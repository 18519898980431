import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntryAdjustmentPhysicalInventoryMedicalComponent } from '../entry-adjustment-physical-inventory-medical/entry-adjustment-physical-inventory-medical.component';
import { ActivatedRoute } from '@angular/router';
import { PhysicalInventoryService } from 'src/app/services/physical-inventory.service';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detail-physical-inventory-medical',
  templateUrl: './detail-physical-inventory-medical.component.html',
  styles: [
  ]
})
export class DetailPhysicalInventoryMedicalComponent implements OnInit {
  paramt :any;
  InventoryDetail:any;
  InventoryHeaeder:any
  cutOffDate
  inventoryChange:any
  inventoryChangeRecord:any;
  inventoryDetailRecord:any;
  conditionId:any;
  constructor(
    private dialog: MatDialog,
    private _servicePhysicalInventory : PhysicalInventoryService,
    private _param : ActivatedRoute,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    this._param.params.subscribe({
      next:(resp:any)=>{
        this.paramt = resp['id'];
        this.getInventoryDetail(this.paramt);
        this.getInventoryHeader(this.paramt)
        this.getinventoryChanges(this.paramt)
      }
    })
  }

  getInventoryHeader(headerid:any){
    this._servicePhysicalInventory.getInventoryHeaderType(1, headerid).subscribe({
      next:(resp:any) => {
        if(!resp?.succeded){
          this.toastr.error(resp?.errors[0]);
          return;
        }
        this.InventoryHeaeder = resp.dataList[0]
        this.conditionId = resp.dataList[0].conditionId;
        this.cutOffDate =  formatDate(this.InventoryHeaeder?.cutOffDate, 'yyyy-MM-dd', 'en' )
      }, error: res => {
        this.toastr.error("Ha ocurrido un error al tratar de obtener la información de cabezera del inventario fisico");
      }
    })
  }

  getInventoryDetail(param? : any){
    this._servicePhysicalInventory.getInventoryDetail(param).subscribe({
      next:(resp:any) => {
        if(!resp?.succeded){
          this.toastr.error(resp?.errors[0]);
          return;
        }
        this.InventoryDetail = resp.dataList
      }, error: res => {
        this.toastr.error("Ha ocurrido un error al tratar de obtener la lista de detalle del inventario fisico");
      }
    })
  }

  getinventoryChanges(headerId?:any){
    this._servicePhysicalInventory.getInventoryChanges(1, headerId)
            .subscribe({
              next:(resp:any) => {
                if(!resp?.succeded){
                  this.toastr.error(resp?.errors[0]);
                  return;
                }
                this.inventoryChange = resp.dataList
              },  error: res => {
                this.toastr.error("Ha ocurrido un error al tratar de obtener la lista de ajustes del inventario fisico");
              }
            })
  }

  adjustmentreason(conceptChanges:any){
    Swal.fire({
      title: 'Razón de Ajuste',
      text: `${conceptChanges}`,

    })
  }

  AjusteDeEntrada(item: any): void
  {
    this.dialog.open(EntryAdjustmentPhysicalInventoryMedicalComponent, {
      width: '50%',
      data: {
        header: this.InventoryHeaeder,
        detail: item
      }
    }).afterClosed().subscribe((result: any) => {
      if (result?.Applied) {
        this.getInventoryDetail(this.paramt);
        this.getinventoryChanges(this.paramt);
      }
    });;
  }


  getPaginatedRecords(event) {
    this.inventoryChangeRecord = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  getPaginatedRecord(event) {
    this.inventoryDetailRecord = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

}
