import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { DepartmentService } from 'src/app/services/department.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { DirectoryModalComponent } from '../directory-modal/directory-modal/directory-modal.component';
declare function applySort(): any;

@Component({
  selector: 'app-employees-tab',
  templateUrl: './employees-tab.component.html',
  styleUrls: ['./employees-tab.component.css']
})
export class EmployeesTabComponent implements OnInit {

  selectedItems
  dataPaginated = []
  dataListOriginal = []
  dataList = []
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =[
    { propkey: "name", displayName: "Nombre" },
    { propkey: "positionName", displayName: "Cargo" },
    { propkey: "departmentName", displayName: "Unidad organizativa" },
    { propkey: "email", displayName: "Correo" },
    { propkey: "phoneNumber", displayName: "Teléfono" },
    { propkey: "phoneNumber", displayName: "Extensión" },
  ];
  @ViewChild('paginator') paginator: CaptionComponent;
  constructor(
    private employeeService: EmployeesService,
    private auth: AuthInfoService,
    private cdRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private exlService: ExportToExcelService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getData();
    applySort();
  }


  openDirectoryModal(){
    this.dialog.open(DirectoryModalComponent,{
      width:'50%'
    })
  }

  exportToExcel() {
    let data = this.filteredList.enable ? this.filteredList.records : this.dataList
    if(data.length < 1) return

    let excelHeaders: string[][] = [[
      "Nombre",
      "Cargo",
      "Unidad Organizativa",
      "Correo",
      "Teléfono",
      "Extensión",
    ], [
      "name",
      "positionName",
      "departmentName",
      "email",
      "phoneNumber",
    ]]
    this.exlService.exportToExcelSpecificColumns(data, excelHeaders, 'Directorio Telefónico -Unidades Organizativas', true);
  }
  getData(){
    this.employeeService.getEmployees().subscribe({
      next: (res:any) => {
      if (res.errors.length > 0) { this.toastr.error("Ha ocurrido un error al obtener la lista de Servidores Públicos"); return; }
      this.dataList = res.dataList;
      this.dataListOriginal = res.dataList;
    }, error: (error:any) => {this.toastr.error('Ha ocurrido un error inesperado')}})
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
  }

  sortArray(list: Array<any>) {
    list.sort(function (a, b) {
      if (a.departmentId > b.departmentId) {
        return -1;
      }
      else if (a.departmentId < b.departmentId) {
        return 1;
      }
      return 0;
    });
  }
  getPaginatedRecords(event) {
    this.dataPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  
  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Click para desplegar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }


}
