<div *ngIf="isLoading; else loaded"
    class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
    <el-spinner (loadingStatus)="isLoading=$event" animation="spinner-logo">
    </el-spinner>
</div>

<ng-template #loaded>
    <div id="map" class="card card-custom">
        <div class="card-header mt-3">
            <div class="card-title flex-column align-items-start">
                <div class="ttl d-flex align-items-center">
                    <i class="flaticon-candidato-1 icon-3x text-danger mr-3"></i>
                    <h1 class="text-primary"> No Objeción </h1>
                </div>
                <div class="border-primary input-group input-group-sm mb-3 min-w-200px rounded">
                    <span class="bg-white input-group-text"> <i
                            class="flaticon-departamento-1 icon-3x text-primary"></i></span>
                    <ngx-select-dropdown class="flex-1" [config]="config" [options]="mapInstitutionsList"
                        [multiple]="false"
                        (change)="mapHeaderStringDataList=filter(mapDataList,'companyName', $event.value.companyName); displayKey='companyName'">
                    </ngx-select-dropdown>
                </div>
            </div>
            <div class="card-toolbar">
                <div class="border-primary min-w-225px card p-2 mr-2 switch switch-primary switch-sm rounded-pill">
                    <label class="cursor-pointer d-flex align-items-center">
                        <input (ngModelChange)='mapToggle()' [checked]='false'
                            class="cursor-pointer abc-checkbox-primary" [(ngModel)]="appoved" type="checkbox" />
                        <span></span>
                        <strong *ngIf="!appoved" class="font-size-h5 mx-4">
                            No aprobadas
                        </strong>
                        <strong *ngIf="appoved" class="font-size-h5 mx-4">
                            Aprobadas
                        </strong>
                    </label>
                </div>
                <!-- <button class="btn btn-light-success btn-pill font-weight-bolder mr-3 mb-3">
                    <i class="flaticon-excel-1"></i>
                    Exportar a Excel
                </button> -->
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table id="" class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-center min-w-175px"> Número de Remisión</th>
                            <th class="text-center min-w-175px">Fecha de Remisión</th>
                            <th class="text-center min-w-175px">Cédula</th>
                            <th class="text-center min-w-175px">Nombre</th>
                            <th class="text-center min-w-175px">Cargo</th>
                            <th class="text-center">Unidad organizativa</th>
                            <th class="text-center">Condiciones</th>
                            <th class="text-center ">Status</th>
                            <th class="text-center">Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of records">
                            <td class="text-center">{{row.communicationId}}</td>
                            <td class="text-center">{{row.dateEnd | date: 'dd-MM-yyyy'}}</td>
                            <td class="text-center">{{row.cedula}}</td>
                            <td class="text-center">{{row.nombre}}</td>
                            <td class="text-center">{{row.posicion}}</td>
                            <td class="text-center">{{row.departamento}}</td>

                            <ng-container [ngSwitch]="row.condiciones">
                                <ng-container *ngSwitchCase="0">
                                    <td class="text-center">
                                        <i class="flaticon-reloj-de-pared icon-2x text-primary"></i>
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchCase="1">
                                    <td class="text-center">
                                        <i class="flaticon-checked icon-2x text-success"></i>
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchCase="2">
                                    <td class="text-center">
                                        <i class="flaticon-checked icon-2x text-success"></i>
                                        <i class="flaticon-checked icon-2x text-success"></i>
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchCase="3">
                                    <td class="text-center">
                                        <i class="flaticon-mala-critica icon-2x text-danger"></i>
                                    </td>
                                </ng-container>
                            </ng-container>

                            <td class="w-100px text-center" *ngIf="row.condition == 2">
                                <span style="width: 137px;">
                                    <span class="label label-success label-dot mr-2"></span>
                                    <span class="font-weight-bold text-success">Aprobada</span>
                                </span>
                            </td>
                            <td class="w-100px text-center" *ngIf="row.condition == 0 || row.condition == 1">
                                <span style="width: 137px;">
                                    <span class="label label-danger label-dot mr-2"></span>
                                    <span class="font-weight-bold text-danger">No aprobada</span>
                                </span>
                            </td>

                            <td class="w-100px text-center">
                                <!-- <button  type="button"class="btn btn-sm btn-light-skype btn-icon mr-2">
                                    <i class="flaticon-ver icon-2x" data-title="Ver" data-toggle="tooltip" ></i>
                                </button> -->
                                <button *ngIf="row.condition == 0 || row.condition == 1" type="button"
                                    data-toggle="modal" data-target="#approbed" (click)="buildMapValidationForm(row)"
                                    class="btn btn-sm btn-light-success btn-icon mr-2">
                                    <i class="flaticon-checked icon-2x" data-title="Validar" data-toggle="tooltip"></i>
                                </button>
                                <!-- <button  type="button" class="btn btn-sm btn-light-danger btn-icon mr-2" data-toggle="modal" data-target="#editOjetion" (click)="getDataList(row)">
                                    <i  class="flaticon-mala-critica icon-2x" data-title="No objetar" data-toggle="tooltip"></i>
                                </button> -->
                                <ng-template [ngIf]="row.condition==1">
                                    <button type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                        data-toggle="modal" data-target="#editOjetion" (click)="getDataList(row)">
                                        <i class="flaticon-mala-critica icon-2x" data-title="No objetar"
                                            data-toggle="tooltip"></i>
                                    </button>

                                </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-end align-items-center flex-wrap">
                    <el-caption #paginator [registerQuantity]="mapPrincipalDataList.length"
                        [records]="mapPrincipalDataList" (onChage)="getPaginatedRecords($event)"></el-caption>

                </div>
            </div>
        </div>
    </div>

    <el-modal target="approbed" size="xl" title="Analisis del Trabajo y Remuneración">
        <form [formGroup]="mapValidation">
            <div class="row gutter-b">
                <div class="col-lg-2 d-flex align-items-center justify-content-center">
                    <div class="symbol symbol-150 mr-3">
                        <div class="fotoPerfil" [ngClass]="{'d-block': path=='', 'd-none' : path !=''}">
                            <span *ngIf="loadingPic" class="d-flex flex-column align-items-center">
                                <img src="assets/media/logos/xmera-logo-3.png" alt="loading" width="70px" />
                                <strong class="text-danger font-size-h3">Cargando...</strong>
                            </span>
                        </div>

                        <div class="symbol symbol-150 d245 my-4 max-w-250px" *ngIf="!loadingPic && path != ''">
                            <img alt="Pic" [src]="path" />
                        </div>
                    </div>
                </div>

                <!--     <div class="col-xl-3 col-lg-6">
                    <div class="fotoPerfil" [ngClass]="{'d-block': file.guid=='', 'd-none' : file.guid !=''}">
                    </div>
                    <div class="center-v gutter-b h-100"
                        [ngClass]="{'showUpload': file.guid!='', 'NotShowUpload' : file.guid ==''}">
                        <span *ngIf="loadingPic" class="d-flex flex-column align-items-center">
                            <img src="assets/media/logos/xmera-logo-3.png" alt="loading" width="70px" />
                            <strong class="text-danger font-size-h3">Cargando...</strong>
                        </span>
                        <div class="symbol symbol-150 d250 my-4 max-w-250px" *ngIf="!loadingPic && file.url != ''">
                            <img alt="Pic" [src]="file.url" />
                        </div>
                    </div>
                </div> -->

                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-lg-6 form-group">
                            <label>Número de Remisión</label>
                            <input class="form-control form-control-solid form-control-lg"
                                [value]="(mapPrincipalDataItem)? mapPrincipalDataItem.communicationId: ''" readonly>
                        </div>
                        <div class="col-lg-6 form-group">
                            <label>Fecha de Remisión</label>
                            <input class="form-control form-control-solid form-control-lg"
                                [value]="((mapPrincipalDataItem)? mapPrincipalDataItem.dateEnd: '') | date:'dd-MM-yyyy'"
                                readonly>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-9 form-group">
                            <label>Nombre</label>
                            <input class="form-control form-control-solid form-control-lg"
                                [value]="(mapPrincipalDataItem)? mapPrincipalDataItem.nombre: ''" readonly>
                        </div>
                        <!-- <div class="col-lg-3 form-group">
                            <label>Sexo</label>
                            <input class="form-control form-control-solid form-control-lg" readonly value="Insdistinto">
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="col-lg-6 form-group">
                            <label>Cédula</label>
                            <input class="form-control form-control-solid form-control-lg"
                                [value]="(mapPrincipalDataItem)? mapPrincipalDataItem.cedula: ''" readonly>
                        </div>
                        <!-- <div class="col-lg-6 form-group">
                            <label>Nivel Académico</label>
                            <input class="form-control form-control-solid form-control-lg" readonly>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card p-3 border-primary mt-n3">
                        <div class="form-group">
                            <!-- El señor Osvaldo indica que este titulo es distinto, se cambió en la sabana -->
                            <!-- <label>Nivel Académico <span class="text-danger">*</span></label> -->
                            <label>Número de certificación <span class="text-danger">*</span></label>
                            <input [formControl]="mapValidation.controls['noCertification']" type="number"
                                class="form-control form-control-solid form-control-lg">
                        </div>
                        <div class="form-group">
                            <label>Fecha Salida <span class="text-danger">*</span> </label>
                            <input type="date" [formControl]="mapValidation.controls['departureDate']"
                                class="form-control form-control-solid form-control-lg">
                        </div>
                        <div class="form-group m-0">
                            <!-- El señor Osvaldo indica que este es distinto, se cambió en la sabana -->
                            <!-- <label>Fecha de Propuesta <span class="text-danger">*</span> </label> -->
                            <label>Fecha de la respuesta <span class="text-danger">*</span> </label>
                            <input type="date" [formControl]="mapValidation.controls['dateResponse']"
                                class="form-control form-control-solid form-control-lg">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gutter-b" formArrayName="details">
                <div class="table-responsive">
                    <table class="table atbl table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left">Datos del Cargo</th>
                                <th class="text-center w-175px">Acción</th>
                                <th class="text-center min-w-175px">Motivo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-template [ngIf]="mapValidation.get('details')">
                                <tr [formGroupName]="i"
                                    *ngFor="let item of mapValidation.get('details')['controls']; let i = index;">
                                    <ng-template [ngIf]="mapValidationPositionRows[i]">
                                        <td>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <label
                                                    class="mr-3 font-size-h5 w-300px float-left">{{(mapValidationPositionRows[i].fieldName)?mapValidationPositionRows[i].fieldName:
                                                    'Parámetro no especificado' | json}}</label>
                                                <input type="text" class="form-control"
                                                    [value]="(mapValidationPositionRows[i].text)?mapValidationPositionRows[i].text: 'Este registro no existe o no se encuentra.'"
                                                    readonly>
                                            </div>
                                        </td>
                                        <td class="w-200px text-center">
                                            <ng-template [ngIf]="mapValidationPositionRows[i].pdf">

                                                <!-- <a target="_blank" href="assets/docs/prueba/Diplomas_Estudios_de_Grado_1994_y_Posgrado_2003_UNAPEC.pdf" class="btn btn-sm btn-light-danger btn-icon mr-2">
                                                <i class="flaticon-pdf-file icon-2x" data-title="PDF" data-toggle="tooltip"></i>
                                            </a> 
    
                                            <el-modal target="openDoc" size="xl">
                                                <object style="height: 100%;" width="100%" data="assets/docs/prueba/Diplomas_Estudios_de_Grado_1994_y_Posgrado_2003_UNAPEC.pdf" type="application/pdf">
                                                    <embed src="assets/docs/prueba/Diplomas_Estudios_de_Grado_1994_y_Posgrado_2003_UNAPEC.pdf" type="application/pdf" />
                                                </object>
                                            </el-modal> -->



                                                <button data-toggle="modal" data-target="#openDoc" type="button"
                                                    class="btn btn-sm btn-light-danger btn-icon mr-2">
                                                    <i class="flaticon-pdf-file icon-2x" data-title="PDF"
                                                        data-toggle="tooltip"></i>
                                                </button>

                                                <el-modal size="xl" target="openDoc">
                                                    <object style="height: 1500px; width: 1000px;"
                                                        data="assets/docs/prueba/Diplomas_Estudios_de_Grado_1994_y_Posgrado_2003_UNAPEC.pdf"
                                                        type="application/pdf">
                                                        <embed
                                                            src="assets/docs/prueba/Diplomas_Estudios_de_Grado_1994_y_Posgrado_2003_UNAPEC.pdf"
                                                            type="application/pdf" />
                                                    </object>
                                                </el-modal>


                                            </ng-template>
                                            <button type="button" data-toggle="modal"
                                                (click)="mapValidation.controls['details'].value[i]['approval']=true"
                                                class="btn btn-sm btn-light-success btn-icon mr-2">
                                                <i class="flaticon-checked icon-2x" data-title="Aprobar"
                                                    data-toggle="tooltip"></i>
                                            </button>
                                            <button type="button"
                                                (click)="mapValidation.controls['details'].value[i]['approval']=false"
                                                class="btn btn-sm btn-light-danger btn-icon mr-2">
                                                <i class="flaticon-mala-critica icon-2x" data-title="No objetar"
                                                    data-toggle="tooltip"></i>
                                            </button>
                                        </td>
                                        <td class="text-center">
                                            <ngx-select-dropdown class="flex-1" [config]="parameterControlConfig"
                                                [options]="rejectReasonsList" formControlName="reasonId">
                                            </ngx-select-dropdown>
                                        </td>
                                    </ng-template>
                                </tr>
                            </ng-template>
                        </tbody>
                        <!-- <tbody>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <label class="mr-3 font-size-h5 w-300px float-left">Recinto</label>
                                        <input type="text" class="form-control" readonly>
                                    </div>
                                </td>
                                <td  class="w-200px text-center">                       
                                    <button  type="button" data-toggle="modal" data-target="#approbed" class="btn btn-sm btn-light-success btn-icon mr-2">
                                        <i class="flaticon-checked icon-2x" data-title="Aprobar" data-toggle="tooltip"></i>
                                    </button>
                                    <button  type="button" class="btn btn-sm btn-light-danger btn-icon mr-2">
                                        <i  class="flaticon-mala-critica icon-2x" data-title="No objetar" data-toggle="tooltip"></i>
                                    </button>
                                </td>
                                <td class="text-center">
                                    <ngx-select-dropdown class="flex-1"
                                    
                                    ></ngx-select-dropdown></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <label class="mr-3 font-size-h5 w-300px float-left">Unidad organizativa</label>
                                        <input type="text" class="form-control" readonly>
                                    </div>
                                </td>
                                <td  class="w-200px text-center">                       
                                    <button  type="button" data-toggle="modal" data-target="#approbed" class="btn btn-sm btn-light-success btn-icon mr-2">
                                        <i class="flaticon-checked icon-2x" data-title="Aprobar" data-toggle="tooltip"></i>
                                    </button>
                                    <button  type="button" class="btn btn-sm btn-light-danger btn-icon mr-2">
                                        <i  class="flaticon-mala-critica icon-2x" data-title="No objetar" data-toggle="tooltip"></i>
                                    </button>
                                </td>
                                <td class="text-center"><ngx-select-dropdown class="flex-1"></ngx-select-dropdown></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center justify-content-between">
                                    <label class="mr-3 font-size-h5 w-300px">Posición / Cargo</label>
                                    <input type="text" class="form-control" readonly>
                                    </div>
                                </td>
                                <td  class="w-200px text-center">                       
                                    <button  type="button" data-toggle="modal" data-target="#approbed" class="btn btn-sm btn-light-success btn-icon mr-2">
                                        <i class="flaticon-checked icon-2x" data-title="Aprobar" data-toggle="tooltip"></i>
                                    </button>
                                    <button  type="button" class="btn btn-sm btn-light-danger btn-icon mr-2">
                                        <i  class="flaticon-mala-critica icon-2x" data-title="No objetar" data-toggle="tooltip"></i>
                                    </button>
                                </td>
                                <td class="text-center"><ngx-select-dropdown class="flex-1"></ngx-select-dropdown></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center justify-content-between">
                                    <label class="mr-3 font-size-h5 w-300px">Grupo Ocupacional</label>
                                    <input type="text" class="form-control" readonly>
                                    </div>
                                </td>
                                <td  class="w-200px text-center">                       
                                    <button  type="button" data-toggle="modal" data-target="#approbed" class="btn btn-sm btn-light-success btn-icon mr-2">
                                        <i class="flaticon-checked icon-2x" data-title="Aprobar" data-toggle="tooltip"></i>
                                    </button>
                                    <button  type="button" class="btn btn-sm btn-light-danger btn-icon mr-2">
                                        <i  class="flaticon-mala-critica icon-2x" data-title="No objetar" data-toggle="tooltip"></i>
                                    </button>
                                </td>
                                <td class="text-center"><ngx-select-dropdown class="flex-1"></ngx-select-dropdown></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center justify-content-between">
                                    <label class="mr-3 font-size-h5 w-300px">Tipo de Nombramiento</label>
                                    <input type="text" class="form-control" readonly>
                                    </div>
                                </td>
                                <td  class="w-200px text-center">                       
                                    <button  type="button" data-toggle="modal" data-target="#approbed" class="btn btn-sm btn-light-success btn-icon mr-2">
                                        <i class="flaticon-checked icon-2x" data-title="Aprobar" data-toggle="tooltip"></i>
                                    </button>
                                    <button  type="button" class="btn btn-sm btn-light-danger btn-icon mr-2">
                                        <i  class="flaticon-mala-critica icon-2x" data-title="No objetar" data-toggle="tooltip"></i>
                                    </button>
                                </td>
                                <td class="text-center"><ngx-select-dropdown class="flex-1"></ngx-select-dropdown></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center justify-content-between">
                                    <label class="mr-3 font-size-h5 w-300px">Sueldo</label>
                                    <input type="text" class="form-control" readonly>
                                    </div>
                                </td>
                                <td  class="w-200px text-center">                       
                                    <button  type="button" data-toggle="modal" data-target="#approbed" class="btn btn-sm btn-light-success btn-icon mr-2">
                                        <i class="flaticon-checked icon-2x" data-title="Aprobar" data-toggle="tooltip"></i>
                                    </button>
                                    <button  type="button" class="btn btn-sm btn-light-danger btn-icon mr-2">
                                        <i  class="flaticon-mala-critica icon-2x" data-title="No objetar" data-toggle="tooltip"></i>
                                    </button>
                                </td>
                                <td class="text-center"><ngx-select-dropdown class="flex-1"></ngx-select-dropdown></td>
                            </tr>
                        </tbody> -->
                    </table>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group d-flex justify-content-end align-items-center pt-5 m-0">
                    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2"
                        data-dismiss="modal">
                        <i class="flaticon2-cross"></i>
                        <span>Rechazar</span>
                    </button>
                    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success"
                        data-dismiss="modal" (click)="onSubmitValidationForm()">
                        <i class="flaticon-disco-flexible"></i>
                        <span> Aprobar </span>
                    </button>
                </div>
            </div>
        </form>
    </el-modal>

    <el-modal target="editOjetion" size="xl" title="Registrar la No-Objeción">
        <div class="gutter-b ml-n5 mr-n5 row mt-n5">
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table id="" class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th>
                                    <span>Número de remisión</span>
                                </th>
                                <th>
                                    <span>Nombres y Apellidos</span>
                                </th>
                                <th>
                                    <span>Unidad Organizativa</span>
                                </th>
                                <th>
                                    <span> Puesto Solicitado</span>
                                </th>
                                <th>
                                    <span>Cédula</span>
                                </th>
                                <th>
                                    <span>Salario Propuesto</span>
                                </th>
                                <th>
                                    <span>Fecha Solicitud</span>
                                </th>
                                <th>
                                    <span>Estatus de aprobación</span>
                                </th>
                                <!-- <th class="actionsBtn">
                                    <span>Acciones</span>
                                </th> -->
                            </tr>
                        </thead>
                        <ng-template [ngIf]="mapDataList">
                            <tbody>
                                <tr *ngFor="let row of mapDataList">
                                    <td>
                                        {{row.companyName}}
                                    </td>
                                    <td>
                                        {{row.fullName}}
                                    </td>
                                    <td>
                                        {{row.departmentName}}
                                    </td>
                                    <td>
                                        {{row.requiredPosition}}
                                    </td>
                                    <td>
                                        {{row.cedula}}
                                    </td>
                                    <td>
                                        <span class="font-weight-bold text-warning">{{row.salaryRequested |
                                            currency}}</span>
                                    </td>
                                    <td>
                                        {{row.requestedDate |date: 'dd-MM-yyyy' }}
                                    </td>
                                    <td>
                                        <ng-container *ngIf="row.condition==0; else secondCondition">
                                            <i class="flaticon-reloj-de-pared icon-2x text-primary"></i>
                                        </ng-container>
                                        <ng-template #secondCondition>
                                            <ng-container *ngIf="row.condition==1; else thirdCondition">
                                                <i class="flaticon-checked icon-2x text-success"></i>
                                            </ng-container>
                                        </ng-template>
                                        <ng-template #thirdCondition>
                                            <i class="flaticon-mala-critica icon-2x text-danger"></i>
                                        </ng-template>
                                    </td>
                                </tr>
                            </tbody>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">

            <form [formGroup]="mapForm">
                <div *ngIf="!mapFormCancelationAction; else cancelationForm">
                    <div class="row">
                        <div class="form-group col-md-4">
                            <label>Puesto Solicitado</label>
                            <input class="form-control form-control-solid form-control-lg" type="text"
                                [value]="(mapEmployeeSelectedItem)?mapEmployeeSelectedItem.posicion:''" disabled>
                        </div>
                        <div class="form-group col-md-2">
                            <label>Salario Solicitado</label>
                            <input class="form-control form-control-solid form-control-lg" type="text"
                                [value]="((mapEmployeeSelectedItem)?mapEmployeeSelectedItem.salaryRequested :0) | currency"
                                disabled>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Tipo de Vinculación Solicitada</label>
                            <input class="form-control form-control-solid form-control-lg" type="text"
                                [value]="(mapEmployeeSelectedItem) ? getTextFromValue(bondingTypeList,'ocode', +mapEmployeeSelectedItem.bondingType) : ''"
                                disabled>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Fecha de Ingreso Solicitada</label>
                            <input class="form-control form-control-solid form-control-lg" type="text"
                                [value]="((mapEmployeeSelectedItem) ? mapEmployeeSelectedItem.requestedDate : null) |date: 'dd-MM-yyyy'"
                                disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-4">
                            <label class="d-block w-100">Puesto Aprobado</label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown class=" flex-1 "
                                    [formControl]="mapForm.controls['positionApprovedId']"
                                    [config]="employeePositionsConfig" [options]="employeesPositionsList">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label>Salario Aprobado</label>
                            <input class="form-control form-control-solid form-control-lg" currencyMask min="0"
                                max="500000.00"
                                [value]="((mapEmployeeSelectedItem)?mapEmployeeSelectedItem.salaryRequested :0) | currency"
                                [formControl]="mapForm.controls['salaryApproved']"
                                [options]="{ prefix: '$ ', thousands: ',', decimal: '.',precision:2 } ">
                        </div>
                        <div class="form-group col-md-3">
                            <label class="d-block w-100">Tipo de Vinculación Aprobada</label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown class=" flex-1 " [formControl]="mapForm.controls['bondingType']"
                                    [config]="parameterControlConfig" [options]="bondingTypeList"></ngx-select-dropdown>
                            </div>

                        </div>
                        <div class="form-group col-md-3">
                            <label>Fecha de Ingreso Aprobada</label>
                            <input class="form-control form-control-solid form-control-lg" type="date"
                                [formControl]="mapForm.controls['requestedDate']">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label>Vigencia del Nombramiento Aprobado</label>
                            <input class="form-control form-control-solid form-control-lg" type="date"
                                [formControl]="mapForm.controls['validyDate']"
                                (change)="contractTime=dateDiff(mapForm.controls['requestedDate'].value, mapForm.controls['validyDate'].value, 'weeks')">
                        </div>
                        <div class="form-group col-md-2">
                            <label>Tiempo de Nombramiento</label>
                            <input class="form-control form-control-solid form-control-lg" type="text"
                                [value]="(contractTime) ? contractTime : ''" disabled>
                        </div>
                        <div class="form-group col-md-7">
                            <label>Observaciones MAP</label>
                            <input class="form-control form-control-solid form-control-lg" type="text"
                                [formControl]="mapForm.controls['observationMap']">
                        </div>
                    </div>
                </div>
                <ng-template #cancelationForm>
                    <div class="row">
                        <div class="form-group col-md-4">
                            <label>Puesto Solicitado</label>
                            <input class="form-control form-control-solid form-control-lg" type="text"
                                [value]="(mapEmployeeSelectedItem)?mapEmployeeSelectedItem.requiredPosition:''"
                                disabled>
                        </div>
                        <div class="form-group col-md-2">
                            <label>Salario Solicitado</label>
                            <input class="form-control form-control-solid form-control-lg" type="text"
                                [value]="((mapEmployeeSelectedItem)?mapEmployeeSelectedItem.salaryRequested :0) | currency"
                                disabled>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Tipo de Vinculación Solicitada</label>
                            <input class="form-control form-control-solid form-control-lg" type="text"
                                [value]="(mapEmployeeSelectedItem) ? getTextFromValue(bondingTypeList,'ocode', +mapEmployeeSelectedItem.bondingType) : ''"
                                disabled>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Fecha de Ingreso Solicitada</label>
                            <input class="form-control form-control-solid form-control-lg" type="text"
                                [value]="((mapEmployeeSelectedItem) ? mapEmployeeSelectedItem.requestedDate : null) |date: 'dd-MM-yyyy'"
                                disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-4">
                            <label class="d-block w-100">Puesto Aprobado</label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown class=" flex-1 "
                                    [formControl]="mapForm.controls['positionApprovedId']"
                                    [config]="employeePositionsConfig" [options]="employeesPositionsList">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                        <div class="form-group col-md-2">
                            <label>Salario Aprobado</label>
                            <input class="form-control form-control-solid form-control-lg" currencyMask min="0"
                                max="500000.00" [options]="{ prefix: '$ ', thousands: ',', decimal: '.',precision:2 } " 
                                [formControl]="mapForm.controls['salaryApproved']" disabled>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="d-block w-100">Tipo de Vinculación Aprobada</label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown class=" flex-1" [formControl]="mapForm.controls['bondingType']"
                                    [config]="bondingTypeConfig" [options]="bondingTypeList"></ngx-select-dropdown>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Fecha de Ingreso Aprobada</label>
                            <input class="form-control form-control-solid form-control-lg" type="date"
                                [value]="(mapEmployeeSelectedItem) ? mapEmployeeSelectedItem.requestedDate: null"
                                [formControl]="mapForm.controls['requestedDate']" disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label>Vigencia del Nombramiento Aprobado</label>
                            <input class="form-control form-control-solid form-control-lg" type="date"
                                [formControl]="mapForm.controls['validyDate']"
                                (change)="contractTime=dateDiff(mapForm.controls['requestedDate'].value, mapForm.controls['validyDate'].value, 'weeks')"
                                disabled>
                        </div>
                        <div class="form-group col-md-2">
                            <label>Tiempo de Nombramiento</label>
                            <input class="form-control form-control-solid form-control-lg" type="text"
                                [value]="(contractTime) ? contractTime : ''" disabled>
                        </div>
                        <div class="form-group col-md-7">
                            <label>Observaciones MAP</label>
                            <input class="form-control form-control-solid form-control-lg" type="text"
                                [formControl]="mapForm.controls['observationMap']">
                        </div>
                    </div>
                </ng-template>

                <div class="row">
                    <div class="form-group col-md-12 d-flex justify-content-end border-top pt-3 m-0">
                        <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2"
                            data-dismiss="modal">
                            <i class="flaticon2-cross"></i>
                            <span>Cerrar</span>
                        </button>
                        <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success"
                            data-dismiss="modal" (click)="onSubmit(mapForm)" type="submit">
                            <i class="flaticon-disco-flexible"></i>
                            <span> Aprobar </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </el-modal>

</ng-template>