import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { Location } from '@angular/common';
import { Period } from '@payroll/schedule/models/period.model';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { CompaniesService } from 'src/app/services/companies.service';
import { ToastrService } from 'ngx-toastr';
import { PayrollReportService } from 'src/app/services/payroll-report.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { PayrollConceptService } from 'src/app/services/payroll-concept.service';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import * as moment from 'moment';
@Component({
  selector: 'app-individual-payroll-report',
  templateUrl: './individual-payroll-report.component.html',
  styleUrls: ['./individual-payroll-report.component.css']
})
export class IndividualPayrollReportComponent implements OnInit {
  institutions: any = []
  budgets: any = []
  payrollTypes: any = []
  periods: any = []
  employeeTypes: any = []
  concepts: any = []
  relatedPeriods = []
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PAYROLL-REPORT',
    exportAction: 'EXPGEN',
    searchAction: 'SEARGEN'
  }
  form = new FormGroup({
    institution: new FormControl(null),
    budget: new FormControl(null, [Validators.required]),
    payrollType: new FormControl(null, [Validators.required]),
    period: new FormControl(null, [Validators.required]),
    version: new FormControl(null, [Validators.required]),
    employeeType: new FormControl(null),
    concept: new FormControl(null),
    year: new FormControl(null),
    relatedPeriod: new FormControl(null)
  });
  institutionConfig;
  periodConfig;
  payrollTypeConfig;
  relatedPeriodConfig;
  budgetConfig;
  conceptConfig;
  versionConfig;
  employeeTypeConfig;
  budgetAreaList: any = []
  filteredPeriods: Period[] = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'employeeTypeId',
    textField: 'description',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  employeeType: any[] = [];
  employeeTypeSelected: any[] = [];
  versionList: any = []
  overallSummary: any[] = [];
  overallSummaryOriginalList: any = [];
  yearsList: any[] = []

  constructor(
    public authInfo: AuthInfoService,
    private location: Location,
    private conpaniesService: CompaniesService,
    private toastr: ToastrService,
    private payrollReportService: PayrollReportService,
    private payrollService: PayrollService,
    private parameterServices: ParameterControlService,
    private srvPayrollConcept: PayrollConceptService,
  ) { }

  ngOnInit(): void {
    this.institutionConfig = this.getDropdownConfig('description')
    this.budgetConfig = this.getDropdownConfig('description')
    this.payrollTypeConfig = this.getDropdownConfig('stringData')
    this.versionConfig = this.getDropdownConfig('versionDescription')
    this.conceptConfig = this.getDropdownConfig('description')
    this.periodConfig = this.getDropdownConfig('description')
    this.employeeTypeConfig = this.getDropdownConfig('description')
    this.relatedPeriodConfig = this.getDropdownConfig('description')
    this.form.get('concept').disable()
    this.form.get('version').disable()

    this.getBranches()
    this.getPeriods()
    this.getPayrollTypes()
    this.getEmployeeType()
  }
  changeSelectedPayrollType(event) {
    const isAdditional = this.form.value.payrollType?.ocode === 4
    this.form.get('payrollType').patchValue(this.form.value.payrollType)
    this.form.get('version').disable()
    this.form.get('concept').setValue(null);
    this.concepts = []
    this.getPayrollVersions()
    this.form.get('relatedPeriod').patchValue(null)
    if (isAdditional) {
      this.addValidation('relatedPeriod', [Validators.required]);
      this.getBeforePeriod();
      return;
    }
    this.addValidation('relatedPeriod', null);

  }

  changeRelatedPeriod() {
    this.form.get('version').patchValue(null)
    if (!this.form.get('relatedPeriod').value?.payrollPeriodId) {
      this.form.get('version').disable()
    }
    this.getPayrollVersions()
  }
  private addValidation(controlName: string, validators) {
    this.form.get(controlName).setValidators(validators);
    this.form.get(controlName).updateValueAndValidity();
  }
  changeYear(event) {
    this.form.get('period').patchValue(null)
    this.filteredPeriods = this.periods.filter((period: any) => period.description.includes(`${event.value}`))
  }
  getPayrollVersions() {
    this.concepts = []
    this.form.get('version').patchValue(null)
    this.form.get('concept').patchValue(null)
    this.form.get('concept').disable()
    const form = this.form
    const payrollPeriodId = form.value.period?.payrollPeriodId;
    const payrollTypeId = form.value.payrollType?.ocode;
    const budgetAreaId = form.value.budget?.budgetAreaId;
    const companyId = this.authInfo.getCompanyId()/* form.value.institution?.companyId; */
    this.versionList = [];
    if (payrollPeriodId === undefined || budgetAreaId === undefined || payrollTypeId === undefined || companyId === undefined) {
      return;
    }
    const isAdditional = payrollTypeId === 4

    let relatedPeriod = '0'
    if (isAdditional) {
      if (form.value.relatedPeriod?.payrollPeriodId) {
        relatedPeriod = form.value.relatedPeriod?.payrollPeriodId
      } else {
        return
      }
    }
    this.getVersions(isAdditional, payrollPeriodId, payrollTypeId, budgetAreaId, companyId, relatedPeriod)
  }
  getVersions(additional, payrollPeriodId, payrollTypeId, budgetAreaId, companyId, relatedPeriod) {
    if (additional) {
      this.payrollService.getPayrollVersionsAdditional(payrollPeriodId, payrollTypeId, budgetAreaId, relatedPeriod, 3, companyId).subscribe((res: any) => {
        if (res?.errors?.length > 0) {
          this.toastr.error(res.errors[0]);
          return;
        }
        this.versionList = res?.dataList?.map(v => {
          return {
            ...v,
            versionDescription: `${v?.headerId} - ${v?.versionId} - ${v?.headerCondition}`
          }
        });
        this.form.get('version').enable()
      }, error => {

      })
    } else {
      this.payrollService.getPayrollVersions(payrollPeriodId, payrollTypeId, budgetAreaId, companyId).subscribe((res: any) => {
        if (res?.errors?.length > 0) {
          this.toastr.error(res.errors[0]);
          return;
        }
        this.versionList = res?.dataList?.map(v => {
          return {
            ...v,
            versionDescription: `${v?.headerId} - ${v?.versionId} - ${v?.headerCondition}`
          }
        });
        this.form.get('version').enable()
      }, error => {

      })
    }
  }
  getBeforePeriod() {
    this.payrollService.getBeforePeriod().subscribe(res => {
      if (res.errors.length > 0) {
        this.toastr.error(res.errors[0]);
        return;
      }
      this.relatedPeriods = res.dataList;
    });
  }

  generate() {
    const { budget, payrollType, period, version, concept, relatedPeriod } = this.form.value
    const isAdditional = payrollType?.ocode === 4
    let opt = {
      PayrollTypeId: payrollType?.ocode,
      Version: version?.versionId,
      PayrollPeriodId: period?.payrollPeriodId,
      budgetAreaId: budget?.budgetAreaId,
      CompanyId: this.authInfo.getCompanyId(),
      // employType: employeeType ? employeeType?.employeeTypeId : null,
      newsConceptId: concept?.code,
      PayrollPeriodIdRelated: relatedPeriod?.payrollPeriodId ? relatedPeriod?.payrollPeriodId : '0',
    }

    if (!isAdditional) {
      opt.PayrollPeriodIdRelated = '0'
    }
    if (this.form.invalid) {
      this.toastr.warning('Debe llenar los campos')
      return
    } else {
      if (!opt.newsConceptId) {
        this.toastr.warning('Debe llenar los campos')
        return
      }
    }


    let url = environment.reportUrl + `?ReportCode=individual_concept&PayrollTypeId=${opt.PayrollTypeId}&Code=${opt.newsConceptId}&VersionId=${opt.Version}&PeriodId=${opt.PayrollPeriodId}&BudgetAreaId=${opt.budgetAreaId}&CompanyId=${opt.CompanyId}&PayrollPeriodIdRelated=${opt.PayrollPeriodIdRelated}`
    openReport({ url, title: 'Title', width: '900', height: '700' })
  }

  changeVersion() {
    this.getConcepts()
  }

  getConcepts() {
    const { institution, budget, payrollType, period, version, employeeType, concept, relatedPeriod } = this.form.value
    if (this.form.invalid) {
      this.form.get('concept').setValue(null);
      this.concepts = []

      return
    }

    let opt = {
      PayrollTypeId: payrollType.ocode,
      Version: version.versionId,
      PayrollPeriodId: period.payrollPeriodId,
      budgetAreaId: budget.budgetAreaId,
      CompanyId: this.authInfo.getCompanyId(),
      PayrollPeriodIdRelated: relatedPeriod?.payrollPeriodId ? relatedPeriod.payrollPeriodId : '0',
      NewsConceptId: '0',
      Type: '0',
      status: 'true'
    }
    this.srvPayrollConcept.getNewsConceptByHeader(opt).subscribe({
      next: (res: any) => {
        if (res?.errors?.length > 0) { this.toastr.error(res.errors[0]) }
        if (res?.warnings?.length > 0) { this.toastr.error(res.errors[0]) }
        this.concepts = res.dataList
        this.form.get('concept').enable()
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }
  getPayrollConcepts() {
    this.form.get('concept').setValue(null);

    const formValue = this.form?.value;
    const payrollTypeId = formValue?.payrollType?.ocode ?? 0;
    const payrollPeriodId = formValue?.period?.payrollPeriodId ?? 0;
    const payrollPeriodRelatedId = formValue?.relatedPeriod?.payrollPeriodId ?? 0;
    const budgetAreaId = formValue?.area?.budgetAreaId ?? 0;

    this.srvPayrollConcept.getConceptsBasedNewsAvailable(payrollTypeId, payrollPeriodRelatedId, budgetAreaId, payrollPeriodId).subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          })
          res.warnings.forEach(warn => {
            this.toastr.warning(warn);
          })
          return;
        }
        this.concepts = res?.dataList;
      },
      error: (err: any) => {
        this.toastr.error('Ha ocurrido un error tratando de obtener los conceptos');
      }
    });
  }
  getEmployeeType() {
    this.payrollService.getEmployeeTypes().subscribe((res: any) => {
      if (!res.succeded) {
        return
      }
      this.employeeTypes = res.dataList?.filter(d => d?.status);
    });
  }
  getPeriods() {
    this.payrollService.getBudgetAreas().subscribe((res: any) => {
      this.budgets = res.dataList.filter(ba => ba.status == true)

    })

    this.payrollReportService.getPeriods().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastr.error("Ha ocurrido un error al obtener los períodos");
        return;
      }
      this.periods = res.dataList.filter(e => e.status);
      this.filteredPeriods = this.periods
      let periodYears: any[] = []
      let years = this.periods.map((period: any) => {
        let periodYear = moment(`${period.startDate}`).startOf('date').year().toString()
        if (!periodYears.includes(periodYear)) {
          periodYears.push(periodYear)
        }
        return (periodYears)
      })

      this.yearsList = years[0].reverse()
    }, err => this.toastr.error(err))
  }
  getPayrollTypes() {
    this.parameterServices.getParameters(keyWord.PayrollType).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastr.error(res.errors[0]);
        return;
      }
      this.payrollTypes = res.dataList;
    }, error => {
      this.toastr.error('Ocurrió un error inesperado', '');
    });
  }
  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }
  getBranches() {
    this.conpaniesService.getCompanies().subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          this.toastr.error(res.errors[0])
          return;
        }
        this.institutions = res.dataList;
      },
      error: (err) => {

      }
    });
  }
  goBack() {
    this.location.back();
  }

}
