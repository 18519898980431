<div class="container">
    <div class="row">
        <div class="form-group col-md-6">
            <label class="d-block">¿Posee usted otro empleo aparte
                del que solicita?<span class="text-danger text-monospace legend"> *</span>

            </label>

            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " required="" name="anotherJob" type="radio" [value]="anotherJob.yes"
                        [(ngModel)]="data.anotherJob" (ngModelChange)="validateForm()">
                    Sí</label></div>
            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " required="" name="anotherJob" type="radio" [value]="anotherJob.no"
                        [(ngModel)]="data.anotherJob" (ngModelChange)="validateForm()">
                    No</label></div>
        </div>
        <div class="form-group col-md-6">
            <label>Respuesta <span class="text-danger text-monospace legend">*</span>

            </label><input class="form-control form-control-solid form-control-lg" id="descriptionOtherJob"
                placeholder="Ej: Soporte Tecnico" type="text" name="descriptionOtherJob" required=""
                [(ngModel)]="data.descriptionOtherJob" (ngModelChange)="validateForm()" [readonly]="!data.anotherJob">

        </div>
        <div class="col-md-6">

        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Hora de Inicio <span class="text-danger">*</span></label>
                        <input type="time" [(ngModel)]="data.jobEntryHour" (ngModelChange)="validateForm()"
                            class="form-control form-control-lg" [readonly]="!data.anotherJob" />

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Hora de Fin <span class="text-danger">*</span></label>
                        <input type="time" [(ngModel)]="data.jobExitHour" (ngModelChange)="validateHours();validateForm()"
                            class="form-control form-control-lg" [readonly]="!data.anotherJob" />
                            <span class="text-danger"
                            *ngIf="!validateHours()">La hora salida no puede ser menor ni igual a la hora de entrada</span>
                    </div>
                </div>
            </div>

        </div>
        <hr class="col-md-11">

        <ng-container *ngFor="let item of recruitmentQuestionsAnswer">
            <div class="form-group col-md-6">
                <label class="d-block">{{item?.question}}<span
                        class="text-danger text-monospace legend"> *</span>
                </label>
                <div class="form-check form-check-inline">
                    <label class="form-check-label mt-2 check-dtl">
                        <input class="form-check-input " [checked]="item?.answer === true"
                        (click)="changeQuestion(item, true)" [name]="item.recruitmentQuestionsId" type="radio"> Sí
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="form-check-label mt-2 check-dtl">
                        <input class="form-check-input "  [checked]="item.answer === false"
                        (click)="changeQuestion(item, false)" [name]="item.recruitmentQuestionsId" type="radio"> No
                    </label>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label>Respuesta <span class="text-danger text-monospace legend">* </span>
                </label><input  [(ngModel)]="item.comment" class="form-control form-control-solid form-control-lg"  
                (ngModelChange)="validateForm()"
                [readonly]="!item?.answer">
            </div>
        </ng-container>
        <!--div class="form-group col-md-6">
            <label class="d-block">¿Tiene vehículo propio?<span class="text-danger text-monospace legend"> *</span>
            </label>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.ownVehicle" required="" name="ownVehicle"
                        type="radio" [value]="ownVehicle.yes"> Sí</label>
            </div>
            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.ownVehicle" required="" name="ownVehicle"
                        type="radio" [value]="ownVehicle.no"> No</label>
            </div>
        </div-->
        <!--div class="form-group col-md-6">
            <label class="d-block">¿Está usted
                vacunado?<span class="text-danger text-monospace legend"> *</span>

            </label>

            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.vaccinated" required="" name="vaccinated"
                        type="radio" [value]="vaccinated.yes"> Sí</label>
            </div>
            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.vaccinated" required="" name="vaccinated"
                        type="radio" [value]="vaccinated.no"> No</label>
            </div>
        </div-->
        <!--div class="form-group col-md-6">
            <label class="d-block">¿Utilizará el servicio de transporte de la institución?<span
                    class="text-danger text-monospace legend"> *</span>

            </label>

            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.institutionTransportation" required=""
                        name="institutionTransportation" type="radio" [value]="institutionTransportation.yes">
                    Sí</label></div>
            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.institutionTransportation" required=""
                        name="institutionTransportation" type="radio" [value]="institutionTransportation.no"> No</label>
            </div>
        </div-->
        <!--div>
            <hr class="col-md-11">
            <div class="form-group col-md-12">
                <label class="d-block">Disponibidad de horario<span class="text-danger text-monospace legend"> *</span>

                </label>

                <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                        <input class="form-check-input " required="" name="institutionTransportation" type="checkbox">
                        Sábados</label></div>
                <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                        <input class="form-check-input " required="" name="institutionTransportation" type="checkbox">
                        Domingos</label>
                </div>
                <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                        <input class="form-check-input " required="" name="institutionTransportation" type="checkbox">
                        Feriados</label></div>
                <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                        <input class="form-check-input " required="" name="institutionTransportation" type="checkbox">
                        Nocturno</label></div>
            </div>
        </div>
        <hr class="col-md-11"-->
        <!--div class="form-group col-md-6">
            <label class="d-block">¿Pertenece usted a alguna organización social, fraternal, cultural o religiosa?<span
                    class="text-danger text-monospace legend"> *</span>

            </label>

            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.socialOrganization" required=""
                        name="socialOrganization" type="radio" [value]="socialOrganization.yes"
                        (ngModelChange)="validateForm()"> Sí</label></div>
            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.socialOrganization" required=""
                        name="socialOrganization" type="radio" [value]="socialOrganization.no"
                        (ngModelChange)="validateForm()"> No</label></div>
        </div>
        <div class="form-group col-md-6">
            <label>Respuesta <span class="text-danger text-monospace legend">*</span>

            </label><input class="form-control form-control-solid form-control-lg" id="responseOrganization"
                placeholder="Ej: Iglesia Dios es bueno" type="text" name="responseOrganization" required=""
                [(ngModel)]="data.descriptionSocialOrganization" (ngModelChange)="validateForm()"
                [readonly]="!data.socialOrganization">

        </div>
        <div class="form-group col-md-6">
            <label class="d-block">¿Ha tenido usted alguna enfermedad y/o accidente importante en los últimos 5
                años?<span class="text-danger text-monospace legend"> *</span>

            </label>

            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.disease" required="" name="disease" type="radio"
                        [value]="disease.yes" (ngModelChange)="validateForm()">

                    Sí</label></div>
            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.disease" required="" name="disease" type="radio"
                        [value]="disease.no" (ngModelChange)="validateForm()">
                    No</label></div>

        </div>
        <div class="form-group col-md-6">
            <label>Respuesta <span class="text-danger text-monospace legend">* </span>

            </label><input class="form-control form-control-solid form-control-lg" id="deseaseResponse"
                placeholder="Ej: Accidente de tránsito" type="text" name="deseaseResponse" required=""
                [(ngModel)]="data.descriptionDisease" (ngModelChange)="validateForm()" [readonly]="!data.disease">

        </div>
        <div class="form-group col-md-6">
            <label class="d-block">¿Ha tenido usted alguna implicación o proceso legal en los últimos 5 años?<span
                    class="text-danger text-monospace legend"> *</span>

            </label>

            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.legalProcess" required="" name="legalProcess"
                        type="radio" [value]="legalProcess.yes" (ngModelChange)="validateForm()"> Sí
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.legalProcess" required="" name="legalProcess"
                        type="radio" [value]="legalProcess.no" (ngModelChange)="validateForm()"> No
                </label>
            </div>
        </div>
        <div class="form-group col-md-6">
            <label>Respuesta <span class="text-danger text-monospace legend">* </span>

            </label><input class="form-control form-control-solid form-control-lg" id="legalProcessResponse"
                placeholder="Ej: Demanda" type="text" name="legalProcessResponse" required=""
                [(ngModel)]="data.descriptionLegalProcess" (ngModelChange)="validateForm()"
                [readonly]="!data.legalProcess">

        </div-->

        <!--div class="form-group col-md-6">
            <label class="d-block">¿Tiene usted visa?<span class="text-danger text-monospace legend"> *</span>

            </label>

            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.visa" required="" name="visa" type="radio"
                        [value]="visa.yes" (ngModelChange)="validateForm()" >
                    Sí</label></div>
            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.visa" required="" name="visa" type="radio"
                        [value]="visa.no" (ngModelChange)="validateForm()">
                    No</label></div>
        </div-->
        <!--div class="form-group col-md-6">
            <label>Respuesta<span class="text-danger text-monospace legend">*</span>

            </label><input class="form-control form-control-solid form-control-lg" id="typeVisaResponse"
                placeholder="Ej: Visa Americana" type="text" name="typeVisaResponse" required=""
                [(ngModel)]="data.descriptionVisa" (ngModelChange)="validateForm()" [readonly]="!data.visa">

        </div-->
        <!--div class="form-group col-md-6">
            <label class="d-block">¿Tiene
                usted algún dispositivo móvil?<span class="text-danger text-monospace legend"> *</span>

            </label>
            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.mobileDevice" required="" name="mobileDevice"
                        type="radio" [value]="mobileDevice.yes">
                    Sí</label></div>
            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.mobileDevice" required="" name="mobileDevice"
                        type="radio" [value]="mobileDevice.no">
                    No</label></div>

             <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.mobileDevice" required="" name="mobileDevice"
                        type="radio" [value]="mobileDevice.yes">
                    Sí</label></div>
            <div class="form-check form-check-inline"><label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input " [(ngModel)]="data.mobileDevice" required="" name="mobileDevice"
                        type="radio" [value]="mobileDevice.no"> No</label></div>
        </div-->
        <!--div>
        <hr class="col-md-11">
        <div class="col-md-12">
            <h3 class="bg-primary col-md-12 gutter-b justify-content-between d-flex align-items-center position">
                Posición o Cargo
            </h3>
        </div>
        <div class="form-group col-md-12">
            <label>Posición o cargo que solicita
            </label><input class="form-control form-control-solid form-control-lg" id="positionRequest"
            [(ngModel)]="data.positionToApply"
                placeholder="" type="text" name="positionRequest" required="">
        </div>
        <div class="form-group col-md-12">
            <label>Sueldo al que aspira
            </label><input  mask="separator.2" thousandSeparator="."
            class="form-control form-control-solid form-control-lg" id="salary"
            [(ngModel)]="data.salaryToAspire"
                placeholder="" type="text" name="salary" required="">
        </div>
        <div class="form-group col-md-12">
            <label>¿Algún otro puesto que podría desempeñar?
            </label><input class="form-control form-control-solid form-control-lg" id="position"
            [(ngModel)]="data.anotherPositionToFill"
                placeholder="" type="text" name="position" required="">
        </div>
        </div-->
       
    </div>
</div>