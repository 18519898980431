import { ChangeDetectorRef, Component, Inject, OnInit, AfterViewChecked, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-details-news',
  templateUrl: './details-news.component.html',
  styleUrls: ['./details-news.component.css']
})
export class DetailsNewsComponent implements OnInit, AfterViewChecked {

  employeeDetail: any
  newsDetails: any[] = []
  payrollnewsDetailsOriginal: any[] = []
  paginatedNewsDetails: any[] = []

  filterText: string = '';
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "payrollNewsId", displayName: "Secuencia" },
      { propkey: "firstName", displayName: "Nombre" },
      { propkey: "lastName", displayName: "Apellido" },
      { propkey: "positionName", displayName: "Posición" },
      { propkey: "newsType", displayName: "Tipo de Novedad" },
      { propkey: "newsConcept", displayName: "Concepto de Novedad" },
      { propkey: "amount", displayName: "Cantidad" },
      { propkey: "balance", displayName: "Balance" },
      { propkey: "defaultValue", displayName: "Valor por defecto" },
      { propkey: "manualValue", displayName: "Valor Manual" },
      { propkey: "observation", displayName: "Observación" },
    ];

  
  constructor(public dialogRef: MatDialogRef<DetailsNewsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _payrollService: PayrollService, private _toastService: ToastService,
    private _cdRef: ChangeDetectorRef,
    private _exlService: ExportToExcelService
    ) {
    this.employeeDetail = data.item;
  }

  ngOnInit(): void {
    this.getData()
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  getData() {
    this._payrollService.getPayrollNewsByEmployee(this.employeeDetail.headerId, this.employeeDetail.employeeId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.newsDetails =  this.filterTable(res.dataList);
        this.payrollnewsDetailsOriginal = res.dataList;
      }
    }, err => this._toastService.error(err)
    )
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = this.filterTable(event.records)
  }

  
  filterTable(list: any[]) {
    return list
  }

  getPaginatedRecords(event) {
    this.paginatedNewsDetails = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  exportToExcel() {
    let excelHeaders: string[][] = [[
      "Secuencia",
      "ID de Período",
      "ID de Tipo",
      "Tipo",
      "ID de Condicion",
      "Condición",
      "ID de compañia",
      "Estatus",
      "Fecha de creación",
      "ID de usuario",
      "Fecha de modificación",
      "Usuario que modifica",
      "ID de versión",
      "Período",
      "ID de Área",
      "Área",
      "Fecha límite"
    ]]
    this._exlService.exportToExcelSpecificColumns(this.filteredList.enable ? this.filteredList.records : this.newsDetails, excelHeaders, 'Lista de Servidores Públicos');
  }

  onKeyChange(text) {
    this.filterText = text;
  }
  
}
