import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { RequestMetaData, ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import Swal from 'sweetalert2';
import { IExitListParameter, IExitList, IEditUnlikInfo } from './models/ExitList';
import { environment } from 'src/environments/environment';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { docService } from 'src/app/services/doc.service';
import { DatePipe } from '@angular/common';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { personnelActionTypes } from '../personnel-actions/personnel-actions-tap/personnel-actions-tap.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EvindenceModalComponent } from './evindence-modal/evindence-modal.component';
import { InOutList } from 'src/app/shared/models/InOutList.model';
import * as moment from 'moment';
import { PersonWorkExperience } from 'src/app/shared/models/PersonsWorkExperience';
import { PositionService } from 'src/app/services/position.service';
import { PersonsWorkExperiencesService } from 'src/app/services/persons-work-experiences.service';
import { openReport } from 'src/app/shared/utils/utility';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-unlink-employees-tab',
  templateUrl: './unlink-employees-tab.component.html',
  styleUrls: ['./unlink-employees-tab.component.css']
})
export class UnlinkEmployeesTabComponent implements OnInit, OnChanges {

  arrForm: FormArray
  @Input() personSelected: any;
  @Output() unlinkEmitter = new EventEmitter<boolean>();
  subscriptions: Subscription = new Subscription()
  entryListParameterControl: InOutList[];
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  exitList: any[];
  formChecks: boolean[];
  exitListToggle: InOutList[] = [];
  employeeExitForm: FormGroup = new FormGroup({
    employeeExitList: new FormArray([]),
    exitEvidence: new FormControl(null, Validators.required),
    exitUserId: new FormControl(null),
    exitDate: new FormControl(null, Validators.required),
    exitTypeId: new FormControl(null, Validators.required),
    exitReason: new FormControl(null, Validators.required),
    todayDate: new FormControl(null),
    reasonExitDropdown: new FormControl(null),
    exitTypeDropdown: new FormControl(null),
    comment:new FormControl(''),
    newModificationComment: new FormControl(null),
    newModificationReason: new FormControl(null),
    newModificationReasonId: new FormControl(null),
    oldModificationComment: new FormControl(null),
    oldModificationReason: new FormControl(null)
  });

  guiID = '';
  file = { document: '' }
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  personalFile = personalFile;

  config: any = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  }

  options: any[] = []
  reasonsOptions: any[] = []
  reasonsOptionsOriginal: any[] = []

  showDateError: boolean = false;

  isEditingExitDate: boolean = false;
  storedExitDate: string = '';

  private readonly urlDoc = environment.api_doc + '/Document';
  @Input() isSeviceCommission: boolean = false;
  @Input() isSuspended: boolean = false;

  reasonsConfig = this.getDropdownConfig('stringData');

  modificationReasons: ParameterControl[] = [];

  @ViewChild('uppyFileUnlink')
  uppyFileUnlink: UppyFileComponent | null = null;
  constructor(
    private srvParameter: ParameterControlService,
    private toastr: ToastrService,
    private srvPersonnelActions: PersonnelActionsService,
    private _builder: FormBuilder,
    private sanitizer: DomSanitizer,
    private docService: docService,
    private datePipe: DatePipe,
    private srvPersonnel: PersonnelActionsService,
    private authInfo: AuthInfoService,
    private dialog: MatDialog,
    private srvPosition: PositionService,
    private srvPersonWorkExperience: PersonsWorkExperiencesService,
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.personSelected?.currentValue?.employeeId > 0) {
      if (this.personSelected.status == false || this.isSeviceCommission == true
        || this.isSuspended == true
      ) {
        this.employeeExitForm.disable();
        let buttons: any = document.getElementsByClassName('btn');
        for (let i = 0; i < buttons.length; i++) {
          buttons[i].disabled = true;
        }

        let inputs: any = document.getElementsByTagName("INPUT");
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].disabled = true;
        }
        this.employeeExitForm.get('exitTypeId').setValue(this.personSelected.exitTypeId);
        this.employeeExitForm.get('exitReason').setValue(this.personSelected.exitReason);
        this.employeeExitForm.get('comment').setValue(this.personSelected.comment);
      }
      this.guiID = this.personSelected.exitEvidence;
      this.getParameters();
    }
    if(this.personSelected?.status){
      this.employeeExitForm.get('exitDate').setValue(new Date().toLocaleDateString('fr-CA'));
    }else{
      this.employeeExitForm.get('exitDate').setValue(moment(this.personSelected.exitDate).format('YYYY-MM-DD'));
    }
    this.employeeExitForm.get('todayDate').setValue(new Date().toLocaleDateString('fr-CA'));
  }

  ngOnInit(): void {

  }

  getMinDate(date) {
    if (!date) {
      date = new Date();
    }
    return date.toLocaleDateString('fr-CA');
  }

  validateDateInput() {
    let time1 = new Date(this.employeeExitForm.get('exitDate').value)
    let time2 = new Date(this.employeeExitForm.get('todayDate').value)

    time1.setDate(time1.getDate() + 1);
    time2.setDate(time2.getDate() + 1);

    if (time1 >= time2) {
      this.showDateError = false;
    } else {
      this.showDateError = true;
    }
  }

  // changeOption(value){
  //   this.employeeExitForm.get('exitTypeId').setValue(value.ocode);
  //   this.reasonsOptions = [];
  //   this.employeeExitForm.get('reasonExitDropdown').patchValue(null)
  //   this.employeeExitForm.get('exitReason').patchValue(null);
  //   this.reasonsOptionsOriginal.forEach(ele=>{
  //     if(value.ocode == 1){
  //       ele.ocode == 2 || ele.ocode == 4  ? this.reasonsOptions.push(ele) : null;
  //     }

  //     if(value.ocode == 2){
  //       ele.ocode == 1 || ele.ocode == 6 || ele.ocode == 8 ? this.reasonsOptions.push(ele) : null;
  //     }
  //   });
  // }

  changeReasonOption(value) {
    this.employeeExitForm.get('exitReason').setValue(value?.ocode);
  }

  openReport(employeeId, companyId){
    const reportUrl = `${environment.reportUrl}/?Reportcode=rpt_desvinculation&EmployeeId=${employeeId}&CompanyId=${companyId}`
    let parameters = {
      url: reportUrl,
      title: 'Desvinculación',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }


  changeTypeExitOption(value) {
    this.employeeExitForm.get('exitTypeId').setValue(value?.ocode);
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openGuID(guid) {
    try {
      this.docService.getDocument(guid).subscribe(e => {
        window.open(e.data, '_blank');
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  setGuID(guid) {
    try {
      this.docService.getDocument(guid).subscribe(e => {
        this.file.document = e.data ? e.data : ''
        this.guiID = guid;
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }
  

  markAsTouchedEvidence() {
    this.employeeExitForm.get('exitEvidence').markAsTouched();
  }

  showEvidenceErrors() {
    let field = this.employeeExitForm.get('exitEvidence');
    if (field!.invalid && (field!.dirty || field!.touched)) {
      return 'Este campo es requerido';
    }
    return '';
  }

  openEvidence(item, i) {
    if ((!item.value.evidence && this.personSelected.status == false) || (this.isSeviceCommission == true
      || this.isSuspended == true)) {
      this.toastr.warning('No hay evidencia')
      return
    }
    this.dialog.open(EvindenceModalComponent, {
      width: '30%',
      data: {
        item,
        i
      }
    }).afterClosed().subscribe((result: any) => {
      if (!result) return
      const { i, guid } = result
      const a = this.employeeExitForm.get('employeeExitList') as FormArray
      const item = a.at(i)
      item.get('evidence').patchValue(guid ? guid : null)
    })
  }

  buildExitListForm(ocode: number, employeeId: number, employeeExitListId: number, selected: boolean, observations = '', evidence = null, assignedBy = this.authInfo.getUserId()) {
    return this._builder.group({
      employeeExitListId: [employeeExitListId],
      employeeId: [Number(employeeId)],
      ocode: [ocode, [Validators.required]],
      selected: [selected, [Validators.required]],
      companyId: [this.authInfo.getCompanyId()],
      status: [true],
      observations: observations,
      evidence: evidence,
      assignedBy: assignedBy,
      disableBtn: evidence ? 1 : 2
    });
  }

  pushExitListItems(exitListTypeId: number, employeeId: number, employeeExitListId: number, selected: boolean, observations, evidence, assignedBy) {
    const item = this.employeeExitForm.get('employeeExitList') as FormArray;
    item.push(this.buildExitListForm(exitListTypeId, employeeId, employeeExitListId, selected, observations, evidence, assignedBy));
    if (this.personSelected?.status == false || this.isSeviceCommission == true
      || this.isSuspended == true
    ) {
      this.employeeExitForm.disable();
      item.disable();
    }
  }

  updateExitList() {
    if (this.employeeExitForm.invalid) {
      return;
    }

    if(this.employeeExitForm.get('exitDate').value < this.personSelected?.admissionDate ){
      this.toastr.warning("La fecha salida, debe ser mayor a la fecha de ingreso de servidor publico.")
      return
    }

    let exitList = this.employeeExitForm.get('employeeExitList').value;
    Swal.fire({
      title: "Guardando cambios",
      html: '',
      icon: 'info',
      didOpen: () => {
        Swal.showLoading()
      }
    })

    exitList.forEach(element => {
      element.assignedBy = this.authInfo?.getUserId()
      element.evidence = element.evidence == '' ? null : element.evidence
    });
    this.unlinkEmployee(this.personSelected?.employeeId, this.personSelected?.companyId, exitList);
  }

  employeeExitList(exitList) {
    this.srvPersonnelActions.upsertEmployeeExitList(exitList).subscribe((response: RequestMetaData) => {
      if (!response.succeded) {
        Swal.close()
        this.toastr.error(response.errors[0])
      } else {
        Swal.close()
        this.toastr.success("Servidor Público desvinculado")
        this.personSelected.status = false;
        this.unlinkEmitter.emit(true);
      }
    }, err => {
      Swal.close()
      this.toastr.error(this.errorMessage)
    });
  }

  getParameters() {
    this.srvPersonnelActions.getInOutList().subscribe({
      next: res => {
        if(res.errors.length > 0){
          this.toastr.error(res.errors[0])
          return;
        }
        this.exitList = res.dataList;
        this.getExitLists(this.personSelected?.employeeId)
      }, error: () => {
        this.toastr.error(this.errorMessage);
      }
    })
   
    this.srvParameter.getParameters(keyWord.TypeExit).subscribe((res: any) => {
      if (res.succeded) {
        this.options = res.dataList
        if(!this.personSelected.status){
          const reason = this.options.find(x => x.ocode == this.personSelected.exitTypeId)
          if(reason){
            this.employeeExitForm.get('exitTypeDropdown').patchValue(reason)
          }
        }
      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error(this.errorMessage)
    })

    this.srvParameter.getParameters(keyWord.ReasonExit).subscribe((res: any) => {
      if (res.succeded) {
        this.reasonsOptionsOriginal = res.dataList
        if(!this.personSelected.status){
          const reason = this.reasonsOptionsOriginal.find(x => x.ocode == this.personSelected.exitReason)
          if(reason){
            this.employeeExitForm.get('reasonExitDropdown').patchValue(reason)
          }
        }
      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error(this.errorMessage)
    })

    this.srvParameter.getParameters(keyWord.ReasonsToModifyPersonalAction).subscribe((res: any) => {
      if(!res.succeded){
        res.errors.forEach(err=>{
          this.toastr.error(err);
        })

        res.warnings.forEach(warn=>{
          this.toastr.warning(warn);
        })

        return;
      }

      this.modificationReasons = res.dataList;
    }, err => {
      this.toastr.error('Ha ocurrido un error consultando el listado de motivos de modificacion')
    })


  }
  getExitLists(employeeId) {
    this.srvPersonnelActions.getEmployeeExitListByEmployeeId(employeeId).subscribe((res: ResponseModel<any>) => {
      if (res.succeded) {
        this.exitListToggle = this.exitList;
        this.exitList.forEach((item, i: number) => {
          const element = res.dataList.find(x => x.ocode == item.inOutId)
          item.assignedBy = element?.assignedBy ?? ''
          this.pushExitListItems(item.inOutId, this.personSelected.employeeId, element?.employeeExitListId ?? 0, element?.selected ?? false, element?.observations ?? '', element?.evidence ?? null, element?.assignedBy);
        });
      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error(this.errorMessage)
    })
  }

  unlinkEmployee(employeeId,companyId, exitList) {
    this.uppyFileUnlink.handleFile(guid => {
      let model = {
        exitEvidence: guid,
        exitUserId: this.authInfo.getUserId(),
        exitDate: this.datePipe.transform(this.employeeExitForm.get('exitDate').value, 'MM-dd-yyyy'),
        exitTypeId: this.employeeExitForm.get('exitTypeId').value,
        exitReason: this.employeeExitForm.get('exitReason').value,
        comment: this.employeeExitForm.get('comment').value
      };
  
      this.srvPersonnelActions.unlinkEmployee(employeeId, model.exitTypeId, model.exitReason, model.exitEvidence, model.exitDate, model.exitUserId, model.comment).subscribe((res: any) => {
  
  
        if (!res.succeded) {
  
          res.errors.forEach(err => {
            this.toastr.error(err);
          })
  
          res.warnings.forEach(err => {
            this.toastr.warning(err);
          })
  
          Swal.close()
  
          return;
        }
  
        this.getPositionById()
        this.employeeExitList(exitList);
        this.inactiveEmployeeUser(employeeId,companyId);
      }, err => {
        Swal.close()
        this.toastr.error(this.errorMessage)
      })
    });
  }

  getPositionById(){
    const id = this.personSelected.positionId
    this.srvPosition.getPositionsById(this.authInfo.getCompanyId(), id, true).subscribe({
      next: (res:any) => {
        const data = res.dataList[0]
        const positionFunction = data.activity
        this.saveExperience(positionFunction)
      }, error: () => {
        this.toastr.error('Se ha producido un error inesperado al intentar recuperar la información del puesto del empleado.')
      }
    })
  }

  saveExperience(positionFunction){
    const exitReasonId = this.employeeExitForm.get('reasonExitDropdown').value?.ocode
    const exitDate = this.employeeExitForm.get('exitDate').value
    if(!exitReasonId){
      return
    }
    let model: PersonWorkExperience = {
      companyId: this.authInfo.getCompanyId(),
      company: this.authInfo.getUser().companyName,
      departureDate: exitDate,
      jobTitle: this.personSelected.positionName,
      lastSalary: this.personSelected.salary,
      personId: this.personSelected.personId,
      startDate: this.personSelected.admissionDate,
      role: positionFunction,
      reasonOfExitId: exitReasonId,
      stateEnterprise: true,
      workExperienceId: 0,
      status: true,
    }

    this.srvPersonWorkExperience.saveWorkExperience(model).subscribe({
      next: (res:any) => {
        if(res.errors?.length > 0){
          this.toastr.error(res.errors[0])
          return
        }
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado al guardar la experiencia laboral')
      }
    })
  }


  editUnlinkInfo() {

    if(this.employeeExitForm.controls['newModificationReason']?.errors?.required){
      this.toastr.warning('Debe seleccionar un motivo para la modificación');
      return;
    }

    const employeeId: number = this.personSelected?.employeeId;

    const model: IEditUnlikInfo = {
      employeeId: employeeId,
      exitUserId: this.authInfo.getUserId(),
      exitDate: this.employeeExitForm.get('exitDate').value,
      modificationComment: this.employeeExitForm?.value?.newModificationComment,
      modificationReasonId: this.employeeExitForm?.value?.newModificationReasonId
    };

    if(!model.exitDate){
      this.toastr.warning('Debe seleccionar una fecha valida');
      return;
    }

    this.srvPersonnelActions.editUnlinkInfoEmployee(model).subscribe({
      next: (res) => {

        if (!res.succeded) {

          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(err => {
            this.toastr.warning(err);
          })

          Swal.close()

          return;
        }


        this.toastr.success('Fecha de salida actualizada satisfactoriamente');
        this.storedExitDate = this.employeeExitForm.get('exitDate').value;
        this.disableEditingExitDate();

      },
      error: (err) => {
        Swal.close()
        this.toastr.error(this.errorMessage)
      }
    })
  }

  enableEditingExitDate(){
    this.isEditingExitDate = true;
    this.employeeExitForm.get('exitDate').enable();
    this.storedExitDate = this.employeeExitForm.get('exitDate').value;

    this.employeeExitForm.get('newModificationReason').enable();
    this.employeeExitForm.get('newModificationReasonId').enable();
    this.employeeExitForm.get('newModificationComment').enable();
    this.employeeExitForm.get('newModificationReason').setValidators(Validators.required);
    this.employeeExitForm.get('newModificationReason').updateValueAndValidity();

    this.getAndSetUnlikActionInfo(this.personSelected?.employeeId);
    
  }

  getAndSetUnlikActionInfo(employeeId: number){
    this.srvPersonnelActions.getUnlikInfo(employeeId).subscribe({
      next: (res) => {

        if (!res.succeded) {

          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(err => {
            this.toastr.warning(err);
          })


          return;
        }

        const action = res.dataList[0];

        if(action?.actionsEmployeeId > 0){
          const modificationReason = this.modificationReasons?.find(m => m.ocode == action?.modificationReasonId);
    
          this.employeeExitForm.get('oldModificationComment').setValue(action?.modificationComment);
          this.employeeExitForm.get('oldModificationReason').setValue(modificationReason);
        }

      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error consultando la información de la acción de desvinculación')
      }
    })
  }

  disableEditingExitDate(){
    this.isEditingExitDate = false;
    this.employeeExitForm.get('exitDate').setValue(this.storedExitDate);
    this.employeeExitForm.get('exitDate').disable();

    this.employeeExitForm.get('oldModificationComment').setValue(null);
    this.employeeExitForm.get('oldModificationReason').setValue(null);
    this.employeeExitForm.get('newModificationComment').setValue(null);
    this.employeeExitForm.get('newModificationReason').setValue(null);
    this.employeeExitForm.get('newModificationReasonId').setValue(null);

    this.employeeExitForm.get('newModificationReason').disable();
    this.employeeExitForm.get('newModificationReasonId').disable();
    this.employeeExitForm.get('newModificationComment').disable();

    this.employeeExitForm.get('newModificationReason').clearValidators();
    this.employeeExitForm.get('newModificationReason').updateValueAndValidity();
  }

  inactiveEmployeeUser(employeeId: number, companyId: number){
    this.srvPersonnelActions.inactiveEmployeeUser(employeeId,companyId).subscribe({
      next:(res)=>{
        if (!res.succeded) {

          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(err => {
            this.toastr.warning(err);
          })

          return;
        }

        if(employeeId === this.authInfo.getEmployeeId()){
          this.authInfo.doLogOut();
        }

      },
      error: (err)=>{
        this.toastr.error('Ha ocurrido un error tratando de inactivar el usuario perteneciente a este Servidor Público')
      }
    })
  }

  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  changeModificationReason(value: ParameterControl){
    this.employeeExitForm.get('newModificationReasonId').setValue(value?.ocode);
  }

  setEvidence(event){
    this.employeeExitForm.get('exitEvidence').setValue(event);
  }

}