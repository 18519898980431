<div *ngIf="isLoading; else loaded"
    class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
    <el-spinner (loadingStatus)="isLoading=$event" animation="spinner-logo">
    </el-spinner>
</div>

<ng-template #loaded>
    <div class="card card-custom">
        <div class="card-header mt-3">
            <div class="card-title">
                <div class="ttl d-flex align-items-center">
                    <i class="flaticon-calendario-1 icon-3x text-danger mr-2"></i>
                    <h1 class="text-primary"> Períodos </h1>
                </div>
            </div>
        </div>


        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-6 c-left">
                <div class="card-toolbar d-flex justify-content-start">

                    <div class="col-md-4 mr-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Estatus
                            </label>
                            <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                                [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                                (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-md-4 mr-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Año
                            </label>
                            <ngx-select-dropdown [options]='yearList' [(ngModel)]="currentYear"
                                (change)='getFilteredYear($event.value)' [config]='config'>
                            </ngx-select-dropdown>
                        </div>
                    </div>
                    <button (click)="openDialog(null)"
                        class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                        <i class="flaticon-mas icon-2x"></i> Agregar
                    </button>
                </div>
            </div>

            <div class="col-sm-12 col-md-5 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.exportAction)"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exporta
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                                <i class="fa fa-print icon-2x text-primary mr-3"></i>
                                Visualizar Reporte
                            </a>

                            <!-- <a class="dropdown-item align-items-center" (click)="exportToExcel()">
                                <i class="flaticon-excel icon-2x text-success mr-3"></i>
                                Excel
                            </a>
                            <a class="dropdown-item align-items-center" (click)="onBtnPrintClick()">
                                <i class="flaticon-pdf-file icon-2x mr-2 text-danger"></i>
                                PDF
                            </a> -->
                            <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                            PDF
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a> -->
                        </div>
                    </div>

                    <div class="col text-nowrap">
                        <el-search-bar #searchBar [records]="periods" (onKeyChange)="onKeyChange($event)"
                            [keyValue]="filterText" [itemForSearching]="searchBarDisplayNames"
                            (onChange)="getFilteredList($event)" [itemForSearching]="searchBarDisplayNames">
                        </el-search-bar>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body">

            <div class="table-responsive table-responsive-sigei">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-left">Secuencia</th>
                            <th class="text-left">Descripción</th>
                            <th>Fecha Inicio</th>
                            <th>Fecha Final</th>
                            <th>Fecha Apertura</th>
                            <th>Fecha Cierre</th>
                            <th>Condición</th>
                            <th>Estatus</th>
                            <th class="actionsBtn">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-template [ngIf]="filteredList">

                            <tr *ngFor="let period of paginatedPeriods">
                                <td class="text-left">{{period.payrollPeriodId}}</td>
                                <td class="text-left">{{period.description}}</td>
                                <td class="text-left">{{period.startDate | date: 'dd/MM/yyyy'}}</td>
                                <td class="text-left">{{period.endDate | date: 'dd/MM/yyyy'}}</td>
                                <td class="text-left">{{period.openingDate | date: 'dd/MM/yyyy'}}</td>
                                <td class="text-left">{{period.sendLimitDate | date: 'dd/MM/yyyy'}}</td>
                                <td>
                                    <span
                                        [ngClass]="{'text-success font-weight-bolder ': period.conditionId == 1, 'font-weight-bolder text-danger': period.conditionId == 0}">
                                        {{period.conditionId == 1 ? "Abierto" : "Cerrado"}}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        [ngClass]="{'font-weight-bold text-success': period.status, 'font-weight-bold text-danger': !period.status}">
                                        {{period.status? "Activa" : "Inactiva"}}
                                    </span>
                                </td>

                                <td class="actionsBtn">
                                    <!-- <button class="btn btn-icon btn-light-info btn-sm m-1"
                                        *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.viewAction)"
                                        (click)="openDialog(period)">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver"></i>
                                    </button> -->
                                    <a (click)="openDialog(period)" class="btn btn-icon btn-light-warning btn-sm m-1"
                                        data-toggle="tooltip" title="Editar">
                                        <i class="flaticon-edit"></i>
                                    </a>
                                    <button *ngIf="period.conditionId==0" (click)="openPeriod(period)"
                                        data-toggle="tooltip" title="" class="btn btn-sm btn-light-success btn-icon m-1"
                                        data-original-title="Abrir">
                                        <i class="flaticon-etiqueta icon-2x"></i>
                                    </button>

                                    <button *ngIf="period.conditionId==1" (click)="closePeriod(period)"
                                        data-toggle="tooltip" title="" class="btn btn-sm btn-light-danger btn-icon m-1"
                                        data-original-title="Cerrar">
                                        <i class="ki ki-close"></i>
                                    </button>
                                    <button [disabled]="period.conditionId==1" (click)="deletePeriod(period)"
                                        data-toggle="tooltip" title="" class="btn btn-sm btn-light-danger btn-icon"
                                        data-original-title="Remover">
                                        <i class="flaticon-delete"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
                <div>

                    <el-caption #paginator [originalRecordsLength]="periods.length"
                        [listedRecordsLength]="paginatedPeriods.length"
                        [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :periods.length"
                        [records]="filteredList.enable ? filteredList.records : periods"
                        (onChage)="getPaginatedRecords($event)"></el-caption>

                </div>
            </div>

        </div>

    </div>

</ng-template>

<export-data-to-pdf [title]="'Período'" id="dataToPrint">
    <ng-container thead>
        <th class="text-left">Descripción</th>
        <th>Fecha Inicio</th>
        <th>Fecha Final</th>
        <th>Fecha apertura</th>
        <th>Fecha cierre</th>
        <th>Condición</th>
        <th>Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let period of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.periods"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td class="text-left">{{period.description}}</td>
            <td class="text-left">{{period.startDate | date: 'dd/MM/yyyy'}}</td>
            <td class="text-left">{{period.endDate | date: 'dd/MM/yyyy'}}</td>
            <td class="text-left">{{period.openingDate | date: 'dd/MM/yyyy'}}</td>
            <td class="text-left">{{period.sendLimitDate | date: 'dd/MM/yyyy'}}</td>
            <td>
                <span
                    [ngClass]="{'text-success font-weight-bolder ': period.conditionId == 1, 'font-weight-bolder text-danger': period.conditionId == 0}">
                    {{period.conditionId == 1 ? "Abierto" : "Cerrado"}}
                </span>
            </td>
            <td>
                <span
                    [ngClass]="{'font-weight-bold text-success': period.status, 'font-weight-bold text-danger': !period.status}">
                    {{period.status? "Activa" : "Inactiva"}}
                </span>
            </td>
        </tr>
    </ng-container>
</export-data-to-pdf>
