import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { VacanciesService } from 'src/app/services/vacancies.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { mapArrays } from 'src/app/shared/utils/utility';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {

  dropdownSettings = {
    singleSelection: false,
    idField: 'careerId',
    textField: 'description',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: true,
    limitSelection: -1
  };
  careers: any[] = []
  careersForm = new FormGroup({
    selectedCareers: new FormControl('', Validators.required)
  })

  config ={
    moreText:'más',
    placeholder: 'Seleccione una opción',
    searchPlaceholder: 'Buscar',
    search:true,
  }


  constructor(private pService: PersonalRequsitionService, private _toastService: ToastService, @Inject(MAT_DIALOG_DATA) public data: any,
    private vacanciesService: VacanciesService, public dialogRef: MatDialogRef<CareersComponent>) {
      this.dialogRef.disableClose = true;
     }

  ngOnInit(): void {
    this.pService.getCareers().subscribe(res => {
      this.careers = res.dataList.filter((career:any) => career.status == true)
    },
      err => this._toastService.error(err))

      

    if (this.data.careers.length > 0) {
      this.careersForm.setValue({
        selectedCareers: this.data.careers
      })
      
    }
  }

  Create() {
    //this.vacanciesService.preferibleCareers = this.careersForm.value.selectedCareers
    const selectedCareers = mapArrays(this.careersForm.value.selectedCareers, this.careers, 'careerId')
    const row = { Applied: false, careers: selectedCareers }

    this.dialogRef.close(row);
  }

  Cancel(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

}
