import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PayrollService } from 'src/app/services/payroll.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { Header } from 'src/app/models/header';
import { MatDialog } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { IHeader } from '@payroll/payroll-models';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { PayrollRoyaltyDetailComponent } from './payroll-royalty-detail/payroll-royalty-detail.component';
import { object } from '@amcharts/amcharts5';
import { Location as LocationAngular } from '@angular/common'
import * as moment from 'moment';
import { RoyaltyDetailAddModalComponent } from './royalty-detail-add-modal/royalty-detail-add-modal.component';
import { RoyaltyDetailBulkloadModalComponent } from './royalty-detail-bulkload-modal/royalty-detail-bulkload-modal.component';
import { RoyaltyDetailService } from 'src/app/services/royaltyDetails.service';

declare const $: any;

@Component({
  selector: 'app-payroll-royalty-summary',
  templateUrl: './payroll-royalty-summary.component.html',
  styleUrls: ['./payroll-royalty-summary.component.css']
})
export class PayrollRoyaltySummaryComponent implements OnInit, OnDestroy, AfterViewChecked {

  //incompleto
  public privilege: any = {};
  selectedItems: any;
  sweetAlert = new SweetAlert()
  currentDate = new Date()

  filtervalues:any;
  headerId:number = 0;
  headerYear:number = 0;

  
  registersPerPage: number;
  selectedPage: number;
  royaltySummaryList: any[] = [];
  royaltySummaryListOriginal: any[] = [];
    royaltyDetails = []
  paginatedroyaltySummaryList: any[] = [];
  paginatedRecords = []
  @ViewChild('paginator') paginator: CaptionComponent;
  isLoading:boolean = false


  activeButtons = [];
  page = 1
  pageSize = 20


  propKeyId = 0
  text = ''
  filterText: string = '';
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string, propKeyId?:any }[] =
    [
      {propkey: "personalIdentification", displayName: "Documento de identidad", propKeyId: 1 },
      {propkey: "name", displayName: "Servidor publico", propKeyId: 2 },
      {propkey: "positionName", displayName: "Cargo", propKeyId: 3 },
      {propkey: "departmentName", displayName: "Unidad Organizativa", propKeyId: 4 },
      {propkey: "createUser", displayName: "Usuario Creación", propKeyId: 5 },
    ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' }
  ];


  getFilteredList(event) {
    this.royaltySummaryList = event.records
  }
  
  getPaginatedRecords(event) {
    this.paginatedRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.registersPerPage = event?.registersPerPage;
    this.selectedPage = event?.selectedPage
  }

  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  enableAdd = false
  constructor(    
    private _royaltyDetailService: RoyaltyDetailService,    
    private _toastService: ToastService, 
    private _cdRef: ChangeDetectorRef,
    private _payroll: PayrollService,
    private dialog: MatDialog, 
    public authInfo: AuthInfoService, 
    private route: ActivatedRoute,
    private locations: LocationAngular
  ) {
    this.headerId = route.snapshot.params.id;
  }

  ngOnInit(): void {
    this.privilege = {
      nameKeyModule: 'HRM',
      nameKeyOption: 'PAYROLL-REGALIA',
      rejectAction: { key: 'REJECTPAYROLL', value: false },
      exportAction: { key: 'EXPORTPAYROLL', value: false },
      viewAction: { key: 'VIEWPAY', value: false },
      openAction: { key: 'OPENPAYROLL', value: false },
      dashboardAction: { key: 'DASHBOARDPAY', value: false },
    };

    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.getData()
        this.getHeader()
      }
    })
  }

  getHeader(){
    this._payroll.getHeaderByOptions({type: 2, companyId: this.authInfo.getCompanyId(), headerId: this.headerId}).subscribe({
      next: (res:any) => {
        if(res.errors.length>0){this._toastService.error(res.errors[0]); return;}
        if(res.dataList.length<1){return;}
        const conditionId = res.dataList[0].header.conditionId
        this.enableAdd = conditionId === 0
      }, error: (error:any) => {
        this._toastService.error("Ha ocurrido un error inesperado")
      }
    })
  }

  getData() {
    this.getRoyaltySummaryList();
    this._royaltyDetailService.getRoyaltyDetail(this.headerId).subscribe({
      next: (res:any) => {
        if(res.errors.length > 0){this._toastService.error(res.errors[0]);return;}
        let periods = []
        for (let i = 0; i < res.dataList.length; i++) {
          const element = res.dataList[i];
          const period_month = element.periodMonth
          
          if(!periods.find(x => period_month == x.periodMonth)){
            periods.push({
              period:element.period,
              periodMonth: period_month,
              employeeId: element.employeeId,
              data: [element]
            })
          }else{
            const periodF = periods.find(x => x.periodMonth == period_month)
            periodF.data.push(element)
          }
        }
        periods.sort((a, b) => a.periodMonth - b.periodMonth)
        this.royaltyDetails = periods

      }, error: () => {this._toastService.error('Ha ocurrido un error inesperado')}
    })
  }

  openDetailsModal(header: IHeader) {
    this.dialog.open(PayrollRoyaltyDetailComponent, {
      width: '50%',
      data: { headerId: header.headerId }
    }).afterClosed().subscribe((result: any) => {
      if (result?.saved) {        
      }
    })
  }

  openModalAddNew() {
    this.dialog.open(RoyaltyDetailAddModalComponent, {
      data: {
        headerId: this.headerId
      },
    })
      .afterClosed()
      .subscribe((result) => {
          
        this.getData()      
        
      });
  }

  openModalBulkloadNews() {
    this.dialog.open(RoyaltyDetailBulkloadModalComponent, {
      width: '70%',
      data: {
        headerId: this.headerId
      }
    })
      .afterClosed()
      .subscribe((result) => {
         
          this.getData()
        
      });
  }

  getRoyaltySummaryList() {
    this._royaltyDetailService.getRoyaltySummary(this.headerId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }            
      this.royaltySummaryListOriginal = res.dataList
      this.royaltySummaryList = [...this.royaltySummaryListOriginal] ;
      this.headerYear = moment(res.dataList[0]?.createDate)?.startOf('date').year();
    })
  }

  showDetail(item){
    const d = JSON.parse(JSON.stringify(this.royaltyDetails))
    for (let i = 0; i < d.length; i++) {
      const element = d[i];
      element.data = element.data.filter(c => c.employeeId == item.employeeId)
    }
    this.dialog.open(PayrollRoyaltyDetailComponent, {
      data: {item, details:d},
      width: '60%'
    })
  }


  filterTable(list: any[]) {
    return list.filter((pl: Header) => pl.status == true);
  }

  getRouterToBack(){
    this.locations.back();
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  setFilterToLocalStorage() {
    localStorage.setItem('royalty-summary_filter', JSON.stringify(this.selectedItems));
  }

  showModal(modalName: string) {
    $(`#${modalName}`).modal('show');
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  onKeyParameterChange(event){
    this.filtervalues = event;
  }

}
