<div class="card card-custom">
    <!-- <div class="card-header mt-3">
        <div class="card-title d-flex justify-content-between">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-reunion icon-3x text-danger mr-2"></i>
                <h1 class="text-primary"> Vacaciones Colectivas </h1>
            </div>
            <a [routerLink]="getRouterToBack()" class="btn btn-pill font-weight-bolder btn-shadow btn-warning  ">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
              </a>
        </div>
    </div> -->

    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-reunion icon-3x text-danger mr-2"></i>
                <h1 class="text-primary"> Vacaciones Colectivas </h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Volver Atras">
            <a [routerLink]="getRouterToBack()" class="btn btn-pill font-weight-bolder btn-shadow btn-warning  ">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
              </a>
        </div>
    </div>
    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
            <div class="card-toolbar d-flex justify-content-start">

                <!-- <div class="col-md-4 mr-2 px-0 pt-1"> -->
                <!-- <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Estatus
                            </label>
                            <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                                [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                                (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                        </div> -->
                <!-- </div> -->
                <button *ngIf="privilege?.add?.value && useCollectiveVacations"
                    class="btn btn-success btn-pill font-weight-bolder mt-9 col-md-auto"
                    (click)="openModalAddVacations()">
                    <i class="flaticon-mas icon-2x" data-toggle="tooltip"
                        data-original-title="Agregar Vacación Colectiva"></i>
                    Agregar
                </button>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12"
                    *ngIf="privilege?.export?.value && useCollectiveVacations">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="openPdf()">
                            <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                            Visualizar Reporte
                        </a>
                        <!-- <a class="dropdown-item disabled" href="#">
                                <i class="flaticon-word icon-2x text-primary mr-3"></i>
                                Word
                            </a>
                            <a class="dropdown-item disabled" href="#">
                                <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                                PDF
                            </a>
                            <a class="dropdown-item disabled" href="#">
                                <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                                CSV
                            </a> -->
                    </div>
                </div>

                <div class="col text-nowrap" *ngIf="privilege?.search?.value && useCollectiveVacations">
                    <app-search-bar-pagination (onFilter)="filterSearch($event)"
                     [parameters]="searchBarDisplayNames"
                     [namePageLocalStorage]="localStorageSearch"
                     ></app-search-bar-pagination>
            </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-vertical-center caption-top">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th class="text-left">Cantidad de Servidores</th>
                        <th class="text-left">Fecha Inicio</th>
                        <th class="text-left">Fecha Término</th>
                        <th class="text-left">Años al que Pertenece</th>
                        <th class="text-left">Total Días</th>
                        <th class="text-left">Comentario</th>
                        <th class="text-left">Condición</th>
                        <th class="text-center">Acciones</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let vacation of vacationsList">
                        <td class="text-center">{{vacation?.collectiveVacationsHeaderId}}</td>
                        <td class="text-left">{{vacation.quantityEmployees}}</td>
                        <td class="text-left">{{vacation?.startDate |date: 'dd/MM/yyyy' || 'N/A'}}</td>
                        <td class="text-left">{{vacation?.endDate |date: 'dd/MM/yyyy' || 'N/A'}}</td>
                        <td class="text-left">{{vacation?.year || 'N/A'}}</td>
                        <td class="text-left">{{vacation?.totalDays || 'N/A'}}</td>
                        <td class="text-left">{{vacation?.comment || 'N/A'}}</td>
                        <td class="text-left">
                            <span
                                [ngClass]="vacation.conditionId == 2 ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{vacation.condition}}</span>
                        </td>
                        <td class="actionsBtn">
                            <button
                                *ngIf="vacation.conditionId !== 2 && privilege?.edit?.value"
                                class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip"
                                (click)="openModalAddVacations(false, true, vacation)" title="Editar">
                                <i class="flaticon-edit"></i>
                            </button>
                            <button *ngIf="privilege?.view?.value"
                                data-toggle="tooltip" (click)="openModalAddVacations(true, false, vacation)" title=""
                                class="btn btn-sm btn-light-info btn-icon m-1">
                                <i class="flaticon-ver" data-toggle="tooltip" data-original-title="Ver"></i>
                            </button>
                            <button *ngIf="privilege?.manage?.value"
                                data-toggle="tooltip" (click)="openVacationDetail(vacation)" title=""
                                class="btn btn-sm btn-light-success btn-icon m-1">
                                <i class="flaticon-lista-de-verificacion-3 icon-2x" data-toggle="tooltip"
                                    data-original-title="Administrar"></i>
                            </button>
                            <button
                                *ngIf="vacation.conditionId == 1 && privilege?.apply?.value"
                                data-toggle="tooltip" (click)="applyVacation(vacation)" title=""
                                class="btn btn-sm btn-light-success btn-icon">
                                <i class="flaticon-etiqueta icon-2x" data-toggle="tooltip"
                                    data-original-title="Aplicar"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <app-paginator
                [listedRecordsLength]="vacationsList?.length"
                [registerQuantity]="vacationsPagedResut.totalItems"
                [registerPerPage]="vacationsPagedResut.pageSize"
                [totalPages]="vacationsPagedResut.totalPages"
                [selectedPage]="vacationsPagedResut.page"
                (onChange)="getPaginatedRecords($event)">
            </app-paginator>
            </div>
        </div>
    </div>
</div>

<export-data-to-pdf [title]="'Vacaciones Colectivas'" id="dataToPrint">
    <ng-container thead>
        <th class="text-center">Secuencia</th>
        <th class="text-left">Cantidad de Servidoress</th>
        <th class="text-left">Fecha Inicio</th>
        <th class="text-left">Fecha Término</th>
        <th class="text-left">Años al que pertenece</th>
        <th class="text-left">Total Días</th>
        <th class="text-left">Comentario</th>
        <th class="text-left">Condición</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let item of this.vacationsList"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td class="text-center">{{item?.collectiveVacationsHeaderId}}</td>
            <td class="text-left">{{item.quantityEmployees}}</td>
            <td class="text-left">{{item?.startDate |date: 'dd/MM/yyyy' || 'N/A'}}</td>
            <td class="text-left">{{item?.endDate |date: 'dd/MM/yyyy' || 'N/A'}}</td>
            <td class="text-left">{{item?.year || 'N/A'}}</td>
            <td class="text-left">{{item?.totalDays || 'N/A'}}</td>
            <td class="text-left">{{item?.comment || 'N/A'}}</td>
            <td class="text-left">
                <span
                    [ngClass]="item.conditionId == 2 ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{item.condition}}</span>
            </td>

        </tr>
    </ng-container>
</export-data-to-pdf>
