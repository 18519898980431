<!-- Modal -->
<div class="modal fade modal-z-index" [id]="target" tabindex="-1" role="dialog" data-backdrop="static"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" [class]="addSize(size)" role="document">
    <div class="modal-content dv-border-radious-1">
      <div *ngIf="hasHeader" class="modal-header border-0 dv-table-header-1 bg-primary">
        <h2 class="font-size-h2 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>{{ title }}</h2>
        <button type="button" [disabled]="isLoading" class="close" aria-label="Close" (click)="close()">
          <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
      </div>
      <div class="modal-body" style="max-height: 80vh; overflow-y: auto;">
        <ng-content></ng-content>
      </div>
       
      <div *ngIf="hasFooter" class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          <i class="flaticon-cancel"></i>
          Close
        </button>
        <button type="button" class="btn btn-success"> <i class="flaticon-upload"></i> Guardar</button>
      </div>
    </div>
  </div>
</div>