import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SearchModalComponent } from '../../proof-applicants/search-modal/search-modal.component';
import { ModalPersonnelActionsComponent } from './modal-personnel-actions/modal-personnel-actions.component';
import { IEmployeePermissions } from './models/EmployeePermissions';
import { IVacancy } from './models/Vacancy';

@Component({
  selector: 'app-personnel-actions',
  templateUrl: './personnel-actions.component.html',
  styleUrls: ['./personnel-actions.component.css']
})
export class PersonnelActionsComponent implements OnInit, OnDestroy {
  vacancyId: number = 0;
  personSelected: any;
  vacancySelected: any;
  isEditingVacancy: boolean = false;
  isInDetailsVacancy: boolean = false;
  subscriptions: Subscription = new Subscription();
  vacancy: IVacancy;
  employeePermissions: IEmployeePermissions;
  vacationForm: FormGroup;
  permissionForm: FormGroup;
  employeeDataForm: FormGroup;
  vacationFormInvalid: boolean = false;
  employeeId: number = 0;
  employeeNumber: number = 0;
  FilterOptions: any[] = [
    {ocode: -1, stringData: "Seleccionar"}
  ];
  permissionFormInvalid: boolean = false;
  workHistoryData: any[] = [];
  vacationData: IVacancy[] = [];
  licenseForm: FormGroup;
  licenseFormInvalid: boolean = false;

  constructor(
    private fBuilder : FormBuilder,
    private toastr: ToastrService,
    private parameterControlService: ParameterControlService,
    private _http: HttpClient,
    private dialog: MatDialog,
    private personnelActionsService: PersonnelActionsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authInfo: AuthInfoService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.builEmployeeDataForm();
    this.buildVacationForm();
    this.buildPermissionForm();
    this.buildLicenseForm();
    this.activatedRoute.params.subscribe((params:any) => {
      if(isNaN(params.id)){
        this.router.navigate(['/personnel-actions-employee-list'])
        return;
      }
      this.employeeNumber = params.id;
      this.getEmployeeByNumber(this.employeeNumber)
      this.getVacationTablesData();
    })
    this.disablePermissionDays()
  }

  builEmployeeDataForm(){
    this.employeeDataForm = this.fBuilder.group({
      employeeName: '',
      employeeNumber: '',
      employeeType: '',
      department: '',
      position: ''
    })
  }

  getEmployeeByNumber(employNumber){
    this.personnelActionsService.getEmployeeByNumber(employNumber).subscribe((res:any) => {
      if(res.errors.length > 0){
        this.toastr.error("Ha ocurrido un error al obtener los datos del Servidor Público")
        return;
      }
      this.personSelected = res.dataList[0];

      if(this.personSelected.status == false){
        this.employeeDataForm.disable();
        this.permissionForm.disable();
        this.licenseForm.disable();
        this.vacationForm.disable();

        var buttons:any = document.getElementsByClassName('btn');
        for(let i = 0; i < buttons.length; i++){
          buttons[i].disabled = true; 
        }

        let inputs:any = document.getElementsByTagName("INPUT");
        for(let i = 0; i < inputs.length; i++){
          inputs[i].disabled = true; 
        }
      }


      this.employeeId = this.personSelected.employeeId;
      this.employeeDataForm.get('employeeName').setValue(this.personSelected.name)
      this.employeeDataForm.get('employeeNumber').setValue(this.personSelected.employNumber)
      this.employeeDataForm.get('employeeType').setValue(this.personSelected.employeeType)
      this.employeeDataForm.get('department').setValue(this.personSelected.departmentName)
      this.employeeDataForm.get('position').setValue(this.personSelected.positionName)
    }, error => {
      this.toastr.error("Error al buscar el Servidor Público")
      this.router.navigate(['/personnel-actions-employee-list'])
      return;
    })
  }

  disablePermissionDays(disable = true){ /* Esta función se utiliza para desabilitar los campos de dias de la semana en permisos */
    if(disable){
      this.permissionForm.get('monday').disable();
      this.permissionForm.get('tuesday').disable();
      this.permissionForm.get('wednesday').disable();
      this.permissionForm.get('thursday').disable();
      this.permissionForm.get('friday').disable();
      this.permissionForm.get('saturday').disable();
      this.permissionForm.get('sunday').disable();
    }else{
      this.permissionForm.get('monday').enable();
      this.permissionForm.get('tuesday').enable();
      this.permissionForm.get('wednesday').enable();
      this.permissionForm.get('thursday').enable();
      this.permissionForm.get('friday').enable();
      this.permissionForm.get('saturday').enable();
      this.permissionForm.get('sunday').enable();
    }


  }


  //#region
  buildLicenseForm(){
    this.licenseForm = this.fBuilder.group({
      reason:       ['', Validators.required],
      comment:      ['', Validators.required],
      applicationDate:  [new Date().toISOString().substring(0, 10), Validators.required],
      dateStart:    ['', Validators.required],
      dateEnd:      ['', Validators.required],
      idTypePermissions:  [0],
      evidence:     [''],
    })
  }
  showReasonLicenseErrors(){
    let field = this.licenseForm.get('reason');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showCommentLicenseErrors(){
    let field = this.licenseForm.get('comment');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showApplicationDateLicenseErrors(){
    let field = this.licenseForm.get('applicationDate');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showDateStartLicenseErrors(){
    let field = this.licenseForm.get('dateStart');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showDateEndLicenseErrors(){
    let field = this.licenseForm.get('dateEnd');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    if(field.hasError('higherDate')){
      return 'La fecha de término no puede ser menor a la fecha de inicio';
    }
    return '';
  }
  //#endregion

  saveEmployeeLicense(){
    if(this.licenseForm.get('dateStart').value > this.licenseForm.get('dateEnd').value){
      this.licenseForm.get('dateEnd').setErrors({'higherDate': true});
    }else{
        delete this.licenseForm.get('dateEnd').errors?.higherDate;
    }

    if(this.licenseForm.invalid){
      this.toastr.warning("Debe llenar todos los campos", "Aviso")
      this.licenseFormInvalid = true;
      return;
    }
    this.licenseFormInvalid = false;
    this.employeePermissions = {
      employeeId: this.employeeId,
      ...this.licenseForm.value,
      companyId: this.authInfo.getCompanyId(),
      status: true,
      typePermissionsId: 3,
      evidence: null
    }
    let saveEmployeeLicenseSub = this.personnelActionsService.saveEmployeePermission(this.employeePermissions).subscribe((res:any) => {
      //console.log(res);
      this.toastr.success("Registro guardado exitosamente", "Registro guardado")
      this.employeePermissions = null;
      this.buildLicenseForm();
    }, error => {
      this.toastr.error("Error al guardar los datos", "Error inesperado")
    })
    this.subscriptions.add(saveEmployeeLicenseSub)
  }

  // Inicio de pantalla de permisos

  savePermission(){
    if(this.employeeId == 0){
      this.toastr.warning("Debe seleccionar un Servidor Público","Aviso")
      return;
    }
    if(this.permissionForm.get('dateStart').value > this.permissionForm.get('dateEnd').value){
      this.permissionForm.get('dateEnd').setErrors({'higherDate': true});
    }else{
        delete this.permissionForm.get('dateEnd').errors?.higherDate;
    }
    if(this.permissionForm.invalid){
      this.permissionFormInvalid = true;
      this.toastr.warning("Debe llenar todos los campos", "Aviso")
      return;
    }
    this.permissionFormInvalid = false;

    if(this.permissionForm.get('idTypePermissions').value == "2"){
      if(!this.permissionForm.get('monday').value && !this.permissionForm.get('tuesday').value
      && !this.permissionForm.get('wednesday').value && !this.permissionForm.get('thursday').value
      && !this.permissionForm.get('friday').value && !this.permissionForm.get('saturday').value){
        this.toastr.warning("Debe seleccionar almenos un dia de la semana")
        return;
      }
    }
    this.employeePermissions = {
      employeeId: this.employeeId,
      companyId: this.authInfo.getCompanyId(),
      status: true,
      ...this.permissionForm.value,
      evidence: null,
      typePermissionsId: Number(this.permissionForm.get('idTypePermissions').value)
    }
    //console.log(this.permissionForm.value);

    let saveEmployeePermissionSub = this.personnelActionsService.saveEmployeePermission(this.employeePermissions).subscribe((res:any) => {
      if (!res.succeded) {    
        if (res.errors.length > 0) {
          this.toastr.error(res.errors[0])
        } else if(res.warnings.length > 0) {
          this.toastr.warning(res.warnings[0])
        }
      }
      this.toastr.success("Se ha registrado el permiso correctamente", "Registro guardado")
      this.buildPermissionForm();

    }, (error) => {
      this.toastr.error("Error al guardar el registro", "Error inesperado")
    })
    this.subscriptions.add(saveEmployeePermissionSub)
  }


  buildPermissionForm(){
    this.permissionForm = this.fBuilder.group({
      reason:       ['', Validators.required],
      comment:      ['', Validators.required],
      applicationDate:  [new Date().toISOString().substring(0, 10), Validators.required],
      dateStart:    ['', Validators.required],
      dateEnd:      ['', Validators.required],
      hourStart:    ['', Validators.required],
      hourEnd:      ['', Validators.required],
      idTypePermissions:  [0, Validators.min(1)],
      monday:       [false],
      tuesday:      [false],
      wednesday:    [false],
      thursday:     [false],
      friday:       [false],
      saturday:     [false],
      sunday:       [false],
      evidence:     ['']

    })
    this.permissionForm.get('idTypePermissions').valueChanges.subscribe((res:any) => {
      if(this.permissionForm.get('idTypePermissions').value == "2"){
        this.disablePermissionDays(false)
      }else{
        this.disablePermissionDays()
        this.permissionForm.get('monday').setValue(false)
        this.permissionForm.get('tuesday').setValue(false)
        this.permissionForm.get('wednesday').setValue(false)
        this.permissionForm.get('thursday').setValue(false)
        this.permissionForm.get('friday').setValue(false)
        this.permissionForm.get('saturday').setValue(false)
        this.permissionForm.get('sunday').setValue(false)
      }

    })

  }
  //#region
  showReasonErrors(){
    let field = this.permissionForm.get('reason');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showHourEndErrors(){
    let field = this.permissionForm.get('hourEnd');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showidTypePermissionsErrors(){
    let field = this.permissionForm.get('idTypePermissions');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    if(field.hasError('min')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showCommentErrors(){
    let field = this.permissionForm.get('comment');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showHourStartErrors(){
    let field = this.permissionForm.get('hourStart');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showApplicationDateErrors(){
    let field = this.permissionForm.get('applicationDate');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showDateStartErrorsPermission(){
    let field = this.permissionForm.get('dateStart');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showDateEndErrorsPermission(){
    let field = this.permissionForm.get('dateEnd');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    if(field.hasError('higherDate')){
      return 'La fecha de término no puede ser menor a la fecha de inicio';
    }
    return '';
  }
  //#endregion

  // Aqui termina la pantalla de permisos

  // VACACIONES


  buildVacationForm(){
    this.vacationForm = this.fBuilder.group({
      applicationDate:  [new Date().toISOString().substring(0, 10)],
      dateStart:        ['', Validators.required],
      dateEnd:          ['', Validators.required],
      year:             ['', Validators.required],
      days:             ['', Validators.required],
      bond:             ["false"]
    });

  }
  resetVacationForm(){
    this.vacationForm.enable()
    this.vacationForm.patchValue({
      applicationDate:  new Date().toISOString().substring(0, 10),
      dateStart:        '',
      dateEnd:          '',
      year:             '',
      days:             '',
    });
  }

  // llena las dos tablas que estan en vacaciones
  getVacationTablesData(){
    let workExperienceSub = this.personnelActionsService.getPersonsWorkExperiences(this.personSelected.personId).subscribe((res: any) => {
      this.workHistoryData = (res.dataList)? res.dataList: [];
      //console.log(res.dataList);

    }, (error) => {
      this.toastr.error("Error al listar el historial laboral", "Error inesperado");
    })
    this.subscriptions.add(workExperienceSub)

    let employeeVacanciesSub = this.personnelActionsService.getEmployeeVacancies(this.employeeNumber).subscribe((res:any) => {
      this.vacationData = (res.dataList != null && res.dataList.length>0)? res.dataList: [];
    }, (error) => {
      this.toastr.error("Error al listar las vacaciones", "Error inesperado");
    })
    this.subscriptions.add(employeeVacanciesSub)

  }
  clearVacancyForm(){
    this.changeDisableVacancyForm(false)
    this.resetVacationForm();
    this.isEditingVacancy=false;
    this.isInDetailsVacancy=false;
    this.vacancySelected = null;
  }
  getVacancy(item){
    this.personnelActionsService.getVancacyById(item.vacancyId).subscribe((res:any) => {
      //console.log(res);
      if(res.dataList.length < 1){
        this.toastr.error("Error al obtener los detalles de la vacaciones")
        return;
      }
      this.vacancySelected = res.dataList[0];
      this.vacationForm.get('dateStart').patchValue(formatDate(this.vacancySelected.dateStart, "yyyy-MM-dd", "en"));
      this.vacationForm.get('dateEnd').patchValue(formatDate(this.vacancySelected.dateEnd, "yyyy-MM-dd", "en"));
      this.vacationForm.get('year').patchValue(this.vacancySelected.year);
      this.vacationForm.get('days').patchValue(this.vacancySelected.days);
      this.vacationForm.get('applicationDate').patchValue(formatDate(this.vacancySelected.applicationDate, "yyyy-MM-dd", "en"));
    }, error => {
      this.toastr.error("Error al obtener los detalles de la vacaciones")
    })
  }
  showVacancy(item){
    this.isEditingVacancy = false;
    this.isInDetailsVacancy = true;
    this.getVacancy(item)
    this.changeDisableVacancyForm();
  }
  changeDisableVacancyForm(disable: boolean = true){
    if(disable){
      this.vacationForm.get('dateStart').disable()
      this.vacationForm.get('dateEnd').disable()
      this.vacationForm.get('year').disable()
      this.vacationForm.get('days').disable()
      this.vacationForm.get('applicationDate').disable()
    }else{
      this.vacationForm.get('dateStart').enable()
      this.vacationForm.get('dateEnd').enable()
      this.vacationForm.get('year').enable()
      this.vacationForm.get('days').enable()
      this.vacationForm.get('applicationDate').enable()
    }
  }

  editVacancy(item){
    this.changeDisableVacancyForm(false);
    this.isEditingVacancy = true;
    this.isInDetailsVacancy = false;
    this.getVacancy(item)
  }



  updateVacancy(){
    if(this.vacancyFormHasError()){
      return;
    }
    this.vacancy = {
      employeeId: this.employeeId,
      companyId: this.authInfo.getCompanyId(),
      status: true,
      ...this.vacationForm.value,
      vacancyId: this.vacancySelected.vacancyId,
      bond: (this.vacationForm.get('bond').value === 'true')? true: false
    }

    this.personnelActionsService.updateVacancy(this.vacancy).subscribe((res:any) => {
      this.toastr.success("Registro modificado exitosamente")
      this.resetVacationForm()
      this.getVacationTablesData()
      this.vacancyId = 0;
      this.isEditingVacancy = false;
    }, error => {
      this.toastr.error("Error al modificar el registro")
    })
  }

  vacancyFormHasError(){
    if(this.vacationForm.get('dateStart').value > this.vacationForm.get('dateEnd').value){
      this.vacationForm.get('dateEnd').setErrors({'higherDate': true});
    }else{
        delete this.vacationForm.get('dateEnd').errors?.higherDate;
    }
    this.vacationFormInvalid = this.vacationForm.invalid;
    if(this.vacationFormInvalid){
      return true;
    }
    return false;
  }


  submitVacationForm(){
    if(this.isEditingVacancy){
      this.updateVacancy();
    }else{
      this.saveVacancy();
    }
  }
  saveVacancy(){
    if(this.vacancyFormHasError()){
      return;
    }
    let vacancy : IVacancy = {
      employeeId: this.employeeId,
      companyId: this.authInfo.getCompanyId(),
      status: true,
      ...this.vacationForm.value,
      vacancyId: this.vacancyId
    }

    let saveVacancySub = this.personnelActionsService.saveVacancy(vacancy).subscribe((res:any) => {

      if(res.errors.length > 0){
        this.toastr.error("Error al guardar el registro", "Error inesperado");
        return;
      }
      else if(res.warnings.length > 0) {
        this.toastr.warning(res.warnings[0])
        return
      }
      this.toastr.success("Registro guardado con exito", "Registro guardado")
      this.resetVacationForm()
      this.getVacationTablesData();
    }, (error) => {
      this.toastr.error("Error al guardar las vacaciones", "Error inesperado");

    })
    this.subscriptions.add(saveVacancySub)
  }

  removeVacation(item){

    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      title: "¿Está seguro que desea eliminar este registro?",
      icon: "question",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar"
    }).then(result => {
      if(result.isConfirmed){
        let deleteVacancySub = this.personnelActionsService.deleteVacancy(item.vacancyId).subscribe((res: any) => {
          this.toastr.success("Se ha eliminado el registro")
          this.getVacationTablesData()
        }, (error) => {
          this.toastr.error("Error al eliminar el registro", "Error inesperado")
        });
        this.subscriptions.add(deleteVacancySub)
      }
    })
  }
  //#region
  showDateStartErrors(){
    let field = this.vacationForm.get('dateStart');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showDaysErrors(){
    let field = this.vacationForm.get('days');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  showDateEndErrors(){
    let field = this.vacationForm.get('dateEnd');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    if(field.hasError('higherDate')){
      return 'La fecha de término no puede ser menor a la fecha de inicio';
    }
    return '';
  }
  showYearErrors(){
    let field = this.vacationForm.get('year');
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  //#endregion

}
