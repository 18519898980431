import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InterviewEvaluationService } from 'src/app/services/interview-evaluation.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-applied-vacancy-modal',
  templateUrl: './applied-vacancy-modal.component.html',
  styleUrls: ['./applied-vacancy-modal.component.css']
})
export class AppliedVacancyModalComponent implements OnInit {

  modalForm: FormGroup;
  YesOrNot = [{ value: true, text: 'SI' }, { value: false, text: 'NO' }];
  datePipe = new DatePipe('en-Us');

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AppliedVacancyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private interviewEvaluationService: InterviewEvaluationService,
    private toastr: ToastService,
  ) {
    this.dialogRef.disableClose = true;
    this.buildForm()
  }

  ngOnInit(): void {
    this.getPostulationById()
    this.modalForm.disable()
  }

  getPostulationById() {
    this.interviewEvaluationService.getPostulationById(this.data.interviewId, this.data.companyId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastr.error("Error al buscar la postulación")
        return;
      }
      this.setModalFormData(res.dataList[0])
    }, error => {
      this.toastr.error("Error al buscar la postulación")
    })
  }

  setModalFormData(data: any) {
    this.modalForm.patchValue({
      positionName: data.positionName,
      requisitionType: data.vacancyType,
      dateApplication: this.datePipe.transform(data.dateApplication, 'yyyy-MM-dd'),
      desiredSalary: data.desiredSalary,
      startDate: this.datePipe.transform(data.startDate, 'yyyy-MM-dd'),
      hoursAvailableName: data.hoursAvailableName,
      employee: data.employee,
      toTravel: data.toTravel,
      transfer: data.transfer,
      authorizedData: data.authorizedData,
      comment: data.comment
    })
  }

  buildForm() {
    this.modalForm = this.formBuilder.group({
      positionName: '',
      requisitionType: '',
      dateApplication: '',
      desiredSalary: '',
      hoursAvailableName: '',
      startDate: '',
      employee: false,
      toTravel: false,
      transfer: false,
      authorizedData: false,
      comment: ''
    })
  }

}
