import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class ProofApplicantsService {

  api = environment.api_url;
  constructor(private http: HttpClient,private authInfo: AuthInfoService) { }

  getTypeTests(companyId){
    return this.http.get(this.api + `/PostulationTypeTest?CompanyId=${companyId}&Status=true`)
  }


  getPostulationVacants(){
    return this.http.get(this.api + '/PersonsPostulationVacants')
  }

  getPostulationsList(type:number = 0,condition:number = 0){
    //type 0 get all
    //type 1 get by condition
    return this.http.get(this.api + `/PersonsPostulationVacants/GetPostulation?type=${type}&condition=${condition}&IdCompany=${this.authInfo.getCompanyId()}`);
  }

  savePostulation(postulation):Observable<any>{
    return this.http.post(this.api + '/PostulationTests', postulation)
  }

  getPaginatedPostulations(page?: number, pageSize?: number, PropkeyId = null, SearchParameter = null, status = 1, condition?: string) {
    return this.http.get(this.api + `/PersonsPostulationVacants/GetPaginated?CompanyId=${this.authInfo.getCompanyId()}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Status=${status}&Conditions=${condition}&Page=${page}&PageSize=${pageSize}&Type=1`);
  }

  getPostulationsTestsByPPVID(idPostulationTest, companyId: number):Observable<any>{
    return this.http.get(this.api + `/PostulationTests?Type=1&IdPostulationTest=${idPostulationTest}&companyId=${companyId}`)
  }

  getPostulationTestById(id){
    return this.http.get(`${this.api}/PostulationTests?type=2&idPostulationTest=${id}`)
  }

  getPersonVacantByPPVID(ppvid: number, companyId: number){
    return this.http.get(`${this.api}/PersonsPostulationVacants?idppv=${ppvid}&companyId=${companyId}`)
  }

  deleteTest(data:any){
    return this.http.put(this.api + '/PostulationTests/deletePostulationTests', data);
  }
}
