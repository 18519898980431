import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { CompaniesService } from 'src/app/services/companies.service';
import { docService } from 'src/app/services/doc.service';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { personalFile } from 'src/app/shared/utils/parameterDoc';
import { environment } from 'src/environments/environment';
import { PersonalRequisitionsModel } from '../models/personal-requisitions';
import { Location } from '@angular/common';
import { DepartmentService } from 'src/app/services/department.service';
import { Departments } from 'src/app/models/departments.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
type GUID = string & { isGuid: true };


@Component({
  selector: 'app-staff-requirement-summary',
  templateUrl: './staff-requirement-summary.component.html',
  styleUrls: ['./staff-requirement-summary.component.css']
})


export class StaffRequirementSummaryComponent implements OnInit {
  public  parameters = {
    companyId: this.authInfo.getCompanyId(),
    parentId: 0,
  }
  isLoading: Boolean;
  requisition: PersonalRequisitionsModel
  replacements: any[] = []
  company: any
  expirationDate: Date;

  readonly urlDoc = environment.api_doc + '/Document';

  personalFile = personalFile;
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  file = { logo: '', image1: '', image2:'' }

  logoUppy = new Uppy({
    restrictions: {
      maxFileSize: 1000000,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['image/*', 'video/*']
    },
    autoProceed: true,
    locale:Mexico
  });

  params: any;
  departmentInfo: Departments
  rrhhDepartmentInfo: Departments

  constructor(private _route: ActivatedRoute, private _requisitionSrv: PersonalRequsitionService, private _toastService: ToastService,
    private _companiesService: CompaniesService, private srvDoc: docService, private sanitizer: DomSanitizer, private location: Location,
    private _departmentService: DepartmentService, private _companiestService: CompaniesService, private authInfo: AuthInfoService
  ) {
    this.params = this._route.snapshot.params
  }

  ngOnInit(): void {
    this.getPersonalRequisition()
  }
  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }
  getPersonalRequisition() {
    this._requisitionSrv.geOnetPersonalRequisition(this.params.id, this.params.status, this.params.condition, this.params.companyId).subscribe((res: any) => {
      this.requisition = res.singleData.personalRequisition[0] ?? []
      this.replacements = res.singleData.viewGetReplacing ?? []
      this.companyById(this.requisition.companyId)
      if (this.requisition) {
        this.expirationDate = new Date(this.requisition.requestedDate);
        this.expirationDate.setMonth(this.expirationDate.getMonth() + 3);
        this.getMainDepartment()
      }
    })
  }

  getMainDepartment() {
    this._departmentService.getParentSectionById(this.parameters.companyId, this.parameters.parentId).subscribe((res: any) => {
      this.departmentInfo = res.dataList[0]
      this.getRRHHDepartment(this.departmentInfo)
    })
  }

  getRRHHDepartment(mainDepartment:Departments) {
    if ( mainDepartment == undefined) {
      return
    }

    this._companiesService.getDepartmentsChildren(mainDepartment.companyId, mainDepartment.departmentId).then((res:any) => {
      if (res.succeded) {
        this.rrhhDepartmentInfo = res.dataList.find((dp) => dp.isHhrr == true)
      }
      else return
    }).catch((err) => {
      this._toastService.error('Sucedio un error tratando de obetener el responsable de la unidad organizativa de Recursos humanos, por favor comunicarse con el administrador')
    });
  }

  goBack() {
    this.location.back();
  }


  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }


  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


}
