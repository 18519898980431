import { Component, OnInit, AfterViewChecked, ChangeDetectorRef, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PayrollNewDetailComponent } from '@payroll/manage-payroll/payroll-new-detail/payroll-new-detail.component';
import { ToastrService } from 'ngx-toastr';
import { Header } from 'src/app/models/header';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { DetailsNewsComponent } from './details-news/details-news.component';
import { Location as LocationAngular } from '@angular/common'
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ExcludeEmployeePayrollModalComponent } from '@payroll/modals/exclude-employee-payroll-modal/exclude-employee-payroll-modal.component';
import { PayrollConditions } from 'src/app/shared/enums/PayrollConditions';

declare const $: any;
@Component({
  selector: 'app-payroll-detail-view',
  templateUrl: './payroll-detail-view.component.html',
  styleUrls: ['./payroll-detail-view.component.css']
})
export class PayrollDetailViewComponent implements OnInit, AfterViewChecked, OnDestroy, AfterViewInit {
  public loading: boolean = false
  payrollId: number
  payrollDetails: any[] = []
  payrollDetailsOriginal: any[] = []
  paginatedPayrollDetails: any[] = []
  payrollSelected: any
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'ViewPayrollDetail',
    Exclude: {key: 'Exclude', value: false },
    ViewNews: {key: 'ViewNews', value: false },
  }
  filterText: string = '';
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "employeeId", displayName: "Secuencia" },
      { propkey: "name", displayName: "Servidor Público" },
      { propkey: "personalIdentification", displayName: "Identificación" },
      { propkey: "departmentName", displayName: "Unidad Organizativa"},
      { propkey: "sex", displayName: "Sexo" },
      // { propkey: "employeeType", displayName: "Estatus" },
      { propkey: "grossSalary", displayName: "Ingreso Bruto" },
      { propkey: "totalSalary", displayName: "Total Ingreso" },
      { propkey: "afp", displayName: "AFP" },
      { propkey: "sfs", displayName: "SFS" },
      { propkey: "isr", displayName: "ISR" },
      { propkey: "otherDiscounts", displayName: "Otros Descuentos" },
      { propkey: "totalDiscount", displayName: "Total Descuentos" },
      { propkey: "netIncome", displayName: "Neto" },
    ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' }
  ];
  showExcludeBtn = false
  @ViewChild('searchBar') searchBar: SearchBarComponent;

  constructor(private _payrollService: PayrollService, private route: ActivatedRoute,
    private locations: LocationAngular,
    private _exlService: ExportToExcelService, private _cdRef: ChangeDetectorRef, private _toastService: ToastrService,
    private dialog: MatDialog,
    private auth: AuthInfoService,
  ) {
    this.payrollId = route.snapshot.params.id;
  }

  ngAfterViewInit(): void {
    this.searchBar?.setParameterSearch("personalIdentification");
  }

  ngOnInit(): void {
    this.auth.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result=>{
      if(result == true){
        this.getPayrollDetails();

      }
    })
    this.privilege = this.auth.setPrivileges(this.privilege)

  }

  showNews(item: any, IsView = false): void {
    this.dialog.open(PayrollNewDetailComponent, {
      data: {
        item: item,
        IsView: IsView
      },
      width: '100%'
    })
  }

  removeEmployee(item){
    if (item?.conditionId === 3 || item?.conditionId === 5) {
      return
    }
    this.dialog.open(ExcludeEmployeePayrollModalComponent, {
      data: {item},
      width: 'auto'
    }).afterClosed().subscribe({
      next: (res:any) => {
        if(res.success){
          this.getPayrollDetails()
        }
      }

    })
  }

  getRouterToBack(){
    this.locations.back();
  }

  getFilteredList(event) {
    event.records = [...this.getGroupOverallSummaries(this.filterTable(event.records))];
    this.filteredList = event;
  }


  filterTable(list: any[]) {
    return list.filter((pl: Header) => pl.status == true);
  }

  getPayrollDetails() {
    this._payrollService.getPayrollDetails(this.payrollId).subscribe((res: any) => {
      const payrolls =  (res.dataList as any[]).map((e: any) => {
        let employee = {
          ...e,
          fullName: `${e?.firstName} ${e?.lastName}`
        }
        return employee;
      })
      this.payrollDetails = [...this.getGroupOverallSummaries(payrolls)]

      this.payrollDetailsOriginal =  [...payrolls];
    })
    this.getHeaderById()

  }

  getHeaderById(){
    this._payrollService.getHeaderbyId(this.payrollId).subscribe({
      next: (res: any) => {
        if(res.errors.length > 0){

        }
        let excludeBtnCondition = [PayrollConditions.rejected, PayrollConditions.definitive]
        this.payrollSelected = res.dataList[0]
        if(!excludeBtnCondition.some(x => x == this.payrollSelected.conditionId)){
          this.showExcludeBtn = true
        }
      }, error: (error: any) => {

      }
    })
  }
  getPaginatedRecords(event) {
    this.paginatedPayrollDetails = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  exportToExcel() {
    let excelHeaders: string[][] = [[
      "Secuencia",
      "ID de Período",
      "ID de Tipo",
      "Tipo",
      "ID de Condición",
      "Condición",
      "ID de compañia",
      "Estatus",
      "Fecha de creación",
      "ID de usuario",
      "Fecha de modificación",
      "Usuario que modifica",
      "ID de versión",
      "Período",
      "ID de Área",
      "Área",
      "Fecha límite"
    ]]
    this._exlService.exportToExcelSpecificColumns(this.filteredList.enable ? this.filteredList.records : this.payrollDetails, excelHeaders, 'Lista de Servidores Públicos');
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }


  onKeyChange(text) {
    this.filterText = text;
  }

  private getGroupOverallSummaries(viewGetOverallSummaries: any[]){
    let overallSummaries: any[] = [];
    let departmentsSummaries = this.groupBy(viewGetOverallSummaries, (overall: { departmentId: number; }) =>  overall?.departmentId);
    departmentsSummaries.forEach(department => {
       overallSummaries.push({
        departmentName: this.getValues(department)[0]?.departmentName,
        departments: this.getValues(department)?.sort((departmentA: any, departmentB: any) => {
          return  departmentB?.grossSalary  - departmentA?.grossSalary
        })
      });
    })
    overallSummaries = overallSummaries?.sort((departmentGroupA: any, departmentGroupB: any) => {
      return departmentGroupA?.departments[0]?.order - departmentGroupB?.departments[0]?.order
    })
    overallSummaries.forEach(d => {
      d.grossSalary = this.getSubTotalOverallSummaries(d?.departments, 'grossSalary');
      d.totalSalary = this.getSubTotalOverallSummaries(d?.departments, 'totalSalary');
      d.otherCredits = this.getSubTotalOverallSummaries(d?.departments, 'otherCredits');
      d.afp = this.getSubTotalOverallSummaries(d?.departments, 'afp');
      d.isr = this.getSubTotalOverallSummaries(d?.departments, 'isr');
      d.sfs = this.getSubTotalOverallSummaries(d?.departments, 'sfs');
      d.otherDiscounts = this.getSubTotalOverallSummaries(d?.departments, 'otherDiscounts');
      d.totalDiscount = this.getSubTotalOverallSummaries(d?.departments, 'totalDiscount');
      d.netIncome = this.getSubTotalOverallSummaries(d?.departments, 'netIncome');
    })
    return overallSummaries;
  }

  private getSubTotalOverallSummaries(list: any[], typeTotal: string){
    return list?.reduce((previous, current) => previous + current[typeTotal], 0)
  }

  private getValues(item) {
    return Object.values(item) as any[];
  }

  private groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  getTotalOverallSummarie(total) {
    const viewGetOverallSummaries = this.filteredList?.enable ? this.filteredList.records :
      this.payrollDetails;
    const totalOverallSummaries = viewGetOverallSummaries?.reduce((previous, current) => previous + current[total], 0);
    return totalOverallSummaries;
  }


}
