import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CollectiveVations, VacationDetail } from '../models/collective-vations';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class CollectiveVacationsService {
  private readonly api = environment.api_url

  constructor(private http: HttpClient,
    private _authInfo: AuthInfoService,
  ) { }

  // getCollectiveVacations() {
  //   return this.http.get(`${this.api}/CollectiveVacationsHeader?CompanyId=${this._authInfo.getCompanyId()}&Status=true`);
  // }

  consultCollectiveVacations({page, pageSize, PropkeyId, SearchParameter, status = -1, startDate, endDate}) {
    return this.http.get(`${this.api}/CollectiveVacationsHeader/GetPaginated?CompanyId=${this._authInfo.getCompanyId()}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Status=${status}&Page=${page}&PageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&Type=1
    `);
  }

  getPaginatedCollectiveVacations(page?: number, pageSize?: number, PropkeyId = null, SearchParameter = null, status = -1) {
    return this.http.get(`${this.api}/CollectiveVacationsHeader/GetPaginated?CompanyId=${this._authInfo.getCompanyId()}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Status=${status}&Page=${page}&PageSize=${pageSize}&Type=1`);
  }

  createCollectiveVacation(vacation: CollectiveVations) {
    return this.http.post(`${this.api}/CollectiveVacationsHeader`, vacation);
  }

  applyCollectiveVacation(vacation) {
    return this.http.post(`${this.api}/CollectiveVacationsHeader/Apply`, vacation);
  }

  updateCollectiveVacation(vacation: CollectiveVations) {
    return this.http.put(`${this.api}/CollectiveVacationsHeader`, vacation);
  }

  getVacationDetals(collectiveVacationsHeaderId: number) {
    return this.http.get(`${this.api}/CollectiveVacationsDetail?CollectiveVacationsHeaderId=${collectiveVacationsHeaderId}&CompanyId=${this._authInfo.getCompanyId()}&Type=1`);
  }

  getPaginatedVacationDetals( collectiveVacationsHeaderId: number, page?: number, pageSize?: number, PropkeyId = null, SearchParameter = null, status = -1, condition?:string) {
    return this.http.get(`${this.api}/CollectiveVacationsDetail/GetPaginated?CompanyId=${this._authInfo.getCompanyId()}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&CollectiveVacationsHeaderId=${collectiveVacationsHeaderId}&Status=${status}&Conditions=${condition}&Page=${page}&PageSize=${pageSize}&Type=1`);
  }

  updateDetail(vacationDetail: VacationDetail) {
    return this.http.put(`${this.api}/CollectiveVacationsDetail`, vacationDetail);
  }

  filterByDate(startDate: string, endDate: string) {
    return this.http.get(`${this.api}/CollectiveVacationsHeader?StartDate=${startDate}&EndDate=${endDate}&CompanyId=${this._authInfo.getCompanyId()}&Type=2`);
  }


}
