import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import Uppy from "@uppy/core";
import { ToastrService } from "ngx-toastr";
import { Observable, Subscription } from "rxjs";
import { AuthInfoService } from "src/app/services/auth-info.service";
import { CompaniesService } from "src/app/services/companies.service";
import { docService } from "src/app/services/doc.service";
import { PayrollFlyerService } from "src/app/services/payroll-flyer.service";
import { RequestMetaData, ResponseModel } from "src/app/shared/models/strongly-typed-response.model";
import { environment } from "src/environments/environment";
import { NewsAppliedType } from "../models/news-applied-type.enum";
import { PayrollFlyerGetTypes } from "../models/payroll-flyer-get-type.enum";
import { EmployeeDebitCredit, NewsApplied, PayrollVoucher } from "../models/payroll-voucher.model";
import { Location as LocationAngular } from '@angular/common';
import { PayrollFlyer } from "../models/payroll-flyer.model";

type GUID = string & { isGuid: true };

@Component({
    selector: 'app-result-payroll-voucher',
    templateUrl: './pdf-payment-multiple voucher.component.html',
    styleUrls: ['./pdf-payment-multiple voucher.component.css']
})
export class PdfPaymentMultipleVoucherComponent implements OnInit, OnDestroy {

    subscriptions: Subscription = new Subscription();
    selectedPeriod: any;
    selectedArea: any;
    selectedPayrollType: any;
    profilePhotoUrl: string = "";

    company: any;

    file = { logo: '', image1: '', image2: '' }

    selectedFlyeres: PayrollFlyer[] = [];

    payrollVoucheresData: PayrollVoucher[] = [];

    today!: Date;

    employeeProfilePhoto: { profilePhotoUrl: string, employeeId: number }[] = [];

    constructor(
        private _payrollFlyerService: PayrollFlyerService,
        private srvDoc: docService,
        private _companiesService: CompaniesService,
        private authInfo: AuthInfoService,
        public locations: LocationAngular,
        private _route: ActivatedRoute,
        private _toastService: ToastrService,
        private router: Router,
    ) {

        this.today = new Date();
    }

    ngOnInit(): void {
        if (localStorage?.getItem('paymentFlyersPrintData')) {

            const printData = JSON.parse(localStorage?.getItem('paymentFlyersPrintData'));

            if (printData?.data) {

                this.selectedFlyeres = printData?.data as PayrollFlyer[];
                this.selectedPeriod = printData?.arguments?.selectedPeriod;
                this.selectedArea = printData?.arguments?.selectedArea;
                this.selectedPayrollType = printData?.data[0]?.payrollType;

                const paymentFlyer = {
                    paymentId: '',
                    paymentType: '',
                    headerId: this.selectedFlyeres[0].headerId,
                    payrollPeriodId: this.selectedPeriod.payrollPeriodId,
                    budgetAreaId: this.selectedArea?.budgetAreaId,
                    employee: this.selectedFlyeres.map(f => {
                        return {
                            employeeId: f.employeeId
                        };
                    }),
                    type: 0,
                    companyId: this.selectedFlyeres[0]?.companyId,
                    status: true
                }

                this._payrollFlyerService.getPaymentFlyer(paymentFlyer).subscribe({
                    next: (res) => {

                        if (!res.succeded) {

                            res.errors.forEach(err => {
                                this._toastService.error(err);
                            });

                            return;
                        }


                        const vouchersList = res.dataList.map((voucherData) => {
                            return {
                                ...voucherData,
                                flyerData: this.getPayrollFlyerByEmployeeId(voucherData.employee.employeeId)
                            }
                        });

                        this.payrollVoucheresData = vouchersList;

                        this.getProfilePhoto(vouchersList);


                    },
                    error: (err) => {
                        this._toastService.error('Ha ocurrido un error tratando de consultar la información de los volantes solicitados');
                    }
                });

            } else {
                this.router.navigate(['/payroll/payment-flyer']);
            }
        } else {
            this.router.navigate(['/payroll/payment-flyer']);
        }
        this.companyById(this.authInfo.getCompanyId());
    }

    locationsBack() {
        const printData = localStorage?.getItem('paymentFlyersPrintData');
        if (printData) {
            localStorage?.setItem('paymentFlyersPrintData', JSON.stringify({ ...JSON.parse(printData), returned: true }));
        }
        this.locations.back();
    }

    private companyById(companyId: number) {
        this._companiesService.getCompanyById(companyId).subscribe({
            next: (res) => {

                if (!res.succeded) {

                    res.errors.forEach(err => {
                        this._toastService.error(err);
                    });

                    res.warnings.forEach(warn=>{
                        this._toastService.warning(warn);
                    })

                    return;
                }

                this.company = res.dataList[0]
                this.setGuids(this.company)

            },
            error: (err) => {
                this._toastService.error('Ha ocurrido un error tratando de consultar los datos de la institución');
            }
        })
    }

    private setGuids(company) {
        try {
            this.srvDoc.getDocument(company.logo).subscribe(e => {
                this.file.logo = e.data
            }, error => {
                this._toastService.error(error)
            })
        } catch (error) {
            this._toastService.error(error)
        }

    }

    private getProfilePhoto(payrollVoucher: PayrollVoucher[]) {
        payrollVoucher.forEach(p => {


            if (p?.employee?.profileImage) {
                this.srvDoc.getDocument(p?.employee?.profileImage).subscribe(e => {

                    p.photo = e.data ? e.data : 'assets/images/no-user.png';

                    // this.employeeProfilePhoto = [...this.employeeProfilePhoto, {
                    //     profilePhotoUrl: e.data ? e.data : 'assets/images/no-user.png',
                    //     employeeId: p?.employee?.employeeId
                    // }]


                }, error => {

                    p.photo = 'assets/images/no-user.png';

                    // this.employeeProfilePhoto = [...this.employeeProfilePhoto, {
                    //     profilePhotoUrl: 'assets/images/no-user.png',
                    //     employeeId: p?.employee?.employeeId
                    // }]


                })
            }




        })
    }

    private getGuid(guid: string): GUID {
        return guid as GUID; // maybe add validation that the parameter is an actual guid ?
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getPayrollFlyerByEmployeeId(employeeId: number): PayrollFlyer | null | undefined {
        return this.selectedFlyeres.find(fly => fly.employeeId === employeeId);
    }

    private getProfilePhotoByEmployeeId(employeeId) {
        return this.employeeProfilePhoto?.find(p => p?.employeeId === employeeId);
    }

}
