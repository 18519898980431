import { Pipe, PipeTransform } from "@angular/core";
import { DateUtil, NumberUtil } from "../utils/utility";

@Pipe({
  name: "format"
})
export class FormatPipe implements PipeTransform {
  public transform(value: any, ...args: any[]): any {
    if (value == null) {
      return "";
    }

    let type: string | null = args?.length > 0 ? args[0] : null;

    if (type === "date") {
      let isUtc = args[1];
      let outFormat = args[2];

      return DateUtil.formatDate(value, isUtc, outFormat);
    }

    if (type === "time") {
      let outFormat = args[1];

      return DateUtil.formatTime(value, outFormat);
    }

    if (type === "datetime") {
      let isUtc = args[1];
      let outFormat = args[2];

      return DateUtil.formatDateTime(value, isUtc, outFormat);
    }

    // Although it's a shared pipe, it's only being used within the training module, so it doesn't affect the others.
    if (type === "percent" || type === "integer" || type === "decimal") {
      return NumberUtil.format(value, type);
    }

    return value;
  }
}
