import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IHttpClient } from './Ihttp-client';
import { candidate, personalInformation, personeEmail, personPhone } from 'src/app/shared/models/candidate-registration.model';
import { AuthInfoService } from '../auth-info.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { UpdateCode } from 'src/app/shared/models/updateCode.model';

@Injectable({
  providedIn: 'root',
})

export class PersonService {
  private readonly urlApi = environment.api_core_url
  private readonly url_Api_hrm = environment.api_url
  private readonly url_Api_map = environment.api_map

  private readonly api_lobby = environment.api_lobby;

  constructor(private _http: HttpClient, private authInfo: AuthInfoService) { }
  route = '/ParameterControl/GetKeyWord';

  getPersonById(idPerson: number): Observable<any> {
    let par = new HttpParams();

    par = par.set("type", "3")
    par = par.set("idPerson", idPerson.toString())

    return this._http.get<any>(`${this.urlApi}/Persons?companyId=${this.authInfo.getCompanyId()}`, { params: par });
  }

  getPersonByType(type: string, identificacion: string, personId: number) {
    return this._http.get<any>(`${this.urlApi}/Persons?Type=${type}&Person=${identificacion}&IdPerson=${personId}&companyId=${this.authInfo.getCompanyId()}`);
  }

  postPersonIdentificationImage(personId: number, guid) {
    let body = {
      idPerson: personId,
      type: 1,
      document: guid
    }
    return this._http.post(`${this.urlApi}/Persons/AddDocument`, body)
  }
  postDriveLicenseImage(personId: number, guid) {
    let body = {
      idPerson: personId,
      type: 2,
      document: guid
    }
    return this._http.post(`${this.urlApi}/Persons/AddDocument`, body)
  }
  postMiscellaneousImage(personId: number, guid) {
    let body = {
      idPerson: personId,
      type: 3,
      document: guid
    }
    return this._http.post(`${this.urlApi}/Persons/AddDocument`, body)
  }
  postFingerImage(personId: number, guid) {
    let body = {
      idPerson: personId,
      type: 4,
      document: guid
    }
    return this._http.post(`${this.urlApi}/Persons/AddDocument`, body)
  }
  deletePersonIdentificationImage(personId) {
    return this._http.delete(`${this.urlApi}/Persons/DeleteDocument?IdPerson=${personId}&Type=1`)
  }
  deleteDriveLicenseImage(personId) {
    return this._http.delete(`${this.urlApi}/Persons/DeleteDocument?IdPerson=${personId}&Type=2`)
  }
  deleteMiscellaneousImage(personId) {
    return this._http.delete(`${this.urlApi}/Persons/DeleteDocument?IdPerson=${personId}&Type=3`)
  }
  deleteFingerImage(personId) {
    return this._http.delete(`${this.urlApi}/Persons/DeleteDocument?IdPerson=${personId}&Type=4`)
  }

  deleteDocument(personId, type: number) {
    return this._http.delete(`${this.urlApi}/Persons/DeleteDocument?IdPerson=${personId}&Type=${type}`)
  }

  getPersonAll(type: string): Observable<any> {
    let par = new HttpParams();

    par = par.set("type", type)

    return this._http.get<any>(`${this.urlApi}/Persons?companyId=${this.authInfo.getCompanyId()}`, { params: par });
  }
  getPersonAllPaginated(page, pageSize, propKeyId, search, status): Observable<any> {
    return this._http.get<any>(`${this.urlApi}/Persons/GetPaginated?PropkeyId=${propKeyId}&SearchParameter=${search}&Status=${status}&Page=${page}&PageSize=${pageSize}&Type=1&CompanyId=${this.authInfo.getCompanyId()}`);
  }


  deletePerson(data:any) {
    return this._http.put<any>(`${this.urlApi}/PersonWizard/`, data);
  }

  activatePerson(id: number) {
    return this._http.delete<any>(`${this.urlApi}/Persons/Active/?IdPerson=${id}`);
  }

  postPerson(person: personalInformation): Observable<any> {
    return this._http.post<any>(`${this.urlApi}/Persons`, person);
  }

  getEmployeeDataByPersonId(personId) {
    return this._http.get(`${this.url_Api_hrm}/employees?type=3&employeeNumber=${personId}&companyId=${this.authInfo.getCompanyId()}`)
  }

  getEmployeeDataByEmployeeId(employeeId) {
    return this._http.get<ResponseModel<any>>(`${this.url_Api_hrm}/employees?type=5&employeeNumber=${employeeId}&companyId=${this.authInfo.getCompanyId()}`)
  }

  getEmployeeBenefit(IdEcb){
    return this._http.get(`${this.url_Api_hrm}/EmployeesContractBenefit?Type=2&IdEcb=${IdEcb}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  deleteEmployeeBenefit(IdEcb){
    return this._http.delete(`${this.url_Api_hrm}/EmployeesContractBenefit?IdEcb=${IdEcb}`);
  }

  postEmployeeBenefit(postEmployeeBenefit: any){
    return this._http.post(`${this.url_Api_hrm}/EmployeesContractBenefit`, postEmployeeBenefit);
  }

  deleteAllEmployeeBenefit(EmployeeId: number){
    return this._http.delete(`${this.url_Api_hrm}/EmployeesContractBenefit/DeleteAll?EmployeeId=${EmployeeId}`);
  }

  UpdateDataEmployee(data) {
    return this._http.post(`${this.url_Api_hrm}/employees/UpdateDataEmployee`, data)
  }

  getPersonWizard(idPerson: number): Observable<any> {
    return this._http.get<any>(`${this.urlApi}/PersonWizard/${idPerson}?&CompanyId=${this.authInfo.getCompanyId()}`);
  }

  postPersonWizard(person: any): Observable<any> {
    return this._http.post<any>(`${this.urlApi}/PersonWizard`, person);
  }

  putPerson(person: any): Observable<any> {
    return this._http.put<any>(`${this.urlApi}/Persons`, person);
  }

  postPersonPhone(phones: personPhone[]): Observable<any> {
    return this._http.post<any>(`${this.urlApi}/PersonPhone/postList`, phones);
  }

  postPersonEmail(emails: personeEmail[]): Observable<any> {
    return this._http.post<any>(`${this.urlApi}/PersonsEmails/postList`, emails);
  }

  getCarrers(carrerid: number = 0): Observable<any> {
    return this._http.get<any>(`${this.url_Api_hrm}/Careers?IdCareers=0&companyId=${this.authInfo.getCompanyId()}&Status=true`);
  }

  getPersonSkills(IdPerson: number = 0): Observable<any> {
    return this._http.get<any>(`${this.urlApi}/PersonsSkills?IdPerson=${IdPerson}&companyId=${this.authInfo.getCompanyId()}`);
  }

  deleteReletionship(data:any): Observable<any> {
    return this._http.put<any>(`${this.urlApi}/FamilyPersonInInstitution/DeleteFamilyPersonsInInstitution`, data);
  }

  deleteRecomendations(id): Observable<any> {
    return this._http.delete<any>(`${this.urlApi}/PersonRecomendation?Id=${id}`);
  }

  deleteFamilyData(data:any): Observable<any> {
    return this._http.put<any>(`${this.urlApi}/PersonsDependents/DeletePersonDepend`, data);
  }

  deleteAcademicData(id): Observable<any> {
    return this._http.delete<any>(`${this.urlApi}/PersonsAppliedStudies?IdPersonAppliedStudies=${id}`);
  }

  deleteJobReference(id): Observable<any> {
    return this._http.delete<any>(`${this.urlApi}/PersonJobReference?IdPersonJobReferences=${id}`);
  }

  deletePersonalReference(data:any): Observable<any> {
    return this._http.put<any>(`${this.urlApi}/PersonalReferences/DeletePersonalReferences`, data);
  }

  deleteWorkExp(id): Observable<any> {
    return this._http.delete<any>(`${this.urlApi}/PersonsWorkExperiences?IdWorkExperience=${id}`);
  }

  getObjetionProfile(ppvid: number, companyId: number): Observable<any> {
    return this._http.get<any>(`${this.url_Api_hrm}/PersonsPostulationVacants/GetObjetionProfile?IdPpv=${ppvid}&CompanyId=${companyId}`)
  }

  getObjectionProfileReject(PPVId, companyId) {
    return this._http.get<any>(`${this.url_Api_hrm}/Reject?RejectId=${PPVId}&Type=2&CompanyId=${companyId}&Status=true`);
  }

  getHeaderByEmployee(employeeChangeHeaderId: number) {
    return this._http.get<any>(`${this.url_Api_hrm}/EmployeeChangeHeader?EmployeeChangeHeaderId=${employeeChangeHeaderId}&Type=1&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }

  getEmployeeChangeDetails(idEmployee: number) {
    return this._http.get<any>(`${this.url_Api_hrm}/EmployeeChangeDetails?IdEmployeeChangeDetails=${idEmployee}&type=2&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }

  updateEmployeeNumber(updateCode: UpdateCode){
    return this._http.post<ResponseModel<any>>(`${this.api_lobby}/User/update-code`, updateCode);
  }

  getEmployeeBankAccountHistory(EmployeeId:any){
    return this._http.get<ResponseModel<any>>(`${this.url_Api_hrm}/BankAccountHistory?EmployeeId=${EmployeeId}`)
  }



}
