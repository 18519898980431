<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"> <span class="h-20px label label-danger label-dot mr-3 w-20px"></span> Dashboard
        de Servidores Públicos</h2>
</div>

<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <div class="d-flex justify-content-between align-items-end mb-3 row">
            <div class="text-left col-md-8 col-lg-9">
                <h3>Filtrado</h3>
                <div class="text-left d-flex flex-wrap">
                    <h4 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                            class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||'
                            '}}</span></h4>
                    <h4 class="d-flex font-weight-bolder text-primary">Hasta: <span
                            class="text-dark font-weight-lighter ">{{filterParams.date2|date:'dd/MM/yyyy'||'
                            '}}</span></h4>
                </div>
            </div>
            <div class="d-flex flex-nowrap justify-content-between col-12 col-md-3 col-lg-3">
                <button class="btn btn-primary me-2 w-50" (click)="filterByDate()">
                    <i class="fas fa-filter" data-toggle="tooltip" data-title="Filtrar"></i>
                </button>
                <button class="btn btn-light-warning ms-2 w-50" (click)="clearFilter()">
                    <i class="fas fa-redo" data-toggle="tooltip" data-title="Reiniciar filtros"></i>
                </button>
            </div>
        </div>

        <div class="card bg-white p-5 gutter-b">
            <div class="row">
                <div class=" col-12 col-lg-6">
                    <div class="d-flex align-items-center bg-light-warning rounded p-5 mb-5 mb-xxl-0 ">
                        <span class="svg-icon svg-icon-warning svg-icon-4x mr-5">
                            <span class="svg-icon-r svg-icon-primary svg-icon-2x">
                                <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Text/Bullet-list.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path
                                            d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z"
                                            fill="#000000" />
                                        <path
                                            d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z"
                                            fill="#000000" opacity="0.3" />
                                    </g>
                                </svg>
                                <!--end::Svg Icon-->
                            </span>
                        </span>
                        <div class="d-flex flex-column flex-grow-1 mr-2">
                            <h3 class="font-weight-bold text-dark-75 font-size-r">Total Activos</h3>
                        </div>
                        <span class="font-weight-bolder text-warning py-1 font-size-r">
                            {{totalEmployees}}
                        </span>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="d-flex align-items-center bg-light-danger rounded p-5">
                        <span class="svg-icon svg-icon-danger svg-icon-4x mr-5">
                            <span class="svg-icon-r svg-icon-primary svg-icon-2x">
                                <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Code/Error-circle.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                        <path
                                            d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"
                                            fill="#000000" />
                                    </g>
                                </svg>
                                <!--end::Svg Icon-->
                            </span>
                        </span>
                        <!--begin::Title-->
                        <div class="d-flex flex-column flex-grow-1 mr-2">
                            <h3 class="font-weight-bold text-dark-75 font-size-r">Total Inactivos</h3>
                        </div>
                        <span class="font-weight-bolder text-danger py-1 font-size-r">
                            {{inactiveEmployees}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card card-custom bg-white">
                    <div class="card-header p-2 mt-2 d-flex justify-content-between">
                        <h3 class="ms-3 font-weight-bold text-dark-75 py-3">Por Unidad Organizativa</h3>
                        <ngx-select-dropdown 
                        [multiple]='true' 
                        (change)="changeDepartment()" 
                        [options]="departments" 
                        [(ngModel)]="department" 
                        [config]="organizationalDropdownSetting"
                         class="col-12 col-xxl-4"></ngx-select-dropdown> 
                    </div>
                    <div class="card-body p-3">
                        <!-- <apx-chart [series]="barOptions2?.series" [chart]="barOptions2?.chart"
                            [dataLabels]="barOptions2?.dataLabels" [plotOptions]="barOptions2?.plotOptions"
                            [yaxis]="barOptions2?.yaxis" [legend]="barOptions2?.legend" [fill]="barOptions2?.fill"
                            [stroke]="barOptions2?.stroke" [tooltip]="barOptions2?.tooltip"
                            [xaxis]="barOptions2?.xaxis">
                        </apx-chart> -->
                        <app-bar-chart-race 
                    [autoHeight]="true" [id]="'chartsource1'" 
                    [data]="employeesByDepartment"></app-bar-chart-race>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Nombramientos en los últimos 3 años</h4>
                    </div>
                    <div class="card-body p-3">
                        <!-- <apx-chart [series]="barOptions.series" [chart]="barOptions.chart"
                            [dataLabels]="barOptions.dataLabels" [plotOptions]="barOptions.plotOptions"
                            [yaxis]="barOptions.yaxis" [legend]="barOptions.legend" [fill]="barOptions.fill"
                            [stroke]="barOptions.stroke" [tooltip]="barOptions.tooltip" [xaxis]="barOptions.xaxis">
                        </apx-chart> -->
                        <app-bar-chart-race 
                    [autoHeight]="true" [id]="'chartsource2'" 
                    [data]="employeesBy3Years"></app-bar-chart-race>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Por Sucursal</h4>
                    </div>
                    <div class="card-body p-3">
                        <!-- <apx-chart [series]="enclosureBarOptions2?.series" [chart]="enclosureBarOptions2?.chart"
                            [dataLabels]="enclosureBarOptions2?.dataLabels"
                            [plotOptions]="enclosureBarOptions2?.plotOptions" [yaxis]="enclosureBarOptions2?.yaxis"
                            [legend]="enclosureBarOptions2?.legend" [fill]="enclosureBarOptions2?.fill"
                            [stroke]="enclosureBarOptions2?.stroke" [tooltip]="enclosureBarOptions2?.tooltip"
                            [xaxis]="enclosureBarOptions2?.xaxis"></apx-chart> -->
                            <app-bar-chart-race 
                    [autoHeight]="true" [id]="'chartsource3'" 
                    [data]="employeesByEnclosure"></app-bar-chart-race>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Por Tipo De Nombramiento</h4>
                    </div>
                    <div class="card-body p-3">
                        <ng-container *ngIf="contratcTypeChart.series.length">
                            <apx-chart [series]="contratcTypeChart?.series" [chart]="chartOptions?.chart"
                                [dataLabels]="chartOptions.dataLabels" 
                                [title]="chartOptions?.title" [labels]="contratcTypeChart?.labels"
                                [plotOptions]="chartOptions?.plotOptions" [stroke]="chartOptions?.stroke"
                                [fill]='chartOptions?.fill' [colors]='chartOptions?.colors'
                                [responsive]="chartOptions.responsive"
                                [legend]="chartOptions?.legend ">
                            </apx-chart>
                        </ng-container>
                        <ng-container *ngIf="contratcTypeChart.series.length == 0">
                            {{nationalityChart.status}}
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Por Nacionalidad</h4>
                    </div>
                    <div class="card-body p-3">
                        <ng-container *ngIf="nationalityChart.series.length">
                            <apx-chart [series]="nationalityChart.series" [labels]="nationalityChart.labels"
                                [chart]="nacionalityChartOptions.chart" [title]="nacionalityChartOptions.title"
                                [dataLabels]="nacionalityChartOptions.dataLabels" 
                                [plotOptions]="nacionalityChartOptions.plotOptions"
                                [stroke]="nacionalityChartOptions.stroke" [fill]='nacionalityChartOptions.fill'
                                [colors]='nacionalityChartOptions.colors'
                                [responsive]="chartOptions.responsive"
                                [legend]="chartOptions?.legend"></apx-chart>
                        </ng-container>
                        <ng-container *ngIf="nationalityChart.series.length == 0">
                            {{nationalityChart.status}}
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Por Nivel Académico</h4>
                    </div>
                    <div class="container mt-2">
                        <ul class="nav nav-tabs border-tabs-primary" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="deparment-tap" data-toggle="tab" href="#deparment">
                                    <span class="nav-text">
                                        <h3>Por Unidad Organizativa</h3>
                                    </span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="position-tap" data-toggle="tab" href="#position"
                                    aria-controls="acciones-personal">
                                    <span class="nav-text">
                                        <h3>Por Cargo</h3>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    
                        <div class="row tab-content">
                            <div class="tab-pane fade show active" id="deparment" role="tabpanel" aria-labelledby="deparment">
                                <div class="card-header p-3">         
                                    <ng-multiselect-dropdown [placeholder]="'Posición'" [settings]="departmentDropdownSettings"
                                    [classList]="['dropdown-custom']"
                                    [data]="deparments" [(ngModel)]="selectedItemsDeparments" (onDeSelectAll)="onDeSelectAllDepart()"
                                    (onDeSelect)="onItemDeSelectDepart($event)" (onSelect)="onItemSelectDepart($event)"
                                    (onSelectAll)="onSelectAllDepart($event)"></ng-multiselect-dropdown>       
                                </div>

                                <div class="card-body p-3">
                                    <ng-container *ngIf="academicChart2.series.length">
                                        <apx-chart [series]="academicChart2.series" [labels]="academicChart2.labels"
                                            [dataLabels]="academicChartOptions.dataLabels" [chart]="academicChartOptions.chart"
                                            [title]="academicChartOptions.title" [plotOptions]="academicChartOptions.plotOptions"
                                            [stroke]="academicChartOptions.stroke" [fill]='academicChartOptions.fill'
                                            [colors]='academicChartOptions.colors'
                                            [responsive]="chartOptions.responsive"
                                            [legend]="chartOptions?.legend"></apx-chart>
                                    </ng-container>
                                    <ng-container *ngIf="academicChart2.series.length == 0">
                                        {{academicChart.status}}
                                    </ng-container>
                                </div>
                            </div>
                    
                            <div class="tab-pane fade" id="position" role="tabpanel" aria-labelledby="position">
                                <div class="card-header p-3">
                                
                                    <ng-multiselect-dropdown [placeholder]="'Posición'" [settings]="positionDropdownSettings"
                                    [classList]="['dropdown-custom']"
                                    [data]="positions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                                    (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                                    (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>       
                                </div>
                                <div class="card-body p-3">
                                    <ng-container *ngIf="academicChart.series.length">
                                        <apx-chart [series]="academicChart.series" [labels]="academicChart.labels"
                                            [dataLabels]="academicChartOptions.dataLabels" [chart]="academicChartOptions.chart"
                                            [title]="academicChartOptions.title" [plotOptions]="academicChartOptions.plotOptions"
                                            [stroke]="academicChartOptions.stroke" [fill]='academicChartOptions.fill'
                                            [colors]='academicChartOptions.colors'
                                            [responsive]="chartOptions.responsive"
                                            [legend]="chartOptions?.legend"></apx-chart>
                                    </ng-container>
                                    <ng-container *ngIf="academicChart.series.length == 0">
                                        {{academicChart.status}}
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>

            <div class="col-md-6">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Por Edad</h4>
                    </div>
                    <div class="card-body p-3">
                        <ng-container *ngIf="ageChart.series.length">
                            <apx-chart [series]="ageChart.series" [labels]="ageChart.labels"
                                [dataLabels]="ageCharttOptions.dataLabels" [chart]="ageCharttOptions.chart"
                                [title]="ageCharttOptions.title" [plotOptions]="ageCharttOptions.plotOptions"
                                [stroke]="ageCharttOptions.stroke" [fill]='ageCharttOptions.fill'
                                [colors]='ageCharttOptions.colors'
                                [responsive]="chartOptions.responsive"
                                [legend]="chartOptions?.legend"></apx-chart>
                        </ng-container>
                        <ng-container *ngIf="ageChart.series.length == 0">
                            {{ageChart.status}}
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Por Sexo</h4>
                    </div>
                    <div class="card-body p-3">
                        <ng-container *ngIf="ageChart.series.length">
                            <apx-chart [series]="genderCharttOptions?.series" [labels]="genderCharttOptions?.labels"
                                [dataLabels]="genderCharttOptions?.dataLabels" [chart]="genderCharttOptions?.chart"
                                [title]="genderCharttOptions?.title" [plotOptions]="genderCharttOptions?.plotOptions"
                                [stroke]="genderCharttOptions?.stroke" [fill]='genderCharttOptions?.fill'
                                [colors]='genderCharttOptions?.colors'
                                [responsive]="chartOptions.responsive"
                                [legend]="chartOptions?.legend"></apx-chart>
                        </ng-container>
                        <ng-container *ngIf="ageChart.series.length == 0">
                            {{ageChart.status}}
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Uso de Transporte</h4>
                    </div>
                    <div class="card-body p-3">
                        <ng-container *ngIf="ageChart.series.length">
                            <apx-chart [series]="transportUsageChartOptions?.series"
                                [labels]="transportUsageChartOptions?.labels"
                                [dataLabels]="transportUsageChartOptions?.dataLabels"
                                [chart]="transportUsageChartOptions?.chart" [title]="transportUsageChartOptions?.title"
                                [plotOptions]="transportUsageChartOptions?.plotOptions"
                                [stroke]="transportUsageChartOptions?.stroke" [fill]='transportUsageChartOptions?.fill'
                                [colors]='transportUsageChartOptions?.colors' [responsive]="chartOptions?.responsive"
                                [legend]="chartOptions?.legend"
                                [responsive]="chartOptions.responsive"></apx-chart>
                        </ng-container>
                        <ng-container *ngIf="ageChart.series.length == 0">
                            {{ageChart.status}}
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>