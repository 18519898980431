import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { CompanyStructureService } from 'src/app/services/company-structure.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';


@Component({
  selector: 'app-advanced-filter-by-personal-requisition',
  templateUrl: './advanced-filter-by-personal-requisition.component.html',
  styleUrls: []
})
export class AdvancedFilterByPersonalRequisitionComponent implements OnInit {

  units: { item_id: number, item_text: string }[] = [];
  organizationalUnit = []
  position = []
  requisitionMethod = []
  sex = []
  branch = []
  createDate
  approvedDate
  sexCategories = []
  requisitionMethodCategories = []
  positions = []
  branches = []
  
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: true,
    limitSelection: -1
  };

  filters = {
    branch: [],
    unit: [],
    position: [],
    requisitionMethod: [],
    sex: [],
    createDate: null,
    approvedDate: null,
  }


  constructor(public dialogRef : MatDialogRef<AdvancedFilterByPersonalRequisitionComponent>,
    private srvCompanyStructure: CompanyStructureService,
    private toastr: ToastrService,
    private srvParam: ParameterControlService,
    private srvCompanies: CompaniesService,
    private srvRequisition: PersonalRequsitionService,
    private auth: AuthInfoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.fillFilter()
    //Branch,Units,Position,RequisitionMethod,Sex,CreateDate,ApprovedDate
    
    this.getRequisitionMethod()
    this.getSex()
    this.getOrganizations()
    this.getBranches()
    this.getPositions()
  }

  fillFilter(){
    if(!this.data.advanced){
      return
    }
    let strFilter = this.data.advanced.split(',')

    

    this.filters.branch = strFilter[0].split('|')
    this.filters.unit = strFilter[1].split('|')
    this.filters.position = strFilter[2].split('|')
    this.filters.requisitionMethod = strFilter[3].split('|')
    this.filters.sex = strFilter[4].split('|')
    this.filters.createDate = strFilter[5]
    this.filters.approvedDate = strFilter[6]

    this.createDate = this.filters.createDate
    this.approvedDate = this.filters.approvedDate
  }

  Cancel(success = false, text = null): void {
    this.dialogRef.close({success, text});
  }
  getBranches() {
    this.srvCompanies.getCompanies().subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          this.toastr.error(res.errors[0])
          return;
        }
        this.branches = res.dataList.map(x => {
          return {
            item_id: x.companyId,
            item_text: x.companyName,
            ...x
          }
        })
        this.branch = this.setDropdown(this.branches, this.filters.branch)
        
      },
      error: (err) => {

      }
    });
  }

  setDropdown(arrOriginal, arrToFilter){
    let o = []
    if(arrToFilter){
      arrOriginal.forEach(x => {
        arrToFilter.forEach(c => {
          if(Number(c) == Number(x.item_id)){
            o.push(x)
          }
        })
      })
    }
    return o
  }

  getRequisitionMethod(){
    this.srvParam.getParameters(keyWord.RequisitionMethod).subscribe({
      next: (res:any) => {
        if(res.errors.length > 0){
          this.toastr.error("Ha ocurrido un error inesperado")
          return
        }
        this.requisitionMethodCategories = res.dataList.map(x => {
          return {
            item_id: x.ocode,
            item_text: x.stringData,
            ...x
          }
        })
        this.requisitionMethod = this.setDropdown(this.requisitionMethodCategories, this.filters.requisitionMethod)

      }, error: (error:any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  getSex(){
    this.srvParam.getParameters(keyWord.Gender).subscribe({
      next: (res:any) => {
        if(res.errors.length > 0){
          this.toastr.error("Ha ocurrido un error inesperado")
          return
        }
        this.sexCategories = res.dataList.map(x => {
          return {
            item_id: x.ocode,
            item_text: x.stringData,
            ...x
          }
        })
        this.sex = this.setDropdown(this.sexCategories, this.filters.sex)

      }, error: (error:any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }


  getPositions() {
    
    this.srvRequisition.GetApprovedPositions(this.auth.getCompanyId(), true).then(data => {
      if (data.errors.length > 0) {
        this.toastr.error(data.errors[0])

      }
      if (data.succeded) {
        this.positions = data.dataList.sort((a, b) => a.positionName.trim().localeCompare(b.positionName.trim())).map(x => {
          return {
            item_id: x.positionId,
            item_text: x.positionName,
            ...x
          }
        })
        this.position = this.setDropdown(this.positions, this.filters.position)
      }
    })
  }


  getOrganizations(){
    this.srvCompanyStructure.getAllCompanyStructures().subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          if(res.errors.legnth > 0){
            this.toastr.error(res.errors[0])
          }
          return
        }

        this.units = res.dataList.map(x => {
          return {
            item_id: x.departmentId,
            item_text: x.departmentName,
            ...x
          }
        })
        this.organizationalUnit = this.setDropdown(this.units, this.filters.unit)
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  Accept() {
    
    let branch = this.getStringIds(this.branch)
    let organizationalUnit = this.getStringIds(this.organizationalUnit)
    let position = this.getStringIds(this.position)
    let requisitionMethod = this.getStringIds(this.requisitionMethod)
    let sex = this.getStringIds(this.sex)

    
    let createDate = this.createDate ? moment(this.createDate).format('yyyy-MM-DD') : ''
    let approvedDate = this.approvedDate ? moment(this.approvedDate).format('yyyy-MM-DD') : ''
    
    let text = `${branch},${organizationalUnit},${position},${requisitionMethod},${sex},${createDate},${approvedDate}`
    
    this.Cancel(true,text)
  }

  getStringIds(arr){
    let str = ''
    if(!arr){
      return ''
    }
    let arrLength = arr.length
    if(arrLength === 0){
      return ''
    }
    arr.forEach((x, i) => {
      str += x.item_id
      if(arrLength !== (i + 1)){
        str += '|'
      }
    })

    return str
  }
}
