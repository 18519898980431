<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span
            class="h-20px label label-danger label-dot mr-3 w-20px"></span>{{titleModal}}</h2>

</div>
<div class="mat-dialog-content-custom">

    <div class="card card-custom bg-transparent">
        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-7 c-left">
                <div class="card-toolbar d-flex justify-content-start">

                    <div class="col-md-4 mr-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Estatus
                            </label>
                            <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                                [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                                (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-5 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <!-- <a class="dropdown-item align-items-center" (click)="exportToExcel()">
                                <i class="flaticon-excel icon-2x text-success mr-3"></i>
                                Excel
                            </a>
                            <a class="dropdown-item align-items-center" (click)="onBtnPrintClick()">
                                <i class="flaticon-pdf-file text-danger icon-2x text-primary mr-3 text-primary"></i>
                                PDF
                            </a> -->
                            <a class="dropdown-item align-items-center" (click)="openReport()">
                              <i class="fa fa-print icon-2x text-primary mr-3"></i>
                              Visualizar Reporte
                            </a>
                            <!-- <a class="dropdown-item disabled" href="#">
                                <i class="flaticon-word icon-2x text-primary mr-3"></i>
                                Word
                            </a>
                            <a class="dropdown-item disabled" href="#">
                                <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                                PDF
                            </a>
                            <a class="dropdown-item disabled" href="#">
                                <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                                CSV
                            </a> -->
                        </div>
                    </div>
                    <div class="col text-nowrap">
                        <app-search-bar-pagination (onFilter)="filterSearch($event)" [parameters]="searchBarDisplayNames" (onKeyParameterChange)="onKeyParameterChange($event)"></app-search-bar-pagination>
                </div>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-vertical-center caption-top">
                    <caption *ngIf="!data?.isView">
                        <button (click)="excludeEmployeesSelection()" [disabled]="employeeList.length <= 0"
                            class="btn btn-danger btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                            <i class="flaticon-mala-critica" data-toggle="tooltip"
                                data-original-title="Agregar nueva requisición"></i>
                            Excluir Selección
                        </button>
                        <button (click)="includeEmployeesSelection()" [disabled]="employeeList.length <= 0"
                            class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                            <i class="flaticon-check" data-toggle="tooltip"
                                data-original-title="Agregar nueva requisición"></i>
                            Incluir Selección
                        </button>
                    </caption>
                    <thead>
                        <tr class="bg-primary">
                            <th *ngIf="!data?.isView && selectedVacation?.conditionId == 1" class="text-left"><input
                                    type="checkbox" [checked]="isAllChecked"
                                    (change)="onCheckedAllChange($event.target.checked)" name="" id=""
                                    class="check-size"></th>
                            <th class="text-center">Secuencia</th>
                            <th class="text-left">Código Servidor Público</th>
                            <th class="text-left">Servidor Público</th>
                            <th class="text-left">Documento de Identidad</th>
                            <th class="text-left">Unidad Organizativa</th>
                            <th class="text-left">Cargo</th>
                            <th class="text-left">Tipo</th>
                            <th class="text-left">Categoría</th>
                            <th class="text-left">Tanda</th>
                            <th class="text-left">Condición de Servidor Público </th>
                            <th class="text-left">Estatus</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let detail of vacationDetailsList">
                            <td *ngIf="!data?.isView && selectedVacation?.conditionId == 1" class="text-left"><input
                                    type="checkbox" [checked]="isAllChecked"
                                    (change)="onChecked(detail, $event.target.checked)" name="" id=""
                                    class="check-size"></td>
                            <td class="text-center">{{detail.collectiveVacationsDetailId}}</td>
                            <td class="text-left">{{detail.employNumber}}</td>
                            <td class="text-left">{{detail.name}}</td>
                            <td class="text-left" *ngIf="detail.typeIdentificationId == 1">{{detail.personalIdentification | mask: '000-0000000-0'}}</td>
                            <td class="text-left" *ngIf="detail.typeIdentificationId != 1">{{detail.personalIdentification}}</td>
                            <td class="text-left">{{detail.departmentName}}</td>
                            <td class="text-left">{{detail.positionName}}</td>
                            <td class="text-left">{{detail.employeeType}}</td>
                            <td class="text-left">{{detail.contractType}}</td>
                            <td class="text-left">{{detail.proposedSchedule}}</td>
                            <td class="text-left">
                                <span
                                    [ngClass]="detail.employeeStatus ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{detail.employeeStatus?
                                    "Activo":
                                    "Inactivo"}}</span>
                            </td>
                            <td class="text-left">
                                <span
                                    [ngClass]="detail.status ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{detail.status?
                                    "Incluido":
                                    "Excluido"}}</span>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div>
                    <app-paginator
                    [listedRecordsLength]="vacationDetailsList?.length"
                    [registerQuantity]="vacationsPagedResut.totalItems"
                    [registerPerPage]="vacationsPagedResut.pageSize"
                    [totalPages]="vacationsPagedResut.totalPages"
                    [selectedPage]="vacationsPagedResut.page"
                    (onChange)="getPaginatedRecords($event)">
                </app-paginator>
                </div>
            </div>
        </div>
    </div>
</div>

<export-data-to-pdf [title]="titleModal" id="dataToPrint2">
    <ng-container thead>
        <th class="text-center">Secuenciaa</th>
        <th class="text-left">Código Servidor Público</th>
        <th class="text-left">Servidor Público</th>
        <th class="text-left">Documento de Identidad</th>
        <th class="text-left">Unidad Organizativa</th>
        <th class="text-left">Cargo</th>
        <th class="text-left">Tipo</th>
        <th class="text-left">Categoría</th>
        <th class="text-left">Tanda</th>
        <th class="text-left">Condición de Servidor Público </th>
        <th class="text-left">Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let detail of vacationDetailsList"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td class="text-center">{{detail.collectiveVacationsDetailId}}</td>
            <td class="text-left">{{detail.employNumber}}</td>
            <td class="text-left">{{detail.name}}</td>
            <td class="text-left" *ngIf="detail.typeIdentificationId == 1">{{detail.personalIdentification | mask: '000-0000000-0'}}</td>
            <td class="text-left" *ngIf="detail.typeIdentificationId != 1">{{detail.personalIdentification}}</td>
            <td class="text-left">{{detail.departmentName}}</td>
            <td class="text-left">{{detail.positionName}}</td>
            <td class="text-left">{{detail.employeeType}}</td>
            <td class="text-left">{{detail.contractType}}</td>
            <td class="text-left">{{detail.proposedSchedule}}</td>
            <td class="text-left">
                <span
                    [ngClass]="detail.employeeStatus ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{detail.employeeStatus?
                    "Activo":
                    "Inactivo"}}</span>
            </td>
            <td class="text-left">
                <span
                    [ngClass]="detail.status ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{detail.status?
                    "Incluido":
                    "Excluido"}}</span>
            </td>
        </tr>
    </ng-container>
</export-data-to-pdf>
