type GUID = string & { isGuid: true };
export class Vacancies {
    vacantId?: number;
    personalRequisitionId: number;
    description: string;
    positionId: number;
    vacancyTypeId: number;
    minimumSalary: number;
    maximumSalary: number;
    jobFunctions: string;
    publicationDate: Date;
    grupoOcupacionalId: number;
    sex: number;
    minimumAge: number;
    maximumAge: number;
    admissionDate: Date;
    documentId: string;
    age: number;
    hoursWorked: number;
    yearsExperience: number;
    cause: number;
    academicLevel: number;
    requirements: string;
    requesitiontTypeId: number;
    companyId: number;
    status: boolean;
    published?: boolean;
}

export interface IVacancy {
    bond: boolean;
    vacancyId: number;
    employeeId: number;
    applicationDate: Date;
    dateStart: Date;
    dateEnd: Date;
    year: number;
    days: number;
    companyId: number;
    status: boolean;
    expr1: number;
    employNumber: number;
    postulationId: number;
    sendMapId: number;
    positionId: number;
    departmentId: number;
    typeEmployeeId: number;
    contractExpiration: Date;
    typeContractId: number;
    branchId: number;
    entityBankId: number;
    bankAccount: string;
    admissionDate: Date;
    conditionId: number;    
}

export interface PersonsWorkExperiences{
    workExperienceId: number;
    personId: number;
    personName: string;
    jobTitle: string;
    startDate: Date;
    departureDate: Date;
    lastSalary: number;
    reasonOfExitId: number;
    stateEnterprise: boolean;
    role: string;
    companyId: number;
    status: boolean;
    company: string;
    reasonOfExit: string;
}






