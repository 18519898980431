import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { SweetAlert } from '../../../shared/utils/Sweet-Alert';
import { openPayroll, Payroll } from './models/mock-data';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import Swal from 'sweetalert2'
import { ActivatedRoute, Router } from '@angular/router';
import { PayrollService } from 'src/app/services/payroll.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { Header } from 'src/app/models/header';
import { DashboardPayrollComponent } from './dashboard-payroll/dashboard-payroll.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { ViewDetailRejectPayrollComponent } from './view-detail-reject-payroll/view-detail-reject-payroll.component';
import { IHeader, IHeaderWithSettings } from '@payroll/payroll-models';
import { RejectPayrollModalComponent } from '@payroll/audit-approval/reject-payroll-modal/reject-payroll-modal.component';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';

declare const $: any;

enum PayrollTypes {
  normal = 0,
  final = 1,
  incentiveWithoutContribution = 2,
  royalties = 3,
  additional = 4
}

@Component({
  selector: 'open-payroll',
  templateUrl: './open-payroll.component.html',
  styleUrls: ['./open-payroll.component.scss']
})

export class OpenPayrollComponent implements OnInit, OnDestroy, AfterViewChecked {

  //incompleto
  public privilege: any = {};
  selectedItems: any;
  sweetAlert = new SweetAlert()
  currentDate = new Date()
  typePayroll: string = ""
  budgetAreaList: any[] = []
  filtervalues:any;
  budgetTypeList: any[] = []
  openedPayrollList: any[] = []
  payrollListOriginal: any[] = [];
  openedPayroModel = new Header()
  paginatedPayrollList: any[] = [];
  @ViewChild('paginator') paginator: CaptionComponent;
  isLoading:boolean = false

  payrollForm = this.builform.group({
    period : ['', Validators.required],
    area: ['', Validators.required],
    payrollType: ['', Validators.required]
  })


  // payrollFormdd = new FormGroup({
  //   period: new FormControl('', Validators.required),
  //   area: new FormControl('', Validators.required),
  //   payrollType: new FormControl('', Validators.required),
  // })

  payrollRecords: Payroll[] = openPayroll
  records: Payroll[] = []

  yearsRange = []
  payrollOptions = []
  payrollSelected;
  yearsSelected;

  activeButtons = [];
  page = 1
  pageSize = 20

  periodOptions = [

  ];

  periodConfig = {
    displayKey: 'description', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };
  propKeyId = 0
  text = ''
  filterText: string = '';
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string, propKeyId?:any }[] =
    [
      {propkey: "headerId", displayName: "Secuencia", propKeyId: 1 },
      {propkey: "payrollType", displayName: "Tipo de Nómina", propKeyId: 2 },
      {propkey: "headerCondition", displayName: "Estatus", propKeyId: 3 },
      {propkey: "name", displayName: "Usuario Creación", propKeyId: 4 },
    ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' }
  ];


  getFilteredList(event) {
    this.text = event?.text ? event?.text : ''
    this.propKeyId = event?.propkey?.propKeyId ? event?.propkey?.propKeyId : '0'
    this.filteredList = event;
    this.paginator.selectedPage = 1;
    this.filteredList.records = this.filterTable(event.records)
  }

  getPaginatedRecords(event) {
    this.pageSize = event.registersPerPage
    this.page = event.selectedPage
    this.paginatedPayrollList = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }


  constructor(
    private builform : FormBuilder,
    private _exlService: ExportToExcelService, private router: Router, private _payrollService: PayrollService,
    private _parameterService: ParameterControlService, private _toastService: ToastService, private _cdRef: ChangeDetectorRef,
    private dialog: MatDialog, public authInfo: AuthInfoService, private route: ActivatedRoute
  ) {
    this.typePayroll = route.snapshot.params["type"];
    this.payrollForm.patchValue({
      payrollType: this.typePayroll?.toLocaleLowerCase() === 'normal' ? PayrollTypes.normal : PayrollTypes.royalties
    });
  }

  ngOnInit(): void {
    this.typePayroll == "normal" ? this.privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'NORMAL-PAYROLL',
    rejectAction: { key: 'REJECTPAYROLL', value: false },
    exportAction: { key: 'EXPORTPAYROLL', value: false },
    viewAction: { key: 'VIEWPAY', value: false },
    openAction: { key: 'OPENPAYROLL', value: false },
    dashboardAction: { key: 'DASHBOARDPAY', value: false },

    } : this.privilege = {
      nameKeyModule: 'HRM',
      nameKeyOption: 'PAYROLL-REGALIA',
      rejectAction: { key: 'REJECTPAYROLL', value: false },
      exportAction: { key: 'EXPORTPAYROLL', value: false },
      viewAction: { key: 'VIEWPAY', value: false },
      openAction: { key: 'OPENPAYROLL', value: false },
      dashboardAction: { key: 'DASHBOARDPAY', value: false },
    };

    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.getData()
      }
    })
  }

  getData() {
    this._payrollService.getBudgetAreas().subscribe((res: any) => {
      this.budgetAreaList = res.dataList.filter(ba => ba.status == true)
    })

    this._payrollService.getPeriods().subscribe((res: any) => {
      if (res.succeded) {

        this.periodOptions = res.dataList.filter(x => x.conditionId == 1 && x.status == true);

        this.payrollForm.patchValue({
          period: this.periodOptions[0]
        })
        this.changePeriod();
      }

    }, err => {
    })

    this._parameterService.getParameters('PayrollType').subscribe((res: any) => {
      this.budgetTypeList = res.dataList
    })

  }

  changePeriod() {
    this.openedPayrollList = [];
    if (this.payrollForm.get('period').value?.payrollPeriodId) {
      this.getHeadersList()
    }
  }

  openRejectModal(header: IHeader) {
    this.dialog.open(RejectPayrollModalComponent, {
      width: '50%',

      data: { headerId: header.headerId }
    }).afterClosed().subscribe((result: any) => {
      if (result?.saved) {
        this._toastService.success("Datos guardados")
        this.getHeadersList()
      }
    })
  }

  getHeadersList() {
    this._payrollService.getHeaders(4, this.payrollForm.get('period').value?.payrollPeriodId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      const dataList = res.dataList.filter(d => d.payrollTypeId === this.payrollForm.get('payrollType')?.value);
      this.payrollListOriginal = dataList;
      this.filter()
    })
  }

  openPayroll() {
    if(this.payrollForm.invalid){
      this._toastService.warning("Período y Área Presupuestaria deben de estar selecionada");
      return
    }

    this.openedPayroModel = {
      payrollPeriodId: this.payrollForm.get('period').value?.payrollPeriodId,
      budgetAreaId: this.payrollForm.get('area').value?.budgetAreaId,
      payrollTypeId: this.payrollForm.get('payrollType')?.value,
      conditionId: 0,
      companyId: this.authInfo.getCompanyId(),
      status: true,
      createDate: this.currentDate.toLocaleDateString('fr-CA'),
      createUserId: this.authInfo.getUserId(),
      modifyDate: new Date().toLocaleDateString('fr-CA'),
    }

    this.sweetAlert.AlertConfirm("Abrir Nómina", "¿Está seguro de que desea abrir esta nómina?", "question").then(res => {
      if (res) {
        this.isLoading = true

          this._payrollService.createHeader(this.openedPayroModel).subscribe({
            next: (res:any)=>{
              if (res.errors.length > 0) {
                if (res.errors[0] == 'Ya existe esta nomina aperturada' || res.errors[0] == 'Ya existe esta nómina aperturada') {
                  this._toastService.warning(res.errors[0], "");
                  return;
                }
                this._toastService.error(res.errors[0], "");
                return;
              }
              this._toastService.success("Nomina aperturada satisfactoriamente")
              this.getHeadersList()
            },
            error:(err:any)=>{ this._toastService.error("Ocurrió un error abriendo la nómina, por favor comunicarse con el administrador")},
            complete:()=>{
              this.isLoading = false
            }
             
            }
          )
      
      } else {
        return
      }
    })
  }

  filter() {
    document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.openedPayrollList = this.filterTable(this.payrollListOriginal)
    }
    this.setFilterToLocalStorage();
  }

  filterTable(list: any[]) {
    return list.filter((pl: Header) => pl.status == true);
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  setFilterToLocalStorage() {
    localStorage.setItem('employee-list_filter', JSON.stringify(this.selectedItems));
  }

  showModal(modalName: string) {
    $(`#${modalName}`).modal('show');
  }

  exportToExcel() {
    let excelHeaders: string[][] = [[
      "Secuencia",
      "ID de Período",
      "ID de Tipo",
      "Tipo",
      "ID de Condicion",
      "Condición",
      "ID de compañia",
      "Estatus",
      "Fecha de creación",
      "ID de usuario",
      "Fecha de modificación",
      "Usuario que modifica",
      "ID de versión",
      "Período",
      "ID de Área",
      "Área",
      "Fecha límite"
    ]]
    const name = this.typePayroll == "normal"? "Nómina Normal" : "Nómina Regalía"
    this._exlService.exportToExcelSpecificColumns(this.filteredList.enable ? this.filteredList.records : this.openedPayrollList, excelHeaders, `${name}`);
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  openDashBoard(): void {
    this.dialog.open(DashboardPayrollComponent, {
      width: '100%'
    })
      .afterClosed()
      .subscribe((result) => {
      });
  }

  openViewDetailRejectPayrollComponent(openedPayroll: any) {
    this.dialog.open(ViewDetailRejectPayrollComponent, {
      data: openedPayroll,
      width: '60%'
    })
      .afterClosed()
      .subscribe((result) => {
      });
  }

  onBtnPrintClick(){
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

  onKeyParameterChange(event){
    this.filtervalues = event;
  }

  exportNormal(){
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.ListPayroll;
    const periodId =  this.payrollForm.get('period').value?.payrollPeriodId ?? 0
    const budgetAreaId = 0 //se envia 0 porque en esta pantalla actualmente no se filtra por este campo. si no se filtra por este campo, en el reporte tampoco deberia filtrar. si se filtra en el reporte pero en la pantalla no, se mostrarian datos diferentes
    const PropkeyId: any = this.filtervalues?.propKeyId ?? 0
    const SearchParameter: any = this.filterText ?? ''
    const reportUrl = `${environment.reportUrl}?Reportcode=${reportCode}&budgetAreaId=${budgetAreaId}&PeriodId=${periodId}&PayrollTypeId=0&PayrollPeriodIdRelated=0&CompanyId=${companyId}&Conditions=0-1-2-3-4-5-6&PropKeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Page=${this.page}&PageSize=${this.pageSize}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Unidades Organizativas',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  exportRegalia(){
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.ListRoyaltyPayroll;
    const periodId =  this.payrollForm.get('period').value?.payrollPeriodId ?? 0
    const budgetAreaId = 0
    const PropkeyId: any = this.filtervalues?.propKeyId ?? 0
    const SearchParameter: any = this.filterText ?? ''
    const reportUrl = `${environment.reportUrl}?Reportcode=${reportCode}&PeriodId=${periodId}&PayrollTypeId=3&BudgetAreaId=${budgetAreaId}&PayrollPeriodIdRelated=0&CompanyId=${companyId}&Conditions=0-1-2-3-4-5-6&PropKeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Page=${this.page}&PageSize=${this.pageSize}`;

    let parameters = {
      url: reportUrl,
      title: 'Reporte Nomina Regalia',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }


  exportToPdf() {
    if(this.typePayroll == "normal"){
      this.exportNormal()
    }
    if(this.typePayroll == "regalia"){
      this.exportRegalia()
    }
  }

}
