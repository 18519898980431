import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonDropDownSettings } from 'src/app/shared/settings/common-dropdown.settings';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-collective-vacation-vacation-scheduling',
  templateUrl: './collective-vacation-vacation-scheduling.component.html',
  styles: [
  ]
})
export class CollectiveVacationVacationSchedulingComponent implements OnInit {

  public readonly commonDropDownSettings = CommonDropDownSettings;

  anhoPerteneceList: { propkey?: string, text: string }[] =
  [
    { propkey: "1", text: "2025 - 2024" },
    { propkey: "2", text: "2024 - 2023" },
    { propkey: "3", text: "2023 - 2022" },
  ];
  
  constructor(
    public dialogRef: MatDialogRef<CollectiveVacationVacationSchedulingComponent>,
    @Inject(MAT_DIALOG_DATA)private data : any,
  ) { }

  ngOnInit(): void {
  }

  close(): void
  {
    this.dialogRef.close();
  }

  save(): void
  {
    
  }
}
