import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/internal/Subscription';
import { IEmployeeVacancyHistory } from 'src/app/pages/RegistrationAndControl/personnel-actions/models/EmployeeVacancyHistory';
import { SearchParametersForEmployeesVacancies } from 'src/app/pages/RegistrationAndControl/personnel-actions/models/SearchParametersForEmployeesVacancies.enum';
import { IVacancy, PersonsWorkExperiences } from 'src/app/pages/RegistrationAndControl/personnel-actions/models/Vacancy';
import { vacancyCalculations, VacationDaysAllowed } from 'src/app/pages/RegistrationAndControl/personnel-actions/models/vacation-days-allowed.enum';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { DepartmentService } from 'src/app/services/department.service';
import { docService } from 'src/app/services/doc.service';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { IPersonsWorkExperiencesGET } from 'src/app/shared/models/personsWorkExperiences';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import * as moment from 'moment';

@Component({
  selector: 'app-vacations',
  templateUrl: './vacations.component.html',
  styleUrls: ['./vacations.component.css']
})
export class VacationsComponent implements OnInit, OnChanges {
  @Input() vacationId: number;
  @Input() employee: any;

  onLoading: boolean = true;

  historyWork: IPersonsWorkExperiencesGET[] = [];
  vacationData: IVacancy[] = [];
  personSelected: any = {
    typeIdentificationId: 0
  };
  workHistoryData: PersonsWorkExperiences[] = [];

  pdfOpt: any;

  employeeVacationHistory: {
    vacationId: number, year: number, daysUsed: number, totalDaysAllowed: number,
    employeeVacationHistoryDates: [{ startDate: Date, endDate: Date }?]
  }[] = [];
  currentVacationCounter;
  experienceYears: number = 0;
  daysAvailables = 0

  totalPeriods: {
    days: number,
    deduct: number,
    year: number,
    yearTo: number
  }[] = [];

  @Output() vacationsData: EventEmitter<any> = new EventEmitter();
  constructor(
    private _personnelActionsService: PersonnelActionsService,
    private _toastrService: ToastrService,
    private srvDoc: docService,
    private _companiesService: CompaniesService,
    private authInfo: AuthInfoService,
    private _departmentService: DepartmentService
  ) {
    this.pdfOpt = {
      orientation: 'p',
      unit: 'in',
      format: [11, 8.5]
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.employee?.employeeId > 0){
      this.getHistoryWork();
    }
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy(): void {

  }

  getVacationTablesData() {
    this._personnelActionsService.getEmployeesVacancyData(SearchParametersForEmployeesVacancies.EmployeeId,
      this.employee?.employeeId).subscribe((res: ResponseModel<any>) => {
        if (res.dataList.length == 0) {
          this._toastrService.warning('No se encontraron registros');
          this.onLoading = false;
          return;
        } else {
          this.vacationData = (res.dataList != null && res.dataList.length > 0) ? res.dataList : [];

          this.employeeVacationHistory = [];

          let arrWithoutDuplicates = this.removeDuplicates(this.vacationData, 'year');

          arrWithoutDuplicates.filter(x => x.status).forEach(vacation => {
            this.employeeVacationHistory.push({
              vacationId: vacation.vacancyId,
              year: vacation.year,
              daysUsed: this.vacationData.filter(x => x.year == vacation.year && x.status).reduce((a, b) => a + b.days, 0) ?? 0,
              totalDaysAllowed: VacationDaysAllowed.Year,
              employeeVacationHistoryDates: []
            });

            this.vacationData.filter(x => x.year == vacation.year && x.status).forEach(vacation => {
              let { dateStart, dateEnd } = vacation;
              this.employeeVacationHistory.find(x => x.year == vacation.year).employeeVacationHistoryDates.push({
                startDate: dateStart,
                endDate: dateEnd
              })
            });
          });

          this.currentVacationCounter = this.employeeVacationHistory[0];

          this.getVacationSummary()

          const dataToOutput = this.vacationData.find(x=>x.vacancyId == this.vacationId);

          const vacationInfo = `${this.currentVacationCounter.daysUsed} día(s) de vacaciones correspondiente al ${this.currentVacationCounter?.year}, pendientes ${this.getHistoryVacancyDays() - this.currentVacationCounter?.daysUsed} día(s) del ${this.currentVacationCounter?.year}.`;
          
          
          
          this.vacationsData.emit({
            dateStart: dataToOutput?.dateStart,
            dateEnd: dataToOutput?.dateEnd,
            comment: dataToOutput?.comment || 'N/A',
            vacationInfo: vacationInfo
          })
        }

        this.onLoading = false;

      }, (error) => {
        this._toastrService.error("Error al listar las vacaciones", "Error inesperado");
      })
  }

  getVacationSummary() {
    const vacations = this.vacationData
    const years : {year: number, yearTo: number}[] = [];

    vacations.forEach(v=>{
      if(years.find(y=>y.year != v.year)){
        years.push({year:v.year, yearTo: v.yearTo})
      }
    })

    this.totalPeriods = []

    years?.forEach(y => {
      const vacationsInYear = vacations.filter(v => v.year == y?.year)
      let daystaken = 0
      vacationsInYear.forEach(x => {
        daystaken += x.days
      })
      const daysAvailable = this.getDaysAvailableInSpecificYear(y?.year)

      const daysResult =  daysAvailable - daystaken
      if (daysResult > 0) {
        this.totalPeriods.push({
          days: daysResult,
          deduct: daystaken,
          year: y?.year,
          yearTo: y?.yearTo
        })
      }
    })

  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  getHistoryWork() {
    this._personnelActionsService.getHistoryWork(this.employee?.personId).subscribe((res: any) => {

      if (!res.succeded) {
        res.errors.forEach(err => {
          this._toastrService.error(err);
        });

        res.warnings.forEach(err => {
          this._toastrService.warning(err);
        })

        return;
      }

      const data: IPersonsWorkExperiencesGET[] = res.dataList
      this.historyWork = data;
      let totalMonths = 0
      data.forEach(e => {
        totalMonths += e.totalMonths
      })
      let totalYears = 0;
      if (totalMonths >= 12) {
        totalYears = totalMonths / 12
        let totalYearString = totalYears.toString()
        if (totalYearString.includes('.')) {
          const index = totalYearString.indexOf('.')
          totalYears = Number(totalYearString.slice(0, index))
        }

      }
      this.experienceYears = totalYears;
      this.getVacationTablesData();

    }, error => {
      this._toastrService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  getHistoryVacancyDays(): number {
    if (this.historyWork?.length > 0) {
      let cal = vacancyCalculations?.find(x => this.experienceYears >= x.yearsWorked[0] && this.experienceYears <= x.yearsWorked[1]);
      if (cal) {
        this.daysAvailables = cal?.days
        return cal?.days
      }
    } else {
      return 15;
    }
  }

  getDaysAvailableInSpecificYear(year): number {
    const totalYearsWorking = this.getTotalYearsWorking(year)
    let cal = vacancyCalculations?.find(x => totalYearsWorking >= x?.yearsWorked[0] && totalYearsWorking <= x?.yearsWorked[1]);
    const days = cal?.days ?? 0;
    const availableDays = (days)

    return availableDays

  }

  getTotalYearsWorking(year) {
    const admission = moment(this.personSelected?.admissionDate).startOf('date')
    const date = moment(admission).year(year).startOf('date')
    const totalYearsWorking = date.diff(admission, 'year')
    const totalYears = totalYearsWorking + this.experienceYears
    if(totalYearsWorking >= 0){
      return totalYears
    }else{
      return this.experienceYears
    }
  }

}