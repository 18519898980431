import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SearchEmployeeComponent } from '@payroll/action-edit/search-employee/search-employee.component';
import { RejectPayrollModalComponent } from '@payroll/audit-approval/reject-payroll-modal/reject-payroll-modal.component';

import { IEmployeeGET } from '@payroll/final-payroll/models/models';

import { NewsType, PayrollNew } from '@payroll/news-list/models/PayrollNews.model';
import { headerCondition } from '@payroll/payroll-models';
import { PeriodParams } from '@payroll/schedule/models/period-get-params.model';
import { ToastrService } from 'ngx-toastr';
import { IEmployee } from 'src/app/models/Employee.model';
import { AdditionalPayrollService } from 'src/app/services/additional-payroll.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { FinalPayrollService } from 'src/app/services/final-payroll.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { PayrollPeriodService } from 'src/app/services/payroll-period.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { ToastService } from 'src/app/shared/toast/toast.service';
import Swal from 'sweetalert2';
import { ActionEditComponent } from '..';
import { AdditionalPayrollAddNewsModalComponent } from './additional-payroll-add-news-modal/additional-payroll-add-news-modal.component';
import { AdditionalPayrollApproveNewsModalComponent } from './additional-payroll-approve-news-modal/additional-payroll-approve-news-modal.component';
import { AdditionalPayrollBulkLoadComponent } from './additional-payroll-bulk-load/additional-payroll-bulk-load.component';
import { AdditionalPayrollNewsDetailModalComponent } from './additional-payroll-news-detail-modal/additional-payroll-news-detail-modal.component';
import { AdditionalPayrollSearchModalComponent } from './additional-payroll-search-modal/additional-payroll-search-modal.component';
import { openReport } from 'src/app/shared/utils/utility';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ExcludeEmployeePayrollModalComponent } from '@payroll/modals/exclude-employee-payroll-modal/exclude-employee-payroll-modal.component';
import { PayrollConditions } from 'src/app/shared/enums/PayrollConditions';
@Component({
  selector: 'app-additional-payroll',
  templateUrl: './additional-payroll.component.html',
  styleUrls: ['./additional-payroll.component.css']
})


export class AdditionalPayrollComponent implements OnInit, AfterViewChecked {
  periodDescription: string = ''
  periodRelatedDescription: string = ''
  budgetAreaDescription: string = ''
  headerCondition = ''

  versionText = ''
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'ADDITIONAL-PAYROLL',
    addAction: { key: 'ADDREQUISITION', value: false },
    exportAction: { key: 'EXPORT', value: false },
    approveAction: { key: 'APPROVAL', value: false },
    massiveLoadAction: { key: 'MASIVE-LOAD', value: false },
    newsAction: { key: 'NEWS', value: false },
    viewAction: { key: 'VIEW', value: false },
    Exclude: { key: 'Exclude', value: false }
  }


  filterText: string = '';
  periodDropdownIsDisable = true;
  areaDropdownIsDisable = true;
  periodRelatdDropdownIsDisable = false;
  disableDropdowns = false;
  budgetAreaId = 0;
  payrollPeriodId = 0;
  selectedItems: any[] = [];
  employeeList: any[] = []
  relatedPeriods: any[] = [];
  periods: any[] = []
  budgetAreas: any[] = []
  selectedPeriod: any;
  selectedRelatedPeriod: any;
  selectedBudgetArea: any;
  paginatedRecords: any[] = []
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propKeyId: number, propkey?: string, displayName: string }[] = [
    { propKeyId: 1, propkey: "employeeId", displayName: "Secuencia" },
    { propKeyId: 2, propkey: "fullName", displayName: "Servidor Público" },
    { propKeyId: 3, propkey: "departmentName", displayName: "Unidad organizativa" },
    { propKeyId: 4, propkey: "sex", displayName: "Genero" },
  ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' },
    { item_id: 3, item_text: 'Aprobadas' },
    { item_id: 4, item_text: 'No Aprobadas' }
  ];

  selectedHeader;

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  headerId = 0
  @ViewChild('paginator') paginator: CaptionComponent;
  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  payrollHeader: { headerId: number, conditionId: number } = {
    headerId: 0,
    conditionId: 0,
  };
  showExcludeBtn = false
  searchPropKeySelected!: { propKeyId?: number, propkey?: string, displayName: string };
  selectedPage: any
  constructor(private employeeService: EmployeesService,
    private _toastService: ToastService,
    private _exlService: ExportToExcelService,
    private _changeDet: ChangeDetectorRef,
    private _parameterService: ParameterControlService,
    private _fb: FormBuilder,
    private _toastr: ToastrService, private srvDoc: docService,
    private dialog: MatDialog,
    private payrollService: PayrollService,
    private additionalService: AdditionalPayrollService,
    private periodService: PayrollPeriodService,
    public authInfo: AuthInfoService,
    private router: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.router.params.subscribe({
          next: (res: any) => {
            this.headerId = res.id
            const params = {
              headerId: this.headerId, type: 2, companyId: this.authInfo.getCompanyId()
            }
            this.payrollService.getHeaderByOptions(params).subscribe({
              next: (res: any) => {
                if (res.errors.length > 0) {
                  this._toastService.error(res.errors[0])
                  return
                }
                if (!res.dataList?.length) {
                  return
                }
                this.selectedHeader = res.dataList[0]
                if (!this.selectedHeader?.header) {
                  return
                }
                let header = this.selectedHeader.header
                let excludeBtnConditions = [PayrollConditions.definitive, PayrollConditions.rejected]
                if (!excludeBtnConditions.some(x => x == header.conditionId)) {
                  this.showExcludeBtn = true
                }
                const { payrollPeriodId, payrollPeriodIdRelated, budgetAreaId } = header
                this.versionText = `${this.selectedHeader.header.versionId} - ${this.selectedHeader.header.headerCondition}`
                this.getPeriods(payrollPeriodId, payrollPeriodIdRelated)
                this.getBudgetAreas(budgetAreaId)
              }
            })
          }
        })

        this.privilege = this.authInfo.setPrivileges(this.privilege)

      }
    })


  }



  openModalBulkloadFinalNom() {
    if (!this.periodAndBudgetAreasHasValue()) {
      this._toastService.warning("Debe llenar seleccionar un período y el área")
      return
    }
    this.dialog.open(AdditionalPayrollBulkLoadComponent, {
      data: {
        budgetArea: this.selectedBudgetArea,
        payrollPeriod: this.selectedPeriod,
        headerId: this.headerId,
        payrollPeriodRelated: this.selectedRelatedPeriod
      },
      width: '70%'
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.Applied) {
          this.getOverallSummary();
        }
      });
  }

  getPeriods(payrollPeriodId, payrollPeriodIdRelated) {
    let params: PeriodParams = {
      value: 0,
      type: 0,
      companyId: this.authInfo.getCompanyId(),
      status: true
    }
    this.periodService.getAll(params).subscribe((res: any) => {
      if (res.succeded) {
        this.periods = res.dataList.filter(x => x.conditionId == 1 && x.status == true)
        const payrollPeriod = this.periods.find(x => x.payrollPeriodId == payrollPeriodId)
        if (payrollPeriod) {
          this.selectedPeriod = payrollPeriod
          this.periodDescription = this.selectedPeriod.description
        }
        this.getOverallSummary()
      } else {

      }
    }, err => {

    })

    this.periodService.getBeforePeriod().subscribe((res: any) => {
      if (res.succeded) {
        this.relatedPeriods = res.dataList;
        const payrollPeriodRelated = this.relatedPeriods.find(x => x.payrollPeriodId == payrollPeriodIdRelated)
        if (payrollPeriodRelated) {
          this.selectedRelatedPeriod = payrollPeriodRelated
          this.periodRelatedDescription = this.selectedRelatedPeriod.description
        }
        this.getOverallSummary()

      } else {

      }
    }, err => {

    })
  }

  get isOpenToNews() {
    return this.payrollHeader?.conditionId === headerCondition?.openToNews;
  }

  get isContitionalRejectPayroll() {
    return (this.payrollHeader?.conditionId === headerCondition?.openToNews
      || this.payrollHeader?.conditionId === headerCondition?.audited
      || this.payrollHeader?.conditionId === headerCondition?.simulated
      || this.payrollHeader?.conditionId === headerCondition?.approved);
  }

  getBudgetAreas(budgetAreaId) {
    this.payrollService.getBudgetAreas().subscribe((res: any) => {
      if (res.succeded) {
        this.budgetAreas = res.dataList.filter(d => d.status);
        this.selectedBudgetArea = this.budgetAreas.find(x => x.budgetAreaId == budgetAreaId)
        this.budgetAreaDescription = this.selectedBudgetArea?.description
        this.getOverallSummary()
      } else {

      }
    }, err => {

    })
  }

  changeDropdownBeforePeriod() {
    this.periodDropdownIsDisable = this.selectedRelatedPeriod?.payrollPeriodId < 1



  }

  changeDropdownPeriod() {
    this.areaDropdownIsDisable = this.selectedPeriod?.payrollPeriodId < 1



  }

  clear() {
    this.employeeList = []
    this.selectedRelatedPeriod = null
    this.selectedPeriod = null
    this.selectedBudgetArea = null
    this.periodRelatdDropdownIsDisable = false;
    this.areaDropdownIsDisable = true;
    this.periodDropdownIsDisable = true;
    this.payrollHeader = {
      headerId: 0,
      conditionId: 0
    };
  }

  newsToApprove(employeeId) {
    const periodId = this.selectedPeriod.payrollPeriodId
    this.dialog.open(AdditionalPayrollApproveNewsModalComponent, {
      height: '800px',
      data: {
        budgetArea: this.selectedBudgetArea,
        employeeId,
        periodId,
        header: this.selectedHeader?.header
      }
    })
  }
  changeDropdownArea() {
    this.getOverallSummary();
  }

  getHeader(headerID) {
    this.additionalService.getHeader(headerID).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) { this._toastService.error(res.errors[0]); return }
        if (res.dataList.length == 0) { return }
        this.headerCondition = res.dataList[0].header.headerCondition
      }, error: (error: any) => {
        this._toastService.error('Ha ocurrido un error inesperado')
      }
    })
  }



  getOverallSummary() {
    if (!this.selectedPeriod?.payrollPeriodId || !this.selectedBudgetArea?.budgetAreaId || !this.selectedRelatedPeriod?.payrollPeriodId) {
      return
    }
    const options = {
      type: 2,
      companyId: this.authInfo.getCompanyId(),
      headerId: this.selectedHeader.header.headerId
    }
    this.additionalService.getOverallSummaryByOptions(options).subscribe((res: any) => {
      if (res.succeded) {
        if (res.dataList.length > 0) {
        }
        this.areaDropdownIsDisable = true
        this.periodDropdownIsDisable = true
        this.periodRelatdDropdownIsDisable = true
        this.employeeList = res?.dataList?.map(d => {
          return {
            ...d,
            fullName: `${d.firstName} ${d.lastName}`
          }
        });
        if (res?.dataList?.length > 0) {
          this.payrollHeader = {
            headerId: res?.dataList[0]?.headerId,
            conditionId: res?.dataList[0]?.conditionId
          }
        }
      } else {
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }

  removeEmployee(item) {
    if (item?.conditionId === 3 || item?.conditionId === 5) {
      return
    }
    this.dialog.open(ExcludeEmployeePayrollModalComponent, {
      data: { item },
      width: 'auto'
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (res.success) {
          this.getOverallSummary()
        }
      }

    })
  }
  openRejectModal() {
    this.dialog.open(RejectPayrollModalComponent, {
      width: '50%',
      data: { headerId: this.payrollHeader?.headerId }
    }).afterClosed().subscribe((result: any) => {
      if (result?.saved) {
        this._toastr.success("Nómina Rechazada");
        this.clear();
      }
    })
  }

  periodAndBudgetAreasHasValue(): boolean {
    if (this.selectedBudgetArea.length == 0) return false
    if (this.selectedPeriod.length == 0) return false
    return true
  }

  openSearchEmployee() {
    if (!this.periodAndBudgetAreasHasValue()) {
      this._toastService.warning("Debe llenar seleccionar un período y el área")
      return
    }
    this.dialog.open(AdditionalPayrollSearchModalComponent, {
      data: {
        budgetAreaId: this.selectedBudgetArea.budgetAreaId,
        payrollPeriodId: this.selectedPeriod.payrollPeriodId,
        payrollPeriodIdRelated: this.selectedRelatedPeriod.payrollPeriodId,
        headerId: this.selectedHeader.header.headerId
      },
      width: '80%'
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.success == false) return
        const employee: IEmployeeGET = result.employee

        this.budgetAreaId = result.budgetAreaId
        this.payrollPeriodId = result.payrollPeriodId
        this.getOverallSummary()
      });
  }

  openAddNew(item: IEmployee, modalTitle, mode: 'show' | 'add') {
    const startDate = this.selectedPeriod.startDate
    const endDate = this.selectedPeriod.endDate
    const employeeId = item.employeeId
    this.dialog.open(AdditionalPayrollAddNewsModalComponent, {
      data: {
        startDate,
        endDate,
        modalTitle,
        mode,
        employeeId
      }
    })
  }

  viewNewsDetails(item) {
    const periodId = this.selectedRelatedPeriod.payrollPeriodId
    this.dialog.open(AdditionalPayrollNewsDetailModalComponent, {
      data: {
        item,
        periodId,
        budgetArea: this.selectedBudgetArea,
      }
    })
  }
  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
  }
  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }
  getPaginatedRecords(event) {
    this.paginatedRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.selectedPage = event.selectedPage
  }
  exportToExcel() {
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Servidor Público",
      "Unidad Organizativa",
      "Genero",
      "Ingreso Bruto"
    ]]
    let customItems = [];
    let items = [];
    items = this.filteredList.enable ? this.filteredList.records : this.employeeList;
    if (items.length < 1) return
    customItems = items.map(x => {
      return {
        employeeId: x.employeeId,
        name: x.firstName + x.lastName,
        departmentName: x.departmentName,
        sex: x.sex,
        totalCredits: x.totalCredits,
      }
    })

    const name = this.selectedRelatedPeriod.description ?? ''
    this._exlService.exportToExcelSpecificColumns(customItems, excelHeaders, `Nómina adicional ${name}`);
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }


  onKeyParameterChange(value: { propKeyId?: number, propkey: string, displayName: string } | null) {
    this.searchPropKeySelected = value ?? null;
  }

  exportToPdf() {
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.AdditionalPayrollDetail;
    const periodId = this.selectedPeriod?.payrollPeriodId ?? 0
    const budgetAreaId = this.selectedBudgetArea?.budgetAreaId ?? 0
    const relatedPeriod = this.selectedRelatedPeriod?.payrollPeriodId ?? 0
    const pageSize = this.paginatedRecords.length
    const propKeyId = this.searchPropKeySelected?.propKeyId ?? 0
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&HeaderId=${this.headerId}&PeriodId=${periodId}&BudgetAreaId=${budgetAreaId}&CompanyId=${companyId}&PayrollPeriodIdRelated=${relatedPeriod}&PropKeyId=${propKeyId}&SearchParameter=${this.filterText ?? ''}&Page=${this.selectedPage}&PageSize=${pageSize}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Unidades Organizativas',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

}
