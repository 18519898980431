import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { removeWhitespace } from 'src/app/shared/utils/utility';

@Component({
  selector: 'app-institution-relatives',
  templateUrl: './institution-relatives.component.html',
  styleUrls: ['./institution-relatives.component.css']
})
export class InstitutionRelativesComponent implements OnInit {
  relationShipsList: any[] = []
  nameError = ''
  relativesForm: FormGroup = new FormGroup({
    relationship: new FormControl(null, Validators.required),
    relativeName: new FormControl(null, Validators.required),
  })

  constructor(
    public dialogRef: MatDialogRef<InstitutionRelativesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dialogRef.disableClose = true;
  }


  ngOnInit(): void {
    this.relativesForm.get('relativeName').valueChanges.subscribe({
      next: (res) => {
        this.validateName()
      }
    })
    this.getData()
  }
  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  getData() {
    if (this.data) {
      this.relationShipsList = this.data.relationShips
      if (this.data?.isEditing) {
        this.getRelativeNyId(this.data.relativeInfo)
      }
    }
  }

  getRelativeNyId(relativeInfo) {
    this.relativesForm.get("relativeName").setValue(relativeInfo?.personName)
    this.relativesForm.get("relationship").setValue(this.relationShipsList.find((rel: any) => rel.id === relativeInfo?.relationshipId) ?? null)
  }


  Cancel() {
    this.dialogRef.close()
  }

  removeWhitespaces(event, control){
    removeWhitespace(event,control)
  }

  validateName(){
    const name = this.relativesForm.get('relativeName').value.trim()
    const regex = /^[a-zA-Z\s\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA]{1,}$/g
    const check = regex.test(name)
    this.nameError = ''
    if(name.length < 1){
      this.nameError = 'Este campo es requerido.'
      return
    }
    if(check == false){
      this.nameError = 'Este campo contiene caracteres inválidos.'
      return
    }
  }

  Accept() {
    const name = this.relativesForm.get('relativeName').value.trim()
    if(this.nameError.length > 0){
      return
    }
    if (this.relativesForm.invalid) {
      return
    }
    let relativeInfo = {
      relName: name,
      relationship: this.relativesForm.get('relationship').value
    }
    this.dialogRef.close(relativeInfo)
  }

}
