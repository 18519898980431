import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IHeaderDepartmentEmployeeGET } from '@payroll/audit-approval/Audit-Approval-models';
import { Subscription } from 'rxjs';
import { AuditApprovalService } from 'src/app/services/audit-approval.service';

@Component({
  selector: 'app-p-department-details-modal',
  templateUrl: './p-department-details-modal.component.html',
  styleUrls: ['./p-department-details-modal.component.css']
})
export class PDepartmentDetailsModalComponent implements OnInit, OnDestroy {
  departmentName = ''
  suscriptions = new Subscription()
  dataList: IHeaderDepartmentEmployeeGET[] = []
  dataListPaginated: IHeaderDepartmentEmployeeGET[] = []
  constructor(
    private srvAudit: AuditApprovalService,
    @Inject(MAT_DIALOG_DATA) public data: {headerId: number, departmentId: number, typeEmployeeId: number, departmentName: string},
    public dialogRef: MatDialogRef<PDepartmentDetailsModalComponent>
  ) { }
  ngOnDestroy(): void {
    this.suscriptions.unsubscribe()  
  }

  ngOnInit(): void {
    this.departmentName = this.data.departmentName
    this.getEmployees()
  }
  
  
  
  getEmployees(){
    const sub = this.srvAudit.getDetailsHeaderByDepartment(this.data.headerId, this.data.departmentId, this.data.typeEmployeeId).subscribe((resp:any) => {
      if(resp.succeded){
        const res: IHeaderDepartmentEmployeeGET[] = resp.dataList
        this.dataList = res
        this.dataList.sort((a,b) => b.grossSalary - a.grossSalary)
      }else{
        
      }
    }, err => {
      
    })
    this.suscriptions.add(sub)
  }
  closeModal(){
    this.dialogRef.close();
  }
  
  getPaginatedRecords(event) {
    this.dataListPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

}
