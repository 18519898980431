import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewGetDetailByDeparments } from '@payroll/audit-approval/indicators-modal/models/viewGetDetailByDeparments';
import { ViewGetDetailResumes } from '@payroll/audit-approval/indicators-modal/models/viewGetDetailResumes';
import { ViewGetOverallSummaries } from '@payroll/audit-approval/indicators-modal/models/viewGetOverallSummaries';
import { ToastrService } from 'ngx-toastr';
import { AuditApprovalService } from 'src/app/services/audit-approval.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
interface chart { description: string, value: number }
@Component({
  selector: 'app-dashboard-audit-approval',
  templateUrl: './dashboard-audit-approval.component.html',
  styleUrls: ['./dashboard-audit-approval.component.css']
})
export class DashboardAuditApprovalComponent implements OnInit {
  total = 0

  payrollTypes: any[] = [];
  payrollTypeSeleted: any;


  periods: any[] = [];

  periodsModel: any[] = [];

  viewGetDetailResumes: ViewGetDetailResumes[] = [];
  viewGetDetailResumesOriginalList: ViewGetDetailResumes[] = [];
  viewGetDetailResumesRecords: ViewGetDetailResumes[] = [];

  viewGetDetailByDeparments: ViewGetDetailByDeparments[] = [];
  viewGetDetailByDeparmentsOriginalList: ViewGetDetailByDeparments[] = [];
  viewGetDetailByDeparmentsRecords: ViewGetDetailByDeparments[] = [];

  viewGetOverallSummaries: ViewGetOverallSummaries[] = [];
  viewGetOverallSummariesOriginalList: ViewGetOverallSummaries[] = [];
  viewGetOverallSummariesRecords: ViewGetOverallSummaries[] = [];

  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
  [
    { propkey: "fullName", displayName: "Servidor Público" },
    { propkey: "sex", displayName: "Genero" },
    { propkey: "positionName", displayName: "Cargo" },
    { propkey: "employeeType", displayName: "Tipo" },
  ];

  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  filterText: string = '';
  @ViewChild('paginator') paginator: CaptionComponent;

  constructor(
    private payrollService: PayrollService,
    private _toastr: ToastrService,
    private _ParamService: ParameterControlService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DashboardAuditApprovalComponent>,
    private auditApprovalService: AuditApprovalService,
    private _cdRef: ChangeDetectorRef,
    private authInfoService: AuthInfoService
  ) { 
   
  }
  dropdownSettings = {
    singleSelection: false,
    idField: 'payrollPeriodId',
    textField: 'description',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  ngOnInit(): void {
    this.getPayrollType();
    this.getPeriodByPayrollTypeId();
  }

  getPayrollType(){
    this.auditApprovalService.getPayrollType().subscribe((res: any) => {
      if(res.errors.length > 0){
        this._toastr.error(res.errors[0]);
        return;
      }
      this.payrollTypes = res.dataList;
    }, error => {
      this._toastr.error('Ha ocurrido un errror al inesperado');
    })
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  getPeriodByPayrollTypeId(){
    this.auditApprovalService.getPeriodByPayrollTypeId(0, 
      this.authInfoService.getCompanyId(), 7).subscribe((res: any) => {
         if(res.errors.length > 0){
           this._toastr.error(res.errors[0]);
           return;
         }
         this.periods = res.dataList;
      }, error => {
        this._toastr.error('Ha ocurrido un errror al inesperado');
      })
  }


  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  search(){
    let periods = this.periodsModel?.map(p => p?.payrollPeriodId);
    let payrollTyId = this.payrollTypeSeleted?.ocode;
 
    if(payrollTyId === undefined){
      this._toastr.warning('Debe seleccionar un tipo de nómina');
      return;
    }
    if(periods?.length === 0 ){
      this._toastr.warning('Debe seleccionar por lo menos una nómina');
      return;
    }
    let  periodsString =  periods?.toString() as any;
    periodsString = periodsString?.replaceAll(',', '-');
    this.GetDetailPayrollAudit(periodsString)
  }

  GetDetailPayrollAudit(periodsString){
    const payrollTypeId = this.payrollTypeSeleted?.ocode;
    const companyId = this.authInfoService.getCompanyId();
    const headerId = 0;
    this.auditApprovalService.GetDetailPayrollAudit(periodsString, headerId, payrollTypeId, companyId).subscribe((res: any) => {
      if(res.errors.length){
        this._toastr.error(res.errors[0]);
        return;
      }
      this.viewGetDetailResumes = res.singleData?.viewGetDetailResumes;
      this.viewGetDetailResumesOriginalList =  res.singleData?.viewGetDetailResumes;

      this.viewGetDetailByDeparments = res.singleData?.viewGetDetailByDeparments;
      this.viewGetDetailByDeparmentsOriginalList = res.singleData?.viewGetDetailByDeparments;

      const viewGetOverallSummaries = res.singleData?.viewGetOverallSummaries?.map((e: any) => {
        let employee = {
          ...e,
          fullName: `${e?.firstName} ${e?.lastName}`
        }
        return employee;
      })
      this.viewGetOverallSummaries = [...viewGetOverallSummaries];
      this.viewGetOverallSummariesOriginalList = [...viewGetOverallSummaries];
    }, error => {
      this._toastr.error('Ha ocurrido un errror al inesperado');
    })
  }

  getPaginatedRecordsResumes(event){
    this.viewGetDetailResumesRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  getPaginatedRecordsDeparments(event){
    this.viewGetDetailByDeparmentsRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  getPaginatedOverallSummaries(event){
    this.viewGetOverallSummariesRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  getTotalOverallSummarie(total){
    const viewGetOverallSummaries = this.filteredList?.enable ? this.filteredList.records : 
    this.viewGetOverallSummaries;
    const totalOverallSummaries = viewGetOverallSummaries?.reduce((previous, current) => previous + current[total], 0);
    return totalOverallSummaries;
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
  }

  onKeyChange(text) {
    this.filterText = text;
  }
  
}