import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-separations-detail-bulk-load',
  templateUrl: './separations-detail-bulk-load.component.html',
  styleUrls: ['./separations-detail-bulk-load.component.css']
})
export class SeparationsDetailBulkLoadComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SeparationsDetailBulkLoadComponent>,
  ) { }

  ngOnInit(): void {
  }

  close(): void
  {
    this.dialogRef.close();
  }

}
