<div class="row">
    <div class="form-group col-lg-3">
        <label>Sueldo Actual <span class="text-danger">*</span></label>
        <input type="text" class="form-control form-control-lg" />
    </div>
    <div class="form-group col-lg-3">
        <label>Sueldo Anterior </label>
        <input type="text" class="form-control form-control-lg" />
    </div>
    <div class="form-group col-lg-6">
        <label class="d-block">Forma Pago</label>
        <div class="d-flex">
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    Transferencia
                    <input type="radio" class="form-check-input ml-3" /> 
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    Cheque
                    <input type="radio" class="form-check-input ml-3" /> 
                </label>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="form-group col-lg-6">
        <label>&nbsp;</label>
        <div class="d-flex">
            <div class="card flex-row align-items-center justify-content-center flex-grow-1 mr-3 mb-3 p-2">
                <strong class="mx-4">Horas Extras</strong>
                <span class="switch switch-sm switch-primary mx-2">
                    <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
                </span>
            </div>
            <div class="card flex-row align-items-center justify-content-center flex-grow-1 mr-3 mb-3 p-2">
                <strong class="mx-4">Dietas</strong>
                <span class="switch switch-sm switch-primary mx-2">
                    <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
                </span>
            </div>
        </div>
    </div>
    <div class="form-group col-lg-6">
        <label>Cuenta Banco <span class="text-danger">*</span></label>
        <input type="text" class="form-control form-control-lg" />
    </div>
    
</div>
<div class="col-md-12 gutter-t">
    <div class=" px-5">
        <div class="form-group d-flex justify-content-between align-items-center p-3">
            <button [routerLink]="['/employees']"  class="btn btn-pill font-weight-bolder btn-shadow btn-warning mr-3 ">
                <i class="flaticon2-left-arrow-1"></i>
                <span>Volver</span>
            </button> 
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-success ">
                <i class="flaticon-disco-flexible"></i>
                <span>Guardar</span>
            </button>                   
        </div>
    </div>
</div>