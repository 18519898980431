import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { userGuideNames } from 'src/app/models/userGuideEnum';

@Component({
  selector: 'app-bread-crum',
  templateUrl: './bread-crum.component.html',
  styleUrls: ['./bread-crum.component.scss'],
})
export class BreadCrumComponent implements OnInit, OnDestroy {
  routes = [
    {
      url: '/?language=',
      title: 'HRM',
      text: 'Dashboard',
    },
    {
      url: '/request-no-objection-map',
      title: 'Reclutamiento y Selección',
      text: 'Solicitar No Objeción',
    },
    {
      url: '/user-guide',
      title: 'Guía de Usuario',
      text: '',
    }, {
      url: '/interview-list',
      title: 'Reclutamiento y Selección',
      text: 'Entrevista y Evaluación',
    },
    {
      url: '/interview-evaluation',
      title: 'Reclutamiento y Selección',
      text: 'Entrevista y Evaluación',
      subtext: 'Hacer Entrevista',
      link: '/interview-list'
    },
    {
      url: '/employee-personnel-actions-list',
      title: 'Registro y Control',
      text: 'Consulta de Acciones de Personal',
    },
    {
      url: '/view-estructure',
      title: 'Estructura Orgánica',
      text: 'Ver Estructura',
    },
    {
      url: '/planning/planning-opening',
      title: 'Planificación',
      text: 'Apertura de Planificación',
    },
    {
      url: '/planning/detail-planning',
      title: 'Planificación',
      text: 'Detalles de la planificación',
    },
    {
      url: '/planning/request-positions-vacancies',
      title: 'Planificación',
      text: 'Solicitar Cargos y Plazas',
    },
    {
      url: '/planning/application-for-positions-and-vacancies-rrhh',
      title: 'Planificación',
      text: 'Aprobar Solicitud de Cargos y Plazas',
    },
    {
      url: '/planning/generate-planning',
      title: 'Planificación',
      text: 'Generar planificación',
    },
    {
      url: '/planning/authorize-planning',
      title: 'Planificación',
      text: 'Revisar y Autorizar Planificación',
    },
    {
      url: '/planning/reports',
      title: 'Planificación',
      text: 'Informes',
    },
    {
      url: '/planning/list-vacation-scheduling',
      title: 'Planificación',
      text: 'Programar Vacaciones',
    },
    {
      url: '/planning/detail-vacation-scheduling',
      title: 'Planificación',
      text: 'Reporte de Programación de Vacaciones',
    },
    {
      url: '/vacancies/post-vacancies',
      title: 'Reclutamiento y Selección',
      text: 'Requisiciones / Plazas Aprobadas',
      subtext: 'Habilitar vacantes',
      link: '/vacancies'
    }, {
      url: '/vacancies',
      title: 'Reclutamiento y Selección',
      text: 'Requisiciones / Habilitar Vacantes'
    }, {
      url: '/hcm/job-application',
      title: 'Reclutamiento y Selección',
      text: 'Solicitud de Empleo',
      subtext: 'Formulario de solicitud de Empleo',
      link: '/hcm/personal-request'
    }, {
      url: '/personal-requisition',
      title: 'Reclutamiento y Selección',
      text: 'Requisición de Personal',
    }, {
      url: '/staff-requirement-summary',
      title: 'Reclutamiento y Selección',
      text: 'Requisición de Personal',
      subtext: 'Detalle de Requisición',
      link: '/personal-requisition'
    }, {
      url: '/candidate-registration-list',
      title: 'Reclutamiento y Selección',
      text: 'Registro de Personas',
    }, {
      url: '/candidate-registration',
      title: 'Reclutamiento y Selección',
      text: 'Registro de Personas',
    }, {
      url: '/candidate-registration',
      title: 'Registro y Control',
      text: 'Registro de Servidores Públicos',
    }, {
      url: '/pdf-preview',
      title: 'Reclutamiento y Selección',
      text: 'Registro de Personas',
      subtext: 'Detalle de Persona',
      link: '/candidate-registration-list'
    }, {
    }, {
      url: '/apply-vacancies',
      title: 'Reclutamiento y Selección',
      text: 'Aplicar a Vacantes',
    }, {
      url: '/proof-applicants',
      title: 'Reclutamiento y Selección',
      text: 'Prueba de Postulantes',
    }, {
      url: '/job-offer',
      title: 'Reclutamiento y Selección',
      text: 'Vinculación',
    }, {
      url: '/approval-employee-change-actions',
      title: 'Registro y Control',
      text: 'Aprobación Acciones de Cambios a Servidores Públicos',
    }, {
      url: '/personnel-actions',
      title: 'Registro y Control',
      text: 'Acciones de Personal',
    }, {
      url: '/incidents',
      title: 'Evaluación del Desempeño',
      text: 'Incidentes',
    }, {
      url: '/annual-operative-plan-list',
      title: 'Evaluación del Desempeño Laboral',
      text: 'POA-PEI',
    }, {
      url: '/map/no-objection',
      title: 'MAP',
      text: 'No Objeción',
    }, {
      url: '/employees',
      title: 'Registro y control',
      text: 'Servidores Públicos',
    },
    {
      url: '/action-change-employee',
      title: 'Registro y control',
      text: ' Servidor Público',
      subtext: 'Acciones del Personal',
      link: '/employees'
    },
    {
      url: '/pdf-preview-action-change/',
      title: 'Registro y control',
      text: 'Acciones del Personal',
      subtext: 'Detalle de acción de Cambio',
      link: '/action-change-employee'
    },
    {
      url: '/collective-actions',
      title: 'Registro y control',
      text: 'Acciones Colectivas',
    },
    {
      url: '/collective-vacations',
      title: 'Registro y control',
      subtext: 'Consulta Vacaciones Colectivas',
      text: 'Acciones Colectivas',
    },
    {
      url: '/consult-collective-vacations',
      title: 'Registro y control',
      text: 'Consulta Vacaciones Colectivas',
    },
    {
      url: '/separations',
      title: 'Registro y control',
      subtext: 'Desvinculaciones Masivas',
      text: 'Acciones Colectivas',
    },
    {
      url: '/vinculations',
      title: 'Registro y control',
      text: 'Vinculaciones',
    },
    {
      url:'/change-supervisor',
      title:'Registro y control',
      text:'Cambio de Supervisor'
    },
    {
      url: '/hourly-teachers',
      title: 'Registro y control',
      text: 'Docente por Asignatura',
    },
    {
      url: '/employee-service-request',
      title: 'Registro y control',
      text: 'Solicitudes de Servicios',
    },
    {
      url: '/birthday',
      title: 'Registro y control',
      text: 'Cumpleaños',
    },
    {
      url: '/content-management',
      title: 'Gestión de Contenido',
      text: 'Contenido',
    },
    {
      url: '/links',
      title: 'Gestión de Contenido',
      text: 'Enlaces',
    },
    {
      url: '/open-payroll/normal',
      title: 'Nómina',
      text: 'Nómina Normal',
    },
    {
      url: '/open-payroll/regalia',
      title: 'Nómina',
      text: 'Nómina Regalía',
    },
    {
      url: '/payroll/payroll-royalty-summary',
      title: 'Nómina',
      subtext: 'Resumen por mes',
      text: 'Nómina Regalía',
    },
    {
      url: '/payroll/news-list',
      title: 'Nómina',
      text: 'Registro de Novedades',
    },
    {
      url: '/payroll/payroll-home',
      title: 'Nómina',
      text: 'Apertura de Nómina',
    },
    {
      url: '/payroll/incentive-payroll',
      title: 'Nómina',
      text: 'Nómina de Incentivos sin Aportes',
    },
    {
      url: '/payroll/additional-payroll',
      title: 'Nómina',
      text: 'Nómina Adicional',
    },
    {
      url: '/payroll/beneficiaries',
      title: 'Nómina',
      text: 'Beneficiarios de Conceptos de Nómina',
    },
    {
      url: '/payroll/statistics',
      title: 'Nómina',
      text: 'Estadística de Nóminas',
    },
    {
      url: '/payroll/manage-payroll',
      title: 'Nómina',
      text: 'Generar Nómina',
    },
    {
      url: '/payroll/audit-approval',
      title: 'Nómina',
      text: 'Auditar y Aprobar',
    },
    {
      url: '/payroll/process-payroll',
      title: 'Nómina',
      text: 'Archivos de Nómina',
    },
    {
      url: '/payroll/payroll-report',
      title: 'Nómina',
      text: 'Generar Reportes',
    },
    {
      url: '/payroll-report/indicators-payroll-comparison-report',
      title: 'Nómina',
      text: 'Generar Reporte de Comparativa de Nómina de los Indicadores',
    },
    {
      url: 'payroll-report/parcial-payroll',
      title: 'Nómina',
      text: 'Reporte Nómina Parcial',
    },
    {
      url: '/payroll-report/audit',
      title: 'Nómina',
      text: 'Reporte de Auditoria de Nómina',
    },
    {
      url: '/payroll/payment-flyer',
      title: 'Nómina',
      text: 'Comprobante de pago',
    },
    {
      url: '/payroll/budget-areas',
      title: 'Nómina',
      text: 'Áreas Presupuestales',
    },
    {
      url: '/schedule',
      title: 'Nómina',
      text: 'Período',
    },
    {
      url: '/payroll-report/payroll-comparison-report',
      title: 'Nómina',
      text: 'Comparativo de Nómina',
    },
    {
      url: '/departament-list',
      title: 'Estructura Orgánica',
      text: 'Unidades Organizativas',
    },
    {
      url: '/department-changes',
      title: 'Estructura Orgánica',
      text: 'Acciones de cambio de la Unidad Organizativa',
    },
    {
      url: '/positions-list',
      title: 'Estructura Orgánica',
      text: 'Cargos',
    },
    {
      url: '/positionsList-description',
      title: 'Estructura Orgánica',
      text: 'Descripción de Cargos',
    },
    {
      url: '/pdf-positions-description',
      title: 'Estructura Orgánica',
      text: 'Descripción de Cargos',
    },
    {
      url: '/telephone-directory',
      title: 'Estructura Orgánica',
      text: 'Directorio Telefónico'
    },
    {
      url: '/position-changes',
      title: 'Estructura Orgánica',
      text: 'Acciones del Cargo'
    },
    {
      url: '/payroll/payroll-detail',
      title: 'Nómina',
      text: 'Apertura de Nómina',
      subtext: 'Detalle de Nómina Aperturada',
      link: '/open-payroll'
    },
    {
      url: 'payroll/final-payroll',
      title: 'Nómina',
      text: 'Nómina de Prestaciones Laborales',
    },
    {
      url: '/payroll/payroll-concept',
      title: 'Nómina',
      text: 'Conceptos de Nómina',
    },
    {
      url: '/assist-control',
      title: 'Registro y Control',
      text: 'Control de Asistencia',
    },
    {
      url: '/medical-history/employee-health',
      title: 'Historial de Salud',
      text: 'Historial de Salud',
    },
    {
      url: '/medical-history/medical-record',
      title: 'Historial de Salud',
      text: 'Expediente Médico',

    },
    {
      url: '/medical-history/physicalinventory',
      title: 'Historial de Salud',
      text: 'Inventario Físico',
    },
    {
      url: '/medical-history/medical-consultation',
      title: 'Historial de Salud',
      text: 'Consultas Médicas',
    },
    {
      url: '/medical-history/medical-journey',
      title: 'Historial de Salud',
      text: 'Jornadas Médicas',
    },
    {
      url: '/medical-history/requisitions',
      title: 'Historial de Salud',
      text: 'Requisición de Medicamentos y Suministros',
    },
    {
      url: '/medical-history/requisitionreport',
      title: 'Historial de Salud',
      text: 'Reporte de Requisición de Medicamentos y Suministros',
    },
    {
      url: '/medical-history/requisitionsedit',
      title: 'Historial de Salud',
      text: 'Detalle de la Requisición',
    },
    {
      url: '/medical-history/reception-medicines-supplies',
      title: 'Historial de Salud',
      text: 'Recepción de Medicamentos y Suministros',
    },
    {
      url: '/medical-history/inventory-movements',
      title: 'Historial de Salud',
      text: 'Movimientos de Inventario',
    },
    {
      url: '/medical-history/existence',
      title: 'Historial de Salud',
      text: 'Existencia',
    },
    {
      url: '/employee-service-request',
      title: 'Registro y control',
      text: 'Solicitudes de Servicios',
    },
    {
      url: '/reports',
      title: 'Registro y control',
      text: 'Reportes',
    },
    {
      url: '/report/certification-report',
      title: 'Registro y control',
      text: 'Reporte de Certificación ',
    },
    {
      url: '/report/occupational-groups-report',
      title: 'Registro y control',
      text: 'Reporte Grupo Ocupacional ',
    },
    {
      url: '/report/employee-birthday-report',
      title: 'Registro y control',
      text: 'Reporte Cumpleaños Servidores Públicos ',
    },
    {
      url: '/report/age-children-employees-report',
      title: 'Registro y control',
      text: 'Reporte Hijos de Servidores Públicos ',
    },
    {
      url: '/performance-evaluation/evaluations',
      title: 'Evaluación del Desempeño Laboral',
      text: 'Evaluaciones',
    },
    {
      url: '/performance-evaluation/organizational-unit-monitoring',
      title: 'Evaluación del Desempeño Laboral',
      text: 'Monitoreo de los Acuerdos del Desempeño',
    },
    {
      url: '/performance-evaluation/organizational-unit-plan-improvement',
      title: 'Evaluación del Desempeño Laboral',
      text: 'Plan de Mejora Unidad Organizativa',
    },
    {
      url: '/performance-evaluation/competency-dictionary',
      title: 'Evaluación del Desempeño Laboral',
      text: 'Diccionario de Competencias',
    },
    {
      url: '/performance-evaluation/goal-management',
      title: 'Evaluación del Desempeño Laboral',
      text: 'Metas',
    },
    {
      url: '/performance-evaluation/generate-report',
      title: 'Evaluación del Desempeño Laboral',
      text: 'Indicadores',
    },
    {
      url: '/payroll-report/individual-payroll',
      title: 'Nómina',
      text: 'Relación de Servidores Público con Conceptos',
    },
    {
      url: '/payroll/payroll-general',
      title: 'Nómina',
      text: 'Reporte de Nómina General',
    },
    {
      url: '/payroll-report/overtimes-report',
      title: 'Nómina',
      text: 'Reporte de Horas Extras',
    },
    {
      url: '/payroll-report/employee-concepts-report',
      title: 'Nómina',
      text: 'Reporte de Nómina de Concepto Individual',
    },
    {
      url: '/payroll-report/keys-configuration',
      title: 'Nómina',
      text: 'Reporte de Configuración de Llave Presupuestaria',
    },
    {
      url: '/report/payroll-transparency',
      title: 'Registro y control',
      text: 'Reporte de Nómina para Transparencia',
    },
    {
      url: '/employee-response-request',
      title: 'Nómina',
      text: 'Certificaciones de Retención de Impuestos y TSS',
    },
    {
      url: '/payroll/certification-codes',
      title: 'Nómina',
      text: 'Códigos Certificación',
    },
    {
      url: '/training-development/internal-course-catalogs',
      title: 'Capacitación y Desarrollo',
      text: 'Catálogo de Cursos Internos',
    },
    {
      url: '/training-development/curricula',
      title: 'Capacitación y Desarrollo',
      text: 'Plan de Estudio',
    },
    {
      url: '/training-development/training-schedule',
      title: 'Capacitación y Desarrollo',
      text: 'Calendario de Capacitaciones',
    },
    {
      url: '/training-development/training-request',
      title: 'Capacitación y Desarrollo',
      text: 'Solicitud de Capacitaciones',
    },
    {
      url: '/training-development/training-development-plan',
      title: 'Capacitación y Desarrollo',
      text: 'Plan de Capacitación y Desarrollo Institucional',
    },
    {
      url: '/training-development/training-certificates',
      title: 'Capacitación y Desarrollo',
      text: 'Certificados',
    },
    {
      url: '/training-development/training-reports',
      title: 'Capacitación y Desarrollo',
      text: 'Reportes',
    },
    {
      url: '/training-development/training-group',
      title: 'Capacitación y Desarrollo',
      text: 'Grupos de Capacitación',
    },
    {
      url: '/training-development/external-course-catalogs',
      title: 'Capacitación y Desarrollo',
      text: 'Catálogo de Cursos Externos',
    },
    {
      url: '/training-development/training-request-user',
      title: 'Capacitación y Desarrollo',
      text: 'Solicitud de Capacitaciones',
    },
    {
      url: '/training-development/provider-catalogs',
      title: 'Capacitación y Desarrollo',
      text: 'Proveedores',
    },
    {
      url: '/training-development/training-dashboard',
      title: 'Capacitación y Desarrollo',
      text: 'Dashboard',
    },
    {
      url: '/overtime-and-travel-expenses/overtime',
      title: 'Horas extras y viaticos',
      text: 'Horas extras y viaticos',
      subtext: 'Horas extras',
      link: '/overtime-and-travel-expenses'
    },
  ];


  user_guide = userGuideNames

  routerSubcribe?: Subscription;
  previousUrl: string
  currentRouter?: any;
  constructor(public router: Router) {

  }

  ngOnInit(): void {
    this.router.events
    .pipe(filter((e: any) => e instanceof RoutesRecognized),
      pairwise()
    ).subscribe((e: any) => {
      this.previousUrl = e[0].urlAfterRedirects
      if (this.previousUrl == '/employees') {
        let actionChangeUrl =  this.routes.find((route:any)=> route?.url === '/action-change-employee')
        actionChangeUrl.text = 'Servidor Público'
        actionChangeUrl.link = '/employees'
       }
       else if (this.previousUrl == '/employee-personnel-actions-list') {
        let actionChangeUrl =  this.routes.find((route:any)=> route?.url === '/action-change-employee')
        actionChangeUrl.text = 'Consulta de Acciones de Personal'
        actionChangeUrl.link = '/employee-personnel-actions-list'
       }
    });

    this.routerSubcribe = this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationStart) {
          const path = event.url;
          const split = path?.split('/')
          const employeeRegistration = path?.includes('/candidate-registration')
          if (split.length > 3 && employeeRegistration) {
            this.currentRouter = this.routes.find(x => x.text == 'Servidores Públicos')
            return;
          }
          if (path == '/') {
            this.currentRouter = this.routes[0];
            return;
          }
          this.setUserGuides(path)
          this.currentRouter = this.routes.find((route) => path.includes(route.url));
        }

      });
  }

  ngOnDestroy(): void {
    this.routerSubcribe.unsubscribe();
  }

  setUserGuides(path) {
    if (path.includes('user-guide/')) {
      const split = path.split('/')
      const item = this.user_guide.find(x => x.name == split[split.length - 1])
      const route = this.routes.find(x => x.url == '/user-guide')
      if (item) {
        route.text = item.text
      } else {
        route.text = ''
      }
    }
  }
}
