import { SweetAlert } from '../../../shared/utils/Sweet-Alert';
import { FormatTypes } from '../../../shared/dynamic-format/enums/format-types';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { employeeExitTableData } from '../../../models/employee-charge.model';
import { EmployeesComponent } from '../employees/employees.component';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ToastrService } from 'ngx-toastr';
import { docService } from 'src/app/services/doc.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
declare const $: any;

@Component({
  selector: 'app-employee-exit-verification',
  templateUrl: './employee-exit-verification.component.html',
  styleUrls: ['./employee-exit-verification.component.scss']
})
export class EmployeeExitVerificationComponentMockup implements OnInit, AfterViewChecked {
  public photoUrl: string = ''
  public loadingPic: boolean = true
  AlertSweet = new SweetAlert();
  model = {};
  formattedList: any[] = [];
  loading = false;
  typeExitList: any[] = [];
  reasonExitList: any[] = [];
  employeeId: number;
  postulationId: number;
  searchTextFilter;
  employeesDetailsOriginal;
  unrelated: boolean = false;
  exitReasonDescription;
  personId: number;
  vacantId: number;

  yesNo = [
    { value: true, text: 'Sí' },
    { value: false, text: 'No' }
  ];

  employeeDropdown = [
    { value: 1, text: 'Alejandro Martinez' },
    { value: 2, text: 'Maria Rodriguez' },
    { value: 3, text: 'Susana Fernandez' }
  ]



  records = employeeExitTableData;

  headers = [
    { label: 'Servidor Público', key: 'employee', view: { detail: true, table: true } },
    { label: 'Tipo de Servidor Público', key: 'employeeType', view: { detail: true, table: true } },
    { label: 'Unidad Organizativa', key: 'department', view: { detail: true, table: true } },
    { label: 'Posición', key: 'position', view: { detail: true, table: true } },
    { label: 'Motivo', key: 'motive', view: { detail: true, table: true } },
    { label: 'Fecha Efectiva de Salida', key: 'exitEfectiveDate', view: { detail: true, table: true }, format: FormatTypes.Date },
  ]

  config = {
    displayKey: "stringData",
    search: true,
    height: 'auto', //height of the liilts to auto. With auto height scroll will never appear
    placeholder: 'Elige una opción', //i    customComparator: ()=>{}, // a cus ndefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits e limited)
    moreText: 'more', // text to be di 
    noResultsFound: 'Sin resultados!',
    searchPlaceholder: 'Buscar', // lab
    searchOnKey: 'stringData', // key on whiwill be selective search. if undefined this will be extensive search on all keys
  }

  lablesDetails: string[] = [];
  detailsValues: string[] = [];
  formatValues: string[] = [];
  employeesDetails: any[] = [];

  constructor(private _Service: EmployeesService,
    private _fb: FormBuilder,
    private _changeDet: ChangeDetectorRef,
    private _parameterService: ParameterControlService,
    private toastr: ToastrService,
    private srvDoc: docService,
    private authInfo: AuthInfoService) {
    this.initializeForm();
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  form: FormGroup;

  ngOnInit(): void {
    this.getEmployees();
    this.getTypeExit();
    this.getReasonExit();
  }

  search(search: string) {

  }

  initializeForm() {
    this.form = this._fb.group({
      employeeId: 0,
      employNumber: '',
      //firstName:	"",
      //lastName:	"",
      postulationId: 0,
      //sendMapId:	"",
      //positionId:	"",
      //departmentId:	"",
      //typeEmployeeId:	"",
      employeeType: "",
      //admissionDate:	"",
      //contractExpiration:	"",
      //typeContractId:	"",
      //contractType:	"",
      //branchId:	"",
      //entityBankId:	"",
      //bankingEntity: "",
      //bankAccount:	"",
      exitTypeId: 0,
      exitReason: null,
      receptionExitDate: "",
      processingExitDate: "",
      electiveDepartureDate: "",
      //preAdvised:	false,
      //unemployment:	false,
      //holidays:	null,
      //christmasSalary: null,
      //observations:	null,
      //evidence:	null,
      //effectiveDate:	"",
      //departureLetter:	false,
      //closingAccount:	false,
      //officekeys:	false,
      //companyId:this.authInfo.getCompanyId(),
      status: true,
      //personalIdentification:	"",
      //positionName:	"",
      departmentName: "",
      personId: 0,
      //vacantId:	"",
      //isSupervisor:	false,
      //authorizedData:	true,
      //comment: "",
      //condition:	"",
      //countInterviews: "",
      //countTest: "",
      //dateApplication: ""
    })
  }

  update() {
    this.form.get("employNumber").value;
    this.form.get("employeeType").value;
    this.form.get("departmentName").value;
    this._Service.UpdateEmployee(this.form).subscribe(data => {

    }, error => {
    })
  }

  updateVinculacion() {
    var desvincular: any = {
      employeeId: this.employeeId,
      postulationId: this.postulationId,
      employNumber: this.form.get("employNumber").value,
      departmentName: this.form.get("departmentName").value,
      exitTypeId: this.form.get("exitTypeId").value.ocode,
      exitReason: this.form.get("exitReason").value.ocode,
      electiveDepartureDate: this.form.get("electiveDepartureDate").value,
      processingExitDate: this.form.get("processingExitDate").value,
      receptionExitDate: this.form.get("receptionExitDate").value,
      exitReasonDescription: this.exitReasonDescription,
      companyId: this.authInfo.getCompanyId(),
      sendMapId: 1,
      positionId: 1,
      departmentId: 1000,
      typeEmployeeId: 1,
      typeContractId: 1,
      branchId: 1,
      entityBankId: 0,
      personId: this.personId,
      vacantId: this.vacantId,
      employeeBenefictContract: [
        {
          ecbid: 0,
          employeeId: 0,
          benefitId: 0,
          companyId: this.authInfo.getCompanyId(),
          status: true
        }
      ]
    }
    
    this._Service.UpdateEmployee(desvincular).subscribe((data: any) => {
      if (data.succeded == false) {
        this.toastr.error(data.errors[0]);
        return
      }
      document.getElementById("btnClose").click();
      this.getEmployees();
      this.toastr.success("El registro ha sido desvinculado con éxito", "Confirmación");
    }, error => {
    })
  }

  Modify(value: any) {
    this.employeeId = value.employeeId;
    this.postulationId = value.postulationId;
    this.exitReasonDescription = value.exitReasonDescription;
    this.personId = value.personId;
    this.vacantId = value.vacantId;
    this.form.patchValue({
      employNumber: value.employNumber,
      employeeType: value.employeeType,
      departmentName: value.departmentName
    })
  }

  filterPersons() {
    this.employeesDetails = this.employeesDetailsOriginal;
    if (!isNaN(this.searchTextFilter) && this.searchTextFilter.toString().length < 4) {
      this.employeesDetails = this.employeesDetails.filter(x => x.employNumber.toString().indexOf(this.searchTextFilter) !== -1)
    }
    else if (!isNaN(this.searchTextFilter) && this.searchTextFilter.toString().length > 4) {
      this.employeesDetails = this.employeesDetails.filter(x => x.personalIdentification.indexOf(Number(this.searchTextFilter)) !== -1)
    }
    else if (this.searchTextFilter.toString().length > 0) {
      this.employeesDetails = this.employeesDetails.filter(x => x.name.toLowerCase().indexOf(this.searchTextFilter.toLowerCase()) !== -1)
    }

  }

  tableToggle() {
    this.employeesDetails = [];
    if (this.unrelated) {
      this.unrelated = false;
    }
    else {
      this.unrelated = true;
    }
    this.getEmployees()
  }

  getEmployees() {
    this._Service.getEmployee(!this.unrelated).subscribe((data: any) => {
      if (data.succeded == false) {
        this.toastr.error(data.errors[0]);
        return
      }

      if (data.person.profileImage != '' && data.person.profileImage != null) {
        this.srvDoc.getDocument(data.profileImage).subscribe(e => {
          this.photoUrl = e.data;
          this.loadingPic = false
        }, error => {
          this.loadingPic = false
        })
      } else {
        this.loadingPic = false
      }
      this.employeesDetails = data.dataList;
      this.employeesDetailsOriginal = data.dataList;
    }, error => {
    })
  }

  getTypeExit() {
    this._parameterService.getParameters(keyWord.TypeExit).subscribe((data: any) => {
      if (data.succeded) {        
        this.typeExitList = data.dataList;
      } else {
        this.toastr.error(data.errors[0]);
      }
    })
  }

  getReasonExit() {
    this._parameterService.getParameters(keyWord.ReasonExit).subscribe((data: any) => {
      if (data.succeded) {        
        this.reasonExitList = data.dataList;
      } else {
        this.toastr.error(data.errors[0]);
      }
    })
  }

  add() {
    $('#modal4').modal('show');
    this.model = {};
  }

  view(rowData: object): void {
    $('#modelView').modal('show')
    for (const iterator of this.headers) {
      if (
        iterator['view'] &&
        iterator['view'].hasOwnProperty('detail') &&
        iterator['view']['detail'] === true
      ) {
        this.lablesDetails[iterator['key']] = iterator['label'];
        this.detailsValues[iterator['key']] = rowData[iterator['key']];
        this.formatValues[iterator['key']] = this.headers.find(
          (header) => header.key === iterator['key']
        )?.format;
      }
    }
  }

  edit(record) {
    $('#modal4').modal('show');
    this.model = record
  }
  verification(record) {
    $('#verificacionSalida').modal('show');
    this.model = record
  }

  async delete() {
    const success = await this.AlertSweet.AlertConfirm(
      'Inactivar',
      `¿Seguro que desea inactivar el registro?`,
      'error'
    );
  }

  close() {
    $('#modal4').modal('hide');
  }

  closeV() {
    $('#verificacionSalida').modal('hide')
  }

  save() {
    this.loading = true
    setTimeout(() => {
      this.loading = false;
      $('#modal4').modal('hide')
    }, 1000)

  }
  ApplySearch() { }

  getPaginatedRecords(event) {
    this.formattedList = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  finish() {
    this.form.reset();
  }
}
