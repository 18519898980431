import { formatDate } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { InterviewEvaluationService } from 'src/app/services/interview-evaluation.service';
import { FilterEvent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { InterviewDashboardComponent } from './dashboard/interview-dashboard/interview-dashboard.component';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';

declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'app-interview-list',
  templateUrl: './interview-list.component.html',
  styleUrls: ['./interview-list.component.css']
})
export class InterviewListComponent implements OnInit, AfterViewChecked {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'EVALENTREVISTA',
    interviewAction: 'INTERV',
    dashboardAction: 'DASH',
    viewAction: 'VIEW',
    exportAction: 'EXPORT',
  }
  searchparameterlocalstorage: string = 'interview-list_filter_search';
  interviewList: any[] = [];
  interviewListRecords: any[] = [];
  @ViewChild('paginator') paginator: CaptionComponent;
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  filterText: string | number | any = '';
  @Input() defaultParameterValue: { propkey: string, displayName: string }
  interviewListOriginal: any[] = [];
  dataList: any[] = [];
  companies: [] = [];
  interviewsPagedResut = new PagedResult<any>();
  searchParameter: FilterEvent;
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchList: any[] = [];
  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Secuencia" },
      { propkeyId: 2, displayName: "Cargo" },
      { propkeyId: 3, displayName: "Candidato" },
      { propkeyId: 4, displayName: "Documento de Identidad", default: true },
      { propkeyId: 5, displayName: "Unidad organizativa" },
      { propkeyId: 6, displayName: "Entrevistas Realizadas" },
    ];

  selectedItems = [];

  statusOptions = [
    { item_id: 0, item_text: 'En Proceso', isCondition: true, condition: 0, status: true },
    { item_id: 1, item_text: 'Enviadas al MAP', isCondition: true, condition: 1, status: true },
    { item_id: 3, item_text: 'Nombrados', isCondition: true, condition: 3, status: true },
    { item_id: 4, item_text: 'Reservadas para Nombramiento', isCondition: true, condition: 4, status: true },
    { item_id: 2, item_text: 'Aprobadas por el MAP', isCondition: true, condition: 2, status: true },
    { item_id: 5, item_text: 'Rechazadas por el MAP', isCondition: true, condition: 5, status: true },
    { item_id: 10, item_text: 'Nombramiento Anulado', isCondition: true, condition: 10, status: true }
  ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  canUseOption = false
  config = {
    displayKey: 'departmentName',
    search: true,
    placeholder: 'Unidades Organizativas', // text to be displayed when no item is selected defaults to Select,
    noResultsFound: 'No se han encontrado resultados',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'departmentName',
    clearOnSelection: false,
  };

  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
  }

  constructor(private service: InterviewEvaluationService,
    private _cdRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private _xlsxService: ExportToExcelService,
    private dialog: MatDialog,
    public authInfo: AuthInfoService
  ) {
  }

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem("interviews-list_filter"))?.length > 0) {
      this.selectedItems = JSON.parse(localStorage.getItem("interviews-list_filter"));
    }
    this.setFilterStorage();
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.canUseOption = true
        this.getInterviews();
      }
    });
    applySort();
  }
  private setFilterStorage(){
    const localStorageSearch: FilterEvent = JSON.parse(localStorage.getItem(this.searchparameterlocalstorage));
    this.searchParameter = localStorageSearch;
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  filterSearch(event: FilterEvent) {
    this.interviewsPagedResut.page = 1
    this.searchParameter = event
    this.getInterviews()
  }

  getInterviews() {
    if(!this.canUseOption){
      return
    }
    const page = this.interviewsPagedResut.page
    const pageSize = this.interviewsPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const condition = this.selectedItems?.map((condition: any) => condition.item_id)?.join('-')
    this.service.getPaginatedPostulations(page, pageSize, propKeyId, text, 1, condition).subscribe((data: any) => {
      if (data.succeded) {
        data.singleData.items.forEach(element => {
          switch (element.condition) {
            case 1:
              element.conditionDescription = 'Enviada al MAP'
              break;
            case 2:
              if(element.requisitionTypeId === 5){
                element.conditionDescription = 'Aprobada'
              }else{
                element.conditionDescription = 'Aprobada por el MAP'
              }
              break;
            case 3:
              element.conditionDescription = 'Nombrado'
              break;
            case 4:
              element.conditionDescription = 'Reservado para Nombramiento'
              break;
            case 5:
              element.conditionDescription = 'Rechazado por el MAP'
              break;
            case 6:
              element.conditionDescription = 'Devuelta para Revisión'
              break;
            default:
              break;
          }
        });
        this.interviewList = data.singleData.items;
        this.interviewListOriginal = [...data.singleData.items];
        this.interviewsPagedResut = data.singleData
      } else {
        this.toastr.error(data.errors[0])
      }
    }, error => {
      this.toastr.error(this.errorMessage)
    })
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.getInterviews();
    this.setFilterToLocalStorage();
  }
  onItemDeSelect(item: any) {
    this.getInterviews();
    this.setFilterToLocalStorage();
  }
  onItemSelect(item: any) {
    this.getInterviews();
    this.setFilterToLocalStorage();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.getInterviews();
    this.setFilterToLocalStorage();
  }

  setFilterToLocalStorage() {
    localStorage.setItem('interviews-list_filter', JSON.stringify(this.selectedItems));

  }

  filter() {

    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.interviewList = this.filterTable(this.interviewListOriginal)
    }
  }


  filterTable(list: any[]) {

    let sl = this.selectedItems;

    if (sl.length > 0) {
      list = list.filter(x => {
        return sl.find(z => z.item_id == x.condition)
      });
    }


    return list;
  }

  openDashBoard(): void {
    this.dialog.open(InterviewDashboardComponent, {
      width: '100%'
    })
      .afterClosed()
      .subscribe((result) => {
      });
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  exportToExcel() {
    let data = this.interviewList
    data.forEach(element => {
      element.dateText = formatDate(element.dateApplication, 'dd/MM/yyyy', 'en')
    });
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Posición",
      "Candidato",
      "Documento de Identidad",
      "Unidad Organizativa",
      "Fecha de postulación",
      "Entrevistas Realizadas",
      "Estatus",
    ], [
      "ppvid",
      "positionName",
      "personName",
      "personalIdentification",
      "departmentName",
      "dateText",
      "interview",
      "conditionDescription",
    ]]
    this._xlsxService.exportToExcelSpecificColumns(data, excelHeaders, 'Listado de Entrevista', true);
  }
  getPaginatedRecords(event: PaginationEvent) {
    if (this.interviewsPagedResut.page == event.selectedPage && this.interviewsPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.interviewsPagedResut.page = event.selectedPage;
    this.interviewsPagedResut.pageSize = event.registersPerPage;
    this.getInterviews()
  }

  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

  printReport(){
    const reportUrl = environment.reportUrl
    const companyId = this.authInfo.getCompanyId()
    const userId = this.authInfo.getUserId()
    const page = this.interviewsPagedResut.page
    const pageSize = this.interviewsPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const condition = this.selectedItems?.map((condition: any) => condition.item_id)?.join('-')
    const url = `${reportUrl}?Reportcode=Exp_ListInterview&CompanyId=${companyId}&PropKeyId=${propKeyId}&SearchParameter=${text}&Status=1&Conditions=${condition}&Page=${page}&PageSize=${pageSize}&Type=1`
    let parameters = {
      url: url,
      title: 'Listado de entrevistas',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }


}
