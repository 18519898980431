import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ConceptValues } from 'src/app/shared/models/conceptValues.model';

@Component({
  selector: 'app-default-value-setting',
  templateUrl: './default-value-setting.component.html',
  styleUrls: ['./default-value-setting.component.css']
})
export class DefaultValueSettingComponent implements OnInit, AfterViewChecked {
  form: FormGroup;
  formIsInvalid: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
  private fb: FormBuilder, private srvAuth: AuthInfoService, 
  private _changeDet: ChangeDetectorRef,
  public dialogRef :MatDialogRef<DefaultValueSettingComponent>,) { 
    this.buildForm(); 
    this.dialogRef.disableClose = true;
    if(this.data?.item){
        this.form.patchValue({
            ...this.data?.item
        });
    }
    if(this.data?.inDetails){
        this.form.disable();
    }
}

    ngAfterViewChecked(): void {
        this._changeDet.detectChanges();
    }

  ngOnInit(): void {
  }

  private buildForm(){
    this.form = this.fb.group({
        conceptValuesId: [0],
        newsConceptId: [0],
        status: [true],
        description: [null, [Validators.required, Validators.maxLength(50)]],
        value: [null,  [Validators.required]],
        createDate: [null],
        createUserId: [0],
        modifyDate: [null],
        modifyUserId: [0]
    });
  }

  showError(name){
    const field = this.form.get(name);
    if(field.hasError('required')){
      return 'Este campo es requerido';
    }
    return '';
  }
  
  submit(){
    if(this.form.invalid){
        this.formIsInvalid = true;
        return;
    }
    this.formIsInvalid = false;

    const conceptValues: ConceptValues = this.form.value;
    if(this.data.inEdit){
        conceptValues.modifyUserId = this.srvAuth.getUserId();
        conceptValues.modifyDate = new Date();
        this.dialogRef.close({success: true, conceptValues: conceptValues});
    }else{
        conceptValues.createUserId = this.srvAuth.getUserId();
        conceptValues.createDate = new Date();
        this.dialogRef.close({success: true, conceptValues: conceptValues});
    }
  }

}
