<div class="d-flex justify-content-between align-items-center flex-wrap">
<div class="col pl-0">
  <div class="d-flex align-items-center mr-7">
      <span class="svg-icon svg-icon-gray-500 pr-2">
          <i class="flaticon-marca-de-verificacion"></i>
      </span>
      <div class="dataTables_info" id="kt_datatable_info" role="status" aria-live="polite">
          Mostrando <span class="font-weight-bolder text-primary ml-1">{{listedRecordsLength}}</span> - Total de registros <span class="font-weight-bolder text-primary ml-1">{{records?.length}} </span>
      </div>
  </div>
</div>
<div class="d-flex justify-content-end align-items-center flex-wrap">
    <div class="d-flex align-items-center py-3 mr-2">
        <select class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light" style="width: 75px" (change)="chageRegisterPerPage($event.target.value)" [(ngModel)]="registerPerPage">
      <option [value]="item" *ngFor="let item of options">{{item}}</option>
    </select>
        <span class="text-muted"><span class="v-desk">Mostrando</span> página {{ selectedPage }} de {{ lastPage }}
    </span>
    </div>

    <div class="d-flex flex-wrap py-2 mr-3">
        <!-- <a href="#" class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1"
    ><i class="ki ki-bold-double-arrow-back icon-xs"></i
  ></a> -->
        <button (click)="previousPageCaption()" class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" [disabled]="selectedPage === firstPage">
      <i class="ki ki-bold-arrow-back icon-xs"></i>
    </button>

        <button (click)="nextPageCaption()" class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" [disabled]="selectedPage === lastPage || records?.length == 0">
      <i class="ki ki-bold-arrow-next icon-xs"></i>
    </button>
        <!-- <a href="#" class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1"
    ><i class="ki ki-bold-double-arrow-next icon-xs"></i
  ></a> -->
    </div>
</div>
</div>