<div class="container-fluid py-3">
  <button  (click)="goBack()"  class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
      <i class="flaticon2-left-arrow-1"></i>
      <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
  </button>
</div>
<div class="container-fluid">
  <div id="pdfExp" class="card">
    <div class="card-header px-22">
      <div class="align-items-center d-flex flex-wrap justify-content-lg-between justify-content-md-center" id="header">
        <div class="logo px-2" >
          <img [src]="file.logo" alt="" class="max-w-200px" />
        </div>

        <div *ngIf="fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">
            {{fileModalConfig.formTitle}}
          </h1>
          <h2 class="text-danger">{{fileModalConfig.formSubTitle}}</h2>
        </div>
        <div *ngIf="!fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">
            Instituto Tecnológico de Las Américas
          </h1>
          <h2 class="text-danger">Acción de Cambio de Servidor Público (Vacaciones)</h2>
        </div>
        <div class="date mt-md-5 p-4 d-flex flex-wrap justify-content-between align-items-center">
            <h4 class="text-right mr-3">Fecha de solicitud</h4>
            <h5 class="text-danger text-right">{{vacationData && vacationData.length>0 ? (vacationData[0].applicationDate | date: "dd/MM/yyyy") : '__-__-____'}}</h5>
            <!-- &nbsp; -->
          </div>
      </div>
    </div>
    <div class="card-body">
      <div id="pdf">
        <div class="pdf-page pb-4">
          <div class="container-fluid row mt-3" id="personal-data">
            <h3 class="col-md-12 p-2 bg-primary text-white mt-3 text-center">DATOS PERSONALES</h3>
            <div class="col-md-6 row">
              <div class="photo col-md-4">
                <!-- <span *ngIf="loadingPic" class="d-flex flex-column align-items-center">
                  <img src="assets/media/logos/xmera-logo-3.png" alt="loading" width="70px" />
                  <strong class="text-danger font-size-h3">Cargando...</strong>
                </span> -->

                <ng-container>
                  <div class="symbol symbol-lg-200" *ngIf="!photoUrl; else second">
                    <i class="symbol-badge bg-success"></i>
                    <img src="/assets/media/no-user.png" alt="image">
                  </div>
                  <ng-template #second>
                    <div class="symbol symbol-lg-200">
                      <i class="symbol-badge bg-success"></i>
                      <img [src]="photoUrl | safe" alt="image" (click)="showModal('photo')">
                    </div>
                  </ng-template>
                </ng-container>
              </div>
              <div class="pdf-list col-md-8">
                <div class="pdf-items">
                  <h4>Código del Servidor Público:</h4>
                  <span>{{personSelected ? personSelected.employNumber : 'N/A'}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Servidor Público:</h4>
                  <span>{{personSelected ? personSelected.name: 'N/A'}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Identificación:</h4>
                  <span *ngIf="personSelected?.typeIdentificationId == 1">{{personSelected.personalIdentification | mask:"000-0000000-0"}}</span>
                  <span *ngIf="personSelected?.typeIdentificationId != 1">{{personSelected.personalIdentification }}</span>
                </div>
                <div class="pdf-items">
                  <h4>Teléfonozzz:</h4>
                  <span *ngIf="employeeId > 0">{{personSelected ? (personSelected.phoneNumber | mask:"(000)-000-0000"): 'N/A'}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Unidad Organizativa:</h4>
                  <span *ngIf="employeeId > 0">{{personSelected ? personSelected.departmentName : 'N/A'}}</span>
                </div>
              </div>
            </div>
            <div class="pdf-list col-md-6">
              <div class="pdf-items">
                <h4>Puesto:</h4>
                <span>{{ personSelected ? personSelected.positionName : 'N/A'}}</span>
              </div>
              <div class="pdf-items">
                <h4>Salario:</h4>
                <span>{{personSelected ? (personSelected.salary | currency: "RD$ ") : 'N/A'}}</span>
              </div>
              <div class="pdf-items">
                <h4>Tipo de Nombramiento:</h4>
                <span>{{ personSelected ? personSelected.contractType : 'N/A'}}</span>
              </div>
              <div class="pdf-items">
                <h4>Fecha Efectiva:</h4>
                <span>{{personSelected ? (personSelected.effectiveDate | date:"dd/MM/yyyy") : 'N/A'}}</span>
              </div>
              <div class="pdf-items">
                <h4>Fecha de Ingreso:</h4>
                <span>{{personSelected ? (personSelected.admissionDate | date:"dd/MM/yyyy") : 'N/A'}}</span>
              </div>

            </div>
          </div>

          <div class="container-fluid row mt-3 " id="family-working-at-here">
            <h3 class="col-md-12 mt-3 p-2 bg-secondary text-white text-center">
                VACACIONES
            </h3>
            <div class="col-md-12 m-0 p-0 ">
                <div class="pdf-list col-md-8">
                    <div class="pdf-items">
                      <h4>Fecha de Solicitud:</h4>
                      <span>{{ vacationData && vacationData.length>0?( vacationData[0].applicationDate | date: "dd/MM/yyyy"):'N/A'}}</span>
                    </div>
                    <div class="pdf-items">
                      <h4>Fecha de Inicio:</h4>
                      <span>{{ vacationData && vacationData.length>0? (vacationData[0].dateStart | date: "dd/MM/yyyy"):'N/A'}}</span>
                    </div>
                    <div class="pdf-items">
                      <h4>Fecha de Termino:</h4>
                      <span>{{ vacationData && vacationData.length>0? (vacationData[0].dateEnd | date: "dd/MM/yyyy"):'N/A'}}</span>
                    </div>
                    <div class="pdf-items">
                      <h4>Año al que pertenecen :</h4>
                      <span *ngIf="employeeId > 0">{{ vacationData && vacationData.length>0? vacationData[0].year: 'N/A'}}</span>
                    </div>
                    <div class="pdf-items">
                      <h4>Días:</h4>
                      <span *ngIf="employeeId > 0">{{ vacationData && vacationData.length>0? vacationData[0].days : 'N/A'}}</span>
                    </div>
                    <div class="pdf-items">
                        <h4>¿Desea pago de bono vacacional?:</h4>
                        <span *ngIf="employeeId > 0">{{vacationData && vacationData.length>0? vacationData[0].bond? 'Si' : 'No': 'N/A' }}</span>
                      </div>
                  </div>
            </div>
          </div>

          <div class="container-fluid row mt-3" id="references">
            <h3 class="col-md-12 p-2 bg-primary text-white mt-3 text-center">
                HISTORIAL LABORAL
            </h3>
            <div class="col-md-12 m-0 p-0 overflow-auto" id="laboral-history">
                <table class="table p-0">
                    <thead>
                      <tr class="p-0">
                        <th scope="col" class="text-dark">Institución Gubernamental</th>
                        <th scope="col" class="text-dark">Cantidad de Años</th>
                        <th scope="col" class="text-dark">Cantidad de Días</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="historyWork.length>0">
                      <tr class="p-0" *ngFor="let item of historyWork" >
                        <td class="">{{item.socialReason}}</td>
                        <td class="">{{item.years}}</td>
                        <td class="">{{item.days}}</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
          </div>

          <div class="container-fluid row mt-3 " id="family-working-at-here">
            <h3 class="col-md-12 mt-3 p-2 bg-secondary text-white text-center">
                FECHA DE VACACIONES
            </h3>
            <div class="col-md-12 m-0 p-0 overflow-auto">
              <table class="table p-0">
                <thead>
                  <tr class="p-0">
                    <th scope="col" class="text-dark">Fecha de Inicio</th>
                    <th scope="col" class="text-dark">Fecha de Termino</th>
                    <th scope="col" class="text-dark">Año al que Pertenece</th>
                    <th scope="col" class="text-dark">Total de Días</th>
                  </tr>
                </thead>
                <tbody *ngIf="vacationData && vacationData.length>0">
                  <tr class="p-0" *ngFor="let item of vacationData">
                    <td class="">{{item.dateStart | date:"dd/MM/yyyy"}}</td>
                    <td class="">{{item.dateEnd | date:"dd/MM/yyyy"}}</td>
                    <td class="">{{item.year}}</td>
                    <td class="">{{item.days}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="container-fluid row mt-12 " id="family-working-at-here">
            <div class="col-md-12 m-0 p-0 ">
                <div class="detail pdf-list col-md-8">
                    <div class="pdf-items">
                      <label>Para un total de:</label>
                      <span>{{ vacationData && vacationData.length>0? vacationData[0].days :'N/A'}}</span>
                    </div>
                    <div class="pdf-items">
                        <label>Restando un total de:</label>
                        <span>{{getHistoryVacancyDays() - getVacationsCount(vacationData)}}</span>
                    </div>
                    <div class="pdf-items">
                        <label>Correspondientes al año:</label>
                        <span>{{vacationData && vacationData.length>0? vacationData[0].year: 'N/A'}}</span>
                    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer mt-4">
      <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
        <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-80px">
          <div class="d-flex flex-wrap justify-content-around flex-fill">
            <div *ngIf="supervisorNames.employeeSupervisor" class="mr-3">
              <span>__________________________</span>
              <p class="font-weight-bold text-dark text-center">
                {{supervisorNames.employeeSupervisor}}
              </p>
            </div>
            <div *ngIf="supervisorNames.hhrr" class="mr-3">
              <span>__________________________</span>
              <p class="font-weight-bold text-dark text-center">
                {{supervisorNames.hhrr}}
              </p>
            </div>
            <div *ngIf="supervisorNames.rectory" class="mr-3">
              <span>__________________________</span>
              <p class="font-weight-bold text-dark text-center">
                {{supervisorNames.rectory}}
              </p>
            </div>
          </div>
        <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
      </div>
    </div>
  </div>
</div>
<button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
  <i class="flaticon2-printer font-size-h1"></i>
</button>
</div>
<script>
  let print = () => {
    let pdf = document.getElementById("pdfExp");
    pdf.contentWindow.focus();
    pdf.contentWindow.print();
  };
  </script>

<el-modal target="logoModal" size="md" [title]="'Vista de foto'">
  <el-embed *ngIf="fileModalConfig.src" [src]="fileModalConfig.src" [type]="fileModalConfig.type"
    [filename]="fileModalConfig.filename" [width]="fileModalConfig.width" [height]="fileModalConfig.height">
  </el-embed>
</el-modal>
<el-modal target="photo" size="md" [title]="'Vista de foto'">
  <div class="container justify-content-center" style="align-content: center;">
    <ng-template [ngIf]="photoUrl">
      <img [src]="photoUrl | safe" alt="image" style="max-width: 350px;">
    </ng-template>
  </div>
</el-modal>
