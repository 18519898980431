import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { Departments, IDepartmentChangeDetailPost, IDepartmentChangeDetailView, IDepartmentChangePost } from 'src/app/models/departments.model';
import { AuthInfoService } from './auth-info.service';


@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  private readonly urlApi = environment.api_core_url

  constructor(private http: HttpClient, private srvAuthInfo:AuthInfoService) { }

  getAll(): Observable<ResponseModel<Departments>> {
    const companyId = this.srvAuthInfo.getCompanyId()
    return this.http.get<ResponseModel<Departments>>(`${this.urlApi}/CompanyStructure?CompanyId=${companyId}`);
  
  }

  getAllApproved(companyId: number): Observable<ResponseModel<Departments>> {
    
    return this.http.get<ResponseModel<Departments>>(`${this.urlApi}/companyStructure/GetCompanyStructure?CompanyId=${companyId}&type=2`);
  }

  getById(companyId: Number, departmentId): Observable<ResponseModel<Departments>> {
    return this.http.get<ResponseModel<Departments>>(`${this.urlApi}/CompanyStructure/GetCompanyStructure?CompanyId=${companyId}&idDepartment=${departmentId}&Status=true&type=0`);
  }

  getParentSectionById(institutionId:number, parentId: Number, type:number = 1): Observable<ResponseModel<Departments>> {
    return this.http.get<ResponseModel<Departments>>(`${this.urlApi}/companyStructure/GetCompanyStructure?CompanyId=${institutionId}&Type=${type}&parentSection=${parentId}`);
  }

  sendDepartmentChanges(model: IDepartmentChangePost){
    return this.http.post<ResponseModel<any>>(`${this.urlApi}/CompanyStructureChange`, model)
  }

  getChangeDetails(departmentChangeDetailsId: number,departmentChangeHeaderId: number, departmentId: number, companyId: number, type: number ){
    return this.http.get<ResponseModel<IDepartmentChangeDetailView>>(`${this.urlApi}/CompanyStructureChange/Details?departmentChangeDetailsId=${departmentChangeDetailsId}&departmentChangeHeaderId=${departmentChangeHeaderId}&departmentId=${departmentId}&companyId=${companyId}&type=${type}`)
  }

}
