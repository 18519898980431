<div class="container">
    <div class="col-md-12 gutter-t gutter-b">
        <div class="card card-custom border-primary p-0">
            <div class="card-header mt-3">
                <div class="card-title align-items-center">
                    <h3 class="text-primary m-0">Experiencia Laboral</h3>
                </div>
                <div class="card-toolbar">
                    <button (click)="clear()" class="btn btn-pill btn-shadow btn-success max-w-200px mr-3" type="button"
                        data-target="#addExp" data-toggle="modal">
                        <i class="flaticon2-plus"></i>
                        <span> Agregar</span>
                    </button>
                </div>
            </div>
            <div class="card-body p-0 min-h-100px">
                <div class="table-responsive">
                    <table class="table mt-2 table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th>Empresa o Institución</th>
                                <th>Nombre del Cargo</th>
                                <th>Fecha Inicio</th>
                                <th>Fecha Salida</th>
                                <th>Último Salario</th>
                                <th>Motivo Salida</th>
                                <th class="w-200px">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let m of personWorkExp; let i = index">
                                <td>{{m.company}}</td>
                                <td>{{m.jobTitle}}</td>
                                <td>{{m.startDate | date: 'dd/MM/yyyy'}}</td>
                                <td>
                                    <span *ngIf="m.reasonOfExitId > 0">{{ m.departureDate | date: 'dd/MM/yyyy' }}</span>
                                    <span *ngIf="m.reasonOfExitId == 0">Actual</span>
                                </td>
                                <td>{{m.lastSalary | currency}}</td>
                                <td>
                                    <span *ngIf="m.reasonOfExitId > 0">{{getReasonExit(m)}}</span>
                                    <span *ngIf="m.reasonOfExitId == 0">N/A</span>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-sm btn-light-warning btn-icon mr-2"
                                        data-title="Editar" data-target="#addExp" data-toggle="modal"
                                        (click)="editExpWork(i)">
                                        <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                                    </button>
                                    <button type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                        data-title="Remover" (click)="removeExpWork(i)">
                                        <i class="flaticon-delete" data-toggle="tooltip" data-title="Eliminar"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>



    <div class="col-md-12 gutter-t gutter-b">
        <div class="card card-custom border-primary p-0">
            <div class="card-header mt-3">
                <div class="card-title align-items-center">
                    <h3 class="text-primary m-0">Referencias Personales</h3>
                </div>
                <div class="card-toolbar">
                    <button class="btn btn-pill btn-shadow btn-success max-w-200px mr-3" type="button"
                        data-target="#addRef" data-toggle="modal" (click)="clearRef()">
                        <i class="flaticon2-plus"></i>
                        <span> Agregar</span>
                    </button>
                </div>
            </div>
            <div class="card-body p-0 min-h-100px">
                <div class="table-responsive">
                    <table class="table mt-2 table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th>Nombre</th>
                                <th>Cargo</th>
                                <th>Empresa</th>
                                <th>Teléfono 1</th>
                                <th>Teléfono 2</th>
                                <th class="w-200px">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let m of personRef; let i = index">
                                <td>{{m.fullName}}</td>
                                <td>{{m.position}}</td>
                                <td>{{m.company}}</td>
                                <td>{{m.phoneNumber1 | mask: '(000) 000-0000'}}</td>
                                <td>{{m.phoneNumber2 | mask: '(000) 000-0000'}}</td>
                                <td>
                                    <button type="button" class="btn btn-sm btn-light-warning btn-icon mr-2"
                                        data-title="Editar" data-target="#addRef" data-toggle="modal"
                                        (click)="editRef(i)">
                                        <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                                    </button>
                                    <button type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                        data-title="Remover" (click)="removeRef(i)">
                                        <i class="flaticon-delete" data-toggle="tooltip" data-title="Eliminar"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<el-modal target="addExp" title="Agregar Experiencia Laboral" size="xl">
    <div class="row">
        <div class="form-group col-md-6">
            <label>Empresa o institución <span class="text-danger">*</span></label>
            <input class="form-control form-control-solid form-control-lg" placeholder="" type="text" required=""
                [(ngModel)]="dataWorkExp.company" (keyup)="trimLeadingSpaces('company')">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-6">
            <label>Nombre del Cargo <span class="text-danger">*</span></label>
            <input  class="form-control form-control-solid form-control-lg" placeholder="" type="text" required=""
                [(ngModel)]="dataWorkExp.jobTitle" (keyup)="trimLeadingSpaces('jobTitle')" >
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-6">
            <label>Fecha de inicio <span class="text-danger">*</span></label>
            <input class="form-control form-control-solid form-control-lg" placeholder="" type="date"
                [(ngModel)]="startDate">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-3">
            <label>¿Trabaja actualmente? <span class="text-danger">*</span></label>
            <div class="form-check form-check-inline">
                <label class="form-check-label check-dtl">
                    <input class="form-check-input cursor-pointer" required="true" name="working" type="radio"
                        value="yes" [(ngModel)]="working">Si
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label check-dtl">
                    <input class="form-check-input cursor-pointer" required="true" name="working" type="radio"
                        value="no" [(ngModel)]="working">No
                </label>
            </div>
        </div>
        <div class="form-group col-md-3">
            <label>Fecha de salida <span *ngIf="working == 'no'" class="text-danger">*</span></label>
            <input [disabled]="working == 'yes'" class="form-control form-control-solid form-control-lg" placeholder=""
                type="date" [min]="startDate ? getMinDate(startDate): ''" [(ngModel)]="endDate">
            <span *ngIf="validDates()" class="form-text text-danger">La fecha de salida no pueder ser menor a la fecha
                de inicio</span>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-6">
            <label>Último Salario <span class="text-danger">*</span></label>
            <input [options]="{precision:2}" class="form-control form-control-solid form-control-lg" placeholder=""
                required="" [(ngModel)]="dataWorkExp.lastSalary" currencyMask>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-6">
            <label class="d-block w-100">Motivo de salida <span *ngIf="working == 'no'"
                    class="text-danger">*</span></label>
            <div class="dropdown-alt input-group">
                <ngx-select-dropdown [disabled]="working == 'yes'" class=" flex-1" [options]="requisitionTypeList"
                    [config]="configRequisitionType" [(ngModel)]="CauseRequisitionData">
                </ngx-select-dropdown>
            </div>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
    </div>
    <div class="row gutter-b">
        <div class="form-check form-check-inline font-size-h4 font-weight-bold col-md-12 ml-3">
            <input type="checkbox" class="form-check-input mr-2" [(ngModel)]="dataWorkExp.stateEnterprise">
            <label class="form-check-label"> ¿Esta empresa/institución pertenece al estado? </label>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-12">
            <label class="d-block">Funciones o tareas</label>
            <textarea class="form-control form-control-solid form-control-lg"
                placeholder="Ej: Dar soporte al hardware de la institución." id="jobDescription" rows="3" required=""
                [(ngModel)]="dataWorkExp.role"></textarea>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 gutter-t gutter-b">
            <div class="card card-custom border-primary p-0">
                <div class="card-header mt-3">
                    <div class="card-title align-items-center">
                        <h3 class="text-primary m-0">Referencias Laborales</h3>
                    </div>
                    <div class="card-toolbar">
                        <button class="btn btn-pill btn-shadow btn-success max-w-200px mr-3" type="button"
                            data-target="#addJob" data-toggle="modal" (click)="clearJob()">
                            <i class="flaticon2-plus"></i>
                            <span> Agregar</span>
                        </button>
                    </div>
                </div>
                <div class="card-body p-0 min-h-100px">
                    <div class="table-responsive">
                        <table class="table mt-2 table-vertical-center">
                            <thead>
                                <tr class="bg-primary">
                                    <th>Nombre</th>
                                    <th>Cargo</th>
                                    <th>Teléfono</th>
                                    <th class="w-200px">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let m of personJob; let i = index">
                                    <td>{{m.fullName}}</td>
                                    <td>{{m.position}}</td>
                                    <td>{{m.phoneNumber | mask: '(000) 000-0000'}}</td>
                                    <td>
                                        <button type="button" class="btn btn-sm btn-light-warning btn-icon mr-2"
                                            data-title="Editar" data-target="#addJob" data-toggle="modal"
                                            (click)="editJob(i)">
                                            <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                            data-title="Remover" (click)="removeJob(i)">
                                            <i class="flaticon-delete" data-toggle="tooltip" data-title="Eliminar"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row gutter-t">
        <div class="form-group col-md-12 d-flex justify-content-end border-top pt-3 m-0">
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2" id="btnCerrarEx"
                data-dismiss="modal" type="button">
                <i class="flaticon2-cross"></i>
                <span>Cerrar</span>
            </button>
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
                (click)="addExpWork()" [disabled]="validButtonExpWork()">
                <i class="flaticon-disco-flexible"></i>
                <span> Agregar </span>
            </button>
        </div>
    </div>
</el-modal>

<el-modal target="addJob" title="Agregar Referencias Laborales" size="xl">
    <div class="row">
        <div class="form-group col-md-6">
            <label>Nombre <span class="text-danger">*</span></label>
            <input
                onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32 || event.charCode == 209 || event.charCode == 241 )"
                class="form-control form-control-solid form-control-lg" placeholder="" type="text" required=""
                [(ngModel)]="dataJob.fullName">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-6">
            <label>Cargo <span class="text-danger">*</span></label>
            <input class="form-control form-control-solid form-control-lg" placeholder="" type="text" required=""
                [(ngModel)]="dataJob.position">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-6">
            <label>Teléfono <span class="text-danger">*</span></label>
            <input class="form-control form-control-solid form-control-lg" placeholder="" type="tel" required=""
                [(ngModel)]="dataJob.phoneNumber" mask="(000)-000-0000" autocomplete="0">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-6">

        </div>
    </div>
    <div class="row gutter-t">
        <div class="form-group col-md-12 d-flex justify-content-end border-top pt-3 m-0">
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2" data-dismiss="modal"
                type="button">
                <i class="flaticon2-cross"></i>
                <span>Cerrar</span>
            </button>
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
                (click)="addJob()" [disabled]="validButtonJob()" data-dismiss="modal">
                <i class="flaticon-disco-flexible"></i>
                <span> Agregar </span>
            </button>
        </div>
    </div>
</el-modal>

<el-modal target="addRef" title="Agregar Referencias Personales" size="xl">
    <div class="row">
        <div class="form-group col-md-6">
            <label>Nombre <span class="text-danger">*</span></label>
            <input class="form-control form-control-solid form-control-lg" placeholder="" type="text" required=""
                [(ngModel)]="dataRef.fullName"
                onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32 )">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-6">
            <label>Cargo <span class="text-danger">*</span></label>
            <input class="form-control form-control-solid form-control-lg" placeholder="" type="text" required=""
                [(ngModel)]="dataRef.position">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-6">
            <label>Empresa <span class="text-danger">*</span></label>
            <input class="form-control form-control-solid form-control-lg" placeholder="" type="text" required=""
                [(ngModel)]="dataRef.company">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-6">
            <label>Teléfono 1 <span class="text-danger">*</span></label>
            <input class="form-control form-control-solid form-control-lg" placeholder="(809)-000-0000" type="tel"
                required="" [(ngModel)]="dataRef.phoneNumber1" mask="(000)-000-0000">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-6">
            <label>Teléfono 2 </label>
            <input class="form-control form-control-solid form-control-lg" placeholder="(809)-000-0000" type="tel"
                [(ngModel)]="dataRef.phoneNumber2" mask="(000)-000-0000">
        </div>
    </div>
    <div class="row gutter-t">
        <div class="form-group col-md-12 d-flex justify-content-end border-top pt-3 m-0">
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2" data-dismiss="modal"
                type="button">
                <i class="flaticon2-cross"></i>
                <span>Cerrar</span>
            </button>
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
                (click)="addRef()" [disabled]="validButtonRef()" data-dismiss="modal">
                <i class="flaticon-disco-flexible"></i>
                <span> Agregar </span>
            </button>
        </div>
    </div>
</el-modal>
