import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { AccountPrivilege, IRefreshTokenModel, ModuleList } from '../shared/models/account';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { AccountService } from './account.service';
import { DataService } from './data.service';
@Injectable({
  providedIn: 'root'
})
export class AuthInfoService {


  public redirectUrl: string = null;

  constructor(private jwtHelper: JwtHelperService, private route: Router, private dataService: DataService, private accountService: AccountService,
    private toastService: ToastrService ) { }

  getAllowedUsersToAction(companyId: number, actionKey: string, optionKey: string){
    return this.accountService.getAllowedUsersToAction(companyId, actionKey, optionKey);
  }

  getCompanyId(): number {
    let account = this.dataService.GetUserData();
    return +account?.companyId;

  }

  getConstitutionDate(): string {
    let account = this.dataService.GetUserData();
    return account?.companyConstitutionDate;

  }

  getUserFullName(): string {
    let account = this.dataService.GetUserData();
    return `${account?.firstName} ${account?.lastName}`;
  }

  getUserId(): number {
    let account = this.dataService.GetUserData();
    return +account?.userId;
  }

  getEmployeeId() {
    let account = this.dataService.GetUserData();
    return +account?.employeeId;
  }

  getEmployeeIdFromSession(): number {
    let account = this.dataService.GetUserData();
    return +account?.employeeId;
  }

  getToken(): string | null {
    const token = localStorage.getItem('token');
    return (token == 'null' || token == 'undefined' || !token) ? null : token;
  }

  getRefreshToken() {
    let refreshToken = localStorage.getItem('refreshToken');
    return refreshToken ? refreshToken : null;
  }

  getUser() {
    let account = this.dataService.GetUserData();
    return account;
  }

  getFullUserName() {
    let account = this.dataService.GetUserData();
    const name = account.firstName.trim()
    const lastname = account.lastName.trim()
    const full = name + ' ' + lastname
    return full;
  }

  async getUserModules(): Promise<ModuleList[]> {

    let response = await this.setUserOptions();

    if (response == false) {
      return [];
    }

    const Data = localStorage.getItem('Usr-Modules');
    if (Data) {
      let UsrModules: ModuleList[];
      UsrModules = JSON.parse(Data);
      if (UsrModules) {
        return UsrModules;
      }
    } else {
      return []
    }

  }

  async canUseModule(globalModule): Promise<boolean> {
    let modules = await this.getUserModules();
    if (modules.length > 0) {
      let module = modules.find(x => x.nameKey == globalModule);

      return module ? true : false;
    } else {
      return false;
    }

  }

  getUserPriviliges(): AccountPrivilege[] {

    const Data = localStorage.getItem('Usr-Privileges');
    if (Data) {
      let UsrPriviliges: AccountPrivilege[];
      UsrPriviliges = JSON.parse(Data);
      if (UsrPriviliges) {
        return UsrPriviliges;
      }
    } else {
      return []
    }
  }

  async setUserOptions() {
    let id = this.getUserId();

    if (!id) {
      return;
    }

    let isValid: boolean = false;

    await this.accountService.getUserInfo(id).toPromise().then((res: ResponseModel<any>) => {

      if (!res.succeded) {
        this.toastService.error(res.errors[0]);
        this.accountService.logout();
        isValid = false;
        return;
      }

      localStorage.setItem('Usr-Modules', JSON.stringify(res.singleResultData.modules));
      localStorage.setItem('Usr-Privileges', JSON.stringify(res.singleResultData.privileges));
      localStorage.setItem('refreshToken', res.singleResultData?.account?.refreshToken);

      let user = this.getUser();
      user.userTypeId = res.singleResultData?.account?.userTypeId;

      localStorage.setItem('Usr-Account', JSON.stringify(user));

      isValid = true;

      this.accountService.setAccountSubject(res.singleResultData.account);

    }).catch(err => {
      isValid = false;
      this.toastService.error('No se ha podido obtener los privilegios del usuario');
    });

    return isValid;
  }

  async canUseOption(moduleKey: string, optionKey: string): Promise<boolean> {

    let privileges = this.getUserPriviliges();
    if (privileges.length > 0) {
      let privilege = privileges.find(x => x.nameKeyOption == optionKey && x.status == true && x.nameKeyModule == moduleKey);

      if (!privilege) {
        this.toastService.warning('Usted no tiene acceso a esta pantalla, favor de comunicarse con Help Desk.')
      }


      return privilege ? true : false;
    } else {
      return false;
    }
  }

  setPrivileges(privileges) {
    const keys = Object.keys(privileges);
    const excludedKeys = ['nameKeyModule', 'nameKeyOption'];
    for (const privilegeKey of keys) {
      if (!excludedKeys.includes(privilegeKey)) {
        privileges[privilegeKey].value = this.canDoAction(privileges.nameKeyOption, privileges[privilegeKey].key);
      }
    }
    return privileges;
  }



  canDoAction(optionKey: string, actionKey: string): boolean {
    let privileges = this.getUserPriviliges();
    if (privileges.length > 0) {
      let privilege = privileges.find(x => x.nameKeyOption == optionKey && x.status == true);

      if (!privilege) {
        return false;
      }

      let action = privilege.allowActions.find(x => x.nameKey == actionKey && x.status == true);

      return action ? true : false;
    } else {
      return false;
    }
  }

  isAuthenticated(): boolean {
    const token = this.getToken();

    if (token == null) {
      return false;
    }

    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  async checkAndRefreshAuthentication(){
    const token = this.getToken();

    if(token == null){
      return 
    }

    const isAuth = !this.jwtHelper.isTokenExpired(token);

    if(isAuth){
      return
    }

    const model: IRefreshTokenModel = {
      accessToken: token,
      refreshToken: this.getRefreshToken(),
      userId: this.getUserId()
    }

    if (model.accessToken) {
      await this.accountService.refreshToken(model).toPromise()
      .then((res)=>{
        if(!res.succeded){
          this.accountService.logout();
          return;
        }

        localStorage.setItem('token', res.singleResultData.accessToken);

      }).catch((err)=>{
        this.accountService.logout();
      })
    }
  }

  doLogOut() {
    this.accountService.logout();
  }
}
