export class Account {
    userId: string;
    uEmail: string;
    companyId: number;
    companyName: string;
    companyEmail: string;
    name: string;
    jwtToken: string;
    employeeNumber: string;
    employeeId: string;
    UserImage: string;
    userName: string;
    status: boolean;
    firstName: string;
    lastName: string;
    useCollectiveVacations: boolean;
    userTypeId: number;
    identification: string;
    companyConstitutionDate:string;
}
export interface AccountData {
    userId: string;
    uEmail: string;
    companyId: number;
    companyName: string;
    companyEmail: string;
    name: string;
    employeeNumber: string;
    employeeId: string;
    userImage: string;
    userName: string;
    status: boolean;
    firstName: string;
    lastName: string;
    identification: string;
}

export interface ModuleList {
    nameKey: string;
    moduleName: string;
    urlmodule: string;
    isManager: boolean;
    displayOrder: number;
}

export interface AllowAction {
    nameKey: string;
    actionName: string;
    status: boolean;
}

export interface AccountPrivilege {
    nameKeyOption: string;
    nameKeyModule: string;
    allowActions: AllowAction[];
    status: boolean;
    optionName: string;
}

export interface RootObject {
    accountData: AccountData;
    moduleList: ModuleList[];
    accountPrivileges: AccountPrivilege[];
}

export interface IRefreshTokenModel{
    userId: number;
    accessToken: string;
    refreshToken: string;
}

export interface IUserSession{
    userId: number;
    token: string;
    message: string;
}