<app-modal-layout (onClose)="close()" styles="min-height: 200px">
    <ng-container title>
        Detalle Agrupado por Período
    </ng-container>
    <ng-container body>
            <div class="bg-light-info rounded row h-100 mb-5">
                <div class="col-md-12 d-flex justify-content-center flex-column">
                    <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">
                        Nombre: <span class="text-dark ml-3">{{data.item.name}}</span>
                    </h3>
                </div>
                <div class="col-md-12 d-flex justify-content-center flex-column">
                    <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">
                        Documento de Identidad: 
                        <span *ngIf="data.item.personalIdentification.length == 11" class="text-dark ml-3">{{data.item.personalIdentification | mask:'000-0000000-0'}}</span>
                        <span *ngIf="data.item.personalIdentification.length != 11" class="text-dark ml-3">{{data.item.personalIdentification}}</span>
                    </h3>
                </div>
                <div class="col-md-12 d-flex justify-content-center flex-column">
                    <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">
                        Cargo: <span class="text-dark ml-3">{{data.item.positionName}}</span>
                    </h3>
                </div>
                <div class="col-md-12 d-flex justify-content-center flex-column">
                    <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">
                        Unidad Organizativa <span class="text-dark ml-3">{{data.item.departmentName}}</span>
                    </h3>
                </div>
                <div class="col-md-12 d-flex justify-content-center flex-column">
                    <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">
                        Salario: <span class="text-dark ml-3">{{data.item.totalPayable | currency: 'RD$'}}</span>
                    </h3>
                </div>
            </div>

        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left">Código de Concepto</th>
                                <th class="text-left">Nombre del Concepto</th>
                                <th class="text-left">Monto</th>
                                <th class="text-left">Secuencia de Nómina</th>
                            </tr>
                        </thead>

                        <tbody>
                            <ng-container *ngFor="let item of dataList">
                                <tr class="bg-primary">
                                    <th colspan="4" class="text-white">{{item.period}}</th>
                                </tr>
                                <tr *ngFor="let item2 of item.data" class="">
                                    <td class="text-left">{{ item2?.code }}</td>
                                    <td class="text-left">{{ item2?.description }}</td>
                                    <td class="text-left">{{ item2?.amount | currency: "RD$"}}</td>
                                    <td class="text-left">{{ item2?.headerId }}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    
                </div>
            </div>
        </div>


    </ng-container>
<ng-container buttons>
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>

</ng-container>
</app-modal-layout>
