import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Incidents } from '../pages/EvaluationPerformance/models/Incidents';
import { keyWord } from '../shared/utils/parameterControl';
import { AuthInfoService } from './auth-info.service';
import { ParameterControlService } from './parameter-control.service';

@Injectable({
  providedIn: 'root'
})
export class InicidentsService {
  apiCore = environment.api_core_url;// 'http://172.16.11.173/core_back/api'
  api = environment.api_url; // 'http://172.16.11.173/xhrm_back/api'

  constructor(private _http: HttpClient, private srvParam:ParameterControlService,
    private authInfo: AuthInfoService) { }

  createIncident(newIncident:Incidents){
    return this._http.post<Incidents>(`${this.api}/CriticalIncident`,newIncident)
  }

  getIncidents(){
    return this._http.get(`${this.api}/CriticalIncident?CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getIncidentsById(id){
    return this._http.get(`${this.api}/CriticalIncident?idCriticalIncident=${id}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  delete(id:number){
    return this._http.delete(`${this.api}/CriticalIncident?IdCriticalIncident=${id}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getEmployees(type:number){
    return this._http.get<Incidents>(`${this.api}/Employees?type=${type}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getEmployeeByParemeter(type:number, paremeter:string){
    return this._http.get(`${this.api}/Employees?type=${type}&person=${paremeter}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getEventType(){
    return this.srvParam.getParameters(keyWord.IncidentType)
  }

  getEventGrade(){
    return this.srvParam.getParameters(keyWord.IncidentGrade)
  }

  getStatistics(
    type: number,
    dateFrom: string,
    dateTo: string,
    status: boolean = true,
  ) {
    return this._http.get<any>(`${this.api}/CriticalIncident/Dashboard?Type=${type}&CompanyId=${this.authInfo.getCompanyId()}&DateFrom=${dateFrom}&DateTo=${dateTo}&Status=${status}`);
  }

  getDashboard(
    dateFrom: string,
    dateTo: string,  
    CompanyId: number = this.authInfo.getCompanyId(),
    status: boolean = true,
    ){
      return this._http.get<any>(`${this.api}/CriticalIncident/Dashboard?Status=${status}&CompanyId=${CompanyId}&DateFrom=${dateFrom}&DateTo=${dateTo}`);
  }
}
