<ng-template [ngIf]="isLoading | async" [ngIfElse]="default">
    <div *ngIf="animation == 'spinner-b'" class="spinner-border text-white dv-spinner-tiny" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    
    <div *ngIf="animation == 'spinner-b2'" class="spinner-border text-dark dv-spinner-big m-auto" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    
    <div class="spinner" *ngIf="animation === 'spinner-logo'">
        <img src="/src/assets/media/logos/xmera-logo-3.png" alt="loading" width="4000px" />
    </div>
</ng-template>

<ng-template #default>
    <div  class="spinner">
        <img src="/assets/media/logos/xmera-logo-3.png" alt="PDF" width="4000px" />
    </div>
</ng-template>
