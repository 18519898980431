import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-vinculations-detail-bulk-load',
  templateUrl: './vinculations-detail-bulk-load.component.html',
  styleUrls: ['./vinculations-detail-bulk-load.component.css']
})
export class VinculationsDetailBulkLoadComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<VinculationsDetailBulkLoadComponent>,
  ) { }

  ngOnInit(): void {
  }

  close(): void
  {
    this.dialogRef.close();
  }
}
