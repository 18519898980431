<ng-container>
    <div class="card card-custom">
        <div class="card-header mt-3">
            <div class="card-title">
                <div class="ttl d-flex align-items-center">
                    <i class="flaticon-lista-de-verificacion icon-3x text-danger mr-2"></i>
                    <h1 class="text-primary"> Nómina de Prestaciones Laborales </h1>
                </div>
            </div>
        </div>
        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-7 c-left">
                <div class="card-toolbar d-flex justify-content-start">

                    <div class="col-md-3 mr-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Período
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown [disabled]="periodDropdownIsDisable" [options]="periods" [(ngModel)]="dropdownPeriod" (change)="changeDropdownPeriod()"
                                    [config]="dropdownConfig('description')" name="period" class="w-100"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 mr-0 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Área Presupuestaria
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown [disabled]="areaDropdownIsDisable" name="budgetArea" [options]="budgetAreas" [(ngModel)]="dropdownBudgetAreas" (change)="changeDropdownArea()"
                                    [config]="dropdownConfig('description')" class="w-100"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                    <button data-toggle="modal"
                        class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto"
                        *ngIf="privilege.addAction.value && isOpenToNews"
                        (click)="openSearchEmployee()">
                        <i class="flaticon-mas icon-2x" data-toggle="tooltip"
                            data-original-title="Agregar Servidor Público"></i>
                        Agregar
                    </button>

                    <button data-toggle="modal" (click)="openModalBulkloadFinalNom()"
                    *ngIf="privilege.uploadAction.value && isOpenToNews"
                        class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto">
                        <i class="flaticon-recursos-humanos-6 icon-2x"></i> Carga Masiva Servidores Públicos
                    </button>
                    <button data-toggle="modal" *ngIf="privilege.cleanAction.value"
                        class="btn btn-primary btn-pill font-weight-bolder mt-2 mx-2 col-md-auto"
                        (click)="clear()">
                        <i class="flaticon-delete-1 icon-2x text-white"></i>
                        Limpiar selección
                    </button>
                </div>
            </div>
            <div class="col-sm-12 col-md-5 c-right mr-0  col-md-auto">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton" *ngIf="privilege.exportAction.value && employeeList?.length"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                              <i class="fa fa-print icon-2x text-primary "></i>
                              Visualizar Reporte
                            </a>
                            <!-- <a class="dropdown-item align-items-center"
                            href="assets/docs/plantilla-carga-masiva/Plantilla Carga Masiva.xlsx" download="Plantilla Carga Masiva.xlsx">
                                <i class="flaticon-excel icon-2x text-primary mr-3"></i>
                                Plantilla Carga Masiva
                            </a>
                            <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                                <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                                PDF
                            </a> -->
                        </div>
                    </div>

                    <div class="col text-nowrap">
                        <el-search-bar #searchBar [records]="employeeList"
                            (onChange)="getFilteredList($event)"
                            (onKeyChange)="onKeyChange($event);"
                            [keyValue]="filterText"
                            (onKeyParameterChange)="onKeyParameterChange($event)"
                            [itemForSearching]="searchBarDisplayNames"></el-search-bar>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="d-flex align-items-center mb-3">
            <button data-toggle="modal" *ngIf="employeeList?.length > 0 && isContitionalRejectPayroll" (click)="openRejectModal()"
            class="btn btn-danger btn-pill font-weight-bolder mx-2 col-md-auto">
            <i class="flaticon-lista-de-verificacion-1 icon-2x" data-toggle="tooltip"
                data-original-title="Rechazar Nómina"></i>
            Rechazar
        </button>
                <h3>{{headerCondition}}</h3>
            </div>
            <div class="table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-left">Secuencia</th>
                            <th class="text-left">Servidor Público</th>
                            <th class="text-left">Unidad Organizativa</th>
                            <th class="text-left">Sexo</th>
                            <th class="text-left">Ingreso Bruto</th>
                            <th class="actionsBtn">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>


                            <tr *ngFor="let item of paginatedRecords">
                                <td class="text-left">{{item.employeeId}}</td>
                                <td class="text-left">{{item.name}}</td>
                                <td class="text-left">{{item.departmentName}}</td>
                                <td class="text-left">{{item.sex}}</td>
                                <td class="text-left">{{item.totalCredits}}</td>
                                <td class="actionsBtn">
                                    <button (click)="openAddNew(item, 'Agregar Novedad', 'add')" class="btn btn-icon btn-light-info btn-sm m-1" *ngIf="privilege.newsAction.value">
                                        <i class="flaticon-calendario icon-2x" data-toggle="tooltip" title="Agregar novedad"></i>
                                    </button>


                                    <button class="btn btn-icon btn-light-success btn-sm m-1" *ngIf="privilege.approveAction.value"
                                    (click)="newsToApprove(item.employeeId)">
                                        <i class="flaticon-etiqueta icon-2x" data-toggle="tooltip" title="Gestionar novedades"></i>
                                    </button>
                                    <button (click)="viewNewsDetails(item)" class="btn btn-sm btn-light-skype btn-icon mr-1" *ngIf="privilege.viewAction.value">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver aplicables"></i>
                                    </button>
                                    <button *ngIf="showExcludeBtn && privilege.Exclude.value" (click)="removeEmployee(item)" type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                        data-title="Excluir" >
                                        <i class="flaticon-usuario-1" data-toggle="tooltip" data-title="Excluir"></i>
                                    </button>
                                </td>
                            </tr>
                    </tbody>
                </table>
                <div>
                    <el-caption #paginator
                        [originalRecordsLength]="employeeList.length"
                        [listedRecordsLength]="paginatedRecords?.length"
                        [registerQuantity]="filteredList?.enable && filteredList.records?.length>0 ? filteredList.records?.length :employeeList?.length"
                        [records]="filteredList.enable ? filteredList.records : employeeList"
                        (onChage)="getPaginatedRecords($event)"></el-caption>

                </div>
            </div>
        </div>
    </div>
</ng-container>

<export-data-to-pdf [title]="'Nómina Adicional'" id="dataToPrint">
    <ng-container thead>
        <th class="text-left">Servidor Público</th>
        <th class="text-left">Unidad Organizativa</th>
        <th class="text-left">Sexo</th>
        <th class="text-left">Ingreso Bruto</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let item of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.employeeList"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td class="text-left">{{item.firstName + ' ' + item.lastName}}</td>
            <td class="text-left">{{item.departmentName}}</td>
            <td class="text-left">{{item.sex}}</td>
            <td class="text-left">{{item.totalCredits}}</td>
        </tr>
    </ng-container>
</export-data-to-pdf>
