import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-text-area',
  templateUrl: './modal-text-area.component.html',
  styleUrls: ['./modal-text-area.component.css']
})
export class ModalTextAreaComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalTextAreaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {text:string, inputLabel:string,modalTitle:string, rows?: number}) { }

  ngOnInit(): void {
  }

}
