import { SweetAlert } from "../../../../shared/utils/Sweet-Alert";
import { Component } from "@angular/core";
declare const $: any

@Component({
    selector: 'list-payroll',
    templateUrl: './list-payroll.component.html',
})

export class ListPayrollComponent {
    sweetAlert = new SweetAlert()
    loading = false;
    constructor () {}
    async deleteAlert() {
        const success = await this.sweetAlert.AlertConfirm(
            'Eliminar',
            `¿Seguro que deseas borrar el Servidor Público de esta nómina?`,
            'error'
          );
      
          if (success) {
            this.loading = true
            setTimeout(() => { 
                this.loading = false
              }, 2000)
          } 
    }

    search(search: string){
        
    }
}