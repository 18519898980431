import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-funds-certification',
  templateUrl: './funds-certification.component.html',
  styleUrls: ['./funds-certification.component.css']
})
export class FundsCertificationComponent implements OnInit {

  tableData = [
    {type: "Nuevos Nombramientos", requested: 226, rejected: 27, approved: 192, pending: 7},
    {type: "Promoción personal", requested: 30, rejected: 6, approved: 24, pending: "-"},
    {type: "Nuevos puestos", requested: 6, rejected: 1, approved: 4, pending: 1},
    {type: "Proyectos especiales", requested: 1, rejected: "-", approved: "-", pending: 2},
  ]
  fundRequestData = [];
  showTable = false;
  fillTable(){
    this.showTable = true;
    this.fundRequestData = [
      {remission: "2354-2021", detail: "Luis Manuel Marte Then", requestDate: "12-8-2021", type: "Nombramiento", estimate: "201-346-01-01-2018", amount: 40000}
    ];
  }




  constructor() { }

  ngOnInit(): void {
  }

}
