<div *ngIf="isLoading; else loaded" class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
    <el-spinner (loadingStatus)="isLoading=$event" animation="spinner-logo">
    </el-spinner>
</div>

<ng-template #loaded>
    <div class="card card-custom">
        <div class="card-header mt-3">
            <div class="card-title">
                <div class="ttl d-flex align-items-center">
                    <i class="flaticon-colaboracion icon-3x text-danger mr-2"></i>
                    <h1 class="text-primary"> Acciones de Personal </h1>
                </div>
            </div>
            <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard" data-toggle="tooltip" data-original-title="Dashboard">
                <button data-toggle="modal" class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto" data-toggle="modal"
                data-target="#dashboard">
                    <i class="flaticon-dashboard-1 icon-2x" ></i> Ver Dashboard
                </button>
            </div>
        </div>

        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-6 c-left">
                <div class="card-toolbar d-flex justify-content-start">

                    <div class="col-md-4 mr-2 px-0 pt-1">
                      <div class="form-group mx-2 institution">
                        <label class="text-primary">
                          Estatus
                        </label>
                      <ng-multiselect-dropdown
                      [placeholder]="'Seleccionar Estatus'"
                      [settings]="dropdownSettings"
                      [data]="statusOptions"
                      [(ngModel)]="selectedItems"
                      (onDeSelectAll)="onDeSelectAll()"
                      (onDeSelect)="onItemDeSelect($event)"
                      (onSelect)="onItemSelect($event)"
                      (onSelectAll)="onSelectAll($event)"
                      ></ng-multiselect-dropdown>
                      </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-5 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12" >
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white"  data-toggle="tooltip" data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" (click)="exportToExcel()">
                                <i class="flaticon-excel icon-2x text-success mr-3"></i>
                                Excel
                            </a>
                            <!-- <a class="dropdown-item disabled" href="#">
                                <i class="flaticon-word icon-2x text-primary mr-3"></i>
                                Word
                            </a>
                            <a class="dropdown-item disabled" href="#">
                                <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                                PDF
                            </a>
                            <a class="dropdown-item disabled" href="#">
                                <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                                CSV
                            </a> -->
                        </div>
                    </div>

                    <div class="col text-nowrap">
                        <el-search-bar [records]="employeesListOriginal" [itemForSearching]="searchBarDisplayNames"
                            (onChange)="getFilteredList($event)"></el-search-bar>
                    </div>
                </div>
            </div>
        </div>

            <div class="card-body">

                    <div class="table-responsive">
                        <table class="table table-vertical-center">
                            <thead>
                                <tr class="bg-primary">
                                    <th class="text-center">Servidor Público</th>
                                    <th class="text-left">Posición</th>
                                    <th class="text-center min-w-150px">Tipo</th>
                                    <th class="text-left">Concepto</th>
                                    <th class="text-left">Monto</th>
                                    <th class="text-center min-w-150px">Fecha Inicio</th>
                                    <th class="text-center">Fecha Final</th>
                                    <th class="text-center">Monto Total</th>
                                    <th class="text-center">Saldo</th>
                                    <th class="text-center">Estatus</th>
                                    <th class="actionsBtn">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let employee of paginatedEmployees">
                                    <td class="text-center">
                                        {{employee.name}}
                                    </td>
                                    <td class="text-center">{{employee.employNumber}}</td>
                                    <td class="text-left">{{employee.positionName}}</td>
                                    <td class="text-center">{{employee.employeeType}}</td>
                                    <td class="text-center">-</td>
                                    <td class="text-left">-</td>
                                    <td class="text-left"></td>
                                    <td class="text-center">-</td>
                                    <td class="text-center">-</td>
                                    <!-- <td class="text-center"></td> -->
                                    <td>
                                      <span *ngIf="employee.status" class="font-weight-bold text-success">Activa</span>
                                      <span *ngIf="!employee.status" class="font-weight-bold text-danger">Inactiva</span>
                                    </td>
                                    <td class="actionsBtn">
                                        <a *ngIf="showActives && employee.status" [routerLink]="['/candidate-registration/',employee.personId, 1,employee.employeeId]" class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip" title="Editar">
                                            <i class="flaticon-edit"></i>
                                        </a>
                                        <a *ngIf="employee.status" [routerLink]="['/pdf-preview/',employee.personId]" class="btn btn-icon btn-light-info btn-sm m-1" data-toggle="tooltip" title="Ver Servidor Público">
                                            <i class="flaticon-ver"></i>
                                        </a>
                                        <a *ngIf="showActives && employee.status" [routerLink]="['/action-change-employee',employee.employNumber]" class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip" title="Acciones del Personal">
                                            <i class="flaticon-colaboracion icon-2x"></i>
                                        </a>
                                        <!-- <a *ngIf="showActives" [routerLink]="['/personnel-actions/',employee.employNumber]" class="btn btn-icon btn-light-primary btn-sm m-1" data-toggle="tooltip" title="Acciones deServidores Públicos">
                                            <i class="flaticon-lista-de-verificacion icon-2x"></i>
                                        </a>
                                        <a *ngIf="showActives" class="btn btn-icon btn-light-danger btn-sm m-1" data-target="#desvincular" (click)="edit(employee)" data-toggle="modal">
                                            <i class="flaticon-mala-critica icon-2x" data-toggle="tooltip" title="Desvincular"></i>
                                        </a> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-between align-items-center flex-wrap">
                            <div class="col-sm-12 col-md-5 pl-0">
                                <div class="d-flex align-items-center mr-7">
                                    <span class="svg-icon svg-icon-gray-500 pr-2">
                                        <i class="flaticon-marca-de-verificacion"></i>
                                    </span>
                                    <div class="dataTables_info" id="kt_datatable_info" role="status" aria-live="polite">
                                        Total de registros <span
                                            class="font-weight-bolder text-primary ml-1">{{paginatedEmployees.length}} </span>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex justify-content-end align-items-center flex-wrap">
                                <el-caption #paginator [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :employees.length" [records]="filteredList.enable ? filteredList.records : employees" (onChage)="getPaginatedRecords($event)"></el-caption>
                            </div>
                        </div>
                    </div>

            </div>

    </div>
    

</ng-template>



