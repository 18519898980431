export class Content {
    newsId?: number;
    companyId: number;
    date: string;
    postType: number;
    postImage: string;
    postVideo: string;
    postTitle: string;
    postText: string;
    createUserId: number;
    employeeId: number;
    allowComment: boolean;
    allowShared: boolean;
    publicationClassificationId: number;
    link: string;
    mediaFile: string;
    originId: number;
    conditionId: number
    directedId: number
    IsActivity: boolean
    status: boolean;
    linkCode: string
    postGalleries: postGallery[]
    isRecurring: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    finalDate: string;

    constructor() {
        this.companyId = 0
        this.date = ""
        this.postType = 0
        this.postImage = ""
        this.postVideo = ""
        this.postTitle = ""
        this.postText = ""
        this.createUserId = 0
        this.employeeId = 0
        this.allowComment = false
        this.allowShared = false
        this.publicationClassificationId = 0
        this.link = ""
        this.mediaFile = ""
        this.originId = 0
        this.conditionId = 0
        this.directedId = 0
        this.postGalleries = []
        this.linkCode = ''
        this.IsActivity = false
        this.status = false
        this.isRecurring= false;
        this.monday= false;
        this.tuesday= false;
        this.wednesday= false;
        this.thursday= false;
        this.friday= false;
        this.saturday= false;
        this.sunday= false;
        this.finalDate= "";
    }
}


export interface postGallery {
    postGalleryId: number;
    newsId: number;
    image: string;
}

export interface postContent {
    newsId: number;
    userId: number;
}
