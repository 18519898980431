import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Inject, NgZone, PLATFORM_ID } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";

// amCharts imports


import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { isPlatformBrowser } from '@angular/common';



@Component({
  selector: 'app-semi-circle',
  templateUrl: './semi-circle.component.html',
  styleUrls: ['./semi-circle.component.css']
})
export class SemiCircleComponent implements OnInit, OnDestroy, OnChanges {
  private root: am5.Root;
  @Input() valueField = 'value'
  @Input() categoryField = 'description'
  @Input() data = []
  
  /* 
  [
      { value: 10, category: "One" },
      { value: 9, category: "Two" },
      { value: 6, category: "Three" },
      { value: 5, category: "Four" },
      { value: 4, category: "Five" },
      { value: 3, category: "Six" },
      { value: 1, category: "Seven" }
    ]
  
  
  
  */

  
  @Input() id: string = "chartSemiCircle"
  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) {}
  ngOnChanges(changes: SimpleChanges): void {
    if(this.data){
      this.buildChart()
      if(this.root){
        this.root.dispose()
        this.root = am5.Root.new(this.id)
        this.buildChart()
      }
    }
  }
  ngOnDestroy(): void {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
        this.root = undefined
        document.getElementById(this.id).remove()
        
      }
    });
  }


  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }


  ngAfterViewInit() {
    this.browserOnly(() => {
      this.root = am5.Root.new(this.id);
      this.buildChart()
    })
    
    
  }
  
  buildChart(){
    if(!this.root) return
    let root = this.root
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    // start and end angle must be set both for chart and series
    let chart = root.container.children.push(am5percent.PieChart.new(root, {
      startAngle: 180,
      endAngle: 360,
      layout: root.verticalLayout,
      innerRadius: am5.percent(50)
    }));

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    // start and end angle must be set both for chart and series
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      startAngle: 180,
      endAngle: 360,
      valueField: this.valueField,
      categoryField: this.categoryField,
      alignLabels: false
    }));

    series.states.create("hidden", {
      startAngle: 180,
      endAngle: 180
    });

    series.slices.template.setAll({
      cornerRadius: 5
    });

    series.ticks.template.setAll({
      forceHidden: true
    });

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(this.data);

    series.appear(1000, 100);
  }


  
  ngOnInit(): void {
  }

}
