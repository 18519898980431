import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Departments } from '../models/departments.model';
import { Positions } from '../models/positions.model';
import { EmployeeDepartment } from '../pages/RegistrationAndControl/employee-maintenance/models/employee-department.model';
import { EmployeeInfo } from '../pages/RegistrationAndControl/employee-maintenance/models/EmployeeMaintenance';
import { PersonsDependent, PersonsEmail } from '../pages/RegistrationAndControl/pdf-preview/models/pdf-personal-record.model';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeMaintenanceService {

  private readonly api = environment.api_url;
  private readonly core_api = environment.api_core_url;
  constructor(
    private _http: HttpClient,
    private authInfo: AuthInfoService
  ) { }


  getEmployeeByEmployeeNumber(employeeNumber: number): Observable<ResponseModel<EmployeeInfo>>{
    return this._http.get<ResponseModel<EmployeeInfo>>(`${this.api}/employees?type=2&EmployeeNumber=${employeeNumber}`)
  }

  getEmployeeDepartment(departmentId:number): Observable<ResponseModel<EmployeeDepartment>>{
    return this._http.get<ResponseModel<EmployeeDepartment>>(`${this.core_api}/Deparments/GetDeparments?DepartmentId=${departmentId}`)
  }

  getDepartments():Observable<ResponseModel<Departments>>{
    return this._http.get<ResponseModel<Departments>>(this.core_api + `/CompanyStructure?CompanyId=${this.authInfo.getCompanyId()}&IdDepartment=0`)
  }

  getEmployeePosition(positionId:number):Observable<ResponseModel<Positions>> {
    return this._http.get<ResponseModel<Positions>>(`${this.core_api}/positions?positionId=${positionId}&CompanyId=${this.authInfo.getCompanyId()}`)
  }

  getPersonDependents(personId:number):Observable<ResponseModel<PersonsDependent>>{
    return this._http.get<ResponseModel<PersonsDependent>>(`${this.core_api}/PersonsDependents?PersonId=${personId}&companyId=${this.authInfo.getCompanyId()}`)
  }
  getEmployeeEmails(personId:number): Observable<ResponseModel<PersonsEmail>> {
    return this._http.get<ResponseModel<PersonsEmail>>(`${this.core_api}/PersonsEmails?IdPersonEmail=${personId}&companyId=${this.authInfo.getCompanyId()}`);
}
}
