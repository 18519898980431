<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Anular Acción de Personal
    </h2>
    <button type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom ">
    <div class="p-5">
        <div class="row pb-4">
            <div class="col-md-4">
                <div class="form-group">
                    <label >Servidor Público</label>
                    <h4>
                        {{data?.actionData?.name}}
                    </h4>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label >Código</label>
                    <h4>
                        {{data?.actionData?.employNumber}}
                    </h4>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label >Documento de Identidad </label>
                    <h4>
                        {{data?.actionData?.personalIdentification | mask:'000-0000000-0'}}
                    </h4>
                </div>
            </div>
        </div>
        <div class="row pb-4" *ngIf="requestInfo?.actionCanceledId > 0">
            <div class="text-primary ">
                <hr>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label >Secuencia de la Solicitud</label>
                    <h4>
                        {{requestInfo?.actionCanceledId}}
                    </h4>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label >Fecha</label>
                    <h4>
                        {{(requestInfo?.requestDate      | date:'dd/MM/yyyy')}}
                    </h4>
                </div>
            </div>
            <div class="col-md-4">
                <div class="d-flex align-items-center">
                    <div class="form-group">
                        <label >Estado</label>
                        <h4>
                            <ng-container [ngSwitch]="requestInfo.conditionId" >
                                <span *ngSwitchCase="1" class="font-weight-bold text-info">{{requestInfo?.condition || 'N/A'}}</span>
                                <span *ngSwitchCase="2" class="font-weight-bold text-success">{{requestInfo?.condition || 'N/A'}}</span>
                                <span *ngSwitchCase="3" class="font-weight-bold text-danger">{{requestInfo?.condition || 'N/A'}}</span>
                                <span *ngSwitchCase="4" class="font-weight-bold text-success">{{requestInfo?.condition || 'N/A'}}</span>
                                <span *ngSwitchDefault class="font-weight-bold ">
                                        <span class="mr-2">N/A</span>
                                </span>
                            </ng-container>
                        </h4>
                    </div>
    
                    <a *ngIf="requestInfo.conditionId == 3" class="btn btn-sm btn-light-danger btn-icon mr-2 ml-5" (click)="openCommentModal(requestInfo?.rejectComment)"
                        data-toggle="tooltip" title="Ver razón de Rechazo"> 
                        <i class="flaticon-ver icon-2x" ></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="row  mt-4 pb-5">

            <div class="card custom-card p-4">
                <div class="row  m-0 card-body p-0">
                    <div class="col">
                        <div class="text-primary pt-4">
                            <h4>Datos actuales de la Acción de Personal</h4>
                            <hr>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Clasificación </label>
                                    <div>
                                        <input type="text" class="form-control" value="{{data?.actionData?.personalActionsType || 'N/A' }}" disabled>
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Tipo </label>
                                    <div>
                                        <input type="text" class="form-control" [value]="data?.actionData?.typePermissions" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Fecha Inicio </label>
                                    <div>
                                        <input type="text" class="form-control" value="{{(data?.actionData?.dateStart      | date:'dd/MM/yyyy') || 'N/A' }}" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Fecha Final </label>
                                    <div>
                                        <input type="text" class="form-control" value="{{(data?.actionData?.dateEnd        | date:'dd/MM/yyyy') || 'N/A' }}" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Fecha Efectiva </label>
                                    <div>
                                        <input type="text" class="form-control" value="{{(data?.actionData?.effectiveDate  | date:'dd/MM/yyyy') || 'N/A' }}" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="isPermanentChange">
                                <div class="form-group">
                                    <label for="">Dato Solicitado </label>
                                    <div>
                                        <input type="text" *ngIf="!permanentChangeInfo?.valueAfterNumeric && !isDate(permanentChangeInfo?.valueAfter)" class="form-control" value="{{permanentChangeInfo?.valueAfter || 'N/A'}}" disabled>
                                        <input type="text" *ngIf="permanentChangeInfo?.valueAfterNumeric" class="form-control" value="{{(permanentChangeInfo?.valueAfter || 'N/A') | currency }}" disabled>
                                        <input type="text" *ngIf="!permanentChangeInfo?.valueAfterNumeric && isDate(permanentChangeInfo?.valueAfter)" class="form-control" value="{{(permanentChangeInfo?.valueAfter || 'N/A') | date:'dd/MM/yyyy'}}" disabled>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="isPermanentChange">
                                <div class="form-group">
                                    <label for="">Dato Adicional </label>
                                    <div>
                                        <input type="text" class="form-control" value="{{permanentChangeInfo?.additionalValueName || 'N/A'}}" disabled>
                                    </div>
                                </div>
                            </div>
                
                        </div>
                    </div>
                    <div class="col-md-1 d-flex justify-content-center align-items-center text-center " *ngIf="isPermanentChange">
                        <i class="fas fa-arrow-right text-muted mr-2"></i>
                        <i class="fas fa-arrow-right text-primary mr-2"></i>
                        <i class="fas fa-arrow-right text-muted mr-2"></i>
                        <i class="fas fa-arrow-right text-primary "></i>
                    </div>
                    <div class="col" *ngIf="isPermanentChange">
                        <div class="text-primary pt-4">
                            <h4>Datos a aplicar luego de la Anulación</h4>
                            <hr>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Dato Anterior </label>
                                    <div>

                                        <input type="text" *ngIf="!permanentChangeInfo?.valueAfterNumeric && !isDate(permanentChangeInfo?.valueAfter)" class="form-control" value="{{permanentChangeInfo?.valueBefore || 'N/A'}}" disabled>
                                        <input type="text" *ngIf="permanentChangeInfo?.valueAfterNumeric" class="form-control" value="{{(permanentChangeInfo?.valueBefore || 'N/A') | currency }}" disabled>
                                        <input type="text" *ngIf="!permanentChangeInfo?.valueAfterNumeric && isDate(permanentChangeInfo?.valueAfter)" class="form-control" value="{{(permanentChangeInfo?.valueBefore || 'N/A') | date:'dd/MM/yyyy'}}" disabled>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Dato Adicional </label>
                                    <div>
                                        <input type="text" class="form-control" value="{{permanentChangeInfo?.additionalValueBeforeName || 'N/A'}}" disabled>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="row pb-3 m-0 pt-5">
            <form [formGroup]="form" class="row m-0">
                <div class="col-md-6">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Motivo <span class="text-danger">*</span></label>
                            <div>
                                <ngx-select-dropdown formControlName="reasonObject" [config]="dropdownReasons"
                                    [options]="reasonList" [multiple]="false" class="flex-1"
                                    (change)="changeReason($event.value)">
                                </ngx-select-dropdown>
                            </div>
                            <errors-component [controlName]="form.get('reasonId')">
                            </errors-component>
                        </div>
                    </div>
                    <div class="col-md-12">
                            <div class="form-group">
                                <label for="">Comentario </label>
                                <div>
                                    <textarea type="date" class="form-control" formControlName="comment"
                                        rows="9"></textarea>
                                </div>
                                <errors-component [controlName]="form.get('comment')">
                                </errors-component>
                            </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Evidencia </label>
                        <div class="card align-items-center">
                            <uppy-file 
                                #uppyFile
                                cardBody="card-body"
                                classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px"
                                targetClass="actionEvidence"
                                previewDefaultImage="assets/images/cv.jpg"
                                [width]="320"
                                [height]="295"
                                [isDisabled]="data?.viewType == viewTypes.View"
                                [showDocumentoAfterUploaded]="false"
                                [fileForEditting]="evidenceGUID"
                                [allowedFileTypes]="['application/pdf']"
                                (fileEmitter)="setEvidence($event)">
                            </uppy-file>                    
                        </div>
                        <errors-component [controlName]="form.get('evidence')">
                        </errors-component>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="dialogRef.close(false)">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="data.viewType != viewTypes.View"
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success mr-2" type="button"
        [disabled]="form.invalid" (click)="sendRequest()">
        <i class="flaticon-enviar"></i>
        <span>Enviar Solicitud de Anulación</span>
    </button>
</div>