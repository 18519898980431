import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { docService } from 'src/app/services/doc.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { environment } from 'src/environments/environment';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-evindence-modal',
  templateUrl: './evindence-modal.component.html',
  styleUrls: ['./evindence-modal.component.css']
})
export class EvindenceModalComponent implements OnInit {
  private readonly urlDoc = environment.api_doc + '/Document';
  disabled = false;
  file = { document: ''}
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  personalFile = personalFile;
  evidenceUppy = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['application/pdf']
    },
    locale:Mexico
  });

  evidenceGUID: string = '';

  constructor(
    private dialogRef: MatDialogRef<EvindenceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private srvParameter: ParameterControlService,
    private docService: docService,
  ) { }

  ngOnInit(): void {
    const { item } = this.data
    const evidence = item.get('evidence').value
    if(evidence){
      this.getDocument(evidence)
      this.disabled = true
    }
    this.getParameters()
  }



  

  getDocument(guid) {
    try {
      this.docService.getDocument(guid).subscribe(e => {
        this.file.document = e.data
      }, error => {
        //this.toastr.error(error)
      })
    } catch (error) {
      //this.toastr.error(error)
    }
  }



  close(){
    this.dialogRef.close()
  }

  
  loadUppy() {
    this.evidenceUppy.use(Dashboard, {
      trigger: '.UppyModalOpenerBtn',
      inline: true,
      disabled: false,
      target: '.evidence1',
      showProgressDetails: true,
      note: 'Solo PDF, 1 archivo, con un maximo de 1 MB',
      width: 300,
      height: 138,
      locale: {
        strings: {
          dropPasteFiles: 'Arrastra archivos aquí o %{browse}'
        }
      },
      thumbnailWidth: 100,
      metaFields: [
        { id: 'name', name: 'Nombre', placeholder: 'Nombre del Archivo' },
        { id: 'caption', name: 'Caption', placeholder: 'Descripción del archivo' }
      ],
      browserBackButtonClose: false,
      showLinkToFileUploadResult: true,
    })
      .use(XHRUpload, { endpoint: this.urlDoc + `?SystemLoad=${this.SystemLoad}&ModuleLoad=${this.ModuleLoad}&personalFile=${this.personalFile.curriculumnVitae}&parameterDoc=${this.routeFile}` })
      .on('file-removed', () => {
      })
      .on('complete', (result) => {
        if (result.successful) {
          let resData = result.successful[0].response.body.data as FileResponse
          let guiID = this.getGuid(resData.fileUnit.guidname)
          this.file.document = resData.path;
          this.evidenceGUID = guiID ? guiID : null
        }
      });
  }

  confirm(){
    this.dialogRef.close({guid: this.evidenceGUID, i: this.data.i})
  }

  
  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }
  getParameters(){
    this.srvParameter.getParameters(parameterDoc.PathTest).subscribe(e => {
      if (e.dataList.length > 0) {
        this.routeFile = e.dataList[0].stringData.toString();
      }
      this.loadUppy()
    })

  }

}
