import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { Subscription } from 'rxjs';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';

declare const $: any;

@Component({
  selector: 'app-recurring-news',
  templateUrl: './recurring-news.component.html',
  styleUrls: ['./recurring-news.component.css']
})
export class RecurringNewsComponent implements OnInit {

  public photoUrl: string = ''
  public loadingPic: boolean = true

  isLoading: boolean = true;
  subscription: Subscription = new Subscription();
  employees: any[] = [];
  employeesListOriginal: any[] = [];
  @ViewChild('paginator') paginator: CaptionComponent;
  paginatedEmployees: any[] = [];
  employeesAI: boolean = false;
  tableFilter = '';
  employeeFilter: any = '';
  showActives: boolean = true;
  typeExitList: any[] = [];
  reasonExitList: any[] = [];
  form: FormGroup;
  employeeId: number;
  filterText:string = '';
  postulationId: number;
  exitReasonDescription;
  vacantId: number;
  selectedItems:any;
  personId: number;
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "name", displayName: "Servidor Público" },
      
      { propkey: "positionName", displayName: "Posición" },
      { propkey: "employeeType", displayName: "Tipo" },
      { propkey: "employNumber", displayName: "Concepto" },
    ];
    statusOptions = [
      { item_id: 1, item_text: 'Activos'},
      { item_id: 2, item_text: 'Inactivos'}
    ];
    dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      enableCheckAll: true,
      itemsShowLimit: 2,
      allowSearchFilter: false,
      limitSelection: -1
    };

    getFilteredList(event) {
      this.filteredList = event;
      this.paginator.selectedPage = 1;
      this.filteredList.records = this.filterTable(event.records)
    }

    constructor(private employeeService: EmployeesService,
      private _toastService: ToastService,
      private _exlService: ExportToExcelService,
      private _cdRef: ChangeDetectorRef,
      private _parameterService: ParameterControlService,
      private _fb: FormBuilder,
      private _toastr: ToastrService, private srvDoc: docService, private authInfo: AuthInfoService) { }

  ngOnInit(): void {
    if(localStorage.getItem("employee-list_filter")){
      this.selectedItems = JSON.parse(localStorage.getItem("employee-list_filter"));
    }else{
      this.selectedItems = [{ item_id: 1, item_text: 'Activos'}];
    }
    this.getEmployees();
    this.getTypeExit();
    this.getReasonExit();
    this.initializeForm();
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  employeeToggle() {
    this.paginatedEmployees = []
    this.showActives = !this.showActives;
    this.getEmployees();
  }


  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  onDeSelectAll(){
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item:any){
    this.filter();
  }
  onItemSelect(item:any){
    this.filter();
  }
  onSelectAll(item:any){
    this.selectedItems = item;
    this.filter()
  }

  filter(){
    document.getElementById('searchBarBtn').click()
    if(this.filteredList.records.length > 0 && this.filterText){
      this.filteredList.records = this.filterTable(this.filteredList.records)
    }else{
      this.employees = this.filterTable(this.employeesListOriginal)
    }
    this.setFilterToLocalStorage();
  }
  setFilterToLocalStorage(){
    localStorage.setItem('employee-list_filter', JSON.stringify(this.selectedItems));
  }

  filterTable(list:any[]){
    let sl = this.selectedItems;
    if(sl.find(x => x.item_id == 1) && !sl.find(x => x.item_id == 2)){
      list = list.filter(x => x.status == true);
    }
    if(sl.find(x => x.item_id == 2) && !sl.find(x => x.item_id == 1)){
      list = list.filter(x => x.status == false);
    }
    return list;
  }

  getEmployees() {
    var value = this.employeeService.getEmployees().subscribe((res: any) => {
      if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al obtener la lista de Servidores Públicos"); return; }
      //los endpoints fueron cambiados y por eso no traia el nombre de la persona y el departamento.
      // los campos deServidor Públicos ahora se llaman name antes era fullname, y position cambio a positionname
      this.employees = this.filterTable(res.dataList)
      this.employeesListOriginal = res.dataList
    }, err => this._toastService.error(err))
    this.subscription.add(value);
  }

  ApplySearch() { }
  vacancyToggle() { }

  getPaginatedRecords(event) {
    this.paginatedEmployees = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  exportToExcel() {
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Código del Servidor Público",
      "Servidor Público",
      "Secuencia de Postulación",
      "Secuencia de Envío al MAP",
      "Secuencia de Posición",
      "Secuencia de Unidad Organizativa",
      "Secuencia de Tipo de Servidor Público",
      "Tipo de Servidor Público",
      "Fecha de Admisión",
      "Expiración de Nombramiento",
      "Secuencia de Tipo de Nombramiento",
      "Tipo de Nombramiento",
      "Secuencia de Rama",
      "Secuencia de Entidad Bancaria",
      "Entidad Bancaria",
      "Número de Cuenta",
      "Secuencia de Tipo de Salida",
      "Razón de Salida",
      "Recepción de Fecha de Salida",
      "Procesando Fecha de Salida",
      "Fecha de Salida Electiva",
      "Pre aviso",
      "Desempleado",
      "Vacaciones",
      "Salario Navidad",
      "Observaciones",
      "Evidencia",
      "Fecha Efectiva",
      "Carta de Renuncia",
      "Cierre de Cuenta",
      "Llaves de Oficina",
      "Secuencia de la Companía",
      "Estatus",
      "Identificación",
      "Secuencia de la Persona",
      "Secuencia de la Vacante",
      "¿Es Supervisor?",
      "Información Autorizada",
      "Comentario",
      "Condición",
      "Fecha en que Aplicó",
      "Cantidad de Pruebas",
      "Cantidad de Entrevistas",
      "Secuencia de Unidad Organizativa",
      "Unidad Organizativa",
      "Posición",
      "Descripción de Razón de Salida",
      "Imagen de Perfil",
      "Secuencia de AFP",
      "AFP",
      "Secuencia de ARS",
      "ARS",
      "Salario",
      "Secuencia de Tipo de Identificación",
      "Tipo de Identificación",
      "Número de Teléfono",
    ]]
    this._exlService.exportToExcelSpecificColumns(this.filteredList.enable ? this.filteredList.records : this.employees, excelHeaders, 'Lista de Servidores Públicos');
  }



  getReasonExit() {
    var value = this._parameterService.getParameters(keyWord.ReasonExit).subscribe((data: any) => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return
      }
      this.reasonExitList = data.dataList;
    })
    this.subscription.add(value);
  }

  getTypeExit() {
    var value = this._parameterService.getParameters(keyWord.TypeExit).subscribe((data: any) => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return
      }
      this.typeExitList = data.dataList;
    })
    this.subscription.add(value);
  }

  config = {
    displayKey: "stringData",
    search: true,
    height: 'auto', //height of the liilts to auto. With auto height scroll will never appear
    placeholder: 'Elige una opción', //i    customComparator: ()=>{}, // a cus ndefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits e limited)
    moreText: 'more', // text to be di
    noResultsFound: 'Sin resultados!',
    searchPlaceholder: 'Buscar', // lab
    searchOnKey: 'stringData', // key on whiwill be selective search. if undefined this will be extensive search on all keys
  }

  initializeForm() {
    this.form = this._fb.group({
      employeeId: 0,
      employNumber: '',
      //firstName:	"",
      //lastName:	"",
      postulationId: 0,
      //sendMapId:	"",
      //positionId:	"",
      //departmentId:	"",
      //typeEmployeeId:	"",
      employeeType: "",
      //admissionDate:	"",
      //contractExpiration:	"",
      //typeContractId:	"",
      //contractType:	"",
      //branchId:	"",
      //entityBankId:	"",
      //bankingEntity: "",
      //bankAccount:	"",
      exitTypeId: 0,
      exitReason: null,
      receptionExitDate: "",
      processingExitDate: "",
      electiveDepartureDate: "",
      //preAdvised:	false,
      //unemployment:	false,
      //holidays:	null,
      //christmasSalary: null,
      //observations:	null,
      //evidence:	null,
      //effectiveDate:	"",
      //departureLetter:	false,
      //closingAccount:	false,
      //officekeys:	false,
      //companyId: this.authInfo.getCompanyId(),
      status: true,
      //personalIdentification:	"",
      //positionName:	"",
      departmentName: "",
      personId: 0,
      //vacantId:	"",
      //isSupervisor:	false,
      //authorizedData:	true,
      //comment: "",
      //condition:	"",
      //countInterviews: "",
      //countTest: "",
      //dateApplication: ""
    })
  }

  updateVinculacion() {
    var desvincular: any = {
      employeeId: this.employeeId,
      postulationId: this.postulationId,
      employNumber: this.form.get("employNumber").value,
      departmentName: this.form.get("departmentName").value,
      exitTypeId: this.form.get("exitTypeId").value.ocode,
      exitReason: this.form.get("exitReason").value.ocode,
      electiveDepartureDate: this.form.get("electiveDepartureDate").value,
      processingExitDate: this.form.get("processingExitDate").value,
      receptionExitDate: this.form.get("receptionExitDate").value,
      exitReasonDescription: this.exitReasonDescription,
      companyId: this.authInfo.getCompanyId(),
      sendMapId: 1,
      positionId: 1,
      departmentId: 1000,
      typeEmployeeId: 1,
      typeContractId: 1,
      branchId: 1,
      entityBankId: 0,
      personId: this.personId,
      vacantId: this.vacantId,
      employeeBenefictContract: [
        {
          ecbid: 0,
          employeeId: 0,
          benefitId: 0,
          companyId: this.authInfo.getCompanyId(),
          status: true
        }
      ]
    }
    var value = this.employeeService.UpdateEmployee(desvincular).subscribe((data: any) => {
      if (data.errors.length > 0) {
        this._toastr.error(data.errors[0])
        return
      }
      document.getElementById("btnClose").click();
      this.getEmployees();
      this._toastr.success("El registro ha sido desvinculado con éxito", "Confirmación");
    }, error => {
    })
    this.subscription.add(value);
  }

  edit(value: any) {
    this.employeeId = value.employeeId;
    this.postulationId = value.postulationId;
    this.exitReasonDescription = value.exitReasonDescription;
    this.personId = value.personId;
    this.vacantId = value.vacantId;
    this.form.patchValue({
      employNumber: value.employNumber,
      employeeType: value.employeeType,
      departmentName: value.departmentName
    })
    this.showEmployee(value)
  }
  showEmployee(employee) {
    this.photoUrl = ''
    if (employee.profileImage != '' && employee.profileImage != null) {
      this.srvDoc.getDocument(employee.profileImage).subscribe(e => {
        if (e.errors.length > 0) {
          this._toastr.error(e.errors[0])
          return
        }
        this.photoUrl = e.data;
        this.loadingPic = false
      }, error => {
        this.loadingPic = false
      })
    } else {
      this.loadingPic = false
    }
  }

}
