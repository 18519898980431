<div *ngIf="isTableLoading" class="d-flex justify-content-center align-items-center spinner-container">
  <el-spinner animation="spinner-logo"></el-spinner>
</div>

<div class="card d-flex">
  <div class="card-toolbar p-3 d-flex justify-content-between mx-4">
    <div class=" text-primary mt-2">
      <h1>{{ titles[selectedPage] }}</h1>
    </div>
    <a [routerLink]="getRouterToBack()" class="btn btn-pill font-weight-bolder btn-shadow btn-warning  ">
      <i class="flaticon2-left-arrow-1"></i>
      <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
    </a>
  </div>
</div>


<div *ngIf="!isTableLoading" class="card card-custom accordion accordion-svg-toggle wz_dtl" data-card="true">
  <div class="card-header">
    <span class="card-icon">

    </span>
    <div class="card-title d-flex justify-content-end text-primary flex-1" data-toggle="collapse"
      data-target="#pasos">
    <!--   <h1>{{ titles[selectedPage] }}</h1> -->
      <span class="svg-icon svg-icon-primary svg-icon-3x">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
          viewBox="0 0 24 24" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24"></polygon>
            <path
              d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
              fill="#000000" fill-rule="nonzero"></path>
            <path
              d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
              fill="#000000" fill-rule="nonzero" opacity="0.3"
              transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "></path>
          </g>
        </svg>
      </span>
    </div>
  </div>

  <div class="card-body p-0">

    <!--begin::Wizard-->
    <div id="kt_wizard" class="wizard wizard-1" data-wizard-state="first" data-wizard-clickable="true">
      <!--begin::Wizard Nav-->

      <div class="wizard-nav border-bottom collapse show wz_dtl_responsive" id="pasos">
        <div class="wz_dtl_scroll">
          <div class="wizard-steps p-2">
            <!--begin::Wizard Step 1 Nav-->
            <div class="wizard-step" id="wdp" name="wdp" data-wizard-type="step" (click)="selectedPage = 1">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-recursos-humanos-6"></i>
                <h3 class="wizard-title">Datos Personales</h3>
              </div>
              <span class="svg-icon svg-icon-xl wizard-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <rect fill="#000000" opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                      x="11" y="5" width="2" height="14" rx="1"></rect>
                    <path
                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                      fill="#000000" fill-rule="nonzero"
                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                    </path>
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>

            </div>
            <!--end::Wizard Step 1 Nav-->
            <!--begin::Wizard Step 2 Nav-->
            <div class="wizard-step " data-wizard-type="step" (click)="selectedPage = 2">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-relacion"></i>
                <h3 class="wizard-title">Medios de Contacto</h3>
              </div>
              <span class="svg-icon svg-icon-xl wizard-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <rect fill="#000000" opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                      x="11" y="5" width="2" height="14" rx="1"></rect>
                    <path
                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                      fill="#000000" fill-rule="nonzero"
                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                    </path>
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <!--end::Wizard Step 2 Nav-->
            <!--begin::Wizard Step 3 Nav-->
            <div class="wizard-step" data-wizard-type="step" (click)="selectedPage = 3">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-network"></i>
                <h3 class="wizard-title">Datos Familiares</h3>
              </div>
              <span class="svg-icon svg-icon-xl wizard-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <rect fill="#000000" opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                      x="11" y="5" width="2" height="14" rx="1"></rect>
                    <path
                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                      fill="#000000" fill-rule="nonzero"
                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                    </path>
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <!--end::Wizard Step 3 Nav-->
            <!--begin::Wizard Step 4 Nav-->
            <div class="wizard-step" data-wizard-type="step" (click)="selectedPage = 4">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-information"></i>
                <h3 class="wizard-title">Información Adicional</h3>
              </div>
              <span class="svg-icon svg-icon-xl wizard-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <rect fill="#000000" opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                      x="11" y="5" width="2" height="14" rx="1"></rect>
                    <path
                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                      fill="#000000" fill-rule="nonzero"
                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                    </path>
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <!--end::Wizard Step 4 Nav-->
            <!--begin::Wizard Step 5 Nav-->
            <div class="wizard-step" data-wizard-type="step" (click)="selectedPage = 5">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-graduated"></i>
                <h3 class="wizard-title">Formación Académica</h3>
              </div>
              <span class="svg-icon svg-icon-xl wizard-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <rect fill="#000000" opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                      x="11" y="5" width="2" height="14" rx="1"></rect>
                    <path
                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                      fill="#000000" fill-rule="nonzero"
                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                    </path>
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <!--end::Wizard Step 5 Nav-->
            <!--begin::Wizard Step 6 Nav-->
            <div class="wizard-step" data-wizard-type="step" (click)="selectedPage = 6">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-ordenador-personal"></i>
                <h3 class="wizard-title">Habilidades</h3>
              </div>
              <span class="svg-icon svg-icon-xl wizard-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <rect fill="#000000" opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                      x="11" y="5" width="2" height="14" rx="1"></rect>
                    <path
                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                      fill="#000000" fill-rule="nonzero"
                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                    </path>
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <!--end::Wizard Step 6 Nav-->
            <!--begin::Wizard Step 7 Nav-->
            <div class="wizard-step" data-wizard-type="step" (click)="selectedPage = 7">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-candidato-2"></i>
                <h3 class="wizard-title">Historial y Referencias</h3>
              </div>
              <span class="svg-icon svg-icon-xl wizard-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <rect fill="#000000" opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                      x="11" y="5" width="2" height="14" rx="1"></rect>
                    <path
                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                      fill="#000000" fill-rule="nonzero"
                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                    </path>
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <!--end::Wizard Step 7 Nav-->
            <!--begin::Wizard Step 8 Nav-->

            <div class="wizard-step" [ngClass]="{'wizard-disabled': idPerson === 0}" data-wizard-type="step" (click)="selectedPage = 8">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-carpeta"></i>
                <h3 class="wizard-title">Archivos Requeridos</h3>
              </div>
              <span class="svg-icon svg-icon-xl wizard-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <rect fill="#000000" opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                      x="11" y="5" width="2" height="14" rx="1"></rect>
                    <path
                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                      fill="#000000" fill-rule="nonzero"
                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                    </path>
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>

            <div class="wizard-step" [ngClass]="{'wizard-disabled': idPerson === 0}" data-wizard-type="step" (click)="selectedPage = 9">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-carne-de-identidad"></i>
                <h3 class="wizard-title">Huellas Dactilares</h3>
              </div>
            </div>
            <!--end::Wizard Step 8 Nav-->
          </div>
        </div>
      </div>
      <!--end::Wizard Nav-->

      <!--begin::Wizard Body-->
      <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
        <div class="col-xl-12">
          <!--begin::Wizard Form-->
          <form class="form fv-plugins-bootstrap fv-plugins-framework" id="kt_form">
            <!--begin::Wizard Step 1-->
            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              <app-personal-information [type]="type" (changeLoadData)="changeLoadData($event)"
                (changeButton)="changePersonalInfomation($event)"></app-personal-information>
            </div>
            <!--end::Wizard Step 1-->
            <!--begin::Wizard Step 2-->
            <div class="pb-5" data-wizard-type="step-content">
              <app-contact-component [personType]="type"></app-contact-component>
            </div>
            <!--end::Wizard Step 2-->
            <!--begin::Wizard Step 3-->
            <div class="pb-5" data-wizard-type="step-content">
              <app-family-data></app-family-data>
            </div>
            <!--end::Wizard Step 3-->
            <!--begin::Wizard Step 4-->
            <div class="pb-5" data-wizard-type="step-content">
              <app-additional-info  (changeValidation)="changeAdditionalInfo($event)"></app-additional-info>
            </div>
            <!--end::Wizard Step 4-->
            <!--begin::Wizard Step 5-->
            <div class="pb-5" data-wizard-type="step-content">
              <app-academic></app-academic>
            </div>
            <!--end::Wizard Step 5-->
            <!--begin::Wizard Step 6-->
            <div class="pb-5" data-wizard-type="step-content">
              <app-skills></app-skills>
            </div>
            <!--end::Wizard Step 6-->
            <!--begin::Wizard Step 7-->
            <div class="pb-5" data-wizard-type="step-content">
              <app-history-references></app-history-references>
            </div>
            <!--end::Wizard Step 7-->
            <!--begin::Wizard Step 8-->
            <div class="pb-5" data-wizard-type="step-content">
              <app-required-files #requiredFiles [personId]="idPerson"></app-required-files>
            </div>

            <div class="pb-5" data-wizard-type="step-content">
              <app-finger-print [personId]="idPerson"></app-finger-print>
            </div>
            <!--end::Wizard Step 8-->
            <!--begin::Wizard Actions-->
            <div class="d-flex justify-content-between border-top mt-5 pt-10">
              <div class="mr-2">
                <button type="button" class="btn btn-light-danger font-weight-bolder text-uppercase px-9 py-4"
                  data-wizard-type="action-prev" (click)="previousPage()">
                  <i class="flaticon2-left-arrow mr-3"></i> Anterior
                </button>
              </div>
              <div>
                <button type="button" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                  data-wizard-type="action-submit" (click)="finish()">
                  Completar
                </button>
                <button id="nextButton" type="button"
                  class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-next"
                  (click)="nextPage()" [disabled]="isValidContinueButton()">
                  Guardar y Continuar <i class="ml-3 flaticon2-right-arrow"></i>
                </button>
              </div>
            </div>
            <!--end::Wizard Actions-->
          </form>
          <!--end::Wizard Form-->
        </div>
      </div>
      <!--end::Wizard Body-->
    </div>
    <!--end::Wizard-->
  </div>
</div>