<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span
            class="h-20px label label-danger label-dot mr-3 w-20px"></span>Motivos de Devolución</h2>
    <button type="button" (click)="close()" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>

<div class="mat-dialog-content-custom">
    <div class="">

            <div class="table-responsive">
                <table class="table table-hover table-vertical-center">
                    <thead class="bg-primary">
                        <tr>
                            <th>Motivo</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let item of motiveListPaginated">
                            <td>{{item.reason}}</td>
                        </tr>
                    </tbody>
                </table>
                
            <el-caption [originalRecordsLength]="motiveList.length"
            [listedRecordsLength]="motiveListPaginated.length" #paginator
            [registerQuantity]="motiveList.length"
            [records]="motiveList"
            (onChage)="getPaginatedRecords($event)"></el-caption>
            </div>
    </div>
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="close()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>