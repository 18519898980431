import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SelectDropDownModule } from "ngx-select-dropdown";
import { SharedModule } from "../../shared/shared.module";
import { MAPRoutingModule } from "./map-routing.module";
import { MapVacanciesComponent } from "./map-vacancies/map-vacancies.component";
import { NoObjectionComponent } from "./no-objection/no-objection.component";
import { MapBudgetApprobationComponent } from './map-budget-approbation/map-budget-approbation.component';
import { MapPostVacanciesComponent } from './map-vacancies/map-post-vacancies/map-post-vacancies.component';
import { MapPersonnelChangeActionsComponent } from './map-personnel-change-actions/map-personnel-change-actions.component';
import { MapPersonnelChangeComponent } from './map-personnel-change-actions/map-personnel-change/map-personnel-change.component';



@NgModule({
    declarations: [
        NoObjectionComponent,
        MapBudgetApprobationComponent,
        MapVacanciesComponent,
        MapPostVacanciesComponent,
        MapPersonnelChangeActionsComponent,
        MapPersonnelChangeComponent
    ],
    imports: [
        SharedModule, 
        CommonModule, 
        SelectDropDownModule, 
        FormsModule,
        ReactiveFormsModule, 
        MAPRoutingModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
  })
  export class MAPModule {}
