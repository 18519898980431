import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { IEmployee } from 'src/app/pages/job-offer/models/Employee';
import { EmployeeMaintenanceService } from 'src/app/services/employee-maintenance.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { PersonsDependent } from '../../pdf-preview/models/pdf-personal-record.model';
import { EmployeeDependentsModalComponent } from './employee-dependents-modal/employee-dependents-modal.component';

@Component({
  selector: 'app-employee-dependents',
  templateUrl: './employee-dependents.component.html',
  styleUrls: ['./employee-dependents.component.css']
})
export class EmployeeDependentsComponent implements OnInit {

  form:FormGroup;

  employeeDependents: PersonsDependent[];
  @Input() selectedEmployee: IEmployee;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>(); 
  constructor(
    private dialog:MatDialog,
    private _employeeMaintenanceService:EmployeeMaintenanceService,
    private _toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this._employeeMaintenanceService.getPersonDependents(this.selectedEmployee.personId).subscribe((response:ResponseModel<PersonsDependent>)=>{
      this.employeeDependents = response.succeded? response.dataList : [];
      if(this.employeeDependents.length==0)
        this._toastrService.warning('El Servidor Público seleccionado no tiene dependientes.', 'Observación');
    });
  }
  submitForm(){
    this.onSubmit.emit(this.form.value)
  }
  editDependent(){
    this.dialog.open(EmployeeDependentsModalComponent, {

    }).afterClosed()
      .subscribe((result) => {
        
      })
  }

}
