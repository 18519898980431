<div
  class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg"
>
  <form>
    <!--begin::Header-->
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      style="background-image: url(assets/media/bg-1.jpg)"
    >
      <!--begin::Title-->
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">Notificaciones</span>
        <span
          class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
          >3 Nuevas</span
        >
      </h4>
      <!--end::Title-->
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <div class="tab-content">
      <!--begin::Tabpane-->
      <div
        class="tab-pane active show p-8"
        id="topbar_notifications_notifications"
        role="tabpanel"
      >
        <!--begin::Scroll-->
        <div
          class="scroll pr-7 mr-n7 ps ps--active-y"
          data-scroll="true"
          data-height="300"
          data-mobile-height="200"
          style="height: 300px; overflow: hidden"
        >
          <!--begin::Item-->
          <div class="d-flex align-items-center mb-6">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light-primary mr-5">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-lg svg-icon-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z"
                        fill="#000000"
                      ></path>
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)"
                        x="16.3255682"
                        y="2.94551858"
                        width="3"
                        height="18"
                        rx="1"
                      ></rect>
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column font-weight-bold">
              <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg"
                >Solicitud de Personal</a
              >
              <span class="text-muted">Recursos Humanos</span>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
          <!--begin::Item-->
          <div class="d-flex align-items-center mb-6">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light-warning mr-5">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-lg svg-icon-warning">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                      ></path>
                      <path
                        d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      ></path>
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column font-weight-bold">
              <a
                href="#"
                class="text-dark-75 text-hover-primary mb-1 font-size-lg"
                >Solicitud de Permiso</a
              >
              <span class="text-muted">Recursos Humanos</span>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
          <!--begin::Item-->
          <div class="d-flex align-items-center mb-6">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light-success mr-5">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-lg svg-icon-success">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z"
                        fill="#000000"
                      ></path>
                      <path
                        d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z"
                        fill="#000000"
                        opacity="0.3"
                      ></path>
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column font-weight-bold">
              <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg"
                >Nuevo tipo de espacio</a
              >
              <span class="text-muted">Academico</span>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->

          <div class="ps__rail-x" style="left: 0px; bottom: 0px">
            <div
              class="ps__thumb-x"
              tabindex="0"
              style="left: 0px; width: 0px"
            ></div>
          </div>
          <div class="ps__rail-y" style="top: 0px; right: 0px; height: 300px">
            <div
              class="ps__thumb-y"
              tabindex="0"
              style="top: 0px; height: 205px"
            ></div>
          </div>
        </div>
        <!--end::Scroll-->
        <!--begin::Action-->
        <div class="d-flex flex-center pt-7">
          <a
            href="#"
            class="btn btn-primary font-weight-bold text-center text-white"
            >Ver Todas</a
          >
        </div>
        <!--end::Action-->
      </div>
      <!--end::Tabpane-->
    </div>
    <!--end::Content-->
  </form>
</div>
