<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="this.dialogRef.close(false);" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Parámetros Envio a Concursa</h2>
</div>

<div class="mat-dialog-content">
    <div class="card-body">
        <div class="row gutter-b">
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Cargo Solicitado <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg dynamicFormControl" [value]="requisition?.positionName"
                    readonly type="text">
            </div>
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Recinto <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg dynamicFormControl" [value]="requisition?.enclosure" readonly
                    type="text">
            </div>
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Unidad Organizativa <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg dynamicFormControl" [value]="requisition?.department"
                    readonly type="text">
            </div>
        </div>

        <div class="row gutter-b" [formGroup]="formSendConcursaRequisition">
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Provincia <span class="text-danger">*</span></label>
                <ngx-select-dropdown class="flex-1" [options]="province" formControlName="province"
                    (change)="changeProvince($event.value)" [config]="configCountry">
                </ngx-select-dropdown>
                <span class="text-danger"
                    *ngIf="invalidFormSendConcursaRequisition">{{getErrorFormSendConcursaRequisition('province')}}</span>
            </div>
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Municipio <span class="text-danger">*</span></label>
                <ngx-select-dropdown class="flex-1" [options]="municipality" formControlName="municipality"
                    (change)="changeMunicipality($event.value)" [config]="configCountry">
                </ngx-select-dropdown>
                <span class="text-danger"
                    *ngIf="invalidFormSendConcursaRequisition">{{getErrorFormSendConcursaRequisition('municipality')}}</span>
            </div>
            <div class="form-group col-xl-4 col-lg-6">
                <label>Salario <span class="text-danger">*</span></label>
                <div class="input-group">
                    <input (blur)="onSalaryError()" placeholder="RD$00.00" type="text" currencyMask
                        [options]="{precision:2}" formControlName="salary"
                        class="form-control form-control-solid form-control-lg">
                    <button id="searchBarBtn" class="input-group-text bg-primary" data-toggle="modal"
                        data-target="#salaryRange">
                        <i class="flaticon-ver icon-2x text-white" data-toggle="tooltip"
                            title="Sugerencia salarial"></i>
                    </button>
                </div>
                <span class="text-danger"
                    *ngIf="invalidFormSendConcursaRequisition">{{getErrorFormSendConcursaRequisition('salary')}}</span>
                <span *ngIf="isSalaryInvalid" class="font-weight-bold text-danger">El salario debe estar dentro del
                    rango salarial establecido</span>
            </div>
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Meses de Prueba <span class="text-danger">*</span></label>
                <ngx-select-dropdown class="flex-1" [config]="config" [options]="testingMonths"
                    formControlName="testingMonths">
                </ngx-select-dropdown>
                <span class="text-danger"
                    *ngIf="invalidFormSendConcursaRequisition">{{getErrorFormSendConcursaRequisition('testingMonths')}}</span>
            </div>
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Causa de la Vacante <span class="text-danger">*</span></label>
                <ngx-select-dropdown class="flex-1" [config]="configCountry" [options]="causes"
                    (change)="changeCause($event.value)" formControlName="vacancyCause">
                </ngx-select-dropdown>
                <span class="text-danger"
                    *ngIf="invalidFormSendConcursaRequisition">{{getErrorFormSendConcursaRequisition('vacancyCause')}}</span>
            </div>
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Fecha Requerida <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg dynamicFormControl" [min]="tomorrowDate"
                    formControlName="requiredDate" type="date">
                <errors-component
                    [controlName]="formSendConcursaRequisition.controls['requiredDate']"></errors-component>
            </div>
            <div class="form-group col-12">
                <label class="">Observaciones </label>
                <textarea class="form-control form-control-lg dynamicFormControl" readonly
                    [value]="requisition?.observations" rows="4"></textarea>
            </div>
        </div>

        <div class="row gutter-b">
            <div class="col-md-6">
                <div class="form-group mb-0">
                    <label class="mb-0">Titulaciones <span class="text-danger">*</span></label>
                </div>
                <div class="p-5 py-2 bg-primary">
                    <h3 class="text-white mb-0">Titulación</h3>
                </div>
                <ul class="list-group list-group-flush" [ngClass]="{'scroll-list-group': carrers?.length > 10}">
                    <li class="list-group-item" *ngFor="let carrer of carrers">
                        <div class="d-flex text-primary justify-content-between">
                            <label class="h6 mt-1">{{carrer?.nombre}}</label>
                            <div class="d-flex">
                                <strong
                                    class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                                <div class="flex-shrink-0 mx-2">
                                    <span class="switch switch-sm switch-primary">
                                        <label>
                                            <input [checked]="carrer?.isSeleted" (click)="changeCarrer(carrer)"
                                                class="cursor-pointer abc-checkbox-primary" type="checkbox" />
                                            <span></span>
                                        </label>
                                    </span>
                                </div>
                                <strong
                                    class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="col-md-6">
                <div class="form-group mb-0">
                    <label class="mb-0">Requisitos <span class="text-danger">*</span></label>
                </div>
                <div class="p-5 py-2 bg-primary">
                    <h3 class="text-white mb-0">Requisito</h3>
                </div>
                <ul class="list-group list-group-flush" [ngClass]="{'scroll-list-group': requirements?.length > 10}">
                    <li class="list-group-item" *ngFor="let requirement of requirements">
                        <div class="d-flex text-primary justify-content-between">
                            <label class="h6 mt-1">{{requirement?.nombre}}</label>
                            <div class="d-flex">
                                <strong
                                    class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                                <div class="flex-shrink-0 mx-2">
                                    <span class="switch switch-sm switch-primary">
                                        <label>
                                            <input [checked]="requirement?.isSeleted"
                                                (click)="changeRequirement(requirement)"
                                                class="cursor-pointer abc-checkbox-primary" type="checkbox" />
                                            <span></span>
                                        </label>
                                    </span>
                                </div>
                                <strong
                                    class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row gutter-b">

            <div class="col-md-12">
                <div class="form-group mb-0">
                    <label class="mb-0">Remuneraciones <span class="text-danger">*</span></label>
                </div>
                <div class="p-5 py-2 bg-primary">
                    <h3 class="text-white mb-0">Remuneración</h3>
                </div>
                <ul class="list-group list-group-flush" [ngClass]="{'scroll-list-group': remunerations?.length > 10}">
                    <li class="list-group-item" *ngFor="let remuneration of remunerations">
                        <div class="d-flex text-primary justify-content-between">
                            <label class="h6 mt-1">{{remuneration?.nombre}}</label>
                            <div class="d-flex">
                                <strong
                                    class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                                <div class="flex-shrink-0 mx-2">
                                    <span class="switch switch-sm switch-primary">
                                        <label>
                                            <input [checked]="remuneration?.isSeleted"
                                                (click)="changeRemuneration(remuneration)"
                                                class="cursor-pointer abc-checkbox-primary" type="checkbox" />
                                            <span></span>
                                        </label>
                                    </span>
                                </div>
                                <strong
                                    class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
                            </div>
                        </div>
                    </li>



                </ul>
            </div>
        </div>

        <div class="row vacancy-options">
            <div class="col-xl-6 col-lg-12">
                <div class="card card-custom border-primary gutter-b">
                    <div class="card-header mt-3">
                        <div class="card-title align-items-center">
                            <i class="flaticon-gestion-de-proyectos icon-3x text-primary mr-3"></i>
                            <h3 class="text-primary m-0">Experiencias <span class="text-danger">*</span></h3>
                        </div>
                        <div class="card-toolbar ml-auto">
                            <a data-toggle="modal" data-target="#experience"
                                class="btn btn-pill font-weight-bolder btn-shadow btn-success">
                                <i class="flaticon2-plus"></i> Agregar
                            </a>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="table-responsive">
                            <table class="table mt-2 table-vertical-center text-center">
                                <thead>
                                    <tr class="bg-primary">
                                        <th class="text-left">Experiencias</th>
                                        <th class="text-center">Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of recordExperiences; index as i">
                                        <td class="text-left">{{item.description}}</td>
                                        <td class="text-center">
                                            <a (click)="deleteExperiences(i)"
                                                class="btn btn-sm btn-light-danger btn-icon mr-2" data-toggle="tooltip"
                                                data-original-title="Remover">
                                                <i class="flaticon-delete icon-2x"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="ml-2">
                                <el-caption #paginator [originalRecordsLength]="experiences?.length"
                                    [listedRecordsLength]="recordExperiences?.length"
                                    [registerQuantity]="experiences?.length" [records]="experiences"
                                    (onChage)="getPaginatedRecordsExperiences($event)"></el-caption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-12">
                <div class="card card-custom border-primary gutter-b">
                    <div class="card-header mt-3">
                        <div class="card-title align-items-center">
                            <i class="flaticon-mortarboard icon-3x text-primary mr-3"></i>
                            <h3 class="text-primary m-0">Conocimientos <span class="text-danger">*</span></h3>
                        </div>
                        <div class="card-toolbar ml-auto">
                            <a data-toggle="modal" data-target="#conocimiento"
                                class="btn btn-pill font-weight-bolder btn-shadow btn-success">
                                <i class="flaticon2-plus"></i> Agregar
                            </a>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="table-responsive">
                            <table class="table mt-2 table-vertical-center text-center">
                                <thead>
                                    <tr class="bg-primary">
                                        <th class="text-left">Conocimientos</th>
                                        <th class="text-center">Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of recordsKnowleadges; index as i">
                                        <td class="text-left">{{item.description}}</td>
                                        <td class="text-center">
                                            <a (click)="deleteKnowleadges(i)"
                                                class="btn btn-sm btn-light-danger btn-icon mr-2" data-toggle="tooltip"
                                                data-original-title="Remover">
                                                <i class="flaticon-delete  icon-2x"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="ml-2">
                                <el-caption #paginator [originalRecordsLength]="knowleadges?.length"
                                    [listedRecordsLength]="recordsKnowleadges?.length"
                                    [registerQuantity]="knowleadges?.length" [records]="knowleadges"
                                    (onChage)="getPaginatedRecordsKnowleadges($event)"></el-caption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button" (click)="send()">
        <i class="flaticon-enviar icon-2x" data-toggle="tooltip" title="Enviar a Concursa"></i>
        <span>Enviar a Concursa</span>
    </button>
</div>

<el-modal size="md" title="Agregar Experiencia" target="experience" (onClose)="resetFormExperiences()">
    <form [formGroup]="formExperiences">
        <div class="row gutter-b">
            <div class="form-group col">
                <label>Experiencia <span class="text-danger">*</span></label>
                <input formControlName="description" class="form-control form-control-lg" placeholder="" type="text">
                <span class="text-danger"
                    *ngIf="invalidFormExperiences">{{getErrorFormExperiences('description')}}</span>
            </div>
        </div>
        <div class="row gutter-b">
            <div class=" d-flex justify-content-end">
                <button #closeModalExperiences class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3"
                    type="button" data-dismiss="modal" (click)="resetFormExperiences()">
                    <i class="flaticon2-cross"></i>
                    <span>Cerrar</span>
                </button>
                <button (click)="addExperiences()" class="btn btn-pill font-weight-bolder btn-shadow btn-success"
                    type="button">
                    <i class="flaticon-disco-flexible"></i>
                    <span>Guardar</span>
                </button>
            </div>
        </div>
    </form>
</el-modal>

<el-modal title="Rango salarial" size="sm" target="salaryRange">

    <div>
        <h3 data-dismiss="modal" (click)="selectSalaryRange(requisition?.minSalary)"> <span role="button"
                class="badge  bg-light text-dark">Salario mínimo: {{requisition?.minSalary | currency}}</span></h3>
        <h3 data-dismiss="modal" (click)="selectSalaryRange(requisition?.halfSalary)"> <span role="button"
                class="badge  bg-light text-dark">Salario medio: {{requisition?.halfSalary | currency}}</span></h3>
        <h3 data-dismiss="modal" (click)="selectSalaryRange(requisition?.maxSalary)"> <span role="button"
                class="badge  bg-light text-dark">Salario máximo: {{requisition?.maxSalary | currency}}</span></h3>
    </div>

    <div class="my-7 separator separator-solid"></div>
    <div class="row">
        <div class="d-flex justify-content-end">
            <button id="btnClose" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2"
                data-dismiss="modal" type="button">
                <i class="flaticon2-cross"></i>
                <span>Cerrar</span>
            </button>
        </div>
    </div>
</el-modal>


<el-modal size="md" title="Agregar Conocimiento" target="conocimiento" (onClose)="resetFormknowleadge()">
    <form [formGroup]="formKnowleadges">
        <div class="row gutter-b">
            <div class="form-group col">
                <label>Conocimiento <span class="text-danger">*</span></label>
                <input formControlName="description" class="form-control form-control-lg" placeholder="" type="text">
                <span class="text-danger"
                    *ngIf="invalidFormKnowleadges">{{getErrorFormknowleadge('description')}}</span>
            </div>
        </div>
        <div class="row gutter-b">
            <div class=" d-flex justify-content-end">
                <button #closeModalKnowleadges class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3"
                    type="button" data-dismiss="modal" (click)="resetFormknowleadge()">
                    <i class="flaticon2-cross"></i>
                    <span>Cerrar</span>
                </button>
                <button (click)="addKnowleadges()" class="btn btn-pill font-weight-bolder btn-shadow btn-success"
                    type="button">
                    <i class="flaticon-disco-flexible"></i>
                    <span>Guardar</span>
                </button>
            </div>
        </div>
    </form>
</el-modal>