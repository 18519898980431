import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css']
})
export class PermissionsComponent implements OnInit {
  @Input() permissionId
  @Input() employee
  @Output() sendChangeDate = new EventEmitter();
  permission:any

  constructor(private personnelActionsService: PersonnelActionsService, private _toastService: ToastrService) { 

  }

  ngOnInit(): void {
    this. getPermission()
  }

  getPermission(){
    this.personnelActionsService.getEployeePermissionById(this.permissionId).subscribe((res: any) => {
      if (res.succeded) {
        this.permission = res.dataList[0];
        this.getChangeDate()

      } else {
        this._toastService.error(res.errors[0]);
      }
    })
  }

  convert(hora) {
    if(!hora) return '';
    return (new Date("1955-11-05T" + hora + "Z")).toLocaleTimeString("bestfit", {
        timeZone: "UTC",
        hour12: !0,
        hour: "numeric",
        minute: "numeric"
    });
  };

  
  getChangeDate(){
    this.sendChangeDate.emit(this.permission?.dateStart)
  }

}
