import { SweetAlert } from '../../../shared/utils/Sweet-Alert';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { approvedPayroll, Payroll } from '../open-payroll/models/mock-data';
import Swal from 'sweetalert2';
import { PayrollReport, Reports } from '../open-payroll/models/mock-data';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { MatDialog } from '@angular/material/dialog';
import { DashboardManagePayrollComponent } from '@payroll/dashboard-manage-payroll/dashboard-manage-payroll.component';
import { PayrollNewDetailComponent } from './payroll-new-detail/payroll-new-detail.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { ActionEditComponent } from '..';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { AdditionalPayrollService } from 'src/app/services/additional-payroll.service';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { ReportCode } from 'src/app/shared/utils/report-code.model';


declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'manage-payroll',
  templateUrl: './manage-payroll.component.html',
  styleUrls: ['./manage-payroll.component.css']
})


export class ManagePayrollComponent implements OnInit {
  enableBtn = false
  disableFilters = false
  //incompleto
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'MANAGE-PAYROLL',
    exportAction: 'EXPORTGN',
    searchAction: 'SEARCHGN',
    applyAction: 'APNOV',
    viewAction: 'VerNov'
  }

  sweetAlert = new SweetAlert()
  notDataFound = 'No se encontraron registros';
  @ViewChild('paginator') paginator: CaptionComponent;

  headers = [
    { label: 'Id de Reporte', key: 'id' },
    { label: 'Nombre de Reporte', key: 'name' },
  ]

  concepts: any = [];
  conceptsSelected: any[] = [];
  periodOptions = [];
  budgetAreaOptions = [];
  payrollTypeOptions = [];
  relatedPeriods = [];
  versions = [];
  selectedPeriod: any;
  selectedBudgetArea: any;
  selectedPayrollType: any;
  selectedPayrollPeriodRelated: any;
  selectedVersion: any;

  paginatedEmployees: any[] = [];
  selectedItems: any;
  employees: any[] = [];
  employeesListOriginal: any[] = [];
  filterText: string = '';
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string, default?:any }[] =
    [
      { propkey: "name", displayName: "Servidor Público" },
      { propkey: "category", displayName: "Categoría del Servidor Público" },
      { propkey: "positionName", displayName: "Cargo" },
      { propkey: "personalIdentification", displayName: "Documento de Identidad",  default: true },
      // { propkey: "payrollNewsId", displayName: "Secuencia" },
      // { propkey: "newsType", displayName: "Tipo" },
      // { propkey: "newsConcept", displayName: "Concepto" },
      // { propkey: "amount", displayName: "Monto" },


    ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' }
  ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'newsConceptId',
    textField: 'description',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };


  config = {
    displayKey: 'stringData', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };
  config2 = {
    displayKey: 'description', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };


  activeButtons = [
    {
      function: async (_) => {
        const url = '../../../../assets/pdf-Resources/Volante-de-pago.pdf'
        // let link = document.createElement('a');
        // link.href = url;

        // link.click();
        window.open(url, '_blank').focus();
      },
      icon: 'flaticon-pdf-file',
      color: 'text-danger',
      tooltip: 'Descargar PDF',
      color2: 'btn-hover-danger'
    },
    {
      function: async (_) => {
        const url = 'https://www.itla.edu.do/transparencia/archivos/nominas/nomina-administrativos-fijos-mayo-2018.xls'
        window.open(url, '_blank').focus();
      },
      icon: 'flaticon-excel-1',
      color: 'text-success',
      tooltip: 'Excel',
      color2: 'btn-hover-success'
    },
    {
      function: async (_) => {

      },
      icon: 'flaticon2-printer',
      color: 'text-dark',
      tooltip: 'Imprimir',
      color2: 'btn-hover-dark'
    },
  ];

  payrollRecords: Payroll[] = approvedPayroll
  records: any[] = [];
  recordsPaginated: any[] = [];

  public counts = {
    grossSalary: 0,
    isr: 0,
    afp: 0,
    sfs: 0,
    otherDiscounts: 0,
    totalDiscounts: 0,
    netIncome: 0
  }

  // yearsRange: DropdownModel[] = [];


  constructor(private _exlService: ExportToExcelService,
    private payrollService: PayrollService,
    private _toastr: ToastService,
    private _ParamService: ParameterControlService,
    private cdRef: ChangeDetectorRef, private dialog: MatDialog,
    public authInfo: AuthInfoService,
    private additionalPayroll: AdditionalPayrollService,) {

  }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.loadPeriods();
        this.loadPayrollTypes();
        this.loadBudgetAreas();
      }
    });
    applySort();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  dropdownConfig(displayKey: any) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  loadPeriods() {
    this.payrollService.getPeriods().subscribe((res: any) => {
      if (res.succeded) {

        this.periodOptions = res.dataList.filter(x => x.conditionId == 1 && x.status == true);

        this.periodOptions.forEach(element => {
          if (element.conditionId == 1 && element.status == true) {
            this.selectedPeriod = element;
          }
        });
      } else {
        this._toastr.error(res?.errors[0])
      }
    }, err => {
      this._toastr.error('', 'ERROR INESPERADO')
    })

    this.payrollService.getBeforePeriod().subscribe(res => {
      if (res.succeded) {
        this.relatedPeriods = res.dataList;
      } else {
        this._toastr.error(res.errors[0]);
      }
    });
  }

  loadPayrollTypes() {
    this._ParamService.getParameters(keyWord.Payroll).subscribe((res: any) => {
      if (res.succeded) {
        this.payrollTypeOptions = res.dataList;
      } else {
        this._toastr.error(res.error[0])
      }
    }, err => {
      this._toastr.error('', 'ERROR INESPERADO')
    })
  }

  changePayrollPeriodRelated() {
    this.getVersion();
  }

  getVersion() {
    this.selectedVersion = null
    this.versions = []
    const payrollPeriodId = this.selectedPeriod?.payrollPeriodId ?? 0;
    const budgetAreaId = this.selectedBudgetArea ? this.selectedBudgetArea.budgetAreaId : null;
    const payrollType = this.selectedPayrollType ? this.selectedPayrollType.ocode : null;
    const relatedPeriodId = this.selectedPayrollPeriodRelated?.payrollPeriodId ?? 0;
    const type = 10
    this.payrollService.getPayrollVersionsAdditional(payrollPeriodId, payrollType, budgetAreaId, relatedPeriodId, type).subscribe({
      next: (res: any) => {
        if (res?.succeded) {
          this.versions = res.dataList.map((version: any) => {
            return { ...version, versionText: `${version.versionId} - ${version.headerCondition}`}
          })
        } else {
          this._toastr.error(res.errors[0])
        }

      },
      error: (err: any) => {
        this._toastr.error(err)
      }
    })
  }


  changeVersion() {
    this.getConcepts()
  }

  getConcepts() {
    this.conceptsSelected = [];
    this.concepts = [];
    const payrollTypeId = this.selectedPayrollType?.ocode ?? 0;
    const payrollPeriodRelatedId = this.selectedPayrollPeriodRelated?.payrollPeriodId ?? 0;
    const budgetAreaId = this.selectedBudgetArea?.budgetAreaId ?? 0;
    const payrollPeriodId = this.selectedPeriod?.payrollPeriodId ?? 0;
    const versionId = this.selectedVersion?.versionId ?? 0;
    if (payrollTypeId !== 4) {
      this.selectedPayrollPeriodRelated = null;
      return;
    }
    if (payrollTypeId <= 0 || payrollPeriodRelatedId <= 0 || payrollPeriodId <= 0 || budgetAreaId <= 0) {
      return;
    }
    this.additionalPayroll.getConceptsBasedNewsAvailable(payrollTypeId, payrollPeriodRelatedId, budgetAreaId, payrollPeriodId, versionId).subscribe((res: any) => {
      if (res?.succeded) {
        this.concepts = [...res?.dataList];
      } else {
        this._toastr.error(res.errors[0])
      }
    }, err => {
      this._toastr.error('Ha ocurrido un error tratando de obtener los conceptos', '')
    });
  }

  loadBudgetAreas() {
    this.payrollService.getBudgetAreas().subscribe((res: any) => {
      if (res.succeded) {
        this.budgetAreaOptions = res.dataList.filter(ba => ba.status == true)
      } else {
        this._toastr.error(res.error[0])
      }
    }, err => {
      this._toastr.error('', 'ERROR INESPERADO')
    })
  }

  onPeriodChange() {
    this.records = [];
    this.getConcepts();
  }

  changeBudgetArea() {
    this.getConcepts();
  }

  loadDetails(showToastNoData = true) {
    let periodId = this.selectedPeriod ? this.selectedPeriod.payrollPeriodId : null;
    const budgetAreaId = this.selectedBudgetArea ? this.selectedBudgetArea.budgetAreaId : null;
    const payrollType = this.selectedPayrollType ? this.selectedPayrollType.ocode : null;
    const relatedPeriodId = this.selectedPayrollPeriodRelated?.payrollPeriodId ?? 0;
    const newsConcepts = this.conceptsSelected.map((concept: any) => concept?.newsConceptId).join("-");
    const headerId = this.selectedVersion?.headerId ?? 0;


    if (periodId == null || undefined) {
      this._toastr.warning('Debe seleccionar un período');
      return;
    }
    if (budgetAreaId == null || undefined) {
      this._toastr.warning('Debe seleccionar un área presupuestaria');
      return;
    }
    if (payrollType == null || undefined) {
      this._toastr.warning('Debe seleccionar un tipo de nómina');
      return;
    }

    if (payrollType === 4 && relatedPeriodId === 0) {
      this._toastr.warning('Debe seleccionar un período anterior');
      return;
    }

    this.enableBtn = true
    this.payrollService.getPayrollNewsToApply2(periodId, budgetAreaId, payrollType, relatedPeriodId, newsConcepts, headerId).subscribe((res: any) => {
      if (res.succeded) {
        this.records = res.dataList;

        if (this.records.length == 0 && showToastNoData === true) {
          this._toastr.warning('No existen registros para mostrar');
        }

      } else {
        this._toastr.error(res.errors[0]);
      }
    }, err => {
      this._toastr.error('', 'ERROR INESPERADO')
    }, () => {
      this.disableFilters = true
    });
  }

  getYears(startYear) {
    var currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  }

  clear() {
    this.selectedBudgetArea = null
    this.selectedPayrollType = null
    this.selectedPayrollPeriodRelated = null;
    this.enableBtn = false
    this.disableFilters = false
    this.filteredList.enable = false
    this.filteredList.records = []
    this.records = []
  }

  openModalAddNew(item: any, IsView = false, isEdit = false) {
    this.dialog.open(ActionEditComponent, {
      data: {
        item: item,
        IsView: IsView,
        isEdit: isEdit
      },
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.newItem) {
          this.loadDetails(true);
        }
      });
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.records = this.filterTable(this.records)
    }
    this.setFilterToLocalStorage();
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl.find(x => x.item_id == 1) && !sl.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl.find(x => x.item_id == 2) && !sl.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    return list;
  }

  setFilterToLocalStorage() {
    localStorage.setItem('nomina-list_filter', JSON.stringify(this.selectedItems));
  }

  exportToExcel() {

    if (this.records.length <= 0) {
      return;
    }

    let excelHeaders: string[][] = [[
      "Secuencia",
      "Nombre Servidor Público",
      "Apellido Servidor Público",
      "Posicion",
      "Tipo de Nomina",
      "Concepto",
      "Monto",
      "Fecha Inicio",
      "Fecha Final",
      "Condición de la Novedad",
      "Estatus"
    ],
    [
      "payrollNewsId",
      "firstName",
      "lastName",
      "positionName",
      "newsType",
      "newsConcept",
      "amount",
      "startDate",
      "endDate",
      "condition",
      "status"
    ]]
    this._exlService.exportToExcelSpecificColumns(this.filteredList.enable ? this.filteredList.records : this.records, excelHeaders, 'Lista de nominas generadas', true);
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
    //this.filteredList.records = this.filterTable(event.records)
  }

  getPaginatedRecords(event) {
    this.recordsPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  setNews() {
    const payrollPeriodRelatedId = this.selectedPayrollPeriodRelated?.payrollPeriodId ?? 0;
    const versionId = this.selectedVersion?.versionId ?? 0;
    let payrollTypeId = this.selectedPayrollType ? this.selectedPayrollType.ocode : null;
    let budgetAreaId = this.selectedBudgetArea ? this.selectedBudgetArea.budgetAreaId : null;
    if (payrollTypeId == null || undefined) {
      this._toastr.warning('Debe seleccionar un tipo de nómina');
      return;
    }

    if (!budgetAreaId == null || undefined) {
      this._toastr.warning('Debe seleccionar un area');
      return;
    }
    const newsConcepts = this.conceptsSelected.map((concept: any) => concept?.newsConceptId).join("-")
    this.payrollService.setNewsApplied(this.selectedPeriod.payrollPeriodId,
      this.selectedBudgetArea.budgetAreaId, this.selectedPayrollType.ocode, newsConcepts, payrollPeriodRelatedId, versionId).subscribe((res: any) => {
        if (res.succeded) {
          Swal.fire({
            title: 'Se ha generado la nómina correctamente',
            icon: 'success'
          }).then((result) => {
            this.clear()
          })
          this.loadDetails(false);
        } else {
          this._toastr.warning(res.errors[0])
        }
      }, error => {
        this._toastr.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
      })
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  openDashBoard() {
    this.dialog.open(DashboardManagePayrollComponent, {
      width: '100%'
    })
      .afterClosed()
      .subscribe((result) => {
      });
  }

  openPayrollNewDetailComponent(item: any) {
    this.dialog.open(PayrollNewDetailComponent, {
      data: {
        item: item,
      },
      width: '100%'
    })
      .afterClosed()
      .subscribe((result) => {
      });
  }

  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }


  exportToPdf() {
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.GeneratePayroll;
    const periodId = this.selectedPeriod ? this.selectedPeriod.payrollPeriodId : null;
    const budgetAreaId = this.selectedBudgetArea ? this.selectedBudgetArea.budgetAreaId : null;
    const payrollType = this.selectedPayrollType ? this.selectedPayrollType.ocode : null;
    const relatedPeriodId = this.selectedPayrollPeriodRelated?.payrollPeriodId ?? 0;
    const newsConcepts = this.conceptsSelected.map((concept: any) => concept?.newsConceptId).join("-") ?? 0
    const reportUrl = `${environment.reportUrl}/?Reportcode=${reportCode}&PayrollPeriodId=${periodId}&CompanyId=${companyId}&Status=1&BudgetAreaId=${budgetAreaId}&PayrollTypeId=${payrollType}&PayrollPeriodIdRelated=${relatedPeriodId}&Concepts=${newsConcepts}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Unidades Organizativas',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }


}
