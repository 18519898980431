<div class="container">
    <div class="col-md-12 gutter-t gutter-b">
        <div class="card card-custom border-primary p-0">
            <div class="card-header mt-3">
                <div class="card-title align-items-center">
                    <h3 class="text-primary m-0">Habilidades</h3>
                </div>
                <div class="card-toolbar">
                    <button class="btn btn-pill btn-shadow btn-success max-w-200px mr-3" type="button"
                        data-toggle="modal" data-target="#mskill">
                        <i class="flaticon2-plus"></i>
                        <span> Agregar Habilidad</span>
                    </button>
                </div>
            </div>
            <div class="card-body p-0 min-h-100px">
                <div class="table-responsive">
                    <table class="table mt-2 table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th>Tipo de Habilidad</th>
                                <th>Habilidad</th>
                                <th>Tipo de Nivel</th>
                                <th>Nivel</th>
                                <th class="w-200px">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let m of dataGv; let i = index">
                                <td>{{m.TypeSkillsDesc}}</td>
                                <td>{{m.SkillsDesc}}</td>
                                <td>{{m.TypeLevelSkillsDesc}}</td>
                                <td>{{m.LevelSkillsDesc}}</td>
                                <td>
                                    <button type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                        data-title="Remover" (click)="remove(i)">
                                        <i class="flaticon-delete" data-toggle="tooltip"
                                        data-title="Eliminar"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<el-modal size="xl" target="mskill" title="Agregar Habilidad">
    <div class="row">
        <h3 class="bg-primary col-lg-12 gutter-b text-white p-3">Habilidades</h3>
        <div class="form-group col-xl-3 col-lg-6">
            <label>Tipo de Habilidad <span class="text-danger text-monospace legend">*</span></label>
            <ngx-select-dropdown class=" flex-1" [options]="typeSkillsList" [config]="config"
                [(ngModel)]="typeSkillsData" (change)="changeTypeSkills($event)">
            </ngx-select-dropdown>
        </div>
        <div class="form-group col-xl-3 col-lg-6">
            <label>Habilidad <span class="text-danger text-monospace legend">*</span></label>
            <ngx-select-dropdown class=" flex-1" [options]="skillsList" [config]="config" [(ngModel)]="SkillsData"
                (change)="changeSkills($event)">
            </ngx-select-dropdown>
        </div>
        <div class="form-group col-xl-3 col-lg-6">
            <label>Tipo de Nivel <span class="text-danger text-monospace legend">*</span></label>
            <ngx-select-dropdown class=" flex-1" [options]="typeLevelSkillsList" [config]="config"
                [(ngModel)]="typeLevelSkillsData" (change)="changeTypeLevelSkill($event)">
            </ngx-select-dropdown>
        </div>
        <div class="form-group col-xl-3 col-lg-6">
            <label>Nivel <span class="text-danger text-monospace legend">*</span></label>
            <ngx-select-dropdown class=" flex-1" [options]="levelSkillsList" [config]="config"
                [(ngModel)]="levelSkillsData" (change)="changeLevelSkills($event)">
            </ngx-select-dropdown>
        </div>
    </div>

    <div class="form-group gutter-t col-md-12 d-flex justify-content-end">
        <button class="btn btn-pill btn-shadow btn-success max-w-200px mr-3" type="button" (click)="add()"
            [disabled]="isValid()">
            <i class="flaticon2-plus"></i>
            <span> Agregar Habilidad</span>
        </button>
        <button id="btnAddEmail" id="btnCloseSkill"
            class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2" data-dismiss="modal"
            type="button">
            <i class="flaticon2-cross"></i>
            <span>Cerrar</span>
        </button>
    </div>

</el-modal>