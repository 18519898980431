import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import Uppy from '@uppy/core'
import Mexico from '@uppy/locales/lib/es_MX'
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { environment } from 'src/environments/environment';
import { parameterDoc } from 'src/app/shared/utils/parameterDoc';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { personalRequiredFilesService } from 'src/app/services/personalRequiredFiles.service';
import { FileResponse, PersonalGuidEntity, personRequiredFiles } from 'src/app/shared/models/candidate-registration.model';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CandidateRegistrationComponent } from 'src/app/pages/candidate-registration/candidate-registration.component';
import { docService } from 'src/app/services/doc.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import Swal from 'sweetalert2';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-finger-print',
  templateUrl: './finger-print.component.html',
  styleUrls: ['./finger-print.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FingerPrintComponent implements OnInit, AfterViewInit {
  private readonly urlDoc = environment.api_doc + '/Document';
  public ModuleLoad: number = 1;
  public parameterDoc = parameterDoc.wizard;
  public data = {} as personRequiredFiles
  public foto: FormData | null = null;
  public guid = ""
  @Input() personId = 0;
 
  @ViewChild('uppyFileFinger')
  uppyFile: UppyFileComponent | null = null;
  constructor(
    private srvParameter: ParameterControlService,
    private srvPersonalReqFiles: personalRequiredFilesService,
    private srvDoc: docService,
    private route: ActivatedRoute,
    private toast: ToastService,
    private sanitizer: DomSanitizer,
    private personService: PersonService, private cdr: ChangeDetectorRef) {

  }

  changeDetector() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
   
  }

  ngAfterViewInit() {
    if (this.personId > 0) {
      this.personService.getPersonById(this.personId).subscribe(e => {
        if (e.dataList.length > 0) {
          this.guid = e.dataList[0].fingerprint;
          this.changeDetector();
        }
      });
    }
  }

  postFingerImage(personId, guid){
    return this.personService.postFingerImage(personId, guid).toPromise();
  }

  deleteFingerImage(){
    return this.personService.deleteFingerImage(this.personId).toPromise();
  }
 
  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  uploadFile(formData: FormData) {
    let data = {
      SystemLoad: this.uppyFile.SystemLoad,
      ModuleLoad: this.uppyFile.ModuleLoad,
      personalFile: this.uppyFile.typePersonalFile,
      routeFile: this.uppyFile.routeFile
    }
    return this.srvDoc.createFile(data, formData).toPromise();
  }

  existFileToDelete(){
    return this.uppyFile.fileToDelete?.length > 0;
  }

  async deleteFile(){
    await this.srvDoc.deleteFile(this.uppyFile.fileToDelete).toPromise();
    await this.deleteFingerImage();
  }

  setImage(event){
    this.foto = event;
  }

}
