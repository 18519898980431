<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span
            class="h-20px label label-danger label-dot mr-3 w-20px"></span>Planificación RRHH</h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>

<div class="mat-dialog-content-custom">
    <div class="">

            <div class=" mantEmpleado">
                <div class=" mx-9 mb-3 mt-3">
                    <!--         <div class="card-title">
                <span class="card-icon">
                      <i class="flaticon-nomina-de-sueldos icon-3x text-danger"></i>
                </span>
                 <h2 class="d-flex flex-column text-primary">
                    Planificación RRHH
                </h2> 
            </div> -->
                    <div [formGroup]="planningForm" class="row">
                        <div class="col-md-12 col-xl-2">
                            <div class="form-group px-0-mobile">
                                <label>Año en Curso <span class="text-danger text-monospace legend">*</span></label>
                                <input type="number" formControlName="currentYear"
                                    class="form-control form-control-lg" />
                            </div>
                        </div>
                        <div class="col-md-12 col-xl-2">
                            <div class="form-group px-0-mobile">
                                <label>Año a Planificar <span class="text-danger text-monospace legend">*</span></label>
                                <input type="number" formControlName="planningYear"
                                    class="form-control form-control-lg" />
                            </div>
                        </div>
                        <div class="col-md-12 col-xl-2">
                            <div class="form-group px-0-mobile">
                                <label>Presupuesto aprobado <span
                                        class="text-danger text-monospace legend">*</span></label>
                                <input placeholder="0.00" type="text" currencyMask [options]="{precision:2}"
                                    formControlName="approvedBudget" class="form-control form-control-lg" />
                            </div>
                        </div>
                        <div *ngIf="planningRecord?.conditionId == 2 || planningRecord?.conditionId == 4 && this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.sendAction)" class=" col-xl-auto mt-2 mt-sm-6 mb-3">
                            <button (click)="sendPlanning()"
                                class="btn btn-success btn-pill w-100 font-weight-bolder mt-2 mr-4 mr-0-mobile mb-2-mobile">
                                <i class="flaticon-enviar icon-2x"></i> Enviar Planificación
                            </button>
                        </div>
                        <div *ngIf="planningRecord?.conditionId == 4 && this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.rejectMotives)" class="col-xl-auto mt-sm-2 mt-md-6 mb-3">
                            <button  (click)="openMotives()" class="btn font-weight-bolder w-100 btn-shadow btn-primary mt-2 mr-4 mr-0-mobile mb-2-mobile" type="button">
                                <span>Ver Motivos de Devolución</span>
                            </button>
                        </div>
                        <div *ngIf="!planningRecord" class="col-xl-auto mt-sm-2 mt-md-6 mb-3">
                            <button 
                                class="btn btn-primary btn-pill w-100 font-weight-bolder mt-2 mr-4 mr-0-mobile mb-2-mobile"
                                *ngIf="this.data.viewMode == viewsEnum.Create && !isPlanningOpened && this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.open)"
                                [disabled]="planningForm.invalid" (click)="openPlanning()">
                                <i class="flaticon-folder icon-2x"></i> Aperturar Planificación
                            </button>
                        </div>
                    </div>
                </div>

                <div class="card-body pt-0">
                    <div class="row">
                        <div class="col-md-12">
                            <ul class="nav nav-tabs border-tabs-primary" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="sactual-tab" data-toggle="tab" href="#sactual">
                                        <span class="nav-icon mr-5">
                                            <i class="flaticon-recursos-humanos icon-2x"></i>
                                        </span>
                                        <span class="nav-text">
                                            <h4>Situación Actual</h4>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="ccargo-tab" data-toggle="tab" href="#planningTab"
                                        aria-controls="ccargo">
                                        <span class="nav-icon mr-5">
                                            <i class="flaticon-recursos-humanos-1 icon-2x"></i>
                                        </span>
                                        <span class="nav-text">
                                            <h4>Planificación</h4>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="rgeneral-tab" data-toggle="tab" href="#rgeneral"
                                        aria-controls="rgeneral">
                                        <span class="nav-icon mr-5">
                                            <i class="flaticon-recursos-humanos-5 icon-2x"></i>
                                        </span>
                                        <span class="nav-text">
                                            <h4>Resumen General</h4>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content px-0-mobile p-3 pt-5">
                                <div class="tab-pane p-3 fade active show" id="sactual" role="tabpanel"
                                    aria-labelledby="sactual-tab">
                                    <ng-template [ngIf]="dropdownsLoaded">
                                        <form [formGroup]="currentSituationFilter">
                                            <div class="row">
                                                <div class="form-group col-lg-3 px-0-mobile">
                                                    <label>Unidad Organizativa</label>
                                                    <ngx-select-dropdown class=" flex-1"
                                                        [options]="departmentsDropdown.dataList"
                                                        [config]="getDropdownConfig('departmentName')"
                                                        formControlName="departmentId">
                                                    </ngx-select-dropdown>
                                                </div>
                                                <div class="form-group col-lg-3 px-0-mobile">
                                                    <label>Cargo </label>
                                                    <ngx-select-dropdown class=" flex-1"
                                                        [options]="positionsDropdown.dataList"
                                                        [config]="getDropdownConfig('positionName')"
                                                        formControlName="positionId"></ngx-select-dropdown>
                                                </div>
                                                <div class="form-group col-lg-3 px-0-mobile">
                                                    <label>Grupo Ocupacional </label>
                                                    <ngx-select-dropdown class=" flex-1"
                                                        [options]="ocupationalGroupsDropdown.dataList"
                                                        [config]="getDropdownConfig('stringData')"
                                                        formControlName="ocupationalGroupId">
                                                    </ngx-select-dropdown>
                                                </div>

                                                <div class="form-group col-lg-6 pt-6 px-0-mobile">
                                                    <button
                                                        class="btn btn-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto mr-4"
                                                        (click)="getCurrentSituationByFilters()"
                                                        [disabled]="!isPlanningOpened">
                                                        <i class="flaticon-search icon-2x"></i> Buscar
                                                    </button>

                                                    <button [disabled]="currentSituationFilter.pristine"
                                                        (click)="resetFilter()"
                                                        class="btn btn-success btn-pill font-weight-bolder mt-2 mr-4 mr-0-mobile col-md-auto"
                                                        >
                                                        <i class="flaticon-delete-1 icon-2x"></i> Limpiar Filtros
                                                    </button>
                                                    <button *ngIf="this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.view)"
                                                        class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto"
                                                        (click)="openDetailsModal()"
                                                         [disabled]="!isPlanningOpened">
                                                        <i class="flaticon-ver icon-2x"></i> Ver Detalle
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                    </ng-template>
                                    <div class="row">
                                        <div class="table-responsive px-0-mobile">
                                            <table class="table table-hover table-vertical-center">
                                                <thead class="bg-primary">
                                                    <tr>
                                                        <th class="text-left">Unidad Organizativa</th>
                                                        <th class="text-left">Cargo</th>
                                                        <th class="text-left">Grupo Ocupacional</th>
                                                        <!-- <th class="text-left">Tipo de Servidor Público</th> -->
                                                        <th class="text-left">Cantidad</th>
                                                        <!-- <th class="text-center">Salario</th> -->
                                                        <th class="text-center">Total Salarios</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    <tr *ngFor="let item of currentSituationRecords">
                                                        <td class="text-left">{{item.departmentName || 'N/A'}}</td>
                                                        <td class="text-left">{{item.positionName || 'N/A'}}</td>
                                                        <td class="text-left">{{item.occupationalGroup || 'N/A'}}</td>
                                                        <!-- <td class="text-leftr">{{item.employeeType || 'N/A'}}</td> -->
                                                        <td class="text-left">{{item.count || '0'}}</td>
                                                        <!--  <td class="text-right">{{item.salary | currency: "RD$"}}</td> -->
                                                        <td class="text-center">{{item.totalSalary | currency: "RD$"}}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            <div>
                                                <el-caption *ngIf="currentSituationResponse"
                                                    [originalRecordsLength]="currentSituationResponse.dataList.length"
                                                    [listedRecordsLength]="currentSituationRecords.length"
                                                    [registerQuantity]="currentSituationResponse.dataList.length"
                                                    [records]="currentSituationResponse.dataList"
                                                    (onChage)="currentSituationRecords = getPaginatedRecords($event)">
                                                </el-caption>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane p-3 fade" id="planningTab" role="tabpanel"
                                    aria-labelledby="ccargo-tab">
                                    <div class="row">


                                        <div class="table-responsive">
                                            <table class="table table-hover table-vertical-center">
                                                <thead class="bg-primary">
                                                    <tr>
                                                        <th>Secuencia</th>
                                                        <th>Cargo</th>
                                                        <th>Unidad Organizativa</th>
                                                        <th>Código Cargo</th>
                                                        <th>Grupo Ocupacional</th>
                                                        <th>Plazas Aprobadas</th>
                                                        <th>Plazas Ocupadas</th>
                                                        <th>Plazas Disponibles</th>
                                                        <th>Plazas Solicitadas</th>
                                                        <th class="actionsBtn">Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of newPositionsRecords">
                                                        <td>{{item.planningCurrentPositionsId}}</td>
                                                        <td>{{item.positionName}}</td>
                                                        <td>{{item.departmentName}}</td>
                                                        <td>{{item.positionCode}}</td>
                                                        <td>{{item.occupationalGroup}}</td>
                                                        <td>{{item.approvalPositions || 0}}</td>
                                                        <td>{{item.occupiedPositions || 0}}</td>
                                                        <td>{{item.availablePositions || 0}}</td>
                                                        <td>{{item.requestedPositions || 0}}</td>
                                                        <td class="actionsBtn" >
                                                            <button (click)="openVacancyModal(viewsEnum.Edit,item)" *ngIf="(planningRecord?.conditionId == 4 || data.planningRecord?.conditionId == 1 || isPlanningOpened) && data.ViewMode != viewsEnum.View && this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.placerequest)"
                                                                class="btn btn-sm btn-light-skype btn-icon mr-2">
                                                                <i class="flaticon-subir-1 icon-2x"
                                                                    data-toggle="tooltip" title="Solicitar Plaza"></i>
                                                            </button>
                                                            <button (click)="openVacancyModal(viewsEnum.View,item)" *ngIf="data.planningRecord?.conditionId != 1 && !isPlanningOpened && planningRecord?.conditionId != 4 && this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.viewplace)"
                                                                class="btn btn-sm btn-light-skype btn-icon mr-2">
                                                                <i class="flaticon-subir-1 icon-2x"
                                                                    data-toggle="tooltip" title="Ver Plazas"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div>
                                                <el-caption *ngIf="newPositionsResponse"
                                                    [originalRecordsLength]="newPositionsResponse.dataList.length"
                                                    [listedRecordsLength]="newPositionsRecords.length"
                                                    [registerQuantity]="newPositionsResponse.dataList.length"
                                                    [records]="newPositionsResponse.dataList"
                                                    (onChage)="newPositionsRecords=getPaginatedRecords($event)">
                                                </el-caption>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane p-3 fade" id="rgeneral" role="tabpanel"
                                    aria-labelledby="rgeneral-tab">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-vertical-center">
                                            <thead class="bg-primary">
                                                <tr>
                                                    <th class="text-left">Unidad Organizativa</th>
                                                    <th class="text-left">Cargo</th>
                                                    <th class="text-left">Grupo Ocupacional</th>
                                                    <th class="text-left">Plazas Aprobadas</th>
                                                    <th class="text-left">Plazas Ocupadas</th>
                                                    <th class="text-left">Plazas Solicitadas</th>
                                                    <th class="text-left">Total de Salarios</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="overallSummary.length> 0">
                                                <tr *ngFor="let summary of overallSummary">
                                                    <td class="text-left fs-6">{{summary.departmentName || 'N/A'}}</td>
                                                    <td class="text-left fs-6">{{summary.positionName || 'N/A'}}</td>
                                                    <td class="text-left fs-6">{{summary.occupationalGroup || 'N/A'}}</td>
                                                    <td class="text-left fs-6">{{summary.approvalPositions || 0}}</td>
                                                    <td class="text-left fs-6">{{summary.occupiedPositions || 0}}</td>
                                                    <td class="text-left fs-6">{{summary.requestedPositions || 0}}</td>
                                                    <td class="text-left fs-6">{{summary.totalSalary | currency}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot class="bg-secondary text-white">
                                                <tr *ngIf="overallSummary">
                                                    <td class="text-left font-size-h4" colspan="3">Total General
                                                    </td>
                                                    <td class="text-left fs-6">{{approvedPositionsTotal}}</td>
                                                    <td class="text-left fs-6">{{occupiedPositionsTotal}}</td>
                                                    <td class="text-left fs-6">{{requestedPositionsTotal}}</td>
                                                    <td class="text-left fs-6">{{salaryTotal | currency}}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="this.dialogRef.close(false)">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="(data.planningRecord?.conditionId == 1 || isPlanningOpened) && data.viewMode != viewsEnum.View  && this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.finish)" (click)="finishPlanning()" 
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-correccion icon-2x"></i>
        <span>Concluir Planificación</span>
    </button>
</div>
