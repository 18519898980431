<form [formGroup]="mapValidation">
    <div class="card">
        <div class="card-header bg-primary py-3 d-flex justify-content-between align-items-center" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
            <div class="card-title m-0">
                <h2 class=" text-light">Validación de Perfil</h2>
            </div>
            <div class="card-toolbar">
                <button class="btn btn-clear btn-icon" (click)="close()">
                    <i class="flaticon2-cross text-light"></i>
                </button>
            </div>
        </div>
        <div class="card-body max-h-800 overS">
            <div class="row gutter-b">
                <div class="col d-flex justify-content-between">

                    <div class="col-lg-3 d-flex align-items-center justify-content-center">
                        <div class="symbol symbol-150 mr-3">
                            <div class="fotoPerfil" [ngClass]="{'d-none': path=='', 'd-block' : path !=''}">
                                <span *ngIf="loadingPic" class="d-flex flex-column align-items-center">
                                    <img src="../../../../assets/media/logos/xmera-logo-3.png" alt="loading"
                                        width="70px" />
                                    <strong class="text-danger font-size-h3">Cargando...</strong>
                                </span>
                                <div class="symbol symbol-150 d240 my-4 max-w-250px" *ngIf="!loadingPic && path != ''">
                                    <img alt="Pic" [src]="path" />
                                </div>
                            </div>

                            <div class="fotoPerfil" [ngClass]="{'d-block': path=='', 'd-none' : path !=''}">
                                <span class="d-flex flex-column align-items-center">
                                    <img alt="Pic" src="../../../../assets/images/no-user.png" width="100px" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 d-flex flex-column justify-content-center">
                        <div class="row">
                            <div *ngIf="mapPrincipalDataItem?.communicationId" class="col-lg-6 form-group">
                                <label>Número de Remisión</label>
                                <input class="form-control form-control-solid form-control-lg"
                                    [value]="((mapPrincipalDataItem)? mapPrincipalDataItem.communicationId: '')"
                                    readonly>

                            </div>
                            <div *ngIf="mapPrincipalDataItem?.date" class="col-lg-6 form-group">
                                <label>Fecha de Remisión</label>
                                <input class="form-control form-control-solid form-control-lg"
                                    [value]="((mapPrincipalDataItem)? mapPrincipalDataItem.date: '') | date:'dd-MM-yyyy'"
                                    readonly>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8 form-group">
                                <label>Nombre</label>
                                <input class="form-control form-control-solid form-control-lg"
                                    formControlName="name" readonly>
                            </div>
                            <div class="col-lg-4 form-group">
                                <label>Identificación</label>
                                <input *ngIf="!useMask" class="form-control form-control-solid form-control-lg"
                                    formControlName="id" 
                                    readonly>
                                <input *ngIf="useMask" class="form-control form-control-solid form-control-lg"
                                    formControlName="id" mask="000-0000000-0" 
                                    readonly>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="row gutter-b" >
                <div class="table-responsive w-100">
                    <table class="table atbl table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left">Datos del Cargo</th>
                                <th class="text-center w-175px">Acción</th>
                                <th class="text-center min-w-175px" *ngIf="data?.validationProfile?.condition === 5">Motivo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let item of jobDataTypes; let i = index;">
                                <tr>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <label
                                                    class="mr-3 w-300px fs-6 fw-bold float-left">{{item.stringData}}</label>
                                                <input type="text" class="form-control" *ngIf="item.ocode==6"
                                                [value]="item.value"
                                                readonly>
                                                <input type="text" class="form-control" *ngIf="item.ocode!==6" 
                                                    [value]="(item.value || item.value === 0)?
                                                    item.value: 'Este registro no existe o no se encuentra.'"
                                                    readonly>
                                            </div>
                                        </td>
                                        <td class="w-200px text-center">
                                            <ng-template [ngIf]="item.isPdf">
                                                <a id="pdf" (click)="openPdf(item.urlPdf)"
                                                    ngClass="pdf-{{i}}"
                                                    target="_blank" type="button"
                                                    class="btn btn-sm  btn-icon mr-2">
                                                    <!-- <i class="flaticon-pdf-file icon-2x" data-title="PDF"
                                                        data-toggle="tooltip"></i> -->
                                                        <svg data-title="Ver Documento"
                                                        data-toggle="tooltip" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file" width="38" height="38" viewBox="0 0 24 24" stroke-width="1.5" stroke="#003876" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                          </svg>
                                                </a>
                                            </ng-template>
                                        </td>
                                        <td class="text-center" *ngIf="data?.validationProfile?.condition === 5">
                                            <label
                                            class="mr-3 font-size-h6 w-300px" >
                                            <strong>
                                                {{item.reasonReject || 'N/A'}}
                                            </strong>
                                            
                                        </label>
                                            
                                        </td>
                                </tr>
                                </ng-container>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>

</form>