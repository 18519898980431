<div *ngIf="loading" class="d-flex justify-content-center flex-column align-items-center spinner-container">
    <el-spinner animation="spinner-logo"></el-spinner>
    <span class="d-block font-size-h5 font-weight-bold">{{message}}</span>
</div>

<div class="card card-custom" data-card="true" id="kt_card_3">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-agregar-archivo-1 icon-3x text-danger mr-2"></i>
                <h1 class="text-primary">Detalle de Nómina Aperturada</h1>
            </div>
        </div>
        <div class="card-toolbar">
            <button (click)="getRouterToBack()" class="btn btn-pill font-weight-bolder btn-shadow btn-warning">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
            </button>

        </div>
        <!-- <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Dashboard">
            <button data-toggle="modal"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto" data-toggle="modal"
                data-target="#dashboard">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </button>
        </div> -->
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-8 c-left">
            <div class="card-toolbar d-flex justify-content-start">

                <div class="col-md-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Período
                        </label>
                        <input readonly type="text" class="form-control form-control-solid form-control-lg" name=""
                            id="" value={{payrollSelected?.period}}>
                    </div>
                </div>

                <div class="col-md-3 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Área Presupuestaria
                        </label>
                        <input readonly type="text" class="form-control form-control-solid form-control-lg" name=""
                            id="" value={{payrollSelected?.budgetArea}}>
                    </div>
                </div>
                <div class="col-md-1 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Secuencia
                        </label>
                        <input readonly type="text" class="form-control form-control-solid form-control-lg" name=""
                            id="" value={{payrollSelected?.headerId}}>
                    </div>
                </div>
                <div class="col-md-3 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Tipo de Nómina
                        </label>
                        <input readonly type="text" class="form-control form-control-solid form-control-lg" name=""
                            id="" value={{payrollSelected?.payrollType}}>
                    </div>
                </div>
                <div class="col-md-3 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Estado
                        </label>
                        <input readonly type="text" class="form-control form-control-solid form-control-lg" name=""
                            id="" value={{payrollSelected?.headerCondition}}>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-4 c-right mr-0 mt-1">
            <div class="card-toolbar d-flex justify-content-end">
                <!-- <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click)="exportToExcel()">
                            <i class="flaticon-excel icon-2x text-primary mr-3"></i>
                            Excel
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                            PDF
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a>
                        <a class="dropdown-item" [routerLink]="['/summary']">
                            <i class="flaticon-pdf-file icon-2x text-primary mr-3"></i>
                            Plantilla
                        </a>
                    </div>
                </div> -->

                <div class="col text-nowrap">
                    <el-search-bar #searchBar [records]="payrollDetailsOriginal" (onKeyChange)="onKeyChange($event)"
                        [keyValue]="filterText" [itemForSearching]="searchBarDisplayNames"
                        (onChange)="getFilteredList($event)"></el-search-bar>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body" kt-hidden-height="282">
        <div class="table-responsive">
            <table class="table table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-left">Código del Servidor Público</th>
                        <th class="text-left">Servidor Público</th>
                        <th class="text-center min-w-160px">Documento de Identidad</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-center">Sexo</th>
                        <th class="text-left">Tipo de Servidor Público</th>
                        <th class="text-right">Ingreso Bruto</th>
                        <th class="text-right">Otros Ingresos</th>
                        <th class="text-right">Total Ingreso</th>
                        <th class="text-right">AFP</th>
                        <th class="text-right">SFS</th>
                        <th class="text-right">ISR</th>
                        <th class="text-right">Otros Desc.</th>
                        <th class="text-right">Total Desc.</th>
                        <th class="text-right">Neto</th>
                        <th class="text-right"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of paginatedPayrollDetails">
                         <tr>
                             <th class="text-left bg-light-secondary" colspan="16" >
                                 <span class="text-dark text-uppercase ">
                                     {{item?.departmentName}}
                                 </span>
                             </th>
                         </tr>
                         <ng-container  *ngFor="let department of item?.departments">
                             <tr>
                                <td class="text-left">{{department.employNumber}}</td>
                                <td class="text-left">{{department.name}}</td>
                                <td class="text-center">
                                    <ng-container *ngIf="department.typeIdentificationId == 1; else noIdentificationId">
                                        {{department.personalIdentification | mask: '000-0000000-0'}}
                                    </ng-container>
                                    <ng-template #noIdentificationId>
                                        {{department.personalIdentification}}
                                    </ng-template>
                                </td>
                                <td class="text-left">{{department.departmentName}}</td>
                                <td class="text-center">{{department.sex}}</td>
                                <td class="text-left">{{department.employeeType}}</td>
                                <td class="text-right">{{department.grossSalary | currency:'RD$'}}</td>
                                <td class="text-right">{{department.otherCredits | currency:'RD$'}}</td>
                                <td class="text-right">{{department.totalSalary | currency:'RD$'}}</td>
                                <td class="text-right">{{department.afp | currency:'RD$'}}</td>
                                <td class="text-right">{{department.sfs | currency:'RD$'}}</td>
                                <td class="text-right">{{department.isr | currency:'RD$'}}</td>
                                <td class="text-right">{{department.otherDiscounts | currency:'RD$'}}</td>
                                <td class="text-right">{{department.totalDiscount | currency:'RD$'}}</td>
                                <td class="text-right">{{department.netIncome | currency:'RD$'}}</td>
                                <td>
                                    <button *ngIf="privilege.ViewNews.value" class="btn btn-icon btn-light-info btn-sm m-1" (click)="showNews(department)">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver Novedades"></i>
                                    </button>
                                    <button *ngIf="showExcludeBtn && privilege.Exclude.value" [disabled]="department?.conditionId === 3 || department?.conditionId === 5" (click)="removeEmployee(department)" type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                        data-title="Excluir" >
                                        <i class="flaticon-usuario-1" data-toggle="tooltip"
                                            data-title="Excluir"></i>
                                    </button>
                                </td>
                             </tr>
                         </ng-container>
                         <tr>
                             <td class="text-left fw-bolder">Sub totales:</td>
                             <td class="text-left"></td>
                             <td class="text-left"></td>
                             <td class="text-left"></td>
                             <td class="text-left"></td>
                             <td class="text-left"></td>
                             <td class="text-right fw-bold">{{item.grossSalary | currency: 'RD$'}}</td>
                             <td class="text-right fw-bold">{{item.otherCredits | currency: 'RD$'}}</td>
                             <td class="text-right fw-bold">{{item.totalSalary | currency: 'RD$'}}</td>
                             <td class="text-right fw-bold">{{item.afp | currency: 'RD$'}}</td>
                             <td class="text-right fw-bold">{{item.sfs | currency: 'RD$'}}</td>
                             <td class="text-right fw-bold">{{item.isr | currency: 'RD$'}}</td>
                             <td class="text-right fw-bold">{{item.otherDiscounts | currency: 'RD$'}}</td>
                             <td class="text-right fw-bold">{{item.totalDiscount | currency: 'RD$'}}</td>
                             <td class="text-right fw-bold">{{item.netIncome | currency: 'RD$'}}</td>
                             <td></td>
                         </tr>
                    </ng-container>
                 </tbody>
                 <tfoot>
                     <tr class="bg-light-secondary">
                       <td class="text-left fw-bolder"  colspan="2">TOTAL GENERAL:</td>
                       <td class="text-left"></td>
                       <td class="text-left"></td>
                       <td class="text-left"></td>
                       <td class="text-left"></td>
                       <td class="text-right fw-bolder">{{getTotalOverallSummarie('grossSalary') | currency: 'RD$'}}</td>
                       <td class="text-right fw-bolder">{{getTotalOverallSummarie('otherCredits') | currency: 'RD$'}}</td>
                       <td class="text-right fw-bolder">{{getTotalOverallSummarie('totalSalary') | currency: 'RD$'}}</td>
                       <td class="text-right fw-bolder">{{getTotalOverallSummarie('afp') | currency: 'RD$'}}</td>
                       <td class="text-right fw-bolder">{{getTotalOverallSummarie('sfs') | currency: 'RD$'}}</td>
                       <td class="text-right fw-bolder">{{getTotalOverallSummarie('isr') | currency: 'RD$'}}</td>
                       <td class="text-right fw-bolder">{{getTotalOverallSummarie('otherDiscounts') | currency: 'RD$'}}</td>
                       <td class="text-right fw-bolder">{{getTotalOverallSummarie('totalDiscount') | currency: 'RD$'}}</td>
                       <td class="text-right fw-bolder">{{getTotalOverallSummarie('netIncome') | currency: 'RD$'}}</td>
                       <td></td>
                     </tr>
                   </tfoot>
            </table>
            <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div class="col-sm-12 col-md-5 pl-0">
                    <div class="d-flex align-items-center mr-7">
                        <span class="svg-icon svg-icon-gray-500 pr-2">
                            <i class="flaticon-marca-de-verificacion"></i>
                        </span>
                        <div class="dataTables_info" id="kt_datatable_info" role="status" aria-live="polite">
                            Total de registros <span
                                class="font-weight-bolder text-primary ml-1">{{paginatedPayrollDetails.length}} </span>
                        </div>
                    </div>
                </div>

                <el-caption [originalRecordsLength]="payrollDetailsOriginal.length"
                    [listedRecordsLength]="paginatedPayrollDetails.length" #paginator
                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :payrollDetails.length"
                    [records]="filteredList.enable ? filteredList.records : payrollDetails"
                    (onChage)="getPaginatedRecords($event)"></el-caption>
            </div>
        </div>
    </div>
</div>