<h2 class="mat-dialog-title">Editar dependiente</h2>
<div class="mat-dialog-content">
    
    <div class="row">
        <div class="form-group col-lg-4">
            <label>Código</label>
            <input type="text" class="form-control form-control-lg"/>
        </div>
        <div class="form-group col-lg-4">
            <label>Cédula</label>
            <input type="text" class="form-control form-control-lg"/>
        </div>
        <div class="form-group col-lg-4">
            <label>No. NSS</label>
            <input type="text" class="form-control form-control-lg"/>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-lg-4">
            <label>Nombres <span class="text-danger">*</span></label>
            <input type="text" class="form-control form-control-lg" />
        </div>
        <div class="form-group col-lg-4">
            <label>Apellidos <span class="text-danger">*</span></label>
            <input type="text" class="form-control form-control-lg" />
        </div>
        <div class="form-group col-lg-4">
            <label>Fecha Nacimiento <span class="text-danger">*</span></label>
            <input type="date" class="form-control form-control-lg" />
        </div>
    </div>
    <div class="row">
        <div class="form-group col-lg-4">
            <label>Género <span class="text-danger">*</span></label>
            <ngx-select-dropdown class=" flex-1"></ngx-select-dropdown>
        </div>
        <div class="form-group col-lg-4">
            <label>Parentezco <span class="text-danger">*</span></label>
            <input type="text" class="form-control form-control-lg" />
        </div>
   </div>
   <div class="row border-top">
       <div class="d-flex justify-content-end align-items-center p-3">
            <button (click)="Cancel()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3" data-dismiss="modal">
                <i class="flaticon2-cross"></i>   
                Cerrar
            </button> 
            <button (click)="Accept()" class="btn btn-pill font-weight-bolder btn-shadow btn-success ">
                <i class="flaticon-disco-flexible"></i>
                <span>Guardar</span>
            </button>                   
        </div>
   </div>
</div>