<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
<h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>
    Detalles del Rechazo
</h2>
    <button (click)="dialogRef.close()" type="button" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>

<div class="mat-dialog-content">
    <div class="card rechazarn border-0 ">
        <div class="dropdown p-3 text-center mt-3">
            <table class="table atbl table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-left">Motivo de Rechazo</th>
                        <th class="text-left text-wrap">Comentario</th>
                        <th class="text-left">Fecha</th>
                        <th class="text-left">Usuario</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of payrollReject">
                        <td class="text-left" style=" min-width: 150px;">
                            {{item.reasonDescription}}
                        </td>
                        <td class="text-left">
                            {{item.comment}}
                        </td>
                        <td class="text-left">
                            {{item.createDate | date : 'dd/MM/yyy'}}
                        </td>
                        <td class="text-left">
                            {{item.createUserName}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="dialogRef.close()"
        class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>