import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ContactMeansDepartment } from "../pages/telephone-directory/department-tab/models/contactMeans-department.model";
import { ResponseModel } from "../shared/models/strongly-typed-response.model";
import { AuthInfoService } from "./auth-info.service";


@Injectable({
    providedIn: 'root'
})
export class ContactMeansDepartmentService {
    private readonly api = environment.api_url
    
    constructor(private http: HttpClient, private _authInfo: AuthInfoService){

    }

    getContactMeansDepartment(companyId: number){
        return this.http.get<ResponseModel<ContactMeansDepartment>>(`${this.api}/ContactMeansDepartment?ContactMeansDepartmentId=0&DepartamentId=0&CompanyId=${companyId}&Status=true&Type=0`)
    }

    getContactMeansDepartmentBy(departamentId: number, companyId: number){
        return this.http.get<ResponseModel<ContactMeansDepartment>>(`${this.api}/ContactMeansDepartment?ContactMeansDepartmentId=0&DepartamentId=${departamentId}&CompanyId=${companyId}&Status=true&Type=2`)
    }

    post(contactMeansDepartment: ContactMeansDepartment){
        contactMeansDepartment.contactMeansDepartmentId
        return this.http.post<ResponseModel<any>>(`${this.api}/ContactMeansDepartment`, contactMeansDepartment);
    }

    put(contactMeansDepartment: ContactMeansDepartment){
        contactMeansDepartment.contactMeansDepartmentId
        return this.http.put<ResponseModel<any>>(`${this.api}/ContactMeansDepartment`, contactMeansDepartment);
    }

    getPhoneExtensions(contactMeansDepartmentId: number)
    {
        return this.http.get<ResponseModel<any>>(`${this.api}/PhoneExtensions?PhoneExtensionId=0&ContactMeansDepartmentId=${contactMeansDepartmentId}&CompanyId=${this._authInfo.getCompanyId()}&Status=true&Type=2`);
    }

    deletePhoneExtensions(phoneExtensionId: number){
        return this.http.delete<ResponseModel<any>>(`${this.api}/PhoneExtensions?PhoneExtensionId=${phoneExtensionId}&UserId=${this._authInfo?.getUserId()}`)
    }

}