<div class="container-fluid py-3 d-print-none">
    <button (click)="locationsBack()" class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
        <i class="flaticon2-left-arrow-1"></i>
        <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
    </button>
</div>

<div class="container-fluid">
    <div id="pdfExp" class="card">

        <div *ngFor="let payrollVoucher of payrollVoucheresData; let voucherIndex = index">

            <div class="card-body page-employee"  >
    
                <div class="page-v-auto mx-auto">
                    <header style="padding-bottom: 10px; padding-top: 20px;">
                        <div class="report-content">
                            <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div style="position: relative; text-align: center; width: 100%;">
                                        <img src="/assets/images/logo-presidencia.png" alt="logo" style="width: 100%;" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <h2 class="text-center mt-0 mb-0 title">
                                        Gobierno de la República Dominicana
                                    </h2>
                                    <h3 class="text-center mt-0 mb-2 title2">
                                        Sistema Integrado de Gestión Institucional (SIGEI)
                                    </h3>
                                    <h3 class="text-center mt-0 mb-2 title">
                                        Comprobante de Pago {{selectedPeriod?.description}}
                                    </h3>
                                    <h4 class="text-center mt-0 mb-2">
                                        Tipo de Nómina {{selectedPayrollType}}
                                    </h4>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div style="position: relative; text-align: center; width: 100%;">
                                        <img [src]="file.logo" alt="logo" style="width: 100%;" />
                                        <p class="text-small-report mt-0 mb-1">
                                            <!-- <strong>Pág.</strong>: 1<br /> -->
                                            <strong>Impreso</strong>: {{today | date: 'dd/MMM/yyyy h:mm a'}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
                <div class="page-v-auto mx-auto">
                    <div class="report-content">
                        <div class="card-report">
                            <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <img [src]="payrollVoucher?.photo ? payrollVoucher?.photo : 'assets/images/no-user.png'" alt="foto" style="width: 90%; margin: 0 auto;"
                                        onerror="this.onerror=null; this.src='assets/images/no-user.png'" />
                                </div>
                                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <h2 class="text-left mt-0 mb-0 title">
                                                {{payrollVoucher?.employee?.firstName}}
                                                {{payrollVoucher?.employee?.lastName}}
                                            </h2>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="mb-3">
                                                <strong>Área Presupuestaria:</strong><br />
                                                {{selectedFlyeres[0]?.budgetArea}}
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                            <div class="mb-3">
                                                <strong>Documento de Identidad:</strong><br />
                                                {{payrollVoucher?.employee?.personalIdentification|mask:"000-0000000-0"}}
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                            <div class="mb-3">
                                                <strong>Fecha designación:</strong><br />
                                                {{payrollVoucher?.employee?.admissionDate | date: "dd/MM/yyyy"}}
                                            </div>
                                            <!--
                                                <div class="mb-3">
                                                    <strong>Servidor Público:</strong><br />
                                                    OMAR GUARIONEX MENDEZ LLUBERES
                                                </div>
                                                -->
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="mb-3">
                                                <strong>Unidad Organizativa:</strong><br />
                                                {{payrollVoucher?.employee?.departmentName}}
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                            <div class="mb-3">
                                                <strong>Cargo:</strong><br />
                                                {{payrollVoucher?.employee?.positionName}}
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="table-report-sigei" cellspacing="0" cellpadding="0" width="100%">
                            <thead>
                                <tr>
                                    <th>Concepto</th>
                                    <th class="text-right">Ingresos</th>
                                    <th class="text-right">Descuento</th>
                                    <th class="text-right">Sueldo Neto</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="payrollVoucher">
                                <tr *ngFor="let concept of payrollVoucher?.paymentDetails">
                                    <td class="">
                                        <strong>{{concept?.code}}</strong>
                                        {{concept?.description}}
                                    </td>
                                    <td class="text-right">{{(concept.isDebit == false ? concept.amount: '') |
                                        currency}}</td>
                                    <td class="text-right">{{(concept.isDebit == true ? concept.amount: '') |
                                        currency}}</td>
                                    <td class=""></td>
                                </tr>
                                <!-- <tr class="" *ngFor="let newApplied of payrollVoucher?.newsApplied">
                                    <td class="">
                                        <strong>{{newApplied?.code}}</strong>
                                        {{newApplied.newsConcept}}
                                    </td>
                                    <td class="text-right">
                                        {{ newApplied.isDebit == false ? (newApplied.amount | currency) : '' }}
                                    </td>
                                    <td class="text-right">{{newApplied.isDebit == true ? (newApplied.amount | currency) : ''}}</td>
                                    <td class=""></td>
                                </tr> -->
                                <tr>
                                    <td class="text-left">
                                        <strong>TOTAL POR SERVIDOR</strong>
                                    </td>
                                    <td class="text-right">
                                        <strong>
                                            {{payrollVoucher?.flyerData?.grossSalary
                                            +
                                            payrollVoucher?.flyerData?.otherCredits
                                            | currency}}</strong>
                                    </td>
                                    <td class="text-right">
                                        <strong>
                                            {{payrollVoucher?.flyerData?.totalDiscount
                                            | currency}}</strong>
                                    </td>
                                    <td class="text-right">
                                        <strong>{{payrollVoucher?.flyerData?.netIncome
                                            | currency}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
    
            </div>
    
            <hr class="w-100 mr-0 ml-0 d-print-none" *ngIf="payrollVoucheresData.length != (voucherIndex+1)">
        </div>


    </div>
</div>
<button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn d-print-none" >
    <i class="flaticon2-printer font-size-h1"></i>
</button>
<script>
    let print = () => {
        window.focus()
        window.print()
    };
</script>