import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollConceptService } from 'src/app/services/payroll-concept.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';

@Component({
  selector: 'app-advanced-filter-by-payroll-concept',
  templateUrl: './advanced-filter-by-payroll-concept.component.html',
  styles: [
  ]
})
export class AdvancedFilterByPayrollConceptComponent implements OnInit {

  conceptTypes:any [] = []
  newsTypes:any [] = []
  beneficiaryList:any [] = []

  dropdownSettings = {
    singleSelection: true,
    idField: 'ocode',
    textField: 'stringData',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: true,
    limitSelection: -1
  };

  form = this.fb.group({
    code:[''],
    concept:[''],
    shortName:[''],
    conceptType:[''],
    newsType:[''],
    beneficiary:[''],
    isTss:[''],
    isIsr:[''],
    isSalary:[''],
    isDebit:[''],
    higherThanSalary:['']
  })

  constructor(public dialogRef : MatDialogRef<AdvancedFilterByPayrollConceptComponent>,
              private srvParameter: ParameterControlService, private toastr: ToastrService,
              private srvPayrollNew: PayrollNewService,
              private fb : FormBuilder,
              private srvConcept: PayrollConceptService,
              @Inject(MAT_DIALOG_DATA) public data: any) { 

                this.dialogRef.disableClose = true;
              }


 ngOnInit(): void {
    this.getConceptTypes()
    this.getNewsTypes()
    this.getBeneficiaries()
    if (this.data.activedfilters) {
      this.getCurrentSearch()
    }
  }

  getCurrentSearch() {
    this.form.setValue({
      ...this.data.activedfilters,
    })
  }

  getConceptTypes(){
    this.srvParameter.getParameters(keyWord.ConceptType).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0])
          return
        }
        this.conceptTypes = res.dataList
      }, error: (error: any) => { this.toastr.error('Ha ocurrido un error inesperado')  }
    })
  }

  getNewsTypes(){
    this.srvPayrollNew.getNewsType().subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0]); return
        }
        let newsTypes = res.dataList?.filter(d => d?.status);
        newsTypes.forEach(resp =>{
          resp.stringData = resp.description;
          resp.ocode = resp.newsTypeId;
        })
        this.newsTypes = newsTypes
      }, error: (error: any) => { this.toastr.error('Ha ocurrido un error inesperado') }
    })
  }

  getBeneficiaries(){
    this.srvConcept.getBeneficiaries().subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0])
          return
        }
        let beneficiaryList = res.dataList
        beneficiaryList.forEach(resp =>{
          resp.stringData = resp.description;
          resp.ocode = resp.conceptBeneficiaryId;
        })
        this.beneficiaryList = beneficiaryList


      }, error: (error:any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }


  Cancel(): void {
    this.dialogRef.close();
  }

  Accept() {
    let formcode = this.form.get('code').value;
    let formconcept = this.form.get('concept').value;
    let formshortName = this.form.get('shortName').value;
    let formconceptType = this.form.get('conceptType')?.value[0]?.ocode ?? '';
    let formnewsType = this.form.get('newsType')?.value[0]?.ocode ?? '';
    let formbeneficiary = this.form.get('beneficiary')?.value[0]?.ocode ?? '';
    let formisTss = this.form.get('isTss').value;
    let formisIsr = this.form.get('isIsr').value;
    let formisSalary = this.form.get('isSalary').value;
    let formisDebit = this.form.get('isDebit').value;
    let formhigherThanSalary = this.form.get('higherThanSalary').value;

    const AdvancedFilter = formcode + ',' + formconcept + ',' + formshortName + ',' + formconceptType +','+  formnewsType+ ','
                               + formbeneficiary + ',' + formisTss + ',' + formisIsr + ',' + formisSalary + ',' + formisDebit + ',' + formhigherThanSalary;

    this.dialogRef.close({tempForm: this.form.value, AdvancedFilter});

   }
}
