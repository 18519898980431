<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="fs-3 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px "></span> Recomendaciones
    </h2>
</div>

<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <form [formGroup]="recomendationsForm">
            <div class="form-group col-xl-12">
                <label class="">Nombres <span class="text-danger">*</span></label>
                <el-input id="personName" formControlName="name" placeholder="Ej: Carlitos Rijo"  (input)="removeWhitespaces($event, recomendationsForm.get('name'))"
                    onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32 || event.charCode == 209 || event.charCode == 241 )">
                </el-input>
                <errors-component [controlName]="recomendationsForm.controls['name']"></errors-component>
            </div>
            <div class="form-group col-xl-12">
                <label for="number">Número de teléfono <span class="text-danger">*</span></label>
                <input class="form-control form-control-solid form-control-lg" id="phoneNumber" mask="(000)-000-0000"
                    type="mask" placeholder="Ej: 809-000-0000" label="Teléfono" autocomplete="0"
                    formControlName="phoneNumber">
                <errors-component [controlName]="recomendationsForm.controls['phoneNumber']"></errors-component>
            </div>

            <div class="form-group col-xl-12">
                <label class="">Institución <span class="text-danger">*</span></label>
                <el-input id="institutionName" placeholder="Ej: ITLA" type="text" formControlName="institution" (input)="removeWhitespaces($event, recomendationsForm.get('institution'))">
                </el-input>
                <errors-component [controlName]="recomendationsForm.controls['institution']"></errors-component>
            </div>
        </form>
    </div>
</div>

<div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cancelar
    </button>
    <button [disabled]="recomendationsForm.invalid"
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button" (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Aceptar</span>
    </button>
</div>
