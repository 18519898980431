import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Beneficiary } from 'src/app/models/beneficiary';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { BeneficiariesService } from 'src/app/services/beneficiaries.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';

enum modes {
  createMode = 1,
  viewMode = 2,
  editMode = 3
}

@Component({
  selector: 'app-beneficiaries-modal',
  templateUrl: './beneficiaries-modal.component.html',
  styleUrls: ['./beneficiaries-modal.component.css']
})
export class BeneficiariesModalComponent implements OnInit, AfterViewChecked {
  public mask = "000-0000000-0"
  typeIdList: [] = []
  beneficiaryDTO = new Beneficiary
  selectedBeneficiary: any
  modalMode: any
  conceptBeneficiaryId: number = 0

  beneficiaryForm = new FormGroup({
    identificationType: new FormControl('', Validators.required),
    identification: new FormControl('', Validators.required),
    beneficiaryName: new FormControl('', Validators.required),
  })


  constructor(private _parameterService: ParameterControlService, private _toastService: ToastService,
    public dialogRef: MatDialogRef<BeneficiariesModalComponent>,
    private _changeDet: ChangeDetectorRef, private _beneficiariesService: BeneficiariesService,
    private _authService: AuthInfoService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogRef.disableClose = true;
    this.selectedBeneficiary = data.beneficiary ?? null
    if(data.beneficiary){
      this.conceptBeneficiaryId = data.beneficiary.conceptBeneficiaryId
    }
    this.modalMode = data.mode
  }


  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccionar',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  ngOnInit(): void {
    this.getData()
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  getData() {
    this.getParameters()
  }

  getParameters() {
    this._parameterService.getParameters(keyWord.IDType).subscribe((data: any) => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return
      }
      this.typeIdList = data.dataList
      if (this.modalMode != modes.createMode) {
        this.getSelectedBeneficiary()
      }
    })
  }

  getSelectedBeneficiary() {
    if (this.modalMode == modes.viewMode) {
      this.beneficiaryForm.disable()
    }

    this.beneficiaryForm.setValue({
      identificationType: this.typeIdList.find((type: any) => type.ocode == this.selectedBeneficiary.identificationType),
      identification: this.selectedBeneficiary.identification,
      beneficiaryName: this.selectedBeneficiary.description,
    })
    this.typeIdChange(this.selectedBeneficiary.identificationType)
  }

  typeIdChange(typeId) {
    if (typeId == 1) {
      this.mask = "000-0000000-0"
    } else {
      this.beneficiaryForm.get('identification').setValidators(Validators.required)
      this.mask = ''
    }
  }

  submitBeneficiary() {
    if (this.beneficiaryForm.invalid) {
      return
    }

    this.beneficiaryDTO = {
      ...this.beneficiaryDTO,
      description: this.beneficiaryForm.get('beneficiaryName').value,
      identificationType: this.beneficiaryForm.get('identificationType').value?.ocode,
      identification: this.beneficiaryForm.get('identification').value,
      companyId: this._authService.getCompanyId(),
      createUserId: this._authService.getUserId(),
      createDate: new Date(),
      status: true,
      conceptBeneficiaryId: this.conceptBeneficiaryId
    }

    //Create
    if (this.conceptBeneficiaryId == 0) {
      this._beneficiariesService.createBeneficiary(this.beneficiaryDTO).subscribe({
        next: (res: any) => this._toastService.success('Registro agregado satisfactoriamente'),
        error: err => this._toastService.error('Ocurrió un error al agregar el beneficiario')
      })
    }
    //Update
    else if (this.conceptBeneficiaryId > 0) {
      this._beneficiariesService.updateBeneficiary(this.beneficiaryDTO).subscribe({
        next: (res: any) => this._toastService.success('Registro actualizado satisfactoriamente'),
        error: err => this._toastService.error('Ocurrió un error al actualizar el registro')
      })

    }


    const row = { created: true }
    this.dialogRef.close(row);
  }

  close() {
    const row = { created: false }
    this.dialogRef.close(row);
  }


}
