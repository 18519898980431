import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { VacanciesService } from 'src/app/services/vacancies.service';
import { FilterEvent } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { ApplyVacanciesDashboardComponent } from '../apply-vacancies-dashboard/apply-vacancies-dashboard.component';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';

declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'app-apply-vacancies-list',
  templateUrl: './apply-vacancies-list.component.html',
  styleUrls: ['./apply-vacancies-list.component.css']
})
export class ApplyVacanciesListComponent implements OnInit, AfterViewChecked {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'APPLYVACANT',
    applyAction: {key: 'APPLY', value: false},
    viewAction: {key: 'VIEW', value: false},
    exportAction: {key: 'EXPORT', value: false},
    dashboardAction: {key: 'DASHB', value: false}
  }
  searchparameterlocalstorage: string = 'apply-vacancies-list_filter_search';
  canUseOption = false
  applicantsPagedResult = new PagedResult<any>();
  applicantsList: any[] = [];
  applicantsListOriginal: any[] = [];
  applicantsListRecords: any[] = [];
  applicants: any[] = [];
  dataList: any[] = [];
  companies: [] = [];
  selectedItems: any[] = [];
  filterText: any = '';
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchList: any[] = []; searchBarDisplayNames: { propkeyId?: number, propkey?: string, displayName: string, default?: boolean }[] =
    [
      { propkeyId: 1, propkey: "personId", displayName: "Secuencia" },
      { propkeyId: 7, propkey: "type", displayName: "Tipo de Identificación" },
      { propkeyId: 2, propkey: "personalIdentification", displayName: "Documento de Identidad",  default: true },
      { propkeyId: 3, propkey: "fullName", displayName: "Nombre" },
      { propkeyId: 4, propkey: "academicLevel", displayName: "Nivel Académico" },
      { propkeyId: 5, propkey: "gender", displayName: "Sexo" },
      { propkeyId: 6, propkey: "nationality", displayName: "Nacionalidad" },
    ];
  searchParameter: any
  getFilteredList(event) {
    this.filteredList = event;
  }

  constructor(
    private dialog: MatDialog,
    private vacanciesService: VacanciesService,
    private toastr: ToastrService,
    private _cdRef: ChangeDetectorRef,
    private _xlsxService: ExportToExcelService,
    public authInfo: AuthInfoService
  ) {

  }

  ngOnInit(): void {
    this.setFilterStorage();
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result) {
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.canUseOption = true
        // this.getApplicants()
        this.getPaginatedApplicants()
      }
    })
    applySort();
  }


  private setFilterStorage(){
    const localStorageSearch: FilterEvent = JSON.parse(localStorage.getItem(this.searchparameterlocalstorage));
    this.searchParameter = localStorageSearch;
  }


  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  filterSearch(event) {
    this.applicantsPagedResult.page = 1
    this.searchParameter = event;
    this.getPaginatedApplicants();
  }

  filterPersons() {
    this.applicants = this.applicantsListOriginal;
    if (!isNaN(Number(this.filterText)) && this.filterText.toString().length > 0) {
      this.applicants = this.applicants.filter(x => x.personalIdentification.indexOf(this.filterText) !== -1)
    } else if (this.filterText.toString().length > 0) {
      this.applicants = this.applicants.filter(x => x.fullName.toLowerCase().indexOf(this.filterText.toLowerCase()) !== -1)
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ApplyVacanciesDashboardComponent, {
      width: '100%',

      data: { persons: this.applicantsListOriginal },
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.animal = result;
    });
  }

  getApplicants() {
    this.vacanciesService.getApplicants().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastr.error("Error al obtener los datos")

        return;
      }
      this.applicants = res.dataList;
      this.applicantsListOriginal = res.dataList;
      this._cdRef.detectChanges();
    }, error => {

    })
  }

  getPaginatedApplicants() {
    if(!this.canUseOption){
      return
    }
    const page = this.applicantsPagedResult.page
    const pageSize = this.applicantsPagedResult.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    this.vacanciesService.getPaginatedApplicants(page, pageSize, propKeyId, text).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastr.error("Error al obtener los datos")

        return;
      }
      this.applicants = res.singleData.items;
      this.applicantsListOriginal = res.singleData.items;
      this.applicantsPagedResult = res.singleData
      this._cdRef.detectChanges();
    }, error => {

    })
  }

  exportToExcell() {
    let data = this.applicants;
    let excelHeaders: string[][] = [[
      "Secuencia de la Persona",
      "Secuencia del Tipo de Identificación",
      "Tipo",
      "Identificación de la Persona",
      "Nombre Completo",
      "Secuencia de Nivel Académico",
      "Nivel Académico",
      "Cumpleaños",
      "Género",
      "País",
      "Nacionalidad",
    ], [
      "personId",
      "typeIdentificationId",
      "type",
      "personalIdentification",
      "fullName",
      "academicLevelId",
      "academicLevel",
      "birthday",
      "gender",
      "country",
      "nationality"
    ]]
    this._xlsxService.exportToExcelSpecificColumns(data, excelHeaders, 'Lista de Aplicantes', true);
  }


  getPaginatedRecords(event) {
    if (this.applicantsPagedResult.page == event.selectedPage && this.applicantsPagedResult.pageSize == event.registersPerPage) {
      return;
    }
    this.applicantsPagedResult.page = event.selectedPage;
    this.applicantsPagedResult.pageSize = event.registersPerPage;
    this.getPaginatedApplicants()
  }

  exportToPdf() {
    const propKeyId: number = this.searchParameter?.propkeyId ?? 0;
    const searchParameter: string = this.searchParameter?.value ?? '';
    const page: number = this.applicantsPagedResult?.page;
    const pageSize: number = this.applicantsPagedResult?.pageSize;
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.ApplyVacancies;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&PropKeyId=${propKeyId}&SearchParameter=${searchParameter}&Page=${page}&PageSize=${pageSize}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Servidores Públicos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  exportCandidateToPdf(candidate) {
    const { companyId, personId, status } = candidate
    const reportCode: ReportCode = ReportCode.JobReport;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&PersonId=${personId}&Person=&Status=${status ? 1 : 2}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Unidades Organizativas',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }
}
