import { SweetAlert } from '../../shared/utils/Sweet-Alert';
import { HttpClientService } from '../../services/http-client/http-client.service';
import { HttpResponse } from '../../services/http-client/http-Response';
import { ToastService } from '../../shared/toast/toast.service';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location as LocationAngular } from '@angular/common';
import { PersonalRequestPages } from './enums/personal-request-pages';
import { LoadingService } from '../../shared/loading/loading.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PersonalInformationComponent } from './components/personal-information/personal-information.component';
import { ContactComponentComponent } from './components/contact-component/contact-component.component';
import { FamilyDataComponent } from './components/family-data/family-data.component';
import { AdditionalInfoComponent } from './components/additional-info/additional-info.component';
import { AcademicComponent } from './components/academic/academic.component';
import { HistoryReferencesComponent } from './components/history-references/history-references.component';
import { candidate, familyPersonInInstitutions, familyPersonInstitutionEntities, FileResponse, personAdditionalInformation, PersonalGuidEntity, personalInformation, personalReferences, personeEmail, personPhone, personRecomendation, personRequiredFiles, personResponse, personsAppliedStudies, personsDependents, personsJobReferences, personsSkills, personsWorkExperiences } from 'src/app/shared/models/candidate-registration.model';
import { CandidateRegService } from 'src/app/services/http-client/candidateRegService.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { SkillsComponent } from './components/skills/skills.component';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { personalRequiredFilesService } from 'src/app/services/personalRequiredFiles.service';
import { docService } from 'src/app/services/doc.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import Uppy from '@uppy/core';
import { parameterDoc } from 'src/app/shared/utils/parameterDoc';
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { RequiredFilesComponent } from 'src/app/pages/candidate-registration/components/required-files/required-files.component';
import { FingerPrintComponent } from './components/finger-print/finger-print.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { UpdateCode } from 'src/app/shared/models/updateCode.model';
import { promise } from 'protractor';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';
declare const KTWizard1: any;

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-candidate-registration',
  templateUrl: './candidate-registration.component.html',
  styleUrls: ['./candidate-registration.component.scss'],
})
export class CandidateRegistrationComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, AfterViewChecked {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'REGPER',
    changeCodeAction: 'EditEmployeeNumber'
  }

  employeeNumberCopy;

  public dataPerson = [];
  @ViewChild('mediaandcontact', { static: false })
  mediaAndContact: ContactComponentComponent;
  @ViewChild('familydata', { static: false }) familyData: FamilyDataComponent;
  @ViewChild('additionalinfo', { static: false })
  additionalInfo: AdditionalInfoComponent
  @ViewChild('academicFormation', { static: false }) academicFormation: AcademicComponent;
  @ViewChild('referencesHistorial', { static: false }) historyReferences: HistoryReferencesComponent;

  @ViewChild(PersonalInformationComponent) personalInfoData;
  @ViewChild(ContactComponentComponent) personContact;
  @ViewChild(FamilyDataComponent) personFamilyData;
  @ViewChild(AdditionalInfoComponent) personAdditionalInfo: AdditionalInfoComponent
  @ViewChild(AcademicComponent) personAcademic
  @ViewChild(HistoryReferencesComponent) personWorkExp
  @ViewChild(HistoryReferencesComponent) personJob
  @ViewChild(HistoryReferencesComponent) personRef
  @ViewChild(SkillsComponent) personSkills
  @ViewChild(HistoryReferencesComponent) personHistoryReferences
  @ViewChild("requiredFiles") requiredFiles
  @ViewChild(FingerPrintComponent) fingerPrint


  public type: any;
  public personData = {} as personalInformation;
  public relationship: familyPersonInstitutionEntities[] = [];
  public recomendations: personRecomendation[] = [];
  public idPerson = 0;
  public employeeId = 0;
  public validButton = { personalInfo: false, contact: false, additionalInfo: false, requiredFiles: false }

  selectedPage: number = 1;
  AlertSweet = new SweetAlert();
  personalRequestSubscription: Subscription
  isTableLoading: boolean = false;

  titles = {
    1: 'Datos Personales',
    2: 'Medios de Contacto',
    3: 'Datos Familiares',
    4: 'Información Adicional',
    5: 'Formación Académica',
    6: 'Habilidades',
    7: 'Historial y Referencias',
    8: 'Archivos Requeridos',
    9: 'Huellas Dactilares'
  };

  constructor(private _toastService: ToastService,
    private _router: Router, private _http: HttpClientService,
    private activeRoute: ActivatedRoute, private locations: LocationAngular,
    private loadingService: LoadingService, private toast: ToastService,
    private cdRef: ChangeDetectorRef,
    private srvPerson: PersonService,
    private route: ActivatedRoute,
    private authInfo: AuthInfoService) {
    this.idPerson = Number(this.route.snapshot.params["id"] || 0)
    this.type = Number(this.route.snapshot.params["type"] || 0)
    this.employeeId = Number(this.route.snapshot.params["employeeId"] || 0)
  }

  getRouterToBack() {
    return this.idPerson > 0 && this.type == 1 ? '/employees' : this.type == 2 ? '/hourly-teachers' : '/candidate-registration-list';

  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
    KTWizard1.init();

  }

  changeAdditionalInfo(value) {
    this.validButton.additionalInfo = value;
    this.isValidContinueButton();
  }

  changePersonalInfomation(value) {
    this.validButton.personalInfo = value;
    this.isValidContinueButton();
  }

  changeRequiredFilesValue(value) {
    this.validButton.requiredFiles = value;
    this.isValidContinueButton();
  }

  changeLoadData(data) {
    this.loadData(data);
  }

  postEmployeeBenefit() {
    const employeeBenefit: any[] = this.personalInfoData?.employeeBenefit;
    if (employeeBenefit?.length === 0) return;
    employeeBenefit?.forEach(benefit => {
      if (benefit?.ecbid <= 0) {
        this.srvPerson.postEmployeeBenefit(benefit).subscribe((res: any) => {
          this.personalInfoData?.getEmployeeBenefit();
        })
      }
    });
  }


  loadData(data, fromSave: boolean = false) {
    if (data?.familyPersonInInstitutions) {
      let dataInst = data?.familyPersonInInstitutions;
      if (this.personalInfoData)
        for (let i = 0; i < this.personalInfoData?.relationshipsAdded?.length; i++) {
          this.personalInfoData.relationshipsAdded[i].fpiiid = dataInst[i]?.fpiiid
        }
    }

    //agregamos la recomendaciones
    if (data?.personRecomendation) {
      let dataInst = data?.personRecomendation;
      if (this.personalInfoData)
        for (let i = 0; i < this.personalInfoData?.recomendations.length; i++) {
          this.personalInfoData.recomendations[i].id = dataInst[i]?.personRecomendationId
        }
    }

    //person phones
    var phones = (data?.personPhone || [])

    if (this.personContact) {
      this.personContact.phoneList = []
      this.personContact.phoneListCorreo = []
    }

    for (let i = 0; i < phones.length; i++) {
      const item = phones[i];

      let m = {
        id: item.personPhoneId,
        idPerson: this.idPerson,
        type: item.typeContactId,
        description: item.phoneNumber,
        principal: (item.primaryContact == true ? 1 : 2),
        status: true,
        companyId: item.companyId,
        phoneExtension: item.phoneExtension
      }

      this.personContact.phoneList.push(m);
    }


    //person email
    var emails = data?.personsEmails

    if (emails) {
      for (let i = 0; i < emails.length; i++) {
        const item = emails[i] as personeEmail

        let m = {
          id: item.personEmailId,
          idPerson: this.idPerson,
          type: item.typeContactId,
          description: item.email,
          principal: (item.primaryContact == true ? 1 : 2),
          status: true,
          companyId: item.companyId,
        }
        this.personContact.phoneListCorreo.push(m)
      }
    }

    //dependencia
    var dependents = data?.personsDependents

    for (let i = 0; i < dependents?.length; i++) {
      if (dependents[i]?.birthday) {
        let birthday = dependents[i]?.birthday
        let anio = {
          y: birthday.split('-')[0],
          m: birthday.split('-')[1],
          d: birthday.split('-')[2].substring(0, 2),
        }
        dependents[i].birthday = anio.y + '-' + anio.m + '-' + anio.d
      }
    }

    if (this.personFamilyData) {
      this.personFamilyData.familyData = dependents
      this.personFamilyData.changeDetector();
    }

    //addition info
    var addInfo = (data?.personAdditionalInformation?.length > 0 ? data?.personAdditionalInformation[0] : []);

    if (this.personAdditionalInfo) {
      this.personAdditionalInfo.data = addInfo;
      this.personAdditionalInfo.getRecruitmentQuestions(data?.recruitmentQuestionsAnswer);
      this.personAdditionalInfo.changeDetector();
    }

    //formacion academica
    var studies = data?.personsAppliedStudies

    if (this.personAcademic) {
      this.personAcademic.academicData = studies
      this.personAcademic.changeDetector();
    }

    //skills //si fue un guardado entonces ponemos el id en cada registro
    if (fromSave == true && data?.personsSkills) {
      var skill = data?.personsSkills;
      this.personSkills.data = skill


      for (let i = 0; i < skill?.length; i++) {
        const item = skill[i];
        this.personSkills.dataGv[i].idPersonSkills = item?.personSkillsId;
        this.personSkills.data[i].levelSkills = item?.levelSkillsId;
        this.personSkills.data[i].personId = this.idPerson;
        this.personSkills.dataGv[i].personId = this.idPerson;
        this.personSkills.changeDetector();
      }
      this.personSkills.changeDetector();
    }

    //personal referencia
    var historyReferences = data?.personsWorkExperiences;
    var personsJobReferences = data?.personsJobReferences;
    var personRef = data?.personalReferences;

    if (this.personHistoryReferences) {
      this.personHistoryReferences.personWorkExp = historyReferences
      this.personHistoryReferences.personRef = personRef
      this.personHistoryReferences.changeDetector();
    }


    this.personContact.changeDetector();
  }

  async nextPage() {
    
    try {
      // if (this.selectedPage == 1) {
      if (this.validButton.personalInfo == false) {

        document.getElementById('wdp').click()

        this.selectedPage = 1
        this.toast.warning("Debes llenar los campos obligatorios")
        return
      }

      const m = this.personalInfoData.personalInfo
      this.relationship = [];
      this.recomendations = [];

      if (this.type == 1 || this.type == 2) {
        let employeeNumberCopy = this.personalInfoData?.employeeNumberCopy;

        if ((employeeNumberCopy != this.personalInfoData?.employee?.employNumber) && !this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.changeCodeAction)) {
          this._toastService.error('Usted no tiene permisos para editar el Código del Servidor Público.')
          return;
        }
        if ((employeeNumberCopy != this.personalInfoData?.employee?.employNumber) && this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.changeCodeAction)) {
          const updateCode: UpdateCode = {
            newEmployeeNumber: this.personalInfoData?.employee?.employNumber,
            modifyUserId: this.authInfo?.getUserId(),
            companyId: this.personalInfoData?.employee?.companyId,
            employeeId: this.personalInfoData?.employee?.employeeId,
          }
          this.updateUserEmployeeNumber(updateCode);
        }
      }

      m.identificationImage = this.requiredFiles?.identificationImage
      m.fingerprint = this.fingerPrint?.identificationImage
      m.driveLicenseImage = this.requiredFiles?.driveLicenseImage
      m.miscellaneous = this.requiredFiles?.miscellaneous

      //busco los familiares que tengo trabajando en la institucion
      if (this.personalInfoData.relationshipsAdded) {
        for (let index = 0; index < this.personalInfoData.relationshipsAdded.length; index++) {
          const element = this.personalInfoData.relationshipsAdded[index];
          let mr = {
            fpiiid: element.fpiiid,
            personId: this.idPerson,
            fullName: element.personName,
            relationshipId: element.relationshipId,
            department: '',
            position: '',
            companyId: m.companyId > 0 ? m.companyId : this.authInfo.getCompanyId(),
            status: true
          } as familyPersonInstitutionEntities

          this.relationship.push(mr)
        }
      }

      //busco las recomendaciones
      if (this.personalInfoData.recomendations) {
        for (let index = 0; index < this.personalInfoData.recomendations.length; index++) {
          const element = this.personalInfoData.recomendations[index];
          let mr = {
            personRecomendationId: element.id,
            personId: this.idPerson,
            personName: element.name,
            phone: element.tel,
            institution: element.institutions,
            companyId: m.companyId > 0 ? m.companyId : this.authInfo.getCompanyId(),
            status: true
          }
          this.recomendations.push(mr)
        }
      }


      //hago el objeto para guardar
      let personalData = {
        personId: this.idPerson,
        firstName: m.firstName?.trim()?.replace(/\s+/g, ' '),
        lastName: m.lastName?.trim()?.replace(/\s+/g, ' '),
        academicLevelId: (m.academicLevelId || 0),
        birthday: (m.birthday || null),
        sexId: Number(m.sexId),
        nationalityId: m.nationality.idCountry,
        typeIdentificationId: m.typeIdentification.ocode,
        countryId: (m.country.idCountry || 0),
        provinceId: (m.province.stateId || 0),
        municipalityId: (m.municipality.cityId),
        sectorId: (m.sector.sectorId || 0),
        personalIdentification: (m.personalIdentification || ''),
        street: m.street,
        numberhouse: Number(m.numberhouse),
        buildingName: (m.buildingName || ''),
        apartmentNumber: (m.apartmentNumber || ''),
        personalReview: (m.personalReview || ''),
        achievements: (m.achievements || ''),
        strengths: (m.strengths || ''),
        pointsImprove: (m.pointsImprove || ''),
        recommendation: m.recommendation,
        namePersonRecommended: (m.namePersonRecommended || ''),
        phonePersonRecommended: (m.phonePersonRecommended || ''),
        institutionPersonRecommended: (m.institutionPersonRecommended || ''),
        maritalStatusId: Number(m.maritalStatusId || 0),
        disabilityTypeId: Number(m.disabilityTypeId || 0),
        bloodTypeId: Number(m.bloodTypeId || 0),
        spouseName: (m.spouseName || ''),
        spouseTypeIdentificationId: (m.spouseTypeIdentificationId || ''),
        spouseIdentification: (m.spouseIdentification || ''),
        spousePhone: (m.spousePhoto?.toString() || ''),
        spousePhoto: (m.spousePhoto || 0),
        userName: (m.userName || ''),
        pasword: (m.pasword || ''),
        arsId: (m.arsId || 0),
        afpId: (m.afpId || 0),
        companyId: m.companyId > 0 ? m.companyId : this.authInfo.getCompanyId(),
        status: true,
        FamilyPersonsInInstitutionEntities: (this.relationship || []),
        personRecomendationEntities: (this.recomendations || []),
        profileImage: (m.profileImage == "" ? null : m.profileImage),
        identificationImage: (m.identificationImage == "" ? null : m.identificationImage),
        driveLicenseImage: (m.driveLicenseImage == "" ? null : m.driveLicenseImage),
        miscellaneous: (m.miscellaneous == "" ? null : m.miscellaneous),
        fingerprint: (m.fingerprint == "" ? null : m.fingerprint),
        createUserId: this.authInfo.getUserId(),
        modifyUserId: this.authInfo.getUserId()
      } as personalInformation

      this.personFamilyData.familyData.forEach(element => {
        element.companyId = m.companyId > 0 ? m.companyId : this.authInfo.getCompanyId();
      });

      this.personAcademic.academicData.forEach(element => {
        element.companyId = m.companyId > 0 ? m.companyId : this.authInfo.getCompanyId();
      });

      await this.uploadFileProfileImage((guid) => {
        personalData.profileImage = guid;
      });

      await this.uploadFileAcademicData();

      this.personRef.personRef.forEach(element => {
        element.companyId = m.companyId > 0 ? m.companyId : this.authInfo.getCompanyId();
      });

      this.personSkills.data.forEach(element => {
        element.companyId = m.companyId > 0 ? m.companyId : this.authInfo.getCompanyId();
        element.personId = m.personId;
      });

      let personPhone: personPhone[] = this.getPhoneData().map(data => ({
        ...data,
        userId: this.authInfo.getUserId()
      }))
      let personsEmails: personeEmail[] = this.getEmailsData()
      let personsDependents: personsDependents[] = this.personFamilyData.familyData
      let personAdditionalInformation = this.getPeronAddInfo()
      let personsAppliedStudies: personsAppliedStudies[] = this.personAcademic.academicData
      let personsSkills: personsSkills[] = this.personSkills.data;
      let personsWorkExperiences: personsWorkExperiences[] = this.getExp()
      let personalReferences: personalReferences[] = this.personRef.personRef
      this.personHistoryReferences.changeDetector();
      let candidateData = {} as candidate

      candidateData.person = personalData;
      candidateData.personPhone = personPhone;
      candidateData.personsEmails = personsEmails;
      candidateData.personsDependents = personsDependents;
      candidateData.personAdditionalInformation = personAdditionalInformation;
      candidateData.personsAppliedStudies = personsAppliedStudies;
      candidateData.recruitmentQuestionsAnswer = this.personAdditionalInfo?.getRecruitmentQuestionsAnswer();
      candidateData.personsSkills = personsSkills.map(p => {
        return {
          ...p,
          personId: this.idPerson
        }
      });
      candidateData.personsWorkExperiences = personsWorkExperiences;
      candidateData.personalReferences = personalReferences

      for (let i = 0; i < personsWorkExperiences.length; i++) {
        const item = personsWorkExperiences[i];
        if (item.lastSalary == null) {
          this.toast.warning('El salario no debe estar vacío');
          return;
        }
      }
   
      this.srvPerson.postPersonWizard(candidateData).subscribe(async res => {

        if (res.error.length > 0) {
          this.toast.error(res.error, '');
          return
        }

        var data = res as personResponse;
        var sigleData = data.data.singleResultData as candidate;

        if (res.data.succeded == true) {
          this.idPerson = res.identity
          this.requiredFiles.personId = this.idPerson;
          this.fingerPrint.personId = this.idPerson;
        }

        await this.uploadRequiredFiles();

        this.loadData(sigleData, true);

        if (this.type == 1 || this.type == 2) {
          let email = this.personalInfoData?.employee?.institionalEmail;
          let supervisorId = this.personalInfoData?.employee?.employeeSupervisorId;
          const bankAccountCertification = (this.personalInfoData?.employee?.bankAccount !== this.personalInfoData?.bankAccountCopy
            || this.personalInfoData?.employee?.entityBankId !== this.personalInfoData?.entityBankIdCopy
            || this.personalInfoData?.employee?.accountTypeId !== this.personalInfoData?.accountTypeIdCopy)
            ? this.personalInfoData?.builkBankAccountCertification : null;
          var param = {
            personId: this.idPerson,
            supervisorId: supervisorId,
            employeeId: this.employeeId,
            email: email,
            modifyUserId: this.authInfo.getUserId(),
            bankAccount: this.personalInfoData?.employee?.bankAccount,
            entityBankId: this.personalInfoData?.employee?.entityBankId ?? 0,
            accountTypeId: this.personalInfoData?.employee?.accountTypeId ?? 0,
            employNumber: this.personalInfoData?.employee?.employNumber,
            budgetAreaId: Number(this.personalInfoData?.employee?.budgetAreaId ?? 0),
            branchId: Number(this.personalInfoData?.employee?.branchId ?? 0),
            bankAccountCertification: bankAccountCertification
          }
          //this.postEmployeeBenefit();
          this.srvPerson.UpdateDataEmployee(param).subscribe({
            next: (res: any) => {
              if (!res.succeded) {
                res.errors.forEach(element => {
                  this._toastService.error(element);
                });
                return;
              }
              this.toast.success("", 'Datos guardados exitosamente');
              this.personalInfoData.getEmployeeData(param.employeeId);
            },
            error: (err) => {
              this._toastService.error('Se ha producido un error al guardar los datos del Servidor Público.', '')
            }
          })
        } else {
          this.selectedPage++
          this.toast.success("", 'Datos guardados exitosamente');
        }
      }, error => {
        if (error.error.errors['Person.Street']) {
          this.toast.error('El campo de Calle es requerido', '')
        }

        else {
          this.toast.error('Ha ocurrido un error guardando los datos')
        }
      })
    } catch (error) {
      this.toast.error('Ha ocurrido un error guardando los datos')
    }
  }

  async uploadFileAcademicData() {
    const m = this.personalInfoData.personalInfo;
    const promises = this.personAcademic.academicData.map(async (element) => {
      element.companyId = m.companyId > 0 ? m.companyId : this.authInfo.getCompanyId();
      const academicComponent = (this.personAcademic as AcademicComponent);
      if (academicComponent.existFileToDelete()) {
        await academicComponent?.deleteFile();
      }
      if (element.file) {
        const res = await academicComponent?.uploadFile(element.file);
        const resData = res?.data as FileResponse;
        element.documentPhoto = resData.fileUnit.guidname;
      }
    });
    await Promise.all(promises);
  }

  async uploadFileProfileImage(setProfileImage: (profileImage: GUID) => void) {
    try {
      const personalInformation = (this.personalInfoData as PersonalInformationComponent);
      if (personalInformation.existFileToDelete()) {
        await personalInformation?.deleteFile();
      }
      if (personalInformation?.file?.formData) {
        const res = await personalInformation?.uploadFile(personalInformation?.file?.formData);
        const resData = res?.data as FileResponse;
        personalInformation.file.guid = resData?.fileUnit?.guidname;
        setProfileImage(resData.fileUnit.guidname as GUID);
        personalInformation.changeDetector();
      }
    } catch (error) {
      
    }
  }

  async uploadFileFingerPrint() {
    try {
      const fingerPrint = (this.fingerPrint as FingerPrintComponent);
      if (fingerPrint.existFileToDelete()) {
        await fingerPrint?.deleteFile();
      }
      if (fingerPrint?.foto) {
        const res = await fingerPrint?.uploadFile(fingerPrint?.foto);
        const resData = res?.data as FileResponse;
        await fingerPrint.postFingerImage(this.idPerson, resData?.fileUnit?.guidname);
        fingerPrint.guid = resData?.fileUnit?.guidname;
        fingerPrint.changeDetector();
      }
    } catch (error) {
      
    }
  }

  async uploadRequiredFiles() {
    const requiredFiles = this.requiredFiles as RequiredFilesComponent;
    const promises = [];
  
    if (requiredFiles.file.identificationImage) {
      const identificationResponse = await requiredFiles.uploadFile(requiredFiles.file.identificationImage);
      const resData = identificationResponse?.data as FileResponse;
      requiredFiles.personalInfo.identificationImage = resData?.fileUnit.guidname;
      const identificationPromise = requiredFiles.personService.postPersonIdentificationImage(this.idPerson, resData?.fileUnit.guidname).toPromise();
      promises.push(identificationPromise);
    }
  
    if (requiredFiles.file.driveLicenseImage) {
      const licenseResponse = await requiredFiles.uploadFile(requiredFiles.file.driveLicenseImage);
      const resData = licenseResponse?.data as FileResponse;
      requiredFiles.personalInfo.driveLicenseImage = resData?.fileUnit.guidname;
      const licensePromise = requiredFiles.personService.postDriveLicenseImage(this.idPerson, resData?.fileUnit.guidname).toPromise();
      promises.push(licensePromise);
    }
  
    if (requiredFiles.file.miscellaneous) {
      const miscellaneousResponse = await requiredFiles.uploadFile(requiredFiles.file.miscellaneous);
      const resData = miscellaneousResponse?.data as FileResponse;
      requiredFiles.personalInfo.miscellaneous = resData?.fileUnit.guidname;
      const miscellaneousPromise = requiredFiles.personService.postMiscellaneousImage(this.idPerson, resData?.fileUnit.guidname).toPromise();
      promises.push(miscellaneousPromise);
    }
  
    if (requiredFiles.file.photo) {
      const photoResponse = await requiredFiles.uploadFile(requiredFiles.file.photo);
      const resData = photoResponse?.data as FileResponse;
      requiredFiles.data.photo = resData?.fileUnit.guidname;
      promises.push(Promise.resolve()); 
    }
  
    if (requiredFiles.file.cv) {
      const cvResponse = await requiredFiles.uploadFile(requiredFiles.file.cv);
      const resData = cvResponse?.data as FileResponse;
      requiredFiles.data.cv = resData?.fileUnit.guidname;
      promises.push(Promise.resolve()); 
    }
  
    if (requiredFiles.file.criminalRecord) {
      const criminalRecordResponse = await requiredFiles.uploadFile(requiredFiles.file.criminalRecord);
      const resData = criminalRecordResponse?.data as FileResponse;
      requiredFiles.data.criminalRecord = resData?.fileUnit.guidname;
      promises.push(Promise.resolve()); 
    }
  
    if (requiredFiles.file.medicalCertificate) {
      const medicalCertificateResponse = await requiredFiles.uploadFile(requiredFiles.file.medicalCertificate);
      const resData = medicalCertificateResponse?.data as FileResponse;
      requiredFiles.data.medicalCertificate = resData?.fileUnit.guidname;
      promises.push(Promise.resolve()); 
    }
  
    try {
      if(promises?.length > 0){
        await Promise.all(promises);
        requiredFiles.save();
        requiredFiles.changeDetector();
        
      }
    } catch (error) {
      console.error("Error durante la carga de archivos o la asociación de imágenes:", error);
      throw error;
    }
  
    await this.deleteRequiredFiles();
  }
  

  async deleteRequiredFiles() {
    const requiredFiles = this.requiredFiles as RequiredFilesComponent;
    if (requiredFiles.existFileToDelete(requiredFiles.personalInfo.identificationImage)) {
      let file = requiredFiles.existFileToDelete(requiredFiles.personalInfo.identificationImage)
      requiredFiles.deleteFile(file)
      await requiredFiles.personService.deletePersonIdentificationImage(this.idPerson).toPromise()
    }

    if (requiredFiles.existFileToDelete(requiredFiles.personalInfo.driveLicenseImage)) {
      let file = requiredFiles.existFileToDelete(requiredFiles.personalInfo.driveLicenseImage)
      requiredFiles.deleteFile(file)
      await requiredFiles.personService.deleteDriveLicenseImage(this.idPerson).toPromise()
    }

    if (requiredFiles.existFileToDelete(requiredFiles.personalInfo.miscellaneous)) {
      let file = requiredFiles.existFileToDelete(requiredFiles.personalInfo.miscellaneous)
      requiredFiles.deleteFile(file)
      await requiredFiles.personService.deleteMiscellaneousImage(this.idPerson).toPromise()
    }

    if (requiredFiles.existFileToDelete(requiredFiles.data.photo)) {
      let file = requiredFiles.existFileToDelete(requiredFiles.data.photo)
      requiredFiles.delete(1, file.fileToDelete)
    }

    if (requiredFiles.existFileToDelete(requiredFiles.data.cv)) {
      let file = requiredFiles.existFileToDelete(requiredFiles.data.cv)
      requiredFiles.delete(2, file.fileToDelete)
      //await requiredFiles.personService.deleteMiscellaneousImage(this.idPerson).toPromise()
    }

    if (requiredFiles.existFileToDelete(requiredFiles.data.criminalRecord)) {
      let file = requiredFiles.existFileToDelete(requiredFiles.data.criminalRecord)
      requiredFiles.delete(3, file.fileToDelete)
      //await requiredFiles.personService.deleteMiscellaneousImage(this.idPerson).toPromise()
    }

    if (requiredFiles.existFileToDelete(requiredFiles.data.medicalCertificate)) {
      let file = requiredFiles.existFileToDelete(requiredFiles.data.medicalCertificate)
      requiredFiles.delete(4, file.fileToDelete)
      //await requiredFiles.personService.deleteMiscellaneousImage(this.idPerson).toPromise()
    }

  }



  updateUserEmployeeNumber(updateCode: UpdateCode) {
    this.srvPerson.updateEmployeeNumber(updateCode).subscribe(res => {
      if (res.errors.length > 0) {
        return;
      }
      this.personalInfoData.employeeNumberCopy = updateCode.newEmployeeNumber;
    });
  }

  getPhoneData() {
    let dataContact: personPhone[] = []

    for (let i = 0; i < this.personContact.phoneList.length; i++) {
      const item = this.personContact.phoneList[i];

      let m = {} as personPhone
      m.personPhoneId = Number(item.id || 0);
      m.personId = this.idPerson;
      m.typeContactId = item.type;
      m.phoneNumber = item.description;
      m.primaryContact = (item.principal == 1)
      m.status = true;
      m.companyId = this.personalInfoData.personalInfo.companyId > 0 ? this.personalInfoData.personalInfo.companyId : this.authInfo.getCompanyId();
      m.phoneExtension = item.phoneExtension
      dataContact.push(m);
    }

    return dataContact
  }

  getEmailsData() {
    let dataEmail: personeEmail[] = []

    for (let i = 0; i < this.personContact.phoneListCorreo.length; i++) {
      const item = this.personContact.phoneListCorreo[i];

      let m = {} as personeEmail
      m.personEmailId = Number(item.id || 0);
      m.personId = this.idPerson;
      m.typeContactId = item.type;
      m.email = item.description;
      m.primaryContact = (item.principal == 1)
      m.status = true;
      m.companyId = this.personalInfoData.personalInfo.companyId > 0 ? this.personalInfoData.personalInfo.companyId : this.authInfo.getCompanyId();

      dataEmail.push(m);
    }

    return dataEmail
  }

  getPeronAddInfo() {
    let data = this.personAdditionalInfo.data as personAdditionalInformation

    let m = {
      paiid: (data.paiid || 0),
      personId: (data.personId || 0),
      anotherJob: (data.anotherJob || false),
      anotherPositionId: (data.anotherPositionId || 0),
      descriptionOtherJob: (data.descriptionOtherJob || ''),
      jobEntryHour: (data.jobEntryHour || null),
      jobExitHour: (data.jobExitHour || null),
      saturdays: (data.saturdays || false),
      sundays: (data.sundays || false),
      holidays: (data.holidays || false),
      night: (data.night || false),
      workDaysId: (data.workDaysId || 0),
      institutionTransportation: (data.institutionTransportation || false),
      socialOrganization: (data.socialOrganization || false),
      descriptionSocialOrganization: (data.descriptionSocialOrganization || ''),
      disease: (data.disease || false),
      descriptionDisease: (data.descriptionDisease || ''),
      legalProcess: (data.legalProcess || false),
      descriptionLegalProcess: (data.descriptionLegalProcess || ''),
      positionToApply: (data.positionToApply || ''),
      salaryToAspire: (data.salaryToAspire ? data.salaryToAspire?.toString() : ''),
      anotherPositionToFill: (data.anotherPositionToFill || ''),
      enclosureId: (data.enclosureId || 0),
      bankAccountNumber: (data.bankAccountNumber || ''),
      companyId: this.personalInfoData.personalInfo.companyId > 0 ? this.personalInfoData.personalInfo.companyId : this.authInfo.getCompanyId(),
      status: (data.status || false),
    } as personAdditionalInformation
    this.personAdditionalInfo.changeDetector();
    return m
  }

  getExp() {
    for (let i = 0; i < this.personWorkExp.personWorkExp.length; i++) {
      this.personWorkExp.personWorkExp[i].companyId = this.personalInfoData.personalInfo.companyId > 0 ? this.personalInfoData.personalInfo.companyId : this.authInfo.getCompanyId();
      if (this.personWorkExp.personWorkExp[i].personsJobReferences == undefined) {
        this.personWorkExp.personWorkExp[i].personsJobReferences = []

      } else {
        this.personWorkExp.personWorkExp[i].personsJobReferences.forEach(element => {
          element.companyId = this.personalInfoData.personalInfo.companyId > 0 ? this.personalInfoData.personalInfo.companyId : this.authInfo.getCompanyId();
        });
      }
    }

    return this.personWorkExp.personWorkExp
  }

  previousPage() {
    this.stepChanged()
    this.selectedPage--;
  }

  stepChanged(): boolean {

    this.selectedPage++;
    return false;
  }

  isValidContinueButton() {
    if (this.validButton.personalInfo == false) { return true }
    if (this.validButton.additionalInfo == false) { return true }

    return false;
  }

  async finish() {
    await this.uploadFileFingerPrint();
    this._router.navigate([this.getRouterToBack().replace('/', '')]);
  }

}
