import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SearchEmployeeComponent } from '@payroll/action-edit/search-employee/search-employee.component';
import { RejectPayrollModalComponent } from '@payroll/audit-approval/reject-payroll-modal/reject-payroll-modal.component';
import { NewsType, PayrollNew } from '@payroll/news-list/models/PayrollNews.model';
import { headerCondition } from '@payroll/payroll-models';
import { ToastrService } from 'ngx-toastr';
import { IEmployee } from 'src/app/models/Employee.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { FinalPayrollService } from 'src/app/services/final-payroll.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { ToastService } from 'src/app/shared/toast/toast.service';
import Swal from 'sweetalert2';
import { ActionEditComponent} from '..';
import { BulkLoadFinalNomComponent } from './bulk-load-final-nom/bulk-load-final-nom.component';
import { FinalPayrollAddnewsModalComponent } from './final-payroll-addnews-modal/final-payroll-addnews-modal.component';
import { FinalPayrollSearchModalComponent } from './final-payroll-search-modal/final-payroll-search-modal.component';
import { EmployeeToPayroll, IEmployeeGET, IFinalPayrollPOST } from './models/models';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { NewsToApproveModalComponent, payrollNewsTypesToModal } from './news-to-approve-modal/news-to-approve-modal.component';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { ExcludeEmployeePayrollModalComponent } from '@payroll/modals/exclude-employee-payroll-modal/exclude-employee-payroll-modal.component';
import { PayrollConditions } from 'src/app/shared/enums/PayrollConditions';



type NewType = MatDialog;

@Component({
  selector: 'app-final-payroll',
  templateUrl: './final-payroll.component.html',
  styleUrls: ['./final-payroll.component.css']
})
export class FinalPayrollComponent implements OnInit, AfterViewChecked {
  headerCondition = ''
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'FINALNEW',
    addAction:{key: 'ADDFINALNEW', value: false },
    cleanAction:{key: 'CLEANFINALNEW', value: false },
    exportAction: {key: 'EXPORTFINALNEW', value: false },
    uploadAction: {key: 'UPLOADFINALNEW', value: false },
    viewAction: {key: 'VIEWNOM', value: false },
    approveAction: {key: 'APROBNOM', value: false },
    newsAction: {key: 'NOVNOM', value: false },
    Exclude: {key: 'Exclude', value: false },
  }
  showExcludeBtn = false
  filterText: string = '';
  periodDropdownIsDisable = false;
  areaDropdownIsDisable = true
  disableDropdowns = false;
  budgetAreaId = 0;
  payrollPeriodId = 0;
  filtervalues:any
  selectedItems: any;
  registersPerPage: any
  selectedPage: any
  employeeList: any[] = []
  periods: any[] = []
  budgetAreas: any[] = []
  dropdownPeriod: any;
  dropdownBudgetAreas: any;
  paginatedRecords: any[] = []
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: {propKeyId:number, propkey?: string, displayName: string }[] = [
    { propKeyId: 1, propkey: "employeeId", displayName: "Secuencia" },
    { propKeyId: 2, propkey: "name", displayName: "Servidor Público" },
    { propKeyId: 3, propkey: "departmentName", displayName: "Unidad organizativa" },
    { propKeyId: 4, propkey: "sex", displayName: "Sexo" },
    { propKeyId: 5, propkey: "totalCredits", displayName: "Ingreso Bruto" },
  ];



  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' },
    { item_id: 3, item_text: 'Aprobadas' },
    { item_id: 4, item_text: 'No Aprobadas' }
  ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  @ViewChild('paginator') paginator: CaptionComponent;
  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  payrollHeader: { headerId: number, conditionId: number } = {
    headerId: 0,
    conditionId: 0,
  };

  constructor(private employeeService: EmployeesService,
    private _toastService: ToastService,
    private _exlService: ExportToExcelService,
    private _changeDet: ChangeDetectorRef,
    private _parameterService: ParameterControlService,
    private _fb: FormBuilder,
    private _toastr: ToastrService, private srvDoc: docService,
    private dialog: MatDialog,
    private payrollNewService: PayrollNewService,
    private srvFinal: FinalPayrollService,
    public authInfo: AuthInfoService
  ) {
  }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result=>{
      if(result == true){
        this.getPeriods()
        this.getBudgetAreas()
      }
    })
    this.privilege = this.authInfo.setPrivileges(this.privilege)

  }

  removeEmployee(item){
    this.dialog.open(ExcludeEmployeePayrollModalComponent, {
      data: {item},
      width: 'auto'
    }).afterClosed().subscribe({
      next: (res:any) => {
        if(res.success){
          this.getOverallSummary()
        }
      }

    })
  }

  openModalBulkloadFinalNom() {
    if(!this.periodAndBudgetAreasHasValue()){
      this._toastService.warning("Debe llenar seleccionar un período y el área")
      return
    }
    this.dialog.open(BulkLoadFinalNomComponent, {
      data: { budgetArea: this.dropdownBudgetAreas,
        payrollPeriod: this.dropdownPeriod },
      width: '70%'
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.Applied) {
          this.getOverallSummary();
        }
      });
  }

  getPeriods(){
    this.srvFinal.getPeriods().subscribe((res:any) => {
      if(res.succeded){
        this.periods = res.dataList.filter(x => x.conditionId == 1 && x.status == true)
      }else{

      }
    }, err => {

    })
  }


  get isOpenToNews(){
    return this.payrollHeader?.conditionId ===  headerCondition?.openToNews;
  }

  get isContitionalRejectPayroll(){
    return (this.payrollHeader?.conditionId === headerCondition?.openToNews
      || this.payrollHeader?.conditionId === headerCondition?.audited
      || this.payrollHeader?.conditionId === headerCondition?.simulated
      || this.payrollHeader?.conditionId === headerCondition?.approved);
  }


  getHeader(headerID){
    this.srvFinal.getHeader(headerID).subscribe({
      next: (res:any) => {
        if(res.errors.length > 0) { this._toastService.error(res.errors[0]); return}
        if(res.dataList.length == 0){ return }
        let header = res.dataList[0].header
        this.headerCondition = header.headerCondition
        let excludeBtnCondition = [PayrollConditions.rejected, PayrollConditions.definitive]
        if(!excludeBtnCondition.some(x => x == header.conditionId)){
          this.showExcludeBtn = true
        }
      }, error: (error: any) => {
        this._toastService.error('Ha ocurrido un error inesperado')
      }
    })
  }
  openRejectModal() {
    this.dialog.open(RejectPayrollModalComponent, {
      width: '50%',
      data: { headerId: this.payrollHeader?.headerId}
    }).afterClosed().subscribe((result: any) => {
      if (result?.saved) {
        this._toastService.success("Nómina Rechazada");
        this.clear();
      }
    })
  }

  getBudgetAreas(){
    this.srvFinal.getBudgetAreas().subscribe((res:any) => {
      if(res.succeded){
        this.budgetAreas = res.dataList.filter(d => d.status);
      }else{

      }
    }, err => {

    })
  }
  changeDropdownPeriod(){
    this.areaDropdownIsDisable = this.dropdownPeriod?.payrollPeriodId < 1


  }

  clear(){
    this.employeeList = []
    this.dropdownPeriod = null
    this.dropdownBudgetAreas = null
    this.areaDropdownIsDisable = true
    this.periodDropdownIsDisable = false
    this.payrollHeader =  {
      headerId: 0,
      conditionId: 0
    };
  }

  newsToApprove(employeeId){
    const periodId = this.dropdownPeriod.payrollPeriodId
    const payrollNewsTypes: payrollNewsTypesToModal = {
      final: true
    }
    this.dialog.open(NewsToApproveModalComponent, {
      height: '800px',
      data: {
        employeeId,
        periodId,
        payrollNewsTypes,
        budgetArea: this.dropdownBudgetAreas,
        payrollType: 1,
        type: 10
      }
    })
  }
  changeDropdownArea(){
    this.getOverallSummary()
  }
  getOverallSummary(){
    this.headerCondition = ''
    if(this.dropdownPeriod?.payrollPeriodId > 0){
      this.srvFinal.getOverallSumary(this.dropdownPeriod.payrollPeriodId, this.dropdownBudgetAreas.budgetAreaId).subscribe((res:any) => {
        if(res.succeded){
          if(res.dataList.length > 0){
            const headerId = res.dataList[0].headerId
            this.getHeader(headerId)
          }
          this.areaDropdownIsDisable = true;
          this.periodDropdownIsDisable = true;
          this.employeeList = res.dataList;
          if(res?.dataList?.length > 0){
            this.payrollHeader = {
              headerId: res?.dataList[0]?.headerId,
              conditionId: res?.dataList[0]?.conditionId
            }
          }
        }else{
          this._toastService.error(res.errors[0])
        }
      }, err => {
        this._toastService.error('Ha ocurrido un error inesperado')
      })
    }
  }

  periodAndBudgetAreasHasValue(): boolean{
    if(this.dropdownBudgetAreas.length == 0) return false
    if(this.dropdownPeriod.length == 0) return false
    return true
  }

  openSearchEmployee() {
    if(!this.periodAndBudgetAreasHasValue()){
      this._toastService.warning("Debe llenar seleccionar un período y el área")
      return
    }
    this.dialog.open(FinalPayrollSearchModalComponent, {
      data: {
        budgetAreaId: this.dropdownBudgetAreas.budgetAreaId,
        payrollPeriodId: this.dropdownPeriod.payrollPeriodId
      },
      width: '80%'
    })
    .afterClosed()
    .subscribe((result) => {
      if (result?.success == false)  return
      const employee: IEmployeeGET = result.employee
      this.budgetAreaId = result.budgetAreaId
      this.payrollPeriodId = result.payrollPeriodId
      this.getOverallSummary()
    });
  }

  openAddNew(item: IEmployee, modalTitle, mode: 'show' | 'add'){
    const startDate = this.dropdownPeriod.startDate
    const endDate = this.dropdownPeriod.endDate
    const employeeId = item.employeeId
    this.dialog.open(FinalPayrollAddnewsModalComponent,{
      data: {
        startDate,
        endDate,
        modalTitle,
        mode,
        employeeId
      }
    })
  }

  viewNewsDetails(item){
    const periodId = item.payrollPeriodId
    this.dialog.open(NewsDetailsComponent, {
      data: {
        type: 7,
        item,
        periodId
      }
    })
  }
  sendData(){
    /* const finalPayroll: IFinalPayrollPOST = {
      budgetAreaId: this.budgetAreaId,
      companyId: 1,
      headerId: 0,
      employeeToPayroll: this.employeeList,
      status: true,
      createDate: new Date(),
      createUserId: 1,
      modifyDate: new Date(),
      modifyUserId: 1,
      payrollPeriodId: this.payrollPeriodId,
      payrollTypeId: 1
    }
    this.srvFinal.postFinalPayroll(finalPayroll).subscribe((res:any) => {
      if(res.succeded){
        this._toastService.success("Datos guardados")
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error("Ha ocurido un error inesperado")
    }) */
  }













  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  getPaginatedRecords(event) {
    this.paginatedRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.registersPerPage = event?.registersPerPage;
    this.selectedPage = event?.selectedPage;
  }
  exportToExcel() {
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Servidor Público",
      "Unidad Organizativa",
      "Genero",
      "Ingreso Bruto"
    ]]
    let customItems = [];
    let items = [];
    items = this.filteredList.enable ? this.filteredList.records : this.employeeList;

    if(items.length > 0){
      customItems = items.map(x=>{
        return{
          employeeId : x.employeeId,
          name : x.firstName + x.lastName,
          departmentName : x.departmentName,
          sex : x.sex,
          totalCredits : x.totalCredits,
        }
      })
    }

    this._exlService.exportToExcelSpecificColumns(customItems, excelHeaders, 'Lista de Servidores Públicos');
  }

  onKeyChange(text) {
    this.filterText = text;

  }

  onKeyParameterChange(event){
    this.filtervalues = event;
  }


  onBtnPrintClick(){
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

  exportToPdf() {
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.ListPayrollEnd;
    const periodId =  this.dropdownPeriod?.payrollPeriodId ?? 0
    const PropkeyId: any = this.filtervalues?.propKeyId ?? 0
    const SearchParameter: any = this.filterText ?? ''
    const budgetAreaId = this.dropdownBudgetAreas.budgetAreaId ?? 0
    const reportUrl = `${environment.reportUrl}/?Reportcode=${reportCode}&HeaderId=${this.payrollHeader?.headerId}&PeriodId=${periodId}&CompanyId=${companyId}&BugetAreaId=${budgetAreaId}&PropKeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Page=${this.selectedPage}&PageSize=${this.registersPerPage}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Nómina de Prestaciones Laborales',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }
}

