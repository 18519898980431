import { OperationResult } from './../../../shared/models/operation-result.enum';
import { AddModalComponent } from './add-modal/add-modal.component';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, OnDestroy, AfterViewInit, AfterContentInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { Subscription } from 'rxjs';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { PayrollPeriodService } from 'src/app/services/payroll-period.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { Period } from './models/period.model';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { PeriodParams } from './models/period-get-params.model';
import { PeriodTypeGet } from './models/period-type-get-param.enum';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { IPostHeaderApprove } from '@payroll/payroll-models';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import * as moment from 'moment';
declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})


export class ScheduleComponent implements OnInit, AfterViewChecked, OnDestroy {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'SCHEDULE',
    exportAction: 'EXPORTPERI',
    viewAction: 'VIEWPERI',
  }

  public photoUrl: string = ''
  public loadingPic: boolean = true

  isLoading: boolean = true;
  subscription: Subscription = new Subscription();
  tableFilter = '';
  employeeFilter: any = '';
  showActives: boolean = true;
  employeeId: number;
  filterText: string = '';
  selectedItems: any[] = [];
  selectedPage: any[] = [];
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      // { propkey: "employeeId", displayName: "Número de Secuencia" },
      { propkey: "description", displayName: "Descripcion" },
    ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' }
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  config = {
    searchOnKey: "description",
    search: true,
    height: 'auto', //height of the liilts to auto. With auto height scroll will never appear
    placeholder: 'Elige el año', //i    customComparator: ()=>{}, // a cus ndefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits e limited)
    moreText: 'más', // text to be di
    noResultsFound: 'Sin resultados!',
    searchPlaceholder: 'Buscar', // lab
  }

  //#region Period variables
  originalPeriods: Period[] = [];
  periods: Period[] = [];
  paginatedPeriods: Period[] = [];
  yearList: { description: string, value: number }[] = [];
  //#endregion
  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = this.filterTable(event.records)
  }

  currentYear: any;

  constructor(
    private _exlService: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private _toastr: ToastrService,
    private _dialog: MatDialog,
    private _payrollPeriodSvc: PayrollPeriodService, public authInfo: AuthInfoService) { }


  ngOnInit(): void {
    this.selectedItems.push(this.statusOptions[0])
    this.yearList = this.scheduleYearsDropdown();
    this.currentYear = this.yearList.find(x => x.value == moment().startOf('date').year())
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        if (localStorage.getItem("period-list_filter")) {
          this.selectedItems = JSON.parse(localStorage.getItem("period-list_filter"));
        } else {
          this.selectedItems = [this.statusOptions[0]];
        }
        
        this.getFilteredYear(this.currentYear);
      }
    });
    applySort();
  }

  getFilteredYear(event) {
    this.getPeriods({ value: event.value, type: PeriodTypeGet.ByYear });
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  //#region MultiSelect
  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();

  }
  onItemDeSelect(item: any) {
    this.filter();

  }
  onItemSelect(item: any) {
    this.filter();

  }

  get yearSelected():number{
    if(Array.isArray(this.currentYear)){
      return 0
    }
    if(this.currentYear != 0){
      if(!this.currentYear){
        return 0
      }
    }
    if(this.currentYear.value){
      return this.currentYear.value
    }
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()

  }
  //#endregion

  getPeriods(params: PeriodParams) {
    params.value = this.yearSelected
    this._payrollPeriodSvc.getAll(params).subscribe((response: ResponseModel<Period>) => {
      if (response.succeded) {
        this.periods = this.filterTable(response.dataList);
        this.originalPeriods = response.dataList;
      }
    });
  }

  closePeriod(period:Period) {
    if (period.conditionId == 0) return
    // let approve: any;
    let randomNumber = Math.floor(Math.random() * (50 - 1)) + 1

    Swal.fire({
      title: `Cerrar Periodo ${period.description}`,
      html: `Por favor introduzca el número <b class="text-danger">${randomNumber}</b> de su tarjeta de códigos.`,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Cancelar',
      preConfirm: (data) => {
        if (data == '' || data == undefined) {
          Swal.showValidationMessage(
            'Debe digitar el código requerido'
          )
        } else {
          // approve = {
          //   generatedToken: randomNumber.toString(),
          //   confirmationToken: data,
          //   companyId: 1,
          //   status: true,
          //   createUserId: 0,
          //   modifyUserId: 0,
          //   approvedDate: new Date(),
          //   createDate: new Date(),
          //   modifyDate: new Date(),
          // }
          return;
        }
      },
    }).then((result) => {
      if (!result.dismiss) {
        period.modifyUserId = this.authInfo.getUserId()
        period.userName = this.authInfo.getFullUserName()
        this._payrollPeriodSvc.closePeriod(period).subscribe(res => {
          if (res.errors.length > 0) { this._toastr.error("Error cerrando periodo"); return; }
          this._toastr.success("El periodo fue cerrado satisfactoriamente")
          this.getFilteredYear(this.currentYear);
        }, err => this._toastr.error(err))
      }
    })
  }


  openPeriod(period:Period) {
    if (period.conditionId == 1) return
    Swal.fire({
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      title: "¿Seguro/a que desea abrir este periodo?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      icon: 'question',
      confirmButtonColor: '#15c229'
    }).then((result) => {
      if (result.isConfirmed) {
        period.modifyUserId = this.authInfo.getUserId()
        period.userName = this.authInfo.getFullUserName()
        this._payrollPeriodSvc.openPeriod(period).subscribe(res => {
          if (res.errors.length > 0) { this._toastr.error(res.errors[0], "Error abriendo periodo, verifique que no existe otro periodo abierto o comuniquese con Helpdesk"); return; }
          this._toastr.success("El periodo fue abierto satisfactoriamente")
          this.getFilteredYear(this.currentYear);
        }, err => this._toastr.error(err))
      }
    })
  }


  deletePeriod(period: Period) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      title: "¿Está seguro que desea eliminar este registro?",
      icon: "question"
    }).then(res => {
      if (res.isConfirmed) {
        if (period.conditionId == 1) {
          this._toastr.warning("Por favor cierre el periodo y luego trate de inactivarlo", 'No se puede inactivar un periodo que está abierto')
          return
        }
        period.modifyUserId = this.authInfo.getUserId()
        period.userName = this.authInfo.getFullUserName()
        let deleteInterviewSub = this._payrollPeriodSvc.delete(period).subscribe((res: any) => {
          if (res.errors.length > 0) {
            this._toastr.error("Error al eliminar el periodo")
            return;
          }
        }, (error) => {
          this._toastr.error("Error al eliminar el periodo", "Error inesperado")
        }, () => {
          this.getPeriods({ value: this.yearSelected, type: PeriodTypeGet.ByYear});
          Swal.fire("Registro eliminado", '', 'success')
        })
        this.subscription.add(deleteInterviewSub)
      }
    })
  }


  filter() {
    //document.getElementById('searchBarBtn').click()
    if (this.filteredList.records?.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.periods = this.filterTable(this.originalPeriods)
    }
    this.setFilterToLocalStorage();
  }

  scheduleYearsDropdown() {
    let years: { description: string, value: number }[] = [];
    for (let i = new Date().getFullYear(); i >= 2000; i--) {

      years.push({ description: `${i}`, value: i });
    }
    
    return years;
  }

  setFilterToLocalStorage() {
    localStorage.setItem('period-list_filter', JSON.stringify(this.selectedItems));
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl.find(x => x.item_id == 1) && !sl.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl.find(x => x.item_id == 2) && !sl.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    return list;
  }

  openDialog(model?: Period): void {
    const dialogRef = this._dialog.open(AddModalComponent, {

      data: model ? model : null,
    });
    let sub = dialogRef.afterClosed().subscribe((result: { status: OperationResult }) => {
      switch (result.status) {
        case OperationResult.Succeeded:
          this._toastr.success("El período fue guardado satisfactoriamente")
          this.getPeriods({ value: this.yearSelected, type: PeriodTypeGet.ByYear });
          break;

        case OperationResult.Canceled:
          //  this._toastr.info('Operación cancelada');
          break;


        case OperationResult.Failed:
          this._toastr.error('Ocurrió un error al realizar la operación.')
          break;

      }
    });
    this.subscription.add(sub);
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  getPaginatedRecords(event) {
    this.paginatedPeriods = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.selectedPage = event.selectedPage
  }

  exportToExcel() {
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Descripción",
      "Fecha Inicio",
      "Fecha Fin",
      "Fecha de Apertura",
      "Fecha de Cierre",
      "Condición",
      "Estatus",
      "Fecha de Creación",
    ],
    [
      "payrollPeriodId",
      "description",
      "startDate",
      "endDate",
      "openingDate",
      "sendLimitDate",
      "conditionId",
      "status",
      "createDate"
    ]
    ]
    this._exlService.exportToExcelSpecificColumns(this.filteredList.enable ? this.filteredList.records : this.periods, excelHeaders, 'Lista de períodos', true);
  }


  exportToPdf() {
    const payrollPeriodId: number = this.yearSelected
    const reportCode: ReportCode = ReportCode.Period;
    const companyId: number = this.authInfo.getCompanyId();
    const pagesize = this.paginatedPeriods?.length
    const status: number = this.selectedItems?.length === 2 || this.selectedItems?.length == 0 ? -1 : this.selectedItems[0]?.item_id;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&PayrollPeriodId=${payrollPeriodId}&CompanyId=${companyId}&Status=${status}&PropKeyId=0&SearchParameter=&Page=${this.selectedPage}&PageSize=${pagesize}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Período de Nómina',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

}
