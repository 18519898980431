<div *ngIf="isTableLoading"
  class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
  <el-spinner animation="spinner-logo"></el-spinner>
  <h3 class="text-primary">Cargando PDF...</h3>
</div>
<div class="container-fluid py-3">
  <button  (click)="goBack()"  class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
      <i class="flaticon2-left-arrow-1"></i>
      <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
  </button>
</div>
<div class="container-fluid" *ngIf="!isTableLoading">
  <div id="pdfExp" class="card">
    <div class="card-header">
      <div class="row" id="header">
        <div class="col-lg-2 col-md-3 col-sm-2 col-12">
          <div class="logo p-2" >
            <!-- <img [src]="file.logo" alt="" class="max-w-200px" /> -->
            <img [src]="file.logo" alt="" class="img-fluid" />
          </div>
        </div>
        <div class="col-lg-8 col-md-6 col-sm-7 col-12">
          <div class="">
            <!-- title p-2 d-flex justify-content-center align-items-center flex-column -->
            <div *ngIf="fileModalConfig.formSubTitle" class="title w-100 text-center">
              <h1 class="ttlPDF text-center w-100">
                {{fileModalConfig.formTitle}}
              </h1>
              <h2 class="text-danger text-center w-100">{{fileModalConfig.formSubTitle}}</h2>
            </div>
            <div *ngIf="!fileModalConfig.formSubTitle" class="title w-100 text-center">
              <h1 class="ttlPDF">
                Instituto Tecnológico de Las Américas
              </h1>
              <h2 class="text-danger">Acción de Cambio de Servidor Público (Servicios Externos)</h2>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-3 col-12">
          <!-- align-items-center d-flex flex-wrap justify-content-md-between justify-content-md-center -->
          <div class="d-flex flex-wrap justify-content-end">
            <h4 class="text-right">Fecha de solicitud</h4>
            <h5 class=" text-center text-danger ms-3">{{permission?.applicationDate | date: 'dd-MM-yyyy'}}</h5>
          </div>
        </div>
      </div>

      <!-- <div class="">
        <div class="date mt-md-5 p-4 d-flex flex-wrap justify-content-between align-items-right">
        </div>
      </div> -->
    </div>
    <div class="card-body">
      <div id="pdf">
        <div class="pdf-page p-4">
          <div class="container-fluid mt-3" id="personal-data">
            <div class="row">
              <div class="col-md-12">
                <h3 class="p-2 bg-primary text-white mt-3 text-center">
                  DATOS PERSONALES
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-sm-2 col-12 align-items-center">
                <div class="row">
                  <div class="col-12">
                    <!-- <span *ngIf="loadingPic" class="d-flex flex-column align-items-center">
                      <img src="assets/media/logos/xmera-logo-3.png" alt="loading" width="70px" />
                      <strong class="text-danger font-size-h3">Cargando...</strong>
                    </span> -->
  
                    <!-- <ng-container>
                      <div class="symbol symbol-lg-200 align-content row"*ngIf="!photoUrl; else second">
                        <i class="symbol-badge bg-success"></i>
                        <img src="../../../../assets/media/no-user.png" style="width:200px; height:200px "alt="image">
                      </div>
                      <ng-template #second>
                        <div class="symbol symbol-lg-200 align-content">
                          <i class="symbol-badge bg-success"></i>
                          <img [src]="photoUrl | safe" alt="image" class="size=max-w-200px"  (click)="showModal('photo')">
                        </div>
                      </ng-template>
                    </ng-container> -->
                    <div class="symbol symbol-lg-200 align-content">
                      <i class="symbol-badge bg-success"></i>
                      <img src="../../../../assets/media/no-user.png" class="img-fluid photo-profile-action-change-employee" alt="image">
                    </div>
                  </div>
                </div>
              </div>
              <div class="pdf-list col-xxl-5 col-xl-5 col-lg-5 col-md-4 col-sm-5 col-12">
                  <div class="row">
                      <div class="col-12 col-sm-5 label-inline">
                          <label>Código del Servidor Público:</label>
                      </div>
                      <div class="col-12 col-sm-7 label-inline">
                          <span class="text-wrap">{{personSelected?.employNumber }}</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12 col-sm-5 label-inline">
                          <label>Servidor Público:</label>
                      </div>
                      <div class="col-12 col-sm-7 label-inline">
                          <span class="text-wrap">{{personSelected?.name}}</span>
                      </div>
                  </div>
                  <div class="row" >
                      <div class="col-12 col-sm-5 label-inline">
                          <label>{{personSelected?.typeIdentification}}:</label>
                      </div>
                      <div class="col-12 col-sm-7 label-inline">
                          <span class="text-wrap">
                            <ng-container *ngIf="personSelected?.typeIdentificationId === 1; else noCedula">
                              {{personSelected?.personalIdentification | mask: "000-0000000-0"}}
                            </ng-container>
                            <ng-template #noCedula>
                              {{personSelected?.personalIdentification}}
                            </ng-template>
                          </span>
                      </div>
                  </div>
                  <div class="row" >
                      <div class="col-12 col-sm-5 label-inline">
                          <label>Teléfono:</label>
                      </div>
                      <div class="col-12 col-sm-7 label-inline">
                          <span class="text-wrap">{{ (personSelected?.phoneNumber | mask: "(000)-000-0000") || ' ' }}</span>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-5 label-inline">
                        <label>Unidad Organizativa:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap">{{personSelected?.departmentName}}</span>
                    </div>
                  </div>
              </div>
              <div class="pdf-list col-xxl-5 col-xl-5 col-lg-5 col-md-4 col-sm-5 col-12">
                  <div class="row">
                      <div class="col-12 col-sm-5 label-inline">
                          <label>Salario:</label>
                      </div>
                      <div class="col-12 col-sm-7 label-inline">
                          <span class="text-wrap" >{{personSelected?.salary | currency:"RD$"}}</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12 col-sm-5 label-inline">
                          <label>Tipo de Nombramiento:</label>
                      </div>
                      <div class="col-12 col-sm-7 label-inline">
                          <span class="text-wrap">{{personSelected?.contractType || 'N/A'}}</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12 col-sm-5 label-inline">
                          <label>Fecha Efectiva:</label>
                      </div>
                      <div class="col-12 col-sm-7 label-inline">
                          <span class="text-wrap">{{personSelected?.effectiveDate | date:'dd/MM/yyyy'}}</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12 col-sm-5 label-inline">
                          <label>Fecha de Ingreso:</label>
                      </div>
                      <div class="col-12 col-sm-7 label-inline">
                          <span class="text-wrap">{{personSelected?.admissionDate | date:'dd/MM/yyyy'}}</span>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-5 label-inline p-3">
                        <label>Puesto:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap">{{personSelected?.positionName}}</span>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="container-fluid row mt-3 " id="family-working-at-here">
            <h3 class="col-md-12 mt-3 p-2 bg-secondary text-white text-center">
                SERVICIOS EXTERNOS
            </h3>
            <div class="col-md-12 m-0 p-0 ">
                <div class="pdf-list col-md-8">
                    <div class="pdf-items">
                      <h4>Fecha de Solicitud:</h4>
                      <span>{{permission?.applicationDate | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <div class="pdf-items">
                        <h4>Fecha de Inicio:</h4>
                        <span>{{permission?.dateStart | date: 'dd/MM/yyyy'}}</span>
                      </div>
                    <div class="pdf-items">
                      <h4>Fecha de Termino:</h4>
                      <span>{{permission?.dateEnd | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <div class="pdf-items">
                        <h4>Hora de Inicio:</h4>
                        <span>{{convert(permission?.hourStart)}}</span>
                      </div>
                    <div class="pdf-items">
                      <h4>Hora de Termino:</h4>
                      <span>{{convert(permission?.hourEnd)}}</span>
                    </div>
                </div>
          </div>

          <div class="container-fluid row mt-3" id="references">
            <h3 class="col-md-12 p-2 bg-primary text-white mt-3 text-center">
                CAUSA DEL SERVICIO EXTERNO
            </h3>
            <div class="col-md-12 m-0 p-0 overflow-auto" id="laboral-history">
                <table class="table ">
                    <tbody>
                      <tr class="p-0">
                        <td class="">{{permission?.reason || "N/A"}}</td>
                      </tr>
                    </tbody>
                </table>
            </div>
            <h3 class="bg-white border-bottom col-md-12 p-3 text-center text-primary bg-light-light ">
              COMENTARIOS
            </h3>
            <div class="col-md-12 m-0 p-0 overflow-auto" id="laboral-history">
                <table class="table ">
                    <tbody>
                      <tr class="p-0">
                        <td class="">{{permission?.comment || "N/A"}}</td>
                      </tr>
                    </tbody>
                </table>
            </div>
            <ng-container *ngIf="permission?.typePermissionsId === 2">
              <h3 class="bg-white border-bottom col-md-12 p-3 text-center text-primary bg-light-light ">
                DIAS
              </h3>
              <div class="col-md-12 m-0 p-0 overflow-auto" id="laboral-history">
                <div class="form-group mb-0">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label plz">
                            <input type="radio" disabled [checked]="permission?.monday" class="form-check-input">
                             Lunes
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <label class="form-check-label plz">
                            <input type="radio" disabled [checked]="permission?.tuesday" class="form-check-input">
                            Martes
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <label class="form-check-label plz">
                            <input  type="radio" disabled [checked]="permission?.wednesday" class="form-check-input">
                            Miércoles
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <label class="form-check-label plz">
                            <input  type="radio" disabled [checked]="permission?.thursday"  class="form-check-input">
                            Jueves
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label plz">
                            <input type="radio" disabled [checked]="permission?.friday" class="form-check-input">
                            Viernes
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <label class="form-check-label plz">
                            <input type="radio" disabled [checked]="permission?.saturday" class="form-check-input">
                            Sábado
                        </label>
                    </div>
                </div>
              </div>
            </ng-container>
          </div>
      </div>
    </div>
    <div class="card-footer mt-4">
      <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
        <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-100px">
        <div class="d-flex flex-wrap justify-content-around flex-fill">
          <div *ngIf="supervisorNames.employeeSupervisor" class="mr-3">
            <span>______________________</span>
            <p class="font-weight-bold text-dark text-center">
              {{supervisorNames.employeeSupervisor}}
            </p>
          </div>
          <div *ngIf="supervisorNames.hhrr" class="mr-3">
            <span>______________________</span>
            <p class="font-weight-bold text-dark text-center">
              {{supervisorNames.hhrr}}
            </p>
          </div>
          <div *ngIf="supervisorNames.rectory" class="mr-3">
            <span>______________________</span>
            <p class="font-weight-bold text-dark text-center">
              {{supervisorNames.rectory}}
            </p>
          </div>
        </div>
        <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-100px">
      </div>
    </div>
  </div>
</div>
<button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
  <i class="flaticon2-printer font-size-h1"></i>
</button>
<script>
  let print = () => {
    let pdf = document.getElementById("pdfExp");
    pdf.contentWindow.focus();
    pdf.contentWindow.print();
  };
</script>
<el-modal target="logoModal" size="md" [title]="'Vista de foto'">
  <el-embed *ngIf="fileModalConfig.src" [src]="fileModalConfig.src" [type]="fileModalConfig.type"
    [filename]="fileModalConfig.filename" [width]="fileModalConfig.width" [height]="fileModalConfig.height">
  </el-embed>
</el-modal>
<el-modal target="photo" size="md" [title]="'Vista de foto'">
  <div class="container justify-content-center" style="align-content: center;">
    <ng-template [ngIf]="photoUrl">
      <img [src]="photoUrl | safe" alt="image" style="max-width: 350px;">
    </ng-template>
  </div>
</el-modal>
