import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MapPersonnelChangeActionsComponent } from "./map-personnel-change-actions/map-personnel-change-actions.component";
import { MapPersonnelChangeComponent } from "./map-personnel-change-actions/map-personnel-change/map-personnel-change.component";
import { MapPostVacanciesComponent } from "./map-vacancies/map-post-vacancies/map-post-vacancies.component";
import { MapVacanciesComponent } from "./map-vacancies/map-vacancies.component";
import { NoObjectionComponent } from "./no-objection/no-objection.component";
import { MapBudgetApprobationComponent } from './map-budget-approbation/map-budget-approbation.component';

const routes: Routes = [
    {
      path: 'no-objection',
      component: NoObjectionComponent,
      data: {
        breadcrumb: 'No Objeción',
        module: 'MAP',
      },
    },
    {
      path: 'map/vacancies',
      component: MapVacanciesComponent,
    },
    {
      path: 'map/post-vacancies',
      component: MapPostVacanciesComponent,
    },
    {
      path: 'map/personnel-changes-actions',
      component: MapPersonnelChangeActionsComponent,
    },
    {
      path: 'map/personnel-change',
      component: MapPersonnelChangeComponent,
    },
    {
      path: 'map-budget-aprobation',
      component: MapBudgetApprobationComponent,
      data: {
        breadcrumb: 'Aprobación de presupuesto',
        module: 'MAP',
      }
    },
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class MAPRoutingModule { }
