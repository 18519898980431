import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'search-employee',
  templateUrl: './search-employee.component.html',
  styleUrls: ['./search-employee.component.css']
})
export class SearchEmployeeComponent implements OnInit, AfterViewChecked {
  employeesListOriginal: any[] = [];
  employees: any[] = [];
  byTeacher = false
  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 2, displayName: "Código de Servidor Público" },
      { propkeyId: 3, displayName: "Servidor Público" },
      { propkeyId: 4, displayName: "Documento de Identidad", default: true },
    ];
  isSingleClick: Boolean = true;
  paginatedEmployees: any[] = [];
  searchParameter;

  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };

  employeesPagedResut = new PagedResult<any>();
  selectedEmployee: any;

  IsViewMode = false;

  @ViewChild('paginator') paginator: CaptionComponent;
  
  filterText = '';

  constructor(private payrollNewService: PayrollNewService,
    public dialogRef: MatDialogRef<SearchEmployeeComponent>,
    private _toastService: ToastService, private _changeDet: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {
      item: any,
      IsView: boolean,
      currentPage: string,
      type?: number,
      typeParameter?: string,
      typeValue?: any ,
      hideInputTeacher: boolean
    }) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  getEmployees() {
    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = !this.searchParameter?.value ? 0 : this.searchParameter?.propkeyId
    this.payrollNewService.getEmployeesPaginated(page, pageSize, propKeyId, text).subscribe((res: any) => {
      if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al obtener la lista de Servidor Público"); return; }
      const dataList = res.singleData.items.filter(e => e.status);
      
      this.employees = [...dataList];
      this.employeesListOriginal = [...dataList];
      this.employeesPagedResut = res.singleData
    }, err => this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk."))
  }

  getFilteredList(event) {
    this.paginator.selectedPage = 1;
    this.filteredList = event;
  }

  selectTeachers(event){
    this.getData()
  }

  filterSearch(event){
    this.employeesPagedResut.page = 1
    this.searchParameter = event
    this.getData()
  }

  getData(){
    this.selectedEmployee = null
    if(this.byTeacher){
      this.getTeacher()
    }else{
      this.getEmployees()
    }
  }

  getTeacher(){
    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value == '' ? 0 : this.searchParameter?.propkeyId
    this.payrollNewService.getEmployeeTeacherPaginated(page, pageSize, propKeyId, text).subscribe({
      next: (res) => {

        if(!res.succeded){

          res.errors.forEach(err=>{
            this._toastService.error(err);
          })

          res.warnings.forEach(warn=>{
            this._toastService.warning(warn);
          })

          return;
        }


        const dataList = res.singleData.items.filter(e => e.status);
        this.employees = [...dataList];
        this.employeesListOriginal = [...dataList];
        this.employeesPagedResut = res.singleData
      }, error : (error :any) => {
        this._toastService.error('Ha ocurrido un error tratando de consultar la lista de Servidores Públicos')
      }
    })
  }

  getPaginatedRecords(event) {
    if (this.employeesPagedResut.page == event.selectedPage && this.employeesPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.employeesPagedResut.page = event.selectedPage;
    this.employeesPagedResut.pageSize = event.registersPerPage;
    this.getData()
  }

  selectEmployee(employee) {
    setTimeout(()=>{
      if(this.isSingleClick){
        if (this.selectedEmployee && this.selectedEmployee.employeeId === employee.employeeId) {
          this.selectedEmployee = null;
          return;
        }
        this.selectedEmployee = { ...employee };
        this.isSingleClick = true;
                 
      }
    },250)
  }
  
  doubleClick(employee){
    
    this.selectedEmployee = { ...employee };
    this.isSingleClick = true;
    this.Accept()
  }

  isEmployeeSelected(employee) {
    if (!employee) return false;
    if (!this.selectedEmployee) return false;
    return this.selectedEmployee.employeeId === employee.employeeId;
  }

  Cancel(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

  Accept() {
    const row = { Applied: true, data: this.selectedEmployee }
    this.dialogRef.close(row);
  }

  onKeyChange(text) {
    this.filterText = text;
  }

}