import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ProcessPayrollService } from 'src/app/services/process-payroll.service';
import { ExpenseDetailModalComponent } from '../expense-detail-modal/expense-detail-modal.component';
import * as moment from 'moment';
import { ItemExpensesDetailComponent } from '../item-expenses-detail/item-expenses-detail.component';
import { IPayrollExpenseDeparturePOST, IPartidaGastosDetalleView, PayrollFileUpload } from '../models/payrollExpense';
import { IEmployeeTypeView } from 'src/app/models/Employee.model';
import { IDetallePartidaGastoDTO, ISendPayrollFilePOST, SendPayrollFileAdditional } from '../models/SendPayrollFile';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { IHeader } from '@payroll/payroll-models';
import { CompaniesService } from 'src/app/services/companies.service';
import { OtherBeneficiaryModalComponent } from '../other-beneficiary-modal/other-beneficiary-modal.component';
import Swal from 'sweetalert2';
import { PayrollConceptService } from 'src/app/services/payroll-concept.service';
import { DropdownConfig } from 'src/app/models/dropDownConfig.model';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';

@Component({
  selector: 'app-add-expense-detail-modal',
  templateUrl: './add-expense-detail-modal.component.html',
  styleUrls: ['./add-expense-detail-modal.component.css']
})
export class AddExpenseDetailModalComponent implements OnInit {

  employeeTypeConfig: DropdownConfig
  payrollConceptsConfig: DropdownConfig
  transactionTypeConfig: DropdownConfig
  budgetaryProcesesConfig: DropdownConfig
  specificSourcesConfig: DropdownConfig
  preventiveDocumentConfig: DropdownConfig

  sending = false;

  statusOptions = [
    { employeeStatus: true, description: 'Activos' },
    { employeeStatus: false, description: 'Inactivos' }
  ];


  employeeTypes: IEmployeeTypeView[] = []
  transactionTypes = []
  budgetaryProceses = []
  specificSources = []
  beneficiaries = []
  preventiveDocuments = []
  budgetSources: IDetallePartidaGastoDTO[] = []
  budgetSourcesPaginated: IDetallePartidaGastoDTO[] = []
  payrollConcepts = []

  form = new FormGroup({
    employeeType: new FormControl(null),
    employeeStatus: new FormControl(null),
    preventiveDocument: new FormControl(null),
    transactionType: new FormControl(null, Validators.required),
    budgetProcess: new FormControl(null, Validators.required),
    amount: new FormControl(0, Validators.min(0)),
    beneficiary: new FormControl(null, Validators.required),
    beneficiaryObject: new FormControl(null),
    bankAccount: new FormControl(null, Validators.required),
    subBankAccount: new FormControl(null, Validators.required),
    payrollConcept: new FormControl(null),
    descripcion: new FormControl(null, [Validators.required, Validators.maxLength(265)]),
    coletilla: new FormControl(null, [Validators.required, Validators.maxLength(70)]),
  })

  private headerId: number = 0;
  private partidaGastosId: number = 0;

  thereIsOtherBeneficiary: boolean = false;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddExpenseDetailModalComponent>,
    private formBuilder: FormBuilder,
    private srvProcess: ProcessPayrollService,
    private toastr: ToastrService,
    private srvAuth: AuthInfoService,
    private srvParameter: ParameterControlService,
    private companiesSrv: CompaniesService,
    private srvConcept: PayrollConceptService,
    @Inject(MAT_DIALOG_DATA) public data: { headerId: number, mode: 'send' | 'save', item: IHeader }
  ) {

    this.sending = this.data.mode === 'send'

    if (this.sending) {
      this.form.disable();
      this.form.get('employeeType').enable();
      this.form.get('employeeStatus').enable();
      this.form.get('payrollConcept').enable();
    }

    this.form.get('amount').disable();

    if (this.data?.item?.payrollTypeId === 4 || this.data?.item?.payrollTypeId === 1) {
      this.form.get('payrollConcept').setValidators([Validators.required]);
      this.form.get('payrollConcept').updateValueAndValidity();
    } else {
      this.form.get('employeeType').setValidators([Validators.required]);
      this.form.get('employeeType').updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.headerId = this.data.headerId
    this.employeeTypeConfig = this.getDropdownConfig('description')
    this.payrollConceptsConfig = this.getDropdownConfig('description')
    this.transactionTypeConfig = this.getDropdownConfig('customDescription')
    this.budgetaryProcesesConfig = this.getDropdownConfig('description')
    this.specificSourcesConfig = this.getDropdownConfig('description')
    this.preventiveDocumentConfig = this.getDropdownConfig('descripcion')
    this.getDropdownsData()
    if (this.data?.item?.payrollTypeId !== 4 && this.data?.item?.payrollTypeId !== 1) {
      this.getEmployeeTypes()
    }
    this.getBeneficiary();
    this.getPreventiveDocuments();
    if (this.data?.item?.payrollTypeId === 4 || this.data?.item?.payrollTypeId === 1) {
      this.getPayrollConcepts();
    }
  }

  getPayrollConcepts() {
    const payrollTypeId = this.data?.item?.payrollTypeId;
    const payrollPeriodRelatedId = this.data?.item?.payrollPeriodIdRelated;
    const budgetAreaId = this.data?.item?.budgetAreaId;
    const payrollPeriodId = this.data?.item?.payrollPeriodId;
    const VersionId = this.data?.item?.versionId;
    this.srvConcept.getConceptsBasedNewsAvailable(payrollTypeId, payrollPeriodRelatedId, budgetAreaId, payrollPeriodId, VersionId).subscribe({
      next: (res: ResponseModel<any>) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          })
          res.warnings.forEach(warn => {
            this.toastr.warning(warn);
          })
          return;
        }
        this.payrollConcepts = res?.dataList;
      },
      error: (err: any) => {
        this.toastr.error('Ha ocurrido un error tratando de obtener los conceptos');
      }
    });
  }

  changeConcept() {
    const payrollConcept = this.form.get('payrollConcept')?.value;
    const companyId = this.data?.item?.companyId;
    const employeeStatus: any = this.form.get('employeeStatus').value;

    this.budgetSources = [];
    this.partidaGastosId = 0;

    if (this.sending) {
      const employee = this.form.get('payrollConcept').value
      this.form.reset();
      this.form.get('payrollConcept').patchValue(employee)
    }

    if (!payrollConcept?.newsConceptId) {
      return;
    }

    this.srvProcess.getPayrollAditionalExpenseFilter(payrollConcept?.newsConceptId, this.headerId, employeeStatus?.employeeStatus).subscribe({
      next: (res) => {
        if (!res.succeded) {

          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(warn => {
            this.toastr.warning(warn);
          })


          if (!this.sending) {
            this.getPartidasGastoPayrollAditional(this.data.headerId, payrollConcept?.newsConceptId, companyId, null);
          }
          return;
        }

        const data = res.singleData.partidaGastos
        if (!data.partidaGastosId) {
          return;
        }

        this.partidaGastosId = data.partidaGastosId;
        const details = res.singleData.partidaGastosDetalle;

        if (this.sending) {
          this.budgetSources = details.map((partida: IPartidaGastosDetalleView) => {
            return {
              partidaGastosDetalleId: partida.partidaGastosDetalleId,
              partidaGastosId: partida.partidaGastosId,
              partidaHaciendaId: partida.partidaHaciendaId,
              capitulo: partida.capitulo,
              subcapitulo: partida.subCapitulo,
              unidadEjecutora: partida.unidadEjecutora,
              programa: partida.programa,
              subprograma: partida.subPrograma,
              proyecto: partida.proyecto,
              actividadObra: partida.actividadObra,
              objetal: partida.objetal,
              organismoFinanciador: partida.organismoFinanciador,
              fondo: partida.fondo,
              region: partida.region,
              provincia: partida.provincia,
              municipio: partida.municipal,
              fuente: partida.fuente,
              funcion: partida.funcion,
              ccp: partida.cuentaPresupuestaria,
              totalIngresos: partida.totalIngresos,
              totalDescuentos: partida.totalDescuentos,
              netoPrimeraQuincena: partida.netoPrimeraQuincena,
              netoSegundaQuincena: partida.netoSegundaQuincena,
              patron: partida.patron,
              riesgo: partida.riesgo,
              sfs: partida.sfs,
              retiroComplementario: partida.retiroComplementario
            } as IDetallePartidaGastoDTO;
          });

          this.form.get('amount').setValue(data.importe ?? null)
        } else {
          this.getPartidasGastoPayrollAditional(this.data.headerId, payrollConcept?.newsConceptId, companyId, data.importe);
        }
        this.form.get('payrollConcept').setValue(this.payrollConcepts.find(x => x.newsConceptId == data?.newsConceptId) ?? null)
        this.form.get('transactionType').setValue(this.transactionTypes.find(x => x.codigo == data.tipoTransaccion) ?? null)
        this.form.get('budgetProcess').setValue(this.budgetaryProceses.find(x => x.budgetaryid == data.procesoPresupuestario) ?? null)
        this.form.get('preventiveDocument').setValue(this.preventiveDocuments.find(x => x?.documento == data?.documentoPreventivo) ?? null)

        if (data.cuentaBancaria) {
          this.form.get('bankAccount').setValue(data.cuentaBancaria)
        }
        if (this.sending) {
          this.form.get('subBankAccount').setValue(data.subCuentaBancaria)
        }
        this.form.get('beneficiary').setValue(this.beneficiaries.find(x => x.numericData == data.beneficiario)?.numericData ?? data.beneficiario ?? null);
        this.form.get('beneficiaryObject').setValue(this.beneficiaries.find(x => x.numericData == data.beneficiario) ?? null);
        this.form.get('descripcion').setValue(data.descripcion ?? null)
        this.form.get('coletilla').setValue(data.coletilla ?? null)
        this.checkBeneficiary();
      },
      error: (err) => {
        this.toastr.warning('No ha sido posible consultar la lista de detalles del gasto guardados');

        if (!this.sending) {
          this.getPartidasGastoPayrollAditional(this.data.headerId, payrollConcept?.newsConceptId, companyId, null);
        }
        return;
      }
    });
  }

  getDropdownsData() {
    const year = moment(this.data.item.startDate).startOf('date').year()
    this.srvProcess.getPayrollCatalogs(year).subscribe((res) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })

        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })


        return;
      }
      const data = res.singleResultData;
      this.budgetaryProceses = data.budgetaryProceses;
      this.transactionTypes = data.transactionTypes?.map(tran => {
        return {
          ...tran,
          customDescription: tran.codigo + ' - ' + tran.descripcion
        }
      });
      this.specificSources = data.specificSources;

    }, err => {
      this.toastr.error('Ha ocurrido un error tratando de consultar los catalogos financieros')
    })

    this.srvParameter.getParameters(keyWord.CompanySubBankAccount).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(warn => {
            this.toastr.warning(warn);
          })


          return;
        }

        const subBankAccount = res.dataList.filter(x => x.status == true)[0];
        this.form.get('subBankAccount').setValue(subBankAccount.stringData);

      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error tratando de consultar la sub-cuenta bancaria de la institución')
      }
    })

    this.srvParameter.getParameters(keyWord.CompanyBankAccount).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(warn => {
            this.toastr.warning(warn);
          })


          return;
        }

        const account = res.dataList.filter(x => x.status == true)[0];
        this.form.get('bankAccount').setValue(account.stringData);

      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error tratando de consultar la cuenta bancaria de la institución')
      }
    })
  }

  getEmployeeTypes() {
    this.srvProcess.getEmployeeTypes(this.headerId).subscribe((res) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })

        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })


        return;
      }
      this.employeeTypes = res.dataList
    }, err => {
      this.toastr.error('Ha ocurrido un error tratando de consultar los tipos de Servidores Publicos')
    })
  }

  getPreventiveDocuments() {

    const executingUnit = this.data.item?.executingUnit;
    let chapter = '';
    let subChapter = '';
    const companyId = this.data.item.companyId;
    const userId = this.srvAuth.getUserId();

    this.companiesSrv.getCompanyById(companyId).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(warn => {
            this.toastr.warning(warn);
          })


          return;
        }

        const company = res.dataList[0];

        chapter = company?.chapter;
        subChapter = company?.subchapter;

        this.loadPreventiveDocuments(executingUnit, chapter, subChapter, companyId, userId);

      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error tratando de consultar la información de la institución relacionada')
      }
    })



  }

  private loadPreventiveDocuments(executingUnit: string, chapter: string, subChapter: string, companyId: number, userId: number) {
    this.srvProcess.getPreventiveDocuments(null, executingUnit, chapter, subChapter, companyId, userId).subscribe((res) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })

        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })


        return;
      }
      this.preventiveDocuments = res.dataList
    }, err => {
      this.toastr.error('Ha ocurrido un error tratando de consultar la lista de documentos')
    })
  }

  getBeneficiary() {
    this.srvParameter.getParameters(keyWord.SocialSecurityBeneficiary).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(warn => {
            this.toastr.warning(warn);
          })


          return;
        }

        this.beneficiaries = res.dataList.filter(x => x.status == true);

        const beneficiary = this.beneficiaries[0];
        this.form.get('beneficiary').setValue(beneficiary.numericData);
        this.form.get('beneficiaryObject').setValue(beneficiary);

      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error tratando de consultar el beneficiario de la seguridad social')
      }
    })
  }

  changeEmployeeType() {
    const employeeType: IEmployeeTypeView = this.form.get('employeeType').value;
    const employeeStatus: any = this.form.get('employeeStatus').value;
    const companyId = this.data?.item?.companyId;
    this.budgetSources = [];
    this.partidaGastosId = 0;

    if (this.sending) {
      const employee = this.form.get('employeeType').value
      this.form.reset();
      this.form.get('employeeType').patchValue(employee)
    }

    if (!employeeType.employeeTypeId && employeeType?.employeeTypeId !== 0) {
      return;
    }
    if (this.data?.item?.payrollTypeId === 3 && (!employeeType?.employeeTypeId || employeeStatus?.employeeStatus == null)) {
      return;
    }

    this.srvProcess.getPayrollExpenseFilter(employeeType.employeeTypeId, this.headerId, employeeStatus?.employeeStatus).subscribe({
      next: (res) => {

        // if (this.sending === true) {
        //   if(res?.warnings?.length > 0 ){
        //     this.toastr.warning(res.warnings[0]);
        //     return
        //   }
        // }

        if (!res.succeded) {


          if (!this.sending) {
            this.getPartidas(this.data.headerId, employeeType.employeeTypeId, companyId, null, employeeStatus?.employeeStatus);
          }

         
          return
        }

        const data = res.singleData.partidaGastos
        if (!data.partidaGastosId) {
          return
        }

        this.partidaGastosId = data.partidaGastosId;
        const details = res.singleData.partidaGastosDetalle;

        // if(details.length > 0){
        //  
        // }else{
        if (this.sending) {
          this.budgetSources = details.map((partida: IPartidaGastosDetalleView) => {
            return {
              partidaGastosDetalleId: partida.partidaGastosDetalleId,
              partidaGastosId: partida.partidaGastosId,
              partidaHaciendaId: partida.partidaHaciendaId,
              capitulo: partida.capitulo,
              subcapitulo: partida.subCapitulo,
              unidadEjecutora: partida.unidadEjecutora,
              programa: partida.programa,
              subprograma: partida.subPrograma,
              proyecto: partida.proyecto,
              actividadObra: partida.actividadObra,
              objetal: partida.objetal,
              organismoFinanciador: partida.organismoFinanciador,
              fondo: partida.fondo,
              region: partida.region,
              provincia: partida.provincia,
              municipio: partida.municipal,
              fuente: partida.fuente,
              funcion: partida.funcion,
              ccp: partida.cuentaPresupuestaria,
              totalIngresos: partida.totalIngresos,
              totalDescuentos: partida.totalDescuentos,
              netoPrimeraQuincena: partida.netoPrimeraQuincena,
              netoSegundaQuincena: partida.netoSegundaQuincena,
              patron: partida.patron,
              riesgo: partida.riesgo,
              sfs: partida.sfs,
              retiroComplementario: partida.retiroComplementario
            } as IDetallePartidaGastoDTO;
          });

          this.form.get('amount').setValue(data.importe ?? null)
        } else {
          this.getPartidas(this.data.headerId, employeeType.employeeTypeId, companyId, data.importe, employeeStatus?.employeeStatus);
        }

        //}


        this.form.get('employeeType').setValue(this.employeeTypes.find(x => x.employeeTypeId == data.employType) ?? null)
        this.form.get('employeeStatus').setValue(employeeStatus)
        this.form.get('transactionType').setValue(this.transactionTypes.find(x => x.codigo == data.tipoTransaccion) ?? null)
        this.form.get('budgetProcess').setValue(this.budgetaryProceses.find(x => x.budgetaryid == data.procesoPresupuestario) ?? null)
        //this.form.get('specificSource').setValue(this.specificSources.find(x => x.code == data.fondo) ?? null)
        this.form.get('preventiveDocument').setValue(this.preventiveDocuments.find(x => x?.documento == data?.documentoPreventivo) ?? null)


        if (this.sending) {
          this.form.get('bankAccount').setValue(data.cuentaBancaria)
          this.form.get('subBankAccount').setValue(data.subCuentaBancaria)
        }




        this.form.get('beneficiary').setValue(this.beneficiaries.find(x => x.numericData == data.beneficiario)?.numericData ?? data.beneficiario ?? null);
        this.form.get('beneficiaryObject').setValue(this.beneficiaries.find(x => x.numericData == data.beneficiario) ?? null);


        this.form.get('descripcion').setValue(data.descripcion ?? null)
        this.form.get('coletilla').setValue(data.coletilla ?? null)

        this.checkBeneficiary();


      },
      error: (err) => {
        if (!this.sending) {
          this.getPartidas(this.data.headerId, employeeType.employeeTypeId, companyId, null, employeeStatus?.employeeStatus);
        }
        return
      }
    })

  }

  checkBeneficiary() {
    const currentBeneficiary = this.form.get('beneficiary').value;
    this.thereIsOtherBeneficiary = this.beneficiaries.filter(x => x.numericData != currentBeneficiary).length > 0;
  }

  private getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }



  private getPartidas(headerId: number, employeeTypeId: number, companyId: number, importe: number, employeeStatus:boolean) {
    this.srvProcess.getPartidasGastoPayroll(headerId, employeeTypeId, companyId, employeeStatus).subscribe({
      next: (res) => {

        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(warn => {
            this.toastr.warning(warn);
          })


          return;
        }


        this.budgetSources = res.singleData?.viewGetPartidasGastoPayroll;
        this.form.get('amount').setValue(res.singleData.importeTSS ?? importe)

      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error tratando de consultar las partidas del gasto');
      }
    })
  }

  private getPartidasGastoPayrollAditional(headerId: number, newsConceptId: number, companyId: number, importe: number) {
    this.srvProcess.getPartidasGastoPayrollAditional(headerId, newsConceptId, companyId).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          })
          res.warnings.forEach(warn => {
            this.toastr.warning(warn);
          })
          return;
        }
        this.budgetSources = res.singleData?.viewGetPartidasGastoPayroll;
        this.form.get('amount').setValue(res.singleData.importeTSS ?? importe)
      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error tratando de consultar las partidas del gasto');
      }
    });
  }


  save() {

    if (this.form.invalid) {
      this.toastr.warning("Debe completar los campos obligatorios");
      return
    }

    const bankAccount = this.form.get('bankAccount').value;
    const subBankAccount = this.form.get('subBankAccount').value;
    const employeeStatus: any = this.form.get('employeeStatus').value;

    if (!bankAccount || !subBankAccount) {
      this.showBankAccountMessage();
      return;
    }

    const values = this.form.getRawValue();

    const details = this.budgetSources.map(partida => {
      return {
        partidaGastosDetalleId: partida.partidaGastosDetalleId,
        partidaGastosId: partida.partidaGastosId,
        partidaHaciendaId: partida.partidaHaciendaId,
        capitulo: partida.capitulo,
        subCapitulo: partida.subcapitulo,
        unidadEjecutora: partida.unidadEjecutora,
        programa: partida.programa,
        subPrograma: partida.subprograma,
        proyecto: partida.proyecto,
        actividadObra: partida.actividadObra,
        objetal: partida.objetal,
        organismoFinanciador: partida.organismoFinanciador,
        fuente: partida.fuente,
        fondo: partida.fondo,
        region: partida.region,
        provincia: partida.provincia,
        municipal: partida.municipio,
        funcion: partida.funcion,
        cuentaPresupuestaria: partida.ccp,
        totalIngresos: partida.totalIngresos,
        totalDescuentos: partida.totalDescuentos,
        netoPrimeraQuincena: partida.netoPrimeraQuincena,
        netoSegundaQuincena: partida.netoSegundaQuincena,
        patron: partida.patron,
        riesgo: partida.riesgo,
        sfs: partida.sfs,
        retiroComplementario: partida.retiroComplementario
      } as IPartidaGastosDetalleView
    });

    if (details.length == 0) {
      this.toastr.warning("No es posible guardar el detalle, no hay partidas del gasto agregadas");
      return;
    }

    const payroll: IPayrollExpenseDeparturePOST = {
      partidaGastosId: this.partidaGastosId,
      payrollTypeId: this.data?.item?.payrollTypeId,
      newsConceptId: this.data?.item?.payrollTypeId === 4 || this.data?.item?.payrollTypeId === 1 ? values?.payrollConcept?.newsConceptId : null,
      employType: this.data?.item?.payrollTypeId !== 4 && this.data?.item?.payrollTypeId !== 1 ? values?.employeeType?.employeeTypeId : null,
      createUserId: this.srvAuth.getUserId(),
      headerId: this.data.item.headerId,
      versionTransaccion: values.transactionType.version,
      documentoPreventivo: values.preventiveDocument?.documento,
      tipoTransaccion: values.transactionType.codigo,
      procesoPresupuestario: values.budgetProcess.budgetaryid,
      importe: values.amount,
      tipoDocumento: values.beneficiaryObject?.ccode ?? "R",
      beneficiario: values.beneficiary?.toString(),
      fondo: details[0].fondo,
      fuenteEspecifica: details[0].fuente,
      cuentaBancaria: values.bankAccount?.toString(),
      subCuentaBancaria: values.subBankAccount?.toString(),
      descripcion: values.descripcion,
      coletilla: values.coletilla,
      status: true,
      companyId: this.data.item.companyId,
      partidaGastosDetalle: details,
      employeeStatus:employeeStatus?.employeeStatus ?? true
    }




    this.srvProcess.postPayrollExpense(payroll).subscribe((res: any) => {

      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })

        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })


        return;
      }

      this.toastr.success('Datos guardados')
      this.close()
    }, err => {
      this.toastr.error('Ha ocurrido un error tratando de guardar el detalle del gasto')
    })
  }

  private showBankAccountMessage() {
    Swal.fire({
      icon: 'warning',
      title: 'Información bancaria incompleta',
      text: 'Debe solicitar la configuración de la Cuenta y Sub-Cuenta bancaria de la institución; estos valores son requeridos para el posterior envío de la nómina',
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Cerrar',
      confirmButtonColor: 'red'
    })
  }


  send() {
    if (this.form.invalid) {
      this.toastr.warning("Debe completar los campos obligatorios");
      return
    }

    if (this.data?.item?.payrollTypeId === 4 || this.data?.item?.payrollTypeId === 1) {
      this.sendPayrollFileAdditional();
    } else {
      this.sendPayrollFile();
    }

  }


  sendPayrollFile() {
    const companyId = this.data.item?.companyId ?? this.srvAuth.getCompanyId()
    const employeeTypeId = this.form.value.employeeType?.employeeTypeId
    const employeeStatus: any = this.form.get('employeeStatus').value;

    const preventiveDocument: string = this.form.getRawValue()?.preventiveDocument?.documento ?? null;

    if (!preventiveDocument || preventiveDocument.length == 0) {
      this.toastr.warning("No se ha seleccionado un Documento Compromiso para este archivo")
      return
    }

    const send: ISendPayrollFilePOST = {
      companyId,
      employeeType: employeeTypeId,
      headerId: this.headerId,
      userId: this.srvAuth.getUserId(),
      employeeStatus: employeeStatus?.employeeStatus ?? true
    }

    if (this.budgetSources.length == 0) {
      this.toastr.warning("No es posible enviar el archivo, no hay partidas del gasto agregadas")
      return
    }

    this.srvProcess.send(send).subscribe((res: any) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        });
        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        });
        return;
      }

      let data: PayrollFileUpload = res.singleData

      if (data.operacion == "exitosa") {
        this.toastr.success("Proceso terminado exitosamente.")
        this.close()
      }

    }, err => {
      this.toastr.error('Ha ocurrido un error tratando de enviar el archivo de nómina')
    })
  }

  private sendPayrollFileAdditional() {
    const companyId = this.data.item?.companyId ?? this.srvAuth.getCompanyId()
    const newsConceptId = this.form.value.payrollConcept?.newsConceptId

    const send: SendPayrollFileAdditional = {
      companyId,
      newsConceptId: newsConceptId,
      headerId: this.headerId,
      userId: this.srvAuth.getUserId()
    }

    if (this.budgetSources.length == 0) {
      this.toastr.warning("No es posible enviar el archivo, no hay partidas del gasto agregadas")
      return
    }
    this.srvProcess.sendPayrollFileAdditional(send).subscribe((res: any) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        });
        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        });
        return;
      }

      let data: PayrollFileUpload = res.singleData;

      if (data.operacion == "exitosa") {
        this.toastr.success("Proceso terminado exitosamente.")
        this.close()
      }

    }, err => {
      this.toastr.error('Ha ocurrido un error tratando de enviar el archivo de nómina');
    })
  }


  getPaginatedRecords(event) {
    this.budgetSourcesPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  openDetail(data) {
    this.dialog.open(ItemExpensesDetailComponent, {
      data: { partida: data },
      width: '60%'
    })
  }

  openOtherBeneficiary() {

    const currentBeneficiary = this.form.get('beneficiaryObject').value;

    this.dialog.open(OtherBeneficiaryModalComponent, {
      data: { beneficiaries: this.beneficiaries, currentBeneficiary },
      width: '60%'
    }).afterClosed().subscribe({
      next: (result) => {
        if (result.changed == true) {

          this.form.get('beneficiary').setValue(result.beneficiary?.numericData ?? null);
          this.form.get('beneficiaryObject').setValue(result.beneficiary ?? null);

          this.thereIsOtherBeneficiary = this.beneficiaries.filter(x => x.numericData != result.beneficiary?.numericData).length > 0;
        }
      }
    })
  }

  close() {
    this.dialogRef.close()
  }

}
