<div class="aside aside-left d-flex aside-fixed bg-aside" id="kt_aside">
  <div class="aside-primary d-flex flex-column align-items-center flex-row-auto">
    <div class="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12">
      <div data-toggle="tooltip" data-original-title="Volver" data-placement="right" id="logo">
        <a href="{{lobbyUrl}}">
          <img alt="Logo" src="/assets/logos/logo-sigei-100-02.png" class="max-h-70px logo-font" />
        </a>
        <h1 class="text-white ms-1">SIGEI</h1>
      </div>
    </div>
    <div class="aside-nav d-flex flex-column align-items-center flex-column-fluid text-center text-white py-5 side">
      <ul class="nav flex-column" role="tablist">

        <li class="nav-item mb-5" id="inicio_toggle" data-toggle="tooltip" data-placement="right"
          data-container="body" data-boundary="window" title="" data-original-title="Dashboard">
          <a routerLink="/" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab"
            data-target="#kt_aside_tab_1" role="tab" aria-selected="true">
            <i class="flaticon-dashboard icon-3x"></i>
          </a>
          <span class="d-des-none">Dashboard</span>
        </li>
        <li class="nav-item mb-5" id="reclutamiento_seleccion_toggle" data-toggle="tooltip" data-placement="right"
          data-container="body" data-boundary="window" title="" data-original-title="Reclutamiento y selección">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab"
            data-target="#kt_aside_tab_1" role="tab" aria-selected="true">
            <i class="flaticon-reclutamiento-1 icon-3x"></i>
          </a>
          <span class="d-des-none">Reclutamiento y selección</span>
        </li>
        <li class="nav-item mb-5" id="registro_control_toggle" data-toggle="tooltip" data-placement="right"
          data-container="body" data-boundary="window" title="" data-original-title="Registro y Control">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_2"
            role="tab" aria-selected="true">
            <i class="flaticon-contract-3 icon-3x"></i>
          </a>
          <span class="d-des-none"> Servidores Públicos</span>
        </li>

        <li class="nav-item mb-5" id="estructura_organica_toggle" data-toggle="tooltip" data-placement="right" data-container="body"
          data-boundary="window" title="" data-original-title="Estructura Orgánica">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_4"
            role="tab">
            <i class="flaticon-recursos-humanos-3 icon-3x"></i>
          </a>
          <span class="d-des-none">Estructura Orgánica</span>
        </li>
        <li class="nav-item mb-5" id="planificacion_toggle" data-toggle="tooltip" data-placement="right"
          data-container="body" data-boundary="window" title="" data-original-title="Planificación">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab"
            data-target="#kt_aside_tab_1" role="tab" aria-selected="true">
            <i class="flaticon-nomina-de-sueldos icon-3x"></i>
          </a>
          <span class="d-des-none">Planificación</span>
        </li>
        <li class="nav-item mb-5" id="nomina_toggle" data-toggle="tooltip" data-placement="right"
          data-container="body" data-boundary="window" title="" data-original-title="Nómina">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab"
            data-target="#kt_aside_tab_1" role="tab" aria-selected="true">
            <i class="flaticon-nomina-de-sueldos-1 icon-3x"></i>
          </a>
          <span class="d-des-none">Nómina</span>
        </li>

        <li class="nav-item mb-5 " id="evaluacion_desempeno_toggle" data-toggle="tooltip" data-placement="right"
          data-container="body" data-boundary="window" title="" data-original-title="Evaluación del Desempeño">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg " data-toggle="tab" data-target="#kt_aside_tab_3"
            role="tab" aria-selected="true">
            <i class="flaticon-lista-de-verificacion-1 icon-3x"></i>
          </a>
          <span class="d-des-none">Evaluación del Desempeño Laboral</span>
        </li>


        <li class="nav-item mb-5" id="historial_salud_toggle" data-toggle="tooltip" data-placement="right" data-container="body"
        data-boundary="window" title="" data-original-title="Historial de Salud">
        <a href="#" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_4"
          role="tab" aria-selected="true">
          <img src="assets/svg/medical/medicine.svg" class="svg-icon svg-icon-3x wizard-icon  filter-white">
        </a>
        <span class="d-des-none">Historial de Salud</span>
      </li>

        <li class="nav-item mb-5" id="capacitacion_desarrollo_toggle" data-toggle="tooltip" data-placement="right" data-container="body" *ngIf="!enviroment.production"
          data-boundary="window" title="" data-original-title="Capacitación y Desarrollo">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_4"
            role="tab">
            <i class="flaticon-graduated icon-3x"></i>
          </a>
          <span class="d-des-none">Capacitación y Desarrollo</span>
        </li>

        <li class="nav-item mb-5" id="capacitacion_desarrollo_toggle" data-toggle="tooltip" data-placement="right" data-container="body"
          data-boundary="window" title="" data-original-title="Repositorio KPI/HRM">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg disabled" data-toggle="tab" data-target="#kt_aside_tab_4"
            role="tab">
            <i class="flaticon-archivo icon-3x"></i>
          </a>
          <span class="d-des-none">Repositorio KPI/HRM</span>
        </li>
        <!-- <li class="nav-item mb-5" id="capacitacion_desarrollo_toggle" data-toggle="tooltip" data-placement="right" data-container="body"
          data-boundary="window" title="" data-original-title="Servicio al Servidor Público">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg disabled" data-toggle="tab" data-target="#kt_aside_tab_4"
            role="tab">
            <i class="flaticon-equipo icon-3x"></i>
          </a>
          <span class="d-des-none">Servicio al Servidor Público</span>
        </li> -->

        <!--li class="nav-item mb-5" id="capacitacion_desarrollo_toggle" data-toggle="tooltip" data-placement="right" data-container="body"
          data-boundary="window" title="" data-original-title="Valoración de Puestos">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg disabled" data-toggle="tab" data-target="#kt_aside_tab_4"
            role="tab">
            <i class="flaticon-dinero icon-3x"></i>
          </a>
          <span class="d-des-none">Valoración de Puestos</span>
        </li-->



        <li class="nav-item mb-5" id="gestion_contenido_toggle" data-toggle="tooltip" data-placement="right" data-container="body"
          data-boundary="window" title="" data-original-title="Gestión de Contenido">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_6"
            role="tab">
            <i class="flaticon-datos icon-3x"></i>
          </a> <br>
          <span class="d-des-none">Gestión de Contenido</span>
        </li>

        <li class="nav-item mb-5 "  data-toggle="tooltip" data-placement="right"
          data-container="body" data-boundary="window" title="" data-original-title="Configuración">
          <a (click)="goConfig()" class="nav-link btn btn-icon btn-clean btn-lg "
             aria-selected="true">
            <i class="flaticon-servidor icon-3x"></i>
          </a>
          <span class="d-des-none">Configuración</span>
        </li>

        <!-- <li class="nav-item mb-5" id="map_toggle" data-toggle="tooltip" data-placement="right" data-container="body"
          data-boundary="window" title="" data-original-title="MAP">
          <a class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_4"
            role="tab">
            <i class="mapicon icon-3x"></i>
          </a>
          <span class="d-des-none">MAP</span>
        </li>
        <li class="nav-item mb-5" id="nomina_toggle" data-toggle="tooltip" data-placement="right" data-container="body"
          data-boundary="window" title="" data-original-title="Nómina">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_5"
            role="tab">
            <i class="flaticon-banco icon-3x"></i>
          </a>
          <span class="d-des-none">Nómina</span>
        </li> -->

        <!-- <li id="Seguridad_toggle" class="nav-item mb-5 dropright" data-toggle="tooltip" data-placement="right"
          data-container="body" data-boundary="window" title="Seguridad" data-original-title="Seguridad">
          <a href="#" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_5"
            role="tab">
            <i class="flaticon-proteger icon-3x"></i>
          </a>
        </li> -->
      </ul>
      <!--end::Nav-->
      <div class="ps__rail-x">
        <div class="ps__thumb-x"></div>
      </div>
      <div class="ps__rail-y">
        <div class="ps__thumb-y"></div>
      </div>
    </div>
    <!--end::Nav Wrapper-->
    <!--begin::Footer-->
    <div class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
      <img alt="Logo" src="/assets/logos/logo-sigei-100-02.png" class="max-h-30px opacity-42" />
      <span class="font-size-h3 font-weight-bold opacity-42 text-white">SIGEI</span>

      <div  class="col mt-2 text-center font-weight-bold">
        <label  class="text-muted  fs-6 mb-0">
          Versión
        </label>
        <br class="mt-0 mb-0 pt-0 pb-0">
        <label  class="text-muted  fs-6">
          <span >{{appVersion}}</span>
        </label>
      </div>
     <!--  <div class="text-dark order-2 order-md-1 d-flex flex-column text-center">
        <a href="/" target="_blank" class="text-muted text-hover-primary text-center">{{ enviroment.copy_right }}</a>

        <span class="text-muted text-hover-primary text-center">{{
          enviroment.years
          }}</span>
      </div> -->
      <!-- <span class="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm aside-toggle-active" id="kt_aside_toggle" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="Toggle Aside">
        <i class="ki ki-bold-arrow-back icon-sm"></i>
      </span> -->
    </div>
   
    <!--end::Footer-->
  </div>
</div>
<div class="odffcanvas-overlayy">

</div>
<app-side-bar-content class="sidebar_content"></app-side-bar-content>

