import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IEmployee } from 'src/app/models/Employee.model';
import { MatDialog } from '@angular/material/dialog';
import { ListOfFaultsModalComponent } from '../list-of-faults-modal/list-of-faults-modal.component';

@Component({
  selector: 'app-second-and-third-grade',
  templateUrl: './second-and-third-grade.component.html',
  styleUrls: ['./second-and-third-grade.component.css']
})
export class SecondAndThirdGradeComponent implements OnInit {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'ACTION-CHANGE-EMPLOYEE',
    serviceCommission: { key: 'Service-Commission-Recurring-Actions', value: false },
    add: { key: 'Add-Service-Commission-Recurring-Actions', value: false },
    view: { key: 'View-Pdf-Service-Commission-Recurring-Actions', value: false },
    edit: { key: 'Edit-Service-Commission-Recurring-Actions', value: false },
    finalize: { key: 'Finalize-Service-Commission-Recurring-Actions', value: false },
    approve: { key: 'Approve-Service-Commission-Recurring-Actions', value: false },
    reject: { key: 'Reject-Service-Commission-Recurring-Actions', value: false }
  }

  minEndDate = null
  formIsInvalid = false
  endDateInvalid = false;
  motiveDropdownConfig;
  institutionDropdownConfig;
  motives = []
  institutions = []
  file = { document: '', documentLicense: '' }
  form: FormGroup
  @Input() employee: IEmployee
  @Input() commissions: any;
  @Input() isSeviceCommission: boolean = false;
  isEditingServiceCommision: boolean = false;
  currentConditionOnEditting!: number;
  currentEdittingIsWithoutPayroll: boolean = false;
  localStorageTab2 = 'disciplinary-measures-selected'
  
  idGradoFalta: number = 0;
  gradosFaltasList = [{ key: 1, name: 'Primer grado', },{ key: 2, name: 'Segundo grado', },{ key: 3, name: 'Tercer grado', }];
  dropdownConfig = {
    displayKey: 'name',
    search: true,
    height: 'auto',
    placeholder: 'Seleccionar',
    moreText: '...',
    noResultsFound: 'No se han encontrado registros',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'name'
  }

  constructor(
    private dialog: MatDialog,
  ) {
  }

  changeStartDate() {
  }

  ngOnInit(): void {
  }

  clearFields(reloadList: boolean = false){ }

  setTabSelected(tab: string) {
    localStorage.setItem(this.localStorageTab2, tab)
  }

  openCancelActionModal(){

    this.dialog.open(ListOfFaultsModalComponent,{
      width: '70%',
      data: {}
    }).afterClosed().subscribe({
      next: (res: boolean) =>{
        
      }
    })
  }

  changeGradoFalta(event: any): void {
    let idGradoFalta: number = event.value.key;
    this.idGradoFalta = idGradoFalta;
  }

}
