<div class="home-dashboard">
    <div class="card-body-custom">
        <div class="d-flex justify-content-between align-items-end mb-3 row">
            <div class="d-flex flex-nowrap justify-content-end col-12 col-md-12 col-lg-12">
                <div class="text-left d-flex flex-wrap mr-3 mt-3">
                    <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                            class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                            class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                </div>
                <button class="btn btn-primary" (click)="openFilter()">
                    <i class="fas fa-filter" data-toggle="tooltip" data-title="Filtrar"></i>
                </button>
            </div>
        </div>
    <div class="card bg-white p-5 gutter-b">
        <div class="row">
            <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-wrap align-items-center bg-light-warning rounded p-5 my-3">
                    <i class="flaticon-departamento icon-4x-c mr-5 text-warning"></i>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                        <h3 class="font-weight-bold text-dark-75 font-size-r text-nowrap"> Servidores Públicos</h3>
                    </div>
                    <span class="font-weight-bolder text-warning py-1 font-size-r">
                        {{ cardsStats?.activeEmployees|number }}
                    </span>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-wrap align-items-center bg-light-info rounded p-5 my-3">

                    <i class="flaticon-masculino-1 icon-4x-c mr-5 text-info"></i>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                        <h3 class="font-weight-bold text-dark-75 font-size-r text-nowrap">Hombres</h3>
                    </div>
                    <span class="font-weight-bolder text-info py-1 font-size-r">
                        {{ cardsStats?.maleEmployees.total|number }}
                        ({{ cardsStats?.maleEmployees.percentage }})%
                    </span>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-wrap align-items-center bg-light-danger rounded p-5 my-3">

                    <i class="flaticon-signo-de-genero-femenino icon-4x-c mr-5 text-danger"></i>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                        <h3 class="font-weight-bold text-dark-75 font-size-r text-nowrap">Mujeres</h3>
                    </div>
                    <span class="font-weight-bolder text-danger py-1 font-size-r">
                        {{ cardsStats?.femaleEmployees.total|number }}
                        ({{ cardsStats?.femaleEmployees.percentage }})%
                    </span>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-wrap align-items-center bg-light-success rounded p-5 my-3">

                    <i class="flaticon-departamento-1 icon-4x-c mr-5 text-success"></i>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                        <h3 class="font-weight-bold text-dark-75 font-size-r text-nowrap">Unidades Organizativas</h3>
                    </div>
                    <span class="font-weight-bolder text-success py-1 font-size-r">
                        {{ cardsStats?.department|number }}
                    </span>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-wrap align-items-center bg-light-dtl rounded p-5 my-3">

                    <i class="flaticon-recursos-humanos-4 icon-4x-c mr-5 text-dtl"></i>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                        <h3 class="font-weight-bold text-dark-75  font-size-r text-nowrap">Usuarios</h3>
                    </div>
                    <span class="font-weight-bolder text-dtl py-1 font-size-r">
                        {{ cardsStats?.users|number }}
                    </span>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="d-flex flex-wrap align-items-center bg-light-primary rounded p-5 my-3">


                    <i class="flaticon-lista-de-verificacion icon-4x-c mr-5 text-bgligth"></i>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                        <h3 class="font-weight-bold text-dark-75 font-size-r text-nowrap">
                            Vacantes Publicadas
                        </h3>
                    </div>
                    <span class="font-weight-bolder text-primary py-1 font-size-r">{{cardsStats.vacants|number}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row gutter-b">
        <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-2 mt-2  ms-3">
                    <h3 class="font-weight-bold text-dark-75 py-3">Vacante por Año</h3>
                    <div class="text-right d-flex flex-wrap mr-3 mt-3">
                        <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                        <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    </div>
                </div>
                <div style="height: 519px; overflow-y: auto;" class="card-body p-3">
                    <app-bar-chart-race [autoHeight]="true" [data]="vacantPerYear" [id]="'chartVacante'"></app-bar-chart-race>
                </div>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-2 mt-2  ms-3">
                    <h3 class="font-weight-bold text-dark-75 py-3">Requisiciones Aprobadas por Mes</h3>
                        <div class="text-right d-flex flex-wrap mr-3 mt-3">
                        <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                        <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    </div>
                </div>
                <div class="card-body p-3">
                    <app-bar-chart-race [year]="''" [id]="'chart-bar1'" [data]="requisitionMonth"></app-bar-chart-race>
                </div>
            </div>
        </div>

        <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-2 mt-2 d-flex justify-content-between">
                    <h3 class="ms-3 font-weight-bold text-dark-75 py-3"> Servidores Públicos por Unidad Organizativa</h3>
                        <div class="text-right d-flex flex-wrap mr-3 mt-3">
                        <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                        <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    </div>
                    <!-- <ngx-select-dropdown [multiple]='true' (change)="changeEmployee_Dep()" [options]="departments" [(ngModel)]="dropdownEmployeeDepartment" [config]="dropdownConfig('departmentName')" class="col-12 col-xxl-4"></ngx-select-dropdown> -->


                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccionar'"
                        [settings]="getDropdownSettings('departmentId', 'departmentName')"
                        [data]="departments"
                        [(ngModel)]="dropdownEmployeeDepartment"
                        (onDeSelect)="onDeSelect($event, 'dropdownEmployeeDepartment')"
                        (onSelect)="onSelect($event, 'dropdownEmployeeDepartment')"
                        (onDeSelectAll)="onDeSelectAll($event, 'dropdownEmployeeDepartment')"
                        (onSelectAll)="onSelectAll($event, 'dropdownEmployeeDepartment')"
                    ></ng-multiselect-dropdown>
                </div>
                <div style="height: 476px; overflow-y: auto;" class="card-body p-3">

                    <app-bar-chart-race 
                    [autoHeight]="true" [id]="'chartsource1'" 
                    [data]="employeeByDepartments"></app-bar-chart-race>
                </div>
            </div>
        </div>

        <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-2 mt-2  ms-3">
                    <h3 class="font-weight-bold text-dark-75 py-3">Personal por Sucursal</h3>
                        <div class="text-right d-flex flex-wrap mr-3 mt-3">
                        <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                        <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    </div>
                </div>
                <div class="card-body p-3">
                    <app-bar-chart-race class="img-fluid" [id]="'chartHome'" [data]="personsByCompany"></app-bar-chart-race>
                </div>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">

            <div class="card card-custom bg-white">
                <div class="card-header p-2 mt-2 d-flex justify-content-between">
                    <h3 class="ms-3 font-weight-bold text-dark-75 py-3"> Servidores Públicos por Sexo y Unidad Organizativa</h3>
                        <div class="text-right d-flex flex-wrap mr-3 mt-3">
                        <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                        <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    </div>


                    <!-- <ngx-select-dropdown [multiple]='true' (change)="changeSexDepartment()" [options]="departments" [(ngModel)]="dropdownEmployeeSexDepartment" [config]="dropdownConfig('departmentName')" class="col-12 col-xxl-4"></ngx-select-dropdown>
                     -->
                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccionar'"
                        [settings]="getDropdownSettings('departmentId', 'departmentName')"
                        [data]="departments"
                        [(ngModel)]="dropdownEmployeeSexDepartment"
                        (onDeSelect)="onDeSelect($event, 'dropdownEmployeeSexDepartment')"
                        (onSelect)="onSelect($event, 'dropdownEmployeeSexDepartment')"
                        (onDeSelectAll)="onDeSelectAll($event, 'dropdownEmployeeSexDepartment')"
                        (onSelectAll)="onSelectAll($event, 'dropdownEmployeeSexDepartment')"
                    ></ng-multiselect-dropdown>
                </div>
                <div class="card-body p-3">

                    <app-chart-clustered-bar [id]="'clusteredbar1'" [data]="employeeGenderDepartment" [categoryField]="'rowText'" [serieValue1]="'value1'" [serieValue2]="'value2'"></app-chart-clustered-bar>
                </div>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-2 mt-2 ms-3">
                    <h3 class="font-weight-bold text-dark-75 py-3"> Servidores Públicos por Categoría</h3>
                        <div class="text-right d-flex flex-wrap mr-3 mt-3">
                        <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                        <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    </div>
                </div>
                <div class="card-body p-3">
                    <app-bar-chart-race [id]="'chartsource12'" [data]="employeeByContractTypes"></app-bar-chart-race>
                </div>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-2 mt-2 d-flex justify-content-between">
                    <h3 class=" ms-3 font-weight-bold text-dark-75 py-3">Entrevista por Unidad Organizativa</h3>
                        <div class="text-right d-flex flex-wrap mr-3 mt-3">
                        <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                        <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    </div>

                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccionar'"
                        [settings]="getDropdownSettings('departmentId', 'departmentName')"
                        [data]="departments"
                        [(ngModel)]="dropdownInterviewDepartment"
                        (onDeSelect)="onDeSelect($event, 'dropdownInterviewDepartment')"
                        (onSelect)="onSelect($event, 'dropdownInterviewDepartment')"
                        (onDeSelectAll)="onDeSelectAll($event, 'dropdownInterviewDepartment')"
                        (onSelectAll)="onSelectAll($event, 'dropdownInterviewDepartment')"
                    ></ng-multiselect-dropdown>

                    <!-- <ngx-select-dropdown [multiple]='true' (change)="changeInterviewDepartment()" [options]="departments" [(ngModel)]="dropdownInterviewDepartment" [config]="dropdownConfig('departmentName')" class="col-12 col-xxl-4"></ngx-select-dropdown>
                     -->
                </div>
                <div style="height: 519px; overflow-y: auto;" class="card-body p-3">
                    <app-bar-chart-race [autoHeight]="true" [year]="''" [id]="'chart-pie'" [data]="interviewsByDepartment"></app-bar-chart-race>
                </div>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-2 mt-2 ms-3">
                    <h3 class="font-weight-bold text-dark-75 py-3">Nombramiento por año de Servidores Públicos</h3>
                        <div class="text-right d-flex flex-wrap mr-3 mt-3">
                        <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                        <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    </div>
                    <ngx-select-dropdown [multiple]='false' [options]="statusHiringOpts" [(ngModel)]="dropdownStatusHirigns" (change)="changeStatusHirings()" [config]="dropdownConfig('text', '', false)" class="col-12 col-xxl-4"></ngx-select-dropdown>
                </div>
                <div style="height: 519px; overflow-y: auto;" class="card-body p-3">
                    <!-- <app-chart-sorted-bar [id]="'sortedchar1'" [data]="hiringsPerYear"></app-chart-sorted-bar> -->

                    <app-bar-chart-race  [autoHeight]="true" [id]="'hiringsPerYear'" [data]="hirings"></app-bar-chart-race>

                </div>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-2 mt-2 ms-3">
                    <h3 class="font-weight-bold text-dark-75 py-3">Nómina Últimos 3 Meses</h3>
                        <div class="text-right d-flex flex-wrap mr-3 mt-3">
                        <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                        <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    </div>
                </div>
                <div class="card-body p-3">
                    <!-- <app-semi-circle [data]="payrollLast3Months" [valueField]="'value3'" [id]="'chartpayrollMonth'"></app-semi-circle> -->
                    <app-chartsource [id]="'asdwqew'" [data]="payrollLast3Months" [valueField]="'value3'"></app-chartsource>
                </div>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-2 mt-2 ms-3 d-flex justify-content-between">
                    <h3 class="font-weight-bold text-dark-75 py-3">Nómina por Unidad Organizativa</h3>
                        <div class="text-right d-flex flex-wrap mr-3 mt-3">
                        <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                        <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    </div>
                    <ngx-select-dropdown [multiple]='false' (change)="changePeriod()" [options]="periods" [(ngModel)]="dropdownPeriod" [config]="dropdownConfig('description', 'Selecciona un período')" class="col-12 col-xxl-4"></ngx-select-dropdown>
                    <!-- <ngx-select-dropdown [multiple]='true' (change)="changePayrollDepartment()" [options]="departments" [(ngModel)]="dropdownPayrollDepartment" [config]="dropdownConfig('departmentName')" class="col-12 col-xxl-4"></ngx-select-dropdown>
                     -->
                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccionar'"
                        [settings]="getDropdownSettings('departmentId', 'departmentName')"
                        [data]="departments"
                        [(ngModel)]="dropdownPayrollDepartment"
                        (onDeSelect)="onDeSelect($event, 'dropdownPayrollDepartment')"
                        (onSelect)="onSelect($event, 'dropdownPayrollDepartment')"
                        (onDeSelectAll)="onDeSelectAll($event, 'dropdownPayrollDepartment')"
                        (onSelectAll)="onSelectAll($event, 'dropdownPayrollDepartment')"
                    ></ng-multiselect-dropdown>
                </div>
                <div style="height: 434px;overflow-y: auto;" class="card-body p-3">
                    <app-bar-chart-race [autoHeight]="true" [valueField]="'value3'" [isCurrency]="true" [id]="'chartPayrollDep'" [data]="payrollDepartmentChart"></app-bar-chart-race>

                </div>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-2 mt-2 ms-3">
                    <h3 class="font-weight-bold text-dark-75 py-3">Nombramiento por mes de Servidores Públicos</h3>
                        <div class="text-right d-flex flex-wrap mr-3 mt-3">
                        <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                        <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    </div>
                </div>
                <div class="card-body p-3">
                    <!-- <app-chart-sorted-bar [id]="'sortedchar1'" [data]="hiringsPerYear"></app-chart-sorted-bar> -->
                    <app-bar-chart-race [id]="'hiringsPerMonth'"  [data]="hiringsPerMonth"></app-bar-chart-race>
                </div>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-2 mt-2  ms-3">
                    <h3 class="font-weight-bold text-dark-75 py-3">Expedientes Incompletos</h3>
                        <div class="text-right d-flex flex-wrap mr-3 mt-3">
                        <h3 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h3>
                        <h3 class="d-flex font-weight-bolder text-primary">Hasta: <span
                                class="text-dark font-weight-lighter ">{{filterParams.date2 |date:'dd/MM/yyyy' ||''}}</span></h3>
                    </div>
                </div>
                <div class="card-body p-3">
                    <app-bar-chart-race [year]="''" [id]="'chart-incomplete-information'" [data]="incompleteProfiles"></app-bar-chart-race>
                </div>
            </div>
        </div>
    </div>
</div>
