import { DatePipe } from '@angular/common';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { IDonutChartData } from 'src/app/pages/home/home.models';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
import { ToastService } from 'src/app/shared/toast/toast.service';

type  DashboardPayroll = {description: string, value: number}

@Component({
  selector: 'dashboard-payroll',
  templateUrl: './dashboard-payroll.component.html',
  styleUrls: ['./dashboard-payroll.component.css']
})
export class DashboardPayrollComponent implements OnInit, AfterViewInit {
  suscriptions: Subscription = new Subscription();

  payrollData: DashboardPayroll[] = [];
  budgetData: DashboardPayroll[] = [];
  total: number = 0;
  filtered = false
  filterParams = {
    year: null,
    date2: this.datePipe.transform(new Date(), 'MM-dd-yyyy'),
    date1: '01-01-0001',
    companyId: this.authInfo.getCompanyId()
  }
  
  constructor(
    public dialogRef: MatDialogRef<DashboardPayrollComponent>, 
    private payrollService: PayrollService,
    public dialog: MatDialog, 
    private datePipe: DatePipe,
    private _toastService: ToastService,
    private authInfo: AuthInfoService
  ) { 
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    try{
      this.filterParams.date1 = this.datePipe.transform(`${this.authInfo.getConstitutionDate()}`, 'yyyy-MM-dd')
      this.filterParams.date2 = this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd')
    }catch(e){

    }
    
    this.getDashBoardData();
    
  }
  
  ngAfterViewInit(): void {
    
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FilterDashboardComponent, {
      data: {filterParams: this.filterParams},
    });

    let sub = dialogRef.afterClosed().subscribe((result) => {
      
      this.filtered = result.year != null || result.date1 != null || result.date2 != null;

      let year = new Date().getFullYear()
      
      if(result?.applyFilter == false){
        return
      }
      if(result.year){
        this.filterParams = {
          year: result.year,
          date1: `${result.year}-01-01`,
          date2: `${result.year}-12-31`,
          companyId: result.companyId
        }
      }else{
        if(result.date1 && !result.date2){
          this.filterParams = {
            year: null,
            date1: result.date1,
            date2: null,
            companyId: result.companyId
          } 
        }
        if(result.date2 && !result.date1){
          this.filterParams = {
            year: null,
            date1: null,
            date2: result.date2,
            companyId: result.companyId
          }
        }
        if(result.date1 && result.date2){
          this.filterParams = {
            year: null,
            date1: result.date1,
            date2: result.date2,
            companyId: result.companyId
          }
        }
      }
      this.getDashBoardData();
    });
    this.suscriptions.add(sub)
  }

  clear(){
    this.filterParams = {
      date1: null,
      date2: null,
      year: null,
      companyId: this.authInfo.getCompanyId()
    }
    this.getDashBoardData()
  }

  getDashBoardData(){
      this.payrollService.getGetDashBoardHeaders(this.filterParams.date1, this.filterParams.date2, this.filterParams.companyId).subscribe((res: any) => {
        if(res.errors.length > 0){
          this._toastService.error(res.errors[0]);
          return;
        }
        res.singleData.headerByType.forEach((type: { description: string; value: any; }) => {
          type.description = `${type.description} (${type.value})`
        });
        res.singleData.headerByBudgedArea.forEach((area: { description: string; value: any; }) => {
          area.description = `${area.description} (${area.value})`
        });
        this.payrollData = res.singleData.headerByType;
        this.budgetData = res.singleData.headerByBudgedArea;
        this.total = this.payrollData.reduce((previousValue, currentValue) =>  currentValue.value + previousValue, 0);
      }, error => {
        this._toastService.error('Ocurrió  un error inesperado al obtener los datos');
      });
  }

}
