<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-recursos-humanos-4 icon-3x text-danger mr-2"></i>
                <h1 class="text-primary"> Docentes por Asignatura </h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Dashboard">
            <button (click)="openDashboard()"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </button>
        </div>
    </div>
    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
            <div class="card-toolbar d-flex justify-content-start">

                <div class="col-md-4 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Estatus
                        </label>
                        <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                            [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                            (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="col-md-3 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Sucursal
                        </label>
                        <ngx-select-dropdown [options]="companys" [(ngModel)]="company"
                            [config]="dropdownConfig('companyName')" (change)="empleyeeebycompany()"
                            class="w-100"></ngx-select-dropdown>
                    </div>
                </div>

                <button *ngIf="incompleteEmployees?.quantity > 0 && privilege?.IncompleteTeachers?.value"
                    (click)="openModalIncompleteEmployees()" d class="btn btn-danger btn-pill  mt-2 mx-0 col-md-auto">
                    <i class="flaticon-recursos-humanos-6 icon-2x"></i> Docentes Incompletos:
                    {{incompleteEmployees?.quantity | number}}
                </button>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button *ngIf="privilege?.export?.value" class="btn btn-primary btn-pill dropdown-toggle"
                        type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                            <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                            Visualizar Reporte
                        </a>
                    </div>
                </div>

                <div class="col text-nowrap mt-2" *ngIf="privilege?.search?.value">
                    <app-search-bar-pagination #searchBar (onFilter)="filterSearch($event)"
                        [namePageLocalStorage]="localStorageSearch" [parameters]="searchBarDisplayNames">
                    </app-search-bar-pagination>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-vertical-center caption-top">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th class="text-left">Docente</th>
                        <th class="text-left">Tipo de Documento</th>
                        <th class="text-left">Documento Identidad</th>
                        <th class="text-left">Sexo</th>
                        <th class="text-left">Sucursal</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-left">Cargo</th>
                        <th class="text-left">Fecha de Ingreso</th>
                        <th class="text-left">Inicio Contrato</th>
                        <th class="text-left">Fin Contrato</th>
                        <th class="text-right">Precio por Hora</th>
                        <th class="text-left">Fecha Ult. Revisión</th>
                        <th class="text-left">Estatus</th>
                        <th class="text-center">Acciones</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let teacher of teachers; let i = index">
                        <td class="text-center">{{teacher?.employeeId}}</td>
                        <td class="text-left">{{teacher?.name }}</td>
                        <td class="text-left">{{teacher.typeIdentification}}</td>
                        <td class="text-left">
                            <span *ngIf="teacher.typeIdentificationId == 1"> {{teacher.personalIdentification | mask:
                                '000-0000000-0'}}</span>

                            <span *ngIf="teacher.typeIdentificationId != 1"> {{teacher.personalIdentification}}</span>
                        </td>
                        <td class="text-left">{{teacher?.sex }}</td>
                        <td class="text-left">{{teacher?.branch}}</td>
                        <td class="text-left">{{teacher?.departmentName }}</td>
                        <td class="text-left">{{teacher?.positionName }}</td>
                        <td class="text-left">{{teacher?.admissionDate | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-left">{{teacher?.hiredDate | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-left">{{teacher?.contractExpiration | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-right">{{teacher?.salary | currency:'$'}}</td>
                        <td class="text-left">{{(teacher?.lastTeacherReviewDate | date: 'dd/MM/yyyy') || 'N/A'}}</td>
                        <td class="text-left">
                            <span *ngIf="teacher.status" class="font-weight-bold text-success">Activo</span>
                            <span *ngIf="!teacher.status && !teacher.wasHiringCanceled" class="font-weight-bold text-danger">Inactivo</span>
                            <span *ngIf="!teacher.status && teacher.wasHiringCanceled" class="font-weight-bold text-danger">Nombramiento Anulado</span>
                        </td>
                        <td class="actionsBtn">
                            <a *ngIf="privilege?.edit?.value && !teacher.wasHiringCanceled"
                                [routerLink]="['/candidate-registration/',teacher.personId, 2,teacher?.employeeId]"
                                class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip" title="Editar">
                                <i class="flaticon-edit"></i>
                            </a>
                            <a *ngIf="privilege?.view?.value" (click)="exportTeacherData(teacher)"
                                class="btn btn-icon btn-light-info btn-sm m-1" data-toggle="tooltip"
                                title="Ver Docente">
                                <i class="flaticon-ver"></i>
                            </a>
                            <a *ngIf="privilege?.add?.value && !teacher.wasHiringCanceled" class="btn btn-icon btn-light-warning btn-sm m-1"
                                data-toggle="tooltip" (click)="OpenAddContract(teacher,viewTypes.Create)"
                                title="Agregar Contrato">
                                <i class="flaticon-mas"></i>
                            </a>
                            <a *ngIf="privilege?.ViewContractHistory?.value" data-toggle="tooltip" title=""
                                class="btn btn-sm btn-light-success btn-icon m-1" (click)="OpenViewContract(teacher)"
                                data-original-title="Ver Historial de Contratos">
                                <i class="flaticon-contract-3"></i>
                            </a>
                            <a  data-toggle="tooltip" title=""
                                class="btn btn-sm btn-light-success btn-icon m-1" routerLink="/teacher-reviews/{{teacher.employeeId}}"
                                data-original-title="Historia de Revisiones">
                                <i class="flaticon-lista-de-verificacion-2"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <app-paginator [listedRecordsLength]="teachers?.length"
                    [registerQuantity]="employeesPagedResult.totalItems"
                    [registerPerPage]="employeesPagedResult.pageSize" [totalPages]="employeesPagedResult.totalPages"
                    [selectedPage]="employeesPagedResult.page" (onChange)="getPaginatedRecords($event)">
                </app-paginator>
            </div>
        </div>
    </div>
</div>