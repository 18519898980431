import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ISendMap, SendMapPut } from './models/send-map-model';
import { SendMapHeader } from './models/send-map-header';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { forkJoin, Subscription } from 'rxjs';
import { RequestNoObjectionService } from 'src/app/services/request-no-objection.service';
import Swal from 'sweetalert2';
import { ValidateProfileComponent } from './validate-profile/validate-profile.component';
import { MatDialog } from '@angular/material/dialog';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { DashboardNoObjectionComponent } from './dashboard-no-objection/dashboard-no-objection.component';
import { EmployeesService } from 'src/app/services/employees.service';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { DigitalSignService } from 'src/app/services/digital-sign.service';
import { CreateSignRequestService } from 'src/app/services/create-sign-request.service';
import { DigitalSignatureApproval } from 'src/app/models/digital-signature-approval.model';
import { CompaniesService } from 'src/app/services/companies.service';
import { Metadata } from 'src/app/models/sign-request.model';
import { docService } from 'src/app/services/doc.service';
import { UserEntitiesAction } from 'src/app/models/enums-sign.model';
import { parameterDoc } from 'src/app/shared/utils/parameterDoc';
import { SendMapPdfSign } from './models/send-map-pdf-sign';
import { openReport, swalWarning } from 'src/app/shared/utils/utility';
import { formatDate } from '@angular/common';
import { EditNoObjectionModalComponent } from './edit-no-objection-modal/edit-no-objection-modal.component';
import { INoObjecionView } from 'src/app/models/map';
import { PagedResult } from 'src/app/shared/models/page-result';
import { FilterEvent, SearchBarPaginationComponent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import * as moment from 'moment';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';

enum condition {
  notsent = 0,
  sent = 1,
  approved = "3-2",
  reject = 5,
  returned = 6,
  canceled = 10
}

enum Status {
  active = 20,
  inactive = 21
}

declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'app-request-no-objection-map',
  templateUrl: './request-no-objection-map.component.html',
  styleUrls: ['./request-no-objection-map.component.css'],
})

export class RequestNoObjectionMapComponent implements OnInit, OnDestroy, AfterViewChecked {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'NOOBJECION',
    dashboardAction: { key: 'DASH', value: false },
    exportAction: { key: 'EXPORT', value: false },
    viewAction: { key: 'VIEW', value: false },
    sendAction: { key: 'SEND', value: false }
  }
  searchparameterlocalstorage: string = 'request-no-objection-map_filter_search';

  tableData: any[] = [];
  records: any[] = [];
  tableDataRecords = []
  private dataToSend: any[] = [];
  departments: any[] = ["Cargando datos..."];
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  sendMapHeader: SendMapHeader;
  sendMapList: SendMapPut[] = [];
  dataList: any[] = [];
  @Input() defaultParameterValue: { propkey: string, displayName: string }
  companies: [] = [];
  subscriptions: Subscription = new Subscription();
  sendMap: ISendMap;
  reNumber: string = '';
  showSended = false;
  selectDropdown;
  department = ''
  employeesPagedResult = new PagedResult<any>();
  tableDataOriginal: any[] = [];
  selectedItems: any[] = [];
  sent: boolean = false
  filterText: string = '';
  selectedDepartment: string = null; // Este es el departamento seleccionado del dropdown
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  isAllChecked: boolean = false

  statusOptions = [
    { item_id: Status.active, item_text: 'Activos' },
    { item_id: Status.inactive, item_text: 'Inactivos' },
    { item_id: condition.sent, item_text: 'Enviadas' },
    { item_id: condition.notsent, item_text: 'No enviadas' },
    { item_id: condition.approved, item_text: 'Aprobadas por el MAP' },
    { item_id: condition.reject, item_text: 'Rechazadas por el MAP' },
    { item_id: condition.returned, item_text: 'Devuelta para Revisión' },
    { item_id: condition.canceled, item_text: 'Nombramiento Anulado' }
  ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  searchParameter: FilterEvent
  config = {
    displayKey: 'departmentName',
    search: true,
    placeholder: 'Unidades Organizativas', // text to be displayed when no item is selected defaults to Select,
    noResultsFound: 'No se han encontrado resultados',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'departmentName',
    clearOnSelection: false,
  };
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Número de Secuencia" },
      { propkeyId: 4, displayName: "Documento de Identidad", default: true },
      { propkeyId: 3, displayName: "Nombre" },
      { propkeyId: 2, displayName: "Cargo" },
      { propkeyId: 5, displayName: "Unidad Organizativa" },
      { propkeyId: 7, displayName: "Número de Remisión" },
    ];

  @ViewChild('searchBar') searchBar: SearchBarPaginationComponent
  private usersApprovals: any = [];
  company: any;

  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  canUseOption = false
  companySign: any;

  isSending: boolean = false;

  constructor(
    private _excelService: ExportToExcelService,
    private _toastService: ToastService,
    private _parameterControlService: ParameterControlService,
    private _requestNoObjectionService: RequestNoObjectionService,
    private _changeDet: ChangeDetectorRef,
    private _dialog: MatDialog,
    private srvCreateSign: CreateSignRequestService,
    private digitalSignService: DigitalSignService,
    private docService: docService,
    private employeeService: EmployeesService, public authInfo: AuthInfoService
  ) {
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  ngOnInit(): void {
    const requestNoObjectionFilter = JSON.parse(localStorage.getItem("request-no-objection_filter"));
    this.selectedItems = requestNoObjectionFilter?.length > 0 ? requestNoObjectionFilter
      : [{ item_id: Status.active, item_text: 'Activos' },
      { item_id: condition.notsent, item_text: 'No enviadas' }];
    this.setFilterStorage();
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.canUseOption = true
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.generateReNumber();
        this.getDepartments();
        this.getPostulations();
        this.getCompanyByID(this.authInfo?.getCompanyId());
        this.getParameterRoutes();
      }
    });
    applySort();
  }

  private setFilterStorage() {
    const localStorageSearch: FilterEvent = JSON.parse(localStorage.getItem(this.searchparameterlocalstorage));
    this.searchParameter = localStorageSearch;
  }

  getParameterRoutes() {
    this._parameterControlService.getParameters(parameterDoc.PathTest).subscribe(e => {
      if (e.dataList.length > 0) {
        this.routeFile = e.dataList[0].stringData.toString();
      }
    });
  }

  generateReNumber() {
    let randomNumer = this.getRandomInt(10000000, 99999999);
    this.reNumber = `${randomNumer}`
  }

  openDashBoard(): void {
    this._dialog.open(DashboardNoObjectionComponent, {
      width: '100%'
    })
      .afterClosed()
      .subscribe((result) => {
      });
  }

  getPostulations() {
    if (!this.canUseOption) {
      return
    }
    const { page, pageSize } = this.employeesPagedResult
    const text = this.searchParameter?.value
    const propKeyId = !this.searchParameter?.value ? 0 : this.searchParameter?.propkeyId;

    const actives = this.selectedItems.find((x) => x.item_id === Status.active);
    const inactives = this.selectedItems.find((x) => x.item_id === Status.inactive);
    let status = 0;
    if ((!actives && !inactives) || (actives && inactives)) {
      status = -1;
    } else if (actives && !inactives) {
      status = 1;
    }

    const ableConditions = this.selectedItems
      ?.filter((x) => x.item_id !== Status.active && x.item_id !== Status.inactive)
      ?.map((e) => e.item_id)
      ?.join("-");

    const params = {
      propKeyId,
      status,
      conditions: ableConditions,
      page,
      pageSize,
      type: 2,
      searchParameter: text,
    };
    this._requestNoObjectionService.getPostulationsNoObjectionPaginated(params).subscribe((res: any) => {
      if (res.succeded) {
        this.employeesPagedResult = res.singleData
        const postulationNoObjection = res?.singleData?.items?.map(element => {
          element.conditionText = this.getConditionText(element.condition)
          element.statusText = element.status === true ? 'Activa' : 'Inactiva'
          element.isSelected = this.sendMapList?.some(s => s?.ppvid === element?.ppvid)
          return element;
        })
        this.tableData = [...postulationNoObjection];
        if (this.sendMapList.length > 0) {
          this.resetItemsSelection()
        }
        this.tableDataOriginal = res?.singleData?.items;
        // for (let index = 0; index < this.tableData.length; index++) {
        //   const element = this.tableData[index];
        //   element.conditionText = this.getConditionText(element.condition)
        //   element.statusText = element.status === true ? 'Activa' : 'Inactiva'
        //   element.isSelected =  this.sendMapList?.some(s => s?.ppvid === element?.ppvid)
        //   for (let index = 0; index < this.sendMapList.length; index++) {
        //     const e = this.sendMapList[index];
        //     if(element.isSelected){break}
        //     if(element.ppvid === e.ppvid){
        //       element.isSelected = true
        //     }else{
        //       element.isSelected = false
        //     }
        //   }
        // }

        /* for (let index = 0; index < this.sendMapList.length; index++) {
          const element = this.sendMapList[index];
          if(this.tableData.includes(element)){
            element.isSelected = true
          }else{
            element.isSelected = false
          }
        } */
      } else {
        this._toastService.error(res.errors[0])
      }
    }, error => {
      this._toastService.error(this.errorMessage)
    })
  }
  resetItemsSelection() {
    this.isAllChecked = false
    this.tableData.forEach(postulation => {
      postulation.isSelected = false
    });
    this.sendMapList = []
  }
  onDeSelectAll() {
    this.selectedItems = [];
    this.setFilterToLocalStorage();
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.setFilterToLocalStorage();
    this.filter();

  }
  onItemSelect(item: any) {
    this.setFilterToLocalStorage();
    this.filter();
  }
  onSelectAll(item: any) {
    this.setFilterToLocalStorage();
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    this.getPostulations()
    this.setFilterToLocalStorage();
  }
  setFilterToLocalStorage() {
    localStorage.setItem('request-no-objection_filter', JSON.stringify(this.selectedItems));

  }

  filterSearch(event: FilterEvent) {
    this.employeesPagedResult.page = 1
    this.searchParameter = event
    this.isAllChecked = false
    this.getPostulations()
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    $('.tooltip.show').removeClass("show");
  }

  selectItem(item) {
    if (this.sendMapList.some(send => send?.ppvid === item?.ppvid)) {
      this.sendMapList = this.sendMapList?.filter(send => send.ppvid !== item?.ppvid);
      if (this.sendMapList.length < 1) {
        this.isAllChecked = false
      }
    } else {
      this.sendMapList.push({
        ...item,
        isSelected: true,
      });
    }
    // const itemIndex = this.sendMapList.findIndex(value => {
    //   return value.ppvid === item.ppvid
    // });

    // if (itemIndex !== -1) {
    //   this.sendMapList.splice(itemIndex, 1);
    //   item.isSelected = false;
    // } else {
    //   item.isSelected = true;
    //   this.sendMapList.push(item);
    // }
  }

  selectAllItems(checked: boolean) {
    this.sendMapList = []
    this.isAllChecked = checked
    let itemsList = this.filteredList.enable ? this.filteredList.records : this.tableData
    let unselectedItems = itemsList.filter((item: any) => item.condition === 0 || item.condition === 5)
    if (checked) {
      this.sendMapList.push(...unselectedItems)
    }
    else {
      this.sendMapList = []
    }

    // console.log(this.sendMapList);
  }


  getCompanyByID(CompanyId) {
    this._requestNoObjectionService.getCompanyByID(CompanyId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.companySign = res.dataList[0];
    }, error => {
      this._toastService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.');
    })
  }

  private SwalFireInfo(message: string) {
    Swal.fire({
      icon: 'info',
      html:
        `<h2 style="font-weight: 600;font-size: 1.5rem;color: #181c32;">
        ${message}
      </h2>`,
      width: "1000px"
    });
  }


  getUserName(){
    return this.authInfo.getUser().firstName.trim() + ' ' + this.authInfo.getUser().lastName.trim()
  }


  sendData() {
    if (this.sendMapList.length < 1) {
      this._toastService.warning('Debe seleccionar un registro', 'Aviso');
      return;
    }
    if (this.companySign?.useDigitalSignature && !this.companySign?.entityCode) {
      this._toastService.warning('La institución no tiene una entidad asignada para la firma digital');
      return;
    }

    this.isSending = true;

    const personsIds = this.sendMapList?.map(s => s?.personId);

    this.employeeService.activeEmployeeInstitution(personsIds, true).subscribe((employeeInInstitutionResponse) => {

      if (!employeeInInstitutionResponse.succeded) {

        this.isSending = false;

        employeeInInstitutionResponse.errors.forEach(err => {
          this._toastService.error(err)
        });

        return;
      }


      let namesFound: string[] = [];

      this.sendMapList.forEach(element => {
        if (employeeInInstitutionResponse.dataList.find(x => x.personId == element.personId && x.status == true)) {
          namesFound.push(element?.personName);
        }
      })

      if (namesFound.length > 0) {
        namesFound = namesFound.map(n => {
          return `<strong><span class="text-primary">${n}</span></strong>`
        })

        let template: string = "";
        let title: string = ""

        if (namesFound.length === 1) {
          template = '<div class="text-left"><ul>';
          title = "La siguiente persona no puede ser nombrada dos veces:"
        }
        else {
          template = '<div class="text-left"><ul>';
          title = "Las siguientes personas no pueden ser nombradas dos veces:"
        }

        namesFound.forEach(name => {
          template += `<li><p>${name}</p></li><br>`
        });
        template += '</ul></div>';
        swalWarning(title, template, "warning", "1000px");

        this.isSending = false;
        return;
      }

      this._requestNoObjectionService.validateMapHeader(this.reNumber.toString()).subscribe((validateHeaderResponse) => {

        if (!validateHeaderResponse.succeded) {

          this.isSending = false;

          validateHeaderResponse.errors.forEach(err => {
            this._toastService.error(err)
          });

          return;
        }

        if (validateHeaderResponse.dataList.length > 0) {
          this.isSending = false;
          Swal.fire("Ya este número de remisión está registrado, intente con otro", "", "info");
          this.generateReNumber();
          return;
        }

        this.sendMapHeader = {
          sedMapHeaderId: 0,
          communicationId: this.reNumber.toString(),
          date: new Date(),
          dateEnd: new Date(),
          responseId: "1",
          dateResponse: new Date(),
          institutionId: this.authInfo.getCompanyId(),
          companyId: this.authInfo.getCompanyId(),
          status: true
        };

        this.sendMap = {
          sendMapId: 0,
          sedMapHeaderId: this.reNumber.toString(),
          postulationId: 0,
          positionApprovedId: 0,
          salaryRequested: 0,
          salaryApproved: 0,
          occupationalGroupId: 0,
          departmentId: 0,
          bondingType: 0,
          requestedDate: null,
          validyDate: null,
          observationMap: "",
          condition: 0,
          companyId: this.authInfo.getCompanyId(),
          status: true
        }

        let sendMapList: any[] = [];

        this._requestNoObjectionService.saveSendMapHeader(this.sendMapHeader).subscribe((saveMapHeaderResponse) => {

          if (!saveMapHeaderResponse.succeded) {
            this.isSending = false;

            saveMapHeaderResponse.errors.forEach(err => {
              this._toastService.error(err)
            });

            return;
          }


          this.sendMapList.forEach((element) => {
            // const lengthIndex = this.sendMapList.length - 1
            /* Recibe el sedMapHeaderId y se le coloca a cada elemento del array que se va a enviar */
            this.sendMap.sedMapHeaderId = saveMapHeaderResponse?.identity.toString() ?? "";
            this.sendMap.postulationId = element.ppvid;
            this.sendMap.companyId = element.companyId;

            sendMapList.push({
              ...this.sendMap,
              sedMapHeaderId: saveMapHeaderResponse.identity,
              postulationId: element.ppvid,
              companyId: element.companyId,
              createdByUserName: this.getUserName(),
              modifiedByUserName: this.getUserName()
            });
          });

          this._requestNoObjectionService.saveSendMap(sendMapList).subscribe({
            next: (saveSendMapResponse) => {


              let sendToSign = sendMapList?.map((send: any) => {
                return {
                  ...send,
                  ...this.tableDataOriginal?.find((x: any) => x?.ppvid == send?.postulationId),
                  requestedDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
                }
              });


              if (!saveSendMapResponse.succeded) {
                this.isSending = false;

                saveSendMapResponse.errors.forEach(err => {
                  this._toastService.error(err)
                });

                saveSendMapResponse.warnings.forEach(warn => {
                  this._toastService.warning(warn)
                });


                if (saveSendMapResponse.dataList.length > 0) {

                  //   let template = saveSendMapResponse.warnings.length === sendMapList.length ? '<ul>'
                  // : saveSendMapResponse.warnings.length > 0 ? '<h6>No se pudo realizar el envío debido a lo siguiente:</h6><ul>': '<ul>'

                  let template = '<h4><strong>No se pudo realizar el envío debido a lo siguiente:</strong></h4><div class="text-left"><ul>';

                  saveSendMapResponse.dataList.forEach(element => {
                    if (element.sent != true) {
                      const text = element.warningMessage?.split('-')
                      const id = Number(text[0] ??= '0')
                      template += `<li>${text[1]}</li> <a class="mt-3"  href="/candidate-registration/${id}" >
                      Ir al perfil  <i class="flaticon-usuario text-black"></i>
                      </a><br>`
                      sendToSign = sendToSign.filter(x => x.personId !== id)
                    }
                  });
                  template += `
                  </ul>
                  </div>
                  `;
                  swalWarning("Advertencia", template, "warning", "1000px");
                }

                this.generateReNumber();
                return;
              }



              this.sendMap.sedMapHeaderId = null;
              this.sendMap.postulationId = null;

              if (!this.companySign?.useDigitalSignature) {
                Swal.fire('Datos enviados correctamente', '', 'success')
                this.isSending = false;
                this.getPostulations();
                this.generateReNumber();
                return;
              }

              this.checkConfiguration(sendToSign, {
                ...this.sendMapHeader,
                comunicationId: this.sendMapHeader?.communicationId
              });

              this.generateReNumber();
            }, error: (error: any) => {

            }
          })
        }, (error) => {
          this.isSending = false;
          this._toastService.error("Error realizando el envío, favor de comunicarse con un Administrador")
        })
      }, error => {
        this.isSending = false;
        this._toastService.error("Error validando el número de remisión")
      });
    }, e => {
      this.isSending = false;
      this._toastService.error('Error validando los Servidores Públicos')
    })
  }

  checkResponseSendMap(sendMapList, alert: () => void) {
    this.reNumber = '';
    try {
      alert()
      this.sendMap.sedMapHeaderId = null;
      this.sendMap.postulationId = null;
      if (this.companySign?.useDigitalSignature && sendMapList.length > 0) {
        this.checkConfiguration(sendMapList, {
          ...this.sendMapHeader,
          comunicationId: this.sendMapHeader?.communicationId
        });
        return;
      } else {

        this.isSending = false;

        this.getPostulations();
      }
    } catch (e) {
      this.isSending = false;
    }
  }

  getPaginatedRecords(event: PaginationEvent) {
    const { selectedPage, registersPerPage } = event;
    const { employeesPagedResult } = this;
    if (employeesPagedResult.page === selectedPage && employeesPagedResult.pageSize === registersPerPage) {
      return;
    }
    this.employeesPagedResult.page = selectedPage;
    this.employeesPagedResult.pageSize = registersPerPage;
    this.getPostulations()
  }
  checkConfiguration(sendMapList, sendMapHeader) {
    if (!this.companySign?.entityCode) {
      this._toastService.warning('No se pudo enviar la petición de firma porque La institución no tiene una entidad asignada para la firma digital');
      return;
    }
    this.digitalSignService.getUserApprovals(this.authInfo.getCompanyId(), DigitalSignatureApproval.ApproveObjection).subscribe((res: any) => {
      if (!res.succeded) {
        this.isSending = false;
        this._toastService.error(res.errors[0]);
        return;
      }
      if (res?.singleResultData.approvalsIdentification?.length === 0
        && res?.singleResultData.signatureIdentification?.length === 0) {
        this.isSending = false;
        this._toastService.warning('No se encontró ninguna configuración de usuarios para la firma digital');
        this.getPostulations();
        Swal.fire('Datos enviados correctamente', '', 'success')
        return;
      }
      this.usersApprovals = res?.singleResultData;
      this.sendMapSign(sendMapList, sendMapHeader);
    }, error => {
      this.isSending = false;
      this._toastService.error('Ocurrió un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.');
    });
  }

  ResendRequestSign(item: any) {
    const sendMapHeader = {
      responseId: item?.responseId,
      dateEnd: item?.dateEnd,
      dateResponse: item?.dateResponse,
      companyName: this.authInfo?.getUser()?.companyName,
      comunicationId: item?.referralNumber
    }
    this.checkConfiguration([{ ...item, postulationId: item?.ppvid }], sendMapHeader);
  }

  private sendMapSign(sendMapList: any[], sendMapHeader: any) {
    const sendMapPdfSign: SendMapPdfSign = {
      responseId: sendMapHeader?.responseId,
      dateEnd: sendMapHeader?.dateEnd,
      dateResponse: sendMapHeader?.dateResponse,
      companyName: this.authInfo?.getUser()?.companyName,
      comunicationId: sendMapHeader?.comunicationId,
      personData: sendMapList?.map(send => {
        return {
          typeIdentificationId: send?.typeIdentificationId,
          personalIdentification: send?.personalIdentification,
          fullName: send?.personName,
          sex: send?.sex,
          requiredPosition: send?.positionName,
          academicLevel: send?.academicLevel,
          positionApproved: send?.positionApproved,
          salaryApproved: send?.proposedSalary,
          occupationalGroup: send?.occupationalGroup,
          departmentName: send?.departmentName,
          requestedDate: send?.requestedDate,
          observationMap: ""
        }
      })
    }
    this.srvCreateSign.getGetBase64ApprovalNoObjection(sendMapPdfSign).subscribe((resFile: any) => {
      let data = {
        SystemLoad: this.SystemLoad,
        ModuleLoad: this.ModuleLoad,
        personalFile: 1,
        routeFile: this.routeFile
      }
      if (resFile?.errors?.length > 0) {
        this.isSending = false;
        this._toastService.error(resFile?.errors[0]);
        return;
      }
      const { initDate, expirationDate } = resFile?.singleData;
      let formData = new FormData();
      fetch(`data:application/pdf;base64,${resFile?.singleData?.base64}`)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "No Objeción.pdf");
          formData.append("Aprobación No Objeción", file);
          const metadatas = [{ key: "RRHH-No-Objeción", value: 'No Objeción' } as Metadata]
          this.docService.createFile(data, formData).subscribe((result: any) => {
            if (result?.data?.path) {
              const documents = [
                {
                  filename: "Aprobación No Objeción.pdf",
                  url: result?.data?.path
                }];
              this.CreateSignRequest(metadatas, documents, sendMapList, 'No Objeción', initDate, expirationDate);
              return;
            }
            this.isSending = false;
          }, err => {
            this.isSending = false;
            this._toastService.error('Ha ocurrido un error al obtener el documento a firmar, por favor intente más tarde o comuníquese con HelpDesk.');
          }
          )
        })

    }, error => {
      this.isSending = false;
      this._toastService.error('Ha ocurrido un error al obtener el documento a firmar, por favor intente más tarde o comuníquese con HelpDesk.');
    })
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getTableData(showAlert = true) {

    this.getPostulations()

  }

  proofDetails(item: any) {
    this._dialog.open(ValidateProfileComponent, {

      data: {
        validationProfile: item,
      }
    });
  }

  selectionChanged(itemSelected) {
    if (itemSelected.value.departmentName) {
      this.selectedDepartment = itemSelected.value.departmentName;
      this.getTableData()
      return;
    }
    this.selectedDepartment = null;
    this.tableData = [];
  }

  getDepartments() {
    // Llenar el listado del dropdown de departamentos
    this._requestNoObjectionService.getDepartments().subscribe((res: any) => {
      this.departments = res.dataList;
    }, error => {
      this._toastService.error("Error al listar las Unidades Organizativas", "Error inesperado");
    })
  }

  getConditionText(condition: number): string {
    switch (condition) {
      case 0:
        return 'No enviada'
      case 1:
        return 'Enviada'
      case 2:
        return 'Aprobada por el MAP'
      case 3:
        return 'Aprobada por el MAP'
      case 4:
        return ''
      case 5:
        return 'Rechazada por el MAP'
      case 6:
        return 'Devuelta para Revisión'
      case 10:
        return 'Nombramiento Anulado'
      default:
        return ''
    }
  }


  exportToExcel() {
    for (let index = 0; index < this.tableData.length; index++) {
      const element = this.tableData[index];
      element.requestedDateFormatted = moment(element.requestedDate).isValid() === true ? moment(element.requestedDate).format('D/M/yyyy') : 'N/A'
      element.validyDateFormatted = moment(element.validyDate).isValid() === true ? moment(element.validyDate).format('D/M/yyyy') : 'N/A'
    }

    let excelHeaders: string[][] = [[
      "Secuencia",
      "Documento de Identidad	",
      "Nombre",
      "Cargo",
      "Unidad Organizativa	",
      "Condición de la No Objeción	",
      "Fecha de envío	",
      "Fecha de aprobación	",
      "Estatus",
    ], [
      "ppvid",
      "personalIdentification",
      "personName",
      "positionName",
      "departmentName",
      "conditionText",
      "requestedDateFormatted",
      "validyDateFormatted",
      "statusText",
    ]]
    this._excelService.exportToExcelSpecificColumns(this.tableData, excelHeaders, 'No Objeción', true)
  }


  onKeyChange(text) {
    this.filterText = text;
  }

  CreateSignRequest(metadatas, documents, sendMapList: ISendMap[], parameterName, initDate, expirationDate) {
    this.srvCreateSign.CreateSignRequest(
      metadatas,
      `Aprobación ${parameterName}`,
      `Aprobación ${parameterName}`,
      documents,
      [],
      this.getAddresseeGroups,
      initDate,
      expirationDate,
      this.companySign?.entityCode
    ).subscribe((data: any) => {
      if (data?.publicAccessId) {
        this.updatePublicAccessId(sendMapList, data?.publicAccessId);
      }
      if (data?.errorCode) {
        this.isSending = false;
        this._toastService.error('Ha ocurrido un error creando la petición de la firma');
      }
    }, error => {
      this.isSending = false;
      this._toastService.error(error?.error?.errorMessage ?? '', 'Ha ocurrido un error creando la petición de la firma');
    });
  }

  updatePublicAccessId(sendMapList: ISendMap[], publicAccessId: string) {
    const requestUpdatePublicAccessId = sendMapList.map(s => {
      return this._requestNoObjectionService.UpdatePublicAccessId(s?.postulationId, publicAccessId);
    });
    forkJoin(requestUpdatePublicAccessId).subscribe((res: any) => {
      this.isSending = false;
      if (res[0]?.errors?.length > 0) {
        this._toastService.error(res[0]?.errors[0]);
        return;
      }

      Swal.fire('Datos enviados correctamente', '', 'success')



      this._toastService.success('Firma digital enviada correctamente');

      this.getTableData(false);
    }, error => {
      this.isSending = false;
      this._toastService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.');
    });
  }

  get getAddresseeGroups() {
    const userEntitiesAPPROVAL = {
      isOrGroup: false,
      userEntities: this.usersApprovals?.approvalsIdentification.map(identification => {
        return {
          userCode: identification,
          entityCode: this.companySign?.entityCode,
          action: UserEntitiesAction.APPROVAL
        }
      })
    };
    const userEntitiesSIGN = {
      isOrGroup: false,
      userEntities: this.usersApprovals?.signatureIdentification.map(identification => {
        return {
          userCode: identification,
          entityCode: this.companySign?.entityCode,
          action: UserEntitiesAction.SIGN
        }
      })
    };
    let addresseeGroups = [
      {
        addresseeGroups: []
      }
    ];
    const isSignature = userEntitiesSIGN.userEntities.length > 0;
    const isApproval = userEntitiesAPPROVAL.userEntities.length > 0;
    if (isApproval) {
      addresseeGroups[0].addresseeGroups.push(userEntitiesAPPROVAL);
    }
    if (isSignature) {
      addresseeGroups[0].addresseeGroups.push(userEntitiesSIGN);
    }
    return addresseeGroups;
  }

  openEditNoObjectionModal(item: INoObjecionView) {
    this._dialog.open(EditNoObjectionModalComponent, {
      data: item
    }).afterClosed().subscribe(result => {
      if (result == true) {
        this.getTableData(false);
        try {
          this.generateReNumber();
        } catch (e) {

        }

      }
    })
  }

  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }


  openNoObjectionReport() {

    const actives = this.selectedItems.find((x) => x.item_id === Status.active);
    const inactives = this.selectedItems.find((x) => x.item_id === Status.inactive);
    const status: number = ((!actives && !inactives) || (actives && inactives)) ? -1 : ((actives && !inactives) ? 1 : 0);

    const ableConditions = this.selectedItems
      ?.filter((x) => x.item_id !== Status.active && x.item_id !== Status.inactive)
      ?.map((e) => e.item_id)
      ?.join("-");

    const propKeyId: number = this.searchParameter?.propkeyId ?? 0;
    const searchParameter: string = this.searchParameter?.value ?? '';
    
    const page: number = this.employeesPagedResult?.page;
    const pageSize: number = this.employeesPagedResult?.pageSize;
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.NoObjection;
    
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&PropkeyId=${propKeyId}&SearchParameter=${searchParameter}&Status=${status}&Conditions=${ableConditions}&Type=${2}&Page=${page}&PageSize=${pageSize}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Servidores Públicos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

}
