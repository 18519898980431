<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="fs-3 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px "></span> 
            Editar No-Objeción
    </h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>

<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <div class="gutter-b ml-n5 mr-n5 row mt-n5">
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table id="" class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th>
                                    <span>Número de remisión</span>
                                </th>
                                <th style="text-align: left !important;">
                                    <span>Nombres y Apellidos</span>
                                </th>
                                <th style="text-align: left !important;">
                                    <span>Unidad Organizativa</span>
                                </th>
                                <th style="text-align: left !important;">
                                    <span> Puesto Solicitado</span>
                                </th>
                                <th class="text-center">
                                    <span>Cédula</span>
                                </th>
                                <th>
                                    <span>Salario Solicitado</span>
                                </th>
                                <th>
                                    <span>Fecha Solicitud</span>
                                </th>
                                <th>
                                    <span>Condición de Aprobación</span>
                                </th>
                                <!-- <th class="actionsBtn">
                                    <span>Acciones</span>
                                </th> -->
                            </tr>
                        </thead>
                        <ng-template [ngIf]="mapDataList">
                            <tbody>
                                <tr *ngFor="let row of mapDataList">
                                    <td>
                                        {{row.communicationId}}
                                    </td>
                                    <td style="text-align: left;">
                                        {{row.fullName}}
                                    </td>
                                    <td>
                                        {{row.departmentName}}
                                    </td>
                                    <td style="text-align: left;">
                                        {{row.requiredPosition}}
                                    </td>
                                    <td style="text-align: left; min-width: 125px;">
                                        <ng-container *ngIf="row.typeIdentificationId == 1">{{row.personalIdentification
                                            |
                                            mask: '000-0000000-0'}}</ng-container>
                                        <ng-container *ngIf="row.typeIdentificationId != 1">
                                            {{row.personalIdentification}}
                                        </ng-container>
                                    </td>
                                    <td>
                                        <span class="font-weight-bold text-warning">{{row.salaryRequested |
                                            currency:"RD$"}}</span>
                                    </td>
                                    <td>
                                        {{row.requestedDate |date: 'dd/MM/yyyy' }}
                                    </td>
                                    <td class="text-center">
                                        <i class="flaticon-mala-critica icon-2x text-warning"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <form [formGroup]="postulationForm">
                <div >
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label>Comentario<span class="text-danger text-monospace legend"></span></label>
                            <textarea class="form-control form-control-solid form-control-lg" 
                            [value]="mapDataList[0]?.rejectComment" disabled>
                            </textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Salario Solicitado<span class="text-danger text-monospace legend"></span></label>
                                    <input class="form-control form-control-solid form-control-lg" type="text" currencyMask
                                        [value]="((postulationSelected)?postulationSelected.proposedSalary:0) | currency"
                                        disabled>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Fecha de Ingreso Solicitada<span
                                            class="text-danger text-monospace legend"></span></label>
                                    <input class="form-control form-control-solid form-control-lg" type="text"
                                        [value]="(postulationSelected?.dateAvailability |date: 'dd/MM/yyyy') || null" disabled>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Salario Propuesto<span class="text-danger text-monospace legend"> *</span></label>
                                    <div class="input-group">
                                        <input currencyMask [options]="{ prefix: '$ ', thousands: ',', decimal: '.' }"
                                            (keyup)="onSalaryError()" class="form-control  form-control-solid form-control-lg"
                                            placeholder="0.00" [formControl]="postulationForm.controls['proposedSalary']" type="text"
                                            [value]="((postulationSelected)?postulationSelected.proposedSalary :0) | currency" />
                                        <button class=" input-group-text bg-primary" (click)="openSalaryRangeComponent()">
                                            <i class="flaticon-ver icon text-white" data-toggle="tooltip"
                                                title="Sugerencia salarial"></i>
                                        </button>
                                    </div>
                                    <span *ngIf="this.postulationForm.get('proposedSalary').invalid" class="text-danger">{{
                                        showApprovedSalaryErrors() }}</span>
                                    <span *ngIf="isSalaryInvalid" class="font-weight-bold text-danger ml-3">El salario
                                        aprobado debe estar dentro del rango salarial establecido</span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Fecha de Ingreso Propuesta<span
                                            class="text-danger text-monospace legend"> *</span></label>
                                    <input class="form-control form-control-solid form-control-lg" type="date"
                                        [formControl]="postulationForm.controls['dateAvailability']"  [value]="(postulationSelected?.dateAvailability |date: 'dd/MM/yyyy') || null" >
                                    <span class="form-text text-danger" *ngIf="postulationForm.invalid && showErrors.dateAvailability">
                                        Este campo es requerido
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Documento adjunto <span class="text-danger text-monospace legend">*</span></label>
                                <uppy-file #uppyFile
                                    [width]="350"
                                    [height]="138"
                                    [showDocumentoAfterUploaded]="false"
                                    cardBody="''"
                                    previewDefaultImage="assets/images/ver_file.jpg"
                                    classPreviewDefaultImage="symbol"
                                    showFileModalTitle="Documento adjunto"
                                    [allowedFileTypes]="['application/pdf']"
                                    (fileEmitter)="setFile($event)"
                                    >
                                </uppy-file>
                                <span class="form-text text-danger" *ngIf="postulationForm.invalid && !uppyFileComponent?.existFile">
                                    Este campo es requerido
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button #closeModal (click)="this.dialogRef.close();"
        class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2" data-dismiss="modal">
        <i class="flaticon2-cross"></i>
        <span>Cerrar</span>
    </button>
    <button 
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" (click)="onSubmit()" type="submit"
        [disabled]="isSalaryInvalid ||  postulationForm.invalid">
        <i class="flaticon-disco-flexible"></i>
        <span> Guardar y Reenviar </span>
    </button>
</div>