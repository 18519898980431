import { DropdownModel } from "../shared/interface/dropdown-model";

export interface EmployeeChangeTable {
    employee: string,
    employeeId: number;
    department: string;
    position: string;
    employeeType: string;
    changeType: string;
    motive: string;
    efectiveDate: string;
}

export const employeeChangeTableData: EmployeeChangeTable[] = [
    {employeeId: 1, employeeType: 'Administrativo', changeType: 'Sueldo', motive: 'Promoción',
    department: 'TIC', position: 'Programador', efectiveDate: '12-01-2021', employee: 'Mario Rojas'}
]

export const employeeExitTableData = [
    {employeeId: 1, employeeType: 'Administrativo', changeType: 'Sueldo', motive: 'Termino de Nombramiento',
    department: 'TIC', position: 'Programador', exitEfectiveDate: '05-19-2021', employee: 'Mario Rojas'}
]

export interface EmployeeChangeOptions {
    name: string;
    options: Array<DropdownModel>;
    type: string;
    label: string
}

export const employeeChangeData: EmployeeChangeOptions[] = [
    {name: 'department',label: 'Unidad Organizativa', type: 'dropdown', options: [
        {value: 1, text: 'TIC'},
        {value: 2, text: 'Académico'}
    ] },
    {name: 'position',label: 'Posición', type: 'dropdown', options: [
        {value: 1, text: 'Profesor'},
        {value: 2, text: 'Recepcionista'}
    ]},
    {name: 'mapPosition',label: 'Posición MAP', type: 'dropdown', options: [
        {value: 1, text: 'Técnico Programador'},
        {value: 2, text: 'Consultor'}
    ]},
    {name: 'employeeType',label: 'Tipo Servidor Público', type: 'dropdown', options: [
        {value: 1, text: 'Nombramiento'},
        {value: 2, text: 'Fijo'}
    ]},
    {name: 'contractType',label: 'Tipo Nombramiento', type: 'dropdown', options: [
        {value: 1, text: 'Temporal'},
        {value: 2, text: 'Indefinido'}
    ]},
    {name: 'salary',label: 'Sueldo', type: 'number', options: []},
    {name: 'bankingEntity',label: 'Entidad Bancaria', type: 'dropdown', options: [
        {value: 1, text: 'Banreservas'},
        {value: 2, text: 'BHD'}
    ]},
    {name: 'bankAccount',label: 'Cuenta Bancaria', type: 'text', options: []},
    {name: 'location',label: 'Recinto', type: 'dropdown', options: [
        {value: 1, text: 'Caleta'},
        {value: 2, text: 'Santiago'}
    ]},
    {name: 'benefits',label: 'Beneficios', type: 'dropdown', options: [
        {value: 1, text: 'Bonos'},
        {value: 2, text: 'Doble Sueldo'}
    ]},
    {name: 'afp',label: 'AFP', type: 'dropdown', options: [
        {value: 1, text: 'Siembra'},
        {value: 2, text: 'Crecer'}
    ]},
    {name: 'ars',label: 'ARS', type: 'dropdown', options: [
        {value: 1, text: 'ARS Humano'},
        {value: 2, text: 'Académico'}
    ]},
    {name: 'contractStart',label: 'Inicio de Nombramiento', type: 'date', options: []},
    {name: 'contractEnd',label: 'Expiración del Nombramiento', type: 'date', options: []},
]

