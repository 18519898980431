import { ɵNullViewportScroller } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-add-phone',
  templateUrl: './add-phone.component.html',
  styleUrls: ['./add-phone.component.css']
})
export class AddPhoneComponent implements OnInit {

  phoneTypeList: any[] = []
  principal = { yes: 1, no: 2 }
  phoneList: any[] = []

  phoneForm: FormGroup = new FormGroup({
    personPhoneId: new FormControl(null),
    phoneType: new FormControl(null, Validators.required),
    phoneNumber: new FormControl(null, Validators.required),
    isPrimary: new FormControl(null, Validators.required),
    extension: new FormControl(null),
  })

  constructor(
    public dialogRef: MatDialogRef<AddPhoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _toast: ToastService,
    private _auth: AuthInfoService
  ) {
    this.dialogRef.disableClose = true;
    this.phoneList = data.phoneList
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.phoneTypeList = this.data.phoneTypeList
    if (this.data.isEditing) {
      this.getPhoneInfo()
      return
    }
  }

  getPhoneInfo() {
    const { phoneInfo } = this.data
    this.phoneForm.patchValue({
      personPhoneId: phoneInfo.personPhoneId,
      phoneType: this.phoneTypeList.find((type: any) => type.ocode === phoneInfo.type),
      phoneNumber: phoneInfo.description,
      isPrimary: phoneInfo.principal,
      extension: phoneInfo.phoneExtension
    })
  }

  Cancel() {
    this.dialogRef.close()
  }

  Accept() {
    if (this.phoneForm.invalid) {
      return
    }
    let existingPhone = this.phoneList
    ?.some((phone: any, index) => phone.description === this.phoneForm.get('phoneNumber').value
    && index != this.data?.indexEditing)
    if (existingPhone) {
      this._toast.warning('Este número de teléfono ya está agregado')
      return
    }
    let phoneInfo = {
      ...this.phoneForm.value
    }
    this.dialogRef.close(phoneInfo)
  }


}
