import { AfterViewChecked, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { DepartmentService } from 'src/app/services/department.service';
import { ICompanyStructure } from '../models/CompanyStructure';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { CaptionEventModel } from 'src/app/shared/element-ui/table/caption/models/caption-event-model';
declare function applySort(): any;

@Component({
  selector: 'app-deparments-list',
  templateUrl: './deparments-list.component.html',
  styleUrls: ['./deparments-list.component.css']
})
export class DeparmentsListComponent implements OnInit {

  //privilegio incompleto
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'DEPART',
    exportAction: 'EXPORT-D'
  }

  selectedItems: any = [];

  localStorageSearch:string ='app-deparments-list';
  hasReportedChange: boolean;
  departmentsActives: any[];
  departmentsListOriginal: any[] = [];
  subscriptions: Subscription = new Subscription();
  dataList: any[] = [];
  companies: any[] = [];
  records: any[] = [];
  recordsPaginated: any[] = [];
  selectedCompany: ICompanyStructure;
  formData: { formBody: any } = {
    formBody: {
      companyId: 0,
      locationsId: 0,
      idDepartment: 0,
      departmentName: '',
      supervisorId: 0,
      idSection: 0,
      levelSection: 0,
      parentSection: 0,
      head: '',
      afterId: 0,
      beforeId: 0,
      depthLevel: 0,
      parentSection2: 0,
      hasChildren: false,
      disableDrag: false,
      disableDragDest: false,
      status: true
    }
  }
  showInactives = false;
  type = '';
  text = '';
  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = this.filterTable(event.records);
  }
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propKeyId?: number, propkey?: string, displayName: string }[] =
    [
      { propKeyId: 2, propkey: "departmentName", displayName: "Unidad Organizativa" },
      { propKeyId: 1, propkey: "departmentId", displayName: "Secuencia" },
      { propKeyId: 3, propkey: "parentSectionName", displayName: "Unidad Organizativa Supervisora" },
    ];
  config = {
    displayKey: 'companyName',
    placeholder: 'Seleccione una institución',
    search: true,
    searchPlaceholder: 'Buscar',
    searchOnKey: 'companyName'
  };

  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' },
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  filterText = '';
  searchPropKeySelected!:{ propKeyId?: number, propkey?: string, displayName: string };

  registersPerPage: number;
  selectedPage: number;

  constructor(
    private _toastr: ToastrService,
    private srvExportToExcel: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private _departmentService: DepartmentService,
    public authInfo: AuthInfoService,
    private companyService: CompaniesService
  ) {

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {

    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result=>{
      if(result == true){
        this.getCompanies();
        this.getData();
      }
    });
    applySort();
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  getData() {
    let sub = this._departmentService.getAllApproved(this.authInfo.getCompanyId()).subscribe((data: any) => {
      if (data.succeded) {
        this.dataList = data.dataList.length > 0 ? this.filterTable(data.dataList) : [];
        this.departmentsListOriginal = data.dataList;
        this._cdRef.detectChanges();
      } else {
        this._toastr.error(data.errors[0]);
      }
    }, error => {
      this._toastr.error('Ocurrió un error al cargar los datos, por favor intente más tarde o comuníquese con HelpDesk.');
    });
    this.subscriptions.add(sub);

  }

  exportToExcell() {
    let excelHeaders: string[][] = [[
      "Secuencia de la Institución",
      "Siglas de la Institución",
      "Razón Social de la Institución",
      "Secuencia de la Unidad Organizativa",
      "Nombre de la Unidad Organizativa",
      "Unidad Organizativa Supervisora",
      "Supervisor",
      "Secuencia del la Sucursal",
      "Estatus",
    ]]
    let departmentList: any[] = [];
    let array = this.dataList
    if(this.filteredList.enable){
      array = this.filteredList.records
    }
    array.forEach((e) => {
      departmentList.push({
        companyId: e.companyId,
        shortNameCompany: e.shortNameCompany,
        socialReason: e.socialReason,
        departmentId: e.departmentId,
        departmentName: e.departmentName,
        parentSectionName: e.parentSectionName ? e.parentSectionName : "Ninguno",
        supervisor: e.supervisor,
        locationsId: e.locationsId,
        status: e.status ? "Activa" : "Inactiva",
      })
    })
    this.srvExportToExcel.exportToExcelSpecificColumns(departmentList, excelHeaders, 'Lista de Unidades Organizativas');
  }

  filter() {
    document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.dataList = this.filterTable(this.departmentsListOriginal)
    }
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    list = this.sortArray(list)
    if (sl.find(x => x.item_id == 1) && !sl.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl.find(x => x.item_id == 2) && !sl.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }


    return list;
  }

  // departmentsToggle() {
  //   this.dataList = [];
  //   this.showInactives = !this.showInactives;

  //   if (this.selectedCompany?.companyId) {
  //     this.getData(this.selectedCompany.companyId);
  //   }
  // }

  getCompanies(): any {
    let sub = this.companyService.getCompanies().subscribe((data: any) => {
      if (data.succeded) {
        this.companies = data.dataList;
      } else {
        this._toastr.error(data.errors[0]);
      }
    }, error => {
      this._toastr.error('Ocurrió un error al cargar las compañías, por favor intente más tarde o comuníquese con HelpDesk.');
    });
    this.subscriptions.add(sub);
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  onKeyParameterChange(value: { propKeyId?: number, propkey: string, displayName: string } | null) {
    this.searchPropKeySelected = value ?? null;
  }

  onKeyChange(text:string) {
    this.filterText = text ?? '';
  }


  getPaginatedRecords(event?: CaptionEventModel | null) {
    this.recordsPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];

    this.registersPerPage = event?.registersPerPage;
    this.selectedPage = event?.selectedPage;
  }

  sortArray(list: Array<any>) {
    list.sort(function (a, b) {
      if (a.departmentId > b.departmentId) {
        return -1;
      }
      else if (a.departmentId < b.departmentId) {
        return 1;
      }
      return 0;
    });
    return list
  }

  openDepartmentReport() {
    const companyId: number = this.authInfo.getCompanyId();
    const status:string = this.selectedItems.length > 0 ? this.selectedItems.map((value: any) => {
      return `${value?.item_id == 1 ? 1 : 0}`
    }).join("-") : '';
    const conditions:string =  '2'; //2 = aprobado
    const reportCode: ReportCode = ReportCode.Exp_OrginazacionalUnit;
    
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&Status=${status}&Conditions=${conditions}&PropkeyId=${this.searchPropKeySelected?.propKeyId ?? 0}&SearchParameter=${this.filterText ?? ''}&Page=${this.selectedPage}&PageSize=${this.registersPerPage}`;
    
    let parameters = {
      url: reportUrl,
      title: 'Reporte Unidades Organizativas',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

}
