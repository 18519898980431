export interface RecruitmentQuestions {
    recruitmentQuestionsId: number;
    question: string;
    status: boolean;
    orderToDisplay: number;
    createDate: Date;
    createUserId: number;
    modifyDate: Date;
    modifyUserId: number;
    companyId: number;
    quetionTypeDesc: string;
    questionTypeId: number;
    answer?: boolean;
    comment?: string;
}

export enum QuestionType {
    AdditionalInformation = 1
}