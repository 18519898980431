import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { forkJoin, Subscription } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';
import { changeDetails, changeHeader, EmployeeChange, IAdditionalValue } from './models/employee-change';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { docService } from 'src/app/services/doc.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { RequestMetaData, ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { EmployeeEntry, EmployeeEntryListHistory } from '../../job-offer/models/Employee';
import { IExitList } from './models/ExitList';
import { environment } from 'src/environments/environment';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { Location } from '@angular/common';
import { IActionsEmployeesGET } from './models/ActionsEmployees';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { DepartmentService } from 'src/app/services/department.service';
import { CreateSignRequestService, EmployeeChangeBase64 } from 'src/app/services/create-sign-request.service';
import { IEmployee } from 'src/app/models/Employee.model';
import { Metadata } from 'src/app/models/sign-request.model';
import { UserEntitiesAction } from 'src/app/models/enums-sign.model';
import { FormActions } from './models/form-actions.model';
import { DigitalSignService } from 'src/app/services/digital-sign.service';
import { DigitalSignatureApproval } from 'src/app/models/digital-signature-approval.model';
import { AbstractControl } from '@angular/forms';
import { JobOfferService } from 'src/app/services/job-offer.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { EmployeeCondition } from 'src/app/shared/models/employee-condition.model';
import { personnelActionTypes } from '../personnel-actions/personnel-actions-tap/personnel-actions-tap.component';
import { DeclarationModalComponent } from './declaration-modal/declaration-modal.component';
import { ShowSwornDeclarationComponent } from './show-sworn-declaration/show-sworn-declaration.component';
import { NgxSelectDropdownComponent } from 'ngx-select-dropdown';
import * as moment from 'moment';
import { CompaniesService } from 'src/app/services/companies.service';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

enum changesMap {
  salary = 4,
  position = 2,
  contratType = 3,
  employeeType = 13
}

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-approval-employee-change-actions',
  templateUrl: './approval-employee-change-actions.component.html',
  styleUrls: ['./approval-employee-change-actions.component.css'],
})
export class ApprovalEmployeeChangeActionsComponent implements OnInit, AfterViewChecked, OnDestroy {
  userFullName = ''
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'ACTION-CHANGE-EMPLOYEE',
    changeActionsPermanent: { key: 'Change-Actions-Permanent', value: false },
    apply: { key: 'Apply-Change-Actions-Permanent', value: false },
    edit: { key: 'Edit-Change-Actions-Permanent', value: false },
    viewPdfChange: { key: 'View-Pdf-Change-Actions-Permanent', value: false },
    viewEvidence: { key: 'View-Evidence-Change-Actions-Permanent', value: false },
    viewRejectionReason: { key: 'View-Rejection-Reason-Change-Actions-Permanent', value: false },
    resendSignatureRequest: { key: 'Resend-Signature-Request-Change-Actions-Permanent', value: false },
    recurringActions: { key: 'Recurring-Actions', value: false },
    addUnlinkEmployees: { key: 'Add-Unlink-Employees', value: false },
    entryList: { key: 'Entry-List', value: false },
    AddEntryList: { key: 'Add-Entry-List', value: false },
    ViewEntryList: { key: 'View-Entry-List', value: false },
    ViewSwornDeclarationEntryList: { key: 'View-Sworn-Declaration-Entry-List', value: false }
  }
  userApprovalsPosition: any = [];
  userApprovalsSalary: any = [];

  public photoUrl: string = ''
  public loadingPic: boolean = true
  newChanges: changeDetails[] = [];
  changeDeyailsListRecords: any[] = []; /* Para paginar  */
  changeDetailsList: any[] = [];
  changeDeyailsListRecordsOriginal: any[] = [];
  employeeId: number = 0;
  datePie = new DatePipe('en-Us')
  fields: any[] = []
  departments: any[] = []
  positions: any[] = []
  afp: any[] = []
  ars: any[] = []
  banks: any[] = []
  contractTypes: any[] = []
  locations: any[] = []
  designations: IActionsEmployeesGET[] = []
  designationsPaginated: IActionsEmployeesGET[] = []
  motives: any[] = []
  modificationReasons: ParameterControl[] = []
  statusValidation: any[] = [];
  fieldType: any = 'drop';
  additionalValueFieldName: string = '';
  personSelected: IEmployee = {
    typeIdentificationId: 0,
    employeeSupervisor: ''
  };
  exitlists: IExitList[] = [];
  subscriptions: Subscription = new Subscription()
  employeeChangeDTO = new EmployeeChange()
  changeHeaderDTO: changeHeader
  inViewDetails: boolean = false;
  newChangesRecords: any[] = [];
  newChangesRecordsOriginal: any[] = [];
  formIsInvalid: boolean = false;
  parameterType: number;
  employeeEntryForm: FormGroup = new FormGroup({
    employeeEntryList: new FormArray([]),
    employeeEntryListCopy: new FormArray([]),
  });
  parameterName: string
  effectiveDateIsInvalid = false
  effectiveDateError: string = ''
  employeeTypeList: any[] = [];
  additionalValueList: IAdditionalValue[] = [];
  budgetAreas = []
  entryListChanges: any[] = [];
  entryListChangesPaginated: any[] = [];
  routes = ['/employees', '/employee-personnel-actions-list']
  routeType = 0
  entryListParameterControl: any[] = [];
  jobOfferBenefitsToggle: ParameterControl[] = [];
  actionChangeForm = new FormGroup({
    employee: new FormControl('', Validators.required),
    employeeName: new FormControl('', Validators.required),
    currentData: new FormControl({ value: '', disabled: true }),
    currentDataId: new FormControl({ value: null, disabled: true }),
    newData: new FormControl({ value: '', disabled: true }, Validators.required),
    newDataId: new FormControl({ value: null, disabled: true }),
    additionalValueObject: new FormControl({ value: null, disabled: true }),
    additionalValue: new FormControl({ value: null, disabled: true }),
    additionalValueName: new FormControl({ value: null, disabled: true }),
    parameter: new FormControl({ value: '', disabled: true }, Validators.required,),
    motive: new FormControl({ value: '', disabled: true }, Validators.required),
    effectiveDate: new FormControl({ value: '', disabled: true }, Validators.required),
    evidence: new FormControl({ value: '', disabled: true }, Validators.required),
    comment: new FormControl({ value: '', disabled: true }),
    condition: new FormControl([0]),
    employeeChangeDetailsId: new FormControl([0]),
    employeeChangeHeaderId: new FormControl([0]),
    dateApproved: new FormControl([null]),
    createUserId: new FormControl(0),
    newModificationComment: new FormControl({ value: null, disabled: true }),
    newModificationReason: new FormControl({ value: null, disabled: true }),
    oldModificationComment: new FormControl({ value: null, disabled: true }),
    oldModificationReason: new FormControl({ value: null, disabled: true })
  }, { validators: this.ValidateNewData });

  admissionDate: string = ""
  guiID = '';
  file = { document: '', entryListEvidence: '' }
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  personalFile = personalFile;

  EntryListEvidenceUppy = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['application/pdf']
    },
    locale: Mexico
  });
  localStorageTab = 'personnel-actions-tab-selected'
  localStorageTab1 = 'action-change-tab-selected'

  private readonly urlDoc = environment.api_doc + '/Document';

  public isSalaryCorrect: boolean = true;

  companySign: any;

  isSeviceCommission: boolean = false;
  isSuspended: boolean = false;

  currentEmployeeCondition: EmployeeCondition;
  currentDate = new Date();
  isEdittingEmployeeChange: boolean = false;
  currentChangeConditionOnEditting!: number;
  oldModificationReasonSelected: any;

  @ViewChild('uppyFile')
  uppyFileComponent: UppyFileComponent | null = null;
  constructor(
    private actionPSrv: PersonnelActionsService,
    private srvParam: ParameterControlService,
    private dialog: MatDialog,
    private _toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private _cdRef: ChangeDetectorRef,
    private srvDoc: docService,
    private _parameterCtrlService: ParameterControlService,
    private _builder: FormBuilder,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private location: Location,
    public authInfo: AuthInfoService,
    private employeeService: EmployeesService,
    private departmentService: DepartmentService,
    private srvCreateSign: CreateSignRequestService,
    private digitalSignService: DigitalSignService,
    private jobOfferService: JobOfferService,
    private router: Router,
    private companiesService: CompaniesService
  ) { }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();

  }

  setPrivileges() {
    const privileges = this.privilege
    this.privilege = this.authInfo.setPrivileges(privileges);
  }
  getCompanyByID(CompanyId) {
    this.actionPSrv.getCompanyByID(CompanyId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.companySign = res.dataList[0];
    }, error => {
      this._toastService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.');
    })
  }

  goBack() {
    const route = this.routes[this.routeType]
    if (route) {
      this.router.navigate([route])
    }
  }

  getUnlinkEmitter($event) {
    this.personSelected.status = !$event;
    if (this.personSelected.status == false || this.isSeviceCommission) {

      this.disableAll()

      //  const dashboard = this.evidenceUppy.getPlugin('Dashboard')
      //  dashboard.setOptions({ disabled: true })
    }
  }

  config: any = {
    placeholder: 'Seleccione una opción',
    noResultsFound: 'No se han encontrado registros'
  }

  additionalValueConfig: any = {
    displayKey: 'additionalValueName',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar',
    noResultsFound: 'No se han encontrado registros'
  }

  motivesConfig = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar',
    noResultsFound: 'No se han encontrado registros'
  }

  fieldsConfig = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar',
    noResultsFound: 'No se han encontrado registros'
  }
  options: any[] = []

  ngOnInit(): void {
    this.userFullName = this.authInfo?.getUserFullName()
    this.setPrivileges()
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result) {

        const ls = localStorage.getItem(this.localStorageTab1)
        if (ls) {
          document.getElementById(ls).click()
        }
        const lsa = localStorage.getItem(this.localStorageTab)
        if (lsa) {
          document?.getElementById(lsa)?.click()
        }
        this.getEntryListParameter();
        this.activatedRoute.params.subscribe((params: any) => {
          this.employeeId = +params.employeeId;
          this.getData()
          this.routeType = params.type
        })
      }
    })
  }

  getDesignations() {
    if (!this.personSelected) return
    this.actionPSrv.getDesignations(this.personSelected.employeeId).subscribe((res: any) => {
      if (res.succeded) {
        this.designations = res.dataList
      } else {
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }

  getParameterRoutes() {
    this._parameterCtrlService.getParameters(parameterDoc.PathTest).subscribe(e => {
      if (e.dataList.length > 0) {
        this.routeFile = e.dataList[0].stringData.toString();
      }
    })
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openGuID(guid) {
    try {
      this.srvDoc.getDocument(guid).subscribe(e => {
        if(!e.succeded){
          this._toastService.error('Ha ocurrido un error tratando de consultar el documento seleccionado.')
          return;
        }
        window.open(e.data, '_blank');
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  setEvidence(file){
    this.actionChangeForm.get('evidence').setValue(file);
  }

  manageFiles(file, position) {

    if (this.personSelected?.status == false || this.isSeviceCommission) {
      return
    }

    let data = {
      SystemLoad: this.SystemLoad,
      ModuleLoad: this.ModuleLoad,
      personalFile: this.personalFile.photo2x2,
      routeFile: this.routeFile
    }

    let formData = new FormData
    formData.append(file.name, file)
    this.srvDoc.createFile(data, formData).subscribe((result: any) => {
      if (result.errors.length > 0) { this._toastService.error(result.errors[0], "Error al tratando de subir el archivo"); return; }
      if (result.succeded) {
        let resData = result.data as FileResponse
        this.guiID = this.getGuid(resData.fileUnit.guidname)
        this.file.entryListEvidence = resData.path;
        this.employeeEntryForm.get('employeeEntryList')["controls"][position]?.get('evidence').setValue(this.guiID ? this.guiID : null)
        this.employeeEntryForm.get('employeeEntryListCopy')["controls"][position]?.get('evidence').setValue(this.guiID ? this.guiID : null)
      }
    }, err => { this._toastService.error(err, "Error al tratando de subir el archivo") }
    )
  }


  showEvidenceErrors() {
    let field = this.actionChangeForm.get('evidence');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  getData() {
    let sub = forkJoin([
      this.actionPSrv.getPersonalActions(),
      this.actionPSrv.getDepartments(this.authInfo.getCompanyId(), 2),
      this.actionPSrv.getPositions(),
      this.actionPSrv.getBankingEntity(),
      this.actionPSrv.getAFP(),
      this.actionPSrv.getARS(),
      this.actionPSrv.getContractType(),
      this.companiesService.getCompanies(),
      this.actionPSrv.getActionMotives(),
      this.actionPSrv.getStatusGlobalValidation(),
      this.employeeService.getEmployeeActionsByEmployeeId(this.employeeId),
      this.actionPSrv.getModificationReasons()
    ]).subscribe((responses) => {
      responses.forEach((response: ResponseModel<any>, i: number) => {
        if (response.succeded) {
          switch (i) {
            case 0:
              this.fields = response.dataList;
              break;
            case 1:
              this.departments = response.dataList;
              break;
            case 2:
              this.positions = response.dataList;
              break;
            case 3:
              this.banks = response.dataList;
              break;
            case 4:
              this.afp = response.dataList;
              break;
            case 5:
              this.ars = response.dataList;
              break;
            case 6:
              this.contractTypes = response.dataList;
              break;
            case 7:
              this.locations = response.dataList;
              break;
            case 8:
              this.motives = response.dataList;
              break;
            case 9:
              this.statusValidation = response.dataList;
              break;
            /*case 10:
              this.entryListParameterControl = response.dataList;
              break;*/
            case 10:
              this.currentEmployeeCondition = response?.dataList[0] as EmployeeCondition;
              this.isSeviceCommission = (response?.dataList as EmployeeCondition[])?.some(employeeCondition => employeeCondition?.typePersonalActionId === personnelActionTypes.commission);
              this.isSuspended = (response?.dataList as EmployeeCondition[])?.some(employeeCondition => employeeCondition?.typePersonalActionId === personnelActionTypes.disciplinaryMeasures);

              if(this.isSuspended){
                this.employeeEntryForm.disable();
              }
              break;
            case 11:
              this.modificationReasons = response.dataList;
              break;
          }
        } else {
          this._toastService.error(`Ha ocurrido un error cargando el recurso ${i}`);
        }
      });
      this.getEmployeeByNumber()
    });
    this.subscriptions.add(sub);
  }
  getDetails(id) {
    this.actionPSrv.getChangeDetails(id, 3).subscribe((data: any) => {
      if (data.errors.length > 0) { this._toastService.error("Error al obtener los detalles"); return; }
      this.newChanges = data.dataList
      this.newChangesRecordsOriginal = [...data.dataList]
    })

  }
  showDetails(item) {
    this.inViewDetails = true;
    let id = item.employeeChangeHeaderId;
    this.getDetails(id)
  }


  getDropDownConfig(fieldCode: number) {
    switch (fieldCode) {
      case 1: //'Departamento'
        this.config = {
          displayKey: 'departmentName',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 2: //'Posición/Cargo'
        this.config = {
          displayKey: 'positionName',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 3: //'Tipo Contratación'
        this.config = {
          displayKey: 'stringData',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 13: //'Tipo Servidor Público'
        this.config = {
          displayKey: 'description',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      // case 'Entidad Bancaria':
      //   this.config = {
      //     displayKey: 'stringData',
      //     placeholder: 'Seleccione una opción',
      //     search: true,
      //     searchPlaceholder: 'Buscar'
      //   }
      //   break;
      case 7: //'Recinto'
        this.config = {
          displayKey: 'socialReason',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 14: //'area presupuestaria'
        this.config = {
          displayKey: 'description',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 9:
        this.config = {
          displayKey: 'stringData',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 10:
        this.config = {
          displayKey: 'stringData',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;

      default:
        break;
    }
    return this.config
  }

  getDropDownOptions(fieldCode: number) {
    this.options = []
    let positions = [...this.positions];
    switch (fieldCode) {
      case 1: //'Departamento'
        this.options = this.departments
        break;
      case 2: //'Posición/Cargo'
        this.options = positions.sort((a, b) => a.positionName.trim().localeCompare(b.positionName.trim()))
        break;
      case 3: //'Tipo Contratación'
        this.options = this.contractTypes
        break;
      // case 'Entidad Bancaria':
      //   this.options = this.banks
      //   break;
      case 7: //'Recinto'
        this.options = this.locations
        break;
      case 9:
        this.options = this.ars
        break;
      case 10:
        this.options = this.afp
        break;
      case 13: //'Tipo Servidor Público'
        this.options = this.employeeTypeList;
        break;

      default:
        break;
    }
    return this.options
  }

  getEmployeeByNumber() {
    this.actionPSrv.getEmployeeByEmployeeId(this.employeeId).subscribe((res: any) => {
      if (res.errors.length > 0) { this._toastService.error("Error al buscar los datos del Servidor Público"); return; }
      if (res.dataList.length < 1) {
        this.personSelected = null;

        this.actionChangeForm.get('parameter').disable()
        this.actionChangeForm.get('currentData').disable()
        this.actionChangeForm.get('newData').disable()
        this.actionChangeForm.get('motive').disable()
        this._toastService.warning("", "No se han encontrado datos del Servidor Público"); return;
      }
      this.personSelected = res.dataList[0];
      this.admissionDate = this.datePie.transform(this.personSelected?.admissionDate, 'yyyy-MM-dd');
      this.getCompanyByID(this.personSelected?.companyId);
      this.getParameterRoutes();
      //Filtro para ocultar el parametro Inicio Nombramiento en caso de que el empleado sea temporal
      // this.fields = this.fields?.filter(f => {
      //   if ((f?.ocode === 12 || f.ocode == 11) && this.personSelected?.typeEmployeeId === 1) {
      //     return;
      //   }
      //   return f;
      // })
      this.getEntryListHistory();
      //this.getExitLists(this.personSelected?.employeeId)
      this.getDesignations()
      let entrySub = this.actionPSrv.getEmployeeEntryList(this.personSelected?.employeeId).subscribe((response: ResponseModel<EmployeeEntry>) => {
        if (response.succeded) {
          this.jobOfferBenefitsToggle = this.entryListParameterControl;
          this.entryListParameterControl.forEach((item, i: number) => {
            this.pushJobOfferBenefitsItems(item.inOutId, this.personSelected.employeeId, response.dataList.find(x => x.ocode == item.inOutId)?.employeeEntryListId ?? 0, response.dataList.find(x => x.ocode == item.inOutId)?.selected ?? false);
          });
        }
      })
      this.subscriptions.add(entrySub);
      this.actionChangeForm.get('employee').setValue(`${this.personSelected?.employNumber}`);
      this.actionChangeForm.get('employeeName').setValue(`${this.personSelected?.name}`)
      this.actionChangeForm.get('effectiveDate').setValue(null)
      this.actionChangeForm.enable()

      if (this.personSelected.profileImage != '' && this.personSelected.profileImage != null) {
        this.srvDoc.getDocument(this.personSelected.profileImage).subscribe(e => {
          this.photoUrl = e.data;
          this.loadingPic = false
        })
      } else {
        this.loadingPic = false
      }
      //disable all if employee was unlinked
      if (this.personSelected?.status == false || this.isSeviceCommission) {
        this.disableAll();
      }


      this.getChangeDetails();
    }, error => {
    })

  }



  getExitLists(employeeId) {
    this.actionPSrv.getEmployeeExitListByEmployeeId(employeeId).subscribe((res: ResponseModel<any>) => {
      if (res.succeded) {
        this.exitlists = res.dataList
      } else {
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  getEntryListHistory() {
    this.actionPSrv.getEmployeeEntryListHistory(this.personSelected?.employeeId).subscribe((res: ResponseModel<any>) => {
      if (res.succeded) {
        this.entryListChanges = res.dataList
      } else {
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  getChangeType(entryListId): string {

    let value;
    let body: any[] = this.employeeEntryForm.get('employeeEntryList').value;
    body.forEach(element => {

      if (entryListId == element.employeeEntryListId) {
        this.entryListParameterControl.forEach((e) => {
          if (element.ocode == e.ocode) {
            value = e.stringData;
          }
        })
      }
    })

    return value;
  }

  getChangeDetails() {
    this.actionPSrv.getChangeDetails(this.personSelected?.employeeId, 2).subscribe((res: any) => {
      res.dataList.forEach(x => {
        x.showResendBtn = false
        if (x.parameterChangeId == 2 || x.parameterChangeId == 4) {
          x.showResendBtn = x.publicAccessId == null && x.condition == 1
        }
      })
      this.changeDetailsList = res.dataList;
      this.changeDeyailsListRecordsOriginal = [...res.dataList];
    })
  }
  getPaginatedRecordsHeader(event) {
    this.changeDeyailsListRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  getPaginatedDesignations(event) {
    this.designationsPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  getPaginatedRecordsEntryList(event) {
    this.entryListChangesPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  getPaginatedRecordsDetails(event) {
    this.newChangesRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  fillCurrentData(parameter: number) {
    this.actionChangeForm.get('newData').setValue('')
    this.actionChangeForm.get('additionalValue').setValue(null);
    this.actionChangeForm.get('additionalValueObject').setValue(null);
    this.getDropDownConfig(parameter)
    this.getDropDownOptions(parameter)


    this.additionalValueList = [];
    this.employeeTypeList = [];

    this.verifySalary();

    switch (parameter) {
      case 1: //'Departamento'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.departmentName)
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.departmentId)
        this.fieldType = 'drop'
        break;
      case 2: //'Posición/Cargo'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.positionName)
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.positionId)
        this.fieldType = 'drop'
        break;
      case 4: //'Sueldo'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.salary)
        this.actionChangeForm.get('newData').setValue(null)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'text'
        break;
      case 3: //'Tipo Contratación'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.contractType)
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.typeContractId)
        this.fieldType = 'drop-additional';
        this.additionalValueFieldName = 'Tipo de Servidor Público';
        break;
      // case 'Entidad Bancaria':
      //   this.actionChangeForm.get('currentData').setValue(this.personSelected?.bankingEntity)
      //   this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.entityBankId)
      //   this.fieldType = 'drop'
      //   break;
      case 7: //'Recinto'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.branch)
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.branchId)
        this.fieldType = 'drop'
        break;
      case 9:
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.ars)
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.arsid)
        this.fieldType = 'drop'
        break;
      case 10:
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.afp)
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.afpid)
        this.fieldType = 'drop'
        break;
      case 11: //'Inicio Contrato'
        this.actionChangeForm.get('currentData').setValue(this.datePipe.transform(this.personSelected?.admissionDate,'yyyy-MM-dd')) 
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'date'
        break;
      case 12: //'Exp. Contrato'
        this.actionChangeForm.get('currentData').setValue(this.datePipe.transform(this.personSelected?.contractExpiration,'yyyy-MM-dd')) 
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'date'
        break;
      case 13: //'Tipo Servidor Público'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.employeeType)
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.typeEmployeeId)
        this.fieldType = 'drop'
        this.getEmployeeTypes(this.personSelected?.typeContractId, parameter);
        break;
      case 14: //'Área presupuestaria'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.budgetArea)
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.budgetAreaId)
        this.fieldType = 'drop'
        this.getBudgetAreas();
        break;

      default:
        break;
    }


  }

  fillNewData(value) {
    let valueType = this.actionChangeForm.get('parameter').value;
    switch (valueType.ocode) {
      case 1: //'Departamento'
        this.actionChangeForm.get('newDataId').setValue(value?.departmentId)
        break;
      case 2: //'Posición/Cargo'
        this.actionChangeForm.get('newDataId').setValue(value?.positionId)
        break;
      case 4: //'Sueldo'
        this.actionChangeForm.get('newDataId').setValue(null)
        break;
      case 3: //'Tipo Contratación'
        this.actionChangeForm.get('newDataId').setValue(value?.ocode)
        this.actionChangeForm.get('additionalValueObject').setValue(null)
        this.getEmployeeTypes(value?.ocode, null);
        break;
      // case 'Entidad Bancaria':
      //   this.actionChangeForm.get('newDataId').setValue(value?.ocode)
      //   break;
      case 7: //'Recinto'
        this.actionChangeForm.get('newDataId').setValue(value?.companyId)
        break;
      case 9:
        this.actionChangeForm.get('newDataId').setValue(value?.ocode)
        break;
      case 10:
        this.actionChangeForm.get('newDataId').setValue(value?.ocode)
        break;
      case 11: //'Inicio Contrato'
        this.actionChangeForm.get('newDataId').setValue(null)
        break;
      case 12: //'Exp. Contrato'
        this.actionChangeForm.get('newDataId').setValue(null)
        break;
      case 13: //'Tipo Servidor Público'
        this.actionChangeForm.get('newDataId').setValue(value?.employeeTypeId)
        break;
      case 14: //'AREA PRESUPUESTARIA'
        this.actionChangeForm.get('newDataId').setValue(value?.budgetAreaId)
        break;
      default:
        break;
    }
  }

  changeAdditionalValue(value: IAdditionalValue) {
    this.actionChangeForm.get('additionalValue').setValue(value?.additionalValue);
    this.actionChangeForm.get('additionalValueName').setValue(value?.additionalValueName);
  }

  getNewDataValue(): string {
    let field = this.actionChangeForm.get('newData').value;

    let value: string = null;
    let valueType = this.actionChangeForm.get('parameter').value;
    if (field.stringData == undefined) {
      switch (valueType.ocode) {
        case 1: //'Departamento'
          value = field.departmentName
          break;
        case 2: //'Posición/Cargo'
          value = field.positionName
          break;
        case 7: //'Recinto'
          value = field.companyName
          break;
        case 9:
          value = field.ars
          break;
        case 10:
          value = field.afp
          break;
        case 11: //'Inicio Contrato'
          value = field
          break;
        case 12: //'Exp. Contrato'
          value = field
          break;
        case 4: //'Sueldo'
          value = field
          break;
        case 3: //'Tipo Contratación'
          value = field.contractType
          break;
        case 13: //'Tipo Servidor Público'
          value = field.description
          break;
        case 14: //'area presupuestaria'
          value = field.description
          break;
        // case 'Entidad Bancaria':
        //   value = field.bankingEntity
        //   break;
        default:
          if (!isNaN(field)) { /* comprobar cuando es un sueldo que se esta digitando */
            value = field.toString();
          } else {
            value = field; /* Si es una fecha */
          }
          break;
      }

    } else {
      value = field.stringData;

    }
    return value;
  }

  getOldDataValue(): any {
    let field = this.actionChangeForm.get('newData').value;

  }

  changeEmployeeInfoOnStringParameter(parameterChange: changeDetails) {
    let parameter = this.fields.find(x => x.ocode == parameterChange.parameterChangeId);

    let value: number = null;
    value = parameter?.ocode;
    if (parameter) {
      switch (value) {
        case 1: //'Departamento'
          this.personSelected.departmentName = parameterChange.valueAfter;
          this.personSelected.departmentId = parameterChange.valueAfterId;
          break;
        case 2: //'Posición/Cargo'
          this.personSelected.positionName = parameterChange.valueAfter;
          this.personSelected.positionId = parameterChange.valueAfterId;
          break;
        case 7: //'Recinto'
          this.personSelected.branch = parameterChange.valueAfter;
          this.personSelected.branchId = parameterChange.valueAfterId;
          break;
        case 9:
          this.personSelected.ars = parameterChange.valueAfter;
          this.personSelected.arsid = parameterChange.valueAfterId;
          break;
        case 10:
          this.personSelected.afp = parameterChange.valueAfter;
          this.personSelected.afpid = parameterChange.valueAfterId;
          break;
        case 11: //'Inicio Contrato'
          this.personSelected.admissionDate = parameterChange.valueAfter;
          break;
        case 12: //'Exp. Contrato'
          this.personSelected.contractExpiration = parameterChange.valueAfter;
          break;
        case 4: //'Sueldo'
          this.personSelected.salary = parameterChange.valueAfterNumeric;
          break;
        case 3: //'Tipo Contratación'
          this.personSelected.contractType = parameterChange.valueAfter;
          this.personSelected.typeContractId = parameterChange.valueAfterId;

          this.personSelected.employeeType = parameterChange.additionalValueName;
          this.personSelected.typeEmployeeId = parameterChange.additionalValue;
          break;
        case 13: //'Tipo Contratación'
          this.personSelected.employeeType = parameterChange.valueAfter;
          this.personSelected.typeEmployeeId = parameterChange.valueAfterId;
          break;
        // case 'Entidad Bancaria':
        //   this.personSelected.bankingEntity = parameterChange.valueAfter;
        //   this.personSelected.entityBankId = parameterChange.valueAfterId;
        //   break;
        default:

          break;
      }

    }

  }

  ValidateNewData(data: AbstractControl) {
    if (data.get('newData').value != '') {
      let newData = data.get('newDataId').value ? data.get('newDataId').value : data.get('newData').value
      let currentData = data.get('currentDataId').value ? data.get('currentDataId').value : data.get('currentData').value
      if (newData == currentData) {
        return { invalidValue: true };
      }
    }
    return null;
  }

  validateEffectiveDate() {

    const changeType = this.actionChangeForm.get('parameter').value
    //cambio de tipo Inicio de Contrato
    if(changeType?.ocode == 11){
      this.effectiveDateIsInvalid = false;
      this.effectiveDateError = ''
      return;
    }

    const admissionDate = moment(this.personSelected.admissionDate).startOf('date')
    const effectiveDate = moment(this.actionChangeForm.get('effectiveDate').value).startOf('date')
    this.effectiveDateIsInvalid = effectiveDate < admissionDate
    this.effectiveDateError = ''
    
    if (this.effectiveDateIsInvalid) {
      this.effectiveDateError = 'La fecha efectiva debe ser superior a la fecha de ingreso del servidor público'
    }
  }

  getUserName() {
    return this.authInfo.getUser().firstName.trim() + ' ' + this.authInfo.getUser().lastName.trim()
  }

  addChangeDetails() {
    this.verifySalary();
    this.validateEffectiveDate();
    this.formIsInvalid = this.actionChangeForm.invalid;

    if(this.effectiveDateIsInvalid){
      return;
    }

    if (!this.isSalaryCorrect) {
      return
    }

    if (this.formIsInvalid) {
      this._toastService.warning('Debe completar todos los campos requeridos')
      return;
    }

    if (this.fieldType == 'drop-additional' && !this.actionChangeForm.get('additionalValue').value) {
      this._toastService.warning('Debe completar todos los campos requeridos')
      return;
    }

    if (this.inViewDetails) {
      this.inViewDetails = false;
      this.newChanges = [];
    }



    if (this.isEdittingEmployeeChange) {
      this.setValueToEditChange();
      return;
    }


    let f = this.actionChangeForm;

    if (this.fieldType == 'date') {
      const currentDate = f.get('currentData').value;

      f.get('currentData').setValue(this.datePie.transform(currentDate, 'yyyy-MM-dd'));
    }

    let condition;

    this.parameterType = f.get('parameter').value?.ocode;

    //ocode 4 = Sueldo | ocode 2 = Posición/Cargo
    if (this.parameterType == 4 || this.parameterType == 2 || this.parameterType == 3 || this.parameterType == 13) {
      condition = 1

    }
    else {
      condition = 2
    }

    //7 = Recinto
    if (this.parameterType == 7) {
      this.newChanges.push({
        employeeChangeHeaderId: 0,
        parameterChangeId: f.get('parameter').value?.ocode,
        valueBeforeId: this.isNumeric(f.get('currentDataId').value) ? f.get('currentDataId').value : 0,//f.get('currentData').value,
        valueBefore: this.isNumeric(f.get('currentData').value) ? f.get('currentData').value.toString() : f.get('currentData').value,
        valueAfterId: this.isNumeric(f.get('newDataId').value) ? f.get('newDataId').value : 0,//this.getNewDataValue(),
        valueAfterNumeric: this.isNumeric(this.getNewDataValue()) ? parseFloat(this.getNewDataValue()) : null,
        valueAfter: this.isNumeric(this.getNewDataValue()) ? this.getNewDataValue().toString() : this.getNewDataValue(),
        effectiveDate: f.get('effectiveDate')?.value,
        additionalValue: f.get('additionalValue').value,
        additionalValueName: f.get('additionalValueName').value,
        condition: condition,
        companyId: this.personSelected.companyId,
        reasonId: f.get('motive').value.ocode,
        evidence: f.get('evidence').value,
        status: true,
        comment: f.get('comment').value,
        usrApproved: this.authInfo.getUserId(),
        dateApproved: new Date().toLocaleDateString("fr-CA"),
        parameterName: f.get('parameter').value?.stringData,
        reason: f.get('motive').value?.stringData,
        createUserId: this.isEdittingEmployeeChange ? f.value.createUserId : this.authInfo.getUserId(),
        modifyUserId: this.isEdittingEmployeeChange ? this.authInfo.getUserId() : 0,
        userName: this.getUserName()
      })
      this.parameterName = f.get('parameter').value?.stringData;
      this.resetInputs()


    } else {
      let valueAfterNumeric = this.isNumeric(this.getNewDataValue()) ? parseFloat(this.getNewDataValue()) : null
      let valueAfter = this.getNewDataValue()?.toString()
      if (f.get('parameter').value?.ocode == 4) { // 4 = sueldo
        if (this.salaryIsInvalid(valueAfterNumeric)) {
          return
        }
      }
      this.newChanges.push({
        employeeChangeHeaderId: 0,
        parameterChangeId: f.get('parameter').value?.ocode,
        valueBeforeId: this.isNumeric(f.get('currentDataId').value) ? f.get('currentDataId').value : 0,//f.get('currentData').value,
        valueBefore: this.isNumeric(f.get('currentData').value) ? f.get('currentData').value.toString() : f.get('currentData').value,
        valueAfterId: this.isNumeric(f.get('newDataId').value) ? f.get('newDataId').value : 0,//this.getNewDataValue(),
        effectiveDate: f.get('effectiveDate')?.value,
        valueAfterNumeric: valueAfterNumeric,
        valueAfter: valueAfter,
        additionalValue: f.get('additionalValue').value,
        additionalValueName: f.get('additionalValueName').value,
        additionalValueBefore: this.parameterType === 3 ? this.personSelected?.typeEmployeeId : 0,
        additionalValueBeforeName: this.parameterType === 3 ? this.personSelected?.employeeType : '',
        condition: condition,
        companyId: this.personSelected.companyId,
        reasonId: f.get('motive').value.ocode,
        evidence: f.get('evidence').value,
        status: true,
        comment: f.get('comment').value,
        usrApproved: this.authInfo.getUserId(),
        dateApproved: new Date().toLocaleDateString("fr-CA"),
        parameterName: f.get('parameter').value?.stringData,
        reason: f.get('motive').value?.stringData,
        createUserId: this.isEdittingEmployeeChange ? f.value.createUserId : this.authInfo.getUserId(),
        modifyUserId: this.isEdittingEmployeeChange ? this.authInfo.getUserId() : 0,
        userName: this.getUserName()
      })
      this.parameterName = f.get('parameter').value?.stringData
      this.resetInputs()
    }
  }

  async setValueToEditChange() {
    const formValues = this.actionChangeForm;
    this.parameterType = formValues.get('parameter').value?.ocode;
    let condition = 0;
    if (this.parameterType == 4 || this.parameterType == 2 || this.parameterType == 3) {
      condition = 1
    }
    else {
      condition = 2
    }
    let change = null;

    this.uppyFileComponent.handleFile(guid => {
      if (this.parameterType == 7) {
        change = {
          employeeChangeHeaderId: formValues?.value?.employeeChangeHeaderId,
          employeeChangeDetailsId: formValues?.value?.employeeChangeDetailsId > 0 ? formValues?.value?.employeeChangeDetailsId : 0,
          parameterChangeId: formValues.get('parameter').value?.ocode,
          valueBeforeId: this.isNumeric(formValues.get('currentDataId').value) ? formValues.get('currentDataId').value : 0,//formValues.get('currentData').value,
          valueBefore: this.isNumeric(formValues.get('currentData').value) ? formValues.get('currentData').value.toString() : formValues.get('currentData').value,
          valueAfterId: this.isNumeric(formValues.get('newDataId').value) ? formValues.get('newDataId').value : 0,//this.getNewDataValue(),
          valueAfterNumeric: this.isNumeric(this.getNewDataValue()) ? parseFloat(this.getNewDataValue()) : null,
          valueAfter: this.isNumeric(this.getNewDataValue()) ? this.getNewDataValue().toString() : this.getNewDataValue(),
          effectiveDate: formValues.get('effectiveDate')?.value,
          additionalValue: formValues.get('additionalValue').value,
          additionalValueName: formValues.get('additionalValueName').value,
          condition: formValues?.value?.employeeChangeDetailsId > 0 ? formValues?.value?.condition : condition,
          companyId: this.personSelected.companyId,
          reasonId: formValues.get('motive').value.ocode,
          evidence: guid,
          status: true,
          comment: formValues.get('comment').value,
          usrApproved: this.authInfo.getUserId(),
          dateApproved: new Date().toLocaleDateString("fr-CA"),
          parameterName: formValues.get('parameter').value?.stringData,
          reason: formValues.get('motive').value?.stringData,
          createUserId: this.isEdittingEmployeeChange ? formValues.value.createUserId : this.authInfo.getUserId(),
          modifyUserId: this.isEdittingEmployeeChange ? this.authInfo.getUserId() : 0,
          modificationReasonId: formValues.get('newModificationReason').value.ocode,
          modificationComment: formValues.get('newModificationComment').value
        };
        this.parameterName = formValues.get('parameter').value?.stringData;
        this.newChanges = [{ ...change }];
        this.newChangesRecordsOriginal = [{ ...change }];
        this.updateChange()
  
      } else {
        let valueAfterNumeric = this.isNumeric(this.getNewDataValue()) ? parseFloat(this.getNewDataValue()) : null
        let valueAfter = this.getNewDataValue()?.toString()
        if (formValues.get('parameter').value?.ocode == 4) { // 4 = sueldo
          if (this.salaryIsInvalid(valueAfterNumeric)) {
            return
          }
        }
        change = {
          employeeChangeHeaderId: formValues?.value?.employeeChangeHeaderId,
          employeeChangeDetailsId: formValues?.value?.employeeChangeDetailsId > 0 ? formValues?.value?.employeeChangeDetailsId : 0,
          parameterChangeId: formValues.get('parameter').value?.ocode,
          valueBeforeId: this.isNumeric(formValues.get('currentDataId').value) ? formValues.get('currentDataId').value : 0,//formValues.get('currentData').value,
          valueBefore: this.isNumeric(formValues.get('currentData').value) ? formValues.get('currentData').value.toString() : formValues.get('currentData').value,
          valueAfterId: this.isNumeric(formValues.get('newDataId').value) ? formValues.get('newDataId').value : 0,//this.getNewDataValue(),
          effectiveDate: formValues.get('effectiveDate')?.value,
          valueAfterNumeric: valueAfterNumeric,
          valueAfter: valueAfter,
          additionalValue: formValues.get('additionalValue').value,
          additionalValueName: formValues.get('additionalValueName').value,
          condition: condition,
          companyId: this.personSelected.companyId,
          reasonId: formValues.get('motive').value.ocode,
          evidence: guid,
          status: true,
          comment: formValues.get('comment').value,
          usrApproved: this.authInfo.getUserId(),
          dateApproved: new Date().toLocaleDateString("fr-CA"),
          parameterName: formValues.get('parameter').value?.stringData,
          reason: formValues.get('motive').value?.stringData,
          createUserId: this.isEdittingEmployeeChange ? formValues.value.createUserId : this.authInfo.getUserId(),
          modifyUserId: this.isEdittingEmployeeChange ? this.authInfo.getUserId() : 0,
          modificationReasonId: formValues.get('newModificationReason').value.ocode,
          modificationComment: formValues.get('newModificationComment').value
        };
        this.parameterName = formValues.get('parameter').value?.stringData
        this.newChanges = [{ ...change }];
        this.newChangesRecordsOriginal = [{ ...change }];
        this.updateChange();
      }
    });
  }


  remove(item, rowIndex) {
    const changeType = item.parameterChangeId
    if (changeType == 2) {// 2 = posicion
      const hasChangeSalary = this.newChanges.find(x => x.parameterChangeId == 4) // 4 = sueldo
      if (hasChangeSalary) {
        const index = this.newChanges.indexOf(hasChangeSalary)
        if (!this.newSalaryIsInvalid(hasChangeSalary.valueAfterNumeric)) {
          this.newChanges.splice(rowIndex, 1)
          return
        }
        Swal.fire({
          title: '¡Atención!',
          html: 'Si elimina este cambio de posición, también se eliminirá el cambio de sueldo',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancelar',
        }).then((res) => {
          if (res.isConfirmed) {
            this.newChanges.splice(index, 1)
            this.newChanges.splice(rowIndex, 1)
          }
          if (res.isDismissed) {
            return
          }
        })
      } else {
        this.newChanges.splice(rowIndex, 1)
      }
    } else {
      this.newChanges.splice(rowIndex, 1)
    }
  }

  employeeAppliedForPosition() {
    return this.newChanges.find(x => x.parameterChangeId == 2 && x.condition == 1)
  }
  salaryIsInvalid(salary: number): boolean {
    const requestPosition = this.employeeAppliedForPosition()
    let positionId = requestPosition ? requestPosition.valueAfterId : this.personSelected.positionId
    const salaryRange = this.getSalaryRange(positionId)
    const isLower = salary < salaryRange.minSalary
    const isHigher = salary > salaryRange.maxSalary
    if (isHigher) {
      let text = `El salario que solicita es mayor al permitido para su posición/cargo actual`
      if (this.employeeAppliedForPosition()) { // verifica si el empleado tiene un cambio de posicion
        text = `El salario que solicita es mayor al permitido para la posición/cargo que esta solicitando`
      }
      this._toastService.warning(text)
      return true
    }
    if (isLower) {
      let text = `El salario que solicita es menor al permitido para su posición/cargo actual`
      if (this.employeeAppliedForPosition()) { // verifica si el empleado tiene un cambio de posicion
        text = `El salario que solicita es menor al permitido para la posición/cargo que esta solicitando`
      }
      this._toastService.warning(text)
      return true
    }
    return false
  }
  newSalaryIsInvalid(salary) {
    let positionId = this.personSelected.positionId
    const salaryRange = this.getSalaryRange(positionId)
    const isLower = salary <= salaryRange.minSalary
    const isHigher = salary >= salaryRange.maxSalary
    if (isLower || isHigher) {
      return true
    }
    return false
  }
  getSalaryRange(positionId): { maxSalary: number, minSalary: number } {
    const position = this.positions.find(x => x.positionId == positionId)
    if (!position) {
      return { maxSalary: 0, minSalary: 0 }
    }
    const salaryRange = {
      maxSalary: position.maxSalary,
      minSalary: position.minSalary
    }
    return salaryRange
  }

  isDate(date): boolean {
    if (this.isNumeric(date)) {
      return false;
    }
    return (new Date(date).toString() !== "Invalid Date") && !isNaN(Number(new Date(date)));
  }

  isNumeric(value): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    return !isNaN(Number(value));
  }

  buildJobOfferBenefitsForm(inOutId: number, employeeId: number, employeeEntryListId: number, selected: boolean) {
    return this._builder.group({
      employeeEntryListId: [employeeEntryListId],
      employeeId: [Number(employeeId)],
      inOutId: [inOutId, [Validators.required]],
      selected: [selected, [Validators.required]],
      companyId: [this.authInfo.getCompanyId()],
      status: [true],
      evidence: [null, [Validators.required]],
      observations: ["", [Validators.required]],
      assignedBy: this.authInfo.getUserId(),
      deviceBrand: [null],
      deviceModel: [null],
      deviceAsset: [null],
      deviceSerial: [null],
      deviceDeliveredDate: [null],


    });
  }

  pushJobOfferBenefitsItems(benefitTypeId: number, employeeId: number, employeeEntryListId: number, selected: boolean) {
    const item = this.employeeEntryForm.get('employeeEntryList') as FormArray;
    const itemCopy = this.employeeEntryForm.get('employeeEntryListCopy') as FormArray;
    item.push(this.buildJobOfferBenefitsForm(benefitTypeId, employeeId, employeeEntryListId, selected));
    itemCopy.push(this.buildJobOfferBenefitsForm(benefitTypeId, employeeId, employeeEntryListId, selected));
    if (this.personSelected?.status == false || this.isSeviceCommission == true
      || this.isSuspended == true
    ) {
      item.disable();
      this.employeeEntryForm.disable();
    }
  }

  async setChangeHeader() {
    //ocode 4 = Sueldo | ocode 2 = Posición/Cargo
    let parameterType;
    if (this.parameterType == 4) {
      parameterType = 2
    }
    else if (this.parameterType == 2) {
      parameterType = 1
    }

    else if (this.parameterType == 3) {
      parameterType = 4
    }
    else if (this.parameterType == 13) {
      parameterType = 5
    }
    else {
      parameterType = null
    }


    this.changeHeaderDTO = {
      employeeId: this.personSelected?.employeeId,
      date: new Date().toLocaleString("fr-CA", {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }),
      createUserId: this.isEdittingEmployeeChange ? this.actionChangeForm.value.createUserId : this.authInfo.getUserId(),
      modifyUserId: this.isEdittingEmployeeChange ? this.authInfo.getUserId() : 0,
      companyId: this.personSelected?.companyId,
      requestMapid: null,
      requestTypes: parameterType,
      status: true,
      modifyDate: new Date().toLocaleDateString("fr-CA"),
      createDate: new Date().toLocaleDateString("fr-CA"),
      userName: this.getUserName()
    }
    this.employeeChangeDTO.header = this.changeHeaderDTO
    this.employeeChangeDTO.details = this.newChanges;

    const promises = this.employeeChangeDTO.details.map(async detail => {
      if(this.uppyFileComponent?.fileToDelete){
        this.uppyFileComponent.removeFileDefinitely();
      }
      if(detail.evidence){
        const res = await this.uploadFile(detail.evidence as unknown as FormData);
        const resData = res?.data as FileResponse;
        detail.evidence = resData.fileUnit.guidname;
      }
    });
    await Promise.all(promises);

    const sendToMap = this.newChanges.find(x => x.parameterChangeId == changesMap.salary || x.parameterChangeId == changesMap.position || x.parameterChangeId == changesMap.contratType || x.parameterChangeId == changesMap.employeeType)
    if (sendToMap) {
      if (this.companySign?.useDigitalSignature && !this.companySign?.entityCode) {
        this._toastService.warning('La institución no tiene una entidad asignada para la firma digital');
        return;
      }
      this.createChange(true)
    }
    else {
      this.createChange()
    }
  }

  uploadFile(formData: FormData) {
    let data = {
      SystemLoad: this.uppyFileComponent.SystemLoad,
      ModuleLoad: this.uppyFileComponent.ModuleLoad,
      personalFile: this.uppyFileComponent.typePersonalFile,
      routeFile: this.uppyFileComponent.routeFile
    }
    return this.srvDoc.createFile(data, formData).toPromise();
  }

  createChange(toMap = false) {
    this.actionPSrv.CreateEmployeeChange(this.employeeChangeDTO).subscribe((res: any) => {

      if (!res.succeded) {

        const errorChangeExist = res?.errors?.some(e => (e as string)?.includes('Ya hay una Acción de Personal en proceso'));
        if (errorChangeExist) {
          this._toastService.warning(`Este Servidor Público ya tiene un proceso de cambio de ${this.parameterName} pendiente de aprobación`);
        } else {
          res.errors.forEach(err => {
            this._toastService.error(err);
          })

          res.warnings.forEach(err => {
            this._toastService.warning(err);
          })
        }

        Swal.hideLoading()
        return;
      }

      const response = res.singleData.details

      if (toMap == true) {

        for (let i = 0; i < response.length; i++) {
          const element = response[i];
          this.employeeChangeDTO.details[i].employeeChangeDetailsId = element.employeeChangeDetailsId
          this.employeeChangeDTO.details[i].employeeChangeHeaderId = element.employeeChangeHeaderId
        }
        let changesSaved = this.employeeChangeDTO.details
        changesSaved.forEach(employeeChange => {
          if (employeeChange.parameterChangeId == changesMap.position
            || employeeChange.parameterChangeId == changesMap.salary
            || employeeChange.parameterChangeId == changesMap.contratType
            || employeeChange.parameterChangeId == changesMap.employeeType) {

            const employeeChangeModel: EmployeeChangeBase64 = {
              admissionDate: this.personSelected.admissionDate,
              companyName: this.personSelected.companyName,
              departmentName: this.personSelected.departmentName,
              employeeFirstName: this.personSelected.firstName,
              employeeBudgetArea: this.personSelected.budgetArea,
              employeeLastName: this.personSelected.lastName,
              employeeStatus: this.personSelected.status,
              employeeType: this.personSelected.employeeType,
              personalIdentification: this.personSelected.personalIdentification,
              positionName: this.personSelected.positionName,
              salary: this.personSelected.salary,
              supervisor: this.personSelected.employeeSupervisor,
              employeeChangeDetailsId: employeeChange.employeeChangeDetailsId,
              parameterName: employeeChange.parameterName,
              effectiveDate: employeeChange?.effectiveDate,
              valueAfter: employeeChange.valueAfter,
              changeType: employeeChange.parameterChangeId,
              valueAfterNumeric: employeeChange.valueAfterNumeric ?? 0,
              valueBefore: employeeChange.valueBefore
            }
            if (this.companySign?.useDigitalSignature) {
              this.checkConfiguration(employeeChange, employeeChangeModel);
              this.newChanges = [];
              return;
            } else {
              this._toastService.success('Registro Guardado Exitosamente', '¡Éxito!');

              this.newChanges = [];
              this.getChangeDetails();
            }
          }
        })
      } else {
        Swal.close();
        this._toastService.success("Acción de cambio efectuada exitosamente")

        let theChanges = [...this.employeeChangeDTO.details];
        theChanges.forEach((change) => {
          this.changeEmployeeInfoOnStringParameter(change);
        });
        this.getChangeDetails();
        this.resetInputs();
        this.newChanges = [];
        this.employeeChangeDTO.header = null;
        this.employeeChangeDTO.details = [];
        this.getEmployeeByNumber();
      }

    }, err => {
      Swal.close();
      this._toastService.error(err)
    })
  }

  private updateChange() {
    this.actionPSrv.updateEmployeeChangeDetails(this.newChanges[0]).subscribe({
      next: (res) => {

        if (!res.succeded) {

          res.errors.forEach(err => {
            this._toastService.error(err);
          })

          res.warnings.forEach(err => {
            this._toastService.warning(err);
          })
          Swal.hideLoading()
          return;
        }

        this._toastService.success('Acción actualizada satisfactoriamente');
        if (this.newChanges.some(x => x.parameterChangeId == 11)) {
          this.showAlertForAdmissionDate();
        }
        this.clearEditting(false);
        this.getEmployeeByNumber();
      },
      error: (err) => {
        Swal.close();
        this._toastService.error('Ocurrió un error inesperado tratando de actualizar la acción de personal');
      }
    })
  }

  showAlertForAdmissionDate() {
    const message: string = 'Usted ha registrado una Acción de Personal que posiblemente requerirá un pago adicional de Salario a este Servidor Público debido al cambio de fecha de ingreso';
    Swal.fire(message, '', 'info');
  }

  checkConfiguration(employeeChange, employeeChangeModel) {
    if (!this.companySign?.entityCode) {
      this._toastService.warning('No se pudo enviar la petición de firma porque la institución no tiene una entidad asignada para la firma digital');
      this.getChangeDetails();
      return;
    }
    if (employeeChange.parameterChangeId == 2) {
      this.actionPSrv.getUsersApprovals(this.authInfo.getCompanyId(), DigitalSignatureApproval.ApproveJobChanges).subscribe((res: any) => {
        if (!res.succeded) {
          this._toastService.error(res.errors[0]);
          return;
        }
        if (res?.singleResultData.approvalsIdentification?.length === 0
          && res?.singleResultData.signatureIdentification?.length === 0) {
          this._toastService.warning('No se encontró ninguna configuración de usuarios para la firma digital');
          Swal.close();
          this.getChangeDetails();
          return;
        }
        this.userApprovalsPosition = res?.singleResultData;
        Swal.fire("Guardando datos", '', 'info');
        Swal.showLoading();
        this.signRequestActionChange(employeeChange, employeeChangeModel);
      });
    }
    if (employeeChange.parameterChangeId == 4) {
      this.actionPSrv.getUsersApprovals(this.authInfo.getCompanyId(), DigitalSignatureApproval.ApproveSalaryChanges).subscribe((res: any) => {
        if (!res.succeded) {
          this._toastService.error(res.errors[0]);
          return;
        }
        if (res?.singleResultData.approvalsIdentification?.length === 0
          && res?.singleResultData.signatureIdentification?.length === 0) {
          this._toastService.warning('No se encontró ninguna configuración de usuarios para la firma digital');
          Swal.hideLoading();
          this.getChangeDetails();
          return;
        }
        this.userApprovalsSalary = res?.singleResultData;
        Swal.fire("Guardando datos", '', 'info')
        Swal.showLoading();
        this.signRequestActionChange(employeeChange, employeeChangeModel);
      });
    }
  }

  requestSign(metadatas, documents, employeeChange, parameterName, initDate, expirationDate, addresseeGroups) {
    this.srvCreateSign.CreateSignRequest(
      metadatas,
      `Aprobación ${parameterName}`,
      `Aprobación ${parameterName}`,
      documents,
      [],
      addresseeGroups,
      initDate,
      expirationDate,
      this.companySign?.entityCode
    ).subscribe((data: any) => {
      if (data?.publicAccessId) {
        this.updatePublicAccessId(data?.publicAccessId, employeeChange);
      }
      if (data?.errorCode) {
        this._toastService.error(`Ha ocurrido un error creando la petición de la firma para la aprobación del cambio de ${parameterName}`);
      }
    }, error => {
      this.getChangeDetails()
      Swal.close()
      this._toastService.error(error?.error?.errorMessage ?? '', `Ha ocurrido un error creando la petición de la firma para la aprobación del cambio de ${parameterName}`);
    });
  }

  CreateSignRequest(metadatas, documents, employeeChange, parameterName, initDate, expirationDate) {
    let addresseeGroups = []
    if (employeeChange.parameterChangeId == 2) {
      addresseeGroups = this.getAddresseeGroups(this.userApprovalsPosition);
      this.requestSign(metadatas, documents, employeeChange, parameterName, initDate, expirationDate, addresseeGroups);
    }
    if (employeeChange.parameterChangeId == 4) {
      addresseeGroups = this.getAddresseeGroups(this.userApprovalsSalary);
      this.requestSign(metadatas, documents, employeeChange, parameterName, initDate, expirationDate, addresseeGroups);
    }
  }

  signRequestActionChange(employeeChange, employeeChangeModel) {
    this.srvCreateSign.getGetBase64Approval(employeeChangeModel).subscribe((res: any) => {
      if (!res.succeded) {
        this._toastService.error(res.errors[0])
        Swal.close()
        return
      }

      const { initDate, expirationDate } = res.singleData
      let data = {
        SystemLoad: this.SystemLoad,
        ModuleLoad: this.ModuleLoad,
        personalFile: 1,
        routeFile: this.routeFile
      }
      let parameterName = ''
      if (changesMap.position == employeeChange.parameterChangeId) {
        parameterName = 'Cargo'
      }
      if (changesMap.salary == employeeChange.parameterChangeId) {
        parameterName = 'Salario'
      }
      if (changesMap.contratType == employeeChange.parameterChangeId) {
        parameterName = 'Tipo de Contrato'
      }
      let formData = new FormData();
      fetch(`data:application/pdf;base64,${res?.singleData?.base64}`).then(res => res.blob()).then(blob => {
        const file = new File([blob], `Aprobación ${parameterName}.pdf`);
        formData.append(`Aprobación ${parameterName}`, file);
        let key = ''
        if (changesMap.salary == employeeChange.parameterChangeId) {
          key = 'RRHH-Salario'
        }
        if (changesMap.position == employeeChange.parameterChangeId) {
          key = 'RRHH-Cargo'
        }
        if (changesMap.contratType == employeeChange.parameterChangeId) {
          key = 'RRHH-Contrato'
        }
        const metadatas: Metadata[] = [
          { key: key, value: `Aprobación ${parameterName}` }
        ]
        this.srvDoc.createFile(data, formData).subscribe((result: any) => {
          if (result?.data?.path) {
            const documents = [
              {
                filename: `Aprobación Cambio ${parameterName}.pdf`,
                url: result?.data?.path
              }
            ];
            this.CreateSignRequest(metadatas, documents, employeeChange, parameterName, initDate, expirationDate);
          }
        }, err => {
          Swal.close()
          this._toastService.error('Ha ocurrido un error al obtener el documento a firmar, por favor intente más tarde o comuníquese con HelpDesk.');
        }, () => {
          this.resetInputs()
          this.newChanges = []
          this.employeeChangeDTO.header = null
          this.employeeChangeDTO.details = []
          return
        })
      })
    })
  }

  resendRequestSign(employeeChange) {
    const employeeChangeModel: EmployeeChangeBase64 = {
      admissionDate: this.personSelected.admissionDate,
      companyName: this.personSelected.companyName,
      departmentName: this.personSelected.departmentName,
      employeeFirstName: this.personSelected.firstName,
      employeeBudgetArea: this.personSelected.budgetArea,
      employeeLastName: this.personSelected.lastName,
      employeeStatus: this.personSelected.status,
      employeeType: this.personSelected.employeeType,
      personalIdentification: this.personSelected.personalIdentification,
      positionName: this.personSelected.positionName,
      salary: this.personSelected.salary,
      supervisor: this.personSelected.employeeSupervisor,
      employeeChangeDetailsId: employeeChange.employeeChangeDetailsId,
      parameterName: employeeChange.parameterName,
      valueAfter: employeeChange.valueAfter,
      effectiveDate: employeeChange?.effectiveDate,
      changeType: employeeChange.parameterChangeId,
      valueAfterNumeric: employeeChange.valueAfterNumeric ?? 0,
      valueBefore: employeeChange.valueBefore
    }
    this.checkConfiguration(employeeChange, employeeChangeModel);
  }


  updatePublicAccessId(publicAccessId, employeeChange: changeDetails) {
    employeeChange.publicAccessId = publicAccessId;
    employeeChange.userName = this.getUserName()
    this.actionPSrv.updateEmployeeChangeDetails(employeeChange).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        Swal.close()
        return;
      }
      this._toastService.success('Registro Guardado Exitosamente', '¡Éxito!');
      Swal.fire("Datos Guardados", '', 'info');
      Swal.hideLoading();
      this.getChangeDetails();
    }, error => {
      this.getChangeDetails();
      Swal.close();
      this._toastService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.');
    })
  }

  showRejectionReason(reasonMessage: string) {
    Swal.fire({
      text: reasonMessage,
      icon: "info",
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Entendido'
    })
  }

  updateEntryList() {

    let body: any[] = this.employeeEntryForm.get('employeeEntryList').value;
    let bodyCopy = this.employeeEntryForm.get('employeeEntryListCopy').value;


    if (body.length < 1) {
      return;
    }

    let history: EmployeeEntryListHistory[] = body.filter(x => {
      return bodyCopy.find(f => f.inOutId == x.inOutId && f.selected != x.selected);
    })

    let historyFormatted: any[] = history.map(element => {
      return {
        companyId: element.companyId,
        employeeEntryListId: element.employeeEntryListId,
        selected: element.selected,
        createUserId: this.authInfo.getUserId(),
        observations: element.observations,
        assignedBy: element.assignedBy,
        evidence: element.evidence,
        deviceBrand: element.deviceBrand,
        deviceModel: element.deviceModel,
        deviceAsset: element.deviceAsset,
        deviceSerial: element.deviceSerial,
        deviceDeliveredDate: element.deviceDeliveredDate,
      }
    });

    if (historyFormatted.length > 0) {

      this.actionPSrv.insertEmployeeEntryListHistory(historyFormatted).subscribe((res: any) => {
        if (res.errors < 1) {
          this.employeeEntryForm.get('employeeEntryListCopy').setValue(this.employeeEntryForm.get('employeeEntryList').value);

          let sub = this.actionPSrv.upsertEmployeeEntryList(body).subscribe((response: RequestMetaData) => {
            if (response.succeded) {
              this._toastService.success('Se ha actualizado la lista de entrada con éxito.');
              this.getEntryListHistory();
              this.resetForm()
              //this.getEmployeeByNumber();
            } else {
              this._toastService.error('Ocurrió un error al guardar, por favor validar con TI.')
            }
          });


          this.subscriptions.add(sub);


        } else {
          this._toastService.error(res.errors[0])
        }
      }, err => {
        this._toastService.error('Ocurrió un error al guardar el historico, por favor validar con TI.')
      })
    }

  }
  resetForm() {
    this.employeeEntryForm.get('employeeEntryList')['controls'].forEach((list: FormGroup) => {
      list.get('observations').reset()
    });
  }

  setTabSelected(tab: string) {
    localStorage.setItem(this.localStorageTab1, tab)
  }

  clearEditting(reloadDetail: boolean = false) {
    if (this.isEdittingEmployeeChange) {
      this.resetInputs();
      this.newChanges = [];
      this.employeeChangeDTO.header = null;
      this.employeeChangeDTO.details = [];
      if (reloadDetail) {
        this.getChangeDetails();
      }
    } else {
      this.resetInputs();
    }
  }

  resetInputs() {
    this.actionChangeForm.get('parameter').setValue(null)
    this.actionChangeForm.get('currentData').setValue('')
    this.actionChangeForm.get('currentDataId').setValue(null)
    this.actionChangeForm.get('newData').setValue(null)
    this.actionChangeForm.get('newDataId').setValue(null)
    this.actionChangeForm.get('motive').setValue(null)
    this.actionChangeForm.get('comment').setValue(null)
    this.actionChangeForm.get('effectiveDate').setValue(null)
    this.file.document = '';
    this.guiID = null;
    this.uppyFileComponent.cleanUppy();
    this.actionChangeForm.get('evidence').setValue(null)
    this.additionalValueList = [];
    this.actionChangeForm.get('additionalValue').setValue(null)
    this.actionChangeForm.get('additionalValueName').setValue(null)
    this.actionChangeForm.get('additionalValueObject').setValue(null)
    this.actionChangeForm.get('oldModificationReason').setValue(null)
    this.actionChangeForm.get('oldModificationComment').setValue(null)
    this.actionChangeForm.get('newModificationReason').setValue(null)
    this.actionChangeForm.get('newModificationComment').setValue(null)
    this.fieldType = this.fieldType == 'drop-additional' ? 'drop' : this.fieldType;
    this.isEdittingEmployeeChange = false;
    this.currentChangeConditionOnEditting = null;
    this.actionChangeForm.get('parameter').enable();

    this.actionChangeForm.get('newModificationReason').clearValidators();
    this.actionChangeForm.get('newModificationReason').updateValueAndValidity();
    this.verifySalary();
  }
  getEntryListParameter() {
    this.actionPSrv.getEntryList().subscribe({
      next: (res: any) => {
        this.entryListParameterControl = res.dataList
      },
      error: err => this._toastService.error('Ha sucedido un error tratando de cargar la lista de entrada')
    })
  }

  showParameterErrors() {
    let field = this.actionChangeForm.get('parameter');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showNewDataErrors() {
    let field = this.actionChangeForm.get('newData');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showMotiveErrors() {
    let field = this.actionChangeForm.get('motive');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showEffectiveDateErrors() {
    let field = this.actionChangeForm.get('effectiveDate');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  verifySalary(): void {
    let valueType = this.actionChangeForm.get('parameter').value;
    if (valueType.stringData != 'Sueldo') {
      this.isSalaryCorrect = true;
      return;
    }
    let salaryApproved = this.actionChangeForm.controls['newData'].value ? this.actionChangeForm.controls['newData'].value : 0;
    let salary = this.personSelected.salary ? this.personSelected.salary : 0;

    if (salaryApproved <= salary) {
      this.isSalaryCorrect = false;
    } else {
      this.isSalaryCorrect = true;
    }

  }


  getAddresseeGroups(userApprovals) {
    const userEntitiesAPPROVAL = {
      isOrGroup: false,
      userEntities: userApprovals?.approvalsIdentification?.map(identification => {
        return {
          userCode: identification,
          entityCode: this.companySign?.entityCode,
          action: UserEntitiesAction.APPROVAL
        }
      })
    };

    const userEntitiesSIGN = {
      isOrGroup: false,
      userEntities: userApprovals?.signatureIdentification?.map(identification => {
        return {
          userCode: identification,
          entityCode: this.companySign?.entityCode,
          action: UserEntitiesAction.SIGN
        }
      })
    };
    let addresseeGroups = [
      {
        addresseeGroups: []
      }
    ];
    const isSignature = userEntitiesSIGN.userEntities.length > 0;
    const isApproval = userEntitiesAPPROVAL.userEntities.length > 0;
    if (isApproval) {
      addresseeGroups[0].addresseeGroups.push(userEntitiesAPPROVAL);
    }
    if (isSignature) {
      addresseeGroups[0].addresseeGroups.push(userEntitiesSIGN);
    }
    return addresseeGroups;
  }

  getEmployeeTypes(contractTypeId: number, parameter: number, additionalValueId?: number) {
    this.jobOfferService.getEmployeeType(contractTypeId).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this._toastService.error(err);
          });
        }

        this.additionalValueList = res.dataList?.filter(e => e?.status)?.map(x => {
          return {
            additionalValue: x.employeeTypeId,
            additionalValueName: x.description
          }
        })
        this.employeeTypeList = res.dataList?.filter(d => d?.status);
        if (parameter == 13) {
          this.getDropDownOptions(parameter)
        }
      },
      error: (err) => {
        this._toastService.error('Ocurrió un error tratando de buscar los tipos de Servidores Públicos. Favor de comunicarse con un Administrador.')
      }
    })
  }
  getBudgetAreas(valueAfterId?: number) {
    this.jobOfferService.getBudgetAreas().subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          res.errors.forEach(err => {
            this._toastService.error(err);
          });
          return
        }
        this.budgetAreas = res.dataList.filter(x => x.status == true);
        this.options = this.budgetAreas;
        if (this.isEdittingEmployeeChange) {
          this.actionChangeForm.get('newData').setValue(this.options?.find(o => o?.budgetAreaId === valueAfterId))
        }
      },
      error: (err) => {
        this._toastService.error('Ocurrió un error tratando de buscar los tipos de Servidores Públicos. Favor de comunicarse con un Administrador.')
      }
    })
  }

  openDeclarationModal(formItem: FormGroup, isEditing?: boolean) {

    if (isEditing && !formItem.get('deviceAsset').value) {
      return
    }

    this.dialog.open(DeclarationModalComponent, {
      data: {
        formItem,
        isEditing
      }

    }).afterClosed().subscribe({
      next: res => {
        if (!res.created) {
          return
        }
        formItem.get('deviceBrand').setValue(res.deviceInfo?.deviceBrand)
        formItem.get('deviceModel').setValue(res.deviceInfo?.deviceModel)
        formItem.get('deviceAsset').setValue(res.deviceInfo?.deviceAsset)
        formItem.get('deviceSerial').setValue(res.deviceInfo?.deviceSerial)
        formItem.get('deviceDeliveredDate').setValue(res.deviceInfo?.deviceDeliveredDate)
      }
    })
  }

  openSwornDeclarationModal(entryListHistoryId: number) {
    this.dialog.open(ShowSwornDeclarationComponent, {
      data: {
        entryListHistoryId
      },
      width: '100%'
    })
  }

  editEmployeeChange(employeeChange) {

    this.clearEditting();


    this.newChanges = [{ ...employeeChange }];
    this.newChangesRecordsOriginal = [{ ...employeeChange }];

    this.currentChangeConditionOnEditting = employeeChange?.condition;
    this.isEdittingEmployeeChange = true;

    const parameter = this.fields.find(f => f?.ocode === employeeChange?.parameterChangeId);
    //this.fillCurrentData(parameter?.ocode);
    const motive = this.motives.find(m => m?.ocode === employeeChange?.reasonId)
    const modificationReason = this.modificationReasons.find(m => m?.ocode === employeeChange?.modificationReasonId)

    this.actionChangeForm.patchValue({
      ...employeeChange,
      parameter: parameter,
      newDataId: employeeChange?.valueAfterId,
      motive: motive,
      createUserId: employeeChange.createUserId,
      oldModificationReason: modificationReason,
      oldModificationComment: employeeChange?.modificationComment ?? ''
    });

    this.oldModificationReasonSelected = modificationReason;

    if (employeeChange.evidence) {
      this.file.document = employeeChange?.evidence;
      this.guiID = employeeChange?.evidence;
      this.getEvidence(employeeChange?.evidence);
    }
    this.setCurrentData(parameter?.ocode, employeeChange?.valueBeforeId, employeeChange?.valueBefore);
    this.setNewData(parameter?.ocode, employeeChange?.valueAfterId, employeeChange?.additionalValue, employeeChange?.valueAfter);
    this.actionChangeForm.get('effectiveDate').setValue(formatDate(employeeChange?.effectiveDate, 'yyyy-MM-dd', 'en'));
    this.actionChangeForm.get('parameter').disable();
    this.actionChangeForm.get('oldModificationReason').disable();
    this.actionChangeForm.get('newModificationReason').setValidators(Validators.required);
    this.actionChangeForm.get('newModificationReason').updateValueAndValidity();

    if (employeeChange?.parameterChangeId == 4) {
      this.verifySalary();
    }
  }

  isShowButtonEdit(employeeChange) {
    return formatDate(employeeChange?.effectiveDate, 'yyyy-MM-dd', 'en') > formatDate(this.currentDate, 'yyyy-MM-dd', 'en')
  }

  getEvidence(guid) {
    this.srvDoc.getDocument(guid).subscribe(res => {
      this.file.document = res.data ? res.data : ''
    })
  }


  setNewData(fieldCode: number, valueAfterId, additionalValueId?: number, valueAfter?: any) {
    switch (fieldCode) {
      case 1: //'Departamento'
        this.actionChangeForm.get('newData').setValue(this.options?.find(o => o.departmentId === valueAfterId))
        break;
      case 2: //'Posición/Cargo'
        this.actionChangeForm.get('newData').setValue(this.options?.find(o => o?.positionId === valueAfterId))
        break;
      case 3: //'Tipo Contratación'
        this.actionChangeForm.get('newData').setValue(this.options?.find(o => o?.ocode === valueAfterId))
        this.getEmployeeTypes(valueAfterId, null, additionalValueId);
        break;
      case 7: //'Recinto'
        this.actionChangeForm.get('newData').setValue(this.options?.find(o => o.companyId === valueAfterId))
        break;
      case 14: //'AREA PRESUPUESTARIA'
        this.getBudgetAreas(valueAfterId);
        break;
      case 11: //'Inicio Contrato'
        this.actionChangeForm.get('newData').setValue(formatDate(valueAfter, 'yyyy-MM-dd', 'en'))
        break;
      case 12: //'Exp. Contrato'
        this.actionChangeForm.get('newData').setValue(formatDate(valueAfter, 'yyyy-MM-dd', 'en'))
        break;
      default: //Cualquier otro
        this.actionChangeForm.get('newData').setValue(valueAfter)
        break;
    }
  }

  setCurrentData(parameter: number, valueBeforeId: number, valueBefore?: any) {
    this.getDropDownConfig(parameter)
    this.getDropDownOptions(parameter)
    this.additionalValueList = [];
    this.employeeTypeList = [];
    switch (parameter) {
      case 1: //'Departamento'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        break;
      case 2: //'Posición/Cargo'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        break;
      case 4: //'Sueldo'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'text'
        break;
      case 3: //'Tipo Contratación'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop-additional';
        this.additionalValueFieldName = 'Tipo de Servidor Público';
        break;
      case 7: //'Recinto'
        this.actionChangeForm.get('currentData').setValue(this.locations.find(lt => lt?.companyId == valueBeforeId)?.socialReason)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        break;
      case 9:
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        break;
      case 10:
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        break;
      case 11: //'Inicio Contrato'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'date'
        break;
      case 12: //'Exp. Contrato'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'date'
        break;
      case 13: //'Tipo Servidor Público'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        if (this.additionalValueList.length === 0) {
          this.getEmployeeTypes(valueBeforeId, parameter);
        }
        break;
      case 14: //'Área presupuestaria'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        if (this.budgetAreas.length === 0) {
          this.getBudgetAreas();
        }
        break;

      default:
        break;
    }

    this.verifySalary();

  }

  disableAll() {
    this.actionChangeForm.disable();
    this.employeeEntryForm.disable();


    let btns: any = document.getElementsByClassName('btn');
    for (let i = 0; i < btns.length; i++) {
      btns[i].disabled = true;
    }

    let uppies: any = document.getElementsByClassName('uppy-Dashboard');
    for (let i = 0; i < uppies.length; i++) {
      uppies[i].classList.add("uppy-Dashboard--isDisabled");
    }

    let ndb: any = document.getElementsByClassName('ngx-dropdown-button');
    for (let i = 0; i < ndb.length; i++) {
      ndb[i].classList.add("ngx-disabled");
    }


    let inputs: any = document.getElementsByTagName("INPUT");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].disabled = true;
    }

    let areas: any = document.getElementsByTagName("TEXTAREA");
    for (let i = 0; i < areas.length; i++) {
      areas[i].disabled = true;
    }

    let selects: any = document.getElementsByTagName("SELECT");
    for (let i = 0; i < selects.length; i++) {
      selects[i].disabled = true;
    }

    let buttons: any = document.getElementsByTagName("BUTTON");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = true;
    }

    this.hiddeButtons();

    setTimeout(() => {
      this.unHiddeAllowedButtons();
    }, 1000);


  }

  hiddeButtons() {

    const btnS: any = document.getElementsByClassName('btn-success');
    for (let i = 0; i < btnS.length; i++) {
      btnS[i].classList.add("d-none");
    }
    const btnS2: any = document.getElementsByClassName('btn-light-success');
    for (let i = 0; i < btnS2.length; i++) {
      btnS2[i].classList.add("d-none");
    }
    const btnsW: any = document.getElementsByClassName('btn-light-warning');
    for (let i = 0; i < btnsW.length; i++) {
      btnsW[i].classList.add("d-none");
    }
    const btnsW2: any = document.getElementsByClassName('btn-warning');
    for (let i = 0; i < btnsW2.length; i++) {
      btnsW2[i].classList.add("d-none");
    }
    const btnsD: any = document.getElementsByClassName('btn-danger');
    for (let i = 0; i < btnsD.length; i++) {
      btnsD[i].classList.add("d-none");
    }
    const btnsD2: any = document.getElementsByClassName('btn-light-danger');
    for (let i = 0; i < btnsD2.length; i++) {
      btnsD2[i].classList.add("d-none");
    }

  }

  unHiddeAllowedButtons() {
    const goBackBu: any = document.getElementsByClassName("goBackButton");
    for (let i = 0; i < goBackBu.length; i++) {
      goBackBu[i].classList.remove("d-none");
      goBackBu[i].disabled = false;
    }

    const btni: any = document.getElementsByClassName("btn-light-info");
    for (let i = 0; i < btni.length; i++) {
      btni[i].classList.remove("d-none");
      btni[i].disabled = false;
    }

    const awaf: any = document.getElementsByClassName("allow-view-after-fired");
    for (let i = 0; i < awaf.length; i++) {
      awaf[i].classList.remove("d-none");
      awaf[i].disabled = false;
    }
  }

  exportToPdf(changeDetail) {
    let reportUrl = ''
    const { employeeChangeDetailsId, employeeId, parameterChangeId } = changeDetail
    const companyId: number = this.authInfo.getCompanyId();
    const employeechangedetailsId = employeeChangeDetailsId;

    switch (parameterChangeId) {
      case 1:
        reportUrl = `${environment.reportUrl}/?ReportCode=rpt_ChangesDepartment&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeechangedetailsId}`
        break;

      case 2:
        reportUrl = `${environment.reportUrl}/?ReportCode=rpt_Changeposition&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeechangedetailsId}`
        break;

      case 3:
        reportUrl = `${environment.reportUrl}/?ReportCode=rpt_Changecontractedfile&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeechangedetailsId}`
        break;

      case 4:
        reportUrl = `${environment.reportUrl}/?ReportCode=rpt_ChangesSalary&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeechangedetailsId}`
        break;

      case 7:
        reportUrl = `${environment.reportUrl}/?ReportCode=rpt_ChangeBranch&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeechangedetailsId}`
        break;

      case 11:
        reportUrl = `${environment.reportUrl}/?ReportCode=rpt_Changecontractedfile&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeechangedetailsId}`
        break;
      case 13:
        reportUrl = `${environment.reportUrl}/?ReportCode=rpt_ChangesServertype&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeechangedetailsId}`
        break;

      case 14:
        reportUrl = `${environment.reportUrl}/?ReportCode=rpt_ChangeBugetArea&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeechangedetailsId}`
        break;

      default:
        break;
    }

    let parameters = {
      url: reportUrl,
      title: 'Consulta Acciones de Personal',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }


  exportDesignation(designation) {
    const { actionsEmployeeId, employeeId, companyId } = designation
    let url = `${environment.reportUrl}/?ReportCode=rpt_Changesadmissiondesignation&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${actionsEmployeeId}`
    let parameters = {
      url: url,
      title: 'Reporte Designaciones',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  viewPdf(formData: FormData): void {
    const pdfFile = formData.get('file');

    if (pdfFile && pdfFile instanceof Blob) {
      const pdfUrl = URL.createObjectURL(pdfFile);
      window.open(pdfUrl); 
    } 
  }

}
