import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { PayrollFlyerService } from 'src/app/services/payroll-flyer.service';
import { ResultPayrollVoucherMail } from '../models/result-payroll-voucher-mail.model';

@Component({
  selector: 'app-result-payroll-voucher-mail',
  templateUrl: './result-payroll-voucher-mail.component.html',
  styleUrls: ['./result-payroll-voucher-mail.component.css']
})
export class ResultPayrollVoucherMailComponent implements OnInit {
  resultPayrollVoucherMail: ResultPayrollVoucherMail[] = [];
  resultPayrollVoucherMailOriginalList: ResultPayrollVoucherMail[] = [];
  paginatedResultPayrollVoucherMail: ResultPayrollVoucherMail[] = [];
 

  constructor(
    public dialogRef: MatDialogRef<ResultPayrollVoucherMailComponent>,
    private _toastService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.resultPayrollVoucherMail = [...this.data as ResultPayrollVoucherMail[]];
    this.resultPayrollVoucherMailOriginalList = [...this.data as ResultPayrollVoucherMail[]];
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  getPaginatedRecords(event) {
    this.paginatedResultPayrollVoucherMail = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }


}
