import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Period } from '@payroll/schedule/models/period.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { OvertimeActivityService } from 'src/app/services/overtime-activity.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollReportService } from 'src/app/services/payroll-report.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { dropdownGlobalConfig, openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-overtime-report',
  templateUrl: './overtime-report.component.html',
  styleUrls: ['./overtime-report.component.css']
})
export class OvertimeReportComponent implements OnInit {
  periods: Period[] = [];
  filteredPeriods: Period[] = [];
  yearsList: any[] = []
  payrollTypes: any[] = [];
  form: FormGroup;
  budgetAreaList: any[] = [];
  payrollVersions: any[] = [];
  selectedPayrollType: any;
  relatedPeriods = [];

  dropdownConfigDescription = { ...dropdownGlobalConfig('monthName') };
  periodSelected;

  constructor(
    private parameterServices: ParameterControlService,
    private payrollReportService: PayrollReportService,
    private _toastService: ToastService,
    private fb: FormBuilder,
    public authInfo: AuthInfoService,
    private location: Location,
    private payrollService: PayrollService,
    private srvOvertimeActivity: OvertimeActivityService,
  ) {

  }

  ngOnInit(): void {
    this.getPeriods();
  }

  getPeriods(){
    this.srvOvertimeActivity.getOvertimeActivityPeriods(this.authInfo.getCompanyId()).subscribe({
      next: (res:any) => {
        if(res.errors.length>0){this._toastService.error(res.errors[0]);return;}
        this.periods = res.dataList

      }, error: (error:any) => {this._toastService.error("Ha ocurrido un error inesperado")}
    })
  }

  goBack() {
    this.location.back();
  }




  openReport(){
    console.log(this.periodSelected)
    if (!this.periodSelected?.monthName) {
      this._toastService.warning('Debe llenar todos los campos previamente');
      return;
    }

    
    const url = `${environment.reportUrl}?Reportcode=OvertimeReportPayment&Month=${this.periodSelected.month}&Year=${this.periodSelected.year}&CompanyId=${this.authInfo.getCompanyId()}&PropkeyId=0&SearchParameter=&Page=1&PageSize=10000`;
    openReport({
      url: url,
      title: 'Reporte de Horas Extras',
      width: '1024',
      height: '768'
    });
  }

}
