import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormatTypes } from '../../../shared/dynamic-format/enums/format-types';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../shared/loading/loading.service';
import { formatDate, Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { docService } from 'src/app/services/doc.service';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { IEmployeeChangeDetails } from 'src/app/models/EmployeeChangeDetails.model';
import { ToastrService } from 'ngx-toastr';
import { IEmployeeChangeHeader } from 'src/app/models/EmployeeChangeHeader.model';
import { IEmployee } from 'src/app/models/Employee.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { DepartmentService } from 'src/app/services/department.service';
declare const $: any

@Component({
  selector: 'app-pdf-personnel-actions-regulated',
  templateUrl: './pdf-personnel-actions-regulated.component.html',
  styleUrls: ['./pdf-personnel-actions-regulated.component.css']
})


export class PdfPersonnelActionsRegulatedComponent implements OnInit {
  readonly personalRequestRoute = 'hcm/personal-request';
  public loadingPic = true
  supervisorNames = {
    hhrr: '',
    rectory: '',
    employeeSupervisor: ''
  }
  subscriptions: Subscription = new Subscription();
  employee: IEmployee;
  isTableLoading: boolean = true;
  changeDetail:any
  changeHeader: IEmployeeChangeHeader;
  document: any;
  photoUrl: string;
  profileImage: string;
  fileModalConfig = {
    src: '',
    type: '',
    filename: '',
    width: 225,
    height: 225,
    formTitle: '',
    formSubTitle: '',
  };

  pdfOpt: any;

  public company: any;
  file = { logo: '', image1: '', image2: '' }

  constructor(
    private srvActionChange: PersonnelActionsService,
    private _route: ActivatedRoute,
    public loadingService: LoadingService,
    private location: Location,
    private toastr: ToastrService,
    private srvDoc: docService,
    private authInfo: AuthInfoService,
    private _departmentService: DepartmentService,
    private _companiesService: CompaniesService) {
    this.pdfOpt = {
      orientation: 'p',
      unit: 'in',
      format: [11, 8.5]
    };
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let employeeChangeHeaderId = Number(this._route.snapshot.params["id"] || 0)
    this.getEmployeeChangeDetails(employeeChangeHeaderId);
    this.companyById(this.authInfo.getCompanyId());
  }

  goBack() {
    this.location.back();
  }

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  openGuID(guid) {
    try {
      this.srvDoc.getDocument(guid).subscribe(e => {
        window.open(e.data, '_blank');
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  getProfileImage(profileImageGUID) {
    this.srvDoc.getDocument(profileImageGUID).subscribe(e => {
      this.profileImage = e.data
    }, error => {
      this.toastr.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  getEmployeeChangeDetails(id) {
    this.srvActionChange.getChangeDetails(id, 1).subscribe((res: any) => {
      if (res.succeded) {
        this.changeDetail = res.dataList[0];
        this.getEmployeeById()

      } else {
        this.toastr.error(res.errors[0]);
      }
    }, error => {
      this.toastr.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  isNumeric(value): boolean {
    if (value == null || value == undefined) {
      return false;
    }
    return !isNaN(Number(value));
  }

  isDate(date): boolean {
    if (this.isNumeric(date)) {
      return false;
    }

    let regExp = /[a-zA-Z]/g;
    if (regExp.test(date)) {
      return false;
    }

    return (new Date(date).toString() !== "Invalid Date") && !isNaN(Number(new Date(date)));
  }

  getEmployeeById() {
    this.srvActionChange.getEmployeeById(this.changeDetail.employeeId).subscribe((res: any) => {
      if (res.succeded) {
        this.employee = res.dataList[0]
        this.isTableLoading = false;
        const departmentId = this.employee.departmentId
        this.getDepartments(departmentId)
        if (this.employee.profileImage) {
          this.getProfileImage(this.employee.profileImage)
        }
      } else {
        this.toastr.error(res.errors[0]);
      }
    }, error => {
      this.toastr.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }
  showModal(modalName: string): void {
    $(`#${modalName}`).modal('show');
  }
  getDepartments(employeeSupervisorId) {
    this._departmentService.getAll().subscribe((res: any) => {
      if (res.succeded) {
        const departments = res.dataList
        const hhrrDepartment = departments.find(x => x.isHhrr == true)
        const employeeDepartment = departments.find(x => x.departmentId == employeeSupervisorId)
        const rectoryDepartment = departments.find(x => x.departmentId == 1000)
        this.supervisorNames.employeeSupervisor = this.getSupervisorName(employeeDepartment)
        this.supervisorNames.rectory = this.getSupervisorName(rectoryDepartment)
        this.supervisorNames.hhrr = this.getSupervisorName(hhrrDepartment)
      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }
  getSupervisorName(department): string {
    if (!department) return ''
    if (department?.supervisor == "No Definido" || !department?.supervisor) {
      return ''
    }
    return department?.supervisor;
  }

}
