import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { removeWhitespace } from 'src/app/shared/utils/utility';

@Component({
  selector: 'app-add-dependents',
  templateUrl: './add-dependents.component.html',
  styleUrls: ['./add-dependents.component.css']
})
export class AddDependentsComponent implements OnInit {

  dependentsForm: FormGroup = new FormGroup({
    depedentName: new FormControl(null, Validators.required),
    relationShip: new FormControl(null, Validators.required),
    maritalStatus: new FormControl(null, Validators.required),
    birthDay: new FormControl(null, Validators.required),
    age: new FormControl(null, Validators.required),
    isWorking: new FormControl(null, Validators.required),
    isStudying: new FormControl(null, Validators.required),
    isDependent: new FormControl(null, Validators.required),
    workPlace: new FormControl(null),
    studyingPlace: new FormControl(null),
  })
  relationshipsList: any[] = []
  maritalStatusList: any[] = []

  constructor(
    public dialogRef: MatDialogRef<AddDependentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _toast: ToastService
  ) {
    this.dialogRef.disableClose = true;
    this.relationshipsList = data.relationshipsList
    this.maritalStatusList = data.maritalStatusList

    if (this.data.isEditing) {
      let dependentInfo =  this.data.dependentInfo
      this.onBirthdayChange(dependentInfo?.birthday)
      this.dependentsForm.patchValue({
        depedentName: dependentInfo?.fullName,
        relationShip: this.relationshipsList.find((relationship:any)=> relationship.id == dependentInfo?.relationshipId),
        maritalStatus: this.maritalStatusList.find((maritalStatus:any)=> maritalStatus.ocode == dependentInfo?.maritalStatusId),
        birthDay:dependentInfo?.birthday,
        isWorking:dependentInfo?.job,
        isStudying:dependentInfo?.study,
        isDependent:dependentInfo?.dependent,
        workPlace:dependentInfo?.placeJob,
        studyingPlace:dependentInfo?.placeStudy,
      })
    }
  }

  ngOnInit(): void {

  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }


  onBirthdayChange(birthday) {
    if (!birthday) {
      return;
    }

    let dateToday = new Date();
    let dateBirth = new Date(birthday);

    if (dateBirth > dateToday) {
      this.dependentsForm.get('age').patchValue(0);
      return;
    }

    let time = dateToday.getTime() - dateBirth.getTime();

    //1000 = milliseconds
    //3600 = seconds
    //24 = hours
    let days = time / (1000 * 3600 * 24);


    let age = days / 365;

    age = parseInt(age.toString())

    this.dependentsForm.get('age').patchValue(age);
  }

  Cancel() {
    this.dialogRef.close()
  }

  Accept() {
    if (this.dependentsForm.invalid) {
      return
    }

    let dependentData = {
      ...this.dependentsForm.value
    }
    this.dialogRef.close(dependentData)
  }


  removeWhitespaces(event, control){
    removeWhitespace(event, control)
  }


}
