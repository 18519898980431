import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ActionEmployComponent, ActionEmployListComponent, NewsListComponent, ActionEditComponent, OpenPayrollComponent, LoadMovementComponent, AuditApprovalComponent, ProcessPayrollComponent, PayrollReportComponent, PaymentFlyerComponent, FlyerSendPrintComponent, BulkLoadNewsComponent} from '@payroll/index';
import { payrollRoutingModule } from './pay-roll-routing.module';
import { ScheduleComponent } from './schedule/schedule.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HomeComponent } from '../home/home.component';
import { AddModalComponent } from './schedule/add-modal/add-modal.component';
import { ListPayrollComponent } from './open-payroll/list-payroll/list-payroll.component';
import { ManagePayrollComponent } from './manage-payroll/manage-payroll.component';
import { ToastrModule } from 'ngx-toastr';
import { BudgetAreasComponent } from './budget-areas/budget-areas.component';
import { RecurringNewsComponent } from './recurring-news/recurring-news.component';
import { BulkLoadNewsComponent as BulkLoadRecurringNewsComponent } from './recurring-news/bulk-load-news/bulk-load-news.component';
import { PayrollDetailViewComponent } from '@payroll/open-payroll/payroll-detail-view/payroll-detail-view.component';
import { SearchEmployeeComponent } from './action-edit/search-employee/search-employee.component';
import { NgxMaskModule } from 'ngx-mask';
import { PayrollPeriodService } from 'src/app/services/payroll-period.service';
import { DetailsNewsComponent } from './open-payroll/payroll-detail-view/details-news/details-news.component';
import { IndicatorsModalComponent } from './audit-approval/indicators-modal/indicators-modal.component';
import { CurrentPayrollTabComponent } from './audit-approval/indicators-modal/current-payroll-tab/current-payroll-tab.component';
import { PreviousPayrollTabComponent } from './audit-approval/indicators-modal/previous-payroll-tab/previous-payroll-tab.component';
import { CreateBudgetAreaComponent } from './budget-areas/create-budget-area/create-budget-area.component';
import { RejectPayrollModalComponent } from './audit-approval/reject-payroll-modal/reject-payroll-modal.component';
import { AdministrativeAndTeachingEmployeesComponent } from './payroll-report/administrative-and-teaching-employees/administrative-and-teaching-employees.component';
import { PaymentVoucherComponent } from './payment-flyer/payment-voucher/payment-voucher.component';
import { DeletePayrollNewComponent } from './news-list/delete-payroll-new/delete-payroll-new.component';
import { PayrollFlyerService } from 'src/app/services/payroll-flyer.service';
import { DashboardPayrollComponent } from './open-payroll/dashboard-payroll/dashboard-payroll.component';
import { DashboardNewsListComponent } from './news-list/dashboard-news-list/dashboard-news-list.component';
import { DashboardManagePayrollComponent } from './dashboard-manage-payroll/dashboard-manage-payroll.component';
import { DashboardAuditApprovalComponent } from './dashboard-audit-approval/dashboard-audit-approval.component';
import { FlyerComponent } from './payroll-report/flyer/flyer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PayrollNewDetailComponent } from './manage-payroll/payroll-new-detail/payroll-new-detail.component';
import { PDepartmentDetailsModalComponent } from './audit-approval/indicators-modal/p-department-details-modal/p-department-details-modal.component';
import { LastPayrollTabComponent } from './audit-approval/indicators-modal/last-payroll-tab/last-payroll-tab.component';
import { FinalPayrollComponent } from './final-payroll/final-payroll.component';
import { ResultPayrollVoucherMailComponent } from './payment-flyer/result-payroll-voucher-mail/result-payroll-voucher-mail.component';
import { BulkLoadFinalNomComponent } from './final-payroll/bulk-load-final-nom/bulk-load-final-nom.component';
import { FinalPayrollSearchModalComponent } from './final-payroll/final-payroll-search-modal/final-payroll-search-modal.component';
import { FinalPayrollAddnewsModalComponent } from './final-payroll/final-payroll-addnews-modal/final-payroll-addnews-modal.component';
import { NewsDetailsComponent } from './final-payroll/news-details/news-details.component';
import { NewsToApproveModalComponent } from './final-payroll/news-to-approve-modal/news-to-approve-modal.component';
import { FinalPayrollDeleteNewsComponent } from './final-payroll/final-payroll-delete-news/final-payroll-delete-news.component';
import { PdfPaymentVoucherComponent } from './payment-flyer/pdf-payment-voucher/pdf-payment-voucher.component';
import { ViewDetailRejectPayrollComponent } from './open-payroll/view-detail-reject-payroll/view-detail-reject-payroll.component';
import { AdditionalPayrollComponent } from './additional-payroll/additional-payroll.component';
import { bulkLoadFinalErrosTableComponent } from './final-payroll/bulk-load-final-nom/bulk-load-final-erros-table/bulkLoadFinalErrosTable.component';
import { AddModalIncentivePayrollComponent } from './incentive-payroll/add-modal-incentive-payroll/add-modal-incentive-payroll.component';
import { IncentiveAddNewsComponent } from './incentive-payroll/incentive-add-news/incentive-add-news.component';
import { AdditionalPayrollSearchModalComponent } from './additional-payroll/additional-payroll-search-modal/additional-payroll-search-modal.component';
import { AdditionalPayrollAddNewsModalComponent } from './additional-payroll/additional-payroll-add-news-modal/additional-payroll-add-news-modal.component';
import { AdditionalPayrollDeleteNewsModalComponent } from './additional-payroll/additional-payroll-delete-news-modal/additional-payroll-delete-news-modal.component';
import { AdditionalPayrollNewsDetailModalComponent } from './additional-payroll/additional-payroll-news-detail-modal/additional-payroll-news-detail-modal.component';
import { AdditionalPayrollApproveNewsModalComponent } from './additional-payroll/additional-payroll-approve-news-modal/additional-payroll-approve-news-modal.component';
import { AdditionalPayrollBulkLoadComponent } from './additional-payroll/additional-payroll-bulk-load/additional-payroll-bulk-load.component';

import { IncentivePayrollComponent } from './incentive-payroll/incentive-payroll.component';
import { PayrollHomeComponent } from './payroll-home/payroll-home.component';
import { IncentivePayrollBulkLoadComponent } from './incentive-payroll/incentive-payroll-bulk-load/incentive-payroll-bulk-load.component';
import { ExpenseDetailModalComponent } from './process-payroll/expense-detail-modal/expense-detail-modal.component';
import { ExpenseItemsComponent } from './process-payroll/expense-items/expense-items.component';
import { StateSigeiComponent } from './process-payroll/state-sigei/state-sigei.component';
import { DisapprovePayrollNewsComponent } from './news-list/disapprove-payroll-news/disapprove-payroll-news.component';
import { PdfPaymentMultipleVoucherComponent } from './payment-flyer/pdf-payment-multiple-voucher/pdf-payment-multiple voucher.component';
import { PayrollConceptComponent } from './payroll-concept/payroll-concept.component';
import { AddConceptModalComponent } from './payroll-concept/add-concept-modal/add-concept-modal.component';
import { GeneralComponent } from './payroll-concept/taps-concept/general/general.component';
import { FilterComponent } from './payroll-concept/taps-concept/filter/filter.component';
import { ApplicationComponent } from './payroll-concept/taps-concept/application/application.component';
import { NormativeComponent } from './payroll-concept/taps-concept/normative/normative.component';
import { ConceptGroupComponent } from './payroll-concept/taps-concept/concept-group/concept-group.component';
import { FormulaComponent } from './payroll-concept/taps-concept/formula/formula.component';
import { FormulaModalComponent } from './payroll-concept/formula-modal/formula-modal.component';
import { BeneficiariesComponent } from './beneficiaries/beneficiaries.component';
import { BeneficiariesModalComponent } from './beneficiaries/beneficiaries-modal/beneficiaries-modal/beneficiaries-modal.component';
import { BulkLoadPayrollConceptComponent } from './payroll-concept/bulk-load-payroll-concept/bulk-load-payroll-concept.component';
import { ReasonDisapproveNewsComponent } from './news-list/reason-disapprove-news/reason-disapprove-news.component';
import { AdditionalSettingsComponent } from './payroll-concept/taps-concept/additional-settings/additional-settings.component';
import { DefaultValueSettingsComponent } from './payroll-concept/taps-concept/default-value-settings/default-value-settings.component';
import { DefaultValueSettingComponent } from './payroll-concept/taps-concept/default-value-settings/default-value-setting/default-value-setting.component';
import { PayrollStatisticsComponent } from './statistics/payroll-statistics/payroll-statistics.component';
import { PayrollParcialComponent } from './payroll-report/payroll-parcial/payroll-parcial.component';
import { PayrollAuditReportComponent } from './payroll-report/payroll-audit-report/payroll-audit-report.component';
import { IndividualPayrollReportComponent } from './payroll-report/individual-payroll-report/individual-payroll-report.component';
import { EmployeeConceptsComponent } from './payroll-report/employee-concepts/employee-concepts.component';
import { AuditExcelComponent } from './payroll-report/audit-excel/audit-excel.component';
import { PaymentVoucherVersion2Component } from './payment-flyer/payment-voucher-version2/payment-voucher-version2.component';
import { AddExpenseDetailModalComponent } from './process-payroll/add-expense-detail-modal/add-expense-detail-modal.component';
import { ItemExpensesDetailComponent } from './process-payroll/item-expenses-detail/item-expenses-detail.component';
import { OtherBeneficiaryModalComponent } from './process-payroll/other-beneficiary-modal/other-beneficiary-modal.component';
import { PayrollGeneralComponent } from './payroll-report/payroll-general/payroll-general.component';
import { EmployeesPayrollComponent } from './process-payroll/employees-payroll/employees-payroll.component';
import { KeysConfigurationComponent } from './payroll-report/keys-configuration/keys-configuration.component';
import { CertificationCodesComponent } from './certification-codes/certification-codes.component';
import { CertificationCodeComponent } from './certification-codes/certification-code/certification-code.component';
import { DeleteCertificationCodeComponent } from './certification-codes/delete-certification-code/delete-certification-code.component';
import { OpenAdditionalPayrollComponent } from './additional-payroll/open-additional-payroll/open-additional-payroll.component';
import { ExcludeEmployeePayrollModalComponent } from './modals/exclude-employee-payroll-modal/exclude-employee-payroll-modal.component';
import { BankAccountsComponent } from './payroll-report/bank-accounts/bank-accounts.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AdvancedFilterByPayrollConceptComponent } from './payroll-concept/advanced-filter-by-payroll-concept/advanced-filter-by-payroll-concept.component';
import { AdvancedFilterByPayrollNewsListComponent } from './news-list/advanced-filter-by-payroll-news-list/advanced-filter-by-payroll-news-list.component';
import { DataValidationPayrollNewsDetailModalComponent } from './news-list/bulk-load-news/data-validation-payroll-news-detail-modal/data-validation-payroll-news-detail-modal.component';
import { AdvancedSearchComponent } from './payment-flyer/advanced-search/advanced-search.component';
import { AuditChangesComponent } from './payroll-concept/audit-changes/audit-changes.component';
import { OvertimeReportComponent } from './payroll-report/overtime-report/overtime-report.component';
import { PayrollComparisonReportComponent } from './payroll-report/payroll-comparison-report/payroll-comparison-report.component';
import { RejectFilesHaciendaComponent } from './process-payroll/reject-files-hacienda/reject-files-hacienda.component';
import { PayrollRoyaltySummaryComponent } from './open-payroll/payroll-royalty-summary/payroll-royalty-summary.component';
import { PayrollRoyaltyDetailComponent } from './open-payroll/payroll-royalty-summary/payroll-royalty-detail/payroll-royalty-detail.component';
import { RoyaltyDetailAddModalComponent } from './open-payroll/payroll-royalty-summary/royalty-detail-add-modal/royalty-detail-add-modal.component';
import { RoyaltyDetailBulkloadModalComponent } from './open-payroll/payroll-royalty-summary/royalty-detail-bulkload-modal/royalty-detail-bulkload-modal.component';
import { DataValidationRoyaltyDetailModalComponent } from './open-payroll/payroll-royalty-summary/royalty-detail-bulkload-modal/data-validation-royalty-detail-modal/data-validation-royalty-detail-modal.component';
import { OvertimeIndividualReportComponent } from './payroll-report/overtime-individual-report/overtime-individual-report.component';


//import { ModalComponent } from 'src/app/shared/element-ui/modal/modal.component';
// import { AddModalComponent } from './schedule/add-modal/add-modal.component';
// import { ActionEditComponent } from './action-edit/action-edit.component';
// import { NovedadesListComponent } from './novedades-list/novedades-list.component';

@NgModule({
  declarations: [
    ActionEmployListComponent,
    ActionEmployComponent,
    ScheduleComponent,
    NewsListComponent,
    ActionEditComponent,
    AddModalComponent,
    ListPayrollComponent,
    OpenPayrollComponent,
    LoadMovementComponent,
    AuditApprovalComponent,
    ProcessPayrollComponent,
    PayrollReportComponent,
    PaymentFlyerComponent,
    FlyerSendPrintComponent,
    BulkLoadNewsComponent,
    ManagePayrollComponent,
    BudgetAreasComponent,
    RecurringNewsComponent,
    BulkLoadRecurringNewsComponent,
    PayrollDetailViewComponent,
    SearchEmployeeComponent,
    CreateBudgetAreaComponent,
    IndicatorsModalComponent,
    CurrentPayrollTabComponent,
    PreviousPayrollTabComponent,
    CreateBudgetAreaComponent,
    RejectPayrollModalComponent,
    DetailsNewsComponent,
    PaymentVoucherComponent,
    AdministrativeAndTeachingEmployeesComponent,
    DeletePayrollNewComponent,
    DashboardPayrollComponent,
    DashboardNewsListComponent,
    DashboardManagePayrollComponent,
    DashboardAuditApprovalComponent,
    FlyerComponent,
    PayrollNewDetailComponent,
    PDepartmentDetailsModalComponent,
    LastPayrollTabComponent,
    FinalPayrollComponent,
    BulkLoadFinalNomComponent,
    FinalPayrollAddnewsModalComponent,
    NewsDetailsComponent,
    ResultPayrollVoucherMailComponent,
    BulkLoadFinalNomComponent,
    FinalPayrollSearchModalComponent,
    NewsToApproveModalComponent,
    FinalPayrollDeleteNewsComponent,
    PdfPaymentVoucherComponent,
    ViewDetailRejectPayrollComponent,
    IncentivePayrollComponent,
    AdditionalPayrollComponent,
    bulkLoadFinalErrosTableComponent,
    AddModalIncentivePayrollComponent,
    IncentiveAddNewsComponent,
    AdditionalPayrollSearchModalComponent,
    AdditionalPayrollAddNewsModalComponent,
    AdditionalPayrollDeleteNewsModalComponent,
    AdditionalPayrollNewsDetailModalComponent,
    AdditionalPayrollApproveNewsModalComponent,
    AdditionalPayrollBulkLoadComponent,
    PayrollHomeComponent,
    IncentivePayrollBulkLoadComponent,
    ExpenseDetailModalComponent,
    ExpenseItemsComponent,
    StateSigeiComponent,
    DisapprovePayrollNewsComponent,
    PdfPaymentMultipleVoucherComponent,
    PayrollConceptComponent,
    AddConceptModalComponent,
    GeneralComponent,
    FilterComponent,
    ApplicationComponent,
    NormativeComponent,
    ConceptGroupComponent,
    FormulaComponent,
    FormulaModalComponent,
    BeneficiariesComponent,
    BeneficiariesModalComponent,
    BulkLoadPayrollConceptComponent,
    ReasonDisapproveNewsComponent,
    AdditionalSettingsComponent,
    DefaultValueSettingsComponent,
    DefaultValueSettingComponent,
    PayrollStatisticsComponent,
    PayrollParcialComponent,
    PayrollAuditReportComponent,
    IndividualPayrollReportComponent,
    EmployeeConceptsComponent,
    AuditExcelComponent,
    PaymentVoucherVersion2Component,
    AddExpenseDetailModalComponent,
    ItemExpensesDetailComponent,
    OtherBeneficiaryModalComponent,
    PayrollGeneralComponent,
    EmployeesPayrollComponent,
    KeysConfigurationComponent,
    CertificationCodesComponent,
    CertificationCodeComponent,
    DeleteCertificationCodeComponent,
    OpenAdditionalPayrollComponent,
    ExcludeEmployeePayrollModalComponent,
    BankAccountsComponent,
    AdvancedFilterByPayrollConceptComponent,
    AdvancedFilterByPayrollNewsListComponent,
    DataValidationPayrollNewsDetailModalComponent,
    AdvancedSearchComponent,
    AuditChangesComponent,
    OvertimeReportComponent,
    PayrollComparisonReportComponent,
    RejectFilesHaciendaComponent,
    PayrollRoyaltySummaryComponent,
    PayrollRoyaltyDetailComponent,
    OvertimeIndividualReportComponent,
    RoyaltyDetailAddModalComponent,
    RoyaltyDetailBulkloadModalComponent,
    DataValidationRoyaltyDetailModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    payrollRoutingModule,
    SharedModule,
    SelectDropDownModule,
    DragDropModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers:[
    CurrencyPipe,
    PayrollPeriodService,
    PayrollFlyerService
  ]
})

export class payrollModule { }
