<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>
        {{data.title}}</h2>
</div>

<div class="mat-dialog-content-custom">
    <div class="card card-custom">


        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-12 col-xl-12 col-xxl-6 c-left">
                <div class="card-toolbar d-flex justify-content-start">

                    <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4 mr-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Condición
                            </label>
                            <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="dropdownSettings"
                                [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                                (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                        </div>
                    </div>

                    <button *ngIf="showButtonCertificateBankAccount"
                    (click)="certificateAccount()"
                    class="btn btn-primary btn-pill mt-2 ml-3 mx-0 col-md-auto">
                            <i class="flaticon-seguro icon-2x" ></i>
                            Certificar Cuentas Bancarias
                    </button>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-xl-12 col-xxl-6 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <!-- <a *ngIf="data.type === 1" (click)="exportToExcel()" class="dropdown-item align-items-center">
                                <i class="flaticon-excel icon-2x text-success mr-3"></i>
                                Excel
                            </a> -->
                            <a *ngIf="data.type === 1" (click)="exportReport()" class="dropdown-item align-items-center">
                              <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                              Visualizar Reporte
                          </a>
                            <a *ngIf="data.type === 2" (click)="exportToExcel()" class="dropdown-item align-items-center">
                                <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                                Visualizar Reporte
                            </a>
                        </div>
                    </div>
                    <div class="col text-nowrap">
                        <app-search-bar-pagination (onFilter)="filterSearch($event)"
                        [parameters]="searchBarDisplayNames" ></app-search-bar-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="card-body">
        <ng-container  *ngIf="showCheckboxCertificateBankAccount">
            <input type="checkbox" (change)="selectAllItems()"
            [(ngModel)]="allItemsSelected"  class="check-size me-3 ml-1">
            <label class="h6">Marcar Todos</label>
        </ng-container>
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-left" *ngIf="showCheckboxCertificateBankAccount">

                        </th>
                        <th class="text-center">Secuencia</th>
                        <th class="text-left">Servidor Público</th>
                        <th class="text-left">Documento de Identidad</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-left">Teléfono Primario</th>
                        <th class="text-center">Estatus</th>
                        <th class="actionsBtn">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let employee of incompleteEmployees">
                        <td class="text-center" *ngIf="showCheckboxCertificateBankAccount">
                            <input *ngIf="showCheckboxCertificateBankAccount && employee?.withoutCertificate" (change)="selectItem(employee)"
                            [(ngModel)]="employee.isSelected" type="checkbox" name="{{employee.employeeId}}"
                            class="check-size">
                        </td>
                        <td class="text-center">
                            {{employee.employeeId}}
                        </td>
                        <td class="text-left">{{employee.name}}</td>
                        <td class="text-left">
                            <span *ngIf="employee.typeIdentificationId === 1">{{employee.personalIdentification | mask: '000-0000000-0'}}</span>
                            <span *ngIf="employee.typeIdentificationId !== 1">{{employee.personalIdentification}}</span>
                        </td>
                        <td class="text-left">{{employee.departmentName}}</td>
                        <td class="text-left">{{employee.phoneNumber | mask: '000-000-0000'}}</td>

                        <td class="text-center">
                            <span *ngIf="employee.status" class="font-weight-bold text-success">Activo</span>
                            <span *ngIf="!employee.status" class="font-weight-bold text-danger">Desvinculado</span>
                        </td>
                        <td class="text-center">
                            <div class="d-flex">
                                <div class="col px-0 min-w-36">
                                    <a *ngIf="employee.status && privilege?.editAction?.value"
                                        [routerLink]="['/candidate-registration/',employee.personId, data.type,employee.employeeId]"
                                        (click)="dialogRef.close(false)"
                                        class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip"
                                        title="Editar">
                                        <i class="flaticon-edit"></i>
                                    </a>
                                    <a *ngIf="employee.status && privilege?.editAction?.value"
                                        (click)="openModalIncompletedFiles(employee)"
                                        class="btn btn-icon btn-light-info btn-sm m-1" data-toggle="tooltip"
                                        title="Ver Campos Faltantes">
                                        <i class="flaticon-ver"></i>
                                    </a>
                                </div>
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <app-paginator
                [listedRecordsLength]="incompleteEmployees?.length"
                [registerQuantity]="employeesPagedResut.totalItems"
                [registerPerPage]="employeesPagedResut.pageSize"
                [totalPages]="employeesPagedResut.totalPages"
                [selectedPage]="employeesPagedResut.page"
                (onChange)="getPaginatedRecords($event)">
            </app-paginator>
            </div>
        </div>

    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3" id="btnCloseU" data-dismiss="modal"
        (click)="close()">
        <i class="flaticon2-cross"></i>
        <span>Cerrar</span>
    </button>
</div>
