<div class="card-body"><div class="d-flex justify-content-between pr-5">
    <div>
        
    </div>
    <div class="d-flex align-items-center">
        <div class="dropdown col-md-auto col-sm-12" *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.exportAction)">
            <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                    data-original-title="Opciones de exportar"></i> Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button (click)="exportToExcel()" class="dropdown-item d-flex align-items-center" href="#">
                    <i class="flaticon-excel icon-2x text-success mr-3"></i>
                    <span>Excel</span>
                </button>
                <!-- <a class="dropdown-item disabled" href="#">
                    <i class="flaticon-word icon-2x text-primary mr-3"></i>
                    Word
                </a>
                <a class="dropdown-item disabled" href="#">
                    <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                    PDF
                </a>
                <a class="dropdown-item disabled" href="#">
                    <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                    CSV
                </a> -->
            </div>
        </div>
        <el-search-bar *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.searchAction)" #searchBar [records]="departmentsListOriginal" [itemForSearching]="searchBarDisplayNames"
        (onChange)="getFilteredList($event);"></el-search-bar>
    </div>
</div>
    <div class="table-responsive">
        <table class="table mt-2 table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <!-- <th>Código Compañía</th> -->
                    <th>Unidad Organizativa</th>
                    <th>Encargado</th>
                    <th></th>
                    <th></th>
                    <th class="actionsBtn">Acciones</th>
                </tr>
            </thead>
            <tbody>
                    <tr *ngFor="let item of departmentsPaginated">
                        <td>{{item.departmentName}}</td>
                        <td slot="3">{{item.supervisor}}</td>
                        <td></td>
                        <td></td>
                        <td class="actionsBtn">
                            <button (click)="openDirectoryModal(item, false, false, true)"
                            *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.editAction)"
                             class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip" title="Editar">
                                <i class="flaticon-editar"></i>
                            </button>
                        </td>
                    </tr>
            </tbody>
        </table>
        <div>
            <el-caption #paginator [originalRecordsLength]="filteredList?.enable ? filteredList.records?.length :departments?.length"
                [listedRecordsLength]="departmentsPaginated?.length"
                [registerQuantity]="filteredList?.enable && filteredList.records?.length>0 ? filteredList.records?.length :departments?.length"
                [records]="filteredList?.enable ? filteredList?.records : departments"
                (onChage)="getPaginatedRecords($event)"></el-caption>
        </div>
    </div>
</div>