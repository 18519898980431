<div data-card="true" class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-lista-de-verificacion-2 icon-3x text-danger mr-3"></i>
                <h1 class="text-primary">
                    Preguntas frecuentes
                </h1>
            </div>
        </div>
        <!-- <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Dashboard">
            <button data-toggle="modal"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </button>
        </div> -->
    </div>
    <div class="card-header mt-0 row justify-content-between">
        <div class="col-sm-12 col-md-12 col-lg-6 c-left">
            <!-- <div class="card-toolbar d-flex justify-content-start">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Estatus
                        </label>
                        <ng-multiselect-dropdown
                            [placeholder]="'Seleccionar'"
                            [settings]="dropdownSettings"
                            [data]="statusOptions">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="col-sm-12 col-md-12 col-lg-6 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="col text-nowrap mt-2">
                    <el-search-bar
                        [records]="searchList"
                        [itemForSearching]="searchBarDisplayNames">
                    </el-search-bar>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="accordion" id="accordionFrequentQuestion">
            <div class="accordion-item" *ngFor="let item of questions; let i = index;">
                <h2 class="accordion-header" [id]="'heading' + i">
                    <button [ngClass]="i == 0 ? 'accordion-button': 'accordion-button collapsed'" type="button" data-toggle="collapse" [attr.aria-controls]="'collapse' + i" [attr.data-target]="'#collapse' + i" aria-expanded="true">
                        <span class="question-number">
                            {{ item.id }}
                        </span>
                        {{ item.question }}
                    </button>
                </h2>
                <div [id]="'collapse' + i" [ngClass]="i == 0 ? 'accordion-collapse collapse show': 'accordion-collapse collapse'" [attr.aria-labelledby]="'heading' + i" data-parent="#accordionFrequentQuestion">
                    <div class="accordion-body">
                        <p class="text-end mb-2">
                            Temas relacionados:
                            <span class="badge rounded-pill bg-primary me-1" *ngFor="let tag of item.tags">{{ tag }}</span>
                        </p>
                        <p class="text-justify mb-2">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis, eros ac dignissim iaculis, est felis tincidunt arcu, nec tempus magna risus eget sem. Sed ut tortor eu felis egestas tincidunt. Nulla facilisi. Sed suscipit, augue pharetra aliquam facilisis, lorem lorem feugiat ex, et viverra neque nisi ullamcorper leo. Proin ut nulla in dolor facilisis consectetur ac eu turpis. Ut ac dui feugiat, sagittis ipsum rutrum, dictum diam. Vestibulum varius purus ut lobortis placerat. Morbi quam erat, consequat eget quam vitae, luctus facilisis nibh.
                            <br /><br />
                            Morbi porta ac sem in mattis. Phasellus ut elementum mauris, at maximus odio. Suspendisse potenti. Morbi cursus eros neque, id egestas magna vehicula eget. Nulla pulvinar eget arcu sed ullamcorper. Donec libero leo, sodales eget tincidunt quis, tempus a enim. Sed condimentum, nisi non commodo sodales, dui magna tristique augue, convallis consectetur felis leo sit amet lorem. Quisque quis enim id ante eleifend pretium nec vitae nunc. Maecenas pharetra posuere blandit. Ut non dignissim felis. Vivamus iaculis, leo porta aliquam tincidunt, lectus nibh rutrum quam, ut volutpat lacus odio a ante. Fusce molestie vel purus quis sodales. Aliquam tristique vestibulum egestas. Aliquam eleifend, augue ut vulputate finibus, nulla libero venenatis leo, id eleifend arcu mauris in nulla. Vestibulum eu massa at diam dictum aliquet eget et velit. Integer est enim, luctus vel posuere ut, tempor ut turpis.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>