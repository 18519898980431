import { Component, Inject, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { BudgetArea } from 'src/app/models/budget-area';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { removeWhitespace } from 'src/app/shared/utils/utility';

@Component({
  selector: 'app-create-budget-area',
  templateUrl: './create-budget-area.component.html',
  styleUrls: ['./create-budget-area.component.css']
})
export class CreateBudgetAreaComponent implements OnInit, AfterViewChecked {
  budgetAreaModel = new BudgetArea()
  executingUnits = []
  executingUnitsConfig;
  budgetAreaForm = new FormGroup({
    description: new FormControl('', Validators.required),
    code: new FormControl('', Validators.required),
    executingUnit: new FormControl('', Validators.required),
    inactiveReason: new FormControl('')
  })

  showInactiveReason = false

  originalBudgetAreaList: any[] = [];
  company;
  budgetArea: any;
  title = 'Agregar'
  
  constructor(
    public dialogRef: MatDialogRef<CreateBudgetAreaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _payrollService: PayrollService, private _toastService: ToastService,
    private _cdRef: ChangeDetectorRef,
    private authInfo: AuthInfoService,
    private srvCompanies: CompaniesService,
  ) {
    this.originalBudgetAreaList = this.data.originalBudgetAreaList;
  }

  ngOnInit(): void {
    
    this.executingUnitsConfig = this.dropdownConfig('codeDescription')
    
    const item = this.data.item
    if(item){
      if(item.status == false){
        this.showInactiveReason = true
      }
    }
    this.title = this.data.mode == 'edit' ? 'Editar' : this.data.mode == 'view' ? 'Detalle' : 'Agregar'

    this.getCompany()
    this.budgetAreaForm.get('inactiveReason').disable()
    //this.getExecutingUnits()
    if (this.data.mode != 'create') {
      this.getById(this.data.item.budgetAreaId)
    }
    if(this.data.mode == 'view'){
      this.budgetAreaForm.disable()
    }
  }

  removeWhitespace(event, control){
    removeWhitespace(event, control)
  }

  isFormInvalid(){
    const code = this.budgetAreaForm.get('code')
    const description = this.budgetAreaForm.get('description')
    const unit = this.budgetAreaForm.get('executingUnit')
    if(code.value.trim().length < 1){
      return true
    }
    if(description.value.trim().length < 1){
      return true
    }
    if(Array.isArray(unit.value)){
      if(unit.value.length < 1){
        return true
      }
    }
    if(!unit.value){
      return true
    }
    return false
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }


  getById(budgetAreaId:number){
    this._payrollService.getBudgetAreaById(budgetAreaId).subscribe((res:any)=>{
      this.budgetArea = res.dataList[0]
      this.budgetAreaForm.get('description').setValue(this.budgetArea.description)
      this.budgetAreaForm.get('code').setValue(this.budgetArea.code)
      this.budgetAreaForm.get('inactiveReason').setValue(this.budgetArea.inactiveReason)
    })
  }

  getCompany(){
    this.srvCompanies.getCompanyByUserAndId().subscribe({
      next: (res:any) => {
        if(res.errors.length > 0){this._toastService.error(res.errors[0]); return}
        if(res.warnings.length > 0){this._toastService.warning(res.errors[0]); return}
        this.company = res.dataList[0]
        
        this.getExecutingUnits()
      }, error: (errors: any) => {

      }
    })
  }
  getExecutingUnits(){
    const { chapter, subchapter} = this.company
    this.srvCompanies.getExecutionUnits(chapter, subchapter).subscribe({
      next: (res: any) => {
        if(res.errors.length > 0){this._toastService.error(res.errors[0]); return}
        if(res.warnings.length > 0){this._toastService.warning(res.errors[0]); return}
        res.dataList.forEach(element => {
          element.codeDescription = `${element.code}-${element.description}`
        });
        this.executingUnits = res.dataList
        if (this.data.mode == 'create') {
          return
        }
        const executingUnit = this.data.item?.executingUnit
        if(!executingUnit){
          return
        }
        const budget = this.executingUnits.find(x => x.code === executingUnit)
        if(!budget){
          return
        }
        this.budgetAreaForm.get('executingUnit').setValue(budget)
        
      }, error: (error: any) => {
        this._toastService.error('Ha ocurrido un error inesperado')
      }
    })
  }
  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }
  closeModal(): void {
    const result = { cretaed: false }
    this.dialogRef.close(result);
  }

  SubmitBudgetArea(): void {
    if(this.isFormInvalid()){
      this._toastService.warning('Campos inválidos.')
      return
    }
    if (this.data.mode == 'edit') {
      this.budgetAreaModel.budgetAreaId = this.data.item.budgetAreaId
    }
    const { code, description } = this.budgetAreaForm.get('executingUnit').value
    this.budgetAreaModel = {
      ...this.budgetAreaModel,
      executingUnit: code,
      description: this.budgetAreaForm.get('description').value ?? null,
      code: this.budgetAreaForm.get('code').value ?? null,
      status: true,
      companyId: this.authInfo.getCompanyId(),
      createDate: this.data.mode == 'edit' ? this.data.item.createDate : moment().format(),
      modifyDate: moment().format(),
      modifyUserId: this.authInfo.getUserId(),
      executingUnitDescription: description
    }

    if(this.codeExist(this.budgetAreaModel.code)){
      this._toastService.warning('Ya existe una Área Presupuestaria con el mismo código','')
      return;
    }
    
    if(this.descriptionExist(this.budgetAreaModel.description)){
      this._toastService.warning('Ya existe una Área Presupuestaria con la misma descripción','')
      return;
    }

    if (this.data.mode == 'create') {
      this.budgetAreaModel.createUserId = this.authInfo.getUserId();
      this.createBudgetArea()
    } else {
      this.budgetAreaModel.modifyUserId = this.authInfo.getUserId();
      this.updateBudgetArea()
    }

  }

  //CREATE
  createBudgetArea() {
    let result = {
      created: false
    };
    
    this._payrollService.createBudgetArea(this.budgetAreaModel).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }

      this._toastService.success('Área presupuestal creada satisfactoriamente')
      result.created = res.succeded
      this.dialogRef.close(result);
    })

  }

  //UPDATE
  updateBudgetArea() {
    let result = {
      created: false
    }

    this._payrollService.updateBudgetArea(this.budgetAreaModel).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      this._toastService.success('Área presupuestal actualizada satisfactoriamente')
      result.created = res.succeded
      this.dialogRef.close(result);
    })
  }

  codeExist(code: string){
    if (this.data.mode != 'create') {
      return this.originalBudgetAreaList.some(o => o.code === code 
        && this.budgetArea.budgetAreaId !== o.budgetAreaId);
    } else {
      return this.originalBudgetAreaList.some(o => o.code === code);
    }
   
  }
  
  descriptionExist(description: string){
    if (this.data.mode != 'create') {
      return this.originalBudgetAreaList.some(o => o.description === description 
        && this.budgetArea.budgetAreaId !== o.budgetAreaId);
    } else {
      return this.originalBudgetAreaList.some(o => o.description === description);
    }
  }

}
