import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-budget-approbation',
  templateUrl: './map-budget-approbation.component.html',
  styleUrls: ['./map-budget-approbation.component.css']
})
export class MapBudgetApprobationComponent implements OnInit {

  constructor() { }
  tableRows = new Array(3);
  pendingListView:boolean;
  dataList:{
    requestType:string; 
    requested:string|number;
    rejected:string|number ,
    approbed:string|number; 
    pending:string|number}[]=[
    {requestType: 'Nuevos Nombramientos', requested:226, rejected:27, approbed:192, pending:7 },
    {requestType: 'Promoción Personal', requested:30, rejected:6, approbed:24, pending:'-' },
    {requestType: 'Nuevos Puestos', requested:6, rejected:1, approbed:4, pending:1 },
    {requestType: 'Proyectos Especiales', requested:1, rejected:'-', approbed:'-', pending:2 },
    {requestType: 'Total', requested:264, rejected:34, approbed:224, pending:10 },
  ]
  ngOnInit(): void {
    this.pendingListView=false;
  }

  isVisible(){
    if(this.pendingListView)
      this.pendingListView=false;
    else
      this.pendingListView=true;
  }
  
}
