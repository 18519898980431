import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IEmployee } from 'src/app/models/Employee.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { EmployeeCondition } from 'src/app/shared/models/employee-condition.model';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';

@Component({
  selector: 'app-teacher-reviews',
  templateUrl: './teacher-reviews.component.html',
  styleUrls: ['./teacher-reviews.component.css']
})
export class TeacherReviewsComponent implements OnInit {

  employeeId: number | null = null;
  employeeInfo: IEmployee | null = null;
  photoUrl: string | null = null;

  isSeviceCommission: boolean = false;

  currentEmployeeCondition: EmployeeCondition;
  currentDate = new Date();

  isEdittingRevision: boolean = false;
  currentOnEditting!: number;
  oldModificationReasonSelected: any;

  constructor(private activatedRoute: ActivatedRoute,
    private actionPSrv: PersonnelActionsService,
    private cdRef: ChangeDetectorRef,
    private toastService: ToastrService,
    private dialog: MatDialog,
    public authInfo: AuthInfoService,
    private employeeService: EmployeesService,
    private datePipe: DatePipe,
    private router: Router,
    private srvDoc: docService,
  ) { }

  ngOnInit(): void {
    this.employeeId = this.activatedRoute.snapshot.params['employeeId'];

    if(this.employeeId){
      this.getData();
    }
  }

  goBack() {
    this.router.navigate(['/hourly-teachers'])
  }

  getData(){
    const services: { service: Observable<any>, canExecuteService: boolean, unexpectedErrorMessage?: string, successMessage?: string, methodToExecuteAfterSuccess?: (data:any) => void }[] = [
      {
        service: this.actionPSrv.getEmployeeByEmployeeId(this.employeeId),
        canExecuteService: true,
        unexpectedErrorMessage: 'Ha ocurrido un error tratando de consultar los datos del docente',
        successMessage: '',
        methodToExecuteAfterSuccess: (data:ResponseModel<any>)=>{ 
          this.employeeInfo = data?.dataList[0];

          if(this.employeeInfo?.profileImage){
            this.setProfileImage(this.employeeInfo?.profileImage)
          }
        }
      },
      {
        service: this.actionPSrv.getStatusGlobalValidation(),
        canExecuteService: true,
        unexpectedErrorMessage: 'Ha ocurrido un error tratando de consultar los tipos condiciones del docente',
        successMessage: '',
        methodToExecuteAfterSuccess: (data:ResponseModel<any>)=>{ 
          this.currentEmployeeCondition = data?.dataList[0] as EmployeeCondition;
        }
      }
    ]
    
    const $requests = services.map((model) => {
      if (model.canExecuteService) {
        return model.service
          .pipe(map(value => ({ type: model, value: value })))
          .pipe(catchError(e => of({ succeded: false, errors: [model.unexpectedErrorMessage] } as ResponseModel<any>)));
      }
    }).filter(Boolean);

    forkJoin($requests).subscribe((responses: { type: any, value: ResponseModel<any> }[]) => {

      responses.forEach((response) => {
        if (!response?.value?.succeded) {
          response?.value?.errors.forEach(err => {
            this.toastService.error(err);
          })

          response?.value?.warnings.forEach(warn => {
            this.toastService.warning(warn);
          })
        } else {
          response?.type?.methodToExecuteAfterSuccess(response?.value);
          this.cdRef.detectChanges();
        }
      })

    })
  }

  setProfileImage(guid: string){
    this.srvDoc.getDocument(guid).subscribe({
      next: (res)=>{
        if(!res.succeded){
          return;
        }
        this.photoUrl = res.data;
      },
      error: (err)=>{

      }
    })
  }

}
