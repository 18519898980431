import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SeparationsService } from 'src/app/services/separations.service';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { PagedResult } from 'src/app/shared/models/page-result';

@Component({
  selector: 'app-data-validation-separations-detail-modal',
  templateUrl: './data-validation-separations-detail-modal.component.html',
  styleUrls: ['./data-validation-separations-detail-modal.component.css'],
})
export class DataValidationseparationsDetailModalComponent implements OnInit {
  dataList = [];
  paginatedDataList = [];
  paginatedDataListOriginal = [];
  paginatedDataListPagedResut = new PagedResult<any>();
  conditionId = 0;
  title = '';

  constructor(
    private dialogRef: MatDialogRef<DataValidationseparationsDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private separationservice: SeparationsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.conditionId = this.data?.item.conditionId;
    this.title = this.data?.item?.description;
    this.getDataValidationseparationsDetail();
  }

  close() {
    this.dialogRef.close();
  }

  getDataValidationseparationsDetail() {
    this.separationservice
      .getDataValidationseparationsDetail(this.conditionId)
      .subscribe({
        next: (res: any) => {
          if (res.succeded == false) {
            this.toastr.warning(res.errors[0]);
            return;
          }
          this.dataList = res.dataList;
          this.paginatedDataListOriginal = res.dataList;
          this.updatePagination();
        },
        error: (error: any) => {
          this.toastr.error('Ha ocurrido un error inesperado');
        },
      });
  }

  getPaginatedRecords(event: PaginationEvent) {
    if (
      this.paginatedDataListPagedResut.page == event.selectedPage &&
      this.paginatedDataListPagedResut.pageSize == event.registersPerPage
    ) {
      return;
    }
    this.paginatedDataListPagedResut.page = event.selectedPage;
    this.paginatedDataListPagedResut.pageSize = event.registersPerPage;
    this.paginatedDataListPagedResut.items = this.paginatedDataListOriginal;
    this.updatePagination();
  }

  updatePagination() {
    const page = this.paginatedDataListPagedResut.page;
    const pageSize = this.paginatedDataListPagedResut.pageSize;
    const total = this.paginatedDataListPagedResut.totalItems;
    const startIndex = (page - 1) * pageSize;
    this.paginatedDataList = this.paginatedDataListOriginal.slice(
      startIndex,
      startIndex + pageSize
    );
  }
}
