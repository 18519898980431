import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Departments } from 'src/app/models/departments.model';
import { Positions } from 'src/app/models/positions.model';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PlanningRrhhService } from 'src/app/services/planning-rrhh.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { RequestMetaData, ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { CurrentSituation } from '../../models/current-situation.model';
import { NewPositions } from '../../models/new-positions.model';
import { forkJoin, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { NewPositionEntities, NewPositionEntitiesView } from '../../models/new-position-POST.model';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { CurrentSituationFilter, CurrentSituationFilterDTO } from '../../models/current-situation-filter.model';
import { BudgetApprovation } from '../../models/budget-approvation.model';
import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplyVacancyModel } from 'src/app/pages/personal-requisition/models/apply-vacancy-model';
import { ChangeConditionPlanning, openPlanning, PostPlanningHeader } from '../../models/planning';
import { VacancyModalComponent } from '../vacancyModal/vacancy-modal/vacancy-modal.component';
import { ModalViewsEnum } from 'src/app/models/modal-views';
import { PlanningDetailsComponent } from '../planning-details/planning-details.component';
import { ReturnMotivesModalComponent } from '../return-motives-modal/return-motives-modal.component';
@Component({
  selector: 'app-planning-rrhh',
  templateUrl: './planning-rrhh.component.html',
  styleUrls: ['./planning-rrhh.component.css']
})
export class PlanningRrhhComponent implements OnInit, OnDestroy, AfterViewChecked {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PLANRRHH',
    addAction: 'ADDPOST',
    cleanAction: 'CLEANSIT',
    importAction: 'IMPORTPOST',
    resumeAction: 'RESUMEPOST',
    resume2Action: 'RESUMESIT',
    sendAction: 'SENDPLAN',
    searchAction: 'SEARCHSIT',
    placerequest: 'PLACE-REQUEST',
    finish: 'FINISH',
    view: 'VIEW',
    rejectMotives: "VIEW-REJECT-MOTIVES",
    open: 'OPEN',
    viewplace: "VIEW-PLACE"
  }

  IsviewMode = false;
  VacancyModel = new ApplyVacancyModel();
  isSalaryInvalid: boolean = false
  currentYear: any = new Date().getFullYear();
  isPlanningOpened: boolean = false
  isCreated: boolean = false

  planning: ChangeConditionPlanning
  planningHeaderId: number
  planningRecord: any
  salaryTotal: number = 0
  approvedPositionsTotal: number = 0
  occupiedPositionsTotal: number = 0
  requestedPositionsTotal: number = 0

  private readonly api_url = environment.api_url
  public message = "";
  public progress = 0;
  public enviUrl = environment;
  public excelData: NewPositionEntities[] = [];

  estimatedYearlySalary: number = 0;
  //TABLES
  newPositionsResponse: ResponseModel<NewPositions>;
  currentSituationResponse: ResponseModel<CurrentSituation>;
  overallSummary: any[] = []

  //budgetCalculations

  budgetMonthlyAccumulation: number = 0;

  //DROPDOWNS
  requisitionTypesDropdown: ResponseModel<ParameterControl>;
  ocupationalGroupsDropdown: ResponseModel<ParameterControl>;
  departmentsDropdown: ResponseModel<Departments>;
  positionsDropdown: ResponseModel<Positions>;
  planningDTO: PostPlanningHeader = {
    companyId: 0,
    lastYear: 0,
    yearExecution: 0,
    userId: 0,
    approvedBudget: 0,
    startDate: null,
    endDate: null
  }

  //FILTER
  currentSituationFilter: FormGroup = new FormGroup({
    departmentId: new FormControl(0),
    positionId: new FormControl(0),
    ocupationalGroupId: new FormControl(0),
    provisionTypeId: new FormControl(0),
    companyId: new FormControl(this.authInfo.getCompanyId()),
    status: new FormControl(true),
  })
  sweetAlert = new SweetAlert();

  planningForm: FormGroup = new FormGroup({
    currentYear: new FormControl(this.currentYear, Validators.required),
    planningYear: new FormControl('', Validators.required),
    approvedBudget: new FormControl('', Validators.required),
  })


  currentSituationRecords: any[] = [];
  //filteredCurrentSituationRecords:any[]=[];

  newPositionsRecords: any[] = [];
  generalSummaryRecords: any[] = [];

  dropdownsLoaded: boolean = false;

  viewsEnum = ModalViewsEnum;

  subscriptions: Subscription = new Subscription();
  constructor(
    private _cdRef: ChangeDetectorRef,
    private _planningHrmService: PlanningRrhhService,
    private _toastrService: ToastrService,
    private _builder: FormBuilder,
    private _parameterControlService: ParameterControlService,
    private http: HttpClient,
    public authInfo: AuthInfoService,
    public dialogRef: MatDialogRef<PlanningRrhhComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      viewMode: number
      planningRecord: any
    },
  ) { 
    
    dialogRef.disableClose = true 
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if(result == true){
        if (this.data.viewMode == ModalViewsEnum.Edit || this.data.viewMode == ModalViewsEnum.View) {
          this.getPlanningRecord()
        }
      }
     })
  }


  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  ngOnInit(): void {
    
   this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
    if(result == true){
      this.getDropdownsData();
    }
   })
  }

  getPlanningRecord() {
    this.planningRecord = this.data.planningRecord;
    this.planningHeaderId = this.planningRecord.planningHeaderId;
    this.currentSituationFilter.enable();
    this.isPlanningOpened = true;

    this.planningForm.patchValue(this.data?.planningRecord);
    this.planningForm.get('approvedBudget').setValue(this.data?.planningRecord?.executedBudget)
    this.planningForm.get('currentYear').setValue(this.data?.planningRecord?.lastYear)
    this.planningForm.get('planningYear').setValue(this.data?.planningRecord?.yearExecution)
  

    this.getCurrentSituation(this.planningHeaderId)
    this.getPositionsList()
    this.getSummary()

      this.planningForm.disable()
    this.currentSituationFilter.enable()
  }


  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccione una opción', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }




  openPlanning() {

    if (this.planningForm.invalid) {
      this._toastrService.warning('Por favor complete los campos requeridos')
      return
    }

    this.planningDTO = {
      lastYear: this.planningForm.get('currentYear').value,
      yearExecution: this.planningForm.get('planningYear').value,
      approvedBudget: Number(this.planningForm.get('approvedBudget').value),
      companyId: this.authInfo.getCompanyId(),
      userId: this.authInfo.getUserId(),
      startDate: null,
      endDate: null
    }


    this._planningHrmService.openPlanning(this.planningDTO).subscribe({
      next: (res) => {
        if (res.succeded == false) {
          this._toastrService.error(res.errors[0]);
          return
        }

        this._toastrService.success("Planificación aperturada exitosamente")

        this.planningHeaderId = res.identity
        this.getCurrentSituation(res.identity)
        this.getPositionsList()
        this.getSummary()
        this.isPlanningOpened = true
        this.isCreated = true
        this.planningForm.disable();
        this.currentSituationFilter.enable()
      },
      error: (err: any) => {
        this._toastrService.error(err, 'Ocurrio un error tratando de aperturar esta plnificación')
      }
    })
  }


  resetFilter() {
    if (!this.currentSituationFilter.dirty) {
      return
    }

    this.currentSituationFilter.get('departmentId').reset()
    this.currentSituationFilter.get('positionId').reset()
    this.currentSituationFilter.get('provisionTypeId').reset()
    this.currentSituationFilter.get('ocupationalGroupId').reset()
    this.currentSituationFilter.reset(
      {
        departmentId: [],
        positionId: [],
        ocupationalGroupId: [],
        provisionTypeId: [],
        companyId: this.authInfo.getCompanyId(),
        status: true,
      }
    );

    if (this.isPlanningOpened) {
      this.getCurrentSituation(this.planningHeaderId)
    }
  }


  getCurrentSituation(planningHeaderId: number) {
    this._planningHrmService.getCurrentSituation(planningHeaderId).subscribe((response: ResponseModel<CurrentSituation>) => {
      if (response.succeded == false) {
        this._toastrService.error(response.errors[0]);
        return
      }

      this.currentSituationResponse = response.succeded ? response : null;
      if (this.currentSituationResponse.dataList.length == 0) {
        this._toastrService.warning('No existen registros para llenar la tabla.', 'Pantalla situación actual');
      }
    }, error => {
      this._toastrService.error('Error al buscar los datos.');
    });

  }


  getCurrentSituationByFilters() {
    if (!this.isPlanningOpened) {
      this._toastrService.warning('Por favor aperture la planificación y pruebe nuevamente','No hay datos para mostrar')
      return
    }
    let { departmentId } = this.currentSituationFilter.get('departmentId').value;
    let { positionId } = this.currentSituationFilter.get('positionId').value;
    let { ocode: occupationalGroupId } = this.currentSituationFilter.get('ocupationalGroupId').value;

    let filter: CurrentSituationFilter = {
      departmentId: departmentId ?? 0,
      positionId: positionId ?? 0,
      ocupationalGroupId: occupationalGroupId ?? 0,
      companyId: this.currentSituationFilter.get('companyId').value,
      planningHeaderId: this.planningHeaderId
    }

    this._planningHrmService.getFilteredCurrentSituationRecords(filter).subscribe({
      next: (res: ResponseModel<CurrentSituation>) => {
        if (res.succeded == false) {
          this._toastrService.error(res.errors[0]);
          return
        }

        this.currentSituationResponse = res.succeded ? res : null;

        if (this.currentSituationResponse.dataList.length == 0) {
          this._toastrService.warning('No existen registros para llenar la tabla.', 'Pantalla situación actual');
        }
      },
      error: err => {
        this._toastrService.error('Error al buscar los datos.');
      }
    })
  }

  finishPlanning() {
    this.planning = {
      planningHeaderId: this.planningHeaderId,
      userId: this.authInfo.getUserId()
    }

    this._planningHrmService.finishPlanning(this.planning).subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          this._toastrService.error(res.errors[0]);
          return
        }

        this._toastrService.success('Planificación concluida. En espera de aprobación')
        this.dialogRef.close(true);
      }
    })
  }

  sendPlanning() {
    this.planning = {
      planningHeaderId: this.planningHeaderId,
      userId: this.authInfo.getUserId()
    }

    this._planningHrmService.sendPlanning(this.planning).subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          this._toastrService.error(res.errors[0]);
          return
        }

        this._toastrService.success('Planificación enviada')
        this.dialogRef.close(true);
      }
    })
  }

  getPositionsList() {
    let sub = this._planningHrmService.getPositions(this.planningHeaderId).subscribe((response: ResponseModel<NewPositions>) => {
      if (response.succeded == false) {
        this._toastrService.error(response.errors[0]);
        return
      }

      this.newPositionsResponse = response.succeded ? response : null;
      if (this.newPositionsResponse?.dataList.length == 0) {
        this._toastrService.warning('No existen registros para llenar la tabla.', 'Pestaña de Planificación');
      }
    }, error => {
      this._toastrService.error('Error al buscar los datos.');
    });

    this.subscriptions.add(sub);
  }

  openVacancyModal(viewMode: number,position: any) {
    this._dialog.open(VacancyModalComponent, {
      data: {
        viewMode,
        position,
        headerId: this.planningHeaderId
      },
      width: '80%'
    }).afterClosed().subscribe({
      next: (res:any)=>{
        if (res) {
          this.getPositionsList()
        }
      },
      error:(err:any)=>{

      }
    })
  }

  openDetailsModal() {
    if(!this.openDetailsModal) return;
    this._dialog.open(PlanningDetailsComponent, {
      data: {
        headerId: this.planningHeaderId
      },
      width: '100%'
    })
  }

  openMotives(){
    this._dialog.open(ReturnMotivesModalComponent, {
      width: '60%',
      data: {item: this.planningRecord}
    })
  }

  getDropdownsData() {
    this.currentSituationFilter.disable()
    let forkJoinSub = forkJoin([
      this._planningHrmService.getDepartments(),
      this._planningHrmService.getEmployeesPositions(),
      this._parameterControlService.getParameters(keyWord.RequisitionType),
      this._parameterControlService.getParameters(keyWord.OccupationalGroup)
    ]).pipe(
      tap(_ => {
        this.dropdownsLoaded = true;
      })
    ).subscribe((responses) => {
      this.departmentsDropdown = responses[0].succeded ? responses[0] : null;
      this.positionsDropdown = responses[1].succeded ? responses[1] : null;
      this.requisitionTypesDropdown = responses[2].succeded ? responses[2] : null;
      this.ocupationalGroupsDropdown = responses[3].succeded ? responses[3] : null;

      this.currentSituationFilter.enable();
    }, error => {
    }, () => {
      if (this.departmentsDropdown.dataList.length == 0) {
        this._toastrService.warning('No se encontraron registros.', 'Lista de unidades organizativas');
      }
      if (this.ocupationalGroupsDropdown.dataList.length == 0) {
        this._toastrService.warning('No se encontraron registros.', 'Lista de grupos ocupacionales');
      }
      if (this.positionsDropdown.dataList.length == 0) {
        this._toastrService.warning('No se encontraron registros.', 'Lista de posiciones');
      }
      if (this.requisitionTypesDropdown.dataList.length == 0) {
        this._toastrService.warning('No se encontraron registros.', 'Lista de tipos de Servidores Públicos');
      }
    })
    this.subscriptions.add(forkJoinSub);

    

  }

  getSummary() {
    this._planningHrmService.getPlanningSummary(this.planningHeaderId).subscribe({
      next: (res: any) => {
        this.overallSummary = res.dataList

        res.dataList.forEach((summary: any) => {
          this.salaryTotal += summary.totalSalary
          this.approvedPositionsTotal += (summary.approvalPositions ?? 0)
          this.occupiedPositionsTotal += (summary.occupiedPositions ?? 0)
          this.requestedPositionsTotal += (summary.requestedPositions ?? 0)
        })
      },
      error: (err: any) => {

      }
    })
  }


  getPaginatedRecords(event) {
    return event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }


}
