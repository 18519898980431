import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CandidateRegService } from 'src/app/services/http-client/candidateRegService.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { parameterControlModel, personalReferences, personsJobReferences, personsWorkExperiences } from 'src/app/shared/models/candidate-registration.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import Swal from 'sweetalert2';
import { CandidateRegistrationComponent } from '../../candidate-registration.component';
import { removeWhitespace } from 'src/app/shared/utils/utility';

@Component({
  selector: 'app-history-references',
  templateUrl: './history-references.component.html',
  styleUrls: ['./history-references.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HistoryReferencesComponent implements OnInit {
  public personWorkExp: personsWorkExperiences[] = []
  public personJob: personsJobReferences[] = []
  public personRef: personalReferences[] = []
  public startDate = null;
  working = 'no';
  public endDate = null;
  public dataWorkExp = {
    workExperienceId: 0,
    personId: 0,
    company: '',
    jobTitle: '',
    startDate: null,
    departureDate: null,
    lastSalary: null,
    reasonOfExitId: null,
    stateEnterprise: null,
    role: '',
    companyId: this.authInfo.getCompanyId(),
    status: true,
    personsJobReferences: []
  } as personsWorkExperiences

  public dataJob = {
    personJobReferencesId: 0,
    workExperienceId: 0,
    fullName: '',
    position: '',
    phoneNumber: '',
    companyId: this.authInfo.getCompanyId(),
    status: true,
  } as personsJobReferences

  public dataRef = {
    personalReferencesId: 0,
    personId: 0,
    fullName: '',
    position: '',
    company: '',
    phoneNumber1: '',
    phoneNumber2: '',
    companyId: this.authInfo.getCompanyId(),
    status: true,
  } as personalReferences

  public requisitionTypeList = [];
  public index = -1;
  public indexJob = -1;
  public CauseRequisitionData = {} as parameterControlModel
  public reasonOfExitId: number = 0;

  public configRequisitionType = {
    displayKey: 'stringData', // if objects array passed which key to be displayed defaults to description
    search: true,
    placeholder: 'Seleccionar',
    searchPlaceholder: 'Buscar',
    noResultsFound: 'No hay data para mostrar'
  };
  private readonly isPhoneNumberValid = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
  constructor(
    private srvCandidateService: CandidateRegService,
    private srvPersonService: PersonService,
    private toast: ToastService, private cdr: ChangeDetectorRef, private authInfo: AuthInfoService) { }

  ngOnInit(): void {
    this.CauseRequisitionData = null;
    this.loadDDL()
  }

  changeDetector() {
    this.cdr.detectChanges();
  }


  loadDDL() {
    this.srvCandidateService.getParameters(keyWord.ReasonExit).subscribe(e => {
      if (e.succeded) {

        let values = e.dataList;



        this.requisitionTypeList = values

        this.changeDetector();

      } else {
        this.toast.error(e.errors[0]);
      }
    }, error => {
    })
  }

  getReasonExit(m) {
    if (m.reasonOfExitId && this.requisitionTypeList.length > 0)
      return this.requisitionTypeList.filter(e => e.ocode == m.reasonOfExitId)[0]?.stringData
  }

  getMinDate(date){
    let d = new Date(date);
    d.setDate(d.getDate() + 2);
    return d.toLocaleDateString('fr-CA');
  }

  getMaxDate(date = new Date()){
    let d = new Date(date);
    return d.toLocaleDateString('fr-CA');
  }


  addExpWork() {
    if (this.working == 'no') {
      this.dataWorkExp.reasonOfExitId = this.CauseRequisitionData.ocode
      this.dataWorkExp.departureDate = this.endDate;
    } else {
      this.dataWorkExp.departureDate = new Date();
      this.dataWorkExp.reasonOfExitId = 0
      //this.dataWorkExp.departureDate =  '';
    }
    if(this.dataWorkExp.lastSalary < 1){
      this.toast.warning("Campo último sueldo inválido")
      return
    }
    this.dataWorkExp.startDate = this.startDate;

    if (this.index < 0) {
      this.dataWorkExp.personsJobReferences = Object.assign([], this.personJob);
      this.personWorkExp.push(this.dataWorkExp)
      this.dataWorkExp = {} as personsWorkExperiences

    } else {
      this.personWorkExp[this.index] = this.dataWorkExp;
      this.dataWorkExp.personsJobReferences = Object.assign([], this.personJob);
    }

    this.index = -1
    this.changeDetector();

    document.getElementById('btnCerrarEx').click()
  }

  editExpWork(index) {
    this.index = index;
    this.dataWorkExp = Object.assign({}, this.personWorkExp[index]);
    this.startDate = moment(this.dataWorkExp.startDate).format("YYYY-MM-DD")
    this.endDate =  this.working == 'yes'? null : moment(this.dataWorkExp.departureDate).format("YYYY-MM-DD")

    this.personJob = Object.assign([], this.personWorkExp[index].personsJobReferences);
    this.reasonOfExitId = this.dataWorkExp.reasonOfExitId || null



    if (this.dataWorkExp.reasonOfExitId == 0) {
      this.working = 'yes'
    } else {
      this.working = 'no'
    }
    this.changeDetector();
    this.loadExitOut();
  }

  loadExitOut() {
    this.CauseRequisitionData = {
      ccode: null,
      ocode: this.dataWorkExp.reasonOfExitId,
      stringData: this.requisitionTypeList.filter(e => e.ocode == this.dataWorkExp.reasonOfExitId)[0]?.stringData
    }
  }
  clear() {
    this.dataWorkExp.company = '';
    this.dataWorkExp.jobTitle = '';
    this.startDate = null;
    this.working = 'no';
    this.endDate = null;
    this.personJob = [];
    this.dataWorkExp.lastSalary = null;
    this.CauseRequisitionData = null;
    this.dataWorkExp.stateEnterprise = false;
    this.dataWorkExp.role = '';
    this.dataWorkExp.workExperienceId = 0;
    this.dataWorkExp.companyId = this.authInfo.getCompanyId();
    this.index = -1;
  }

  clearRef() {
    this.dataRef = {} as personalReferences
    this.index = -1;
  }

  removeExpWork(i) {
    let id = this.personWorkExp[i].workExperienceId

    if (id == 0) {
      this.personWorkExp.splice(i, 1)
    } else {
      this.swalQuestion(() => {
        this.srvPersonService.deleteWorkExp(id).subscribe(e => {
          this.personWorkExp.splice(i, 1)
          this.toast.success('Registro borrado')
          this.changeDetector();
        }, error => {
          this.toast.error(error)
        });
      });
    }
  }

  validButtonExpWork() {
    let m = this.dataWorkExp;

    if (this.working == 'no' && (!this.endDate || !this.CauseRequisitionData?.ocode)) {

      return true;

    }

    if (m.company == '' || m.jobTitle == '' || (this.startDate || "") == "" || (m.lastSalary || "") == "") {

      return true

    }

    if(this.endDate){
      let s = new Date(this.startDate).getTime();
      let e = new Date(this.endDate).getTime();

      if(e < s){
        return true;
      }

    }

    return false;
  }

  validDates():boolean{
    if(this.endDate === null || this.startDate === null){
      return false;
    }
    return this.startDate > this.endDate;
  }


  /* referencias laborales*/
  getReaJob(m) {
    if (m.reasonOfExitId)
      return this.requisitionTypeList.filter(e => e.ocode == m.reasonOfExitId)[0].stringData
  }

  addJob() {

    this.dataJob.workExperienceId = this.dataWorkExp.workExperienceId;

    if (this.indexJob < 0) {
      this.personJob.push(this.dataJob)
      this.dataJob = {} as personsJobReferences
    } else {
      this.personJob[this.indexJob] = Object.assign({}, this.dataJob);
      this.dataJob = {} as personsJobReferences
    }
    this.changeDetector();
    this.indexJob = -1
  }

  editJob(index) {
    this.indexJob = index;
    this.dataJob = Object.assign({}, this.personJob[index]);
    this.dataJob.workExperienceId = this.dataWorkExp.workExperienceId;
    this.changeDetector();
  }

  removeJob(i) {
    let id = this.personJob[i].personJobReferencesId;

    if (id == 0) {
      this.personJob.splice(i, 1);
    } else {
      this.swalQuestion(() => {
        this.srvPersonService.deleteJobReference(id).subscribe(e => {
          this.personJob.splice(i, 1);
          this.toast.success('Registro borrado')
          this.changeDetector();
        }, error => {
          this.toast.error(error)
        });
      });
    }
  }

  validButtonJob() {
    let m = this.dataJob;

    if (m.fullName?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') == ''
    || m.position?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') == '' || (m.phoneNumber || '') == '') {
      return true
    }
    return this.isPhoneNumberValid?.test(m?.phoneNumber) ? false : true;
  }

  clearJob() {
    this.indexJob = -1;
    this.dataJob = {
      personJobReferencesId: 0,
      workExperienceId: 0,
      fullName: '',
      position: '',
      phoneNumber: '',
      companyId: this.authInfo.getCompanyId(),
      status: true,
    } as personsJobReferences
  }

  /* referencias personales */
  getReaRef(m) {
    if (m.reasonOfExitId)
      return this.requisitionTypeList.filter(e => e.ocode == m.reasonOfExitId)[0].stringData
  }

  addRef() {
    if (this.index < 0) {
      this.dataRef.companyId = this.authInfo.getCompanyId()
      this.personRef.push(this.dataRef)
      this.dataRef = {} as personalReferences
      this.changeDetector();
    } else {
      this.personRef[this.index] = Object.assign({}, this.dataRef);
      this.dataRef = {} as personalReferences
    }

    this.index = -1
  }

  editRef(index) {
    this.index = index;
    this.dataRef = this.personRef[index];
  }

  removeRef(i) {
    let id = this.personRef[i].personalReferencesId;

    let model = {
      personId: id,
      userId: this.authInfo.getUserId(),
      operation: 2
    }

    if (id == 0 || id === undefined) {
      this.personRef.splice(i, 1);
    } else {
      this.swalQuestion(() => {
        this.srvPersonService.deletePersonalReference(model).subscribe(e => {
          this.personRef.splice(i, 1);
          this.toast.success('Registro borrado')
          this.changeDetector();
        }, error => {
          this.toast.error(error)
        })
      });
    }
  }

  validButtonRef() {
    let m = this.dataRef;

    if (m.fullName?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') == ''
    || m.position?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') == ''
    || (m.company?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') || "") == "" || (m.phoneNumber1 || "") == "") {
      return true
    }

    if(!this.isPhoneNumberValid.test(m?.phoneNumber1)){
      return true;
    }

    if(m?.phoneNumber2?.length > 0){
      return this.isPhoneNumberValid.test(m?.phoneNumber2) ? false : true;
    }

    return false;
  }

  swalQuestion(fn: () => void){
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      title: "¿Está seguro que desea eliminar este registro?",
      icon: "question"
    }).then(res => {
      if (res.isConfirmed) {
        fn();
      }
    });
  }

  trimLeadingSpaces(property: string) {
    if (this.dataWorkExp[property]) {
      this.dataWorkExp[property] = this.dataWorkExp[property].replace(/^\s+/, '').trimLeft();
    }
  }

}
