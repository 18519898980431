<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span
            class="h-20px label label-danger label-dot mr-3 w-20px"></span>{{data.edit ? 'Editar Descripción del Cargo' : 'Ver Descripción del Cargo'}}</h2>
</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom mt-0 pt-0">
        <div class="card-body">
            <form [formGroup]="form">
                <div class="row">

                    <div class="form-group col-md-6 col-lg-4">
                        <div>
                            <label for="">
                                Cargo
                            </label>
                        </div>
                        <input  formControlName="positionName" class="form-control form-control-lg dynamicFormControl"
                            id="" placeholder="" type="text" />
                    </div>

                    <div class="form-group col-md-6 col-lg-4">
                        <label class="d-block">Grupo Ocupacional</label>
                        <input formControlName="occupationalGroupDescription" class="form-control form-control-lg dynamicFormControl"
                                id="" placeholder="" type="text" />
                    </div>

                    <div class="form-group col-md-6 col-lg-4">
                        <label class="d-block">Período Probatorio</label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [options]="probationaryPeriodsOption" [config]="dropdownConfig('text')"
                            formControlName="probatoryPeriod" class="w-100"></ngx-select-dropdown>
                        </div>
                    </div>

                    <!-- <div class="form-group col-md-6 col-lg-4">
                        <label class="d-block">Carrera</label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [options]="careers" [config]="dropdownConfig('description')"
                            formControlName="career" class="w-100"></ngx-select-dropdown>
                        </div>
                    </div>   --> 

                    <div class="form-group col-md-6 col-lg-4">
                        <label class="d-block">Experiencia</label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [options]="experiences" [config]="dropdownConfig('stringData')"
                            formControlName="experience" class="w-100"></ngx-select-dropdown>
                        </div>
                    </div>
                    <div class="form-group col-md-6 col-lg-4">
                        <label class="d-block">Educación Formal</label>
                        <div class="dropdown-alt input-group">
                            <textarea  formControlName="academicLevel"
                            class="form-control form-control-lg dynamicFormControl"></textarea>
                        </div>
                    </div>
                    <div class="form-group col-md-6 col-lg-4">
                        <div>
                            <label>
                                Propósito del Cargo
                            </label>
                        </div>
                        <textarea formControlName="purpose"
                            class="form-control form-control-lg dynamicFormControl"></textarea>
                    </div>
                </div>

                <div class="row">
                    
                    

                    <div class="form-group col-md-6 col-lg-4">
                        <div>
                            <label>
                                Actividades Esenciales
                            </label>
                        </div>
                        <textarea formControlName="activity"
                            class="form-control form-control-lg dynamicFormControl"></textarea>
                    </div>

                    <div class="form-group col-md-6 col-lg-4">
                        <div>
                            <label>
                                Otros Conocimientos
                            </label>
                        </div>
                        <textarea formControlName="otherKnowledge"
                            class="form-control form-control-lg dynamicFormControl"></textarea>
                    </div>
                </div>
            </form>
        </div>

        <div class="card-header mt-1 row justify-content-end pt-0" *ngIf="data?.edit">
            <div class="col-sm-12 col-md-7 c-left">
                <div class="card-toolbar d-flex justify-content-end pt-0">
                    <button data-toggle="modal"
                        class="btn btn-success btn-pill font-weight-bolder mx-2 col-md-auto"
                        (click)="openModalAdd()">
                        <i class="flaticon-mas icon-2x" data-toggle="tooltip"
                            data-original-title="Agregar Capacidades y Competencias"></i>
                        Agregar Capacidades y Competencias
                    </button>
                </div>
            </div>
        </div>

        <div class="card-body pt-0">
            <div class="table-responsive">
                <table class="table mt-2 table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th>Competencias</th>
                            <th>Capacidades</th>
                            <th>Grado</th>
                            <th width="120px" class="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of dictionaryRecord">
                            <td>{{item?.competencyName}}</td>
                            <td>{{item?.capabilityDescription}}</td>
                            <td>{{item?.grade}}</td>
                            <td class="text-center ">
                                <button class="btn btn-light-info btn-sm btn-icon m-1"
                                (click)="openViewBehaviorsComponent(item)" >
                                    <i class="flaticon-ver"
                                    data-toggle="tooltip" data-original-title="Ver Comportamientos">
                                </i>
                                </button>
                                <button *ngIf="data?.edit" class="btn btn-light-danger btn-sm btn-icon m-1" (click)="deleteDictionary(item)">
                                    <i class="flaticon-delete" data-toggle="tooltip" data-original-title="Eliminar">
                                    </i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <el-caption #paginator [originalRecordsLength]="dictionary?.length"
                [listedRecordsLength]="dictionaryRecord?.length"
                [registerQuantity]="dictionary?.length"
                [records]="dictionary"
                (onChage)="getPaginatedRecords($event)"></el-caption>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
    *ngIf="data?.edit"
        (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>
