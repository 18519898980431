import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-formula-modal',
  templateUrl: './formula-modal.component.html',
  styleUrls: ['./formula-modal.component.css']
})
export class FormulaModalComponent implements OnInit {

 
  constructor(public dialogRef :MatDialogRef<FormulaModalComponent>) { }

  ngOnInit(): void {
  }
  selectedItems: any;
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  statusOptions = [
    { item_id: 1, item_text: 'Texto de ejemplo' },
  ];

  close(){
    this.dialogRef.close("close");
  }
}
