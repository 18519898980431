import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, Inject, AfterViewChecked, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { forkJoin, Subscription } from 'rxjs';
import { IEmployee } from 'src/app/models/Employee.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { DepartmentService } from 'src/app/services/department.service';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { JobOfferService } from 'src/app/services/job-offer.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { EmployeeCondition } from 'src/app/shared/models/employee-condition.model';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { RequestMetaData, ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { DeclarationModalComponent } from '../../approval-employee-change-actions/declaration-modal/declaration-modal.component';
import { IActionsEmployeesGET } from '../../approval-employee-change-actions/models/ActionsEmployees';
import { changeDetails, changeHeader, EmployeeChange, IAdditionalValue } from '../../approval-employee-change-actions/models/employee-change';
import { IExitList } from '../../approval-employee-change-actions/models/ExitList';
import { ShowSwornDeclarationComponent } from '../../approval-employee-change-actions/show-sworn-declaration/show-sworn-declaration.component';
import { personnelActionTypes } from '../../personnel-actions/personnel-actions-tap/personnel-actions-tap.component';
import { openReport } from 'src/app/shared/utils/utility';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';
type GUID = string & { isGuid: true };

enum changesMap {
  salary = 4,
  position = 2,
  contratType = 3,
  employeeType = 13
}

@Component({
  selector: 'app-history-personal-actions',
  templateUrl: './history-personal-actions.component.html',
  styleUrls: ['./history-personal-actions.component.css']
})
export class HistoryPersonalActionsComponent implements OnInit, AfterViewChecked {
  userFullName = ''
  userApprovalsPosition: any = [];
  userApprovalsSalary: any = [];
  public photoUrl: string = ''
  public loadingPic: boolean = true
  newChanges: changeDetails[] = [];
  changeDeyailsListRecords: any[] = []; /* Para paginar  */
  changeDetailsList: any[] = [];
  changeDeyailsListRecordsOriginal: any[] = [];
  employeeId: number = 0;
  datePie = new DatePipe('en-Us')
  fields: any[] = []
  departments: any[] = []
  positions: any[] = []
  afp: any[] = []
  ars: any[] = []
  banks: any[] = []
  contractTypes: any[] = []
  locations: any[] = []
  designations: IActionsEmployeesGET[] = []
  designationsPaginated: IActionsEmployeesGET[] = []
  motives: any[] = []
  statusValidation: any[] = [];
  fieldType: any = 'drop';
  additionalValueFieldName: string = '';
  personSelected: IEmployee = {
    typeIdentificationId: 0
  };
  exitlists: IExitList[] = [];
  subscriptions: Subscription = new Subscription()
  employeeChangeDTO = new EmployeeChange()
  changeHeaderDTO: changeHeader
  inViewDetails: boolean = false;
  newChangesRecords: any[] = [];
  newChangesRecordsOriginal: any[] = [];
  formIsInvalid: boolean = false;
  parameterType: number;
  employeeEntryForm: FormGroup = new FormGroup({
    employeeEntryList: new FormArray([]),
    employeeEntryListCopy: new FormArray([]),
  });
  parameterName: string

  employeeTypeList: any[] = [];
  additionalValueList: IAdditionalValue[] = [];
  budgetAreas = []
  entryListChanges: any[] = [];
  entryListChangesPaginated: any[] = [];
  routes = ['/employees', '/employee-personnel-actions-list']
  jobOfferBenefitsToggle: ParameterControl[] = [];
  actionChangeForm = new FormGroup({
    employee: new FormControl('', Validators.required),
    employeeName: new FormControl('', Validators.required),
    currentValue: new FormControl('', Validators.required),
    currentData: new FormControl({ value: ''}),
    currentDataId: new FormControl({ value: null}),
    newData: new FormControl({ value: '', disabled: true }, Validators.required),
    newDataId: new FormControl({ value: null, disabled: true }),
    additionalValueObject: new FormControl({ value: null, disabled: true }),
    additionalValue: new FormControl({ value: null, disabled: true }),
    additionalValueName: new FormControl({ value: null, disabled: true }),
    parameter: new FormControl({ value: '', disabled: true }, Validators.required,),
    motive: new FormControl({ value: '', disabled: true }, Validators.required),
    effectiveDate: new FormControl({ value: '', disabled: true }, Validators.required),
    evidence: new FormControl({ value: '', disabled: true }, Validators.required),
    comment: new FormControl({ value: '', disabled: true }),
    condition: new FormControl([0]),
    employeeChangeDetailsId: new FormControl([0]),
    employeeChangeHeaderId: new FormControl([0]),
    dateApproved: new FormControl([null])
  }, { validators: this.ValidateNewData });

  guiID = '';
  file = { document: '', entryListEvidence: '' }
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  personalFile = personalFile;
  
  EntryListEvidenceUppy = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['application/pdf']
    },
    locale:Mexico
  });
  localStorageTab = 'personnel-actions-tab-selected'
  localStorageTab1 = 'action-change-tab-selected'

  private readonly urlDoc = environment.api_doc + '/Document';

  public isSalaryCorrect: boolean = true;

  isSeviceCommission: boolean = false;
  currentEmployeeCondition: EmployeeCondition;
  currentDate = new Date();
  isEdittingEmployeeChange: boolean = false;

  reportUrl = environment.reportUrl

  @ViewChild('uppyFile')
  uppyFile: UppyFileComponent | null = null;

  constructor(public dialogRef: MatDialogRef<HistoryPersonalActionsComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: { employee: IEmployee },
    public authInfo: AuthInfoService, private _cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private actionPSrv: PersonnelActionsService,
    private _toastService: ToastService,
    private sanitizer: DomSanitizer,
    private srvDoc: docService,
    private _parameterCtrlService: ParameterControlService,
    private _builder: FormBuilder,
    private employeeService: EmployeesService,
    private departmentService: DepartmentService,
    private jobOfferService: JobOfferService,
  ) {
    this.dialogRef.disableClose = true;
  }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.userFullName = this.authInfo?.getUserFullName();
    this.employeeId = +this._data?.employee?.employeeId;
    this.setDataEmployee();
    this.getData();
    if (this._data.employee.unemployment) {
      this.actionChangeForm.disable()
    }
  }

  private setDataEmployee(){
    this.personSelected = this._data?.employee;
    this.getDesignations()
    this.actionChangeForm.get('employee').setValue(`${this.personSelected?.employNumber}`);
    this.actionChangeForm.get('employeeName').setValue(`${this.personSelected?.name}`)
    this.actionChangeForm.get('effectiveDate').setValue(null)
    this.actionChangeForm.enable()
    if (this.personSelected.profileImage != '' && this.personSelected.profileImage != null) {
      this.srvDoc.getDocument(this.personSelected.profileImage).subscribe(e => {
        this.photoUrl = e.data;
        this.loadingPic = false;
      })
    } else {
      this.loadingPic = false;
    }
    this.getChangeDetails();
  }

  config: any = {
    placeholder: 'Seleccione una opción'
  }

  additionalValueConfig: any = {
    displayKey: 'additionalValueName',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  }

  motivesConfig = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  }

  fieldsConfig = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  }
  options: any[] = []


  getDesignations() {
    if (!this.personSelected) return
    this.actionPSrv.getDesignations(this.personSelected.employeeId).subscribe((res: any) => {
      if (res.succeded) {
        this.designations = res.dataList
      } else {
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openGuID(guid) {
    try {
      this.srvDoc.getDocument(guid).subscribe(e => {
        if(!e.succeded){
          this._toastService.error('Ha ocurrido un error tratando de consultar el documento seleccionado.')
          return;
        }
        if(!e?.data){
          this._toastService.error('El documento seleccionado no ha podido ser encontrado en el servidor de archivos guardados.')
          return;
        }
        window.open(e.data, '_blank');

      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  setGuID(guid) {
    try {
      this.srvDoc.getDocument(guid).subscribe(e => {
        this.file.document = e.data
        this.guiID = guid;
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  manageFiles(file, position) {

    if (this.personSelected?.status == false || this.isSeviceCommission) {
      return
    }

    let data = {
      SystemLoad: this.SystemLoad,
      ModuleLoad: this.ModuleLoad,
      personalFile: this.personalFile.photo2x2,
      routeFile: this.routeFile
    }

    let formData = new FormData
    formData.append(file.name, file)
    this.srvDoc.createFile(data, formData).subscribe((result: any) => {
      if (result.errors.length > 0) { this._toastService.error(result.errors[0], "Error al tratando de subir el archivo"); return; }
      if (result.succeded) {
        let resData = result.data as FileResponse
        this.guiID = this.getGuid(resData.fileUnit.guidname)
        this.file.entryListEvidence = resData.path;
        this.employeeEntryForm.get('employeeEntryList')["controls"][position]?.get('evidence').setValue(this.guiID ? this.guiID : null)
        this.employeeEntryForm.get('employeeEntryListCopy')["controls"][position]?.get('evidence').setValue(this.guiID ? this.guiID : null)
      }
    }, err => { this._toastService.error(err, "Error al tratando de subir el archivo") }
    )
  }


  showEvidenceErrors() {
    let field = this.actionChangeForm.get('evidence');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  getData() {
    let sub = forkJoin([
      this.actionPSrv.getPersonalActions(),
      this.actionPSrv.getDepartments(this.authInfo.getCompanyId(), 2),
      this.actionPSrv.getPositions(),
      this.actionPSrv.getBankingEntity(),
      this.actionPSrv.getAFP(),
      this.actionPSrv.getARS(),
      this.actionPSrv.getContractType(),
      this.actionPSrv.getLocations(),
      this.actionPSrv.getActionMotives(),
      this.actionPSrv.getStatusGlobalValidation(),
      this.employeeService.getEmployeeActionsByEmployeeId(this.employeeId)
    ]).subscribe((responses) => {
      responses.forEach((response: ResponseModel<any>, i: number) => {
        if (response.succeded) {
          switch (i) {
            case 0:
              this.fields = response.dataList;
              break;
            case 1:
              this.departments = response.dataList;
              break;
            case 2:
              this.positions = response.dataList;
              break;
            case 3:
              this.banks = response.dataList;
              break;
            case 4:
              this.afp = response.dataList;
              break;
            case 5:
              this.ars = response.dataList;
              break;
            case 6:
              this.contractTypes = response.dataList;
              break;
            case 7:
              this.locations = response.dataList;
              break;
            case 8:
              this.motives = response.dataList;
              break;
            case 9:
              this.statusValidation = response.dataList;
              break;
            /*case 10:
              this.entryListParameterControl = response.dataList;
              break;*/
            case 10:
              this.currentEmployeeCondition = response?.dataList[0] as EmployeeCondition;
              this.isSeviceCommission = (response?.dataList as EmployeeCondition[])?.some(employeeCondition => employeeCondition?.typePersonalActionId === personnelActionTypes.commission);
              break;
          }
        } else {
          this._toastService.error(`Ha ocurrido un error cargando el recurso ${i}`);
        }
      });
    });
    this.subscriptions.add(sub);
  }
  getDetails(id) {
    this.actionPSrv.getChangeDetails(id, 3).subscribe((data: any) => {
      if (data.errors.length > 0) { this._toastService.error("Error al obtener los detalles"); return; }
      this.newChanges = data.dataList
      this.newChangesRecordsOriginal = [...data.dataList]
    })

  }
  showDetails(item) {
    this.inViewDetails = true;
    let id = item.employeeChangeHeaderId;
    this.getDetails(id)
  }


  getDropDownConfig(fieldCode: number) {
    switch (fieldCode) {
      case 1: //'Departamento'
        this.config = {
          displayKey: 'departmentName',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 2: //'Posición/Cargo'
        this.config = {
          displayKey: 'positionName',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 3: //'Tipo Contratación'
        this.config = {
          displayKey: 'stringData',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 13: //'Tipo Servidor Público'
        this.config = {
          displayKey: 'description',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      // case 'Entidad Bancaria':
      //   this.config = {
      //     displayKey: 'stringData',
      //     placeholder: 'Seleccione una opción',
      //     search: true,
      //     searchPlaceholder: 'Buscar'
      //   }
      //   break;
      case 7: //'Recinto'
        this.config = {
          displayKey: 'socialReason',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 14: //'area presupuestaria'
        this.config = {
          displayKey: 'description',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 9:
        this.config = {
          displayKey: 'stringData',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 10:
        this.config = {
          displayKey: 'stringData',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;

      default:
        break;
    }
    return this.config
  }

  getDropDownOptions(fieldCode: number) {
    this.options = []
    switch (fieldCode) {
      case 1: //'Departamento'
        this.options = this.departments
        break;
      case 2: //'Posición/Cargo'
        this.options = this.positions
        break;
      case 3: //'Tipo Contratación'
        this.options = this.contractTypes
        break;
      // case 'Entidad Bancaria':
      //   this.options = this.banks
      //   break;
      case 7: //'Recinto'
        this.options = this.locations
        break;
      case 9:
        this.options = this.ars
        break;
      case 10:
        this.options = this.afp
        break;
      case 13: //'Tipo Servidor Público'
        this.options = this.employeeTypeList;
        break;

      default:
        break;
    }
    return this.options
  }

  getEmployeeByNumber() {
    this.actionPSrv.getEmployeeByEmployeeId(this.employeeId).subscribe((res: any) => {
      if (res.errors.length > 0) { this._toastService.error("Error al buscar los datos del Servidor Público"); return; }
      if (res.dataList.length < 1) {
        this.personSelected = null;
        this._toastService.warning("", "No se han encontrado datos del Servidor Público"); return;
      }
      this.personSelected = res.dataList[0];
      this.getDesignations()
      this.actionChangeForm.get('employee').setValue(`${this.personSelected?.employNumber}`);
      this.actionChangeForm.get('employeeName').setValue(`${this.personSelected?.name}`)
      this.actionChangeForm.get('effectiveDate').setValue(null)
      this.actionChangeForm.enable()

      if (this.personSelected.profileImage != '' && this.personSelected.profileImage != null) {
        this.srvDoc.getDocument(this.personSelected.profileImage).subscribe(e => {
          this.photoUrl = e.data;
          this.loadingPic = false
        })
      } else {
        this.loadingPic = false
      }
      this.getChangeDetails();
    }, error => {
    })

  }
  getExitLists(employeeId) {
    this.actionPSrv.getEmployeeExitListByEmployeeId(employeeId).subscribe((res: ResponseModel<any>) => {
      if (res.succeded) {
        this.exitlists = res.dataList
      } else {
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  getChangeDetails() {
    this.actionPSrv.getChangeDetails(this.personSelected?.employeeId, 2).subscribe((res: any) => {
      res.dataList.forEach(x => {
        x.showResendBtn = false
        if (x.parameterChangeId == 2 || x.parameterChangeId == 4) {
          x.showResendBtn = x.publicAccessId == null && x.condition == 1
        }
      })
      this.changeDetailsList = res.dataList;
      this.changeDeyailsListRecordsOriginal = [...res.dataList];
    })
  }
  getPaginatedRecordsHeader(event) {
    this.changeDeyailsListRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  getPaginatedDesignations(event) {
    this.designationsPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  getPaginatedRecordsEntryList(event) {
    this.entryListChangesPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  getPaginatedRecordsDetails(event) {
    this.newChangesRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  fillCurrentData(parameter: number) {
    this.actionChangeForm.get('newData').setValue('')
    this.actionChangeForm.get('additionalValue').setValue(null);
    this.actionChangeForm.get('additionalValueObject').setValue(null);
    this.getDropDownConfig(parameter)
    this.getDropDownOptions(parameter)


    this.additionalValueList = [];
    this.employeeTypeList = [];
    switch (parameter) {
      case 1: //'Departamento'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.departmentName)
        this.actionChangeForm.get('currentValue').setValue(this.options?.find(o => o?.departmentId === this.personSelected?.departmentId));
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.departmentId)
        this.fieldType = 'drop'
        break;
      case 2: //'Posición/Cargo'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.positionName)
        this.actionChangeForm.get('currentValue').setValue(this.options?.find(o => o?.positionId === this.personSelected?.positionId));
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.positionId)
        this.fieldType = 'drop'
        break;
      case 4: //'Sueldo'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.salary)
        this.actionChangeForm.get('currentValue').setValue(this.personSelected?.salary)
        this.actionChangeForm.get('newData').setValue(this.personSelected?.salary)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'number'
        break;
      case 3: //'Tipo Contratación'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.contractType)
        this.actionChangeForm.get('currentValue').setValue(this.options?.find(o => o?.ocode === this.personSelected?.typeContractId));
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.typeContractId)
        this.fieldType = 'drop-additional';
        this.additionalValueFieldName = 'Tipo de Servidor Público';
        break;
      // case 'Entidad Bancaria':
      //   this.actionChangeForm.get('currentData').setValue(this.personSelected?.bankingEntity)
      //   this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.entityBankId)
      //   this.fieldType = 'drop'
      //   break;
      case 7: //'Recinto'
        this.actionChangeForm.get('currentData').setValue(this.locations.find(lt => lt.companyId == this.personSelected?.companyId)?.socialReason)
        this.actionChangeForm.get('currentDataId').setValue(this.locations.find(lt => lt.companyId == this.personSelected?.companyId)?.socialReason)
        this.actionChangeForm.get('currentValue').setValue(this.locations.find(lt => lt.companyId == this.personSelected?.companyId))
        this.fieldType = 'drop'
        break;
      case 9:
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.ars)
        this.actionChangeForm.get('currentValue').setValue(this.options?.find(o => o?.ocode === this.personSelected?.arsid));
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.arsid)
        this.fieldType = 'drop'
        break;
      case 10:
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.afp)
        this.actionChangeForm.get('currentValue').setValue(this.options?.find(o => o?.ocode === this.personSelected?.afpid));
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.afpid)
        this.fieldType = 'drop'
        break;
      case 11: //'Inicio Contrato'
        this.actionChangeForm.get('currentData').setValue(this.datePie.transform(this.personSelected?.admissionDate, 'dd/MM/yyyy'))
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.actionChangeForm.get('currentValue').setValue(this.datePie.transform(this.personSelected?.admissionDate, 'yyyy-MM-dd'))
        this.fieldType = 'date'
        break;
      case 12: //'Exp. Contrato'
        this.actionChangeForm.get('currentData').setValue(this.datePie.transform(this.personSelected?.contractExpiration, 'dd/MM/yyyy'))
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.actionChangeForm.get('currentValue').setValue(this.datePie.transform(this.personSelected?.contractExpiration, 'yyyy-MM-dd'))
        this.fieldType = 'date'
        break;
      case 13: //'Tipo Servidor Público'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.employeeType)
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.typeEmployeeId)
        this.fieldType = 'drop'
        this.getEmployeeTypes(this.personSelected?.typeContractId, parameter);
        break;
      case 14: //'Área presupuestaria'
        this.actionChangeForm.get('currentData').setValue(this.personSelected?.budgetArea)
        this.actionChangeForm.get('currentDataId').setValue(this.personSelected?.budgetAreaId)
        this.fieldType = 'drop'
        this.getBudgetAreas(parameter);
        break;

      default:
        break;
    }
  }

  changeCurrentData(value) {
    let valueType = this.actionChangeForm.get('parameter').value;
    switch (valueType.ocode) {
      case 1: //'Departamento'
        this.actionChangeForm.get('currentDataId').setValue(value?.departmentId);
        this.actionChangeForm.get('currentData').setValue(value?.departmentName);
        break;
      case 2: //'Posición/Cargo'
        this.actionChangeForm.get('currentDataId').setValue(value?.positionId);
        this.actionChangeForm.get('currentData').setValue(value?.positionName);
        break;
      case 4: //'Sueldo'
        this.actionChangeForm.get('currentDataId').setValue(null);
        this.actionChangeForm.get('currentData').setValue(this.actionChangeForm.get('currentValue')?.value);
        break;
      case 3: //'Tipo Contratación'
        this.actionChangeForm.get('currentDataId').setValue(value?.ocode)
        this.actionChangeForm.get('currentData').setValue(value?.stringData);
        this.getEmployeeTypes(value?.ocode, null);
        break;
      // case 'Entidad Bancaria':
      //   this.actionChangeForm.get('newDataId').setValue(value?.ocode)
      //   break;
      case 7: //'Recinto'
        this.actionChangeForm.get('currentDataId').setValue(value?.companyId)
        this.actionChangeForm.get('currentData').setValue(value?.companyName);
        break;
      case 9:
        this.actionChangeForm.get('currentDataId').setValue(value?.ocode);
        this.actionChangeForm.get('currentData').setValue(value?.stringData);
        break;
      case 10:
        this.actionChangeForm.get('currentDataId').setValue(value?.ocode);
        this.actionChangeForm.get('currentData').setValue(value?.stringData);
        break;
      case 11: //'Inicio Contrato'
        this.actionChangeForm.get('currentDataId').setValue(this.datePie.transform(this.actionChangeForm.get('currentValue').value, 'dd/MM/yyyy'));
        this.actionChangeForm.get('currentData').setValue(this.datePie.transform(this.actionChangeForm.get('currentValue').value, 'dd/MM/yyyy'));
        break;
      case 12: //'Exp. Contrato'
        this.actionChangeForm.get('currentDataId').setValue(this.datePie.transform(this.actionChangeForm.get('currentValue').value, 'dd/MM/yyyy'));
        this.actionChangeForm.get('currentData').setValue(this.datePie.transform(this.actionChangeForm.get('currentValue').value, 'dd/MM/yyyy'));
        break;
      case 13: //'Tipo Servidor Público'
        this.actionChangeForm.get('currentDataId').setValue(value?.employeeTypeId);
        this.actionChangeForm.get('currentData').setValue(value?.description);
        break;
      case 14: //'AREA PRESUPUESTARIA'
        this.actionChangeForm.get('currentDataId').setValue(value?.budgetAreaId);
        this.actionChangeForm.get('currentData').setValue(value?.description);
        break;
      default:
        break;
    }
  }

  fillNewData(value) {
    let valueType = this.actionChangeForm.get('parameter').value;
    switch (valueType.ocode) {
      case 1: //'Departamento'
        this.actionChangeForm.get('newDataId').setValue(value?.departmentId)
        break;
      case 2: //'Posición/Cargo'
        this.actionChangeForm.get('newDataId').setValue(value?.positionId)
        break;
      case 4: //'Sueldo'
        this.actionChangeForm.get('newDataId').setValue(null)
        break;
      case 3: //'Tipo Contratación'
        this.actionChangeForm.get('newDataId').setValue(value?.ocode)
        this.getEmployeeTypes(value?.ocode, null);
        break;
      // case 'Entidad Bancaria':
      //   this.actionChangeForm.get('newDataId').setValue(value?.ocode)
      //   break;
      case 7: //'Recinto'
        this.actionChangeForm.get('newDataId').setValue(value?.companyId)
        break;
      case 9:
        this.actionChangeForm.get('newDataId').setValue(value?.ocode)
        break;
      case 10:
        this.actionChangeForm.get('newDataId').setValue(value?.ocode)
        break;
      case 11: //'Inicio Contrato'
        this.actionChangeForm.get('newDataId').setValue(this.datePie.transform(this.actionChangeForm.get('newData').value, 'dd/MM/yyyy'))
        break;
      case 12: //'Exp. Contrato'
        this.actionChangeForm.get('newDataId').setValue(this.datePie.transform(this.actionChangeForm.get('newData').value, 'dd/MM/yyyy'))
        break;
      case 13: //'Tipo Servidor Público'
        this.actionChangeForm.get('newDataId').setValue(value?.employeeTypeId)
        break;
      case 14: //'AREA PRESUPUESTARIA'
        this.actionChangeForm.get('newDataId').setValue(value?.budgetAreaId)
        break;
      default:
        break;
    }
  }

  changeAdditionalValue(value: IAdditionalValue) {
    this.actionChangeForm.get('additionalValue').setValue(value?.additionalValue);
    this.actionChangeForm.get('additionalValueName').setValue(value?.additionalValueName);
  }

  getNewDataValue(): string {
    let field = this.actionChangeForm.get('newData').value;

    let value: string = null;
    let valueType = this.actionChangeForm.get('parameter').value;
    if (field.stringData == undefined) {
      switch (valueType.ocode) {
        case 1: //'Departamento'
          value = field.departmentName
          break;
        case 2: //'Posición/Cargo'
          value = field.positionName
          break;
        case 7: //'Recinto'
          value = field.socialReason
          break;
        case 9:
          value = field.ars
          break;
        case 10:
          value = field.afp
          break;
        case 11: //'Inicio Contrato'
          value = field
          break;
        case 12: //'Exp. Contrato'
          value = field
          break;
        case 4: //'Sueldo'
          value = field
          break;
        case 3: //'Tipo Contratación'
          value = field.contractType
          break;
        case 13: //'Tipo Servidor Público'
          value = field.description
          break;
        case 14: //'area presupuestaria'
          value = field.description
          break;
        // case 'Entidad Bancaria':
        //   value = field.bankingEntity
        //   break;
        default:
          if (!isNaN(field)) { /* comprobar cuando es un sueldo que se esta digitando */
            value = field.toString();
          } else {
            value = field; /* Si es una fecha */
          }
          break;
      }

    } else {
      value = field.stringData;

    }
    return value;
  }

  getOldDataValue(): any {
    let field = this.actionChangeForm.get('newData').value;

  }

  changeEmployeeInfoOnStringParameter(parameterChange: changeDetails) {
    let parameter = this.fields.find(x => x.ocode == parameterChange.parameterChangeId);

    let value: number = null;
    value = parameter?.ocode;
    if (parameter) {
      switch (value) {
        case 1: //'Departamento'
          this.personSelected.departmentName = parameterChange.valueAfter;
          this.personSelected.departmentId = parameterChange.valueAfterId;
          break;
        case 2: //'Posición/Cargo'
          this.personSelected.positionName = parameterChange.valueAfter;
          this.personSelected.positionId = parameterChange.valueAfterId;
          break;
        case 7: //'Recinto'
          this.personSelected.companyName = parameterChange.valueAfter;
          this.personSelected.companyId = parameterChange.valueAfterId;
          break;
        case 9:
          this.personSelected.ars = parameterChange.valueAfter;
          this.personSelected.arsid = parameterChange.valueAfterId;
          break;
        case 10:
          this.personSelected.afp = parameterChange.valueAfter;
          this.personSelected.afpid = parameterChange.valueAfterId;
          break;
        case 11: //'Inicio Contrato'
          this.personSelected.admissionDate = parameterChange.valueAfter;
          break;
        case 12: //'Exp. Contrato'
          this.personSelected.contractExpiration = parameterChange.valueAfter;
          break;
        case 4: //'Sueldo'
          this.personSelected.salary = parameterChange.valueAfterNumeric;
          break;
        case 3: //'Tipo Contratación'
          this.personSelected.contractType = parameterChange.valueAfter;
          this.personSelected.typeContractId = parameterChange.valueAfterId;

          this.personSelected.employeeType = parameterChange.additionalValueName;
          this.personSelected.typeEmployeeId = parameterChange.additionalValue;
          break;
        case 13: //'Tipo Contratación'
          this.personSelected.employeeType = parameterChange.valueAfter;
          this.personSelected.typeEmployeeId = parameterChange.valueAfterId;
          break;
        // case 'Entidad Bancaria':
        //   this.personSelected.bankingEntity = parameterChange.valueAfter;
        //   this.personSelected.entityBankId = parameterChange.valueAfterId;
        //   break;
        default:

          break;
      }

    }

  }

  ValidateNewData(data: AbstractControl) {
    if (data.get('newData').value != '') {
      let newData = data.get('newDataId').value ? data.get('newDataId').value : data.get('newData').value
      let currentData = data.get('currentDataId').value ? data.get('currentDataId').value : data.get('currentData').value
      if (newData == currentData) {
        return { invalidValue: true };
      }
    }
    return null;
  }

  addChangeDetails() {
    this.formIsInvalid = this.actionChangeForm.invalid;
    if (this.formIsInvalid) {
      this._toastService.warning('Debe completar todos los campos requeridos')
      return;
    }

    if (this.fieldType == 'drop-additional' && !this.actionChangeForm.get('additionalValue').value) {
      this._toastService.warning('Debe completar todos los campos requeridos')
      return;
    }
    const effectiveDate = this.actionChangeForm.get('effectiveDate')?.value;
    if (formatDate(effectiveDate, 'yyyy-MM-dd', 'en') >= formatDate(new Date(), 'yyyy-MM-dd', 'en')) {
      this._toastService.warning('La fecha de efectividad debe ser menor a la fecha de hoy');
      return;
    }

    if (formatDate(effectiveDate, 'yyyy-MM-dd', 'en') < formatDate(this.personSelected?.admissionDate, 'yyyy-MM-dd', 'en')) {
      this._toastService.warning('La fecha de efectividad no debe ser menor a la fecha de ingreso del servidor público');
      return;
    }

    if (this.inViewDetails) {
      this.inViewDetails = false;
      this.newChanges = [];
    }

    let f = this.actionChangeForm;
    this.parameterType = f.get('parameter').value?.ocode;
    let condition = (this.parameterType == changesMap.salary || this.parameterType == changesMap.position
      || this.parameterType == changesMap.contratType || this.parameterType == changesMap.employeeType)
    ? 5 : 2;
      
    let valueAfterNumeric = this.isNumeric(this.getNewDataValue()) ? parseFloat(this.getNewDataValue()) : null
    let valueAfter = this.getNewDataValue()?.toString()

    this.newChanges.push({
      employeeChangeHeaderId: 0,
      parameterChangeId: f.get('parameter').value?.ocode,
      valueBeforeId: this.isNumeric(f.get('currentDataId').value) ? f.get('currentDataId').value : 0,//f.get('currentData').value,
      valueBefore: this.isNumeric(f.get('currentData').value) ? f.get('currentData').value.toString() : f.get('currentData').value,
      valueAfterId: this.isNumeric(f.get('newDataId').value) ? f.get('newDataId').value : 0,//this.getNewDataValue(),
      effectiveDate: f.get('effectiveDate')?.value,
      valueAfterNumeric: valueAfterNumeric,
      valueAfter: valueAfter,
      additionalValue: f.get('additionalValue').value,
      additionalValueName: f.get('additionalValueName').value,
      condition: condition,
      companyId: this.personSelected.companyId,
      reasonId: f.get('motive').value.ocode,
      evidence: f.get('evidence').value,
      status: true,
      comment: f.get('comment').value,
      usrApproved: this.authInfo.getUserId(),
      dateApproved: f.get('effectiveDate')?.value,
      parameterName: f.get('parameter').value?.stringData,
      reason: f.get('motive').value?.stringData,
      createUserId: this.isEdittingEmployeeChange ? f.value.createUserId : this.authInfo.getUserId(),
      modifyUserId: this.isEdittingEmployeeChange ? this.authInfo.getUserId() : 0
    })
    this.parameterName = f.get('parameter').value?.stringData
    this.resetInputs()
  }

  remove(item, rowIndex) {
    this.newChanges.splice(rowIndex, 1);
  }

  employeeAppliedForPosition() {
    return this.newChanges.find(x => x.parameterChangeId == 2 && x.condition == 1)
  }

  isDate(date): boolean {
    if (this.isNumeric(date)) {
      return false;
    }
    return (new Date(date).toString() !== "Invalid Date") && !isNaN(Number(new Date(date)));
  }

  isNumeric(value): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    return !isNaN(Number(value));
  }

  buildJobOfferBenefitsForm(inOutId: number, employeeId: number, employeeEntryListId: number, selected: boolean) {
    return this._builder.group({
      employeeEntryListId: [employeeEntryListId],
      employeeId: [Number(employeeId)],
      inOutId: [inOutId, [Validators.required]],
      selected: [selected, [Validators.required]],
      companyId: [this.authInfo.getCompanyId()],
      status: [true],
      evidence: [null, [Validators.required]],
      observations: ["", [Validators.required]],
      assignedBy: this.authInfo.getUserId(),
      deviceBrand: [null],
      deviceModel: [null],
      deviceAsset: [null],
      deviceSerial: [null],
      deviceDeliveredDate: [null],


    });
  }

  pushJobOfferBenefitsItems(benefitTypeId: number, employeeId: number, employeeEntryListId: number, selected: boolean) {
    const item = this.employeeEntryForm.get('employeeEntryList') as FormArray;
    const itemCopy = this.employeeEntryForm.get('employeeEntryListCopy') as FormArray;
    item.push(this.buildJobOfferBenefitsForm(benefitTypeId, employeeId, employeeEntryListId, selected));
    itemCopy.push(this.buildJobOfferBenefitsForm(benefitTypeId, employeeId, employeeEntryListId, selected));
    if (this.personSelected?.status == false || this.isSeviceCommission) {
      item.disable();
      this.employeeEntryForm.disable();
    }
  }

  async setChangeHeader() {
    //ocode 4 = Sueldo | ocode 2 = Posición/Cargo
    let parameterType;
    if (this.parameterType == 4) {
      parameterType = 2
    }
    else if (this.parameterType == 2) {
      parameterType = 1
    }

    else if (this.parameterType == 3) {
      parameterType = 4
    }
    else if (this.parameterType == 13) {
      parameterType = 5
    }
    else {
      parameterType = null
    }
    this.changeHeaderDTO = {
      employeeId: this.personSelected?.employeeId,
      date: new Date().toLocaleString("fr-CA", {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }),
      createUserId: this.isEdittingEmployeeChange ? this.actionChangeForm.value.createUserId : this.authInfo.getUserId(),
      modifyUserId: this.isEdittingEmployeeChange ? this.authInfo.getUserId() : 0,
      companyId: this.personSelected?.companyId,
      requestMapid: null,
      requestTypes: parameterType,
      status: true,
      modifyDate: new Date().toLocaleDateString("fr-CA"),
      createDate: new Date().toLocaleDateString("fr-CA"),
      userName: this.authInfo.getFullUserName()
    }
    this.employeeChangeDTO.header = this.changeHeaderDTO
    this.employeeChangeDTO.details = this.newChanges?.map(newChange => {
      return {
        ...newChange,
        isHistory: true
      }
    });

    const promises = this.employeeChangeDTO.details.map(async detail => {
      if(this.uppyFile?.fileToDelete){
        this.uppyFile.removeFileDefinitely();
      }
      if(detail.evidence){
        const res = await this.uploadFile(detail.evidence as unknown as FormData);
        const resData = res?.data as FileResponse;
        detail.evidence = resData.fileUnit.guidname;
      }
    });
    await Promise.all(promises);

    this.newChanges = this.newChanges.map(x => {
      if(x.parameterChangeId == changesMap.salary || x.parameterChangeId == changesMap.position || x.parameterChangeId == changesMap.contratType || x.parameterChangeId == changesMap.employeeType){
        x.condition = 5;
      }else{
        x.condition = 2;
      }
      return x
    })
    this.createChange();
  }

  uploadFile(formData: FormData) {
    let data = {
      SystemLoad: this.uppyFile.SystemLoad,
      ModuleLoad: this.uppyFile.ModuleLoad,
      personalFile: this.uppyFile.typePersonalFile,
      routeFile: this.uppyFile.routeFile
    }
    return this.srvDoc.createFile(data, formData).toPromise();
  }


  createChange() {
    this.actionPSrv.createHistoryPersonalActions(this.employeeChangeDTO).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        this.newChanges = []
        Swal.hideLoading()
        return;
      }
      Swal.close();
      this._toastService.success("Acción de Personal Registrada Correctamente")
      let theChanges = [...this.employeeChangeDTO.details];
      theChanges.forEach((change) => {
        this.changeEmployeeInfoOnStringParameter(change);
      });
      this.getChangeDetails();
      this.resetInputs();
      this.newChanges = [];
      this.employeeChangeDTO.header = null;
      this.employeeChangeDTO.details = [];
      this.getEmployeeByNumber();

    }, err => {
      Swal.close();
      this._toastService.error(err)
    })
  }


  showRejectionReason(reasonMessage: string) {
    Swal.fire({
      text: reasonMessage,
      icon: "info",
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Entendido'
    })
  }

  resetInputs() {
    this.actionChangeForm.get('parameter').setValue(null)
    this.actionChangeForm.get('currentValue').setValue(null)
    this.actionChangeForm.get('currentData').setValue('')
    this.actionChangeForm.get('currentDataId').setValue(null)
    this.actionChangeForm.get('newData').setValue(null)
    this.actionChangeForm.get('newDataId').setValue(null)
    this.actionChangeForm.get('motive').setValue(null)
    this.actionChangeForm.get('comment').setValue(null)
    this.actionChangeForm.get('effectiveDate').setValue(null)
    this.file.document = '';
    this.guiID = null;
    this.uppyFile.cleanUppy();
    this.actionChangeForm.get('evidence').setValue(null)
    this.additionalValueList = [];
    this.actionChangeForm.get('additionalValue').setValue(null)
    this.actionChangeForm.get('additionalValueName').setValue(null)
    this.actionChangeForm.get('additionalValueObject').setValue(null)
    this.fieldType = this.fieldType == 'drop-additional' ? 'drop' : this.fieldType;
    this.isEdittingEmployeeChange = false;
  }

  showParameterErrors() {
    let field = this.actionChangeForm.get('parameter');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showNewDataErrors() {
    let field = this.actionChangeForm.get('newData');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showMotiveErrors() {
    let field = this.actionChangeForm.get('motive');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showEffectiveDateErrors() {
    let field = this.actionChangeForm.get('effectiveDate');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }


  getEmployeeTypes(contractTypeId: number, parameter: number, additionalValueId?: number) {
    this.jobOfferService.getEmployeeType(contractTypeId).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this._toastService.error(err);
          });
        }

        this.additionalValueList = res.dataList?.filter(e => e?.status)?.map(x => {
          return {
            additionalValue: x.employeeTypeId,
            additionalValueName: x.description
          }
        })
        this.employeeTypeList = res.dataList?.filter(d => d?.status);
        if (parameter == 13) {
          this.actionChangeForm.get('currentValue').setValue(this.employeeTypeList?.find(o => o?.employeeTypeId === this.personSelected?.typeEmployeeId));
          this.getDropDownOptions(parameter)
        }
      },
      error: (err) => {
        this._toastService.error('Ocurrió un error tratando de buscar los tipos de Servidores Públicos. Favor de comunicarse con un Administrador.')
      }
    })
  }

  getBudgetAreas(parameterId?: number) {
    this.jobOfferService.getBudgetAreas().subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          res.errors.forEach(err => {
            this._toastService.error(err);
          });
          return
        }
        this.budgetAreas = res.dataList.filter(x => x.status == true);
        this.options = this.budgetAreas;
        if(parameterId === 14){
          this.actionChangeForm.get('currentValue').setValue(this.options?.find(o => o?.budgetAreaId === this.personSelected?.budgetAreaId));
        }
      },
      error: (err) => {
        this._toastService.error('Ocurrió un error tratando de buscar los tipos de Servidores Públicos. Favor de comunicarse con un Administrador.')
      }
    })
  }

  openDeclarationModal(formItem: FormGroup, isEditing?: boolean) {

    if (isEditing && !formItem.get('deviceAsset').value) {
      return
    }

    this.dialog.open(DeclarationModalComponent, {
      data: {
        formItem,
        isEditing
      }

    }).afterClosed().subscribe({
      next: res => {
        if (!res.created) {
          return
        }
        formItem.get('deviceBrand').setValue(res.deviceInfo?.deviceBrand)
        formItem.get('deviceModel').setValue(res.deviceInfo?.deviceModel)
        formItem.get('deviceAsset').setValue(res.deviceInfo?.deviceAsset)
        formItem.get('deviceSerial').setValue(res.deviceInfo?.deviceSerial)
        formItem.get('deviceDeliveredDate').setValue(res.deviceInfo?.deviceDeliveredDate)
      }
    })
  }

  openSwornDeclarationModal(entryListHistoryId: number) {
    this.dialog.open(ShowSwornDeclarationComponent, {
      data: {
        entryListHistoryId
      },
      width: '100%'
    })
  }

  editEmployeeChange(employeeChange) {
    this.resetInputs();
    this.newChanges = [{ ...employeeChange }];
    this.newChangesRecordsOriginal = [{ ...employeeChange }];
    this.isEdittingEmployeeChange = true;
    const parameter = this.fields.find(f => f?.ocode === employeeChange?.parameterChangeId);
    //this.fillCurrentData(parameter?.ocode);
    const motive = this.motives.find(m => m?.ocode === employeeChange?.reasonId)
    this.actionChangeForm.patchValue({
      ...employeeChange,
      parameter: parameter,
      newDataId: employeeChange?.valueAfterId,
      motive: motive,
      createUserId: employeeChange.createUserId
    });
    if (employeeChange.evidence) {
      this.file.document = employeeChange?.evidence;
      this.guiID = employeeChange?.evidence;
      this.getEvidence(employeeChange?.evidence);
    }
    this.setCurrentData(parameter?.ocode, employeeChange?.valueBeforeId, employeeChange?.valueBefore);
    this.setNewData(parameter?.ocode, employeeChange?.valueAfterId, employeeChange?.additionalValue, employeeChange?.valueAfter);
    this.actionChangeForm.get('effectiveDate').setValue(formatDate(employeeChange?.effectiveDate, 'yyyy-MM-dd', 'en'));
  }

  isShowButtonEdit(employeeChange) {
    return formatDate(employeeChange?.effectiveDate, 'yyyy-MM-dd', 'en') > formatDate(this.currentDate, 'yyyy-MM-dd', 'en')
  }

  getEvidence(guid) {
    this.srvDoc.getDocument(guid).subscribe(res => {
      this.file.document = res.data ? res.data : ''
    })
  }

  setNewData(fieldCode: number, valueAfterId, additionalValueId?: number, valueAfter?: any) {
    switch (fieldCode) {
      case 1: //'Departamento'
        this.actionChangeForm.get('newData').setValue(this.options?.find(o => o.departmentId === valueAfterId))
        break;
      case 2: //'Posición/Cargo'
        this.actionChangeForm.get('newData').setValue(this.options?.find(o => o?.positionId === valueAfterId))
        break;
      case 3: //'Tipo Contratación'
        this.actionChangeForm.get('newData').setValue(this.options?.find(o => o?.ocode === valueAfterId))
        this.getEmployeeTypes(valueAfterId, null, additionalValueId);
        break;
      case 7: //'Recinto'
        this.actionChangeForm.get('newData').setValue(this.options?.find(o => o.companyId === valueAfterId))
        break;
      case 14: //'AREA PRESUPUESTARIA'
        this.getBudgetAreas(valueAfterId);
        break;
      case 11: //'Inicio Contrato'
        this.actionChangeForm.get('newData').setValue(formatDate(valueAfter, 'yyyy-MM-dd', 'en'))
        break;
      case 12: //'Exp. Contrato'
        this.actionChangeForm.get('newData').setValue(formatDate(valueAfter, 'yyyy-MM-dd', 'en'))
        break;
      default:
        this.actionChangeForm.get('newData').setValue(valueAfter)
        break;
    }
  }

  setCurrentData(parameter: number, valueBeforeId: number, valueBefore?: any) {
    this.getDropDownConfig(parameter)
    this.getDropDownOptions(parameter)
    this.additionalValueList = [];
    this.employeeTypeList = [];
    switch (parameter) {
      case 1: //'Departamento'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        break;
      case 2: //'Posición/Cargo'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        break;
      case 4: //'Sueldo'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'text'
        break;
      case 3: //'Tipo Contratación'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop-additional';
        this.additionalValueFieldName = 'Tipo de Servidor Público';
        break;
      case 7: //'Recinto'
        this.actionChangeForm.get('currentData').setValue(this.locations.find(lt => lt?.companyId == valueBeforeId)?.socialReason)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        break;
      case 9:
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        break;
      case 10:
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        break;
      case 11: //'Inicio Contrato'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'date'
        break;
      case 12: //'Exp. Contrato'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'date'
        break;
      case 13: //'Tipo Servidor Público'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        if (this.additionalValueList.length === 0) {
          this.getEmployeeTypes(valueBeforeId, parameter);
        }
        break;
      case 14: //'Área presupuestaria'
        this.actionChangeForm.get('currentData').setValue(valueBefore)
        this.actionChangeForm.get('currentDataId').setValue(valueBeforeId)
        this.fieldType = 'drop'
        if (this.budgetAreas.length === 0) {
          this.getBudgetAreas();
        }
        break;

      default:
        break;
    }
  }

  openReport(change: any) {
    const { employeeChangeDetailsId, employeeId, companyId, parameterChangeId } = change
    let reportUrl = ''

      switch (parameterChangeId) {
        case 1:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_ChangesDepartment&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeeChangeDetailsId}`
          break;

        case 2:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_Changeposition&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeeChangeDetailsId}`
          break;

        case 3:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_Changecontractedfile&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeeChangeDetailsId}`
          break;

        case 4:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_ChangesSalary&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeeChangeDetailsId}`
          break;

        case 7:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_ChangeBranch&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeeChangeDetailsId}`
          break;

        case 11:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_Changecontractedfile&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeeChangeDetailsId}`
          break;

        case 14:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_ChangeBugetArea&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${employeeChangeDetailsId}`
          break;

        default:
          break;
      }


    if (reportUrl.length === 0) {
      return
    }

    let parameters = {
      url: reportUrl,
      title: 'Consulta Acciones de Personal',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }

  exportDesignation(designation) {
    const { actionsEmployeeId, employeeId, companyId } = designation
    let url = `${environment.reportUrl}/?ReportCode=rpt_Changesadmissiondesignation&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${actionsEmployeeId}`
    let parameters = {
      url: url,
      title: 'Reporte Designaciones',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  setEvidence(event){
    this.actionChangeForm.get('evidence').setValue(event);
  }

  viewPdf(formData: FormData): void {
    const pdfFile = formData.get('file');

    if (pdfFile && pdfFile instanceof Blob) {
      const pdfUrl = URL.createObjectURL(pdfFile);
      window.open(pdfUrl); 
    } 
  }

}
