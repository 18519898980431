import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-collective-vacation-public-services-modal',
  templateUrl: './collective-vacation-public-services-modal.component.html',
  styleUrls: ['./collective-vacation-public-services-modal.component.css']
})
export class CollectiveVacationPublicServicesModalComponent implements OnInit {

  titleModal = "Vacaciones Colectivas por Servidor Público";

  IsViewMode = false;

  constructor(
    public dialogRef: MatDialogRef<CollectiveVacationPublicServicesModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  Cancel(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

}
