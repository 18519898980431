<div class="modal-header border-0 dv-table-header-1 bg-primary">
  <h2 class="font-size-h5 text-white">
    <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
    Dashboard Auditar Nómina
  </h2>
  <button type="button" (click)="this.dialogRef.close()" class="close" aria-label="Close">
    <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
  </button>
</div>
<div style="min-height: 30rem;" class="mat-dialog-content-custom">
  <div class="card-body-custom">
    <div class="col-sm-12 c-left">
      <div class="card-toolbar audit-approval d-flex flex-wrap gap-0">
        <div class="col-md-2" style="padding-right: 0px !important;">
          <div class="form-group institution">
            <label class="text-primary"> Tipo de Nómina </label>
            <ngx-select-dropdown [(ngModel)]="payrollTypeSeleted" [options]="payrollTypes"
              [config]="dropdownConfig('stringData')" class="w-100"></ngx-select-dropdown>
          </div>

        </div>

        <div  class="col-md-2" >
          <div class="form-group institution">
            <label class="text-primary"> Nómina </label>
            <ng-multiselect-dropdown [data]="periods" [placeholder]="'Seleccionar'" [(ngModel)]="periodsModel"
            [settings]="dropdownSettings">
          </ng-multiselect-dropdown>
          </div>
        </div>

        <div class="col-md-1 mt-9 ms-4 me-2  w-100" style="padding-left: 0px !important;">
          <button  (click)="search()" class="input-group-text bg-primary col-md-auto " data-toggle="tooltip"
            title="Buscar">
            <i class="flaticon2-search-1  text-white"></i>
          </button>
        </div>
        <ul class="nav nav-primary nav-pills text-uppercase mt-8" id="myTab2" role="tablist">
          <li class="nav-item col-md-auto mb-5">
            <a class="nav-link active" id="nempleado-tab-2" data-toggle="tab" href="#porCantidad">
              <span class="nav-icon mr-5 ">
                <i class="flaticon-productividad icon-2x"></i>
              </span>
              <span class="nav-text font-size-h3">Por Cantidad</span>
            </a>
          </li>
          <li class="nav-item col-md-auto mb-5">
            <a class="nav-link" id="inomina-tab-2" data-toggle="tab" href="#porValores" aria-controls="inomina">
              <span class="nav-icon mr-5 ">
                <i class="flaticon-agil icon-2x"></i>
              </span>
              <span class="nav-text font-size-h3">Por Valores</span>
            </a>
          </li>
          <li class="nav-item col-md-auto mb-5">
            <a class="nav-link" id="inomina-tab-2" data-toggle="tab" href="#porDetalle" aria-controls="idetalle">
              <span class="nav-icon mr-5">
                <i class="flaticon-agil icon-2x"></i>
              </span>
              <span class="nav-text font-size-h3">Detalle</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="myTabContent2">
        <div class="tab-pane fade active show" id="porCantidad" role="tabpanel" aria-labelledby="nempleado-tab-2">
          <!--div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center bg-light-info rounded p-5">
                <div class="d-flex flex-row flex-wrap flex-grow-1 mr-2 justify-content-evenly">
                  <div class="mb-4 mb-sm-0">
                    <h2 class="text-center">{{ total }}</h2>
                    <h3 class="font-weight-bold text-dark-75">Enero 2022</h3>
                  </div>
                  <div class="mb-4 mb-sm-0">
                    <h2 class="text-center">{{ total }}</h2>
                    <h3 class="font-weight-bold text-dark-75">Febrero 2022</h3>
                  </div>
                  <div>
                    <h2 class="text-center">{{ total }}</h2>
                    <h3 class="font-weight-bold text-dark-75">Marzo 2022</h3>
                  </div>
                </div>
              </div>
            </div>
          </div-->
          <div class="table-responsive mt-5">
            <table class="table table-hover table-vertical-center">
              <thead>
                <tr class="bg-primary">
                  <th class="text-center">Secuencia</th>
                  <th>Unidad Organizativa</th>
                  <th class="text-center">Cantidad de Servidores Públicos</th>
                  <th class="text-center">Total Salario Neto</th>
                  <!--th class="text-center">Periodo</th-->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of viewGetDetailByDeparmentsRecords">
                  <td class="text-center">{{item.departmentId}}</td>
                  <td class="">{{item.departmentName}}</td>
                  <td class="text-center">{{item.employeeCount}}</td>
                  <td class="text-center">{{item.totalNetIncome | currency: 'RD$'}}</td>
                  <!--td class="text-center">{{item.totalNetIncome}}</td-->
                </tr>
              </tbody>
            </table>

            <div>
              <el-caption #paginator [originalRecordsLength]="viewGetDetailByDeparmentsOriginalList.length"
                [listedRecordsLength]="viewGetDetailByDeparmentsRecords.length"
                [registerQuantity]="viewGetDetailByDeparments?.length" [records]="viewGetDetailByDeparments"
                (onChage)="getPaginatedRecordsDeparments($event)"></el-caption>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="porValores" role="tabpanel" aria-labelledby="inomina-tab-2">
          <div class="row">
            <!--div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center bg-light-info rounded p-5">
                <div class="d-flex flex-row flex-wrap flex-grow-1 mr-2  justify-content-evenly">
                  <div>
                    <h2 class="text-center">{{ total }}</h2>
                    <h3 class="font-weight-bold text-dark-75">Enero 2022</h3>
                  </div>
                  <div>
                    <h2 class="text-center">{{ total }}</h2>
                    <h3 class="font-weight-bold text-dark-75">Febrero 2022</h3>
                  </div>
                  <div>
                    <h2 class="text-center">{{ total }}</h2>
                    <h3 class="font-weight-bold text-dark-75">Marzo 2022</h3>
                  </div>
                </div>
              </div>
            </div-->
            <div class="table-responsive mt-5" *ngIf="viewGetDetailResumes.length > 0">
              <table class="table table-hover table-vertical-center">
                <thead>
                  <tr class="bg-primary">
                    <th class="text-left">Concepto</th>
                    <th class="text-center" *ngFor="let item of viewGetDetailResumes">
                      {{item?.period}}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td class="text-left">Salario Bruto</td>
                    <td class="text-center" *ngFor="let item  of viewGetDetailResumesOriginalList">{{item.totalGrossSalary | currency: 'RD$'}}</td>
                  </tr>
                  <tr>
                    <td class="text-left">AFP</td>
                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">{{item.totalAfp | currency: 'RD$'}}</td>
                  </tr>
                  <tr>
                    <td class="text-left">SFS</td>
                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">{{item.totalSfs | currency: 'RD$'}}</td>
                  </tr>
                  <tr>
                    <td class="text-left">TSS</td>
                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">{{item.totalTss | currency: 'RD$'}}</td>
                  </tr>
                  <tr>
                    <td class="text-left">ISR</td>
                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">{{item.totalIsr | currency: 'RD$'}}</td>
                  </tr>
                  <tr>
                    <td class="text-left">SFS Empleador</td>
                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">{{item.totalSfse | currency: 'RD$'}}</td>
                  </tr>
                  <tr>
                    <td class="text-left">AFP Empleador</td>
                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">{{item.totalAfpe | currency: 'RD$'}}</td>
                  </tr>
                  <tr>
                    <td class="text-left">SRL(Riesgo Laboral)</td>
                    <td class="text-center" *ngFor="let item  of viewGetDetailResumesOriginalList">{{item.totalSrl | currency: 'RD$'}}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Otros Decuentos</td>
                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">{{item.totalOtherDebits | currency: 'RD$'}}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Otros Ingresos</td>
                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">{{item.totalOtherCredits | currency: 'RD$'}}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Descuento Total</td>
                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">{{item.totalDiscount | currency: 'RD$'}}</td>
                  </tr>
      
                </tbody>
                <tfoot>
                  <tr>
                    <td class="text-left fw-bold">Total Neto</td>
                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">{{item.totalNetIncome | currency: 'RD$'}}</td>
                  </tr>
                </tfoot>
              </table>

         
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="porDetalle" role="tabpanel" aria-labelledby="inomina-tab-2">
         <div>
          <div class="d-flex justify-content-end mr-4">
            <el-search-bar #searchBar (onKeyChange)="onKeyChange($event)" [keyValue]="filterText"
            [records]="viewGetOverallSummariesOriginalList" [itemForSearching]="searchBarDisplayNames"
            (onChange)="getFilteredList($event);">
          </el-search-bar>
         </div>
          <div class="table-responsive mt-5">
            <table class="table table-hover table-vertical-center">
              <thead>
                <tr class="bg-primary">
                  <th>Servidor Público</th>
                  <th>Genero</th>
                  <th class="">Cargo</th>
                  <th class="">Tipo</th>
                  <th class="text-center">Ingreso Bruto</th>
                  <th class="text-center">Ingreso total</th>
                  <th class="text-center">AFP</th>
                  <th class="text-center">SFS</th>
                  <th class="text-center">Otro Desc.</th>
                  <th class="text-center">Total Desc.</th>
                  <th class="text-center">Neto</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of viewGetOverallSummariesRecords">
                  <td>{{item.firstName}} {{item.lastName}}</td>
                  <td class="text-center">{{item?.sex}}</td>
                  <td class="">{{item?.positionName}}</td>
                  <td class="">{{item?.employeeType}}</td>
                  <td class="text-center">{{item?.grossSalary | currency: 'RD$'}}</td>
                  <td class="text-center">{{item?.totalSalary | currency: 'RD$'}}</td>
                  <td class="text-center">{{item?.afp | currency: 'RD$'}}</td>
                  <td class="text-center">{{item?.sfs | currency: 'RD$'}}</td>
                  <td class="text-center">{{item?.otherDiscounts | currency: 'RD$'}}</td>
                  <td class="text-center">{{item?.totalDiscount | currency: 'RD$'}}</td>
                  <td class="text-center">{{item?.netIncome | currency: 'RD$'}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td class=""></td>
                  <td class=""></td>
                  <td class=""></td>
                  <td class="text-center fw-bold">Total</td>
                  <td class="text-center">{{getTotalOverallSummarie('grossSalary') | currency: 'RD$'}}</td>
                  <td class="text-center">{{getTotalOverallSummarie('totalSalary') | currency: 'RD$'}}</td>
                  <td class="text-center">{{getTotalOverallSummarie('afp') | currency: 'RD$'}}</td>
                  <td class="text-center">{{getTotalOverallSummarie('sfs') | currency: 'RD$'}}</td>
                  <td class="text-center">{{getTotalOverallSummarie('otherDiscounts') | currency: 'RD$'}}</td>
                  <td class="text-center">{{getTotalOverallSummarie('totalDiscount') | currency: 'RD$'}}</td>
                  <td class="text-center">{{getTotalOverallSummarie('netIncome') | currency: 'RD$'}}</td>
                </tr>
              </tfoot>
            </table>

            <div>
                <el-caption #paginator [originalRecordsLength]="viewGetOverallSummaries.length"
                [listedRecordsLength]="viewGetOverallSummariesRecords.length"
                [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :viewGetOverallSummaries.length"
                [records]="filteredList.enable ? filteredList.records : viewGetOverallSummaries"
                (onChage)="getPaginatedOverallSummaries($event)"></el-caption>
            </div>
          </div>
        </div>
      </div>

      <!--         <div class="d-flex justify-content-between align-items-end mb-3 row">
            <div class="text-left col-md-8 col-lg-9">
                <h3>Filtrado</h3>
                <div class="text-left d-flex flex-wrap">
                  <h4 class="d-flex font-weight-bolder text-primary me-4">Desde: <span class="text-dark font-weight-lighter ml-3">{{filterParams.date1 | date: 'dd/MM/yyyy'}}</span></h4>
                  <h4 class="d-flex font-weight-bolder text-primary">Hasta: <span class="text-dark font-weight-lighter ml-3">{{filterParams.date2 | date: 'dd/MM/yyyy'}}</span></h4>
                </div>
              </div>
            
              <div class="d-flex flex-nowrap justify-content-between col-12 col-md-3 col-lg-3">
                <button (click)="openDialog()" class="btn btn-primary me-2 w-50">
                    <i class="fas fa-filter" data-toggle="tooltip" data-title="Filtrar"></i>
                </button>
                <button (click)="refreshDashboard()" class="btn btn-light-warning me-2 w-50">
                    <i class="fas fa-redo" data-toggle="tooltip" data-title="Reiniciar filtros"></i>
                </button>
              </div>
            
        </div> -->
      <!--      <div class="card bg-white p-5 gutter-b">
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex  flex-wrap align-items-center bg-light-info rounded p-5"> -->
      <!--        <span class="svg-icon svg-icon-warning svg-icon-4x mr-5">
                            <span class="svg-icon svg-icon-primary svg-icon-2x">
                                begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Text/Bullet-list.svg<svg
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path
                                            d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z"
                                            fill="#000000" />
                                        <path
                                            d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z"
                                            fill="#000000" opacity="0.3" />
                                    </g>
                                </svg>
                             
                            </span>
                        </span> -->
      <!--         <i class="flaticon-agil icon-4x mr-3 text-primary"></i>
                        <div class="d-flex flex-column flex-grow-1 mr-2">
                            <h3 class="font-weight-bold text-dark-75">Total</h3>
                        </div>
                        <span class="font-weight-bolder text-primary py-1 font-size-h2">
                            {{total}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class=" col-12 col-lg-6">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Nóminas por Período</h4>
                    </div>
                    <div class="card-body p-3">
                        <app-chart-dnut [id]="'chartPeriodo'" [data]="periodData"></app-chart-dnut>
                    </div>
                </div>
            </div>
            <div class=" col-md-12 col-lg-6  gutter-b">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Nóminas Rechazadas</h4>
                    </div>
                    <div class="card-body p-3">
                       <app-chart [id]="'chartRechazadas'" [data]="payrollRejects">
                        </app-chart>
                    </div>
                </div>
            </div>
            <div class="col-lg-12  gutter-b">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Novedades por Tipo de Nómina</h4>
                    </div>
                    <div class="card-body p-3">
                        <app-chart-clustered-bar [fieldValue1]="fieldValue1" [fieldValue2]="fieldValue2" [id]="'clusteredbar1'" 
                        [data]="dataPayrollTypes"></app-chart-clustered-bar>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
  </div>
</div>