<ng-container>
    <div class="card card-custom gutter-b">
        <div class="card-header mt-3">
            <div class="card-title">
                <div class="ttl d-flex align-items-center">
                    <i class="flaticon-proceso-3 icon-3x text-danger mr-2"></i>
                    <h1 class="text-primary">Auditar y Aprobar</h1>
                </div>
            </div>
            <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
                data-toggle="tooltip" data-original-title="Dashboard">
                <!-- <button data-toggle="modal" *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.dashboardAction)"
                    class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto" (click)="openDashBoard()" >
                    <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
                </button> -->
            </div>
        </div>

        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-7 c-left">
                <div class="card-toolbar d-flex justify-content-start">

                    <div class="col-md-4 mr-2 px-0">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Período
                            </label>
                            <ngx-select-dropdown  [options]='periodOptions' [config]='periodConfig'
                            [(ngModel)]="period" (change)="changePeriod()" > 
                            </ngx-select-dropdown>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-sm-12 col-md-5 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton" *ngIf="privilege.exportAction.value "
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item align-items-center" (click)="exportToExcel()">
                                <i class="flaticon-excel icon-2x text-success mr-3"></i>
                                Excel
                            </a>
                            <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                                <i class="flaticon-pdf-file icon-2x mr-2 text-danger"></i>
                                PDF
                            </a>
                            <!-- <a class="dropdown-item disabled">
                                <i class="flaticon-word icon-2x text-primary mr-3"></i>
                                Word
                            </a>
                            <a class="dropdown-item disabled">
                                <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                                PDF
                            </a>
                            <a class="dropdown-item disabled">
                                <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                                CSV
                            </a> -->
                        </div>
                    </div>

                    <div class="col text-nowrap">
                        <el-search-bar #searchBar (onKeyChange)="onKeyChange($event)" [keyValue]="filterText"
                            [records]="headersListOriginal" [itemForSearching]="searchBarDisplayNames" 
                            (onChange)="getFilteredList($event);"></el-search-bar>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="table-responsive table-responsive-sigei">
                <table class="table table-hover table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th>Secuencia</th>
                            <th>Tipo Nómina</th>
                            <!-- <th>Tipo Servidor Público</th> -->
                            <th>Área Presupuestaria</th>
                            <!-- <th>Fecha Pago</th> -->
                            <th>Estatus</th>
                            <th class="text-center">Flujo de Trabajo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of paginatedHeaders">
                            <td>{{item?.headerId}}</td>
                            <td>{{item?.payrollType}}</td>
                            <!-- <td>Contratado</td> -->
                            <td>{{item?.budgetArea}}</td>
                            <td>
                                <div *ngIf="item?.status">
                                    <span class="font-weight-bold">{{item.headerCondition}}</span>
                                </div>
                                <div *ngIf="!item?.status">
                                    <span *ngIf="item.status" class="font-weight-bold text-success">Activa</span>
                                    <span *ngIf="!item.status"
                                        class="font-weight-bold text-danger">Inactiva</span>
                                </div>
                            </td>
                            <td class="actionsBtn">
                                <span>
                                    <a data-placement="top" title="Indicadores" data-toggle="tooltip" *ngIf="privilege.indicatorAction.value "
                                        class="btn btn-icon btn-light-skype mr-2" (click)="openIndicators(item)"><i
                                            class="flaticon-dashboard icon-2x" data-target="#nEnviada"
                                            data-toggle="modal"></i></a>
                                    <button [disabled]="item.disableAuditButton" data-placement="top" 
                                    *ngIf=" privilege.auditAction.value && companySign?.useDigitalSignature"
                                        title="Firmar" data-toggle="tooltip"
                                        class="btn btn-icon btn-light-primary mr-2" (click)="digitalSignature(item)">
                                        <i class="flaticon-contract-1 mb-2 icon-2x"></i>
                                    </button>
                                    <ng-container *ngIf="!companySign?.useDigitalSignature && privilege.auditAction.value">
                                          <button [disabled]="item.disableAuditButton" data-placement="top" 
                                        title="Auditar" data-toggle="tooltip"
                                        class="btn btn-icon btn-light-primary mr-2" (click)="fun1(item)">
                                        <i class="flaticon-etiqueta mb-2 icon-2x"></i>
                                        </button>
                                        <ng-container *ngFor="let setting of item.payrollApproveSetting;let i = index" >
                                            <ng-container *ngIf="setting.approvedLevel != 0" >

                                                <button [disabled]="setting.disableButton" data-placement="top"
                                                    title="Aprobar N-{{setting.approvedLevel}}" data-toggle="tooltip"
                                                    class="btn btn-icon mr-2" [ngClass]="{
                                                                    'btn-light-warning':setting.approvedLevel == 2,
                                                                    'btn-light-success':setting.approvedLevel != 2
                                                                }" (click)="fun3(item, setting)">
                                                    <i class="icon-2x" [ngClass]="{
                                                                                            'flaticon-checked' : setting.approvedLevel != 2,
                                                                                            'flaticon-check' : setting.approvedLevel == 2
                                                                                        }"></i>
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    <!-- <a data-placement="top" title="Autorizar N-2" data-toggle="tooltip"
                                        class="btn btn-icon btn-light-success mr-2" (click)="fun2()">
                                        <i class="flaticon-check icon-2x"></i>
                                    </a> -->
                                    </ng-container>
                                    <!--button [disabled]="item.disableAuditButton" data-placement="top" *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.auditAction)"
                                        title="Auditar" data-toggle="tooltip"
                                        class="btn btn-icon btn-light-primary mr-2" (click)="fun1(item)">
                                        <i class="flaticon-etiqueta mb-2 icon-2x"></i>
                                    </button-->
                                    <!--ng-container *ngFor="let setting of item.payrollApproveSetting;let i = index" >
                                        <ng-container *ngIf="setting.approvedLevel != 0 && authInfo.canDoAction(privilege.nameKeyOption, privilege.auditAction)" >

                                            <button [disabled]="setting.disableButton" data-placement="top"
                                                title="Aprobar N-{{setting.approvedLevel}}" data-toggle="tooltip"
                                                class="btn btn-icon mr-2" [ngClass]="{
                                                                'btn-light-warning':setting.approvedLevel == 2,
                                                                'btn-light-success':setting.approvedLevel != 2
                                                            }" (click)="fun3(item, setting)">
                                                <i class="icon-2x" [ngClass]="{
                                                                                        'flaticon-checked' : setting.approvedLevel != 2,
                                                                                        'flaticon-check' : setting.approvedLevel == 2
                                                                                    }"></i>
                                            </button>
                                        </ng-container>
                                    </ng-container-->
                                    <!-- <a data-placement="top" title="Autorizar N-2" data-toggle="tooltip"
                                        class="btn btn-icon btn-light-success mr-2" (click)="fun2()">
                                        <i class="flaticon-check icon-2x"></i>
                                    </a> -->
                                    <a data-placement="top" title="Rechazar" (click)="openRejectModal(item)" *ngIf=" privilege.rejectAction.value "
                                        data-toggle="tooltip" class="btn btn-icon btn-light-danger"><i
                                            class="flaticon-mala-critica icon-2x"></i></a>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>

                    <el-caption #paginator [originalRecordsLength]="headers.length"
                        [listedRecordsLength]="paginatedHeaders.length"
                        [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :headers.length"
                        [records]="filteredList.enable ? filteredList.records : headers"
                        (onChage)="getPaginatedRecords($event)"></el-caption>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<img id="imageid" hidden src="">
<export-data-to-pdf [title]="'Auditar y Aprobar'" id="dataToPrint">
    <ng-container thead>
        <th>Tipo Nómina</th>
        <!-- <th>Tipo Servidor Público</th> -->
        <th>Área Presupuestaria</th>
        <!-- <th>Fecha Pago</th> -->
        <th>Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let item of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.headers" data-row="0" class="datatable-row" style="left: 0px;">
            <td>{{item?.payrollType}}</td>
                            <!-- <td>Contratado</td> -->
            <td>{{item?.budgetArea}}</td>
            <td>
                <div *ngIf="item?.status">
                    <span class="font-weight-bold">{{item.headerCondition}}</span>
                </div>
                <div *ngIf="!item?.status">
                    <span *ngIf="item.status" class="font-weight-bold text-success">Activa</span>
                    <span *ngIf="!item.status"
                        class="font-weight-bold text-danger">Inactiva</span>
                </div>
            </td>
        </tr>
    </ng-container>
</export-data-to-pdf>