import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CompaniesService } from 'src/app/services/companies.service';
import { openReport } from 'src/app/shared/utils/utility';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { AuthInfoService } from 'src/app/services/auth-info.service';

@Component({
  selector: 'app-certification-report',
  templateUrl: './certification-report.component.html',
  styleUrls: ['./certification-report.component.css']
})
export class CertificationReportComponent implements OnInit {
  institutionConfig;
  institutions: any = []
  idType
  identificationTypes = []
  mask: string = ''

  config = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  };

  form = new FormGroup({
    institution: new FormControl('',),
    id: new FormControl('', [Validators.required]),
    type: new FormControl(null, ),
    year: new FormControl('', Validators.required)
  })

  enableNumbersOnly = false

  constructor(
    private conpaniesService: CompaniesService,
    private toastr: ToastrService,
    private location: Location,
    private parameter: ParameterControlService,
    public authInfo: AuthInfoService,
  ) { }

  ngOnInit(): void {
    this.institutionConfig = this.getDropdownConfig('companyName')
    this.getIdentificationTypes()
    // this.getBranches()
  }


  generate(){
    const {id, year } = this.form.value

    if(this.form.invalid){
      this.toastr.warning('Debe llenar los campos')
      return
    }


    let url = environment.reportUrl + `?ReportCode=rrhh_certification&CompanyId=${this.authInfo.getUser().companyId}&Emp=${id}&Year=${year}`
    openReport({url, title: 'Title', width: '900', height: '700'})
  }
  getBranches() {
    this.conpaniesService.getCompanies().subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          this.toastr.error(res.errors[0])
          return;
        }
        this.institutions = res.dataList;

      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    });
  }




  changeIdType(event){
    this.form.get('id').setValue('')
    const {type} = this.form.value
    switch (type.ocode) {
      case 1:
        this.mask = '000-0000000-0'
        this.enableNumbersOnly = false
        break;
      case 2:
        this.mask = ''
        this.enableNumbersOnly = true
        break;
      case 3:
        this.mask = '000-0000000-0'
        this.enableNumbersOnly = false
        break;
      case 4:
        this.mask = '000-0000000-0'
        this.enableNumbersOnly = false
        break;
      case 5:
        this.mask = ''
        this.enableNumbersOnly = false
        break;
      case 6:
        this.mask = ''
        this.enableNumbersOnly = true
        break;
      default:
        this.mask = ''
        break;
    }
  }


  getIdentificationTypes(){
    this.parameter.getParameters(keyWord.IDType).subscribe({
      next: (res:any) => {
        if(res.errors.length> 0){ this.toastr.error(res.errors[0]); return}
        if(res.warnings.length> 0){ this.toastr.warning(res.warnings[0]); return}
        this.identificationTypes = res.dataList
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }
  getDropdownConfig(displayKey: string){
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  goBack() {
    this.location.back();
  }
}
