import { AfterContentChecked, AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormatTypes } from '../../../shared/dynamic-format/enums/format-types';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../shared/loading/loading.service';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { docService } from 'src/app/services/doc.service';
import { IEmployeePermissions } from '../personnel-actions/models/EmployeePermissions';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ToastrService } from 'ngx-toastr';
import { DepartmentService } from 'src/app/services/department.service';
declare const $: any

@Component({
  selector: 'app-pdf-action-change-employee-exservices',
  templateUrl: './pdf-action-change-employee-exservices.component.html',
  styleUrls: ['./pdf-action-change-employee-exservices.component.css']
})
export class PdfActionChangeEmployeeExservicesComponent implements OnInit {
  readonly personalRequestRoute = 'hcm/personal-request';
  public loadingPic = true
  public requisitionTypeList = [];
  subscriptions: Subscription = new Subscription();

  supervisorNames = {
    hhrr: '',
    rectory: '',
    employeeSupervisor: ''
  }
  isTableLoading: boolean;
  formatYesOrNot = FormatTypes.YesOrNo;
  document: any;
  id: number;
  photoUrl: string;
  primaryPhone: string;
  primaryEmail: string;
  skills: [[key: string], { personalSkill: string, personalSkillLevel: string }];
  isLoading: boolean;
  fileModalConfig = {
    src: '',
    type: '',
    filename: '',
    width: 225,
    height: 225,
    formTitle: '',
    formSubTitle: '',
  };

  pdfOpt: any;
  permission: IEmployeePermissions;
  personId: number;
  personSelected: any;

  public company: any;
  file = { logo: '', image1: '', image2:'' }

  constructor(
    private _route: ActivatedRoute,
    public loadingService: LoadingService,
    private srvDoc: docService,
    private _companiesService: CompaniesService,
    private authInfo: AuthInfoService,
    private toastr: ToastrService,
    private personnelActionsService: PersonnelActionsService,
    private _departmentService: DepartmentService,
    private location: Location) {
    this.pdfOpt = {
      orientation: 'p',
      unit: 'in',
      format: [11, 8.5]
    };
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {

    this.id = Number(this._route.snapshot.params["id"] || 0)
    this.personId  = Number(this._route.snapshot.params["personId"] || 0)
    if (this.id == null || this.id == 0) {
      return;
    }

      this.loadingRecordData();
      this.companyById(this.authInfo.getCompanyId())
  }

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  goBack(){
    this.location.back();
  }

  get currentDate(){
    return new Date();
  }


  convert(hora) {
    return (new Date("1955-11-05T" + hora + "Z")).toLocaleTimeString("bestfit", {
        timeZone: "UTC",
        hour12: !0,
        hour: "numeric",
        minute: "numeric"
    });
  };

  loadingRecordData() {

    this.personnelActionsService.getEmployeeByNumberInfoAll(this.personId).subscribe((res: any) => {
      this.personSelected = res.dataList[0]
      const departmentId = this.personSelected.departmentId
      this.getDepartments(departmentId)
      this.srvDoc.getDocument(this.personSelected.profileImage).subscribe(e => {
        this.photoUrl = e.data;
        this.loadingPic = false
      }, error => {
        this.loadingPic = false
      })
    })
    this.getPermission();
  }

  getPermission(){
    this.personnelActionsService.getEployeePermissionById(this.id).subscribe((res: any) => {
      this.permission = res.dataList[0]
    })
  }

  showModal(modalName: string): void {
    $(`#${modalName}`).modal('show');
  }
  getDepartments(employeeSupervisorId){
    this._departmentService.getAll().subscribe((res:any) => {
      if(res.succeded){
        const departments = res.dataList
        const hhrrDepartment = departments.find(x => x.isHhrr == true)
        const employeeDepartment = departments.find(x => x.departmentId == employeeSupervisorId)
        const rectoryDepartment = departments.find(x => x.departmentId == 1000)
        this.supervisorNames.employeeSupervisor = this.getSupervisorName(employeeDepartment)
        this.supervisorNames.rectory = this.getSupervisorName(rectoryDepartment)
        this.supervisorNames.hhrr = this.getSupervisorName(hhrrDepartment)
      }else{
        this.toastr.error(res.errors[0])        
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }
  getSupervisorName(department):string {
    if(!department) return ''
    if(department?.supervisor == "No Definido" || !department?.supervisor){
      return ''
    }
    return department?.supervisor;
  }

}
