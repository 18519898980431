import { Component, Inject, OnInit } from '@angular/core';
import { SelectDepartmentModalComponent } from '../../../select-department-modal/select-department-modal/select-department-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-in-substitution-modal',
  templateUrl: './in-substitution-modal.component.html',
  styleUrls: ['./in-substitution-modal.component.css']
})
export class InSubstitutionModalComponent implements OnInit {

  replacements: any[] = []
  replacementsList: any[] = []

  constructor(
    public dialogRef: MatDialogRef<SelectDepartmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true
  }

  ngOnInit(): void {
    const { substitutionEmployees } = this.data
    this.replacementsList = substitutionEmployees
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No hay resgistros para seleccional',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }


  dropdownSettings = {
    singleSelection: false,
    idField: 'name',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };


  fillReplacingList(replacements: any[]) {
    this.replacements = replacements
  }

  closeReplacemntsModal(updated: boolean) {
    this.dialogRef.close({ updated, replacements: this.replacements })
  }






}
