<div class="card card-custom">
    <div class="card-header mt-3 row justify-content-between">
        <div class="row">
            
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
                <div class="mt-button">
                    <button    type="button" class="btn btn-success btn-sm btn-pill font-weight-bolder m-0" (click)="OpenCreateModal(viewTypes.Create, null)">
                        <i class="flaticon-mas icon-2x"></i>
                        Agregar
                    </button>
                </div>
            </div>
            <div class="col-xl-2 col-lg-8 col-md-8 col-sm-12 col-12">
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
                <div class="mt-button text-right">
                    <div   class="dropdown d-inline-block">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip" data-original-title="Opciones de exportar"></i>
                            Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                            <a class="dropdown-item align-items-center" >
                                <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                                Visualizar Reporte 
                            </a>


                        </div>
                    </div>
                </div>
            </div>
            <div class="col text-nowrap mt-1">
                <el-search-bar   #searchBar (onKeyChange)="onKeyChange($event)" (onKeyParameterChange)="onKeyParameterChange($event)" [keyValue]="filterText"
                    [records]="reviews" [itemForSearching]="searchBarDisplayNames"
                    (onChange)="getFilteredList($event);"></el-search-bar>
            </div>
        </div>
    </div>
    <div class="card-body pt-2">
        <div class="table-responsive table-responsive-sigei">
            <table class="table  table-vertical-center ">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th class="text-left">Fecha de Revisión</th>
                        <th class="text-left">Comentario</th>
                        <th class="text-left">Registrado por</th>
                        <th class="text-left">Fecha de Registro</th>
                        <th  class="text-center actionBtn">
                            Acciones
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of reviewsPaginated; let i = index">
                        <td class="text-center">{{item?.reviewId}}</td>
                        <td class="text-left">{{item?.reviewDate | date:'dd/MM/yyyy' || 'N/A'}}</td>
                        <td class="text-left">{{item?.comment || 'NULL'}}</td>
                        <td class="text-left">{{item?.createdBy || 'N/A'}}</td>
                        <td class="text-left">{{item?.createDate | date:'dd/MM/yyyy' || 'N/A'}}</td>
                        <td class="text-center">
                            <button   data-toggle="tooltip" data-title="Ver" class="btn btn-light-info btn-sm btn-icon m-1" (click)="OpenCreateModal(viewTypes.View, item)">
                                <i class="flaticon-ver"></i>
                            </button>
                            <button *ngIf="item.status"  class="btn btn-light-warning btn-sm btn-icon m-1" data-title="Editar" data-toggle="tooltip" (click)="OpenCreateModal(viewTypes.Edit, item)">
                                <i class="flaticon-edit"></i>
                            </button>
                            <button *ngIf="item.status" class="btn btn-light-danger btn-sm btn-icon " data-title="Inactivar" data-toggle="tooltip" (click)="deleteReview(item)">
                                <i class="flaticon-delete"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <el-caption [originalRecordsLength]="reviewsFiltered.length"
                    [listedRecordsLength]="reviewsPaginated.length" #paginator
                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :reviewsFiltered.length"
                    [records]="filteredList.enable ? filteredList.records : reviewsFiltered"
                    (onChage)="getPaginatedRecords($event)"></el-caption>
            </div>
        </div>
    </div>
</div>
