import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { FilesUnit, PersonalGuidEntity, personRequiredFiles } from 'src/app/shared/models/candidate-registration.model';

@Injectable({
    providedIn: 'root',
})

export class docService {
    private readonly url_api_doc = environment.api_doc
    constructor(private _http: HttpClient) { }

    getDocument(guid: string): Observable<any> {
        return this._http.get<any>(`${this.url_api_doc}/Document?guid=${guid}`);
    }

    getGuidName(companyId: number, SystemLoad: number, ModuleLoad: number, personalFile: number): Observable<any> {
        return this._http.get<any>(`${this.url_api_doc}/RequiresFiles?companyId=${companyId}&SystemLoad=${SystemLoad}&ModuleLoad=${ModuleLoad}&personalFile=${personalFile}`);
    }

    GetGUIDData(data: PersonalGuidEntity[]): Observable<any> {
        return this._http.post<any>(`${this.url_api_doc}/RequiresFiles`, data);
    }

    createFile({ SystemLoad, ModuleLoad, personalFile, routeFile}, formData:any): Observable<any> {
        return this._http.post<any>(`${this.url_api_doc}/Document?SystemLoad=${SystemLoad}&ModuleLoad=${ModuleLoad}&personalFile=${personalFile}&parameterDoc=${routeFile}`, formData);
    }

    deleteFile(guid: string): Observable<any> {
        return this._http.delete<any>(`${this.url_api_doc}/Document?Guid=${guid}`);
    }

    getBase64(url){
        return this._http.get<any>(url);
    }

    getBlob(url): Observable<Blob> {
        return this._http.get(url, {
            responseType: 'blob'
        });
    }
}
