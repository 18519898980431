import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IHttpClient } from './Ihttp-client';
import { candidate, personalInformation, personeEmail, personPhone } from 'src/app/shared/models/candidate-registration.model';

@Injectable({
    providedIn: 'root',
})

export class skillsServices {
    private readonly url_core = environment.api_core_url
    private readonly url_hrm = environment.api_url
    constructor(private _http: HttpClient) { }

    getTypeSkills(idTypeSkill: number = 0,companyId): Observable<any> {
        return this._http.get<any>(`${this.url_core}/TypeSkills?IdTypeSkills=${idTypeSkill}&CompanyId=${companyId}`);
    }

    getSkills(idSkills: number, companyId): Observable<any> {
        return this._http.get<any>(`${this.url_core}/Skills?IdSkills=${idSkills}&CompanyId=${companyId}`);
    }

    getTypeLevelSkills(skillId: number,companyId): Observable<any> {
        return this._http.get<any>(`${this.url_core}/typeLevelSkills?SkillId=${skillId}&CompanyId=${companyId}`);
    }

    getTypeLevelSkill(skillId: number, company: number): Observable<any> {
        return this._http.get<any>(`${this.url_core}/LevelSkill?TypeLevelSkillsId=${skillId}&CompanyId=${company}&Status=true`);
    }

    getLevelSkills(typeLevelSkillsId: number, company: number): Observable<any> {
        return this._http.get<any>(`${this.url_core}/LevelSkill?LevelSkillsId=${typeLevelSkillsId}&CompanyId=${company}&Status=true`);
    }

    delete(id): Observable<any> {
        return this._http.delete<any>(`${this.url_core}/PersonsSkills?IdPersonSkills=${id}`);
    }
}
