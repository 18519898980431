<div data-card="true" class="card card-custom">

        <div class="card-header mt-3">
          <div class="card-title">
            <div class="ttl d-flex align-items-center">
              <i class="flaticon-departamento icon-3x text-danger"></i>
              <h1 class="text-primary ml-2">
                  Reporte de Programación de Vacaciones
              </h1>
            </div>
          </div>
          <div data-toggle="tooltip" data-original-title="Atras" class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard">
            <button type="button" (click)="goBack()" class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning" tabindex="0" ng-reflect-router-link="/medical-history/physicalinven">
              <i class="flaticon2-left-arrow-1"></i>
              <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
            </button>
          </div>
        </div>
    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-7 c-left mr-0">
            <div class="card-toolbar d-flex justify-content-start">
                <!-- <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="form-group institution">
                        <label class="text-primary">
                            Estatus
                        </label>
                        <ng-multiselect-dropdown
                        [placeholder]="'Seleccionar Estatus'"
                        [settings]="dropdownSettings"
                        [data]="estatusList"
                        [(ngModel)]="selectedItems"
                        (onDeSelectAll)="onDeSelectAll()"
                        (onDeSelect)="onItemDeSelect($event)"
                        (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                    </div>
                </div> -->
                <div class="col-xl-9 col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="planningVacationsDetailEmial.length > 0">
                    <button class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2" data-toggle="tooltip" data-original-title="Enviar correos" (click)="sendVacaciones()">
                        <i class="flaticon-enviar icon-2x"></i>
                        Enviar Programación de Vacaciones
                    </button>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip" data-original-title="Opciones de exportar"></i>
                        Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item align-items-center" (click)="generateReport()">
                        <i class="fa fa-print icon-2x text-primary mr-3"></i>
                        Visualizar Reporte
                      </a>
                    </div>
                </div>
                <div class="col text-nowrap">
                  <el-search-bar (onChange)="getFilteredList($event)" #searchBar [keyValue]="filterText"
                  [itemForSearching]="searchBarDisplayNames" [records]="planningVacationsDetailListOriginal"
                  [namePageLocalStorage]="localStorageSearch"></el-search-bar>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">
                            <div class="form-check">
                                <input (change)="selectAllPlanningVacationsDetail($event)" class="form-check-input" type="checkbox" [id]="'select_all'">
                                <label class="form-check-label" [for]="'select_all'"></label>
                            </div>
                        </th>
                        <th class="text-center">Secuencia</th>
                        <th class="text-left">Código de Servidor Público</th>
                        <th class="text-left">Servidor Público</th>
                        <th class="text-left">Documento<br /> Identidad</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-left">Cargo</th>
                        <th class="text-left">Fecha de<br /> Ingreso</th>
                        <th class="text-left">Dias Correspondientes</th>
                        <th class="text-left">Fecha <br />de Inicio</th>
                        <th class="text-left">Fecha <br />de Fin</th>
                        <th class="text-left">Días</th>
                        <th class="text-left">Tipo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of planningVacationsDetail">
                        <td class="text-center">
                            <div class="form-check">
                                <input class="form-check-input" [(ngModel)]="item.selected" (change)="checkplanningVacationsDetail($event, item)" type="checkbox">
                                <label class="form-check-label" [for]="'select_'+item"></label>
                            </div>
                        </td>
                        <td class="text-center">{{item.planningVacationsDetailId }}</td>
                        <td class="text-left">{{item.employNumber}}</td>
                        <td class="text-left">{{item.name}}</td>
                        <td class="text-left">{{item.personalIdentification}}</td>
                        <td class="text-left">{{item.departmentName || 'N/A'}}</td>
                        <td class="text-left">{{item.positionName}}</td>
                        <td class="text-left">{{item.admissionDate | date:'dd/MM/yyyy'}}</td>
                        <td class="text-left">{{item.correspondingDays}}</td>
                        <td class="text-left">{{item.startOfVacation | date:'dd/MM/yyyy'}}</td>
                        <td class="text-left">{{item.endtOfVacation | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-left">{{item.availableDays}}</td>

                        <td class="text-left">
                            <span [ngClass]="{'text-success': item.vacationType === 1 , 'text-danger' : item.vacationType === 2}">{{item.vacationTypeDescription}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <el-caption [originalRecordsLength]="planningVacationsDetailListOriginal?.length"
        [listedRecordsLength]="planningVacationsDetail?.length" #paginator
        [registerQuantity]="planningVacationsDetailList?.length"
        [records]="planningVacationsDetailList"
        (onChage)="getPaginatedRecords($event)"></el-caption>

    </div>
</div>
