<div
  class="subheader subheader-transparent d-flex align-items-center d-md-block"
  id="kt_subheader"
  >
  <!-- *ngIf="breadcrumb?.text" -->
  <div
    class="container-fluid d-flex align-items-center h-100 justify-content-between flex-wrap flex-sm-nowrap"
  >
    <!--begin::Info-->
    <div class="d-flex align-items-center mr-1">
      <!--begin::Page Heading-->
      <div class="d-flex align-items-baseline flex-wrap mr-5">
        <!--begin::Breadcrumb-->
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0"
        >
          <li class="breadcrumb-item text-muted">
            <a class="text-muted">{{ currentRouter?.title }}</a>
          </li>
          <li
            class="breadcrumb-item"
            [ngClass]="{
              active: !currentRouter?.subtext,
              'text-muted': currentRouter?.subtext
            }"
          >
            <span
              *ngIf="!currentRouter?.subtext; else linkBreadCrumb"
              [ngClass]="{
                'font-size-h3': !currentRouter?.subtext,
                'text-muted': currentRouter?.subtext
              }"
              >{{ currentRouter?.text }}</span
            >
            <ng-template #linkBreadCrumb>
              <a [routerLink]="currentRouter.link">{{ currentRouter?.text }}</a>
            </ng-template>
          </li>
          <li
            *ngIf="currentRouter?.subtext"
            class="breadcrumb-item"
            [class.active]="currentRouter.subtext"
          >
            <span class="font-size-h3">{{ currentRouter?.subtext }}</span>
          </li>
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page Heading-->
    </div>
    <!--end::Info-->
  </div>
</div>
