<app-modal-layout (onClose)="close()" styles="min-height: 200px">
    <ng-container title>
        {{title}}
    </ng-container>
    <ng-container body>
        

        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left">Identificación</th>
                                <th class="text-left">Nombre Servidor Público</th>
                                <th class="text-left">Código Concepto</th>
                                <th class="text-left">Categoría</th>
                                <th class="text-left">Fecha Inicio</th>
                                <th class="text-left">Fecha Final</th>
                                <th class="text-left">Monto</th>
                                <th class="text-left">Monto Total</th>
                                <th class="text-left">Saldo</th>
                                <th class="text-left">Valor Manual</th>
                                <th class="text-left">Valor Predefinido</th>
                                <th class="text-left">Observación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of paginatedDataList">
                                <td class="text-left">{{ item?.personalIdentification }}</td>
                                <td class="text-left">{{ item?.name }}</td>
                                <td class="text-left">{{ item?.conceptCode }}</td>
                                <td class="text-left">{{ item?.employeeCategory }}</td>
                                <td class="text-left">{{ item?.startDate | date: 'dd/MM/YYY' }}</td>
                                <td class="text-left">{{ item?.endDate | date: 'dd/MM/YYY' }}</td>
                                <td class="text-right">{{ item?.amount | currency: '$' }}</td>
                                <td class="text-right">{{ item?.totalAmount | currency: '$' }}</td>
                                <td class="text-right">{{ item?.balance | currency: '$' }}</td>
                                <td class="text-right">{{ item?.manualValue | currency: '$'}}</td>
                                <td class="text-right">{{ item?.defaultValue | currency: '$' }}</td>
                                <td class="text-left">{{ item?.observation }}</td>
                                
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <el-caption #paginator [originalRecordsLength]="paginatedDataList?.length"
                            [listedRecordsLength]="paginatedDataList?.length"
                            [registerQuantity]="dataList?.length"
                            [records]="dataList"
                            (onChage)="getPaginatedRecords($event)"></el-caption>
                    </div>
                </div>
            </div>
        </div>


    </ng-container>
<ng-container buttons>
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    
</ng-container>
</app-modal-layout>
