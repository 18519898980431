import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Period } from '@payroll/schedule/models/period.model';
import { ToastService } from 'src/app/shared/toast/toast.service';

import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx'
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { getDOCurrencyFormat } from 'src/app/shared/utils/utility';
import * as moment from 'moment';
import { PostRoyaltyDetailBulkLoad } from '../models/royaltyDetail.model';
import { PayrollService } from 'src/app/services/payroll.service';
import { RoyaltyDetailService } from 'src/app/services/royaltyDetails.service';
import { DataValidationRoyaltyDetailModalComponent } from './data-validation-royalty-detail-modal/data-validation-royalty-detail-modal.component';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';


@Component({
  selector: 'app-royalty-detail-bulkload-modal',
  templateUrl: './royalty-detail-bulkload-modal.component.html',
  styleUrls: ['./royalty-detail-bulkload-modal.component.css']
})
export class RoyaltyDetailBulkloadModalComponent  implements OnInit, AfterViewChecked {
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };

  dataUploaded: any[] = [];
  dataUploadedListOriginal: any[] = [];
  paginatedDataUploaded: any[] = [];

  ProcessButton: boolean = false;
  img1 = ''
  statusList = [
    {text: "Activo", value: true},
    {text: "Inactivo", value: false},
  ]
  @ViewChild('uppyFile')
  uppyFile: UppyFileComponent | null = null;
  employeeStatus = null;
  statusSetting = {
    displayKey: 'text',
    search: true,
    height: 'auto',
    placeholder: 'Seleccione',
    moreText: '...',
    noResultsFound: 'No se han encontrado registros',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'text'
  }

  disabledFields = false

  @ViewChild('paginator') paginator: CaptionComponent;

  validFileExtensions: string[] = ['.xls', '.xlsx'];

  fileUploaded: boolean = false;

  form: FormGroup;

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  selectedItems: any;

  filterText: string = '';

  succeed = false
  isFormValid = true
  startDate: Date;
  endDate: Date;
  alreadyHasData = false
  royaltyDetailData: PostRoyaltyDetailBulkLoad[] = []
  itemIsRequired = false
  constructor(private _toastService: ToastService,
    public dialogRef: MatDialogRef<RoyaltyDetailBulkloadModalComponent>,
    private _changeDet: ChangeDetectorRef, private dialog: MatDialog,
    private fb: FormBuilder,
    private authInfo: AuthInfoService,
    private _royaltyDetailService: RoyaltyDetailService,    
    private _xlsxService: ExportToExcelService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dialogRef.disableClose = true;
    this.form = this.fb.group({
      file: ['']
    });
   
  }

  ngOnInit(): void {
    this.getDataValidation()
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.dataUploaded = this.filterTable(this.dataUploadedListOriginal)
    }
    this.paginator.selectedPage = 1;
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl?.find(x => x?.item_id === 1) && !sl?.find(x => x?.item_id === 2)) {
      list = list?.filter(x => !x?.details);
    }
    if (sl?.find(x => x?.item_id === 2) && !sl?.find(x => x?.item_id === 1)) {
      list = list?.filter(x => x?.details);
    }
    return list;
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  getPaginatedRecords(event) {
    this.paginatedDataUploaded = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  Cancel() {
    const row = { Applied: this.succeed }
    this.dialogRef.close(row);
  }

  Accept() {
    if(!this.uppyFile.fileSelected){
      this._toastService.warning("Debe subir una evidencia")
      return
    }
    this.processAllDataRoyaltyDetail()
  }


  processAllDataRoyaltyDetail(){    
    if(this.itemIsRequired == true){
      this._toastService.warning("Hay errores de validaciones obligatorias.")
      return
    }
    const payload:any = {
      processedByUserId: this.authInfo.getUserId(),
      status: true,
      headerId: Number(this.data.headerId)
    }
    this.uppyFile.handleFile(guid => {
      payload.evidence = guid
      
      this._royaltyDetailService.processAllDataRoyaltyDetail(payload).subscribe({
        next: (res:any) => {
          if(res.succeded == false){
            return
          }
          this.data.getRoyaltyDetail()
          this.dataUploaded = []
          this.dataUploadedListOriginal = []
          this.ProcessButton = false
          this._toastService.success("Novedades procesadas exitosamente.")
        }, error: (error: any) => {

        }
      })
    })

  } 

  dateIsValid(date){
    return moment(date).isValid()
  }

  onClick(){
    this.form.patchValue({
      file: '',
    })
  }
  setFieldsDisabled(){
    this.disabledFields = true
    this.form.get('file').disable()
  }

  fileUpload(event: any) {
    /* if(this.alreadyHasData == true){
      return
    } */
    //this.setFieldsDisabled()
    if (!this.validateFileExtension(event.target.files[0].name)) {
      this._toastService.error('El tipo de archivo cargado no es válido', 'Tipo de Archivo Inválido');
      this.onClick();
      return;
    }
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = (e: any) => {
      let binaryData = e.target.result;
      let workbook = XLSX.read(binaryData, { type: 'binary', cellDates: true, cellFormula: true  });
      let bulkLoad: PostRoyaltyDetailBulkLoad[] = [];
      workbook.SheetNames.forEach((s, i) => {
        if(i > 0){
          return
        }
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[s], { header: 0, raw: false, dateNF: 'yyyy-MM-dd', rawNumbers: true, defval: null, skipHidden: false});
        if (this.fileContentIsInvalid(data)) {
          this._toastService.error('El contenido del archivo cargado no es válido', 'Contenido Inválido');
          return;
        }
        //bulkLoad = this.typeBulkLoadRoyaltyDetail === TypeBulkLoadRoyaltyDetail.BulkLoadNews ? this.buildModel(data) : this.buildModelBulkLoadAmortizable(data);
        bulkLoad = this.buildModel(data)
        this.royaltyDetailData = bulkLoad
        this.postRoyaltyDetailBulkLoad();
        
      });
    }

  }

  getDataValidation(){
    this._royaltyDetailService.getDataValidationRoyaltyDetailSummary().subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          return
        }
        res.dataList.forEach(element => {
          element.isRequiredText = element.isRequired ? 'Sí' : 'No'
        });
        
        this.itemIsRequired = res.dataList.find(x => x.isRequired == true) ? true : false
        this.dataUploaded = res.dataList;
        this.dataUploadedListOriginal = res.dataList;

        if((res.identity === 1 && this.dataUploaded.length === 0) || (!this.itemIsRequired && this.dataUploaded.length > 0)){
          this.ProcessButton = true;
        }
        else{
          this.ProcessButton = false;
        }

        
      }, error: (error: any) => {

      }
    })
  }

  openDetail(item){
    this.dialog.open(DataValidationRoyaltyDetailModalComponent, {
      data: {item}
    })
  }

  

  postRoyaltyDetailBulkLoad(){
    this._royaltyDetailService.postRoyaltyDetailsBulkLoad(this.royaltyDetailData).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this._toastService.error(res.errors[0])
          return
        }
        res.dataList.forEach(element => {
          element.isRequiredText = element.isRequired ? 'Sí' : 'No'
        });
        this.itemIsRequired = res.dataList.find(x => x.isRequired == true) ? true : false

        this.dataUploaded = res.dataList
        this.dataUploadedListOriginal = res.dataList

        if((res.identity === 1 && this.dataUploaded.length === 0) || (!this.itemIsRequired && this.dataUploaded.length > 0)){
          this.ProcessButton = true;
        }
        else{
          this.ProcessButton = false;
        }

      }, error: (error: any) =>{
        this._toastService.error("Ha ocurrido un error inesperado")
      }
    })
  }

  private buildModel(data: any[]): PostRoyaltyDetailBulkLoad[]{
    return data.map((d: any) => {
      return {

        personalIdentification: d['Documento de identidad'] ? d['Documento de identidad'] : '',
        name: d?.Nombre ? d?.Nombre : '',
        code: d['Codigo del concepto'] ? d['Codigo del concepto'] : '',
        period: d['Periodo(MES)'] ? d['Periodo(MES)'] : '',
        amount: d?.Monto != 0 ? Number(d?.Monto) : 0,
        periodMonth: d?.Periodo > 0 ? Number(d?.Periodo) : 0,
      }
    });
  }

  validateFileExtension(fileName: string) {
    const fileArray = fileName.split('.');
    const extension = fileArray[fileArray.length - 1];
    return this.validFileExtensions.some(v => v.includes(extension));
  }

  get isValidData() {
    return this.dataUploadedListOriginal?.every(d => !d.details);
  }

  get errorDate(){
    if(this.startDate && this.endDate){
      return formatDate(this.startDate, 'yyyy-MM-dd', 'en-US') < formatDate(this.endDate, 'yyyy-MM-dd', 'en')
      ? '' : 'La Fecha Inicio no puede ser mayor que la Fecha Final';
    }
    return '';
  }

  private fileContentIsInvalid(data: any[]) {  
    let isInvalid = false
    let dateIsInvalid = false
    data.forEach(x => {
      if(isInvalid == true){
        return
      }
      if(!x['Documento de identidad'] || !x['Codigo del concepto'] || !x?.Monto || !x?.Nombre || !x['Periodo(MES)']){
        isInvalid = true
        return
      }
    })    
    return isInvalid
  }

  remove(data: any){
    this.dataUploadedListOriginal = this.dataUploadedListOriginal?.filter(d => d?.identificationNumber !== data?.identificationNumber);
    this.dataUploaded = this.dataUploadedListOriginal;
    this.filter();
  }
  export(){
    let data = this.paginatedDataUploaded
    if(!data) {
      this._toastService.warning('No hay registros')
      return
    }
    if(data.length < 1) {
      this._toastService.warning('No hay registros')
      return
    }
    let excelHeaders: string[][] = [[
      "Descripción",
      "Comentario",
      "Cantidad de Errores",
      "¿Es requerido?"
    ], [
      "description",
      "comment",
      "quantity",
      "isRequiredText"
    ]]
    this._xlsxService.exportToExcelSpecificColumns(data, excelHeaders, 'Carga masiva novedades', true);
  }
  
}