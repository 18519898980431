import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { IEmployee, IGender } from '../models/EmployeeMaintenance';
import { PersonModel } from '../models/person.model';
import { forkJoin } from 'rxjs';
import { EmployeeMaintenanceService } from 'src/app/services/employee-maintenance.service';
import { PersonsEmail } from '../../pdf-preview/models/pdf-personal-record.model';
@Component({
  selector: 'app-employee-info',
  templateUrl: './employee-info.component.html',
  styleUrls: ['./employee-info.component.css']
})
export class EmployeeInfoComponent implements OnInit, OnDestroy {

  form: FormGroup = this.formBuilder.group({
    employNumber:         [''],
    personalIdentification: [''],
    nss:        [''],
    firstName:  [''],
    lastName:   [''],
    birthday:   [''],
    gender:     [''],
    phone:      [''],
    address:    [''],
    email:      [''],
    status:     ['']
  });
  genders: IGender[] = [];
  employeeEmail:PersonsEmail;

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>(); 
  @Input() selectedEmployee: IEmployee;
  
  @Input() personInfo: PersonModel;
  subscriptions:Subscription = new Subscription();
  constructor(
    private formBuilder: FormBuilder,
    private _employeeMaintenanceService: EmployeeMaintenanceService
  ) { }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  ngOnInit(): void {
    this.getEmployeeEmails(this.selectedEmployee.personId);
  
  }

  getEmployeeEmails(personId:number){
    let sub = this._employeeMaintenanceService.getEmployeeEmails(personId)
      .subscribe((response:ResponseModel<PersonsEmail>)=>{
      this.employeeEmail = (response.succeded && response.dataList.length>0) ? response.dataList[0] : null;
    }, error=> {}
    ,()=>{
      this.buildForm();
    })

    this.subscriptions.add(sub);
  }


  buildForm(){
    if(this.selectedEmployee != null){
      this.form.patchValue({
        employNumber: this.selectedEmployee.employNumber,
        personalIdentification: this.selectedEmployee.personalIdentification,
        nss: 'No existe',
        firstName: this.selectedEmployee.name,
        lastName: this.selectedEmployee.name,
        birthday: formatDate(this.personInfo.birthday, 'yyyy-MM-dd', 'en-US'),
        gender: this.personInfo.sex,
        phone: this.personInfo.phoneNumber,
        address: '',
        email: this.employeeEmail ? this.employeeEmail.email:'No existe',
        status: this.selectedEmployee.status
      })
    }
  }
  
  getStatus(){

  }

  submitForm(){
    this.onSubmit.emit(this.form.value)
  }


}
