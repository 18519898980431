<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Declaración jurada
    </h2>
    <button type="button" class="close" aria-label="Close" (click)="this.dialogRef.close()">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">
    <div id="pdfContent" style="display: none;">
        <div class="row" id="pdfSHow">

        </div>

    </div>
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="this.dialogRef.close()"
        class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>