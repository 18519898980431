import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import Mexico from '@uppy/locales/lib/es_MX'
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { CandidateRegService } from 'src/app/services/http-client/candidateRegService.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { DropdownModel } from 'src/app/shared/interface/dropdown-model';
import { FileResponse, personsAppliedStudies } from 'src/app/shared/models/candidate-registration.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { parameterDoc } from 'src/app/shared/utils/parameterDoc';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CandidateRegistrationComponent } from '../../candidate-registration.component';
import { MatDialog } from '@angular/material/dialog';
import { ShowDocumentModalComponent } from 'src/app/shared/show-document-modal/show-document-modal.component';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-academic',
  templateUrl: './academic.component.html',
  styleUrls: ['./academic.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcademicComponent implements OnInit, AfterViewInit {
  public academicLevelList: any[] = [];
  public careerList = [];
  public dataCareerList: any[] = [];
  public languageLevelList: any[] = [];
  public academicData: personsAppliedStudies[] = [];
  public index = -1;
  carrer = null;
  public data = {
    personAppliedStudiesId: 0,
    personId: 0,
    academicLevelId: 0,
    careerId: 0,
    studyHouse: '',
    academicIndex: null,
    endYear: null,
    documentPhoto: null,
    companyId: this.authInfo.getCompanyId(),
    status: true,
    comment: '',
    file: null
  } as personsAppliedStudies & { file?: FormData }
  config = {
    moreText: 'más',
    placeholder: 'Seleccione una opción',
    searchPlaceholder: 'Buscar',
    search: true,
    displayKey: 'description',
    searchOnKey: 'description'
  }

  configACL = {
    moreText: 'más',
    placeholder: 'Seleccione una opción',
    searchPlaceholder: 'Buscar',
    search: true,
    displayKey: 'stringData',
    searchOnKey: 'stringData'
  }

  academicLevelSelected: any = {
    parametersId: 0,
    keyWord: "",
    companyCode: 0,
    ocode: 0,
    description: "",
    moduleId: 0,
    ccode: "",
    numericData: null,
    doubleData: null,
    stringData: "",
    booleanData: null,
    dateData: null,
    socialReason: "",
    shortName: "",
    status: true
  }

  isEditting: boolean = false;

  @ViewChild('uppyFile')
  uppyFileComponent: UppyFileComponent | null = null;

  constructor(
    private srvCandidateService: CandidateRegService,
    private srvPersonService: PersonService,
    private toast: ToastService,
    private srvDoc: docService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private authInfo: AuthInfoService,
    private dialog: MatDialog,
  ) { }


  ngAfterViewInit(): void {
    this.changeDetector();
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit(): void {
    this.loadDDL()
  }

  changeDetector() {
    this.cdr.detectChanges();
  }

  loadDDL() {
    this.srvCandidateService.getParameters(keyWord.AcademicLevel).subscribe(res => {
      if (res.errors.length > 0) {
        this.toast.error(res.errors[0])
        return;
      }

      this.academicLevelList = res.dataList;
      // this.academicLevelSelected = { ...this.academicLevelList[0] };
      // this.changeAcademicLevel();
      this.changeDetector();
    }, error => {
    })

    this.srvPersonService.getCarrers().subscribe(e => {
      if (e.errors.length > 0) {
        this.toast.error(e.errors[0])
        return;
      }
      this.dataCareerList = e.dataList;
      this.careerList = [...this.dataCareerList];
      this.changeDetector();
    }, error => {
      this.toast.error(error)
    })
  }

  selectCarrer(event) {
    this.data.careerId = event.value.careerId
  }

  viewDocument(study){
    const {documentPhoto} = study
    this.getDocument(documentPhoto, (data) => {
      if(!data){
        this.toast.warning("No se pudo obtener o no existe el documento")
        return
      }
      this.dialog.open(ShowDocumentModalComponent, {
        data: {
          src: data,
          title: 'Ver Documento'
        },
        width: '80%'
      })
    })
  }

  getDocument(guid, callback: (str: any) => void){
    return this.srvDoc.getDocument(guid).subscribe({
      next: (res:any) => {
        callback(res.data)
      }, error: error => {

      }
    })
  }


  reset() {
    this.carrer = null
    this.index = -1
    this.data = {
      personAppliedStudiesId: 0,
      personId: 0,
      academicLevelId: 0,
      careerId: 0,
      studyHouse: '',
      academicIndex: null,
      endYear: null,
      documentPhoto: null,
      companyId: this.authInfo.getCompanyId(),
      status: true,
      comment: ''
    } as personsAppliedStudies
    this.academicLevelSelected = null;
    this.carrer = null;
    this.resetDataCareer();
    //this.changeAcademicLevel();
    //this.data.careerId = this.careerList[0].careerId;
    this.isEditting = false;
  }

  addAC() {
    const uppyFile = this.uppyFileComponent;

    if (!uppyFile?.existFile) {
      this.toast.warning('El campo de evidencia es obligatorio')
      return;
    }

    if (this.data.endYear < 1900) {
      this.toast.warning('El año de término no es válido', '')
      return;
    }

    this.data.endYear = Number(this.data.endYear); 
    if (this.index < 0) {
      this.academicData.push(this.data);
      this.reset()
    } else {
      this.academicData[this.index] = { ...this.data };
      this.index = -1
      this.isEditting = false;
    }
    this.resetDataCareer();
    document.getElementById('btnremove').click()
    this.changeDetector();

    // if (this.data.documentPhoto == '' && this.doc.state.files) {
    //   this.toast.warning('Tienes uno o mas archivos seleccionados. Por favor subirlo/s antes de guardar.')
    //   return;
    // }

  }

  uploadFile(formData: FormData) {
    let data = {
      SystemLoad: this.uppyFileComponent.SystemLoad,
      ModuleLoad: this.uppyFileComponent.ModuleLoad,
      personalFile: this.uppyFileComponent.typePersonalFile,
      routeFile: this.uppyFileComponent.routeFile
    }
    return this.srvDoc.createFile(data, formData).toPromise();
  }

  existFileToDelete(){
    return this.uppyFileComponent.fileToDelete?.length > 0;
  }

  deleteFile(){
    return this.srvDoc.deleteFile(this.uppyFileComponent.fileToDelete).toPromise();
  }

  getDescripcionACLevel(id) {
    try {
      return this.academicLevelList.filter(e => e.ocode == id)[0].stringData
    } catch (error) {

    }
  }
  getDescripcionACGrado(id) {
    try {
      if (this.dataCareerList && this.dataCareerList.length > 0)
        return this.dataCareerList.filter(e => e.careerId == id)[0].description
    } catch (error) {

    }
  }

  setFormFile(file: FormData){
    this.data.file = file;
  }

  removeAC(i) {
    let id = this.academicData[i].personAppliedStudiesId;

    if (id == 0) {
      this.academicData.splice(i, 1);
    } else {
      this.swalQuestion(() => {
        this.srvPersonService.deleteAcademicData(id).subscribe(e => {
          this.toast.success('Registro borrado')
          this.academicData.splice(i, 1);
          this.changeDetector();
        }, error => {
          this.toast.error(error)
        });
      });
    }
  }

  btnDisabledAC() {
    if (this.data.academicLevelId < 0 
      || this.data.careerId <= 0 || this.data.studyHouse?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') == '' ||
      this.data.endYear <= 0 || !this.uppyFileComponent?.existFile) {
      return false
    } else {
      return true
    }
  }

  edit(data, index, careerId = 0) {
    try {
      this.index = index;
      this.data = { ...this.academicData[index] };

      this.academicLevelSelected = this.academicLevelList.find(a => a.ocode === this.data.academicLevelId);
      this.changeAcademicLevel();
      if (careerId > 0) {
        this.carrer = this.careerList.find(x => x.careerId == careerId)
      }
      this.isEditting = true;
    } catch (error) {
      this.toast.error(error);
    }
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  changeAcademicLevel() {
    this.data.academicLevelId = this.academicLevelSelected.ocode;
    this.careerList = [...this.dataCareerList.filter(c => c.gradeId === this.data.academicLevelId)];
    this.carrer = null
    /* if(!this.isEditting){
      this.data.careerId = this.careerList[0].careerId;
    } */
  }

  resetDataCareer() {
    //this.doc.reset()
    this.data.documentPhoto = null;
    this.isEditting = false;
    this.careerList = [...this.dataCareerList];
    //this.changeAcademicLevel();
  }

  swalQuestion(fn: () => void) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      title: "¿Está seguro que desea eliminar este registro?",
      icon: "question"
    }).then(res => {
      if (res.isConfirmed) {
        fn();
      }
    });
  }

}
