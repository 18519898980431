import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PayrollService } from 'src/app/services/payroll.service';

@Component({
  selector: 'app-payroll-royalty-detail',
  templateUrl: './payroll-royalty-detail.component.html',
  styleUrls: ['./payroll-royalty-detail.component.css']
})
export class PayrollRoyaltyDetailComponent implements OnInit {

  dataList = []
  constructor(
    private dialogRef: MatDialogRef<PayrollRoyaltyDetailComponent>,
    private srvPayroll: PayrollService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private toastr: ToastrService
  ) { 
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.dataList = this.data.details
  }



  


  close(success = false){
    this.dialogRef.close({success})
  }
}
