<div data-card="true" class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <!-- <img src="assets/svg/medical/medical-case-svgrepo-com.svg" class="svg-icon svg-icon-4x text-danger mr-3 filter-danger"> -->
                <i class="flaticon-lista-de-verificacion-3 icon-4x text-danger mr-3"></i>
                <h1 class="text-primary mt-4"> Detalle Inventario Físico </h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Atras">
            <button type="button" routerLink="/medical-history/physicalinventory" class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
            </button>
        </div>
    </div>
    <div class="card-header mt-3">
        <div class="row w-100">
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                    <label>Fecha del Inventario</label>
                    <div>
                        <input type="date"  class="form-control form-control-lg" [value]="cutOffDate" readonly="readonly" />
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                    <label>Realizado Por</label>
                    <div>
                        <input type="text" class="form-control form-control-lg" [value]="InventoryHeaeder?.name" readonly="readonly" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <ul class="nav nav-tabs xmera-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="detail-physical-tab-01-tab"  data-toggle="tab" data-target="#detail-physical-tab-01" type="button" role="tab" aria-controls="detail-physical-tab-01" aria-selected="true">
                    <i class="fa fa-check-circle me-2" aria-hidden="true"></i>
                    Inventario realizado
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="detail-physical-tab-02-tab" data-toggle="tab" data-target="#detail-physical-tab-02" type="button" role="tab" aria-controls="detail-physical-tab-02" aria-selected="false">
                    <i class="fa fa-calculator me-2" aria-hidden="true"></i>
                    Ajustes de Entrada Realizados
                </button>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="detail-physical-tab-01" role="tabpanel" aria-labelledby="detail-physical-tab-01-tab">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="table-responsive">
                            <table class="table table-hover table-vertical-center">
                                <thead>
                                    <tr class="bg-primary">
                                        <th class="text-center">Código</th>
                                        <th class="text-left">Producto</th>
                                        <th class="text-center">Existencia según el sistema</th>
                                        <th class="text-center">Conteo físico</th>
                                        <th class="text-center">Diferencia</th>
                                        <th class="text-center">Concepto de la Diferencia</th>
                                        <th class="text-center">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of inventoryDetailRecord">
                                        <td class="text-center">{{item.inventoryDetailId}}</td>
                                        <td class="text-left">{{item.shortDescription}}</td>
                                        <td class="text-center">{{item.stock}}</td>
                                        <td class="text-center">{{item.physicalCount || '0'}}</td>
                                        <td class="text-center">{{item.different || item.stock}}</td>
                                        <td class="text-center">
                                          <strong *ngIf="item.stock > item.physicalCount  " class="text-danger">
                                            FALTANTE
                                          </strong>
                                          <strong *ngIf="item.stock < item.physicalCount" class="text-warning">
                                              SOBRANTE
                                          </strong>
                                          <strong *ngIf="item.stock == item.physicalCount" class="text-success">
                                            No Necesita Ajuste
                                        </strong>
                                        </td>
                                        <td class="text-center">
                                            <a *ngIf="item.different != 0 && conditionId == 2" (click)="AjusteDeEntrada(item)" tooltip="Ajuste de entreda" placement="top" data-toggle="modal" class="btn btn-sm btn-light-skype btn-icon mr-2">
                                                <i class="flaticon-llaves icon-2x" data-toggle="tooltip" data-title="Ajuste de entreda"></i>
                                            </a>
                                            <!-- <button  disabled *ngIf="item.different == 0" tooltip="No nesecita ajuste" placement="top" data-toggle="modal" class=" pix btn btn-sm btn-light-success btn-icon mr-2">
                                              <i class="flaticon-check icon-2x" data-toggle="tooltip" data-title="No nesecita ajuste"></i>
                                            </button> -->
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                            <el-caption [originalRecordsLength]="InventoryDetail?.length"
                            [listedRecordsLength]="inventoryDetailRecord?.length" #paginator
                            [registerQuantity]="InventoryDetail?.length"
                            [records]="InventoryDetail"
                            (onChage)="getPaginatedRecord($event)"></el-caption>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="detail-physical-tab-02" role="tabpanel" aria-labelledby="detail-physical-tab-02-tab">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="table-responsive">
                            <table class="table table-hover table-vertical-center">
                                <thead>
                                    <tr class="bg-primary">
                                        <th class="text-center">Código</th>
                                        <th class="text-left">Descripción</th>
                                        <th class="text-center">Existencia Según el Sistema</th>
                                        <th class="text-center">Conteo Físico</th>
                                        <th class="text-center">Diferencia</th>
                                        <th class="text-center">Concepto de la Diferencia</th>
                                        <th class="text-center">Fecha del Ajuste</th>
                                        <th class="text-center">Cantidad</th>
                                        <th class="text-center">Ajuste Realizado Por</th>
                                        <th class="text-center">Nueva Existencia</th>
                                        <th class="text-center">Razón De Ajuste</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of inventoryChangeRecord">
                                        <td class="text-center">{{item.inventoryChangesId}}</td>
                                        <td class="text-left">{{item.shortDescription || 'N/A'}}</td>
                                        <td class="text-center">{{item.oldStock}}</td>
                                        <td class="text-center">{{item.newStock}}</td>
                                        <td class="text-center">{{item.difference}}</td>
                                        <td class="text-center">
                                            <strong *ngIf="item.oldStock  > item.newStock" class="text-danger">
                                                FALTANTE
                                            </strong>
                                            <strong *ngIf="item.oldStock  < item.newStock" class="text-warning">
                                              SOBRANTE
                                          </strong>
                                        </td>
                                        <td class="text-center">{{item.createDate | date: 'dd/MM/yyyy'}}</td>
                                        <td class="text-center">{{item.amountChanges}}</td>
                                        <td class="text-center">{{item.name}}</td>
                                        <td class="text-center">{{item.newStock}}</td>
                                        <td class="text-center">
                                            <a *ngIf="item.different != 0 && conditionId != 1" (click)="adjustmentreason(item.conceptChanges)" tooltip="Ajuste de entreda" placement="top" data-toggle="modal" class="btn btn-sm btn-light-success btn-icon mr-2">
                                                <i class="flaticon-despacho-de-aduana icon-2x" data-toggle="tooltip" data-title="Ajuste de entreda"></i>
                                            </a>
                                        </td>


                                    </tr>
                                </tbody>
                            </table>
                            <el-caption [originalRecordsLength]="inventoryChange?.length"
                            [listedRecordsLength]="inventoryChangeRecord?.length" #paginator
                            [registerQuantity]="inventoryChange?.length"
                            [records]="inventoryChange"
                            (onChage)="getPaginatedRecords($event)"></el-caption>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
