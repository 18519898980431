import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IBehaviourView, ICapabilityView, ICompetency, ICompetencyPost, IDictionaryView } from '../../models/competency';
import { ResponseModel } from '../../shared/models/strongly-typed-response.model';
import { AuthInfoService } from '../auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class CompetencyService {

  private readonly API_HRM = environment.api_url;

  constructor(private httpClient: HttpClient, private authInfo: AuthInfoService) { }

  getCompetencyDictionary(type: number = 0){
    return this.httpClient.get<ResponseModel<IDictionaryView>>(`${this.API_HRM}/Competency/CompetenciesDictionary?CompanyId=${this.authInfo.getCompanyId()}&type=${type}`);
  }

  getCompetencyList(competencyId: number,competencyTypeId: number,companyId: number, type: number, status: boolean = true){
    return this.httpClient.get<ResponseModel<any>>(`${this.API_HRM}/Competency?competencyId=${competencyId}&competencyTypeId=${competencyTypeId}&companyId=${companyId}&type=${type}&status=${status}`);
  }

  getBehaviours(agreementId: number,capabilityId: number,competencyId: number,positionId: number,type: number = 0, status: boolean = true){
    return this.httpClient.get<ResponseModel<IBehaviourView>>(`${this.API_HRM}/Behaviour`,{
      params: <any>{
        agreementId,
        capabilityId,
        competencyId,
        positionId,
        type, 
        status,
        companyId: this.authInfo.getCompanyId()
      }
    });
  }

  getCapabilities(competencyId: number){
    return this.httpClient.get<ResponseModel<ICapabilityView>>(`${this.API_HRM}/Capability?CompanyId=${this.authInfo.getCompanyId()}&CompetencyId=${competencyId}&Status=true`);
  }

  insertCompetency(model: ICompetencyPost){
    return this.httpClient.post<ResponseModel<ICompetency>>(`${this.API_HRM}/Competency`,model);
  }

  updateCompetency(model: ICompetencyPost){
    return this.httpClient.put<ResponseModel<ICompetency>>(`${this.API_HRM}/Competency`,model);
  }

  deleteBehaviour(behaviourId: number){
    return this.httpClient.delete<ResponseModel<null>>(`${this.API_HRM}/Behaviour?behaviourId=${behaviourId}&userId=${this.authInfo.getUserId()}`);
  }

  deleteCapability(capabilityId: number){
    return this.httpClient.delete<ResponseModel<null>>(`${this.API_HRM}/Capability?capabilityId=${capabilityId}&userId=${this.authInfo.getUserId()}`);
  }

  deleteCompetency(competencyId: number){
    return this.httpClient.delete<ResponseModel<null>>(`${this.API_HRM}/Competency?competencyId=${competencyId}&userId=${this.authInfo.getUserId()}`);
  }

  restoreCompetency(competencyId: number){
    return this.httpClient.delete<ResponseModel<null>>(`${this.API_HRM}/Competency/Restore?competencyId=${competencyId}&userId=${this.authInfo.getUserId()}`);
  }

  restoreCapability(capabilityId: number){
    return this.httpClient.delete<ResponseModel<null>>(`${this.API_HRM}/Capability/Restore?capabilityId=${capabilityId}&userId=${this.authInfo.getUserId()}`);
  }
}
