<div class="modal-header border-0 dv-table-header-1 bg-primary d-flex"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Filtros avanzados
    </h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h5"></i>
    </button>
</div>
<div class="mat-dialog-content-custom" [formGroup]="form">
    <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Secuencia</label>
                <div>
                    <input type="text" formControlName="code" class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Concepto</label>
                <div>
                    <input type="text" formControlName="concept" class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Nombre corto</label>
                <div>
                    <input type="text" formControlName="shortName"  class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Tipo de concepto</label>
                <div>
                    <ng-multiselect-dropdown
                        formControlName="conceptType"
                        [placeholder]="'Seleccione'"
                        [settings]="dropdownSettings"
                        [data]="conceptTypes">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Tipo de novedad</label>
                <div>
                    <ng-multiselect-dropdown
                        formControlName="newsType"
                        [placeholder]="'Seleccione'"
                        [settings]="dropdownSettings"
                        [data]="newsTypes">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Beneficiario</label>
                <div>
                  <ng-multiselect-dropdown
                      formControlName="beneficiary"
                      [placeholder]="'Seleccione'"
                      [settings]="dropdownSettings"
                      [data]="beneficiaryList">
                  </ng-multiselect-dropdown>
              </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="form-group">
                <label>¿Aplica para TSS?</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input formControlName="isTss" class="form-check-input" type="radio"  id="TSS1" [value]="true">
                        <label class="form-check-label" for="TSS1">Si</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input formControlName="isTss" class="form-check-input" type="radio"  id="TSS2" [value]="false">
                        <label class="form-check-label" for="TSS2">No</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="form-group">
                <label>¿Aplica para ISR?</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input formControlName="isIsr" class="form-check-input" type="radio"  id="ISR1" [value]="true">
                        <label  class="form-check-label" for="ISR1">Si</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input formControlName="isIsr" class="form-check-input" type="radio"  id="ISR2" [value]="false">
                        <label class="form-check-label" for="ISR2">No</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="form-group">
                <label>¿Funciona como salario?</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input formControlName="isSalary" class="form-check-input" type="radio"  id="FuncionaSalario1" [value]="true">
                        <label class="form-check-label" for="FuncionaSalario1">Si</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input formControlName="isSalary" class="form-check-input" type="radio"  id="FuncionaSalario2" [value]="false">
                        <label class="form-check-label" for="FuncionaSalario2">No</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="form-group">
                <label>¿Es un débito?</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input formControlName="isDebit" class="form-check-input" type="radio"  id="EsDebito1" [value]="true">
                        <label class="form-check-label" for="EsDebito1">Si</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input formControlName="isDebit" class="form-check-input" type="radio" id="EsDebito2" [value]="false">
                        <label class="form-check-label" for="EsDebito2">No</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="form-group">
                <label>¿Puede ser mayor al salario?</label>
                <div>
                    <div class="form-check form-check-inline">
                        <input formControlName="higherThanSalary" class="form-check-input" type="radio"  id="MayorAlSalario1" [value]="true">
                        <label class="form-check-label" for="MayorAlSalario1">Si</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input formControlName="higherThanSalary" class="form-check-input" type="radio"  id="MayorAlSalario2" [value]="false">
                        <label class="form-check-label" for="MayorAlSalario2">No</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mat-dialog-actions"[align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary" type="button"
        (click)="Accept()">
        <i class="flaticon-buscar"></i>
        <span>Buscar</span>
    </button>
</div>
