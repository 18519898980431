<div class="modal-header border-0 dv-table-header-1 bg-primary d-flex"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white" >
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Búsqueda avanzada
    </h2>
    <button type="button" (click)="close()" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h5"></i>
    </button>
</div>
<div class="mat-dialog-content-custom" style="min-height:450px;">
    <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Código de Servidor Público</label>
                <div>
                    <input [(ngModel)]="formData.employeeNumber" type="text" class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Servidor publico</label>
                <div>
                    <input [(ngModel)]="formData.employeeName" type="text" class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Unidad organizativa</label>
                <div>
                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccione'"
                        [settings]="dropdownSettings"
                        [data]="departmentList"
                        [(ngModel)]="formData.departments">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Cargo</label>
                <div>
                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccione'"
                        [settings]="dropdownSettings" [(ngModel)]="formData.positions"
                        [data]="positionList">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Clasificación</label>
                <div>
                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccione'"
                        [settings]="dropdownSettings"  [(ngModel)]="formData.classifications"
                        [data]="classificationList">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Tipo</label>
                <div>
                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccione'"
                        [settings]="dropdownSettings" [(ngModel)]="formData.types"
                        [data]="typeList">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Tipo de Servidor Publico</label>
                <div>
                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccione'"
                        [settings]="dropdownSettings" [(ngModel)]="formData.employeeTypes"
                        [data]="employeeTypeList">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Fecha Efectividad</label>
                <div>
                    <input [(ngModel)]="formData.efectiveDate" type="date" class="form-control form-control-lg" />
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mat-dialog-actions"[align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="close()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary" type="button"
        (click)="doSearch()">
        <i class="flaticon-buscar"></i>
        <span>Buscar</span>
    </button>
</div>