import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import { IDashboardOptions } from './vacancies-list-dashboard.service';
enum types{
  total=0,
  department=1,
  contractType=2,
  last3Year=3,
  gender = 4
}
@Injectable({
  providedIn: 'root'
})
export class PersonnelActionsDashboardService {

  private readonly api = environment.api_url
  constructor(
    private http: HttpClient, private authInfo: AuthInfoService
  ) { }

  getTotal(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    return this.http.get(this.getEndpoint(options, types.total))
  }
  getTotalByDepartment(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    return this.http.get(this.getEndpoint(options, types.department))
  }
  getTotalByContractType(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    return this.http.get(this.getEndpoint(options, types.contractType))
  }
  getTotalIn3Years(companyId = this.authInfo.getCompanyId()){
    return this.http.get(`${this.api}/Employees/Dashboard?Type=3&CompanyId=${companyId}&Status=true`)
  }
  getTotalByGender(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    return this.http.get(this.getEndpoint(options, types.gender))
  }

  private getEndpoint(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }, type):string{
    let dateFrom:any = options.dateFrom
    let dateTo = ''
    if(options.dateTo == null){
      dateTo = '3000-12-31'
    }else{
      dateTo = formatDate(options.dateTo, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom != null){
      dateFrom = formatDate(options.dateFrom, 'yyyy-MM-dd', 'en')
      return `${this.api}/Employees/Dashboard?Type=${type}&DateTo=${dateTo}&DateFrom=${dateFrom}&CompanyId=${options.companyId}&Status=true`
    }else{
      return `${this.api}/Employees/Dashboard?Type=${type}&DateTo=${dateTo}&CompanyId=${options.companyId}&Status=true`
    }
  }

}
