import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { postContent } from 'src/app/models/content';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ContentManagementService } from 'src/app/services/content-management.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { AddContentModalComponent } from './add-content-modal/add-content-modal.component';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';
declare function applySort(): any;

@Component({
  selector: 'content-management-list',
  templateUrl: './content-management-list.component.html',
  styleUrls: ['./content-management-list.component.css']
})
export class ContentManagementListComponent implements OnInit {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'CONTENT-MANAGEMENT',
    addAction: { key: 'Add', value: false },
    editAction: { key: 'Edit', value: false },
    seeAction: { key: 'View', value: false },
    postAction: { key: 'Post', value: false },
    deleteAction: { key: 'Inactivate', value: false },
    exportAction: { key: 'Export', value: false },
    searchAction: { key: 'SEARCH', value: false },
  }

  subscription: Subscription = new Subscription();
  sweetAlrt = new SweetAlert
  postContent: postContent
  isLoading: boolean = true;

  originalContentList = []
  paginatedContentList = []
  contentList: any[] = []

  filterText: string = '';
  postulationId: number;
  selectedItems: any[] = [];
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propKeyId: number, propkey?: string, displayName: string }[] = [
    { propKeyId: 1, propkey: "newsId", displayName: "Secuencia" },
    { propKeyId: 5, propkey: "postTitle", displayName: "Título" },
    { propKeyId: 4, propkey: "postTypeDescription", displayName: "Tipo de Publicación" },
    { propKeyId: 2, propkey: "publicationClassification", displayName: "Clasificación" },
    { propKeyId: 3, propkey: "directed", displayName: "Dirigido a" }
  ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 0, item_text: 'Inactivos' },
    { item_id: 2, item_text: 'Publicada' },
    { item_id: 3, item_text: 'No Publicada' },
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = this.filterTable(event.records)
  }

  searchPropKeySelected!: { propKeyId?: number, propkey?: string, displayName: string };
  selectedPage: any

  constructor(
    private _exlService: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private _contenManagementService: ContentManagementService,
    private dialog: MatDialog,
    public authInfo: AuthInfoService,
    private _toastService: ToastService,
  ) {
  }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.getData()
      }
    });
    applySort();
  }

  getData() {
    this.getContentList()
  }

  getContentList() {
    this._contenManagementService.getContent().subscribe({
      next: (res: any) => {
        this.originalContentList = res.dataList
        this.contentList = this.filterTable(this.originalContentList)
      },
      error: (err: any) => {
      }
    })
  }

  deleteContent(contentId: number) {
    this.sweetAlrt.AlertConfirm("Inactivar registro", "¿Está seguro de inactivar este registro?", "question").then(res => {
      if (res) {
        this._contenManagementService.deleteContent(contentId).subscribe(res => {
          this.sweetAlrt.Default("Inactivado", "Registro inactivado satisfactoriamente", "success")
          this.getContentList()
        },
          err => this._toastService.error(err))
      } else {

      }
    })
  }

  publishContent(contentId: number) {
    this.postContent = {
      newsId: contentId,
      userId: this.authInfo.getUserId()
    }
    this.sweetAlrt.AlertConfirm("Publicar Contenido", "¿Está seguro que desea publicar este registro?", "question").then(res => {
      if (res) {
        this._contenManagementService.publishContent(this.postContent).subscribe(res => {
          this.sweetAlrt.Default("Publicado", "Contenido publicado satisfactoriamente", "success")
          this.getContentList()
        },
          err => this._toastService.error(err))
      } else {

      }
    })
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.contentList = this.filterTable(this.originalContentList)
    }
    this.setFilterToLocalStorage();
  }
  setFilterToLocalStorage() {
    localStorage.setItem('budget-areas_filter', JSON.stringify(this.selectedItems));
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl?.find(x => x.item_id == 1) && !sl?.find(x => x.item_id == 0)) {
      list = list.filter(x => x.status == true);
    }
    if (sl?.find(x => x.item_id == 0) && !sl?.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    if (sl?.find(x => x.item_id == 2) && !sl?.find(x => x.item_id == 3)) {
      list = list.filter(x => x.conditionId == 2);
    }
    if (sl?.find(x => x.item_id == 3) && !sl?.find(x => x.item_id == 2)) {
      list = list.filter(x => x.conditionId == 1);
    }
    return list;
  }



  getPaginatedRecords(event) {
    this.paginatedContentList = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.selectedPage = event.selectedPage
  }




  onKeyChange(text) {
    this.filterText = text;
  }

  openModalAddContent(item: any, IsView = false, isEdit = false) {
    this.dialog.open(AddContentModalComponent, {
      width: '60%',
      data: {
        item: item,
        IsView: IsView,
        isEdit: isEdit
      },
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          this.getContentList()
        }
      }
    })

  }

  exportToExcel() {
    let data = []
    if (this.filteredList.enable) {
      data = this.filteredList.records
    } else {
      data = this.contentList
    }
    data.forEach(element => {
      element.statusText = element.status ? 'Activo' : 'Inactivo'
      element.postDate = element.date
      element.allowCommentText = element.allowComment ? 'Sí' : 'No'
      element.allowSharedText = element.allowShared ? 'Sí' : 'No'
    });
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Título",
      "Fecha Entrada",
      "Fecha Publicación",
      "Tipo de ",
      "Clasificación",
      "Dirigido a",
      "Condición",
      "¿Permite Comentar?",
      "¿Permite Compartir?",
      "Estatus",
    ], [
      "newsId",
      "postTitle",
      "date",
      "postDate",
      "postTypeDescription",
      "publicationClassification",
      "directed",
      "condition",
      "allowCommentText",
      "allowSharedText",
      "statusText"
    ]]
    this._exlService.exportToExcelSpecificColumns(data, excelHeaders, 'Lista de contenido', true);
  }

  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

  onKeyParameterChange(value: { propKeyId?: number, propkey: string, displayName: string } | null) {
    this.searchPropKeySelected = value ?? null;
  }

  exportToPdf() {
    const reportCode: ReportCode = ReportCode.Content_Management;
    const companyId: number = this.authInfo.getCompanyId();
    const actives = this.selectedItems.find((x) => x.item_id === 1);
    const inactives = this.selectedItems.find((x) => x.item_id === 0);
    let status = 0;
    if ((!actives && !inactives) || (actives && inactives)) {
      status = -1;
    } else if (actives && !inactives) {
      status = 1;
    }
    const conditions = this.selectedItems
      ?.filter((x) => x.item_id !== 1 && x.item_id !== 0)
      ?.map((e) => e.item_id)
      ?.join("-");
    const pageSize = this.paginatedContentList?.length
    const propKeyId = this.searchPropKeySelected?.propKeyId ?? 0
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&PropkeyId=${propKeyId}&SearchParameter=${this.filterText ?? ''}&Condition=${conditions}&Status=${status}&Page=${this.selectedPage}&PageSize=${pageSize}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Códigos Certificación',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }


}
