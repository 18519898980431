import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { PersonModel } from '../../personal-requisition/models/person.model';
import { VacanciesService } from '../../../services/vacancies.service';
import { ToastService } from '../../../shared/toast/toast.service';
import { docService } from 'src/app/services/doc.service';
import { ApplyVacancyModel } from '../../personal-requisition/models/apply-vacancy-model';
import { CurrencyPipe, DatePipe, formatDate } from '@angular/common';
import { SearchEmployeeComponent } from './search-employee/search-employee.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewsListComponent } from '@payroll/news-list/news-list.component';
import Uppy from '@uppy/core'
import Mexico from '@uppy/locales/lib/es_MX'
import XHRUpload from '@uppy/xhr-upload'
import Dashboard from '@uppy/dashboard'
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { environment } from 'src/environments/environment';

import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { DomSanitizer } from '@angular/platform-browser';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { Beneficiary, ConceptSetting, DefaultValue, NewsConcept, NewsType, PayrollNew } from '@payroll/news-list/models/PayrollNews.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import * as moment from 'moment';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';
enum identificationType {
  identificationCard = 1
}
enum paymentMethods {
  check = 2
}
type GUID = string & { isGuid: true };
@Component({
  selector: 'action-edit',
  templateUrl: './action-edit.component.html',
  styleUrls: ['./action-edit.component.css']
})
export class ActionEditComponent implements OnInit, AfterViewChecked {
  checkIsSelected: boolean = undefined;
  titleModal = "Agregar Novedad";
  formIsInvalid = false

  PersonSelected = new PersonModel();
  datePie = new DatePipe('en-US')

  employeeSelected: any;

  IsViewMode = false;
  IsEditMode = false;

  guiID: string = '';
  personalFile = personalFile;

  //Form
  form: FormGroup;

  newtyTypes: NewsType[] = [];
  concepts: NewsConcept[] = [];
  conceptsListOriginal: NewsConcept[] = [];

  typeDocuments: any[] = [];
  banks: any[] = [];
  accountTypes: any[] = [];
  paymentMethods: any[] = [];

  isLitisSelected: boolean = false;
  isAdditionalSelected: Boolean = false;
  isExternalSelected: boolean = false;
  isFiscalCreditSelected: boolean = false;

  datePipe = new DatePipe('en-Us')

  public mask = "000-0000000-0"
  public maskText = "Ej: 001-000000-2"

  defaultValues: DefaultValue[] = [];
  defaultValuesOriginalList: DefaultValue[] = [];

  conceptSetting: ConceptSetting;
  

  @ViewChild('defaultValue') defaultValue: ElementRef;
  @ViewChild('manualValue') manualValue: ElementRef;

  imgDefaultPdf: string = 'assets/images/cv.jpg';

  @ViewChild('uppyFile')
  uppyFile: UppyFileComponent | null = null;
  constructor(
    private _toastService: ToastService,
    private srvVacancy: VacanciesService,
    private srvDoc: docService,
    public dialogRef: MatDialogRef<ActionEditComponent>,
    private _changeDet: ChangeDetectorRef, private dialog: MatDialog,
    private parameterControlService: ParameterControlService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private payrollNewService: PayrollNewService,  
    private authInfo: AuthInfoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.buldForm();
    this.dialogRef.disableClose = true;
    this.IsViewMode = this.data.IsView;
    this.IsEditMode = this.data.isEdit;
    if (this.data.item) {
      this.guiID = this.data?.item?.evidence;
      this.getNewsConceptsSetting(this.data?.item?.newsConceptId);
      this.getPersonByIdEmployee(this.data.item.employeeId, this.data.item.companyId);
      if (this.IsViewMode) {
        this.titleModal = "Detalle de la Novedad";
        this.form.disable();
      }
      if(this.IsEditMode) {
        this.titleModal = "Editar Novedad";
      }
    }
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  ngOnInit(): void {
    this.getNewsType();
    this.getConcepts();
    this.getTypeDocuments();
    this.getBanks();
    this.getAccountTypes();
    this.getPaymentMethods();
  }

  getNewTypeById(id) {
    return this.newtyTypes.find(n => n.newsTypeId === id);
  }

  getConcetById(id) {
    return this.conceptsListOriginal.find(n => n.newsConceptId === id);
  }

  getTypeDocumentById(id) {
    return this.typeDocuments.find(n => n.ocode === id);
  }

  getPaymentMethodById(id) {
    return this.paymentMethods.find(n => n.ocode === id);
  }

  getAccountTypeById(id) {
    return this.accountTypes.find(n => n.ocode === id);
  }

  getBankById(id) {
    return this.banks.find(n => n.ocode === id);
  }

  private resetConcetsSetting(){
    this.showManuelValue();
    this.showDefaultValue();
    this.defaultValues = [];
    this.defaultValuesOriginalList = [];
    this.conceptSetting = null;
    this.form.get('defaultValueSeleted').setValue(null);
    this.form.get('manualValue').setValue(null);
  }

  getNewsConceptsSetting(ConceptId: number){
    if(ConceptId === 0){
      this.resetConcetsSetting();
      return;
    }
    this.payrollNewService.getNewsConceptsSetting(ConceptId).subscribe((res: any) => {
      if(res.errors.length > 0){
        this._toastService.error(res.errors[0]);
        return;
      }
      if(res.dataList.length === 0){
        this.resetConcetsSetting();
        return;
      }
      this.conceptSetting = res.dataList[0] as ConceptSetting;
      if(this.conceptSetting?.requiredValue){
        this.showDefaultValue();
        this.getDefaultValuesByConceptValuesId(ConceptId);
      }else{
        this.hideDefaultValue();
      }
      if(this.conceptSetting?.manualValue){
        this.showManuelValue();
      }else{
        this.hideManuelValue();
      }
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  getDefaultValuesByConceptValuesId(id){
    this.payrollNewService.getDefaultValues(id).subscribe((res: any) => {
      if(res.errors.length > 0){
        this._toastService.error(res.errors[0]);
        return;
      }
      this.defaultValues = res?.dataList?.filter((d: { status: boolean; }) => d?.status)
      this.defaultValuesOriginalList = res?.dataList?.filter((d: { status: boolean; }) => d?.status)
      if(this.data?.item){
        const defaultValue = {...this.defaultValuesOriginalList.find(d => d.conceptValuesId === this.data?.item?.defaultValue)};
        this.form.get('defaultValueSeleted').setValue(defaultValue?.conceptValuesId ? defaultValue : null);
      }
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    });
  }

  checkNewType(value) {
    const type = value?.newsTypeId as number;
    if (type == 4) {
      this.hideFieldByFiscalCredit();
      return;
    }
    if (type == 3) {
      this.hideFieldByLitis();
      return;
    }
    if (type== 2) {
      this.hideFieldByAdditional();
      return;
    }
    if (type == 1) {
      this.hideFieldByExternal();
      return;
    }
  }

  buldForm() {
    this.form = this.fb.group({
      newsAppliedId: [0],
      positionId: [0],
      payrollNewsId: [0],
      actionsEmployeeId: [0],
      beneficiaryId: [0],
      sourceId: [0],
      countApproves: [0],
      isPorcent: [true],
      status: [true],
      companyId: [0],
      createUserId: [0],
      modifyUserId: [0],
      newsTypeId: [''],
      newsConceptId: [''],
      condition: [0],
      defaultValue: [0],
      employeeId: ['', [Validators.required]],
      newType: [null, [Validators.required]],
      concept: [null, [Validators.required]],
      amount: [0, [Validators.required]],
      totalAmount: [0],
      balance: [0],
      manualValue: [0, [Validators.required]],
      defaultValueSeleted: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null],
      applicationDate: [null],
      observation: [''],
      evidence: [''],
      beneficiary: this.fb.group({
        beneficiaryId: [0],
        employeeId: [0],
        identificationTypeId: [0],
        paymentMethodId: [0],
        bankId: [0],
        accountTypeId: [0],
        status: [true],
        companyId: [0],
        createDate: [null],
        createUserId: [0],
        modifyDate: [null],
        modifyUserId: [0],
        identificationNumber: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        typeDocument: ['', [Validators.required]],
        paymentMethod: ['', [Validators.required]],
        bank: ['', [Validators.required]],
        accountType: [''],
        accountNumber: [''],
        office: [''],
      })
    });
  }

  setData() {
    if (this.data?.item !== null) {
      if (this.data.IsView) {
        this.IsViewMode = true;
        this.form.patchValue({ ...this.data?.item, 
          balance: this.data?.item.balance || 0,
          totalAmount: this.data?.item.totalAmount || 0,
          manualValue: this.data?.item.manualValue || 0,
          defaultValue: this.data?.item?.defaultValue || 0,
          amount: this.data?.item.amount || 0,
        });
        this.form.get('startDate').setValue(formatDate(this.data?.item?.startDate, 'yyyy-MM-dd', 'en'));
        this.form.get('endDate').setValue(this.data?.item?.endDate ? formatDate(this.data?.item?.endDate, 'yyyy-MM-dd', 'en') : null);
        this.form.get('applicationDate').setValue(this.data?.item?.applicationDate ? formatDate(this.data?.item?.applicationDate, 'yyyy-MM-dd', 'en') : null);
        this.checkNewType(this.form.get('newType').value);
        this.form.disable();
        if (this.data?.item?.beneficiaryId >0 ) {
          this.getBeneficiaryById();
        }
      } else {
        this.IsEditMode = true;
        this.form.patchValue({ ...this.data?.item, 
          balance: this.data?.item.balance || 0,
          totalAmount: this.data?.item.totalAmount || 0,
          manualValue: this.data?.item.manualValue || 0,
          defaultValue: this.data?.item?.defaultValue || 0,
          amount: this.data?.item.amount || 0,
        });
        this.form.get('startDate').setValue(formatDate(this.data?.item?.startDate, 'yyyy-MM-dd', 'en'));
        this.form.get('endDate').setValue(this.data?.item?.endDate ? formatDate(this.data?.item?.endDate, 'yyyy-MM-dd', 'en') : null);
        this.form.get('applicationDate').setValue(this.data?.item?.applicationDate ? formatDate(this.data?.item?.applicationDate, 'yyyy-MM-dd', 'en') : null);
        this.checkNewType(this.form.get('newType')?.value);
        if (this?.data?.item?.beneficiaryId >0 ) {
          this.getBeneficiaryById();
        }
      }
    }
  }


  getBeneficiaryById() {
    this.payrollNewService.getBeneficiaryById(this.data.item.beneficiaryId, this.data.item.companyId).subscribe((res: any) => {
      const data = res.dataList[0];
      this.form.get('beneficiary').patchValue({
        ...data,
        typeDocument: this.getTypeDocumentById(data.identificationTypeId),
        paymentMethod: this.getPaymentMethodById(data.paymentMethodId),
        bank: this.getBankById(data.bankId),
        accountType: this.getAccountTypeById(data.accountTypeId)
      });
      this.setCheckIsSelected()
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }


  getNewsType() {
    this.payrollNewService.getNewsType().subscribe((res: any) => {
      if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al obtener los tipos de novedades"); return; }
      this.newtyTypes = res.dataList.filter(e => e.status);
      this.form.get('newType').setValue(this.getNewTypeById(this.data?.item?.newsTypeId));
      this.setData();
    }, err => this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk."))
  }

  getConcepts() {
    this.payrollNewService.getNewsConcept().subscribe((res: any) => {
      if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al obtener los conceptos"); return; }
      this.conceptsListOriginal = res.dataList.filter(e => e.status);
      if(this.data?.item){
        this.concepts = this.conceptsListOriginal.filter(c => c.newsTypeId === this.data?.item?.newsTypeId);
      }
      this.form.get('concept').setValue(this.getConcetById(this.data?.item?.newsConceptId));
    }, err => this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk."))
  }

  getTypeDocuments() {
    this.parameterControlService.getParameters(keyWord.IDType).subscribe(e => {
      if (e.dataList.length > 0) {
        this.typeDocuments = e.dataList;
      }
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  getBanks() {
    this.parameterControlService.getParameters(keyWord.BankingEntity).subscribe(e => {
      if (e.dataList.length > 0) {
        this.banks = e.dataList;
      }
    })
  }

  getAccountTypes() {
    this.parameterControlService.getParameters(keyWord.AccountType).subscribe(e => {
      if (e.dataList.length > 0) {
        this.accountTypes = e.dataList;
      }
    })
  }

  getPaymentMethods() {
    this.parameterControlService.getParameters(keyWord.PaymentForm).subscribe(e => {
      if (e.dataList.length > 0) {
        this.paymentMethods = e.dataList;
      }
    })
  }

  get isNewTypeLitisSeleted() {
    const isLitis = this.form.get('newType').value?.description === 'Litis';
    return isLitis;
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }
  changePaymentMethod() {
    this.setCheckIsSelected()
  }
  setCheckIsSelected() {
    const field = this.form.get('beneficiary').value.paymentMethod.ocode
    this.checkIsSelected = field == paymentMethods.check
  }

  getPersonByIdEmployee(id, companyId) {
    this.payrollNewService.getPersonByIdEmployee(id, companyId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error("Error al obtener los datos del Servidor Público")
        return;
      }
      if(res.dataList.length > 0){
        this.PersonSelected = res.dataList[0];
        this.getPersonPhotoloaded(res.dataList[0]?.profileImage);
        this.form.get('employeeId').setValue(res.dataList[0]?.employeeId);
        this.form.get('beneficiary').patchValue({
          employeeId: res.dataList[0]?.employeeId
        });
      }else{
        this._toastService.warning('No se encontraron datos de Servidor Público');
      }
  
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  getPersonPhotoloaded(guid: any) {
    this.srvDoc.getDocument(guid).subscribe(
      (e: any) => {
        this.PersonSelected.photoloaded = e.data;
      }
    )
  }


  Cancel(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

  Accept() {
    if (!this.employeeSelected && !this.IsEditMode) {
      this._toastService.warning('Por favor seleccione un Servidor Público', 'Servidor Público no selecionado');
      return;
    }

    if (this.form.get('endDate').value && this.form.get('endDate').value < this.form.get('startDate').value) {
      this._toastService.warning('La fecha final no debe ser menor que la fecha inicio');
      return;
    }
 
    if (this.isNewTypeLitisSeleted) {

      if (this.checkIsSelected == false) {
        const office = this.form.get('beneficiary.office').value ?? ''
        const accountNumber = this.form.get('beneficiary.accountNumber').value ?? ''
        const accountType = this.form.get('beneficiary.accountType').value
        /* 
        Esto es para validar estos 3 campos, que sean requerido cuando la 'forma de cobro' sea cheque, 
        y que no sean requeridos cuando no sea cheque 
        */
        if (office.length < 1 || accountNumber.length < 1 || !accountType) {
          this.formIsInvalid = true
          return
        } else {
          this.formIsInvalid = false
        }
      }
    }

    if (this.form.invalid) {
      this.formIsInvalid = true;
      this._toastService.warning('Debe llenar los campos obligatorios', 'Formulario invalido');
      return;
    }

    this.formIsInvalid = false;
    const dataForm = { ...this.form.value };
    delete dataForm.concept
    delete dataForm.newType

    this.uppyFile.handleFile(guid => {
        const evidence = guid;
        let payrollNew: PayrollNew = {
          ...dataForm,
          startDate: this.datePipe.transform(this.form.value.startDate, 'yyyy-MM-dd'),
          endDate: this.datePipe.transform(this.form.value.endDate, 'yyyy-MM-dd'),
          applicationDate: this.IsEditMode ? this.datePipe.transform(this.form.value.applicationDate, 'yyyy-MM-dd') : null,
          createDate: this.IsEditMode ? this.datePipe.transform(this.data.item.createDate, 'yyyy-MM-dd') : this.datePipe.transform(Date.now(), 'yyyy-MM-dd'),
          positionId: this.IsEditMode ? this.form.value.positionId : this.employeeSelected.positionId,
          companyId: this.IsEditMode ? this.form.value.companyId : this.employeeSelected.companyId,
          evidence: evidence !== '' ? evidence : null,
          manualValue: dataForm?.manualValue || 0,
          balance: dataForm?.balance || 0,
          totalAmount: dataForm?.totalAmount || 0,
          amount: dataForm?.amount || 0,
          createUserId:  this.IsEditMode ? this.data.item.createUserId :this.authInfo.getUserId(),
          modifyUserId: this.IsEditMode ? this.authInfo.getUserId() : null,
          modifyDate: moment().format()
        };
    
        if(payrollNew.newsTypeId == 4){
          payrollNew.balance = payrollNew.totalAmount;
        }
    
        if (this.IsEditMode) {
          this.updatePayRollNew(payrollNew);
        } else {
          this.postPayrollNew(payrollNew);
        }
    });
  }

  private updatePayRollNew(payrollNew: PayrollNew) {
    if (this.isLitisSelected) {
      let beneficiary: Beneficiary = {
        ...this.form.value.beneficiary,
        identificationTypeId: this.form?.value?.beneficiary?.typeDocument?.ocode || 0,
        paymentMethodId: this.form?.value?.beneficiary?.paymentMethod?.ocode || 0,
        bankId: this.form?.value?.beneficiary?.bank?.ocode || 0,
        accountTypeId: this.form?.value?.beneficiary?.accountType?.ocode || 0,
      };
      if(beneficiary?.beneficiaryId > 0){
        this.updateBeneficiary(payrollNew, beneficiary)
      }else{
       beneficiary.beneficiaryId = 0;
       beneficiary.employeeId = payrollNew?.employeeId;
       beneficiary.companyId = payrollNew?.companyId;
       beneficiary.accountTypeId = beneficiary?.accountTypeId || 0;
       beneficiary.createDate = new Date();
       beneficiary.createUserId = this.authInfo.getUserId();
       beneficiary.status = true;
       this.postBeneficiary(payrollNew, beneficiary, () => {
        this.changePayrollNew(payrollNew);
       });
      }

    } else {
      this.changePayrollNew(payrollNew);
    }
  }

  postBeneficiary(payrollNew, beneficiary, collBackPayrollNew: () => void){
    this.payrollNewService.postBeneficiary(beneficiary).subscribe(res => {
      if (res.errors.length > 0) { this._toastService.error(res.errors[0]); return; }
      payrollNew.beneficiaryId = res.identity;
      collBackPayrollNew();
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  updateBeneficiary(payrollNew, beneficiary){
    this.payrollNewService.updateBeneficiary(beneficiary).subscribe(res => {
      if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al editar el beneficiario"); return; }
      this.changePayrollNew(payrollNew);
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  changePayrollNew(payrollNew: PayrollNew) {
    this.payrollNewService.updatePayrollNew(payrollNew).subscribe(res => {
      if(res?.warnings?.length > 0){
        this._toastService.warning(res?.warnings[0], '');
        return;
      }
      if (res.errors.length > 0) { this._toastService.error(res.errors[0]); return; }
      this._toastService.success("La novedad se editó con éxito");
      const row = { Applied: false, newItem: true }
      this.dialogRef.close(row);
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  private postPayrollNew(payrollNew: PayrollNew) {
    if (this.isLitisSelected) {
      let beneficiary: Beneficiary = {
        ...this.form.value.beneficiary,
        identificationTypeId: this.form?.value?.beneficiary?.typeDocument?.ocode || 0,
        paymentMethodId: this.form?.value?.beneficiary?.paymentMethod?.ocode || 0,
        bankId: this.form?.value?.beneficiary?.bank?.ocode || 0,
        accountTypeId: this.form?.value?.beneficiary?.accountType?.ocode || 0,
        companyId: this.PersonSelected.companyId,
        createUserId: +this.authInfo.getUser()?.userId,
        createDate: this.datePipe.transform(Date.now(), 'yyyy-MM-dd'),
      };
      this.postBeneficiary(payrollNew, beneficiary, () => {
        this.newPayrollNew(payrollNew);
      });
    } else {
      this.newPayrollNew(payrollNew);
    }

  }

  newPayrollNew(payrollNew: PayrollNew) {
    this.payrollNewService.postPayrollNew(payrollNew).subscribe(res => {
      if(res?.warnings?.length > 0){
        this._toastService.warning(res?.warnings[0], '');
        return;
      }
      if (res.errors.length > 0) { this._toastService.error(res.errors[0], ''); return; }
      this._toastService.success("La novedad se ha guardado con éxito");
      const row = { Applied: false, newItem: true }
      this.dialogRef.close(row);
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }


  openModalSearchEmployee(item: any, IsView = false) {
    this.dialog.open(SearchEmployeeComponent, {
      data: {
        item: item,
        IsView: IsView,
        currentPage: 'ActionEdit'
      },
      width: '80%'
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.data !== undefined) {
          this.employeeSelected = { ...result.data };
          this.getPersonByIdEmployee(this.employeeSelected.employeeId, this.employeeSelected.companyId);
        }
      });
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onChangeNewType(value: NewsType) {
    this.form.get('concept').setValue(null);
    this.concepts = null;
    this.form.get('newsTypeId').setValue(value.newsTypeId);
    if (value?.newsTypeId === undefined) {
      this.showField();
      return;
    }
    const concepts = this.conceptsListOriginal.filter(c => c.newsTypeId === value.newsTypeId)
    this.concepts = [...concepts];
    
    if (value.newsTypeId == 4) {
      this.hideFieldByFiscalCredit();
      this.form.get('balance')?.setValue(this.form.get('totalAmount')?.value);
      if(this.IsViewMode || this.IsEditMode){
        this.form.get('balance').disable();
      }else{
        this.form.get('balance').enable();
      }
      return;
    }

    if (value.newsTypeId == 3) {
      this.hideFieldByLitis();
      return;
    }
    if (value.newsTypeId == 2) {
      this.hideFieldByAdditional();
      return;
    }
    if (value.newsTypeId == 1) {
      this.hideFieldByExternal();
      return;
    }

  }

  onChangeTotalAmount(){
    let newsTypeId = this.form.get('newsTypeId')?.value;

    if(newsTypeId){
      if(newsTypeId == 4 && (!this.IsViewMode && !this.IsEditMode)){
        this.form.get('balance')?.setValue(this.form.get('totalAmount')?.value);
      }
    }

  }

  hideFieldByLitis() {
    this.isLitisSelected = true;
    this.isAdditionalSelected = false;
    this.isExternalSelected = false;
    this.isFiscalCreditSelected = false;
    //show
    this.form.get('beneficiary').enable();
    this.form.get('amount').enable();
    this.form.get('amount').setValidators(Validators.required);
    this.form.get('amount').updateValueAndValidity();
    //hide
    this.form.get('defaultValueSeleted').setValidators(null);
    this.form.get('defaultValueSeleted').updateValueAndValidity();
    this.form.get('manualValue').setValidators(null);
    this.form.get('manualValue').updateValueAndValidity();
    this.form.get('totalAmount').disable();
    this.form.get('totalAmount').setValidators(null);
    this.form.get('totalAmount').updateValueAndValidity();
    this.form.get('balance').disable();
    this.form.get('manualValue').disable();
    this.form.get('defaultValueSeleted').disable();
    this.form.get('applicationDate').disable();
    this.form.get('totalAmount').setValue(0);
    this.form.get('balance').setValue(0);
    this.form.get('manualValue').setValue(null);
    this.form.get('defaultValueSeleted').setValue(null);
    this.form.get('applicationDate').setValue(null);
  }

  hideFieldByAdditional() {
    this.isLitisSelected = false;
    this.isAdditionalSelected = true;
    this.isExternalSelected = false;
    this.isFiscalCreditSelected = false;
    //Hide
    this.form.get('amount').setValue(null);
    this.form.get('totalAmount').setValue(0);
    this.form.get('balance').setValue(null);
    this.form.get('amount').disable();
    this.form.get('amount').setValidators(null);
    this.form.get('amount').updateValueAndValidity();
    this.form.get('totalAmount').disable();
    this.form.get('totalAmount').setValidators(null);
    this.form.get('totalAmount').updateValueAndValidity();
    this.form.get('beneficiary').disable();
    this.form.get('balance').disable();
    //Show
    if(!this.conceptSetting?.newsConceptSettingId){
      this.form.get('manualValue').enable();
      this.form.get('defaultValueSeleted').enable();
      this.form.get('defaultValueSeleted').setValidators([Validators.required]);
      this.form.get('defaultValueSeleted').updateValueAndValidity();
      this.form.get('manualValue').setValidators([Validators.required]);
      this.form.get('manualValue').updateValueAndValidity();
    }
 
    this.form.get('applicationDate').enable();
    this.form.get('beneficiary').reset();
  }

  hideFieldByExternal() {
    this.isLitisSelected = false;
    this.isAdditionalSelected = false;
    this.isFiscalCreditSelected = false;
    this.isExternalSelected = true;
    this.form.get('manualValue').setValue(null);
    this.form.get('applicationDate').setValue(null);
    //hide
    this.form.get('manualValue').disable();

    this.form.get('manualValue').setValidators(null);
    this.form.get('manualValue').updateValueAndValidity();
    this.form.get('defaultValueSeleted').setValidators(null);
    this.form.get('defaultValueSeleted').updateValueAndValidity();
    this.form.get('defaultValueSeleted').disable()
    this.form.get('applicationDate').disable();
    this.form.get('beneficiary').disable();
    this.form.get('defaultValueSeleted').setValue(null);
    //show
    this.form.get('amount').enable();
    this.form.get('amount').setValidators(Validators.required);
    this.form.get('amount').updateValueAndValidity();
    this.form.get('totalAmount').enable();
    this.form.get('totalAmount').setValidators(null);
    this.form.get('totalAmount').updateValueAndValidity();
    this.form.get('balance').enable();
    this.form.get('beneficiary').reset();
  }

  hideFieldByFiscalCredit() {
    this.isLitisSelected = false;
    this.isAdditionalSelected = false;
    this.isExternalSelected = false;
    this.isFiscalCreditSelected = true;
    this.form.get('manualValue').setValue(null);
    this.form.get('applicationDate').setValue(null);
    //hide
    this.form.get('manualValue').disable();

    this.form.get('manualValue').setValidators(null);
    this.form.get('manualValue').updateValueAndValidity();
    this.form.get('defaultValueSeleted').setValidators(null);
    this.form.get('defaultValueSeleted').updateValueAndValidity();
    this.form.get('defaultValueSeleted').disable()
    this.form.get('applicationDate').disable();
    this.form.get('beneficiary').disable();
    this.form.get('amount').disable();
    this.form.get('amount').setValidators(null);
    this.form.get('amount').updateValueAndValidity();
    this.form.get('amount').setValue(null);
    this.form.get('defaultValueSeleted').setValue(null);
    this.form.get('balance').disable();
    //show
    
    this.form.get('totalAmount').enable();
    this.form.get('totalAmount').setValidators(Validators.required);
    this.form.get('totalAmount').updateValueAndValidity();
    this.form.get('beneficiary').reset();
  }



  hideManuelValue(){
    if(this.manualValue?.nativeElement){
      this.manualValue.nativeElement.style.display='none';
    }
    this.form.get('manualValue').setValue(null);
    this.form.get('manualValue').disable();
    this.form.get('manualValue').setValidators(null);
    this.form.get('manualValue').updateValueAndValidity();
  }

  showManuelValue(){
    if(this.manualValue?.nativeElement){
      this.manualValue.nativeElement.style.display='block';
    }
    
    this.form.get('manualValue').enable();
    if (this.IsViewMode) {
      this.form.get('manualValue').setValue(null);
      this.form.get('manualValue').disable();
    }
  }

  hideDefaultValue(){
    if(this.defaultValue?.nativeElement){
      this.defaultValue.nativeElement.style.display='none';
    }
  
    this.form.get('defaultValueSeleted').setValue(null);
    this.form.get('defaultValueSeleted').disable();
  }

  showDefaultValue(){
    if(this.defaultValue?.nativeElement){
      this.defaultValue.nativeElement.style.display='block';
    }
    this.form.get('defaultValueSeleted').enable();
    if (this.IsViewMode) {
      this.form.get('defaultValueSeleted').setValue(null);
      this.form.get('defaultValueSeleted').disable();
    }
  }

  showField() {
    this.isLitisSelected = false;
    this.isAdditionalSelected = false;
    this.isExternalSelected = false;
    this.form.get('amount').enable();
    this.form.get('balance').enable();
    this.form.get('totalAmount').enable();
    this.form.get('manualValue').enable();
    this.form.get('defaultValueSeleted').enable();
    this.form.get('applicationDate').enable();
    this.form.get('balance').enable();
  }

  onChangeConcept(value: NewsConcept) {
    const newType = this.form.get('newType')?.value;
    if (newType?.description?.toLocaleLowerCase() === 'adicional') {
      this.getNewsConceptsSetting(value?.newsConceptId || 0);
    }else{
      this.resetConcetsSetting();
    }
    this.form.get('newsConceptId').setValue(value.newsConceptId);
  }

  onChangeDefaultValue(value: DefaultValue){
    this.form.get('defaultValue').setValue(value?.conceptValuesId);
  }

  onChangeTypeDocument(e) {
    if (e.value.ocode == identificationType.identificationCard) {
      this.mask = "000-0000000-0"
      this.maskText = 'Ej: 001-000000-2'
    } else {
      this.mask = ''
      this.maskText = ''
    }
    this.form.patchValue({
      beneficiary: {
        identificationNumber: ''
      }
    })
  }

  get showErrors() {
    return {
      erorNewType: this.form.get('newType').invalid,
      errorConcept: this.form.get('concept').invalid,
      errorAmount: this.form.get('amount').invalid,
      errorManualValue: this.form.get('manualValue').invalid,
      erorDefaultValue: this.form.get('defaultValueSeleted').invalid,
      errorStartDate: this.form.get('startDate').invalid,
      errorApplicationDate: this.form.get('applicationDate').invalid,
      errorObservation: this.form.get('observation').invalid,
      errorEvidence: this.form.get('evidence').invalid,
      errorsBeneficiary: {
        errorIdentificationNumber: this.form.get('beneficiary.identificationNumber').invalid,
        errorFirstName: this.form.get('beneficiary.firstName').invalid,
        errorLastName: this.form.get('beneficiary.lastName').invalid,
        errorTypeDocument: this.form.get('beneficiary.typeDocument').invalid,
        errorBank: this.form.get('beneficiary.bank').invalid,
        errorAccountType: this.checkIsSelected == true ? false : this.form.get('beneficiary.accountType').value < 1,
        errorAccountNumber: this.checkIsSelected == true ? false : this.form.get('beneficiary.accountNumber').value < 1,
        errorOfice: this.checkIsSelected == true ? false : this.form.get('beneficiary.office').value < 1,
        errorPaymentMethod: this.form.get('beneficiary.paymentMethod').invalid,
      }
    }
  }


  setEvidence(guid: GUID){
    this.form.get('evidence').setValue(guid);
  }

}