import { Component, Input, OnChanges, OnDestroy, AfterViewInit, SimpleChanges } from '@angular/core';
import { Inject, NgZone, PLATFORM_ID } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { IChartData } from '../models/chartModels';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-chart-sorted-bar',
  templateUrl: './chart-sorted-bar.component.html',
  styleUrls: ['./chart-sorted-bar.component.css']
})
export class ChartSortedBarComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() id: string = 'chartsorted'
  @Input() categoryField = 'description'
  @Input() valueField = 'value'
  @Input() data: IChartData[]
  root: any;
  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) {}
  
  ngOnDestroy(): void {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
        document.getElementById(this.id).remove()
      }
    });
  }


  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.data){
      if(this.root){
        this.root.dispose()
        this.root = am5.Root.new(this.id)
        this.buildChart()
      }
    }
  }

  ngAfterViewInit() {
    this.browserOnly(() => {
      
      this.root = am5.Root.new(this.id);
      this.buildChart()
    })
  }
  
  buildChart(){
    if(!this.root) return  
      
    let root = this.root

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);
    
    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none"
      })
    );
    
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let yRenderer = am5xy.AxisRendererY.new(root, { minGridDistance: 30 });
    
    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: this.categoryField,
        renderer: yRenderer
      })
    );
    
    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        renderer: am5xy.AxisRendererX.new(root, {})
      })
    );
    
    
    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: this.valueField,
        sequencedInterpolation: true,
        categoryYField: this.categoryField
      })
    );
    
    let columnTemplate = series.columns.template;
    
    columnTemplate.setAll({
      draggable: true,
      cursorOverStyle: "pointer",
      tooltipText: "drag to rearrange",
      cornerRadiusBR: 10,
      cornerRadiusTR: 10
    });
    columnTemplate.adapters.add("fill", (fill, target:any) => {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });
    
    columnTemplate.adapters.add("stroke", (stroke, target:any) => {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });
    
    columnTemplate.events.on("dragstop", () => {
      sortCategoryAxis();
    });
    
    // Get series item by category
    function getSeriesItem(category) {
      for (var i = 0; i < series.dataItems.length; i++) {
        let dataItem = series.dataItems[i];
        if (dataItem.get("categoryY") == category) {
          return dataItem;
        }
      }
    }
    
    
    // Axis sorting
    function sortCategoryAxis() {
      // Sort by size
      series.dataItems.sort(function (x, y) {
        return y.get("graphics").y() - x.get("graphics").y();
      });
    
      let easing = am5.ease.out(am5.ease.cubic);
    
      // Go through each axis item
      am5.array.each(yAxis.dataItems, function (dataItem: any) {
        // get corresponding series item
        let seriesDataItem = getSeriesItem(dataItem.get("category"));
    
        if (seriesDataItem) {
          // get index of series data item
          let index = series.dataItems.indexOf(seriesDataItem);
    
          let column = seriesDataItem.get("graphics");
    
          // position after sorting
          let fy =
            yRenderer.positionToCoordinate(yAxis.indexToPosition(index)) -
            column.height() / 2;
    
          // set index to be the same as series data item index
          if (index != dataItem.get("index")) {
            dataItem.set("index", index);
    
            // current position
            let x = column.x();
            let y = column.y();
    
            column.set("dy", -(fy - y));
            column.set("dx", x);
    
            column.animate({ key: "dy", to: 0, duration: 600, easing: easing });
            column.animate({ key: "dx", to: 0, duration: 600, easing: easing });
          } else {
            column.animate({ key: "y", to: fy, duration: 600, easing: easing });
            column.animate({ key: "x", to: 0, duration: 600, easing: easing });
          }
        }
      });
    
      // Sort axis items by index.
      // This changes the order instantly, but as dx and dy is set and animated,
      // they keep in the same places and then animate to true positions.
      yAxis.dataItems.sort(function (x, y) {
        return x.get("index") - y.get("index");
      });
    }
    
    // Set data
    let data = this.data ? this.data : []
    
    yAxis.data.setAll(data);
    series.data.setAll(data);
    
    
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);
    
    
  }

  ngOnInit(): void {
  }

}
