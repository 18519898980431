import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { IncompletedFilesComponent } from './incompleted-files/incompleted-files.component';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { PagedResult } from 'src/app/shared/models/page-result';
import { RequestBankAccountCertificationBulkLoad } from 'src/app/models/requestBankAccountCertBulkLoad.model';
import { openReport, swalWarning } from 'src/app/shared/utils/utility';
import { EmployeesIncomplete, IEmployee } from 'src/app/models/Employee.model';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
declare function applySort(): any;

enum TypeModalEmployees {
  Employee = 1,
  Teacher,
}

@Component({
  selector: 'app-employees-incomplete',
  templateUrl: './employees-incomplete.component.html',
  styleUrls: ['./employees-incomplete.component.css']
})

export class EmployeesIncompleteComponent implements OnInit, AfterViewChecked {
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'EMPLOYEES',
    dashboardAction: { key: 'DASHB-E', value: false },
    exportAction: { key: 'EXPORT-E', value: false },
    editAction: { key: 'EDIT-E', value: false },
    viewAction: { key: 'VIEW-E', value: false },
    personnelAction: { key: 'ACTIONS-E', value: false },
    historyPersonalActions: { key: 'HISTORYPERSONALACTIONS', value: false }
  }

  filterText: string = '';
  @ViewChild('paginator') paginator: CaptionComponent;

  incompleteEmployees: EmployeesIncomplete[] = [];
  selectedItems: any[] = [];

  filteredList: { records: EmployeesIncomplete[], enable: boolean } = { records: [], enable: false };

  searchBarDisplayNames: { propkeyId: number, displayName: string, default?: boolean }[] =
    [
      { propkeyId: 1, displayName: "Secuencia" },
      { propkeyId: 2, displayName: "Servidor Público" },
      { propkeyId: 3, displayName: "Unidad organizativa" },
      { propkeyId: 4, displayName: "Teléfono Primario" },
      { propkeyId: 5, displayName: "Documento de Identidad", default: true },
    ];

  searchParameter;
  employeesPagedResut = new PagedResult<EmployeesIncomplete>();

  statusOptions = [
    { item_id: 1, item_text: 'Sin cuenta bancaria' },
    { item_id: 2, item_text: 'Sin tipo de identificación' },
    { item_id: 3, item_text: 'Sin Nivel Académico' },
    { item_id: 4, item_text: 'Sin Dirección' },
    { item_id: 5, item_text: 'Sin AFP' },
    { item_id: 6, item_text: 'Sin ARS' },
    { item_id: 7, item_text: 'Sin foto de la cédula' },
    { item_id: 8, item_text: 'Sin teléfono celular' },
    { item_id: 10, item_text: 'Sin currículum vitae' },
    { item_id: 11, item_text: 'Sin certificado no antecedentes penales' },
    { item_id: 12, item_text: 'Sin foto 2x2' },
    { item_id: 13, item_text: 'Sin Certificar Cuenta Bancaria' }
  ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  showCheckboxCertificateBankAccount: boolean = false;
  showButtonCertificateBankAccount: boolean = false;
  changeInfo: boolean = false;

  sendItems: EmployeesIncomplete[] = [];
  allItemsSelected: boolean = false;

  constructor(public dialogRef: MatDialogRef<EmployeesIncompleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, type: number },
    private employeeService: EmployeesService,
    private _toastService: ToastService,
    public authInfo: AuthInfoService, private _cdRef: ChangeDetectorRef, private dialog: MatDialog, private _exlService: ExportToExcelService
  ) {
  }

  ngOnInit(): void {
    const keyLocalStorage = `incomplete-${this.data.type === TypeModalEmployees.Employee ? 'employees' : 'teachers'}-list_filter`;
    if (JSON.parse(localStorage.getItem(keyLocalStorage))?.length > 0) {
      this.selectedItems = JSON.parse(localStorage.getItem(keyLocalStorage));
    }
    this.privilege = this.authInfo.setPrivileges(this.privilege)
    this.getEmployeesIncompletePaginated();
    applySort();
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  getEmployeesIncompletePaginated() {
    this.isNoAccountCertificationSeleted();
    if (this.data?.type === TypeModalEmployees.Employee) {
      this.getEmployeesPaginated();
    }
    if (this.data?.type === TypeModalEmployees.Teacher) {
      this.getTeacherPaginated();
    }
  }

  private isNoAccountCertificationSeleted() {
    this.showCheckboxCertificateBankAccount = this.selectedItems?.some(item => item?.item_id === 13);
    if (!this.showCheckboxCertificateBankAccount) {
      this.sendItems = [];
      this.incompleteEmployees = this.incompleteEmployees?.map(employee => {
        return {
          ...employee,
          isSelected: false
        }
      })
    }
    this.showButtonCertificateBankAccount = this.sendItems?.length > 0;
  }

  selectItem(employee) {
    if (this.sendItems.some(item => item?.employeeId === employee?.employeeId)) {
      this.sendItems = this.sendItems.filter((value, index, arr) => {
        return value?.employeeId != employee?.employeeId;
      })
      this.allItemsSelected = false;
    } else {
      this.sendItems.push(employee)
    }
    this.showButtonCertificateBankAccount = this.sendItems?.length > 0;
  }

  selectAllItems() {
    this.sendItems = [];
    this.incompleteEmployees.forEach((element) => {
      if (element?.status) {
        element['isSelected'] = this.allItemsSelected;

        if (this.allItemsSelected) {

          this.sendItems.push(element);
        }
        this.showButtonCertificateBankAccount = this.sendItems?.length > 0;
      }
    });
  }

  certificateAccount() {
    const RequestBankAccountCertificationBulkLoad: RequestBankAccountCertificationBulkLoad = {
      companyId: this.authInfo?.getCompanyId(),
      userId: this.authInfo?.getUserId(),
      employees: this.sendItems?.map(item => {
        return {
          employeeId: item.employeeId,
          name: item.name
        }
      })
    }
    this.requestBankAccountCertBulkLoad(RequestBankAccountCertificationBulkLoad);
  }

  private requestBankAccountCertBulkLoad(requestBankAccountCertification: RequestBankAccountCertificationBulkLoad) {
    this.employeeService.requestBankAccountCertBulkLoad(requestBankAccountCertification).subscribe({
      next: res => {
        if (res?.warnings?.length > 0) {
          this.handlerWarningRequestBankCert(res?.warnings, requestBankAccountCertification);
          this.changeInfo = true;
          return;
        }
        if (res?.errors?.length) {
          this._toastService.error(res.errors[0], '');
          return;
        }
        swalWarning("", 'Solicitudes de certificacion de cuentas bancarias realizadas correctamente', "success");
        this.sendItems = [];
        this.changeInfo = true;
        this.getEmployeesIncompletePaginated();
      }, error: res => {
        this._toastService.error('Ha ocurrido un error al certificar las cuentas bancarias');
      }
    });
  }

  private handlerWarningRequestBankCert(resWarnings: string[], requestBankAccountCertification: RequestBankAccountCertificationBulkLoad) {
    const warnings = resWarnings?.map(w => {
      return {
        employeeId: +w?.split('|')[0],
        message: w.split('|')[1],
        type: w?.split('|').length === 3 ? w?.split('|')[2] : null
      }
    });
    const employees = [
      ...requestBankAccountCertification?.employees?.map(employee => {
        return {
          ...employee,
          warnings: warnings.filter(w => w.employeeId === employee.employeeId) as any[]
        }
      })
    ];
    let template = !employees?.every(e => e?.warnings?.length > 0) ? '<h6>Se procesaron exceptuando a:</h6><ul>'
      : '<ul>'

    employees?.forEach(employee => {
      if (employee?.warnings?.length > 0) {
        template += `<li><b>${employee.name}</b>: ${employee.warnings.map(w => `${w?.message}`)}</li>`
      }
    });
    template += '</ul>'
    swalWarning("Información", template, "warning", 600);
    this.sendItems = this.sendItems?.filter(item => {
      if ((warnings?.some(w => w?.employeeId == item?.employeeId
        && w.type === 'WasCertificated'))
        || !warnings?.some(w => w?.employeeId == item?.employeeId)) {
        return;
      }
      return item;
    })
    if (warnings?.some(w => w.type === 'WasCertificated')
      || !employees?.every(e => e?.warnings?.length > 0)) {
      this.getEmployeesIncompletePaginated();
    }
  }

  private getEmployeesPaginated() {
    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const conditions = this.selectedItems?.map((condition: any) => condition.item_id)?.join('-')
    this.employeeService.getEmployeesIncompletePaginated(conditions, page, pageSize, propKeyId, text).subscribe(res => {
      if (res?.errors?.length > 0) {
        res.errors.forEach(element => {
          this._toastService.error(element);
        });
        return;
      }
      this.employeesPagedResut = res.singleData
      this.incompleteEmployees = res.singleData?.items?.map(employee => {
        return {
          ...employee,
          isSelected: this.sendItems?.some(item => item?.employeeId === employee?.employeeId && item?.isSelected === true),
          withoutCertificate: employee?.incompleteInformations?.some(info => info?.descriptionId === 13)
        }
      })
    }, err => {
      this._toastService.error('Ha ocurrido un error buscando la lista de servidores público incompletos, Consulte al soporte técnico del sistema');
    });
  }

  private getTeacherPaginated() {
    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const conditions = this.selectedItems?.map((condition: any) => condition.item_id)?.join('-')
    this.employeeService.getTeacherIncompletePaginated(conditions, page, pageSize, propKeyId, text).subscribe(res => {
      if (res?.errors?.length > 0) {
        res.errors.forEach(element => {
          this._toastService.error(element);
        });
        return;
      }
      this.employeesPagedResut = res.singleData
      this.incompleteEmployees = res.singleData?.items?.map(employee => {
        return {
          ...employee,
          isSelected: this.sendItems?.some(item => item?.employeeId === employee?.employeeId && item?.isSelected === true),
          withoutCertificate: employee?.incompleteInformations?.some(info => info?.descriptionId === 13)
        }
      })
    }, err => {
      this._toastService.error('Ha ocurrido un error buscando la lista de docentes incompletos, Consulte al soporte técnico del sistema');
    });
  }


  filterSearch(event) {
    this.employeesPagedResut.page = 1;
    this.searchParameter = event;
    this.getEmployeesIncompletePaginated();
  }

  openModalIncompletedFiles(employee) {
    this.dialog.open(IncompletedFilesComponent, {
      data: {
        employeeId: employee?.employeeId,
        incompleteInformations: employee?.incompleteInformations,
        type: this.data.type
      },
    }).afterClosed().subscribe(() => {

    })
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.getEmployeesIncompletePaginated();
    this.setFilterToLocalStorage()
  }
  onItemDeSelect(item: any) {
    this.getEmployeesIncompletePaginated();
    this.setFilterToLocalStorage()
  }
  onItemSelect(item: any) {
    this.getEmployeesIncompletePaginated();
    this.setFilterToLocalStorage()
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.getEmployeesIncompletePaginated();
    this.setFilterToLocalStorage()
  }

  setFilterToLocalStorage() {
    const keyLocalStorage = `incomplete-${this.data.type === TypeModalEmployees.Employee ? 'employees' : 'teachers'}-list_filter`;
    localStorage.setItem(keyLocalStorage, JSON.stringify(this.selectedItems));
  }

  getPaginatedRecords(event) {
    if (this.employeesPagedResut.page == event.selectedPage && this.employeesPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.employeesPagedResut.page = event.selectedPage;
    this.employeesPagedResut.pageSize = event.registersPerPage;
    this.getEmployeesIncompletePaginated();
  }

  close() {
    this.dialogRef.close({ changeInfo: this.changeInfo });
  }

  exportReport(){
    const companyId = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.EmployeeincompleteList;
    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const conditions = this.selectedItems?.map((condition: any) => condition.item_id)?.join('-')
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&Status=true&Conditions=${conditions}&PropKeyId=${propKeyId}&SearchParameter=${text}&page=${page}&pageSize=${pageSize}`;
    let parameters = {
      url: reportUrl,
      title: 'Docentes por Asignatura Incompletos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
    return;

  }

  exportToExcel() {
    if (this.data.type === TypeModalEmployees.Teacher) {
      const companyId = this.authInfo.getCompanyId();
      const page = this.employeesPagedResut.page;
      const pageSize = this.employeesPagedResut.pageSize;
      const text = this.searchParameter?.value ?? '';
      const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
      const conditions = this.selectedItems?.map((condition: any) => condition.item_id)?.join('-');
      const reportCode: ReportCode = ReportCode.TeacherPerHoursIncomplete;
      const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&Status=true&PropKeyId=${propKeyId}&SearchParameter=${text}&Conditions=${conditions}&Page=${page}&PageSize=${pageSize}`;
      let parameters = {
        url: reportUrl,
        title: 'Docentes por Asignatura Incompletos',
        width: 1024,
        height: 768
      }
      openReport(parameters);
      return;
    }
    let data = [...this.incompleteEmployees] as any[];
    data?.forEach(element => {
      element.statusText = element.status ? 'Activo' : this.data.type === TypeModalEmployees.Employee ? 'Desvinculado' : 'Inactivo'
    });
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Servidor Público",
      "Documento de Identidad",
      "Unidad Organizativa",
      "Teléfono Primario",
      "Estatus",
    ], [
      "employeeId",
      "name",
      "personalIdentification",
      "departmentName",
      "phoneNumber",
      "statusText"
    ]]
    const fileName = this.data.type === TypeModalEmployees.Employee ? 'Lista de Servidores Públicos incompletos' : 'Lista de Docentes incompletos';
    this._exlService.exportToExcelSpecificColumns(data, excelHeaders, fileName, true);
  }

}
