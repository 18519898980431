import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-expense-items',
  templateUrl: './expense-items.component.html',
  styleUrls: ['./expense-items.component.css']
})
export class ExpenseItemsComponent implements OnInit {

  formIsInvalid = false
  form: FormGroup
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ExpenseItemsComponent>,
    private formBuilder: FormBuilder
  ) {
    this.buildForm()
  }

  ngOnInit(): void {
  }


  buildForm() {
    this.form = this.formBuilder.group({
      budgetAccount: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9\.\s]+$/)]],
      totalIncome: [0, [Validators.required]],
      fullDiscount: [0, [Validators.required]],
      netFirstFortnight: [0, [Validators.required]],
      netSecondFortnight: [0, [Validators.required]],
      pattern: [0, [Validators.required]],
      risk: [0, [Validators.required]],
      sfs: [0, [Validators.required]],
      complementaryRetreat: [0, [Validators.required]],
    })
  }

  save() {
    // this.formIsInvalid = this.form.invalid
    // if (this.formIsInvalid) {
    //   return
    // }
    // const values = this.form.value
    // let item: PartidaGastosDetalle = {
    //   cuentaPresupuestaria: values.budgetAccount,
    //   netoPrimeraQuincena: values.netFirstFortnight,
    //   netoSegundaQuincena: values.netSecondFortnight,
    //   patron: values.pattern,
    //   riesgo: values.risk,
    //   sfs: values.sfs,
    //   retiroComplementario: values.complementaryRetreat,
    //   totalDescuentos: values.fullDiscount,
    //   totalIngresos: values.totalIncome,
    // }
    // this.dialogRef.close(item)
  }

  close() {
    this.dialogRef.close()
  }

  getError(name: string) {
    if (!this.formIsInvalid) {
      return ''
    }
    const field = this.form.get(name)
    if (field.hasError('required')) {
      return 'Este campo es requerido'
    }
    if(field.hasError('pattern')){
      return 'No se permiten caracteres especiales';
    }
    return ''
  }

}