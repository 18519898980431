import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Content, postContent } from '../models/content';
import { keyWord } from '../shared/utils/parameterControl';
import { AuthInfoService } from './auth-info.service';
import { ParameterControlService } from './parameter-control.service';

@Injectable({
  providedIn: 'root'
})
export class ContentManagementService {

  private readonly api = environment.api_url

  constructor(private http: HttpClient, 
    private _authInfo: AuthInfoService,
    private srvParam: ParameterControlService
    ) { }
  

  getContent() {
    return this.http.get(`${this.api}/News?NewsId=0&CompanyId=${this._authInfo.getCompanyId()}&Type=1`);
  }
  
  createContent(content:Content){
    return this.http.post(`${this.api}/News`, content);
  }

  publishContent(content:postContent){
    return this.http.post(`${this.api}/News/PostNews`, content);
  }

  updateContent(content:Content){
    return this.http.put(`${this.api}/News`, content);
  }

  deleteContent(contentId){
    return this.http.delete(`${this.api}/News?NewsId=${contentId}&UserId=${this._authInfo.getUserId()}`);
  }

  getPostClassification() {
    return this.srvParam.getParameters(keyWord.PublicationClassification)
  }

  getPostTypes() {
    return this.srvParam.getParameters(keyWord.PostType)
  }

  getConditions() {
    return this.srvParam.getParameters(keyWord.NewConditionStatus)
  }

  getOrigins() {
    return this.srvParam.getParameters(keyWord.OriginNews)
  }

  getDemographics() {
    return this.srvParam.getParameters(keyWord.Directed)
  }

}
