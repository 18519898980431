import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { removeWhitespace } from 'src/app/shared/utils/utility';

@Component({
  selector: 'app-recomendations',
  templateUrl: './recomendations.component.html',
  styleUrls: ['./recomendations.component.css']
})
export class RecomendationsComponent implements OnInit {

  recomendationsForm: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    phoneNumber: new FormControl(null, Validators.required),
    institution: new FormControl(null, Validators.required),
  })

  constructor(
    public dialogRef: MatDialogRef<RecomendationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    if (this.data?.isEditing) {
      this.getRecomendationInfo()
    }
  }

  getRecomendationInfo() {
    const { name, tel, institutions } = this.data.recomendationInfo
    this.recomendationsForm.patchValue({
      name: name,
      phoneNumber: tel,
      institution: institutions,
    })
  }

  Cancel() {
    this.dialogRef.close()
  }

  Accept() {
    if (this.recomendationsForm.invalid) {
      return
    }

    let recomendationData = {
      name: this.recomendationsForm.get('name').value,
      phoneNumber: this.recomendationsForm.get('phoneNumber').value,
      institution: this.recomendationsForm.get('institution').value,
    }
    this.dialogRef.close(recomendationData)
  }


  removeWhitespaces(event, control){
    removeWhitespace(event, control)
  }

}
