import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'concept-group-tap',
  templateUrl: './concept-group.component.html',
  styleUrls: ['./concept-group.component.css']
})
export class ConceptGroupComponent implements OnInit {

  @Output() onClose = new EventEmitter<boolean>()
  constructor() { }

  ngOnInit(): void {
  }

  close(){
    this.onClose.emit(true)
  }
}
