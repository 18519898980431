<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-aprobado icon-3x text-danger"></i>
                <h1 class="text-primary pl-2"> Listado de Prueba </h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard"
        data-toggle="tooltip" data-original-title="Dashboard">
            <a (click)="openDialog()"
                *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.dashboardAction)"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </a>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-6 c-left">
            <div class="col-md-4 mr-2 pl-0 pt-1">
                <div class="form-group  institution">
                    <label class="text-primary">
                        Estado de Candidato
                    </label>
                    <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                        [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                        (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.exportAction)"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="printReport()">
                            <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                            Visualizar Reporte
                        </a>
                        <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a> -->
                    </div>
                </div>

                <div class="col text-nowrap">
                    <app-search-bar-pagination (onFilter)="filterSearch($event)"
                        [parameters]="searchBarDisplayNames" [namePageLocalStorage]="searchparameterlocalstorage"></app-search-bar-pagination>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table mt-2 table-vertical-center text-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th class="text-left">Cargo</th>
                        <th class="text-left">Candidato</th>
                        <th class="text-left">Documento de Identidad</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-left">Fecha de Postulación</th>
                        <th>Pruebas Realizadas</th>
                        <th class="text-left">Condición del Candidato</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-template [ngIf]="testList.length>0">
                        <tr *ngFor="let item of testList; let i=index">
                            <td class="text-center">{{item.ppvid}}</td>
                            <td class="text-left">{{item.positionName}}</td>
                            <td class="text-left">{{item.personName}}</td>
                            <td class="text-left" *ngIf="item.typeIdentificationId == 1 || item.typeIdentificationId == 4">{{item.personalIdentification |
                                mask:
                                "000-0000000-0"}}</td>
                            <td class="text-left" *ngIf="item.typeIdentificationId != 1 && item.typeIdentificationId != 4">{{item.personalIdentification}}</td>
                            <td class="text-left">{{item.departmentName}}</td>
                            <td class="text-left">{{item.dateApplication |date: "dd/MM/yyyy"}}</td>
                            <td>{{item.test}}/{{item.countTest}}</td>
                            <td class="text-left">
                                <span *ngIf="item.condition != 0">
                                    <span *ngIf="item.condition == 1" class="font-weight-bold text-warning"><span
                                            class="label label-warning label-dot mr-2"></span>{{item.conditionDescription}}</span>
                                    <span *ngIf="item.condition == 2" class="font-weight-bold text-success"><span
                                            class="label label-success label-dot mr-2"></span>{{item.conditionDescription}}</span>
                                    <span *ngIf="item.condition == 3" class="font-weight-bold text-success"><span
                                            class="label label-success label-dot mr-2"></span>{{item.conditionDescription}}</span>
                                    <span *ngIf="item.condition == 4" class="font-weight-bold text-warning"><span
                                            class="label label-warning label-dot mr-2"></span>{{item.conditionDescription}}</span>
                                    <span *ngIf="item.condition == 5" class="font-weight-bold text-danger"><span
                                            class="label label-danger label-dot mr-2"></span>{{item.conditionDescription}}</span>
                                    <span *ngIf="item.condition == 6" class="font-weight-bold text-warning"><span
                                            class="label label-warning label-dot mr-2"></span>{{item.conditionDescription}}</span>
                                    <span *ngIf="item.condition == 10" class="font-weight-bold text-danger"><span
                                            class="label label-danger label-dot mr-2"></span>{{item.conditionDescription}}</span>
                                </span>
                                <span *ngIf="item.condition == 0">
                                    <span class="label label-info label-dot mr-2"></span>
                                    <span class="font-weight-bold text-info">En Proceso</span>
                                </span>
                            </td>
                            <td class="actionsBtn">
                                <button
                                    *ngIf="item.condition != 0 && authInfo.canDoAction(privilege.nameKeyOption, privilege.viewAction)"
                                    [routerLink]="['/proof-applicants', item.ppvid]"
                                    class="btn btn-sm btn-light-skype btn-icon" data-toggle="tooltip">
                                    <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver"></i>
                                </button>
                                <button
                                    *ngIf="item.condition == 0 && authInfo.canDoAction(privilege.nameKeyOption, privilege.testAction)"
                                    [disabled]="item.condition != 0" [routerLink]="['/proof-applicants', item.ppvid]"
                                    class="btn btn-sm btn-light-success btn-icon" data-toggle="tooltip"
                                    title="Hacer prueba">
                                    <i class="flaticon-contract-3 icon-2x"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
            <div>
                <app-paginator [listedRecordsLength]="testList?.length"
                    [registerQuantity]="testsListPagedResut.totalItems" [registerPerPage]="testsListPagedResut.pageSize"
                    [totalPages]="testsListPagedResut.totalPages" [selectedPage]="testsListPagedResut.page"
                    (onChange)="getPaginatedRecords($event)">
                </app-paginator>
            </div>
        </div>
    </div>
</div>

<export-data-to-pdf [title]="'Listado de Prueba'" id="dataToPrint">
    <ng-container thead>
        <th class="text-left">Posición</th>
        <th class="text-left">Candidato</th>
        <th>Fecha de postulación</th>
        <th>Pruebas Realizadas</th>
        <th>Condición de Candidato</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let item of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.testList"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td class="text-left">{{item.positionName}}</td>
            <td class="text-left">{{item.personName}}</td>
            <td>{{item.dateApplication |date: "dd/MM/yyyy"}}</td>
            <td>{{item.test}}/{{item.countTest}}</td>
            <td class="text-center">
                <span *ngIf="item.condition != 0">
                    <span *ngIf="item.condition == 1" class="font-weight-bold text-warning"><span
                            class="label label-warning label-dot mr-2"></span>Enviada al MAP</span>
                    <span *ngIf="item.condition == 2" class="font-weight-bold text-success"><span
                            class="label label-success label-dot mr-2"></span>Aprobada por el MAP</span>
                    <span *ngIf="item.condition == 3" class="font-weight-bold text-success"><span
                            class="label label-success label-dot mr-2"></span>Nombrado</span>
                    <span *ngIf="item.condition == 4" class="font-weight-bold text-warning"><span
                            class="label label-warning label-dot mr-2"></span>Reservado para Nombramiento</span>
                    <span *ngIf="item.condition == 5" class="font-weight-bold text-danger"><span
                            class="label label-danger label-dot mr-2"></span>Rechazada por el MAP</span>
                    <span *ngIf="item.condition == 6" class="font-weight-bold text-warning"><span
                            class="label label-warning label-dot mr-2"></span>Devuelta para Revisión</span>
                </span>
                <span *ngIf="item.condition == 0">
                    <span class="label label-info label-dot mr-2"></span>
                    <span class="font-weight-bold text-info">En Proceso</span>
                </span>
            </td>

        </tr>
    </ng-container>
</export-data-to-pdf>
