import { Injectable, EventEmitter } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Account } from 'src/app/shared/models/account';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  account$ = new EventEmitter<Account>();

  IsLoading$ = new EventEmitter<boolean>();
  IsHome$ = new EventEmitter<boolean>();
  constructor(private jwtHelper: JwtHelperService) { }

  GetUserData(): Account {
    const token = localStorage.getItem("token");
    const data = this.jwtHelper.decodeToken(token); 

    const temp = localStorage.getItem('Usr-Account');

    if (data) {
      let UsrData = new Account();
      UsrData = data;

      if (UsrData) {
        /*
        This is due to UserTypeId could be change while the user is using the system and it is importan to keep it up to date at anytime, anyway there are 
        restrictions on the backend in case of inappropiated use.
        */
        if(temp){
          let model = new Account();
          model = JSON.parse(temp);
          UsrData.userTypeId = model.userTypeId;
        }
        
        return UsrData;
      } 
    } else {
        return new Account();
    }
  }
}