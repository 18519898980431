<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>Servicio al Servidor Público</h2>
</div>
<div class="mat-dialog-content-custom" style="border-bottom: 0px solid white;">
    <div class="card-body-custom my-0 py-0">
        <div id="pdfContent">
            <div class="row">
                <div class="col-md-12" *ngIf="pdfUrl">
                    <iframe [src]="pdfUrl" height='700px' width='100%'></iframe>
              </div>`
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions justify-content-center" *ngIf="loading" style="height: 60%;">
    <div>
         
    </div>
</div>

<div class="mat-dialog-actions justify-content-end" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="close()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-success" type="button" (click)="aprovee()"
        *ngIf="aproveed==0">
        <i class="flaticon-disco-flexible"></i>
        <span>{{buttonText}}</span>
    </button>
</div>