<div *ngIf="isLoading" class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
    <el-spinner animation="spinner-logo"></el-spinner>
    <h3 class="text-primary">Cargando Resumen...</h3> 
  </div>
  <div class="container-fluid py-3">
    <button  (click)="goBack()"  class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
        <i class="flaticon2-left-arrow-1"></i>
        <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
    </button>
  </div>
  <div class="container-fluid" *ngIf="!isLoading">
    <div id="pdfExp" class="card">
      <div class="card-header px-22">
        <div class="align-items-center d-flex flex-md-row justify-content-lg-between justify-content-sm-center" id="header">
          <div class="logo px-2"> <img [src]="file.logo" alt="" class="max-w-200px" /> </div>
          <div  class="title p-2 d-flex justify-content-center align-items-center flex-column">
            <h1 class="ttlPDF text-center">Recursos Humanos</h1>
            <h2 class="text-danger text-center">Titulo de la pantalla Referenciada</h2>
          </div>
          <div class="date mt-md-5 p-4 d-flex flex-wrap justify-content-between align-items-center">
            <h4 class="text-right mr-3">Fecha de solicitud</h4>
            <h5 class="text-danger text-right">__-__-____</h5>
            <!-- &nbsp; -->
          </div>
        </div>
      </div>
      <div class="card-body">
        <div id="pdf">
          <div class="pdf-page pb-4">
            <div class="row gutter-t" id="personal-data">
              <h3 class="col-md-12 p-2 bg-primary text-white text-center gutter-b">DATOS PERSONALES</h3>
              <div class="col-md-6 row">
                <div class="photo col-md-4">
                    <div class="symbol symbol-lg-200">
                        <i class="symbol-badge bg-success"></i>
                        <img src="assets/media/no-user.png" alt="image">
                    </div>                 
                </div>              
                <div class="pdf-list col-md-8">
                    <div class="label-inline">
                        <label>Número de Registro:</label>
                        <span>N/A</span>
                    </div>
                    <div class="label-inline">
                        <label>Instrumento Legal:</label>
                        <span>N/A</span>
                    </div>
                    <div class="label-inline">
                        <label>Cédula:</label>
                        <span>N/A</span>
                    </div>
                    <div class="label-inline">
                        <label>Nombre(s):</label>
                        <span>N/A</span>
                    </div>
                    <div class="label-inline">
                        <label>Apellido(s):</label>
                        <span>N/A</span>
                    </div>
                    <div class="label-inline">
                        <label>Supervisor Inmediato:</label>
                        <span>N/A</span>
                    </div>                  
                </div>
              </div>            
              <div class="pdf-list col-md-6">
                <div class="label-inline">
                    <label>Organismo Asignado:</label>
                    <span>N/A</span>
                </div>
                <div class="label-inline">
                    <label>Desempeña Funciones en:</label>
                    <span>N/A</span>
                </div>
                <div class="label-inline">
                    <label>Sueldo Base:</label>
                    <span>N/A</span>
                </div>
                <div class="label-inline">
                    <label>Tarjeta:</label>
                    <span>N/A</span>
                </div>
                <div class="label-inline">
                    <label>Fecha de Ingreso:</label>
                    <span>N/A</span>
                </div>
                <div class="label-inline">
                    <label>Fecha Desvinculación:</label>
                    <span>N/A</span>
                </div> 
              </div>            
            </div>
            <div class="row gutter-t">
              <h3 class="col-md-12 p-2 bg-secondary text-uppercase text-white text-center d-block">
                Naturaleza de Acción
              </h3>
              <div class="col-md-12 row m-0 p-0">
                <div class="h-200px">[ Contenido ]</div>
              </div>
            </div>
            <div class="row gutter-t">
              <h3 class="col-md-12 p-2 bg-primary text-uppercase text-white text-center text-center">
                Motivo de Acción
              </h3>
              <div class="col-md-12 row m-0 p-0">
                <div class="h-200px">[ Contenido ]</div>
              </div>
            </div>
            <div class="row gutter-t " >
              <h3 class="col-md-12 px-5 py-2 bg-light-info text-uppercase text-primary">Observaciones</h3>
              <div class="col-md-12 row m-0 p-0">
                <div class="h-200px">[ Contenido ]</div>
              </div>
            </div>           
          </div>
        </div>
      </div>
      <div class="card-footer gutter-t">
        <div class="row my-5">
            <div class="col-md-4 gutter-b gutter-t">
                <div class="firma text-center">
                    <span>_________________________________________</span>
                    <p class="font-weight-bold text-dark">
                        Elaborado por
                    </p>
                </div>
            </div>
            <div class="col-md-4 gutter-b gutter-t">
                <div class="firma text-center">
                    <span>_________________________________________</span>
                    <p class="font-weight-bold text-dark">
                        Firma Superior Inmediato
                    </p>
                </div>
            </div>
            <div class="col-md-4 gutter-b gutter-t">
                <div class="firma text-center">
                    <span>_________________________________________</span>
                    <p class="font-weight-bold text-dark">
                        Firma Responsable de RRHH
                    </p>
                </div>
            </div>
        </div>
        <div class="row mt-5 gutter-t">
            <div class="col-md-4 gutter-b">
            <div class="firma text-center">
                <span>_________________________________________</span>
                <p class="font-weight-bold text-dark">
                    Elaborado por
                </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="firma text-center"></div>
            </div>
            <div class="col-md-4 gutter-b">
                <div class="firma text-center">
                    <span>_________________________________________</span>
                    <p class="font-weight-bold text-dark">
                        Firma Responsable de RRHH
                    </p>
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
          <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-80px">
              <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
        </div>
      </div>
    </div>
  </div>
  <button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
    <i class="flaticon2-printer font-size-h1"></i>
  </button>
  <script>
    let print = () => {
      let pdf = document.getElementById("pdfExp");
      pdf.contentWindow.focus();
      pdf.contentWindow.print();
    };
  </script>  