import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Beneficiary, DisapproveMassive, PayrollNew, PayrollNewsDelete, PostPayrollNewsBulkLoad } from '@payroll/news-list/models/PayrollNews.model';
import { environment } from 'src/environments/environment';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class PayrollNewService {

  private readonly api = environment.api_url;

  constructor(
    private http: HttpClient, 
    private authInfo: AuthInfoService
  ) { }

  getPayrollNews(companyId: number = this.authInfo.getCompanyId(), status: boolean = true) {
    return this.http.get<any>(`${this.api}/PayrollNews?PayrollNewsId=0&CompanyId=${companyId}&Status=${status}`);
  }

  getPayrollNewsPaginated(options, advanceFilterOptions?) {
    let params = new HttpParams()
    params = params.append('CompanyId', this.authInfo.getCompanyId().toString());
    params = params.append('PropkeyId', options.propkeyId);
    params = params.append('SearchParameter', options.searchParameter);
    params = params.append('Status', options.status);
    params = params.append('Page', options.page);
    params = params.append('PageSize', options.pageSize);
    params = params.append('Type', options.type.toString());
    params = params.append('NewsTypeId',  options?.newsTypeId?.toString());
    params = params.append('Conditions',  options?.conditions?.toString());
    params = params.append('AdvancedFilter',  advanceFilterOptions?.toString());
    return this.http.get<ResponseModel<PayrollNew>>(`${this.api}/PayrollNews/GetPaginated`, {params});
  }

  getEmployees(type:number = 26, typeParameter?:string, typeValue?:any) {
    typeParameter = typeParameter != null ? `&${typeParameter}=${typeValue}` : '';
    return this.http.get(`${this.api}/Employees?Type=${type}&CompanyId=${this.authInfo.getCompanyId()}`+typeParameter);
  }


  getEmployeeTeacherPaginated(page?:number, pageSize?:number, PropkeyId = 0, SearchParameter = null){
    return this.http.get<ResponseModel<any>>(`${this.api}/Employees/GetPaginatedFilter?Type=3&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Page=${page}&PageSize=${pageSize}&CompanyId=${this.authInfo.getCompanyId()}`)
  }

  getEmployeesPaginated(page?:number, pageSize?:number, PropkeyId = 0, SearchParameter = null) {
    return this.http.get(`${this.api}/Employees/GetPaginatedFilter?Type=7&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Page=${page}&PageSize=${pageSize}&CompanyId=${this.authInfo.getCompanyId()}`)
  }

  getPayrollNewById(payrollNewsId: number, companyId: number = this.authInfo.getCompanyId(), status: boolean = true) {
    return this.http.get<any>(`${this.api}/PayrollNews?PayrollNewsId=${payrollNewsId}&CompanyId=${companyId}&Status=${status}`);
  }

  postPayrollNew(payrollNew: PayrollNew) {
    return this.http.post<any>(`${this.api}/PayrollNews`, payrollNew);
  }
  
  updatePayrollNew(payrollNew: PayrollNew) {
    return this.http.put<any>(`${this.api}/PayrollNews`, payrollNew);
  }

  deletePayrollNew(model: {PayrollNewsId, Reason, userId}){
    return this.http.put<any>(`${this.api}/PayrollNews/Reject`, model);
  }

  approvePayrollNew(model: {payrollNewsId: number, userId: number}){
    return this.http.post<any>(`${this.api}/PayrollNews/Approve`, model);
  }

  approveMassive(data: {payrollNewsId: number, userId: number}[]){
    return this.http.post<any>(`${this.api}/PayrollNews/ApproveMassive`, data);
  }
  disapproveMassive(model: DisapproveMassive){
    return this.http.put<any>(`${this.api}/PayrollNews/DisapproveMassive`, model);
  }

  getNewsType(companyId: number = this.authInfo.getCompanyId(), status: boolean = true) {
    return this.http.get<any>(`${this.api}/NewsType?NewsTypeId=0&CompanyId=${companyId}&Status=${status}`);
  }

  getNewsConcept(companyId: number = this.authInfo.getCompanyId(), status: boolean = true) {
    return this.http.get<any>(`${this.api}/NewsConcept?NewsConceptId=0&CompanyId=${companyId}&Status=${status}`);
  }

  getNewsConceptsByFilter(filter:string) {
    return this.http.get<any>(`${this.api}/NewsConcept/GetFilterBy?Filter=${filter}&Type=0`);
  }

  postBeneficiary(beneficiary: Beneficiary) {
    return this.http.post<any>(`${this.api}/Beneficiary`, beneficiary);
  }

  updateBeneficiary(beneficiary: Beneficiary){
    return this.http.put<any>(`${this.api}/Beneficiary`, beneficiary);
  }
  
  getBeneficiaryById(BeneficiaryId: number, companyId: number = this.authInfo.getCompanyId(), status: boolean = true){
    return this.http.get<any>(`${this.api}/Beneficiary?BeneficiaryId=${BeneficiaryId}&CompanyId=${companyId}&Status=${status}`);
  }


  getPersonByIdEmployee(id: number, companyId: number = this.authInfo.getCompanyId(), status: boolean = true) {
    return this.http.get(`${this.api}/Employees?employeeNumber=${id}&DepartmentId=0&PositionId=0&Type=5&CompanyId=${companyId}&Status=${status}`)
  }

  getPeriods(companyId: number = this.authInfo.getCompanyId(), status: boolean = true) {
    return this.http.get(`${this.api}/Period?CompanyId=${companyId}&Status=${status}`);
  }

  getBulkLoad(bulkLoad: any[]){
    return this.http.post(`${this.api}/BulkLoad/GetBulkLoadCooperative`, bulkLoad);
  }

  postPayrollNewsBulkLoad(payload: PostPayrollNewsBulkLoad[]){
    return this.http.post(`${this.api}/PayrollNews/PostPayrollNewsBulkLoad?UserId=${this.authInfo.getUserId()}`, payload)
  }

  getDataValidationPayrollNewsDetail(conditionId){
    return this.http.get(`${this.api}/PayrollNews/GetDataValidationPayrollNewsDetail?ConditionId=${conditionId}`)
  }

  getDataValidationPayrollNews(){
    return this.http.get(`${this.api}/PayrollNews/GetDataValidationPayrollNews`)
  }

  processAllDataPayrollNews(payload){
    return this.http.post(`${this.api}/PayrollNews/ProcessAllDataPayrollNews`, payload)
  } 

  postBulkLoadCooperative(bulkLoad: any[]){
    return this.http.post(`${this.api}/BulkLoad/PostBulkLoadCooperative`, bulkLoad);
  }

  getDashboard(dateFrom = null, dateTo = null, companyId: number, status: boolean = true){
    return this.http.get(`${this.api}/PayrollNews/GetDashboard?CompanyId=${companyId}&Status=${status}&DateFrom=${dateFrom}&DateTo=${dateTo}`);
  }

  getDefaultValues(ConceptValuesId: number,CompanyId: number = this.authInfo.getCompanyId(), Status: boolean =  true){
    return this.http.get(`${this.api}/ConceptValues?ConceptValuesId=${ConceptValuesId}&CompanyId=${CompanyId}&Status=${Status}&type=2`);
  }

  getNewsConceptsSetting(NewsConceptSettingId: number, CompanyId: number = this.authInfo.getCompanyId(), Status: boolean =  true){
    return this.http.get(`${this.api}/NewsConceptSetting?NewsConceptSettingId=${NewsConceptSettingId}&Type=1&CompanyId=${CompanyId}&Status=${Status}`);
  }

  disapprovePayrollNews(model){
    return this.http.put(`${this.api}/PayrollNews/Disapprove`, model);
  }

  removeAll(payrollNewsDelete: PayrollNewsDelete){
    return this.http.put<ResponseModel<any>>(`${this.api}/PayrollNews/RejectAll`, payrollNewsDelete);
  }

}