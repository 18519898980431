<h2 class="mat-dialog-title m-0">
    Cambiar contraseña
    <button type="button" (click)="closeModal()" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
      </button>
</h2>
<div class="mat-dialog-content">
    <form [formGroup]="passwordForm">
    
        <div class="row px-5">
            <div class="col-md-12">
                <div class="bg-light-info rounded row px-3">
                    <div class="text-center col-lg-3">
                        <div>
                            <div  class="symbol symbol-150 d250 rd5 mr-3 my-5">
                                <img [src]="userImg ? userImg : 'assets/images/no-user.png'" alt="Foto" onerror="this.error=null; this.src='assets/images/no-user.png'"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 d-flex justify-content-center flex-column">
                        <h5 class="d-flex font-weight-bolder my-1 p-2 text-primary">Nombre: <span
                                class="text-dark ml-3">{{user?.firstName + ' ' + user?.lastName}}</span></h5>
                        <h5 class="d-flex font-weight-bolder my-1 p-2 text-primary">Documento de identificación: <span
                                class="text-dark ml-3">
                                {{
                                    employeeData?.typeIdentificationId == 1 ? (employeeData?.personalIdentification
                                    | mask: '000-0000000-0') : (employeeData?.personalIdentification)
                                }}
                            </span></h5>
                        <h5 class="d-flex font-weight-bolder my-1 p-2 text-primary">Teléfono: <span
                                class="text-dark ml-3">
                                {{(employeeData?.phoneNumber ? employeeData?.phoneNumber : "N/A") |
                                mask:employeeData?.phoneNumber ?"(000)-000-0000":"N/A"}}
                            </span></h5>
                        <h5 class="d-flex font-weight-bolder my-1 p-2 text-primary">Correo Electronico: <span
                                class="text-dark ml-3">{{user?.uEmail}}</span></h5>
                        <h5 class="d-flex font-weight-bolder my-1 p-2 text-primary">Cargo: <span
                                class="text-dark ml-3">{{employeeData?.positionName}}</span></h5>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row g-10 mt-5">
            <div class="col-12">
                <div class="d-flex h-100">
                    <div class="w-100 d-flex flex-column border rounded-3 p-10">
                        <!-- <div class="mb-7 text-left">
                            <h1 class="text-primary mb-5 fw-boldest">Datos que puede modificar</h1>
                        </div> -->

                        <div class="w-100 mb-0">
                            <div class="row pb-0 ">
                                <div class=" col-12 col-md-6 pb-5 form-group">
                                    <label class="text-primary">Contraseña Actual</label>
                                    <input formControlName="currentPassword" class="form-control form-control-lg" type="password" >
                                    <errors-component [controlName]="passwordForm.controls['currentPassword']"></errors-component>
                                </div>
                                
                                <div class=" col-12 col-md-6 pb-5 form-group">
                                    <label class="text-primary">Nueva Contraseña</label>
                                    <input formControlName="newPassword" class="form-control form-control-lg" type="password" >
                                    <errors-component [controlName]="passwordForm.controls['newPassword']"></errors-component>
                                </div>

                                <div class=" col-12 col-md-6 pb-5 form-group">
                                    <label class="text-primary">Confirmar Contraseña</label>
                                    <input formControlName="confirmPassword" class="form-control form-control-lg" type="password" >
                                    <span class="text-danger" *ngIf="!passwordMatch() && passwordForm.get('confirmPassword').touched">Las contraseñas no coinciden</span>
                                </div>

                                <div class=" col-12 col-md-6 pb-5 form-group">
                                    <label class="text-primary">Código de validación</label>
                                    <input formControlName="validationCode" class="form-control form-control-lg" type="text" >
                                    <errors-component [controlName]="passwordForm.controls['validationCode']"></errors-component>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="separator separator-solid my-7"></div>
        </div>
        <div class="row">
            <div class=" d-flex justify-content-end">
                <button (click)="closeModal()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3" data-dismiss="modal">
                    <i class="flaticon2-cross"></i>
                    <span>Cerrar</span>
                </button>
                <button [disabled]="passwordForm.invalid|| !passwordMatch()" (click)="changePassword()" class="btn btn-pill font-weight-bolder btn-shadow btn-success" type="submit">
                    <i class="flaticon-disco-flexible"></i>
                    <span>Guardar</span>
                    <!-- <span>Guardar</span> -->
                </button>
            </div>
        </div>  
    </form>
    
</div>
    