<div class="row pt-5 gutter-b">
    <div class="col-sm-12 col-lg-4">
        <div class="form-group">
            <label>Tipo de Aplicación</label>
            <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="dropdownSettings"
            [data]="statusOptions" [(ngModel)]="selectedItems">
            </ng-multiselect-dropdown> 
        </div>
    </div>
    <div class="col-sm-12 col-lg-4">
        <div class="form-group">
            <label>Ámbito de Aplicación</label>
            <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="dropdownSettings"
            [data]="statusOptions" [(ngModel)]="selectedItems">
            </ng-multiselect-dropdown> 
        </div>
    </div>
    <div class="col-sm-12 col-lg-4">
        <div class="form-group">
            <label>Tipo de Categoria</label>
            <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="dropdownSettings"
            [data]="statusOptions" [(ngModel)]="selectedItems">
            </ng-multiselect-dropdown> 
        </div>
    </div>
    <div class="col-sm-12 col-lg-4">
        <div class="d-flex text-primary border border-primary rounded justify-content-between" style="margin-top: 2.2rem; padding: .6rem;">
            <label class="h6 mt-1">¿Proporcional dias de trabajo?</label>
            <div class="d-flex">
                <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                <div class="flex-shrink-0 mx-2">
                    <span class="switch switch-sm switch-primary">
                        <label>
                            <input class="cursor-pointer abc-checkbox-primary" type="checkbox" />
                            <span></span>
                        </label>
                    </span>
                </div>
                <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
            </div>      
        </div>
    </div>
    <div class="col-sm-12 col-lg-4">
        <div class="d-flex text-primary border border-primary rounded justify-content-between" style="margin-top: 2.2rem; padding: .6rem;">
            <label class="h6 mt-1">¿Proporcional Porcentaje?</label>
            <div class="d-flex">
                <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                <div class="flex-shrink-0 mx-2">
                    <span class="switch switch-sm switch-primary">
                        <label>
                            <input class="cursor-pointer abc-checkbox-primary" type="checkbox" />
                            <span></span>
                        </label>
                    </span>
                </div>
                <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
            </div>      
        </div>
    </div>
</div>

<!--div class="mat-dialog-actions mt-2 p-0 mb-0 text-right" [align]="'end'">
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div-->