<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Datos a procesar
    </h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content">
    <div class="table-responsive">
        <table class="table table-hover table-vertical-center" style="table-layout: fixed;">
            <thead>
                <tr class="bg-primary">
                    <th class="text-left" style="width: 160px;">Institución</th>
                    <th class="text-left" style="width: 160px;">Departamento</th>
                    <th class="text-left" style="width: 200px;">Grupo ocupacional</th>
                    <th class="text-left" style="width: 200px;">Tipo contrato</th>
                    <th class="text-left" style="width: 200px;">Tipo empleado</th>
                    <th class="text-left" style="width: 200px;">Cargo</th>
                    <th class="text-left" style="width: 120px;">Fecha de Ingreso</th>
                    <th class="text-left" style="width: 120px;">Estatus</th>
                    <th class="text-left" style="width: 180px;">Tipo de documento</th>
                    <th class="text-left" style="width: 160px;">Número de documento</th>
                    <th class="text-left" style="width: 120px;">Nombre</th>
                    <th class="text-left" style="width: 120px;">Apellido</th>
                    <th class="text-left" style="width: 150px;">Géneo</th>
                    <th class="text-left" style="width: 120px;">Fecha de nacimiento</th>
                    <th class="text-left" style="width: 150px;">Estado civil</th>
                    <th class="text-left" style="width: 180px;">Cuenta bancaria</th>
                    <th class="text-left" style="width: 200px;">Banco</th>
                    <th class="text-right" style="width: 150px;">Sueldo bruto</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-left">
                        ITLA CALETA
                    </td>
                    <td class="text-left">
                        Finanzas
                    </td>
                    <td class="text-left">
                        Grupo ocupacional I
                    </td>
                    <td class="text-left">
                        Tipo de contrato A
                    </td>
                    <td class="text-left">
                        Tipo de empleado A
                    </td>
                    <td class="text-left">
                        Gerente general
                    </td>
                    <td class="text-left">
                        15/dic/2023
                    </td>
                    <td class="text-left">
                        En proceso
                    </td>
                    <td class="text-left">
                        Cédula
                    </td>
                    <td class="text-left">
                        000-0000000-0
                    </td>
                    <td class="text-left">
                        Julisy
                    </td>
                    <td class="text-left">
                        Amador
                    </td>
                    <td class="text-left">
                        Masculino
                    </td>
                    <td class="text-left">
                        15/Ene/1980
                    </td>
                    <td class="text-left">
                        Soltero
                    </td>
                    <td class="text-left">
                        XXXXX-15055
                    </td>
                    <td class="text-left">
                        Banco HD Leon
                    </td>
                    <td class="text-right">
                        RD$ 120,000.00
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        ITLA CALETA
                    </td>
                    <td class="text-left">
                        Finanzas
                    </td>
                    <td class="text-left">
                        Grupo ocupacional I
                    </td>
                    <td class="text-left">
                        Tipo de contrato A
                    </td>
                    <td class="text-left">
                        Tipo de empleado A
                    </td>
                    <td class="text-left">
                        Gerente general
                    </td>
                    <td class="text-left">
                        15/dic/2023
                    </td>
                    <td class="text-left">
                        En proceso
                    </td>
                    <td class="text-left">
                        Cédula
                    </td>
                    <td class="text-left">
                        000-0000000-0
                    </td>
                    <td class="text-left">
                        Julisy
                    </td>
                    <td class="text-left">
                        Amador
                    </td>
                    <td class="text-left">
                        Masculino
                    </td>
                    <td class="text-left">
                        15/Ene/1980
                    </td>
                    <td class="text-left">
                        Soltero
                    </td>
                    <td class="text-left">
                        XXXXX-15055
                    </td>
                    <td class="text-left">
                        Banco HD Leon
                    </td>
                    <td class="text-right">
                        RD$ 120,000.00
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        ITLA CALETA
                    </td>
                    <td class="text-left">
                        Finanzas
                    </td>
                    <td class="text-left">
                        Grupo ocupacional I
                    </td>
                    <td class="text-left">
                        Tipo de contrato A
                    </td>
                    <td class="text-left">
                        Tipo de empleado A
                    </td>
                    <td class="text-left">
                        Gerente general
                    </td>
                    <td class="text-left">
                        15/dic/2023
                    </td>
                    <td class="text-left">
                        En proceso
                    </td>
                    <td class="text-left">
                        Cédula
                    </td>
                    <td class="text-left">
                        000-0000000-0
                    </td>
                    <td class="text-left">
                        Julisy
                    </td>
                    <td class="text-left">
                        Amador
                    </td>
                    <td class="text-left">
                        Masculino
                    </td>
                    <td class="text-left">
                        15/Ene/1980
                    </td>
                    <td class="text-left">
                        Soltero
                    </td>
                    <td class="text-left">
                        XXXXX-15055
                    </td>
                    <td class="text-left">
                        Banco HD Leon
                    </td>
                    <td class="text-right">
                        RD$ 120,000.00
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        ITLA CALETA
                    </td>
                    <td class="text-left">
                        Finanzas
                    </td>
                    <td class="text-left">
                        Grupo ocupacional I
                    </td>
                    <td class="text-left">
                        Tipo de contrato A
                    </td>
                    <td class="text-left">
                        Tipo de empleado A
                    </td>
                    <td class="text-left">
                        Gerente general
                    </td>
                    <td class="text-left">
                        15/dic/2023
                    </td>
                    <td class="text-left">
                        En proceso
                    </td>
                    <td class="text-left">
                        Cédula
                    </td>
                    <td class="text-left">
                        000-0000000-0
                    </td>
                    <td class="text-left">
                        Julisy
                    </td>
                    <td class="text-left">
                        Amador
                    </td>
                    <td class="text-left">
                        Masculino
                    </td>
                    <td class="text-left">
                        15/Ene/1980
                    </td>
                    <td class="text-left">
                        Soltero
                    </td>
                    <td class="text-left">
                        XXXXX-15055
                    </td>
                    <td class="text-left">
                        Banco HD Leon
                    </td>
                    <td class="text-right">
                        RD$ 120,000.00
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        ITLA CALETA
                    </td>
                    <td class="text-left">
                        Finanzas
                    </td>
                    <td class="text-left">
                        Grupo ocupacional I
                    </td>
                    <td class="text-left">
                        Tipo de contrato A
                    </td>
                    <td class="text-left">
                        Tipo de empleado A
                    </td>
                    <td class="text-left">
                        Gerente general
                    </td>
                    <td class="text-left">
                        15/dic/2023
                    </td>
                    <td class="text-left">
                        En proceso
                    </td>
                    <td class="text-left">
                        Cédula
                    </td>
                    <td class="text-left">
                        000-0000000-0
                    </td>
                    <td class="text-left">
                        Julisy
                    </td>
                    <td class="text-left">
                        Amador
                    </td>
                    <td class="text-left">
                        Masculino
                    </td>
                    <td class="text-left">
                        15/Ene/1980
                    </td>
                    <td class="text-left">
                        Soltero
                    </td>
                    <td class="text-left">
                        XXXXX-15055
                    </td>
                    <td class="text-left">
                        Banco HD Leon
                    </td>
                    <td class="text-right">
                        RD$ 120,000.00
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        ITLA CALETA
                    </td>
                    <td class="text-left">
                        Finanzas
                    </td>
                    <td class="text-left">
                        Grupo ocupacional I
                    </td>
                    <td class="text-left">
                        Tipo de contrato A
                    </td>
                    <td class="text-left">
                        Tipo de empleado A
                    </td>
                    <td class="text-left">
                        Gerente general
                    </td>
                    <td class="text-left">
                        15/dic/2023
                    </td>
                    <td class="text-left">
                        En proceso
                    </td>
                    <td class="text-left">
                        Cédula
                    </td>
                    <td class="text-left">
                        000-0000000-0
                    </td>
                    <td class="text-left">
                        Julisy
                    </td>
                    <td class="text-left">
                        Amador
                    </td>
                    <td class="text-left">
                        Masculino
                    </td>
                    <td class="text-left">
                        15/Ene/1980
                    </td>
                    <td class="text-left">
                        Soltero
                    </td>
                    <td class="text-left">
                        XXXXX-15055
                    </td>
                    <td class="text-left">
                        Banco HD Leon
                    </td>
                    <td class="text-right">
                        RD$ 120,000.00
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        ITLA CALETA
                    </td>
                    <td class="text-left">
                        Finanzas
                    </td>
                    <td class="text-left">
                        Grupo ocupacional I
                    </td>
                    <td class="text-left">
                        Tipo de contrato A
                    </td>
                    <td class="text-left">
                        Tipo de empleado A
                    </td>
                    <td class="text-left">
                        Gerente general
                    </td>
                    <td class="text-left">
                        15/dic/2023
                    </td>
                    <td class="text-left">
                        En proceso
                    </td>
                    <td class="text-left">
                        Cédula
                    </td>
                    <td class="text-left">
                        000-0000000-0
                    </td>
                    <td class="text-left">
                        Julisy
                    </td>
                    <td class="text-left">
                        Amador
                    </td>
                    <td class="text-left">
                        Masculino
                    </td>
                    <td class="text-left">
                        15/Ene/1980
                    </td>
                    <td class="text-left">
                        Soltero
                    </td>
                    <td class="text-left">
                        XXXXX-15055
                    </td>
                    <td class="text-left">
                        Banco HD Leon
                    </td>
                    <td class="text-right">
                        RD$ 120,000.00
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        ITLA CALETA
                    </td>
                    <td class="text-left">
                        Finanzas
                    </td>
                    <td class="text-left">
                        Grupo ocupacional I
                    </td>
                    <td class="text-left">
                        Tipo de contrato A
                    </td>
                    <td class="text-left">
                        Tipo de empleado A
                    </td>
                    <td class="text-left">
                        Gerente general
                    </td>
                    <td class="text-left">
                        15/dic/2023
                    </td>
                    <td class="text-left">
                        En proceso
                    </td>
                    <td class="text-left">
                        Cédula
                    </td>
                    <td class="text-left">
                        000-0000000-0
                    </td>
                    <td class="text-left">
                        Julisy
                    </td>
                    <td class="text-left">
                        Amador
                    </td>
                    <td class="text-left">
                        Masculino
                    </td>
                    <td class="text-left">
                        15/Ene/1980
                    </td>
                    <td class="text-left">
                        Soltero
                    </td>
                    <td class="text-left">
                        XXXXX-15055
                    </td>
                    <td class="text-left">
                        Banco HD Leon
                    </td>
                    <td class="text-right">
                        RD$ 120,000.00
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        ITLA CALETA
                    </td>
                    <td class="text-left">
                        Finanzas
                    </td>
                    <td class="text-left">
                        Grupo ocupacional I
                    </td>
                    <td class="text-left">
                        Tipo de contrato A
                    </td>
                    <td class="text-left">
                        Tipo de empleado A
                    </td>
                    <td class="text-left">
                        Gerente general
                    </td>
                    <td class="text-left">
                        15/dic/2023
                    </td>
                    <td class="text-left">
                        En proceso
                    </td>
                    <td class="text-left">
                        Cédula
                    </td>
                    <td class="text-left">
                        000-0000000-0
                    </td>
                    <td class="text-left">
                        Julisy
                    </td>
                    <td class="text-left">
                        Amador
                    </td>
                    <td class="text-left">
                        Masculino
                    </td>
                    <td class="text-left">
                        15/Ene/1980
                    </td>
                    <td class="text-left">
                        Soltero
                    </td>
                    <td class="text-left">
                        XXXXX-15055
                    </td>
                    <td class="text-left">
                        Banco HD Leon
                    </td>
                    <td class="text-right">
                        RD$ 120,000.00
                    </td>
                </tr>
                <tr>
                    <td class="text-left">
                        ITLA CALETA
                    </td>
                    <td class="text-left">
                        Finanzas
                    </td>
                    <td class="text-left">
                        Grupo ocupacional I
                    </td>
                    <td class="text-left">
                        Tipo de contrato A
                    </td>
                    <td class="text-left">
                        Tipo de empleado A
                    </td>
                    <td class="text-left">
                        Gerente general
                    </td>
                    <td class="text-left">
                        15/dic/2023
                    </td>
                    <td class="text-left">
                        En proceso
                    </td>
                    <td class="text-left">
                        Cédula
                    </td>
                    <td class="text-left">
                        000-0000000-0
                    </td>
                    <td class="text-left">
                        Julisy
                    </td>
                    <td class="text-left">
                        Amador
                    </td>
                    <td class="text-left">
                        Masculino
                    </td>
                    <td class="text-left">
                        15/Ene/1980
                    </td>
                    <td class="text-left">
                        Soltero
                    </td>
                    <td class="text-left">
                        XXXXX-15055
                    </td>
                    <td class="text-left">
                        Banco HD Leon
                    </td>
                    <td class="text-right">
                        RD$ 120,000.00
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2 " (click)="close()" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
       <i class="flaticon-disco-flexible"></i>
       <span>Procesar</span>
   </button>
</div>