<ng-container>
    <div class="container bg-white pt-3">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-custom border-primary p-0">
                    <div class="card-header mt-3">
                        <div class="card-title align-items-center">
                            <h3 class="text-primary m-0">Teléfonos</h3>
                        </div>
                        <div class="card-toolbar">
                            <button class="btn btn-pill btn-shadow btn-success mr-3" type="button"
                                (click)="openAddPhoneModal()">
                                <i class="flaticon2-plus"></i>
                                <span> Agregar teléfono</span>
                            </button>
                        </div>
                    </div>
                    <div class="card-body p-0 min-h-100px">
                        <div class="table-responsive">
                            <table class="table mt-2 table-vertical-center">
                                <thead>
                                    <tr class="bg-primary">
                                        <th style="width: 200px;">Tipo de Teléfono</th>
                                        <th style="width: 390px;">Número</th>
                                        <th style="width: 390px;">Contacto Primario</th>
                                        <th style="width: 390px;">Extensión</th>
                                        <th class="actionsBtn">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let phone of phoneList; let i = index">
                                        <td>{{getType(phone.type)}}</td>
                                        <td>{{phone.description | mask: '(000) 000-0000'}} </td>
                                        <td>{{(phone.principal == 1) ? 'Primario' : 'Secundario'}} </td>
                                        <td>{{(phone.phoneExtension) || 'N/A'}} </td>
                                        <td class="actionsBtn">
                                            <button type="button" class="btn btn-sm btn-light-warning btn-icon mr-2"
                                                data-title="Editar"
                                                (click)="openAddPhoneModal(true, phone, i)">
                                                <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                                            </button>
                                            <button type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                                data-title="Remover" (click)="removePhone(i)">
                                                <i class="flaticon-delete" data-toggle="tooltip" title="Eliminar"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 gutter-t gutter-b">
                <div class="card card-custom border-primary p-0">
                    <div class="card-header mt-3">
                        <div class="card-title align-items-center">
                            <h3 class="text-primary m-0">Correos</h3>
                        </div>
                        <div class="card-toolbar">
                            <button class="btn btn-pill btn-shadow btn-success mr-3" type="button"
                                 (click)="openAddEmailModal()">
                                <i class="flaticon2-plus"></i>
                                <span> Agregar correo</span>
                            </button>
                        </div>
                    </div>
                    <div class="card-body p-0 min-h-100px">
                        <div class="table-responsive">
                            <table class="table mt-2 table-vertical-center">
                                <thead>
                                    <tr class="bg-primary">
                                        <th style="width: 200px;">Tipo de Correo</th>
                                        <th class="text-wrap" style="width: 390px;">Correo electrónico</th>
                                        <th style="width: 390px;">Correo Primario</th>
                                        <th class="actionsBtn">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let email of phoneListCorreo; let i = index">
                                        <td>{{getTypeEmail(email.type)}}</td>
                                        <td class="text-wrap" style="max-width: 390px;">{{email.description}} </td>
                                        <td>{{(email.principal == 1) ? 'Primario' : 'Secundario'}} </td>
                                        <td class="actionsBtn">
                                            <button type="button" class="btn btn-sm btn-light-warning btn-icon mr-2"
                                                data-title="Editar"
                                                (click)="openAddEmailModal(true, email, i);    this.changeDetector();">
                                                <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                                            </button>
                                            <button type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                                data-title="Remover" (click)="removeCorreo(i)">
                                                <i class="flaticon-delete" data-toggle="tooltip"
                                                    data-title="Eliminar"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- <el-modal target="addPhone" title="Agregar Teléfonos">
    <div class="row">
        <div class="form-group col-md-6">
            <label class="d-block w-100">Tipo de teléfono <span class="text-danger">*</span></label>
            <div class="dropdown-alt input-group">
                <select class="form-control  form-control-solid form-control-lg form-select" [(ngModel)]="type">
                    <option *ngFor="let m of TypePhoneList" [ngValue]="m.ocode">{{m.stringData}}</option>
                </select>
            </div>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-6">
            <label for="number">Número de teléfono <span class="text-danger">*</span></label>
            <input class="form-control form-control-solid form-control-lg" id="tel" name="tel" mask="(000)-000-0000"
                type="mask" placeholder="Ej: 809-000-0000" label="Teléfono" [(ngModel)]="description" autocomplete="0">

            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-6">
            <label for="" class="d-block">¿Contacto Primario? <span class="text-danger">*</span></label>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input" required="true" name="principalYes" type="radio"
                        [value]="principal.yes" [(ngModel)]="isPrincipal">
                    Sí
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input" required="true" name="principalNo" type="radio"
                        [value]="principal.no" [(ngModel)]="isPrincipal"> No
                </label>
            </div>
        </div>
        <div class="form-group col-md-6">
            <label for="number">Extensión </label>
            <input appNumbersOnly [allowDecimals]="false" [allowSign]="false"
                class="form-control form-control-solid form-control-lg" type="text" [(ngModel)]="phoneExtension"
                autocomplete="0">

        </div>
        <div class="form-group gutter-t col-md-12 d-flex justify-content-end">
            <button id="btnAddPhone" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2"
                data-dismiss="modal" type="button">
                <i class="flaticon2-cross"></i>
                <span>Cerrar</span>
            </button>
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
                [disabled]="description == ''">
                <i class="flaticon-disco-flexible"></i>
                <span> Agregar </span>
            </button>
        </div>
    </div>
</el-modal>

<el-modal target="addEmail" title="Agregar Correos">
    <div class="row">
        <div class="form-group col-md-6">
            <label class="d-block w-100">Tipo de correo <span class="text-danger">*</span></label>
            <div class="dropdown-alt input-group">
                <select class="form-control  form-control-solid form-control-lg form-select" [(ngModel)]="typeCorreo">
                    <option *ngFor="let m of TypeEmailList" [ngValue]="m.ocode">{{m.stringData}}</option>
                </select>
            </div>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-6">
            <label for="number">Correo electrónico <span class="text-danger">*</span></label>
            <el-input id="tel" (getValue)="isEmailValid($event)" placeholder="Correo electrónico" name="tel" type="text"
                [(ngModel)]="descriptionCorreo" autocomplete="false">
            </el-input>
            <span class="text-danger" *ngIf="showEmailError">Debe ingresar un correo válido</span>
        </div>
        <div class="form-group col-md-6">
            <label for="" class="d-block">Correo Primario? <span class="text-danger">*</span></label>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input" required="true" name="principalCorreoYes" type="radio"
                        [value]="principalCorreo.yes" [(ngModel)]="isPrincipalCorreo"> Sí
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input class="form-check-input" required="true" name="principalCorreoNo" type="radio"
                        [value]="principalCorreo.no" [(ngModel)]="isPrincipalCorreo"> No
                </label>
            </div>
        </div>
        <div class="form-group gutter-t col-md-12 d-flex justify-content-end">
            <button id="btnAddEmail" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2"
                data-dismiss="modal" type="button">
                <i class="flaticon2-cross"></i>
                <span>Cerrar</span>
            </button>
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
                 [disabled]="descriptionCorreo == ''">
                <i class="flaticon-disco-flexible"></i>
                <span> Agregar </span>
            </button>
        </div>
    </div>
</el-modal> -->