<ng-container>
    <div class="card card-custom ">
        <div class="card-header mt-3">
            <div class="card-title">
                <span class="card-icon">
                    <i class="flaticon-retocar icon-3x text-danger"></i>
                </span>
                <h1>
                    Habilitar Vacantes
                    <!-- <small>sub title</small> -->
                </h1>
            </div>
            <div class="card-toolbar">

                <button routerLink="/vacancies" class="btn btn-pill font-weight-bolder btn-shadow btn-warning">
                    <i class="flaticon2-left-arrow-1"></i>
                    <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
                </button>

            </div>
        </div>
        <div class="card-body dtl_font">
            <fieldset>
                <form [formGroup]='vacanciesForm'>
                    <div class="row gutter-b">
                        <div class="col-md-7 col-lg-9">
                            <div class="border-primary rounded-lg p-5">
                                <div class="row">
                                    <div class="form-group col-lg-4 danger">
                                        <label>Vacante</label>
                                        <input type="text" readonly class="form-control form-control-lg"
                                            formControlName='positionName'>
                                    </div>
                                    <div class="form-group col-lg-4 danger">
                                        <label>Id de la Plaza</label>
                                        <input type="text" readonly class="form-control form-control-lg"
                                            formControlName='plaza'>
                                    </div>
                                    <div class="form-group col-lg-4 danger">
                                        <label class="">Sucursal</label>
                                        <ngx-select-dropdown
                                            [ngClass]="{danger: this.vacanciesForm.controls.enclousure.invalid}"
                                            class="flex-1" formControlName='enclousure' [options]='locations'
                                            [config]='enclousureConfig'></ngx-select-dropdown>
                                    </div>

                                    <div class="form-group col-lg-4">
                                        <label>Unidad Organizativa
                                        </label>
                                        <input type="text" readonly class="form-control form-control-lg"
                                            formControlName='department'>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label>Edad Mínima
                                        </label>
                                        <input readonly type="number"
                                            class="form-control form-control-solid form-control-lg"
                                            formControlName='minimumAge'>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label>Edad Máxima
                                        </label>
                                        <input readonly type="number"
                                            class="form-control form-control-solid form-control-lg"
                                            formControlName='maximumAge'>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label class="">Causa de la Plaza Vacante</label>
                                        <ngx-select-dropdown class=" flex-1" [options]='vacancyCause' [config]='config'
                                            formControlName='vacancyCause'></ngx-select-dropdown>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label>Experiencia
                                        </label>
                                        <input readonly type="text"
                                            class="form-control form-control-solid form-control-lg"
                                            formControlName='experience'>
                                    </div>

                                    <div class="form-group col-lg-4">
                                        <label class="">Fecha de Ingreso</label>
                                        <input readonly type="date"
                                            class="form-control form-control-solid form-control-lg"
                                            formControlName='startDate'>
                                    </div>

                                    <div class="form-group col-lg-4">
                                        <label class="">Categoría del Cargo</label>
                                        <ngx-select-dropdown class=" flex-1" [options]='requisitionsType'
                                            [config]='config' formControlName='requisitionType'></ngx-select-dropdown>
                                    </div>
                                    <div *ngIf="showAdmissionEndingContract" class="form-group col-lg-4">
                                        <div>
                                            <label for="dateRequest">Inicio Vigencia Contrato
                                                <span class="text-danger text-monospace legend">*</span></label>
                                        </div>
                                        <input class="form-control form-control-lg dynamicFormControl" type="date"
                                            formControlName="contractAdmissionDate" />
                                    </div>

                                    <div *ngIf="showAdmissionEndingContract" class="form-group col-lg-4">
                                        <div>
                                            <label for="dateRequest">Fin Vigencia Contrato
                                                <span class="text-danger text-monospace legend">*</span></label>
                                        </div>
                                        <input class="form-control form-control-lg dynamicFormControl" type="date"
                                            formControlName="contractEndingDate" />
                                    </div>



                                    <div class="form-group col-lg-4">
                                        <label class="">Nivel Académico</label>
                                        <ngx-select-dropdown class=" flex-1" [options]='academicLevels'
                                            [config]='config' formControlName='academicLevel'></ngx-select-dropdown>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label>Horas Trabajadas</label>
                                        <ngx-select-dropdown class=" flex-1" [options]='hoursWorked' [config]='config'
                                            formControlName='hoursWorked'></ngx-select-dropdown>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label>Tipo de vacante<span class="text-danger text-monospace legend"> *</span>
                                        </label>
                                        <ngx-select-dropdown class=" flex-1" [options]='vacancyTypes'
                                            [config]='VacancyTypesconfig' formControlName='vacancyType'>
                                        </ngx-select-dropdown>
                                    </div>
                                    <div class="form-group col-lg-5">
                                        <label class="d-block">Sexo</label>
                                        <div class="form-check form-check-inline " *ngFor="let gender of genders">
                                            <label class="form-check-label mt-2 check-dtl plz">
                                                <input readonly class="form-check-input" required="" name="sex"
                                                    type="radio" value={{gender.ocode}} formControlName='sex'>
                                                {{gender.stringData}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="form-group col-lg-4">
                                        <div>
                                            <label for="exampleFormControlTextarea1" class="d-block">Requerimientos
                                                deseables</label>
                                        </div>
                                        <textarea readonly formControlName="desirableRequirements"
                                            class="form-control form-control-lg dynamicFormControl"></textarea>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label>Observaciones </label>
                                        <textarea readonly formControlName='observations'
                                            class="form-control form-control-solid form-control-lg"></textarea>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label>Otros Requisitos</label>
                                        <textarea readonly class="form-control form-control-solid form-control-lg"
                                            formControlName='requirements'></textarea>
                                    </div>
                                    <div class="form-group col-lg-12">
                                        <label>Funciones o Tareas</label>
                                        <textarea readonly class="form-control form-control-solid form-control-lg"
                                            formControlName='jobFunctions'></textarea>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="col-md-5 col-lg-3">
                            <div class="form-group">
                                <label>Evidencia</label>
                                <div class="card align-items-center">
                                    <uppy-file #uppyFile
                                    [isDisabled]="vacanciesDTO?.published"
                                    previewDefaultImage="assets/images/ver_file.jpg"
                                    classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px"
                                    [cleanAll]="false"
                                    [allowedFileTypes]="['application/pdf', 'image/*']"
                                    [fileForEditting]="vacanciesForm.get('documentId')?.value">
                                    </uppy-file>
                                </div> 
                            </div>
                        </div>
                    </div>

                    <div class="row vacancy-options">
                        <div class="col-xl-4 col-lg-12">
                            <div class="card card-custom border-primary gutter-b">
                                <div class="card-header mt-3">
                                    <div class="card-title align-items-center">
                                        <i class="flaticon-gestion-de-proyectos icon-3x text-primary mr-3"></i>
                                        <h3 class="text-primary m-0">Habilidades Deseables</h3>
                                    </div>
                                    <div class="card-toolbar ml-auto">
                                        <a *ngIf="!inView" (click)='openSkill()'
                                            class="btn btn-pill font-weight-bolder btn-shadow btn-success">
                                            <i class="flaticon2-plus"></i> Agregar
                                        </a>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive">
                                        <table class="table mt-2 table-vertical-center text-center">
                                            <thead>
                                                <tr class="bg-primary">
                                                    <th>Competencias</th>
                                                    <th>Tipo</th>
                                                    <th>Nivel</th>
                                                    <th class="text-center">Acción</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let skills of selectedSkills; let index = index">
                                                    <td>{{skills.skill.description}}</td>
                                                    <td>{{skills.typelevel.description}}</td>
                                                    <td>{{skills.skillLevel.description}}</td>
                                                    <td class="text-center">
                                                        <a *ngIf="inView != true"
                                                            class="btn btn-sm btn-light-danger btn-icon mr-2"
                                                            (click)="Delete('skills',index)" data-toggle="tooltip"
                                                            data-original-title="Remover">
                                                            <i class="flaticon-delete"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="d-flex justify-content-end align-items-center flex-wrap">
                                            <div class="d-flex align-items-center py-3 mr-2"><select
                                                    class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light ng-untouched ng-pristine ng-valid"
                                                    style="width: 75px;" ng-reflect-model="10">
                                                    <option value="1" ng-reflect-value="1">1</option>
                                                    <option value="5" ng-reflect-value="5">5</option>
                                                    <option value="10" ng-reflect-value="10">10</option>
                                                    <option value="15" ng-reflect-value="15">15</option>
                                                    <option value="20" ng-reflect-value="20">20</option>
                                                </select><span class="text-muted">Mostrando página 1 de 1 </span></div>
                                            <div class="d-flex flex-wrap py-2 mr-3">
                                                <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1"
                                                    disabled="">
                                                    <i class="ki ki-bold-arrow-back icon-xs"></i></button>
                                                <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1"
                                                    disabled="">
                                                    <i class="ki ki-bold-arrow-next icon-xs"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-12">
                            <div class="card card-custom border-primary gutter-b">
                                <div class="card-header mt-3">
                                    <div class="card-title align-items-center">
                                        <i class="flaticon-mortarboard icon-3x text-primary mr-3"></i>
                                        <h3 class="text-primary m-0">Carreras Deseables</h3>
                                    </div>
                                    <div class="card-toolbar ml-auto">
                                        <a *ngIf="!inView" (click)='openCarreer()'
                                            class="btn btn-pill font-weight-bolder btn-shadow btn-success">
                                            <i class="flaticon2-plus"></i> Agregar
                                        </a>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive">
                                        <table class="table mt-2 table-vertical-center text-center">
                                            <thead>
                                                <tr class="bg-primary">
                                                    <th class="text-left">Áreas de estudio</th>
                                                    <th class="text-center">Acción</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let career of selectedCareers; let index = index">
                                                    <td class="text-left">{{career.description}}</td>
                                                    <td class="text-center">
                                                        <a *ngIf="inView != true"
                                                            class="btn btn-sm btn-light-danger btn-icon mr-2"
                                                            (click)="Delete('careers',index)" data-toggle="tooltip"
                                                            data-original-title="Remover">
                                                            <i class="flaticon-delete"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="d-flex justify-content-end align-items-center flex-wrap">
                                            <div class="d-flex align-items-center py-3 mr-2"><select
                                                    class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light ng-untouched ng-pristine ng-valid"
                                                    style="width: 75px;" ng-reflect-model="10">
                                                    <option value="1" ng-reflect-value="1">1</option>
                                                    <option value="5" ng-reflect-value="5">5</option>
                                                    <option value="10" ng-reflect-value="10">10</option>
                                                    <option value="15" ng-reflect-value="15">15</option>
                                                    <option value="20" ng-reflect-value="20">20</option>
                                                </select><span class="text-muted">Mostrando página 1 de 1 </span></div>
                                            <div class="d-flex flex-wrap py-2 mr-3">
                                                <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1"
                                                    disabled="">
                                                    <i class="ki ki-bold-arrow-back icon-xs"></i></button>
                                                <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1"
                                                    disabled="">
                                                    <i class="ki ki-bold-arrow-next icon-xs"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-12">
                            <div class="card card-custom border-primary gutter-b">
                                <div class="card-header mt-3">
                                    <div class="card-title align-items-center">
                                        <i class="flaticon-riesgo icon-3x text-primary mr-3"></i>
                                        <h3 class="text-primary m-0">Beneficios de la Plaza</h3>
                                    </div>
                                    <div class="card-toolbar ml-auto">
                                        <a *ngIf="!inView" (click)='openBenefit()'
                                            class="btn btn-pill font-weight-bolder btn-shadow btn-success">
                                            <i class="flaticon2-plus"></i> Agregar
                                        </a>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive">
                                        <table class="table mt-2 table-vertical-center text-center">
                                            <thead>
                                                <tr class="bg-primary">
                                                    <th>Beneficios</th>
                                                    <th class="text-center">Acción</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let benefit of selectedBenefits; let index = index">
                                                    <td>{{benefit.description}}</td>
                                                    <td class="text-center">
                                                        <a *ngIf="inView != true"
                                                            class="btn btn-sm btn-light-danger btn-icon mr-2"
                                                            (click)="Delete('benefits',index)" data-toggle="tooltip"
                                                            data-original-title="Remover">
                                                            <i class="flaticon-delete"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="d-flex justify-content-end align-items-center flex-wrap">
                                            <div class="d-flex align-items-center py-3 mr-2"><select
                                                    class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light ng-untouched ng-pristine ng-valid"
                                                    style="width: 75px;" ng-reflect-model="10">
                                                    <option value="1" ng-reflect-value="1">1</option>
                                                    <option value="5" ng-reflect-value="5">5</option>
                                                    <option value="10" ng-reflect-value="10">10</option>
                                                    <option value="15" ng-reflect-value="15">15</option>
                                                    <option value="20" ng-reflect-value="20">20</option>
                                                </select><span class="text-muted">Mostrando página 1 de 1 </span></div>
                                            <div class="d-flex flex-wrap py-2 mr-3">
                                                <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1"
                                                    disabled="">
                                                    <i class="ki ki-bold-arrow-back icon-xs"></i></button>
                                                <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1"
                                                    disabled="">
                                                    <i class="ki ki-bold-arrow-next icon-xs"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </fieldset>
            <div class="card-footer d-flex justify-content-between">
                <a [routerLink]="['/vacancies']" class="btn btn-light-primary font-weight-bold">Ir a Lista</a>
                <button *ngIf="!inView" [disabled]='vacanciesForm.touched && vacanciesForm.invalid'
                    (click)="updateVacants()" type="submit"
                    class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success">
                    <i class="flaticon-disco-flexible"></i> Publicar
                </button>
            </div>
        </div>
    </div>
</ng-container>