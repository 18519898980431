import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent {
  yesnot = false;
  btnAceptar = 'Aceptar';
  btnCancelar = 'Cancelar';
   constructor( public dialogRef: MatDialogRef<ConfirmationComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogRef.disableClose = true;
    if (data.yesno) {
      this.btnAceptar = 'Si';
      this.btnCancelar = 'No';
    }
  }

  Accept(): void {
    this.dialogRef.close(true);
  }

  Cancel(): void {
    this.dialogRef.close(false);
  }
}

