<h2 class="mat-dialog-title">Filtrar por fecha</h2>
    <div class="container p-5">
        <form [formGroup]="filterForm">
            <div class="row mb-4">
                <div class="col-md-12">
                    <label for="" class="fw-bold">Año</label>
                    <select class="form-select" formControlName="year" name="" id="" (change)="onYearChange($event.target.value)">
                        <option [selected]="true" [value]="" disabled>Seleccionar</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                    </select>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-md-6">
                    <label class="d-block fw-bold">Desde</label>
                    <input type="date" formControlName="dateFrom" class="form-control" name="" id="">
                </div>
                <div class="col-md-6">
                    <label class="d-block fw-bold">Hasta</label>
                    <input type="date" formControlName="dateTo" class="form-control" name="" id="">
                </div>
            </div>
            <button [disabled]="filterForm.invalid" class="btn btn-success fw-bold" type="button" (click)="applyFilter()">
                <i class="flaticon-disco-flexible"></i>
                <span>Aplicar filtro</span>
            </button>
            <button class="btn btn-light-warning ml-4" (click)="clear()" >
                <i class="fas fa-redo"></i>
              </button>
        </form>
    </div>

<div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="close()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>