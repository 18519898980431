import { DatePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

declare const $: any;
@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css']
})
export class DateFilterComponent implements OnInit {

  filterForm = new FormGroup({
    year: new FormControl(''),
    dateFrom: new FormControl(''),
    dateTo: new FormControl(''),
  })
  invalidFilter:boolean = false
  datePipe =  new DatePipe('en-Us')

  constructor(public dialogRef: MatDialogRef<DateFilterComponent>,
    private _changeDet: ChangeDetectorRef) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  close(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

  onYearChange(year: string) {
    this.filterForm.get('dateFrom').setValue(this.datePipe.transform(`${year}/01/01`,'yyyy-MM-dd'))
    this.filterForm.get('dateTo').setValue(this.datePipe.transform(`${year}/12/31`,'yyyy-MM-dd'))
  }

  applyFilter() {
    if (this.filterForm.get('dateFrom').value == '' || this.filterForm.get('dateTo').value == '') {
      this.invalidFilter = true
      return
    }
    this.dialogRef.close({
      ...this.filterForm.value,
      Applied: true
    });
  }

}
