import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';


import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { DepartmentService } from 'src/app/services/department.service';
import { docService } from 'src/app/services/doc.service';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';



interface Structures {
  name: string;
  employeeId: number;
  imageUrl: string;
  area: string;
  profileUrl: string;
  office: string;
  tags?: any;
  isLoggedUser: boolean;
  positionName: string;
  children: Child[];
}
interface Child extends Structures {

}

declare function loadChartJs(data): any;

@Component({
  selector: 'app-organic-structure',
  templateUrl: './organic-structure.component.html',
  styleUrls: ['./organic-structure.component.css']
})
export class OrganicStructureComponent implements OnInit {

  //incompleto
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'ESTRUCT',
    searchAction: ''
  }

  private readonly url_api_doc = environment.api_doc
  public companies: any[] = []
  public company: any;
  public locations = []
  public department = []
  public parentSection: any;
  public data;

  @ViewChild('showStructure') showStructure: ElementRef;

  constructor(
    private srvCompany: CompaniesService,
    private toast: ToastrService,
    private srvDepartment: DepartmentService,
    private srvDocument: docService,
    private _http: HttpClient,
    public authInfo: AuthInfoService) { }

  public configDDLCompany = {
    displayKey: 'companyName',
    search: true,
    noResultsFound: 'No hay data para mostrar',
    placeholder: 'Seleccionar'
  };

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.srvCompany.getCompanies().subscribe((e: any) => {
          if (e.succeded) {
            this.companies = e.dataList
            this.search()
          } else {
            this.toast.error('Ocurrió un error al cargar las compañías, por favor intente más tarde o comuníquese con HelpDesk.');
          }
        }, error => {
          this.toast.error(error.message, 'Error')
        })
      }
    })
  }

  loadChart(data) {
    loadChartJs(data)
  }

  private hideStructure(style: string) {
    this.showStructure.nativeElement.style.display = style;
  }

  search() {

    if (!this.authInfo.getCompanyId()) {
      return;
    }

    if (this.authInfo.getCompanyId() > 0) {
      this.srvCompany.getStructure(this.authInfo.getCompanyId()).subscribe(async e => {
        if (e.succeded) {
          if (e.singleResultData.structures.length === 0) {
            this.hideStructure('none');
            this.toast.warning('No se encontraron unidades organizativas en la institución seleccionada');
            return;
          }
          const companyId = this.authInfo.getCompanyId()
          const reportCode: ReportCode = ReportCode.EmployeeData;
          const environmentUrl = environment.reportUrl
          this.data = {
            ...e.singleResultData.structures[0],
            companyId,
            reportCode,
            environmentUrl
          };
          this._http.get(`${this.url_api_doc}/Document?Guid=${this.data?.imageUrl}`).toPromise().then(async (res: any) => {
            this.data.imageUrl = res.data;
            this.getImg().then(e => {
              document.getElementById('svgChartx').innerHTML = "";
              this.hideStructure('block');
              this.loadChart(this.data)
            })
          })
        } else {
          this.toast.error(e.errors[0]);
        }
      }, error => {
        this.toast.error('Ocurrió un error al cargar la estructura orgánica, por favor intente más tarde o comuníquese con HelpDesk.');
      })
    } else {
      this.loadChart([])
    }
  }

  async getItemChildren(structures: Child[]) {
    structures?.forEach(async (currentStructure: Child) => {
      if (currentStructure?.imageUrl) {
        await this._http
          .get<any>(`${this.url_api_doc}/Document?Guid=${currentStructure?.imageUrl}`)
          .toPromise()
          .then(res => {
            currentStructure.imageUrl = res?.data;
            if (currentStructure?.children?.length > 0) {
              this.getItemChildren(currentStructure?.children);
            }
          });
      }
    });
  }

  async getImg() {
    var imgLvl1 = this.data?.children;

    for (let i = 0; i < imgLvl1.length; i++) {

      if (this?.data?.children[i]?.imageUrl) {
        await this._http
          .get<any>(`${this.url_api_doc}/Document?Guid=${this.data?.children[i]?.imageUrl}`)
          .toPromise()
          .then(res => {
            this.data.children[i].imageUrl = res?.data;
          });
      }
      this.getItemChildren(this.data?.children[i]?.children);
      /*var childrenLv3 = this.data.children[i].children;

      for (let n = 0; n < childrenLv3.length; n++) {
        const guid = this.data.children[i].children[n].imageUrl

        if (guid) {
          await this._http
            .get<any>(`${this.url_api_doc}/Document?Guid=${guid}`)
            .toPromise()
            .then(res => {
              this.data.children[i].children[n].imageUrl = res.data;
            });
        }
      }*/
    }
  }

  async getImageUrl() {
    return new Promise(resolve => {
      /* this.srvDocument.getConditionalDataUsingPromise('15F2B480-AC5E-4B1B-BCF5-F13DE749AF52').then(e => {
       })*/
      var guid = (this.data.imageUrl || '');
      var children = this.data.children;


      this._http.get(`${this.url_api_doc}/Document?Guid=${guid}`).toPromise().then((res: any) => {
        this.data.imageUrl = res.data;

        var children = this.data.children;

        for (let n = 0; n < children.length; n++) { //6
          var item = children[n];

          //buscamos el segundo nivel 
          if (item.imageUrl) {
            const lvl1 = this.getImageLvl2(item.imageUrl)
          }

          //buscamos el tercer nivel de cada segundo nivel 
          var childrenLv3 = this.data.children[n].children;
          for (let m = 0; m < childrenLv3.length; m++) {
            const itemLv3 = childrenLv3[m];

            if (itemLv3.imageUrl) {
              const lvl1 = this.getImageLvl3(itemLv3.imageUrl)//.then(chUrl => {
              lvl1.then(prom => {
                this.data.children[n].children[m].imageUrl = prom;
              });
              //})
            }

            if (m == (childrenLv3.length - 1)) {

              resolve(true)
            }
          }


          if (n == (children.length - 1) && item.children && item.children.length == 0) {
            resolve(true)
          }

        }
      });

    });
  }

  getImageLvl2 = async (guid) => {
    return new Promise(resolve => {
      this._http.get(`${this.url_api_doc}/Document?Guid=${guid}`).toPromise().then((res: any) => {
        resolve(res.data)
      });
    });
  }

  getImageLvl3 = async (guid) => {
    return new Promise(resolve => {
      this._http.get(`${this.url_api_doc}/Document?Guid=${guid}`).subscribe((res: any) => {
        resolve(res.data)
        return res.data;
      });
    });
  }

  async getHeroesSlowly(guid): Promise<any> {
    await new Promise<any>(resolve =>
      setTimeout(resolve, 2000));
    return await this.getUrl(guid);
  }

  async getUrl(guid) {
    this._http.get(`${this.url_api_doc}/Document?Guid=${guid}`).toPromise().then((res: any) => {
      return res.data
    });
  }

  async printFiles() {
    var guid = 'a0334632-0621-48d7-9456-639cc8b97392';

    await this._http.get(`${this.url_api_doc}/Document?Guid=${guid}`).subscribe(e => {
      return e;
    })
  }

  start(): Promise<string> {
    return new Promise((resolve, reject) => {
      for (let j = 0, p = Promise.resolve(); j < 10; j++) {

        p = p.then(() => new Promise<null>((res) => {


          this.get().then((e) => {

          });
        }));
      }


    });
  }

  get(): Promise<string> {
    return new Promise((resolve, reject) => {
      /* setTimeout(() => {
        resolve("listo");
      }, 3000); */
      this._http.get(`${this.url_api_doc}/Document?Guid=a0334632-0621-48d7-9456-639cc8b97392`).subscribe((e: any) => {
        resolve(e.data.toString())
      })
    });
  }


  myCurrentData: Array<any> = [];
  obsevableResponseArray: Array<any> = [];
  promiseResponseArray: Array<any> = [];
  async ngOnInitx() {
    for (let i = 0; i < 2; i++) {
      await this._http
        .get<any>("https://api.chucknorris.io/jokes/random")
        .toPromise()
        .then(data => {
        });

      await this._http
        .get<any>("https://api.chucknorris.io/jokes/random")
        .toPromise()
        .then(data => {

        });
    }


  }

}
