<div class="" style="width: 100%;">
    <div class="container-fluid">
      <div id="pdfExp" class="card">
        <div class="card-body pt-2">
          <div id="pdf">
            <div class="pdf-page performance-evaluation-form pb-4">
              <div class="container-fluid mt-3 px-0" id="contact-media">
                <table class="table table-bordered-2 mb-0">
                    <tr>
                        <td colspan="2" class="br-t">
                            <table class="table table-bordered-0 mb-0" style="width: 100%;">
                                <tr>
                                    <td class="bl-0" style="padding: 0;">
                                        <img [src]="file?.logo ? file?.logo : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-200px" width="100px">
                                    </td>
                                    <td class="br-0" align="right" style="padding: 0;">
                                        <div class="title px-2 d-flex justify-content-center align-items-center flex-column">
                                            <h1 class="ttlPDF text-primary text-center">{{company?.companyName}}</h1>
                                            <h2 class="ttlPDF text-center text-danger">{{title}}</h2>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="">
                                            <div class="row  align-items-center pb-0">
                                                <div class="col label-inline mr-0 pr-0 mb-0 pb-0">
                                                    <label class="mr-2">Fecha:</label>
                                                    <span class="text-wrap ms-75px fecha">
                                                        {{getCurrentDate | date : 'dd-MM-yyyy'}}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="row  align-items-center pb-0">
                                                <div class="col label-inline mr-0 pr-0">
                                                    <label class="mr-2">Hora:</label>
                                                    <span class="text-wrap ms-75px fecha">
                                                        {{getCurrentDate | date : 'hh:mm a'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                </table>

                <table class="table table-vertical-center">
                    <thead class="bg-primary">
                        <tr class="bg-primary" >
                            <ng-content select="[thead]"></ng-content>
                        </tr>
                    </thead>
                    <tbody>
                           <ng-content select="[tbody]"></ng-content>
                    </tbody>
                </table>
            </div>
          </div>
        </div>
        <div class="card-footer mt-4 pt-0 mx">
          <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
            <img [src]="file?.image1 ? file?.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
            <div class="firma text-center">
            </div>
            <img [src]="file?.image2 ? file?.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
          </div>
        </div>
      </div>
    </div>
    <button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
      <i class="flaticon2-printer font-size-h1"></i>
    </button>
    <script>
      let print = () => {
        let pdf = document.getElementById("pdfExp");
        pdf.contentWindow.focus();
        pdf.contentWindow.print();
      };
    </script>
</div>
