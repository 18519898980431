import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { MatDialog } from '@angular/material/dialog';
import { ActionEditComponent } from '@payroll/action-edit/action-edit.component';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { DeletePayrollNewComponent } from './delete-payroll-new/delete-payroll-new.component';
import { NewsType, PayrollNew } from './models/PayrollNews.model';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { BulkLoadNewsComponent } from './bulk-load-news/bulk-load-news.component';
import { DashboardNewsListComponent } from './dashboard-news-list/dashboard-news-list.component';
import Swal from 'sweetalert2';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { DisapprovePayrollNewsComponent } from './disapprove-payroll-news/disapprove-payroll-news.component';
import { ReasonDisapproveNewsComponent } from './reason-disapprove-news/reason-disapprove-news.component';
import { FilterEvent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { AdvancedFilterByPayrollNewsListComponent } from './advanced-filter-by-payroll-news-list/advanced-filter-by-payroll-news-list.component';

declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})


export class NewsListComponent implements OnInit, AfterViewChecked, OnDestroy {
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'NEWS-LIST',
    addAction: { key: 'ADDNEWS', value: false },
    editAction: { key: 'EDITNEWS', value: false },
    approveAction: { key: 'APPROVENEWS', value: false },
    viewAction: { key: 'VIEWNEWS', value: false },
    exportAction: { key: 'EXPORTNEWS', value: false },
    deleteAction: { key: 'REMOVENEWS', value: false },
    uploadAction: { key: 'UPLOADNEWS', value: false },
    dashboardAction: { key: 'DASHBOARDNEWS', value: false },
    disapprove: { key: 'DISAPPROOVENEW', value: false },
  }

  @Input() defaultParameterValue: { propkey: string, displayName: string }
  public photoUrl: string = ''
  public loadingPic: boolean = true
  isLoading: boolean = true;

  tableFilter = '';
  advanceFilterOptions: any
  tempAdvanceSearch: any

  selectedItems: any;
  newsTypeId: number = 0;
  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Secuencia" },
      { propkeyId: 2, displayName: "Documento de Identidad", default: true },
      { propkeyId: 3, displayName: "Servidor Público" },
      { propkeyId: 4, displayName: "Cargo" },
      { propkeyId: 5, displayName: "Tipo" },
      { propkeyId: 6, displayName: "Concepto" },
      { propkeyId: 7, displayName: "Registrado por" },
      { propkeyId: 100, displayName: "Búsqueda avanzada" },
    ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' },
    { item_id: 3, item_text: 'Aprobadas' },
    { item_id: 4, item_text: 'No Aprobadas' }
  ];
  canUseOption = false
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  newTypeOptions: any[] = [];

  payrollNews: PayrollNew[] = [];

  @ViewChild('paginator') paginator: CaptionComponent;

  sendItems: any[] = [];
  allItemsSelected: boolean = false;

  searchParameter: FilterEvent;
  payrollNewPagedResult = new PagedResult<PayrollNew>();

  constructor(
    private _toastService: ToastService,
    private _exlService: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private _toastr: ToastrService,
    private dialog: MatDialog,
    private payrollNewService: PayrollNewService,
    public authInfo: AuthInfoService
  ) {
  }

  ngOnInit(): void {
    const employeeListFilter = JSON.parse(localStorage.getItem("payrollnew-list_filter"));
    if (employeeListFilter?.length > 0) {
      this.selectedItems = JSON.parse(localStorage.getItem("payrollnew-list_filter"));
    } else {
      this.selectedItems = [{ item_id: 1, item_text: 'Activos' }];
    }
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.canUseOption = true
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.getPayrollNew();
        this.getNewType();
      }
    });
    applySort();
  }

  disapprovalreason(conceptChanges: any) {
    Swal.fire({
      title: 'Razón de Desaprobación',
      text: `${conceptChanges}`,

    })
  }

  getNewType() {
    this.payrollNewService.getNewsType().subscribe((res: any) => {
      this.newTypeOptions = res?.dataList?.filter(d => d?.status);
    })
  }

  filterByNewType(event: NewsType) {
    if (event?.newsTypeId) {
      this.newsTypeId = event?.newsTypeId
      this.getPayrollNew();
    } else {
      this.newsTypeId = 0
      this.getPayrollNew();
    }
  }

  filterSearch(event: FilterEvent) {
    this.payrollNewPagedResult.page = 1
    this.searchParameter = event;
   
    if (event.propkeyId != 100) {
      this.advanceFilterOptions = null
      this.tempAdvanceSearch = null
  
      this.getPayrollNew();
    } else {
      this.OpenAdvancedFilters();
    }
  }

  getPayrollNew() {
    if (!this.canUseOption) {
      return
    }

    const { page, pageSize } = this.payrollNewPagedResult;
    const text = this.searchParameter?.value;
    const propkeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const actives = this.selectedItems?.find((x) => x.item_id === 1);
    const inactives = this.selectedItems?.find((x) => x.item_id === 2);
    const status = (!actives?.item_id && !inactives?.item_id) || (actives?.item_id && inactives?.item_id) ? -1
      : actives?.item_id && !inactives?.item_id ? 1 : 0;
    const ableConditions = this.selectedItems
      ?.filter((items) => items?.item_id !== 1 && items?.item_id !== 2)
      ?.map((items) => items?.item_id === 3 ? 1 : 0)
      ?.join("-");
    const params = {
      propkeyId,
      status,
      page,
      pageSize,
      type: 1,
      searchParameter: text,
      newsTypeId: this.newsTypeId,
      conditions: ableConditions
    };
    this.payrollNewService.getPayrollNewsPaginated(params, this.advanceFilterOptions).subscribe((res: any) => {
      if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al obtener el listado de novedades"); return; }
      const payrollNews = res.singleData?.items?.map(p => {
        const payrollNew = {
          ...p,
          fullName: `${p.firstName} ${p.lastName}`,
          isSelected: this.sendItems?.some(item => item?.payrollNewsId === p?.payrollNewsId && item?.isSelected === true)
        }
        return { ...payrollNew };
      });
      this.payrollNews = [...payrollNews];
      this.payrollNewPagedResult = res?.singleData
    }, err => this._toastr.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk."))

  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  detectChanges() {
    this._cdRef.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.detectChanges();
  }
  onDeSelectAll() {
    this.selectedItems = [];
    this.setFilterToLocalStorage();
    this.getPayrollNew();
  }
  onItemDeSelect(item: any) {
    this.setFilterToLocalStorage();
    this.getPayrollNew();
  }
  onItemSelect(item: any) {
    this.setFilterToLocalStorage();
    this.getPayrollNew();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.setFilterToLocalStorage();
    this.getPayrollNew();
  }


  setFilterToLocalStorage() {
    localStorage.setItem('payrollnew-list_filter', JSON.stringify(this.selectedItems));
  }

  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

  getPaginatedRecords(event: PaginationEvent) {
    const { selectedPage, registersPerPage } = event;
    const { payrollNewPagedResult } = this;
    if (payrollNewPagedResult.page === selectedPage && payrollNewPagedResult.pageSize === registersPerPage) {
      return;
    }
    this.payrollNewPagedResult.page = selectedPage;
    this.payrollNewPagedResult.pageSize = registersPerPage;
    this.getPayrollNew();
  }

  exportToExcel() {
    let excelHeaders: string[][] = [[
      "Secuencia de la Novedad",
      "Documento Identificación",
      "Nombre Servidor Público",
      "Apellido Servidor Público",
      "Cargo",
      "Tipo",
      "Concepto",
      "Monto",
      "Valor Manual",
      "Valor Predeterminado Secuencia",
      "Valor Predeterminado Descripión",
      "Fecha Inicio",
      "Fecha Final",
      "Monto Total",
      "Saldo",
      "Condición de la Novedad",
      "Estatus"
    ],
    [
      "payrollNewsId",
      "personalIdentification",
      "firstName",
      "lastName",
      "positionName",
      "newsType",
      "newsConcept",
      "amount",
      "manualValue",
      "defaultValue",
      "defaultValueDescription",
      "startDate",
      "endDate",
      "totalAmount",
      "balance",
      "condition",
      "status"
    ]]
    const payrollNews = this.payrollNews.map(p => {
      let payrollNew: any = {
        ...p
      }
      delete payrollNew.fullName
      return { ...payrollNew }
    });
    this._exlService.exportToExcelSpecificColumns(payrollNews, excelHeaders, 'Lista de Novedades', true);
  }

  config = {
    displayKey: "stringData",
    search: true,
    height: 'auto', //height of the liilts to auto. With auto height scroll will never appear
    placeholder: 'Elige una opción', //i    customComparator: ()=>{}, // a cus ndefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits e limited)
    moreText: 'more', // text to be di
    noResultsFound: 'Sin resultados!',
    searchPlaceholder: 'Buscar', // lab
    searchOnKey: 'stringData', // key on whiwill be selective search. if undefined this will be extensive search on all keys
  }


  openModalAddNew(item: any, IsView = false, isEdit = false) {
    this.dialog.open(ActionEditComponent, {
      data: {
        item: item,
        IsView: IsView,
        isEdit: isEdit
      },
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.newItem) {
          this.getPayrollNew();
        }
      });
  }

  openModalDeleteNew(item: any, IsView = false, isEdit = false) {
    this.dialog.open(DeletePayrollNewComponent, {
      data: {
        item: item,
        IsView: IsView,
        isEdit: isEdit
      },
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.newItem) {
          this.getPayrollNew();
        }
      });
  }

  openModalDisapproveNew(payrollNew: any, IsView = false, isEdit = false) {
    this.dialog.open(DisapprovePayrollNewsComponent, {
      data: {
        payrollNew: payrollNew,
        IsView: IsView,
        isEdit: isEdit
      },
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.disapproved) {
          this.getPayrollNew();
        }
      });
  }

  openDashBoard(): void {
    this.dialog.open(DashboardNewsListComponent, {
      width: '100%'
    })
      .afterClosed()
      .subscribe((result) => {
      });
  }

  openModalBulkloadNews() {
    this.dialog.open(BulkLoadNewsComponent, {
      width: '70%',
      data: {
        getPayrollNew: () => this.getPayrollNew()
      }
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.newItem) {
          this.getPayrollNew();
        }
      });
  }

  approvePayrollNew(payrollNewsId: number) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      title: "¿Está seguro que desea aprobar esta novedad?",
      icon: "question",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: '#15c229'
    }).then(res => {
      if (res.isConfirmed) {
        const model = {
          payrollNewsId, userId: this.authInfo.getUserId()
        }
        this.payrollNewService.approvePayrollNew(model).subscribe((res: any) => {
          if (res.errors.length > 0) {
            this._toastr.error("Error al aprobar la novedad", "Error inesperado");
          }
          Swal.fire("Novedad Aprobada", '', 'success');
          this.getPayrollNew();
        }, error => {
          this._toastr.error("Error al aprobar la novedad", "Error inesperado");
        })
      }
    });
  }

  selectItem(payrollNew) {
    if (this.sendItems.some(item => item?.payrollNewsId === payrollNew?.payrollNewsId)) {
      this.sendItems = this.sendItems.filter((value, index, arr) => {
        return value?.payrollNewsId != payrollNew?.payrollNewsId;
      })
      this.allItemsSelected = false;
    } else {
      this.sendItems.push(payrollNew)
    }
  }

  selectAllItems() {
    this.sendItems = [];
    this.payrollNews.forEach((element) => {
      if (element?.status) {
        element['isSelected'] = this.allItemsSelected;
        if (this.allItemsSelected) {
          this.sendItems.push(element);
        }
      }
    });
  }

  approveSelection() {
    if (this.newsIdsToApprove?.length === 0) {
      this._toastr.warning('Debe marcar por lo menos una novedad no aprobada', '');
      return;
    }
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      title: "¿Está seguro que desea aprobar las novedades selecionadas?",
      icon: "question",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: '#15c229'
    }).then(res => {
      if (res.isConfirmed) {
        this.approveeNewsSeleted();
      }
    });
  }

  disapproveModal() {
    if (this.newsIdsToDisapprove?.length === 0) {
      this._toastr.warning('Debe marcar por lo menos una novedad aprobada', '');
      return;
    }
    this.dialog.open(ReasonDisapproveNewsComponent, {
      data: {
        ids: this.newsIdsToDisapprove
      }
    }).afterClosed().subscribe({
      next: (result: any) => {
        if (result?.success == false) {
          return;
        }
        this.sendItems = [];
        this.allItemsSelected = false;
        this.selectAllItems();
        this.getPayrollNew();
      }
    })
  }

  get newsIdsToApprove() {
    const ableToApprove = this.sendItems.filter((x: PayrollNew) => x.condition == 0)
    const payrollNewsIds: number[] = ableToApprove.map((s: PayrollNew) => {
      return s.payrollNewsId;
    });
    return payrollNewsIds
  }

  get newsIdsToDisapprove() {
    const ableToDisapprove = this.sendItems.filter((x: PayrollNew) => x.condition == 1)
    const payrollNewsIds: number[] = ableToDisapprove.map((s: PayrollNew) => {
      return s.payrollNewsId;
    });
    return payrollNewsIds
  }

  private approveeNewsSeleted() {
    const ableToApprove = this.sendItems.filter((x: PayrollNew) => x.condition == 0)
    const data = ableToApprove.map((s: PayrollNew) => {
      return { payrollNewsId: s.payrollNewsId, userId: this.authInfo.getUserId() };
    });

    this.payrollNewService.approveMassive(data).subscribe(newsResponse => {
      if (newsResponse?.errors?.length > 0) {
        this._toastr.error(newsResponse?.errors[0])
      }
      this.sendItems = [];
      this.allItemsSelected = false;
      this.selectAllItems();
      Swal.fire("Novedades Aprobadas", '', 'success');
      this.getPayrollNew();
    }, error => {
      this._toastr.error(`Error al aprobar la novedades`, "Error inesperado");
    });

  }

  get newsIdsToRemove() {
    const newsToRemove = this.sendItems?.filter((x: PayrollNew) => x?.status === true && x?.condition === 0)
    const payrollNewsIds: number[] = newsToRemove?.map((s: PayrollNew) => {
      return s?.payrollNewsId;
    });
    return payrollNewsIds;
  }

  removeModal() {
    if (this.newsIdsToRemove?.length === 0) {
      this._toastr.warning('Debe marcar por lo menos una novedad no aprobada', '');
      return;
    }
    this.dialog.open(DeletePayrollNewComponent, {
      data: {
        item: this.newsIdsToRemove,
      },
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.Applied) {
          this.sendItems = [];
          this.allItemsSelected = false;
          this.selectAllItems();
          Swal.fire("Novedades Eliminadas", '', 'success');
          this.getPayrollNew();
        }
      });
  }

  get getCountNewsNoApprove() {
    return this.payrollNews?.filter(p => p.condition === 0 && p?.status)?.length;
  }
  get getCountNewsToDisapprove() {
    return this.payrollNews?.filter(p => p.condition === 1 && p?.status)?.length;
  }

  get getCountNewsToRemove() {
    return this.payrollNews?.filter(p => p?.status)?.length;
  }

  get isShowSelectItems() {
    return (this.getCountNewsNoApprove > 0 && this.privilege?.approveAction?.value)
      || (this.getCountNewsToDisapprove > 0 && this.privilege?.disapprove?.value)
      || (this.getCountNewsToRemove > 0 && this.privilege?.deleteAction?.value)
  }

  get hasPermission() {
    return this.privilege?.approveAction?.value
      || this.privilege?.disapprove?.value
      || this.privilege?.deleteAction?.value;
  }

  exportPdf() {
    const propKeyId: number = this.searchParameter?.propkeyId ?? 0;
    const searchParameter: string = this.searchParameter?.value ?? '';
    const actives = this.selectedItems?.find((x) => x.item_id === 1);
    const inactives = this.selectedItems?.find((x) => x.item_id === 2);
    const status = (!actives?.item_id && !inactives?.item_id) || (actives?.item_id && inactives?.item_id) ? -1
      : actives?.item_id && !inactives?.item_id ? 1 : 0;
    const page: number = this.payrollNewPagedResult?.page;
    const pageSize: number = this.payrollNewPagedResult?.pageSize;
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.PayrollNews;
    const ableConditions = this.selectedItems
      ?.filter((items) => items?.item_id !== 1 && items?.item_id !== 2)
      ?.map((items) => items?.item_id === 3 ? 1 : 0)
      ?.join("-");
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&PropkeyId=${propKeyId}&SearchParameter=${searchParameter}&Status=${status}&Conditions=${ableConditions}&Page=${page}&PageSize=${pageSize}&AdvancedFilter=${this.advanceFilterOptions || ''}&NewsTypeId=${this.newsTypeId}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Servidores Públicos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  OpenAdvancedFilters() {
    this.dialog.open(AdvancedFilterByPayrollNewsListComponent, {
      width: '55%',
      data: {
        activedfilters: this.tempAdvanceSearch
      }
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (!res) return
        let searchOptions = {
          ...res,
          newsType: res?.newsType?.map((type: any) => type.newsTypeId)?.join('|'),
          newsConcept: res?.newsConcept?.map((concept: any) => concept.newsConceptId)?.join('|'),
          newsCondition: res?.newsCondition?.map((condition: any) => condition.item_id)?.join('|')
        }
        this.advanceFilterOptions = Object.values(searchOptions)?.map((options: any) => options)?.join(",");
        this.tempAdvanceSearch = res
        if (this.advanceFilterOptions) {
          this.getPayrollNew()
        }
      },
      error: (err: any) => {

      }
    })
  }

}
