<div class="card-body">
    <fieldset>
        <div class="row">
            <div class="form-group col-md-4">
                <label class="d-block">Tipo de Novedaddd<span class="text-danger text-monospace legend">*</span></label>
                <div class="dropdown-alt input-group">
                    <ngx-select-dropdown [options]="locations" [config]="dropdownConfig('socialReason')"
                        [(ngModel)]="form.enclosure" class="w-100"></ngx-select-dropdown>
                </div>
                <span class="form-text text-danger" *ngIf="formIsInvalid">{{showEnclosureIdErrors()}}</span>
            </div>

            <div class="form-group col-md-4">
                <label class="d-block">Cargar Documento<span class="text-danger text-monospace legend">*</span></label>
                <div class="dropzone dropzone-default dz-clickable" id="kt_dropzone_1">
                    <div class="dropzone-msg dz-message needsclick">
                        <h3 class="dropzone-msg-title">Suelte los archivos aquí o haga clic para cargar.</h3>
                    </div>
                </div>
            </div>
         

            <div class="form-group col-md-4">
                <button data-toggle="modal" data-target="#add" class="btn btn-success btn-pill font-weight-bolder mt-9 mx-2 col-md-auto"><i data-toggle="tooltip" data-original-title="Procesar" class="flaticon2-plus"></i> Procesar </button>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-center">Cédula </th>
                                <th class="text-center">Servidor Público</th>
                                <th class="text-left">Tipo</th>
                                <th class="actionsBtn">Monto</th>
                                <th class="actionsBtn">Fecha</th>
                                <th class="actionsBtn">Detalle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">000-0000000-0</td>
                                <td class="text-left">-</td>
                                <td class="text-left">-</td>
                                <td class="text-left">RD$00.00</td>
                                <td class="text-left">03/02/2022</td>
                                <td class="text-left">--</td>
                            </tr>
                            <tr>
                                <td class="text-center">000-0000000-0</td>
                                <td class="text-left">-</td>
                                <td class="text-left">-</td>
                                <td class="text-left">RD$00.00</td>
                                <td class="text-left">03/02/2022</td>
                                <td class="text-left">--</td>
                            </tr>
                            <tr>
                                <td class="text-center">000-0000000-0</td>
                                <td class="text-left">-</td>
                                <td class="text-left">-</td>
                                <td class="text-left">RD$00.00</td>
                                <td class="text-left">03/02/2022</td>
                                <td class="text-left">--</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <div class="col-sm-12 col-md-5 pl-0">
                            <div class="d-flex align-items-center mr-7">
                                <span class="svg-icon svg-icon-gray-500 pr-2">
                                    <i class="flaticon-marca-de-verificacion"></i>
                                </span>
                                <div class="dataTables_info" id="kt_datatable_info" role="status" aria-live="polite">
                                    Total de registros <span
                                        class="font-weight-bolder text-primary ml-1">{{paginatedEmployees.length}} </span>
                                </div>
                            </div>
                        </div>
            
                        <div class="d-flex justify-content-end align-items-center flex-wrap">
                            <el-caption #paginator [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :employees.length" [records]="filteredList.enable ? filteredList.records : employees" (onChage)="getPaginatedRecords($event)"></el-caption>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" data-dismiss="modal">
      <i class="ki ki-close"></i>
      Close
    </button>
    <button type="button" class="btn btn-success"> <i class="flaticon-disquete"></i> Guardar</button>
  </div>
