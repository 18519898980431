import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { Subscription } from 'rxjs';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeDashboardComponent } from './dashboard/employee-dashboard/employee-dashboard.component';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { EmployeesIncompleteComponent } from '../employees-incomplete/employees-incomplete.component';
import { DatePipe } from '@angular/common';
import { HistoryPersonalActionsComponent } from './history-personal-actions/history-personal-actions.component';
import { IEmployee } from 'src/app/models/Employee.model';
import { PagedResult } from 'src/app/shared/models/page-result';
import { EmployeeServiceRequestModalComponent } from '../employee-service/employee-service-request-detail/employee-service-request-modal.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { FilterEvent, SearchBarPaginationComponent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { CompaniesService } from 'src/app/services/companies.service';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { AdvancedFilterByEmployeesComponent } from './advanced-filter-by-employees/advanced-filter-by-employees.component';
import { employeeServiceRequestDetailModalFormComponent } from '../employee-service/employee-service-request-detail-modal-form/employee-service-request-detail-modal-form.component';
import { AuditChangesComponent } from './audit-changes/audit-changes.component';

declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css']
})
export class EmployeesComponent implements OnInit, AfterViewChecked, OnDestroy {
  advancedFilter = null
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'EMPLOYEES',
    dashboardAction: { key: 'DASHB-E', value: false },
    exportAction: { key: 'EXPORT-E', value: false },
    editAction: { key: 'EDIT-E', value: false },
    viewAction: { key: 'VIEW-E', value: false },
    personnelAction: { key: 'ACTIONS-E', value: false },
    historyPersonalActions: { key: 'HISTORYPERSONALACTIONS', value: false }, 
    auditChanges:{ key:'HISTORY-E',value: false }
  }

  public photoUrl: string = ''
  public loadingPic: boolean = true
  localStorageSearch = 'employees-list_filter_search'
  @ViewChild('searchBar') searchBar: SearchBarPaginationComponent
  datePipe = new DatePipe('en-Us')
  isLoading: boolean = true;
  subscription: Subscription = new Subscription();
  employees: any[] = [];
  pageQuantity = 0
  employeesListOriginal: any[] = [];
  paginatedEmployees: any[] = [];
  employeesAI: boolean = false;
  tableFilter = '';
  employeeFilter: any = '';
  typeExitList: any[] = [];
  branches: any[] = []
  branch: any
  reasonExitList: any[] = [];
  @ViewChild('paginator') paginator: CaptionComponent;
  form: FormGroup;
  employeeId: number;
  filterText: string = '';
  postulationId: number;
  exitReasonDescription;
  vacantId: number;
  selectedItems: any[] = [];
  personId: number;
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };

  incompleteEmployees: { quantity: number } = {
    quantity: 0
  }
  canUseOption = false;
  searchParameter!: FilterEvent;
  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Secuencia" },
      { propkeyId: 2, displayName: "Código de Servidor Público" },
      { propkeyId: 3, displayName: "Servidor Público" },
      { propkeyId: 16, displayName: "Tipo de Documento" },
      { propkeyId: 4, displayName: "Documento de Identidad", default: true },
      { propkeyId: 15, displayName: "Sexo" },
      { propkeyId: 5, displayName: "Unidad Organizativa" },
      { propkeyId: 6, displayName: "Cargo" },
      { propkeyId: 7, displayName: "Teléfono" },
      { propkeyId: 8, displayName: "Tipo de Servidor Público" },
      { propkeyId: 9, displayName: "Categoría de Servidores Públicos" },
      { propkeyId: 10, displayName: "Tanda" },
      { propkeyId: 11, displayName: "Condición Actual" },
      { propkeyId: 100, displayName: "Búsqueda avanzada" },
    ];

  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Desvinculados' }
  ];

  employeesPagedResut = new PagedResult<any>();
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Todo',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }
  constructor(private employeeService: EmployeesService,
    private _toastService: ToastService,
    private _exlService: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private conpaniesService: CompaniesService,
    private _fb: FormBuilder,
    private router: Router,
    private _toastr: ToastrService, private srvDoc: docService,
    private dialog: MatDialog,
    public authInfo: AuthInfoService,
  ) {
  }

  ngOnInit(): void {
    const employeeListFilter = localStorage.getItem("employee-list_filter");

    if(employeeListFilter){
      try{
        const listFilteJSON: any[] = JSON.parse(employeeListFilter);

        if(listFilteJSON && listFilteJSON?.length > 0){
          this.selectedItems = listFilteJSON;
        }else{
          this.setFilterDefaultLocalStorage();
        }
      }catch(exception){
        this.setFilterDefaultLocalStorage();
      }
    }else{
      this.setFilterDefaultLocalStorage();
    }

    this.setSearchParameterStorage()

    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.canUseOption = true
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.getIncompleteEmployees();

        this.getData()

      }
    });
    applySort();
  }

  //Started working in open the modal
  openModalAuditChanges(employee){
    this.dialog.open(AuditChangesComponent,{
      data:{
        employee
      },
      width:'100%'
    });
  }

  getCompanyLocalStorage() {
    const companyStorage = localStorage.getItem('employees_companySelected');
    let companyId = this.authInfo.getCompanyId()
    if (companyStorage !== 'undefined') {
      if (companyStorage?.length) {
        let companyIdStorage = JSON.parse(companyStorage)?.companyId
        if (companyIdStorage != 'undefined') {
          companyId = companyIdStorage
          this.branch = this.findBranchByCompanyId(companyId);
        }
      }
    }
  }
  findBranchByCompanyId(companyId) {
    return this.branches.find(branch => branch.companyId === companyId);
  }
  setCompanyToLocalStorage() {
    const company = this.branch?.companyId;
    localStorage.setItem('employees_companySelected', company ? JSON.stringify(this.branch) : undefined);
  }

  setSearchParameterStorage() {

    const localStorageItem = localStorage.getItem(this.localStorageSearch);
    if(localStorageItem){
      try{
        const localStorageSearch: FilterEvent = JSON.parse(localStorageItem);
        this.searchParameter = localStorageSearch;
      }catch(exception){
        this.searchParameter = {};
      }
    }else{
      this.searchParameter = {};
    }
    
  }

  setFilterDefaultLocalStorage(){
    const valuepr = [this.statusOptions[0]];
    localStorage.setItem('employee-list_filter', JSON.stringify(valuepr));
    this.selectedItems = [...valuepr]; 
  }

  getData() {
    this.conpaniesService.getCompanies().subscribe({
      next: (res) => {
        if (!res.succeded) {

          res.errors.forEach(err => {
            this._toastService.error(err);
          })
  
          res.warnings.forEach(err => {
            this._toastService.warning(err);
          })
  
          return;
        }

        this.branches = res.dataList;

        this.getCompanyLocalStorage()
        this.getEmployeeList();
      },
      error: (err) => {

      }
    });
  }

  onBranchChange() {
    this.getEmployeeList()
    this.setCompanyToLocalStorage()
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  filterSearch(event: FilterEvent) {

    this.employeesPagedResut.page = 1
    this.searchParameter = event
    if (event.propkeyId != 100) {
      this.advancedFilter = ','.repeat(9);
      this.getEmployeeList();
    } else {
      this.OpenAdvancedFilters();
    }
  }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  changeStatus() {
    this.getEmployeeList();
  }

  setFilterToLocalStorage() {
    localStorage.setItem('employee-list_filter', JSON.stringify(this.selectedItems));
  }


  clearStorage() {
    localStorage.removeItem('action-change-tab-selected')
    localStorage.removeItem('personnel-actions-tab-selected')
  }

  getEmployeeList() {
    if (!this.canUseOption) {
      return
    }
    const advanced = this.advancedFilter ?? ','.repeat(9);
    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value ?? ''
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const status: number = this.selectedItems?.length === 2 || this.selectedItems?.length == 0 ? -1 : (this.selectedItems[0]?.item_id === 1 ? 1 : 0);
    const branchId: number = this.branch?.companyId ?? 0
    this.employeeService.getEmployeesPaginatedAdvanced(branchId, 0, page, pageSize, propKeyId, text, status, 1, null, advanced).subscribe((res: any) => {
      if (!res.succeded) {

        res.errors.forEach(err => {
          this._toastService.error(err);
        })

        res.warnings.forEach(err => {
          this._toastService.warning(err);
        })

        return;
      }
      //this.employees = this.filterTable(res.singleData.items);
      this.employeesListOriginal = res.singleData.items
      this.employees = res.singleData.items;
      this.employeesPagedResut = res.singleData
      this.getEmployeeActions();
    }, err => this._toastService.error('Ha ocurrido un error inesperado al obtener la lista de Servidores Públicos'))

  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.getEmployeeList();
    this.setFilterToLocalStorage()
  }
  onItemDeSelect(item: any) {
    this.getEmployeeList();
    this.setFilterToLocalStorage()
  }
  onItemSelect(item: any) {
    this.getEmployeeList();
    this.setFilterToLocalStorage()
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.getEmployeeList()
    this.setFilterToLocalStorage()
  }

  getIncompleteEmployees() {
    this.employeeService.getEmployeeIncompleteCount().subscribe({
      next: res => {
        if (!res.succeded) {
          res.errors.forEach(element => {
            this._toastService.error(element);
          });
          return;
        }
        this.incompleteEmployees = res?.singleData;
      },
      error: (err) => {
        this._toastService.error('Ha ocurrido un error inesperado al obtener el total de servidores públicos incompletos');
      }
    })
  }

  openModalIncompleteEmployees() {
    this.dialog.open(EmployeesIncompleteComponent, {
      data: { incompleteEmployees: this.incompleteEmployees, title: 'Lista de Servidores Públicos con Datos Incompletos', type: 1 },
      width: '100%'
    }).afterClosed().subscribe((res: { changeInfo: boolean }) => {
      if (res?.changeInfo) {
        this.getIncompleteEmployees();
      }
    })
  }

  openModalHistoryPersonalActionsComponent(employee: IEmployee) {
    this.dialog.open(HistoryPersonalActionsComponent, {
      data: { employee: employee },
      width: '100%'
    }).afterClosed().subscribe(() => {

    })
  }


  getPaginatedRecords(event: PaginationEvent) {
    if (this.employeesPagedResut.page == event.selectedPage && this.employeesPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.employeesPagedResut.page = event.selectedPage;
    this.employeesPagedResut.pageSize = event.registersPerPage;
    this.getEmployeeList()
  }

  config = {
    displayKey: "stringData",
    search: true,
    height: 'auto', //height of the liilts to auto. With auto height scroll will never appear
    placeholder: 'Elige una opción', //i    customComparator: ()=>{}, // a cus ndefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits e limited)
    moreText: 'more', // text to be di
    noResultsFound: 'Sin resultados!',
    searchPlaceholder: 'Buscar', // lab
    searchOnKey: 'stringData', // key on whiwill be selective search. if undefined this will be extensive search on all keys
  }

  openDashBoard(): void {
    this.dialog.open(EmployeeDashboardComponent, {
      width: '100%'
    });
  }




  getEmployeeActions() {
    this.employeeService.getEmployeeActions().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastr.error(res.errors[0]);
        return;
      }
      const employeeActions = res.dataList as any[];
      const employees = this.employeesListOriginal.map((em: any) => {
        let employee = { ...em };
        const employeeAction = employeeActions.find((e: any) => e.employeeId === em.employeeId);
        employee.employeeAction = employeeAction?.personalActionsType || "N/A";
        return employee;
      });
      this.employees = [...employees];
      this.employeesListOriginal = [...employees];
    });
  }


  resetFilters() {
    localStorage.removeItem(this.localStorageSearch);
    localStorage.removeItem('employee-list_filter');
    localStorage.removeItem('employees_companySelected');
    this.selectedItems = [this.statusOptions[0]];
    this.searchBarDisplayNames = this.searchBarDisplayNames.map(value => {
      if (value.propkeyId === 4) {
        return { ...value, default: true };
      }
      return value;
    });
    this.getData();
  }

  exportToPdf() {
    const propKeyId: number = this.searchParameter?.propkeyId ?? 0;
    const searchParameter: string = this.searchParameter?.value ?? '';
    const status: number = this.selectedItems?.length === 2 || this.selectedItems?.length == 0 ? -1 : (this.selectedItems[0]?.item_id === 1 ? 1 : 0);
    const page: number = this.employeesPagedResut?.page;
    const pageSize: number = this.employeesPagedResut?.pageSize;
    const branchId: number = this.branch?.companyId ?? 0
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.EmployeeList;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&AdvancedFilter=${this.advancedFilter}&CompanyId=${companyId}&BranchId=${branchId}&PropKeyId=${propKeyId}&SearchParameter=${searchParameter}&Status=${status}&Page=${page}&PageSize=${pageSize}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Servidores Públicos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  exportEmployeeByDepartments() {
    const companyId: number = this.authInfo.getCompanyId();
    const type: number = this.selectedItems?.length === 2 || this.selectedItems?.length === 0 ? 1 : 2;
    const status: boolean = type === 2 && this.selectedItems?.some(s => s?.item_id === 1) ? true :
      type === 2 && this.selectedItems?.some(s => s?.item_id === 2) ? false : true;
    const reportCode: ReportCode = ReportCode.EmployeesActive;
    const branchId = this.branch.companyId;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&BranchId=${branchId ?? 0}&Status=${status}&Type=${type}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Servidores Públicos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  exportEmployeeData(employee) {
    const { companyId, employeeId, status } = employee
    const reportCode: ReportCode = ReportCode.EmployeeData;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&EmployeeId=${employeeId}&Status=${status ? 1 : 0}`;

    let parameters = {
      url: reportUrl,
      title: 'Reporte Servidores Públicos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  OpenAdvancedFilters() {
    this.dialog.open(AdvancedFilterByEmployeesComponent, {
      width: '55%',
      data: {
        advanced: this.advancedFilter
      }
    }).afterClosed().subscribe({
      next: (res:any) => {
        if(res.success == false){
          return
        }
        this.advancedFilter = res.text
        this.getEmployeeList()
      }
    })
  }

  showModalCertification(item) {

    /*     this.dialog.open(EmployeeServiceRequestModalComponent,
          {
            data: { employeeId: item.employeeId, reason: '', letterType: 2 },
            width: '50%',
            height: '90%'
          }).afterClosed().subscribe(req => {

          }) */
    this.showmodal(item)
  }

  showmodal(item) {
    this.dialog.open(employeeServiceRequestDetailModalFormComponent,
      {
        data: new Date(item.exitDate).getFullYear(),
        width: '30%',
      }).afterClosed().subscribe(req => {
        if (req) {
          this.displayModel(item, req.year, 0, req)
        }
      })
  }

  displayModel(item, year, aproveed, letterBody?) {
    this.dialog.open(EmployeeServiceRequestModalComponent,
      {
        data: {
          year: year,
          employeeId: item.employeeId,
          reason: item.reason,
          letterType: 2,
          aproveed: aproveed,
          requestId: item.requestId,
          letterBody: letterBody
        },
        width: '50%',
      }).afterClosed().subscribe(req => {
        /* if (req && req.name) {
          req["RequestId"] = item.id
          req["ResponseUserId"] = Number(this.account.employeeId)
          req["ApplicationDate"] = item.applicationDate;

          this.srvServiceRequest.approveRequestServices(req).subscribe(res => {
            if (res.succeded == true) {
              this.toastr.success("Solicitud Terminada")
              this.getRequest();
            }
          })
        } */
      })
  }
}
