import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthInfoService } from './auth-info.service';
import { Separation } from '../models/separation';

@Injectable({
  providedIn: 'root'
})
export class SeparationsService {

  private readonly api = environment.api_url;


  constructor(
    private _http: HttpClient,
    private _authInfo: AuthInfoService
  ) { }

  getValidation() {
    return this._http.get(`${this.api}/MassiveDimissals/GetDataValidation`)
  }

  getDataValidationseparationsDetail(conditionId:number) {
    return this._http.get(`${this.api}/MassiveDimissals?ConditionId=${conditionId}`);
  }

  submitManualFilledFiredEmployees(separationModel: Separation[]) {
    return this._http.post(`${this.api}/MassiveDimissals?UserId=${this.getCurrentUserId()}&CompanyId=${this.getCurrentUserCompanyId()}`, separationModel)
  }

  applyFinalSeparation() {
    return this._http.post(`${this.api}/MassiveDimissals/ProcessMassiveDimissals?ExitUserId=${this.getCurrentUserId()}&Code=0000000`, {})
  }

  getPeopleToBeFired() {
    return this._http.get(`${this.api}/MassiveDimissals/GetPreview?type=0`)
  }

  getCurrentUserId(){
    return this._authInfo.getUserId();
  }
  getCurrentUserCompanyId(){
    return this._authInfo.getCompanyId();
  }


}
