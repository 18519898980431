<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Historial de Modificaciones
    </h2>
    <button type="button" (click)="close()" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>    
</div>

<div class="mat-dialog-content-custom">
    <div class="card card-custom">
        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-12 col-xl-12 col-xxl-6 c-left">
                <div class="card-toolbar d-flex justify-content-start">
                    <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4 mr-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <h2 class=" font-size-h5 text-primary">
                                Datos actuales
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-xl-12 col-xxl-6 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a (click)="exportReport()" class="dropdown-employee align-employees-center">
                              <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                              Visualizar Reporte
                          </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th class="text-left min-w-150px">Código de Servidor Público</th>
                        <th class="text-left">Servidor Público</th>
                        <th class="text-left">Tipo de Documento</th>
                        <th class="text-left min-w-160px">Documento de Identidad</th>
                        <th class="text-left min-w-160px">Sexo</th>
                        <th class="text-left min-w-160px">Fecha de Ingreso</th>
                        <th class="text-left">Sucursal</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-left">Cargo</th>
                        <th class="text-left min-w-150px">Teléfono Primario</th>
                        <th class="text-left">Tipo de Servidor Público</th>
                        <th class="text-left">Categoría de Servidores Públicos</th>
                        <th class="text-left">Tanda</th>
                        <th class="text-left">Condición Actual</th>
                        <th>Creado Por</th>
                        <th>Fecha Creación</th>
                        <th>Modificado por</th>
                        <th>Fecha Modificacion</th>
                        <th class="text-left">Estatus</th>
                    </tr>
                </thead>
                <tbody>
                    <tr data-row="0" class="datatable-row" style="left: 0px;">
                    <td class="text-center">
                            {{data.employee.employeeId}}
                        </td> 
                        <td class="text-left">{{data.employee.employNumber}}</td>
                        <td class="text-left">{{data.employee.name}}</td>
                        <td class="text-left">{{data.employee.typeIdentification}}</td>
                        <td class="text-left" *ngIf="data.employee.typeIdentificationId == 1">
                            {{data.employee.personalIdentification | mask: '000-0000000-0'}}</td>
                        <td class="text-left" *ngIf="data.employee.typeIdentificationId != 1">
                            {{data.employee.personalIdentification}}</td>
                        <td class="text-left">{{data.employee.sex}}</td>
                        <td class="text-left">{{data.employee.admissionDate |date:'dd/MM/yyyy'}}</td>
                        <td class="text-left">{{data.employee.branch}}</td>
                        <td class="text-left">{{data.employee.departmentName}}</td>
                        <td class="text-left">{{data.employee.positionName}}</td>
                        <td class="text-left">{{data.employee.phoneNumber | mask: "(000) 000-0000"}}</td>
                        <td class="text-left">{{data.employee.employeeType}}</td>
                        <td class="text-left">{{data.employee.contractType}}</td>
                        <td class="text-left">{{data.employee.proposedSchedule}}</td>
                        <td class="text-left">
                            <ng-container *ngIf="data.employee?.employeeAction">
                                {{data.employee?.employeeAction}}
                            </ng-container>
                            <ng-container *ngIf="data.employee?.employeeAction === undefined">
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                <span class="visually-hidden">Loading...</span>
                            </ng-container>
                        </td>

                        <td class="text-center">
                            <span>{{ data.employee.userCreateFullName }}</span>
                        </td>
                        <td class="text-center">
                            <span>{{data.employee.createDate | date:'short' }}</span>
                        </td>
                        <td class="text-center">
                            <span>{{ data.employee.userModifyFullName }}</span>
                        </td>
                        <td class="text-center">
                            <span>{{data.employee.modifyDate | date:'short' }}</span>
                        </td>

                        <td class="text-center">
                            <span *ngIf="data.employee.status" class="font-weight-bold text-success">Activo</span>
                            <span *ngIf="!data.employee.status && !data.employee.wasHiringCanceled" class="font-weight-bold text-danger">Desvinculado</span>
                            <span *ngIf="!data.employee.status && data.employee.wasHiringCanceled" class="font-weight-bold text-danger">Nombramiento Anulado</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="card card-custom">
        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-12 col-xl-12 col-xxl-6 c-left">
                <!-- <div class="card-toolbar d-flex justify-content-start"></div> -->
                <h2 class=" font-size-h5 text-primary">
                    Historico de cambios
                </h2>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th class="text-left min-w-150px">Código de Servidor Público</th>
                        <th class="text-left">Servidor Público</th>
                        <th class="text-left">Tipo de Documento</th>
                        <th class="text-left min-w-160px">Documento de Identidad</th>
                        <th class="text-left min-w-160px">Sexo</th>
                        <th class="text-left min-w-160px">Fecha de Ingreso</th>
                        <th class="text-left">Sucursal</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-left">Cargo</th>
                        <th class="text-left min-w-150px">Teléfono Primario</th>
                        <th class="text-left">Tipo de Servidor Público</th>
                        <th class="text-left">Categoría de Servidores Públicos</th>
                        <th class="text-left">Tanda</th>
                        <th class="text-left">Condición Actual</th>

                        <th>Creado Por</th>
                        <th>Fecha Creación</th>
                        <th>Modificado por</th>
                        <th>Fecha Modificacion</th>

                        <th class="text-left">Estatus</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let emp of employees" data-row="0" class="datatable-row" style="left: 0px;">
                        <td class="text-center">
                            {{emp.employeeId}}
                        </td> 
                        <td class="text-left">{{emp.employNumber}}</td>
                        <td class="text-left">{{emp.name}}</td>
                        <td class="text-left">{{emp.typeIdentification}}</td>
                        <td class="text-left" *ngIf="emp.typeIdentificationId == 1">
                            {{emp.personalIdentification | mask: '000-0000000-0'}}</td>
                        <td class="text-left" *ngIf="emp.typeIdentificationId != 1">
                            {{emp.personalIdentification}}</td>
                        <td class="text-left">{{emp.sex}}</td>
                        <td class="text-left">{{emp.admissionDate |date:'dd/MM/yyyy'}}</td>
                        <td class="text-left">{{emp.branch}}</td>
                        <td class="text-left">{{emp.departmentName}}</td>
                        <td class="text-left">{{emp.positionName}}</td>
                        <td class="text-left">{{emp.phoneNumber | mask: "(000) 000-0000"}}</td>
                        <td class="text-left">{{emp.employeeType}}</td>
                        <td class="text-left">{{emp.contractType}}</td>
                        <td class="text-left">{{emp.proposedSchedule}}</td>
                        <td class="text-left">
                            <ng-container *ngIf="data.employee?.employeeAction">
                                {{data.employee?.employeeAction}}
                            </ng-container>
                            <ng-container *ngIf="data.employee?.employeeAction === undefined">
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                <span class="visually-hidden">Loading...</span>
                            </ng-container>
                        </td>

                        <td class="text-center">
                            <span>{{ emp.userCreateFullName }}</span>
                        </td>
                        <td class="text-center">
                            <span>{{ emp.createDate | date:'short' }}</span>
                        </td>
                        <td class="text-center">
                            <span>{{ emp.userModifyFullName }}</span>
                        </td>
                        <td class="text-center">
                            <span>{{ emp.modifyDate | date:'short' }}</span>
                        </td>
     
                        <td class="text-center">
                            <span *ngIf="emp.status" class="font-weight-bold text-success">Activo</span>
                            <span *ngIf="!emp.status && !emp.wasHiringCanceled" class="font-weight-bold text-danger">Desvinculado</span>
                            <span *ngIf="!emp.status && emp.wasHiringCanceled" class="font-weight-bold text-danger">Nombramiento Anulado</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <app-paginator [listedRecordsLength]="employees.length"
                    [registerQuantity]="employeesPagedResut.totalItems"
                    [registerPerPage]="employeesPagedResut.pageSize" [totalPages]="employeesPagedResut.totalPages"
                    [selectedPage]="employeesPagedResut.page" (onChange)="getPaginatedRecords($event)">
                </app-paginator>
            </div>
        </div>
    </div>
</div>