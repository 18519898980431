<div id="accordion" class="container pt-3 accordion accordion-svg-toggle">
    <div class="row">

        <div data-toggle="collapse" class="" id="heading-1a" data-target="#prefix1">
            <h3 class="bg-primary col-md-12 gutter-b justify-content-between d-flex align-items-center">
                <button data-toggle="collapse" class="btn btn-link text-left font-size-h3 font-weight-bold text-white">
                    Datos Personales
                </button>
                <span class="svg-icon svg-icon-white svg-icon-3x">
                    <svg _ngcontent-geh-c123="" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24"
                        version="1.1">
                        <g _ngcontent-geh-c123="" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon _ngcontent-geh-c123="" points="0 0 24 0 24 24 0 24"></polygon>
                            <path _ngcontent-geh-c123=""
                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                            <path _ngcontent-geh-c123=""
                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                fill="#000000" fill-rule="nonzero" opacity="0.3"
                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) ">
                            </path>
                        </g>
                    </svg>
                </span>
            </h3>
        </div>
        <div data-parent="#accordion" class="row" id="prefix1">
            <div class="col-xl-3 col-lg-6">
                <uppy-file 
                    #uppyFileFotoPerfil
                    cardBody=""
                    classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px"
                    targetClass="fotoPerfil"
                    centerContentClass="center-v gutter-b h-100"
                    [parameterDoc]="parameterDoc"
                    [ModuleLoad]="ModuleLoad"
                    [width]="372"
                    [height]="280"
                    [showDocumentoAfterUploaded]="true"
                    [showButtonViewFile]="false"
                    [fileForEditting]="file?.guid"
                    [allowedFileTypes]="['image/*']"
                    (changeEmitter)="changeDetector()"
                    (fileEmitter)="setImage($event)">
                </uppy-file>
            </div>
            <div class="col-xl-9">
                <div class="row">
                    <div class="form-group col-xl-3 col-lg-6">
                        <label class="">Tipo de Identificación <span class="text-danger">*</span></label>
                        <ngx-select-dropdown class=" flex-1" [options]="typeIdList" [config]="configDDL"
                            [(ngModel)]="personalInfo.typeIdentification"
                            (change)=" validatingTypeIdentificationInput();typeIdChange($event)">
                        </ngx-select-dropdown>
                        <span class="text-danger">{{fieldsErrors.idType.message}}</span>
                    </div>
                    <div class="form-group col-xl-3 col-lg-6">
                        <label class="">Número de Documento <span class="text-danger">*</span></label>
                        <input style="text-transform: uppercase;" (input)="inputId()"
                            class="form-control form-control-solid form-control-lg" id="cedula" name="cedula"
                            required="true" type="text" [(ngModel)]="personalInfo.personalIdentification"
                            (change)="ValitingForm();ValidateID()" [mask]="mask" maxlength="13" autocomplete="0">
                        <span *ngIf="this.fieldsErrors.id.dirty" class="text-danger">{{fieldsErrors.id.message}}</span>
                    </div>
                    <div class="form-group col-xl-3 col-lg-6">
                        <label>Fecha de Nacimiento <span class="text-danger">*</span></label>
                        <input class="form-control form-control-solid form-control-lg" name="dateOfBirth"
                            id="dateOfBirth" type="date" [(ngModel)]="personalInfo.birthday"
                            (change)="ValitingForm();validateBirthdayInput()" [max]="getMaxBirthdayDate()">
                        <span class="text-danger" *ngIf="showBirthdayError">Fecha de nacimiento inválida</span>
                        <span class="text-danger">{{fieldsErrors.birth.message}}</span>
                    </div>
                    <div class="form-group col-xl-3 col-lg-6">
                        <label class="">Edad <span class="text-danger">*</span></label>
                        <input class="form-control form-control-solid form-control-lg" id="edad" name="edad"
                            required="true" type="text" [(ngModel)]="age" autocomplete="0" readonly>
                        <span class="text-danger">{{fieldsErrors.age.message}}</span>
                    </div>


                </div>
                <div class="row">
                    <div class="form-group col-xl-3 col-lg-6">
                        <label for="firstName">Nombres <span class="text-danger"> *</span></label>
                        <input id="firstName"
                            onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32 || event.charCode == 209 || event.charCode == 241 )"
                            type="text" class="form-control form-control-solid form-control-lg"
                            [(ngModel)]="personalInfo.firstName" (change)="validatingFirstNameInput();ValitingForm()">
                        <span class="text-danger" *ngIf="inputFirstNameError">{{inputFirstNameError}}</span>
                        <!-- <el-input id="name" name="name" placeholder="" required="true" type="text"
                            label="Nombres" [(ngModel)]="personalInfo.firstName" (change)="ValitingForm();"></el-input> -->
                    </div>
                    <div class="form-group col-xl-3 col-lg-6">
                        <label for="lastName">Apellidos <span class="text-danger"> *</span></label>
                        <input id="lastName"
                            onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32 || event.charCode == 209 || event.charCode == 241 )"
                            type="text" class="form-control form-control-solid form-control-lg"
                            [(ngModel)]="personalInfo.lastName" (change)="validatingLastNameInput();ValitingForm()">
                        <!-- <el-input id="lastname" name="lastname" placeholder="" required="true" type="text"
                            label="Apellidos" [(ngModel)]="personalInfo.lastName" (change)="ValitingForm();validatingLastNameInput()"></el-input>
                             -->
                        <span class="text-danger" *ngIf="inputLastNameError">{{inputLastNameError}}</span>
                    </div>
                    <div class="form-group col-xl-3 col-lg-6">
                        <label class="">Nacionalidad <span class="text-danger">*</span></label>
                        <ngx-select-dropdown class=" flex-1" [options]="countries" [config]="config"
                            [(ngModel)]="personalInfo.nationality" (change)="nationalityChange($event);">
                        </ngx-select-dropdown>
                        <span class="text-danger">{{fieldsErrors.nationality.message}}</span>
                    </div>
                    <div class="form-group col-xl-3 col-lg-6">
                        <label class="d-block">Sexo <span class="text-danger"> *</span>
                        </label>
                        <!--  <div class="form-check form-check-inline">
                            <label class="form-check-label mt-2 check-dtl">
                                <input class="form-check-input" required="true" name="sexMas" type="radio"
                                    [value]="sex.mas" [(ngModel)]="personalInfo.sexId" (change)="ValitingForm()">
                                Masculino
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label mt-2 check-dtl">
                                <input class="form-check-input" required="true" name="sexFem" type="radio"
                                    [value]="sex.fem" [(ngModel)]="personalInfo.sexId" (change)="ValitingForm()">
                                Femenino
                            </label>
                        </div> -->
                        <div class="form-check form-check-inline">
                            <label class="form-check-label mt-0 check-dtl mx-1"
                                *ngFor="let m of sexList; let i = index">
                                <input class="form-check-input" name="sexFem{{i}}" type="radio" [value]="m.ocode"
                                    [(ngModel)]="personalInfo.sexId" (change)="ValitingForm()">
                                <span class=" font-size-h6">{{m.stringData}}</span>
                            </label>
                        </div>
                    </div>


                </div>
                <div class="row">
                    <div class="form-group col-xl-3 col-lg-6">
                        <label>Estado Civil <span class="text-danger"> *</span></label>
                        <ngx-select-dropdown class=" flex-1" [options]="maritalList" [config]="configMarital"
                            [(ngModel)]="personalInfo.maritalStatus" (change)="maritalChange($event)">
                        </ngx-select-dropdown>
                        <span class="text-danger">{{fieldsErrors.marital.message}}</span>
                    </div>
                    <div class="form-group col-xl-3 col-lg-6">
                        <label>Discapacidad</label>
                        <ngx-select-dropdown class=" flex-1" [options]="disabilityList" [config]="configDisability"
                            (change)="disabilityChange($event)" [(ngModel)]="personalInfo.disability">
                        </ngx-select-dropdown>
                    </div>
                    <div class="form-group col-xl-3 col-lg-6">
                        <label>Tipo de Sangre</label>
                        <ngx-select-dropdown class=" flex-1" [options]="bloodTypeList" [config]="configBloodType"
                            (change)="bloodTypeChange($event)" [(ngModel)]="personalInfo.bloodType">
                        </ngx-select-dropdown>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div data-toggle="collapse" class="" id="heading-2a" data-target="#prefix2">
            <h3 class="bg-primary col-md-12 gutter-b justify-content-between d-flex align-items-center">
                <button data-toggle="collapse" class="btn btn-link text-left font-size-h3 font-weight-bold text-white">
                    Domicilio Actual
                </button>
                <span class="svg-icon svg-icon-white svg-icon-3x">
                    <svg _ngcontent-geh-c123="" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24"
                        version="1.1">
                        <g _ngcontent-geh-c123="" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon _ngcontent-geh-c123="" points="0 0 24 0 24 24 0 24"></polygon>
                            <path _ngcontent-geh-c123=""
                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                            <path _ngcontent-geh-c123=""
                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                fill="#000000" fill-rule="nonzero" opacity="0.3"
                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) ">
                            </path>
                        </g>
                    </svg>
                </span>
            </h3>
        </div>
        <div data-parent="#accordion" class="" id="prefix2">
            <div class="row">
                <div class="form-group col-xl-3 col-lg-6">
                    <label class="">País de Residencia <span class="text-danger">*</span></label>
                    <ngx-select-dropdown class=" flex-1" [options]="countries" [config]="configCountry"
                        (change)="countryChange($event)" [(ngModel)]="personalInfo.country">
                    </ngx-select-dropdown>
                    <span class="text-danger">{{fieldsErrors.country.message}}</span>
                </div>
                <div class="form-group col-xl-3 col-lg-6">
                    <label>Provincia / Estado <span *ngIf="personalInfo.country?.idCountry==214"
                            class="text-danger">*</span></label>
                    <ngx-select-dropdown class=" flex-1" [options]="provinces"
                        [config]="getDropdownConfig('name', 'Seleccionar')" (change)="provinceChange($event)"
                        [(ngModel)]="personalInfo.province"></ngx-select-dropdown>
                </div>
                <!--    <div class="form-group col-xl-3 col-lg-6">
                    <label>Ciudad <span class="text-danger">*</span></label>
                    <ngx-select-dropdown class=" flex-1"></ngx-select-dropdown>
                </div> -->
                <div class="form-group col-xl-3 col-lg-6">
                    <label>Municipio / Ciudad <span *ngIf="personalInfo.country?.idCountry==214"
                            class="text-danger">*</span></label>
                    <ngx-select-dropdown class="flex-1" [options]="municipalities"
                        [config]="getDropdownConfig('name', 'Seleccionar')" (change)="municipalityChange($event)"
                        [(ngModel)]="personalInfo.municipality">
                    </ngx-select-dropdown>
                </div>

                <div class="form-group col-xl-3 col-lg-6">
                    <label>Sector <span *ngIf="personalInfo.country?.idCountry==214"
                            class="text-danger">*</span></label>
                    <ngx-select-dropdown class=" flex-1" [options]="sectors"
                        [config]="getDropdownConfig('name', 'Seleccionar')" [(ngModel)]="personalInfo.sector"
                        (change)="sectorChange($event)">
                    </ngx-select-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-xl-3">
                    <!--el-input id="street" name="street" placeholder="" type="text" label="Calle"
                    [(ngModel)]="personalInfo.street" [required]="personalInfo.country?.idCountry==214"
                    (dataChange)="ValitingForm()">

                </el-input-->
                    <label>Calle <span *ngIf="personalInfo.country?.idCountry==214"
                            class="text-danger text-monospace legend">
                            *</span></label>
                    <input [(ngModel)]="personalInfo.street" type="text"
                        (change)="validatingStreetInput();ValitingForm()" (mouseleave)="ValitingForm()"
                        class="form-control form-control-solid form-control-lg" name="" id="">
                    <span class="text-danger" *ngIf="inputStreetError">{{inputStreetError}}</span>
                </div>

                <div class="form-group col-xl-3 col-lg-4">
                    <el-input id="housenumber" name="housenumber" placeholder="" type="number" label="N. Casa"
                        [(ngModel)]="personalInfo.numberhouse">
                    </el-input>
                </div>

                <div class="form-group col-xl-3 col-lg-4">
                    <el-input id="building" name="building" placeholder="" type="text" label="Edificio"
                        [(ngModel)]="personalInfo.buildingName">
                    </el-input>
                </div>
                <div class="form-group col-xl-3 col-lg-4">
                    <el-input id="apartment" name="apartment" placeholder="" type="text" label="Apartamento"
                        [(ngModel)]="personalInfo.apartmentNumber"></el-input>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-container *ngIf="type == 1 || type == 2">


    <div id="accordion3" class="container pt-5 accordion accordion-svg-toggle">
        <div class="row">
            <div data-toggle="collapse" class="" id="heading-2a" data-target="#prefix4">
                <h3 class="bg-primary col-md-12 gutter-b justify-content-between d-flex align-items-center">
                    <button data-toggle="collapse"
                        class="btn btn-link text-left font-size-h3 font-weight-bold text-white">
                        Datos del Servidor Público
                    </button>
                    <span class="svg-icon svg-icon-white svg-icon-3x">
                        <svg _ngcontent-geh-c123="" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24"
                            version="1.1">
                            <g _ngcontent-geh-c123="" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon _ngcontent-geh-c123="" points="0 0 24 0 24 24 0 24"></polygon>
                                <path _ngcontent-geh-c123=""
                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                    fill="#000000" fill-rule="nonzero"></path>
                                <path _ngcontent-geh-c123=""
                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                    fill="#000000" fill-rule="nonzero" opacity="0.3"
                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) ">
                                </path>
                            </g>
                        </svg>
                    </span>
                </h3>
            </div>

            <div data-parent="#accordion3" class="" id="prefix4">
                <div class="row">
                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="">Código del Servidor Público</label>
                        <input [readonly]="!authInfo.canDoAction(privilege.nameKeyOption, privilege.changeCodeAction)"
                            appNumbersOnly [allowDecimals]="false" [allowSign]="false"
                            [(ngModel)]="employee.employNumber" type="text"
                            class="form-control form-control-solid form-control-lg" name="" id="">
                    </div>
                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="">Unidad Organizativa</label>
                        <input readonly [(ngModel)]="employee.departmentName" type="text"
                            class="form-control form-control-solid form-control-lg" name="" id="">
                    </div>
                    <div class="form-group col-lg-4">
                        <label class="d-block">Cargo <span class="text-danger text-monospace legend">*</span></label>
                        <div class="input-group">
                            <input readonly [(ngModel)]="employee.positionName" type="text"
                                class="form-control form-control-solid form-control-lg" name="" id="">
                            <a data-toggle="tooltip" data-original-title="Imprimir Descripción" (click)="openReport()"
                                class="input-group-text bg-primary btn-select-Supervisor">
                                <i class="flaticon-ver icon-2x text-white"></i>
                            </a>
                        </div>
                        <!--  <span *ngIf="formIsInvalid && form?.get('employeeSupervidor')?.hasError('required') === true"
                        class="text-danger">Este campo es requerido</span> -->
                    </div>
                </div>

                <div class="row">
                    <!-- <div class="form-group col-xl-4 col-lg-6">
                        <label>Supervisor</label>
                        <input readonly [(ngModel)]="employee.employeeSupervisor" type="text"
                            class="form-control form-control-solid form-control-lg" name="" id="">
                    </div> -->
                    <div class="form-group col-lg-4">
                        <label class="d-block">Supervisor Inmediato <span
                                class="text-danger text-monospace legend">*</span></label>
                        <div class="input-group">
                            <input [(ngModel)]="supervisorName" type="text"
                                class="form-control form-control-solid form-control-lg" readonly>
                            <a data-toggle="tooltip" data-original-title="Seleccionar Supervisor"
                                (click)="openModalSearchEmployee()"
                                class="input-group-text bg-primary btn-select-Supervisor">
                                <i class="flaticon2-search-1 icon-2x text-white"></i>
                            </a>
                        </div>
                        <!--  <span *ngIf="formIsInvalid && form?.get('employeeSupervidor')?.hasError('required') === true"
                        class="text-danger">Este campo es requerido</span> -->
                    </div>
                    <div class="form-group col-lg-4">
                        <label >Supervisor de Unidad Organizativa </label>
                        <input [(ngModel)]="departmentSupervisor" type="text"
                                class="form-control form-control-solid form-control-lg" readonly>
                    </div>

                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="">Salario</label>
                        <input readonly currencyMask [options]="{precision:2}" [(ngModel)]="employee.salary" type="text"
                            class="form-control form-control-solid form-control-lg" name="" id="">
                    </div>

                </div>


                <div class="row">
                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="">Correo Institucional</label>
                        <input [(ngModel)]="employee.institionalEmail" type="text" placeholder="Correo Institucional"
                            class="form-control form-control-solid form-control-lg" name="" id="">
                        <span class="form-text text-danger" *ngIf="validateEmail()">
                            El correo no es válido
                        </span>
                    </div>
                    <div class="form-group col-xl-4 col-lg-6">
                        <label>Fecha de Vinculación a la Institución Actual</label>
                        <input readonly [(ngModel)]="employee.admissionDate" type="date"
                            placeholder="Fecha Nombramiento" class="form-control form-control-solid form-control-lg"
                            name="" id="">
                    </div>
                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="">ARS</label>
                        <ngx-select-dropdown class=" flex-1" [options]="arsList" [config]="configAfpArs"
                            [(ngModel)]="ars" (change)="arsAfpChange($event, 2)">
                        </ngx-select-dropdown>
                    </div>


                </div>
                <div class="row">
                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="">AFP</label>
                        <ngx-select-dropdown class=" flex-1" [options]="afpList" [config]="configAfpArs"
                            [(ngModel)]="afp" (change)="arsAfpChange($event, 1)">
                        </ngx-select-dropdown>
                    </div>
                    <div class="form-group col-xl-4 col-lg-6">
                        <label>Tipo de Servidor Público</label>
                        <input readonly [(ngModel)]="employee.employeeType" type="text" placeholder=""
                            class="form-control form-control-solid form-control-lg" name="" id="">
                    </div>
                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="">Tipo de contratación</label>
                        <input readonly [(ngModel)]="employee.contractType" type="text" placeholder=""
                            class="form-control form-control-solid form-control-lg" name="" id="">
                    </div>

                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="">Tanda</label>
                        <input readonly [(ngModel)]="employee.proposedSchedule" type="text" placeholder=""
                            class="form-control form-control-solid form-control-lg" name="" id="">
                    </div>
                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="">Categoría</label>
                        <input readonly [(ngModel)]="employee.employeeCategory" type="text" placeholder=""
                            class="form-control form-control-solid form-control-lg" name="" id="">
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div id="accordion4" class="container pt-5 accordion accordion-svg-toggle">
        <div class="row">

            <div data-toggle="collapse" class="" id="heading-2a" data-target="#prefix5">
                <h3 class="bg-primary col-md-12 gutter-b justify-content-between d-flex align-items-center">
                    <button data-toggle="collapse"
                        class="btn btn-link text-left font-size-h3 font-weight-bold text-white">
                        Datos de Nómina
                    </button>
                    <span class="svg-icon svg-icon-white svg-icon-3x">
                        <svg _ngcontent-geh-c123="" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24"
                            version="1.1">
                            <g _ngcontent-geh-c123="" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon _ngcontent-geh-c123="" points="0 0 24 0 24 24 0 24"></polygon>
                                <path _ngcontent-geh-c123=""
                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                    fill="#000000" fill-rule="nonzero"></path>
                                <path _ngcontent-geh-c123=""
                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                    fill="#000000" fill-rule="nonzero" opacity="0.3"
                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) ">
                                </path>
                            </g>
                        </svg>
                    </span>
                </h3>
            </div>

            <div data-parent="#accordion4" class="" id="prefix5">
                <div class="row">
                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="">Banco</label>
                        <ngx-select-dropdown class=" flex-1" [options]="bankList" [config]="configAfpArs"
                            [(ngModel)]="bank" (change)="bankChange($event, 1)">
                        </ngx-select-dropdown>
                    </div>
                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="">Tipo de Cuenta</label>
                        <ngx-select-dropdown class=" flex-1" [options]="accountTypes" [config]="configAfpArs"
                            [(ngModel)]="accountType" (change)="accountTypeChange($event)">
                        </ngx-select-dropdown>
                    </div>
                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="">Número de Cuenta</label>
                        <input [(ngModel)]="employee.bankAccount" type="text"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'').replace(/(\..*)\./g,'$1');"
                            class="form-control form-control-solid form-control-lg" name="" id="">
                    </div>
                    <div class="form-group col-xl-4 col-lg-6">
                        <label class="d-block">Estatus de la Cuenta </label>
                        <div class="input-group">
                            <input type="text" class="form-control form-control-solid form-control-lg" readonly
                                [value]="employee?.conditionCodeBankAccountCertificationDesc" readonly>
                            <a data-toggle="tooltip" data-original-title="Validar"
                                *ngIf="!employee?.statusBankAccountCertification"
                                (click)="bankAccountCertificationStatus()"
                                class="input-group-text bg-primary btn-validate">
                                <i class="flaticon-checked icon-2x text-white"></i>
                            </a>
                        </div>
                    </div>
                    <div class="form-group col-xl-4 col-lg-6">
                        <label>Área Presupuestaria</label>
                        <!--select [(ngModel)]='employee.budgetAreaId' name="budgetAreaId"
                            class="form-control form-control-solid form-control-lg">
                            <option value="">Seleccionar</option>
                            <option value="{{ value.budgetAreaId }}" *ngFor="let value of budgetAreas">
                                {{ value.description }}
                            </option>
                        </select-->
                        <input readonly [(ngModel)]="employee.budgetArea" type="text"
                            class="form-control form-control-solid form-control-lg">
                    </div>
                    <div class="form-group col-xl-4 col-lg-6">
                        <label for="" class="d-block text-primary">¿Esta Cuenta está Certificada?</label>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label  check-dtl">
                                <input type="radio" class="form-check-input" name="isCeritificate"
                                    (change)="changeStatusBankAccountCert(isBankAccountCertificate.yes)"
                                    [(ngModel)]="statusBankAccountCertification" [value]="isBankAccountCertificate.yes">
                                Sí
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label  check-dtl">
                                <input type="radio" class="form-check-input"
                                    [(ngModel)]="statusBankAccountCertification"
                                    (change)="changeStatusBankAccountCert(isBankAccountCertificate.no)"
                                    name="isCeritificate" [value]="isBankAccountCertificate.no"> No
                            </label>
                        </div>
                    </div>
                    <div class="form-group col-xl-4 col-lg-6">
                        <div>
                            <label for="" class="d-block">¿Posee más de un cargo?
                                <span class="font-italic"></span><span
                                    class="text-danger text-monospace legend">*</span>
                            </label>
                        </div>
                        <div class="d-flex gender flex-wrap">
                            <div class="form-check form-check-inline">
                                <label class="form-check-label mt-2 check-dtl me-3">
                                    <input [value]="moreThanOnePosition.yes" [(ngModel)]="employee.moreThanOnePosition"
                                        (change)="changePositionQuantityCondition(moreThanOnePosition.yes)" type="radio"
                                        class="form-check-input" name="hasMoreThanOnePosition" />
                                    Sí
                                </label>

                                <label class="form-check-label mt-2 check-dtl">
                                    <input [value]="moreThanOnePosition.no" [(ngModel)]="employee.moreThanOnePosition"
                                        (change)="changePositionQuantityCondition(moreThanOnePosition.no)" type="radio"
                                        class="form-check-input" name="hasMoreThanOnePosition" />
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="card card-custom border-primary p-0">
                        <div class="card-header mt-3">
                            <div class="card-title align-items-center">
                                <h3 class="text-primary m-0">Historial de Cuentas</h3>
                            </div>
                        </div>
                        <div class="card-body p-0 min-h-100px">
                            <div class="table-responsive">
                                <table class="table mt-2 table-vertical-center">
                                    <thead>
                                        <tr class="bg-primary">
                                            <th>Entidad Bancaria</th>
                                            <th>Tipo de Cuenta</th>
                                            <th>Número de Cuenta</th>
                                            <th>Estatus de Cuenta</th>
                                            <th>Registrado Por</th>
                                            <th>Fecha de Registro</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let m of employeeBankAccountHistory; let i = index">
                                            <td>{{m.bank}}</td>
                                            <td>{{m.accountType}} </td>
                                            <td>{{m.bankAccount }} </td>
                                            <td>{{m.conditionCodeBankAccountCertificationDesc}}</td>
                                            <td>{{m.registeredby}} </td>
                                            <td>{{m.createDate | date:'dd/MM/yyyy'}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div id="accordion2" class="container pt-3 accordion accordion-svg-toggle">
    <div class="row">
        <div data-toggle="collapse" class="" id="heading-2a" data-target="#prefix3">
            <h3 class="bg-primary col-md-12 gutter-b justify-content-between d-flex align-items-center">
                <button data-toggle="collapse" class="btn btn-link text-left font-size-h3 font-weight-bold text-white">
                    Familiares en la Institución y Recomendaciones
                </button>
                <span class="svg-icon svg-icon-white svg-icon-3x">
                    <svg _ngcontent-geh-c123="" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24"
                        version="1.1">
                        <g _ngcontent-geh-c123="" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon _ngcontent-geh-c123="" points="0 0 24 0 24 24 0 24"></polygon>
                            <path _ngcontent-geh-c123=""
                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                            <path _ngcontent-geh-c123=""
                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                fill="#000000" fill-rule="nonzero" opacity="0.3"
                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) ">
                            </path>
                        </g>
                    </svg>
                </span>
            </h3>
        </div>
        <div data-parent="#accordion2" class="" id="prefix3">
            <div class="row">
                <div class="col-xl-6">
                    <div class="card card-custom border-primary p-0">
                        <div class="card-header mt-3">
                            <div class="card-title align-items-center">
                                <h4 class="text-primary m-0">Familiares Trabajando en la Institución</h4>
                            </div>
                            <div class="card-toolbar">
                                <button class="btn btn-success btn-pill right-0 font-weight-bolder h-40px mx-3"
                                    (click)="openRelativesModal()">
                                    <i class="flaticon2-plus"></i>
                                    Agregar
                                </button>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table mt-2 table-vertical-center">
                                    <thead>
                                        <tr class="bg-primary">
                                            <th>Nombre</th>
                                            <th>Parentesco</th>
                                            <th class="actionsBtn">Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let relative of relationshipsAdded; let i = index">
                                            <td> {{relative.personName}}</td>
                                            <td> {{getParentDescription(relative.relationshipId)}}</td>
                                            <td class="actionsBtn">
                                                <button type="button" class="btn btn-sm btn-light-warning btn-icon mr-2"
                                                    data-title="Editar" (click)="openRelativesModal(true, relative, i)">
                                                    <i class="flaticon-edit" data-toggle="tooltip"
                                                        data-title="Editar"></i>
                                                </button>
                                                <button type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                                    data-title="Remover"
                                                    (click)="relationShipRemove(relative.fpiiid, i)">
                                                    <i class="flaticon-delete" data-toggle="tooltip"
                                                        data-title="Eliminar"></i>
                                                </button>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="card card-custom border-primary p-0">
                        <div class="card-header mt-3">
                            <div class="card-title align-items-center">
                                <h4 class="text-primary m-0">Recomendaciones</h4>
                            </div>
                            <div class="card-toolbar">
                                <button class="btn btn-success btn-pill right-0 font-weight-bolder h-40px mx-3"
                                    (click)="openRecomendationsModal()"
                                    [disabled]="recomendations.length >= 6">
                                    <i class="flaticon2-plus"></i>
                                    Agregar
                                </button>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table mt-2 table-vertical-center">
                                    <thead>
                                        <tr class="bg-primary">
                                            <th>Nombre</th>
                                            <th>Teléfono</th>
                                            <th>Institución</th>
                                            <th class="actionsBtn">Acciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let recomendation of recomendations; let i = index">
                                            <td> {{recomendation.name}}</td>
                                            <td> {{recomendation.tel | mask: '(000) 000-0000'}}</td>
                                            <td> {{recomendation.institutions}}</td>
                                            <td class="actionsBtn">
                                                <button type="button" class="btn btn-sm btn-light-warning btn-icon mr-2"
                                                    data-title="Editar" (click)="openRecomendationsModal(true, recomendation, i)"
                                                    >
                                                    <i class="flaticon-edit" data-toggle="tooltip"
                                                        data-title="Editar"></i>
                                                </button>
                                                <button type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                                    data-title="Remover" (click)="removeRecomendations(i)">
                                                    <i class="flaticon-delete" data-toggle="tooltip"
                                                        data-title="Eliminar"></i>
                                                </button>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="type == 1 || type == 2">
    <div id="accordion4" class="container mt-3 pt-3 accordion accordion-svg-toggle">
        <div class="row">
            <div data-toggle="collapse" class="" id="heading-2a" data-target="#prefix4">
                <h3 class="bg-primary col-md-12 gutter-b justify-content-between d-flex align-items-center">
                    <button data-toggle="collapse"
                        class="btn btn-link text-left font-size-h3 font-weight-bold text-white">
                        Beneficios del Servidor Público
                    </button>
                    <span class="svg-icon svg-icon-white svg-icon-3x">
                        <svg _ngcontent-geh-c123="" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24"
                            version="1.1">
                            <g _ngcontent-geh-c123="" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon _ngcontent-geh-c123="" points="0 0 24 0 24 24 0 24"></polygon>
                                <path _ngcontent-geh-c123=""
                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                    fill="#000000" fill-rule="nonzero"></path>
                                <path _ngcontent-geh-c123=""
                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                    fill="#000000" fill-rule="nonzero" opacity="0.3"
                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) ">
                                </path>
                            </g>
                        </svg>
                    </span>
                </h3>
            </div>
            <div data-parent="#accordion4" class="" id="prefix4">
                <div class="row">
                    <div class="col-xl-6">
                        <div class="card card-custom border-primary p-0">
                            <div class="card-header mt-3">
                                <div class="card-title align-items-center">
                                    <h4 class="text-primary m-0">Beneficios</h4>
                                </div>
                                <div class="card-toolbar">
                                    <button (click)="openBenefits()"
                                        class="btn btn-success btn-pill right-0 font-weight-bolder h-40px mx-3">
                                        <i class="flaticon2-plus"></i>
                                        Agregar
                                    </button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                    <table class="table mt-2 table-vertical-center">
                                        <thead>
                                            <tr class="bg-primary">
                                                <th>Descripción</th>
                                                <th class="actionsBtn">Acciones</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let item of employeeBenefit; let i = index">
                                                <td>{{item?.benefit}}</td>
                                                <td class="actionsBtn">
                                                    <button type="button" (click)="deleteBenefit(item.ecbid, i)"
                                                        class="btn btn-sm btn-light-danger btn-icon mr-2">
                                                        <i class="flaticon-delete"></i>
                                                    </button>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<!-- <el-modal target="photoProfile" title="Foto Perfil" size="md">
    <img src="assets/images/300_20.png" alt="">
</el-modal> -->

<el-modal target="mrelationship" title="Familiares en la Institución">
    <div style="height: 300px;">
        <div class="row">
            <div class="form-group col-xl-12">
                <label>Parentesco <span class="text-danger">*</span></label>
                <select class="form-control  form-control-solid form-control-lg" [(ngModel)]="relOption">
                    <option *ngFor="let m of relationshipsList" [ngValue]="m.id">{{m.description}}</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-xl-12">
                <label class="">Nombre Completo <span class="text-danger">*</span></label>
                <el-input id="relationShipName" name="relationShipName" placeholder="" type="text"
                    onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32 || event.charCode == 209 || event.charCode == 241 )"
                    [(ngModel)]="relName" autocomplete="false"></el-input>
            </div>
        </div>

        <div class="row" class="text-right">
            <button class="btn btn-danger btn-pill font-weight-bolder h-40px mr-1" data-dismiss="modal">
                <i class=""></i>
                Cancelar
            </button>
            <button class="btn btn-success btn-pill font-weight-bolder h-40px" (click)="addRelationship()"
                [disabled]="relName=='' || relOption <= 0">
                <i class="flaticon2-plus"></i>
                Agregar
            </button>
            <button id="modelClose" style="display: none;" data-dismiss="modal"></button>
        </div>
    </div>
</el-modal>

<el-modal target="recomendations" title="Recomendaciones">
    <div class="row">
        <div class="form-group col-xl-12">
            <el-input id="personName" name="personName" placeholder="Ej: Carlitos Rijo" label="Nombres"
                [required]="true" [(ngModel)]="recomendationsData.name"
                onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32 || event.charCode == 209 || event.charCode == 241 )">
            </el-input>
        </div>
        <div class="form-group col-xl-12">
            <label for="number">Número de teléfono <span class="text-danger">*</span></label>
            <input class="form-control form-control-solid form-control-lg" id="phoneNumber" name="phoneNumber"
                mask="(000)-000-0000" type="mask" placeholder="Ej: 809-000-0000" label="Teléfono"
                [(ngModel)]="recomendationsData.tel" autocomplete="0">
        </div>

        <div class="form-group col-xl-12">
            <el-input id="institutionName" [required]="true" name="institutionName" placeholder="Ej: ITLA" type="text"
                label="Institución" [(ngModel)]="recomendationsData.institutions"></el-input>
        </div>
    </div>

    <div class="row">
        <div class="text-right">
            <button id="btnCloseRecomendations" class="mr-1 btn btn-danger btn-pill font-weight-bolder h-40px"
                data-dismiss="modal">
                <i class=""></i>
                Cancelar
            </button>
            <button class="btn btn-success btn-pill font-weight-bolder h-40px"
                [disabled]="recomendationsData.name == '' || recomendationsData.institutions == ''">
                <i class="flaticon2-plus"></i>
                Agregar
            </button>

            <button id="modelClose" style="display: none;" data-dismiss="modal"></button>
        </div>
    </div>
</el-modal>

<el-modal target="benefit" title="Beneficios">
    <div class="row">
        <div class="form-group col-xl-12">
            <label>Beneficios del Servidor Público</label>
            <ngx-select-dropdown class=" flex-1" [options]='getBenefitsDropdown' [config]='benefitSelectConfig'
                [(ngModel)]="benefitsSeleted" [multiple]='true'>
            </ngx-select-dropdown>
        </div>
    </div>

    <div class="row">
        <div class="text-right">
            <button #btnCloseBenefit class="mr-1 btn btn-danger btn-pill font-weight-bolder h-40px"
                data-dismiss="modal">
                <i class=""></i>
                Cancelar
            </button>
            <button [disabled]="benefitsSeleted?.length === 0"
                class="btn btn-success btn-pill font-weight-bolder h-40px" (click)="addBenefits()">
                <i class="flaticon2-plus" (click)="addBenefits()"></i>
                Agregar
            </button>

            <button id="modelClose" style="display: none;" data-dismiss="modal"></button>
        </div>
    </div>
</el-modal>