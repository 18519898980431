export enum StatusDocuments {
    COMPLETED = "COMPLETED",
    NOT_STARTED = "NOT_STARTED",
    IN_PROCESS = "IN_PROCESS",
    EXPIRED = "EXPIRED",
    REJECTED = "REJECTED"
  }
  
  export enum UserEntitiesAction{
    SIGN = "SIGN",
    APPROVAL = "APPROVAL"
  }