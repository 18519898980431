<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Comportamientos</h2>
        <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    
</div>
<div class="mat-dialog-content-custom">
    <div class="card card-custom border-primary gutter-b">
        <div class="card-header mt-3">
            <div class="card-title align-items-center">
                <i class="flaticon-gestion-de-proyectos icon-3x text-primary mr-3"></i>
                <h3 class="text-primary m-0">Comportamientos</h3>
            </div>
            <div class="card-toolbar ml-auto">
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-responsive">
                <table class="table mt-2 table-vertical-center text-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-center">Orden</th>
                            <th class="text-left">Descripción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of behaviorsRecord">
                            <td class="text-center">
                                {{item?.order}}
                            </td>
                            <td class="text-left">{{item?.description}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="ml-3">
                    <el-caption #paginator [originalRecordsLength]="behaviors?.length"
                    [listedRecordsLength]="behaviorsRecord?.length"
                    [registerQuantity]="behaviors?.length"
                    [records]="behaviors"
                    (onChage)="getPaginatedRecords($event)"></el-caption>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
            (click)="this.dialogRef.close();">
            <i class="flaticon2-cross"></i>
            Cerrar
    </button>
</div>