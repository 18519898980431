<div class="card-body">
    <div
        [ngClass]="{'d-none': fileData.filePath != '', 'd-block': fileData.filePath == '' }">
        <div [class]="targetClass"></div>
    </div>
    <div *ngIf="fileData.filePath != ''">
        <div class="symbol symbol-150 d250 my-4 max-w-250px">
            <img alt="Pic" src="assets/images/cv.jpg" />
        </div>
        <div class="btn-group center" role="group">
            <button type="button" class="btn btn-primary btn-icon"
                (click)="showFile(fileData.filePath, showFileModalTitle)">
                <i class="flaticon-ver"></i>
            </button>
            <button (click)="removeFile()" type="button" class="btn btn-danger btn-icon">
                <i class="flaticon-delete-1"></i>
            </button>
        </div>
    </div>
</div>