
export enum parameterDoc {
    wizard = "PathWizar",
    PathTest = "PathTest"
}

export enum personalFile {
    photo2x2 = 1,
    curriculumnVitae = 2,
    criminalRecord = 3,
    medicalCertificate = 4,
    cedula = 5,
    others = 6
}
