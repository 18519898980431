import { DatePipe, formatDate } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Beneficiary, ConceptSetting, DefaultValue } from '@payroll/news-list/models/PayrollNews.model';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { ToastrService } from 'ngx-toastr';
import { IEmployee } from 'src/app/models/Employee.model';
import { AdditionalPayrollService } from 'src/app/services/additional-payroll.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { FinalPayrollService } from 'src/app/services/final-payroll.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { environment } from 'src/environments/environment';
import { IEmployeeGET, INewsPOST, INewsTypeGET } from '../models/models';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };

enum paymentMethods {
  check = 2
}

@Component({
  selector: 'app-additional-payroll-add-news-modal',
  templateUrl: './additional-payroll-add-news-modal.component.html',
  styleUrls: ['./additional-payroll-add-news-modal.component.css']
})
export class AdditionalPayrollAddNewsModalComponent implements OnInit, AfterViewChecked {
  modalTitle = 'Agregar novedad'
  newsType: INewsTypeGET;
  newsTypeList: INewsTypeGET[] = [];
  errors = {
    concept: false,
    amount: false,
    evidence: false
  }
  newId = 0
  formIsInvalid = false;
  private readonly urlDoc = environment.api_doc + '/Document';
  private readonly SystemLoad: number = 1;
  private readonly ModuleLoad: number = 2;
  private routeFile: string = "";
  employeeImg = ''
  evidenceImg = ''
  employeeSelected: IEmployeeGET = {
    email: '',
    name: '',
    phoneNumber: '',
    academicLevel: '',
    personalIdentification: '',
    typeIdentificationId: 0,
    employeeSupervisor: ''
  }
  file: any = {
    evidence: ''
  }

  personalFile = personalFile;
  conceptSetting: any;
  concepts: any = [];
  conceptsFiltered: any = [];

  evidence = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.jpg','.png','application/pdf']
    },
    locale:Mexico
  });

  form: FormGroup
  evidenceGuid: string = '';
  isLitisSelected: boolean = false;
  checkIsSelected: boolean = undefined;

  @ViewChild('defaultValue') defaultValue: ElementRef;
  @ViewChild('manualValue') manualValue: ElementRef;

  typeDocuments: any[] = [];
  banks: any[] = [];
  accountTypes: any[] = [];
  paymentMethods: any[] = [];
  datePipe = new DatePipe('en-Us');

  isAdditionalSelected: Boolean = false;
  isExternalSelected: boolean = false;
  isFiscalCreditSelected: boolean = false;
  defaultValues: any[] = [];
  defaultValuesOriginalList: any[] = [];
  imgDefaultPdf: string = 'assets/images/cv.jpg';
  public mask = "000-0000000-0"
  public maskText = "Ej: 001-000000-2";

  @ViewChild('uppyFile')
  uppyFile: UppyFileComponent | null = null;
  constructor(
    private srvDoc: docService,
    private _toastService: ToastrService,
    private dialogRef: MatDialogRef<AdditionalPayrollAddNewsModalComponent>,
    private sanitizer: DomSanitizer,
    private _cdRef: ChangeDetectorRef,
    private payrollNewService: PayrollNewService,
    @Inject(MAT_DIALOG_DATA) public data: { startDate:any, endDate: any, modalTitle: string, mode: 'show' | 'add' | 'edit', employeeId: number, newId: number, item: any},
    private formBuilder: FormBuilder,
    private additionalPayroll: AdditionalPayrollService,
    private parameterControlService: ParameterControlService,
    private authInfo: AuthInfoService
  ) { }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges()
  }

  ngOnInit(): void {
    const {startDate, endDate} = this.data;
    this.form = this.buildForm()
    const form = this.form
    this.getEmployeeByNumber(this.data.employeeId)
    if(this.data.modalTitle){
      this.modalTitle = this.data.modalTitle
    }

    this.getNewsType();
    this.getConcepts();
    this.getTypeDocuments();
    this.getBanks();
    this.getAccountTypes();
    this.getPaymentMethods();
    form.patchValue({
      startDate: formatDate(startDate, 'yyyy-MM-dd', 'en'),
      endDate: endDate ? formatDate(endDate, 'yyyy-MM-dd', 'en') : null
    });
    this.checkNewType(this.data?.item?.newsTypeId);
  }

  getTypeDocuments() {
    this.parameterControlService.getParameters(keyWord.IDType).subscribe(e => {
      if (e.dataList.length > 0) {
        this.typeDocuments = e.dataList;
      }
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  getBanks() {
    this.parameterControlService.getParameters(keyWord.BankingEntity).subscribe(e => {
      if (e.dataList.length > 0) {
        this.banks = e.dataList;
      }
    })
  }

  getAccountTypes() {
    this.parameterControlService.getParameters(keyWord.AccountType).subscribe(e => {
      if (e.dataList.length > 0) {
        this.accountTypes = e.dataList;
      }
    })
  }

  getPaymentMethods() {
    this.parameterControlService.getParameters(keyWord.PaymentForm).subscribe(e => {
      if (e.dataList.length > 0) {
        this.paymentMethods = e.dataList;
      }
    })
  }

  getTypeDocumentById(id) {
    return this.typeDocuments.find(n => n.ocode === id);
  }

  getPaymentMethodById(id) {
    return this.paymentMethods.find(n => n.ocode === id);
  }

  getAccountTypeById(id) {
    return this.accountTypes.find(n => n.ocode === id);
  }

  getBankById(id) {
    return this.banks.find(n => n.ocode === id);
  }

  getNewsById(id){
    this.additionalPayroll.getPayrollNewById(id).subscribe((res:any) => {
      if(res.succeded){
        const news = res.dataList[0];
        this.form.get('newsTypeId').setValue(news?.newsTypeId);
        this.evidenceGuid = news.evidence
        const concept = this.concepts.find(x => x.newsConceptId == news.newsConceptId);
        if(concept){
          this.form.get('conceptId').patchValue(news.newsConceptId);
        }
        this.newsType = this.newsTypeList.find(n => n.newsTypeId === news?.newsTypeId);
        this.form.get('newsType').patchValue(this.newsType);
        this.selectNewsType(true);
        this.form.patchValue({
          startDate: formatDate(news.startDate, 'yyyy-MM-dd', 'en'),
          endDate: news?.endDate ? formatDate(news?.endDate, 'yyyy-MM-dd', 'en') : null,
          concept: concept ?? null,
          balance: news?.balance || 0,
          totalAmount: news?.totalAmount || 0,
          manualValue: news?.manualValue || 0,
          defaultValue: news?.defaultValue || 0,
          amount: news?.amount || 0,
        });

        if(news?.beneficiaryId > 0){
          this.getBeneficiaryById(news?.beneficiaryId, news?.companyId);
          this.isLitisSelected = true;
          this.form.get('beneficiary').enable();
        }else{
          this.showAndHideBeneficiary(false);
        }
        if (this.data.mode === 'show' || this.data.mode === 'edit') {
          this.getNewsConceptsSetting(news?.newsConceptId, news);
        }

      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }

  getNewsConceptsSetting(ConceptId: number, news){
    if(ConceptId === 0){
      this.resetConcetsSetting();
      return;
    }
    this.payrollNewService.getNewsConceptsSetting(ConceptId).subscribe((res: any) => {
      if(res.errors.length > 0){
        this._toastService.error(res.errors[0]);
        return;
      }
      if(res.dataList.length === 0){
        this.resetConcetsSetting();
        return;
      }
      this.conceptSetting = res.dataList[0] as ConceptSetting;
      if(this.conceptSetting?.requiredValue){
        this.showDefaultValue();
        this.getDefaultValuesByConceptValuesId(ConceptId, news);
      }else{
        this.hideDefaultValue();
      }
      if(this.conceptSetting?.manualValue){
        this.showManuelValue();
      }else{
        this.hideManuelValue();
      }
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  private resetConcetsSetting(){
    this.showManuelValue();
    this.showDefaultValue();
    this.defaultValues = [];
    this.defaultValuesOriginalList = [];
    this.conceptSetting = null;
    this.form.get('defaultValueSeleted').setValue(null);
    this.form.get('manualValue').setValue(null);
  }

  getDefaultValuesByConceptValuesId(id, news){
    this.payrollNewService.getDefaultValues(id).subscribe((res: any) => {
      if(res.errors.length > 0){
        this._toastService.error(res.errors[0]);
        return;
      }
      this.defaultValues = res?.dataList?.filter((d: { status: boolean; }) => d?.status)
      this.defaultValuesOriginalList = res?.dataList?.filter((d: { status: boolean; }) => d?.status)
      if (this.data.mode === 'show' || this.data.mode === 'edit') {
        const defaultValue = {...this.defaultValuesOriginalList.find(d => d.conceptValuesId === news?.defaultValue)};
        this.form.get('defaultValueSeleted').setValue(defaultValue?.conceptValuesId ? defaultValue : null);
      }
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    });
  }

  getBeneficiaryById(beneficiaryId, companyId) {
    this.additionalPayroll.getBeneficiaryById(beneficiaryId, companyId).subscribe((res: any) => {
      const data = res.dataList[0];
      this.form.get('beneficiary').patchValue({
        ...data,
        typeDocument: this.getTypeDocumentById(data.identificationTypeId),
        paymentMethod: this.getPaymentMethodById(data.paymentMethodId),
        bank: this.getBankById(data.bankId),
        accountType: this.getAccountTypeById(data.accountTypeId)
      });
      this.setCheckIsSelected()
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  close(){
    this.dialogRef.close({success:false})
  }

  save(){
    if (this.form.get('endDate')?.value && this.form.get('endDate')?.value < this.form.get('startDate')?.value) {
      this._toastService.warning('La fecha final no debe ser menor que la fecha inicio');
      return;
    }
    this.formIsInvalid = this.form.invalid;
    if(this.formIsInvalid) return

    if (this.isLitisSelected) {
      if (this.checkIsSelected == false) {
        const office = this.form.get('beneficiary.office').value ?? ''
        const accountNumber = this.form.get('beneficiary.accountNumber').value ?? ''
        const accountType = this.form.get('beneficiary.accountType').value
        /*
        Esto es para validar estos 3 campos, que sean requerido cuando la 'forma de cobro' sea cheque,
        y que no sean requeridos cuando no sea cheque
        */
        if (office.length < 1 || accountNumber.length < 1 || !accountType) {
          this.formIsInvalid = true
          return
        } else {
          this.formIsInvalid = false
        }
      }
    }

    this.uppyFile.handleFile(guid => {
      const newsType: INewsPOST = this.getNewsValues();
      newsType.evidence = guid;
      if(newsType.newsTypeId == 4){
        newsType.balance = newsType?.totalAmount;
      }
      if(this.data.mode == 'add'){
        this.post(newsType)
      }
      if(this.data.mode == 'edit'){
        this.put(newsType)
      }
    });
  }

  post(newsType){
    if (this.isLitisSelected) {
      let beneficiary: Beneficiary = {
        ...this.form.value.beneficiary,
        identificationTypeId: this.form?.value?.beneficiary?.typeDocument?.ocode || 0,
        paymentMethodId: this.form?.value?.beneficiary?.paymentMethod?.ocode || 0,
        bankId: this.form?.value?.beneficiary?.bank?.ocode || 0,
        accountTypeId: this.form?.value?.beneficiary?.accountType?.ocode || 0,
        status: true,
      };
      beneficiary.createDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
      beneficiary.beneficiaryId = 0;
      beneficiary.createUserId = +this.authInfo.getUser()?.userId;
      beneficiary.companyId = newsType?.companyId;
      beneficiary.employeeId = this.data.employeeId;
      this.postBeneficiary(beneficiary, newsType, () => {
        this.newPayrollNew(newsType);
      });
    } else {
      this.newPayrollNew(newsType);
    }

  }

  postBeneficiary(beneficiary, newsType, collBackPayrollNew: () => void){
    this.additionalPayroll.postBeneficiary(beneficiary).subscribe(res => {

      if(!res.succeded){
        res?.warnings?.forEach((warn)=>{
          this._toastService.warning(warn);
        })
        res?.errors?.forEach((err)=>{
          this._toastService.error(err);
        })
        return
      }

      newsType.beneficiaryId = res.identity;
      collBackPayrollNew();
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  updateBeneficiary(beneficiary, newsType){
    this.additionalPayroll.updateBeneficiary(beneficiary).subscribe(res => {

      if(!res.succeded){
        res?.warnings?.forEach((warn)=>{
          this._toastService.warning(warn);
        })
        res?.errors?.forEach((err)=>{
          this._toastService.error(err);
        })
        return
      }

      this.changePayrollNew(newsType);
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  newPayrollNew(payrollNew) {
    this.additionalPayroll.postNewsAdditionalPayroll(payrollNew).subscribe((res:any) => {

      if(!res.succeded){
        res?.warnings?.forEach((warn)=>{
          this._toastService.warning(warn);
        })
        res?.errors?.forEach((err)=>{
          this._toastService.error(err);
        })
        return
      }

      this._toastService.success('Datos guardados')
      this.dialogRef.close({success: true})
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }


  put(newsType){
    if (this.isLitisSelected) {
      let beneficiary: Beneficiary = {
        ...this.form.value.beneficiary,
        identificationTypeId: this.form?.value?.beneficiary?.typeDocument?.ocode || 0,
        paymentMethodId: this.form?.value?.beneficiary?.paymentMethod?.ocode || 0,
        bankId: this.form?.value?.beneficiary?.bank?.ocode || 0,
        accountTypeId: this.form?.value?.beneficiary?.accountType?.ocode || 0,
        status: true,
      };
      beneficiary.companyId = newsType?.companyId;
      beneficiary.employeeId = this.data.employeeId;
      if(beneficiary?.beneficiaryId > 0){
        this.updateBeneficiary(beneficiary, newsType);
      }else{
        beneficiary.createDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
        beneficiary.beneficiaryId = 0;
        beneficiary.createUserId = +this.authInfo.getUser()?.userId;
        this.postBeneficiary(beneficiary, newsType, () => {
          this.changePayrollNew(newsType);
        });
      }
    } else {
      this.changePayrollNew(newsType);
    }
  }

  changePayrollNew(newsType) {
    this.additionalPayroll.putNewsAdditionalPayroll(newsType).subscribe((res:any) => {


      if(!res.succeded){
        res?.warnings?.forEach((warn)=>{
          this._toastService.warning(warn);
        })
        res?.errors?.forEach((err)=>{
          this._toastService.error(err);
        })
        return
      }


      this._toastService.success('Datos guardados')
      this.dialogRef.close({success: true})
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }

  getNewsValues(): INewsPOST{
    const form = this.form.value
    const news: INewsPOST = {
      amount: form.amount,
      newsConceptId: form.concept.newsConceptId,
      companyId: this.authInfo.getCompanyId(),
      createDate: new Date(),
      createUserId: this.authInfo.getUserId(),
      employeeId: this.employeeSelected.employeeId,
      evidence: this.evidenceGuid ? this.evidenceGuid : null,
      newsTypeId: this.newsType.newsTypeId,
      payrollNewsId: this.data.mode == 'edit' ? this.data.newId : 0,
      positionId: this.employeeSelected.positionId,
      startDate: this.datePipe.transform(this.form?.value?.startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(this.form?.value?.endDate, 'yyyy-MM-dd'),
      beneficiaryId: this.form?.value?.beneficiary?.beneficiaryId || 0,
      balance: form?.balance || 0,
      manualValue: form?.manualValue || 0,
      defaultValue: form?.defaultValue || 0,
      totalAmount: form?.totalAmount || 0,
      status: true,
      sourceId: 3,
      condition: 0,
      countApproves: 0
    }
    return news;
  }

  buildForm(){
    return this.formBuilder.group({
      newsType: ['', [Validators.required]],
      concept: ['', Validators.required],
      conceptId: [0, [Validators.min(1), Validators.required]],
      amount: [0, [Validators.required]],
      startDate: [''],
      endDate: [''],
      balance: [0],
      newsTypeId: [0, [Validators.min(1), Validators.required]],
      defaultValueSeleted: [null],
      manualValue: [0],
      defaultValue: [0],
      totalAmount: [0],
      evidence: [''],
      beneficiary: this.formBuilder.group({
        beneficiaryId: [0],
        employeeId: [0],
        identificationTypeId: [0],
        paymentMethodId: [0],
        bankId: [0],
        accountTypeId: [0],
        status: [true],
        companyId: [0],
        createDate: [null],
        createUserId: [0],
        modifyDate: [null],
        modifyUserId: [0],
        identificationNumber: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        typeDocument: ['', [Validators.required]],
        paymentMethod: ['', [Validators.required]],
        bank: ['', [Validators.required]],
        accountType: [''],
        accountNumber: [''],
        office: [''],
      })
    })
  }

  get isNewTypeLitisSeleted() {
    const isLitis = this.form.get('newsType').value?.newsTypeId === 3;
    return isLitis;
  }

  getNewsType(){
    this.additionalPayroll.getNewsType().subscribe((res:any) => {
      if(res.succeded){
        this.newsTypeList = res.dataList.filter(d => d.status);
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error("Ha ocurrido un error inesperado", '')
    })
  }
  getConcepts(){
    this.additionalPayroll.getNewsConcept().subscribe((res:any) => {
      if(res.succeded){
        this.concepts = res.dataList.filter(x => x.status);
        if(this.data.newId> 0){
          this.getNewsById(this.data.newId)
        }
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado', '')
    })
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }


  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  
  getEmployeeByNumber(employeeId: number){
    this.additionalPayroll.getEmployeeById(employeeId).subscribe((res: ResponseModel<IEmployeeGET>) => {
      if(res.succeded){
        this.employeeSelected = res.dataList[0]
        const img = this.employeeSelected.profileImage
        if(img){
          this.getDocument(img)
        }
      }else{

      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }

  selectNewsType(firstLoad: boolean = false){
    this.newsType = this.form.get('newsType').value;

    if(!firstLoad){
      this.form.get('conceptId').patchValue(0);
      this.form.get('concept').patchValue(null);
    }
 
    if(!this.newsType?.newsTypeId){
      this.conceptsFiltered = [];
      this.isLitisSelected = false;
      this.form.get('newsTypeId').setValue(0);
      return;
    }else{
      this.form.get('newsTypeId').setValue(this.newsType?.newsTypeId);
    }

    this.conceptsFiltered = this.concepts.filter(x=>x.newsTypeId == this.newsType.newsTypeId);

    this.isLitisSelected = this.newsType?.newsTypeId === 3;
    this.showAndHideBeneficiary(this.isLitisSelected);

    const typeId = this.newsType.newsTypeId;
    if (typeId == 4) {
      this.hideFieldByFiscalCredit();
    }
    if (typeId == 3) {
      this.hideFieldByLitis();
    }
    if (typeId == 2) {
      this.hideFieldByAdditional();
    }
    if (typeId == 1) {
      this.hideFieldByExternal();
    }
    if(typeId == 4){
      this.form.get('balance')?.setValue(this.form.get('totalAmount')?.value);
      this.form.get('balance').disable();
    }else{
      this.form.get('balance').enable();
    }
  }


  checkNewType(type) {
    if (type == 4) {
      this.hideFieldByFiscalCredit();
      return;
    }
    if (type == 3) {
      this.hideFieldByLitis();
      return;
    }
    if (type== 2) {
      this.hideFieldByAdditional();
      return;
    }
    if (type == 1) {
      this.hideFieldByExternal();
      return;
    }
  }

  showAndHideBeneficiary(show: boolean){
    this.form.get('beneficiary').reset();
    if(show){
      this.form.get('beneficiary').enable();
    }else{
      this.form.get('beneficiary').disable();
    }
  }

  selectConcept(item){
    const concept = item.value
    if(Array.isArray(concept)){
      if(concept.length == 0){
        this.form.get('conceptId').patchValue(0)
      }
    }else{
      if (concept?.newsTypeId === 2) {
        const newsType = this.form.get('newsType').value;
        this.getNewsConceptsSetting(concept?.newsConceptId || 0, newsType);
      }else{
        this.resetConcetsSetting();
      }
      this.form.get('conceptId').setValue(concept.newsConceptId);
    }
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getDocument(guid: string){
    this.srvDoc.getDocument(guid).subscribe((res:any) => {
      this.employeeImg = res.data
    })
  }

  getErrors(name){
    const field = this.form.get(name)
    if(field.hasError('required') && (field.dirty || field.touched)){
      return 'Este campo es requerido'
    }
    return ''
  }

  changePaymentMethod() {
    this.setCheckIsSelected()
  }

  setCheckIsSelected() {
    const field = this.form.get('beneficiary').value.paymentMethod.ocode
    this.checkIsSelected = field == paymentMethods.check
  }

  get showErrors() {
    return {
      erorNewType: this.form.get('newsType').invalid,
      errorConcept: this.form.get('conceptId').invalid,
      errorAmount: this.form.get('amount').invalid,
      errorManualValue: this.form.get('manualValue').invalid,
      erorDefaultValue: this.form.get('defaultValueSeleted').invalid,
      errorStartDate: this.form.get('startDate').invalid,
      errorTotalAmount: this.form.get('totalAmount').invalid,
      errorsBeneficiary: {
        errorIdentificationNumber: this.form.get('beneficiary.identificationNumber').invalid,
        errorFirstName: this.form.get('beneficiary.firstName').invalid,
        errorLastName: this.form.get('beneficiary.lastName').invalid,
        errorTypeDocument: this.form.get('beneficiary.typeDocument').invalid,
        errorBank: this.form.get('beneficiary.bank').invalid,
        errorAccountType: this.checkIsSelected == true ? false : this.form.get('beneficiary.accountType').value < 1,
        errorAccountNumber: this.checkIsSelected == true ? false : this.form.get('beneficiary.accountNumber').value < 1,
        errorOfice: this.checkIsSelected == true ? false : this.form.get('beneficiary.office').value < 1,
        errorPaymentMethod: this.form.get('beneficiary.paymentMethod').invalid,
      }
    }
  }


  onChangeTotalAmount(){
    let newsTypeId = this.form.get('newsTypeId')?.value;
    if(newsTypeId){
      if(newsTypeId == 4){
        this.form.get('balance')?.setValue(this.form.get('totalAmount')?.value);
      }
    }

  }

  hideFieldByLitis() {
    this.isLitisSelected = true;
    this.isAdditionalSelected = false;
    this.isExternalSelected = false;
    this.isFiscalCreditSelected = false;
    //show
    this.form.get('beneficiary').enable();
    this.form.get('amount').enable();
    this.form.get('amount').setValidators(Validators.required);
    this.form.get('amount').updateValueAndValidity();
    //hide
    this.form.get('defaultValueSeleted').setValidators(null);
    this.form.get('defaultValueSeleted').updateValueAndValidity();
    this.form.get('manualValue').setValidators(null);
    this.form.get('manualValue').updateValueAndValidity();
    this.form.get('totalAmount').disable();
    this.form.get('totalAmount').setValidators(null);
    this.form.get('totalAmount').updateValueAndValidity();
    this.form.get('balance').disable();
    this.form.get('manualValue').disable();
    this.form.get('defaultValueSeleted').disable();
    this.form.get('totalAmount').setValue(0);
    this.form.get('balance').setValue(null);
    this.form.get('manualValue').setValue(null);
    this.form.get('defaultValueSeleted').setValue(null);
  }

  hideFieldByExternal() {
    this.isLitisSelected = false;
    this.isAdditionalSelected = false;
    this.isFiscalCreditSelected = false;
    this.isExternalSelected = true;
    this.form.get('manualValue').setValue(null);
    //hide
    this.form.get('manualValue').disable();

    this.form.get('manualValue').setValidators(null);
    this.form.get('manualValue').updateValueAndValidity();
    this.form.get('defaultValueSeleted').setValidators(null);
    this.form.get('defaultValueSeleted').updateValueAndValidity();
    this.form.get('defaultValueSeleted').disable()
    this.form.get('beneficiary').disable();
    this.form.get('defaultValueSeleted').setValue(null);
    //show
    this.form.get('amount').enable();
    this.form.get('amount').setValidators(Validators.required);
    this.form.get('amount').updateValueAndValidity();
    this.form.get('totalAmount').enable();
    this.form.get('totalAmount').setValidators(null);
    this.form.get('totalAmount').updateValueAndValidity();
    this.form.get('balance').enable();
    this.form.get('beneficiary').reset();
  }

  hideFieldByFiscalCredit() {
    this.isLitisSelected = false;
    this.isAdditionalSelected = false;
    this.isExternalSelected = false;
    this.isFiscalCreditSelected = true;
    this.form.get('manualValue').setValue(null);
    //hide
    this.form.get('manualValue').disable();

    this.form.get('manualValue').setValidators(null);
    this.form.get('manualValue').updateValueAndValidity();
    this.form.get('defaultValueSeleted').setValidators(null);
    this.form.get('defaultValueSeleted').updateValueAndValidity();
    this.form.get('defaultValueSeleted').disable()
    this.form.get('beneficiary').disable();
    this.form.get('amount').disable();
    this.form.get('amount').setValidators(null);
    this.form.get('amount').updateValueAndValidity();
    this.form.get('amount').setValue(null);
    this.form.get('defaultValueSeleted').setValue(null);
    this.form.get('balance').disable();
    //show

    this.form.get('totalAmount').enable();
    this.form.get('totalAmount').setValidators(Validators.required);
    this.form.get('totalAmount').updateValueAndValidity();
    this.form.get('beneficiary').reset();
  }


  hideFieldByAdditional() {
    this.isLitisSelected = false;
    this.isAdditionalSelected = true;
    this.isExternalSelected = false;
    this.isFiscalCreditSelected = false;
    //Hide
    this.form.get('amount').setValue(null);
    this.form.get('totalAmount').setValue(0);
    this.form.get('balance').setValue('');
    this.form.get('amount').disable();
    this.form.get('amount').setValidators(null);
    this.form.get('amount').updateValueAndValidity();
    this.form.get('totalAmount').disable();
    this.form.get('totalAmount').setValidators(null);
    this.form.get('totalAmount').updateValueAndValidity();
    this.form.get('beneficiary').disable();
    this.form.get('balance').disable();
    //Show
    if(!this.conceptSetting?.newsConceptSettingId){
      this.form.get('manualValue').enable();
      this.form.get('defaultValueSeleted').enable();
      this.form.get('defaultValueSeleted').setValidators([Validators.required]);
      this.form.get('defaultValueSeleted').updateValueAndValidity();
      this.form.get('manualValue').setValidators([Validators.required]);
      this.form.get('manualValue').updateValueAndValidity();
    }

    this.form.get('beneficiary').reset();
  }


  hideManuelValue(){
    if(this.manualValue?.nativeElement){
      this.manualValue.nativeElement.style.display='none';
    }
    this.form.get('manualValue').setValue(null);
    this.form.get('manualValue').disable();
    this.form.get('manualValue').setValidators(null);
    this.form.get('manualValue').updateValueAndValidity();
  }

  showManuelValue(){
    if(this.manualValue?.nativeElement){
      this.manualValue.nativeElement.style.display='block';
    }

    this.form.get('manualValue').enable();
  }

  hideDefaultValue(){
    if(this.defaultValue?.nativeElement){
      this.defaultValue.nativeElement.style.display='none';
    }

    this.form.get('defaultValueSeleted').setValue(null);
    this.form.get('defaultValueSeleted').disable();
  }

  showDefaultValue(){
    if(this.defaultValue?.nativeElement){
      this.defaultValue.nativeElement.style.display='block';
    }
    this.form.get('defaultValueSeleted').enable();
  }

  onChangeDefaultValue(value: DefaultValue){
    this.form.get('defaultValue').setValue(value?.conceptValuesId);
  }

  showField() {
    this.isLitisSelected = false;
    this.isAdditionalSelected = false;
    this.isExternalSelected = false;
    this.form.get('amount').enable();
    this.form.get('balance').enable();
    this.form.get('totalAmount').enable();
    this.form.get('manualValue').enable();
    this.form.get('defaultValueSeleted').enable();
    this.form.get('balance').enable();
  }


  onChangeTypeDocument(e) {
    if (e.value.ocode == 1) {
      this.mask = "000-0000000-0"
      this.maskText = 'Ej: 001-000000-2'
    } else {
      this.mask = ''
      this.maskText = ''
    }
    this.form.patchValue({
      beneficiary: {
        identificationNumber: ''
      }
    })
  }

  setEvidence(guid: GUID){
    this.form.get('evidence').setValue(guid);
  }

}