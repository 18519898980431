<!--h2 class="mat-dialog-title">Filtrar por fecha</h2-->
<div class="modal-header border-0 dv-table-header-1 bg-primary mb-4" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
    Filtrar por fecha</h2>
  <button type="button" (click)="dialogRef.close(false)" class="close" aria-label="Close">
    <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
  </button>
</div>

<div class="container pb-4 responsiveWidth">
  <form [formGroup]="form">
    <div class="row">
      <div class="col text-right">
        <button class="btn btn-light-warning ml-4" (click)="clear()">
          <i class="fas fa-redo"></i>
        </button>
      </div>

    </div>
    <div class="row mb-4">
      <div class="col-md-12">
        <label for="" class="fw-bold">Sucursal</label>

        <ngx-select-dropdown formControlName="company" [multiple]='false' (change)="changeCompany()"
          [options]="dropdownItems" [config]="dropdownConfig('companyName')" class="w-100"></ngx-select-dropdown>


      </div>
      <ng-container *ngIf="this.hideYearField == false">

        <div class="col-md-12">
          <label for="" class="fw-bold">Año</label>
          <ng-container *ngIf="!homeConfig">

            <select formControlName="year" (change)="selectYear()" class="form-select" name="" id="">
              <option [value]="null">Seleccionar</option>
              <option *ngFor="let i of years" [value]="i.year">{{i.year}}</option>
            </select>
          </ng-container>

          <ng-container *ngIf="homeConfig">
            <ngx-select-dropdown [multiple]='true' (change)="changeYear()" [options]="years"
              [config]="dropdownConfig('year')" formControlName="year" class="w-100"></ngx-select-dropdown>
          </ng-container>

        </div>
      </ng-container>
    </div>
    <div class="row mb-4">
      <div class="col-md-6">
        <label for="" class="fw-bold">Desde</label>
        <input formControlName="date1" (change)="selectDate1()" type="date" class="form-control" name="" id="">
      </div>
      <div class="col-md-6">
        <label for="" class="fw-bold">Hasta</label>
        <input formControlName="date2" type="date" (change)="selectDate2()" class="form-control" name="" id="">
      </div>
      <div class="col-md-12 align-items-center">
        <span class="text-danger" [innerText]="errorMessage"></span>
      </div>
    </div>
  </form>

</div>

<div class="mat-dialog-actions" [align]="'end'">

  <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2 "
    (click)="close()" type="button">
    <i class="flaticon2-cross"></i>
    Cerrar
  </button>
  <button class="btn btn-pill font-weight-bolder btn-shadow btn-success fw-bold" (click)="closeModal()"> <i
      class="flaticon-disco-flexible"></i> Aplicar filtro</button>
</div>