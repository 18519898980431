<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon">
                <i class="flaticon-recursos-humanos-4  icon-3x text-danger"></i>
            </span>
            <h2 class="d-flex flex-column text-primary">
                Servidores Públicos
            </h2>
        </div>
        <div class="card-toolbar">
            <div class="form-group">
                <label>Búsqueda de Servidores Públicos<span class="text-danger">*</span></label>
                <div class="input-group">
                    <input (input)="filterEmployee()" [(ngModel)]="employeeFilter" type="text" class="form-control form-control-solid form-control-lg" placeholder="Escriba aquí su búsqueda">
                    <button (click)="ApplySearch()" class="input-group-text bg-primary" data-toggle="tooltip" title="Buscar">
                        <i class="flaticon2-search-1 icon-2x text-white"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row gutter-b">
            <div class="table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-center">Código del Servidor Público</th>
                            <th class="text-left">Servidor Público</th>
                            <th class="text-left">Unidad Organizativa</th>
                            <th class="text-left">Posición</th>
                            <th class="text-center">Cédula</th>
                            <th class="text-center">Teléfono Primario</th>
                            <th class="text-center">Tipo de Servidor Público</th>
                            <th class="actionsBtn">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let employee of paginatedEmployees">
                            <td class="text-center">{{employee.employNumber}}</td>
                            <td class="text-left">{{employee.name}}</td>
                            <td class="text-left">{{employee.departmentName}}</td>
                            <td class="text-left">{{employee.positionName}}</td>
                            <td class="text-center">{{employee.personalIdentification}}</td>
                            <td class="text-center">(849)-562-3457</td>
                            <td class="text-center">{{employee.employeeType}}</td>
                            <td class="actionsBtn">
                                <a [routerLink]="['/personnel-actions/',employee.employNumber]"
                                    class="btn btn-icon btn-light-success btn-sm m-1" data-toggle="tooltip" title="Editar Servidor Público"><i class="flaticon-edit"></i></a>
                                <a [routerLink]="['/pdf-preview/',employee.personId]" 
                                    class="btn btn-icon btn-light-info btn-sm m-1" data-toggle="tooltip" title="Ver Servidor Público"><i class="flaticon-ver"></i></a>
                                <a [routerLink]="['/action-change-employee']"
                                    class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip" title="Acción de Cambio a Servidor Público"><i class="flaticon-colaboracion"></i></a>
                                <!-- <a class="btn btn-icon btn-light-danger btn-sm m-1"><i class="flaticon-list-2"></i></a> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="card-footer d-flex justify-content-end">
                    <div >
                        <el-caption [originalRecordsLength]="employees.length" [listedRecordsLength]="paginatedEmployees.length" #paginator [registerQuantity]="employees.length" [records]="employees" (onChage)="getPaginatedRecords($event)"></el-caption>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>