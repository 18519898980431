import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'el-progres-bar',
  template: `
    <div class="progress">
      <div
        class="progress-bar"
        [class]="buildClass"
        [style]="'width: ' + percent + '%'"
        role="progressbar"
        [attr.aria-valuenow]="percent"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  `,
})
export class ProgresBarComponent implements OnInit {
  @Input() color: string = 'info';
  @Input() isStriped: boolean = false;
  @Input() isAnimated: boolean = false;
  @Input() percent: string = '0';

  striped: string = '';
  animated: string = '';

  constructor() {}

  ngOnInit(): void {
    this.striped = this.isStriped ? 'progress-bar-striped' : this.striped;
    this.animated = this.isAnimated ? 'progress-bar-animated' : this.animated;
  }

  get buildClass(): string {
    return `progress-bar-${this.color} ${this.striped} ${this.animated}`;
  }
}
