<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span
            class="h-20px label label-danger label-dot mr-3 w-20px"></span>{{titleModal}}</h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>

</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom mt-0 pt-0">
        <div class="card-header py-3 row justify-content-start" *ngIf="!data?.item">
            <div class="col-sm-12  c-right mx-0">
                <div class="card-toolbar">
                    <div class="text-nowrap ml-0">
                        <button id="searchBarBtn" (click)="openModalSearchEmployee(null)"
                            class=" btn btn-primary text-white" data-toggle="tooltip" title="Buscar">
                            <i class="flaticon2-search-1 icon-2x text-white mr-2"></i> <b> Seleccionar Servidor Público</b>
                        </button>
                    </div>
                </div>
            </div>


        </div>

        <div class="card-body mt-0 pt-4 row justify-content-center">
            <div class="row">
                <div class="col-md-12">
                    <div class="bg-light-info rounded row h-100">
                        <div class="text-center col-md-3 center">
                            <div class="symbol symbol-150 d250 rd20 mr-3 my-5 center">
                                <img *ngIf="PersonSelected?.photoloaded" alt="Pic" [src]="PersonSelected?.photoloaded" />
                                <img alt="Pic" *ngIf="!PersonSelected?.photoloaded"
                                    src="../../../../assets/images/no-user.png" onerror="this.error=null; this.src='assets/images/no-user.png'" />
                            </div>
                        </div>
                        <div class="col-md-8">
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary ">Nombre: <span
                                    class="text-dark ml-3">{{PersonSelected?.name}}</span></label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary ">Identificación:
                                <span class="text-dark ml-3"
                                    *ngIf="PersonSelected?.typeIdentificationId === 1">{{PersonSelected?.personalIdentification
                                    | mask:'000-0000000-0'}}</span>
                                <span class="text-dark ml-3"
                                    *ngIf="PersonSelected?.typeIdentificationId !== 1">{{PersonSelected?.personalIdentification}}</span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary ">Teléfono:
                                <span class="text-dark ml-3">{{PersonSelected?.phoneNumber |
                                    mask:'(000)-000-0000'}}</span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary ">Correo Institucional: <span
                                    class="text-dark ml-3">{{PersonSelected?.institionalEmail}}</span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Supervisor Inmediato: <span
                                    class="text-dark ml-3">{{PersonSelected?.employeeSupervisor}}</span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Supervisor de Unidad Organizativa: <span
                                    class="text-dark ml-3">{{PersonSelected?.supervisor}}</span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Fecha de Ingreso:
                                <span class="text-dark ml-3">{{PersonSelected?.admissionDate|date:"dd/MM/yyyy"}}</span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Salario Bruto: <span
                                    class="text-dark ml-3">{{PersonSelected?.salary | currency:'RD$'}}
                                </span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Unidad Organizativa: <span
                                    class="text-dark ml-3 h6">{{PersonSelected?.departmentName}}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card-body">
            <form [formGroup]="form">
                <div class="row">
                    <div class="form-group col-md-6 col-lg-4">
                        <label class="d-block">Tipo de Novedad<span
                                class="text-danger text-monospace legend">*</span></label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [options]="newtyTypes" [config]="dropdownConfig('description')"
                                (change)="onChangeNewType($event.value)" formControlName="newType" class="w-100">
                            </ngx-select-dropdown>
                            <span class="form-text text-danger" *ngIf="formIsInvalid && showErrors.erorNewType">
                                Este campo es requerido
                            </span>
                        </div>
                    </div>

                    <div class="form-group col-md-6 col-lg-4">
                        <label class="d-block">Concepto<span class="text-danger text-monospace legend">* </span></label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [options]="concepts" [config]="dropdownConfig('description')"
                                (change)="onChangeConcept($event.value)" formControlName="concept" class="w-100">
                            </ngx-select-dropdown>
                            <span class="form-text text-danger" *ngIf="formIsInvalid && showErrors.errorConcept">
                                Este campo es requerido
                            </span>
                        </div>
                    </div>

                    <div class="form-group col-md-6 col-lg-4" *ngIf="!isFiscalCreditSelected && !isExternalSelected && !isLitisSelected"
                    #defaultValue>
                        <div>
                            <label for="quantityPlaces">
                                Valor Predefinido<span class="text-danger text-monospace legend">*</span>
                            </label>
                        </div>

                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [options]="defaultValues" [config]="dropdownConfig('description')"
                               (change)="onChangeDefaultValue($event.value)"  formControlName="defaultValueSeleted" class="w-100">
                            </ngx-select-dropdown>
                            <span class="form-text text-danger" *ngIf="formIsInvalid && showErrors.erorDefaultValue">
                                Este campo es requerido
                            </span>
                        </div>
                    </div>

                    <div class="form-group col-md-6 col-lg-4" *ngIf="!isFiscalCreditSelected && !isAdditionalSelected">
                        <div>
                            <label for="quantityPlaces">
                                Monto<span class="text-danger text-monospace legend">* </span>
                            </label>
                        </div>
                        <input formControlName="amount" class="form-control form-control-lg dynamicFormControl"
                            id="quantityPlaces" placeholder="RD$00.00" type="text" currencyMask
                            [options]="{precision:2}" />
                        <span class="form-text text-danger" *ngIf="formIsInvalid && showErrors.errorAmount">
                            Este campo es requerido
                        </span>
                    </div>

                    <div class="form-group col-md-6 col-lg-4" *ngIf="!isAdditionalSelected && !isLitisSelected">
                        <div>
                            <label for="quantityPlaces">
                                Monto Total
                            </label>
                        </div>
                        <input formControlName="totalAmount" class="form-control form-control-lg dynamicFormControl"
                            id="quantityPlaces" placeholder="RD$00.00" type="text" currencyMask (keyup)="onChangeTotalAmount()"
                            [options]="{precision:2}" />
                    </div>

                    <div class="form-group col-md-6 col-lg-4" *ngIf="!isAdditionalSelected && !isLitisSelected">
                        <div>
                            <label for="quantityPlaces">
                                Saldo
                            </label>
                        </div>
                        <input formControlName="balance" class="form-control form-control-lg dynamicFormControl"
                            id="quantityPlaces" placeholder="RD$00.00" type="text" currencyMask
                            [options]="{precision:2}"   />
                        <span class="form-text text-danger" *ngIf="formIsInvalid && showErrors.errorBalance">
                            Este campo es requerido
                        </span>
                    </div>



                    <div class="form-group col-md-6 col-lg-4" *ngIf="!isFiscalCreditSelected && !isExternalSelected && !isLitisSelected"
                    #manualValue>
                        <div>
                            <label for="quantityPlaces">
                                Valor Manual<span class="text-danger text-monospace legend">*</span>
                            </label>
                        </div>
                        <input formControlName="manualValue" class="form-control form-control-lg dynamicFormControl"
                            id="quantityPlaces" placeholder="RD$00.00" required="" type="text" currencyMask
                            [options]="{precision:2}" />
                        <span class="form-text text-danger" *ngIf="formIsInvalid && showErrors.errorManualValue">
                            Este campo es requerido
                        </span>
                    </div>



                    <div class="form-group col-md-6 col-lg-4">
                        <div>
                            <label for="dateAdmision">
                                Fecha Inicio<span class="text-danger text-monospace legend">*</span>
                            </label>
                        </div>
                        <input formControlName="startDate" class="form-control form-control-lg dynamicFormControl"
                            id="dateAdmision" placeholder="Fecha de ingreso" type="date" />
                        <span class="form-text text-danger" *ngIf="formIsInvalid && showErrors.errorStartDate">
                            Este campo es requerido
                        </span>
                    </div>

                    <div class="form-group col-md-6 col-lg-4">
                        <div>
                            <label for="dateAdmision">
                                Fecha Final
                            </label>
                        </div>
                        <input formControlName="endDate" class="form-control form-control-lg dynamicFormControl"
                            id="dateAdmision" placeholder="Fecha de ingreso" type="date" />
                        <!-- <span class="form-text text-danger" *ngIf="formIsInvalid && showErrors.errorEndDate">
                            Este campo es requerido
                        </span> -->
                    </div>

                    <div class="form-group col-md-6 col-lg-4" *ngIf="!isFiscalCreditSelected && !isExternalSelected && !isLitisSelected">
                        <div>
                            <label for="dateAdmision">
                                Fecha Aplicación
                            </label>
                        </div>
                        <input formControlName="applicationDate" class="form-control form-control-lg dynamicFormControl"
                            id="dateAdmision" placeholder="Fecha de ingreso" type="date" />
                        <span class="form-text text-danger" *ngIf="formIsInvalid && showErrors.errorApplicationDate">
                            Este campo es requerido
                        </span>
                    </div>

                    <div class="form-group col-md-6 col-lg-4">
                        <div>
                            <label for="dateAdmision">
                                Observación
                            </label>
                        </div>

                        <textarea formControlName="observation"
                            class="form-control form-control-lg dynamicFormControl"></textarea>
                        <span class="form-text text-danger" *ngIf="formIsInvalid && showErrors.errorObservation">
                            Este campo es requerido
                        </span>
                    </div>

                    <div class="form-group col-md-6 col-lg-4">
                        <label>Evidencia </label>
                        <div class="card align-items-center">
                            <uppy-file 
                                #uppyFile
                                cardBody="card-body img-fluid"
                                classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px"
                                targetClass="evidence"
                                [previewDefaultImage]="imgDefaultPdf"
                                [width]="320"
                                [height]="295"
                                [isDisabled]="IsViewMode"
                                [showDocumentoAfterUploaded]="false"
                                [fileForEditting]="guiID"
                                [allowedFileTypes]="['.jpg','.png','application/pdf']"
                                (fileEmitter)="setEvidence($event)">
                            </uppy-file>
                        </div>
                        <span class="form-text text-danger" *ngIf="formIsInvalid && showErrors.errorEvidence">
                            Este campo es requerido
                        </span>
                    </div>

                </div>


                <div class="row" *ngIf="isNewTypeLitisSeleted" formGroupName="beneficiary">
                    <div data-toggle="collapse" class="" id="heading-2a" data-target="#prefix6">
                        <h3 class="bg-primary col-md-12 gutter-b justify-content-between d-flex align-items-center">
                            <button data-toggle="collapse"
                                class="btn text-left font-size-h3 font-weight-bold text-white button-custom">
                                Datos del Beneficiario
                            </button>
                            <span class="svg-icon svg-icon-white svg-icon-3x">
                                <svg _ngcontent-geh-c123="" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                    viewBox="0 0 24 24" version="1.1">
                                    <g _ngcontent-geh-c123="" stroke="none" stroke-width="1" fill="none"
                                        fill-rule="evenodd">
                                        <polygon _ngcontent-geh-c123="" points="0 0 24 0 24 24 0 24"></polygon>
                                        <path _ngcontent-geh-c123=""
                                            d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                            fill="#000000" fill-rule="nonzero"></path>
                                        <path _ngcontent-geh-c123=""
                                            d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                            fill="#000000" fill-rule="nonzero" opacity="0.3"
                                            transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) ">
                                        </path>
                                    </g>
                                </svg>
                            </span>
                        </h3>
                    </div>

                    <div data-parent="#accordion" class="" id="prefix6">
                        <div class="row">
                            <div class="form-group col-xl-4 col-lg-4">
                                <label class="">Nombres<span class="text-danger text-monospace legend">*</span></label>
                                <input formControlName="firstName" type="text"
                                    class="form-control form-control-solid form-control-lg" name="" id="">
                                <span class="form-text text-danger"
                                    *ngIf="formIsInvalid && showErrors.errorsBeneficiary.errorFirstName">
                                    Este campo es requerido
                                </span>
                            </div>


                            <div class="form-group col-xl-4 col-lg-4">
                                <label class="">Apellidos<span
                                        class="text-danger text-monospace legend">*</span></label>
                                <input formControlName="lastName" type="text"
                                    class="form-control form-control-solid form-control-lg" name="" id="">
                                <span class="form-text text-danger"
                                    *ngIf="formIsInvalid && showErrors.errorsBeneficiary.errorLastName">
                                    Este campo es requerido
                                </span>
                            </div>


                            <div class="form-group col-xl-4 col-lg-4">
                                <label class="">Tipo de Documento<span
                                        class="text-danger text-monospace legend">*</span></label>
                                <ngx-select-dropdown [options]="typeDocuments" [config]="dropdownConfig('stringData')"
                                    class=" flex-1" (change)="onChangeTypeDocument($event)"
                                    formControlName="typeDocument">
                                </ngx-select-dropdown>
                                <span class="form-text text-danger"
                                    *ngIf="formIsInvalid && showErrors.errorsBeneficiary.errorTypeDocument">
                                    Este campo es requerido
                                </span>
                            </div>

                            <div class="form-group col-xl-4 col-lg-4">
                                <label class="">Número de Documento<span
                                        class="text-danger text-monospace legend">*</span></label>
                                <input formControlName="identificationNumber" type="text" [mask]="mask" maxlength="13"
                                    class="form-control form-control-solid form-control-lg" name="" id="">
                                <span class="form-text text-danger"
                                    *ngIf="formIsInvalid && showErrors.errorsBeneficiary.errorIdentificationNumber">
                                    Este campo es requerido
                                </span>
                            </div>

                            <div class="form-group col-xl-4 col-lg-4">
                                <label class="">Forma de Cobro<span
                                        class="text-danger text-monospace legend">*</span></label>
                                <ngx-select-dropdown [options]="paymentMethods" [config]="dropdownConfig('stringData')"
                                    (change)="changePaymentMethod($event)" class=" flex-1"
                                    formControlName="paymentMethod">
                                </ngx-select-dropdown>
                                <span class="form-text text-danger"
                                    *ngIf="formIsInvalid && showErrors.errorsBeneficiary.errorPaymentMethod">
                                    Este campo es requerido
                                </span>
                            </div>

                            <div class="form-group col-xl-4 col-lg-4">
                                <label class="">Banco<span class="text-danger text-monospace legend">*</span></label>
                                <ngx-select-dropdown [options]="banks" [config]="dropdownConfig('stringData')"
                                    class=" flex-1" formControlName="bank">
                                </ngx-select-dropdown>
                                <span class="form-text text-danger"
                                    *ngIf="formIsInvalid && showErrors.errorsBeneficiary.errorBank">
                                    Este campo es requerido
                                </span>
                            </div>

                            <div *ngIf="!checkIsSelected" class="form-group col-xl-4 col-lg-4">
                                <label class="">Tipo de Cuenta<span
                                        class="text-danger text-monospace legend">*</span></label>
                                <ngx-select-dropdown [options]="accountTypes" [config]="dropdownConfig('stringData')"
                                    class=" flex-1"
                                    formControlName="accountType">
                                </ngx-select-dropdown>
                                <span class="form-text text-danger"
                                    *ngIf="formIsInvalid && showErrors.errorsBeneficiary.errorAccountType">
                                    Este campo es requerido
                                </span>
                            </div>

                            <div *ngIf="!checkIsSelected" class="form-group col-xl-4 col-lg-4">
                                <label class="">Número de Cuenta<span
                                        class="text-danger text-monospace legend">*</span></label>
                                <input formControlName="accountNumber" type="text"
                                    class="form-control form-control-solid form-control-lg" name="" id="">
                                <span class="form-text text-danger"
                                    *ngIf="formIsInvalid && showErrors.errorsBeneficiary.errorAccountNumber">
                                    Este campo es requerido
                                </span>
                            </div>

                            <div *ngIf="!checkIsSelected" class="form-group col-xl-4 col-lg-4">
                                <label class="">Oficina Bancaria<span class="text-danger text-monospace legend">*</span></label>
                                <input formControlName="office" type="text"
                                    class="form-control form-control-solid form-control-lg" name="" id="">
                                <span class="form-text text-danger"
                                    *ngIf="formIsInvalid && showErrors.errorsBeneficiary.errorOfice">
                                    Este campo es requerido
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="!IsViewMode" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
        (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>