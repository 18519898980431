import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {

  private api = environment.api_url
  constructor(
    private http: HttpClient
  ) { }

  getSocialNetworks(companyId){
    return this.http.get(`${this.api}/SocialNetworks?SocialNetworkId=0&CompanyId=${companyId}&Status=true&Type=0`)
  }

  postSocialNetworks(model){
    return this.http.post(`${this.api}/SocialNetworks`, model)
  }
  putSocialNetworks(model){
    return this.http.put(`${this.api}/SocialNetworks`, model)
  }

  deleteSocialNetwork(id, userid){
    return this.http.delete(`${this.api}/SocialNetworks?SocialNetworkId=${id}&UserId=${userid}`)
  }

}
