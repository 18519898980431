import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CompetencyService } from 'src/app/services/performance-evaluation/competency.service';
import { PositionCompetency } from '../../models/positions-description.model';

@Component({
  selector: 'app-view-behaviors',
  templateUrl: './view-behaviors.component.html',
  styleUrls: ['./view-behaviors.component.css']
})
export class ViewBehaviorsComponent implements OnInit, AfterViewChecked {
  behaviors: any[] = [];
  behaviorsRecord: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<ViewBehaviorsComponent>,
    private dialog: MatDialog,
    private competencyService: CompetencyService,
    private toastService: ToastrService,  private _changeDet: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {
      dictionary: PositionCompetency,
      filterType?: number | null
    },
  ) { }

  ngOnInit(): void {
    this.getBehaviours();
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  getBehaviours(){

    const type: number = this.data?.filterType ? this.data.filterType : 1;

    this.competencyService.getBehaviours(0,this.data?.dictionary?.capabilityId, this.data?.dictionary?.competencyId,0,type).subscribe({
      next: res => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          });

          res.warnings.forEach(err => {
            this.toastService.warning(err);
          })

          return;
        }
        
        this.behaviors = res?.dataList;
      }
    })
  }

  getPaginatedRecords(event) {
    this.behaviorsRecord = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

}