import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PostulationInterview } from '../pages/interview-evaluation/models/PostulationInterview';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class InterviewEvaluationService {

  apiCore = environment.api_core_url;// 'http://172.16.11.173/core_back/api'
  api = environment.api_url; // 'https://hrmapi.azurewebsites.net/api'
  constructor(
    private http: HttpClient,
    private authInfo: AuthInfoService
  ) { }

  getInterviewHistory(postulationId) {
    return this.http.get(this.api + `/PostulationsInterviews?type=2&idPostulationInterview=${postulationId}&companyId=${this.authInfo.getCompanyId()}`);
  }


  getPostulationsByDocumentID(personalIdentification): Observable<any> {
    return this.http.get(this.api + `/PersonsPostulationVacants/GetPostulation?Type=2&Person=${personalIdentification}`);
  }

  getAllPostulationsByDocumentID(personalIdentification): Observable<any> {
    return this.http.get(this.api + `/PersonsPostulationVacants/GetPostulation?Type=10&Person=${personalIdentification}`);
  }

  getEmployeesPositions() {
    return this.http.get(this.apiCore + `/Positions?PositionId=-1&companyId=${this.authInfo.getCompanyId()}`)
  }

  savePositionInterview(postulationInterview) {
    return this.http.post(this.api + '/PostulationsInterviews', postulationInterview);
  }

  deleteInterview(data:any) {
    return this.http.put(this.api + '/PostulationsInterviews/deleteInterviews', data)
  }

  getPostulationsList(type: number = 0, condition: number = 0) {
    //type 0 get all
    //type 1 get by condition
    return this.http.get(this.api + `/PersonsPostulationVacants/GetPostulation?type=${type}&condition=${condition}&idCompany=${this.authInfo.getCompanyId()}`);
  }

  getPaginatedPostulations(page?: number, pageSize?: number, PropkeyId = null, SearchParameter = null, status = 1, condition?: string) {
    return this.http.get(this.api + `/PersonsPostulationVacants/GetPaginated?CompanyId=${this.authInfo?.getCompanyId()}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Status=${status}&Conditions=${condition}&Page=${page}&PageSize=${pageSize}&Type=1`);
  }

  getPostulationById(id, companyId) {
    return this.http.get(`${this.api}/PersonsPostulationVacants?idppv=${id}&companyId=${companyId}`);
  }

  getInterviewById(id) {
    return this.http.get(`${this.api}/PostulationsInterviews?type=1&idPostulationInterview=${id}&companyId=${this.authInfo.getCompanyId()}`)
  }


}
