import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActionEmployComponent, ActionEmployListComponent, ScheduleComponent, NewsListComponent, ListPayrollComponent, OpenPayrollComponent, LoadMovementComponent, AuditApprovalComponent, ProcessPayrollComponent, PayrollReportComponent, PaymentFlyerComponent } from '@payroll/index';
import { PayrollDetailViewComponent } from '@payroll/open-payroll/payroll-detail-view/payroll-detail-view.component';
import { PayrollHomeComponent } from './payroll-home/payroll-home.component';
import { BudgetAreasComponent } from './budget-areas/budget-areas.component';
import { FinalPayrollComponent } from './final-payroll/final-payroll.component';
import { PaymentVoucherComponent } from './payment-flyer/payment-voucher/payment-voucher.component';
import { PdfPaymentVoucherComponent } from './payment-flyer/pdf-payment-voucher/pdf-payment-voucher.component';
import { FlyerComponent } from './payroll-report/flyer/flyer.component';
import { RecurringNewsComponent } from './recurring-news/recurring-news.component';
import { IncentivePayrollComponent } from './incentive-payroll/incentive-payroll.component';
import { AdditionalPayrollComponent } from './additional-payroll/additional-payroll.component';
import { BeneficiariesComponent } from './beneficiaries/beneficiaries.component';
import { PdfPaymentMultipleVoucherComponent } from './payment-flyer/pdf-payment-multiple-voucher/pdf-payment-multiple voucher.component';
import { PayrollConceptComponent } from './payroll-concept/payroll-concept.component';
import { PayrollStatisticsComponent } from './statistics/payroll-statistics/payroll-statistics.component';
import { ManagePayrollComponent } from './manage-payroll/manage-payroll.component';
import { PayrollAuditReportComponent } from './payroll-report/payroll-audit-report/payroll-audit-report.component';
import { IndividualPayrollReportComponent } from './payroll-report/individual-payroll-report/individual-payroll-report.component';
import { EmployeeConceptsComponent } from './payroll-report/employee-concepts/employee-concepts.component';
import { AuditExcelComponent } from './payroll-report/audit-excel/audit-excel.component';
import { PayrollTransparencyComponent } from '../RegistrationAndControl/reports/payroll-transparency/payroll-transparency.component';
import { PayrollParcialComponent } from './payroll-report/payroll-parcial/payroll-parcial.component';
import { PayrollGeneralComponent } from './payroll-report/payroll-general/payroll-general.component';
import { KeysConfigurationComponent } from './payroll-report/keys-configuration/keys-configuration.component';
import { CertificationCodesComponent } from './certification-codes/certification-codes.component';
import { IndicatorsPayrollComparisonReportComponent } from './payroll-report/indicators-payroll-comparison-report/indicators-payroll-comparison-report.component';
import { OpenAdditionalPayrollComponent } from './additional-payroll/open-additional-payroll/open-additional-payroll.component';
import { BankAccountsComponent } from './payroll-report/bank-accounts/bank-accounts.component';
import { OvertimeReportComponent } from './payroll-report/overtime-report/overtime-report.component';
import { PayrollComparisonReportComponent } from './payroll-report/payroll-comparison-report/payroll-comparison-report.component';
import { PayrollRoyaltySummaryComponent } from './open-payroll/payroll-royalty-summary/payroll-royalty-summary.component';
import { OvertimeIndividualReportComponent } from './payroll-report/overtime-individual-report/overtime-individual-report.component';

const routes: Routes = [
  {
    path: 'action-employ', component: ActionEmployComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: ' Nómina',
    }
  },
  {
    path: 'action-employ-list', component: ActionEmployListComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: ' Nómina',
    }
  },
  {
    path: 'schedule', component: ScheduleComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: ' Nómina',
    }
  },
  {
    path: 'news-list', component: NewsListComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: ' Nómina',
    }
  },
  {
    path: 'recurring-news', component: RecurringNewsComponent, data: {
      breadcrumb: 'Novedades recurrente',
      module: 'Nomina',
    }
  },
  {
    path: 'list-payroll', component: ListPayrollComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: ' Nómina',
    }
  },
  {
    path: 'open-payroll/:type', component: OpenPayrollComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: ' Nómina',
    }
  },
  {
    path: 'load-movement', component: LoadMovementComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: ' Nómina',
    }
  },
  {
    path: 'audit-approval', component: AuditApprovalComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: ' Nómina',
    }
  },
  {
    path: 'process-payroll', component: ProcessPayrollComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: ' Nómina',
    }
  },
  {
    path: 'payroll-report', component: PayrollReportComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: ' Nómina',
    }
  },
  {
    path: 'payroll-report/audit', component: PayrollAuditReportComponent, data: {
      breadcrumb: 'Reporte de Auditoria de Nómina',
      module: ' Nómina',
    }
  },
  {
    path: 'payment-flyer', component: PaymentFlyerComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: 'Nonina',
    }
  },
 {
    path: 'payment-flyer/print-flyer',
    component: PdfPaymentMultipleVoucherComponent
  },
  {
    path: 'manage-payroll', component: ManagePayrollComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: 'Nonina',
    }
  },
  {
    path: 'budget-areas', component: BudgetAreasComponent, data: {
      breadcrumb: 'Acción de Personal',
      module: 'Nonina',
    }
  },
  {
    path: 'payroll-detail/:id', component: PayrollDetailViewComponent, data: {
      breadcrumb: 'Detalle de Nómina Aperturada',
      module: 'Nonina',
    }
  },
  {
    path: 'payroll-royalty-summary/:id', component: PayrollRoyaltySummaryComponent, data: {
      breadcrumb: 'Resumen de Nómina Regalia',
      module: 'Nonina',
    }
  },
  {
    path: 'payment-voucher', component: PaymentVoucherComponent, data: {
      breadcrumb: 'Detalle de Nómina Aperturada',
      module: 'Nonina',
    }
  },
  {
    path: 'final-payroll', component: FinalPayrollComponent, data: {
      breadcrumb: 'Nómina de Prestaciones Laborales',
      module: 'Nonina',
    }
  },

  {
    path: 'incentive-payroll', component: IncentivePayrollComponent, data: {
      breadcrumb: 'Nómina de Incentivos sin Aportes',
      module: 'Nonina',
    }
  },
  {
    path: 'additional-payroll', component: OpenAdditionalPayrollComponent, data: {
      breadcrumb: 'Nómina Adicional',
      module: 'Nonina',
    }
  },
  {
    path: 'additional-payroll/:id', component: AdditionalPayrollComponent, data: {
      breadcrumb: 'Nómina Adicional',
      module: 'Nonina',
    }
  },
  {
    path: 'statistics', component: PayrollStatisticsComponent, data: {
      breadcrumb: 'Estadística de Nóminas',
      module: 'Nonina',
    }
  },
  {
    path: 'payroll-report-flyer',
    component: FlyerComponent
  },
  {
    path: 'payroll-general',
    component: PayrollGeneralComponent
  },
  {
    path: 'payroll-report/overtimes-report',
    component: OvertimeReportComponent
  },
  {
    path: 'payroll-report/overtime-individual-report',
    component: OvertimeIndividualReportComponent
  },
  {
    path: 'payroll-report/individual-payroll',
    component: IndividualPayrollReportComponent
  },
  {
    path: 'payroll-report/keys-configuration',
    component: KeysConfigurationComponent
  },
  {
    path: 'payroll-report/employee-concepts-report',
    component: EmployeeConceptsComponent
  },
  {
    path: 'payroll-report/audit-excel-report',
    component: AuditExcelComponent
  },
  {
    path: 'pdf-payment-voucher/:headerId/:employeeId/:periodId',
    component: PdfPaymentVoucherComponent
  },
  {
    path: 'payroll-home',
    component: PayrollHomeComponent
  },
  {
    path: 'beneficiaries',
    component: BeneficiariesComponent
  },
  {
    path: 'payroll-report/parcial-payroll',
    component: PayrollParcialComponent,
  },
  {
    path: 'payroll-report/indicators-payroll-comparison-report',
    component: IndicatorsPayrollComparisonReportComponent
  },
  {
    path: 'payroll-report/bank-accounts',
    component: BankAccountsComponent
  },
  {
    path: 'payroll-concept', component: PayrollConceptComponent, data: {
      breadcrumb: 'Concepto de Nómina',
      module: 'Nonina',
    }
  },
  {
    path: 'certification-codes', component: CertificationCodesComponent, data: {
      breadcrumb: 'Códigos Certificación',
      module: 'Nonina',
    }
  },
  {
    path: 'payroll-report/payroll-comparison-report',
    component: PayrollComparisonReportComponent
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class payrollRoutingModule { }
