<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"> <span class="h-20px label label-danger label-dot mr-3 w-20px"></span> Dashboard
        de Docentes por Asignatura</h2>
</div>

<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <div class="d-flex justify-content-between align-items-end mb-3 row">
            <div class="text-left col-md-8 col-lg-9">
                <h3>Filtrado</h3>
                <div class="text-left d-flex flex-wrap">
                    <h4 class="d-flex font-weight-bolder text-primary  me-4">Desde: <span class="text-dark font-weight-lighter ml-3">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h4>
                    <h4 class="d-flex font-weight-bolder text-primary">Hasta: <span class="text-dark font-weight-lighter ml-3">{{filterParams.date2|date:'dd/MM/yyyy'||''}}</span></h4>
                </div>
            </div>
            <div class="d-flex flex-nowrap justify-content-between col-12 col-md-3 col-lg-3">
                <button class="btn btn-primary me-2 w-50" (click)="filterByDate()">
                    <i class="fas fa-filter" data-toggle="tooltip" data-title="Filtrar"></i>
                </button>
                <button class="btn btn-light-warning me-2 w-50" (click)="clearFilter()">
                    <i class="fas fa-redo" data-toggle="tooltip" data-title="Reiniciar filtros"></i>
                </button>
            </div>
        </div>

        <div class="card bg-white p-5 gutter-b">
            <div class="row">
                <div class="col-lg-6 gutter-b">
                    <div class="card card-custom bg-white">
                        <div class="card-header p-2 mt-2 ms-3">
                            <h3 class="font-weight-bold text-dark-75 py-3"> Docentes por Sexo</h3>
                        </div>
                        <div class="card-body p-3">
                            <app-bar-chart-race [id]="'chartsource12'" [data]="teachersByGenre"></app-bar-chart-race>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 gutter-b">
                    <div class="card card-custom bg-white">
                        <div class="card-header p-2 mt-2  ms-3">
                            <h3 class="font-weight-bold text-dark-75 py-3">Docentes por Sucursal</h3>
                        </div>
                        <div class="card-body p-3">
                            <app-bar-chart-race class="img-fluid" [id]="'chartHome'" [data]="teachersByBranch"></app-bar-chart-race>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 gutter-b">
                  <div class="card card-custom bg-white">
                      <div class="card-header p-2 mt-2  ms-3">
                          <h3 class="font-weight-bold text-dark-75 py-3">Docentes por Unidad organizativa</h3>
                      </div>
                      <div class="card-body p-3">
                          <app-bar-chart-race class="img-fluid" [id]="'chartHome2'" [data]="teachersByDepartment"></app-bar-chart-race>
                      </div>
                  </div>
                </div>
                <div class="col-lg-6 gutter-b">
                  <div class="card card-custom bg-white">
                      <div class="card-header p-2 mt-2 ms-3">
                          <h3 class="font-weight-bold text-dark-75 py-3"> Docentes por Cargo</h3>
                      </div>
                      <div class="card-body p-3">
                          <app-bar-chart-race [id]="'chartsource13'" [data]="teachersByPosition"></app-bar-chart-race>
                      </div>
                  </div>
              </div>
            </div>
        </div>
        <div class="row">



        </div>
    </div>

</div>
