export class Beneficiary {
    conceptBeneficiaryId?: number;
    description: string;
    identificationType: number;
    identification: string;
    status: boolean;
    companyId: number;
    createDate: Date;
    createUserId: number;

    constructor() {
        this.description = ''
        this.identificationType = null
        this.identification = ''
        this.companyId = null
        this.status = true
        this.createDate = null
        this.createUserId = null
    }
}
