<div class="row">
    <div class="col-xl-3 col-lg-6">
        <div class="card mb-5 full-h">
            <h5 class="d-block p-3 text-danger">Huella Dactilar
            </h5>

            <uppy-file 
                #uppyFileFinger
                cardBody=""
                classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px"
                targetClass="finger"
                centerContentClass="center-v gutter-b h-100"
                [confirmation]="true"
                [parameterDoc]="parameterDoc"
                [ModuleLoad]="ModuleLoad"
                [width]="387"
                [height]="280"
                [showDocumentoAfterUploaded]="true"
                [showButtonViewFile]="false"
                [fileForEditting]="guid"
                [allowedFileTypes]="['image/*']"
                (changeEmitter)="changeDetector()"
                (fileEmitter)="setImage($event)">
            </uppy-file>
        </div>
    </div>
</div>
