import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Inject, NgZone, PLATFORM_ID } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";

import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { any } from '@amcharts/amcharts5/.internal/core/util/Array';
import { isPlatformBrowser } from '@angular/common';
import { IChartClusteredBarData } from '../models/chartModels';
import { TooltipBy } from '../enums/TooltipBy';
export interface chartClustered { rowText: string, value1: number, value2: number }
@Component({
  selector: 'app-chart-clustered-bar',
  templateUrl: './chart-clustered-bar.component.html',
  styleUrls: ['./chart-clustered-bar.component.css']
})
export class ChartClusteredBarComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input() id: string = 'clustered-chart1'
  @Input() serieName1 = 'Masculino'
  @Input() serieName2 = 'Femenino'
  @Input() serieValue1 = 'value1'
  @Input() serieValue2 = 'value2'
  @Input() data = []
  @Input() categoryField = 'rowText'

  @Input() seriesList: {serieName: string, serieValueAttribute:string, serieColor: number}[] = []

  @Input() useSeriesList: boolean = false;

  @Input() height: number = 500;
  // Show all tooltips by series as default to keep previous behavior
  @Input() tooltipBy: TooltipBy = TooltipBy.Series;
  // Use color from series as default to keep previous behavior
  @Input() useSeriesColor: boolean = true;

  private root: am5.Root;

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.root && changes['data'].currentValue) {
      this.root.dispose();
      this.root = am5.Root.new(this.id)
      this.buildChart()
    }
  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f()
      })
    }
  }

  ngAfterViewInit() {
    this.root = am5.Root.new(this.id)
    this.buildChart()
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose()
        document.getElementById(this.id).remove()
      }
    })
  }


  buildChart() {

    if (!this.root) {
      return
    }

    if(this.data.length == 0){
      return;
    }

    this.browserOnly(() => {

      const root = this.root;
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
      }));


      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }))


      // Data
      const data = this.data;


      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {

        renderer: am5xy.AxisRendererY.new(root, {
        }),
        min: 0
      }));

      const categoryField = this.categoryField;

      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: categoryField,

        renderer: am5xy.AxisRendererX.new(root, {
          inversed: false,
        }),
      }));
      xAxis.data.setAll(data);




      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function createSeries(field, name, color:number|am5.Color, tooltipBy: TooltipBy) {
        const series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: categoryField,
          sequencedInterpolation: true,
          fill: color instanceof am5.Color ? color : am5.color(color),
          tooltip: tooltipBy === TooltipBy.Series ? am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
          }) : null
        }));

        series.columns.template.setAll({
          height: am5.p100,
          tooltipText: tooltipBy === TooltipBy.Column ? "[bold]{name}[/]\n{categoryX}: {valueY}" : null
        });


        /* series.bullets.push(function() {
          return am5.Bullet.new(root, {
            locationY: 1,
            locationX: 0.5,
            sprite: am5.Label.new(root, {
              centerX: am5.p50,
              text: "{name}",
              populateText: true
            })
          });
        }); */

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 0.5,
            sprite: am5.Label.new(root, {
              centerX: am5.percent(50),
              centerY: am5.percent(50),
              text: "{valueY}",
              fill: am5.color(0xffffff),
              populateText: true
            })
          });
        });

        series.data.setAll(data);
        series.appear();

        return series;
      }

      if(this.useSeriesList){
        if (this.useSeriesColor) {
          this.seriesList.forEach(serie=>{
            createSeries(serie.serieValueAttribute, serie.serieName, serie.serieColor, this.tooltipBy);
          })
        } else {
          let colors = chart.get("colors");
          this.seriesList.forEach((serie, serieIndex) =>{
            createSeries(serie.serieValueAttribute, serie.serieName, colors.getIndex(serieIndex), this.tooltipBy);
          });
        }
      }else{
        createSeries(this.serieValue1, this.serieName1, 0x3399ff, this.tooltipBy);
        createSeries(this.serieValue2, this.serieName2, 0xff66ff, this.tooltipBy);
      }
      
     




      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend2 = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));

      legend2.data.setAll(chart.series.values);


      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "zoomY"
      }));
      cursor.lineY.set("forceHidden", true);
      cursor.lineX.set("forceHidden", true);


      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
    })
  }



}
