<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="closeModal(false)" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span
            class="h-20px label label-danger label-dot mr-3 w-20px"></span>{{titleModal}}</h2>

</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom my-0 py-0">

        <div class="card-body">
            <form [formGroup]="vacationsForm">

                <div class="row">

                    <div class="form-group col-md-6">
                        <div>
                            <label for="">
                                Categoría Servidor Público
                            </label>
                        </div>
                        <ngx-select-dropdown 
                            formControlName="categories"
                            [config]="categoriesDropdownConfig"
                            [options]="categoryTypes"></ngx-select-dropdown>
                        <errors-component [controlName]="vacationsForm.controls['categories']"></errors-component>
                    </div>

                    <div class="form-group col-md-6">
                        <div>
                            <label for="">
                                Fecha de Inicio<span class="text-danger text-monospace legend">* </span>
                            </label>
                        </div>
                        <input formControlName="startDate" (change)="startDateChange($event.target.valueAsDate)" id="dateAdmision" placeholder="Fecha de ingreso" type="date"
                            class="form-control form-control-lg dynamicFormControl ng-pristine ng-valid ng-touched">
                        <errors-component [controlName]="vacationsForm.controls['startDate']"></errors-component>
                    </div>

                    <div class="form-group col-md-6">
                        <div>
                            <label for="">
                                Fecha de Término<span class="text-danger text-monospace legend">* </span>
                            </label>
                        </div>
                        <input formControlName="endDate" [disabled]="true"
                            [min]="minDate" id="dateAdmision"
                            placeholder="Fecha de ingreso" type="date"
                            class="form-control form-control-lg dynamicFormControl ng-pristine ng-valid ng-touched">
                        <errors-component [controlName]="vacationsForm.controls['endDate']"></errors-component>
                    </div>

                    <div class="form-group col-md-6">
                        <div>
                            <label for="">
                                Año al que pertenecen<span class="text-danger text-monospace legend">* </span>
                            </label>
                        </div>
                        <ngx-select-dropdown (change)="changeYear()" formControlName="period"
                            [config]="yearDropdownConfig" [options]="vacationYears"></ngx-select-dropdown>
                        <errors-component [controlName]="vacationsForm.controls['period']"></errors-component>
                    </div>

                    <div class="form-group col-md-6">
                        <div>
                            <label for="">
                                Cantidad de Días<span class="text-danger text-monospace legend">* </span>
                            </label>
                        </div>
                        <input formControlName="totalDays" class="form-control form-control-lg dynamicFormControl" id=""
                            placeholder="" type="number" />
                        <errors-component [controlName]="vacationsForm.controls['totalDays']"></errors-component>
                    </div>


                </div>

                <div class="row">
                    <div class="form-group col-md-12">
                        <div>
                            <label for="dateAdmision">
                                Comentario
                            </label>
                        </div>

                        <textarea formControlName="comment" rows="4"
                            class="form-control form-control-lg dynamicFormControl"></textarea>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="mat-dialog-actions justify-content-end" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="closeModal(false)">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="!this.data.isView" [disabled]="vacationsForm.invalid" (click)="submitVacation()"
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>