import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmployeesService } from 'src/app/services/employees.service';
import { FilterEvent } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-audit-changes',
  templateUrl: './audit-changes.component.html',
  styleUrls: ['./audit-changes.component.css']
})
export class AuditChangesComponent implements OnInit {
  companies = []
  searchParameter: FilterEvent;
  employeesPagedResut = new PagedResult<any>();
  advancedFilter:any;
  employees = [];
  
  constructor(
    private readonly _dialogRef:MatDialogRef<AuditChangesComponent>,
    private readonly _employeesService:EmployeesService,
    private readonly toastr: ToastService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) 
  {
    this._dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getAllHistoryEmployees();
  }

  close(){
    this._dialogRef.close();
  }

  getPaginatedRecords(event: PaginationEvent) {
    if (this.employeesPagedResut.page == event.selectedPage && this.employeesPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.employeesPagedResut.page = event.selectedPage;
    this.employeesPagedResut.pageSize = event.registersPerPage;
    this.getAllHistoryEmployees()
  }

  private getAllHistoryEmployees(){
    const page = this.employeesPagedResut.page;
    const pageSize = this.employeesPagedResut.pageSize;
    const searchText = this.searchParameter?.value ?? ''
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const status: number = -1;
    const companyId = this.data.employee.companyId;
    const advancedsearch = this.advancedFilter ?? ''

    // getAllEmployeesPaginatedHistory(employeeId,companyId, branchId, typeEmployeeId, status, propKeyId, searchParameter, page, pageSize, advancedFilter?){


    this._employeesService.getAllEmployeesPaginatedHistory(this.data.employee.employeeId,
                                                           companyId,
                                                           this.data.employee.branchId,
                                                           this.data.employee.typeEmployeeId,
                                                           status,
                                                           propKeyId,
                                                           searchText,
                                                           page,
                                                           pageSize,
                                                           advancedsearch).subscribe({
      next: (response:any) => {
        if(!response.succeded){
          this.toastr.error(response.errors[0]);
          return;
        }
        this.employees = response.singleData.items;
        this.employeesPagedResut = response.singleData;
      }
    })
  }

  exportReport(){
    const reportCode: ReportCode = ReportCode.ReportEmployeesHistoryPaginated;
    const companyId =this.data.employee.companyId;
    const employeeId = this.data.employee.employeeId
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&EmployeeId=${employeeId}&CompanyId=${companyId}`

    const parameters = {
      url: reportUrl,
      title: 'Lista de Empleados Historico',
      width: 1024,
      height: 768
    }

    openReport(parameters);
  }

}
