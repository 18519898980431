import { registerLocaleData } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import localeES from '@angular/common/locales/es-US';
import { SignalrService } from './services/signalr.service';
declare const $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    public signalr: SignalrService
  ) {
    
  }

  ngOnInit(): void {
   registerLocaleData(localeES, 'es-US')

   this.signalr.startConnection();
   this.signalr.addSessionLogOutDataListener();
  }

  ngAfterViewChecked(): void {
 /*    $('[data-toggle="tooltip"]').tooltip();   */  
  }
  
 
}
