<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span> 
       {{title}} Código Certificación</h2>
    <button (click)="dialogRef.close()" type="button" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content">
    <div class="card-body" [formGroup]="form">
        <div class="row">
            <div class="form-group col-md-12">
                <label class="d-block">Código <span
                        class="text-danger text-monospace legend">*</span></label>
                <div class="input-group">
                    <input (input)="removeWhitespace($event, form.get('errorCode'))" type="text" formControlName="errorCode" class="form-control form-control-solid form-control-lg">
                </div>
                <ng-container *ngIf="formInvalid && form?.get('errorCode')?.invalid">
                    <span class="text-danger" *ngIf="form?.get('errorCode')?.hasError('required')">
                        Este campo es obligatorio
                    </span>
                    <span class="text-danger" *ngIf="form?.get('errorCode')?.hasError('maxlength')">
                        Este campo sólo admite un máximo de {{form?.get('errorCode')?.errors['maxlength']?.requiredLength}} caracteres
                    </span>
                </ng-container>
            </div>
            <div class="form-group col-md-12">
                <label class="d-block">Descripción Corta <span
                        class="text-danger text-monospace legend">*</span></label>
                <div class="input-group">
                    <input (input)="removeWhitespace($event, form.get('shortDescription'))" type="text" formControlName="shortDescription" class="form-control form-control-solid form-control-lg">
                </div>
                <ng-container *ngIf="formInvalid && form?.get('shortDescription')?.invalid">
                    <span class="text-danger" *ngIf="form?.get('shortDescription')?.hasError('required')">
                        Este campo es obligatorio
                    </span>
                    <span class="text-danger" *ngIf="form?.get('shortDescription')?.hasError('maxlength')">
                        Este campo sólo admite un máximo de {{form?.get('shortDescription')?.errors['maxlength']?.requiredLength}} caracteres
                    </span>
                </ng-container>
            </div>
            <div class="form-group col-md-12">
                <label class="d-block">Descripción Técnica <span
                        class="text-danger text-monospace legend">*</span></label>
                <div class="input-group">
                    <textarea (input)="removeWhitespace($event, form.get('technicalDescription'))" formControlName="technicalDescription" class="form-control form-control-solid form-control-lg" cols="20" rows="5"></textarea>
                </div>
                <ng-container *ngIf="formInvalid && form?.get('technicalDescription')?.invalid">
                    <span class="text-danger" *ngIf="form?.get('technicalDescription')?.hasError('required')">
                        Este campo es obligatorio
                    </span>
                    <span class="text-danger" *ngIf="form?.get('technicalDescription')?.hasError('maxlength')">
                        Este campo sólo admite un máximo de {{form?.get('technicalDescription')?.errors['maxlength']?.requiredLength}} caracteres
                    </span>
                </ng-container>
            </div>
        </div>
    </div>

</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
    (click)="dialogRef.close()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="data.action !== 3" [disabled]="form.invalid" (click)="Accept()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success"
        type="button" >
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>