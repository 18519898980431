import { ChangeDetectorRef, Component, OnInit, OnDestroy, AfterViewChecked, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { CreateBudgetAreaComponent } from './create-budget-area/create-budget-area.component';
import Swal from 'sweetalert2';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { map } from 'rxjs/operators';
declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'app-budget-areas',
  templateUrl: './budget-areas.component.html',
  styleUrls: ['./budget-areas.component.css']
})
export class BudgetAreasComponent implements OnInit, OnDestroy, AfterViewChecked {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'BUDGET-AREAS',
    exportAction: { key: 'EXPORTAREAP', value: false},
    addAction: { key: 'ADDAREAP', value: false},
    deleteAction: { key: 'REMOVEAREAP', value: false},
    editAction: { key: 'EDITAREAP', value: false},
    view: { key: 'VIEWAP', value: false}
  }

  subscription: Subscription = new Subscription();
  sweetAlrt = new SweetAlert
  isLoading: boolean = true;
  areasList = []
  originalBudgetAreaList = []
  paginatedBudgetAreaList = []
  propkey:any;
  page:any;
  pagesize:any;
  filterText: string = '';
  postulationId: number;
  selectedItems: any [] = [];
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: {propkeyId:any, propkey?: string, displayName: string }[] = [
    {propkeyId:1, propkey: "budgetAreaId", displayName: "Secuencia" },
    {propkeyId:2, propkey: "code", displayName: "Código" },
    {propkeyId:3, propkey: "description", displayName: "Descripción" },
    {propkeyId:4, propkey: "executingUnitDescriptionConcat", displayName: "Unidad Ejecutora" },
  ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' }
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  getFilteredList(event) {
    this.filteredList = event;
    this.propkey = event.propkey;
    this.filteredList.records = this.filterTable(event.records)
  }



  constructor(
    private _exlService: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private _payrollService: PayrollService,
    private _toastService: ToastrService,
    private dialog: MatDialog,
    public authInfo: AuthInfoService
  ) {
  }

  ngOnInit(): void {

    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result=>{
      if(result == true){
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        if (localStorage.getItem("budget-areas_filter")) {
          this.selectedItems = JSON.parse(localStorage.getItem("budget-areas_filter"));
        } else {
          this.selectedItems = [{ item_id: 1, item_text: 'Activos' }];
        }

        this.getBudgetAreaList()
      }
    });
    applySort();
  }

  manageBudgetArea(item: any, mode: 'create' | 'edit' | 'view'): void {

    
    this.dialog.open(CreateBudgetAreaComponent, {
      data: {
        item: item,
        mode,
        originalBudgetAreaList: [...this.originalBudgetAreaList]
      }
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.created == true) {
          this.getBudgetAreaList()
        }
      });
  }


  getBudgetAreaList() {
    this._payrollService.getBudgetAreas().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.areasList = this.filterTable(res.dataList);
        this.originalBudgetAreaList = res.dataList;
      }
    })
  }

  deleteBudgetArea(budgetAreaId: number) {
    Swal.fire({
      title: `¿Está seguro de inactivar este registro?`,
      html: `Razón`,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Cancelar',
      preConfirm: (data) => {
        if (data == '' || data == undefined) {
          Swal.showValidationMessage(
            'Debe digitar la razón'
          )
        } else {
         return;
        }
      },
    }).then((result) => {
      if (!result.dismiss) {
        const value: any = result
        const model = {
          budgetAreaId,
          modifyUserId: this.authInfo.getUserId(),
          inactiveReason: value?.value ? value?.value : ''
        }
        
        this._payrollService.deleteBudgetArea(model).subscribe((result) => {
          this.sweetAlrt.Default("Inactivado", "Registro inactivado satisfactoriamente", "success")
          this.getBudgetAreaList()
        }, err => {
          this._toastService.error(err, "Ocurrió un error tratando de inactivar el registro")
        });
      } else {
      }
    }
    )

    /*this.sweetAlrt.AlertConfirm("Inactivar", "¿Está seguro de inactivar este registro?", "question").then(res => {
      if (res) {

      } else {
      }
    })*/

  }



  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    //document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.areasList = this.filterTable(this.originalBudgetAreaList)
    }
    this.setFilterToLocalStorage();
  }
  setFilterToLocalStorage() {
    localStorage.setItem('budget-areas_filter', JSON.stringify(this.selectedItems));
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl?.find(x => x.item_id == 1) && !sl?.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl?.find(x => x.item_id == 2) && !sl?.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    return list;
  }



  getPaginatedRecords(event) {
    this.paginatedBudgetAreaList = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.page = event.selectedPage;
    this.pagesize = event.registersPerPage;
  }


  exportToPdf() {
    const reportCode: ReportCode = ReportCode.ReportPayrollBudgetArea2;
    const companyId: number = this.authInfo.getCompanyId();
    const propkeyId = this.propkey?.propkeyId ?? 0;
    // const status : number = this.selectedItems[0].item_id
    const SearchParameter = this.filterText ?? ''
    const status = this.selectedItems.map(item => item?.item_id).join('-').replace('2','0') ?? 0
 10
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&PropkeyId=${propkeyId}&SearchParameter=${SearchParameter}&Status=${status}&Page=${this.page}&PageSize=${this.pagesize}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Áreas Presupuestales',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  onBtnPrintClick(){
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

}
