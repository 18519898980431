<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>{{modalTitle}}</h2>
        <button (click)="close()" type="button" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    
    </div>
    <div class="mat-dialog-content-custom">
        <div class="card-body-custom ">
    
            <div class="card-body mt-0 row justify-content-center">
                <div class="row">
                    <div class="col-md-12">
                        <div class="bg-light-info rounded row h-100">
                            <div class="text-center col-md-3 center">
                                <div class="symbol symbol-150 d250 rd20 mr-3 my-5 center">
                                    <img *ngIf="employeeImg" [src]="employeeImg" alt="Pic"/>
                                    <img *ngIf="!employeeImg" alt="Pic" src="../../../../assets/images/no-user.png" onerror="this.error=null; this.src='assets/images/no-user.png'" />
                                </div>
                            </div>
               
                            <div class="col-md-8">
                                <label class="d-flex font-weight-bolder my-2 p-2 text-primary ">Servidor Público: <span
                                        class="text-dark ml-3">{{employeeSelected?.name}}</span></label>
                                <label class="d-flex font-weight-bolder my-2 p-2 text-primary ">Identificación:
                                    <span class="text-dark ml-3"
                                        *ngIf="employeeSelected?.typeIdentificationId === 1">{{employeeSelected?.personalIdentification
                                        | mask:'000-0000000-0'}}</span>
                                    <span class="text-dark ml-3"
                                        *ngIf="employeeSelected?.typeIdentificationId !== 1">{{employeeSelected?.personalIdentification}}</span>
                                </label>
                                <label class="d-flex font-weight-bolder my-2 p-2 text-primary ">Teléfono:
                                    <span class="text-dark ml-3">{{employeeSelected?.phoneNumber |
                                        mask:'(000)-000-0000'}}</span>
                                </label>
                                <label class="d-flex font-weight-bolder my-2 p-2 text-primary ">Correo Institucional: <span
                                        class="text-dark ml-3">{{employeeSelected?.institionalEmail}}</span>
                                </label>
                                <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Supervisor Inmediato: <span
                                        class="text-dark ml-3">{{employeeSelected?.employeeSupervisor}}</span>
                                </label>
                                <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Supervisor de Departamento: <span
                                        class="text-dark ml-3">{{employeeSelected?.supervisor}}</span>
                                </label>
                                <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Fecha de Ingreso:
                                    <span class="text-dark ml-3">{{employeeSelected?.admissionDate|date:"dd/MM/yyyy"}}</span>
                                </label>
                                <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Salario Bruto: <span
                                        class="text-dark ml-3">{{employeeSelected?.salary | currency:'RD$'}}
                                    </span>
                                </label>
                                <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Unidad Organizativa: <span
                                        class="text-dark ml-3 h6">{{employeeSelected?.departmentName}}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
    
            <div class="card-body">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="form-group col-md-6 col-lg-4">
                            <label class="d-block">Tipo de Novedad<span
                                    class="text-danger text-monospace legend"> </span></label>
                            <div class="dropdown-alt input-group">
                                <input formControlName="newsType" type="text" class="form-control form-control-lg dynamicFormControl">
                            </div>
                        </div>
    
                        <div class="form-group col-md-6 col-lg-4">
                            <label class="d-block">Concepto<span class="text-danger text-monospace legend"> </span></label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown formControlName="concept"
                                (change)="selectConcept($event)" [options]="concepts" [config]="dropdownConfig('description')" class="w-100">
                                </ngx-select-dropdown>
                                <span *ngIf="formIsInvalid" class="form-text text-danger">
                                    {{getErrors('conceptId')}}
                                </span>
                            </div>
                        </div>
    
                        <div class="form-group col-md-6 col-lg-4">
                            <div>
                                <label for="quantityPlaces">
                                    Monto<span class="text-danger text-monospace legend"> </span>
                                </label>
                            </div>
                            <input formControlName="amount" class="form-control form-control-lg dynamicFormControl"
                                id="quantityPlaces" placeholder="RD$00.00" type="text" currencyMask
                                [options]="{precision:2}" />
                            <span *ngIf="formIsInvalid" class="form-text text-danger">
                                {{getErrors('amount')}}
                            </span>
                        </div>
    
    
    
                        <div class="form-group col-md-6 col-lg-4">
                            <div>
                                <label>
                                    Fecha Inicio<span class="text-danger text-monospace legend"></span>
                                </label>
                            </div>
                            <input formControlName="startDate" class="form-control form-control-lg dynamicFormControl" type="date"/>
                        </div>
    
                        <div class="form-group col-md-6 col-lg-4">
                            <div>
                                <label>
                                    Fecha Final
                                </label>
                            </div>
                            <input formControlName="endDate" class="form-control form-control-lg dynamicFormControl" type="date" />
                        </div>
    
    
                        <div class="form-group col-md-6 col-lg-4">
                            <label>Evidencia <span class="text-danger"></span></label>
                            <div class="card align-items-center">
                                <div class="card-body img-fluid">
                                    <div>
                                        <div [ngClass]="{'d-inline-block': evidenceImg === '', 'd-none' : evidenceImg !==''}"
                                            class="evidence"></div>
                                    </div>
                                    <div *ngIf="evidenceImg !== ''" class="center-v">
                                        <div class="symbol symbol-150 d250 my-4 max-w-250px">
                                            <img alt="Pic" src="assets/images/cv.jpg" />
                                        </div>
                                        <div class="btn-group center" role="group">
                                            <button type="button" class="btn btn-primary btn-icon" data-toggle="modal"
                                                data-target="#modalEvidence">
                                                <i class="flaticon-ver"></i>
                                            </button>
                                            <button *ngIf="!IsViewMode" type="button" class="btn btn-danger btn-icon"
                                                (click)="deleteGuid('evidence')">
                                                <i class="flaticon-delete-1"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
    
    
                </form>
            </div>
        </div>
    </div>
    
    <div class="mat-dialog-actions" [align]="'end'">
        <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
        <button (click)="save()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
            <i class="flaticon-disco-flexible"></i>
            <span>Guardar</span>
        </button>
    </div>
    
    <el-modal size="xl" target="modalEvidence" title="Evidencia">
        <iframe width="100%" height="600px" [src]="transform(evidenceImg)"></iframe>
    </el-modal>
    
    