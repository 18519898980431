import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CertificationCodeService } from 'src/app/services/certification-code.service';
import { CatalogResponseCertificationAccount, PostCatalogResponseCertificationAccount } from '../models/certification-codes.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { removeWhitespace } from 'src/app/shared/utils/utility';

@Component({
  selector: 'app-certification-code',
  templateUrl: './certification-code.component.html',
  styleUrls: ['./certification-code.component.css']
})
export class CertificationCodeComponent implements OnInit {
  title: string = '';

  form: FormGroup;
  formInvalid: boolean = false;

  constructor(private _toastService: ToastrService,
    public dialogRef: MatDialogRef<CertificationCodeComponent>,
    private fb: FormBuilder,
    private _changeDet: ChangeDetectorRef, private certificationCodeService: CertificationCodeService,
    private _authService: AuthInfoService, @Inject(MAT_DIALOG_DATA) public data: {
      catalogRespons: CatalogResponseCertificationAccount,
      action: number
    }) {
    this.title = this.data.action === 1 ? 'Agregar' : this.data.action === 2 ? 'Editar' : 'Detalle';
    this.dialogRef.disableClose = true;
    this.formBuilder();
    if(this.data?.catalogRespons?.catalogResponseCertificationAccountId){
      this.form.patchValue({
        ...this.data?.catalogRespons
      })
    }
    if(this.data.action === 3){
      this.form.disable();
    }
  }

  private formBuilder(){
    this.form = this.fb.group({
      catalogResponseCertificationAccountId: [0],
      errorCode: [null, [Validators.required, Validators.maxLength(10), Validators.pattern('^([^\\s]+[A-Za-z0-9_]*\\s)*[^\\s]*$')]],
      shortDescription: [null, [Validators.required, Validators.maxLength(100), Validators.pattern('^([^\\s]+[A-Za-z0-9_]*\\s)*[^\\s]*$')]],
      technicalDescription: [null, [Validators.required, Validators.maxLength(250), Validators.pattern('^([^\\s]+[A-Za-z0-9_]*\\s)*[^\\s]*$')]],
      UserId: [this._authService.getUserId()],
      companyId: [this._authService.getCompanyId()]
    });
  }
  trimLeft1(name){
    const errorCode = this.form.get('errorCode')
    const strTrim = errorCode.value.trimLeft()
    errorCode.patchValue(strTrim, {emitEvent: false})
  }

  ngOnInit(): void {
  }
  removeWhitespace(event, control){
    removeWhitespace(event, control, true)
  }

  Accept() {
    if (this.form.invalid) {
      this._toastService.warning('Debe llenar todos los campos obligatorios')
      this.formInvalid = true;
      return;
    }
    ['errorCode', 'shortDescription', 'technicalDescription'].forEach((path) => {
      const control = this.form.get(path)
      const valueTrimmed = control.value.trim()
      control.patchValue(valueTrimmed)
    })
    const catalogResponse = this.form?.value as PostCatalogResponseCertificationAccount;
    
    if(this.data.action === 1){
      this.post(catalogResponse);
    }else{
      this.put(catalogResponse);
    }
  }

  private post(catalogResponse: PostCatalogResponseCertificationAccount){
    this.certificationCodeService.postCertificationCodes(catalogResponse).subscribe({
      next: res => {
        if(!res?.succeded){
          res.errors.forEach(error => {
            this._toastService.error(error);
          });
          res.warnings.forEach(warning => {
            this._toastService.warning(warning);
          });
          return;
        }
        this._toastService.success('Código de certificación agregado correctamente');
        this.dialogRef.close({created: true});
      }, error: res => {
        this._toastService.error('Ha ocurrido un error tratando de insertar el código de Certificación');
      }
    });
  }

  private put(catalogResponse: PostCatalogResponseCertificationAccount){
    this.certificationCodeService.putCertificationCodes(catalogResponse).subscribe({
      next: res => {
        if(!res?.succeded){
          res.errors.forEach(error => {
            this._toastService.error(error);
          });
          res.warnings.forEach(warning => {
            this._toastService.warning(warning);
          });
          return;
        }
        this._toastService.success('Código de certificación actualizado correctamente');
        this.dialogRef.close({created: true});
      }, error: res => {
        this._toastService.error('Ha ocurrido un error tratando de actualizar el código de Certificación');
      }
    });
  }

}