<!-- <div *ngIf="isLoadingData()" class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
    <el-spinner animation="spinner-logo"></el-spinner>
    <h3 class="text-primary">Cargando...</h3>
</div> -->
<ng-container>
    <h2 class="d-flex m-0 align-items-center justify-content-between mat-dialog-title font-size-h2 text-white">
        <div class="d-flex align-items-center">
            <span class="h-20px label label-danger label-dot mr-4 w-20px"></span>
            <span>Rechazar licencia</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    </h2>
    <div class="my-5">
        <div class="container-fluid">
            <div class="form-group">
                <label for="">Motivo del rechazo<span class="text-danger">*</span></label>
                <textarea [(ngModel)]="reasonToReject" class="form-control form-control-lg"></textarea>
            </div>
            <span *ngIf="showRejectReasonError" class="text-danger">Este campo es requerido</span>
            <div class="mt-3 text-right">
        
                <button data-dismiss="modal" id="btnClose" class="btn btn-secondary">
                    Cancelar
                </button>
                <button (click)="rejectPermission()" class="btn btn-danger ml-3">
                    Rechazar
                </button>
            </div>

        </div>
    </div>
    
</ng-container>

