<div class="row">
    <div class="col-12 col-md-6">
        <ng-template [ngIf]="parameters.length>0">
            <div class="form-group">
                <label class="text-primary">Parámetro de Búsqueda</label>
                <ngx-select-dropdown [(ngModel)]="parameter" [options]='searchParameter'
                    (change)='changeParameter($event.value)' [config]='config'>
                </ngx-select-dropdown>
            </div>
        </ng-template>
    </div>
    <div class="col-12 col-md-6 mr-0 pr-0">
        <div class="form-group">
            <label>Búsqueda</label>
            <div class="input-group">
                <input [(ngModel)]="value" (keyup.enter)="filter()"
                    type="{{parameter?.type ? parameter?.type : 'text'}}"
                    class="form-control form-control-solid form-control-lg"
                    placeholder="{{parameter?.placeHolder ? parameter?.placeHolder : 'Escriba aquí su búsqueda'}}">
                <button id="searchBarBtn" (click)="filter()" class="input-group-text bg-primary" data-toggle="tooltip"
                    title="Buscar">
                    <i class="flaticon2-search-1 icon-2x text-white"></i>
                </button>
            </div>
        </div>
    </div>
</div>