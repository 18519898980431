import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PayrollConceptService } from 'src/app/services/payroll-concept.service';
import { FilterEvent } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'audit-changes',
  templateUrl:'./audit-changes.component.html',
  styleUrls: ['./audit-changes.component.css']
})
export class AuditChangesComponent implements OnInit {
  companies = []
  searchParameter: FilterEvent;
  payrollConceptPagedResut = new PagedResult<any>();
  advancedFilter:any;
  concepts = [];

  constructor(
    private readonly _dialogRef:MatDialogRef<AuditChangesComponent>,
    private readonly _payrollConceptService:PayrollConceptService,
    private readonly toastr: ToastService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this._dialogRef.disableClose = true;
  }


  ngOnInit(): void {
    this.getAllHistoryConcept();
  }

  close(){
    this._dialogRef.close();
  }

  getPaginatedRecords(event: PaginationEvent) {
    if (this.payrollConceptPagedResut.page == event.selectedPage && this.payrollConceptPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.payrollConceptPagedResut.page = event.selectedPage;
    this.payrollConceptPagedResut.pageSize = event.registersPerPage;
    this.getAllHistoryConcept()
  }

  private getAllHistoryConcept(){
    const page = this.payrollConceptPagedResut.page;
    const pageSize = this.payrollConceptPagedResut.pageSize;
    const searchText = this.searchParameter?.value ?? ''
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const status: number = -1;
    const companyId = this.data.item.companyId;
    const advancedsearch = this.advancedFilter ?? ''
    this._payrollConceptService.getAllNewConceptPaginatedHistory(companyId,this.data.item.newsConceptId, page, pageSize, propKeyId, searchText, status, advancedsearch).subscribe({
      next: (response:any) => {
        if(!response.succeded){
          this.toastr.error(response.errors[0]);
          return;
        }
        this.concepts = response.singleData.items;
        this.payrollConceptPagedResut = response.singleData
      }
    })
  }

  exportReport(){
    const reportCode: ReportCode = ReportCode.ReportPayrollConceptHistoryPaginated;
    const companyId = this.data.item.companyId;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&NewsConceptId=${this.data.item.newsConceptId}&CompanyId=${companyId}`

    const parameters = {
      url: reportUrl,
      title: 'Lista de Conceptos de Nóminas Historico',
      width: 1024,
      height: 768
    }

    openReport(parameters);
  }
 }
