import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


export interface SearchBarPaginationParameter {
  propkeyId: number,
  displayName: string,
  default?: boolean,
  type?: 'text' | 'date' | 'number',
  placeHolder?: string
};

export interface FilterEvent {
  propkeyId?: number;
  value?: string;
  default?: boolean;
}
@Component({
  selector: 'app-search-bar-pagination',
  templateUrl: './search-bar-pagination.component.html',
  styleUrls: ['./search-bar-pagination.component.css']
})
export class SearchBarPaginationComponent implements OnInit, OnChanges {
  @Input() parameters: SearchBarPaginationParameter[];
  @Input() namePageLocalStorage: string;
  @Output() onFilter = new EventEmitter<FilterEvent>()
  @Output() onChangeDropDown = new EventEmitter<FilterEvent>()
  value: string = ''
  parameter: SearchBarPaginationParameter;
  searchParameter: any

  config = {
    displayKey: 'displayName',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar',
    searchOnKey: 'displayName',
    noResultsFound: 'No se han encontrado registros'
  };
  /**
   *
   */
  constructor(
    private _changeDet: ChangeDetectorRef,
    private toastr: ToastrService
  ) {


  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setFilterStorage();

  }

  private setFilterStorage() {
    try {
      const localStorageSearch: FilterEvent = JSON.parse(localStorage.getItem(this.namePageLocalStorage));

      this.parameter = localStorageSearch?.propkeyId > 0 ? this.parameters?.find(p => p.propkeyId == localStorageSearch?.propkeyId) : null;
      this.value = localStorageSearch?.value ?? '';
      if (this.namePageLocalStorage) {
        this.filter()
      }
      if (this.parameter == null || !localStorageSearch) {
        this.parameter = this.parameters.find((item) => item.default)
      }
    } catch (exception) {
      this.parameter = this.parameters.find((item) => item.default)
    }

  }

  ngOnInit(): void {
    this.searchParameter = this.parameters.sort((a, b) => a.displayName.localeCompare(b.displayName))

  }

  filter() {
    const values = {
      propkeyId: this.parameter?.propkeyId ?? 0,
      value: this.value?.trim()?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, "")?.replace(/\s+/g, ' ')?.toLocaleLowerCase()
    }
    if (this.namePageLocalStorage) {
      localStorage.setItem(this.namePageLocalStorage, JSON.stringify(values));
    }
    this.onFilter.emit(values)
  }

  changeParameter() {

    const values = {
      propkeyId: this.parameter?.propkeyId ?? 0,
    }

    const id = this.parameter?.propkeyId
    this.value = ''
    if (id == 100) {
      this.onChangeDropDown.emit(values)
      this.parameter = null
    }
    if (!id) {
      this.filter()
    }
  }
  reset() {
    this.value = ''
    this.parameter = null
  }

}
