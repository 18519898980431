
    
      <div class="card-title">
        <div class="ttl d-flex align-items-center">
          <i class="flaticon-agregar-archivo-1 icon-3x text-danger mr-2"></i>
          <h1 class="text-primary">Apertura de Nómina</h1>
        </div>
      </div>

    <div class="card-boddy mt-20 mb-20 ">
      <div class="text-title">
        <p style="padding-left: 80px;" class="h4 font-weight-bold text-primary">Seleccione el Tipo de Nómina que desea Aperturar.</p>
      </div>
     
        
        <div class="d-flex flex-wrap justify-content-evenly p-5 mt-15">
            <div class="mb-5 card-payroll card-1">
                <a routerLink="/payroll/open-payroll/normal"
                  class=" btn text-center py-5 px-5">
                  <i class="flaticon-agregar-archivo-1 icon-5x text-white mt-13"></i>
                  <span class="d-block font-weight-bold mt-2 h5 text-white mt-5">Nómina Normal</span>
                </a>
              </div>
              <div class="mb-5 card-payroll card-2">
                <a routerLink="/payroll/additional-payroll" 
                  class=" btn text-center py-5 px-5">
                  <i class="flaticon-notas icon-5x text-white mt-13"></i>
                  <span class="d-block font-weight-bold mt-2 h5 text-white  mt-5">Nómina Adicional</span>
                </a>
              </div>
              <div class="mb-5 card-payroll card-3">
                <a routerLink="/payroll/incentive-payroll"
                  class=" btn text-center py-5 px-5">
                  <i class="flaticon-salario icon-5x text-white  mt-13"></i>
                  <span class="d-block font-weight-bold mt-2 h5 text-white  mt-5">Nómina de Incentivos sin Aportes</span>
                </a>
              </div>
              <div class="mb-5 card-payroll card-4">
                <a routerLink="/payroll/final-payroll"
                  class=" btn text-center py-5 px-5">
                  <i class="flaticon-lista-de-verificacion icon-5x text-white mt-13"></i>
                  <span class="d-block font-weight-bold mt-2 h5 text-white  mt-5">Nómina de Prestaciones Laborales</span>
                </a>
              </div>
              <div class="mb-5 card-payroll card-5">
                <a routerLink="/payroll/open-payroll/regalia"
                  class=" btn text-center py-5 px-5 ">
                  <i class="flaticon-agregar-archivo-1 icon-5x text-white  mt-13"></i>
                  <span class="d-block font-weight-bold mt-2 h5 text-white  mt-5">Nómina Regalía</span>
                </a>
              </div>
        </div>
     
        
    </div>
