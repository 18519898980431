export let userGuideNames = [
    {
        name: 'Reclutamiento_Seleccion',
        text: 'Reclutamiento y Selección'
    },
    {
        name: 'Registro_Control',
        text: 'Registro y Control'
    },
    {
        name: 'Estructura_Organica',
        text: 'Estructura Organica'
    },
    {
        name: 'Historial_Salud',
        text: 'Historial de Salud'
    },
    {
        name: 'Gestion_Contenido',
        text: 'Gestion de Contenido'
    },
    {
        name: 'nomina',
        text: 'Nómina'
    },
];