import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';



@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})

export class ChartComponent implements AfterViewInit, OnChanges, OnDestroy {

  private root: am5.Root;
  @Input() valueField = 'value'
  @Input() categoryField = 'description'
  @Input() data = []

  @Input() id: string = "chartComponet"
  @Input() height: number = 500;

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.root && changes['data'].currentValue) {
      this.root.dispose();
      this.root = am5.Root.new(this.id)
      this.buildChart()
    }
  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f()
      })
    }
  }

  ngAfterViewInit() {
    this.root = am5.Root.new(this.id)
    this.buildChart()
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose()
        document.getElementById(this.id).remove()
      }
    })
  }

  buildChart() {

    if (!this.root) {
      return
    }


    if(this.data.length == 0){
      return;
    }

    this.browserOnly(() => {

      const root = this.root;
      

      root.setThemes([
        am5themes_Animated.new(root)
      ]);
  
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
      }));
  
  
      // Data
      let colors = chart.get("colors");
  
      let data:any = this.data ? this.data : []
        data.forEach(e => {
          e.columnSettings = { fill: colors.next() }
        })
  
  
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: this.categoryField,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 30
        }),
        bullet: function (root, axis, dataItem) {
          return am5xy.AxisBullet.new(root, {
            location: 0.5,
            sprite: am5.Picture.new(root, {
              width: 24,
              height: 24,
              centerY: am5.p50,
              centerX: am5.p50,
            })
          });
        }
      }));
  
      xAxis.get("renderer").labels.template.setAll({
        paddingTop: 20
      });
  
      xAxis.data.setAll(data);
  
      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      }));
  
  
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: this.valueField,
        categoryXField: this.categoryField
      }));
  
      series.columns.template.setAll({
        tooltipText: "{categoryX}: {valueY}",
        tooltipY: 0,
        strokeOpacity: 0,
        templateField: "columnSettings",
        cornerRadiusTL: 6,
        cornerRadiusTR: 6
      });
  
         
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            text: "{valueYWorking}",
            fill: root.interfaceColors.get("alternativeText"),
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            populateText: true,
          })
        });
      });
  
      series.data.setAll(data);
  
  
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();
      chart.appear(1000, 100);

      this.root = root;
    });
  }

}
