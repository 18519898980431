<div class="row overS">
    <h2 mat-dialog-title>Agregar habilidad</h2>
    <form [formGroup]="skillForm" (ngSubmit)="Accept()">
        <div class="form-group col-md-12">
            <div>
                <label for="skill">Competencia o habilidad <span class="text-danger text-monospace legend">*</span>
                </label>
            </div>
            <input name="skill" class="form-control form-control-solid form-control-lg" placeholder="Ej: Manejo de Excel" type="string" required="" formControlName='skill' /><span class="form-text text-danger d-none">Este
                campo es requerido</span>
        </div>
        <div class="form-group col-md-12">
            <label for="level" class="d-block w-100">Nivel <span class="text-danger text-monospace legend">*</span> </label>
            <div class="dropdown-alt input-group">
                <select name="level" class="form-control w-100 form-control-solid form-control-lg" formControlName='level'>
                    <option value="" selected disabled>Elige una opción</option>
                    <option *ngFor="let skillLevel of skillLevels" value="{{skillLevel.stringData}}">{{skillLevel.stringData}}</option>
                </select>
            </div>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="col-md-12 d-flex form-group justify-content-end mt-4 text-right">
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button" (click)="Cancel()"><i class="flaticon2-cross"></i> Cerrar</button>
            <button type="button" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" (click)="Accept()">
                <i class="flaticon-disco-flexible"></i><span> Guardar </span>
            </button>
        </div>
    </form>
</div>