import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class CandidateDashboardService {

  private readonly apiCore = environment.api_core_url
  constructor(
    private http: HttpClient,
    private authInfo: AuthInfoService
  ) { }

  getGender(){
    return this.http.get(`${this.apiCore}/Persons/Dashboard?Type=2&Status=true&CompanyId=${this.authInfo.getCompanyId()}`)
  }
  getTotal(dateTo: string,
    dateFrom: string, companyId = this.authInfo.getCompanyId(),
    status: boolean = true){
    return this.http.get(`${this.apiCore}/Persons/Dashboard?DateTo=${dateTo}&DateFrom=${dateFrom}&CompanyId=${companyId}&Status=${status}`)
  }
  getStatsCounty(){
    return this.http.get(`${this.apiCore}/Persons/Dashboard?Type=3&Status=true&CompanyId=${this.authInfo.getCompanyId()}`)
  }
  getAgeStats(){
    return this.http.get(`${this.apiCore}/Persons/Dashboard?Type=4&Status=true&CompanyId=${this.authInfo.getCompanyId()}`)
  }
  getStatsByAcademicLevel(){
    return this.http.get(`${this.apiCore}/Persons/Dashboard?Type=5&Status=true&CompanyId=${this.authInfo.getCompanyId()}`)
  }
}
