import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { DisciplinaryActionsService } from 'src/app/services/disciplinary-actions.service';
import { FilterEvent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { IOffenseView } from 'src/app/shared/models/disciplinaryActions.model';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';

@Component({
  selector: 'list-of-faults-modal',
  templateUrl: './list-of-faults-modal.component.html',
  styleUrls: ['./list-of-faults-modal.component.css']
})
export class ListOfFaultsModalComponent implements AfterViewInit {

  grades: { name: string, code: number }[] = [
    { name: 'Primer Grado', code: 1 },
    { name: 'Segundo Grado', code: 2 },
    { name: 'Tercer Grado', code: 3 },
    { name: 'Privacidad de Libertad', code: 4 }
  ];

  searchList: IOffenseView[] = [];

  searchBarDisplayNames: { propKeyId: number, propkey: string, displayName: string, exactSearch?: boolean, searchKey?:string, default?:any }[] =
    [
      { propKeyId: 1,propkey: "offenseId", displayName: "Secuencia"},
      { propKeyId: 2,propkey: "description", displayName: "Descripción", default: true },
      { propKeyId: 3,propkey: "grade", displayName: "Grado" },
      { propKeyId: 5,propkey: "gradeId", displayName: "Número de Grado" }
    ];
  filterText = '';
  searchKeySelected = '';
  searchPropKeySelected!: { propKeyId?: number, propkey?: string, displayName: string };
  filteredList: { records: IOffenseView[], enable: boolean } = { records: [], enable: false };
  selectedItems: any[] = [];

  offensesList: IOffenseView[] = [];
  offensesListFiltered: IOffenseView[] = [];
  offensesListPaginated: IOffenseView[] = [];

  offenseSelected!: IOffenseView;

  dropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  isRecurringOffense: boolean = false;

  selectedPage: any
  pageSize: number

  constructor(public dialogRef: MatDialogRef<ListOfFaultsModalComponent>,
    private toastr: ToastrService,
    private discService: DisciplinaryActionsService,
    private authInfo: AuthInfoService,
    @Inject(MAT_DIALOG_DATA) public data: { searchText: string, maxGrade: number, couldBeRecurring: boolean, previousOffenseSelected: IOffenseView },
    private cdRef: ChangeDetectorRef,
    private excelService: ExportToExcelService) {
    
    if(data.previousOffenseSelected){
      this.offenseSelected = data.previousOffenseSelected;
    }

    this.getList();
  }

  ngAfterViewInit(): void {
    
  }

  getList(): void {
    this.discService.getOffensesList(0, this.data.maxGrade, this.authInfo.getCompanyId(), 2).subscribe({
      next: (res) => {
        if (!res.succeded) {

          res.errors.forEach(err => {
            this.toastr.error(err);
          });

          res.warnings.forEach(warn => {
            this.toastr.warning(warn);
          });

          return;
        }

        this.offensesList = res.dataList;
        this.filter();
        this.filterText = this.data?.searchText;
        
      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error inesperado consultando el listado de faltas')
      }
    })
  }

  changeRecurringOffense(value: boolean){
    this.isRecurringOffense = value;
    this.filter();
  }

  onKeyParameterChange(value: { propKeyId?: number, propkey: string, displayName: string } | null) {
    this.searchKeySelected = value?.propkey ?? '';
    this.searchPropKeySelected = value ?? null;
  }

  onKeyChange(text: string) {
    this.filterText = text ?? '';
  }

  exportToExcel() {
    let data: IOffenseView[] = []

    if (this.filteredList.enable) {
      data = this.filteredList.records
    } else {
      data = this.offensesListFiltered
    }

    const excelHeaders: string[][] = [[
      "Secuencia",
      "Descripción",
      "Grado",
      "Estatus"
    ]];

    const records = data.map(d => {
      return {
        offenseId: d.offenseId,
        description: d.description,
        grade: d.grade,
        status: d.status ? 'Activa' : 'Inactiva'
      }
    });

    this.excelService.exportToExcelSpecificColumns(records, excelHeaders, 'Listado de Faltas');
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.offensesListFiltered = this.filterTable(this.offensesList)
    }
  }

  filterTable(list: IOffenseView[]) {

    if(this.isRecurringOffense){
      const maxGrade: number = this.data.maxGrade ?? 0;
      list = list.filter(x=>x.gradeId > maxGrade);
    }

    const selected = [...this.selectedItems];

    if (selected.length === 0) {
      return list;
    }

    list = list.filter(item=> selected.some(s=>{
      if(s.code == item.gradeId){
        return true
      };
    }));

    return list;
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = this.filterTable(event.records)
    this.cdRef.detectChanges()
  }

  getPaginatedRecords(event) {
    this.offensesListPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.cdRef.detectChanges()
    this.selectedPage = event.selectedPage
    this.pageSize = event?.registersPerPage
  }

  selectItem(item: IOffenseView){
    this.offenseSelected = item;
  }

  closeModal(succeded: boolean = false) {
    this.dialogRef.close({ succeded, data: this.offenseSelected });
  }

  

  exportToPdf() {
    if (this.offensesListPaginated.length <= 0) {
      this.toastr.warning('No se puede cargar el reporte debido a que no hay datos disponibles', 'No hay datos para mostrar')
      return
    }
    const reportCode: ReportCode = ReportCode.ExportOffense;
    const companyId: number = this.authInfo.getCompanyId()
    const pageSize = this.pageSize
    const propKeyId = this.searchPropKeySelected?.propKeyId ?? 0
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&Status=1&PropkeyId=${propKeyId}&SearchParameter=${this.filterText ?? ''}&Page=${this.selectedPage}&PageSize=${pageSize}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Códigos Certificación',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

}
