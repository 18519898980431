import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-show-sworn-declaration',
  templateUrl: './show-sworn-declaration.component.html',
  styleUrls: ['./show-sworn-declaration.component.css']
})
export class ShowSwornDeclarationComponent implements OnInit {

  swornDeclaration: string

  constructor(private sanitizer: DomSanitizer, public dialogRef: MatDialogRef<ShowSwornDeclarationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private actionPSrv: PersonnelActionsService, private _toastService: ToastService) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.actionPSrv.getSwornDeclaration(this.data.entryListHistoryId).subscribe({
      next: (res: any) => {
        // this.swornDeclaration = res.singleData?.base64
        const pdfContent = document.getElementById('pdfContent') as HTMLElement;

        if (pdfContent) {
          pdfContent.style.display = "block";
          const pdf = 'data:application/pdf;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(res.singleData?.base64) as any).changingThisBreaksApplicationSecurity;
          document.getElementById('pdfSHow').innerHTML = `
          <div class="col-md-12">
            <iframe src="${pdf}"  height="700px" width="100%"></iframe>
          </div>`;
        }
      },
      error: err => this._toastService.error(err)
    })
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
