import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { MobileHeaderComponent } from './layout/dashboard/mobile-header/mobile-header.component';
import { SideBarComponent } from './layout/dashboard/side-bar/side-bar.component';
import { HeaderComponent } from './layout/dashboard/header/header.component';
import { BreadCrumComponent } from './layout/dashboard/header/bread-crum/bread-crum.component';
import { FooterComponent } from './layout/dashboard/footer/footer.component';
import { SideBarContentComponent } from './layout/dashboard/side-bar/side-bar-content/side-bar-content.component';
import { HeaderContentComponent } from './layout/dashboard/header/header-content/header-content.component';
import { NotificationContentComponent } from './layout/dashboard/header/notification-content/notification-content.component';
import { NotificationComponent } from './layout/dashboard/header/notification/notification.component';
import { HomeComponent } from './pages/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PersonalRequisitionComponent } from './pages/personal-requisition/personal-requisition.component';
import { CandidateRegistrationComponent } from './pages/candidate-registration/candidate-registration.component';
import { SharedModule } from './shared/shared.module';
import { HttpInterceptorService } from './services/http-client/http-interceptor';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { PersonalInformationComponent } from './pages/candidate-registration/components/personal-information/personal-information.component';
import { ContactComponentComponent } from './pages/candidate-registration/components/contact-component/contact-component.component';
import { FamilyDataComponent } from './pages/candidate-registration/components/family-data/family-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdditionalInfoComponent } from './pages/candidate-registration/components/additional-info/additional-info.component';
import { NgxMaskModule } from 'ngx-mask';
import { AcademicComponent } from './pages/candidate-registration/components/academic/academic.component';
import { HistoryReferencesComponent } from './pages/candidate-registration/components/history-references/history-references.component';
import { RequisitionComponent } from './pages/personal-requisition/requisition/requisition.component';
import { SkillsRequiredComponent } from './pages/personal-requisition/skills-required/skills-required.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { InterviewEvaluationComponent } from './pages/interview-evaluation/interview-evaluation.component';
import { ConfirmationComponent } from './shared/confirmation/confirmation.component';
import { VacanciesListComponent } from './pages/post-vacancies/components/vacancies-list/vacancies-list.component';
import { PostVacanciesComponent } from './pages/post-vacancies/components/post-vacancies/post-vacancies.component';
import { CandidateSearchResultComponent } from './pages/candidate-search-result/candidate-search-result.component';
import { ApplyVacanciesComponent } from './pages/apply-vacancies/apply-vacancies.component';
import { ProofApplicantsComponent } from './pages/proof-applicants/proof-applicants.component';
import { SearchModalComponent } from './pages/proof-applicants/search-modal/search-modal.component';
import { JobOfferComponent } from './pages/job-offer/job-offer.component';
import { ToastrModule } from 'ngx-toastr';
import { CandidateRegistrationListComponent } from './pages/candidate-registration/components/candidate-registration-list/candidate-registration-list.component';
import { SkillsComponent } from './pages/candidate-registration/components/skills/skills.component';
import { RequiredFilesComponent } from './pages/candidate-registration/components/required-files/required-files.component';
import { RequestNoObjectionMapComponent } from './pages/request-no-objection-map/request-no-objection-map.component';
import { MAPModule } from './pages/map/map.module';
import { PersonnelActionsComponent } from './pages/RegistrationAndControl/personnel-actions/personnel-actions.component';
import { ApprovalEmployeeChangeActionsComponent } from './pages/RegistrationAndControl/approval-employee-change-actions/approval-employee-change-actions.component';
import { EmployeesComponent } from './pages/RegistrationAndControl/employees/employees.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';

import { IncidentsModalSearchComponent } from './pages/EvaluationPerformance/incidents/incidentsModal/incidents-modal-search/incidents-modal-search.component';
import { IncidentsListComponent } from './pages/EvaluationPerformance/incidents/incidents-list/incidents-list.component';
import { IncidentsComponent } from './pages/EvaluationPerformance/incidents/incidents.component';
import { IncidentesDashboardComponent } from './pages/EvaluationPerformance/incidents/incidentes-dashboard/incidentes-dashboard.component';
import { AnnualOperativePlanListComponent } from './pages/EvaluationPerformance/AnnualOperativePlan/annual-operative-plan-list/annual-operative-plan-list.component';
import { DateFilterComponent as IncidentDateFilter } from './pages/EvaluationPerformance/incidents/incidentes-dashboard/date-filter/date-filter.component';

import { ModalPersonnelActionsComponent } from './pages/RegistrationAndControl/personnel-actions/modal-personnel-actions/modal-personnel-actions.component';
import { FundsCertificationComponent } from './pages/funds-certification/funds-certification.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PDFPreviewComponent } from './pages/RegistrationAndControl/pdf-preview/pdf-preview.component';
import { EmployeeSearchResultComponent } from './pages/RegistrationAndControl/approval-employee-change-actions/dialog/employee-search-result/employee-search-result.component';
import { EmployeeExitVerificationComponentMockup } from './pages/RegistrationAndControl/employee-exit-verification/employee-exit-verification.component';
import { InterviewListComponent } from './pages/interview-evaluation/interview-list/interview-list.component';
import { ProofApplicantsListComponent } from './pages/proof-applicants/proof-applicants-list/proof-applicants-list.component';
import { EmployeeMaintenanceComponent } from './pages/RegistrationAndControl/employee-maintenance/employee-maintenance.component';
import { PersonnelActionsEmployeeListComponent } from './pages/RegistrationAndControl/personnel-actions/personnel-actions-employee-list/personnel-actions-employee-list.component';
import { ApplyVacanciesListComponent } from './pages/apply-vacancies/apply-vacancies-list/apply-vacancies-list.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { RequsitionSummaryComponent } from './pages/personal-requisition/requisitions-summary/requsition-summary/requsition-summary.component';
import { RemissionNoObjectionComponent } from './pages/request-no-objection-map/remission-no-objection/remission-no-objection.component';
import { StaffRequirementSummaryComponent } from './pages/personal-requisition/staff-requirement-summary/staff-requirement-summary.component';
import { SummaryInterviewComponent } from './pages/interview-evaluation/summary-interview/summary-interview.component';
import { EmployeeDepartmentComponent } from './pages/RegistrationAndControl/employee-maintenance/components/employee-department/employee-department.component';
import { EmployeeDependentsModalComponent } from './pages/RegistrationAndControl/employee-maintenance/employee-dependents/employee-dependents-modal/employee-dependents-modal.component';
import { EmployeeDependentsComponent } from './pages/RegistrationAndControl/employee-maintenance/employee-dependents/employee-dependents.component';
import { EmployeeInfoComponent } from './pages/RegistrationAndControl/employee-maintenance/employee-info/employee-info.component';
import { EmployeePaymentDetailsComponent } from './pages/RegistrationAndControl/employee-maintenance/employee-payment-details/employee-payment-details.component';
import { EmployeePaymentsComponent } from './pages/RegistrationAndControl/employee-maintenance/employee-payments/employee-payments.component';
import { EmployeeTimeRecognitionComponent } from './pages/RegistrationAndControl/employee-maintenance/employee-time-recognition/employee-time-recognition.component';
import { EmployeeTransactionsModalComponent } from './pages/RegistrationAndControl/employee-maintenance/employee-transactions/employee-transactions-modal/employee-transactions-modal.component';
import { EmployeeTransactionsComponent } from './pages/RegistrationAndControl/employee-maintenance/employee-transactions/employee-transactions.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NavComponent } from 'src/app/layout/nav/nav.component';
import { InterceptorService } from 'src/app/layout/loader/interceptor.service';
import { CleanInterceptor } from 'src/app/services/http-client/clean.interceptor';
import { OrganicStructureComponent } from 'src/app/pages/organic-structure/organic-structure.component';
import { DropdownMultiSelectComponent } from './shared/element-ui/dropdown-multi-select/dropdown-multi-select.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PersonnelActionsTapComponent } from './pages/RegistrationAndControl/personnel-actions/personnel-actions-tap/personnel-actions-tap.component';
import { PdfPreviewResultadosComponent } from './pages/RegistrationAndControl/pdf-preview-resultados/pdf-preview-resultados.component';
import { ValidateProfileComponent } from './pages/request-no-objection-map/validate-profile/validate-profile.component';
import { PdfActionChangeEmployeeComponent } from './pages/RegistrationAndControl/pdf-action-change-employee/pdf-action-change-employee.component';
import { PdfActionChangeEmployeeLicenseComponent } from './pages/RegistrationAndControl/pdf-action-change-employee-license/pdf-action-change-employee-license.component';
import { PdfActionChangeEmployeeVacationsComponent } from './pages/RegistrationAndControl/pdf-action-change-employee-vacations/pdf-action-change-employee-vacations.component';
import { PdfActionChangeEmployeePermisosComponent } from './pages/RegistrationAndControl/pdf-action-change-employee-permisos/pdf-action-change-employee-permisos.component'

import { PdfActionChangeEmployeeDesvincularComponent } from './pages/RegistrationAndControl/pdf-action-change-employee-desvincular/pdf-action-change-employee-desvincular.component';
import { EmployeePersonnelActionsListComponent } from './pages/RegistrationAndControl/employee-personnel-actions-list/employee-personnel-actions-list.component'
import { payrollModule } from '@payroll/pay-roll.module';
import { UnlinkEmployeesTabComponent } from './pages/RegistrationAndControl/unlink-employees-tab/unlink-employees-tab.component'
import { VacanciesDashboardComponent } from './pages/post-vacancies/components/vacancies-dashboard/vacancies-dashboard.component';
import { DashboardComponent as requisitionDashboard } from './pages/personal-requisition/requisition-dashboard/dashboard/dashboard.component';
import { CandidateRegistrationDashboardComponent } from './pages/candidate-registration/components/candidate-registration-dashboard/candidate-registration-dashboard.component';
import { DateFilterComponent } from './pages/personal-requisition/requisition-dashboard/dateFilter/date-filter/date-filter.component';
import { ApplyVacanciesDashboardComponent } from './pages/apply-vacancies/apply-vacancies-dashboard/apply-vacancies-dashboard.component';
import { DateFilterComponent as EmployeeDateFilter } from './pages/RegistrationAndControl/employees/dashboard/employee-dashboard/date-filter/date-filter/date-filter.component';
import { DateFilterComponent as InterviewDateFilter } from './pages/interview-evaluation/interview-list/dashboard/date-filter/date-filter/date-filter.component';
import { DateFilterComponent as JobOfferDateFilter } from './pages/job-offer/job-offer-dashboard/date-filter/date-filter.component';
import { EmployeeDashboardComponent } from './pages/RegistrationAndControl/employees/dashboard/employee-dashboard/employee-dashboard.component';
import { InterviewDashboardComponent } from './pages/interview-evaluation/interview-list/dashboard/interview-dashboard/interview-dashboard.component';
import { ProofDashboardComponent } from './pages/proof-applicants/proof-dashboard/proof-dashboard.component';
import { JobOfferDashboardComponent } from './pages/job-offer/job-offer-dashboard/job-offer-dashboard.component';
import { DashboardNoObjectionComponent } from './pages/request-no-objection-map/dashboard-no-objection/dashboard-no-objection.component';

import { PersonnelActionsDashboardComponent } from './pages/RegistrationAndControl/personnel-actions-dashboard/personnel-actions-dashboard.component';
import { JobOfferModalComponent } from './pages/job-offer/job-offer-modal/job-offer-modal.component';
import { DeparmentsComponent } from './pages/deparments/deparments.component';
import { DeparmentsListComponent } from './pages/deparments/deparments-list/deparments-list.component';
import { ChartComponent } from './shared/chart/chart.component';
import { BarChartRaceComponent } from './shared/bar-chart-race/bar-chart-race.component';
import { SemiCircleComponent } from './shared/semi-circle/semi-circle.component';

import { ChartsourceComponent } from './shared/chartsource/chartsource.component';
import { ChrtAreaRadarComponent } from './shared/chrt-area-radar/chrt-area-radar.component';
import { ChartDnutComponent } from './shared/chart-dnut/chart-dnut.component';
import { FingerPrintComponent } from './pages/candidate-registration/components/finger-print/finger-print.component';
import { LoadingComponent } from './loading/loading/loading.component';
import { PdfEmployeeDataComponent } from './pages/RegistrationAndControl/pdf-employee-data/pdf-employee-data.component';
import { PositionsListComponent } from './pages/position-list/position-list.component';
import { ExternalServiceTapComponent } from './pages/RegistrationAndControl/personnel-actions/personnel-actions-tap/external-service-tap/external-service-tap.component';
import { PdfActionChangeEmployeeExservicesComponent } from './pages/RegistrationAndControl/pdf-action-change-employee-exservices/pdf-action-change-employee-exservices.component';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthGuard } from './shared/auth/auth.guard';
import { AssistControlComponent } from './pages/RegistrationAndControl/employees/assist-control/assist-control.component';
import { registerLocaleData } from '@angular/common';
import localePy from '@angular/common/locales/es-US';
import { LicensesTabComponent } from './pages/RegistrationAndControl/personnel-actions/licenses-tab/licenses-tab.component';
import { PdfEmployeeeExportComponent } from './pages/RegistrationAndControl/employees/pdf-employeee-export/pdf-employeee-export.component';
import { EmployeePersoneelActDashboardComponent } from './pages/RegistrationAndControl/employee-personeel-act-dashboard/employee-personeel-act-dashboard.component';
import { PdfPersonnelActionsRegulatedComponent } from './pages/RegistrationAndControl/pdf-personnel-actions-regulated/pdf-personnel-actions-regulated.component';
import { EmployeesIncompleteComponent } from './pages/RegistrationAndControl/employees-incomplete/employees-incomplete.component';
import { EvindenceModalComponent } from './pages/RegistrationAndControl/unlink-employees-tab/evindence-modal/evindence-modal.component';
import { EditNoObjectionModalComponent } from './pages/request-no-objection-map/edit-no-objection-modal/edit-no-objection-modal.component';
import { ExperienceComponent } from './pages/personal-requisition/experience/experience.component';
import { KnowledgeComponent } from './pages/personal-requisition/knowledge/knowledge.component';
import { IncompletedFilesComponent } from './pages/RegistrationAndControl/employees-incomplete/incompleted-files/incompleted-files.component';
import { ParametersSendConcursaComponent } from './pages/personal-requisition/parameters-send-concursa/parameters-send-concursa.component';
import { ServiceCommissionComponent } from './pages/RegistrationAndControl/personnel-actions/service-commission/service-commission.component';
import { SignApprovalProcessComponent } from './pages/sign-approval-process/sign-approval-process.component';
import { ConcursaStatusModalComponent } from './pages/personal-requisition/concursa-status-modal/concursa-status-modal.component';
import { DeclarationModalComponent } from './pages/RegistrationAndControl/approval-employee-change-actions/declaration-modal/declaration-modal.component';
import { ShowSwornDeclarationComponent } from './pages/RegistrationAndControl/approval-employee-change-actions/show-sworn-declaration/show-sworn-declaration.component';
import { CreatePositionModalComponent } from './pages/position-list/create-position-modal/create-position-modal.component';
import { VacancyModalComponent } from './pages/Planning/components/vacancyModal/vacancy-modal/vacancy-modal.component';
import { ReturnMotivesModalComponent } from './pages/Planning/components/return-motives-modal/return-motives-modal.component';
import { ViewReasonRejectComponent } from './pages/position-list/view-reason-reject/view-reason-reject.component';
import { PositionChangesComponent } from './pages/position-changes/position-changes.component';
import { BannsComponent } from './pages/RegistrationAndControl/personnel-actions/banns/banns.component';
import { PositionsListDescriptionComponent } from './pages/positions-list-description/positions-list-description.component';
import { ModalCompetencyDictionarySearchComponent } from './pages/positions-list-description/modal-competency-dictionary-search/modal-competency-dictionary-search.component';
import { CollectiveVacationsComponent } from './pages/RegistrationAndControl/collective-vacations/collective-vacations.component';
import { CollectiveVacationPublicServicesModalComponent } from './pages/RegistrationAndControl/collective-vacations/collective-vacation-public-services-modal/collective-vacation-public-services-modal.component';
import { PublicServantCollectiveVacationModalComponent } from './pages/RegistrationAndControl/collective-vacations/public-servant-collective-vacation-modal/public-servant-collective-vacation-modal.component';
import { ContentManagementListComponent } from './pages/content-management/content-management-list/content-management-list.component';
import { AddContentModalComponent } from './pages/content-management/content-management-list/add-content-modal/add-content-modal.component';
import { PositionListDescriptionComponent } from './pages/positions-list-description/position-list-description/position-list-description.component';
import { ViewBehaviorsComponent } from './pages/positions-list-description/position-list-description/view-behaviors/view-behaviors.component';
import { ConsultCollectiveVacationsComponent } from './pages/RegistrationAndControl/consult-collective-vacations/consult-collective-vacations.component';
import { AddCollectiveVacationsComponent } from './pages/RegistrationAndControl/consult-collective-vacations/add-collective-vacations/add-collective-vacations.component';
import { PdfPositionsDescriptionComponent } from './pages/positions-list-description/pdf-positions-description/pdf-positions-description.component';
import { HourlyTeachersComponent } from './pages/RegistrationAndControl/hourly-teachers/hourly-teachers.component';
import { SocialMedialComponent } from './pages/social-medial/social-medial.component';
import { SocialMediaModalComponent } from './pages/social-medial/social-media-modal/social-media-modal.component';
import { AddTeacherContractComponent } from './pages/RegistrationAndControl/hourly-teachers/add-teacher-contract/add-teacher-contract.component';
import { ViewContractsComponent } from './pages/RegistrationAndControl/hourly-teachers/view-contracts/view-contracts.component';
import { CollectiveActionsComponent } from './pages/collective-actions/collective-actions.component';
import { TelephoneDirectoryComponent } from './pages/telephone-directory/telephone-directory.component';
import { DepartmentTabComponent } from './pages/telephone-directory/department-tab/department-tab.component';
import { EmployeesTabComponent } from './pages/telephone-directory/employees-tab/employees-tab.component';
import { ResetPasswordModalComponent } from './layout/dashboard/header/reset-password-modal/reset-password-modal/reset-password-modal.component';
import { DirectoryModalComponent } from './pages/telephone-directory/directory-modal/directory-modal/directory-modal.component';
import { environment } from 'src/environments/environment';
import { HistoryPersonalActionsComponent } from './pages/RegistrationAndControl/employees/history-personal-actions/history-personal-actions.component';
import { EmployeeServiceRequestComponent } from './pages/RegistrationAndControl/employee-service/employee-service-request/employee-service-request.component';
import { EmployeeServiceRequestModalComponent } from './pages/RegistrationAndControl/employee-service/employee-service-request-detail/employee-service-request-modal.component';
import { DashboardHourlyTeachersComponent } from './pages/RegistrationAndControl/hourly-teachers/dashboard-hourly-teachers/dashboard-hourly-teachers.component';
import { RejectPermissionModalComponent } from './pages/RegistrationAndControl/personnel-actions/personnel-actions-tap/reject-permission-modal/reject-permission-modal.component';
import { RejectLicenseModalComponent } from './pages/RegistrationAndControl/personnel-actions/licenses-tab/reject-license-modal/reject-license-modal.component';
import { WizardBenefitsModalComponent } from './pages/candidate-registration/components/personal-information/wizard-benefits-modal/wizard-benefits-modal.component';
import { ReportListComponent } from './pages/RegistrationAndControl/report-list/report-list.component';
import { CertificationReportComponent } from './pages/RegistrationAndControl/reports/certification-report/certification-report.component';
import { PayrollTransparencyComponent } from './pages/RegistrationAndControl/reports/payroll-transparency/payroll-transparency.component';
import { employeeServiceRequestDetailModalFormComponent } from './pages/RegistrationAndControl/employee-service/employee-service-request-detail-modal-form/employee-service-request-detail-modal-form.component';
import { SelectDepartmentModalComponent } from './pages/personal-requisition/select-department-modal/select-department-modal/select-department-modal.component';
import { ApplyVacancyModalComponent } from './pages/apply-vacancies/apply-vacancy-modal/apply-vacancy-modal.component';
import { SalaryRangeComponent } from './pages/apply-vacancies/apply-vacancy-modal/salary-range/salary-range.component';
import { OccupationalGroupsReportComponent } from './pages/RegistrationAndControl/reports/occupational-groups-report/occupational-groups-report.component';
import { EmployeeBirthdayReportComponent } from './pages/RegistrationAndControl/reports/employee-birthday-report/employee-birthday-report.component';
import { AgeChildrenEmployeesReportComponent } from './pages/RegistrationAndControl/reports/age-children-employees-report/age-children-employees-report.component';
import { DepartmentChangesComponent } from './pages/department-changes/department-changes.component';
import { EmployeeResponseRequestComponent } from './pages/RegistrationAndControl/employee-service/employee-reponse-request/employee-reponse-request.component';
import { UserGuideComponent } from './pages/RegistrationAndControl/user-guide/user-guide.component';
import { IndicatorsPayrollComparisonReportComponent } from './pages/payroll-new/payroll-report/indicators-payroll-comparison-report/indicators-payroll-comparison-report.component';
import { SeparationsComponent } from './pages/RegistrationAndControl/separations/separations.component';
import { VinculationsComponent } from './pages/RegistrationAndControl/vinculations/vinculations.component';
import { SeparationsDetailBulkLoadComponent } from './pages/RegistrationAndControl/separations-detail-bulk-load/separations-detail-bulk-load.component';
import { VinculationsDetailBulkLoadComponent } from './pages/RegistrationAndControl/vinculations-detail-bulk-load/vinculations-detail-bulk-load.component';
import { PauseVacationModalComponent } from './pages/RegistrationAndControl/personnel-actions/personnel-actions-tap/pause-vacation-modal/pause-vacation-modal.component';
import { ShowFileVacationModalComponent } from './pages/RegistrationAndControl/personnel-actions/personnel-actions-tap/show-file-vacation-modal/show-file-vacation-modal.component';
import { CancelPersonalActionModalComponent } from './pages/RegistrationAndControl/employee-personnel-actions-list/cancel-personal-action-modal/cancel-personal-action-modal.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { InSubstitutionModalComponent } from './pages/personal-requisition/requisition/in-substitution-modal/in-substitution-modal/in-substitution-modal.component';
import { AppliedVacancyModalComponent } from './pages/interview-evaluation/appliedVacancy-modal/applied-vacancy-modal/applied-vacancy-modal.component';
import { AdvancedFilterByEmployeesComponent } from './pages/RegistrationAndControl/employees/advanced-filter-by-employees/advanced-filter-by-employees.component';
import { DisciplinaryMeasuresComponent } from './pages/RegistrationAndControl/personnel-actions/disciplinary-measures/disciplinary-measures.component';
import { ListOfFaultsModalComponent } from './pages/RegistrationAndControl/personnel-actions/disciplinary-measures/list-of-faults-modal/list-of-faults-modal.component';
import { FirstGradeComponent } from './pages/RegistrationAndControl/personnel-actions/disciplinary-measures/first-grade/first-grade.component';
import { SecondAndThirdGradeComponent } from './pages/RegistrationAndControl/personnel-actions/disciplinary-measures/second-and-third-grade/second-and-third-grade.component'
import { InstitutionRelativesComponent } from './pages/candidate-registration/components/personal-information/institution-relatives/institution-relatives/institution-relatives.component';
import { RecomendationsComponent } from './pages/candidate-registration/components/personal-information/recomendations/recomendations/recomendations.component';
import { AddPhoneComponent } from './pages/candidate-registration/components/contact-component/add-phone/add-phone/add-phone.component';
import { AddEmailComponent } from './pages/candidate-registration/components/contact-component/add-email/add-email/add-email.component';
import { AddDependentsComponent } from './pages/candidate-registration/components/family-data/add-dependents/add-dependents/add-dependents.component';
import { FrequentQuestionsComponent } from './pages/FrequentQuestions/frequent-questions/frequent-questions.component';
import { AdvancedFilterByPersonalRequisitionComponent } from './pages/personal-requisition/advanced-filter-by-personal-requisition/advanced-filter-by-personal-requisition.component';
import { BirthdayComponent } from './pages/RegistrationAndControl/birthday/birthday.component';
import { AdvancedFilterByPersonnelActionsComponent } from './pages/RegistrationAndControl/employee-personnel-actions-list/advanced-filter-by-personnel-actions/advanced-filter-by-personnel-actions.component';

import { TeacherReviewsComponent } from './pages/RegistrationAndControl/hourly-teachers/teacher-reviews/teacher-reviews.component';
import { TeacherReviewsListComponent } from './pages/RegistrationAndControl/hourly-teachers/teacher-reviews/teacher-reviews-list/teacher-reviews-list.component';
import { TeacherReviewsModalComponent } from './pages/RegistrationAndControl/hourly-teachers/teacher-reviews/teacher-reviews-modal/teacher-reviews-modal.component';
import { MassiveChangeSupervisorComponent } from './pages/RegistrationAndControl/massive-change-supervisor/massive-change-supervisor.component';

import { DataValidationseparationsDetailModalComponent } from './pages/RegistrationAndControl/separations/data-validation-separations-detail-modal/data-validation-separations-detail-modal.component';
import { AuditChangesRequisitionComponent } from './pages/personal-requisition/audit-changes/audit-changes-requisition.component';

import { AuditChangesComponent } from './pages/RegistrationAndControl/employees/audit-changes/audit-changes.component';


export function tokenGetter() {
  return localStorage.getItem('token');
}

registerLocaleData(localePy, 'es');

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MobileHeaderComponent,
    SideBarComponent,
    HeaderComponent,
    BreadCrumComponent,
    ApplyVacanciesComponent,
    PostVacanciesComponent,
    FooterComponent,
    SideBarContentComponent,
    HeaderContentComponent,
    NotificationContentComponent,
    NotificationComponent,
    HomeComponent,
    PersonalRequisitionComponent,
    RequisitionComponent,
    CandidateRegistrationComponent,
    PersonalInformationComponent,
    ContactComponentComponent,
    FamilyDataComponent,
    AdditionalInfoComponent,
    AcademicComponent,
    HistoryReferencesComponent,
    SkillsRequiredComponent,
    InterviewEvaluationComponent,
    ConfirmationComponent,
    VacanciesListComponent,
    CandidateSearchResultComponent,
    CandidateRegistrationListComponent,
    SkillsComponent,
    RequiredFilesComponent,
    ProofApplicantsComponent,
    SearchModalComponent,
    JobOfferComponent,
    CandidateSearchResultComponent,
    CandidateRegistrationListComponent,
    RequestNoObjectionMapComponent,
    PersonnelActionsComponent,
    ApprovalEmployeeChangeActionsComponent,
    IncidentsComponent,
    EmployeesComponent,
    IncidentsModalSearchComponent,
    ModalPersonnelActionsComponent,
    PDFPreviewComponent,
    EmployeeSearchResultComponent,
    EmployeeExitVerificationComponentMockup,
    IncidentsListComponent,
    InterviewListComponent,
    ProofApplicantsListComponent,
    EmployeeMaintenanceComponent,
    PersonnelActionsEmployeeListComponent,
    ApplyVacanciesListComponent,
    SummaryComponent,
    EmployeeInfoComponent,
    EmployeeDepartmentComponent,
    EmployeePaymentDetailsComponent,
    EmployeeDependentsComponent,
    EmployeeTimeRecognitionComponent,
    EmployeeTransactionsComponent,
    EmployeePaymentsComponent,
    EmployeeDependentsModalComponent,
    EmployeeTransactionsModalComponent,
    RemissionNoObjectionComponent,
    RequsitionSummaryComponent,
    RemissionNoObjectionComponent,
    StaffRequirementSummaryComponent,
    SummaryInterviewComponent,
    NavComponent,
    //DonutChartComponent,
    OrganicStructureComponent,
    PersonnelActionsTapComponent,
    PdfPreviewResultadosComponent,
    //DropdownMultiSelectComponent,
    ValidateProfileComponent,
    PdfActionChangeEmployeeComponent,
    PdfActionChangeEmployeeLicenseComponent,
    PdfActionChangeEmployeeVacationsComponent,
    PdfActionChangeEmployeePermisosComponent,
    AnnualOperativePlanListComponent,
    PdfActionChangeEmployeeDesvincularComponent,
    VacanciesDashboardComponent,
    EmployeePersonnelActionsListComponent,
    UnlinkEmployeesTabComponent,
    requisitionDashboard,
    CandidateRegistrationDashboardComponent,
    DateFilterComponent,
    ApplyVacanciesDashboardComponent,
    EmployeeDashboardComponent,
    EmployeeDateFilter,
    DataValidationseparationsDetailModalComponent,
    InterviewDashboardComponent,
    InterviewDateFilter,
    ProofDashboardComponent,
    JobOfferDashboardComponent,
    JobOfferDateFilter,
    DashboardNoObjectionComponent,
    IncidentesDashboardComponent,
    IncidentDateFilter,
    PersonnelActionsDashboardComponent,
    JobOfferModalComponent,
    DeparmentsComponent,
    DeparmentsListComponent,
    //  ChartComponent,
    //  BarChartRaceComponent,
    //  SemiCircleComponent,
    //  StackedChartComponent,
    //  ChartsourceComponent,
    //  ChrtAreaRadarComponent,
    //  ChartDnutComponent,
    FingerPrintComponent,
    LoadingComponent,
    PdfEmployeeDataComponent,
    PositionsListComponent,
    ExternalServiceTapComponent,
    PdfActionChangeEmployeeExservicesComponent,
    AssistControlComponent,
    LicensesTabComponent,
    PdfEmployeeeExportComponent,
    EmployeePersoneelActDashboardComponent,
    PdfPersonnelActionsRegulatedComponent,
    EmployeesIncompleteComponent,
    EvindenceModalComponent,
    EditNoObjectionModalComponent,
    ExperienceComponent,
    KnowledgeComponent,
    IncompletedFilesComponent,
    ParametersSendConcursaComponent,
    ServiceCommissionComponent,
    SignApprovalProcessComponent,
    ConcursaStatusModalComponent,
    DeclarationModalComponent,
    ShowSwornDeclarationComponent,
    CreatePositionModalComponent,
    ReturnMotivesModalComponent,
    ViewReasonRejectComponent,
    PositionChangesComponent,
    BannsComponent,
    PositionsListDescriptionComponent,
    ModalCompetencyDictionarySearchComponent,
    CollectiveVacationsComponent,
    AddCollectiveVacationsComponent,
    CollectiveVacationPublicServicesModalComponent,
    PublicServantCollectiveVacationModalComponent,
    ContentManagementListComponent,
    AddContentModalComponent,
    PositionListDescriptionComponent,
    ViewBehaviorsComponent,
    ConsultCollectiveVacationsComponent,
    PdfPositionsDescriptionComponent,
    HourlyTeachersComponent,
    SocialMedialComponent,
    SocialMediaModalComponent,
    AddTeacherContractComponent,
    ViewContractsComponent,
    CollectiveActionsComponent,
    TelephoneDirectoryComponent,
    DepartmentTabComponent,
    EmployeesTabComponent,
    ResetPasswordModalComponent,
    DirectoryModalComponent,
    HistoryPersonalActionsComponent,
    EmployeeServiceRequestComponent,
    EmployeeResponseRequestComponent,
    EmployeeServiceRequestModalComponent,
    DashboardHourlyTeachersComponent,
    RejectPermissionModalComponent,
    RejectLicenseModalComponent,
    WizardBenefitsModalComponent,
    ReportListComponent,
    CertificationReportComponent,
    PayrollTransparencyComponent,
    SelectDepartmentModalComponent,
    employeeServiceRequestDetailModalFormComponent,
    ApplyVacancyModalComponent,
    SalaryRangeComponent,
    OccupationalGroupsReportComponent,
    EmployeeBirthdayReportComponent,
    AgeChildrenEmployeesReportComponent,
    DepartmentChangesComponent,
    UserGuideComponent,
    IndicatorsPayrollComparisonReportComponent,
    SeparationsComponent,
    VinculationsComponent,
    SeparationsDetailBulkLoadComponent,
    VinculationsDetailBulkLoadComponent,
    PauseVacationModalComponent,
    ShowFileVacationModalComponent,
    CancelPersonalActionModalComponent,
    InSubstitutionModalComponent,
    AppliedVacancyModalComponent,
    InstitutionRelativesComponent,
    AdvancedFilterByEmployeesComponent,
    DisciplinaryMeasuresComponent,
    ListOfFaultsModalComponent,
    FirstGradeComponent,
    SecondAndThirdGradeComponent,
    RecomendationsComponent,
    AddPhoneComponent,
    AddEmailComponent,
    AddDependentsComponent,
    FrequentQuestionsComponent,
    AdvancedFilterByPersonalRequisitionComponent,
    BirthdayComponent,
    AdvancedFilterByPersonnelActionsComponent,
    TeacherReviewsComponent,
    TeacherReviewsListComponent,
    TeacherReviewsModalComponent,
    MassiveChangeSupervisorComponent,
    AuditChangesRequisitionComponent,
    
    AuditChangesComponent,
    
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    SelectDropDownModule,
    MatDialogModule,
    MatSelectModule,
    ToastrModule.forRoot({
      timeOut: environment.toastTimeOut,
      closeButton: true,
      maxOpened: 3,
      toastClass:'d-print-none',
      progressBar:true,
      preventDuplicates:true,
      countDuplicates: true,
      resetTimeoutOnDuplicate:true,
      extendedTimeOut: environment.toastTimeOut
    }),
    MAPModule,
    payrollModule,
    CurrencyMaskModule,
    NgApexchartsModule,
    MatProgressBarModule,
    NgMultiSelectDropDownModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [],
        disallowedRoutes: []
      }
    })
  ],
  entryComponents: [
    RequisitionComponent,
    SkillsRequiredComponent,
    ConfirmationComponent,
    CandidateSearchResultComponent
  ],
  providers: [
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    DatePipe,
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'es' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
