import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Period } from '@payroll/schedule/models/period.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { OvertimeActivityService } from 'src/app/services/overtime-activity.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollReportService } from 'src/app/services/payroll-report.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { FilterEvent } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { dropdownGlobalConfig, openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-overtime-individual-report',
  templateUrl: './overtime-individual-report.component.html',
  styleUrls: ['./overtime-individual-report.component.css']
})
export class OvertimeIndividualReportComponent implements OnInit {
  periods: Period[] = [];
  employeesPagedResut = new PagedResult<any>();
  filteredPeriods: Period[] = [];
  yearsList: any[] = []
  payrollTypes: any[] = [];
  form: FormGroup;
  budgetAreaList: any[] = [];
  payrollVersions: any[] = [];
  selectedPayrollType: any;
  relatedPeriods = [];
  employees: any[] = [];
  dropdownConfigDescription = { ...dropdownGlobalConfig('monthName') };
  periodSelected;

  searchParameter!: FilterEvent;
  constructor(
    private parameterServices: ParameterControlService,
    private payrollReportService: PayrollReportService,
    private _toastService: ToastService,
    private fb: FormBuilder,
    public authInfo: AuthInfoService,
    private location: Location,
    private payrollService: PayrollService,
    private srvOvertimeActivity: OvertimeActivityService,
    private employeeService: EmployeesService
  ) {

  }

  ngOnInit(): void {
    this.getPeriods();
  }

  getPeriods(){
    this.srvOvertimeActivity.getOvertimeActivityPeriods(this.authInfo.getCompanyId()).subscribe({
      next: (res:any) => {
        if(res.errors.length>0){this._toastService.error(res.errors[0]);return;}
        this.periods = res.dataList

      }, error: (error:any) => {this._toastService.error("Ha ocurrido un error inesperado")}
    })
  }
  searchEmployees(){
    this.getEmployeeList()
  }
  getEmployeeList() {
    const year = this.periodSelected.year
    if(!year){return}
    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value ?? ''
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const month = this.periodSelected.month;
    const branchId: number = 1//this.branch?.companyId ?? 0
    this.employeeService.getEmployeesPaginatedAdvanced(branchId, year, page, pageSize, propKeyId, text, 1, 12, null,'', month).subscribe((res: any) => {
      if (!res.succeded) {

        res.errors.forEach(err => {
          this._toastService.error(err);
        })

        res.warnings.forEach(err => {
          this._toastService.warning(err);
        })

        return;
      }
      //this.employees = this.filterTable(res.singleData.items);
      this.employees = res.singleData.items;
      this.employeesPagedResut = res.singleData
    }, err => this._toastService.error('Ha ocurrido un error inesperado al obtener la lista de Servidores Públicos'))

  }
  goBack() {
    this.location.back();
  }
  showReport(item){
    const year = this.periodSelected.year
    if(!year){return}
    const { companyId, startDate, employeeId } = item
    const month = this.periodSelected.month
    const reportCode: ReportCode = ReportCode.PaymentVoucherOverTime;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&type=2&EmployeeId=${employeeId}&CompanyId=${companyId}&Year=${year}&Month=${month}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Horas Extras Individual',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }



  openReport(){
    if (!this.periodSelected?.monthName) {
      this._toastService.warning('Debe llenar todos los campos previamente');
      return;
    }

    
    const url = `${environment.reportUrl}?Reportcode=OvertimeReportPayment&Month=${this.periodSelected.month}&Year=${this.periodSelected.year}&CompanyId=${this.authInfo.getCompanyId()}&PropkeyId=0&SearchParameter=&Page=1&PageSize=10000`;
    openReport({
      url: url,
      title: 'Reporte de Horas Extras',
      width: '1024',
      height: '768'
    });
  }
  getPaginatedRecords(event: PaginationEvent) {
    if (this.employeesPagedResut.page == event.selectedPage && this.employeesPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.employeesPagedResut.page = event.selectedPage;
    this.employeesPagedResut.pageSize = event.registersPerPage;
    this.getEmployeeList()
  }

}
