import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Vacancies } from 'src/app/models/vacancies';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { VacanciesService } from 'src/app/services/vacancies.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { BenefitsComponent } from '../benefits/benefits/benefits.component';
import { CareersComponent } from '../careers/careers/careers.component';
import { SkillsComponent } from '../skills/skills/skills.component';
import { environment } from 'src/environments/environment';
import { docService } from 'src/app/services/doc.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Career, CareerDTO } from '../../models/careers.model';
import { Skills, SkillsDTO } from '../../models/skills.model';
import { Benefit } from '../../models/benefits';
import { ParentModel, VacancyModel, ViewGetVacantBenefit, ViewGetVacantsCareer, ViewGetVacantsSkill } from '../../models/post-vacancy.model';
import { element } from 'protractor';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };
@Component({
  selector: 'app-post-vacancies',
  templateUrl: './post-vacancies.component.html',
  styleUrls: ['./post-vacancies.component.css']
})
export class PostVacanciesComponent implements OnInit, OnDestroy {
  private readonly urlDoc = environment.api_doc + '/Document';
  public SystemLoad: number = 1;
  public ModuleLoad: number = 2;
  public routeFile: string = "";

  isLoading: boolean = true;
  vacancyCause: [] = []
  locations: [] = []
  vacancies: [] = []
  requisitions: [] = []
  genders: [] = []
  occupationalGrps: [] = []
  vacancyTypes: [] = []
  requisitionsType: [] = []
  academicLevels: [] = []
  hoursWorked: [] = []
  array1: any[] = []
  vacant: any;

  selectedCareers: CareerDTO[] = [];
  selectedSkills: SkillsDTO[] = [];
  selectedBenefits: Benefit[] = []
  url = ' /assets/images/archivo.png'
  subscriptions: Subscription = new Subscription();
  datePipe = new DatePipe('en-Us')
  vacanciesDTO: Vacancies = new Vacancies
  showAdmissionEndingContract = false

  vacanciesForm = new FormGroup({
    positionName: new FormControl(''),
    position: new FormControl({ value: '', disabled: true }, [Validators.required]),
    vacancyCause: new FormControl('', [Validators.required]),
    vacancyType: new FormControl('', [Validators.required]),
    enclousure: new FormControl('', [Validators.required]),
    plaza: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    department: new FormControl('', [Validators.required]),
    admissionDate: new FormControl('', [Validators.required]),
    startDate: new FormControl(''),
    requirements: new FormControl(''),
    experience: new FormControl(''),
    jobFunctions: new FormControl(''),
    maximumAge: new FormControl('', [Validators.required]),
    minimumAge: new FormControl('', [Validators.required]),
    sex: new FormControl('', [Validators.required]),
    hoursWorked: new FormControl('', [Validators.required]),
    yearsExperience: new FormControl(''),
    academicLevel: new FormControl('', [Validators.required]),
    requisitionType: new FormControl('', [Validators.required]),
    documentId: new FormControl(''),
    observations: new FormControl(''),
    desirableRequirements: new FormControl(''),
    contractAdmissionDate: new FormControl({ value: null, disabled: true }),
    contractEndingDate: new FormControl({ value: null, disabled: true })
  })

  inView = false

  enclousureConfig = {
    displayKey: 'socialReason',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  };
  status: boolean = true
  config = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  };

  VacancyTypesconfig = {
    //displayKey default 'description'
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  };
  positionsConfig = {
    displayKey: 'positionName',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  };

  @ViewChild('uppyFile')
  uppyFileComponent: UppyFileComponent | null = null;

  constructor(
    public vacanciesService: VacanciesService,
    private route: ActivatedRoute,
    private _toastService: ToastService,
    private _ParamService: ParameterControlService,
    private dialog: MatDialog,
    private router: Router,
    private _activeRoute: ActivatedRoute,
    private _doc: docService,
    private _sanitizer: DomSanitizer,
    private authInfo: AuthInfoService) { }


  ngOnInit(): void {
    const type = this.route.snapshot.params.action
    const status = this.route.snapshot.params.status
    if (type) {
      this.inView = true
      this.vacanciesForm.get('vacancyType').disable()
      this.status = status
    }
    this.getData().then((result) => {
      this.getById();
    }).catch((err) => {
    });

  }

  disableInputs(fields: string[]) {
    fields.forEach(field => {
      this.vacanciesForm.get(field).disable();
    })
  }

  transform(url) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  // getApprovedVacancy(id:number){
  //   this.vacanciesService.getApprovedVacancy(id).subscribe((response:VacancyModel)=>{

  //   })
  // }

  Image(e) {
    if (e.target.files[0]?.type == "application/pdf") {
      this.url = ` /assets/images/PDF.png`
      return
    }
    this.url = ` /assets/images/${e.target?.files[0]?.name}`
  }

  async getData() {
    let requestRT = this.vacanciesService.getRequisitionType().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.requisitionsType = res.dataList
      }
    }, err => this._toastService.error(err))

    this.subscriptions.add(requestRT);

    let requestL = this.vacanciesService.getLocations().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.locations = res.dataList
      }
    }, err => this._toastService.error(err))

    this.subscriptions.add(requestL);

    let requestV = this.vacanciesService.getVacants(0).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.vacancies = res.dataList
      }
    }, err => this._toastService.error(err))

    this.subscriptions.add(requestV);




    let requestG = this.vacanciesService.getGender().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.genders = res.dataList.sort((a: { ocode: number; }, b: { ocode: number; }) => a.ocode - b.ocode);
      }
    }, err => this._toastService.error(err))

    this.subscriptions.add(requestG);

    let requestGO = this.vacanciesService.getOccupationalGroup().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.occupationalGrps = res.dataList
      }
    }, err => this._toastService.error(err))

    this.subscriptions.add(requestGO);


    this._ParamService.getParameters(keyWord.VacancyType).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.vacancyTypes = res.dataList
      }
    }, err => this._toastService.error(err))

    /* this.vacanciesService.getVacancyType().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.vacancyTypes = res.dataList
      }
    }, err => this._toastService.error(err)) */


    let requestVC = this._ParamService.getParameters(keyWord.CauseRequisition).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.vacancyCause = res.dataList
      }
    }, err => this._toastService.error(err))

    this.subscriptions.add(requestVC);

    let requestWH = this._ParamService.getParameters(keyWord.workinghours).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.hoursWorked = res.dataList
      }
    }, err => this._toastService.error(err))

    this.subscriptions.add(requestWH);


    await this.vacanciesService.getAcademicLevels().then((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.academicLevels = res.dataList
      }
    }).catch((err) => {
      this._toastService.error(err)
    });

    return {
      succeded: true
    }
  }


  getById() {
    let sub = this.vacanciesService.getVacancy(this.route.snapshot.params.id, this.status).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (!res.succeded) {
        this._toastService.error("Error tratando de cargar la información, por favor intenta mas tarde")
        return
      }
      if (res.singleData == null) {
        this._toastService.error("No hay datos para cargar")
      }
      else {

        let { vacants } = res.singleData;
        this.vacant = res.singleData.vacants;

        const { requisitionTypeId } = res.singleData.vacants
        this.showAdmissionEndingContract = requisitionTypeId === 3

        this.vacanciesForm.setValue({
          contractAdmissionDate: vacants.contractAdmissionDate ? moment(vacants.contractAdmissionDate).format('yyyy-MM-DD') : null,
          contractEndingDate: vacants.contractEndingDate ? moment(vacants.contractEndingDate).format('yyyy-MM-DD') : null,
          positionName: vacants.positionName ?? '',
          position: this.vacancies.find((vc: any) => vc.positionId == vacants.positionId) ?? 0,
          vacancyCause: this.vacancyCause.find((vc: any) => vc.ocode == vacants.cause) ?? 0,
          vacancyType: this.vacancyTypes.find((vt: any) => vt.ocode == vacants.vacancyTypeId) ?? '',
          enclousure: this.locations.find((l: any) => l.companyId == vacants.enclousureId) ?? 0,
          description: vacants.vacant,
          plaza: vacants.vacantId,
          department: vacants.department,
          admissionDate: this.datePipe.transform(vacants.admissionDate, 'yyyy-MM-dd'),
          startDate: vacants.admissionDate ? moment(vacants.admissionDate).format('yyyy-MM-DD') : null,
          requirements: vacants.requirements,
          jobFunctions: vacants.jobFunctions,
          maximumAge: vacants.maximumAge,
          minimumAge: vacants.minimumAge,
          sex: vacants.sex.toString(),
          hoursWorked: this.hoursWorked.find((hw: any) => hw.ocode == vacants.hoursWorked) ?? 0,
          yearsExperience: vacants.yearsExperience ?? 0,
          experience: vacants.experience,
          academicLevel: this.academicLevels.find((al: any) => al.ocode == vacants.academicLevel) ?? 0,
          requisitionType: this.requisitionsType.find((rt: any) => rt.ocode == vacants.requesitiontTypeId),
          documentId: vacants.documentId,
          observations: vacants.observations,
          desirableRequirements: vacants.desirableRequirements
        });
        this.disableInputs([
          'enclousure', 'vacancyCause', 'hoursWorked',
          'requisitionType', 'academicLevel', 'observations', 'desirableRequirements', 'sex'
        ])

        if (res.singleData.viewGetVacantsCareers.length > 0) {

          res.singleData.viewGetVacantsCareers.forEach((element: ViewGetVacantsCareer) => {
            let { caeerId, companyName, status, companyId, careers } = element;
            this.selectedCareers.push(
              {
                careerId: caeerId,
                description: careers,
                companyId: companyId,
                companyName: companyName,
                status: status
              }
            );
          });
        }

        if (res.singleData.viewGetVacantsSkills.length > 0) {
          res.singleData.viewGetVacantsSkills.forEach((element: ViewGetVacantsSkill) => {
            let {
              vacantSkillsId,
              vacantId,
              vacant,
              skillsId,
              skills,
              typeLivelSkillsId,
              typeLivelSkills,
              levelSkillsId,
              levelSkill,
              companyName,
              status,
              typeSkill,
              companyId,
            } = element;

            let skillDTO: SkillsDTO | any = {
              skill: {
                vacantSkillsId: vacantSkillsId,
                vacantId: vacantId,
                skillsId: skillsId,
                description: skills,
                typeLivelSkillsId: typeLivelSkillsId,
                levelSkill: levelSkill,
                levelSkillId: levelSkillsId,
                companyId: companyId,
                status: status
              },
              skillLevel: {
                companyId: companyId,
                typeLevelSkillsId: typeLivelSkillsId,
                levelSkillsId: levelSkillsId,
                levelSkill: levelSkill,
                description: levelSkill,
                status: status,
                skillId: skillsId
              },
              typelevel: {
                companyId: companyId,
                typeLevelSkillsId: typeLivelSkillsId,
                levelSkillsId: levelSkillsId,
                levelSkill: levelSkill,
                description: typeSkill,
                status: status,
                skillId: skillsId
              }
            }

            this.selectedSkills.push(skillDTO);
          });
        }
        if (res.singleData.viewGetVacantBenefits.length > 0) {
          res.singleData.viewGetVacantBenefits.forEach((element: ViewGetVacantBenefit) => {
            let { vacantBenefitId, typeBenefitId, vacantId, status, companyId, benefit } = element;
            this.selectedBenefits.push(
              {
                vacantBenefitId: vacantBenefitId,
                typeBenefitId: typeBenefitId,
                vacantId: vacantId,
                companyId: companyId,
                description: benefit,
                status: status
              }
            );
          });
        }
        this.subscriptions.add(sub)
        this.vacanciesDTO = {
          ...res.singleData.vacants,
          description: res.singleData.vacants.vacant
        }
      }
    },
      err => this._toastService.error(err))
  }

  openCarreer() {
    this.dialog.open(CareersComponent, {
      width: '600px',
      data: {
        careers: []
      }
    })
      .afterClosed()
      .subscribe((result) => {
        if (result.careers.length > 0) {
          result.careers.forEach((element: CareerDTO) => {
            const item = this.selectedCareers.filter(x => x.careerId === element.careerId);
            if (item.length > 0) {
              this._toastService.warning('Alguna de la (s) carrera (s) ya ha sido agregada previamente. Favor verificar');
              return;
            } else {
              this.selectedCareers.push(element);
            }
          });
        }
      });
  }

  openSkill() {
    this.dialog.open(SkillsComponent, {
      width: '650px',
    })
      .afterClosed()
      .subscribe((result) => {
        //console.log(result);


        //console.log(result);

        if (result.data) {
          let { skill } = result.data;
          let { skillLevel } = result.data;
          //console.log(this.selectedSkills);

          const item = this.selectedSkills.filter(x => x.skill.skillsId === skill.skillsId && x.skillLevel.typeLevelSkillsId == skillLevel.typeLevelSkillsId);
          if (item.length > 0) {
            this._toastService.warning('Esta habilidad ya ha sido agregada previamente. Favor verificar');
            return;
          } else {
            this.selectedSkills.push(result.data)
          }
        }

      });
  }

  openBenefit() {
    const { companyId, vacantId } = this.vacant
    this.dialog.open(BenefitsComponent, {
      width: '600px',
      data: { benefits: [], companyId, vacantId }
    })
      .afterClosed()
      .subscribe((result) => {
        //console.log(result);
        const arrBenefit = result?.benefits

        if (arrBenefit) {
          arrBenefit.forEach(element => {
            this.selectedBenefits.push({
              companyId: this.vacant.companyId,
              status: true,
              typeBenefitId: element.ocode,
              vacantId: this.vacant.vacantId,
              description: element.stringData
            })
          });
        }
      });
  }


  updateVacants() {
    this.uppyFileComponent.handleFile((guid: GUID) => {
      this.vacanciesDTO = {
        ...this.vacanciesDTO,
        vacantId: Number(this.route.snapshot.params.id),
        description: this.vacanciesForm.value.description,
        vacancyTypeId: this.vacanciesForm.value.vacancyType.ocode,
        cause: this.vacant.cause,
        admissionDate: this.vacanciesForm.value.admissionDate,
        requirements: this.vacanciesForm.value.requirements,
        jobFunctions: this.vacanciesForm.value.jobFunctions,
        maximumAge: this.vacanciesForm.value.maximumAge,
        minimumAge: this.vacanciesForm.value.minimumAge,
        sex: this.vacant.sex,
        hoursWorked: this.vacant.hoursWorked,
        yearsExperience: this.vacanciesForm.value.yearsExperience,
        academicLevel: this.vacant.academicLevel,
        requesitiontTypeId: this.vacant.requesitiontTypeId,
        published: true,
        documentId: guid
      }
      this.vacanciesService.updateVacants(this.vacanciesDTO).subscribe((res: any) => {
        if (res.errors.length > 0) {
          this._toastService.error(res.errors[0])
          return;
        }
        if (res.succeded == true) {
          this.addCareers();
          this.addSkills();
          this.addBenefits();
          this.router.navigate(["/vacancies"])
          this._toastService.success("", "Vacante publicada con éxito")
          this.vacanciesService.vacantsBenefits = []
          this.vacanciesService.preferibleSkills = []
          this.vacanciesService.preferibleCareers = null
        }
      }, err => this._toastService.error('Ha ocurrido un error inesperado al habilitar la vacante, Consulte al soporte técnico del sistema'))
    });
  }

  addCareers() {
    let careersDTO: Career
    for (let c = 0; c < this.selectedCareers.length; c++) {
      const career = this.selectedCareers[c];
      careersDTO = {
        vacantId: Number(this.route.snapshot.params.id),
        caeerId: career.careerId,
        status: true,
        companyId: career.companyId
      }
      //console.log(careersDTO);
      //console.log(career);

      let sub = this.vacanciesService.createVacantCareer(careersDTO).subscribe(res => {
        //console.log(res);
      }, err => this._toastService.error(err))
      this.subscriptions.add(sub);
    }
  }

  addSkills() {
    let skillsDTO: Skills;
    //PENDIENTE A REFACTORIZAR
    for (let c = 0; c < this.selectedSkills.length; c++) {
      const { skill } = this.selectedSkills[c];
      const { skillLevel } = this.selectedSkills[c];
      //console.log(skill);
      skillsDTO = {
        vacantId: Number(this.route.snapshot.params.id),
        skillsId: skill.skillsId,
        typeLivelSkillsId: skillLevel.typeLevelSkillsId,
        levelSkills: skillLevel.levelSkillsId,
        companyId: skillLevel.companyId,
        status: true
      }

      let sub = this.vacanciesService.createVacantSkills(skillsDTO).subscribe(res => {
        //console.log(res);
      }, err => this._toastService.error(err))
      this.subscriptions.add(sub);
    }
  }

  addBenefits() {
    let sub = this.vacanciesService.createVacantBenefit(this.selectedBenefits).subscribe(res => {
      //console.log(res);
    }, err => this._toastService.error(err))

    this.subscriptions.add(sub);

  }

  Delete(name: string, id: number) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#ff0000',
      title: '¿Seguro que desea eliminar este beneficio?'
    }).then((result) => {
      if (result.isConfirmed) {
        switch (name) {
          case "benefits":
            this.selectedBenefits.splice(id, 1)
            // this.vacanciesService.vacantsBenefits = this.vacanciesService.vacantsBenefits.filter(b => b.idTypeBenefit != file.idTypeBenefit)
            break;
          case "careers":
            this.selectedCareers.splice(id, 1)
            // this.selectedCareers = this.selectedCareers.filter(b => b.careerId != file.careerId)
            break;
          case "skills":
            this.selectedSkills.splice(id, 1)
            // this.selectedSkills = this.selectedSkills.filter(b => b.skillsId != file.skill.skillsId);
            break;

          default:
            break;
        }
      }
    })

  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
