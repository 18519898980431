import { AuthInfoService } from './../../../../services/auth-info.service';
import { IEstadoArchivoNominaView, IPayrollSubmissionView, PayrollFileUpload, PayrollVerifyStatus } from './../models/payrollExpense';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ProcessPayrollService } from 'src/app/services/process-payroll.service';
import { ModalTextAreaComponent } from 'src/app/shared/modal-text-area/modal-text-area.component';
import { EmployeesPayrollComponent } from '../employees-payroll/employees-payroll.component';
import { ISendPayrollFilePOST, SendPayrollFileAdditional } from '../models/SendPayrollFile';
import { RejectFilesHaciendaComponent } from '../reject-files-hacienda/reject-files-hacienda.component';
@Component({
  selector: 'app-state-sigei',
  templateUrl: './state-sigei.component.html',
  styleUrls: ['./state-sigei.component.css']
})
export class StateSigeiComponent implements OnInit {

  headerId: number;
  records: IPayrollSubmissionView[] = []
  paginatedRecords: IPayrollSubmissionView[] = []
  statusUpdated: boolean = false
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<StateSigeiComponent>,
    private srvProcess: ProcessPayrollService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { headerId: number, payrollTypeId: number },
    private srvAuth: AuthInfoService
  ) { }

  ngOnInit(): void {
    this.headerId = this.data.headerId
    if (this.headerId) {
      this.getPayrollFiles()
    }
  }

  close(updated: boolean) {
    this.dialogRef.close(updated)
  }

  showReasonReject(item: IPayrollSubmissionView) {
    this.srvProcess.getVerifyStatusMonitoring(item?.responseCode).subscribe(res => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })
        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })
        return;
      }
      const matches = res?.singleData?.descripcionmonitoreo?.match(/\n/g);
      const breaks = matches ? matches.length : 2;
      this.dialog.open(ModalTextAreaComponent, {
        data: {
          text: res?.singleData?.descripcionmonitoreo,
          inputLabel: 'Razón de Rechazo',
          modalTitle: item.sentFileName,
          rows: breaks + 2
        },
        width: '40%'
      })
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado tratando de obtener la razón de rechazo');
    });

  }

  getPayrollFiles() {
    this.srvProcess.getPayrollFiles(this.headerId).subscribe((res: any) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })
        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })
        return;
      }
      res.dataList.forEach(x => {
        x.rejectBtn = 
          (x.status == "PENDIENTE") 
        ||(x.status == "VALIDADO") 
        ||(x.status == "CERTIFICADO")
      })
      this.records = res.dataList;
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado');
    });
  }

  openEmployeesPayroll(header): void {
    this.dialog.open(EmployeesPayrollComponent, {
      width: '100%',
      data: {
        headerId: header?.payrollHeaderId,
        employeeTypeId: header?.employeeTypeId ?? 0,
        newsConceptId: header?.newsConceptId ?? 0,
        payrollTypeId: header?.payrollTypeId,
        forwarded: header?.forwarded,
        status: header?.status
      }
    }).afterClosed().subscribe((result) => {

    });
  }

  send(item: IPayrollSubmissionView) {
    const payrollVerifyStatus: PayrollVerifyStatus = {
      code: item.responseCode,
      companyId: this.srvAuth.getCompanyId(),
      createUserId: this.srvAuth.getUserId(),
      payrollTypeId: item.payrollTypeId,
      newsConceptId: item.newsConceptId ?? 0,
      employeeTypeId: item?.employeeTypeId ?? 0,
      headerId: item.payrollHeaderId,
      submissionId: item.submissionId
    }
    this.srvProcess.getVerifyStatusFile(payrollVerifyStatus).subscribe((res) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        });
        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        });
        return;
      }
      this.getPayrollFiles();
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado tratando de obtener el estatus');
    })

    this.statusUpdated = true

  }

  resend(header) {
    if (this.data?.payrollTypeId === 4 || this.data?.payrollTypeId === 1) {
      this.resendPayrollAditionalFile(header);
    } else {
      this.resendPayrollFile(header);
    }
  }
  reject1(item){
    this.dialog.open(RejectFilesHaciendaComponent,{
      data: {item},
      width: '60%'
    }).afterClosed().subscribe({
      next: (res:any) => {
        if(res.success){
          this.getPayrollFiles()
        }
      }
    })
  }
  resendPayrollFile(header) {
    const send: ISendPayrollFilePOST = {
      companyId: this.srvAuth.getCompanyId(),
      employeeType: header.employeeTypeId,
      headerId: header.payrollHeaderId,
      userId: this.srvAuth.getUserId(),
      submissionId: header?.submissionId,
      employeeStatus: header.employeeStatus ?? true
    }
    this.srvProcess.send(send).subscribe((res: any) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })
        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })
        return;
      }
      let data: PayrollFileUpload = res.singleData
      if (data?.operacion?.toLowerCase() == "exitosa") {
        this.toastr.success("Proceso terminado exitosamente.");
      }
      this.getPayrollFiles();
    }, err => {
      this.toastr.error('Ha ocurrido un error tratando de reenviar el archivo de nómina')
    });
  }

  resendPayrollAditionalFile(header) {
    const send: SendPayrollFileAdditional = {
      companyId: this.srvAuth?.getCompanyId(),
      newsConceptId: header?.newsConceptId,
      headerId: this.headerId,
      userId: this.srvAuth.getUserId(),
      submissionId: header?.submissionId
    }
    this.srvProcess.sendPayrollFileAdditional(send).subscribe((res: any) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })
        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })
        return;
      }
      let data: PayrollFileUpload = res.singleData
      if (data?.operacion?.toLowerCase() == "exitosa") {
        this.toastr.success("Proceso terminado exitosamente.");
      }
      this.getPayrollFiles();
    }, err => {
      this.toastr.error('Ha ocurrido un error tratando de reenviar el archivo de nómina')
    });
  }

  getPaginatedRecords(event) {
    this.paginatedRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
}
