<div *ngIf="loading" class="d-flex justify-content-center flex-column align-items-center spinner-container">
    <el-spinner animation="spinner-logo"></el-spinner>  
    <span class="d-block font-size-h5 font-weight-bold">{{message}}</span>
  </div>
<div class="card card-custom p-0 border-0">
    <div class="card-header p-0"> 
        <div class="card-title v-pago">
            <el-search-bar placeholder="Buscar registro" ></el-search-bar> <!--(onFilter)="search($event)"-->
        </div> 
        <div class="card-toolbar">
            <div class="d-flex align-items-center">
                <strong class="text-primary font-size-h4">Seleccione una Unidad Organizativa</strong>
                <div class="dropdown card p-3 mx-3 text-center flex-1 border-primary min-w-200px">
                    <a class="dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="true" aria-expanded="false">
                        <strong class="font-size-h5">Todos</strong>
                    </a>
                    <div class="dropdown-menu dropdown-menu-bottom">
                        <a class="dropdown-item" href="javascript:void(0)">Unidad Organizativa de tecnología</a>
                        <a class="dropdown-item" href="javascript:void(0)">Unidad Organizativa 2</a>
                        <a class="dropdown-item" href="javascript:void(0)">Unidad Organizativa 3</a>
                        <a class="dropdown-item" href="javascript:void(0)">Unidad Organizativa 4</a>
                        <a class="dropdown-item" href="javascript:void(0)">Unidad Organizativa 5</a>
                    </div>
                </div>          
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
                <!-- <label class="font-size-h5 font-weight-bold label label-inline label-light-dark p-4 mb-2 d-none">Envío Masivo</label> -->
                <div class="d-flex justify-content-center align-items-center">
                    <a class="btn btn-sm btn-icon btn-light-success mr-3 icon-2x" (click)="sendMailMax()" >
                        <i class=" flaticon2-black-back-closed-envelope-shape icon-2x" data-toggle="tooltip" data-placement="top" title="Envío Masivo"></i>
                    </a>
                    <a (click)="printPDF()" class="btn btn-sm btn-icon btn-light-danger icon-2x">
                        <i class="flaticon-pdf-file icon-2x" data-toggle="tooltip" data-placement="top" title="Envío Masivo"></i>
                    </a>
                </div>
            </div>
        </div> 
    </div>
    <div class="card-body p-0 mt-3">
        <div class="table-responsive">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th>Unidad organizativa</th>
                        <th>ID Servidor Público</th>
                        <th>Servidor Público</th>
                        <th>Cédula</th>
                        <th class="actionsBtn">Volante Individual</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Solutions</td>
                        <td>2312</td>
                        <td>Omar Mendez</td>
                        <td>001-0005322-1</td>
                        <td class="actionsBtn">
                            <a  data-toggle="tooltip" data-placement="top" title="Enviar a Servidor Público" class="btn btn-light-success btn-icon btn-sm mr-3" (click)="sendMail()" ><i class="flaticon2-black-back-closed-envelope-shape icon-2x"></i></a>
                            <a  data-toggle="tooltip" data-placement="top" title="Ver/Imprimir a Servidor Público" class="btn btn-light-danger btn-icon btn-sm" (click)="printPDF()"  ><i class="flaticon-pdf-file"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer mt-3 d-flex justify-content-end align-items-center flex-wrap">
        <div class="d-flex align-items-center py-3 mr-2"><select
                class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light ng-untouched ng-pristine ng-valid"
                style="width: 75px;" ng-reflect-model="10">
                <option value="1" ng-reflect-value="1">1</option>
                <option value="5" ng-reflect-value="5">5</option>
                <option value="10" ng-reflect-value="10">10</option>
                <option value="15" ng-reflect-value="15">15</option>
                <option value="20" ng-reflect-value="20">20</option>
            </select><span class="text-muted">Mostrando página 1 de 1 </span></div>
        <div class="d-flex flex-wrap py-2 mr-3"><button
                class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled=""><i
                    class="ki ki-bold-arrow-back icon-xs"></i></button><button
                class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled=""><i
                    class="ki ki-bold-arrow-next icon-xs"></i></button></div>
    </div>
</div>