
<div class="row mt-3">
    <h4>Período</h4>
    <ngx-select-dropdown [config]="getDropdownConfig('description')" [options]="periodOptions" 
    (change)="periodDropdownChange($event.value)" class="flex-1"
    [(ngModel)]="periodSelected">
    </ngx-select-dropdown>
</div>
<ng-container *ngIf="showContent">
<div class="card audit-approval border-0">
    <div class="card-header p-3 gutter-b">
        <ul class="nav nav-primary nav-pills text-uppercase" id="myTab2" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="qty-tab-2" data-toggle="tab" href="#qty">
                    <span class="nav-icon mr-10">
                        <i class="flaticon-productividad icon-3x"></i>
                    </span>
                    <span class="nav-text font-size-h3">Por Cantidad</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="values-tab-2" data-toggle="tab" href="#values"
                    aria-controls="values">
                    <span class="nav-icon mr-10">
                        <i class="flaticon-agil icon-3x"></i>
                    </span>
                    <span class="nav-text font-size-h3">Por Valores</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="detail-tab-2" data-toggle="tab" href="#detail"
                    aria-controls="detail">
                    <span class="nav-icon mr-10">
                        <i class="flaticon-agil icon-3x"></i>
                    </span>
                    <span class="nav-text font-size-h3">Detalle</span>
                </a>
            </li>
        </ul>
    </div>
    <div class="card-body p-0">
        <div class="tab-content" id="myTabContent2">
            <div class="tab-pane fade active show" id="qty" role="tabpanel"
                aria-labelledby="qty-tab-2">
                <div class="row justify-content-between">
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{employeesCounted}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">Total Servidores Públicos</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card card-custom card-stretch gutter-b border">

                            <div class="card-header border-0 pt-5">
                                <div class="form-group col-xl-3 col-lg-6">
                                    <label>Tipo de Servidores Públicos</label>
                                    <ngx-select-dropdown [disabled]="disableEmployeeTypeInput" class="flex-1" [options]="employeeTypes"
                                        [config]="getDropdownConfig('stringData')" (change)="employeeTypeChange($event)"
                                        [(ngModel)]="selectedEmployeeType">
                                    </ngx-select-dropdown>
                                </div>

                            </div>


                            <div class="card-body pt-2 pb-0 mt-n3">
                                <div class="tab-content mt-5" id="myTabTables5">

                                    <div class="tab-pane fade active show" id="kt_tab_pane_5_1"
                                        role="tabpanel" aria-labelledby="kt_tab_pane_5_1">
                                        <div class="table-responsive" style="min-height: 600px;">
                                            <table class="table table-vertical-center">
                                                <thead>
                                                    <tr class="bg-primary">
                                                        <th class="min-w-150px">&Aacute;rea</th>
                                                        <th class="min-w-140px text-center">Cantidad de Servidores Públicos
                                                        </th>
                                                        <th class="min-w-110px text-center">Total Salario Neto
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let item of payrollDetailDepartmentsFilteredCopy">
                                                        <td class="text-left">
                                                            <span
                                                                class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{{item?.departmentName}}</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <span (click)="viewDetails(item)" class="label-lg p-1 px-2 rounded label-light-success cursor-pointer"><strong>{{item?.employeeCount}}</strong></span>
                                                        </td>
                                                        <td class="text-right">
                                                            <span
                                                                class="label label-lg pt-1 pb-1 label-light-success label-inline">{{item?.totalNetIncome
                                                                | currency}}</span>
                                                        </td>
                                                    </tr>
                                                    <!-- <tr>                                                        
                                              <td class="text-left">
                                                  <span class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Recursos Humanos</span>
                                              </td>
                                              <td class="text-center">
                                                  <span class="text-muted font-weight-500">15</span>
                                              </td>
                                              <td class="text-right">
                                                  <span class="label label-lg label-light-primary label-inline">$200,000</span>
                                              </td>
                                              <td class="text-right pr-0">
                                                  <a  class="btn btn-icon btn-light btn-sm" style="transform: rotate(90deg);">
                                                      <span class="svg-icon svg-icon-md svg-icon-success">
                                                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                  <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                                  <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                                              </g>
                                                          </svg>
                                                      </span>
                                                  </a>
                                              </td>
                                          </tr>
                                          <tr>                                                        
                                              <td class="text-left">
                                                  <span class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Académico</span>
                                              </td>
                                              <td class="text-center">
                                                  <span class="text-muted font-weight-500">50</span>
                                              </td>
                                              <td class="text-right">
                                                  <span class="label label-lg label-light-danger label-inline">$200,000</span>
                                              </td>
                                              <td class="text-right pr-0">
                                                  <a  class="btn btn-icon btn-light btn-sm" style="transform: rotate(270deg);">
                                                      <span class="svg-icon svg-icon-md svg-icon-danger">
                                                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                  <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                                  <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                                              </g>
                                                          </svg>
                                                      </span>
                                                  </a>
                                              </td>
                                          </tr>
                                          <tr>                                                        
                                              <td class="text-left">
                                                  <span class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Extensiones</span>
                                              </td>
                                              <td class="text-center">
                                                  <span class="text-muted font-weight-500">08</span>
                                              </td>
                                              <td class="text-right">
                                                  <span class="label label-lg label-light-primary label-inline">$200,000</span>
                                              </td>
                                              <td class="text-right pr-0">
                                                  <a  class="btn btn-icon btn-light btn-sm" style="transform: rotate(90deg);">
                                                      <span class="svg-icon svg-icon-md svg-icon-success">
                                                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                  <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                                  <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                                              </g>
                                                          </svg>
                                                      </span>
                                                  </a>
                                              </td>
                                          </tr> -->
                                                </tbody>
                                            </table>
                                            <div>

                                                <el-caption #paginator
                                                    [originalRecordsLength]="payrollDetailDepartmentsFiltered.length"
                                                    [listedRecordsLength]="payrollDetailDepartmentsFilteredCopy.length"
                                                    [registerQuantity]="payrollDetailDepartmentsFiltered.length"
                                                    [records]="payrollDetailDepartmentsFiltered"
                                                    (onChage)="getPaginatedRecordsForDepartments($event)">
                                                </el-caption>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="tab-pane fade" id="kt_tab_pane_5_2" role="tabpanel"
                                        aria-labelledby="kt_tab_pane_5_2">
                                        <div class="table-responsive">
                                            <table class="table table-vertical-center">
                                                <thead>
                                                    <tr class="bg-primary">
                                                        <th class="min-w-150px">&Aacute;rea</th>
                                                        <th class="min-w-140px text-center">Personas
                                                        </th>
                                                        <th class="min-w-110px text-center">Moto Salario
                                                        </th>
                                                        <th class="min-w-50px"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-left">
                                                            <span
                                                                class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Extensiones</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <span
                                                                class="text-muted font-weight-500">08</span>
                                                        </td>
                                                        <td class="text-right">
                                                            <span
                                                                class="label label-lg label-light-primary label-inline">$200,000</span>
                                                        </td>
                                                        <td class="text-right pr-0">
                                                            <a class="btn btn-icon btn-light btn-sm"
                                                                style="transform: rotate(90deg);">
                                                                <span
                                                                    class="svg-icon svg-icon-md svg-icon-success">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px" height="24px"
                                                                        viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" stroke-width="1"
                                                                            fill="none" fill-rule="evenodd">
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24">
                                                                            </polygon>
                                                                            <rect fill="#000000"
                                                                                opacity="0.3"
                                                                                transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                                                                                x="11" y="5" width="2"
                                                                                height="14" rx="1">
                                                                            </rect>
                                                                            <path
                                                                                d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                                                                            </path>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-left">
                                                            <span
                                                                class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Recursos
                                                                Humanos</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <span
                                                                class="text-muted font-weight-500">15</span>
                                                        </td>
                                                        <td class="text-right">
                                                            <span
                                                                class="label label-lg label-light-primary label-inline">$200,000</span>
                                                        </td>
                                                        <td class="text-right pr-0">
                                                            <a class="btn btn-icon btn-light btn-sm"
                                                                style="transform: rotate(90deg);">
                                                                <span
                                                                    class="svg-icon svg-icon-md svg-icon-success">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px" height="24px"
                                                                        viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" stroke-width="1"
                                                                            fill="none" fill-rule="evenodd">
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24">
                                                                            </polygon>
                                                                            <rect fill="#000000"
                                                                                opacity="0.3"
                                                                                transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                                                                                x="11" y="5" width="2"
                                                                                height="14" rx="1">
                                                                            </rect>
                                                                            <path
                                                                                d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                                                                            </path>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-left">
                                                            <span
                                                                class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Tecnología</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <span
                                                                class="text-muted font-weight-500">30</span>
                                                        </td>
                                                        <td class="text-right">
                                                            <span
                                                                class="label label-lg label-light-danger label-inline">$200,000</span>
                                                        </td>
                                                        <td class="text-right pr-0">
                                                            <a class="btn btn-icon btn-light btn-sm"
                                                                style="transform: rotate(270deg);">
                                                                <span
                                                                    class="svg-icon svg-icon-md svg-icon-danger">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px" height="24px"
                                                                        viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" stroke-width="1"
                                                                            fill="none" fill-rule="evenodd">
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24">
                                                                            </polygon>
                                                                            <rect fill="#000000"
                                                                                opacity="0.3"
                                                                                transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                                                                                x="11" y="5" width="2"
                                                                                height="14" rx="1">
                                                                            </rect>
                                                                            <path
                                                                                d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                                                                            </path>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-left">
                                                            <span
                                                                class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Académico</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <span
                                                                class="text-muted font-weight-500">50</span>
                                                        </td>
                                                        <td class="text-right">
                                                            <span
                                                                class="label label-lg label-light-danger label-inline">$200,000</span>
                                                        </td>
                                                        <td class="text-right pr-0">
                                                            <a class="btn btn-icon btn-light btn-sm"
                                                                style="transform: rotate(270deg);">
                                                                <span
                                                                    class="svg-icon svg-icon-md svg-icon-danger">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px" height="24px"
                                                                        viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" stroke-width="1"
                                                                            fill="none" fill-rule="evenodd">
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24">
                                                                            </polygon>
                                                                            <rect fill="#000000"
                                                                                opacity="0.3"
                                                                                transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                                                                                x="11" y="5" width="2"
                                                                                height="14" rx="1">
                                                                            </rect>
                                                                            <path
                                                                                d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)">
                                                                            </path>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="values" role="tabpanel" aria-labelledby="values-tab-2">
                <div class="row justify-content-between">
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume.totalEmployee}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block"> Servidor Público</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume.totalGrossSalary
                                    | currency}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">Salario
                                Bruto</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume.totalNetIncome
                                    | currency}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">Salario
                                Neto</a>
                        </div>
                    </div>

                </div>
                <div class="row justify-content-between">
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume.totalDiscount
                                    | currency}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">Descuentos</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume.totalTss|
                                    currency}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">TSS</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume.totalIsr
                                    | currency}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">ISR</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="detail" role="tabpanel" aria-labelledby="detail-tab-2">
                <div class="row justify-content-between">
                    <div class="table-responsive">
                        <table class="table table-vertical-center">
                            <thead>
                                <tr class="bg-primary">
                                    <th class="min-w-150px">Servidor Público</th>
                                    <th class="min-w-140px text-center">Genero</th>
                                    <th class="min-w-110px ">Cargo</th>
                                    <th class="min-w-110px ">Tipo</th>
                                    <th class="min-w-110px ">Ingreso Bruto</th>
                                    <th class="min-w-110px ">Ingreso Total</th>
                                    <th class="min-w-110px ">AFP</th>
                                    <th class="min-w-110px ">SFS</th>
                                    <th class="min-w-110px ">ISR</th>
                                    <th class="min-w-110px ">Otro Desc.</th>
                                    <th class="min-w-110px ">Total Desc.</th>
                                    <th class="min-w-110px ">Neto</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of payrollAllDetailsFiltered">
                                    <td class="text-left">
                                        <span
                                            class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{{item?.firstName}}
                                            {{item?.lastName}}</span>
                                    </td>
                                    <td class="text-center">
                                        <span
                                            class="text-muted font-weight-500"><strong>{{item?.sex}}</strong></span>
                                    </td>
                                    <td class="text-left">
                                        {{item?.positionName}}
                                    </td>
                                    <td class="text-left">
                                        {{item?.employeeType}}
                                    </td>
                                    <td class="text-left">
                                        {{item?.grossSalary | currency:'RD$'}}
                                    </td>
                                    <td class="text-left">{{item?.totalSalary | currency:"RD$"}}</td>
                                    <td class="text-left">
                                        {{item?.afp | currency:'RD$'}}
                                    </td>
                                    <td class="text-left">
                                        {{item?.sfs | currency:'RD$'}}
                                    </td>
                                    <td class="text-left">
                                        {{item?.isr | currency:'RD$'}}
                                    </td>
                                    <td class="text-left">
                                        {{item?.otherDiscounts | currency:'RD$'}}
                                    </td>
                                    <td class="text-left">
                                        {{item?.totalDiscount | currency:'RD$'}}
                                    </td>
                                    <td class="text-left">
                                        {{item?.netIncome | currency:'RD$'}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>

                            <el-caption #paginator [originalRecordsLength]="payrollAllDetails.length"
                                [listedRecordsLength]="payrollAllDetailsFiltered.length"
                                [registerQuantity]="payrollAllDetails.length" [records]="payrollAllDetails"
                                (onChage)="getPaginatedRecordsForDetails($event)"></el-caption>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</ng-container>