<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Pausar Vacaciones
    </h2>
    <button type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom " >
    <div class="p-5">
        <div class="row m-0">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Tipo de vacaciones </label>
                    <div>
                        <input type="text" class="form-control"
                            value="{{data?.vacationData?.vacationType}}" disabled>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Período al que Pertenecen </label>
                    <div>
                        <input type="text" class="form-control"
                            value="{{data?.vacationData?.year}}-{{data?.vacationData?.yearTo}}" disabled>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Fecha Inicio </label>
                    <div>
                        <input type="text" class="form-control"
                            value="{{data?.vacationData?.dateStart | date: 'dd/MM/yyyy'}}" disabled>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Fecha Término </label>
                    <div>
                        <input type="text" class="form-control"
                            value="{{data?.vacationData?.dateEnd | date: 'dd/MM/yyyy'}}" disabled>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Total de Días </label>
                    <div>
                        <input type="text" class="form-control"
                            value="{{data?.vacationData?.days | number}}" disabled>
                    </div>
                </div>
            </div>
           
        </div>
        <hr>
        <div class="row pb-3 m-0">
            <form [formGroup]="vacationForm" class="row ">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Nueva Fecha Término <span class="text-danger">*</span> </label>
                        <div>
                            <input type="date" class="form-control" formControlName="newDateEnd"  (change)="daysRequested();" [max]="maxDateEnd" [min]="minDateEnd">
                        </div>
                        <errors-component [controlName]="vacationForm.get('newDateEnd')">
                        </errors-component>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Días Correspondientes</label>
                        <div>
                            <input type="text" class="form-control"
                                value="{{vacationForm?.get('days')?.value | number}}" disabled>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-6 p-0">
                    <ng-template [ngIf]="noWorkingDayMatchWithDates.length > 0">
                        <div class="form-group col-md-12">
                            <label class="d-block w-100">
                                Fechas festivas detectadas
                            </label>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let item of noWorkingDayMatchWithDates">
                                    <span class="text-danger">
                                        <i class="favicon-dot"></i>
                                        Celebración: {{item.celebration}} se encuentra en la fecha: {{item.date |
                                        date:"dd/MM/yyyy"}}.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </ng-template>
        
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Comentario </label>
                            <div>
                                <textarea type="date" class="form-control" formControlName="comment" rows="9" ></textarea>
                            </div>
                            <errors-component [controlName]="vacationForm.get('comment')">
                            </errors-component>
                        </div>
                    </div>
        
                </div>
               
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Evidencia <span class="text-danger">*</span></label>
                        <div class="card align-items-center">
                            <div class="card-body">
                                <div
                                    [ngClass]="{'d-block': evidence.document=='', 'd-none' : evidence.document!=''}">
                                    <div class="vacationEvidence"></div>
                                </div>
                                <div *ngIf="evidence.document !=''">
                                    <div class="symbol symbol-150 d250 my-4 max-w-250px">
                                        <img alt="Pic" src="assets/images/cv.jpg" />
                                    </div>
                                    <div class="btn-group center" role="group">
                                        <button type="button" class="btn btn-primary btn-icon" 
                                            (click)="openEvidence(vacationForm?.get('evidence')?.value)">
                                            <i class="flaticon-ver"></i>
                                        </button>
                                        <button type="button" class="btn btn-danger btn-icon"
                                            (click)="deleteEvidence()"
                                            *ngIf="data.viewType != viewTypes.View ">
                                            <i class="flaticon-delete-1"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <errors-component [controlName]="vacationForm.get('evidence')">
                        </errors-component>
                    </div>
                </div>
    
                
    
               
            </form>
        </div>
    </div>
    <el-modal target="documentEvidence" title="Evidencia">
        <iframe [src]='transform(evidence.document)' width="100%" height="600px"></iframe>
    </el-modal>
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2"
        type="button" (click)="dialogRef.close(false)">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="data.viewType != viewTypes.View"
     class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
     (click)="pauseVacation()" [disabled]="vacationForm.invalid">
    <i class="flaticon-disco-flexible"></i>
    <span>Guardar</span>
</button>
</div>

