import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Documentsannex, Documentstosign, Metadata, SignRequest, TypeVerificationAccess } from 'src/app/models/sign-request.model';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import { DigitalSignService } from './digital-sign.service';
import { SendMapPdfSign } from '../pages/request-no-objection-map/models/send-map-pdf-sign';
export interface EmployeeChangeBase64 {
  employeeChangeDetailsId: number;
  companyName: string;
  employeeFirstName: string;
  employeeLastName: string;
  personalIdentification: string;
  positionName: string;
  salary: number;
  supervisor: string;
  admissionDate: string;
  departmentName: string;
  parameterName: string;
  employeeStatus: boolean;
  employeeBudgetArea: string;
  employeeType: string;
  changeType: number;
  effectiveDate: Date;
  valueBefore: string;
  valueAfter: string;
  valueAfterNumeric: number;
}
@Injectable({
  providedIn: 'root'
})
export class CreateSignRequestService {

  private readonly api_url = environment.api_url;

    constructor(
      private srvFirmaGob: DigitalSignService,
      private toastService: ToastrService,
      private http: HttpClient,
      private auth: AuthInfoService
    ) {

    }

    CreateSignRequest(
      metadatas: Metadata[],
      message,
      subject,
      documentsToSign: Documentstosign[],
      documentsAnnexes: Documentsannex[],
      userApprovals: any[],
      initDate,
      expirationDate,
      entityCode: string
    ) {
      const model = new SignRequest();
      model.verificationAccess = {
        type: TypeVerificationAccess.USERPASSWORD,
        username: environment.UsernameFirmaGob,
        password: environment.PasswordFirmaGob,
      }
      model.sender = {
        userCode: this.auth.getUser()?.identification,
        entityCode: entityCode
      };
      model.addresseeLines = userApprovals;
      model.internalNotification = this.getInternalNotification([...userApprovals])
      ?.filter((currentUser, index, users) =>
          index === users?.findIndex((user) => (
            user === currentUser
          ))
      );
      model.subject = subject;
      model.message = message;
      model.initDate = initDate;
      model.expirationDate = expirationDate;
      model.metadatas = metadatas;
      model.documentsToSign = documentsToSign;
      model.documentsAnnexes = documentsAnnexes;
      const modelResquest = {
        content: JSON.stringify(model),
        companyId: this.auth?.getCompanyId()
      }
      return this.srvFirmaGob.CreateSignRequest(modelResquest);
    }

    private getInternalNotification(userApprovals) {
        let internalNotification = userApprovals[0].addresseeGroups.map(user => {
            return user.userEntities.map(entities => {
                return entities.userCode
            })
        }).map(i => i[0]);
        internalNotification.push(this.auth.getUser()?.identification);
        return internalNotification;
    }

    getGetBase64Approval(changeDetails: EmployeeChangeBase64) {
        return this.http.post(`${this.api_url}/EmployeeChange/GetBase64-Approval`, changeDetails);
    }

    getGetBase64ApprovalNoObjection(sendMapPdfSign: SendMapPdfSign) {
      return this.http.post(`${this.api_url}/PersonsPostulationVacants/GetBase64-Approval`, sendMapPdfSign);
    }
    
}