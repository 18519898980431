<div class="row pt-5 gutter-b">
    <div class="col-sm-12 col-lg-4">
        <div class="form-group">
            <label>Tipo de Instrumento Legal</label>
            <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="dropdownSettings"
            [data]="statusOptions" [(ngModel)]="selectedItems">
            </ng-multiselect-dropdown> 
        </div>
    </div>
    <div class="col-sm-12 col-lg-4">
        <div class="form-group">
            <label>Número de Instrumento Legal </label>
            <input type="text" class="form-control form-control-solid form-control-lg">
        </div>
    </div>
    <div class="col-sm-12">
        <div class="form-group">
            <label>Norma Legal</label>
            <textarea class="form-control"  id="floatingTextarea" style="height: 150px;"></textarea>
        </div>
    </div>
</div>

<!--div class="mat-dialog-actions mt-2 p-0 mb-0 text-right" [align]="'end'">
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div-->