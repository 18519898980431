import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import { IAdditionalValue, IChangeDetails, IDetailPost, IHeaderPost, IPositionChangeDetailView, IPositionChangePost, IPositionView } from 'src/app/models/positions.model';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { docService } from 'src/app/services/doc.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PositionService } from 'src/app/services/position.service';
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { IGetDepartments } from '../home/home.models';
import { DepartmentService } from 'src/app/services/department.service';
import { Departments } from 'src/app/models/departments.model';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-position-changes',
  templateUrl: './position-changes.component.html',
  styleUrls: ['./position-changes.component.css']
})
export class PositionChangesComponent implements OnInit {

  positionSelected: IPositionView = {
    companyId: 0,
    shortName: '',
    positionId: 0,
    positionName: '',
    approvalStatus: false,
    requestedPositions: 0,
    approvalPositions: 0,
    approvalDoc: '',
    status: false,
    socialReason: '',
    occupationalGroup: 0,
    occupationalGroupDescription: '',
    countTest: 0,
    countInterviews: 0,
    minSalary: 0,
    halfSalary: 0,
    maxSalary: 0,
    isSupervisor: false,
    positionCode: '',
    condition: '',
    conditionId: 0,
    reasonReject: '',
    mapPositionId: 0,
    sendCode: ''
  }

  actionChangeForm = new FormGroup({
    currentData: new FormControl({ value: '', disabled: true }),
    currentDataId: new FormControl({ value: null, disabled: true }),
    newData: new FormControl({ value: '', disabled: true }, Validators.required),
    newDataId: new FormControl({ value: null, disabled: true }),
    additionalValueObject: new FormControl({ value: null, disabled: true }),
    additionalValue: new FormControl({ value: null, disabled: true }),
    additionalValueName: new FormControl({ value: null, disabled: true }),
    parameter: new FormControl({ value: null, disabled: true }, Validators.required,),
    effectiveDate: new FormControl({ value: null, disabled: true }, Validators.required),
    evidence: new FormControl({ value: null, disabled: true }, Validators.required),
    comment: new FormControl({ value: null, disabled: true }),
    conditionId: new FormControl([0]),
    positionChangeDetailsId: new FormControl([0]),
    positionChangeHeaderId: new FormControl([0]),
    dateApproved: new FormControl([null])
  }, { validators: this.ValidateNewData });


  fieldType: any = 'drop';
  additionalValueFieldName: string = '';

  config: any = {
    placeholder: 'Seleccione una opción'
  }

  additionalValueConfig: any = {
    displayKey: 'additionalValueName',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  }

  motivesConfig = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  }

  fieldsConfig = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  }

  fields: any[] = []
  options: any[] = []
  occupationalGroups: ParameterControl[] = [];
  departments: Departments[] = [];
  positionsConcursa: any[] = [];

  newChanges: IChangeDetails[] = [];
  newChangesPaginated: IChangeDetails[] = [];
  newChangesCopy: IChangeDetails[] = [];
  additionalValueList: IAdditionalValue[] = [];

  details: IPositionChangeDetailView[] = [];
  detailsPaginated: IPositionChangeDetailView[] = [];

  formIsInvalid: boolean = false;
  isSalaryCorrect: boolean = true;
  salaryError: string = '';

  guiID = '';
  file = { document: '' }

  @ViewChild('uppyFile')
  uppyFile: UppyFileComponent | null = null;
  constructor(private dialog: MatDialog,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private srvDoc: docService,
    private location: Location,
    private parameterCtrlService: ParameterControlService,
    private sanitizer: DomSanitizer,
    public authInfo: AuthInfoService,
    private positionService: PositionService,
    private route : Router) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      this.positionSelected.positionId = params.positionId;

      if (this.positionSelected.positionId > 0) {

        this.getPositionData();
      }

    })
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  goBack() {
    this.route.navigate(['positions-list'])

  }

  private getParameters() {
    this.parameterCtrlService.getParameters(keyWord.OccupationalGroup).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })

          return;
        }
        this.occupationalGroups = res.dataList;
      },
      error: (err) => {
      }
    })

    this.parameterCtrlService.getParameters(keyWord.PositionChangeType).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })
          return;
        }

        if (this.positionSelected.occupationalGroup == 1 || this.positionSelected.occupationalGroup == 2) {
          this.fields = res.dataList.filter(x => x.ocode != 4); //exclude concursa for occupational group 1 and 2
        } else {
          this.fields = res.dataList;
        }

      },
      error: (err) => {
      }
    })
  }

  private loadPositionsConcursa() {
    this.positionService.getConcursaPositions().subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })
          return;
        }


        this.positionsConcursa = res.dataList;
        this.options = this.positionsConcursa;

        this.actionChangeForm.get('currentData')?.setValue(res.dataList.find(x => x.id == this.positionSelected.concursaId)?.nombre);
      },
      error: err => {
        this.toastService.error("Ha ocurrido un error tratando de consultar la lista de cargos de Concursa")
      }
    })
  }

  private getPositionData(isReload: boolean = false) {
    this.positionService.getPositions(this.authInfo.getCompanyId(), this.positionSelected.positionId, true).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })
          return;
        }

        this.positionSelected = res.dataList[0];
        this.actionChangeForm.enable()
        //disable all if position is disabled
        if (this.positionSelected?.status == false) {
          this.actionChangeForm.disable();

          var buttons: any = document.getElementsByClassName('btn');
          for (let i = 0; i < buttons.length; i++) {
            buttons[i].disabled = true;
          }

          let inputs: any = document.getElementsByTagName("INPUT");
          for (let i = 0; i < inputs.length; i++) {
            inputs[i].disabled = true;
          }

          let areas: any = document.getElementsByTagName("TEXTAREA");
          for (let i = 0; i < areas.length; i++) {
            areas[i].disabled = true;
          }
        }

        //if is a reload, dont call this functions
        if (!isReload) {
          this.getParameters();
          this.getChangeDetails();
        }


      },
      error: (err) => {
        this.toastService.error("Ha ocurrido un error tratando de consultar el historial de cambios");
      }
    })
  }

  private getChangeDetails() {
    this.positionService.getChangeDetails(0, 0, this.positionSelected.positionId, this.positionSelected.companyId, 2).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })
          return;
        }

        this.details = res.dataList;
      },
      error: (err) => {
        this.toastService.error("Ha ocurrido un error tratando de consultar el historial de cambios");
      }
    })
  }

  fillCurrentData(parameter: number) {

    this.actionChangeForm.get('newData').setValue(null);
    this.actionChangeForm.get('additionalValue').setValue(null);
    this.actionChangeForm.get('additionalValueObject').setValue(null);
    this.getDropDownConfig(parameter);
    this.getDropDownOptions(parameter);


    this.additionalValueList = [];

    switch (parameter) {
      case 1: //Min Salary
        this.actionChangeForm.get('currentData').setValue(this.positionSelected?.minSalary)
        this.actionChangeForm.get('newData').setValue(this.positionSelected?.minSalary)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'number'
        break;
      case 2: //Max Salary
        this.actionChangeForm.get('currentData').setValue(this.positionSelected?.maxSalary)
        this.actionChangeForm.get('newData').setValue(this.positionSelected?.maxSalary)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'number'
        break;
      case 3: //Occupational Group
        this.actionChangeForm.get('currentData').setValue(this.positionSelected?.occupationalGroupDescription)
        this.actionChangeForm.get('currentDataId').setValue(this.positionSelected?.occupationalGroup)
        this.fieldType = 'drop'
        break;
      case 4: //Concursa
        this.actionChangeForm.get('currentData').setValue(null)
        this.actionChangeForm.get('currentDataId').setValue(this.positionSelected?.concursaId)
        this.fieldType = 'drop'
        this.positionsConcursa = [];
        this.loadPositionsConcursa();
        break;
      case 5: //Name
        this.actionChangeForm.get('currentData').setValue(this.positionSelected?.positionName)
        this.actionChangeForm.get('newData').setValue(this.positionSelected?.positionName)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'text'
        break;
      case 6: //Code
        this.actionChangeForm.get('currentData').setValue(this.positionSelected?.positionCode)
        this.actionChangeForm.get('newData').setValue(this.positionSelected?.positionCode)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'text'
        break;
      default:
        this.actionChangeForm.get('currentData').setValue(null)
        this.actionChangeForm.get('newData').setValue(null)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'text'
        break;
    }

    this.verifySalary();

  }

  fillNewData(value: any) {

    let valueType = this.actionChangeForm.get('parameter').value;

    switch (valueType.ocode) {
      case 1: //Min Salary
        this.actionChangeForm.get('newDataId').setValue(null)
        break;
      case 2: //Max Salary
        this.actionChangeForm.get('newDataId').setValue(null)
        break;
      case 3: //Occupational Group
        this.actionChangeForm.get('newDataId').setValue(value?.ocode)
        break;
      case 4: //Concursa
        this.actionChangeForm.get('newDataId').setValue(value?.id)
        break;
      case 5: //Name
        this.actionChangeForm.get('newDataId').setValue(null)
        break;
      case 6: //Code
        this.actionChangeForm.get('newDataId').setValue(null)
        break;
      default:
        break;
    }
  }

  getDropDownConfig(fieldCode: number) {
    switch (fieldCode) {
      case 3: //Occupational Group
        this.config = {
          displayKey: 'stringData',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      case 4: //Concursa
        this.config = {
          displayKey: 'nombre',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      default:
        break;
    }

    return this.config
  }

  getDropDownOptions(fieldCode: number) {
    this.options = []
    switch (fieldCode) {
      case 3: //Occupational Group
        this.options = this.occupationalGroups
        break;
      case 4: //Concursa
        this.options = this.positionsConcursa
        break;
      default:
        break;
    }
    return this.options
  }

  getNewDataValue(): string {
    let field = this.actionChangeForm.get('newData').value;

    let value: string = null;
    let valueType = this.actionChangeForm.get('parameter').value;
    if (field.stringData == undefined) {
      switch (valueType.ocode) {
        case 1: //Min sALARY
          value = field
          break;
        case 2: //Max Salary
          value = field
          break;
        case 3: //Occupational Group
          value = field.stringData
          break;
        case 4: //Concursa
          value = field.nombre
          break;
        case 5: //Nombre
          value = field
          break;
        case 6: //Codigo
          value = field
          break;
        default:
          if (!isNaN(field)) { /* comprobar cuando es un sueldo que se esta digitando */
            value = field.toString();
          } else {
            value = field; /* Si es una fecha */
          }
          break;
      }

    } else {
      value = field.stringData;

    }
    return value;
  }


  addChangeDetails() {
    this.formIsInvalid = this.actionChangeForm.invalid;
    if (this.formIsInvalid) {
      this.toastService.warning('Debe completar todos los campos requeridos')
      return;
    }

    if (this.fieldType == 'drop-additional' && !this.actionChangeForm.get('additionalValue').value) {
      this.toastService.warning('Debe completar todos los campos requeridos')
      return;
    }

    const form = this.actionChangeForm;

    const valueAfterNumeric = this.fieldType == 'number' ? (parseFloat(this.getNewDataValue()) ) : null;
    const valueAfter = this.getNewDataValue()?.toString();

    this.newChanges.push({
      positionChangeDetailsId: 0,
      positionChangeHeaderId: 0,
      parameterChangeId: form.get('parameter').value?.ocode,
      valueBeforeId: this.fieldType != 'number'  ? form.get('currentDataId').value : 0,
      valueBefore: this.fieldType == 'number' ? form.get('currentData').value?.toString() : form.get('currentData').value,
      valueAfterId: this.fieldType != 'number'  ? form.get('newDataId').value : 0,
      effectiveDate: form.get('effectiveDate')?.value,
      valueAfterNumeric: valueAfterNumeric,
      valueAfter: valueAfter,
      additionalValueName: form.get('additionalValueName')?.value,
      additionalValue: form.get('additionalValue').value,
      companyId: this.positionSelected.companyId,
      evidence: form.get('evidence').value,
      comment: form.get('comment').value,
      userId: this.authInfo.getUserId(),
      parameterName: form.get('parameter').value?.stringData,
      publicAccessId: ''
    })
    

    this.resetInputs()

  }

  remove(item: IChangeDetails, rowIndex) {
    this.newChanges.splice(rowIndex, 1);
  }

  async setChangeHeader() {

    if (this.newChanges.length == 0) {
      this.toastService.warning("No hay datos para guardar")
      return;
    }

    let header: IHeaderPost = {
      positionChangeHeaderId: 0,
      positionId: this.positionSelected.positionId,
      requestMapId: 0,
      userId: this.authInfo.getUserId(),
      companyId: this.positionSelected.companyId
    };

    let model: IPositionChangePost = {
      header: header,
      details: []
    };

    this.newChanges.forEach(detail => {
      model.details.push({
        positionChangeDetailsId: detail.positionChangeDetailsId,
        positionChangeHeaderId: detail.positionChangeHeaderId,
        parameterChangeId: detail.parameterChangeId,
        valueBeforeId: detail.valueBeforeId,
        valueBefore: detail.valueBefore,
        valueAfterId: detail.valueAfterId,
        valueAfterNumeric: detail.valueAfterNumeric,
        valueAfter: detail.valueAfter,
        additionalValueName: detail.additionalValueName,
        additionalValue: detail.additionalValue,
        comment: detail.comment,
        evidence: detail.evidence,
        publicAccessId: detail.publicAccessId,
        companyId: header.companyId,
        userId: header.userId,
        effectiveDate: detail.effectiveDate
      })
    });

    const promises = model?.details?.map(async detail => {
      if(this.uppyFile?.fileToDelete){
        this.uppyFile.removeFileDefinitely();
      }
      if(detail.evidence){
        const res = await this.uploadFile(detail?.evidence as unknown as FormData);
        const resData = res?.data as FileResponse;
        detail.evidence = resData?.fileUnit?.guidname;
      }
    });
    await Promise.all(promises);

    this.sendChanges(model)
  }

  uploadFile(formData: FormData) {
    let data = {
      SystemLoad: this.uppyFile.SystemLoad,
      ModuleLoad: this.uppyFile.ModuleLoad,
      personalFile: this.uppyFile.typePersonalFile,
      routeFile: this.uppyFile.routeFile
    }
    return this.srvDoc.createFile(data, formData).toPromise();
  }

  private sendChanges(model: IPositionChangePost) {

    this.positionService.sendPositionChanges(model).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          });

          res.warnings.forEach(warning => {
            this.toastService.warning(warning);
          });

          return;
        }

        this.toastService.success('Acciones enviadas exitosamente')


        this.newChanges = [];
        this.resetInputs();
        this.getChangeDetails();

        this.getPositionData(true);

      },
      error: (err) => {
        this.toastService.error("Ha ocurrido un error tratando de enviar los cambios");
      }
    })

  }


  ValidateNewData(data: AbstractControl) {
    if (data.get('newData').value != '') {
      let newData = data.get('newDataId').value ? data.get('newDataId').value : data.get('newData').value
      let currentData = data.get('currentDataId').value ? data.get('currentDataId').value : data.get('currentData').value
      if (newData == currentData) {
        return { invalidValue: true };
      }
    }
    return null;
  }

  verifySalary(): void {

    let valueType: ParameterControl = this.actionChangeForm.get('parameter').value;
    if (valueType.ocode != 1 && valueType.ocode != 2) {

      this.isSalaryCorrect = true;
      this.salaryError = null;

      return;
    }

    let maxSalary = this.positionSelected.maxSalary;
    let minSalary = this.positionSelected.minSalary;

    switch (valueType.ocode) {
      case 1:

        let newMinSalary = this.actionChangeForm.get('newData').value ? this.actionChangeForm.get('newData').value : 0;

        if (newMinSalary > maxSalary) {
          this.isSalaryCorrect = false;
          this.salaryError = 'El salario mínimo no puede ser mayor al salario máximo.'
        } else {
          this.isSalaryCorrect = true;
          this.salaryError = null;
        }

        break;

      case 2:

        let newMaxSalary = this.actionChangeForm.get('newData').value ? this.actionChangeForm.get('newData').value : 0;

        if (newMaxSalary < minSalary) {
          this.isSalaryCorrect = false;
          this.salaryError = 'El salario máximo no puede ser menor al salario mínimo.'
        } else {
          this.isSalaryCorrect = true;
          this.salaryError = null;
        }

        break;
      default:
        break;
    }


  }

  isDate(date): boolean {
    if (this.isNumeric(date)) {
      return false;
    }
    return (new Date(date).toString() !== "Invalid Date") && !isNaN(Number(new Date(date)));
  }

  isNumeric(value): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    return !isNaN(Number(value));
  }


  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openGuID(guid) {
    try {
      this.srvDoc.getDocument(guid).subscribe(e => {
        window.open(e.data, '_blank');
      }, error => {
        this.toastService.error(error)
      })
    } catch (error) {
      this.toastService.error(error)
    }
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  setGuID(guid) {
    try {
      this.srvDoc.getDocument(guid).subscribe(e => {
        this.file.document = e.data
        this.guiID = guid;
      }, error => {
        this.toastService.error(error)
      })
    } catch (error) {
      this.toastService.error(error)
    }
  }


  showParameterErrors() {
    let field = this.actionChangeForm.get('parameter');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showNewDataErrors() {
    let field = this.actionChangeForm.get('newData');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showEffectiveDateErrors() {
    let field = this.actionChangeForm.get('effectiveDate');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showEvidenceErrors() {
    let field = this.actionChangeForm.get('evidence');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showRejectionReason(reasonMessage: string) {
    Swal.fire({
      text: reasonMessage,
      icon: "info",
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Entendido'
    })
  }

  resetInputs() {
    this.actionChangeForm.get('parameter').setValue(null)
    this.actionChangeForm.get('currentData').setValue(null)
    this.actionChangeForm.get('currentDataId').setValue(null)
    this.actionChangeForm.get('newData').setValue(null)
    this.actionChangeForm.get('newDataId').setValue(null)
    this.actionChangeForm.get('comment').setValue(null)
    this.actionChangeForm.get('effectiveDate').setValue(null)
    this.file.document = '';
    this.guiID = null;
    this.uppyFile.cleanUppy();
    this.actionChangeForm.get('evidence').setValue(null)
    this.additionalValueList = [];
    this.actionChangeForm.get('additionalValue').setValue(null)
    this.actionChangeForm.get('additionalValueName').setValue(null)
    this.actionChangeForm.get('additionalValueObject').setValue(null)
    this.fieldType = this.fieldType == 'drop-additional' ? 'drop' : this.fieldType;
  }

  getPaginatedRecords(event) {
    this.detailsPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  getPaginatedUnsavedChanges(event) {
    this.newChangesPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  setEvidence(evidence){
    this.actionChangeForm.get('evidence').setValue(evidence);
  }

  viewPdf(formData: FormData): void {
    const pdfFile = formData.get('file');

    if (pdfFile && pdfFile instanceof Blob) {
      const pdfUrl = URL.createObjectURL(pdfFile);
      window.open(pdfUrl); 
    } 
  }

}
