import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { DropdownModel } from '../../interface/dropdown-model';
import { DynamicSelectOptionModel } from './models/dynamic-select-option-model';

@Component({
  selector: 'el-dynamic-select',
  templateUrl: './dynamic-select.component.html',
  styleUrls: ['./dynamic-select.component.scss'],
})
export class DynamicSelectComponent
  implements OnInit, AfterViewChecked, OnChanges {
  @Input() inputType: string;
  @Input() dropoption: Array<DropdownModel>;
  @Input() childrenIcon: string;
  @Input() Dynamicselect: boolean = true;
  @Input() dropdownText: string;
  @Input() data: Array<DynamicSelectOptionModel>;
  @Output() dataChange = new EventEmitter();
  dropDownItems: Array<DynamicSelectOptionModel> = [];
  selectedItems: Array<DynamicSelectOptionModel> = [];
  dropdownSettings: any;
  selectionSize: boolean;
  in = { message: 'true' };
  constructor(private changeRef: ChangeDetectorRef) {}

  onDataChange() {
    this.dataChange.emit(this.data);
  }

  ngAfterViewChecked(): void {
    this.changeRef.detectChanges();
  }

  ngOnChanges() {
    this.selectedItems = this.data?.map((item) => ({
      id: item.id,
      itemName: item.itemName,
      childrenIds: item.childrenIds,
    }));
  }
  isBeenUse() {
    return this.data?.length > 0 ? (this.selectionSize = false) : true;
  }
  isinputEmpty(id: number) {
    const item = this.data.find((item) => item.id === id);

    if (item.childrenIds[0] < 0 || item.childrenIds[0] == undefined) {
      return true;
    }
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      text: this.dropdownText,
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Desseleccionar todo',
      enableSearchFilter: true,
    };

    this.dropDownItems = this.dropoption.map((item) => ({
      id: Number(item.value),
      itemName: item.text,
      childrenIds: [],
    }));
  }

  getOptionsSelectedTotalHours(id: number) {
    const item = this.data.find((item) => item.id === id);
    return item.childrenIds[0]?.toString();
  }

  onChildrenCLick(value, id, childId) {
    const item = this.data.find((item) => item.id === id);
    const index = item.childrenIds.indexOf(childId);

    if (value) {
      if (index <= -1) {
        item.childrenIds.push(childId);
      }
    } else {
      if (index > -1) {
        item.childrenIds.splice(index, 1);
      }
    }

    this.onDataChange();
  }

  onItemSelect() {
    this.data = this.selectedItems.map((item) => ({
      id: item.id,
      itemName: item.itemName,
      childrenIds: item.childrenIds,
    }));
    this.data.length > 0 ? (this.selectionSize = false) : true;

    this.onDataChange();
  }

  OnItemDeSelect() {
    this.data = this.selectedItems.map((item) => ({
      id: item.id,
      itemName: item.itemName,
      childrenIds: item.childrenIds,
    }));

    this.onDataChange();
  }

  onSelectAll() {
    this.data = this.selectedItems.map((item) => ({
      id: item.id,
      itemName: item.itemName,
      childrenIds: item.childrenIds,
    }));
  }

  onDeSelectAll() {
    this.data = this.selectedItems.map((item) => ({
      id: item.id,
      itemName: item.itemName,
      childrenIds: item.childrenIds,
    }));
  }
}
