
export interface IPeriod {
    payrollPeriodId: number;
    periodId: number;
    description: string;
    sendLimitDate: Date;
    conditionId: number;
    status: boolean;
    companyId: number;
    createDate: Date;
    createUserId: number;
    modifyDate: Date;
    modifyUserId: number;
}
export interface IHeader {
    headerId: number
    payrollPeriodId: number
    payrollTypeId: number
    payrollType: string
    conditionId: number
    headerCondition: string
    companyId: number
    status: boolean
    createDate: Date
    createUserId: number
    modifyDate: Date
    modifyUserId: number
    versionId: number
    period: string
    budgetAreaId: number
    budgetArea: string
    code: string
    sendLimitDate: string
    countApprove: number
    payrollPeriodIdRelated: any
    startDate: string
    executingUnit: string
}

export interface IEmployeeType {
    employeeTypeId: number
    description: string
    ccp: string
    code: string
    comment: string
    csfs: any
    cafp: any
    csrl: any
    isTss: boolean
    isRoyalty: boolean
    status: boolean
    companyId: number
    createDate: Date
    createUserId: number
    modifyDate: Date
    modifyUserId: number
}
export interface IPeriodDetailsResume {
    headerId: number;
    totalGrossSalary: number;
    totalTss: number;
    totalIsr: number;
    totalDiscount: number;
    totalNetIncome: number;
    totalEmployee: number;
    companyId: number;
}
export interface IGetOverallSummary {
    headerId: number;
    payrollPeriodId: number;
    budgetAreaId: number;
    payrollTypeId: number;
    employeeId: number;
    firstName: string;
    lastName: string;
    sexId: number;
    sex: string;
    positionId: number;
    positionName: string;
    departmentId: number;
    departmentName: string;
    employeeTypeId: number;
    employeeType: string;
    admissionDate: Date;
    contractExpiration: Date;
    grossSalary: number;
    totalSalary: number;
    afp: number;
    isr: number;
    sfs: number;
    otherDiscounts: number;
    totalDiscount: number;
    netIncome: number;
    companyId: number;
    socialReason: string;
    status: boolean;
}
export interface IGetDetailByDeparments {
    headerId: number;
    departmentId: number;
    departmentName: string;
    totalNetIncome: number;
    employeeCount: number;
    companyId: number;
    typeEmployeeId: number;
}

export enum PeriodConditions{
    open = 1,
    closed = 0
}

export enum headerCondition{
    openToNews= 0,
    simulated=1,
    audited=2,
    approved=3,
    blocked=4,
    definitive=5,
    rejected = 6
  }


export interface IHeaderWithSettings {
    header: IHeader;
    payrollApproveSetting: IPayrollApproveSetting[];
    payrollApprove: IPayrollApprove[];
}
export interface IPayrollApproveSetting {
    payrollApproveSettingId: number;
    headerId: number;
    approvedLevel: number;
    status: boolean;
    companyId: number;
    createDate: Date;
    createUserId: number;
    modifyDate: Date;
    modifyUserId: number;
    disableButton?: boolean;
    disableAuditButton?: boolean;
}
export interface IPostHeaderApprove {
    payrollApproveId?: number;
    headerId: number;
    employeeAprovedId?: number;
    generatedToken: string;
    confirmationToken: string;
    approvedLevel?: number;
    approvedDate?: Date;
    status?: boolean;
    companyId?: number;
    createDate?: Date;
    createUserId?: number;
    modifyDate?: Date;
    modifyUserId?: number;
}
export interface IPostPayrollReject {
    payrollRejectId: number;
    headerId: number;
    employeeRejectId: number;
    reason: number;
    comment: string;
    rejectDate: Date;
    generatedToken: string;
    confirmationToken: string;
    status: boolean;
    companyId: number;
    createDate: Date;
    createUserId: number;
    modifyDate: Date;
    modifyUserId: number;
    reasonText: string;
}
export interface IPayrollApprove {
    payrollApproveId: number;
    headerId: number;
    employeeAprovedId: number;
    generatedToken: string;
    confirmationToken: string;
    approvedLevel: number;
    approvedDate: Date;
    status: boolean;
    companyId: number;
    createDate: Date;
    createUserId: number;
    modifyDate?: any;
    modifyUserId?: any;
}


export enum PayrollReportCode {
    Payroll_gral = 'payroll_gral',
    Individual_concept = 'individual_concept',
    Employee_concepts = 'employee_concepts',
    Payroll_compare = 'payroll_compare',
    Payroll_audit = 'payroll_audit',
    Partial_payroll = 'partial_payroll',
    Payroll_gral_multiple = 'payroll_gral_multiple',
    Payroll_audit_excel = 'payroll_audit_excel'
}