<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Notificaciones
    </h2>
    <button type="button" (click)="Cancel();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">

    <div class="table-responsive">
        <table class="table mt-2 table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th class="text-left">Evento</th>
                    <th class="text-left">Mensaje</th>
                    <th style="width: 80px;">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-left">Cambio en la estructura del departamento de Contabilidad</td>
                    <td class="text-left">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus consequuntur sapiente nihil consequatur vitae harum animi, delectus expedita? Quaerat dolorum reiciendis provident repellat pariatur at similique, animi unde quidem eligendi.</td>
                    <td class="text-left">
                        <button title="add" class="btn btn-icon btn-light-info btn-sm m-1" id="btnAdd">
                            <i class="flaticon-ver" data-toggle="tooltip" data-title="Ver" title="Ver"></i>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td class="text-left">Cambio en la estructura del departamento de Contabilidad</td>
                    <td class="text-left">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus consequuntur sapiente nihil consequatur vitae harum animi, delectus expedita? Quaerat dolorum reiciendis provident repellat pariatur at similique, animi unde quidem eligendi.</td>
                    <td class="text-left">
                        <button title="add" class="btn btn-icon btn-light-info btn-sm m-1" id="btnAdd">
                            <i class="flaticon-ver" data-toggle="tooltip" data-title="Ver" title="Ver"></i>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td class="text-left">Cambio en la estructura del departamento de Contabilidad</td>
                    <td class="text-left">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus consequuntur sapiente nihil consequatur vitae harum animi, delectus expedita? Quaerat dolorum reiciendis provident repellat pariatur at similique, animi unde quidem eligendi.</td>
                    <td class="text-left">
                        <button title="add" class="btn btn-icon btn-light-info btn-sm m-1" id="btnAdd">
                            <i class="flaticon-ver" data-toggle="tooltip" data-title="Ver" title="Ver"></i>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td class="text-left">Cambio en la estructura del departamento de Contabilidad</td>
                    <td class="text-left">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus consequuntur sapiente nihil consequatur vitae harum animi, delectus expedita? Quaerat dolorum reiciendis provident repellat pariatur at similique, animi unde quidem eligendi.</td>
                    <td class="text-left">
                        <button title="add" class="btn btn-icon btn-light-info btn-sm m-1" id="btnAdd">
                            <i class="flaticon-ver" data-toggle="tooltip" data-title="Ver" title="Ver"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button" (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>