
<div class="container-fluid py-3">
  <button  (click)="goBack()"  class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
      <i class="flaticon2-left-arrow-1"></i>
      <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
  </button>
</div>
<div class="container-fluid" *ngIf="!isTableLoading">
  <div id="pdfExp" class="card">
    <div class="card-header px-22">
      <div class="align-items-center d-flex flex-wrap justify-content-lg-between justify-content-md-center" id="header">
        <div class="logo px-2" >
          <img src="../../../../assets/media/logos/logo-Escudo-Dominicano.png"  alt="" class="max-w-120px" />
        </div>

        <div *ngIf="fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">
            {{fileModalConfig.formTitle}}
          </h1>
          <h2 class="text-danger">{{fileModalConfig.formSubTitle}}</h2>
        </div>
        <div *ngIf="!fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">
            PRESIDENCIA DE LA REPUBLICA
          </h1>
        </div>
        <div class="version p-4 d-flex justify-content-between align-items-center flex-column">
          <h4 class="text-right ">FO-RH-14</h4>
          <h4 class="text-right ">Versión 0</h4>
        </div>
       <!-- <div class="date p-4 d-flex flex-wrap justify-content-between align-items-center">
           <h4 class="text-right mr-3">Fecha de solicitud</h4>
          <ng-template [ngIf]="personalRequestView?.person">
            <h5 class="text-danger text-right">{{ personalRequestView?.person.dateRequestFormatted || '__-__-____' }}</h5>
          </ng-template> 
          &nbsp;
        </div>-->
      </div>
    </div>
    <div class="card-body">
      <div id="pdf">
        <div class="pdf-page pb-4">
          <div class="container-fluid row mt-3" id="personal-data">
            <div class="col-md-7">
                <div class="row">
                    <div class="col-12 col-sm-2 label-inline">
                        <label>Institución:</label>
                    </div>
                    <div class="col-12 col-sm-10 label-inline">
                        <span class="text-wrap ms-75px">INSTITUTO TECNOLOGICO DE LAS AMERICAS</span>
                    </div>
                </div>
            </div>
            <div class=" col-md-5">
                <div class="row" >
                    <div class="col-12 col-sm-10">
                        <div class="row" >
                            <div class="col-12 col-sm-5 label-inline">
                                <label class="w-100 text-nowrap">Instrumento Legal:</label>
                            </div>
                            <div class="col-12 col-sm-7 label-inline">
                                <span class="text-wrap ms-75px">ACCION DE PERSONAL</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-2">
                        
                        
                            <div class="row" >
                                <div class="col-12 col-sm-6 label-inline">
                                    <label>No.:</label>
                                </div>
                                <div class="col-12 col-sm-6 label-inline">
                                    <span class="text-wrap ms-75px">{{changeDetail.employeeChangeDetailsId}}</span>
                                </div>
                            </div>
                        
                    </div>
                </div>
            </div>
            
          </div>

          <div class="container-fluid row mt-3" id="contact-media">
            <h3 class="col-md-12 p-2 bg-secondary text-white text-center mt-3 d-block"
              style="background: #e3292e !important;">
              DATOS GENERALES
            </h3>
            <div class="col-md-12 row justify-content-center m-0 p-0 overflow-auto">
                <div class="pdf-list col-md-6">
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Nombre:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span>{{employee?.firstName || ""}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Apellidos:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span>{{employee?.lastName || ""}}</span>
                        </div>
                    </div>

                  <div class="row">
                    <div class="col-12 col-sm-5 label-inline">
                        <label class="w-100 text-nowrap">Organismo Designado:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span>{{employee?.departmentName || ""}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-5 label-inline">
                        <label class="w-100 text-nowrap">Desempeña funciones en:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span>{{employee?.departmentName || ""}}</span>
                    </div>
                </div>
                    
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label class="w-100 text-nowrap">Cargo que desempeña:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span>{{employee?.positionName || ""}}</span>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label class="w-100 text-nowrap">Superior Inmediato:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-75px">{{employee?.employeeSupervisor || "N/A"}}</span>
                        </div>
                      </div>
                    
                  </div>
                  <div class="pdf-list col-md-6">
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Cédula:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span *ngIf="!employee?.unemployment">{{employee?.personalIdentification || "" | mask: '000-0000000-0'}}</span>
                            <span *ngIf="employee?.typeIdentificationId != 1">{{employee?.personalIdentification || ""}}</span>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Tarjeta:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-75px">000000003</span>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label class="w-100 text-nowrap">Sueldo Base:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span>{{employee?.salary || "" | currency}}</span>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label class="w-100 text-nowrap">Fecha de Ingreso:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span>{{employee?.admissionDate || "" |date:'dd MMMM yyyy':'UTC':'es-US'}}</span>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label class="w-100 text-nowrap">Fecha desvinculación:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-75px" *ngIf="employee?.unemployment">{{employee?.receptionExitDate |date:'dd MMMM yyyy':'UTC':'es-US' || 'N/A'}}</span>
                        </div>
                    </div>
                  </div>
            </div>
          </div>

          <div class="container-fluid row mt-3" id="contact-media">
            <h3 class="col-md-12 p-2 bg-secondary text-white text-center mt-3 d-block"
              style="background: #e3292e !important;">
              NATURALEZA DE LA ACCIÓN
            </h3>
            <div class="col-md-12 row justify-content-center m-0 p-0 overflow-auto">
                <div class="pdf-list col-md-6">
                    <!-- <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Movtivo:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-75px">{{changeDetail.reason}}</span>
                        </div>
                    </div> -->
                        <div class="row">
                            <h4 class="col-md-12 p-2 d-block"
                            >
                            DESIGNACIÓN ACTUAL
                            </h4>
                        </div>
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label class="w-100 text-nowrap">{{changeDetail.parameterName || "N/A"}}:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-75px" *ngIf="!changeDetail?.valueAfterNumeric && !isDate(changeDetail.valueAfter)">{{changeDetail?.valueAfter || "N/A"}}</span>
                            <span class="text-wrap ms-75px" *ngIf="!changeDetail?.valueAfterNumeric && isDate(changeDetail.valueAfter)">{{changeDetail?.valueAfter || "N/A"}}</span>
                            <span class="text-wrap ms-75px" *ngIf="changeDetail?.valueAfterNumeric">{{changeDetail?.valueAfter || "N/A" | currency:'RD$'}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label class="w-100 text-nowrap">Programación:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-75px">{{employee.budgetArea}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label class="w-100 text-nowrap">Fecha de Aprobación</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-75px">{{changeDetail?.dateApproved  |date:'dd MMMM yyyy':'UTC':'es-US'}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label class="w-100 text-nowrap">Estatus:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-75px">{{employee.status ? "Activo" : "Desvinculado"}}</span>
                        </div>
                    </div>
                  </div>

                  <div class="pdf-list col-md-6">
                    <div class="row">
                        <h4 class="col-md-12 p-2 d-block">
                        DATO {{changeDetail.parameterName || "" | uppercase }} ANTERIOR
                        </h4>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>{{changeDetail.parameterName || "N/A"}}:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-75px" *ngIf="!changeDetail?.valueAfterNumeric && !isDate(changeDetail.valueBefore)">{{changeDetail?.valueBefore || "N/A"}}</span>
                            <span class="text-wrap ms-75px" *ngIf="!changeDetail?.valueAfterNumeric && isDate(changeDetail.valueBefore)">{{changeDetail?.valueBefore || "N/A"}}</span>
                            <span class="text-wrap ms-75px" *ngIf="changeDetail?.valueAfterNumeric">{{changeDetail?.valueBefore || "N/A" | currency:'RD$'}}</span>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label class="w-100 text-nowrap">Programación:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-75px">{{employee.budgetArea}}</span>
                        </div>
                    </div>
                  </div>
            </div>
          </div>

          <div class="container-fluid row mt-3" id="contact-media">
            <h3 class="col-md-12 p-2 bg-primary text-white text-center mt-3 d-block"S>
              MOTIVACIÓN DE ACCIÓN
            </h3>
            <div class="col-md-12 row justify-content-center m-0 p-0 overflow-auto">
                <div class="pdf-list col-md-12">
                    <div class="row my-5">
                        <div class="col-12 col-sm-12 label-inline">
                            <label>Observaciones:</label>
                        </div>
                        <div class="col-12 col-sm-12 label-inline">
                            <span class="text-wrap ms-75px">{{changeDetail.reason}}</span>
                        </div>
                    </div>
                    
                   
                  </div>
            </div>
          </div>

        <div class="row container-fluid">
            <h3 class="col-md-12 p-2 bg-primary text-white text-center mt-3 d-block"S>
                Firmas
              </h3>
            <div class="col-4 border">
                <h3 class="col-md-12 p-2  text-uppercase text-center text-center">
                    ELABORADO POR
                </h3>
                <div class="firma text-center mt-25"></div>
            </div>
            <div class="col-4 border">
                <h3 class="col-md-12 p-2  text-uppercase text-center text-center">
                    FIRMA SUPERIOR INMEDIATA
                </h3>
                <div class="firma text-center mt-25"></div>
                
            </div>
            <div class="col-8 border">
                <h3 class="col-md-12 p-2  text-uppercase text-center text-center ">
                    FIRMA RESPONSABLE INSTITUCIÓN
                </h3>
                <div class="firma text-center mt-25"></div>
             
            </div>
            <div class="col-4 border">
                <h3 class="col-md-12 p-2  text-uppercase text-center text-center">
                    FIRMA RESPONSABLE ÁREA
                </h3>
                <div class="firma text-center mt-25"></div>
             
            </div>
            <div class="col-4 border">
                <h3 class="col-md-12 p-2  text-uppercase text-center text-center">
                    FIRMA RESPONSABLE ÁREA
                </h3>
                <div class="firma text-center mt-25"></div>
             
            </div>
        </div>



<!-- 
          <div class="container-fluid row gutter-t d-">
            <h3 class="col-md-12 p-2 bg-secondary text-uppercase text-white text-center text-center">
                ELABORADO POR
            </h3>
            
            <div class="col-md-12 gutter-b gutter-t mt-5">
                <div class="firma text-center">
                    <span>_________________________________________</span>
                    <p class="font-weight-bold text-dark">
                        Firma
                    </p>
                </div>
            </div>
        </div>
        <div class="container-fluid row gutter-t">
            <h3 class="col-md-12 p-2 bg-primary text-uppercase text-white text-center text-center">
                FIRMA SUPERIOR INMEDIATA
            </h3>
            
            <div class="col-md-12 gutter-b gutter-t mt-5">
                <div class="firma text-center">
                    <span>_________________________________________</span>
                    <p class="font-weight-bold text-dark">
                        Firma
                    </p>
                </div>
            </div>
        </div>

        <div class="container-fluid row gutter-t">
            <h3 class="col-md-12 p-2 bg-primary text-uppercase text-white text-center text-center">
                FIRMA RESPONSABLE RH
            </h3>
            
            <div class="col-md-12 gutter-b gutter-t mt-5">
                <div class="firma text-center">
                    <span>_________________________________________</span>
                    <p class="font-weight-bold text-dark">
                        Firma
                    </p>
                </div>
            </div>
        </div>
        <div class="container-fluid row gutter-t">
            <h3 class="col-md-12 p-2 bg-secondary text-uppercase text-white text-center text-center">
                FIRMA RESPONSABLE INSTITUCIÓN
            </h3>
            <div class="col-md-12 gutter-b gutter-t mt-5">
                <div class="firma text-center">
                    <span>_________________________________________</span>
                    <p class="font-weight-bold text-dark">
                        Firma
                    </p>
                </div>
            </div>
        </div>
        <div class="container-fluid row gutter-t">
            <h3 class="col-md-12 p-2 bg-secondary text-uppercase text-white text-center text-center">
                FIRMA RESPONSABLE DE AREA
            </h3>
            <div class="col-md-12 gutter-b gutter-t mt-5">
                <div class="firma text-center">
                    <span>_________________________________________</span>
                    <p class="font-weight-bold text-dark">
                        Firma
                    </p>
                </div>
            </div>
        </div> -->

        </div>
      </div>
    </div>
    <div class="card-footer mt-4">
      
      <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
        <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-80px">
        <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
      </div>
    </div>
  </div>
</div>
<button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
  <i class="flaticon2-printer font-size-h1"></i>
</button>
<script>
  let print = () => {
    let pdf = document.getElementById("pdfExp");
    pdf.contentWindow.focus();
    pdf.contentWindow.print();
  };
</script>
<el-modal target="logoModal" size="md" [title]="'Vista de foto'">
  <el-embed *ngIf="fileModalConfig.src" [src]="fileModalConfig.src" [type]="fileModalConfig.type"
    [filename]="fileModalConfig.filename" [width]="fileModalConfig.width" [height]="fileModalConfig.height">
  </el-embed>
</el-modal>
<el-modal target="photo" size="md" [title]="'Vista de foto'">
  <div class="container justify-content-center" style="align-content: center;">
    <ng-template [ngIf]="photoUrl">
      <img [src]="photoUrl | safe" alt="image" style="max-width: 350px;">
    </ng-template>
  </div>
</el-modal>
