
<div data-card="true" class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-presupuesto icon-3x text-danger mr-3"></i>
                <h1 class="text-primary"> Conceptos de Nóminas </h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Dashboard">
        <!--     <button data-toggle="modal"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </button> -->
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-4 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Institución
                        </label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [options]="companies" [(ngModel)]="company" (change)="changeCompany()"
                                [config]="getDropdownConfig('companyName')" class="w-100"></ngx-select-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mr-2 px-0 pt-1">
                    <div class="form-group institution">

                        <label class="text-primary">
                            Estatus
                        </label>


                        <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                        [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                        (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                    </div>
                </div>

                <button data-toggle="modal" id="btnAdd"  *ngIf="privilege.addAction.value"
                    class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto" (click)="openModalAdd()">
                    <i class="flaticon-mas icon-2x" data-toggle="tooltip" data-original-title="Agregar nuevo Concepto"></i>
                    Agregar
                </button>

                <button data-toggle="modal" (click)="openBulkModal()" *ngIf="privilege.buldLoadAction.value"
                    class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto">
                    <i class="flaticon-recursos-humanos-6 icon-2x"></i> Carga Masiva
                </button>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12"  *ngIf="privilege.exportAction.value">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                        <i class="fa fa-print icon-2x text-primary mr-3"></i>
                        Visualizar Reporte
                      </a>
                      <a class="dropdown-item align-items-center"
                      href="assets/docs/plantilla-carga-masiva/Plantilla-conceptos-nomina.xlsx" download="Plantilla-conceptos-nomina.xlsx">
                          <i class="flaticon-excel icon-2x text-success mr-3"></i>
                          Plantilla Carga Masiva
                      </a>
                        <!-- <button class="dropdown-item align-items-center" (click)="exportToExcel()">
                            <i class="flaticon-excel icon-2x text-success mr-3"></i>
                            Excel
                        </button>

                        <a class="dropdown-item align-items-center" (click)="onBtnPrintClick()">
                            <i class="flaticon-pdf-file icon-2x mr-2 text-danger"></i>
                            PDF
                        </a> -->
                        <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                            PDF
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a>
                        <a class="dropdown-item align-items-center" [routerLink]="['/summary']">
                            <i class="flaticon-pdf-file icon-2x text-primary mr-3"></i>
                            Plantilla
                        </a> -->
                    </div>
                </div>

                <div class="col text-nowrap" *ngIf="privilege.searchAction.value">
                    <app-search-bar-pagination [namePageLocalStorage]="localStorageSearch"
                        (onFilter)="filterSearch($event)"
                        (onChangeDropDown)="filterSearch($event)"
                        [parameters]="searchBarDisplayNames"></app-search-bar-pagination>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th>Código</th>
                        <th>Concepto</th>
                        <th>Nombre Corto</th>
                        <th class="text-center">Orden de Ejecución</th>
                        <th>Tipo de Concepto</th>
                        <th>Tipo de Novedad</th>
                        <th>Beneficiario</th>
                        <th>CCP</th>
                        <th class="text-center">¿Aplica para TSS? </th>
                        <th class="text-center">¿Aplica para ISR? </th>
                        <th class="text-center">¿Funciona como Salario? </th>
                        <th class="text-center">¿Es un Débito?</th>
                        <th class="text-center">¿Puede ser Mayor al Salario? </th>
                        <th>Estatus</th>
                        <th class="actionsBtn" style="width: 100px;">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of concepts" data-row="0" class="datatable-row" style="left: 0px;">
                        <td class="text-center">{{item.newsConceptId}}</td>
                        <td>{{item.code}}</td>
                        <td>{{item.concept}}</td>
                        <td>{{item.shortName || 'N/A'}}</td>
                        <td class="text-center">{{item.executionOrder}}</td>
                        <td>{{item.conceptType}}</td>
                        <td>{{item.newsType}}</td>
                        <td>{{item.conceptBeneficiaryDesc}}</td>
                        <td>{{item.ccp}}</td>
                        <td class="text-center">
                          <span  *ngIf="item.isTss">Si</span>
                          <span  *ngIf="item.isTss == false">No</span>
                        </td>
                        <td class="text-center">
                          <span  *ngIf="item.isIsr">Si</span>
                          <span  *ngIf="item.isIsr == false">No</span>
                        </td>
                        <td class="text-center">
                          <span   *ngIf="item.isSalary">Si</span>
                          <span   *ngIf="item.isSalary == false">No</span>
                        </td>
                        <td class="text-center" >
                          <span *ngIf="item.isDebit">Si</span>
                          <span *ngIf="item.isDebit == false">No</span>
                        </td>
                        <td class="text-center" >
                          <span *ngIf="item.higherThanSalary">Si</span>
                          <span *ngIf="item.higherThanSalary == false">No</span>
                        </td>
                        <td>
                            <span class="text-success" *ngIf="item.status">Activo</span>
                            <span class="text-danger" *ngIf="item.status == false">Inactivo</span>
                        </td>
                        <td data-field="Actions" data-autohide-disabled="false" aria-label="null"
                            class="datatable-cell-left datatable-cell">
                            <div class="d-flex justify-content-center  px-5  " style="width: max-content">
                                <div class="justify-content-start  px-5">
                                <button *ngIf="privilege.seeAction.value" (click)="openModalAdd(item, true, false)" tooltip="Ver" placement="top" data-toggle="modal"
                                    class="btn btn-sm btn-light-skype btn-icon mr-2">
                                    <i class="flaticon-ver icon-2x" data-toggle="tooltip" data-title="Ver"></i>
                                </button>
                                <button *ngIf="privilege.editAction.value &&  item.status != false" (click)="openModalAdd(item, false, true)" tooltip="Editar" placement="top"
                                    class="btn btn-sm btn-light-warning btn-icon mr-2">
                                    <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                                </button>
                                <button *ngIf="item.status && privilege.deleteAction.value" type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                    data-title="Remover" (click)="deleteConcept(item)">
                                    <i class="flaticon-delete" data-toggle="tooltip"
                                        data-title="Inactivar "></i>
                                </button>
                                <button *ngIf="item.status == false && privilege.activate.value" type="button" class="btn btn-sm btn-light-success btn-icon mr-2"
                                    data-title="Activar" (click)="activateConcept(item)">
                                    <i class="flaticon-checked" data-toggle="tooltip"
                                        data-title="Activar "></i>
                                </button>
                                <button *ngIf="privilege.auditChanges.value" type="button" class="btn btn-sm btn-light-dark btn-icon mr-2"
                                    data-title="Auditar Cambios" (click)="openModalAuditChanges(item)">
                                    <i class="flaticon-lista-de-verificacion-2" data-toggle="tooltip"
                                        data-title="Auditar Cambios"></i>
                                </button>
                            <!--     <a  tooltip="Inactivar"
                                    placement="top" class="btn btn-sm btn-light-success btn-icon mr-2">
                                    <i class="flaticon-etiqueta icon-2x " data-toggle="tooltip"
                                        data-title="Aprobar"></i>
                                </a> -->
                                <!-- <a class="btn btn-icon btn-light-danger btn-sm m-1" (click)="openModalFormula()">
                                    <i class="las la-file-invoice-dollar  icon-2x" data-toggle="tooltip" title="Fórmulas"></i>
                                </a> -->
                           </div>
                           </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>

                <app-paginator [listedRecordsLength]="concepts?.length"
                [registerQuantity]="employeesPagedResut.totalItems"
                [registerPerPage]="employeesPagedResut.pageSize" [totalPages]="employeesPagedResut.totalPages"
                [selectedPage]="employeesPagedResut.page" (onChange)="getPaginatedRecords($event)">
            </app-paginator>
            </div>
        </div>
    </div>
</div>
<export-data-to-pdf [title]="'Conceptos de Nóminas'" id="dataToPrint">
    <ng-container thead>
        <th>Código</th>
        <th>Concepto</th>
        <th>Orden de Ejecución</th>
        <th>Tipo de Concepto</th>
        <th>Tipo de Novedad</th>
        <th>Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let item of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.concepts" data-row="0" class="datatable-row" style="left: 0px;">
            <td>{{item.code}}</td>
            <td>{{item.concept}}</td>
            <td>{{item.executionOrder}}</td>
            <td>{{item.conceptType}}</td>
            <td>{{item.newsType}}</td>
            <td>
                <span class="text-success" *ngIf="item.status">Activo</span>
                <span class="text-danger" *ngIf="item.status == false">Inactivo</span>
            </td>
        </tr>
    </ng-container>
</export-data-to-pdf>
