import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Period } from '@payroll/schedule/models/period.model';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { DeletePayrollNewComponent } from '../delete-payroll-new/delete-payroll-new.component';
import { EnumNewsType, NewsConcept, NewsType, PayrollNew, PostPayrollNewsBulkLoad, TypeBulkLoadPayrollNew } from '../models/PayrollNews.model';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { DatePipe, formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx'
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { NgxSelectDropdownComponent } from 'ngx-select-dropdown';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { getDOCurrencyFormat } from 'src/app/shared/utils/utility';
import * as moment from 'moment';
import { DataValidationPayrollNewsDetailModalComponent } from './data-validation-payroll-news-detail-modal/data-validation-payroll-news-detail-modal.component';

export enum NewType {
  Externo = 1,
  Adicional = 2,
  Litis = 3,
  CreditoFiscal = 4,
}

@Component({
  selector: 'bulk-load-news',
  templateUrl: './bulk-load-news.component.html',
  styleUrls: ['./bulk-load-news.component.css']
})
export class BulkLoadNewsComponent implements OnInit, AfterViewChecked {
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };

  dataUploaded: any[] = [];
  dataUploadedListOriginal: any[] = [];
  paginatedDataUploaded: any[] = [];

  newtyTypes: NewsType[] = [];
  concepts: NewsConcept[] = [];
  conceptsListOriginal: NewsConcept[] = [];
  ProcessButton: boolean = false;

  statusList = [
    {text: "Activo", value: true},
    {text: "Inactivo", value: false},
  ]
  employeeStatus = null;
  statusSetting = {
    displayKey: 'text',
    search: true,
    height: 'auto',
    placeholder: 'Seleccione',
    moreText: '...',
    noResultsFound: 'No se han encontrado registros',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'text'
  }

  periods: Period[] = [];
  disabledFields = false
  periodSelected: Period;
  newtyTypeSelected: NewsType;
  conceptSelected: NewsConcept;

  @ViewChild('paginator') paginator: CaptionComponent;

  validFileExtensions: string[] = ['.xls', '.xlsx'];

  fileUploaded: boolean = false;

  form: FormGroup;

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  statusOptions = [
    { item_id: 1, item_text: 'Listo para procesar' },
    { item_id: 2, item_text: 'No se encontró el Servidor Público' },
  ];

  selectedItems: any;

  filterText: string = '';

  succeed = false
  isFormValid = true
  startDate: Date;
  endDate: Date;
  alreadyHasData = false
  payrollNewsData: PostPayrollNewsBulkLoad[] = []
  itemIsRequired = false

  typeBulkLoadPayrollNew: TypeBulkLoadPayrollNew = TypeBulkLoadPayrollNew.BulkLoadNews;
  
  constructor(private _toastService: ToastService,
    public dialogRef: MatDialogRef<BulkLoadNewsComponent>,
    private _changeDet: ChangeDetectorRef, private dialog: MatDialog,
    private parameterControlService: ParameterControlService,
    private fb: FormBuilder,
    private authInfo: AuthInfoService,
    private payrollNewService: PayrollNewService,
    private _xlsxService: ExportToExcelService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dialogRef.disableClose = true;
    this.form = this.fb.group({
      file: ['']
    });
   
  }

  ngOnInit(): void {
    this.getDataValidation()
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  getNewTypeById(id) {
    return this.newtyTypes.find(n => n.newsTypeId === id).description;
  }

  getConceptById(id: number) {
    let concept = {...this.conceptsListOriginal?.find(n => n?.newsConceptId === id)};
    return concept?.description;
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.dataUploaded = this.filterTable(this.dataUploadedListOriginal)
    }
    this.paginator.selectedPage = 1;
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl?.find(x => x?.item_id === 1) && !sl?.find(x => x?.item_id === 2)) {
      list = list?.filter(x => !x?.details);
    }
    if (sl?.find(x => x?.item_id === 2) && !sl?.find(x => x?.item_id === 1)) {
      list = list?.filter(x => x?.details);
    }
    return list;
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  getPaginatedRecords(event) {
    this.paginatedDataUploaded = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  Cancel() {
    const row = { Applied: this.succeed }
    this.dialogRef.close(row);
  }

  Accept() {
    
    this.processAllDataPayrollNews()
  }


  processAllDataPayrollNews(){
    let status = this.employeeStatus?.value

    if(status !== false && status !== true){
      this._toastService.warning("Debe seleccionar el Estatus Servidor Público")
      return
    }
    if(this.itemIsRequired == true){
      this._toastService.warning("Hay errores de validaciones obligatorias.")
      return
    }
    const payload = {
      processedByUserId: this.authInfo.getUserId(),
      status: this.employeeStatus?.value
    }
    this.payrollNewService.processAllDataPayrollNews(payload).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          return
        }
        this.data.getPayrollNew()
        this.dataUploaded = []
        this.dataUploadedListOriginal = []
        this.ProcessButton = false
        this._toastService.success("Novedades procesadas exitosamente.")
      }, error: (error: any) => {

      }
    })
  } 

  postBulkLoadCooperative(payrollNews) {
    this.payrollNewService.postBulkLoadCooperative(payrollNews).subscribe((res: any) => {
      if (res.errors.length > 0) {
        if (res.errors.length === payrollNews.length) {
          Swal.fire({
            icon: 'error',
            title: 'Ocurrió un error insertando las novedades',
            html: `${res.errors.join('<br>')}`,
            width: '30%'
          })
          return;
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Algunas novedades se insertaron correctamente, excepto:',
            html: `${res.errors.join('<br>')}`,
            width: '30%'
          })
          const row = { Applied: false, newItem: true }
          this.fileUploaded = false;
          this.dialogRef.close(row);
          return;
        }
      }
      this._toastService.success("Las novedades se insertaron correctamente");
      const row = { Applied: false, newItem: true }
      this.fileUploaded = false;
      this.dialogRef.close(row);
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    });

  }

  getNewsType() {
    this.payrollNewService.getNewsType().subscribe((res: ResponseModel<any>) => {
      if (res.errors.length > 0) { 
        this._toastService.error("Ha ocurrido un error al obtener los tipos de novedades");
         return; 
      }
      this.newtyTypes = res.dataList.filter(e => e.status);
    }, err => this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk."))
  }

  getConcepts() {
    this.payrollNewService.getNewsConcept().subscribe((res: any) => {
      if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al obtener los conceptos"); return; }
      this.conceptsListOriginal = res.dataList?.filter(e => e?.status);
    }, err =>  this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk."))
  }

  onChangeNewType(value: NewsType) {
    this.conceptSelected = null;
    this.concepts = [];
    if (value?.newsTypeId !== undefined) {
      this.concepts = [...this.conceptsListOriginal.filter(c => c.newsTypeId === value.newsTypeId)];
      if(value?.newsTypeId === EnumNewsType?.FiscalCredit){
        this.changeTypeBulkLoad(TypeBulkLoadPayrollNew.BulkLoadAmortizableNews);
        return;
      }
      this.changeTypeBulkLoad();
      return;
    }
    this.changeTypeBulkLoad()
  }

  private changeTypeBulkLoad(type: TypeBulkLoadPayrollNew = TypeBulkLoadPayrollNew?.BulkLoadNews){
    this.typeBulkLoadPayrollNew = type;
  }
  dateIsValid(date){
    return moment(date).isValid()
  }

  onClick(){
    this.form.patchValue({
      file: '',
    })
  }
  setFieldsDisabled(){
    this.disabledFields = true
    this.form.get('file').disable()
  }

  fileUpload(event: any) {
    /* if(this.alreadyHasData == true){
      return
    } */
    //this.setFieldsDisabled()
    if (!this.validateFileExtension(event.target.files[0].name)) {
      this._toastService.error('El tipo de archivo cargado no es válido', 'Tipo de Archivo Inválido');
      this.onClick();
      return;
    }
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = (e: any) => {
      let binaryData = e.target.result;
      let workbook = XLSX.read(binaryData, { type: 'binary', cellDates: true, cellFormula: true  });
      let bulkLoad: PostPayrollNewsBulkLoad[] = [];
      workbook.SheetNames.forEach((s, i) => {
        if(i > 0){
          return
        }
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[s], { header: 0, raw: false, dateNF: 'yyyy-MM-dd', rawNumbers: true, defval: null, skipHidden: false});
        if (this.fileContentIsInvalid(data)) {
          this._toastService.error('El contenido del archivo cargado no es válido', 'Contenido Inválido');
          return;
        }
        //bulkLoad = this.typeBulkLoadPayrollNew === TypeBulkLoadPayrollNew.BulkLoadNews ? this.buildModel(data) : this.buildModelBulkLoadAmortizable(data);
        bulkLoad = this.buildModel(data)
        this.payrollNewsData = bulkLoad
        this.postPayrollNewsBulkLoad();
        
      });
    }

  }

  getDataValidation(){
    this.payrollNewService.getDataValidationPayrollNews().subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          return
        }
        res.dataList.forEach(element => {
          element.isRequiredText = element.isRequired ? 'Sí' : 'No'
        });
        
        this.itemIsRequired = res.dataList.find(x => x.isRequired == true) ? true : false
        this.dataUploaded = res.dataList;
        this.dataUploadedListOriginal = res.dataList;

        if((res.identity === 1 && this.dataUploaded.length === 0) || (!this.itemIsRequired && this.dataUploaded.length > 0)){
          this.ProcessButton = true;
        }
        else{
          this.ProcessButton = false;
        }

        
      }, error: (error: any) => {

      }
    })
  }

  openDetail(item){
    this.dialog.open(DataValidationPayrollNewsDetailModalComponent, {
      data: {item}
    })
  }

  

  postPayrollNewsBulkLoad(){
    this.payrollNewService.postPayrollNewsBulkLoad(this.payrollNewsData).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this._toastService.error(res.errors[0])
          return
        }
        res.dataList.forEach(element => {
          element.isRequiredText = element.isRequired ? 'Sí' : 'No'
        });
        this.itemIsRequired = res.dataList.find(x => x.isRequired == true) ? true : false

        this.dataUploaded = res.dataList
        this.dataUploadedListOriginal = res.dataList

        if((res.identity === 1 && this.dataUploaded.length === 0) || (!this.itemIsRequired && this.dataUploaded.length > 0)){
          this.ProcessButton = true;
        }
        else{
          this.ProcessButton = false;
        }

      }, error: (error: any) =>{
        this._toastService.error("Ha ocurrido un error inesperado")
      }
    })
  }

  private getBulkLoad(bulkLoad){
    this.payrollNewService.getBulkLoad(bulkLoad).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0],'');
        return;
      }
      
      const payrollNews = res.dataList.map(f => {
        let payrollNew = {
          ...f,
          periodId: 0,
          payrollNewsId: 0,
          newsTypeId: this.newtyTypeSelected?.newsTypeId,
          newsConceptId: this.conceptSelected?.newsConceptId,
          createUserId: this.authInfo?.getUserId(),
          conceptById: this.getConceptById(this.conceptSelected?.newsConceptId),
          amount: (f.amount),
          amountFormatted: getDOCurrencyFormat(f.amount),
          manualOrBalance: (this.typeBulkLoadPayrollNew === 0 ? (f?.manualValue) : (f?.balance)),
          manualOrBalanceFormatted: (this.typeBulkLoadPayrollNew === 0 ? getDOCurrencyFormat(f?.manualValue) : getDOCurrencyFormat(f?.balance)),
          defaultOrTotal: (this.typeBulkLoadPayrollNew === 0 ? (f?.defaultValue) : (f?.totalAmount)),
          defaultOrTotalFormatted: (this.typeBulkLoadPayrollNew === 0 ? getDOCurrencyFormat(f?.defaultValue) : getDOCurrencyFormat(f?.totalAmount)),
          statusText: f.details? f.details : 'Listo para procesar'
        }
        return payrollNew;
      });

      this.dataUploaded = [...payrollNews];
      this.dataUploadedListOriginal = [...payrollNews];
      this.fileUploaded = true;
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese   HelpDesk.")
    });
  }

  private buildModel(data: any[]): PostPayrollNewsBulkLoad[]{
    return data.map((d: any) => {
      return {
        totalAmount: d['MontoTotal(0.00)'] && d['MontoTotal(0.00)'] != 0 ? Number(d['MontoTotal(0.00)']) : 0,
        balance: d['Saldo(0.00)'] && d['Saldo(0.00)'] != 0 ? Number(d['Saldo(0.00)']) : 0,
        conceptCode: d?.CodigoConcepto ? d?.CodigoConcepto : '',
        employeeCategory: d?.Categoria ? d?.Categoria : '',
        name: d?.NombreServidorPublico ? d?.NombreServidorPublico : '',
        observation: d?.Observacion ? d?.Observacion : '',
        personalIdentification: d?.Identificacion ? d?.Identificacion : '',
        amount: d['Monto(0.00)'] && d['Monto(0.00)'] != 0 ? Number(d['Monto(0.00)']) : 0,
        defaultValue: d.ValorPredefinido ? d.ValorPredefinido : '',
        manualValue: d['ValorManual(0.00)'] && d['ValorManual(0.00)'] != 0 ? Number(d['ValorManual(0.00)']) : 0,
        startDate: d['FechaInicio(yyyy-mm-dd)'] ? moment(d['FechaInicio(yyyy-mm-dd)']).startOf('date').format() : null,
        endDate: d['FechaFinal(yyyy-mm-dd)'] ? moment(d['FechaFinal(yyyy-mm-dd)']).startOf('date').format() : null,

        /* companyId: this.authInfo?.getCompanyId(),
        startDate: formatDate(this.startDate, 'yyyy-MM-dd', 'en-Us'),
        endDate: this.endDate ? formatDate(this.endDate, 'yyyy-MM-dd', 'en-Us') : null,
        conceptId: this.conceptSelected?.newsConceptId,
        status: this.employeeStatus?.value ?? null */
      }
    });
  }

  private buildModelBulkLoadAmortizable(data: any[]){
    /* return data.map((d: any) => {
      return {
        startDate: formatDate(this.startDate, 'yyyy-MM-dd', 'en-Us'),
        endDate: this.endDate ? formatDate(this.endDate, 'yyyy-MM-dd', 'en-Us') : null,
        identificationNumber: d['Identificacion']?.toString()?.replaceAll('-','')?.trim(),
        concept: d?.Concepto?.toString(),
        conceptId: this.conceptSelected?.newsConceptId,
        amount: d?.Monto,
        totalAmount: d['Monto Total'],
        balance: d['Balance'], 
        companyId: this.authInfo?.getCompanyId(),
        status: this.employeeStatus?.value ?? null
      }
    }) as any[]; */
  }

  validateFileExtension(fileName: string) {
    const fileArray = fileName.split('.');
    const extension = fileArray[fileArray.length - 1];
    return this.validFileExtensions.some(v => v.includes(extension));
  }

  get isValidData() {
    return this.dataUploadedListOriginal?.every(d => !d.details);
  }

  get errorDate(){
    if(this.startDate && this.endDate){
      return formatDate(this.startDate, 'yyyy-MM-dd', 'en-US') < formatDate(this.endDate, 'yyyy-MM-dd', 'en')
      ? '' : 'La Fecha Inicio no puede ser mayor que la Fecha Final';
    }
    return '';
  }

  /* isFormValid() {
    let isValid = (this.startDate && this.conceptSelected?.newsConceptId && this.newtyTypeSelected?.newsTypeId) ? true : false;
    if(isValid && this.startDate && this.endDate){
      isValid = formatDate(this.startDate, 'yyyy-MM-dd', 'en-US') < formatDate(this.endDate, 'yyyy-MM-dd', 'en');
    }
    if(!isValid) this.form.disable();
    else  this.form.enable();
    return isValid;
  } */

  private fileContentIsInvalid(data: any[]) {  
    if(this.typeBulkLoadPayrollNew === TypeBulkLoadPayrollNew.BulkLoadAmortizableNews){
      return data.every(d => d?.Identificacion && d?.Concepto && d['Monto(0.00)'] && d['MontoTotal(0.00)'] && d['Saldo(0.00)'] && d?.Categoria && d?.CodigoConcepto && d['FechaFinal(yyyy-mm-dd)']);
    }
    let isInvalid = false
    let dateIsInvalid = false
    data.forEach(x => {
      const endDate = x['FechaFinal(yyyy-mm-dd)']
      const startDate = x['FechaInicio(yyyy-mm-dd)']
      if(isInvalid == true){
        return
      }
      if(!x?.Identificacion || !x?.CodigoConcepto || !x?.Categoria || !x['FechaInicio(yyyy-mm-dd)'] || !x?.NombreServidorPublico){
        isInvalid = true
        return
      }
      if(dateIsInvalid == false){
        if(endDate){
          if(!this.dateIsValid(endDate)){
            dateIsInvalid = true
            isInvalid = true
          }
        }
        if(startDate){
          if(!this.dateIsValid(startDate)){
            dateIsInvalid = true
            isInvalid = true
          }
        }
      }
    })
    if(dateIsInvalid){
      this._toastService.warning("Hay campos de fecha sin formato de fecha.")
    }
    return isInvalid
  }

  remove(data: any){
    this.dataUploadedListOriginal = this.dataUploadedListOriginal?.filter(d => d?.identificationNumber !== data?.identificationNumber);
    this.dataUploaded = this.dataUploadedListOriginal;
    this.filter();
  }
  export(){
    let data = this.paginatedDataUploaded
    if(!data) {
      this._toastService.warning('No hay registros')
      return
    }
    if(data.length < 1) {
      this._toastService.warning('No hay registros')
      return
    }
    let excelHeaders: string[][] = [[
      "Descripción",
      "Comentario",
      "Cantidad de Errores",
      "¿Es requerido?"
    ], [
      "description",
      "comment",
      "quantity",
      "isRequiredText"
    ]]
    this._xlsxService.exportToExcelSpecificColumns(data, excelHeaders, 'Carga masiva novedades', true);
  }
  
}