<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <h2 class=" text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Indicadores</h2>
  <button (click)="dialogRef.close()" type="button" class="close" aria-label="Close">
    <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
  </button>
</div>
<ng-container *ngIf="loading">
  <h3>Cargando información...</h3>
</ng-container>
<ng-container *ngIf="!loading">
  <div class="container mt-2">
    <div class="fs-5 bg-primary text-white p-3 mb-3 rounded d-flex justify-content-around align-items-center">
      <span><b>Tipo Nómina: </b>{{payrollSelected.payrollType}}</span><span><b>Área Presupuestaria:
        </b>{{payrollSelected.budgetArea}}</span>
    </div>
    <ul class="nav nav-tabs border-tabs-primary" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="cambio-empleado-tab" data-toggle="tab" href="#current-payroll">
          <span class="nav-icon mr-5">
            <i class="flaticon-proceso icon-3x"></i>
          </span>
          <span class="nav-text">
            <h3>Nómina Actual</h3>
          </span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="acciones-personal-tab" data-toggle="tab" href="#previous-payroll"
          aria-controls="acciones-personal">
          <span class="nav-icon mr-5">
            <i class="flaticon-lista-de-verificacion icon-3x"></i>
          </span>
          <span class="nav-text">
            <h3>Nóminas Anteriores</h3>
          </span>
        </a>
      </li>

    </ul>

    <div class="row tab-content">
        <div class="tab-pane fade show active" id="current-payroll" role="tabpanel" aria-labelledby="current-payroll">
            <app-current-payroll-tab [item]="payrollSelected" [employeeTypes]="employeeTypes"></app-current-payroll-tab>
        </div>

        <div class="tab-pane fade" id="previous-payroll" role="tabpanel" aria-labelledby="previous-payroll">
            <div class="card">
                <div class="card-header">
                    <div class="card-toolbar d-flex justify-content-start row" [formGroup]="statisticsForm">
                        <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group mx-2 institution">
                                <label class="text-primary">
                                    Período <span class="text-danger text-monospace legend">*</span>
                                </label>
                                <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="dropdownSettings"
                                    [data]="periods" (onSelect)="onPeriodSelect($event)" (onDeSelect)="onDeselectPeriod($event)"
                                    (onDeSelectAll)="onDeselectAllPeriods()" (onSelectAll)="onPeriodSelectAll($event)">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group mx-2 institution">
                                <label class="text-primary">
                                    Área Presupuestaria <span class="text-danger text-monospace legend">*</span>
                                </label>
                                <ngx-select-dropdown formControlName="area" [options]="budgetAreas"
                                    [config]="dropdownConfig('description','Seleccionar')">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group mx-2 institution">
                                <label class="text-primary">
                                    Tipo de Nómina <span class="text-danger text-monospace legend">*</span>
                                </label>
                                <ngx-select-dropdown formControlName="type" [options]="payrollTypes"
                                    [config]="dropdownConfig('stringData', 'Seleccionar')">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <button [disabled]="statisticsForm.invalid" (click)="GetDetailPayrollAudit()"
                                class="btn btn-primary btn-pill font-weight-bolder mt-6 mx-2 col-md-auto">
                                <i class="flaticon2-search-1 icon-2x"></i> Buscar
                            </button>
                        </div>
                    </div>

                    <ul class="nav nav-primary nav-pills text-uppercase" id="myTab2" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="nempleado-tab-2" data-toggle="tab" href="#porCantidad">
                                <span class="nav-icon mr-10">
                                    <i class="flaticon-productividad icon-3x"></i>
                                </span>
                                <span class="nav-text font-size-h3">Por Cantidad</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="inomina-tab-2" data-toggle="tab" href="#porValores" aria-controls="porValores">
                                <span class="nav-icon mr-10">
                                    <i class="flaticon-agil icon-3x"></i>
                                </span>
                                <span class="nav-text font-size-h3">Por Valores</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="inomina-tab-2" data-toggle="tab" href="#porDetalle" aria-controls="porDetalle">
                                <span class="nav-icon mr-10">
                                    <i class="flaticon-agil icon-3x"></i>
                                </span>
                                <span class="nav-text font-size-h3">Detalle</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="myTabContent2">
                        <div class="tab-pane fade active show" id="porCantidad" role="tabpanel" aria-labelledby="nempleado-tab-2">
                            <div class="table-responsive mt-5">
                                <table class="table table-hover table-vertical-center">
                                    <thead>
                                        <tr class="bg-primary">
                                            <th class="text-center">Secuencia</th>
                                            <th>Unidad Organizativa</th>
                                            <th class="text-center" *ngFor="let period of departmentsPeriods">
                                            {{period.period}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of viewGetDetailByDeparmentsRecords">
                                            <td class="text-center">{{item.departmentId}}</td>
                                            <td class="">{{item.departmentName}}</td>
                                            <td class="text-center" *ngFor="let count of item.employeeAmount">
                                            {{count}}</td>
                                            <!--td class="text-center">{{item.totalNetIncome}}</td-->
                                        </tr>
                                    </tbody>
                                </table>

                                <div>
                                    <el-caption #paginator [originalRecordsLength]="viewGetDetailByDeparmentsOriginalList.length"
                                    [listedRecordsLength]="viewGetDetailByDeparmentsRecords.length"
                                    [registerQuantity]="viewGetDetailByDeparments?.length" [records]="viewGetDetailByDeparments"
                                    (onChage)="getPaginatedRecordsDeparments($event)"></el-caption>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="porValores" role="tabpanel" aria-labelledby="inomina-tab-2">
                            <div class="table-responsive mt-5" *ngIf="viewGetDetailResumes.length > 0">
                                <table class="table table-hover table-vertical-center">
                                    <thead>
                                        <tr class="bg-primary">
                                            <th class="text-left">Concepto</th>
                                            <th class="text-center" *ngFor="let item of viewGetDetailResumes">
                                                {{item?.period}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-left">Salario Bruto</td>
                                            <td class="text-center" *ngFor="let item  of viewGetDetailResumesOriginalList">
                                            {{item.totalGrossSalary | currency: 'RD$'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">AFP</td>
                                            <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                            {{item.totalAfp
                                            | currency: 'RD$'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">SFS</td>
                                            <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                            {{item.totalSfs
                                            | currency: 'RD$'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">TSS</td>
                                            <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                            {{item.totalTss
                                            | currency: 'RD$'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">ISR</td>
                                            <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                            {{item.totalIsr
                                            | currency: 'RD$'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">SFS Empleador</td>
                                            <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                            {{item.totalSfse
                                            | currency: 'RD$'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">AFP Empleador</td>
                                            <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                            {{item.totalAfpe
                                            | currency: 'RD$'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">SRL(Riesgo Laboral)</td>
                                            <td class="text-center" *ngFor="let item  of viewGetDetailResumesOriginalList">
                                            {{item.totalSrl
                                            | currency: 'RD$'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">Otros Decuentos</td>
                                            <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                            {{item.totalOtherDebits | currency: 'RD$'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">Otros Ingresos</td>
                                            <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                            {{item.totalOtherCredits | currency: 'RD$'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">Descuento Total</td>
                                            <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                            {{item.totalDiscount | currency: 'RD$'}}</td>
                                        </tr>
                                    </tbody>
                                <tfoot>
                                <tr>
                                <td class="text-left fw-bold">Total Neto</td>
                                <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                {{item.totalNetIncome | currency: 'RD$'}}</td>
                                </tr>
                                </tfoot>
                                </table>

                            </div>
                        </div>
                        <div class="tab-pane fade" id="porDetalle" role="tabpanel" aria-labelledby="inomina-tab-2">
                            <div class="d-flex justify-content-end">
                                <el-search-bar #searchBar (onKeyChange)="onKeyChange($event)" [keyValue]="filterText"
                                [records]="overallSummariesOriginalList" [itemForSearching]="searchBarDisplayNames"
                                (onChange)="getFilteredList($event);">
                                </el-search-bar>
                            </div>
                            <div class="table-responsive ">
                                <table class="table table-hover table-vertical-center">
                                    <thead>
                                        <tr class="bg-primary">
                                            <th>Servidor Público</th>
                                            <th>Sexo</th>
                                            <th class="">Cargo</th>
                                            <th class="">Unidad Organizativa</th>
                                            <th class="">Tipo</th>
                                            <th class="text-right">Ingreso Bruto</th>
                                            <th class="text-right">Otros ingresos</th>
                                            <th class="text-right">Ingreso total</th>
                                            <th class="text-right">AFP</th>
                                            <th class="text-right">SFS</th>
                                            <th class="text-right">ISR</th>
                                            <th class="text-right">Otro Desc.</th>
                                            <th class="text-right">Total Desc.</th>
                                            <th class="text-right">Neto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let overallSumaries of viewGetOverallSummariesRecords">
                                            <tr>
                                                <th class="text-left bg-light-secondary" colspan="14" >
                                                    <span class="text-dark text-uppercase ">
                                                        {{overallSumaries.departmentName}}
                                                    </span>
                                                </th>
                                            </tr>
                                            <ng-container  *ngFor="let department of overallSumaries.departments">
                                                <th class="text-left bg-light-secondary" colspan="14" >
                                                    <span class="text-dark text-uppercase ">
                                                        {{department?.payrollPeriodName}}
                                                    </span>
                                                </th>
                                                <tr *ngFor="let periods of department.payrollPeriods">
                                                    <td>{{periods.fullName}}</td>
                                                    <td class="text-center">{{periods?.sex}}</td>
                                                    <td class="">{{periods?.positionName}}</td>
                                                    <td class="">{{periods?.departmentName}}</td>
                                                    <td class="">{{periods?.employeeType}}</td>
                                                    <td class="text-right">{{periods?.grossSalary | currency: 'RD$'}}</td>
                                                    <td class="text-right">{{periods?.otherCredits | currency: 'RD$'}}</td>
                                                    <td class="text-right">{{periods?.totalSalary | currency: 'RD$'}}</td>
                                                    <td class="text-right">{{periods?.afp | currency: 'RD$'}}</td>
                                                    <td class="text-right">{{periods?.sfs | currency: 'RD$'}}</td>
                                                    <td class="text-right">{{periods?.isr | currency: 'RD$'}}</td>
                                                    <td class="text-right">{{periods?.otherDiscounts | currency: 'RD$'}}</td>
                                                    <td class="text-right">{{periods?.totalDiscount | currency: 'RD$'}}</td>
                                                    <td class="text-right">{{periods?.netIncome | currency: 'RD$'}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left fw-bolder w-100">Sub totales - {{department?.payrollPeriodName}}:</td>
                                                    <td class="text-left"></td>
                                                    <td class="text-left"></td>
                                                    <td class="text-left"></td>
                                                    <td class="text-left"></td>
                                                    <td class="text-right fw-bold">{{department.grossSalary | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{department.otherCredits | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{department.totalSalary | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{department.afp | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{department.sfs | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{department.isr | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{department.otherDiscounts | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{department.totalDiscount | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{department.netIncome | currency: 'RD$'}}</td>
                                                </tr>
                                            </ng-container>
                                            <tr>
                                                <td class="text-left fw-bolder">Sub totales:</td>
                                                <td class="text-left"></td>
                                                <td class="text-left"></td>
                                                <td class="text-left"></td>
                                                <td class="text-left"></td>
                                                <td class="text-right fw-bold">{{overallSumaries.grossSalary | currency: 'RD$'}}</td>
                                                <td class="text-right fw-bold">{{overallSumaries.otherCredits | currency: 'RD$'}}</td>
                                                <td class="text-right fw-bold">{{overallSumaries.totalSalary | currency: 'RD$'}}</td>
                                                <td class="text-right fw-bold">{{overallSumaries.afp | currency: 'RD$'}}</td>
                                                <td class="text-right fw-bold">{{overallSumaries.sfs | currency: 'RD$'}}</td>
                                                <td class="text-right fw-bold">{{overallSumaries.isr | currency: 'RD$'}}</td>
                                                <td class="text-right fw-bold">{{overallSumaries.otherDiscounts | currency: 'RD$'}}</td>
                                                <td class="text-right fw-bold">{{overallSumaries.totalDiscount | currency: 'RD$'}}</td>
                                                <td class="text-right fw-bold">{{overallSumaries.netIncome | currency: 'RD$'}}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                    <tfoot>
                                        <tr class="bg-light-secondary">
                                            <td class="text-left fw-bolder">TOTAL GENERAL:</td>
                                            <td class="text-left"></td>
                                            <td class="text-left"></td>
                                            <td class="text-left"></td>
                                            <td class="text-left"></td>
                                            <td class="text-right fw-bolder">{{getTotalOverallSummarie('grossSalary') | currency: 'RD$'}}</td>
                                            <td class="text-right fw-bolder">{{getTotalOverallSummarie('otherCredits') | currency: 'RD$'}}</td>
                                            <td class="text-right fw-bolder">{{getTotalOverallSummarie('totalSalary') | currency: 'RD$'}}</td>
                                            <td class="text-right fw-bolder">{{getTotalOverallSummarie('afp') | currency: 'RD$'}}</td>
                                            <td class="text-right fw-bolder">{{getTotalOverallSummarie('sfs') | currency: 'RD$'}}</td>
                                            <td class="text-right fw-bolder">{{getTotalOverallSummarie('isr') | currency: 'RD$'}}</td>
                                            <td class="text-right fw-bolder">{{getTotalOverallSummarie('otherDiscounts') | currency: 'RD$'}}</td>
                                            <td class="text-right fw-bolder">{{getTotalOverallSummarie('totalDiscount') | currency: 'RD$'}}</td>
                                            <td class="text-right fw-bolder">{{getTotalOverallSummarie('netIncome') | currency: 'RD$'}}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div>
                                    <el-caption #paginatorOverallSummaries [originalRecordsLength]="viewGetOverallSummaries.length"
                                    [listedRecordsLength]="viewGetOverallSummariesRecords.length"
                                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :viewGetOverallSummaries.length"
                                    [records]="filteredList.enable ? filteredList.records : viewGetOverallSummaries"
                                    (onChage)="getPaginatedOverallSummaries($event)"></el-caption>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <!-- <div class="card-header mt-3 row justify-content-between">
                            <div class="col-sm-12 col-md-12 c-left">
                            </div>
                            <div class="col-sm-12 col-md-12 c-right mr-0">
                                <div class="card-toolbar d-flex justify-content-end">
                                    <div class="col text-nowrap">
                                        <ul class="nav nav-primary nav-pills text-uppercase mt-2" id="myTab2" role="tablist">
                                        <li class="nav-item col-md-auto mb-2">
                                        <a class="nav-link active" id="nempleado-tab-2" data-toggle="tab" href="#porCantidad">
                                        <span class="nav-icon mr-2 ">
                                        <i class="flaticon-productividad icon-2x"></i>
                                        </span>
                                        <span class="nav-text font-size-h3">Por Cantidad</span>
                                        </a>
                                        </li>
                                        <li class="nav-item col-md-auto mb-2">
                                        <a class="nav-link" id="inomina-tab-2" data-toggle="tab" href="#porValores"
                                        aria-controls="inomina">
                                        <span class="nav-icon mr-2">
                                        <i class="flaticon-agil icon-2x"></i>
                                        </span>
                                        <span class="nav-text font-size-h3">Por Valores</span>
                                        </a>
                                        </li>
                                        <li class="nav-item col-md-auto mb-2">
                                        <a class="nav-link" id="inomina-tab-2" data-toggle="tab" href="#porDetalle"
                                        aria-controls="idetalle">
                                        <span class="nav-icon mr-2">
                                        <i class="flaticon-agil icon-2x"></i>
                                        </span>
                                        <span class="nav-text font-size-h3">Detalle</span>
                                        </a>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

      <!--div class="tab-pane fade" id="last-payroll" role="tabpanel" aria-labelledby="last-payroll">
           <app-last-payroll-tab></app-last-payroll-tab>
        </div-->
    </div>
  </div>
</ng-container>