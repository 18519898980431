import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ContactsService } from 'src/app/services/http-client/contactsService.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { CandidateRegService } from 'src/app/services/http-client/candidateRegService.service';
import * as uuid from 'uuid';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import Swal from 'sweetalert2';
import { getRegex } from 'src/app/shared/utils/utility';
import { MatDialog } from '@angular/material/dialog';
import { AddPhoneComponent } from './add-phone/add-phone/add-phone.component';
import { AddEmailComponent } from './add-email/add-email/add-email.component';
import { ParameterControlService } from 'src/app/services/parameter-control.service';

@Component({
  selector: 'app-contact-component',
  templateUrl: './contact-component.component.html',
  styleUrls: ['./contact-component.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactComponentComponent implements OnInit, DoCheck {
  public phoneList = [];
  public TypePhoneList: any[] = [/* { id: 1, description: 'Personal' }, { id: 2, description: 'Politico' }, { id: 3, description: 'Trabajo' } */];
  public type = 1;
  @Input() personType: number;
  public description = '';
  public isPrincipal = 1;
  public principal = { yes: 1, no: 2 }
  phoneExtension: string = '';
  public phoneListCorreo = [];
  public typeCorreo = 1;
  public TypeEmailList: any[] = [];
  public descriptionCorreo = '';
  public isPrincipalCorreo = 1;
  public principalCorreo = { yes: 1, no: 2 }
  public editing = false;
  public id = 0;
  showEmailError = false; //Variable para mostrar el mensaje de error del correo
  constructor(private srvContact: ContactsService, private toast: ToastService,
    private candidateService: CandidateRegService, private cdr: ChangeDetectorRef, private _dialog: MatDialog, private parameterService: ParameterControlService) { }

  

  ngDoCheck(): void {
    this.changeDetector()
  }

  ngOnInit(): void {
    this.parameterService.getParameters(keyWord.ContactType).subscribe({
      next:(res)=>{

        if(!res.succeded){
          this.toast.error("Error al cargar los tipos de teléfonos")
          return;
        }

        this.TypePhoneList = this.personType > 0 ? res.dataList : res.dataList.filter(x=> x.ocode != 2);
        this.changeDetector();
      },
      error:(err)=>{
        this.toast.error("Error al cargar los tipos de teléfonos")
      }
    })

    this.parameterService.getParameters(keyWord.EmailContactType).subscribe({
      next:(res)=>{

        if(!res.succeded){
          this.toast.error("Error al cargar los tipos de correos")
          return;
        }

        this.TypeEmailList = res.dataList;
        this.changeDetector();
      },
      error:(err)=>{
        this.toast.error("Error al cargar los tipos de correos")
      }
    })
    
  }

  changeDetector() {
    this.cdr.detectChanges();
  }

  addPhone(phoneInfo) {
    if (this.editing == true) {
      let phoneIndex = this.phoneList.indexOf(this.phoneList.find(x => x.description == this.description))
      if (phoneIndex >= 0) {

        if (this.id != phoneIndex) {
          this.toast.warning("Este teléfono ya está agregado");
          return;
        }
      }
      this.phoneList[this.id].type = this.type
      this.phoneList[this.id].description = this.description
      this.phoneList[this.id].principal = this.isPrincipal
      this.phoneList[this.id].phoneExtension = this.phoneExtension
    } else {

      this.phoneList.push({ id: 0, ...phoneInfo })
    }

    this.changeDetector();
    document.getElementById('btnAddPhone').click()
  }

  getType(id) {
    return this.TypePhoneList.find(e => e.ocode == id)?.stringData
  }
  getTypeEmail(id) {
    return this.TypeEmailList.find(e => e.ocode == id)?.stringData
  }

  validateCharacter(event) {
    if (/\D/g.test(this.phoneExtension)) this.phoneExtension = this.phoneExtension.replace(/\D/g, '')

  }
  removePhone(i) {
    let id = this.phoneList[i].id;


    this.swalQuestion(() => {
      if (id == 0) {
        this.phoneList.splice(i, 1)
      } else {
        this.srvContact.deletePhone(id).subscribe(e => {
          this.toast.success('Contacto borrado')
          this.phoneList.splice(i, 1)
          this.changeDetector();
        }, error => {
          this.toast.error(error)
        })
      }
    })

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  editPhone(i: number, phoneData) {
    console.log(i);
    this.phoneList[i].description = phoneData.description;
    this.phoneList[i].type = phoneData.type;
    this.phoneList[i].principal = phoneData.principal;;
    this.phoneList[i].phoneExtension = phoneData.phoneExtension;;

    this.editing = true
    this.id = i;
  }

  clear() {
    this.editing = false;
    this.id = 0;
    this.type = 1
    this.description = ''
    this.isPrincipal = 2
    this.descriptionCorreo = '';
    this.typeCorreo = 1;
    this.phoneExtension = ''
  }

  extensionIsValid() {
    if (!this.phoneExtension) {
      return true
    }
    const re = getRegex('number')
    if (re.test(this.phoneExtension)) {
      return true
    } else {
      return false
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isEmailValid(email: string) {
    const valid = this.validateEmail(email.trim());
    this.showEmailError = !valid;
    this.changeDetector();
  }
  /*datos de correo */

  addCorreo(emailData) {
    if (this.editing == true) {
      let mailIndex = this.phoneListCorreo.indexOf(this.phoneListCorreo.find(x => x.description == this.descriptionCorreo))
      if (mailIndex >= 0) {

        if (this.id != mailIndex) {
          this.toast.warning("Este correo electrónico ya está agregado");
          return;
        }
      }
      this.phoneListCorreo[this.id].type = emailData.type
      this.phoneListCorreo[this.id].description = emailData.description
      this.phoneListCorreo[this.id].principal = emailData.isPrincipal
    } else {

      this.phoneListCorreo.push({ id: 0, ...emailData })
      this.changeDetector();
    }

    document.getElementById('btnAddEmail').click()
  }

  removeCorreo(i) {
    let id = this.phoneListCorreo[i].id;

    this.swalQuestion(() => {
      if (id == 0) {
        this.phoneListCorreo.splice(i, 1)
      }
      else {
        this.srvContact.deleteEmail(id).subscribe(e => {
          this.toast.success('Correo borrado')
          this.phoneListCorreo.splice(i, 1)
          this.changeDetector();
        }, error => {
          this.toast.error(error)
        })
      }
    })

  }

  editCorreo(i: number, emailData) {
    this.phoneListCorreo[i].description = emailData.description;
    this.phoneListCorreo[i].type = emailData.type;
    this.phoneListCorreo[i].principal = emailData.principal;

    this.editing = true
    this.id = i;

  }

  swalQuestion(fn: () => void) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      title: "¿Está seguro que desea eliminar este registro?",
      icon: "question"
    }).then(res => {
      if (res.isConfirmed) {
        fn();
      }
    });
  }

  openAddPhoneModal(isEditing: boolean = false, phoneInfo: any = null, index?: number) {
    this._dialog.open(AddPhoneComponent, {
      data: {
        phoneTypeList: this.TypePhoneList,
        phoneList: this.phoneList,
        phoneInfo,
        isEditing,
        personType: this.personType,
        indexEditing: index
      },
      width: '30%'
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          const { phoneType, phoneNumber, isPrimary, extension } = res
          let phoneData = { type: phoneType?.ocode, description: phoneNumber, principal: isPrimary, phoneExtension: extension }
          if (!isEditing) {
            this.addPhone(phoneData)
            return
          }
          this.editPhone(index, phoneData)
        }
      },
      error: (err: any) => {

      }
    })
  }

  openAddEmailModal(isEditing: boolean = false, emailInfo: any = null, index?: number) {
    this._dialog.open(AddEmailComponent, {
      data: {
        emailTypeList: this.TypeEmailList,
        emailList: this.phoneListCorreo,
        emailInfo,
        isEditing
      },
      width: '30%'
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          const { emailType, email, isPrimary } = res
          let emialData = { type: emailType?.ocode, description: email, principal: isPrimary }
          if (!isEditing) {
            this.addCorreo(emialData)
            return
          }
          this.editCorreo(index, emialData)
        }
      },
      error: (err: any) => {

      }
    })
  }

}
