import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { parameterDoc, personalFile } from '../utils/parameterDoc';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { environment } from 'src/environments/environment';
import { FileResponse } from '../models/uppy-file-manager.model';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ShowDocumentModalComponent } from '../show-document-modal/show-document-modal.component';
import { docService } from 'src/app/services/doc.service';
import { NumbersService } from 'src/app/services/numbers.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Swal from 'sweetalert2';

type GUID = string & { isGuid: true };

@Component({
  selector: 'uppy-file',
  templateUrl: './uppy-file.component.html',
  styleUrls: ['./uppy-file.component.css']
})
export class UppyFileComponent implements OnInit, AfterViewInit {
  @Input() parameterDoc: parameterDoc = parameterDoc.PathTest;
  @Input() isDisabled: boolean = false;
  @Input() showDocumentoAfterUploaded: boolean = true;
  @Input() showButtonViewFile: boolean = true;
  @Input() targetClass: string = 'targetUppyFileM'
  @Input() customId: string = 'uppy0778'
  @Input() cardBody: string = 'card-body';
  @Input() centerContentClass: string = '';
  @Input() hidePreviewDefaultImage: boolean = false;
  @Input() previewDefaultImage: string = 'assets/images/cv.jpg';
  @Input() classPreviewDefaultImage: string = 'symbol symbol-150 d250 my-4 max-w-250px';
  @Input() typePersonalFile: personalFile = personalFile.others;
  @Input() allowedFileTypes: string[] = [
    'application/pdf'
  ];
  @Input() showFileModalTitle: string = 'Evidencia';
  @Input() uploadFile: boolean = false;
  @Input() height: number = 295;
  @Input() width: number = 320;
  @Input() thumbnailWidth: number = 320;

  @Input() cleanAll!: boolean;
  @Input() fileForEditting: string;
  @Input() confirmation: boolean;

  @Input()
  SystemLoad: number = 1;
  @Input()
  ModuleLoad: number = 2;
  wasFileLoaded: boolean = false;
  fileSelected: FormData | null = null;

  @Output() fileEmitter = new EventEmitter<FormData>();

  @Output() changeEmitter = new EventEmitter<void>();

  uppyComponent = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
    },
    locale: Mexico
  });

  fileGUID!: GUID;

  private readonly DOCUMENT_API_BASE_URL = environment.api_doc + '/Document';

  fileData: { filePath: string } = { filePath: '' };

  routeFile: string = "";

  extension: string = '';

  fileToDelete: string = '';

  safeResourceUrl: SafeResourceUrl | null = null;

  imageExtension: boolean = false;

  constructor(private dialog: MatDialog,
    private toastService: ToastrService,
    private docService: docService,
    private numbersService: NumbersService,
    private sanitizer: DomSanitizer,
    private parameterService: ParameterControlService) {

    const number = this.numbersService.getRandomInt(1, 1000);
    this.targetClass = `${this.targetClass}-${number}`;

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.uppyComponent.setOptions({
      restrictions: {
        maxFileSize: environment.maxFileSize,
        maxNumberOfFiles: 1,
        allowedFileTypes: this.allowedFileTypes
      },
    })

    const fileForEdittingChange: SimpleChange = changes['fileForEditting'];

    if (fileForEdittingChange && fileForEdittingChange?.previousValue != fileForEdittingChange?.currentValue) {
      if (fileForEdittingChange.currentValue) {
        this.getFileforEditting(fileForEdittingChange.currentValue);
      }
    }
    this.uppyComponent?.getPlugin('Dashboard')?.setOptions({
      disabled: this.isDisabled
    });
  }


  ngAfterViewInit(): void {
    this.getPath();
  }

  getPath() {
    this.parameterService.getParameters(this.parameterDoc).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })

          res.warnings.forEach(warn => {
            this.toastService.warning(warn);
          })

          return;
        }

        this.routeFile = res.dataList[0].stringData.toString();
        this.loadUppy()
      },
      error: (err) => {
        this.toastService.error('Ha ocurrido un error inesperado consultado la lista de parametros para la evidencia')
      }
    })

  }

  loadUppy() {
    this.uppyComponent.use(Dashboard, {
      trigger: '.UppyModalOpenerBtn',
      inline: true,
      target: `.${this.targetClass}`,
      disabled: this.isDisabled,
      showProgressDetails: true,
      note: `1 archivo, con un maximo de ${(environment.maxFileSize / 0.000001)} MB`,
      width: this.width,
      height: this.height,
      locale: {
        strings: {
          dropPasteFiles: 'Arrastra archivos aquí o %{browse}'
        }
      },
      thumbnailWidth: this.thumbnailWidth,
      metaFields: [
        { id: 'name', name: 'Nombre', placeholder: 'Nombre del Archivo' },
        { id: 'caption', name: 'Caption', placeholder: 'Descripción del archivo' }
      ],
      browserBackButtonClose: false,
      showLinkToFileUploadResult: true,
      hideUploadButton: true
    },
    ).use(XHRUpload, { endpoint: `${this.DOCUMENT_API_BASE_URL}?SystemLoad=${this.SystemLoad}&ModuleLoad=${this.ModuleLoad}&personalFile=${this.typePersonalFile}&parameterDoc=${this.routeFile}` })
      .on('file-added', (file) => {
        const formData = new FormData();
        formData.append('file', file.data);
        this.fileSelected = formData;
        this.fileEmitter.emit(formData);
        this.changeEmitter.emit();
      }).on('file-removed', () => {
        this.fileEmitter.emit(null);
        this.changeEmitter.emit();
      });
  }


  cleanUppy() {
    this.fileToDelete = null;
    this.fileGUID = null;
    this.fileData.filePath = '';
    this.fileForEditting = null;
    this.extension = '';
    this.uppyComponent?.cancelAll();
    this.uppyComponent.reset();
  }

  getFileforEditting(guid) {
    this.docService.getDocument(guid).subscribe(res => {
      this.fileGUID = this.fileForEditting as GUID;
      this.fileData.filePath = res.data ? res.data : ''
      this.safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.data);
      const fileName = this.fileData.filePath;
      this.extension = this.fileData?.filePath?.substring(fileName.lastIndexOf('.') + 1);
      this.imageExtension = this.isImage(this.extension);
      this.changeEmitter.emit();
    })
  }

  showFile(filePath: string, title: string) {
    this.dialog.open(ShowDocumentModalComponent, {
      width: '50%',
      data: {
        src: filePath,
        title
      }
    })
  }

  removeFile(needConfirmation) {

    if (needConfirmation) {
      Swal.fire({
        icon: 'question',
        title: 'Borrar Archivo',
        text: '¿Está seguro de eliminar este archivo?',
        showCancelButton: true,
        confirmButtonColor: '#e63946',
        confirmButtonText: 'Sí, Eliminar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {

          this.fileToDelete = this.fileGUID;

          this.fileGUID = null;
          this.fileData.filePath = '';
          this.fileForEditting = '';
          this.uppyComponent?.cancelAll();
          this.uppyComponent.reset();
          this.changeEmitter.emit();
          this.fileEmitter.emit(null);
        }
      })
      return
    }


    this.fileToDelete = this.fileGUID;

    this.fileGUID = null;
    this.fileData.filePath = '';
    this.fileForEditting = '';
    this.uppyComponent?.cancelAll();
    this.uppyComponent.reset();
    this.changeEmitter.emit();
    this.fileEmitter.emit(null);
  }

  removeFileDefinitely() {
    this.docService.deleteFile(this.fileToDelete).subscribe(e => {
      this.fileToDelete = null;
      this.changeEmitter.emit();
    });
  }

  handleFile(setGuid: (guid: string) => void) {

    if (this.uppyComponent.getFiles()?.length == 0) {
      setGuid(this.fileGUID);
      return;
    }

    if (this.wasFileLoaded && this.strongExistFile) {
      setGuid(this.fileGUID);
      return;
    }

    this.uppyComponent.getPlugin('XHRUpload').uppy.upload().then(result => {
      if (result.successful) {
        let resData = result.successful[0].response.body.data as FileResponse;
        this.fileGUID = (resData.fileUnit.guidname) as GUID;
        setGuid(this.fileGUID);
        if (this.showDocumentoAfterUploaded) {
          this.fileData.filePath = resData.path;
          this.safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(resData.path);
          const fileName = this.fileData.filePath;
          this.extension = this.fileData?.filePath?.substring(fileName.lastIndexOf('.') + 1);
        }
        if (this.fileToDelete) {
          this.removeFileDefinitely();
        }
        this.wasFileLoaded = true;
        this.changeEmitter.emit();
      } else {
        throw new Error('Upload failed');
      }
    }).catch(error => {
      throw new Error(error);
    });
  }

  get existFileUploaded(){
    const exist = this.uppyComponent.getFiles()?.length > 0;
    return exist;
  }

  get existFile(): boolean {
    const exist = this.uppyComponent.getFiles()?.length > 0 || this.fileGUID?.length > 0;
    return exist;
  }

  get strongExistFile(): boolean {
    const exist = this.uppyComponent.getFiles()?.length > 0 && this.fileGUID?.length > 0;
    return exist;
  }

  isImage(extension: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'tiff'];
    return imageExtensions.includes(extension.toLowerCase());
  }

}