export class Replacements {
    replacements: Replacement[] = []
}

export class Replacement {
    replacingId?: number;
    personalRequisitionId: number;
    employeeId: number;
    companyId: number;
    status: boolean;
}
