export class CollectiveVations {
    collectiveVacationsHeaderId?: number;
    startDate: string
    endDate: string
    comment: string;
    totalDays: number;
    year: number;
    conditionId: number;
    status: boolean;
    createDate: string
    createUserId: number;
    employeeCategoryId?:number;
    modifyDate?: string
    modifyUserId?: number;
    companyId: number;

    constructor() {
        this.startDate = ''
        this.endDate = ''
        this.comment = ''
        this.totalDays = 0
        this.year = 0
        this.conditionId = 0
        this.status = false
        this.createDate = ''
        this.createUserId = 0
        this.employeeCategoryId = 0
        this.modifyDate = ''
        this.modifyUserId = 0
        this.companyId = 0
    }
}
export class VacationDetail {
    exclusionReason?: string;
    userId: number;
    companyId: number;
    collectiveVacationsHeaderId: number;
    employees: Employee[];

    constructor() {
        this.exclusionReason = ''
        this.userId = 0
        this.companyId = 0
        this.collectiveVacationsHeaderId = 0
        this.employees = []
    }
}

export class Employee {
    employeeId: number;
    status: boolean;
}