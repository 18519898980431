export class Header {
    headerId?: number;
    payrollPeriodId: number;
    budgetAreaId: number;
    payrollTypeId: number;
    conditionId: number;
    companyId: number;
    status: boolean;
    createDate: string;
    createUserId:any;
    modifyDate: string;

    constructor() {
        this.headerId = 0
        this.payrollPeriodId = 0
        this.budgetAreaId = 0
        this.payrollTypeId = 0
        this.conditionId = 0
        this.companyId = 0
        this.status = false
        this.createDate = ""
        this.createDate = "";
        this.modifyDate = ""
        this.createUserId = 0
    }
}

