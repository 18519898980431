<div *ngIf="isLoading" class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
    <el-spinner animation="spinner-logo"></el-spinner>
    <h3 class="text-primary">Cargando Requisición...</h3>
  </div>
  <div class="container-fluid py-3">
    <button  (click)="goBack()"  class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
        <i class="flaticon2-left-arrow-1"></i>
        <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
    </button>
  </div>
  <div class="container-fluid" *ngIf="!isLoading && interview">
    <div id="pdfExp" class="card">
      <div class="card-header px-22">
        <div class="align-items-center d-flex flex-md-row justify-content-lg-between justify-content-sm-center" id="header">
          <div class="logo px-2"> <img [src]="file.logo" alt="" class="max-w-200px"/> </div>
          <div class="title p-2 d-flex justify-content-center align-items-center flex-column" >
            <h1 class="ttlPDF text-primary text-center"> Gerencia de Recursos Humanos </h1>
            <h2 class="text-danger text-center">Reporte de Entrevistas</h2>
          </div>
          <div class="date mt-md-5 p-4 d-flex flex-wrap justify-content-between align-items-center">
            <h4 class="text-right mr-3">Fecha de solicitud</h4>
            <h5 class="text-danger text-right">{{interview.dateInterview | date: 'dd MMMM yyyy': 'UTC':'es-US'}}</h5>
            <!-- &nbsp; -->
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row gutter-t">
            <h3 class="col-md-12 p-2 bg-primary text-white text-center gutter-b">DATOS GENERALES</h3>
            <div class="col-6">
            <div class="row">
            <div class="col-12 col-sm-3 label-inline">
                    <label>Nombre del Entrevistado:</label>
            </div>
                <div class="col-12 col-sm-3 label-inline">
                    <span class="text-nowrap ms-75px">{{ interview.fullName || ""}}</span>
             </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Puesto Solicitado:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.position || ""}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Estatus Laboral:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{ interview.unemployed ? 'Desempleado': 'Trabajando' }}</span>
                </div>
            </div>
          
            <div *ngIf="interview.vocationalTraining" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Aspecto profesional:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.vocationalTraining ? 'Si' : 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.learning" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Auto aprendizaje:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.learning ? 'Si' : 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.analysisCapacity" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Capacidad analítica:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.analysisCapacity ? 'Si' : 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.languageAbility" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Capacidad idiomática:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.languageAbility ? 'Si' : 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.eyeContact" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Contacto visual:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.eyeContact ? 'Si' : 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.creativityAndInnovation" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Creatividad e Innovación:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.creativityAndInnovation ? 'Si' : 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.communication" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Comunicación no verbal:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.communication ? 'Si' : 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.laborStability" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Estabilidad Laboral:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.laborStability ? 'Si' : 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.supervisionExperience" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Experiencia de Supervisión:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.supervisionExperience ? 'Si' : 'No'}}</span>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div *ngIf="interview.verbalFluency" class="row">
            <div class="col-12 col-sm-3 label-inline">
                    <label>Fluidez Verbal:</label>
            </div>
                <div class="col-12 col-sm-3 label-inline">
                    <span class="text-wrap ms-75px">{{interview.verbalFluency ? 'Si' : 'No'}}</span>
             </div>
            </div>
            <div *ngIf="interview.professionalDevelopment" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Formación Profesional:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.professionalDevelopment ? 'Si' : 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.initiative" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Iniciativa:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.initiative ? 'Si': 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.leadership" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Liderazgo:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.leadership ? 'Si': 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.multitask" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Multitarea:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.multitask ? 'Si': 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.achievementOrientation" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Orientado a Logros:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.achievementOrientation ? 'Si': 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.criticalThinking" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Pensamiento Crítico:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.criticalThinking ? 'Si': 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.structuredThinking" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Pensamiento Estructurado:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.structuredThinking ? 'Si': 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.systematicThinking" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Pensamiento Sistemático:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.systematicThinking ? 'Si': 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.puntuality" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Puntualidad:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.puntuality ? 'Si': 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.resilience" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Resiliencia:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.resilience ? 'Si': 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.selfConfidence" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Seguridad en sí mismo:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.selfConfidence ? 'Si': 'No'}}</span>
                </div>
            </div>
            <div *ngIf="interview.sociability" class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Sociabilidad:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.sociability ? 'Si': 'No'}}</span>
                </div>
            </div>
        </div>
        </div>
        <div class="row gutter-t">
            <h3 class="col-md-12 p-2 bg-secondary text-uppercase text-white text-center d-block">
                Observaciones Generales
            </h3>
            <div class="col-6 pdf-list col-md-6">
            <div class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Fecha de entrevista:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.dateInterview || "" | date:'dd MMMM yyyy':'UTC':'es-US'}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Posición propuesta:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.proposedPositionName || ""}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Horario propuesto:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.proposedScheduleName || ""}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Razones del cambio:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.reasonsChange || ""}}</span>
                </div>
            </div>
        </div>
        <div class="col-6 pdf-list col-md-6">
            <div class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Salario propuesto:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.proposedSalary | currency}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Fecha Disponibilidad:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.dateAvailability | date:'dd MMMM yyyy':'UTC':'es-US'}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3 label-inline">
                    <label>Observaciones:</label>
                </div>
                <div class="col-12 col-sm-9 label-inline">
                    <span class="text-wrap ms-75px">{{interview.generalRemarks}}</span>
                </div>
            </div>
        
        </div>
        </div>
        <div class="row gutter-t">
          <div class="col-md-6 gutter-t gutter-b">
            <div class="card card-dt">
                <div class="card-header p-2"><h3 class="text-uppercase m-0 text-primary font-weight-bolder text-center">Resultados</h3></div>
                <div class="card-body"><strong>{{interview.result}}</strong></div>
            </div>
        </div>
        <div class="col-md-6 gutter-t gutter-b">
            <div class="card card-dt">
                <div class="card-header p-2"><h3 class="text-uppercase m-0 text-primary font-weight-bolder text-center">Entrevistado por</h3></div>
                <div class="card-body"><strong>{{interview.employeeFullName}}</strong></div>
            </div>
        </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="col-md-12 gutter-b gutter-t">
            <div class="firma text-center">
                <span>_________________________________________</span>
                <p class="font-weight-bold text-dark">
                    Firma
                </p>
            </div>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
          <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-80px">
          <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
        </div>
      </div>
    </div>
  </div>
  <button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
    <i class="flaticon2-printer font-size-h1"></i>
  </button>
  <script>
    let print = () => {
      let pdf = document.getElementById("pdfExp");
      pdf.contentWindow.focus();
      pdf.contentWindow.print();
    };
  </script>
