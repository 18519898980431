import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FinalPayrollAddnewsModalComponent } from '@payroll/final-payroll/final-payroll-addnews-modal/final-payroll-addnews-modal.component';
import { INewsTypeGET, IEmployeeGET, INewsPOST } from '@payroll/final-payroll/models/models';
import { Beneficiary, ConceptSetting, DefaultValue, PayrollNew } from '@payroll/news-list/models/PayrollNews.model';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { FinalPayrollService } from 'src/app/services/final-payroll.service';
import { IncentivePayrollService } from 'src/app/services/incentive-payroll.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { personalFile, parameterDoc } from 'src/app/shared/utils/parameterDoc';
import { environment } from 'src/environments/environment';

type GUID = string & { isGuid: true };
@Component({
  selector: 'app-incentive-add-news',
  templateUrl: './incentive-add-news.component.html',
  styleUrls: ['./incentive-add-news.component.css']
})
export class IncentiveAddNewsComponent implements OnInit {

  modalTitle = 'Agregar novedad'
  newsType: INewsTypeGET;
  errors = {
    concept: false,
    amount: false,
    evidence: false
  }
  newsTypesOpts = []
  newId = 0
  formIsInvalid = false;
  checkIsSelected: boolean = undefined;
  private readonly urlDoc = environment.api_doc + '/Document';
  private readonly SystemLoad: number = 1;
  private readonly ModuleLoad: number = 2;
  private routeFile: string = "";
  employeeImg = ''
  evidenceImg = ''
  employeeSelected: IEmployeeGET = {
    email: '',
    name: '',
    phoneNumber: '',
    academicLevel: '',
    personalIdentification: '',
    typeIdentificationId: 0,
    employeeSupervisor: ''
  }
  file: any = {
    evidence: ''
  }
  conceptsOriginal = []
  personalFile = personalFile;
  
  concepts: any = [];
  
  evidence = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.jpg','.png','application/pdf']
    },
    locale:Mexico
  });
  form: FormGroup;
  datePipe = new DatePipe('en-Us');
  conceptSetting: any;
  
  typeDocuments: any[] = [];
  banks: any[] = [];
  accountTypes: any[] = [];
  paymentMethods: any[] = [];

  isLitisSelected: boolean = false;
  isAdditionalSelected: Boolean = false;
  isExternalSelected: boolean = false;
  isFiscalCreditSelected: boolean = false;
  
  @ViewChild('defaultValue') defaultValue: ElementRef;
  @ViewChild('manualValue') manualValue: ElementRef;
  defaultValues: any[] = [];
  defaultValuesOriginalList: any[] = [];
  imgDefaultPdf: string = 'assets/images/cv.jpg';
  public mask = "000-0000000-0"
  public maskText = "Ej: 001-000000-2"
  constructor(
    private srvDoc: docService,
    private _toastService: ToastrService,
    private dialogRef: MatDialogRef<FinalPayrollAddnewsModalComponent>,
    private sanitizer: DomSanitizer,
    private _cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { startDate:any, endDate: any, modalTitle: string, mode: 'show' | 'add' | 'edit', employeeId: number, newId: number,
    item: any},
    private formBuilder: FormBuilder,
    private srvFinal: FinalPayrollService,
    private parameterControlService: ParameterControlService,
    private srvAuth: AuthInfoService,
    private payrollNewService: PayrollNewService,  
    private srvIncentive: IncentivePayrollService
  ) { 
  }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges()
  }

  ngOnInit(): void {
    this.getParameterControl()
    this.getNewsType()
    this.getConcepts()
    this.getTypeDocuments();
    this.getBanks();
    this.getAccountTypes();
    this.getPaymentMethods();
    const {startDate, endDate} = this.data
    this.form = this.buildForm()
    const form = this.form
    this.getEmployeeByNumber(this.data.employeeId)
    if(this.data.modalTitle){
      this.modalTitle = this.data.modalTitle
    }
    if(this.data.mode == 'show'){
      form.disable()
    }
    if(this.data.mode == 'edit'){
      this.getNewsById(this.data.newId)
    }
    
    form.patchValue({
      startDate: formatDate(startDate, 'yyyy-MM-dd', 'en'),
      endDate: endDate ? formatDate(endDate, 'yyyy-MM-dd', 'en') : null
    })
    this.checkNewType(this.data?.item?.newsTypeId);
  }

  getTypeDocumentById(id) {
    return this.typeDocuments.find(n => n.ocode === id);
  }

  getPaymentMethodById(id) {
    return this.paymentMethods.find(n => n.ocode === id);
  }

  getAccountTypeById(id) {
    return this.accountTypes.find(n => n.ocode === id);
  }

  getBankById(id) {
    return this.banks.find(n => n.ocode === id);
  }

  getTypeDocuments() {
    this.parameterControlService.getParameters(keyWord.IDType).subscribe(e => {
      if (e.dataList.length > 0) {
        this.typeDocuments = e.dataList;
      }
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  getBanks() {
    this.parameterControlService.getParameters(keyWord.BankingEntity).subscribe(e => {
      if (e.dataList.length > 0) {
        this.banks = e.dataList;
      }
    })
  }

  getAccountTypes() {
    this.parameterControlService.getParameters(keyWord.AccountType).subscribe(e => {
      if (e.dataList.length > 0) {
        this.accountTypes = e.dataList;
      }
    })
  }

  getPaymentMethods() {
    this.parameterControlService.getParameters(keyWord.PaymentForm).subscribe(e => {
      if (e.dataList.length > 0) {
        this.paymentMethods = e.dataList;
      }
    })
  }

  changePaymentMethod() {
    this.setCheckIsSelected()
  }
  setCheckIsSelected() {
    const field = this.form.get('beneficiary').value.paymentMethod.ocode
    this.checkIsSelected = field == 2
  }

  getBeneficiaryById(beneficiaryId, companyId) {
    this.payrollNewService.getBeneficiaryById(beneficiaryId, companyId).subscribe((res: any) => {
      const data = res.dataList[0];
      this.form.get('beneficiary').patchValue({
        ...data,
        typeDocument: this.getTypeDocumentById(data.identificationTypeId),
        paymentMethod: this.getPaymentMethodById(data.paymentMethodId),
        bank: this.getBankById(data.bankId),
        accountType: this.getAccountTypeById(data.accountTypeId)
      });
      this.setCheckIsSelected()
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  getNewsById(id){
    this.srvFinal.getPayrollNewById(id).subscribe((res:any) => {
      if(res.succeded){
        const news = res.dataList[0]
        const evidence = news.evidence
        const concept = this.conceptsOriginal.find(x => x.newsConceptId == news.newsConceptId)
        if(concept){
          this.form.get('conceptId').patchValue(news.newsConceptId)
        }
        this.getEvidence(evidence)
        this.form.patchValue({
          newsType: this.newsTypesOpts.find(x => x.newsTypeId == news.newsTypeId),
          newsTypeId: news.newsTypeId,
          startDate: formatDate(news.startDate, 'yyyy-MM-dd', 'en'),
          endDate: news.endDate ? formatDate(news.endDate, 'yyyy-MM-dd', 'en') : null,
          concept: concept ?? null,
          evidence: news.evidence,
          balance: news?.balance || 0,
          totalAmount: news?.totalAmount || 0,
          manualValue: news?.manualValue || 0,
          defaultValue: news?.defaultValue || 0,
          amount: news?.amount || 0,
        });
        if (this.data.mode === 'show' || this.data.mode === 'edit') {
          this.getNewsConceptsSetting(news?.newsConceptId, news);
        }
        if(news.beneficiaryId > 0){
          this.getBeneficiaryById(news?.beneficiaryId, news?.companyId);
        }
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }
  
  deleteGuid(name) {
    let evidence = this.form.get('evidence').value
    try {
      if (evidence) {
        this.srvDoc.deleteFile(evidence).subscribe(e => {
          this.evidence.cancelAll()
          this.evidenceImg = '';
          this.form.get('evidence').patchValue('')
        }, error => {
          this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
        })
      }
    } catch (error) {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    }
  }
  
  close(){
    this.dialogRef.close({success:false})
  }
  
  save(){
    if (this.form.get('endDate')?.value && this.form.get('endDate')?.value < this.form.get('startDate')?.value) {
      this._toastService.warning('La fecha final no debe ser menor que la fecha inicio');
      return;
    }
    this.formIsInvalid = this.form.invalid
    if(this.formIsInvalid) return

    if (this.isNewTypeLitisSeleted) {

      if (this.checkIsSelected == false) {
        const office = this.form.get('beneficiary.office').value ?? ''
        const accountNumber = this.form.get('beneficiary.accountNumber').value ?? ''
        const accountType = this.form.get('beneficiary.accountType').value
        /* 
        Esto es para validar estos 3 campos, que sean requerido cuando la 'forma de cobro' sea cheque, 
        y que no sean requeridos cuando no sea cheque 
        */
        if (office.length < 1 || accountNumber.length < 1 || !accountType) {
          this.formIsInvalid = true
          return
        } else {
          this.formIsInvalid = false
        }
      }
    }

    const newsType: INewsPOST = this.getNewsValues()
    if(newsType?.newsTypeId == 4){
      newsType.balance = newsType?.totalAmount;
    }
    if(this.data.mode == 'add'){
      this.post(newsType)
    }
    if(this.data.mode == 'edit'){
      this.put(newsType)
    }
  }
  getBeneficiaryValues(){
    const beneficiaryValues = this.form?.value?.beneficiary
    let beneficiary: Beneficiary = {
      identificationTypeId: beneficiaryValues?.typeDocument?.ocode || 0,
      paymentMethodId: beneficiaryValues?.paymentMethod?.ocode || 0,
      bankId: beneficiaryValues?.bank?.ocode || 0,
      accountTypeId: beneficiaryValues?.accountType?.ocode || 0,
      companyId: this.srvAuth.getCompanyId(),
      createUserId: +this.srvAuth.getUser()?.userId,
      createDate:  new Date(),
      modifyUserId: 0,
      modifyDate: new Date(),
      status: true,
      accountNumber: beneficiaryValues.accountNumber,
      office: beneficiaryValues.office,
      identificationNumber: beneficiaryValues.identificationNumber,
      lastName: beneficiaryValues.lastName,
      firstName: beneficiaryValues.firstName,
      employeeId: this.data.employeeId,
      beneficiaryId: 0
    };
    if(this.data.mode == 'edit'){
      const beneficiaryId = beneficiary?.beneficiaryId
      if(beneficiaryId){
        beneficiary.beneficiaryId = beneficiaryId
      }
    }
    return beneficiary
  }
  
  post(newsType){
    if (this.isLitisSelected) {
      let beneficiary: Beneficiary = this.getBeneficiaryValues()
      this.postBeneficiary(beneficiary, newsType, () => {
        this.newPayrollNew(newsType);
      });
    } else {
      this.newPayrollNew(newsType);
    }
  }

  postBeneficiary(beneficiary, newsType, collBackPayrollNew: () => void){
    
    this.payrollNewService.postBeneficiary(beneficiary).subscribe(res => {
      if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al guardar el beneficiario"); return; }
      newsType.beneficiaryId = res.identity;
      collBackPayrollNew();
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  updateBeneficiary(beneficiary, newsType){
    this.payrollNewService.updateBeneficiary(beneficiary).subscribe(res => {
      if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al editar el beneficiario"); return; }
      this.changePayrollNew(newsType);
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  newPayrollNew(newsType) {
    this.srvFinal.postNewsFinalPayroll(newsType).subscribe((res:any) => {
      if(res?.warnings?.length > 0){
        this._toastService.warning(res?.warnings[0], '');
        return;
      }
      if (res?.errors?.length > 0) { this._toastService.error("Ha ocurrido un error al editar la novedad"); return; }
      if(res.succeded){
        this._toastService.success('Datos guardados')
        this.dialogRef.close({success: true})
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }

  put(newsType){
    if (this.isLitisSelected) {
      let beneficiary: Beneficiary = this.getBeneficiaryValues()
      if(beneficiary?.beneficiaryId > 0){
        this.updateBeneficiary(beneficiary, newsType);
      }else{
        beneficiary.createDate = new Date();
        beneficiary.beneficiaryId = 0;
        beneficiary.createUserId = +this.srvAuth.getUser()?.userId;
        this.postBeneficiary(beneficiary, newsType, () => {
          this.changePayrollNew(newsType);
        });
      }
    } else {
      this.changePayrollNew(newsType);
    }
  }

  changePayrollNew(newsType) {
    this.srvFinal.putNewsFinalPayroll(newsType).subscribe((res:any) => {
      if(res?.warnings?.length > 0){
        this._toastService.warning(res?.warnings[0], '');
        return;
      }
      if (res?.errors?.length > 0) { this._toastService.error("Ha ocurrido un error al editar la novedad"); return; }
      if(res.succeded){
        this._toastService.success('Datos guardados')
        this.dialogRef.close({success: true})
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }

  
  getNewsValues(): INewsPOST{
    const form = this.form.value
    const news: INewsPOST = {
      amount: form.amount,
      newsConceptId: form.concept.newsConceptId,
      companyId: this.srvAuth.getCompanyId(),
      createDate: new Date(),
      createUserId: 1,
      employeeId: this.employeeSelected.employeeId,
      evidence: form?.evidence ? form?.evidence : null,
      newsTypeId: form.newsTypeId,
      payrollNewsId: this.data.mode == 'edit' ? this.data.newId : 0,
      positionId: this.employeeSelected.positionId,
      startDate: this.datePipe.transform(this.form?.value?.startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(this.form?.value?.endDate, 'yyyy-MM-dd'),
      balance: form?.balance || 0,
      manualValue: form?.manualValue || 0,
      defaultValue: form?.defaultValue || 0,
      totalAmount: form?.totalAmount || 0,
      status: true,
      sourceId: 4,
      condition: 0,
      countApproves: 0
    }
    return news;
  }
  
  buildForm(){
    return this.formBuilder.group({
      newsType: ['', [Validators.required]],
      concept: ['', [Validators.required]],
      conceptId: [0, [Validators.min(1), Validators.required]],
      amount: [0, [Validators.required]],
      startDate: [''],
      endDate: [''],
      balance: [0],
      newsTypeId: [0, [Validators.min(1), Validators.required]],
      defaultValueSeleted: [null],
      manualValue: [0],
      defaultValue: [0],
      totalAmount: [0],
      evidence: [''],
      beneficiary: this.formBuilder.group({
        beneficiaryId: [0],
        employeeId: [0],
        identificationTypeId: [0],
        paymentMethodId: [0],
        bankId: [0],
        accountTypeId: [0],
        status: [true],
        companyId: [0],
        createDate: [null],
        createUserId: [0],
        modifyDate: [null],
        modifyUserId: [0],
        identificationNumber: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        typeDocument: ['', [Validators.required]],
        paymentMethod: ['', [Validators.required]],
        bank: ['', [Validators.required]],
        accountType: [''],
        accountNumber: [''],
        office: [''],
      })
    }) 
  }

  get isNewTypeLitisSeleted() {
    const isLitis = this.form.get('newsType').value?.newsTypeId === 3;
    return isLitis;
  }

  selectNewType(){
    const form = this.form
    const typeId = form.get('newsType').value.newsTypeId
    const newTypeIdField = form.get('newsTypeId')
    form.get('concept').patchValue(null)
    if(typeId){
      newTypeIdField.patchValue(typeId)
      this.concepts = this.conceptsOriginal.filter(x => x.newsTypeId === typeId)
    }else{
      this.concepts = []
    }
    if (typeId == 4) {
      this.hideFieldByFiscalCredit();
    }
    if (typeId == 3) {
      this.hideFieldByLitis();
    }
    if (typeId == 2) {
      this.hideFieldByAdditional();
    }
    if (typeId == 1) {
      this.hideFieldByExternal();
    }
    if(typeId == 4){
      this.form.get('balance')?.setValue(this.form.get('totalAmount')?.value);
      this.form.get('balance').disable();
    }else{
      this.form.get('balance').enable();
    }
  }
  getNewsType(){
    this.srvFinal.getNewsType().subscribe((res:any) => {
      if(res.succeded){
        this.newsTypesOpts = res.dataList.filter(x => x.status == true)
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error("Ha ocurrido un error inesperado", '')
    })
  }
  getConcepts(){
    this.srvIncentive.getNewsConcept().subscribe((res:any) => {
      if(res.succeded){
        this.conceptsOriginal = res.dataList.filter(x => x.status)
        if(this.data.newId> 0){
          this.getNewsById(this.data.newId)
        }
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado', '')
    })
  }
  
  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }
  
  loadUppy() {
    this.evidence.use(Dashboard, {
      trigger: '.UppyModalOpenerBtn',
      inline: true,
      target: '.evidence',
      showProgressDetails: true,
      note: 'Solo PDF, 1 archivo, con un maximo de 1 MB',
      width: 320,
      height: 295,
      thumbnailWidth: 320,
      metaFields: [
        { id: 'name', name: 'Nombre', placeholder: 'Nombre del Archivo' },
        { id: 'caption', name: 'Caption', placeholder: 'Descripción del archivo' }
      ],
      browserBackButtonClose: false,
      showLinkToFileUploadResult: true,
    },
    )
      .use(XHRUpload, { endpoint: this.urlDoc + `?SystemLoad=${this.SystemLoad}&ModuleLoad=${this.ModuleLoad}&personalFile=${this.personalFile.photo2x2}&parameterDoc=${this.routeFile}` })
      .on('complete', (result) => {
        if (result.successful) {
          let resData = result.successful[0].response.body.data as FileResponse
          this.form.get('evidence').patchValue(this.getGuid(resData.fileUnit.guidname))
          this.evidenceImg = resData.path;
        }
      });
  }
  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }
  
  getParameterControl() {
    this.parameterControlService.getParameters(parameterDoc.PathTest).subscribe(e => {
      if (e.dataList.length > 0) {
        this.routeFile = e.dataList[0].stringData.toString();
      }
      this.loadUppy();
    })
  }
  getEmployeeByNumber(employeeId: number){
    this.srvFinal.getEmployeeById(employeeId).subscribe((res: ResponseModel<IEmployeeGET>) => {
      if(res.succeded){
        this.employeeSelected = res.dataList[0]
        const img = this.employeeSelected.profileImage
        if(img){
          this.getDocument(img)
        }
      }else{
        
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }
  selectConcept(item){
    const concept = item.value
    if(Array.isArray(concept)){
      if(concept.length == 0){
        this.form.get('conceptId').patchValue(0)
      }
    }else{
      if (concept?.newsTypeId === 2) {
        const newsType = this.form.get('newsType').value;
        this.getNewsConceptsSetting(concept?.newsConceptId || 0, newsType);
      }else{
        this.resetConcetsSetting();
      }
      this.form.get('conceptId').setValue(concept.newsConceptId);
    }

 
  }

  checkNewType(type) {
    if (type == 4) {
      this.hideFieldByFiscalCredit();
      return;
    }
    if (type == 3) {
      this.hideFieldByLitis();
      return;
    }
    if (type== 2) {
      this.hideFieldByAdditional();
      return;
    }
    if (type == 1) {
      this.hideFieldByExternal();
      return;
    }
  }

  getNewsConceptsSetting(ConceptId: number, news){
    if(ConceptId === 0){
      this.resetConcetsSetting();
      return;
    }
    this.payrollNewService.getNewsConceptsSetting(ConceptId).subscribe((res: any) => {
      if(res.errors.length > 0){
        this._toastService.error(res.errors[0]);
        return;
      }
      if(res.dataList.length === 0){
        this.resetConcetsSetting();
        return;
      }
      this.conceptSetting = res.dataList[0] as ConceptSetting;
      if(this.conceptSetting?.requiredValue){
        this.showDefaultValue();
        this.getDefaultValuesByConceptValuesId(ConceptId, news);
      }else{
        this.hideDefaultValue();
      }
      if(this.conceptSetting?.manualValue){
        this.showManuelValue();
      }else{
        this.hideManuelValue();
      }
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  private resetConcetsSetting(){
    this.showManuelValue();
    this.showDefaultValue();
    this.defaultValues = [];
    this.defaultValuesOriginalList = [];
    this.conceptSetting = null;
    this.form.get('defaultValueSeleted').setValue(null);
    this.form.get('manualValue').setValue(null);
  }

  getDefaultValuesByConceptValuesId(id, news){
    this.payrollNewService.getDefaultValues(id).subscribe((res: any) => {
      if(res.errors.length > 0){
        this._toastService.error(res.errors[0]);
        return;
      }
      this.defaultValues = res?.dataList?.filter((d: { status: boolean; }) => d?.status)
      this.defaultValuesOriginalList = res?.dataList?.filter((d: { status: boolean; }) => d?.status)
      if (this.data.mode === 'show' || this.data.mode === 'edit') {
        const defaultValue = {...this.defaultValuesOriginalList.find(d => d.conceptValuesId === news?.defaultValue)};
        this.form.get('defaultValueSeleted').setValue(defaultValue?.conceptValuesId ? defaultValue : null);
      }
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    });
  }
  
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getDocument(guid: string){
    this.srvDoc.getDocument(guid).subscribe((res:any) => {
      this.employeeImg = res.data
    })
  }
  getEvidence(guid: string){
    this.srvDoc.getDocument(guid).subscribe((res:any) => {
      this.evidenceImg = res.data
      if(!res.data){
        this.evidence.reset()
        this.evidenceImg = ''
        this.form.get('evidence').patchValue('')
      }
    })
  }
  getErrors(name){
    const field = this.form.get(name)
    if(field.hasError('min')){
      return 'Este campo es requerido'
    }
    if(field.hasError('required')){
      return 'Este campo es requerido'
    }
    return ''
  }

  onChangeTotalAmount(){
    let newsTypeId = this.form.get('newsTypeId')?.value;

    if(newsTypeId){
      if(newsTypeId == 4){
        this.form.get('balance')?.setValue(this.form.get('totalAmount')?.value);
      }
    }

  }

  hideFieldByLitis() {
    this.isLitisSelected = true;
    this.isAdditionalSelected = false;
    this.isExternalSelected = false;
    this.isFiscalCreditSelected = false;
    //show
    this.form.get('beneficiary').enable();
    this.form.get('amount').enable();
    this.form.get('amount').setValidators(Validators.required);
    this.form.get('amount').updateValueAndValidity();
    //hide
    this.form.get('defaultValueSeleted').setValidators(null);
    this.form.get('defaultValueSeleted').updateValueAndValidity();
    this.form.get('manualValue').setValidators(null);
    this.form.get('manualValue').updateValueAndValidity();
    this.form.get('totalAmount').disable();
    this.form.get('totalAmount').setValidators(null);
    this.form.get('totalAmount').updateValueAndValidity();
    this.form.get('balance').disable();
    this.form.get('manualValue').disable();
    this.form.get('defaultValueSeleted').disable();
    this.form.get('totalAmount').setValue(0);
    this.form.get('balance').setValue(0);
    this.form.get('manualValue').setValue(null);
    this.form.get('defaultValueSeleted').setValue(null);
  }

  hideFieldByExternal() {
    this.isLitisSelected = false;
    this.isAdditionalSelected = false;
    this.isFiscalCreditSelected = false;
    this.isExternalSelected = true;
    this.form.get('manualValue').setValue(null);
    //hide
    this.form.get('manualValue').disable();

    this.form.get('manualValue').setValidators(null);
    this.form.get('manualValue').updateValueAndValidity();
    this.form.get('defaultValueSeleted').setValidators(null);
    this.form.get('defaultValueSeleted').updateValueAndValidity();
    this.form.get('defaultValueSeleted').disable()
    this.form.get('beneficiary').disable();
    this.form.get('defaultValueSeleted').setValue(null);
    //show
    this.form.get('amount').enable();
    this.form.get('amount').setValidators(Validators.required);
    this.form.get('amount').updateValueAndValidity();
    this.form.get('totalAmount').enable();
    this.form.get('totalAmount').setValidators(null);
    this.form.get('totalAmount').updateValueAndValidity();
    this.form.get('balance').enable();
    this.form.get('beneficiary').reset();
  }

  hideFieldByFiscalCredit() {
    this.isLitisSelected = false;
    this.isAdditionalSelected = false;
    this.isExternalSelected = false;
    this.isFiscalCreditSelected = true;
    this.form.get('manualValue').setValue(null);
    //hide
    this.form.get('manualValue').disable();

    this.form.get('manualValue').setValidators(null);
    this.form.get('manualValue').updateValueAndValidity();
    this.form.get('defaultValueSeleted').setValidators(null);
    this.form.get('defaultValueSeleted').updateValueAndValidity();
    this.form.get('defaultValueSeleted').disable()
    this.form.get('beneficiary').disable();
    this.form.get('amount').disable();
    this.form.get('amount').setValidators(null);
    this.form.get('amount').updateValueAndValidity();
    this.form.get('amount').setValue(null);
    this.form.get('defaultValueSeleted').setValue(null);
    this.form.get('balance').disable();
    //show
    
    this.form.get('totalAmount').enable();
    this.form.get('totalAmount').setValidators(Validators.required);
    this.form.get('totalAmount').updateValueAndValidity();
    this.form.get('beneficiary').reset();
  }


  hideFieldByAdditional() {
    this.isLitisSelected = false;
    this.isAdditionalSelected = true;
    this.isExternalSelected = false;
    this.isFiscalCreditSelected = false;
    //Hide
    this.form.get('amount').setValue(null);
    this.form.get('totalAmount').setValue(0);
    this.form.get('balance').setValue(0);
    this.form.get('amount').disable();
    this.form.get('amount').setValidators(null);
    this.form.get('amount').updateValueAndValidity();
    this.form.get('totalAmount').disable();
    this.form.get('totalAmount').setValidators(null);
    this.form.get('totalAmount').updateValueAndValidity();
    this.form.get('beneficiary').disable();
    this.form.get('balance').disable();
    //Show
    if(!this.conceptSetting?.newsConceptSettingId){
      this.form.get('manualValue').enable();
      this.form.get('defaultValueSeleted').enable();
      this.form.get('defaultValueSeleted').setValidators([Validators.required]);
      this.form.get('defaultValueSeleted').updateValueAndValidity();
      this.form.get('manualValue').setValidators([Validators.required]);
      this.form.get('manualValue').updateValueAndValidity();
    }
 
    this.form.get('beneficiary').reset();
  }

  
  hideManuelValue(){
    if(this.manualValue?.nativeElement){
      this.manualValue.nativeElement.style.display='none';
    }
    this.form.get('manualValue').setValue(null);
    this.form.get('manualValue').disable();
    this.form.get('manualValue').setValidators(null);
    this.form.get('manualValue').updateValueAndValidity();
  }

  showManuelValue(){
    if(this.manualValue?.nativeElement){
      this.manualValue.nativeElement.style.display='block';
    }
    
    this.form.get('manualValue').enable();
  }

  hideDefaultValue(){
    if(this.defaultValue?.nativeElement){
      this.defaultValue.nativeElement.style.display='none';
    }
  
    this.form.get('defaultValueSeleted').setValue(null);
    this.form.get('defaultValueSeleted').disable();
  }

  showDefaultValue(){
    if(this.defaultValue?.nativeElement){
      this.defaultValue.nativeElement.style.display='block';
    }
    this.form.get('defaultValueSeleted').enable();
  }

  onChangeDefaultValue(value: DefaultValue){
    this.form.get('defaultValue').setValue(value?.conceptValuesId);
  }

  showField() {
    this.isLitisSelected = false;
    this.isAdditionalSelected = false;
    this.isExternalSelected = false;
    this.form.get('amount').enable();
    this.form.get('balance').enable();
    this.form.get('totalAmount').enable();
    this.form.get('manualValue').enable();
    this.form.get('defaultValueSeleted').enable();
    this.form.get('balance').enable();
  }

  
  onChangeTypeDocument(e) {
    if (e.value.ocode == 1) {
      this.mask = "000-0000000-0"
      this.maskText = 'Ej: 001-000000-2'
    } else {
      this.mask = ''
      this.maskText = ''
    }
    this.form.patchValue({
      beneficiary: {
        identificationNumber: ''
      }
    })
  }

  get showErrors() {
    return {
      erorNewType: this.form.get('newsType').invalid,
      errorConcept: this.form.get('concept').invalid,
      errorAmount: this.form.get('amount').invalid,
      errorManualValue: this.form.get('manualValue').invalid,
      erorDefaultValue: this.form.get('defaultValueSeleted').invalid,
      errorStartDate: this.form.get('startDate').invalid,
      errorTotalAmount: this.form.get('totalAmount').invalid,
      errorsBeneficiary: {
        errorIdentificationNumber: this.form.get('beneficiary.identificationNumber').invalid,
        errorFirstName: this.form.get('beneficiary.firstName').invalid,
        errorLastName: this.form.get('beneficiary.lastName').invalid,
        errorTypeDocument: this.form.get('beneficiary.typeDocument').invalid,
        errorBank: this.form.get('beneficiary.bank').invalid,
        errorAccountType: this.checkIsSelected == true ? false : this.form.get('beneficiary.accountType').value < 1,
        errorAccountNumber: this.checkIsSelected == true ? false : this.form.get('beneficiary.accountNumber').value < 1,
        errorOfice: this.checkIsSelected == true ? false : this.form.get('beneficiary.office').value < 1,
        errorPaymentMethod: this.form.get('beneficiary.paymentMethod').invalid,
      }
    }
  }

  get isPdfextensionEvidence(){
    return this.evidenceImg?.includes('.pdf');
  }

}