import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CertificationCodeService } from 'src/app/services/certification-code.service';
import { CatalogResponseCertificationAccount } from '../models/certification-codes.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-delete-certification-code',
  templateUrl: './delete-certification-code.component.html',
  styleUrls: ['./delete-certification-code.component.css']
})
export class DeleteCertificationCodeComponent implements OnInit {
  form: FormGroup;
  formInvalid: boolean = false;
  
  constructor(private _toastService: ToastrService,
    public dialogRef: MatDialogRef<DeleteCertificationCodeComponent>,
    private fb: FormBuilder,
    private _changeDet: ChangeDetectorRef, private certificationCodeService: CertificationCodeService,
    private _authService: AuthInfoService, @Inject(MAT_DIALOG_DATA) public data: {
      catalogRespons: CatalogResponseCertificationAccount
    }) {
      this.form = this.fb.group({
        reason: [null, [Validators.required]]
      });
  }

  ngOnInit(): void {
  }

  Accept() {
    if (this.form.invalid) {
      this.formInvalid = true;
      return;
    }
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#ff0000',
      title: '¿Seguro que desea eliminar este registro?',
    }).then(res => {
      if(res.isConfirmed){
      const codeId = this.data?.catalogRespons?.catalogResponseCertificationAccountId;
      const reasonDelete = this.form?.value?.reason;
      const model = {
        catalogResponseCertificationAccountId: codeId,
        reasonDelete, userId: this._authService.getUserId()
      }
      this.certificationCodeService.deleteCertificationCodes(model).subscribe({
        next: res => {
          if(!res?.succeded){
            res.errors.forEach(error => {
              this._toastService.error(error);
            });
            res.warnings.forEach(warning => {
              this._toastService.warning(warning);
            });
            return;
          }
          this._toastService.success('Código de certificación eliminada correctamente');
          this.dialogRef.close({created: true});
        }, error: res => {
          this._toastService.error('Ha ocurrido un error tratando de eliminar el código de Certificación');
        }
       });
      }
    });
  }

}