<app-modal-layout (onClose)="close()" styles="min-height: 200px">
    <ng-container title>
        Razón de exclusión
    </ng-container>
    <ng-container body>
        <div>
            <form [formGroup]="form">
                <label>Escriba el motivo <span class="text-danger">*</span></label>
                <textarea (input)="removeWhitespaces($event, form.get('reason'))" class="form-control" name="" id="" cols="40" formControlName="reason" rows="5"></textarea>
                <span *ngIf="this.form.controls['reason'].errors?.required && formIsInvalid"  class="text-danger">Este campo es requerido</span>
            </form>
        </div>
    </ng-container>
<ng-container buttons>
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    
    <button (click)="excludeEmployee()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i> 
        <span>Guardar</span>
    </button>
</ng-container>
</app-modal-layout>