<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        {{data?.inEdit ? 'Editar' : data?.inDetails ? 'Detalle' : 'Agregar'}} Conceptos</h2>
        <button type="button" (click)="close()" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    </div>
    <div class="mat-dialog-content-custom">
        <div class="card-body-custom">
            <ul class="nav nav-tabs border-tabs-primary" role="tablist">

                <li class="nav-item">
                    <a class="nav-link active" id="general-tab" data-toggle="tab" href="#general" aria-controls="general">
                        <span class="nav-icon mr-5">
                            <i class="flaticon-departamento icon-3x"
                            [ngClass]="{'form-text text-danger': generalComponent?.formIsInvalid === true && generalComponent?.form?.invalid}"></i>
                        </span>
                        <span class="nav-text" [ngClass]="{'form-text text-danger': generalComponent?.formIsInvalid === true && generalComponent?.form?.invalid}">
                            <h3 >General</h3>
                        </span>
                    </a>
                </li>

                <li class="nav-item" *ngIf="isNewsTypeAdditionalSelected">
                    <a class="nav-link" id="aditional-tab" data-toggle="tab" href="#aditional" aria-controls="aditional">
                        <span class="nav-icon mr-5">
                            <i class="flaticon-departamento icon-3x" [ngClass]="{'text-danger': additionalSettingsComponent?.formIsInvalid === true
                            && additionalSettingsComponent?.form?.invalid}"></i>
                        </span>
                        <span class="nav-text"
                        [ngClass]="{'text-danger': additionalSettingsComponent?.formIsInvalid === true
                                    && additionalSettingsComponent?.form?.invalid}">
                            <h3>Adicional</h3>
                        </span>
                    </a>
                </li>

                <li class="nav-item" *ngIf="requiredValue && isNewsTypeAdditionalSelected">
                    <a class="nav-link" id="requiredValue-tab" data-toggle="tab" href="#requiredValue" aria-controls="requiredValue">
                        <span class="nav-icon mr-5">
                            <i class="flaticon-departamento icon-3x"></i>
                        </span>
                        <span class="nav-text">
                            <h3>Valores Predefinidos</h3>
                        </span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="filtros-tab" data-toggle="tab" href="#filtros" aria-controls="filtros">
                        <span class="nav-icon mr-5">
                            <i class="las la-filter icon-3x"></i>
                        </span>
                        <span class="nav-text">
                            <h3>Filtros</h3>
                        </span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="aplicacion-tab" data-toggle="tab" href="#aplicacion" aria-controls="aplicacion">
                        <span class="nav-icon mr-5">
                            <i class="las la-cog icon-3x"></i>
                        </span>
                        <span class="nav-text">
                            <h3>Aplicación</h3>
                        </span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="normativa-tab" data-toggle="tab" href="#normativa" aria-controls="normativa">
                        <span class="nav-icon mr-5">
                            <i class="flaticon-recursos-humanos-7 icon-3x"></i>
                        </span>
                        <span class="nav-text">
                            <h3>Normativa</h3>
                        </span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="grupo-conceptos-tab" data-toggle="tab" href="#grupo-conceptos" aria-controls="grupo-conceptos">
                        <span class="nav-icon mr-5">
                            <i class="flaticon-gestion-de-proyectos icon-3x"></i>
                        </span>
                        <span class="nav-text">
                            <h3>Grupo de Conceptos</h3>
                        </span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="formulas-tab" data-toggle="tab" href="#formulas" aria-controls="formulas">
                        <span class="nav-icon mr-5">
                            <i class="las la-file-invoice-dollar icon-3x"></i>
                        </span>
                        <span class="nav-text">
                            <h3>Fórmulas</h3>
                        </span>
                    </a>
                </li>
            </ul>
            <div class="tab-content mt-5">
                <div class="tab-pane fade active show" id="general" role="tabpanel" aria-labelledby="general-tab">
                    <general-tap [data]="data"
                    #generalComponent
                    (onNewsTypeAdditional)="changeNewsConceptsSetting($event)"
                    (onClose)="close()"
                    (onSuccess)="closeModal($event)"></general-tap>
                </div>
                <div class="tab-pane fade" *ngIf="isNewsTypeAdditionalSelected" id="aditional" role="tabpanel" aria-labelledby="aditional-tab">
                    <add-additional-settings
                    (onConceptValues)="conceptValues = $event"
                    (onRequiredValue)="requiredValue = $event"
                    [data]="data"
                    [newsConceptsSetting]="newsConceptsSetting"
                    #additionalSettingsComponent></add-additional-settings>
                </div>
                <div class="tab-pane fade" *ngIf="requiredValue && isNewsTypeAdditionalSelected" id="requiredValue" role="tabpanel" aria-labelledby="requiredValue-tab">
                    <app-default-value-settings
                    [data]="data"
                    [conceptValues]="conceptValues"
                    #defaultValueSettingsComponent></app-default-value-settings>
                </div>
                <div class="tab-pane fade" id="filtros" role="tabpanel" aria-labelledby="filtros-tab">
                    <filter-tap (onClose)="close()"></filter-tap>
                </div>
                <div class="tab-pane fade" id="aplicacion" role="tabpanel" aria-labelledby="aplicacion-tab">
                    <application-tap (onClose)="close()"></application-tap>
                </div>
                <div class="tab-pane fade" id="normativa" role="tabpanel" aria-labelledby="normativa-tab">
                <normative-tap (onClose)="close()"></normative-tap>
                </div>
                <div class="tab-pane fade" id="grupo-conceptos" role="tabpanel" aria-labelledby="grupo-conceptos-tab">
                    <concept-group-tap (onClose)="close()"></concept-group-tap>
                </div>
                <div class="tab-pane fade" id="formulas" role="tabpanel" aria-labelledby="formulas-tab">
                    <formula-tap (onClose)="close()"></formula-tap>
                </div>
            </div>
        </div>
    </div>
    <div class="mat-dialog-actions mt-2 p-0 mb-0 text-right" [align]="'end'">
        <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
        <button *ngIf="data.inDetails == false" (click)="submit()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
            <i class="flaticon-disco-flexible"></i>
            <span>Guardar</span>
        </button>
    </div>

   <!--  <div class="mat-dialog-actions"[align]="'end'">
        <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
        <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
            <i class="flaticon-disco-flexible"></i>
            <span>Guardar</span>
        </button>
    </div> -->
