<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    
    <div class="d-flex justify-content-between">
        <div>
            <h2  class="font-size-h5 text-white"><span
                class="h-20px label label-danger label-dot mr-3 w-20px"></span>{{data?.item ? 'Editar' : 'Agregar'}}
            Requisición</h2>
        </div>
        <div>
            <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
                <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
            </button>
            
            <button class="close" mat-icon-button tabindex="-1" (click)="this.maximizeDialog()">
                <i aria-hidden="true" class="ki ki-copy text-white font-size-h3"></i>
            </button>
            
            <button class="close" mat-icon-button tabindex="-1" (click)="this.minimizeDialog()">
                <i aria-hidden="true" class="ki ki-minus text-white font-size-h3"></i>
            </button>
        </div>
    </div>
    
    
</div>

<div [hidden]="!this.contentVisible">
<div class="mat-dialog-content-custom" >
    <div class="card-body-custom">
        <fieldset [disabled]='IsViewMode'>
            <div class="row">
                <div class="form-group col-md-4">
                    <div>
                        <label for="" class="d-block">Planificado <span class="font-italic"></span><span
                                class="text-danger text-monospace legend">*</span></label>
                    </div>
                    <div class="d-flex gender flex-wrap">
                        <div class="form-check form-check-inline">
                            <label class="form-check-label mt-2 check-dtl">
                                <input type="radio" class="form-check-input" (change)="changePlanificate()"
                                    name="isPlanned" [value]="true" [(ngModel)]="form.isPlanned" />
                                Sí
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label mt-2 check-dtl">
                                <input type="radio" class="form-check-input" (change)="changePlanificate()"
                                    name="isPlanned" [value]="false" [(ngModel)]="form.isPlanned" />
                                No
                            </label>
                        </div>
                    </div>
                    <div class="mt-3"></div>
                    <div class="mt-3"></div>
                </div>
                <div class="form-group col-md-4" *ngIf="form.isPlanned == true">
                    <label class="d-block">Período de Planificación <span
                            class="text-danger text-monospace legend">*</span></label>
                    <div class="dropdown-alt input-group">
                        <ngx-select-dropdown [options]="planning" [config]="dropdownConfig('yearExecution')"
                            [(ngModel)]="form.planning" (change)="changePlanningHeader($event.value)" class="w-100">
                        </ngx-select-dropdown>
                    </div>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showPlanningErrors()}}</span>
                </div>
                <!-- <div class="form-group col-md-4">
                    <label class="d-block">Recinto <span class="text-danger text-monospace legend">*</span></label>
                    <div class="dropdown-alt input-group">
                        <ngx-select-dropdown [options]="locations" [config]="dropdownConfig('socialReason')"
                            [(ngModel)]="form.enclosure" class="w-100"></ngx-select-dropdown>
                    </div>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showEnclosureIdErrors()}}</span>
                </div> -->
                <div class="form-group col-md-4">
                    <label class="d-block">Unidad Organizativa <span class="text-danger text-monospace legend">*</span></label>
                    <div class="input-group">
                        <input type="text" class="form-control form-control-solid form-control-lg"
                            placeholder="Escriba aquí su búsqueda" [(ngModel)]="form.department.departmentName"
                            readonly>
                        <button (click)="openSelectDepartmentModal(0,false)" class="input-group-text bg-primary">
                            <i class="flaticon2-search-1 icon-2x text-white"></i>
                        </button>
                    </div>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showDepartmentIdErrors()}}</span>
                </div>
                <div class="form-group col-md-4" *ngIf="!form?.isPlanned">
                    <label class="d-block">Cargo Solicitado <span
                            class="text-danger text-monospace legend">*</span></label>
                    <div class="dropdown-alt input-group">
                        <ngx-select-dropdown [options]="positions" [config]="dropdownConfig('positionName')"
                            [(ngModel)]="form.position" (change)="getEmployeesByPosition($event.value,null)" class="w-100">
                        </ngx-select-dropdown>
                    </div>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showPositionIdErrors()}}</span>
                </div>
                <div class="form-group col-md-4" *ngIf="form.isPlanned == true">
                    <label class="d-block">Cargo Solicitado <span
                            class="text-danger text-monospace legend">*</span></label>
                    <div class="dropdown-alt input-group">
                        <ngx-select-dropdown [options]="planningRequest" [config]="dropdownConfig('positionName')"
                            (change)="changePlanningRequest($event.value)" [(ngModel)]="form.planningRequest"
                            class="w-100">
                        </ngx-select-dropdown>
                    </div>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showPlanningRequestErrors()}}</span>
                </div>
                <div class="form-group col-md-4">
                    <div>
                        <label for="quantityPlaces">Cantidad de Plazas <span
                                class="text-danger text-monospace legend">*</span></label>
                    </div>
                    <input type="number" oninput="this.value=(parseInt(this.value)||0)" min="0"
                        class="form-control form-control-lg dynamicFormControl" id="quantityPlaces" placeholder="Ej: 5"
                        required="" [(ngModel)]="requisitionDTO.numberPlaces" />
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showNumberPlacesErrors()}}</span>

                </div>
                <div class="form-group col-md-4">
                    <label class="d-block">Categoría del Cargo <span
                            class="text-danger text-monospace legend">*</span></label>
                    <div class="dropdown-alt input-group">
                        <ngx-select-dropdown (change)="changeRequisitionType($event)" [options]="requisitionsType" [config]="dropdownConfig('stringData')"
                            [(ngModel)]="form.requisitionType" class="w-100"></ngx-select-dropdown>
                    </div>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showRequisitionTypeIdErrors()}}</span>
                    <span class="form-text text-danger d-none">Este campo es requerido</span>
                </div>

                <div *ngIf="beginningAndEndingDateRequired" class="form-group col-md-4">
                    <div>
                        <label for="dateRequest">Inicio Vigencia Contrato 
                            <span class="text-danger text-monospace legend">*</span></label>
                    </div>
                    <input (change)="changeContractDate()" class="form-control form-control-lg dynamicFormControl" 
                    type="date" [(ngModel)]="form.contractAdmissionDate" />
                    <span class="form-text text-danger">{{contractAdmissionDateError}}</span>
                </div>
                
                <div *ngIf="beginningAndEndingDateRequired" class="form-group col-md-4">
                    <div>
                        <label for="dateRequest">Fin Vigencia Contrato 
                            <span class="text-danger text-monospace legend">*</span></label>
                    </div>
                    <input (change)="changeContractDate()" class="form-control form-control-lg dynamicFormControl" 
                    type="date" [(ngModel)]="form.contractEndingDate" />
                    <span class="form-text text-danger">{{contractDateError}}</span>
                </div>
                <div class="form-group col-md-4">
                    <div>
                        <label for="minAgeRange">Edad Mínima <span
                                class="text-danger text-monospace legend">*</span></label>
                    </div>

                    <input type="number" min="0" oninput="this.value=(parseInt(this.value)||0)" [max]="99"
                        class="form-control form-control-lg dynamicFormControl" id="minAgeRange" required=""
                        (keyup)="limiteMaxNumber('min', requisitionDTO.minimumAge)"
                        [(ngModel)]="requisitionDTO.minimumAge" />
                    <span class="form-text text-danger">{{ageError}}</span>
                    
                </div>

                <div class="form-group col-md-4">
                    <div>
                        <label for="maxAgeRange">Edad Máxima <span
                                class="text-danger text-monospace legend">*</span> </label>
                    </div>
                    <input type="number" min="0" oninput="this.value=(parseInt(this.value)||0)" [max]="99"
                        class="form-control form-control-lg dynamicFormControl" id="maxAgeRange" placeholder="Ej: 55"
                        (keyup)="limiteMaxNumber('max', requisitionDTO.maximumAge)" required=""
                        [(ngModel)]="requisitionDTO.maximumAge" />
                        <span class="form-text text-danger">{{maxAgeError}}</span>
                </div>

                <div class="form-group col-md-4">
                    <!--div><label for="yearsOfExperience">Años de Experiencia </label></div>
                    <input oninput="this.value=(parseInt(this.value)||0)" type="number" min="0" class="form-control form-control-lg dynamicFormControl" id="yearsOfExperience"
                        placeholder="Ej: 2" [(ngModel)]="requisitionDTO.experience" /-->

                    <label class="d-block">Experiencia</label>
                    <div class="dropdown-alt input-group">
                        <ngx-select-dropdown [options]="experience" [config]="dropdownConfig('stringData')"
                            [(ngModel)]="requisitionDTO.experiences" class="w-100"></ngx-select-dropdown>
                    </div>
                </div>

                <div class="form-group col-md-4">
                    <label class="d-block">Cantidad de Horas a Trabajar <span
                            class="text-danger text-monospace legend">*</span></label>
                    <div class="dropdown-alt input-group">
                        <ngx-select-dropdown [options]="workingHours" [config]="dropdownConfig('stringData')"
                            [(ngModel)]="form.workingHours" class="w-100"></ngx-select-dropdown>
                    </div>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showWorkingHoursIdErrors()}}</span>
                </div>

                <div class="form-group col-md-4">
                    <div>
                        <label for="" class="d-block">Sexo <span class="font-italic"></span><span
                                class="text-danger text-monospace legend">*</span></label>
                    </div>
                    <div class="d-flex gender flex-wrap">
                        <div class="form-check form-check-inline" *ngFor="let sex of sexo">
                            <label class="form-check-label mt-2 check-dtl">
                                <input type="radio" class="form-check-input" name="sex" [value]="sex.ocode" required=""
                                    [(ngModel)]="requisitionDTO.sexId" />
                                {{sex.stringData}}
                            </label>
                        </div>
                    </div>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showSexIdErrors()}}</span>
                    <div class="mt-3"></div>
                    <div class="mt-3"></div>
                </div>

                <div class="form-group col-md-4">
                    <label class="d-block">Nivel Académico Deseado <span
                            class="text-danger text-monospace legend">*</span></label>
                    <div class="dropdown-alt input-group">
                        <ngx-select-dropdown [options]="academicLevels" [config]="dropdownConfig('stringData')"
                            [(ngModel)]="form.academicLevel" class="w-100"></ngx-select-dropdown>
                    </div>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showAcademicLevelIdErrors()}}</span>
                </div>

                <div class="form-group col-md-4">
                    <div>
                        <label for="dateRequest">Fecha de Solicitud <span
                                class="text-danger text-monospace legend">*</span></label>
                    </div>
                    <input class="form-control form-control-lg dynamicFormControl" id="dateRequest"
                        placeholder="Fecha de Solicitud" type="date" [(ngModel)]="requisitionDTO.requestedDate" />
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showRequestedDateErrors()}}</span>
                </div>

                <div class="form-group col-md-4">
                    <div>
                        <label for="dateAdmision">Fecha de Ingreso <span
                                class="text-danger text-monospace legend">*</span></label>
                    </div>
                    <input class="form-control form-control-lg dynamicFormControl" id="dateAdmision"
                        placeholder="Fecha de ingreso" type="date" [(ngModel)]="requisitionDTO.applicationDate" />
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showApplicationDateErrors()}}</span>
                </div>

                <div class="form-group col-md-4">
                    <label class="d-block">Causa de la Vacante <span class="text-danger text-monospace legend">*</span>
                    </label>
                    <div class="dropdown-alt input-group">
                        <ngx-select-dropdown [options]="requisitionsCause"
                            (change)="onVacancyCauseSelected($event.value)" [config]="dropdownConfig('stringData')"
                            [(ngModel)]="form.vacancyCause" class="w-100"></ngx-select-dropdown>
                    </div>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showVacancyCauseIdErrors()}}</span>
                    <span class="form-text text-danger d-none">Este campo es requerido</span>
                </div>

                <div class="form-group col-md-3" *ngIf="form?.vacancyCause?.stringData == 'Creación Cargo' ">
                    <div>
                        <label for="dateRequest">Causa de la Creación</label>
                    </div>
                    <input class="form-control form-control-lg dynamicFormControl"
                        [(ngModel)]="requisitionDTO.creationCause" id="dateRequest" placeholder="Causa de la creación"
                        type="text" />
                </div>

                <div *ngIf="recruitmentMethodRequired" class="form-group col-md-3">
                    <div>
                        <label for="dateRequest">Método de Reclutamiento <span
                                class="text-danger text-monospace legend">*</span></label>
                    </div>
                    <ngx-select-dropdown [options]="concursaParameter" [config]="dropdownConfig('stringData')"
                        [(ngModel)]="form.concursaParameter" class="w-100"></ngx-select-dropdown>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showConcursaParameterErrors()}}</span>
                </div>
            </div>

            <div class="separator border-solid mt-4 mb-8"></div>

            <div class="row">
                <div class="col">
                    <button [disabled]="!isSustitution" data-toggle="modal"
                        class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 px-10 mb-3 col-md-3"
                        (click)="openInSubsitutionModal()">
                        <i class="flaticon-reclutamiento-1 icon-2x fs-2 me-2"></i>
                        En Sustitución de
                    </button>
                </div>
                <div class="pb-5">

                    <div class="table-responsive">
                        <table class="table mt-2 table-vertical-center text-center">
                            <thead>
                                <tr class="bg-primary">
                                    <th class="text-left col-md-3">Código</th>
                                    <th class="text-left">Servidor Público</th>
                                    <th class="text-left">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let replacement of replacementsList">
                                    <td class="text-left">{{replacement.employNumber}}</td>
                                    <td class="text-left">{{replacement.name}}</td>
                                    <td><a (click)="deleteReplacing(replacement)"
                                            class="btn btn-icon btn-light-danger btn-sm m-1"><i class="flaticon-delete"
                                                data-toggle="tooltip" data-original-title="Borrar"></i></a></td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <div class="separator border-solid mt-6 mb-8"></div>

            <div class="row">
                <div class="form-group col-md-4">
                    <div>
                        <label for="exampleFormControlTextarea1" class="d-block">Requerimientos Deseables
                        </label>
                    </div>
                    <textarea class="form-control form-control-lg dynamicFormControl"
                        placeholder="(Competencias Técnicas)" id="desirableRequirements" rows="4"
                        [(ngModel)]="requisitionDTO.desirableRequirements"></textarea>
                </div>

                <div class="form-group col-md-4">
                    <div>
                        <label for="exampleFormControlTextarea1" class="d-block">Observaciones
                        </label>
                    </div>
                    <textarea class="form-control form-control-lg dynamicFormControl" placeholder="" id="observations"
                        rows="4" [(ngModel)]="requisitionDTO.observations"></textarea>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showObservationsErrors()}}</span>
                </div>

                <div class="form-group col-md-4">
                    <div>
                        <label for="exampleFormControlTextarea1" class="d-block">Otros Requisitos
                        </label>
                    </div>
                    <textarea class="form-control form-control-lg dynamicFormControl" id="otherRequirement" rows="4"
                        [(ngModel)]="requisitionDTO.otherRequirements"></textarea>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showOtherRequirementsErrors()}}</span>

                </div>

                <div class="form-group col-md-12">
                    <div>
                        <label for="exampleFormControlTextarea1" class="d-block">
                            Funciones o Tareas
                        </label>
                    </div>
                    <textarea class="form-control form-control-lg dynamicFormControl" id="functionDescription" rows="4"
                        required="" [(ngModel)]="requisitionDTO.generalDescription"></textarea>
                    <span class="form-text text-danger" *ngIf="formIsInvalid">{{showGeneralDescriptionErrors()}}</span>

                </div>
            </div>
        </fieldset>
    </div>
</div>
<div class="mat-dialog-actions" align="end">
    <!-- <button *ngIf="data.item !== null && !IsViewMode"
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-primary mr-2" type="button"
        (click)="Approved()">
        <i class="flaticon-check icon"></i>
        <span> Aprobar </span>
    </button> -->
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="!IsViewMode" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
        (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>
</div>

<el-modal size="md" title="En Sustitución de" target="sustitucion">
    <div class="card-body customHeight">
        <div class="row">
            <ngx-select-dropdown [options]="substitutionEmployees" [config]='dropdownConfig("name")' [multiple]="true"
                [(ngModel)]="replacements"></ngx-select-dropdown>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button"
            class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2"
            data-dismiss="modal">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
        <button [disabled]="replacements.length == 0" (click)="fillReplacingList()" data-dismiss="modal" type="button"
            class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success">
            <i class="flaticon2-plus"></i>
            Agregar
        </button>
    </div>
</el-modal>

