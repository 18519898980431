import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-remission-no-objection',
  templateUrl: './remission-no-objection.component.html',
  styleUrls: ['./remission-no-objection.component.css']
})
export class RemissionNoObjectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  

}
