<div class="card load-movement">
    <div class="card-header p-3">
        <ul class="nav nav-primary nav-pills text-uppercase" id="myTab2" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="cmanual-tab-2" data-toggle="tab" href="#cmanual">
                    <span class="nav-icon mr-10">
                        <i class="flaticon-productividad icon-3x"></i>
                    </span>
                    <span class="nav-text font-size-h5">Carga Manual</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="cmasiva-tab-2" data-toggle="tab" href="#cmasiva" aria-controls="cmasiva">
                    <span class="nav-icon mr-10">
                        <i class="flaticon-agil icon-3x"></i>
                    </span>
                    <span class="nav-text font-size-h5">Carga Masiva</span>
                </a>
            </li>
        </ul>
    </div>
    <div class="card-body">
        <div class="tab-content" id="myTabContent2">
            <div class="tab-pane fade active show" id="cmanual" role="tabpanel" aria-labelledby="cmanual-tab-2">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <h3>Conceptos de Pago</h3>
                            </div>
                            <div class="card-body p-2">
                                <table class="table table-bordered table-vertical-center">
                                    <thead class="bg-primary">
                                        <th><span>Concepto</span></th>
                                        <th><span>Cantidad</span></th>
                                        <th><span>Monto</span></th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Sueldo Básico</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Horas Normales</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Horas Extras</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Días Feriados</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Vacaciones </strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Licencias</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Bono Transporte</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Bono Desmp. Individual </strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Bono Desmp. Colectivo</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Bono Nacim. Hijo</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Bono Escolar Dependiente</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Bono Capacitación</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>                                                
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Otros Ingresos</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <h3>Conceptos de Descuento</h3>
                            </div>
                            <div class="card-body p-2">
                                <table class="table table-bordered">
                                    <thead class="bg-primary">
                                        <th><span>Concepto</span></th>
                                        <th><span>Cantidad</span></th>
                                        <th><span>Monto</span></th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">ARS</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">ARL</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">SFS</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">ISR</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Cooperativa</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Cafetería</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Transporte</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Gimnasio</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Consumo Tiendas</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Seg. Complementario </strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Inavi </strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Servidor Público feliz </strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="switch switch-sm switch-outline switch-icon switch-success">
                                                    <label class="d-flex align-items-center">
                                                        <input type="checkbox" name="">
                                                        <span></span>
                                                       <strong class="text-primary ml-3">Seguro de vida</strong> 
                                                    </label>
                                                </span>
                                            </td>
                                            <td><input type="text" class="form-control" placeholder=""></td>
                                            <td><input type="text" class="form-control" placeholder="$"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-end mt-3">       
                    <button class="btn btn-pill font-weight-bolder btn-shadow btn-warning d-flex mr-3" data-dismiss="modal" ><i class="flaticon2-left-arrow-1"></i><span> Volver </span></button>
                    <button class="btn btn-pill font-weight-bolder btn-shadow btn-success d-flex mr-3" data-dismiss="modal" ><i class="flaticon-disco-flexible"></i><span> Guardar </span></button>
                </div>
            </div>
            <div class="tab-pane fade" id="cmasiva" role="tabpanel" aria-labelledby="cmasiva-tab-2">
                <div class="row">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" id="fileMasive" lang="es">
                        <label class="custom-file-label" for="fileMasive">Seleccionar Archivo</label>
                      </div>
                </div>
                <div class="row justify-content-end mt-3">       
                    <button class="btn btn-pill font-weight-bolder btn-shadow btn-warning d-flex mr-3" data-dismiss="modal" ><i class="flaticon2-left-arrow-1"></i><span> Volver </span></button>
                    <button class="btn btn-pill font-weight-bolder btn-shadow btn-success d-flex mr-3" data-dismiss="modal" ><i class="flaticon-disco-flexible"></i><span> Guardar </span></button>
                </div>
            </div>
        </div>
    </div>
</div>