import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { forkJoin } from 'rxjs';
import { Content } from 'src/app/models/content';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ContentManagementService } from 'src/app/services/content-management.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { docService } from 'src/app/services/doc.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DepartmentService } from 'src/app/services/department.service';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };
@Component({
  selector: 'add-content-modal',
  templateUrl: './add-content-modal.component.html',
  styleUrls: ['./add-content-modal.component.css']
})
export class AddContentModalComponent implements OnInit {

  titleModal = "Agregar Contenido";
  contentForm: FormGroup = new FormGroup({
    postType: new FormControl('', Validators.required),
    origin: new FormControl('', Validators.required),
    date: new FormControl('', Validators.required),
    postClassification: new FormControl(null),
    directed: new FormControl(null),
    postTitle: new FormControl('', Validators.required),
    link: new FormControl(''),
    allowComment: new FormControl(false, Validators.required),
    allowShared: new FormControl(false, Validators.required),
    isActivity: new FormControl(false, Validators.required),
    condition: new FormControl('', Validators.required),
    postText: new FormControl(null),
    mediaFile: new FormControl(null),
    isRecurring: new FormControl(false),
    monday: new FormControl(null),
    tuesday: new FormControl(null),
    wednesday: new FormControl(null),
    thursday: new FormControl(null),
    friday: new FormControl(null),
    saturday: new FormControl(null),
    sunday: new FormControl(null),
    finalDate: new FormControl(null),
  })
  newtyTypes = [];

  contentDTO: Content = new Content()

  showIsRecurring: boolean = false;

  postClassification: any[] = []
  postTypes: any[] = []
  conditions: any[] = []
  origins: any[] = []
  demographics: any[] = []
  datePipe = new DatePipe('en-Us')
  mediaType: string = ''
  departments: any[] = []

  checkIsSelected: boolean = undefined;

  IsViewMode = false;

  readonly urlDoc = environment.api_doc + '/Document';
  guiID = '';
  personalFile = personalFile;
  SystemLoad: number = 1;
  ModuleLoad: number = 1;
  routeFile: string = "";

  previewDefaultImage: string = '';

  @ViewChild('uppyFile')
  uppyFile: UppyFileComponent | null = null;
  constructor(
    public dialogRef: MatDialogRef<AddContentModalComponent>,
    private _contenManagementService: ContentManagementService,
    private _toastService: ToastService,
    private _authInfo: AuthInfoService,
    private srvParameter: ParameterControlService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private srvDoc: docService,
    private _departmentService: DepartmentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { dialogRef.disableClose = true }

  public file = {
    url: '',
    guid: ''
  }

  allowedFileTypes: string[] = [];

  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Click para desplegar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }


  ngOnInit(): void {

    if (this.data.isEdit) {
      this.titleModal = "Editar Contenido";
    }

    if (this.data.IsView) {
      this.titleModal = "Ver Contenido";
    }

    this.getData()
    if (this.data.IsView && !this.data.isEdit) {
      this.contentForm.disable()
    }
  }

  changeIsRecurring(): void{
    const isRecurring: boolean = this.contentForm?.get('isRecurring')?.value;
    this.showIsRecurring = isRecurring;
  }

  changeDetector() {
    this.cdr.detectChanges();
  }

  onChangePostType(postType) {
    this.setUppyOptions(postType.ocode)

    switch (postType.ocode) {
      case 1:
        this.contentForm.get('link').enable()
        this.contentForm.get('link').setValidators([Validators.required, Validators.pattern(/(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/)])
        this.contentForm.get('link').updateValueAndValidity()
        this.contentDTO.postVideo = this.guiID;
        this.previewDefaultImage = 'assets/images/ver_file.jpg';
        this.contentForm.get('postClassification').setValidators(Validators.required)
        this.contentForm.get('postClassification').updateValueAndValidity()
        this.contentForm.get('directed').setValidators(Validators.required)
        this.contentForm.get('directed').updateValueAndValidity()
        this.contentForm.get('isActivity').enable()
        break;
      case 2:
        this.contentForm.get('link').disable()
        this.contentDTO.postImage = this.guiID
        this.contentForm.get('mediaFile').setValidators(Validators.required)
        this.contentForm.get('mediaFile').updateValueAndValidity()

        this.contentForm.get('postClassification').setValidators(Validators.required)
        this.contentForm.get('postClassification').updateValueAndValidity()
        this.contentForm.get('directed').setValidators(Validators.required)
        this.contentForm.get('directed').updateValueAndValidity()
        this.contentForm.get('isActivity').enable()
        break;
      case 3:
        this.previewDefaultImage = 'assets/images/PDF.png';

        this.contentForm.get('link').disable()
        //this.contentForm.get('allowComment').disable()
        this.contentForm.get('allowShared').disable()
        this.contentForm.get('isActivity').disable()
        //  this.contentForm.get('postClassification').disable()
        this.contentForm.get('postClassification').setValue(null)
        //  this.contentForm.get('directed').disable()
        this.contentForm.get('directed').setValue(null)
        this.contentForm.get('mediaFile').setValidators(Validators.required)
        this.contentForm.get('mediaFile').updateValueAndValidity()
        this.contentDTO.postVideo = null
        this.contentDTO.postImage = null
        break;

      default:
        break;
    }
  }

  getData() {
    forkJoin([
      this._contenManagementService.getPostClassification(),
      this._contenManagementService.getPostTypes(),
      this._contenManagementService.getConditions(),
      this._departmentService.getAll(),
      this._contenManagementService.getDemographics(),
    ]).subscribe((responses) => {
      if (responses[0].succeded &&
        responses[1].succeded &&
        responses[2].succeded &&
        responses[3].succeded &&
        responses[4].succeded) {
        this.postClassification = responses[0].dataList
        this.postTypes = responses[1].dataList
        this.conditions = responses[2].dataList
        this.departments = responses[3].dataList
        this.demographics = responses[4].dataList
        if (this.data.isEdit || this.data.IsView) {
          this.getContentData()
        }
      }
    });

  }

  getGuidName(guid: string): GUID {
    return guid as GUID;
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  setUppyOptions(postType) {
    this.allowedFileTypes =
      postType == 1 && ['video/*'] ||
      postType == 2 && ['image/*'] ||
      postType == 3 && ['application/pdf']
  }

  getContentData() {
    let selectedContent = this.data.item
    this.contentDTO.newsId = selectedContent.newsId
    this.contentForm.setValue({
      postType: this.postTypes.find((postType: any) => postType.ocode == selectedContent.postType) ?? 0,
      origin: this.departments.find((department: any) => department.departmentId == selectedContent.originId) ?? 0,
      date: this.datePipe.transform(selectedContent.date, 'yyyy-MM-dd'),
      postClassification: this.postClassification.find((classification: any) => classification.ocode == selectedContent.publicationClassificationId) ?? 0,
      directed: this.demographics.find((demographic: any) => demographic.ocode == selectedContent.directedId) ?? 0,
      postTitle: selectedContent.postTitle,
      link: selectedContent.link,
      allowComment: selectedContent.allowComment,
      allowShared: selectedContent.allowShared,
      isActivity: selectedContent.isActivity,
      mediaFile: selectedContent.mediaFile,
      condition: this.conditions.find((condition: any) => condition.ocode == selectedContent.conditionId) ?? 0,
      postText: selectedContent.postText,
      isRecurring: selectedContent.isRecurring,
      monday: selectedContent.monday,
      tuesday: selectedContent.tuesday,
      wednesday: selectedContent.wednesday,
      thursday: selectedContent.thursday,
      friday: selectedContent.friday,
      saturday: selectedContent.saturday,
      sunday: selectedContent.sunday,
      finalDate: this.datePipe.transform(selectedContent.finalDate, 'yyyy-MM-dd')
    })

    this.changeIsRecurring();

    if (selectedContent.postType === 1) {
      this.previewDefaultImage = 'assets/images/ver_file.jpg';
      this.mediaType = 'video'
      this.guiID = selectedContent.postVideo;
    }

    else if (selectedContent.postType === 2) {
      this.mediaType = 'image'
      this.guiID = selectedContent.postImage;
    }

    else if (selectedContent.postType === 3) {
      this.guiID = selectedContent.mediaFile;
      this.previewDefaultImage = 'assets/images/PDF.png';
      this.mediaType = 'application/pdf'
      //  this.contentForm.get('postClassification').disable()
      this.contentForm.get('directed').disable()
      // this.contentForm.get('allowComment').disable()
      this.contentForm.get('allowShared').disable()
      this.contentForm.get('isActivity').disable()

    }
    this.setUppyOptions(selectedContent.postType)
  }

  submitContent() {

    if (this.contentForm.invalid) {
      this._toastService.warning('Complete todo los campos obligatorios')
      return
    }

    this.uppyFile.handleFile(guid => {
      const postType = this.contentForm.get('postType').value?.ocode;
      this.contentDTO.postVideo = null;
      this.contentDTO.postImage = null;
      this.contentDTO.mediaFile = null;

      if (postType === 1) {
        this.contentDTO.postVideo = guid;
      }
      if (postType === 2) {
        this.contentDTO.postImage = guid
      }
      if (postType === 3) {
        this.contentDTO.mediaFile = guid;
      }

      this.contentDTO = {
        ...this.contentDTO,
        postType: postType,
        originId: this.contentForm.get('origin').value?.departmentId,
        date: this.contentForm.get('date').value,
        publicationClassificationId: this.contentForm.get('postClassification').value?.ocode,
        employeeId: this._authInfo.getEmployeeId(),
        IsActivity: this.contentForm.get('isActivity').value,
        postTitle: this.contentForm.get('postTitle').value,
        link: this.contentForm.get('link').value,
        allowComment: this.contentForm.get('allowComment').value,
        allowShared: this.contentForm.get('allowShared').value,
        conditionId: this.contentForm.get('condition').value?.ocode,
        postText: this.contentForm.get('postText').value,
        directedId: this.contentForm.get('directed').value?.ocode,
        postGalleries: [],
        linkCode: this.youTubeGetID(this.contentForm.get('link').value),
        isRecurring: this.contentForm?.get('isRecurring')?.value,
        monday: this.contentForm?.get('monday')?.value,
        tuesday: this.contentForm?.get('tuesday')?.value,
        wednesday: this.contentForm?.get('wednesday')?.value,
        thursday: this.contentForm?.get('thursday')?.value,
        friday: this.contentForm?.get('friday')?.value,
        saturday: this.contentForm?.get('saturday')?.value,
        sunday: this.contentForm?.get('sunday')?.value,
        finalDate: this.contentForm?.get('finalDate')?.value,
        status: true,
        companyId: this._authInfo.getCompanyId(),
        createUserId: this._authInfo.getUserId()
      }

      if (this.contentDTO.newsId) {
        this.updateContent()
      } else {
        this.createNewContent()
      }
    });

  }

  youTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }

  createNewContent() {
    this._contenManagementService.createContent(this.contentDTO).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) {
          this._toastService.error("Error al tratar de crear el contenido")
          return;
        }
        this._toastService.success("Contenido agregado satisfactoriamente")
        this.closeModal(true)
      },
      error: (err: any) => this._toastService.error('Sucedio un error tratando de crear el contenido')
    })
  }

  updateContent() {
    this._contenManagementService.updateContent(this.contentDTO).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) {
          this._toastService.error("Error al tratar de crear el contenido")
          return;
        }
        this._toastService.success("Contenido agregado satisfactoriamente")
        this.closeModal(true)
      },
      error: (err: any) => this._toastService.error('Sucedio un error tratando de crear el contenido')
    })
  }

  closeModal(created: boolean) {
    this.dialogRef.close(created)
  }

  setFile(event) {
    const postType = this.contentForm.get('postType').value?.ocode;

    if (postType === 1) {
      this.contentForm.get('postVideo')?.setValue(event);
    }
    if (postType === 2) {
      this.contentForm.get('postImage')?.setValue(event);
    }
    if (postType === 3) {
      this.contentForm.get('mediaFile')?.setValue(event);
    }
  }

}
