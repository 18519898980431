<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="fs-3 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px "></span> Rango salarial
    </h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>

<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <div>
            <h3 data-dismiss="modal" (click)="selectSalaryRange(postulationSelected?.minSalary)"> <span role="button"
                    class="badge  bg-light text-dark">Salario mínimo: {{postulationSelected?.minSalary |currency }}</span>
            </h3>
            <h3 data-dismiss="modal" (click)="selectSalaryRange(postulationSelected?.halfSalary)"> <span role="button"
                    class="badge  bg-light text-dark">Salario medio: {{postulationSelected?.halfSalary |currency }}</span>
            </h3>
            <h3 data-dismiss="modal" (click)="selectSalaryRange(postulationSelected?.maxSalary)"> <span role="button"
                    class="badge  bg-light text-dark">Salario máximo: {{postulationSelected?.maxSalary |currency }}</span>
            </h3>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2"
    (click)="this.dialogRef.close();" type="button">
    <i class="flaticon2-cross"></i>
    <span>Cerrar</span>
</button>
</div>