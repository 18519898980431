import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CollectiveVacationsService } from 'src/app/services/collective-vacations.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { FilterEvent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { ICompany } from 'src/app/shared/models/company';
import { PagedResult } from 'src/app/shared/models/page-result';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { AddCollectiveVacationsComponent } from '../consult-collective-vacations/add-collective-vacations/add-collective-vacations.component';
// import { AddCollectiveVacationsComponent } from '../consult-collective-vacations/add-collective-vacations/add-collective-vacations.component';
import { PublicServantCollectiveVacationModalComponent } from './public-servant-collective-vacation-modal/public-servant-collective-vacation-modal.component';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
declare function applySort(): any;

@Component({
  selector: 'collective-vacations',
  templateUrl: './collective-vacations.component.html',
  styleUrls: ['./collective-vacations.component.css']
})
export class CollectiveVacationsComponent implements OnInit, OnDestroy {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'COLLECTIVE-VACATIONS',
    view: { key: 'View', value: false },
    add: { key: 'Add', value: false },
    edit: { key: 'Edit', value: false },
    manage: { key: 'Manage', value: false },
    apply: { key: 'Apply', value: false },
    export: { key: 'Export', value: false },
    search: { key: 'SEARCH', value: false}
  }
  localStorageSearch:string = 'collective-vacations';
  subscription: Subscription = new Subscription();
  vacationsPagedResut = new PagedResult<any>();
  searchParameter: FilterEvent;
  reportUrl = environment.reportUrl

  sweetAlrt = new SweetAlert
  isLoading: boolean = true;
  vacationsList = []
  originalVacationsList = []
  paginatedVacationsList = []

  filterText: string = '';
  postulationId: number;
  selectedItems: any;

  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };

  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Secuencia" },
      { propkeyId: 2, displayName: "Cantidad de Colaboradores" },
      { propkeyId: 3, displayName: "Año al que pertenece" },
      { propkeyId: 4, displayName: "Total de Días" },
    ];

  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' }
  ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = this.filterTable(event.records)
  }

  useCollectiveVacations: boolean = false;

  constructor(
    private _exlService: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    public authInfo: AuthInfoService,
    private _collectiveVacationsService: CollectiveVacationsService,
    private _toastService: ToastService,
    private accountService: AccountService,
    private _companiesService: CompaniesService,
  ) {
  }

  ngOnInit(): void {
    this.canUseCollectiveVacations();
    applySort();
  }

  private canUseCollectiveVacations(){
    this.subscription = this.accountService.getAccountSubject().subscribe({
      next: res => {
        this.useCollectiveVacations = res?.useCollectiveVacations;
        if (!this.useCollectiveVacations) {
          this._toastService.warning('No es posible acceder a esta pantalla porque la institución no utiliza vacaciones colectivas');
          return;
        }else{
          this.verifyPrivilege();
        }
      }
    });
  }

  private verifyPrivilege(){
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.privilege = this.authInfo.setPrivileges(this.privilege);
        this.getData();
      }
    });
  }

  getData() {
    this.getVacationsList()
  }

  getVacationsList() {
    if (!this.useCollectiveVacations) {
      return
    }
    const page = this.vacationsPagedResut.page
    const pageSize = this.vacationsPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    this._collectiveVacationsService.getPaginatedCollectiveVacations(page, pageSize, propKeyId, text).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al obtener la lista de vacaciones colectivas"); return; }
        this.originalVacationsList = res.singleData.items
        this.vacationsList = res.singleData.items
        this.vacationsPagedResut = res.singleData
      },
      error: (err: any) => {
        console.log(err);
        this._toastService.error('Ha ocurrido un error al obtener la lista de vacaciones colectivas')
      }
    })
  }

  filterSearch(event: FilterEvent) {
    this.vacationsPagedResut.page = 1
    this.searchParameter = event
    this.getVacationsList()
  }


  openModalAddVacations(isView: boolean = false, isEdit: boolean = false, vacation?: any,) {
    this.dialog.open(AddCollectiveVacationsComponent, {
      width: '40%',
      data: {
        vacation,
        isView,
        isEdit
      },
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          this.getVacationsList()
        }
      },
      error: (err: any) => {

      },
    })

  }

  getRouterToBack() {
    return '/collective-actions'

  }
  openVacationDetail(vacation) {
    this.dialog.open(PublicServantCollectiveVacationModalComponent, {
      width: '100%',
      data: {
        vacation
      }
    })

  }
  applyVacation(vacation) {
    let data = {
      collectiveVacationsHeaderId: vacation.collectiveVacationsHeaderId,
      startDate: vacation.startDate,
      endDate: vacation.endDate,
      comment: vacation.comment,
      totalDays: vacation.totalDays,
      year: vacation.year,
      conditionId: vacation.conditionId,
      status: vacation.status,
      modifyUserId: this.authInfo.getUserId(),
      companyId: this.authInfo.getCompanyId(),
    }

    this.sweetAlrt.AlertConfirm("Aplicar Vacaciones Colectivas", "¿Está seguro que desea aplicar estas Vacaciones Colectivas?", "question").then(res => {
      if (res) {
        this._collectiveVacationsService.applyCollectiveVacation(data).subscribe({
          next: (res: any) => {
            if (res.errors.length > 0) {
              this._toastService.success('Ocurrió un problema tratando de aplicar esta vacación')
              return
            }
            this._toastService.success('Vacación aplicada satisfactoriamente')
            this.getVacationsList()
          },
          error: (err: any) => {
            this._toastService.success('Ocurrió un problema tratando de aplicar esta vacación')
          },
        })
      } else {

      }
    })
  }

  onKeyChange(text) {
    this.filterText = text;
  }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.vacationsList = this.filterTable(this.originalVacationsList)
    }
    this.setFilterToLocalStorage();
  }
  setFilterToLocalStorage() {
    localStorage.setItem('budget-areas_filter', JSON.stringify(this.selectedItems));
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl?.find(x => x.item_id == 1) && !sl?.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl?.find(x => x.item_id == 2) && !sl?.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    return list;
  }



  getPaginatedRecords(event: PaginationEvent) {
    if (this.vacationsPagedResut.page == event.selectedPage && this.vacationsPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.vacationsPagedResut.page = event.selectedPage;
    this.vacationsPagedResut.pageSize = event.registersPerPage;
    this.getVacationsList()
  }
  exportToExcel() {
    let data = this.vacationsList
    data.forEach(element => {
      element.startDateText = formatDate(element.startDate, 'yyyy-MM-dd', 'en')
      element.endDateText = formatDate(element.endDate, 'yyyy-MM-dd', 'en')
    });
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Cantidad de Colaboradores",
      "Fecha Inicio",
      "Fecha Término",
      "Años al que pertenece",
      "Total Días",
      "Comentario",
      "Condición"
    ],
    [
      "collectiveVacationsHeaderId",
      "quantityEmployees",
      "startDateText",
      "endDateText",
      "year",
      "totalDays",
      "comment",
      "condition"
    ]]
    this._exlService.exportToExcelSpecificColumns(this.filteredList.enable ? this.filteredList.records : this.vacationsList, excelHeaders, 'Lista de Vacaciones Colectivas', true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }


  openPdf() {

    let url = ''
    const propKeyId = this.searchParameter?.propkeyId ?? 0
    const companyId = this.authInfo.getCompanyId()
    const searchParameter = this.searchParameter?.value
    const { page, pageSize } = this.vacationsPagedResut
    const reportCode: ReportCode = ReportCode.CollectiveVacations;

    url = `${this.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&PropKeyId=${propKeyId}&SearchParameter=${searchParameter}&status=1&page=${page}&pageSize=${pageSize}`

    let parameters = {
      url: url,
      title: '',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }

}
