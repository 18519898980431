import { Component, Inject, Input, NgZone, OnChanges, OnDestroy, OnInit, PLATFORM_ID, SimpleChanges, ViewChild } from '@angular/core';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.css']
})
export class DonutChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: string = "charDonut"
  @Input() valueField: string = 'value'
  @Input() categoryField: string = 'description'
  root: any;
  @Input() data: any[] = [];
  @Input() hideLabels: boolean = false;


  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) { }
  
  
  ngOnDestroy(): void {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
        this.root = undefined
        document.getElementById(this.id).remove()
        
      }
    });
  }
  
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.data){
      if(this.root){
        this.root.dispose()
        this.root = am5.Root.new(this.id)
        this.buildChart()
      }
    }
  }
  
  ngAfterViewInit() {
    this.browserOnly(() => {
      this.root = am5.Root.new(this.id);
      this.buildChart()
    })
  }

  buildChart(){
    if(!this.root) return
    let root = this.root
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    let chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      innerRadius: am5.percent(50)
    }));
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: this.valueField,
      categoryField: this.categoryField,
      alignLabels: false
    }));

    series.labels.template.setAll({
      textType: "circular",
      centerX: 0,
      centerY: 0,
      forceHidden: this.hideLabels
    });
    series.data.setAll(this.data);
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      marginTop: 15,
      marginBottom: 15,
    }));
    legend.data.setAll(series.dataItems);
    series.appear(1000, 100);
  }

  ngOnInit(): void {
   
  }

}