import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import {BulkLoad, IAdditionalPayrollHeaderPOST, INewsPOST} from 'src/app/pages/payroll-new/additional-payroll/models/models'
import { Beneficiary } from '@payroll/news-list/models/PayrollNews.model';

@Injectable({
  providedIn: 'root'
})
export class AdditionalPayrollService {

  apiCore = environment.api_core_url;
  api = environment.api_url;

  constructor(private http:  HttpClient, private authInfo: AuthInfoService) { }

  getEmployees(beforePeriodId: number, budgetAreaId: number, status: boolean = true){
    return this.http.get(`${this.api}/Employees?DepartmentId=${beforePeriodId}&BudgetAreaId=${budgetAreaId}&Type=13&CompanyId=${this.authInfo.getCompanyId()}&Status=${status}`)
  }
  getEmployeesPaginated(page?:number, pageSize?:number, PropkeyId = null, SearchParameter = null, beforePeriodId: number = 0, budgetAreaId: number = 0, status: boolean = true){
    
    return this.http.get(`${this.api}/Employees/GetPaginatedFilter?BeforePeriodId=${beforePeriodId}&BudgetAreaId=${budgetAreaId}&CompanyId=${this.authInfo.getCompanyId()}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Page=${page}&PageSize=${pageSize}&Type=5`);
  }

  getOverallSumary(periodId, areaId, periodIdRelated){
    return this.http.get(`${this.api}/Detail/GetOverallSummary?PayrollPeriodIdRelated=${periodIdRelated}&PayrollPeriodId=${periodId}&PayrollTypeId=4&BudgetAreaId=${areaId}&Type=11&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  
  getOverallSummaryByOptions(options: {
    headerId, type, companyId
  }) {
    let params = new HttpParams()
    if(options.headerId != null){
      params = params.append('headerId', options.headerId)
    }
    if(options.type != null){
      params = params.append('type', options.type)
    }
    if(options.companyId != null){
      params = params.append('companyId', options.companyId)
    }
    return this.http.get(`${this.api}/Detail/GetOverallSummary`, { params })
  }

  OpenAdditionalPayroll(model){
    return this.http.post(`${this.api}/Payroll/OpenAdditionalPayroll`, model)
  }
  
  postFinalPayroll(payroll: IAdditionalPayrollHeaderPOST){
    return this.http.post(`${this.api}/header`, payroll)
  }
  

  
  getPayrollNewDetail(PayrollPeriodId: number, EmployeeId: number, Status: boolean = true, BudgetAreaId: number){
    return this.http.get(`${this.api}/PayrollNews/GetPayrollNewsToApply?PayrollPeriodId=${PayrollPeriodId}&EmployeeId=${EmployeeId}&type=6&CompanyId=${this.authInfo.getCompanyId()}&Status=${Status}&BudgetAreaId=${BudgetAreaId}&PayrollType=4`);
  }
  
  getNewsByEmployeeId(employeeId: number, headerId){
    return this.http.get(`${this.api}/PayrollNews/GetPayrollNewsToApply?EmployeeId=${employeeId}&type=9&CompanyId=${this.authInfo.getCompanyId()}&Status=true&HeaderId=${headerId}`);
  }
  
  getPayrollNewById(payrollNewsId: number, status: boolean = true) {
    return this.http.get<any>(`${this.api}/PayrollNews?PayrollNewsId=${payrollNewsId}&type=1&CompanyId=${this.authInfo.getCompanyId()}&Status=${status}`);
  }

  getNewsType(status: boolean = true) {
    return this.http.get<any>(`${this.api}/NewsType?NewsTypeId=0&CompanyId=${this.authInfo.getCompanyId()}&Status=${status}`);
  }
  getEmployeeById(employeeId: number){
    return this.http.get(`${this.api}/employees?type=5&employeeNumber=${employeeId}&companyId=${this.authInfo.getCompanyId()}`)
  }
  
  postNewsAdditionalPayroll(news: INewsPOST){
    return this.http.post(`${this.api}/PayrollNews`, news)
  }

  postBeneficiary(beneficiary: Beneficiary) {
    return this.http.post<any>(`${this.api}/Beneficiary`, beneficiary);
  }

  updateBeneficiary(beneficiary: Beneficiary){
    return this.http.put<any>(`${this.api}/Beneficiary`, beneficiary);
  }
  
  getBeneficiaryById(BeneficiaryId: number, companyId: number = this.authInfo.getCompanyId(), status: boolean = true){
    return this.http.get<any>(`${this.api}/Beneficiary?BeneficiaryId=${BeneficiaryId}&CompanyId=${companyId}&Status=${status}`);
  }

  
  putNewsAdditionalPayroll(news: INewsPOST){
    return this.http.put(`${this.api}/PayrollNews`, news)
  }

  getNewsConcept(companyId: number = this.authInfo.getCompanyId(), status: boolean = true) {
    return this.http.get<any>(`${this.api}/NewsConcept?NewsConceptId=0&CompanyId=${companyId}&Status=${status}`);
  }

  getConceptsBasedNewsAvailable(PayrollTypeId: number, PayrollPeriodRelatedId: number, budgetAreaId: number, payrollPeriodId: number, versionId?:number){
    return this.http.get<any>(`${this.api}/NewsConcept/ConceptsBasedNewsAvailable?PayrollTypeId=${PayrollTypeId}&PayrollPeriodRelatedId=${PayrollPeriodRelatedId}&BudgetAreaId=${budgetAreaId}&PayrollPeriodId=${payrollPeriodId}&CompanyId=${this.authInfo.getCompanyId()}&Type=0&VersionId=${versionId}`);
  }

  getBulkLoadAdditional(bulkLoad: BulkLoad[]){
    return this.http.post(`${this.api}/BulkLoad/GetBulkLoadFinal`, bulkLoad);
  }

  postHeader(header: any){
    return this.http.post(`${this.api}/header`, header);
  }
  
  getHeader(headerId){
    return this.http.get(`${this.api}/Header/GetHeader?HeaderId=${headerId}&type=2&CompanyId=${this.authInfo.getCompanyId()}`)
  }
}
