import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormatTypes } from '../../../shared/dynamic-format/enums/format-types';
import { ToastService } from '../../../shared/toast/toast.service';
import { FileManagerService } from '../../../shared/file-manager/file-manager.service';
import { HttpClientService } from '../../../services/http-client/http-client.service';
import { HttpResponse } from '../../../services/http-client/http-Response';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../shared/loading/loading.service';
import { delay } from 'rxjs/operators';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { formatDate, Location } from '@angular/common';
import {
  FamilyPersonInInstitution,
  Person,
  PersonAdditionalInformation,
  PersonalReference,
  PersonPhone,
  PersonRecomendation,
  PersonsAppliedStudy,
  PersonsDependent,
  PersonsEmail,
  PersonsJobReference,
  PersonsSkill,
  PersonsWorkExperience,
  WizardGatheredData
} from './models/pdf-personal-record.model';
import { CandidateRegService } from 'src/app/services/http-client/candidateRegService.service';
import { Subscription } from 'rxjs';
import { docService } from 'src/app/services/doc.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { RecruitmentQuestionsService } from 'src/app/services/recruitment-questions.service';
import { QuestionType, RecruitmentQuestions } from 'src/app/models/recruitment-questions.model';
import { RecruitmentQuestionsAnswer } from 'src/app/models/recruitment-questions-answer.model';
import { EmployeesService } from 'src/app/services/employees.service';
import { ITeacherContractView } from 'src/app/models/Employee.model';
declare const $: any


@Component({
  selector: 'app-pdf-employee-data',
  templateUrl: './pdf-employee-data.component.html',
  styleUrls: ['./pdf-employee-data.component.css']
})
export class PdfEmployeeDataComponent implements OnInit {
  readonly personalRequestRoute = 'hcm/personal-request';
  public loadingPic = true
  public requisitionTypeList = [];
  subscriptions: Subscription = new Subscription();

  subtitle = 'Datos del servidor público'

  recruitmentQuestions: RecruitmentQuestions[] = [];
  recruitmentQuestionsAnswer: RecruitmentQuestionsAnswer[]= []
  isTableLoading: boolean;
  formatYesOrNot = FormatTypes.YesOrNo;
  document: any;
  id: number;
  photoUrl: string;
  primaryPhone: string;
  primaryEmail: string;
  skills: [[key: string], { personalSkill: string, personalSkillLevel: string }];
  additionalInfoLists: any = []
  isLoading: boolean;
  contracts: ITeacherContractView[] = []
  fileModalConfig = {
    src: '',
    type: '',
    filename: '',
    width: 225,
    height: 225,
    formTitle: '',
    formSubTitle: '',
  };
  personalRequestView: {
    person: Person;
    familyPersonInInstitutions: FamilyPersonInInstitution[];
    personPhone: PersonPhone[];
    personsEmails: PersonsEmail[];
    personsDependents: PersonsDependent[];
    personAdditionalInformation: PersonAdditionalInformation[];
    personsAppliedStudies: PersonsAppliedStudy[];
    personsSkills: PersonsSkill[];
    personsWorkExperiences: PersonsWorkExperience[];
    personsJobReferences: PersonsJobReference[];
    personalReferences: PersonalReference[];
    personRecomendation: PersonRecomendation[];
    recruitmentQuestionsAnswer?: RecruitmentQuestionsAnswer[]
  }

  sonsAndDaugthersCount: number = 0;
  personSpouse: string;

  pdfOpt: any;

  employee: any;

  countries = [];

  public company: any;
  file = { logo: '', image1: '', image2:'' }


  employeeChangeDetails: any[] = [];

  constructor(private _http: HttpClientService,
    private _fileManager: FileManagerService,
    private _toastService: ToastService,
    private _route: ActivatedRoute,
    public loadingService: LoadingService,
    private srvPerson: PersonService,
    private _srvCandidateService: CandidateRegService,
    private srvDoc: docService,
    private srvCandidateService: CandidateRegService,
    private location: Location,
    private _companiesService: CompaniesService,
    private authInfo: AuthInfoService,
    private recruitmentQuestionsService: RecruitmentQuestionsService,
    private cdr: ChangeDetectorRef,
    private employeeService: EmployeesService
    ) {
    this.pdfOpt = {
      orientation: 'p',
      unit: 'in',
      format: [11, 8.5]
    };
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {

    this.id = Number(this._route.snapshot.params["id"] || 0)
    if (this.id == null || this.id == 0) {
      return;
    }

    this.loadDDL().then(e => {
      this.getEmployeeData();
    })

    this.getContries();
    this.companyById(this.authInfo.getCompanyId())
  }
  

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  goBack(){
    this.location.back();
  }

  async loadDDL() {
    this.srvCandidateService.getParameters(keyWord.CauseRequisition).subscribe(e => {
      if (e.succeded) {
        this.requisitionTypeList = e.dataList
      } else {
        this._toastService.error(e.errors[0]);
      }
    }, error => {
    })
  }

  loadingRecordData(employeeId) {
    let sub = this.loadingService.loadingSub.pipe(delay(0)).subscribe(x => {
      this.isTableLoading = x;
    })

    this.subscriptions.add(sub);

    if (employeeId > 0) {
      this.srvPerson.getPersonWizard(employeeId).subscribe((res: ResponseModel<WizardGatheredData>) => {
        if (res.singleResultData) {
          this.personalRequestView = res.singleResultData;


          this.personalRequestView.personsJobReferences = [];
          for (let i = 0; i < this.personalRequestView.personsWorkExperiences.length; i++) {
            const item = this.personalRequestView.personsWorkExperiences[i];
            this.personalRequestView.personsWorkExperiences[i].personName = ''


            for(let j = 0; j < item.personsJobReferences.length; j++){
              this.personalRequestView.personsJobReferences.push(item.personsJobReferences[j]);
            }


            this.requisitionTypeList.filter(x => x.ocode == item.reasonOfExitId).forEach(res => {
              this.personalRequestView.personsWorkExperiences[i].personName = res.stringData
            })
          }

          /* if (this.personalRequestView.person.sexId == 2) {
            this.personalRequestView.person.sex = 'Masculino'
          } else {
            this.personalRequestView.person.sex = 'Femenino'
          } */

          if (this.personalRequestView.person.profileImage != '' && this.personalRequestView.person.profileImage != null) {
            this.srvDoc.getDocument(this.personalRequestView.person.profileImage).subscribe(e => {
              this.photoUrl = e.data;
              this.loadingPic = false
            }, error => {
              this.loadingPic = false
            })
          } else {
            this.loadingPic = false
          }

          //FIXME: The person photoId isn't within the model of the Person.
          // if (this.personalRequestView.personalPhoto) {
          //   this._fileManager.getFileToShow(this.personalRequestView.personalPhoto.fileId).subscribe(
          //     res => {
          //       this.photoUrl = res.file;
          //     })
          // }
          this.getRecruitmentQuestions()
        }
      }, error => {}, () => {
        this.loadingPic = false
        if (this.personalRequestView) {
          this.primaryPhone = this.personalRequestView.personPhone?.find(phone => phone?.primaryContact == true)?.phoneNumber;
          this.primaryEmail = this.personalRequestView.personsEmails?.find(email => email?.primaryContact == true)?.email || 'N/A';
        }
      })
    }
  }

  getEmployeeData() {
    this.srvPerson.getEmployeeDataByEmployeeId(this.id).subscribe((res: any) => {
      if (res.succeded) {
        this.employee = res.dataList[0]
        if(this.employee?.employeeCondition == 2){
          this.subtitle = 'Datos del Docente'
        }
        this.getHeaderEmployee();
        this.loadingRecordData(this.employee.personId)
        this.loadContracts()
      } else {
        this._toastService.error(res.errors[0])
      }
    }, error => {
      this._toastService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  getRecruitmentQuestions(){
    this.recruitmentQuestionsService.getRecruitmentQuestions(QuestionType.AdditionalInformation).subscribe({
      next: res => {
        if(res.errors.length > 0){
          return;
        }
        this.recruitmentQuestions = res.dataList;
        this.recruitmentQuestions.forEach((r) => {
          const answerObj = this.personalRequestView.recruitmentQuestionsAnswer.find(x => x.recruitmentQuestionsId == r.recruitmentQuestionsId)
          r.answer = answerObj ? answerObj.answer : false
          r.comment = answerObj ? answerObj.comment : ''
        })
        const half = Math.ceil(this.recruitmentQuestions.length / 2);    

        const firstHalf = this.recruitmentQuestions.slice(0, half)
        const secondHalf = this.recruitmentQuestions.slice(half)
        this.additionalInfoLists = [firstHalf, secondHalf]
        this.changeDetector();
      }
    })
  }
  changeDetector() {
    this.cdr.detectChanges();
  }
  getExitReason(id) {
    this.requisitionTypeList.filter(e => e.ocode = id)[0].stringData
  }

  showModal(modalName: string): void {
    $(`#${modalName}`).modal('show');
  }

  getContries(){
    this.srvCandidateService.getCountry().subscribe(e => {
      if (e.errors.length > 0) {
        this._toastService.error(e.errors[0])
        return
      }
      this.countries = e.dataList
    })
  }


  getCountyName(id){
    const countyName = this.countries.find(c => c.idCountry === id);
    return countyName?.names;
  }

  isDominican(): boolean {

    const dominicanDictionary = [
      "Dominicano",
      "Dominicana",
      "Republica Dominicana",
      "República Dominicana",
    ]
    return dominicanDictionary.find(x => this.personalRequestView?.person.nationality?.toLowerCase().includes(x.toLowerCase()))
      ? true : false;
  }

  isMarriage(): boolean {
    const marriageDictionary = [
      "Casado",
      "Casada",
      "Casado/a",
      "Casada/o",
      "Casado/(a)",
      "Casada/(o)",
    ]
    return marriageDictionary.find(x => this.personalRequestView?.person.maritalStatus?.toLowerCase().includes(x.toLowerCase()))
      ? true : false;
  }

  loadContracts() {
    this.employeeService.getTeacherContracts(this.id).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this._toastService.error(err);
          });

          res.warnings.forEach(err => {
            this._toastService.warning(err);
          });

          return;
        }

        this.contracts = res.dataList;

      },
      error: (err) => {
        this._toastService.error('Ha ocurrido un error tratando de consultar la lista de contratos.');
      }
    });
  }

  

  getHeaderEmployee(){
    /*this.srvPerson.getHeaderByEmployee(this.id).subscribe((res: any) => {
      if(res.errors.length > 0){
        this._toastService.error(res.errors[0]);
        return;
      }

      this.srvPerson.getEmployeeChangeDetails(res.dataList[0]?.employeeId).subscribe((res: any) => {
        if(res.errors.length > 0){
          this._toastService.error(res.errors[0]);
          return;
        }
       this.employeeChangeDetails = res.dataList;
      });

    })*/
    this.srvPerson.getEmployeeChangeDetails(this.employee?.employeeId).subscribe((res: any) => {
      if(res.errors.length > 0){
        this._toastService.error(res.errors[0]);
        return;
      }
     this.employeeChangeDetails = res.dataList;

    });
  }

  isNumeric(value):boolean {
    if(value == null || value == undefined ){
      return false;
    }
    return !isNaN(Number(value));
  }

  isDate(date):boolean {
    if(this.isNumeric(date)){
      return false;
    }

    let regExp = /[a-zA-Z]/g;
    if(regExp.test(date)){
      return false;
    }
    
    return (new Date(date).toString() !== "Invalid Date") && !isNaN(Number(new Date(date)));
  }

}