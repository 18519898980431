<div class="card card-custom">
  <div class="card-header mt-3">
    <div class="card-title">
      <div class="ttl d-flex align-items-center">
        <i class="flaticon-recursos-humanos-4 icon-3x text-danger mr-2"></i>
        <h1 class="text-primary">Cumpleaños</h1>
      </div>
    </div>
  </div>

  <div class="card-header mt-3 row justify-content-between">
    <div class="col-sm-12 col-md-7 c-left">
      <div class="card-toolbar d-flex justify-content-start">
        <div class="col-md-3 mr-2 px-0 pt-1">
          <div class="form-group mx-2 institution">
            <label class="text-primary"> Mes </label>
            <ng-multiselect-dropdown
              [placeholder]="'Seleccionar Estatus'"
              [settings]="dropdownSettings"
              [data]="statusOptions"
              [(ngModel)]="selectedItems"
              (onDeSelectAll)="onDeSelectAll()"
              (onDeSelect)="onItemDeSelect($event)"
              (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)"
            ></ng-multiselect-dropdown>
          </div>
        </div>

        <div class="col-md-3 mr-2 px-0 pt-1">
          <div class="form-group mx-2 institution">
            <label class="text-primary"> Condición de Foto </label>
            <ng-multiselect-dropdown
              [placeholder]="'Seleccionar Estatus'"
              [settings]="dropdownSettings"
              [data]="photoConditionList"
              [(ngModel)]="selectedItemsConditionPhoto"
              (onDeSelectAll)="onDeSelectAll()"
              (onDeSelect)="onItemDeSelect($event)"
              (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)"
            ></ng-multiselect-dropdown>
          </div>
        </div>

                <div class="col-md-3 mr-2 px-0 pt-1">
                    <div class="row justify-content-end">
                        <div>
                            <button [disabled]='sendItems.length == 0'
                                class="ml-3 input-group-text btn btn-success btn-pill dropdown-toggle"
                                 (click)="sendMail()">
                                <i class="flaticon-enviar icon-2x text-white" data-toggle="tooltip" title="Enviar"></i> Notificar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <div class="col-sm-12 col-md-5 c-right mr-0">
      <div class="card-toolbar d-flex justify-content-end">
        <div class="dropdown col-md-auto col-sm-12">
          <button
            class="btn btn-primary btn-pill dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            *ngIf="privilege.exportAction.value"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i
              class="flaticon-descarga-arrow icon-2x text-white"
              data-toggle="tooltip"
              data-original-title="Opciones de exportar"
            ></i>
            Exportar
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item align-items-center" (click)="exportToPdf()">
              <i class="fa fa-print icon-2x text-primary mr-3"></i>
              Visualizar Reporte
            </a>
          </div>
        </div>

        <div class="col text-nowrap">
          <app-search-bar-pagination
            [namePageLocalStorage]="localStorageSearch"
            (onFilter)="filterSearch($event)"
            (onChangeDropDown)="filterSearch($event)"
            [parameters]="searchBarDisplayNames"
          ></app-search-bar-pagination>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="table-responsive table-responsive-sigei">
      <table class="table table-vertical-center">
        <thead>
          <tr class="bg-primary">
            <th class="text-center"></th>
            <th class="text-center">Foto Perfil</th>
            <th class="text-center">Secuencia</th>
            <th class="text-left min-w-150px">Código de Servidor Público</th>
            <th class="text-left">Servidor Público</th>
            <th class="text-left min-w-160px">Documento de Identidad</th>
            <th class="text-left min-w-160px">Fecha de Nacimiento</th>
            <th class="text-left min-w-160px">Fecha de Ingreso</th>
            <th class="text-left">Fecha Cumpleaños</th>
            <th class="text-left">Cargo</th>
            <th class="text-left">Unidad Organizativa</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let m of employees">
            <td class="text-center">
              <input
                type="checkbox"
                name="flyerItem"
                id=""
                class="check-size"
                (change)="selectItem(m)"
                style="cursor: pointer"
                [(ngModel)]="m.isSelected"
              />
            </td>
            <td class="text-center">
              <img
                class="photo-profile"
                [src]="m.url"
                alt="foto profile"
                *ngIf="m.url"
                style="width: 110px; height: 100px; cursor: pointer"
                (click)="showImagen(m)"
              />
              <img
                class="photo-profile"
                src="assets/images/no-user.png"
                alt="foto profile"
                *ngIf="!m.url"
                title="No foto"
                style="width: 110px; height: 100px"
              />
            </td>
            <td class="text-center">{{ m.employeeId }}</td>
            <td class="text-left">{{ m.employNumber }}</td>
            <td class="text-left">{{ m.name }}</td>
            <td class="text-left" *ngIf="m.typeIdentificationId == 1">
              {{ m.personalIdentification | mask : "000-0000000-0" }}
            </td>
            <td class="text-left" *ngIf="m.typeIdentificationId != 1">
              {{ m.personalIdentification }}
            </td>
            <td class="text-left">{{ m.birthday | date : "dd/MM/yyyy" }}</td>
            <td class="text-left">
              {{ m.admissionDate | date : "dd/MM/yyyy" }}
            </td>
            <td class="text-left">
              {{ m.birthday | date : "dd" }} de {{ m.birthday | date : "MMMM" }}
            </td>
            <td class="text-left">{{ m.positionName }}</td>
            <td class="text-left">{{ m.departmentName }}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <div>
          <app-paginator
            [listedRecordsLength]="employees?.length"
            [registerQuantity]="employeesPagedResut.totalItems"
            [registerPerPage]="employeesPagedResut.pageSize"
            [totalPages]="employeesPagedResut.totalPages"
            [selectedPage]="employeesPagedResut.page"
            (onChange)="getPaginatedRecords($event)"
          >
          </app-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
