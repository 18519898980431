import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RejectPayrollModalComponent } from '@payroll/audit-approval/reject-payroll-modal/reject-payroll-modal.component';
import { ViewDetailRejectPayrollComponent } from '@payroll/open-payroll/view-detail-reject-payroll/view-detail-reject-payroll.component';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Header } from 'src/app/models/header';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-open-additional-payroll',
  templateUrl: './open-additional-payroll.component.html',
  styleUrls: ['./open-additional-payroll.component.css']
})
export class OpenAdditionalPayrollComponent implements OnInit {

  public privilege: any = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'NORMAL-PAYROLL',
    rejectAction: { key: 'REJECTPAYROLL', value: false },
    exportAction: { key: 'EXPORTPAYROLL', value: false },
    viewAction: { key: 'VIEWPAY', value: false },
    openAction: { key: 'OPENPAYROLL', value: false },
    dashboardAction: { key: 'DASHBOARDPAY', value: false },

  };
  form = new FormGroup({
    additionalPeriod: new FormControl(''),
    period: new FormControl(''),
    area: new FormControl('')
  });
  periodList = []
  @ViewChild('paginator') paginator: CaptionComponent;
  paginatedPayrollList = []
  openedPayrollList = []
  budgetAreaList = []
  periodConfig;
  budgetConfig;
  payrollListOriginal = []
  filterText = ''
  periodBeforeList = []

  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "headerId", displayName: "Secuencia" },
      { propkey: "payrollType", displayName: "Tipo de Nómina" },
      { propkey: "headerCondition", displayName: "Estatus" },
      { propkey: "name", displayName: "Usuario Creación" },
    ];
  constructor(
    private srvPayroll: PayrollService,
    private srvAuth: AuthInfoService,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.srvAuth.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.privilege = this.srvAuth.setPrivileges(this.privilege)
        this.getDropdownsData()
      }
    })
    this.periodConfig = this.getDropdownConfig('description')
    this.budgetConfig = this.getDropdownConfig('description')
  }


  getDropdownsData() {
    this.srvPayroll.getBudgetAreas().subscribe((res: any) => {
      this.budgetAreaList = res.dataList.filter(ba => ba.status == true)
    })

    this.srvPayroll.getPeriods().subscribe((res: any) => {
      if (res.succeded) {

        this.periodList = res.dataList.filter(x => x.conditionId == 1 && x.status == true);

      }

    }, err => {
    })
    this.srvPayroll.getBeforePeriod().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastr.error(res.errors[0])
        return
      }
      if (res.succeded) {

        this.periodBeforeList = res.dataList.filter(x => x.conditionId == 1 && x.status == true);

      }

    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })


  }

  changePeriod() {
    this.getHeaders()
  }

  changeAdditionalPeriod() {
    this.getHeaders()
  }

  exportToExcel() {

  }

  exportToPdf() {

    const form = this.form.value
    const payrollPeriodIdRelated = form.additionalPeriod?.payrollPeriodId
    const payrollPeriodId = form.period?.payrollPeriodId
    const budgetAreaId = form.area?.budgetAreaId

    const companyId: number = this.srvAuth.getCompanyId();
    const status = true
    const reportCode: ReportCode = ReportCode.Open_Payroll;
    const reportUrl = `${environment.reportUrl}/?Reportcode=${reportCode}&PeriodId=${payrollPeriodId}&PayrollTypeId=4&BudgetAreaId=${1}&PayrollPeriodRelated=${payrollPeriodIdRelated}&CompanyId=${companyId}&Status=${status}&Conditions=0-1-2-3-5-6&PropKeyId=0&SearchParameter=&Page=1&PageSize=10`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Unidades Organizativas',
      width: 1024,
      height: 768
    }
    openReport(parameters);



  }

  getHeaders() {
    const form = this.form.value
    const payrollPeriodIdRelated = form.additionalPeriod?.payrollPeriodId
    const payrollPeriodId = form.period?.payrollPeriodId

    if (!payrollPeriodId || !payrollPeriodIdRelated) {
      //this.toastr.warning('Debe llenar los campos')
      return
    }



    const params = {
      payrollPeriodIdRelated: payrollPeriodIdRelated,
      type: 4,
      companyId: this.srvAuth.getCompanyId(),
      payrollPeriodId: payrollPeriodId,
      status: true
    }

    this.srvPayroll.getHeadersByOptions(params).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) {
          this.toastr.error(res.errors[0])
          return
        }

        const dataList = res.dataList//.filter(d => d.payrollTypeId === this.form.get('payrollType')?.value);
        this.openedPayrollList = this.filterTable(dataList);
        this.payrollListOriginal = dataList;
      }
    })
  }
  openViewDetailRejectPayrollComponent(openedPayroll: any) {
    this.dialog.open(ViewDetailRejectPayrollComponent, {
      data: openedPayroll,
      width: '60%'
    })
      .afterClosed()
      .subscribe((result) => {
      });
  }
  openRejectModal(item) {
    const headerId = item?.headerId
    this.dialog.open(RejectPayrollModalComponent, {
      width: '50%',
      data: { headerId }
    }).afterClosed().subscribe((result: any) => {
      if (result?.saved) {
        this.toastr.success("Nómina Rechazada");
        this.getHeaders()
      }
    })
  }
  openPayroll() {
    const form = this.form
    const budgetAreaId = form.value.area?.budgetAreaId
    const periodId = form.value.period.payrollPeriodId
    const periodRelatedId = form.value.additionalPeriod.payrollPeriodId

    if (!budgetAreaId || !periodId || !periodRelatedId) {
      this.toastr.warning('Debe llenar todos los campos')
      return
    }
    const model = {
      budgetAreaId: budgetAreaId,
      companyId: this.srvAuth.getCompanyId(),
      createDate: moment().format(),
      createUserId: this.srvAuth.getUserId(),
      payrollPeriodId: periodId,
      payrollTypeId: 4,
      payrollPeriodIdRelated: periodRelatedId,
      status: true
    }

    this.srvPayroll.createHeader(model).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) {
          this.toastr.error(res.errors[0])
          return
        }
        this.toastr.success('Nomina aperturada satisfactoriamente')
        this.getHeaders()
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }
  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  filterTable(list: any[]) {
    return list.filter((pl: Header) => pl.status == true);
  }
  onKeyChange(text) {
    this.filterText = text;
  }
  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
    this.filteredList.records = this.filterTable(event.records)
  }

  getPaginatedRecords(event) {
    this.paginatedPayrollList = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
}
