<form class="mt-10" [formGroup]="form">
    <div class="row">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="form-group">
                <label>Descripción de la falta</label>
                <div class="input-group">
                    <input type="text" placeholder="Escriba aquí su búsqueda" class="form-control form-control-solid form-control-lg ng-untouched ng-pristine ng-valid" ng-reflect-model="">
                    <button id="searchBarBtn" data-toggle="tooltip" title="" class="input-group-text bg-primary" data-original-title="Buscar" data-toggle="tooltip"
                    title="Anular" (click)="openCancelActionModal()">
                        <i class="flaticon2-search-1 icon-2x text-white"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="form-group">
                <label for="">Tipo de falta <span class="text-danger">*</span></label>
                <ngx-select-dropdown formControlName="motive"
                    [config]="motiveDropdownConfig" [options]="motives"></ngx-select-dropdown>
                <span style="position: absolute;" *ngIf="formIsInvalid && form.controls['gradoFalta']?.errors?.required"
                    class="text-danger">Este campo es requerido</span>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="form-group">
                <label for="">Grado de la falta <span class="text-danger">*</span></label>
                <ngx-select-dropdown formControlName="gradoFalta"
                    [config]="dropdownConfig" [options]="gradosFaltasList" (change)="changeGradoFalta($event)"></ngx-select-dropdown>
                <span style="position: absolute;" *ngIf="formIsInvalid && form.controls['motive']?.errors?.required"
                    class="text-danger">Este campo es requerido</span>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="form-group">
                <label for="">Fecha <span class="text-danger">*</span></label>
                <input type="date" class="form-control" />
                <span style="position: absolute;" class="text-danger">Este campo es requerido</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-9 col-lg-6 col-md-12 col-sm-12 col-12">    
            <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-6 col-sm-6 col-12" *ngIf="idGradoFalta == 2">
                    <div class="form-group">
                        <label for="">Fecha de inicio<span class="text-danger">*</span></label>
                        <input formControlName="requestDate" type="date" class="form-control">
                        <span style="position: absolute;"
                            *ngIf="formIsInvalid && form.controls['requestDate']?.errors?.required" class="text-danger">Este
                            campo es requerido</span>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-6 col-sm-6 col-12" *ngIf="idGradoFalta == 2">
                    <div class="form-group">
                        <label for="">Fecha de termino<span class="text-danger">*</span></label>
                        <input formControlName="requestDate" type="date" class="form-control">
                        <span style="position: absolute;"
                            *ngIf="formIsInvalid && form.controls['requestDate']?.errors?.required" class="text-danger">Este
                            campo es requerido</span>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-6 col-sm-6 col-12" *ngIf="idGradoFalta == 2">
                    <div class="form-group">
                        <label for="">Días correspondientes <span class="text-danger">*</span></label>
                        <div class="input-group">
                            <input type="number" class="form-control" step="1" min="0" />
                            <span class="input-group-text border-secondary">días</span>
                        </div>
                        <!-- <ngx-select-dropdown formControlName="motive"
                            [config]="motiveDropdownConfig" [options]="motives"></ngx-select-dropdown> -->
                        <span style="position: absolute;" *ngIf="formIsInvalid && form.controls['motive']?.errors?.required"
                            class="text-danger">Este campo es requerido</span>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <label for="">Comentario <span class="text-danger">*</span></label>
                        <textarea formControlName="comment" class="form-control" rows="5"></textarea>
                        <span style="position: absolute;"
                            *ngIf="formIsInvalid && form.controls['comment']?.errors?.required" class="text-danger">Este
                            campo es requerido</span>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group h-100">
                <label>Evidencia <span class="text-danger">*</span></label>
                <div class="card align-items-center h-100-50">
                 
                </div>
                <span style="position: absolute;"
                    *ngIf="formIsInvalid && form.controls['evidence']?.errors?.required"
                    class="text-danger">Este campo es requerido</span>
            </div>
        </div>
    </div>
</form>

<div class="row">
    <div class="form-group d-flex justify-content-end align-items-center">
        <button #saveButtonCs
            class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
            <i class="flaticon-disco-flexible"></i>
            <span>{{isEditingServiceCommision ? 'Guardar Modificación' : 'Guardar'}}</span>
        </button>
        <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3">
            <span>Limpiar campos</span>
        </button>
    </div>
</div>      

<div class="row gutter-b">
    <h2 class="text-primary gutter-b mb-2 d-flex justify-content-between flex-wrap" >
        Historial de Suspensiones
    </h2>
    <div class="table-responsive">
        <table class="table atbl table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th class="text-left">Fecha</th>
                    <th class="text-left">Grado de falta</th>
                    <th class="text-left">Sanción o medidas</th>
                    <th class="text-left">Descripción de la falta</th>
                    <th class="text-left">Fecha inicio</th>
                    <th class="text-left">Fecha término</th>
                    <th class="text-left">Días</th>
                    <th class="text-left">Supervisor</th>
                    <th class="text-left">Evidencia</th>
                    <th class="text-center">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of [1,2,3,4,5]">
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td>
                        <a data-toggle="tooltip"
                            title="Evidencia" class="btn btn-light-youtube font-weight-bold mb-3">
                            <i class="flaticon-pdf-file icon-2x"></i>
                        </a>
                    </td>
                    <td class="actionsBtn">
                        <a
                            class="btn btn-sm btn-light-info btn-icon mr-2">
                            <i class="flaticon-ver" data-toggle="tooltip" title="Ver"></i>
                        </a>
                        <a
                            class="btn btn-icon btn-light-warning btn-sm m-1">
                            <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                        </a>
                        <a class="btn btn-icon btn-light-danger btn-sm m-1">
                            <i data-toggle="tooltip" title="" class="flaticon-delete" data-original-title="Remover"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>