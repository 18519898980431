<h2 class="mat-dialog-title">Resultado de la búsqueda</h2>
<div class="mat-dialog-content">
    <div class="row gutter-t gutter-b" style="height: 500px; overflow-y: scroll;">
        <div class="table-responsive">
            <table class="table mt-2 table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="w-50px"></th>
                        <th>Nombres</th>
                        <th>Apellidos</th>
                        <th>Fecha nacimiento</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of data.items">
                        <td class="actionsBtn">
                            <button type="button" class="btn btn-sm btn-light-success btn-icon mr-2"
                                data-title="Seleccionar" (click)="Accept(item)">
                                <i class="flaticon-etiqueta icon-2x "></i>
                            </button>
                        </td>
                        <td>{{item.firstName}}</td>
                        <td>{{item.lastName}}</td>
                        <td>{{item.birthday | date: 'dd/MM/yyyy'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)='Cancel()'>
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>