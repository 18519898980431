<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Estatus
        Nóminas</h2>
    <button type="button" (click)="close(statusUpdated)" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">

    <div class="card-body mb-4" style="padding: 0rem 2.25rem ;">
        <label class="text-primary h6" style="font-weight:600;">Nóminas enviadas</label>
        <div class="table-responsive">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-left">Secuencia</th>
                        <th class="text-left">
                            <ng-container *ngIf="data.payrollTypeId === 4 || data.payrollTypeId === 1">
                                Concepto
                            </ng-container>
                            <ng-container *ngIf="data.payrollTypeId !== 4 && data.payrollTypeId !== 1">
                                Tipo Servidor Público
                            </ng-container>
                        </th>
                        <th class="text-left">
                            Nombre de Archivo
                        </th>
                        <th class="text-left">Fecha de Envío</th>
                        <th class="text-left">Descripción</th>
                        <th class="text-left">Coletilla</th>
                        <th class="text-left">Estatus</th>
                        <th class="text-center">Acciones</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of paginatedRecords; let row = index" style="left: 0px;">
                        <td class="text-left">{{item.submissionId}}</td>
                        <td class="text-left">
                            {{item.employeeType}}
                        </td>
                        <td class="text-left">{{item.sentFileName}}</td>
                        <td class="text-left">{{item.createDate | date:"dd/MM/yyyy"}}</td>
                        <td class="text-left">{{item.description || 'N/A'}}</td>
                        <td class="text-left">{{item.coletilla}}</td>
                        <td class="text-left">{{item.status}}</td>
                        <td class=" text-center">
                            <div class="d-flex justify-content-center">
                                
                                <a (click)="send(item)" class="btn btn-sm btn-light-skype btn-icon mx-1"
                                    data-toggle="tooltip" title="Actualizar">
                                    <img src="/assets/images/refresh.svg" class="flaticon-ver icon-custom">
                                </a>

                                <a class="btn btn-sm btn-light-skype btn-icon mx-1" (click)="openEmployeesPayroll(item)"
                                    data-toggle="tooltip" title="Ver Servidores Públicos">
                                    <i class="flaticon-colaboracion icon-2x"></i>
                                </a>

                                <a class="btn btn-sm btn-light-skype btn-icon mx-1"
                                    *ngIf="item?.status?.toUpperCase()?.includes('RECHAZADO') && !item?.forwarded"
                                    (click)="resend(item)" data-toggle="tooltip" title="Reenviar">
                                    <img src="/assets/images/resend.svg" class="flaticon-ver icon-custom">
                                </a>

                                <a class="btn btn-light-danger btn-sm btn-icon mx-1" data-toggle="tooltip"
                                    title="Ver Razón de Rechazo"
                                    *ngIf="item!.description && item?.status?.toUpperCase()?.includes('RECHAZADO')"
                                    (click)="showReasonReject(item)">
                                    <i class="flaticon-ver icon-2x"></i>
                                </a>
                                <a *ngIf="item.rejectBtn" class="btn btn-sm btn-light-danger btn-icon mx-1"
                                    data-toggle="tooltip" title="Rechazar" (click)="reject1(item)">
                                    <i class="flaticon2-cross"></i>
                                </a>

                            </div>

                        </td>
                    </tr>

                </tbody>
            </table>
            <div>
                <el-caption #paginator [registerQuantity]="records.length" [originalRecordsLength]="records.length"
                    [records]="records" [listedRecordsLength]="paginatedRecords.length"
                    (onChage)="getPaginatedRecords($event)"></el-caption>
            </div>
        </div>
    </div>

</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="close(statusUpdated)"
        class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>