<ng-container>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'radio'">
      <label class="d-block" *ngIf="!inlineAlign"
        >{{ label
        }} <span class="text-danger text-monospace legend" *ngIf="required">
           *</span
        ></label
      >
      
      <div class="form-check form-check-inline" *ngFor="let opt of options">
        <label class="form-check-label mt-2 check-dtl" >
          <input
            [id]="id"
            [required]="required"
            [name]="name"
            [type]="type"
            [class]="'form-check-input mt-2 '"
            [ngClass]="inlineAlign ? 'form-check-inline': ''"
            [value]="opt.value"
            [disabled]="disabled"
            (change)="change($event.target.value)"
            [checked]="value === opt.value"
          />
          {{ opt.text }}</label
        >
      </div>
      <label *ngIf="inlineAlign"
        >{{ label
        }} <span class="text-danger text-monospace legend mr-5" *ngIf="required">
          *</span
        ></label
      >
      <ng-content></ng-content>
    </ng-container>
    <ng-container *ngSwitchCase="'checkbox'">
      <label class="d-block"
        >{{ label
        }} <span class="text-danger text-monospace legend" *ngIf="required">
          *</span
        ></label
      >
      <div class="form-check form-check-inline" *ngFor="let opt of options">
        <input
          [id]="id"
          [required]="required"
          [name]="name"
          [type]="type"
          class="form-check-input mt-2"
          [value]="opt.value"
          [disabled]="disabled"
          (change)="changeCheckedValue($event.target.value)"
          [checked]="getCheckedValues(opt.value, checked)"
        />
        <label class="form-check-label mt-2">{{ opt.text }}</label>
      </div>
      <ng-content></ng-content>
    </ng-container>
    <ng-container *ngSwitchCase="'mask'">
      <label
        >{{ label
        }} <span class="text-danger text-monospace legend" *ngIf="required"
          >*</span
        ></label
      >
      <input
        class="form-control form-control-solid form-control-lg"
        [id]="id"
        [placeholder]="placeholder"
        type="text"
        [name]="name"
        [required]="required"
        mask="000-000-0000"
        [validation]="true"
        [prefix]="prefix"
        [thousandSeparator]="mask === 'separator.2' ? ',' : null"
        [disabled]="disabled"
        [(ngModel)]="value"
        (input)="toggleMask($event.target.value)"
        (blur)="onBlur()"
        (change)="toggleMask($event.target.value)"
      />
    </ng-container>
    <ng-container *ngSwitchCase="'email'">
      <label
        >{{ label
        }} <span class="text-danger text-monospace legend" *ngIf="required"
          >*</span
        ></label
      >
      <input
        class="form-control form-control-solid form-control-lg"
        [id]="id"
        [placeholder]="placeholder"
        type="email"
        [name]="name"
        [required]="required"
        [disabled]="disabled"
        [value]="value"
        (input)="change($event.target.value)"
        (blur)="onBlur()"
        (change)="change($event.target.value)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="'currency'">
      <label
        >{{ label
        }} <span class="text-danger text-monospace legend" *ngIf="required"
          >*</span
        ></label
      >
      <input
        class="form-control form-control-solid form-control-lg"
        [id]="id"
        [placeholder]="placeholder"
        [name]="name"
        currencyMask
        [options]="{precision:2}"
        [required]="required"
        [disabled]="disabled"
        [value]="value"
        (blur)="onBlur()"
        (change)="change($event.target.value)"
        (input)="change($event.target.value)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <label
        >{{ label
        }} <span class="text-danger text-monospace legend" *ngIf="required"
          >*</span
        ></label
      >
      <input
        class="form-control form-control-solid form-control-lg"
        [id]="id"
        [placeholder]="placeholder"
        type="date"
        [name]="name"
        [min]="min"
        [required]="required"
        [disabled]="disabled"
        [(ngModel)]="value"
        [value]="value | date: 'yyyy-MM-dd'"
        (ngModelChange)="onValueChange()"
      />
    </ng-container>

    <ng-container *ngSwitchCase="'textarea'">
      <label
        >{{ label
        }} <span class="text-danger text-monospace legend" *ngIf="required"
          >*</span
        ></label
      >
      <textarea
        [required]="required"
        [name]="name"
        [placeholder]="placeholder"
        class="form-control form-control-solid form-control-lg"
        [id]="id"
        [rows]="rows"
        [disabled]="disabled"
        [value]="value"
        (input)="change($event.target.value)"
        (blur)="onBlur()"
        (change)="change($event.target.value)"
      >
      </textarea>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <label
        >{{ label
        }} <span class="text-danger text-monospace legend" *ngIf="required"
          >*</span
        ></label
      >
      <input
        class="form-control form-control-solid form-control-lg"
        [id]="id"
        [placeholder]="placeholder"
        [type]="type"
        [name]="name"
        [required]="required"
        [value]="value"
        [disabled]="disabled"
        [class]="customClass"
        (input)="change($event.target.value)"
        (blur)="onBlur()"
        (change)="change($event.target.value)"
        autocomplete="0"
      />
    </ng-container>
  </ng-container>
</ng-container>
