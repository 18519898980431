import { ChangeDetectorRef, Component, Inject, OnInit, AfterViewChecked } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-select-department-modal',
  templateUrl: './select-department-modal.component.html',
  styleUrls: ['./select-department-modal.component.css']
})
export class SelectDepartmentModalComponent implements OnInit, AfterViewChecked {
  levels: any[] = []
  FirstEntry: any = 0
  grandChildSelected: any = ""

  constructor(
    private authInfo: AuthInfoService,
    private _companiesService: CompaniesService,
    private _toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectDepartmentModalComponent>,
    private _changeDet: ChangeDetectorRef,
  ) { 
    dialogRef.disableClose = true
  }

  ngOnInit(): void {
    const { department, isGrandChild, index } = this.data
    this.getDepartmentChlidren(department, isGrandChild, index)
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }


  getDepartmentChlidren(department, isGrandChild: boolean, index?: number) {
    if (this.FirstEntry == 0) {
      let id = this.authInfo.getCompanyId();
      this._companiesService.getDepartmentsChildren(id, 0).then((data: any) => {
        if (data.errors.length > 0) {
          this._toastService.error(data.errors[0])
          return;
        }
        if (data.succeded == true) {
          this.levels.push({
            childData: data.dataList
          })

          if (this.levels[0]?.childData.length < 1) {
            this._toastService.warning('No hay unidades organizativas para mostrar');
          }
        }
      }, err => {

      });
      this.FirstEntry++
      return
    }

    if (!isGrandChild) {
      this.levels = []
    }

    if (!department.departmentId) {
      this.levels.splice(index + 1)
      this.grandChildSelected = this.levels[this.levels.length - 2]?.childData[0]
      return
    }

    this._companiesService.getDepartmentsChildren(this.authInfo.getCompanyId(), department.departmentId).then((data: any) => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return;
      }
      if (data.succeded == true) {
        this.grandChildSelected = department
        if (data.dataList.length == 0) {
          this.levels.splice(index + 1)
          return
        }
        this.levels.splice(index + 1)
        this.levels.push({
          childData: data.dataList
        })
      }
    });
  }

  closeDepartmentModal(updated: boolean) {
    this.levels = []
    this.FirstEntry = 0
    this.dialogRef.close({ updated, grandChild: this.grandChildSelected })
  }

}
