import { FormControl, ValidationErrors } from "@angular/forms";

export function NoWhitespaceValidator(control: FormControl): ValidationErrors {
    const isWhitespace: boolean = (control?.value || '')?.length > 0 ? ((control?.value || '')?.trim()?.length === 0) : false;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

// Validators.min doesn't work correctly when used together NumbersOnlyDirective
export function MinValidator(minValue: number) {
  return (control: FormControl): ValidationErrors => {
    let value = control?.value;
    let parsedValue = value == null || value === '' ? null : Number(value);
    return parsedValue == null || parsedValue >= minValue ? null : {
      min: {
        min: minValue,
        actual: parsedValue
      }
    };
  };
}

// Validators.max doesn't work correctly when used together NumbersOnlyDirective
export function MaxValidator(maxValue: number) {
  return (control: FormControl): ValidationErrors => {
    let value = control?.value;
    let parsedValue = value == null || value === '' ? null : Number(value);
    return parsedValue == null || parsedValue <= maxValue ? null : {
      max: {
        max: maxValue,
        actual: parsedValue
      }
    };
  };
}

// Compare validators
interface ValueComparer {
  (leftValue: any, rightValue: any): boolean;
}

interface ValueFormatter {
  (value: any): any;
}

function CompareValidator(rightControlName: string, errorKey: string, compare: ValueComparer, format?: ValueFormatter) {
  return (leftControl: FormControl): ValidationErrors => {
    if (leftControl == null || leftControl.disabled || leftControl.value == null) {
      return null;
    }

    let rightControl = leftControl.parent.get(rightControlName);

    if (rightControl == null || rightControl.disabled || rightControl.value == null) {
      return null;
    }

    let error: ValidationErrors | null = null;

    if (!compare(leftControl.value, rightControl.value)) {
      error = {};
      error[errorKey] = {
        left: format == null ? leftControl.value : format(leftControl.value),
        right: format == null ? rightControl.value : format(rightControl.value)
      };
    }

    return error;
  };
}

export function LessThanValidator(rightControlName: string, format?: ValueFormatter) {
  return CompareValidator(rightControlName, 'lessThan', (left, right) => left < right, format);
}

export function LessThanOrEqualsValidator(rightControlName: string, format?: ValueFormatter) {
  return CompareValidator(rightControlName, 'lessThanOrEquals', (left, right) => left <= right, format);
}

export function GreaterThanValidator(rightControlName: string, format?: ValueFormatter) {
  return CompareValidator(rightControlName, 'greaterThan', (left, right) => left > right, format);
}

export function GreaterThanOrEqualsValidator(rightControlName: string, format?: ValueFormatter) {
  return CompareValidator(rightControlName, 'greaterThanOrEquals', (left, right) => left >= right, format);
}
