<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white d-inline">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Historial de modificaciones
    </h2>
    <button type="button" (click)="close()" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>    
</div>

<div class="mat-dialog-content-custom">
    <div class="card card-custom">
        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-12 col-xl-12 col-xxl-6 c-left">
                <div class="card-toolbar d-flex justify-content-start">
                    <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4 mr-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <h2 class=" font-size-h5 text-primary">
                                Datos actuales
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-xl-12 col-xxl-6 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a (click)="exportReport()" class="dropdown-item align-items-center">
                              <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                              Visualizar Reporte
                          </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center" *ngFor="let column of data.columns">
                            {{ column }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                   <tr>
                     <td class="text-center">{{data.selectedRequisition.personalRequisitionId}}</td>
                        <td class="text-center">{{data.selectedRequisition.enclosure}}</td>
                        <td class="text-center">{{data.selectedRequisition.department}}</td>
                        <td class="text-center">{{ data.selectedRequisition.positionName }}</td>
                        <td class="text-center">{{ data.selectedRequisition.numberPlaces }}</td>
                        <td class="text-center">{{ data.selectedRequisition?.requisitionMethod }}</td>
                        <td class="text-center">{{ data.selectedRequisition.sex }}</td>
                        <td class="text-center"> {{data.selectedRequisition.requestedDate | date:'dd/MM/yyyy'}}</td>
                        <td class="text-center"> {{!data.selectedRequisition?.approvationDate ? 'N/A' : data.selectedRequisition?.approvationDate | date:'dd/MM/yyyy' }}</td>
                        <ng-container *ngIf="data.selectedRequisition.condition; else notApproved">
                            <td>
                                <div class="d-flex flex-row justify-content-evenly">
                                    <span [ngClass]="statusClass(data.selectedRequisition)"class="label label-dot mr-2"></span>
                                    <span [ngClass]="statusTextClass(data.selectedRequisition)" class="font-weight-bold">{{ statusText(data.selectedRequisition) }}</span>
                                </div>
                            </td>
                        </ng-container>
                        <ng-template #notApproved>
                            <td>
                                <div class="d-flex flex-row p-2 justify-content-evenly">
                                    <span class="label label-danger label-dot mr-2"></span>
                                    <span class="font-weight-bold text-danger">No aprobada</span>
                                </div>
                            </td>
                        </ng-template>
                        <td class="text-center">{{ data.selectedRequisition.createUserName }}</td>
                        <td class="text-center">{{ data.selectedRequisition.creationDate | date:'short' }}</td>
                        <td class="text-center">{{ data.selectedRequisition.modifyUserName }}</td>
                        <td class="text-center">{{ data.selectedRequisition.modifyDate | date:'short' }}</td>
                        <td class="text-center">
                            <span [ngClass]="data.selectedRequisition.status ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{data.selectedRequisition.status ? "Activa" : "Inactiva"}}</span>
                        </td>
                   </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="card card-custom">
        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-12 col-xl-12 col-xxl-6 c-left">
                <h2 class=" font-size-h5 text-primary">
                    Historico de cambios
                </h2>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center" *ngFor="let column of data.columns">
                            {{ column }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let requisitionHistory of requisitionHistoryChanges">
                        <td class="text-center">{{ requisitionHistory.personalRequisitionId}}</td>
                        <td class="text-center">{{ requisitionHistory.enclosure}}</td>
                        <td class="text-center">{{ requisitionHistory.department}}</td>
                        <td class="text-center">{{ requisitionHistory.positionName }}</td>
                        <td class="text-center">{{ requisitionHistory.numberPlaces }}</td>
                        <td class="text-center">{{ requisitionHistory?.requisitionMethod }}</td>
                        <td class="text-center">{{ requisitionHistory.sex }}</td>
                        <td class="text-center"> {{ requisitionHistory.requestedDate | date:'dd/MM/yyyy'}}</td>
                        <td class="text-center"> {{ !requisitionHistory?.approvationDate ? 'N/A' : requisitionHistory?.approvationDate | date:'dd/MM/yyyy' }}</td>
                        <ng-container *ngIf="requisitionHistory.condition; else notApproved">
                            <td>
                                <div class="d-flex flex-row justify-content-evenly">
                                    <span [ngClass]="statusClass(requisitionHistory)"class="label label-dot mr-2"></span>
                                    <span [ngClass]="statusTextClass(requisitionHistory)" class="font-weight-bold">{{ statusText(requisitionHistory) }}</span>
                                </div>
                            </td>
                        </ng-container>
                        <ng-template #notApproved>
                            <td>
                                <div class="d-flex flex-row p-2 justify-content-evenly">
                                    <span class="label label-danger label-dot mr-2"></span>
                                    <span class="font-weight-bold text-danger">No aprobada</span>
                                </div>
                            </td>
                        </ng-template>
                        <td class="text-center">{{ requisitionHistory.createUserName }}</td>
                        <td class="text-center">{{ requisitionHistory.creationDate | date:'short' }}</td>
                        <td class="text-center">{{ requisitionHistory.modifyUserName }}</td>
                        <td class="text-center">{{ requisitionHistory.modifyDate | date:'short' }}</td>
                        <td class="text-center">
                            <span [ngClass]="requisitionHistory.status ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{requisitionHistory.status ? "Activa" : "Inactiva"}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <app-paginator 
                    [listedRecordsLength]="requisitionHistoryChanges.length"
                    [registerQuantity]="requisitionPagedResult.totalItems"
                    [registerPerPage]="requisitionPagedResult.pageSize" [totalPages]="requisitionPagedResult.totalPages"
                    [selectedPage]="requisitionPagedResult.page" (onChange)="getPaginatedRecords($event)">
                </app-paginator>
            </div>
        </div>
    </div>