import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PayrollFlyer } from '@payroll/payment-flyer/models/payroll-flyer.model';
import { PayrollFlyerParams } from '@payroll/payment-flyer/models/payroll-params.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestMetaData, ResponseModel } from '../shared/models/strongly-typed-response.model';
import { PayrollFlyerGetTypes } from '@payroll/payment-flyer/models/payroll-flyer-get-type.enum';
import { PaymentFlyerPrint } from '@payroll/payment-flyer/models/payment-flyer-print.model';
import { PayrollVoucher } from '@payroll/payment-flyer/models/payroll-voucher.model';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class PayrollFlyerService {

  apiCore = environment.api_core_url;// 'http://172.16.11.173/core_back/api'
  api = environment.api_url; // 'http://172.16.11.173/xhrm_back/api'

  constructor(private _http: HttpClient, private authInfo: AuthInfoService) { }

  getEmployeePayrollFlyer(params:PayrollFlyerParams): Observable<ResponseModel<PayrollFlyer>>{
    params = {
      payrollPeriodId: params.payrollPeriodId,
      value: params.value,
      headerId: params.headerId??0,
      type: params.type??PayrollFlyerGetTypes.ByPeriodAndEmployeeId,
      companyId: params.companyId??this.authInfo.getCompanyId(),
      status: params.status??true,
      BudgetAreaId: params.BudgetAreaId??0,
      payrollTypeId: params.payrollTypeId??0,

    }
    return this._http.get<ResponseModel<PayrollFlyer>>(
        `${this.api}/Detail/GetOverallSummary?EmployeeId=${params.value}&payrollPeriodId=${params.payrollPeriodId}&Type=${params.type}&CompanyId=${params.companyId}&Status=${params.status}`);
  }

  getPayrollFlyers(params:PayrollFlyerParams): Observable<ResponseModel<PayrollFlyer>>{
    params = {
      payrollPeriodId: params.payrollPeriodId,
      value: params.value,
      headerId: params.headerId??0,
      type: params.type??PayrollFlyerGetTypes.ByPayrollParamsWithoutCondition,
      companyId: params.companyId??this.authInfo.getCompanyId(),
      status: params.status??true,
      advanceFilter : params.advanceFilter ?? ''

    }
    return this._http.get<ResponseModel<PayrollFlyer>>(
        `${this.api}/Detail/GetOverallSummary?PayrollPeriodId=${params.payrollPeriodId}&Type=${params.type}&CompanyId=${params.companyId}&Status=${params.status}&AdvanceFilter=${params.advanceFilter}`);
  }

  getEmployeePayrollVoucher(params:PayrollFlyerParams): Observable<ResponseModel<PayrollVoucher>>{
    params = {
      headerId: params.headerId,
      value: params.value,
      type: params.type??PayrollFlyerGetTypes.ByHeaderAndEmployeeId,
      companyId: params.companyId??this.authInfo.getCompanyId(),
      status: params.status??true,
      BudgetAreaId: params.BudgetAreaId??0,
      payrollTypeId: params.payrollTypeId??0
    }
    return this._http.get<ResponseModel<PayrollVoucher>>(
        `${this.api}/Detail/GetPaymentFlyer?HeaderId=${params.headerId}&PayrollTypeId=${params.payrollTypeId}&BudgetAreaId=${params.BudgetAreaId}&EmployeeId=${params.value}&Type=${params.type}&CompanyId=${params.companyId}&Status=${params.status}`);
  }

  getPaymentFlyer(paymentFlyer){
    return this._http.post<ResponseModel<PayrollVoucher>>(`${this.api}/Detail/GetPaymentFlyer`, paymentFlyer);
  }

  postEmployeePayrollVoucherMail(data:PayrollFlyerParams[]): Observable<ResponseModel<PayrollVoucher>>{
    return this._http.post<ResponseModel<any>>(`${this.api}/Detail/PostPaymentFlyerMail`, data);
  }

  registeringPaymentFlyerPrinted(model: PaymentFlyerPrint){
    return this._http.post<ResponseModel<any>>(`${this.api}/PaymentFlyerPrint`, model);
  }

  getPaymentFlyerColetilla(periodId:any){
    return this._http.get<ResponseModel<PayrollVoucher>>(`${this.api}/Header/Coletilla?HeaderId=0&EmployType=0&Type=3&PayrollPeriodId=${periodId}`);
  }
}
