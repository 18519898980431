<form [formGroup]="form">
    <div class="row pt-5 gutter-b">
        <div class="col-sm-12 col-lg-3">
            <div class="form-group">
                <label>Tipo de Valor <span class="font-italic"></span><span
                    class="text-danger text-monospace legend">*</span></label>
                <ngx-select-dropdown formControlName="valueTypeSelected" class=" flex-1 "
                    [config]="getDropdownConfig('stringData')"
                    [options]="valueTypes"></ngx-select-dropdown>
                    <span class="text-danger" *ngIf="formIsInvalid">{{showError('valueTypeSelected')}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group " >
                <div>
                    <label for="" class="d-block">¿Acepta Valor Manual? <span class="font-italic"></span><span
                            class="text-danger text-monospace legend">*</span></label>
                </div>
                <div class="d-flex gender flex-wrap">
                    <div class="form-check form-check-inline" >
                        <label class="form-check-label mt-2 check-dtl me-3">
                            <input value="true" formControlName="manualValue" type="radio" class="form-check-input" />
                            Sí
                        </label>
    
                        <label class="form-check-label mt-2 check-dtl">
                            <input value="false" formControlName="manualValue"  type="radio" class="form-check-input" />
                            No
                        </label>
                    </div>
                </div>
                <span class="text-danger" *ngIf="formIsInvalid">{{showError('manualValue')}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group " >
                <div>
                    <label for="" class="d-block">¿Acepta Valor por Defecto? <span class="font-italic"></span><span
                            class="text-danger text-monospace legend">*</span></label>
                </div>
                <div class="d-flex gender flex-wrap">
                    <div class="form-check form-check-inline" >
                        <label class="form-check-label mt-2 check-dtl me-3">
                            <input value="true" (change)="changeRequiredValue($event)" formControlName="requiredValue" type="radio" class="form-check-input" />
                            Sí
                        </label>
    
                        <label class="form-check-label mt-2 check-dtl">
                            <input value="false" (change)="changeRequiredValue($event)" formControlName="requiredValue"  type="radio" class="form-check-input" />
                            No
                        </label>
                    </div>
                </div>
                <span class="text-danger" *ngIf="formIsInvalid">{{showError('requiredValue')}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group " >
                <div>
                    <label for="" class="d-block">¿Es mensual? <span class="font-italic"></span><span
                            class="text-danger text-monospace legend">*</span></label>
                </div>
                <div class="d-flex gender flex-wrap">
                    <div class="form-check form-check-inline" >
                        <label class="form-check-label mt-2 check-dtl me-3">
                            <input value="true" formControlName="monthly" type="radio" class="form-check-input" />
                            Sí
                        </label>
    
                        <label class="form-check-label mt-2 check-dtl">
                            <input value="false" formControlName="monthly"  type="radio" class="form-check-input" />
                            No
                        </label>
                    </div>
                </div>
                <span class="text-danger" *ngIf="formIsInvalid">{{showError('monthly')}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group " >
                <div>
                    <label for="" class="d-block">¿Se Puede Duplicar?</label>
                </div>
                <div class="d-flex gender flex-wrap">
                    <div class="form-check form-check-inline" >
                        <label class="form-check-label mt-2 check-dtl me-3">
                            <input value="true" formControlName="duplicate" type="radio" class="form-check-input" />
                            Sí
                        </label>
    
                        <label class="form-check-label mt-2 check-dtl">
                            <input value="false" formControlName="duplicate"  type="radio" class="form-check-input" />
                            No
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group " >
                <div>
                    <label for="" class="d-block">¿Tiene Restrinción?</label>
                </div>
                <div class="d-flex gender flex-wrap">
                    <div class="form-check form-check-inline" >
                        <label class="form-check-label mt-2 check-dtl me-3">
                            <input value="true" formControlName="isRestriction" type="radio" class="form-check-input" />
                            Sí
                        </label>
    
                        <label class="form-check-label mt-2 check-dtl">
                            <input value="false" formControlName="isRestriction"  type="radio" class="form-check-input" />
                            No
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group">
                <label>Tipo de Aplicación</label>
                <input formControlName="applicationType" type="number" class="form-control form-control-solid form-control-lg">
            </div>
        </div>
    </div>
</form>
