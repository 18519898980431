import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IEmployee } from 'src/app/models/Employee.model';
import { ListOfFaultsModalComponent } from './list-of-faults-modal/list-of-faults-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DisciplinaryActionsService } from 'src/app/services/disciplinary-actions.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { IDisciplinaryActionsPost, IDisciplinaryActionsView, IOffenseView, IOffensesPost } from 'src/app/shared/models/disciplinaryActions.model';
import { DatePipe } from '@angular/common';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { ToastrService } from 'ngx-toastr';
import { docService } from 'src/app/services/doc.service';
import Swal from 'sweetalert2';
import { openReport } from 'src/app/shared/utils/utility';
import { BehaviorSubject, Observable, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { NoWhitespaceValidator } from 'src/app/shared/custom-validation/custom-validation';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';
import * as moment from 'moment';

@Component({
  selector: 'app-disciplinary-measures',
  templateUrl: './disciplinary-measures.component.html',
  styleUrls: ['./disciplinary-measures.component.css']
})
export class DisciplinaryMeasuresComponent implements OnInit, OnChanges {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'ACTION-CHANGE-EMPLOYEE',
    add: { key: 'ADD-DISCIPLINARY-ACTION', value: false },
    view: { key: 'VIEW-DISCIPLINARY-ACTION', value: false },
    edit: { key: 'EDIT-DISCIPLINARY-ACTION', value: false },
    delete: { key: 'DELETE-DISCIPLINARY-ACTION', value: false }
  }


  form!: FormGroup;

  @Input() employeeInfo!: IEmployee;

  @Input() isInSeviceCommission: boolean = false;
  @Input() isSuspended: boolean = false;

  isEditing: boolean = false;
  currentConditionOnEditting!: number;

  localStorageTab = 'disciplinary-measures-selected';

  offenseSelected!: IOffenseView;

  minEffectiveDate!: string;
  currentDate!: string;
  minAllowedDaysToRequest: number = 5;

  calculatedDays!: number;

  reasonsConfig = this.getDropdownConfig('stringData');
  @Input() modificationReasons: ParameterControl[] = [];

  actionsList: IDisciplinaryActionsView[] = [];
  actionsListPaginated: IDisciplinaryActionsView[] = [];

  useOffenseToUpdatedIt: boolean = true;

  evidencePath: string = "";

  EvidenceForEditting: string = '';

  showDaysErrorMessage: boolean = false;

  @ViewChild('uppyFileDisciplinaryMeasures')
  uppyFileDisciplinaryMeasures: UppyFileComponent | null = null;
  constructor(
    private dialog: MatDialog,
    private disciService: DisciplinaryActionsService,
    private authInfo: AuthInfoService,
    private datePipe: DatePipe,
    private toastServ: ToastrService,
    private cdRef: ChangeDetectorRef,
    private docService: docService
  ) {
    
    this.buildForm();
  }

  ngOnInit(): void {
    this.privilege = this.authInfo.setPrivileges(this.privilege)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['employeeInfo']?.currentValue?.employeeId > 0) {
      this.loadDisciplinaryActionsList();

      const employeeId = this.employeeInfo?.employeeId;
      const companyId = this.employeeInfo?.companyId;

      this.form.get('employeeId')?.setValue(employeeId);
      this.form.get('companyId')?.setValue(companyId);
    }

    if(changes['isSuspended']?.currentValue){
      this.disableButtonsAndInputs();
    }
  }

  private buildForm(): void {

    const employeeId = this.employeeInfo?.employeeId;
    const companyId = this.employeeInfo?.companyId;

    const currentDate = new Date();
    const days = currentDate.getDate();
    const minDate = (new Date()).setDate(days - 5);
    this.minEffectiveDate = this.datePipe.transform(minDate, 'yyyy-MM-dd');
    this.currentDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd')

    this.form = new FormGroup({
      actionId: new FormControl(0, Validators.required),
      employeeId: new FormControl(employeeId, Validators.required),
      offenseId: new FormControl(null, Validators.required),
      savedOffenseDescription: new FormControl(null),
      savedRecommendedAction: new FormControl(null),
      savedGradeId: new FormControl(null),
      effectiveDate: new FormControl(this.currentDate, Validators.required),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      days: new FormControl(null),
      comment: new FormControl(null, [Validators.required, NoWhitespaceValidator]),
      evidence: new FormControl(null, Validators.required),
      companyId: new FormControl(companyId),
      userId: new FormControl(this.authInfo.getUserId()),
      newModificationComment: new FormControl(null),
      newModificationReason: new FormControl(null),
      newModificationReasonId: new FormControl(null),
      oldModificationComment: new FormControl(null),
      oldModificationReason: new FormControl(null),


      offenseSearchText: new FormControl(null)
    });

  }

  disableButtonsAndInputs() {
    this.form.disable();
    var buttons: any = document.getElementsByClassName('btn');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = true;
    }
  }


  private loadDisciplinaryActionsList() {
    this.disciService.getList(0, this.employeeInfo?.employeeId, 0, 0, 0, this.employeeInfo?.companyId, true, 0, 0).subscribe({
      next: (res) => {
        if (!res.succeded) {

          res.errors.forEach(err => {
            this.toastServ.error(err);
          });

          res.warnings.forEach(warn => {
            this.toastServ.warning(warn);
          });

          return;
        }

        this.actionsList = res.dataList;
        this.cdRef.detectChanges();

      },
      error: (err) => {
        this.toastServ.error('Ha ocurrido un error inesperado consultando el listado de medidas disciplinarias')
      }
    })
  }

  clearFields(reloadList: boolean = false) {
    this.resetForm()
    this.offenseSelected = null;
    this.isEditing = false;

    this.uppyFileDisciplinaryMeasures.cleanUppy();
    this.EvidenceForEditting = '';

    this.form.get('startDate').clearValidators();
    this.form.get('endDate').clearValidators();
    this.form.get('newModificationReason').clearValidators();
    this.form.get('endDate').updateValueAndValidity();
    this.form.get('startDate').updateValueAndValidity();
    this.form.get('newModificationReason').updateValueAndValidity();

    if (reloadList) {
      this.loadDisciplinaryActionsList();
    }
  }

  private resetForm() {
    this.form.reset();

    const employeeId = this.employeeInfo?.employeeId;
    const companyId = this.employeeInfo?.companyId;

    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd')

    const days = currentDate.getDate();
    const minDate = currentDate.setDate(days - this.minAllowedDaysToRequest);

    this.minEffectiveDate =  this.datePipe.transform(minDate, 'yyyy-MM-dd');
    
    this.form.patchValue({
      actionId: 0,
      employeeId: employeeId,
      effectiveDate: this.currentDate,
      companyId: companyId,
      userId: this.authInfo.getUserId(),
    })

  }

  changeModificationReason(value: ParameterControl) {
    this.form.get('newModificationReasonId').setValue(value?.ocode);
  }

  setTabSelected(tab: string) {
    localStorage.setItem(this.localStorageTab, tab)
  }

  saveChanges() {
    if(this.form.invalid){
      this.toastServ.warning('Debe completar los campos obligatorios');
      return;
    }

    this.showDaysErrorMessage = false;

    let model: IDisciplinaryActionsPost = this.form.getRawValue();
    model.modificationComment = this.form?.value?.newModificationComment;
    model.modificationReasonId = this.form?.value?.newModificationReasonId;
    model.startDate = model.savedGradeId == 2 || model.savedGradeId == 4 ? model.startDate : null;
    model.endDate = model.savedGradeId == 2 || model.savedGradeId == 4 ? model.endDate : null;
    model.days = model.savedGradeId == 2 || model.savedGradeId == 4 ? model.days : null;
    model.offensesList = [];

    if(model?.days > 90 && model.savedGradeId == 2){
      this.showDaysErrorMessage = true;
      this.toastServ.warning('Una sanción puede abarcar de uno a noventa (90) días máximo, la misma no puede exceder los 90 días');
      return;
    }

    if(model?.days > 180 && model.savedGradeId == 4){
      this.toastServ.warning('Este tipo de sanción o medida no puede exceder los 180 días (6 meses)');
      return;
    }


    if(this.useOffenseToUpdatedIt){
      const offense: IOffensesPost = {
        offenseId: this.offenseSelected.offenseId,
        description: this.offenseSelected.description,
        recommendedAction: this.offenseSelected.recommendedAction,
        gradeId: this.offenseSelected.gradeId,
        startDate: this.offenseSelected.startDate,
        endDate: this.offenseSelected.endDate,
        maxDays: this.offenseSelected.maxDays,
        status: this.offenseSelected.status,
        companyId: this.offenseSelected.companyId,
        userId: this.authInfo.getUserId()
      }
      
      model.offensesList.push(offense);
    }

    this.uppyFileDisciplinaryMeasures.handleFile((guid) => {
      model.evidence = guid;
      if(this.isEditing){
        this.updateRecord(model);
      }else{
        this.insertNew(model);
      }
    });
  }

  private insertNew(model: IDisciplinaryActionsPost){
    this.disciService.insert(model).subscribe({
      next: (res) => {
        if (!res.succeded) {

          res.errors.forEach(err => {
            this.toastServ.error(err);
          });

          res.warnings.forEach(warn => {
            this.toastServ.warning(warn);
          });

          return;
        }

        this.toastServ.success('Medida Disciplinaria registrada satisfactoriamente');
        this.clearFields(true);


      },
      error: (err) => {
        this.toastServ.error('Ha ocurrido un error inesperado insertando la medida disciplinaria')
      }
    })
  }

  private updateRecord(model: IDisciplinaryActionsPost){
    this.disciService.update(model).subscribe({
      next: (res) => {
        if (!res.succeded) {

          res.errors.forEach(err => {
            this.toastServ.error(err);
          });

          res.warnings.forEach(warn => {
            this.toastServ.warning(warn);
          });

          return;
        }

        this.toastServ.success('Medida Disciplinaria actualizada satisfactoriamente');
        this.clearFields(true);


      },
      error: (err) => {
        this.toastServ.error('Ha ocurrido un error inesperado actualizando la medida disciplinaria')
      }
    })
  }

  deleteDA(item: IDisciplinaryActionsView) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#e63946',
      cancelButtonText: 'Cancelar',
      title: '¿Seguro que desea eliminar esta Medida Disciplinaria?',

    }).then((result) => {
      if (result.isConfirmed) {
        this.disciService.delete(item.actionId,this.authInfo.getUserId()).subscribe({
          next: (res) => {
            if (!res.succeded) {
              res.errors.forEach(err => {
                this.toastServ.error(err);
              });

              res.warnings.forEach(warn => {
                this.toastServ.warning(warn);
              });
              return;
            }

            this.toastServ.success('Medida Disciplinaria eliminada satisfactoriamente');
            this.loadDisciplinaryActionsList();

          },
          error: (err) => {
            this.toastServ.error('Ha ocurrido un error tratando de eliminar la Medida Disciplinaria')
          }
        });

      } else {
        return;
      }
    })
  }

  editDisciplinaryAction(disciplinaryAction: IDisciplinaryActionsView) {

    this.clearFields(false);

    this.isEditing = true;

    this.offenseSelected = {
      offenseId: disciplinaryAction.offenseId,
      description: disciplinaryAction.offenseDescription,
      recommendedAction: disciplinaryAction.recommendedAction,
      gradeId: disciplinaryAction.offenseGradeId,
      grade: disciplinaryAction.offenseGrade
    }

    this.useOffenseToUpdatedIt = false;

    this.currentConditionOnEditting = disciplinaryAction?.conditionId;
    const modificationReason = this.modificationReasons?.find(m => m.ocode == disciplinaryAction?.modificationReasonId);

    const effectiveDate = new Date(disciplinaryAction.effectiveDate);
    const days = effectiveDate.getDate();
    const minDate = effectiveDate.setDate(days - this.minAllowedDaysToRequest);

    this.minEffectiveDate =  this.datePipe.transform(minDate, 'yyyy-MM-dd');

    this.form.patchValue({
      ...disciplinaryAction,
      effectiveDate: this.datePipe.transform(disciplinaryAction.effectiveDate, "yyyy-MM-dd"),
      startDate: this.datePipe.transform(disciplinaryAction.startDate, "yyyy-MM-dd"),
      endDate: this.datePipe.transform(disciplinaryAction.endDate, "yyyy-MM-dd"),
      userId: this.authInfo.getUserId(),
      oldModificationComment: disciplinaryAction?.modificationComment,
      oldModificationReason: modificationReason,

      offenseSearchText: disciplinaryAction?.offenseDescription
    });

    if(disciplinaryAction?.offenseGradeId == 2 || disciplinaryAction?.offenseGradeId == 4){
      this.form.get('startDate').setValidators(Validators.required);
      this.form.get('endDate').setValidators(Validators.required);
      this.form.get('endDate').updateValueAndValidity();
      this.form.get('startDate').updateValueAndValidity();
    }else{
      this.form.get('startDate').clearValidators();
      this.form.get('endDate').clearValidators();
      this.form.get('endDate').updateValueAndValidity();
      this.form.get('startDate').updateValueAndValidity();
    }

    this.form.get('newModificationReason').setValidators(Validators.required);
    this.form.get('newModificationReason').updateValueAndValidity();

    if(disciplinaryAction?.evidence){
      this.EvidenceForEditting = disciplinaryAction?.evidence;
    }

    this.changeDateRange();
  }


  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  openListOfFaulstsModal(): void {
    const offenseSearchText = this.form.get('offenseSearchText')?.value;
    const maxGrade = this.getMaxGrade();
    const couldBeRecurring = this.determineIfThereIsRecurringOffenses();

    this.dialog.open(ListOfFaultsModalComponent, {
      width: '70%',
      data: { searchText: offenseSearchText, maxGrade:maxGrade, couldBeRecurring:couldBeRecurring, previousOffenseSelected: this.offenseSelected }
    }).afterClosed().subscribe({
      next: (res: { succeded: boolean, data: IOffenseView }) => {
        if (res.succeded) {
          this.offenseSelected = res.data;

          if(res.data){
            this.useOffenseToUpdatedIt = true;
          }

          this.form.get('offenseSearchText')?.setValue(res?.data?.description);
          this.form.get('offenseId')?.setValue(res?.data?.offenseId);
          this.form.get('savedOffenseDescription')?.setValue(res?.data?.description);
          this.form.get('savedRecommendedAction')?.setValue(res?.data?.recommendedAction);
          this.form.get('savedGradeId')?.setValue(res?.data?.gradeId);
          
          
          if(res?.data?.gradeId == 2 || res?.data?.gradeId == 4){
            this.form.get('startDate').setValidators(Validators.required);
            this.form.get('endDate').setValidators(Validators.required);
            this.form.get('endDate').updateValueAndValidity();
            this.form.get('startDate').updateValueAndValidity();
          }else{
            this.form.get('startDate').clearValidators();
            this.form.get('endDate').clearValidators();
            this.form.get('endDate').updateValueAndValidity();
            this.form.get('startDate').updateValueAndValidity();
          }
        
        }
      }
    })
  }

  private getMaxGrade(): number{
    const filteredList = this.actionsList.filter(x=>x.status == true);
    const grade = filteredList.length > 0 ? filteredList[0]?.offenseGradeId : 0;
    return grade;
  }

  private determineIfThereIsRecurringOffenses(): boolean{
    const filteredList = this.actionsList.filter(x=>x.status == true);
    const countByGrade = filteredList.reduce((previous,current)=>{
      const grade = current.offenseGradeId;
      if(grade > 0){
        previous[grade] = (previous[grade] || 0) + 1;
      }
      return previous;
    },{});
    const couldBeRecurring = Object.values(countByGrade).some((value:number)=> value > 1);
     //verficar si hay faltas recurrentes de algun tipo, mas de 1 - true or false
    return couldBeRecurring;
  }

  changeEvidence(GUID:string | null | undefined){
    this.form.get('evidence')?.setValue(GUID);
  }

  afterChangeDates(){
    const startDate = this.form.get('startDate')?.value;
    const endDate = this.form.get('endDate')?.value;

    if(!startDate || !endDate){
      this.form.get('days')?.setValue(0);
      return;
    }

    const formattedStartDate = new Date(startDate);
    const formattedEndDate = new Date(endDate);

    if (isNaN(formattedStartDate.getTime()) || isNaN(formattedEndDate.getTime())) {
      this.form.get('days')?.setValue(0);
      return;
    }

    const difference = formattedEndDate?.getTime() - formattedStartDate?.getTime();

    if(difference < 0){
      this.form.get('days')?.setValue(0);
      return;
    }
    
    const milisecondsInSecond = 1000;
    const secondsInMinute = 60;
    const minutesInHour = 60;
    const hoursInDay = 24;

    const days = Math.floor(difference / (milisecondsInSecond * secondsInMinute * minutesInHour * hoursInDay)) + 1;

    this.form.get('days')?.setValue(days);

    if(this.offenseSelected?.gradeId != 2){
      return;
    }

    if(days > 90){
      this.showDaysErrorMessage = true;
    }else{
      this.showDaysErrorMessage = false;
    }
  }

  openGuID(guid) {
    try{
      this.docService.getDocument(guid).subscribe({
        next:(res)=>{
          window.open(res?.data, '_blank');
        },
        error:(err)=>{
          this.toastServ.error('Ha ocurrido un error inesperado consultando el archivo')
        }
      })
    }catch(err){
      this.toastServ.error('Ha ocurrido un error inesperado consultando el archivo')
    }
  }

  getPaginatedRecords(event) {
    this.actionsListPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.cdRef.detectChanges()
  }

  viewReport(item: IDisciplinaryActionsView){
    
    const companyId: number = item?.companyId;
    const employeeId: number = item?.employeeId;
    const actionId: number = item?.actionId;
    const reportCode: ReportCode = ReportCode.DisciplinaryActionReport;
    
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&EmployeeId=${employeeId}&ActionId=${actionId}`;
    
    let parameters = {
      url: reportUrl,
      title: 'Reporte Medidas Disciplinarias',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }

  changeDateRange(){
    if(this.offenseSelected?.gradeId == 4){
      this.validateDateRange();
    }
    this.afterChangeDates();
  }

  validateDateRange() {
    const startDate = this.form.get('startDate')?.value;
    const endDate = this.form.get('endDate')?.value;

    const start = moment(startDate);
    const end = moment(endDate);

    const diffInDays = end.diff(start, 'days'); 

    if(diffInDays > 180){
      this.form.get('endDate').setErrors({ dateRangeInvalid: true });
    }else{
      this.form.get('endDate').setErrors(null);
    }
  }

  setEvidence(event){
    this.form.get('evidence').setValue(event);
  }
}
