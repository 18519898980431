import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PayrollService } from 'src/app/services/payroll.service';
import Swal from 'sweetalert2';
import { RejectPayrollEmployeeType } from '../models/SendPayrollFile';
declare function applySort(): any;

@Component({
  selector: 'app-employees-payroll',
  templateUrl: './employees-payroll.component.html',
  styleUrls: ['./employees-payroll.component.css']
})

export class EmployeesPayrollComponent implements OnInit, AfterViewChecked {
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };

  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
  [
    { propkey: "employeeId", displayName: "Secuencia" },
    { propkey: "name", displayName: "Servidor Público" },
    { propkey: "personalIdentification", displayName: "Documento de Identidad" },
    { propkey: "positionName", displayName: "Cargo"},
    { propkey: "departmentName", displayName: "Unidad Organizativa"}
  ];

  payrollDetails: any[] = []
  payrollDetailsOriginal: any[] = []
  paginatedPayrollDetails: any[] = []

  sendItems: any[] = [];
  allItemsSelected: boolean = false;

  canMakeChanges: boolean = false;

  constructor(private _payrollService: PayrollService,
    private srvAuth: AuthInfoService,
    public dialogRef: MatDialogRef<EmployeesPayrollComponent>,
    private toastr: ToastrService, private _cdRef: ChangeDetectorRef, 
    @Inject(MAT_DIALOG_DATA) public data: { headerId: number, employeeTypeId: number, 
      newsConceptId: number, payrollTypeId: number,
      status: string, forwarded: boolean }) { 
      this.canMakeChanges = this.data?.status?.toUpperCase()?.includes('RECHAZADO') && !this.data?.forwarded;
    }

  ngOnInit(): void {
    this.getPayrollDetails();
    applySort();
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  getPayrollDetails() {

    let type = 0;
    if(+this.data?.payrollTypeId == 4 || +this.data?.payrollTypeId == 1){
      type = 1;
    }
    const employeeTypeId = this.data.payrollTypeId == 4 || this.data.payrollTypeId == 1  ? this.data?.newsConceptId : this.data?.employeeTypeId;
    this._payrollService.getEmployeeInPayroll(this.data?.headerId, employeeTypeId, type).subscribe(res => {
      if(!res?.succeded){
        res?.errors?.forEach(error => {
          this.toastr.error(error);
        })
        res?.warnings?.forEach(warning => {
          this.toastr.warning(warning);
        })
        return;
      }
      const payrolls =  res?.dataList;
      this.payrollDetails = [...payrolls];
      this.payrollDetailsOriginal =  [...payrolls];
    });
  }

  excludeEmployees(){
    if(this.sendItems?.length === 0){
      this.toastr.warning('Debe selecionar por lo menos un registro');
      return;
    }
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#28a745',
      cancelButtonText: 'Cancelar',
      title: '¿Seguro que desea excluir los servidores públicos seleccionados?',
    }).then((result) => {
      if (result.isConfirmed) {
        this.rejectPayrollEmployeeType();
      } 
    });
  }

  private rejectPayrollEmployeeType(){
    const rejectPayrollEmployeeType: RejectPayrollEmployeeType = {
      headerId: this.data.headerId,
      userId: this.srvAuth?.getUserId(),
      employees: this.sendItems?.map(employee => employee?.employeeId)?.join('-')
    }
    this._payrollService.rejectPayrollEmployeeType(rejectPayrollEmployeeType).subscribe({
      next: res => {
        if(!res?.succeded){
          res?.errors?.forEach(error => {
            this.toastr.error(error);
          })
          res?.warnings?.forEach(warning => {
            this.toastr.warning(warning);
          })
          return;
        }
        Swal.fire({
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonColor: '#003876',
          confirmButtonText: 'Aceptar',
          html: 'Servidores públicos excluidos correctamente',
          icon: 'success'
        });
        this.sendItems = [];
        this.allItemsSelected = false;
        this.getPayrollDetails();
      }, error: res => {
        this.toastr.error('Ha ocurrido un error al excluir los servidores públicos');
      }
    });
  }

  selectAllItems() {
    this.sendItems = [];
    this.payrollDetails.forEach((element) => {
      if (element?.status) {
        element['isSelected'] = this.allItemsSelected;
        if (this.allItemsSelected) {
          this.sendItems.push(element);
        }
      }
    });
    
  }

  selectItem(employee) {
    if (this.sendItems.some(item => item?.employeeId === employee?.employeeId)) {
      this.sendItems = this.sendItems?.filter((value) => {
        return value?.employeeId != employee?.employeeId;
      })
    } else {
      this.sendItems.push(employee)
    }
    this.allItemsSelected = this.sendItems?.length ===  this.payrollDetails?.length;
    this.setStatusItems();
  }


  private setStatusItems(){
    this.filteredList.records = this.filteredList?.records?.map(employee => {
      return {
        ...employee,
        isSelected: this.sendItems?.some(item => item?.employeeId === employee?.employeeId && item?.isSelected === true)
      }
    });
    this.payrollDetails = this.payrollDetails?.map(employee => {
      return {
        ...employee,
        isSelected: this.sendItems?.some(item => item?.employeeId === employee?.employeeId && item?.isSelected === true)
      }
    });
    this.paginatedPayrollDetails = this.paginatedPayrollDetails?.map(employee => {
      return {
        ...employee,
        isSelected: this.sendItems?.some(item => item?.employeeId === employee?.employeeId && item?.isSelected === true)
      }
    });
  }


  getFilteredList(event: { records: any[], enable: boolean } ) {
    this.filteredList = event;
    this.filteredList.records = event?.records?.map(employee => {
      return {
        ...employee,
        isSelected: this.sendItems?.some(item => item?.employeeId === employee?.employeeId && item?.isSelected === true)
      }
    });
  }

  getPaginatedRecords(event) {
    this.paginatedPayrollDetails = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

}