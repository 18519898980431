<div *ngIf="isTableLoading"
  class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
  <el-spinner animation="spinner-logo"></el-spinner>
  <h3 class="text-primary">Cargando PDF...</h3>
</div>
<div class="container-fluid py-3">
  <button (click)="goBack()" class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
    <i class="flaticon2-left-arrow-1"></i>
    <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
  </button>
</div>
<div class="container-fluid" *ngIf="!isTableLoading">
  <div id="pdfExp" class="card">
    <div class="card-header px-22">
      <div class="align-items-center d-flex flex-wrap justify-content-lg-between justify-content-md-center" id="header">
        <div class="logo px-2" >
          <img [src]="file.logo" alt="" class="max-w-200px" />
        </div>

        <div *ngIf="fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">
            {{fileModalConfig.formTitle}}
          </h1>
          <h2 class="text-danger">{{fileModalConfig.formSubTitle}}</h2>
        </div>
        <div *ngIf="!fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">
            Instituto Tecnológico de Las Américas
          </h1>
          <h2 class="text-danger">Acción de Cambio de Servidor Público (Desvincular)</h2>
        </div>
        <div class="date mt-md-5 p-4 d-flex flex-wrap justify-content-between align-items-center">
          <h4 class="text-right mr-3">Fecha de solicitud:</h4>
          <h5 class="text-danger text-right">{{personSelected?.exitDate | date:'dd/MM/yyyy'}}</h5>
          <!-- &nbsp; -->
        </div>
      </div>
    </div>
    <div class="card-body">
      <div id="pdf">
        <div class="pdf-page pb-4">
          <div class="container-fluid row mt-3" id="personal-data">
            <h3 class="col-md-12 p-2 bg-primary text-white mt-3 text-center">DATOS PERSONALES</h3>
            <div class="col-md-6 row">
              <div class="photo col-md-4">
                <!-- <span *ngIf="loadingPic" class="d-flex flex-column align-items-center">
                  <img src="assets/media/logos/xmera-logo-3.png" alt="loading" width="70px" />
                  <strong class="text-danger font-size-h3">Cargando...</strong>
                </span> -->

                <ng-container>
                  <div class="symbol symbol-lg-200" *ngIf="!photoUrl; else second">
                    <i class="symbol-badge bg-success"></i>
                    <img src="../../../../assets/media/no-user.png" alt="image">
                  </div>
                  <ng-template #second>
                    <div class="symbol symbol-lg-200">
                      <i class="symbol-badge bg-success"></i>
                      <img [src]="photoUrl | safe" alt="image" (click)="showModal('photo')">
                    </div>
                  </ng-template>
                </ng-container>
              </div>
              <div class="pdf-list col-md-8">
                <div class="pdf-items">
                  <h4>Código del Servidor Público:</h4>
                  <span>{{personSelected?.employNumber}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Servidor Público:</h4>
                  <span>{{personSelected?.name}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Identificación:</h4>
                  <span>{{personSelected?.personalIdentification | mask:"000-0000000-0"}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Teléfono:</h4>
                  <span *ngIf="employeeId > 0">{{personSelected?.phoneNumber | mask: "(000)-000-0000" }}</span>
                </div>
                <div class="pdf-items">
                  <h4>Unidad Organizativa:</h4>
                  <span *ngIf="employeeId > 0">{{personSelected?.departmentName ? personSelected?.departmentName:
                    'N/A'}}</span>
                </div>
              </div>
            </div>
            <div class="pdf-list col-md-6">
              <div class="pdf-items">
                <h4>Puesto:</h4>
                <span>{{personSelected?.positionName}}</span>
              </div>
              <div class="pdf-items">
                <h4>Salario:</h4>
                <span>{{personSelected?.salary | currency:"RD$"}}</span>
              </div>
              <div class="pdf-items">
                <h4>Tipo de Nombramiento:</h4>
                <span>{{personSelected?.contractType}}</span>
              </div>
              <div class="pdf-items">
                <h4>Fecha Efectiva:</h4>
                <span>{{personSelected?.effectiveDate |date:"dd/MM/yyyy"}}</span>
              </div>
              <div class="pdf-items">
                <h4>Fecha de Ingreso:</h4>
                <span>{{personSelected?.admissionDate |date:"dd/MM/yyyy"}}</span>
              </div>

            </div>
          </div>

          <div class="container-fluid row mt-3 " id="family-working-at-here">
            <h3 class="col-md-12 mt-3 p-2 bg-secondary text-white text-center">
              DATOS DE LA DESVINCULACION
            </h3>
            <div class="col-md-12 m-0 p-0 mb-3">
              <div class="row">
                <div class="pdf-list col-md-6">
                  <div class="pdf-items">
                    <h4>Tipo de salida:</h4>
                    <span>{{personSelected?.exitTypeDescription || 'N/A'}}</span>
                  </div>
                  <div class="pdf-items">
                    <h4>Motivo de salida:</h4>
                    <span>{{personSelected?.exitReasonDescription || 'N/A'}}</span>
                  </div>
                  <div class="pdf-items">
                    <h4>Fecha Salida:</h4>
                    <span>{{(personSelected?.exitDate | date:'dd/MM/yyyy') || 'N/A'}}</span>
                  </div>
                </div>
                <div class="pdf-list col-md-6">
                  <div class="pdf-items evidenceO">
                    <h4>Evidencia:</h4>
                    <span *ngIf="!personSelected?.exitEvidence">N/A</span>
                    <a *ngIf="personSelected?.exitEvidence" (click)='openGuID(personSelected?.exitEvidence)' data-toggle="tooltip" title="Evidencia"
                          class="btn btn-light-youtube font-weight-bold mb-3">
                          <i class="flaticon-pdf-file icon-2x"></i>
                      </a>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>

          <div class="container-fluid row mt-3 " id="family-working-at-here">
            <h3 class="col-md-12 mt-3 p-2 bg-secondary text-white text-center">
              DESCRIPCIÓN DE DOCUMENTOS
            </h3>
            <div class="col-md-12 m-0 p-0 mb-3">
              <fieldset [disabled]="true">
                <form [formGroup]="employeeExitForm">
                  <ng-template [ngIf]="employeeExitForm && employeeExitForm.get('employeeExitList')">
                    <div class="pdf-list col-md-6"
                      *ngFor="let item of employeeExitForm.get('employeeExitList')['controls']; let i=index">
                      <div class="pdf-items" formArrayName="employeeExitList">
                        <h4>{{exitListToggle[i].stringData}}:</h4>
                        <div class="form-group mb-0 fr" [formGroupName]="i"
                          *ngIf="employeeExitForm.get('employeeExitList').at(i).controls['selected'].value; else unSelected">
                          <div class="form-check form-check-inline">
                            <label class="form-check-label plz">
                              <input [checked]="true" type="radio"
                                class="form-check-input ng-untouched ng-pristine ng-invalid">
                              Sí
                            </label>
                          </div>

                          <div class="form-check form-check-inline">
                            <label class="form-check-label plz">
                              <input [checked]="false" required="" type="radio"
                                class="form-check-input ng-untouched ng-pristine ng-invalid">
                              No
                            </label>
                          </div>
                        </div>
                        <ng-template #unSelected>
                          <div class="form-group mb-0 fr">
                            <div class="form-check form-check-inline">
                              <label class="form-check-label plz">
                                <input [checked]="false" type="radio"
                                  class="form-check-input ng-untouched ng-pristine ng-invalid">
                                Sí
                              </label>
                            </div>

                            <div class="form-check form-check-inline">
                              <label class="form-check-label plz">
                                <input [checked]="true" required="" type="radio"
                                  class="form-check-input ng-untouched ng-pristine ng-invalid">
                                No
                              </label>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                </form>
              </fieldset>
            </div>


            <div class="container-fluid row mt-3" id="references">

              <h3 class="bg-white border-bottom col-md-12 p-3 text-center text-primary bg-light-light ">
                ESPECIFICAR OTRO AQUÍ
              </h3>
              <div class="col-md-12 m-0 p-0 overflow-auto" id="laboral-history">
                <table class="table ">
                  <tbody>
                    <tr class="p-0" *ngFor="let item of personalRequestView?.personsDependents ;let i = index">
                      <td class=""></td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>


        </div>
        <div class="card-footer mt-4">
          <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
            <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-80px">
            <div class="d-flex flex-wrap justify-content-around flex-fill">
              <div *ngIf="supervisorNames.employeeSupervisor" class="mr-3">
                <span>__________________________</span>
                <p class="font-weight-bold text-dark text-center">
                  {{supervisorNames.employeeSupervisor}}
                </p>
              </div>
              <div *ngIf="supervisorNames.hhrr" class="mr-3">
                <span>__________________________</span>
                <p class="font-weight-bold text-dark text-center">
                  {{supervisorNames.hhrr}}
                </p>
              </div>
              <div *ngIf="supervisorNames.rectory" class="mr-3">
                <span>__________________________</span>
                <p class="font-weight-bold text-dark text-center">
                  {{supervisorNames.rectory}}
                </p>
              </div>
            </div>
            <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
          </div>
        </div>
      </div>
    </div>
    <button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
      <i class="flaticon2-printer font-size-h1"></i>
    </button>
    <script>
      let print = () => {
        let pdf = document.getElementById("pdfExp");
        pdf.contentWindow.focus();
        pdf.contentWindow.print();
      };
    </script>
    <el-modal target="logoModal" size="md" [title]="'Vista de foto'">
      <el-embed *ngIf="fileModalConfig.src" [src]="fileModalConfig.src" [type]="fileModalConfig.type"
        [filename]="fileModalConfig.filename" [width]="fileModalConfig.width" [height]="fileModalConfig.height">
      </el-embed>
    </el-modal>
    <el-modal target="photo" size="md" [title]="'Vista de foto'">
      <div class="container justify-content-center" style="align-content: center;">
        <ng-template [ngIf]="photoUrl">
          <img [src]="photoUrl | safe" alt="image" style="max-width: 350px;">
        </ng-template>
      </div>
    </el-modal>
