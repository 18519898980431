import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class ContactsService {
    private readonly urlApi = environment.api_core_url
    constructor(private _http: HttpClient) { }

    deletePhone(id: number = 0): Observable<any> {
        return this._http.delete<any>(`${this.urlApi}/PersonPhone?IdPersonPhone=${id}`);
    }

    deleteEmail(id: number = 0): Observable<any> {
        return this._http.delete<any>(`${this.urlApi}/PersonsEmails?IdPersonEmail=${id}`);
    }

}
