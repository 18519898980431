import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RequestNoObjectionService } from 'src/app/services/request-no-objection.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FilterDashboardComponent } from '../../../shared/filter-dashboard/filter-dashboard.component'
import { DatePipe } from '@angular/common';
import { ChartOptions } from 'src/app/shared/models/apexChartModels';
import { AuthInfoService } from 'src/app/services/auth-info.service';



@Component({
  selector: 'app-dashboard-no-objection',
  templateUrl: './dashboard-no-objection.component.html',
  styleUrls: ['./dashboard-no-objection.component.css']
})
export class DashboardNoObjectionComponent implements OnInit {

  public chartOptions: Partial<ChartOptions>;

  public dataByConditions = [];
  public dataByDate = [];
  public allRecordsCount: number = 0;
  public submittedObjections: number = 0;
  public unSubmittedObjections: number = 0;
  public approvedObjections: number = 0;
  public contractedObjections: number = 0;
  public reservedObjections: number = 0;
  returned = 0
  hired = 0
  public rejected: number = 0;
  inactives: number = 0;
  cancelled: number = 0;

  // public dateFrom: string = '01-01-0001'
  // public dateTo: string = this.datePipe.transform(new Date(),'MM-dd-yyyy')
  public refresIsDisabled: boolean = true

  filtered = false
  filterParams = {
    year: null,
    date2: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    date1: '0001-01-01',
    companyId: this.authInfo.getCompanyId()
  }

  constructor(private rNOService: RequestNoObjectionService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<DashboardNoObjectionComponent>,
    private dialog: MatDialog,
    private _changeDet: ChangeDetectorRef,
    private datePipe: DatePipe, private authInfo: AuthInfoService) {
    this.dialogRef.disableClose = true;
    this.buildCharts();


  }

  ngOnInit(): void {
    try{

      this.filterParams.date1 = this.datePipe.transform(`${this.authInfo.getConstitutionDate()}`, 'yyyy-MM-dd')
      this.filterParams.date2 = this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd')
    }catch(e){

    }

    this.getDashboardNoObjection();
  }

  getDashboardNoObjection() {
    this.rNOService.getDashboardNoObjection(this.filterParams.date1, this.filterParams.date2, this.filterParams.companyId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastService.error(res.errors[0]);
        return;
      }
      const noObjectionTotalQuantities = res.singleData.noObjectionTotalQuantities;
      this.allRecordsCount = noObjectionTotalQuantities[0].value;

      const noObjectionByStatusQuantities = res.singleData.noObjectionByStatusQuantities;
      this.submittedObjections = noObjectionByStatusQuantities.find(n => n.description.toLowerCase() === 'enviado')?.value || 0;
      this.unSubmittedObjections = noObjectionByStatusQuantities.find(n => n.description.toLowerCase() === 'no enviado')?.value || 0;
      this.approvedObjections = noObjectionByStatusQuantities.find(n => n.description.toLowerCase() === 'aprobado')?.value || 0;
      this.hired = noObjectionByStatusQuantities.find(n => n.description.toLowerCase() === 'contratado')?.value || 0;
      this.returned = noObjectionByStatusQuantities.find(n => n.description.toLowerCase() === 'devuelto para revisión')?.value || 0;
      this.rejected = noObjectionByStatusQuantities.find(n => n.description.toLowerCase() === 'rechazado')?.value || 0;
      this.cancelled = noObjectionByStatusQuantities.find(n => n.description.toLowerCase() === 'nombramiento anulado')?.value || 0;

      const status = [
        {
          description: 'Enviadas',
          value: this.submittedObjections
        },
        {
          description: 'No enviadas',
          value: this.unSubmittedObjections
        },
        {
          description: 'Aprobadas',
          value: this.approvedObjections
        },
        {
          description: 'Rechazadas',
          value: this.rejected
        },
        {
          description: 'Contratados',
          value: this.hired
        },
        {
          description: 'Devuelto para revisión',
          value: this.returned
        },
        {
          description: 'Nombramiento Anulado',
          value: this.cancelled
        }
      ];
      this.chartOptions.series = [];
      this.chartOptions.labels = [];
      status.forEach(s => {
        this.chartOptions.labels.push(s.description);
        this.chartOptions.series.push(s.value);
      });
    }, error => {
      this.toastService.error('Ocurrió un error al obtener los datos');
    })
  }

  buildCharts() {
    this.chartOptions = {
      series: [0, 0, 0],
      chart: {
        // width: 425,
        type: "donut",
      },
      stroke: {
        width: 0
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold',
          colors: ['#ffffff', '#ffffff', '#ffffff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      title: {
        text: ""
      },
      labels: [],

      legend: {
        show: true,
        position: 'bottom',
        },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }
      ]
    };
  }

  filterByDate(): void {
    const dialogRef = this.dialog.open(FilterDashboardComponent, {
      data: { filterParams: this.filterParams },
    });
    dialogRef.afterClosed().subscribe((result: { year, date1, date2, companyId }) => {

      if(!result) return
      this.filtered = result?.year != null || result.date1 != null || result.date2 != null;

      if (!this.filtered) {
        return;
      }

      this.filterParams.year = result.year
      if (result.date1) {
        this.filterParams.date1 = result.date1
      }

      if (result.date2) {
        this.filterParams.date2 = result.date2
      }
      this.filterParams.companyId = result.companyId

      this.getDashboardNoObjection()
    });
  }

  close(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

  clearFilter(): void {

    if (!this.filtered) {
      return
    }

    this.filterParams = {
      date1: this.datePipe.transform(`01-01-${new Date().getFullYear()}`, 'MM-dd-yyyy'),
      date2: this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'MM-dd-yyyy'),
      year: null,
      companyId: this.authInfo.getCompanyId()
    }

    this.getDashboardNoObjection()
    this.filtered = false;
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

}
