<div class="container-fluid">
    <div id="pdfExp" class="card">
        <div class="card-header px-22">
            <div class="row"
                id="header">
                <div class="col-md-3">
                    <div class="form-group mb-0">
                        <div class="card-body p-0">
                            <div>
                                <div class="evidence"></div>
                            </div>
                            <div >
                                <div class="symbol symbol-150 d250 my-2 max-w-250px">
                                    <img alt="Pic" src="assets/media/xmera-logo-3.png" class="w-80" style="width: 150px;"/>
                                </div>
                                <div class="btn-group center" role="group">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="title p-2 d-flex justify-content-center align-items-center flex-column col-md-auto w">
                    <h1 class="ttlPDF text-primary text-center"> Instituto Tecnológico de las Américas (ITLA)</h1>
                    <h3 class=" text-center"><span class="text-danger">Pago sueldo enero 2022:</span> Servidores Públicos Administrativos y Docentes Fijos</h3>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table mt-2 table-vertical-center text-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-left">Servidor Público</th>
                            <th class="text-center">Género</th>
                            <th class="text-left">Cargo</th>
                            <th class="text-center">Estatus</th>
                            <th class="text-right">Ingreso Bruto</th>
                            <th class="text-right">Ingreso Total</th>
                            <th class="text-right">AFP</th>
                            <th class="text-right">SFS</th>
                            <th class="text-right">ISR</th>
                            
                            <th class="text-right">Otros Desc.</th>
                            <th class="text-right">Total Desc.</th>
                            <th class="text-right">Neto</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="text-left" colspan="12">
                                <span class="text-dark text-uppercase">Instituto Tecnológico de las Américas</span>
                            </th>
                        </tr>
                        <tr>
                            <td class="text-left">Omar Guarionex Mendez Llubieres</td>
                            <td class="text-center">M</td>
                            <td class="text-left">Rector</td>
                            <td class="text-center">Fijo</td>
                            <td class="text-right">RD$240,000.00</td>
                            <td class="text-right">RD$240,000.00</td>
                            <td class="text-right">RD$6,888.00</td>
                            <td class="text-right">RD$45,287.39</td>
                            <td class="text-right">RD$4,943.80</td>
                            <td class="text-right">RD$4,121.36</td>
                            <td class="text-right">RD$61,240.55</td>
                            <td class="text-right">RD$178,759.45</td>
                        </tr>

                        <tr>
                            <th class="text-left" colspan="12">
                                <span class="text-dark text-uppercase">Unidad Organizativa de Relaciones Internacionales e Interinstitucionales-ITLA</span>
                            </th>
                        </tr>
                        <tr>
                            <td class="text-left">Omar Guarionex Mendez Llubieres</td>
                            <td class="text-center">M</td>
                            <td class="text-left">Rector</td>
                            <td class="text-center">Fijo</td>
                            <td class="text-right">RD$240,000.00</td>
                            <td class="text-right">RD$240,000.00</td>
                            <td class="text-right">RD$6,888.00</td>
                            <td class="text-right">RD$45,287.39</td>
                            <td class="text-right">RD$4,943.80</td>
                            <td class="text-right">RD$4,121.36</td>
                            <td class="text-right">RD$61,240.55</td>
                            <td class="text-right">RD$178,759.45</td>
                        </tr>

                        <tr>
                            <td class="text-left">Omar Guarionex Mendez Llubieres</td>
                            <td class="text-center">M</td>
                            <td class="text-left">Rector</td>
                            <td class="text-center">Fijo</td>
                            <td class="text-right">RD$240,000.00</td>
                            <td class="text-right">RD$240,000.00</td>
                            <td class="text-right">RD$6,888.00</td>
                            <td class="text-right">RD$45,287.39</td>
                            <td class="text-right">RD$4,943.80</td>
                            <td class="text-right">RD$4,121.36</td>
                            <td class="text-right">RD$61,240.55</td>
                            <td class="text-right">RD$178,759.45</td>
                        </tr>

                        <tr>
                            <td class="text-left">Omar Guarionex Mendez Llubieres</td>
                            <td class="text-center">M</td>
                            <td class="text-left">Rector</td>
                            <td class="text-center">Fijo</td>
                            <td class="text-right">RD$240,000.00</td>
                            <td class="text-right">RD$240,000.00</td>
                            <td class="text-right">RD$6,888.00</td>
                            <td class="text-right">RD$45,287.39</td>
                            <td class="text-right">RD$4,943.80</td>
                            <td class="text-right">RD$4,121.36</td>
                            <td class="text-right">RD$61,240.55</td>
                            <td class="text-right">RD$178,759.45</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div class="card-footer gutter-t">
            <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
                <img src="assets/media/logos/xmera-logo-3.png" alt="" class="max-w-80px">
                <img src="assets/media/logos/xmera-logo-3.png" alt="" class="max-w-175px">
            </div>
        </div>
    </div>
</div>

