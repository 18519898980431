<div class="row">
    <div class="col-12 col-md-6">
        <ng-template [ngIf]="propList.length>0">
            <div class="form-group">
                <label class="text-primary">Parámetro de Búsqueda</label>
                <ngx-select-dropdown #selectComp [(ngModel)]="parameterValue" [options]='searchParameter' (change)='onChangeParameterSearch($event.value)'
                    [config]='config' (searchChange)="onSearchChange($event)">
                </ngx-select-dropdown>
            </div>
        </ng-template>
    </div>
    <div class="col-12 col-md-6 mr-0 pr-0">
        <div class="form-group">
            <label>Búsqueda</label>
            <div class="input-group">
                <input [(ngModel)]="keyValue" (keyup.enter)="filter(keyValue)" type="text"
                    class="form-control form-control-solid form-control-lg" placeholder="Escriba aquí su búsqueda">
                <button id="searchBarBtn" (click)="filter(keyValue)" class="input-group-text bg-primary"
                    data-toggle="tooltip" title="Buscar">
                    <i class="flaticon2-search-1 icon-2x text-white"></i>
                </button>
            </div>
        </div>
    </div>
</div>
