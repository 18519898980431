import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CaptionOptions } from '../caption/enums/caption-options';
import { CaptionEventModel } from '../caption/models/caption-event-model';

export interface PaginationEvent {
  registersPerPage: number,
  option:string,
  selectedPage:number
}
@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {
  ngOnInit(): void {
  }

  options = []
  @Input() registerQuantity: number;
  @Input() listedRecordsLength: number;
  @Input() registerPerPage: number = environment.register_per_page;
  @Input() selectedPage = 1;
  firstPage = 1;
  @Input() totalPages: number
  @Output() onChange = new EventEmitter<PaginationEvent>();
  pagesOptions: {page?:number, records?:any[]}[]= [];
  
  calc(n){
    let arr1 = [1, 5, 10, 20, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
    let arr2 = []
    arr1.forEach(e => {
      if(n > e){
        arr2.push(e)
      }
    })
    arr2.push(n)
    return arr2
  }
  ngOnChanges(): void {
    this.pagesOptions=[];
    this.options = this.calc(this.registerQuantity)
  }


  nextPageCaption() {
    this.selectedPage++;
    this.emitChange(CaptionOptions.NextPage);
  }

  previousPageCaption() {
    this.selectedPage--;
    this.emitChange(CaptionOptions.PreviousPage);
  }


  emitChange(option: string) {
    const emitter = {
      registersPerPage: +this.registerPerPage,
      option,
      selectedPage:this.selectedPage
    };
    this.onChange.emit(emitter);
  }

  changeRegisterPerPage(registerPerPage: number) {
    this.selectedPage = this.firstPage;
    this.registerPerPage = Number(registerPerPage);
    this.pagesOptions=[];
    this.emitChange(CaptionOptions.ChangeRegisterPerPage);
  }

}

