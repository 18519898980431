import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PhysicalInventoryService } from 'src/app/services/physical-inventory.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-entry-adjustment-physical-inventory-medical',
  templateUrl: './entry-adjustment-physical-inventory-medical.component.html',
  styles: [
  ]
})
export class EntryAdjustmentPhysicalInventoryMedicalComponent implements OnInit {
  cutOffDate:any
  inventoryadjustment:any;
  inventoryChanges:any;
  formInvalid:any;
  madeby: any = this.authInfo.getUser().firstName + this.authInfo.getUser().lastName;

  constructor(
    public dialogRef: MatDialogRef<EntryAdjustmentPhysicalInventoryMedicalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authInfo: AuthInfoService,
    private fb : FormBuilder,
    private _servicesphysical : PhysicalInventoryService,
    private toastr: ToastrService,
  ) {
    this.dialogRef.disableClose = true;

  }

  form = this.fb.group({
    inventoryDetailId : [this.data.detail.inventoryDetailId],
    oldStock : [this.data.detail.stock],
    companyId: [Number(this.authInfo.getUser().companyId)],
    userId:[Number(this.authInfo.getUser().userId)],
    conceptChanges:[],
    productId : [this.data.detail.productId],
    amountChanges: ['', Validators.required],
    newStock: [this.data.detail.physicalCount]
  })

  ngOnInit(): void {
    this.inventoryadjustment = this.data
    this.cutOffDate = formatDate(this.inventoryadjustment?.header?.cutOffDate, 'yyyy-MM-dd', 'es')
    if(this.inventoryadjustment.detail.different < 0){
      this.inventoryadjustment * -1;
    }
    if(this.inventoryadjustment.detail.different > 0){
      this.inventoryadjustment * 1;
    }
  }

  Acept(){
    this.formInvalid = this.form.invalid
    if(this.formInvalid) return;

    if(this.form.get('amountChanges').value != this.inventoryadjustment.detail.different){
      Swal.fire({
        icon: 'error',
        title: 'Ajuste Incorrecto',
        text: `La cantidad a ajustar que usted coloco no es la correcta, favor colocar la cantidad correcta Ej: ${this.inventoryadjustment.detail.different}`,

      })
      return
    }
    Swal.fire({
      title: 'Agregar motivo del ajuste',
      input: 'textarea',
      inputPlaceholder: 'Escribe el motivo aquí...',
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const comentario = result.value;
        this.form.get('conceptChanges').setValue(comentario);
        this.inventoryChanges = this.form.value
        this._servicesphysical.postInventoryChanges(this.inventoryChanges).subscribe({
          next:(resp:any) => {
            if(!resp?.succeded){
              this.toastr.error(resp?.errors[0]);
              return;
            }
            this.toastr.success('Los datos fueron ingresados correctamente')
            this.dialogRef.close({Applied: true});
          }, error: res => {
            this.toastr.error("Ha ocurrido un error al tratar de agregar el ajuste al inventario fisico");
          }
        })
      }
    });
  }

  close(): void
  {
    this.dialogRef.close();
  }

}
