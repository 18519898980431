import { Component, Input, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';

@Component({
  selector: 'app-designacion',
  templateUrl: './designacion.component.html',
  styleUrls: ['./designacion.component.css']
})
export class DesignacionComponent implements OnInit {
  @Input() employee
  constructor(){ }

  ngOnInit(): void {
  }

}
