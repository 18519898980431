<ng-container>
    <div class="card card-custom ">
        <div class="card-header mt-3">
            <div class="card-title">
                <span class="card-icon">
                    <i class="flaticon-recursos-humanos-4 icon-3x text-danger"></i>
                </span>
                <h1>
                    Vinculaciones
                    <!-- <small>sub title</small> -->
                </h1>
            </div>

            <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Volver a la pantalla anterior">
            <a [routerLink]="getRouterToBack()" class="btn btn-pill font-weight-bolder btn-shadow btn-warning  ">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
              </a>
        </div>
        </div>
        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-7 c-left">
                <div class="card-toolbar d-flex justify-content-start w-100">
                    <div class="row w-100">
                        <div class="col-sm-12 col-md-6">
                            <div class="form-group mx-2 institution">
                                <label class="text-primary">
                                    Tipo de procesamiento
                                </label>
                                <select class="form-select w-100 form-control-solid form-control-lg" [(ngModel)]="showProcess">
                                    <option>Elige una opción</option>
                                    <option [value]="'CargaManual'">Carga manual</option>
                                    <option [value]="'CargaArchivo'">Carga mediante archivo</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div *ngIf="showProcess == 'CargaArchivo'">
                                <div class="mt-button">
                                    <button class="btn btn-primary btn-pill" type="button">
                                        <i class="flaticon-subir-1 icon-2x text-white"></i>
                                        Cargar archivo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-5 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12"
                        >
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item align-items-center" (click)="exportToExcel()">
                                <i class="flaticon-excel icon-2x text-success mr-3"></i>
                                Excel
                            </a>
                            <a class="dropdown-item align-items-center" (click)="openPdf()">
                                <i class="flaticon-pdf-file text-danger icon-2x text-primary mr-3 text-primary"></i>
                                PDF
                            </a>
                            <!-- <a class="dropdown-item disabled" href="#">
                                    <i class="flaticon-word icon-2x text-primary mr-3"></i>
                                    Word
                                </a>
                                <a class="dropdown-item disabled" href="#">
                                    <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                                    PDF
                                </a>
                                <a class="dropdown-item disabled" href="#">
                                    <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                                    CSV
                                </a> -->
                        </div>
                    </div>

                    <div class="col text-nowrap" >
                        <app-search-bar-pagination (onFilter)="filterSearch($event)"
                         [parameters]="searchBarDisplayNames"
                         [namePageLocalStorage]="localStorageSearch"
                         ></app-search-bar-pagination>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div *ngIf="showProcess == 'CargaManual'">
                <div class="table-responsive table-responsive-sigei">
                    <table class="table table-vertical-center caption-top">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-center">Institución</th>
                                <th class="text-center">Departamento</th>
                                <th class="text-center">Grupo ocupacional</th>
                                <th class="text-center">Tipo contrato</th>
                                <th class="text-center">Tipo empleado</th>
                                <th class="text-center">Cargo</th>
                                <th class="text-center">Fecha de Ingreso</th>
                                <th class="text-center">Estatus</th>
                                <th class="text-center">Tipo de documento</th>
                                <th class="text-center">Número de documento</th>
                                <th class="text-center">Nombre</th>
                                <th class="text-center">Apellido</th>
                                <th class="text-center">Géneo</th>
                                <th class="text-center">Fecha de nacimiento</th>
                                <th class="text-center">Estado civil</th>
                                <th class="text-center">Cuenta bancaria</th>
                                <th class="text-center">Banco</th>
                                <th class="text-center">Salario bruto</th>
                                <th width="80px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    <ngx-select-dropdown class="flex-1" [config]="config" [options]='options'></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='departament' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='ocupacionalGroup' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='contractType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='employeType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="date"
                                        class="form-control form-control-solid form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='status' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='documentType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="actionsBtn">
                                    <input type="number" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='gender' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="date" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='civilStatus' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='bank' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-center">
                                    <button title="Agregar" data-toggle="tooltip" class="btn btn-sm btn-light-info btn-icon m-1">
                                        <i class="flaticon-mas"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <ngx-select-dropdown class="flex-1" [config]="config" [options]='options'></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='departament' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='ocupacionalGroup' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='contractType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='employeType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="date"
                                        class="form-control form-control-solid form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='status' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='documentType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="actionsBtn">
                                    <input type="number" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='gender' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="date" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='civilStatus' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='bank' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-center">
                                    <button title="Agregar" data-toggle="tooltip" class="btn btn-sm btn-light-info btn-icon m-1">
                                        <i class="flaticon-mas"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <ngx-select-dropdown class="flex-1" [config]="config" [options]='options'></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='departament' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='ocupacionalGroup' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='contractType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='employeType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="date"
                                        class="form-control form-control-solid form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='status' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='documentType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="actionsBtn">
                                    <input type="number" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='gender' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="date" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='civilStatus' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='bank' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-center">
                                    <button title="Agregar" data-toggle="tooltip" class="btn btn-sm btn-light-info btn-icon m-1">
                                        <i class="flaticon-mas"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <ngx-select-dropdown class="flex-1" [config]="config" [options]='options'></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='departament' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='ocupacionalGroup' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='contractType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='employeType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="date"
                                        class="form-control form-control-solid form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='status' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='documentType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="actionsBtn">
                                    <input type="number" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='gender' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="date" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='civilStatus' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='bank' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-center">
                                    <button title="Agregar" data-toggle="tooltip" class="btn btn-sm btn-light-info btn-icon m-1">
                                        <i class="flaticon-mas"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <ngx-select-dropdown class="flex-1" [config]="config" [options]='options'></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='departament' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='ocupacionalGroup' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='contractType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='employeType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="date"
                                        class="form-control form-control-solid form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='status' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='documentType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="actionsBtn">
                                    <input type="number" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='gender' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="date" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='civilStatus' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='bank' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-center">
                                    <button title="Agregar" data-toggle="tooltip" class="btn btn-sm btn-light-info btn-icon m-1">
                                        <i class="flaticon-mas"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <ngx-select-dropdown class="flex-1" [config]="config" [options]='options'></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='departament' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='ocupacionalGroup' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='contractType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='employeType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="date"
                                        class="form-control form-control-solid form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='status' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='documentType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="actionsBtn">
                                    <input type="number" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='gender' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="date" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='civilStatus' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='bank' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-center">
                                    <button title="Agregar" data-toggle="tooltip" class="btn btn-sm btn-light-info btn-icon m-1">
                                        <i class="flaticon-mas"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <ngx-select-dropdown class="flex-1" [config]="config" [options]='options'></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='departament' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='ocupacionalGroup' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='contractType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='employeType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="date"
                                        class="form-control form-control-solid form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='status' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='documentType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="actionsBtn">
                                    <input type="number" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='gender' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="date" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='civilStatus' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='bank' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-center">
                                    <button title="Agregar" data-toggle="tooltip" class="btn btn-sm btn-light-info btn-icon m-1">
                                        <i class="flaticon-mas"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <ngx-select-dropdown class="flex-1" [config]="config" [options]='options'></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='departament' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='ocupacionalGroup' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='contractType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='employeType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="date"
                                        class="form-control form-control-solid form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='status' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='documentType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="actionsBtn">
                                    <input type="number" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='gender' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="date" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='civilStatus' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='bank' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-center">
                                    <button title="Agregar" data-toggle="tooltip" class="btn btn-sm btn-light-info btn-icon m-1">
                                        <i class="flaticon-mas"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <ngx-select-dropdown class="flex-1" [config]="config" [options]='options'></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='departament' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='ocupacionalGroup' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='contractType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='employeType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="date"
                                        class="form-control form-control-solid form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='status' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='documentType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="actionsBtn">
                                    <input type="number" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='gender' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="date" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='civilStatus' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='bank' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-center">
                                    <button title="Agregar" data-toggle="tooltip" class="btn btn-sm btn-light-info btn-icon m-1">
                                        <i class="flaticon-mas"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <ngx-select-dropdown class="flex-1" [config]="config" [options]='options'></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='departament' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown
                                        class="flex-1" [options]='ocupacionalGroup' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='contractType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='employeType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="date"
                                        class="form-control form-control-solid form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='status' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='documentType' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <input type="text" class="form-control form-control-lg">
                                </td>
                                <td class="actionsBtn">
                                    <input type="number" class="form-control form-control-lg">
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='gender' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="date" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='civilStatus' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-left">
                                    <ngx-select-dropdown class="flex-1" [options]='bank' [config]="config"></ngx-select-dropdown>
                                </td>
                                <td class="text-left">
                                    <input type="number" class="form-control form-control-solid form-control-lg" />
                                </td>
                                <td class="text-center">
                                    <button title="Agregar" data-toggle="tooltip" class="btn btn-sm btn-light-info btn-icon m-1">
                                        <i class="flaticon-mas"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <app-paginator
                        [listedRecordsLength]="separationsList?.length"
                        [registerQuantity]="separationsPagedResut"
                        [registerPerPage]="separationsPagedResut"
                        [totalPages]="separationsPagedResut"
                        [selectedPage]="separationsPagedResut"
                        (onChange)="getPaginatedRecords($event)">
                    </app-paginator>
                </div>
                <div class="card-footer d-flex justify-content-end">
                    <button type="button" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success">
                        <i class="flaticon-etiqueta"></i>
                        Procesar
                    </button>
                </div>
            </div>
            <div *ngIf="showProcess == 'CargaArchivo'">
                <div class="table-responsive table-responsive-sigei">
                    <table class="table table-hover table-vertical-center" style="table-layout: fixed;">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left" style="width: 160px;">Institución</th>
                                <th class="text-left" style="width: 160px;">Departamento</th>
                                <th class="text-left" style="width: 200px;">Grupo ocupacional</th>
                                <th class="text-left" style="width: 200px;">Tipo contrato</th>
                                <th class="text-left" style="width: 200px;">Tipo empleado</th>
                                <th class="text-left" style="width: 200px;">Cargo</th>
                                <th class="text-left" style="width: 120px;">Fecha de Ingreso</th>
                                <th class="text-left" style="width: 120px;">Estatus</th>
                                <th class="text-left" style="width: 180px;">Tipo de documento</th>
                                <th class="text-left" style="width: 160px;">Número de documento</th>
                                <th class="text-left" style="width: 120px;">Nombre</th>
                                <th class="text-left" style="width: 120px;">Apellido</th>
                                <th class="text-left" style="width: 150px;">Géneo</th>
                                <th class="text-left" style="width: 120px;">Fecha de nacimiento</th>
                                <th class="text-left" style="width: 150px;">Estado civil</th>
                                <th class="text-left" style="width: 180px;">Cuenta bancaria</th>
                                <th class="text-left" style="width: 200px;">Banco</th>
                                <th class="text-right" style="width: 150px;">Sueldo bruto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-left">
                                    ITLA CALETA
                                </td>
                                <td class="text-left">
                                    Finanzas
                                </td>
                                <td class="text-left">
                                    Grupo ocupacional I
                                </td>
                                <td class="text-left">
                                    Tipo de contrato A
                                </td>
                                <td class="text-left">
                                    Tipo de empleado A
                                </td>
                                <td class="text-left">
                                    Gerente general
                                </td>
                                <td class="text-left">
                                    15/dic/2023
                                </td>
                                <td class="text-left">
                                    En proceso
                                </td>
                                <td class="text-left">
                                    Cédula
                                </td>
                                <td class="text-left">
                                    000-0000000-0
                                </td>
                                <td class="text-left">
                                    Julisy
                                </td>
                                <td class="text-left">
                                    Amador
                                </td>
                                <td class="text-left">
                                    Masculino
                                </td>
                                <td class="text-left">
                                    15/Ene/1980
                                </td>
                                <td class="text-left">
                                    Soltero
                                </td>
                                <td class="text-left">
                                    XXXXX-15055
                                </td>
                                <td class="text-left">
                                    Banco HD Leon
                                </td>
                                <td class="text-right">
                                    RD$ 120,000.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left">
                                    ITLA CALETA
                                </td>
                                <td class="text-left">
                                    Finanzas
                                </td>
                                <td class="text-left">
                                    Grupo ocupacional I
                                </td>
                                <td class="text-left">
                                    Tipo de contrato A
                                </td>
                                <td class="text-left">
                                    Tipo de empleado A
                                </td>
                                <td class="text-left">
                                    Gerente general
                                </td>
                                <td class="text-left">
                                    15/dic/2023
                                </td>
                                <td class="text-left">
                                    En proceso
                                </td>
                                <td class="text-left">
                                    Cédula
                                </td>
                                <td class="text-left">
                                    000-0000000-0
                                </td>
                                <td class="text-left">
                                    Julisy
                                </td>
                                <td class="text-left">
                                    Amador
                                </td>
                                <td class="text-left">
                                    Masculino
                                </td>
                                <td class="text-left">
                                    15/Ene/1980
                                </td>
                                <td class="text-left">
                                    Soltero
                                </td>
                                <td class="text-left">
                                    XXXXX-15055
                                </td>
                                <td class="text-left">
                                    Banco HD Leon
                                </td>
                                <td class="text-right">
                                    RD$ 120,000.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left">
                                    ITLA CALETA
                                </td>
                                <td class="text-left">
                                    Finanzas
                                </td>
                                <td class="text-left">
                                    Grupo ocupacional I
                                </td>
                                <td class="text-left">
                                    Tipo de contrato A
                                </td>
                                <td class="text-left">
                                    Tipo de empleado A
                                </td>
                                <td class="text-left">
                                    Gerente general
                                </td>
                                <td class="text-left">
                                    15/dic/2023
                                </td>
                                <td class="text-left">
                                    En proceso
                                </td>
                                <td class="text-left">
                                    Cédula
                                </td>
                                <td class="text-left">
                                    000-0000000-0
                                </td>
                                <td class="text-left">
                                    Julisy
                                </td>
                                <td class="text-left">
                                    Amador
                                </td>
                                <td class="text-left">
                                    Masculino
                                </td>
                                <td class="text-left">
                                    15/Ene/1980
                                </td>
                                <td class="text-left">
                                    Soltero
                                </td>
                                <td class="text-left">
                                    XXXXX-15055
                                </td>
                                <td class="text-left">
                                    Banco HD Leon
                                </td>
                                <td class="text-right">
                                    RD$ 120,000.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left">
                                    ITLA CALETA
                                </td>
                                <td class="text-left">
                                    Finanzas
                                </td>
                                <td class="text-left">
                                    Grupo ocupacional I
                                </td>
                                <td class="text-left">
                                    Tipo de contrato A
                                </td>
                                <td class="text-left">
                                    Tipo de empleado A
                                </td>
                                <td class="text-left">
                                    Gerente general
                                </td>
                                <td class="text-left">
                                    15/dic/2023
                                </td>
                                <td class="text-left">
                                    En proceso
                                </td>
                                <td class="text-left">
                                    Cédula
                                </td>
                                <td class="text-left">
                                    000-0000000-0
                                </td>
                                <td class="text-left">
                                    Julisy
                                </td>
                                <td class="text-left">
                                    Amador
                                </td>
                                <td class="text-left">
                                    Masculino
                                </td>
                                <td class="text-left">
                                    15/Ene/1980
                                </td>
                                <td class="text-left">
                                    Soltero
                                </td>
                                <td class="text-left">
                                    XXXXX-15055
                                </td>
                                <td class="text-left">
                                    Banco HD Leon
                                </td>
                                <td class="text-right">
                                    RD$ 120,000.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left">
                                    ITLA CALETA
                                </td>
                                <td class="text-left">
                                    Finanzas
                                </td>
                                <td class="text-left">
                                    Grupo ocupacional I
                                </td>
                                <td class="text-left">
                                    Tipo de contrato A
                                </td>
                                <td class="text-left">
                                    Tipo de empleado A
                                </td>
                                <td class="text-left">
                                    Gerente general
                                </td>
                                <td class="text-left">
                                    15/dic/2023
                                </td>
                                <td class="text-left">
                                    En proceso
                                </td>
                                <td class="text-left">
                                    Cédula
                                </td>
                                <td class="text-left">
                                    000-0000000-0
                                </td>
                                <td class="text-left">
                                    Julisy
                                </td>
                                <td class="text-left">
                                    Amador
                                </td>
                                <td class="text-left">
                                    Masculino
                                </td>
                                <td class="text-left">
                                    15/Ene/1980
                                </td>
                                <td class="text-left">
                                    Soltero
                                </td>
                                <td class="text-left">
                                    XXXXX-15055
                                </td>
                                <td class="text-left">
                                    Banco HD Leon
                                </td>
                                <td class="text-right">
                                    RD$ 120,000.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left">
                                    ITLA CALETA
                                </td>
                                <td class="text-left">
                                    Finanzas
                                </td>
                                <td class="text-left">
                                    Grupo ocupacional I
                                </td>
                                <td class="text-left">
                                    Tipo de contrato A
                                </td>
                                <td class="text-left">
                                    Tipo de empleado A
                                </td>
                                <td class="text-left">
                                    Gerente general
                                </td>
                                <td class="text-left">
                                    15/dic/2023
                                </td>
                                <td class="text-left">
                                    En proceso
                                </td>
                                <td class="text-left">
                                    Cédula
                                </td>
                                <td class="text-left">
                                    000-0000000-0
                                </td>
                                <td class="text-left">
                                    Julisy
                                </td>
                                <td class="text-left">
                                    Amador
                                </td>
                                <td class="text-left">
                                    Masculino
                                </td>
                                <td class="text-left">
                                    15/Ene/1980
                                </td>
                                <td class="text-left">
                                    Soltero
                                </td>
                                <td class="text-left">
                                    XXXXX-15055
                                </td>
                                <td class="text-left">
                                    Banco HD Leon
                                </td>
                                <td class="text-right">
                                    RD$ 120,000.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left">
                                    ITLA CALETA
                                </td>
                                <td class="text-left">
                                    Finanzas
                                </td>
                                <td class="text-left">
                                    Grupo ocupacional I
                                </td>
                                <td class="text-left">
                                    Tipo de contrato A
                                </td>
                                <td class="text-left">
                                    Tipo de empleado A
                                </td>
                                <td class="text-left">
                                    Gerente general
                                </td>
                                <td class="text-left">
                                    15/dic/2023
                                </td>
                                <td class="text-left">
                                    En proceso
                                </td>
                                <td class="text-left">
                                    Cédula
                                </td>
                                <td class="text-left">
                                    000-0000000-0
                                </td>
                                <td class="text-left">
                                    Julisy
                                </td>
                                <td class="text-left">
                                    Amador
                                </td>
                                <td class="text-left">
                                    Masculino
                                </td>
                                <td class="text-left">
                                    15/Ene/1980
                                </td>
                                <td class="text-left">
                                    Soltero
                                </td>
                                <td class="text-left">
                                    XXXXX-15055
                                </td>
                                <td class="text-left">
                                    Banco HD Leon
                                </td>
                                <td class="text-right">
                                    RD$ 120,000.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left">
                                    ITLA CALETA
                                </td>
                                <td class="text-left">
                                    Finanzas
                                </td>
                                <td class="text-left">
                                    Grupo ocupacional I
                                </td>
                                <td class="text-left">
                                    Tipo de contrato A
                                </td>
                                <td class="text-left">
                                    Tipo de empleado A
                                </td>
                                <td class="text-left">
                                    Gerente general
                                </td>
                                <td class="text-left">
                                    15/dic/2023
                                </td>
                                <td class="text-left">
                                    En proceso
                                </td>
                                <td class="text-left">
                                    Cédula
                                </td>
                                <td class="text-left">
                                    000-0000000-0
                                </td>
                                <td class="text-left">
                                    Julisy
                                </td>
                                <td class="text-left">
                                    Amador
                                </td>
                                <td class="text-left">
                                    Masculino
                                </td>
                                <td class="text-left">
                                    15/Ene/1980
                                </td>
                                <td class="text-left">
                                    Soltero
                                </td>
                                <td class="text-left">
                                    XXXXX-15055
                                </td>
                                <td class="text-left">
                                    Banco HD Leon
                                </td>
                                <td class="text-right">
                                    RD$ 120,000.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left">
                                    ITLA CALETA
                                </td>
                                <td class="text-left">
                                    Finanzas
                                </td>
                                <td class="text-left">
                                    Grupo ocupacional I
                                </td>
                                <td class="text-left">
                                    Tipo de contrato A
                                </td>
                                <td class="text-left">
                                    Tipo de empleado A
                                </td>
                                <td class="text-left">
                                    Gerente general
                                </td>
                                <td class="text-left">
                                    15/dic/2023
                                </td>
                                <td class="text-left">
                                    En proceso
                                </td>
                                <td class="text-left">
                                    Cédula
                                </td>
                                <td class="text-left">
                                    000-0000000-0
                                </td>
                                <td class="text-left">
                                    Julisy
                                </td>
                                <td class="text-left">
                                    Amador
                                </td>
                                <td class="text-left">
                                    Masculino
                                </td>
                                <td class="text-left">
                                    15/Ene/1980
                                </td>
                                <td class="text-left">
                                    Soltero
                                </td>
                                <td class="text-left">
                                    XXXXX-15055
                                </td>
                                <td class="text-left">
                                    Banco HD Leon
                                </td>
                                <td class="text-right">
                                    RD$ 120,000.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left">
                                    ITLA CALETA
                                </td>
                                <td class="text-left">
                                    Finanzas
                                </td>
                                <td class="text-left">
                                    Grupo ocupacional I
                                </td>
                                <td class="text-left">
                                    Tipo de contrato A
                                </td>
                                <td class="text-left">
                                    Tipo de empleado A
                                </td>
                                <td class="text-left">
                                    Gerente general
                                </td>
                                <td class="text-left">
                                    15/dic/2023
                                </td>
                                <td class="text-left">
                                    En proceso
                                </td>
                                <td class="text-left">
                                    Cédula
                                </td>
                                <td class="text-left">
                                    000-0000000-0
                                </td>
                                <td class="text-left">
                                    Julisy
                                </td>
                                <td class="text-left">
                                    Amador
                                </td>
                                <td class="text-left">
                                    Masculino
                                </td>
                                <td class="text-left">
                                    15/Ene/1980
                                </td>
                                <td class="text-left">
                                    Soltero
                                </td>
                                <td class="text-left">
                                    XXXXX-15055
                                </td>
                                <td class="text-left">
                                    Banco HD Leon
                                </td>
                                <td class="text-right">
                                    RD$ 120,000.00
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer d-flex justify-content-end">
                    <button type="button" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success">
                        <i class="flaticon-etiqueta"></i>
                        Procesar
                    </button>
                </div>
            </div>
        </div>
    </div>


</ng-container>
