<app-modal-layout (onClose)="close()">
    <ng-container title>
        {{sending ? 'Enviar Nómina' : 'Agregar Detalle del Gasto'}}
    </ng-container>
    <ng-container body>
        <form [formGroup]="form">

            <div class="row mb-3">
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Período </label>
                    <input [value]="data?.item?.period " disabled
                        class="form-control form-control-solid form-control-lg">
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Tipo de Nómina </label>
                    <input [value]="data?.item?.payrollType " disabled
                        class="form-control form-control-solid form-control-lg">
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Área Presupuestaria </label>
                    <input [value]="data?.item?.budgetArea " disabled
                        class="form-control form-control-solid form-control-lg">
                </div>
            </div>
            <div class="row mb-3 text-primary text-center justify-content-center">
                <hr>
            </div>
            <div class="row">
                <div *ngIf="data?.item?.payrollTypeId !== 4 && data?.item?.payrollTypeId !== 1"
                    class="form-group col-lg-6 col-xxl-3">
                    <label>Tipo de Servidor Público <span class="text-danger"> *</span></label>
                    <ngx-select-dropdown [config]="employeeTypeConfig" [options]="employeeTypes"
                        formControlName="employeeType" class=" flex-1" (change)="changeEmployeeType()">
                    </ngx-select-dropdown>
                    <errors-component [controlName]="form.get('employeeType')"></errors-component>
                </div>
                <div *ngIf="data?.item?.payrollTypeId !== 4 && data?.item?.payrollTypeId !== 1 && data?.item?.payrollTypeId !== 0" class="form-group col-lg-6 col-xxl-3">
                    <label>Estatus del servidor público <span class="text-danger"> *</span></label>
                    <ngx-select-dropdown [config]="employeeTypeConfig" [options]="statusOptions"
                        formControlName="employeeStatus" class=" flex-1" (change)="changeEmployeeType()">
                    </ngx-select-dropdown>
                    <errors-component [controlName]="form.get('employeeType')"></errors-component>
                </div>
                <div *ngIf="data?.item?.payrollTypeId === 4 || data?.item?.payrollTypeId === 1"
                    class="form-group col-lg-6 col-xxl-3">
                    <label>Concepto <span class="text-danger"> *</span></label>
                    <ngx-select-dropdown [config]="payrollConceptsConfig" [options]="payrollConcepts"
                        (change)="changeConcept()" formControlName="payrollConcept" class=" flex-1">
                    </ngx-select-dropdown>
                    <errors-component [controlName]="form.get('payrollConcept')"></errors-component>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Documento Compromiso<span class="text-danger" *ngIf="sending"> *</span></label>
                    <ngx-select-dropdown [options]="preventiveDocuments" [config]="preventiveDocumentConfig"
                        formControlName="preventiveDocument" class=" flex-1"> </ngx-select-dropdown>
                    <errors-component [controlName]="form.get('preventiveDocument')"></errors-component>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Tipo de Transacción Gasto <span class="text-danger"> *</span></label>
                    <ngx-select-dropdown [options]="transactionTypes" [config]="transactionTypeConfig"
                        formControlName="transactionType" class=" flex-1"> </ngx-select-dropdown>
                    <errors-component [controlName]="form.get('transactionType')"></errors-component>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Proceso Presupuestario<span class="text-danger"> *</span></label>
                    <ngx-select-dropdown [options]="budgetaryProceses" formControlName="budgetProcess"
                        [config]="budgetaryProcesesConfig" class=" flex-1"> </ngx-select-dropdown>
                    <errors-component [controlName]="form.get('budgetProcess')"></errors-component>
                </div>
                <!-- <div class="form-group col-lg-6 col-xxl-3">
                    <label>Fuente Especifica<span class="text-danger"> *</span></label>
                    <ngx-select-dropdown [options]="specificSources" formControlName="specificSource" [config]="specificSourcesConfig" class=" flex-1" > </ngx-select-dropdown>
                    <span class="text-danger"></span>
                </div> -->
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Beneficiario<span class="text-danger"> *</span></label>

                    <div class="input-group">
                        <input formControlName="beneficiary" class="form-control form-control-solid form-control-lg"
                            (keyup)="checkBeneficiary()">
                        <button type="button" class="btn btn-warning" (click)="openOtherBeneficiary()"
                            *ngIf="thereIsOtherBeneficiary && !sending" data-toggle="tooltip"
                            title="Ver Beneficiarios Disponibles">
                            <i class="fa fa-lightbulb"></i>
                        </button>
                    </div>
                    <errors-component [controlName]="form.get('beneficiary')"></errors-component>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Importe de Seguridad Social</label>
                    <input formControlName="amount" placeholder="RD$0.00" type="text" currencyMask
                        [options]="{precision:2}" class="form-control form-control-solid form-control-lg">
                    <errors-component [controlName]="form.get('amount')"></errors-component>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Cuenta Bancaria<span class="text-danger"> *</span></label>
                    <input formControlName="bankAccount" type="text"
                        class="form-control form-control-solid form-control-lg">
                    <errors-component [controlName]="form.get('bankAccount')"></errors-component>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Sub-Cuenta Bancaria<span class="text-danger"> *</span></label>
                    <input formControlName="subBankAccount" type="text"
                        class="form-control form-control-solid form-control-lg">
                    <errors-component [controlName]="form.get('subBankAccount')"></errors-component>
                </div>

            </div>
            <div class="row">
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Descripción<span class="text-danger"> *</span></label>
                    <input formControlName="descripcion" type="text"
                        class="form-control form-control-solid form-control-lg" placeholder="PAGO XXXX">
                    <errors-component [controlName]="form.get('descripcion')"></errors-component>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Coletilla<span class="text-danger"> *</span></label>
                    <input formControlName="coletilla" type="text"
                        class="form-control form-control-solid form-control-lg" placeholder="PAGO XXXX 032023">
                    <errors-component [controlName]="form.get('coletilla')"></errors-component>
                </div>
            </div>
        </form>


        <h3> Partidas del Gasto</h3>
        <div class="card-body" style="padding: 0;">
            <div class="table-responsive">
                <table class="table table-hover table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th>Programa</th>
                            <th>Producto</th>
                            <th>Proyecto</th>
                            <th>Actividad Obra</th>
                            <th>Fondo</th>
                            <th>Organismo Financiador</th>
                            <th>Región</th>
                            <th>Provincia</th>
                            <th>Municipio</th>
                            <th>Fuente Específica</th>
                            <th>Función</th>
                            <th>CCP</th>
                            <th class="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let partida of budgetSourcesPaginated" style="left: 0px;">
                            <td>{{partida?.programa || 'N/A'}}</td>
                            <td>{{partida?.subprograma || 'N/A'}}</td>
                            <td>{{partida?.proyecto || 'N/A'}}</td>
                            <td>{{partida?.actividadObra || 'N/A'}}</td>
                            <td>{{partida?.fondo || 'N/A'}}</td>
                            <td>{{partida?.organismoFinanciador || 'N/A'}}</td>
                            <td>{{partida?.region || 'N/A'}}</td>
                            <td>{{partida?.provincia || 'N/A'}}</td>
                            <td>{{partida?.municipio || 'N/A'}}</td>
                            <td>{{partida?.fuente || 'N/A'}}</td>
                            <td>{{partida?.funcion || 'N/A'}}</td>
                            <td>{{partida?.ccp || 'N/A'}}</td>
                            <td class="text-center">
                                <a class="btn btn-sm btn-light-success btn-icon mr-2" data-toggle="tooltip"
                                    title="Ver Partida" (click)="openDetail(partida)">
                                    <i class="flaticon-productividad icon-2x "></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <el-caption #paginator [registerQuantity]="budgetSources.length"
                        [originalRecordsLength]="budgetSources.length"
                        [listedRecordsLength]="budgetSourcesPaginated.length" [records]="budgetSources"
                        (onChage)="getPaginatedRecords($event)"></el-caption>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container buttons>
        <button (click)="close()"
            class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2"
            type="button">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
        <button *ngIf="sending" (click)="send()" class="btn btn-pill font-weight-bolder btn-shadow btn-info"
            type="button">
            <i class="flaticon-enviar"></i>
            <span>Enviar</span>
        </button>
        <button *ngIf="!sending" (click)="save()"
            class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
            <i class="flaticon-disco-flexible"></i>
            <span>Guardar</span>
        </button>
    </ng-container>
</app-modal-layout>