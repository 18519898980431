import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApexNonAxisChartSeries, ApexChart, ApexResponsive, ApexFill, ApexStroke, ApexStates, ApexLegend, ApexTitleSubtitle, ApexTheme, ApexPlotOptions, ApexDataLabels, ApexAxisChartSeries, ApexXAxis, ApexYAxis, ApexTooltip } from 'ng-apexcharts';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { DataService } from 'src/app/services/data.service';
import { HomeService } from 'src/app/services/home.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
import { Account } from 'src/app/shared/models/account';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { getPercentage } from 'src/app/shared/utils/utility';
import { mapArrays } from 'src/app/shared/utils/utility';
import { ChartDepartments, ChartGender, DashboardPayrollPeriodTypes, EmployeeDepartmentQuantity, employeeSexDepartment, Genders, IDashboardResponse, IDashboardSelect, IDonutChartData, IEmployeeByCompany, IEmployeeByContract, IGetDepartments, IHiringsPerYear, IInterviewByDepartment, InterviewDepartmentsQuantity, IPeriodsGET, IResponseHome, IRquisitionByMonth, PayrollByDeparment, payrollBylast3month }
  from './home.models';
import { CurrencyPipe } from '@angular/common';
import { FilterDashboardService } from 'src/app/services/filter-dashboard.service';
import * as moment from 'moment';


interface barClustered { rowText: string, value1: number, value2: number }
interface chartPie { description: string, value: number }
interface semiCircle { description: string, value: number }
interface barChartRace { description: string, value: number }
interface sortedBar { description: string, value: number }
interface chart { description: string, value: number }
interface chartSource { description: string, value: number }
declare const KTWizard1: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  statusHiringOpts = [
    { id: 1, text: 'Activos' },
    { id: 2, text: 'Inactivos' },
  ]
  dropdownStatusHirigns: any = this.statusHiringOpts[0];
  payrollDepartment = []
  currencyPipe = new CurrencyPipe('en-Us')
  hirings = []
  departments: IGetDepartments[] = [];
  payrollLast3Months: any[] = []
  dropdownEmployeeSexDepartment: IGetDepartments[] = [];
  dropdownInterviewDepartment: IGetDepartments[] = [];
  dropdownEmployeeDepartment: IGetDepartments[] = [];
  employeeDepartments: EmployeeDepartmentQuantity[] = [];
  emSexDep: employeeSexDepartment[] = [];
  interDepart: InterviewDepartmentsQuantity[] = [];
  payrollDepartmentChart: PayrollByDeparment[] = []
  hiringsByUnlink = []
  filterOptions = [
    { item_id: 1, item_text: '2022' },
    { item_id: 2, item_text: '2021' },
    { item_id: 3, item_text: '2020' },
    { item_id: 4, item_text: '2019' },
  ]
  yearSelected;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: false,
    itemsShowLimit: 5,
    allowSearchFilter: false,
    limitSelection: -1,

  };
  monthList = [
    { monthNumber: 1, month: 'Enero', englishMonth: 'January' },
    { monthNumber: 2, month: 'Febrero', englishMonth: 'February' },
    { monthNumber: 3, month: 'Marzo', englishMonth: 'March' },
    { monthNumber: 4, month: 'Abril', englishMonth: 'April' },
    { monthNumber: 5, month: 'Mayo', englishMonth: 'May' },
    { monthNumber: 6, month: 'Junio', englishMonth: 'June' },
    { monthNumber: 7, month: 'Julio', englishMonth: 'July' },
    { monthNumber: 8, month: 'Agosto', englishMonth: 'August' },
    { monthNumber: 9, month: 'Septiembre', englishMonth: 'September' },
    { monthNumber: 10, month: 'Octubre', englishMonth: 'October' },
    { monthNumber: 11, month: 'Noviembre', englishMonth: 'November' },
    { monthNumber: 12, month: 'Diciembre', englishMonth: 'December' },
  ]

  vacantPerYear: semiCircle[] = []
  employeeGenderDepartment: barClustered[] = []
  periods: IPeriodsGET[] = [];
  dropdownPeriod: any;
  interviewsByDepartment: barChartRace[] = []
  hiringsPerYear: sortedBar[] = []
  hiringsPerMonth: sortedBar[] = []
  year = new Date().getFullYear()
  filterParams = {
    date1: `1980-01-01`,
    date2: `${this.year}-12-31`,
    year: this.year,
    yearsList: [],
    companyId: this.authInfo.getCompanyId()
  }
  personsByCompany: chart[] = [];
  employeeByContractTypes: chartSource[] = [];
  requisitionMonth: barChartRace[] = []
  incompleteProfiles: barChartRace[] = []
  dropdownPayrollDepartment: any;
  dropdownPayrollDepartment2: any;
  cardsStats = {
    activeEmployees: 0,
    maleEmployees: {
      total: 0,
      percentage: 0
    },
    femaleEmployees: {
      total: 0,
      percentage: 0
    },
    department: 0,
    users: 0,
    vacants: 0
  }

  employeeByDepartments = [];
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';

  constructor(
    private srvHome: HomeService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private dataService: DataService,
    private accountService: AccountService,
    private activateRoute: ActivatedRoute,
    private authInfo: AuthInfoService,
    private filterService: FilterDashboardService,
  ) {

  }

  ngOnInit(): void {

    const isLoadJsString = localStorage.getItem('isLoadJs');
    if (!JSON.parse(isLoadJsString)) {
      localStorage.setItem('isLoadJs', JSON.stringify(true));
      location.reload();
      KTWizard1.init();
    }
    this.getCompanies()
    this.getPeriods()
    //this.VerifyUserSession();
  }

  openFilter() {
    this.dialog.open(FilterDashboardComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        homeConfig: true,
        filterParams: this.filterParams,
        hideYearField: false,
        companyId: this.filterParams.companyId
      }
    }).afterClosed().subscribe(res => {
      if (!res) return
      if (res.year) {
        this.filterParams.year = res.year
      }
      if (res.yearsList) {
        this.filterParams.yearsList = res.yearsList
      }
      const { date1, date2, companyId } = res
      if (date1) {
        this.filterParams.date1 = date1
      }
      if (date2) {
        this.filterParams.date2 = date2
      }
      if (!date1 && !date2) {
        return
      }
      if (!date1 && !date2) {
        this.filterParams.companyId = companyId;
      }
      this.getHomeCharts()
    })
  }

  setPayrollLast3Months(arr: payrollBylast3month[]) {
    /* arr.forEach(e => {
      let parseToNumber = Intl.NumberFormat('en-US')
      e.description = `${e.description} \n (${e.value3 > 0 ? parseToNumber.format(e.value3) : 0})`
    }) */
    this.payrollLast3Months = arr
  }
  changeStatusHirings() {
    if (this.dropdownStatusHirigns.id == 1) {
      this.hirings = this.hiringsPerYear.map(hiring => {
        const amount = {
          ...hiring,
          value: hiring.value,
          description: `${hiring.description} (${hiring.value})`
        }
        return amount
      })
    }
    if (this.dropdownStatusHirigns.id == 2) {
      this.hirings = this.hiringsByUnlink.map(hiring => {
        const amount = {
          ...hiring,
          value: hiring.value,
          description: `${hiring.description} ( ${hiring.value})`
        }
        return amount
      })
    }
  }


  getHomeCharts() {
    const { date1, date2, yearsList, companyId } = this.filterParams

    let filtersYears = '';
    if (yearsList) {

      if (yearsList.length > 0) {
        for (let i = 0; i < yearsList.length; i++) {
          var getYear = yearsList[i].year
          filtersYears += getYear + "-"
        }
        filtersYears = filtersYears.substring(0, filtersYears.length - 1)
      }
    }

    this.srvHome.getHomeCharts(date1, date2, filtersYears.toString(), companyId).subscribe((res: IResponseHome) => {
      if (res.succeded) {
        let d = res.singleData;
        this.setRequisitionChart(d.requisitionMonthQuantities);
        this.setVacantYearChart(d.vacantsByYearQuantities);
        this.setHiringsYearChart(d.hiringAgeQuantities);
        this.setHiringsMonthChart(d.hiringMonthQuantities);
        this.setIncompleteProfilesChart(d.totalEmployeeIncompleteInformation);

        this.hirings = d.hiringAgeQuantities;
        this.hiringsPerYear = d.hiringAgeQuantities;
        this.hiringsByUnlink = d.hiringUnlinkQuantities;
        this.setPayrollLast3Months(d.payrollBylast3month)
        this.setEmployeeCompanyChart(d.employeeByCompaniesQuantities)
        this.setEmployeeContractTypeChart(d.employeeTypeContractQuantities)
        this.interDepart = d.interviewDepartmentsQuantities
        this.employeeDepartments = d.employeeDepartmentQuantities
        let cardsData = {
          employees: d.totalEmployeesQuantities[0]?.value,
          genders: d.employeeGenderQuantities,
          departments: d.totalDepartmentsQuantities[0]?.value,
          users: d.totalUserQuantities[0]?.value,
          vacants: d.totalVacantsQuantities[0]?.value
        }
        this.setCardsData(cardsData);
        this.emSexDep = this.parseDepartmentSexData(d.totalGenderByDeparmentQuantities);
        this.getDepartments();
        this.changeStatusHirings();

      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {

    })
  }

  parseDepartmentSexData(dataList): employeeSexDepartment[] {
    let departmentTexts: string[] = dataList.map(e => e.description)
    let departmentNonDuplicated: string[] = [...new Set(departmentTexts)] /* Hace un array quitando los string duplicados */

    let data2: employeeSexDepartment[] = []

    departmentNonDuplicated.forEach(departmentName => {

      let male = dataList.find(x => x.description == departmentName && x.description2 == Genders.male) ?? { description: departmentName, description2: Genders.male, value: 0 };
      let female = dataList.find(x => x.description == departmentName && x.description2 == Genders.female) ?? { description: departmentName, description2: Genders.female, value: 0 };

      let ob: employeeSexDepartment = {
        rowText: departmentName ?? "No definido",
        value1: male.value,
        value2: female.value
      }
      data2.push(ob)
    })
    return data2;
  }

  changeEmployee_Dep() {
    let departmentDropdown = []
    for (let i = 0; i < this.dropdownEmployeeDepartment.length; i++) {
      const element = this.dropdownEmployeeDepartment[i];
      const a = this.departments.find(x => x.departmentName == element.departmentName)
      departmentDropdown.push(a)
    }
    this.employeeByDepartments = []
    if (departmentDropdown.length < 1) {
      return
    }
    let data = []
    departmentDropdown.forEach(e => {
      const departmentText = (e.shortName ?? e.departmentName)
      let chartValue = {
        description: `${departmentText} (0)`,
        value: 0
      }
      const departmentValues = this.employeeDepartments.find(x => x.description == departmentText)
      if (departmentValues) {
        chartValue = {
          description: `${departmentValues.description} (${departmentValues.value})`,
          value: departmentValues.value
        }
      }
      data.push(chartValue)
    })
    this.employeeByDepartments = data
  }


  getDepartments() {
    this.srvHome.getDepartments().subscribe((res: ResponseModel<IGetDepartments>) => {
      if (res.succeded) {
        this.departments = res?.dataList?.sort((departmentA: any, departmentB: any) => {
          return departmentA?.order - departmentB?.order
        });
        this.getPeriods()
        this.setDropDownValues()
        this.setDepartmentInterviewChart()
        this.setEmployeeByDepartmentChart()
        this.setEmployeeGenderChart()
      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error(this.errorMessage)
    })
  }
  setEmployeeGenderChart() {
    /* Se filtra la data del chart con el departamento seleccionado */
    const dropdown = this.dropdownEmployeeSexDepartment
    const department = (dropdown[0]?.shortName ?? dropdown[0]?.departmentName)
    this.changeSexDepartment()
    // this.employeeGenderDepartment = [this.emSexDep.find(x => x.rowText == department)]
  }
  setDepartmentInterviewChart() {
    /* Se filtra la data del chart con el departamento seleccionado */
    const dropdown = mapArrays(this.dropdownInterviewDepartment, this.departments, 'departmentId')
    const department = (dropdown[0].shortName ?? dropdown[0].departmentName)
    const chartDataFiltered = this.interDepart.find(x => x.description == department)
    let values = {
      description: `${department} (0)`,
      value: 0
    }
    if (chartDataFiltered) {
      values = {
        description: `${chartDataFiltered.description} (${chartDataFiltered.value})`,
        value: chartDataFiltered.value
      }
    }
    this.interviewsByDepartment = [values]
  }
  getDepartmentsHasData(text: string): boolean {
    const find = this.employeeDepartments.find(x => x.description == text && x.value > 0)
    return find ? true : false;
  }
  setDropDownValues() {
    let selectDep: IGetDepartments = this.departments.find(x => x.isHhrr == true) ?? this.departments[0]
    this.dropdownInterviewDepartment = [selectDep]

    let data: IGetDepartments[] = []
    let count = 0
    let data2 = []
    this.departments.every(e => {
      if (count < 5) {
        if (this.getDepartmentsHasData(e.shortName)) {
          data.push(e)
          count++;
        }
        return true
      } else {
        return false
      }
    })
    this.departments.forEach(e => {
      if (this.getDepartmentsHasData(e.shortName)) {
        data2.push(e)
      }
    })
    this.dropdownPayrollDepartment = data
    this.dropdownEmployeeDepartment = data2;
    this.dropdownEmployeeSexDepartment = data2?.slice(0, 10);
  }
  getPeriods() {
    this.srvHome.getPeriods(DashboardPayrollPeriodTypes.allPeriodClosePayrollDefinitiveOrderDesc, this.filterParams.companyId).subscribe((res: any) => {
      if (res.succeded) {
        this.periods = res.dataList
        const currentPeriod = this.periods[0]
        this.dropdownPeriod = currentPeriod ? currentPeriod : null
        if (currentPeriod) {
          this.getPayrollByPeriod()
        }
      } else {
        this.toastr.error(res.errors[0])
      }
    })
  }
  changePeriod() {
    if (this.dropdownPeriod.payrollPeriodId) {
      this.getPayrollByPeriod()
    }
  }

  getPayrollByPeriod() {
    const periodId = this.dropdownPeriod.payrollPeriodId
    this.srvHome.getHeadersByPeriod(periodId, this.filterParams.companyId).subscribe((res: any) => {
      if (res.succeded) {
        const response: IDashboardSelect = res.singleData
        this.payrollDepartment = response.payrollByDeparment
        this.setPayrollDepartmentChart(response.payrollByDeparment)
      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }

  setPayrollDepartmentChart(arr: PayrollByDeparment[]) {
    const drop = mapArrays(this.dropdownPayrollDepartment, this.departments, 'departmentId')
    if (drop.length > 0) {
      const d = []
      drop.forEach(e => {
        d.push(arr.find(x => x.description == e.shortName))
      })
      this.payrollDepartmentChart = d.map(pDepartment => {
        let textAmount = this.currencyPipe.transform(pDepartment.value3, 'RD$')
        const amount = {
          ...pDepartment,
          value: pDepartment.value3,
          description: `${pDepartment.description} ( ${textAmount})`
        }
        return amount
      })
    } else {
      this.payrollDepartmentChart = []
    }

  }
  changePayrollDepartment() {
    const drop = mapArrays(this.dropdownPayrollDepartment, this.departments, 'departmentId')
    /* if (!Array.isArray(drop)) {
      const data = this.payrollDepartment
      const chartData = data.filter(x => x.description == drop.shortName)
      if (chartData.length > 0) {
        this.payrollDepartmentChart = chartData
      }
      return
    } */
    if (drop.length == 0) {
      this.payrollDepartmentChart = []
    } else {
      const arr = []
      drop.forEach(e => {
        const find = this.payrollDepartment.find(x => x.description == e.shortName)
        if (find) {
          arr.push(find)
        } else {
          arr.push({
            description: e.shortName,
            value3: 0
          })
        }
      })
      this.payrollDepartmentChart = arr.map(pDepartment => {
        let textAmount = this.currencyPipe.transform(pDepartment.value3, 'RD$')
        const amount = {
          ...pDepartment,
          value: pDepartment.value3,
          description: `${pDepartment.description} ( ${textAmount})`
        }
        return amount
      })
    }
  }
  setEmployeeByDepartmentChart() {
    if (this.dropdownEmployeeDepartment.length < 1) {
      this.employeeByDepartments = []
      return
    }
    const drop = mapArrays(this.dropdownEmployeeDepartment, this.departments, 'departmentId')
    let data = []
    drop.forEach(element => {
      const departmentText = (element.shortName ?? element.departmentName)
      const departmentData = this.employeeDepartments.find(x => x.description == departmentText)
      let chartValues = {
        description: `${departmentText} (0)`,
        value: 0
      }
      if (departmentData) {
        chartValues = {
          description: `${departmentData.description} (${departmentData.value})`,
          value: departmentData.value
        }
      }
      data.push(chartValues)
    });
    this.employeeByDepartments = data
  }
  changeInterviewDepartment() {
    let departmentDropdown = []
    for (let i = 0; i < this.dropdownInterviewDepartment.length; i++) {
      const element = this.dropdownInterviewDepartment[i];
      const a = this.departments.find(x => x.departmentName == element.departmentName)
      departmentDropdown.push(a)
    }
    if (departmentDropdown.length < 1) {
      this.interviewsByDepartment = []
      return
    }
    let data: barChartRace[] = []
    this.interviewsByDepartment = []
    departmentDropdown.forEach(e => {
      let chartData: any = this.interDepart.find(x => x.description == e.shortName)
      if (!chartData) {
        chartData = this.interDepart.find(x => x.description == e.departmentName) ?? { description: `${e.departmentName} (0)`, value: 0 }
      }
      let cData = Object.assign({}, chartData)
      data.push(cData)
    })
    this.interviewsByDepartment = data
  }
  changeSexDepartment() {
    let departmentDropdown = []
    for (let i = 0; i < this.dropdownEmployeeSexDepartment.length; i++) {
      const element = this.dropdownEmployeeSexDepartment[i];
      const a = this.departments.find(x => x.departmentName == element.departmentName)
      departmentDropdown.push(a)
    }
    this.employeeGenderDepartment = []
    if (departmentDropdown.length < 1) {
      return
    }
    let data: barClustered[] = []
    departmentDropdown.forEach(e => {
      let chartData: barClustered = this.emSexDep.find(x => x.rowText == e.shortName)
      if (!chartData) {
        chartData = this.emSexDep.find(x => x.rowText == e.departmentName) ?? { rowText: e.shortName, value1: 0, value2: 0 }
      }
      let cData = Object.assign({}, chartData)
      data.push(cData)
    });
    this.employeeGenderDepartment = data
  }

  getPercentage(total: number, count: number) {
    return getPercentage(total, count)
  }


  dropdownConfig(displayKey, placeholder?, search = true) {
    return {
      displayKey: displayKey,
      search: search,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }
  setRequisitionChart(dataList: barChartRace[]) {
    let data = []
    this.monthList.forEach(e => {
      let count = dataList.find(x => Number(x.description) == e.monthNumber)
      data.push(
        { description: e.month + ` (${count ? count.value : 0})`, value: count ? count.value : 0 }
      )
    })
    this.requisitionMonth = data
  }
  setIncompleteProfilesChart(dataList: barChartRace[]) {
    let data = []
    dataList.forEach(e => {
      let count = dataList.find(x => x.description == e.description)
      data.push(
        { description: e.description + ` (${count ? count.value : 0})`, value: count ? count.value : 0 }
      )
    })
    this.incompleteProfiles = data;
  }

  setVacantYearChart(dataList: semiCircle[]) {
    dataList.forEach(x => {
      x.description = `${x.description} (${x.value})`
    })
    this.vacantPerYear = dataList
  }
  setHiringsYearChart(dataList: sortedBar[]) {
    this.hiringsPerYear = dataList
  }

  setHiringsMonthChart(dataList: sortedBar[]) {
    let data = []
    this.monthList.forEach(e => {
      let count = dataList.find(x => x.description == e.englishMonth)
      data.push(
        { description: `${e.month.substring(0, 3)} (${count.value})`, value: count ? count.value : 0 }
      )
    })
    this.hiringsPerMonth = data
  }
  getCompanies(){
    const companyId = this.authInfo.getCompanyId()
    this.filterService.getCompaniesById(companyId).subscribe((res:any) => {
      if(res.succeded){
        const company = res.dataList.find(x => x.companyId == this.authInfo.getCompanyId())
        if(!company){
          return
        }
        
        this.filterParams.date1 = !company.constitutionDate ? null : moment(company.constitutionDate).startOf('date').format('yyyy-MM-D')
        this.filterParams.date2 = moment().startOf('date').format('yyyy-MM-DD')
        this.getHomeCharts()
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }
  setEmployeeCompanyChart(dataList: chart[]) {
    this.personsByCompany = dataList?.map(data => {
      return {
        ...data,
        description: `${data.description} (${data.value})`
      }
    })
  }
  setEmployeeContractTypeChart(dataList: chartSource[]) {
    this.employeeByContractTypes = dataList.map(data => {
      return {
        ...data,
        description: `${data.description} (${data.value})`
      }

    })
  }
  setCardsData(data) {
    let { employees, genders, departments, users, vacants } = data
    this.cardsStats.department = departments
    this.cardsStats.users = users
    this.cardsStats.activeEmployees = employees
    this.cardsStats.vacants = vacants
    let male = genders.find(x => x.description == Genders.male)?.value
    let female = genders.find(c => c.description == Genders.female)?.value
    let total = male + female;
    this.cardsStats.maleEmployees.total = male
    this.cardsStats.femaleEmployees.total = female
    this.cardsStats.maleEmployees.percentage = this.getPercentage(total, male)
    this.cardsStats.femaleEmployees.percentage = this.getPercentage(total, female)
  }

  changeChartData(action) {
    if (action == 'dropdownEmployeeDepartment') {
      this.changeEmployee_Dep()
    }
    if (action == 'dropdownEmployeeSexDepartment') {
      this.changeSexDepartment()
    }
    if (action == 'dropdownInterviewDepartment') {
      this.changeInterviewDepartment()
    }
    if (action == 'dropdownPayrollDepartment') {
      this.changePayrollDepartment()
    }
  }
  changeChartDataAll(event, action) {
    if (action == 'dropdownEmployeeDepartment') {
      this.dropdownEmployeeDepartment = event
      this.changeEmployee_Dep()
    }
    if (action == 'dropdownEmployeeSexDepartment') {
      this.dropdownEmployeeSexDepartment = event
      this.changeSexDepartment()
    }
    if (action == 'dropdownInterviewDepartment') {
      this.dropdownInterviewDepartment = event
      this.changeInterviewDepartment()
    }
    if (action == 'dropdownPayrollDepartment') {
      this.dropdownPayrollDepartment = event
      this.changePayrollDepartment()
    }
  }
  onDeSelect(event, action) {
    this.changeChartData(action)
  }
  onSelect(event, action) {
    this.changeChartData(action)
  }
  onDeSelectAll(event, action) {
    this.changeChartDataAll(event, action)
  }
  onSelectAll(event, action) {
    this.changeChartDataAll(event, action)
  }


  getDropdownSettings(idField = 'item_id', textField = 'item_text', checkAll = true) {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      enableCheckAll: checkAll,
      itemsShowLimit: 2,
      allowSearchFilter: true,
      limitSelection: -1,

    };
    return dropdownSettings
  }

}
