import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { LoadingService } from '../../loading/loading.service';

@Component({
  selector: 'el-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() animation: string = '';
  @Input() color: string = '';
  isLoading: Subject<boolean> = this._loadingService.loadingSub;
  @Output('loadingStatus') change = new EventEmitter<boolean>();
  constructor(
    private _loadingService:LoadingService
  ) { }

  ngOnInit(): void {
    this.onChange();
  }
  onChange(){
   this.isLoading.subscribe((loadingStatus)=>{
    this.change.emit(loadingStatus);
   })
  }
}
