  
<div class="card card-custom">
    <div class="card-body">    
        <div class="row gutter-b">
            <div class="table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-center">Tipo de Solicitud</th>
                            <th class="text-center">Solicitadas</th>
                            <th class="text-center">Rechazadas</th>
                            <th class="text-center">Aprobadas</th>
                            <th class="text-center" (click)="isVisible()">Pendientes</th>
                        </tr>
                    </thead>
                    <tbody>                            
                        <tr *ngFor="let item of dataList">                                
                            <td class="text-center text-primary big-td titles">{{item.requestType}}</td>
                            <td class="text-center text-primary big-td numbers">{{item.requested}}</td>
                            <td class="text-center text-danger big-td numbers">{{item.rejected}}</td>
                            <td class="text-center text-green big-td numbers">{{item.approbed}}</td>                
                            <td class="text-center text-grey big-td numbers" >{{item.pending}}</td>
                        </tr>
                    </tbody>
                </table> 
            </div>
        </div>
    </div>    
</div>

<ng-template [ngIf]="pendingListView==true">
    <div class="card card-custom">
        <div class="card-header mt-3">
            <div class="card-title">
                <h2 class="d-flex flex-column text-primary">
                    Solicitud Nuevos Puestos MAP
                </h2>      
            </div>
            <div class="card-toolbar"></div>
        </div>
        <div class="card-body">    
            <div class="row gutter-b">
                <div class="table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-center">Recinto</th>
                                <th class="text-center">Unidad organizativa</th>
                                <th class="text-center">Puesto</th>
                                <th class="text-center">Grupo Ocupacional</th>
                                <th class="text-center">Fecha</th>
                                <th class="text-center">Salario</th>
                                <th class="text-center">Estatus</th>
                                <th class="actionsBtn">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>                            
                            <tr *ngFor="let i of tableRows ">                                
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center">&nbsp;</td>                
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center">&nbsp;</td>
                                <td class="actionsBtn">
                                    <a class="btn btn-icon btn-light-info btn-sm m-1"><i class="flaticon-ver" data-title="Ver" data-toggle="tooltip"></i></a>                                        
                                    <a class="btn btn-icon btn-light-success btn-sm m-1"><i class="flaticon-edit" data-title="No Objetar" data-toggle="tooltip"></i></a>                                        
                                    <a class="btn btn-icon btn-light-danger btn-sm m-1"><i class="flaticon-list-2" data-title="Objetar" data-toggle="tooltip"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>    
    </div>
</ng-template>