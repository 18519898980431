import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { docService } from 'src/app/services/doc.service';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.css']
})
export class DocumentViewerComponent implements OnInit {
  documentUrl: SafeResourceUrl;

  constructor(
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<DocumentViewerComponent>,
    private _sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string;
      documentId: string;
    },
    private _docService: docService,
    private _toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this._docService.getDocument(this.data.documentId).subscribe(response => {
      if (!response.succeded) {
        this._toastr.error(response.errors[0]);
        return;
      }
      if(!response?.data){
        this._toastr.warning('No se pudo encontrar el documento');
        return;
      }
      this.documentUrl = this._sanitizer.bypassSecurityTrustResourceUrl(response.data);
    });
  }

  close(): void {
    this._dialogRef.close();
  }
}
