<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span
            class="h-20px label label-danger label-dot mr-3 w-20px"></span>{{data.title}}</h2>
    <button (click)="close()" type="button" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>

</div>
<div class="mat-dialog-content-custom min-height-300px">
    <div class="card-body-custom my-0 py-0">
        <form [formGroup]="form">
            <!-- <div class="row">
            </div> -->

            <div class="row">

                <div class="form-group col-md-4">
                    <div>
                        <label for="">
                            Nombre   <span class="text-danger text-monospace legend">*</span>
                        </label>
                    </div>
                    <input formControlName="name" class="form-control form-control-lg dynamicFormControl" id=""
                        placeholder="" type="text" />
                    <errors-component [controlName]="form.get('name')"></errors-component>
                </div>
                <div class="form-group col-md-4">
                    <div>
                        <label for="">
                            Enlace   <span class="text-danger text-monospace legend">*</span>
                        </label>
                    </div>
                    <input formControlName="link" class="form-control form-control-lg dynamicFormControl" id=""
                        placeholder="" type="text" />
                    <errors-component [controlName]="form.get('link')"></errors-component>
                </div>
                <div class="form-group col-md-4">
                    <div>
                        <label for="">
                            Tipo de Enlace   <span class="text-danger text-monospace legend">*</span>
                        </label>
                    </div>
                    
                    <ngx-select-dropdown (change)="changeType()" [options]="linkTypes"
                    [config]="getDropdownConfig('description', 'Seleccionar')"
                    formControlName="linkType" class="w-100">
                </ngx-select-dropdown>
                    <errors-component [controlName]="form.get('linkType')"></errors-component>
                </div>

            </div>

            <div [ngClass]="{'d-block': form.value.linkType?.ocode == 1, 'd-none': form.value.linkType?.ocode != 1 || !form.value.linkType?.ocode}" class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Icono
                        </label>
                        <div class="card align-items-center">
                            <uppy-file 
                                #uppyFile
                                classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-200px"
                                targetClass="docUpload"
                                showFileModalTitle="Icono"
                                [width]="280"
                                [height]="150"
                                [showDocumentoAfterUploaded]="false"
                                [fileForEditting]="file?.guid"
                                [allowedFileTypes]="['image/*']"
                                (fileEmitter)="setEvidence($event)">
                            </uppy-file>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>

<div class="mat-dialog-actions justify-content-end" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="close()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="mode == 'add' || mode == 'edit'" [disabled]="form.invalid" (click)="submit()"
        class="btn btn-pill font-weight-bolder btn-shadow btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>
