import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-other-beneficiary-modal',
  templateUrl: './other-beneficiary-modal.component.html',
  styleUrls: ['./other-beneficiary-modal.component.css']
})
export class OtherBeneficiaryModalComponent implements OnInit {

  beneficiaries = []

  selectedBeneficiary: any;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<OtherBeneficiaryModalComponent>,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: {beneficiaries: any[], currentBeneficiary: any}
  ) { 

    this.beneficiaries = data.beneficiaries.map(x=>{
      return {
        ...x,
        ['selected']: x.numericData == data.currentBeneficiary?.numericData
      }
    });

    this.selectedBeneficiary = this.beneficiaries.find(x=>x?.numericData == data.currentBeneficiary?.numericData) ?? null;

  }

  ngOnInit(): void {
    
  }


  close(){
    this.dialogRef.close({changed: false})
  }

  selectBene(bene){
    bene.selected = !bene?.selected;
    this.selectedBeneficiary = bene.selected ? bene : null;
  }

  save(){
    if(!this.selectedBeneficiary){
      this.toastr.warning('Debe seleccionar algun beneficiario para poder realizar esta acción');
      return;
    }

    this.dialogRef.close({changed: true, beneficiary: this.selectedBeneficiary})

  }
}
