import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { docService } from 'src/app/services/doc.service';

@Component({
  selector: 'app-show-file-modal',
  template: `
    <div class="modal-header border-0 dv-table-header-1 bg-primary">
      <h2 class=" text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>{{title}}</h2>
      <button (click)="close()" type="button" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
      </button>
    </div>
    <div class="container mt-2">
    <iframe [src]='transformedUrl' width="100%" height="600px"></iframe>
    </div>
  `,
  styles: [
  ]
})
export class ShowDocumentModalComponent implements OnInit {
  src = ''
  title = ''
  transformedUrl: SafeResourceUrl | string = ''
  constructor(
    private dialogRef: MatDialogRef<ShowDocumentModalComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: { src: string, title: string }
  ) { }

  ngOnInit(): void {
    this.src = this.data.src
    this.title = this.data.title
    this.transform(this.src)
  }

  transform(url) {
    this.transformedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  close() {
    this.dialogRef.close()
  }

}
