import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { CompanyStructureService } from 'src/app/services/company-structure.service';
import { DepartmentService } from 'src/app/services/department.service';
import { JobOfferService } from 'src/app/services/job-offer.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { PositionService } from 'src/app/services/position.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';

@Component({
  selector: 'app-advanced-filter-by-personnel-actions',
  templateUrl: './advanced-filter-by-personnel-actions.component.html',
  styleUrls: ['./advanced-filter-by-personnel-actions.component.css']
})
export class AdvancedFilterByPersonnelActionsComponent implements AfterViewInit {

  filters = {
    employeeNumber: null,
    employeeName: null,
    departments: [],
    positions: [],
    startDate: null,
    endDate: null,
    classifications: [],
    types: [],
    employeeTypes: [],
    efectiveDate:null
  }

  formData = {
    employeeNumber: null,
    employeeName: null,
    departments: [],
    positions: [],
    startDate: null,
    endDate: null,
    classifications: [],
    types: [],
    employeeTypes: [],
    efectiveDate:null
  }

  departmentList:any[] = []
  positionList:any[] = []
  employeeTypeList: any[] = []
  classificationList: any[] = []
  typeList: any[] = []

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: true,
    limitSelection: -1
  };

  constructor(public dialogRef : MatDialogRef<AdvancedFilterByPersonnelActionsComponent>,
    private departmentService: DepartmentService,
    private toastr: ToastrService,
    private srvParam: ParameterControlService,
    private jobOfferService: JobOfferService,
    private positionService: PositionService,
    private auth: AuthInfoService,
    private cdref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {advanced:string}) { }

  ngAfterViewInit(): void {
    this.fillFilter()
    this.getParameters()
  }

  fillFilter(){
    if(!this.data.advanced){
      return
    }

    const strFilter: string[] = this.data.advanced.split(',') 

    
    this.filters.employeeNumber = strFilter[0]
    this.filters.employeeName = strFilter[1]
    this.filters.departments = strFilter[2].split('|')
    this.filters.positions = strFilter[3].split('|')
    this.filters.startDate = strFilter[4]
    this.filters.endDate = strFilter[5]
    this.filters.classifications = strFilter[6].split('|')
    this.filters.types = strFilter[7].split('|')
    this.filters.employeeTypes = strFilter[8].split('|')
    this.filters.efectiveDate = strFilter[9];

    this.formData.employeeNumber = this.filters.employeeNumber;
    this.formData.employeeName = this.filters.employeeName;
    this.formData.startDate = this.filters.startDate;
    this.formData.endDate = this.filters.endDate;
    this.formData.efectiveDate = this.filters.efectiveDate;

    this.cdref.detectChanges();
  }

  getParameters(){
    const services: { service: Observable<any>, canExecuteService: boolean, unexpectedErrorMessage?: string, successMessage?: string, methodToExecuteAfterSuccess?: (data:any) => void }[] = [
      {
        service: this.srvParam.getParameters(keyWord.PersonalActionsClassificationsOnUse),
        canExecuteService: true,
        unexpectedErrorMessage: 'Ha ocurrido un error tratando de consultar el listado de clasificaciones',
        successMessage: '',
        methodToExecuteAfterSuccess: (data:ResponseModel<any>)=>{ 
          this.classificationList = data?.dataList.map(x => {
            return {
              item_id: x.ocode,
              item_text: x.stringData,
              ...x
            }
          })
          this.formData.classifications = this.setDropdown(this.classificationList, this.filters.classifications)
        }
      },
      {
        service: this.srvParam.getParameters(keyWord.PersonalActionsClassificationTypesOnUse),
        canExecuteService: true,
        unexpectedErrorMessage: 'Ha ocurrido un error tratando de consultar el listado de tipos por clasificación',
        successMessage: '',
        methodToExecuteAfterSuccess: (data:ResponseModel<any>)=>{ 
          this.typeList = data?.dataList.map(x => {
            return {
              item_id: x.ccode,
              item_text: x.stringData,
              ...x
            }
          })
          this.formData.types = this.setDropdown(this.typeList, this.filters.types)
        }
      },
      {
        service: this.departmentService.getAllApproved(this.auth.getCompanyId()),
        canExecuteService: true,
        unexpectedErrorMessage: 'Ha ocurrido un error tratando de consultar el listado de unidades organizativas',
        successMessage: '',
        methodToExecuteAfterSuccess: (data:ResponseModel<any>)=>{ 
          this.departmentList = data?.dataList.map(x => {
            return {
              item_id: x.departmentId,
              item_text: x.departmentName,
              ...x
            }
          })
          this.formData.departments = this.setDropdown(this.departmentList, this.filters.departments)
        }
      },
      {
        service: this.positionService.getApprovedPositionsByCompany(this.auth.getCompanyId()),
        canExecuteService: true,
        unexpectedErrorMessage: 'Ha ocurrido un error tratando de consultar el listado de cargos',
        successMessage: '',
        methodToExecuteAfterSuccess: (data:ResponseModel<any>)=>{ 
          this.positionList = data?.dataList.sort((a, b) => a.positionName.trim().localeCompare(b.positionName.trim())).map(x => {
            return {
              item_id: x.positionId,
              item_text: x.positionName,
              ...x
            }
          })
          this.formData.positions = this.setDropdown(this.positionList, this.filters.positions)
        }
      },
      {
        service: this.jobOfferService.getAllEmployeeType(),
        canExecuteService: true,
        unexpectedErrorMessage: 'Ha ocurrido un error tratando de consultar el listado de tipos de servidores públicos',
        successMessage: '',
        methodToExecuteAfterSuccess: (data:ResponseModel<any>)=>{ 
          this.employeeTypeList = data?.dataList.map(x => {
            return {
              item_id: x.employeeTypeId,
              item_text: x.description,
              ...x
            }
          })
          
          this.formData.employeeTypes = this.setDropdown(this.employeeTypeList, this.filters.employeeTypes)
        }
      },
    ]

    const $requests = services.map((model) => {
      if (model.canExecuteService) {
        return model.service
          .pipe(map(value => ({ type: model, value: value })))
          .pipe(catchError(e => of({ succeded: false, errors: [model.unexpectedErrorMessage] } as ResponseModel<any>)));
      }
    }).filter(Boolean);

    forkJoin($requests).subscribe((responses: { type: any, value: ResponseModel<any> }[]) => {

      responses.forEach((response) => {
        if (!response?.value?.succeded) {
          response?.value?.errors.forEach(err => {
            this.toastr.error(err);
          })

          response?.value?.warnings.forEach(warn => {
            this.toastr.warning(warn);
          })
        } else {
          response?.type?.methodToExecuteAfterSuccess(response?.value);
          this.cdref.detectChanges();
        }
      })

    })
  }

  getStringIds(arr: any): string{
    let str = ''
    if(!arr){
      return ''
    }
    let arrLength = arr.length
    if(arrLength === 0){
      return ''
    }
    arr.forEach((x, i) => {
      str += x.item_id
      if(arrLength !== (i + 1)){
        str += '|'
      }
    })
    this.cdref.detectChanges();
    return str
  }

  setDropdown(arrOriginal: any[], arrToFilter: any[]): any[] {
    let o = []
    if(arrToFilter){
      arrOriginal.forEach(x => {
        arrToFilter.forEach(c => {
          if(c == x.item_id){
            o.push(x)
          }
        })
      })
    }
    this.cdref.detectChanges();
    return o
  }

  doSearch() {
    const employeeNumber = this.formData.employeeNumber ? this.formData.employeeNumber.trim() : ''
    const employeeName = this.formData.employeeName ? this.formData.employeeName .trim() : ''
    const departments = this.getStringIds(this.formData.departments)
    const positions = this.getStringIds(this.formData.positions)
    const startDate = this.formData.startDate ? moment(this.formData.startDate).format('yyyy-MM-DD') : ''
    const endDate = this.formData.endDate ? moment(this.formData.endDate).format('yyyy-MM-DD') : ''
    const classifications = this.getStringIds(this.formData.classifications)
    const types = this.getStringIds(this.formData.types)
    const employeeTypes = this.getStringIds(this.formData.employeeTypes);
    const effectiveDate = this.formData.efectiveDate ? moment(this.formData.efectiveDate).format('yyyy-MM-DD'): '';

    const text = `${employeeNumber},${employeeName},${departments},${positions},${startDate},${endDate},${classifications},${types},${employeeTypes},${effectiveDate}`
    
    this.close(true,text);
  }

  close(success = false, text = null): void {
    this.dialogRef.close({success, text});
  }
}
