import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
declare const $: any
@Component({
  selector: 'el-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit {
  @Input() footer: boolean;
  @Input() target: string;
  @Input() size: string;
  @Input() title: string;
  @Input() header = true;
  @Input() isLoading = false;
  hasFooter = false;
  hasHeader = true;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    this.hasFooter = this.footer;
    this.hasHeader = this.header;
  }
  addSize(size: string): string {
    return size ? `modal-${size.toLowerCase()}` : '';
  }

  close() {
    $(`#${this.target}`).modal('hide')
    this.onClose.emit(false)
  }
}
