import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IGetDetailByDeparments, IGetOverallSummary, IHeader, IPeriod, IPeriodDetailsResume, PeriodConditions } from '@payroll/audit-approval/Audit-Approval-models';
import { ToastrService } from 'ngx-toastr';
import { IEmployeeTypeView } from 'src/app/models/Employee.model';
import { AuditApprovalService } from 'src/app/services/audit-approval.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { PDepartmentDetailsModalComponent } from '../p-department-details-modal/p-department-details-modal.component';

@Component({
  selector: 'app-previous-payroll-tab',
  templateUrl: './previous-payroll-tab.component.html',
  styleUrls: ['./previous-payroll-tab.component.css']
})
export class PreviousPayrollTabComponent implements OnInit, OnChanges {
  periodOptions: any[] = [];
  periodSelected: any;
  showContent = false;
  disableEmployeeTypeInput = true;
  employeesCounted: number = 0;
  payrollAllDetailsFiltered: any[] =[];
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  payrollAllDetailsFilteredCopy: any[] =[];
  @Input() employeeTypes: IEmployeeTypeView[] = [];
  @Input() payrollTypeId: number = 0;
  @Input() budgetAreaId: number = 0;
  payrollAllDetails: IGetOverallSummary[] =[];
  payrollDetailDepartmentsFiltered: any[] = [];
  payrollDetailDepartmentsFilteredCopy: any[] = [];
  payrollDetailDepartments: IGetDetailByDeparments[] = [];
  
  selectedEmployeeType: any;
  payrollDetailResume: IPeriodDetailsResume = {
    companyId: 0,
    headerId: 0,
    totalDiscount: 0,
    totalEmployee: 0,
    totalGrossSalary: 0,
    totalIsr: 0,
    totalNetIncome: 0,
    totalTss: 0,
  }
  constructor(
    private srvAudit:AuditApprovalService,
    private _toastr: ToastrService,
    private dialog: MatDialog
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.loadPeriods()
  }
  
  clearWhenPeriodNotSelected(){
    let period = this.periodSelected
    if(Array.isArray(period)){
      if(period.length < 1){
        this.disableEmployeeTypeInput = true;
        this.showContent = false;
        this.payrollDetailResume = {
          companyId: 0,
          headerId: 0,
          totalDiscount: 0,
          totalEmployee: 0,
          totalGrossSalary: 0,
          totalIsr: 0,
          totalNetIncome: 0,
          totalTss: 0,
        }
        this.payrollAllDetails = []
        this.payrollDetailDepartments = []
        this.employeesCounted = 0
        this.payrollDetailDepartmentsFilteredCopy = []
        this.selectedEmployeeType = null
        return true
      }
    }
    this.disableEmployeeTypeInput = false;
    return false;
  }
  periodDropdownChange(period: IPeriod){
    if(this.clearWhenPeriodNotSelected())return
    this.disableEmployeeTypeInput = false;
    if(this.payrollTypeId == null){
      this._toastr.error("El tipo de nómina no cargó", "Error inesperado")
      return
    }
    if(this.budgetAreaId == null){
      this._toastr.error("El área presupuestaria no cargó", "Error inesperado")
      return
    }
    let payrollTypeId = this.payrollTypeId;
    let budgetAreaId = this.budgetAreaId;
    let periodId = period.payrollPeriodId;
    
    this.srvAudit.getHeaderByPeriodTypeArea(periodId, payrollTypeId, budgetAreaId).subscribe((res:ResponseModel<IHeader>) => {
      if(res.succeded){
        if(res.dataList.length < 1){
          this._toastr.warning(`No existen datos en el período seleccionado`);
          this.showContent=false
          return;
        }
        this.showContent=true; 
        let headerId = res.dataList[0].headerId;
        this.loadResume(headerId);
        this.loadDetails(headerId);
        this.loadDepartments(headerId)
      }else{
        this._toastr.error(res.errors[0])
      }
    }, err => {
      this._toastr.error(this.errorMessage)
    })
    
    
  }
  
  loadDepartments(id){
    this.srvAudit.getPayrollDetailByDepartments(id).subscribe((res:any)=>{
      if(res.succeded){
        this.employeesCounted = 0;

        this.payrollDetailDepartments = res.dataList;
        res.dataList.forEach(element => {
          this.employeesCounted = element.employeeCount + this.employeesCounted;
        });

      }else{
        this._toastr.error(res.error[0]);
      }
    },err=>{
      this._toastr.error('','ERROR INESPERADO')
    });
  }
  
  loadPeriods(){
    this.srvAudit.getPeriods(11).subscribe((res:ResponseModel<IPeriod>)=>{
      if(res.succeded){
        this.periodOptions = res.dataList.filter(x => x.conditionId == PeriodConditions.closed);
        
      }else{
        this._toastr.error(res.errors[0])
      }
    },err=>{
      this._toastr.error('','ERROR INESPERADO')
    })
  }
  
  viewDetails(item){
    const {headerId, departmentId, typeEmployeeId} = item
    this.dialog.open(PDepartmentDetailsModalComponent,{
      data: {
        headerId,
        departmentId,
        typeEmployeeId
      }
    })    
  }
  
  loadResume(id){
    this.srvAudit.getPayrollDetailResume(id).subscribe((res:any)=>{
      if(res.succeded){
        this.payrollDetailResume = res.dataList[0];
      }else{
        this._toastr.error(res.error[0]);
      }
    },err=>{
      this._toastr.error('','ERROR INESPERADO')
    });
  }
  employeeTypeChange($event){
    this.filterDepartments($event.value);
  } 
  filterDepartments(item){
    this.payrollDetailDepartmentsFiltered = [];
    let d = this.payrollDetailDepartments;

    if(d.length <= 0){
      return;
    }

    this.payrollDetailDepartmentsFiltered = d.filter(x=>{
      if(item.ocode == x.typeEmployeeId){
        return true;
      }
    });
  }
  loadDetails(id){
    this.srvAudit.getPayrollDetails(id).subscribe((res:any)=>{
      if(res.succeded){
        this.payrollAllDetails = res.dataList;
      }else{
        this._toastr.error(res.error[0]);
      }
    },err=>{
      this._toastr.error('','ERROR INESPERADO')
    });
  }
  
  
  getPaginatedRecordsForDetails(event) {
    this.payrollAllDetailsFiltered = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  getPaginatedRecordsForDepartments(event) {
    this.payrollDetailDepartmentsFilteredCopy = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  
  getDropdownConfig(displayKey: string){
    return {
      displayKey: displayKey, // if objects array passed which key to be displayed defaults to description
      search: true,
      searchPlaceholder: "Buscar",
      placeholder: 'Seleccionar',
      noResultsFound: 'No hay data para mostrar',
      searchOnKey: displayKey
    };
  }

}
