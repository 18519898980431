import { Component, Input, OnInit } from '@angular/core';
import { IDictionaryView } from 'src/app/models/competency';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { docService } from 'src/app/services/doc.service';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'export-data-to-pdf',
  templateUrl: './export-data-to-pdf.component.html',
  styleUrls: ['./export-data-to-pdf.component.css']
})
export class ExportDataToPdfComponent implements OnInit {
  @Input() title: string;
  isTableLoading: boolean;
  file = { logo: '', image1: '', image2:'' }

  fileModalConfig = {
    src: '',
    type: '',
    filename: '',
    width: 225,
    height: 225,
    formTitle: '',
    formSubTitle: '',
  };

  company: any;

  constructor(private _companiesService: CompaniesService,  
    private authInfo: AuthInfoService,
    private srvDoc: docService,  private _toastService: ToastService) { }

  ngOnInit(): void {
    this.companyById(this.authInfo.getCompanyId());
  }

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      if(data.succeded){
        this.company = data?.dataList[0];
        this.setGuids(this.company);
      }
    })
  }

  setGuids(company) {
    this.srvDoc.getDocument(company?.logo).subscribe(e => {
      this.file.logo = e?.data ?? '';
    });

    this.srvDoc.getDocument(company?.image1).subscribe(e => {
      this.file.image1 = e?.data ?? '';
    });

    this.srvDoc.getDocument(company?.image2).subscribe(e => {
      this.file.image2 = e?.data ?? '';
    });
  }

  get getCurrentDate(){
    return new Date();
  }
}
