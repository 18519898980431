import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'normative-tap',
  templateUrl: './normative.component.html',
  styleUrls: ['./normative.component.css']
})
export class NormativeComponent implements OnInit {

  constructor() { }
  @Output() onClose = new EventEmitter<boolean>()
  selectedItems: any;
  ngOnInit(): void {
  }

  
  close(){
    this.onClose.emit(true)
  }
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  statusOptions = [
    { item_id: 1, item_text: 'Texto de ejemplo' },
  ];
}
