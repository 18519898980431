import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import Swal from 'sweetalert2';
import { RejectPermissionModalComponent } from '../../personnel-actions-tap/reject-permission-modal/reject-permission-modal.component';

@Component({
  selector: 'app-reject-license-modal',
  templateUrl: './reject-license-modal.component.html',
  styleUrls: ['./reject-license-modal.component.css']
})
export class RejectLicenseModalComponent implements OnInit {

  showRejectReasonError = false;
  reasonToReject = ''
  permissionToRejectId = 0
  constructor(
    private personnelActionsService: PersonnelActionsService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<RejectPermissionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any, 
    ) { }

  ngOnInit(): void {
    const { permissionsId, conditionId } = this.data.item
    this.permissionToRejectId = permissionsId
    this.reasonToReject = ''
  }


  close(success = false){
    this.dialogRef.close({success})
  }
  submit(){

  }

  rejectPermission() {
    if (!this.reasonToReject) {
      this.showRejectReasonError = true
      return
    } else {
      this.showRejectReasonError = false
    }
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      title: "¿Está seguro que desea rechazar este permiso?",
      icon: "question",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: '#f64e60'
    }).then(res => {
      if (res.isConfirmed) {
        const permissionReject = {
          permissionsId: this.permissionToRejectId,
          reasonReject: this.reasonToReject
        }
        this.personnelActionsService.rejectPermission(permissionReject).subscribe((res: any) => {
          if (res.succeded) {
            this.toastr.success('Licencia rechazado')
            this.close(true)
          } else {
            this.toastr.error(res.errors[0])
          }
        }, err => {
          this.toastr.error('Ha ocurrido un error inesperado')
        })
      }
    })
  }
}

