import { SweetAlert } from '../../utils/Sweet-Alert';
import { FileManagerService } from '../../file-manager/file-manager.service';
import { ToastService } from '../../toast/toast.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { CaptionComponent } from './caption/caption.component';
declare const $: any;

@Component({
  selector: 'el-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() titlePage: string = "";
  @Input() subTitlePage: string = "";
  constructor(
    private _toastService: ToastService,
    private _fileManager: FileManagerService,
  ) {}

  ngOnInit(): void {}

}
