<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">

    <button type="button" class="close" aria-label="Close" (click)="this.dialogRef.close();">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>En
        Sustitución de</h2>

</div>
<div class="mat-dialog-content-custom" style="height: 300px !important;">
    <div class="card-body">
        <div>
            <div>
                <div class="form-group">
                    <label class="d-block"></label>
                    <ngx-select-dropdown [options]="replacementsList" [config]='dropdownConfig("name")'
                        [multiple]="true" (change)="fillReplacingList($event.value)">
                    </ngx-select-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions justify-content-end" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2"
        type="button" (click)="closeReplacemntsModal(false)">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button [disabled]="replacements.length == 0" (click)="closeReplacemntsModal(true)" class="btn btn-pill font-weight-bolder btn-shadow btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Agregar</span>
    </button>
</div>
