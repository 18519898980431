

  <div class="card card-custom">
      <div class="card-header mt-3">
          <div class="card-title">
              <div class="ttl d-flex align-items-center">
                  <i class="flaticon-lista-de-verificacion-2 icon-3x text-danger mr-2"></i>
                  <h1 class="text-primary"> Plan Operativo Anual </h1>
              </div>
          </div>
          <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard" data-toggle="tooltip" data-original-title="Dashboard">
              
              <button data-toggle="modal" class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto" data-toggle="modal" *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.dashboardAction)"
              data-target="#dashboard">
                  <i class="flaticon-dashboard-1 icon-2x" ></i> Ver Dashboard
              </button>
          </div>
      </div>

      <div class="card-header mt-3 row justify-content-between">
          <div class="col-sm-12 col-md-6 c-left">
              <div class="card-toolbar d-flex justify-content-start">

                  <div class="col-md-4 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                      <label class="text-primary">
                        Estatus
                      </label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Seleccionar Estatus'"
                        [settings]="dropdownSettings"
                        [data]="statusOptions"
                        [(ngModel)]="selectedItems"
                        ></ng-multiselect-dropdown>
                    </div>
                  </div>

                <button data-toggle="modal" id="btnAdd"  *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.addAction)"
                  class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                  <i class="flaticon-mas icon-2x" data-toggle="tooltip" data-original-title="Agregar"></i>
                  Agregar
                </button>
              </div>
          </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12" >
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"  *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.exportAction)"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white"  data-toggle="tooltip" data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center">
                            <i class="flaticon-excel icon-2x text-success mr-3"></i>
                            Excel
                        </a>
                        <a class="dropdown-item disabled align-items-center" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled align-items-center" href="#">
                            <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                            PDF
                        </a>
                        <a class="dropdown-item disabled align-items-center" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a>
                    </div>
                </div>

                <div class="col text-nowrap pt-2">
                    <el-search-bar [records]="list" [itemForSearching]="searchBarDisplayNames"
                        (onChange)="getFilteredList($event)"></el-search-bar>
                </div>
            </div>
        </div>
  </div>

  <div class="card-body">

    <div class="table-responsive">
        <table class="table table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th class="text-center">Secuencia</th>
                    <th class="text-center min-w-150px">Referencia</th>
                    <th class="text-left">Descripción</th>
                    <th class="text-center min-w-150px">POA-PEI</th>
                    <th class="text-left">Estatus</th>
                    <th class="text-left">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">1</td>
                    <td class="text-center">Texto</td>
                    <td class="text-left">Texto</td>
                    <td class="text-left">Texto</td>
                    <td>
                      <span *ngIf="true" class="font-weight-bold text-success">Activa</span>
                      <span *ngIf="false" class="font-weight-bold text-danger">Inactiva</span>
                    </td>
                    <td class="actionsBtn">
                      <!-- Aqui van los botones -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div >
            
                <el-caption #paginator  [originalRecordsLength]="list.length" [listedRecordsLength]="paginatedList.length" 
                [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :list.length" [records]="filteredList.enable ? filteredList.records : list" (onChage)="getPaginatedRecords($event)"></el-caption>
           
        </div>
    </div>

  </div>

</div>

