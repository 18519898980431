import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { PayrollFlyerService } from 'src/app/services/payroll-flyer.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { PayrollFlyerGetTypes } from '../models/payroll-flyer-get-type.enum';
import { PayrollFlyer } from '../models/payroll-flyer.model';
import { PaymentFlyerComponent } from '../payment-flyer.component';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import { environment } from 'src/environments/environment';
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { docService } from 'src/app/services/doc.service';
import { EmployeeDebitCredit, NewsApplied, PayrollVoucher } from '../models/payroll-voucher.model';
import { NewsAppliedType } from '../models/news-applied-type.enum';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ToastrService } from 'ngx-toastr';
type GUID = string & { isGuid: true };

@Component({
  selector: 'payment-voucher',
  templateUrl: './payment-voucher.component.html',
  styleUrls: ['./payment-voucher.component.css']
})
export class PaymentVoucherComponent implements OnInit {
  //Other debits //Other credits
  //debitCreditId = 4 debitCreditId = 5
  selectedFlyer: PayrollFlyer;

  payrollVoucherData: PayrollVoucher;
  isTableLoading: boolean;
  subscriptions: Subscription = new Subscription();
  bugetArea: string = "";
  contractType: string = "";
  selectedPeriod: string = "";
  //#region Variables related to load picture
  profilePhoto = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['image/*']
    },
    locale:Mexico
  });
  profilePhotoUrl: string = "assets/images/no-user.png";
  
  employeeDebitCredit: any[] = [];
  newsApplied: any[]= [];

  @Input() type: number;
  public loadingPic = true;
  //#endregion

  public company: any;
  file = { logo: '', image1: '', image2:'' }
  constructor(
    public dialogRef: MatDialogRef<PaymentFlyerComponent>,
    private _payrollFlyerService: PayrollFlyerService,
    private srvDoc: docService,
    private _companiesService: CompaniesService,
    private authInfo: AuthInfoService,
    private _toastService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) 
    {
    }

  ngOnInit(): void {
    
    this.selectedFlyer = this.data.model;
    this.payrollVoucherData = this.data.voucher;

    this.employeeDebitCredit = this.payrollVoucherData.employeeDebitCredit.
    filter((e: EmployeeDebitCredit) => e.debitCreditId !== 4 && e.debitCreditId !== 5);

    this.newsApplied =  this.payrollVoucherData.newsApplied;


 
    this.bugetArea = (this.selectedFlyer.budgetArea || '');
    this.contractType = (this.payrollVoucherData.employee.contractType || '');
    this.selectedPeriod = (this.data.selectedPeriod || '');

    
    this.getProfilePhoto();
    this.companyById(this.authInfo.getCompanyId())
  }
  

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  getProfilePhoto(){
    this.srvDoc.getDocument(this.payrollVoucherData.employee.profileImage).subscribe(e => {
      if(e.data){
        this.profilePhotoUrl = e.data
      }
      this.loadingPic = false
    }, error => {
      this.loadingPic = false
    })
  }
  

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }
  close(){
    this.dialogRef.close();
  }



}
