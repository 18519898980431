<div class="card card-custom">
  <div class="card-header mt-3">
    <div class="card-title">
        <span class="card-icon">
            <i class="flaticon-candidatos  icon-3x text-danger"></i>
        </span>
        <h2 class="d-flex flex-column text-primary">
            Desvincular
        </h2>
    </div>
    <div class="card-toolbar">
      <div class="border-primary min-w-225px card p-2 mr-2 switch switch-primary switch-sm rounded-pill">
        <label class="cursor-pointer d-flex align-items-center">
            <input (ngModelChange)='tableToggle()' [checked]='false' class="cursor-pointer abc-checkbox-primary" [(ngModel)]="unrelated" type="checkbox"/>
            <span></span>
            <strong *ngIf="unrelated" class="font-size-h5 mx-4">
                Desvinculados
            </strong>
            <strong  *ngIf="!unrelated" class="font-size-h5 mx-4">
                Vinculados
            </strong>
        </label>
      </div>
        <div class="form-group">
            <label>Búsqueda de Servidores Públicos</label>
            <div class="input-group">
                <input [(ngModel)]="searchTextFilter" (input)="filterPersons()" type="text" class="form-control form-control-solid form-control-lg" placeholder="Escriba aquí su búsqueda">
                <button (click)="ApplySearch()" class="input-group-text bg-primary" data-toggle="tooltip" title="Buscar">
                    <i class="flaticon2-search-1 icon-2x text-white"></i>
                </button>
            </div>
        </div>
    </div>
  </div>
    <div class="card-body" kt-hidden-height="282">
      <div class="table-responsive" id="kt_datatable">
        <table class="table table-hover table-vertical-center text-center">
          <thead>
            <tr class="bg-primary">
              <th>Código</th>
              <th>Identificador</th>
              <th class="text-left">Servidor Público</th>
              <th>Tipo de Servidor Público</th>
              <th class="text-left">Unidad Organizativa</th>
              <th class="text-left">Posición</th>
              <th>Motivo</th>
              <th>Fecha efectiva de salida</th>
              <!-- <ng-container *ngFor="let header of headers">
                <th data-field="Cedula" class="datatable-cell datatable-cell-sort">
                  <span>
                    <span>{{ header.label }}</span>
                  </span>
                </th>
              </ng-container> -->
              <th data-field="Actions" data-autohide-disabled="false"
                class="datatable-cell-left datatable-cell datatable-cell-sort actionsBtn">
                <span>Acciones</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let value of formattedList"> 
              <td>
                <!-- <h1 class="text-muted my-4"> -->
                  {{value.employNumber}}
                <!-- </h1> -->
              </td>
              <td>
                <!-- <h1 class="text-muted my-4"> -->
                  {{value.personalIdentification}}
                <!-- </h1> -->
              </td>
              <td class="text-left">
                <!-- <h1 class="text-muted my-4"> -->
                  {{ value.name}}
                <!-- </h1> -->
              </td>
            <!-- </tr> -->
            <!-- <tr *ngFor="let record of records; let i = index" data-row="0" class="datatable-row" style="left: 0px"> -->
              <td>
                {{ value.employeeType }}
              </td>
              <td class="text-left">
                {{ value.departmentName}}
              </td>
              <!-- <ng-container *ngFor="let header of headers"> -->
                <td class="text-left">
                  {{value.positionName}}
                </td>
              <!-- </ng-container> -->
                <td>
                  {{value.exitReasonDescription}}
                </td>
                <td >
                  <span *ngIf="unrelated">
                    {{value.electiveDepartureDate | date: 'dd/MM/yyyy'}}
                  </span>
                  <span *ngIf="!unrelated">
                    ---
                  </span>
                </td>

              <td>
                <span>
                  <a *ngIf="!unrelated" class="btn btn-sm btn-light-danger btn-icon mr-2" data-target="#desvincular" data-toggle="modal" (click)="Modify(value)">
                    <i class="flaticon-mala-critica icon-2x" data-toggle="tooltip" title="Desvincular"></i>
                  </a>
                  <!-- <a class="btn btn-sm btn-light-danger btn-icon" data-toggle="tooltip" title="Remover"
                    (click)="delete(record.id, record.code)">
                    <i class="flaticon-delete"></i>
                  </a> -->
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-end align-items-center flex-wrap">
        <el-caption #paginator [registerQuantity]="employeesDetails.length" [records]="employeesDetails" (onChage)="getPaginatedRecords($event)"></el-caption>
      </div>
    </div>
  </div>

  <form [formGroup]="form">
    <el-modal target="desvincular" title="Desvincular" size="xl">
      <div class="row">
        <div class="col-lg-3">
          <div class="text-center">
            <div class="symbol symbol-150 d250 my-4">
              <img alt="Pic" src="assets/media/users/300_21.jpg"/>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-3">
              <div class="form-group">
                <label>Código</label>
                <input type="text" readonly name="name" formControlName="employNumber" class="form-control form-control-lg" />
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label>Tipo de Servidor Público</label>
                <input type="text" readonly name="name" formControlName="employeeType" class="form-control form-control-lg" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label>Unidad Organizativa</label>
                <input type="text" name="name" readonly formControlName="departmentName" class="form-control form-control-lg" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label>Tipo de Salida<span class="text-danger">*</span></label>
                <ngx-select-dropdown
                class=" flex-1"
                [config]="config" 
                [options]="typeExitList"
                formControlName="exitTypeId"
                >
                </ngx-select-dropdown>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label>Motivo de Salida<span class="text-danger">*</span></label>
                <ngx-select-dropdown 
                class=" flex-1"
                [config]="config"
                [options]="reasonExitList"
                formControlName="exitReason"
                >
                </ngx-select-dropdown>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <label>Fecha Efectiva de Salida<span class="text-danger">*</span></label>
                <input 
                  type="date" 
                  class="form-control form-control-lg"
                  formControlName="electiveDepartureDate"
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label>Fecha de Procesamiento<span class="text-danger">*</span></label>
                <input
                  type="date" 
                  class="form-control form-control-lg" 
                  formControlName="processingExitDate"  
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label>Fecha de Recepción<span class="text-danger">*</span></label>
                <input 
                  type="date" 
                  class="form-control form-control-lg" 
                  formControlName="receptionExitDate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-light-info gutter-b p-5">
        <div class="row mb-3">
          <div class="col-lg-6">
            <h6>¿Ha sido usted pre-avisado?<span class="text-danger">*</span></h6>
          </div>
          <div class="col-lg-2">
            <div class="d-flex align-items-center flex-grow-1 mb-3">
              <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
              <div class="flex-shrink-0 mx-2">
                  <span class="switch switch-sm switch-primary">
                      <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
                  </span>
              </div>
              <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
            </div>
          </div>
          <div class="col-lg-4">
            <input type="text" class="form-control form-control-lg" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6">
            <h6>¿Desea incluir cesantía?<span class="text-danger">*</span></h6>
          </div>
          <div class="col-lg-2">
            <div class="d-flex align-items-center flex-grow-1 mb-3">
              <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
              <div class="flex-shrink-0 mx-2">
                  <span class="switch switch-sm switch-primary">
                      <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
                  </span>
              </div>
              <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
            </div>
          </div>
          <div class="col-lg-4">
            <input type="text" class="form-control form-control-lg" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6">
            <h6>¿Ha tomado las vacaciones correspondientes al último año?<span class="text-danger">*</span></h6>
          </div>
          <div class="col-lg-2">
            <div class="d-flex align-items-center flex-grow-1 mb-3">
              <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
              <div class="flex-shrink-0 mx-2">
                  <span class="switch switch-sm switch-primary">
                      <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
                  </span>
              </div>
              <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
            </div>
          </div>
          <div class="col-lg-4">
            <input type="text" class="form-control form-control-lg" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6">
            <h6>¿Incluir salario de navidad?<span class="text-danger">*</span></h6>
          </div>
          <div class="col-lg-2">
            <div class="d-flex align-items-center flex-grow-1 mb-3">
              <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
              <div class="flex-shrink-0 mx-2">
                  <span class="switch switch-sm switch-primary">
                      <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
                  </span>
              </div>
              <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
            </div>
          </div>
          <div class="col-lg-4">
            <input type="text" class="form-control form-control-lg" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="d-flex justify-content-end">
          <button id="btnClose" class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3" data-dismiss="modal" (click)="finish()">
            <i class="flaticon2-cross"></i>   
            Cerrar
          </button>
          <button data-toggle="modal" data-target="#verificacionSalida" class="btn btn-pill font-weight-bolder btn-shadow btn-primary mr-3">
            <i class="flaticon-correccion icon-2x"></i>   
            Verificación de Salida
          </button>        
          <button class="btn btn-pill font-weight-bolder btn-shadow btn-success" (click)="updateVinculacion()">
            <i class="flaticon-disco-flexible"></i>
              Guardar
          </button>          
        </div>
      </div>
    </el-modal>
  </form>

  <el-modal target="verificacionSalida" title="Verificación de salida de Servidores Públicos" size="xl">
    <div class="row">
      <div class="col-lg-4 form-group">
        <label>Fecha Efectiva<span class="text-danger">*</span></label>
        <input type="date" class="form-control form-control-lg" />
      </div>
      <div class="col-lg-4 form-group">
          <label>Código del Servidor Público<span class="text-danger">*</span></label>
          <div class="input-group">
              <input type="text" class="form-control form-control-lg">
              <button class="input-group-text bg-primary" data-toggle="tooltip" title="Buscar">
                  <i class="flaticon2-search-1 icon-2x text-white"></i>
              </button>
          </div>
      </div>        
      <div class="col-lg-4 form-group">
          <label>Tipo Nombramiento<span class="text-danger">*</span></label>
          <ngx-select-dropdown class=" flex-1"></ngx-select-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 form-group">
        <label>Unidad Organizativa<span class="text-danger">*</span></label>
        <input type="text" class="form-control form-control-lg" />
      </div>
      <div class="col-lg-4 form-group">
        <label>Puesto<span class="text-danger">*</span></label>
        <input type="text" class="form-control form-control-lg" />
      </div>
      <div class="col-lg-4 form-group">
        <label>Nombre Completo<span class="text-danger">*</span></label>
        <input type="text" class="form-control form-control-lg" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 form-group">
        <label>Cédula<span class="text-danger">*</span></label>
        <input type="text" class="form-control form-control-lg" />
      </div>
      <div class="col-lg-4 form-group">
        <label>Fecha Ingreso<span class="text-danger">*</span></label>
        <input type="date" class="form-control form-control-lg" />
      </div>
      <div class="col-lg-4 form-group">
        <label>Fecha Salida<span class="text-danger">*</span></label>
        <input type="date" class="form-control form-control-lg" />
      </div>
    </div>

    <div class="row gutter-b">
      <h3 class="d-flex bg-primary text-white p-4">Descripción de documentos</h3>
    </div>
    <div class="row mb-3 border-bottom">
      <div class="col-lg-9">
        <h6>Carta de Salida<span class="text-danger">*</span></h6>
      </div>
      <div class="col-lg-3">
        <div class="d-flex align-items-center flex-grow-1 mb-3">
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
          <div class="flex-shrink-0 mx-2">
              <span class="switch switch-sm switch-primary">
                  <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
              </span>
          </div>
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
        </div>
      </div>
    </div>
    <div class="row mb-3 border-bottom">
      <div class="col-lg-9">
        <h6>Cierre correo institucional<span class="text-danger">*</span></h6>
      </div>
      <div class="col-lg-3">
        <div class="d-flex align-items-center flex-grow-1 mb-3">
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
          <div class="flex-shrink-0 mx-2">
              <span class="switch switch-sm switch-primary">
                  <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
              </span>
          </div>
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
        </div>
      </div>
    </div>
    <div class="row mb-3 border-bottom">
      <div class="col-lg-9">
        <h6>LLaves de la oficina o locker<span class="text-danger">*</span></h6>
      </div>
      <div class="col-lg-3">
        <div class="d-flex align-items-center flex-grow-1 mb-3">
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
          <div class="flex-shrink-0 mx-2">
              <span class="switch switch-sm switch-primary">
                  <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
              </span>
          </div>
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
        </div>
      </div>
    </div>
    <div class="row mb-3 border-bottom">
      <div class="col-lg-9">
        <h6>Carnet institucional<span class="text-danger">*</span></h6>
      </div>
      <div class="col-lg-3">
        <div class="d-flex align-items-center flex-grow-1 mb-3">
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
          <div class="flex-shrink-0 mx-2">
              <span class="switch switch-sm switch-primary">
                  <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
              </span>
          </div>
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
        </div>
      </div>
    </div>
    <div class="row mb-3 border-bottom">
      <div class="col-lg-9">
        <h6>Activo asignado<span class="text-danger">*</span></h6>
      </div>
      <div class="col-lg-3">
        <div class="d-flex align-items-center flex-grow-1 mb-3">
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
          <div class="flex-shrink-0 mx-2">
              <span class="switch switch-sm switch-primary">
                  <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
              </span>
          </div>
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
        </div>
      </div>
    </div>
    <div class="row mb-3 border-bottom">
      <div class="col-lg-9">
        <h6>Socio de la cooperative<span class="text-danger">*</span></h6>
      </div>
      <div class="col-lg-3">
        <div class="d-flex align-items-center flex-grow-1 mb-3">
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
          <div class="flex-shrink-0 mx-2">
              <span class="switch switch-sm switch-primary">
                  <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
              </span>
          </div>
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
        </div>
      </div>
    </div>
    <div class="row mb-3 border-bottom">
      <div class="col-lg-9">
        <h6>Usuario de Xmera<span class="text-danger">*</span></h6>
      </div>
      <div class="col-lg-3">
        <div class="d-flex align-items-center flex-grow-1 mb-3">
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
          <div class="flex-shrink-0 mx-2">
              <span class="switch switch-sm switch-primary">
                  <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
              </span>
          </div>
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
        </div>
      </div>
    </div>
    <div class="row mb-3 border-bottom">
      <div class="col-lg-9">
        <h6>Beca de titular o algún familiar<span class="text-danger">*</span></h6>
      </div>
      <div class="col-lg-3">
        <div class="d-flex align-items-center flex-grow-1 mb-3">
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
          <div class="flex-shrink-0 mx-2">
              <span class="switch switch-sm switch-primary">
                  <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
              </span>
          </div>
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
        </div>
      </div>
    </div>
    <div class="row mb-3 border-bottom">
      <div class="col-lg-9">
        <h6>Posee seguro médico complementario<span class="text-danger">*</span></h6>
      </div>
      <div class="col-lg-3">
        <div class="d-flex align-items-center flex-grow-1 mb-3">
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
          <div class="flex-shrink-0 mx-2">
              <span class="switch switch-sm switch-primary">
                  <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
              </span>
          </div>
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
        </div>
      </div>
    </div>
    <div class="row mb-3 border-bottom">
      <div class="col-lg-9">
        <h6>Posee plan de seguro de vida<span class="text-danger">*</span></h6>
      </div>
      <div class="col-lg-3">
        <div class="d-flex align-items-center flex-grow-1 mb-3">
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
          <div class="flex-shrink-0 mx-2">
              <span class="switch switch-sm switch-primary">
                  <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
              </span>
          </div>
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
        </div>
      </div>
    </div>
    <div class="row mb-3 border-bottom">
      <div class="col-lg-9">
        <h6>Posee pase rápido<span class="text-danger">*</span></h6>
      </div>
      <div class="col-lg-3">
        <div class="d-flex align-items-center flex-grow-1 mb-3">
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
          <div class="flex-shrink-0 mx-2">
              <span class="switch switch-sm switch-primary">
                  <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
              </span>
          </div>
          <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
        </div>
      </div>
    </div>
      
    <div class="row">
      <div class="form-group">
        <label>Especificar otro aquí</label>
        <textarea rows="5" class="form-control form-control-lg"></textarea>
      </div>
    </div>
    <div class="form-group text-right mt-4 col-md-12">
      <button class="mr-2 btn btn-danger btn-pill" (click)="closeV()">
          <i class="flaticon2-cross"></i> Cerrar
      </button>
      <button class="btn btn-success btn-pill font-weight-bolder" (customClick)="save()">
        <small *ngIf="loading">
            <el-spinner animation="spinner-b"> </el-spinner>
        </small>
        <i class="flaticon-disco-flexible"></i>
        <span *ngIf="!loading">
            Guardar
        </span>
      </button>
  </div>
</el-modal>

  