import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchEmployeeComponent } from '@payroll/action-edit/search-employee/search-employee.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { delay, map, retryWhen, take, tap } from 'rxjs/operators';
import { LoaderService } from 'src/app/layout/loader/loader.service';
import { Departments } from 'src/app/models/departments.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { DepartmentService } from 'src/app/services/department.service';
import { docService } from 'src/app/services/doc.service';
import { JobOfferService } from 'src/app/services/job-offer.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { VacanciesService } from 'src/app/services/vacancies.service';
import { NoWhitespaceValidator } from 'src/app/shared/custom-validation/custom-validation';
import { Account, AccountCertificationEnum, BankAccountCertification, RequestInfoBankAccountCertification } from 'src/app/shared/models/bankAccountCertification.model';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { InOutList } from 'src/app/shared/models/InOutList.model';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import Swal from 'sweetalert2';
import { IBankingEntity } from '../models/BankingEntity';
import { EmployeeEntryListHistory, IEmployee, IEmployeeBenefictContract, IEmployeePOST, IPersonJO, IPostulationJO } from '../models/Employee';
import { VacantBenefit } from '../models/VacantBenefit';
import * as moment from 'moment';

enum EmployeeType {
  EstatutoSimplificado = 2,
  Temporales = 3,
  LibreLibramiento = 1
}

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-job-offer-modal',
  templateUrl: './job-offer-modal.component.html',
  styleUrls: ['./job-offer-modal.component.css']
})
export class JobOfferModalComponent implements OnInit {
  enumEmployeeType = EmployeeType;
  loadingRequests = {
    bankInfo: true,
    info: true,
    photo: true,
    entryList: true
  }
  employeeCategories = []
  employeeCategoriesConfig = {
    displayKey: 'stringData', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };
  formIsInvalid = false;
  isLoading = true
  benefits = []
  accountTypes = [];
  positionMap: any[] = [];
  budgetAreas = []
  saving = false;
  suscriptions = new Subscription()
  employeeTypeList: any[] = [];
  bankingEntity: IBankingEntity[] = [];
  employeeBenefit: IEmployeeBenefictContract[] = []
  entryList: InOutList[] = [];
  locations: any[] = [];
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  benefitSelectConfig = {
    placeholder: 'Seleccionar',
    search: true,
    searchPlaceholder: 'Buscar',
    moreText: 'más',
    displayKey: 'stringData'
  }
  budgetAreaSelectConfig = {
    displayKey: 'description', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };


  postulationSelected: IPostulationJO;
  form: FormGroup;
  departmentSelected: Departments;
  bondingTypeList = [];

  guiID = '';
  file = { document: '', entryListEvidence: '' }
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  branches: any[] = []
  personalFile = personalFile;
  employeeSelected: any;
  typeDocument: ParameterControl;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<JobOfferModalComponent>,
    private _ParamService: ParameterControlService,
    private actionPSrv: PersonnelActionsService,
    private toastr: ToastrService,
    private srvDoc: docService,
    private dialog: MatDialog,
    private vacanciesService: VacanciesService,
    private jobOfferService: JobOfferService,
    private _departmentService: DepartmentService,
    private authInfo: AuthInfoService,
    private conpaniesService: CompaniesService,
    private _parameterCtrlService: ParameterControlService,
    @Inject(MAT_DIALOG_DATA) public data: { ppvid: number, postulation: any, inDetails: boolean }
  ) { }

  ngOnInit(): void {
    this.createForm()
    this.openModalWithData()
    this.getParameterRoutes();
    this.getEmployeeCategories()
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  getParameterRoutes() {
    this._parameterCtrlService.getParameters(parameterDoc.PathTest).subscribe(e => {
      if (e.dataList.length > 0) {
        this.routeFile = e.dataList[0].stringData.toString();
      }
    })
  }


  manageFiles(file, position) {
    if (this.data?.inDetails) return;

    let data = {
      SystemLoad: this.SystemLoad,
      ModuleLoad: this.ModuleLoad,
      personalFile: this.personalFile.photo2x2,
      routeFile: this.routeFile
    }

    let formData = new FormData
    formData.append(file.name, file)

    this.srvDoc.createFile(data, formData).subscribe((result: any) => {
      if (result.errors.length > 0) { this.toastr.error(result.errors[0], "Error al tratando de subir el archivo"); return; }
      if (result.succeded) {
        let resData = result.data as FileResponse
        this.guiID = this.getGuid(resData?.fileUnit.guidname)
        this.file.entryListEvidence = resData.path;
        this.form.get('employeeEntryList')["controls"][position]?.get('evidence').setValue(this.guiID ? this.guiID : null)
      }
    }, err => { this.toastr.error(err, "Error al tratando de subir el archivo") }
    )
  }


  submit() {
    this.saving = true
    this.formIsInvalid = this.form.invalid;

    if (this.formIsInvalid) return;
    if (this.form.get("isSupervisor").value == true && this.departmentSelected.supervisor != "No Definido") {
      this.toastr.error('Ya existe un supervisor en esta unidad organizativa')
      return
    }
    this.postJobOffer(this.getEmployeeValues());
  }

  private postJobOffer(employeeValues: IEmployeePOST) {
    this.jobOfferService.postJobOffer(employeeValues).subscribe(async res => {
      if (res.errors.length > 0) {
        this.toastr.error(res.errors[0])
        return;
      }
      const bankAccountCertification: BankAccountCertification = this.builkBankAccountCertification(res?.identity);
      this.jobOfferService.requestBankAccountCertification(bankAccountCertification).subscribe({
        next: (res) => { }
      })
      this.toastr.success("Esta persona ha sido vinculada con éxito");
      this.dialogRef.close(true)
      this.saving = false
      this.submitToEntryListHistory()
    }, e => {
      if (e.error.errors.BankAccount.length !== 0) {
        this.toastr.error('El Número de cuenta debe tener un limite de 50 caracteres')
        return
      }
      this.toastr.error(this.errorMessage)
    })
  }

  builkBankAccountCertification(employeeId: number): BankAccountCertification {
    const account: Account = {
      tipoDocumento: this.typeDocument?.ccode,
      documento: this.postulationSelected?.personalIdentification,
      banco: this.form?.get('bankEntity')?.value?.ccode,
      tipoCuenta: this.form?.get('accountType')?.value?.ccode,
      cuenta: this.getEmployeeValues()?.bankAccount,
      nombreBeneficiario: this.postulationSelected?.personName,
      moneda: AccountCertificationEnum?.Currency
    }
    const requestInfo: RequestInfoBankAccountCertification = {
      origen: AccountCertificationEnum?.Source,
      cuentas: [account]
    }
    const bankAccountCertification: BankAccountCertification = {
      requestInfo: requestInfo,
      employeeId: employeeId,
      companyId: this.authInfo?.getCompanyId(),
      userId: this.authInfo?.getUserId(),
    }
    return bankAccountCertification;
  }

  submitToEntryListHistory() {

    let body: any[] = this.form.get('employeeEntryList').value;
    let bodyCopy = this.form.get('employeeEntryListCopy').value;

    if (body.length < 1) {
      return;
    }

    let history: EmployeeEntryListHistory[] = bodyCopy.filter(x => {
      return body.find(f => f.ocode == x.ocode && f.selected != x.selected);
    })

    let historyFormatted: any[] = history.map(element => {
      return {
        companyId: element.companyId,
        employeeEntryListId: element.employeeEntryListId,
        selected: element.selected,
        observations: element?.observations,
        createUserId: this.authInfo.getUserId()
      }
    });


    this.actionPSrv.insertEmployeeEntryListHistory(historyFormatted).subscribe((res: any) => {
      if (res.errors < 1) {

      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ocurrió un error al guardar el historico, por favor validar con TI.')
    })
  }


  getEmployeeCategories(){
    this._ParamService.getParameters(keyWord.EmployeeCategory).subscribe({
      next: (res:any) => {
        if(res.errors.length > 0){
          this.toastr.error("Ha ocurrido un error inesperado")
          return
        }
        this.employeeCategories = res.dataList
        this.setEmployeeCategory()

      }, error: (error:any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  openModalWithData() {
    let ppvid = this.data.ppvid

    this.postulationSelected = null;
    this.resetForm();
    this.getPositionMap();
    this.getLocations();
    if (!this.data?.inDetails) {
      this.getEntryListParameter();
    }
    this.getBudgetAreas();
    this.getBranches()
    this.loadingRequests.bankInfo = this.data.inDetails;

    if (this.data.inDetails) {
      this.form.disable();
    } else {
      this.getAccountTypes();
      this.getBankingEntities();
    }
    this.getPersonData(ppvid)
    this.getParameter();
  }
  getBranches() {
    this.conpaniesService.getCompanies().subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          this.toastr.error(res.errors[0])
          return;
        }
        this.branches = res.dataList;
      },
      error: (err) => {

      }
    });
  }

  getParameter() {
    var parameterControlSUbscription = this._ParamService.getParameters(keyWord.RequisitionType).subscribe((data) => {
      this.bondingTypeList = data.dataList;
    });
  }

  getEmployeeEntryList(person) {
    let entrySub = this.jobOfferService.getEntryListByEmployeeId(person?.employeeId).subscribe((response: ResponseModel<any>) => {
      if (response.succeded) {
        this.entryList.forEach((item, i: number) => {
          this.pushEntryListItems(item?.inOutId, person.employeeId, response.dataList.find(x => x.ocode == item.inOutId)?.employeeEntryListId ?? 0, response.dataList.find(x => x.ocode == item.inOutId)?.selected ?? false,
            response.dataList.find(x => x.ocode == item.inOutId)?.observations);
        });
      }
    })
    this.suscriptions.add(entrySub)
  }

  getEmployeeByPPVID(ppvid) {
    let sub = this.jobOfferService.getEmployeeByPPVID(ppvid).subscribe((res: ResponseModel<any>) => {
      if (!res.succeded) { this.toastr.error(res.errors[0]); return; }
      let data = res.dataList[0];
      this.employeeSelected = data;
      this.getEntryListParameter();
      this.getBankingEntities(data);
      this.setEmployeeCategory()
      this.form.patchValue({
        bankAccount: data?.bankAccount,
        employeeType: data?.employeeType,
        bondingType: data?.contractType,
        budgetAreaO: this.budgetAreas?.find(x => x?.budgetAreaId == data?.budgetAreaId),
        branch: this.branches?.find(branch => branch?.companyId == data?.branchId),
        employeeSupervisor: data?.employeeSupervisor,
        supervisorId: data?.employeeSupervisorId,
        //departmentSupervisor: data?.supervisor,
        departmentSupervisorId: data?.supervisorId,
      })
      this.loadingRequests.bankInfo = false;
      this.getVacantBenefits();
      this.getAccountTypes();
    }, error => {
      this.toastr.error(this.errorMessage)
    })
    this.suscriptions.add(sub)
  }

  getDepartmentById(departmentId: number) {
    this._departmentService.getById(1, departmentId).subscribe(res => {
      if (res.errors.length > 0) {
        return;
      }
      this.departmentSelected = res?.dataList[0]
      if (this.departmentSelected.supervisor != "No Definido") {
        this.form.get("isSupervisor").disable()
      }
    })
  }

  getEmployeeType(contractTypeId: number) {
    this.jobOfferService.getEmployeeType(contractTypeId).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          });
        }
        this.employeeTypeList = res.dataList.filter(e => e?.status);
      },
      error: (err) => {
        this.toastr.error('Ocurrió un error tratando de buscar los tipos de Servidor Público. Favor de comunicarse con un Administrador.')
      }
    })
  }

  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  changeBondingType(value) {
    if (value) {
      this.postulationSelected.bondingType = value.ocode;
      this.postulationSelected.bondingTypeName = value.stringData;
      this.getEmployeeType(value.ocode);
    } else {
      this.postulationSelected.bondingType = null;
      this.postulationSelected.bondingTypeName = null;
    }
  }

  setEmployeeCategory(){
    if(this.data.inDetails == false){
      return
    }
    if(!this.employeeCategories){
      return
    }
    if(this.employeeCategories.length < 1){
      return
    }
    if(!this.employeeSelected){
      return
    }
    this.form.get('category').patchValue(this.employeeCategories.find(x => x.ocode == this.employeeSelected.categoryId))
  }

  changeEmployeeType(value) {
    if (value) {
      this.form.get('employeeTypeId').setValue(value?.employeeTypeId);
    } else {
      this.form.get('employeeTypeId').setValue(null);
    }
  }

  getTypeDocument(typeDocumentId: number) {
    this._parameterCtrlService.getParameters(keyWord.IDType).subscribe({
      next: (res) => {
        if (res?.succeded) {
          this.typeDocument = res.dataList?.find(d => d?.ocode === typeDocumentId);
        }
      }
    })
  }

  getPersonData(ppvid) {
    this.jobOfferService.getPostulationsFilteredByIdPpv(ppvid).subscribe((res: any) => {
      if (res.succeded == false) {
        this.toastr.error(res.errors[0]);
        return
      }
      this.postulationSelected = res.dataList[0];
      this.getTypeDocument(this.postulationSelected?.typeIdentificationId);
      if (!this.data.inDetails) {
        this.getVacantBenefits()
      }

      const admission = this.postulationSelected.contractAdmissionDateApproved
      const ending = this.postulationSelected.contractEndingDateApproved

      const { departmentName, positionApproved, personName,
              referralNumber, positionName, salaryApproved, desiredSalary } = this.postulationSelected

      this.form.patchValue({
        department: departmentName,
        mapPosition: positionApproved,
        personName: personName,
        referralNumber: referralNumber,
        position: positionName,
        salary: salaryApproved,
        desiredSalary: desiredSalary,
        contractStart: admission ? moment(admission).format('yyyy-MM-DD'): null,
        admissionDate: admission ? moment(admission).format('yyyy-MM-DD'): null,
        contractExpiration: ending ? moment(ending).format('yyyy-MM-DD'): null
      })
      this.form.get('contractExpiration').disable()
      if (this.postulationSelected.requisitionTypeId == 5) {
        this.form.get('contractExpiration').enable()
      }

      if (this.data.inDetails == false) {
        this.form.patchValue({
          bondingType: this.postulationSelected.bondingTypeName
        })

        if (this.postulationSelected.requesitiontTypeId == 5) {
          this.form.patchValue({
            bondingType: this.postulationSelected.requisitionType
          })
        }
      }

      if (this.postulationSelected.requesitiontTypeId == 5) {
        this.form.get('salary').setValue(this.postulationSelected.proposedSalary);
        this.form.get('mapPosition').setValue(this.postulationSelected.positionName);
        this.getEmployeeType(this.postulationSelected.requisitionTypeId);
      } else {
        this.getEmployeeType(this.postulationSelected.bondingType);
      }

      this.getDepartmentById(this.postulationSelected?.departmentId);

      if (this.postulationSelected.photo) {
        this.getPersonPhoto(this.postulationSelected.photo)
      } else {
        this.loadingRequests.photo = false
      }
      this.loadingRequests.info = false;
    }, error => {
      this.toastr.error(this.errorMessage)
    });
  }
  close() {
    this.dialogRef.close()
  }
  createForm() {
    this.form = this.fb.group({
      branch: ['', Validators.required],
      department: [''],
      personName: [''],
      bondingType: [''],
      bondingTypeObject: [],
      employeeType: ['', Validators.required],
      employeeTypeId: ['', Validators.required],
      mapPosition: [''],
      position: [''],
      salary: [0],
      desiredSalary: [0],
      bankAccount: [0, [Validators.required, NoWhitespaceValidator]],
      accountType: [null, [Validators.required]],
      bankEntity: [0, Validators.required],
      admissionDate: [''],
      referralNumber: [0],
      contractStart: [''],
      contractExpiration: [''],
      employeeBenefits: [null],
      budgetAreaId: [null, Validators.required],
      budgetAreaO: [''],
      category: ['', Validators.required],
      isSupervisor: [false],
      employeeEntryList: new FormArray([]),
      employeeEntryListCopy: new FormArray([]),
      employeeSupervisor: ['', [Validators.required]],
      //departmentSupervisor: [''],
      supervisorId: [null, [Validators.required]]
    })
  }
  fillBudgetArea(value: any) {
    this.form.controls.budgetAreaId.setValue(value.budgetAreaId);
  }

  fillBenefits(benefits: any) {
    benefits.forEach(benefit => {
      if (this.employeeBenefit.find(eb => eb.benefitId == benefit.ocode)) {
        return
      }
      this.employeeBenefit.push({
        ecbid: 0,
        employeeId: 0,
        benefitId: benefit.ocode,
        companyId: this.authInfo.getCompanyId(),
        status: true,
      })
    });
  }

  getEntryListParameter() {
    this.jobOfferService.getInOutList().subscribe({
      next: res => {
        if (res.errors.length > 0) {
          this.toastr.error(res.errors[0])
          return;
        }
        this.entryList = res.dataList;
        if (!this.data?.inDetails) {
          this.entryList?.forEach((benefit) => {
            this.pushEntryListItems(benefit?.inOutId);
          })
        }
        if (this.data?.inDetails) {
          this.getEmployeeEntryList(this.employeeSelected);
        }
      }, error: () => {
        this.toastr.error(this.errorMessage);
      }
    })
  }
  getEmployeeValues(): IEmployeePOST {
    let benefits: IEmployeeBenefictContract[] = []
    this.form.get('employeeBenefits')?.value?.forEach(element => {
      benefits.push({
        ecbid: 0,
        employeeId: 0,
        benefitId: element.ocode,
        companyId: this.postulationSelected.companyId,
        createUserId: this.authInfo?.getUserId(),
        status: true,
      })
    });
    let employee: IEmployeePOST = {
      categoryId: this.form.get('category').value?.ocode,
      branchId: this.form.get('branch').value?.companyId,
      contractExpiration: this.form.get('contractExpiration').value ? formatDate(this.form.get('contractExpiration').value, 'yyyy-MM-dd', 'en') : null,
      bankAccount: this.form.get('bankAccount').value.trim(),
      positionId: this.postulationSelected.positionId,
      departmentId: this.postulationSelected.departmentId,
      postulationId: this.postulationSelected.ppvid,
      typeEmployeeId: this.form.get('employeeTypeId').value,
      entityBankId: this.form.get('bankEntity').value?.ocode ?? 0,
      accountTypeId: this.form.get('accountType').value?.ocode ?? 0,
      typeContractId: this.postulationSelected.requesitiontTypeId == 5 ? this.postulationSelected.requesitiontTypeId : this.postulationSelected.bondingType,
      personId: this.postulationSelected.personId,
      sendMapId: Number(this.postulationSelected.referralNumber),
      isSupervisor: this.form.get('isSupervisor').value,
      budgetAreaId: this.form.get('budgetAreaId').value,
      admissionDate: formatDate(this.form.get('admissionDate').value, 'yyyy-MM-dd', 'en'),
      effectiveDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
      salary: this.postulationSelected.requesitiontTypeId == 5 ? this.postulationSelected.proposedSalary : this.postulationSelected.salaryApproved,
      desiredSalary: this.postulationSelected.desiredSalary,
      EmployeeBenefictContract: benefits,
      employeeEntryList: this.form.get('employeeEntryList').value,
      companyId: this.postulationSelected.companyId,
      status: true,
      createUserId: this.authInfo?.getUserId(),
      supervisorId: this.form.get('supervisorId')?.value
    }
    return employee;
  }
  pushEntryListItems(benefitType: number, employeeId: number = 0, employeeEntryListId: number = 0, selected: boolean = false, observations: string = '') {
    const item = this.form.get('employeeEntryList') as FormArray;
    const itemCopy = this.form.get('employeeEntryListCopy') as FormArray;
    item.push(this.buildEntryListForm(benefitType, employeeId, employeeEntryListId, selected, observations));
    itemCopy.push(this.buildEntryListForm(benefitType, employeeId, employeeEntryListId, selected, observations));
  }
  buildEntryListForm(ocode: number, employeeId: number, employeeEntryListId: number, selected: boolean, observations: string = '') {

    return this.fb.group({
      employeeEntryListId: [employeeEntryListId],
      employeeId: [Number(employeeId)],
      ocode: [ocode, [Validators.required]],
      selected: [{ value: selected, disabled: this.data.inDetails }, [Validators.required]],
      companyId: [this.authInfo.getCompanyId()],
      status: [true],
      evidence: [null],
      observations: [observations],
      assignedBy: this.authInfo.getUserId()
    });

  }
  resetForm() {
    this.form.patchValue({
      branch: '',
      department: '',
      personName: '',
      bondingType: '',
      employeeTypeId: '',
      mapPosition: '',
      position: '',
      salary: '',
      desiredSalary: '',
      bankAccount: '',
      bankEntity: '',
      admissionDate: '',
      referralNumber: '',
      contractStart: '',
      contractExpiration: '',
      employeeBenefits: null,
      budgetAreaId: '',
      isSupervisor: false
    });
    while ((this.form.get('employeeEntryList') as FormArray).controls.length > 0) {
      (this.form.get('employeeEntryList') as FormArray).removeAt(
        (this.form.get('employeeEntryList') as FormArray).controls.length - 1);
    }
  }
  getBankingEntities(dataEmployee: any = null) {
    let sub = this._ParamService.getParameters(keyWord.BankingEntity).subscribe((data) => {
      if (data.succeded) {
        this.bankingEntity = data.dataList;
        if (this.data?.inDetails) {
          this.form.patchValue({
            bankEntity: this.bankingEntity?.find((b) => b?.ocode === dataEmployee?.entityBankId)
          });
        }
      } else {
        this.toastr.error(data.errors[0]);
      }
    }, (error) => {
      this.toastr.error(this.errorMessage);
    });
    this.suscriptions.add(sub)
  }
  getVacantBenefits() {
    this._ParamService.getParameters(keyWord.typeBenefits).subscribe(res => {
      if (res.succeded) {
        this.benefits = res.dataList
        if (this.employeeSelected?.employeeId) {
          this.getBenefits();
        } else {
          this.getEmployeeVacantBenefits();
        }
      } else {
        this.toastr.error(res.errors[0]);
      }
    }, error => {
      this.toastr.error(this.errorMessage)
    })
  }

  getAccountTypes() {
    this._ParamService.getParameters(keyWord.AccountType).subscribe(e => {
      if (e.errors.length > 0) {
        this.toastr.error(e.errors[0])
        return
      }
      this.accountTypes = e?.dataList.filter(d => d?.status);
      this.form.get('accountType').setValue(this.accountTypes?.find(a => a?.ocode === this.employeeSelected?.accountTypeId));
    });
  }

  get getEmployeeBenefits() {

    return this.form?.get('employeeBenefits')?.value as any[];
  }
  getEmployeeVacantBenefits() {
    this.vacanciesService.getBenefitsByVacantsId(this.postulationSelected.vacantId).subscribe({
      next: (res: any) => {
        let employeeBenefits = []
        let employeeCurrentBenefit = this.form?.get('employeeBenefits')?.value || [];
        res.dataList.forEach(benefit => {
          if (!this.employeeSelected?.employeeId) {
            employeeBenefits.push(this.benefits.find((benefits) => benefits.ocode == benefit.typeBenefitId))
          } else {
            if (!employeeCurrentBenefit?.some(e => e?.ocode === benefit.typeBenefitId)) {
              employeeBenefits.push(this.benefits.find((benefits) => benefits.ocode == benefit.typeBenefitId))
            }
          }
        });
        this.form?.get('employeeBenefits').setValue([...employeeCurrentBenefit, ...employeeBenefits])
      }
    })
  }

  getBenefits() {
    let sub = this.jobOfferService.getEmployeesContractBenefit(this.employeeSelected?.employeeId).subscribe((res: ResponseModel<VacantBenefit>) => {
      if (res.succeded) {
        let benefits = res.dataList;
        let b = []
        benefits.forEach(e => {
          b.push(this.benefits.find(x => x.ocode == e.benefitId))
        })
        this.form.get('employeeBenefits').patchValue(b);
        this.getEmployeeVacantBenefits();
      } else {
        this.toastr.error(res.errors[0])
      }
    }, (error) => {
      this.toastr.error(this.errorMessage)
    });
    this.suscriptions.add(sub)
  }
  getPositionMap() {
    let sub = this.jobOfferService.getPositionMap().subscribe((data: any) => {
      if (data.errors.length > 0) {
        this.toastr.error(data.errors[0])
        return
      }
      this.positionMap = data.dataList;
    }, (error) => {
    });
    this.suscriptions.add(sub)
  }
  getLocations() {
    let sub = this.jobOfferService.getLocations().subscribe((data: any) => {
      if (data.succeded) {
        this.locations = data.dataList;
      } else {
        this.toastr.error(data.errors[0])
      }
    }, (error) => {
      this.toastr.error(this.errorMessage)
    });
    this.suscriptions.add(sub)
  }
  getBudgetAreas() {
    let sub = this.jobOfferService.getBudgetAreas().subscribe((data: any) => {
      if (data.succeded) {
        this.budgetAreas = data.dataList.filter(b => b.status);
      } else {
        this.toastr.error(data.errors[0])
      }
      if (this.data?.inDetails) {
        this.getEmployeeByPPVID(this.data?.ppvid)
      }
    }, (error) => {
      this.toastr.error(this.errorMessage)
    });
    this.suscriptions.add(sub)
  }

  getPersonPhoto(photo) {
    let sub = this.srvDoc.getDocument(photo).subscribe(e => {
      if (e.succeded) {
        this.postulationSelected['photoloaded'] = e.data
        this.loadingRequests.photo = false;
      } else {
        this.toastr.error(e.errors[0]);
      }
    }, error => {
      this.toastr.error(this.errorMessage)
    })
    this.suscriptions.add(sub)
  }

  isLoadingData() {
    let loading = this.loadingRequests;
    if (loading.info && loading.photo && loading.bankInfo && loading.entryList) {
      return true;
    }
    return false;
  }
  showBudgetAreaErrors() {
    let field = this.form.get('budgetAreaId');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showBankEntityErrors() {
    let field = this.form.get('bankEntity');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showBankAccountErrors() {
    let field = this.form.get('bankAccount');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    if (field.hasError('whitespace')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showEmployeeTypeErrors() {
    let field = this.form.get('employeeType');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showBranchErrors() {
    let field = this.form.get('branch');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  getDropdownSettings(idField = 'item_id', textField = 'item_text', checkAll = true) {
    const dropdownSettings: IDropdownSettings = {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      enableCheckAll: checkAll,
      itemsShowLimit: 2,
      allowSearchFilter: true,
      limitSelection: -1,

    };
    return dropdownSettings
  }

  onDeSelect(event) {
  }
  onSelect(event) {
  }
  onDeSelectAll(event) {
  }
  onSelectAll(event) {
  }

  openModalSearchEmployee() {
    this.dialog.open(SearchEmployeeComponent, {
      data: {
        hideInputTeacher: true
      },
      width: '80%'
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.data !== undefined) {
          const employee = { ...result.data };
          this.form.patchValue({
            employeeSupervisor: employee?.name,
            supervisorId: employee?.employeeId
          });
        }
      });
  }

}
