export interface BankAccountCertification {
    employeeId: number,
    companyId: number,
    userId: number,
    requestInfo?: RequestInfoBankAccountCertification;
}

export interface RequestInfoBankAccountCertification {
    origen: string;
    cuentas: Account[];
}

export interface Account {
    tipoDocumento: string;
    documento: string;
    banco: string;
    tipoCuenta: string;
    cuenta: string;
    nombreBeneficiario: string;
    moneda: string;
}

export enum AccountCertificationEnum {
    Source = 'SIGEI',
    AccountType = 'CA',
    Currency = '1'
}

export interface BankAccountCertificationStatus {
    idSolcitud: string;
    tipoDocumento: string;
    documento: string;
    nombre: string;
    banco: string;
    tipoCuenta: string;
    cuenta: string;
    moneda: string;
    idCertificacion: string;
    fechaRegistroCertificacion: string;
    fechaImputacionCertificacion: string;
    resultado: string;
    error: string;
}

export interface ChangeStatusBankAccountCertification {
    employeeId: number;
    statusBankAccountCertification: boolean;
    userId: number;
    companyId: number;
}