<div class="container-fluid ">
    <div class="card card-custom">
        <div class="card-header mt-3  d-flex justify-content-between">
            <div class="card-title ">
                <div class="ttl d-flex align-items-center ">
                    <i class="flaticon-proceso-2 icon-3x text-danger mr-2 forward-btn"></i>
                    <h1 class="text-primary forward-btn">Reporte de Auditoria de Nómina</h1>
                </div>
            </div>
            <div class="card-toolbar">
                <button (click)="goBack()" class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
                    <i class="flaticon2-left-arrow-1"></i>
                    <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
                </button>
            </div>
        </div>
        <div class="card-header row justify-content-between p-2 mx-0 ">

            <div class="col-sm-12 col-md-8 c-left forward-btn">
                <div class="card-toolbar d-flex justify-content-start" [formGroup]="form">
                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2">
                            <label class="text-primary">
                                Año
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown [options]="yearsList" (change)="changeYear($event)"
                                    [config]="dropdownConfigDescription" class="w-100"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2">
                            <label class="text-primary">
                                Período<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown (change)="getPayrollVersions()" formControlName="period"
                                    [options]="filteredPeriods" [config]="dropdownConfigDescription" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2">
                            <label class="text-primary">
                                Área Presupuestaria<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown (change)="getPayrollVersions()" [options]="budgetAreaList"
                                    formControlName="area" [config]="dropdownConfigDescription" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2">
                            <label class="text-primary">
                                Tipo de Nómina<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown (change)="changePayrollType($event?.value)" formControlName="payrollType"
                                    [options]="payrollTypes" [config]="dropdownConfigStringData" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 m1-2 px-0 pt-1" *ngIf="form.get('payrollType')?.value?.ocode === 4">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Período Anterior<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown 
                                [options]="relatedPeriods" 
                                formControlName="relatedPeriod"
                                [config]="dropdownConfigDescription" class="w-100"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Versión<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown formControlName="version" [options]="payrollVersions"
                                    [config]="dropdownConfigVersion" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-sm-12 col-md-4 c-right mr-0 forward-btn">
                <div class="card-toolbar d-flex justify-content-end">
                    <a data-toggle="tooltip" data-original-title="Generar Reporte" (click)="openReportAudit()"
                        class="btn btn-success btn-pill font-weight-bolder mt-9 mx-2 col-md-auto ">
                        <i class="flaticon2-search-1 icon-2x"></i>
                        Generar Reporte
                    </a>
                </div>
            </div>
        </div>
</div>