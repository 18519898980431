import { Component, OnInit } from '@angular/core';
import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ChangeDetectorRef, ElementRef, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { ToastService } from "src/app/shared/toast/toast.service";
import { environment } from "src/environments/environment";
import { SalaryRangeComponent } from 'src/app/shared/salary-range/salary-range.component';
import { RequestNoObjectionService } from 'src/app/services/request-no-objection.service';
import { INoObjecionView, IReturnForRevision, ISendMapData, MapObject } from 'src/app/models/map';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import { DomSanitizer } from '@angular/platform-browser';
import { docService } from 'src/app/services/doc.service';
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import Swal from 'sweetalert2';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-edit-no-objection-modal',
  templateUrl: './edit-no-objection-modal.component.html',
  styleUrls: ['./edit-no-objection-modal.component.css']
})
export class EditNoObjectionModalComponent  {

  postulationSelected: INoObjecionView;
  mapDataList: any[] = [];
  postulationForm: FormGroup;
  isSalaryInvalid: boolean;
  CompanySelected: any;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  contractTime;
  hasValidDate: boolean = false;

  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  personalFile = personalFile;

  @ViewChild('uppyFile')
  uppyFileComponent: UppyFileComponent | null = null;

  constructor(
    private toastService: ToastService,
    public dialogRef: MatDialogRef<EditNoObjectionModalComponent>,
    private _parameterCtrlService: ParameterControlService,
    private _changeDet: ChangeDetectorRef,
    private fb: FormBuilder,
    private _toastService: ToastService,
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _requestNoObjectionService: RequestNoObjectionService,
    private sanitizer: DomSanitizer,
    private srvDoc: docService,
    private authInfo: AuthInfoService,
    @Inject(MAT_DIALOG_DATA) public data: INoObjecionView) {
    
    this.postulationSelected = this.data;
    this.postulationSelected.proposedSalary
    this.dialogRef.disableClose = true;

    this.getDataList(data);
  }

  selectSalaryRange(salary: number) {
    this.postulationForm.get('proposedSalary').setValue(salary)
    this.isSalaryInvalid = false
  }

  getTextFromValue(fromApiList, prop: string, key) {
    return fromApiList.find(x => x[prop] == key).stringData;
  }

  getDataList(model: INoObjecionView) {

    this.buildForm(model)

    this._requestNoObjectionService.getNoObjectionMap(model?.lastPostulationSendMapId, model?.companyId).subscribe((data: any) => {
      if (data.succeded) {
        this.mapDataList = (data.dataList) ? data.dataList : [];
        this.mapDataList = this.mapDataList.filter(x => x.sendMapId == model.lastPostulationSendMapId);
      } else {
        data.errors.forEach(err => {
          this._toastService.error(err)
        });
      }
    }, error => {
      this._toastService.error('Ha ocurrido un error inesperado')
    });

  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  buildForm(model: INoObjecionView) {

    this.postulationForm = new FormGroup({
      ppvid: new FormControl(model.ppvid),
      proposedSalary: new FormControl(model.proposedSalary, [Validators.required]),
      dateAvailability: new FormControl(formatDate(new Date(model.dateAvailability), 'yyyy-MM-dd', 'en'), [Validators.required]),
      revisionFile: new FormControl(null, [Validators.required])
    })

  }

  getUserName(){
    return this.authInfo.getUser().firstName.trim() + ' ' + this.authInfo.getUser().lastName.trim()
  }

  setFile(event){
    this.postulationForm.get("revisionFile").setValue(event);
  }

  onSubmit() {
    if (this.postulationForm.invalid) {
      this.toastService.warning('Debe completar los campos obligatorios');
    }

    var body: IReturnForRevision = this.postulationForm.value;
    this.uppyFileComponent.handleFile((guid: string) => {
      body.revisionFile = guid;
      let mapModel: ISendMapData = {
        sendMapId: 0,
        sedMapHeaderId: 0,
        postulationId: body.ppvid,
        positionApprovedId: 0,
        salaryRequested: 0,
        salaryApproved: 0,
        occupationalGroupId: 0,
        departmentId: 0,
        bondingType: 0,
        requestedDate: undefined,
        approvedDate: undefined,
        validyDate: undefined,
        rejectDate: undefined,
        observationMap: '',
        condition: 0,
        companyId: this.postulationSelected.companyId,
        status: true,
        rejectComment: '',
        revisionFile: guid,
        createdByUserName: null,
        modifiedByUserName: this.getUserName()
      }
  
      body.sendMapData = mapModel;
  
      this._requestNoObjectionService.returnRevisionToMap(body).subscribe({
        next: (res)=>{
          if(!res.succeded){
            res.errors.forEach(err=>{
              this.toastService.error(err);
            });
            return;
          }
  
          Swal.fire({
            title: "Datos enviados",
            icon: "success"
          });
  
          this.dialogRef.close(true);
        },
        error: (err)=>{
          this.toastService.error('Ha ocurrido un error inesperado. Favor de comunicarse con un administrador.');
        }
      });
    });
  }

  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Click para desplegar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  onSalaryError() {
    if (this.postulationForm.get('proposedSalary').invalid) {
      return
    }
    if (this.postulationForm.get('proposedSalary').value > this.postulationSelected.maxSalary || this.postulationForm.get('proposedSalary').value < this.postulationSelected.minSalary) {
      this.isSalaryInvalid = true
    }
    else {
      this.isSalaryInvalid = false
    }
  }


  showApprovedSalaryErrors() {
    let field = this.postulationForm.get('proposedSalary');
    if (field.hasError('required')) {
      this.isSalaryInvalid = false
      return 'Debe ingresar un salario';
    }
    return '';
  }

  getTextFromDescription(fromApiList, prop: string, key) {
    return fromApiList.find(x => x[prop] == key).description;
  }


  get showErrors() {
    return {
      dateAvailability: this.postulationForm.get('dateAvailability').invalid,
      proposedSalary: this.postulationForm.get('proposedSalary').invalid,
      revisionFile: this.postulationForm.get('revisionFile').invalid
    }
  }

  openSalaryRangeComponent() {
    this._dialog.open(SalaryRangeComponent, {
      data: {
        postulationSelected: this.postulationSelected,
      },

    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.Applied) {
          this.selectSalaryRange(result?.salary);
        }
      });
  }


}
