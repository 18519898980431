<ng-container>
    <div class="card card-custom gutter-b">
        <div class="card-header mt-3">
            <div class="card-title">
                <div class="ttl d-flex align-items-center">
                    <i class="flaticon-proceso-3 icon-3x text-danger mr-2"></i>
                    <h1 class="text-primary">Beneficiarios de Conceptos de Nómina</h1>
                </div>
            </div>
        </div>

        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-7 c-left">
                <div class="card-toolbar d-flex justify-content-start">
                    <div class="col-md-4 mr-2 px-0 pt-1">
                        <div class="form-group institution">

                            <label class="text-primary">
                                Estatus
                            </label>
                            <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                                [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                                (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <button data-toggle="modal" id="btnAdd" (click)="openBeneficiariesModal(1)"
                        *ngIf="privilege.addAction.value"
                        class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                        <i class="flaticon-mas icon-2x" data-toggle="tooltip"
                            data-original-title="Agregar nuevo Beneficiario"></i>
                        Agregar
                    </button>
                </div>
            </div>

            <div class="col-sm-12 col-md-5 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12"
                        *ngIf="privilege.exportAction.value">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <!-- <a (click)="exportToExcel()" class="dropdown-item align-items-center">
                                <i class="flaticon-excel icon-2x text-success mr-3"></i>
                                Excel
                            </a>
                            <a class="dropdown-item align-items-center" (click)="onBtnPrintClick()">
                                <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                                PDF
                            </a> -->
                            <a class="dropdown-item align-items-center" (click)="exportResport()">
                              <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                              Visualizar Reporte
                          </a>
                        </div>
                    </div>

                    <div class="col text-nowrap"
                        *ngIf="privilege.searchAction.value">
                        <el-search-bar #searchBar (onKeyChange)="onKeyChange($event)" [keyValue]="filterText"
                            [records]="beneficiariesOriginal" [itemForSearching]="searchBarDisplayNames"
                            (onChange)="getFilteredList($event);" (onKeyParameterChange)="onKeyParameterChange($event)"></el-search-bar>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="table-responsive table-responsive-sigei">
                <table class="table table-hover table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th>Secuencia</th>
                            <th>Tipo de Identificación</th>
                            <th>Documento de Identidad</th>
                            <th>Nombre del Beneficiario</th>
                            <th>Estatus</th>
                            <th class="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let beneficiary of paginatedBeneficiaries">
                            <td>{{beneficiary.conceptBeneficiaryId}}</td>
                            <td>{{beneficiary.identificationTypeName}}</td>
                            <td *ngIf="beneficiary.identificationType == 1">{{beneficiary.identification | mask:
                                '000-0000000-0'}}</td>
                            <td *ngIf="beneficiary.identificationType != 1">{{beneficiary.identification}}</td>
                            <td>{{beneficiary.description}}</td>
                            <td *ngIf="beneficiary.status == true">
                                <span style="width: 137px;">
                                    <span class="label label-success label-dot mr-2"></span>
                                    <span class="font-weight-bold text-success">Activo</span>
                                </span>
                            </td>
                            <td *ngIf="beneficiary.status == false">
                                <span style="width: 137px;">
                                    <span class="label label-danger label-dot mr-2"></span>
                                    <span class="font-weight-bold text-danger">Inactivo</span>
                                </span>
                            </td>
                            <td class="actionsBtn">
                                <button (click)="openBeneficiariesModal(2, beneficiary)" tooltip="Ver"
                                    *ngIf="privilege.viewAction.value"
                                    placement="top" data-toggle="modal"
                                    class="btn btn-sm btn-light-skype btn-icon mr-2">
                                    <i class="flaticon-ver icon-2x" data-toggle="tooltip" data-title="Ver"></i>
                                </button>
                                <button (click)="openBeneficiariesModal(3, beneficiary)" tooltip="Ver"
                                    *ngIf="privilege.editAction.value && beneficiary.status"
                                    placement="top" data-toggle="modal"
                                    class="btn btn-sm btn-light-warning btn-icon mr-2">
                                    <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                                </button>
                                <button
                                    *ngIf="privilege.deleteAction.value && beneficiary.status"
                                    (click)="deleteBeneficiary(beneficiary.conceptBeneficiaryId)" tooltip="Ver"
                                    placement="top" data-toggle="modal"
                                    class="btn btn-sm btn-light-danger btn-icon mr-2">
                                    <i class="flaticon-delete " data-toggle="tooltip" title="Inactivar"></i>
                                </button>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>

                    <el-caption [originalRecordsLength]="beneficiariesOriginal.length"
                        [listedRecordsLength]="paginatedBeneficiaries.length" #paginator
                        [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :beneficiaries.length"
                        [records]="filteredList.enable ? filteredList.records : beneficiaries"
                        (onChage)="getPaginatedRecords($event)"></el-caption>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<export-data-to-pdf [title]="'Beneficiarios de Conceptos de Nómina'" id="dataToPrint">
    <ng-container thead>
        <th>Tipo de Identificación</th>
        <th>Documento de Identidad</th>
        <th>Nombre del Beneficiario</th>
        <th>Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let beneficiary of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.beneficiaries"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td>{{beneficiary.identificationTypeName}}</td>
            <td *ngIf="beneficiary.identificationType == 1">{{beneficiary.identification | mask:
                '000-0000000-0'}}</td>
            <td *ngIf="beneficiary.identificationType != 1">{{beneficiary.identification}}</td>
            <td>{{beneficiary.description}}</td>
            <td *ngIf="beneficiary.status == true">
                <span style="width: 137px;">
                    <span class="label label-success label-dot mr-2"></span>
                    <span class="font-weight-bold text-success">Activo</span>
                </span>
            </td>
            <td *ngIf="beneficiary.status == false">
                <span style="width: 137px;">
                    <span class="label label-danger label-dot mr-2"></span>
                    <span class="font-weight-bold text-danger">Inactivo</span>
                </span>
            </td>
        </tr>
    </ng-container>
</export-data-to-pdf>
