import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { DataService } from './data.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { Account, IRefreshTokenModel } from 'src/app/shared/models/account';
import { environment } from 'src/environments/environment';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';


@Injectable({ providedIn: 'root' })
export class AccountService {
  private accountSubject: BehaviorSubject<Account>;
  public account: Observable<Account>;
  public env = environment.url;
  public baseUrl = `${environment.api_core_sigei}/DecodeToken`;
  public apiLobby = environment.api_lobby;

  public refreshTokenUrl: string = "user/refreshToken";

  constructor(
    private router: Router,
    private http: HttpClient,
    private dataService: DataService,
    private toast: ToastService) {
    this.accountSubject = new BehaviorSubject<Account>(null);
    this.account = this.accountSubject.asObservable();
  }

  public get accountValue(): Account {
    const Data = this.dataService.GetUserData();

    if (Data) {
      this.accountSubject = new BehaviorSubject<Account>(Data);
    } else {
      //this.accountSubject = new BehaviorSubject<Account>(new Account());
    }

    return this.accountSubject.value;
  }

  getAccountSubject(){
      return this.accountSubject.asObservable();
  }

  setAccountSubject(account: Account){
    this.accountSubject.next(account);
  }

  logout() {
    this.dataService.IsLoading$.emit(false);
    this.accountSubject.next(null);
    localStorage.clear();
    //let url = window.location.href

    // if (url.indexOf('localhost') >= 0) {
    //   window.location.replace('http://localhost:4200/')
    // } else {
    //   window.location.replace(this.env);
    // }
    window.location.replace(`${this.env}/account/log-out`);
  }

  logoutWithAuth(){
    this.dataService.IsLoading$.emit(false);
    this.accountSubject.next(null);
    localStorage.clear();
    //let url = window.location.href

    // if (url.indexOf('localhost') >= 0) {
    //   window.location.replace('http://localhost:4200/')
    // } else {
    //   window.location.replace(this.env);
    // }
    window.location.replace(`${this.env}`);
  }

  validateToken(token: string) {
    let model = {
      userToken: token,
      nameKey: "HRM"
    }
    return this.http.post<ResponseModel<any>>(this.baseUrl ,model);
  }

  getUserInfo(userId){
    return this.http.get(`${environment.api_core_sigei}/user/detail?UserId=${userId}`)
  }

  getAllowedUsersToAction(companyId: number, actionKey: string, optionKey: string){
    return this.http.get<ResponseModel<any>>(`${environment.api_core_sigei}/user?CompanyId=${companyId}&type=8&optionKey=${optionKey}&actionKey=${actionKey}`)
  }

  sendValidationCode(body: { companyId: number, userId: number, email: string }) {
    return this.http.post(`${this.apiLobby}/User/PasswordVerficationCode`, body)
  }

  updatePassword(passwordData) {
    return this.http.post(`${this.apiLobby}/User/ChangePassword`, passwordData)
  }
  refreshToken(model: IRefreshTokenModel){
    return this.http.post<ResponseModel<IRefreshTokenModel>>(`${environment.api_core_sigei}/${this.refreshTokenUrl}`,model);
  }


}
