import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { CollectiveVations } from 'src/app/models/collective-vations';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CollectiveVacationsService } from 'src/app/services/collective-vacations.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';

@Component({
  selector: 'add-collective-vacations',
  templateUrl: './add-collective-vacations.component.html',
  styleUrls: ['./add-collective-vacations.component.css']
})
export class AddCollectiveVacationsComponent implements OnInit {

  
  categoryTypes: ParameterControl[] = [];
  vacationYears = [];
  yearDropdownConfig = {
    displayKey: 'period',
    search: true,
    height: 'auto',
    placeholder: 'Seleccione una opción',
    moreText: '...',
    noResultsFound: 'No se han encontrado registros',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'period'
  }


  categoriesDropdownConfig = {
    displayKey: 'stringData',
    search: true,
    height: 'auto',
    placeholder: 'Todos',
    moreText: '...',
    noResultsFound: 'No se han encontrado registros',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'stringData'
  }

  titleModal = "Agregar Vacaciones Colectivas";
  vacationsForm: FormGroup = new FormGroup({
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
    year: new FormControl(''),
    totalDays: new FormControl('', Validators.required),
    comment: new FormControl(''),
    categories: new FormControl(''),
    period: new FormControl(null, Validators.required),
  })
  newtyTypes = [];

  datePipe = new DatePipe('en-Us')
  currentUserCompany: any

  minDate:any

  collectiveVacationDTO: CollectiveVations = new CollectiveVations()
  selectedVacation: any

  constructor(
    public dialogRef: MatDialogRef<AddCollectiveVacationsComponent>,
    private _collectiveVacationsService: CollectiveVacationsService,
    private _toastService: ToastService,
    private _authInfo: AuthInfoService,
    private _companiesService: CompaniesService,
    private srvParameter: ParameterControlService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true
  }

  ngOnInit(): void {
    const periods = [
      {
        year: moment().year()-3,
        period: `${moment().year()-3} - ${moment().year() -2 }`
      },
      {
        year: moment().year()-2,
        period: `${moment().year()-2} - ${moment().year() - 1}`
      },
      {
        year: moment().year()-1,
        period: `${moment().year()-1} - ${moment().year()}`
      },
      {
        year: moment().year(),
        period: `${moment().year()} - ${moment().year() + 1}`
      }
    ]
    this.vacationYears = periods
    this.getData()
    if (this.data.isEdit || this.data.isView) {
      this.selectedVacation = this.data?.vacation
      this.getSelectedVacationData()
    }
    if (!this.data.isEdit && this.data.isView) {
      this.vacationsForm.disable()
    }
    this.getAllCategoryTypes()
  }

  getData() {
    this._companiesService.getCompanyByUserAndId().subscribe({
      next: (res: any) => {
        this.currentUserCompany = res.dataList[0]
        this.vacationsForm.get('endDate').disable()
        this.vacationsForm.get('totalDays').disable()
        this.vacationsForm.get('totalDays').setValue(this.currentUserCompany?.daysDiscount)
      },
      error: (err: any) => {
      },
    })
  }

  startDateChange(date:Date){
    this.vacationsForm.get('endDate').enable()
    if (!date) {
      this.minDate =  new Date()
      this.vacationsForm.get('endDate').setValue('')
      this.vacationsForm.get('endDate').disable()
      return
    }
    let tomorrow =  new Date().setDate(date.getDate() + 2)
    this.minDate = new DatePipe('en-Us').transform(tomorrow, 'yyyy-MM-dd')

  }

  getSelectedVacationData() {
    this.collectiveVacationDTO.collectiveVacationsHeaderId = this.selectedVacation?.collectiveVacationsHeaderId
    if(this.selectedVacation.year != moment().year()){
      let model = {
        year: this.selectedVacation.year,
        period: `${this.selectedVacation.year} - ${this.selectedVacation.year + 1}`
      }
      this.vacationYears.push(model)
    }
    this.vacationsForm.setValue({
      period: this.vacationYears.find(x => x.year == this.selectedVacation.year),
      startDate: this.datePipe.transform(this.selectedVacation.startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(this.selectedVacation.endDate, 'yyyy-MM-dd'),
      year: this.selectedVacation.year,
      totalDays: this.selectedVacation.totalDays,
      comment: this.selectedVacation.comment,
    })
  }
  changeYear() {
  }
  submitVacation() {
    if (this.vacationsForm.invalid) {
      this._toastService.warning('Complete los campos requeridos')
      return
    }

    if(this.vacationsForm.get('startDate').value  > this.vacationsForm.get('endDate').value){
      this._toastService.warning('La fecha de inicio no puede ser mayor a la fecha de termino')
      return
    }

    if(new Date(this.vacationsForm.get('startDate').value).getFullYear() < moment().year()){
      this._toastService.warning('El Año al que pertenecen y la fechas colocadas no corresponden')
      return
    }


    this.collectiveVacationDTO = {
      ...this.collectiveVacationDTO,
      startDate: this.vacationsForm.get('startDate').value,
      endDate: this.vacationsForm.get('endDate').value,
      year: this.vacationsForm.get('period').value.year,
      totalDays: Number(this.vacationsForm.get('totalDays').value),
      comment: this.vacationsForm.get('comment').value,
      companyId: this._authInfo.getCompanyId(),
      conditionId: 1,
      createUserId: this._authInfo.getUserId(),
      createDate: new Date().toLocaleString(),
      employeeCategoryId: this.vacationsForm.get('categories').value.ocode ?? null,
      status: true
    }

    if (!this.collectiveVacationDTO.collectiveVacationsHeaderId) this.createVacation()
    else this.updateVacation()
  }

  createVacation() {
    this._collectiveVacationsService.createCollectiveVacation(this.collectiveVacationDTO).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) {
          this._toastService.error(res.errors[0], 'Ocurrió un error tratando de crear el registro')
          return
        }
        this._toastService.success('Registro creado satisfactoriamente')
        this.closeModal(true)
      },
      error: (err: any) => {
        this._toastService.error('Ocurrió un error tratando de crear el registro')
      }
    })
  }

  getAllCategoryTypes() {
    this.srvParameter.getParameters(keyWord.EmployeeCategory).subscribe(
      (res: any) => {
        if (res.succeded) {
          this.categoryTypes = res.dataList;
          console.log(this.categoryTypes);
        } else {
          this._toastService.error(res.errors[0]);
        }
      },
      (err) => {
        this._toastService.error(err);
      }
    );
  }

  updateVacation() {
    this._collectiveVacationsService.updateCollectiveVacation(this.collectiveVacationDTO).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) {
          this._toastService.error(res.errors[0], 'Ocurrió un error tratando de editar el registro')
          return
        }
        this._toastService.success('Registro editado satisfactoriamente')
        this.closeModal(true)
      },
      error: (err: any) => {
        this._toastService.error('Ocurrió un error tratando de editar el registro')
      }
    })
  }

  closeModal(created: boolean): void {
    this.dialogRef.close(created);
  }

}
