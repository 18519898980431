import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'application-tap',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {

  @Output() onClose = new EventEmitter<boolean>()
  constructor() { }
  selectedItems: any;
  ngOnInit(): void {
  }
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  statusOptions = [
    { item_id: 1, item_text: 'Texto de ejemplo' },
  ];

  close(){
    this.onClose.emit(true)
  }
}
