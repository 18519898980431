import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-transactions',
  templateUrl: './employee-transactions.component.html',
  styleUrls: ['./employee-transactions.component.css']
})
export class EmployeeTransactionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  addTransaction(){
    
  }

}
