import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Beneficiary } from '../models/beneficiary';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class BeneficiariesService {

  private readonly api = environment.api_url;

  constructor(
    private _http: HttpClient,
    private _authInfo: AuthInfoService
  ) { }

  getBeneficiaries() {
    return this._http.get(`${this.api}/ConceptBeneficiary?CompanyId=${this._authInfo.getCompanyId()}&Status=true`)
  }

  createBeneficiary(beneficiaryModel: Beneficiary) {
    return this._http.post(`${this.api}/ConceptBeneficiary`, beneficiaryModel)
  }

  updateBeneficiary(beneficiaryModel: Beneficiary) {
    return this._http.put(`${this.api}/ConceptBeneficiary`, beneficiaryModel)
  }

  deleteBeneficiary(conceptBeneficiaryId: number, userId: number = this._authInfo.getUserId()) {
    return this._http.post(`${this.api}/ConceptBeneficiary/delete`, { conceptBeneficiaryId, userId })
  }

}
