import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-employee-search-result',
  templateUrl: './employee-search-result.component.html',
  styleUrls: ['./employee-search-result.component.css']
})
export class EmployeeSearchResultComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EmployeeSearchResultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  Accept(item): void {
    const row = { Applied: true, item: item }
    this.dialogRef.close(row);
  }

  Cancel(): void {
    const row = { Applied: false, item: null }
    this.dialogRef.close(row);
  }

}
