<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Eliminar Código Certificación</h2>
    <button (click)="dialogRef.close()" type="button" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>

<div class="mat-dialog-content">
    <div class="card-body">
        <form [formGroup]="form">
            <div class="row">
                <div class="form-group col-md-12">
                    <label class="d-block">Escriba la razón por la cual desea eliminar el código certificación
                        <span class="text-danger text-monospace legend">*</span>
                    </label>
                    <textarea formControlName="reason" class="form-control form-control-lg dynamicFormControl" rows="6"
                        cols="50"></textarea>
                    <span class="form-text text-danger" *ngIf="formInvalid && form.get('reason')?.invalid">
                        Este campo es requerido
                    </span>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="dialogRef.close()">
        <i class="flaticon2-cross"></i>
        Cancelar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button" (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Confirmar</span>
    </button>
</div>