import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { VacanciesService } from 'src/app/services/vacancies.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Vacants } from '../../models/post-vacancy.model';
import { MatDialog } from '@angular/material/dialog';
import { VacanciesDashboardComponent } from '../vacancies-dashboard/vacancies-dashboard.component';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { PagedResult } from 'src/app/shared/models/page-result';
import { SearchBarDisplayNames, SearchParameter } from 'src/app/shared/element-ui/search-bar-pagination/models/SearchBarModels';
import { FilterEvent, SearchBarPaginationComponent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';
declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'app-vacancies-list',
  templateUrl: './vacancies-list.component.html',
  styleUrls: ['./vacancies-list.component.css']
})
export class VacanciesListComponent implements OnInit, OnDestroy, AfterViewChecked {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'HABVACANT',
    dashboardAction: { key: 'DASH', value: false},
    postAction: { key: 'PUBVAC', value: false},
    viewAction: { key: 'VIEW', value: false},
    exportAction: { key: 'EXPORT', value: false}
  };
  searchparameterlocalstorage: string = 'vacancies-list_filter_search';

  vacancies: Vacants[] = [];
  // tslint:disable-next-line:no-inferrable-types
  publishedVacancy: boolean = false;
  employeesPagedResult = new PagedResult<any>();
  selectedItems: any;
  reportUrl = environment.reportUrl

  // tslint:disable-next-line:semicolon
  searchParameter: FilterEvent
  statusOptions = [
    { item_id: 1, item_text: 'Activas' },
    { item_id: 2, item_text: 'Inactivas' },
    { item_id: 3, item_text: 'Publicada' },
    { item_id: 4, item_text: 'No publicadas' }
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  canUseOption = false
  subscriptions: Subscription = new Subscription();
  searchBarDisplayNames: SearchBarPaginationParameter[] = [
    { propkeyId: 1, displayName: 'Secuencia' },
    { propkeyId: 2, displayName: 'Sucursal' },
    { propkeyId: 3, displayName: 'Unidad Organizativa' },
    { propkeyId: 4, displayName: 'Puesto Vacante' },
  ];

  enclousureConfig = {
    displayKey: 'socialReason',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar',
  };


  @ViewChild('searchBar') searchBar: SearchBarPaginationComponent;
  constructor(
    public vacanciesService: VacanciesService,
    // tslint:disable-next-line:variable-name
    private _toastService: ToastService,
    // tslint:disable-next-line:variable-name
    private _exportService: ExportToExcelService,
    // tslint:disable-next-line:variable-name
    private _changeDet: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog,
    public authInfo: AuthInfoService
  ) {

   }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  ngOnInit(): void {

  // tslint:disable-next-line:align
  if (localStorage.getItem('vacancies-list_filter')) {
      this.selectedItems = JSON.parse(localStorage.getItem('vacancies-list_filter'));

    } else {
      this.selectedItems = [{ item_id: 4, item_text: 'No publicadas'}, { item_id: 1, item_text: 'Activas' }];

    }
    // tslint:disable-next-line:no-trailing-whitespace
    if (localStorage.getItem('vacancies-list_filter_search')){
      this.setFilterStorage();
    }
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result=>{
      if (result){
        this.canUseOption = true
        this.privilege = this.authInfo.setPrivileges(this.privilege);
        this.getVacantsAll();
      }
    });
    applySort();
  }


  // tslint:disable-next-line:typedef
  private setFilterStorage(){
    const localStorageSearch: FilterEvent = JSON.parse(localStorage.getItem(this.searchparameterlocalstorage));
    this.searchParameter = localStorageSearch;
  }

  onDeSelectAll(): void {
    this.selectedItems = [];

    this.filter();
    this.setFilterToLocalStorage();
  }
  // tslint:disable-next-line:typedef
  onItemDeSelect(item: any) {
        this.filter();
        this.setFilterToLocalStorage();
  }
  // tslint:disable-next-line:typedef
  onItemSelect(item: any) {

    this.filter();
    this.setFilterToLocalStorage();
  }
  // tslint:disable-next-line:typedef
  onSelectAll(item: any) {
    this.selectedItems = item;

    this.filter();
    this.setFilterToLocalStorage();
  }
  // tslint:disable-next-line:typedef
  filter() {

    this.getVacantsAll();
  }
  // tslint:disable-next-line:typedef
  deleteItem(item){
    const model = {
      vacantId: item,
      userId: this.authInfo.getUserId()
    };
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      title: '¿Seguro que desea inactivar esta vacante?'
    }).then((result) => {
      if(result.isConfirmed){
        Swal.showLoading();
        this.vacanciesService.deleteVacant(model).subscribe((res:any) => {
          if(res.succeded){
            this._toastService.success('Vacante inactivada');
            this.getVacantsAll()
          }else{
            this._toastService.warning(res.errors[0]);
          }
        }, err => {
          this._toastService.error('Ha ocurrido un error inesperado');
        });
      }
    });

  }
  // tslint:disable-next-line:typedef
  filterSearch(event: FilterEvent){
    this.employeesPagedResult.page = 1;
    this.searchParameter = event;
    this.getVacantsAll();

  }
  // tslint:disable-next-line:typedef
  getVacantsAll() {
    if(!this.canUseOption){
      return
    }
    const { page, pageSize } = this.employeesPagedResult;
    const text = this.searchParameter?.value;
    const propKeyId = !this.searchParameter?.value ? 0 : this.searchParameter?.propkeyId;

    const actives = this.selectedItems.find((x) => x.item_id === 1);
    const inactives = this.selectedItems.find((x) => x.item_id === 2);
    let status = 0;
    if ((!actives && !inactives) || (actives && inactives)) {
      status = -1;
    } else if (actives && !inactives) {
      status = 1;
    }

    const ableConditions = this.selectedItems
      ?.filter((x) => x.item_id !== 1 && x.item_id !== 2)
      ?.map((e) => e.item_id)
      ?.join('-');

    const params = {
      propKeyId,
      status,
      conditions: ableConditions,
      page,
      pageSize,
      type: 1,
      searchParameter: text,
    };
    // tslint:disable-next-line:prefer-const
    let sub = this.vacanciesService.getVacantsAllPaginated(params).subscribe((res: any) => {
      if (res.succeded) {
        this.vacancies = res.singleData.items;
        this.vacancies.forEach(element => {
          element.statusText = element.status ? 'Activo' : 'Inactiva'
          element.createdDateFormatted = moment(element.createdDate).format('DD/MM/YYYY')
          element.publicationDateFormatted = element.publicationDate ? moment(element.publicationDate).format('DD/MM/YYYY') : 'Aún sin publicar'
          element.publishedText = element.published ? 'Publicada' : 'No Publicada'
        });
        this.employeesPagedResult = res.singleData;
        this._changeDet.detectChanges();
      } else {
        this._toastService.error(res.errors[0]);
      }
    }, error => {
      this._toastService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    });
    this.subscriptions.add(sub);
  }



  getPaginatedRecords(event: PaginationEvent) {
    const { selectedPage, registersPerPage } = event;
    const { employeesPagedResult } = this;
    if (employeesPagedResult.page === selectedPage && employeesPagedResult.pageSize === registersPerPage) {
      return;
    }
    this.employeesPagedResult.page = selectedPage;
    this.employeesPagedResult.pageSize = registersPerPage;
    this.getVacantsAll();
  }
  // tslint:disable-next-line:typedef
  exportToExcell() {

    // tslint:disable-next-line:prefer-const
    let data = this.vacancies;

    // tslint:disable-next-line:prefer-const
    let excelHeaders: string[][] = [[
      'Secuencia',
      'Recinto',
      'Unidad Organizativa',
      'Puesto Vacante',
      'Fecha de Aprobación',
      'Fecha Publicación',
      'Condición Publicación',
      'Estatus'
    ], [
      'vacantId',
      'companyName',
      'department',
      'positionName',
      'createdDateFormatted',
      'publicationDateFormatted',
      'publishedText',
      'statusText',

    ]];
    this._exportService.exportToExcelSpecificColumns(data, excelHeaders, 'Lista de Vacantes', true);
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(VacanciesDashboardComponent, {
      width: '100%',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  // tslint:disable-next-line:typedef
setFilterToLocalStorage() {
    localStorage.setItem('vacancies-list_filter', JSON.stringify(this.selectedItems));

  }
  ngOnDestroy(): void {
    $('.tooltip.show').removeClass('show');
    this.subscriptions.unsubscribe();
  }

  openReport() {

    let url = ''
    const propKeyId = this.searchParameter?.propkeyId ?? 0
    const searchParameter = this.searchParameter?.value
    const actives = this.selectedItems.find((x) => x.item_id === 1);
    const inactives = this.selectedItems.find((x) => x.item_id === 2);
    let status = 0;
    if ((!actives && !inactives) || (actives && inactives)) {
      status = -1;
    } else if (actives && !inactives) {
      status = 1;
    }
    const ableConditions = this.selectedItems
    ?.filter((x) => x.item_id !== 1 && x.item_id !== 2)
    ?.map((e) => e.item_id)
    ?.join("-");
    const { page, pageSize } = this.employeesPagedResult

      url = `${this.reportUrl}/?ReportCode=Exp_PersonalRequisitionEnable&CompanyId=${this.authInfo.getCompanyId()}&PropKeyId=${propKeyId}&SearchParameter=${searchParameter}&status=${status}&conditions=${ableConditions}&page=${page}&pageSize=${pageSize}`

    let parameters = {
      url: url,
      title: 'Requisición / Habilitar Vacantes',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }

}
