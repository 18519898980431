import {Component, OnDestroy, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { ToastrService } from 'ngx-toastr';
import { IVacancy, PersonsWorkExperiences } from '../personnel-actions/models/Vacancy';
import { Subscription } from 'rxjs';
import { vacancyCalculations, VacationDaysAllowed } from '../personnel-actions/models/vacation-days-allowed.enum';
import { SearchParametersForEmployees } from '../personnel-actions/models/SearchParametersForEmployees.enum';
import { SearchParametersForEmployeesVacancies } from '../personnel-actions/models/SearchParametersForEmployeesVacancies.enum';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { docService } from 'src/app/services/doc.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { IEmployeeVacancyHistory } from '../personnel-actions/models/EmployeeVacancyHistory';
import { DepartmentService } from 'src/app/services/department.service';

@Component({
  selector: 'action-change-employee-vacations',
  templateUrl: './pdf-action-change-employee-vacations.component.html',
  styleUrls: ['./pdf-action-change-employee-vacations.component.css']
})

export class PdfActionChangeEmployeeVacationsComponent implements OnInit, OnDestroy {
  supervisorNames = {
    hhrr: '',
    rectory: '',
    employeeSupervisor: ''
  }
  onLoading:boolean = true;
  fileModalConfig = {
    src: '',
    type: '',
    filename: '',
    width: 225,
    height: 225,
    formTitle: '',
    formSubTitle: '',
  };
  historyWork: IEmployeeVacancyHistory[] = [];
  vacationData:IVacancy[];
  personSelected:any = {
    typeIdentificationId: 0
  };
  workHistoryData: PersonsWorkExperiences[] = [];
  employeeId: number;
  pdfOpt:any;
  photoUrl: string;
  subscriptions: Subscription = new Subscription();
  employeeVacationHistory:{vacationId: number, year:number, daysUsed:number, totalDaysAllowed:number,
    employeeVacationHistoryDates:[{startDate:Date, endDate:Date}?]}[] = [];
  currentVacationCounter;

  public company: any;
  file = { logo: '', image1: '', image2:'' }

  constructor(
    private _location:Location,
    private _activeRoute:ActivatedRoute,
    private _personnelActionsService: PersonnelActionsService,
    private _toastrService:ToastrService,
    private srvDoc: docService,
    private _companiesService: CompaniesService,
    private authInfo: AuthInfoService,
    private _departmentService: DepartmentService
  ) {    
    this.pdfOpt = {
      orientation: 'p',
      unit: 'in',
      format: [11, 8.5]
    };
  }
  ngOnInit(): void {
    this.getVacationTablesData(this._activeRoute.snapshot.params["vacancyId"]);
    this.companyById(this.authInfo.getCompanyId())
  }
  ngOnDestroy(): void {
 
  }

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this._toastrService.error(error)
      })
    } catch (error) {
      this._toastrService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this._toastrService.error(error)
      })
    } catch (error) {
      this._toastrService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this._toastrService.error(error)
      })
    } catch (error) {
      this._toastrService.error(error)
    }
  }
 
  getEmployeeById(employeeId:number){
    this._personnelActionsService.getEmployeeData(SearchParametersForEmployees.EmployeeId,employeeId).subscribe((res:any) => {
      if(res.errors.length > 0){
        this._toastrService.error("Ha ocurrido un error al obtener los datos del Servidor Público")
        return;
      }
      this.personSelected = res.dataList[0];
      this.employeeId = this.personSelected.employeeId;
      this.getHistoryWork();
      // this.getVacationTablesData(this._activeRoute.snapshot.params["vacancyId"]);
      const departmentId = this.personSelected.departmentId
      this.getDepartments(departmentId)
    }, error => {
      this._toastrService.error("Error al buscar el Servidor Público")
      return;
    });
    
  }

  // getEmployeeWorkingExperience(personId:number){
  //   let workExperienceSub = this._personnelActionsService.getPersonsWorkExperiences(personId).subscribe((res: any) => {
  //     this.workHistoryData = (res.dataList)? res.dataList: [];
  //     //console.log(res.dataList);

  //   }, (error) => {
  //     console.log(error);
  //     this._toastrService.error("Error al listar el historial laboral", "Error inesperado");
  //   })
  //   this.subscriptions.add(workExperienceSub)

  // }

  getVacationTablesData(vacancyId:number){
    let employeeVacanciesSub = this._personnelActionsService.getEmployeesVacancyData(SearchParametersForEmployeesVacancies.VacancyId, vacancyId).subscribe((res:ResponseModel<any>) => {
      if(res.dataList.length==0){
        this._toastrService.warning('No se encontraron registros');
        this.onLoading=false;
        return;
      }else{

        this.vacationData = (res.dataList != null && res.dataList.length>0)? res.dataList: [];
        
        this.employeeVacationHistory = [];
        
        let arrWithoutDuplicates = this.removeDuplicates(this.vacationData, 'year');
        
        
        arrWithoutDuplicates.filter(x=>x.status).forEach(vacation=>{
          this.employeeVacationHistory.push({
            vacationId: vacation.vacancyId,
            year:vacation.year,
            daysUsed: this.vacationData.filter(x=>x.year==vacation.year && x.status).reduce((a,b)=> a + b.days, 0) ?? 0,
            totalDaysAllowed: VacationDaysAllowed.Year,
            employeeVacationHistoryDates:[]
          });
          
          this.vacationData.filter(x=>x.year==vacation.year && x.status).forEach(vacation=>{
            let {dateStart, dateEnd} = vacation;
            this.employeeVacationHistory.find(x=>x.year==vacation.year).employeeVacationHistoryDates.push({
              startDate:dateStart,
              endDate:dateEnd
            })    
          });
        });
        
        this.getEmployeeById(this.vacationData[0].employeeId);
        
        this.currentVacationCounter = this.employeeVacationHistory[0];
      }

      this.onLoading=false;
        
      }, (error) => {
        this._toastrService.error("Error al listar las vacaciones", "Error inesperado");
      })
      this.subscriptions.add(employeeVacanciesSub)
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
}
  
  goBack(){
    this._location.back();
  }

  getHistoryWork(){
    this._personnelActionsService.getHistoryWork(this.personSelected.employeeId).subscribe((res:any) => {
      if(res.succeded){
        this.historyWork = res.dataList;
      }else{
        this._toastrService.error(res.errors[0])
      }
    }, error => {
      this._toastrService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  getHistoryVacancyDays():number{
    let years:number = 0;
    if(this.historyWork.length > 0){
      this.historyWork.forEach(x => {
        years += x.years;
      });
      let cal = vacancyCalculations.find(x => years >= x.yearsWorked[0] && years <= x.yearsWorked[1]);
      
      if(cal){
        return cal.days
      }
    }else{
      return 15;
    }
  }

  getVacationsCount(array){
    return array.reduce((a,b)=> +a + +b.days,0)
  }
  getDepartments(employeeSupervisorId){
    this._departmentService.getAll().subscribe((res:any) => {
      if(res.succeded){
        const departments = res.dataList
        const hhrrDepartment = departments.find(x => x.isHhrr == true)
        const employeeDepartment = departments.find(x => x.departmentId == employeeSupervisorId)
        const rectoryDepartment = departments.find(x => x.departmentId == 1000)
        this.supervisorNames.employeeSupervisor = this.getSupervisorName(employeeDepartment)
        this.supervisorNames.rectory = this.getSupervisorName(rectoryDepartment)
        this.supervisorNames.hhrr = this.getSupervisorName(hhrrDepartment)
      }else{
        this._toastrService.error(res.errors[0])        
      }
    }, err => {
      this._toastrService.error('Ha ocurrido un error inesperado')
    })
  }
  getSupervisorName(department):string {
    if(!department) return ''
    if(department?.supervisor == "No Definido" || !department?.supervisor){
      return ''
    }
    return department?.supervisor;
  }
  
}
