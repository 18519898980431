import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-personnel-actions',
  templateUrl: './modal-personnel-actions.component.html',
  styleUrls: ['./modal-personnel-actions.component.css']
})
export class ModalPersonnelActionsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalPersonnelActionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.dialogRef.disableClose = true;
    }

  ngOnInit(): void {
  }

  Accept(item): void {
    const row = { Applied: true, item: item }
    this.dialogRef.close(row);
  }

  Cancel(): void {
    const row = { Applied: false, item: null }
    this.dialogRef.close(row);
  }

}
