import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-item-expenses-detail',
  templateUrl: './item-expenses-detail.component.html',
  styleUrls: ['./item-expenses-detail.component.css']
})
export class ItemExpensesDetailComponent implements OnInit {


  form = new FormGroup({
    ccp: new FormControl(''),
    totalIngresos: new FormControl(''),
    totalDescuentos: new FormControl(''),
    netoPrimeraQuincena: new FormControl(''),
    netoSegundaQuincena: new FormControl(''),
    patron: new FormControl(''),
    riesgo: new FormControl(''),
    sfs: new FormControl(''),
    retiroComplementario: new FormControl(''),
  })

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ItemExpensesDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {partida: any}
  ) { 

    if(data?.partida){
      this.form.patchValue(data?.partida)
    }

  }

  ngOnInit(): void {
    this.form.disable()
  }


  close(){
    this.dialogRef.close()
  }

}
