import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PersonnelActionsDashboardService } from 'src/app/services/personnel-actions-dashboard.service';
import { IDashboardOptions } from 'src/app/services/vacancies-list-dashboard.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
import { BarOptions, ChartOptions } from 'src/app/shared/models/apexChartModels';
import { getPercentage } from 'src/app/shared/utils/utility';

@Component({
  selector: 'app-personnel-actions-dashboard',
  templateUrl: './personnel-actions-dashboard.component.html',
  styleUrls: ['./personnel-actions-dashboard.component.css']
})
export class PersonnelActionsDashboardComponent implements OnInit {

  loadingMessage = 'Cargando...'
  noDataMessage = 'No hay datos'
  public bar3years: Partial<BarOptions>;
  genderChart = {
    series:[],
    labels: [],
    message: this.loadingMessage
  }
  departmentChart = {
    series: [{data: []}],
    labels: [],
    xaxis: {
      categories: []
    },
    message: this.loadingMessage
  }
  contractTypeChart = {
    series:[],
    labels: [],
    message: this.loadingMessage
  }
  suscriptions: Subscription = new Subscription()
  public chartOptions: Partial<ChartOptions>;
  public barOptions: Partial<BarOptions>;
  public chartOptions2: Partial<ChartOptions>;
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  amountMale: {total: number, percentage: number} = {
    total: 0,
    percentage: 0
  };
  amountFemale: {total: number, percentage: number} = {
    total: 0,
    percentage: 0
  };
  amountTotal : number = 0;
  filtered = false
  filterParams = {
    year: null,
    date1: null,
    date2: null,
    companyId: this.authInfo.getCompanyId()
  }

  constructor(
      public dialogRef: MatDialogRef<PersonnelActionsDashboardComponent>,
      public dialog: MatDialog,
      private toastr: ToastrService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private srvDashboard: PersonnelActionsDashboardService,
      private datePipe: DatePipe,
      private authInfo: AuthInfoService
    ) {

      this.filterParams.date1 = this.datePipe.transform(`01-01-${new Date().getFullYear()}`, 'MM-dd-yyyy')
      this.filterParams.date2 = this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'MM-dd-yyyy')

    this.getChartOptions()

  }

  ngOnInit(): void {
    this.getChart()
  }
  getChart(){
    this.getTotal()
    this.getGenderChart()
    this.getContractTypeChart()
    this.getDepartmentChart()
    this.set3yearChart()
  }
  getTotal(){
    let options = this.getOptions()
    this.srvDashboard.getTotal(options).subscribe((res:any) => {
      if(res.succeded){
        this.amountTotal = res.singleData;
      }else{
        this.toastr.error(res.errors[0])
      }
    }, error => {
      this.toastr.error(this.errorMessage)
    })
  }
  getDepartmentChart(){
    this.departmentChart.series = [];
    this.departmentChart.labels = []
    this.departmentChart.message = this.loadingMessage
    let options = this.getOptions()
    let sub = this.srvDashboard.getTotalByDepartment(options).subscribe((res:any) => {
      if(res.succeded){
        if(res.dataList.length == 0){
          this.departmentChart.message = this.noDataMessage
        }
        res.dataList = res.dataList.filter(x => x.department != null)
        let data = []
        res.dataList.forEach((element) => {
          data.push(
            {
              x: element.department,
              y: element.countDepartment
            }
          )
        })
        this.departmentChart.series = [{
          data: data
        }]

      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error(this.errorMessage)
    })
    this.suscriptions.add(sub)
  }
  getGenderChart(){
    this.genderChart.message = this.loadingMessage
    let options = this.getOptions()
    this.srvDashboard.getTotalByGender(options).subscribe((res:any) => {
      if (res.succeded) {
        this.genderChart.series = []
        this.genderChart.labels = []
        if(res.dataList == null){
          this.genderChart.message = this.noDataMessage
          return;
        }
        if(res.dataList.length == 0){
          this.genderChart.message = this.noDataMessage
          return;
        }
        res.dataList = res.dataList.filter(x => x.gender != null)
        res.dataList.forEach((element) => {
          this.genderChart.series.push(element.countSex)
          this.genderChart.labels.push(element.gender)
        })
      }else{
        this.toastr.error(res.errors[0])
      }
    }, er => {
      this.toastr.error(this.errorMessage)
    })
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(FilterDashboardComponent, {
      data: {filterParams: this.filterParams},
    });
    let sub = dialogRef.afterClosed().subscribe((result: {year:Date,date1:Date,date2:Date, companyId}) => {

      this.filtered = result.year != null || result.date1 != null || result.date2 != null;

      this.filterParams.year = result.year
      this.filterParams.date1 = result.date1
      this.filterParams.date2 = result.date2
      this.filterParams.companyId = result.companyId
      this.getChart()
      //console.log(result);
    });
    this.suscriptions.add(sub)
  }
  clear(){
    this.filterParams = {
      date1: this.datePipe.transform(`01-01-${new Date().getFullYear()}`, 'MM-dd-yyyy'),
      date2: this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'MM-dd-yyyy'),
      year: null,
      companyId: this.authInfo.getCompanyId()
    }
    this.getChart()
  }

  getContractTypeChart(){
    this.contractTypeChart.series = [];
    this.contractTypeChart.labels = []
    let options = this.getOptions()
    this.contractTypeChart.message = this.loadingMessage
    let sub = this.srvDashboard.getTotalByContractType(options).subscribe((res:any) => {
      if(res.succeded){
        if(res.dataList.length == 0){
          this.contractTypeChart.message = this.noDataMessage
        }
        res.dataList = res.dataList.filter(x => x.contractType != null)
        res.dataList.forEach((element) => {

          this.contractTypeChart.series.push(element.countContractType)
          this.contractTypeChart.labels.push(element.contractType)
        })


      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error(this.errorMessage)
    })
    this.suscriptions.add(sub)
  }
  set3yearChart(){
    let sub = this.srvDashboard.getTotalIn3Years(this.filterParams.companyId).subscribe((res:any) => {
      if(res.succeded){
        let data = []
        if(res.dataList[0].admissionDate == 2021){
          data.push({
            x: 2020,
            y: 0
          })
        }
        res.dataList.forEach(e => {
          data.push({
            x: e.admissionDate,
            y: e.countYear
          })
        })
        this.bar3years.series = []
        this.bar3years.series = [{
          data: data
        }]
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error(this.errorMessage)
    })
    this.suscriptions.add(sub)
  }

  getPercentage(total: number, count: number){
    return getPercentage(total, count)
  }
  getOptions(): IDashboardOptions{
    return {
      dateFrom: this.filterParams.date1? this.filterParams.date1 : null,
      dateTo: this.filterParams.date2? this.filterParams.date2: null,
      companyId: this.filterParams.companyId
    }
  }
  getChartOptions(){
    this.chartOptions = {
      series: [],
      chart: {
        height: 400,
        type: "donut",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#ffffff', '#ffffff', '#ffffff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },

      stroke: {
        width: 0
      },
      title: {
        text: ""
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
          }
        }
      ]
    };
    this.chartOptions2 = {
      series: [5,45],
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
            fontSize: '14px',
            fontWeight: 'bold',
            colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      stroke: {
        width: 0
      },
      title: {
        text: ""
      },
      labels: ["Femenino", "Masculino"],
      fill: {
        colors: ["#f11565", "#0481af"]
      },
      colors: ["#f11565", "#0481af"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
          }
        }
      ]
    };


    this.barOptions = {
      series: [
        {
          name: "a",
          data: [60, 55, 57]
        }
      ],
      chart: {
        type: "bar",

      },

      plotOptions: {

        bar: {
          horizontal: false,
          columnWidth: "30%",
          distributed: true
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
        ]
      },
      yaxis: {
        title: {
          text: ""
        },
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        }
      }

    }
    this.bar3years = {
      series: [
        {
          name: "Cantidad de requisiciones",
          data: [60, 55, 57]
        }
      ],
      chart: {
        zoom: {
          enabled:false
        },
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr"
        ]
      },
      yaxis: {
        title: {
          text: ""
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        }
      }
    }
  }


}
