import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Employee } from '@payroll/payment-flyer/models/payroll-voucher.model';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import { Subscription } from 'rxjs';
import { Company } from 'src/app/models/company';
import { EmployeeRequests } from 'src/app/models/employee-request.model';
import { EmployeeServiceHistory } from 'src/app/models/Employee.model';
import { EmployeePermissions } from 'src/app/models/EmployeePermissions';
import { IPersonsWorkExperiencesGET } from 'src/app/models/personsWorkExperiences';
import { PermissionType } from 'src/app/models/service-request.model';
import { AccountService } from 'src/app/services/account.service';
import { serviceRequestService } from 'src/app/services/serviceRequest.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { personalFile } from 'src/app/shared/utils/parameterDoc';
import { environment } from 'src/environments/environment';
import { IVacancy } from '../../personnel-actions/models/Vacancy';
import { EmployeeServiceRequestModalComponent } from '../employee-service-request-detail/employee-service-request-modal.component';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { employeeServiceRequestDetailModalFormComponent } from '../employee-service-request-detail-modal-form/employee-service-request-detail-modal-form.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { FilterEvent } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { openReport } from 'src/app/shared/utils/utility';
declare function applySort(): any;

export enum personnelActionTypes {
  license = 1,
  change = 2,
  healthEvent = 3,
  permission = 4,
  admonition = 5,
  recognition = 6,
  normalPermission = 7,
  specialPermission = 8,
  externalService = 9,
  commission = 12
}

enum Sex {
  Femenino = 1,
  Masculino = 2
}

enum action {
  permissionAdd = 0,
  permissionView = 1,
  permissionEdit = 2,
  permissionAprove = 3,
  vacancyView = 4,
  vacancyAprove = 5,
  ExternalServiceAdd = 6,
  ExternalServiceView = 7,
  ExternalServiceEdit = 8,
  ExternalServiceAprove = 9,
}
type GUID = string & { isGuid: true };


@Component({
  selector: 'app-employee-reponse-request',
  templateUrl: './employee-reponse-request.component.html',
  styleUrls: ['./employee-reponse-request.component.css']
})
export class EmployeeResponseRequestComponent implements OnInit {
  public dataEmployee = {} as Employee;
  public LoggInDataEmployee = {} as Employee;
  public account = this.accountService.accountValue;
  private readonly urlDoc = environment.api_doc + '/Document';
  public personalFile = personalFile;
  public action = action.permissionView;
  public tittle = 'Ver Solicitud';
  @Input() defaultParameterValue: { propkey: string, displayName: string }
  public photoUrl: string = ''
  public uppyLoaded = false;
  public data: EmployeeRequests[] = [];
  public dataOrigin: EmployeeRequests[] = [];
  public departmentId: number = 0;
  public employeeId: number = 0;
  searchParameter: FilterEvent
  propkey:any
  page:any;
  pagesize:any
  employeesPagedResult = new PagedResult<any>();
  reportUrl = environment.reportUrl
  public reason
  public comment
  public applicationDate
  localStorageSearch: string = 'employee-service-request';
  canUseOption = false;

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'CERT-RITSS',
    exportAction: { key: 'EXPORT', value: false },
    viewAction: { key: 'VIEW', value: false },
    searchAction: { key: 'SEARCH', value: false },
    processAction: { key: 'PROCESS', value: false },
  }
  serviceTypeList: any[] = [
    { id: 1, description: 'Permisos' },
    { id: 2, description: 'Licencias' },
    { id: 3, description: 'Vacaciones' },
    /*     { id: 4, description: 'Carta de Trabajo' },
    { id: 5, description: 'Seguro Médico' },
    { _id: 6, description: 'Ver Historial de Salud' },
        { _id: 7, description: 'Ver Eva. Desempeño' },
        { _id: 8, description: 'Ver Hoja de Vida y Doc' }, */
  ];

  public serviceTypeconfig = {
    displayKey: 'description',
    placeholder: 'Seleccionar',
    search: false,
    searchPlaceholder: 'Buscar',
    searchOnKey: 'description'
  };

  public descriptionServiceConfig = {
    displayKey: 'name',
    placeholder: 'Seleccionar',
    search: false,
    searchPlaceholder: 'Buscar',
    searchOnKey: 'name'
  };

  public serviceDescriptionList: any[] = []
  public serviceType = { id: 1, description: 'Permisos' };
  public dataRequest = {
    serviceType: { id: 1, description: 'Permisos' } as PermissionType,
    serviceTypeDescription: null,
    applicationDate: Date.now(),
    dateStart: null,
    dateEnd: null,
    hourStart: null,
    hourEnd: null,
    motivo: '',
    recurrencia: false
  }

  permissionForm: FormGroup;
  ExternServiceForm: FormGroup;
  permissionFormInvalid: boolean = false;
  ExternServiceFormInvalid: boolean = false;
  checkPermissionRecurrent: boolean = false;
  vacationData: IVacancy[] = [];
  licenses = []
  employeePermissions: EmployeePermissions;
  subscriptions: Subscription = new Subscription();
  guiID = '';
  routeFile: string = "";
  disabledRecurrent: boolean = false
  disabledTypeRequest: boolean = false

  loadingPic: boolean = true
  filterText: string = '';
  records: EmployeeServiceHistory[] = []
  recordsOrigin: EmployeeServiceHistory[] = []
  payrollLawDiscounts: [] = [];
  form: FormGroup;
  id: undefined | number;

  invalidForm: boolean = false;
  selectedCompany: Company;
  selectedCompanyModal: Company = null;
  subscription: Subscription = new Subscription();
  evidenceUppy = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['application/pdf']
    },
    locale:Mexico
  });
  permissionHasHours: boolean = false
  hoursIsRequired = false;
  reasonToReject = ''
  reasonToRejectValidation = false;
  file = { document: '', documentLicense: '' }
  formIsInvalid = false
  checkIsSelected: boolean = undefined;

  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: {propkeyId:any, propkey?: string, displayName: string }[] =
    [
      {propkeyId:1, propkey: "sequence", displayName: "Secuencia" },
      {propkeyId:2, propkey: "name", displayName: "Servidor Público" },
      {propkeyId:3, propkey: "departmentName", displayName: "Unidad organizativa" },
      {propkeyId:4, propkey: "personalIdentification", displayName: "Documento de Identidad" },
      //{ propkey: "description", displayName: "Servicio Solicitado" },
    ];

  config = {
    displayKey: 'companyName',
    placeholder: 'Seleccionar',
    search: true,
    searchPlaceholder: 'Buscar',
    searchOnKey: 'companyName'
  };

  configParamenterControl = {
    displayKey: 'stringData',
    placeholder: 'Seleccionar',
    search: true,
    searchPlaceholder: 'Buscar',
    searchOnKey: 'stringData'
  };

  dropdownList = [
    { statusId: 1, stringData: 'Solicitada' },
    { statusId: 3, stringData: 'Terminada' },
  ];

  selectedItems = [{
    "statusId": 1,
    "stringData": "Solicitada"
  }];

  dropdownSettings = {
    singleSelection: false,
    idField: 'statusId',
    textField: 'stringData',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  yearList = [];

  /*VACACIONES */
  public vacationForm: FormGroup;
  public vacationFormView: FormGroup;
  noWorkingDays: { date: string, celebration: string; country: string, countryShortName: string; }[] = [];
  noWorkingDayMatchWithDates: { date: string, celebration: string; country: string, countryShortName: string; }[] = [];
  isEditingVacancy: boolean = false;
  vacancy: IVacancy;
  vacancyId: number = 0;
  vacancySelected: any;
  employeeVacationHistory: {
    vacationId: number, year: number, daysUsed: number, totalDaysAllowed: number,
    employeeVacationHistoryDates: [{ startDate: Date, endDate: Date }?]
  }[] = [];
  vacationFormInvalid: boolean = false;
  currentVacationCounter: any;
  historyWork: IPersonsWorkExperiencesGET[] = [];
  experienceYears: number = 0
  daysAvailables = 0
  vacationYears = [];
  recordsPaginated: any[] = [];
  dataList: any[] = [];
  departmentsListOriginal: any[] = [];
  searchDateFrom = null;
  searchDateTo = null;
  addressJobLetter: string = "";

  constructor(
    private srvServiceRequest: serviceRequestService,
    private toastr: ToastService,
    private accountService: AccountService,
    private route: Router,
    private dialog: MatDialog,
    private srvExportToExcel: ExportToExcelService,
    public authInfo: AuthInfoService,
  ) { }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.canUseOption = true
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.getRequest();
      }
    });
    applySort();
  }

  search() {
    this.getRequest(this.searchDateFrom, this.searchDateTo, true)
  }

  getRequest(dateFrom?: Date, dateTo?: Date, search: boolean = false) {

    this.srvServiceRequest.getAddressJobLetter(this.account.companyId).subscribe(e => {
      this.addressJobLetter = (e.singleData?.address || '')
    })

    this.srvServiceRequest.getRequestServices(this.employeeId, this.account.companyId, this.departmentId, true, dateFrom || null, dateTo || null).subscribe(e => {
      if (e.errors.length > 0) {
        this.toastr.error(e.errors[0])
        return
      }
      this.dataList = e.dataList.length > 0 ? e.dataList : [];
      this.departmentsListOriginal = [...this.dataList];

      if (search) {
        this.filter()
      } else {
        this.dataList = this.dataList.filter(e => e.conditionId == 1)
      }
    }, error => {
      this.toastr.error(error[0])
    })
  }

  filter() {
    this.dataList = this.filterTable(this.departmentsListOriginal)
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    let origin = list;
    let status = [];

    if (sl.length < 1) {
      return list;
    }

    for (let i = 0; i < this.selectedItems.length; i++) {
      const element = this.selectedItems[i];
      status.push(element.statusId)
    }

    if (status.length > 0) {
      list = origin.filter(x => status.includes(x.conditionId));
    } else {
      list = origin
    }

    return list;
  }

  /*  metodos para los filtros */
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }
  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }

  getPaginatedRecords(event) {
    this.recordsPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.page = event.selectedPage;
    this.pagesize = event.registersPerPage;
  }

  onKeyChange(value: string) {
    this.filterText = value;
  }

  onChange(event) {
    this.filteredList = event;
    this.propkey = event.propkey
  }

  close() {

  }

  showmodal(item, aproveed) {
    if (item.letterType == 2) {
      if (item.conditionId == 1) {
        this.dialog.open(employeeServiceRequestDetailModalFormComponent,
          {
            width: '30%',
          }).afterClosed().subscribe(req => {
            if (req) {
              this.displayModel(item, req.year, aproveed, req)
            }
          })
      } else {
        this.displayModel(item, item.year, aproveed)
      }
    } else {
      this.displayModel(item, item.year, aproveed)
    }
  }

  displayModel(item, year, aproveed, letterBody?) {
    this.dialog.open(EmployeeServiceRequestModalComponent,
      {
        data: {
          year: year,
          employeeId: item.employeeId,
          reason: item.reason,
          letterType: item.letterType,
          aproveed: aproveed,
          requestId: item.requestId,
          letterBody: letterBody
        },
        width: '50%',
      }).afterClosed().subscribe(req => {
        if (req && req.name) {
          req["RequestId"] = item.id
          req["ResponseUserId"] = Number(this.account.employeeId)
          req["ApplicationDate"] = item.applicationDate;

          this.srvServiceRequest.approveRequestServices(req).subscribe(res => {
            if (res.succeded == true) {
              this.toastr.success("Solicitud Terminada")
              this.getRequest();
            }
          })
        }
      })
  }

  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

  exportToExcell() {
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Servidor Público",
      "Documento Identidad",
      "Unidad Organizativa",
      "Servicio Solicitado",
      "Fecha de Solicitud",
      "Estatus"
    ]]
    let positionList: any[] = [];
    let array = this.dataList
    if (this.filteredList.enable) {
      array = this.filteredList?.records
    }
    array.forEach((e) => {
      positionList.push({
        sequence: e.sequence,
        name: e.name,
        personalIdentification: e.personalIdentification,
        departmentName: e.departmentName,
        description: e.description,
        applicationDate: e.applicationDate,
        status: e.status ? "Activa" : "Inactiva",
      })
    })
    this.srvExportToExcel.exportToExcelSpecificColumns(positionList, excelHeaders, 'Solicitudes de Servicios');
  }

  saveAddress() {
    let body = { Address: this.addressJobLetter, CompanyId: Number(this.account.companyId) };

    this.srvServiceRequest.postAddressJobLetter(body).subscribe(res => {
      if (res.succeded) {
        this.toastr.success("Dirección modificada exitosamente.")
      }
    }, error => {
      console.log(error);

    })
  }

  openReport() {
    let url = ''
    const propKeyId = this.propkey?.propkeyId ?? 0
    const searchParameter = this.filterText?? '';
    const actives = this.selectedItems.find((x: any) => x.item_id === 1);
    const inactives = this.selectedItems.find((x: any) => x.item_id === 2);
    let status = 0;
    if ((!actives && !inactives) || (actives && inactives)) {
      status = -1;
    } else if (actives && !inactives) {
      status = 1;
    }

    let condition:any = this.selectedItems.map(item => item.statusId).join('-');

    url = `${this.reportUrl}/?ReportCode=Exp_ListCertificationISRandTSS&EmployeeId=0&CompanyId=${this.authInfo.getCompanyId()}&PropkeyId=${propKeyId}&SearchParameter=${searchParameter}&Conditions=${condition}&Page=${this.page}&PageSize=${this.pagesize}`

    let parameters = {
      url: url,
      title: 'Certificaciones de Retención de Impuestos y TSS',
      width: 1024,
      height: 768
    }

    openReport(parameters)
  }
}
