<h2 class="mat-dialog-title" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">Agregar Beneficios</h2>
<div class="mat-dialog-content">
    <form [formGroup]="benefitsForm">
        <div class="row gutter-b">
            <div class="form-group col-lg-12">
                <label>Beneficio</label>
                <!-- <ngx-select-dropdown class=" flex-1" [multiple]="true" formControlName='benefit' [options]='benefits' [config]="config"></ngx-select-dropdown>
                 -->
                <ng-multiselect-dropdown
                    [placeholder]="'Seleccionar Estatus'"
                    [settings]="dropdownSettings"
                    [data]="benefits"
                    formControlName="benefit"
                    ></ng-multiselect-dropdown>
            </div>
        </div>
    </form>
</div>
<div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2"
        type="button" (click)='Cancel()'>
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-success" type="button" [disabled]='benefitsForm.invalid' (click)='Create()'>
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>