<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>A quien va dirigida</h2>
</div>
<div class="mat-dialog-content-custom" style="border-bottom: 0px solid white;">
    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-12 c-left">
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-12 px-0 mr-2">
                    <div class="form-group institution">
                        <label class="text-primary">
                            Año
                        </label>
                        <input type="number" class="form-control form-control-lg" [(ngModel)]="body.year"
                            maxlength="4" />
                    </div>
                </div>
            </div>
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-12 px-0 mr-2">
                    <div class="form-group institution">
                        <label class="text-primary">
                            Nombre Director DGII
                        </label>
                        <input type="text" class="form-control form-control-lg" [(ngModel)]="body.nameTo" />
                    </div>
                </div>
            </div>
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-12 px-0 mr-2">
                    <div class="form-group institution">
                        <label class="text-primary">
                            Cargo
                        </label>
                        <input type="text" class="form-control form-control-lg" [(ngModel)]="body.positionTo" />
                    </div>
                </div>
            </div>
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-12 px-0 mr-2">
                    <div class="form-group institution">
                        <label class="text-primary">
                            Institución
                        </label>
                        <input type="text" class="form-control form-control-lg" [(ngModel)]="body.institutionTo" />
                    </div>
                </div>
            </div>
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-12 px-0 mr-2">
                    <div class="form-group institution">
                        <label class="text-primary">
                            Dirección
                        </label>
                        <input type="text" class="form-control form-control-lg" [(ngModel)]="body.addressTo" />
                    </div>
                </div>
            </div>
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-12 px-0 mr-2">
                    <div class="form-group institution">
                        <label class="text-primary">
                            Dirigido a
                        </label>
                        <input type="text" class="form-control form-control-lg" [(ngModel)]="body.tittleTo" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="mat-dialog-actions justify-content-end" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="close()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>

    <button class="btn btn-pill font-weight-bolder btn-shadow btn-success" type="button" (click)="aprovee()">
        <i class="flaticon-disco-flexible"></i>
        <span>Aceptar</span>
    </button>
</div>