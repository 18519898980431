import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CandidateRegService } from 'src/app/services/http-client/candidateRegService.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { personsDependents } from 'src/app/shared/models/candidate-registration.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import Swal from 'sweetalert2';
import { AddDependentsComponent } from './add-dependents/add-dependents/add-dependents.component';

@Component({
  selector: 'app-family-data',
  templateUrl: './family-data.component.html',
  styleUrls: ['./family-data.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FamilyDataComponent implements OnInit {
  titlePage = "Dependientes"
  public relationshipsList = [];
  public maritalList = []
  public work = { yes: 1, no: 2 }
  public study = { yes: 1, no: 2 }
  public dependent = { yes: 1, no: 2 }
  public familyData: personsDependents[] = []
  public nameEdit = '';
  public index = -1;

  public data = {
    personId: 0,
    name: '',
    relationsShip: 0,
    maritaly: 0,
    birthday: null,
    works: 1,
    study: 1,
    dependent: 1,
    placeJob: null,
    placeStudy: null
  }

  showBirthdayError: boolean = false;

  public age: number = null;

  constructor(
    private authInfo: AuthInfoService,
    private cdr: ChangeDetectorRef,
    private srvCandidateService: CandidateRegService,
    private toast: ToastService,
    private srvPerson: PersonService,
    private _dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadDDL()
  }

  changeDetector() {
    this.cdr.detectChanges();
  }

  loadDDL() {
    this.srvCandidateService.getParameters(keyWord.RelationShip).subscribe(e => {
      if (e.succeded) {
        for (let i = 0; i < e.dataList.length; i++) {
          const item = e.dataList[i];
          this.relationshipsList.push({ id: item.ocode, description: item.stringData })
        }
        this.changeDetector();
      } else {
        this.toast.error(e.errors[0]);
      }

    }, error => {
    })

    this.srvCandidateService.getParameters(keyWord.MaritalStatus).subscribe(e => {
      if (e.succeded) {
        this.maritalList = e.dataList
        this.changeDetector();
      } else {
        this.toast.error(e.errors[0]);
      }
    }, error => {
    })
  }

  relationsShipChange($event) {

    if (this.data.relationsShip <= 0) {
      return;
    }

    let maritalId = 0;

    this.maritalList.forEach((element) => {
      if (element.stringData.toLowerCase() == 'casado/a') {
        maritalId = element.ocode;
      } else {
        this.data.maritaly = 0;
      }
    });

    this.relationshipsList.forEach((element) => {
      if (element.id == this.data.relationsShip) {
        if (element.description.toLowerCase() == 'esposo/a') {
          this.data.maritaly = maritalId;
        } else {
          this.data.maritaly = 0;
        }
      }
    });
  }

  validButton() {
    const validationWork = !this.data?.placeJob?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') || this.data?.name?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') === ''
      || this.data.birthday === null || this.data.birthday === '' || this.data.maritaly === 0
      || this.data.relationsShip === 0;

    const validationStudy = !this.data?.placeStudy?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') || this.data.name?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') === ''
      || this.data.birthday === null || this.data.birthday === '' || this.data.maritaly === 0
      || this.data.relationsShip === 0;

    const otherValidation = this.data.name?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') === ''
      || this.data.birthday === null || this.data.birthday === '' || this.data.maritaly === 0
      || this.data.relationsShip === 0;

    if (this.data.works === 1) {
      let isInvalid = validationWork;
      if (this.data.study === 1) {
        isInvalid = validationStudy;
      }
      if (!this.data?.placeJob?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')) {
        isInvalid = true;
      }
      return isInvalid;
    }

    if (this.data.study === 1) {
      let isInvalid = validationStudy;
      if (this.data.works === 1) {
        isInvalid = validationWork;
      }
      if (!this.data?.placeStudy?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')) {
        isInvalid = true;
      }
      return isInvalid;
    }

    if (this.data.works == 2 && this.data.placeJob) {
      return true
    }

    if (this.data.study == 2 && this.data.placeStudy) {
      return true
    }
    return otherValidation;
  }

  add(dependentInfo) {
    try {
      let dependent = {} as personsDependents;

      dependent.personId = (dependentInfo.personId || 0)
      dependent.fullName = dependentInfo.name;
      dependent.relationshipId = dependentInfo.relationsShip;
      dependent.maritalStatusId = dependentInfo.maritaly;
      dependent.birthday = dependentInfo.birthday;
      dependent.job = dependentInfo.job == 1
      dependent.study = dependentInfo.study == 1;
      dependent.dependent = dependentInfo.dependent == 1;
      dependent.photo = 0;
      dependent.companyId = this.authInfo.getCompanyId();
      dependent.status = true;
      dependent.placeJob = dependentInfo.placeJob;
      dependent.placeStudy = dependentInfo.placeStudy;

      this.updateAge();

      if (this.nameEdit == '') {
        dependent.dependentsId = 0;

        if (dependentInfo.relationsShip == 2) {
          if (this.motherExists()) {
            this.toast.warning('Ya agregó este parentesco')
            return
          }
        }
        if (dependentInfo.relationsShip == 1) {
          if (this.fatherExists()) {
            this.toast.warning('Ya agregó este parentesco')
            return
          }
        }
        this.familyData.push(dependent);
        this.closeModal()
        this.index = -1
      } else {

        if (this.index >= 0) {

          if (dependentInfo.relationsShip == 2 && this.familyData[this.index].relationshipId != 2) {
            if (this.motherExists()) {
              this.toast.warning('Ya agregó este parentesco')
              return
            }
          }
          if (dependentInfo.relationsShip == 1 && this.familyData[this.index].relationshipId != 1) {
            if (this.fatherExists()) {
              this.toast.warning('Ya agregó este parentesco')
              return
            }
          }
          const item = this.familyData[this.index]


          item.personId = (dependentInfo.personId || 0)
          item.fullName = dependentInfo.name;
          item.relationshipId = dependentInfo.relationsShip;
          item.maritalStatusId = dependentInfo.maritaly;
          item.birthday = dependentInfo.birthday;
          item.job = dependentInfo.works == 1
          item.study = dependentInfo.study == 1;
          item.dependent = dependentInfo.dependent == 1;
          item.photo = 0;
          item.companyId = this.authInfo.getCompanyId();
          item.status = true;
          item.placeJob = dependentInfo.placeJob;
          item.placeStudy = dependentInfo.placeStudy;
          this.index = -1
        }

        /* if (this.familyData[i].fullName == this.nameEdit) {
          dependent.dependentsId = this.familyData[i].dependentsId;
          this.familyData[i] = dependent
          break
        } */

      }

      this.data = {
        personId: 0,
        name: '',
        relationsShip: 0,
        maritaly: 0,
        birthday: null,
        works: 1,
        study: 1,
        dependent: 1,
        placeJob: null,
        placeStudy: null
      }
      this.nameEdit = ''
      this.changeDetector();
      document.getElementById('btnCerrar').click();
      this.resetShowBirthdayError();
    } catch (error) {
      this.toast.error(error)
    }
  }

  resetShowBirthdayError() {
    this.showBirthdayError = false;
  }

  motherExists() {
    const exists = this.familyData.find(x => x.relationshipId == 2)
    if (exists) {
      return true
    }
    return false
  }
  fatherExists() {
    const exists = this.familyData.find(x => x.relationshipId == 1)
    if (exists) {
      return true
    }
    return false
  }

  edit(dependentData, i) {
    this.index = i
    this.familyData[i].fullName =  dependentData.name
    this.familyData[i].relationshipId =  dependentData.relationsShip
    this.familyData[i].maritalStatusId =  dependentData.maritaly
    this.familyData[i].birthday =  dependentData.birthday
    this.familyData[i].placeJob =  dependentData.placeJob
    this.familyData[i].job =  dependentData.job
    this.familyData[i].study =  dependentData.study
    this.familyData[i].dependent =  dependentData.dependent
    this.familyData[i].placeStudy =  dependentData.placeStudy

    this.updateAge();
    this.changeDetector();
  }

  clear() {
    this.index = -1
    this.nameEdit = ''
    this.data = {
      personId: 0,
      name: '',
      relationsShip: 0,
      maritaly: 0,
      birthday: null,
      works: 1,
      study: 1,
      dependent: 1,
      placeJob: null,
      placeStudy: null
    }

    this.age = null;
  }

  getRelationshipDescription(id) {
    try {
      return this.relationshipsList.filter(e => e.id == id)[0].description
    } catch (error) {

    }
  }

  getMarital(id) {
    try {
      return this.maritalList.filter(e => e.ocode == id)[0].stringData
    } catch (error) {

    }
  }

  remove(i) {
    let id = this.familyData[i]?.dependentsId;
    this.index = -1;

    let model = {
      personId: id,
      userId: this.authInfo.getUserId(),
      operation: 2
    }

    this.swalQuestion(() => {
      if (id == 0 || !id) {
        this.familyData.splice(i, 1);
        this.changeDetector();
        return
      }
      this.srvPerson.deleteFamilyData(model).subscribe(e => {
        this.toast.success('Registro borrado')
        this.familyData.splice(i, 1);
        this.changeDetector();
      }, error => {
        this.toast.error(error)
      })
    })

  }

  getAge(model) {

    if (!model.birthday) {
      return;
    }

    let dateToday = new Date();
    let dateBirth = new Date(model.birthday);

    if (dateBirth > dateToday) {
      this.age = 0;
      return;
    }

    let time = dateToday.getTime() - dateBirth.getTime();

    //1000 = milliseconds
    //3600 = seconds
    //24 = hours
    let days = time / (1000 * 3600 * 24);


    let age = days / 365;

    age = parseInt(age.toString())

    return age;
  }

  updateAge() {

    if (!this.data.birthday) {
      return;
    }

    let dateToday = new Date();
    let dateBirth = new Date(this.data.birthday);

    if (dateBirth > dateToday) {
      this.age = 0;
      return;
    }

    let time = dateToday.getTime() - dateBirth.getTime();

    //1000 = milliseconds
    //3600 = seconds
    //24 = hours
    let days = time / (1000 * 3600 * 24);


    let age = days / 365;

    age = parseInt(age.toString())

    this.age = age;

  }


  swalQuestion(fn: () => void) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      title: "¿Está seguro que desea eliminar este registro?",
      icon: "question"
    }).then(res => {
      if (res.isConfirmed) {
        fn();
      }
    });
  }
  closeModal() {
    document.getElementById('btnCerrar').click();
    this.showBirthdayError = false;
  }

  get getCurrentDate() {
    return new Date()?.toLocaleDateString('fr-CA');
  }

  validateBirthdayInput() {
    let time1 = new Date(this.data?.birthday);
    let time2 = new Date();
    this.showBirthdayError = time1?.getTime() > time2?.getTime();
  }

  openAddDependentsModal(isEditing: boolean = false, dependentInfo: any = null, index?: number) {
    this.index = isEditing ? index : -1;
    this._dialog.open(AddDependentsComponent, {
      data: {
        dependentInfo,
        isEditing,
        relationshipsList: this.relationshipsList,
        maritalStatusList: this.maritalList
      },

    }).afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          const { depedentName, relationShip, maritalStatus, birthDay, age, isWorking, isStudying, isDependent, workPlace, studyingPlace } = res
          let dependentData = {
            personId: 0,
            name: depedentName,
            relationsShip: relationShip.id,
            maritaly: maritalStatus.ocode,
            birthday: birthDay,
            placeJob: workPlace,
            job: isWorking,
            study: isStudying,
            dependent: isDependent,
            placeStudy: studyingPlace,
          }

          if (!isEditing) {
            this.add(dependentData)
            return
          }
          this.edit(dependentData, index)
        }
      },
      error: (err: any) => {

      }
    })
  }

}
