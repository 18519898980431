import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { docService } from 'src/app/services/doc.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EmployeesService } from 'src/app/services/employees.service';


@Component({
  selector: 'app-pdf-employeee-export',
  templateUrl: './pdf-employeee-export.component.html',
  styleUrls: ['./pdf-employeee-export.component.css']
})
export class PdfEmployeeeExportComponent implements OnInit, OnDestroy {
  loadingPic: boolean = true;
  file = { logo: '', image1: '', image2: '' }
  photoUrl: string = '';
  company: any;

  filters: any;

  subscription: Subscription;

  showEstatus: string = 'Activos';

  employeeByDepartment: any[] = [];

  constructor(private location: Location, private srvDoc: docService,
    private _toastService: ToastService, private authInfo: AuthInfoService,
    private _companiesService: CompaniesService, private router: Router,
    private route: ActivatedRoute, private employeesService: EmployeesService,
    private authInfoService: AuthInfoService) {

  }

  ngOnInit(): void {
    this.subscription = this.route.queryParamMap
      .subscribe((params) => {
        const actives = params.get('actives') == 'true';
        const inactives = params.get('inactive') == 'true';

        this.filters = {
          actives: actives,
          inactives: inactives
        };
        if (this.filters.actives === true && this.filters.inactives === true) {
          this.showEstatus += ' y Desvinculados';
        } if (this.filters.actives === false && this.filters.inactives === true) {
          this.showEstatus = 'Desvinculados';
        }
        this.employeesService.getEmployeeDataExportPdf(this.authInfoService.getCompanyId()).subscribe((res: any) => {
          if (res.errors.length > 0) {
            this._toastService.error(res.errors[0]);
            return;
          }
          this.getEmployeeFilterByEstatus(res.dataList);
        }, error => {
          this._toastService.error('Ocurrió un error trantando de obtener los datos, por favor intente más tarde o comuníquese con HelpDesk.');
        });
      });
    this.getCompany();
  }

  getEmployeeFilterByEstatus(employees: any[]) {
    employees?.
      forEach(d => {
        if (this.filters.actives === true && this.filters.inactives === true) {
          this.employeeByDepartment.push({
            department: d?.department,
            employees: d?.employees?.sort((departmentA: any, departmentB: any) => {
              return departmentB?.salary - departmentA?.salary
            })
          })
        }
        if (this.filters.actives && !this.filters.inactives) {
          const filter = d?.employees.filter(e => e.status === true);
          this.employeeByDepartment.push({
            department: d?.department,
            employees: filter?.sort((departmentA: any, departmentB: any) => {
              return departmentB?.salary - departmentA?.salary
            })
          })
        }
        if (!this.filters.actives && this.filters.inactives) {
          const filter = d?.employees.filter(e => e.status === false);
          this.employeeByDepartment.push({
            department: d?.department,
            employees: filter?.sort((departmentA: any, departmentB: any) => {
              return departmentB?.salary - departmentA?.salary
            })
          })
        }
      });
      this.employeeByDepartment = this.employeeByDepartment?.sort((departmentGroupA: any, departmentGroupB: any) => {
        return departmentGroupA?.department[0]?.order - departmentGroupB?.department[0]?.order
      })
  }

  getCompany() {
    this._companiesService.getCompanyById(this.authInfo.getCompanyId()).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.company = res.dataList[0];
      this.setGuids(this.company);
      this.getProfileImageCompany(this.company?.logo);
    }, error => {
      this._toastService.error('Ocurrió un error trantando de obtener la campañia, por favor intente más tarde o comuníquese con HelpDesk.');
    });
  }

  goBack() {
    this.location.back();
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  getProfileImageCompany(profileImage) {
    this.srvDoc.getDocument(profileImage).subscribe(e => {
      this.photoUrl = e.data;
      this.loadingPic = false
    }, error => {
      this.loadingPic = false
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
