<div *ngIf="isTableLoading"
  class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
  <el-spinner animation="spinner-logo"></el-spinner>
  <h3 class="text-primary">Cargando PDF...</h3>
</div>
<div class="container-fluid py-3">
  <button  (click)="goBack()"  class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
      <i class="flaticon2-left-arrow-1"></i>
      <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
  </button>
</div>
<div class="container-fluid" *ngIf="!isTableLoading">
  <div id="pdfExp" class="card">
    <div class="card-header px-22">
      <div class="align-items-center d-flex flex-wrap justify-content-lg-between justify-content-md-center" id="header">
        <div class="logo px-2" >
          <img [src]="file.logo" alt="" class="max-w-200px" />
        </div>
        <div *ngIf="fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">
            {{fileModalConfig.formTitle}}
          </h1>
          <h2 class="text-danger">{{fileModalConfig.formSubTitle}}</h2>
        </div>
        <div *ngIf="!fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">
            Resultados de Prueba de Postulante
          </h1>
        </div>
        <div class="date p-4 d-flex flex-wrap justify-content-between align-items-center">
          <!-- <h4 class="text-right mr-3">Fecha de solicitud</h4>
          <ng-template [ngIf]="personalRequestView?.person">
            <h5 class="text-danger text-right">{{ personalRequestView?.person.dateRequestFormatted || '__-__-____' }}</h5>
          </ng-template> -->
          &nbsp;
        </div>
      </div>
    </div>
    <div class="card-body">
      <div id="pdf">
        <div class="pdf-page pb-4">
          <div class="container-fluid row mt-3" id="personal-data">
            <h3 class="col-md-12 p-2 bg-primary text-white mt-3 text-center mb-6">DATOS PERSONALES</h3>
            <div class="col row">
              <div class="photo col-md-3">
                <span *ngIf="loadingPic" class="d-flex flex-column align-items-center">
                  <img src="assets/media/logos/xmera-logo-3.png" alt="loading" width="70px" />
                  <strong class="text-danger font-size-h3">Cargando...</strong>
                </span>

                <ng-container *ngIf="!loadingPic">
                  <div class="symbol symbol-lg-200" *ngIf="!photoUrl; else second">
                    <i class="symbol-badge bg-success"></i>
                    <img src="assets/media/no-user.png" alt="image">
                  </div>
                  <ng-template #second>
                    <div class="symbol symbol-lg-200">
                      <i class="symbol-badge bg-success"></i>
                      <img [src]="photoUrl | safe" alt="image" (click)="showModal('photo')">
                    </div>
                  </ng-template>
                </ng-container>
              </div>
              <div class="pdf-list col-md-8">
                <div class="d-flex justify-content-start font-size-lg mb-3 pdf-items-2">
                    <h4 class="font-weight-bold mr-3">Nombre:</h4>
                    <span class="text-right">{{person?.personName || ''}}</span>
                </div>
                <div class="d-flex justify-content-start font-size-lg mb-3 pdf-items-2 ">
                    <h4 class="font-weight-bold mr-3">Identificación:</h4>
                    <span *ngIf="person?.typeIdentificationId == 1" class="text-right">{{person?.personalIdentification | mask: '000-0000000-0' || ''}}</span>
                    <span *ngIf="person?.typeIdentificationId != 1" class="text-right">{{person?.personalIdentification || ''}}</span>
                </div>
                <div class="d-flex justify-content-start font-size-lg mb-3 pdf-items-2">
                    <h4 class="font-weight-bold mr-3">Teléfono:</h4>
                    <span *ngIf="person?.phoneNumber?.length == 10" class="text-right">{{person?.phoneNumber | mask: '(000) 000-0000' || ''}}</span>
                    <span *ngIf="person?.phoneNumber?.length != 10" class="text-right">{{person?.phoneNumber || ''}}</span>
                    <span *ngIf="!person?.phoneNumber" class="text-right">{{'N/A'}}</span>
                </div>
                <div class="d-flex justify-content-start font-size-lg mb-3 pdf-items-2">
                    <h4 class="font-weight-bold mr-3">Correo Electrónico:</h4>
                    <span class="text-right">{{person?.email || 'N/A'}}</span>
                </div>
              </div>
            </div>

            <div class="col-md-5">
                <div class="d-flex justify-content-start font-size-lg mb-3 pdf-items-2">
                    <h4 class="font-weight-bold mr-3">Tipo de Prueba Aplicada:</h4>
                    <span class="text-right">{{test?.typeTest || ''}}</span>
                </div>
                <div class="d-flex justify-content-start font-size-lg mb-3 pdf-items-2">
                    <h4 class="font-weight-bold mr-3">Fecha de Aplicación:</h4>
                    <span class="text-right">{{test?.postulationDate |date:'dd MMMM yyyy':'UTC':'es-US' || ''}}</span>
                </div>
                <div class="d-flex justify-content-start font-size-lg mb-3 pdf-items-2">
                    <h4 class="font-weight-bold mr-3">Puntuación o Resultados:</h4>
                    <span class="text-right">{{test?.score || ''}}</span>
                </div>
            </div>
          </div>
          <div class="container-fluid row mt-3" id="contact-media">
            <h3 class="col-md-12 p-2 bg-secondary text-white text-center mt-3 d-block"
              style="background: #e3292e !important;">
              COMENTARIOS DEL EVALUADOR
            </h3>
            <div class="col-md-12 row m-0 p-0 overflow-auto" style="min-height: 200px;">
              {{test?.comment || ''}}
            </div>
          </div>
          <h3 class="col-md-12 mt-3 p-2 bg-primary text-white text-center text-center">
            HISTORIAL DE PRUEBAS APLICADAS
          </h3>
          <div class="container-fluid row mt-3" id="family-info">
            <div *ngFor="let item of testList" class="col-md-12 row" style="min-height: 50px;">
                <div class="col">
                    <div class="d-flex justify-content-start font-size-lg mb-3 pdf-items-2">
                        <h4 class="font-weight-bold mr-3">Secuencia:</h4>
                        <span class="text-right">{{item.postulationTestId}}</span>
                    </div>
                </div>
                <div class="col">
                    <div class="d-flex justify-content-start font-size-lg mb-3 pdf-items-2">
                        <h4 class="font-weight-bold mr-3">Prueba Aplicada:</h4>
                        <span class="text-right">{{item.typeTest}}</span>
                    </div>
                </div>
                <div class="col">
                    <div class="d-flex justify-content-start font-size-lg mb-3 pdf-items-2">
                        <h4 class="font-weight-bold mr-3">Fecha Aplicada:</h4>
                        <span class="text-right">{{item.postulationDate |date:'dd MMMM yyyy':'UTC':'es-US'}}</span>
                    </div>
                </div>
                <div class="col">
                    <div class="d-flex justify-content-start font-size-lg mb-3 pdf-items-2">
                        <h4 class="font-weight-bold mr-3">Puntuación o Resultados:</h4>
                        <span class="text-right">{{item.score}}</span>
                    </div>
                </div>
            </div>
          </div>



        </div>
      </div>
    </div>
    <div class="card-footer mt-4">
      <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
        <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-80px">
        <div class="firma text-center">
          <span>_________________________________________</span>
          <p class="font-weight-bold text-dark">
            Firma del(de la) interesado(a)
          </p>
        </div>
       
              <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
      </div>
    </div>
  </div>
</div>
<button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
  <i class="flaticon2-printer font-size-h1"></i>
</button>
<script>
  let print = () => {
    let pdf = document.getElementById("pdfExp");
    pdf.contentWindow.focus();
    pdf.contentWindow.print();
  };
</script>
<el-modal target="logoModal" size="md" [title]="'Vista de foto'">
  <el-embed *ngIf="fileModalConfig.src" [src]="fileModalConfig.src" [type]="fileModalConfig.type"
    [filename]="fileModalConfig.filename" [width]="fileModalConfig.width" [height]="fileModalConfig.height">
  </el-embed>
</el-modal>
<el-modal target="photo" size="md" [title]="'Vista de foto'">
  <div class="container justify-content-center" style="align-content: center;">
    <ng-template [ngIf]="photoUrl">
      <img [src]="photoUrl | safe" alt="image" style="max-width: 350px;">
    </ng-template>
  </div>
</el-modal>
