import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ContactMeansDepartmentService } from 'src/app/services/contactMeans-department.service';
import { DepartmentService } from 'src/app/services/department.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { Departments } from '../../deparments/models/departments.model';
import { DirectoryModalComponent } from '../directory-modal/directory-modal/directory-modal.component';
import { ContactMeansDepartment } from './models/contactMeans-department.model';

@Component({
  selector: 'app-department-tab',
  templateUrl: './department-tab.component.html',
  styleUrls: ['./department-tab.component.css']
})
export class DepartmentTabComponent implements OnInit {
  selectedItems
  departments: Departments[] = [];
  departmentsPaginated: Departments[] = [];
  departmentsListOriginal: Departments[] = [];
  filteredList: { records: Departments[], enable: boolean } = { records: [], enable: false };
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'TELEPHONE-DIRECTORY',
    editAction: 'Edit',
    exportAction: 'Export',
    searchAction: 'Search'
  }
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =[
    { propkey: "departmentName", displayName: "Unidad organizativa" },
    { propkey: "supervisor", displayName: "Encargado" },
  ];
  @ViewChild('paginator') paginator: CaptionComponent;
  constructor(
    private departmentService: DepartmentService,
    public authInfo: AuthInfoService,
    private cdRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private exlService: ExportToExcelService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.getData();
      }
    });
  }

  getData(){
    this.departmentService.getAllApproved(this.authInfo.getCompanyId()).subscribe({
      next:(res:any) => {
        if (res.succeded) {
          this.departments = res.dataList.length > 0 ? res.dataList : [];
          this.departmentsListOriginal = res.dataList.length > 0 ? res.dataList : [];
        } else {
          this.toastr.error(res.errors[0]);
        }
      }, error: (error:any) => {this.toastr.error('Ha ocurrido un error inesperado consultando los medios de contactos de las Unidades Organizativas')}
    })
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
  }

  openDirectoryModal(data: Departments = null, add: boolean = true, view: boolean = false, edit: boolean = false){
    this.dialog.open(DirectoryModalComponent,{
      width:'50%',
      data: { department: data, add: add, view: view, edit: edit }
    }).afterClosed().subscribe({
      next: (result: { success: boolean }) => {
        if (result?.success) {
          this.getData();
        }
      }
    })
  }

  exportToExcel() {
    let data = this.filteredList.enable ? this.filteredList.records : this.departments
    if(data.length < 1) return

    let excelHeaders: string[][] = [[
      "Unidad Organizativa",
      "Encargado",
    ], [
      "departmentName",
      "supervisor",
    ]]
    this.exlService.exportToExcelSpecificColumns(data, excelHeaders, 'Directorio Telefónico - Unidades Organizativas', true);
  }

  getPaginatedRecords(event) {
    this.departmentsPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  
  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Click para desplegar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }


}
