import { IEstadoArchivoNominaView, IPartidaGastosView, IPayrollSubmissionView, MonitoreoSolicitud, PayrollFileUpload, PayrollVerifyStatus } from './../pages/payroll-new/process-payroll/models/payrollExpense';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPayrollExpenseDeparturePOST } from '@payroll/process-payroll/models/payrollExpense';
import {   IDetallePartidaGastoDTO, IPayrollCatalogView, ISendPayrollFilePOST, IViewPartidasHacienda, SendPayrollFileAdditional } from '@payroll/process-payroll/models/SendPayrollFile';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { IEmployeeTypeView } from '../models/Employee.model';
import { IHeader } from '@payroll/payroll-models';

@Injectable({
  providedIn: 'root'
})
export class ProcessPayrollService {
  private readonly api = environment.api_url
  private readonly api_lobby = environment.api_core_sigei
  constructor(
    private http: HttpClient,
    private authInfo: AuthInfoService,
  ) { }
  
  
  getPeriods(){
    return this.http.get(this.api + `/Period?PayrollPeriodId=0&Type=0&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getPeriodsWithPayrollDefinitiveAndApprove(companyId){
    return this.http.get(`${this.api}/Period?PayrollPeriodId=0&Type=9&CompanyId=${companyId}&Status=true`)
  }
  getHeadersByPeriod(periodId){
    return this.http.get<ResponseModel<IHeader>>(this.api + `/Header?HeaderId=0&PayrollPeriodId=${periodId}&PayrollTypeId=0&type=1&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getHeaders(periodId){
    return this.http.get(this.api + `/Header?type=4&PayrollPeriodId=${periodId}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  postPayrollExpense(data: IPayrollExpenseDeparturePOST){
    return this.http.post(`${this.api}/PayrollPartidaGastos`, data)
  }

  getPayrollExpense(){
    return this.http.get(`${this.api}/PayrollPartidaGastos`)
  }


  getHeadersApproved(){
    const companyId = this.authInfo.getCompanyId()
    return this.http.get(`${this.api}/header?type=7&companyId=${companyId}`)
  }

  getPayrollCatalogs(year){
    const companyId = this.authInfo.getCompanyId()
    return this.http.get<ResponseModel<IPayrollCatalogView>>(`${this.api_lobby}/Companies/payroll-catalogs?companyId=${companyId}&currentYear=${year}`)
  }

  getPayrollExpenseFilter(employeeType, headerId, employeeStatus = true){
    const companyId = this.authInfo.getCompanyId()
    return this.http.get<ResponseModel<IPartidaGastosView>>(`${this.api}/PayrollPartidaGastos?companyId=${companyId}&employeeTypeId=${employeeType}&headerId=${headerId}&EmployeeStatus=${employeeStatus}`)
  }
  getEmployeeTypes(headerId){
    const companyId = this.authInfo.getCompanyId()
    return this.http.get<ResponseModel<IEmployeeTypeView>>(`${this.api}/EmployeeType?EmployeeTypeId=0&HeaderId=${headerId}&Type=2&CompanyId=${companyId}&Status=true`)
  }


  send(body: ISendPayrollFilePOST){
    return this.http.post(`${this.api}/Payroll/SendPayrollFile`, body)
  }

  sendPayrollFileAdditional(payrollFileAdditional: SendPayrollFileAdditional){
    return this.http.post(`${this.api}/Payroll/SendPayrollFileAdditional`, payrollFileAdditional);
  }

  getPayrollFiles(headerId){
    return this.http.get<ResponseModel<IPayrollSubmissionView>>(`${this.api}/Payroll/files?PayrollHeaderId=${headerId}`)
  }
  getVerifyStatusFile(params: PayrollVerifyStatus){
    const {companyId, code, employeeTypeId, newsConceptId, payrollTypeId, submissionId, headerId, createUserId} = params
    return this.http.get<ResponseModel<IEstadoArchivoNominaView>>(`${this.api}/Payroll/VerifyStatus?companyId=${companyId}&codeNomina=${code}&employeeTypeId=${employeeTypeId}&newsConceptId=${newsConceptId}&payrollTypeId=${payrollTypeId}&payrollHeaderId=${headerId}&SubmissionId=${submissionId}&createdUserId=${createUserId}`)
  }

  getVerifyStatusMonitoring(responseCode: string){
    return this.http.get<ResponseModel<MonitoreoSolicitud>>(`${this.api}/Payroll/VerifyStatusMonitoring?companyId=${this.authInfo.getCompanyId()}&codeNomina=${responseCode}`);
  }

  getPartidasGastoPayroll(headerId: number,employeeTypeId: number, companyId: number, employeeStatus = true ){
    return this.http.get<ResponseModel<IViewPartidasHacienda>>(`${this.api}/UploadPartidasGasto/GetPartidasGastoPayroll?HeaderId=${headerId}&EmployeeTypeId=${employeeTypeId}&type=0&CompanyId=${companyId}&Status=true&EmployeeStatus=${employeeStatus} `);
  }

  getPayrollAditionalExpenseFilter(NewsconceptId , headerId, employeeStatus = true){
    const companyId = this.authInfo.getCompanyId()
    return this.http.get<ResponseModel<IPartidaGastosView>>(`${this.api}/PayrollPartidaGastos?companyId=${companyId}&employeeTypeId=${NewsconceptId}&headerId=${headerId}&Type=1&employeeStatus=${employeeStatus}`);
  }

  getPartidasGastoPayrollAditional(headerId: number, newsConceptId: number, companyId: number, employeeStatus = true ){
    return this.http.get<ResponseModel<IViewPartidasHacienda>>(`${this.api}/UploadPartidasGasto/GetPartidasGastoPayrollAditional?HeaderId=${headerId}&NewsConceptId=${newsConceptId}&type=0&CompanyId=${companyId}&Status=true&EmployeeStatus=${employeeStatus}`);
  }

  getPreventiveDocuments(documento: string, unidaEjecutora: string, capitulo: string, subCapitulo: string, companyId: number, userId: number){
    return this.http.get<ResponseModel<any>>(`${this.api_lobby}/Companies/PreventiveDocuments?documento=${documento}&unidaEjecutora=${unidaEjecutora}&capitulo=${capitulo}&subCapitulo=${subCapitulo}&CompanyId=${companyId}&UserId=${userId}&Type=0`); 
  }

  concludePayroll(model){
    return this.http.put(`${this.api}/Header/Definitive`, model)
  }
}
