<div *ngIf="isLoading else loaded"
    class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
    <el-spinner (loadingStatus)="isLoading=$event" animation="spinner-logo">
    </el-spinner>
</div>
<ng-template #loaded>
    <div class="card card-custom">
        <div class="card-header mt-3">
            <div class="card-title">
                <div class="ttl d-flex align-items-center">
                    <i class="flaticon-datos icon-3x text-danger mr-2"></i>
                    <h1 class="text-primary"> Áreas Presupuestales </h1>
                </div>
            </div>
        </div>
        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-7 c-left">
                <div class="card-toolbar d-flex justify-content-start">

                    <div class="col-md-4 mr-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Estatus
                            </label>
                            <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                                [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                                (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                        </div>
                    </div>
                    <button class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto"
                        *ngIf="privilege.addAction.value"
                        (click)="manageBudgetArea(null, 'create')">
                        <i class="flaticon-mas icon-2x" data-toggle="tooltip"
                            data-original-title="Agregar nueva"></i>
                        Agregar
                    </button>
                </div>
            </div>

            <div class="col-sm-12 col-md-5 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            *ngIf="privilege.exportAction.value"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <!-- <a class="dropdown-item align-items-center" (click)="exportToExcel()">
                                <i class="flaticon-excel icon-2x text-success mr-3"></i>
                                Excel
                            </a>
                            <a class="dropdown-item align-items-center" (click)="onBtnPrintClick()">
                                <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                                PDF
                            </a> -->
                            <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                              <i class="fa fa-print icon-2x text-primary mr-3"></i>
                              Visualizar Reporte
                            </a>
                            <!-- <a class="dropdown-item disabled" href="#">
                                <i class="flaticon-word icon-2x text-primary mr-3"></i>
                                Word
                            </a>
                            <a class="dropdown-item disabled" href="#">
                                <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                                CSV
                            </a> -->
                        </div>
                    </div>

                    <div class="col text-nowrap mt-2">
                        <el-search-bar #searchBar [records]="originalBudgetAreaList" (onKeyChange)="onKeyChange($event)"
                            [keyValue]="filterText" [itemForSearching]="searchBarDisplayNames"
                            (onChange)="getFilteredList($event)"></el-search-bar>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="table-responsive table-responsive-sigei">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-center">Secuencia</th>
                            <th class="text-left">Código</th>
                            <th class="text-left">Descripción</th>
                            <th class="text-left">Unidad Ejecutora</th>
                            <th>Fecha de Creación</th>
                            <th class="text-left">Estatus</th>
                            <th class="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let budgetArea of paginatedBudgetAreaList">
                            <td class="text-center">{{budgetArea.budgetAreaId}}</td>
                            <td class="text-left">{{budgetArea.code}}</td>
                            <td class="text-left">{{budgetArea.description}}</td>
                            <td class="text-left">{{budgetArea.executingUnitDescriptionConcat}}</td>
                            <td>{{budgetArea.createDate |date:'dd/MM/YYY'}}</td>
                            <td>
                                <span
                                    [ngClass]="{'font-weight-bold text-success': budgetArea.status, 'font-weight-bold text-danger': !budgetArea.status}">
                                    {{budgetArea.status ? "Activa" : "Inactiva"}}
                                </span>
                            </td>
                            <td class="actionsBtn">
                                <button *ngIf="privilege.view.value" (click)="manageBudgetArea(budgetArea, 'view')"
                                    class="btn btn-icon btn-light-info btn-sm m-1" data-toggle="tooltip"
                                    title="Ver Servidor Público">
                                    <i class="flaticon-ver"></i>
                                </button>
                               
                                <button (click)="manageBudgetArea(budgetArea, 'edit')"
                                    *ngIf="budgetArea.status && privilege.editAction.value"
                                    class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip"
                                    title="Editar">
                                    <i class="flaticon-edit"></i>
                                </button>
                                <button 
                                    (click)="deleteBudgetArea(budgetArea.budgetAreaId)" data-toggle="tooltip"
                                    *ngIf="privilege.deleteAction.value && budgetArea.status"
                                    title="" class="btn btn-sm btn-light-danger btn-icon" data-original-title="Inactivar">
                                    <i class="flaticon-delete"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>

                    <el-caption #paginator [originalRecordsLength]="areasList.length"
                        [listedRecordsLength]="paginatedBudgetAreaList.length"
                        [registerQuantity]="filteredList.enable && filteredList.records?.length>0 ? filteredList.records?.length :areasList?.length"
                        [records]="filteredList.enable ? filteredList.records : areasList"
                        (onChage)="getPaginatedRecords($event)"></el-caption>

                </div>
            </div>
        </div>
    </div>
</ng-template>

<export-data-to-pdf [title]="'Áreas Presupuestales'" id="dataToPrint">
    <ng-container thead>
        <th class="text-left">Código</th>
        <th class="text-left">Descripción</th>
        <th class="text-center">Fecha de creacion</th>
        <th class="text-left">Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let budgetArea of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.areasList"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td class="text-left">{{budgetArea.code}}</td>
            <td class="text-left">{{budgetArea.description}}</td>
            <td class="text-center">{{budgetArea.createDate |date:'dd/MM/YYY'}}</td>
            <td>
                <span
                    [ngClass]="{'font-weight-bold text-success': budgetArea.status, 'font-weight-bold text-danger': !budgetArea.status}">
                    {{budgetArea.status ? "Activa" : "Inactiva"}}
                </span>
            </td>
        </tr>
    </ng-container>
</export-data-to-pdf>
