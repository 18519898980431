import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EmployeeMedicalProfile } from '../models/employee-medical-profile';
import { EmployeesIncomplete, IDocumentFileView, IEmployee, ITeacherContractPost } from '../models/Employee.model';
import { AcademicLevelFilter } from '../shared/models/academicLevelFilter.model';
import { EmployeeCondition, IPersonalActionView } from '../shared/models/employee-condition.model';
import { PagedResult } from '../shared/models/page-result';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { AuthInfoService } from './auth-info.service';
import { RequestBankAccountCertificationBulkLoad } from '../models/requestBankAccountCertBulkLoad.model';
import { EndContract } from '../models/end-contract.model';
import { ITeacherReviewEvidenceView, ITeacherReviewPost, ITeacherReviewView } from '../models/teacher.model';
import { ChangeSupervisor } from '../models/change-supervisor.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {
  api = environment.api_url
  api_core = environment.api_core_url;

  constructor(private _http: HttpClient, private authInfo: AuthInfoService) { }

  getEmployees() {
    return this._http.get(`${this.api}/Employees?Type=1&CompanyId=${this.authInfo.getCompanyId()}`);
  }
  getEmployeesPaginated(branchId: number, beforePeriodId?: number, page?: number, pageSize?: number, PropkeyId = null, SearchParameter = null, status = -1, type: number = 1, additionalParameters: string = null) {
    return this._http.get<ResponseModel<PagedResult<IEmployee>>>(`${this.api}/Employees/GetPaginatedFilter?Status=${status}&CompanyId=${this.authInfo.getCompanyId()}&BranchId=${branchId}&BeforePeriodId=${beforePeriodId}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Page=${page}&PageSize=${pageSize}&Type=${type}&AdditionalParameters=${additionalParameters}`);
  }
  getEmployeesPaginatedAdvanced(branchId: number, beforePeriodId?: number, page?: number, pageSize?: number, PropkeyId = null, SearchParameter = null, status = -1, type: number = 1, additionalParameters: string = null, advanced = '', budgetAreaId = 0) {
    return this._http.get<ResponseModel<PagedResult<IEmployee>>>(`${this.api}/Employees/GetPaginatedFilter?Status=${status}&CompanyId=${this.authInfo.getCompanyId()}&BranchId=${branchId}&BeforePeriodId=${beforePeriodId}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Page=${page}&PageSize=${pageSize}&Type=${type}&AdditionalParameters=${additionalParameters}&AdvancedFilter=${advanced}&budgetAreaId=${budgetAreaId}`);
  }
  getEmployeesPaginatedAdvancedBirthday(page?: number, pageSize?: number, PropkeyId = null, SearchParameter = null, status = -1, type: number = 1, additionalParameters: string = null, advanced = '', month = 0, HasImage = 0) {
    return this._http.get<ResponseModel<PagedResult<IEmployee>>>(`${this.api}/Employees/GetPaginatedFilterBirthday?CompanyId=${this.authInfo.getCompanyId()}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&BudgetAreaId=${month}&Status=1&Page=${page}&PageSize=${pageSize}&Type=${type}&AdvancedFilter=${advanced}&HasImage=${HasImage}`);
  }
  postEmployeesBirthday(data) {
    return this._http.post<ResponseModel<PagedResult<IEmployee>>>(`${this.api}/Employees/SendMailBirthday`, data);
  }

  getDoctors() {
    return this._http.get(`${this.api}/Employees?Type=20&CompanyId=${this.authInfo.getCompanyId()}`);
  }
  getEmployeeByEmployeeId(employeeId) {
    return this._http.get<ResponseModel<IEmployee>>(`${this.api}/Employees?employeeNumber=${employeeId}&Type=5&CompanyId=${this.authInfo.getCompanyId()}`);
  }

  getEmployeeInterview() {
    return this._http.get(`${this.api}/Employees?Type=15&CompanyId=${this.authInfo.getCompanyId()}`);
  }

  getIncompleteEmployees() {
    return this._http.get(`${this.api}/Employees/GetEmployeesIncomplete?CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getEmployeesIncompletePaginated(conditions: string = null, page?: number, pageSize?: number, PropkeyId = null, SearchParameter = null) {
    return this._http.get<ResponseModel<PagedResult<EmployeesIncomplete>>>(`${this.api}/Employees/GetEmployeesIncompletePaginated?Status=true&CompanyId=${this.authInfo.getCompanyId()}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&conditions=${conditions}&Page=${page}&PageSize=${pageSize}&Type=1`);
  }

  getEmployeeIncompleteCount() {
    return this._http.get<ResponseModel<{ quantity: number }>>(`${this.api}/Employees/GetEmployeeIncompleteCount?CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getTeacherIncompletePaginated(conditions: string = null, page?: number, pageSize?: number, PropkeyId = null, SearchParameter = null) {
    return this._http.get<ResponseModel<PagedResult<EmployeesIncomplete>>>(`${this.api}/Employees/GetTeachersIncompletePaginated?Status=true&CompanyId=${this.authInfo.getCompanyId()}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&conditions=${conditions}&Page=${page}&PageSize=${pageSize}&Type=1`);
  }

  getTeacherIncompleteCount() {
    return this._http.get<ResponseModel<{ quantity: number }>>(`${this.api}/Employees/GetTeachersIncompleteCount?CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getIncompleteTeachers(companyId: number) {
    return this._http.get(`${this.api}/Employees/GetTeachersIncomplete?CompanyId=${companyId}&Status=true`)
  }

  getIncompletedFilesByEmployee(employeeId: number, type: number = 0) {
    return this._http.get(`${this.api}/Employees/GetEmployeesIncompleteByEmployee?type=${type}&CompanyId=${this.authInfo.getCompanyId()}&employeeId=${employeeId}`)
  }

  getEmployeePersonalActions() {
    return this._http.get(`${this.api}/EmployeePermissions/GetEmployeeActions?EmployeeId=0&Type=2&Status=true&CompanyId=${this.authInfo.getCompanyId()}`)
  }

  getEmployeePersonalActionsByDate(startDate, endDate) {
    return this._http.get(`${this.api}/EmployeePermissions/GetEmployeeActions?EmployeeId=0&Type=3&Status=true&StartDate=${startDate}&EndDate=${endDate}&CompanyId=${this.authInfo.getCompanyId()}`)
  }

  getEmployeeActionsPaginated(params: { propKey, SearchParameter, Page, PageSize, StartDate, EndDate, type, conditions, additionalParameters, advancedFilter, companyId }) {

    let dateString = ``
    if (params.StartDate) {
      dateString += `&StartDate=${params.StartDate}`
    }
    if (params.EndDate) {
      dateString += `&EndDate=${params.EndDate}`

    }
    return this._http.get<ResponseModel<PagedResult<IPersonalActionView>>>(`${this.api}/EmployeePermissions/GetEmployeeActionsPaginated?Conditions=${params.conditions}${dateString}&Type=${params.type}&PropkeyId=${params.propKey}&SearchParameter=${params.SearchParameter}&Page=${params.Page}&PageSize=${params.PageSize}&CompanyId=${params.companyId}&AdditionalParameters=${params.additionalParameters}&AdvancedFilter=${params.advancedFilter}`)
  }

  getEmployee(status: boolean, type: number = 1, employeeId: number = 0) {
    return this._http.get<ResponseModel<any>>(this.api + `/employees?status=${status}&employeeNumber=${employeeId}&type=${type}&companyId=${this.authInfo.getCompanyId()}`)
  }

  activeEmployeeInstitution(personIds: number[], status: boolean) {
    return this._http.post<ResponseModel<any>>(this.api + `/employees/ActiveEmployeeInstitution?status=${status}&companyId=${this.authInfo.getCompanyId()}`, personIds);
  }

  getFiredEmployeeByPositions(departmenId: number, positionId: number) {
    return this._http.get<ResponseModel<any>>(`${this.api}/employees?Type=4&DepartmentId=${departmenId}&PositionId=${positionId}`)
  }

  getEmployeesByPositions(departmenId: number, positionId: number) {
    return this._http.get(`${this.api}/employees?Type=9&DepartmentId=${departmenId}&PositionId=${positionId}&CompanyId=${this.authInfo.getCompanyId()}`);
  }

  getEmployeesByIdentification(typeIdentificationId: number, identificacion: number) {
    return this._http.get(`${this.api}/employees/GetEmployeeByIdentification?TypeIdentificationId=${typeIdentificationId}&PersonalIdentification=${identificacion}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  UpdateEmployee(value) {
    return this._http.put(this.api + "/employees", value)
  }


  getStatistics(
    companyId: number,
    dateFrom: string,
    dateTo: string,
    status: boolean = true,
  ) {
    return this._http.get<any>(`${this.api}/Employees/Dashboard?CompanyId=${companyId}&DateFrom=${dateFrom}&DateTo=${dateTo}&Status=${status}`);
  }

  getDashboardAcademicLevelFilter(academicLevelFilter: AcademicLevelFilter[]) {
    return this._http.post(`${this.api}/Employees/DashboardMulti`, academicLevelFilter);
  }

  getDeparments() {
    return this._http.get(`${this.api_core}/companyStructure/GetCompanyStructure?CompanyId=${this.authInfo.getCompanyId()}&type=2`)
  }

  getDashboardEmployeepersonnelActions(year, dateFrom, dateTo, companyId: number) {
    return this._http.get(`${this.api}/ActionsEmployees/DashboardActions?CompanyId=${companyId}&DateFrom=${dateFrom}&DateTo=${dateTo}&Status=true`);
  }

  getEmployeeDataExportPdf(CompanyId: number) {
    return this._http.get(`${this.api}/Employees/GetEmployeeActive?CompanyId=${CompanyId}`);
  }

  getEmployeeActions(CompanyId: number = this.authInfo.getCompanyId()) {
    return this._http.get(`${this.api}/EmployeePermissions/GetVacationAndLicenseList?Status=true&CompanyId=${CompanyId}`);
  }

  getEmployeeActionsByEmployeeId(EmployeeId: number, CompanyId: number = this.authInfo.getCompanyId()) {
    return this._http.get<ResponseModel<EmployeeCondition>>(`${this.api}/EmployeePermissions/GetVacationAndLicenseList?Employee=${EmployeeId}&Type=6&Status=true&CompanyId=${CompanyId}`);
  }

  createMedicalProfile(medicalProfile: EmployeeMedicalProfile) {
    return this._http.post(`${this.api}/EmployeeProfile`, medicalProfile);
  }

  getMedicalProfile(type: number = 3, companyId: number = this.authInfo.getCompanyId()) {
    return this._http.get(`${this.api}/EmployeeProfile?type=${type}&companyId=${companyId}`);
  }

  getMedicalProfileById(employeeProfileId: number, type: number = 2, companyId: number = this.authInfo.getCompanyId()) {
    return this._http.get(`${this.api}/EmployeeProfile?EmployeeProfileId=${employeeProfileId}&type=${type}&companyId=${companyId}`);
  }

  deleteEmployeeDisability(disabilityId: number) {
    return this._http.delete(`${this.api}/EmployeeDisabilities?employeeDisabilitiesId=${disabilityId}&userId=${this.authInfo.getUserId()}`)
  }

  getTeachers(companyId: number) {
    return this._http.get<ResponseModel<IEmployee>>(`${this.api}/Employees/GetTeacherPerHour?type=25&EmployeeId=0&CompanyId=${companyId}`);
  }
  getTeachersPaginated(options) {
    let params = new HttpParams()
    params = params.append('BranchId', options.branchId)
    params = params.append('CompanyId', this.authInfo.getCompanyId().toString())
    params = params.append('PropkeyId', options.propkeyId)
    params = params.append('SearchParameter', options.searchParameter)
    params = params.append('Status', options.status)
    params = params.append('Page', options.page)
    params = params.append('PageSize', options.pageSize)
    params = params.append('Type', options.type.toString())
    return this._http.get(`${this.api}/Employees/GetTeacherPerHourPaginated`, { params })
  }

  insertTeacherContract(model: ITeacherContractPost) {
    return this._http.post<ResponseModel<any>>(`${this.api}/TeacherContract`, model);
  }
  updateTeacherContract(model: ITeacherContractPost) {
    return this._http.put<ResponseModel<any>>(`${this.api}/TeacherContract`, model);
  }

  getDocumentFiles(companyId: number) {
    return this._http.get<ResponseModel<IDocumentFileView>>(`${this.api_core}/DocumentFile?DocumentFileId=0&Type=0&CompanyId=${companyId}&Status=true`);
  }

  getTeacherContracts(employeeId: number) {
    return this._http.get<ResponseModel<any>>(`${this.api}/TeacherContract?TeacherContractId=0&EmployeeId=${employeeId}&Type=1&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }

  getTeacherDocuments(TeacherContractId: number, companyId: number) {
    return this._http.get<ResponseModel<any>>(`${this.api}/TeacherDocuments?TeacherDocumentId=0&TeacherContractId=${TeacherContractId}&DocumentFileId=0&Type=1&CompanyId=${companyId}&Status=true`);
  }
  getEmployeeTypes() {
    return this._http.get(`${this.api}/EmployeeType?&CompanyId=${this.authInfo.getCompanyId()}`);
  }

  getHourlyTeacherDashboard(dateFrom = null, dateTo = null, companyId: number, status: boolean = true) {
    let endpoint = `${this.api}/Employees/DashboardTeachers?Status=true`
    if (companyId) {
      endpoint += `&CompanyId=${companyId}`
    }
    if (dateFrom) {
      endpoint += `&DateFrom=${dateFrom}`
    }
    if (dateTo) {
      endpoint += `&DateTo=${dateTo}`
    }
    return this._http.get(endpoint)
  }

  requestBankAccountCertBulkLoad(requestBankAccountCertification: RequestBankAccountCertificationBulkLoad) {
    return this._http.post<ResponseModel<any>>(`${this.api}/Employees/RequestBankAccountCertBulkLoad`, requestBankAccountCertification);
  }

  endTeacherContract(endContractModel: EndContract) {
    return this._http.post<ResponseModel<any>>(`${this.api}/TeacherContract/AnnulTeacherContract`, endContractModel);
  }

  contractAdd(employeeId: any) {
    return this._http.get<ResponseModel<any>>(`${this.api}/TeacherContract/ContractAdd?TeacherContractId=${employeeId}`);
  }

  advancedFilter(parameters) {
    let params: HttpParams = new HttpParams()
    params = params.append('CompanyId', parameters.companyId)
    params = params.append('BranchId', parameters.branchId)
    params = params.append('PropkeyId', parameters.propkeyId)
    params = params.append('SearchParameter', parameters.searchParameter)
    params = params.append('BudgetAreaId', parameters.budgetAreaId)
    params = params.append('BeforePeriodId', parameters.beforePeriodId)
    params = params.append('Status', parameters.status)
    params = params.append('Page', parameters.page)
    params = params.append('PageSize', parameters.pageSize)
    params = params.append('Type', parameters.type)
    params = params.append('AdditionalParameters', parameters.additionalParameters)
    params = params.append('AdvancedFilter', parameters.advancedFilter)
    return this._http.get(`${this.api}/Employees/GetPaginatedFilter`, { params })
  }

  getTeacherReviews( type?: number, reviewId?: number, employeeId?: number, companyId?: number, status?: boolean, userId?: number){
    return this._http.get<ResponseModel<ITeacherReviewView>>(`${this.api}/TeacherReview/Reviews`, {
      params: <any>{
        reviewId, employeeId, companyId, status, userId, type
      }
    })
  }

  getTeacherReviewEvidences( type?: number, evidenceId?:number, reviewId?: number, employeeId?: number, companyId?: number, status?: boolean, userId?: number){
    return this._http.get<ResponseModel<ITeacherReviewEvidenceView>>(`${this.api}/TeacherReview/Evidences`, {
      params: <any>{
        evidenceId, reviewId, employeeId, companyId, status, userId, type
      }
    })
  }

  insertTeacherReview(model: ITeacherReviewPost){
    return this._http.post<ResponseModel<null>>(`${this.api}/TeacherReview`, model)
  }

  updateTeacherReview(model: ITeacherReviewPost){
    return this._http.put<ResponseModel<null>>(`${this.api}/TeacherReview`, model)
  }

  deleteTeacherReviews(reviewId: number, userId: number){
    return this._http.delete<ResponseModel<null>>(`${this.api}/TeacherReview`, {
      params: <any>{
        reviewId, userId
      }
    })
  }

  deleteTeacherEvidence(evidenceId: number, userId: number){
    return this._http.delete<ResponseModel<null>>(`${this.api}/TeacherReview/DeleteEvidence`, {
      params: <any>{
        evidenceId, userId
      }
    })
  }

  changeSupervisor(model:ChangeSupervisor):Observable<ResponseModel<null>>{
    return this._http.post<ResponseModel<null>>(`${this.api}/Employees/ChangeSupervisor`, model);
  }

  getAllEmployeesPaginatedHistory(employeeId,companyId, branchId, typeEmployeeId, status, propKeyId, searchParameter, page, pageSize, advancedFilter?){
    return this._http.get( `${this.api}/Employees/PaginatedHistory?employeeId=${employeeId}&searchParameter=${searchParameter}&propKeyId=${propKeyId}&page=${page}&pageSize=${pageSize}&CompanyId=${companyId}&Status=${status}&AdvancedFilter=${advancedFilter}&branchId=${branchId}&type=${typeEmployeeId}`);
  }
}
