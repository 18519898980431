<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-datos icon-3x text-danger mr-2"></i>
                <h1 class="text-primary"> Enlaces </h1>
            </div>
        </div>
    </div>
    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
            <div class="card-toolbar d-flex justify-content-start">

                <div class="col-md-4 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Estatus
                        </label>
                        <ng-multiselect-dropdown (onDeSelectAll)="onDeSelectAll()" (onDeSelect)="onItemDeSelect($event)"
                            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                            [placeholder]="'Seleccionar'" [settings]="dropdownSettings" [data]="statusOptions"
                            [(ngModel)]="selectedItems"></ng-multiselect-dropdown>
                    </div>
                </div>
                <button class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto"
                    *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.addAction)"
                    (click)="openModal(null, 'add', 'Agregar')">
                    <i class="flaticon-mas icon-2x" data-toggle="tooltip" data-original-title="Agregar Enlace"></i>
                    Agregar
                </button>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.exportAction)"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                            <i class="fa fa-print icon-2x text-primary mr-3"></i>
                            Visualizar Reporte
                        </a>
                        <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>

                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a> -->
                    </div>
                </div>

                <div class="col text-nowrap mt-2">
                    <el-search-bar #searchBar [records]="dataListOriginal" (onKeyChange)="onKeyChange($event)"
                        [keyValue]="filterText" [itemForSearching]="searchBarDisplayNames"
                        (onChange)="getFilteredList($event)"></el-search-bar>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-vertical-center caption-top">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-left">Secuencia</th>
                        <th class="text-left">Nombre</th>
                        <th class="text-left">Enlace</th>
                        <th class="text-left">Tipo</th>
                        <th class="text-left">Estatus</th>
                        <th class="text-center">Acciones</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let item of dataPaginated">
                        <td class="text-left">{{item.socialNetworkId}}</td>
                        <td class="text-left">{{item.name}}</td>
                        <td class="text-left">{{item.link}}</td>
                        <td class="text-left">{{item.type}}</td>
                        <td class="text-left">
                            <span
                                [ngClass]="item.status ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{item.status?
                                "Activa":
                                "Inactiva"}}</span>
                        </td>
                        <td class="actionsBtn">
                            <button *ngIf="item.status && authInfo.canDoAction(privilege.nameKeyOption, privilege.edit)"
                                class="btn btn-icon btn-light-warning btn-sm mr-1" data-toggle="tooltip"
                                (click)="openModal(item, 'edit', 'Modificar')" title="Editar">
                                <i class="flaticon-edit"></i>
                            </button>

                            <button data-toggle="tooltip"
                                *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.view)"
                                (click)="openModal(item, 'details', 'Detalles')" title=""
                                class="btn btn-sm btn-light-info btn-icon mr-1">
                                <i class="flaticon-ver" data-toggle="tooltip" data-original-title="Ver"></i>
                            </button>

                            <button (click)="deleteSocial(item)"
                                *ngIf="item.status && authInfo.canDoAction(privilege.nameKeyOption, privilege.inactivate)"
                                data-toggle="tooltip" title="" class="btn btn-sm btn-light-danger btn-icon mr-1">
                                <i class="flaticon-delete" data-toggle="tooltip" data-original-title="Inactvar"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>

                <el-caption #paginator [originalRecordsLength]="dataList?.length"
                    [listedRecordsLength]="dataPaginated?.length"
                    [registerQuantity]="filteredList.enable && filteredList.records?.length>0 ? filteredList.records?.length :dataList?.length"
                    [records]="filteredList.enable ? filteredList.records : dataList"
                    (onChage)="getPaginatedRecords($event)"></el-caption>

            </div>
        </div>
    </div>
</div>

<export-data-to-pdf [title]="'Enlaces'" id="dataToPrint">
    <ng-container thead>
        <th class="text-left">Secuencia</th>
        <th class="text-left">Nombre</th>
        <th class="text-left">Enlace</th>
        <th class="text-left">Tipo</th>
        <th class="text-left">Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let item of dataPaginated">
            <td class="text-left">{{item.socialNetworkId}}</td>
            <td class="text-left">{{item.name}}</td>
            <td class="text-left">{{item.link}}</td>
            <td class="text-left">{{item.type}}</td>
            <td class="text-left">
                <span
                    [ngClass]="item.status ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{item.status?
                    "Activa":
                    "Inactiva"}}
                </span>
            </td>
        </tr>
    </ng-container>
</export-data-to-pdf>