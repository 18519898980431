import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'knowledge',
  templateUrl: './knowledge.component.html',
  styleUrls: ['./knowledge.component.css']
})
export class KnowledgeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  Create() {
    
  }

  Cancel(): void {
    
  }

}
