
<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Comprobante de pago
    </h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content">
    <div class="container-fluid">
        <div id="pdfExp" class="card">
            <div class="card-body">
                <div class="page-v-auto mx-auto">
                    <header style="padding-bottom: 10px; padding-top: 20px;">
                        <div class="report-content">
                            <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div style="position: relative; text-align: center; width: 100%;">
                                        <img src="/assets/images/logo-presidencia.png" alt="logo" style="width: 100%;" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <h2 class="text-center mt-0 mb-0 title">
                                        Gobierno de la República Dominicana
                                    </h2>
                                    <h3 class="text-center mt-0 mb-2 title2">
                                        Sistema Integrado de Gestión Institucional (SIGEI)
                                    </h3>
                                    <h3 class="text-center mt-0 mb-2 title">
                                        Comprobante de Pago {{data.selectedPeriod?.description}}
                                    </h3>
                                    <h4 class="text-center mt-0 mb-2">
                                        Tipo de Nómina: {{data.model.payrollType}}
                                    </h4>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div style="position: relative; text-align: center; width: 100%;">
                                        <img [src]="file.logo" alt="logo" style="width: 100%;" />
                                        <p class="text-small-report mt-0 mb-1">
                                            <!-- <strong>Pág.</strong>: 1<br /> -->
                                            <strong>Impreso</strong>: {{today | date: 'dd/MMM/yyyy h:mm a'}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
                <div class="page-v-auto mx-auto">
                    <div class="report-content">
                        <div class="card-report">
                            <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <img [src]="profilePhotoUrl ? profilePhotoUrl : 'assets/images/no-user.png'" alt="foto" style="width: 90%; margin: 0 auto;" onerror="this.onerror=null; this.src='assets/images/no-user.png'" />
                                </div>
                                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <h2 class="text-left mt-0 mb-0 title">
                                                {{payrollVoucherData.employee?.firstName}} {{payrollVoucherData.employee?.lastName}}
                                            </h2>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="mb-3">
                                                <strong>Área Presupuestaria:</strong><br />
                                                {{selectedFlyer?.budgetArea}}
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                            <div class="mb-3">
                                                <strong>Documento de Identidad:</strong><br />
                                                {{payrollVoucherData.employee?.personalIdentification|mask:"000-0000000-0"}}
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                            <div class="mb-3">
                                                <strong>Fecha designación:</strong><br />
                                                {{payrollVoucherData.employee?.admissionDate | date: "dd/MM/yyyy"}}
                                            </div>
                                            <!--
                                            <div class="mb-3">
                                                <strong>Servidor Público:</strong><br />
                                                OMAR GUARIONEX MENDEZ LLUBERES
                                            </div>
                                            -->
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="mb-3">
                                                <strong>Unidad Organizativa:</strong><br />
                                                {{payrollVoucherData.employee?.departmentName}}
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                            <div class="mb-3">
                                                <strong>Cargo:</strong><br />
                                                {{payrollVoucherData.employee?.positionName}}
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="table-report-sigei" cellspacing="0" cellpadding="0" width="100%">
                            <thead>
                                <tr>
                                    <th>Conceptos</th>
                                    <th class="text-right">Ingresos</th>
                                    <th class="text-right">Descuento</th>
                                    <th class="text-right">Sueldo Neto</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="payrollVoucherData">
                                <tr  *ngFor="let concept of paymentDetails">
                                    <td class="">
                                        <strong>{{concept?.code}}</strong>
                                        {{concept?.description}}
                                    </td>
                                    <td class="text-right">{{(concept.isDebit == false ? concept.amount: '') | currency}}</td>
                                    <td class="text-right">{{(concept.isDebit == true ? concept.amount: '') | currency}}</td>
                                    <td class=""></td>
                                </tr>
                                <!-- <tr class="" *ngFor="let newApplied of newsApplied">
                                    <td class="">
                                        <strong>{{newApplied?.code}}</strong>
                                        {{newApplied.newsConcept}}
                                    </td>
                                    <td class="text-right">
                                      {{ newApplied.isDebit == false ? (newApplied.amount | currency) : '' }}
                                    </td>
                                    <td class="text-right">{{newApplied.isDebit == true ? (newApplied.amount | currency) : ''}}</td>
                                    <td class=""></td>
                                </tr> -->
                                <tr>
                                    <td class="text-left">
                                        <strong>TOTAL POR SERVIDOR</strong>
                                    </td>
                                    <td class="text-right">
                                        <strong>{{selectedFlyer.grossSalary + selectedFlyer.otherCredits | currency}}</strong>
                                    </td>
                                    <td class="text-right">
                                        <strong>{{selectedFlyer.totalDiscount  | currency}}</strong>
                                    </td>
                                    <td class="text-right">
                                        <strong>{{selectedFlyer.netIncome | currency}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ng-container *ngIf="compensations?.length > 0">
                            <br>
                            <h3 class="text-left mt-0 mb-2 title">
                                Saldo a favor
                            </h3>
                            <table class="table-report-sigei" cellspacing="0" cellpadding="0" width="100%">
                                <thead>
                                    <tr>
                                        <th class="text-left">Conceptos</th>
                                        <th class="text-right">Monto</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="payrollVoucherData">
                                    <tr  *ngFor="let concept of compensations">
                                        <td class="">
                                            <strong>{{concept?.code}}</strong>
                                            {{concept?.description}}
                                        </td>
                                        <td class="text-right">{{(concept.amount > 0 ? concept.amount: 0) | currency}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
        <i class="flaticon2-printer font-size-h1"></i>
    </button>
    <script>
        let print = () => {
            let pdf = document.getElementById("pdfExp");
            pdf.contentWindow.focus();
            pdf.contentWindow.print();
        };
    </script>
</div>
<div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2 "
    (click)="close()" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>
