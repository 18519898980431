<h2 class="mat-dialog-title">Editar dependiente</h2>
<div class="mat-dialog-content">
    
    <div class="row">
        <div class="form-group col-lg-5">
            <label>Tipo Servidor Público<span class="text-danger">*</span> </label>
            <input type="text" class="form-control form-control-lg" />
        </div> 
    </div>
    <div class="row">
        <div class="col-lg-5 border-u p-0">
            <h3 class="p-3 d-flex bg-primary text-white">Transacciones</h3>
            <ul class="no-style">
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 1</li>
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 2</li>
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 3</li>
            </ul>            
        </div>
        <div class="col-lg-2 center flex-column">
            <button class="btn btn-light-primary btn-icon gutter-b">
                <i class="flaticon2-fast-back"></i>
            </button>
            <button class="btn btn-light-primary btn-icon">
                <i class="flaticon2-fast-next"></i>
            </button>            
        </div>
        <div class="col-lg-5 border-u p-0">
            <h3 class="p-3 d-flex bg-primary text-white">Transacciones por Tipo Servidor Público</h3>
            <ul class="no-style">
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 1</li>
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 2</li>
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 3</li>
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 4</li>
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 5</li>
            </ul>            
        </div>
        
    </div>
    <div class="row gutter-t">
        <div class="d-flex justify-content-end">
          <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3" data-dismiss="modal">
            <i class="flaticon2-cross"></i>   
            Cerrar
          </button>       
          <button class="btn btn-pill font-weight-bolder btn-shadow btn-success">
              <i class="flaticon-disco-flexible"></i>
              Guardar
          </button>          
        </div>
      </div>
</div>