<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>
        Servidores Públicos de la Nómina</h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">
    <div class="card card-custom">
        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-7 c-left">
                <div class="card-toolbar d-flex justify-content-start">
                    <div class="col-md-4 mr-2 px-0 pt-1">
                        <div class="form-group  institution" *ngIf="canMakeChanges">
                            <button (click)="excludeEmployees()"
                            [disabled]="sendItems?.length === 0"
                            class="btn btn-danger btn-pill mt-5 mx-0 col-md-auto">
                                    <i class="flaticon-usuarios icon-2x" ></i>
                                   Excluir Selección
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-5 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="col text-nowrap">
                        <el-search-bar  [records]="payrollDetailsOriginal"  (onChange)="getFilteredList($event)"
                        [itemForSearching]="searchBarDisplayNames" ></el-search-bar>
                    </div>
                </div>
            </div>
        </div>
</div>

<div class="card-body mb-4" style="padding: 0rem 2.25rem ;">
    <ng-container *ngIf="canMakeChanges">
        <input type="checkbox" (change)="selectAllItems()"  
        [(ngModel)]="allItemsSelected"  class="check-size me-3 ml-1 mt-2">
        <label class="h6">Marcar Todos</label>
    </ng-container>
    <div class="table-responsive table-responsive-sigei">
        <table class="table table-hover table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th class="text-center"  *ngIf="canMakeChanges"></th>
                    <th class="text-left">Secuencia</th>
                    <th class="text-left">Servidor Público</th>
                    <th class="text-left">Documento de Identidad</th>
                    <th class="text-left">Cargo</th>
                    <th class="text-left">Unidad Organizativa</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let employee of paginatedPayrollDetails">
                    <td class="text-center"  *ngIf="canMakeChanges">
                        <input  (change)="selectItem(employee)"
                        [(ngModel)]="employee.isSelected" type="checkbox" name="{{employee.employeeId}}"
                        class="check-size">
                    </td>
                    <td class="text-left">{{employee.employeeId}}</td>
                    <td class="text-left">{{employee.name}}</td>
                    <td class="text-left">
                        <ng-container  *ngIf="employee.typeIdentificationId == 1 || employee.typeIdentificationId == 4 ">
                            {{employee.personalIdentification | mask: '000-0000000-0'}}
                        </ng-container>
                        <ng-container  *ngIf="employee.typeIdentificationId != 1 && employee.typeIdentificationId != 4">
                            {{employee.personalIdentification}}
                        </ng-container>
                    </td>
                    <td class="text-left">{{employee.positionName}}</td>
                    <td class="text-left">{{employee.departmentName}}</td>
                </tr>
            </tbody>
        </table>
        <div >
            <el-caption [originalRecordsLength]="payrollDetails.length"
                    [listedRecordsLength]="paginatedPayrollDetails.length" #paginator
                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :payrollDetails.length"
                    [records]="filteredList.enable ? filteredList.records : payrollDetails"
                    (onChage)="getPaginatedRecords($event)"></el-caption>
        </div>
    </div>
</div>

</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="this.dialogRef.close();" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>