<ng-container *ngIf="privilege.add.value">
    <form [formGroup]="externalServiceForm">
        <div class="row mt-10"
            *ngIf="isEditingExternalService && (currentConditionOnEditting == 2) && currentEdittingIsWithoutPayroll == true">
            <div class="alert alert-warning d-flex align-items-center">
                <i class="fas fa-exclamation-triangle mr-2 fa-2x blink-for-warning"></i>
                <div>
                    Esta acción de personal ya fue <strong>aprobada</strong>, modificarla puede causar alteraciones en
                    la <strong>nómina</strong> y otros módulos del <strong>SIGEI</strong>. En caso de que la acción de
                    personal haya sido aplicada en una
                    nómina u otro proceso dependiente de la misma, su modificación <strong>NO</strong> será
                    <strong>permitida</strong>.
                </div>
            </div>

        </div>
        <div class="row mt-10">
            <div class="col-md-2">
                <div class="form-group">
                    <label>Fecha de Solicitud <span class="text-danger">*</span></label>
                    <input formControlName="applicationDate" type="date" class="form-control form-control-lg" />
                    <span style="position: absolute;"
                        *ngIf="this.externalServiceForm.get('applicationDate').hasError('required') && externalServiceFormInvalid"
                        class="text-danger">Este campo es requerido</span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>Fecha de Inicio <span class="text-danger">*</span></label>
                    <input formControlName="dateStart" type="date" class="form-control form-control-lg" />
                    <span style="position: absolute;"
                        *ngIf="this.externalServiceForm.get('dateStart').hasError('required') && externalServiceFormInvalid"
                        class="text-danger">Este campo es requerido</span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>Fecha de Término <span class="text-danger">*</span></label>
                    <input formControlName="dateEnd" [min]="getDateStart ? getMinDate(getDateStart) : ''" type="date"
                        class="form-control form-control-lg" />
                    <span style="position: absolute;"
                        *ngIf="this.externalServiceForm.get('dateEnd').hasError('required') && externalServiceFormInvalid"
                        class="text-danger">
                        Este campo es requerido
                    </span>
                    <span style="position: absolute;"
                        *ngIf="this.externalServiceForm.get('dateEnd').hasError('higherDate') && externalServiceFormInvalid"
                        class="text-danger">
                        La fecha de término no puede ser menor a la fecha de inicio
                    </span>
                    <span *ngIf="endDateInvalid" class="text-danger">La fecha de término debe ser superior a la fecha de
                        inicio
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>Hora de Inicio <span class="text-danger">*</span></label>
                    <input formControlName="hourStart" type="time" class="form-control form-control-lg" />
                    <span style="position: absolute;"
                        *ngIf="this.externalServiceForm.get('hourStart').hasError('required') && externalServiceFormInvalid"
                        class="text-danger">Este campo es requerido</span>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>Hora de Fin <span class="text-danger">*</span></label>
                    <input formControlName="hourEnd" type="time" class="form-control form-control-lg" />
                    <span style="position: absolute;"
                        *ngIf="this.externalServiceForm.get('hourEnd').hasError('required') && externalServiceFormInvalid"
                        class="text-danger">Este campo es requerido</span>
                </div>
            </div>
        </div>
        <div class="row flex-wrap justify-content-between">
            <div class="col">
                <div class="form-group">
                    <label>Causa del servicio externo <span class="text-danger">*</span></label>
                    <textarea formControlName="reason" class="form-control"
                        (input)="validateReason($event.target.value)"></textarea>
                    <span style="position: absolute;"
                        *ngIf="this.externalServiceForm.get('reason').hasError('required') && externalServiceFormInvalid"
                        class="text-danger">Este campo es requerido</span>
                </div>
            </div>

            <div class="col">
                <div class="form-group">
                    <label>Comentario</label>
                    <textarea formControlName="comment" class="form-control"></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="d-block">Recurrencia
                        <span class="text-danger"> *</span>
                    </label>
                    <div class="form-group">
                        <div class="form-check form-check-inline">
                            <input (change)="selectDay()" type="checkbox" formControlName="monday"
                                class="form-check-input dynamicFormControl mr-2 ng-valid ng-dirty ng-touched"
                                value="1" />
                            <label class="form-check-label"> Lunes </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input (change)="selectDay()" type="checkbox" formControlName="tuesday"
                                class=" form-check-input dynamicFormControl mr-2 ng-valid ng-dirty ng-touched "
                                value="2" />
                            <label class="form-check-label"> Martes </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input (change)="selectDay()" type="checkbox" formControlName="wednesday" class="
                                form-check-input
                                dynamicFormControl
                                mr-2
                                ng-valid ng-dirty ng-touched
                            " value="3" />
                            <label class="form-check-label"> Miércoles </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input (change)="selectDay()" type="checkbox" formControlName="thursday" class="
                                form-check-input
                                dynamicFormControl
                                mr-2
                                ng-valid ng-dirty ng-touched
                            " value="4" />
                            <label class="form-check-label"> Jueves </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input (change)="selectDay()" type="checkbox" formControlName="friday" class="
                                form-check-input
                                dynamicFormControl
                                mr-2
                                ng-valid ng-dirty ng-touched
                            " value="5" />
                            <label class="form-check-label"> Viernes </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input (change)="selectDay()" type="checkbox" formControlName="saturday" class="
                                form-check-input
                                dynamicFormControl
                                mr-2
                                ng-valid ng-dirty ng-touched
                            " value="6" />
                            <label class="form-check-label"> sábado </label>
                        </div>
                    </div>
                    <span *ngIf="dayOfWeekInvalid.invalid && dayOfWeekInvalid.dirty" class="text-danger">Este campo es
                        requerido</span>
                </div>
            </div>
        </div>

        <div class="row mt-2" *ngIf="isEditingExternalService">
            <div class="text-primary pt-4">
                <h4>Información de la Modificación</h4>
                <hr>
            </div>
            <div class="row mb-2">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Motivo de la Modificación Anterior </label>
                        <input type="text" class="form-control form-control-lg"
                            [value]="externalServiceForm.get('oldModificationReason')?.value?.stringData" readonly />
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="form-group">
                        <label>Comentario de la Modificación Anterior</label>
                        <textarea [value]="externalServiceForm.get('oldModificationComment')?.value"
                            class="form-control" rows="6" readonly></textarea>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Motivo de la Modificación Actual <span class="text-danger">*</span></label>
                        <ngx-select-dropdown formControlName='newModificationReason' [options]='modificationReasons'
                            [config]='reasonsConfig' class="flex-1" (change)="changeModificationReason($event?.value)">
                        </ngx-select-dropdown>
                        <span class="text-danger"
                            *ngIf="externalServiceForm.invalid && externalServiceForm.controls['newModificationReason']?.errors?.required">Este
                            campo es requerido</span>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="form-group">
                        <label>Comentario de la Modificación Actual</label>
                        <textarea formControlName="newModificationComment" class="form-control" rows="6"></textarea>
                    </div>
                </div>
            </div>


        </div>
    </form>

    <div class="row">
        <div class="form-group d-flex justify-content-end align-items-center">
            <button [disabled]="externalServiceForm.invalid || dayOfWeekInvalid.invalid" (click)="savePermission()"
                class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
                <i class="flaticon-disco-flexible"></i>
                <span>{{isEditingExternalService ? 'Guardar Modificación' : 'Guardar'}}</span>
            </button>

            <button (click)="clearFields(isEditingExternalService ? true  : false);"
                class="btn btn-danger btn-pill font-weight-bolder">
                {{isEditingExternalService ? 'Cancelar Modificación' : 'Limpiar campos'}}
            </button>
        </div>
    </div>
</ng-container>
<div class="row gutter-b">
    <h2 class="text-primary gutter-b d-flex justify-content-between flex-wrap">
        Lista de Servicios Externos
    </h2>
    <div class="table-responsive">
        <table class="table atbl table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th class="text-left">Secuencia</th>
                    <th class="text-left">Fecha de Solicitud</th>
                    <th class="text-left">Fecha de Inicio</th>
                    <th class="text-left">Fecha de Término</th>
                    <th class="text-left">Hora de Inicio</th>
                    <th class="text-left">Hora de Fin</th>
                    <th class="text-left">Causa del Servicio</th>
                    <th class="text-left">Estatus</th>
                    <th class="text-center">Acción</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let permission of servicesList">
                    <td class="text-left">{{permission.permissionsId}}</td>
                    <td class="text-left">{{permission.applicationDate |date: 'dd/MM/yyy'}}</td>
                    <td class="text-left">{{permission.dateStart |date: 'dd/MM/yyy'}}</td>
                    <td class="text-left">{{permission.dateEnd |date: 'dd/MM/yyy'}}</td>
                    <td class="text-left">{{permission.hourStartText1}}</td>
                    <td class="text-left">{{permission.hourEndText1}}</td>
                    <td class="text-left">
                        <div class="reasonPermiso">
                            {{permission.reason}}
                        </div>
                    </td>
                    <td>
                        <ng-container *ngIf="!permission?.status; else conditions">
                            <span class="font-weight-bold text-danger">{{permission?.conditionId === 10 ? 'Anulado' :
                                'Inactivo'}}</span>
                        </ng-container>
                        <ng-template #conditions>
                            <ng-container *ngIf="permission?.conditionId === 6">
                                <span class="font-weight-bold text-warning">En Espera de Aprobación</span>
                            </ng-container>
                            <ng-container *ngIf="permission?.conditionId === 2">
                                <span class="font-weight-bold text-success">Aprobado</span>
                            </ng-container>
                            <ng-container *ngIf="permission?.conditionId === 3">
                                <span class="font-weight-bold text-danger">Rechazado</span>
                            </ng-container>
                            <ng-container *ngIf="permission?.conditionId === 10">
                                <span class="font-weight-bold text-danger">Anulado</span>
                            </ng-container>
                        </ng-template>
                    </td>
                    <td class="actionsBtn">
                        <button *ngIf="privilege.view.value" (click)="exportExternalService(permission)" class="btn btn-sm btn-light-info btn-icon mr-2">
                            <i class="flaticon-ver" data-toggle="tooltip" title="Ver"></i>
                        </button>
                        <button *ngIf="privilege.edit.value && permission?.conditionId != 10 && permission?.status == true"
                            (click)="editExternalService(permission)" class="btn btn-icon btn-light-warning btn-sm m-1">
                            <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>