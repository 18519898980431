import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Vacancies } from '../models/vacancies';
import { ApplyVacancyModel } from '../pages/personal-requisition/models/apply-vacancy-model';
import { ParentModel } from '../pages/post-vacancies/models/post-vacancy.model';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { keyWord } from '../shared/utils/parameterControl';
import { AuthInfoService } from './auth-info.service';
import { ParameterControlService } from './parameter-control.service';

@Injectable({
  providedIn: 'root'
})
export class VacanciesService {
  preferibleCareers: any
  preferibleSkills: any[] = []
  vacantsBenefits: any[] = []
  private readonly url = environment.api_url
  private readonly url_apicore = environment.api_core_url
  constructor(private _http: HttpClient, private srvParam: ParameterControlService,
    private authInfo: AuthInfoService) { }

  getVacanciesType() {
    return this._http.get(`${this.url}/VacancyType?companyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getLocations() {
    // return this.srvParam.getParameters(keyWord.Locations)
    return this._http.get<any>(`${this.url_apicore}/Locations`)
  }
  //Aplicar vacantes
  GetVacancies(typeVacant: number, idPerson: number) {
    return this._http.get<any>(`${this.url}/Vacants?TypeVacant=${typeVacant}&IdVacant=0&idPerson=${idPerson}&companyId=${this.authInfo.getCompanyId()}`);
  }
  //Aplicar vacantes fin

  GetVacancyById(id: number) {
    return this._http.get<any>(`${this.url}/Vacants?IdVacant=${id}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getApprovedVacancy(id: number): Observable<ParentModel> {
    return this._http.get<ParentModel>(`${this.url}/Vacants/GetVacants?VacantsId=${id}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }
  getVacancy(id: number,status): Observable<ParentModel> {
    return this._http.get<ParentModel>(`${this.url}/Vacants/GetVacants?VacantsId=${id}&CompanyId=${this.authInfo.getCompanyId()}&Status=${status}`);
  }
  //Publicar vacantes
  getVacants(typeVacant: number) {
    return this._http.get<any>(`${this.url}/Vacants?TypeVacant=${typeVacant}&IdVacant=0&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getVacantsAll(companyId = this.authInfo.getCompanyId()) {
    return this._http.get<any>(`${this.url}/Vacants?TypeVacant=6&companyId=${companyId}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getVacantsAllPaginated(options) {
    let params = new HttpParams();
    params = params.append('CompanyId', this.authInfo.getCompanyId().toString())
    params = params.append('PropkeyId', options.propKeyId.toString())
    params = params.append('SearchParameter', options.searchParameter)
    params = params.append('Status', options.status)
    params = params.append('Conditions', options.conditions)
    params = params.append('Page', options.page)
    params = params.append('PageSize', options.pageSize)
    params = params.append('Type', options.type)
    if(options?.personId){
      params = params.append('PersonId', options.personId)
    }
    return this._http.get<any>(`${this.url}/Vacants/GetPaginated`, {params})
  }
  //Publicar vacantes fin
  getVacantsTypeBenefit() {
    return this.srvParam.getParameters(keyWord.typeBenefits)
  }

  getBenefitsByVacantsId(IdvacantBenefit:number) {
    return this._http.get<any>(`${this.url}/VacantBenefit?type=2&IdvacantBenefit=${IdvacantBenefit}`)
  }

  getById(id: number) {
    return this._http.get<any>(`${this.url}/Vacants?TypeVacant=3&IdVacant=${id}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }

  getVacancyType() {
    return this._http.get(`${this.url}/vacancyType?companyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  ApplyToVacancy(model: ApplyVacancyModel) {
    return this._http.post<any>(`${this.url}/PersonsPostulationVacants`, model);
  }

  getPostulation(personId: number, vacantId: any) {
    return this._http.get<any>(`${this.url}/PersonsPostulationVacants?IdPpv=${personId}&VacantId=${vacantId}&CompanyId=${this.authInfo.getCompanyId()}`);
  }

  updateVacants(newVacant: Vacancies) {
    return this._http.put(`${this.url}/Vacants`, newVacant)
  }

  getPersonalRequisition() {
    return this._http.get(`${this.url}/PersonalRequisition`)
  }

  getRequisitionType() {
    return this.srvParam.getParameters(keyWord.RequisitionType)
  }

  getGender() {
    return this.srvParam.getParameters(keyWord.Gender)
  }

  getEmployees() {
    return this._http.get(`${this.url}/Employees?CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getOccupationalGroup() {
    return this.srvParam.getParameters(keyWord.OccupationalGroup)
  }

  getHoursAvailable() {
    return this.srvParam.getParameters(keyWord.HoursAvailable)
  }

  getTypeSkill() {
    return this._http.get<any>(`${this.url_apicore}/TypeSkills?CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getSkills(id: number) {
    return this._http.get<any>(`${this.url_apicore}/Skills?idSkills=${id}&CompanyId=${this.authInfo.getCompanyId()}`)
  }

  getTypeSkillsLevel(id: number) {
    return this._http.get<any>(`${this.url_apicore}/TypeLevelSkills?skillId=${id}&CompanyId=${this.authInfo.getCompanyId()}`)
  }

  getSkillsLevel(id: number) {
    return this._http.get<any>(`${this.url_apicore}/LevelSkill?CompanyId=${this.authInfo.getCompanyId()}&typeLevelSkillsId=${id}&CompanyId=${this.authInfo.getCompanyId()}`)
  }

  createVacantCareer(newVacantCareer) {
    return this._http.post<any>(`${this.url}/VacantsCareers`, newVacantCareer)
  }

  createVacantSkills(newVacantSkill) {
    return this._http.post<any>(`${this.url}/VacantsSkills`, newVacantSkill)
  }

  createVacantBenefit(newVacantBenefit) {
    return this._http.post<any>(`${this.url}/VacantBenefit`, newVacantBenefit)
  }

  getAcademicLevels() {
    return this.srvParam.getParameters(keyWord.AcademicLevel).toPromise()
  }

  getApplicants() {
    return this._http.get(`${this.url}/Vacants/GetView`)
  }

  getPaginatedApplicants(page?:number, pageSize?:number, PropkeyId = null, SearchParameter = null) {
    return this._http.get(`${this.url}/Vacants/GetViewGetPaginated?CompanyId=${this.authInfo.getCompanyId()}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Page=${page}&PageSize=${pageSize}&Type=1`)
  }

  getPersonByIdentification(identification) {
    return this._http.get(`${this.url_apicore}/persons?type=2&Person=${identification}`)
  }

  deleteVacant(model:any){
    return this._http.put(`${this.url}/Vacants/DeleteVacants`, model)
  }

  activeEmployeeInstitution(personIds: number[]) {
    return this._http.post<ResponseModel<any>>(this.url + `/employees/ActiveEmployeeInstitution?status=true&companyId=${this.authInfo.getCompanyId()}`, personIds);
  }

}
