import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deparments',
  templateUrl: './deparments.component.html',
  styleUrls: ['./deparments.component.css']
})
export class DeparmentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
