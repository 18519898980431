import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Employee, VacationDetail } from 'src/app/models/collective-vations';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CollectiveVacationsService } from 'src/app/services/collective-vacations.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { FilterEvent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-public-servant-collective-vacation-modal',
  templateUrl: './public-servant-collective-vacation-modal.component.html',
  styleUrls: ['./public-servant-collective-vacation-modal.component.css']
})
export class PublicServantCollectiveVacationModalComponent implements OnInit {

  titleModal = "Vacaciones Colectivas por Servidor Público";
  sweetAlrt = new SweetAlert
  filtervalues:any
  vacationDetailsList = []
  originalVacationDetailsList = []
  paginatedVacationDetailsList = []
  selectedVacation: any
  vacationDetailDTO: VacationDetail = new VacationDetail()
  vacationDetailEmployeeDTO = new Employee()
  vacationsPagedResut = new PagedResult<any>();

  isAllChecked: boolean = false
  employeeList: any[] = []

  searchParameter: FilterEvent;

  filterText: string = '';
  selectedItems: any[] = [];
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: SearchBarPaginationParameter[] = [
    { propkeyId: 1, displayName: "Secuencia" },
    { propkeyId: 2, displayName: "Código Servidor Público" },
    { propkeyId: 3, displayName: "Servidor Público" },
    { propkeyId: 4, displayName: "Documento de Identidad", default: true },
    { propkeyId: 5, displayName: "Unidad Organizativa" },
    { propkeyId: 6, displayName: "Cargo" },
    { propkeyId: 7, displayName: "Tipo" },
    { propkeyId: 8, displayName: "Categoría" },
    { propkeyId: 9, displayName: "Tanda" },
  ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' },
    { item_id: 3, item_text: 'Incluidos' },
    { item_id: 4, item_text: 'Excluidos' },
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  reportUrl: any;



  constructor(
    public dialogRef: MatDialogRef<PublicServantCollectiveVacationModalComponent>,
    private _exlService: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private _toastService: ToastrService,
    private dialog: MatDialog,
    public authInfo: AuthInfoService,
    private _collectiveVacationsService: CollectiveVacationsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true
    this.selectedVacation = data?.vacation
  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.getPaginatedVacationDetails()
  }

  onCheckedAllChange(checked: boolean) {
    this.isAllChecked = checked
    this.vacationDetailDTO = new VacationDetail()
    if (checked) {
      if (this.filteredList.enable) this.employeeList = this.filteredList.records
      else this.employeeList = this.vacationDetailsList
    }
    else {
      this.employeeList = []
    }
  }

  onChecked(detail: any, checked: boolean) {
    if (checked) {
      this.employeeList.push(detail)
    }
    else {
      this.employeeList = this.employeeList.filter((employee: any) => employee.collectiveVacationsDetailId !== detail.collectiveVacationsDetailId)
    }

  }

  getVacationDetails() {
    this._collectiveVacationsService.getVacationDetals(this.selectedVacation?.collectiveVacationsHeaderId).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) {
          this._toastService.error('Ocurrio un error tratando de cargar los detalles de este registro')
        }

        this.originalVacationDetailsList = res.dataList
        this.vacationDetailsList = this.filterTable(this.originalVacationDetailsList)

      },
      error: (err: any) => {
        this._toastService.error('Ocurrio un error tratando de cargar los detalles de este registro')
      },
    })
  }

  getPaginatedVacationDetails() {

    const actives = this.selectedItems.find((x) => x.item_id === 1);
    const inactives = this.selectedItems.find((x) => x.item_id === 2);
    let status = 0;
    if ((!actives && !inactives) || (actives && inactives)) {
      status = -1;
    } else if (actives && !inactives) {
      status = 1;
    }

    const selectedConditions = this.selectedItems
      ?.filter((x) => x.item_id !== 1 && x.item_id !== 2)
      ?.map((e) => e.item_id)
      ?.join("-");
    const page = this.vacationsPagedResut.page
    const pageSize = this.vacationsPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const condition = selectedConditions
    this._collectiveVacationsService.getPaginatedVacationDetals(this.selectedVacation?.collectiveVacationsHeaderId, page, pageSize, propKeyId, text, status, condition).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) {
          this._toastService.error('Ocurrio un error tratando de cargar los detalles de este registro')
        }

        this.originalVacationDetailsList = res.singleData.items
        this.vacationDetailsList = res.singleData.items
        this.vacationsPagedResut = res.singleData

      },
      error: (err: any) => {
        this._toastService.error('Ocurrio un error tratando de cargar los detalles de este registro')
      },
    })
  }

  filterSearch(event: FilterEvent) {
    this.vacationsPagedResut.page = 1
    this.searchParameter = event
    this.getPaginatedVacationDetails()
  }


  excludeEmployeesSelection() {
    if (this.employeeList.length <= 0) {
      this._toastService.warning('Debe seleccionar al menos un registro')
      return
    }
    this.vacationDetailDTO.employees = []
    let detailsEmployees = []
    this.employeeList.forEach(employee => {
      this.vacationDetailEmployeeDTO = {
        employeeId: employee.employeeId,
        status: false
      }
      detailsEmployees.push(this.vacationDetailEmployeeDTO)
    });

    this.sweetAlrt.AlertConfirm_with_input("Excluir", "¿Está seguro de excluir este registro?", "Por favor introducir la razón", "question").then(res => {
      if (res.isConfirmed) {
        this.vacationDetailDTO = {
          collectiveVacationsHeaderId: this.selectedVacation.collectiveVacationsHeaderId,
          companyId: this.authInfo.getCompanyId(),
          userId: this.authInfo.getUserId(),
          employees: detailsEmployees,
          exclusionReason: res.value
        }
        this._collectiveVacationsService.updateDetail(this.vacationDetailDTO).subscribe({
          next: (res: any) => {
            this._toastService.success('Servidores publicos excluidos satisfactoriamente')
            this.getVacationDetails()
            this.employeeList = []
            this.isAllChecked = false
          },
          error: (err: any) => {
            this._toastService.error('Ocurrio un error tratando de excluir los servidores publicos')
          },
        })
      } else {
      }
    })
  }

  onKeyParameterChange(event){
    this.filtervalues = event;
  }

  openReport() {

    let statusArray: any = '-1';
    const active = this.selectedItems.find(x => x.item_id == 1)
    const inactive = this.selectedItems.find(x => x.item_id == 2)
    if(inactive && !active){
      statusArray = '0'
    }
    if(active && !inactive){
      statusArray = '1'
    }
    

    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.CollectiveVacationsDetail;
    const PropkeyId: any = this.searchParameter?.propkeyId ?? 0
    const SearchParameter: any = this.searchParameter?.value ?? ''
    const collectiveVacationsHeaderId = this.selectedVacation.collectiveVacationsHeaderId ?? 0;
    const reportUrl = `${environment.reportUrl}/?Reportcode=${reportCode}&CompanyId=${companyId}&PropKeyId=${PropkeyId}&SearchParameter=${SearchParameter}&CollectiveVacationsHeaderId=${collectiveVacationsHeaderId}&Status=${statusArray}&Page=${this.vacationsPagedResut.page}&PageSize=${this.vacationsPagedResut.pageSize}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Nómina de Prestaciones Laborales',
      width: 1024,
      height: 768
    }
    openReport(parameters);

  }



  includeEmployeesSelection() {
    if (this.employeeList.length <= 0) {
      this._toastService.warning('Debe seleccionar al menos un registro')
      return
    }
    this.vacationDetailDTO.employees = []
    let detailsEmployees = []
    this.employeeList.forEach(employee => {
      this.vacationDetailEmployeeDTO = {
        employeeId: employee.employeeId,
        status: true
      }
      detailsEmployees.push(this.vacationDetailEmployeeDTO)
    });

    this.vacationDetailDTO = {
      collectiveVacationsHeaderId: this.selectedVacation.collectiveVacationsHeaderId,
      companyId: this.authInfo.getCompanyId(),
      userId: this.authInfo.getUserId(),
      employees: detailsEmployees,
      exclusionReason: null
    }


    this._collectiveVacationsService.updateDetail(this.vacationDetailDTO).subscribe({
      next: (res: any) => {
        this._toastService.success('Servidores publicos incluidos satisfactoriamente')
        this.getVacationDetails()
        this.employeeList = []
        this.isAllChecked = false
      },
      error: (err: any) => {
        this._toastService.error('Ocurrio un error tratando de incluir los servidores/or publico/s')
      },
    })
  }

  Cancel(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }


  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  onDeSelectAll() {
    this.selectedItems = [];
    this.getPaginatedVacationDetails();
  }
  onItemDeSelect(item: any) {
    this.getPaginatedVacationDetails();
  }
  onItemSelect(item: any) {
    this.getPaginatedVacationDetails();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.getPaginatedVacationDetails();
  }


  onKeyChange(text) {
    this.filterText = text;
  }
  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = this.filterTable(event.records)

  }

  filter() {
    document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.vacationDetailsList = this.filterTable(this.originalVacationDetailsList)
    }
    this.setFilterToLocalStorage();
  }
  setFilterToLocalStorage() {
    localStorage.setItem('budget-areas_filter', JSON.stringify(this.selectedItems));
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl?.find(x => x.item_id == 1) && !sl?.find(x => x.item_id == 2)) {
      list = list.filter(x => x.employeeStatus == true);
    }
    if (sl?.find(x => x.item_id == 2) && !sl?.find(x => x.item_id == 1)) {
      list = list.filter(x => x.employeeStatus == false);
    }
    if (sl?.find(x => x.item_id == 3) && !sl?.find(x => x.item_id == 4)) {
      list = list.filter(x => x.status == true);
    }
    if (sl?.find(x => x.item_id == 4) && !sl?.find(x => x.item_id == 3)) {
      list = list.filter(x => x.status == false);
    }
    return list;
  }





  exportToExcel() {
    let data = []
    if (this.filteredList.enable) {
      data = this.filteredList.records
    } else {
      data = this.vacationDetailsList
    }
    data.forEach(element => {
      element.statusText = element.status ? 'Incluido' : 'Excluido'
      element.employeeStatusText = element.employeeStatus ? 'Activo' : 'Inactivo'
    });
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Código Servidor Público",
      "Servidor Público",
      "Documento de Identidad",
      "Unidad Organizativa",
      "Cargo",
      "Tipo",
      "Categoría",
      "Tanda",
      "Condición de Servidor Público",
      "Estatus",
    ], [
      "collectiveVacationsDetailId",
      "employNumber",
      "name",
      "personalIdentification",
      "departmentName",
      "positionName",
      "employeeType",
      "contractType",
      "proposedSchedule",
      "employeeStatusText",
      "statusText"
    ]]
    this._exlService.exportToExcelSpecificColumns(data, excelHeaders, 'Lista de Vacaciones Colectivas por Servidor Público', true);
  }



  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint2').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }
  getPaginatedRecords(event: PaginationEvent) {
    if (this.vacationsPagedResut.page == event.selectedPage && this.vacationsPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.vacationsPagedResut.page = event.selectedPage;
    this.vacationsPagedResut.pageSize = event.registersPerPage;
    this.getPaginatedVacationDetails()
  }


}
