import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Departments } from 'src/app/models/departments.model';
import { Positions } from 'src/app/models/positions.model';
import { IEmployee } from 'src/app/pages/job-offer/models/Employee';
import { PlanningRrhhComponent } from 'src/app/pages/Planning/components/planning-rrhh/planning-rrhh.component';
import { EmployeeMaintenanceService } from 'src/app/services/employee-maintenance.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PlanningRrhhService } from 'src/app/services/planning-rrhh.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { Person } from '../../../pdf-preview/models/pdf-personal-record.model';
import { EmployeeDepartment } from '../../models/employee-department.model';

@Component({
  selector: 'app-employee-department',
  templateUrl: './employee-department.component.html',
  styleUrls: ['./employee-department.component.css']
})
export class EmployeeDepartmentComponent implements OnInit {

  employeeDepartmentData:EmployeeDepartment;
  employeePositionData:Positions;
  departmentsDropdown:Departments[];
  employeeTypes: ParameterControl[];
  requisitionTypes: ParameterControl[];

  form:FormGroup = this._builder.group({
    departmentName:[''],
    positionName: [''],
    admissionDate:   [null],
    reentryDate:  [null],
    electiveDepartureDate:   [null],
    employeeType:     [''],
    vacationBonus: [true],
    healthInsurance: [true],
    vacation: [true],
    status:     [true],
  });
  @Input() selectedEmployee: IEmployee;
  @Input() personInfo: Person;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>(); 
  constructor(
    private _builder: FormBuilder,
    private _planificationService:PlanningRrhhService,
    private _employeeMaintenanceService: EmployeeMaintenanceService,
    private _parameterControlService: ParameterControlService,
    private _personService:PersonService
  ) { }

  ngOnInit(): void {
  
    this.getData();

  }

  getData(){
    forkJoin([
      this._employeeMaintenanceService.getEmployeeDepartment(this.selectedEmployee.departmentId),
      this._employeeMaintenanceService.getDepartments(),
      this._parameterControlService.getParameters(keyWord.RequisitionType),
      this._employeeMaintenanceService.getEmployeePosition(this.selectedEmployee.positionId),
      this._parameterControlService.getParameters(keyWord.EmployeeType),

    
    ]).subscribe((responses)=>{
      if(responses[0].succeded){
        this.employeeDepartmentData = responses[0].dataList[0];
      }
      if(responses[1].succeded){
        this.departmentsDropdown = responses[1].dataList;
      }
      if(responses[2].succeded){
        this.requisitionTypes = responses[2].dataList;
      }

      if(responses[3].succeded){
        this.employeePositionData = responses[3].dataList[0];
      }

      if(responses[4].succeded){
        this.employeeTypes = responses[4].dataList;
      }

      this.form = this.buildForm();
    })
  }

  addingData(response:ResponseModel<any>){
    return (response.succeded) ? response.dataList : null
  }

  buildForm(){
    return this._builder.group({
      departmentName:[this.employeeDepartmentData.departmentName],
      positionName: [this.employeePositionData.positionName],
      admissionDate:   [formatDate(this.selectedEmployee.admissionDate, 'yyyy-MM-dd', 'en-US')],
      reentryDate:  [null],
      electiveDepartureDate:   [formatDate(this.selectedEmployee.electiveDepartureDate, 'yyyy-MM-dd', 'en-US')],
      employeeType:     [this.employeeTypes.find(x=>x.ocode===this.selectedEmployee.typeEmployeeId).stringData],
      vacationBonus: [true],
      healthInsurance: [true],
      vacation: [true],
      status:     [true],
    });
  }
  submitForm(){
    this.onSubmit.emit(this.form.value)
  }
}
