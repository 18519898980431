import { Component, Input, OnInit, Output,  EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';

@Component({
  selector: 'app-cambio-sueldo',
  templateUrl: './cambio-sueldo.component.html',
  styleUrls: ['./cambio-sueldo.component.css']
})
export class CambioSueldoComponent implements OnInit {
  @Input() changeDetailId
  @Input() employee
  @Output() sendChangeDate = new EventEmitter();
  @Output() sendComment = new EventEmitter<string>();
  changeDetail:any
  constructor(private srvActionChange: PersonnelActionsService, private _toastService: ToastrService,) { }

  ngOnInit(): void {
    this.getEmployeeChangeDetails(this.changeDetailId)
    
  }

  getEmployeeChangeDetails(id) {
    this.srvActionChange.getChangeDetails(id, 1).subscribe((res: any) => {
      if (res.succeded) {
        this.changeDetail = res.dataList[0];
        if(this.isDate(this.changeDetail?.valueAfter)){
          this.changeDetail.valueAfterFormattedDate = moment(this.changeDetail?.valueAfter).format('DD/MM/YYYY')
        }
        if(this.changeDetail?.comment){
          this.sendComment.emit(this.changeDetail?.comment);
        }
        this.getChangeDate()
      } else {
        this._toastService.error(res.errors[0]);
      }
    }, error => {
      this._toastService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  isNumeric(value): boolean {
    if (value == null || value == undefined) {
      return false;
    }
    return !isNaN(Number(value));
  }

  isDate(date): boolean {
    if (this.isNumeric(date)) {
      return false;
    }

    let regExp = /[a-zA-Z]/g;
    if (regExp.test(date)) {
      return false;
    }

    return (new Date(date).toString() !== "Invalid Date") && !isNaN(Number(new Date(date)));
  }

  getChangeDate(){
    this.sendChangeDate.emit(this.changeDetail?.effectiveDate)
  }

}
