<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-candidato-1 icon-3x text-danger mr-3"></i>
                <h1 class="text-primary"> No Objeción </h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Dashboard">
            <a *ngIf="privilege.dashboardAction.value"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto"
                (click)="openDashBoard()">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </a>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-6 c-left">
            <div class="card-toolbar d-flex justify-content-start">

                <div class="col-md-4 mr-2 pl-0 pt-1">
                    <div class="form-group  institution">
                        <label class="text-primary">
                            Estatus
                        </label>
                        <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                            [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                            (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                    </div>
                </div>

                <div class="col-md-auto mr-2 pl-0 pt-1">
                    <div class=" card-toolbar d-flex justify-items-end ">
                        <div class="col-md-auto form-group">
                            <label>Número de Remisión</label>
                            <div class="d-flex">
                                <input type="text" [(ngModel)]="reNumber" id="nremision"
                                    class="form-control form-control-solid form-control-lg mr-2"
                                    placeholder="Escriba aquí el número" autocomplete="0" disabled>
                                <!-- mask="AAAA-AAA-AA-AAAA" -->
                                <button (click)="sendData()" [disabled]='!reNumber || sendMapList.length < 1'
                                    class="btn bg-primary" data-toggle="tooltip" title="Enviar"
                                    *ngIf="privilege.sendAction.value">
                                    <i class="flaticon-enviar icon-2x text-white"></i>
                                </button>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="privilege.exportAction.value" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="openNoObjectionReport()">
                            <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                            Visualizar Reporte
                        </a>
                        <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a> -->
                    </div>
                </div>

                <div class="col text-nowrap">
                    <app-search-bar-pagination #searchBar [namePageLocalStorage]="searchparameterlocalstorage"
                        (onFilter)="filterSearch($event)" [parameters]="searchBarDisplayNames">
                    </app-search-bar-pagination>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table id="" class="table table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-left"><input type="checkbox" [checked]="isAllChecked"
                                (change)="selectAllItems($event.target.checked)" name="" id="" class="check-size"></th>
                        <th class="text-center">Secuencia</th>
                        <th class="text-left min-w-175px">Documento de Identidad</th>
                        <th>Nombre</th>
                        <th>Cargo</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-left">Número de Remisión</th>
                        <th class="text-left">Condición de la No Objeción</th>
                        <th class="text-left">Fecha de Envío</th>
                        <th class="text-left">Fecha de Aprobación</th>
                        <th class="text-center">Estatus</th>
                        <th class="text-center">Acción</th>
                        <!-- <th class="text-center">Acciones</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of tableData">

                        <td class="text-center">
                            <input *ngIf="(item?.condition == 0 || item?.condition == 5) && privilege.sendAction.value"
                                (click)="selectItem(item)"  [(ngModel)]="item.isSelected"  type="checkbox"
                                name="{{item?.ppvid}}" id="" class="check-size" [checked]="isAllChecked">
                        </td>
                        <td class="text-center">{{item.ppvid}}</td>
                        <td class="text-left" *ngIf="item.typeIdentificationId == 1">{{item.personalIdentification |
                            mask: '000-0000000-0'}}</td>
                        <td class="text-left" *ngIf="item.typeIdentificationId != 1">{{item.personalIdentification}}
                        </td>
                        <td>{{item.personName}}</td>
                        <td>{{item.positionName}}</td>
                        <td>{{item.departmentName}}</td>
                        <td>{{item.referralNumber}}</td>
                        <td class="text-left">
                            <span *ngIf="item.condition != 0">
                                <span *ngIf="item.condition == 1" class="font-weight-bold text-warning">
                                    <span class="label label-warning label-dot mr-2"></span>
                                    {{item.conditionText}}
                                </span>
                                <span *ngIf="item.condition == 2 || item.condition == 3"
                                    class="font-weight-bold text-success">
                                    <span class="label label-success label-dot mr-2"></span>
                                    {{item.conditionText}}
                                </span>
                                <span *ngIf="item.condition == 5" class="font-weight-bold text-danger">
                                    <span class="label label-danger label-dot mr-2"></span>
                                    {{item.conditionText}}
                                </span>
                                <span *ngIf="item.condition == 6" class="font-weight-bold text-warning">
                                    <span class="label label-warning label-dot mr-2"></span>
                                    {{item.conditionText}}
                                </span>
                                <span *ngIf="item.condition == 10" class="font-weight-bold text-danger">
                                    <span class="label label-danger label-dot mr-2"></span>
                                    {{item.conditionText}}
                                </span>
                            </span>
                            <span *ngIf="item.condition == 0">
                                <span class="label label-info label-dot mr-2"></span>
                                <span class="font-weight-bold text-info">{{item.conditionText}}</span>
                            </span>
                        </td>
                        <td class="text-left">
                            <ng-container *ngIf="item.requestedDate">

                                {{item.requestedDate | date: 'dd/MM/yyyy'}}
                            </ng-container>
                            <ng-container *ngIf="!item.requestedDate">
                                N/A
                            </ng-container>
                        </td>
                        <td class="text-left">
                            <ng-container *ngIf="item.ministerApprovalDate">

                                {{item.ministerApprovalDate | date: 'dd/MM/yyyy'}}
                            </ng-container>
                            <ng-container *ngIf="!item.ministerApprovalDate">
                                N/A
                            </ng-container>
                        </td>
                        <td>
                            <span *ngIf="item.status" class="font-weight-bold text-success">{{item.statusText}}</span>
                            <span *ngIf="!item.status" class="font-weight-bold text-danger">{{item.statusText}}</span>
                        </td>
                        <td class="actionsBtn">
                            <button tooltip="Ver" placement="top" data-toggle="modal" *ngIf="privilege.viewAction.value"
                                class="btn btn-sm btn-light-skype btn-icon mr-2" (click)="proofDetails(item)">
                                <i class="flaticon-ver icon-2x" data-title="Ver" data-toggle="tooltip"></i>
                            </button>
                            <button tooltip="Ver" placement="top" data-toggle="modal"
                                *ngIf="privilege.viewAction.value && item.condition == 6"
                                class="btn btn-sm btn-light-warning btn-icon mr-2"
                                (click)="openEditNoObjectionModal(item)">
                                <i class="flaticon-edit icon-2x" data-toggle="tooltip" data-title="Editar"></i>
                            </button>
                            <button
                                *ngIf="(item.condition === 1 && !item?.publicAccessId && companySign?.useDigitalSignature && !isSending)  && privilege.sendAction.value"
                                class="btn btn-sm btn-warning btn-icon mr-2" data-toggle="tooltip"
                                (click)="ResendRequestSign(item)" title="Reenviar Solicitud de firma">
                                <img src="/assets/images/refresh.svg" class="flaticon-ver icon-custom">
                            </button>
                        </td>
                        <!-- <td class="actionsBtn" *ngIf="">
                            <a routerLink="/remission-no-objection" class="btn btn-icon btn-light-info" data-toggle="tooltip" title="Remisión"><i class="flaticon-ver"></i></a>
                        </td> -->
                    </tr>
                </tbody>
            </table>
            <div>
                <app-paginator [listedRecordsLength]="tableData?.length"
                    [registerQuantity]="employeesPagedResult.totalItems"
                    [registerPerPage]="employeesPagedResult.pageSize" [totalPages]="employeesPagedResult.totalPages"
                    [selectedPage]="employeesPagedResult.page" (onChange)="getPaginatedRecords($event)">
                </app-paginator>
            </div>
        </div>
    </div>
</div>

<export-data-to-pdf [title]="'No Objeción'" id="dataToPrint">
    <ng-container thead>
        <th class="text-center min-w-175px">Documento de Identidad</th>
        <th>Nombre</th>
        <th>Cargo</th>
        <th class="text-left">Unidad Organizativa</th>
        <th class="text-left">Condición de la No Objeción</th>
        <th class="text-left">Fecha de envío</th>
        <th class="text-left">Fecha de aprobación</th>
        <th class="text-center">Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let item of this.tableData" data-row="0" class="datatable-row" style="left: 0px;">
            <td class="text-left" *ngIf="item.typeIdentificationId == 1">{{item.personalIdentification |
                mask: '000-0000000-0'}}</td>
            <td class="text-left" *ngIf="item.typeIdentificationId != 1">{{item.personalIdentification}}
            </td>
            <td>{{item.personName}}</td>
            <td>{{item.positionName}}</td>
            <td>{{item.departmentName}}</td>
            <td class="text-center">
                <span *ngIf="item.condition != 0">
                    <span *ngIf="item.condition == 1" class="font-weight-bold text-warning"><span
                            class="label label-warning label-dot mr-2"></span>Enviada</span>
                    <span *ngIf="item.condition == 2 || item.condition == 3" class="font-weight-bold text-success"><span
                            class="label label-success label-dot mr-2"></span>Aprobada por el MAP</span>
                    <span *ngIf="item.condition == 5" class="font-weight-bold text-danger"><span
                            class="label label-danger label-dot mr-2"></span>Rechazada por el MAP</span>
                    <span *ngIf="item.condition == 6" class="font-weight-bold text-warning"><span
                            class="label label-warning label-dot mr-2"></span>Devuelta para Revisión</span>
                </span>
                <span *ngIf="item.condition == 0">
                    <span class="label label-info label-dot mr-2"></span>
                    <span class="font-weight-bold text-info">No enviada</span>
                </span>
            </td>
            <td class="text-center">
                <ng-container *ngIf="item.requestedDate">

                    {{item.requestedDate | date: 'dd/MM/yyyy'}}
                </ng-container>
                <ng-container *ngIf="!item.requestedDate">
                    N/A
                </ng-container>
            </td>
            <td class="text-center">
                <ng-container *ngIf="item.validyDate">

                    {{item.validyDate | date: 'dd/MM/yyyy'}}
                </ng-container>
                <ng-container *ngIf="!item.validyDate">
                    N/A
                </ng-container>
            </td>
            <td>
                <span *ngIf="item.status" class="font-weight-bold text-success">Activa</span>
                <span *ngIf="!item.status" class="font-weight-bold text-danger">Inactiva</span>
            </td>

        </tr>
    </ng-container>
</export-data-to-pdf>
