import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import { utils, WorkBook, WorkSheet, write, writeFile} from "xlsx"

@Injectable({
    providedIn: 'root',
})

export class ExportToExcelService {
    readonly EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
    readonly EXCEL_EXT = '.xlsx';

    exportToExcel(json: any[], filename: string) {
        const worksheet: WorkSheet = utils.json_to_sheet(json);
        const workbook: WorkBook = {
            Sheets: {'data': worksheet},
            SheetNames: ['data'],
        }
        
        const excelBuffer: any = write(workbook, {bookType: 'xlsx', type: 'array'});
        this.SaveExcel(excelBuffer, filename);
    }

    private SaveExcel(buffer: any, fileName: string) {
        const data: Blob = new Blob([buffer], {type: this.EXCEL_TYPE});
        FileSaver.saveAs(data, `${fileName}_export_${new Date().getTime()}${this.EXCEL_EXT}`);
    }


    exportToExcelSpecificColumns(json:any[], headers:string[][], fileName:string, customHeaderNames = false){
        const workBook = utils.book_new();
        const workSheet = utils.json_to_sheet([]);
        
        let properties = Object.getOwnPropertyNames(json[0]);
        if(customHeaderNames == true){
            properties = headers[1];
        }
        let resultList = this.mapSelectedProps(json, properties);
        utils.sheet_add_aoa(workSheet, headers);
        utils.sheet_add_json(workSheet, resultList, {origin:'A2', skipHeader:true});
        utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
        writeFile(workBook,`${fileName}_export_${new Date().getTime()}${this.EXCEL_EXT}`);
    }

    mapSelectedProps(list:any[], properties?:string[]){
        return list.map(data=> properties.reduce((o,k) => (o[k] = data[k], o),{}));
    }

}