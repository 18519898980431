<div class="card card-custom">
    <div class="card-header mt-3 ">
        <div class="card-title flex-column align-items-start" style="justify-content: flex-start !important">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-lista-de-verificacion-3 icon-3x text-danger mr-3"></i>
                <h2 class="text-primary"> Solicitudes de Servicios </h2>
            </div>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
            <div class="card-toolbar d-flex justify-content-start" *ngIf="privilege?.searchAction?.value">
                <div class="col-md-3 mr-2 px-0 pt-1">
                    <div class="form-group institution">
                        <label class="text-primary">
                            Estatus
                        </label>
                        <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                            [data]="dropdownList" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                            (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="col-md-2 px-0 pt-1 mr-1">
                    <div class="form-group institution">
                        <label class="text-primary">
                            Fecha de Inicio
                        </label>
                        <input type="date" class="form-control form-control-lg" [(ngModel)]="searchDateFrom" />
                    </div>
                </div>
                <div class="col-md-2 px-0 pt-1 mr-2">
                    <div class="form-group institution">
                        <label class="text-primary">
                            Fecha de Término
                        </label>
                        <input type="date" class="form-control form-control-lg" [(ngModel)]="searchDateTo" />
                    </div>
                </div>
                <div class="col-md-2 px-0 pt-1">
                    <button data-toggle="tooltip" data-original-title="Buscar"
                        class="btn btn-success btn-pill font-weight-bolder mt-2 col-md-auto mtop-custom"
                        (click)="search()">
                        <i class="flaticon2-search-1 icon-2x"></i>
                        Buscar
                    </button>
                </div>
                <!-- <button data-toggle="modal" id="btnAdd" data-target="#addSystemOptions" *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.addAction)"
                    class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                    <i class="flaticon-mas icon-2x" data-toggle="tooltip" title="Agregar nuevo Sistema" data-original-title="Agregar nuevo Sistema"></i>
                    Agregar
                </button> -->
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12" *ngIf="privilege?.exportAction?.value">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white"></i> Exportar
                    </button>
                    <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">

                        <a class="dropdown-item align-items-center" (click)="openReport()">
                            <i class="fa fa-print icon-2x text-primary mr-3"></i>
                            Visualizar Reporte
                        </a>
                    </div>
                </div>

                <div class="col text-nowrap" *ngIf="privilege?.searchAction?.value">
                    <el-search-bar (onChange)="getFilteredList($event)" #searchBar [keyValue]="filterText"
                        [itemForSearching]="searchBarDisplayNames" [records]="departmentsListOriginal"
                        [namePageLocalStorage]="localStorageSearch"></el-search-bar>
                </div>
            </div>
        </div>
    </div>

    <!--     <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-7 px-0 pt-1 mr-1">
                    <div class="form-group institution">
                        <label class="text-primary">
                            Dirección Certificación Laboral
                        </label>
                        <input type="text" class="form-control form-control-lg" [(ngModel)]="addressJobLetter" />
                    </div>
                </div>
                <div class="col-md-2 px-0 pt-1">
                    <button data-toggle="tooltip"
                        class="btn btn-primary btn-pill font-weight-bolder mt-2 col-md-auto mtop-custom ml-1"
                        (click)="saveAddress()">
                        <i class="flaticon-disco-flexible"></i>
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div> -->

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table mt-2 table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th>Servidor Público</th>
                        <th>Documento Identidad</th>
                        <th>Unidad Organizativa</th>
                        <th>Servicio Solicitado</th>
                        <th class="text-left">Fecha de Solicitud</th>
                        <!-- <th class="text-center">Quien Apureba</th> -->
                        <th class="text-left">Fecha Aprobación</th>
                        <th class="text-left">Estatus</th>
                        <th class="actionsBtn">Acciones</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of recordsPaginated">
                        <td class="text-center">{{item.sequence}}</td>
                        <td class="text-left">{{item.name}}</td>
                        <td class="text-left">{{item.personalIdentification | mask: '000-0000000-0'}}</td>
                        <td class="text-left">{{item.departmentName}}</td>
                        <td class="text-left">{{item.description}}</td>
                        <td class="text-left">{{item.applicationDate | date: 'dd/MM/yyyy'}}</td>
                        <!-- <td class="text-left">{{(item.typeService != 'Licencia' && item.type != 3 ?
                            (item.approvalDate | date: 'dd/MM/yyyy') : 'N/A')}}</td> -->
                        <td class="text-left">{{item.approvalDate | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-left">
                            <span *ngIf="item.type == 3">
                                <!-- carta laboral -->
                                <ng-container *ngIf="item.conditionId == 1">
                                    <span class="font-weight-bold text-primary">{{item.status}}</span>
                                </ng-container>
                                <ng-container *ngIf="item.conditionId == 3 || item.conditionId == 4">
                                    <span class="font-weight-bold text-success">{{item.status}}</span>
                                </ng-container>
                            </span>
                            <span *ngIf="item.type != 3">
                                <ng-container *ngIf="item?.conditionId === 0">
                                    <span class="font-weight-bold">Anulado</span>
                                </ng-container>
                                <ng-container *ngIf="item?.conditionId === 6">
                                    <span class="font-weight-bold text-warning">En Espera de Aprobación</span>
                                </ng-container>
                                <ng-container *ngIf="item?.conditionId === 2">
                                    <span class="font-weight-bold text-success">Aprobado</span>
                                </ng-container>
                                <ng-container *ngIf="item?.conditionId === 3">
                                    <span class="font-weight-bold text-danger">Rechazado</span>
                                </ng-container>
                            </span>
                        </td>
                        <td class="actionsBtn">
                            <div
                                *ngIf="item.conditionId == 1 && item.letterType == 1 && privilege?.processAction?.value">
                                <button title="add" class="btn btn-icon btn-light-success btn-sm m-1"
                                    (click)="showmodal(item, 0)">
                                    <i class="flaticon-etiqueta icon-2x" data-toggle="tooltip" data-title="Procesar"
                                        title="Procesar"></i>
                                </button>
                            </div>
                            <div *ngIf="item.conditionId == 3 && privilege?.viewAction?.value">
                                <button title="add" class="btn btn-icon btn-light-info btn-sm m-1" data-toggle="modal"
                                    id="btnAdd" data-target="#view" (click)="showmodal(item, 1)">
                                    <i class="flaticon-ver" data-toggle="tooltip" data-title="Ver" title="Ver"></i>
                                </button>
                            </div>
                            <div *ngIf="item.conditionId == 4">
                                <button title="add" class="btn btn-icon btn-light-info btn-sm m-1" data-toggle="modal"
                                    id="btnAdd" data-target="#view" (click)="showmodal(item, 1)">
                                    <i class="flaticon-ver" data-toggle="tooltip" data-title="Ver" title="Ver"></i>
                                </button>
                            </div>
                            <div *ngIf="item.conditionId == 3 && item.letterType == 2">
                                <button title="add" class="btn btn-icon btn-light-success btn-sm m-1"
                                    (click)="showmodal(item, 0, true)">
                                    <i class="flaticon-etiqueta icon-2x" data-toggle="tooltip" data-title="Entregar"
                                        title="Entregar"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <el-caption #paginator [originalRecordsLength]="dataList.length"
                    [listedRecordsLength]="recordsPaginated.length"
                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :dataList.length"
                    [records]="filteredList.enable ? filteredList.records : dataList"
                    (onChage)="getPaginatedRecords($event)"></el-caption>

            </div>
        </div>
    </div>
</div>

<el-modal (onClose)="close()" size="xl" title="Solicitud" target="viewLetter">
    <div>
        <div class="row gutter-t">
            <div class="col-md-4">
                <div class="form-group">
                    <label>Fecha Requerida <span class="text-danger">*</span></label>
                    <input type="date" class="form-control form-control-solid form-control-lg" readonly />
                </div>
            </div>
            <div class="form-group col-md-4">
                <label for="startDate">
                    ¿A quién va dirigida? <span class="text-danger">*</span>
                </label>
                <input type="text" class="form-control form-control-solid form-control-lg" id="startDate" required=""
                    readonly />
            </div>
        </div>

        <div class="row gutter-t">
            <div class="form-group col-md-8">
                <label for="startDate">
                    Comentario <span class="text-danger">*</span>
                </label>
                <textarea class="form-control" readonly></textarea>
            </div>
        </div>

        <div class="row gutter-t">
            <div class="form-group col-md-8">
                <button class="btn btn-success">
                    Aprobar
                </button>
                <button class="btn btn-danger ml-3">
                    Rechazar
                </button>
                <button class="btn btn-primary ml-3" data-dismiss="modal">
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</el-modal>


<export-data-to-pdf [title]="'Solicitudes de Servicios'" id="dataToPrint">
    <ng-container thead>
        <th>Servidor Público</th>
        <th>Documento Identidad</th>
        <th>Unidad organizativa</th>
        <th>Servicio Solicitado</th>
        <th class="text-left">Fecha de Solicitud</th>
        <th class="text-left">Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let item of recordsPaginated">
            <td class="text-left">{{item.name}}</td>
            <td class="text-left">{{item.personalIdentification | mask: '000-0000000-0'}}</td>
            <td class="text-left">{{item.departmentName}}</td>
            <td class="text-left">{{item.description}}</td>
            <td class="text-left">{{item.applicationDate | date: 'dd/MM/yyyy'}}</td>
            <td class="text-left">
                <span *ngIf="item.type == 3">
                    <!-- carta laboral -->
                    <ng-container *ngIf="item.conditionId == 1">
                        <span class="font-weight-bold text-primary">{{item.status}}</span>
                    </ng-container>
                    <ng-container *ngIf="item.conditionId == 3">
                        <span class="font-weight-bold text-success">{{item.status}}</span>
                    </ng-container>
                </span>
                <span *ngIf="item.type != 3">
                    <ng-container *ngIf="item?.conditionId === 0">
                        <span class="font-weight-bold">Anulado</span>
                    </ng-container>
                    <ng-container *ngIf="item?.conditionId === 6">
                        <span class="font-weight-bold text-warning">En Espera de Aprobación</span>
                    </ng-container>
                    <ng-container *ngIf="item?.conditionId === 2">
                        <span class="font-weight-bold text-success">Aprobado</span>
                    </ng-container>
                    <ng-container *ngIf="item?.conditionId === 3">
                        <span class="font-weight-bold text-danger">Rechazado</span>
                    </ng-container>
                </span>
            </td>
        </tr>
    </ng-container>
</export-data-to-pdf>