export enum ReportCode {
    TeacherPerHoursList = 'List_TeacherPerHours',
    EmployeeList = 'Exp_EmployeeList',
    ChangeSupervisor = 'Exp_EmployeeMassloadingList',
    EmployeesActive = 'List_ServerActive',
    TeacherPerHoursIncomplete = 'List_TeacherPerHoursIncomplete',
    RequisitionMedications = 'Exp_RequisitionMedications',
    ReviewAutorizePlanning = 'ReviewAutorizePlanning',
    ReviewDetailPlaning = 'ReviewDetailPlaningPaginated',
    SituationSummaryPlanning = 'SituationSummaryPlanning',
    ExpListOpenPlanning = 'ExpListOpenPlanning',
    DetailPlaning = 'DetailPlaning',
    PositionPlanning = 'PositionPlanning',
    ExpListChargerPlanning = 'ExpListChargerPlanningPaginated',
    ExpListApproveChargerPlanning = 'ExpListApproveChargerPlanning2',
    Exp_OrginazacionalUnit = 'Exp_OrginazacionalUnit',
    ApplyVacancies = 'ExpList_Vacancies',
    PositionListDescription = 'Exp_ListDescriptionJob',
    NoObjection = 'ExpListNoObjection',
    JobOffer = 'ExportListLinkage',
    GeneratePlanning = 'ExpList_GeneratePlanning',
    PayrollFile = 'Exp_ReportPayrollFiled',
    AuditApprovalPayroll = 'Exp_ReportPayrollAudited',
    PayrollNews = 'Exp_PayrollNews',
    GeneratePayroll = 'Exp_PayrollNewsGenerate',
    Generatereports = 'Exp_ListReportPayrolls',
    Period = 'Exp_ReportPayrollPeriod',
    FinalPayroll = 'ListPayrollEnd',
    NormalPayroll = 'ListPayroll',
    ReportPayrollBudgetArea = 'Exp_ReportPayrollBudgetArea',
    ReportPayrollBudgetArea2 = 'Exp_ReportPayrollBudgetArea2',
    NewsConceptId = 'Exp_ReportPayrollConcept',
    AdditionalPayroll = 'ListPayrollAditional',
    AdditionalPayrollDetail = 'ListPayrollAditionalDetail',
    ServiceComission = 'rpt_Sevicecommission',
    UnitMonitoring = 'Rpt_GetReportMonitoringPerformaceAgrements',
    ReportAccountCertification = 'Exp_ReportAccountCertification2',
    JobReport = 'JobApllied',
    PaymentVoucherOverTime = "PaymentVoucherOverTime",
    Positions = 'Exp_ListJob',
    Open_Payroll = 'ListPayrollAditional',
    EmployeeData = 'DataPublicServer',
    CollectiveVacations = 'Exp_collectiveVacatinosHeader',
    ChangesServertype = 'rpt_ChangesServertype',
    medicalJourney = 'Medical-JourneyPaginated',
    BankAccounts = 'ListEmployeeAccount',
    medicalConsultation = 'Medical-ConsultationPaginated',
    EmployeeincompleteList = 'Exp_EmployeeincompleteList',
    MedicalRecord = 'MedicalRecord',
    ListPayrollEnd = 'ListPayrollEnd',
    List_TeacherPerHoursContract = 'List_TeacherPerHoursContract',
    PerformanceEvaluation = 'Rpt_PerformaceEvaluaction',
    OrganizationalImprovementPlan = 'Rpt_OrganizationalUnitPlanImprovement',
    UniqueOrganizationalUnitPlanImprovement = 'OrganizaorganizationalUnitPlanImprovement',
    ListPayroll = 'ListPayroll',
    ListRoyaltyPayroll = 'ListRoyaltyPayroll',
    ApplicantsTestResult = 'HistoryTestForm',
    PhysicalInventoryHeader = 'PhysicalInventoryHeader',
    Medication_Existence = 'Medication-Existence',
    CollectiveVacationsDetail = 'Exp_CollectiveVacationsDetail',
    ListOpenPlanningPaginated = 'ExpListOpenPlanningPaginated',
    inventory_movements = 'inventory-movements',
    DetailPlanningCurrent = 'ReviewAutorizePlanningNow2',
    DetailPlannedPlanning = 'ExpList_GetRequestPlanned2',
    Competencies_Dictionary = 'Rpt_CompetencyDictionary',
    Training_Group = 'training-group',
    Resume = 'Resume',
    MemberInclude = 'MemberInclude',
    MemberExclude = 'MemberExclude',
    ReportPayrollConceptBeneficiary = 'Exp_ReportPayrollConceptBeneficiaryPaginated',
    ReportPayrollConceptHistoryPaginated = 'Exp_ReportPayrollConceptHistory',
    PersonalRequisitionHistory = 'Exp_PersonalRequisitionHistory',
    DisciplinaryActionReport = 'EmployeeDisciplinaryActions',
    Content_Management = 'ExportListNews',
    Social_Media = 'NewsLink',
    Employee_Health = 'Employee-health',
    PerformanceAgreementForm = 'PerformanceagreementForm',
    RelevantBehaviourReport = 'BehaviorrelevantForm',
    PerformaceMonitoringForm = 'PerformaceMonitoringForm',
    TrainingReports = 'training-reports',
    EmployeesQualificationAgreement = 'Rpt_EmployeesQualificationAgreement',
    rrhh_PaymentForm = 'rrhh_PaymentForm',
    CompetencyEvaluatioForm = 'CompetencyEvaluationForm',
    PersonalImprovementPlan = 'ImprovementPlan',
    EfficacyEvaluation = 'rpt_EfficacyEvaluation',
    Emplpoyee_Birthday = 'rrhh_employees_birthdaypaginated',
    ExportOffense = 'ExportOffense',
    EmployeesRelationshipAgreement = 'Rpt_EmployeesRelationshipAgreement',
    PerformanceDashboardReport = 'Performance-assessment',
    ExternalService = 'rpt_ExternalService',
    Permission = 'rpt_Permission',
    SheduleVacations = 'Exp_SheduleVacations',
    VacationProgrammingReport = 'Exp_VacationProgrammingReport',
    ReportEmployeesHistoryPaginated = 'Exp_EmployeeHistoryList',

}
