import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-reason-reject',
  templateUrl: './view-reason-reject.component.html',
  styleUrls: ['./view-reason-reject.component.css']
})
export class ViewReasonRejectComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<ViewReasonRejectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { reasonReject: string }) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

}
