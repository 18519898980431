import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EmployeesService } from 'src/app/services/employees.service';
import { ExportToExcelService } from 'src/app/shared/export-to-excel/export-to-excel.service';

@Component({
  selector: 'app-personnel-actions-employee-list',
  templateUrl: './personnel-actions-employee-list.component.html',
  styleUrls: ['./personnel-actions-employee-list.component.css']
})
export class PersonnelActionsEmployeeListComponent implements OnInit {

  tableFilter = '';
  employeeFilter: any = '';
  employees: any[] = [];
  paginatedEmployees: any[] = []
  
  constructor(private employeeService: EmployeesService, private _toastService: ToastrService,
    private _exlService: ExportToExcelService, private _cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getEmployees()
  }


  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  
  filterEmployee() {
    /* this.personList = this.personListOriginal;
    if (!isNaN(this.searchTextFilter) && this.searchTextFilter.toString().length > 0) {
      this.personList = this.personList.filter(x => x.personalIdentification.indexOf(Number(this.searchTextFilter)) !== -1)
    } else if (this.searchTextFilter.toString().length > 0) {
      this.personList = this.personList.filter(x => x.firstName.toLowerCase().includes(this.searchTextFilter.toLowerCase()))
    } */
  }


  getEmployees() {
    this.employeeService.getEmployees().subscribe((res: any) => {
      if(res.errors.length > 0){
        this._toastService.error("Error al obtener los Servidores Públicos")
        return;
      }
      this.employees = res.dataList
    },
      err => this._toastService.error(err))
  }

  ApplySearch() { }

  getPaginatedRecords(event) {
    this.paginatedEmployees = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  refreshTable(){

  }

}
