import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-employee-transactions-modal',
  templateUrl: './employee-transactions-modal.component.html',
  styleUrls: ['./employee-transactions-modal.component.css']
})
export class EmployeeTransactionsModalComponent implements OnInit {

  form: FormGroup;
  


  constructor(
    public dialogRef: MatDialogRef<EmployeeTransactionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      employNumber: [''],
      personalIdentification: [''],
      nss: [''],
      firstName: [''],
      lastName: [''],
      birthday: [''],
      gender: [''],
      relationship: ['']
    })
  }
  Accept(): void {
    const row = { Applied: true, item: this.form.value }
    this.dialogRef.close(row);
  }

  Cancel(): void {
    const row = { Applied: false, item: null }
    this.dialogRef.close(row);
  }

}
