<div class="container-fluid ">
    <div class="card card-custom">
        <div class="card-header mt-3  d-flex justify-content-between">
            <div class="card-title ">
                <div class="ttl d-flex align-items-center ">
                    <i class="flaticon-proceso-2 icon-3x text-danger mr-2 forward-btn"></i>
                    <h1 class="text-primary forward-btn">Reporte de Horas Extras Individual</h1>
                </div>
            </div>
            <div class="card-toolbar">
                <button (click)="goBack()" class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
                    <i class="flaticon2-left-arrow-1"></i>
                    <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
                </button>
            </div>
        </div>
        <div class="card-header row justify-content-between p-2 mx-0 ">

            <div class="col-sm-12 col-md-8 c-left forward-btn">
                <div class="card-toolbar d-flex justify-content-start">
                    
                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2">
                            <label class="text-primary">
                                Período<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown (change)="searchEmployees()" [(ngModel)]="periodSelected"
                                    [options]="periods" [config]="dropdownConfigDescription" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-sm-12 col-md-4 c-right mr-0 forward-btn">
                <div class="card-toolbar d-flex justify-content-end">
                    <a data-toggle="tooltip" data-original-title="Generar Reporte" (click)="openReport()"
                        class="btn btn-success btn-pill font-weight-bolder mt-9 mx-2 col-md-auto ">
                        <i class="flaticon2-search-1 icon-2x"></i>
                        Generar Reporte
                    </a>
                </div>
            </div>
        </div>
        <div class="card-body">

            <div class="table-responsive table-responsive-sigei">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-center">Secuencia</th>
                            <th class="text-left min-w-150px">Código de Servidor Público</th>
                            <th class="text-left">Servidor Público</th>
                            <th class="text-left">Tipo de Documento</th>
                            <th class="text-left min-w-160px">Documento de Identidad</th>
                            <th class="text-left min-w-160px">Sexo</th>
                            <th class="text-left min-w-160px">Fecha de Ingreso</th>
                            <th class="text-left">Sucursal</th>
                            <th class="text-left">Unidad Organizativa</th>
                            <th class="text-left">Cargo</th>
                            <th class="text-left min-w-150px">Teléfono Primario</th>
                            <th class="text-left">Tipo de Servidor Público</th>
                            <th class="text-left">Categoría de Servidores Públicos</th>
                            <th class="text-left">Tanda</th>
                            <th class="text-left">Condición Actual</th>
                            <th class="text-left">Estatus</th>
                            <th class="actionsBtn">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let employee of employees">
                            <td class="text-center">
                                {{employee.employeeId}}
                            </td>
                            <td class="text-left">{{employee.employNumber}}</td>
                            <td class="text-left">{{employee.name}}</td>
                            <td class="text-left">{{employee.typeIdentification}}</td>
                            <td class="text-left" *ngIf="employee.typeIdentificationId == 1">
                                {{employee.personalIdentification | mask: '000-0000000-0'}}</td>
                            <td class="text-left" *ngIf="employee.typeIdentificationId != 1">
                                {{employee.personalIdentification}}</td>
                            <td class="text-left">{{employee.sex}}</td>
                            <td class="text-left">{{employee.admissionDate |date:'dd/MM/yyyy'}}</td>
                            <td class="text-left">{{employee.branch}}</td>
                            <td class="text-left">{{employee.departmentName}}</td>
                            <td class="text-left">{{employee.positionName}}</td>
                            <td class="text-left">{{employee.phoneNumber | mask: "(000) 000-0000"}}</td>
                            <td class="text-left">{{employee.employeeType}}</td>
                            <td class="text-left">{{employee.contractType}}</td>
                            <td class="text-left">{{employee.proposedSchedule}}</td>
                            <td class="text-left">
                                <ng-container *ngIf="employee?.employeeAction">
                                    {{employee?.employeeAction}}
                                </ng-container>
                                <ng-container *ngIf="employee?.employeeAction === undefined">
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </ng-container>
                            </td>
                            <td class="text-center">
                                <span *ngIf="employee.status" class="font-weight-bold text-success">Activo</span>
                                <span *ngIf="!employee.status && !employee.wasHiringCanceled" class="font-weight-bold text-danger">Desvinculado</span>
                                <span *ngIf="!employee.status && employee.wasHiringCanceled" class="font-weight-bold text-danger">Nombramiento Anulado</span>
                            </td>
                            <td class="text-left">
                                <div class="d-flex">
                                    
                                    <div class="col px-0 min-w-36">
                                        <a (click)="showReport(employee)"
                                            class="btn btn-icon btn-light-info btn-sm m-1" data-toggle="tooltip"
                                            title="Abrir Reporte">
                                            <i class="flaticon-ver"></i>
                                        </a>
                                        
                                    </div>
                                    
                                </div>
    
    
    
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <div>
                        <app-paginator [listedRecordsLength]="employees?.length"
                            [registerQuantity]="employeesPagedResut.totalItems"
                            [registerPerPage]="employeesPagedResut.pageSize" [totalPages]="employeesPagedResut.totalPages"
                            [selectedPage]="employeesPagedResut.page" (onChange)="getPaginatedRecords($event)">
                        </app-paginator>
                    </div>
                </div>
            </div>
    
</div>