import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulk-load-recurring-news',
  templateUrl: './bulk-load-news.component.html',
  styleUrls: ['./bulk-load-news.component.css']
})
export class BulkLoadNewsComponent implements OnInit {
  formIsInvalid = false
  locations: any[] = [];
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  filterText:string = '';
  paginatedEmployees: any[] = [];
  selectedItems:any;
  employees: any[] = [];
  employeesListOriginal: any[] = [];

  form: any = {
    department: '',
    enclosure: '',
    position: '',
    requisitionType: '',
    vacancyCause: '',
    workingHours: '',
    academicLevel: ''
  }

  public identificationImage = '';
  public file = {
    photo: '',
    cv: '',
    criminalRecord: '',
    medicalCertificate: '',
    identificationImage: '',
    driveLicenseImage: '',
    miscellaneous: ''
  }


  constructor() { }

  ngOnInit(): void {
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  filter(){
    document.getElementById('searchBarBtn').click()
    if(this.filteredList.records.length > 0 && this.filterText){
      this.filteredList.records = this.filterTable(this.filteredList.records)
    }else{
      this.employees = this.filterTable(this.employeesListOriginal)
    }
    this.setFilterToLocalStorage();
  }

  setFilterToLocalStorage(){
    localStorage.setItem('employee-list_filter', JSON.stringify(this.selectedItems));
  }


  filterTable(list:any[]){
    let sl = this.selectedItems;
    if(sl.find(x => x.item_id == 1) && !sl.find(x => x.item_id == 2)){
      list = list.filter(x => x.status == true);
    }
    if(sl.find(x => x.item_id == 2) && !sl.find(x => x.item_id == 1)){
      list = list.filter(x => x.status == false);
    }
    return list;
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = this.filterTable(event.records)
  }

  getPaginatedRecords(event) {
    this.paginatedEmployees = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

}
