<div class="card card-custom">
    <div class="card-header mt-3 " style="justify-content: flex-start !important">
        <div class="card-title flex-column align-items-start" style="justify-content: flex-start !important">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-lista-de-verificacion-2 icon-3x text-danger mr-3"></i>
                <h2 class="text-primary"> Directorio Telefónico </h2>
            </div>
        </div>
    </div>
    <div class="card-body">

        <nav>
            <div class="nav nav-tabs border-tabs-primary" id="nav-tab" role="tablist">
                <button  (click)="tabSelected = 'tab1'" class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab"
                    aria-controls="tab1" aria-selected="true">
                    <span class="nav-icon mr-5">
                        <i class="flaticon-proceso icon-3x"></i>
                    </span>
                    <span class="nav-text">
                        <h3>Unidades Organizativas</h3>
                    </span>
                </button>
                <!--button (click)="tabSelected = 'tab2'" class="nav-link border-tabs-primary" id="tab2-tab" data-toggle="tab"
                    href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">
                    <span class="nav-icon mr-5">
                        <i class="flaticon-lista-de-verificacion icon-3x"></i>
                    </span>
                    <span class="nav-text">
                        <h3>Servidores Público</h3>
                    </span>
                </button-->
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="tab1" role="tabpanel"
                aria-labelledby="tab1-tab">
                <app-department-tab *ngIf="tabSelected=='tab1'"></app-department-tab>
            </div>
            <!--div class="tab-pane fade" id="tab2" role="tabpanel"
                aria-labelledby="tab2-tab">
                <app-employees-tab *ngIf="tabSelected=='tab2'"></app-employees-tab>
            </div-->
        </div>
    </div>
</div>