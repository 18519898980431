import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.component.html',
  styleUrls: ['./add-email.component.css']
})
export class AddEmailComponent implements OnInit {

  emailTypeList: any[] = []
  principal = { yes: 1, no: 2 }
  emailList: any[] = []
  showEmailError:boolean = false; //Variable para mostrar el mensaje de error del correo

  emailForm: FormGroup = new FormGroup({
    emailType: new FormControl(null, Validators.required),
    email: new FormControl(null, Validators.required),
    isPrimary: new FormControl(null, Validators.required),
    extension: new FormControl(null),
  })

  constructor(
    public dialogRef: MatDialogRef<AddEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _toast: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    this.dialogRef.disableClose = true;
    this.emailList = data.emailList
  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.emailTypeList = this.data.emailTypeList
    if (this.data.isEditing) {
      this.getemailInfo()
      return
    }
  }

  getemailInfo() {
    const { emailInfo } = this.data
    this.emailForm.patchValue({
      emailType: this.emailTypeList.find((type: any) => type.ocode === emailInfo.type),
      email: emailInfo.description,
      isPrimary: emailInfo.principal,
      extension: emailInfo.emailExtension
    })
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  Cancel() {
    this.dialogRef.close()
  }

  validateEmail(email): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isEmailValid() {
    const email = this.emailForm.get('email')?.value
    const valid = this.validateEmail(email?.trim());
    this.showEmailError = !valid;
    this.changeDetector();
  }

  changeDetector() {
    this.cdr.detectChanges();
  }

  Accept() {

    this.isEmailValid();

    if (this.emailForm.invalid || this.showEmailError) {
      this._toast.warning('Debe completar los campos obligatorios')
      return
    }

    let existingEmail = this.emailList.some((email: any) => email.description === this.emailForm.get('email').value)
    if (existingEmail) {
      this._toast.warning('Este correo electrónico ya está agregado')
      return
    }

    let emailInfo = {
      ...this.emailForm.value
    }
    this.dialogRef.close(emailInfo)
  }

}
