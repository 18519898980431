import { OperationResult } from './../../../../shared/models/operation-result.enum';
import { ScheduleComponent } from '@payroll/index';
import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { PayrollPeriodService } from 'src/app/services/payroll-period.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { DateRestrictions, OpeningOrClosingRestrictions, StartOrEndRestrictions } from '../models/date-restriction.model';
import { Period } from '../models/period.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AuthInfoService } from 'src/app/services/auth-info.service';

@Component({
  selector: 'add-modal',
  templateUrl: './add-modal.component.html',
  styleUrls: ['./add-modal.component.css']
})


export class AddModalComponent implements OnInit {
  periodForm: FormGroup;
  startOrEndRestrictions: StartOrEndRestrictions = {
    startRestriction: null,
    endRestriction: null,
  }
  openingOrClosingRestrictions: OpeningOrClosingRestrictions = {
    startRestriction: null,
    endRestriction: null,
  }
  dateRestrictions: DateRestrictions = {
    startOrEndRestrictions: {
      startRestriction: null,
      endRestriction: null,
    },
    openingOrClosingRestrictions: {
      startRestriction: null,
      endRestriction: null
    }
  };

  title ='Agregar'

  periodDescriptionData: { monthNumber: number, month: string }[] = [
    { monthNumber: 1, month: "Enero" },
    { monthNumber: 2, month: "Febrero" },
    { monthNumber: 3, month: "Marzo" },
    { monthNumber: 4, month: "Abril" },
    { monthNumber: 5, month: "Mayo" },
    { monthNumber: 6, month: "Junio" },
    { monthNumber: 7, month: "Julio" },
    { monthNumber: 8, month: "Agosto" },
    { monthNumber: 9, month: "Septiembre" },
    { monthNumber: 10, month: "Octubre" },
    { monthNumber: 11, month: "Noviembre" },
    { monthNumber: 12, month: "Diciembre" },
  ]

  constructor(
    private _builder: FormBuilder,
    private _toastr: ToastService,
    public dialogRef: MatDialogRef<ScheduleComponent>,
    // private _sweetAlrt: SweetAlert,
    private _periodSvc: PayrollPeriodService,
    private authInfo: AuthInfoService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.periodForm = this.buildPeriodForm();
    if (this.data) {
      this.patchForm(this.data);
      this.title = 'Editar'
    }
  }

  buildPeriodForm(): FormGroup {
    return this._builder.group({
      payrollPeriodId: [0],
      periodId: [0, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      description: [{ value: null, disabled: true }, [Validators.required]],
      sendLimitDate: [null, [Validators.required]],
      openingDate: [null, [Validators.required]],
      conditionId: [0],
      status: [true],
      companyId: [this.authInfo.getCompanyId()],
      createDate: [formatDate(new Date(), 'yyyy-MM-dd', 'en-US')],
      createUserId: [this.authInfo.getUserId()],
      modifyDate: [null],
      modifyUserId: [0],
      action: ["save"]
    });
  }

  patchForm(model: Period) {
    this.periodForm.patchValue({
      payrollPeriodId: model.payrollPeriodId,
      periodId: model.periodId,
      startDate: formatDate(new Date(model.startDate), 'yyyy-MM-dd', 'en-US'),
      endDate: formatDate(new Date(model.endDate), 'yyyy-MM-dd', 'en-US'),
      description: model.description,
      sendLimitDate: formatDate(new Date(model.sendLimitDate), 'yyyy-MM-dd', 'en-US'),
      openingDate: formatDate(new Date(model.openingDate), 'yyyy-MM-dd', 'en-US'),
      conditionId: model.conditionId,
      status: model.status,
      companyId: model.companyId,
      createDate: formatDate(new Date(model.createDate), 'yyyy-MM-dd', 'en-US'),
      createUserId: model.createUserId,
      modifyDate: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
      modifyUserId: this.authInfo.getUserId(),
      action: "update"
    })
    // this.periodForm.disable();
  }

  calendarFixedDateScope(dateControlName: string) {
    let startDate = this.periodForm.get('startDate').value
    let openingDate = this.periodForm.get('openingDate').value
    let dateParts;
    if (this.periodForm.get('action').value == 'update') {
      this.periodForm.get('description').patchValue(this.getSelectedMonth(startDate));
      this.periodForm.get('sendLimitDate').patchValue(null);
      this.periodForm.get('openingDate').patchValue(null);
    }
    if (dateControlName == 'endDate') {
      this.periodForm.get('endDate').patchValue(null);
      this.periodForm.get('sendLimitDate').patchValue(null);
      this.periodForm.get('openingDate').patchValue(null);
      dateParts = (this.periodForm.get('startDate').value as string).split("-");
      dateParts[2] = new Date(dateParts[0], dateParts[1], 0).getDate() // Esto busca el último día del mes seleccionado
      let date = `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;

      this.periodForm.get('endDate').patchValue(date)
      let dateStartRestriction = formatDate(new Date(startDate), 'yyyy-MM-dd', 'en');
      let dateEndRestriction = formatDate(new Date(Number(dateParts[0]), Number(dateParts[1]), 0), 'yyyy-MM-dd', 'en-Us');

      let restriction = {
        startRestriction: dateStartRestriction,
        endRestriction: dateEndRestriction
      };
      return restriction;
    } else if (dateControlName == 'sendLimitDate') {
      this.periodForm.get('sendLimitDate').patchValue(null);
      this.periodForm.get('openingDate').patchValue(null);
      let dateEndRestriction = formatDate(new Date(this.periodForm.get('endDate').value), 'yyyy-MM-dd', 'en-Us');

      let restriction = {
        startRestriction: null,
        endRestriction: dateEndRestriction
      }
      return restriction;
    }

  }

  setCloseDate() {
    let dateParts = (this.periodForm.get('openingDate').value as string).split("-");
    let date = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]) + 5);
    let a = formatDate(date, 'yyyy-MM-dd', 'en');
    this.periodForm.get('sendLimitDate').patchValue(a);
  }

  correctOrderDatesValidation(startDate, endDate): boolean {
    endDate = new Date(endDate);
    startDate = new Date(startDate);
    let timediff = endDate - startDate;
    if (isNaN(timediff)) return false;
    else if (moment(startDate) >= moment(endDate)) {
      this._toastr.error('La fecha de inicio debe ser menor a la fecha de finalización');
      return false;
    } else {
      return true;
    }
  }

  openAndCloseDatesValidation(openingDate, sendLimitDate): boolean {
    let startDate = this.periodForm.get('startDate').value;
    let endDate = this.periodForm.get('endDate').value;
    // debugger;
    if ((this.correctOrderDatesValidation(openingDate, sendLimitDate) &&
      this.correctOrderDatesValidation(startDate, endDate)
    )) {

      let openingValidation = (new Date(openingDate) >= new Date(startDate) && new Date(openingDate) <= new Date(endDate));
      let closingValidation = (new Date(endDate) >= new Date(sendLimitDate) && new Date(startDate) <= new Date(sendLimitDate));


      return (openingValidation && closingValidation);
    }
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  bodyFormToModel(body): Period {
    let model: Period = {
      payrollPeriodId: body.payrollPeriodId,
      periodId: body.periodId,
      description: body.description,
      sendLimitDate: body.sendLimitDate,
      conditionId: body.conditionId,
      status: body.status,
      companyId: body.companyId,
      createDate: body.createDate,
      createUserId: body.createUserId,
      modifyDate: body.modifyDate,
      modifyUserId: body.modifyUserId,
      startDate: body.startDate,
      endDate: body.endDate,
      openingDate: body.openingDate,
    };
    return model;
  }

  getSelectedMonth(date): string {
    let dateParts = (date as string).split("-");
    let year = Number(dateParts[0])
    let { month } = this.periodDescriptionData.find(x => x.monthNumber == Number(dateParts[1]));
    return `${month} ${year}`
  }

  saveForm() {
    new Promise((res, rej) => {
      if (!this.openAndCloseDatesValidation(
        this.periodForm.get('openingDate').value,
        this.periodForm.get('sendLimitDate').value
      )) {
        this._toastr.error('Las fechas de inicio y cierre deben estar dentro del mes seleccionado.')
        return;
      }
      let body = this.periodForm.value;
      body.description = this.getSelectedMonth(body.startDate);
      return res({ action: body.action, model: this.bodyFormToModel(body) });

    }).then((form: any) => {
      form.model.userName = this.authInfo.getFullUserName()
      if (form.action == "save") {
        this.createPeriod(form.model);
        return;
      }
      if (form.action == "update") {
        this.updatePeriod(form.model);
      }
    });

  }

  createPeriod(model: Period) {
    this._periodSvc.post(model).subscribe(res => {
      if (res.errors.length > 0) { this._toastr.error(res.errors[0]); return; }
      this.accept();
    }, err => this._toastr.error(err))
  }

  updatePeriod(model: Period) {
    this._periodSvc.put(model).subscribe(res => {
      if (res.errors.length > 0) { this._toastr.error("Error al actualizar el periodo"); return; }
      this.accept();
    }, err => this._toastr.error(err))
  }

  accept(): void {

    this.dialogRef.close({ status: OperationResult.Succeeded });
  }

  cancel(): void {
    this.dialogRef.close({ status: OperationResult.Canceled });
  }

}

