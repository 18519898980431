<div class="card card-custom">
  <div class="card-header mt-3">
      <div class="card-title">
          <span class="card-icon mr-5">
              <i class="flaticon-contratacion icon-3x text-danger"></i>
          </span>
          <h1 class="d-flex flex-column">
              Certificación de Fondos
              <!-- <small>Resultados de Pruebas</small> -->
          </h1>
      </div>
      <!-- <div class="card-toolbar">
      </div> -->
  </div>
  <div class="card-body">

    <div class="row gutter-t gutter-b">
      <div class="col-md-12">
          <div class="card card-custom border-primary">
              <!-- <div class="card-header mt-3">
                  <div class="card-title align-items-center">
                      <i class="flaticon2-time icon-2x text-primary mr-3"></i>
                      <h2 class="text-primary m-0">Historial de Pruebas Aplicadas

                      </h2>
                  </div>
                  <div class="card-toolbar ml-auto"></div>
              </div> -->

              <div class="card-body p-0">

                  <div class="table-responsive">
                      <table class="table mt-2 table-vertical-center text-center">
                          <thead>
                              <tr class="bg-primary">
                                  <th class="max-w-50px">Tipo de solicitud</th>
                                  <th>Solicitadas</th>
                                  <th class="max-w-200px">Rechazadas</th>
                                  <th>Aprobadas</th>
                                  <th class="actionsBtn">Pendientes</th>
                              </tr>
                          </thead>
                            <style>
                              .setColors>tr>td:nth-child(2){
                                color: blue;
                              }
                              .trr>tr>td:nth-child(3){
                                color: red;
                              }
                              .trr>tr>td:nth-child(4){
                                color: green;
                              }
                              .trr>tr>td:nth-child(5){
                                color: gray;
                              }
                            </style>
                          <tbody style="font-size: large;" class="setColors">
                            <tr *ngFor="let item of tableData">
                                <td>{{item.type}}</td>
                                <td class="">{{item.requested}}</td>
                                <td>{{item.rejected}}</td>
                                <td>{{item.approved}}</td>
                                <td>{{item.pending}}</td>
                            </tr>
                            <tr *ngIf="tableData.length>0">
                              <td>Total</td>
                              <td>264</td>
                              <td>34</td>
                              <td>220</td>
                              <td style="cursor: pointer;" (click)="fillTable()">10</td>
                            </tr>

                          </tbody>
                      </table>
                      <div class="d-flex justify-content-end align-items-center flex-wrap">
                          <div class="d-flex align-items-center py-3 mr-2"><select class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light ng-untouched ng-pristine ng-valid" style="width: 75px;" ng-reflect-model="10">
                                  <option value="1" ng-reflect-value="1">1</option>
                                  <option value="5" ng-reflect-value="5">5</option>
                                  <option value="10" ng-reflect-value="10">10</option>
                                  <option value="15" ng-reflect-value="15">15</option>
                                  <option value="20" ng-reflect-value="20">20</option>
                              </select><span class="text-muted">Mostrando página 1 de 1 </span></div>
                          <div class="d-flex flex-wrap py-2 mr-3">
                              <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                                  <i class="ki ki-bold-arrow-back icon-xs"></i></button>
                              <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                                  <i class="ki ki-bold-arrow-next icon-xs"></i></button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>




      <!-- Tabla -->

      <ng-container *ngIf="showTable">
      <div class="separator separator-solid separator-primary gutter-b gutter-t"></div>
      <div class="row gutter-t gutter-b">
          <div class="col-md-12">

              <div class="card card-custom border-primary">
                  <div class="card-header mt-3">
                      <div class="card-title align-items-center">
                          <i class="flaticon2-time icon-2x text-primary mr-3"></i>
                          <h2 class="text-primary m-0">Validar Solicitud de Fondo
                          </h2>
                      </div>
                      <div class="card-toolbar ml-auto"></div>


                      <div class="text-right">
                        <select class="form-select">
                          <option>Instituciones</option>
                        </select>
                      </div>

                  </div>

                  <div class="card-body p-0">
                      <div class="table-responsive">
                          <table class="table mt-2 table-vertical-center text-center">
                              <thead>
                                  <tr class="bg-primary">
                                      <th>Número de remisión</th>
                                      <th>Detalles de caso</th>
                                      <th>Fecha Solicitud</th>
                                      <th>Tipo</th>
                                      <th>Partida Presupuesto</th>
                                      <th>Monto</th>
                                      <th class="actionsBtn">Acciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let data of fundRequestData; let index = index">
                                      <td>{{data.remission}}</td>
                                      <td>{{data.detail}}</td>
                                      <td>{{data.requestDate | date:'dd/MM/yyyy' }}</td>
                                      <td>{{data.type}}</td>
                                      <td>{{data.estimate}}</td>
                                      <td>{{data.amount}}</td>
                                      <td>
                                        <button data-toggle="tooltip" title="Ver" placement="top"
                                            class="btn btn-sm btn-light-skype btn-icon mr-2">
                                            <i class="flaticon-ver icon-2x"></i>
                                        </button>
                                        <button data-toggle="tooltip" title="Certificar" placement="top"
                                            class="btn btn-sm btn-light-success btn-icon mr-2">
                                            <i class="flaticon-check icon"></i>
                                        </button>
                                          <button data-toggle="tooltip" title="Rechazar" placement="top" class="btn btn-sm btn-light-danger btn-icon">
                                            <i class="flaticon2-cross"></i>
                                          </button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <div class="d-flex justify-content-end align-items-center flex-wrap">
                              <div class="d-flex align-items-center py-3 mr-2"><select class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light ng-untouched ng-pristine ng-valid" style="width: 75px;" ng-reflect-model="10">
                                      <option value="1" ng-reflect-value="1">1</option>
                                      <option value="5" ng-reflect-value="5">5</option>
                                      <option value="10" ng-reflect-value="10">10</option>
                                      <option value="15" ng-reflect-value="15">15</option>
                                      <option value="20" ng-reflect-value="20">20</option>
                                  </select><span class="text-muted">Mostrando página 1 de 1 </span></div>
                              <div class="d-flex flex-wrap py-2 mr-3">
                                  <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                                      <i class="ki ki-bold-arrow-back icon-xs"></i></button>
                                  <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                                      <i class="ki ki-bold-arrow-next icon-xs"></i></button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </ng-container>


  </div>
</div>
