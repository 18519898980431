<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
<h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Buscar Servidor Público</h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>

</div>
<div class="mat-dialog-content-custom">
    <div class="card card-custom">
        <!--div class="card-header mt-5 row justify-content-between">
            <div class="col-sm-6 c-left">
                <div class="card-toolbar d-flex justify-item-center">
                    <input type="checkbox" class="check-size me-3">
                    <label class="h6">Solo Docentes</label>
                </div>
            </div>
            <div class="col-sm-6 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="col text-nowrap">
                        <el-search-bar #searchBar [records]="employeesListOriginal" (onKeyChange)="onKeyChange($event)"
                        [keyValue]="filterText" [itemForSearching]="searchBarDisplayNames"
                        (onChange)="getFilteredList($event)" 
                        [itemForSearching]="searchBarDisplayNames"></el-search-bar>
                    </div>
                </div>
            </div>
        </div-->

        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12  mt-5  col-md-7 c-left">
                <div class="card-toolbar mt-5 d-flex justify-item-center">
                    <div class="col-md-3 mt-4 ml-1 px-0 pt-1"  *ngIf="!data?.hideInputTeacher">
                        <input type="checkbox" [(ngModel)]="byTeacher" (change)="selectTeachers($event?.target)" class="check-size me-3">
                        <label class="h6">Solo Docentes</label>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-5 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="col text-nowrap">
                        <app-search-bar-pagination (onFilter)="filterSearch($event)" [parameters]="searchBarDisplayNames"></app-search-bar-pagination>
                    
                    </div>
                </div>
            </div>
        </div>

    
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-left">Secuencia</th>
                            <th class="text-left">Código del Servidor Público</th>
                            <th class="text-left">Servidor Público</th>
                            <th class="text-left">Documento de Identidad</th>
                            <th class="text-left">Unidad Organizativa</th>
                            <th class="text-left">Cargo</th>             
                            <th class="text-center">Estatus</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let employee of employees">
                            <tr class="select-employee" (click)="selectEmployee(employee)"  (dblclick)="doubleClick(employee)"
                                [ngClass]="{'selected-employee bg-primary': isEmployeeSelected(employee)}">
                                <td class="text-left">
                                    {{employee.employeeId}}
                                </td>
                                <td class="text-left">{{employee.employNumber}}</td>
                                <td class="text-left">{{employee.name}}</td>
                                <td class="text-left">{{employee.personalIdentification}}</td>
                                <td class="text-left">{{employee.departmentName}}</td>
                                <td class="text-left">{{employee.positionName}}</td>                 
                                <td class="text-center">
                                    <span *ngIf="employee.status" class="font-weight-bold text-success"
                                        [ngClass]="{'estatus': isEmployeeSelected(employee)}">Activa</span>
                                    <span *ngIf="!employee.status" class="font-weight-bold text-danger"
                                        [ngClass]="{'estatus': isEmployeeSelected(employee)}">Inactiva</span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div>
                    <!-- <el-caption #paginator [originalRecordsLength]="employeesListOriginal?.length" [listedRecordsLength]="paginatedEmployees?.length"
                    [registerQuantity]="filteredList?.enable && filteredList.records?.length>0 ? filteredList.records?.length :employees?.length"
                    [records]="filteredList?.enable ? filteredList?.records : employees"
                    (onChage)="getPaginatedRecords($event)"></el-caption> -->
                    
                    <app-paginator
                    [listedRecordsLength]="employees?.length"
                    [registerQuantity]="employeesPagedResut.totalItems"
                    [registerPerPage]="employeesPagedResut.pageSize"
                    [totalPages]="employeesPagedResut.totalPages"
                    [selectedPage]="employeesPagedResut.page"
                    (onChange)="getPaginatedRecords($event)">
                    </app-paginator>
                </div>
            </div>
    
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="!IsViewMode" [disabled]="!selectedEmployee" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
        (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Selecionar</span>
    </button>
</div>