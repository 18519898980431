<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <button type="button" class="close" (click)="closeDepartmentModal(false)" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>Seleccione
        una Unidad Organizativa</h2>

</div>
<div class="mat-dialog-content-custom" style="height: 300px !important;">
    <div class="card-body">
        <div *ngFor="let item of levels; let i = index">
            <div class="form-group" *ngIf="item.childData.length !== 0">
                <label class="d-block"></label>
                <ngx-select-dropdown [options]="item.childData" [config]='dropdownConfig("departmentName")'
                    (change)="getDepartmentChlidren($event.value, true, i)">
                </ngx-select-dropdown>
            </div>
        </div>
    </div>
</div>
<div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="closeDepartmentModal(false)">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
        (click)="closeDepartmentModal(true)">
        <i class="flaticon-disco-flexible"></i>
        <span>Agregar</span>
    </button>
</div>