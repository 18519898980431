<form class="row mt-10" [formGroup]="form">
    <div class="col-md-8">
        <div class="row">
            <!-- <div class="col form-group">
                <label for="">Tipos de Falta <span class="text-danger">*</span></label>
                <ngx-select-dropdown formControlName="motive"
                    [config]="motiveDropdownConfig" [options]="motives"></ngx-select-dropdown>
                <span style="position: absolute;" *ngIf="formIsInvalid && form.controls['motive']?.errors?.required"
                    class="text-danger">Este campo es requerido</span>
            </div> -->
    
            <div class="col form-group">
                <label>Falta</label>
                <div class="input-group">
                    <input type="text" placeholder="Escriba aquí su búsqueda" class="form-control form-control-solid form-control-lg ng-untouched ng-pristine ng-valid" ng-reflect-model="">
                    <button id="searchBarBtn" data-toggle="tooltip" title="" class="input-group-text bg-primary" data-original-title="Buscar" data-toggle="tooltip"
                    title="Anular" (click)="openCancelActionModal()">
                        <i class="flaticon2-search-1 icon-2x text-white"></i>
                    </button>
                </div>
            </div>
    
            <div class="col form-group">
                <label for="">Fecha<span class="text-danger">*</span></label>
                <input formControlName="requestDate" type="date" class="form-control">
                <span style="position: absolute;"
                    *ngIf="formIsInvalid && form.controls['requestDate']?.errors?.required" class="text-danger">Este
                    campo es requerido</span>
            </div>

            <div class="col-md-12 form-group">
                <label for="">Comentario <span class="text-danger">*</span></label>
                <textarea formControlName="comment" class="form-control" rows="4"></textarea>
                <span style="position: absolute;"
                    *ngIf="formIsInvalid && form.controls['comment']?.errors?.required" class="text-danger">Este
                    campo es requerido</span>
            </div>
        </div>
    </div>

    <div class="col-md-4">
        <div class="form-group h-100">
            <label>Evidencia <span class="text-danger">*</span></label>
            <div class="card align-items-center h-100-50">
                <div class="card-body">
                    <div [ngClass]="{'d-block': file.document=='', 'd-none' : file.document!=''}">
                        <div class="evidence22"></div>
                    </div>
                    <div *ngIf="file.document !=''">
                        <div class="symbol symbol-150 d250 my-4 max-w-250px">
                            <img alt="Pic" src="assets/images/cv.jpg" />
                        </div>
                        <div class="btn-group center" role="group">
                            <button type="button" class="btn btn-primary btn-icon" data-toggle="modal"
                                data-target="#document">
                                <i class="flaticon-ver"></i>
                            </button>
                            <button type="button" class="btn btn-danger btn-icon" (click)="deleteGuid()" >
                                <i class="flaticon-delete-1"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <span style="position: absolute;"
                *ngIf="formIsInvalid && form.controls['evidence']?.errors?.required"
                class="text-danger">Este campo es requerido</span>
        </div>
    </div>
</form>

<div class="row">
    <div class="form-group d-flex justify-content-end align-items-center">
            <button #saveButtonCs
            class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
            <i class="flaticon-disco-flexible"></i>
            <span>{{isEditingServiceCommision ? 'Guardar Modificación' : 'Guardar'}}</span>
        </button>
    </div>
</div>      

<div class="row gutter-b">
    <h2 class="text-primary gutter-b mb-2 d-flex justify-content-between flex-wrap" >
        Historial de Faltas
    </h2>
    <div class="table-responsive">
        <table class="table atbl table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th class="text-left">Secuencia</th>
                    <th class="text-left">Tipo de Falta</th>
                    <th class="text-left">Fecha</th>
                    <th class="text-left">Descripción</th>
                    <th class="text-left">Supervisor</th>
                    <th class="text-left">Evidencia</th>
                    <th class="text-center">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-left">-</td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td>
                        <a data-toggle="tooltip"
                            title="Evidencia" class="btn btn-light-youtube font-weight-bold mb-3">
                            <i class="flaticon-pdf-file icon-2x"></i>
                        </a>
                    </td>
    
                    <td class="actionsBtn">
                        <a
                            class="btn btn-sm btn-light-info btn-icon mr-2">
                            <i class="flaticon-ver" data-toggle="tooltip" title="Ver"></i>
                        </a>
                        <a
                            class="btn btn-icon btn-light-warning btn-sm m-1">
                            <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                        </a>
                        <a class="btn btn-icon btn-light-danger btn-sm m-1">
                            <i data-toggle="tooltip" title="" class="flaticon-delete" data-original-title="Remover"></i>
                        </a>
                    </td>
                </tr>
    
                <tr>
                    <td class="text-left">-</td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td>
                        <a data-toggle="tooltip"
                            title="Evidencia" class="btn btn-light-youtube font-weight-bold mb-3">
                            <i class="flaticon-pdf-file icon-2x"></i>
                        </a>
                    </td>
    
                    <td class="actionsBtn">
                        <a class="btn btn-sm btn-light-info btn-icon mr-2">
                            <i class="flaticon-ver" data-toggle="tooltip" title="Ver"></i>
                        </a>
                        <a class="btn btn-icon btn-light-warning btn-sm m-1">
                            <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                        </a>
                        <a class="btn btn-icon btn-light-danger btn-sm m-1">
                            <i data-toggle="tooltip" title="" class="flaticon-delete" data-original-title="Remover"></i>
                        </a>
                </tr>
    
                <tr>
                    <td class="text-left">-</td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td>
                        <a data-toggle="tooltip"
                            title="Evidencia" class="btn btn-light-youtube font-weight-bold mb-3">
                            <i class="flaticon-pdf-file icon-2x"></i>
                        </a>
                    </td>
    
                    <td class="actionsBtn">
                        <a class="btn btn-sm btn-light-info btn-icon mr-2">
                            <i class="flaticon-ver" data-toggle="tooltip" title="Ver"></i>
                        </a>
                        <a class="btn btn-icon btn-light-warning btn-sm m-1">
                            <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                        </a>
                        <a class="btn btn-icon btn-light-danger btn-sm m-1">
                            <i data-toggle="tooltip" title="" class="flaticon-delete" data-original-title="Remover"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>