import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ToastrService } from 'ngx-toastr';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ConceptSetting } from '@payroll/news-list/models/PayrollNews.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { NewsConceptGET } from '@payroll/payroll-concept/models';
import { PayrollConceptService } from 'src/app/services/payroll-concept.service';
import { ConceptValues } from 'src/app/shared/models/conceptValues.model';

@Component({
  selector: 'add-additional-settings',
  templateUrl: './additional-settings.component.html',
  styleUrls: ['./additional-settings.component.css']
})
export class AdditionalSettingsComponent implements OnInit, OnChanges {
  formIsInvalid: boolean = false;
  form: FormGroup;
  valueTypes: any[] = [];
  @Input() newsConceptsSetting: ConceptSetting;
  @Input() data: {inDetails: boolean, item: NewsConceptGET, inEdit: boolean}
  @Output() onRequiredValue = new EventEmitter<boolean>();
  @Output() onConceptValues = new EventEmitter<ConceptValues[]>();
  constructor(
    private srvParameter: ParameterControlService,
    private toastr: ToastrService,
    private srvAuth: AuthInfoService,
    private srvConcept: PayrollConceptService,
    private fb: FormBuilder
  ) { this.buildForm() }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.newsConceptsSetting?.newsConceptSettingId){
      this.form.patchValue({
        ...this.newsConceptsSetting,
        manualValue: this.newsConceptsSetting?.manualValue.toString(),
        requiredValue: this.newsConceptsSetting.requiredValue.toString(),
        monthly: this.newsConceptsSetting?.monthly.toString(),
        duplicate: this.newsConceptsSetting?.duplicate.toString(),
        isRestriction: this.newsConceptsSetting?.isRestriction.toString(),
      })
    }
    if(this.newsConceptsSetting?.newsConceptSettingId){
      this.form.get('valueTypeSelected').setValue(this.valueTypes.find(v => v?.ocode === this.newsConceptsSetting?.valueType));
    }
    if(this.newsConceptsSetting?.requiredValue){
      this.changeRequiredValue(true);
    }
  }

  ngOnInit(): void {
    this.getValueType();
    if(this.data?.inDetails){
      this.form.disable();
    }

  }

  private buildForm(){
    this.form = this.fb.group({
      newsConceptSettingId: [0],
      newsConceptId: [0],
      valueTypeSelected: [null, [Validators.required]],
      valueType: [0],
      manualValue: [null, [Validators.required]],
      requiredValue: [null, [Validators.required]],
      monthly: [null, [Validators.required]],
      duplicate: ['false'],
      applicationType: [0],
      isRestriction: ['false'],
      createDate: [null],
      createUserId: [0],
      modifyDate: [null],
      modifyUserId: [0],
    });
  }

  public getValueNewsConceptSetting(){
    const model: any = {...this.form.value};
    return {
      ...model,
      manualValue: model?.manualValue === 'true',
      requiredValue: model?.requiredValue === 'true',
      duplicate: model?.duplicate === 'true',
      monthly: model?.monthly=== 'true',
      isRestriction: model?.isRestriction=== 'true',
      createDate: new Date(),
      createUserId: this.srvAuth.getUserId(),
      valueType: model?.valueTypeSelected?.ocode
    }
  }

  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  showError(name){
    const field = this.form.get(name)
    if(field.hasError('required')){
      return 'Este campo es requerido'
    }
    return ''
  }

  getValueType(){
    this.srvParameter.getParameters(keyWord.ConceptValueType).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0])
          return;
        }
        this.valueTypes = res?.dataList;
        if(this.newsConceptsSetting?.newsConceptSettingId){
          this.form.get('valueTypeSelected').setValue(this.valueTypes.find(v => v?.ocode === this.newsConceptsSetting?.valueType));
        }
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  changeRequiredValue(event){
    const requiredValue = this.form?.get('requiredValue')?.value === 'true';
    this.onRequiredValue.emit(requiredValue);
    if(this.data?.item?.newsConceptId > 0 && requiredValue){
      this.getDefaultValuesByConceptValuesId(this.data?.item?.newsConceptId);
    }
  }
  
  getDefaultValuesByConceptValuesId(id){
    this.srvConcept.getConceptValues(id).subscribe((res: any) => {
      if(res.errors.length > 0){
        this.toastr.error(res.errors[0]);
        return;
      }
      this.onConceptValues.emit(res?.dataList);
    });
  }

}