import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { IDiscountConceptsRelatedPOST, NewsConceptDiscountView, NewsConceptPOST } from '@payroll/payroll-concept/models';
import { PayrollConceptService } from './../../../../../services/payroll-concept.service';
import { AuthInfoService } from './../../../../../services/auth-info.service';
import { ToastrService } from 'ngx-toastr';
import { NewsConceptGET } from './../../models';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { removeWhitespace } from 'src/app/shared/utils/utility';

@Component({
  selector: 'general-tap',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {
  conceptProperties = []
  beneficiaryList = []
  payrollTypesTable = []
  conceptTypes = []
  split = 0
  newsTypes = []
  formIsInvalid = false
  form: FormGroup
  payrollTypes = []
  /* @Input() inDetails
  @Input() item */
  @Input() data: {inDetails: boolean, item: NewsConceptGET, inEdit: boolean}
  item:NewsConceptGET;
  @Output() onClose = new EventEmitter<boolean>()
  @Output() onSuccess = new EventEmitter<boolean>()
  @Output() onNewsTypeAdditional = new EventEmitter<boolean>();

  shortNameError = ''
  codeError = ''
  descriptionError = ''
  conceptTypeError = ''
  newsTypeError = ''
  salaryError = ''
  isrError = ''
  tssError = ''
  debitError = ''
  higherThanSalaryError = ''
  payrollTypesError = ''
  orderError = ''
  ccpError = ''
  toRoyaltyError = ''
  hasDiscountConceptsError = ''
  hasDiscountConceptsListError = ''

  discountConceptsSelected: NewsConceptDiscountView[] = []
  discountConceptsList: {newsConceptId: number,
    description: string,
    code: string,
    companyId: number}[] = []
  
  constructor(
    private srvConcept: PayrollConceptService,
    private fbuilder: FormBuilder,
    private srvAuth: AuthInfoService,
    private toastr: ToastrService,
    private srvParameter: ParameterControlService,
    private srvPayrollNew: PayrollNewService,
  ) { }

  ngOnInit(): void {
    this.getNewsTypes()
    this.getBeneficiaries()
    this.getPayrollTypes()
    this.buildForm()
    this.item = this.data.item
    this.getConceptTypes()
    this.getConceptProperties()
    if(this.data.inDetails || this.data.inEdit){
      this.fillForm()
    }
    if(this.data.inDetails){
      this.disableForm()
    }
    this.setValueChanges()
  }
  

  fillForm(){
    this.form.get('code').patchValue(this.item.code)
    this.form.get('description').patchValue(this.item.concept)
    this.form.get('order').patchValue(this.item.executionOrder)
    this.form.get('isTss').patchValue(this.item?.isTss?.toString())
    this.form.get('isIsr').patchValue(this.item?.isIsr?.toString())
    this.form.get('codeSigef').patchValue(this.item?.codeSigef)
    this.form.get('isDebit').patchValue(this.item?.isDebit?.toString())
    this.form.get('ccp').patchValue(this.item?.ccp?.toString())
    this.form.get('higherThanSalary').patchValue(this.item?.higherThanSalary?.toString())
    this.form.get('isSalary').patchValue(this.item?.isSalary?.toString())
    this.form.get('shortName').patchValue(this.item?.shortName)
    this.form.get('toRoyalty').patchValue(this.item?.toRoyalty?.toString())
    this.form.get('hasDiscountConcepts').patchValue(this.item?.hasDiscountConcepts ? 'true': 'false')
    this.getDiscountConceptsRelated(this.item?.newsConceptId);

    this.changeHasDiscountConcepts(this.item?.hasDiscountConcepts ? 'true': 'false');
  }

  getConceptTypes(){
    this.srvParameter.getParameters(keyWord.ConceptType).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0])
          return
        }
        this.conceptTypes = res.dataList
        if(this.data.inDetails || this.data.inEdit){
          const conceptType = this.conceptTypes.find(x => x.ocode == this.item.conceptTypeId)
          this.form.get('conceptType').patchValue(conceptType)
        }
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  changeHasDiscountConcepts(value:string){
    if(value == 'true'){
      this.getDiscountConcepts();
    }
  }

  getDiscountConcepts(){
    this.srvConcept.getAllDiscountNewConcept(this.srvAuth.getCompanyId()).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          });

          return;
        }

        this.discountConceptsList = res.dataList.map(concept=>{
          return{
            newsConceptId: concept.newsConceptId,
            description: concept.description,
            code: concept.code,
            companyId: concept.companyId
          }
        });

      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error tratando de consultar la lista de conceptos de descuento.');
      }
    });
  }

  getDiscountConceptsRelated(conceptOriginId: number){
    this.srvConcept.getAllDiscountNewConceptRelated(conceptOriginId,this.srvAuth.getCompanyId()).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          });

          return;
        }

        const concepts = res.dataList?.map(concept=>{
          return{
            newsConceptId: concept.newsConceptId,
            description: concept.description,
            code: concept.code,
            companyId: concept.companyId
          }
        });

        this.form?.get('discountConceptsRelated')?.setValue(concepts);

      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error tratando de consultar la lista de conceptos de descuento relacionados.');
      }
    });
  }

  getConceptProperties(){
    /*
    this.srvParameter.getParameters(keyWord.ConceptProperties).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0])
          return
        }
        this.conceptProperties = res.dataList
        this.split = Math.floor(this.conceptProperties.length / 2)
        this.conceptProperties.forEach(e => {
          const field = this.getFields(e.stringData)
          this.properties.push(field)
        })
      }, error: (error) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    }) */
  }

  public getValueForm(){
    this.formIsInvalid = this.isFormInvalid()
    if(this.formIsInvalid) return

    const values = this.form.value
    const conceptBeneficiaryId = values?.beneficiary?.conceptBeneficiaryId
    const newsConceptId = this.data.inEdit && this.item?.newsConceptId > 0 ? this.item?.newsConceptId : 0
    const createDate = this.data.inEdit && this.item?.newsConceptId > 0 ? this.item?.createDate : new Date()
    const createUserId = this.data.inEdit && this.item?.newsConceptId > 0 ? this.item?.createUserId : this.srvAuth.getUserId()
    const conceptTypeId = values?.conceptType?.ocode
    const newsType = values?.newsType?.newsTypeId
    const hasDiscountConcepts: boolean = this.form.value.hasDiscountConcepts === 'true';

    const discountConcepts: IDiscountConceptsRelatedPOST[] = hasDiscountConcepts ? this.form.value.discountConceptsRelated?.map(concept=>{
      return {
        conceptOriginId: newsConceptId,
        newsConceptId: concept.newsConceptId,
        userId: this.srvAuth.getUserId()
      } as IDiscountConceptsRelatedPOST
    }): [];

    const model: NewsConceptPOST = {
      code: values?.code?.trim(),
      isIsr: this.form.value?.isIsr === 'true',
      isTss: this.form.value?.isTss === 'true',
      status: true,
      isDebit: this.form.value?.isDebit === 'true',
      codeSigef: this.form.value?.codeSigef?.trim(),
      companyId: this.srvAuth.getCompanyId(),
      modifyDate: new Date(),
      createDate: createDate,
      newsTypeId: newsType,
      description: values.description?.trim(),
      shortName: values.shortName?.trim(),
      modifyUserId: this.srvAuth.getUserId(),
      createUserId: createUserId,
      payrollTypes: values?.payrollTypes?.map(x => x.ocode),
      conceptTypeId: conceptTypeId,
      newsConceptId: newsConceptId,
      conceptGroupId: 1,
      executionOrder: Number(values.order),
      conceptBeneficiaryId: conceptBeneficiaryId,
      ccp: values?.ccp?.trim(),
      higherThanSalary: this.form.value?.higherThanSalary === 'true',
      isSalary: this.form.value?.isSalary === 'true',
      toRoyalty: this.form.value?.toRoyalty === 'true',
      hasDiscountConcepts: hasDiscountConcepts,
      discountConceptsRelated: discountConcepts //this.form.value.discountConceptsRelated
    }
    return model;
  }


  getPayrollTypeByConcept(newsConceptId){
    this.srvConcept.getPayrollTypesByConceptId(newsConceptId).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          if(res.errors.length > 0){
            this.toastr.error(res.errors[0])
          }
          return
        }

        const pay = res.dataList != null ? res.dataList.filter(x => x.status == true) : []
        let p = []
        pay.forEach(e => {
          const payrolltype = this.payrollTypes.find(x => x.ocode == e.payrollTypeId)
          if(payrolltype){
            p.push(payrolltype)
          }
        })
        if(this.data.inDetails){
          this.payrollTypesTable = p
          return
        }
        this.form.get('payrollTypes').patchValue(p.length > 0 ? p : null)
      }, error: (error:any) => {
        this.toastr.error("Ha ocurrido un error inesperado")
      }
    })
  }

  getPayrollTypes(){
    this.srvParameter.getParameters(keyWord.PayrollType).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0])
          return
        }
        this.payrollTypes = res.dataList =! null ? res.dataList.filter(x => x.status == true) : []
        if(this.data.inDetails || this.data.inEdit){
          this.getPayrollTypeByConcept(this.item.newsConceptId)
        }
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  submit(){
    this.formIsInvalid = this.isFormInvalid()
    if(this.formIsInvalid) return

    const values = this.form.value
    const conceptBeneficiaryId = values.beneficiary.conceptBeneficiaryId
    const newsConceptId = this.data.inEdit && this.item.newsConceptId > 0 ? this.item.newsConceptId : 0
    const createDate = this.data.inEdit && this.item.newsConceptId > 0 ? this.item.createDate : new Date()
    const createUserId = this.data.inEdit && this.item.newsConceptId > 0 ? this.item.createUserId : this.srvAuth.getUserId()
    const conceptTypeId = values.conceptType?.ocode
    const newsType = values.newsType?.newsTypeId
    const hasDiscountConcepts: boolean = this.form.value.hasDiscountConcepts === 'true';

    const discountConcepts: IDiscountConceptsRelatedPOST[] = hasDiscountConcepts ? this.form.value.discountConceptsRelated.map(concept=>{
      return {
        conceptOriginId: newsConceptId,
        newsConceptId: concept.newsConceptId,
        userId: this.srvAuth.getUserId()
      } as IDiscountConceptsRelatedPOST
    }): [];

    const model: NewsConceptPOST = {
      code: values.code,
      isIsr: this.form.value?.isIsr === 'true',
      isTss: this.form.value?.isTss === 'true',
      status: true,
      isDebit: this.form.value?.isDebit === 'true',
      codeSigef: this.form.value?.codeSigef,
      companyId: this.srvAuth.getCompanyId(),
      modifyDate: new Date(),
      createDate: createDate,
      newsTypeId: newsType,
      description: values.description,
      shortName: values.shortName,
      modifyUserId: this.srvAuth.getUserId(),
      createUserId: createUserId,
      payrollTypes: values.payrollTypes.map(x => x.ocode),
      conceptTypeId: conceptTypeId,
      newsConceptId: newsConceptId,
      conceptGroupId: 1,
      executionOrder: Number(values.order),
      conceptBeneficiaryId: conceptBeneficiaryId,
      ccp: values.ccp,
      higherThanSalary: this.form.value?.higherThanSalary === 'true',
      isSalary: this.form.value.isSalary === 'true',
      toRoyalty: this.form.value.toRoyalty === 'true',
      hasDiscountConcepts: hasDiscountConcepts,
      discountConceptsRelated: discountConcepts //this.form.value.discountConceptsRelated
    }

    if(this.data.inEdit){
      this.srvConcept.updateConcept(model).subscribe({
        next: (res:any) => {
          if (res.succeded == false){
            this.toastr.error(res.errors[0])
            return
          }
          this.toastr.success('Datos guardados')
          this.onSuccess.emit(true)
        }, error: (error:any) => {
          this.toastr.error('Ha ocurrido un error inesperado')
        }
      })
    }else{
      this.srvConcept.postConcept(model).subscribe({
        next: (res:any) => {
          if(res.succeded == false){
            this.toastr.error(res.errors[0])
            return
          }
          this.toastr.success('Datos guardados')
          this.onSuccess.emit(true)
        }, error: (error:any) => {
          this.toastr.error('Ha ocurrido un error inesperado')
        }
      })
    }

  }

  getBeneficiaries(){
    this.srvConcept.getBeneficiaries().subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0])
          return
        }
        this.beneficiaryList = res.dataList
        if(this.data.inDetails || this.data.inEdit){
          const beneficiary = this.beneficiaryList.find(x => x.conceptBeneficiaryId == this.item.conceptBeneficiaryId)
          this.form.get('beneficiary').patchValue(beneficiary)
        }
      }, error: (error:any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  close(){
    this.onClose.emit(true)
  }


  buildForm(){
    this.form = this.fbuilder.group({
      code: [''],
      description: [''],
      shortName: [''],
      descriptionAbbr: [''],
      order: [0],
      beneficiary: [''],
      isTss: [null],
      isIsr: [null],
      codeSigef: [''],
      isDebit: [null],
      currency: [''],
      isZero: [false],
      isNegative: [false],
      isVisible: [false],
      toExternalConcept: [false],
      uniqueAmount: [false],
      currentPeriod: [false],
      isMonthly: [false],
      isImportable: [false],
      retroactive: [false],
      complementary: [false],
      isLiquidated: [false],
      startDate: [null],
      endDate: [null],
      conceptType: [null],
      newsType: [null],
      properties: this.fbuilder.array([]),
      payrollTypes: [null],
      ccp:[''],
      higherThanSalary:[''],
      isSalary: [null],
      toRoyalty: [null],
      hasDiscountConcepts: [null],
      discountConceptsRelated: [([])]
    })
  }


  getFields(stringData, selected = false){
    const item: any = {
      stringData,
      selected
    }
    return this.fbuilder.group(item)
  }
  get properties(){
    return this.form.get('properties') as FormArray
  }
  disableForm(){
    this.form.get('code').disable()
    this.form.get('description').disable()
    this.form.get('descriptionAbbr').disable()
    this.form.get('order').disable()
    this.form.get('beneficiary').disable()
    this.form.get('conceptType').disable()
    this.form.get('newsType').disable()
    this.form.disable()
  }

  resetForm(){
    this.form.patchValue({
      code: '',
      description: '',
      descriptionAbbr: '',
      shortName: '',
      order: '',
      currency: '',
      isZero: false,
      isNegative: false,
      isVisible: false,
      toExternalConcept: false,
      uniqueAmount: false,
      currentPeriod: false,
      isMonthly: false,
      isImportable: false,
      retroactive: false,
      complementary: false,
      isLiquidated: false,
      startDate: null,
      endDate: null,
      conceptType: null,
      payrollTypes: null,
      ccp:null,
      isSalary:null,
      toRoyalty: null,
      hasDiscountConcepts: null
    })
  }

  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  getNewsTypes(){
    this.srvPayrollNew.getNewsType().subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0]); return
        }
        this.newsTypes = res.dataList?.filter(d => d?.status);
        if(this.data.inDetails || this.data.inEdit){
          const newstype = this.newsTypes.find(x => x.newsTypeId == this.item.newsTypeId)
          this.form.get('newsType').patchValue(newstype)
        }
      }, error: (error: any) => {

      }
    })
  }

  changeNewsType(){
    this.onNewsTypeAdditional.emit(this.form?.value?.newsType?.newsTypeId === 2);
  }


  isFormInvalid(){
    //Cambié la validación, antes, el modal se iva poniendo lento mientras mas cosas hacías.
    const shortName = this.isShortNameInvalid()
    const code = this.isCodeInvalid()
    const payrollType = this.isPayrollTypesInvalid()
    const description = this.isDescriptionInvalid()
    const isSalary = this.isSalaryInvalid()
    const isr = this.isIsrInvalid()
    const tss = this.isTssInvalid()
    const debit = this.isDebitInvalid()
    const higher = this.isHigherThanSalaryInvalid()
    const newsType = this.isNewsTypeInvalid()
    const concept = this.isConceptTypeInvalid()
    const order = this.isOrderInvalid()
    const ccp = this.isCcpInvalid()
    const toRoyalty = this.isToRoyalty()
    const discount = this.hasDiscountInvalid();
    const discountRelated = this.isDiscountConceptsListInvalid();
    const isInvalid = (
      shortName   || code     || payrollType  || description  || isSalary   || 
      isr         || tss      || debit        || higher       || newsType   || 
      concept     || order    || ccp || discount || discountRelated)
    if(isInvalid){
      return true
    }
    return false
  }
  

  isOrderInvalid(){
    this.orderError = ''
    const value = this.form.get('order').value.toString()?.trim()
    const regex = /^-?(0|[1-9]\d*)?$/
    if(regex.test(value) == false){
      this.orderError = 'Este campo solo permite números'
      return true
    }
    return false
  }
  isShortNameInvalid(){
    this.shortNameError = ''
    const value = this.form.get('shortName').value?.trim()
    const isEmpty = value.length < 1
    if(isEmpty){
      this.shortNameError = 'Este campo es requerido.'
      return true
    }
    if(value.length > 20){
      this.shortNameError = 'Este solo admite un máximo de 20 caracteres'
      return true
    }
    return false
  }


  isDescriptionInvalid(){
    this.descriptionError = ''
    const isEmpty = this.form.get('description').value?.trim().length < 1
    if(isEmpty){
      this.descriptionError = 'Este campo es requerido.'
      return true
    }
    return false
  }
  isConceptTypeInvalid(){
    this.conceptTypeError = ''
    const isEmpty = this.dropdownIsEmpty('conceptType')
    if(isEmpty){
      this.conceptTypeError = 'Este campo es requerido.'
      return true
    }
    return false
  }
  
  isNewsTypeInvalid(){
    this.newsTypeError = ''
    const isEmpty = this.dropdownIsEmpty('newsType')
    if(isEmpty){
      this.newsTypeError = 'Este campo es requerido.'
      return true
    }
    return false
  }
  isSalaryInvalid(){
    this.salaryError = ''
    const isEmpty = this.form.get('isSalary').value ? false : true
    if(isEmpty){
      this.salaryError = 'Este campo es requerido.'
      return true
    }
    return false
  }
  isIsrInvalid(){
    this.isrError = ''
    const isEmpty = this.form.get('isIsr').value ? false : true
    if(isEmpty){
      this.isrError = 'Este campo es requerido.'
      return true
    }
    return false
  }
  isDebitInvalid(){
    this.debitError = ''
    const isEmpty = this.form.get('isDebit').value ? false : true
    if(isEmpty){
      this.debitError = 'Este campo es requerido.'
      return true
    }
    return false
  }
  isTssInvalid(){
    this.tssError = ''
    const isEmpty = this.form.get('isTss').value ? false : true
    if(isEmpty){
      this.tssError = 'Este campo es requerido.'
      return true
    }
    return false
  }
  isHigherThanSalaryInvalid(){
    this.higherThanSalaryError = ''
    const isEmpty = this.form.get('higherThanSalary').value ? false : true
    if(isEmpty){
      this.higherThanSalaryError = 'Este campo es requerido.'
      return true
    }
    return false
  }
  isPayrollTypesInvalid(){
    this.payrollTypesError = ''
    const isEmpty = this.dropdownIsEmpty('payrollTypes')
    if(isEmpty){
      this.payrollTypesError = 'Este campo es requerido.'
      return true
    }
    return false
  }
  isCodeInvalid(){
    this.codeError = ''
    const isEmpty = this.form.get('code').value?.trim().length < 1
    if(isEmpty){
      this.codeError = 'Este campo es requerido.'
      return true
    }
    return false
  }
  isCcpInvalid(){
    this.ccpError = ''
    const value = this.form.get('ccp').value?.trim()
    if(value?.length > 50){
      this.ccpError = 'Este solo admite un máximo de 50 caracteres'
      return true
    }
    return false
  }
  isToRoyalty(){
    this.toRoyaltyError = ''
    const isEmpty = this.form.get('toRoyalty').value ? false : true
    if(isEmpty){
      this.toRoyaltyError = 'Este campo es requerido.'
      return true
    }
    return false    
  }
  hasDiscountInvalid(){
    this.hasDiscountConceptsError = ''
    const isEmpty = this.form.get('hasDiscountConcepts').value ? false : true
    if(isEmpty){
      this.hasDiscountConceptsError = 'Este campo es requerido.'
      return true
    }
    return false    
    
  }

  isDiscountConceptsListInvalid(){
    this.hasDiscountConceptsListError = ''
    const hasDiscount = this.form.get('hasDiscountConcepts').value == 'true' ? true : false;
    if(!hasDiscount){
      return false
    }

    const isEmpty = this.form.get('discountConceptsRelated')?.value?.length > 0 ? false : true;
    if(isEmpty){
      this.hasDiscountConceptsListError = 'Este campo es requerido.'
      return true
    }
    return false    
    
  }

  getControl(name){
    return this.form.get(name)
  }
  // code, shortName, description, conceptType, newsType, isSalary, isIsr, isTss, isDebit, higherThanSalary, payrollTypes
  setValueChanges(){
    /* this.form.valueChanges.subscribe({
      next: (res) => {
        this.isFormInvalid()
      }
    }) */
    this.getControl('payrollTypes').valueChanges.subscribe({next: (res) => {this.isPayrollTypesInvalid()}})
    this.getControl('higherThanSalary').valueChanges.subscribe({next: (res) => {this.isHigherThanSalaryInvalid()}})
    this.getControl('isDebit').valueChanges.subscribe({next: (res) => {this.isDebitInvalid()}})
    this.getControl('isTss').valueChanges.subscribe({next: (res) => {this.isTssInvalid()}})
    this.getControl('isIsr').valueChanges.subscribe({next: (res) => {this.isIsrInvalid()}})
    this.getControl('isSalary').valueChanges.subscribe({next: (res) => {this.isSalaryInvalid()}})
    this.getControl('newsType').valueChanges.subscribe({next: (res) => {this.isNewsTypeInvalid()}})
    this.getControl('conceptType').valueChanges.subscribe({next: (res) => {this.isConceptTypeInvalid()}})
    this.getControl('description').valueChanges.subscribe({next: (res) => {this.isDescriptionInvalid()}})
    this.getControl('shortName').valueChanges.subscribe({next: (res) => {this.isShortNameInvalid()}})
    this.getControl('code').valueChanges.subscribe({next: (res) => {this.isCodeInvalid()}})
    this.getControl('order').valueChanges.subscribe({next: (res) => {this.isOrderInvalid()}})
    this.getControl('ccp').valueChanges.subscribe({next: (res) => {this.isCcpInvalid()}})
    this.getControl('toRoyalty').valueChanges.subscribe({next: (res) => {this.isToRoyalty()}})
    this.getControl('hasDiscountConcepts').valueChanges.subscribe({next: (res) => {this.hasDiscountInvalid()}})
  }
  removeWhitespaces(event, control){
    removeWhitespace(event, control)
  }
  dropdownIsEmpty(name){
    const dropValue = this.form.get(name).value
    if(dropValue == null){
      return true
    }
    if(Array.isArray(dropValue)){
      if(dropValue.length < 1){
        return true
      }
    }
    if(dropValue != 0){
      if(!dropValue){
        return true
      }
    }
    return false
  }
}
