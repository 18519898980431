<h2 class="mat-dialog-title">Agregar Habilidades</h2>
<div class="mat-dialog-content">
    <form [formGroup]="">
        <div class="row gutter-b">
            <div class="form-group col-lg-6">
                <label>Tipo de habilidad</label>
                <input class="form-control form-control-lg" placeholder="" type="text" >
            </div>

            
        </div>
    </form>
</div>
<div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)='Cancel()'>
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-success" type="button"
        (click)='Create()'>
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>