<div data-card="true" class="card card-custom" [id]="privilege.nameKeyOption">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-busqueda-de-trabajo icon-3x text-danger mr-3"></i>
                <h1 class="text-primary"> Requisición de Personal </h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Dashboard">
            <button data-toggle="modal" *ngIf="privilege?.dashboardAction?.value"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto"
                (click)="openDashBoard()">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </button>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-6 c-left">
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-4 mr-2 px-0 pt-1">
                    <div class="form-group institution">

                        <label class="text-primary">
                            Estatus
                        </label>
                        <!-- <app-dropdown-multi-select (changeAsw)="filterTable($event)"></app-dropdown-multi-select> -->
                        <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                            [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelect)="onItemDeSelect($event)"
                            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                            (onDeSelectAll)="onDeSelectAll()">
                        </ng-multiselect-dropdown>
                    </div>
                </div>

                <button data-toggle="modal" id="btnAdd" (click)="AddNewRequisition(null)"
                    *ngIf="privilege?.addAction?.value"
                    class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                    <i class="flaticon-mas icon-2x" data-toggle="tooltip"
                        data-original-title="Agregar nueva requisición"></i>
                    Agregar
                </button>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="privilege?.exportAction?.value" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                        <a class="dropdown-item align-items-center" (click)="openReport()">
                            <i class="fa fa-print icon-2x text-primary mr-3"></i>
                            Visualizar Reporte
                        </a>
                        <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a> -->
                    </div>
                </div>

                <div class="col text-nowrap">
                    <app-search-bar-pagination #searchBar (onFilter)="filterSearch($event)" (onChangeDropDown)="filterSearch($event)"
                        [parameters]="searchBarDisplayNames" [namePageLocalStorage]="searchparameterlocalstorage">
                    </app-search-bar-pagination>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th class="text-left">Sucursal</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-left">Cargo</th>
                        <th class="text-left">Cantidad de Plazas</th>
                        <th class="text-left">Método de Reclutamiento</th>
                        <th class="text-left">Sexo</th>
                        <th class="text-left">Creación</th>
                        <th class="text-left">Aprobación</th>
                        <th class="text-left">Estatus Requisición</th>
                        <th class="text-left">Estatus</th>
                        <th class="actionsBtn" style="width: 100px;">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let requisition of requisitions" data-row="0" class="datatable-row" style="left: 0px;">
                        <td class="text-center">{{requisition.personalRequisitionId}}</td>
                        <td class="text-left">{{requisition.enclosure}}</td>
                        <td class="text-left">{{requisition.department}}</td>
                        <td class="text-left">{{requisition.positionName}}</td>
                        <td class="text-left">{{requisition.numberPlaces}}</td>
                        <td class="text-left">{{requisition?.requisitionMethod}}</td>
                        <td class="text-left">{{requisition.sex}}</td>
                        <td class="text-left"> {{requisition.requestedDate | date:'dd/MM/yyyy'}}</td>
                        <td class="text-left"> {{!requisition?.approvationDate? 'N/A':
                            requisition?.approvationDate | date:'dd/MM/yyyy' }}</td>
                        <td *ngIf="requisition.condition == true && requisition.requisitionMethodId == 1 && requisition.concursaCondition != 1"
                            class="text-left">
                            <span style="width: 137px;">
                                <span class="label label-warning label-dot mr-2"></span>
                                <span class="font-weight-bold text-warning">Pendiente enviar a concursa</span>
                            </span>
                        </td>
                        <td *ngIf="requisition.condition == true && requisition.requisitionMethodId == 1 && requisition.concursaCondition == 1"
                            class="text-left">
                            <span style="width: 137px;">
                                <span class="label label-info label-dot mr-2"></span>
                                <span class="font-weight-bold text-info">Enviada a Concursa</span>
                            </span>
                        </td>
                        <td *ngIf="requisition.condition == true && requisition.requisitionMethodId != 1"
                            class="text-left">
                            <span style="width: 137px;">
                                <span class="label label-success label-dot mr-2"></span>
                                <span class="font-weight-bold text-success">Aprobada</span>
                            </span>
                        </td>
                        <td *ngIf="requisition.condition == false" class="text-left">
                            <span style="width: 137px;">
                                <span class="label label-danger label-dot mr-2"></span>
                                <span class="font-weight-bold text-danger">No aprobada</span>
                            </span>
                        </td>
                        <td class="text-left">
                            <span style="width: 137px;">
                                <span
                                    [ngClass]="requisition.status ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{requisition.status?
                                    "Activa":
                                    "Inactiva"}}</span>
                            </span>
                        </td>
                        <td data-field="Actions" data-autohide-disabled="false" aria-label="null"
                            class="datatable-cell-left datatable-cell">
                            <div class="d-flex justify-content-center px-5" style="width: max-content">
                                <div class="justify-content-center px-5">
                                    <a tooltip="Ver" placement="top" data-toggle="modal"
                                        *ngIf="privilege?.viewAction?.value" (click)="openReport(requisition)"
                                        class="btn btn-sm btn-light-skype btn-icon mr-2">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" data-title="Ver"></i>
                                    </a>
                                    <a *ngIf="!requisition.condition && requisition.status && privilege?.editAction?.value"
                                        tooltip="Editar" placement="top"
                                        class="btn btn-sm btn-light-warning btn-icon mr-2"
                                        (click)="AddNewRequisition(requisition)">
                                        <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                                    </a>
                                    <a *ngIf="!requisition.condition && requisition.status && privilege?.approveAction?.value"
                                        tooltip="Inactivar" placement="top"
                                        class="btn btn-sm btn-light-success btn-icon mr-2"
                                        (click)="aprovate(requisition.personalRequisitionId)">
                                        <i class="flaticon-etiqueta icon-2x " data-toggle="tooltip"
                                            data-title="Aprobar"></i>
                                    </a>
                                    <a *ngIf="!requisition.condition && requisition.status && privilege?.deleteAction?.value"
                                        class="btn btn-icon btn-light-danger btn-sm m-1">
                                        <i class="flaticon-usuarios icon-2x" data-toggle="tooltip" title="Inactivar"
                                            (click)="DeleteItem(requisition.personalRequisitionId)"></i>
                                    </a>
                                    <a *ngIf="requisition.requisitionMethodId == 1 && requisition.concursaCondition != 1 && requisition.condition && requisition.status && privilege?.approveAction?.value"
                                        class="btn btn-icon btn-light-primary btn-sm m-1"
                                        (click)="openParametersSendConcursaComponent(requisition)">
                                        <i class="flaticon-enviar icon-2x" data-toggle="tooltip"
                                            title="Enviar a Concursa"></i>
                                    </a>
                                    <a *ngIf="privilege.auditChangesAction.value"
                                        class="btn btn-sm btn-light-dark btn-icon m-1"
                                        (click)="openModalAuditChanges(requisition)">
                                        <i class="flaticon-lista-de-verificacion-2" data-toggle="tooltip"
                                            title="Auditar Cambios"></i>
                                    </a>
                                    <button
                                        *ngIf="requisition.requisitionMethodId == 1 && requisition.requisitionMethodId == 1 && requisition.concursaCondition == 1 && requisition.condition && requisition.status && privilege?.approveAction?.value"
                                        class="btn btn-icon btn-light-primary btn-sm m-1"
                                        (click)="openConcursaCheckStatusModal(requisition)">
                                        <i class="flaticon-buscar icon-2x" data-toggle="tooltip"
                                            title="Verificar Envío"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <app-paginator [listedRecordsLength]="requisitions?.length"
                    [registerQuantity]="employeesPagedResult.totalItems"
                    [registerPerPage]="employeesPagedResult.pageSize" [totalPages]="employeesPagedResult.totalPages"
                    [selectedPage]="employeesPagedResult.page" (onChange)="getPaginatedRecords($event)">
                </app-paginator>
            </div>
        </div>
    </div>
</div>
