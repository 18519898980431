import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { removeWhitespace } from 'src/app/shared/utils/utility';

@Component({
  selector: 'app-exclude-employee-payroll-modal',
  templateUrl: './exclude-employee-payroll-modal.component.html',
  styleUrls: ['./exclude-employee-payroll-modal.component.css']
})
export class ExcludeEmployeePayrollModalComponent implements OnInit {
  
  form = new FormGroup({
    reason: new FormControl('', Validators.required)
  })
  formIsInvalid = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private srvPayroll: PayrollService,
    private auth: AuthInfoService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<ExcludeEmployeePayrollModalComponent>
  ) { }

  ngOnInit(): void {
  }

  removeWhitespaces(event, control){
    removeWhitespace(event, control)
  }
  excludeEmployee(){
    this.formIsInvalid = this.form.invalid
    if(this.formIsInvalid){
      return
    }
    
    const model = {
      headerId: this.data.item.headerId,
      employeeId: this.data.item.employeeId,
      userId: this.auth.getUserId(),
      rejectReason: this.form.get('reason').value
    }
    this.srvPayroll.excludeEmployeePayroll(model).subscribe({
      next: (res:any) => {
        if(res.errors.length > 0){
          this.toastr.error(res.errors[0])
          return
        }

        this.toastr.success('Colaborador excluido de la nómina')
        this.close(true)
      }, error: (error: any) => {

      }
    })
  }

  close(success = false){
    this.dialogRef.close({success})
  }

}
