import { PayrollReportComponent } from "../../payroll-report/payroll-report.component"

export interface Payroll {
    payrollType: string;
    employeeType: string;
    area: string;
    status: string;
    statusId: number;
    startDate: string;
    endDate: string;
    payDate: string;
    year: number;
}

export interface PayrollReport {
    id: number;
    name: string;
}

export const openPayroll: Payroll[] = [
    {status: 'Abiertas', statusId: 1, year: 2020, area: 'La Caleta', payrollType: 'Normal', employeeType: 'Fijos', startDate: '12-06-2020', endDate: '28-06-2020', payDate: '30-06-2020'},
    {status: 'Abiertas', statusId: 1, year: 2021, area: 'Santiago', payrollType: 'Especial', employeeType: 'Por hora', startDate: '15-07-2020', endDate: '28-07-2020', payDate: '30-07-2020'},
    {status: 'Pendientes', statusId: 2, year: 2021, area: 'La Romana', payrollType: 'Normal', employeeType: 'Fijos', startDate: '15-01-2021', endDate: '27-01-2021', payDate: '30-01-2021'},
    {status: 'Pendientes', statusId: 2, year: 2021, area: 'La Caleta', payrollType: 'Normal', employeeType: 'Nombrado', startDate: '15-02-2021', endDate: '27-02-2021', payDate: '30-02-2021'},
]
export const approvedPayroll: Payroll[] = [
    {status: 'Aprobadas', statusId: 1, year: 2020, area: 'La Caleta', payrollType: 'Normal', employeeType: 'Fijos', startDate: '12-06-2020', endDate: '28-06-2020', payDate: '30-06-2020'},
    {status: 'Aprobadas', statusId: 1, year: 2021, area: 'Santiago', payrollType: 'Especial', employeeType: 'Por hora', startDate: '15-07-2020', endDate: '28-07-2020', payDate: '30-07-2020'},
]

export const Reports: PayrollReport[] = [
    {id: 1, name: 'Reporte de Nómina o Kardex'},
    {id: 2, name: 'Volante de Nómina'},
    {id: 3, name: 'Deducciones por Entidad Relacionada'},
    {id: 4, name: 'Entrada Contable de Nómina'}
]