
import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplyVacanciesComponent } from './pages/apply-vacancies/apply-vacancies.component';
import { CandidateRegistrationComponent } from './pages/candidate-registration/candidate-registration.component';
import { CandidateRegistrationListComponent } from './pages/candidate-registration/components/candidate-registration-list/candidate-registration-list.component';
import { HomeComponent } from './pages/home/home.component';
import { InterviewEvaluationComponent } from './pages/interview-evaluation/interview-evaluation.component';
import { NoObjectionComponent } from './pages/map/no-objection/no-objection.component';
import { PersonalRequisitionComponent } from './pages/personal-requisition/personal-requisition.component';
import { PersonnelActionsComponent } from './pages/RegistrationAndControl/personnel-actions/personnel-actions.component';
import { PostVacanciesComponent } from './pages/post-vacancies/components/post-vacancies/post-vacancies.component';
import { ProofApplicantsComponent } from './pages/proof-applicants/proof-applicants.component';
import { ApprovalEmployeeChangeActionsComponent } from './pages/RegistrationAndControl/approval-employee-change-actions/approval-employee-change-actions.component';
import { RequestNoObjectionMapComponent } from './pages/request-no-objection-map/request-no-objection-map.component';
import { JobOfferComponent } from './pages/job-offer/job-offer.component';
import { EmployeesComponent } from './pages/RegistrationAndControl/employees/employees.component';

import { Incidents } from './pages/EvaluationPerformance/models/Incidents';
import { IncidentsListComponent } from './pages/EvaluationPerformance/incidents/incidents-list/incidents-list.component';
import { IncidentsComponent } from './pages/EvaluationPerformance/incidents/incidents.component';
import { AnnualOperativePlanListComponent } from './pages/EvaluationPerformance/AnnualOperativePlan/annual-operative-plan-list/annual-operative-plan-list.component';

import { MapBudgetApprobationComponent } from './pages/map/map-budget-approbation/map-budget-approbation.component';
import { FundsCertificationComponent } from './pages/funds-certification/funds-certification.component';
import { PDFPreviewComponent } from './pages/RegistrationAndControl/pdf-preview/pdf-preview.component';
import { EmployeeExitVerificationComponentMockup } from './pages/RegistrationAndControl/employee-exit-verification/employee-exit-verification.component';
import { InterviewListComponent } from './pages/interview-evaluation/interview-list/interview-list.component';
import { ProofApplicantsListComponent } from './pages/proof-applicants/proof-applicants-list/proof-applicants-list.component';
import { EmployeeMaintenanceComponent } from './pages/RegistrationAndControl/employee-maintenance/employee-maintenance.component';
import { PersonnelActionsEmployeeListComponent } from './pages/RegistrationAndControl/personnel-actions/personnel-actions-employee-list/personnel-actions-employee-list.component';
import { ApplyVacanciesListComponent } from './pages/apply-vacancies/apply-vacancies-list/apply-vacancies-list.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { RequsitionSummaryComponent } from './pages/personal-requisition/requisitions-summary/requsition-summary/requsition-summary.component';
import { RemissionNoObjectionComponent } from './pages/request-no-objection-map/remission-no-objection/remission-no-objection.component';
import { StaffRequirementSummaryComponent } from './pages/personal-requisition/staff-requirement-summary/staff-requirement-summary.component';
import { SummaryInterviewComponent } from './pages/interview-evaluation/summary-interview/summary-interview.component';
import { FinancePlanningApprovalComponent } from './pages/Planning/components/finance-planning-approval/finance-planning-approval.component';
import { MAPPlanningApprovalComponent } from './pages/Planning/components/mapplanning-approval/mapplanning-approval.component';
import { PlanningRrhhComponent } from './pages/Planning/components/planning-rrhh/planning-rrhh.component';
import { OrganicStructureComponent } from 'src/app/pages/organic-structure/organic-structure.component';
import { PdfActionChangeEmployeeComponent } from './pages/RegistrationAndControl/pdf-action-change-employee/pdf-action-change-employee.component';
import { PdfActionChangeEmployeeLicenseComponent } from './pages/RegistrationAndControl/pdf-action-change-employee-license/pdf-action-change-employee-license.component';
import { PdfActionChangeEmployeeVacationsComponent } from './pages/RegistrationAndControl/pdf-action-change-employee-vacations/pdf-action-change-employee-vacations.component';
import { PdfActionChangeEmployeePermisosComponent } from './pages/RegistrationAndControl/pdf-action-change-employee-permisos/pdf-action-change-employee-permisos.component';
import { PdfActionChangeEmployeeDesvincularComponent } from './pages/RegistrationAndControl/pdf-action-change-employee-desvincular/pdf-action-change-employee-desvincular.component';
import { EmployeePersonnelActionsListComponent } from './pages/RegistrationAndControl/employee-personnel-actions-list/employee-personnel-actions-list.component'
import { PdfPreviewResultadosComponent } from './pages/RegistrationAndControl/pdf-preview-resultados/pdf-preview-resultados.component';
import { DeparmentsListComponent } from './pages/deparments/deparments-list/deparments-list.component';
import { ChartComponent } from './shared/chart/chart.component';
import { PdfEmployeeDataComponent } from './pages/RegistrationAndControl/pdf-employee-data/pdf-employee-data.component';
import { PositionsListComponent } from './pages/position-list/position-list.component';
import { PositionChangesComponent } from './pages/position-changes/position-changes.component';
import { PdfActionChangeEmployeeExservicesComponent } from './pages/RegistrationAndControl/pdf-action-change-employee-exservices/pdf-action-change-employee-exservices.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { AssistControlComponent } from './pages/RegistrationAndControl/employees/assist-control/assist-control.component';
import { PdfPaymentVoucherComponent } from '@payroll/payment-flyer/pdf-payment-voucher/pdf-payment-voucher.component';
import { PdfEmployeeeExportComponent } from './pages/RegistrationAndControl/employees/pdf-employeee-export/pdf-employeee-export.component';
import { PdfPersonnelActionsRegulatedComponent } from './pages/RegistrationAndControl/pdf-personnel-actions-regulated/pdf-personnel-actions-regulated.component';
import { ControlDocumentComponent } from './shared/control-document/control-document.component';
import { PlanningHistoryComponent } from './pages/Planning/planning-history/planning-history.component';
import { PositionsListDescriptionComponent } from './pages/positions-list-description/positions-list-description.component';
import { CollectiveVacationsComponent } from './pages/RegistrationAndControl/collective-vacations/collective-vacations.component';
import { ContentManagementListComponent } from './pages/content-management/content-management-list/content-management-list.component';
import { ConsultCollectiveVacationsComponent } from './pages/RegistrationAndControl/consult-collective-vacations/consult-collective-vacations.component';
import { PdfPositionsDescriptionComponent } from './pages/positions-list-description/pdf-positions-description/pdf-positions-description.component';
import { HourlyTeachersComponent } from './pages/RegistrationAndControl/hourly-teachers/hourly-teachers.component';
import { SocialMedialComponent } from './pages/social-medial/social-medial.component';
import { CollectiveActionsComponent } from './pages/collective-actions/collective-actions.component';
import { TelephoneDirectoryComponent } from './pages/telephone-directory/telephone-directory.component';
import { PlanningOpeningPlanningComponent } from './pages/Planning/components/planning-opening-planning/planning-opening-planning.component';
import { ApplicationForPositionsAndVacanciesPlanningComponent } from './pages/Planning/components/application-for-positions-and-vacancies-planning/application-for-positions-and-vacancies-planning.component';
import { GeneratePlanningPlanningComponent } from './pages/Planning/components/generate-planning-planning/generate-planning-planning.component';
import { AuthorizePlanningPlanningComponent } from './pages/Planning/components/authorize-planning-planning/authorize-planning-planning.component';
import { ApprovePlanningPlanningComponent } from './pages/Planning/components/approve-planning-planning/approve-planning-planning.component';
import { ReportsPlanningComponent } from './pages/Planning/components/reports-planning/reports-planning.component';
import { DetailPlanningPlanningComponent } from './pages/Planning/components/detail-planning-planning/detail-planning-planning.component';
import { ApplicationForPositionsAndVacanciesRRHHPlanningComponent } from './pages/Planning/components/application-for-positions-and-vacancies-rrhhplanning/application-for-positions-and-vacancies-rrhhplanning.component';
import { EmployeeServiceRequestComponent } from './pages/RegistrationAndControl/employee-service/employee-service-request/employee-service-request.component';
import { PayrollParcialComponent } from '@payroll/payroll-report/payroll-parcial/payroll-parcial.component';
import { ReportListComponent } from './pages/RegistrationAndControl/report-list/report-list.component';
import { CertificationReportComponent } from './pages/RegistrationAndControl/reports/certification-report/certification-report.component';
import { PayrollTransparencyComponent } from './pages/RegistrationAndControl/reports/payroll-transparency/payroll-transparency.component';
import { OccupationalGroupsReportComponent } from './pages/RegistrationAndControl/reports/occupational-groups-report/occupational-groups-report.component';
import { EmployeeBirthdayReportComponent } from './pages/RegistrationAndControl/reports/employee-birthday-report/employee-birthday-report.component';
import { AgeChildrenEmployeesReportComponent } from './pages/RegistrationAndControl/reports/age-children-employees-report/age-children-employees-report.component';
import { DepartmentChangesComponent } from './pages/department-changes/department-changes.component';
import { EmployeeResponseRequestComponent } from './pages/RegistrationAndControl/employee-service/employee-reponse-request/employee-reponse-request.component';
import { UserGuideComponent } from './pages/RegistrationAndControl/user-guide/user-guide.component';
import { DetailPhysicalInventoryMedicalComponent } from './pages/medical-history/physical-inventory-medical/detail-physical-inventory-medical/detail-physical-inventory-medical.component';
import { SeparationsComponent } from './pages/RegistrationAndControl/separations/separations.component';
import { VinculationsComponent } from './pages/RegistrationAndControl/vinculations/vinculations.component';
import { FrequentQuestionsComponent } from './pages/FrequentQuestions/frequent-questions/frequent-questions.component';
import { BirthdayComponent } from './pages/RegistrationAndControl/birthday/birthday.component';
import { TeacherReviewsComponent } from './pages/RegistrationAndControl/hourly-teachers/teacher-reviews/teacher-reviews.component';
import { DetailVacationSchedulingComponent } from './pages/Planning/components/vacation-scheduling/detail-vacation-scheduling/detail-vacation-scheduling.component';
import { MassiveChangeSupervisorComponent } from './pages/RegistrationAndControl/massive-change-supervisor/massive-change-supervisor.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    data: { nameKey: 'HRM' },
    children: [
      {
        path: 'dashboard',
        component: HomeComponent,

      },

      {
        path: 'personal-requisition',
        component: PersonalRequisitionComponent,

      },
      {
        path: 'candidate-registration',
        component: CandidateRegistrationComponent,

      },
      {
        path: 'candidate-registration/:id',
        component: CandidateRegistrationComponent,

      },
      {
        path: 'candidate-registration/:id/:type',
        component: CandidateRegistrationComponent,

      },
      {
        path: 'candidate-registration/:id/:type/:employeeId',
        component: CandidateRegistrationComponent,

      },
      {
        path: 'candidate-registration-list',
        component: CandidateRegistrationListComponent,

      },
      {
        path: 'summary',
        component: RequsitionSummaryComponent,

      },
      {
        path: 'medical-history/physicalinventorydetail/:id',
        component: DetailPhysicalInventoryMedicalComponent,

      },
      {
        path: 'post-vacancies',
        component: PostVacanciesComponent,

      },
      {
        path: 'apply-vacancies-list',
        component: ApplyVacanciesListComponent,

      },
      {
        path: 'apply-vacancies/:id',
        component: ApplyVacanciesComponent,

      },
      {
        path: 'interview-list',
        component: InterviewListComponent,

      },
      {
        path: 'interview-evaluation/:id',
        component: InterviewEvaluationComponent,

      },
      {
        path: 'proof-applicants/:id',
        component: ProofApplicantsComponent,

      },
      {
        path: 'proof-applicants-list',
        component: ProofApplicantsListComponent,

      },
      {
        path: 'proof-details/:id',
        component: PdfPreviewResultadosComponent,

      },
      {
        path: 'job-offer',
        component: JobOfferComponent,

      },
      {
        path: 'request-no-objection-map',
        component: RequestNoObjectionMapComponent,

      },
      {
        path: 'map/no-objection',
        component: NoObjectionComponent,

      },
      {
        path: 'map/map-budget-aprobation',
        component: MapBudgetApprobationComponent,

      },
      {
        path: 'personnel-actions/:id',
        component: PersonnelActionsComponent,

      },
      {
        path: 'personnel-actions-employee-list',
        component: PersonnelActionsEmployeeListComponent,

      },
      {
        path: 'action-change-employee/:employeeId/:type',
        component: ApprovalEmployeeChangeActionsComponent,

      },
      {
        path: 'employee-maintenance/:id',
        component: EmployeeMaintenanceComponent,

      },
      {
        path: 'incidents/:id',
        component: IncidentsComponent,

      },
      {
        path: 'incidents',
        component: IncidentsComponent,

      },
      {
        path: 'annual-operative-plan-list',
        component: AnnualOperativePlanListComponent,

      },
      {
        path: 'incidents-list',
        component: IncidentsListComponent,

      },
      {
        path: 'employees',
        component: EmployeesComponent,

      },
      {
        path: 'pdf-preview',
        component: PDFPreviewComponent,

      },
      {
        path: 'reports',
        component: ReportListComponent,

      },
      {
        path: 'report/certification-report',
        component: CertificationReportComponent
      },
      {
        path: 'report/occupational-groups-report',
        component: OccupationalGroupsReportComponent
      },
      {
        path: 'report/employee-birthday-report',
        component: EmployeeBirthdayReportComponent
      },
      {
        path: 'report/age-children-employees-report',
        component: AgeChildrenEmployeesReportComponent
      },
      {
        path: 'employee-exit-verification',
        component: EmployeeExitVerificationComponentMockup,

      },
      {
        path: 'remission-no-objection',
        component: RemissionNoObjectionComponent,

      },
      {
        path: 'pdf-preview/:id',
        component: PDFPreviewComponent,

      },
      {
        path: 'pdf-Employeee-Export',
        component: PdfEmployeeeExportComponent,

      }
      ,
      {
        path: 'summary/:id',
        component: SummaryComponent,

      },
      {
        path: 'staff-requirement-summary/:id/:status/:condition/:companyId',
        component: StaffRequirementSummaryComponent,

      },
      {
        path: 'summary-interview/:id',
        component: SummaryInterviewComponent,

      },
      {
        path: 'funds-certification',
        component: FundsCertificationComponent,

      },
      {
        path: 'planning-rrhh',
        component: PlanningHistoryComponent,

      },
      /*   {
          path: 'mapplanning-approval',
          component: MAPPlanningApprovalComponent,
        }, */
      {
        path: 'finance-planning-approval',
        component: FinancePlanningApprovalComponent,

      },
      {
        path: 'chart',
        component: ChartComponent,

      },
      {
        path: 'view-estructure',
        component: OrganicStructureComponent,

      },
      {
        path: 'pdf-employee-data',
        component: PdfEmployeeDataComponent,

      },
      {
        path: 'pdf-employee-data/:id',
        component: PdfEmployeeDataComponent,


      },
      {
        path: 'pdf-personnel-actions-regulated/:id',
        component: PdfPersonnelActionsRegulatedComponent,

      },
      {
        path: 'positions-list',
        component: PositionsListComponent,

      },
      /* {
        path: 'telephone-directory',
        component: TelephoneDirectoryComponent,

      }, */
      {
        path: 'positionsList-description',
        component: PositionsListDescriptionComponent,

      },
      {
        path: 'pdf-positions-description/:positionId',
        component: PdfPositionsDescriptionComponent,

      },
      {
        path: 'position-changes',
        component: PositionChangesComponent,
      },
      {
        path: 'position-changes/:positionId',
        component: PositionChangesComponent,
      },
      {
        path: 'pdf-preview-action-change',
        component: PdfActionChangeEmployeeComponent,

      },
      {
        path: 'pdf-preview-action-change/:id',
        component: PdfActionChangeEmployeeComponent,

      },
      {
        path: 'pdf-action-change-employee-license',
        component: PdfActionChangeEmployeeLicenseComponent,

      },
      {
        path: 'pdf-action-change-employee-license/:id',
        component: PdfActionChangeEmployeeLicenseComponent,

      },
      {
        path: 'pdf-action-change-employee-exservices',
        component: PdfActionChangeEmployeeExservicesComponent,

      },
      {
        path: 'pdf-action-change-employee-exservices/:id/:personId',
        component: PdfActionChangeEmployeeExservicesComponent,

      },
      {
        path: 'pdf-action-change-employee-vacations',
        component: PdfActionChangeEmployeeVacationsComponent,

      },
      {
        path: 'pdf-action-change-employee-vacations/:vacancyId',
        component: PdfActionChangeEmployeeVacationsComponent,

      },
      {
        path: 'pdf-action-change-employee-permisos/:id/:personId',
        component: PdfActionChangeEmployeePermisosComponent,

      },
      {
        path: 'pdf-action-change-employee-desvincular',
        component: PdfActionChangeEmployeeDesvincularComponent,

      },
      {
        path: 'pdf-action-change-employee-desvincular/:id/:employeeId',
        component: PdfActionChangeEmployeeDesvincularComponent,

      },
      {
        path: 'employee-personnel-actions-list',
        component: EmployeePersonnelActionsListComponent,

      },
      {
        path: 'departament-list',
        component: DeparmentsListComponent,

      },
      {
        path: 'department-changes/:departmentId',
        component: DepartmentChangesComponent,

      },
      {
        path: 'collective-actions',
        component: CollectiveActionsComponent,

      },
      {
        path: 'collective-vacations',
        component: CollectiveVacationsComponent,

      },
      {
        path: 'separations',
        component: SeparationsComponent,

      },
      {
        path: 'vinculations',
        component: VinculationsComponent,

      },
      {
        path:'change-supervisor',
        component:MassiveChangeSupervisorComponent
      },
      {
        path: 'consult-collective-vacations',
        component: ConsultCollectiveVacationsComponent,

      },

      {
        path: 'content-management',
        component: ContentManagementListComponent,

      },
      {
        path: 'links',
        component: SocialMedialComponent,

      },
      {
        path: 'hourly-teachers',
        component: HourlyTeachersComponent,

      },
      {
        path: 'teacher-reviews',
        component: TeacherReviewsComponent,

      },
      {
        path: 'teacher-reviews/:employeeId',
        component: TeacherReviewsComponent,

      },
      {
        path: 'employee-service-request',
        component: EmployeeServiceRequestComponent,

      },
      {
        path: 'employee-response-request',
        component: EmployeeResponseRequestComponent,

      },
      /*   {
          path: 'payroll',
          loadChildren: () =>
            import('./pages/payroll/payroll.module').then((m) => m.PayrollModule),
        }, */
      {
        path: 'payroll',
        loadChildren: () =>
          import('./pages/payroll-new/pay-roll.module').then((m) => m.payrollModule),

      },
      // {
      //   path: 'map',
      //   loadChildren: () =>
      //     import('./pages/map/map.module').then((m) => m.MAPModule),
      // },
      {
        path: 'vacancies',
        loadChildren: () =>
          import('./pages/post-vacancies/vacancies.module').then((m) => m.VacanciesModule),

      },

      {
        path: 'medical-history',
        loadChildren: () =>
          import('./pages/medical-history/medical-history.module').then((m) => m.MedicalHistoryModule),

      },

      {
        path: 'training-development',
        loadChildren: () =>
          import('./pages/training-development/training-development.module').then((m) => m.TrainingDevelopmentModule),

      },
      {
        path: 'overtime-and-travel-expenses',
        loadChildren: () =>
          import('./pages/overtime-and-travel-expenses/overtime-and-travel-expenses.module').then((m) => m.OvertimeAndTravelExpensesModule),

      },
      {
        path: 'performance-evaluation',
        loadChildren: () =>
          import('./pages/performance-evaluation/performance-evaluation.module').then((m) => m.PerformanceEvaluationModule),

      },
      {
        path: 'planning',
        loadChildren: () =>
          import('./pages/Planning/planning.module').then((m) => m.PlanningModule),

      },
      {
        path: 'employee-service-request',
        component: EmployeeServiceRequestComponent,

      },
      {
        path: 'user-guide/:userGuideName',
        component: UserGuideComponent,

      },
      //{ path: 'action-employ', loadChildren: () => import('./pages/payroll-new/action-employ/action-employ.module').then(m => m.ActionEmployModule) },
      // {
      //   path: 'sec',
      //   loadChildren: () =>
      //     import('@/modules/sec/sec.module').then((m) => m.SECModule),
      // },
      // {
      //   path: '',
      //   pathMatch: 'full',
      //   redirectTo:'/dashboard'
      // },
      // {
      //   path: 'planning/planning-opening',
      //   component: PlanningOpeningPlanningComponent,
      // },
      // {
      //   path: 'planning/application-for-positions-and-vacancies',
      //   component: ApplicationForPositionsAndVacanciesPlanningComponent,
      // },
      // {
      //   path: 'planning/application-for-positions-and-vacancies-rrhh',
      //   component: ApplicationForPositionsAndVacanciesRRHHPlanningComponent,
      // },
      // {
      //   path: 'planning/generate-planning',
      //   component: GeneratePlanningPlanningComponent,
      // },
      // {
      //   path: 'planning/authorize-planning',
      //   component: AuthorizePlanningPlanningComponent,
      // },
      // {
      //   path: 'planning/approve-planning',
      //   component: ApprovePlanningPlanningComponent,
      // },
      // {
      //   path: 'planning/reports',
      //   component: ReportsPlanningComponent,
      // },
      // {
      //   path: 'planning/detail-planning',
      //   component: DetailPlanningPlanningComponent,
      // },
      {
        path: '',
        component: HomeComponent,

      },
      {
        path: 'assist-control',
        component: AssistControlComponent,

      },
      {
        path: 'pdf-control-document/:type/:id/:employeeId',
        component: ControlDocumentComponent,

      },
      {
        path: 'report/payroll-transparency',
        component: PayrollTransparencyComponent
      },
      {
        path: 'frequent-questions',
        component: FrequentQuestionsComponent
      },
      {
        path: 'birthday',
        component: BirthdayComponent
      },
      {
        path: 'planning/detail-vacation-scheduling/:id',
        component: DetailVacationSchedulingComponent
      }

    ]
  }

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
