<h2 class="mat-dialog-title" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">Agregar Habilidades</h2>
<div class="mat-dialog-content">
    <form [formGroup]="skillsForm">
        <div class="row gutter-b">
            <div class="form-group col-lg-6">
                <label>Tipo de habilidad</label>
                <ngx-select-dropdown (change)='skillsChange("typeSkill")' class="flex-1" [config]='config'
                    formControlName='typeSkill' [options]='skillsType'></ngx-select-dropdown>
            </div>

            <div class="form-group col-lg-6">
                <label>Habilidad</label>
                <ngx-select-dropdown (change)='skillsChange("skill")' class="flex-1" [disabled]='skillsForm.controls.typeSkill.invalid' [config]='config' formControlName='skill' [options]='skills'>
                </ngx-select-dropdown>
            </div>

            <div class="form-group col-lg-6">
                <label>Tipo de nivel</label>
                <ngx-select-dropdown class="flex-1" [disabled]='skillsForm.controls.skill.invalid' (change)='skillsChange("typelevel")' [config]='config'
                    formControlName='typelevel' [options]='levelsType'></ngx-select-dropdown>
            </div>

            <div class="form-group col-lg-6">
                <label>Nivel</label>
                <ngx-select-dropdown class="flex-1" [disabled]='skillsForm.controls.typelevel.invalid' [config]='config' formControlName='level' [options]='levels'>
                </ngx-select-dropdown>
            </div>
        </div>
    </form>
</div>
<div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)='Cancel()'>
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-success" type="button" [disabled]='skillsForm.invalid'
        (click)='Create()'>
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>