<div *ngIf="isLoading; else loaded"
    class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
    <el-spinner (loadingStatus)="isLoading=$event" animation="spinner-logo">
    </el-spinner>
</div>

<ng-template #loaded>
    <div class="card card-custom">
        <div class="card-header mt-3">
            <div class="card-title">
                <div class="ttl d-flex align-items-center">
                    <i class="flaticon-colaboracion icon-3x text-danger mr-2"></i>
                    <h1 class="text-primary"> Consulta Acción de Personal </h1>
                </div>
            </div>
            <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
                data-toggle="tooltip" data-original-title="Dashboard">
                <button (click)="openDashboard()"
                    class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto"
                    *ngIf="privilege?.dashboardAction?.value">
                    <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
                </button>
            </div>
        </div>


        <div class="card-header mt-3 row justify-content-between">
            <div class="d-flex justify-content-md-between flex-wrap mt-3">

                <div class="col-sm-12 col-xxl-7">
                    <div class="row card-toolbar">

                        <div class="col-md-3 px-0 mb-3">
                            <div class="form-group mx-1 gap-4 institution">
                                <label class="text-primary">
                                    Estatus / Condición
                                </label>
                                <ng-multiselect-dropdown [placeholder]="'Seleccionar'"
                                    [settings]="dropdownSettings" [data]="statusOptions"
                                    [(ngModel)]="selectedItems"></ng-multiselect-dropdown>
                            </div>
                        </div>
                        <div class="col-md-3 px-0 mb-3">
                            <div class="form-group mx-2 institution">
                                <label>Fecha de Inicio <span class="text-danger">*</span></label>
                                <input [(ngModel)]="startDate" type="date" [max]="getMaxDate(endDate)"
                                    class="form-control form-control-lg" />

                            </div>
                        </div>
                        <div class="col-md-3 px-0 mb-3">
                            <div class="form-group mx-2 institution">
                                <label>Fecha Final <span class="text-danger">*</span></label>
                                <input [(ngModel)]="endDate" [min]="getMinDate(startDate)" type="date"
                                    class="form-control form-control-lg" />

                            </div>
                        </div>

                        <div class="col d-flex align-items-center px-0 mb-3">
                            <button data-toggle="tooltip" data-original-title="Buscar" (click)="filterByDate()"
                                *ngIf="privilege?.searchAction?.value"
                                class="btn btn-success btn-pill font-weight-bolder col-md-auto">
                                <i class="flaticon2-search-1 icon-2x"></i>
                                Buscar
                            </button>
                            <button *ngIf="privilege?.searchAction?.value"
                                class="btn btn-light-warning btn-pill font-weight-bolder mx-2" (click)="resetFilters()">
                                <i class="fas fa-redo  icon-2x" data-toggle="tooltip" data-title="Reiniciar filtro"></i>
                            </button>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-sm-5 c-right mr-0">
                    <div class="card-toolbar d-flex justify-content-end">
                        <div class="dropdown col-md-auto col-sm-12">
                            <button class="btn btn-primary btn-pill dropdown-toggle" type="button"
                                id="dropdownMenuButton" *ngIf="privilege?.exportAction?.value" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                    data-original-title="Opciones de exportar"></i> Exportar
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item align-items-center" (click)="openGeneralReport()">
                                    <i class="fa fa-print icon-2x text-primary mr-3"></i>
                                    Visualizar Reporte
                                </a>
                            </div>
                        </div>


                        <div class="col  text-nowrap">
                            <app-search-bar-pagination #searchBar (onFilter)="filterSearch($event)" (onChangeDropDown)="filterSearch($event)"
                                [namePageLocalStorage]="localStorageSearch" [parameters]="searchBarDisplayNames">
                            </app-search-bar-pagination>

                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row justify-content-center">

                        <div class="col-md-6">

                        </div>
                        <div class="col-md-6">

                        </div>
                    </div>
                </div>
            </div>

        </div>



        <div class="card-body">

            <div class="table-responsive table-responsive-sigei">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-center">Código de Servidor Público</th>
                            <th class="text-left ">Servidor Público</th>
                            <th class="text-left ">Documento de Identidad</th>
                            <th class="text-left ">Unidad Organizativa</th>
                            <th class="text-left">Cargo</th>
                            <th class="text-left ">Tipo de Servidor Público</th>
                            <th class="text-left">Clasificación</th>
                            <th class="text-left ">Tipo</th>
                            <th class="text-right ">Salario</th>
                            <th class="text-left ">Fecha de Registro</th>
                            <th class="text-left ">Fecha Inicio</th>
                            <th class="text-left ">Fecha Final</th>
                            <th class="text-left ">Fecha Efectiva</th>
                            <th class="text-left ">Estatus</th>
                            <th class="text-left ">Condición</th>
                            <th class="actionsBtn text-center ">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let employee of actionsList">
                            <td class="text-center">{{employee.employNumber}}</td>
                            <td class="text-left w-9">{{employee.name}}</td>
                            <td class="text-left w-9">{{employee.personalIdentification |mask:'000-0000000-0'}}</td>
                            <td class="text-left">{{employee.departmentName}}</td>
                            <td class="text-left">{{employee.positionName}}</td><!--{{employee.name}}-->
                            <td class="text-left w-10">{{employee.employeeType}}</td>
                            <td class="text-left">{{employee.personalActionsType || 'N/A'}}</td>
                            <td class="text-left ">{{employee?.typePermissions}}</td>
                            <td class="text-right ">{{employee?.salary | currency:'RD$'}}</td>
                            <td class="text-left">{{employee.createDate | date:'dd/MM/yyyy'}}</td>
                            <td class="text-left">{{employee.dateStart | date:'dd/MM/yyyy'}}</td>
                            <td class="text-left">{{employee.dateEnd | date:'dd/MM/yyyy'}}</td>
                            <td class="text-left">{{employee.effectiveDate | date:'dd/MM/yyyy'}}</td>
                            <td class="text-center-15">
                                <span style="width: 137px;">
                                    <ng-container *ngIf="!employee.status; else notInactivesStatus">
                                        <span class="font-weight-bold text-danger">
                                            Inactiva
                                        </span>
                                    </ng-container>
                                    <ng-template #notInactivesStatus>
                                        <span class="font-weight-bold text-success">
                                            Activa
                                        </span>
                                    </ng-template>
                                </span>
                            </td>
                            <td class="text-center-15">
                                <span style="width: 137px;">
                                        <ng-container
                                            *ngIf="employee.requestedToBeCanceled && (employee.cancelRequestConditionId == 1 || employee.cancelRequestConditionId == 2 || employee.cancelRequestConditionId == 4); else switchConditions"
                                            [ngSwitch]="employee.cancelRequestConditionId">
                                            <span *ngSwitchCase="1" class="font-weight-bold text-info">Pendiente de
                                                Anulación</span>
                                            <span *ngSwitchCase="2" class="font-weight-bold text-warning">Pendiente de
                                                Aplicar Anulación</span>
                                            <span *ngSwitchCase="4" class="font-weight-bold text-danger">Anulado</span>
                                            <span *ngSwitchDefault class="font-weight-bold ">
                                                <span class="mr-2">N/A</span>
                                            </span>
                                        </ng-container>
                                        <ng-template [ngSwitch]="employee.conditionId" #switchConditions>
                                            <span *ngSwitchCase="1"
                                                class="font-weight-bold text-info">{{employee.condition
                                                }}</span>
                                            <span *ngSwitchCase="2"
                                                class="font-weight-bold text-success">{{employee.condition
                                                }}</span>
                                            <span *ngSwitchCase="3"
                                                class="font-weight-bold text-danger">{{employee.condition
                                                }}</span>
                                            <span *ngSwitchCase="5"
                                                class="font-weight-bold text-success">{{employee.condition
                                                }}</span>
                                            <span *ngSwitchCase="6"
                                                class="font-weight-bold text-info">{{employee.condition
                                                }}</span>
                                            <span *ngSwitchCase="7"
                                                class="font-weight-bold text-success">{{employee.condition
                                                }}</span>
                                            <span *ngSwitchCase="9"
                                                class="font-weight-bold text-info">{{employee.condition
                                                }}</span>
                                            <span *ngSwitchCase="10"
                                                class="font-weight-bold text-danger">{{employee.condition
                                                }}</span>
                                            <span *ngSwitchDefault class="font-weight-bold ">
                                                <ng-container *ngIf="employee.wasCanceled; else notValue">
                                                    <span class="font-weight-bold text-danger">
                                                        Anulado
                                                    </span>
                                                </ng-container>
                                                <ng-template #notValue>
                                                    <span class="mr-2">N/A</span>
                                                </ng-template>
                                            </span>
                                        </ng-template>
                                </span>
                            </td>
                            <td class="actionsBtn">
                                <ng-container *ngIf="employee.conditionId != 1">
                                    <button
                                        *ngIf="employee.typePersonalActionId === 2
                                                                        && employee.conditionId !== 3 && privilege?.viewAction?.value"
                                        class="btn btn-icon btn-light-info btn-sm m-1" (click)="openReport(employee)">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                                    </button>

                                    <a *ngIf="employee.typePersonalActionId === 10
                                                                    && employee.conditionId !== 3 && privilege?.viewAction?.value"
                                        class="btn btn-icon btn-light-info btn-sm m-1" (click)="openReport(employee)">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                                    </a>

                                    <a *ngIf="(employee.typePersonalActionId === 4 ||
                                                                        employee.typePersonalActionId === 7 || employee.typePersonalActionId === 8
                                                                        || employee.typePersonalActionId === 12)
                                                                        && employee.conditionId !== 3 && privilege?.viewAction?.value"
                                        (click)="openReport(employee)" class="btn btn-sm btn-light-info btn-icon m-1">
                                        <i class="flaticon-ver" data-toggle="tooltip" title="Ver"></i>
                                    </a>

                                    <a *ngIf="employee.typePersonalActionId === 9
                                                                        && employee.conditionId !== 3 && privilege?.viewAction?.value"
                                        (click)="openReport(employee)" class="btn btn-sm btn-light-info btn-icon m-1">
                                        <i class="flaticon-ver" data-toggle="tooltip" title="Ver"></i>
                                    </a>

                                    <a *ngIf="employee.typePersonalActionId === 1
                                                                        && employee.conditionId !== 3 && privilege?.viewAction?.value"
                                        class="btn btn-icon btn-light-info btn-sm m-1">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"
                                            (click)="openReport(employee)"></i>
                                    </a>

                                    <!-- <a *ngIf="getPersonnelActionForPDF(employee.typePersonalActionId,'/pdf-preview-action-change')
                                                                        && employee.conditionId !== 3 && privilege?.viewAction?.value"
                                                                        class="btn btn-icon btn-light-info btn-sm m-1"
                                                                        [routerLink]="['/pdf-preview-action-change',employee.personalActionId]">
                                                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                                                                        </a> -->

                                    <a *ngIf="employee.typePersonalActionId === 11
                                                                        && employee.conditionId !== 3 && privilege?.viewAction?.value"
                                        class="btn btn-icon btn-light-info btn-sm m-1" (click)="openReport(employee)">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                                    </a>

                                    <a *ngIf="employee.typePersonalActionId === 14
                                        && employee.conditionId !== 3 && privilege?.viewAction?.value"
                                        class="btn btn-icon btn-light-info btn-sm m-1" (click)="openReport(employee)">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                                    </a>


                                </ng-container>

                                <button *ngIf="employee.canBeCanceled && privilege.cancelAction.value"
                                    class="btn btn-icon btn-light-danger btn-sm m-1" data-toggle="tooltip"
                                    title="Anular" (click)="openCancelActionModal(employee,viewTypes.Create)">
                                    <i class="flaticon-carpeta-4 icon-2x"></i>
                                </button>

                                <ng-container *ngIf="employee.requestedToBeCanceled ">

                                    <button *ngIf="privilege.viewCancelAction.value"
                                        class="btn btn-icon btn-light-info btn-sm m-1" data-toggle="tooltip"
                                        title="Ver Solicitud de Anulación"
                                        (click)="openCancelActionModal(employee,viewTypes.View)">
                                        <i class="flaticon-carpeta-4 icon-2x"></i>
                                    </button>

                                    <button
                                        *ngIf="employee.cancelRequestConditionId == 1 && privilege.approveCancelAction.value"
                                        class="btn btn-icon btn-light-success btn-sm m-1" data-toggle="tooltip"
                                        title="Aprobar y Aplicar Anulación"
                                        (click)="openChangeCancelConditionModal(employee,1)">
                                        <i class="flaticon-correccion icon-2x"></i>
                                    </button>

                                    <button
                                        *ngIf="employee.cancelRequestConditionId == 2 && privilege.applyCancelAction.value"
                                        class="btn btn-icon btn-light-success btn-sm m-1" data-toggle="tooltip"
                                        title="Aplicar Anulación" (click)="openChangeCancelConditionModal(employee,2)">
                                        <i class="flaticon-check icon-2x"></i>
                                    </button>

                                    <button
                                        *ngIf="employee.cancelRequestConditionId == 1 && privilege.rejectCancelAction.value"
                                        class="btn btn-icon btn-light-danger btn-sm m-1" data-toggle="tooltip"
                                        title="Rechazar Anulación" (click)="openChangeCancelConditionModal(employee,3)">
                                        <i class="flaticon-mala-critica icon-2x"></i>
                                    </button>
                                </ng-container>


                                <a *ngIf="privilege?.personnelAction?.value" (click)="setRefferedTab(employee)"
                                    [routerLink]="['/action-change-employee',employee.employeeId, 1]"
                                    class="btn btn-icon btn-light-success btn-sm m-1" data-toggle="tooltip"
                                    title="Acciones del Personal">
                                    <i class="flaticon-colaboracion icon-2x"></i>
                                </a>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <div>

                        <app-paginator [listedRecordsLength]="actionsList?.length"
                            [registerQuantity]="employeesPagedResult.totalItems"
                            [registerPerPage]="employeesPagedResult.pageSize"
                            [totalPages]="employeesPagedResult.totalPages" [selectedPage]="employeesPagedResult.page"
                            (onChange)="getPaginatedRecords($event)">
                        </app-paginator>
                    </div>
                </div>
            </div>

        </div>

    </div>


</ng-template>
