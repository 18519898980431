import * as moment from 'moment';
import Swal from 'sweetalert2';
import { DropDownOption } from '../models/dropDownOption';

export class ValidationIdentification {
  static validate(): string {
    return 'hi';
  }
}

export class DateUtil {
  public static getLocalNow(): moment.Moment {
    return moment().local();
  }

  public static getUtcNow(): moment.Moment {
    return moment().utc();
  }

  public static create(year: number, month: number, day: number, hour?: number, minute?: number, second?: number, millisecond?: number): moment.Moment {
    return moment({
      year: year,
      month: month - 1,
      day: day,
      hour: hour ?? 0,
      minute,
      second: second ?? 0,
      millisecond: millisecond ?? 0
    });
  }

  public static parse(
    value: any,
    isUtc?: boolean,
    inFormat?: moment.MomentFormatSpecification
  ): moment.Moment | null {
    if (value == null || value === '') {
      return null;
    }

    isUtc ??= true;
    inFormat ??= moment.ISO_8601;

    let date = isUtc ? moment.utc(value, inFormat).local() : moment(value, inFormat).local();

    return date.isValid() ? date : null;
  }

  public static formatDate(
    value: any,
    isUtc?: boolean,
    outFormat?: string,
    inFormat?: moment.MomentFormatSpecification
  ): string | null {
    isUtc ??= true;
    outFormat ??= 'DD/MM/YYYY';
    inFormat ??= moment.ISO_8601;

    return DateUtil.parse(value, isUtc, inFormat)?.format(outFormat) ?? null;
  }

  public static formatTime(
    value: any,
    outFormat?: string,
    inFormat?: string
  ): string | null {
    if (value == null || value === '') {
      return null;
    }

    outFormat ??= 'hh:mm A';
    inFormat ??= 'HH:mm';

    let time = moment(value, inFormat);

    return time.isValid() ? time.format(outFormat) : null;
  }

  public static formatDateTime(
    value: any,
    isUtc?: boolean,
    outFormat?: string,
    inFormat?: moment.MomentFormatSpecification
  ): string | null {
    isUtc ??= true;
    outFormat ??= 'DD/MM/YYYY hh:mm A';
    inFormat ??= moment.ISO_8601;

    return DateUtil.parse(value, isUtc, inFormat)?.format(outFormat) ?? null;
  }
}

export const validatePhone = (phone) => {
  const PhonePrefix = '+1'
  const PhoneLenght = 16;

  if (phone && phone != PhonePrefix && phone.length == PhoneLenght) {
    return true;
  }

  return false;
}

export class NumberUtil {
  public static format(value: number, type: 'percent' | 'integer' | 'decimal'): string {
    if (type === 'percent') {
      return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
      }).format(value);
    }

    // Although it's a shared pipe, it's only being used within the training module, so it doesn't affect the others.
    if (type === 'integer') {
      return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: true
      }).format(value);
    }

    if (type === 'decimal') {
      return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
      }).format(value);
    }

    return '';
  }
}

export class DropDownOptionUtil {
  public static DefaultFilter<T>(option: DropDownOption<T>): boolean {
    return option.text != null && option.text !== '';
  }

  public static DefaultSorter<T>(left: DropDownOption<T>, right: DropDownOption<T>): number {
    if (left.text < right.text) {
      return -1;
    }

    if (left.text > right.text) {
      return 1;
    }

    return 0;
  }
}

export const getMask = (ocode: number) => {
  /*
    ocode 1 es el ocode de cedula
    ocode 4 es el ocode de licencia
  */
  if(ocode === 1 || ocode === 4){
    return '000-0000000-0'
  }
  return ''
}


export const getPercentage = (total: number, count: number, decimals: number = 1) => {
  const stringResult = ((count/total)*100).toFixed(decimals)
  const result = Number(stringResult)
  if(isNaN(result)){
    return 0
  }
  return result
}

export const mapArrays = (arrayToMap: any[] = [], arrayMap:any[], fieldId: string) => {
  const mappedArray = arrayToMap.map(x => {
    const item = arrayMap.find(c => c[fieldId] == x[fieldId])
    if(item){
      return item
    }
  })
  return mappedArray
}

export const getRegex = (type: 'number') => {
  if( type == 'number' ) {
    return /^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/
  }
  return null
}

export const swalConfirmDelete = (fn: () => void) => {
  Swal.fire({
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: '#eb220c',
    title: "¿Está seguro que desea eliminar este registro?",
    icon: "question"
  }).then(res => {
    if (res?.isConfirmed) {
      fn();
    }
  });
}
export const swalWarning = (title, content, icon, width = null) => {
  return Swal.fire({
    showConfirmButton: true,
    showCancelButton: false,
    confirmButtonColor: '#003876',
    confirmButtonText: 'Aceptar',
    html: content,
    title: title,
    width: width,
    icon: icon
  })
}

export const getDOCurrencyFormat = (param):string => {
  if(!param) return ''
  return param.toLocaleString('es-DO', {
    style: 'currency',
    currency: 'DOP',
  })
}
export const removeWhitespace = (event, control, alwaysTrimLeft = false) => {
  const letter = event.data
  if(alwaysTrimLeft){
    control.patchValue(((control.value || '') as any).trimLeft())
    return
  }
  if(control.value.length == 1){
    if(letter == ' '){
      control.patchValue(((control.value || '') as any).trimLeft())
    }
  }
  
}
export const dropdownGlobalConfig = (displayKey: string) => {
  return {
    displayKey: displayKey,
    search: true,
    height: 'auto',
    placeholder: 'Seleccionar',
    moreText: 'más',
    noResultsFound: 'No se han encontrado registros',
    searchPlaceholder: 'Buscar',
    searchOnKey: displayKey
  }
}

export const openReport = ({url, title, width, height}) => {
  const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

  const innerWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const innerHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (innerWidth - width) / 2 / systemZoom + dualScreenLeft
  const top = (innerHeight - height) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open(url, title,
    `
    scrollbars=yes,
    width=${width / systemZoom},
    height=${height / systemZoom},
    top=${top},
    left=${left}
    `
  )
  if (window.focus) newWindow.focus();
}
