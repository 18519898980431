import { DatePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { FilterDashboardService } from 'src/app/services/filter-dashboard.service';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css']
})
export class DateFilterComponent implements OnInit, AfterViewChecked {
  dropdownItems = []
  datePipe =  new DatePipe('en-Us')
  filterForm = new FormGroup({
    year: new FormControl(''),
    dateFrom: new FormControl(''),
    dateTo: new FormControl(''),
    companyId: new FormControl(null),
    company: new FormControl(null),
  })
  invalidFilter: boolean = false

  years: {year: number}[];

  constructor(
    public dialogRef: MatDialogRef<DateFilterComponent>,
    private _changeDet: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private filterService: FilterDashboardService,
    private authInfo: AuthInfoService,
    private toastr: ToastrService
  ) {
    this.dialogRef.disableClose = true;
    this.filterForm.patchValue({
      year: null,
      dateFrom: this.datePipe.transform(`${this.data.dateFrom}`,'yyyy-MM-dd'),
      dateTo: this.datePipe.transform(`${this.data.dateTo}`,'yyyy-MM-dd'),
      companyId: this.data.companyId
    });
    
  }
  
  getCompanies(){
    const companyId = this.authInfo.getCompanyId()
    this.filterService.getCompaniesById(companyId).subscribe((res:any) => {
      if(res.succeded){
        this.dropdownItems = res.dataList
        this.filterForm.get('company').patchValue(this.dropdownItems.find(x => x.companyId == this.filterForm.get('companyId').value))
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }

  ngOnInit(): void {
    this.years = [
      {year: new Date().getFullYear() - 2,},
      {year: new Date().getFullYear() - 1},
      {year: new Date().getFullYear()}
    ]
    this.getCompanies()
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  close(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

  onYearChange(year: string) {
    this.filterForm.get('dateFrom').setValue(this.datePipe.transform(`${year}/01/01`,'yyyy-MM-dd'))
    this.filterForm.get('dateTo').setValue(this.datePipe.transform(`${year}/12/31`,'yyyy-MM-dd'))
  }

  applyFilter() {
    if(!this.filterForm.get('companyId').value){
      this.toastr.warning('Debe seleccionar una institución')
      return
    }
    if (this.filterForm.get('dateFrom').value == '' || this.filterForm.get('dateTo').value == '') {
      this.invalidFilter = true
      return
    }
    this.dialogRef.close({
      ...this.filterForm.value,
      Applied: true
    });
  }

  clear(){
    this.filterForm.patchValue({
      year:null,
      dateFrom:null,
      dateTo:null,
      companyId: null,
      company: null
    })
  }

  changeCompany(){
    const company = this.filterForm.get('company')
    if(!company.value || company.value.length < 1){
      this.filterForm.get('companyId').patchValue(null)
      return
    }
    this.filterForm.get('companyId').patchValue(company.value.companyId)
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey,
      limitTo: 0
    }
  }
}
