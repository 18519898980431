import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {
  @Input() licenseId: number
  @Input() employee: any
  subscriptions: Subscription = new Subscription();
  license:any

  constructor(private personnelActionsService: PersonnelActionsService, private _toastService: ToastrService) { }

  ngOnInit(): void {
    this.loadLicense()
  }

  loadLicense(){
    let getLicense = this.personnelActionsService.getEmployeeLicenseById(this.licenseId).subscribe((res:any)=>{
       if(res.errors.length > 0){
         this._toastService.error("Ha ocurrido un error al obtener los datos de la licencia")
         return;
       }
       this.license = res.dataList[0];
     },error=>{
       this._toastService.error("Error al buscar la licencia")
       return;
     });
 
     this.subscriptions.add(getLicense);
   }

}
