import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { formatDate, Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-control-document',
  templateUrl: './control-document.component.html',
  styleUrls: ['./control-document.component.css']
})
export class ControlDocumentComponent implements OnInit {
  type: number
  id: number
  employeeId: number
  employee: any;
  changeDate:string
  currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

  vacationsData: any = {
    dateStart: null,
    dateEnd: null,
    comment: '',
    vacationInfo: ''
  }

  comment: string = '';
  constructor(private _route: ActivatedRoute, private srvActionChange: PersonnelActionsService, private _toastService: ToastrService,
    private location: Location) {
    this.type = Number(this._route.snapshot.params["type"] || 0)
    this.id = Number(this._route.snapshot.params["id"] || 0)
    this.employeeId = Number(this._route.snapshot.params["employeeId"] || 0)

  }

  ngOnInit(): void {
    this.getEmployeeById()
  }

  goBack() {
    this.location.back();
  }

  getEmployeeById() {
    this.srvActionChange.getEmployeeById(this.employeeId).subscribe((res) => {

      if (!res.succeded) {
        res.errors.forEach(err => {
          this._toastService.error(err);
        });

        res.warnings.forEach(err => {
          this._toastService.warning(err);
        })

        return;
      }

      this.employee = res.dataList[0];
    }, error => {
      this._toastService.error('Ha ocurrido un error tratando de consultar los datos del Servidor Público')
    })
  }

  getChangeDate(date: string) {
    this.changeDate = date
  }

}
