import { Component, OnInit } from '@angular/core';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';

@Component({
  selector: 'app-finance-planning-approval',
  templateUrl: './finance-planning-approval.component.html',
  styleUrls: ['./finance-planning-approval.component.css']
})
export class FinancePlanningApprovalComponent implements OnInit {
  
  sweetAlert = new SweetAlert();

  constructor() { }

  ngOnInit(): void {
    this.developing();
  }

  async developing() { const success = await this.sweetAlert.ErrorAlert(); }

}
