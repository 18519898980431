<div class="container">
    <div class="alert alert-warning fs-5">
        <i class="fa fa-exclamation-circle text-white" aria-hidden="true"></i>
        Solo es permitido archivos tipo imagen jpg, o documentos .pdf
    </div>
    <!-- <span class="text-muted mb-5"></span> -->
    <div class="row arequerido">
        <div class="col-xl-3 col-lg-6">

            <div class="card mb-5 full-h">
                <h5 class="d-block p-3 text-danger">
                    <label class="">
                        Cédula de identidad
                    </label>
                </h5>
                <uppy-file #uppyFiles previewDefaultImage="{{imgDefaultPdf}}"
                    classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px" [cleanAll]="false"
                    [allowedFileTypes]="['application/pdf', 'image/*']"
                    [fileForEditting]="personalInfo?.identificationImage" [confirmation]="true"
                    (changeEmitter)="changeDetector()" (fileEmitter)="setFormFile($event,0)"
                    targetClass="identificationImageId">
                </uppy-file>
            </div>
        </div>

        <div class="col-xl-3 col-lg-6">
            <div class="card mb-5 full-h">
                <h5 class="d-block p-3 text-danger">Foto 2x2
                </h5>

                <uppy-file #uppyFiles previewDefaultImage="{{imgDefaultPdf}}"
                    classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px" [cleanAll]="false"
                    [allowedFileTypes]="['application/pdf', 'image/*']" [fileForEditting]="data?.photo"
                    [confirmation]="true" (changeEmitter)="changeDetector()" (fileEmitter)="setFormFile($event,1)"
                    targetClass="photo2x2Id">
                </uppy-file>

            </div>
        </div>
        <div class="col-xl-3 col-lg-6">
            <div class="card mb-5 full-h">
                <h5 class="d-block p-3 text-danger">Currículum vitae</h5>

                <uppy-file #uppyFiles previewDefaultImage="{{imgDefaultPdf}}"
                    classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px" [cleanAll]="false"
                    [allowedFileTypes]="['application/pdf']" [fileForEditting]="data?.cv" [confirmation]="true"
                    (changeEmitter)="changeDetector()" (fileEmitter)="setFormFile($event,2)" targetClass="cvId">
                </uppy-file>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6">
            <div class="card mb-5 full-h">
                <h5 class="d-block p-3 text-danger">
                    <label class="">
                        Certificado no antecedentes penales
                    </label>
                </h5>

                <uppy-file #uppyFiles previewDefaultImage="{{imgDefaultPdf}}"
                    classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px" [cleanAll]="false"
                    [allowedFileTypes]="['application/pdf']" [fileForEditting]="data?.criminalRecord"
                    [confirmation]="true" (changeEmitter)="changeDetector()" (fileEmitter)="setFormFile($event,3)"
                    targetClass="criminalRecordId">
                </uppy-file>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6">
            <div class="card mb-5 full-h">
                <h5 class="d-block p-3 text-danger">
                    <label class="">
                        Certificado médico
                    </label>
                </h5>

                <uppy-file #uppyFiles previewDefaultImage="{{imgDefaultPdf}}"
                    classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px" [cleanAll]="false"
                    [allowedFileTypes]="['application/pdf']" [fileForEditting]="data?.medicalCertificate"
                    [confirmation]="true" (changeEmitter)="changeDetector()" (fileEmitter)="setFormFile($event,4)"
                    targetClass="medicalCertificateId">
                </uppy-file>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6">

            <div class="card mb-5 full-h">
                <h5 class="d-block p-3 text-danger">Foto licencia de conducir
                </h5>

                <uppy-file #uppyFiles previewDefaultImage="{{imgDefaultPdf}}"
                    classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px" [cleanAll]="false"
                    [allowedFileTypes]="['application/pdf', 'image/*']"
                    [fileForEditting]="personalInfo?.driveLicenseImage" [confirmation]="true"
                    (changeEmitter)="changeDetector()" (fileEmitter)="setFormFile($event,5)"
                    targetClass="driveLicenseImageId">
                </uppy-file>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6">

            <div class="card mb-5 full-h">
                <h5 class="d-block p-3 text-danger">Misceláneos
                </h5>

                <uppy-file #uppyFiles previewDefaultImage="{{imgDefaultPdf}}"
                    classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px" [cleanAll]="false"
                    [allowedFileTypes]="['application/pdf', 'image/*']" [fileForEditting]="personalInfo?.miscellaneous"
                    [confirmation]="true" (changeEmitter)="changeDetector()" (fileEmitter)="setFormFile($event,6)"
                    targetClass="miscellaneousImageId">
                </uppy-file>
            </div>
        </div>
    </div>

</div>