import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
// import { IChartData } from '../models/chartModels';

@Component({
  selector: 'app-bar-chart-race',
  templateUrl: './bar-chart-race.component.html',
  styleUrls: ['./bar-chart-race.component.css']
})
export class BarChartRaceComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  private root: am5.Root;
  @Input() categoryField = 'description'
  @Input() isCurrency: boolean = false
  @Input() valueField = 'value'
  @Input() year = 0;
  @Input() data = []
  @Input() id: string = 'chart-bar1';
  @Input() autoHeight = false
  height = '500px'
  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      if (this.root) {
        this.root.dispose()
        this.root = am5.Root.new(this.id)
        this.buildChart()
      }
    }
  }


  calculateNewHeight() {
    if (this.autoHeight == false) return
    const length = this.data.length
    const heightPerItem = (length / 4)
    const defaultHeight = '457px'
    if(length <= 16){
      this.height = defaultHeight
      return
    }
    const height = ((heightPerItem * length) + 460) + 'px'
    this.height = height
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit() {
    this.root = am5.Root.new(this.id);
    this.buildChart()
  }
  buildChart() {
    this.calculateNewHeight()
    if (!this.root) return
    this.browserOnly(() => {

      let root = this.root
      let allData = this.data;

      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element


      root.numberFormatter.setAll({
        numberFormat: this.isCurrency ? "RD$ #,###" : "#,###",

        smallNumberPrefixes: []
      });

      let stepDuration = 2000;


      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "none",
        wheelY: "none"
      }));


      // We don't want zoom-out button to appear while animating, so we hide it at all
      chart.zoomOutButton.set("forceHidden", true);


      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let yRenderer = am5xy.AxisRendererY.new(root, {
        minGridDistance: 20,
        inversed: true
      });
      // hide grid
      yRenderer.grid.template.set("visible", false);

      let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "network",
        renderer: yRenderer
      }));

      let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        strictMinMax: true,
        numberFormat: "#a",
        extraMax: 0.1,
        renderer: am5xy.AxisRendererX.new(root, {})
      }));

      xAxis.set("interpolationDuration", stepDuration / 10);
      xAxis.set("interpolationEasing", am5.ease.linear);


      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "value",
        categoryYField: "network"
      }));

      // Rounded corners for columns
      series.columns.template.setAll({ cornerRadiusBR: 5, cornerRadiusTR: 5 });

      // Make each column to be of a different color
      series.columns.template.adapters.add("fill", function (fill, target: any) {
        return chart.get("colors").getIndex(series.columns.indexOf(target));
      });

      series.columns.template.adapters.add("stroke", function (stroke, target: any) {
        return chart.get("colors").getIndex(series.columns.indexOf(target));
      });

      // Add label bullet
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          sprite: am5.Label.new(root, {
            text: "{valueXWorking}",
            fill: root.interfaceColors.get("alternativeText"),
            centerX: am5.p100,
            centerY: am5.p50,
            populateText: true
          })
        });
      });

      let label = chart.plotContainer.children.push(am5.Label.new(root, {
        text: this.year > 0 ? this.year?.toString() : '',
        fontSize: "8em",
        opacity: 0.2,
        x: am5.p100,
        y: am5.p100,
        centerY: am5.p100,
        centerX: am5.p100
      }));

      // Get series item by category
      function getSeriesItem(category) {
        for (var i = 0; i < series.dataItems.length; i++) {
          let dataItem = series.dataItems[i];
          if (dataItem.get("categoryY") == category) {
            return dataItem;
          }
        }
      }

      // Axis sorting
      function sortCategoryAxis() {
        // sort by value
        series.dataItems.sort(function (x, y) {
          return y.get("valueX") - x.get("valueX"); // descending
          //return x.get("valueX") - y.get("valueX"); // ascending
        });

        // go through each axis item
        am5.array.each(yAxis.dataItems, function (dataItem) {
          // get corresponding series item
          let seriesDataItem = getSeriesItem(dataItem.get("category"));

          if (seriesDataItem) {
            // get index of series data item
            let index = series.dataItems.indexOf(seriesDataItem);
            // calculate delta position
            let deltaPosition =
              (index - dataItem.get("index", 0)) / series.dataItems.length;
            // set index to be the same as series data item index
            if (dataItem.get("index") != index) {
              dataItem.set("index", index);
              // set deltaPosition instanlty
              dataItem.set("deltaPosition", -deltaPosition);
              // animate delta position to 0
              dataItem.animate({
                key: "deltaPosition",
                to: 0,
                duration: stepDuration / 2,
                easing: am5.ease.out(am5.ease.cubic)
              });
            }
          }
        });
        // sort axis items by index.
        // This changes the order instantly, but as deltaPosition is set, they keep in the same places and then animate to true positions.
        yAxis.dataItems.sort(function (x, y) {
          return x.get("index") - y.get("index");
        });
      }

      let year = this.year;
      let categoryField = this.categoryField;
      let valueField = this.valueField;
      function setInitialData() {
        let d = allData;

        for (var n in d) {
          series.data.push({ network: d[n][categoryField], value: d[n][valueField] });
          yAxis.data.push({ network: d[n][categoryField] });
        }
      }

      function updateData() {
        let itemsWithNonZero = 0;

        if (allData[year]) {
          label.set("text", year > 0 ? year?.toString() : '');

          am5.array.each(series.dataItems, function (dataItem) {
            let category = dataItem.get("categoryY");
            let value = allData[year][category];

            if (value > 0) {
              itemsWithNonZero++;
            }

            dataItem.animate({
              key: "valueX",
              to: value,
              duration: stepDuration,
              easing: am5.ease.linear
            });
            dataItem.animate({
              key: "valueXWorking",
              to: value,
              duration: stepDuration,
              easing: am5.ease.linear
            });
          });

          yAxis.zoom(0, itemsWithNonZero / yAxis.dataItems.length);
        }
      }

      setInitialData();
      /* setTimeout(function () {
        year++;
        updateData();
      }, 50); */

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear(1000);
      chart.appear(1000, 100);
    })

  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
        this.root = undefined
        document.getElementById(this.id).remove()
      }
    });
  }
  ngOnInit(): void {
  }
}
