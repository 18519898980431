export interface IPlanning {
  planingsId: number;
  companyId: number;
  years: number;
  positionId: number;
  departmentId: number;
  ocupationalGroupId: number;
  requisitionTypeId: number;
  positionsNumber: number;
  salary: number;
  status: boolean;
  budgetCode?: any;
  positionName: string;
  departmentName: string;
  occupationalGroup: string;
  requisitionType: string;
  isNew: boolean;
  isClosed: boolean;
  condition?: any;
  dateCondition?: any;
  rejectionReason?: any;
}

export interface PlanningHeader {
  planningHeaderId: number;
  yearExecution: number;
  lastYear: number;
  requestedBudget: number;
  approvedBudget: number;
  executedBudget: number;
  availableBudget: number;
  totalCurrentSalary: number;
  createDate: Date;
  createUserId: number;
  modifyDate?: Date;
  modifyUserId?: number;
  companyId: number;
  condition: string;
  conditionId: number;
  status: boolean;
  sendDate: Date;
  sendUserId: number;
  sendUserName: string;
  approveDate?: Date;
  approveUserId?: number;
  rejectDate?: Date;
  rejectUserId?: number;
  finishedDate?: Date;
  finishedUserId?: number;
  disableCheckButton?: boolean;
  disableButton?: boolean;
  document?: string;
  surplusPercentage?: number;
  surplus?: number;
  surplusPercentageMessage?: string;
}

export interface PostPlanningHeader {
  companyId: number;
  yearExecution: number;
  lastYear: number;
  userId: number;
  approvedBudget: number;
  startDate: Date;
  endDate: Date;
}

export enum PlanningCondition {
  InProcess = 1,
  PendingReview = 2,
  Revised = 3,
  InternallyRejected = 4,
  SentMAP = 5,
  ApprovedMAP = 6,
  RejectedMAP = 7
}

export interface ChangeConditionPlanning {
  planningHeaderId: number;
  conditionId?: number;
  sendCode?: string;
  userId: number;
}

export interface openPlanning {
  companyId: number;
  lastYear: number;
  yearExecution: number;
  userId: number;
  approvedBudget: number;
}

export class PlanningPlace {
  planningPlaceRequestId?: number;
  planningHeaderId: number;
  departmentId: number;
  positionId: number;
  salary: number;
  startDate: Date;
  placeQuantity: number;
  userId: number;

  constructor() {
    this.planningPlaceRequestId = 0
    this.planningHeaderId = 0
    this.departmentId = 0
    this.positionId = 0
    this.salary = 0
    this.startDate = null
    this.placeQuantity = 0
    this.userId = 0
  }
}

export class PlanningPlaces {
  requests: PlanningPlace[]

  constructor() {
    this.requests = []
  }
}

export interface PlanningHeaderType2 {
  planningHeaderId: number;
  yearExecution: number;
  lastYear: number;
  approvedBudget: number;
  executedBudget?: any;
  availableBudget?: any;
  createDate: Date;
  createUserId: number;
  modifyDate?: any;
  modifyUserId?: any;
  companyId: number;
  conditionId: number;
  status: boolean;
  sentDate?: any;
  senderUserId?: any;
  approvedDate?: any;
  approvedUserId?: any;
  rejectedDate: Date;
  rejectedUserId: number;
  finishedDate?: any;
  finishedUserId?: any;
  sendCode?: any;
  condition: string;
  approvedMapDate: Date;
  approvedMapUserId: number;
  senderName?: any;
  startDate?: any;
  endDate?: any;
}

export interface QuantityPlacePositions {
  planningHeaderId: number;
  planningRequestTypeId: number;
  totalPlanning: number;
  currentQuantityPlace: number;
  currentQuantityPosition: number;
  different: number;
  companyId: number;
}

export interface PlanningStatistics {
  section: number;
  planningHeaderId: number;
  typeEmployeeId: number;
  employeeType: string;
  amount: number;
  annualAmount: number;
}

export interface PlanningPlacePositionStatistic {
  viewGetQuantityPlacePositions: QuantityPlacePositions[];
  viewGetPlanningStatistics: PlanningStatistics[]
}

export enum PlanningRequestType {
  Position = 1,
  Place = 2
}

export interface PlanningApprove {
  planningApproveId?: number;
  planningHeaderId: number;
  employeeAprovedId: number;
  generatedToken: string;
  confirmationToken: string;
  approvedLevel: number;
  approvedDate?: string;
  status: boolean;
  companyId: number;
  createDate?: string;
  createUserId: number;
  modifyDate?: string;
  modifyUserId?: number;
}

export interface PlanningApproveSetting {
  planningApproveSettingId: number;
  planningHeaderId: number;
  approvedLevel: number;
  status: boolean;
  companyId: number;
  createDate: Date;
  createUserId: number;
  modifyDate: Date;
  modifyUserId: number;
  disableButton?: boolean;
  nameKey?: string;
}

export interface PlanningRejectionReason {
  rejectionReasonId?: number;
  planningHeaderId: number;
  motiveId: number;
  motive?: string;
  reason: string;
  userId: number;
  createdDate?: Date;
  createdUserId?: number;
  type: TypeRejectPlanning;
  status?: boolean;
}

export enum TypeRejectPlanning {
  InternallyRejected = 1,
  RejectedMAP
}

export interface PlanningRequest {
  planningPlaceRequestId: number;
  planningHeaderId: number;
  yearExecution: number;
  departmentId: number;
  departmentName: string;
  positionId: number;
  positionName: string;
  salary: number;
  startDate: Date;
  placeQuantity: number;
  createDate: Date;
  createUserId: number;
  modifyDate: null;
  modifyUserId: null;
  status: boolean;
  conditionId: number;
  condition: string;
  planningHeaderConditionId: number;
  occupationalGroupId: number;
  occupationalGroup: string;
  planningRequestTypeId: number;
  planningRequestType: string;
  sendDateUserId: number;
  sendDate: Date;
  rejectUserId: null;
  rejectDate: null;
  approveUserId: number;
  approveDate: Date;
  estimatedSalaryAnnual: number;
  estimatedSalaryMonthly: number;
  typeEmployeeId: number;
  typeEmployee: string;
  companyId: number;
  description?: string;
}

export interface PlanningDashboard {
  totalPlanningPositions: PlanningDashboardQuantities[];
  totalPlanningPlaces: PlanningDashboardQuantities[];
  planningBudget: PlanningDashboardQuantities[];
  planningOccupationalGroup: PlanningDashboardQuantities[];
  planningEmployeeType: PlanningDashboardQuantities[];
  planningPlacesByDepartament: PlanningDashboardQuantities[];
  planningPlacesByAdmissionDate: PlanningDashboardQuantities[];
}

export interface PlanningDashboardQuantities {
  id?: number;
  id2?: number;
  description?: string;
  rowText: string;
  description2?: string;
  value?: number;
  value2?: number;
  value3?: number;
  value4?: number;
}

export interface ConfigSurplusPercentage {
  configSurplusPercentageId: number;
  yearPlanning: number;
  surplusPercentage: number;
  planningHeaderId?: number;
  conditionId?: number;
  createDate: string;
  createUserId: number;
  modifyDate?: string;
  modifyUserId?: number;
  companyId: number;
  status: boolean;
}

export interface PlanningPlaceRequestDetail {
  planningPlaceRequestId: number;
  planningHeaderId: number;
  yearExecution: number;
  departmentId?: number;
  departmentName: string;
  positionId: number;
  positionName: string;
  salary: number;
  startDate?: string;
  placeQuantity: number;
  createDate: string;
  modifyDate?: string;
  modifyUserId?: number;
  status: boolean;
  conditionId?: number;
  condition: string;
  planningHeaderConditionId: number;
  occupationalGroupId?: number;
  occupationalGroup: string;
  planningRequestTypeId?: number;
  planningRequestType: string;
  typeEmployeeId?: number;
  typeEmployee: string;
  companyId: number;
  monthsUntilDecember: number;
  afpe: number;
  sfse: number;
  srl: number;
  estimatedSalaryMonthly: number;
  salaryMonthly: number;
  royaltySalary: number;
  salaryAnnual: number;
}