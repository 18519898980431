import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IEmployeeHasMoreThanOnePosition, IEmployeeTypeView } from '../models/Employee.model';
import { BankAccountCertification, BankAccountCertificationStatus, ChangeStatusBankAccountCertification } from '../shared/models/bankAccountCertification.model';
import { InOutList } from '../shared/models/InOutList.model';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class JobOfferService {

  api = environment.api_url;
  apiCore = environment.api_core_url;
  constructor(private http: HttpClient,  private authInfo: AuthInfoService) { }


  getEmployeeType(contractTypeId: number){
    return this.http.get<ResponseModel<IEmployeeTypeView>>(this.api + `/EmployeeType?EmployeeTypeId=0&HeaderId=${contractTypeId}&Type=3&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getAllEmployeeType(){
    return this.http.get<ResponseModel<IEmployeeTypeView>>(this.api + `/EmployeeType?EmployeeTypeId=0&HeaderId=0&Type=4&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getBankingEntity(){
    return this.http.get(this.apiCore + `/ParameterControl/GetKeyWord?parameter=bankingEntity&CompanyCode=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getPositionMap(){
    return this.http.get(this.apiCore + `/Positions?PositionId=-1&CompanyId=${this.authInfo.getCompanyId()}`)
  }

  getLocations(){
    return this.http.get(this.apiCore + '/locations')
  }

  getDepartments(){
    return this.http.get(this.apiCore + `/CompanyStructure?CompanyId=${this.authInfo.getCompanyId()}&IdDepartment=0`)
  }

  postJobOffer(form: any){
    return this.http.post<ResponseModel<any>>(this.api + '/Employees/', form)
  }

  getPostulationsFilteredByIdPpv(idPpv){
    return this.http.get(`${this.api}/PersonsPostulationVacants?idPpv=${idPpv}&companyId=${this.authInfo.getCompanyId()}&type=1`);
  }
  getEmployeeByPPVID(ppvid){
    return this.http.get(`${this.api}/employees?type=6&employeeNumber=${ppvid}&companyId=${this.authInfo.getCompanyId()}`)
  }
  getEntryListByEmployeeId(employeeId){
    return this.http.get(`${this.api}/EmployeeEntryList?EmployeeId=${employeeId}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getPostulationsByType(type: number){
    return this.http.get(this.api + `/PersonsPostulationVacants/GetPostulation?Type=${type}&IdCompany=${this.authInfo.getCompanyId()}`);
  }

  getPostulationPaginated(options){
    let params = new HttpParams();
    params = params.append('CompanyId', this.authInfo.getCompanyId().toString())
    params = params.append('PropkeyId', options.propKeyId.toString())
    params = params.append('SearchParameter', options.searchParameter)
    params = params.append('Status', options.status)
    params = params.append('Conditions', options.conditions)
    params = params.append('Page', options.page)
    params = params.append('PageSize', options.pageSize)
    params = params.append('Type', options.type.toString())
    return this.http.get<any>(`${this.api}/PersonsPostulationVacants/GetPaginated`, {params})
  }

  getBudgetAreas(){
    return this.http.get(`${this.api}/budgetArea?companyId=${this.authInfo.getCompanyId()}`)
  }

  getBenefitsByVacantID(id){
    return this.http.get(`${this.api}/VacantBenefit?IdVacantBenefit=${id}&Type=2&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getEmployeesContractBenefit(employeeId: number){
    return this.http.get(`${this.api}/EmployeesContractBenefit?Type=2&IdEcb=${employeeId}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  setToElegible(idPpv){
    return this.http.delete(`${this.api}/PersonsPostulationVacants/Elegible/${idPpv}`);
  }

  getStatistics(
    type: number,
    dateFrom: string,
    dateTo: string,
    status: boolean = true,
  ) {
    return this.http.get<any>(`${this.api}/PersonsPostulationVacants/Dashboard?Type=${type}&CompanyId=${this.authInfo.getCompanyId()}&DateFrom=${dateFrom}&DateTo=${dateTo}&Status=${status}`);
  }

  getJobOfferDashboard(dateFrom: string,
    dateTo: string,CompanyId: number = this.authInfo.getCompanyId(), Status: boolean = true,
  ){
    return this.http.get<any>(`${this.api}/Employees/Dashboard Hiring?Status=${Status}&CompanyId=${CompanyId}&DateFrom=${dateFrom}&DateTo=${dateTo}`);
  }

  requestBankAccountCertification(bankAccountCertification: BankAccountCertification){
    return this.http.post<ResponseModel<any>>(`${this.api}/Employees/RequestBankAccountCertification`, bankAccountCertification);
  }

  bankAccountCertificationStatus(bankAccountCertification: BankAccountCertification){
    return this.http.post<ResponseModel<BankAccountCertificationStatus>>(`${this.api}/Employees/BankAccountCertificationStatus`, bankAccountCertification);
  }

  changeStatusBankAccountCertification(changeStatusBankAccountCertification: ChangeStatusBankAccountCertification){
    return this.http.post(`${this.api}/Employees/ChangeStatusBankAccountCertification`, changeStatusBankAccountCertification);
  }

  getInOutList(){
    return this.http.get<ResponseModel<InOutList>>(`${this.apiCore}/InOutList?companyId=${this.authInfo.getCompanyId()}&status=true&type=1`);
  }

  changePositionsQuantityCondition(model: IEmployeeHasMoreThanOnePosition){
    return this.http.put<ResponseModel<any>>(`${this.api}/Employees/HasMoreThanOnePosition`, model);
  }

}
