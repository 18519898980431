<div class="container-fluid ">
    <div class="card card-custom">
        <div class="card-header mt-3  d-flex justify-content-between">
            <div class="card-title ">
                <div class="ttl d-flex align-items-center ">
                    <i class="flaticon-proceso-2 icon-3x text-danger mr-2 forward-btn"></i>
                    <h1 class="text-primary forward-btn">Relación de Servidores Público con Conceptos</h1>
                </div>
            </div>
            <div class="card-toolbar">
                <button (click)="goBack()" class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
                    <i class="flaticon2-left-arrow-1"></i>
                    <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
                </button>
            </div>
        </div>
        <div class="card-header row justify-content-between p-2 mx-0 ">

            <div class="col-sm-12 col-md-8 c-left forward-btn">
                <div class="card-toolbar d-flex justify-content-start" [formGroup]="form">

                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Año <span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown (change)="changeYear($event)"
                                    formControlName="year" [options]="yearsList" [config]="institutionConfig"
                                    class="w-100"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Período <span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown formControlName="period"
                                    (change)="changeSelectedPayrollType($event)" [options]="filteredPeriods"
                                    [config]="periodConfig" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Área Presupuestaria <span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown (change)="changeSelectedPayrollType($event)"
                                    formControlName="budget" [options]="budgets" [config]="budgetConfig" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>


                    


                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Tipo de nómina <span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown (change)="changeSelectedPayrollType($event)"
                                    formControlName="payrollType" [options]="payrollTypes" [config]="payrollTypeConfig"
                                    class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 m1-2 px-0 pt-1" *ngIf="this.form.value.payrollType?.ocode === 4">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Período Anterior<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown 
                                [disabled]="disableFilters"
                                [options]="relatedPeriods" 
                                formControlName="relatedPeriod"
                                (change)="changeRelatedPeriod($event)"
                                [config]="relatedPeriodConfig" class="w-100"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Versión <span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">

                                <ngx-select-dropdown formControlName="version" (change)="changeVersion()"
                                    [options]="versionList" [config]="versionConfig" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Tipo de Servidor Público <span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">


                                <ngx-select-dropdown formControlName="employeeType" [options]="employeeTypes"
                                    [config]="employeeTypeConfig" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Conceptos <span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">

                                <ngx-select-dropdown formControlName="concept" [options]="concepts"
                                    [config]="conceptConfig" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-sm-12 col-md-4 c-right mr-0 forward-btn">
                <div class="card-toolbar d-flex justify-content-end">
                    <button (click)="generate()" data-toggle="tooltip" data-original-title="Generar Reporte"
                        class="btn btn-success btn-pill font-weight-bolder mt-9 mx-2 col-md-auto ">
                        <i class="flaticon2-search-1 icon-2x"></i>
                        Generar reporte
                    </button>



                    <!-- <button [disabled]="overallSummary.length === 0" (click)="exportToExcel()" data-toggle="tooltip"
                              data-original-title="Exportar"
                              class="btn btn-primary btn-pill font-weight-bolder mt-9 mx-2 col-md-auto">
                              <i class="flaticon-descarga-arrow icon-2x text-white"></i>
                              Exportar
                          </button>
                          <button [disabled]="overallSummary.length === 0" onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow
                          mt-9 mx-2 col-md-auto">
                              <i class="flaticon2-printer font-size-h1"></i>
                          </button> -->
                </div>
            </div>
        </div>
    </div>





    <script>
        let print = () => {
            let pdf = document.getElementById("pdfExp");
            pdf.contentWindow.focus();
            pdf.contentWindow.print();
        };
    </script>
