import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-employee-birthday-report',
  templateUrl: './employee-birthday-report.component.html',
  styleUrls: ['./employee-birthday-report.component.css']
})
export class EmployeeBirthdayReportComponent implements OnInit {

  listmonths: any [] = [
    {nun: 1, months: 'Enero'},
    {nun: 2, months: 'Febrero'},
    {nun: 3, months: 'Marzo'},
    {nun: 4, months: 'Abril'},
    {nun: 5, months: 'Mayo'},
    {nun: 6, months: 'Junio'},
    {nun: 7, months: 'Julio'},
    {nun: 8, months: 'Agosto'},
    {nun: 9, months: 'Septiembre'},
    {nun: 10, months: 'Octubre'},
    {nun: 11, months: 'Noviembre'},
    {nun: 12, months: 'Diciembre'},

  ]

  form = this.fb.group({
    months: ['', Validators.required]
  })


  constructor(
    private location: Location,
    private authInfo: AuthInfoService,
    private fb: FormBuilder,
    private _toastService: ToastService,
  ) { }

  ngOnInit(): void { }

  getDropdownConfig(displayKey: string){
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }


  generate(){
    let month = this.form.value.months
    if(this.form.invalid){
      this._toastService.warning('Favor de seleccionar un mes.')
      return

    }
    let url = environment.reportUrl + `?ReportCode=rrhh_employees_birthday&CompanyId=${this.authInfo.getCompanyId()}&MesId=${month.nun}`
    openReport({url, title: 'Title', width: '900', height: '700'})
  }




  goBack() {
    this.location.back();
  }

}
