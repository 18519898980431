<div class="card-body">
    <form [formGroup]="employeeExitForm">
        <div class="row ">
            <div class="col-md-4 form-group">
                <label>Tipo de salida <span class="text-danger">*</span></label>
                <ngx-select-dropdown [options]='options' [config]='config' formControlName="exitTypeDropdown"
                    class="flex-1" (change)='changeTypeExitOption($event.value)'>
                </ngx-select-dropdown>
            </div>

            <div class="col-md-4 form-group">
                <label>Motivo de salida <span class="text-danger">*</span></label>
                <ngx-select-dropdown [options]='reasonsOptionsOriginal' [config]='config' class="flex-1"
                    formControlName="reasonExitDropdown" (change)='changeReasonOption($event.value)'>
                </ngx-select-dropdown>
            </div>

            <div class="col-md-4 form-group">
                <label>Fecha Salida <span class="text-danger">*</span></label>
                <input formControlName="exitDate" type="date" class="form-control form-control-lg" />
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>Evidencia <span class="text-danger">*</span></label>
                    <div>
                        <uppy-file 
                            #uppyFileUnlink
                            classPreviewDefaultImage="symbol"
                            targetClass="ExitEvidence"
                            [width]="300"
                            [height]="138"
                            [isDisabled]="!this.personSelected?.status || this.isSuspended == true"
                            [showDocumentoAfterUploaded]="true"
                            [showDocumentoAfterUploaded]="false"
                            [fileForEditting]="guiID"
                            [allowedFileTypes]="['application/pdf']"
                            (fileEmitter)="setEvidence($event)">
                        </uppy-file>
                    </div>
                    <span style="position: absolute;" *ngIf="this.employeeExitForm.get('exitEvidence').invalid"
                        class="text-danger">{{ showEvidenceErrors() }}</span>
                </div>
            </div>

            <div class="form-group col-md-6">
                <div><label for="comment"> Comentario</label></div>
                <textarea formControlName="comment" class="form-control form-control-solid form-control-lg" id="comment"
                    placeholder="" rows="4"></textarea>
            </div>
        </div>
        <div class="row mt-2" *ngIf="isEditingExitDate">
            <div class="text-primary pt-4">
                <h4>Información de la Modificación</h4>
                <hr>
            </div>
            <div class="row mb-2">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Motivo de la Modificación Anterior </label>
                        <input type="text" class="form-control form-control-lg" [value]="employeeExitForm.get('oldModificationReason')?.value?.stringData" readonly/>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="form-group">
                        <label>Comentario de la Modificación Anterior</label>
                        <textarea [value]="employeeExitForm.get('oldModificationComment')?.value" class="form-control"
                            rows="6" readonly></textarea>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Motivo de la Modificación Actual <span class="text-danger">*</span></label>
                        <ngx-select-dropdown formControlName='newModificationReason' [options]='modificationReasons'
                            [config]='reasonsConfig' class="flex-1" (change)="changeModificationReason($event?.value)">
                        </ngx-select-dropdown>
                        <span class="text-danger"
                            *ngIf="employeeExitForm.controls['newModificationReason']?.errors?.required">Este
                            campo es requerido</span>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="form-group">
                        <label>Comentario de la Modificación Actual</label>
                        <textarea formControlName="newModificationComment" class="form-control"
                            rows="6"></textarea>
                    </div>
                </div>
            </div>
            
           
        </div>
        <div class="row" *ngIf="!personSelected?.status">
            <div class="form-group d-flex justify-content-end align-items-center">
                <a class="btn btn-pill btn-light-warning font-weight-bolder  mr-3 allow-view-after-fired" type="button"
                *ngIf="!isEditingExitDate && !personSelected?.wasHiringCanceled" (click)="enableEditingExitDate()">
                <i class="flaticon-edit icon-2x" data-toggle="tooltip" title="Habilitar Modificación de Fecha"></i>
                    Habilitar Modificación
                </a>
                
                <a class="btn btn-success btn-pill font-weight-bolder mr-3 allow-view-after-fired"
                    *ngIf="isEditingExitDate && !personSelected?.wasHiringCanceled" (click)="editUnlinkInfo()">
                    <i class="flaticon-disco-flexible " data-toggle="tooltip" title="Guardar Cambios"></i>
                    Guardar Modificación
                </a>
                <a *ngIf="isEditingExitDate" (click)="disableEditingExitDate()" type="button"
                    class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3 allow-view-after-fired">
                    <i class="fas fa-ban " data-toggle="tooltip" title="Cancelar Modificación de Fecha"></i>
                    Cancelar Modificación
                </a>
                <a *ngIf="!personSelected?.status && !personSelected?.wasHiringCanceled" 
                class="btn btn-icon btn-light-info btn-lg " type="button"
                (click)="openReport(personSelected.employeeId, personSelected.companyId)">
                <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
            </a>
            </div>
        </div>

        <div class="table-responsive">


            <table class="table mt-2 table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th>Herramiento de trabajo</th>
                        <th>¿Asignación?</th>
                        <th>Observaciones</th>
                        <th>Evidencia</th>
                        <th>Asignado por</th>
                    </tr>
                </thead>
                <tbody formArrayName="employeeExitList">
                    <ng-template [ngIf]="employeeExitForm && employeeExitForm.get('employeeExitList')">
                        <tr [formGroupName]="i"
                            *ngFor="let item of employeeExitForm.get('employeeExitList')['controls']; let i=index">

                            <td>
                                {{exitListToggle[i]?.description}}
                            </td>
                            <td>
                                <div class="d-flex">
                                    <strong
                                        class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                                    <div class="flex-shrink-0 mx-2">
                                        <span class="switch switch-sm switch-primary">
                                            <label>
                                                <input class="cursor-pointer abc-checkbox-primary"
                                                    formControlName="selected" type="checkbox" />
                                                <span></span>
                                            </label>
                                        </span>
                                    </div>
                                    <strong
                                        class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
                                </div>
                            </td>

                            <td>
                                <textarea class="form-control" formControlName="observations" cols="30"></textarea>
                            </td>
                            <td>

                                <button (click)="openEvidence(item, i)" type="button" class="btn btn-primary btn-icon">
                                    <i class="flaticon-ver"></i>

                                </button>
                            </td>
                            <td>{{exitList[i]?.assignedBy}}</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>

        <!-- <div class="row gutter-b">
          <div class="col-md-3">
              <h3 class="d-flex bg-primary text-white p-4">Descripción</h3>
          </div>
          <div class="col-md-2">
                <h3 class="d-flex bg-primary text-white p-4">¿Asignación?</h3>
          </div>
          <div class="col-md-3">
            <h3 class="d-flex bg-primary text-white p-4">Observaciones</h3>
          </div>
          <div class="col-md-2">
            <h3 class="d-flex bg-primary text-white p-4">Evidencias</h3>
          </div>
          <div class="col-md-2">
            <h3 class="d-flex bg-primary text-white p-4">Asignado por</h3>
          </div>
  </div> -->


    </form>
    <!-- <div class="row">
      <div class="form-group">
          <label>Especificar otro aquí</label>
          <textarea rows="5" class="form-control form-control-lg"></textarea>
      </div>
  </div> -->
    <div class="form-group text-right mt-4 col-md-12">
        <button *ngIf="personSelected?.status" (click)="updateExitList()" type="button"
            class="btn btn-danger btn-pill font-weight-bolder"
            [disabled]="this.employeeExitForm.invalid || personSelected.unemployment">
            <i class="flaticon-disco-flexible"></i>
            Efectuar salida
        </button>
    </div>

    <el-modal target="documentEvidence" title="Evidencia">
        <iframe [src]='transform(file.document)' width="100%" height="600px"></iframe>
    </el-modal>
</div>