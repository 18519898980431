import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonModel } from 'src/app/pages/personal-requisition/models/person.model';
import { docService } from 'src/app/services/doc.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-payroll-new-detail',
  templateUrl: './payroll-new-detail.component.html',
  styleUrls: ['./payroll-new-detail.component.css']
})
export class PayrollNewDetailComponent implements OnInit, AfterViewChecked {
  payrollNewDetail: any[] = [];
  payrollNewDetailListOriginal: any[] = [];
  paginatedPayrollNewDetail: any[] = [];

  PersonSelected = new PersonModel();

  employeeDetail: any;

  noData: boolean = false;

  constructor(public dialogRef: MatDialogRef<PayrollNewDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private payrollService: PayrollService,
    private _toastr: ToastService,   private srvDoc: docService, 
    private payrollNewService: PayrollNewService, private _changeDet: ChangeDetectorRef) {
    this.dialogRef.disableClose = true;
    this.employeeDetail = this.data.item;
  }
  
  ngOnInit(): void {
    this.getPayrollNewDetail();
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  Cancel(){
    this.dialogRef.close();
  }

  getPersonByIdEmployee(){
    this.payrollNewService.getPersonByIdEmployee(this.data.item.employeeId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastr.error("Error al obtener los datos del Servidores Públicos")
        return;
      }
      if(res.dataList.length > 0){
        this.PersonSelected = res.dataList[0];
        this.getPersonPhotoloaded(res.dataList[0].profileImage);
      }else{
        this._toastr.warning('No se encontraron datos de Servidores Públicos');
      }
    }, error => {
      this._toastr.error("Ha ocurrido un error inesperado, por favor pintente más tarde o comuníquese con HelpDesk.")
    })
  }

  getPersonPhotoloaded(guid: any) {
    this.srvDoc.getDocument(guid).subscribe(
      (e: any) => {
        this.PersonSelected.photoloaded = e.data
      }
    )
  }

  getPayrollNewDetail(){
    this.payrollService.getPayrollNewDetail(this.data.item.payrollPeriodId,this.data.item.employeeId).subscribe((res: any) => {
      if(res.errors.length > 0){
        return;
      }
      this.getPersonByIdEmployee();
      this.payrollNewDetail = res.dataList;
      this.payrollNewDetailListOriginal = res.dataList;
      if(res.dataList.length === 0){
        this._toastr.warning('Este Servidor Público no tiene novedades aplicadas');
        this.noData = true;
      }
    }, error => {
      this._toastr.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  
  getPaginatedRecords(event) {
    this.paginatedPayrollNewDetail = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

}