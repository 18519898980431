<div class="card card-custom">
    <div class="card-header mt-3 mb-3">
        <div class="card-title">
            <span class="card-icon">
                <i class="flaticon-colaboracion icon-3x text-danger"></i>
            </span>
            <h2 class="d-flex flex-column text-primary">
                Acciones del Cargo
            </h2>
            <!-- <h6 class="center font-size-h6 font-weight-bold label label-inline label-light-danger ml-5 px-5">En Desarrollo</h6> -->
        </div>
        <div class="card-toolbar">
            <a (click)="goBack()" class="btn btn-pill font-weight-bolder btn-shadow btn-warning">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
            </a>
        </div>
    </div>

    <div class="card-header mt-5">

        <div class="row gutter-b align-items-center">
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="mb-0">Código del Cargo</label>
                        <h3>
                            {{positionSelected?.positionCode}}
                        </h3>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="mb-0">Nombre</label>
                        <h3>
                            {{positionSelected?.positionName}}
                        </h3>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label class="mb-0">Grupo Ocupacional</label>
                        <h3>
                            {{positionSelected?.occupationalGroupDescription}}
                        </h3>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="mb-0">Condición Actual</label>
                        <h3 lass="text-success">
                            {{positionSelected?.condition}}
                        </h3>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="mb-0">Salario Máximo</label>
                        <h3>
                            {{positionSelected?.maxSalary | currency:"RD$"}}
                        </h3>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="mb-0">Salario Medio</label>
                        <h3>
                            {{positionSelected?.halfSalary | currency:"RD$"}}
                        </h3>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="mb-0">Salario Mínimo</label>
                        <h3>
                            {{positionSelected?.minSalary | currency:"RD$"}}
                        </h3>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="mb-0">Estatus</label>
                        <h3 *ngIf="positionSelected?.status" class="text-success">
                            Activo
                        </h3>
                        <h3 *ngIf="positionSelected?.status == false" class="text-danger">
                            Inactivo
                        </h3>
                        <h3 *ngIf="positionSelected?.status == undefined" class="text-danger">

                        </h3>
                    </div>
                </div>


        </div>

    </div>

    <div class="card-body">

        <div class="tab-content mt-5">
            <div class="tab-pane fade active show py-4" id="cambio-cargo" role="tabpanel"
                aria-labelledby="cambio-cargo-tab">
                <div class="card-body">

                    <form [formGroup]="actionChangeForm" class="w-100">
                        <div class="row gutter-b">
                            <div class="col">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3 form-group">
                                                <label>Cambios <span class="text-danger">*</span></label>
                                                <ngx-select-dropdown formControlName='parameter'
                                                    (change)='fillCurrentData($event.value.ocode)' [options]='fields'
                                                    [config]='fieldsConfig' class="flex-1">
                                                </ngx-select-dropdown>
                                                <span class="text-danger"
                                                    *ngIf="actionChangeForm.get('parameter').invalid && formIsInvalid">{{showParameterErrors()}}</span>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label>Datos Actuales <span class="text-danger">*</span></label>

                                                <input *ngIf="fieldType != 'number' " formControlName='currentData'
                                                    class="form-control form-control-lg" readonly>

                                                <input currencyMask [options]="{precision:2}" placeholder="$0.00"
                                                    *ngIf="fieldType == 'number'" formControlName='currentData'
                                                    class="form-control form-control-lg" readonly>
                                            </div>
                                            <div class="col-md-3 form-group">
                                                <label>Datos Nuevos <span class="text-danger">*</span></label>
                                                <ngx-select-dropdown formControlName='newData'
                                                    [ngClass]="{'d-none': (fieldType!=='drop' || fieldType !== 'drop-additional'), 'd-inline': (fieldType=='drop' || fieldType == 'drop-additional')}"
                                                    class="flex-1" [options]='options'
                                                    (change)='fillNewData($event.value)' [config]='config'>
                                                </ngx-select-dropdown>

                                                <input *ngIf="fieldType == 'text'" formControlName='newData'
                                                class="form-control form-control-lg">

                                                <input currencyMask [options]="{precision:2}" placeholder="$0.00"
                                                    *ngIf="fieldType == 'number'" formControlName='newData'
                                                    class="form-control form-control-lg" (keyup)="verifySalary()">

                                                <input type="date" (change)='fillNewData($event.value)'
                                                    *ngIf="fieldType == 'date'" formControlName='newData'
                                                    class="form-control form-control-lg">
                                                <span class="text-danger"
                                                    *ngIf="actionChangeForm.get('newData').invalid && formIsInvalid">{{showNewDataErrors()}}</span>
                                                <span class="text-danger"
                                                    *ngIf="actionChangeForm.errors?.invalidValue">Los valores no pueden
                                                    ser los mismos</span>
                                                <br>
                                                <span *ngIf="!isSalaryCorrect"
                                                    class="text-danger">{{salaryError}}</span>
                                            </div>
                                            <div class="col-md-3 form-group" *ngIf=" fieldType=='drop-additional'">
                                                <label>{{additionalValueFieldName}} <span
                                                        class="text-danger">*</span></label>
                                                <ngx-select-dropdown formControlName='additionalValueObject'
                                                    class="flex-1" [options]='additionalValueList'
                                                    (change)='changeAdditionalValue($event.value)'
                                                    [config]='additionalValueConfig'>
                                                </ngx-select-dropdown>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Fecha Efectividad <span class="text-danger">*</span></label>
                                                    <input type="date" formControlName="effectiveDate"
                                                        class="form-control">
                                                </div>
                                                <span class="text-danger"
                                                    *ngIf="actionChangeForm.get('effectiveDate').invalid && formIsInvalid">{{showEffectiveDateErrors()}}</span>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Comentario</label>
                                                    <textarea formControlName="comment" class="form-control"
                                                        rows="6"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Evidencia <span class="text-danger">*</span></label>

                                                    <uppy-file 
                                                        #uppyFile
                                                        cardBody=""
                                                        classPreviewDefaultImage="symbol"
                                                        targetClass="evidenceOb"
                                                        previewDefaultImage="assets/images/cv.jpg"
                                                        [width]="350"
                                                        [height]="138"
                                                        [isDisabled]="!this.positionSelected?.status"
                                                        [showDocumentoAfterUploaded]="false"
                                                        [fileForEditting]="guiID"
                                                        [allowedFileTypes]="['application/pdf']"
                                                        (fileEmitter)="setEvidence($event)">
                                                    </uppy-file>    
                                                    <span style="position: absolute;"
                                                        *ngIf="this.actionChangeForm.get('evidence').invalid && formIsInvalid"
                                                        class="text-danger">{{ showEvidenceErrors() }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div class="col-md-auto d-flex justify-content-center align-items-center">
                                <button (click)="addChangeDetails()"
                                    [disabled]="!isSalaryCorrect || actionChangeForm.invalid"
                                    class="btn btn-pill font-weight-bolder btn-shadow btn-success">
                                    <i class="flaticon-disco-flexible"></i>
                                    <span> Agregar </span>
                                </button>
                            </div>
                        </div>
                    </form>

                    <div class="row gutter-b">
                        <div class="table-responsive">
                            <table class="table atbl table-vertical-center">
                                <thead>
                                    <tr class="bg-primary">
                                        <!-- <th class="text-center"></th> -->
                                        <th class="text-left">Parámetros</th>
                                        <th class="text-left">Datos Antiguos</th>
                                        <th class="text-left">Datos Nuevos</th>
                                        <th class="text-left">Fecha Efectividad</th>
                                        <th class="text-left">Evidencia</th>
                                        <th class="text-left">Comentario</th>
                                        <th class="text-center">Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let detail of newChangesPaginated; let i =index">
                                        <!-- <td class="text-center"><input type="checkbox" name="" id=""></td> -->
                                        <td class="text-left">{{detail.parameterName}}</td>
                                        <td class="text-left" *ngIf="detail.valueAfterNumeric">{{detail.valueBefore |
                                            currency}}</td>
                                        <td class="text-left" *ngIf="!detail.valueAfterNumeric">{{detail.valueBefore}}
                                        </td>
                                        <td class="text-left" *ngIf="detail.valueAfterNumeric">{{detail.valueAfter |
                                            currency}}</td>
                                        <td class="text-left" *ngIf="!detail.valueAfterNumeric">
                                            <ng-container
                                                *ngIf="detail?.additionalValueName else noAdditionalValueName">
                                                {{detail.valueAfter}} | {{detail.additionalValueName}}
                                            </ng-container>
                                            <ng-template #noAdditionalValueName>
                                                {{detail.valueAfter}}
                                            </ng-template>
                                        </td>
                                        <td class="text-left">
                                            {{detail.effectiveDate |date:'dd MMMM yyyy':'UTC':'es-US'}}
                                        </td>
                                        <td>
                                            <a *ngIf="detail.evidence"
                                                (click)="viewPdf(detail.evidence)" 
                                                data-toggle="tooltip" title="Evidencia"
                                                class="btn btn-light-youtube font-weight-bold mb-3">
                                                <i class="flaticon-pdf-file icon-2x"></i>
                                            </a>
                                            <p *ngIf="!detail.evidence">N/A</p>
                                        </td>
                                        <td class="text-left">{{detail.comment}}</td>
                                        <td class="text-center w-100px">
                                            <button class="btn btn-sm btn-light-danger btn-icon"
                                                (click)="remove(detail, i)">
                                                <i class="flaticon-delete"></i>
                                            </button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <div>
                                <el-caption #paginator [originalRecordsLength]="newChanges.length"
                                    [listedRecordsLength]="newChangesPaginated.length"
                                    [registerQuantity]="newChanges.length" [records]="newChanges"
                                    (onChage)="getPaginatedUnsavedChanges($event)"></el-caption>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group d-flex justify-content-end align-items-center ">
                            <button [disabled]="newChanges.length < 1" (click)="setChangeHeader()"
                                class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
                                <i class="flaticon-disco-flexible"></i>
                                <span> Guardar </span>
                            </button>
                        </div>
                    </div>


                    <div class="row gutter-b">
                        <h3 class="text-primary">Historial de cambios guardados</h3>
                        <div class="table-responsive">
                            <table class="table atbl table-vertical-center">
                                <thead>
                                    <tr class="bg-primary">
                                        <!-- <th class="text-center"></th> -->
                                        <th class="text-left">Secuencia</th>
                                        <th class="text-left">Tipo de cambios</th>
                                        <th class="text-left">Dato Anterior</th>
                                        <th class="text-left">Dato Propuesto</th>
                                        <th class="text-center">Estatus</th>

                                        <th class="text-left">Fecha Efectividad</th>
                                        <th class="text-left">Fecha Aprobación</th>
                                        <th class="text-left">Aprobado Por</th>
                                        <th class="text-center">Evidencia</th>
                                        <th class="text-center">Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let changeDetail of detailsPaginated; let i = index">
                                        <td class="">{{changeDetail?.positionChangeDetailsId || 0}}</td>
                                        <td class="">{{changeDetail?.parameterName || "N/A"}}</td>
                                        <td class="" *ngIf="!changeDetail?.valueAfterNumeric">
                                            {{changeDetail?.valueBefore || "N/A"}}</td>
                                        <td class="" *ngIf="changeDetail?.valueAfterNumeric">{{changeDetail?.valueBefore
                                            | currency}}</td>
                                        <td class="" *ngIf="!changeDetail?.valueAfterNumeric">{{changeDetail?.valueAfter
                                            || "N/A"}}</td>
                                        <td class="" *ngIf="changeDetail?.valueAfterNumeric">{{changeDetail?.valueAfter|
                                            currency}}</td>

                                        <td class="text-center">
                                            <span style="width: 150px;">
                                                <span *ngIf="changeDetail.conditionId == 1"
                                                    class="font-weight-bold text-info"><span
                                                        class="label label-info label-dot mr-2"></span>{{changeDetail.condition
                                                    }}</span>
                                                <span *ngIf="changeDetail.conditionId == 2"
                                                    class="font-weight-bold text-success"><span
                                                        class="label label-success label-dot mr-2"></span>{{changeDetail.condition
                                                    }}</span>
                                                <span *ngIf="changeDetail.conditionId == 3"
                                                    class="font-weight-bold text-danger"><span
                                                        class="label label-danger label-dot mr-2"></span>{{changeDetail.condition
                                                    }}</span>
                                                <span *ngIf="changeDetail.conditionId == 5"
                                                    class="font-weight-bold text-success"><span
                                                        class="label label-success label-dot mr-2"></span>{{changeDetail.condition
                                                    }}</span>
                                                <span *ngIf="changeDetail.conditionId == 8"
                                                    class="font-weight-bold text-info"><span
                                                        class="label label-info label-dot mr-2"></span>{{changeDetail.condition
                                                    }}</span>
                                                <span *ngIf="changeDetail.conditionId == 9"
                                                    class="font-weight-bold text-info"><span
                                                        class="label label-info label-dot mr-2"></span>{{changeDetail.condition
                                                    }}</span>
                                            </span>
                                        </td>

                                        <td class="">{{changeDetail?.effectiveDate |date:'dd/MM/yyyy':'UTC':'es-US'}}
                                        </td>
                                        <td class="">{{changeDetail?.approvedDate |date:'dd/MM/yyyy':'UTC':'es-US'}}
                                        </td>
                                        <td>
                                            {{changeDetail.approvedBy}}
                                        </td>
                                        <td class="text-center">
                                            <a *ngIf="changeDetail?.evidence" (click)='openGuID(changeDetail?.evidence)'
                                                data-toggle="tooltip" title="Evidencia"
                                                class="btn btn-icon  btn-light-youtube btn-sm m-1">
                                                <i class="flaticon-pdf-file icon-2x"></i>
                                            </a>
                                            <p *ngIf="!changeDetail?.evidence">N/A</p>
                                        </td>
                                        <td class="text-center">
                                            <a *ngIf="changeDetail.conditionId == 3"
                                                (click)="showRejectionReason(changeDetail?.reasonReject)"
                                                class="btn btn-icon btn-light-warning btn-sm m-1">
                                                <i class="flaticon-quitar-usuario-2 icon-2x" data-toggle="tooltip"
                                                    title="Ver razón rechazo"></i>
                                            </a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <div>
                                <el-caption [originalRecordsLength]="details.length"
                                    [listedRecordsLength]="detailsPaginated.length" #paginator
                                    [registerQuantity]="details.length" [records]="details"
                                    (onChage)="getPaginatedRecords($event)"></el-caption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>

    <el-modal target="documentxEvidence" title="Evidencia">
        <iframe [src]='transform(file.document)' width="100%" height="600px"></iframe>
    </el-modal>
</div>
