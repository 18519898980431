import { Injectable } from '@angular/core';
import { AuthInfoService } from './auth-info.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyStructureService {

  api = environment.api_url
  api_core = environment.api_core_url;

  constructor(private _http: HttpClient, private authInfo: AuthInfoService) { }


  getAllCompanyStructures(){
    return this._http.get(`${this.api_core}/CompanyStructure/GetCompanyStructure?CompanyId=1&IdDepartment=0&Type=2&Status=true`)
  }
}
