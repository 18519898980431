import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class PayrollStatisticsService {

  private readonly api = environment.api_url;

  constructor(
    private http: HttpClient,
    private authInfo: AuthInfoService
  ) { }


  GetDetailPayrollAudit(PayrollPeriodId: string, budgetAreaId: number, PayrollTypeId: number) {
    return this.http.get(`${this.api}/Detail/GetDetailPayrollStadistic?HeaderId=${budgetAreaId}&PayrollPeriodId=${PayrollPeriodId}&PayrollTypeId=${PayrollTypeId}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }
}
