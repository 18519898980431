<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-factura icon-3x text-danger mr-2"></i>
                <h1 class="text-primary">Comprobante de pago</h1>
            </div>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="d-flex justify-content-md-between flex-wrap mt-3">
            <div class="col-sm-12 col-md-7">
                <div class="row">
                    <div class="col-md-3  px-0 mb-3">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Año
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown [options]="yearsList" (change)="changeYear($event)"
                                    [config]="getDropdownConfig('description')" class="w-100"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class=" col-md-3 px-0  mb-3 ">
                        <div class="form-group institution mx-1 gap-4">
                            <div class="mx-1">
                                <label class="text-primary">
                                    Período
                                </label>
                                <ngx-select-dropdown [options]="filteredPeriods" #dropdownPeriod
                                    (change)="changeSelectedPeriod($event)" [config]="getDropdownConfig('description')"
                                    class="w-100">
                                </ngx-select-dropdown>
                            </div>

                            <!-- <div class="w-50 ">
                                <label class="text-primary align-items-end">
                                    Estatus de impresión
                                </label>
                                <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                                    [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                                    (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                                    (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                            </div> -->

                        </div>

                    </div>

                    <div class=" col-md-4 d-flex align-items-center px-0  mb-3 ">
                        <div>

                            <button data-toggle="tooltip" data-original-title="Buscar" (click)="loadPaymentFlyerList()"
                                *ngIf="privilege.searchAction.value"
                                class="btn btn-success btn-pill font-weight-bolder card-toolbar">
                                <i class="flaticon2-search-1 icon-2x"></i>
                                Buscar
                            </button>
                        </div>
                        <div>
                            <button [disabled]='sendItems.length == 0' *ngIf="privilege.sendAction.value"
                                class="ml-3 input-group-text btn btn-success btn-pill dropdown-toggle"
                                data-toggle="tooltip" title="Enviar" (click)="sendMail()">
                                <i class="flaticon-enviar icon-2x text-white"></i> Enviar Comprobante
                            </button>
                        </div>
                    </div>


                    <!-- <div class="col-md-3  px-0 mb-3">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Área Presupuestaria
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown [options]="budgetAreaList" #dropdownBudgetArea  (change)="changeSelectedArea($event)"
                                    [config]="getDropdownConfig('description')" class="w-100"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 px-0 mb-3">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary text-nowrap">
                                Tipo de Nómina
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown [options]="payrollTypeList"
                                    (change)="changeSelectedPayrollType($event)" #dropdownPayrollType
                                    [config]="getDropdownConfig('stringData')" class="w-100"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 px-0 mb-3">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Versión<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown [(ngModel)]="dropdownVersion" [options]="payrollVersions"
                                    [config]="dropdownConfig('versionDescription')" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div> -->


                </div>
            </div>



            <div class="col-sm-12 col-md-4 ">

                <div class="row justify-content-end">
                    <!-- <div class="col-12 col-md-7 text-nowrap">
                        <div class=" col-md-auto mr-0 pr-0">
                            <div class="form-group">
                                <label>Búsqueda</label>
                                <div class="input-group">
                                    <input type="text" readonly class="form-control form-control-solid form-control-lg"
                                        placeholder="Escriba aquí su búsqueda">
                                    <button type="button" (click)="openModalSearchEmployee(null)"
                                        class="input-group-text bg-primary" data-toggle="tooltip" title="Buscar">
                                        <i class="flaticon2-search-1 icon-2x text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div> -->

                    <div class="col-12 text-nowrap me-2">
                        <el-search-bar #searchBar (onKeyChange)="onKeyChange($event)" [keyValue]="filterText"
                            [records]="payrollFlyerDetailsOriginal" [itemForSearching]="searchBarDisplayNames"
                            (onChange)="getFilteredList($event);"></el-search-bar>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row justify-content-end">
                <div>

                    <div class="form-group float-md-right">
                        <button [disabled]='sendItems.length == 0' *ngIf="privilege.exportAction.value"
                            data-placement="top" class="btn  btn-light-info" data-toggle="tooltip"
                            title="Imprimir Comprobantes" (click)="printAllFlyer()">
                            <i class="flaticon-factura icon-2x"></i> Imprimir Comprobantes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body" kt-hidden-height="282">

        <div class="table-responsive table-responsive-sigei">
            <table class="table table-vertical-center table-print">
                <thead>
                    <tr class="bg-primary">
                        <th>
                            <input (change)="selectAllItems()" [disabled]="payrollFlyerDetails.length < 1"
                                [(ngModel)]="allItemsSelected" type="checkbox" name="" id="" class="check-size ms-1">
                        </th>
                        <th class="text-left">Servidor Público</th>
                        <th class="text-left">Documento de Identidad</th>
                        <th class="text-center">Sexo</th>
                        <th class="">Tipo de Nómina</th>
                        <th class="">Tipo de Servidor Público</th>
                        <th class="">Cargo</th>
                        <th class="">Coletilla</th>
                        <th class="">Unidad Organizativa</th>
                        <th class="text-center">Estatus</th>

                        <th class="text-center">Acciones</th>
                    </tr>
                </thead>
                <tbody *ngIf="paginatedPaymentFlyers;">
                    <tr *ngFor="let payrollFlyer of paginatedPaymentFlyers">
                        <td class="text-center"><input (change)="selectItem(payrollFlyer)"
                                [(ngModel)]="payrollFlyer.isSelected" type="checkbox" name="flyerItem" id=""
                                class="check-size"></td>
                        <td class="text-left">
                            {{payrollFlyer.name}}
                        </td>
                        <td class="text-left">
                            <span *ngIf="payrollFlyer.typeIdentificationId == 1">{{payrollFlyer.personalIdentification |
                                mask: '000-0000000-0'}}</span>
                            <span
                                *ngIf="payrollFlyer.typeIdentificationId != 1">{{payrollFlyer.personalIdentification}}</span>
                        </td>
                        <td class="text-center">{{payrollFlyer.sex}}</td>
                        <td class="">{{payrollFlyer.payrollType}}</td>
                        <td class="">{{payrollFlyer.employeeType}}</td>
                        <td class="">{{payrollFlyer.positionName}}</td>
                        <td class="">{{payrollFlyer.coletilla}}</td>
                        <td class="">{{payrollFlyer.departmentName}}</td>
                        <td class="text-center">{{(payrollFlyer.status == true ? 'Activo' : 'Inactivo') }}</td>

                        <td class="actionsBtn">
                            <span>
                                <a data-placement="top" class="btn btn-icon btn-light-info mr-3" title=""
                                    *ngIf="privilege.exportAction.value" (click)="exportToPdf(payrollFlyer)">
                                    <i class="flaticon-factura icon-2x" data-toggle="tooltip"
                                        data-original-title="Imprimir Comprobante"></i>
                                </a>
                                <!-- <a data-placement="top" class="btn btn-icon btn-light-info mr-3" title=""
                                        [routerLink]="['/payroll/pdf-payment-voucher',payrollFlyer.headerId,payrollFlyer.employeeId,payrollFlyer.payrollTypeId]">
                                        <i class="flaticon-factura icon-2x" data-toggle="tooltip"
                                            data-original-title="Imprimir Volante"></i>
                                    </a> -->
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>


                <el-caption #paginator [originalRecordsLength]="payrollFlyerDetails.length"
                    [listedRecordsLength]="paginatedPaymentFlyers.length"
                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :payrollFlyerDetails.length"
                    [records]="filteredList.enable ? filteredList.records : payrollFlyerDetails"
                    (onChage)="getPaginatedRecords($event)"></el-caption>

            </div>
        </div>

    </div>

</div>

<!-- <el-modal target="openflyers" size="xl" [title]="sharedModalTitle" >
    <payment-voucher></payment-voucher>
</el-modal> -->

<!-- payment-voucher -->