<div class="row">
    <div class="col-6">
        <label class="label-item mb-2">AUSENCIAS:</label>
        <p class="label-item mb-2">Tipo de Ausencia: ADM-Vacaciones</p>
        <p class="label-item mb-2">Tipo de Servidor Público: {{employee?.employeeType}}</p>
        <!--p class="label-item mb-2">Fecha de Inicio: {{vacationData[0]?.dateStart | date:"dd/MM/yyyy"}}</p>
        <p class="label-item mb-2">Fecha de Termino: {{vacationData[0]?.dateEnd | date: 'dd/MM/yyyy'}}</p>
        <p class="label-item mb-2">Año al que Pertenece: {{vacationData[0]?.year}}</p>
        <p class="label-item mb-2">Total de Días: {{vacationData[0]?.days}}</p>
        <p class="label-item mb-2">¿Desea pago de bono vacacional?: {{vacationData && vacationData.length>0?
            vacationData[0].bond? 'Si' : 'No': 'N/A' }}</p-->
    </div>
    <!--div class="col-12 mt-4">
        <p class="label-item mb-2">Para un total de:
            <ng-container *ngIf="employee">
                {{currentVacationCounter?.daysUsed}} días
            </ng-container>
        </p>
        <p class="label-item mb-2">Restando un total de:
            <ng-container *ngIf="employee">
                {{getHistoryVacancyDays() - currentVacationCounter?.daysUsed}}
            </ng-container>
        </p>
        <p class="label-item mb-2">Correspondientes al año:
            <ng-container *ngIf="employee">
                {{currentVacationCounter?.year}}
            </ng-container>
        </p>
    </div-->
</div>