<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        {{title}} Área Presupuestaria
    </h2>
        <button type="button" (click)="this.dialogRef.close({created:false});" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    </div>

<div class="mat-dialog-content">
    <div class="card-body" style="height: 35em">
        <div class="row" [formGroup]="budgetAreaForm">
            <div class="form-group col-md-12">
                <div>
                    <label for="code">Código<span class="text-danger text-monospace legend">*</span></label>
                </div>
                <input (input)="removeWhitespace($event, budgetAreaForm.get('code'))" type="text" formControlName="code" class="form-control form-control-lg dynamicFormControl" id="code"required="" />
            </div>
            <div class="form-group col-md-12">
                <div>
                    <label for="description">Descripción<span class="text-danger text-monospace legend">*</span></label>
                </div>
                <input (input)="removeWhitespace($event, budgetAreaForm.get('description'))" type="text" formControlName="description" class="form-control form-control-lg dynamicFormControl" id="description" required="" />

            </div>   
            <div class="form-group col-md-12">
                <label>Unidad ejecutora <span class="text-danger"> *</span></label>
                <ngx-select-dropdown [config]="executingUnitsConfig" [options]="executingUnits" formControlName="executingUnit" class=" flex-1" > </ngx-select-dropdown>
                <span class="text-danger"></span>
            </div>
            <div *ngIf="showInactiveReason" class="form-group col-md-12">
                <label>Razón de la Inactivación </label>
                <textarea class="form-control" formControlName="inactiveReason"></textarea>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="closeModal()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="showInactiveReason == false" [disabled]="budgetAreaForm.invalid" (click)="SubmitBudgetArea()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>