import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';

@Component({
  selector: 'errors-component',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.css']
})
export class ErrorsComponent {

  errorMsgList: any = [];

  @Input() showErrors: boolean = false;
  @Input() controlName: AbstractControl | AbstractControlDirective

  errorMessage = {
      'required'  : (params)  => `Este campo es requerido.`,
      'maxlength' : (params)  => `La cantidad máxima de caracteres permitidos es de ${params.requiredLength}.`,
      'minlength' : (params)  => `La cantidad mínima de caracteres permitidos es de ${params.requiredLength}.`,
      'pattern'   : (params)  => `Formato invalido.`,
      'min'       : (params)  => `El número ingresado debe ser mayor o igual que ${params.min}.`,
      'max'       : (params)  => `El número ingresado debe ser menor o igual que ${params.max}.`,
      'whitespace': (params)  => `No se permiten espacios en blanco.`,
      'email'     : (params)  => `No es un correo válido.`,
      'lessThan'            : (params)  => `Debe ser menor que ${params.right}`,
      'lessThanOrEquals'    : (params)  => `Debe ser menor o igual que ${params.right}`,
      'greaterThan'         : (params)  => `Debe ser mayor que ${params.right}`,
      'greaterThanOrEquals' : (params)  => `Debe ser mayor o igual que ${params.right}`
  };


  listErrors() {
      if (!this.controlName) return [];
      if (this.controlName?.errors) {
          this.errorMsgList = [];

          if (this.showErrors || this.controlName?.touched || this.controlName?.dirty) {
            Object.keys(this.controlName?.errors).forEach(error => {

              if(!this.errorMessage[error]){
                return;
              }

                let translator = this.errorMessage[error];
                let parameters = this.controlName?.errors[error];

                this.errorMsgList.push(translator(parameters));
            });
          }

          return this.errorMsgList;
      } else {
          return [];
      }
  }
}
