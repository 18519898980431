import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BulkLoad } from '@payroll/additional-payroll/models/models';
import { bulkLoadFinalErrosTableComponent } from '@payroll/final-payroll/bulk-load-final-nom/bulk-load-final-erros-table/bulkLoadFinalErrosTable.component';
import { NewsConcept, NewsType } from '@payroll/news-list/models/PayrollNews.model';
import { Period } from '@payroll/schedule/models/period.model';
import { AdditionalPayrollService } from 'src/app/services/additional-payroll.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { FinalPayrollService } from 'src/app/services/final-payroll.service';
import { IncentivePayrollService } from 'src/app/services/incentive-payroll.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { ToastService } from 'src/app/shared/toast/toast.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx'

enum PayrollTypes{
  normal = 0,
  final = 1,
  incentiveWithoutContribution = 2,
  royalties = 3,
  additional = 4
}
@Component({
  selector: 'app-incentive-payroll-bulk-load',
  templateUrl: './incentive-payroll-bulk-load.component.html',
  styleUrls: ['./incentive-payroll-bulk-load.component.css']
})
export class IncentivePayrollBulkLoadComponent implements OnInit {
  isFormValid = false
  statusList = [
    {text: "Activo", value: true},
    {text: "Inactivo", value: false},
  ]
  employeeStatus = null;
  statusSetting = {
    displayKey: 'text',
    search: true,
    height: 'auto',
    placeholder: 'Seleccione una opción',
    moreText: '...',
    noResultsFound: 'No se han encontrado registros',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'text'
  }
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };

  dataUploaded: any[] = [];
  dataUploadedListOriginal: any[] = [];
  paginatedDataUploaded: any[] = [];


  @ViewChild('paginator') paginator: CaptionComponent;

  validFileExtensions: string[] = ['.xls', '.xlsx'];

  fileUploaded: boolean = false;

  form: FormGroup;

  budgetAreaId: number = 0;
  budgetArea: any;
  payrollPeriod: any;
  payrollPeriodRelated: any;

  
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  statusOptions = [
    { item_id: 1, item_text: 'Listo para procesar' },
    { item_id: 2, item_text: 'No se encontró el Servidor Público' },
  ];

  selectedItems: any;

  filterText: string = '';

  constructor(private _toastService: ToastService,
    public dialogRef: MatDialogRef<IncentivePayrollBulkLoadComponent>,
    private _changeDet: ChangeDetectorRef, private dialog: MatDialog,
    private parameterControlService: ParameterControlService,
    private fb: FormBuilder,
    private authInfo: AuthInfoService,
    private srvIncentive: IncentivePayrollService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.payrollPeriod = this.data?.payrollPeriod;
    this.budgetArea = this.data?.budgetArea;
    this.payrollPeriodRelated = this.data?.payrollPeriodRelated;
    this.dialogRef.disableClose = true;
    this.form = this.fb.group({
      file: ['']
    })
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  getPaginatedRecords(event) {
    this.paginatedDataUploaded = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  Cancel() {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

  Accept() {
    if (this.dataUploadedListOriginal.length === 0) {
      this._toastService.warning('Por favor debe cargar un archivo previamente', 'Archivo no cargado');
      return;
    }
    if(!this.isValidData){
      return;
    }
    this.postHeader();
  }

  onClick() {
    this.form.reset();
  }
  
  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.dataUploaded = this.filterTable(this.dataUploadedListOriginal)
    }
    this.paginator.selectedPage = 1;
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl?.find(x => x?.item_id === 1) && !sl?.find(x => x?.item_id === 2)) {
      list = list?.filter(x => !x?.details);
    }
    if (sl?.find(x => x?.item_id === 2) && !sl?.find(x => x?.item_id === 1)) {
      list = list?.filter(x => x?.details);
    }
    return list;
  }

  fileUpload(event: any) {
    if (!this.validateFileExtension(event.target.files[0].name)) {
      this._toastService.error('El tipo de archivo cargado no es válido', 'Tipo de Archivo Inválido');
      this.onClick();
      return;
    }
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = (e: any) => {
      let binaryData = e.target.result;
      let workbook = XLSX.read(binaryData, { type: 'binary' });
      let bulkLoadFinal: BulkLoad[] = [];
      workbook.SheetNames.forEach(s => {
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[s]);
        if (!this.validateFileContent(data)) {
          this._toastService.error('El contenido del archivo cargado no es válido', 'Contenido Inválido');
          return;
        }
        bulkLoadFinal = this.buildModel(data);
      });
      this.getBulkLoadFinal(bulkLoadFinal);
    }
  }

  private buildModel(data: any){
    return data.map((d: any) => {
      return {
        personalIdentification: d?.Identificacion?.toString(),
        budgetAreaId: this.budgetArea.budgetAreaId,
        companyId: this.authInfo.getCompanyId(),
        status: this.employeeStatus?.value ?? null
      }
    }) as BulkLoad[];
  }

  private getBulkLoadFinal(bulkLoadFinal: BulkLoad[]){
    this.srvIncentive.getBulkLoadIncentive(bulkLoadFinal).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.dataUploaded = res.dataList;
      this.dataUploadedListOriginal = res.dataList;
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.");
    });
  }

  private openbulkLoadFinalErrosTableComponent(error: string[]) {
    this.dialog.open(bulkLoadFinalErrosTableComponent, {
      data: { errors: error },
      width: '40%'
    })
      .afterClosed()
      .subscribe((result) => {
        this.reset();
      });
  }

  private validateFileExtension(fileName: string) {
    const fileArray = fileName.split('.');
    const extension = fileArray[fileArray.length - 1];
    return this.validFileExtensions.some(v => v.includes(extension));
  }

  get isValidData() {
    const isValid = this.dataUploadedListOriginal.every(d => !d.details);
    return isValid;
  }


  private validateFileContent(data: any[]) {
    return data.every(d => d?.Identificacion);
  }

  private get buildHeader(){
    return {
      payrollPeriodId: this.payrollPeriod?.payrollPeriodId,
      budgetAreaId: this.budgetArea?.budgetAreaId,
      payrollTypeId: PayrollTypes.incentiveWithoutContribution,
      companyId: this.authInfo.getCompanyId(),
      status: true,
      createUserId: +this.authInfo.getUser()?.userId,
      employeeToPayroll: this.dataUploadedListOriginal.map(d => {
        return {
          employeeId: d?.employeeId,
          positionId: d?.positionId,
          departmentId: d?.departmentId,
          typeEmployeeId: d?.typeEmployeeId,
          status: d?.status
        };
      })
    };
  }

  private postHeader(){
    this.srvIncentive.postHeader(this.buildHeader).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error('Ocurrió un error al procesar los datos, por favor verifique los datos enviados', '');
        this.openbulkLoadFinalErrosTableComponent(res.errors);
        return;
      }
      this._toastService.success('Los datos se procesaron correctamente');
      const row = { Applied: true }
      this.dialogRef.close(row);
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.");
    });
  }

  private reset(){
    this.dataUploaded = [];
    this.dataUploadedListOriginal = [];
    this.paginatedDataUploaded = [];
    this.onClick();
  }

  remove(data: any){
    this.dataUploadedListOriginal = this.dataUploadedListOriginal.filter(d => d?.personalIdentification !== data?.personalIdentification);
    this.dataUploaded = this.dataUploadedListOriginal;
    this.filter();
  }

}