import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-desvinculacion',
  templateUrl: './desvinculacion.component.html',
  styleUrls: ['./desvinculacion.component.css']
})
export class DesvinculacionComponent implements OnInit {
  @Input() employee
  constructor() { }

  ngOnInit(): void {
  }

}
