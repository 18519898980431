import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { Component } from './component';
import { Pipe } from './pipe';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { TruncateTextPipe } from './pipe/truncate-text.pipe';
import { NgWizardModule, THEME } from 'ng-wizard';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ControlDocumentComponent } from './control-document/control-document.component';
import { DesignacionComponent } from './control-document/contol-document-dinamico/designacion/designacion.component';
import { CambioSueldoComponent } from './control-document/contol-document-dinamico/cambio-sueldo/cambio-sueldo.component';
import { DesvinculacionComponent } from './control-document/contol-document-dinamico/desvinculacion/desvinculacion.component';
import { PermissionsComponent } from './control-document/contol-document-dinamico/permissions/permissions.component';
import { LicenseComponent } from './control-document/contol-document-dinamico/license/license.component';
import { VacationsComponent } from './control-document/contol-document-dinamico/vacations/vacations.component';
import { NgxMaskModule } from 'ngx-mask';
import { SalaryRangeComponent } from './salary-range/salary-range.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ShowDocumentModalComponent } from './show-document-modal/show-document-modal.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { ExportDataToPdfComponent } from './export-data-to-pdf/export-data-to-pdf.component';
import { SearchBarPaginationComponent } from './element-ui/search-bar-pagination/search-bar-pagination.component';
import { PaginatorComponent } from './element-ui/table/paginator/paginator.component';
import { DonutChartComponent } from './element-ui/donut-chart/donut-chart.component';
import { ModalTextAreaComponent } from './modal-text-area/modal-text-area.component';
import { FixedDecimalPipe } from './fixed-decimal-pipe.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { RemoveAccentsPipe } from './pipe/remove-accents.pipe';
import { UppyFileManagerComponent } from './uppy-file-manager/uppy-file-manager.component';
import { ShowSubjectModalComponent } from './show-subject-modal/show-subject-modal.component';
import { UppyFileComponent } from './uppy-file/uppy-file.component';

@NgModule({
  providers: [
  ],
  declarations: [
    Component,
    Pipe,
    TruncateTextPipe,
    ControlDocumentComponent,
    DesignacionComponent,
    CambioSueldoComponent,
    DesvinculacionComponent,
    PermissionsComponent,
    LicenseComponent,
    VacationsComponent,
    SalaryRangeComponent,
    ShowDocumentModalComponent,
    NumbersOnlyDirective,
    ExportDataToPdfComponent,
    SearchBarPaginationComponent, PaginatorComponent,
    DonutChartComponent,
    ModalTextAreaComponent,
    FixedDecimalPipe,
    DocumentViewerComponent,
    RemoveAccentsPipe,
    UppyFileManagerComponent,
    ShowSubjectModalComponent,
    UppyFileComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule,
    RouterModule,
    ToastrModule.forRoot(),
    AngularMultiSelectModule,
    SelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgWizardModule.forRoot({
      theme: THEME.default,
    }),
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    SelectDropDownModule
  ],
  exports: [Component, Pipe, CurrencyMaskModule, NumbersOnlyDirective, ExportDataToPdfComponent, SearchBarPaginationComponent,
    PaginatorComponent, DonutChartComponent, FixedDecimalPipe, RemoveAccentsPipe, UppyFileComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }