export interface PayrollNew {
    firstName?: string;
    lastName?: string;
    positionName?: string;
    newsType?: string;
    newsConcept?: string;
    payrollNewsId: number;
    employeeId: number;
    positionId: number;
    actionsEmployeeId: number;
    sourceId: number;
    newsTypeId: number;
    newsConceptId: number;
    beneficiaryId: number;
    amount: number;
    startDate: Date;
    endDate: Date;
    applicationDate: Date;
    totalAmount: number;
    balance: number;
    defaultValue: number;
    manualValue: number;
    countApproves: number;
    isPorcent: boolean;
    observation: string;
    status: boolean;
    companyId: number;
    createDate: Date;
    createUserId: number;
    modifyDate: Date;
    modifyUserId: number;
    condition: number;
}

export interface Beneficiary {
    beneficiaryId: number;
    employeeId: number;
    firstName: string;
    lastName: string;
    identificationTypeId: number;
    identificationNumber: string;
    paymentMethodId: number;
    bankId: number;
    office: string;
    accountTypeId: number;
    accountNumber: string;
    status: boolean;
    companyId: number;
    createDate?: Date | string;
    createUserId: number;
    modifyDate: Date | string;
    modifyUserId: number;
}

export interface NewsType {
    newsTypeId: number;
    description: string;
    status: boolean;
    companyId: number;
    createDate: Date;
    createUserId: number;
    modifyDate: Date;
    modifyUserId: number;
}

export interface NewsConcept {
    newsConceptId: number;
    newsTypeId: number;
    description: string;
    isDebit: boolean;
    status: boolean;
    companyId: number;
    createDate: Date;
    createUserId: number;
    modifyDate: Date;
    modifyUserId: number;
}

export interface DefaultValue{
    conceptValuesId: number;
    newsConceptId: number;
    description: string;
    value: number;
    status: boolean;
    createDate: Date;
    createUserId: number;
    modifyDate?: Date;
    modifyUserId?: Date;
}

export interface ConceptSetting {
    newsConceptSettingId: number;
    newsConceptId: number;
    valueType: number;
    manualValue: boolean;
    requiredValue: boolean;
    monthly: boolean;
    duplicate: boolean;
    applicationType: number;
    isRestriction: boolean;
    createDate: Date;
    createUserId: number;
    modifyDate: Date;
    modifyUserId: number;
}


export interface DisapproveMassive {
    payrollNewsId: number[];
    reason: string;
    userId: number;
}

export enum TypeBulkLoadPayrollNew {
    BulkLoadNews,
    BulkLoadAmortizableNews
}

export enum EnumNewsType{
    External = 1,
    Additional = 2,
    Litis = 3,
    FiscalCredit = 4
}

export interface PayrollNewsDelete {
    payrollNewsId: number[];
    reason: string;
}

export interface PostPayrollNewsBulkLoad{
    personalIdentification: string,
    name: string,
    conceptCode: string,
    employeeCategory: string,
    startDate: any,
    endDate: any,
    amount: number,
    totalAmount: number,
    balance: number,
    manualValue: number,
    defaultValue: string,
    observation: string
}