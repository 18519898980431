<div id="PVacante" class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon">
                <i class="flaticon-etiqueta icon-3x text-danger"></i>
            </span>
            <h2 class="d-flex flex-column">
                Vacantes
            </h2>
        </div>
        <div class="card-toolbar flex-md-nowrap">
            <button class="btn btn-primary btn-pill font-weight-bolder mr-3 mb-3" routerLink="/candidate-registration">
                <i class="flaticon2-print"></i> Imprimir</button>

            <button class="btn btn-light-success btn-pill font-weight-bolder mr-3 mb-3">
                <i class="flaticon-excel-1"></i> Exportar a Excel
            </button>

            <button type="button" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="true"
                class="btn btn-primary btn-pill font-weight-bolder dropdown-toggle mr-3 mb-3"><i class="flaticon2-gear"></i>
                Configuración </button>

            <button class="btn btn-success btn-pill font-weight-bolder mb-3" routerLink="/map/post-vacancies">
                <i class="flaticon2-plus"></i> Agregar</button>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table mt-2 table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th>Recinto</th>
                        <th>Unidad Organizativa</th>
                        <th>Puesto solicitado</th>
                        <th>Grupo ocupacional</th>
                        <th>Fecha solicitud</th>
                        <th>Salario</th>
                        <th>Estatus</th>
                        <th class="actionsBtn">Acciones</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>Recinto</td>
                        <td>Unidad Organizativa</td>
                        <td>Puesto solicitado</td>
                        <td>Grupo ocupacional</td>
                        <td>Fecha solicitud</td>
                        <td>Salario</td>
                        <td>Estatus</td>
                        <td class="actionsBtn">
                            <a class="btn btn-icon btn-light-success btn-sm m-1"><i class="flaticon-edit"></i></a>
                            <a class="btn btn-icon btn-light-success btn-sm m-1"> <i class="flaticon-enviar"></i></a>
                            <a class="btn btn-icon btn-light-info btn-sm m-1"><i class="flaticon-ver"></i></a>
                            <a class="btn btn-icon btn-light-danger btn-sm m-1"><i class="flaticon-delete"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-end align-items-center flex-wrap">
                <div class="d-flex align-items-center py-3 mr-2">
                    <select
                        class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light"
                        style="width: 75px;">
                        <option value="1">1</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                    </select>
                    <span class="text-muted">Mostrando página 1 de 1</span>
                </div>
                <div class="d-flex flex-wrap py-2 mr-3">
                    <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                        <i class="ki ki-bold-arrow-back icon-xs"></i>
                    </button>
                    <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                        <i class="ki ki-bold-arrow-next icon-xs"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>