<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span> 
        Datos del Equipo
    </h2>
        <button type="button" (click)="this.closeDialog(false);" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    </div>
    <div class="mat-dialog-content-custom">
        <div class="card-body-custom p-2" style="padding-top: 0;">
            <div class="form" [formGroup]="deviceForm">
                <div class="row"> 
              <!--       <h3>Datos del Equipo</h3>
                    <hr class="m"> -->
                    <div class="form-group col-12 col-md-6 mb-3">
                        <label class="d-block">Marca <span
                                class="text-danger text-monospace legend"> *</span></label>
                        <input type="text" class="form-control form-control-lg" formControlName="deviceBrand">
                    </div>
                    <div class="form-group col-12 col-md-6 mb-3">
                        <label class="d-block">Modelo <span
                                class="text-danger text-monospace legend"> *</span></label>
                        <input  type="text" class="form-control form-control-lg" formControlName="deviceModel">
                    </div>
                    <div class="form-group col-12 col-md-6 mb-3">
                        <label class="d-block">Activo<span
                                class="text-danger text-monospace legend"> *</span></label>
                        <input  type="text" class="form-control form-control-lg" formControlName="deviceAsset">
                    </div>
                    <div class="form-group col-12 col-md-6 mb-3">
                        <label class="d-block">Número de serie<span
                                class="text-danger text-monospace legend"> *</span></label>
                        <input  type="text" class="form-control form-control-lg" formControlName="deviceSerial">
                    </div>
                    <div class="form-group col-12 col-md-6 mb-3">
                        <label class="d-block">Fecha<span
                                class="text-danger text-monospace legend"> *</span></label>
                        <input  type="date" class="form-control form-control-lg" formControlName="deviceDeliveredDate">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mat-dialog-actions"[align]="'end'">
        <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
            (click)="Cancel()">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
        <button [disabled]="deviceForm.invalid" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
            (click)="Accept()">
            <i class="flaticon-disco-flexible"></i>
            <span>Guardar</span>
        </button>
    </div>