
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from '../../../../services/data.service';
import { AccountService } from '../../../../services/account.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { MatDialog } from '@angular/material/dialog';
import { ResetPasswordModalComponent } from '../reset-password-modal/reset-password-modal/reset-password-modal.component';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.scss'],
})
export class HeaderContentComponent implements OnInit, AfterViewInit {
  @Input() opened = false
  @Output() close = new EventEmitter<boolean>()

  userDeparment: any;
  hasAPosition: boolean;
  logoLongName = environment.logo_long_name;
  logoShortName = environment.logo_short_name;

  account = this.accountService.accountValue;

  image: string = 'assets/images/no-user.png';

  constructor(private ds: DataService,
    private authInfoService: AuthInfoService,
    private _docService: docService,
    private toast: ToastService,
    private dialog: MatDialog,
    private accountService: AccountService) {

  }

  ngAfterViewInit(): void {
    if (this.authInfoService.getUser()?.UserImage) {
      this.getUserImage(this.authInfoService.getUser()?.UserImage);
    }
  }

  ngOnInit(): void {
    this.SubscribeEvents();
  }

  SubscribeEvents(): void {
    this.ds.account$.subscribe(user => {
      this.account = user;
      this.getUserImage(user?.UserImage);
    })
  }

  logout(): void {
    this.accountService.logout();
  }

  closeSidebar() {
    this.close.emit(false)
    let classesOverly: any = document.getElementsByClassName("offcanvas-overlay");

    classesOverly.forEach(element => {
      element.classList.remove('offcanvas-overlay');
    });
  }

  getUserImage(guid) {
    this._docService.getDocument(guid).subscribe((res: any) => {
      this.image = res.data ? res.data : 'assets/images/no-user.png';
    }, error => {
      this.image = 'assets/images/no-user.png';
    });
  }

  openResetPassword() {
    this.close.emit(false);
    Swal.fire({
      title: 'Cambio de Contraseña',
      icon: 'info',
      showDenyButton: true,
      confirmButtonText: 'Obtener Código de Verificación',
      confirmButtonColor: '#003876',
      denyButtonText: `¿Tienes un Código de Verificación?`,
      denyButtonColor: '#003876',
    }).then((result) => {
      if (result.isConfirmed) {
        this.sendValidationCode();
      } else if (result.isDenied) {
        this.dialog.open(ResetPasswordModalComponent);
      }
    })
  }

  private sendValidationCode(){
    let data = {
      companyId: this.authInfoService.getCompanyId(),
      userId: this.authInfoService.getUserId(),
      email: this.account.uEmail,
    }
    this.accountService.sendValidationCode(data).subscribe({
      next: (res: any) => {
        if (res.succeded) {
          this.toast.success('Hemos enviado un código de verificación a su correo')
          this.dialog.open(ResetPasswordModalComponent)
        }else{
          this.toast.error(res?.errors[0],'');
        }
      },
      error: (err: any) => {
        this.toast.error('Ha ocurrido un error al enviar el código de verificación')
      },
    });
  }

  goConfig() {
    window.open(environment.configUrl + '?language=' + this.authInfoService.getToken());
  }

}
