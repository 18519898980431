<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>
        {{sending ? 'Enviar Nómina' : 'Agregar Detalle del Gasto'}}
    </h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <form [formGroup]="form">
            <div class="row">
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Tipo de Servidor Público <span class="text-danger"> *</span></label>
                    <ngx-select-dropdown [config]="getDropdownConfig('description')" (change)="changeEmployeeType()" [options]="employeeTypes" formControlName="employeeType" class=" flex-1" > </ngx-select-dropdown>
                    <span class="text-danger">{{getError('employeeType')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Documento Preventivo<span class="text-danger"> *</span></label>
                    <input formControlName="preventiveDocument" type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('preventiveDocument')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Tipo de Transacción Gasto <span class="text-danger"> *</span></label>
                    <ngx-select-dropdown [options]="transactionTypes" [config]="getDropdownConfig('descripcion')" formControlName="transactionType" class=" flex-1" > </ngx-select-dropdown>
                    <span class="text-danger">{{getError('transactionType')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Proceso Presupuestario<span class="text-danger"> *</span></label>
                    <ngx-select-dropdown [options]="budgetaryProceses" formControlName="budgetProcess" [config]="getDropdownConfig('description')" class=" flex-1" > </ngx-select-dropdown>
                    <span class="text-danger">{{getError('budgetProcess')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Programa<span class="text-danger"> *</span></label>
                    <input appNumbersOnly formControlName="program" type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('program')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Sub-Programa<span class="text-danger"> *</span></label>
                    <input appNumbersOnly formControlName="subProgram" type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('subProgram')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Proyecto<span class="text-danger"> *</span></label>
                    <input appNumbersOnly formControlName="project" type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('project')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Actividad-Obra<span class="text-danger"> *</span></label>
                    <input appNumbersOnly formControlName="activityWork" type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('activityWork')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Objetal<span class="text-danger"> *</span></label>
                    <input appNumbersOnly formControlName="object" type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('object')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Organismo Financiador<span class="text-danger"> *</span></label>
                    <input appNumbersOnly formControlName="fundingOrganizations" type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('fundingOrganizations')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Fuente<span class="text-danger"> *</span></label>
                    <ngx-select-dropdown [options]="specificSources" [config]="getDropdownConfig('description')" formControlName="source" class=" flex-1" > </ngx-select-dropdown>
                    <span class="text-danger">{{getError('source')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Región<span class="text-danger"> *</span></label>
                    <input appNumbersOnly formControlName="region" type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('region')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3 ">
                    <label>Provincia<span class="text-danger"> *</span></label>
                    <input appNumbersOnly formControlName="province" type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('province')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Municipio<span class="text-danger"> *</span></label>
                    <input appNumbersOnly formControlName="municipality" type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('municipality')}}</span>
                </div>
                <div class="form-group col-lg-6 col-xxl-3">
                    <label>Función<span class="text-danger"> *</span></label>
                    <input appNumbersOnly formControlName="function" type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('function')}}</span>
                </div>
            </div>
        </form>
        <div class="bg-primary text-white p-4 mb-6">
            <h3>Beneficiario Seguridad Social</h3>
        </div>
        <div class="form" [formGroup]="form">
            <div class="row">
                <div class="form-group col-xl-3 col-lg-6">  
                    <label>Tipo de Documento<span class="text-danger"> *</span></label>
                    <ngx-select-dropdown (change)="changeDocType()" [options]="documentTypes" [config]="getDropdownConfig('stringData')" formControlName="documentType" class=" flex-1" > </ngx-select-dropdown>
                    <span class="text-danger">{{getError('documentType')}}</span>
                </div>
    
                <div class="form-group col-xl-3 col-lg-6">
                    <label>Beneficiario<span class="text-danger"> *</span></label>
                    <input [mask]="mask" formControlName="beneficiary" type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('beneficiary')}}</span>
                </div>
    
                <div class="form-group col-xl-3 col-lg-6">
                    <label>Importe<span class="text-danger"> *</span></label>
                    <input formControlName="amount" placeholder="RD$00.00" type="text" currencyMask
                    [options]="{precision:2}" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('amount')}}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-xl-3 col-lg-6">
                <button *ngIf="!sending" data-toggle="modal" id="btnAdd"
                class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto" (click)="openPartidasGastos()">
              <!--   <i class="flaticon-mas icon-2x" data-toggle="tooltip" data-original-title="Agregar nueva requisición"></i> -->
                Partidas del Gasto
            </button>
            </div>
        </div>
        <div class="card-body" style="padding: 0;">
            <div class="table-responsive">
                <table class="table table-hover table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-center">Cuenta Presupuestaria</th>
                            <th>Total de Ingreso</th>
                            <th>Total de Descuento</th>
                            <th>Neto Primera Quincena</th>
                            <th class=" text-center">Neto Segunda Quincena</th>
                            <th class=" text-center">Patrón</th>
                            <th class=" text-center">Riesgo</th>
                            <th class=" text-center">SFS</th>
                            <th class=" text-center">Retiro Complementario</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of paginatedItems"
                            style="left: 0px;">
                            <td class="text-center">{{item.cuentaPresupuestaria}}</td>
                            <td>RD{{item.totalIngresos | currency}}</td>
                            <td>RD{{item.totalDescuentos | currency}}</td>
                            <td>RD{{item.netoPrimeraQuincena | currency}}</td>
                            <td class=" text-center">RD{{item.netoSegundaQuincena | currency}}</td>
                            <td class=" text-center">{{item.patron | currency}}</td>
                            <td class=" text-center">{{item.riesgo | currency}}</td>
                            <td class=" text-center">RD{{item.sfs | currency}}</td>
                            <td class=" text-center">RD{{item.retiroComplementario | currency}}</td>
                        </tr>
    
                    </tbody>
                </table>
                <div >
                    <el-caption #paginator
                                [registerQuantity]="items.length"
                                [originalRecordsLength]="items.length"
                                [listedRecordsLength]="paginatedItems.length"
                                [records]="items"
                                (onChage)="getPaginatedRecords($event)"></el-caption>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="sending" (click)="send()" class="btn btn-pill font-weight-bolder btn-shadow btn-success" type="button">
        <i class="flaticon-enviar"></i>
        <span>Enviar</span>
    </button>
    <button *ngIf="!sending" (click)="save()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>