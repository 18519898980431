<form class="mt-10" [formGroup]="form">
    <div class="row">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="form-group">
                <label>Descripción de la falta</label>
                <div class="input-group">
                    <input type="text" placeholder="Escriba aquí su búsqueda" class="form-control  form-control-lg " formControlName="offenseSearchText" >
                    <button id="searchBarBtn" data-toggle="tooltip" title="" class="input-group-text bg-primary" data-original-title="Buscar" data-toggle="tooltip"
                    title="Anular" (click)="openListOfFaulstsModal()">
                        <i class="flaticon2-search-1 icon-2x text-white"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="form-group">
                <label for="">Sanción o Medida <span class="text-danger">*</span></label>
                <input type="text" class="form-control  form-control-lg " value="{{offenseSelected?.recommendedAction || 'N/A'}}" disabled>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="form-group">
                <label for="">Grado de la falta <span class="text-danger">*</span></label>
                <input type="text" class="form-control  form-control-lg " value="{{offenseSelected?.grade || 'N/A'}}" disabled>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="form-group">
                <label for="">Fecha de Solicitud<span class="text-danger">*</span></label>
                <input type="date" class="form-control" formControlName="effectiveDate" [max]="currentDate" />
                <errors-component [controlName]="form.get('effectiveDate')">
                </errors-component>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-9 col-lg-6 col-md-12 col-sm-12 col-12">    
            <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-6 col-sm-6 col-12" *ngIf="offenseSelected?.gradeId == 2
                || offenseSelected?.gradeId == 4">
                    <div class="form-group">
                        <label for="">Fecha de inicio <span class="text-danger">*</span></label>
                        <input formControlName="startDate" type="date" class="form-control" [min]="form.get('effectiveDate')?.value" (change)="changeDateRange()">
                        <errors-component [controlName]="form.get('startDate')">
                        </errors-component>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-6 col-sm-6 col-12" *ngIf="offenseSelected?.gradeId == 2
                || offenseSelected?.gradeId == 4">
                    <div class="form-group">
                        <label for="">Fecha de termino <span class="text-danger">*</span></label>
                        <input formControlName="endDate" type="date" class="form-control" [min]="form.get('startDate')?.value" (change)="changeDateRange()">
                        <errors-component [controlName]="form.get('endDate')">
                        </errors-component>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-6 col-sm-6 col-12" *ngIf="offenseSelected?.gradeId == 2
                || offenseSelected?.gradeId == 4">
                    <div class="form-group">
                        <label for="">Días correspondientes <span class="text-danger">*</span></label>
                        <div class="input-group">
                            <input type="number" class="form-control" step="1" min="0" [value]="form.get('days')?.value" disabled/>
                            <span class="input-group-text border-secondary">días</span>
                        </div>
                        <small class="error-block text-danger" *ngIf="showDaysErrorMessage && offenseSelected?.gradeId == 2">
                            Una sanción puede abarcar de uno a noventa (90) días máximo, la misma no puede exceder los 90 días.
                        </small>  
                        <small class="error-block text-danger" *ngIf="form.get('endDate')?.hasError('dateRangeInvalid')
                        && offenseSelected?.gradeId == 4">
                            Este tipo de sanción o medida no puede exceder los 180 días (6 meses)
                        </small>  
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <label for="">Comentario <span class="text-danger">*</span></label>
                        <textarea formControlName="comment" class="form-control" rows="5"></textarea>
                        <errors-component [controlName]="form.get('comment')">
                        </errors-component>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group h-100">
                <label>Evidencia <span class="text-danger">*</span></label>
                <div class="card align-items-center h-100-50" *ngIf="this.employeeInfo?.employeeId > 0">
                    <uppy-file 
                        #uppyFileDisciplinaryMeasures
                        previewDefaultImage="assets/images/cv.jpg"
                        targetClass="uppyFileDisciplinaryMeasures"
                        [width]="320"
                        [height]="295"
                        [isDisabled]="this.employeeInfo ? (!this.employeeInfo?.status || this.isInSeviceCommission == true
                        || this.isSuspended == true) : true"
                        [showDocumentoAfterUploaded]="false"
                        [fileForEditting]="EvidenceForEditting"
                        [allowedFileTypes]="['application/pdf']"
                        (fileEmitter)="setEvidence($event)">
                     </uppy-file>
                </div>
                <errors-component [controlName]="form.get('evidence')">
                </errors-component>
            </div>
        </div>
    </div>

    <div class="row mt-2" *ngIf="isEditing">
        <div class="text-primary pt-4">
            <h4>Información de la Modificación</h4>
            <hr>
        </div>
        <div class="row mb-2">
            <div class="col-md-3">
                <div class="form-group">
                    <label>Motivo de la Modificación Anterior </label>
                    <input type="text" class="form-control form-control-lg" [value]="form.get('oldModificationReason')?.value?.stringData" readonly/>
                </div>
            </div>
            <div class="col-md-9">
                <div class="form-group">
                    <label>Comentario de la Modificación Anterior</label>
                    <textarea [value]="form.get('oldModificationComment')?.value" class="form-control"
                        rows="6" readonly></textarea>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-3">
                <div class="form-group">
                    <label>Motivo de la Modificación Actual <span class="text-danger">*</span></label>
                    <ngx-select-dropdown formControlName='newModificationReason' [options]='modificationReasons'
                        [config]='reasonsConfig' class="flex-1" (change)="changeModificationReason($event?.value)">
                    </ngx-select-dropdown>
                        <errors-component [controlName]="form.get('newModificationReason')">
                        </errors-component>
                </div>
            </div>
            <div class="col-md-9">
                <div class="form-group">
                    <label>Comentario de la Modificación Actual</label>
                    <textarea formControlName="newModificationComment" class="form-control"
                        rows="6"></textarea>
                </div>
            </div>
        </div>
        
    </div>
</form>

<div class="row">
    <div class="form-group d-flex justify-content-end align-items-center">
        <button (click)="saveChanges()" *ngIf="privilege.add.value"
            class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
            <i class="flaticon-disco-flexible"></i>
            <span>{{isEditing ? 'Guardar Modificación' : 'Guardar'}}</span>
        </button>
        <button (click)="clearFields(isEditing ? true  : false);"
                class="btn btn-danger btn-pill font-weight-bolder ">
                {{isEditing ? 'Cancelar Modificación' : 'Limpiar campos'}}
            </button>
    </div>
</div>      

<div class="row gutter-b">
    <h2 class="text-primary gutter-b mb-2 d-flex justify-content-between flex-wrap" >
        Historial de Medidas Disciplinarias
    </h2>
    <div class="table-responsive">
        <table class="table atbl table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th class="text-left">Secuencia</th>
                    <th class="text-center">Fecha de Solicitud</th>
                    <th class="text-left">Grado de falta</th>
                    <th class="text-left">Sanción o medidas</th>
                    <th class="text-left">Descripción de la falta</th>
                    <th class="text-center">Fecha inicio</th>
                    <th class="text-center">Fecha término</th>
                    <th class="text-center">Días</th>
                    <th class="text-left">Supervisor</th>
                    <th class="text-center">Evidencia</th>
                    <th class="text-left">Estatus</th>
                    <th class="text-center">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of actionsListPaginated; let i = index">
                    <td class="text-left">{{item?.actionId}}</td>
                    <td class="text-center">{{item?.effectiveDate | date: 'dd/MM/yyyy'}}</td>
                    <td class="text-left">{{item?.offenseGrade}}</td>
                    <td class="text-left">{{item?.recommendedAction}}</td>
                    <td class="text-left">{{item?.offenseDescription}}</td>
                    <td class="text-center">{{(item?.startDate | date: 'dd/MM/yyyy') || 'N/A'}}</td>
                    <td class="text-center">{{(item?.endDate | date: 'dd/MM/yyyy') || 'N/A'}}</td>
                    <td class="text-center">{{(item?.days | number) || 'N/A'}}</td>
                    <td class="text-left">{{item?.directSupervisorName || 'N/A'}}</td>
                    <td class="text-center">
                        <a data-toggle="tooltip" *ngIf="item?.evidence" (click)='openGuID(item.evidence)'
                            title="Evidencia" class="btn btn-light-youtube font-weight-bold mb-3">
                            <i class="flaticon-pdf-file icon-2x"></i>
                        </a>
                        <p *ngIf="!item.evidence">N/A</p>
                    </td>
                    <td class="text-left">
                        <ng-container *ngIf="!item?.status; else conditions">
                            <span class="font-weight-bold text-danger"> {{item?.conditionId === 10 ? 'Anulado' : 'Inactivo'}} </span>
                        </ng-container>
                        <ng-template #conditions>
                            <ng-container *ngIf="item?.conditionId === 6">
                                <span class="font-weight-bold text-warning">En Espera de Aprobación</span>
                            </ng-container>
                            <ng-container *ngIf="item?.conditionId === 2">
                                <span class="font-weight-bold text-success">Aprobado</span>
                            </ng-container>
                            <ng-container *ngIf="item?.conditionId === 3">
                                <span class="font-weight-bold text-danger">Rechazado</span>
                            </ng-container>
                            <ng-container *ngIf="item?.conditionId === 10">
                                <span class="font-weight-bold text-danger">Anulado</span>
                            </ng-container>
                        </ng-template>
                    </td>
                    <td class="actionsBtn">
                        <button *ngIf="privilege.view.value" (click)="viewReport(item)"
                            class="btn btn-sm btn-light-info btn-icon mr-2">
                            <i class="flaticon-ver" data-toggle="tooltip" title="Ver Reporte"></i>
                        </button>
                        <button *ngIf="item?.conditionId != 10 && item?.status == true && privilege.edit.value"
                            (click)="editDisciplinaryAction(item)"
                            class="btn btn-icon btn-light-warning btn-sm m-1">
                            <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                        </button>
                        <button *ngIf="item.conditionId != 10  && !isEditing && item?.status == true && privilege.delete.value" (click)="deleteDA(item)"
                            class="btn btn-icon btn-light-danger btn-sm m-1">
                            <i data-toggle="tooltip" title="" class="flaticon-delete" data-original-title="Remover"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div>
            <el-caption [originalRecordsLength]="actionsList.length"
                [listedRecordsLength]="actionsListPaginated.length" #paginator
                [registerQuantity]="actionsList.length"
                [records]="actionsList"
                (onChage)="getPaginatedRecords($event)"></el-caption>
        </div>
    </div>
</div>









