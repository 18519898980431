import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonDropDownSettings } from 'src/app/shared/settings/common-dropdown.settings';
import { CollectiveVacationVacationSchedulingComponent } from '../collective-vacation-vacation-scheduling/collective-vacation-vacation-scheduling.component';
import { MatDialog } from '@angular/material/dialog';
import { SendEmailVacationSchedulingComponent } from '../send-email-vacation-scheduling/send-email-vacation-scheduling.component';
import { ToastrService } from 'ngx-toastr';
import { PlanningVacationsService } from 'src/app/services/planning-vacations.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';

@Component({
  selector: 'app-detail-vacation-scheduling',
  templateUrl: './detail-vacation-scheduling.component.html',
  styles: [
  ]
})
export class DetailVacationSchedulingComponent implements OnInit {

  public planningVacationsDetailList:any
  public planningVacationsDetailListOriginal:any
  public planningVacationsDetail:any
  public planningVacationsHeaderId:any
  public  selectedItems: any[] = [];
  public planningVacationsDetailEmial:any[] =[]
  @ViewChild('paginator') paginator: CaptionComponent;


  filterText = '';
  filteredList: { records: any[], enable: boolean, propkey?:any, text?:string } = { records: [], enable: false, };
  localStorageSearch:string = 'app-detailVacationScheduling';
  public readonly commonDropDownSettings = CommonDropDownSettings;


  searchList: any[] = [];
  searchBarDisplayNames: {propKeyId:any, propkey?: string, displayName: string }[] =
  [
    {propKeyId: 2, propkey: "employNumber", displayName: "Código servidor público" },
    {propKeyId: 4, propkey: "name", displayName: "Servidor público" },
    {propKeyId: 3, propkey: "personalIdentification", displayName: "Documento de identidad" },
    {propKeyId: 6, propkey: "departmentName", displayName: "Unidad organizativa" },
    {propKeyId: 1, propkey: "positionName", displayName: "Cargo" },
    {propKeyId: 5, propkey: "vacationTypeDescription", displayName: "Tipo" },
  ];


  constructor( private dialog: MatDialog, private toastr: ToastrService, private _services: PlanningVacationsService,
              public _authInfo: AuthInfoService, private activRout: ActivatedRoute, private datetime: DatePipe, private route: Router) { }

  ngOnInit(): void {
    this.getUrl()
    this.getPlanningVacationsDetail()
  }

  getUrl(){
    this.activRout.params.subscribe({
      next:(resp:any)=>{
        this.planningVacationsHeaderId = resp['id'];
      }
    })
  }

  getPlanningVacationsDetail(){
    this._services.getPlanningVacationsDetail(this._authInfo.getCompanyId(), 1, this.planningVacationsHeaderId).subscribe({
      next:(resp:ResponseModel<any>) => {
        if(resp.errors.length > 0) {
          resp.errors.forEach(x => {
            this.toastr.error(x);
            return
          })
        }

        this.planningVacationsDetailListOriginal  = resp.dataList.map(x => ({
          ...x,
          vacationTypeDescription: x.vacationType == 1 ? "Ordinaria" : "Colectivas",
          selected:false
        }));

        this.planningVacationsDetailList =  resp.dataList.map(x => ({
          ...x,
          vacationTypeDescription: x.vacationType == 1 ? "Ordinaria" : "Colectivas",
          selected:false
        }));
      }
    })
  }

  openVacacionesColectivas(): void {
    this.dialog.open(CollectiveVacationVacationSchedulingComponent,{
      data: {
      },
      width: '50%',
    });
  }



  sendVacaciones(): void {

    let body:any [] = this.planningVacationsDetailEmial.map(x => ({
      uniqueId: x.planningVacationsDetailId,
      uniqueIdString: x.institionalEmail,
      fullName: x.name,
      toMail: x.institionalEmail,
      subject: "Planificacion de Vacaciones",
      body: "s",
      attachments: [""],
      displayName: "Vacaciones Planificadas"
    }))

    let companyId:any = this._authInfo.getCompanyId()
    let data:any = body.filter(x => x.toMail != null)
    let year:any = this.datetime.transform(this.planningVacationsDetailEmial[0]?.startOfVacation,'yyyy')

    this._services.setEmailVacation(companyId, year, data).subscribe({
      next:(resp:ResponseModel<any>) => {
        if(resp.errors.length > 0) {
          resp.errors.forEach(x => {
            this.toastr.error(x);
            return
          })
        }
        this.toastr.success("Correo envido correctamente")
      }
    })
  }

  selectAllPlanningVacationsDetail(event: any) {
    const isChecked = event.target.checked;
    this.planningVacationsDetailList.forEach(item => {
      item.selected = isChecked;
    });

    if(isChecked){
      this.planningVacationsDetailEmial.push(this.planningVacationsDetailList)

    }else{
      this.planningVacationsDetailEmial = []
    }

  }

  checkplanningVacationsDetail(e:any, i:any){
    if(e.target.checked){
      this.planningVacationsDetailEmial.push(i)
    }else{
      let index = this.planningVacationsDetailEmial.indexOf(i);
      if(index > -1){
        this.planningVacationsDetailEmial.splice(index, 1)
      }
    }
  }



  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1
    this.planningVacationsDetailList = this.filteredList.records
  }


  getPaginatedRecords(event) {
    this.planningVacationsDetail = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  goBack() {
    this.route.navigate(['planning/list-vacation-scheduling'])
  }

  generateReport(){

    const reportCode = ReportCode.VacationProgrammingReport
    const page: number = this.paginator?.selectedPage ? this.paginator?.selectedPage : 1;
    const pageSize: number = this.paginator?.registerPerPage ? this.paginator?.registerPerPage : 10;
    const propKeyId = this.filteredList?.propkey?.propKeyId ?? 0;
    const SearchParameter = this.filteredList?.text ?? ''
    const companyId = this._authInfo.getCompanyId();

    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&OperationId=2&PlanningVactionsHeaderId=${this.planningVacationsHeaderId}&PropKeyId=${propKeyId}&SearchParameter=${SearchParameter}&Status=0-1&Page=${page}&PageSize=${pageSize}`;
    let parameters = {
      url: reportUrl,
      title: '',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

}

