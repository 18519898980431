import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ModalViewsEnum } from 'src/app/models/modal-views';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { PlanningRrhhService } from 'src/app/services/planning-rrhh.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import Swal from 'sweetalert2';
import { PlanningRrhhComponent } from '../components/planning-rrhh/planning-rrhh.component';
import { ChangeConditionPlanning, PlanningCondition, PlanningHeader } from '../models/planning';

@Component({
  selector: 'app-planning-history',
  templateUrl: './planning-history.component.html',
  styleUrls: ['./planning-history.component.css']
})
export class PlanningHistoryComponent implements OnInit {
  searchList: any[] = []; searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "planningHeaderId", displayName: "Secuencia" },
      { propkey: "yearExecution", displayName: "Año" },
      { propkey: "condition", displayName: "Condición" }
    ];

    public privilege = {
      nameKeyModule: 'HRM',
      nameKeyOption: 'PLANRRHH',
      search: 'SEARCHSIT',
      addpost: 'ADDPOST',
      sendplan: 'SENDPLAN',
      view: 'VIEW',
      finish: 'FINISH',
      edit: 'EDIT',
      approve:'APPROVE',
      placerequest: 'PLACE-REQUEST',
      addplace: 'ADD-PLACE',
      editplace: 'EDIT-PLACE',
      deleteplace: 'DELETE-PLACE',
      viewmodal: 'VIEW-MODAL'
    }
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  filterText = '';
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };

  statusOptions = [
    { item_id: 1, item_text: 'Activas' },
    { item_id: 2, item_text: 'Inactivas' },
    { item_id: 3, item_text: 'En Proceso' },
    { item_id: 4, item_text: 'Pendiente de Aprobación' },
    { item_id: 5, item_text: 'Pendiente de Envío' },
    { item_id: 6, item_text: 'Enviada' },
    { item_id: 7, item_text: 'Aprobada' },
    { item_id: 8, item_text: 'Devuelta' },
  ];
  selectedItems: any[] = [];
  @ViewChild('paginator') paginator: CaptionComponent;
  planningRrhh: PlanningHeader[] = [];
  planningRrhhOriginalList: PlanningHeader[] = [];
  planningRrhhRecords: PlanningHeader[] = [];

  viewsEnum = ModalViewsEnum;

  constructor(private dialog: MatDialog, private planningRrhhService: PlanningRrhhService,
    private _exlService: ExportToExcelService, public authInfo: AuthInfoService,
    private _toastrService: ToastrService,) { }

  ngOnInit(): void {
   this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
    if(result == true){
      this.getPlanningHeader();

    }
   })
    
  }

  getPlanningHeader() {
    this.planningRrhhService.getPlanningHeader().subscribe({
      next: res => {
        if (res?.errors?.length > 0) {
          this._toastrService.error(res?.errors[0]);
          return;
        }
        this.planningRrhh = res?.dataList;
        this.planningRrhhOriginalList = res?.dataList;
      },
      error: error => {
        this._toastrService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
      }
    })
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }

  onItemDeSelect(item: any) {
    this.filter();
  }

  onItemSelect(item: any) {
    this.filter();
  }

  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
    this.filteredList.records = this.filterTable(event.records)
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  filter() {
    document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.planningRrhh = this.filterTable(this.planningRrhhOriginalList)
    }
  }

  filterTable(list: PlanningHeader[]) {
    let sl = this.selectedItems;
    if (sl.find(x => x.item_id == 1) && !sl.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl.find(x => x.item_id == 2) && !sl.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    const conditions = [...this.getConditionsSeleted];
    if (conditions?.length > 0) {
      return list?.filter(l => conditions?.some(condition => condition === l?.conditionId));
    }
    return list;
  }

  private get getConditionsSeleted() {
    const sl: number[] = this.selectedItems?.map(s => s?.item_id);
    const conditions: number[] = [];
    if (sl?.find(x => x === 3)) conditions.push(PlanningCondition.InProcess);
    // if (sl?.find(x => x === 4)) conditions.push(PlanningCondition.PendingReview);
    // if (sl?.find(x => x === 5)) conditions.push(PlanningCondition.PendingSend);
    // if (sl?.find(x => x === 6)) conditions.push(PlanningCondition.Sent);
    // if (sl?.find(x => x === 7)) conditions.push(PlanningCondition.Approved);
    // if (sl?.find(x => x === 8)) conditions.push(PlanningCondition.Returned);
    return conditions;
  }

  getPaginatedRecords(event) {
    this.planningRrhhRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  approvePlanning(planningHeader: PlanningHeader) {
    const changeConditionPlanning: ChangeConditionPlanning = {
      planningHeaderId: planningHeader?.planningHeaderId,
      conditionId: PlanningCondition?.PendingReview,
      userId: this.authInfo?.getUserId()
    }
    this.swalQuestion('¿Está seguro que desea aprobar esta planificación?', '#28a745', 'Aprobar', () => {
      this.planningRrhhService.approvePlanningByRRHH(changeConditionPlanning).subscribe({
        next: res => {
          if (res?.errors?.length > 0) {
            this._toastrService.error(res?.errors[0]);
            return;
          }
          this._toastrService.success('La planificación se aprobó correctamente');
          this.getPlanningHeader();
        },
        error: error => {
          this._toastrService.error("Ha ocurrido un error inesperado aprobando la planificación, por favor intente más tarde o comuníquese con HelpDesk.")
        }
      });
    });
  }

  openPlanifficationModal(viewMode:number, planningRecord?:any) {
    this.dialog.open(PlanningRrhhComponent, {
      data:{
        viewMode,
        planningRecord
      },
      width:'100%'

    }).afterClosed().subscribe({
      next: (res: any) => {
        if (!res) {
          return
        }
        this.getPlanningHeader()
        
      },
      error: (err: any) => {

      }
    })
  }

  exportToExcel() {
    let data: PlanningHeader[] = []
    if (this.filteredList.enable) {
      data = [...this.filteredList.records];
    } else {
      data = [...this.planningRrhh];
    }
    data = data.map(d => {
      return {
        ...d,
        statusText: d?.status ? 'Activo' : 'Inactivo'
      }
    })
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Año",
      "Presupuesto Aprobado",
      "Presupuesto Ejecutado",
      "Presupuesto Disponible",
      "Condición",
      "Estatus",
    ], [
      "planningHeaderId",
      "yearExecution",
      "approvedBudget",
      "executedBudget",
      "availableBudget",
      "condition",
      "statusText",
    ]]
    this._exlService.exportToExcelSpecificColumns(data, excelHeaders, 'Lista de Planificación RRHH', true);
  }

  private swalQuestion(title: string, confirmButtonColor: string, confirm: string, fn: () => void) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      //confirmButtonColor: '#28a745',
      confirmButtonColor: confirmButtonColor,
      title: title,
      icon: "question",
      confirmButtonText: confirm,
      cancelButtonText: 'Cancelar',
    }).then(res => {
      if (res?.isConfirmed) {
        fn();
      }
    });
  }

  onBtnPrintClick(){
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

}