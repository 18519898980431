<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>{{titleModal}}</h2>
        <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    
    </div>
    <div class="mat-dialog-content-custom">
        <div class="card card-custom">
            <div class="card-header mt-5 row justify-content-end">
                <div class="col-sm-6 c-right mr-0">
                    <div class="card-toolbar d-flex justify-content-end">
                        <div class="col text-nowrap">
                            <el-search-bar #searchBar (onKeyChange)="onKeyChange($event);" [keyValue]="filterText"
                            [records]="dictionaryOriginalList" [itemForSearching]="searchBarDisplayNames"
                            (onChange)="getFilteredList($event);"></el-search-bar>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th>Secuencia</th>
                                <th>Tipo de Competencias</th>
                                <th>Competencia</th>
                                <th>Grado</th>
                                <th>Capacidades</th>
                                <th class="text-center">Selección</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let competency of dictionaryRecord">
                                <td>{{competency.dictionarySequence}}</td>
                                <td>{{competency.competencyType}}</td>
                                <td >{{competency.competencyName}}</td>
                                <td>{{competency.grade}}</td>
                                <td>{{competency.capabilityDescription}}</td>
                                <td class="text-center">
                                    <input type="checkbox" [checked]="competency?.selected" (click)="selectCompetency(competency)" class="form-check-input mr-2">
                                </td>
                            </tr>
                        </tbody>               
                    </table>
                    <div>
                        <el-caption #paginator [originalRecordsLength]="dictionary?.length"
                        [listedRecordsLength]="dictionaryRecord?.length"
                        [registerQuantity]="filteredList?.enable && filteredList.records?.length>0 ? filteredList.records?.length :dictionary?.length"
                        [records]="filteredList?.enable ? filteredList?.records : dictionary"
                        (onChage)="getPaginatedRecords($event)"></el-caption>
                    </div>
                </div>
        
            </div>
        </div>
    </div>
    
<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
            (click)="Cancel()">
            <i class="flaticon2-cross"></i>
            Cerrar
    </button>
    <button  class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
            (click)="Accept()">
            <i class="flaticon-disco-flexible"></i>
            <span>Guardar</span>
    </button>
</div>