import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { Subscription } from 'rxjs';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { EmployeePersoneelActDashboardComponent } from 'src/app/pages/RegistrationAndControl/employee-personeel-act-dashboard/employee-personeel-act-dashboard.component';
import { DatePipe, formatDate } from '@angular/common';
import { PagedResult } from 'src/app/shared/models/page-result';
import { SearchBarPaginationComponent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { CancelPersonalActionModalComponent } from './cancel-personal-action-modal/cancel-personal-action-modal.component';
import { ModalViewsEnum } from 'src/app/models/modal-views';
import { ICancelPersonalActionRequestView, IConditionCancelRequest, IPersonalActionView } from 'src/app/shared/models/employee-condition.model';
import Swal from 'sweetalert2';
import { CancelPersonalActionService } from 'src/app/services/cancel-personal-action.service';
import * as moment from 'moment';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { AdvancedFilterByPersonnelActionsComponent } from './advanced-filter-by-personnel-actions/advanced-filter-by-personnel-actions.component';

declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'employee-personnel-actions-list',
  templateUrl: './employee-personnel-actions-list.component.html',
  styleUrls: ['./employee-personnel-actions-list.component.css']
})


export class EmployeePersonnelActionsListComponent implements OnInit, AfterViewChecked, OnDestroy {

  employeesPagedResult = new PagedResult<any>();
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'CHANGES',
    dashboardAction: { key: 'DASHB-A', value: false },
    exportAction: { key: 'EXPORT-A', value: false },
    searchAction: { key: 'SEARCH-A', value: false },
    viewAction: { key: 'PDF-A', value: false },
    personnelAction: { key: 'ACTIONS-A', value: false },
    cancelAction: { key: 'CANCEL', value: false },
    viewCancelAction: { key: 'VIEW-CANCEL', value: false },
    approveCancelAction: { key: 'APPROVE-CANCEL', value: false },
    rejectCancelAction: { key: 'REJECT-CANCEL', value: false },
    applyCancelAction: { key: 'APPLY-CANCEL', value: false }
  }

  searchParameter
  public photoUrl: string = ''
  public loadingPic: boolean = true
  reportUrl = environment.reportUrl

  isLoading: boolean = true;
  subscription: Subscription = new Subscription();
  actionsList: any[] = [];
  actionsListOriginal: any[] = [];
  paginatedActions: any[] = [];
  employeesAI: boolean = false;
  tableFilter = '';
  actionsListFilter: any = '';
  showActives: boolean = true;
  typeExitList: any[] = [];
  localStorageSearch = 'employee-personnel-actions-list'
  reasonExitList: any[] = [];
  startDateParam = null
  endDateParam = null
  @ViewChild('searchBar') searchBar: SearchBarPaginationComponent
  @ViewChild('paginator') paginator: CaptionComponent;
  form: FormGroup;
  employeeId: number;
  filterText: string = '';
  postulationId: number;
  exitReasonDescription;
  vacantId: number;
  selectedItems: any[] = [];
  personId: number;
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Código de Servidor Público" },
      { propkeyId: 2, displayName: "Servidor Público" },
      { propkeyId: 3, displayName: "Unidad organizativa" },
      { propkeyId: 4, displayName: "Cargo" },
      { propkeyId: 5, displayName: "Tipo de Servidor Público" },
      { propkeyId: 6, displayName: "Clasificación" },
      { propkeyId: 7, displayName: "Tipo" },
      { propkeyId: 8, displayName: "Documento de Identidad", default: true },
      { propkeyId: 9, displayName: "Salario" },
      { propkeyId: 100, displayName: "Búsqueda avanzada" },
    ];
    advancedFilter = null

    statusOptions = [
      { item_id: 1, item_text: 'Pendientes de aprobación MAP' },
      { item_id: 2, item_text: 'Aprobadas Internamente' },
      { item_id: 3, item_text: 'Rechazadas' },
      { item_id: 5, item_text: 'Aprobadas por MAP' },
      { item_id: 6, item_text: 'Pendientes de aprobación' },
      { item_id: 7, item_text: 'Aprobado y Aplicado' },
      { item_id: 10, item_text: 'Anuladas' },
      { item_id: 11, item_text: 'Pendiente de Anulación' },
      { item_id: 12, item_text: 'Pendiente de Aplicar Anulación' },
      { item_id: 13, item_text: 'Inactiva' }
    ];
  canUseOption = false
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  startDate;
  endDate;

  parameterValue: { propkey?: string, displayName: string };
  viewTypes= ModalViewsEnum

  isFiltered: boolean = false;

  constructor(private employeeService: EmployeesService,
    private _toastService: ToastService,
    private _exlService: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private _toastr: ToastrService,
    public authInfo: AuthInfoService, private datePipe: DatePipe,
    private personalActionsService: PersonnelActionsService,
    private cancelActionService: CancelPersonalActionService) {

  }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.canUseOption = true;
        this.privilege = this.authInfo.setPrivileges(this.privilege);
        this.getParameterValue();
        this.getFilterValue();
        this.getActions();
      }
    });
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  employeeToggle() {
    this.paginatedActions = []
    this.showActives = !this.showActives;
    this.getActions();
  }

  getMinDate(date) {
    if (!date) return;
    let d = new Date(date);
    d.setDate(d.getDate() + 1);
    return d.toLocaleDateString('fr-CA');
  }

  getMaxDate(date) {
    if (!date) return;
    let d = new Date(date);
    d.setDate(d.getDate() + 1);
    return d.toLocaleDateString('fr-CA');

  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }




  getActions() {
    if (!this.canUseOption) {
      return
    }

    const advanced = this.advancedFilter ?? ','.repeat(10);

    let startD = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    let endD = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');

    if (!startD) {
      startD = null;
    }

    if (!endD) {
      endD = null;
    }
    let params = {
      propKey: !this.searchParameter?.value ? 0 : this.searchParameter?.propkeyId,
      SearchParameter: this.searchParameter?.value ? this.searchParameter?.value : '',
      Page: this.employeesPagedResult.page,
      PageSize: this.employeesPagedResult.pageSize,
      StartDate: startD,
      EndDate: endD,
      type: 1,
      conditions: this.selectedItems?.length > 0 ? this.selectedItems.map(e => {
        return e.item_id
      }).join('-') : '',
      additionalParameters: '',
      advancedFilter: advanced,
      companyId: this.authInfo.getCompanyId()
    }
    if (startD || endD || params.conditions.length > 0) {
      params.type = 2
    }


    this.startDateParam = this.startDate
    this.endDateParam = this.endDate

    /* propKey, SearchParameter, Page, PageSize, StartDate, EndDate, type, effectiveDate */

    var subscription = this.employeeService.getEmployeeActionsPaginated(params).subscribe({
      next: (res)=>{
        if(!res.succeded){

          res.errors.forEach(err=>{
            this._toastService.error(err)
          });

          res.warnings.forEach(warn=>{
            this._toastService.warning(warn);
          });

          return;
        }

        this.actionsList = res.singleData.items;

        this.actionsListOriginal = res.singleData.items;
        this.employeesPagedResult = res.singleData
        this._cdRef.detectChanges();

        applySort();
      },
      error: (err)=>{
        this._toastService.error("Ha ocurrido un error al obtener la lista de Servidores Públicos");
      }
    })
    this.subscription.add(subscription);

    applySort();
  }

  resetFilters() {
    this.searchParameter = undefined
    this.startDate = null
    this.endDate = null
    this.selectedItems = []
    this.searchBar.reset()
    this.getActions()
  }

  filterSearch(event) {
    this.employeesPagedResult.page = 1
    this.searchParameter = event
    
    if (event.propkeyId != 100) {
      this.advancedFilter = ','.repeat(9);
      this.getActions()
    } else {
      this.OpenAdvancedFilters()
    }
    this._cdRef.detectChanges();
  }

  OpenAdvancedFilters() {
    this.dialog.open(AdvancedFilterByPersonnelActionsComponent, {
      width: '55%',
      data: {
        advanced: this.advancedFilter
      },
      hasBackdrop: true
    }).afterClosed().subscribe({
      next: (res:any) => {
        if(!res?.success){
          return
        }
        this.advancedFilter = res?.text;

        this.getActions()
      }
    })
  }

  getPersonnelActionForPDF(typePersonalActionId, pdfRoute): boolean {
    let result: boolean = false;

    switch (typePersonalActionId) {
      case 1:
        if (pdfRoute == '/pdf-action-change-employee-license') {
          result = true;
        } else {
          result = false;
        }
        break;
      case 2:
        if (pdfRoute == '/pdf-personnel-actions-regulated') {
          result = true;
        } else {
          result = false;
        }
        break;
      case 4:
        if (pdfRoute == '/pdf-action-change-employee-permisos') {
          result = true;
        } else {
          result = false;
        }
        break;
      case 7:
        if (pdfRoute == '/pdf-action-change-employee-permisos') {
          result = true;
        } else {
          result = false;
        }
        break;
      case 8:
        if (pdfRoute == '/pdf-action-change-employee-permisos') {
          result = true;
        } else {
          result = false;
        }
        break;
      case 9:
        if (pdfRoute == '/pdf-action-change-employee-exservices') {
          result = true;
        } else {
          result = false;
        }
        break;
      case 10:
        if (pdfRoute == '/pdf-action-change-employee-vacations') {
          result = true;
        } else {
          result = false;
        }
        break;

      case 11:
        if (pdfRoute == '/pdf-control-document') {
          result = true;
        } else {
          result = false;
        }
        break;

      case 12:
        if (pdfRoute == '/pdf-action-change-employee-permisos') {
          result = true;
        } else {
          result = false;
        }
        break;

      default:
        result = false;
        break;
    }


    return result
  }


  ApplySearch() { }
  vacancyToggle() { }

  getPaginatedRecords(event) {
    if (this.employeesPagedResult.page == event.selectedPage && this.employeesPagedResult.pageSize == event.registersPerPage) {
      return;
    }
    this.employeesPagedResult.page = event.selectedPage;
    this.employeesPagedResult.pageSize = event.registersPerPage;
    this.getActions()
  }

  exportToExcel() {
    let data = []
    if (this.filteredList.enable) {
      data = this.filteredList.records
    } else {
      data = this.actionsList
    }
    data.forEach(element => {
      element.personalActionsType = element.personalActionsType ? element.personalActionsType : 'N/A';
      element.createDateFormatted = formatDate(element.createDate, 'dd/MM/yyyy', 'es');
      element.dateStartFormatted = formatDate(element.dateStart, 'dd/MM/yyyy', 'es')
      element.dateEndFormatted = formatDate(element.dateEnd, 'dd/MM/yyyy', 'es')
      element.effectiveDateFormatted = formatDate(element.effectiveDate, 'dd/MM/yyyy', 'es')
    });
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Código del Servidor Público",
      "Servidor Público",
      "Unidad Organizativa",
      "Cargo",
      "Tipo de Servidor Público",
      "Clasificación",
      "Tipo",
      "Fecha de Registro",
      "Fecha Inicio",
      "Fecha Final",
      "Fecha Efectiva",
      "Estatus",
    ], [
      "employeeId",
      "employNumber",
      "name",
      "departmentName",
      "positionName",
      "employeeType",
      "personalActionsType",
      "typePermissions",
      "createDateFormatted",
      "dateStartFormatted",
      "dateEndFormatted",
      "effectiveDateFormatted",
      "condition",
    ]]
    this._exlService.exportToExcelSpecificColumns(this.filteredList.enable ? this.filteredList.records : this.actionsList, excelHeaders, 'Lista de acciones de personal', true);
  }




  config = {
    displayKey: "stringData",
    search: true,
    height: 'auto', //height of the liilts to auto. With auto height scroll will never appear
    placeholder: 'Elige una opción', //i    customComparator: ()=>{}, // a cus ndefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits e limited)
    moreText: 'more', // text to be di
    noResultsFound: 'Sin resultados!',
    searchPlaceholder: 'Buscar', // lab
    searchOnKey: 'stringData', // key on whiwill be selective search. if undefined this will be extensive search on all keys
  }


  updateVinculacion() {
    var desvincular: any = {
      employeeId: this.employeeId,
      postulationId: this.postulationId,
      employNumber: this.form.get("employNumber").value,
      departmentName: this.form.get("departmentName").value,
      exitTypeId: this.form.get("exitTypeId").value.ocode,
      exitReason: this.form.get("exitReason").value.ocode,
      electiveDepartureDate: this.form.get("electiveDepartureDate").value,
      processingExitDate: this.form.get("processingExitDate").value,
      receptionExitDate: this.form.get("receptionExitDate").value,
      exitReasonDescription: this.exitReasonDescription,
      companyId: this.authInfo.getCompanyId(),
      sendMapId: 1,
      positionId: 1,
      departmentId: 1000,
      typeEmployeeId: 1,
      typeContractId: 1,
      branchId: 1,
      entityBankId: 0,
      personId: this.personId,
      vacantId: this.vacantId,
      employeeBenefictContract: [
        {
          ecbid: 0,
          employeeId: 0,
          benefitId: 0,
          companyId: this.authInfo.getCompanyId(),
          status: true
        }
      ]
    }
    var value = this.employeeService.UpdateEmployee(desvincular).subscribe((data: any) => {
      if (data.errors.length > 0) {
        this._toastr.error(data.errors[0])
        return
      }
      document.getElementById("btnClose").click();
      this.getActions();
      this._toastr.success("El registro ha sido desvinculado con éxito", "Confirmación");
    }, error => {
    })
    this.subscription.add(value);
  }

  openDashboard() {
    this.dialog.open(EmployeePersoneelActDashboardComponent, {
      width: '100%'
    })
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  setlocalStorageParameterValue(value) {
    if (value?.displayName) {
      localStorage.setItem('EmployeePersonnelActionsList-ParameterValue', JSON.stringify(value));
    } else {
      localStorage.setItem('EmployeePersonnelActionsList-ParameterValue', JSON.stringify({}));
    }
    this.getParameterValue();
  }

  getParameterValue() {
    const value = JSON.parse(localStorage.getItem("EmployeePersonnelActionsList-ParameterValue"));
    if (value?.displayName) {
      this.parameterValue = value;
    }
  }

  setlocalStorageValue(value) {
    localStorage.setItem('EmployeePersonnelActionsList-Value', JSON.stringify(value));
  }

  getFilterValue() {
    this.filterText = JSON.parse(localStorage.getItem("EmployeePersonnelActionsList-Value"));
  }

  get currentFilterEqualDefaultFilter() {
    return !this.endDate && !this.startDate;
  }

  filterByDate() {
    this.isFiltered = true;
    this.employeesPagedResult.page = 1
    this.getActions();
  }

  openCancelActionModal(actionData: IPersonalActionView, viewType: ModalViewsEnum){

    const cancelRequestData:  ICancelPersonalActionRequestView = {
      actionCanceledId: actionData?.actionCanceledId ?? 0,
      personalActionId: actionData.personalActionId,
      typePersonalActionId: actionData.typePersonalActionId,
      typePermissionId: actionData.typePermissionsId
    }

    this.dialog.open(CancelPersonalActionModalComponent,{
      width: '70%',
      data: {cancelRequestData,actionData,  viewType}
    }).afterClosed().subscribe({
      next: (res: boolean) =>{
        if(res == true){
          this.getActions();
        }
      }
    })
  }

  openChangeCancelConditionModal(actionData: IPersonalActionView, type: number){

    switch(type){
      case 1:
        this.approveCancelRequest(actionData);
        break
      case 2:
        this.applyCancelRequest(actionData);
        break
      case 3:
        this.rejectCancelRequest(actionData);
        break
      default:
        this._toastr.warning('La acción seleccionada no se encuentra disponible');
        break
    }

  }

  private rejectCancelRequest(actionData: IPersonalActionView){

    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Continuar',
      confirmButtonColor: '#e63946',
      cancelButtonText: 'Cancelar',
      title: '¿Seguro que deseas rechazar la solicitud de anulación?',

    }).then((result) => {
      if (result.isConfirmed) {

        Swal.fire({
          showConfirmButton: true,
          showCancelButton: true,
          icon: 'warning',
          confirmButtonText: 'Rechazar',
          confirmButtonColor: '#e63946',
          cancelButtonText: 'Cancelar',
          title: 'Introduzca una razón para el rechazo',
          input: 'textarea'
        }).then((resultInput) => {
          if (resultInput.isConfirmed) {

            const model: IConditionCancelRequest = {
              actionCanceledId: actionData.actionCanceledId,
              comment: resultInput?.value,
              userId: this.authInfo.getUserId()
            }

            this.cancelActionService.rejectRequest(model).subscribe({
              next:(res)=>{
                if (!res.succeded) {
                  res.errors.forEach(err => {
                    this._toastr.error(err);
                  })

                  res.warnings.forEach(err => {
                    this._toastr.warning(err);
                  })
                  return;
                }

                this._toastr.success('Solicitud de anulación rechazada satisfactoriamente')
                this.getActions();

              },
              error: (err)=>{
                this._toastr.error('Ha ocurrido un error tratando de rechazar la solicitud de anulación');
              }
            })

          } else {
            return;
          }
        })
      } else {
        return;
      }
    })

  }

  private approveCancelRequest(actionData: IPersonalActionView){
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Aprobar',
      confirmButtonColor: 'green',
      cancelButtonText: 'Cancelar',
      title: '¿Seguro que deseas aprobar la solicitud de anulación?',

    }).then((result) => {
      if (result.isConfirmed) {

        const model: IConditionCancelRequest = {
          actionCanceledId: actionData.actionCanceledId,
          userId: this.authInfo.getUserId()
        }

        this.cancelActionService.approveAndApplyRequest(model).subscribe({
          next:(res)=>{
            if (!res.succeded) {
              res.errors.forEach(err => {
                this._toastr.error(err);
              })

              res.warnings.forEach(err => {
                this._toastr.warning(err);
              })
              return;
            }

            this._toastr.success('Solicitud de anulación aprobada satisfactoriamente')

            const result = res.singleData;
            if(result.personalActionTypeId == 13){ //desvinculacion
              this.sendActiveUserRequest(result.employeeUserId);
            }

            this.getActions();



          },
          error: (err)=>{
            this._toastr.error('Ha ocurrido un error tratando de aprobar la solicitud de anulación');
          }
        })

      } else {
        return;
      }
    })
  }

  private applyCancelRequest(actionData: IPersonalActionView){
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Aplicar',
      confirmButtonColor: 'green',
      cancelButtonText: 'Cancelar',
      title: '¿Seguro que deseas aplicar la solicitud de anulación?',

    }).then((result) => {
      if (result.isConfirmed) {

        const model: IConditionCancelRequest = {
          actionCanceledId: actionData.actionCanceledId,
          userId: this.authInfo.getUserId()
        }

        this.cancelActionService.applyRequest(model).subscribe({
          next:(res)=>{
            if (!res.succeded) {
              res.errors.forEach(err => {
                this._toastr.error(err);
              })

              res.warnings.forEach(err => {
                this._toastr.warning(err);
              })
              return;
            }

            this._toastr.success('Solicitud de anulación aplicada satisfactoriamente')

            const result = res.singleData;
            if(result.personalActionTypeId == 13){ //desvinculacion
              this.sendActiveUserRequest(result.employeeUserId);
            }

            this.getActions();



          },
          error: (err)=>{
            this._toastr.error('Ha ocurrido un error tratando de aplicar la solicitud de anulación');
          }
        })

      } else {
        return;
      }
    })
  }

  private sendActiveUserRequest(userId: number){
    this.personalActionsService.activeEmployeeUser(userId).subscribe({
      next: (res) => {
        if (!res.succeded) {
          this._toastr.error('Ha ocurrido un error tratando de activar el usuario relacionado con el Servidor Público.')
          return;
        }

        this._toastr.success("Usuario relacionado con el Servidor Público activado exitosamente");
      },
      error: (err) => {
        this._toastr.error('Ha ocurrido un error tratando de activar el usuario relacionado con el Servidor Público.')
      }
    });
  }

  openReport(change: any) {
    const { personalActionId, employeeId, companyId, typePermissionsId, typePersonalActionId } = change
    let reportUrl = ''

    if (typePersonalActionId === 2) {

      switch (typePermissionsId) {
        case 1:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_ChangesDepartment&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${personalActionId}`
          break;

        case 2:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_Changeposition&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${personalActionId}`
          break;

        case 3:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_Changecontractedfile&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${personalActionId}`
          break;

        case 4:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_ChangesSalary&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${personalActionId}`
          break;

        case 7:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_ChangeBranch&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${personalActionId}`
          break;

        case 11:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_Changecontractedfile&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${personalActionId}`
          break;

        case 14:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_ChangeBugetArea&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${personalActionId}`
          break;

        default:
          break;
      }

    }
    else if (typePersonalActionId !== 2) {
      switch (typePersonalActionId) {
        case 1:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_License&CompanyId=${companyId}&EmployeeId=${employeeId}&LicenseId=${personalActionId}`
          break;
        case 4:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_Permission&CompanyId=${companyId}&EmployeeId=${employeeId}&PermissionsId=${personalActionId}`
          break;
        case 9:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_ExternalService&CompanyId=${companyId}&EmployeeId=${employeeId}&PermissionsId=${personalActionId}`
          break;
        case 10:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_Vacancy&CompanyId=${companyId}&EmployeeId=${employeeId}&VacancyId=${personalActionId}`
          break;
        case 11:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_Changesadmissiondesignation&CompanyId=${companyId}&EmployeeId=${employeeId}&EmployeeChangeDetailsId=${personalActionId}`
          break;
        case 12:
          reportUrl = `${this.reportUrl}/?ReportCode=rpt_Sevicecommission&CompanyId=${companyId}&EmployeeId=${employeeId}&PermissionsId=${personalActionId}`
          break;
        case 14:
            reportUrl = `${this.reportUrl}/?ReportCode=EmployeeDisciplinaryActions&CompanyId=${companyId}&EmployeeId=${employeeId}&ActionId=${personalActionId}`
            break;

        default:
          break;
      }
    }

    if (reportUrl.length === 0) {
      return
    }

    let parameters = {
      url: reportUrl,
      title: 'Consulta Acción de Personal',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }

  openGeneralReport() {

    let url = ''
    const propKeyId = this.searchParameter?.propkeyId ?? 0
    const searchParameter = this.searchParameter?.value ?? ''
    const actives = this.selectedItems.find((x) => x.item_id === 1);
    const inactives = this.selectedItems.find((x) => x.item_id === 2);
    let status = 0;
    if ((!actives && !inactives) || (actives && inactives)) {
      status = -1;
    } else if (actives && !inactives) {
      status = 1;
    }
    const conditions = `&Conditions=${this.selectedItems?.length > 0 ? this.selectedItems.map(e => {
      return e.item_id
    }).join('-') : ''}`
    const startD = this.startDateParam ? moment(this.startDateParam).format('yyyy-MM-DD') : null;
    const endD = this.endDateParam ? moment(this.endDateParam).format('yyyy-MM-DD') : null;
    const { page, pageSize } = this.employeesPagedResult
    let dateString = ''

    if (startD) {
      dateString += `&StartDate=${startD}`
    }
    if(endD){
      dateString += `&EndDate=${endD}`
    }

    url = `${this.reportUrl}/?ReportCode=Exp_PersonalActionList${dateString}&type=2&CompanyId=${this.authInfo.getCompanyId()}${conditions}&PropKeyId=${propKeyId}&SearchParameter=${searchParameter}&status=${status}&page=${page}&pageSize=${pageSize}&AdvancedFilter=${this.advancedFilter ?? ','.repeat(9)}`



    let parameters = {
      url: url,
      title: 'Requisición / Habilitar Vacantes',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }


  setRefferedTab(change: any) {
    const { personalActionId, employeeId, companyId, typePermissionsId, typePersonalActionId } = change

    let upperTab = ''
    let subTab = ''

    if (typePersonalActionId === 2) {
      upperTab = 'cambio-empleado-tab'
      subTab = ''
    }
    else if (typePersonalActionId !== 2) {
      upperTab = 'acciones-personal-tab'
      switch (typePersonalActionId) {
        case 1:
          subTab = 'licencias-tab'
          break;
        case 4:
          subTab = 'permisos-tab'
          break;
        case 9:
          subTab = 'extServices-tab'
          break;
        case 10:
          subTab = 'vacaciones-tab'
          break;
        case 11:
          upperTab = 'cambio-empleado-tab'
          subTab = ''
          break;
        case 12:
          subTab = 'commission-tab'
          break;
        case 13:
          upperTab = 'desvincular-tab'
          subTab = ''
          break;
        case 14:
          subTab = 'disciplinaryMeasures-tab'
            break;
        default:
          break;
      }
    }

    localStorage.setItem('action-change-tab-selected', upperTab)
    localStorage.setItem('personnel-actions-tab-selected', subTab)
  }

}
