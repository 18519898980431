import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { finalize, delay, switchMap, filter, take, map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { from } from 'rxjs'
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { LoaderService } from './loader.service';
import { IRefreshTokenModel } from 'src/app/shared/models/account';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor(public loaderService: LoaderService, private accountService: AccountService, private toastService: ToastrService, private authInfo: AuthInfoService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if account is logged in and request is to the api url
    //const isApiUrl = request.url.startsWith(environment.api_url);

    let params = request.params;

    for (const key of params.keys()) {
        if (params.get(key) == null || params.get(key) == undefined ) {
            params = params.delete(key);
        }
    }
    
    request = request.clone({ params });

    // const url = new URL(request.url);
    // const searchParams = url.searchParams;

    // searchParams.forEach((value,key)=>{
    //   if(value == 'null' || value == 'undefined'){
    //     searchParams.delete(key);
    //   }
    // });

    // const newUrl = url.origin + url.pathname + '?' + searchParams.toString()

    // request = request.clone({ url: newUrl });
        
    const authToken = this.authInfo.getToken();

    if (authToken) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${authToken}` }
      });
    }

    this.loaderService.setLoading(true, request);

    return next.handle(request).pipe(catchError(error => {
      this.loaderService.setLoading(false, request);
      if (error instanceof HttpErrorResponse && [401, 403].includes(error.status) && !request.url.includes(this.accountService.refreshTokenUrl)) {
        return this.handle401Error(request, next);
      }
      
      return throwError(error);
    }))
    .pipe(map(evt => {
      if (evt instanceof HttpResponse) {
        this.loaderService.setLoading(false, request);
      }
      return evt;
    }));

  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);


      const model: IRefreshTokenModel = {
        accessToken: this.authInfo.getToken(),
        refreshToken: this.authInfo.getRefreshToken(),
        userId: this.authInfo.getUserId()
      }

      if (model.accessToken) {
        return this.accountService.refreshToken(model).pipe(
          switchMap((res) => {

            this.isRefreshing = false;

            if(!res.succeded){
              console.log('LOG OUT - ERROR REFRESHING TOKEN');
              this.accountService.logout();
              return throwError(null);
            }
            
            localStorage.setItem('token', res.singleResultData.accessToken);
            this.refreshTokenSubject.next(res.singleResultData.accessToken);

            return next.handle(this.addTokenHeader(request, res.singleResultData.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            console.log('LOG OUT - ERROR REFRESHING TOKEN');
            this.accountService.logout();
            return throwError(err);
          })
        );
      }
    }


    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, authToken: string) {
    return request.clone({
      setHeaders: { Authorization: `Bearer ${authToken}` }
    });
  }
}

