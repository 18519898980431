<div class="modal-header border-0 dv-table-header-1 bg-primary d-flex"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Búsqueda avanzada
    </h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h5"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">
    <div class="row">
        
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Sucursal</label>
                <div>
                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccione'"
                        [settings]="dropdownSettings"  [(ngModel)]="branch"
                        [data]="branches">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Unidad organizativa</label>
                <div>
                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccione'"
                        [settings]="dropdownSettings"
                        [data]="units"
                        [(ngModel)]="organizationalUnit">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>        
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Cargo</label>
                <div>
                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccione'"
                        [settings]="dropdownSettings" 
                        [(ngModel)]="position"
                        [data]="positions">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Método de Reclutamiento</label>
                <div>
                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccione'"
                        [settings]="dropdownSettings" 
                        [(ngModel)]="requisitionMethod"
                        [data]="requisitionMethodCategories">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Sexo</label>
                <div>
                    <ng-multiselect-dropdown
                        [placeholder]="'Seleccione'"
                        [settings]="dropdownSettings" 
                        [(ngModel)]="sex"
                        [data]="sexCategories">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>        
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Fecha de Creación</label>
                <div class="input-group">                    
                    <input [(ngModel)]="createDate" type="date" class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Fecha de Aprobación</label>
                <div class="input-group">                    
                    <input [(ngModel)]="approvedDate" type="date" class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mat-dialog-actions"[align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary" type="button"
        (click)="Accept()">
        <i class="flaticon-buscar"></i>
        <span>Buscar</span>
    </button>
</div>