<!--h2 class="mat-dialog-title">Filtrar por fecha</h2-->

<div class="modal-header border-0 dv-table-header-1 bg-primary mb-4">
  <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
    Beneficios del Servidor Público
  </h2>
  <button type="button" (click)="close(false)" class="close" aria-label="Close">
    <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
  </button>
</div>

<div class="container pb-4" style="height: 30em">
  <form [formGroup]="form">
    <div class="row">
      <div class="form-group col-xl-12">
        <label>Beneficios del Servidor Público</label>
        <ngx-select-dropdown class=" flex-1" [options]='benefits' [config]='benefitSelectConfig'
          formControlName="benefitsSelected" [multiple]='true'>
        </ngx-select-dropdown>
      </div>
    </div>
  </form>

</div>
<div class="mat-dialog-actions" [align]="'end'">

  <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2 "
    (click)="close()" type="button">
    <i class="flaticon2-cross"></i>
    Cerrar
  </button>
  <button class="btn btn-pill font-weight-bolder btn-shadow btn-success fw-bold" (click)="submit()"> <i
      class="flaticon-disco-flexible"></i> Guardar</button>
</div>