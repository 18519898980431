<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Detalle Errores - Carga Masiva</h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>

<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left">Detalle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of errorListPaginate">
                                <td class="text-left text-danger">{{item}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <el-caption #paginator [originalRecordsLength]="errorListOriginalList?.length" [listedRecordsLength]="errorListPaginate?.length"
                        [registerQuantity]="errorList?.length"
                        [records]="errorList"
                        (onChage)="getPaginatedRecords($event)"></el-caption>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="this.dialogRef.close();">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>
