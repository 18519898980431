import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
import { ToastService } from 'src/app/shared/toast/toast.service';

interface DashboardEmployeePersoneelAct{
  description: string;
  quantity: number;
}
export interface chart {
  description: string;
  description2?: any;
  value: number;
  value2: number;
  value3: number;
  value4: number;
}

@Component({
  selector: 'app-employee-personeel-act-dashboard',
  templateUrl: './employee-personeel-act-dashboard.component.html',
  styleUrls: ['./employee-personeel-act-dashboard.component.css'],
})
export class EmployeePersoneelActDashboardComponent implements OnInit {
  datePipe = new DatePipe('en-Us')
  personalActionByType1 = []
  departments:any = [];
  personalActionByDepartment: chart[] = []
  employeeByDepartments = []
  dropdownEmployeeDepartment = null
  filtered = false;
  filterParams = {
    year: new Date().getFullYear(),
    date2: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    date1: '01-01-0001',
    companyId: this.authInfo.getCompanyId()
  };

  vacanciesByMonths: DashboardEmployeePersoneelAct[] = [];
  licencesByMonths: DashboardEmployeePersoneelAct[] = [];
  permissionsByMonths: DashboardEmployeePersoneelAct[] = [];
  changesByType: DashboardEmployeePersoneelAct[] = [];
  total: number = 0;

  constructor(
    public dialogRef: MatDialogRef<EmployeePersoneelActDashboardComponent>,
    private authInfoService: AuthInfoService, private employeesService: EmployeesService,
    private _toastService: ToastService,  private dialog: MatDialog,
    private authInfo: AuthInfoService
  ) { }

  ngOnInit(): void {
    try{
      this.filterParams.date1 = this.datePipe.transform(`${this.authInfo.getConstitutionDate()}`, 'yyyy-MM-dd');
      this.filterParams.date2 = this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd');
      this.getDashboard();
    }catch(e){

    }
  }

  getDashboard(){
    this.employeesService.getDashboardEmployeepersonnelActions(
      this.filterParams.year, 
      this.filterParams.date1, 
      this.filterParams.date2, 
      this.filterParams.companyId).subscribe((res: any) => {
        if(res.errors.length > 0){
          this._toastService.error(res.errors[0])
          return;
        }
        this.vacanciesByMonths = res.singleData.vacanciesByMonths;
        this.licencesByMonths = res.singleData.licencesByMonths;
        this.permissionsByMonths = res.singleData.permissionsByMonths;
        res.singleData.changesByType.map(element=>{
            if(element.description == null){
              element.description = 'No Definido'
            }
            element.description = `${element.description} (${element.quantity})`,
            element.quantity = element.quantity
          
        });
        this.changesByType = res.singleData.changesByType
        
        this.personalActionByDepartment = res.singleData.personalActionByDepartment   
        this.total = res.singleData?.totalPersonalActions[0]?.value
        res.singleData.personalActionByType.map(element=>{
          if(element.description == null){
            element.description = 'No Definido'
          }
          element.description = `${element.description} (${element.value})`
        
      });
        this.personalActionByType1 = res.singleData.personalActionByType
        this.getDepartments()
        
  
      }, error => {
        this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.");
      })
  }

  sumTotal(value: any[]){
    return  value.reduce(
      (previousValue, currentValue: DashboardEmployeePersoneelAct) => previousValue + currentValue.quantity,
      0
    );
  }
  getDepartments(){
    this.employeesService.getDeparments().subscribe((res:any) => {
      if(res.succeded){
        this.departments = res.dataList
        this.setDropdownValue()
        this.setActionsByDepartment()
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }

  filterByDate(): void {
    this.dialog.open(FilterDashboardComponent, {
      data: { filterParams: this.filterParams }
    })
      .afterClosed()
      .subscribe((result) => {
        this.filtered = result.year != null || result.date1 != null || result.date2 != null;

        if (!this.filtered) {
          return;
        }

        this.filterParams.year = result.year
        if (result.date1) {
          this.filterParams.date1 = result.date1
        }

        if (result.date2) {
          this.filterParams.date2 = result.date2
        }
        this.filterParams.companyId = result.companyId

        this.getDashboard();
      });
  }

  clearFilter(): void {

    if (!this.filtered) {
      return;
    }
    this.filterParams = {
      year: null,
      date1: this.datePipe.transform(`01-01-${new Date().getFullYear()}`, 'yyyy-MM-dd'),
      date2: this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd'),
      companyId: this.authInfo.getCompanyId()
    }

    this.getDashboard();
    this.filtered = false;
  }
  changeEmployee_Department(){
    this.employeeByDepartments = []
    if (this.dropdownEmployeeDepartment.length < 1) {
      return
    }
    let data = []
    this.dropdownEmployeeDepartment.forEach(e => {
      const departmentText = (e.shortName ?? e.departmentName)
      let chartValue = {
        description: `${departmentText} (0)`,
        value: 0
      }
      const departmentValues = this.personalActionByDepartment.find(x => x.description == departmentText)
      if (departmentValues) {
        chartValue = {
          description: `${departmentValues.description} (${departmentValues.value})`,
          value: departmentValues.value
        }
      }
      data.push(chartValue)
    })
    this.employeeByDepartments = data
    
  }
  setActionsByDepartment(){
    
    if(this.dropdownEmployeeDepartment.length < 1) {
      this.employeeByDepartments = []
      return
    }
    let data = []
    this.dropdownEmployeeDepartment.forEach(element => {
      const departmentText = (element.shortName ?? element.departmentName)
      const departmentData = this.personalActionByDepartment.find(x => x.description == departmentText)
      let chartValues = {
        description: `${departmentText} (0)`,
        value: 0
      }
      if (departmentData) {
        chartValues = {
          description: `${departmentData.description} (${departmentData.value})`,
          value: departmentData.value
        }
      }
      data.push(chartValues)
    });
    this.employeeByDepartments = data
  }
  
  getDepartmentsHasData(text: string): boolean {
    const find = this.personalActionByDepartment.find(x => x.description == text && x.value > 0)
    return find ? true : false;
  }
  setDropdownValue(){
    let count = 0
    let data: chart[] = []
    this.departments.forEach(e => {
      if (count < 5) {
        if (this.getDepartmentsHasData(e.shortName)) {
          data.push(e)
          count++;
        }
      }
    })
    this.dropdownEmployeeDepartment = data
  }
  
  dropdownConfig(displayKey, placeholder?) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

}