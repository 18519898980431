import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import Swal from 'sweetalert2';
import { DisapproveMassive } from '../models/PayrollNews.model';

@Component({
  selector: 'app-reason-disapprove-news',
  templateUrl: './reason-disapprove-news.component.html',
  styleUrls: ['./reason-disapprove-news.component.css']
})
export class ReasonDisapproveNewsComponent implements OnInit {
  form: FormGroup;
  newsIds: number[] = []
  constructor(
    private dialogRef: MatDialogRef<ReasonDisapproveNewsComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private payrollNewService: PayrollNewService,
    private srvAuth: AuthInfoService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      reason: ['', [Validators.required]]
    })
    this.newsIds = this.data.ids
  }

  save(){
    if(this.form.invalid) return
    const model: DisapproveMassive = {
      payrollNewsId: this.newsIds,
      reason: this.form.value.reason,
      userId: this.srvAuth.getCompanyId()
    }


    
    this.payrollNewService.disapproveMassive(model).subscribe(res => {
      if (res.succeded == false) {
        this.toastr.error(res?.errors[0])
        return
      }
      Swal.fire("Novedades desaprobadas", '', 'success');
      this.close(true)
    }, error => {
      this.toastr.error(`Error al aprobar la novedades`, "Error inesperado");
    });
  }

  close(success = false){
    this.dialogRef.close({success})
  }


}
