import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MultiSelectDataModel } from './models/multi-select-data-model';
import { MultiSelectOptionModel } from './models/multi-select-option-model';

@Component({
  selector: 'el-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() options: MultiSelectDataModel;
  @Input() childrenIcon: string;
  @Input() multiselect: boolean = true;
  @Input() dropdownText: string;
  @Input() data: Array<{ id: number | string, name: string, childrenIds: Array<number | string> }>;
  @Output() dataChange = new EventEmitter();
  dropDownItems: Array<MultiSelectOptionModel> = [];
  selectedItems: Array<MultiSelectOptionModel> = [];
  dropdownSettings: any;

  constructor(private changeRef: ChangeDetectorRef) { }

  onDataChange() {
    this.dataChange.emit(this.data);
  }

  ngAfterViewChecked(): void {
    this.changeRef.detectChanges();
  }

  ngOnChanges() {
    this.selectedItems = this.data?.map(item => ({ id: item.id, itemName: item.name, childrenIds: item.childrenIds }));
    this.dropDownItems = this.options.data.map(item => ({ id: item.id, itemName: item.name, childrenIds: [] }));
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      text: this.dropdownText,
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      enableSearchFilter: true,
    };

    this.dropDownItems = this.options.data.map(item => ({ id: item.id, itemName: item.name, childrenIds: [] }));
    this.selectedItems = this.data?.map(item => ({ id: item.id, itemName: item.name, childrenIds: item.childrenIds }));
  }

  getOptionsSelectedQuantity(id: number) {
    const item = this.data.find(item => item.id === id);
    return item.childrenIds.length;
  }

  onChildrenCLick(value, id, childId) {
    const item = this.data.find(item => item.id === id);

    const index = item.childrenIds.indexOf(childId);

    if (value) {
      if (index <= -1) {
        item.childrenIds.push(childId);
      }
    } else {
      if (index > -1) {
        item.childrenIds.splice(index, 1);
      }
    }

    this.onDataChange();
  }

  onChildrenSelect(id, childId) {
    const item = this.data.find(item => item.id === id);
    item.childrenIds = [childId];

    this.onDataChange();
  }

  onItemSelect() {
    this.data = this.selectedItems.map(item => ({ id: item.id, name: item.itemName, childrenIds: item.childrenIds }));

    this.onDataChange();
  }

  OnItemDeSelect() {
    this.data = this.selectedItems.map(item => ({ id: item.id, name: item.itemName, childrenIds: item.childrenIds }));

    this.onDataChange();
  }

  onSelectAll() {
    this.data = this.selectedItems.map(item => ({ id: item.id, name: item.itemName, childrenIds: item.childrenIds }));
    this.onDataChange();
  }

  onDeSelectAll() {
    this.data = this.selectedItems.map(item => ({ id: item.id, name: item.itemName, childrenIds: item.childrenIds }));
    this.onDataChange();
  }
}
