import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-dashboard-manage-payroll',
  templateUrl: './dashboard-manage-payroll.component.html',
  styleUrls: ['./dashboard-manage-payroll.component.css']
})
export class DashboardManagePayrollComponent implements OnInit {

  dataDiscounts = [
    { description: "Rectoria", value: 12 },
    { description: "Finanzas", value: 30 },
    { description: "RRHH", value: 15 },
    { description: "Seguridad", value: 70 },
    { description: "Compras", value: 25 },
    { description: "Content Factory", value: 16 },
    { description: "Comunicaciones", value: 10 },
    { description: "Tecnología", value: 100 }
  ]
  data = [{
    description: "SD",
    value: 300
  }, {
    description: "Santiago",
    value: 100
  }, {
    description: "SPM",
    value: 75
  }, {
    description: "SDE",
    value: 50
  }, {
    description: "Bonao",
    value: 45
  }, {
    description: "La Vega",
    value: 42
  }, {
    description: "Azua",
    value: 41
  }, {
    description: "Moca",
    value: 13
  }
];
  constructor( public dialogRef: MatDialogRef<DashboardManagePayrollComponent>) { 
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

}
