<div class="row">
    <div class="table-responsive border-u bg-white p-0">
        <table class="table table-vertical-center">
            <thead class="bg-primary">
                <tr>
                    <th class="text-center">Código</th>
                    <!-- <th class="text-center">Cédula</th>
                    <th class="text-center">No. NSS</th> -->
                    <th class="text-center">Nombre Completo</th>
                    <!-- <th class="text-center">Apellidos</th> -->
                    <th class="text-center">Fecha Nacimiento</th>
                    <!-- <th class="text-center">Género</th> -->
                    <th class="text-center">Parentezco</th>
                    <th class="text-center">Estudia</th>
                    <th class="text-center">Trabaja</th>
                    <!-- <th class="text-center">Acciones</th> -->
                </tr>
            </thead>
            <tbody *ngIf="employeeDependents && employeeDependents.length>0">
                <tr *ngFor="let item of employeeDependents">
                    <td class="text-center">{{item.dependentsId}}</td>
                    <td class="text-center">{{item.fullName}}</td>
                    <td class="text-center">{{item.birthday | date: "dd/MM/yyyy" }}</td>
                    <td class="text-center">{{item.relationship}}</td>
                    <td class="text-center">{{item.study ? "Sí" : "No"}}</td>
                    <td class="text-center">{{item.job ? "Sí" : "No"}}</td>
                    <!-- <td class="text-center actionsBtn">                                                
                        <a class="btn btn-sm btn-light-success btn-icon mr-2" data-toggle="modal" data-target="#EditDependiente">
                            <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                        </a>
                        <a class="btn btn-sm btn-light-danger btn-icon" data-toggle="tooltip" data-title="Remover">
                            <i class="flaticon-delete"></i>
                        </a>
                    </td> -->
                </tr>
                <!-- <tr>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center actionsBtn">                                                
                        <a class="btn btn-sm btn-light-success btn-icon mr-2" data-toggle="modal" data-target="#EditDependiente">
                            <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                        </a>
                        <a class="btn btn-sm btn-light-danger btn-icon" data-toggle="tooltip" data-title="Remover">
                            <i class="flaticon-delete"></i>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center">Tests</td>
                    <td class="text-center actionsBtn">                                                
                        <button (click)="editDependent()" class="btn btn-sm btn-light-success btn-icon mr-2" data-toggle="modal" data-target="#EditDependiente">
                            <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                        </button>
                        <a class="btn btn-sm btn-light-danger btn-icon" data-toggle="tooltip" data-title="Remover">
                            <i class="flaticon-delete"></i>
                        </a>
                    </td>
                </tr> -->
            </tbody>
        </table>
    </div>
</div>
<div class="col-md-12 gutter-t">
    <div class=" px-5">
        <div class="form-group d-flex justify-content-between align-items-center p-3">
            <button [routerLink]="['/employees']"  class="btn btn-pill font-weight-bolder btn-shadow btn-warning mr-3 ">
                <i class="flaticon2-left-arrow-1"></i>
                <span>Volver</span>
            </button> 
            <!-- <button class="btn btn-pill font-weight-bolder btn-shadow btn-success ">
                <i class="flaticon-disco-flexible"></i>
                <span>Guardar</span>
            </button>                    -->
        </div>
    </div>
</div>
<!-- <el-modal target="EditDependiente" title="Editar Dependiente" size="xl">
    <div class="row">
        <div class="form-group col-lg-4">
            <label>Código</label>
            <input type="text" class="form-control form-control-lg"/>
        </div>
        <div class="form-group col-lg-4">
            <label>Cédula</label>
            <input type="text" class="form-control form-control-lg"/>
        </div>
        <div class="form-group col-lg-4">
            <label>No. NSS</label>
            <input type="text" class="form-control form-control-lg"/>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-lg-4">
            <label>Nombres <span class="text-danger">*</span></label>
            <input type="text" class="form-control form-control-lg" />
        </div>
        <div class="form-group col-lg-4">
            <label>Apellidos <span class="text-danger">*</span></label>
            <input type="text" class="form-control form-control-lg" />
        </div>
        <div class="form-group col-lg-4">
            <label>Fecha Nacimiento <span class="text-danger">*</span></label>
            <input type="date" class="form-control form-control-lg" />
        </div>
    </div>
    <div class="row">
        <div class="form-group col-lg-4">
            <label>Género <span class="text-danger">*</span></label>
            <ngx-select-dropdown class=" flex-1"></ngx-select-dropdown>
        </div>
        <div class="form-group col-lg-4">
            <label>Parentezco <span class="text-danger">*</span></label>
            <input type="text" class="form-control form-control-lg" />
        </div>
   </div>
   <div class="row border-top">
       <div class="d-flex justify-content-end align-items-center p-3">
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3" data-dismiss="modal">
                <i class="flaticon2-cross"></i>   
                Cerrar
            </button> 
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-success ">
                <i class="flaticon-disco-flexible"></i>
                <span>Guardar</span>
            </button>                   
        </div>
   </div>
</el-modal> -->