import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { environment } from 'src/environments/environment';

declare const KTUtil: any;
declare const KTLayoutQuickPanel: any;

@Component({
  selector: 'app-side-bar-content',
  templateUrl: './side-bar-content.component.html',
  styleUrls: ['./side-bar-content.component.scss']
})

export class SideBarContentComponent implements OnInit, OnDestroy {

  enviroment = environment;
  subscription: Subscription = new Subscription();
  useCollectiveVacations: boolean = false;

  constructor(private authInfo: AccountService) { }
 

  ngOnInit(): void {
    this.subscription = this.authInfo.getAccountSubject().subscribe({
      next: res => {
        this.useCollectiveVacations = res?.useCollectiveVacations;
      }
    });
    // this.initMenu("Seguridad");
    // this.initMenu("recursos_humanos");
    // this.initMenu("academico");
    // this.initMenu("xdigit");
    // this.initMenu("admin_tab");
    // this.initMenu("configuracion");

  }

  hideMenu(){
    document.getElementById('reclutamiento_seleccion_close').click();
  }
  hideMenuEmployees(){
    document.getElementById('registro_control_close').click();
  }
  hideMenuOrganicStructure(){
    document.getElementById('estructura_organica_close').click();
  }
  hideMenuPlanning(){
    document.getElementById('planificacion_close').click();
  }
  hideMenuPayroll(){
    document.getElementById('nomina_close').click();
  }

  hideEvDes(){
    document.getElementById('evaluacion_desempeno_close').click();
  }

  hideHistorialSalud(){
    document.getElementById('historial_salud_close').click();
  }

  hideFormationDevelopment(){
    document.getElementById('capacitacion_desarrollo_close').click();
  };

  hideContentManagement(){
    document.getElementById('gestion_contenido_close').click();
  }

  // initMenu(uniqueId: string): any {
  //   KTUtil.ready(function() {
  //     KTLayoutQuickPanel.init(uniqueId);
  //   });
  //   return uniqueId;
  // }

  closeAll(){
    let classesOverly: any = document.getElementsByClassName("close-w");
    classesOverly.forEach(element => {
      element.click();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}