import { SweetAlert } from '../../../shared/utils/Sweet-Alert';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { approvedPayroll, Payroll } from '../open-payroll/models/mock-data';
import Swal from 'sweetalert2';
import { PayrollReport, Reports } from '../open-payroll/models/mock-data';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { MatDialog } from '@angular/material/dialog';
import { FlyerComponent } from './flyer/flyer.component';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PayrollReportService } from 'src/app/services/payroll-report.service';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'payroll-report',
  templateUrl: './payroll-report.component.html',
  styleUrls: ['./payroll-report.component.scss']
})
export class PayrollReportComponent implements OnInit {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PAYROLL-REPORT',
    exportAction: 'EXPGEN',
    searchAction: 'SEARGEN'
  }

  sweetAlert = new SweetAlert()
  notDataFound = 'No se encontraron registros';

  headers = [
    { label: 'Id de Reporte', key: 'id' },
    { label: 'Nombre de Reporte', key: 'name' },
  ]

  selectedItems: any;
  @ViewChild('paginator') paginator: CaptionComponent;

  filterText: string = '';
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "reportId", displayName: "Secuencia" },
      { propkey: "reportName", displayName: "Nombre del Reporte" },
    ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' }
  ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };


  payrollRecords: Payroll[] = approvedPayroll
  records: Payroll[] = []


  payrollReports: any[] = [];
  payrollReportsOriginalList: any[] = [];
  paginatedPayroll: any[] = [];

  constructor(
    private _exlService: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private parameterServices: ParameterControlService,
    private _toastService: ToastService,
    private dialog: MatDialog,
    public authInfo: AuthInfoService,
    private srvReports: PayrollReportService,
  ) {

  }

  ngOnInit(): void {
    this._cdRef.detectChanges();
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result=>{
      if(result == true){
        this.getPayrollReports();
      }
    });
    applySort();
  }

  viewReport(report){
    const { reportUrl } = report
    window.open(reportUrl,
    '_blank',
    "toolbar=yes,scrollbars=yes,resizable=yes,top=10,left=10,width=1024,height=800");
    return
  }

  getPayrollReports(){
    this.srvReports.getReports().subscribe((res:any) => {
      if(res.succeded = false){
        this._toastService.error(res.errors[0])
        return
      }

      this.payrollReports = res.dataList;
      this.payrollReportsOriginalList =  res.dataList;
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
    /* this.parameterServices.getParameters(keyWord.PayrollReports).subscribe((res: any) => {
      if(res.errors.length > 0){
        this._toastService.error(res.errors[0]);
      }
      this.payrollReports = res.dataList;
      this.payrollReportsOriginalList =  res.dataList;
    }, error => {
      this._toastService.error('Ocurrió un error inesperado','');
    }); */
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    //document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.payrollReports = this.filterTable([...this.payrollReportsOriginalList]);
    }
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl?.find(x => x.item_id == 1) && !sl?.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl?.find(x => x.item_id == 2) && !sl?.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    return list;
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
    this.filteredList.records = this.filterTable(event.records)
  }

  getPaginatedRecords(event) {
    this.paginatedPayroll = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  /*openReport() {
    window.open('http://20.22.219.21/Reports2019/report/rpt_compare_payroll', '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=10,left=10,width=1024,height=800");
  }*/

  onKeyChange(text) {
    this.filterText = text;
  }

  export(type){
    if(type.ocode === 1){
      this.openFlyerComponent(null);
    }
  }

  openFlyerComponent(item: any) {
    this.dialog.open(FlyerComponent, {
      data: {
        item: item
      },
      width: '100%'
    })
      .afterClosed()
      .subscribe((result) => {

      });
  }


  exportToPdf() {
    const reportCode: ReportCode = ReportCode.Generatereports;
    const companyId: number = this.authInfo.getCompanyId();
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&Status=1`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Reportes',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  // onBtnPrintClick(){
  //   let printData = document.getElementById('dataToPrint').cloneNode(true);
  //   document.body.classList.add('mode-print');
  //   document.body.appendChild(printData);
  //   window.print();
  //   document.body.classList.remove('mode-print');
  //   document.body.removeChild(printData);
  // }


}
