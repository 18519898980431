<div class="card card-custom gutter-b">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-proceso-3 icon-3x text-danger mr-2"></i>
                <h1 class="text-primary">Estadística de Nóminas</h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Dashboard">
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
            <div class="card-toolbar d-flex justify-content-start" [formGroup]="statisticsForm">

                <div class="col-md-3 px-0">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Período <span class="text-danger text-monospace legend">*</span>
                        </label>
                        <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="dropdownSettings"
                            [data]="periods" (onSelect)="onPeriodSelect($event)" (onDeSelect)="onDeselectPeriod($event)"
                            (onDeSelectAll)="onDeselectAllPeriods()" (onSelectAll)="onPeriodSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="col-md-3 px-0">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Área Presupuestaria <span class="text-danger text-monospace legend">*</span>
                        </label>
                        <ngx-select-dropdown formControlName="area" [options]="budgetAreas"
                            [config]="dropdownConfig('description','Seleccionar')">
                        </ngx-select-dropdown>
                    </div>
                </div>
                <div class="col-md-3 px-0">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Tipo de Nómina <span class="text-danger text-monospace legend">*</span>
                        </label>
                        <ngx-select-dropdown formControlName="type" [options]="payrollTypes"
                            [config]="dropdownConfig('stringData', 'Seleccionar')">
                        </ngx-select-dropdown>
                    </div>
                </div>
                <div class="col-md-3 px-0">
                    <button *ngIf="this._auth.canDoAction(this.privilege.nameKeyOption, this.privilege.search)" [disabled]="statisticsForm.invalid" (click)="GetDetailPayrollAudit()"
                        class="btn btn-primary btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                        <i class="flaticon2-search-1 icon-2x"></i> Buscar
                    </button>
                </div>
                <!-- <div class="col-md-3 px-0">
                    <button [disabled]="statisticsForm.invalid" (click)="GetDetailPayrollAudit()"
                        class="btn btn-primary btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                        <i class="flaticon2-search-1 icon-2x"></i> Buscar
                    </button>
                </div> -->

            </div> 

        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="col text-nowrap">
                    <ul class="nav nav-primary nav-pills text-uppercase mt-8" id="myTab2" role="tablist">
                        <li class="nav-item col-md-auto mb-5">
                            <a *ngIf="this._auth.canDoAction(this.privilege.nameKeyOption, this.privilege.quantity)" class="nav-link active" id="nempleado-tab-2" data-toggle="tab" href="#porCantidad">
                                <span class="nav-icon mr-2 ">
                                    <i class="flaticon-productividad icon-2x"></i>
                                </span>
                                <span class="nav-text font-size-h6">Por Cantidad</span>
                            </a>
                        </li>
                        <li class="nav-item col-md-auto mb-5">
                            <a *ngIf="this._auth.canDoAction(this.privilege.nameKeyOption, this.privilege.values)" class="nav-link" id="inomina-tab-2" data-toggle="tab" href="#porValores"
                                aria-controls="inomina">
                                <span class="nav-icon mr-2 ">
                                    <i class="flaticon-agil icon-2x"></i>
                                </span>
                                <span class="nav-text font-size-h6">Por Valores</span>
                            </a>
                        </li>
                        <li class="nav-item col-md-auto mb-5">
                            <a *ngIf="this._auth.canDoAction(this.privilege.nameKeyOption, this.privilege.detail)" class="nav-link" id="inomina-tab-2" data-toggle="tab" href="#porDetalle"
                                aria-controls="idetalle">
                                <span class="nav-icon mr-2">
                                    <i class="flaticon-agil icon-2x"></i>
                                </span>
                                <span class="nav-text font-size-h6">Detalle</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="tab-content" id="myTabContent2">
        <div class="tab-pane fade active show" id="porCantidad" role="tabpanel" aria-labelledby="nempleado-tab-2">
            <div class="row">

            </div>
            <div class="card-body">
                <div class="table-responsive table-responsive-sigei mt-5">
                    <table class="table table-hover table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-center">Secuencia</th>
                                <th>Unidad Organizativa</th>
                                <th class="text-center" *ngFor="let period of departmentsPeriods">
                                    {{period.period}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of viewGetDetailByDeparmentsRecords">
                                <td class="text-center">{{item.departmentId}}</td>
                                <td class="">{{item.departmentName}}</td>
                                <td class="text-center" *ngFor="let count of item.employeeAmount">
                                    {{count}}</td>
                                <!--td class="text-center">{{item.totalNetIncome}}</td-->
                            </tr>
                        </tbody>
                    </table>

                    <div>
                        <el-caption #paginator [originalRecordsLength]="viewGetDetailByDeparmentsOriginalList.length"
                            [listedRecordsLength]="viewGetDetailByDeparmentsRecords.length"
                            [registerQuantity]="viewGetDetailByDeparments?.length" [records]="viewGetDetailByDeparments"
                            (onChage)="getPaginatedRecordsDeparments($event)"></el-caption>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="porValores" role="tabpanel" aria-labelledby="inomina-tab-2">
            <div class="row">
                <div class="card-body">
                    <div class="table-responsive table-responsive-sigei mt-5" *ngIf="viewGetDetailResumes.length > 0">
                        <table class="table table-hover table-vertical-center">
                            <thead>
                                <tr class="bg-primary">
                                    <th class="text-left">Concepto</th>
                                    <th class="text-center" *ngFor="let item of viewGetDetailResumes">
                                        {{item?.period}}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td class="text-left">Salario Bruto</td>
                                    <td class="text-center" *ngFor="let item  of viewGetDetailResumesOriginalList">
                                        {{item.totalGrossSalary | currency: 'RD$'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">AFP</td>
                                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                        {{item.totalAfp
                                        | currency: 'RD$'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">SFS</td>
                                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                        {{item.totalSfs
                                        | currency: 'RD$'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">TSS</td>
                                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                        {{item.totalTss
                                        | currency: 'RD$'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">ISR</td>
                                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                        {{item.totalIsr
                                        | currency: 'RD$'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">SFS Empleador</td>
                                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                        {{item.totalSfse
                                        | currency: 'RD$'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">AFP Empleador</td>
                                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                        {{item.totalAfpe
                                        | currency: 'RD$'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">SRL(Riesgo Laboral)</td>
                                    <td class="text-center" *ngFor="let item  of viewGetDetailResumesOriginalList">
                                        {{item.totalSrl
                                        | currency: 'RD$'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">Otros Decuentos</td>
                                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                        {{item.totalOtherDebits | currency: 'RD$'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">Otros Ingresos</td>
                                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                        {{item.totalOtherCredits | currency: 'RD$'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-left">Descuento Total</td>
                                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                        {{item.totalDiscount | currency: 'RD$'}}</td>
                                </tr>

                            </tbody>
                            <tfoot>
                                <tr>
                                    <td class="text-left fw-bold">Total Neto</td>
                                    <td class="text-center" *ngFor="let item of viewGetDetailResumesOriginalList">
                                        {{item.totalNetIncome | currency: 'RD$'}}</td>
                                </tr>
                            </tfoot>
                        </table>

                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="porDetalle" role="tabpanel" aria-labelledby="inomina-tab-2">
            <div class="d-flex justify-content-end mr-6 mt-5">
                <el-search-bar #searchBar (onKeyChange)="onKeyChange($event)" [keyValue]="filterText"
                [records]="overallSummariesOriginalList" [itemForSearching]="searchBarDisplayNames"
                (onChange)="getFilteredList($event);">
              </el-search-bar>
            </div>
            
            <div class="card-body">
                <div class="table-responsive table-responsive-sigei">
                    <table class="table table-hover table-vertical-center">
                      <thead>
                        <tr class="bg-primary">
                          <th>Servidor Público</th>
                          <th>Genero</th>
                          <th class="">Cargo</th>
                          <th class="">Unidad Organizativa</th>
                          <th class="">Tipo</th>
                          <th class="text-right">Ingreso Bruto</th>
                          <th class="text-right">Otros ingresos</th>
                          <th class="text-right">Ingreso total</th>
                          <th class="text-right">AFP</th>
                          <th class="text-right">SFS</th>
                          <th class="text-right">ISR</th>
                          <th class="text-right">Otro Desc.</th>
                          <th class="text-right">Total Desc.</th>
                          <th class="text-right">Neto</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let overallSumaries of viewGetOverallSummariesRecords">
                            <tr>
                                <th class="text-left bg-light-secondary" colspan="14" >
                                    <span class="text-dark text-uppercase ">
                                        {{overallSumaries.departmentName}}
                                    </span>
                                </th>
                            </tr>
                            <ng-container  *ngFor="let department of overallSumaries.departments">
                                <th class="text-left bg-light-secondary" colspan="14" >
                                    <span class="text-dark text-uppercase ">
                                        {{department?.payrollPeriodName}}
                                    </span>
                                </th>
                                <tr *ngFor="let periods of department.payrollPeriods">
                                    <td>{{periods.fullName}}</td>
                                    <td class="text-center">{{periods?.sex}}</td>
                                    <td class="">{{periods?.positionName}}</td>
                                    <td class="">{{periods?.departmentName}}</td>
                                    <td class="">{{periods?.employeeType}}</td>
                                    <td class="text-right">{{periods?.grossSalary | currency: 'RD$'}}</td>
                                    <td class="text-right">{{periods?.otherCredits | currency: 'RD$'}}</td>
                                    <td class="text-right">{{periods?.totalSalary | currency: 'RD$'}}</td>
                                    <td class="text-right">{{periods?.afp | currency: 'RD$'}}</td>
                                    <td class="text-right">{{periods?.sfs | currency: 'RD$'}}</td>
                                    <td class="text-right">{{periods?.isr | currency: 'RD$'}}</td>
                                    <td class="text-right">{{periods?.otherDiscounts | currency: 'RD$'}}</td>
                                    <td class="text-right">{{periods?.totalDiscount | currency: 'RD$'}}</td>
                                    <td class="text-right">{{periods?.netIncome | currency: 'RD$'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-left fw-bolder w-100">Sub totales - {{department?.payrollPeriodName}}:</td>
                                    <td class="text-left"></td>
                                    <td class="text-left"></td>
                                    <td class="text-left"></td>
                                    <td class="text-left"></td>
                                    <td class="text-right fw-bold">{{department.grossSalary | currency: 'RD$'}}</td>
                                    <td class="text-right fw-bold">{{department.otherCredits | currency: 'RD$'}}</td>
                                    <td class="text-right fw-bold">{{department.totalSalary | currency: 'RD$'}}</td>
                                    <td class="text-right fw-bold">{{department.afp | currency: 'RD$'}}</td>
                                    <td class="text-right fw-bold">{{department.sfs | currency: 'RD$'}}</td>
                                    <td class="text-right fw-bold">{{department.isr | currency: 'RD$'}}</td>
                                    <td class="text-right fw-bold">{{department.otherDiscounts | currency: 'RD$'}}</td>
                                    <td class="text-right fw-bold">{{department.totalDiscount | currency: 'RD$'}}</td>
                                    <td class="text-right fw-bold">{{department.netIncome | currency: 'RD$'}}</td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td class="text-left fw-bolder">Sub totales:</td>
                                <td class="text-left"></td>
                                <td class="text-left"></td>
                                <td class="text-left"></td>
                                <td class="text-left"></td>
                                <td class="text-right fw-bold">{{overallSumaries.grossSalary | currency: 'RD$'}}</td>
                                <td class="text-right fw-bold">{{overallSumaries.otherCredits | currency: 'RD$'}}</td>
                                <td class="text-right fw-bold">{{overallSumaries.totalSalary | currency: 'RD$'}}</td>
                                <td class="text-right fw-bold">{{overallSumaries.afp | currency: 'RD$'}}</td>
                                <td class="text-right fw-bold">{{overallSumaries.sfs | currency: 'RD$'}}</td>
                                <td class="text-right fw-bold">{{overallSumaries.isr | currency: 'RD$'}}</td>
                                <td class="text-right fw-bold">{{overallSumaries.otherDiscounts | currency: 'RD$'}}</td>
                                <td class="text-right fw-bold">{{overallSumaries.totalDiscount | currency: 'RD$'}}</td>
                                <td class="text-right fw-bold">{{overallSumaries.netIncome | currency: 'RD$'}}</td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                        </ng-container>
                      </tbody>
                      <tfoot>
                        <tr class="bg-light-secondary">
                          <td class="text-left fw-bolder">TOTAL GENERAL:</td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td class="text-right fw-bolder">{{getTotalOverallSummarie('grossSalary') | currency: 'RD$'}}</td>
                          <td class="text-right fw-bolder">{{getTotalOverallSummarie('otherCredits') | currency: 'RD$'}}</td>
                          <td class="text-right fw-bolder">{{getTotalOverallSummarie('totalSalary') | currency: 'RD$'}}</td>
                          <td class="text-right fw-bolder">{{getTotalOverallSummarie('afp') | currency: 'RD$'}}</td>
                          <td class="text-right fw-bolder">{{getTotalOverallSummarie('sfs') | currency: 'RD$'}}</td>
                          <td class="text-right fw-bolder">{{getTotalOverallSummarie('isr') | currency: 'RD$'}}</td>
                          <td class="text-right fw-bolder">{{getTotalOverallSummarie('otherDiscounts') | currency: 'RD$'}}</td>
                          <td class="text-right fw-bolder">{{getTotalOverallSummarie('totalDiscount') | currency: 'RD$'}}</td>
                          <td class="text-right fw-bolder">{{getTotalOverallSummarie('netIncome') | currency: 'RD$'}}</td>
                        </tr>
                      </tfoot>
                    </table>

                    <div>
                        <el-caption #paginatorOverallSummaries [originalRecordsLength]="viewGetOverallSummaries.length"
                            [listedRecordsLength]="viewGetOverallSummariesRecords.length"
                            [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :viewGetOverallSummaries.length"
                            [records]="filteredList.enable ? filteredList.records : viewGetOverallSummaries"
                            (onChage)="getPaginatedOverallSummaries($event)"></el-caption>
                    </div>
                </div>
            </div>
    </div>
</div>