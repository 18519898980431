import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountService } from 'src/app/services/account.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { docService } from 'src/app/services/doc.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { serviceRequestService } from 'src/app/services/serviceRequest.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-employee-service-request-detail-modal-form',
  templateUrl: './employee-service-request-detail-modal-form.component.html',
  styleUrls: ['./employee-service-request-detail-modal-form.component.css']
})

export class employeeServiceRequestDetailModalFormComponent implements OnInit {
  @ViewChild("userHtml", { static: false }) userHtml;
  public body = {
    year: '',
    nameTo: '',
    positionTo: '',
    addressTo: '',
    institutionTo: '',
    tittleTo: '',
  }

  public title = "Solicitud de Servicio"

  constructor(
    private _toastService: ToastService,
    private _serviceRequestService: serviceRequestService,
    private dialogRef: MatDialogRef<employeeServiceRequestDetailModalFormComponent>,
    @Inject(MAT_DIALOG_DATA) public params,) { }

  ngOnInit(): void {
    if (this.params) {
      this.body.year = this.params;
    } else {
      this._serviceRequestService.getInformationToJobLetter().subscribe(e => {
        if (e.singleData) {
          let data = e.singleData

          this.body = {
            year: data.year,
            nameTo: data.nameTo,
            positionTo: data.positionTo,
            addressTo: data.addressTo,
            institutionTo: data.institutionTo,
            tittleTo: data.dirigidoA,
          }
        }
      })
    }
  }

  close() {
    this.dialogRef.close(null);
  }

  aprovee() {
    if (this.body.year == '' || this.body.nameTo == '' || this.body.addressTo == '' || this.body.institutionTo == '' || this.body.positionTo == '' || this.body.tittleTo == '') {
      this._toastService.info('Debe completar los campos faltantes')
      return
    }

    this.dialogRef.close(this.body);
  }
}
