import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuditApprovalService } from 'src/app/services/audit-approval.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';

@Component({
  selector: 'app-view-detail-reject-payroll',
  templateUrl: './view-detail-reject-payroll.component.html',
  styleUrls: ['./view-detail-reject-payroll.component.css']
})
export class ViewDetailRejectPayrollComponent implements OnInit {
  payrollReject: any[] = [];
  reasons: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<ViewDetailRejectPayrollComponent>,
    private srvParameter: ParameterControlService,
    @Inject(MAT_DIALOG_DATA) public data: {headerId: number},
    private payrollService: PayrollService,
    private toastr: ToastrService,
    private authInfo: AuthInfoService
  ) { 
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getPayrollReject();
  }

  getPayrollReject(){
    this.payrollService.getPayrollReject(this.data?.headerId, this.authInfo.getCompanyId())
    .subscribe((res: any) => {
      if (res.errors.length > 0){
        this.toastr.error(res.errors[0]);
        return;
      }
      this.payrollReject = res.dataList;
    }, error => {
      this.toastr.error('Ocurrió un error al tratar de obtener los motivos del rechazo');
    })
  }

}
