import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BulkLoadFinal } from '@payroll/final-payroll/models/models';
import { IIncentivePayrollPOST } from '@payroll/incentive-payroll/models/header';
import { environment } from 'src/environments/environment';
import { PayrollTypes } from '../shared/models/payrollTypes';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class IncentivePayrollService {

  private hrm = environment.api_url
  constructor(
    private http: HttpClient,
    private srvAuth: AuthInfoService
  ) { }
  getEmployees(endpoint: string){
    return this.http.get(`${this.hrm + endpoint}`)
  }

  postHeader(header: any){
    return this.http.post(`${this.hrm}/header`, header)
  }  
  getEmployeesPaginated(page?:number, pageSize?:number, PropkeyId = null, SearchParameter = null, budgetAreaId: number = 0, status: boolean = true){
    
    return this.http.get(`${this.hrm}/Employees/GetPaginatedFilter?BudgetAreaId=${budgetAreaId}&CompanyId=${this.srvAuth.getCompanyId()}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Page=${page}&PageSize=${pageSize}&Type=6`);
  }
  getOverallSumary(periodId, areaId){
    /* PayrollTypeId=1 para nominas finales */
    return this.http.get(`${this.hrm}/Detail/GetOverallSummary?PayrollPeriodId=${periodId}&PayrollTypeId=${PayrollTypes.incentiveWithoutContribution}&BudgetAreaId=${areaId}&Type=12&CompanyId=${this.srvAuth.getCompanyId()}&Status=true`)
  }
  getPeriods(){
    return this.http.get(`${this.hrm}/Period?CompanyId=${this.srvAuth.getCompanyId()}&Status=true`)
  }
  getBudgetAreas(){
    return this.http.get(`${this.hrm}/BudgetArea?BudgetAreaId=0&CompanyId=${this.srvAuth.getCompanyId()}&Status=true`)
  }
  getNewsConcept(companyId: number = this.srvAuth.getCompanyId(), status: boolean = true) {
    return this.http.get<any>(`${this.hrm}/NewsConcept?NewsConceptId=0&CompanyId=${companyId}&type=2&Status=${status}`);
  }
  getBulkLoadIncentive(bulkLoadFinal: BulkLoadFinal[]){
    return this.http.post(`${this.hrm}/BulkLoad/GetBulkLoadFinal`, bulkLoadFinal);
  }

  getHeader(headerId){
    return this.http.get(`${this.hrm}/Header/GetHeader?HeaderId=${headerId}&type=2&CompanyId=${this.srvAuth.getCompanyId()}`)
  }
}