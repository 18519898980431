import { SweetAlert } from "../../../../shared/utils/Sweet-Alert";
import { Component } from "@angular/core";
declare const $: any

@Component({
  selector: 'flyer-send-print',
  templateUrl: './flyer-send-print.component.html',
  styleUrls: ['./flyer-send-print.component.scss']
})
export class FlyerSendPrintComponent{
  message = "¡Enviando Volante!";
  sweetAlert = new SweetAlert()
  loading = false;
  constructor () {}
  async deleteAlert() {
      const success = await this.sweetAlert.AlertConfirm(
          'Eliminar',
          `¿Seguro que deseas borrar el Servidor Público de esta nómina?`,
          'error'
        );
    
        if (success) {
          this.loading = true
          setTimeout(() => { 
              this.loading = false
            }, 2000)
        } 
  }
  async sendMailMax() {
      const success = await this.sweetAlert.AlertConfirm(
          'Envío Masivo',
          `¿Seguro que deseas enviar correo masivo?`,
          'warning'
        );
    
        if (success) {
          this.loading = true
          setTimeout(() => { 
              this.loading = false
            }, 2000)
        } 
  }
  async sendMail() {
      const success = await this.sweetAlert.AlertConfirm(
          'Envío a Servidor Público',
          `¿Seguro que deseas enviar volante a este Servidor Público?`,
          'warning'
        );
    
        if (success) {
          this.loading = true
          setTimeout(() => { 
              this.loading = false
            }, 2000)
        } 
  }
  printPDF() {
    const url = '../../../../assets/pdf-Resources/Volante-de-pago.pdf';
    window.open(url, '_blank').focus();
  }

  

}
