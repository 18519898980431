<div [ngClass]="cardBody">
    <ng-content></ng-content>
    <div
        [ngClass]="{'d-none': fileData.filePath != '', 'd-block': fileData.filePath == '' }"
        [ngClass]="centerContentClass">
        <div [class]="targetClass"></div>
    </div>
   
    <div *ngIf="fileData.filePath != ''" [ngClass]="centerContentClass">
        <div [class]="classPreviewDefaultImage" *ngIf="!hidePreviewDefaultImage">
            <img alt="Pic" *ngIf="!imageExtension" [src]="previewDefaultImage" />
            <img alt="Pic" *ngIf="imageExtension" [src]="fileData.filePath" />
        </div>
        <div class="btn-group center" role="group">
            <button *ngIf="showButtonViewFile" type="button" class="btn btn-primary btn-icon"
                (click)="showFile(fileData.filePath, showFileModalTitle)">
                <i class="flaticon-ver"></i>
            </button>
        
            <button *ngIf="!isDisabled" (click)="removeFile(confirmation)" type="button" class="btn btn-danger btn-icon">
                <i class="flaticon-delete-1"></i>
            </button>
        </div>
    </div>
</div>
