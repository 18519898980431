import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { CompanyStructureService } from 'src/app/services/company-structure.service';
import { JobOfferService } from 'src/app/services/job-offer.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { PositionService } from 'src/app/services/position.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';

@Component({
  selector: 'app-advanced-filter-by-employees',
  templateUrl: './advanced-filter-by-employees.component.html',
  styles: [
  ]
})
export class AdvancedFilterByEmployeesComponent implements OnInit {

  units: { item_id: number, item_text: string }[] = [];
  organizationalUnit = []
  position = []
  employeeType = []
  category = []
  shift = []
  branch = []
  name
  startDate
  endDate
  id
  employeeTypeList = []
  sucursalOptions: { item_id: number, item_text: string }[] =
    [
      { item_id: 1, item_text: 'Sucursal A' },
      { item_id: 2, item_text: 'Sucursal B' },
      { item_id: 2, item_text: 'Sucursal C' },
    ];
  employeeCategories = []
  cargoOptions: { item_id: number, item_text: string }[] =
    [
      { item_id: 1, item_text: 'Cargo A' },
      { item_id: 2, item_text: 'Cargo B' },
      { item_id: 2, item_text: 'Cargo C' },
    ];
  positions = []
  tipoServidorPublicoOptions: { item_id: number, item_text: string }[] =
    [
      { item_id: 1, item_text: 'Tipo de Servidor Publico A' },
      { item_id: 2, item_text: 'Tipo de Servidor Publico B' },
      { item_id: 2, item_text: 'Tipo de Servidor Publico C' },
    ];

  categoriaOptions: { item_id: number, item_text: string }[] =
    [
      { item_id: 1, item_text: 'Categoría A' },
      { item_id: 2, item_text: 'Categoría B' },
      { item_id: 2, item_text: 'Categoría C' },
    ];

  tandaOptions: { item_id: number, item_text: string }[] =
    [
      { item_id: 1, item_text: 'Tanda A' },
      { item_id: 2, item_text: 'Tanda B' },
      { item_id: 2, item_text: 'Tanda C' },
    ];

  branches = []
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: true,
    limitSelection: -1
  };
  shifts = []
  filters = {
    id: [],
    name: [],
    unit: [],
    branch: [],
    employeeType: [],
    position: [],
    startDate: null,
    endDate: null,
    category: [],
    shift: []
  }


  constructor(public dialogRef : MatDialogRef<AdvancedFilterByEmployeesComponent>,
    private srvCompanyStructure: CompanyStructureService,
    private toastr: ToastrService,
    private srvParam: ParameterControlService,
    private jobOfferService: JobOfferService,
    private srvCompanies: CompaniesService,
    private srvRequisition: PersonalRequsitionService,
    private auth: AuthInfoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.fillFilter()
    //id,name,organizationalUnit,startDate,endDate,branch,position,employeeType,category,shift
    
    this.getEmployeeCategories()
    this.getEmployeeType()
    this.getOrganizations()
    this.getShifts()
    this.getBranches()
    this.getPositions()
  }

  fillFilter(){
    if(!this.data.advanced){
      return
    }
    let strFilter = this.data.advanced.split(',')

    
    this.filters.id = strFilter[0]
    this.filters.name = strFilter[1]
    this.filters.unit = strFilter[2].split('|')
    this.filters.startDate = strFilter[3]
    this.filters.endDate = strFilter[4]
    this.filters.branch = strFilter[5].split('|')
    this.filters.position = strFilter[6].split('|')
    this.filters.employeeType = strFilter[7].split('|')
    this.filters.category = strFilter[8].split('|')
    this.filters.shift = strFilter[9].split('|')

    this.id = this.filters.id
    this.name = this.filters.name
    this.startDate = this.filters.startDate
    this.endDate = this.filters.endDate
  }

  Cancel(success = false, text = null): void {
    this.dialogRef.close({success, text});
  }
  getBranches() {
    this.srvCompanies.getCompanies().subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          this.toastr.error(res.errors[0])
          return;
        }
        this.branches = res.dataList.map(x => {
          return {
            item_id: x.companyId,
            item_text: x.companyName,
            ...x
          }
        })
        this.branch = this.setDropdown(this.branches, this.filters.branch)
        
      },
      error: (err) => {

      }
    });
  }

  setDropdown(arrOriginal, arrToFilter){
    let o = []
    if(arrToFilter){
      arrOriginal.forEach(x => {
        arrToFilter.forEach(c => {
          if(Number(c) == Number(x.item_id)){
            o.push(x)
          }
        })
      })
    }
    return o
  }

  getShifts(){
    this.srvParam.getParameters(keyWord.HoursAvailable).subscribe({
      next: (res:any) => {
        if(res.errors.length > 0){
          this.toastr.error("Ha ocurrido un error inesperado")
          return
        }
        this.shifts = res.dataList.map(x => {
          return {
            item_id: x.ocode,
            item_text: x.stringData,
            ...x
          }
        })
        this.shift = this.setDropdown(this.shifts, this.filters.shift)

      }, error: (error:any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }
  getEmployeeCategories(){
    this.srvParam.getParameters(keyWord.EmployeeCategory).subscribe({
      next: (res:any) => {
        if(res.errors.length > 0){
          this.toastr.error("Ha ocurrido un error inesperado")
          return
        }
        this.employeeCategories = res.dataList.map(x => {
          return {
            item_id: x.ocode,
            item_text: x.stringData,
            ...x
          }
        })
        this.category = this.setDropdown(this.employeeCategories, this.filters.category)

      }, error: (error:any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }
  getEmployeeType() {
    this.jobOfferService.getAllEmployeeType().subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          });
        }
        this.employeeTypeList = res.dataList.map(x => {
          return {
            item_id: x.employeeTypeId,
            item_text: x.description,
            ...x
          }
        })
        
        this.employeeType = this.setDropdown(this.employeeTypeList, this.filters.employeeType)
      },
      error: (err) => {
        this.toastr.error('Ocurrió un error tratando de buscar los tipos de Servidor Público. Favor de comunicarse con un Administrador.')
      }
    })
  }

  getPositions() {
    
    this.srvRequisition.GetApprovedPositions(this.auth.getCompanyId(), true).then(data => {
      if (data.errors.length > 0) {
        this.toastr.error(data.errors[0])

      }
      if (data.succeded) {
        this.positions = data.dataList.sort((a, b) => a.positionName.trim().localeCompare(b.positionName.trim())).map(x => {
          return {
            item_id: x.positionId,
            item_text: x.positionName,
            ...x
          }
        })
        this.position = this.setDropdown(this.positions, this.filters.position)
      }
    })
  }


  getOrganizations(){
    this.srvCompanyStructure.getAllCompanyStructures().subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          if(res.errors.legnth > 0){
            this.toastr.error(res.errors[0])
          }
          return
        }

        this.units = res.dataList.map(x => {
          return {
            item_id: x.departmentId,
            item_text: x.departmentName,
            ...x
          }
        })
        this.organizationalUnit = this.setDropdown(this.units, this.filters.unit)
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  Accept() {
    let shift = this.getStringIds(this.shift)
    let category = this.getStringIds(this.category)
    let branch = this.getStringIds(this.branch)
    let position = this.getStringIds(this.position)
    let employeeType = this.getStringIds(this.employeeType)
    let organizationalUnit = this.getStringIds(this.organizationalUnit)

    let id = this.id ? this.id.trim() : ''
    let name = this.name ? this.name.trim() : ''
    let startDate = this.startDate ? moment(this.startDate).format('yyyy-MM-DD') : ''
    let endDate = this.endDate ? moment(this.endDate).format('yyyy-MM-DD') : ''
    let text = `${id},${name},${organizationalUnit},${startDate},${endDate},${branch},${position},${employeeType},${category},${shift}`
    
    this.Cancel(true,text)
  }

  getStringIds(arr){
    let str = ''
    if(!arr){
      return ''
    }
    let arrLength = arr.length
    if(arrLength === 0){
      return ''
    }
    arr.forEach((x, i) => {
      str += x.item_id
      if(arrLength !== (i + 1)){
        str += '|'
      }
    })

    return str
  }
}
