import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DropDownOptionModel } from '../dropdown/models/dropdown-option-model';
import { searchBarModel } from './model/searchBarModel';
import { FilterEvent } from '../search-bar-pagination/search-bar-pagination.component';
import { NgxSelectDropdownComponent } from 'ngx-select-dropdown';

@Component({
  selector: 'el-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() records: any[] = [];
  @Input() itemForSearching: { propKeyId?: number, propkey: string, displayName: string, exactSearch?: boolean, searchKey?:string, default?:any }[];
  @Input() defaultParameterValue: { propKeyId?: number, propkey: string, displayName: string, exactSearch?: boolean, searchKey?:string  }
  @Output() onChange = new EventEmitter<{ records: any[], enable: boolean, returnValues?: boolean, values?: any }>();
  @Output() onKeyChange = new EventEmitter<string>();
  @Output() onKeyParameterChange = new EventEmitter<{ propKeyId?: number, propkey: string, displayName: string } | null>();
  propList: { propKeyId?: number, propkey: string, displayName: string }[] = new Array();
  @Input() keyValue: string;
  @Input() namePageLocalStorage: string;
  @Input() returnValues: boolean = false;
  searchParameter: any
  searchParameterValue: { propKeyId?: number, propkey: string, displayName: string, exactSearch?: boolean, searchKey?:string  };

  parameterValue: { propKeyId?: number, propkey: string, displayName: string, exactSearch?: boolean, searchKey?:string, default?:any  }

  config = {
    displayKey: 'displayName',
   // displayFn:(item: any) => { return item?.displayName; },
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar',
    searchOnKey: 'searchKey',
    noResultsFound:'No se han encontrado registros'
  };

  @ViewChild('selectComp') selectComp: NgxSelectDropdownComponent;

  constructor(
    private _changeDet: ChangeDetectorRef,
    private toastr: ToastrService
  ) {



  }

  ngOnChanges(changes: SimpleChanges): void {

    this.itemForSearching = this.itemForSearching?.map(x=>{
      return {
        ...x,
        searchKey: x?.displayName?.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      }
    })

    if (!this.parameterValue?.propkey) {
      this.setFilterStorage();
    } else {
      this.filter(this.keyValue);
    }


  }

  ngOnInit(): void {
    this.searchParameter = this.itemForSearching.sort((a, b) => a.displayName.localeCompare(b.displayName))
    this.parameterValue  = this.itemForSearching.find(x => x?.default == true)

  }

  private setFilterStorage() {



    const localStorageSearch = JSON.parse(localStorage.getItem(this.namePageLocalStorage));
    this.parameterValue = localStorageSearch?.propkey ? this.itemForSearching?.find(p => p.propkey == localStorageSearch?.propkey) : null;
    this.keyValue = localStorageSearch?.value ?? '';
    if (this.parameterValue == null || !localStorageSearch) {
      this.parameterValue = this.itemForSearching.find((item: any) => item == this.defaultParameterValue)
    }
    if (this.keyValue && this.records?.length > 0) {
      this.filter(this.keyValue);
    }

    this.onKeyParameterChange.emit(this.parameterValue ?? null);
    this.emitText();
  }

  ngAfterViewInit(): void {
    this.getProps(this.records);
    this._changeDet.detectChanges();
  }

  emitText() {
    this.onKeyChange.emit(this.keyValue)
  }

  getProps(model) {
    for (let i = 0; i < this.itemForSearching.length; i++) {
      this.propList.push({ propKeyId: this.itemForSearching[i].propKeyId, propkey: this.itemForSearching[i].propkey, displayName: this.itemForSearching[i].displayName })
    }
  }

  filter(key: any) {
    // Emit event or perform action when filtering starts (optional)
    this.emitText();

    if (this.returnValues === true) {
      const values = {
        text: this.keyValue,
        propkey: this.parameterValue
      }
      // Emit event with specific values and return (clarify purpose)
      this.onChange.emit({ enable: false, records: [], returnValues: this.returnValues, values: values });
      return;
    }    

    this.searchParameterValue = this.parameterValue;

    // Prepare search key
    key = this.getNormalizedValue(key?.trimEnd());

    const filteredRecords: { records: any[], enable: boolean, text?: string, propkey: any } = { records: [], enable: false, text: '', propkey: '' };

    if (!key || key.length === 0) {
      filteredRecords.records = [...this.records];
      this.onChange.emit(filteredRecords);
      return;
    }

    if (this.searchParameterValue.propkey == 'personalIdentification') {
      key = key.replaceAll('-', '')
    }

    const searchWords = key.split(' '); //if there is spaces, split in spaces, unlees return the whole array

    if(this.parameterValue?.exactSearch){
      filteredRecords.records = this.useExactFilter()
    }else{
      filteredRecords.records = this.useNormalizedFilter(searchWords)
    }

    filteredRecords.enable = true;

    filteredRecords.text = this.keyValue
    filteredRecords.propkey = this.parameterValue

    if (filteredRecords?.records?.length === 0) {
      this.toastr.warning('No se encontraron registros con los criterios de búsqueda especificados');
    }

    if (this.namePageLocalStorage) {
      localStorage.setItem(this.namePageLocalStorage, JSON.stringify({ ...this.parameterValue, value: this.keyValue }));
    }

    this.onChange.emit(filteredRecords)
  }

  private useExactFilter() : any[] {
    const recordsFiltered: any[]  = this.records.filter(record => {
      const value = record[this.searchParameterValue.propkey]?.toString() || '';
      const text: any = (this.keyValue as string).toLocaleLowerCase()
      return value == text
    })
    return recordsFiltered
  }

  private useNormalizedFilter(searchWords)  : any[] {
    const recordsFiltered: any[]  = this.records.filter(record => {
      const propValue = record[this.searchParameterValue.propkey]?.toString() || '';
      const normalizedPropValue = this.getNormalizedValue(propValue);

      return searchWords.every(word => normalizedPropValue.indexOf(word.toLocaleLowerCase()) !== -1);
    });
    return recordsFiltered
  }

  getNormalizedValue(value: any){
    return value?.normalize('NFD')
    .replaceAll('RD$', "") // Elimina signo de peso dominicano
    .replace(/(\d)[,.]00\b/g, '$1') //Eliminar .00 || ,00  //ejemplo: 5,000.00 -> 5,000 - pero 5,000.000 -> 5,000.000 - pero 5.005 -> 5.005 //ejemplo: 5.000,00 -> 5.000 - pero 5.000,000 -> 5.000,000;
    .replace(/[\u0300-\u036f]/g, "") // Elimina acentos diacríticos
    .replace(/[\(\)\%\,\.\$]/g, "") // Elimina paréntesis, signo de porcentaje, comas, puntos, signo de dólar
    .toLocaleLowerCase();
  }

  public setParameterSearch(key) {
    this.parameterValue = this.propList.find(p => p.propkey === key);
    this.onChangeParameterSearch(null);
  }

  onChangeParameterSearch(parameterValue: { propKeyId?: number, propkey: string, displayName: string }) {
    this.keyValue = '';
    this.searchParameterValue = parameterValue;

    let filteredRecords = { records: this.records, enable: false, parameterValue: this.parameterValue }

    if (this.namePageLocalStorage) {
      localStorage.setItem(this.namePageLocalStorage, JSON.stringify({ ...this.parameterValue, value: this.keyValue }));
    }
    this.onKeyParameterChange.emit(parameterValue ?? null);
    this.onChange.emit(filteredRecords);
    if(this.parameterValue.propkey == "advancedsearch"){
      this.parameterValue = null;
    }
    this.emitText();

  }

  isNumeric(num) {
    return !isNaN(num)
  }

  onSearchChange(text:string){
    if(!text){
      return;
    }
    const newText = text?.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    this.selectComp.searchText = newText;
  }

}
