<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Novedades Aprobadas</h2>
    <button type="button" (click)="close()" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content">
    <div class="row">
        <div class="card-body mt-0 row justify-content-center">
            <div class="row">
                <div class="col-md-12">
                    <div class="bg-light-info rounded row h-100">
                        <div class="text-center col-md-3 center">
                            <div class="symbol symbol-150 d250 rd20 mr-3 my-5 center">
                                <img *ngIf="employeeImg" alt="Pic" [src]="employeeImg" />
                                <img alt="Pic" *ngIf="!employeeImg" src="../../../../assets/images/no-user.png" onerror="this.error=null; this.src='assets/images/no-user.png'" />
                            </div>
                        </div>
                        <div class="col-md-8">
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary ">Servidor Público: <span
                                    class="text-dark ml-3">{{employeeSelected?.name}}</span></label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary ">Identificación:
                                <span class="text-dark ml-3"
                                    *ngIf="employeeSelected?.typeIdentificationId === 1">{{employeeSelected?.personalIdentification
                                    | mask:'000-0000000-0'}}</span>
                                <span class="text-dark ml-3"
                                    *ngIf="employeeSelected?.typeIdentificationId !== 1">{{employeeSelected?.personalIdentification}}</span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary ">Teléfono:
                                <span class="text-dark ml-3">{{employeeSelected?.phoneNumber |
                                    mask:'(000)-000-0000'}}</span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary ">Correo Institucional: <span
                                    class="text-dark ml-3">{{employeeSelected?.institionalEmail}}</span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Supervisor Inmediato: <span
                                    class="text-dark ml-3">{{employeeSelected?.employeeSupervisor}}</span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Supervisor de Departamento: <span
                                    class="text-dark ml-3">{{employeeSelected?.supervisor}}</span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Fecha de Ingreso:
                                <span class="text-dark ml-3">{{employeeSelected?.admissionDate|date:"dd/MM/yyyy"}}</span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Salario Bruto: <span
                                    class="text-dark ml-3">{{employeeSelected?.salary | currency:'RD$'}}
                                </span>
                            </label>
                            <label class="d-flex font-weight-bolder my-2 p-2 text-primary">Unidad Organizativa: <span
                                    class="text-dark ml-3 h6">{{employeeSelected?.departmentName}}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body mt-3">
            <div class="table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-left">Tipo</th>
                            <th class="text-left">Concepto</th>
                            <th class="text-left">Monto</th>
                            <th class="text-left">Fecha Inicio</th>
                            <th class="text-left">Fecha Final</th>
                            <th class="text-left">Estatus</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of paginatedRecords">
                            <td class="text-left">
                                {{item.newsType}}
                            </td>
                            <td class="text-left">{{item.newsConcept}}</td>
                            <td class="text-left">{{item.amount | currency: 'RD$'}}</td>
                            <td class="text-left">{{item.startDate | date: 'dd/MM/yyyy'}}</td>
                            <td class="text-left">{{item.endDate | date: 'dd/MM/yyyy'}}</td>
                            <td>
                                <span *ngIf="item.status" class="font-weight-bold text-success">Activa</span>
                                <span *ngIf="!item.status" class="font-weight-bold text-danger">Inactiva</span>
                            </td>
                        </tr>
                        <tr *ngIf="records.length == 0">
                            <td colspan="10" class="text-center">
                                No se encontraron novedades
                            </td>                     
                        </tr>
                    </tbody>
                </table>
                <div class="">
                    <el-caption #paginator [originalRecordsLength]="records.length"
                        [listedRecordsLength]="paginatedRecords.length"
                        [registerQuantity]="records.length"
                        [records]="records"
                        (onChage)="getPaginatedRecords($event)"></el-caption>
                </div>
            </div>
        </div>
    </div>
 
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>