import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixedDecimal'
})
export class FixedDecimalPipe implements PipeTransform {

  transform(value: number, fixedTo: number): string {

    console.log(value.toFixed(fixedTo))

    return value.toFixed(fixedTo);
  }

}
