import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
import { ToastService } from 'src/app/shared/toast/toast.service';
import * as moment from 'moment';

interface DashboardModel {
  description: string;
  description2: string;
  value: number;
  value2: number;
  value3: number;
}

@Component({
  selector: 'app-dashboard-news-list',
  templateUrl: './dashboard-news-list.component.html',
  styleUrls: ['./dashboard-news-list.component.css']
})
export class DashboardNewsListComponent implements OnInit {
  datePipe = new DatePipe('en-Us')
  currencyPipe = new CurrencyPipe('en-Us')
  filtered = false;
  filterParams: {year, date1, date2, companyId}
  defaultFilter = {
    year: null,
    date1: this.datePipe.transform(`${this.authInfo.getConstitutionDate()}`, 'yyyy-MM-dd'),
    date2: moment().endOf('year').format('yyyy-MM-DD'),
    companyId: this.authInfo.getCompanyId()
  }

  payrollNewsAmountbyNewsType: DashboardModel[] = [];
  payrollNewsByConcept: DashboardModel[] = [];
  payrollNewsByType: DashboardModel[] = [];
  payrollNewsBySource: DashboardModel[] = [];
  payrollNewsByStatus: DashboardModel[] = [];
  payrollNewsByConceptListOriginal: DashboardModel[] = [];
  payrollNewsByCondition: DashboardModel[] = [];
  total: number = 0;

  constructor(
    public dialogRef: MatDialogRef<DashboardNewsListComponent>,
    private dialog: MatDialog,
    private payrollNewService: PayrollNewService,
    private _toastService: ToastService,
    private authInfo: AuthInfoService
  ) {
    this.dialogRef.disableClose = true;

  }

  ngOnInit(): void {
    this.filterParams = {...this.defaultFilter}
    this.getDashboard();
  }

  getDashboard() {
    this.payrollNewService.getDashboard(this.filterParams.date1, this.filterParams.date2, this.filterParams.companyId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      //Novedades por Monto
      const amountbyNewsTyped = res.singleData.getPayrollNewsAmountbyNewsType as DashboardModel[];
      this.payrollNewsAmountbyNewsType = amountbyNewsTyped.map(a => {
        let textAmount = this.currencyPipe.transform(a.value3, 'RD$')
        const amount = {
          ...a,
          value: a.value3,
          description: `${a.description} ( ${textAmount})`
        }
        return amount;
      })

      //Novedades por Conceptos
      const concepts = res.singleData.getPayrollNewsByConcept as DashboardModel[];
      this.payrollNewsByConceptListOriginal = [...concepts];
      this.payrollNewsByConcept = [...concepts.slice(0, 4)];

      //Novedades por Tipos
      this.payrollNewsByType = res.singleData.getPayrollNewsByType;
      this.total = this.payrollNewsByType.
        reduce((previousValue, currentValue) => {
          return previousValue + currentValue.value
        }, 0);

      //Novedades por Fuente
      this.payrollNewsBySource = res.singleData.getPayrollNewsBySource;

      //Novedades por Estatus
      this.payrollNewsByStatus = res.singleData.getPayrollNewsByStatus;
      this.payrollNewsByCondition = res.singleData.getPayrollNewsByCondition;
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    });
  }

  filterByDate(): void {
    this.dialog.open(FilterDashboardComponent, {
      data: { filterParams: this.filterParams }
    })
      .afterClosed()
      .subscribe((result) => {
        this.filtered = result.year != null || result.date1 != null || result.date2 != null;

        if (!this.filtered) {
          return;
        }

        this.filterParams.year = result.year
        if (result.date1) {
          this.filterParams.date1 = result.date1
        }

        if (result.date2) {
          this.filterParams.date2 = result.date2
        }

        if(result.date2 < result.date1){
          this._toastService.warning('La fecha hasta no puede ser menor a la fecha desde');
        }

        this.filterParams.companyId = result.companyId

        this.getDashboard();
      });
  }

  clearFilter(): void {

    if (!this.filtered) {
      return;
    }
    this.filterParams = {...this.defaultFilter}
    this.getDashboard();
    this.filtered = false;
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey,
      limitTo: 4
    }
  }


}
