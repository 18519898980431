import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeesService } from 'src/app/services/employees.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-incompleted-files',
  templateUrl: './incompleted-files.component.html',
  styleUrls: ['./incompleted-files.component.css']
})
export class IncompletedFilesComponent implements OnInit, AfterViewChecked {

  incompletedFilesList: any[] = []

  constructor(private employeeService: EmployeesService,
    private _toastService: ToastService, public dialogRef: MatDialogRef<IncompletedFilesComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: {employeeId,type,incompleteInformations},
    private _cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    if(this.data.type == 1){
      //employees
      this.getIncompletedFiles(this.data.employeeId,0)
    }else{
      //teachers
      this.incompletedFilesList = this.data?.incompleteInformations;
      //this.getIncompletedFiles(this.data.employeeId,1)
    }
    
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }


  getIncompletedFiles(employeeId: number,type) {
    this.employeeService.getIncompletedFilesByEmployee(employeeId,type).subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          res.errors.forEach(element => {
            this._toastService.error(element);
          });
          return;
        }

        this.incompletedFilesList = res.dataList;
      },
      error: (err) => {
        this._toastService.error(err);
      }
    })
  }

}
