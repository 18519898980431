<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
<h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
    Carga Masiva
</h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>

</div>
<div class="mat-dialog-content-custom">
    <div class="card-body"> 
        <div class="row">
            

            

            
            <div class="form-group col-md-3">
                <label class="text-primary"> 
                    Estatus Servidor Público <span class="text-danger">*</span>
                </label>
                <ngx-select-dropdown [(ngModel)]="employeeStatus" [options]="statusList"
                        [config]="statusSetting" class="w-100"></ngx-select-dropdown>
            </div>

            <div class="form-group col-md-3" [formGroup]="form">
                <label class="d-block">Cargar Documento<span class="text-danger text-monospace legend">*</span></label>
                <div class="dropzone dropzone-default dz-clickable" id="kt_dropzone_1" [ngClass]="{'form-control-file-custom': !isFormValid}">
                    <div class="dropzone-msg dz-message needsclick">
                        <input (click)="onClick()" formControlName="file" (change)="fileUpload($event)"
                            class="form-control form-control-sm" [ngClass]="{'form-control-file-custom': !disabledFields}"
                            type="file" id="file-upload" name="fileUpload" accept=".xls,.xlsx">
                    </div>
                </div>
            </div>
            

            
            
            <div class="form-group col-md-3  align-self-end">
                <button (click)="export()" class="btn btn-sm btn-light">
                    <i class="flaticon-excel icon-2x text-success text-center"></i>
                    </button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left">Descripción</th>
                                <th class="text-left">Comentario</th>
                                <th class="text-left">Cantidad de Errores</th>
                                <th class="text-left">¿Es requerido?</th>
                                <th class="actionsBtn">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of paginatedDataUploaded">
                                <td class="text-left">{{ item?.description }}</td>
                                <td class="text-left">{{ item?.comment }}</td>
                                <td class="text-left">{{ item?.quantity }}</td>
                                <td class="text-left">{{ item?.isRequiredText}}</td>
                                
                                <td class="actionsBtn">
                                    <button class="btn btn-icon btn-light-info btn-sm m-1"
                                        (click)="openDetail(item)">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <el-caption #paginator [originalRecordsLength]="dataUploadedListOriginal?.length"
                            [listedRecordsLength]="paginatedDataUploaded?.length"
                            [registerQuantity]="filteredList?.enable && filteredList.records?.length>0 ? filteredList.records?.length :dataUploaded?.length"
                            [records]="filteredList?.enable ? filteredList?.records : dataUploaded"
                            (onChage)="getPaginatedRecords($event)"></el-caption>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cancelar
    </button>
    <button *ngIf="ProcessButton"
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button" (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Procesar</span>
    </button>
</div>