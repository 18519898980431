<div *ngIf="isTableLoading"
  class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
  <el-spinner animation="spinner-logo"></el-spinner>
  <h3 class="text-primary">Cargando PDF...</h3>
</div>
<div class="container-fluid py-3">
  <button (click)="goBack()" class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
    <i class="flaticon2-left-arrow-1"></i>
    <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
  </button>
</div>
<div class="container-fluid" *ngIf="!isTableLoading">
  <div id="pdfExp" class="card">
    <div class="card-header px-22">
      <div class="align-items-center d-flex flex-wrap justify-content-lg-between justify-content-md-center" id="header">
        <div class="logo px-2">
          <img [src]="file.logo" alt="" class="max-w-200px" />
        </div>

        <div *ngIf="fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">
            {{fileModalConfig.formTitle}}
          </h1>
          <h2 class="text-danger">{{fileModalConfig.formSubTitle}}</h2>
        </div>
        <div *ngIf="!fileModalConfig.formSubTitle"
          class="title p-2 text-center">
          <div class="fs-2 font-weight-bolder">

            <span class="text-mute">{{company?.companyName}}</span>
          </div>
          <h2 class="text-danger">Descripción de Cargo</h2>
        </div>
        <div class="version p-4 d-flex justify-content-between align-items-center flex-column">

          <div class="col-auto font-weight-bolder fs-5 mr-0 pr-0">
            <label class="mr-0 d-block">
              Fecha:</label>
            <span class="text-wrap fecha text-danger">{{getCurrentDate | date: 'dd/MM/yyyy'}}</span>
          </div>
          <!-- <h4 class="text-right ">FO-RH-14</h4>
           <h4 class="text-right ">Versión 0</h4> -->
        </div>
      </div>
    </div>
    <div class="card-body pdf-break-text">
      <div id="pdf">
        <div class="container-fluid" id="personal-data">
          <h3 style="background: #e3292e !important;" class="p-2 bg-danger text-white mt-3 text-center">DESCRIPCIÓN DEL CARGO</h3>

                <div class="ml-3 mb-3">
                  <label class="text-primary fw-bold fs-5 mb-0">Título: </label>
                  <span class="fs-5 fw-semibold"> {{ position?.positionName }}</span>
                </div>

                <div class="ml-3 mb-3">
                  <label class="text-primary fw-bold fs-5 d-block mb-0">Propósito del cargo:</label>
                  <span class="fs-5 fw-semibold">{{ position?.purpose }}</span>
                </div>
                <div class="ml-3 mb-3">
                  <label class="text-primary fw-bold fs-5 d-block mb-0">Actividades esenciales:</label>
                  <span class="fs-5 fw-semibold text-wrap">{{ position?.activity || 'N/A' }}</span>
                </div>
                <div class="ml-3 mb-3">
                  <label class="text-primary fw-bold fs-5 d-block mb-0">Educación formal:</label>
                  <span class="fs-5 fw-semibold">{{ position?.academicLevel || 'N/A' }}</span>
                </div>
                <div class="ml-3 mb-3">
                  <label class="text-primary fw-bold fs-5 d-block mb-0">Experiencia (Años):</label>
                  <span class="fs-5 fw-semibold">{{ position?.experience || "N/A" }}</span>
                </div>
                <div class="ml-3 mb-3">
                  <label class="text-primary fw-bold fs-5 d-block mb-0">Periodo probatorio:</label>
                  <span class="fs-5 fw-semibold">{{ getProbationaryPeriodName || "N/A" }}</span>
                </div>
                <div class="ml-3 d-flex">
                  <div class=" mr-5">
                    <label class="item-label-2 fw-bold text-primary">Competencias:</label>
                    <ul>
                      <li *ngFor="let item of dictionary">{{item?.competencyName}}</li>
                    </ul>
                  </div>
                  <div class="ml-5">
                    <label class="item-label-2 fw-bold text-primary">Grado Requerido:</label>
                    <span class="d-block text-center" *ngFor="let item of dictionary">
                      {{item?.gradeId}}
                    </span>

                  </div>
              </div>
            </div>
        </div>
        <div class="card-footer mt-4">

          <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
            <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-80px">
            <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
          </div>
        </div>
      </div>
    </div>
    <button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
      <i class="flaticon2-printer font-size-h1"></i>
    </button>
  </div>
<script>
  let print = () => {
    let pdf = document.getElementById("pdfExp");
    pdf.contentWindow.focus();
    pdf.contentWindow.print();
  };
</script>
