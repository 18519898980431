<!-- <div *ngIf="isLoading; else loaded"
    class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
    <el-spinner (loadingStatus)="isLoading=$event" animation="spinner-logo">
    </el-spinner>
</div> -->

<ng-container>
  <div class="card card-custom">
    <div class="card-header mt-3">
      <div class="card-title">
        <div class="ttl d-flex align-items-center">
          <i class="flaticon-calendario icon-3x text-danger mr-2"></i>
          <h1 class="text-primary">Registro de Novedades </h1>
        </div>
      </div>
      <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard" data-toggle="tooltip"
        data-original-title="Dashboard">
        <button data-toggle="modal" *ngIf="privilege.dashboardAction.value"
          class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto" (click)="openDashBoard()">
          <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
        </button>
      </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
      <div class="col-sm-12 col-md-7 c-left">
        <div class="card-toolbar d-flex justify-content-start">

          <div class="col-md-4 mr-2 px-0 pt-1">
            <div class="form-group mx-2 institution">
              <label class="text-primary">
                Estatus
              </label>
              <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
            </div>
          </div>
          <div class="col-md-3 mr-2 px-0 pt-1">
            <div class="form-group mx-2 institution">
              <label class="text-primary">
                Tipo de Novedad
              </label>
              <ngx-select-dropdown (change)="filterByNewType($event.value)" [options]="newTypeOptions"
                [config]="dropdownConfig('description')" class="w-100"></ngx-select-dropdown>
            </div>
          </div>
          <button data-toggle="modal" *ngIf="privilege.addAction.value"
            class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto" (click)="openModalAddNew(null)">
            <i class="flaticon-mas icon-2x" data-toggle="tooltip" data-original-title="Agregar nueva novedad"></i>
            Agregar
          </button>
          <button data-toggle="modal" (click)="openModalBulkloadNews()" *ngIf="privilege.uploadAction.value"
            class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto">
            <i class="flaticon-recursos-humanos-6 icon-2x"></i> Carga Masiva
          </button>
        </div>
      </div>

      <div class="col-sm-12 col-md-5 c-right mr-0">
        <div class="card-toolbar d-flex justify-content-end">
          <div class="dropdown col-md-auto col-sm-12">
            <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
              *ngIf="privilege.exportAction.value" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                data-original-title="Opciones de exportar"></i> Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

              <a class="dropdown-item align-items-center"
                href="assets/docs/plantilla-carga-masiva/Plantilla_Carga_Masiva_Novedades.xlsx"
                download="Plantilla Carga Masiva.xlsx">
                <i class="flaticon-excel icon-2x text-success mr-3"></i>
                Plantilla Carga Masiva
              </a>
              <a class="dropdown-item align-items-center"
                href="assets/docs/plantilla-carga-masiva/Plantilla_Carga_Masiva_Novedades_Amortizables.xlsx"
                download="Plantilla Carga Masiva Novedades Amortizables.xlsx">
                <i class="flaticon-excel icon-2x text-success mr-3"></i>
                Plantilla Carga Masiva Novedades Amortizables
              </a>
              <a class="dropdown-item align-items-center" (click)="exportPdf()">
                <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                Visualizar Reporte
              </a>
            </div>
          </div>

          <div class="col text-nowrap">
            <!-- <el-search-bar #searchBar [records]="payrollNewsListOriginal"
                            (onKeyChange)="onKeyChange($event)" [keyValue]="filterText"
                            [itemForSearching]="searchBarDisplayNames" (onChange)="getFilteredList($event)" [defaultParameterValue]="searchBarDisplayNames[1]"
                            ></el-search-bar> -->
            <app-search-bar-pagination #searchBar (onFilter)="filterSearch($event)"
              (onChangeDropDown)="filterSearch($event)" [parameters]="searchBarDisplayNames">
            </app-search-bar-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body">

      <div class="py-5" style="margin-left: 0.4rem;" *ngIf="isShowSelectItems">
        <input type="checkbox" (change)="selectAllItems()" [(ngModel)]="allItemsSelected" class="check-size me-3">
        <label class="h6">Marcar Todas</label>
        <button *ngIf="getCountNewsNoApprove > 0 && privilege?.approveAction?.value" (click)="approveSelection()"
          class="btn btn-success btn-pill font-weight-bolder mx-2 col-md-auto">
          <i class="flaticon-checked icon-1x"></i>
          Aprobar Selección
        </button>
        <button *ngIf="getCountNewsToDisapprove > 0 && privilege?.disapprove?.value" (click)="disapproveModal()"
          class="btn btn-danger btn-pill font-weight-bolder mx-2 col-md-auto">
          <i class="flaticon-mala-critica text-white icon-1x"></i>
          Desaprobar Selección
        </button>
        <button *ngIf="getCountNewsToRemove > 0 && privilege?.deleteAction?.value" (click)="removeModal()"
          class="btn btn-danger btn-pill font-weight-bolder mx-2 col-md-auto">
          <i class="flaticon-delete text-white icon-1x"></i>
          Eliminar Selección
        </button>
      </div>

      <div class="table-responsive table-responsive-sigei">
        <table class="table table-vertical-center">
          <thead>
            <tr class="bg-primary">
              <th class="text-left">

              </th>
              <th class="text-center">Secuencia</th>
              <th class="text-left">Documento de Identidad</th>
              <th class="text-left">Servidor Público</th>
              <th class="text-left">Cargo</th>
              <th class="text-left">Tipo</th>
              <th class="text-left">Concepto</th>
              <th class="text-right">Monto</th>
              <th class="text-right">Valor Manual</th>
              <th class="text-left">Valor Predeterminado</th>
              <th class="text-left">Fecha Inicio</th>
              <th class="text-left">Fecha Final</th>

              <th class="text-left">Condición de la Novedad</th>
              <th class="text-left">Registrado Por</th>
              <th class="text-left">Fecha Creación</th>
              <th class="text-center">Estatus</th>
              <th class="text-center" style="width: 100px;">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let payrollNew of payrollNews">
              <td class="text-center">
                <input *ngIf="payrollNew.status && hasPermission" (change)="selectItem(payrollNew)"
                  [(ngModel)]="payrollNew.isSelected" type="checkbox" name="{{payrollNew.payrollNewsId}}"
                  class="check-size">
              </td>
              <td class="text-center">{{payrollNew.payrollNewsId}}</td>
              <td class="text-left" *ngIf="payrollNew.typeIdentificationId == 1">
                {{payrollNew.personalIdentification | mask: '000-0000000-0'}}
              </td>
              <td class="text-left" *ngIf="payrollNew.typeIdentificationId != 1">
                {{payrollNew.personalIdentification}}
              </td>
              <td class="text-left">
                {{payrollNew.firstName}} {{payrollNew.lastName}}
              </td>
              <td class="text-left">{{payrollNew.positionName}}</td>
              <td class="text-left">{{payrollNew.newsType}}</td>
              <td class="text-left">{{payrollNew.newsConcept}}</td>
              <td class="text-right">{{payrollNew.amount | currency: '$'}}</td>
              <td class="text-right">{{payrollNew.manualValue | currency: '$'}}</td>
              <td class="text-left">{{payrollNew.defaultValueDescription }}</td>
              <td class="text-left">{{payrollNew.startDate| date:"dd/MM/yyyy"}}</td>
              <td class="text-left">{{payrollNew.endDate | date:"dd/MM/yyyy"}}</td>
              <td class="text-left">
                <span *ngIf="payrollNew.condition === 1" class="font-weight-bold text-success">Aprobada</span>
                <span *ngIf="payrollNew.condition === 0" class="font-weight-bold text-danger">
                  No Aprobada
                </span>
              </td>
              <td class="text-left">{{payrollNew.userFullName}}</td>
              <td class="text-left">{{payrollNew.createDate | date: 'short'}}</td>

              <td>
                <span *ngIf="payrollNew.status" class="font-weight-bold text-success">Activa</span>
                <span *ngIf="!payrollNew.status" class="font-weight-bold text-danger">Inactiva</span>
              </td>
              <td class=" px-5">
                <div class="d-flex justify-content-center px-5" style="width: max-content">
                  <div class="justify-content-start  px-5">
                    <button class="btn btn-icon btn-light-info btn-sm m-1" *ngIf="privilege?.viewAction?.value"
                      (click)="openModalAddNew(payrollNew, true, false)">
                      <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver"></i>
                    </button>
                    <a *ngIf="payrollNew.status && payrollNew.condition === 0 && privilege?.editAction?.value"
                      data-toggle="tooltip" data-original-title="Editar"
                      (click)="openModalAddNew(payrollNew, false, true)"
                      class="btn btn-icon btn-light-warning btn-sm m-1">
                      <i class="flaticon-edit"></i>
                    </a>
                    <a *ngIf="payrollNew.status && payrollNew.condition === 0 && privilege?.editAction?.value"
                      (click)="disapprovalreason(payrollNew.reasonDisapproved)" tooltip="Razón de Desaprobación"
                      placement="top" data-toggle="modal" class="btn btn-sm btn-light-success btn-icon mr-2">
                      <i class="flaticon-despacho-de-aduana icon-2x" data-toggle="tooltip"
                        data-title="Razón de Desaprobación"></i>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <app-paginator [listedRecordsLength]="payrollNews?.length"
            [registerQuantity]="payrollNewPagedResult.totalItems" [registerPerPage]="payrollNewPagedResult.pageSize"
            [totalPages]="payrollNewPagedResult.totalPages" [selectedPage]="payrollNewPagedResult.page"
            (onChange)="getPaginatedRecords($event)">
          </app-paginator>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<export-data-to-pdf [title]="'Registro de Novedades'" id="dataToPrint">
  <ng-container thead>
    <th class="text-left">Documento Identificación</th>
    <th class="text-left">Servidor Público</th>
    <th class="text-left">Cargo</th>
    <th class="text-left">Tipo</th>
    <th class="text-left">Concepto</th>
    <th class="text-left">Monto</th>
    <th class="text-left">Valor Manual</th>
    <th class="text-left">Valor Predeterminado</th>
    <th class="text-left">Fecha Inicio</th>
    <th class="text-left">Fecha Final</th>
    <th class="text-left">Condición de la Novedad</th>
    <th class="text-center">Estatus</th>
  </ng-container>
  <ng-container tbody>
    <tr *ngFor="let payrollNew of payrollNews" data-row="0" class="datatable-row" style="left: 0px;">
      <td class="text-left" *ngIf="payrollNew.typeIdentificationId == 1">
        {{payrollNew.personalIdentification | mask: '000-0000000-0'}}
      </td>
      <td class="text-left" *ngIf="payrollNew.typeIdentificationId != 1">
        {{payrollNew.personalIdentification}}
      </td>
      <td class="text-left">
        {{payrollNew.firstName}} {{payrollNew.lastName}}
      </td>
      <td class="text-left">{{payrollNew.positionName}}</td>
      <td class="text-left">{{payrollNew.newsType}}</td>
      <td class="text-left">{{payrollNew.newsConcept}}</td>
      <td class="text-left">{{payrollNew.amount | currency: 'RD$'}}</td>
      <td class="text-left">{{payrollNew.manualValue | currency: 'RD$'}}</td>
      <td class="text-left">{{payrollNew.defaultValueDescription }}</td>
      <td class="text-left">{{payrollNew.startDate| date:"dd/MM/yyyy"}}</td>
      <td class="text-left">{{payrollNew.endDate | date:"dd/MM/yyyy"}}</td>
      <td class="text-left">
        <span *ngIf="payrollNew.condition === 1" class="font-weight-bold text-success">Aprobada</span>
        <span *ngIf="payrollNew.condition === 0" class="font-weight-bold text-danger">
          No Aprobada
        </span>
      </td>
      <td>
        <span *ngIf="payrollNew.status" class="font-weight-bold text-success">Activa</span>
        <span *ngIf="!payrollNew.status" class="font-weight-bold text-danger">Inactiva</span>
      </td>
    </tr>
  </ng-container>
</export-data-to-pdf>