import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InterviewEvaluationService } from 'src/app/services/interview-evaluation.service';
import { Location } from '@angular/common';
import { docService } from 'src/app/services/doc.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';

interface IInterview {
  postulationInterviewId: number;
  postulationId: number;
  dateInterview: Date;
  checkIn: Date;
  unemployed: boolean;
  reasonsChange: string;
  generalRemarks: string;
  resultsId: number;
  physicalAppearance: boolean;
  eyeContact: boolean;
  selfConfidence: boolean;
  verbalFluency: boolean;
  puntuality: boolean;
  workExperience: boolean;
  supervisionExperience: boolean;
  leadership: boolean;
  sociability: boolean;
  achievementOrientation: boolean;
  communication: boolean;
  multitask: boolean;
  learning: boolean;
  initiative: boolean;
  laborStability: boolean;
  professionalDevelopment: boolean;
  analysisCapacity: boolean;
  languageAbility: boolean;
  creativityAndInnovation: boolean;
  criticalThinking: boolean;
  structuredThinking: boolean;
  systematicThinking: boolean;
  resilience: boolean;
  proposedPosition: number;
  proposedSalary: number;
  proposedSchedule: number;
  dateAvailability: Date;
  status: boolean;
  companyId: number;
  result: string;
  vacant: string;
  positionId: number;
  position: string;
  fullName: string;
  personId: number;
  departmentName: string;
  departmentId: number;
  postulationDate: Date;
  interviews: number;
}

@Component({
  selector: 'app-summary-interview',
  templateUrl: './summary-interview.component.html',
  styleUrls: ['./summary-interview.component.css']
})
export class SummaryInterviewComponent implements OnInit {

  isLoading: Boolean;
  interview: IInterview;

  public company: any;
  file = { logo: '', image1: '', image2:'' }

  constructor(
    private interviewsService: InterviewEvaluationService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService, 
    private location: Location,
    private srvDoc: docService,
    private _companiesService: CompaniesService,
    private authInfo: AuthInfoService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params:any) => {
      this.interviewsService.getInterviewById(params.id).subscribe((res:any) => {
        if(res.errors.length > 0){this.toastr.error("Error al obtener los datos de esta entrevista");return;}
        this.interview = res.dataList[0];
      }, error => {
        this.toastr.error("Error al obtener los datos de esta entrevista")
      })
    })
    this.companyById(this.authInfo.getCompanyId())
  }

  goBack(){
    this.location.back();
  }

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

}
