export enum VacationDaysAllowed{
    HalfYear = 6,
    Year = 15,
    FiveYears = 18
}

export const vacancyCalculations: {yearsWorked:number[], days:number}[] = [
    {yearsWorked: [0, 4], days: 15},
    {yearsWorked: [5, 9], days: 20},
    {yearsWorked: [10, 14], days: 25},
    {yearsWorked: [15, 90000000], days: 30},
  ]