import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';


@Component({
  selector: 'app-additional-payroll-delete-news-modal',
  templateUrl: './additional-payroll-delete-news-modal.component.html',
  styleUrls: ['./additional-payroll-delete-news-modal.component.css']
})
export class AdditionalPayrollDeleteNewsModalComponent implements OnInit {

  form: FormGroup;
  formIsInvalid = false

  constructor(private _toastService: ToastrService,
    public dialogRef: MatDialogRef<AdditionalPayrollDeleteNewsModalComponent>,
    private _changeDet: ChangeDetectorRef, private dialog: MatDialog,
    private parameterControlService: ParameterControlService,
    private fb: FormBuilder,
    private payrollNewService: PayrollNewService,
    private srvAuth: AuthInfoService,
    @Inject(MAT_DIALOG_DATA) public data: any,) { 
      this.buildForm();
    }

  ngOnInit(): void {
  }
  
  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  buildForm(){
    this.form = this.fb.group({
      reason: ['', [Validators.required]]
    });
  }

  Cancel(): void {
    const row = { success: false }
    this.dialogRef.close(row);
  }

  Accept(){
    if(this.form.invalid){
      this.formIsInvalid = true;
      return;
    }
        this.deletePayrollNew(this.data.item.payrollNewsId);
      
  }

  deletePayrollNew(id){
    const model = {
        PayrollNewsId: id, 
        Reason: this.form.value.reason,
        userId: this.srvAuth.getUserId()
    }
    
    this.payrollNewService.deletePayrollNew(model).subscribe(res => {
      if (res.errors.length > 0) {
        this._toastService.error("Error al eliminar la novedad")
        return;
      }
      this._toastService.success("La novedad se eliminó con éxito")
      const row = { success: true, newItem: true }
      this.dialogRef.close(row);
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    })
  }

  get showErrors(){
    return {
      erorReason: this.form.get('reason').invalid,
    }
  }

}
