import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Period } from '@payroll/schedule/models/period.model';
import { docService } from 'src/app/services/doc.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollReportService } from 'src/app/services/payroll-report.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { OverallSummary } from '../models/overallSummary.model';
import * as XLSX from 'xlsx';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { Location } from '@angular/common';
import { PayrollService } from 'src/app/services/payroll.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { dropdownGlobalConfig, openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payroll-audit-report',
  templateUrl: './payroll-audit-report.component.html',
  styleUrls: ['./payroll-audit-report.component.css']
})
export class PayrollAuditReportComponent implements OnInit {
  periods: Period[] = [];
  filteredPeriods: Period[] = [];
  yearsList: any[] = []
  payrollTypes: any[] = [];
  form: FormGroup;
  budgetAreaList: any[] = [];
  payrollVersions: any[] = [];
  selectedPayrollType: any;
  relatedPeriods = [];

  dropdownConfigDescription = { ...dropdownGlobalConfig('description') };
  dropdownConfigStringData = { ...dropdownGlobalConfig('stringData') };
  dropdownConfigVersion = { ...dropdownGlobalConfig('versionDescription') }

  constructor(
    private parameterServices: ParameterControlService,
    private payrollReportService: PayrollReportService,
    private _toastService: ToastService,
    private fb: FormBuilder,
    public authInfo: AuthInfoService,
    private location: Location,
    private payrollService: PayrollService,
  ) {

    this.buildForm();
  }

  ngOnInit(): void {
    this.getPayrollTypes();
    this.getPeriods();
  }

  changePayrollType(payrollType: ParameterControl) {
    if (payrollType?.ocode === 4) {
      this.addValidation('relatedPeriod', [Validators.required]);
      this.getBeforePeriod();
      return;
    }
    this.relatedPeriods = [];
    this.addValidation('relatedPeriod', null);
    this.getPayrollVersions();
  }

  private addValidation(controlName: string, validators) {
    this.form.get(controlName).setValidators(validators);
    this.form.get(controlName).updateValueAndValidity();
  }

  getBeforePeriod() {
    this.payrollService.getBeforePeriod().subscribe(res => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.relatedPeriods = res.dataList;
      this.getPayrollVersions();
    });
  }

  goBack() {
    this.location.back();
  }

  buildForm() {
    this.form = this.fb.group({
      payrollType: [null, [Validators.required]],
      period: [null, [Validators.required]],
      relatedPeriod: [null],
      area: [null, [Validators.required]],
      version: [null, [Validators.required]]
    })
  }

  getPayrollTypes() {
    this.parameterServices.getParameters(keyWord.PayrollType).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.payrollTypes = res.dataList;
    }, error => {
      this._toastService.error('Ocurrió un error inesperado tratando de obtener los tipos de nómina', '');
    });
  }

  getPayrollVersions() {
    const formValue = this.form?.value;
    const payrollPeriodId = formValue?.period?.payrollPeriodId || 0;
    const payrollTypeId = formValue?.payrollType?.ocode;
    const budgetAreaId = formValue?.area?.budgetAreaId || 0;
    this.payrollVersions = [];
    this.form.get('version').setValue(null);
    if (payrollPeriodId === 0 || budgetAreaId === 0 || (payrollTypeId === null || payrollTypeId === undefined)) {
      return;
    }
    this.payrollService.getPayrollVersions(payrollPeriodId, payrollTypeId, budgetAreaId).subscribe((res: any) => {
      if (res?.errors?.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.payrollVersions = res?.dataList?.map(v => {
        return {
          ...v,
          versionDescription: `${v?.headerId} - ${v?.versionId} - ${v?.headerCondition}`
        }
      });
    }, error => {
      this._toastService.error("Ha ocurrido un error al obtener las versiones del tipo de nómina seleccionada");
    })
  }

  getPeriods() {
    this.payrollService.getBudgetAreas().subscribe((res: any) => {
      this.budgetAreaList = res.dataList.filter(ba => ba.status == true)

    })
    this.payrollReportService.getPeriods().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error("Ha ocurrido un error al obtener los períodos");
        return;
      }
      this.periods = res.dataList.filter(e => e.status);
      this.filteredPeriods = this.periods
      let periodYears: any[] = []
      let years = this.periods.map((period: any) => {
        let periodYear = new Date(`${period.startDate}`).getFullYear()
        if (!periodYears.includes(periodYear)) {
          periodYears.push(periodYear)
        }
        return (periodYears)
      })
      this.yearsList = years[0].reverse()
    }, err => { this._toastService.error("Ha ocurrido un error al obtener los períodos");})
  }

  changeYear(event) {
    this.form.get('period').setValue(null);
    this.filteredPeriods = this.periods?.filter((period: any) => period?.description?.includes(`${event.value}`))
  }

  openReportAudit(){
    if (this.form?.invalid) {
      this._toastService.warning('Debe llenar todos los campos previamente');
      return;
    }
    const companyId = this.authInfo?.getCompanyId();
    let payrollPeriodId: number = this.form?.get('period')?.value?.payrollPeriodId;
    let payrollTypeId: number = this.form?.get('payrollType')?.value?.ocode;
    let areaId: number = this.form?.get('area')?.value?.budgetAreaId;
    let versionId: number = this.form?.get('version')?.value?.versionId
    let periodRelated: number = this.form?.get('relatedPeriod')?.value?.payrollPeriodId || 0;

    const url = `${environment.reportUrl}?ReportCode=payroll_audit&CompanyId=${companyId}&PeriodId=${payrollPeriodId}&PayrollTypeId=${payrollTypeId}&BudgetAreaId=${areaId}&VersionId=${versionId}&PayrollPeriodIdRelated=${periodRelated}`;
    openReport({
      url: url,
      title: 'Reporte de Auditoria de Nómina',
      width: '1024',
      height: '768'
    });
  }

}
