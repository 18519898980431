import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Inject, NgZone, PLATFORM_ID } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5radar from "@amcharts/amcharts5/radar";
import { any } from '@amcharts/amcharts5/.internal/core/util/Array';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-chrt-area-radar',
  templateUrl: './chrt-area-radar.component.html',
  styleUrls: ['./chrt-area-radar.component.css']
})
export class ChrtAreaRadarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: any = 'areachart2';
  root: any;
  @Input() data: any = [
    {
      sector: "Rectoria",
      size: 160.2,
    },
    {
      sector: "Finanzas",
      size: 150.1,
    },
    {
      sector: "RRHH",
      size: 120.7,
    },
    {
      sector: "Seguridad",
      size: 89.4,
    },
    {
      sector: "Compras",
      size: 78.5,
    },
    {
      sector: "Content Factory",
      size: 77.6,
    },
    {
      sector: "Comunicaciones",
      size: 69.8,
    },
    {
      sector: "Tecnología",
      size: 63.6,
    }
  ];
  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) {}
  ngOnDestroy(): void {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
        document.getElementById(this.id).remove()
      }
    });
  }


  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.data){
      this.buildChart()
    }
  }

  ngAfterViewInit() {
    this.browserOnly(() => {
      
      this.root = am5.Root.new(this.id);
      this.buildChart()
    })
  }
  buildChart(){
    if(!this.root) return
    let root = this.root
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        innerRadius: am5.percent(40),
        radius: am5.percent(70),
        arrangeTooltips: false
      })
    );
    
    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
    let cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
      behavior: "zoomX"
    }));
    
    cursor.lineY.set("visible", false);
    
    // Create axes and their renderers
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
    let xRenderer = am5radar.AxisRendererCircular.new(root, {
      minGridDistance: 30
    });
    xRenderer.labels.template.setAll({
      textType: "radial",
      radius: 10,
      paddingTop: 0,
      paddingBottom: 0,
      centerY: am5.p50,
      fontSize: "0.8em"
    });
    
    xRenderer.grid.template.setAll({
      location: 0.5,
      strokeDasharray: [2, 2]
    });
    
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "sector",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );
    
    let yRenderer = am5radar.AxisRendererRadial.new(root, {
      minGridDistance: 30
    });
    
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer
      })
    );
    
    yRenderer.grid.template.setAll({
      strokeDasharray: [2, 2]
    });
    
    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    let series1 = chart.series.push(
      am5radar.RadarLineSeries.new(root, {
        name: "Entrevistas",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "size",
        categoryXField: "sector"
      })
    );
    
    series1.strokes.template.setAll({
      strokeOpacity: 0
    });
    
    series1.fills.template.setAll({
      visible: true,
      fillOpacity: 0.5
    });
    
    /* let series2 = chart.series.push(
      am5radar.RadarLineSeries.new(root, {
        name: "Cash held in US",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "size",
        categoryXField: "sector",
        stacked: true,
        tooltip: am5.Tooltip.new(root, {
          labelText: "Outside: {size}\n"
        })
      })
    );
    
    series2.strokes.template.setAll({
      strokeOpacity: 0
    });
    
    series2.fills.template.setAll({
      visible: true,
      fillOpacity: 0.5
    }); */
    
    let legend = chart.radarContainer.children.push(
      am5.Legend.new(root, {
        width: 150,
        centerX: am5.p50,
        centerY: am5.p50
      })
    );
    legend.data.setAll([series1, /* series2 */]);
    
    // Set data
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Setting_data
    let data = this.data ? this.data : []
    
    series1.data.setAll(data);
    /* series2.data.setAll(data); */
    xAxis.data.setAll(data);
    
    // Animate chart and series in
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    series1.appear(1000);
    /* series2.appear(1000); */
    chart.appear(1000, 100);
    
    amCharts:any;
    
  }
  ngOnInit(): void {
  }

}
