<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class=" text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Evidencia</h2>
    <button (click)="close()" type="button" class="close" aria-label="Close">
      <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="form-group text-center my-5">
    <label>Evidencia <span class="text-danger">*</span></label>
        <div>
            <div *ngIf="!disabled" [ngClass]="{'d-inline-block': file.document=='', 'd-none' : file.document!='' }">
                <div class="evidence1" id="ExitEvidenceId" name="ExitEvidenceId"></div>
            </div>
            <div *ngIf="file.document !=''">
                <div class="symbol ">
                    <img alt="Pic" src="assets/images/cv.jpg" />
                </div>
                <div class="row justify-content-center">

                
                    <div class="col-md-3" role="group">
                        <a target="_blank" [href]="file.document" type="button" class="btn btn-primary btn-icon">
                            <i class="flaticon-ver"></i>
                        </a>
                        <button *ngIf="!disabled" type="button" class="btn btn-danger btn-icon">
                            <i class="flaticon-delete-1"></i>
                        </button>
                    </div>
                </div>
            </div>
       
    </div>
    <!-- <span style="position: absolute;" *ngIf="this.employeeExitForm.get('exitEvidence').invalid"
    class="text-danger">{{ showEvidenceErrors() }}</span> -->
    <button *ngIf="!disabled" (click)="confirm()" class="btn btn-primary fw-bold mt-3">Confirmar Evidencia</button>
</div>