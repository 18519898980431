<ng-container>
    <div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
        <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
            Rechazar Nómina
        </h2>
        <button (click)="dialogRef.close()" type="button" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>

    </div>
    <div class="mat-dialog-content">
        <div class="card rechazarn border-0 ">
            <form [formGroup]="form">
                <div class="card-header mx-3 p-3 mt-5">
                    <h6 class=" mb-4 d-block text-primary font-weight-bolder">Por favor introduzca el número <span
                            class="text-danger">{{randomNumber}}</span> de su
                        tarjeta de códigos.<span class="text-danger"> *</span></h6>
                    <input type="number" formControlName="code" class="form-control w-50 mb-5" placeholder="códigos">
                    <ng-container *ngIf=" formIsInvalid && form?.get('code')?.invalid">
                        <span class="text-danger" *ngIf="form?.get('code')?.hasError('required')">
                            Este campo es obligatorio
                        </span>
                        <span class="text-danger" *ngIf="form?.get('pattern')?.hasError('required')">
                            Este campo solo acepta números
                        </span>
                    </ng-container>
                </div>
                <div class="card-body p-0 mt-4">
                    <div class="card-header mx-3">

                        <div class="dropdown card p-3 gutter-b border-0   ">

                            <h6 class="mb-4 d-block text-primary  font-weight-bolder ">Motivo de rechazo<span
                                    class="text-danger"> *</span></h6>
                            <ngx-select-dropdown formControlName="reason" class="flex-1" [options]="reasons"
                                [config]="configDeclinePayroll">
                            </ngx-select-dropdown>
                            <ng-container *ngIf=" formIsInvalid && form?.get('reason')?.invalid">
                                <span class="text-danger" *ngIf="form?.get('reason')?.hasError('required')">
                                    Este campo es obligatorio
                                </span>
                            </ng-container>
                        </div>
                        <div class="mb-2  dropdown p-3 ">
                            <h6 class="mb-4 d-block text-primary font-weight-bolder">Comentario<span
                                    class="text-danger"> *</span></h6>
                            <textarea formControlName="comment" rows="5" class="form-control"></textarea>
                            <ng-container *ngIf="formIsInvalid && form?.get('comment')?.invalid">
                                <span class="text-danger" *ngIf="form?.get('comment')?.hasError('required')">
                                    Este campo es obligatorio
                                </span>
                                <span class="text-danger" *ngIf="form?.get('pattern')?.hasError('required')">
                                    Carácteres inválidos
                                </span>
                            </ng-container>

                        </div>
                        <div class="text-end pe-2">

                            <button class="btn btn-primary w-25 mb-5" (click)="upsertReason()"><span
                                    class="fs-custom">{{btnText}}</span></button>
                        </div>
                    </div>
                    <div class="dropdown p-3 text-center mt-3">
                        <table class="table atbl table-vertical-center">
                            <thead>
                                <tr class="bg-primary">
                                    <th class="text-left">Motivo de Rechazo</th>
                                    <th class="text-left text-wrap">Comentario</th>
                                    <th class="text-right">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of reasonsAdded; let i = index">
                                    <td class="text-left" style=" min-width: 150px;">{{item.reasonText}}</td>
                                    <td class="text-left">{{item.comment}}</td>
                                    <td class="text-right actionsBtn">

                                        <a data-placement="top" title="Editar" (click)="edit(i)" data-toggle="tooltip"
                                            class="btn btn-sm btn-icon btn-light-warning mr-2"><i
                                                class="flaticon-edit"></i></a>
                                        <a data-placement="top" title="Remover" (click)="removeReason(i)"
                                            data-toggle="tooltip" class="btn btn-sm btn-icon btn-light-danger"><i
                                                class="flaticon-delete"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- <div class="card-footer d-flex justify-content-end mt-3 p-0 pb-5">
                        <button data-dismiss="modal" (click)="dialogRef.close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-3 mt-2">

                            <span> Cancelar </span>
                        </button>
                        <button data-dismiss="modal"
                            class="btn btn-pill font-weight-bolder btn-shadow btn-success d-flex mr-3 mt-2">
                            <i class="flaticon-cheque"></i>
                            <span> Guardar </span>
                        </button>
                    </div> -->
                </div>
            </form>
        </div>
    </div>

    <div class="mat-dialog-actions" align="end">
        <button (click)="dialogRef.close()"
            class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2"
            type="button">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
        <button (click)="saveReject()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success"
            type="button">
            <i class="flaticon2-plus"></i>
            <span>Guardar</span>
        </button>
    </div>
</ng-container>