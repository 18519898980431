import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-declaration-modal',
  templateUrl: './declaration-modal.component.html',
  styles: [
  ]
})
export class DeclarationModalComponent implements OnInit {

  deviceForm = new FormGroup({
    deviceBrand: new FormControl('', Validators.required),
    deviceModel: new FormControl('', Validators.required),
    deviceAsset: new FormControl('', Validators.required),
    deviceSerial: new FormControl('', Validators.required),
    deviceDeliveredDate: new FormControl('', Validators.required),
  })

  constructor(public dialogRef: MatDialogRef<DeclarationModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this.data.isEditing) {
      this.loadDeviceInfo()
    }
  }

  loadDeviceInfo() {
    this.deviceForm.setValue({
      deviceBrand:this.data.formItem.value.deviceBrand,
      deviceModel:this.data.formItem.value.deviceModel,
      deviceAsset:this.data.formItem.value.deviceAsset,
      deviceSerial:this.data.formItem.value.deviceSerial,
      deviceDeliveredDate:this.data.formItem.value.deviceDeliveredDate,
    })
  }

  closeDialog(created: boolean, deviceInfo?: any) {
    this.dialogRef.close({ created, deviceInfo });
  }

  Cancel() {
    this.closeDialog(false)
  }

  Accept() {
    if (this.deviceForm.invalid) {
      return
    }
    this.closeDialog(true, this.deviceForm.value)
  }

}
