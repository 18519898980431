import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { IEmployeeGET } from '@payroll/final-payroll/models/models';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { ShowDocumentModalComponent } from 'src/app/shared/show-document-modal/show-document-modal.component';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { IEmployeePermissionPOST } from '../models/EmployeePermissions';
import { personnelActionTypes } from '../personnel-actions-tap/personnel-actions-tap.component';
import { RejectLicenseModalComponent } from './reject-license-modal/reject-license-modal.component';
import { openReport } from 'src/app/shared/utils/utility';
import * as moment from 'moment';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { NoWhitespaceValidator } from 'src/app/shared/custom-validation/custom-validation';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';


enum RequestStates {
  mapPending = 1,
  approvedIntern = 2,
  rejected = 3,
  desvinculations = 4,
  mapApproved = 5
}

enum Sex {
  Femenino = 1,
  Masculino = 2
}

type GUID = string & { isGuid: true };
@Component({
  selector: 'app-licenses-tab',
  templateUrl: './licenses-tab.component.html',
  styleUrls: ['./licenses-tab.component.css']
})
export class LicensesTabComponent implements OnInit, OnChanges {
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'ACTION-CHANGE-EMPLOYEE',
    licenses: { key: 'Licenses-Recurring-Actions', value: false },
    add: { key: 'Add-Licenses-Recurring-Actions', value: false },
    view: { key: 'View-Licenses-Recurring-Actions', value: false },
    edit: { key: 'Edit-Licenses-Recurring-Actions', value: false },
    approvePermission: { key: 'Approve-Licenses-Recurring-Actions', value: false },
    rejectPermission: { key: 'Reject-Licenses-Recurring-Actions', value: false }

  }


  licenseGuiID = ''
  allowedDays
  private readonly urlDoc = environment.api_doc + '/Document';
  file = { document: '', documentLicense: '' }
  reasonToReject = ''
  showRejectReasonError = false;
  licenseToRejectId = 0
  personalFile = personalFile;
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  
  reportUrl = environment.reportUrl
  @Input() licenses = []
  @Input() employeeSelected: IEmployeeGET
  @Input() vacationList: any
  @Output() getVacationList = new EventEmitter<boolean>()
  @Output() refreshLicenses = new EventEmitter<boolean>()
  form: FormGroup
  subscriptions = new Subscription()
  formIsInvalid = false
  licenseOptions = []
  dataList = []
  dataListPaginated = []

  showDateApplicationError: boolean = false;
  showDateStartError: boolean = false;
  showDateEndError: boolean = false;
  showRequestDateToEndDateError: boolean = false;
  sweetAlert = new SweetAlert;

  typeLicenseSeleted: any;

  @Input() isSeviceCommission: boolean = false;
  @Input() isSuspended: boolean = false;
  isEditinglicense: boolean = false;
  currentLicenseConditionOnEditting!: number;
  currentLicenseEdittingIsWithoutPayroll: boolean = false;

  minMaxDate = {
    min: '',
    max: ''
  }
  nameConfig;

  reasonsConfig = this.getDropdownConfig('stringData');

  @Input() modificationReasons: ParameterControl[] = [];

  @ViewChild('uppyFileLicence')
  uppyFileLicence: UppyFileComponent | null = null;
  constructor(
    private srvPersonnel: PersonnelActionsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private docService: docService,
    public authInfo: AuthInfoService,
    private parameterControlService: ParameterControlService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.form) return
    if (this.employeeSelected?.status) {
      this.form.get('applicationDate').setValue(new Date().toLocaleDateString('fr-CA'));
    }

    if(changes['isSuspended']?.currentValue){
      this.disableButtonsAndInputs();
    }
  }

  ngOnInit(): void {
    this.nameConfig = this.getDropdownConfig('name')
    this.privilege = this.authInfo.setPrivileges(this.privilege)
    this.form = this.formBuilder.group({
      permissionsId: [0],
      licenseType: [null],
      licenseTypeId: [0, Validators.min(1)],
      applicationDate: [null, Validators.required],
      dateStart: [null, Validators.required],
      dateEnd: [null, Validators.required],
      reason: [''],
      comment: [''],
      evidence: ['', Validators.required],
      approvedRequired: [false],
      conditionId: [0],
      todayDate: [null],
      createUserId: 0,
      newModificationComment: [null],
      newModificationReason: [null],
      newModificationReasonId: [null],
      oldModificationComment: [null],
      oldModificationReason: [null]
    })

    this.form.get('todayDate').setValue(new Date().toLocaleDateString('fr-CA'));
    this.getLicensesTypes()
  }

  disableButtonsAndInputs() {
    if(this.form){
      this.form.disable();
    }
    var buttons: any = document.getElementsByClassName('btn');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = true;
    }
  }

  rejectLicenseModal(item) {
    this.dialog.open(RejectLicenseModalComponent, {
      data: { item },
      width: '30%'
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (!res) return
        if (res?.success) {
          this.refreshLicenses.emit(true)
        }
      }
    })
  }
  resetForm() {
    this.form.patchValue({
      permissionsId: 0,
      licenseType: null,
      licenseTypeId: 0,
      applicationDate: null,
      dateStart: null,
      dateEnd: null,
      conditionId: 0,
      reason: '',
      comment: '',
      evidence: '',
      approvedRequired: false,
      createUserId: 0,
      newModificationComment: null,
      newModificationReason: null,
      newModificationReasonId: null,
      oldModificationComment: null,
      oldModificationReason: null
    })
  }

  getLicensesTypes() {
    this.srvPersonnel.getLicensesTypes().subscribe((res: any) => {
      if (res.succeded) {
        this.licenseOptions = res?.dataList?.filter((d: { status: boolean; }) => d?.status);
      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error("Ha ocurrido un error inesperado")
    })
  }


  private setDate(date1) {
    let date2 = new Date(date1);
    date2.setDate(date2.getDate() + 1);
    return date2;
  }

  private validateFormatDate(date: Date) {
    return date.getFullYear() > 9999 || date.getDay() > 31 || date.getMonth() > 12;
  }

  validateDate() {
    const dateStart = this.setDate(this.form.get('dateStart').value);
    const dateEnd = this.setDate(this.form.get('dateEnd').value);
    const applicationDate = this.setDate(this.form.get('applicationDate').value);

    if (this.validateFormatDate(applicationDate)) {
      this.toastr.warning('La Fecha de Solicitud es Invalida');
      return false;
    }

    if (this.validateFormatDate(dateStart)) {
      this.toastr.warning('La Fecha de Inicio es Invalida');
      return false;
    }

    if (this.validateFormatDate(dateEnd)) {
      this.toastr.warning('La Fecha de Término es Invalida');
      return false;
    }

    return true;
  }

  handleDateStartChange() {
    const value = this.form.get("dateStart").value
    if (value) {
      this.form.controls["dateEnd"].setValue(value)
    }
  }

  convertData(vacantion) {
    return formatDate(vacantion, 'yyyy-MM-dd', 'en_US')
  }

  employeeIsInVacations(licenseStartDate: any, licenseEndDate: any) {
    let hasVacation = this.vacationList
      .filter((vacantion: any) => vacantion.employeeId == this?.employeeSelected.employeeId)
      .find(vacantion => (this.convertData(vacantion.dateStart) >= licenseStartDate && this.convertData(vacantion.dateEnd) <= licenseEndDate)
        || licenseStartDate == this.convertData(vacantion.dateEnd) || licenseEndDate == this.convertData(vacantion.dateEnd)
        || licenseStartDate == this.convertData(vacantion.dateStart) || licenseEndDate == this.convertData(vacantion.dateStart)
        || (licenseStartDate >= this.convertData(vacantion.dateStart) && licenseStartDate <= this.convertData(vacantion.dateEnd))
        || (licenseEndDate >= this.convertData(vacantion.dateStart) && licenseEndDate <= this.convertData(vacantion.dateEnd)));
    return {
      isInVacation: hasVacation?.vacancyId ? true : false,
      vacantionId: hasVacation?.vacancyId
    }
  }

  showDocument(document: string, title: string) {
    this.dialog.open(ShowDocumentModalComponent, {
      width: '50%',
      data: {
        src: document,
        title
      }
    })
  }

  dateIsTaken(date1, date2) {
    const licenceId = this.form?.get('permissionsId')?.value;
    let dateStart = new Date(date1 + 'T00:00:00')
    let dateEnd = new Date(date2 + 'T00:00:00')
    const licenses = [...this.licenses].filter(s => s.permissionsId !== licenceId)
    licenses.forEach(v => {

      let dStart = new Date(v.dateStart)
      let dEnd = new Date(v.dateEnd)

      //rango de fecha interior
      if (dateStart >= dStart && dateStart <= dEnd) {
        return true
      }
      if (dateEnd >= dStart && dateEnd <= dEnd) {
        return true
      }
      //rango de fecha exterior
      if (dateStart <= dStart && dateEnd >= dEnd) {
        return true
      }

    })
    return false;
  }

  save() {
    this.formIsInvalid = this.form.invalid;

    if (this.form.get('licenseType').value?.requiredMotive == true) {
      this.form.get("reason").setValidators(Validators.required)
      this.form.get("reason").updateValueAndValidity()
    }

    if (this.formIsInvalid) return

    if (this.form.get('licenseType').value?.appliesWomen && this.employeeSelected?.sexId === Sex.Masculino) {
      this.toastr.warning('Este tipo de licencia solo aplica para personal femenino');
      return;
    }

    let filteredLicenses: any

    filteredLicenses = this.licenses.filter(l => l.employeeId === this.employeeSelected?.employeeType)
      .filter((lc: any) => lc.typePermissionsId == this.form.get('licenseType').value?.licenseTypeId)
    if (filteredLicenses.length >= this.form.get('licenseType').value?.limitPerYear) {
      this.toastr.warning(`Ha excedido el límite de ${this.form.get('licenseType').value?.name} por año`)
      return
    }
    this.formIsInvalid = this.form.invalid;

    if (this.formIsInvalid) return;

    if (!this.validateDate()) {
      return;
    }
    const { dateStart, dateEnd } = this.form.value
    if (this.dateIsTaken(dateStart, dateEnd)) {
      this.toastr.warning('Ya tiene una licencia registrada en la fecha seleccionada')
      return
    }

    const val = this.form.value

    this.uppyFileLicence.handleFile((guid) => {
      const license: IEmployeePermissionPOST = {
        applicationDate: val.applicationDate,
        comment: val.comment,
        companyId: this.authInfo.getCompanyId(),
        conditionId: this.isEditinglicense ? val?.conditionId : this.form.get('licenseType').value?.approvedRequired === true ? 6 : 2,
        dateEnd: val.dateEnd,
        dateStart: val.dateStart,
        employeeId: this.employeeSelected.employeeId,
        evidence: guid,
        permissionsId: val?.permissionsId > 0 ? val.permissionsId : 0,
        reason: val.reason,
        status: true,
        typePermissionsId: val.licenseTypeId,
        typePersonalActionId: personnelActionTypes.license,
        createUserId: this.isEditinglicense ? val?.createUserId : this.authInfo.getUserId(),
        modifyUserId: this.isEditinglicense ? this.authInfo.getUserId() : 0,
        modificationComment: this.form?.value?.newModificationComment,
        modificationReasonId: this.form?.value?.newModificationReasonId,
      }
      if (this.employeeIsInVacations(val.dateStart, val.dataEnd).isInVacation) {
        this.sweetAlert.Default("Aviso", "Existen vacaciones activas de este Servidor Público dentro de las fechas seleccionadas para la licencia, Para realizar esta acción deberá pausar o eliminar esas vacaciones", "warning");
        return;
      } else {
        if (this.isEditinglicense) {
          this.updateEmployeePermission(license);
        } else {
          this.saveEmployeePermission(license);
        }
      }
    });
  }

  clearFields(reloadList: boolean = false){
    this.refreshLicenses.emit(reloadList)
    this.resetForm()
    this.file.documentLicense = ''
    this.uppyFileLicence.cleanUppy()
    this.isEditinglicense = false;

    this.form.get('newModificationReason').clearValidators();
    this.form.get('newModificationReason').updateValueAndValidity();
  }

  saveEmployeePermission(license) {
    this.srvPersonnel.saveEmployeePermission(license).subscribe((res: any) => {
      if (!res.succeded) {
        if (res.errors.length > 0) {
          this.toastr.error(res.errors[0])
        } else if (res.warnings.length > 0) {
          this.toastr.warning(res.warnings[0])
        }
        return
      }

      this.toastr.success("Datos guardados")
      this.clearFields(true);
    }, err => {
      this.toastr.error("Ha ocurrido un error al guardar el registro");
    })
  }

  updateEmployeePermission(license) {
    this.srvPersonnel.updateEmployeePermission(license).subscribe((res: any) => {
      if (!res.succeded) {
        if (res.errors.length > 0) {
          this.toastr.error(res.errors[0])
        } else if (res.warnings.length > 0) {
          this.toastr.warning(res.warnings[0])
        }
        return
      }
      if (res.succeded) {
        this.toastr.success("Registro modificado exitosamente", "Registro Modificado");
        this.clearFields(true);
        //this.getLicenses()
      }
    }, err => {
      this.toastr.error("Ha ocurrido un error al modificar el registro");
    })
  }

  approvePermission(item) {
    const { permissionsId, conditionId } = item
    //if(conditionId != 6) return
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      title: "¿Está seguro que desea aprobar esta licencia?",
      icon: "question",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: '#15c229'
    }).then(res => {
      if (res.isConfirmed) {
        this.srvPersonnel.approvePermission(permissionsId).subscribe((res: any) => {
          if (res.succeded) {
            this.refreshLicenses.emit(true)
            this.toastr.success("La licencia ha sido aprobada")
          } else {
            this.toastr.error(res.errors[0])
          }
        }, err => {
          this.toastr.error("Ha ocurrido un error inesperado")
        })
      }
    })
  }

  rejectPermission() {
    if (!this.reasonToReject) {
      this.showRejectReasonError = true
      return
    } else {
      this.showRejectReasonError = false
    }
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      title: "¿Está seguro que desea rechazar esta licencia?",
      icon: "question",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: '#f64e60'
    }).then(res => {
      if (res.isConfirmed) {
        const permissionReject = {
          permissionsId: this.licenseToRejectId,
          reasonReject: this.reasonToReject
        }
        this.srvPersonnel.rejectPermission(permissionReject).subscribe((res: any) => {
          if (res.succeded) {
            this.toastr.success('Licencia rechazada')
            this.refreshLicenses.emit(true)
            document.getElementById('btnClose').click()
          } else {
            this.toastr.error(res.errors[0])
          }
        }, err => {
          this.toastr.error('Ha ocurrido un error inesperado')
        })
      }
    })
  }
  toReject(item) {
    const { permissionsId, conditionId } = item
    this.licenseToRejectId = permissionsId
    this.reasonToReject = ''
  }

  changeLicenseType(item) {
    const value = item;
    this.typeLicenseSeleted = item;
    if (Array.isArray(value)) {
      if (value.length == 0) {
        this.form.get('licenseTypeId').patchValue(0)
        this.form.get('approvedRequired').patchValue(0)
      }
    } else {
      this.form.get('licenseTypeId').patchValue(value.licenseTypeId)
      this.form.get('approvedRequired').patchValue(value.approvedRequired == true ? RequestStates.mapPending : RequestStates.approvedIntern)
      this.allowedDays = value.allowedDays
      if (this.allowedDays == 0 || this.allowedDays == 1) {
        this.form.get('dateEnd').disable()
      } else {
        this.form.get('dateEnd').enable()
      }
    }
    if (value?.requiredMotive == true) {
      this.form.get("reason").setValidators([Validators.required, NoWhitespaceValidator])
      this.form.get("reason").updateValueAndValidity()
    } else {
      this.form.get("reason").setValidators(null)
      this.form.get("reason").updateValueAndValidity()
    }

    if (value?.evidenceRequired === true) {
      this.form.get("evidence").setValidators(Validators.required)
      this.form.get("evidence").updateValueAndValidity()
    } else {
      this.form.get("evidence").setValidators(null)
      this.form.get("evidence").updateValueAndValidity()
    }
  }

  changeModificationReason(value: ParameterControl){
    this.form.get('newModificationReasonId').setValue(value?.ocode);
  }

  changeDateStart() {
    this.setMaxDate()
    this.minMaxDate.min = ''
    if (this.allowedDays == 0 || this.allowedDays == 1) {
      this.form.get('dateEnd').patchValue(this.form.get('dateStart').value)
      return
    }
    this.minMaxDate.min = moment(this.form?.get('dateStart')?.value).startOf('date').format('yyyy-MM-DD')
  }
  get getDateStart() {
    return this.form?.get('dateStart')?.value as Date;
  }

  validateDateInput(date1, date2, name) {
    this.handleDateStartChange();
    let time1 = new Date(date1)
    let time2 = new Date(date2)

    time1.setDate(time1.getDate() + 1);
    time2.setDate(time2.getDate() + 1);

    switch (name) {

      case 'application':
        if (time1 >= time2) {
          this.showDateApplicationError = false;
        } else {
          this.showDateApplicationError = true;
        }
        break;
      case 'start':
        if (time1 >= time2) {
          this.showDateStartError = false;
        } else {
          this.showDateStartError = true;
        }
        break;
      case 'end':
        if (time1 >= time2) {
          this.showDateEndError = false;
        } else {
          this.showDateEndError = true;
        }
        break;
      default:
        break;

    }


  }

  openReportPDF(change: any) {
    const { permissionsId, employeeId, companyId, typePermissionsId, typePersonalActionId } = change
    let reportUrl = ''

    
    reportUrl = `${this.reportUrl}/?ReportCode=rpt_License&CompanyId=${companyId}&EmployeeId=${employeeId}&LicenseId=${permissionsId}`

    if (reportUrl.length === 0) {
      return
    }

    let parameters = {
      url: reportUrl,
      title: 'Licencia',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }
  getMinDate(date) {
    let d = moment(date).startOf('date').add(1,'days');
    return d.format('yyyy-MM-DD');
  }
  get getMaxDate() {
    if (this.allowedDays < 1) return "";
    let d = new Date(this.getDateStart);
    d.setDate(d.getDate() + this.allowedDays);
    return d.toLocaleDateString('fr-CA');
  }

  setMaxDate(){
    const days = this.allowedDays
    if(days < 1){
      return
    }
    this.minMaxDate.max = moment(this.form.get('dateStart').value).add(this.allowedDays - 1, 'd').format('yyyy-MM-DD')

  }

  validateEndDateInput(date1, date2, date3) {

    let time1 = new Date(date1)
    let time2 = new Date(date2)
    let time3 = new Date(date3)

    time1.setDate(time1.getDate() + 1);
    time2.setDate(time2.getDate() + 1);
    time3.setDate(time3.getDate() + 1);

    if (time1 >= time2 && time1 <= time3) {
      this.showDateEndError = false;
    } else {
      this.showDateEndError = true;
    }

  }

  validateEndDate(){
    const form = this.form.value
    const requestDate = moment(form.applicationDate)
    const startDate = moment(form.dateStart)
    const endDate = moment(form.dateEnd)
      
    this.showDateEndError = false
    this.showRequestDateToEndDateError = false

    if(requestDate > startDate){
      this.showRequestDateToEndDateError = true
      return false
    }

    if(endDate < startDate){
      this.showDateEndError = true
      return false
    }
 
    
  }

  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  openGuID(guid) {
    try {
      this.docService.getDocument(guid).subscribe(e => {
        window.open(e.data, '_blank');
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  getLicenses() {
    this.srvPersonnel.getEmployeePermissionsByIdEmployee(this.employeeSelected.employeeId).subscribe((res: any) => {
      if (res.succeded) {
        this.licenses = res.dataList.filter(x => x.typePersonalActionId == personnelActionTypes.license)
      } else {
        this.toastr.error(res.errors[0]);
      }
    }, err => {
      this.toastr.error("Ha ocurrido un error inesperado")
    })
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }
  showError(name) {
    const field = this.form.get(name)
    if (field.hasError('required')) {
      return 'Este campo es requerido'
    }
    if (field.hasError('min')) {
      return 'Este campo es requerido'
    }
    return ''
  }

  setEvidence(event){
    this.form.get('evidence').setValue(event);
  }

  editLicense(license) {

    this.clearFields(false);

    this.isEditinglicense = true;
    this.currentLicenseConditionOnEditting = license?.conditionId;
    this.currentLicenseEdittingIsWithoutPayroll = license?.withoutPayroll;

    const modificationReason = this.modificationReasons?.find(m => m.ocode == license?.modificationReasonId);

    this.form.patchValue({
      ...license,
      applicationDate: formatDate(license?.applicationDate, "yyyy-MM-dd", "en"),
      dateStart: formatDate(license?.dateStart, "yyyy-MM-dd", "en"),
      dateEnd: formatDate(license?.dateEnd, "yyyy-MM-dd", "en"),
      createUserId: license.createUserId,
      oldModificationComment: license?.modificationComment,
      oldModificationReason: modificationReason
    });

    const typePermission = this.licenseOptions?.find(l => l?.licenseTypeId === license?.typePermissionsId);

    this.form.get('licenseType').setValue(typePermission);

    this.changeLicenseType(typePermission);

    if (license?.evidence) {
      this.file.documentLicense = license?.evidence;
    } else {
      this.file.documentLicense = '';
    }

    this.form.get('newModificationReason').setValidators(Validators.required);
    this.form.get('newModificationReason').updateValueAndValidity();
  }

}