<h2 class="mat-dialog-title"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span> Novedades de
    {{employeeDetail.firstName}} {{employeeDetail.lastName}}
    <button (click)="this.dialogRef.close()" type="button" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</h2>

<div class="mat-dialog-content">

    <div class="card-body">
        <div class="card-header row justify-content-between p-0">
            <div class="col-sm-12 col-md-4 c-left">
            </div>

            <div class="col-sm-12 col-md-8 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12 mt-9">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" (click)="exportToExcel()">
                                <i class="flaticon-excel icon-2x text-success mr-3"></i>
                                Excel
                            </a>
                        </div>
                    </div>

                    <div class="col text-nowrap">
                        <el-search-bar #searchBar [records]="newsDetails" (onKeyChange)="onKeyChange($event)"
                            [keyValue]="filterText" [itemForSearching]="searchBarDisplayNames"
                            (onChange)="getFilteredList($event)"
                            [itemForSearching]="searchBarDisplayNames"></el-search-bar>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-left">Secuencia</th>
                        <th class="text-right">Tipo de Novedad</th>
                        <th class="text-right">Concepto</th>
                        <th class="text-right">Monto</th>
                        <th class="text-right">Monto Total</th>
                        <th class="text-right">Balance</th>
                        <th class="text-right">Valor por defecto</th>
                        <th class="text-right">Valor Manual</th>
                        <th class="text-right">Observación</th>
                        <th class="text-right">Fecha Inicial</th>
                        <th class="text-right">Fecha de termino</th>
                        <th class="text-left">Estatus</th>
                        <th class="text-left">Fecha de creacion</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let news of paginatedNewsDetails">
                        <td class="text-left">{{news.payrollNewsId}}</td>
                        <td class="text-right">{{news.newsType}}</td>
                        <td class="text-right">{{news.newsConcept}}</td>
                        <td class="text-right">{{news.amount}}</td>
                        <td class="text-right">{{news.totalAmount}}</td>
                        <td class="text-right">{{news.balance}}</td>
                        <td class="text-right">{{news.defaultValue}}</td>
                        <td class="text-right">{{news.manualValue}}</td>
                        <td class="text-right">{{news.observation}}</td>
                        <td class="text-right">{{news.startDate |date:'dd/MM/YYY'}}</td>
                        <td class="text-right">{{news.endDate |date:'dd/MM/YYY'}}</td>
                        <td class="text-left">
                            <span
                                [ngClass]="{'font-weight-bold text-success': news.status, 'font-weight-bold text-danger': !news.status}">
                                {{news.status ? "Activa" : "Inactiva"}}
                            </span>
                        </td>
                        <td class="text-left">{{news.createDate |date:'dd/MM/YYY'}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div class="col-sm-12 col-md-5 pl-0">
                    <div class="d-flex align-items-center mr-7">
                        <span class="svg-icon svg-icon-gray-500 pr-2">
                            <i class="flaticon-marca-de-verificacion"></i>
                        </span>
                        <div class="dataTables_info" id="kt_datatable_info" role="status" aria-live="polite">
                            Total de registros <span
                                class="font-weight-bolder text-primary ml-1">{{paginatedNewsDetails.length}}
                            </span>
                        </div>
                    </div>
                </div>

                <el-caption [originalRecordsLength]="payrollnewsDetailsOriginal.length"
                    [listedRecordsLength]="paginatedNewsDetails.length" #paginator
                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :newsDetails.length"
                    [records]="filteredList.enable ? filteredList.records : newsDetails"
                    (onChage)="getPaginatedRecords($event)"></el-caption>
            </div>
        </div>
    </div>
</div>

<!-- <div class="mat-dialog-actions" align="end">
    <button (click)="closeModal()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button [disabled]="budgetAreaForm.invalid" (click)="SubmitBudgetArea()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div> -->