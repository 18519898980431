<div class="card card-custom">
    <div class="card-header mt-3 " style="justify-content: flex-start !important">
        <div class="card-title flex-column align-items-start" style="justify-content: flex-start !important">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-lista-de-verificacion-2 icon-3x text-danger mr-3"></i>
                <h2 class="text-primary"> Cargos </h2>
            </div>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
            <div class="card-toolbar d-flex justify-content-start">

                <!-- <div class="col-md-4 col-sm-5 mr-2 px-0 pt-1">
                    <div class="form-group institution">

                        <label class="text-primary">
                            Institución
                        </label>

                        <ngx-select-dropdown [config]="getDropdownConfig('companyName', 'Seleccionar Institución')"
                            [options]="currentCompany" [(ngModel)]="company" (change)="getPositionList($event.value?.companyId)">
                        </ngx-select-dropdown>
                    </div>
                </div> -->

                <div class="col-md-4 col-sm-5 mr-2 px-0 pt-1">
                    <div class="form-group institution">

                        <label class="text-primary">
                            Estatus
                        </label>

                        <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                            [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelect)="onItemDeSelect($event)"
                            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>

                <button (click)="openCreatePositionModal()"
                    *ngIf=" privilege.addAction.value"
                    class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                    <i class="flaticon-mas icon-2x" data-toggle="tooltip" data-original-title="Agregar nuevo Cargo"></i>
                    Agregar
                </button>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="openPositionReport()">
                            <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                            Visualizar Reporte
                        </a>
                        <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a> -->
                    </div>
                </div>

                <div class="col text-nowrap">
                    <el-search-bar #searchBar (onKeyChange)="onKeyChange($event);" [keyValue]="filterText"
                        (onKeyParameterChange)="onKeyParameterChange($event)"
                        [namePageLocalStorage]="localStorageSearch"
                        [records]="dataList" [itemForSearching]="searchBarDisplayNames"
                        (onChange)="getFilteredList($event);"></el-search-bar>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table mt-2 table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <!-- <th>Código Compañía</th> -->
                        <th>Secuencia</th>
                        <th>Nombre Corto</th>
                        <th>Código Cargo</th>
                        <th>Cargo</th>
                        <th>Grupo Ocupacional</th>
                        <th>Posiciones Solicitadas</th>
                        <th>Posiciones Aprobadas</th>
                        <th>Fecha de Creación</th>
                        <th>Fecha de Envío</th>
                        <th>Fecha de Aprobación</th>
                        <th>Fecha de Rechazo</th>
                        <th>Condición</th>
                        <th>Estatus</th>
                        <th class="actionsBtn">Acciones</th>
                    </tr>
                </thead>

                <tbody>
                    <ng-template [ngIf]="records.length>0">
                        <tr *ngFor="let item of records">
                            <td>{{item.positionId}}</td>
                            <td>{{item.shortName}}</td>
                            <td>{{item.positionCode || 'N/A'}}</td>
                            <td>{{item.positionName}}</td>
                            <td>{{item.occupationalGroup || 'N/A'}}</td>
                            <td>{{item.requestedPositions}}</td>
                            <td>{{item.approvalPositions}}</td>
                            <td>{{item?.createDate | date: 'dd/MM/yyyy'}}</td>
                            <td>{{item?.sentDate | date: 'dd/MM/yyyy'}}</td>
                            <td>
                                <span>{{item?.approvedDate ? (item?.approvedDate | date: 'dd/MM/yyyy') : 'N/A' }}</span>
                            </td>
                            <td>
                                <span>{{item?.rejectedDate ? (item?.rejectedDate | date: 'dd/MM/yyyy') : 'N/A' }}</span>
                            </td>
                            <td [ngClass]="{
                                'text-info': item.conditionId == 1 ||  item.conditionId == 5,
                                'text-success': item.conditionId == 2,
                                'text-danger': item.conditionId == 3 ||  item.conditionId == 5,
                                'text-warning': item.conditionId == 4
                                }" class="font-weight-bold">
                                {{item.condition || 'N/A'}}</td>
                            <td>

                                <span class="font-weight-bold text-success"
                                    [ngClass]="item.status? 'text-success': 'text-danger'"> {{item.status?
                                    'Activo':'Inactivo'}}</span>
                            </td>
                            <td class="actionsBtn">
                                <button class="btn btn-icon btn-light-success btn-sm m-1"
                                    (click)="sendPositionToMap(item)"
                                    *ngIf="((item.conditionId == 4 || item.conditionId == 3) && item.status == true) &&  privilege.editAction.value"><i
                                        class="flaticon-enviar" data-toggle="tooltip"
                                        data-original-title="Enviar al MAP"></i></button>

                                <button class="btn btn-icon btn-light-warning btn-sm m-1"
                                    (click)="openCreatePositionModal(false, true, item)"
                                    *ngIf="((item.conditionId == 4 || item.conditionId == 3) && item.status == true) && privilege.editAction.value"><i
                                        class="flaticon-edit" data-toggle="tooltip"
                                        data-original-title="Editar"></i></button>

                                <!-- <a class="btn btn-icon btn-light-success btn-sm m-1"> <i class="flaticon-enviar"></i></a> -->
                                <button class="btn btn-icon btn-light-info btn-sm m-1"
                                    *ngIf=" privilege.viewAction.value"
                                    (click)="openCreatePositionModal(true, false, item)"><i class="flaticon-ver"
                                        data-toggle="tooltip" data-original-title="Ver"></i></button>

                                <button class="btn btn-icon btn-light-danger btn-sm m-1"
                                    *ngIf="item?.conditionId === 3 && privilege.viewAction.value"
                                    (click)="openReasonReject(item?.reasonReject)">
                                    <i class="flaticon-lista-de-verificacion-1" data-toggle="tooltip"
                                        data-original-title="Ver Razón de Rechazo"></i></button>

                                <button class="btn btn-icon btn-light-danger btn-sm m-1"
                                    (click)="positionListDelete(item.positionId)"
                                    *ngIf="(item.conditionId != 1 && item.status == true) &&  privilege.deleteAction.value"><i
                                        class="flaticon-delete" data-toggle="tooltip"
                                        data-original-title="Inactivar"></i></button>
                                <a *ngIf="(item.conditionId != 1 && item.conditionId != 3 && item.conditionId != 4)"
                                    [routerLink]="['/position-changes',item.positionId]"
                                    class="btn btn-icon btn-light-success btn-sm m-1" data-toggle="tooltip"
                                    title="Acciones del Cargo">
                                    <i class="flaticon-colaboracion icon-2x"></i>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
            <div>

                <el-caption #paginator [originalRecordsLength]="dataList?.length"
                    [listedRecordsLength]="records?.length"
                    [registerQuantity]="filteredList?.enable && filteredList.records?.length>0 ? filteredList.records?.length :dataList?.length"
                    [records]="filteredList?.enable ? filteredList?.records : dataList"
                    (onChage)="getPaginatedRecords($event)"></el-caption>
            </div>
        </div>
    </div>
</div>
<export-data-to-pdf [title]="'Cargos'" id="dataToPrint">
    <ng-container thead>
        <th>Nombre Corto</th>
        <th>Cargo</th>
        <th>Unidad Organizativa</th>
        <th>Código Cargo</th>
        <th>Grupo Ocupacional</th>
        <th>Posiciones Solicitadas</th>
        <th>Posiciones Aprobadas</th>
        <th>Condición</th>
        <th>Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let item of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.dataList"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td>{{item.shortName}}</td>
            <td>{{item.positionName}}</td>
            <td>{{item.departmentName || 'N/A'}}</td>
            <td>{{item.positionCode || 'N/A'}}</td>
            <td>{{item.occupationalGroup || 'N/A'}}</td>
            <td>{{item.requestedPositions}}</td>
            <td>{{item.approvalPositions}}</td>
            <td [ngClass]="{
                'text-info': item.conditionId == 1 ||  item.conditionId == 5,
                'text-success': item.conditionId == 2,
                'text-danger': item.conditionId == 3 ||  item.conditionId == 5,
                'text-warning': item.conditionId == 4
                }" class="font-weight-bold">
                {{item.condition || 'N/A'}}</td>
            <td>

                <span class="font-weight-bold text-success" [ngClass]="item.status? 'text-success': 'text-danger'">
                    {{item.status?
                    'Activo':'Inactivo'}}</span>
            </td>

        </tr>
    </ng-container>
</export-data-to-pdf>
