import { DatePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { InicidentsService } from 'src/app/services/inicidents.service';
import { JobOfferService } from 'src/app/services/job-offer.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
import { BarOptions, ChartOptions } from 'src/app/shared/models/apexChartModels';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { DateFilterComponent } from './date-filter/date-filter.component';

declare const $: any;


@Component({
  selector: 'app-incidentes-dashboard',
  templateUrl: './incidentes-dashboard.component.html',
  styleUrls: ['./incidentes-dashboard.component.css']
})
export class IncidentesDashboardComponent implements OnInit {

  public chartOptions: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions>;
  public barOptions: Partial<BarOptions>;
  public barOptions2: Partial<BarOptions>;
  public barOptions3: Partial<BarOptions>;

  datePipe = new DatePipe('en-Us')

  totalIncidents: [] = []
  dateFrom: string = '1-1-0001'
  dateTo: string = new Date().toLocaleDateString('fr-CA')
  refresIsDisabled: boolean = true

  filtered = false;
  filterParams = {
    year: null,
    date2: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    date1: '0001-01-01',
    companyId: this.authInfo.getCompanyId()
  }
  
  departments: any[] = [];
  department: any[] = [];

  constructor(
    private _toastService: ToastService,
    private jobOfferService: JobOfferService,
    private incidentsService: InicidentsService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<IncidentesDashboardComponent>,
    private _changeDet: ChangeDetectorRef,
    private authInfo: AuthInfoService  
  ) {
    this.dialogRef.disableClose = true;
    this.buildCharts()
    this.dateTo = this.datePipe.transform(this.dateTo, 'MM-dd-yyyy')
    
  }

  changeDepartment(){
    this.setDataDeparment([]);

    if (this.department.length < 1) {
      return
    }
    let data: any[] = []
    this.department.forEach(e => {
      let chartData: any = this.departments.find(x => x.description2 == e.description2)
      if (!chartData) {
        chartData = this.departments.find(x => x.description2 == e.description2) ?? { rowText: e.description2, value: 0, value2: 0 }
      }
      let cData = Object.assign({}, chartData)
      data.push(cData)
    });
    this.setDataDeparment([...data]);
  }

  dropdownConfig(displayKey, placeholder?) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }


  buildCharts() {
    this.chartOptions = {
      series: [0, 0, 0],
      chart: {
        width: '80%',
        type: "donut",

      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      title: {
        text: ""
      },
      labels: ["", "", ""],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      fill: {
        colors: ["#f11565", "#0481af", "#023878"],
      },
      colors: ["#f11565", "#0481af", "#023878"],


      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
          }
        }
      ]
    };

    this.chartOptions2 = {
      series: [0, 0, 0],
      chart: {
        width: '80%',
        type: "donut",

      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      title: {
        text: ""
      },
      labels: ["", "", ""],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      fill: {
        colors: ["#f11565", "#0481af", "#023878"],
      },
      colors: ["#f11565", "#0481af", "#023878"],


      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
          }
        }
      ]
    };


    this.barOptions = {
      series: [
        {
          name: "Cantidad de incidentes",
          data: [0, 0, 0],
          
        }
      ],
      chart: {
        type: "bar",
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
        },
        
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          }
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
        ],
        tickPlacement: 'on',
      },
      
      yaxis: {
        title: {
          text: ""
        },
        
      },
      fill: {
        opacity: 1,
        
      },
      legend: {
        show: false,
        
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        }
      },
      
      
    };
    this.barOptions2 = {
      series: [
        {
          name: "Cantidad de incidentes",
          data: [0, 0, 0],
        },
        
      ],
      chart: {
        type: "bar",
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
        },
      
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
        ],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: ""
        },
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        },
        z:{
          formatter: undefined,
          title: ''
        }
      }
    };

    this.barOptions3 = {
      series: [
        {
          name: "Cantidad de incidentes",
          data: [0, 0, 0],
        }
      ],
      chart: {
        type: "bar",
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
        ],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: ""
        },
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        }
      }
    };
  }

  getDashboard() {
    this.incidentsService.getDashboard(this.filterParams.date1, this.filterParams.date2, this.filterParams.companyId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      //Total
      const incidentsTotalQuantities = res.singleData.incidentsTotalQuantities;
      this.totalIncidents = incidentsTotalQuantities[0].value;

      //Gender
      const incidentsByGenderQuantities = res.singleData.incidentsByGenderQuantities;
      this.chartOptions.series = []
      this.chartOptions.labels = []
      incidentsByGenderQuantities.forEach(ps => {
        this.chartOptions.labels.push(ps.description)
        this.chartOptions.series.push(ps.value)
      })

      //type
      const incidentsByTypeQuantities = res.singleData.incidentsByTypeQuantities;
      let data2 = []
      this.barOptions.xaxis.categories = []
      incidentsByTypeQuantities.forEach(ed => {
        data2.push({
          x: ed.description,
          y: ed.value
        })
      })
      this.barOptions.series = [{
        data: data2
      }]

      //Department
      const incidentsByDepartmentQuantities = res.singleData.incidentsByDepartmentQuantities;
      this.departments = [...incidentsByDepartmentQuantities];
      this.department = [...this.departments.slice(0, 10)];
      this.setDataDeparment([...this.department])

      //TypeEmployee
      const incidentsByTypeEmployeeQuantities = res.singleData.incidentsByTypeEmployeeQuantities;
      this.chartOptions2.series = []
      this.chartOptions2.labels = []
      incidentsByTypeEmployeeQuantities.forEach(ps => {
        this.chartOptions2.labels.push(ps.description)
        this.chartOptions2.series.push(ps.value)

      })

      //Degree
      const incidentsByDegreeQuantities = res.singleData.incidentsByDegreeQuantities;
      let data4 = []
      incidentsByDegreeQuantities.forEach(ed => {
        data4.push({
          x: ed.description,
          y: ed.value
        })
      })
      this.barOptions3.series = [{
        data: data4
      }]

    })
  }

  setDataDeparment(deparments){
    let data = []
    this.barOptions2.xaxis.categories = []
    deparments.forEach(ed => {
      data.push({
        x: ed.description2,
        y: ed.value,
        z: ed.description
      })
    })
    this.barOptions2.series = [{
      data: data
    }]
  }


  ngOnInit(): void {
    try{
      this.filterParams.date1 = this.datePipe.transform(`01-01-${new Date().getFullYear()}`, 'yyyy-MM-dd')
      this.filterParams.date2 = this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd')
    }catch(e){

    }
    
    this.buildCharts();
    this.getDashboard();
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  filterByDate(): void {
    this.dialog.open(FilterDashboardComponent, {
      data: { filterParams: this.filterParams }
    })
      .afterClosed()
      .subscribe((result) => {
        this.filtered = result.year != null || result.date1 != null || result.date2 != null;

        if (!this.filtered) {
          return;
        }

        this.filterParams.year = result.year
        if (result.date1) {
          this.filterParams.date1 = result.date1
        }

        if (result.date2) {
          this.filterParams.date2 = result.date2
        }
        this.filterParams.companyId = result.companyId

        this.getDashboard();
      });
  }

  clearFilter(): void {

    if (this.refresIsDisabled) {
      return
    }
    this.filterParams = {
      date1: this.datePipe.transform(`01-01-${new Date().getFullYear()}`, 'yyyy-MM-dd'),
      date2: this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd'),
      year: null,
      companyId: this.authInfo.getCompanyId()
    }

    this.getDashboard()
    this.refresIsDisabled = true
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

}
