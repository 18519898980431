<div class="modal-header border-0 dv-table-header-1 bg-primary mb-4" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="dialogRef.close(false)" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Filtrar por fecha</h2>
  </div>
<div class="container pb-4">
    <form [formGroup]="filterForm">
        <div class="row">
            <div class="col text-right">
                <button class="btn btn-light-warning ml-4" (click)="clear()">
                    <i class="fas fa-redo"></i>
                </button>
            </div>

        </div>
        <div class="row mb-4">
            
      <div class="col-md-12">
        <label for="" class="fw-bold">Institución</label>
        
          <ngx-select-dropdown formControlName="company" [multiple]='false' (change)="changeCompany()" [options]="dropdownItems" [config]="dropdownConfig('companyName')" class="w-100"></ngx-select-dropdown>
       
        
      </div>
            <!-- <div class="col-md-12">
                <label for="" class="fw-bold">Año</label>
          
                <select formControlName="year" (change)="onYearChange($event.target.value)" class="form-select" name="" id="">
                    <option [value]="null">Seleccionar</option>
                    <option *ngFor="let i of years" [value]="i.year">{{i.year}}</option>
                  </select>
            </div> -->
        </div> 
        <div class="row mb-4">
            <div class="col-md-6">
                <label class="d-block fw-bold">Desde</label>
                <input type="date" formControlName="dateFrom" class="form-control" name="" id="">
            </div>
            <div class="col-md-6">
                <label class="d-block fw-bold">Hasta</label>
                <input type="date" formControlName="dateTo" class="form-control" name="" id="">
            </div>
        </div>
    </form>
</div>

<div class="mat-dialog-actions" [align]="'end'">

    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="close()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button [disabled]="filterForm.invalid" class="btn btn-pill font-weight-bolder btn-shadow btn-success fw-bold"
        type="button" (click)="applyFilter()">
        <i class="flaticon-disco-flexible"></i>
        <span>Aplicar filtro</span>
    </button>
</div>