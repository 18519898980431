import { HttpClient, HttpHeaders } from '@angular/common/http';
import { identifierModuleUrl } from '@angular/compiler';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ExportToExcelService } from 'src/app/shared/export-to-excel/export-to-excel.service';
import { environment } from 'src/environments/environment';
import { SendMap, SendMapPut } from '../models/send-map-model';
import { SendMapHeader } from '../models/send-map-header';
import { EmployeesPositions } from '../models/employees-position-model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ParameterType, ValidationMapModel } from '../models/validation-map-model';
import { ObjetionMap } from '../models/objetionMap-model';
import { formatDate } from '@angular/common';
import { docService } from 'src/app/services/doc.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';

declare const $: any;

@Component({
  selector: 'app-no-objection',
  templateUrl: './no-objection.component.html',
  styleUrls: ['./no-objection.component.scss']
})
export class NoObjectionComponent implements OnInit, OnDestroy, AfterViewChecked {

  readonly hrm_route: string = environment.api_url;
  readonly core_route: string = environment.api_core_url;
  public path: string = "";
  public loadingPic = true;

  subscriptions: Subscription = new Subscription();
  records: any[] = [];
  mapInstitutionsList = [];
  mapDataList: any;
  mapHeaderDataList: SendMapHeader[] = [];
  mapPrincipalDataList: ObjetionMap[] = [];
  mapPrincipalDataItem: ObjetionMap;
  mapHeaderStringDataList: any[];
  employeesPositionsList: EmployeesPositions[] = [];
  bondingTypeList: any[] = [];
  rejectReasonsList: any[] = [];
  aprobation: boolean;
  appoved: boolean = false
  mapEmployeeSelectedItem: SendMap;
  mapHeaderSelectedItem: SendMapHeader;
  mapForm: FormGroup = new FormGroup({
    sendMapId: new FormControl(''),
    sedMapHeaderId: new FormControl('', [Validators.required]),
    postulationId: new FormControl('', [Validators.required]),
    positionApprovedId: new FormControl('', [Validators.required]),
    salaryRequested: new FormControl('', [Validators.required]),
    salaryApproved: new FormControl('', [Validators.required]),
    occupationalGroupId: new FormControl('', [Validators.required]),
    departmentId: new FormControl('', [Validators.required]),
    bondingType: new FormControl('', [Validators.required]),
    requestedDate: new FormControl('', [Validators.required]),
    validyDate: new FormControl('', [Validators.required]),
    observationMap: new FormControl('', [Validators.required]),
    condition: new FormControl('', [Validators.required]),
    idCompany: new FormControl('', [Validators.required]),
    status: new FormControl(true, [Validators.required]),
  });
  mapValidation: FormGroup = new FormGroup({
    validationMapHeaderId: new FormControl(['']),
    sendMapId: new FormControl(['', [Validators.required]]),
    noCertification: new FormControl([0, [Validators.required]]),
    departureDate: new FormControl(['', [Validators.required]]),
    dateResponse: new FormControl(['', [Validators.required]]),
    idCompany: new FormControl([1]),
    condition: new FormControl([0]),
    status: new FormControl([true]),
    details: new FormArray([]),
  });
  // mapValidationArray:FormArray = new FormArray([]);
  mapValidationPositionRows: { fieldName: string, text: string, value: number, pdf: boolean }[] = [
    { fieldName: 'Recinto', text: 'La Caleta2', value: 1, pdf: false },
    { fieldName: 'Unidad Organizativa', text: 'Rectoría3', value: 2, pdf: false },
    { fieldName: 'Posición / Cargo', text: 'Programador', value: 3, pdf: false },
    { fieldName: 'Grupo Ocupacional', text: 'IV', value: 4, pdf: false },
    { fieldName: 'Tipo de Nombramiento', text: 'Fijo', value: 5, pdf: false },
    { fieldName: 'Sueldo', text: '$50,000.00', value: 6, pdf: false },
    { fieldName: 'Nacionalidad', text: 'Dominicana', value: 7, pdf: false },
    { fieldName: 'Nivel Académico', text: 'Grado', value: 8, pdf: false },
    { fieldName: 'Título Obtenido 1', text: 'título1.pdf', value: 9, pdf: true },
    { fieldName: 'Título Obtenido 2', text: 'título2.pdf', value: 10, pdf: true },
    { fieldName: 'Título Obtenido 3', text: 'título3.pdf', value: 11, pdf: true },
    { fieldName: 'Certificación No Delincuencia', text: 'certificación-no-delincuencia.pdf', value: 12, pdf: true },
    { fieldName: 'Certificación Médica', text: 'certificación-medica.pdf', value: 13, pdf: true },
    { fieldName: 'Lugar de Residencia', text: 'Los Frailes', value: 14, pdf: false },
    { fieldName: 'Relación Familiar', text: 'N.A', value: 15, pdf: false },
    { fieldName: 'Fecha de Nacimiento', text: '19-07-1986', value: 16, pdf: false },
    { fieldName: 'Horario Disponible', text: '08:00 - 16:00', value: 17, pdf: false },
    { fieldName: 'Habilidades Idiomas', text: 'Ingles - Avanzado', value: 18, pdf: false },
    { fieldName: 'Habilidades Técnicas', text: 'C# - Avanzado', value: 19, pdf: false },

  ];


  mapValidationInformationRows: { fieldName: string, value: number, pdf: boolean }[] = [
    { fieldName: 'Nacionalidad', value: 7, pdf: true },
    { fieldName: 'Nivel Académico', value: 8, pdf: true },
    { fieldName: 'Título Obtenido 1', value: 9, pdf: true },
    { fieldName: 'Título Obtenido 2', value: 10, pdf: true },
    { fieldName: 'Título Obtenido 3', value: 11, pdf: true },
    { fieldName: 'Certificación No Delincuencia', value: 12, pdf: true },
    { fieldName: 'Certificación Médica', value: 13, pdf: true },
    { fieldName: 'Lugar de Residencia', value: 14, pdf: false },
    { fieldName: 'Relación Familiar', value: 15, pdf: false },
    { fieldName: 'Fecha de Nacimiento', value: 16, pdf: false },
    { fieldName: 'Horario Disponible', value: 17, pdf: false },
    { fieldName: 'Habilidades Idiomas', value: 18, pdf: false },
    { fieldName: 'Habilidades Técnicas', value: 19, pdf: false },

  ];
  mapValidationActions: boolean[] = [];

  mapFormCancelationAction: boolean = false;

  contractTime;
  dynamicKeysConfig: {
    displayKey: string;
    placeHolder: string;
    searchOnKey: string;
  }[] = [
      { displayKey: 'companyName', placeHolder: 'Instituciones', searchOnKey: 'companyName' },
      { displayKey: 'description', placeHolder: 'Seleccione una posición', searchOnKey: 'description' },
      // {displayKey:'companyName' , placeHolder: 'Instituciones', searchOnKey:'companyName'},

    ]
  objectionStatus: { key: number, text: string }[] = [
    { key: 0, text: 'EN ESPERA' },
    { key: 1, text: 'APROBADO' },
    { key: 2, text: 'RECHAZADO' },
  ]
  displayKey: string = '';
  placeHolder: string;
  searchOnKey: string;
  isLoading: boolean = true;
  config = {
    displayKey: 'companyName', //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto',
    placeholder: 'Instituciones', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Buscar', // label thats displayed in search input,
    searchOnKey: 'companyName' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }

  employeePositionsConfig = {
    displayKey: 'positionName', //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto',
    placeholder: 'Seleccione una posición', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Buscar', // label thats displayed in search input,
    searchOnKey: 'positionName' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }

  parameterControlConfig = {
    displayKey: 'stringData', //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto',
    placeholder: 'Seleccione una opción', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Buscar', // label thats displayed in search input,
    searchOnKey: 'stringData' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(
    private _http: HttpClient,
    private _excelService: ExportToExcelService,
    private _builder: FormBuilder,
    private _toastService: ToastService,
    private _parameterControlService: ParameterControlService,
    private _cdRef: ChangeDetectorRef,
    private srvDoc: docService,
    private srvPerson: PersonService,
    private authInfo: AuthInfoService
  ) { }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.getMapHeaderList();
    this.getInstitutionsForFilterList();
    this.getDropdownsForForm();
    this.getMapPrincipalDataList();
  }

  mapToggle() {
    var requestSubscription = this._http.get(`${this.hrm_route}/map/${!this.appoved ? 2 : 0}`).subscribe((data: any) => {
      if (data.succeded) {
        this.mapPrincipalDataList = (data.dataList) ? data.dataList : [];
      } else {
        this._toastService.error(data.errors[0]);
      }

    });
    this.subscriptions.add(requestSubscription);
  }

  buildMapValidationForm(selectedItem: ObjetionMap) {
    // this.mapValidation.reset();
    this.mapValidation = this._builder.group({
      validationMapHeaderId: [selectedItem.sendMapId],
      sendMapId: [selectedItem.sendMapId, [Validators.required]],
      noCertification: [0, [Validators.required]],
      departureDate: ['', [Validators.required]],
      dateResponse: ['', [Validators.required]],
      companyId: [this.authInfo.getCompanyId()],
      condition: [0],
      status: [true],
      details: new FormArray([]),
    })


    for (let i = 1; i < 20; i++) {
      this.pushParameters(i);
    }

    this.mapPrincipalDataItem = selectedItem;
    this.path = ''

    this.srvPerson.getPersonById(selectedItem.personId).subscribe(res => {
      if (res.dataList.length > 0) {
        var data = res.dataList[0];
        this.loadingPic = true;
        if (data.profileImage != null && data.profileImage != '') {
          this.srvDoc.getDocument(data.profileImage).subscribe(doc => {
            this.loadingPic = false;
            this.path = doc.data;
          }, error => {
            this.loadingPic = false;
          })
        }
      }
    })
  }
  buildFieldForm(parameterValue: number): FormGroup {
    return new FormGroup({
      validationMapId: new FormControl(0),
      validationMapHeaderId: new FormControl(0),
      parameterId: new FormControl(parameterValue, [Validators.required]),
      approval: new FormControl(null, [Validators.required]),
      reasonId: new FormControl(0, [Validators.required]),
      companyId: new FormControl(0),
      condition: new FormControl(0),
      status: new FormControl(true),

    })
  }

  pushParameters(parameterValue: number) {
    const item = this.mapValidation.get('details') as FormArray;
    item.push(this.buildFieldForm(parameterValue));

  }

  onSubmitValidationForm() {

    var body = this.mapValidation.value;
    body['condition'] = 1;
    body.createUserId = this.authInfo.getUserId();
    
    this.mapPrincipalDataList.find(x => x.sendMapId == body['sendMapId']).condition = 1;
    var array = this.mapValidation.controls['details'].value;

    array.forEach(element => {
      if (element.reasonId['ocode']) {
        let { ocode } = element['reasonId'];
        element['reasonId'] = ocode;
      }
    });

    var sub = this._http.post(`${this.hrm_route}/ValidationMapHeader`, body).subscribe((data) => {
      this._toastService.success('Ha completado la validación', 'Registro exitoso');
    });
    this.subscriptions.add(sub);

    // this.mapValidation.reset();
  }


  buildForm(model: SendMap) {

    this.mapEmployeeSelectedItem = model;
    let bondingType = this.bondingTypeList.find(x => x.ocode == model.bondingType);
    let position = this.employeesPositionsList.find(x => x.employeePositionId == model.positionApprovedId);
    let validyDate = new Date(new Date(model.requestedDate).setMonth(new Date(model.requestedDate).getMonth() + 3)); model.requestedDate
    this.contractTime = this.dateDiff(model.requestedDate, validyDate, 'weeks');
    this.mapForm = new FormGroup({
      sendMapId: new FormControl(model.sendMapId),
      sedMapHeaderId: new FormControl(model.sedMapHeaderId, [Validators.required]),
      postulationId: new FormControl(model.postulationId, [Validators.required]),
      positionApprovedId: new FormControl(position, [Validators.required]),
      salaryRequested: new FormControl(model.salaryRequested, [Validators.required]),
      salaryApproved: new FormControl(model.salaryRequested, [Validators.required]),
      occupationalGroupId: new FormControl(model.occupationalGroupId, [Validators.required]),
      departmentId: new FormControl(model.departmentId, [Validators.required]),
      bondingType: new FormControl(bondingType, [Validators.required]),
      requestedDate: new FormControl(formatDate(new Date(model.requestedDate), 'yyyy-MM-dd', 'en'), [Validators.required]),
      validyDate: new FormControl(formatDate(new Date(validyDate), 'yyyy-MM-dd', 'en'), [Validators.required]),
      observationMap: new FormControl('', [Validators.required]),
      condition: new FormControl(0, [Validators.required]),
      companyId: new FormControl(this.authInfo.getCompanyId(), [Validators.required]),
      status: new FormControl(true, [Validators.required]),
    })

    if (this.mapFormCancelationAction) {
      this.mapForm.controls['bondingType'].disable();
      this.mapForm.controls['positionApprovedId'].disable();
    }
  }

  getUserName(){
    return this.authInfo.getUser().firstName.trim() + ' ' + this.authInfo.getUser().lastName.trim()
  }
  onSubmit(form: FormGroup) {
    var body = form.value;
    body['positionApprovedId'] = form.controls['positionApprovedId'].value['employeePositionId'];
    body['bondingType'] = form.controls['bondingType'].value['ocode'];
    body['condition'] = (this.mapFormCancelationAction) ? 3 : 2;
    body['modifiedByUserName'] = this.getUserName()
    var submitSub = this._http.put(`${this.hrm_route}/sendmap`, body).subscribe((data) => {
      this.mapPrincipalDataList = this.mapPrincipalDataList.filter(x => x.sendMapId != body.sendMapId);
      this._toastService.success(`Se ha completado la aprobación satisfactoriamente.`, 'Registro actualizado')
    });
    this.subscriptions.add(submitSub);
    if (this.mapFormCancelationAction) {
      this.mapDataList.find(x => x.sendMapId == body['sendMapId']).condition = 3;
    } else this.mapDataList.find(x => x.sendMapId == body['sendMapId']).condition = 2;
    this.mapDataList = this.mapDataList.filter(x => x.condition == 0);
    this.mapPrincipalDataList.find(x => x.sendMapId == body.sendMapId).condition = 3;
    //this.mapPrincipalDataList = this.mapPrincipalDataList.filter(x=>x.sendMapId!=body.sendMapId);
  }

  getStatusFromCondition(key: number) {
    return this.objectionStatus.find(x => x.key == key).text
  }

  getTextFromValue(fromApiList, prop: string, key) {
    return fromApiList.find(x => x[prop] == key).stringData;
  }
  getTextFromDescription(fromApiList, prop: string, key) {

    return fromApiList.find(x => x[prop] == key).description;
  }


  getMapHeaderList() {
    var requestSubscription = this._http.get(`${this.hrm_route}/sendmapheader?companyId=${this.authInfo.getCompanyId()}`).subscribe((data: any) => {
      if (data.succeded) {
        this.mapHeaderDataList = (data.dataList) ? data.dataList : [];
      } else {
        this._toastService.error(data.errors[0]);
      }

    });
    this.subscriptions.add(requestSubscription);

  }

  getMapPrincipalDataList() {
    var requestSubscription = this._http.get(`${this.hrm_route}/map/0?companyId=${this.authInfo.getCompanyId()}`).subscribe((data: any) => {
      if (data.succeded) {
        this.mapPrincipalDataList = (data.dataList) ? data.dataList : [];
      } else {
        this._toastService.error(data.errors[0]);
      }
    });
    this.subscriptions.add(requestSubscription);
  }

  getDataList(model) {
    //    this.mapDataList = this.mapDataList.filter(x=>x.idSendMap==model.idSendMap);
    this.buildForm(model)
    var requestSubscription = this._http.get(`${this.hrm_route}/sendmap?2&${model.sendMapId}&companyId=${this.authInfo.getCompanyId()}`, this.httpOptions).subscribe((data: any) => {
      if (data.succeded) {
        this.mapDataList = (data.dataList) ? data.dataList : [];
        this.mapDataList = this.mapDataList.filter(x => x.sendMapId == model.sendMapId);
      } else {
        this._toastService.error(data.errors[0]);
      }
    });
    this.subscriptions.add(requestSubscription);
  }

  getDropdownsForForm() {
    var requestSubscription = this._http.get(`${this.core_route}/positions?companyId=${this.authInfo.getCompanyId()}`).subscribe((data: any) => {
      if (data.succeded) {
        this.employeesPositionsList = data.dataList
      } else {
        this._toastService.error(data.errors[0]);
      }

    });
    this.subscriptions.add(requestSubscription);
    var parameterControlSUbscription = this._parameterControlService.getParameters(keyWord.TypeContractApproved).subscribe((data) => {
      if (data.succeded) {
        this.bondingTypeList = data.dataList;
      } else {
        this._toastService.error(data.errors[0]);
      }

    });
    this.subscriptions.add(parameterControlSUbscription);

    var rejectReasonsSubscription = this._parameterControlService.getParameters(keyWord.reasonRejection).subscribe((data) => {
      if (data.succeded) {
        this.rejectReasonsList = data.dataList;
      } else {
        this._toastService.error(data.errors[0]);
      }
    });
    this.subscriptions.add(rejectReasonsSubscription);
  }

  filter(fromApiList, listMatchKey: string, keyword: string) {
    return fromApiList.filter(x => x[listMatchKey] == keyword);
  }
  getInstitutionsForFilterList() {
    var requestSubscription = this._http.get(`${this.core_route}/company`).subscribe((data: any) => {
      if (data.succeded) {
        this.mapInstitutionsList = (data.dataList) ? data.dataList : [];
      } else {
        this._toastService.error(data.errors[0]);
      }
    });
    this.subscriptions.add(requestSubscription);
  }

  dateDiff(firstDate, secondDate, interval: string) {
    var second = 1000, minute = second * 60, hour = minute * 60, day = hour * 24, week = day * 7;
    firstDate = new Date(firstDate);
    secondDate = new Date(secondDate);
    var timediff = secondDate - firstDate;
    if (isNaN(timediff)) return NaN;
    else if (moment(firstDate) >= moment(secondDate)) {
      this._toastService.error('La fecha de inicio debe ser menor a la fecha de finalización');
    }
    else {

      switch (interval) {
        case "years": return `${secondDate.getFullYear() - firstDate.getFullYear()} años`;
        case "months": return `${(
          (secondDate.getFullYear() * 12 + secondDate.getMonth())
          -
          (firstDate.getFullYear() * 12 + firstDate.getMonth())
        )} meses`;
        case "weeks": return `${Math.floor(timediff / week)} semanas`;
        case "days": return `${Math.floor(timediff / day)} días`;
        default: return undefined;
      }
    }
  }

  getPaginatedRecords(event) {
    this.records = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
}
