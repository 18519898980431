import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { IDocumentFileView, IEmployee, ITeacherContractPost } from 'src/app/models/Employee.model';
import { ModalViewsEnum } from 'src/app/models/modal-views';
import { ShowFileModalComponent } from 'src/app/pages/performance-evaluation/list-performance-evaluation/detail-performance-evaluation/performance-agreement/qualify-performance-agreement/show-file-modal/show-file-modal.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { ShowDocumentModalComponent } from 'src/app/shared/show-document-modal/show-document-modal.component';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';

type GUID = string & { isGuid: true };
@Component({
  selector: 'app-add-teacher-contract',
  templateUrl: './add-teacher-contract.component.html',
  styleUrls: ['./add-teacher-contract.component.css']
})
export class AddTeacherContractComponent implements OnInit {

  formSubmitted = false;

  title: string = (this.data.viewType == ModalViewsEnum.Create) ? 'Agregar' : (this.data.viewType == ModalViewsEnum.Edit) ? 'Editar' : '';

  viewTypes = ModalViewsEnum;
  startDateInvalid = false
  admissionDate = undefined

  pricePerHour:any;
  startOfContract:any;
  endOfContract:any;


  contractForm = new FormGroup({
    teacherContractId: new FormControl(0, Validators.required),
    employeeId: new FormControl(null, Validators.required),
    startDate: new FormControl(null, Validators.required),
    endDate: new FormControl(null, Validators.required),
    pricePerHour: new FormControl(null, Validators.required),
    companyId: new FormControl(this.authInfo.getCompanyId(), Validators.required),
    status: new FormControl(true),
    createUserId: new FormControl(this.authInfo.getUserId(), Validators.required),
    modifyUserId: new FormControl(this.authInfo.getUserId(), Validators.required),
    teacherDocuments: new FormArray([])
  })

  guiID = '';
  file = { document: '' }
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  personalFile = personalFile;

  editMode = false

  constructor(public dialogRef: MatDialogRef<AddTeacherContractComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {viewType: number, model:any, admissionDate: any},
    private authInfo: AuthInfoService,
    private employeeService: EmployeesService,
    private toastService: ToastrService,
    private parameterService: ParameterControlService,
    private sanitizer: DomSanitizer,
    private srvDoc: docService,
    private datePipe: DatePipe) {

      if(data.model){
        this.contractForm.patchValue(this.data.model);
        this.contractForm.get('createUserId').setValue(this.authInfo.getUserId())
        this.contractForm.get('modifyUserId').setValue(this.authInfo.getUserId())
      }
      switch (data.viewType) {
        case ModalViewsEnum.Create:
          this.loadDocuments();
          break;
        case ModalViewsEnum.Edit:
          this.editMode = true
          this.onViewLogic()
          break;
        case ModalViewsEnum.View:
          this.onViewLogic()
          break;
      }

      var startDate  = localStorage.getItem('startDateAddContract');
      var endDate  = localStorage.getItem('endDateAddContract');
      if(startDate && endDate && this.editMode == false){
        this.contractForm.get('startDate').setValue(startDate)
        this.contractForm.get('endDate').setValue(endDate)

      }


    }

  ngOnInit(): void {
    if(this.editMode == true){
      this.admissionDate = moment(this.data.admissionDate).format("yyyy-MM-DD")
    }else{
      this.admissionDate = moment(this.data.model.admissionDate).format("yyyy-MM-DD")
    }
    this.getParameterRoutes();
    this.getContractAdd()
  }

  onViewLogic(){
    this.contractForm.disable();
    if(this.editMode == true){
      this.contractForm.enable();
    }
    this.loadPreviousDocuments()

    this.contractForm.get('startDate').setValue(this.datePipe.transform(this.data.model.startDate,'yyyy-MM-dd'))
    this.contractForm.get('endDate').setValue(this.datePipe.transform(this.data.model.endDate,'yyyy-MM-dd'))
  }

  private getParameterRoutes() {
    this.parameterService.getParameters(parameterDoc.PathTest).subscribe({
      next: (res) =>{
        if(!res.succeded){
          res.errors.forEach(err=>{
            this.toastService.error(err);
          });

          res.warnings.forEach(err=>{
            this.toastService.warning(err);
          });

          return;
        }

        this.routeFile = res.dataList[0].stringData.toString();

      },
      error: (err) =>{
        this.toastService.error('Ha ocurrido un error tratando de consultar la lista de parametros.');
      }
    });
  }

  getContractAdd(){
    this.employeeService.contractAdd(this.data?.model?.employeeId).subscribe({
      next:(resp:any)=>{
        if(!resp.succeded){
          resp.errors.forEach(err=>{
            this.toastService.error(err);
          });

          resp.warnings.forEach(err=>{
            this.toastService.warning(err);
          });

          return;
        }

        const data = resp.dataList[0]

        this.contractForm.get('pricePerHour').patchValue(data?.pricePerHour)
        // this.startOfContract =  formatDate(data?.startOfContract, 'yyyy-MM-dd', 'en' )
        // this.contractForm.get('startDate').patchValue(this.startOfContract)
        // this.endOfContract = formatDate(data?.endOfContract, 'yyyy-MM-dd', 'en' )
        // this.contractForm.get('endDate').patchValue(this.endOfContract)
      },
      error: (err) =>{
        this.toastService.error('Ha ocurrido un error tratando de consultar el ultimo pricio por hora.');
      }
    })
  }

  loadDocuments(){
    this.employeeService.getDocumentFiles(this.authInfo.getCompanyId()).subscribe({
      next: (res) =>{
        if(!res.succeded){
          res.errors.forEach(err=>{
            this.toastService.error(err);
          });

          res.warnings.forEach(err=>{
            this.toastService.warning(err);
          });

          return;
        }

        res.dataList.forEach(document =>{
          this.addDocument(document);
        })

      },
      error: (err) =>{
        this.toastService.error('Ha ocurrido un error tratando de consultar la lista de documentos.');
      }
    });
  }

  private loadPreviousDocuments(){
    this.employeeService.getTeacherDocuments(this.data.model.teacherContractId, this.authInfo.getCompanyId()).subscribe({
      next: (res) =>{
        if(!res.succeded){
          res.errors.forEach(err=>{
            this.toastService.error(err);
          });

          res.warnings.forEach(err=>{
            this.toastService.warning(err);
          });

          return;
        }

        res.dataList.forEach(document =>{
          this.addDocument(document);
        })

      },
      error: (err) =>{
        this.toastService.error('Ha ocurrido un error tratando de consultar la lista de documentos.');
      }
    });
  }

  private addDocument(model: IDocumentFileView = null){

    const document = new FormGroup({
      teacherDocumentId: new FormControl(0, Validators.required),
      teacherContractId: new FormControl(0),
      documentFileId: new FormControl(null, Validators.required),
      document: new FormControl(null,Validators.required),
      description: new FormControl(null),
      isRequired: new FormControl(true),
      companyId: new FormControl(this.data?.model?.companyId, Validators.required),
      status: new FormControl(true),
      createUserId: new FormControl(this.authInfo.getUserId(), Validators.required),
      modifyUserId: new FormControl(this.authInfo.getUserId(), Validators.required)
    });

    switch (this.data.viewType) {
      case ModalViewsEnum.Create:
        break;
      case ModalViewsEnum.Edit:
        document.disable();
        break;
      case ModalViewsEnum.View:
        document.disable();
        break;
    }

    if(model){
      document.patchValue(model);
      document.get('createUserId').setValue(this.authInfo.getUserId());
      document.get('modifyUserId').setValue(this.authInfo.getUserId());

      if(model.isRequired == false){
        document.controls['document'].setErrors(null);
        document.controls['document'].clearValidators();
        document.controls['document'].updateValueAndValidity();
      }
    }


    this.getDocumentsForm().push(document);
  }

  getDocumentsForm(): FormArray{
    return this.contractForm.get('teacherDocuments') as FormArray
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  manageFiles(event, index) {
    let file = event.target.files[0];
    let data = {
      SystemLoad: this.SystemLoad,
      ModuleLoad: this.ModuleLoad,
      personalFile: this.personalFile.photo2x2,
      routeFile: this.routeFile
    }

    let formData = new FormData
    formData.append(file.name, file)

    this.srvDoc.createFile(data, formData).subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })
          res.warnings.forEach(err => {
            this.toastService.warning(err);
          })
          return;
        }
        let resData = res?.data as FileResponse
        this.guiID = this.getGuid(resData.fileUnit.guidname)
        this.getDocumentsForm()?.controls[index]?.get('document')?.setValue(this.guiID ? this.guiID : null)
      },
      error: (err) => {
        this.toastService.error('Ha ocurrido un error tratando de subir el archivo');
      }
    })
  }

  deleteFile(index) {
    let document = this.getDocumentsForm()?.controls[index];
    if (!document?.get('document')?.value) {
      return;
    }

    this.srvDoc.deleteFile(document?.get('document')?.value).subscribe(e => {
      this.getDocumentsForm()?.controls[index]?.get('document')?.setValue(null);

    }, error => {
      this.toastService.error(error)
    })
  }

  openEvidence(guid) {
    this.srvDoc.getDocument(guid).subscribe(e => {
      if(!e?.data){
        this.toastService.warning('No se encontró el documento');
        return;
      }
      this.openDocument({src: e?.data, title: 'Documento'});
    }, error => {
      this.toastService.error("Ocurrió un error inesperado tratando de buscar el documento");
    })

  }

  private openDocument(data: {src: string, title: string}){
    this.dialog.open(ShowDocumentModalComponent, {
      data: data,
      width: '70%',
    });
  }

  update(){

    this.formSubmitted = true
    const admissionDate = moment(this.admissionDate).startOf('date')
    const startDate = moment(this.contractForm.get('startDate').value).startOf('date')
    this.startDateInvalid = admissionDate > startDate
    if(this.startDateInvalid) return

    if(this.contractForm.invalid){
      this.toastService.warning('Debe llenar los campos obligatorios');
      return;
    }

    let model: ITeacherContractPost = this.contractForm.getRawValue();

    model.teacherDocuments.forEach(x=>{
      if(!x.document){
        x.document = undefined;
      }
    });
    model.teacherContractId = this.data.model.teacherContractId
    model.modifyUserId = this.authInfo.getUserId()
    model.modifyDate = moment().toDate()

    this.employeeService.updateTeacherContract(model).subscribe({
      next: (res) =>{
        if(!res.succeded){
          res.errors.forEach(err=>{
            this.toastService.error(err);
          });

          res.warnings.forEach(err=>{
            this.toastService.warning(err);
          });

          return;
        }

        this.toastService.success('Datos guardados.');
        this.dialogRef.close(true);

      },
      error: (err) =>{
        this.toastService.error('Ha ocurrido un error tratando de insertar el contrato.');
      }
    });
  }

  submit(){
    if(this.editMode == true){
      this.update()
    }else{
      localStorage.setItem('startDateAddContract', this.contractForm.get('startDate').value);
      localStorage.setItem('endDateAddContract', this.contractForm.get('endDate').value);
      this.save()
    }
  }

  save(){
    this.formSubmitted = true
    const admissionDate = moment(this.admissionDate).startOf('date')
    const startDate = moment(this.contractForm.get('startDate').value).startOf('date')
    this.startDateInvalid = admissionDate > startDate
    if(this.startDateInvalid) return

    if(this.contractForm.invalid){
      this.toastService.warning('Debe llenar los campos obligatorios');
      return;
    }

    let model: ITeacherContractPost = this.contractForm.getRawValue();
    model.modifyDate = null
    model.modifyUserId = null
    model.teacherDocuments.forEach(x=>{
      if(!x.document){
        x.document = undefined;
      }
    });

    this.employeeService.insertTeacherContract(model).subscribe({
      next: (res) =>{
        if(!res.succeded){
          res.errors.forEach(err=>{
            this.toastService.error(err);
          });

          res.warnings.forEach(err=>{
            this.toastService.warning(err);
          });

          return;
        }

        this.toastService.success('Contrato agregado satisfactoriamente');
        this.dialogRef.close(true);

      },
      error: (err) =>{
        this.toastService.error('Ha ocurrido un error tratando de insertar el contrato.');
      }
    });
  }

}
