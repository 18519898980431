import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplyVacancyModel } from 'src/app/pages/personal-requisition/models/apply-vacancy-model';

@Component({
  selector: 'app-salary-range',
  templateUrl: './salary-range.component.html',
  styleUrls: ['./salary-range.component.css']
})
export class SalaryRangeComponent {
  vacancyModel = new ApplyVacancyModel();
  IsviewMode: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<SalaryRangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { vacancyModel: ApplyVacancyModel, IsviewMode: boolean }) {
    this.IsviewMode = this.data.IsviewMode;
    this.vacancyModel = data?.vacancyModel;
  }

  selectSalaryRange(salary: number) {
    if(!this.IsviewMode){
      this.dialogRef.close({
        Applied: true,
        salary: salary,
      });
    }
   
  }

}