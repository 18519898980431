import { SweetAlert } from '../../../shared/utils/Sweet-Alert';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { paymentFlyer } from './models/mock-data';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { approvedPayroll, Payroll } from '../open-payroll/models/mock-data';
import Swal from 'sweetalert2'
import { MatDialog } from '@angular/material/dialog';
import { SearchEmployeeComponent } from '@payroll/action-edit/search-employee/search-employee.component';
import { IEmployee } from '../../job-offer/models/Employee';
import { PayrollFlyerService } from 'src/app/services/payroll-flyer.service';
import { PayrollFlyerGetTypes } from './models/payroll-flyer-get-type.enum';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { RequestMetaData, ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { PayrollFlyer } from './models/payroll-flyer.model';
import { PaymentVoucherComponent } from './payment-voucher/payment-voucher.component';
import { OperationResult } from 'src/app/shared/models/operation-result.enum';
import { PayrollPeriodService } from 'src/app/services/payroll-period.service';
import { Period } from '@payroll/schedule/models/period.model';
import { PayrollVoucher } from './models/payroll-voucher.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { formatDate } from '@angular/common';
import { PayrollService } from 'src/app/services/payroll.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollFlyerParams, PayrollFlyerParamsMail } from '@payroll/payment-flyer/models/payroll-params.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ResultPayrollVoucherMailComponent } from './result-payroll-voucher-mail/result-payroll-voucher-mail.component';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { Router } from '@angular/router';
import { NgxSelectDropdownComponent } from 'ngx-select-dropdown';
import { CompaniesService } from 'src/app/services/companies.service';
import { docService } from 'src/app/services/doc.service';
import { PaymentVoucherVersion2Component } from './payment-voucher-version2/payment-voucher-version2.component';
import { catchError, finalize, map } from 'rxjs/operators';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { openReport } from 'src/app/shared/utils/utility';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'payment-flyer',
  templateUrl: './payment-flyer.component.html',
  styleUrls: ['./payment-flyer.component.scss']
})
export class PaymentFlyerComponent implements OnInit {


  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PAYMENT-FLYER',
    sendAction: { key: 'SENDFLYER', value: false },
    exportAction: { key: 'EXPORTFLYER', value: false },
    searchAction: { key: 'SEARCHFLYER', value: false },
  }

  sweetAlert = new SweetAlert();
  @ViewChild('paginator') paginator: CaptionComponent;
  headers = [
    { label: 'Tipo de Nómina', key: 'payrollType' },
    { label: 'Tipo de Servidor Público', key: 'employeeType' },
    { label: 'Área Geográfica', key: 'area' },
    { label: 'Estado', key: 'status' },
    { label: 'Fecha Inicio', key: 'startDate' },
    { label: 'Fecha Fin', key: 'endDate' },
    { label: 'Fecha Pago', key: 'payDate' },
    { label: 'Año', key: 'year' }
  ];
  periods: { description: string, periodId: number, condition: number, createDate: Date }[] = [];
  filteredPeriods: { description: string, periodId: number, condition: number }[] = [];
  sharedModalTitle = 'Volante de pago'
  activeButtons = [];
  public advanceFilter: any;
  public formAdvanceFilter: any
  payrollVersions: any[] = [];
  paginatedPaymentFlyers: any[] = [];
  selectedItems: any;
  yearsList: any[] = []
  filterAvanced: any
  filterText: string = '';
  filteredList: { records: any[], enable: boolean, parameterValue?: any } = { records: [], enable: false, parameterValue: {} };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "name", displayName: "Servidor Público" },
      { propkey: "personalIdentification", displayName: "Documento de Identidad" },
      { propkey: "payrollType", displayName: "Tipo de nómina" },
      { propkey: "positionName", displayName: "Cargo" },
      { propkey: "departmentName", displayName: "Unidad Organizativa" },
      { propkey: "advancedsearch", displayName: "Búsqueda Avanzada" },
    ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' }
  ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  payFlyerButton = [
    {
      function: (_) => {
        this.showModal('openflyers')
      },
      color: '',
      icon: 'flaticon-factura',
      tooltip: 'Imprimir Volante',
      color2: 'btn-light-info'
    }
  ];

  employeeSelected: IEmployee;
  payrollRecords: Payroll[] = approvedPayroll
  records: Payroll[] = [];
  selectedPeriod: any;
  payrollFlyerDetails: PayrollFlyer[] = [];
  payrollFlyerDetailsOriginal: PayrollFlyer[] = [];
  subscriptions: Subscription = new Subscription();
  selectedFlyer: PayrollFlyer;
  payrollVoucherData: any = {};

  budgetAreaList: any[] = [];
  payrollTypeList: any[] = [];

  selectedArea: any;
  selectedPayrollType: any;

  sendItems: PayrollFlyer[] = [];

  allItemsSelected: boolean;
  advancedFilterActive: boolean;

  @ViewChild('dropdownPeriod') dropdownPeriod: NgxSelectDropdownComponent;
  @ViewChild('dropdownBudgetArea') dropdownBudgetArea: NgxSelectDropdownComponent;
  @ViewChild('dropdownPayrollType') dropdownPayrollType: NgxSelectDropdownComponent;
  dropdownVersion

  private logoUrl: string = '';
  private company: any;

  constructor(
    private _exlService: ExportToExcelService,
    private _dialog: MatDialog,
    private _payrollFlyerSvc: PayrollFlyerService,
    private _periodSvc: PayrollPeriodService,
    private _toastr: ToastService,
    private _companiesService: CompaniesService,
    private srvDoc: docService,
    private payrollService: PayrollService,
    private _parameterService: ParameterControlService,
    public authInfo: AuthInfoService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.privilege = this.authInfo.setPrivileges(this.privilege);
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        // years.map(x => this.yearsRange.push({value: x, text: x.toString() }))
        this.companyById(this.authInfo?.getCompanyId());
        this.records = this.payrollRecords.filter(x => x.statusId === 1);
        this.activeButtons = this.payFlyerButton;
        if (localStorage?.getItem('paymentFlyersPrintData')) {
          const printData = JSON.parse(localStorage?.getItem('paymentFlyersPrintData'));
          localStorage.removeItem('paymentFlyersPrintData');
          if (printData?.returned) {
            this.setPrintData(printData);
          } else {
            this.getPayrollClosedPeriods();
          }
        } else {
          this.getPayrollClosedPeriods();
        }
      }
    });
    applySort();
  }

  private setPrintData(printData) {
    this.payrollFlyerDetails = printData?.dataGrid?.payrollFlyerDetails;
    this.periods = printData?.dataGrid?.periods;
    this.budgetAreaList = printData?.dataGrid?.budgetAreaList;
    this.payrollTypeList = printData?.dataGrid?.payrollTypeList;
    this.selectedPayrollType = printData?.dataGrid?.selectedPayrollType;
    this.selectedArea = printData?.arguments?.selectedArea;
    this.selectedPeriod = printData.arguments?.selectedPeriod;
    this.sendItems = printData?.data;
    this.allItemsSelected = printData?.dataGrid?.allItemsSelected;
    this.dropdownPeriod.value = printData?.arguments?.selectedPeriod;
    this.dropdownBudgetArea.value = printData?.arguments?.selectedArea;
    this.dropdownPayrollType.value = printData?.dataGrid?.selectedPayrollType;
  }

  private companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      if (data.succeded) {
        this.company = data?.dataList[0];

      }
    })
  }

  private getImageUrl(imageGUID: string) {
    return this.srvDoc.getDocument(imageGUID);
  }

  showModal(modalName: string) {
    $(`#${modalName}`).modal('show');
  }

  search(search: string) {

  }

  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }
  getPayrollVersions() {
    this.dropdownVersion = null
    const payrollPeriodId = this.selectedPeriod.periodId;
    const payrollTypeId = this.selectedPayrollType.ocode;
    const budgetAreaId = this.selectedArea.budgetAreaId;
    this.payrollVersions = [];
    if (payrollPeriodId === 0 || budgetAreaId === 0 || (payrollTypeId === null || payrollTypeId === undefined)) {
      return;
    }
    this.payrollService.getPayrollVersions(payrollPeriodId, payrollTypeId, budgetAreaId).subscribe((res: any) => {
      if (res?.errors?.length > 0) {
        this._toastr.error(res.errors[0]);
        return;
      }
      this.payrollVersions = res?.dataList?.map(v => {
        return {
          ...v,
          versionDescription: `${v?.headerId} - ${v?.versionId} - ${v?.headerCondition}`
        }
      });
    }, error => {

    })
  }
  filter() {
    document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.payrollFlyerDetails = this.filterTable(this.payrollFlyerDetails)
    }
    this.setFilterToLocalStorage();
  }

  getPayrollClosedPeriods() {
    let sub = this._periodSvc.getAll(
      {
        value: 0,
        type: 5
      }).subscribe((response: ResponseModel<Period>) => {
        if (response.succeded) {
          this.periods = response.dataList.filter(period => period.status).map((period: any) => {
            return {
              description: period.description,
              periodId: period.payrollPeriodId,
              condition: period.conditionId,
              createDate: period.createDate,
              startDate: period.startDate,
            }
          });
          this.filteredPeriods = this.periods
          let periodYears: any[] = []
          let years = this.periods.map((period: any) => {
            let periodYear = new Date(`${period.startDate}`).getFullYear()
            if (!periodYears.includes(periodYear)) {
              periodYears.push(periodYear)
            }
            return (periodYears)
          })

          this.yearsList = years[0].reverse()
        }
      });

    this.subscriptions.add(sub);
    this.getData();
  }

  getData() {
    let dub = this.payrollService.getBudgetAreas().subscribe((res: any) => {
      this.budgetAreaList = res.dataList.filter(ba => ba.status == true)
    })

    let mub = this._parameterService.getParameters('PayrollType').subscribe((res: any) => {
      this.payrollTypeList = res.dataList
    })

    this.subscriptions.add(dub);
    this.subscriptions.add(mub);
  }



  filterTable(list: any[]) {
    // let sl = this.selectedItems;
    // if (sl.find(x => x.item_id == 1) && !sl.find(x => x.item_id == 2)) {
    //   list = list.filter(x => x.status == true);
    // }
    // if (sl.find(x => x.item_id == 2) && !sl.find(x => x.item_id == 1)) {
    //   list = list.filter(x => x.status == false);
    // }
    return list;
  }

  changeSelectedPeriod(event) {
    this.selectedPeriod = event.value;
  }

  changeSelectedPayrollType(event) {
    this.selectedPayrollType = event.value;
    this.getPayrollVersions()
  }

  changeSelectedArea(event) {
    this.selectedArea = event.value;
  }

  changeYear(event) {
    this.filteredPeriods = this.periods.filter((period: any) => period.description.includes(`${event.value}`))
  }

  getVoucherData(): Observable<ResponseModel<PayrollVoucher>> {
    const paymentFlyer = {
      paymentId: '',
      paymentType: '',
      headerId: this.selectedFlyer?.headerId,
      payrollPeriodId: this.selectedPeriod?.payrollPeriodId,
      budgetAreaId: this.selectedArea?.budgetAreaId,
      employee: [{
        employeeId: this.selectedFlyer?.employeeId
      }],
      type: 0,
      companyId: this.selectedFlyer?.companyId,
      status: true
    }
    return this._payrollFlyerSvc.getPaymentFlyer(paymentFlyer);
  }

  sendVoucherData() {

  }

  selectAllItems() {
    this.sendItems = [];

    if (this.filteredList.records.length > 0) {
      this.filteredList.records.forEach((element) => {
        element['isSelected'] = this.allItemsSelected;
        if (this.allItemsSelected) {
          this.sendItems.push(element);
        }
      });
    } else {
      this.payrollFlyerDetails.forEach((element) => {
        element['isSelected'] = this.allItemsSelected;
        if (this.allItemsSelected) {
          this.sendItems.push(element);
        }
      });
    }
  }

  selectItem(item) {
    if (this.sendItems?.some(s => s?.employeeId === item?.employeeId)) {
      this.sendItems = this.sendItems?.filter(s => s?.employeeId !== item?.employeeId);
    } else {
      this.sendItems.push(item)
    }
  }

  loadPaymentFlyerList() {

    if (!this.selectedPeriod || this.selectedPeriod.length == 0) {

      this._toastr.warning('Primero debe seleccionar el período de la nómina.', 'Aviso')
      return;
    }





    let sub = this._payrollFlyerSvc.getPayrollFlyers(
      {
        payrollPeriodId: this.selectedPeriod.periodId,
        //value: this.employeeSelected.employeeId,
        advanceFilter: this.advanceFilter,
        type: 14,
      }
    )
      .subscribe((response: ResponseModel<PayrollFlyer>) => {
        if (response.succeded) {
          if (response.dataList.length == 0) {
            this._toastr.warning('No se encontraron las nóminas a las que pertenece este Servidor Público');
            this.payrollFlyerDetails = [];
            return;
          }

          response.dataList.forEach(element => {
            element['isSelected'] = false;
          })

          this.payrollFlyerDetails = response.dataList.map(d => {
            return {
              ...d,
              fullName: `${d.firstName} ${d.lastName}`
            }
          });
          this.payrollFlyerDetailsOriginal = [...this.payrollFlyerDetails]
        }
      }, err => {
      });

    this.subscriptions.add(sub);

  }

  openModalSearchEmployee(item: any, IsView = false) {
    if (!this.selectedPeriod || this.selectedPeriod.length == 0) {

      this._toastr.warning('Primero debe seleccionar el período de la nómina.', 'Aviso')
      return;
    }
    let sub = this._dialog.open(SearchEmployeeComponent, {
      data: {
        item: item,
        IsView: IsView,
      },
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.data !== undefined) {
          this.employeeSelected = { ...result.data };

          this._payrollFlyerSvc.getEmployeePayrollFlyer(
            {
              payrollPeriodId: this.selectedPeriod.periodId,
              value: this.employeeSelected.employeeId,
              type: PayrollFlyerGetTypes.ByPeriodAndEmployeeId
            }
          )
            .subscribe((response: ResponseModel<PayrollFlyer>) => {
              if (response.succeded) {
                if (response.dataList.length == 0) {
                  this._toastr.warning('No se encontraron las nóminas a las que pertenece este Servidor Público');
                  this.payrollFlyerDetails = [];
                  return;
                }
                this.payrollFlyerDetails = response.dataList;
              }
            });
          // this.getPersonByIdentification(this.employeeSelected.personalIdentification);

        }
      });

    this.subscriptions.add(sub);
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }
  setFilterToLocalStorage() {
    localStorage.setItem('payment-flyer-list_filter', JSON.stringify(this.selectedItems));
  }

  exportToExcel() {
    let excelHeaders: string[][] = [[
      "Secuencia de Cabecera",
      "Secuencia de Período de Nómina",
      "Secuencia de Presupuesto de Área",
      "Secuencia de Tipo de Nómina",
      "Secuencia de Servidor Público",
      "Primer Nombre",
      "Primer Apellido",
      "Secuencia de Género",
      "Género",
      "Secuencia de Posición",
      "Posición",
      "Secuencia de Unidad Organizativa",
      "Unidad Organizativa",
      "Secuencia de Tipo de Servidor Público",
      "Tipo de Servidor Público",
      "Fecha de Admisión",
      "Fecha de Expiración de Nombramiento",
      "Salario Bruto",
      "Salario Total",
      "AFP",
      "ISR",
      "SFS",
      "Otros Descuentos",
      "Total descontado",
      "Ingreso Neto",
      "Secuencia de Companía",
      "Razón Social",
      "Estatus",
      "Secuencia de Condición",
      "Área Presupuestaria"
    ]]
    this._exlService.exportToExcelSpecificColumns(this.filteredList.enable ? this.filteredList.records : this.payrollFlyerDetails, excelHeaders, `Volantes de pago de ${this.payrollFlyerDetails[0].firstName} ${this.payrollFlyerDetails[0].lastName}`);
  }

  //#region Interacting with the Modal

  openDialog(model?: PayrollFlyer): void {
    this.selectedFlyer = model;
    let sub = this.getVoucherData().subscribe((response: ResponseModel<PayrollVoucher>) => {
      if (response.succeded) {
        if (response.dataList.length > 0) {
          this.payrollVoucherData = response.dataList[0];

          let paymentFlyerImpresionMetadata = {
            paymentFlyerPrintId: 0,
            employeeId: response.dataList[0]?.employee?.employeeId,
            headerId: this.selectedFlyer.headerId,
            createDate: new Date(),
            createUser: this.authInfo.getUserId()
          }

          this._payrollFlyerSvc.registeringPaymentFlyerPrinted(paymentFlyerImpresionMetadata)
            .subscribe((response: RequestMetaData) => {
              if (response.succeded) {
                // this._toastr.success(
                //   `Última impresión: ${formatDate(new Date(paymentFlyerImpresionMetadata.createDate), 'dd/MM/yyyy HH:mm:ss', 'en-US')}`)

              }
            });
          return response.dataList;


        } else {
          this._toastr.error("No se encontraron datos para imprimir el volante", "Error inesperado");
        }
      }
    }, error => { }, () => {

      const dialogRef = this._dialog.open(PaymentVoucherVersion2Component, {
        width: '100%',
        //height: '70%',
        panelClass: '',
        data: {
          model: model ? model : null,
          voucher: this.payrollVoucherData ? this.payrollVoucherData : null,
          selectedPeriod: this.selectedPeriod
        },
        disableClose: true
      });
      let sub = dialogRef.afterClosed().subscribe((result: { status: OperationResult }) => {
      });
      this.subscriptions.add(sub);

    });
    this.subscriptions.add(sub);
  }
  //#endregion

  getFilteredList(event) {
    this.filteredList = event;
    if (this.filteredList?.parameterValue?.propkey == "advancedsearch") {
      this.advancedSearch();
      this.advancedFilterActive = true;
      return
    }
    else if (this.filteredList?.parameterValue?.propkey != "advancedsearch" && this.advancedFilterActive) {
      this.advancedFilterActive = false;
      this.advanceFilter = ''
      this.loadPaymentFlyerList();
      return
    }
    this.paginator.selectedPage = 1;
    this.filteredList.records = this.filterTable(event.records)
    if (!this.allItemsSelected && this.sendItems.length == 0) {
      this.selectAllItems();
    }
  }

  getPaginatedRecords(event) {
    this.paginatedPaymentFlyers = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  sendMail() {

    Swal.fire({
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      title: "¿Seguro/a enviar este comproboante?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      icon: 'question',
      confirmButtonColor: '#15c229'
    }).then((result) => {
      if (result.isConfirmed) {
        this.getImageUrl(this.company?.logo).pipe(
          finalize(() => {
            this.completeSendMail();
          })
        ).subscribe({
          next: (e) => {
            this.logoUrl = e?.data
          }
        })
      }
    })


 



  }

  private async completeSendMail() {
    let payrollVoucher: PayrollFlyerParamsMail[] = []

    const $requests = this.sendItems.map(element => {

      if (!element?.employeeProfileImage) {
        const item = this.buildEmailParamItem(element);
        payrollVoucher.push(item)
        return undefined;
      }

      return this.getImageUrl(element?.employeeProfileImage)
        .pipe(map(value => ({ type: element, value: value })))
        .pipe(catchError(() => of(() => {
          const item = this.buildEmailParamItem(element);
          payrollVoucher.push(item)
        })));

    }).filter(Boolean); //this filter avoids undefined or null positions in the array due to the "map" function

    if ($requests.length == 0) {
      this.completeSendPayrollVoucher(payrollVoucher);
      return;
    }

    forkJoin($requests).subscribe((responses: { type: any, value: any }[]) => {
      responses?.forEach(element => {
        const item = this.buildEmailParamItem(element?.type, element?.value?.data);
        payrollVoucher.push(item)
      })

      this.completeSendPayrollVoucher(payrollVoucher);
    });
  }

  private completeSendPayrollVoucher(payrollVoucher: PayrollFlyerParamsMail[]) {
    this._payrollFlyerSvc.postEmployeePayrollVoucherMail(payrollVoucher).subscribe(
      {
        next: (res: any) => {
          if (!res.succeded) {

            res.errors.forEach(err => {
              this._toastr.error(err);
            });

            res.warnings.forEach(warn => {
              this._toastr.warning(warn);
            });

            return;
          }

          this.openModalResulPayrollVoucherMail(res.dataList);
        }, error: (err) => {
          this._toastr.error("Ha ocurrido un error tratando de realizar el envío del comprobante de pago");
        }
      });
  }

  private buildEmailParamItem(element: PayrollFlyer, employeeImage?: string): PayrollFlyerParamsMail {
    const item = {
      headerId: element?.headerId,
      employeeId: element?.employeeId,
      type: PayrollFlyerGetTypes.ByHeaderAndEmployeeId,
      companyId: element.companyId ?? this.authInfo.getCompanyId(),
      status: element.status ?? true,
      budgetAreaId: element?.budgetAreaId ?? 0,
      payrollTypeId: element?.payrollTypeId ?? 0,
      payrollPeriodId: element?.payrollPeriodId,
      payrollArea: this.selectedArea?.description,
      payrollPeriod: this.selectedPeriod?.description,
      companyLogoUrl: this.logoUrl,
      userImageUrl: employeeImage,
      sentByUserId: this.authInfo.getUserId()
    } as PayrollFlyerParamsMail

    return item;
  }

  private openModalResulPayrollVoucherMail(item) {
    this._dialog.open(ResultPayrollVoucherMailComponent, {
      data: item,
      width: '60%'
    })
      .afterClosed()
      .subscribe((result) => {
        this.selectAllItems();
      });
  }

  advancedSearch() {
    this._dialog.open(AdvancedSearchComponent, {
      width: '50%',
      data: {
        periodId: this.selectedPeriod.periodId,
        advanceFilter: this.formAdvanceFilter
      }
    })
      .afterClosed()
      .subscribe((result) => {
        this.formAdvanceFilter = result.formBd
        this.filterAvanced = Object.values(result.advanceFilter).join(",")
        this.advanceFilter = this.filterAvanced;
        this.loadPaymentFlyerList()
        console.log(this.advanceFilter)

      });
  }

  printAllFlyer() {
    if (this.sendItems.length === 0) {
      return;
    }
    const printData = {
      data: this.sendItems,
      arguments: {
        selectedArea: this.selectedArea,
        selectedPeriod: this.selectedPeriod
      },
      dataGrid: {
        payrollFlyerDetails: this.payrollFlyerDetails,
        periods: this.periods,
        budgetAreaList: this.budgetAreaList,
        payrollTypeList: this.payrollTypeList,
        selectedPayrollType: this.selectedPayrollType,
        allItemsSelected: this.allItemsSelected
      },
    };
    localStorage.setItem('paymentFlyersPrintData', JSON.stringify(printData));
    this.router.navigate(['payment-flyer/print-flyer']);
  }

  exportToPdf(flyer) {
    const {budgetAreaId, payrollPeriodId, payrollTypeId, versionId, payrollPeriodIdRelated, employeeTypeId, employeeId} = flyer
    if (this.paginatedPaymentFlyers.length <= 0) {
      this._toastr.warning('No se puede cargar el reporte debido a que no hay datos disponibles', 'No hay datos para mostrar')
      return
    }
    const reportCode: ReportCode = ReportCode.rrhh_PaymentForm;
    const companyId: number = this.authInfo.getCompanyId();
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&BudgetAreaId=${budgetAreaId}&PayrollPeriodId=${payrollPeriodId}&PayrollTypeId=${payrollTypeId}&VersionId=${versionId}&PayrollPeriodIdRelated=${payrollPeriodIdRelated || 0}&EmployeeTypeId=${employeeTypeId}&EmployeeId=${employeeId}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Período de Nómina',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

}
