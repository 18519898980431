import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { JobOfferService } from 'src/app/services/job-offer.service';
import { ToastrService } from 'ngx-toastr';
import { IEmployee, IEmployeeBenefictContract, IPersonJO } from './models/Employee';
import { CurrencyPipe, DatePipe, formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { MatDialog } from '@angular/material/dialog';
import { JobOfferDashboardComponent } from './job-offer-dashboard/job-offer-dashboard.component';
import { JobOfferModalComponent } from './job-offer-modal/job-offer-modal.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { FilterEvent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';

declare const $: any;
declare function applySort(): any;

enum condition {
  notsent = 0,
  sent = 1,
  approved = 2,
  nothired = 2,
  hired = 3,
  reserved = 4
}

@Component({
  selector: 'app-job-offer',
  templateUrl: './job-offer.component.html',
  styleUrls: ['./job-offer.component.css'],
})
export class JobOfferComponent implements OnInit, OnDestroy, AfterViewChecked {

  //incompleto
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'VINC',
    dashboardAction: {key: 'DSHVIN', value: false },
    exportAction: {key: 'EXPORT', value: false },
    viewAction: {key: 'VIEW', value: false },
    hiredAction: {key: 'CONTR', value: false },
    reserveAction: {key: 'RESERV', value: false },
  }

  canUseOption = false
  searchParameter: FilterEvent
  employeesPagedResult = new PagedResult<any>();
  subscriptions: Subscription = new Subscription();
  datePipe = new DatePipe('en-Us')
  tableFilter: boolean = false;
  invalidForm: boolean = false;
  employeeType: any[] = [];
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  bankingEntity: any[] = [];
  positionMap: any[] = [];
  locations: any[] = [];
  @ViewChild('paginator') paginator: CaptionComponent;
  @Input() defaultParameterValue: { propkey: string, displayName: string }
  departments: any[] = [];
  postulations: any[] = [];
  dataList: any[] = [];
  budgetAreas: any[] = [];
  companies: [] = [];
  localStorageSearch = 'app-job-offer';
  form: FormGroup;
  onSaving: boolean = false;
  currencyPipe = new CurrencyPipe('en-Us')
  personSelected: IPersonJO;
  filterTableByType: number = 4;
  filterText: string = '';
  employee: IEmployee;
  postulationsTable: any[] = [];
  employeeBenefit: IEmployeeBenefictContract[] = []
  benefits: any[] = [];
  isLoading: boolean = true;
  records: any[] = [];
  recordsOriginal: any[] = [];
  selectedItems: any[] = [];
  postulationsTableOriginal: any = [];
  contractExpirationHasError = false;
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  jobOfferBenefitsToggle: ParameterControl[] = [];

  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Número de Secuencia" },
      { propkeyId: 4, displayName: "Documento de Identidad", default: true},
      { propkeyId: 3, displayName: "Nombre" },
      { propkeyId: 2, displayName: "Cargo" },
      { propkeyId: 5, displayName: "Unidad organizativa" },
      { propkeyId: 7, displayName: "Número de remisión" },
    ];
  statusOptions = [
    { item_id: 3, item_text: 'Nombrados' },
    { item_id: 2, item_text: 'No Nombrado' },
    { item_id: 4, item_text: 'Reservado para Nombramiento' },
    { item_id: 10, item_text: 'Nombramiento Anulado' }
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };


  constructor(
    private jobOfferService: JobOfferService,
    private toastr: ToastrService,
    private _cdRef: ChangeDetectorRef,
    private _xlsxService: ExportToExcelService,
    private dialog: MatDialog,
    public authInfo: AuthInfoService

  ) {
  }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  openModal(ppvid) {
    this.dialog.open(JobOfferModalComponent, {
      width: '75%',
      data: { ppvid, inDetails: false }
    }).afterClosed().subscribe(result => {
      if (result == true) {
        this.getPostulationsData()
      }
    })
  }

  show(ppvid) {
    this.dialog.open(JobOfferModalComponent, {
      width: '75%',
      data: { ppvid, inDetails: true }

    }).afterClosed().subscribe(result => {
      if (result == true) {
        this.getPostulationsData()
      }
    })
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filterSearch(event: FilterEvent){
    this.employeesPagedResult.page = 1
    this.searchParameter = event
    this.getPostulationsData()
  }

  filter() {
    this.getPostulationsData()
    this.setFilterToLocalStorage();
  }

  setFilterToLocalStorage() {
    localStorage.setItem('hiring_filter', JSON.stringify(this.selectedItems));
  }

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem("hiring_filter"))?.length > 0) {
      this.selectedItems = JSON.parse(localStorage.getItem("hiring_filter"));
    } else {
      this.selectedItems =[{ item_id: 3, item_text: 'Nombrados' }, { item_id: 4, item_text: 'Reservado para Nombramiento' }];
    }
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result=>{
      if(result == true){
        this.canUseOption = true
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        //let tt = JSON.parse(localStorage.getItem("hiring_filter"))?.length;
        this.getPostulationsData();
      }
    });
    applySort();
  }

  setToElegible(idPpv) {
    let setToElegibleSub = this.jobOfferService.setToElegible(idPpv).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastr.error(res.errors[0])
        return
      }
      this.getPostulationsData();
    }, (error) => {
      this.toastr.error("Ha ocurrido un error");

    })
    this.subscriptions.add(setToElegibleSub)
  }

  getPostulationsData() {
    if(!this.canUseOption){
      return
    }
    const { page, pageSize } = this.employeesPagedResult
    const text = this.searchParameter?.value
    const propKeyId = !this.searchParameter?.value ? 0 : this.searchParameter?.propkeyId;
    const ableConditions = this.selectedItems
      ?.map((e) => e.item_id)
      ?.join("-");

    const params = {
      propKeyId,
      status: 1,
      conditions: ableConditions,
      page,
      pageSize,
      type: 3,
      searchParameter: text,
    };
    let postulationsByTypeSub = this.jobOfferService.getPostulationPaginated(params).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastr.error(res.errors[0])
        return
      }
      this.employeesPagedResult = res.singleData

      this.postulationsTable = res.singleData.items;
      this.postulationsTableOriginal = res.singleData.items;
    }, (error) => {

    });
    this.subscriptions.add(postulationsByTypeSub)
  }

  openDashBoard(): void {
    this.dialog.open(JobOfferDashboardComponent, {
    })
      .afterClosed()
      .subscribe((result) => {
      });
  }

  getPaginatedRecords(event: PaginationEvent) {
    const { selectedPage, registersPerPage } = event;
    const { employeesPagedResult } = this;
    if (employeesPagedResult.page === selectedPage && employeesPagedResult.pageSize === registersPerPage) {
      return;
    }
    this.employeesPagedResult.page = selectedPage;
    this.employeesPagedResult.pageSize = registersPerPage;
    this.getPostulationsData()
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  onBtnPrintClick(){
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

  exportToPdf() {
    const propKeyId: number = this.searchParameter?.propkeyId ?? 0;
    const searchParameter: string = this.searchParameter?.value ?? '';
    const status: number = this.selectedItems?.length === 2 || this.selectedItems?.length == 0 ? -1 : (this.selectedItems[0]?.item_id === 1 ? 1 : 0);
    const page: number = this.employeesPagedResult?.page;
    const pageSize: number = this.employeesPagedResult?.pageSize;
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.JobOffer;
    const conditions = this.selectedItems.map(x => x.item_id).join("-") ?? ''
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&PropKeyId=${propKeyId}&Conditions=${conditions}&SearchParameter=${searchParameter}&Status=1&Page=${page}&PageSize=${pageSize}&Type=3`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Servidores Públicos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  exportToExcel() {
    let data = this.postulationsTable;
    data.forEach((element:any) => {
      element.conditiontext = this.getConditionDescription(element?.condition)
    });

    let excelHeaders: string[][] = [[
      "Secuencia",
      "Documento de Identidad",
      "Nombre",
      "Cargo",
      "Unidad Organizativa",
      "Remisión",
      "Fecha de vinculación",
      "Estatus",
    ], [
      "ppvid",
      "personalIdentification",
      "personName",
      "positionName",
      "departmentName",
      "referralNumber",
      "hiredDate",
      "conditiontext",
    ]]
    this._xlsxService.exportToExcelSpecificColumns(data, excelHeaders, 'Lista de Candidatos para Nombramiento', true);
  }

  getConditionDescription(conditionId) {
    let description = ''
    switch (conditionId) {
      case 3:
        description = 'Nombrado'
        break;
      case 2:
        description = 'No Nombrado'
        break;
      case 1:
        description = 'Reservado para Nombramiento'
        break;
      default:
        break;
    }
    return description
  }


}
