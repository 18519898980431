import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPostPayrollReject } from '@payroll/payroll-models';
import { ToastrService } from 'ngx-toastr';
import { AuditApprovalService } from 'src/app/services/audit-approval.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reject-payroll-modal',
  templateUrl: './reject-payroll-modal.component.html',
  styleUrls: ['./reject-payroll-modal.component.css']
})
export class RejectPayrollModalComponent implements OnInit {
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  loading = false;
  btnText = 'Agregar Comentario'
  form: FormGroup;
  reasonsAdded: any[] = []
  editingReasonId = -1
  reasons: ParameterControl[]
  formIsInvalid = false
  @Output() onSubmit = new EventEmitter<any>();
  randomNumber = 0;

  configDeclinePayroll = {
    displayKey: 'stringData', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };

  constructor(
    public dialogRef: MatDialogRef<RejectPayrollModalComponent>,
    private fb: FormBuilder,
    private srvParameter: ParameterControlService,
    @Inject(MAT_DIALOG_DATA) public data: {headerId: number, rejectReason?: string},
    private srvAudit: AuditApprovalService,
    private toastr: ToastrService,
    private authInfo: AuthInfoService
  ) {
    dialogRef.disableClose = true
  }

  ngOnInit(): void {
    this.randomNumber = Math.floor(Math.random() * (50 - 1)) + 1
    this.buildForm();
    this.getReasonsParameter()
  }

  upsertReason(){
    this.formIsInvalid = this.form.invalid;
    let userEmployeeId = 0;
    if(this.authInfo.getUser()?.employeeId){
      userEmployeeId = Number(this.authInfo.getUser()?.employeeId)
    }
    if(this.formIsInvalid)return;
    let reason: IPostPayrollReject = {
      companyId: this.authInfo.getCompanyId(),
      createDate: new Date(),
      createUserId: this.authInfo.getUserId(),
      employeeRejectId: userEmployeeId,
      modifyDate: new Date(),
      modifyUserId: 0,
      payrollRejectId: 0,
      rejectDate: new Date(),
      status: true,
      comment: this.form.get('comment').value.trim(),
      headerId: this.data.headerId,
      reason: this.form.get('reason').value.ocode,
      reasonText: this.form.get('reason').value.stringData,
      confirmationToken:  this.form.get('code').value.toString(),
      generatedToken: this.randomNumber.toString(),

    }
    if(this.editingReasonId == -1){
      this.reasonsAdded.push(reason)
      this.form.get('code').disable()
    }else{
      reason.createDate = this.reasonsAdded[this.editingReasonId].createDate
      this.reasonsAdded[this.editingReasonId] = reason
      this.editingReasonId = -1
    }
    this.resetForm()
    this.btnText = 'Agregar Comentario'
  }

  removeReason(index){
    this.reasonsAdded.splice(index, 1)
  }
  saveReject(){
    if(this.reasonsAdded.length < 1){
      this.toastr.warning("Debe agregar una razón")
      return;
    }
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      title: '¿Seguro que desea rechazar esta nómina?',

    }).then((result) => {
      if(result.isConfirmed){
        this.loading = true;
        this.srvAudit.postHeaderReject(this.reasonsAdded).subscribe((res:any) => {
          if(res.succeded){
            this.dialogRef.close({saved: true})

          }else{
            this.toastr.error(res.errors[0])
          }
        }, e=> {
          this.toastr.error(this.errorMessage)
        }, () =>{
          this.loading = false;
        })
      }
    })
  }

  edit(i){
    this.btnText = 'Guardar Comentario'
    let reason = this.reasonsAdded[i]
    this.editingReasonId = i
    this.form.patchValue({
      code: reason.confirmationToken,
      reason: this.reasons.find(x => x.ocode == reason.reason),
      comment: reason.comment
    })

  }
  getReasonsParameter(){
    this.srvParameter.getParameters(keyWord.ReasonRejectPayroll).subscribe((res:ResponseModel<ParameterControl>) => {
      if(res.succeded){
        this.reasons = res.dataList;
      }else{
        this.toastr.error(res.errors[0])
      }
    }, e => {
      this.toastr.error(this.errorMessage)
    })
  }



  resetForm(){
    this.form.patchValue({
      reason: '',
      comment: ''
    })
  }

  buildForm(){
    this.form = this.fb.group({
      code: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      reason: ['', Validators.required],
      comment: [this.data?.rejectReason, [Validators.required, Validators.pattern(/[\S]/)]]
    })
  }
  // getCodeErrors(){

  //   let field = this.form.get('code')
  //   if(field.hasError('required')){
  //     return 'Este campo es requerido'
  //   }
  //   if(field.hasError('pattern')){
  //     return 'Este campo solo acepta números'
  //   }
  //   return ''
  // }
  // getErrors(a){
  //   let field = this.form.get(a)
  //   if(field.hasError('required')){
  //     return 'Este campo es requerido'
  //   }
  //   return ''
  // }
  // getCommentErrors(){
  //   const field = this.form.get('comment')
  //   if(field.hasError('required')){
  //     return 'Este campo es requerido'
  //   }
  //   if(field.hasError('pattern')){
  //     return 'Carácteres inválidos'
  //   }
  //   return ''
  // }

}
