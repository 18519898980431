<div class="card card-custom">
    <div class="card-header mt-5 row justify-content-end">
        <div class="col-sm-6 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <button data-toggle="modal" id="btnAdd" (click)="openModalAdd()"
                *ngIf="!data?.inDetails"
                class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto" >
                <i class="flaticon-mas icon-2x" data-toggle="tooltip" data-original-title="Agregar Valor Predefinido"></i>
                Agregar
              </button>
         
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th>Secuencia</th>
                        <th>Valor</th>
                        <th>Descripción</th>
                        <th>Estatus</th>
                        <th class="actionsBtn" style="width: 100px;">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of conceptValues; let i = index">
                        <tr class="select-employee">
                            <td>{{item?.conceptValuesId}}</td>
                            <td>{{item?.value}}</td>
                            <td>{{item?.description}}</td>
                            <td>
                                <span class="text-success" *ngIf="item?.status">Activo</span>
                                <span class="text-danger" *ngIf="!item?.status">Inactivo</span>
                            </td>
                            <td data-field="Actions" data-autohide-disabled="false" aria-label="null"
                            class="datatable-cell-left datatable-cell">
                                    <div class="d-flex justify-content-center  px-5  " style="width: max-content">
                                        <div class="justify-content-start  px-5">
                                        <button (click)="openModalAdd(item, true, false, 'Detalle Valor Prefefinido', 0)" tooltip="Ver" placement="top" data-toggle="modal"
                                            class="btn btn-sm btn-light-skype btn-icon mr-2">
                                            <i class="flaticon-ver icon-2x" data-toggle="tooltip" data-title="Ver"></i>
                                        </button>
                                        <button *ngIf="item?.status && !data?.inDetails" (click)="openModalAdd(item, false, true, 'Editar Valor Prefefinido', i)" tooltip="Editar" placement="top"
                                            class="btn btn-sm btn-light-warning btn-icon mr-2">
                                            <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                                        </button>
                                        <button *ngIf="item?.status && !data?.inDetails" (click)="deleteConceptValues(item, i)" data-toggle="tooltip" title="" class="btn btn-sm btn-light-danger btn-icon" data-original-title="Remover">
                                            <i class="flaticon-delete"></i>
                                        </button>
                                     </div>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
           
        </div>

    </div>
</div>