import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumbersService {

  private LAST_NUMBER: number = 0;

  constructor() { }

  getRandomInt(min: number, max: number): number {
    let result = this.LAST_NUMBER;

    while (result == this.LAST_NUMBER){
      min = Math.ceil(min);
      max = Math.floor(max);
      result =  Math.floor(Math.random() * (max - min + 1)) + min;
    }
    
    this.LAST_NUMBER = result;
    return result;
  }

  generateRandomUUID(): string {
    return 'xxxxyxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0;
      const v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
