<div class="card card-custom" [id]="privilege.nameKeyOption">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon">
                <i class="flaticon-etiqueta icon-3x text-danger"></i>
            </span>
            <h2 class="d-flex flex-column">
                Requisición / Habilitar Vacantes
            </h2>
        </div>

        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard">
            <button (click)="openDialog()"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto" data-toggle="modal"
                *ngIf="privilege.dashboardAction.value">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </button>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-6 c-left">
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-4 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Estatus
                        </label>
                        <!-- <app-dropdown-multi-select (changeAsw)="filterTable($event)"></app-dropdown-multi-select> -->
                        <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                            [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                            (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                    </div>
                </div>

                <!-- <button data-toggle="modal" id="btnAdd" (click)="AddNewRequisition(null)" class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                    <i class="flaticon-mas icon-2x"></i> Agregar
                </button> -->
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="privilege.exportAction.value" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="openReport()">
                            <i class="fa fa-print icon-2x text-primary mr-3"></i>
                            Visualizar Reporte
                        </a>
                    </div>
                </div>

                <div class="col text-nowrap">
                    <app-search-bar-pagination #searchBar [namePageLocalStorage]="searchparameterlocalstorage"
                        (onFilter)="filterSearch($event)" [parameters]="searchBarDisplayNames">
                    </app-search-bar-pagination>

                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table mt-2 table-vertical-center text-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th class="text-left">Sucursal</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-left">Puesto Vacante</th>
                        <th>Fecha de Aprobación</th>
                        <th>Fecha Publicación</th>
                        <th>Condición Publicación</th>
                        <th>Estatus</th>
                        <th class="actionsBtn">Acciones</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let vacant of vacancies">

                        <td class="text-center">
                            {{vacant.vacantId}}
                        </td>
                        <td class="text-left">{{vacant.companyName}}</td>
                        <td class="text-left">{{vacant.department}}</td>
                        <td class="text-left">{{vacant.positionName}}</td>
                        <td>{{vacant.createdDateFormatted}}</td>
                        <td>{{vacant.publicationDateFormatted}}</td>

                        <td *ngIf="vacant.published">
                            <span style="width: 137px;">
                                <span class="label label-success label-dot mr-2"></span>
                                <span class="font-weight-bold text-success">{{vacant.publishedText}}</span>
                            </span>
                        </td>
                        <td *ngIf="!vacant.published">
                            <span style="width: 137px;">
                                <span class="label label-danger label-dot mr-2"></span>
                                <span class="font-weight-bold text-danger">{{vacant.publishedText}}</span>
                            </span>
                        </td>
                        <td *ngIf="vacant.status">
                            <span style="width: 137px;">
                                <span class="font-weight-bold text-success">Activa</span>
                            </span>
                        </td>
                        <td *ngIf="!vacant.status">
                            <span style="width: 137px;">
                                <span class="font-weight-bold text-danger">Inactiva</span>
                            </span>
                        </td>
                        <td class="actionsBtn">

                            <button [routerLink]="['/vacancies/post-vacancies/view/',vacant.status, vacant.vacantId]"
                                *ngIf="vacant.published  && privilege.viewAction.value"
                                class="btn btn-sm btn-light-skype btn-icon mr-2">
                                <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver"></i>
                            </button>
                            <button *ngIf="!vacant.published && vacant.status && privilege.postAction.value"
                                [routerLink]="['/vacancies/post-vacancies/', vacant.vacantId]"
                                class="btn btn-sm btn-light-success btn-icon mr-2">
                                <i class="flaticon-etiqueta icon-2x" data-toggle="tooltip" title="Publicar Vacante"></i>
                            </button>
                            <button *ngIf="vacant.status" class="btn btn-icon btn-light-danger btn-sm m-1">
                                <i class="flaticon-usuarios icon-2x" data-toggle="tooltip" title="Inactivar"
                                    (click)="deleteItem(vacant.vacantId)"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>

                <app-paginator [listedRecordsLength]="vacancies?.length"
                    [registerQuantity]="employeesPagedResult.totalItems"
                    [registerPerPage]="employeesPagedResult.pageSize" [totalPages]="employeesPagedResult.totalPages"
                    [selectedPage]="employeesPagedResult.page" (onChange)="getPaginatedRecords($event)">
                </app-paginator>
                <!-- <ng-template [ngIf]="filteredList">
                  </ng-template> -->
            </div>
            <div class="d-flex justify-content-end align-items-center flex-wrap">
                <div class="d-flex align-items-center py-3 mr-2">
                    <!-- <select class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light" style="width: 75px;">
                          <option value="1">1</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                      </select> -->
                    <!-- <span class="text-muted">Mostrando página 1 de 1</span> -->
                </div>
                <!-- <div class="d-flex flex-wrap py-2 mr-3">
                      <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                          <i class="ki ki-bold-arrow-back icon-xs"></i>
                      </button>
                      <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                          <i class="ki ki-bold-arrow-next icon-xs"></i>
                      </button>
                  </div> -->
            </div>
        </div>
    </div>
</div>
