<ng-container *ngIf="privilege.add.value">
    <form [formGroup]="form">
        <div class="row mt-10"
            *ngIf="isEditinglicense && (currentLicenseConditionOnEditting == 2) && currentLicenseEdittingIsWithoutPayroll == true">
            <div class="alert alert-warning d-flex align-items-center">
                <i class="fas fa-exclamation-triangle mr-2 fa-2x blink-for-warning"></i>
                <div>
                    Esta acción de personal ya fue <strong>aprobada</strong>, modificarla puede causar alteraciones en
                    la <strong>nómina</strong> y otros módulos del <strong>SIGEI</strong>. En caso de que la acción de
                    personal haya sido aplicada en una
                    nómina u otro proceso dependiente de la misma, su modificación <strong>NO</strong> será
                    <strong>permitida</strong>.
                </div>
            </div>

        </div>
        <div class="row mt-10">
            <div class="col-md-4">
                <div class="form-group">
                    <label>Tipo de licencia <span class="text-danger">*</span></label>
                    <ngx-select-dropdown (change)="changeLicenseType($event?.value)" [options]='licenseOptions'
                        formControlName="licenseType" [disabled]="!this.employeeSelected?.status || isSeviceCommission
                        || isSuspended"
                        [config]="nameConfig" class="flex-1">
                    </ngx-select-dropdown>

                    <span *ngIf="formIsInvalid && form.get('licenseTypeId').errors?.min" class="text-danger">
                        Este campo es requerido
                    </span>
                </div>
            </div>
            <div class="col-md-2">
                <div>
                    <div class="form-group">
                        <label>Fecha de Solicitud <span class="text-danger">*</span>
                        </label>
                        <input formControlName="applicationDate" type="date" class="form-control form-control-lg"
                            (input)="validateEndDate()" />
                        <span *ngIf="formIsInvalid && form.get('applicationDate').errors?.required"
                            class="text-danger">Este
                            campo es requerido</span>
                        <span class="text-danger" *ngIf="showDateApplicationError">Fecha inválida</span>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group">
                    <label>Fecha de Inicio <span class="text-danger">*</span>
                    </label>
                    <input formControlName="dateStart" (change)="changeDateStart();validateEndDate()" type="date"
                        class="form-control form-control-lg" />
                    <span *ngIf="formIsInvalid && form.get('dateStart').hasError('required')" class="text-danger">Este
                        campo es requerido</span>
                    <span class="text-danger" *ngIf="showDateStartError">Fecha inválida</span>
                    <span class="text-danger" *ngIf="showRequestDateToEndDateError">La fecha de inicio debe ser superior
                        a la
                        fecha de solicitud</span>
                </div>
            </div>


            <div class="col-md-2">
                <div>
                    <div class="form-group">
                        <label>Fecha de Término <span class="text-danger">*</span>
                        </label>
                        <input [min]="minMaxDate.min" [max]="this.minMaxDate.max" (input)="validateEndDate()"
                            formControlName="dateEnd" type="date" class="form-control form-control-lg" />
                        <span *ngIf="formIsInvalid && form.get('dateEnd').hasError('required')" class="text-danger">Este
                            campo es requerido</span>
                        <span class="text-danger" *ngIf="showDateEndError">La fecha de término debe ser superior a la
                            fecha de inicio</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div>
                    <div class="form-group">
                        <label>Comentario </label>
                        <textarea formControlName="comment" class="form-control" rows="9"></textarea>
                    </div>
                </div>
                <div *ngIf="form.get('licenseType').value?.requiredMotive == true">
                    <div class="form-group">
                        <label>Motivo de Licencia <span class="text-danger">*</span></label>
                        <textarea formControlName="reason" class="form-control"></textarea>
                        <errors-component [controlName]="form.get('reason')">
                        </errors-component>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Evidencia <span *ngIf="typeLicenseSeleted?.evidenceRequired"
                            class="text-danger">*</span></label>
                    <div class="card align-items-center">
                        <uppy-file 
                            #uppyFileLicence
                            previewDefaultImage="assets/images/cv.jpg"
                            classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px"
                            targetClass="licenseEvidence"
                            [width]="320"
                            [height]="295"
                            [isDisabled]="!this.employeeSelected?.status || this.isSuspended == true"
                            [showDocumentoAfterUploaded]="false"
                            [fileForEditting]="file?.documentLicense"
                            [allowedFileTypes]="['application/pdf']"
                            (fileEmitter)="setEvidence($event)">
                        </uppy-file>
                        <span
                            *ngIf="formIsInvalid && form.get('evidence').hasError('required') && typeLicenseSeleted?.evidenceRequired"
                            class="text-danger">Este campo es requerido</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2" *ngIf="isEditinglicense">
            <div class="text-primary pt-4">
                <h4>Información de la Modificación</h4>
                <hr>
            </div>
            <div class="row mb-2">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Motivo de la Modificación Anterior </label>
                        <input type="text" class="form-control form-control-lg" [value]="form.get('oldModificationReason')?.value?.stringData" readonly/>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="form-group">
                        <label>Comentario de la Modificación Anterior</label>
                        <textarea [value]="form.get('oldModificationComment')?.value" class="form-control"
                            rows="6" readonly></textarea>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Motivo de la Modificación Actual <span class="text-danger">*</span></label>
                        <ngx-select-dropdown formControlName='newModificationReason' [options]='modificationReasons'
                            [config]='reasonsConfig' class="flex-1" (change)="changeModificationReason($event?.value)">
                        </ngx-select-dropdown>
                        <span class="text-danger"
                            *ngIf="formIsInvalid && form.controls['newModificationReason']?.errors?.required">Este
                            campo es requerido</span>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="form-group">
                        <label>Comentario de la Modificación Actual</label>
                        <textarea formControlName="newModificationComment" class="form-control"
                            rows="6"></textarea>
                    </div>
                </div>
            </div>
            
           
        </div>
    </form>
    <div class="row">
        <div class="form-group d-flex justify-content-end align-items-center">
            <button (click)="save()" class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3"
                [disabled]="showDateEndError || showDateApplicationError || showDateStartError || showRequestDateToEndDateError">
                <i class="flaticon-disco-flexible"></i> <span> {{isEditinglicense ? 'Guardar Modificación' :
                    'Guardar'}}</span>
            </button>

            <button (click)="clearFields(isEditinglicense ? true  : false);"
                class="btn btn-danger btn-pill font-weight-bolder">
                {{isEditinglicense ? 'Cancelar Modificación' : 'Limpiar campos'}}
            </button>
        </div>
    </div>
</ng-container>
<div class="row gutter-b">
    <div class="table-responsive">
        <table class="table atbl table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th class="text-left">Secuencia</th>
                    <th class="text-left">Tipo</th>
                    <th class="text-left">Fecha Solicitud</th>
                    <th class="text-center">Fecha inicio</th>
                    <th class="text-center ">Fecha de Termino</th>
                    <th class="text-left ">Motivo de Licencia</th>
                    <th>Evidencia</th>
                    <th class="text-left">Estatus</th>
                    <th class="text-center">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of licenses">
                    <td class="text-left">{{item.permissionsId}}</td>
                    <td class="text-left">{{item.typePermissions}}</td>
                    <td class="text-left">{{item.applicationDate|date: "dd/MM/yyyy"}}</td>
                    <td class="text-center">{{item.dateStart|date: "dd/MM/yyyy"}}</td>
                    <td class="text-center">{{item.dateEnd|date: "dd/MM/yyyy"}}</td>
                    <td class="text-left">
                        <div class="reasonPermiso">
                            {{item.reason || "N/A"}}
                        </div>
                    </td>
                    <td>
                        <ng-container>
                            <a *ngIf="item.evidence" (click)='openGuID(item.evidence)' data-toggle="tooltip"
                                title="Evidencia" class="btn btn-light-youtube font-weight-bold mb-3">
                                <i class="flaticon-pdf-file icon-2x"></i>
                            </a>
                            <p *ngIf="!item.evidence">N/A</p>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngIf="!item?.status; else conditions">
                            <span class="font-weight-bold text-danger"> {{item?.conditionId === 10 ? 'Anulado' : 'Inactivo'}} </span>
                        </ng-container>
                        <ng-template #conditions>
                            <ng-container *ngIf="item?.conditionId === 6">
                                <span class="font-weight-bold text-warning">En Espera de Aprobación</span>
                            </ng-container>
                            <ng-container *ngIf="item?.conditionId === 2">
                                <span class="font-weight-bold text-success">Aprobado</span>
                            </ng-container>
                            <ng-container *ngIf="item?.conditionId === 3">
                                <span class="font-weight-bold text-danger">Rechazado</span>
                            </ng-container>
                            <ng-container *ngIf="item?.conditionId === 10">
                                <span class="font-weight-bold text-danger">Anulado</span>
                            </ng-container>
                        </ng-template>
                    </td>
                    <td class="actionsBtn">
                        <button class="btn btn-icon btn-light-info btn-sm m-1" *ngIf="privilege.view.value"
                            (click)="openReportPDF(item)">
                            <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver Evidencia"></i>
                        </button>
                        <button *ngIf="privilege.edit.value && item?.conditionId != 10 && item?.status == true"
                            (click)="editLicense(item)" class="btn btn-icon btn-light-warning btn-sm m-1">
                            <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                        </button>
                        <button *ngIf="item.conditionId == 6 && privilege.approvePermission.value && !isEditinglicense && item?.status == true"
                            (click)="approvePermission(item)" type="button"
                            class="btn btn-sm btn-light-success btn-icon mr-2">
                            <i class="flaticon-checked" data-title="Aprobar" data-toggle="tooltip"></i>
                        </button>
                        <button *ngIf="item.conditionId == 6 && privilege.rejectPermission.value && !isEditinglicense && item?.status == true"
                            (click)="rejectLicenseModal(item)" type="button"
                            class="btn btn-sm btn-light-danger btn-icon mr-2">
                            <i class="flaticon2-cross" data-title="Rechazar" data-toggle="tooltip"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<el-modal target="documentLicense" title="Evidencia de la Licencia">
    <iframe [src]='transform(file.documentLicense)' width="100%" height="600px"></iframe>
</el-modal>