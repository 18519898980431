

<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Lista de Campos Incompletos</h2>
    <button type="button" (click)="dialogRef.close(false)" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <ul class="list-group list-group-flush">
            <li *ngFor="let file of incompletedFilesList" class="list-group-item">{{file.description}}</li>
        </ul>
    </div>
</div>
<div class="mat-dialog-actions" [align]="'end'">
  
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3 float-end" id="btnCloseU"
                data-dismiss="modal" (click)="dialogRef.close(false)">
                <i class="flaticon2-cross"></i>
                <span>Cerrar</span>
            </button>

</div>