<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="closeModal(false)" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span
            class="h-20px label label-danger label-dot mr-3 w-20px"></span>{{titleModal}}</h2>

</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom my-0 py-0">

        <div class="card-body">
            <form [formGroup]="contentForm">
                <!-- <div class="row">
                   
                </div> -->

                <div class="row">
                    <div class="form-group col-md-4">
                        <label class="d-block">Tipo de Publicación <span
                                class="text-danger text-monospace legend">*</span></label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown (change)="onChangePostType($event.value)" [options]="postTypes"
                                [config]="getDropdownConfig('stringData', 'Seleccionar')" formControlName="postType"
                                class="w-100">
                            </ngx-select-dropdown>
                            <errors-component [controlName]="contentForm.controls['postType']"></errors-component>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="d-block">Unidad Organizativa Origen <span
                                class="text-danger text-monospace legend">*</span></label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [options]="departments" formControlName="origin"
                                [config]="getDropdownConfig('departmentName', 'Seleccionar')" class="w-100">
                            </ngx-select-dropdown>
                            <errors-component [controlName]="contentForm.controls['origin']"></errors-component>
                        </div>
                    </div>

                    <div class="form-group col-md-4">
                        <div>
                            <label for="">
                                Fecha de Publicación <span class="text-danger text-monospace legend">*</span>
                            </label>
                        </div>
                        <input type="date" formControlName="date"
                            class="form-control form-control-lg dynamicFormControl ng-pristine ng-valid ng-touched">
                        <errors-component [controlName]="contentForm.controls['date']"></errors-component>
                    </div>

                    <div class="form-group col-md-4">
                        <label class="d-block">Clasificación de Publicación <span
                                *ngIf="contentForm.get('postType').value?.ocode !== 3"
                                class="text-danger text-monospace legend">*</span></label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [options]="postClassification"
                                [config]="getDropdownConfig('description', 'Seleccionar')"
                                formControlName="postClassification" class="w-100">
                            </ngx-select-dropdown>
                            <errors-component
                                [controlName]="contentForm.controls['postClassification']"></errors-component>
                        </div>
                    </div>

                    <div class="form-group col-md-4">
                        <label class="d-block">Dirigido a <span
                                *ngIf="contentForm.get('postType').value?.ocode !== 3"
                                class="text-danger text-monospace legend">*</span></label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [options]="demographics"
                                [config]="getDropdownConfig('description', 'Seleccionar')" formControlName="directed"
                                class="w-100">
                            </ngx-select-dropdown>
                            <errors-component [controlName]="contentForm.controls['directed']"></errors-component>
                        </div>
                    </div>

                    <div class="form-group col-md-4">
                        <div>
                            <label for="">
                                Título <span class="text-danger text-monospace legend">* </span>
                            </label>
                        </div>
                        <input formControlName="postTitle" class="form-control form-control-lg dynamicFormControl" id=""
                            placeholder="" type="text" />
                        <errors-component [controlName]="contentForm.controls['postTitle']"></errors-component>
                    </div>



                    <div class="form-group col-md-4">
                        <label class="d-block">&nbsp;</label>
                        <div class="flex-shrink-0">
                            <span class="switch switch-sm switch-primary border rounded py-3 w-auto pr-3">
                                <strong
                                    class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4 ng-star-inserted">
                                    ¿Permite Comentar?
                                </strong>
                                <label>
                                    <input formControlName="allowComment" type="checkbox"
                                        class="cursor-pointer abc-checkbox-primary ng-untouched ng-pristine ng-valid"
                                        ng-reflect-is-disabled="false" ng-reflect-model="false">
                                    <span></span>
                                </label>
                            </span>
                        </div>
                    </div>

                    <!-- <div class="form-group col-md-4">
                        <label class="d-block">&nbsp;</label>
                        <div class="flex-shrink-0">
                            <span class="switch switch-sm switch-primary border rounded py-3 w-auto pr-3">
                                <strong
                                    class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4 ng-star-inserted">
                                    ¿Permite Compartir?
                                </strong>
                                <label>
                                    <input formControlName="allowShared" type="checkbox"
                                        class="cursor-pointer abc-checkbox-primary ng-untouched ng-pristine ng-valid"
                                        ng-reflect-is-disabled="false" ng-reflect-model="false">
                                    <span></span>
                                </label>
                            </span>
                        </div>
                    </div> -->
                    <div class="form-group col-md-4">
                        <label class="d-block">&nbsp;</label>
                        <div class="flex-shrink-0">
                            <span class="switch switch-sm switch-primary border rounded py-3 w-auto pr-3">
                                <strong
                                    class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4 ng-star-inserted">
                                    ¿Es una actividad?
                                </strong>
                                <label>
                                    <input formControlName="isActivity" type="checkbox"
                                        class="cursor-pointer abc-checkbox-primary ng-untouched ng-pristine ng-valid"
                                        ng-reflect-is-disabled="false" ng-reflect-model="false">
                                    <span></span>
                                </label>
                            </span>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <div>
                            <label for="">
                                Enlace <span *ngIf="contentForm.get('postType').value?.ocode == 1"
                                    class="text-danger text-monospace legend">*</span>
                            </label>
                        </div>
                        <input formControlName="link" class="form-control form-control-lg dynamicFormControl" id=""
                            placeholder="" type="text" />
                        <errors-component [controlName]="contentForm.controls['link']"></errors-component>
                    </div>

                    <div class="form-group col-md-4">
                        <label class="d-block">Estado <span class="text-danger text-monospace legend">*</span></label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [options]="conditions" formControlName="condition"
                                [config]="getDropdownConfig('description', 'Seleccionar')" class="w-100">
                            </ngx-select-dropdown>
                            <errors-component [controlName]="contentForm.controls['condition']"></errors-component>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="form-group col-md-12">
                                <div>
                                    <label>
                                        Cuerpo del Contenido
                                        <!-- <span *ngIf="contentForm.get('postType').value?.ocode == 3" class="text-danger text-monospace legend">*</span> -->
                                    </label>
                                </div>

                                <textarea formControlName="postText" rows="7"
                                    class="form-control form-control-lg dynamicFormControl"></textarea>
                                <errors-component [controlName]="contentForm.controls['postText']"></errors-component>
                            </div>


                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Subir Archivo Multimedia
                                <span
                                    *ngIf="contentForm.get('postType').value?.ocode == 2 || contentForm.get('postType').value?.ocode == 3"
                                    class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="card align-items-center">
                                <uppy-file 
                                        #uppyFile
                                        [previewDefaultImage]="previewDefaultImage"
                                        [isDisabled]="data?.IsView"
                                        classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px"
                                        targetClass="mediaFile"
                                        showFileModalTitle="Preview"
                                        [width]="280"
                                        [height]="150"
                                        [showDocumentoAfterUploaded]="false"
                                        [fileForEditting]="guiID"
                                        [allowedFileTypes]="allowedFileTypes"
                                        (fileEmitter)="setFile($event)">
                                    </uppy-file>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                        <div class="form-group col-md-6">
                            <label class="d-block">&nbsp;</label>
                            <div class="flex-shrink-0">
                                <span class="switch switch-sm switch-primary border rounded py-3 w-auto pr-3">
                                    <strong
                                        class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4 ng-star-inserted">
                                        ¿Es recurrente?
                                    </strong>
                                    <label>
                                        <input formControlName="isRecurring" type="checkbox" (change)="changeIsRecurring()"
                                            class="cursor-pointer abc-checkbox-primary ng-untouched ng-pristine ng-valid"
                                            ng-reflect-is-disabled="false" ng-reflect-model="false">
                                        <span></span>
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6" *ngIf="showIsRecurring">
                            <div>
                                <label for="">
                                    Fecha Final Publicación 
                                </label>
                            </div>
                            <input type="date" formControlName="finalDate"
                                class="form-control form-control-lg dynamicFormControl ng-pristine ng-valid ng-touched">
                            <errors-component [controlName]="contentForm.controls['finalDate']"></errors-component>
                        </div>
                    <div class="col-12" *ngIf="showIsRecurring">
                            <div class="form-group">
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" formControlName="monday" class="form-check-input dynamicFormControl mr-2 ng-valid ng-dirty ng-touched" value="1"/>
                                    <label class="form-check-label"> Lunes </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" formControlName="tuesday" class=" form-check-input dynamicFormControl mr-2 ng-valid ng-dirty ng-touched " value="2" />
                                    <label class="form-check-label"> Martes </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                    type="checkbox"
                                    formControlName="wednesday"
                                    class="
                                        form-check-input
                                        dynamicFormControl
                                        mr-2
                                        ng-valid ng-dirty ng-touched
                                    "
                                    value="3"
                                    />
                                    <label class="form-check-label"> Miércoles </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                    type="checkbox"
                                    formControlName="thursday"
                                    class="
                                        form-check-input
                                        dynamicFormControl
                                        mr-2
                                        ng-valid ng-dirty ng-touched
                                    "
                                    value="4"
                                    />
                                    <label class="form-check-label"> Jueves </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                    type="checkbox"
                                    formControlName="friday"
                                    class="
                                        form-check-input
                                        dynamicFormControl
                                        mr-2
                                        ng-valid ng-dirty ng-touched
                                    "
                                    value="5"
                                    />
                                    <label class="form-check-label"> Viernes </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                    type="checkbox"
                                    formControlName="saturday"
                                    class="
                                        form-check-input
                                        dynamicFormControl
                                        mr-2
                                        ng-valid ng-dirty ng-touched
                                    "
                                    value="6"
                                    />
                                    <label class="form-check-label"> Sábado </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                    type="checkbox"
                                    formControlName="sunday"
                                    class="
                                        form-check-input
                                        dynamicFormControl
                                        mr-2
                                        ng-valid ng-dirty ng-touched
                                    "
                                    value="7"
                                    />
                                    <label class="form-check-label"> Domingo </label>
                                </div>
                            </div>
                      
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>

<div class="mat-dialog-actions justify-content-end" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="closeModal(false)">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="!data.IsView" [disabled]="contentForm.invalid" (click)="submitContent()"
        class="btn btn-pill font-weight-bolder btn-shadow btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>

<el-modal size="xl" target="video" title="Preview">
    <iframe [src]='transform(file.url)' width="100%" height="600px"></iframe>
</el-modal>