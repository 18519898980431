<div class="card-body">
    <!-- <form [formGroup]="employeeDataForm">
        <div class="row">
            <div class="col-lg-3">
                <div class="form-group">
                    <label>Código <span class="text-danger">*</span></label>
                    <input formControlName="employeeNumber" readonly type="text" class="form-control form-control-solid form-control-lg"/>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label>Empleado <span class="text-danger">*</span></label>
                    <input formControlName="employeeName" readonly type="text" class="form-control form-control-solid form-control-lg"/>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <label>Tipo deServidor Público <span class="text-danger">*</span></label>
                    <input formControlName="employeeType" readonly type="text" class="form-control form-control-solid form-control-lg"/>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="form-group">
                    <label>Unidad Organizativa <span class="text-danger">*</span></label>
                    <input formControlName="department" type="text" class="form-control form-control-solid form-control-lg" readonly />
                </div>
            </div>
            <div class="col-lg-5">
                <div class="form-group">
                    <label>Posición <span class="text-danger">*</span></label>
                    <input formControlName="position" type="text" class="form-control form-control-solid form-control-lg" readonly />
                </div>
            </div>
        </div>
    </form> -->
    <ul class="nav nav-tabs border-tabs-primary" role="tablist">
        <li class="nav-item" *ngIf="privilege.vacations.value">
            <a class="nav-link active" id="vacaciones-tab" (click)="setTabSelected('vacaciones-tab')" data-toggle="tab"
                href="#vacaciones" aria-controls="vacaciones">
                <span class="nav-icon mr-5">
                    <i class="flaticon-despachador-de-vuelo icon-3x"></i>
                </span>
                <span class="nav-text">
                    <h3>Vacaciones</h3>
                </span>
            </a>
        </li>
        <li class="nav-item" *ngIf="privilege.permission.value">
            <a class="nav-link" id="permisos-tab" (click)="setTabSelected('permisos-tab')" data-toggle="tab"
                href="#permisos" aria-controls="permisos">
                <span class="nav-icon mr-5">
                    <i class="flaticon-lista-de-verificacion icon-3x"></i>
                </span>
                <span class="nav-text">
                    <h3>Permisos</h3>
                </span>
            </a>
        </li>
        <li class="nav-item" *ngIf="privilege.licenses.value">
            <a class="nav-link" id="licencias-tab" (click)="setTabSelected('licencias-tab')" data-toggle="tab"
                href="#licencias" aria-controls="licencias">
                <span class="nav-icon mr-5">
                    <i class="flaticon-proceso-3 icon-3x"></i>
                </span>
                <span class="nav-text">
                    <h3>Licencias</h3>
                </span>
            </a>
        </li>
        <li class="nav-item" *ngIf="privilege.externalService.value">
            <a class="nav-link" id="extServices-tab" (click)="setTabSelected('extServices-tab')" data-toggle="tab"
                href="#externalServices" aria-controls="externalServices">
                <span class="nav-icon mr-5">
                    <i class="flaticon-proceso-3 icon-3x"></i>
                </span>
                <span class="nav-text">
                    <h3>Servicios Externos</h3>
                </span>
            </a>
        </li>
        <li class="nav-item" *ngIf="privilege.serviceCommission.value">
            <a class="nav-link" id="commission-tab" (click)="setTabSelected('commission-tab')" data-toggle="tab"
                href="#commission" aria-controls="commission">
                <span class="nav-icon mr-5">
                    <i class="flaticon-proceso-3 icon-3x"></i>
                </span>
                <span class="nav-text">
                    <h3>Comisión de Servicio</h3>
                </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="disciplinaryMeasures-tab" (click)="setTabSelected('disciplinaryMeasures-tab')" data-toggle="tab"
                href="#disciplinaryMeasures" aria-controls="disciplinaryMeasures">
                <span class="nav-icon mr-5">
                    <i class="flaticon-proceso-3 icon-3x"></i>
                </span>
                <span class="nav-text">
                    <h3>Medidas Disciplinarias</h3>
                </span>
            </a>
        </li>
        <!--li class="nav-item" *ngIf="privilege.bans.value">
            <a class="nav-link" id="banns-tab" (click)="setTabSelected('banns-tab')" data-toggle="tab" href="#banns"
               >
                <span class="nav-icon mr-5">
                    <i class="flaticon-proceso-3 icon-3x"></i>
                </span>
                <span class="nav-text">
                    <h3>Amonestaciones</h3>
                </span>
            </a>
        </li-->
    </ul>
    <div class="tab-content mt-5">
        <div class="tab-pane fade active show" id="vacaciones" role="tabpanel" aria-labelledby="vacaciones-tab"
            *ngIf="privilege.vacations.value">
            <div class="row gutter-t">
                <div class="col-md-8">
                    <form [formGroup]="vacationForm" *ngIf="privilege.addVacation.value">
                        <div class="row gutter-t">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Fecha de Solicitud <span class="text-danger">*</span></label>
                                    <input formControlName="applicationDate" type="date"
                                        class="form-control form-control-solid form-control-lg" />
                                    <span *ngIf="vacationFormInvalid && !this.vacationForm?.value.applicationDate"
                                        class="text-danger">Este campo es requerido</span>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label for="startDate">
                                    Fecha de Inicio <span class="text-danger">*</span>
                                </label>
                                <input formControlName="dateStart" type="date" (change)="daysRequested();validateDates()"
                                    class="form-control form-control-solid form-control-lg"
                                    id="startDate" />
                                <span *ngIf="this.vacationForm?.controls['dateStart']?.errors?.required && vacationFormInvalid"
                                    class="text-danger">Este campo es requerido</span>
                                <span class="text-danger d-block">{{ dateSelectedError }}</span>
                            </div>
                            <div class="form-group col-md-3">
                                <div>
                                    <label for="endDate">
                                        Fecha de Término <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <input formControlName="dateEnd" type="date" (change)="daysRequested();validateDates()"
                                    class="form-control form-control-solid form-control-lg" id="endDate" />
                                <span *ngIf="this.vacationForm?.controls['dateEnd']?.errors?.required && vacationFormInvalid"
                                    class="text-danger">Este campo es requerido</span>
                                <span class="text-danger d-block">{{ dateStartIsGreaterThanEndDate }}</span>
                                <span class="text-danger d-block">{{ dateSelectedError }}</span>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="d-block w-100">
                                    Período al que Pertenecen <span class="text-danger">*</span>
                                </label>
                                <ngx-select-dropdown (change)="changeYear()" formControlName="period"
                                    [config]="yearDropdownConfig"
                                    [disabled]="!this.personSelected?.status || isSeviceCommission"
                                    [options]="vacationYears"></ngx-select-dropdown>
                                <!-- <input class="form-control form-control-solid form-control-lg" type="number" formControlName="year" name="" id="" /> -->
                                <span *ngIf="this.vacationForm?.controls['period']?.errors?.required && vacationFormInvalid"
                                    class="text-danger">Este campo es requerido</span>
                            </div>
                            <div class="form-group col-md-3">
                                <div>
                                    <label for="totalDays"> Días Correspondientes</label>
                                </div>
                                <input formControlName="days" readonly class="form-control form-control-solid form-control-lg"
                                    id="totalDays" placeholder="" type="number" />
                                <span *ngIf="vacationFormInvalid &&this.vacationForm?.controls['days']?.errors?.required"
                                    class="text-danger">Este campo es requerido</span>
                                <span class="text-danger d-block">{{ exceededDaysError }}</span>

                            </div>
                            <div class="form-group col-md-5">
                                <div>
                                    <label for="comment"> Comentario</label>
                                </div>

                                <textarea formControlName="comment" class="form-control form-control-solid form-control-lg"
                                    id="comment" placeholder=""></textarea>

                            </div>

                            <ng-template [ngIf]="noWorkingDayMatchWithDates.length>0">
                                <div class="form-group col-md-4">
                                    <label class="d-block w-100">
                                        Fechas festivas detectadas
                                    </label>
                                    <ul class="list-group">
                                        <li class="list-group-item" *ngFor="let item of noWorkingDayMatchWithDates">
                                            <span class="text-danger">
                                                <i class="favicon-dot"></i>
                                                Celebración: {{item.celebration}} se encuentra en la fecha: {{item.date |
                                                date:"dd/MM/yyyy"}}.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </ng-template>
                            <!-- <div class="form-group col-md-4">
                                <div>
                                    <label for="" class="">
                                        ¿Desea pago de bono vacacional?
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label mt-2 check-dtl">
                                        <input formControlName="bond" name="bond" value="true" type="radio" class="form-check-input mt-2" id="payDays"/>
                                            Sí
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label mt-2 check-dtl">
                                        <input formControlName="bond" name="bond" value="false" type="radio" class="form-check-input mt-2" id="payDays"/>
                                            No
                                    </label>
                                </div>
                                <span for="" class="d-block text-muted" >Si es afirmativo, Debe tomar al menos 5 días laborables.
                                    Art.177
                                </span>
                            </div> -->
                        </div>
                        <div class="row mt-2" *ngIf="isEditingVacancy">
                            <div class="text-primary pt-4">
                                <h4>Información de la Modificación</h4>
                                <hr>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Motivo de la Modificación Anterior </label>
                                        <input type="text" class="form-control form-control-lg" [value]="vacationForm.get('oldModificationReason')?.value?.stringData" readonly/>
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="form-group">
                                        <label>Comentario de la Modificación Anterior</label>
                                        <textarea [value]="vacationForm.get('oldModificationComment')?.value" class="form-control"
                                            rows="6" readonly></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Motivo de la Modificación Actual <span class="text-danger">*</span></label>
                                        <ngx-select-dropdown formControlName='newModificationReason' [options]='modificationReasons'
                                            [config]='reasonsConfig' class="flex-1" (change)="changeVacationModificationReason($event?.value)">
                                        </ngx-select-dropdown>
                                        <span class="text-danger"
                                            *ngIf="vacationFormInvalid && vacationForm.controls['newModificationReason']?.errors?.required">Este
                                            campo es requerido</span>
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="form-group">
                                        <label>Comentario de la Modificación Actual</label>
                                        <textarea formControlName="newModificationComment" class="form-control"
                                            rows="6"></textarea>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </form>
                </div>
                <div class="col-md-4">
                    <h2 class="text-primary gutter-b">Días Disponibles</h2>
                    <table class="gutter-b table atbl table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left">Período</th>
                                <th class="text-center">Días Tomados</th>
                                <th class="text-center">Días Disponibles</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of vacationYears">
                                <td class="text-left">{{item.year}}-{{item.yearTo}}</td>
                                <td class="text-center">{{ item.daysTaken }}</td>
                                <td class="text-center">{{ item.availableDays }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!--ng-container *ngFor="let item of totals">
                        <div class="mb-2 d-flex bg-dark-o-50 justify-content-center" >

                            <span class="font-size-h4-md font-size-h6-sm">
                                <b>Disponibles:</b> {{item.days}} día(s)
                            </span>
                            <span class="font-size-h4-md font-size-h6-sm ml-2">
                                <b>Restando un total de:</b> {{item.deduct}}
                            </span>
                            <span class="font-size-h4-md font-size-h6-sm ml-2">
                                <b>Correspondientes al Período:</b> {{item.year}}-{{item.yearTo}}
                            </span>
                        </div>
                    </ng-container-->
                </div>
                <div class="row">
                    <div class="form-group d-flex justify-content-end align-items-center">
                        <button *ngIf="!isInDetailsVacancy" (click)="submitVacationForm();"
                        class="mr-2 btn btn-success btn-pill font-weight-bolder mr-3">
                        <i class="flaticon2-plus"></i> {{isEditingVacancy ? 'Guardar Modificación' : 'Agregar Nueva'}}
                    </button>
                    <button (click)="clearVacancyForm(isEditingVacancy ? true : false);" class="btn btn-danger btn-pill font-weight-bolder ">
                        {{isEditingVacancy ? 'Cancelar Modificación' : 'Limpiar campos'}}
                    </button>
                    </div>
                </div>
            </div>


            <div class="row gutter-b gutter-t">
                <h2 class="text-primary gutter-b d-flex justify-content-between flex-wrap">
                    Historial de Vacaciones
                </h2>
                <div class="table-responsive">
                    <table class="table atbl table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-center">Secuencia</th>
                                <th class="text-left">Tipo de vacaciones</th>
                                <th class="text-left">Fecha Inicio</th>
                                <th class="text-left">Fecha Término</th>
                                <th class="text-left">Período al que Pertenecen</th>
                                <th class="text-center">Total de Días</th>
                                <th class="text-center">Estatus</th>
                                <th class="actionsBtn">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of vacationData">
                                <td class="text-center">{{ item.vacancyId }}</td>
                                <td class="text-left">
                                    {{ item.vacationType }}
                                </td>
                                <td class="text-left">
                                    {{ item.dateStart | date: "dd/MM/yyyy" }}
                                </td>
                                <td class="text-left">
                                    {{ item.dateEnd | date: "dd/MM/yyyy" }}
                                </td>
                                <td class="text-left">{{ item.year }}-{{ item.yearTo }}</td>
                                <td class="text-center">{{ item.days }}</td>
                                <td class="text-center">
                                    <strong class="text-danger" *ngIf="!item?.status"> Inactiva </strong>
                                    <strong class="text-success" *ngIf="item?.status && !item?.paused"> Activa </strong>
                                    <strong class="text-warning" *ngIf="item?.status && item?.paused"> Pausada </strong>
                                </td>
                                <td class="actionsBtn">
                                    <!-- <a (click)="showVacancy(item)" data-toggle="modal" data-target="#fVacations" class="btn btn-icon btn-light-skype btn-sm m-1">
                                        <i class="flaticon-ver" data-toggle="tooltip" title="Ver" ></i>
                                    </a> -->
                                    <a class="btn btn-icon btn-light-info btn-sm m-1"
                                        *ngIf="privilege.viewPdfVacation.value" (click)="openReportPDF(item)">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                                    </a>
                                    <a *ngIf="privilege.editVacation.value && item?.status == true && !item?.paused" (click)="editVacancy(item)"
                                        class="btn btn-icon btn-light-warning btn-sm m-1">
                                        <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                                    </a>
                                    <a *ngIf="privilege.deleteVacation.value && !isEditingVacancy && item?.status == true" (click)="removeVacation(item)"
                                        class="btn btn-icon btn-light-danger btn-sm m-1">
                                        <i class="flaticon-delete" data-toggle="tooltip" title="Remover"></i>
                                    </a>
                                    <a *ngIf="!isEditingVacancy && item?.canBePaused" (click)="openPauseVacationModal(item, viewTypes.Create)"
                                        class="btn btn-icon btn-light-danger btn-sm m-1">
                                        <i class="flaticon-reloj" data-toggle="tooltip" title="Pausar Vacaciones"></i>
                                    </a>
                                    <a *ngIf="!isEditingVacancy && item?.status == true && item?.paused" (click)="openPauseVacationModal(item, viewTypes.View)"
                                        class="btn btn-icon btn-light-info btn-sm m-1">
                                        <i class="flaticon-reloj" data-toggle="tooltip" title="Ver Vacaciones Pausadas"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row gutter-b">
                <div class="table-responsive gutter-b  gutter-t">
                    <h2 class="text-primary gutter-b">Historial Laboral</h2>
                    <table class="gutter-b table atbl table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left">Secuencia</th>
                                <th class="text-left">Institución Gubernamental</th>
                                <th class="text-left">Cargo</th>
                                <th class="text-center">Cantidad de meses</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of historyWorkRecords">
                                <td class="text-left">{{ item.workExperienceId }}</td>
                                <td class="text-left">{{ item.company }}</td>
                                <td class="text-left">{{ item.jobTitle }}</td>
                                <td class="text-center">{{ item.totalMonths }}</td>

                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex bg-dark-o-50 justify-content-center" style="margin-top: 25px !important">
                        <span class="font-size-h4-md font-size-h6-sm">
                            <b>Años acumulados:</b> {{cumulativeYears}}
                        </span>
                        <span class="font-size-h4-md font-size-h6-sm ml-2">
                            <b>Cantidad de días proporcionales:</b> {{correspondingDays}}
                        </span>
                    </div>
                    <div>
                        <el-caption #paginator [originalRecordsLength]="historyWork.length"
                            [listedRecordsLength]="historyWorkRecords.length" [registerQuantity]="historyWork.length"
                            [records]="historyWork" (onChage)="getPaginatedRecordsHistory($event)"></el-caption>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="permisos" role="tabpanel" aria-labelledby="permisos-tab"
            *ngIf="privilege.permission.value">
            <ng-container *ngIf="privilege.addPermission.value">
                <form [formGroup]="permissionForm">
                    <div class="row mb-4 mt-10" *ngIf="isEditingPermission && (currentPermissionConditionOnEditting == 2) && currentPermissionEdittingIsWithoutPayroll == true">
                        <div class="alert alert-warning d-flex align-items-center">
                            <i class="fas fa-exclamation-triangle mr-2 fa-2x blink-for-warning"></i>
                            <div  >
                                Esta acción de personal ya fue <strong>aprobada</strong>, modificarla puede causar alteraciones en la <strong>nómina</strong> y otros módulos del <strong>SIGEI</strong>. En caso de que la acción de personal haya sido aplicada en una
                                nómina u otro proceso dependiente de la misma, su modificación <strong>NO</strong> será <strong>permitida</strong>.
                            </div>
                        </div>

                    </div>
                    <div class="row mt-10">

                        <div class="col-md-2">
                            <div class="form-group">
                                <label class="d-block">
                                    Tipo de Permiso<span class="text-danger"> *</span>
                                </label>
                                <ngx-select-dropdown formControlName="idTypePermissions" [options]="typePermisions"
                                    [disabled]="!this.personSelected?.status || isSeviceCommission"
                                    (change)="changeTypePermison($event.value, isEditingPermission)" [config]="dropdownConfigPermision"
                                    class="w-100"></ngx-select-dropdown>
                                <span
                                    *ngIf="this.permissionForm?.controls['idTypePermissions']?.errors?.required && permissionFormInvalid"
                                    class="text-danger">Este campo es requerido</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Fecha de Solicitud <span class="text-danger">*</span></label>
                                <input formControlName="applicationDate" type="date"
                                    class="form-control form-control-lg" />
                                <span style="position: absolute;"
                                    *ngIf="this.permissionForm?.controls['applicationDate']?.errors?.required && permissionFormInvalid"
                                    class="text-danger">Este campo es requerido</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Fecha de Inicio <span class="text-danger">*</span></label>
                                <input formControlName="dateStart" (input)="handleDateStartChange()" type="date"
                                    class="form-control form-control-lg" />
                                <span style="position: absolute;"
                                    *ngIf="this.permissionForm?.controls['dateStart']?.errors?.required && permissionFormInvalid"
                                    class="text-danger">Este campo es requerido</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Fecha de Término <span class="text-danger">*</span></label>
                                <input formControlName="dateEnd" [min]="getDateStart ? minDateAllow : ''"
                                    [max]="getMaxDate" type="date" class="form-control form-control-lg" />
                                <span
                                    *ngIf="this.permissionForm?.controls['dateEnd']?.errors?.required && permissionFormInvalid"
                                    class="text-danger">Este campo es requerido</span>
                                <span
                                    *ngIf="this.permissionForm?.controls['dateEnd']?.errors?.daysAllowed && permissionFormInvalid"
                                    class="text-danger">No puede superar el limite de días permitidos</span>
                                <span *ngIf="permissionDateInvalid" class="text-danger">La fecha de término debe ser
                                    superior a la fecha de inicio</span>
                                <!-- <span *ngIf="daysAllowedIsInvalid" class="text-danger">
                                    Este permiso tiene como días permitidos: {{this.permissionForm.value.idTypePermissions.daysAllowed || 0 }}
                                </span> -->
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Hora de Inicio <span *ngIf="permissionHasHours"
                                        class="text-danger">*</span></label>
                                <input formControlName="hourStart" type="time" class="form-control form-control-lg" />
                                <span style="position: absolute;"
                                    *ngIf="this.permissionForm?.controls['hourStart']?.errors?.required && permissionFormInvalid"
                                    class="text-danger">Este campo es requerido</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Hora de Fin <span *ngIf="permissionHasHours" class="text-danger">*</span></label>
                                <input formControlName="hourEnd" type="time" class="form-control form-control-lg" />
                                <span style="position: absolute;"
                                    *ngIf="this.permissionForm?.controls['hourEnd']?.errors?.required && permissionFormInvalid"
                                    class="text-danger">Este campo es requerido</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="row flex-wrap justify-content-between">
                                <div class="col-lg-12"
                                    *ngIf="permissionForm?.controls['idTypePermissions']?.value?.requiredMotive == true">
                                    <div class="form-group">
                                        <label>Motivo del Permiso <span class="text-danger">*</span></label>
                                        <textarea formControlName="reason" class="form-control"></textarea>
                                        <span style="position: absolute;"
                                        *ngIf="this.permissionForm?.controls['reason']?.errors?.required && permissionFormInvalid"
                                        class="text-danger">Este campo es requerido</span>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Comentario </label>
                                    <textarea formControlName="comment" class="form-control" rows="6"></textarea>
                                </div>
                              </div>
                                <div class="col-lg-12">
                                    <div class="form-group ">

                                        <div class="form-check form-check-inline">
                                            <input type="checkbox" (change)="changeCheckboxPermision($event)"
                                                [checked]="checkPermissionRecurrent"
                                                class="form-check-input dynamicFormControl" />
                                            <label class="d-block  mt-2">Recurrencia
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <!--div>
                                            <label for="" class="d-block">
                                                Recurrencia
                                                <span class="font-italic"></span>
                                            </label>
                                        </div-->
                                        <div class="form-check form-check-inline">
                                            <input (change)="changeDay()" type="checkbox" formControlName="monday"
                                                class="form-check-input dynamicFormControl mr-2 ng-valid ng-dirty ng-touched"
                                                value="1" />
                                            <label class="form-check-label"> Lunes </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input (change)="changeDay()" type="checkbox" formControlName="tuesday"
                                                class=" form-check-input dynamicFormControl mr-2 ng-valid ng-dirty ng-touched "
                                                value="2" />
                                            <label class="form-check-label"> Martes </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input (change)="changeDay()" type="checkbox" formControlName="wednesday"
                                                class="
                                                form-check-input
                                                dynamicFormControl
                                                mr-2
                                                ng-valid ng-dirty ng-touched
                                            " value="3" />
                                            <label class="form-check-label"> Miércoles </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input (change)="changeDay()" type="checkbox" formControlName="thursday"
                                                class="
                                                form-check-input
                                                dynamicFormControl
                                                mr-2
                                                ng-valid ng-dirty ng-touched
                                            " value="4" />
                                            <label class="form-check-label"> Jueves </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input (change)="changeDay()" type="checkbox" formControlName="friday"
                                                class="
                                                form-check-input
                                                dynamicFormControl
                                                mr-2
                                                ng-valid ng-dirty ng-touched
                                            " value="5" />
                                            <label class="form-check-label"> Viernes </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input (change)="changeDay()" type="checkbox" formControlName="saturday"
                                                class="
                                                form-check-input
                                                dynamicFormControl
                                                mr-2
                                                ng-valid ng-dirty ng-touched
                                            " value="6" />
                                            <label class="form-check-label"> sábado </label>
                                        </div>
                                    </div>
                                    <span *ngIf="permissionDaysInvalid" class="text-danger">Este campo es
                                        requerido</span>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col"
                                    *ngIf="this.personSelected?.status && this.personSelected.employeeCondition != 1">
                                    <div class="form-group">
                                        <label>Evidencia <span id="requiredPermision"
                                                class="text-danger"></span></label>
                                        <div class="card align-items-center">
                                            <!-- <div class="card-body">
                                                <div
                                                    [ngClass]="{'showUpload': file.document=='', 'NotShowUpload' : file.document!=''}">
                                                    <div class="evidence"></div>
                                                </div>
                                                <div *ngIf="file.document !=''">
                                                    <div class="symbol symbol-150 d250 my-4 max-w-250px">
                                                        <img alt="Pic" src="assets/images/cv.jpg" />
                                                    </div>
                                                    <div class="btn-group center" role="group">
                                                        <button type="button" class="btn btn-primary btn-icon"
                                                            (click)="showDocument(file.document, 'Ver Evidencia')">
                                                            <i class="flaticon-ver"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-danger btn-icon"
                                                            (click)="deleteGuid()">
                                                            <i class="flaticon-delete-1"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <uppy-file 
                                                #uppyFilePermission
                                                previewDefaultImage="assets/images/cv.jpg"
                                                classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px"
                                                targetClass="evidence"
                                                [width]="320"
                                                [height]="295"
                                                [isDisabled]="!this.personSelected?.status || this.isSuspended == true"
                                                [showDocumentoAfterUploaded]="false"
                                                [fileForEditting]="guiID"
                                                [allowedFileTypes]="['application/pdf']"
                                                (fileEmitter)="setEvidencePermission($event)">
                                            </uppy-file>
                                        </div>

                                    </div>
                                        <span style="position: absolute;"
                                        *ngIf="this.permissionForm?.controls['evidence']?.errors?.required && permissionFormInvalid"
                                        class="text-danger">Este campo es requerido</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="isEditingPermission">
                        <div class="text-primary pt-4">
                            <h4>Información de la Modificación</h4>
                            <hr>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Motivo de la Modificación Anterior </label>
                                    <input type="text" class="form-control form-control-lg" [value]="permissionForm?.get('oldModificationReason')?.value?.stringData" readonly/>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label>Comentario de la Modificación Anterior</label>
                                    <textarea [value]="permissionForm?.get('oldModificationComment')?.value" class="form-control"
                                        rows="6" readonly></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Motivo de la Modificación Actual <span class="text-danger">*</span></label>
                                    <ngx-select-dropdown formControlName='newModificationReason' [options]='modificationReasons'
                                        [config]='reasonsConfig' class="flex-1" (change)="changeModificationReason($event?.value)">
                                    </ngx-select-dropdown>
                                    <span class="text-danger"
                                        *ngIf="permissionFormInvalid && permissionForm.controls['newModificationReason']?.errors?.required">Este
                                        campo es requerido</span>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label>Comentario de la Modificación Actual</label>
                                    <textarea formControlName="newModificationComment" class="form-control"
                                        rows="6"></textarea>
                                </div>
                            </div>
                        </div>


                    </div>

                </form>

                <div class="row">
                    <div class="form-group d-flex justify-content-end align-items-center">
                        <button  (click)="savePermission()" class="
                        btn btn-pill
                        font-weight-bolder
                        btn-shadow btn-success
                        mr-3">
                            <i class="flaticon-disco-flexible"></i>
                            <span> {{isEditingPermission ? 'Guardar Modificación' : 'Guardar'}}  </span>
                        </button>

                        <button (click)="clearPermission(isEditingPermission ? true  : false);" class="btn btn-danger btn-pill font-weight-bolder">
                            {{isEditingPermission ? 'Cancelar Modificación' : 'Limpiar campos'}}
                        </button>
                    </div>
                </div>
            </ng-container>

            <div class="row gutter-b">
                <h2 class="text-primary gutter-b d-flex justify-content-between flex-wrap">
                    Historial de Permiso
                </h2>
                <div class="table-responsive">
                    <table class="table atbl table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left">Secuencia</th>
                                <th class="text-left">Tipo de Permiso</th>
                                <th class="text-left">Fecha de Solicitud</th>
                                <th class="text-left">Fecha de Inicio</th>
                                <th class="text-left">Fecha de Término</th>
                                <th class="text-left">Hora de Inicio</th>
                                <th class="text-left">Hora de Fin</th>
                                <th class="text-left">Motivo del Permiso</th>
                                <th>Evidencia</th>
                                <th class="text-left">Estatus</th>
                                <th class="text-center">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let permission of listPermisos">
                                <td class="text-left">{{permission.permissionsId}}</td>
                                <td class="text-left">{{(permission.typePermissions)}}
                                </td>
                                <td class="text-left">{{permission.applicationDate |date: 'dd/MM/yyy'}}</td>
                                <td class="text-left">{{permission.dateStart |date: 'dd/MM/yyy'}}</td>
                                <td class="text-left">{{permission.dateEnd |date: 'dd/MM/yyy'}}</td>
                                <td class="text-left">{{permission.hourStartText1 }}</td>
                                <td class="text-left">{{permission.hourEndText1 }}</td>
                                <td class="text-left">
                                    <div class="reasonPermiso">
                                        {{permission.reason || "N/A"}}
                                    </div>
                                </td>
                                <td>
                                    <a *ngIf="permission.evidence" (click)='openGuID(permission.evidence)'
                                        data-toggle="tooltip" title="Evidencia"
                                        class="btn btn-light-youtube font-weight-bold mb-3">
                                        <i class="flaticon-pdf-file icon-2x"></i>
                                    </a>
                                    <p *ngIf="!permission.evidence">N/A</p>
                                </td>
                                <td>
                                    <ng-container *ngIf="!permission?.status; else conditions">
                                        <span class="font-weight-bold text-danger">{{permission?.conditionId === 10 ? 'Anulado' : 'Inactivo'}}</span>
                                    </ng-container>
                                    <ng-template #conditions>
                                        <ng-container *ngIf="permission?.conditionId === 6">
                                            <span class="font-weight-bold text-warning">En Espera de Aprobación</span>
                                        </ng-container>
                                        <ng-container *ngIf="permission?.conditionId === 2">
                                            <span class="font-weight-bold text-success">Aprobado</span>
                                        </ng-container>
                                        <ng-container *ngIf="permission?.conditionId === 3">
                                            <span class="font-weight-bold text-danger">Rechazado</span>
                                        </ng-container>
                                        <ng-container *ngIf="permission?.conditionId === 10">
                                            <span class="font-weight-bold text-danger">Anulado</span>
                                        </ng-container>
                                    </ng-template>
                                </td>
                                <td class="actionsBtn">
                                    <!--button *ngIf="permission.conditionId === 0 || true"
                                        (click)="approvePermission(permission.permissionsId)" data-toggle="tooltip"
                                        title="" class="btn btn-sm btn-light-success btn-icon m-1"
                                        data-original-title="Aprobar">
                                        <i class="flaticon-etiqueta icon-2x"></i>
                                    </button>
                                    <button *ngIf="permission.conditionId === 0 || true"
                                        (click)="rejectPermission(permission.permissionsId)" data-toggle="tooltip"
                                        title="" class="btn btn-sm btn-light-danger btn-icon m-1"
                                        data-original-title="Rechazar">
                                        <i class="flaticon-etiqueta icon-2x"></i>
                                    </button-->
                                    <button *ngIf="privilege.viewPdfPermission.value"
                                       (click)="exportPermission(permission)"
                                        class="btn btn-sm btn-light-info btn-icon mr-2">
                                        <i class="flaticon-ver" data-toggle="tooltip" title="Ver"></i>
                                    </button>
                                    <button (click)="editPermision(permission)"
                                        *ngIf="permission?.conditionId !== 3 && permission?.conditionId !== 10 && privilege.editPermission.value && permission?.status == true"
                                        class="btn btn-icon btn-light-warning btn-sm m-1">
                                        <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                                    </button>
                                    <button *ngIf="permission.conditionId == 6 && privilege.approvePermission.value && !isEditingPermission && permission?.status == true"
                                        (click)="approvePermission(permission)" type="button"
                                        class="btn btn-sm btn-light-success btn-icon mr-2">
                                        <i class="flaticon-checked" data-title="Aprobar" data-toggle="tooltip"></i>
                                    </button>
                                    <button *ngIf="permission.conditionId == 6 && privilege.rejectPermission.value && !isEditingPermission && permission?.status == true"
                                        (click)="rejectPermissionModal(permission)"
                                        type="button" class="btn btn-sm btn-light-danger btn-icon mr-2">
                                        <i class="flaticon2-cross" data-title="Rechazar" data-toggle="tooltip"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="licencias" role="tabpanel" aria-labelledby="licencias-tab"
            *ngIf="privilege.licenses.value">
            <app-licenses-tab (refreshLicenses)="refreshLicenses($event)" [isSeviceCommission]="isSeviceCommission"
                [isSuspended]="isSuspended"
                [employeeSelected]="personSelected" [licenses]="licenses" [vacationList]="vacationData"
                (getVacationList)="getHistoryWork()" [modificationReasons]="modificationReasons"></app-licenses-tab>
        </div>
        <div class="tab-pane fade" id="externalServices" role="tabpanel" aria-labelledby="extServices-tab"
            *ngIf="privilege.externalService.value">
            <app-external-service-tap [employeeData]="personSelected" [vacationData]="vacationData"
                [isSuspended]="isSuspended"
                [licenseData]="licenses" [servicesList]="servicesList"></app-external-service-tap>
        </div>
        <div class="tab-pane fade" id="commission" role="tabpanel" aria-labelledby="commission-tab"
            *ngIf="privilege.serviceCommission.value">
            <app-service-commission [isSeviceCommission]="isSeviceCommission" [employee]="personSelected"
                [isSuspended]="isSuspended"
                [commissions]="commissions" [vacationList]="vacationData"></app-service-commission>
        </div>
        <div class="tab-pane fade" id="disciplinaryMeasures" role="tabpanel" aria-labelledby="disciplinaryMeasures-tab" *ngIf="privilege.disciplinaryActions.value">
            <app-disciplinary-measures [isInSeviceCommission]="isSeviceCommission"
            [isSuspended]="isSuspended"
             [employeeInfo]="personSelected" [modificationReasons]="modificationReasons"></app-disciplinary-measures>
        </div>
        <!--div class="tab-pane fade" id="banns" role="tabpanel" aria-labelledby="banns-tab"
        *ngIf="privilege.bans.value">
            <app-banns [isSeviceCommission]="isSeviceCommission" [employee]="personSelected" [banns]="banns"></app-banns>
        </div-->
    </div>


</div>
