import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { AuthInfoService } from 'src/app/services/auth-info.service';

@Component({
  selector: 'app-occupational-groups-report',
  templateUrl: './occupational-groups-report.component.html',
  styleUrls: ['./occupational-groups-report.component.css']
})
export class OccupationalGroupsReportComponent implements OnInit {

  form = new FormGroup({
    institution: new FormControl(''),
  })

  enableNumbersOnly = false
  constructor(
    private location: Location,
    private authInfo: AuthInfoService,
  ) { }

  ngOnInit(): void { }


  generate(){
    const {institution, id } = this.form.value
    if(this.form.invalid){
      return
    }
    let url = environment.reportUrl + `?ReportCode=rrhh_occupational_group&EmployeeId=0&CompanyId=${this.authInfo.getCompanyId()}`
    openReport({url, title: 'Title', width: '900', height: '700'})
  }

  goBack() {
    this.location.back();
  }

}
