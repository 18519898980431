import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewsConceptGET } from '@payroll/payroll-concept/models';
import { ToastrService } from 'ngx-toastr';
import { PayrollConceptService } from 'src/app/services/payroll-concept.service';
import { ConceptValues } from 'src/app/shared/models/conceptValues.model';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { DefaultValueSettingComponent } from './default-value-setting/default-value-setting.component';

@Component({
  selector: 'app-default-value-settings',
  templateUrl: './default-value-settings.component.html',
  styleUrls: ['./default-value-settings.component.css']
})
export class DefaultValueSettingsComponent implements OnInit {
  @Input() conceptValues: ConceptValues[] = [];
  @Input() data: {inDetails: boolean, item: NewsConceptGET, inEdit: boolean}
  sweetAlrt = new SweetAlert();
  constructor(public dialog: MatDialog, private toastr: ToastrService,   private srvConcept: PayrollConceptService,) { }

  ngOnInit(): void {
  }

  openModalAdd(item = null, inDetails = false, inEdit = false, titleModal: string = 'Agregar Valor Predefinido', index = null){
    this.dialog.open(DefaultValueSettingComponent, {
      data: {
        item,
        inDetails,
        inEdit,
        titleModal
      },
    }).afterClosed().subscribe({
      next: (res) => {
        if(res?.success ){
            const conceptValues: ConceptValues = res?.conceptValues;
            if(inEdit){
                conceptValues.newsConceptId = this.data?.item?.newsConceptId;
                this.conceptValues[index] = {
                    ...this.conceptValues[index],
                    description: conceptValues.description,
                    value: conceptValues.value
                }
            }else{
                if(this.data?.inEdit){
                    conceptValues.newsConceptId = this.data?.item?.newsConceptId;
                }
                this.conceptValues = [...this.conceptValues, conceptValues];
            }
        }
      }
    })
  }

  deleteConceptValues(conceptValues: ConceptValues, index){
    if(conceptValues?.conceptValuesId > 0){
        this.sweetAlrt.AlertConfirm("Inactivar", "¿Está seguro de inactivar este registro?", "question").then(res => {
            if (res) {
              this.srvConcept.deleteConceptValues(conceptValues.conceptValuesId).subscribe((result) => {
                this.sweetAlrt.Default("Inactivado", "Registro inactivado satisfactoriamente", "success");
                this.getDefaultValuesByConceptValuesId(this.data?.item?.newsConceptId);
              }, err => {
                this.toastr.error(err, "Ocurrió un error tratando de inactivar el registro")
              });
            }
        });
        return;
    }
    this.conceptValues.splice(index, 1);
  }

  getDefaultValuesByConceptValuesId(id){
    this.srvConcept.getConceptValues(id).subscribe((res: any) => {
      if(res.errors.length > 0){
        this.toastr.error(res.errors[0]);
        return;
      }
      this.conceptValues = res?.dataList;
    });
  }

}