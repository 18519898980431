
<app-modal-layout (onClose)="close()" styles="min-height: 130px">
    <ng-container title>
        Rechazar Nómina
    </ng-container>
    <ng-container body>
        <div class="bg-light-info rounded d-flex h-100 align-items-center row mb-2">
            <div class="col-12 d-flex justify-content-center  flex-column">
                <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Secuencia: <span class="text-dark ml-3">{{data.item.submissionId || ""}}</span></h3>
                <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Tipo Servidor Público: <span class="text-dark ml-3">{{data.item.employeeType}}</span></h3>
                <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Nombre de Archivo:<span class="text-dark ml-3">{{data.item.sentFileName}}</span></h3>
                <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Fecha de Envío:<span class="text-dark ml-3">{{data.item.createDate | date: 'dd/MM/yyyy' || ""}}</span></h3>
                <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Descripción: <span class="text-dark ml-3">{{data.item.description || ""}}</span></h3>
                <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Coletilla: <span class="text-dark ml-3">{{data.item.coletilla || ""}}</span></h3>
                <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">
                    Estatus:
                    <span class="text-dark ml-3">
                        {{data.item?.status}}
                    </span>
                </h3>
                
            </div>
        </div>
                <div class="form-group col-md-12 mt-5">
                    <label>Motivo del rechazo: </label>
                    <textarea [(ngModel)]="reason" class="form-control"></textarea>
                </div>
        


    </ng-container>
<ng-container buttons>
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button (click)="reject()" class="btn btn-pill font-weight-bolder btn-shadow btn-success d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon-checked"></i>
        Rechazar
    </button>
    
</ng-container>
</app-modal-layout>
