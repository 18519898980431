<div class="container">
    <div class="row d-flex justify-content-end">

    </div>
    <div class="row">
        <div class="col-12">

            <div class="col-md-12 gutter-t gutter-b">
                <div class="card card-custom border-primary p-0">
                    <div class="card-header mt-3">
                        <div class="card-title align-items-center">
                            <h3 class="text-primary m-0">Estudios Realizados</h3>
                        </div>
                        <div class="card-toolbar">
                            <button class="btn btn-pill btn-shadow btn-success max-w-200px mr-3" type="button"
                                data-target="#addEs" data-toggle="modal" (click)="reset()">
                                <i class="flaticon2-plus"></i>
                                <span> Agregar Estudios</span>
                            </button>
                        </div>
                    </div>
                    <div class="card-body p-0 min-h-100px">
                        <div class="table-responsive">
                            <table class="table mt-2 table-vertical-center">
                                <thead>
                                    <tr class="bg-primary">
                                        <th>Nivel Académico</th>
                                        <th>Carrera</th>
                                        <th>Casa de Estudio</th>
                                        <!--  <th>Índice Académico</th>-->
                                        <th>Fecha de Término</th>
                                        <th>Comentario</th>
                                        <th class="actionsBtn">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let m of academicData; let i = index">
                                        <td>{{getDescripcionACLevel(m.academicLevelId)}}</td>
                                        <td>{{getDescripcionACGrado(m.careerId)}}</td>
                                        <td>{{m.studyHouse}}</td>
                                        <!--    <td>{{m.academicIndex}}</td>-->
                                        <td>{{m.endYear}}</td>
                                        <td>{{m.comment}}</td>
                                        <td class="actionsBtn">
                                            <button type="button" class="btn btn-sm btn-light-warning btn-icon mr-2"
                                                data-title="Editar" data-target="#addEs" data-toggle="modal"
                                                (click)="edit(m,i, m.careerId)">
                                                <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                                            </button>
                                            <button type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                                data-title="Remover" (click)="removeAC(i)">
                                                <i class="flaticon-delete" data-toggle="tooltip"
                                                    data-title="Eliminar"></i>
                                            </button>
                                            <button type="button" class="btn btn-sm btn-light-info btn-icon mr-2"
                                                data-title="Ver documento" (click)="viewDocument(m)">
                                                <i class="flaticon-ver" data-toggle="tooltip"
                                                    data-title="Ver documento"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<el-modal target="addEs" [title]="'Agregar Estudios Realizados'" size="xl" (onClose)="resetDataCareer();">
    <div class="row">
        <div class="form-group col-md-6">
            <label class="d-block w-100">Nivel académico obtenido <span class="text-danger">*</span></label>
            <div class="dropdown-alt input-group">
                <ngx-select-dropdown (change)="changeAcademicLevel()" class="flex-1 custom-dropdown" [config]='configACL'
                    [(ngModel)]="academicLevelSelected" [options]='academicLevelList'></ngx-select-dropdown>
                <!-- <select (change)="changeAcademicLevel()" class="form-control  form-control-solid form-control-lg" 
                [(ngModel)]="academicLevelSelected.ocode">
                    <option  *ngFor="let m of academicLevelList" [ngValue]="m.ocode">{{m.stringData}}</option>
                </select> -->
            </div>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-6">
            <label class="d-block w-100">Estudios realizados <span class="text-danger">*</span></label>
            <div class="dropdown-alt input-group">

                <ngx-select-dropdown (change)="selectCarrer($event)" class="flex-1 custom-dropdown" [config]='config'
                    [(ngModel)]="carrer" [options]='careerList'></ngx-select-dropdown>
                <!-- <select class="form-control  form-control-solid form-control-lg" [(ngModel)]="data.careerId">
                    <option *ngFor="let m of careerList" [ngValue]="m.careerId">{{m.description}}</option>
                </select> -->
            </div>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-6">
            <label>Institución educativa <span class="text-danger">*</span></label>
            <input class="form-control form-control-solid form-control-lg" placeholder="" type="text" required=""
                [(ngModel)]="data.studyHouse">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <!--
        <div class="form-group col-md-6">
            <label>Índice académico</label>
            <input class="form-control form-control-solid form-control-lg" placeholder="" type="number"
                [(ngModel)]="data.academicIndex">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>-->
    </div>
    <div class="row">
        <div class="form-group col-md-6">
            <label>Año de término <span class="text-danger">*</span></label>
            <input [min]="0" class="form-control form-control-solid form-control-lg" placeholder="" type="text"
                oninput="this.value=(parseInt(this.value)||0)" required [(ngModel)]="data.endYear" mask="0000">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
            <label class="mt-4">Comentario</label>
            <textarea [(ngModel)]="data.comment" class="form-control"></textarea>
        </div>
        <div class="col-md-6">
            <div class="form-group card">
                <div class="card mb-5 full-h">
                    <uppy-file #uppyFile
                    previewDefaultImage="assets/images/ver_file.jpg"
                    classPreviewDefaultImage="symbol symbol-150 d200 my-4 max-w-200px"
                    [allowedFileTypes]="['application/pdf', 'image/*']"
                    [fileForEditting]="data?.documentPhoto"
                    (changeEmitter)="changeDetector()"
                    (fileEmitter)="setFormFile($event)">
                        <label>Evidencia <span class="text-danger">*</span></label>
                    </uppy-file>
                </div>
            </div>
        </div>
    </div>
    <div class="row gutter-t">
        <div class="form-group col-md-12 d-flex justify-content-end border-top pt-3 m-0">
            <button (click)="resetDataCareer()" id="btnremove"
                class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2" data-dismiss="modal"
                type="button">
                <i class="flaticon2-cross"></i>
                <span>Cerrar</span>
            </button>
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
                (click)="addAC();" [disabled]="!btnDisabledAC()">
                <i class="flaticon-disco-flexible"></i>
                <span> Agregar </span>
            </button>
        </div>
    </div>
</el-modal>