<app-modal-layout (onClose)="close()">
    <ng-container title>
        Beneficiarios Disponibles
    </ng-container>
    <ng-container body>

        <div class="row">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th>Beneficiario</th>
                            <th>RNC</th>
                            <th class="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let bene of beneficiaries" style="left: 0px;">
                            <td>{{bene?.stringData || 'N/A'}}</td>
                            <td>{{bene?.numericData || 'N/A'}}</td>
                            <td class="text-center">
                                <input type="checkbox" [checked]="bene?.selected" (click)="selectBene(bene)" class="form-check-input mr-2">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        </div>

    </ng-container>
    <ng-container buttons>
        <button (click)="close()"
            class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2"
            type="button">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
        <button (click)="save()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success"
            type="button">
            <i class="flaticon-disco-flexible"></i>
            <span>Seleccionar Beneficiario</span>
        </button>
    </ng-container>
</app-modal-layout>