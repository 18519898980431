import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionEditComponent } from '@payroll/action-edit/action-edit.component';
import { ToastrService } from 'ngx-toastr';
import { IDictionaryView } from 'src/app/models/competency';
import { CompetencyService } from 'src/app/services/performance-evaluation/competency.service';
import { PositionCompetency } from '../models/positions-description.model';

@Component({
  selector: 'modal-competency-dictionary-search',
  templateUrl: './modal-competency-dictionary-search.component.html',
  styleUrls: ['./modal-competency-dictionary-search.component.css']
})
export class ModalCompetencyDictionarySearchComponent implements OnInit, AfterViewChecked {
  titleModal = "Agregar Capacidades y Competencias";
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  filterText: string = '';
  selectedItems: any[] = [];

  dictionary: PositionCompetency[] = [];
  dictionaryRecord: PositionCompetency[] = [];
  dictionaryOriginalList: PositionCompetency[] = [];
  
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "dictionarySequence", displayName: "Secuencia" },
      { propkey: "competencyType", displayName: "Tipo de Competencias" },
      { propkey: "competencyName", displayName: "Competencia" },
      { propkey: "grade", displayName: "Grado"},
      { propkey: "capabilityDescription", displayName: "Capacidades"}
  ];

  constructor(
    public dialogRef: MatDialogRef<ModalCompetencyDictionarySearchComponent>,
    private dialog: MatDialog,
    private competencyService: CompetencyService,
    private toastService: ToastrService,  private _changeDet: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { competency: PositionCompetency[], gradeId: number}
  ) { }

  ngOnInit(): void {
    this.loadDictionary();
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }
  
  loadDictionary() {
    this.competencyService.getCompetencyDictionary(4).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })
          return;
        }
        const dictionary: PositionCompetency[] = (res?.dataList as any[])
        ?.filter(d => (d?.gradeId !== null && d?.status) && !this?.data?.competency
          ?.some(c => c?.dictionarySequence === d?.dictionarySequence)) as  PositionCompetency[];
        this.dictionary = [...dictionary];
        this.dictionaryOriginalList = [...dictionary];
      },
      error: (err) => {
        this.toastService.error("Ha ocurrido un error consultando el diccionario de competencias.");
      }
    })
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = event.records
  }

  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Click para desplegar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  selectCompetency(competency: PositionCompetency){
    competency.selected = !competency?.selected;
  }

 
  setFilterToLocalStorage() {
    localStorage.setItem('postions-list_filter', JSON.stringify(this.selectedItems));
  }

  getPaginatedRecords(event) {
    this.dictionaryRecord = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  Cancel(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

  Accept() {
    const row = { selected: true, competency: this.dictionary.filter(d => d?.selected)};
    this.dialogRef.close(row);
  }

}