import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ProcessPayrollService } from 'src/app/services/process-payroll.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { getMask } from 'src/app/shared/utils/utility';
import { ExpenseItemsComponent } from '../expense-items/expense-items.component';
import { IPayrollExpenseDeparturePOST, PayrollFileUpload } from '../models/payrollExpense';
import { ISendPayrollFilePOST } from '../models/SendPayrollFile';
import { StateSigeiComponent } from '../state-sigei/state-sigei.component';
@Component({
  selector: 'app-expense-detail-modal',
  templateUrl: './expense-detail-modal.component.html',
  styleUrls: ['./expense-detail-modal.component.css']
})
export class ExpenseDetailModalComponent implements OnInit {
  budgetaryProceses = []
  sending = false
  headerId;
  documentTypes = []
  transactionTypes = []
  specificSources = []
  employeeTypes = []
  paginatedItems = []
  partidaGastosId = 0;
  items: any[] = []
  formIsInvalid = false;
  form: FormGroup;
  mask: string = ''
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ExpenseDetailModalComponent>,
    private formBuilder: FormBuilder,
    private srvProcess: ProcessPayrollService,
    private toastr: ToastrService,
    private srvAuth: AuthInfoService,
    private srvParameter: ParameterControlService,
    @Inject(MAT_DIALOG_DATA) public data: { headerId: number, mode: 'send' | 'save' }
  ) {
    this.buildForm()
  }

  ngOnInit(): void {
    this.headerId = this.data.headerId
    this.sending = this.data.mode === 'send'
    if (this.sending) {
      const inputs = ['preventiveDocument', 'transactionType', 'budgetProcess', 'documentType', 'beneficiary',
        'amount', 'program', 'subProgram', 'project', 'activityWork', 'object', 'fundingOrganizations', 'source',
        'region', 'province', 'municipality', 'function']
      this.disableInputs(inputs)
    }
    this.getDropdownsData()
    this.getDocumentTypes()
    this.getEmployeeTypes()
  }

  disableInputs(name: string[]) {
    name.forEach(e => {
      this.form.get(e).disable()
    })
  }

  openPartidasGastos(): void {
    this.dialog.open(ExpenseItemsComponent, {
      width: '50%'
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return
      }
      this.items.push(result)
    });
  }

  fillForm(data) {
    const form = this.form
    form.get('employeeType').patchValue(this.employeeTypes.find(x => x.employeeTypeId == data.employType))
    form.get('transactionType').patchValue(this.transactionTypes.find(x => x.codigo == data.tipoTransaccion))
    form.get('documentType').patchValue(this.documentTypes.find(x => x.ccode == data.tipoDocumento))
    form.get('source').patchValue(this.specificSources.find(x => x.code == data.fondo))
    form.get('budgetProcess').patchValue(this.budgetaryProceses.find(x => x.budgetaryid == data.procesoPresupuestario))
    form.get('preventiveDocument').patchValue(data.documentoPreventivo)
    form.get('beneficiary').patchValue(data.beneficiario)
    form.get('amount').patchValue(data.importe)
    form.get('program').patchValue(data.programa)
    form.get('project').patchValue(data.projecto)
    form.get('fundingOrganizations').patchValue(data.organismoFinanciador)
    form.get('activityWork').patchValue(data.actividadObra)
    form.get('object').patchValue(data.objetal)
    form.get('region').patchValue(data.region)
    form.get('municipality').patchValue(data.municipal)
    form.get('function').patchValue(data.funcion)
    form.get('subProgram').patchValue(data.subPrograma)
    form.get('province').patchValue(data.provincia)
    form.get('companyId').patchValue(data.companyId ?? this.srvAuth.getCompanyId())
    this.setMask()
  }

  save() {
    // this.formIsInvalid = this.form.invalid

    // if (this.items.length == 0) {
    //   this.toastr.warning("Debe agregar la partida de gastos")
    //   return
    // }

    // if (this.formIsInvalid) {
    //   return
    // }
    // const values = this.form.value
    // let payroll: IPayrollExpenseDeparturePOST = {
    //   actividadObra: values.activityWork.toString(),
    //   companyId: values.companyId,
    //   documentoPreventivo: values.preventiveDocument,
    //   employType: values.employeeType.employeeTypeId,
    //   fuente: '',
    //   fuenteEspecifica: values.source.codeFinancialSource,
    //   fondo: values.source.code,
    //   versionTransaccion: values.transactionType.version,
    //   tipoTransaccion: values.transactionType.codigo,
    //   funcion: values.function.toString(),
    //   municipal: values.municipality.toString(),
    //   objetal: values.object.toString(),
    //   organismoFinanciador: values.fundingOrganizations.toString(),
    //   programa: values.program.toString(),
    //   projecto: values.project.toString(),
    //   region: values.region.toString(),
    //   status: true,
    //   subPrograma: values.subProgram.toString(),
    //   provincia: values.province.toString(),
    //   headerId: this.headerId,
    //   partidaGastosDetalle: this.items,
    //   partidaGastosId: this.partidaGastosId,
    //   procesoPresupuestario: values.budgetProcess.budgetaryid,
    //   beneficiario: values.beneficiary.toString(),
    //   importe: values.amount.toString(),
    //   tipoDocumento: values.documentType.ccode,
    //   createUserId: this.srvAuth.getUserId()
    // }
    // this.srvProcess.postPayrollExpense(payroll).subscribe((res: any) => {
    //   if (!res.succeded) {
    //     this.toastr.error(res.errors[0])
    //     return
    //   }
    //   this.toastr.success('Datos guardados')
    //   this.close()
    // }, err => {
    //   this.toastr.error('Ha ocurrido un error inesperado')
    // })
  }

  changeEmployeeType() {
    this.items = []
    const employeeTypeId = this.form.get('employeeType').value.employeeTypeId
    this.resetForm()
    if (employeeTypeId) {
      this.getPayrollExpensesFilter(employeeTypeId)
    }

  }

  send() {
    const companyId = this.form.value.companyId ?? this.srvAuth.getCompanyId()
    const employeeTypeId = this.form.value.employeeType.employeeTypeId
    const headerId = this.headerId
    const send: ISendPayrollFilePOST = {
      companyId,
      employeeType: employeeTypeId,
      headerId,
      userId: this.srvAuth.getUserId()
    }

    if (this.items.length == 0) {
      this.toastr.warning("Debe agregar la partida de gastos")
      return
    }

    this.srvProcess.send(send).subscribe((res: any) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })

        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })
        return
      }

      let data: PayrollFileUpload = res.singleData

      if (data.operacion == "exitosa") {
        this.toastr.success("Proceso terminado exitosamente.")
        this.close()
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }

  getPayroll() {
    this.srvProcess.getPayrollExpense().subscribe((res: any) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })

        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })
        return
      }

    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }

  getPayrollExpensesFilter(employeeType) {
    this.srvProcess.getPayrollExpenseFilter(employeeType, this.headerId).subscribe((res: any) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })

        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })
        return
      }
      const data = res.singleData.partidaGastos
      if (!data.partidaGastosId) {
        this.partidaGastosId = 0;
        return
      }
      this.partidaGastosId = data.partidaGastosId
      this.items = res.singleData.partidaGastosDetalle

      this.fillForm(data)

    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }

  resetForm() {
    this.partidaGastosId = 0
    const form = this.form
   // form.get('employeeType').patchValue(null)
    form.get('transactionType').patchValue(null)
    form.get('documentType').patchValue(this.documentTypes.find(x => x.ccode == 'R'))
    form.get('source').patchValue('')
    form.get('budgetProcess').patchValue(null)
    form.get('preventiveDocument').patchValue(null)
    form.get('beneficiary').patchValue('')
    form.get('amount').patchValue(0)
    form.get('program').patchValue('')
    form.get('project').patchValue('')
    form.get('fundingOrganizations').patchValue('')
    form.get('activityWork').patchValue('')
    form.get('object').patchValue('')
    form.get('region').patchValue('')
    form.get('municipality').patchValue('')
    form.get('function').patchValue('')
    form.get('subProgram').patchValue('')
    form.get('province').patchValue('')
    form.get('companyId').patchValue(this.srvAuth.getCompanyId())
  }

  getDocumentTypes() {
    this.srvParameter.getParameters(keyWord.IDType).subscribe((res: any) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })

        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })
        return
      }
      this.documentTypes = res.dataList.filter(x => x.ccode == 'C' || x.ccode == 'R')
      if (!this.sending) {
        this.form.get('documentType').patchValue(this.documentTypes.find(x => x.ccode == 'R'))
      }

    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }

  getEmployeeTypes() {
    this.srvProcess.getEmployeeTypes(this.headerId).subscribe((res: any) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })

        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })
        return
      }
      this.employeeTypes = res.dataList
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }



  getDropdownsData() {
    const year = new Date().getUTCFullYear()
    this.srvProcess.getPayrollCatalogs(year).subscribe((res: any) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        })

        res.warnings.forEach(warn => {
          this.toastr.warning(warn);
        })
        return
      }
      const data = res.singleResultData
      this.budgetaryProceses = data.budgetaryProceses
      this.transactionTypes = data.transactionTypes
      this.specificSources = data.specificSources

      // if (!this.sending) {
      //   this.form.get('source').patchValue(this.specificSources.find(x => x.code == '0100'))
      // }


    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }

  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  getError(name: string) {
    if (!this.formIsInvalid) {
      return ''
    }
    const field = this.form.get(name)
    if (field.hasError('required')) {
      return 'Este campo es obligatorio'
    }
    if (field.hasError('min')) {
      return 'Este campo es obligatorio'
    }
    return ''
  }
  getPaginatedRecords(event) {
    this.paginatedItems = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  close() {
    this.dialogRef.close()
  }
  buildForm() {
    this.form = this.formBuilder.group({
      employeeType: ['', [Validators.required]],
      preventiveDocument: ['', [Validators.required]],
      transactionType: ['', [Validators.required]],
      budgetProcess: ['', [Validators.required]],
      documentType: ['', [Validators.required]],
      beneficiary: ['', [Validators.required]],
      amount: [0, [Validators.required, Validators.min(1)]],
      program: ['', [Validators.required]],
      subProgram: ['', [Validators.required]],
      project: ['', [Validators.required]],
      activityWork: ['', [Validators.required]],
      object: ['', [Validators.required]],
      fundingOrganizations: ['', [Validators.required]],
      source: ['', [Validators.required]],
      region: ['', [Validators.required]],
      province: ['', [Validators.required]],
      municipality: ['', [Validators.required]],
      function: ['', [Validators.required]],
      companyId: [this.srvAuth.getCompanyId()]
    })
  }

  changeDocType(){
    this.form.get('beneficiary').patchValue('')
    this.setMask()
  }

  setMask(){
    this.mask = ''
    const field = this.form.get("documentType")
    if(!field.value) return
    const ocode = field.value.ocode
    const mask = getMask(ocode)
    this.mask = mask
  }

}