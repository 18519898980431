<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon">
                <i class="flaticon-lista-de-verificacion-3 icon-3x text-danger"></i>
            </span>
            <h1>
                Aplicar a Vacantes
                <!-- <small>sub title</small> -->
            </h1>
        </div>
        <div class="card-toolbar">

            <button routerLink="/apply-vacancies-list" class="btn btn-pill font-weight-bolder btn-shadow btn-warning">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
            </button>

        </div>
    </div>
    <div class="card-body">
        <div class="row">

            <!-- <div class="form-group col-xl-4 col-lg-6">
                <label>Postulante</label>
                <input placeholder="" disabled type="text" name=""
                    class="form-control form-control-solid form-control-lg" [(ngModel)]="PersonSelected.fullName">
            </div> -->

            <div class="col-md-12">
                <div *ngIf="PersonSelected" class="bg-light-info rounded row h-100">
                    <div class="text-center col-md-3 center">
                        <div class="symbol symbol-150 d250 rd20 mr-3 my-5 center">
                            <img *ngIf="!this.PersonSelected.profileImage" alt="Pic" src="./assets/media/no-user.png" />
                            <img *ngIf="this.PersonSelected.profileImage" alt="Pic"
                                [src]="this.PersonSelected.photoloaded" />
                        </div>
                    </div>
                    <div class="col-md-9 d-flex justify-content-center flex-column">
                        <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Nombre: <span
                                class="text-dark ml-3">{{PersonSelected.fullName}}</span></h3>
                        <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Identificación:
                            <span class="text-dark ml-3"
                                *ngIf="PersonSelected.typeIdentificationId == 1">{{PersonSelected.personalIdentification
                                | mask:'000-0000000-0'}}</span>
                            <span class="text-dark ml-3"
                                *ngIf="PersonSelected.typeIdentificationId != 1">{{PersonSelected.personalIdentification}}</span>
                        </h3>
                        <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Teléfono:
                            <span class="text-dark ml-3">{{PersonSelected.phoneNumber | mask: "(000) 000-0000"}}</span>
                        </h3>
                        <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Correo Electrónico: <span
                                class="text-dark ml-3">{{PersonSelected.email}}</span></h3>
                        <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Nivel Académico: <span
                                class="text-dark ml-3">{{PersonSelected.academicLevel}}</span></h3>
                    </div>
                </div>
            </div>

        </div>
        <div class="row gutter-t gutter-b">
            <div class="card card-custom border-primary">
                <div class="card-header mt-3">
                    <div class="card-title align-items-center">
                        <i class="flaticon-etiqueta icon-2x text-primary mr-3"></i>
                        <h2 class="text-primary m-0">Vacantes Disponibles</h2>
                    </div>
                    <div class="card-toolbar ml-auto">
                        <div class="d-flex align-items-center flex-grow-1 mb-3">
                            <div class="flex-shrink-0 mr-2">
                                <span class="switch switch-sm switch-primary">
                                    <strong *ngIf="!appliedVacancy"
                                        class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                        No aplicadas
                                    </strong>
                                    <strong *ngIf="appliedVacancy"
                                        class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                        Aplicadas
                                    </strong>
                                    <label>
                                        <input [disabled]='!this.PersonSelected.personId'
                                            (change)='vacancyToggle()' [checked]='false'
                                            class="cursor-pointer abc-checkbox-primary" [(ngModel)]="appliedVacancy"
                                            type="checkbox" />
                                        <span></span>
                                    </label>
                                </span>
                            </div>

                            <!--div class="flex-shrink-0 mr-2 mt-2">
                                <div class="d-flex justify-content-center">
                                    <button class="btn btn-light-primary"
                                    data-toggle="modal" data-target="#filterByDate">
                                        <i class="fas fa-filter" data-toggle="tooltip"
                                            data-title="Filtrar por Fecha"></i>
                                    </button>
                                </div>
                            </div-->
                    <app-search-bar-pagination #searchBar
                    (onFilter)="filterSearch($event)" 
                    [parameters]="searchBarDisplayNames">
                    </app-search-bar-pagination>
                        </div>

                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table mt-2 table-vertical-center">
                            <thead>
                                <tr class="bg-primary">
                                    <th class="text-center">Secuencia</th>
                                    <th>Cargo Vacante</th>
                                    <th class="text-left">Fecha Publicación</th>
                                    <th class="text-left">Tipo de Vacante</th>
                                    <th class="text-left">Tipo de Requisición</th>
                                    <th class="text-left">Sexo</th>
                                    <th>Unidad Organizativa</th>
                                    <th class="text-left">Sucursal</th>
                                    <th class="text-center">Estatus</th>
                                    <th class="actionsBtn">Acciones</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let vacancy of Vacancies">
                                    <td class="text-center">{{vacancy.vacantId}}</td>
                                    <td>{{vacancy.positionName}}</td>
                                    <td class="text-left">{{vacancy.publicationDate | date: 'dd/MM/yyyy'}}</td>
                                    <td class="text-left">{{vacancy.vacancyType}}</td>
                                    <td class="text-left">{{vacancy.requisitionType? vacancy.requisitionType : 'N/A' }}</td>
                                    <td class="text-left">{{vacancy.gender || 'N/A' }}</td>
                                    <td>{{vacancy.department}}</td>
                                    <td class="text-left">{{vacancy.companyName}}</td>
                                    <td class="text-center" *ngIf="this.appliedVacancy">
                                        <span style="width: 137px;">
                                            <span class="label label-success label-dot mr-2"></span>
                                            <span class="font-weight-bold text-success">Aplicada</span>
                                        </span>
                                    </td>
                                    <td class="text-center" *ngIf="!this.appliedVacancy">
                                        <span style="width: 137px;">
                                            <span class="label label-danger label-dot mr-2"></span>
                                            <span class="font-weight-bold text-danger">No aplicada</span>
                                        </span>
                                    </td>
                                    <td class="actionsBtn">
                                        <button *ngIf="!appliedVacancy"
                                            class="btn btn-sm btn-light-success btn-icon mr-2"  (click)="VacancyDetail(vacancy,false)">
                                            <i class="flaticon-etiqueta icon-2x " data-toggle="tooltip"
                                                data-title="Aplicar"></i>
                                        </button>
                                        <button *ngIf="appliedVacancy" 
                                            class="btn btn-sm btn-light-skype btn-icon mr-2"
                                            (click)="VacancyDetail(vacancy, true)">
                                            <i class="flaticon-ver icon-2x" data-toggle="tooltip" data-title="Ver"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-end align-items-center flex-wrap">
                            <app-paginator
                            [listedRecordsLength]="Vacancies?.length"
                            [registerQuantity]="employeesPagedResult.totalItems"
                            [registerPerPage]="employeesPagedResult.pageSize"
                            [totalPages]="employeesPagedResult.totalPages"
                            [selectedPage]="employeesPagedResult.page"
                            (onChange)="getPaginatedRecords($event)">
                            </app-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>