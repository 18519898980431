import { Component, Inject, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApplyVacanciesDashboardService } from 'src/app/services/apply-vacancies-dashboard.service';
import { BarOptions, ChartOptions } from 'src/app/shared/models/apexChartModels';

import { getPercentage } from 'src/app/shared/utils/utility';

enum stats {
  published = 1,
  notpublished = 0,
  female = 1,
  male = 2,
  undefined = 3,
  ITLA = 1,
  ITLASD = 2,
  ITLAST = 3
}
interface AgeStats {
  age: number;
  count: number;
}
@Component({
  selector: 'app-apply-vacancies-dashboard',
  templateUrl: './apply-vacancies-dashboard.component.html',
  styleUrls: ['./apply-vacancies-dashboard.component.css']
})
export class ApplyVacanciesDashboardComponent implements OnInit, OnDestroy {
  ageRanges: { text: string, values: number[] }[] = [
    { text: '17- años', values: [0, 17] },
    { text: '18 a 25 años', values: [18, 25] },
    { text: '26 a 35 años', values: [26, 35] },
    { text: '36 a 45 años', values: [36, 45] },
    { text: '46 a 55 años', values: [46, 55] },
    { text: '56+ años', values: [56, Number.MAX_VALUE] }
  ];
  loadingMessage = 'Cargando...'
  noDataMessage = 'No hay datos'
  countryChart = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }
  academicLevelChart = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }
  ageChart = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }
  genderChart = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }
  public chartOptions: Partial<ChartOptions>;
  public barOptions: Partial<BarOptions>;
  public chartOptions2: Partial<ChartOptions>;

  dashboardData: any

  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  amountMale: { total: number, percentage: number } = {
    total: 0,
    percentage: 0
  };
  amountFemale: { total: number, percentage: number } = {
    total: 0,
    percentage: 0
  };
  amountTotal: number = 0;
  filtered = false
  filterParams = {
    year: null,
    date1: null,
    date2: null
  }
  suscriptions = new Subscription()

  constructor(
    public dialogRef: MatDialogRef<ApplyVacanciesDashboardComponent>,
    public dialog: MatDialog,
    private srvApplyDashboard: ApplyVacanciesDashboardService,
    private toastr: ToastrService
  ) {
    this.chartOptions = {
      series: [45, 30, 25],
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold',
          colors: ['#ffffff', '#ffffff', '#ffffff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      stroke: {
        width: 0
      },
      title: {
        text: ""
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      labels: ["ITLA Santo Domingo", "ITLA Santiago", "ITLA Sede"],
      legend: {
        show: true,
        position: 'bottom'
        },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
              position: 'bottom',
            }
          }
        }
      ]
    };
    this.chartOptions2 = {
      series: [5, 45],
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      stroke: {
        width: 0
      },
      title: {
        text: ""
      },
      labels: ["Femenino", "Masculino"],
      fill: {
        colors: ["#f11565", "#0481af"]
      },
      colors: ["#f11565", "#0481af"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
              position: 'bottom',
            }
          }
        }
      ]
    };
    this.barOptions = {
      series: [
        {
          name: "Mujeres",
          data: [44, 55, 41, 37, 22, 43, 21]
        },
        {
          name: "Hombres",
          data: [53, 32, 33, 52, 13, 43, 32],
        },


      ],
      chart: {
        type: "bar",
        width: 400,
        height: 300,
        stacked: true
      },
      colors: ["#fb7866", "#6489fb"],
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      title: {
        text: ""
      },
      xaxis: {
        labels: {
          show: false
        },

      },
      yaxis: {
        title: {
          text: undefined
        },
        labels: {
          style: {
            colors: ['#033877'],
            fontSize: '15px',
            fontWeight: "bolder",
            cssClass: 'apexcharts-yaxis-label',
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "K";
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40
      }
    }

  }
  ngOnDestroy(): void {
    this.suscriptions.unsubscribe()
  }
  openDialog(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  ngOnInit(): void {
    this.geDashboardData()

  }

  geDashboardData() {
    let sub = this.srvApplyDashboard.getAll().subscribe((res: any) => {
      if (res.succeded) {
        this.dashboardData = res.singleData;
        this.setDaashboardData()
      } else {
        this.toastr.error(res.errors[0])
      }
    }, error => {
      this.toastr.error(this.errorMessage)
    })
    this.suscriptions.add(sub)
  }

  setDaashboardData() {
    this.getCountry()
    this.getGender()
    this.getAllTotal()
    this.getAgeStats()
    this.getAcademicLevel()
  }


  getAllTotal() {
    this.amountTotal = this.dashboardData.personsVacantsTotalQuantities[0]?.value ?? 0;
  }
  getCountry() {
    this.countryChart.status = this.loadingMessage
    let d = this.dashboardData.personsVacantsByNationalityQuantities;
    if (d.lenght < 1) { this.countryChart.status = this.noDataMessage; return; }
    this.countryChart.labels = []
    this.countryChart.series = []
    d.forEach((e) => {
      this.countryChart.labels.push(e.description);
      this.countryChart.series.push(e.value)
    })
  }

  getAcademicLevel() {
    this.academicLevelChart.status = this.loadingMessage
    let d = this.dashboardData.personsVacantsByAcademiclevelQuantities.filter(x => x.description != null);
    if (d.lenght < 1) { this.academicLevelChart.status = this.noDataMessage; return; }
    this.academicLevelChart.labels = []
    this.academicLevelChart.series = []
    d.forEach((e) => {
      this.academicLevelChart.labels.push(e.description);
      this.academicLevelChart.series.push(e.value)
    })
  }

  getAgeStats() {
    let data: AgeStats[] = this.dashboardData.personsVacantsByAgeQuantities
        if (data.length < 1) { this.ageChart.status = this.noDataMessage; return; }
        this.ageChart.labels = []
        this.ageRanges.forEach(element => {
          this.ageChart.labels.push(element.text);
          this.ageChart.series.push(this.getStatsByAgeRange(element.values, data))
        });
  }
  getStatsByAgeRange(range, arr:any[]): number {
    let value = 0;
    let a = arr.filter(x => x.description >= range[0] && x.description <= range[1]);
    a.forEach((element) => {
      value += element.value;
    })
    return value;
  }

  getGender() {

    let genders = this.dashboardData.personsVacantsByGenderQuantities
   
    genders.forEach(e => {
      this.genderChart.labels.push(e.description)
      this.genderChart.series.push(e.value)

    })
  }

  getPercentage(total: number, count: number){
    return getPercentage(total, count)
  }

}
