import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Period } from '@payroll/schedule/models/period.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BudgetArea } from '../models/budget-area';
import { Header } from '../models/header';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { AuthInfoService } from './auth-info.service';
import { RejectPayrollEmployeeType } from '@payroll/process-payroll/models/SendPayrollFile';

@Injectable({
  providedIn: 'root'
})
export class PayrollService {
  api = environment.api_url
  constructor(private http: HttpClient, private authInfo: AuthInfoService) { }

  getHeaders(type:number, payrollPeriodId:number ) {
    return this.http.get(this.api + `/Header?type=${type}&PayrollPeriodId=${payrollPeriodId}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getHeaderbyId(id) {
    return this.http.get(this.api + `/Header?HeaderId=${id}&type=2&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getGetDashBoardHeaders( dateFrom:string, dateTo:string, companyId: number, status: boolean = true) {
    if(dateFrom == null){
      dateFrom = `2000-01-01`
    }
    if(dateTo == null){
      dateTo = `${new Date().getFullYear()}-12-31`

    }
    return this.http.get(`${this.api}/Header/GetDashBoard?CompanyId=${companyId}&Status=${status}&DateFrom=${dateFrom}&DateTo=${dateTo}`)
  }

  createHeader(newHeader: Header | any) {
    return this.http.post(this.api + '/Header', newHeader)
  }

  getPeriods() {
    return this.http.get(this.api + `/Period?CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getPeriodById(id) {
    return this.http.get(this.api + `/Period?PeriodId=${id}CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getBudgetAreas() {
    return this.http.get(this.api + `/BudgetArea?CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getBudgetAreaById(budgetAreaId: number) {
    return this.http.get(this.api + `/BudgetArea?BudgetAreaId=${budgetAreaId}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  createBudgetArea(newArea: BudgetArea) {
    return this.http.post(this.api + '/BudgetArea', newArea)
  }

  updateBudgetArea(newArea: BudgetArea) {
    return this.http.put(this.api + '/BudgetArea', newArea)
  }

  deleteBudgetArea(obj) {
    return this.http.put(this.api + `/BudgetArea/inactive`, obj)
  }

  getPayrollDetailByDepartments(headerId: number) {
    return this.http.get(this.api + `/Detail/GetDetailByDeparments?HeaderId=${headerId}&Type=0&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getPayrollDetailResume(headerId: number) {
    return this.http.get(this.api + `/Detail/GetDetailResume?HeaderId=${headerId}&Type=1&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  getPayrollDetails(headerId: number, type: number = 2) {
    //type 2 = get by headerId
    return this.http.get(this.api + `/Detail/GetOverallSummary?HeaderId=${headerId}&Type=${type}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)

  }

  getPayrollDetailsByPTA(paryrollPeriodId: number, payrollTypeId: number, budgetAreaId: number, type: number = 3) {

    //type 3 = get by others
    return this.http.get(this.api + `/Detail/GetOverallSummary?PayrollPeriodId=${paryrollPeriodId}&PayrollTypeId=${payrollTypeId}&BudgetAreaId=${budgetAreaId}&Type=${type}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)

  }

  getPayrollNewsToApply(payrollPeriodId: number, companyId) {

    //type 3 = get by others
    return this.http.get(this.api + `/PayrollNews/GetPayrollNewsToApply?PayrollPeriodId=${payrollPeriodId}&type=1&CompanyId=${companyId}&Status=true`)

  }
  getPayrollNewsToApply2(payrollPeriodId: number, BudgetAreaId, PayrollType, relatedPeriodId: number = 0, NewsConcepts:string, HeaderId: number = 0) {

    const companyId = this.authInfo.getCompanyId()
    return this.http.get(this.api + `/PayrollNews/GetPayrollNewsToApply?PayrollPeriodId=${payrollPeriodId}&BudgetAreaId=${BudgetAreaId}&PayrollType=${PayrollType}&type=3&CompanyId=${companyId}&Status=true&PayrollPeriodIdRelated=${relatedPeriodId}&NewsConcepts=${NewsConcepts}&HeaderId=${HeaderId}`)

  }

  getPayrollNewsByEmployee(headerId: number, employeeId: number) {
    return this.http.get(this.api + `/NewsApplied?NewsAppliedId=0&HeaderId=${headerId}&EmployeeId=${employeeId}&Type=2&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  setNewsApplied(PayrollPeriodId: number, BudgetAreaId: number, PayrollTypeId: number, NewsConcepts:string, PayrollPeriodIdRelated: number ,VersionId: number) {
    return this.http.post(this.api + `/Payroll/NewsApplied?PayrollPeriodId=${PayrollPeriodId}&BudgetAreaId=${BudgetAreaId}&PayrollTypeId=${PayrollTypeId}&CompanyId=${this.authInfo.getCompanyId()}&NewsConcepts=${NewsConcepts}&Status=true&PayrollPeriodIdRelated=${PayrollPeriodIdRelated}&VersionId=${VersionId}&UserId=${this.authInfo.getUserId()}`, '')
  }

  getPayrollNewDetail(PayrollPeriodId: number, EmployeeId: number, CompanyId: number = this.authInfo.getCompanyId(), Status: boolean = true){
    return this.http.get(`${this.api}/PayrollNews/GetPayrollNewsToApply?PayrollPeriodId=${PayrollPeriodId}&EmployeeId=${EmployeeId}&type=2&CompanyId=${CompanyId}&Status=${Status}`);
  }

  getPayrollReject(PayrollRejectId: number, CompanyId: number = this.authInfo.getCompanyId()){
    return this.http.get(`${this.api}/PayrollReject?PayrollRejectId=${PayrollRejectId}&Type=2&CompanyId=${CompanyId}&Status=true`);
  }
  getEmployeeTypes(){
    return this.http.get(`${this.api}/EmployeeType?&CompanyId=${this.authInfo.getCompanyId()}`);
  }

  getReportsEmployeeTypes(headerId:number){
    return this.http.get(`${this.api}/EmployeeType?HeaderId=${headerId}&Type=2&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }

  getPayrollVersions(PayrollPeriodId: number,  PayrollTypeId: number, BudgetAreaId: number, CompanyId: number = this.authInfo.getCompanyId()){
    return this.http.get(`${this.api}/Header?PayrollPeriodId=${PayrollPeriodId}&PayrollTypeId=${PayrollTypeId}&BudgetAreaId=${BudgetAreaId}&type=13&CompanyId=${CompanyId}&Status=true`)
  }

  getPayrollColetilla(PayrollHeaderId: number, employeeTypeId:number, type?){
    return this.http.get<ResponseModel<any>>(`${this.api}/Header/Coletilla?HeaderId=${PayrollHeaderId}&EmployType=${employeeTypeId}&type=${type || 2}`)
  }

  getPayrollVersionsAdditional(PayrollPeriodId: number,  PayrollTypeId: number, BudgetAreaId: number, relatedPeriod:number, type:number = 3, CompanyId: number = this.authInfo.getCompanyId()){
    return this.http.get(`${this.api}/Header?PayrollPeriodId=${PayrollPeriodId}&PayrollTypeId=${PayrollTypeId}&PayrollPeriodIdRelated=${relatedPeriod}&BudgetAreaId=${BudgetAreaId}&type=${type}&CompanyId=${CompanyId}&Status=true`)
  }

  getBeforePeriod(): Observable<ResponseModel<Period>>{
    return this.http.get<ResponseModel<Period>>(
      `${this.api}/period?Type=8&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }

  getHeadersByOptions(options: {
    payrollPeriodIdRelated, type, companyId, payrollPeriodId, status
  }) {
    let params = new HttpParams()
    params = params.append('PayrollPeriodIdRelated', options.payrollPeriodIdRelated)
    params = params.append('PayrollPeriodId', options.payrollPeriodId)
    params = params.append('Status', options.status)
    params = params.append('CompanyId', options.companyId)
    params = params.append('Type', options.type.toString())
    return this.http.get(`${this.api}/Header`, { params })
  }
  getHeaderByOptions(options: {
    type, companyId, headerId
  }) {
    let params = new HttpParams()
    params = params.append('HeaderId', options.headerId)
    params = params.append('CompanyId', options.companyId)
    params = params.append('Type', options.type.toString())
    return this.http.get(`${this.api}/Header/GetHeader`, { params })
  }

  getEmployeeInPayroll(HeaderId: number, EmployeeTypeId: number, type: number){
    return this.http.get<ResponseModel<any>>(`${this.api}/Employees/GetEmployeeInPayroll?HeaderId=${HeaderId}&EmployeeTypeId=${EmployeeTypeId}&type=${type}&companyId=${this.authInfo.getCompanyId()}&status=true`)
  }

  rejectPayrollEmployeeType(rejectPayrollEmployeeType: RejectPayrollEmployeeType){
    return this.http.post<ResponseModel<null>>(`${this.api}/Detail/RejectPayrollEmployeeType`, rejectPayrollEmployeeType);
  }
  excludeEmployeePayroll(model){
    return this.http.post(`${this.api}/Detail/ExcludeEmployeePayroll`, model)
  }

  rejectPayrollFilesHacienda(model){
    return this.http.post(`${this.api}/payroll/rejectPayrollSubmission`, model)
  }

}
