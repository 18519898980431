<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        {{title}} detalle de regalia
    </h2>
        <button type="button" (click)="this.dialogRef.close({created:false});" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    </div>

<div class="mat-dialog-content">
    <div class="card-body" style="height: 35em">
        <div class="row" [formGroup]="royaltyDetailForm">
            <div class="form-group col-md-12">
                <div class="row align-items-center">
                    <div class="col-md-8">
                        <label for="employeeName">Servidor Publico<span class="text-danger text-monospace legend">*</span></label>
                        <input (input)="removeWhitespace($event, royaltyDetailForm.get('employeeName'))" type="text" formControlName="employeeName" class="form-control form-control-lg dynamicFormControl" id="employeeName" required="" />

                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-primary mt-8" (click)='openEmployeeModal()'>Buscar servidor publico</button>
                        
                    </div>
                </div>
            </div>
            
            <div class="form-group col-md-12">
                <label>Concepto <span class="text-danger"> *</span></label>
                <ngx-select-dropdown [config]="newsConceptListConfig" [options]="newsConceptList" formControlName="newsConceptId" class=" flex-1" > </ngx-select-dropdown>
                <span class="text-danger"></span>
            </div>

            <div class="form-group col-md-12">
                <label>Periodo <span class="text-danger"> *</span></label>
                <ngx-select-dropdown [config]="periodsConfig" [options]="periods" formControlName="periodMonth" class=" flex-1" > </ngx-select-dropdown>
                <span class="text-danger"></span>
            </div>

            <div class="form-group col-md-12">
                <div>
                    <label for="amount">Regalia estimada<span class="text-danger text-monospace legend">*</span></label>
                </div>
                <input (input)="removeWhitespace($event, royaltyDetailForm.get('amount'))" type="number" formControlName="amount" class="form-control form-control-lg dynamicFormControl" id="amount"required="" />
            </div>
            <div class="form-group col-md-12">
                <div>
                    <label>Evidencia<span class="text-danger text-monospace legend">*</span></label>
                </div>
                <uppy-file #uppyFile
                    classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px" [cleanAll]="false"
                    [allowedFileTypes]="['application/pdf', 'image/*']"
                    [fileForEditting]="img1"
                    targetClass="doc1">
                </uppy-file>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="closeModal()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button [disabled]="royaltyDetailForm.invalid" (click)="SubmitroyaltyDetail()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>