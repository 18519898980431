<div class="container-fluid ">
    <div class="card card-custom">
        <div class="card-header mt-3  d-flex justify-content-between">
            <div class="card-title ">
                <div class="ttl d-flex align-items-center ">
                    <i class="flaticon-proceso-2 icon-3x text-danger mr-2 forward-btn"></i>
                    <h1 class="text-primary forward-btn">Reporte de Nómina</h1>
                </div>
            </div>
            <div class="card-toolbar">
                <button (click)="goBack()" class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
                    <i class="flaticon2-left-arrow-1"></i>
                    <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
                </button>
            </div>
        </div>
        <div class="card-header row justify-content-between p-2 mx-0 ">

            <div class="col-sm-12 col-md-8 c-left forward-btn">
                <div class="card-toolbar d-flex justify-content-start" [formGroup]="form">
                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Año
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown [options]="yearsList" (change)="changeYear($event)"
                                    [config]="dropdownConfig('description')" class="w-100"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Período<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown (change)="getPayrollVersions()" formControlName="period"
                                    [options]="filteredPeriods" [config]="dropdownConfig('description')" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Área Presupuestaria<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown (change)="getPayrollVersions()" [options]="budgetAreaList"
                                    formControlName="area" [config]="dropdownConfig('description')" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Tipo de Nómina<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown (change)="changePayrollType($event?.value)" formControlName="payrollType"
                                    [options]="payrollTypes" [config]="dropdownConfig('stringData')" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 m1-2 px-0 pt-1" *ngIf="getPayrollTypeSeleted?.ocode === 4">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Período Anterior<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown
                                [disabled]="disableFilters"
                                [options]="relatedPeriods"
                                formControlName="relatedPeriod"
                                [config]="dropdownConfig('description')" class="w-100"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Versión<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown formControlName="version" [options]="payrollVersions"
                                    [config]="dropdownConfig('versionDescription')" (change)="onPayrollVersionChange($event.value)" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Tipo de Servidor Público<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <!-- <ngx-select-dropdown formControlName="employeeType" [options]="employeeType"
                                    [config]="dropdownConfig('description')" [multiple]="true" class="w-100">
                                </ngx-select-dropdown> -->

                                <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'"
                                    [settings]="dropdownSettings" [data]="employeeType" formControlName="employeeType">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-sm-12 col-md-4 c-right mr-0 forward-btn">
                <div class="card-toolbar d-flex justify-content-end">
                    <button data-toggle="tooltip" data-original-title="Generar Reporte" (click)="getOverallSummary()"
                        *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.searchAction)"
                        class="btn btn-success btn-pill font-weight-bolder mt-9 mx-2 col-md-auto ">
                        <i class="flaticon2-search-1 icon-2x"></i>
                        Generar Reporte
                    </button>
                    <button [disabled]="overallSummary.length === 0"
                        class="btn btn-primary btn-pill font-weight-bolder mt-9 mx-2 col-md-auto" type="button"
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.exportAction)">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item align-items-center" (click)="generateTemplate()">
                            <i class="fa fa-print icon-2x text-primary mr-3"></i>
                            Visualizar Reporte
                          </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-0">
            <div class="container-fluid" *ngIf="overallSummary.length > 0">
                <div id="pdfExp">
                    <div class="card-header border-bottom-0 px-0">
                        <div class="row" id="header">
                            <div class="title p-2 d-flex flex-column align-items-center flex-wrap-mobile px-22">
                                <div class="form-group mb-0">
                                    <div class="card-body p-0">
                                            <span *ngIf="loadingPic" class="d-flex flex-column align-items-center">
                                                <img src="assets/media/logos/xmera-logo-3.png" alt="loading"
                                                    width="70px" />
                                                <strong class="text-danger font-size-h3">Cargando...</strong>
                                            </span>

                                            <ng-container *ngIf="!loadingPic">

                                                <div class="logo px-2" *ngIf="photoUrl">
                                                    <img [src]="file.logo" alt="" class="max-w-200px" />
                                                </div>

                                                <ng-template #second>
                                                    <div class="symbol symbol-lg-200">
                                                        <i class="symbol-badge bg-success"></i>
                                                        <img [src]="photoUrl | safe" alt="image">
                                                    </div>
                                                </ng-template>
                                            </ng-container>
                                    </div>
                                </div>
                                <div class="text-center w-100">
                                    <h1 class="ttlPDF text-primary text-center">
                                        {{overallSummary[0]?.department[0]?.socialReason}}
                                    </h1>
                                    <h3 class="text-muted text-center">{{overallSummary[0]?.department[0]?.budgetArea}}</h3>
                                    <h3 class=" text-center">Pago sueldo {{periodSelected?.description}}:</h3>
                                    <h4 class=" text-center">
                                        {{getEmployeeTypeDescription}}
                                    </h4>
                                </div>

                              </div>
                          </div>
                          <div class="card-body px0-print" >
                              <div id="pdf">
                                  <div class="pdf-page pb-4">
                                    <div class="card-body m-0 p-0 table-responsive" >
                                        <table class="table mt-2  text-center table-print table-nomina" id="excel-table" >
                                            <thead>
                                                <tr class="bg-primary">
                                                    <th class="text-left">Servidor Público</th>
                                                    <th class="text-left">Documento de Identidad</th>
                                                    <th class="text-center">Sexo</th>
                                                    <th class="text-left">Cargo</th>
                                                    <th class="text-center">Estatus</th>
                                                    <th class="text-right">Ingreso Bruto</th>
                                                    <th class="text-right">Otros Ingresos</th>
                                                    <th class="text-right">Ingreso Total</th>
                                                    <th class="text-right">SFS</th>
                                                    <th class="text-right">AFP</th>
                                                    <th class="text-right">ISR</th>
                                                    <th class="text-right">Otros Desc.</th>
                                                    <th class="text-right">Total Desc.</th>
                                                    <th class="text-right">Neto</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let item of overallSummary">
                                                    <tr>
                                                        <th class="text-left bg-light-secondary" colspan="14" >
                                                            <span class="text-dark text-uppercase ">
                                                                {{item.department[0]?.departmentName}}
                                                            </span>
                                                        </th>
                                                    </tr>
                                                    <tr *ngFor="let department of item.department">
                                                        <td class="text-left">{{department.firstName}} {{department.lastName}}</td>
                                                        <td class="text-left">
                                                            <span *ngIf="department.typeIdentificationId == 1">{{department.personalIdentification | mask: '000-0000000-0'}}</span>
                                                            <span *ngIf="department.typeIdentificationId != 1">{{department.personalIdentification}}</span>
                                                        </td>
                                                        <td class="text-center">{{department.sex}}</td>
                                                        <td class="text-left">{{department.positionName}}</td>
                                                        <td class="text-center">{{department.employeeType}}</td>
                                                        <td class="text-right">{{department.grossSalary | currency: 'RD$'}}</td>
                                                        <td class="text-right">{{department.otherCredits | currency: 'RD$'}}</td>
                                                        <td class="text-right">{{department.totalSalary | currency: 'RD$'}}</td>
                                                        <td class="text-right">{{department.sfs | currency: 'RD$'}}</td>
                                                        <td class="text-right">{{department.afp | currency: 'RD$'}}</td>
                                                        <td class="text-right">{{department.isr | currency: 'RD$'}}</td>
                                                        <td class="text-right">{{department.otherDiscounts | currency: 'RD$'}}</td>
                                                        <td class="text-right">{{department.totalDiscount | currency: 'RD$'}}</td>
                                                        <td class="text-right">{{department.netIncome  | currency: 'RD$'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-left fw-bolder">Sub totales:</td>
                                                        <td class="text-left"></td>
                                                        <td class="text-left"></td>
                                                        <td class="text-left"></td>
                                                        <td class="text-left"></td>
                                                        <td class="text-right fw-bold">{{item.subTotalGrossSalary | currency: 'RD$'}}</td>
                                                        <td class="text-right fw-bold">{{item.subTotalOtherCredits | currency: 'RD$'}}</td>
                                                        <td class="text-right fw-bold">{{item.subTotalTotalSalary | currency: 'RD$'}}</td>
                                                        <td class="text-right fw-bold">{{item.subTotalSfs | currency: 'RD$'}}</td>
                                                        <td class="text-right fw-bold">{{item.subTotalAfp | currency: 'RD$'}}</td>
                                                        <td class="text-right fw-bold">{{item.subTotalIsr | currency: 'RD$'}}</td>
                                                        <td class="text-right fw-bold">{{item.subTotalOtherDiscounts | currency: 'RD$'}}</td>
                                                        <td class="text-right fw-bold">{{item.subTotalTotalDiscount | currency: 'RD$'}}</td>
                                                        <td class="text-right fw-bold">{{item.subTotalNetIncome | currency: 'RD$'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                    </tr>
                                                </ng-container>
                                                <tr class="bg-light-secondary">
                                                    <td class="text-left fw-bolder">TOTALES:</td>
                                                    <td class="text-left"></td>
                                                    <td class="text-left"></td>
                                                    <td class="text-left"></td>
                                                    <td class="text-left"></td>
                                                    <td class="text-right fw-bold">{{this.totalGrossSalary | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{this.totalOtherCredits | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{this.totalTotalSalary | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{this.totalSfs | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{this.totalAfp | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{this.totalIsr | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{this.totalOtherDiscounts | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{this.totalTotalDiscount | currency: 'RD$'}}</td>
                                                    <td class="text-right fw-bold">{{this.totalNetIncome | currency: 'RD$'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                  </div>
                              </div>

                          </div>
                          <div class="card-footer gutter-t">
                              <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
                                  <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'"  alt="" class="max-w-80px">
                                  <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
                              </div>
                          </div>
                      </div>
                  </div>
            </div>

    </div>
</div>





<script>
    let print = () => {
        let pdf = document.getElementById("pdfExp");
        pdf.contentWindow.focus();
        pdf.contentWindow.print();
    };
</script>
