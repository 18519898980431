import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IPositionView,IPositionToMap, IPositionChangePost, IPositionChangeDetailView } from '../models/positions.model';
import { IGetDepartments } from '../pages/home/home.models';
import { IPositionCompetencyView, PositionCompetency, PositionsDescription } from '../pages/positions-list-description/models/positions-description.model';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { keyWord } from '../shared/utils/parameterControl';
import { AuthInfoService } from './auth-info.service';

import { HttpClientService } from './http-client/http-client.service';
import { ParameterControlService } from './parameter-control.service';

@Injectable({
  providedIn: 'root'
})
export class PositionService {
  private readonly url = environment.api_core_url;
  private readonly url_sigei = environment.api_core_sigei;
  private readonly xhrm_url = environment.api_url;
  constructor(private authInfo: AuthInfoService, private _httpClient: HttpClient, private srvParam:ParameterControlService) { }

  getPositions(companyId:number = this.authInfo.getCompanyId(), positionId:number, status:boolean) {
    return this._httpClient.get<ResponseModel<IPositionView>>(`${this.url}/positions?companyId=${companyId}&PositionId=${positionId}&Status=${status}`)
  }

  getPositionsById(companyId:number = this.authInfo.getCompanyId(), positionId:number, status:boolean) {
    return this._httpClient.get<ResponseModel<IPositionView>>(`${this.url}/positions?companyId=${companyId}&PositionId=${positionId}&Status=${status}`)
  }

  getPositionsByCompany(companyId:number = this.authInfo.getCompanyId()) {
    return this._httpClient.get<ResponseModel<IPositionView>>(`${this.url}/positions?companyId=${companyId}&PositionId=-2`)
  }

  getApprovedPositionsByCompany(companyId:number = this.authInfo.getCompanyId()) {
    return this._httpClient.get<ResponseModel<IPositionView>>(`${this.url}/positions?type=1&companyId=${companyId}&PositionId=-2`)
  }

  createPosition(position) {
    return this._httpClient.post(`${this.url}/positions`, position)
  }

  updatePosition(position) {
    return this._httpClient.put(`${this.url}/positions`, position)
  }

  sendPositionToMap(model: IPositionToMap) {
    return this._httpClient.post<ResponseModel<any>>(`${this.url}/positions/SendToMap`, model)
  }

  getCompanies() {
    return this._httpClient.get(`${this.url_sigei}/Companies?CompanyCode=${this.authInfo.getCompanyId()}&Status=1&Type=1`)
  }

  getCompaniesById(id){
    return this._httpClient.get(`${this.url_sigei}/Companies?CompanyCode=${id}&Status=1&Type=6`)
  }

  getConcursaPositions() {
    return this._httpClient.get<ResponseModel<any>>(`${this.xhrm_url}/Map/GetPositionsConcursa`)
  }

  getDepartments(companyId: number = this.authInfo.getCompanyId(), departmentId: number) {
    return this._httpClient.get<ResponseModel<IGetDepartments>>(`${this.url}/companyStructure?CompanyId=${companyId}&IdDepartment=${departmentId}`)
  }

  getOcupationalGroup(){
    return this.srvParam.getParameters(keyWord.OccupationalGroup)
  }

  deletePosition(data: any){
    return this._httpClient.put<ResponseModel<any>>(`${this.url}/positions/deletePositionsList`, data)
  }

  sendPositionChanges(model: IPositionChangePost){
    return this._httpClient.post<ResponseModel<any>>(`${this.url}/PositionChange`, model)
  }

  getChangeDetails(positionChangeDetailsId: number,positionChangeHeaderId: number, positionId: number, companyId: number, type: number ){
    return this._httpClient.get<ResponseModel<IPositionChangeDetailView>>(`${this.url}/PositionChange/Details?positionChangeDetailsId=${positionChangeDetailsId}&positionChangeHeaderId=${positionChangeHeaderId}&positionId=${positionId}&companyId=${companyId}&type=${type}`)
  }

  getChangeHeaders(){

  }

  getCareers() {
    return this._httpClient.get<any>(`${this.xhrm_url}/careers?IdCareers=0&companyId=${this.authInfo.getCompanyId()}&Status=true`);
  }

  updatePositionsDescription(positionsDescription: PositionsDescription){
    return this._httpClient.put<ResponseModel<any>>(`${this.url}/Positions/Description`, positionsDescription);
  }

  getPositionCompetency(positionId: number, companyId: number){
    return this._httpClient.get<ResponseModel<IPositionCompetencyView>>(`${this.url}/Positions/PositionCompetency?positionCompetencyId=0&positionId=${positionId}&capabilityId=0&companyId=${companyId}&type=0`);
  }

  deletePositionCompetency(positionCompetencyId: number, userId: number){
    return this._httpClient.delete<ResponseModel<any>>(`${this.url}/Positions/PositionCompetency?positionCompetencyId=${positionCompetencyId}&userId=${userId}`);
  }

}
