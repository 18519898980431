<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span
            class="h-20px label label-danger label-dot mr-3 w-20px"></span>{{data?.titleModal}}</h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">
    <div class="card-body">
        <form [formGroup]="form">
            <div class="row pt-5 gutter-b">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Valor <span class="font-italic"></span><span
                            class="text-danger text-monospace legend">*</span></label>
                        <input formControlName="value" type="number" class="form-control form-control-solid form-control-lg">
                        <span class="text-danger" *ngIf="formIsInvalid">{{showError('value')}}</span>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Descripción <span class="font-italic"></span><span
                            class="text-danger text-monospace legend">*</span></label>
                        <input formControlName="description" type="text" class="form-control form-control-solid form-control-lg">
                        <span class="text-danger" *ngIf="formIsInvalid">{{showError('description')}}</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


<div class="mat-dialog-actions mt-2 p-0 mb-0 text-right" [align]="'end'">
    <button (click)="this.dialogRef.close();" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="data?.inDetails == false" (click)="submit()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>