<div  class="container-fluid">
    <div id="pdfExp" class="card">
      <div class="card-header px-22">
        <div class="row" id="header">
          <div class="col-md-3">
            <div class="form-group mb-0">
              <div class="card-body p-0">
                <div>
                  <div class="evidence"></div>
                </div>
                <div>
                  <div class="symbol symbol-150 d250 my-2 max-w-250px">
                    <img alt="Pic" [src]="file.logo" class="w-80" style="width: 150px;" />
                  </div>
                  <div class="btn-group center" role="group">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="title p-2 d-flex justify-content-center align-items-center flex-column col-md-auto">
            <h1 class="text-primary text-center">Gobierno de la República Dominicana</h1>
            <h2 class="text-danger text-center">Comprobante de pago</h2>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div id="pdf">
          <div class="container-fluid row">
            <div class="pdf-page p-0 mb-0">
              <div class="alert alert-info mb-0" role="alert">
                <div class="row">
                  <div class="col-2 d-flex align-items-center">
                    <div class="pdf-items">
                      <h3 class="align-self-center mb-0"><strong class="text-primary mb-0">Argumento:</strong></h3>
                    </div>
                  </div>
                  <div class="col-md-auto">
                    <div class="pdf-items">
                      <h4>Área:</h4>
                      <span>{{bugetArea}}</span>
                    </div>
                    <div class="pdf-items">
                      <h4>Período:</h4>
                      <span>{{selectedPeriod}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid row" id="personal-data">
            <h3 class="col-md-12 p-2 bg-primary text-white mt-3 text-center">DATOS PERSONALES</h3>
            <div class="col-md-12 row">
              <div class="photo col-2">
                <span *ngIf="loadingPic; else loaded" class="d-flex flex-column align-items-center">
                  <strong class="text-danger font-size-h3">Cargando...</strong>
                </span>
                <ng-template #loaded>
                  <img alt="Pic" [src]="profilePhotoUrl" width="100%" />
                </ng-template>

              </div>
              <div class="pdf-list col-md-auto" *ngIf="payrollVoucherData">
                <div class="pdf-items">
                  <h4>Documento de Identidad:</h4>
                  <span>{{payrollVoucherData.employee.personalIdentification|mask:"000-0000000-0"}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Nombre(s):</h4>
                  <span>{{payrollVoucherData.employee.firstName}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Apellido(s):</h4>
                  <span>{{payrollVoucherData.employee.lastName}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Fecha Designación:</h4>
                  <span>{{payrollVoucherData.employee.admissionDate | date: "dd/MM/yyyy"}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Unidad Organizativa:</h4>
                  <span>{{payrollVoucherData.employee.departmentName}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Tipo Servidor Público:</h4>
                  <span>{{contractType}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Cargo:</h4>
                  <span>{{payrollVoucherData.employee.positionName}}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid row mt-3" id="contact-media">
            <h3 class="col-md-12 p-2 bg-secondary text-white text-center mt-3 d-block mb-2"
              style="background: #e3292e !important;">
              INFORMACIONES
            </h3>
            <div class="py-1 px-0 table-responsive">
              <table class="table p-0">
                <thead>
                  <tr class="bg-primary">
                    <th scope="col" class="text-white">CONCEPTO</th>
                    <th scope="col" class="text-white text-right">INGRESOS</th>
                    <th scope="col" class="text-white text-right">DESCUENTOS</th>
                    <th scope="col" class="text-white text-right">SUELDO NETO</th>
                  </tr>
                </thead>
                <tbody *ngIf="payrollVoucherData">
                  <tr class="" *ngFor="let concept of employeeDebitCredit">
                    <td class="">{{concept.debitCredit}}</td>
                    <td class="text-right">{{(concept.debitCreditId == 0 ? concept.amount: '') | currency: "RD$"}}</td>
                    <td class="text-right">{{(concept.debitCreditId != 0 ? concept.amount: '') | currency: "RD$"}}</td>
                    <td class=""></td>
                  </tr>

                  <tr class="" *ngFor="let newApplied of newsApplied">
                    <td class="">{{newApplied.newsConcept}}</td>
                              <td class="text-right"></td>
                              <td class="text-right">{{returnRelatedAmount(newApplied) | currency: "RD$"}}</td>
                              <td class=""></td>
                  </tr>

                  <tr class="table-info">
                    <th class="text-primary">Total General:</th>
                    <!-- <td class="text-right">{{selectedFlyer.grossSalary | currency: "RD$"}}</td>
                    <td class="text-right">{{selectedFlyer.totalDiscount | currency: "RD$"}}</td>
                    <td class="text-right">{{selectedFlyer.netIncome | currency: "RD$"}}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer mt-4">
        <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
          <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'"  alt="" class="max-w-80px">

          <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
        </div>
      </div>
    </div>
  </div>
  <button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
    <i class="flaticon2-printer font-size-h1"></i>
  </button>
  <script>
    let print = () => {
      window.focus()
      window.print()
    };
  </script>