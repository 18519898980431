import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IDisciplinaryActionsPost, IDisciplinaryActionsView, IOffenseView } from '../shared/models/disciplinaryActions.model';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DisciplinaryActionsService {

  private readonly BASE_URL = environment.api_url;
  private readonly BASE_URL_LOBBY = environment.api_lobby;

  constructor(private httpClient: HttpClient) { }


  getList(actionId: number, employeeId: number, offenseId: number, offenseGradeId: Number, savedGradeId: number, companyId: number, status: boolean, userId: number, type: number){
    return this.httpClient.get<ResponseModel<IDisciplinaryActionsView>>(`${this.BASE_URL}/DisciplinaryActions?`,
    {
      params: <any>{
        actionId,
        employeeId,
        offenseId,
        offenseGradeId,
        savedGradeId,
        companyId,
        status,
        userId,
        type
      }
    });
  }

  insert(model: IDisciplinaryActionsPost){
    return this.httpClient.post<ResponseModel<any>>(`${this.BASE_URL}/DisciplinaryActions`,model);
  }

  update(model: IDisciplinaryActionsPost){
    return this.httpClient.put<ResponseModel<any>>(`${this.BASE_URL}/DisciplinaryActions`,model);
  }

  delete(actionId: number, userId: number){
    return this.httpClient.delete<ResponseModel<any>>(`${this.BASE_URL}/DisciplinaryActions?`,
    {
      params: <any>{
        actionId,
        userId
      }
    });
  }

  getOffensesList(offenseId: number, gradeId: number, companyId: number, type: number){
    return this.httpClient.get<ResponseModel<IOffenseView>>(`${this.BASE_URL_LOBBY}/Offenses?`,
    {
      params: <any>{
        offenseId,
        gradeId,
        companyId,
        type
      }
    });
  }
}
