import { Component, Inject, OnInit, ChangeDetectorRef, AfterViewChecked, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SearchEmployeeComponent } from '@payroll/action-edit/search-employee/search-employee.component';
import * as moment from 'moment';
import { RoyaltyDetailModel } from 'src/app/pages/payroll-new/open-payroll/payroll-royalty-summary/models/royaltyDetail.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { PayrollConceptService } from 'src/app/services/payroll-concept.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { RoyaltyDetailService } from 'src/app/services/royaltyDetails.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';
import { removeWhitespace } from 'src/app/shared/utils/utility';

@Component({
  selector: 'app-royalty-detail-add-modal',
  templateUrl: './royalty-detail-add-modal.component.html',
  styleUrls: ['./royalty-detail-add-modal.component.css']
})
export class RoyaltyDetailAddModalComponent implements OnInit, AfterViewChecked {  

  imgDefaultPdf: string = 'assets/images/ver_file.jpg';
  royaltyDetailForm = new FormGroup({
    royaltyDetailId: new FormControl(0),
    headerId: new FormControl(0),
    employeeId: new FormControl(0, Validators.required),
    newsConceptId: new FormControl(null, Validators.required),
    periodMonth: new FormControl(null, Validators.required),
    amount: new FormControl(0, Validators.required),
    status: new FormControl(true),
    createUserId: new FormControl(0),
    createDate: new FormControl(moment().format('yyyy-MM-DD')),
    modifyUserId: new FormControl(0),
    modifyDate: new FormControl(moment().format('yyyy-MM-DD')),
    employeeName: new FormControl('')
  })
  @ViewChild('uppyFile')
  uppyFile: UppyFileComponent | null = null;
  periods: any [] = [
    {
      displayName: 'Enero',
      Id: 1
    },
    {
      displayName: 'Febrero',
      Id: 2
    },
    {
      displayName: 'Marzo',
      Id: 3
    },
    {
      displayName: 'Abril',
      Id: 4
    },
    {
      displayName: 'Mayo',
      Id: 5
    },
    {
      displayName: 'Junio',
      Id: 6
    },
    {
      displayName: 'Julio',
      Id: 7
    },
    {
      displayName: 'Agosto',
      Id: 8
    },
    {
      displayName: 'Septiembre',
      Id: 9
    },
    {
      displayName: 'Octubre',
      Id: 10
    },
    {
      displayName: 'Noviembre',
      Id: 11
    },
    {
      displayName: 'Diciembre',
      Id: 12
    }
  ]
  newsConceptList: any[] = [];

  newsConceptListConfig: any;
  periodsConfig: any;
  originalroyaltyDetailList: any[] = [];
  company;
  royaltyDetail: any;
  title = 'Agregar'
  img1= ''
  constructor(
    public dialogRef: MatDialogRef<RoyaltyDetailAddModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private royaltyDetailService: RoyaltyDetailService, 
    private _toastService: ToastService,
    public _cdRef: ChangeDetectorRef,
    private authInfo: AuthInfoService,
    private srvCompanies: CompaniesService,
    private dialog: MatDialog,
    private payrollConceptService: PayrollConceptService,
  ) {
    this.originalroyaltyDetailList = this.data.originalroyaltyDetailList;
  }

  ngOnInit(): void {
    
    this.periodsConfig = this.dropdownConfig('displayName')
    this.newsConceptListConfig = this.dropdownConfig('description')
    this.royaltyDetailForm.get('employeeName').disable();    

  
    this.title = this.data.mode == 'edit' ? 'Editar' : this.data.mode == 'view' ? 'Detalle' : 'Agregar'

    this.getCompany();
    this.getNewsConcept();
   
    if(this.data.mode == 'view'){
      this.royaltyDetailForm.disable()
    }

  }

  removeWhitespace(event, control){
    removeWhitespace(event, control)
  }

  isFormInvalid(){
    
    const{employeeId, newsConceptId, periodMonth, amount} = this.royaltyDetailForm.value
    
    if(!employeeId){
      return true
    }
    if (!newsConceptId?.newsConceptId){
      return true
    }
    if (!periodMonth?.Id){
      return true
    }
    if (!amount){
      return true
    }

    return false
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  getCompany(){
    this.srvCompanies.getCompanyByUserAndId().subscribe({
      next: (res:any) => {
        if(res.errors.length > 0){this._toastService.error(res.errors[0]); return}
        if(res.warnings.length > 0){this._toastService.warning(res.errors[0]); return}
        this.company = res.dataList[0]
                
      }, error: (errors: any) => {

      }
    })
  }

  getNewsConcept(){
    this.payrollConceptService.getAllNewConcept(this.authInfo.getCompanyId(), 4).subscribe({
      next: (res:any) => {
        if(res.errors.length > 0){this._toastService.error(res.errors[0]); return}
        if(res.warnings.length > 0){this._toastService.warning(res.errors[0]); return}
        this.newsConceptList = res.dataList
      },
      error: (errors: any) =>{

      }
    })
  }


  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }
  closeModal(): void {
    const result = { cretaed: false }
    this.dialogRef.close(result);
  }

  SubmitroyaltyDetail(): void {
    if(this.isFormInvalid()){
      this._toastService.warning('Campos inválidos.')
      return
    }

    const model = {
      ...this.royaltyDetailForm.value,
      newsConceptId : this.royaltyDetailForm.value.newsConceptId.newsConceptId,
      periodMonth : this.royaltyDetailForm.value.periodMonth.Id,
      amount: Number(this.royaltyDetailForm.value.amount),
      createUserId: this.authInfo.getUserId(),
      headerId: Number(this.data.headerId)
    }
    if (!this.uppyFile.fileSelected) {
      this._toastService.warning("Debe subir una evidencia")
      return
    }
    this.uppyFile.handleFile(guid => {
      model.evidence = guid
      this.createroyaltyDetail(model)
    })
    
    
  }

  //CREATE
  createroyaltyDetail(model) {
    let result = {
      created: false
    };
    
    this.royaltyDetailService.createRoyaltyDetail(model).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }

      this._toastService.success('Detalle de regalia creada satisfactoriamente')
      result.created = res.succeded
      this.dialogRef.close(result);
    })

  }

  openEmployeeModal(){
    this.dialog.open(SearchEmployeeComponent,

    ).afterClosed().subscribe(
      {
        next:(res)=>{
          this.royaltyDetailForm.get('employeeId').setValue(res.data?.employeeId)
          this.royaltyDetailForm.get('employeeName').setValue(res.data?.name)
        }
      }
    )
  }

}
