import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormatTypes } from '../../../shared/dynamic-format/enums/format-types';
import { FileManagerService } from '../../../shared/file-manager/file-manager.service';
import { HttpClientService } from '../../../services/http-client/http-client.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../shared/loading/loading.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';

import { CandidateRegService } from 'src/app/services/http-client/candidateRegService.service';
import { Subscription } from 'rxjs';
import { docService } from 'src/app/services/doc.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ProofApplicantsService } from 'src/app/services/proof-applicants.service';
import { ITest } from './models/Test';
import { ToastrService } from 'ngx-toastr';
import { IPersonVacant } from '../../proof-applicants/models/PersonVacant';
import { Location } from '@angular/common';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
declare const $: any

@Component({
  selector: 'pdf-preview-resultados',
  templateUrl: './pdf-preview-resultados.component.html',
  styleUrls: ['./pdf-preview-resultados.component.css']
})


export class PdfPreviewResultadosComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly personalRequestRoute = 'hcm/personal-request';
  public loadingPic = true
  public requisitionTypeList = [];
  subscriptions: Subscription = new Subscription();
  test:ITest;
  testList:ITest[] = [];
  person: IPersonVacant;
  isTableLoading: boolean;
  formatYesOrNot = FormatTypes.YesOrNo;
  document: any;
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  id: number;
  photoUrl: string;
  fileModalConfig = {
    src: '',
    type: '',
    filename: '',
    width: 225,
    height: 225,
    formTitle: '',
    formSubTitle: '',
  };

  public company: any;
  file = { logo: '', image1: '', image2:'' }

  constructor(private _http: HttpClientService,
    private proofApplicantsService: ProofApplicantsService,
    private _fileManager: FileManagerService,
    private _toastService: ToastrService,
    private _route: ActivatedRoute,
    public loadingService: LoadingService,
    private srvPerson: PersonService,
    private _srvCandidateService: CandidateRegService,
    private srvDoc: docService,
    private _companiesService: CompaniesService,
    private authInfo: AuthInfoService,
    private srvCandidateService: CandidateRegService,
    private location: Location) {

  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    let id = this._route.snapshot.params["id"]
    let sub = this.proofApplicantsService.getPostulationTestById(id).subscribe((res:ResponseModel<ITest>) => {
      if(res.succeded){
        this.test = res.dataList[0]
        let ppvid = this.test.postulationId
        this.getPostulationTests(ppvid)
        let sub = this.proofApplicantsService.getPersonVacantByPPVID(ppvid,this.authInfo.getCompanyId()).subscribe((res:ResponseModel<IPersonVacant>) => {
          if(res.succeded){
            this.person = res.dataList[0]
            if (this.person.photo) {
              this.getPersonPhoto(this.person.photo)
            }else{
              this.loadingPic = false
            }
          }else{
            this._toastService.error(res.errors[0])
          }
        }, err => {
          this._toastService.error(this.errorMessage)
        })
        this.subscriptions.add(sub)
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error(this.errorMessage)
    })
    this.subscriptions.add(sub)
    this.companyById(this.authInfo.getCompanyId())
  }
  

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  getPostulationTests(id){
    let sub = this.proofApplicantsService.getPostulationsTestsByPPVID(id,this.authInfo.getCompanyId()).subscribe((res:ResponseModel<any>) => {
      if(res.succeded){
        this.testList = res.dataList
      }else{
        this._toastService.error(res.errors[0])
      }
    }, e => {
      this._toastService.error(this.errorMessage)
    })
    this.subscriptions.add(sub)
  }
  getPersonPhoto(photo){
    this.srvDoc.getDocument(photo).subscribe(e => {
      if(e.succeded){
        this.photoUrl = e.data
        this.loadingPic = false
      }else{
        this._toastService.error(e.errors[0])
      }
    }, error => {
      this._toastService.error(this.errorMessage)
    })
  }
  showModal(modalName: string): void {
    $(`#${modalName}`).modal('show');
  }
  goBack(){
    this.location.back();
  }

}
