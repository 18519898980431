import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { DataService } from 'src/app/services/data.service';
import { Account } from 'src/app/shared/models/account';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe, formatDate } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DigitalSignService } from 'src/app/services/digital-sign.service';
import { StatusDocuments } from './models/enums-sign.model';
import { DocumentRequest, Metadata, SignRequest } from 'src/app/models/sign-request.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';

@Component({
  selector: 'app-sign-approval-process',
  templateUrl: './sign-approval-process.component.html',
  styleUrls: ['./sign-approval-process.component.css']
})
export class SignApprovalProcessComponent implements OnInit, AfterViewChecked {
  addresseeGroups: any[] = [];
  documents: any[] = [];
  publicAccessId = "";
  account: Account;
  titleModal: string = '';
  messageSign: string = '';
  sending: boolean = false;
  signError: boolean = false;

  signRequest: SignRequest;

  statusRequest: string = '';
  loanding: boolean = false;

  metadatas: Metadata[] = [];

  documentRequest: DocumentRequest;

  formInvalid: boolean = false;

  constructor(private srvFirmaGob: DigitalSignService,
    private dataSrv: DataService,
    private toastService: ToastService,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<SignApprovalProcessComponent>,
    private _changeDet: ChangeDetectorRef,
    private auth: AuthInfoService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogRef.disableClose = true;
    this.titleModal = this.data?.title;
    this.messageSign = this.data?.messageSign;
    this.publicAccessId = this.data?.publicAccessId;
    this.metadatas = this.data?.metadatas;
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  Close(): void {
    const response = { Applied: false };
    this.dialogRef.close(response);
  }

  ngOnInit(): void {
    this.account = this.dataSrv.GetUserData();
    if (this.publicAccessId) {
      this.ValidateSign();
    }
  }

  ValidateSign() {
    this.loanding = true;
    const modelAuthResquest = {
      content: this.publicAccessId,
      companyId: this.auth?.getCompanyId()
    }
    this.srvFirmaGob.GetRequestData(this.publicAccessId, modelAuthResquest).subscribe((data: SignRequest) => {
      this.statusRequest = data?.status;
      this.signRequest = data;
      if (data?.status === StatusDocuments.COMPLETED) {
        this.toastService.success(this.geMessageStatus);
        this.signRequest?.documentsToSign.forEach(d => {
          this.downloadAttachments(d?.publicAccessId);
        });
      } else {
        this.loanding = false;
        this.toastService.info(this.geMessageStatus, '');
      }

    }, error => {
      this.loanding = false;
      this.toastService.error(error?.error?.errorMessage, 'Ha ocurrido un error validando la petición de firma');
    })
  }

  save() {
    const response = { Applied: true, status: this.statusRequest, rejectInfo: this.signRequest?.rejectInfo, documentRequest: this.documentRequest };
    this.dialogRef.close(response);
  }

  downloadAttachments(publicAccessId) {
    const modelAuthResquest = {
      content: publicAccessId,
      companyId: this.auth?.getCompanyId()
    }
    this.srvFirmaGob.DownloadAttachments(publicAccessId, modelAuthResquest).subscribe((data: DocumentRequest) => {
      this.documentRequest = data;
      if (data?.base64) {
        const pdfContent = document.getElementById('pdfContent') as HTMLElement;
        if (pdfContent) {
          pdfContent.style.display = "block";
          const pdf = 'data:application/pdf;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(data?.base64) as any)
            .changingThisBreaksApplicationSecurity;
          document.getElementById('pdfSHow').innerHTML = `
          <div class="col-md-12 text-center">
            <a href="${pdf}" download="${data?.filename}"  class="btn btn-pill font-weight-bolder btn-danger w-60 p-5 ">
            <i class="flaticon-descargar"></i>Descargar Documento Firmado</a>
          </div>
          `
          ;
          this.loanding = false;
        }else{
          this.loanding = false;
        }
      }
    }, error => {
      this.loanding = false;
      this.toastService.error(error?.error?.errorMessage ?? '', 'Ha ocurrido un error al descargar el documento firmado');
    })
  }

  get geMessageStatus() {
    return this.statusRequest === StatusDocuments.COMPLETED ? "El documento ha sido firmado y aprobado completamente." :
      this.statusRequest === StatusDocuments.EXPIRED ? "La petición de firma ha caducado." :
        this.statusRequest === StatusDocuments.IN_PROCESS ? "La firma del documento esta en curso." :
          this.statusRequest === StatusDocuments.REJECTED ? "La petición de firma ha sido rechazada." :
            this.statusRequest === StatusDocuments.NOT_STARTED ? `El proceso de firma y aprobación del documento no se ha iniciado.` : '';
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

}