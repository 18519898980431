<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-riesgo-1 icon-3x text-danger"></i>
                <h1 class="text-primary"> Listado de Incidentes </h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard" data-toggle="tooltip" data-original-title="Dashboard">
            <button (click)="openDashBoard()" class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto" *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.dashboardAction)" >
                <i class="flaticon-dashboard-1 icon-2x" ></i> Ver Dashboard
            </button>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-6 c-left">
            <div class="card-toolbar d-flex justify-content-start">

                <div class="col-md-4 mr-2 px-0 pt-1">
                  <div class="form-group mx-2 institution">
                    <label class="text-primary">
                      Estatus
                    </label>
                    <!-- <app-dropdown-multi-select (changeAsw)="filterTable($event)"></app-dropdown-multi-select> -->
                    <ng-multiselect-dropdown
                      [placeholder]="'Seleccionar Estatus'"
                      [settings]="dropdownSettings"
                      [data]="statusOptions"
                      [(ngModel)]="selectedItems"
                      (onDeSelectAll)="onDeSelectAll()"
                      (onDeSelect)="onItemDeSelect($event)"
                      (onSelect)="onItemSelect($event)"
                      (onSelectAll)="onSelectAll($event)"
                      ></ng-multiselect-dropdown>
                  </div>
                </div>

                <button data-toggle="modal" id="btnAdd" *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.addAction)"
                    class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto" [routerLink]="['/incidents']">
                    <i class="flaticon-mas icon-2x" data-toggle="tooltip" data-original-title="Agregar nuevo Incidente"></i> Agregar
                </button>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12" >
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton" *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.exportAction)"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white"  data-toggle="tooltip" data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item disabled align-items-center">
                            <i class="flaticon-excel icon-2x text-success mr-3"></i>
                            Excel
                        </a>
                        <a class="dropdown-item disabled align-items-center">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled align-items-center">
                            <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                            PDF
                        </a>
                        <a class="dropdown-item disabled align-items-center">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a>
                    </div>
                </div>

                <div class="col text-nowrap pt-2">
                    <el-search-bar [records]="incidentsOriginal" [itemForSearching]="searchBarDisplayNames"
                        (onChange)="getFilteredList($event)"></el-search-bar>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive">
            <table class="table mt-2 table-vertical-center text-center">
                <thead>
                    <tr class="bg-primary">
                        <th>Secuencia</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th>Tipo de Servidor Público</th>
                        <th class="text-left">Servidor Público</th>
                        <th class="text-left">Tipo de Incidente</th>
                        <th>Grado del Incidente</th>
                        <th>Fecha del Incidente</th>
                        <th>Evidencia</th>
                        <th>Estatus</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let incident of paginatedIncidents">
                        <td>{{incident.criticalIncidentId}}</td>
                        <td class="text-left">{{incident.departmentName}}</td>
                        <td>{{incident.typeEmployee}}</td>
                        <td class="text-left">{{incident.employeeName}}</td>
                        <td class="text-left">{{incident.typeIncident}}</td>
                        <td>{{incident.degreeIncident}}</td>
                        <td>{{incident.date |date: 'dd/MM/yyyy'}}</td>
                        <td>
                            <a *ngIf="incident.evidence && authInfo.canDoAction(privilege.nameKeyOption, privilege.evidenceAction)" (click)='getGuID(incident.evidence)' data-toggle="tooltip" title="Evidencia"
                                class="btn btn-light-youtube font-weight-bold mb-3" >
                                <i class="flaticon-pdf-file icon-2x"></i>
                            </a>
                        </td>
                        <td>
                          <span *ngIf="incident.status" class="font-weight-bold text-success">Activa</span>
                          <span *ngIf="!incident.status" class="font-weight-bold text-danger">Inactiva</span>
                        </td>
                        <td class="actionsBtn">
                            <a [routerLink]="['/incidents/',incident.criticalIncidentId]" data-toggle="tooltip" title="Ver"
                                class="btn btn-sm btn-light-skype btn-icon mr-2" *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.viewAction)">
                                <i class="flaticon-ver icon-2x"></i>
                            </a>
                            <button (click)="deleteIncident(incident.criticalIncidentId)" data-toggle="tooltip" title="Remover"
                                 class="btn btn-sm btn-light-danger btn-icon" *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.deleteAction)">
                                <i class="flaticon-delete"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div >
                
                <el-caption #paginator [originalRecordsLength]="incidents.length" [listedRecordsLength]="paginatedIncidents.length" 
                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :incidents.length"
                    [records]="filteredList.enable ? filteredList.records : incidents"
                    (onChage)="getPaginatedRecords($event)"></el-caption>
            </div>
        </div>
    </div>
</div>
