import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-personnel-change',
  templateUrl: './map-personnel-change.component.html',
  styleUrls: ['./map-personnel-change.component.css']
})
export class MapPersonnelChangeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
