import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
enum types {
  gender=2,
  country=3,
  age=4,
  academicLevel=5
}
@Injectable({
  providedIn: 'root'
})
export class ApplyVacanciesDashboardService {

  private readonly api = environment.api_url
  constructor(
    private http: HttpClient,
    private authInfo: AuthInfoService
  ) { }

  getGenders(){
    return this.http.get(`${this.api}/Vacants/GetDashboardVacantsPerson?Type=${types.gender}&Status=true&CompanyId=${this.authInfo.getCompanyId()}`)
  }
  getAll(){
    return this.http.get(`${this.api}/Vacants/GetDashboardVacantsPerson?Status=true&CompanyId=${this.authInfo.getCompanyId()}`)
  }
  getCountry(){
    return this.http.get(`${this.api}/Vacants/GetDashboardVacantsPerson?Type=${types.country}&Status=true&CompanyId=${this.authInfo.getCompanyId()}`)
  }
  getAgeStats(){
    return this.http.get(`${this.api}/Vacants/GetDashboardVacantsPerson?Type=${types.age}&Status=true&CompanyId=${this.authInfo.getCompanyId()}`)
  }
  getAcademicLevelStats(){
    return this.http.get(`${this.api}/Vacants/GetDashboardVacantsPerson?Type=${types.academicLevel}&Status=true&CompanyId=${this.authInfo.getCompanyId()}`)
  }
}
