<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span> Dashboard Apertura de Nómina</h2> 
        <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <div class="d-flex justify-content-between align-items-end mb-3 row">
            <div class="text-left col-md-8 col-lg-9">
              <h3>Filtrado</h3><div class="text-left d-flex flex-wrap">
                <h4 class="d-flex font-weight-bolder text-primary me-4">Desde: <span class="text-dark font-weight-lighter ml-3">{{filterParams.date1| date: 'dd/MM/yyyy' ||'    '}}</span></h4>
                <h4 class="d-flex font-weight-bolder text-primary">Hasta: <span class="text-dark font-weight-lighter ml-3">{{filterParams.date2| date: 'dd/MM/yyyy'||'    '}}</span></h4>
              </div>
            </div>
        
            <div class="d-flex flex-nowrap justify-content-between col-12 col-md-3 col-lg-3">
                <button class="btn btn-primary me-2 w-50" (click)="openDialog()">
                  <i class="fas fa-filter" data-toggle="tooltip" data-title="Filtrar"></i>
                </button>
                <button class="btn btn-light-warning ms-2 w-50" [disabled]="!filtered" (click)="clear()" >
                  <i class="fas fa-redo" data-toggle="tooltip" data-title="Reiniciar filtros"></i>
                </button>
              </div>
          </div>
          <div class="card bg-white p-5 gutter-b">
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex flex-wrap align-items-center bg-light-info rounded p-5">
                        <span class="svg-icon svg-icon-primary svg-icon-4x mr-5">
                            <i class="flaticon-agil icon-4x mr-3 text-primary"></i>
                        </span>
                        <div class="d-flex flex-column flex-grow-1 mr-2">
                            <h3 class="font-weight-bold text-dark-75">Total</h3>
                        </div>
                        <span class="font-weight-bolder text-primary py-1 font-size-h2">
                            {{total}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Nóminas por Tipo</h4>
                    </div>
                    <div class="card-body p-3">
                        <app-bar-chart-race [autoHeight]="true" [id]="'chartTipo'" [data]="payrollData">
                        </app-bar-chart-race>
                    </div>
                </div>
            </div>
            <div class="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 gutter-b">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Áreas Presupuestaria</h4>
                    </div>
                    <div class="card-body p-3">

                        <app-bar-chart-race [autoHeight]="true" [id]="'chartAreasP'" [data]="budgetData">
                        </app-bar-chart-race>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>