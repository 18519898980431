<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="fs-3 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px "></span> Rango salarial
    </h2>
</div>

<div class="mat-dialog-content-custom">
    <div>
        <h3 (click)="selectSalaryRange(vacancyModel.minSalary)"> <span role="button"
            class="badge  bg-light text-dark" [ngClass]="{'disable-option-range-salary': IsviewMode}">Salario mínimo: {{vacancyModel.minSalary | currency}}</span></h3>
        <h3 (click)="selectSalaryRange(vacancyModel.halfSalary)"> <span role="button"
            class="badge  bg-light text-dark" [ngClass]="{'disable-option-range-salary': IsviewMode}">Salario medio: {{vacancyModel.halfSalary | currency}}</span></h3>
        <h3 (click)="selectSalaryRange(vacancyModel.maxSalary)" [ngClass]="{'disable-option-range-salary': IsviewMode}"> <span role="button"
            class="badge  bg-light text-dark">Salario máximo: {{vacancyModel.maxSalary | currency}}</span></h3>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2"
    (click)="this.dialogRef.close();" type="button">
    <i class="flaticon2-cross"></i>
    <span>Cerrar</span>
</button>
</div>