<h2 class="mat-dialog-title"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Eliminar Novedad
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</h2>

<div class="mat-dialog-content">
    <div class="card-body">
        <form [formGroup]="form">
            <div class="row">
                <div class="form-group col-md-12">
                    <label class="d-block">Escriba la razón por la cual desea eliminar las novedades seleccionadas
                        <span class="text-danger text-monospace legend">*</span>
                    </label>
                    <textarea formControlName="reason" class="form-control form-control-lg dynamicFormControl" rows="6" cols="50"></textarea>
                    <span class="form-text text-danger" *ngIf="formIsInvalid && showErrors.erorReason">
                        Este campo es requerido
                    </span>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cancelar
    </button>
    <button  class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
        (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Confirmar</span>
    </button>
</div>
