<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        {{title}}</h2>
    <button type="button" (click)="closeModal()" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>

</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <form [formGroup]="vacancyForm" *ngIf="data.viewMode != viewsEnum.View">
            <div class="row">
                <div class="form-group col-lg-4">
                    <label>Unidad Organizativa</label>
                    <input class="form-control form-control-lg" readonly formControlName="department">
                </div>
                <div class="form-group col-lg-4">
                    <label>Puesto</label>
                    <input class="form-control form-control-lg" readonly formControlName="position">
                </div>
                <div class="form-group col-lg-4">
                    <label>Cantidad de Plazas<span class="text-danger">*</span></label>
                    <input class="form-control form-control-lg" type="number" formControlName="placesQuantity">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-4">
                    <label>Salario Propuesto<span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input (blur)="onSalaryError()" placeholder="0.00" type="text" currencyMask
                            [options]="{precision:2}" name="salary"
                            class="form-control form-control-solid form-control-lg" formControlName="salary">
                        <button id="searchBarBtn" class="input-group-text bg-primary" data-toggle="modal"
                            data-target="#salaryRange">
                            <i class="flaticon-ver icon-2x text-white" data-toggle="tooltip"
                                title="Sugerencia salarial"></i>
                        </button>
                    </div>
                    <span *ngIf="isSalaryInvalid" class="font-weight-bold text-danger">El salario deseado debe estar
                        dentro del rango salarial establecido</span>
                </div>
                <div class="form-group col-lg-4">
                    <label>Fecha Ingreso<span class="text-danger">*</span></label>
                    <input type="date" class="form-control form-control-solid form-control-lg" formControlName="date">
                </div>
            </div>
        </form>

        <div class="row justify-content-end" *ngIf="data.viewMode != viewsEnum.View">
            <div class="form-group col-auto px-0-mobile ms-auto">

                <button (click)="addPLace()" [disabled]="vacancyForm.invalid"
                    class="btn btn-success btn-pill font-weight-bolder mr-0 mr-0-mobile col-md-auto">
                    <i class="flaticon-mas icon-2x"></i> {{placeDTO?.planningPlaceRequestId? 'Editar' : 'Agregar'}}
                </button>
            </div>
        </div>

        <div class="row gutter-b">
            <div class="table-responsive">
                <table class="table table-hover table-vertical-center">
                    <thead class="bg-primary">
                        <tr>
                            <th class="text-left">Unidad Organizativa</th>
                            <th class="text-left">Cargo</th>
                            <th class="text-left">Cantidad de Plazas</th>
                            <th class="text-left">Salario Propuesto</th>
                            <th class="text-left">Fecha Inicio</th>
                            <th class="text-center" *ngIf="data.viewMode != viewsEnum.View">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let place of placesList; let i = index">
                            <td class="text-left">{{place.departmentName}}</td>
                            <td class="text-left">{{place.positionName}}</td>
                            <td class="text-left">{{place.placeQuantity}}</td>
                            <td class="text-left">{{place.salary | currency}}</td>
                            <td class="text-left">{{place.startDate | date:'dd/MM/yyyy'}}</td>
                            <td class="actionsBtn" *ngIf="data.viewMode != viewsEnum.View">
                                <a tooltip="Editar" (click)="editPlace(place, i)" placement="top"
                                    class="btn btn-sm btn-light-warning btn-icon mr-2">
                                    <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                                </a>

                                <button (click)="deletePlace(place, i)" data-toggle="tooltip" title=""
                                    data-original-title="Remover" class="btn btn-sm btn-light-danger btn-icon">
                                    <i class="flaticon-delete"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="mat-dialog-actions" align="end">

        <button (click)="closeModal()"
            class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2"
            type="button">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
        <button (click)="savePlace()" [disabled]="placesListCopy.length === 0" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
            <i class="flaticon-disco-flexible"></i>
            <span>Guardar</span>
        </button>
    </div>

    <el-modal title="Rango salarial" size="sm" target="salaryRange">

        <div>
            <h3 data-dismiss="modal" (click)="selectSalaryRange(this.data.position.minSalary)"> <span role="button"
                    class="badge  bg-light text-dark">Salario mínimo: {{this.data.position.minSalary | currency}}</span>
            </h3>
            <h3 data-dismiss="modal" (click)="selectSalaryRange(this.data.position.halfSalary)"> <span role="button"
                    class="badge  bg-light text-dark">Salario medio: {{this.data.position.halfSalary | currency}}</span>
            </h3>
            <h3 data-dismiss="modal" (click)="selectSalaryRange(this.data.position.maxSalary)"> <span role="button"
                    class="badge  bg-light text-dark">Salario máximo: {{this.data.position.maxSalary | currency}}</span>
            </h3>
        </div>

        <div class="my-7 separator separator-solid"></div>
        <div class="row">
            <div class="d-flex justify-content-end">
                <button id="btnClose" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2"
                    data-dismiss="modal" type="button">
                    <i class="flaticon2-cross"></i>
                    <span>Cerrar</span>
                </button>
            </div>
        </div>
    </el-modal>