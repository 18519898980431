import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PhysicalInventoryService {

  private readonly hrm_api = environment.api_url

  constructor(private http : HttpClient, private authInfo: AuthInfoService) { }

  getInventoryHeader():Observable<any>{
    return this.http.get<ResponseModel<any>>(`${this.hrm_api}/PhysicalInventory/PhysicalInventoryHeader?CompanyId=${this.authInfo.getUser().companyId}`);
  }

  getInventoryHeaderType(type:any, headerid:any):Observable<any>{
    return this.http.get<ResponseModel<any>>(`${this.hrm_api}/PhysicalInventory/PhysicalInventoryHeader?InventoryDetailId=${headerid}&Type=${type}&CompanyId=${this.authInfo.getUser().companyId}`);
  }

  updateConditionInventory(conditionInventory:any){
    return this.http.put<ResponseModel<any>>(`${this.hrm_api}/PhysicalInventory/PhysicalInventoryHeader`, conditionInventory );
  }

  updateStatusInventory(conditionInventory){
    return this.http.put<ResponseModel<any>>(`${this.hrm_api}/PhysicalInventory/PhysicalInventoryStatus`, conditionInventory );
  }

  postInventoryDetail(InventoryDetail: any):Observable<any>{
    return this.http.post<ResponseModel<any>>(`${this.hrm_api}/PhysicalInventory/PhysicalInventoryDetail`, InventoryDetail);
  }

  updateInventoryDetail(InventoryDetail: any, headerid:any):Observable<any>{
    return this.http.put<ResponseModel<any>>(`${this.hrm_api}/PhysicalInventory/UpdatePhysicalInventoryDetail?inventoryHeaderId=${headerid}`, InventoryDetail);
  }

  getInventoryDetail(headerid:any){
    return this.http.get<ResponseModel<any>>(`${this.hrm_api}/PhysicalInventory/PhysicalInventoryDetail?physicalHeaderId=${headerid}&CompanyId=${this.authInfo.getUser().companyId}`)
  }


  postInventoryChanges(InventoryChange : any){
    return this.http.post(`${this.hrm_api}/PhysicalInventory/InventoryChanges`, InventoryChange);
  }

  getInventoryChanges(type:any, headerid:any){
    return this.http.get<ResponseModel<any>>(`${this.hrm_api}/PhysicalInventory/InventoryChanges?physicalHeaderId=${headerid}&Type=${type}&CompanyId=${this.authInfo.getUser().companyId}`)
  }

  approveInventory(conditionInventory:any){
    return this.http.put<ResponseModel<any>>(`${this.hrm_api}/PhysicalInventory/InventoryUpdateSystem`, conditionInventory);
  }



}



