<h2 class="mat-dialog-title"> <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
    Estatus Envio a Concursa
    <button type="button" (click)="this.dialogRef.close(false);" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</h2>

<div class="mat-dialog-content">
    <div class="card-body">
        <div class="row gutter-b">
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Estatus <span class="text-danger"></span></label>
                <input class="form-control form-control-lg dynamicFormControl" [value]="requisition?.estado"
                    readonly type="text">
            </div>
        </div>
        <div class="row gutter-b">
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Puesto Solicitado <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg dynamicFormControl" [value]="requisition?.cargo"
                    readonly type="text">
            </div>
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Recinto <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg dynamicFormControl" [value]="requisition?.institucion" readonly
                    type="text">
            </div>
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Unidad Organizativa <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg dynamicFormControl" [value]="requisition?.unidadAdministrativa"
                    readonly type="text">
            </div>

            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Provincia <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg dynamicFormControl" [value]="requisition?.provincia"
                    readonly type="text">
            </div>
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Municipio <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg dynamicFormControl" [value]="requisition?.municipio" readonly
                    type="text">
            </div>
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Salario <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg dynamicFormControl" 
                    readonly type="text" mask="separator.2" thousandSeparator="," prefix="RD$ "
                    [(ngModel)]="salary"  name="salary"/>
            </div>

            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Meses de Prueba <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg dynamicFormControl" [value]="requisition?.mesesPrueba"
                    readonly type="number">
            </div>
            <div class="form-group col-xl-4 col-lg-6">
                <label class="">Fecha Requerida <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg dynamicFormControl"  [value]="requiredDate"
                     type="date" readonly>
            </div>

            <div class="form-group col-12">
                <label class="">Observaciones <span class="text-danger">*</span></label>
                <textarea class="form-control form-control-lg dynamicFormControl" readonly  [value]="requisition?.observaciones" rows="4"></textarea>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="this.dialogRef.close(false);"  class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        >
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    
</div>
