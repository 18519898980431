import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { FilterEvent } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-audit-changes-requisition',
  templateUrl: './audit-changes-requisition.component.html',
  styleUrls: ['./audit-changes-requisition.component.css']
})
export class AuditChangesRequisitionComponent implements OnInit{
  companies = []
  searchParameter: FilterEvent;
  requisitionPagedResult = new PagedResult<any>();
  advancedFilter:any;
  requisitionHistoryChanges = [];

  constructor(
    private readonly _dialogRef:MatDialogRef<AuditChangesRequisitionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly _personalRequisitionService:PersonalRequsitionService,
    private readonly toastService:ToastService
  ) {
    this._dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getRequisitionHistory(); 
  }

  close():void{
    this._dialogRef.close();
  }

  exportReport():void{
    const reportCode: ReportCode = ReportCode.PersonalRequisitionHistory;
    const companyId = this.data.selectedRequisition.companyId;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&PersonalRequisitionId=${this.data.selectedRequisition.personalRequisitionId}&CompanyId=${companyId}`

    const parameters = {
      url: reportUrl,
      title: 'Lista de requisiciones históricas',
      width: 1024,
      height: 768
    };

    openReport(parameters);
  }

  statusClass(requisition) {
    const { requisitionMethodId, concursaCondition } = requisition;
    if (requisitionMethodId != 1) {
      return 'label-success';
    }
    return concursaCondition == 1 ? 'label-info' : 'label-warning';
  }

  statusText(requisition) {
    const { requisitionMethodId, concursaCondition } = requisition;
    if (requisitionMethodId != 1) {
      return 'Aprobada';
    }
    return concursaCondition == 1 ? 'Enviada a Concursa' : 'Pendiente enviar a concursa';
  }

  statusTextClass(requisition) {
    const { requisitionMethodId, concursaCondition } = requisition;
    if (requisitionMethodId != 1) {
        return 'text-success';
    }
    return concursaCondition == 1 ? 'text-info' : 'text-warning';
  }

  getPaginatedRecords(event){
    if (this.requisitionPagedResult.page == event.selectedPage && this.requisitionPagedResult.pageSize == event.registersPerPage) {
      return;
    }
    this.requisitionPagedResult.page = event.selectedPage;
    this.requisitionPagedResult.pageSize = event.registersPerPage;
    this.getRequisitionHistory();
  }

  private getRequisitionHistory(){
    const page = this.requisitionPagedResult.page;
    const pageSize = this.requisitionPagedResult.pageSize;
    const searchText = this.searchParameter?.value ?? ''
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const status: number = -1;
    const companyId = this.data.selectedRequisition.companyId;
    const advancedsearch = this.advancedFilter ?? ''
    this._personalRequisitionService.getPaginatedRequisitionHistory(companyId,this.data.selectedRequisition.personalRequisitionId,page,pageSize,propKeyId,searchText,status,advancedsearch).subscribe({
      next:(response:any) =>{
        if(!response.succeded){
          this.toastService.error(response.errors[0]);
          return;
        }

        this.requisitionHistoryChanges = response.singleData.items;
        this.requisitionPagedResult = response.singleData;
      }
    });
  }
}
