import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import {
  FilterEvent,
  SearchBarPaginationParameter,
} from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { SeparationsDetailBulkLoadComponent } from '../separations-detail-bulk-load/separations-detail-bulk-load.component';
import {
  FiredType,
  MasiveFiredItem,
} from 'src/app/shared/models/masiveFiredItem';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { SeparationsService } from 'src/app/services/separations.service';
import { Separation } from 'src/app/models/separation';
import { __param } from 'tslib';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { MasiveSeparationValidationResponse } from 'src/app/shared/models/masiveSeparationValidationResponse';
import { MasiveSeparationPeoplePreview } from 'src/app/shared/models/MasiveSeparationPeoplePreview';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { DataValidationseparationsDetailModalComponent } from './data-validation-separations-detail-modal/data-validation-separations-detail-modal.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs';
import * as moment from 'moment';
declare function applySort(): any;

@Component({
  selector: 'separations',
  templateUrl: './separations.component.html',
  styleUrls: ['./separations.component.css'],
})
export class SeparationsComponent implements OnInit {
  employeeCategory: string = '';
  employeeId: string = '';
  effectiveDate: string;
  firedType: FiredType = FiredType.Normal;
  firedReason: string = '';
  initialRowsCount: number = 5;

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'MASSIVE-DISMISSAL',
    view: { key: 'View', value: false },
    add: { key: 'Add', value: false },
    export: { key: 'Export', value: false },
    search: { key: 'SEARCH', value: false },
    process: { key: 'Process', value: false },
  };
  localStorageSearch: string = 'massive-dismissal';
  searchParameter: FilterEvent;

  separationsPagedResut = new PagedResult<any>();
  reportUrl = environment.reportUrl;
  showProcess: string = 'CargaManual';
  exitTypes: ParameterControl[] = [];
  categoryTypes: ParameterControl[] = [];
  exitReason: ParameterControl[] = [];
  IdDictionary: { [key: number]: string } = {};
  CategoryDictionary: { [key: number]: string } = {};
  EffectiveDateDictionary: { [key: number]: string } = {};
  ExitTypeDictionary: { [key: number]: string } = {};
  ExitReasonDictionary: { [key: number]: string } = {};
  separationsList: MasiveFiredItem[] = [];
  errorList: MasiveSeparationValidationResponse;
  peoplePreviewConfirmation: MasiveSeparationPeoplePreview =
    {} as MasiveSeparationPeoplePreview;
  peoplePreviewConfirmationOriginal: MasiveSeparationPeoplePreview =
    {} as MasiveSeparationPeoplePreview;
  peoplePreviewConfirmationPagedResut = new PagedResult<any>();
  FileUploadedpeoplePreviewOriginal: any[] = [];
  FileUploadedpeoplePreview: any[] = [];
  FileUploadedpeoplePreviewPagedResut = new PagedResult<any>();
  fileLoadingStatus = 'waiting';
  AlertSweet = new SweetAlert();
  fileToUpload: File | null = null;
  private cleanEvidenceSubject = new BehaviorSubject<boolean>(false);
  cleanEvidence: Observable<boolean> = this.cleanEvidenceSubject.asObservable();

  searchBarDisplayNames: SearchBarPaginationParameter[] = [
    { propkeyId: 1, displayName: 'Documento de identidad' },
    { propkeyId: 2, displayName: 'Categoria' },
    { propkeyId: 3, displayName: 'Fecha Efectiva' },
    { propkeyId: 4, displayName: 'Tipo de salida' },
    { propkeyId: 4, displayName: 'Motivo de salida' },
  ];

  constructor(
    private dialog: MatDialog,
    public authInfo: AuthInfoService,
    private _toastService: ToastService,
    private _separationsService: SeparationsService,
    private srvParameter: ParameterControlService
  ) {}
  @ViewChild('file') fileUploader: ElementRef;
  filteredList: { records: any[]; enable: boolean } = {
    records: [],
    enable: false,
  };
  enclousureConfig = {
    displayKey: 'socialReason',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar',
  };

  config = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar',
  };

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.verifyPendingProces();
        this.getAllCategoryTypes();
        this.getAllExitTypes();
        this.getAllExitReason();
        this.fillUpInitialFields();

      }
    });

  }

  requestFinalSeparation() {
    this._separationsService.applyFinalSeparation().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
      } else {
        this._toastService.success(
          'Proceso de desvinculacion masiva completado'
        );
        this.showProcess = 'CargaManual';
      }
    });
  }

  canShowProcesSelector(): boolean {
    const validation = this.showProcess !== 'ValidationResults';
    const preview = this.showProcess !== 'Preview';
    return validation && preview;
  }

  verifyPendingProces() {
    this.getPeopleToBeFired().subscribe(async (res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
      }
      if (res.dataList.length >= 1) {
        const success = await this.AlertSweet.AlertConfirm(
          'Proceso Pendiente',
          `Actualmente hay un proceso de desvinculacion masiva en progreso, si inicia uno nuevo se perdera la informacion del proceso en curso ¿Desea retomar este proceso ahora?`,
          'question'
        );
        if (success) {
          this.restorePreviousProcess();
        }
      }
    });
  }

  openDetail(item) {
    this.dialog.open(DataValidationseparationsDetailModalComponent, {
      data: { item },
    });
  }

  restorePreviousProcess() {
    this.getPeopleToBeFired().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
      }
      this.getValidation();
      this.updatePreviewPagination();
      this.showProcess = 'Preview';
      this.peoplePreviewConfirmationOriginal = res;
    });
  }
  splitDataElements(index: number, event: any, origin: string): void {
    const inputElement = event.target as HTMLInputElement;

    switch (origin) {
      case 'id':
        this.IdDictionary[index] = inputElement.value;
        break;
      case 'category':
        this.CategoryDictionary[index] = event.value.ocode;
        break;
      case 'effectiveDate':
        this.EffectiveDateDictionary[index] = inputElement.value;
        break;
      case 'exitType':
        this.ExitTypeDictionary[index] = event.value.ocode;
        break;
      case 'exitReason':
        this.ExitReasonDictionary[index] = event.value.ocode;
        break;
      default:
        break;
    }
  }
  getAllExitTypes() {
    this.srvParameter.getParameters(keyWord.TypeExit).subscribe(
      (res: any) => {
        if (res.succeded) {
          this.exitTypes = res.dataList;
        } else {
          this._toastService.error(res.errors[0]);
        }
      },
      (err) => {
        this._toastService.error(err);
      }
    );
  }

  getAllCategoryTypes() {
    this.srvParameter.getParameters(keyWord.EmployeeCategory).subscribe(
      (res: any) => {
        if (res.succeded) {
          this.categoryTypes = res.dataList;
        } else {
          this._toastService.error(res.errors[0]);
        }
      },
      (err) => {
        this._toastService.error(err);
      }
    );
  }

  submitData() {
    let model: Separation[] = [];
    let currentModelHasError = false;
    const idKeysNew = Object.keys(this.IdDictionary)
      .map(Number)
      .sort((a, b) => b - a);
    const idKeys = Math.max(...idKeysNew);
    for (let index = 0; index <= idKeys; index++) {
      if (this.verifyNonIdProvidedEmployee(index)) {
        delete this.IdDictionary[index];
        continue;
      }
      debugger;
      let tmpDate: string = this.EffectiveDateDictionary[index];
      let date = moment(tmpDate);
      const month = date.format('MM');
      const year = date.format('YYYY');
      const day = date.format('DD');
      const formattedDate: string = `${year}${month}${day}`;
      const categoryInfo = this.getCategoryById(
        this.CategoryDictionary[index],
        index
      );
      const reasonInfo = this.getExitReasonById(
        this.ExitReasonDictionary[index],
        index
      );
      const exitInfo = this.getExitTypeById(
        this.ExitTypeDictionary[index],
        index
      );
      if (
        categoryInfo == undefined ||
        reasonInfo == undefined ||
        exitInfo == undefined
      ) {
        currentModelHasError = true;
        break;
      }
      const currentElement: Separation = {
        category: categoryInfo.stringData,
        categoryId: Number(this.CategoryDictionary[index]),
        effectiveDate: formattedDate,
        exitReason: reasonInfo.stringData,
        exitReasonId: Number(this.ExitReasonDictionary[index]),
        exitType: exitInfo.stringData,
        exitTypeId: Number(this.ExitTypeDictionary[index]),
        personalIdentification: this.IdDictionary[index],
      };
      model.push(currentElement);
    }
    if (currentModelHasError) {
      return;
    }
    if (model.length <= 0) {
      this._toastService.warning('No hay ningun registro para procesar');
      return;
    }

    this._separationsService
      .submitManualFilledFiredEmployees(model)
      .subscribe((res: any) => {
        if (res.errors.lenght > 0) {
          this._toastService.error(res.errors[0]);
        }
        this.getValidation();
      });
  }
  checkPeopleInformation(people: any): boolean {
    if (
      people.personalIdentification === undefined ||
      people.effectiveDate === undefined ||
      people.category === undefined ||
      people.categoryId === undefined ||
      people.exitType === undefined ||
      people.exitReason === undefined ||
      people.exitReasonId === undefined ||
      people.exitTypeId === undefined
    ) {
      return true;
    }
    return false;
  }
  submitDataFromImportedFile() {
    let model: Separation[] = [];
    let re = /\//gi;
    this.FileUploadedpeoplePreviewOriginal.map((people, index) => {
      if (this.checkPeopleInformation(people)) {
        this._toastService.warning(
          `Hay informacion incompleta o no valida en la fila no. ${
            index + 1
          } de la lista`
        );
        throw 'Not completed information at index ' + index + 1;
      }
      let tmpDate: string[] = people.effectiveDate.replace(re, '-').split('-');
      let formattedDate = moment(
        `${tmpDate[2]}-${tmpDate[1]}-${tmpDate[0]}`
      ).format('YYYYMMDD');
      model.push({
        personalIdentification: String(people.personalIdentification),
        effectiveDate: formattedDate,
        category: String(people.category),
        categoryId: people.categoryId,
        exitType: String(people.exitType),
        exitReason: String(people.exitReason),
        exitReasonId: people.exitReasonId,
        exitTypeId: people.exitTypeId,
      });
    });
    if (model.length <= 0) {
      this._toastService.warning('No hay ningun registro para procesar');
      return;
    }

    this._separationsService
      .submitManualFilledFiredEmployees(model)
      .subscribe((res: any) => {
        if (res.errors.lenght > 0) {
          this._toastService.error(res.errors[0]);
        }
        this.getValidation();
      });
  }

  formatDatePreview(date: string) {
    let mask = '####-##-##';
    if (date != undefined) {
      let i = 0;
      const v = date.toString();
      return mask.replace(/#/g, (_) => v[i++]);
    }
  }

  getValidation() {
    this._separationsService.getValidation().subscribe((res: any) => {
      if (res.errors.lenght > 0) {
        this._toastService.error(res.errors[0]);
      }
      this.errorList = res;
      if (this.errorList.dataList.length >= 1) {
        this.showProcess = 'ValidationResults';
      } else {
        this.getPeopleToBeFired().subscribe((res2: any) => {
          if (res2.errors.length > 0) {
            this._toastService.error(res2.errors[0]);
          }

          this.peoplePreviewConfirmationOriginal = res2;
          this.updatePreviewPagination();
          this.showProcess = 'Preview';
        });
      }
    });
  }

  getPeopleToBeFired() {
    return this._separationsService.getPeopleToBeFired();
  }

  verifyNonIdProvidedEmployee(index) {
    if (
      this.IdDictionary[index] === undefined ||
      (this.IdDictionary[index] === '' &&
        this.CategoryDictionary[index] === undefined &&
        this.EffectiveDateDictionary[index] === undefined &&
        this.ExitTypeDictionary[index] === undefined &&
        this.ExitReasonDictionary[index] === undefined)
    ) {
      return true;
    }
    return false;
  }

  getExitReasonById(id: string, index: number) {
    if (id == undefined) {
      this.showHandledValidationError(index, 'Motivo de salida');
    }
    return this.exitReason.find((ee) => ee.ocode === Number(id));
  }

  getCategoryById(id: string, index: number) {
    if (id == undefined) {
      this.showHandledValidationError(index, 'Categoria');
      return;
    }
    return this.categoryTypes.find((ee) => ee.ocode === Number(id));
  }
  getExitTypeById(id: string, index: number) {
    if (id == undefined) {
      this.showHandledValidationError(index, 'Tipo de Salida');
    }
    return this.exitTypes.find((ee) => ee.ocode === Number(id));
  }
  showHandledValidationError(index: number, columna: string) {
    this._toastService.warning(
      `Asegurate de que el empleado en la posicion ${
        index + 1
      } de la columna ${columna} este completo`
    );
  }
  goBack() {
    this.showProcess = 'CargaManual';
  }

  getAllExitReason() {
    this.srvParameter.getParameters(keyWord.ReasonExit).subscribe(
      (res: any) => {
        if (res.succeded) {
          this.exitReason = res.dataList;
        } else {
          this._toastService.error(res.errors[0]);
        }
      },
      (err) => {
        this._toastService.error(err);
      }
    );
  }

  addAtIndex(i: number) {
    this.updateIndexes(i);
    this.addCurrentRow(i + 1, {
      id: '',
      name: '',
      effectiveDate: '',
      firedType: FiredType.Incumplimiento,
      firedReason: '',
      isActive: true,
    });
  }

  updateIndexes(i: number) {
    const idKeys = Object.keys(this.IdDictionary)
      .map(Number)
      .sort((a, b) => b - a);
    const categoriesKeys = Object.keys(this.CategoryDictionary)
      .map(Number)
      .sort((a, b) => b - a);
    const effectiveDateKeys = Object.keys(this.EffectiveDateDictionary)
      .map(Number)
      .sort((a, b) => b - a);
    const exitTypeKeys = Object.keys(this.ExitTypeDictionary)
      .map(Number)
      .sort((a, b) => b - a);
    const exitReasonKeys = Object.keys(this.ExitReasonDictionary)
      .map(Number)
      .sort((a, b) => b - a);
    idKeys.forEach((key) => {
      if (key > i) {
        this.IdDictionary[key + 1] = this.IdDictionary[key];
        delete this.IdDictionary[key];
      }
    });
    categoriesKeys.forEach((key) => {
      if (key > i) {
        this.CategoryDictionary[key + 1] = this.CategoryDictionary[key];
        delete this.CategoryDictionary[key];
      }
    });
    effectiveDateKeys.forEach((key) => {
      if (key > i) {
        this.EffectiveDateDictionary[key + 1] =
          this.EffectiveDateDictionary[key];
        delete this.EffectiveDateDictionary[key];
      }
    });
    exitTypeKeys.forEach((key) => {
      if (key > i) {
        this.ExitTypeDictionary[key + 1] = this.ExitTypeDictionary[key];
        delete this.ExitTypeDictionary[key];
      }
    });
    exitReasonKeys.forEach((key) => {
      if (key > i) {
        this.ExitReasonDictionary[key + 1] = this.ExitReasonDictionary[key];
        delete this.ExitReasonDictionary[key];
      }
    });
  }

  removeRow(index: number) {
    delete this.FileUploadedpeoplePreviewOriginal[index];
    delete this.FileUploadedpeoplePreview[index];
    this.updatePagination();
  }

  addCurrentRow(index: number, row: MasiveFiredItem) {
    this.separationsList.splice(index, 0, row);
    if (this.validateFields()) {
      const currentElement: MasiveFiredItem = {
        name: this.employeeCategory,
        id: this.employeeId,
        effectiveDate: this.effectiveDate,
        firedReason: this.firedReason,
        firedType: this.firedType,
        isActive: false,
      };
      this.separationsList.push(currentElement);
    } else {
      this.showValidationMessage();
    }
  }

  fileUpload() {
    this.fileUploader.nativeElement.click();
  }

  handleFileInput(files: FileList) {
    this.fileLoadingStatus = 'reading';
    this.fileToUpload = files.item(0);
    const lector = new FileReader();

    lector.onload = async (event) => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const book = XLSX.read(data, { type: 'array' });
      const sheetName = book.SheetNames[0];
      const sheet = book.Sheets[sheetName];
      const datosExcel = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const currentUser = this._separationsService.getCurrentUserId();
      const workerData: any[] = [datosExcel, currentUser];
      const headers = datosExcel[0];
      delete datosExcel[0];

      if (this.validateDocument(headers, datosExcel)) {
        this.fileLoadingStatus = 'complete';
        this._toastService.error(
          'La estructura del archivo cargado no es igual a la esperada, por favor descarga la plantilla e intentalo de nuevo.'
        );
        return;
      }

      this.fileUploader.nativeElement.value = null;
      // Procesar datos usando Web Worker para evitar bloquear el hilo principal
      this.processDataInWorker(workerData);
    };

    lector.readAsArrayBuffer(this.fileToUpload);
  }

  processDataInWorker(datosExcel: any[]) {
    if (typeof Worker !== 'undefined') {
      const worker = new Worker('./massive-data-processor.worker.ts', {
        type: 'module',
      });

      worker.postMessage(datosExcel);

      worker.onmessage = ({ data }) => {
        this.FileUploadedpeoplePreviewOriginal = data;
        this.fileLoadingStatus = 'complete';
        this.updatePagination();
      };

      worker.onerror = (error) => {
        console.error('Worker error:', error);
        this.fileLoadingStatus = 'error';
      };
    } else {
      console.error('Web Workers are not supported in this environment.');
      this.fileLoadingStatus = 'error';
    }
  }

  validateDocument(headers, data) {
    const expected: string[] = [
      'Documento de identidad',
      'Tipo de salida',
      'Fecha efectiva',
      'Motivo de salida',
      'Categoria',
      'ExitTypeId',
      'ExitReasonId',
      'CategoryId',
    ];
    return headers === expected;
  }

  showValidationMessage() {
    this._toastService.success('Nueva fila agregada');
  }

  validateFields() {
    if (
      this.employeeCategory === '' ||
      this.employeeId === '' ||
      this.effectiveDate === undefined ||
      this.firedType === 0 ||
      this.firedReason == ''
    ) {
      return false;
    }
    return true;
  }

  filterSearch(event: FilterEvent) {
    this.separationsPagedResut.page = 1;
    this.searchParameter = event;

    applySort();
  }

  getPaginatedRecords(event: PaginationEvent) {
    if (
      this.FileUploadedpeoplePreviewPagedResut.page == event.selectedPage &&
      this.FileUploadedpeoplePreviewPagedResut.pageSize ==
        event.registersPerPage
    ) {
      return;
    }
    this.FileUploadedpeoplePreviewPagedResut.page = event.selectedPage;
    this.FileUploadedpeoplePreviewPagedResut.pageSize = event.registersPerPage;
    this.FileUploadedpeoplePreviewPagedResut.items =
      this.FileUploadedpeoplePreviewOriginal;
    this.updatePagination();
  }

  getPreviewConfirmationPaginatedRecords(event: PaginationEvent) {
    if (
      this.peoplePreviewConfirmationPagedResut.page == event.selectedPage &&
      this.peoplePreviewConfirmationPagedResut.pageSize ==
        event.registersPerPage
    ) {
      return;
    }
    this.peoplePreviewConfirmationPagedResut.page = event.selectedPage;
    this.peoplePreviewConfirmationPagedResut.pageSize = event.registersPerPage;
    this.peoplePreviewConfirmationPagedResut.items =
      this.peoplePreviewConfirmationOriginal.dataList;
    this.updatePreviewPagination();
  }

  updatePreviewPagination() {
    const page = this.peoplePreviewConfirmationPagedResut.page;
    const pageSize = this.peoplePreviewConfirmationPagedResut.pageSize;
    const startIndex = (page - 1) * pageSize;
    this.peoplePreviewConfirmation.dataList =
      this.peoplePreviewConfirmationOriginal?.dataList?.slice(
        startIndex,
        startIndex + pageSize
      );
    applySort();
  }

  updatePagination() {
    const page = this.FileUploadedpeoplePreviewPagedResut.page;
    const pageSize = this.FileUploadedpeoplePreviewPagedResut.pageSize;
    const startIndex = (page - 1) * pageSize;
    this.FileUploadedpeoplePreviewPagedResut.items =
      this.FileUploadedpeoplePreviewOriginal;
    this.FileUploadedpeoplePreview =
      this.FileUploadedpeoplePreviewOriginal.slice(
        startIndex,
        startIndex + pageSize
      );
    applySort();
  }

  getRouterToBack() {
    return '/collective-actions';
  }

  fillUpInitialFields() {
    for (let index = 0; index < this.initialRowsCount; index++) {
      this.separationsList.push({
        id: '',
        name: '',
        effectiveDate: '',
        firedType: FiredType.Incumplimiento,
        firedReason: '',
        isActive: true,
      });
    }
  }
}
