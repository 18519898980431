import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[el-dropdown-side-menu]',
  template: '<ng-container></ng-container>',
})
export class DropdownSideMenuComponent implements OnInit {
  @Input() menu: object[];
  ngOnInit(): void {
  }
}
