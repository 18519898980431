import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmployeeEntry, EmployeeEntryListHistory } from '../pages/job-offer/models/Employee';
import { changeDetails, EmployeeChange } from '../pages/RegistrationAndControl/approval-employee-change-actions/models/employee-change';
import { IEmployeeVacancyHistory } from '../pages/RegistrationAndControl/personnel-actions/models/EmployeeVacancyHistory';
import { SearchParametersForEmployees } from '../pages/RegistrationAndControl/personnel-actions/models/SearchParametersForEmployees.enum';
import { SearchParametersForEmployeesVacancies } from '../pages/RegistrationAndControl/personnel-actions/models/SearchParametersForEmployeesVacancies.enum';
import { InOutList } from '../shared/models/InOutList.model';
import { NoWorkingDay } from '../shared/models/no-working-day.model';
import { RequestMetaData, ResponseModel } from '../shared/models/strongly-typed-response.model';
import { keyWord } from '../shared/utils/parameterControl';
import { IAvailableYearsView, IPauseVacationPost, IVacancy } from './../pages/RegistrationAndControl/personnel-actions/models/Vacancy';
import { AuthInfoService } from './auth-info.service';
import { ParameterControlService } from './parameter-control.service';
import { IEmployeePermissions } from '../pages/RegistrationAndControl/personnel-actions/models/EmployeePermissions';
import { IEditUnlikInfo } from '../pages/RegistrationAndControl/unlink-employees-tab/models/ExitList';

@Injectable({
  providedIn: 'root'
})
export class PersonnelActionsService {


  apiUrl = environment.api_core_url;
  api = environment.api_url
  api_map = environment.api_map
  api_lobby = environment.api_core_sigei
  constructor(
    private http: HttpClient,
    private srvParam: ParameterControlService,
    private authInfoServ: AuthInfoService
  ) { }

  getHolidays():Observable<ResponseModel<NoWorkingDay>>{
    return this.http.get<ResponseModel<NoWorkingDay>>(`${this.apiUrl}/NoWorkingDay?CompanyId=${this.authInfoServ.getCompanyId()}`)
  }

  postExitList(exitlist){
    return this.http.post(`${this.api}/EmployeeExitList`, exitlist)
  }
  getEmployeeExitListByEmployeeId(id){
    return this.http.get(`${this.api}/EmployeeExitList?EmployeeId=${id}&Ocode=0&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }

  getEmployeeEntryListHistory(employeeId){
    return this.http.get(`${this.api}/EmployeeEntryListHistory?EmployeeId=${employeeId}&Type=2&CompanyId=${this.authInfoServ.getCompanyId()}`)
  }

  getEmployeeByEmployeeId(employeeId){
    return this.http.get(`${this.api}/employees?type=5&employeeNumber=${employeeId}&CompanyId=${this.authInfoServ.getCompanyId()}`)
  }

  getEmployeeByNumber(employeeNumber){
    return this.http.get(`${this.api}/employees?type=2&employeeNumber=${employeeNumber}&CompanyId=${this.authInfoServ.getCompanyId()}`)
  }

  getEmployeeData(type:SearchParametersForEmployees, value:number): Observable<ResponseModel<any>>{
    return this.http.get<ResponseModel<any>>(`${this.api}/employees?type=${type}&employeeNumber=${value}&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }

  getEmployeesVacancyData(type:SearchParametersForEmployeesVacancies, value:number): Observable<ResponseModel<any>>{
    return this.http.get<ResponseModel<any>>(`${this.api}/EmployeeVacancy?type=${type}&idvacancy=${value}&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }

  sendPauseVacationRequest(model: IPauseVacationPost){
    return this.http.post<ResponseModel<any>>(`${this.api}/EmployeeVacancy/PauseVacation`, model);
  }

  getEmployeeById(employeeId){
    return this.http.get<ResponseModel<any>>(`${this.api}/employees?type=5&employeeNumber=${employeeId}&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }


  getEmployeeByNumberInfoAll(employeeNumber){
    return this.http.get<ResponseModel<any>>(`${this.api}/employees?type=5&employeeNumber=${employeeNumber}&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)

  }

  unlinkEmployee(employeeId,exitTypeId,exitReason,exitEvidence,exitDate,exitUserId, comment){
    return this.http.delete<ResponseModel<any>>(`${this.api}/Employees/Unlink?EmployeeId=${employeeId}&ExitTypeId=${exitTypeId}&ExitReason=${exitReason}&ExitEvidence=${exitEvidence}&ExitDate=${exitDate}&ExitUserId=${exitUserId}&Comment=${comment}`)
  }

  editUnlinkInfoEmployee(model: IEditUnlikInfo){
    return this.http.put<ResponseModel<any>>(`${this.api}/Employees/EditUnlinkInfo`,model)
  }

  inactiveEmployeeUser(employeeId: number, companyId: number){
    return this.http.delete<ResponseModel<any>>(`${this.api_lobby}/User/DeleteByEmployeeId?employeeId=${employeeId}&companyId=${companyId}&modifyUserId=${this.authInfoServ.getUserId()}`)
  }

  activeEmployeeUser(userId: number){
    return this.http.delete<ResponseModel<any>>(`${this.api_lobby}/User/ActiveUser?userId=${userId}&modifyUserId=${this.authInfoServ.getUserId()}`)
  }

  getHistoryWork(personId:number){
    return this.http.get(`${this.apiUrl}/PersonsWorkExperiences?IdWorkExperience=${personId}&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true&Type=4&Public=true`)
  }

  getPersonsWorkExperiences(personId){
    return this.http.get(this.apiUrl + `/PersonsWorkExperiences?type=1&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true&IdWorkExperience=` + personId);
  }

  getEmployeeAvailableVacationYears(employeeId: number, companyId: number, type: number = 0){
    return this.http.get<ResponseModel<IAvailableYearsView>>(this.api + `/EmployeeVacancy/AvailableYears?type=${type}&employeeId=${employeeId}&companyId=${companyId}`);
  }

  getEmployeeVacancies(employNumber){ /* type 2 busca las vacaciones de unServidor Público */
    return this.http.get(this.api + `/EmployeeVacancy?type=3&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true&idvacancy=` + employNumber);
  }
  getVancacyById(vacancyId){ /* Type 1 busca el detalle de unas vacaciones por su id */
    return this.http.get(this.api + `/EmployeeVacancy?type=1&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true&idvacancy=` + vacancyId);
  }
  getEployeePermissions(){
    return this.http.get(this.api + `/EmployeePermissions?CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`);
  }

  getEmployeePermissionsByIdEmployee(id){
    return this.http.get<ResponseModel<IEmployeePermissions>>(`${this.api}/EmployeePermissions?IdPermissions=${id}&Type=1&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }

  getEployeePermissionById(id){
    return this.http.get(`${this.api}/EmployeePermissions?IdPermissions=${id}&Type=2&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }

  getEmployeeLicenses(employeeNumber){
    return this.http.get(this.api + `/EmployeePermissions?IdPermissions=${employeeNumber}&Type=1&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }
  getEmployeeLicenseById(employeeId){
    return this.http.get(this.api + `/EmployeePermissions?IdPermissions=${employeeId}&Type=2&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }

  /*updateLicense(license){
    return this.http.put(this.api + `/EmployeePermissions?IdPermissions`,license);
  }

  deleteLicense(licenseId){
    return this.http.delete(this.api + `EmployeePermissions?IdPermissions=${licenseId}`);
  }*/

  saveVacancy(vacancy){
    return this.http.post(this.api + '/EmployeeVacancy', vacancy)
  }

  deleteVacancy(vacancyId){
    return this.http.delete(this.api + '/EmployeeVacancy?IdVacancy=' + vacancyId)
  }
  updateVacancy(vacancy){
    return this.http.put(this.api + '/EmployeeVacancy', vacancy)
  }

  saveEmployeeHistory(employeeHistory: IEmployeeVacancyHistory){
    return this.http.post(this.api + '/EmployeeVacancyHistory', employeeHistory)
  }
  getInstitutions(){
    return this.http.get(`${this.apiUrl}/Institution?InstitutionId=0&CompanyId=${this.authInfoServ.getCompanyId()}`)
  }
  deleteHistoryWork(id){
    return this.http.delete(`${this.api}/EmployeeVacancyHistory?IdVacancyHistory=${id}`)
  }
  putHistoryWork(employeeHistory: IEmployeeVacancyHistory){
    return this.http.put(`${this.api}/EmployeeVacancyHistory`, employeeHistory)
  }

  saveEmployeePermission(employeePermission){
    return this.http.post(this.api + '/EmployeePermissions', employeePermission)
  }

  updateEmployeePermission(employeePermission){
    return this.http.put(this.api + '/EmployeePermissions', employeePermission)
  }

  rejectPermission(rejectPermission: {permissionsId: number, reasonReject: string}){
    return this.http.post(this.api + '/EmployeePermissions/Reject', rejectPermission)
  }

  approvePermission(id:number){
    return this.http.delete(`${this.api}/EmployeePermissions/Approved?IdPermissions=${id}`)
  }
  getPersonalActions() {
    return this.srvParam.getParameters(keyWord.PersonalAction)
  }

  getBankingEntity() {
    return this.srvParam.getParameters(keyWord.BankingEntity)
  }

  getAFP() {
    return this.srvParam.getParameters(keyWord.AFP)
  }

  getARS() {
    return this.srvParam.getParameters(keyWord.ARS)
  }

  getContractType() {
    return this.srvParam.getParameters(keyWord.RequisitionType)
  }

  getDepartments(CompanyId: number = this.authInfoServ.getCompanyId(), type: number ) {
    //type 2 only approvals
    //type 5 all
    return this.http.get<ResponseModel<any>>(`${this.apiUrl}/companyStructure/GetCompanyStructure?CompanyId=${CompanyId}&type=${type}`)
  }

  getPositions() {
    return this.http.get(`${this.apiUrl}/positions?type=1&CompanyId=${this.authInfoServ.getCompanyId()}`)
  }

  getEmployeeByParemeter(type: any, paremeter: any) {
    return this.http.get(`${this.api}/Employees?type=${type}&person=${paremeter}&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }

  getLocations() {
    return this.http.get<any>(`${this.apiUrl}/Locations?CompanyId=${this.authInfoServ.getCompanyId()}`);
  }

  getActionMotives() {
    return this.srvParam.getParameters(keyWord.ActionChange)
  }

  getModificationReasons() {
    return this.srvParam.getParameters(keyWord.ReasonsToModifyPersonalAction)
  }

  getStatusGlobalValidation() {
    return this.srvParam.getParameters(keyWord.StatusGlobalValidation)
  }

  CreateEmployeeChange(newEmployeeChange: EmployeeChange) {
    return this.http.post<ResponseModel<any>>(`${this.api}/EmployeeChange`, newEmployeeChange)
  }

  createHistoryPersonalActions(newEmployeeChange: EmployeeChange){
    return this.http.post(`${this.api}/EmployeeChange/HistoryPersonalActions`, newEmployeeChange)
  }

  updateEmployeeChange(newEmployeeChange: EmployeeChange) {
    return this.http.put<ResponseModel<any>>(`${this.api}/EmployeeChange`, newEmployeeChange)
  }

  updateEmployeeChangeDetails(changeDetails:changeDetails){
    return this.http.put<ResponseModel<any>>(`${this.api}/employeeChangeDetails`, changeDetails)
  }

  getChangeDetails(id, type = 3) {
    /* type 3 = employeeChangeHeaderId */
    return this.http.get<ResponseModel<any>>(`${this.api}/EmployeeChangeDetails?IdEmployeeChangeDetails=${id}&type=${type}&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }

  getMAPCompanyDATAByRNC(IdentificationNumber: string){
    return this.http.get(`${this.api_lobby}/Companies?Status=1&Type=4&IdentificationNumber=${IdentificationNumber}`)
  }

  getUsersApprovals(companyId, processId){
    return this.http.get(`${this.api_lobby}/Companies/approvals?CompanyId=${companyId}&ProcessId=${processId}`)
  }

  getDesignations(id: number){
    return this.http.get(`${this.api}/ActionsEmployees?IdActionsEmployee=${id}&type=2&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }

  getUnlikInfo(employeeId: number){
    return this.http.get<ResponseModel<any>>(`${this.api}/ActionsEmployees?EmployeeId=${employeeId}&type=3`)
  }

  getChangeHeader(employId,type = 2) {
    /*
    type 1 = header id
    type 2 = employnumber
    */
    return this.http.get(`${this.api}/EmployeeChangeHeader?type=${type}&employeeChangeHeaderId=${employId}&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }
  getChangeHeaderById(employId) {
    return this.http.get(`${this.api}/EmployeeChangeHeader?type=1&employeeChangeHeaderId=${employId}&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }

  getEmployeeChange(id){
    return this.http.get(`${this.api}/employeechange?employeechangeid=${id}&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }

  getEmployeeEntryList(employeeId:number=0): Observable<ResponseModel<EmployeeEntry>>{
    return this.http.get<ResponseModel<EmployeeEntry>>(`${this.api}/EmployeeEntryList?EmployeeId=${employeeId}&Ocode=0&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`);
  }

  upsertEmployeeEntryList(model:EmployeeEntry[]): Observable<RequestMetaData>{
    if(model[0].employeeEntryListId!==0){
      return this.http.put<RequestMetaData>(`${this.api}/EmployeeEntryList`, model);
    }else{
      return this.http.post<RequestMetaData>(`${this.api}/EmployeeEntryList`, model);

    }
  }

  insertEmployeeEntryListHistory(model:EmployeeEntryListHistory[]): Observable<RequestMetaData>{
      return this.http.post<RequestMetaData>(`${this.api}/EmployeeEntryListHistory`, model);
  }

  upsertEmployeeExitList(model:any): Observable<RequestMetaData>{
    if(model[0].employeeExitListId!==0){
      return this.http.put<RequestMetaData>(`${this.api}/EmployeeExitList`, model);
    }else{
      return this.http.post<RequestMetaData>(`${this.api}/EmployeeExitList`, model);

    }
  }

  getLicensesTypes(){
    return this.http.get(`${this.apiUrl}/LicenseType?LicenseTypeId=0&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }

  getTypePermision(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/PermissionTypes/GetAll?CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`);
  }

  getCompanyByID(companyCode: number){
    return this.http.get(`${this.api_lobby}/Companies?companyCode=${companyCode}&type=3`);
  }

  getEntryList(){
    return this.http.get(`${this.apiUrl}/InOutList?companyId=${this.authInfoServ.getCompanyId()}&status=true&type=1`);
  }

  getSwornDeclaration(entryListHistoryId){
    return this.http.get(`${this.api}/EmployeeEntryListHistory/GetSwornDeclaration?EmployeeEntryListHistoryId=${entryListHistoryId}`);
  }

  getInOutList(){
    return this.http.get<ResponseModel<InOutList>>(`${this.apiUrl}/InOutList?companyId=${this.authInfoServ.getCompanyId()}&status=true&type=2`);
  }

}
