import { Component, OnInit } from '@angular/core';
import { AuthInfoService } from 'src/app/services/auth-info.service';

@Component({
  selector: 'app-annual-operative-plan-list',
  templateUrl: './annual-operative-plan-list.component.html',
  styleUrls: ['./annual-operative-plan-list.component.css']
})
export class AnnualOperativePlanListComponent implements OnInit {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PlanOA',
    addAction:'ADDPO',
    dashboardAction: 'DASHBOARDPO',
    exportAction: 'EXPORTPO',
  }

  isLoading: boolean = true;
  list = []
  paginatedList = []
  selectedItems: any;
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "personalIdentification", displayName: "Identificación" },
      { propkey: "name", displayName: "Servidor Público" },
      { propkey: "employNumber", displayName: "Código del Servidor Público" },
      { propkey: "departmentName", displayName: "Unidad organizativa" },
      { propkey: "positionName", displayName: "Posición" },
    ];
    statusOptions = [
      { item_id: 1, item_text: 'Activos'},
      { item_id: 2, item_text: 'Inactivos'}
    ];
    dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      enableCheckAll: true,
      itemsShowLimit: 2,
      allowSearchFilter: false,
      limitSelection: -1
    };
  constructor(public authInfo: AuthInfoService) { }

  ngOnInit(): void {

    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result=>{
      if(result == true){
        
        
        
      }
    })

  }









  getFilteredList(event) {
    this.filteredList = event;
  }
  getPaginatedRecords(event) {
    this.paginatedList = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }




}
