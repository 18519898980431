import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Departments } from 'src/app/models/departments.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ContactMeansDepartmentService } from 'src/app/services/contactMeans-department.service';
import { DepartmentService } from 'src/app/services/department.service';
import { swalConfirmDelete } from 'src/app/shared/utils/utility';
import { ContactMeansDepartment, PhoneExtensions } from '../../department-tab/models/contactMeans-department.model';

@Component({
  selector: 'app-directory-modal',
  templateUrl: './directory-modal.component.html',
  styleUrls: ['./directory-modal.component.css']
})
export class DirectoryModalComponent implements OnInit {
  form: FormGroup;
  formInvalid: boolean = false;
  phoneExtensions: PhoneExtensions[] = [];
  phoneExtension: PhoneExtensions;
  currentIndexEditExtension: number = - 1;
  contactMeansDepartment: ContactMeansDepartment
  constructor(
    public dialogRef: MatDialogRef<DirectoryModalComponent>,
    private departmentService: DepartmentService,
    private auth: AuthInfoService,
    private contactMeansDepartmentService: ContactMeansDepartmentService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { department: Departments, add: boolean, view: boolean, edit: boolean },
    private fb: FormBuilder,
  ) {
    dialogRef.disableClose = true;
    this.buildForm();
    if(this.data?.department){
     
    }
    if(this.data.view){
      this.form.disable();
    }
  }

  ngOnInit(): void {
    this.getContactMeansDepartment();
  }

  buildForm(){
    this.form = this.fb.group({
      contactMeansDepartmentId: [0],
      departamentId: [0],
      email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/)]],
      phoneNumber: ['', [Validators.required]],
      phoneExtensions: [],
      extension: ['']
    });
  }

  getContactMeansDepartment(){
    this.contactMeansDepartmentService.getContactMeansDepartmentBy(this.data?.department?.departmentId, this.auth.getCompanyId()).subscribe({
      next:(res:any) => {
        if (res.succeded) {
          this.contactMeansDepartment = res?.dataList[0]
          if(this.contactMeansDepartment?.contactMeansDepartmentId > 0){
            this.phoneExtensions = this.contactMeansDepartment?.phoneExtensions.filter(p => p?.status);
            this.form.patchValue({
              ...this.contactMeansDepartment,
              phoneExtensions: this.phoneExtensions
            });
          }
        } else {
          this.toastr.error(res.errors[0]);
        }
      }, error: (error:any) => {this.toastr.error('Ha ocurrido un error inesperado')}
    })
  }

  save(){
    this.formInvalid = this.form.invalid;
    if(this.formInvalid){
      return;
    }
    const formValue = this.form.value;
    const contactMeansDepartment: ContactMeansDepartment = {
      ...formValue,
      status: true,
      phoneNumber: formValue?.phoneNumber,
      email: formValue?.email,
      departamentId: this.data?.department?.departmentId,
      createUserId: formValue.contactMeansDepartmentId > 0 ? 0 : this.auth.getUserId(),
      modifyUserId: formValue.contactMeansDepartmentId > 0 ? this.auth.getUserId() : 0,
      companyId: this.data?.department?.companyId,
      phoneExtensions: formValue?.phoneExtensions ?? []
    }
    if(contactMeansDepartment.contactMeansDepartmentId > 0){
      this.put(contactMeansDepartment);
    }else{
      this.post(contactMeansDepartment);
    }
  }

  post(contactMeansDepartment: ContactMeansDepartment){
    this.contactMeansDepartmentService.post(contactMeansDepartment).subscribe({
      next: res => {
        if(!res?.succeded){
          this.toastr.error(res?.errors[0]);
          return;
        }
        this.toastr.success('Medio de contacto agregado correctamente');
        this.phoneExtension = null;
        this.dialogRef.close({success: true})
      }, error: error => {
        this.toastr.error('Ocurrió un error inesperado al agregar el medio de contacto de la unidad organizativa');
      }
    });
  }

  put(contactMeansDepartment: ContactMeansDepartment){
    this.contactMeansDepartmentService.put(contactMeansDepartment).subscribe({
      next: res => {
        if(!res?.succeded){
          this.toastr.error(res?.errors[0]);
          return;
        }
        this.toastr.success('Medio de contacto actualizado correctamente');
        this.phoneExtension = null;
        this.dialogRef.close({success: true})
      }, error: error => {
        this.toastr.error('Ocurrió un error inesperado al actualizar el medio de contacto de la unidad organizativa');
      }
    });
  }

  saveExtension(){
    const extension = this.form.get('extension')?.value;
    if(extension?.length > 0){
      if(this.currentIndexEditExtension >= 0){
        this.updateExtension(extension);
      }else{
        this.addExtension(extension);
      }
    }
  }

  private updateExtension(extension: string){
    this.phoneExtensions.forEach((p, index) => {
      if(index === this.currentIndexEditExtension){
        p.modifyUserId = this.auth.getUserId();
        p.extension = extension;
      }
    });
    this.phoneExtension = null;
    this.form.get('extension').setValue('');
    this.form.get('phoneExtensions').setValue(this.phoneExtensions);
    this.currentIndexEditExtension = -1;
  }
  
  private addExtension(extension: string){
    const phoneExtension: PhoneExtensions = {
      phoneExtensionId: this.phoneExtension?.phoneExtensionId ?? 0,
      extension: extension,
      contactMeansDepartmentId: this.form?.get('contactMeansDepartmentId')?.value,
      status: true,
      companyId: this.phoneExtension?.companyId ?? this.auth.getCompanyId(),
    }
    if(this.phoneExtensions?.some(p => p?.extension === extension)){
      this.toastr.warning('Ya existe una extensión igual');
      return;
    }
    phoneExtension.createUserId = this.auth.getUserId(),
    this.phoneExtensions.push(phoneExtension);
    this.form.get('extension').setValue('');
    this.form.get('phoneExtensions').setValue(this.phoneExtensions);
    this.currentIndexEditExtension = -1;
  }

  editPhoneExtension(phoneExtension: PhoneExtensions, index: number){
    this.phoneExtension = {...phoneExtension},
    this.currentIndexEditExtension = index;
    this.form.get('extension').setValue(phoneExtension?.extension);
  }

  deletePhoneExtension(phoneExtension: PhoneExtensions, index: number){
    if(phoneExtension?.phoneExtensionId === 0){
      this.phoneExtensions.splice(index, 1);
      this.form.get('phoneExtensions').setValue(this.phoneExtensions);
      return;
    }
    swalConfirmDelete(() => {
      this.contactMeansDepartmentService.deletePhoneExtensions(phoneExtension.phoneExtensionId).subscribe({
        next: res => {
          if(!res?.succeded){
            this.toastr.error(res?.errors[0]);
            return;
          }
          this.toastr.success('Extensió eliminada correctamente');
          this.phoneExtension = null;
          this.phoneExtensions = this.phoneExtensions?.filter(p => p?.phoneExtensionId !== phoneExtension?.phoneExtensionId);
          this.form.get('phoneExtensions').setValue(this.phoneExtensions);
          this.currentIndexEditExtension = -1;
        }, error: error => {
          this.toastr.error('Ocurrió un error inesperado al eliminar la extensión');
        }
      });
    });
  }

  get isExtensionValid(){
    return this.form?.get('extension')?.value?.length === 0
  }

  getError(name: string) {
    if (!this.formInvalid) {
      return '';
    }
    const field = this.form.get(name)
    if (field.hasError('required')) {
      return 'Este campo es obligatorio';
    }
    if (field.hasError('min')) {
      return 'Este campo es obligatorio';
    }
    if (field.hasError('maxlength')) {
      return `Este campo sólo admite un máximo de ${field?.errors['maxlength']?.requiredLength} caracteres`;
    }
    if (field.hasError('pattern')) {
      return name === 'email' ? `El correo electrónico no es valido` : 'El número de teléfono no es valido';
    }
    return '';
  }
  
  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

}