import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'key',
  pure: false,
})
export class KeyPipe implements PipeTransform {
  transform(value: unknown, args: unknown[] = null): unknown {
    return Object.keys(value);
  }
}
