<app-modal-layout (onClose)="close()">
    <ng-container title>
        Partida del Gasto
    </ng-container>
    <ng-container body>
        <form [formGroup]="form">
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Cuenta Presupuestaria<span class="text-danger"> *</span></label>
                    <input formControlName="ccp" type="text"
                        class="form-control form-control-solid form-control-lg">
                    <span class="text-danger"></span>
                </div>
                <div class="form-group col-md-6">
                    <label>Total de Ingreso<span class="text-danger"> *</span></label>
                    <input formControlName="totalIngresos" type="text" currencyMask
                    [options]="{precision:2}"
                        class="form-control form-control-solid form-control-lg">
                    <span class="text-danger"></span>
                </div>
                <div class="form-group col-md-6">
                    <label>Total de Descuento<span class="text-danger"> *</span></label>
                    <input formControlName="totalDescuentos" type="text" currencyMask
                    [options]="{precision:2}"
                        class="form-control form-control-solid form-control-lg">
                    <span class="text-danger"></span>
                </div>
                <div class="form-group col-md-6">
                    <label>Neto Primera Quincena<span class="text-danger"> *</span></label>
                    <input formControlName="netoPrimeraQuincena" type="text" currencyMask
                    [options]="{precision:2}"
                        class="form-control form-control-solid form-control-lg">
                    <span class="text-danger"></span>
                </div>
                <div class="form-group col-md-6">
                    <label>Neto Segunda Quincena<span class="text-danger"> *</span></label>
                    <input formControlName="netoSegundaQuincena" type="text" currencyMask
                    [options]="{precision:2}"
                        class="form-control form-control-solid form-control-lg">
                    <span class="text-danger"></span>
                </div>
                <div class="form-group col-md-6">
                    <label>Patrón<span class="text-danger"> *</span></label>
                    <input formControlName="patron" type="text" currencyMask
                    [options]="{precision:2}"
                        class="form-control form-control-solid form-control-lg">
                    <span class="text-danger"></span>
                </div>
                <div class="form-group col-md-6">
                    <label>Riesgo<span class="text-danger"> *</span></label>
                    <input formControlName="riesgo" type="text" currencyMask
                    [options]="{precision:2}"
                        class="form-control form-control-solid form-control-lg">
                    <span class="text-danger"></span>
                </div>
                <div class="form-group col-md-6">
                    <label>SFS<span class="text-danger"> *</span></label>
                    <input formControlName="sfs" type="text" currencyMask
                    [options]="{precision:2}"
                        class="form-control form-control-solid form-control-lg">
                    <span class="text-danger"></span>
                </div>
                <div class="form-group col-md-6">
                    <label>Retiro Complementario<span class="text-danger"> *</span></label>
                    <input formControlName="retiroComplementario" type="text" currencyMask
                    [options]="{precision:2}"
                        class="form-control form-control-solid form-control-lg">
                    <span class="text-danger"></span>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container buttons>
        <button (click)="close()"
            class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2"
            type="button">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>


    </ng-container>
</app-modal-layout>