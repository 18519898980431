<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span> Aplicar Vacante</h2>
</div>


<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <div class="row d-flex justify-content-between">
            <div class="form-group col-lg-6">
                <h3 class="text-danger">Aplicando a la Vacante: <span class="text-danger">*</span></h3>
                <input [disabled]='IsviewMode == true' type="text" class="form-control form-control-lg"
                    [(ngModel)]="VacancyModel.positionName" readonly>
            </div>
            <div class="form-group col-lg-3">
                <h3 class="text-danger">Tipo de Requisición: <span class="text-danger">*</span></h3>
                <input [disabled]='IsviewMode == true' class="form-control form-control-lg" placeholder="Tipo de requisición"
                    type="text" required [(ngModel)]="VacancyModel.requisitionType" readonly />
            </div>
            <div class="form-group col-lg-3">
                <h3 class="text-danger">Fecha Aplicación: <span class="text-danger">*</span></h3>
                <input   [disabled]='IsviewMode == true' class="form-control form-control-lg" placeholder="Fecha de solicitud"
                    type="date" [(ngModel)]="VacancyModel.dateApplication" />
            </div>
        </div>
        <div class="my-7 separator separator-solid"></div>
        <div class="row">
            <div class="form-group col-md-6 col-xl-4">
                <label class="text-primary">Salario Deseado <span class="text-danger">*</span></label>
                <div class="input-group">
                    <input [disabled]='IsviewMode == true' (blur)="onSalaryError()" placeholder="0.00" type="text" currencyMask
                        [options]="{precision:2}" name="salary" class="form-control form-control-solid form-control-lg"
                        [(ngModel)]="VacancyModel.desiredSalary" #desiredSalary="ngModel">
                    <button (click)="openSalaryRange()" class="input-group-text bg-primary">
                        <i class="flaticon-ver icon-2x text-white" data-toggle="tooltip" title="Sugerencia salarial"></i>
                    </button>
                </div>
                <span *ngIf="isSalaryInvalid" class="font-weight-bold text-danger">El salario deseado debe estar dentro del rango salarial establecido</span>
            </div>
            <div class="form-group col-md-6 col-xl-4">
                <label class="text-primary">Fecha Disponible <span class="text-danger">*</span></label>
                <input [disabled]='IsviewMode == true' type="date" class="form-control form-control-solid form-control-lg"
                    placeholder="" [(ngModel)]="VacancyModel.startDate">
            </div>
            <div class="form-group col-md-6 col-xl-4">
                <label class="text-primary">Horario Disponible <span class="text-danger">*</span></label>
                <ngx-select-dropdown [disabled]='IsviewMode == true' [options]='hoursAvailable'
                    (change)='selecHours($event)' [(ngModel)]="hourSelected" [config]='config' class=" flex-1">
                </ngx-select-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="align-items-center col-md-6 d-flex flex-wrap form-group justify-content-between">
                <label class="d-block text-primary mb-0">¿Está laborando actualmente? <span class="text-danger">
                        *</span></label>
                <div class="yn">
                    <div class="form-check form-check-inline ml-5" *ngFor="let item of YesOrNot">
                        <label class="form-check-label mt-2 check-dtl">
                            <input [disabled]='IsviewMode == true' class="form-check-input" required="" name="employee"
                                type="radio" [(ngModel)]="VacancyModel.employee" [value]="item.value">
                            {{item.text}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="align-items-center col-md-6 d-flex flex-wrap form-group justify-content-between">
                <label class="d-block text-primary mb-0">¿Disponibilidad para traslado? <span
                        class="text-danger">*</span></label>
                <div class="yn">
                    <div class="form-check form-check-inline ml-5" *ngFor="let item of YesOrNot">
                        <label class="form-check-label mt-2 check-dtl">
                            <input [disabled]='IsviewMode == true' class="form-check-input" required="" name="transfer"
                                type="radio" [(ngModel)]="VacancyModel.transfer" [value]="item.value">
                            {{item.text}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="align-items-center col-md-6 d-flex flex-wrap form-group justify-content-between">
                <label class="d-block text-primary mb-0">¿Tiene disponibilidad para viajar? <span class="text-danger">
                        *</span></label>
                <div class="yn">
                    <div class="form-check form-check-inline ml-5" *ngFor="let item of YesOrNot">
                        <label class="form-check-label mt-2 check-dtl">
                            <input [disabled]='IsviewMode == true' class="form-check-input" required="" name="toTravel"
                                type="radio" [(ngModel)]="VacancyModel.toTravel" id="rdYes" [value]="item.value">
                            {{item.text}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="align-items-center col-md-6 d-flex flex-wrap form-group justify-content-between">
                <label class="d-block text-primary mb-0">¿Autorizo el uso público de mis datos? <span class="text-danger">
                        *</span></label>
                <div class="yn">
                    <div class="form-check form-check-inline ml-5" *ngFor="let item of YesOrNot">
                        <label class="form-check-label mt-2 check-dtl">
                            <input [disabled]='IsviewMode == true' class="form-check-input" required="" name="Authorize"
                                type="radio" [(ngModel)]="VacancyModel.authorizedData" id="rdYes" [value]="item.value">
                            {{item.text}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="d-flex flex-column form-group">
                <label>Otras condiciones particulares de empleabilidad</label>
                <textarea [disabled]='IsviewMode == true' placeholder="Describa todas las que considere necesarias"
                    class="form-control form-control-lg" [(ngModel)]="VacancyModel.comment"></textarea>
            </div>
        </div>
    </div>
</div>
<div class="mat-dialog-actions"[align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
            (click)="Cancel()">
            <i class="flaticon2-cross"></i>
            Cerrar
    </button>
    <button   *ngIf="!IsviewMode && VacancyModel && VacancyModel.vacantId > 0" (click)="ApplyVacancy()"
         class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
         >
        <i class="flaticon-disco-flexible"></i>
        <span>Aplicar</span>
    </button>
</div>