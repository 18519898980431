<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon">
                <i class="flaticon-proceso icon-3x text-danger"></i>
            </span>
            <h2 class="d-flex flex-column text-primary">
                Acción de Cambio a Servidor Público
            </h2>                
        </div>
        <div class="card-toolbar">
            <div class="flex-nowrap d-flex">
                <div class="form-group mr-3">
                    <label>Tipo de búsqueda<span class="text-danger">*</span></label>
                    <select class="form-control form-control-lg" >
                        <option value="">Seleccionar</option>
                        <option></option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Criterio de búsqueda<span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input type="text" class="form-control form-control-lg" placeholder="Escriba aquí su búsqueda">
                        <button class="input-group-text bg-primary" data-toggle="tooltip" title="Buscar">
                            <i class="flaticon2-search-1 icon-2x text-white"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">       
        <div class="row gutter-b">
            <div class="col-lg-2 d-flex align-items-center justify-content-center">
                <div class="symbol symbol-150 d250 mr-3">
                    <img alt="Pic" src="assets/images/300_20.jpg"/>
                </div>
            </div>
            <div class="col-lg-10 row">
                <div class="row col-lg-8 align-content-center">
                    <div class="col-lg-8 form-group">
                        <label>Servidor Público <span class="text-danger">*</span></label>
                        <input class="form-control form-control-solid form-control-lg">
                    </div>
                    <div class="col-lg-4 form-group">
                        <label>Fecha Efectiva <span class="text-danger">*</span></label>
                        <input class="form-control form-control-solid form-control-lg" readonly>
                    </div>
                    <div class="col-lg-12 form-group">
                        <label>Nombre</label>
                        <input class="form-control form-control-solid form-control-lg" readonly>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card p-3 border-primary bg-light">
                        <h3 class="text-center">SOLICITUD DE CAMBIO</h3>
                        <div class="form-group m-2">
                            <label>Fecha</label>
                            <input type="date" class="form-control form-control-solid form-control-lg">
                        </div>
                        <div class="form-group m-2">
                            <label>Cambio</label>
                            <input type="text" class="form-control form-control-solid form-control-lg">
                        </div>
                        <div class="form-group m-2">
                            <label>Motivo</label>
                            <input type="text" class="form-control form-control-solid form-control-lg">
                        </div>
                    </div>
                </div>        
            </div>            
        </div>
        <div class="row gutter-b">
            <div class="col-lg-3 form-group">
                <label>Parámetros <span class="text-danger">*</span></label>
                <ngx-select-dropdown class="flex-1"></ngx-select-dropdown>
            </div>
            <div class="col-lg-3 form-group">
                <label>Datos Actuales <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg">
            </div>
            <div class="col-lg-3 form-group">
                <label>Datos Nuevos <span class="text-danger">*</span></label>
                <input class="form-control form-control-lg">
            </div>
            <div class="col-lg-3 form-group">
                <label>Motivo</label>
                <ngx-select-dropdown class="flex-1"></ngx-select-dropdown>
            </div>
        </div>
        <div class="row gutter-b">
            <div class="table-responsive">
                <table class="table atbl table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-center"><input type="checkbox" name="" id=""></th>
                            <th class="text-center">Parámetros</th>
                            <th class="text-center">Datos Actuales</th>
                            <th class="text-center">Datos Nuevos</th>
                            <th class="text-center">Motivo</th>
                            <th class="text-center">Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center"><input type="checkbox" name="" id=""></td>
                            <td class="text-center">Unidad Organizativa</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center w-100px">
                                <button  type="button" data-toggle="modal" data-target="#approbed" class="btn btn-sm btn-light-success btn-icon mr-2">
                                    <i class="flaticon-checked" data-title="Aprobar" data-toggle="tooltip"></i>
                                </button>
                            </td>                            
                        </tr>
                        <tr>
                            <td class="text-center"><input type="checkbox" name="" id=""></td>
                            <td class="text-center">Unidad Organizativa</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center w-100px">
                                <button  type="button" data-toggle="modal" data-target="#approbed" class="btn btn-sm btn-light-success btn-icon mr-2">
                                    <i class="flaticon-checked" data-title="Aprobar" data-toggle="tooltip"></i>
                                </button>
                            </td>                            
                        </tr>
                        <tr>
                            <td class="text-center"><input type="checkbox" name="" id=""></td>
                            <td class="text-center">Unidad Organizativa</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center w-100px">
                                <button  type="button" data-toggle="modal" data-target="#approbed" class="btn btn-sm btn-light-success btn-icon mr-2">
                                    <i class="flaticon-checked" data-title="Aprobar" data-toggle="tooltip"></i>
                                </button>
                            </td>                            
                        </tr>
                        <tr>
                            <td class="text-center"><input type="checkbox" name="" id=""></td>
                            <td class="text-center">Unidad Organizativa</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center w-100px">
                                <button  type="button" data-toggle="modal" data-target="#approbed" class="btn btn-sm btn-light-success btn-icon mr-2">
                                    <i class="flaticon-checked" data-title="Aprobar" data-toggle="tooltip"></i>
                                </button>
                            </td>                            
                        </tr>
                        <tr>
                            <td class="text-center"><input type="checkbox" name="" id=""></td>
                            <td class="text-center">Unidad Organizativa</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center w-100px">
                                <button  type="button" data-toggle="modal" data-target="#approbed" class="btn btn-sm btn-light-success btn-icon mr-2">
                                    <i class="flaticon-checked" data-title="Aprobar" data-toggle="tooltip"></i>
                                </button>
                            </td>                            
                        </tr>
                        
                    </tbody>
                </table>        
            </div>
        </div>
        <div class="row">
            <div class="form-group d-flex justify-content-end align-items-center ">  
                <button class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
                    <i class="flaticon-disco-flexible"></i>
                    <span> Guardar </span>
                </button>          
                <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary d-flex mr-2">
                    <i class="flaticon-checked"></i> 
                    <span>Aprobar</span>  
                </button>
               
            </div>
        </div>
    </div>    
</div>