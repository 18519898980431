<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span
            class="h-20px label label-danger label-dot mr-3 w-20px"></span>Razón de Rechazo</h2>
    <button type="button" class="close" (click)="close()" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>

<div class="mat-dialog-content-custom">
    <div class="card-body-custom mt-3 pt-0">
        <textarea class="form-control form-control-lg" readonly [value]="data?.reasonReject || 'N/A'" cols="30" rows="10"></textarea>

    </div>
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
      (click)="close()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>