<app-modal-layout (onClose)="close()" styles="min-height: 200px">
    <ng-container title>
        {{title}}
    </ng-container>
    <ng-container body>


        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left">Identificación</th>
                                <th class="text-left">Categoría</th>
                                <th class="text-left">Fecha Efectiva</th>
                                <th class="text-left">Motivo de Salida</th>
                                <th class="text-left">Tipo de Salida</th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let item of paginatedDataList">
                                <td class="text-left">{{ item?.personalIdentification }}</td>
                                <td class="text-left">{{ item?.category }}</td>
                                <td class="text-left">{{ item?.effectiveDate}}</td>
                                <td class="text-left">{{ item?.exitReason }}</td>
                                <td class="text-left">{{ item?.exitType }}</td>

                            </tr>
                        </tbody>
                    </table>
                    <app-paginator [listedRecordsLength]="paginatedDataList?.length"
                      [registerQuantity]="paginatedDataListOriginal.length"
                      [registerPerPage]="paginatedDataListPagedResut.pageSize"
                      [totalPages]="paginatedDataListPagedResut.totalPages"
                      [selectedPage]="paginatedDataListPagedResut.page" (onChange)="getPaginatedRecords($event)">
                    </app-paginator>
                </div>
            </div>
        </div>


    </ng-container>
<ng-container buttons>
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>

</ng-container>
</app-modal-layout>
