import { DropDownOption } from "../models/dropDownOption";

export class DropDownSettings {
    constructor() { }
}

export class NgxDropDownSettings extends DropDownSettings {
    public readonly multiple: boolean;
    public readonly displayKey: string;
    public readonly search: boolean;
    public readonly height: string;
    public readonly placeholder: string;
    public readonly customComparator: (left: DropDownOption<any>, right: DropDownOption<any>) => number;
    public readonly limitTo: number;
    public readonly moreText: string;
    public readonly noResultsFound: string;
    public readonly searchPlaceholder: string;
    public readonly searchOnKey: string;
    public readonly clearOnSelection: boolean;
    public readonly inputDirection: string;
    public readonly selectAllLabel: string;
    public readonly enableSelectAll: boolean;

    constructor(parameters: {
        multiple: boolean;
        displayKey: string;
        search: boolean;
        height: string;
        placeholder: string;
        customComparator: (left: DropDownOption<any>, right: DropDownOption<any>) => number;
        limitTo: number;
        moreText: string;
        noResultsFound: string;
        searchPlaceholder: string;
        searchOnKey: string;
        clearOnSelection: boolean;
        inputDirection: string;
        selectAllLabel: string;
        enableSelectAll: boolean;
    }) {
        super();
        this.multiple = parameters.multiple;
        this.displayKey = parameters.displayKey;
        this.search = parameters.search;
        this.height = parameters.height;
        this.placeholder = parameters.placeholder;
        this.customComparator = parameters.customComparator;
        this.limitTo = parameters.limitTo;
        this.moreText = parameters.moreText;
        this.noResultsFound = parameters.noResultsFound;
        this.searchPlaceholder = parameters.searchPlaceholder;
        this.searchOnKey = parameters.searchOnKey;
        this.clearOnSelection = parameters.clearOnSelection;
        this.inputDirection = parameters.inputDirection;
        this.selectAllLabel = parameters.selectAllLabel;
        this.enableSelectAll = parameters.enableSelectAll;
    }

    public With(parameters: {
        multiple?: boolean;
        displayKey?: string;
        search?: boolean;
        height?: string;
        placeholder?: string;
        customComparator?: (left: DropDownOption<any>, right: DropDownOption<any>) => number;
        limitTo?: number;
        moreText?: string;
        noResultsFound?: string;
        searchPlaceholder?: string;
        searchOnKey?: string;
        clearOnSelection?: boolean;
        inputDirection?: string;
        selectAllLabel?: string;
        enableSelectAll?: boolean;
    }): NgxDropDownSettings {
        return new NgxDropDownSettings({
            multiple: parameters.multiple ?? this.multiple,
            displayKey: parameters.displayKey ?? this.displayKey,
            search: parameters.search ?? this.search,
            height: parameters.height ?? this.height,
            placeholder: parameters.placeholder ?? this.placeholder,
            customComparator: parameters.customComparator ?? this.customComparator,
            limitTo: parameters.limitTo ?? this.limitTo,
            moreText: parameters.moreText ?? this.moreText,
            noResultsFound: parameters.noResultsFound ?? this.noResultsFound,
            searchPlaceholder: parameters.searchPlaceholder ?? this.searchPlaceholder,
            searchOnKey: parameters.searchOnKey ?? this.searchOnKey,
            clearOnSelection: parameters.clearOnSelection ?? this.clearOnSelection,
            inputDirection: parameters.inputDirection ?? this.inputDirection,
            selectAllLabel: parameters.selectAllLabel ?? this.selectAllLabel,
            enableSelectAll: parameters.enableSelectAll ?? this.enableSelectAll
        });
    }
}

export class NgDropDownSettings extends DropDownSettings {
    public readonly singleSelection: boolean;
    public readonly placeholder: string;
    public readonly disabled: boolean;
    public readonly data: any[];
    public readonly idField: string;
    public readonly textField: string;
    public readonly enableCheckAll: boolean;
    public readonly selectAllText: string;
    public readonly unSelectAllText: string;
    public readonly allowSearchFilter: boolean;
    public readonly searchPlaceholderText: string;
    public readonly clearSearchFilter: boolean;
    public readonly maxHeight: number;
    public readonly itemsShowLimit: number;
    public readonly limitSelection: number;
    public readonly noDataAvailablePlaceholderText: string;
    public readonly closeDropDownOnSelection: boolean;
    public readonly defaultOpen: boolean;
    public readonly allowRemoteDataSearch: boolean;

    constructor(parameters: {
        singleSelection: boolean;
        placeholder: string;
        disabled: boolean;
        data: any[];
        idField: string;
        textField: string;
        enableCheckAll: boolean;
        selectAllText: string;
        unSelectAllText: string;
        allowSearchFilter: boolean;
        searchPlaceholderText: string;
        clearSearchFilter: boolean;
        maxHeight: number;
        itemsShowLimit: number;
        limitSelection: number;
        noDataAvailablePlaceholderText: string;
        closeDropDownOnSelection: boolean;
        defaultOpen: boolean;
        allowRemoteDataSearch: boolean;
    }) {
        super();
        this.singleSelection = parameters.singleSelection;
        this.placeholder = parameters.placeholder;
        this.disabled = parameters.disabled;
        this.data = parameters.data;
        this.idField = parameters.idField;
        this.textField = parameters.textField;
        this.enableCheckAll = parameters.enableCheckAll;
        this.selectAllText = parameters.selectAllText;
        this.unSelectAllText = parameters.unSelectAllText;
        this.allowSearchFilter = parameters.allowSearchFilter;
        this.searchPlaceholderText = parameters.searchPlaceholderText;
        this.clearSearchFilter = parameters.clearSearchFilter;
        this.maxHeight = parameters.maxHeight;
        this.itemsShowLimit = parameters.itemsShowLimit;
        this.limitSelection = parameters.limitSelection;
        this.noDataAvailablePlaceholderText = parameters.noDataAvailablePlaceholderText;
        this.closeDropDownOnSelection = parameters.closeDropDownOnSelection;
        this.defaultOpen = parameters.defaultOpen;
        this.allowRemoteDataSearch = parameters.allowRemoteDataSearch;
    }

    public With(parameters: {
        singleSelection?: boolean;
        placeholder?: string;
        disabled?: boolean;
        data?: any[];
        idField?: string;
        textField?: string;
        enableCheckAll?: boolean;
        selectAllText?: string;
        unSelectAllText?: string;
        allowSearchFilter?: boolean;
        searchPlaceholderText?: string;
        clearSearchFilter?: boolean;
        maxHeight?: number;
        itemsShowLimit?: number;
        limitSelection?: number;
        noDataAvailablePlaceholderText?: string;
        closeDropDownOnSelection?: boolean;
        defaultOpen?: boolean;
        allowRemoteDataSearch?: boolean;
    }): NgDropDownSettings {
        return new NgDropDownSettings({
            singleSelection: parameters.singleSelection ?? this.singleSelection,
            placeholder: parameters.placeholder ?? this.placeholder,
            disabled: parameters.disabled ?? this.disabled,
            data: parameters.data ?? this.data,
            idField: parameters.idField ?? this.idField,
            textField: parameters.textField ?? this.textField,
            enableCheckAll: parameters.enableCheckAll ?? this.enableCheckAll,
            selectAllText: parameters.selectAllText ?? this.selectAllText,
            unSelectAllText: parameters.unSelectAllText ?? this.unSelectAllText,
            allowSearchFilter: parameters.allowSearchFilter ?? this.allowSearchFilter,
            searchPlaceholderText: parameters.searchPlaceholderText ?? this.searchPlaceholderText,
            clearSearchFilter: parameters.clearSearchFilter ?? this.clearSearchFilter,
            maxHeight: parameters.maxHeight ?? this.maxHeight,
            itemsShowLimit: parameters.itemsShowLimit ?? this.itemsShowLimit,
            limitSelection: parameters.limitSelection ?? this.limitSelection,
            noDataAvailablePlaceholderText: parameters.noDataAvailablePlaceholderText ?? this.noDataAvailablePlaceholderText,
            closeDropDownOnSelection: parameters.closeDropDownOnSelection ?? this.closeDropDownOnSelection,
            defaultOpen: parameters.defaultOpen ?? this.defaultOpen,
            allowRemoteDataSearch: parameters.allowRemoteDataSearch ?? this.allowRemoteDataSearch
        });
    }
}

export module CommonDropDownSettings {
    export module Ngx {
        export const Single = new NgxDropDownSettings({
            multiple: false,
            displayKey: 'text',
            search: true,
            height: 'auto',
            placeholder: 'Seleccionar',
            customComparator: (left: DropDownOption<any>, right: DropDownOption<any>) => {
                let leftText = left.text ?? '';
                let rigthText = right.text ?? '';

                return leftText.localeCompare(rigthText);
            },
            limitTo: 0,
            moreText: '...',
            noResultsFound: 'No hay elementos',
            searchPlaceholder: 'Buscar',
            searchOnKey: 'text',
            clearOnSelection: true,
            inputDirection: 'ltr',
            selectAllLabel: 'Seleccionar todo',
            enableSelectAll: true
        });

        export const Multiple = new NgxDropDownSettings({
            multiple: true,
            displayKey: 'text',
            search: true,
            height: 'auto',
            placeholder: 'Seleccionar',
            customComparator: (left: DropDownOption<any>, right: DropDownOption<any>) => {
                let leftText = left.text ?? '';
                let rigthText = right.text ?? '';

                return leftText.localeCompare(rigthText);
            },
            limitTo: 0,
            moreText: '...',
            noResultsFound: 'No hay elementos',
            searchPlaceholder: 'Buscar',
            searchOnKey: 'text',
            clearOnSelection: true,
            inputDirection: 'ltr',
            selectAllLabel: 'Seleccionar todo',
            enableSelectAll: true
        });
    }

    export module Ng {
        export const Single = new NgDropDownSettings({
            singleSelection: false,
            placeholder: 'Seleccionar',
            disabled: false,
            data: [],
            idField: 'id',
            textField: 'text',
            enableCheckAll: true,
            selectAllText: 'Seleccionar todo',
            unSelectAllText: 'Deseleccionar todo',
            allowSearchFilter: true,
            searchPlaceholderText: 'Buscar',
            clearSearchFilter: true,
            maxHeight: 197,
            itemsShowLimit: 2,
            limitSelection: -1,
            noDataAvailablePlaceholderText: 'No hay elementos',
            closeDropDownOnSelection: true,
            defaultOpen: false,
            allowRemoteDataSearch: false
        });

        export const SingleWithoutSearch = Single.With({ allowSearchFilter: false });

        export const Multiple = new NgDropDownSettings({
            singleSelection: false,
            placeholder: 'Seleccionar',
            disabled: false,
            data: [],
            idField: 'id',
            textField: 'text',
            enableCheckAll: true,
            selectAllText: 'Seleccionar todo',
            unSelectAllText: 'Deseleccionar todo',
            allowSearchFilter: true,
            searchPlaceholderText: 'Buscar',
            clearSearchFilter: true,
            maxHeight: 197,
            itemsShowLimit: 2,
            limitSelection: -1,
            noDataAvailablePlaceholderText: 'No hay elementos',
            closeDropDownOnSelection: false,
            defaultOpen: false,
            allowRemoteDataSearch: false
        });

        export const MultipleWithoutSearch = Multiple.With({ allowSearchFilter: false });
    }
}
