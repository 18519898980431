import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { InicidentsService } from 'src/app/services/inicidents.service';
import { ExportToExcelService } from 'src/app/shared/export-to-excel/export-to-excel.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { IncidentesDashboardComponent } from '../incidentes-dashboard/incidentes-dashboard.component';

declare const $: any;
@Component({
  selector: 'app-incidents-list',
  templateUrl: './incidents-list.component.html',
  styleUrls: ['./incidents-list.component.css']
})
export class IncidentsListComponent implements OnInit, AfterViewChecked {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'INCIDENTSED',
    addAction:'ADDINCIDENT',
    dashboardAction: 'DASHBOARDINCIDENT',
    evidenceAction: 'EVIDENCEINCIDENT',
    deleteAction: 'REMOVEINCIDENT', 
    exportAction: 'EXPORTINCIDENT',
    viewAction: 'VIEWINCIDENT'
  }

  incidents: any[] = []
  paginatedIncidents: any[] = []
  sweetAlrt = new SweetAlert()
  evidencePath: string = ''
  requisitions: any[] = [];
  dataList: any[] = [];
  companies: [] = [];
  selectedItems:any;
  incidentsOriginal: any[]
  filterText:string = '';
  statusOptions = [
    { item_id: 1, item_text: 'Activos'},
    { item_id: 2, item_text: 'Inactivos'},
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchList: any[] = []; searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "personalRequisitionId", displayName: "Número de secuencia" },
      { propkey: "departmentName", displayName: "Unidad organizativa" },
      { propkey: "typeEmployee", displayName: "Tipo Servidor Público" },
      { propkey: "employeeName", displayName: "Servidor Público" },
      { propkey: "typeIncident", displayName: "Tipo de incidente" },
      { propkey: "degreeIncident", displayName: "Grado del incidente" },
    ];
    getFilteredList(event) {
      this.filteredList = event;
      this.filteredList.records = this.filterTable(event.records)
    }

  constructor(private incidentService: InicidentsService, private _toastService: ToastService,
    private exclService: ExportToExcelService, private _cdRef: ChangeDetectorRef, 
    private srvDoc: docService, private dialog: MatDialog, public authInfo: AuthInfoService) { }

  ngOnInit(): void {

    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result=>{
      if(result == true){
        
        if(localStorage.getItem("incident-list_filter")){
          this.selectedItems = JSON.parse(localStorage.getItem("incident-list_filter"));
        }else{
          this.selectedItems = [{ item_id: 1, item_text: 'Activos'}];
        }
        this.getIncidents()
        // this.getData();
        
      }
    })

  }
  ngOnDestroy(): void {
    $('.tooltip.show').removeClass( "show" );
  }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  // getData() {

  //   this.incidentService.getEventType().subscribe((res: any) => {
  //     return res.dataList;
  //   });

  // }
  ApplySearch() { }

  getIncidents() {
    this.incidentService.getIncidents().subscribe((res: any) => {
      if(res.succeded){
        this.incidents = res.dataList
        this.incidentsOriginal = res.dataList
      }else{
        this._toastService.error(res.errors[0])
      }
    },
      err => this._toastService.error(`Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.`))
  }

  onDeSelectAll(){
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item:any){
    this.filter();
  }
  onItemSelect(item:any){
    this.filter();
  }
  onSelectAll(item:any){
    this.selectedItems = item;
    this.filter()
  }

  filter(){
    document.getElementById('searchBarBtn').click()
    if(this.filteredList.records.length > 0 && this.filterText){
      this.filteredList.records = this.filterTable(this.filteredList.records)
    }else{
      this.incidents = this.filterTable(this.incidentsOriginal)
    }
    this.setFilterToLocalStorage();
  }
  setFilterToLocalStorage(){
    localStorage.setItem('incident-list_filter', JSON.stringify(this.selectedItems));
  }

  filterTable(list:any[]){
    let sl = this.selectedItems;
    if(sl.find(x => x.item_id == 1) && !sl.find(x => x.item_id == 2)){
      list = list.filter(x => x.status == true);
    }
    if(sl.find(x => x.item_id == 2) && !sl.find(x => x.item_id == 1)){
      list = list.filter(x => x.status == false);
    }
    return list;
  }
  getGuID(guid) {
    try {
      this.srvDoc.getDocument(guid).subscribe(e => {
        this.evidencePath = e.data
        window.open(this.evidencePath, '_blank');
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  deleteIncident(idIncident: number) {
    this.sweetAlrt.AlertConfirm("Eliminar registro", "¿Está seguro de eliminar este registro?", "question").then(res => {
      if (res) {
        this.incidentService.delete(idIncident).subscribe(res => {
          this.sweetAlrt.Default("Eliminado", "Registro eliminado satisfactoriamente", "success")
          this.getIncidents()
        },
          err => this._toastService.error(err))
      } else {

      }
    })
  }

  getPaginatedRecords(event) {
    this.paginatedIncidents = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  openDashBoard(): void {
    this.dialog.open(IncidentesDashboardComponent, {
    })
      .afterClosed()
      .subscribe((result) => {
      });
  }

  exportToExcell() {
    this.exclService.exportToExcel(this.paginatedIncidents, 'Lista de incidentes')
  }

}
