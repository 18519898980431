
<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>Formulas</h2>
        <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    </div>
    <div class="mat-dialog-content-custom">
        <div class="card-body-custom">
            <div class="row">
                <div class="col-md-12 col-lg-6 col-xl-3 pb-5">
                    <div class="d-flex text-primary border border-primary rounded" style="margin-top: 2.1rem; padding: .6rem;">
                        <label class="h6 mt-1">¿Formula Habilitada?</label>
                        <div class="d-flex">
                            <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                            <div class="flex-shrink-0 mx-2">
                                <span class="switch switch-sm switch-primary">
                                    <label>
                                        <input class="cursor-pointer abc-checkbox-primary" formControlName="selected" type="checkbox" />
                                        <span></span>
                                    </label>
                                </span>
                            </div>
                            <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
                        </div>      
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-3">
                    <div class="form-group">
                        <label>Fecha Inicio</label>
                        <input type="date" class="form-control form-control-solid form-control-lg">
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-3">
                    <div class="form-group">
                        <label>Fecha Fin</label>
                        <input type="date" class="form-control form-control-solid form-control-lg">
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-3">
                    <div class="form-group">
                        <label>Orden de Ejecución</label>
                        <input type="text" class="form-control form-control-solid form-control-lg">
                    </div>
                </div>
                <div class="col-md-12 col-lg-8 ">
                    <div class="border border-primary rounded">
                        <div class="bg-primary">
                            <div class=" clard-title align-items-center ">
                                <h6 class=" p-2 text-white m-0">Definición</h6>
                            </div>
                        </div>
                        <div class="card-body">
                            <ul class="nav nav-tabs border-tabs-primary" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="cantidad-tab" data-toggle="tab" href="#cantidad" aria-controls="cantidad">
                                 <!--        <span class="nav-icon mr-1">
                                            <i class="flaticon-departamento icon-2x"></i>
                                        </span> -->
                                        <span class="nav-text" style="margin: 0px !important;">
                                            <h6>Cantidad</h6>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="objeto-nomina-tab" data-toggle="tab" href="#objeto-nomina" aria-controls="objeto-nomina">
                                   <!--      <span class="nav-icon mr-1">
                                            <i class="flaticon-departamento icon-2x"></i>
                                        </span> -->
                                        <span class="nav-text">
                                            <h6>Objeto de Nómina</h6>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="condicion-ejec-tab" data-toggle="tab" href="#condicion-ejec" aria-controls="condicion-ejec">
                                       <!--  <span class="nav-icon mr-1">
                                            <i class="flaticon-departamento icon-2x"></i>
                                        </span> -->
                                        <span class="nav-text">
                                            <h6>Condición de Ejecución</h6>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="calc-valor-tab" data-toggle="tab" href="#calc-valor" aria-controls="calc-valor">
                                     <!--    <span class="nav-icon mr-1">
                                            <i class="flaticon-departamento icon-2x"></i>
                                        </span> -->
                                        <span class="nav-text">
                                            <h6>Cálculo de Valor</h6>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="tope-tab" data-toggle="tab" href="#tope" aria-controls="tope">
                                    <!--     <span class="nav-icon mr-1">
                                            <i class="flaticon-departamento icon-2x"></i>
                                        </span> -->
                                        <span class="nav-text">
                                            <h6>Tope</h6>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="Beneficiario-tab" data-toggle="tab" href="#Beneficiario" aria-controls="Beneficiario">
                                   <!--      <span class="nav-icon mr-1">
                                            <i class="flaticon-departamento icon-2x"></i>
                                        </span> -->
                                        <span class="nav-text">
                                            <h6>Beneficiario</h6>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content mt-5">
                                <div class="tab-pane fade active show" id="cantidad" role="tabpanel" aria-labelledby="cantidad-tab">
                                    <div class="row">
                                        <div class="col-sm-12 col-lg-5">
                                            <div class="form-group">
                                                <label class="text-primary">
                                                    Tipo
                                                </label>
                                              <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="dropdownSettings"
                                                    [data]="statusOptions" [(ngModel)]="selectedItems">
                                                </ng-multiselect-dropdown> 
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="card-body border rounded" style="height:350px ;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade " id="objeto-nomina" role="tabpanel" aria-labelledby="objeto-nomina-tab">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                              <div class="card-body border rounded" style="height:200px ;">
                                            </div>
                                        </div>
                                        </div>
                                        <div class="col-sm-12">
                                                <div class="border border-primary rounded">
                                                    <div class="bg-primary">
                                                        <div class=" clard-title align-items-center ">
                                                            <h6 class=" p-2 text-white m-0">Eventos</h6>
                                                        </div>
                                                    </div>
                                                    <div class="card-body">
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade " id="condicion-ejec" role="tabpanel" aria-labelledby="condicion-ejec-tab">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <div class="card-body border rounded" style="height:350px ;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade " id="calc-valor" role="tabpanel" aria-labelledby="calc-valor-tab">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <div class="card-body border rounded" style="height:350px ;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade " id="tope" role="tabpanel" aria-labelledby="tope-tab">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <div class="card-body border rounded" style="height:350px ;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade " id="Beneficiario" role="tabpanel" aria-labelledby="Beneficiario-tab">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <div class="card-body border rounded" style="height:350px ;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-4">
                    <div class="border border-primary rounded">
                        <div class="bg-primary">
                            <div class=" clard-title align-items-center ">
                                <h6 class=" p-2 text-white m-0">Variables</h6>
                            </div>
                        </div>
                        <div class="card-body">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mat-dialog-actions"[align]="'end'">
        <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
        <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
            <i class="flaticon-disco-flexible"></i>
            <span>Guardar</span>
        </button>
    </div>