import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BulkLoadFinal } from '@payroll/final-payroll/models/models';
import { ToastService } from 'src/app/shared/toast/toast.service';
import * as XLSX from 'xlsx'
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PayrollConceptsBulk } from './models';
import { PayrollConceptService } from 'src/app/services/payroll-concept.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';

@Component({
  selector: 'app-bulk-load-payroll-concept',
  templateUrl: './bulk-load-payroll-concept.component.html',
  styleUrls: ['./bulk-load-payroll-concept.component.css']
})
export class BulkLoadPayrollConceptComponent implements OnInit {
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  paginatedDataUploaded = []
  validFileExtensions: string[] = ['.xls', '.xlsx'];
  dataUploaded = []

  statusOptions = [
    { item_id: 1, item_text: 'Listo para procesar' },
    { item_id: 2, item_text: 'Errores' },
  ];
  selectedItems: any;
  form: FormGroup;
  dataUploadedListOriginal = []
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  filterText: string = '';
  @ViewChild('paginator') paginator: CaptionComponent;
  constructor(
    public dialogRef: MatDialogRef<BulkLoadPayrollConceptComponent>,
    private _toastService: ToastService,
    private fb: FormBuilder,
    private authInfo: AuthInfoService,
    private srvConcepts: PayrollConceptService,
  ) {
    this.dialogRef.disableClose = true;
    this.form = this.fb.group({
      file: ['']
    })
  }

  ngOnInit(): void {
  }

  onClick() {
    this.form.reset();
  }
  fileUpload(event: any) {
    if (!this.validateFileExtension(event.target.files[0].name)) {
      this._toastService.error('El tipo de archivo cargado no es válido', 'Tipo de Archivo Inválido');
      this.onClick();
      return;
    }
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = (e: any) => {
      let binaryData = e.target.result;
      let workbook = XLSX.read(binaryData, { type: 'binary' });
      let bulk: PayrollConceptsBulk[] = [];
      const data = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
      bulk = this.buildModel(data);
      this.getBulk(bulk);
    }
  }


  private getBulk(bulk: PayrollConceptsBulk[]){
    this.srvConcepts.getBulk(bulk).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.dataUploaded = res.dataList;
      this.dataUploadedListOriginal = res.dataList;
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.");
    });
  }
  private buildModel(data: any){
    return data.map((d: any) => {
      return {
        companyId: this.authInfo.getCompanyId(),
        status: true,
        concept: d.Concepto,
        conceptType: d['Tipo de Concepto'],
        newsType: d['Tipo de Novedad'],
        code: d['Código']?.toString(),
        executionOrder: d['Orden de Ejecución'],
        beneficiaryIdentificationNumber: d['Identificación Beneficiario'].toString()?.replaceAll('-','')?.trim(),
        beneficiaryIdentificationType: d['Tipo Identificación Beneficiario'],
        beneficiaryName: d['Nombre Beneficiario'],
        createUserId: this.authInfo.getUserId(),
        conceptTypeId: 0,
        conceptBeneficiaryId: 0,
        beneficiaryIdentificationTypeId: 0,
        newsTypeId: 0
      }
    }) as PayrollConceptsBulk[];
  }
  get isValidData() {
    const isValid = this.dataUploadedListOriginal.every(d => !d.details);
    return isValid;
  }

  Accept() {
    if (this.dataUploadedListOriginal.length === 0) {
      this._toastService.warning('Por favor debe cargar un archivo previamente', 'Archivo no cargado');
      return;
    }
    if(!this.isValidData){
      return;
    }
    this.postBulk();
  }

  

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl?.find(x => x?.item_id === 1) && !sl?.find(x => x?.item_id === 2)) {
      list = [...list?.filter(x => !x?.details)];
    }
    if (sl?.find(x => x?.item_id === 2) && !sl?.find(x => x?.item_id === 1)) {
      list = [...list?.filter(x => x?.details)];
    }
    return list;
  }
  private postBulk(){
    this.srvConcepts.postBulk(this.dataUploadedListOriginal).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error('Ocurrió un error al procesar los datos, por favor verifique los datos enviados', '');
        return;
      }
      this._toastService.success('Los datos se procesaron correctamente');
      const row = { Applied: true }
      this.dialogRef.close(row);
    }, error => {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.");
    });
  }

  filter() {
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.dataUploaded = this.filterTable([...this.dataUploadedListOriginal])
    }
    this.paginator.selectedPage = 1;
  }
  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }
  private validateFileExtension(fileName: string) {
    const fileArray = fileName.split('.');
    const extension = fileArray[fileArray.length - 1];
    return this.validFileExtensions.some(v => v.includes(extension));
  }
  
  remove(i: any){
    this.dataUploadedListOriginal.splice(i, 1)
    this.dataUploaded = this.dataUploadedListOriginal;
    this.filter();
  }
  close(){
    this.dialogRef.close({success: false})
  }

  getPaginatedRecords(event) {
    this.paginatedDataUploaded = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
}
