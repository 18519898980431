import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaymentFlyerComponent } from '../payment-flyer.component';
import { PayrollFlyerService } from 'src/app/services/payroll-flyer.service';
import { docService } from 'src/app/services/doc.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ToastrService } from 'ngx-toastr';
import { PayrollFlyer } from '../models/payroll-flyer.model';
import { EmployeeDebitCredit, PayrollVoucher } from '../models/payroll-voucher.model';

type GUID = string & { isGuid: true };
@Component({
  selector: 'app-payment-voucher-version2',
  templateUrl: './payment-voucher-version2.component.html',
  styleUrls: ['./payment-voucher-version2.component.css']
})
export class PaymentVoucherVersion2Component implements OnInit {

  employeeDebitCredit: any[] = [];
  newsApplied: any[] = [];
  paymentDetails: any[]=[];
  compensations: any[]=[];
  selectedFlyer!: PayrollFlyer;

  payrollVoucherData!: PayrollVoucher;

  company!: any;
  file = { logo: '', image1: '', image2: '' }

  profilePhotoUrl!: string;

  today!: Date;

  constructor(
    public dialogRef: MatDialogRef<PaymentFlyerComponent>,
    private _payrollFlyerService: PayrollFlyerService,
    private srvDoc: docService,
    private _companiesService: CompaniesService,
    private authInfo: AuthInfoService,
    private _toastService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.today = new Date();

  }

  ngOnInit(): void {

    this.selectedFlyer = this.data.model;
    this.payrollVoucherData = this.data.voucher;

    this.employeeDebitCredit = this.payrollVoucherData.employeeDebitCredit.
      filter((e: EmployeeDebitCredit) => e.debitCreditId !== 4 && e.debitCreditId !== 5);

    this.newsApplied = this.payrollVoucherData.newsApplied;
    this.paymentDetails = this.payrollVoucherData.paymentDetails;
    this.compensations = this.payrollVoucherData.compensationsAndBenefits;
    
    this.getProfilePhoto();
    this.companyById(this.authInfo.getCompanyId())
  }


  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  getProfilePhoto() {
    this.srvDoc.getDocument(this.payrollVoucherData.employee.profileImage).subscribe(e => {
      if (e.data) {
        this.profilePhotoUrl = e.data
      }

    }, error => {

    })
  }


  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  close() {
    this.dialogRef.close();
  }
}
