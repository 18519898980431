import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollReportService } from 'src/app/services/payroll-report.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { openReport } from 'src/app/shared/utils/utility';
import { Period } from '@payroll/schedule/models/period.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-audit-excel',
  templateUrl: './audit-excel.component.html',
  styleUrls: ['./audit-excel.component.css']
})
export class AuditExcelComponent implements OnInit {

  reportForm: FormGroup;

  yearsList: any[] = []
  periods: Period[] = [];
  filteredPeriods: Period[] = [];
  periodSelected: Period;
  payrollVersions: any[] = [];
  budgetAreaList: any[] = [];
  relatedPeriods: any[] = [];
  payrollTypes: any[] = [];
  payrollTypesSelected: any;

  areaSelected: any;
  versionSelected: any;
  periodRelatedSelected: any;
  reportUrl = environment.reportUrl

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PAYROLL-REPORT',
    exportAction: 'EXPGEN',
    searchAction: 'SEARGEN'
  }


  constructor(
    private location: Location,
    private payrollService: PayrollService,
    private _toastService: ToastService,
    public authInfo: AuthInfoService,
    private fb: FormBuilder,
    private payrollReportService: PayrollReportService,
    private parameterServices: ParameterControlService,
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.getPayrollTypes();
        this.getPeriods();
      }
    });
  }

  goBack() {
    this.location.back();
  }

  buildForm() {
    this.reportForm = this.fb.group({
      payrollType: [null, [Validators.required]],
      period: [null, [Validators.required]],
      relatedPeriod: [null],
      area: [null, [Validators.required]],
      version: [null, [Validators.required]]
    })
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  dropdownSettings = {
    singleSelection: false,
    idField: 'employeeTypeId',
    textField: 'description',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  getPeriods() {
    this.payrollService.getBudgetAreas().subscribe((res: any) => {
      this.budgetAreaList = res.dataList.filter(ba => ba.status == true)

    })

    this.payrollReportService.getPeriods().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error("Ha ocurrido un error al obtener los períodos");
        return;
      }
      this.periods = res.dataList.filter(e => e.status);
      this.filteredPeriods = this.periods
      let periodYears: any[] = []
      let years = this.periods.map((period: any) => {
        let periodYear = new Date(`${period.startDate}`).getFullYear()
        if (!periodYears.includes(periodYear)) {
          periodYears.push(periodYear)
        }
        return (periodYears)
      })

      this.yearsList = years[0].reverse()
    }, err => this._toastService.error(err))
  }


  changeYear(event) {
    this.filteredPeriods = this.periods.filter((period: any) => period.description.includes(`${event.value}`))
  }


  getPayrollTypes() {
    this.parameterServices.getParameters(keyWord.PayrollType).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.payrollTypes = res.dataList;
    }, error => {
      this._toastService.error('Ocurrió un error inesperado', '');
    });
  }

  getPayrollVersions() {
    const formValue = this.reportForm?.value;
    const payrollPeriodId = formValue?.period?.payrollPeriodId || 0;
    const payrollTypeId = formValue?.payrollType?.ocode;
    const budgetAreaId = formValue?.area?.budgetAreaId || 0;
    this.payrollVersions = [];
    this.reportForm.get('version').setValue(null);
    if (payrollPeriodId === 0 || budgetAreaId === 0 || (payrollTypeId === null || payrollTypeId === undefined)) {
      return;
    }
    this.payrollService.getPayrollVersions(payrollPeriodId, payrollTypeId, budgetAreaId).subscribe((res: any) => {
      if (res?.errors?.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.payrollVersions = res?.dataList?.map(v => {
        return {
          ...v,
          versionDescription: `${v?.headerId} - ${v?.versionId} - ${v?.headerCondition}`
        }
      });
    }, error => {

    })
  }

  changePayrollType(payrollType: ParameterControl) {
    if (payrollType?.ocode === 4) {
      this.addValidation('relatedPeriod', [Validators.required]);
      this.getBeforePeriod();
      return;
    }
    this.relatedPeriods = [];
    this.addValidation('relatedPeriod', null);
    this.getPayrollVersions();
  }

  private addValidation(controlName: string, validators) {
    this.reportForm.get(controlName).setValidators(validators);
    this.reportForm.get(controlName).updateValueAndValidity();
  }

  getBeforePeriod() {
    this.payrollService.getBeforePeriod().subscribe(res => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.relatedPeriods = res.dataList;
      this.getPayrollVersions();
    });
  }

  get getPayrollTypeSeleted() {
    return this.reportForm.get('payrollType')?.value;
  }

  openReport() {

    if (this.reportForm.invalid) {
      this._toastService.warning('Por favor completar todos los campos requeridos', 'Campos incompletos')
      return
    }

    this.payrollTypesSelected = { ... this.reportForm.get('payrollType')?.value };
    this.periodSelected = { ...this.reportForm.get('period')?.value };
    this.areaSelected = { ...this.reportForm.get('area')?.value };
    this.versionSelected = { ...this.reportForm.get('version')?.value };
    this.periodRelatedSelected = { ...this.reportForm.get('relatedPeriod')?.value };

    let payrollPeriodId: number = this.periodSelected.payrollPeriodId;
    let payrollTypeId: number = this.payrollTypesSelected?.ocode;
    let areaId: number = this.areaSelected?.budgetAreaId;
    let versionId: number = this.versionSelected?.versionId
    let periodRelated: number = this.periodRelatedSelected?.payrollPeriodId || 0;

    const reportUrl = `${this.reportUrl}/?ReportCode=payroll_audit_excel&CompanyId=${this.authInfo.getCompanyId()}
    &PeriodId=${payrollPeriodId}&PayrollTypeId=${payrollTypeId}&VersionId=${versionId}&BudgetAreaId=${areaId}&PayrollPeriodIdRelated=${periodRelated}`
    let parameters = {
      url: reportUrl,
      title: 'Reporte de Auditoria Excel de Nómina',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }


}
