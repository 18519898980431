import { Component, Inject, OnInit, ChangeDetectorRef, AfterContentInit, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PlanningRrhhService } from 'src/app/services/planning-rrhh.service';

@Component({
  selector: 'app-planning-details',
  templateUrl: './planning-details.component.html',
  styleUrls: ['./planning-details.component.css']
})
export class PlanningDetailsComponent implements OnInit {

  planningDetails: any[] = []
  planningDetailsRecords: any[] = []

  constructor(
    private _planningHrmService: PlanningRrhhService,
    private _cdRef: ChangeDetectorRef,
    private _toastrService: ToastrService,
    private authInfo: AuthInfoService,
    public dialogRef: MatDialogRef<PlanningDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true
  }

  ngOnInit(): void {
    this.getData()
  }


  getData() {
    this._planningHrmService.getPlanningDetails(this.data.headerId).subscribe({
      next: (res: any) => {
        this.planningDetails = res.dataList
      },
      error: (err: any) => {
        this._toastrService.error('Ocurrio un error tratando de cargar el detalle')
      }
    })
  }

  closeModal() {
    this.dialogRef.close()
  }

  getPaginatedRecords(event) {
    this.planningDetailsRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

}
