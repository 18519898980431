import { DatePipe, formatDate } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { ToastrService } from 'ngx-toastr';
import { IEmployee } from 'src/app/models/Employee.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { FinalPayrollService } from 'src/app/services/final-payroll.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { environment } from 'src/environments/environment';
import { IEmployeeGET, INewsPOST, INewsTypeGET } from '../models/models';

type GUID = string & { isGuid: true };
@Component({
  selector: 'app-final-payroll-addnews-modal',
  templateUrl: './final-payroll-addnews-modal.component.html',
  styleUrls: ['./final-payroll-addnews-modal.component.css']
})
export class FinalPayrollAddnewsModalComponent implements OnInit, AfterViewChecked {
  modalTitle = 'Agregar novedad'
  newsType: INewsTypeGET;
  errors = {
    concept: false,
    amount: false,
    evidence: false
  }
  newId = 0
  formIsInvalid = false;
  private readonly urlDoc = environment.api_doc + '/Document';
  private readonly SystemLoad: number = 1;
  private readonly ModuleLoad: number = 2;
  private routeFile: string = "";
  employeeImg = ''
  evidenceImg = ''
  employeeSelected: IEmployeeGET = {
    email: '',
    name: '',
    phoneNumber: '',
    academicLevel: '',
    personalIdentification: '',
    typeIdentificationId: 0,
    employeeSupervisor: ''
  }
  file: any = {
    evidence: ''
  }
  
  personalFile = personalFile;
  
  concepts: any = [];
  
  evidence = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['application/pdf']
    },
    locale:Mexico
  });
  form: FormGroup
  evidenceGuid: string = '';
  datePipe = new DatePipe('en-Us');
  constructor(
    private srvDoc: docService,
    private _toastService: ToastrService,
    private dialogRef: MatDialogRef<FinalPayrollAddnewsModalComponent>,
    private sanitizer: DomSanitizer,
    private _cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { startDate:any, endDate: any, modalTitle: string, mode: 'show' | 'add' | 'edit', employeeId: number, newId: number},
    private formBuilder: FormBuilder,
    private srvFinal: FinalPayrollService,
    private parameterControlService: ParameterControlService,
    private auth: AuthInfoService
  ) { }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges()
  }

  ngOnInit(): void {
    const {startDate, endDate} = this.data
    this.form = this.buildForm()
    const form = this.form
    this.getEmployeeByNumber(this.data.employeeId)
    if(this.data.modalTitle){
      this.modalTitle = this.data.modalTitle
    }
    if(this.data.mode == 'show'){
      form.disable()
    }
    if(this.data.mode == 'edit'){
      this.getNewsById(this.data.newId)
    }
    this.getParameterControl()
    this.getNewsType()
    this.getConcepts()
    form.patchValue({
      startDate: formatDate(startDate, 'yyyy-MM-dd', 'en'),
      endDate: endDate ? formatDate(endDate, 'yyyy-MM-dd', 'en') : null
    })
    form.get('newsType').disable()
    
  }

  getNewsById(id){
    this.srvFinal.getPayrollNewById(id).subscribe((res:any) => {
      if(res.succeded){
        const news = res.dataList[0]
        const evidence = news.evidence
        this.evidenceGuid = news.evidence
        const concept = this.concepts.find(x => x.newsConceptId == news.newsConceptId)
        if(concept){
          this.form.get('conceptId').patchValue(news.newsConceptId)
        }
        this.getEvidence(evidence)
        this.form.patchValue({
          startDate: formatDate(news.startDate, 'yyyy-MM-dd', 'en'),
          endDate: news.endDate ? formatDate(news.endDate, 'yyyy-MM-dd', 'en') : null,
          concept: concept ?? null,
          amount: news.amount
        })
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }
  
  deleteGuid(name) {
    try {
      if (this.evidenceGuid) {
        this.srvDoc.deleteFile(this.evidenceGuid).subscribe(e => {
          this.evidence.cancelAll()
          this.evidenceImg = '';
          this.evidenceGuid = ''
        }, error => {
          this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
        })
      }
    } catch (error) {
      this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
    }
  }
  
  close(){
    this.dialogRef.close({success:false})
  }
  
  save(){
    if (this.form.get('endDate')?.value && this.form.get('endDate')?.value < this.form.get('startDate')?.value) {
      this._toastService.warning('La fecha final no debe ser menor que la fecha inicio');
      return;
    }
    this.formIsInvalid = this.form.invalid 
    if(this.formIsInvalid) return
    const form = this.form.value

    const newsType: INewsPOST = this.getNewsValues()
    if(this.data.mode == 'add'){
      this.post(newsType)
    }
    if(this.data.mode == 'edit'){
      this.put(newsType)
    }
    
  }
  
  post(newsType){
    this.srvFinal.postNewsFinalPayroll(newsType).subscribe((res:any) => {
      if(res?.warnings?.length > 0){
        this._toastService.warning(res?.warnings[0], '');
        return;
      }
      if (res?.errors?.length > 0) { this._toastService.error("Ha ocurrido un error al editar la novedad"); return; }
      if(res.succeded){
        this._toastService.success('Datos guardados')
        this.dialogRef.close({success: true})
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }
  put(newsType){
    this.srvFinal.putNewsFinalPayroll(newsType).subscribe((res:any) => {
      if(res?.warnings?.length > 0){
        this._toastService.warning(res?.warnings[0], '');
        return;
      }
      if (res?.errors?.length > 0) { this._toastService.error("Ha ocurrido un error al editar la novedad"); return; }
      if(res.succeded){
        this._toastService.success('Datos guardados')
        this.dialogRef.close({success: true})
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }
  
  getNewsValues(): INewsPOST{
    const form = this.form.value
    const news: INewsPOST = {
      amount: form.amount,
      newsConceptId: form.concept.newsConceptId,
      companyId: this.auth?.getCompanyId(),
      createDate: new Date(),
      createUserId: 1,
      employeeId: this.employeeSelected.employeeId,
      evidence: this.evidenceGuid ? this.evidenceGuid : null,
      newsTypeId: this.newsType.newsTypeId,
      payrollNewsId: this.data.mode == 'edit' ? this.data.newId : 0,
      positionId: this.employeeSelected.positionId,
      startDate: this.datePipe.transform(this.form?.value?.startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(this.form?.value?.endDate, 'yyyy-MM-dd'),
      status: true,
      sourceId: 4,
      condition: 0,
      countApproves: 0
    }
    return news;
  }
  
  buildForm(){
    return this.formBuilder.group({
      newsType: [''],
      concept: [''],
      conceptId: [0, Validators.min(1)],
      amount: [0, Validators.required],
      startDate: [''],
      endDate: ['']
    }) 
  }
  getNewsType(){
    this.srvFinal.getNewsType().subscribe((res:any) => {
      if(res.succeded){
        const field = this.form.get('newsType')
        this.newsType = res.dataList.find(x => x.newsTypeId == 1)
        field.patchValue(this.newsType.description)
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error("Ha ocurrido un error inesperado", '')
    })
  }
  getConcepts(){
    this.srvFinal.getNewsConcept().subscribe((res:any) => {
      if(res.succeded){
        this.concepts = res.dataList.filter(x => x.description.startsWith('400') && x.status)
        if(this.data.newId> 0){
          this.getNewsById(this.data.newId)
        }
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado', '')
    })
  }
  
  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }
  
  loadUppy() {
    this.evidence.use(Dashboard, {
      trigger: '.UppyModalOpenerBtn',
      inline: true,
      target: '.evidence',
      showProgressDetails: true,
      note: 'Solo PDF, 1 archivo, con un maximo de 1 MB',
      width: 320,
      height: 295,
      thumbnailWidth: 320,
      metaFields: [
        { id: 'name', name: 'Nombre', placeholder: 'Nombre del Archivo' },
        { id: 'caption', name: 'Caption', placeholder: 'Descripción del archivo' }
      ],
      browserBackButtonClose: false,
      showLinkToFileUploadResult: true,
    },
    )
      .use(XHRUpload, { endpoint: this.urlDoc + `?SystemLoad=${this.SystemLoad}&ModuleLoad=${this.ModuleLoad}&personalFile=${this.personalFile.photo2x2}&parameterDoc=${this.routeFile}` })
      .on('complete', (result) => {
        if (result.successful) {
          let resData = result.successful[0].response.body.data as FileResponse
          this.evidenceGuid = this.getGuid(resData.fileUnit.guidname)
          this.evidenceImg = resData.path;
        }
      });
  }
  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }
  
  getParameterControl() {
    this.parameterControlService.getParameters(parameterDoc.PathTest).subscribe(e => {
      if (e.dataList.length > 0) {
        this.routeFile = e.dataList[0].stringData.toString();
      }
      this.loadUppy();
    })
  }
  getEmployeeByNumber(employeeId: number){
    this.srvFinal.getEmployeeById(employeeId).subscribe((res: ResponseModel<IEmployeeGET>) => {
      if(res.succeded){
        this.employeeSelected = res.dataList[0]
        const img = this.employeeSelected.profileImage
        if(img){
          this.getDocument(img)
        }
      }else{
        
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }
  selectConcept(item){
    const concept = item.value
    if(Array.isArray(concept)){
      if(concept.length == 0){
        this.form.get('conceptId').patchValue(0)
      }
    }else{
      this.form.get('conceptId').patchValue(concept.newsConceptId)
    }

  }
  
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getDocument(guid: string){
    this.srvDoc.getDocument(guid).subscribe((res:any) => {
      this.employeeImg = res.data
    })
  }
  getEvidence(guid: string){
    this.srvDoc.getDocument(guid).subscribe((res:any) => {
      this.evidenceImg = res.data
      if(!res.data){
        this.evidence.reset()
        this.evidenceGuid = ''
        this.evidenceImg = ''
      }
    })
  }
  getErrors(name){
    const field = this.form.get(name)
    if(field.hasError('min')){
      return 'Este campo es requerido'
    }
    return ''
  }
}
