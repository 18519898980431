<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-aprobado icon-3x text-danger"></i>
                <h1 class="text-primary"> Banco de Candidatos a Vacantes </h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard">
            <a *ngIf="privilege.dashboardAction.value"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto"
                (click)="openDialog()">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
        </a>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-6 c-left">

        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="privilege.exportAction.value"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                            <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                            Visualizar Reporte
                        </a>
                        <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a> -->
                    </div>
                </div>

                <div class="col text-nowrap">
                    <!-- <el-search-bar #searchBar [records]="applicants" [itemForSearching]="searchBarDisplayNames"
                        (onKeyChange)="onKeyChange($event)" [keyValue]="filterText"
                        [defaultParameterValue]="searchBarDisplayNames[1]"
                        (onChange)="getFilteredList($event)"></el-search-bar> -->
                        <app-search-bar-pagination (onFilter)="filterSearch($event)"
                        [parameters]="searchBarDisplayNames"
                        [namePageLocalStorage]="searchparameterlocalstorage"
                        ></app-search-bar-pagination>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table mt-2 table-vertical-center text-center">
                <thead>
                    <tr class="bg-primary">
                        <th>Secuencia</th>
                        <th>Tipo de Identificación</th>
                        <th>Documento de Identidad</th>
                        <th class="text-left">Nombre</th>
                        <th class="text-left">Nivel Académico</th>
                        <th>Fecha Nacimiento</th>
                        <th>Sexo</th>
                        <th class="text-left">Nacionalidad</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let applicant of applicants">
                        <td>{{applicant.personId}}</td>
                        <td>{{applicant.type}}</td>
                        <td *ngIf="applicant.personalIdentification.length == 11">{{applicant.personalIdentification | mask:
                            '000-0000000-0'}}</td>
                        <td *ngIf="applicant.personalIdentification.length != 11">{{applicant.personalIdentification}}</td>
                        <td class="text-left">{{applicant.fullName}}</td>
                        <td class="text-left">{{applicant.academicLevel}}</td>
                        <td>{{applicant.birthday | date:'dd/MM/yyyy'}}</td>
                        <td>{{applicant.gender}}</td>
                        <td class="text-left">{{applicant.nationality |titlecase}}</td>
                        <td class="actionsBtn">
                            <a routerLink="/apply-vacancies/{{applicant.personalIdentification}}"
                                class="btn btn-sm btn-light-success btn-icon"
                                *ngIf="privilege.applyAction.value">
                                <i class="flaticon-etiqueta icon-2x" data-toggle="tooltip" title="Aplicar"></i>
                            </a>
                            <button class="btn btn-icon btn-light-info btn-sm m-1"
                                (click)="exportCandidateToPdf(applicant)"
                                *ngIf="privilege.viewAction.value">
                                <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <app-paginator [listedRecordsLength]="applicants?.length"
                    [registerQuantity]="applicantsPagedResult.totalItems" [registerPerPage]="applicantsPagedResult.pageSize"
                    [totalPages]="applicantsPagedResult.totalPages" [selectedPage]="applicantsPagedResult.page"
                    (onChange)="getPaginatedRecords($event)">
                </app-paginator>
            </div>
        </div>
    </div>
</div>

<export-data-to-pdf [title]="'Banco de Candidatos a Vacantes'" id="dataToPrint">
    <ng-container thead>
        <th>Tipo de Identificación</th>
        <th>Documento de Identidad</th>
        <th class="text-left">Nombre</th>
        <th class="text-left">Nivel Académico</th>
        <th>Fecha Nacimiento</th>
        <th>Sexo</th>
        <th class="text-left">Nacionalidad</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let applicant of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.applicants"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td>{{applicant.type}}</td>
            <td *ngIf="applicant.personalIdentification.length == 11">{{applicant.personalIdentification | mask:
                '000-0000000-0'}}</td>
            <td *ngIf="applicant.personalIdentification.length != 11">{{applicant.personalIdentification}}</td>
            <td class="text-left">{{applicant.fullName}}</td>
            <td class="text-left">{{applicant.academicLevel}}</td>
            <td>{{applicant.birthday | date:'dd MMMM yyyy':'UTC':'es-US'}}</td>
            <td>{{applicant.gender}}</td>
            <td class="text-left">{{applicant.nationality |titlecase}}</td>

        </tr>
    </ng-container>
</export-data-to-pdf>
