import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BudgetAreaService {
  api = environment.api_url
  constructor(
    private http: HttpClient, 
    private authInfo: AuthInfoService
  ) { }


  getBudgetAreas() {
    return this.http.get(this.api + `/BudgetArea?CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
}
