<angular2-multiselect [data]="dropDownItems" [(ngModel)]="selectedItems" [settings]="dropdownSettings" (onSelect)="onItemSelect()" (onDeSelect)="OnItemDeSelect()" (onSelectAll)="onSelectAll()" (onDeSelectAll)="onDeSelectAll()">
</angular2-multiselect>

<div class="mt-3 p-2 " [class]="selectedItems?.length ? 'scrollable' : ''" *ngIf="options.children.length > 0">
    <span class="text-center text-danger font-weight-bold font-size-h5" *ngIf="!selectedItems?.length">
    Debe seleccionar al menos una opción
  </span>

    <div *ngIf="selectedItems?.length" id="accordion">
        <div *ngFor="let item of selectedItems">
            <div id="header">
                <h5 class="mb-0">
                    <button class="btn btn-primary btn-block text-uppercase notification py-2 rounded-pill" data-toggle="collapse" [attr.data-target]="'#p' + item.id.toString()" aria-expanded="true" [attr.aria-controls]="'p' + item.id.toString()">
            {{ item.itemName }}
            <span class="badge badge-light">{{
              getOptionsSelectedQuantity(item.id)
            }}</span>
          </button>
                </h5>
            </div>

            <div [id]="'p' + item.id.toString()" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <ul class="list-group list-group-flush">
                    <li *ngFor="let child of options.children" class="list-group-item cursor-pointer">
                        <span class="label label-warning mr-3">
              <i [class]="childrenIcon"></i>
            </span> {{ child.name }}
                        <span class="float-right">
              <input
                *ngIf="!multiselect"
                [(ngModel)]="item.childrenIds[0]"
                type="radio"
                [value]="child.id"
                class="form-check-input"
                [name]="item.id"
                (change)="onChildrenSelect(item.id, child.id)"
              />

              <input
                *ngIf="multiselect"
                class="cursor-pointer abc-checkbox-primary"
                type="checkbox"
                [checked]="item.childrenIds.includes(child.id)"
                (change)="
                  onChildrenCLick($event.target.checked, item.id, child.id)
                "
              />
            </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>