import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { SkillsRequired } from '../models/personal-requisitions';

@Component({
  selector: 'app-skills-required',
  templateUrl: './skills-required.component.html',
  styleUrls: ['./skills-required.component.css']
})
export class SkillsRequiredComponent implements OnInit {
  skillLevels = [];
  skillForm: FormGroup = new FormGroup({
    skill: new FormControl('', Validators.required),
    level: new FormControl('', Validators.required)
  })
  skillsDTO = new SkillsRequired
  skills:SkillsRequired[] = []

  constructor(public dialogRef: MatDialogRef<SkillsRequiredComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public prService: PersonalRequsitionService) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  Accept(): void {
    const row = { Applied: true }
    this.skillsDTO.name = this.skillForm.value.skill
    this.skillsDTO.level = this.skillForm.value.level
    this.skills.push(this.skillsDTO)
  }

  Cancel(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

}
