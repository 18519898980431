import { Component, OnInit, Inject, AfterViewChecked, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { CauseRequisition, PersonalRequisitionsModel } from '../models/personal-requisitions';
import { SkillsRequiredComponent } from '../skills-required/skills-required.component';
import { ToastService } from '../../../shared/toast/toast.service';
import { DatePipe, formatDate } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ParameterControlService } from '../../../services/parameter-control.service';
import { keyWord } from '../../../shared/utils/parameterControl';
import { forkJoin, Subscription } from 'rxjs';
import { DropDownOptionModel } from '../../../shared/element-ui/dropdown/models/dropdown-option-model';
import { Replacement, Replacements } from '../models/replacement';
import { EmployeesService } from 'src/app/services/employees.service';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { PlanningRrhhService } from 'src/app/services/planning-rrhh.service';
import { PlanningHeader, PlanningRequest } from '../../Planning/models/planning';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { SelectDepartmentModalComponent } from '../select-department-modal/select-department-modal/select-department-modal.component';
import * as moment from 'moment';
import { InSubstitutionModalComponent } from './in-substitution-modal/in-substitution-modal/in-substitution-modal.component';
declare const $: any

@Component({
  selector: 'app-requisition',
  templateUrl: './requisition.component.html',
  styleUrls: ['./requisition.component.css']
})
export class RequisitionComponent implements OnInit, AfterViewChecked {

  @ViewChild('closeDeparmentButton') closeDepartmentModalButton: ElementRef;
  concursaParameter = []
  recruitmentMethodRequired = false
  form: any = {
    department: {
      departmentName: '',
      departmentId: 0
    },
    enclosure: '',
    position: '',
    requisitionType: '',
    vacancyCause: '',
    workingHours: '',
    academicLevel: '',
    concursaParameter: '',
    isPlanned: false,
    planning: null,
    planningRequest: null,
    contractAdmissionDate: null,
    contractEndingDate: null
  }
  ageError = ''
  FirstEntry: any = 0
  levels: any[] = []
  formIsInvalid = false;
  isSustitution: boolean = false
  childSelected: any = ""
  grandChildSelected: any = ""
  datePipe = new DatePipe('en-Us')
  selectedCareers: any[] = []
  requisitionForm: FormGroup;
  obs: Subscription;
  requisitionDTO = new PersonalRequisitionsModel();
  replacementsDTO = new Replacements();
  replacementDTO = new Replacement();
  sweetAlrt = new SweetAlert
  IsViewMode = false;
  academicLevels: any[] = []
  careers: any[] = []
  locations: any[] = []
  children: any[] = []
  grandChildren: any[] = []
  departments: any[] = []
  requisitions: any[] = []
  requisitionsCause: any[] = []
  requisitionsType: any[] = []
  vacants: any[] = []
  skillLevels: any[] = []
  workingHours: any[] = []
  genders: any[] = [];
  maxAgeError = ''
  sexo: any[] = [];
  positions: any[] = [];
  substitutionEmployees: any[] = []
  replacements: any[] = []
  replacementsList: any[] = []

  beginningAndEndingDateRequired = false

  config = {
    displayKey: 'displayName',
    placeholder: 'Seleccionar',
    search: true,
    searchPlaceholder: 'Buscar',
    searchOnKey: 'displayName'
  };
  experiences: ParameterControl[] = [];
  contractDateError = ''
  contractAdmissionDateError = ''
  experience: any[] = [];

  planning: PlanningHeader[] = [];
  planningRequestoriginalList: PlanningRequest[] = [];
  planningRequest: PlanningRequest[] = [];

  constructor(private dialog: MatDialog,
    private _toastService: ToastService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<RequisitionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public prService: PersonalRequsitionService,
    public employeeService: EmployeesService,
    private _ParamService: ParameterControlService,
    private _changeDet: ChangeDetectorRef,
    private _companiesService: CompaniesService,
    private authInfo: AuthInfoService,
    private planningRrhhService: PlanningRrhhService,
  ) {
    this.dialogRef.disableClose = true;
    // esto es para recibir el modelo pasado por parametro desde el grid de requisitions
    // si mandan null le pasa una nueva instancia del modelo
    if (data.item !== null) {
      this.IsViewMode = data.IsView;
      this.requisitionDTO.personalRequisitionId = data.item.personalRequisitionId > 0 ? data.item.personalRequisitionId : new PersonalRequisitionsModel();
      this.requisitionDTO.companyId = data.item.companyId > 0 ? data.item.companyId : new PersonalRequisitionsModel();
      this.requisitionDTO.enclosureId = data.item.enclosureId > 0 ? data.item.enclosureId : new PersonalRequisitionsModel();
      this.form.isPlanned = data?.item?.isPlanned;
      this.form.planningHeaderId = data?.item?.planningHeaderId;
      if (this.form.isPlanned == true) {
        this.getPlanningHeaderApproved();
        return;
      }
    } else {
      this.requisitionDTO = new PersonalRequisitionsModel();
      this.requisitionDTO.minimumAge = 18;
      this.requisitionDTO.numberPlaces = 1
      let today: Date = new Date();
      this.requisitionDTO.requestedDate = formatDate(today, 'yyyy-MM-dd', 'en-US', '-0400');
    }

  }

  contentVisible: boolean = true;

  ngOnInit(): void {
    if (!this.data.item) {
      this.getConcursaParameter();
    }
    this.FillDropDowns();
    this.GetRequisition();
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  InitializeScreen(item: PersonalRequisitionsModel = null) {
    this.requisitionForm = this.formBuilder.group({

    });
  }

  changePlanificate() {
    this.form.planningRequest = null;
    this.planning = [];
    if (this.form.isPlanned == true) {
      this.form.department = {
        departmentId: 0,
        departmentName: ''
      };
      this.form.position = null;
      this.positions = [];
      this.getPlanningHeaderApproved();
      return;
    }
  }

  changeRequisitionType(event) {
    const { ocode } = this.form.requisitionType
    this.beginningAndEndingDateRequired = (ocode === 3)

  }
  getPlanningHeaderApproved() {
    this.planningRrhhService.getPlanningHeaderApproved().subscribe({
      next: res => {
        if (!res?.succeded) {
          this._toastService.error(res?.errors[0]);
          return;
        }
        this.planning = res?.dataList;
        if (this.data.item?.personalRequisitionId && this.data?.item?.isPlanned) {
          this.form.planning = this.planning?.find(p => p?.planningHeaderId === this.data?.item?.planningHeaderId);
          this.changePlanningHeader(this.form.planning);
        }
      }, error: res => {
        this._toastService.error(`Ha ocurrido un error tratando de obtener la lista de planificaciones, Consulte al soporte técnico del sistema`, '');
      }
    })
  }

  getPlacesPositions(planningHeaderId: number) {
    this.planningRrhhService.getPlacesPositions(planningHeaderId).subscribe({
      next: res => {
        if (!res?.succeded) {
          return;
        }
        this.planningRequestoriginalList = res?.dataList;
        this.planningRequest = [...this.planningRequestoriginalList];
        if (this.data?.item?.personalRequisitionId && this.data?.item?.isPlanned) {
          this.form.planningRequest = this.planningRequestoriginalList?.find(p => p?.positionId === this.data?.item?.positionId);
        }
      }, error: res => {
        this._toastService.error(`Ha ocurrido un error tratando de obtener la lista de solicitudes de cargos y plazas, Consulte al soporte técnico del sistema`, '');
      }
    })
  }

  changePlanningHeader(planning: PlanningHeader) {
    this.form.planningRequest = null;
    if (planning?.planningHeaderId) {
      this.getPlacesPositions(planning?.planningHeaderId);
      return;
    }
  }

  changePlanningRequest(planningRequest: PlanningRequest) {
    this.beginningAndEndingDateRequired = false
    if (!planningRequest?.planningPlaceRequestId) {
      return;
    }
    this.form.position = {
      positionId: planningRequest.positionId,
      positionName: planningRequest.positionName
    }
  }

  getConcursaParameter() {
    this._ParamService.getParameters(keyWord.RequisitionMethod).subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          this._toastService.error(res.errors[0])
          return
        }
        this.concursaParameter = res.dataList
        this.form.concursaParameter = this.concursaParameter.find(x => x.ocode == this.requisitionDTO.requisitionMethodId)
      },
      error: (err: any) => {
        this._toastService.error('Ha ocurrido un error inesperado')
      }
    })
  }

  FillDropDowns() {
    this._ParamService.getParameters(keyWord.AcademicLevel).subscribe(data => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return;
      }
      if (data.succeded == true) {
        this.academicLevels = data.dataList;
        if (this.data?.item) {
          this.form.academicLevel = this.academicLevels.find(x => x.ocode == this.data?.item?.academicLevelId)
        }
      }
    });
    this._ParamService.getParameters(keyWord.Gender).subscribe(data => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return;
      }
      if (data.succeded == true) {
        this.genders = data.dataList;

        this.sexo = this.genders.sort((a, b) => a.ocode - b.ocode);

      }
    });

    this._ParamService.getParameters(keyWord.RequisitionType).subscribe(data => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return;
      }
      if (data.succeded == true) {
        this.requisitionsType = data.dataList;
        if (this.data?.item) {
          this.form.requisitionType = this.requisitionsType?.find(x => x.ocode == this.data?.item?.requisitionTypeId)
        }
      }
    });

    this._ParamService.getParameters(keyWord.CauseRequisition).subscribe(data => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return;
      }
      if (data.succeded == true) {
        this.requisitionsCause = data.dataList;
        this.requisitionsCause = this.requisitionsCause.sort((a, b) => a.stringData.localeCompare(b.stringData));
      }
    });

    this.prService.getVacants().subscribe(data => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return;
      }
      if (data.succeded == true) {
        this.vacants = data.dataList;
      }
    })

    this._ParamService.getParameters(keyWord.SkillLevel).subscribe(data => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return;
      }
      if (data.succeded == true) {
        this.skillLevels = data.dataList;
      }
    });

    this.prService.getDepartments(this.requisitionDTO.enclosureId).subscribe(data => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return;
      }
      if (data.succeded == true) {
        this.departments = data.dataList;
        if (this.data?.item) {
          this.form.department = this.departments?.find(dp => dp.departmentId == this.data?.item?.departmentId)
        }
      }
    })

    this._ParamService.getParameters(keyWord.workinghours).subscribe(data => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return;
      }
      if (data.succeded == true) {
        this.workingHours = data.dataList.sort((a, b) => a.ocode - b.ocode);
      }
    });

    this.prService.getLocations().subscribe({
      next: (res) => {
        if (!res.succeded) {
          this._toastService.error(res.errors[0])
          return;
        }
        this.locations = res.dataList;
      },
      error: (err) => {

      }
    });
    this.getExperiences();
  }

  private getExperiences() {
    if (this.experiences.length > 0) {
      this.requisitionDTO.experiences = this.experiences
        ?.find(a => a?.ocode === this.requisitionDTO.experience) ?? null
      return;
    }
    this._ParamService.getParameters(keyWord.Experience).subscribe(res => {
      if (res?.errors?.length > 0) {
        this._toastService.error(res?.errors[0])
        return;
      }
      this.experiences = res?.dataList?.filter(d => d?.status);
      this.experience = this.experiences.sort((a, b) => a.ocode - b.ocode);
      this.requisitionDTO.experiences = this.experiences
        ?.find(a => a?.ocode === this.requisitionDTO.experience) ?? null
    });
  }

  openSelectDepartmentModal(department, isGrandChild: boolean, index?: number) {
    this.dialog.open(SelectDepartmentModalComponent, {
      data: {
        department,
        isGrandChild,
        index
      },
      width: '50%'
    }).afterClosed()
      .subscribe({
        next: (res: any) => {
          if (!res.updated) {
          }
          else {
            this.grandChildSelected = res.grandChild
            this.getDepartment()
          }

        },
        error: (err: any) => {

        }
      })
  }

  openInSubsitutionModal() {
    this.dialog.open(InSubstitutionModalComponent, {
      data: {
        substitutionEmployees: this.substitutionEmployees,
      },
      width: '50%'
    }).afterClosed().subscribe({
      next: (res: any) => {
        console.log(res);
        if (res.updated) {
          this.replacementsList = res.replacements
        }
        else {
          return
        }

      }, error: (err: any) => {

      }
    })
  }

  getDepartment() {
    this.form.department = this.grandChildSelected == '' ? this.childSelected : this.grandChildSelected
    if (this.form?.isPlanned == false) {
      this.getPositions(this.form.department.companyId, this.form?.department?.departmentId);
    }
  }

  async getPositions(companyId, departmentId) {
    if (!companyId) {
      return
    }
    await this.prService.GetApprovedPositions(companyId, true).then(data => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])

      }
      if (data.succeded) {
        this.form.position = ""
        this.positions = data.dataList.sort((a, b) => a.positionName.trim().localeCompare(b.positionName.trim()))
        if (this.data.item) {
          this.form.position = this.positions.find(pt => pt.positionId == this.requisitionDTO.positionId)
          this.getEmployeesByPosition(this.form.position, departmentId)
        }
      }
    })
  }

  getEmployeesByPosition(position, departmentId) {


    if (!departmentId) {
      if (this.IsViewMode) {
        departmentId = this.requisitionDTO.departmentId;
      } else {
        departmentId = this.form?.department?.departmentId;
      }
    }


    this.form.requisitionType = null
    this.form.academicLevel = null
    this.requisitionDTO.experience = this.requisitionDTO?.experience || 0
    this.recruitmentMethodRequired = false
    if (Array.isArray(position)) {
      if (position.length < 1) return
    }
    if (position) {
      let occupationalGroup = position.occupationalGroup
      this.recruitmentMethodRequired = occupationalGroup >= 3
      this.form.requisitionType = this.requisitionsType.find(x => x.ocode == this.requisitionDTO.requisitionTypeId)
      this.form.academicLevel = this.academicLevels.find(x => x.ocode == this.requisitionDTO.academicLevelId)
      this.requisitionDTO.experience = this.requisitionDTO.experience > 0 ? this.requisitionDTO.experience : position.experienceId;
      this.employeeService.getEmployeesByPositions(departmentId, position.positionId).subscribe((data: any) => {
        this.substitutionEmployees = data.dataList
      })
    }
    this.getExperiences();
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  onVacancyCauseSelected(cause) {
    if (cause?.ocode === CauseRequisition?.AbandonmentCharge
      || cause?.ocode == CauseRequisition?.DismissalEmployee
      || cause?.ocode == CauseRequisition?.Resignation) {
      this.isSustitution = true
    }
    else {
      this.isSustitution = false
    }
  }

  GetRequisition(): void {
    if (this.requisitionDTO.personalRequisitionId == null) {
      this.InitializeScreen(null);
      return
    }
    else {
      this.prService.getPersonalRequisitionById(this.requisitionDTO.personalRequisitionId, true, this.requisitionDTO.companyId).subscribe(data => {
        if (data.errors.length > 0) {
          this._toastService.error(data.errors[0])
          return;
        }
        if (data.succeded) {
          if (this.data.item) {
            this.getConcursaParameter()
          }
          this.requisitionDTO = null;
          this.requisitionDTO = new PersonalRequisitionsModel();
          this.requisitionDTO = JSON.parse(JSON.stringify(data.dataList[0])) as PersonalRequisitionsModel;
          this.requisitionDTO.requisitionMethodId = data?.dataList[0]?.requisitionMethodId;
          this.form.contractAdmissionDate = this.requisitionDTO.contractAdmissionDate ? moment(this.requisitionDTO.contractAdmissionDate).format('yyyy-MM-DD') : null
          this.form.contractEndingDate = this.requisitionDTO.contractEndingDate ? moment(this.requisitionDTO.contractEndingDate).format('yyyy-MM-DD') : null
          this.getPositions(this.authInfo.getCompanyId(), this.requisitionDTO.departmentId).then(() => {
            this.prService.getReplacementsByRequisition(data.dataList[0].personalRequisitionId).subscribe((data) => {
              this.replacementsList = data.dataList
            })
            this.form.requisitionType = this.requisitionsType.find(rt => rt.ocode == this.requisitionDTO.requisitionTypeId)
            if (this.form.requisitionType) {
              this.beginningAndEndingDateRequired = this.form.requisitionType.ocode === 3
            }
            this.form.position = this.positions.find(pt => pt.positionId == this.requisitionDTO.positionId)
            this.form.vacancyCause = this.requisitionsCause.find(vc => vc.ocode == this.requisitionDTO.vacancyCauseId)
            this.form.workingHours = this.workingHours.find(wh => wh.ocode == this.requisitionDTO.workingHoursId)
            this.form.academicLevel = this.academicLevels.find(al => al.ocode == this.requisitionDTO.academicLevelId)
            this.form.academicLevel = this.academicLevels.find(al => al.ocode == this.requisitionDTO.academicLevelId)
            this.requisitionDTO.requestedDate = this.requisitionDTO.requestedDate ? moment(this.requisitionDTO.requestedDate).format('yyyy-MM-DD') : null
            this.requisitionDTO.applicationDate = this.requisitionDTO.applicationDate ? moment(this.requisitionDTO.applicationDate).format('yyyy-MM-DD') : null
            this.onVacancyCauseSelected(this.form.vacancyCause)
          })
          this.getExperiences();
        }
      }, error => {
        this._toastService.error('Ocurrió un error buscando los datos', 'Error');
      });
    }
  }
  validateContractsDates() {
    this.contractDateError = ''
    this.contractAdmissionDateError = ''
    const requisitionType = this.form.requisitionType
    if (!requisitionType) {
      this.contractDateError = ''
      return false
    }
    const { ocode } = this.form.requisitionType
    const { contractAdmissionDate, contractEndingDate } = this.form
    if (ocode != 3) {
      this.contractDateError = ''
      return false
    }
    if (!contractAdmissionDate) {
      this.contractAdmissionDateError = 'Debe llenar Inicio Vigencia Contrato'
    }
    if (!contractEndingDate) {
      this.contractDateError = 'Debe llenar Fin Vigencia Contrato'
    }
    if (moment(contractAdmissionDate) > moment(contractEndingDate)) {
      this.contractDateError = 'Fecha Fin Vigencia Contrato debe ser superior a la fecha Inicio Vigencia Contrato'
    }
    if (this.contractDateError.length > 0 || this.contractAdmissionDateError.length > 0) {
      return true
    }
  }
  changeContractDate() {
    this.validateContractsDates()
  }

  Accept(): void {
    const contractDatesAreInvalid = this.validateContractsDates()
    this.formIsInvalid = this.formHasError() || contractDatesAreInvalid;
    if (this.formIsInvalid) {
      return;
    }
    if (this.showMinimumAgeErrors()) {
      return
    }
    if (this.isMaxAgeInvalid()) {
      return
    }
    this.formIsInvalid = false;
    if(this.isSustitution){
      if(this.replacementsList.length < 1){
        this._toastService.warning(`Debe seleccionar "En Sustitución de"`)
        return
      }
    }
    if (this.data.item !== null) {
      this.requisitionDTO.companyId = this.authInfo.getCompanyId();
      this.requisitionDTO.enclosureId = this.authInfo.getCompanyId();
      this.requisitionDTO.departmentId = this.form?.department?.departmentId;
      this.requisitionDTO.positionId = this.form?.isPlanned === true ? this.form?.planningRequest?.positionId : this.form?.position?.positionId;
      this.requisitionDTO.requisitionTypeId = this.form?.requisitionType?.ocode;
      this.requisitionDTO.vacancyCauseId = this.form?.vacancyCause?.ocode;
      this.requisitionDTO.academicLevelId = this.form?.academicLevel?.ocode;
      this.requisitionDTO.workingHoursId = this.form?.workingHours?.ocode;
      this.requisitionDTO.experience = this.requisitionDTO?.experiences?.ocode;
      this.requisitionDTO.isPlanned = this.form?.isPlanned;
      this.requisitionDTO.userId = this.authInfo.getUserId();
      this.requisitionDTO.planningHeaderId = this.form?.planning?.planningHeaderId ?? 0;
      if (this.form.requisitionType) {
        const { ocode } = this.form.requisitionType
        this.requisitionDTO.contractAdmissionDate = ocode == 3 ? this.form.contractAdmissionDate : null
        this.requisitionDTO.contractEndingDate = ocode == 3 ? this.form.contractEndingDate : null
      }

      this.requisitionDTO.requisitionMethodId = this.recruitmentMethodRequired ? this.form.concursaParameter?.ocode : 2
      this.EditPersonalRequisition()
    } else {
      this.requisitionDTO.companyId = this.authInfo.getCompanyId();
      this.requisitionDTO.enclosureId = this.authInfo.getCompanyId();
      this.requisitionDTO.departmentId = this.form?.department?.departmentId;
      this.requisitionDTO.positionId = this.form?.isPlanned === true ? this.form?.planningRequest?.positionId : this.form?.position?.positionId;
      this.requisitionDTO.requisitionTypeId = this.form?.requisitionType?.ocode;
      this.requisitionDTO.vacancyCauseId = this.form?.vacancyCause?.ocode;
      this.requisitionDTO.academicLevelId = this.form?.academicLevel?.ocode;
      this.requisitionDTO.workingHoursId = this.form?.workingHours?.ocode;
      this.requisitionDTO.experience = this.requisitionDTO?.experiences?.ocode;
      this.requisitionDTO.condition = false;
      this.requisitionDTO.requisitionMethodId = this.recruitmentMethodRequired ? this.form.concursaParameter?.ocode : 2;
      this.requisitionDTO.isPlanned = this.form?.isPlanned;
      this.requisitionDTO.userId = this.authInfo.getUserId();
      this.requisitionDTO.planningHeaderId = this.form?.planning?.planningHeaderId ?? 0;
      this.requisitionDTO.contractAdmissionDate = this.form.contractAdmissionDate
      this.requisitionDTO.contractEndingDate = this.form.contractEndingDate
      if (this.form.requisitionType) {
        const { ocode } = this.form.requisitionType
        this.requisitionDTO.contractAdmissionDate = ocode == 3 ? this.form.contractAdmissionDate : null
        this.requisitionDTO.contractEndingDate = ocode == 3 ? this.form.contractEndingDate : null
      }
      this.createPersonalRequisition();
    }
  }

  Cancel(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }
  Approved(): void {
    if (this.data.item !== null) {
      this.prService.approvedPersonalRequisition(this.data.item.personalRequisitionId).subscribe(res => {
        if (res.errors.length > 0) {
          this._toastService.error(res.errors[0])
          return;
        }
        if (res.succeded) {
          this._toastService.success("La requisición fue aprobada satisfactoriamente")
          const row = { Applied: true }
          this.dialogRef.close(row);
        } else {
          this._toastService.error(res.errors[0], 'Información');
        }
      }, err => this._toastService.error(err))
    }
  }

  AddSkills(): void {
    let item;
    this.dialog.open(SkillsRequiredComponent, {
      data: item
    })
      .afterClosed()
      .subscribe((result) => {

      });
  }

  fillReplacingList() {
    if (this.replacements.length == 0) {
      return
    }
    this.replacementsList = this.replacements
    this.replacements = []
  }

  createPersonalRequisition() {

    this.prService.createPersonalRequisition(this.requisitionDTO).subscribe(res => {
      if (res?.errors?.length > 0) {
        this._toastService.error(res.errors[0])
        return;
      }
      if (res?.warnings?.length > 0) {
        this._toastService.warning(res?.warnings[0], '');
        return;
      }
      if (res.succeded) {
        this.replacementsList.forEach(replacement => {
          this.replacementDTO = {
            personalRequisitionId: res.identity,
            employeeId: replacement.employeeId,
            companyId: replacement.companyId,
            status: true
          }
          this.replacementsDTO.replacements.push(this.replacementDTO)
        });
        this.createReplacing()

        this._toastService.success('Agregado satisfactoriamente', 'Información');
        const row = { Applied: true }
        this.dialogRef.close(row);
      } else {
        this._toastService.error(res.errors[0], 'Información');
      }

    }, err => this._toastService.error('Ocurrió un error procesando los datos. Consulte al soporte del sistema'))
  }
  createReplacing() {
    if (this.replacementsList.length == 0) {
      return
    }
    this.prService.createReplacement(this.replacementsDTO.replacements).subscribe((res: any) => {
    })
  }

  deleteReplacing(replacement: Replacement) {
    this.sweetAlrt.AlertConfirm("Eliminar", "¿Está seguro de eliminar este registro?", "question").then(res => {
      if (res) {
        if (!replacement.personalRequisitionId) {
          this.replacementsList = this.replacementsList.filter((rl: any) => rl.employeeId !== replacement.employeeId)
          return
        }
        this.prService.deleteReplacement(replacement.personalRequisitionId, replacement.employeeId).subscribe((result) => {
          this.sweetAlrt.Default("Eliminado", "Registro eliminado satisfactoriamente", "success")
          this.replacementsList = this.replacementsList.filter((rl: any) => rl.employeeId !== replacement.employeeId)
        }, err => {
          this._toastService.error(err, "Ocurrió un error tratando de eliminar el registro")
        });


      }
      else {

      }
    })
  }


  EditPersonalRequisition() {
    this.prService.editPersonalRequisition(this.requisitionDTO).subscribe(data => {
      if (data?.errors?.length > 0) {
        this._toastService.error(data?.errors[0])
        return;
      }
      if (data?.warnings?.length > 0) {
        this._toastService.warning(data?.warnings[0], '');
        return;
      }
      if (data.succeded) {
        this.replacementsList.forEach(replacement => {
          this.replacementDTO = {
            personalRequisitionId: this.requisitionDTO.personalRequisitionId,
            employeeId: replacement.employeeId,
            companyId: replacement.companyId,
            status: true
          }
          this.replacementsDTO.replacements.push(this.replacementDTO)
        });

        this.createReplacing()
        this._toastService.success('Datos actualizados exitosamente!', 'Información');
        const row = { Applied: true }
        this.dialogRef.close(row);
      } else {
        this._toastService.error(data.errors[0], 'Información');
      }
    })
  }
  formHasError(): boolean {
    if (
      this.showRequisitionTypeIdErrors() ||
      this.showWorkingHoursIdErrors() ||
      this.showVacancyCauseIdErrors() ||
      this.showDepartmentIdErrors() ||
      this.showPlanningRequestErrors() ||
      this.showPlanningErrors() ||
      //this.showEnclosureIdErrors() ||
      this.showPositionIdErrors() ||
      // this.showMaximumAgeErrors() ||
      this.showApplicationDateErrors() ||
      this.showNumberPlacesErrors() ||
      this.showSexIdErrors() ||
      this.showGeneralDescriptionErrors() ||
      this.showAcademicLevelIdErrors() ||
      this.showOtherRequirementsErrors() ||
      // this.showDesirableRequirementsErrors() ||
      this.showObservationsErrors() ||
      this.showRequestedDateErrors() ||
      this.showConcursaParameterErrors()
    ) {
      return true;
    }
    return false;
  }
  // showDesirableRequirementsErrors() {
  //   let field = this.requisitionDTO.desirableRequirements.toString();
  //   if (field.length < 1) {
  //     return 'Este campo es requerido';
  //   }
  //   return '';
  // }
  showObservationsErrors() {
    let field = this.requisitionDTO.observations.toString();
    /* if (field.length < 1) {
      return 'Este campo es requerido';
    } */
    return '';
  }
  showGeneralDescriptionErrors() {
    let field = this.requisitionDTO.generalDescription.toString();
    /* if (field.length < 1) {
      return 'Este campo es requerido';
    }
    if (field.length > 250) {
      return 'Cantidad máxima de carácteres: 250';
    } */
    return '';
  }
  showSexIdErrors() {
    let field = this.requisitionDTO.sexId;
    if (field < 1) {
      return 'Debe seleccionar un sexo';
    }
    return '';
  }
  showAcademicLevelIdErrors() {
    let field = this.form.academicLevel;
    if (field < 1) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showRequestedDateErrors() {
    let field = this.requisitionDTO.requestedDate;
    if (!field) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showConcursaParameterErrors() {
    let field = this.form.concursaParameter;
    if (this.recruitmentMethodRequired) {
      if (Array.isArray(field)) {
        if (field.length == 0) {
          return 'Este campo es requerido';
        }
      }
      if (!field) {
        return 'Este campo es requerido';
      }
    }
    return '';
  }
  showRequisitionTypeIdErrors() {
    let field = this.form.requisitionType;
    if (field < 1) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showVacancyCauseIdErrors() {
    let field = this.form.vacancyCause;
    if (field < 1) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showWorkingHoursIdErrors() {
    let field = this.form.workingHours;
    if (field < 1) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showEnclosureIdErrors() {
    let field = this.form.enclosure;
    if (field < 1) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showPositionIdErrors() {
    let field = this.form.position;
    if (this.form?.isPlanned == false && field < 1) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showDepartmentIdErrors() {
    let field = this.form.department;
    if (field < 1) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showMinimumAgeErrors() {
    let field = this.requisitionDTO.minimumAge;
    this.ageError = ''
    if (field < 18) {
      this.ageError = 'Edad debe ser mayor a 18';
      return true
    }
    /* if(this.requisitionDTO.maximumAge < this.requisitionDTO.minimumAge){
      this.ageError = 'La edad máxima debe ser mayor a la edad mínima'
      return true
    } */
    return false
  }
  isMaxAgeInvalid() {
    let field = this.requisitionDTO.maximumAge;
    this.maxAgeError = ''
    /* if (field < 18) {
      this.maxAgeError = 'Edad debe ser mayor a 18';
      return true
    } */
    if (!field) {
      return false
    }
    if (field == 0) {
      return false
    }
    if (field < this.requisitionDTO.minimumAge) {
      this.maxAgeError = 'Edad máxima debe ser mayor a la edad mínima';
      return true
    }
    return false;
  }
  showApplicationDateErrors() {
    let field = this.requisitionDTO.applicationDate;
    if (!field) {
      return 'Este campo es requerido';
    }
    if (field < this.requisitionDTO.requestedDate) {
      return 'Fecha de ingreso debe ser mayor a la fecha de solicitud';
    }
    return '';
  }
  showOtherRequirementsErrors() {
    let field = this.requisitionDTO.otherRequirements.toString();
    /* if (field.length < 1) {
      return 'Este campo es requerido';
    }
    if (field.length > 250) {
      return 'Cantidad máxima de carácteres: 250';
    } */
    return '';
  }
  showNumberPlacesErrors() {
    let field = this.requisitionDTO.numberPlaces;
    if (field < 1) {
      return 'Cantidad mínima de plazas: 1';
    }
    return '';
  }

  showPlanningRequestErrors() {
    if (this.form?.isPlanned == true && !this.form?.planningRequest?.planningPlaceRequestId) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showPlanningErrors() {
    if (this.form?.isPlanned == true && !this.form?.planning?.planningHeaderId) {
      return 'Este campo es requerido';
    }
    return '';
  }

  limiteMaxNumber(ageRange, value) {
    if (ageRange === 'min' && value > 99) {
      this.requisitionDTO.minimumAge = 99;
    }
    if (ageRange === 'max' && value > 99) {
      this.requisitionDTO.maximumAge = 99;
    }
  }

  minimizeDialog() {
    this.contentVisible = false;
    this.dialogRef.updatePosition({ bottom: '0', right: '0' })
  }

  maximizeDialog() {
    this.contentVisible = true;
    this.dialogRef.updatePosition();
  }

}
