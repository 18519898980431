import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ModalViewsEnum } from 'src/app/models/modal-views';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { AddTeacherContractComponent } from '../add-teacher-contract/add-teacher-contract.component';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { IEmployee, ITeacherContractView } from 'src/app/models/Employee.model';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { EndContract } from 'src/app/models/end-contract.model';
import { openReport } from 'src/app/shared/utils/utility';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';

@Component({
  selector: 'app-view-contracts',
  templateUrl: './view-contracts.component.html',
  styleUrls: ['./view-contracts.component.css']
})
export class ViewContractsComponent implements OnInit {

  status: { name: string, code: number }[] = [
    { name: 'Activos', code: 1 },
    { name: 'Inactivos', code: 0 }
  ];

  searchList: ITeacherContractView[] = []; 
  searchBarDisplayNames: { propkey?: string, displayName: string, propkeyId? }[] =
    [
      { propkey: "teacherContractId", displayName: "Secuencia", propkeyId: 1 }
    ];

  filterText = '';
  filteredList: { records: ITeacherContractView[], enable: boolean } = { records: [], enable: false };
  selectedItems: any[] = [];
  viewTypes = ModalViewsEnum;
  searchBarValues = { 
    propkeyId: '0',
    searchParameter: ''
  };
  contracts: ITeacherContractView[] = []
  contractsFiltered: ITeacherContractView[] = [];
  contractsPaginated: ITeacherContractView[] = [];

  dropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  admissionDate = null
  @ViewChild('paginator') paginator: CaptionComponent;
  sweetAlrt = new SweetAlert()

  constructor(public dialogRef: MatDialogRef<ViewContractsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { model: IEmployee },
    private employeeService: EmployeesService,
    private dialog: MatDialog,
    public authInfo: AuthInfoService,
    private toastService: ToastrService,
    private datePipe: DatePipe,
    private _exlService: ExportToExcelService) {

    if (data.model.employeeId > 0) {
      this.admissionDate = data.model.admissionDate
      this.selectedItems.push(this.status.find(x => x.code == 1));
      this.loadContracts();
    }

  }

  ngOnInit(): void {
  }

  loadContracts() {
    this.employeeService.getTeacherContracts(this.data?.model?.employeeId).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          });

          res.warnings.forEach(err => {
            this.toastService.warning(err);
          });

          return;
        }

        this.contracts = res.dataList;
        this.filter();

      },
      error: (err) => {
        this.toastService.error('Ha ocurrido un error tratando de consultar la lista de contratos.');
      }
    });
  }

  OpenAddContract(model, viewType: number): void {
    this.dialog.open(AddTeacherContractComponent, {
      width: '50%',
      data: { viewType, model, admissionDate: this.admissionDate }
    })
      .afterClosed()
      .subscribe((result) => {
        this.loadContracts()
      });
  }

  dropdownConfig(displayKey: string = "label") {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccione una opción', // text to be displayed when no item is selected defaults to Select,
      //customComparator: (a, b)=> a.id - b.id, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  onKeyChange(text) {
    this.filterText = text;
  }
  
  exportToExcel() {
    let data: ITeacherContractView[] = []

    if (this.filteredList.enable) {
      data = this.filteredList.records
    } else {
      data = this.contractsFiltered
    }

    const excelHeaders: string[][] = [[
      "Secuencia",
      "Fecha Registro",
      "Inicio de Contrato",
      "Fin de Contrato",
      "Precio por Hora",
      "Estatus"
    ]];

    const records = data.map(d => {
      return {
        teacherContractId: d.teacherContractId,
        createDate: this.datePipe.transform(d.createDate, 'dd/MM/yyyy'),
        startDate: this.datePipe.transform(d.startDate, 'dd/MM/yyyy'),
        endDate: this.datePipe.transform(d.endDate, 'dd/MM/yyyy'),
        pricePerHour: d.pricePerHour,
        status: d.status ? 'Activo' : 'Inactivo'
      }
    });

    this._exlService.exportToExcelSpecificColumns(records, excelHeaders, 'Lista de Contratos');
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.contractsFiltered = this.filterTable(this.contracts)
    }
  }

  filterTable(list: ITeacherContractView[]) {
    let sl = this.selectedItems;

    if (this.selectedItems.length === 0) {
      return list;
    }

    list = [...list?.filter(l => sl?.some(s => {
      if (s.code == 1 && l.status == true) {
        return true
      } else if (s.code == 0 && l.status == false) {
        return true
      }
    }))];



    return list;
  }

  getFilteredList(event) {
    this.searchBarValues.propkeyId = event?.propkey?.propkeyId ? event?.propkey?.propkeyId : '0'
    this.searchBarValues.searchParameter = event?.text ? event?.text : ''
    this.filteredList = event;
    this.paginator.selectedPage = 1
    this.filteredList.records = this.filterTable(event.records)
  }

  getPaginatedRecords(event) {
    this.contractsPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
//http://localhost:51776/RPVreportPage.aspx?Reportcode=List_TeacherPerHoursContract&EmployeeId=1210&CompanyId=1&Status=0-1&PropKeyId=0&SearchParameter=&Page=1&PageSize=200



  exportToPdf() {
    const propKeyId = this.searchBarValues.propkeyId.toString() ?? '0';
    const searchParameter: string = this.searchBarValues.searchParameter ?? '';
    const page: number = this.paginator?.selectedPage;
    const pageSize: number = this.paginator?.registerPerPage;
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.List_TeacherPerHoursContract;
    const ableConditions = this.selectedItems
    
    ?.map((e) => e.code)
    ?.join("-");

    const employeeId = this.data?.model?.employeeId
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&EmployeeId=${employeeId}&CompanyId=${companyId}&Status=${ableConditions}&PropKeyId=${propKeyId}&SearchParameter=${searchParameter}&Page=${page}&PageSize=${pageSize}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Servidores Públicos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint2').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

  endContract(contract: ITeacherContractView) {
    this.sweetAlrt.AlertConfirm_with_input("Finalizar", "¿Está seguro de finalizar este contrato?", "Por favor introducir el motivo", "question").then(res => {
      if (res?.isConfirmed && res.value?.length !== 0) {
        const {teacherContractId } = contract
        let endContractModel: EndContract = {
          endDate: new Date(),
          teacherContractId: teacherContractId,
          modifyUserId: this.authInfo.getUserId(),
          annulmentComment: res.value
        }

        this.employeeService.endTeacherContract(endContractModel).subscribe((result) => {
          this.sweetAlrt.Default("Finalizado", "Contrato finalizado satisfactoriamente", "success")
          this.loadContracts()
        }, err => {
          this.toastService.error(err, "Ocurrió un error tratando de finalizar el contrato")
        });
      } else if (res.value?.length < 1) {
        this.toastService.warning('Debe digitar un motivo para poder finalizar este contrato')
      }
    })
  }
}
