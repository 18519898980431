import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EmployeesService } from 'src/app/services/employees.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { EMPTY } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { IEmployee } from 'src/app/models/Employee.model';
import { PagedResult } from 'src/app/shared/models/page-result';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { FilterEvent, SearchBarPaginationComponent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { CompaniesService } from 'src/app/services/companies.service';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { AdvancedFilterByEmployeesComponent } from '../employees/advanced-filter-by-employees/advanced-filter-by-employees.component';
import { SearchEmployeeComponent } from '@payroll/action-edit/search-employee/search-employee.component';
import { ChangeSupervisor } from 'src/app/models/change-supervisor.model';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';

declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'app-massive-change-supervisor',
  templateUrl: './massive-change-supervisor.component.html',
  styleUrls: ['./massive-change-supervisor.component.css']
})
export class MassiveChangeSupervisorComponent implements OnInit, AfterViewChecked, OnDestroy {
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'immediate-massive-supervisor-change',
    exportAction: { key: 'Export', value: false },
    addAction: { key: 'Add', value: false }
  }
  
  public advancedFilter = null
  public allItemsSelected: boolean = false;
  public sendItems: any[] = [];
  public localStorageSearch = 'change-supervisor-list_filter_search'
  
  @ViewChild('searchBar') 
  public searchBar: SearchBarPaginationComponent
  public employees: any[] = [];
  public employeesListOriginal: any[] = [];
  public branches: any[] = []
  public branch: any
  public employeeId: number;

  public selectedSupervisor: IEmployee = null;
  public canUseOption = false;
  public searchParameter!: FilterEvent;


  public searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Secuencia" },
      { propkeyId: 2, displayName: "Código de Servidor Público" },
      { propkeyId: 3, displayName: "Servidor Público" },
      { propkeyId: 16, displayName: "Tipo de Documento" },
      { propkeyId: 4, displayName: "Documento de Identidad", default: true },
      { propkeyId: 15, displayName: "Sexo" },
      { propkeyId: 5, displayName: "Unidad Organizativa" },
      { propkeyId: 6, displayName: "Cargo" },
      { propkeyId: 7, displayName: "Teléfono" },
      { propkeyId: 8, displayName: "Tipo de Servidor Público" },
      { propkeyId: 9, displayName: "Categoría de Servidores Públicos" },
      { propkeyId: 10, displayName: "Tanda" },
      { propkeyId: 11, displayName: "Condición Actual" },
      { propkeyId: 100, displayName: "Búsqueda avanzada" },
    ];
  public employeesPagedResut = new PagedResult<any>();
  public dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  public dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Todo',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  constructor(
    private readonly employeeService: EmployeesService,
    private readonly _toastService: ToastService,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly conpaniesService: CompaniesService,
    private readonly _toastr: ToastrService,
    private readonly dialog: MatDialog,
    private readonly authInfo: AuthInfoService,
  ) {
  }

  ngOnInit(): void {
    this.setSearchParameterStorage()

    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result) {
        this.canUseOption = true
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.getData();
      }
    });
    applySort();
  }
  
  openModalSearchEmployee() {
    this.dialog.open(SearchEmployeeComponent, {
      data: {
        hideInputTeacher: true
      },
      width: '80%'
    })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        if (result?.data) {
          this.selectedSupervisor = result?.data
        }
      });
  }

  save():void{
    Swal.fire({
      title: '¿Está seguro que desea cambiar el supervisor inmediato de los servidores públicos seleccionados?',
      text: '',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#28a745',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        const model:ChangeSupervisor = {
          supervisorId:this.selectedSupervisor.employeeId,
          userId:this.authInfo.getUserId(),
          employees:this.sendItems.filter(({isSelected}) => isSelected).map(({employeeId}) => ({employeeId}))
        }
    
        this.employeeService.changeSupervisor(model).pipe(
          catchError(_ =>{
            this._toastService.error('Ocurrio un error tratando de cambiar el supevisor');
    
            return EMPTY;
          })
        ).subscribe({
          next:(res) =>{
            if (!res.succeded) {
    
              res.errors.forEach(err => {
                this._toastService.error(err);
              })
      
              res.warnings.forEach(err => {
                this._toastService.warning(err);
              })
      
              return;
            }
    
            this._toastService.success('Supervisor cambiado correctamente','');
    
            this.sendItems = [];
            this.selectedSupervisor = null;
            this.allItemsSelected = false;
            this.employees = this.employees.map((employee) =>({...employee,isSelected:false}));
          }
        });
      }
    })
  }

  selectItem(employee) {
    if (this.sendItems.some(item => item?.employeeId === employee?.employeeId)) {
      this.sendItems = this.sendItems.filter((value) => value?.employeeId != employee?.employeeId);
      this.allItemsSelected = false;
    } 

    this.sendItems.push(employee)
  }

  selectAllItems() {
    this.sendItems = [];
    this.employees.forEach((employee) => {
      employee['isSelected'] = this.allItemsSelected;
        if (this.allItemsSelected) {
          this.sendItems.push(employee);
        }
    });
  }

  getData() {
    this.conpaniesService.getCompanies().subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this._toastService.error(err);
          })
  
          res.warnings.forEach(err => {
            this._toastService.warning(err);
          })
  
          return;
        }

        this.branches = res.dataList;

        this.getCompanyLocalStorage()
        this.getEmployeeList();
      }
    });
  }

  onBranchChange() {
    this.getEmployeeList()
    this.setCompanyToLocalStorage();
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  filterSearch(event: FilterEvent) {
    this.employeesPagedResut.page = 1
    this.searchParameter = event
    if (event.propkeyId != 100) {
      this.advancedFilter = ','.repeat(9);
      this.getEmployeeList();
    } else {
      this.OpenAdvancedFilters();
    }
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  changeStatus() {
    this.getEmployeeList();
  }

  clearStorage() {
    localStorage.removeItem('action-change-tab-selected')
    localStorage.removeItem('personnel-actions-tab-selected')
  }

  private getEmployeeList() {
    if (!this.canUseOption) {
      return
    }
    const advanced = this.advancedFilter ?? ','.repeat(9);
    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value ?? ''
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const status: number = 1;
    const branchId: number = this.branch?.companyId ?? 0

    this.employeeService.getEmployeesPaginatedAdvanced(branchId, 0, page, pageSize, propKeyId, text, status, 1, null, advanced).pipe(
      catchError(_ =>{
        this._toastService.error('Ha ocurrido un error inesperado al obtener la lista de Servidores Públicos')

        return EMPTY;
      })
    ).subscribe((res: any) => {
      if (!res.succeded) {
        res.errors.forEach(err => {
          this._toastService.error(err);
        })

        res.warnings.forEach(err => {
          this._toastService.warning(err);
        })

        return;
      }

      this.employeesListOriginal = res.singleData.items
      this.employees = res.singleData.items.map((employee) => ({ ...employee, isSelected:false }));
      this.employeesPagedResut = res.singleData
      this.getEmployeeActions();
    });
  }

  getPaginatedRecords(event: PaginationEvent) {
    if (this.employeesPagedResut.page == event.selectedPage 
      && this.employeesPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.employeesPagedResut.page = event.selectedPage;
    this.employeesPagedResut.pageSize = event.registersPerPage;
    this.getEmployeeList()
  }

  getEmployeeActions() {
    this.employeeService.getEmployeeActions().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastr.error(res.errors[0]);
        return;
      }
      const employeeActions = res.dataList as any[];
      const employees = this.employeesListOriginal.map((em: any) => {
        const employee = { ...em };
        const employeeAction = employeeActions.find((e: any) => e.employeeId === em.employeeId);
        employee.employeeAction = employeeAction?.personalActionsType || "N/A";
        return employee;
      });
      this.employees = [...employees];
      this.employeesListOriginal = [...employees];
    });
  }


  resetFilters() {
    localStorage.removeItem(this.localStorageSearch);
    localStorage.removeItem('change-supervisor_companySelected');
    this.allItemsSelected = false;
    this.searchBarDisplayNames = this.searchBarDisplayNames.map(value => {
      if (value.propkeyId === 4) {
        return { ...value, default: true };
      }
      return value;
    });
    this.getData();
  }

  exportToPdf() {
    const propKeyId: number = this.searchParameter?.propkeyId ?? 0;
    const searchParameter: string = this.searchParameter?.value ?? '';
    const status: number = 1;
    const page: number = this.employeesPagedResut?.page;
    const pageSize: number = this.employeesPagedResut?.pageSize;
    const branchId: number = this.branch?.companyId ?? 0
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.ChangeSupervisor;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&AdvancedFilter=${this.advancedFilter}&CompanyId=${companyId}&BranchId=${branchId}&PropKeyId=${propKeyId}&SearchParameter=${searchParameter}&Status=${status}&Page=${page}&PageSize=${pageSize}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Servidores Públicos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  exportEmployeeByDepartments() {
    const companyId: number = this.authInfo.getCompanyId();
    const type: number = 1;
    const status: boolean = true;
    const reportCode: ReportCode = ReportCode.EmployeesActive;
    const branchId = this.branch.companyId;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&BranchId=${branchId ?? 0}&Status=${status}&Type=${type}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Servidores Públicos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  exportEmployeeData(employee) {
    const { companyId, employeeId, status } = employee
    const reportCode: ReportCode = ReportCode.EmployeeData;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&EmployeeId=${employeeId}&Status=${status ? 1 : 0}`;

    let parameters = {
      url: reportUrl,
      title: 'Reporte Servidores Públicos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  OpenAdvancedFilters() {
    this.dialog.open(AdvancedFilterByEmployeesComponent, {
      width: '55%',
      data: {
        advanced: this.advancedFilter
      }
    }).afterClosed().subscribe({
      next: (res:any) => {
        if(res.success == false){
          return
        }
        this.advancedFilter = res.text
        this.getEmployeeList()
      }
    })
  }

  private setSearchParameterStorage() {
    const localStorageItem = localStorage.getItem(this.localStorageSearch);
    if(localStorageItem){
      try{
        const localStorageSearch: FilterEvent = JSON.parse(localStorageItem);
        this.searchParameter = localStorageSearch;
      }catch(exception){
        this.searchParameter = {};
      }
    }else{
      this.searchParameter = {};
    }
  }
  
  private findBranchByCompanyId(companyId) {
    return this.branches.find(branch => branch.companyId === companyId);
  }
  
  private setCompanyToLocalStorage() {
    const company = this.branch?.companyId;
    localStorage.setItem('change-supervisor_companySelected', company ? JSON.stringify(this.branch) : undefined);
  }
  
  private getCompanyLocalStorage() {
    const companyStorage = localStorage.getItem('change-supervisor_companySelected');
    let companyId = this.authInfo.getCompanyId()
    if (companyStorage !== 'undefined' && companyStorage?.length) {
      const companyIdStorage = JSON.parse(companyStorage)?.companyId
        if (companyIdStorage != 'undefined') {
          companyId = companyIdStorage
          this.branch = this.findBranchByCompanyId(companyId);
        }
    }
  }
}
