import { AfterContentChecked, AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormatTypes } from '../../../shared/dynamic-format/enums/format-types';
import { ToastService } from '../../../shared/toast/toast.service';
import { FileManagerService } from '../../../shared/file-manager/file-manager.service';
import { HttpClientService } from '../../../services/http-client/http-client.service';
import { HttpResponse } from '../../../services/http-client/http-Response';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../shared/loading/loading.service';
import { delay } from 'rxjs/operators';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { Location } from '@angular/common';
import {
  FamilyPersonInInstitution,
  Person,
  PersonAdditionalInformation,
  PersonalReference,
  PersonPhone,
  PersonRecomendation,
  PersonsAppliedStudy,
  PersonsDependent,
  PersonsEmail,
  PersonsJobReference,
  PersonsSkill,
  PersonsWorkExperience,
  WizardGatheredData
} from '../pdf-preview/models/pdf-personal-record.model';
import { CandidateRegService } from 'src/app/services/http-client/candidateRegService.service';
import { Subscription } from 'rxjs';
import { docService } from 'src/app/services/doc.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { IExitListParameter } from '../unlink-employees-tab/models/ExitList';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { DepartmentService } from 'src/app/services/department.service';
declare const $: any

@Component({
  selector: 'pdf-action-change-employee-desvincular',
  templateUrl: './pdf-action-change-employee-desvincular.component.html',
  styleUrls: ['./pdf-action-change-employee-desvincular.component.css']
})


export class PdfActionChangeEmployeeDesvincularComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly personalRequestRoute = 'hcm/personal-request';
  public loadingPic = true
  public requisitionTypeList = [];
  subscriptions: Subscription = new Subscription();
  supervisorNames = {
    hhrr: '',
    rectory: '',
    employeeSupervisor: ''
  }
  isTableLoading: boolean;
  formatYesOrNot = FormatTypes.YesOrNo;
  document: any;
  id: number;
  employeeId: number;
  photoUrl: string;
  primaryPhone: string;
  primaryEmail: string;
  skills: [[key: string], { personalSkill: string, personalSkillLevel: string }];
  isLoading: boolean;
  fileModalConfig = {
    src: '',
    type: '',
    filename: '',
    width: 225,
    height: 225,
    formTitle: '',
    formSubTitle: '',
  };
  personalRequestView: {
    person: Person;
    familyPersonInInstitutions: FamilyPersonInInstitution[];
    personPhone: PersonPhone[];
    personsEmails: PersonsEmail[];
    personsDependents: PersonsDependent[];
    personAdditionalInformation: PersonAdditionalInformation[];
    personsAppliedStudies: PersonsAppliedStudy[];
    personsSkills: PersonsSkill[];
    personsWorkExperiences: PersonsWorkExperience[];
    personsJobReferences: PersonsJobReference[];
    personalReferences: PersonalReference[];
    personRecomendation: PersonRecomendation[];
  }

  pdfOpt: any;
  personSelected: any;

  entryListParameterControl:ParameterControl[];
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  exitList:IExitListParameter[];
  formChecks: boolean[];
  exitListToggle: ParameterControl[] = [];
  employeeExitForm: FormGroup = new FormGroup({
    employeeExitList: new FormArray([])
  });


  public company: any;
  file = { logo: '', image1: '', image2:'' }

  constructor(
    private _toastService: ToastService,
    private _route: ActivatedRoute,
    public loadingService: LoadingService,
    private srvPerson: PersonService,
    private _srvParameter: ParameterControlService,
    private _builder: FormBuilder,
    private srvDoc: docService,
    private _companiesService: CompaniesService,
    private authInfo: AuthInfoService,
    private srvCandidateService: CandidateRegService,
    private location: Location,
    private _departmentService: DepartmentService,
    private personnelActionsService: PersonnelActionsService) {
    this.pdfOpt = {
      orientation: 'p',
      unit: 'in',
      format: [11, 8.5]
    };
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  ngAfterViewInit(): void {

  }

  openGuID(guid) {
    try {
      this.srvDoc.getDocument(guid).subscribe(e => {
        window.open(e.data, '_blank');
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  ngOnInit(): void {

    this.id = Number(this._route.snapshot.params["id"] || 0)
    this.employeeId = Number(this._route.snapshot.params["employeeId"] || 0)

    if (this.employeeId == null || this.employeeId == 0) {
      return;
    }

    this.loadDDL().then(e => {
      
      this.loadEmployee();
    })
    this.companyById(this.authInfo.getCompanyId())
    // let {nationalityId} = this.personalRequestView.person;
    // let sub = this._srvCandidateService.getCountry(nationalityId).subscribe((data:ResponseModel)=>{
    //   //this.personalRequestView.person['nationalityName']=data.singleResultData;
    //   console.log(data.dataList);

    // });

    // this.subscriptions.add(sub);
  }

  goBack(){
    this.location.back();
  }

  async loadDDL() {
    this.srvCandidateService.getParameters(keyWord.CauseRequisition).subscribe(e => {
      if (e.succeded) {
        this.requisitionTypeList = e.dataList
      } else {
        this._toastService.error(e.errors[0]);
      }
    }, error => {
    })
  }

  loadingRecordData() {
    let sub = this.loadingService.loadingSub.pipe(delay(0)).subscribe(x => {
      this.isTableLoading = x;
    })

    this.subscriptions.add(sub);
    // this._fileManager.getFileToShow(30435).subscribe((response) => {
    //   const data = response.file.split(";");
    //   data[0]="data:image/png";
    //   (this.fileModalConfig.src = `${data[0]};${data[1]}`),
    //   (this.fileModalConfig.type = response.fileType),
    //     (this.fileModalConfig.filename = response.filename),
    //     (this.isLoading = false);
    // },
    // (error) => {
    //   this._toastService.error(
    //     'El archivo no existe o no se encuentra en el servidor'
    //   );

    // })   


    if (this.id > 0) {
      this.srvPerson.getPersonWizard(this.id).subscribe((res: ResponseModel<WizardGatheredData>) => {
        if (res.singleResultData) {
          this.personalRequestView = res.singleResultData;
          for (let i = 0; i < this.personalRequestView.personsWorkExperiences.length; i++) {
            const item = this.personalRequestView.personsWorkExperiences[i];
            this.personalRequestView.personsWorkExperiences[i].personName = ''

            this.requisitionTypeList.filter(x => x.ocode == item.reasonOfExitId).forEach(res => {
              this.personalRequestView.personsWorkExperiences[i].personName = res.stringData
            })
          }

          /* if (this.personalRequestView.person.sexId == 2) {
            this.personalRequestView.person.sex = 'Masculino'
          } else {
            this.personalRequestView.person.sex = 'Femenino'
          } */

          if (this.personalRequestView.person.profileImage != '' && this.personalRequestView.person.profileImage != null) {
            this.srvDoc.getDocument(this.personalRequestView.person.profileImage).subscribe(e => {
              this.photoUrl = e.data;
              this.loadingPic = false
            }, error => {
              this.loadingPic = false
            })
          } else {
            this.loadingPic = false
          }

          //FIXME: The person photoId isn't within the model of the Person.
          // if (this.personalRequestView.personalPhoto) {
          //   this._fileManager.getFileToShow(this.personalRequestView.personalPhoto.fileId).subscribe(
          //     res => {
          //       this.photoUrl = res.file;
          //     })
          // }
        }
      }, error => {}, () => {
        this.loadingPic = false
        if (this.personalRequestView) {
          this.primaryPhone = this.personalRequestView.personPhone?.find(phone => phone?.primaryContact == true)?.phoneNumber || 'N/A';
          this.primaryEmail = this.personalRequestView.personsEmails?.find(email => email?.primaryContact == true)?.email || 'N/A';
        }
      })
    }


    // this._http.get<HttpResponse<FormConfigurationModel>>(`sec/form-configuration/`+36)
    // // tslint:disable-next-line: deprecation
    // .subscribe((response) => {
    //   if (response.error) {
    //     this._toastService.error(response.error.msg);
    //     return;
    //   }
    //   this.fileModalConfig.formTitle = response.data.title;
    //   this.fileModalConfig.formSubTitle = response.data.description;

    // });

  }

  loadEmployee(){
    let getEmployee = this.personnelActionsService.getEmployeeById(this.employeeId).subscribe((res:any)=>{
      if(res.errors.length > 0){
        this._toastService.error("Ha ocurrido un error al obtener los datos del Servidor Público")
        return;
      }
      this.personSelected = res.dataList[0];
      this.getExitListParameter();

      const departmentId = this.personSelected.departmentId
      this.getDepartments(departmentId)
      if (this.personSelected.profileImage != '' && this.personSelected.profileImage != null) {
        this.srvDoc.getDocument(this.personSelected.profileImage).subscribe(e => {
          this.photoUrl = e.data;
          this.loadingPic = false
        }, error => {
          this.loadingPic = false
        })
      } else {
        this.loadingPic = false
      }

    },error =>{
      this._toastService.error("Error al buscar el Servidor Público")
      return;
    });

    this.subscriptions.add(getEmployee);
  }

  buildExitListForm(ocode:number, employeeId:number, employeeExitListId:number, selected:boolean){
    return this._builder.group({
      employeeExitListId:[employeeExitListId],
      employeeId: [Number(employeeId)],
      ocode: [ocode, [Validators.required]],
      selected: [selected, [Validators.required]],
      companyId:[this.authInfo.getCompanyId()],
      status:[true]
    });
  }

  pushExitListItems(exitListTypeId:number, employeeId:number, employeeExitListId:number, selected:boolean){
    const item = this.employeeExitForm.get('employeeExitList') as FormArray;
    item.push(this.buildExitListForm(exitListTypeId, employeeId, employeeExitListId,selected));
    if(this.personSelected?.status == false){
      item.disable();
    }
  }

  getExitListParameter(){
    this._srvParameter.getParameters(keyWord.ExitList).subscribe((res:any) => {
      if(res.succeded){
        this.exitList = res.dataList
        this.getExitLists(this.personSelected.employeeId)
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error(this.errorMessage)
    })
  }
  getExitLists(employeeId){
    this.personnelActionsService.getEmployeeExitListByEmployeeId(employeeId).subscribe((res:ResponseModel<any>) => {
      if(res.succeded){
          this.exitListToggle = this.exitList;
          this.exitList.forEach((item, i:number)=>{
            this.pushExitListItems(item.ocode, this.personSelected.employeeId, res.dataList.find(x=>x.ocode==item.ocode)?.employeeExitListId ?? 0, res.dataList.find(x=>x.ocode==item.ocode)?.selected ?? false);
          });
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error(this.errorMessage)
    })
  }

  getExitReason(id) {
    this.requisitionTypeList.filter(e => e.ocode = id)[0].stringData
  }

  showModal(modalName: string): void {
    $(`#${modalName}`).modal('show');
  }

  isDominican(): boolean {

    const dominicanDictionary = [
      "Dominicano",
      "Dominicana",
      "Republica Dominicana",
      "República Dominicana",
    ]
    return dominicanDictionary.find(x => this.personalRequestView?.person.nationality?.toLowerCase().includes(x.toLowerCase()))
      ? true : false;
  }

  isMarriage(): boolean {
    const marriageDictionary = [
      "Casado",
      "Casada",
      "Casado/a",
      "Casada/o",
      "Casado/(a)",
      "Casada/(o)",
    ]
    return marriageDictionary.find(x => this.personalRequestView?.person.maritalStatus?.toLowerCase().includes(x.toLowerCase()))
      ? true : false;
  }

  
  getDepartments(employeeSupervisorId){
    this._departmentService.getAll().subscribe((res:any) => {
      if(res.succeded){
        const departments = res.dataList
        const hhrrDepartment = departments.find(x => x.isHhrr == true)
        const employeeDepartment = departments.find(x => x.departmentId == employeeSupervisorId)
        const rectoryDepartment = departments.find(x => x.departmentId == 1000)
        this.supervisorNames.employeeSupervisor = this.getSupervisorName(employeeDepartment)
        this.supervisorNames.rectory = this.getSupervisorName(rectoryDepartment)
        this.supervisorNames.hhrr = this.getSupervisorName(hhrrDepartment)
      }else{
        this._toastService.error(res.errors[0])        
      }
    }, err => {
      this._toastService.error('Ha ocurrido un error inesperado')
    })
  }
  getSupervisorName(department):string {
    if(!department) return ''
    if(department?.supervisor == "No Definido" || !department?.supervisor){
      return ''
    }
    return department?.supervisor;
  }

}

