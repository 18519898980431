import { DropDownOptionModel } from '../dropdown/models/dropdown-option-model';
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'el-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input('customClass') class: string;
  @Input() options: Array<DropDownOptionModel>;
  @Output() onChange = new EventEmitter<string>();

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(): void {
    // console.log(this.options);
  }
}
