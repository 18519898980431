import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { docService } from 'src/app/services/doc.service';

@Component({
  selector: 'app-show-file-modal',
  template: `
    <div class="modal-header border-0 dv-table-header-1 bg-primary">
      <h2 class=" text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>{{title}}</h2>
      <button (click)="close()" type="button" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
      </button>
    </div>
    <div class="container mt-2">
    <iframe [src]='transform(src)' width="100%" height="600px"></iframe>
    </div>
  `,
  styles: [
  ]
})
export class ShowFileVacationModalComponent implements OnInit {
  guid = ''
  title = ''
  src = ''
  constructor(
    private dialogRef: MatDialogRef<ShowFileVacationModalComponent>,
    private docService: docService,
    private sanitizer: DomSanitizer,
    private toastService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { guid: string, title: string, isBase64: boolean, base64: string}
  ) { }

  ngOnInit(): void {
    this.guid = this.data.guid;
    this.title = this.data.title;
    
    if(!this.data.isBase64){
      this.getDocument();
    }else{
      this.src = this.data.base64;
    }
    
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  close(){
    this.dialogRef.close()
  }

  
  getDocument() {
    this.docService.getDocument(this.guid).subscribe(e => {
      this.src = e?.data;
    }, error => {
      this.toastService.error("Ocurrió un error inesperado tratando de descargar el documento");
    })
  }

}
