import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuditApprovalService } from 'src/app/services/audit-approval.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';

@Component({
  selector: 'app-last-payroll-tab',
  templateUrl: './last-payroll-tab.component.html',
  styleUrls: ['./last-payroll-tab.component.css']
})
export class LastPayrollTabComponent implements OnInit {
  headers: string[] = ['Área Presupuestaria'];
  body: any[] = ['Área Presupuestaria'];

  constructor(private auditApprovalService: AuditApprovalService,
    private authInfoService: AuthInfoService) { }

  ngOnInit(): void {
    /*this.auditApprovalService.getLastPayroll(this.authInfoService.getCompanyId())
    .subscribe((res: any) => {
      const data = res.singleData.payrollBylast3month;
      data.forEach((s: any) => {
        this.headers.push(s.description)
        this.body.push(s.value3);
      })
    })*/
  }

  getPaginatedRecords(event){
    
  }

}
