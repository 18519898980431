import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Departments } from '../models/departments.model';
import { Positions } from '../models/positions.model';
import { RequestPositionPlaces } from '../models/request-position-places';
import { BudgetApprovation } from '../pages/Planning/models/budget-approvation.model';
import { CurrentSituationFilter } from '../pages/Planning/models/current-situation-filter.model';
import { CurrentSituation } from '../pages/Planning/models/current-situation.model';
import { NewPositionEntities } from '../pages/Planning/models/new-position-POST.model';
import { NewPositions } from '../pages/Planning/models/new-positions.model';
import { ChangeConditionPlanning, PlanningHeader,  openPlanning, PlanningPlace, PlanningPlaces, PostPlanningHeader, PlanningPlacePositionStatistic, PlanningApprove, PlanningApproveSetting, PlanningRejectionReason, PlanningRequest, PlanningDashboard, PlanningDashboardQuantities, ConfigSurplusPercentage, PlanningPlaceRequestDetail } from '../pages/Planning/models/planning';
import { RequestMetaData, ResponseModel } from '../shared/models/strongly-typed-response.model';
import { AuthInfoService } from './auth-info.service';
import { PlanningAccountDto } from '../pages/Planning/components/planning-opening-planning/models/planningaccount';

@Injectable({
  providedIn: 'root'
})
export class PlanningRrhhService {

  private readonly _hrmRoute = environment.api_url;
  private readonly _coreRoute = environment.api_core_url;
  constructor(
    private _http: HttpClient, private authInfo: AuthInfoService
  ) { }

  //#region CurrentSituation section
  getCurrentSituation(planningHeaderId:number): Observable<ResponseModel<CurrentSituation>> {
    return this._http.get<ResponseModel<CurrentSituation>>(`${this._hrmRoute}/Planning/GetCurrentSituation?CompanyId=${this.authInfo.getCompanyId()}&planningHeaderId=${planningHeaderId}&type=0`);
  }

  getFilteredCurrentSituationRecords(filterObject: CurrentSituationFilter): Observable<ResponseModel<CurrentSituation>> {
    return this._http.get<ResponseModel<CurrentSituation>>
      (`${this._hrmRoute}/Planning/GetCurrentSituation?planningHeaderId=${filterObject.planningHeaderId}&CompanyId=${filterObject.companyId}&departmentId=${filterObject.departmentId}&occupationalGroupId=${filterObject.ocupationalGroupId}&positionId=${filterObject.positionId}&type=1`);
  }
  //#endregion


  saveNewPositionJson(body: NewPositionEntities[]): Observable<RequestMetaData> {
    return this._http.post<RequestMetaData>(`${this._hrmRoute}/NewPosition/PostJson`, body)
  }
  //#endregion

  //#region Dropdowns data

  getDepartments(): Observable<ResponseModel<Departments>> {
    return this._http.get<ResponseModel<Departments>>(this._coreRoute + `/CompanyStructure?CompanyId=${this.authInfo.getCompanyId()}&IdDepartment=0`)
  }

  getEmployeesPositions(): Observable<ResponseModel<Positions>> {
    return this._http.get<ResponseModel<Positions>>(this._coreRoute + `/Positions?PositionId=-1&companyId=${this.authInfo.getCompanyId()}`)
  }

  //#endregion

  getBudgetApprovation(budgetAccountId?: number): Observable<ResponseModel<BudgetApprovation>> {
    return this._http.get<ResponseModel<BudgetApprovation>>(`${this._coreRoute}/BudgetAccount?BudgetAccountId=${budgetAccountId ?? 0}&CompanyId=${this.authInfo.getCompanyId()}&Status=1`);
  }

  getPeriod(): Observable<any> {
    return this._http.get<any>(`${this._hrmRoute}/Planning/GetYearsPlanning?CompanyId=${this.authInfo.getCompanyId()}`);
  }

  setPeriod(year: number): Observable<any> {
    return this._http.get<any>(`${this._hrmRoute}/Planning?Type=1&Year=${year}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }

  getPlanningHeader() {
    return this._http.get<ResponseModel<PlanningHeader>>(`${this._hrmRoute}/PlanningHeader?PlanningHeaderId=0&CompanyId=${this.authInfo.getCompanyId()}&Status=true&Type=1`);
  }
  getPlanningHeaders() {
    return this._http.get<ResponseModel<PlanningHeader>>(`${this._hrmRoute}/PlanningHeader?PlanningHeaderId=0&CompanyId=${this.authInfo.getCompanyId()}&Status=true&Type=2`);
  }
  getCurrentHeader() {
    return this._http.get<ResponseModel<PlanningHeader>>(`${this._hrmRoute}/PlanningHeader?PlanningHeaderId=0&CompanyId=${this.authInfo.getCompanyId()}&Status=true&Type=4`);
  }

  finishPlanning(changeConditionPlanning: ChangeConditionPlanning) {
    return this._http.post<ResponseModel<any>>(`${this._hrmRoute}/PlanningHeader/FinishPlanning`, changeConditionPlanning);
  }

  sendPlanning(changeConditionPlanning: ChangeConditionPlanning) {
    return this._http.post<ResponseModel<any>>(`${this._hrmRoute}/PlanningHeader/SendPlanning`, changeConditionPlanning);
  }

  approvePlanningByRRHH(changeConditionPlanning: ChangeConditionPlanning) {
    return this._http.post<ResponseModel<any>>(`${this._hrmRoute}/PlanningHeader/ApprovePlanningByRRHH`, changeConditionPlanning);
  }

  openPlanning(planningDTO: PostPlanningHeader) {
    return this._http.post<ResponseModel<any>>(`${this._hrmRoute}/Planning`, planningDTO)
  }

  getPositions(headerId:number){
    return this._http.get<any>(`${this._hrmRoute}/Planning/GetCurrentPosition?planningHeaderId=${headerId}&CompanyId=${this.authInfo.getCompanyId()}&type=0`);
  }

  savePlaces(place:PlanningPlaces){
    return this._http.post(`${this._hrmRoute}/PlanningPlaceRequest`, place)
  }

  getPlaces(headerId:number, positionId:number){
    return this._http.get(`${this._hrmRoute}/PlanningPlaceRequest?planningHeaderId=${headerId}&positionId=${positionId}&type=1`)
  }

  getPlaces_Positions(planningHeaderId){
    return this._http.get(`${this._hrmRoute}/PlanningPlaceRequest?planningHeaderId=${planningHeaderId}&companyId=${this.authInfo.getCompanyId()}&status=true&type=1`)
  }

  createRequest(request:RequestPositionPlaces){
    return this._http.post(`${this._hrmRoute}/PlanningPlaceRequest`, request)
  }

  updateRequest(request:RequestPositionPlaces){
    return this._http.put(`${this._hrmRoute}/PlanningPlaceRequest`, request)
  }
  sendRequest(sendRequestBody:any){
    return this._http.post(`${this._hrmRoute}/PlanningPlaceRequest/Send`, sendRequestBody)
  }

  deletePlace(data:any){
    return this._http.put(`${this._hrmRoute}/PlanningPlaceRequest/DeletePlanningPlaceRequest`, data)
  }

  getPlanningSummary(headerId:number){
    return this._http.get(`${this._hrmRoute}/Planning/GetPlanningOverallSummary?planningHeaderId=${headerId}&CompanyId=${this.authInfo.getCompanyId()}&type=0`)
  }

  getPlanningDetails(headerId:number){
    return this._http.get(`${this._hrmRoute}/Planning/GetCurrentSituationDetail?planningHeaderId=${headerId}&CompanyId=${this.authInfo.getCompanyId()}&type=1`)
  }

  getPlanningRejectionReason(planningId,companyId) {
    return this._http.get(`${this._hrmRoute}/PlanningRejectionReason?rejectionReasonId=0&PlanningHeaderId=${planningId}&Type=0&institutionId=${companyId}`)
  }

  getCurrentPlanning() {
    return this._http.get(`${this._hrmRoute}/PlanningHeader?&CompanyId=${this.authInfo.getCompanyId()}&Status=true&Type=4`)
  }
  getCurrentSitutationByHeaderId(headerId){
    return this._http.get(`${this._hrmRoute}/Planning/GetCurrentSituationDetail?planningHeaderId=${headerId}&type=0&companyId=${this.authInfo.getCompanyId()}`)
  }

  getPlanningPlaceRequestDetail(planningHeaderId: number){
    return this._http.get<ResponseModel<PlanningPlaceRequestDetail>>(`${this._hrmRoute}/PlanningPlaceRequest/Detail?planningHeaderId=${planningHeaderId}&companyId=${this.authInfo.getCompanyId()}&status=true&type=0`);
  }

  getPlanningHeaderById(headerId){
    return this._http.get(`${this._hrmRoute}/PlanningHeader?PlanningHeaderId=${headerId}&CompanyId=${this.authInfo.getCompanyId()}&Status=true&Type=2`)
  }

  getCurrentPlanningHeaderGenerated() {
    return this._http.get<ResponseModel<PlanningHeader>>(`${this._hrmRoute}/PlanningHeader?PlanningHeaderId=0&CompanyId=${this.authInfo.getCompanyId()}&Status=true&Type=5`);
  }

  getPlanningPlaceRequestByPlanning(planningHeaderId: number){
    return this._http.get<ResponseModel<any>>(`${this._hrmRoute}/PlanningPlaceRequest?planningPlaceRequestId=0&planningHeaderId=${planningHeaderId}&departmentId=0&positionId=0&companyId=${this.authInfo.getCompanyId()}&status=true&type=6`);
  }

  GetGeneratePlanning(planningHeaderId: number) {
    return this._http.get<any>(`${this._hrmRoute}/PlanningPlaceRequest?planningPlaceRequestId=0&planningHeaderId=${planningHeaderId}&departmentId=0&positionId=0&companyId=${this.authInfo.getCompanyId()}&status=true&type=5`)
  }

  GetRejectedRequests(planningHeaderId: number) {
    return this._http.get<any>(`${this._hrmRoute}/RejectPlanningPlacesRequest?PlanningPlaceRequestId=${planningHeaderId}&Type=2&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }

  deleteGeneratePlanning(planningHeaderId:number){
    return this._http.delete(`${this._hrmRoute}/Planning/PlanningGenerate?planningHeaderId=${planningHeaderId}&companyId=${this.authInfo.getCompanyId()}&modifyUserId=${this.authInfo.getUserId()}`)
  }

  planningCheck(planningHeaderId: number){
    return this._http.delete<ResponseModel<any>>(`${this._hrmRoute}/Planning/PlanningCheck?planningHeaderId=${planningHeaderId}&companyId=${this.authInfo.getCompanyId()}&modifyUserId=${this.authInfo.getUserId()}`);
  }

  getPlanningStatistic(planningHeaderId: number){
    return this._http.get<ResponseModel<PlanningPlacePositionStatistic>>(`${this._hrmRoute}/Planning/PlanningStatistic?planningHeaderId=${planningHeaderId}&companyId=${this.authInfo.getCompanyId()}`);
  }

  getPlanningApprove(PlanningApproveId: number){
    return this._http.get<ResponseModel<PlanningApprove>>(`${this._hrmRoute}/PlanningApprove?PlanningApproveId=${PlanningApproveId}&Type=2&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }

  postPlanningApprove(planningApprove: PlanningApprove){
    return this._http.post<ResponseModel<any>>(`${this._hrmRoute}/PlanningApprove`, planningApprove);
  }

  getPlanningApproveSettingByPlanningHeaderId(PlanningApproveSettingId: number = 0){
    return this._http.get<ResponseModel<PlanningApproveSetting>>(`${this._hrmRoute}/PlanningApproveSetting?PlanningApproveSettingId=${PlanningApproveSettingId}&type=2&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }

  planningRejectionReason(planningRejectionReason: PlanningRejectionReason[]){
    return this._http.post<ResponseModel<any>>(`${this._hrmRoute}/PlanningRejectionReason`, planningRejectionReason);
  }

  getPlanningHeaderRejectionReason(planningHeaderId: number){
    return this._http.get<ResponseModel<PlanningRejectionReason>>(`${this._hrmRoute}/PlanningRejectionReason?PlanningHeaderId=${planningHeaderId}&Type=0`);
  }

  getPlacesPositions(planningHeaderId: number) {
    return this._http.get<ResponseModel<PlanningRequest>>(`${this._hrmRoute}/PlanningPlaceRequest?planningPlaceRequestId=0&planningHeaderId=${planningHeaderId}&departmentId=0&positionId=0&companyId=${this.authInfo.getCompanyId()}&status=true&type=6`);
  }

  getPlanningHeaderApproved() {
    return this._http.get<ResponseModel<PlanningHeader>>(`${this._hrmRoute}/PlanningHeader?PlanningHeaderId=0&CompanyId=${this.authInfo.getCompanyId()}&Status=true&Type=7`);
  }

  getPlanningHeaderAccount(planningheaderId) {
    return this._http.get<ResponseModel<PlanningAccountDto>>(`${this._hrmRoute}/PlanningAccounts?PlanningAccountId=0&PlanningHeaderId=${planningheaderId}&CompanyId=${this.authInfo.getCompanyId()}&Status=true&Type=2`);
  }

  postPlanningAccount(planningaccountdto){
     return this._http.post(`${this._hrmRoute}/PlanningAccounts`, planningaccountdto);
  }

  putPlanningAccount(planningaccountdto){
    return this._http.put(`${this._hrmRoute}/PlanningAccounts`, planningaccountdto);
  }

  deletePlanningAccount(planningaccountid:number){
    return this._http.delete(`${this._hrmRoute}/PlanningAccounts?PlanningAccountId=${planningaccountid}&UserId=${this.authInfo.getUserId()}`);
  }

  dashboard(PlanningHeaderId: number, type = 0,  CompanyId: number = this.authInfo.getCompanyId()){
    return this._http.get<ResponseModel<PlanningDashboard>>(`${this._hrmRoute}/PlanningHeader/Dashboard?PlanningHeaderId=${PlanningHeaderId}&CompanyId=${CompanyId}&Status=true&Type=${type}`);
  }

  getSummaryCurrentSituation(PlanningHeaderId: number){
    return this._http.get<ResponseModel<PlanningDashboardQuantities>>(`${this._hrmRoute}/PlanningHeader/SummaryCurrentSituation?PlanningHeaderId=${PlanningHeaderId}&CompanyId=${this.authInfo.getCompanyId()}`);
  }

  getConfigSurplusPercentage(YearPlanning: number){
    return this._http.get<ResponseModel<ConfigSurplusPercentage>>(`${this._hrmRoute}/ConfigSurplusPercentage?YearPlanning=${YearPlanning}&CompanyId=${this.authInfo.getCompanyId()}&Status=true&Type=1`);
  }

}
