import { DatePipe, formatDate } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexStates,
  ApexTheme,
  ApexChart,
  ApexDataLabels,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexFill,
  ApexLegend,
  ChartComponent,
  ApexAxisChartSeries,
  ApexXAxis,
  ApexYAxis,
  ApexTooltip
} from "ng-apexcharts";
import { Subscription } from 'rxjs';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { InterviewDashboardService } from 'src/app/services/interview-dashboard.service';
import { IDashboardOptions } from 'src/app/services/vacancies-list-dashboard.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
import { BarOptions, ChartOptions } from 'src/app/shared/models/apexChartModels';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { IInterviewDashboardData } from './InterviewDashboard.models';

declare const $: any;


@Component({
  selector: 'app-interview-dashboard',
  templateUrl: './interview-dashboard.component.html',
  styleUrls: ['./interview-dashboard.component.css']
})
export class InterviewDashboardComponent implements OnInit, AfterViewChecked, OnDestroy {
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  amountTotal = 0;
  interviewByDepartment: any = []
  genders: any = []
  academicLevel: any = []
  filterParams = {
    year: null,
    date2: this.datePipe.transform(new Date(), 'MM-dd-yyyy'),
    date1: `${this.authInfo.getConstitutionDate()}`,
    companyId: this.authInfo.getCompanyId()
  }
  filtered = false;
  loadingMessage = 'Cargando...'
  noDataMessage = 'No hay datos'
  genderChart = {
    series: [],
    labels: [],
    message: this.loadingMessage
  }
  academicChart = {
    series: [],
    labels: [],
    message: this.loadingMessage
  }

  public departmentMessage = this.loadingMessage;

  public chartOptions: Partial<ChartOptions>;
  public barOptions: Partial<BarOptions>;

  suscriptions = new Subscription()

  departments: any[] = [];
  department: any[] = [];

  constructor(
    private _toastService: ToastService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<InterviewDashboardComponent>,
    private _changeDet: ChangeDetectorRef,
    private srvInterviewDashboard: InterviewDashboardService,
    private datePipe: DatePipe, private authInfo: AuthInfoService) {
    this.getChartOptions()


  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FilterDashboardComponent, {
      data: { filterParams: this.filterParams },
    });
    let sub = dialogRef.afterClosed().subscribe((result: { year, date1, date2, applyFilter, companyId }) => {

      if (!result) return
      if (result?.applyFilter == false) {
        return
      }
      this.filtered = result.year != null || result.date1 != null || result.date2 != null;

      this.filterParams.year = result.year
      this.filterParams.date1 = result.date1
      this.filterParams.date2 = result.date2,
        this.filterParams.companyId = result.companyId

      this.getDashboardData()
    });
    this.suscriptions.add(sub)
  }


  dropdownConfig(displayKey, placeholder?) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  setLoadingMessage() {
    this.genderChart.message = this.loadingMessage
    this.departmentMessage = this.loadingMessage
    this.academicChart.message = this.loadingMessage
  }

  getDashboardData() {
    this.barOptions.series = []
    this.academicChart.series = []
    this.academicChart.labels = []
    this.genderChart.series = []
    this.genderChart.labels = []
    this.setLoadingMessage()
    this.srvInterviewDashboard.getInterviewDashboard(this.getOptions().dateFrom, this.getOptions().dateTo, this.filterParams.companyId).subscribe((res: IInterviewDashboardData) => {
      if (res.succeded) {
        let d = res.singleData;
        this.amountTotal = d.interviewsTotalQuantities[0]?.value ?? 0
        let genders = d.interviewsByGenderQuantities.filter(x => x.description != null)
        if (genders.length < 1) {
          this.genderChart.message = this.noDataMessage
        } else {
          genders.forEach(e => {
            this.genderChart.labels.push(e.description)
            this.genderChart.series.push(e.value)
          })
        }
        let academicLevel = d.interviewsByAcademicLevelQuantities
        if (academicLevel.length < 1) {
          this.academicChart.message = this.noDataMessage
        } else {
          academicLevel.forEach(e => {
            this.academicChart.labels.push(e.description ?? "No definido")
            this.academicChart.series.push(e.value)
          })
        }
        let interviewByDepartment = d.interviewsByDepartmentsQuantities.filter(x => x.description != null);
        this.departments = [...interviewByDepartment];
        this.department = [...this.departments.slice(0, 10)];

        this.setDataDeparment([...this.department]);

      } else {
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error(this.errorMessage)
    })
  }
  clear() {
    this.filtered = false;
    this.filterParams = {
      date1: null,
      date2: null,
      year: null,
      companyId: this.authInfo.getCompanyId()
    }
    this.getDashboardData()
  }

  changeDepartment() {
    this.setDataDeparment([]);

    if (this.department.length < 1) {
      this.setDataDeparment([]);
      return
    }
    let data: any[] = []
    this.department.forEach(e => {
      let chartData: any = this.departments.find(x => x.description2 == e.description2)
      if (!chartData) {
        chartData = this.departments.find(x => x.description2 == e.description2) ?? { rowText: e.description2, value1: 0, value2: 0 }
      }
      let cData = Object.assign({}, chartData)
      data.push(cData)
    });
    this.setDataDeparment([...data]);
  }


  setDataDeparment(deparments: any[]) {
    let data2 = []
    deparments.forEach(e => {
      data2.push(
        {
          x: e.description2,
          y: e.value,
          z: e.description
        }
      )
    })
    this.barOptions.xaxis.categories = []
    this.barOptions.plotOptions = {
      bar: {
        dataLabels: {
          position: "top" // top, center, bottom
        }
      }
    };
    this.barOptions.dataLabels = {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"]
      }
    };

    this.barOptions.series = [{
      data: data2
    }]
  }

  ngOnInit(): void {
    try {
      this.filterParams.date1 = this.datePipe.transform(`${this.authInfo.getConstitutionDate()}`, 'yyyy-MM-dd')
      this.filterParams.date2 = this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd')
    } catch (e) {

    }

    //this.getChart()
    this.getDashboardData()
    this.getChartOptions();
  }


  getOptions(): IDashboardOptions {
    return {
      dateFrom: this.filterParams.date1 ? formatDate(this.filterParams.date1, 'MM-dd-yyyy', 'en') : null,
      dateTo: this.filterParams.date2 ? formatDate(this.filterParams.date2, 'MM-dd-yyyy', 'en') : null,
      companyId: this.authInfo.getCompanyId()
    }
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }



  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
    this.suscriptions.unsubscribe()
  }
  getChartOptions() {
    this.chartOptions = {
      series: [],
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold',
          colors: ['#ffffff', '#ffffff', '#ffffff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },

      stroke: {
        width: 0
      },
      title: {
        text: ""
      },
      labels: [],
      legend: {
        show: true,
        position: 'bottom'
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
              position: 'bottom',
            }
          }
        }
      ]
    };
    this.barOptions = {
      series: [
        {
          name: "Cantidad de entrevistas",
          data: [0, 0, 0],
        }
      ],
      chart: {
        type: "bar",
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "30%",
          distributed: true
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
        ],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: ""
        },
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        },
        z: {
          formatter: undefined,
          title: ''
        }
      }
    };
  }

}
