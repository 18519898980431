import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ReportService } from 'src/app/services/report.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
declare function applySort(): any;

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css']
})
export class ReportListComponent implements OnInit {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'RRHH-REPORTS',
    export: {key: 'Export', value: false},
    search: {key: 'Search', value: false}
  }
  selectedItems: any;
  @ViewChild('paginator') paginator: CaptionComponent;

  filterText: string = '';
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "reportId", displayName: "Secuencia" },
      { propkey: "reportName", displayName: "Nombre del Reporte" },
    ];
    reports: any[] = [];
  reportsOriginalList: any[] = [];
  paginatedReports: any[] = [];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' }
  ];
  localStorageSearch:string = 'app-report-list';

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  constructor(
    private toastr: ToastrService,
    private report: ReportService,
    private authInfo: AuthInfoService,
  ) { }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if(result == true){
        this.getReports()
        this.privilege = this.authInfo.setPrivileges(this.privilege)
      }
    });
    applySort();
  }

  getReports(){
    this.report.getReports().subscribe((res:any) => {
      if(res.succeded = false){
        this.toastr.error(res.errors[0])
        return
      }

      this.reports = res.dataList;
      this.reportsOriginalList =  res.dataList;
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }
  filter() {
    document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.reports = this.filterTable([...this.reportsOriginalList]);
    }
  }
  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  viewReport(report){
    const { reportUrl } = report
    window.open(reportUrl,
    '_blank',
    "toolbar=yes,scrollbars=yes,resizable=yes,top=10,left=10,width=1024,height=800");
    return
  }
  onBtnPrintClick(){
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }
  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl?.find(x => x.item_id == 1) && !sl?.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl?.find(x => x.item_id == 2) && !sl?.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    return list;
  }
  getPaginatedRecords(event) {
    this.paginatedReports = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  onKeyChange(text) {
    this.filterText = text;
  }
  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
    this.filteredList.records = this.filterTable(event.records)
  }
}
