import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import Dashboard from '@uppy/dashboard'
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import Mexico from '@uppy/locales/lib/es_MX'
import { environment } from 'src/environments/environment';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { personalRequiredFilesService } from 'src/app/services/personalRequiredFiles.service';
import { FileResponse, PersonalGuidEntity, personRequiredFiles } from 'src/app/shared/models/candidate-registration.model';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CandidateRegistrationComponent } from 'src/app/pages/candidate-registration/candidate-registration.component';
import { docService } from 'src/app/services/doc.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import Swal from 'sweetalert2';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-required-files',
  templateUrl: './required-files.component.html',
  styleUrls: ['./required-files.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RequiredFilesComponent implements OnInit {
  private readonly urlDoc = environment.api_doc + '/Document';
  public SystemLoad: number = 1;
  public ModuleLoad: number = 1;
  public routeFile: string = "";
  public data = {} as personRequiredFiles
  @Input() personId = 0;
  @Output() changeRequiredFiles: EventEmitter<boolean> = new EventEmitter();
  public identificationImage = '';
  imgDefaultPdf: string = 'assets/images/ver_file.jpg';
  personalInfo ={
    identificationImage: null,
    medicalCertificate: null,
    driveLicenseImage: null,
    miscellaneous: null,
    driveLicense:null
  };
  driveLicenseImage: any;
  miscellaneous: any;
  public file = {
    photo: null,
    cv: null,
    criminalRecord: null,
    identificationImage: null,
    medicalCertificate: null,
    driveLicenseImage: null,
    miscellaneous: null
  }


  @ViewChildren('uppyFiles')
  uppyFileComponent: QueryList<UppyFileComponent> | null = null;

  constructor(
    private srvParameter: ParameterControlService,
    public srvPersonalReqFiles: personalRequiredFilesService,
    private srvDoc: docService,
    private route: ActivatedRoute,
    private toast: ToastService,
    private sanitizer: DomSanitizer,
    public personService: PersonService, private cdr: ChangeDetectorRef,
    private authInfo: AuthInfoService) {
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  changeDetector() {
    this.cdr.detectChanges();
    // if (this.file.identificationImage && this.file.criminalRecord && this.file.medicalCertificate) {
    //   this.changeRequiredFiles.emit(true)
    // }
    // else{
    //   this.changeRequiredFiles.emit(false)
    // }

  }


  ngOnInit(): void {
    // if (!this.file.identificationImage || !this.file.criminalRecord || !this.file.medicalCertificate) {
    //   this.changeRequiredFiles.emit(false)
    // }

    this.srvParameter.getParameters(parameterDoc.wizard).subscribe(e => {
      if (e.succeded) {
        if (e.dataList.length > 0) {
          this.routeFile = e.dataList[0].stringData.toString();
          this.changeDetector();
        }
      } else {
        this.toast.error(e.errors[0]);
        return
      }

      if (this.personId > 0) {
        this.personService.getPersonById(this.personId).subscribe((res: any) => {
          if (res.succeded) {
            this.personalInfo = res.dataList[0]
            this.identificationImage = this.personalInfo?.identificationImage
            this.driveLicenseImage = this.personalInfo?.driveLicenseImage
            this.miscellaneous = this.personalInfo?.miscellaneous
            this.changeDetector();
          } else {
            this.toast.error(res.errors[0])
          }
        }, error => {

        })
        this.srvPersonalReqFiles.get(this.personId).subscribe(e => {
          if (e.succeded && e.dataList.length > 0) {
            this.data = e.dataList[0]
            this.changeDetector();
            var dataReq: PersonalGuidEntity[] = [];
            let photo = { guidName: this.data.photo } as PersonalGuidEntity
            let cv = { guidName: this.data.cv } as PersonalGuidEntity
            let cr = { guidName: this.data.criminalRecord } as PersonalGuidEntity
            let mc = { guidName: this.data.medicalCertificate } as PersonalGuidEntity



            if (photo.guidName != null) dataReq.push(photo)
            if (cv.guidName != null) dataReq.push(cv)
            if (cr.guidName != null) dataReq.push(cr)
            if (mc.guidName != null) dataReq.push(mc)


          } else {
            this.data.erfid = 0;
            this.data.personId = this.personId
            this.data.companyId = this.authInfo.getCompanyId();
            this.data.status = true;
          }


        })
      } else {
        this.data.companyId = this.authInfo.getCompanyId()    

      }
    })
  }
  
  save() {
    try {
      if (this.personId <= 0) {
        this.data.personId = this.personId
      }

      this.data.personId = this.personId;

      if (this.data.erfid == null || this.data.erfid == undefined) {
        this.data.erfid = 0;
      }
      this.data.status = true;

      this.srvPersonalReqFiles.save(this.data).subscribe(e => {
        if (this.data.erfid == 0 || this.data.erfid == undefined) {
          this.data.erfid = e.entityId
        }
        this.toast.success('Registro Guardado')
        this.changeDetector();
      }, error => {
        this.toast.error(error)
      })
    } catch (error) {
      this.toast.error(error);
    }
  }

  setGuid(personalFile): any {
    try {
      return new Promise(resolve => {
        this.srvDoc.getGuidName(this.data.companyId, this.SystemLoad, this.ModuleLoad, personalFile).subscribe(e => {
          resolve(e.data);
        }, error => {
          this.toast.error(error)
        })
      });
    } catch (error) {
      this.toast.error(error);
    }
  }
  setPersonId() {
    if (this.personId <= 0) {
      this.personId = this.personId
    }
  }

  delete(type, guid) {
    try {
      if (type == 1) { //photo2x2
        this.file.photo = '';
        this.data.photo = null
      }
      else if (type == 2) { //curriculumnVitae
        this.file.cv = '';
        this.data.cv = null
      }
      else if (type == 3) { //criminalRecord
        this.file.criminalRecord = '';
        this.data.criminalRecord = null
      }
      else if (type == 4) {  //medicalCertificate
        this.file.medicalCertificate = '';
        this.data.medicalCertificate = null
      }

      this.srvPersonalReqFiles.update(this.data).subscribe(e => {
        this.srvDoc.deleteFile(guid).subscribe(res => {
          if (res.data == true) {
            this.toast.success('Archivo borrado')
            this.changeDetector();
          } else {
          }
        }, error => {

        })
      })
    } catch (error) {
      this.toast.error(error);
    }

  }

  deleteIdentificationImage() {

    Swal.fire({
      icon: 'question',
      title: 'Borrar Archivo',
      text: '¿Está seguro de eliminar este archivo?',
      showCancelButton: true,
      confirmButtonColor: '#e63946',
      confirmButtonText: 'Sí, Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.srvDoc.deleteFile(this.file?.identificationImage ? this.file?.identificationImage : this.personalInfo?.identificationImage).subscribe(e => {
          this.personalInfo = {
            ...this.personalInfo,
            identificationImage: ''
          }
          this.file.identificationImage = ''
          this.identificationImage = null;
          this.personService.deletePersonIdentificationImage(this.personId).subscribe(e => {
            this.toast.success('Documento borrado', '')
            this.changeDetector();
          }, error => {
          })
        }, error => {
          this.toast.error(error);
        });

      }
    })
  }

  deleteDriveLicense() {
    Swal.fire({
      icon: 'question',
      title: 'Borrar Archivo',
      text: '¿Está seguro de eliminar este archivo?',
      showCancelButton: true,
      confirmButtonColor: '#e63946',
      confirmButtonText: 'Sí, Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.srvDoc.deleteFile(this.file.driveLicenseImage ? this.file.driveLicenseImage : this.personalInfo.driveLicense).subscribe(e => {
          this.personalInfo = {
            ...this.personalInfo,
            driveLicenseImage: ''
          }
          this.file.driveLicenseImage = ''
          this.driveLicenseImage = null;
          this.personService.deleteDriveLicenseImage(this.personId).subscribe(e => {
            this.toast.success('Documento borrado', '')
            this.changeDetector();
          }, error => {
          })
        }, error => {
          this.toast.error(error);
        })
      }
    })
  }

  deleteMiscellaneous() {
    Swal.fire({
      icon: 'question',
      title: 'Borrar Archivo',
      text: '¿Está seguro de eliminar este archivo?',
      showCancelButton: true,
      confirmButtonColor: '#e63946',
      confirmButtonText: 'Sí, Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.srvDoc.deleteFile(this.file.miscellaneous ? this.file.miscellaneous : this.personalInfo.miscellaneous).subscribe(e => {
          this.personalInfo.miscellaneous = ''
          this.file.miscellaneous = ''
          this.miscellaneous = null;
          this.personService.deleteMiscellaneousImage(this.personId).subscribe(e => {
            this.toast.success('Documento borrado', '')
            this.changeDetector();
          }, error => {
          })
        }, error => {
          this.toast.error(error);
        })
      }
    })
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  get extensionIdentificationImage() {
    const extension = this.file.identificationImage?.includes('.pdf');
    return extension;
  }

  get extensionLicense() {
    const extension = this.file.driveLicenseImage?.includes('.pdf');
    return extension;
  }

  uploadFile(formData: FormData) {
    let data = {
      SystemLoad: this.SystemLoad,
      ModuleLoad: this.ModuleLoad,
      personalFile: personalFile.others,
      routeFile: this.routeFile
    }
    return this.srvDoc.createFile(data, formData).toPromise();
  }

  existFileToDelete(fileId) {
    let existedFile = this.uppyFileComponent.toArray().find(file => file?.fileToDelete === fileId)
    return existedFile
  }

  deleteFile(file) {
    return this.srvDoc.deleteFile(file?.fileToDelete).toPromise();
  }

  setFormFile(file: FormData, index) {

    switch (index) {
      case 0:
        this.file.identificationImage = file;
        break;
      case 1:
        this.file.photo = file;
        break;
      case 2:
        this.file.cv = file;
        break;
      case 3:
        this.file.criminalRecord = file;
        break;
      case 4:
        this.file.medicalCertificate = file;
        break;
      case 5:
        this.file.driveLicenseImage = file;
        break;
      case 6:
        this.file.miscellaneous = file;
        break;

      default:
        break;
    }
  }

}
