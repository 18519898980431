import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { HttpClientService } from 'src/app/services/http-client/http-client.service';
import { PositionService } from 'src/app/services/position.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { PositionListDescriptionComponent } from './position-list-description/position-list-description.component';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
declare function applySort(): any;

@Component({
  selector: 'positions-list-description',
  templateUrl: './positions-list-description.component.html',
  styleUrls: ['./positions-list-description.component.css']
})
export class PositionsListDescriptionComponent implements OnInit, AfterViewChecked {
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'POSITION-LIST-DESCRIPTION',
    editAction: {key: 'EDIT', value: false},
    viewAction: {key: 'VIEW', value: false},
    exportAction: {key: 'EXPORT', value: false},
    searchAction: {key: 'SEARCH', value: false},
    printAction: {key: 'Print', value: false}
  }

  localStorageSearch:string ='positions-list-description';

  records: any[] = [];
  filtervalues:any
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  showInactives = false;
  companies: any[] = []
  companyId: number
  currentCompany:any
  registersPerPage:any
  selectedPage:any
  options: any[] = [];
  @ViewChild('paginator') paginator: CaptionComponent;
  status: { name: string, value: boolean }[] = [
    { name: 'Activo', value: true },
    { name: 'Inactivo', value: false },
  ];
  modal: { title: string, action: string; } = { title: 'Agregar', action: 'add' };

  searchBarDisplayNames: {propKeyId:any,  propkey?: string, displayName: string }[] =
    [
      {propKeyId: 1, propkey: "positionId", displayName: "Secuencia" },
      {propKeyId: 2, propkey: "positionName", displayName: "Cargo" },
      {propKeyId: 6, propkey: "occupationalGroupDescription", displayName: "Grupo Ocupacional" }
    ];

  filterText: string = '';
  selectedItems: any[] = [];
  dataOriginalList: any[] = [];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' },
    { item_id: 3, item_text: 'No Enviado' },
    { item_id: 4, item_text: 'Solicitado' },
    { item_id: 5, item_text: 'Aprobado' },
    { item_id: 6, item_text: 'Rechazado' }
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  dataList: any[] = [];
  reportUrl = environment.reportUrl

  constructor(
    private _http: HttpClientService,
    private _cdRef: ChangeDetectorRef,
    private _builder: FormBuilder,
    private _toastr: ToastrService,
    private srvExportToExcel: ExportToExcelService,
    private positionService: PositionService,
    public authInfo: AuthInfoService,
    private dialog: MatDialog,
    private _changeDet: ChangeDetectorRef,
  ) { }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  setPrivileges(){
    this.privilege = this.authInfo.setPrivileges(this.privilege);
  }

  ngOnInit(): void {
    this.setPrivileges();
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.getCompanies();
      }
    });
    applySort();
  }

  getCompanies() {
    this.positionService.getCompanies().subscribe({
      next: (res: any) => {
        this.companies = res.dataList?.filter(d => d?.status);
        this.currentCompany = this.companies?.find((company:any)=> company.companyId == this.authInfo.getCompanyId())
        this.getPositionList()
      },
      error: err => this._toastr.error('Ocurrió un error al obtener el listado de instituciones')
    })
  }


  onKeyChange(text) {
    this.filterText = text;
  }

  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Click para desplegar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  getPositionList() {
    this.companyId = this.authInfo.getCompanyId();
    if (!this.companyId) {
      this.dataList = [];
      this.dataOriginalList = [];
      return;
    }
    this.positionService.getPositionsByCompany(this.companyId).subscribe((data: any) => {
      if (data.succeded) {
        this.dataList = this.filterTable(data.dataList);
        this.dataOriginalList = data.dataList;
      } else {
        this._toastr.error(data.errors[0]);
      }
    }, error => {
      this._toastr.error('Ocurrió un error al cargar la lista de posiciones, por favor intente más tarde o comuníquese con HelpDesk.');
    });
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    //document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.dataList = this.filterTable(this.dataOriginalList)
    }
    this.setFilterToLocalStorage();
  }
  setFilterToLocalStorage() {
    localStorage.setItem('postions-list_filter', JSON.stringify(this.selectedItems));
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl.find(x => x.item_id == 1) && !sl.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl.find(x => x.item_id == 2) && !sl.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    const conditions = [...this.getConditionsSeleted];
    if (conditions?.length > 0) {
      return list?.filter(l => conditions?.some(condition => condition === l?.conditionId));
    }
    return list;
  }

  private get getConditionsSeleted() {
    const sl: number[] = this.selectedItems?.map(s => s?.item_id);
    const conditions: number[] = [];
    if (sl?.find(x => x === 3)) conditions.push(4);
    if (sl?.find(x => x === 4)) conditions.push(1);
    if (sl?.find(x => x === 5)) conditions.push(2);
    if (sl?.find(x => x === 6)) conditions.push(3);
    return conditions;
  }

  exportToExcell() {
    let excelHeaders: string[][] = [[
      "Secuencia del Cargo",
      "Cargo",
      "Grupo Ocupacional",
      "Condición",
      "Estatus",
    ]]
    let positionList: any[] = [];
    let array = this.dataList
    if (this.filteredList.enable) {
      array = this.filteredList?.records
    }
    array.forEach((e) => {
      positionList.push({
        positionId: e.positionId,
        positionName: e.positionName,
        occupationalGroup: e.occupationalGroup,
        conditionId: e.condition,
        status: e.status ? "Activo" : "Inactivo",
      })
    })
    this.srvExportToExcel.exportToExcelSpecificColumns(positionList, excelHeaders, 'Lista de Cargos');
  }

  getPaginatedRecords(event) {
    this.records = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.registersPerPage = event?.registersPerPage;
    this.selectedPage = event?.selectedPage;

  }
  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = event.records
    this.paginator.selectedPage = 1;
  }

  openModalEdit(item: any, IsView = false, isEdit = false) {
    this.dialog.open(PositionListDescriptionComponent, {
      data: {
        position: item,
        edit: isEdit,
        view: IsView
      }
    }).afterClosed().subscribe({
      next: (modal: { Applied: boolean }) => {
        if (modal?.Applied) {
          this.getPositionList();
        }
      }
    })
  }

  openReport(positionId:number, companyId:number) {
    const reportUrl = `${this.reportUrl}/?ReportCode=rrhh_positions&CompanyId=${companyId}&PositionId=${positionId}`
    let parameters = {
      url: reportUrl,
      title: 'Reporte Descripción de Cargo',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }


  onBtnPrintClick(){
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

  onKeyParameterChange(event){
    this.filtervalues = event;
}


  openPositionReport() {

    const conditions = [...this.getConditionsSeleted];
    let condition:any = '';
    conditions.forEach((resp, index )=> {
      condition += resp
      let resta = conditions.length - index;
      if(resta !== 1){
        condition += '-';
      }
    })

    let statusArray:any[] = [];
    this.selectedItems.forEach(resp => {
        if(resp.item_id == 1 || resp.item_id ==2 ){
           statusArray.push(resp)
        }
    })


    const companyId: number = this.authInfo.getCompanyId();
    const status: any = statusArray[0]?.item_id == 1 && statusArray?.length <= 1 ? 1 : statusArray[0]?.item_id == 2 && statusArray?.length <= 1  ? 0 : statusArray[0]?.item_id == 1 && statusArray[1]?.item_id == 1 ? -1 : -1;
    const reportCode: ReportCode = ReportCode.PositionListDescription;
    const PropkeyId: any = this.filtervalues?.propKeyId ?? 0
    const SearchParameter: any = this.filterText ?? ''
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&&ConditionId=${condition}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Status=${status}&Page=${this.selectedPage}&PageSize=${this.registersPerPage}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Descripción de Cargos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

}
