import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
interface chart { description: string, value: number }
@Component({
  selector: 'app-dashboard-hourly-teachers',
  templateUrl: './dashboard-hourly-teachers.component.html',
  styleUrls: ['./dashboard-hourly-teachers.component.css']
})
export class DashboardHourlyTeachersComponent implements OnInit {
  datePipe = new DatePipe('en-Us')

  filterParams = {
    year: null,
    date2: moment().endOf('year').format('YYYY-MM-DD'),
    date1: this.datePipe.transform(`${this.authInfo.getConstitutionDate()}`, 'yyyy-MM-dd'),
    companyId: this.authInfo.getCompanyId()
  }
  teachersByGenre: chart[] = []
  teachersByBranch: chart[] = []
  teachersByDepartment: chart[] = []
  teachersByPosition: chart[] = []

  constructor(
    private dialogRef: MatDialogRef<DashboardHourlyTeachersComponent>,
    private srvEmployee: EmployeesService,
    private dialog: MatDialog,
    private authInfo: AuthInfoService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  filterByDate(): void {
    this.dialog.open(FilterDashboardComponent, {
      data: { filterParams: this.filterParams }
    })
      .afterClosed()
      .subscribe((result) => {
        if(!result){
          return
        }
        if(result?.applyFilter == false){
          return
        }
        this.filterParams.year = result.year
        this.filterParams.date1 = result.date1
        this.filterParams.date2 = result.date2
        this.filterParams.companyId = result.companyId

        this.getData();
      });
  }

  getData(){
    const {date1, date2, companyId} = this.filterParams
    this.srvEmployee.getHourlyTeacherDashboard(date1, date2, companyId).subscribe({
      next: (res:any) => {
        if(res.errors.length > 0){this.toastr.error(res.errors[0]); return}
        if(res.warnings.length > 0){this.toastr.warning(res.errors[0]); return}
        this.teachersByBranch = res.singleData.teachersbyBranch ? res.singleData.teachersbyBranch : []
        this.teachersByGenre = res.singleData.teachersbyGender ? res.singleData.teachersbyGender : []
        this.teachersByDepartment = res.singleData.teachersbyDepartment ? res.singleData.teachersbyDepartment : []
        this.teachersByPosition = res.singleData.teachersbyPosition ? res.singleData.teachersbyPosition : []
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }


  clearFilter(): void {

    this.filterParams = {
      year: null,
      date2: moment().endOf('year').format('YYYY-MM-DD'),
      date1: moment().date(1).month(0).format('YYYY-MM-DD'),
      companyId: this.authInfo.getCompanyId()
    }

    this.getData();
  }


  close(success = false){
    this.dialogRef.close({success})
  }

}
