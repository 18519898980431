<div class="row">
    <div class="col-12 mb-5">
        <div class="p-5 bg-primary">
            <h2 class="text-white">Grupo de Conceptos</h2>
        </div>
    </div>
    <div class="col-sm-12 col-lg-6">
            <div class="card card-custom border border-primary">
                <div class="table-responsive">
                    <table class="table table-hover table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th>Código</th>
                                <th>Descripción</th>
                                <th class=" text-end">Comentario</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr data-row="0" class="datatable-row"
                                style="left: 0px;">
                                <td class="text-end"></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="mx-3">
                        <el-caption #paginator></el-caption>
                    </div>
                </div>
                
            </div>
    </div>
<!--     <div class="col-sm-12 col-lg-1">
        
    </div> -->
    <div class="col-sm-12 col-lg-6">
        <div class="card card-custom border border-primary">
            <div class="table-responsive">
                <table class="table table-hover table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th>Código</th>
                            <th>Descripción</th>
                            <th class=" text-end">Comentario</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr data-row="0" class="datatable-row"
                            style="left: 0px;">
                            <td class="text-end"></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <div class="mx-3">
                    <el-caption #paginator></el-caption>
                </div>
            </div>
            
        </div>
</div>
</div>

<!--div class="mat-dialog-actions mt-2 p-0 mb-0 text-right" [align]="'end'">
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div-->