import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RoyaltyDetailService } from 'src/app/services/royaltyDetails.service';

@Component({
  selector: 'app-data-validation-royalty-detail-modal',
  templateUrl: './data-validation-royalty-detail-modal.component.html',
  styleUrls: ['./data-validation-royalty-detail-modal.component.css']
})
export class DataValidationRoyaltyDetailModalComponent implements OnInit {

  dataList = []
  paginatedDataList = []
  conditionId = 0
  title = ''

  constructor(
    private dialogRef: MatDialogRef<DataValidationRoyaltyDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private royaltyDetailService: RoyaltyDetailService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.conditionId = this.data?.item.conditionId
    this.title = this.data?.item?.description
    this.getDataValidationPayrollNewsDetail()
  }

  close(){
    this.dialogRef.close()
  }

  getDataValidationPayrollNewsDetail(){
    this.royaltyDetailService.getDataValidationRoyaltyDetail(this.conditionId).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.warning(res.errors[0])
          return
        }
        this.dataList = res.dataList
        this.paginatedDataList = res.dataList
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  getPaginatedRecords(event) {
    this.paginatedDataList = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

}
