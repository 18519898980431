<div class="card card-custom">
    <div class="card-header">
        <div class="card-title flex-column align-items-start mt-3">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-usuario icon-3x text-danger mr-3"></i>
                <h1 class="text-primary"> Registro de Personas </h1>
            </div>
        </div>

        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard">
            <button (click)="openDialog()"
                *ngIf="privilege.dashboardAction.value"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </button>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between align-items-center">
        <div class="col-sm-12 col-md-6 c-left">
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-4 mr-2 pl-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Estatus
                        </label>
                        <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                            [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                            (onDeSelect)="onItemDeSelect($event)"
                            (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                    </div>
                </div>

                <button routerLink="/candidate-registration"
                    *ngIf="privilege.addAction.value"
                    class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                    <i class="flaticon-mas icon-2x" data-toggle="tooltip" title="Agregar nueva persona"
                        data-original-title="Agregar nueva persona"></i> Agregar
                </button>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="privilege.exportAction.value"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white"></i> Exportar
                    </button>
                    <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">

                        <a class="dropdown-item align-items-center" (click)="openReport()">
                            <i class="fa fa-print icon-2x text-primary mr-3"></i>
                            Visualizar Reporte
                        </a>
                    </div>
                </div>

                <div class="col text-nowrap">
                    <app-search-bar-pagination #searchBar [namePageLocalStorage]="localStorageSearch"
                    (onFilter)="filterSearch($event)"
                    [parameters]="searchBarDisplayNames">
                    </app-search-bar-pagination>
                </div>
            </div>
        </div>
    </div>

    <div kt-hidden-height="282" class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table id="" class="table table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="max-w-200px text-center"><span>Secuencia</span></th>
                        <th>Nombre</th>
                        <th >Tipo de Documento</th>
                        <th >Documento de Identidad</th>
                        <th class="text-center">Teléfono Primario</th>
                        <th class="text-center">Fecha de Nacimiento</th>
                        <th class="text-center">Fecha de Creación</th>
                        <th class="text-center">Estatus</th>
                        <th class="text-center" style="width: 100px">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let m of personList">
                        <td class="text-center">{{m.personId}}</td>
                        <td>
                            {{m.fullName}}
                        </td>
                        <td>
                            {{m.typeIdentification}}
                        </td>
                        <td *ngIf="m.typeIdentificationId == 1 || m.typeIdentificationId == 4 ">
                            {{m.personalIdentification | mask: '000-0000000-0'}}

                        </td>
                        <td  *ngIf="m.typeIdentificationId != 1 && m.typeIdentificationId != 4">
                            {{m.personalIdentification}}</td>
                        <td class="text-center">{{m.phoneNumber | mask: '(000) 000-0000'}}</td>
                        <td class="text-center">{{m.birthday | date:"dd/MM/yyyy"}}</td>
                        <td class="text-center">{{m.createDate | date:"dd/MM/yyyy"}}</td>
                        <td class="text-center">
                            <span style="width: 137px;">
                                <span
                                    [ngClass]="m.status ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{m.status?
                                    "Activa":
                                    "Inactiva"}}</span>
                            </span>
                        </td>

                        <td class=" px-5">
                            <div class="d-flex justify-content-center px-5" style="width: max-content">
                                <div class="justify-content-start  px-5">


                                    <a *ngIf="m.status && privilege.addAction.value"
                                        class="btn btn-sm btn-light-warning btn-icon mr-2 "
                                        routerLink="/candidate-registration/{{m.personId}}">
                                        <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                                    </a>


                                    <a class="btn btn-icon btn-light-info btn-sm mr-2"
                                        *ngIf="privilege.viewAction.value"
                                        (click)="exportToPdf(m)">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                                    </a>
                                    <ng-container
                                        *ngIf="!m.status && privilege.activeAction.value">
                                        <a class="btn btn-icon btn-light-success btn-sm mr-2">
                                            <i class="flaticon-usuarios icon-2x" (click)="activatePerson(m.personId)"
                                                data-toggle="tooltip" title="Activar"></i>
                                        </a>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="m.status && privilege.deleteAction.value">
                                        <a class="btn btn-icon btn-light-danger btn-sm" *ngIf="m.isEmployee == 0">
                                            <i class="flaticon-usuarios icon-2x mr-2" data-toggle="tooltip"
                                                title="Inactivar" (click)="deletePerson(m.personId)"></i>
                                        </a>
                                    </ng-container>
                                    <a class="btn-sm m-1" style="width: 80px !important;" *ngIf="m.isEmployee == 1">
                                    </a>
                                </div>
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-center mt-8">
                <h5 *ngIf="personList.length == 0" class="text-muted">No hay registros para mostrar</h5>
            </div>

            <app-paginator
            [listedRecordsLength]="personList?.length"
            [registerQuantity]="employeesPagedResult.totalItems"
            [registerPerPage]="employeesPagedResult.pageSize"
            [totalPages]="employeesPagedResult.totalPages"
            [selectedPage]="employeesPagedResult.page"
            (onChange)="getPaginatedRecords($event)">
            </app-paginator>


        </div>
    </div>
</div>