import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';


@Injectable({
  providedIn: 'root'
})
export class PlanningVacationsService {

  private readonly xhrm_url = environment.api_url;
  constructor(private _httpClient: HttpClient) { }

  getPlanningVacationsHeader(companyId:any, status:any){
    return this._httpClient.get<ResponseModel<any>>(`${this.xhrm_url}/PlanningVacations/GetPlanningVacationsHeader?companyId=${companyId}&status=${status}&planningVacationsHeaderId=0`)
  }

  getPlanningVacationsDetail(companyId:any, status:any, planningVacationsHeaderId:any){
    return this._httpClient.get<ResponseModel<any>>(`${this.xhrm_url}/PlanningVacations/GetPlanningVacationsDetail?companyId=${companyId}&status=${status}&planningVacationsHeaderId=${planningVacationsHeaderId}&operation=2&employeeId=0`)
  }

  postPlanningVacations(item:any){
    return this._httpClient.post<ResponseModel<any>>(`${this.xhrm_url}/PlanningVacations`, item)
  }

  getCollectiveVacationsHeader(companyId:any){
    return this._httpClient.get(`${this.xhrm_url}/CollectiveVacationsHeader?CollectiveVacationsHeaderId=0&ConditionId=0&CompanyId=${companyId}&Status=true&Type=0`)
  }

  setEmailVacation(companyId:any, year:any, bodyEmail:any){
    return this._httpClient.post(`${this.xhrm_url}/PlanningVacations/EmailVacation?company=${companyId}&year=${year}`, bodyEmail)
  }



}
