import { HttpHeaders } from "@angular/common/http";
import { Injectable, Sanitizer } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable, ObservableInput, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClientService } from "../../services/http-client/http-client.service";

@Injectable({
    providedIn: 'root'
})

export class FileManagerService {
    fileResolve: Subject<number> = new Subject<number>();
    httpOptions: Object = {
        headers: new HttpHeaders({'accept': '*/*', 'Content-Type': 'application/json'}),
        observe: 'response',
    };
    route = 'fl/file';
    id: number;
    private _filePosition = 0;

    constructor(private httpClientService: HttpClientService, private sanitizer: DomSanitizer) {}

    getFileToShow(id: number): Observable<{file: any, filename: string, fileType: string, url:string}> {
        this.id = id;
        return this.request(id)
        .pipe(map(response => {
                const contentType = response.headers.get('content-type');
                const contentDisposition: string = response.headers.get('content-disposition');
                const filename = this.getFilename(contentDisposition);
                const file = `data:${contentType};base64,${response.body.Data}`  
                const url=response.body.url;           
                return {file, filename, fileType: contentType,url};
            }
        ));
    }

    getDownloadUrl(id: number): Observable<{blob: Blob, filename: string}> {
        return this.request(id)
        .pipe(map(response => {
                const contentType = response.headers.get('content-type');
                const contentDisposition: string = response.headers.get('content-disposition');
                const filename = this.getFilename(contentDisposition);
                const byteArray = this.ConvertToByteArray(response.body.Data)
                let blob = new Blob([byteArray], { type: contentType,});
                
                return {blob, filename};
            }
        ));
    }

    request(id: number): Observable<any> {
        return this.httpClientService.get<any>(`${this.route}?id=${id}`, this.httpOptions)
    }
    

    getFilename(contentDisposition: string) {
        let filename: string;
        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) { 
              filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    }

    ConvertToByteArray(documentContent) {
        const byteChars = atob(documentContent);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
          byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
     
        return byteArray;
      }

    async saveFiles(input: HTMLInputElement, fileType: number): Promise<any> {
        if(input.files.length) {
            const formData = this.convertToFile(input.files[this._filePosition]);
            const { data } =  await this.httpClientService.post<any>(formData,
            `${this.route}?documentTypeId=${fileType}`).toPromise();
            return data;
        }
    }
    convertToFile(file: File): FormData {
        const formData: FormData = new FormData();
        formData.append(`file`, file, file.name);
        return formData;
    }
}