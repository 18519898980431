import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirmaGobService } from './firma-gob.service';

@Injectable({
  providedIn: 'root'
})
export class DigitalSignService {
  private readonly api_lobby = environment.api_core_sigei;
  
  constructor(private _http: HttpClient) { }

  CreateSignRequest(data: any) {
    return this._http.post(`${this.api_lobby}/FirmaGob/requests`, data);
  }

  GetRequestData(AccessId: string, data: any) {
    return this._http.post(`${this.api_lobby}/FirmaGob/requests/${AccessId}`, data);
  }

  DownloadAttachments(publicAccessId: string, data: any) {
    return this._http.post(`${this.api_lobby}/FirmaGob/documents/${publicAccessId}/signed`, data);
  }

  getUserApprovals(CompanyId: number, ProcessId){
    return this._http.get(`${this.api_lobby}/Companies/approvals?CompanyId=${CompanyId}&ProcessId=${ProcessId}`);
  }

}