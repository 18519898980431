
<ng-template [ngIf]="employeeDepartmentData && form">
    <fieldset [disabled]="true">
    <form [formGroup]="form">
            <div class="row">
                <div class="form-group col-lg-4">
                    <label>Unidad Organizativa <span class="text-danger">*</span></label>
                    <input
                        formControlName="departmentName" 
                        type="text" 
                        class="form-control form-control-lg" />
                </div>
                <div class="form-group col-lg-4">
                    <label>Cargo <span class="text-danger">*</span></label>
                    <input
                        formControlName="positionName" 
                        type="text" 
                        class="form-control form-control-lg" />
                </div>
                
            </div>
            <div class="row">                            
                <div class="form-group col-lg-6">
                    <label>Fecha Ingreso</label>
                    <input
                        formControlName="admissionDate"
                        type="date" 
                        class="form-control form-control-lg" />
                </div>
                <div class="form-group col-lg-6">
                    <label>Fecha Reingreso</label>
                    <input type="date" class="form-control form-control-lg" />
                </div>
                <div class="form-group col-lg-6">
                    <label>Fecha Salida</label>
                    <input
                        formControlName="electiveDepartureDate" 
                        type="date" 
                        class="form-control form-control-lg" />
                </div>
                <div class="form-group col-lg-6">
                    <label>Tipo Servidor Público <span class="text-danger">*</span></label>
                    <input
                        formControlName="employeeType" 
                        type="text" 
                        class="form-control form-control-lg" />

                </div>
                
            </div>
            <div class="row">
                <div class="form-group col-lg-12">
                    <label class="d-block">&nbsp;</label>
                    <div class="d-flex">
                        <div class="card flex-row align-items-center justify-content-center flex-grow-1 mr-3 mb-3 p-2">
                            <strong class="mx-4">Vacaciones</strong>
                            <span class="switch switch-sm switch-primary mx-2">
                                <label><input
                                    formControlName="vacation" 
                                    class="cursor-pointer abc-checkbox-primary" 
                                    type="checkbox"/><span></span></label>
                            </span>
                        </div>
                        <div class="card flex-row align-items-center justify-content-center flex-grow-1 mr-3 mb-3 p-2">
                            <strong class="mx-4">Aplica Seguro</strong>
                            <span class="switch switch-sm switch-primary mx-2">
                                <label><input
                                            formControlName="healthInsurance" 
                                            class="cursor-pointer abc-checkbox-primary" 
                                            type="checkbox"/><span></span></label>
                            </span>
                        </div>
                        <div class="card flex-row align-items-center justify-content-center flex-grow-1 mr-3 mb-3 p-2">
                            <strong class="mx-4">Bono Vacacional</strong>
                            <span class="switch switch-sm switch-primary mx-2">
                                <label><input
                                            formControlName="vacationBonus" 
                                            class="cursor-pointer abc-checkbox-primary" 
                                            type="checkbox"/><span></span></label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </fieldset>
    <div class="col-md-12 gutter-t">
        <div class=" px-5">
            <div class="form-group d-flex justify-content-between align-items-center p-3">
                <button [routerLink]="['/employees']"  class="btn btn-pill font-weight-bolder btn-shadow btn-warning mr-3 ">
                    <i class="flaticon2-left-arrow-1"></i>
                    <span>Volver</span>
                </button> 
                <!-- <button class="btn btn-pill font-weight-bolder btn-shadow btn-success ">
                    <i class="flaticon-disco-flexible"></i>
                    <span>Guardar</span>
                </button>                    -->
            </div>
        </div>
    </div> 
</ng-template>