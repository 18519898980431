import { DatePipe } from "@angular/common";
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthInfoService } from "src/app/services/auth-info.service";
import { PersonalRequsitionService } from "src/app/services/personal-requsition.service";
import { ICompany } from "src/app/shared/models/company";
import { ToastService } from "src/app/shared/toast/toast.service";
import { SweetAlert } from "src/app/shared/utils/Sweet-Alert";
import { Carrer, Experience, Knowleadge, MunicipalityConcursa, ProvinceConcursa, Remuneration, Requirement, SendConcursaRequisition, Sender } from "../models/sendConcursaRequisition.model";



@Component({
    selector: 'app-parameters-send-concursa',
    templateUrl: './parameters-send-concursa.component.html',
    styleUrls: ['./parameters-send-concursa.component.css']
})
export class ParametersSendConcursaComponent implements OnInit, AfterViewChecked {
    requisition: any;
    purestPequested: any[] = [];
    enclosure: any[] = [];
    province: any[] = [];
    deparments: any[] = [];
    municipality: any[] = [];
    testingMonths: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    sweetAlrt = new SweetAlert
    isSalaryInvalid: boolean = false

    carrers: any[] = [];
    causes: any[] = [];
    requirements: any[] = [];
    remunerations: any[] = [];
    knowleadges: Knowleadge[] = [];
    recordsKnowleadges: Knowleadge[] = [];
    experiences: Experience[] = [];
    recordExperiences: Experience[] = [];
    companies: ICompany[] = [];

    formSendConcursaRequisition: FormGroup;
    invalidFormSendConcursaRequisition: boolean = false;
    formKnowleadges: FormGroup;
    invalidFormKnowleadges: boolean = false;
    formExperiences: FormGroup;
    invalidFormExperiences: boolean = false;

    public configCountry = {
        displayKey: 'nombre',
        search: true,
        searchPlaceholder: "Buscar",
        placeholder: 'Seleccionar',
        noResultsFound: 'No hay data para mostrar'
    };

    public config = {
        searchPlaceholder: "Buscar",
        placeholder: 'Seleccionar',
        customComparator: (n) => { },
        noResultsFound: 'No hay data para mostrar'
    };



    @ViewChild('closeModalExperiences') closeModalExperiences: ElementRef;
    @ViewChild('closeModalKnowleadges') closeModalKnowleadges: ElementRef;

    tomorrowDate: string = "";

    constructor(
        public dialogRef: MatDialogRef<ParametersSendConcursaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public personalRequsitionService: PersonalRequsitionService,
        private _toastService: ToastService,
        private authInfoService: AuthInfoService,
        private _changeDet: ChangeDetectorRef,
        private fb: FormBuilder, private datePipe: DatePipe) {

        let tomorrow = new Date().setDate(new Date().getDate() + 1);
        this.tomorrowDate = this.datePipe.transform(new Date(tomorrow), 'yyyy-MM-dd')

        this.dialogRef.disableClose = true;
        this.requisition = this.data;
        this.buldForm();
    }

    ngAfterViewChecked(): void {
        this._changeDet.detectChanges();
    }

    ngOnInit(): void {
        this.getCarrers();
        this.getCauses();
        this.getRequirementsConcursa();
        this.getRemunerationsConcursa();
        this.getProvince();
        this.getCompanies();
    }

    buldForm() {
        this.formKnowleadges = this.fb.group({
            description: ['', [Validators.required, Validators.maxLength(150)]]
        });
        this.formExperiences = this.fb.group({
            description: ['', [Validators.required, Validators.maxLength(150)]]
        });
        this.formSendConcursaRequisition = this.fb.group({
            testingMonths: [null, [Validators.required]],
            province: [null, [Validators.required]],
            municipality: [null, [Validators.required]],
            provinceId: [0],
            municipalityId: [0],
            salary: [null, [Validators.required, Validators.min(1)]],
            requiredDate: [null, [Validators.required]],
            vacancyCause: [null, [Validators.required]],
            vacancyCauseId: [null, [Validators.required]],
            concursaCompanyId: [null, [Validators.required]]
        });

        this.formSendConcursaRequisition.get('requiredDate')?.setValue(this.datePipe.transform(new Date(this.requisition?.applicationDate), 'yyyy-MM-dd'))
    }

    getCompanies() {
        this.personalRequsitionService.getLocations().subscribe({
            next: (res) => {
                if (!res.succeded) {
                    this._toastService.error(res.errors[0])
                    return;
                }
                this.companies = res.dataList;

                let concursaCompanyId = this.companies.find(x => x.companyId == this.requisition.companyId)?.homologousConcursaId;
                this.formSendConcursaRequisition.get('concursaCompanyId').setValue(concursaCompanyId);
            },
            error: (err) => {

            }
        });
    }

    getProvince() {
        this.personalRequsitionService.getConcursaProvinces().subscribe(res => {
            if (res.errors.length > 0) {
                this._toastService.error(res.errors[0]);
                return;
            }
            this.province = res?.dataList;
        });
    }

    changeProvince(province: ProvinceConcursa) {
        this.formSendConcursaRequisition.get('municipality').setValue(null);
        this.formSendConcursaRequisition.get('municipalityId').setValue(null);

        this.formSendConcursaRequisition.get('provinceId').setValue(province?.id);
        this.municipality = [];
        if (province?.id) {
            this.getMunicipalityById(province?.id);
        }
    }

    changeMunicipality(municipality: MunicipalityConcursa) {
        this.formSendConcursaRequisition.get('municipalityId').setValue(municipality?.id);
    }

    changeCause(cause) {
        this.formSendConcursaRequisition.get('vacancyCauseId').setValue(cause?.id);
    }


    getMunicipalityById(provinciaId) {
        this.personalRequsitionService.getConcursaMunicipality(provinciaId).subscribe(res => {
            if (res.errors.length > 0) {
                this._toastService.error(res.errors[0]);
                return;
            }
            this.municipality = res?.dataList;
        })
    }

    selectSalaryRange(salary: number) {
        this.formSendConcursaRequisition.get('salary').setValue(salary);
        this.isSalaryInvalid = false
    }

    onSalaryError() {
        if (this.formSendConcursaRequisition.get('salary').value > this.requisition?.maxSalary || this.formSendConcursaRequisition.get('salary').value < this.requisition?.minSalary) {
            this.isSalaryInvalid = true
        }
        else {
            this.isSalaryInvalid = false
        }
    }

    addExperiences() {
        if (this.formExperiences.invalid) {
            this.invalidFormExperiences = true;
            return;
        }
        this.invalidFormExperiences = false;
        const experiencesDescription: string = this.formExperiences?.value?.description;
        this.experiences = [...this.experiences, {
            value: 0,
            description: experiencesDescription
        }];
        this.closeModalExperiences.nativeElement.click();
    }

    deleteExperiences(index: number) {
        this.sweetAlrt.AlertConfirm("Eliminar", "¿Está seguro de eliminar este registro?", "question").then(res => {
            if (res) {
                this.experiences.splice(index, 1);
            } else {
            }
        })
    }

    addKnowleadges() {
        if (this.formKnowleadges.invalid) {
            this.invalidFormKnowleadges = true;
            return;
        }
        this.invalidFormKnowleadges = false;
        const KnowleadgeDescription: string = this.formKnowleadges?.value?.description;
        this.knowleadges = [...this.knowleadges, {
            value: 0,
            description: KnowleadgeDescription
        }];
        this.closeModalKnowleadges.nativeElement.click();
    }

    deleteKnowleadges(index: number) {
        this.sweetAlrt.AlertConfirm("Eliminar", "¿Está seguro de eliminar este registro?", "question").then(res => {
            if (res) {
                this.knowleadges.splice(index, 1);
            } else {
            }
        })

    }

    getCarrers() {
        this.personalRequsitionService.getTitulationsConcursa().subscribe(res => {
            if (res?.errors?.length > 0) {
                this._toastService.error(res?.errors[0]);
                return;
            }
            this.carrers = res?.dataList?.map(d => {
                return {
                    ...d,
                    isSeleted: false
                }
            });
        });
    }

    getCauses() {
        this.personalRequsitionService.getCausesConcursa().subscribe(res => {
            if (res?.errors?.length > 0) {
                this._toastService.error(res?.errors[0]);
                return;
            }
            this.causes = res?.dataList;
        });
    }

    getRequirementsConcursa() {
        this.personalRequsitionService.getRequirementsConcursa().subscribe(res => {
            if (res?.errors?.length > 0) {
                this._toastService.error(res?.errors[0]);
                return;
            }
            this.requirements = res?.dataList?.map(d => {
                return {
                    ...d,
                    isSeleted: false
                }
            });
        });
    }

    getRemunerationsConcursa() {
        this.personalRequsitionService.getRemunerationsConcursa().subscribe(res => {
            if (res?.errors?.length > 0) {
                this._toastService.error(res?.errors[0]);
                return;
            }
            this.remunerations = res?.dataList?.map(d => {
                return {
                    ...d,
                    isSeleted: false
                }
            });
        });
    }

    Cancel() {
        this.dialogRef.close(false);
    }

    resetFormExperiences() {
        this.formExperiences.reset();
        this.invalidFormExperiences = false;
    }

    resetFormknowleadge() {
        this.formKnowleadges.reset();
        this.invalidFormKnowleadges = false;
    }

    dropdownConfig(displayKey) {
        return {
            displayKey: displayKey,
            search: true,
            height: 'auto',
            placeholder: 'Seleccionar',
            moreText: '...',
            noResultsFound: 'No se han encontrado registros',
            searchPlaceholder: 'Buscar',
            searchOnKey: displayKey
        }
    }

    getErrorFormSendConcursaRequisition(name: string) {
        if (!this.invalidFormSendConcursaRequisition) {
            return '';
        }
        const field = this.formSendConcursaRequisition.get(name)
        if (field.hasError('required')) {
            return 'Este campo es obligatorio';
        }
        if (field.hasError('min')) {
            return 'Este campo es obligatorio';
        }
        return '';
    }

    getErrorFormknowleadge(name: string) {
        if (!this.invalidFormKnowleadges) {
            return '';
        }
        const field = this.formKnowleadges.get(name)
        if (field.hasError('required')) {
            return 'Este campo es obligatorio';
        }
        if (field.hasError('maxlength')) {
            return `Este campo sólo admite un máximo de ${field?.errors['maxlength']?.requiredLength} caracteres`;
        }
        return '';
    }

    getErrorFormExperiences(name: string) {
        if (!this.invalidFormExperiences) {
            return '';
        }
        const field = this.formExperiences.get(name)
        if (field.hasError('required')) {
            return 'Este campo es obligatorio';
        }
        if (field.hasError('maxlength')) {
            return `Este campo sólo admite un máximo de ${field?.errors['maxlength']?.requiredLength} caracteres`;
        }
        return '';
    }

    changeCarrer(carrer) {
        this.carrers.forEach(c => {
            if (c?.id === carrer?.id) {
                c.isSeleted = !c?.isSeleted;
            }
        });
    }

    changeRequirement(requirement) {
        this.requirements.forEach(r => {
            if (r?.id === requirement?.id) {
                r.isSeleted = !r?.isSeleted;
            }
        });
    }

    changeRemuneration(remuneration) {
        this.remunerations.forEach(r => {
            if (r?.id === remuneration?.id) {
                r.isSeleted = !r?.isSeleted;
            }
        });
    }

    checkParameters() {
        if(this.getCarrerSeleted?.length === 0){
            this._toastService.warning('Debe seleccionar por lo menos una titulación');
            return false;
        }
        if (this.getRequirements?.length === 0) {
            this._toastService.warning('Debe seleccionar por lo menos un requisito');
            return false;
        }
        if (this.getRemunerations?.length === 0) {
            this._toastService.warning('Debe seleccionar por lo menos una remuneración');
            return false;
        }
        if (this.experiences?.length === 0) {
            this._toastService.warning('Debe proporcionar por lo menos una experiencia');
            return false;
        }
        if (this.knowleadges?.length === 0) {
            this._toastService.warning('Debe proporcionar por lo menos un conocimiento');
            return false;
        }
        return true;
    }

    send() {
        const sendConcursaRequisition: SendConcursaRequisition = this.buildModel();

        if(!sendConcursaRequisition.concursaCompanyId){
            this._toastService.warning('La institucíón no ha sido configurada con Concursa. Favor de comunicarse con un Administrador.')
            return;
        }

        if (this.formSendConcursaRequisition.invalid || this.isSalaryInvalid) {
            this.invalidFormSendConcursaRequisition = true;
            return;
        }

        this.invalidFormSendConcursaRequisition = false;

        if (!this.checkParameters()) {
            return;
        }


        this.personalRequsitionService.sendConcursaRequisition(sendConcursaRequisition).subscribe(res => {
            if (res?.errors?.length > 0) {
                this._toastService.error(res.errors[0]);
                return;
            }
            this._toastService.success('El envío se realizó correctamente');
            this.dialogRef.close(true);
        }, error => {
            this._toastService.error('Ha ocurrido un error procesando el envío a Concursa. Consulte al administrador del sistema');
        });

    }

    private get getCarrerSeleted() {
        const carrers = this.carrers?.filter(c => c?.isSeleted)?.map(c => {
            return {
                value: +c?.id,
                description: c?.nombre
            }
        });
        return carrers;
    }

    private get getRequirements() {
        return this?.requirements?.filter(c => c?.isSeleted)?.map(c => {
            return {
                value: +c?.id,
                description: c?.nombre
            }
        });
    }

    private get getRemunerations() {
        return this.remunerations?.filter(c => c?.isSeleted)?.map(c => {
            return {
                value: +c?.id,
                description: c?.nombre
            }
        });
    }

    private buildModel(): SendConcursaRequisition {
        const sender: Sender = {
            createUserId: this.authInfoService?.getUserId(),
            senderFullName: `${this.authInfoService?.getUser()?.firstName} ${this.authInfoService?.getUser()?.lastName}`,
            senderIdentification: this.authInfoService?.getUser()?.identification
        }
        const carrer: Carrer[] = this?.getCarrerSeleted;
        const requirements: Requirement[] = this?.getRequirements;
        const remunerations: Remuneration[] = this?.getRemunerations;

        const sendConcursaRequisition: SendConcursaRequisition = {
            requisitionId: this.requisition?.personalRequisitionId,
            provinceId: this.formSendConcursaRequisition?.value?.provinceId,
            municipalityId: this.formSendConcursaRequisition?.value?.municipalityId,
            salary: this.formSendConcursaRequisition?.value?.salary,
            testingMonths: this.formSendConcursaRequisition?.value?.testingMonths,
            vacancyCauseId: this.formSendConcursaRequisition?.value?.vacancyCauseId,
            requiredDate: this.formSendConcursaRequisition?.value?.requiredDate,
            concursaCompanyId: this.formSendConcursaRequisition?.value?.concursaCompanyId,
            sender: sender,
            carrers: carrer ?? [],
            requirements: requirements ?? [],
            remunerations: remunerations ?? [],
            knowleadges: this.knowleadges,
            experiences: this.experiences
        }
        return sendConcursaRequisition;
    }

    getPaginatedRecordsExperiences(event) {
        this.recordExperiences = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    }

    getPaginatedRecordsKnowleadges(event) {
        this.recordsKnowleadges = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    }

}  