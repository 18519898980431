import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { BeneficiariesService } from 'src/app/services/beneficiaries.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { BeneficiariesModalComponent } from './beneficiaries-modal/beneficiaries-modal/beneficiaries-modal.component';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
declare function applySort(): any;

@Component({
  selector: 'app-beneficiaries',
  templateUrl: './beneficiaries.component.html',
  styleUrls: ['./beneficiaries.component.css']
})

export class BeneficiariesComponent implements OnInit, AfterViewChecked {

  filterText: string = '';
  beneficiaries: any[] = []
  beneficiariesOriginal: any[] = []
  paginatedBeneficiaries: any[] = []
  selectedItems: any[] = [];
  sweetAlrt = new SweetAlert

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PAYROLL-BENEFICIARIES',
    addAction: { key: 'ADDBENEFICIARIES', value: false },
    editAction: { key: 'EDITBENEFICIARIES', value: false },
    viewAction: { key: 'VIEWBENEFICIARIES', value: false },
    exportAction: { key: 'EXPORTBENEFICIARIES', value: false },
    deleteAction: { key: 'REMOVEBENEFICIARIES', value: false },
    searchAction: { key: 'SEARCHBENEFICIARIES', value: false },
  }

  @ViewChild('paginator') paginator: CaptionComponent;

  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propKeyId: number, propkey?: string, displayName: string }[] =
    [
      { propKeyId: 2, propkey: "identificationTypeName", displayName: "Tipo de Identificación" },
      { propKeyId: 3, propkey: "identification", displayName: "Documento de Identidad" },
      { propKeyId: 1, propkey: "description", displayName: "Nombre del Beneficiario" },
    ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' },
  ];

  searchPropKeySelected!: { propKeyId?: number, propkey?: string, displayName: string };
  pageSize: number
  selectedPage: any

  constructor(private _beneficiariesService: BeneficiariesService, private _toastService: ToastService,
    public authInfo: AuthInfoService, private dialog: MatDialog, private _changeDet: ChangeDetectorRef,
    private _exlService: ExportToExcelService) { }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.getData();
      }
    });
    applySort();
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  getData() {
    this.getBeneficiaries()
  }

  getBeneficiaries() {
    this._beneficiariesService.getBeneficiaries().subscribe({
      next: (res: any) => {
        if (res.errors.lenght > 0) {
          this._toastService.error(res.errors[0])
        }
        this.beneficiaries = this.filterTable(res.dataList)
        this.beneficiariesOriginal = res.dataList

      }
    })
  }




  openBeneficiariesModal(mode: number, beneficiary?) {
    this.dialog.open(BeneficiariesModalComponent, {
      width: '70%',
      data: {
        mode: mode,
        beneficiary: beneficiary
      }
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (!res.created) {
          return
        }
        this.getBeneficiaries()
      },
      error: err => this._toastService.error(err)
    })
  }

  filter() {
    document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.beneficiaries = this.filterTable(this.beneficiariesOriginal)
    }
    this.setFilterToLocalStorage();
  }


  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
    this.filteredList.records = this.filterTable(event.records)
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl.find(x => x.item_id == 1) && !sl.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl.find(x => x.item_id == 2) && !sl.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    return list;
  }

  setFilterToLocalStorage() {
    localStorage.setItem('medical-employee-list_filter', JSON.stringify(this.selectedItems));
  }

  onItemSelect(item: any) {
    this.filter();
  }

  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  onItemDeSelect(item: any) {
    this.filter();
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  getPaginatedRecords(event) {
    this.paginatedBeneficiaries = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.selectedPage = event.selectedPage
    this.pageSize = event?.registersPerPage
  }

  onKeyParameterChange(value: { propKeyId?: number, propkey: string, displayName: string } | null) {
    this.searchPropKeySelected = value ?? null;
  }


  exportResport() {
    const companyId: number = this.authInfo.getCompanyId();
    const status: any = this.selectedItems?.length === 2 || this.selectedItems?.length == 0 ? '0-1' : (this.selectedItems[0]?.item_id === 1 ? 1 : 0);
    const propKeyId = this.searchPropKeySelected?.propKeyId ?? 0
    const pagesize = this.pageSize
    const page = this.selectedPage
    const searchParameter = this.filterText ?? ''
    const reportCode: ReportCode = ReportCode.ReportPayrollConceptBeneficiary;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&Status=${status}&PropkeyId=${propKeyId}&SearchParameter=${searchParameter}&CompanyId=${companyId}&Page=${page}&PageSize=${pagesize}`
    let parameters = {
      url: reportUrl,
      title: 'Beneficiarios de Conceptos de Nómina',
      width: 1024,
      height: 768
    }
    openReport(parameters);

  }


  deleteBeneficiary(idPersonalRequisition: number) {
    this.sweetAlrt.AlertConfirm("Inactivar", "¿Está seguro de inactivar este registro?", "question").then(res => {
      if (res) {
        this._beneficiariesService.deleteBeneficiary(idPersonalRequisition).subscribe((result) => {
          this.sweetAlrt.Default("Inactivado", "Registro inactivado satisfactoriamente", "success")
          this.getBeneficiaries()
        }, err => {
          this._toastService.error(err, "Ocurrió un error tratando de inactivar el registro")
        });
      } else {
      }
    })
  }

  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

}
