import { ChangeDetectorRef, Component, OnInit, AfterViewChecked, ViewChild } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client/http-client.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { MatDialog } from '@angular/material/dialog';
import { RequisitionComponent } from './requisition/requisition.component';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs/internal/Subject';
import { IRequisitionView, PersonalRequisitionsModel } from './models/personal-requisitions';
import { DashboardComponent } from './requisition-dashboard/dashboard/dashboard.component';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { formatDate } from '@angular/common';
import { ExperienceComponent } from './experience/experience.component';
import { KnowledgeComponent } from './knowledge/knowledge.component';
import { ParametersSendConcursaComponent } from './parameters-send-concursa/parameters-send-concursa.component';
import { ICompany } from 'src/app/shared/models/company';
import { ConcursaStatusModalComponent } from './concursa-status-modal/concursa-status-modal.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { FilterEvent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';
import { AdvancedFilterByPersonalRequisitionComponent } from './advanced-filter-by-personal-requisition/advanced-filter-by-personal-requisition.component';
import { AuditChangesRequisitionComponent } from './audit-changes/audit-changes-requisition.component';
declare function applySort(): any;

declare const $: any;

@Component({
  selector: 'app-personal-requisition',
  templateUrl: './personal-requisition.component.html',
  styleUrls: ['./personal-requisition.component.css']
})
export class PersonalRequisitionComponent implements OnInit, AfterViewChecked {

  // public privilege = {
  //   nameKeyModule: 'HRM',
  //   nameKeyOption: 'PERREQ',
  //   addAction:'ADDREQUISITION',
  //   editAction:'EDIT',
  //   approveAction:'APPROVAL',
  //   viewAction:'VIEW',
  //   exportAction: 'EXPORT',
  //   deleteAction: 'DELETE',
  //   dashboardAction: 'DSHReq'
  // }

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PERREQ',
    addAction: { key: 'ADDREQUISITION', value: false },
    editAction: { key: 'EDIT', value: false },
    approveAction: { key: 'APPROVAL', value: false },
    viewAction: { key: 'VIEW', value: false },
    exportAction: { key: 'EXPORT', value: false },
    deleteAction: { key: 'DELETE', value: false },
    dashboardAction: { key: 'DSHReq', value: false },
    auditChangesAction: {key:'AUDIT-CHANGES', value: false }
  }
  searchParameter: FilterEvent
  employeesPagedResult = new PagedResult<any>();
  public eventsSubject: Subject<void> = new Subject<void>();
  dashboardContent: any;
  searchparameterlocalstorage: string = 'requisition-list_filter_search';
  tempAdvanceSearch: any
  advancedFilter:any

  @ViewChild('paginator') paginator: CaptionComponent;
  emitEventToChild() {
    this.eventsSubject.next()
  }

  keyValue: string = '';
  skillsRequired: any[] = [];
  company: any
  reportUrl = environment.reportUrl
  requisitions: IRequisitionView[] = [];
  requisitionsOriginal: IRequisitionView[] = [];
  sweetAlrt = new SweetAlert
  approvedRequisition: boolean = false
  selectedItems: any;
  searchList: any[] = [];
  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Secuencia" },
      { propkeyId: 2, displayName: "Sucursal" },
      { propkeyId: 3, displayName: "Unidad organizativa" },
      { propkeyId: 4, displayName: "Cargo" },
      { propkeyId: 5, displayName: "Cantidad de Plazas" },
      { propkeyId: 7, displayName: "Método de Reclutamiento" },
      { propkeyId: 6, displayName: "Sexo" },
      { propkeyId: 100, displayName: "Búsqueda Avanzada" },
      // { propkey: "workingHours", displayName: "Hóras trabajadas" },
    ];
  status = [{ value: 0, description: 'Aprobada' }, { value: 1, description: 'No aprobada' }]

  title = "Parámetros Envio a Concursa";

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  canUseOption = false
  statusOptions = [
    { item_id: 1, item_text: 'Activas' },
    { item_id: 2, item_text: 'Inactivas' },
    { item_id: 3, item_text: 'Aprobadas' },
    { item_id: 4, item_text: 'No aprobadas' },
    { item_id: 5, item_text: 'Pendiente enviar a concursa' },
    { item_id: 6, item_text: 'Enviada a Concursa' },
  ];

  constructor(
    private _toastService: ToastService,
    private dialog: MatDialog,
    public prService: PersonalRequsitionService,
    private _excellService: ExportToExcelService,
    private _changeDet: ChangeDetectorRef,
    public authInfo: AuthInfoService
  ) {

  }

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem("requisition-list_filter"))?.length > 0) {
      this.selectedItems = JSON.parse(localStorage.getItem("requisition-list_filter"));
    } else {
      const valuepr = [{ "item_id": 1, "item_text": "Activas" }];
      localStorage.setItem('requisition-list_filter', JSON.stringify(valuepr));
      this.selectedItems = JSON.parse(localStorage.getItem("requisition-list_filter"))
    }
    this.setFilterStorage();

    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.canUseOption = true
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.GetRequisitions();
      }
    })

    applySort();
  }

  private setFilterStorage() {
    const localStorageSearch: FilterEvent = JSON.parse(localStorage.getItem(this.searchparameterlocalstorage));
    this.searchParameter = localStorageSearch;
  }



  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccionar',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }
  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  onItemSelect(item: any) {
    this.GetRequisitions()
    this.setFilterToLocalStorage();
  }
  onDeSelectAll() {
    this.selectedItems = []
    this.GetRequisitions()
  }

  onSelectAll(item: any) {
    this.selectedItems = item
    this.setFilterToLocalStorage();
    this.GetRequisitions()
  }

  onItemDeSelect(item: any) {
    this.GetRequisitions()
    this.setFilterToLocalStorage();
  }

  setFilterToLocalStorage() {
    localStorage.setItem('requisition-list_filter', JSON.stringify(this.selectedItems));
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  filterSearch(event: FilterEvent) {
    this.employeesPagedResult.page = 1
    this.searchParameter = event
    this.advancedFilter = ''
    if (event.propkeyId != 100) {
      this.GetRequisitions();
    } else {
      this.OpenAdvancedFilters();
    }
    
  }
  GetRequisitions() {
    if (!this.canUseOption) {
      return
    }
    const { page, pageSize } = this.employeesPagedResult
    const text = this.searchParameter?.value
    const propkeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;

    const actives = this.selectedItems.find((x) => x.item_id === 1);
    const inactives = this.selectedItems.find((x) => x.item_id === 2);
    let status = 0;
    if ((!actives && !inactives) || (actives && inactives)) {
      status = -1;
    } else if (actives && !inactives) {
      status = 1;
    }

    const ableConditions = this.selectedItems
      ?.filter((x) => x.item_id !== 1 && x.item_id !== 2)
      ?.map((e) => e.item_id)
      ?.join("-");

    const advancedsearch = this.advancedFilter ?? ''

    const params = {
      propkeyId,
      status,
      conditions: ableConditions,
      page,
      pageSize,
      type: 1,
      searchParameter: text,
      advancedFilter: advancedsearch,
    };
    this.prService.getRequisitionsPaginated(params).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) {
          this._toastService.error(res.errors[0])
          return;
        }
        if (!res.succeded) {
          return
        }
        this.requisitions = res.singleData.items;
        this.requisitionsOriginal = res.singleData.items;
        this.employeesPagedResult = res.singleData
      }, error: (error: any) => {
        this._toastService.error("Ha ocurrido un error inesperado")
      }
    })
  }

  getByCompany() {
    if (this.company.length == 0) {
      return
    }

    this.GetRequisitions()
  }

  AddNewRequisition(item: any, IsView = false): void {
    this.dialog.open(RequisitionComponent, {
      data: {
        item: item,
        IsView: IsView
      }
    })
      .afterClosed()
      .subscribe((result) => {
        if (!result?.Applied || !result) {
          return
        }
        !this.approvedRequisition ? this.GetRequisitions() : null
      });
  }

  openDashBoard(): void {
    this.dialog.open(DashboardComponent, {
    })
      .afterClosed()
      .subscribe((result) => {
      });
  }

  DeleteItem(idPersonalRequisition: number) {
    this.sweetAlrt.AlertConfirm_with_input("Inactivar", "¿Está seguro de inactivar este registro?", "Por favor introducir la razón", "question").then(res => {
      if (res.isConfirmed) {
        let model = {
          PersonalRequisitionId : idPersonalRequisition,
          reasonToBeRemoved : res.value,
          userId : this.authInfo.getUserId()
        }
        this.prService.deletePersonalRequisition(model).subscribe((result) => {
          this.sweetAlrt.Default("Inactivado", "Registro inactivado satisfactoriamente", "success")
          this.GetRequisitions()
        }, err => {
          this._toastService.error(err, "Ocurrió un error tratando de inactivar el registro")
        });
      } else {
      }
    })
  }

  aprovate(item) {
    Swal.fire({
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      title: "¿Seguro/a que desea aprobar esta requisición?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      icon: 'question',
      confirmButtonColor: '#15c229'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prService.approvedPersonalRequisition(item).subscribe(res => {
          if (res.errors.length > 0) { this._toastService.error("Error al aprobar la requisición"); return; }
          this._toastService.success("La requisicón fue aprobada satisfactoriamente")
          this.GetRequisitions()
        }, err => this._toastService.error(err))
      }
    })
  }


  getPaginatedRecords(event: PaginationEvent) {
    const { selectedPage, registersPerPage } = event;
    const { employeesPagedResult } = this;
    if (employeesPagedResult.page === selectedPage && employeesPagedResult.pageSize === registersPerPage) {
      return;
    }
    this.employeesPagedResult.page = selectedPage;
    this.employeesPagedResult.pageSize = registersPerPage;
    this.GetRequisitions()
  }

  exportToExcel() {
    let data: any = this.requisitions

    data.forEach(element => {
      element.statusText = element.status ? 'Activa' : 'Inactiva'
      element.conditionText = element.condition ? 'Aprobada' : 'No aprobada'
      element.createDateFormatted = formatDate(element.requestedDate, 'dd/MM/yyyy', 'es')
      element.approvationDateFormatted = formatDate(element.approvationDate, 'dd/MM/yyyy', 'es')
      if (element?.condition == true && element?.requisitionMethodId == 1 && element?.concursaCondition != 1) {
        element.conditionText = 'Pendiente enviar a concursa'
      }
      if (element?.condition == true && element?.requisitionMethodId == 1 && element?.concursaCondition == 1) {
        element.conditionText = 'Enviada a Concursa';
      }
    });
    let excelHeaders: string[][] = [[
      'Secuencia',
      'Recinto',
      'Unidad Organizativa',
      'Cargo',
      'Cantidad de plazas',
      'Género',
      'Creación',
      'Aprobación',
      'Estatus Requisición',
      'Estatus'
    ], [
      'personalRequisitionId',
      'enclosure',
      'department',
      'positionName',
      'numberPlaces',
      'sex',
      'createDateFormatted',
      'approvationDateFormatted',
      'conditionText',
      'statusText'
    ]];

    if (this.requisitions.length > 0) {
      this._excellService.exportToExcelSpecificColumns(data, excelHeaders, 'Lista de requisiciones', true);
    } else {
      this._toastService.error(`No puede exportar debido a que no hay registros.`, `Error al exportar`);
    }
  }

  close() {
  }

  openExperience() {
    this.dialog.open(ExperienceComponent, {
      width: '650px',
    })
  }


  openKnowledge() {
    this.dialog.open(KnowledgeComponent, {
      width: '650px',
    });
  }

  openParametersSendConcursaComponent(requisition: IRequisitionView) {
    this.dialog.open(ParametersSendConcursaComponent, {
      data: requisition,
      width: '100%',
    }).afterClosed()
      .subscribe((result) => {
        if (result == true) {
          this.GetRequisitions()
        }
      });
  }

  openModalAuditChanges(requisition){
    this.dialog.open(AuditChangesRequisitionComponent,{
      data:{
        selectedRequisition: { ...requisition },
        columns: ["Secuencia","Sucursal", "Unidad Organizativa", "Cargo", "Cantidad de plazas", "Metodo de reclutamiento", "Sexo", "Creacion", "Aprobacion","Estatus Requisicion","Creado Por","Fecha Creación","Modificado por","Fecha Modificacion","Estatus"]
      },
      width:'100%'
    });
  }

  openConcursaCheckStatusModal(requisition: IRequisitionView) {
    this.dialog.open(ConcursaStatusModalComponent, {
      data: requisition,
      width: '100%',
    }).afterClosed()
      .subscribe((result) => {
        if (result == true) {
          this.GetRequisitions()
        }
      });
  }

  openReport(requisition?) {

    let url = ''
    const propKeyId = this.searchParameter?.propkeyId ?? 0
    const searchParameter = this.searchParameter?.value
    const actives = this.selectedItems.find((x) => x.item_id === 1);
    const inactives = this.selectedItems.find((x) => x.item_id === 2);
    let status = 0;
    if ((!actives && !inactives) || (actives && inactives)) {
      status = -1;
    } else if (actives && !inactives) {
      status = 1;
    }
    const ableConditions = this.selectedItems
    ?.filter((x) => x.item_id !== 1 && x.item_id !== 2)
    ?.map((e) => e.item_id)
    ?.join("-");
    const { page, pageSize } = this.employeesPagedResult

    const personalRequisitionId  = requisition?.personalRequisitionId
    if (personalRequisitionId) {
      url = `${this.reportUrl}/?ReportCode=pr_RequisitionPersonalForm&CompanyId=${this.authInfo.getCompanyId()}&PersonalRequisitionId=${personalRequisitionId}&Status=${requisition?.status}`
    }
    else {
      url = `${this.reportUrl}/?ReportCode=Exp_PersonalRequisition&CompanyId=${this.authInfo.getCompanyId()}&PropKeyId=${propKeyId}&SearchParameter=${searchParameter}&status=${status}&conditions=${ableConditions}&page=${page}&pageSize=${pageSize}&AdvancedFilter=${this.advancedFilter}`
    }

    let parameters = {
      url: url,
      title: 'Consulta Acción de Personal',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }
  OpenAdvancedFilters() {
    this.dialog.open(AdvancedFilterByPersonalRequisitionComponent, {
      width: '55%',
      data: {
        advanced: this.advancedFilter
      }
    }).afterClosed().subscribe({
      next: (res:any) => {
        if(res.success == false){
          return
        }
        this.advancedFilter = res.text
        this.GetRequisitions()
      }
    })
  }
}
