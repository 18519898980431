<div class="container-fluid ">
    <div class="card card-custom">
        <div class="card-header mt-3  d-flex justify-content-between">
            <div class="card-title ">
                <div class="ttl d-flex align-items-center ">
                    <i class="flaticon-recursos-humanos-7 icon-3x text-danger mr-2 forward-btn"></i>
                    <h1 class="text-primary forward-btn">Reporte de Cuentas Bancarias</h1>
                </div>
            </div>
            <div class="card-toolbar">
                <button (click)="goBack()" class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
                    <i class="flaticon2-left-arrow-1"></i>
                    <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
                </button>
            </div>
        </div>
        <div class="card-header row justify-content-between p-2 mx-0 ">

            <div class="col-sm-12 col-md-8 c-left forward-btn">
                <div class="card-toolbar d-flex justify-content-start" [formGroup]="reportForm">

                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Unidad Organizativa <span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown  [options]="departments"
                                    formControlName="department" [config]="dropdownConfig('departmentName')" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Tipo de Servidor Público<span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown formControlName="employeeType" [options]="employeeType"
                                    [config]="dropdownConfig('description')"  class="w-100">
                                </ngx-select-dropdown>
  
                                <!-- <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'"
                                    [settings]="dropdownSettings" [data]="employeeType" formControlName="employeeType">
                                </ng-multiselect-dropdown> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Área Presupuestaria <span class="text-danger text-monospace legend">*</span>
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown  [options]="budgetAreaList"
                                    formControlName="area" [config]="dropdownConfig('description')" class="w-100">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Tipo de Identificación
                            </label>
                            <div class="dropdown-alt input-group">
                                <ngx-select-dropdown  [options]="identifications" (change)="onIdentificationTypeChange($event.value)"
                                    [config]="dropdownConfig('stringData')" class="w-100" >
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 m1-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Documento de Identidad
                            </label>
                            <div class="dropdown-alt input-group">
                                <input style="text-transform: uppercase;"
                            class="form-control form-control-solid form-control-lg" id="cedula" name="cedula"
                             [mask]="mask" type="text"  maxlength="13" autocomplete="0" formControlName="identification">
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-sm-12 col-md-4 c-right mr-0 forward-btn">
                <div class="card-toolbar d-flex justify-content-end">
                    <button [disabled]="reportForm.invalid" data-toggle="tooltip" data-original-title="Generar Reporte" (click)="openReport()"
                        class="btn btn-success btn-pill font-weight-bolder mt-9 mx-2 col-md-auto ">
                        <i class="flaticon2-search-1 icon-2x"></i>
                        Generar Reporte
                    </button>
            </div>
        </div>
</div>
