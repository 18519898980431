import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-layout',
  templateUrl: './modal-layout.component.html',
  styleUrls: ['./modal-layout.component.css']
})
export class ModalLayoutComponent implements OnInit {
  @Input() styles = 'min-height: 400px;'
  @Output() onClose = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  close(){
    this.onClose.emit(true)
  }

}
