<div class="loading-layer">
    <div class="loading-box">
        <div class="loading">
            <div class="loading-container">
                <div class="loading-circle"></div>
                <!-- <div id="loading-text">loading</div> -->
                <img id="loading-text" src="../../../assets/logos/logo-sigei-100-02.png" alt="">
            </div>
            <!--// Link Attribution -->
            <span class="text">PROCESANDO...</span>
        </div>
    </div>
</div>