import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation, AfterViewChecked } from '@angular/core';
import { LoaderService } from 'src/app/layout/loader/loader.service';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavComponent implements OnInit, AfterViewChecked {

  constructor(public loaderService: LoaderService, private _changeDet: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }
}
