import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecruitmentQuestionsAnswer } from 'src/app/models/recruitment-questions-answer.model';
import { QuestionType, RecruitmentQuestions } from 'src/app/models/recruitment-questions.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { RecruitmentQuestionsService } from 'src/app/services/recruitment-questions.service';
import { personAdditionalInformation } from 'src/app/shared/models/candidate-registration.model';
import { CandidateRegistrationComponent } from '../../candidate-registration.component';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalInfoComponent implements OnInit {
  public anotherJob = { yes: true, no: false }
  public institutionTransportation = { yes: true, no: false }
  public disease = { yes: true, no: false }
  public socialOrganization = { yes: true, no: false }
  public legalProcess = { yes: true, no: false }

  @Output() changeValidation: EventEmitter<boolean> = new EventEmitter();

  public data = {
    paiid: 0,
    personId: 0,
    anotherJob: false,
    anotherPositionId: 0,
    descriptionOtherJob: '',
    jobEntryHour: null,
    jobExitHour: null,
    saturdays: false,
    sundays: false,
    holidays: false,
    night: false,
    workDaysId: 0,
    institutionTransportation: false,
    socialOrganization: false,
    descriptionSocialOrganization: '',
    disease: false,
    descriptionDisease: '',
    legalProcess: false,
    descriptionLegalProcess: '',
    positionToApply: '',
    salaryToAspire: '',
    anotherPositionToFill: '',
    enclosureId: 0,
    bankAccountNumber: '',
    companyId: this.authInfo.getCompanyId(),
    status: false
  } as personAdditionalInformation
  public hora;
  recruitmentQuestions: RecruitmentQuestions[] = [];
  recruitmentQuestionsAnswer: RecruitmentQuestionsAnswer[]= []
  idPerson: number = 0;
  constructor(
    private cdr: ChangeDetectorRef,
    private authInfo: AuthInfoService,
    private route: ActivatedRoute,
    private recruitmentQuestionsService: RecruitmentQuestionsService
  ) { }

  ngOnInit(): void {
    this.validateForm();
    this.idPerson = Number(this.route?.snapshot?.params["id"] ?? 0)
    if(this.idPerson === 0){
      this.getRecruitmentQuestions([]);
    }
  }

  changeDetector() {
    this.cdr.detectChanges();
  }

  getRecruitmentQuestions(recruitmentQuestionsAnswer:  RecruitmentQuestionsAnswer[]){
    this.recruitmentQuestionsService.getRecruitmentQuestions(QuestionType.AdditionalInformation).subscribe({
      next: res => {
        if(res.errors.length > 0){
          return;
        }
        this.recruitmentQuestions = res.dataList;
        this.recruitmentQuestionsAnswer = this.recruitmentQuestions?.map(r => {
          return {
            recruitmentQuestionsAnswerId: 0,
            personId: 0,
            recruitmentQuestionsId: r.recruitmentQuestionsId,
            answer: false,
            comment: '',
            companyId: this.authInfo.getCompanyId(),
            question: r.question,
            questionTypeId: r.questionTypeId,
            status: true,
            createDate: new Date(),
            createUserId: this.authInfo.getUserId(),
          }
        });
        this.recruitmentQuestionsAnswer = this.recruitmentQuestionsAnswer.map(r => {
          const recruitmentQuestion = recruitmentQuestionsAnswer?.find(rq => rq?.recruitmentQuestionsId === r?.recruitmentQuestionsId)
          if(recruitmentQuestion?.questionTypeId){
            r = {...recruitmentQuestion};
          }
          return {...r}
        })
        this.changeDetector();
      }
    })
  }

  validateForm() {
    /*if (this.data.socialOrganization) {
      if (this.data.descriptionSocialOrganization?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '').length < 1) {
        this.changeValidation.emit(false);
        return;
      }
    } else {
      this.data.descriptionSocialOrganization = ''
    }*/

    // if (this.data.anotherJob) {
    //   if (this.data.descriptionOtherJob.length < 1 || !this.data.jobEntryHour || !this.data.jobExitHour) {
    //     this.changeValidation.emit(false);
    //     return;
    //   }

    //   if (this.data.jobEntryHour >= this.data.jobExitHour) {
    //     this.changeValidation.emit(false);
    //     return;
    //   }

    // } else {
    //   this.data.descriptionOtherJob = '';
    //   this.data.jobEntryHour = null;
    //   this.data.jobExitHour = null;
    // }


    /*if (this.data.disease) {
      if (this.data.descriptionDisease?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '').length < 1) {
        this.changeValidation.emit(false);
        return;
      }
    } else {
      this.data.descriptionDisease = ''
    }*/
    /*if (this.data.legalProcess) {
      if (this.data.descriptionLegalProcess?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '').length < 1) {
        this.changeValidation.emit(false);
        return;
      }
    } else {
      this.data.descriptionLegalProcess = ''
    }*/
    this.changeValidation.emit(!this.recruitmentQuestionsAnswer?.some(r => r?.answer && r?.comment?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')?.length === 0));
  }

  validateHours() {
    if (this.data.anotherJob) {
      if (this.data.descriptionOtherJob?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '').length < 1 || !this.data.jobEntryHour || !this.data.jobExitHour) {
        this.changeValidation.emit(false);
      }  
      if ( this.data.jobExitHour && this.data.jobEntryHour >= this.data.jobExitHour) {
        this.changeValidation.emit(false);
        return false;
      }
      else return true
    }

    else{
      this.data.descriptionOtherJob = '';
      this.data.jobEntryHour = null;
      this.data.jobExitHour = null;
    }

    return true
  }

  changeQuestion(recruitmentQuestionsAnswer:  RecruitmentQuestionsAnswer, answer: boolean){
    this.recruitmentQuestionsAnswer?.forEach(r => {
      if(r?.recruitmentQuestionsId === recruitmentQuestionsAnswer?.recruitmentQuestionsId){
        r.answer = answer;
        r.comment = r.answer ? r.comment : '';
        this.changeDetector();
      }
    });
    this.validateForm();
  }

  getRecruitmentQuestionsAnswer(){
    return this.recruitmentQuestionsAnswer?.filter(r => {
      if(r?.recruitmentQuestionsAnswerId === 0 && !r.answer){
        return;
      }
      return r;
    }) ?? [];
  }

}