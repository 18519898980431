import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import { ICancelPersonalActionRequestPost, ICancelPersonalActionRequestView, IConditionCancelRequest, IResultCancelRequest } from '../shared/models/employee-condition.model';

@Injectable({
  providedIn: 'root'
})
export class CancelPersonalActionService {

  private readonly API_HRM = environment.api_url;

  constructor(private httpClient: HttpClient, private authInfo: AuthInfoService) { }

  getCancelRequests(paramsRequest: { 
    ActionCanceledId: number,
    PersonalActionId: number,
    TypePersonalActionId: number,
    TypePermissionId: number,
    RequestByUserId: number,
    ApprovedByUserId: number,
    AppliedByUserId: number,
    RejectedByUserId: number,
    EmployeeId: number,
    Type: number
  } ) {

    return this.httpClient.get<ResponseModel<ICancelPersonalActionRequestView>>(`${this.API_HRM}/CancelEmployeePersonalActionRequest`,{
      params: <any> paramsRequest
    });

  }

  insertCancelRequest(model: ICancelPersonalActionRequestPost){
    return this.httpClient.post<ResponseModel<null>>(`${this.API_HRM}/CancelEmployeePersonalActionRequest`,model);
  }

  approveAndApplyRequest(model: IConditionCancelRequest){
    return this.httpClient.post<ResponseModel<IResultCancelRequest>>(`${this.API_HRM}/CancelEmployeePersonalActionRequest/ApproveAndApply`,model);
  }

  rejectRequest(model: IConditionCancelRequest){
    return this.httpClient.post<ResponseModel<IResultCancelRequest>>(`${this.API_HRM}/CancelEmployeePersonalActionRequest/Reject`,model);
  }

  applyRequest(model: IConditionCancelRequest){
    return this.httpClient.post<ResponseModel<IResultCancelRequest>>(`${this.API_HRM}/CancelEmployeePersonalActionRequest/Apply`,model);
  }

}
