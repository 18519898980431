
<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Considerar Vacaciones colectivas
    </h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content">
    <div>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="card mb-3">
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-auto">
                                <i class="far fa-calendar text-primary fa-4x"></i>
                            </div>
                            <div class="col">
                                <h5 class="text-secondary mt-0 mb-2">Año</h5>
                                <h2 class="text-primary mt-0 mb-2">
                                    2024
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="card mb-3">
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-auto">
                                <i class="fas fa-users text-primary fa-4x"></i>
                            </div>
                            <div class="col">
                                <h5 class="text-secondary mt-0 mb-2">Cantidad de servidores</h5>
                                <h2 class="text-primary mt-0 mb-2">
                                    489
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="form-group mb-3">
                            <label>Fecha de inicio <span class="text-danger">*</span></label>
                            <div>
                                <input type="date" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="form-group mb-3">
                            <label>Fecha de término <span class="text-danger">*</span></label>
                            <div>
                                <input type="date" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="form-group mb-3">
                            <label>Cantidad de días <span class="text-danger">*</span></label>
                            <div>
                                <input type="text" class="form-control" readonly />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="form-group mb-3">
                            <label>Año al que pertenecen <span class="text-danger">*</span></label>
                            <div>
                                <ngx-select-dropdown
                                    [config]="commonDropDownSettings.Ngx.Single"
                                    [multiple]="commonDropDownSettings.Ngx.Single.multiple"
                                    [options]="anhoPerteneceList">
                                </ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="form-group mb-3">
                            <label>Comentario</label>
                            <div>
                                <textarea rows="4" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="mt-3 mb-3" />
                <div class="text-end">
                    <button class="btn btn-pill btn-success" type="button" (click)="save()">
                        <i class="flaticon-disco-flexible"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th class="text-left">Inicio</th>
                        <th class="text-left">Termina</th>
                        <th class="text-left">Cantidad</th>
                        <th class="text-left">Año pertenece</th>
                        <th style="width: 120px;">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of [
                        {secuencia:1, inicio: '22/Abr/2024', termina: '27/Abr/2024', cantidad: 6, anhoPertenece: '2024 - 2025'},
                        {secuencia:2, inicio: '17/Dic/2024', termina: '31/Dic/2024', cantidad: 15, anhoPertenece: '2024 - 2025'}
                        ]">
                        <td class="text-center">{{ item.secuencia }}</td>
                        <td class="text-left">{{ item.inicio }}</td>
                        <td class="text-left">{{ item.termina }}</td>
                        <td class="text-left">{{ item.cantidad }}</td>
                        <td class="text-left">{{ item.anhoPertenece }}</td>
                        <td class="text-center">
                            <a tooltip="ver" placement="top" class="btn btn-sm btn-light-success btn-icon mr-2">
                                <i class="flaticon-pencil" data-toggle="tooltip" data-title="Editar"></i>
                            </a>
                            <a tooltip="ver" placement="top" class="btn btn-sm btn-light-danger btn-icon mr-2">
                                <i class="flaticon-delete-1" data-toggle="tooltip" data-title="Editar"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
</div>
<div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2 " (click)="close()" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>