import { ParameterControl } from "src/app/shared/models/parameter-control.model";

export class PersonalRequisitionsModel {
    public personalRequisitionId?: number;
    public departmentId: number;
    public enclosureId: number;
    public applicationDate: string;
    department: string;
    positionName: string;
    workingHours: string;
    vacancyCause: string;
    requisitionType: string;
    sex: string;
    supervisor: string;
    supervisorPosition?: any;
    public numberPlaces: number;
    public vacancyCauseId: number;
    public creationCause?: string;
    public requisitionTypeId: number;
    public workingHoursId: number;
    public minimumAge: number;
    public maximumAge: number;
    public experience: number;
    public experiences: ParameterControl;
    public academicLevelId: number;
    public otherRequirements: string;
    public generalDescription: string;
    public observations: string;
    public requestedby: string;
    public requestedDate: string;
    public desirableRequirements: string;
    public sexId: number;
    public companyId: number;
    public status: boolean
    public positionId: number;
    public condition: boolean
    public requisitionMethodId: number;
    public requisitionMethod: string;
    public isPlanned: boolean;
    public planningHeaderId: number;
    public userId: number;
    public contractAdmissionDate: any;
    public contractEndingDate: any;
    constructor() {
        this.contractAdmissionDate = null;
        this.contractEndingDate = null;
        this.departmentId = null;
        this.enclosureId = 0;
        this.applicationDate = null;
        this.numberPlaces = 0;
        this.vacancyCauseId = null;
        this.creationCause = null;
        this.requisitionTypeId = null;
        this.workingHoursId = null;
        this.minimumAge = 0;
        this.maximumAge = 0;
        this.experience = null;
        this.academicLevelId = null;
        this.otherRequirements = '';
        this.generalDescription = '';
        this.observations = '';
        this.requestedby = '';
        this.requestedDate = null;
        this.desirableRequirements = '';
        this.sexId = null;
        this.companyId = 0;
        this.status = true
        this.positionId = null;
        this.condition = null;
        this.requisitionMethodId = 0;
        this.requisitionMethod = '';
        this.isPlanned = false;
        this.planningHeaderId = 0;
        this.userId = null;
    }

}

export class SkillsRequired {
    public id?: number;
    public name: string;
    public requisitionSkillLevelId: number;
    public personalRequisitionId: number;
    public level: string;
}

export interface IRequisitionView {
    personalRequisitionId: number;
    enclosureId: number;
    enclosure: string;
    departmentId: number;
    applicationDate: Date;
    numberPlaces: number;
    vacancyCauseId: number;
    vacancyCause: string;
    requisitionTypeId: number;
    requisitionType: string;
    workingHoursId: number;
    workingHours: string;
    minimumAge: number;
    maximumAge: number;
    experience?: any;
    academicLevelId: number;
    academicLevel: string;
    otherRequirements: string;
    generalDescription: string;
    observations: string;
    requestedby: string;
    requestedDate: Date;
    desirableRequirements: string;
    sexId: number;
    sex: string;
    department: string;
    positionId: number;
    positionName: string;
    approvalStatus: boolean;
    condition: boolean;
    status: boolean;
    companyId: number;
    supervisor: string;
    supervisorPosition: string;
    creationCause?: any;
    supervisorId: number;
    approvationDate: Date;
    reasonToBeRemoved?: any;
    shortNameDepartement: string;
    requisitionMethodId: number;
    requisitionMethod: string;
    positionConcursaCode?: any;
    maxSalary: number;
    halfSalary: number;
    minSalary: number;
    concursaCondition: number;
    createUserId?: number;
    modifyUserId: number;
    concursaId: number;
}

export enum CauseRequisition {
    Resignation = 1,
    CreationCharge,
    AbandonmentCharge,
    Transfer,
    DismissalEmployee,
    Retirement,
    Holidays,
    License,
    Others,
    Interinato
}