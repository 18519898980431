import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { FilterDashboardService } from 'src/app/services/filter-dashboard.service';
interface IFilterParams { year: any, date1?: any, date2?: any, yearsList: any }
@Component({
  selector: 'app-filter-dashboard',
  templateUrl: './filter-dashboard.component.html',
  styleUrls: ['./filter-dashboard.component.css']
})
export class FilterDashboardComponent implements OnInit {


  homeConfig = false
  errorMessage = ''
  hideYearField = true;
  form: FormGroup
  years;
  yearsList = []
  dropdownItems = []
  filteredPerYear = false
  constructor(
    public dialogRef: MatDialogRef<FilterDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private authInfo: AuthInfoService,
    private filterService: FilterDashboardService,
    private toastr: ToastrService
  ) {
    this.dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    if (this.data?.hideYearField == false) {
      this.hideYearField = this.data.hideYearField
    }
    this.setYears()
    this.buildForm()
    const params = this.data.filterParams
    if (Array.isArray(params?.year)) {
      if (params.length === 1) {
        if (params[0].year === moment().startOf('date').year()) {
          this.filteredPerYear = false
        }
        if (params[0].year !== moment().startOf('date').year()) {
          this.filteredPerYear = true
        }
      } else if (params?.year?.length >= 1) {
        this.filteredPerYear = true
      }
    }

    this.getCompanies()
    this.form.patchValue({
      year: params.year,
      date1: params.date1,
      date2: params.date2,
      companyId: params.companyId
    })
    if (this.data?.homeConfig == true) {
      this.homeConfig = true
      this.setHomeValues(params)
    }
  }
  formValidation(): boolean{
    const { company, date1, date2 } = this.form.value
    const { constitutionDate } = company
    if(moment(constitutionDate) > moment(date1)){
      this.errorMessage = 'Fecha desde debe ser superior a la fecha de constitución de la institución. Fecha constitución: ' + moment(constitutionDate).startOf('date').format('DD/MM/yyyy')
      return true
    }else{
      this.errorMessage = ''
    }
    if(moment(date1) > moment(date2)){
      this.errorMessage = 'Fecha desde debe ser superior a la fecha hasta'
      return true
    }else {
      this.errorMessage = ''
    }
    if (!company) {
      this.toastr.warning('Debe seleccionar una institución')
      return true
    }
    return false
  }

  closeModal() {
    let filter: any;
    const form = this.form

    const formIsValid = this.formValidation()
    if(formIsValid){
      return
    } 

    if (this.homeConfig == true) {
      filter = this.getValuesMultipleYears()
      this.dialogRef.close(filter)
    } else {
      filter = {
        year: null,
        date1: form.get('date1').value,
        date2: form.get('date2').value,
        yearsList: [],
        companyId: form.get('companyId').value
      }

      const params = this.data.filterParams
      if (params.dateTo == form.get('date1').value && params.dateFrom == form.get('date2').value) {
        this.closeWithoutFilter()
      } else {
        this.dialogRef.close(filter)
      }
    }

  }

  close() {
    this.closeWithoutFilter()
  }

  changeYear() {
    let y = this.form.get('year').value
    if (y.length > 1) {
      this.form.get('date1').disable()
      this.form.get('date1').patchValue(null)
      this.form.get('date2').patchValue(null)
      this.form.get('date2').disable()
    } else {
      this.form.get('date1').enable()
      this.form.get('date2').enable()
    }
  }

  selectYear() {
    this.form.get('date1').setValue(`${this.form.get('year').value}-01-01`)
    this.form.get('date2').setValue(this.data.filterParams.date2)

  }
  selectDate1() {
    this.form.get('year').patchValue(null)
  }
  selectDate2() {
    this.form.get('year').patchValue(null)
  }
  clear() {
    const year = moment().startOf('date').year()
    this.form.patchValue({
      year: this.years[0],
      date1:this.data.filterParams.date1,
      date2: this.data.filterParams.date2
    })
  }
  closeWithoutFilter() {
    this.dialogRef.close({ applyFilter: false })
  }
  convertYearsInArray(): number[] {
    let data = []
    const years = this.form.get('year').value
    if (Array.isArray(years)) {
      if (years.length > 0) {
        years.forEach(e => {
          data.push(e.year)
        })
        return data
      }
    } else {
      return [years.year]
    }
  }
  getMinMaxYear(arr): number[] {
    const min: number = arr.reduce((p, v) => (p < v ? p : v));
    const max: number = arr.reduce((p, v) => (p > v ? p : v));
    return [min, max]
  }
  getDatesFromYears() {
    const years = this.convertYearsInArray()
    const minMax = this.getMinMaxYear(years)
    const date1 = `${minMax[0]}-01-01`
    const date2 = this.data.filterParams.date2
    return [date1, date2]
  }




  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: 'más...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey,
      limitTo: 0
    }
  }

  setYears() {
    const year = new Date().getFullYear()
    this.years = []
    for (let i = 0; i < 5; i++) {
      this.years.push({ year: year - i })
    }
  }

  getValuesMultipleYears(): IFilterParams {
    let filter: any;
    const form = this.form
    const year = this.form.get('year').value
    this.yearsList = [];

    if (year.length > 0) {
      for (let i = 0; i < year.length; i++) {
        let y = year[i]['year']
        this.yearsList.push({ year: y });
      }

      filter = {
        date1: this.getDatesFromYears()[0],
        date2: this.getDatesFromYears()[1],
        year: year,
        yearsList: this.yearsList,
      }
    } else {
      filter = {
        year: year.year,
        date1: form.get('date1').value,
        date2: form.get('date2').value,
        yearsList: []
      }
    }
    filter.companyId = form.value.companyId
    return filter;
  }
  setHomeValues(params) {
    if (Array.isArray(params.year) || !params.year) {
      this.form.patchValue(params)
    } else {
      if (params.year.year) {
        this.form.patchValue({
          year: this.years.find(c => c.year == params.year.year),
        })
        return
      }
      this.form.patchValue({
        year: this.years.find(c => c.year == params.year),
      })
    }
  }
  buildForm() {
    this.form = this.fb.group({
      year: null,
      date1: null,
      date2: null,
      company: null,
      companyId: null
    })
  }
  changeCompany() {
    const company = this.form.get('company')
    if (!company.value || company.value.length < 1) {
      this.form.get('companyId').patchValue(null)
      return
    }
    this.form.get('companyId').patchValue(company.value.companyId)
  }
  getCompanies() {
    const companyId = this.authInfo.getCompanyId()
    this.filterService.getCompaniesById(companyId).subscribe((res: any) => {
      if (res.succeded) {
        this.dropdownItems = res.dataList
        const company = this.dropdownItems.find(x => x.companyId == this.form.get('companyId').value)
        this.form.get('company').patchValue(company)
        if (this.filteredPerYear == false) {
          const companyDate = moment(company.constitutionDate)
          const defaultDate = moment('1980-01-01').format('yyyy-MM-D')
          if(this.data.filterParams.date1){
            return
          }
          if(company.constitutionDate == null){
            this.form.get('date1').patchValue(defaultDate)
            return
          }
          if(companyDate.isValid){
            this.form.get('date1').patchValue(companyDate.format('yyyy-MM-D'))
          }else{
            this.form.get('date1').patchValue(defaultDate)
          }
        }
      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }
}
