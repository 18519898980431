<div class="card audit-approval border-0">
    <div class="card-header p-3 gutter-b">
        <ul class="nav nav-primary nav-pills text-uppercase" id="myTab2" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="nempleado-tab-2" data-toggle="tab" href="#ine">
                    <span class="nav-icon mr-10">
                        <i class="flaticon-productividad icon-3x"></i>
                    </span>
                    <span class="nav-text font-size-h3">Por Cantidad</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="inomina-tab-2" data-toggle="tab" href="#inom"
                    aria-controls="inomina">
                    <span class="nav-icon mr-10">
                        <i class="flaticon-agil icon-3x"></i>
                    </span>
                    <span class="nav-text font-size-h3">Por Valores</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="inomina-tab-2" data-toggle="tab" href="#idetalle"
                    aria-controls="idetalle">
                    <span class="nav-icon mr-10">
                        <i class="flaticon-agil icon-3x"></i>
                    </span>
                    <span class="nav-text font-size-h3">Detalle</span>
                </a>
            </li>
        </ul>
    </div>
    <div class="card-body p-0">
        <div class="tab-content" id="myTabContent2">
            <div class="tab-pane fade active show" id="ine" role="tabpanel"
                aria-labelledby="nempleado-tab-2">
                <div class="row justify-content-between">
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalEmployee || 0}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">Total Servidores Públicos</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card card-custom card-stretch gutter-b border">

                            <div class="card-header border-0 pt-5">
                                <div class="form-group col-xl-3 col-lg-6">
                                    <label>Tipo de Servidores Públicos</label>
                                    <ngx-select-dropdown class="flex-1" [options]="employeeTypes"
                                        [config]="configType" (change)="employeeTypeChange($event)"
                                        [(ngModel)]="selectedEmployeeType">
                                    </ngx-select-dropdown>
                                </div>

                            </div>


                            <div class="card-body pt-2 pb-0 mt-n3">
                                <div class="tab-content mt-5" id="myTabTables5">

                                    <div class="tab-pane fade active show" id="kt_tab_pane_5_1"
                                        role="tabpanel" aria-labelledby="kt_tab_pane_5_1">
                                        <div class="table-responsive" style="min-height: 600px;">
                                            <table class="table table-vertical-center">
                                                <thead>
                                                    <tr class="bg-primary">
                                                        <th class="min-w-150px">Unidad organizativa</th>
                                                        <th class="min-w-140px text-center">Cantidad de Servidores Públicos</th>
                                                        <th class="min-w-110px text-center">Total Salario Neto
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let item of payrollDetailDepartmentsFilteredCopy">
                                                        <td class="text-left">
                                                            <span class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{{item?.departmentName}}</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <span (click)="viewDetails(item)" class="label-lg p-1 px-2 rounded label-light-success cursor-pointer"><strong>{{item?.employeeCount}}</strong></span>
                                                        </td>
                                                        <td class="text-right">
                                                            <span class="label label-lg pt-1 pb-1 label-light-success label-inline"><strong>{{item?.totalNetIncome
                                                                | currency:"RD$"}}</strong></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div>

                                                <el-caption #paginator
                                                    [originalRecordsLength]="payrollDetailDepartmentsFiltered.length"
                                                    [listedRecordsLength]="payrollDetailDepartmentsFilteredCopy.length"
                                                    [registerQuantity]="payrollDetailDepartmentsFiltered.length"
                                                    [records]="payrollDetailDepartmentsFiltered"
                                                    (onChage)="getPaginatedRecordsForDepartments($event)">
                                                </el-caption>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="inom" role="tabpanel" aria-labelledby="inomina-tab-2">
                <div class="row justify-content-between">
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalEmployee || 0}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block"> Servidor Público</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalGrossSalary || 0
                                    | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">Salario
                                Bruto</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalNetIncome || 0
                                    | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">Salario
                                Neto</a>
                        </div>
                    </div>
                    
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalDiscount || 0
                                    | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">Descuentos</a>
                        </div>
                    </div>

                </div>
                <div class="row justify-content-between">
                    
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalTss || 0 | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">TSS</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalIsr || 0
                                    | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">ISR</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalSfs || 0
                                    | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">SFS</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalAfp || 0
                                    | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">AFP</a>
                        </div>
                    </div>
                    
                </div>

                <div class="row justify-content-between">
                    
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalSfse || 0
                                    | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">SFS Empleador</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalAfpe || 0
                                    | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">AFP Empleador</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalSrl || 0
                                    | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">SRL(Seguro Riesgo Laboral)</a>
                        </div>
                    </div>
                   
                </div>
                
                <div class="row justify-content-between">
                    
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalOtherCredits || 0
                                    | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">Otros ingresos</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalOtherDebits || 0
                                    | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">Otros descuentos</a>
                        </div>
                    </div>
                    <div class="card card-custom col bg-light-info card-shadowless gutter-b mx-3">
                        <div class="card-body p-4">
                            <div class="font-weight-bold text-muted text-center">
                                <span
                                    class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{payrollDetailResume?.totalInavi || 0
                                    | currency:"RD$"}}</span>
                            </div>
                            <a
                                class="card-title text-center font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block">INAVI</a>
                        </div>
                    </div>
                   
                </div>

                
                    
                    
            </div>
            <div class="tab-pane fade" id="idetalle" role="tabpanel" aria-labelledby="inomina-tab-2">
                <div class="d-flex justify-content-end mr-3">
                    <el-search-bar #searchBar (onKeyChange)="onKeyChange($event)" [keyValue]="filterText"
                    [records]="payrollAllDetailsOriginalList" [itemForSearching]="searchBarDisplayNames"
                    (onChange)="getFilteredList($event);">
                  </el-search-bar>
                </div>
                <div class="row justify-content-between">
                    <div class="table-responsive">
                        <table class="table table-vertical-center">
                            <thead>
                                <tr class="bg-primary">
                                    <th class="min-w-150px">Servidor Público</th>
                                    <th class="min-w-140px text-center">Sexo</th>
                                    <th class="min-w-110px ">Cargo</th>
                                    <th class="min-w-110px ">Tipo</th>
                                    <th class="min-w-110px text-right">Ingreso Bruto</th>
                                    <th class="min-w-110px text-right">Otros Ingresos</th>
                                    <th class="min-w-110px text-right">Ingreso Total</th>
                                    <th class="min-w-110px text-right">AFP</th>
                                    <th class="min-w-110px text-right">SFS</th>
                                    <th class="min-w-110px text-right">ISR</th>
                                    <th class="min-w-110px text-right">Otro Desc.</th>
                                    <th class="min-w-110px text-right">Total Desc.</th>
                                    <th class="min-w-110px text-right">Neto</th>
                                </tr>
                            </thead>
                            <tbody>
                               <ng-container *ngFor="let item of payrollAllDetailsFiltered">
                                    <tr>
                                        <th class="text-left bg-light-secondary" colspan="14" >
                                            <span class="text-dark text-uppercase ">
                                                {{item?.departmentName}}
                                            </span>
                                        </th>
                                    </tr>
                                    <ng-container  *ngFor="let department of item?.departments">
                                        <tr>
                                            <td>{{department.fullName}}</td>
                                            <td class="text-center">
                                                <span
                                                    class="text-muted font-weight-500"><strong>{{department?.sex}}</strong></span>
                                            </td>
                                            <td class="">{{department?.positionName}}</td>
                                            <td class="">{{department?.employeeType}}</td>
                                            <td class="text-right">{{department?.grossSalary | currency: 'RD$'}}</td>
                                            <td class="text-right">{{department?.otherCredits | currency: 'RD$'}}</td>
                                            <td class="text-right">{{department?.totalSalary | currency: 'RD$'}}</td>
                                            <td class="text-right">{{department?.afp | currency: 'RD$'}}</td>
                                            <td class="text-right">{{department?.sfs | currency: 'RD$'}}</td>
                                            <td class="text-right">{{department?.isr | currency: 'RD$'}}</td>
                                            <td class="text-right">{{department?.otherDiscounts | currency: 'RD$'}}</td>
                                            <td class="text-right">{{department?.totalDiscount | currency: 'RD$'}}</td>
                                            <td class="text-right">{{department?.netIncome | currency: 'RD$'}}</td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td class="text-left fw-bolder">Sub totales:</td>
                                        <td class="text-left"></td>
                                        <td class="text-left"></td>
                                        <td class="text-left"></td>
                                        <td class="text-right fw-bold">{{item.grossSalary | currency: 'RD$'}}</td>
                                        <td class="text-right fw-bold">{{item.otherCredits | currency: 'RD$'}}</td>
                                        <td class="text-right fw-bold">{{item.totalSalary | currency: 'RD$'}}</td>
                                        <td class="text-right fw-bold">{{item.afp | currency: 'RD$'}}</td>
                                        <td class="text-right fw-bold">{{item.sfs | currency: 'RD$'}}</td>
                                        <td class="text-right fw-bold">{{item.isr | currency: 'RD$'}}</td>
                                        <td class="text-right fw-bold">{{item.otherDiscounts | currency: 'RD$'}}</td>
                                        <td class="text-right fw-bold">{{item.totalDiscount | currency: 'RD$'}}</td>
                                        <td class="text-right fw-bold">{{item.netIncome | currency: 'RD$'}}</td>
                                    </tr>
                               </ng-container>
                            </tbody>
                            <tfoot>
                                <tr class="bg-light-secondary">
                                  <td class="text-left fw-bolder">TOTAL GENERAL:</td>
                                  <td class="text-left"></td>
                                  <td class="text-left"></td>
                                  <td class="text-left"></td>
                                  <td class="text-right fw-bolder">{{getTotalOverallSummarie('grossSalary') | currency: 'RD$'}}</td>
                                  <td class="text-right fw-bolder">{{getTotalOverallSummarie('otherCredits') | currency: 'RD$'}}</td>
                                  <td class="text-right fw-bolder">{{getTotalOverallSummarie('totalSalary') | currency: 'RD$'}}</td>
                                  <td class="text-right fw-bolder">{{getTotalOverallSummarie('afp') | currency: 'RD$'}}</td>
                                  <td class="text-right fw-bolder">{{getTotalOverallSummarie('sfs') | currency: 'RD$'}}</td>
                                  <td class="text-right fw-bolder">{{getTotalOverallSummarie('isr') | currency: 'RD$'}}</td>
                                  <td class="text-right fw-bolder">{{getTotalOverallSummarie('otherDiscounts') | currency: 'RD$'}}</td>
                                  <td class="text-right fw-bolder">{{getTotalOverallSummarie('totalDiscount') | currency: 'RD$'}}</td>
                                  <td class="text-right fw-bolder">{{getTotalOverallSummarie('netIncome') | currency: 'RD$'}}</td>
                                </tr>
                              </tfoot>
                        </table>
                        <div>
                                <el-caption #paginator [originalRecordsLength]="payrollAllDetails.length"
                                [listedRecordsLength]="payrollAllDetailsFiltered.length"
                                [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :payrollAllDetails.length"
                                [records]="filteredList.enable ? filteredList.records : payrollAllDetails"
                                (onChage)="getPaginatedRecordsForDetails($event)"></el-caption>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>