import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ToastrService } from 'ngx-toastr';
import { ServiceCommissionService } from 'src/app/services/service-commission.service';
import { IEmployeePermissions } from './../models/EmployeePermissions';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { environment } from 'src/environments/environment';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import { IEmployee } from 'src/app/models/Employee.model';
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { DomSanitizer } from '@angular/platform-browser';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { formatDate } from '@angular/common';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-banns',
  templateUrl: './banns.component.html',
  styleUrls: ['./banns.component.css']
})
export class BannsComponent implements OnInit {
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'ACTION-CHANGE-EMPLOYEE',
    bans: 'Ban-Recurring-Actions',
    add: 'Add',
    view: 'View',
    edit: 'Edit',
  }
  motives = []
  formIsInvalid = false
  guiID = '';
  currentDate = new Date().toDateString()
  readonly urlDoc = environment.api_doc + '/Document';
  personalFile = personalFile;
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  sweetAlrt = new SweetAlert()
  institutions = []
  evidenceUppy = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['application/pdf']
    },
    locale:Mexico
  });
  file = { document: '', documentLicense: '' }
  form: FormGroup
  @Input() employee: IEmployee
  @Input() banns: any;
  @Input() isSeviceCommission: boolean = false;

  isEditing: boolean = false;

  constructor(
    private formbuilder: FormBuilder,
    private parameter: ParameterControlService,
    private toastr: ToastrService,
    private srvService: ServiceCommissionService,
    public authInfo: AuthInfoService,
    private sanitizer: DomSanitizer,
    private docService: docService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSeviceCommission?.currentValue) {
      this.form.disable();
    }
  }

  ngOnInit(): void {
    this.form = this.formbuilder.group({
      permissionsId: [0],
      requestDate: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      motive: [null],
      comment: ['', [Validators.required]],
      conditionId: [0],
      evidence: ['', [Validators.required]]
    })

    this.getMotives()
  }

  openGuID(guid) {
    try {
      this.docService.getDocument(guid).subscribe(e => {
        window.open(e.data, '_blank');
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  loadUppy() {
    this.evidenceUppy.use(Dashboard, {
      trigger: '.UppyModalOpenerBtn',
      inline: true,
      target: '.evidenceBanns',
      disabled: !this.employee.status,
      showProgressDetails: true,
      note: 'Solo imagenes, 1 archivo, con un maximo de 1 MB',
      width: 320,
      height: 295,
      locale: {
        strings: {
          dropPasteFiles: 'Arrastra archivos aquí o %{browse}'
        }
      },
      thumbnailWidth: 320,
      metaFields: [
        { id: 'name', name: 'Nombre', placeholder: 'Nombre del Archivo' },
        { id: 'caption', name: 'Caption', placeholder: 'Descripción de la imagen' }
      ],
      browserBackButtonClose: false,
      showLinkToFileUploadResult: true,
    },)
      .use(XHRUpload, { endpoint: this.urlDoc + `?SystemLoad=${this.SystemLoad}&ModuleLoad=${this.ModuleLoad}&personalFile=${this.personalFile.photo2x2}&parameterDoc=${this.routeFile}` })
      .on('complete', (result) => {
        if (result.successful) {
          let resData = result.successful[0].response.body.data as FileResponse
          this.guiID = this.getGuid(resData.fileUnit.guidname)
          this.file.document = resData.path;
          this.form.get('evidence').setValue(this.guiID ? this.guiID : null)
        }
      });
  }
  getMotives() {
    this.parameter.getParameters(keyWord.ServiceCommissionMotives).subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          this.toastr.error(res.errors[0])
          return
        }
        this.motives = res.dataList
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })

    this.parameter.getParameters(parameterDoc.PathTest).subscribe(e => {
      if (e.dataList.length > 0) {
        this.routeFile = e.dataList[0].stringData.toString();
      }
      this.loadUppy()
    })
  }


  resetForm() {
    this.form.patchValue({
      requestDate: null,
      startDate: null,
      endDate: null,
      motive: null,
      comment: '',
      evidence: '',
      permissionsId: 0,
      conditionId: 0,
    })
  }

  save() {
    this.formIsInvalid = this.form.invalid
    if (this.formIsInvalid) return
    const val = this.form.value
    let motiveId = 0
    if (val.motive) {
      motiveId = val.motive.ocode
    }
    let bann: any = {
      typePersonalActionId: 5,
      applicationDate: val.requestDate,
      comment: val.comment,
      companyId: this.authInfo.getCompanyId(),
      dateEnd: val.endDate,
      dateStart: val.startDate,
      employeeId: this.employee.employeeId,
      evidence: val.evidence == '' ? null : val.evidence,
      permissionsId: val?.permissionsId > 0 ? val?.permissionsId : 0,
      status: true,
      conditionId: val?.permissionsId > 0 ? val?.conditionId : 2,
      
    }
    if(this.isEditing){
      this.updatePermission(bann);
    }else{
      this.postPermission(bann);
    }
  }

  postPermission(bann){
    this.srvService.postPermission(bann).subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          this.toastr.error(res.errors[0])
          return
        }
        this.toastr.success("Datos guardados");
        this.resetForm()
        this.getBanns()
        this.evidenceUppy.cancelAll();
        this.file.document = '';
        this.guiID = '';
        this.isEditing = false;
      }, error: (error: any) => {
        this.toastr.error("Ha ocurrido un error al modificar el registro");
      }
    })
  }

  updatePermission(serviceCommision){
    this.srvService.updatePermission(serviceCommision).subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          this.toastr.error(res.errors[0])
          return;
        }
        this.toastr.success("Registro modificado exitosamente", "Registro Modificado");
        this.resetForm()
        this.getBanns()
        this.evidenceUppy.cancelAll();
        this.file.document = '';
        this.guiID = '';
        this.isEditing = false;
      }, error: (error: any) => {
        this.toastr.error("Ha ocurrido un error al modificar el registro");
      }
    })
  }

  getBanns() {
    this.srvService.getEmployeePermissionsByIdEmployee(this.employee.employeeId).subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          this.toastr.error(res.errors[0])
          return
        }
        this.banns = res.dataList.filter(x => x.typePersonalActionId == 5);
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }


  deleteGuid() {
    try {
      if (this.guiID) {
        this.docService.deleteFile(this.guiID).subscribe(e => {
          this.evidenceUppy.cancelAll()
          this.file.document = '';
          this.guiID = ''
          this.form.get('evidence').setValue(null);
        }, error => {
          this.toastr.error(error)
        })
      }
    } catch (error) {
      this.toastr.error(error)
    }
  }




  dropdownConfig(displayKey, placeholder?) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  getGuID(guid) {
    try {
      this.docService.getDocument(guid).subscribe(e => {
        this.file.document = e.data
        this.guiID = guid;
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  showError(name: string) {
    const field = this.form.get(name)
    if (field.hasError('required')) {
      return 'Este campo es requerido'
    }
    return ''
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  editBann(bann){
    this.isEditing = true;
    this.form.patchValue({
      ...bann,
      requestDate: formatDate(bann?.applicationDate, "yyyy-MM-dd", "en"),
      startDate: formatDate(bann?.dateStart, "yyyy-MM-dd", "en"),
      endDate: formatDate(bann?.dateEnd, "yyyy-MM-dd", "en"),
    });
    if(bann?.evidence){
      this.guiID = bann?.evidence;
      this.file.document = bann?.evidence;
      this.getEvidence(bann?.evidence)
    }else{
      this.file.document = '';
    }
  }

  getEvidence(guid){
    this.docService.getDocument(guid).subscribe(res => {
      this.file.document = res.data ? res.data : ''
    })
  }

}
