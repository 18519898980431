<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>Directorio
        Telefónico</h2>
    <button type="button" (click)="dialogRef.close()" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>

</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom my-0 py-0">
        <div class="row" [formGroup]="form">
            <div class="form-group col-md-12">
                <div>
                    <label for="">
                        Unidad Organizativa
                    </label>
                </div>
                <input [value]="data?.department?.departmentName" type="text" readonly
                    class="form-control form-control-lg dynamicFormControl">
            </div>
            <div class="form-group col-md-12">
                <div>
                    <label for="">
                        Correo Electrónico<span class="text-danger text-monospace legend">* </span>
                    </label>
                </div>
                <input formControlName="email" type="text"
                    class="form-control form-control-lg dynamicFormControl">
                <span class="form-text text-danger" *ngIf="formInvalid">
                    {{getError('email')}}
                </span>
            </div>
            <div class="form-group col-md-12">
                <div>
                    <label for="">
                        Número de Teléfono<span class="text-danger text-monospace legend">* </span>
                    </label>
                </div>
                <input formControlName="phoneNumber" type="text" mask="(000)-000-0000"  type="mask"
                    class="form-control form-control-lg dynamicFormControl">
                <span class="form-text text-danger" *ngIf="formInvalid">
                    {{getError('phoneNumber')}}
                </span>
            </div>
            <div class="d-flex justify-content-between">
                <div>
                    <h2 class="font-size-h5 text-primary mt-3">Extensiones
                    </h2>
                </div>
                <div class="d-flex justify-content-between gap-2 align-items-center">
                    <div>
                        <input placeholder="Extensión" type="text"
                        formControlName="extension"
                        appNumbersOnly [allowDecimals]="false" [allowSign]="false"
                            class="form-control form-control-lg dynamicFormControl">
                    </div>
                    <div>
                        <button (click)="saveExtension()" [disabled]="isExtensionValid" class="btn btn-success btn-pill font-weight-bolder">
                            <ng-container *ngIf="!phoneExtension?.phoneExtensionId else editExtension">
                                <i class="flaticon-mas" data-toggle="tooltip"
                                data-original-title="Agregar Extensión"></i>
                            </ng-container>
                            <ng-template #editExtension>
                                <i class="flaticon-mas" data-toggle="tooltip"
                                data-original-title="Guardar Extensión"></i>
                            </ng-template>
                            {{phoneExtension?.phoneExtensionId ? 'Guardar' : 'Agregar'}} 
                        </button>
                    </div>
                </div>
            </div>
          
        </div>
        <table class="table mt-2 table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th>Extensión</th>
                    <th class="actionsBtn">
                        Acciones
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of phoneExtensions; let i = index">
                    <td>{{item?.extension}}</td>
                    <td class="actionsBtn">
                        <button (click)="editPhoneExtension(item, i)" class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip"
                            title="Editar">
                            <i class="flaticon-edit"></i>
                        </button>
                        <button (click)="deletePhoneExtension(item, i)" class="btn btn-icon btn-light-danger btn-sm m-1" data-toggle="tooltip"
                            title="Eliminar">
                            <i class="flaticon-delete"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="mat-dialog-actions justify-content-end" [align]="'end'">
    <button  (click)="dialogRef.close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2"
        type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button (click)="save()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>