import { Component, Inject, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { DepartmentService } from 'src/app/services/department.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PositionService } from 'src/app/services/position.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ViewReasonRejectComponent } from '../view-reason-reject/view-reason-reject.component';

enum PositionCodeTeacherPerHour {
  PositionCode = 'DOCXH'
}

@Component({
  selector: 'app-create-position-modal',
  templateUrl: './create-position-modal.component.html',
  styleUrls: ['./create-position-modal.component.css']
})
export class CreatePositionModalComponent implements OnInit, AfterViewChecked {
  deparments: any[] = [];
  groups: any[] = [];
  concursaPositions: any[] = []
  concursaPositionsOriginal = []
  concursaIdRequired: boolean = false
  companies: any[] = []
  companyName:any

  salariesErros = {
    avg: '',
    max: '',
    min: ''
  }
  companyId: number = 0

  positionForm: FormGroup;

  constructor(private _builder: FormBuilder,
    public authInfo: AuthInfoService,
    private positionService: PositionService,
    public dialogRef: MatDialogRef<CreatePositionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private _toastr: ToastrService, private _cdRef: ChangeDetectorRef,  private parameterService: ParameterControlService) { }

  ngOnInit(): void {
    this.buildForm();
    this.getData()
    this.positionForm.get('requieredStatus').disable()
    this.positionForm.get('approvalStatus').disable()
    if (this.data.isView || this.data.isEdit) {
      if (this.data.isView) {
        this.positionForm.disable()
      }
      this.getPosition()
    }
    if(this.data?.position?.positionCode === PositionCodeTeacherPerHour.PositionCode){
      this.positionForm.get('teacherPerHour').setValue(true)
      this.isTeacherPerHour(true);
    }
  }

  private buildForm(){
    this.positionForm = new FormGroup({
      company: new FormControl(''),
      positionId: new FormControl(0),
      positionName: new FormControl(null, [Validators.required,Validators.pattern('^([^\\s]+[A-Za-z0-9_]*\\s)*[^\\s]*$') ]),
      requieredStatus: new FormControl(null),
      approvalStatus: new FormControl(null),
      requestedPositions: new FormControl(1, [Validators.required, ]),
      minSalary: new FormControl(null, [Validators.required, ]),
      halfSalary: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.min(1)]),
      maxSalary: new FormControl(null, [Validators.required, Validators.min(1)]),
      approvalPositions: new FormControl(0),
      approvalDoc: new FormControl('',),
      occupationalGroup: new FormControl('', [Validators.required, ]),
      countTest: new FormControl(1, [Validators.required, ]),
      countInterviews: new FormControl(1, [Validators.required, ]),
      isSupervisor: new FormControl(false, [Validators.required, ]),
      status: new FormControl(true, [Validators.required, ]),
      positionCode: new FormControl(null, [Validators.required, Validators.pattern('^([^\\s]+[A-Za-z0-9_]*\\s)*[^\\s]*$')]),
      concursaId: new FormControl(null),
      reasonReject: new FormControl({value: '', disabled: true}),
      teacherPerHour: new FormControl(null)
    })
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }


  getData() {
    this.positionService.getCompaniesById(this.authInfo.getCompanyId()).subscribe({
      next: (res: any) => {
        this.companies = res.dataList
        this.companyName = this.companies[0].companyName
        this.positionForm.get('company').setValue(this.companyName)

      },
      error: err => this._toastr.error(err)
    })

    this.positionService.getOcupationalGroup().subscribe({
      next: (res: any) => {
        this.groups = res.dataList
        if (this.data.isView || this.data.isEdit) {
          this.positionForm.get('occupationalGroup').setValue(this.groups.find((group: any) => group.ocode == this.data.position?.occupationalGroup))
        }
      }, error: err => this._toastr.error(err)
    })

    this.positionService.getConcursaPositions().subscribe({
      next: (res: any) => {
        if (res.succeded) {
          if (res.dataList) {
            this.concursaPositionsOriginal = res.dataList
            if (this.data.isView || this.data.isEdit) {
              this.onOcupationalGroupChanged()
            }
          }
        }
      },
      error: err => this._toastr.error(err)
    })
  }

  private isTeacherPerHour(value: boolean){
    if(value){
      this.positionForm.get('positionCode').setValue(PositionCodeTeacherPerHour.PositionCode);
      this.positionForm.get('positionCode').disable();
    }
  }

  changeTeacherPerHour(value){
    if(value){
      this.isTeacherPerHour(true);
    }else{
      this.positionForm.get('positionCode').setValue(null);
      this.positionForm.get('positionCode').enable();
    }
  }




  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Click para desplegar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  getPosition() {
    this.positionForm.patchValue({...this.data?.position})
    if(this.data?.position?.isSupervisor == null || this.data?.position?.isSupervisor == undefined){
      this.positionForm.get('isSupervisor').setValue(false);
    }
  }

  onOcupationalGroupChanged() {
    const occupationalGroup = this.positionForm.get('occupationalGroup')
    const ccode = occupationalGroup.value.ccode
    this.positionForm.patchValue({ concursaId: null })
    this.getConscursa(ccode)
    this.validConcursa(occupationalGroup.value.ocode)
  }

  validConcursa(group) {
    if (group == 3 || group == 4 || group == 5) {
      this.concursaIdRequired = true
      this.positionForm.get("concursaId").setValidators(null)
      this.positionForm.get("concursaId").updateValueAndValidity()
      if (this.data.isView) {
        this.positionForm.get('concursaId').disable()
        this.positionForm.get('isSupervisor').disable()
        return
      }
      this.positionForm.get('concursaId').enable()
      this.positionForm.get('isSupervisor').enable()
    }
    else {
      this.concursaIdRequired = false
      this.positionForm.get('concursaId').disable()
      this.positionForm.get('isSupervisor').disable()
      this.positionForm.get("concursaId").setValidators(null)
      this.positionForm.get("concursaId").updateValueAndValidity()
      this.positionForm.get("concursaId").setValue(null)
    }
  }

  getConscursa(param) {
    this.concursaPositions = this.concursaPositionsOriginal.filter(x => x.grupoOcupacionalId == param)
    if (this.data.isView || this.data.isEdit) {
      this.positionForm.get('concursaId').setValue(this.concursaPositions.find((concursaPosition: any) => concursaPosition.id == this.data.position?.concursaId))
    }
  }

  calculateHalfSalary() {
    const min = this.positionForm.get('minSalary').value
    const max = this.positionForm.get('maxSalary').value

    const average = ((+min + (+max)) / 2);
    this.positionForm.get('halfSalary').setValue(average);

    this.validateSalaries();
  }

  validateSalaries() {
    const min = this.positionForm.get('minSalary').value
    const avg = this.positionForm.get('halfSalary').value
    const max = this.positionForm.get('maxSalary').value
    this.salariesErros = {
      avg: '',
      max: '',
      min: ''
    }
    if (min > avg || min > max) {
      this.salariesErros.min = 'Salario mínimo no puede ser mayor al salario promedio o máximo'
    }
    if (max < avg || max < min) {
      this.salariesErros.max = 'Salario máximo no puede ser menor al salario promedio o mínimo'
    }
    if (avg < min) {
      this.salariesErros.avg = 'Salario promedio no puede ser menor al salario mínimo'
    }

    if (min == 0) {
      this.salariesErros.min = 'Salario mínimo no puede ser 0'
    }
    if (max == 0) {
      this.salariesErros.max = 'Salario máximo no puede ser 0'
    }
    if (avg == 0) {
      this.salariesErros.avg = 'Salario promedio no puede ser 0'
    }

    if ((min == 0 && !this.getMix) && (max == 0 && !this.getMax) && (avg == 0 && !this.getAvg)) {
      this.salariesErros = {
        avg: '',
        max: '',
        min: ''
      }
    }

  }

  get getMix() {
    return this.positionForm.get('minSalary').dirty || this.positionForm.get('minSalary').touched
  }

  get getAvg() {
    return this.positionForm.get('halfSalary').dirty || this.positionForm.get('halfSalary').touched
  }

  get getMax() {
    return this.positionForm.get('maxSalary').dirty || this.positionForm.get('maxSalary').touched
  }

  salariesIsInvalid() {
    if (this.salariesErros.avg.length > 0 || this.salariesErros.max.length > 0 || this.salariesErros.min.length > 0) {
      return true
    }
    return false
  }

  onSubmitPosition() {
    this.validateSalaries()
    if (this.salariesIsInvalid() || this.positionForm.invalid) {
      this._toastr.warning('Debe llenar todos los campos requeridos')
      return;
    }
    let body = { ...this.positionForm.value, conditionId: 4 };
    body.companyId = this.companies[0].companyId;
    body['requestedPositions'] = Number(this.positionForm.value['requestedPositions']);
    body['occupationalGroup'] = this.positionForm.value['occupationalGroup']?.ocode;
    body['concursaId'] = this.positionForm.value['concursaId']?.id;
    body['company'] = this.companies[0].companyId;
    body['positionCode'] = this.positionForm.get('positionCode')?.value
    if (!this.data.position?.positionId) {
      this.calculateHalfSalary();
      body['halfSalary'] = this.positionForm.get('halfSalary').value;
      this.positionService.createPosition(body).subscribe((res: any) => {
        if (!res.succeded) {

          res.errors.forEach(err => {
            this._toastr.error(err);
          })

          res.warnings.forEach(err => {
            this._toastr.warning(err);
          })

          return;
        }

        this._toastr.success('Registro exitoso');
        this.closeDialog(true);

      }, error => {
        this._toastr.error(`Ha ocurrido un error al guardar`);
      });
    } else {
      body['halfSalary'] = this.positionForm.get('halfSalary').value;

      this.positionService.updatePosition(body).subscribe((res: any) => {

        if (!res.succeded) {

          res.errors.forEach(err => {
            this._toastr.error(err);
          })

          res.warnings.forEach(err => {
            this._toastr.warning(err);
          })

          return;
        }

        this._toastr.success('Actualización exitosa');
        this.closeDialog(true);
      }, error => {
        this._toastr.error(`Ha ocurrido un error al guardar`);
      });
    }

  }

  closeDialog(succeded: boolean) {
    this.dialogRef.close(succeded)
  }


  openReasonReject(){
    this.dialog.open(ViewReasonRejectComponent, {
      width: '40%',
      data: {reasonReject: this.data?.position?.reasonReject}
    })
  }

}
