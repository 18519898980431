<div class="row">
    <div class="col-6">
        <p class="label-item mb-2">Motivo: Cambio de {{changeDetail?.parameterName || "N/A"}}</p>
        <p *ngIf="!changeDetail?.valueAfterNumeric && !isDate(changeDetail?.valueAfter)" class="label-item mb-2">Designación Actual: {{changeDetail?.valueAfter || "N/A"}}</p>
        <p *ngIf="!changeDetail?.valueAfterNumeric && isDate(changeDetail?.valueAfter)" class="label-item mb-2">Designación Actual: {{changeDetail?.valueAfterFormattedDate || "N/A"}}</p>
        <p *ngIf="changeDetail?.valueAfterNumeric" class="label-item mb-2">Designación Actual: {{changeDetail?.valueAfter || "N/A" | currency:'RD$'}}</p>
        <p class="label-item mb-2">Tipo de Servidor Público: {{employee?.employeeType || "N/A"}}</p>
        <p class="label-item mb-2">Estado: {{employee?.status? "Activo" : "Desvinculado"}}</p>
        <p class="label-item mb-2">Programación: {{employee?.budgetArea || "N/A"}}</p>
    </div>
    <div class="col-6 ">
        <p class="label-item mb-2">Datos: {{changeDetail?.parameterName || "" }} anterior</p>
        <p *ngIf="!changeDetail?.valueAfterNumeric && !isDate(changeDetail?.valueBefore)" class="label-item mb-2">{{changeDetail?.parameterName || "N/A"}}: {{changeDetail?.valueBefore || "N/A"}}</p>
        <p *ngIf="!changeDetail?.valueAfterNumeric && isDate(changeDetail?.valueBefore)" class="label-item mb-2">{{changeDetail?.parameterName || "N/A"}}: {{changeDetail?.valueBefore || "N/A"}}</p>
        <p *ngIf="changeDetail?.valueAfterNumeric" class="label-item mb-2">{{changeDetail?.parameterName || "N/A"}}: {{changeDetail?.valueBefore || "N/A" | currency:'RD$'}}</p>
    </div>
</div>