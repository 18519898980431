import { isArray } from '@amcharts/amcharts5/.internal/core/util/Type';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewGetDetailByDeparments } from '@payroll/audit-approval/indicators-modal/models/viewGetDetailByDeparments';
import { ViewGetDetailResumes } from '@payroll/audit-approval/indicators-modal/models/viewGetDetailResumes';
import { ViewGetOverallSummaries } from '@payroll/audit-approval/indicators-modal/models/viewGetOverallSummaries';
import { IHeader, IPeriod, PeriodConditions } from '@payroll/payroll-models';
import { AuditApprovalService } from 'src/app/services/audit-approval.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollStatisticsService } from 'src/app/services/payroll-statistics.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';;

@Component({
  selector: 'app-indicators-modal',
  templateUrl: './indicators-modal.component.html',
  styleUrls: ['./indicators-modal.component.css']
})
export class IndicatorsModalComponent implements OnInit {
  periods: IPeriod[] = []
  budgetAreas: any[] = []
  payrollTypes: any[] = []
  loading:boolean = true

  payrollSelected: IHeader;
  employeeTypes: any[] = [];
  departmentsPeriods: any[] = []
  viewGetDetailByDeparments: ViewGetDetailByDeparments[] = [];
  viewGetDetailByDeparmentsRecords: ViewGetDetailByDeparments[] = [];
  viewGetDetailByDeparmentsOriginalList: ViewGetDetailByDeparments[] = [];

  viewGetDetailResumes: ViewGetDetailResumes[] = [];
  viewGetDetailResumesOriginalList: ViewGetDetailResumes[] = [];
  viewGetDetailResumesRecords: ViewGetDetailResumes[] = [];


  viewGetOverallSummaries = [];
  viewGetOverallSummariesOriginalList = [];
  viewGetOverallSummariesRecords = [];
  overallSummariesOriginalList = [];

  statisticsForm = new FormGroup({
    period: new FormControl('', Validators.required),
    area: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required)
  })

  periodIds: any[] = []
  periodId: any = 0

  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  filterText: string = '';
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "fullName", displayName: "Servidor Público" },
      { propkey: "sex", displayName: "Sexo" },
      { propkey: "positionName", displayName: "Cargo" },
      { propkey: "departmentName", displayName: "Unidad organizativa" },
      { propkey: "employeeType", displayName: "Tipo" },
    ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'payrollPeriodId',
    textField: 'description',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  @ViewChild('paginator') paginator: CaptionComponent;
  @ViewChild('paginatorOverallSummaries') paginatorOverallSummaries: CaptionComponent;

  constructor(private _auditService: AuditApprovalService,
    private _toastService: ToastService,
    private _payrollService: PayrollService,
    private _ParamService: ParameterControlService,
    private _statisticsService: PayrollStatisticsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<IndicatorsModalComponent>,
  ) { }

  ngOnInit(): void {
    this.getData()
    this.loading = false

  }

  getData() {
    this.loadPeriods()
    this.loadBudgetAreas()
    this.loadPayrollTypes()

    this.payrollSelected = this.data.item;
    this.loadEmployeeTypes();
  }

    loadEmployeeTypes() {
      this._auditService.getTypeEmployeee(this.payrollSelected?.headerId).subscribe((res: any) => {
        if (res.succeded) {
          this.employeeTypes = res.dataList;
        } else {
          this._toastService.error(res.error[0])
        }
      }, err => {
        this._toastService.error('', 'ERROR INESPERADO')
      })
    }
  dropdownConfig(displayKey, placeholder?) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  loadPeriods() {
    this._auditService.getPeriods(11).subscribe((res: ResponseModel<IPeriod>) => {
      if (res.succeded) {
        let periods: IPeriod[] = res.dataList;
        this.periods = periods.filter(x => x.conditionId == PeriodConditions.open && x.status == true)
      } else {
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error('', 'ERROR INESPERADO')
    })
  }

  loadBudgetAreas() {
    this._payrollService.getBudgetAreas().subscribe((res: any) => {
      if (res.succeded) {
        this.budgetAreas = res.dataList.filter(ba => ba.status == true)
      } else {
        this._toastService.error(res.error[0])
      }
    }, err => {
      this._toastService.error('', 'ERROR INESPERADO')
    })
  }

  loadPayrollTypes() {
    this._ParamService.getParameters(keyWord.Payroll).subscribe((res: any) => {
      if (res.succeded) {
        this.payrollTypes = res.dataList;
      } else {
        this._toastService.error(res.error[0])
      }
    }, err => {
      this._toastService.error('', 'ERROR INESPERADO')
    })
  }

  onDeselectAllPeriods() {
    this.statisticsForm.get('period').reset()
    this.periodId = 0
  }

  onDeselectPeriod(perdioSelected) {
    this.periodIds = this.periodIds.filter((period) => period.payrollPeriodId !== perdioSelected.payrollPeriodId)
    this.periodId = 0
    if (this.periodIds.length == 0) {
      this.statisticsForm.get('period').reset()
    }
  }

  onPeriodSelect(period) {
    this.periodIds.push(period)
    this.statisticsForm.get('period').setValue(0)
  }

  onPeriodSelectAll(periods) {
    this.periodIds = periods
    this.statisticsForm.get('period').setValue(0)
  }

  GetDetailPayrollAudit() {

    if (this.statisticsForm.invalid) {
      this._toastService.warning('Por favor completar los campos requeridos para efectuar su busqueda', 'Campos incompletos')
      return
    }

    this.periodId = 0
    this.periodIds.forEach((period: any) => {
      this.periodId += `-${period.payrollPeriodId}`
    });
    this.statisticsForm.get('period').setValue(this.periodId)

    this._statisticsService.GetDetailPayrollAudit(this.statisticsForm.get('period').value, this.statisticsForm.get('area').value.budgetAreaId, this.statisticsForm.get('type').value.ocode).subscribe((res: any) => {
      if (res.errors.length) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.viewGetDetailResumes = res.singleData?.viewGetDetailResumes;
      this.viewGetDetailResumesOriginalList = res.singleData?.viewGetDetailResumes;

      this.departmentsPeriods = res.singleData?.viewGetDetailByDeparments.map((department: any) => {
        return department.period
      })

      const periodsSet = new Set(this.departmentsPeriods)
      const periods = []
      periodsSet.forEach(period => {
        periods.push({ period: period })
      });
      this.departmentsPeriods = periods

      let employeeCounts = res.singleData?.viewGetDetailByDeparments.map((department: any) => {
        const countsArray = []
        countsArray.push(department.employeeCount)
        return { departmentId: department.departmentId, counts: countsArray }
      })

      let departments = []
      let orderedDepartments = res.singleData?.viewGetDetailByDeparments.filter(department => {
        let existed = departments.includes(department.departmentId)
        if (!existed) {
          departments.push(department.departmentId)
          return true
        }
        return false
      })
      this.viewGetDetailByDeparments = orderedDepartments.map(department => {
        const employeeAmount = []
        employeeCounts.forEach(element => {
          if (element.departmentId == department.departmentId) {
            employeeAmount.push(element.counts[0])
          }
        });
        return { ...department, employeeAmount }
      })
      this.viewGetDetailByDeparmentsOriginalList = orderedDepartments.map(department => {
        const employeeAmount = []
        employeeCounts.forEach(element => {
          if (element.departmentId == department.departmentId) {
            employeeAmount.push(element.counts[0])
          }
        });
        return { ...department, employeeAmount }
      })

      const viewGetOverallSummaries = res.singleData?.viewGetOverallSummaries?.map((e: any) => {
        let employee = {
          ...e,
          fullName: `${e?.firstName} ${e?.lastName}`
        }
        return employee;
      })
      this.overallSummariesOriginalList = [...viewGetOverallSummaries];
      this.viewGetOverallSummariesOriginalList = [...this.getGroupOverallSummaries(viewGetOverallSummaries)];
      this.viewGetOverallSummaries = [...this.getGroupOverallSummaries(viewGetOverallSummaries)];
      this.paginatorOverallSummaries.registerPerPage = 1;
      if (this.viewGetDetailResumes.length == 0 && this.viewGetDetailByDeparments.length == 0 && viewGetOverallSummaries.length == 0) {
        this._toastService.warning('No hay información en la base de datos que comprenda los datos suministrados', 'No hay datos para mostrar')
      }
    }, error => {
      this._toastService.error('Ha ocurrido un errror al inesperado');
    })
  }

  private getGroupOverallSummaries(viewGetOverallSummaries: any[]){
    let overallSummaries: any[] = [];
    let departmentsSummaries = this.groupBy(viewGetOverallSummaries, (overall: { departmentId: number; }) =>  overall?.departmentId);
    departmentsSummaries.forEach(department => {
       overallSummaries.push({
        departmentName: this.getValues(department)[0]?.departmentName,
        departments: this.getValues(department)?.sort((departmentA: any, departmentB: any) => {
          return  departmentB?.grossSalary  - departmentA?.grossSalary
        })
      });
    })
    overallSummaries = overallSummaries?.sort((departmentGroupA: any, departmentGroupB: any) => {
      return departmentGroupA?.departments[0]?.order - departmentGroupB?.departments[0]?.order
    })
    overallSummaries.forEach(d => {
      d.grossSalary = this.getSubTotalOverallSummaries(d?.departments, 'grossSalary');
      d.totalSalary = this.getSubTotalOverallSummaries(d?.departments, 'totalSalary');
      d.otherCredits = this.getSubTotalOverallSummaries(d?.departments, 'otherCredits');
      d.afp = this.getSubTotalOverallSummaries(d?.departments, 'afp');
      d.sfs = this.getSubTotalOverallSummaries(d?.departments, 'sfs');
      d.isr = this.getSubTotalOverallSummaries(d?.departments, 'isr');
      d.otherDiscounts = this.getSubTotalOverallSummaries(d?.departments, 'otherDiscounts');
      d.totalDiscount = this.getSubTotalOverallSummaries(d?.departments, 'totalDiscount');
      d.netIncome = this.getSubTotalOverallSummaries(d?.departments, 'netIncome');
    })
    overallSummaries.forEach(o => {
      let periods = this.groupBy([...o?.departments], (department: { payrollPeriodId: number; }) =>  department?.payrollPeriodId);
      o.departments = this.getValuesGroup(periods);
      o.departments?.forEach(d => {
        d.grossSalary = this.getSubTotalOverallSummaries(d?.payrollPeriods, 'grossSalary');
        d.totalSalary = this.getSubTotalOverallSummaries(d?.payrollPeriods, 'totalSalary');
        d.otherCredits = this.getSubTotalOverallSummaries(d?.payrollPeriods, 'otherCredits');
        d.afp = this.getSubTotalOverallSummaries(d?.payrollPeriods, 'afp');
        d.sfs = this.getSubTotalOverallSummaries(d?.payrollPeriods, 'sfs');
        d.isr = this.getSubTotalOverallSummaries(d?.payrollPeriods, 'isr');
        d.otherDiscounts = this.getSubTotalOverallSummaries(d?.payrollPeriods, 'otherDiscounts');
        d.totalDiscount = this.getSubTotalOverallSummaries(d?.payrollPeriods, 'totalDiscount');
        d.netIncome = this.getSubTotalOverallSummaries(d?.payrollPeriods, 'netIncome');
      })
    })
    return overallSummaries;
  }

  private getSubTotalOverallSummaries(list: any[], typeTotal: string){
    return list?.reduce((previous, current) => previous + current[typeTotal], 0)
  }

  private getValuesGroup(list: Map<any, any>){
    let values = [];
    list.forEach(l => {
      values.push({
        payrollPeriodName: this.periodIds.find(pId => pId?.payrollPeriodId ===  this.getValues(l)[0]?.payrollPeriodId)?.description,
        payrollPeriods: [...this.getValues(l)]
      })
    });
    return values;
  }

  private getValues(item) {
    return Object.values(item) as any[];
  }

  private groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  getTotalOverallSummarie(total) {
    const viewGetOverallSummaries = this.filteredList?.enable ? this.filteredList.records :
      this.viewGetOverallSummaries;
    const totalOverallSummaries = viewGetOverallSummaries?.reduce((previous, current) => previous + current[total], 0);
    return totalOverallSummaries;
  }

  getPaginatedRecordsDeparments(event) {
    this.viewGetDetailByDeparmentsRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  getPaginatedOverallSummaries(event) {
    this.viewGetOverallSummariesRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  getFilteredList(event) {
    event.records = [...this.getGroupOverallSummaries(event.records)];
    this.filteredList = event;
    this.paginator.selectedPage = 1;
  }

  onKeyChange(text) {
    this.filterText = text;
  }


}
