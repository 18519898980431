import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PersonsWorkExperiencesService {
  private readonly url = environment.api_core_url;
  private readonly url_sigei = environment.api_core_sigei;
  private readonly xhrm_url = environment.api_url;
  constructor(
    private authInfo: AuthInfoService, 
    private _httpClient: HttpClient, 
  ) { }

  saveWorkExperience(model){
    return this._httpClient.post(`${this.url}/PersonsWorkExperiences`, model)
  }

}
