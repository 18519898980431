import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { IEmployeePermissions } from '../../models/EmployeePermissions';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { personnelActionTypes } from '../personnel-actions-tap.component';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { environment } from 'src/environments/environment';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';

@Component({
  selector: 'app-external-service-tap',
  templateUrl: './external-service-tap.component.html',
  styleUrls: ['./external-service-tap.component.css']
})
export class ExternalServiceTapComponent implements OnInit, OnChanges {
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'ACTION-CHANGE-EMPLOYEE',
    externalService: { key: 'External-Service-Recurring-Actions', value: false },
    add: { key: 'Add-External-Service-Recurring-Actions', value: false },
    view: { key: 'View-Pdf-External-Service-Recurring-Actions', value: false },
    edit: { key: 'Edit-External-Service-Recurring-Actions', value: false },
  }

  externalServiceForm: FormGroup;
  externalServiceFormInvalid: boolean = false;
  @Input() employeeData: any;
  @Input() isSuspended: boolean = false;

  @Input() servicesList: IEmployeePermissions[] = [];
  @Input() vacationData
  @Input() licenseData
  dayOfWeekInvalid = { invalid: true, dirty: false }
  employeeServicesData: any;

  subscriptions: Subscription = new Subscription();

  isEditingExternalService: boolean = false;
  currentConditionOnEditting!: number;
  currentEdittingIsWithoutPayroll: boolean = false;

  reasonsConfig = this.getDropdownConfig('stringData');

  modificationReasons: ParameterControl[] = [];
  endDateInvalid = false
  constructor(private fBuilder: FormBuilder,
    private toastr: ToastrService,
    private parameterControlService: ParameterControlService,
    private personnelActionsService: PersonnelActionsService,
    public authInfo: AuthInfoService
  ) { 
    this.buildExternalServiceForm();
  }

  ngOnInit(): void {
    this.buildExternalServiceForm();
    this.setPrivileges()
    this.getMotivesModification();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  

  getMotivesModification(){
    this.parameterControlService.getParameters(keyWord.ReasonsToModifyPersonalAction).subscribe({
      next:(res)=>{
        if(!res.succeded){
          res.errors.forEach(err=>{
            this.toastr.error(err);
          })

          res.warnings.forEach(warn=>{
            this.toastr.warning(warn);
          })

          return;
        }

        this.modificationReasons = res.dataList;
      },
      error: (err)=>{
        this.toastr.error('Ha ocurrido un error inesperado consultado la lista de razones de modificacion')
      }
    })
  }

  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!this.externalServiceForm) return
    if (this.employeeData?.status) {
      this.externalServiceForm.get('applicationDate').patchValue(new Date().toISOString().substring(0, 10))
    }

    if (simpleChanges['isSuspended']?.currentValue) {
      this.disableButtonsAndInputs();
    }

  }
  setPrivileges() {
    const privileges = this.privilege
    const nameKey = privileges.nameKeyOption
    this.privilege.add.value = this.authInfo.canDoAction(nameKey, privileges.add.key)
    this.privilege.edit.value = this.authInfo.canDoAction(nameKey, privileges.edit.key)
    this.privilege.externalService.value = this.authInfo.canDoAction(nameKey, privileges.externalService.key)
    this.privilege.view.value = this.authInfo.canDoAction(nameKey, privileges.view.key)
  }
  buildExternalServiceForm() {
    this.externalServiceForm = this.fBuilder.group({
      reason: [null, Validators.required],
      comment: [''],
      applicationDate: [null, Validators.required],
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      hourStart: ['', Validators.required],
      hourEnd: ['', Validators.required],
      idTypePermissions: [9, Validators.required],
      conditionId: [2], //aprobado internamente
      monday: [false],
      tuesday: [false],
      wednesday: [false],
      thursday: [false],
      friday: [false],
      saturday: [false],
      sunday: [false],
      permissionsId: [0],
      createUserId: 0,
      newModificationComment: [null],
      newModificationReason: [null],
      newModificationReasonId: [null],
      oldModificationComment: [null],
      oldModificationReason: [null]
    })
  }

  disableButtonsAndInputs() {
    if(this.externalServiceForm){
      this.externalServiceForm.disable();
    }
    var buttons: any = document.getElementsByClassName('btn');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = true;
    }
  }

  validateReason(reasonText: string) {
    if (reasonText === ' ') {
      this.externalServiceForm.get('reason').setValue(null)
    }
  }

  convert(hora) {
    return (new Date("1955-11-05T" + hora + "Z")).toLocaleTimeString("bestfit", {
      timeZone: "UTC",
      hour12: !0,
      hour: "numeric",
      minute: "numeric"
    });
  };


  dateIsTaken() {
    const { dateStart, dateEnd, hourStart, hourEnd } = this.externalServiceForm.value
    let dateStartFormatted = new Date(dateStart + 'T00:00:00')
    let dateEndFormatted = new Date(dateEnd + 'T00:00:00')

    const hourStartSplit = hourStart.split(':')
    const hoursStart = Number(hourStartSplit[0])
    const minutesStart = Number(hourStartSplit[1])

    const hourEndSplit = hourEnd.split(':')
    const hoursEnd = Number(hourEndSplit[0])
    const minutesEnd = Number(hourEndSplit[1])
    const servicesList = [...this.servicesList].filter(s => s?.permissionsId !== this.externalServiceForm?.value?.permissionsId)
    for (let index = 0; index < servicesList.length; index++) {
      const e = servicesList[index];
      const ehourStartSplit = e.hourStart.split(':')
      const ehourStart = Number(ehourStartSplit[0])
      const eminutesStart = Number(ehourStartSplit[1])
      const ehourEndSplit = e.hourEnd.split(':')
      const ehourEnd = Number(ehourEndSplit[0])
      const eminutesEnd = Number(ehourEndSplit[1])
      const dateS = new Date(e.dateStart)
      const dateE = new Date(e.dateEnd)
      if (dateStartFormatted > dateS && dateStartFormatted < dateE) {
        return true
      }
      if (dateEndFormatted > dateS && dateEndFormatted < dateE) {
        return true
      }
      if (dateEndFormatted >= dateE && dateStartFormatted <= dateS) {
        return true
      }
      if (dateStartFormatted.getTime() == dateE.getTime()) {
        if (hoursStart < ehourEnd) {
          return true
        }
        if (hoursStart == ehourEnd) {
          if (minutesStart <= eminutesEnd) {
            return true
          }
        }
      }
      if (dateEndFormatted.getTime() == dateS.getTime()) {
        if (hoursEnd > ehourStart) {
          return true
        }
        if (hoursEnd == ehourStart) {
          if (minutesEnd <= eminutesStart) {
            return true
          }
        }
      }
    }
    return false
  }
  selectDay() {
    const { monday, tuesday, wednesday, thursday, friday, saturday } = this.externalServiceForm.value;
    const hasSelectedDay = [monday, tuesday, wednesday, thursday, friday, saturday].some(value => value);

    this.dayOfWeekInvalid.dirty = true;
    this.dayOfWeekInvalid.invalid = !hasSelectedDay;
  }
  savePermission() {
    const startDate = moment(this.externalServiceForm.get('dateStart').value)
    const endDate = moment(this.externalServiceForm.get('dateEnd').value)
    this.endDateInvalid = startDate > endDate
    if (this.endDateInvalid) return
    if (this.dateIsTaken()) {
      this.toastr.warning('La fecha seleccionada esta ocupada por otro servicio externo');
      return
    }

    if (!this.employeeData.employeeId) {
      this.toastr.warning("Debe seleccionar un Servidor Público", "Aviso");
      return;
    }
    if (this.externalServiceForm.invalid) {
      this.externalServiceFormInvalid = true;
      this.toastr.warning("Debe llenar todos los campos", "Aviso");
      return;
    }
    this.externalServiceFormInvalid = false;

    if (!this.externalServiceForm.get('monday').value && !this.externalServiceForm.get('tuesday').value
      && !this.externalServiceForm.get('wednesday').value && !this.externalServiceForm.get('thursday').value
      && !this.externalServiceForm.get('friday').value && !this.externalServiceForm.get('saturday').value) {
      this.dayOfWeekInvalid.invalid = true
      return;
    } else {
      this.dayOfWeekInvalid.invalid = false
    }
    if (this.canHavePermision()) {
      return;
    }
    this.employeeServicesData = {
      employeeId: this.employeeData.employeeId,
      companyId: this.authInfo.getCompanyId(),
      status: true,
      ...this.externalServiceForm?.value,
      typePermissionsId: Number(this.externalServiceForm.get('idTypePermissions')?.value),
      typePersonalActionId: personnelActionTypes.externalService,
      createUserId: this.isEditingExternalService ? this.externalServiceForm.value?.createUserId : this.authInfo.getUserId(),
      modifyUserId: this.isEditingExternalService ? this.authInfo.getUserId() : 0,
      modificationComment: this.externalServiceForm?.value?.newModificationComment,
      modificationReasonId: this.externalServiceForm?.value?.newModificationReasonId,
    }



    if(this.isEditingExternalService){
      this.updateEmployeePermission();
    }else{
      this.saveEmployeePermission();
    } 
  }

  clearFields(reloadList: boolean = false){
    this.isEditingExternalService = false;
    this.buildExternalServiceForm();
    
    if(reloadList){
      this.getExternalServices();
    }

    this.externalServiceForm.get('newModificationReason').clearValidators();
    this.externalServiceForm.get('newModificationReason').updateValueAndValidity();
  }

  private updateEmployeePermission() {
    let saveEmployeePermissionSub = this.personnelActionsService.updateEmployeePermission(this.employeeServicesData).subscribe({
      next: (res: ResponseModel<any> ) => {
        if(res?.warnings?.length > 0){
          this.toastr.warning(res?.warnings[0]);
          return;
        }
        if(res?.errors?.length > 0){
          this.toastr.error(res?.errors[0]);
          return;
        }
        this.toastr.success("Registro modificado exitosamente");
        this.clearFields(true);
        
      }, error: res => {
        this.toastr.error("Error al modificar el registro", "Error inesperado")
      }
    });
    this.subscriptions.add(saveEmployeePermissionSub)
  }

  private saveEmployeePermission() {
    let saveEmployeePermissionSub = this.personnelActionsService.saveEmployeePermission(this.employeeServicesData).subscribe({
      next: (res: ResponseModel<any> ) => {
        if(res?.warnings?.length > 0){
          this.toastr.warning(res?.warnings[0]);
          return;
        }
        if(res?.errors?.length > 0){
          this.toastr.error(res?.errors[0]);
          return;
        }
        this.toastr.success("Se ha registrado el servicio correctamente", "Registro guardado")
        this.clearFields(true);
      }, error: res => {
        this.toastr.error("Error al guardar el registro", "Error inesperado");
      }
    });
    this.subscriptions.add(saveEmployeePermissionSub);
  }

  private setDate(date1) {
    if (!date1) return;
    return formatDate(date1, 'yyyy-MM-dd', 'en_US')
  }

  canHavePermision() {
    const dateStart = this.setDate(this.externalServiceForm.get('dateStart')?.value);
    const dateEnd = this.setDate(this.externalServiceForm.get('dateEnd')?.value);

    let hasVacation = this.vacationData
      .filter((vacantion: any) => vacantion.employeeId == this.employeeData.employeeId)
      .some(vacantion => (this.setDate(vacantion.dateStart) >= dateStart && this.setDate(vacantion.dateEnd) <= dateEnd)
        || dateStart == this.setDate(vacantion.dateEnd) || dateEnd == this.setDate(vacantion.dateEnd)
        || dateStart == this.setDate(vacantion.dateStart) || dateEnd == this.setDate(vacantion.dateStart)
        || (dateStart >= this.setDate(vacantion.dateStart) && dateStart <= this.setDate(vacantion.dateEnd))
        || (dateEnd >= this.setDate(vacantion.dateStart) && dateEnd <= this.setDate(vacantion.dateEnd)));

    let hasLicense = this.licenseData
      .filter((license: any) => license.employeeId == this.employeeData.employeeId)
      .some(license => (this.setDate(license.dateStart) >= dateStart && this.setDate(license.dateEnd) <= dateEnd)
        || dateStart == this.setDate(license.dateEnd) || dateEnd == this.setDate(license.dateEnd)
        || dateStart == this.setDate(license.dateStart) || dateEnd == this.setDate(license.dateStart)
        || (dateStart >= this.setDate(license.dateStart) && dateStart <= this.setDate(license.dateEnd))
        || (dateEnd >= this.setDate(license.dateStart) && dateEnd <= this.setDate(license.dateEnd)));

    if (hasVacation || hasLicense) {
      if (hasVacation) {
        this.toastr.warning('Este Servidor Público tiene un periodo de vacación activa en el rango de fecha seleccionado, no se le puede aplicar un servicio')
      }

      if (hasLicense) {
        this.toastr.warning('Este Servidor Público tiene una licencia activa en el rango de fecha seleccionado, no se le puede aplicar un servicio')
      }
      return true;
    }

    return false;

  }

  getExternalServices() {
    if (!this.employeeData) return
    this.personnelActionsService.getEmployeePermissionsByIdEmployee(this.employeeData.employeeId).subscribe((res: any) => {

      if (res.succeded) {
        this.servicesList = res.dataList.filter((e: IEmployeePermissions) => {
          e.hourStartText1 = this.convert(e.hourStart)
          e.hourEndText1 = this.convert(e.hourEnd)
          return e.typePersonalActionId == personnelActionTypes.externalService
        });
      } else {
        this.toastr.error(res.errors[0]);
      }


    })
  }


  get getDateStart() {
    return this.externalServiceForm?.get('dateStart')?.value as Date;;
  }

  getMinDate(date) {
    let d = new Date(date);
    d.setDate(d.getDate() + 1);
    return d.toLocaleDateString('fr-CA');
  }

  showReasonErrors() {
    let field = this.externalServiceForm.get('reason');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showHourEndErrors() {
    let field = this.externalServiceForm.get('hourEnd');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showidTypePermissionsErrors() {
    let field = this.externalServiceForm.get('idTypePermissions');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    if (field.hasError('min')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showCommentErrors() {
    let field = this.externalServiceForm.get('comment');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showHourStartErrors() {
    let field = this.externalServiceForm.get('hourStart');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showApplicationDateErrors() {
    let field = this.externalServiceForm.get('applicationDate');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showDateStartErrorsPermission() {
    let field = this.externalServiceForm.get('dateStart');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showDateEndErrorsPermission() {
    let field = this.externalServiceForm.get('dateEnd');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    if (field.hasError('higherDate')) {
      return 'La fecha de término no puede ser menor a la fecha de inicio';
    }
    return '';
  }

  editExternalService(externalService) {

    this.clearFields(false);

    this.isEditingExternalService = true;
    this.currentConditionOnEditting = externalService?.conditionId;
    this.currentEdittingIsWithoutPayroll = externalService?.withoutPayroll;

    const modificationReason = this.modificationReasons?.find(m => m.ocode == externalService?.modificationReasonId);

    this.externalServiceForm.patchValue({
      ...externalService,
      applicationDate: formatDate(externalService?.applicationDate, "yyyy-MM-dd", "en"),
      dateStart: formatDate(externalService?.dateStart, "yyyy-MM-dd", "en"),
      dateEnd: formatDate(externalService?.dateEnd, "yyyy-MM-dd", "en"),
      createUserId: externalService.createUserId,
      conditionId: !externalService?.conditionId ? 2 : externalService?.conditionId,
      oldModificationComment: externalService?.modificationComment,
      oldModificationReason: modificationReason
    });

    this.externalServiceForm.get('newModificationReason').setValidators(Validators.required);
    this.externalServiceForm.get('newModificationReason').updateValueAndValidity();
    this.externalServiceForm.updateValueAndValidity();
  }

  changeModificationReason(value: ParameterControl){
    this.externalServiceForm.get('newModificationReasonId').setValue(value?.ocode);
  }


  exportExternalService(externalService){
    const {employeeId, permissionsId} = externalService
    const companyId = this.authInfo?.getCompanyId();
    const reportCode: ReportCode = ReportCode.ExternalService;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&EmployeeId=${employeeId}&PermissionsId=${permissionsId}`;
    let parameters = {
      url: reportUrl,
      title: 'Cargos y Plazas de la Planificación',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }
}
