import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { docService } from 'src/app/services/doc.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PositionService } from 'src/app/services/position.service';
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { IGetDepartments } from '../home/home.models';
import { DepartmentService } from 'src/app/services/department.service';
import { Departments, IDepartmentChangeAdditionalValue, IDepartmentChangeDetailView, IDepartmentChangeDetails, IDepartmentChangeHeaderPost, IDepartmentChangePost } from 'src/app/models/departments.model';
import { IEmployee } from 'src/app/models/Employee.model';
import { SearchEmployeeComponent } from '@payroll/action-edit/search-employee/search-employee.component';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-department-changes',
  templateUrl: './department-changes.component.html',
  styleUrls: ['./department-changes.component.css']
})
export class DepartmentChangesComponent implements OnInit {

  departmentSelected: Departments = {
    companyId: 0,
    locationsId: 0,
    departmentId: 0,
    departmentName: '',
    shortName: '',
    sectionId: 0,
    levelSection: 0,
    parentSection: 0,
    parentSectionName: '',
    supervisor: '',
    supervisorId: 0,
    status: false,
    isHhrr: false,
    isNursing: false,
    head: undefined,
    afterId: undefined,
    beforeId: undefined,
    depthLevel: undefined,
    parentSection2: undefined,
    hasChildren: undefined,
    disableDrag: undefined,
    disableDragDest: undefined,
    position: '',
    socialReason: '',
    shortNameCompany: '',
    imageProfile: '',
    code: '',
    requestMapid: 0,
    createUsrId: undefined,
    createDate: undefined,
    modifyUsrId: 0,
    modifyDate: '',
    reasonReject: undefined,
    publicAccessId: undefined,
    conditionId: 0,
    condition: '',
    sentDate: undefined,
    senderUserId: undefined,
    sendCode: undefined,
    rejectedDate: undefined,
    rejectedUserId: undefined,
    approvedDate: '',
    approvedUserId: 0,
    email: undefined,
    telephone: undefined,
    telephoneExtension: undefined,
    isMain: false,
    order: 0,
    cancellationDate: undefined,
    cancellationReason: undefined,
    cancellationUserId: undefined
  }

  actionChangeForm = new FormGroup({
    currentData: new FormControl({ value: '', disabled: true }),
    currentDataId: new FormControl({ value: null, disabled: true }),
    newData: new FormControl({ value: '', disabled: true }, Validators.required),
    newDataId: new FormControl({ value: null, disabled: true }),
    additionalValueBeforeId: new FormControl({ value: null, disabled: true }),
    additionalValueBefore: new FormControl({ value: '', disabled: true }),
    additionalValueObject: new FormControl({ value: null, disabled: true }),
    additionalValueId: new FormControl({ value: null, disabled: true }),
    additionalValue: new FormControl({ value: '', disabled: true }),
    parameter: new FormControl({ value: null, disabled: true }, Validators.required,),
    effectiveDate: new FormControl({ value: null, disabled: true }, Validators.required),
    evidence: new FormControl({ value: null, disabled: true }, Validators.required),
    comment: new FormControl({ value: null, disabled: true }),
    conditionId: new FormControl([0]),
    departmentChangeDetailsId: new FormControl([0]),
    departmentChangeHeaderId: new FormControl([0]),
    dateApproved: new FormControl([null])
  }, { validators: this.ValidateNewData });


  fieldType: any = 'drop';
  additionalValueFieldName: string = '';

  config: any = {
    placeholder: 'Seleccione una opción'
  }

  additionalValueConfig: any = {
    displayKey: 'additionalValue',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  }

  motivesConfig = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  }

  fieldsConfig = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  }

  fields: any[] = []
  options: any[] = []
  selectedItemFromSearchModal: {id: number, text: string} = null;
  departments: Departments[] = [];

  newChanges: IDepartmentChangeDetails[] = [];
  newChangesPaginated: IDepartmentChangeDetails[] = [];
  newChangesCopy: IDepartmentChangeDetails[] = [];
  additionalValueList: IDepartmentChangeAdditionalValue[] = [];

  details: IDepartmentChangeDetailView[] = [];
  detailsPaginated: IDepartmentChangeDetailView[] = [];

  formIsInvalid: boolean = false;
  isSalaryCorrect: boolean = true;
  salaryError: string = '';

  guiID = '';
  file = { document: '' }

  @ViewChild('uppyFile')
  uppyFile: UppyFileComponent | null = null;
  constructor(private dialog: MatDialog,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private srvDoc: docService,
    private location: Location,
    private parameterCtrlService: ParameterControlService,
    private sanitizer: DomSanitizer,
    public authInfo: AuthInfoService,
    private departmentService: DepartmentService,
    private route : Router) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.departmentSelected.departmentId = params['departmentId'];

      if (this.departmentSelected.departmentId > 0) {

        this.getDeparmentData();
      }

    })
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  goBack() {
    this.route.navigate(['departament-list'])

  }

  private getParameters() {
    this.parameterCtrlService.getParameters(keyWord.CompanyStructuresChangeType).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })
          return;
        }

          this.fields = res.dataList;


      },
      error: (err) => {
      }
    })

    this.departmentService.getAllApproved(this.departmentSelected.companyId).subscribe({
      next:(res)=>{
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })

          res.warnings.forEach(warn => {
            this.toastService.warning(warn);
          })

          return;
        }

        this.departments = res.dataList;

      },
      error: (err)=>{
        this.toastService.error('Ha ocurrido un error tratando de consultar la lista de areas organizativas');
      }
    })
  }


  private getDeparmentData(isReload: boolean = false) {
    this.departmentService.getById(this.authInfo.getCompanyId(), this.departmentSelected.departmentId).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })
          return;
        }

        this.departmentSelected = res.dataList[0];
        this.actionChangeForm.enable()
        //disable all if department is disabled
        if (this.departmentSelected?.status == false) {
          this.actionChangeForm.disable();

          var buttons: any = document.getElementsByClassName('btn');
          for (let i = 0; i < buttons.length; i++) {
            buttons[i].disabled = true;
          }

          let inputs: any = document.getElementsByTagName("INPUT");
          for (let i = 0; i < inputs.length; i++) {
            inputs[i].disabled = true;
          }

          let areas: any = document.getElementsByTagName("TEXTAREA");
          for (let i = 0; i < areas.length; i++) {
            areas[i].disabled = true;
          }
        }

        //if is a reload, dont call this functions
        if (!isReload) {
          this.getParameters();
          this.getChangeDetails();
        }


      },
      error: (err) => {
        this.toastService.error("Ha ocurrido un error tratando de consultar el historial de cambios");
      }
    })
  }

  private getChangeDetails() {
    this.departmentService.getChangeDetails(0, 0, this.departmentSelected.departmentId, this.departmentSelected.companyId, 2).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })
          return;
        }

        this.details = res.dataList;
      },
      error: (err) => {
        this.toastService.error("Ha ocurrido un error tratando de consultar el historial de cambios");
      }
    })
  }

  fillCurrentData(parameter: number) {

    this.actionChangeForm.get('newData').setValue(null);
    this.actionChangeForm.get('additionalValue').setValue(null);
    this.actionChangeForm.get('additionalValueObject').setValue(null);
    this.getDropDownConfig(parameter);
    this.getDropDownOptions(parameter);


    this.additionalValueList = [];

    switch (parameter) {
      case 1: //Name
        this.actionChangeForm.get('currentData').setValue(this.departmentSelected?.departmentName)
        this.actionChangeForm.get('newData').setValue(this.departmentSelected?.departmentName)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'text'
        break;
      case 2: //Supervisor
      this.actionChangeForm.get('currentData').setValue(this.departmentSelected?.supervisor)
      this.actionChangeForm.get('currentDataId').setValue(this.departmentSelected?.supervisorId ?? 0)
      this.fieldType = 'modal-searcher'
        break;
      case 3: //Parent
      this.actionChangeForm.get('currentData').setValue(this.departmentSelected?.parentSectionName)
      this.actionChangeForm.get('currentDataId').setValue(this.departmentSelected?.parentSection)
      this.fieldType = 'drop'
        break;
      default:
        this.actionChangeForm.get('currentData').setValue(null)
        this.actionChangeForm.get('newData').setValue(null)
        this.actionChangeForm.get('currentDataId').setValue(null)
        this.fieldType = 'text'
        break;
    }


  }

  fillNewData(value: any) {

    let valueType = this.actionChangeForm.get('parameter').value;

    switch (valueType.ocode) {
      case 1: //Name
        this.actionChangeForm.get('newDataId').setValue(null)
        break;
      case 2: //Supervisor (Employee)
        this.openModalSearchEmployee(value);
        break;
      case 3: //Parent
        this.actionChangeForm.get('newDataId').setValue(value?.departmentId)
        break;
      default:
        break;
    }
  }

  getDropDownConfig(fieldCode: number) {
    switch (fieldCode) {
      case 3: //Parent
        this.config = {
          displayKey: 'departmentName',
          placeholder: 'Seleccione una opción',
          search: true,
          searchPlaceholder: 'Buscar'
        }
        break;
      default:
        break;
    }

    return this.config
  }

  getDropDownOptions(fieldCode: number) {
    this.options = []
    switch (fieldCode) {
      case 3: //Parents
        this.options = this.departments
        break;
      default:
        break;
    }
    return this.options
  }

  getNewDataValue(): string {
    let field = this.actionChangeForm.get('newData').value;

    let value: string = null;
    let valueType = this.actionChangeForm.get('parameter').value;
    if (field.stringData == undefined) {
      switch (valueType.ocode) {
        case 1: //name
          value = field
          break;
        case 2: //Supervisor (employee)
          value = this.selectedItemFromSearchModal.text
          break;
        case 3: //parent
          value = field.departmentName
          break;
        default:
          if (!isNaN(field)) { /* comprobar cuando es un numerico (salario) que se esta digitando */
            value = field.toString();
          } else {
            value = field; /* Si es una fecha */
          }
          break;
      }

    } else {
      value = field.stringData;

    }
    return value;
  }


  addChangeDetails() {
    this.formIsInvalid = this.actionChangeForm.invalid;
    if (this.formIsInvalid) {
      this.toastService.warning('Debe completar todos los campos requeridos')
      return;
    }

    if (this.fieldType == 'drop-additional' && !this.actionChangeForm.get('additionalValue').value) {
      this.toastService.warning('Debe completar todos los campos requeridos')
      return;
    }

    const form = this.actionChangeForm;

    const valueAfterNumeric = this.fieldType == 'number' ? (parseFloat(this.getNewDataValue()) ) : null;
    const valueAfter = this.getNewDataValue()?.toString();

    this.newChanges.push({
      departmentChangeDetailsId: 0,
      departmentChangeHeaderId: 0,
      parameterChangeId: form.get('parameter').value?.ocode,
      valueBeforeId: this.fieldType != 'number'  ? form.get('currentDataId').value : 0,
      valueBefore: this.fieldType == 'number' ? form.get('currentData').value?.toString() : form.get('currentData').value,
      valueAfterId: this.fieldType != 'number'  ? form.get('newDataId').value : 0,
      effectiveDate: form.get('effectiveDate')?.value,
      valueAfterNumeric: valueAfterNumeric,
      valueAfter: valueAfter,
      additionalValueId: form.get('additionalValueId').value,
      additionalValue: form.get('additionalValue').value,
      companyId: this.departmentSelected.companyId,
      evidence: form.get('evidence').value,
      comment: form.get('comment').value,
      userId: this.authInfo.getUserId(),
      parameterName: form.get('parameter').value?.stringData,
      publicAccessId: ''
    })

    this.resetInputs()

  }

  remove(item: IDepartmentChangeDetails, rowIndex) {
    this.newChanges.splice(rowIndex, 1);
  }

  async setChangeHeader() {

    if (this.newChanges.length == 0) {
      this.toastService.warning("No hay datos para guardar")
      return;
    }

    let header: IDepartmentChangeHeaderPost = {
      departmentChangeHeaderId: 0,
      departmentId: this.departmentSelected.departmentId,
      requestMapId: 0,
      userId: this.authInfo.getUserId(),
      companyId: this.departmentSelected.companyId
    };

    let model: IDepartmentChangePost = {
      header: header,
      details: []
    };

    this.newChanges.forEach(detail => {
      model.details.push({
        departmentChangeDetailsId: detail.departmentChangeDetailsId,
        departmentChangeHeaderId: detail.departmentChangeHeaderId,
        parameterChangeId: detail.parameterChangeId,
        valueBeforeId: detail.valueBeforeId,
        valueBefore: detail.valueBefore,
        valueAfterId: detail.valueAfterId,
        valueAfterNumeric: detail.valueAfterNumeric,
        valueAfter: detail.valueAfter,
        additionalValueId: detail.additionalValueId,
        additionalValue: detail.additionalValue,
        additionalValueBeforeId: detail.additionalValueBeforeId,
        additionalValueBefore: detail.additionalValueBefore,
        comment: detail.comment,
        evidence: detail.evidence,
        publicAccessId: detail.publicAccessId,
        companyId: header.companyId,
        userId: header.userId,
        effectiveDate: detail.effectiveDate
      })
    });

    const promises = model?.details?.map(async detail => {
      if(this.uppyFile?.fileToDelete){
        this.uppyFile.removeFileDefinitely();
      }
      if(detail.evidence){
        const res = await this.uploadFile(detail?.evidence as unknown as FormData);
        const resData = res?.data as FileResponse;
        detail.evidence = resData?.fileUnit?.guidname;
      }
    });
    await Promise.all(promises);
    
    this.sendChanges(model)

  }

  uploadFile(formData: FormData) {
    let data = {
      SystemLoad: this.uppyFile.SystemLoad,
      ModuleLoad: this.uppyFile.ModuleLoad,
      personalFile: this.uppyFile.typePersonalFile,
      routeFile: this.uppyFile.routeFile
    }
    return this.srvDoc.createFile(data, formData).toPromise();
  }

  private sendChanges(model: IDepartmentChangePost) {

    this.departmentService.sendDepartmentChanges(model).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          });

          res.warnings.forEach(warning => {
            this.toastService.warning(warning);
          });

          return;
        }

        this.toastService.success('Acciones enviadas exitosamente')


        this.newChanges = [];
        this.resetInputs();
        this.getChangeDetails();

        this.getDeparmentData(true);

      },
      error: (err) => {
        this.toastService.error("Ha ocurrido un error tratando de enviar los cambios");
      }
    })

  }


  ValidateNewData(data: AbstractControl) {
    if (data.get('newData').value != '') {
      let newData = data.get('newDataId').value ? data.get('newDataId').value : data.get('newData').value
      let currentData = data.get('currentDataId').value ? data.get('currentDataId').value : data.get('currentData').value
      if (newData == currentData) {
        return { invalidValue: true };
      }
    }
    return null;
  }



  isDate(date): boolean {
    if (this.isNumeric(date)) {
      return false;
    }
    return (new Date(date).toString() !== "Invalid Date") && !isNaN(Number(new Date(date)));
  }

  isNumeric(value): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    return !isNaN(Number(value));
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openGuID(guid) {
    try {
      this.srvDoc.getDocument(guid).subscribe(e => {
        window.open(e.data, '_blank');
      }, error => {
        this.toastService.error(error)
      })
    } catch (error) {
      this.toastService.error(error)
    }
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  setGuID(guid) {
    try {
      this.srvDoc.getDocument(guid).subscribe(e => {
        this.file.document = e.data
        this.guiID = guid;
      }, error => {
        this.toastService.error(error)
      })
    } catch (error) {
      this.toastService.error(error)
    }
  }

  openModalSearchEmployee(item: any, IsView = false) {

    let model = null;

    if(item){
      model = {
        employeeId: item.id,
        name: item.text
      };
    }


    this.dialog.open(SearchEmployeeComponent, {
      data: {
        item: model,
        IsView: IsView
      },
      width: '80%'
    }).afterClosed().subscribe((result) => {

      if(result?.data){
        this.selectedItemFromSearchModal = {
          id: result?.data?.employeeId,
          text: result?.data?.name,
        };

        this.actionChangeForm.get('newDataId').setValue(result?.data?.employeeId)
        this.actionChangeForm.get('newData').setValue(result?.data?.name)
      }
    })
  }


  showParameterErrors() {
    let field = this.actionChangeForm.get('parameter');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showNewDataErrors() {
    let field = this.actionChangeForm.get('newData');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showEffectiveDateErrors() {
    let field = this.actionChangeForm.get('effectiveDate');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showEvidenceErrors() {
    let field = this.actionChangeForm.get('evidence');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showRejectionReason(reasonMessage: string) {
    Swal.fire({
      text: reasonMessage,
      icon: "info",
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Entendido'
    })
  }

  resetInputs() {
    this.actionChangeForm.reset();
    this.file.document = '';
    this.guiID = null;
    this.uppyFile.cleanUppy();
    this.additionalValueList = [];
    this.fieldType = this.fieldType == 'drop-additional' ? 'drop' : this.fieldType;
  }

  getPaginatedRecords(event) {
    this.detailsPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  getPaginatedUnsavedChanges(event) {
    this.newChangesPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  setEvidence(evidence){
    this.actionChangeForm.get('evidence').setValue(evidence);
  }

  viewPdf(formData: FormData): void {
    const pdfFile = formData.get('file');

    if (pdfFile && pdfFile instanceof Blob) {
      const pdfUrl = URL.createObjectURL(pdfFile);
      window.open(pdfUrl); 
    } 
  }

}