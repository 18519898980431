import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRequisitionView, PersonalRequisitionsModel } from '../pages/personal-requisition/models/personal-requisitions';
import { environment } from '../../environments/environment';
import { Replacement, Replacements } from '../pages/personal-requisition/models/replacement';
import { AuthInfoService } from './auth-info.service';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { MunicipalityConcursa, ProvinceConcursa, SendConcursaRequisition } from '../pages/personal-requisition/models/sendConcursaRequisition.model';
import { IConcursaRequisitonView } from '../pages/personal-requisition/models/concursa-requisition';

@Injectable({
  providedIn: 'root'
})
export class PersonalRequsitionService {
  url = environment.api_url;
  apiCoreUrl = environment.api_core_url;
  api_lobby = environment.api_core_sigei;
  currentDate = new Date()
  constructor(private _http: HttpClient, private authInfo: AuthInfoService) { }

  //PERSONAL REQUISITION
  createPersonalRequisition(newRequisition: PersonalRequisitionsModel) {
    return this._http.post<any>(`${this.url}/PersonalRequisition`, newRequisition);
  }

  getPersonalRequisition(companyId: number = this.authInfo.getCompanyId(), status: boolean) {
    return this._http.get<ResponseModel<IRequisitionView>>(`${this.url}/PersonalRequisition?Status=${status}&companyId=${companyId}`);
  }

  getRequisitionsPaginated(options: {searchParameter?, propkeyId?, status?, conditions?, page?, pageSize?, type?, advancedFilter?}){
    let params = new HttpParams()
    params = params.append('CompanyId', this.authInfo.getCompanyId().toString())
    params = params.append('PropkeyId', options.propkeyId)
    params = params.append('SearchParameter', options.searchParameter)
    params = params.append('Status', options.status)
    params = params.append('Conditions', options.conditions)
    params = params.append('Page', options.page)
    params = params.append('PageSize', options.pageSize)
    params = params.append('Type', !options.type ? 1 : options.type.toString())
    params = params.append('AdvancedFilter', options.advancedFilter)
    return this._http.get(`${this.url}/PersonalRequisition/GetPaginated`, {params})
  }


  getPersonalRequisitionById(id: number, status: boolean,companyId: number) {
    return this._http.get<ResponseModel<IRequisitionView>>(`${this.url}/PersonalRequisition?IdPersonalRequisition=${id}&status=${status}&companyId=${companyId}`);
  }

  geOnetPersonalRequisition(id: number, status: boolean, condition: number, companyId: number = this.authInfo.getCompanyId()) {
    return this._http.get<any>(`${this.url}/PersonalRequisition/GetOnePersonalRequisition?IdPersonalRequisition=${id}&status=${status}&condition=${condition}&CompanyId=${companyId}`);
  }

  editPersonalRequisition(newRequisition: PersonalRequisitionsModel) {
    return this._http.put<any>(`${this.url}/PersonalRequisition`, newRequisition);
  }

  deletePersonalRequisition(model:any) {
    return this._http.put<any>(`${this.url}/PersonalRequisition/DeletePersonalRequisition`, model);
  }

  approvedPersonalRequisition(id: number) {
    const model = {
      userId: this.authInfo.getUserId(),
      personalRequisitionId: id
    }
    return this._http.post<any>(`${this.url}/PersonalRequisition/Aprovate`, model);
  }

  getReplacements() {
    return this._http.get<any>(`${this.url}/Replacing`);
  }

  getReplacementsByRequisition(requisitionId) {
    return this._http.get<any>(`${this.url}/Replacing?PersonalRequisitionId=${requisitionId}`);
  }
  createReplacement(replacement) {
    return this._http.post<any>(`${this.url}/Replacing`, replacement);
  }
  deleteReplacement(requisitionId: number, employeeId: number) {
    return this._http.delete<any>(`${this.url}/Replacing?PersonalRequisitionId=${requisitionId}&EmployeeId=${employeeId}`);
  }

  getStatistics(
    companyId:number = this.authInfo.getCompanyId(),
    dateFrom: string,
    dateTo: string,
    status: boolean = true,
  ) {
    return this._http.get<any>(`${this.url}/PersonalRequisition/Dashboard?Status=${status}&CompanyId=${companyId}&DateFrom=${dateFrom}&DateTo=${dateTo}`);
  }

  //CAREERS
  getCareers() {
    return this._http.get<any>(`${this.url}/careers?IdCareers=0&companyId=${this.authInfo.getCompanyId()}&Status=true`);
  }

  //LOCATIONS/RECINTOS
  getLocations(companyId = this.authInfo.getCompanyId()) {
    return this._http.get<ResponseModel<any>>(`${this.api_lobby}/Companies?CompanyCode=${companyId}&Status=1&Type=1`);
  }

  //DEPARTMENTS
  getDepartments(companyId) {
    return this._http.get<any>(`${this.apiCoreUrl}/CompanyStructure?CompanyId=${companyId}`);
  }

  //VACANTS
  getVacants() {
    return this._http.get<any>(`${this.url}/Vacants`);
  }

  GetApprovedPositions(companyId: number = this.authInfo.getCompanyId(), status: boolean) {
    return this._http.get<any>(`${this.apiCoreUrl}/positions?type=1&CompanyId=${companyId}&DepartmentId=-1&PositionId=-1&Status=${status}`).toPromise();
  }

  createEmployeeChange(newChange) {
    return this._http.post<any>(`${this.url}/EmployeeChange`, newChange);
  }

  getTitulationsConcursa(){
    return this._http.get<ResponseModel<any>>(`${this.url}/Map/GetTitulationsConcursa`);
  }

  getCausesConcursa(){
    return this._http.get<ResponseModel<any>>(`${this.url}/Map/GetCausesConcursa`);
  }

  getRequirementsConcursa(){
    return this._http.get<ResponseModel<any>>(`${this.url}/Map/GetRequirementsConcursa`);
  }

  getRemunerationsConcursa(){
    return this._http.get<ResponseModel<any>>(`${this.url}/Map/GetRemunerationsConcursa`);
  }

  sendConcursaRequisition(sendConcursaRequisition: SendConcursaRequisition){
    return this._http.post<ResponseModel<any>>(`${this.url}/Map/send-concursa-requisition`, sendConcursaRequisition);
  }

  getConcursaProvinces(){
    return this._http.get<ResponseModel<ProvinceConcursa>>(`${this.url}/Map/GetProvincesConcursa`);
  }

  getConcursaMunicipality(provinciaId){
    return this._http.get<ResponseModel<MunicipalityConcursa>>(`${this.url}/Map/GetMunicipalitiesConcursa?provinciaId=${provinciaId}`);
  }

  getRequisitionFromConcursa(requisitionId: number, companyId: number){
    return this._http.get<ResponseModel<IConcursaRequisitonView>>(`${this.url}/Map/GetRequisitionConcursa?personalRequisitionId=${requisitionId}&companyId=${companyId}`);
  }

  // getProvince()  {
  //   return this._http.get<ResponseModel<any>>(`${this.apiCoreUrl}/City/GetCities/214`);
  // }

  // getMunicipality(province){
  //   return this._http.post<ResponseModel<any>>(`${this.apiCoreUrl}/District/GetDistricts`, province);
  // }

  getPaginatedRequisitionHistory(companyId,PersonalRequisitionId,page, pageSize, propKeyId, text, status, advancedFilter?){
    return this._http.get( `${this.url}/PersonalRequisition/GetPaginatedHistory?PersonalRequisitionId=${PersonalRequisitionId}&searchParameter=${text}&propKeyId=${propKeyId}&page=${page}&pageSize=${pageSize}&CompanyId=${companyId}&Status=${status}&AdvancedFilter=${advancedFilter}`);
  }
}
