import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { postContent } from 'src/app/models/content';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ContentManagementService } from 'src/app/services/content-management.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { SocialMediaService } from 'src/app/services/social-media.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import Swal from 'sweetalert2';
import { AddContentModalComponent } from '../content-management/content-management-list/add-content-modal/add-content-modal.component';
import { SocialMediaModalComponent } from './social-media-modal/social-media-modal.component';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';
declare function applySort(): any;

@Component({
  selector: 'app-social-medial',
  templateUrl: './social-medial.component.html',
  styleUrls: ['./social-medial.component.css']
})
export class SocialMedialComponent implements OnInit {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'LINKS',
    exportAction: 'Export',
    addAction: 'Add',
    view: 'View',
    inactivate: 'Inactivate',
    search: 'SEARCH',
    edit: 'Edit'
  }

  subscription: Subscription = new Subscription();
  sweetAlrt = new SweetAlert
  postContent: postContent

  dataPaginated = []
  dataList = []
  dataListOriginal = []
  filterText: string = '';
  selectedItems: any[] = [];
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propKeyId: number, propkey?: string, displayName: string }[] = [
    { propKeyId: 1, propkey: "socialNetworkId", displayName: "Secuencia" },
    { propKeyId: 2, propkey: "name", displayName: "Nombre" },
    { propKeyId: 3, propkey: "link", displayName: "Enlace" },
  ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' },
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = this.filterTable(event.records)
  }

  searchPropKeySelected!: { propKeyId?: number, propkey?: string, displayName: string };
  selectedPage: any

  constructor(
    private _exlService: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private _contenManagementService: ContentManagementService,
    private dialog: MatDialog,
    public authInfo: AuthInfoService,
    private _toastService: ToastService,
    private social: SocialMediaService
  ) {
  }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.getData();
      }
    });
    applySort();
  }


  getData() {
    this.social.getSocialNetworks(this.authInfo.getCompanyId()).subscribe({
      next: (res: any) => {
        if (res.warnings.length > 0) { this._toastService.warning(res.warnings[0]); return }
        if (res.errors.length > 0) { this._toastService.error(res.errors[0]); return }
        this.dataList = res.dataList
        this.dataListOriginal = res.dataList
        this.filter();
      }
    })
  }

  getSocialMedia() {
  }


  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }


  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter();
  }

  filter() {
    document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.dataList = this.filterTable(this.dataListOriginal)
    }
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl?.find(x => x.item_id == 1) && !sl?.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl?.find(x => x.item_id == 2) && !sl?.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    return list;
  }



  getPaginatedRecords(event) {
    this.dataPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.selectedPage = event.selectedPage
  }




  onKeyChange(text) {
    this.filterText = text;
  }

  openModal(item: any, mode, title) {
    this.dialog.open(SocialMediaModalComponent, {
      width: '60%',
      data: {
        item, mode, title
      },
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (!res) return
        if (res.success) {
          this.getData()
        }
      }
    })

  }

  deleteSocial(item) {

    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#ff0000',
      title: '¿Seguro que desea Inactivar este registro?',

    }).then((result) => {
      if (result.isConfirmed) {
        this.social.deleteSocialNetwork(item.socialNetworkId, this.authInfo.getUserId()).subscribe({
          next: (res: any) => {
            if (res.warnings.length > 0) { this._toastService.warning(res.warnings[0]); return }
            if (res.errors.length > 0) { this._toastService.error(res.errors[0]); return }
            this._toastService.success('Red social Inactivada')
            this.getData()
          }, error: (error: any) => { this._toastService.error('Ha ocurrido un error inesperado') }
        })
      }
    });



  }

  exportToExcel() {
    let data = []
    if (this.filteredList.enable) {
      data = this.filteredList.records
    } else {
      data = this.dataPaginated
    }
    data.forEach(element => {
      element.statusText = element.status ? 'Activo' : 'Inactivo'
    });
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Nombre",
      "Enlace",
      "Typo",
      "Estatus",
    ], [
      "socialNetworkId",
      "name",
      "link",
      "type",
      "statusText"
    ]]
    this._exlService.exportToExcelSpecificColumns(data, excelHeaders, 'Lista de redes sociales', true);
  }

  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }


  onKeyParameterChange(value: { propKeyId?: number, propkey: string, displayName: string } | null) {
    this.searchPropKeySelected = value ?? null;
  }

  exportToPdf() {
    const reportCode: ReportCode = ReportCode.Social_Media;
    const companyId: number = this.authInfo.getCompanyId();
    const status: any = this.selectedItems?.length === 2 || this.selectedItems?.length == 0 ? '0-1' : (this.selectedItems[0]?.item_id === 1 ? 1 : 0);
    const pageSize = this.dataPaginated?.length
    const propKeyId = this.searchPropKeySelected?.propKeyId ?? 0
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&PropkeyId=${propKeyId}&SearchParameter=${this.filterText ?? ''}&Condition=&Status=${status}&Page=${this.selectedPage}&PageSize=${pageSize}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Códigos Certificación',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }


}
