<div class="card card-custom dtl_font dtl_font_primary">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-contract-1 icon-3x text-danger"></i>
                <h1 class="text-primary pl-2"> Vinculación </h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="volver atrás">
            <a (click)="openDashBoard()"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto"
                *ngIf="privilege.dashboardAction.value">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </a>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between align-items-center">
        <div class="col-sm-12 col-md-6 c-left">
            <div class="form-group">
                <label class="text-primary">
                    Estatus
                </label>
                <div class="card-toolbar d-flex justify-content-start">
                    <div class="col-md-4 mr-2 px-0 pt-1">
                        <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                            [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                            (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle"
                        *ngIf="privilege.exportAction.value" type="button"
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <!-- <a class="dropdown-item align-items-center" (click)="exportToExcel()">
                            <i class="flaticon-excel icon-2x text-success mr-3"></i>
                            Excel
                        </a> -->
                        <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                            <i class="fa fa-print icon-2x text-primary mr-3"></i>
                            Visualizar Reporte
                        </a>
                        <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a> -->
                    </div>
                </div>

                <div class="col text-nowrap">
                    <app-search-bar-pagination #searchBar
                    (onFilter)="filterSearch($event)"
                    [namePageLocalStorage]="localStorageSearch"
                    [parameters]="searchBarDisplayNames">
                    </app-search-bar-pagination>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table id="" class="table table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">
                            Secuencia
                        </th>
                        <th class="text-left min-w-175px">Documento de Identidad</th>
                        <th class="text-left">Nombre</th>
                        <th class="text-left">Cargo</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-center">Número de Remisión</th>
                        <th class="text-left">Fecha de Vinculación</th>
                        <th class="text-left">Estatus</th>
                        <th class="text-center">Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of postulationsTable">
                        <td class="text-center">
                            {{item.ppvid}}
                        </td>
                        <td class="text-left" *ngIf="item.typeIdentificationId == 1">{{item.personalIdentification | mask: '000-0000000-0'}}</td>
                        <td class="" *ngIf="item.typeIdentificationId != 1">{{item.personalIdentification}}
                        </td>
                        <td class="text-left">{{item.personName}}</td>
                        <td class="text-left">{{item.positionName}}</td>
                        <td class="text-left">{{item.departmentName}}</td>
                        <td class="text-center">{{item.referralNumber}}</td>
                        <td class="text-left">
                            <ng-container *ngIf="item.hiredDate">

                                {{item.hiredDate | date: 'dd/MM/yyyy'}}
                            </ng-container>
                            <ng-container *ngIf="!item.hiredDate">
                                N/A
                            </ng-container>
                        </td>
                        <td class="text-left">
                            <span *ngIf="item.condition == 2" class="font-weight-bold text-danger">No Nombrado</span>
                            <span *ngIf="item.condition == 3" class="font-weight-bold text-success">Nombrado</span>
                            <span *ngIf="item.condition == 4" class="font-weight-bold text-warning">Reservado para
                                Nombramiento</span>
                            <span *ngIf="item.condition == 10" class="font-weight-bold text-danger">Nombramiento Anulado</span>
                        </td>
                        <td class="w-200px text-center">
                            <button
                                *ngIf="(item.condition == 2 || item.condition == 4 || item.condition == 10) && privilege.hiredAction.value"
                                type="button" (click)="openModal(item.ppvid)"
                                class="btn btn-sm btn-light-success btn-icon mr-2">
                                <i class="flaticon-contract-1 icon-2x" data-title="Nombrar" data-toggle="tooltip"></i>
                            </button>
                            <button
                                *ngIf="(item.condition == 2 || item.condition == 10) && privilege.reserveAction.value"
                                (click)="setToElegible(item.ppvid)" type="button"
                                class="btn btn-sm btn-light-warning btn-icon mr-2">
                                <i class="flaticon-reloj-de-pared icon-2x" data-title="Reservar"
                                    data-toggle="tooltip"></i>
                            </button>
                            <button
                                *ngIf="item.condition == 3 && privilege.viewAction.value"
                                (click)="show(item.ppvid)" type="button"
                                class="btn btn-sm btn-light-info btn-icon mr-2">
                                <i class="fas fa-eye" data-title="Ver" data-toggle="tooltip"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>

                <app-paginator
                [listedRecordsLength]="postulationsTable?.length"
                [registerQuantity]="employeesPagedResult.totalItems"
                [registerPerPage]="employeesPagedResult.pageSize"
                [totalPages]="employeesPagedResult.totalPages"
                [selectedPage]="employeesPagedResult.page"
                (onChange)="getPaginatedRecords($event)">
                </app-paginator>
            </div>
        </div>
    </div>
</div>

<export-data-to-pdf [title]="'Vinculación'" id="dataToPrint">
    <ng-container thead>
        <th class="text-center min-w-175px">Documento de Identidad</th>
        <th class="text-left">Nombre</th>
        <th class="text-left">Cargo</th>
        <th class="text-left">Unidad Organizativa</th>
        <th class="text-center">Remisión</th>
        <th class="text-center">Fecha de vinculación a la institución actual</th>
        <th class="text-left">Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let item of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.postulationsTable" data-row="0" class="datatable-row" style="left: 0px;">
            <td class="text-left" *ngIf="item.typeIdentificationId == 1">{{item.personalIdentification |
                mask: '000-0000000-0'}}</td>
            <td class="text-left" *ngIf="item.typeIdentificationId != 1">{{item.personalIdentification}}
            </td>
            <td class="text-left">{{item.personName}}</td>
            <td class="text-left">{{item.positionName}}</td>
            <td class="text-left">{{item.departmentName}}</td>
            <td class="text-center">{{item.referralNumber}}</td>
            <td class="text-center">
                <ng-container *ngIf="item.hiredDate">

                    {{item.hiredDate | date: 'dd/MM/yyyy'}}
                </ng-container>
                <ng-container *ngIf="!item.hiredDate">
                    N/A
                </ng-container>
            </td>
            <td>
                <span *ngIf="item.condition == 2" class="font-weight-bold text-danger">No Nombrado</span>
                <span *ngIf="item.condition == 3" class="font-weight-bold text-success">Nombrado</span>
                <span *ngIf="item.condition == 4" class="font-weight-bold text-warning">Reservado para
                    Nombramiento</span>
            </td>

        </tr>
    </ng-container>
</export-data-to-pdf>
