import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PayrollService } from 'src/app/services/payroll.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reject-files-hacienda',
  templateUrl: './reject-files-hacienda.component.html',
  styleUrls: ['./reject-files-hacienda.component.css']
})
export class RejectFilesHaciendaComponent implements OnInit {

  reason: string = '';
  constructor(
    private dialogRef: MatDialogRef<RejectFilesHaciendaComponent>,
    private srvPayroll: PayrollService,
    private toastr: ToastrService,
    private auth: AuthInfoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }



  reject(){
    const minLength = 10
    const maxLength = 255
    if(this.reason.length <= 0){
      this.toastr.warning(`Debe indicar el motivo`)
      return
    }
    if(this.reason.length <= minLength){
      this.toastr.warning(`El motivo debe contener más de ${minLength} caracteres`)
      return
    }
    if(this.reason.length >= maxLength){
      this.toastr.warning(`El motivo debe contener menos de ${maxLength} caracteres`)
      return
    }
    const model = {
      submissionId: this.data.item.submissionId,
      userId: this.auth.getUserId(),
      reason: this.reason
    }

    Swal.fire({
      title: 'Rechazar',
      text: '¿Está seguro/a de rechazar esta nómina?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Rechazar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.srvPayroll.rejectPayrollFilesHacienda(model).subscribe({
          next: (_:any) => {
            if(_.errors.length> 0){this.toastr.error(_.errors[0]); return}
            this.toastr.success("La nómina fué rechazada exitosamente")
            this.close(true)
          }, error: (error) => {this.toastr.error('Ha ocurrido un error inesperado')}
        })
      }
    });
  }


  close(success = false){
    this.dialogRef.close({success})
  }

}
