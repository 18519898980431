<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Listado de Faltas
    </h2>
    <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom ">
    <div class="p-5">
        <div class="row "
            *ngIf="data.couldBeRecurring">
            <div class="alert alert-warning d-flex align-items-center">
                <i class="fas fa-exclamation-triangle mr-2 fa-2x blink-for-warning"></i>
                <div>
                    El Servidor Público ha alcanzado el número de faltas necesarios para ser considerada como <strong>reiterada</strong>. Es recomendado escalar a un grado superior.
                </div>
            </div>

        </div>
        <div class="card-header mt-3 row justify-content-between">
            <!-- <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
                <div class="form-group">
                    <label for="">Grado</label>
                    <div>
                        <ng-multiselect-dropdown [placeholder]="'Seleccionar grado'" [settings]="dropdownSettings"
                            [data]="grades" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                            (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                    </div>
                </div>
            </div> -->

            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
                <div class="form-group text-center">
                    <label >¿Falta Reiterada?</label>
                    <div class="d-flex justify-content-center align-items-center mb-3">
                        <strong
                            class=" m-0 mx-4">No</strong>
                        <span
                            class="switch  switch-sm switch-primary  me-2">
                            <label><input
                                    (change)="changeRecurringOffense(!isRecurringOffense)"
                                    class="cursor-pointer abc-checkbox-primary align-self-center"
                                    type="checkbox"
                                    [checked]="isRecurringOffense" /><span>
                                </span></label>
                        </span>
                        <strong
                            class=" m-0 mx-4">Sí</strong>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-8 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end align-items-center">
                    <div class="dropdown col-md-auto col-sm-12">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                                <i class="fa fa-print icon-2x text-primary mr-3"></i>
                                Visualizar Reporte
                            </a>
                        </div>
                    </div>

                    <div class="col text-nowrap">
                        <el-search-bar #searchBar (onKeyChange)="onKeyChange($event)"
                            (onKeyParameterChange)="onKeyParameterChange($event)" [keyValue]="filterText"
                            [records]="offensesList" [itemForSearching]="searchBarDisplayNames"
                            (onChange)="getFilteredList($event);"></el-search-bar>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table atbl table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-left">Secuencia</th>
                        <th class="text-left">Descripción de Falta</th>
                        <th class="text-left">Grado</th>
                        <th class="text-left">Estatus</th>
                        <th class="text-center" width="10%">Seleccionar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of offensesListPaginated; let i = index">
                        <td class="text-left">{{item?.offenseId}}</td>
                        <td class="text-left">{{item?.description}}</td>
                        <td class="text-left">{{item?.grade}}</td>
                        <td class="text-left">
                            <div *ngIf="!item.status" class="text-danger">
                                Inactiva
                            </div>

                            <div *ngIf="item.status" class="text-success">
                                Activa
                            </div>
                        </td>
                        <td class="actionsBtn">
                            <div class="form-check form-check-inline">
                                <div class="border-0">
                                    <input type="radio" style="cursor: pointer;" name="selector" [checked]="offenseSelected?.offenseId == item?.offenseId"
                                        class="form-check-input " (change)="selectItem(item)">
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <el-caption [originalRecordsLength]="offensesListFiltered.length"
                    [listedRecordsLength]="offensesListPaginated.length" #paginator
                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :offensesListFiltered.length"
                    [records]="filteredList.enable ? filteredList.records : offensesListFiltered"
                    (onChage)="getPaginatedRecords($event)"></el-caption>
            </div>
        </div>
    </div>

</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3" type="button" [disabled]="!offenseSelected" (click)="closeModal(true)">
        <i class="flaticon-disco-flexible" ></i>
        <span>Seleccionar</span>
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="closeModal(false)">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>