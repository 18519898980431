import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BulkLoadFinal, IFinalPayrollPOST, INewsPOST } from '@payroll/final-payroll/models/models';
import { environment } from 'src/environments/environment';
import { EmployeeInfo } from '../pages/RegistrationAndControl/employee-maintenance/models/EmployeeMaintenance';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class FinalPayrollService {

  private readonly apihrm = environment.api_url
  private companyId = 0;
  constructor(
    private http: HttpClient,
    private authInfo: AuthInfoService
  ) { 
    this.companyId = this.authInfo.getCompanyId()
  }
  
  getPeriods(){
    return this.http.get(`${this.apihrm}/Period?CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getBudgetAreas(){
    return this.http.get(`${this.apihrm}/BudgetArea?BudgetAreaId=0&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getEmployeeInactive(budgetAreaId: number){
    const companyId = this.authInfo.getCompanyId()
    return this.http.get(`${this.apihrm}/Employees?BudgetAreaId=${budgetAreaId}&Type=11&CompanyId=${companyId}&Status=false`)
  }
  getEmployeeInactivePaginated(budgetAreaId, page?:number, pageSize?:number, PropkeyId = 0, SearchParameter = null){
    return this.http.get(`${this.apihrm}/Employees/GetPaginatedFilter?BudgetAreaId=${budgetAreaId}&Type=4&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Page=${page}&PageSize=${pageSize}&CompanyId=${this.authInfo.getCompanyId()}`)
  }
  
  postFinalPayroll(payroll: IFinalPayrollPOST){
    return this.http.post(`${this.apihrm}/header`, payroll)
  }
  
  getNewsConcept(companyId: number = this.authInfo.getCompanyId(), status: boolean = true) {
    return this.http.get<any>(`${this.apihrm}/NewsConcept?NewsConceptId=0&CompanyId=${companyId}&Status=${status}`);
  }
  
  getNewsType(status: boolean = true) {
    return this.http.get<any>(`${this.apihrm}/NewsType?NewsTypeId=0&CompanyId=${this.companyId}&Status=${status}`);
  }
  getEmployeeById(employeeId: number){
    return this.http.get(`${this.apihrm}/employees?type=5&employeeNumber=${employeeId}&companyId=${this.companyId}`)
  }
  
  postNewsFinalPayroll(news: INewsPOST){
    return this.http.post(`${this.apihrm}/PayrollNews`, news)
  }
  putNewsFinalPayroll(news: INewsPOST){
    return this.http.put(`${this.apihrm}/PayrollNews`, news)
  }
  
  getOverallSumary(periodId, areaId){
    /* PayrollTypeId=1 para nominas finales */
    return this.http.get(`${this.apihrm}/Detail/GetOverallSummary?PayrollPeriodId=${periodId}&PayrollTypeId=1&BudgetAreaId=${areaId}&Type=12&CompanyId=${this.companyId}&Status=true`)
  }
  
  getPayrollNewDetail(PayrollPeriodId: number, EmployeeId: number, type: number, Status: boolean = true){
    return this.http.get(`${this.apihrm}/PayrollNews/GetPayrollNewsToApply?PayrollPeriodId=${PayrollPeriodId}&EmployeeId=${EmployeeId}&type=${type}&CompanyId=${this.companyId}&Status=${Status}`);
  }
  
  /*getNewsByEmployeeId(employeeId: number, periodId: number){
    return this.http.get<any>(`${this.apihrm}/PayrollNews?PayrollNewsId=${employeeId}&type=4&payrollPeriodId=${periodId}&CompanyId=${this.companyId}&Status=true`);
  }*/

  getNewsByEmployeeId(employeeId: number, periodId: number, budgetAreaId: number, PayrollType: number, type: number){
    return this.http.get(`${this.apihrm}/PayrollNews/GetPayrollNewsToApply?PayrollPeriodId=${periodId}&EmployeeId=${employeeId}&type=${type}&CompanyId=${this.authInfo.getCompanyId()}&Status=true&BudgetAreaId=${budgetAreaId}&PayrollType=${PayrollType}`);
  }
  
  getPayrollNewById(payrollNewsId: number, status: boolean = true) {
    return this.http.get<any>(`${this.apihrm}/PayrollNews?PayrollNewsId=${payrollNewsId}&type=1&CompanyId=${this.companyId}&Status=${status}`);
  }

  getBulkLoadFinal(bulkLoadFinal: BulkLoadFinal[]){
    return this.http.post(`${this.apihrm}/BulkLoad/GetBulkLoadFinal`, bulkLoadFinal);
  }

  postHeader(header: any){
    return this.http.post(`${this.apihrm}/header`, header);
  }
  getHeader(headerId){
    return this.http.get(`${this.apihrm}/Header/GetHeader?HeaderId=${headerId}&type=2&CompanyId=${this.authInfo.getCompanyId()}`)
  }

}
