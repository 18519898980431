<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        {{title}} Revisión
    </h2>
    <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom ">

    <div class="card-body-custom" style="min-height: 600px;">

        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="form-group mb-3">
                        <label>Fecha de Revisión <span class="text-danger">*</span></label>
                        <div class="input-group">
                            <input formControlName="reviewDate" type="date" class="form-control" />
                        </div>
                        <errors-component [controlName]="form.get('reviewDate')">
                        </errors-component>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-3">
                        <label>Comentario <span class="text-danger">*</span></label>
                        <div class="input-group">
                            <textarea formControlName="comment" class="form-control" rows="8"></textarea>
                        </div>
                        <errors-component [controlName]="form.get('comment')">
                        </errors-component>
                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-12 mb-0 d-flex justify-content-between align-items-center">
                    <h2 class="text-primary">Listado de Evidencias</h2>
                    <button *ngIf="data.viewType != viewTypes.View" type="button"
                        class="btn btn-success btn-sm btn-pill font-weight-bolder m-0" (click)="addEvidence(null)">
                        <i class="flaticon-mas icon-2x"></i>
                        Agregar Evidencia
                    </button>
                </div>
                <div class="col-12">
                    <hr class="text-primary">
                </div>
                <div class="col-12 mt-2" formArrayName="evidences">
                    <ng-container *ngIf="countEvidencesAvailable <= 0">
                        <h6>No hay evidencias agregadas.</h6>
                    </ng-container>
                    <ng-container *ngFor="let evidenceForm of getEvidences().controls; let i = index">
                        <ng-container *ngIf="evidenceForm.get('status').value === true">
                            <div class="row justify-content-between align-items-start mb-3" [formGroupName]="i">
                                <div class="col-md-4">
                                    <div class="form-group ">
                                        <label>Tipo de Evidencia <span class="text-danger">*</span></label>
                                        <ngx-select-dropdown [config]="evidenceTypeDropdown"
                                            formControlName="evidenceTypeObject" (change)="changeEvidenceType(i)"
                                            [options]="evidencesType" [multiple]="false" class="flex-1">
                                        </ngx-select-dropdown>
                                        <errors-component [controlName]="evidenceForm.get('evidenceTypeId')">
                                        </errors-component>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group ">
                                        <label>Evidencia <span class="text-danger">*</span></label>
                                        <div class="card align-items-center">
                                            <uppy-file [customId]="evidenceForm.get('upplyFileId')?.value"
                                                previewDefaultImage="assets/images/ver_file.jpg"
                                                classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-50px"
                                                [cleanAll]="false" [allowedFileTypes]="['application/pdf', 'image/*']"
                                                [height]="100" [fileForEditting]="evidenceForm.get('evidence')?.value"
                                                [isDisabled]="(data.viewType == viewTypes.View)">
                                            </uppy-file>
                                        </div>
                                        <errors-component [controlName]="evidenceForm.get('evidence')">
                                        </errors-component>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group " *ngIf="data.viewType != viewTypes.View">
                                        <label>Acciones </label>
                                        <div class="d-flex ">
                                            <button type="button" class="btn btn-danger btn-pill" (click)="removeEvidence(i)">
                                                <i class="flaticon-delete"></i> Remover del Listado
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <hr class="text-primary">
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                </div>
            </div>
        </form>

    </div>

</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="closeModal(false)">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="data.viewType != viewTypes.View"
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success mr-2" type="button"
        (click)="saveChanges()">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>