import { OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { InterviewEvaluationService } from "src/app/services/interview-evaluation.service";
import { ToastService } from "src/app/shared/toast/toast.service";

@Component({
  selector: 'app-salary-range',
  templateUrl: './salary-range.component.html',
  styleUrls: ['./salary-range.component.css']
})
export class SalaryRangeComponent  {

  postulationSelected: any;

    constructor(
        private toastService: ToastService,
        public dialogRef: MatDialogRef<SalaryRangeComponent>,
        private _changeDet: ChangeDetectorRef,
        private _dialog: MatDialog,
        private interviewService: InterviewEvaluationService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.postulationSelected = data?.postulationSelected;
        this.dialogRef.disableClose = true;
    }

    selectSalaryRange(salary: number) {
        this.dialogRef.close({
            Applied: true,
            salary: salary,
        });
    }

}
