import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RecruitmentQuestions } from '../models/recruitment-questions.model';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class RecruitmentQuestionsService {
  private readonly urlApi = environment.api_core_url

  constructor(private http: HttpClient, private auth: AuthInfoService) { }

  getRecruitmentQuestions(questionTypeId: number){
    return this.http.get<ResponseModel<RecruitmentQuestions>>(`${this.urlApi}/RecruitmentQuestions?CompanyId=${this.auth.getCompanyId()}&questionTypeId=${questionTypeId}&status=true&type=1`)
  }

}
