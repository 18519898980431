<div class="container-fluid">
    <div id="pdfExp" class="card">
        <div class="card-header px-22 border-bottom-danger">
            <div class="align-items-center d-flex flex-wrap justify-content-lg-between justify-content-md-center"
                id="header">
                <div class="logo px-2">
                    <img src="assets/media/logos/LogoAdmiP.png" alt="" class="max-w-200px" />
                </div>

                <div class="title p-2 d-flex justify-content-center align-items-center flex-column">
                    <h1 class="ttlPDF text-primary">
                        Remisión de No Objeción
                    </h1>
                    <!-- <h2 class="text-danger">Titulo de la pantalla Referenciada</h2> -->
                </div>
                <div class="date mt-md-5 p-4 d-flex flex-wrap justify-content-between align-items-center">
                    <h4 class="text-right mr-3">Fecha de solicitud</h4>
                    <h5 class="text-danger text-right">__-__-____</h5>
                    <!-- &nbsp; -->
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-12 row">
                    <div class="pdf-list">
                        <div class="label-inline">
                            <label>Número de Registro:</label>
                            <span>N/A</span>
                        </div>
                        <div class="label-inline">
                            <label>Instrumento Legal:</label>
                            <span>N/A</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gutter-t">
                <div class="table-responsive border-u bg-white p-0">
                    <table class="table table-vertical-center">
                        <thead class="bg-primary">
                            <tr>
                                <th class="text-center">Cédula</th>
                                <th class="text-center">Nombre Completo</th>
                                <th class="text-center">Sexo</th>
                                <th class="text-center">Cargo Solicitado</th>
                                <th class="text-center">Nível Académico</th>
                                <th class="text-center">Salario Solicitado</th>
                                <th class="text-center">Grupo Ocupacional</th>
                                <th class="text-center">Unidad Organizadora<br>(área o unidad organizativa)</th>
                                <th class="text-center">Tipo de Vinculación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>                                
                            </tr>
                            <tr>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>                                
                            </tr>
                            <tr>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>                                
                            </tr>
                            <tr>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>
                                <td class="text-center">Tests</td>                                
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
    <i class="flaticon2-printer font-size-h1"></i>
</button>
<script>
    let print = () => {
        let pdf = document.getElementById("pdfExp");
        pdf.contentWindow.focus();
        pdf.contentWindow.print();
    };
</script>