<!-- <div *ngIf="isLoadingData()" class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
    <el-spinner animation="spinner-logo"></el-spinner>
    <h3 class="text-primary">Cargando...</h3>
</div> -->
<ng-container>
    <h2 class="d-flex m-0 align-items-center justify-content-between mat-dialog-title font-size-h2 text-white" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
        <div class="d-flex align-items-center">
            <span class="h-20px label label-danger label-dot mr-4 w-20px"></span>
            <span>Vinculación</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    </h2>
    <div class="mat-dialog-content">
        <div class="container-fluid">


            <div class="row bg-light-info">
                <div class="text-center col-md-3">
                    <div *ngIf="!postulationSelected?.photo" class="symbol symbol-150 d250 rd20 mr-3 my-5">
                        <img alt="Pic" src="assets/images/no-user.png" />
                    </div>
                    <div *ngIf="postulationSelected?.photo" class="symbol symbol-150 d250 rd20 mr-3 my-5">
                        <img *ngIf="postulationSelected?.photoloaded" alt="Pic" [src]="postulationSelected?.photoloaded" onerror="this.error=null; this.src='assets/images/no-user.png'" />
                    </div>
                </div>
                <div *ngIf="postulationSelected" class="col-md-9 d-flex justify-content-center flex-column">
                    <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Nombre: <span
                            class="text-dark ml-3">{{postulationSelected?.personName}}</span></h3>
                    <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Identificación:
                        <span *ngIf="postulationSelected?.typeIdentificationId == 1"
                            class="text-dark ml-3">{{postulationSelected?.personalIdentification | mask:
                            '000-0000000-0'}}</span>
                        <span *ngIf="postulationSelected?.typeIdentificationId != 1"
                            class="text-dark ml-3">{{postulationSelected?.personalIdentification}}</span>
                    </h3>
                    <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Teléfono:
                        <ng-container *ngIf="postulationSelected?.phoneNumber != null">
                            <span *ngIf="postulationSelected?.phoneNumber.length == 10"
                                class="text-dark ml-3">{{postulationSelected?.phoneNumber | mask: '(000) 000-0000'}}</span>
                            <span *ngIf="postulationSelected?.phoneNumber.length != 10"
                                class="text-dark ml-3">{{postulationSelected?.phoneNumber}}</span>
                        </ng-container>
                    </h3>
                    <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Correo Electrónico: <span
                            class="text-dark ml-3">{{postulationSelected?.email}}</span></h3>
                    <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Nivel Académico: <span
                            class="text-dark ml-3">{{postulationSelected?.academicLevel}}</span></h3>
                </div>
            </div>
            <div class="row mt-4">
                <nav>
                    <div class="nav nav-tabs border-tabs-primary" id="nav-tab" role="tablist">
                        <a class="nav-link active" id="nav-e-info-tab" data-toggle="tab" href="#nav-e-info" role="tab"
                            aria-controls="nav-e-info" aria-selected="true">
                            <span class="nav-icon mr-5">
                                <i class="flaticon-proceso icon-3x"></i>
                            </span>
                            <span class="nav-text">
                                <h3>Datos de Nombramiento</h3>
                            </span>
                        </a>
                        <a class="nav-link border-tabs-primary" id="nav-e-entry-list-tab" data-toggle="tab"
                            href="#nav-e-entry-list" role="tab" aria-controls="nav-e-entry-list" aria-selected="false">
                            <span class="nav-icon mr-5">
                                <i class="flaticon-lista-de-verificacion icon-3x"></i>
                            </span>
                            <span class="nav-text">
                                <h3>Lista de Entrada</h3>
                            </span>
                        </a>
                    </div>
                </nav>
                <form [formGroup]="form" class="pt-4">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-e-info" role="tabpanel"
                            aria-labelledby="nav-e-info-tab">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Sucursal<span class="text-danger"> *</span></label>
                                        <ngx-select-dropdown class=" flex-1"formControlName="branch"
                                         [options]="branches"  [config]="getDropdownConfig('companyName')" [multiple]='false'>
                                    </ngx-select-dropdown>
                                    <span *ngIf="this.form.get('branch')?.invalid && formIsInvalid"
                                                class="text-danger">{{ showBranchErrors() }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="departmentId">Unidad organizativa</label>
                                        <input formControlName="department" readonly type="text"
                                            class="form-control form-control-solid form-control-lg" />
                                    </div>
                                </div>
                                <!-- <div class="col-lg-4">
                                    <div class="form-group">
                                        <label><span>Postulante</span></label>
                                        <input formControlName="personName" readonly type="text" name="name"
                                            class="form-control form-control-solid form-control-lg" />
                                    </div>
                                </div> -->
                                <div class="col-lg-4" >
                                    <div class="form-group">
                                        <label>Tipo de Nombramiento</label>
                                        <input formControlName="bondingType" readonly type="text"
                                            class="form-control form-control-solid form-control-lg" />
                                    </div>
                                </div>
                                <!-- <div class="form-group col-lg-4" *ngIf="postulationSelected?.requesitiontTypeId == 5">
                                    <label class="d-block w-100">Tipo de Nombramiento</label>
                                    <div class="dropdown-alt input-group" *ngIf="postulationSelected?.condition != 3">
                                        <ngx-select-dropdown class=" flex-1 " (change)="changeBondingType($event.value)"
                                            formControlName="bondingTypeObject"
                                            [config]="getDropdownConfig('stringData', 'Seleccionar')"
                                            [options]="bondingTypeList"></ngx-select-dropdown>
                                    </div>

                                    <input formControlName="bondingType" readonly type="text"
                                        *ngIf="postulationSelected?.condition == 3"
                                        class="form-control form-control-solid form-control-lg" />
                                </div> -->
                                <div class="form-group col-lg-4">
                                    <label class="d-block w-100">Tipo de Servidor Público <span class="text-danger">*</span></label>
                                    <div *ngIf="postulationSelected?.condition != 3">
                                        <ngx-select-dropdown class=" flex-1 "
                                            (change)="changeEmployeeType($event.value)"
                                            formControlName="employeeType"
                                            [config]="getDropdownConfig('description', 'Seleccionar')"
                                            [options]="employeeTypeList"></ngx-select-dropdown>
                                            <span *ngIf="this.form.get('employeeType')?.invalid && formIsInvalid"
                                                class="text-danger">{{ showEmployeeTypeErrors() }}</span>
                                    </div>
                                    <input formControlName="employeeType" readonly type="text"
                                        *ngIf="postulationSelected?.condition == 3"
                                        class="form-control form-control-solid form-control-lg" />

                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Cargo MAP</label>
                                        <input formControlName="mapPosition" readonly type="text"
                                            class="form-control form-control-solid form-control-lg" />

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Cargo</label>
                                        <input formControlName="position" readonly type="text"
                                            class="form-control form-control-solid form-control-lg" />

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Salario Solicitado</label>
                                        <input readonly mask="separator.2" thousandSeparator="," prefix="RD$ "
                                            formControlName="desiredSalary" type="text" name="desiredSalary"
                                            class="form-control form-control-solid form-control-lg" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Salario Aprobado</label>
                                        <input readonly mask="separator.2" thousandSeparator="," prefix="RD$ "
                                            formControlName="salary" type="text" name="salary"
                                            class="form-control form-control-solid form-control-lg" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Número de cuenta <span class="text-danger">*</span></label>
                                        <input autocomplete="off" formControlName="bankAccount" type="tel"
                                            name="bankAccount"
                                            class="form-control form-control-solid form-control-lg" mask="0*" />
                                        <span *ngIf="this.form.get('bankAccount').invalid && formIsInvalid"
                                            class="text-danger">{{ showBankAccountErrors() }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Tipo de Cuenta <span class="text-danger">*</span></label>
                                        <ngx-select-dropdown
                                        [options]="accountTypes" [config]='getDropdownConfig("stringData")'
                                        formControlName="accountType" class="flex-1">
                                        </ngx-select-dropdown>
                                        <span *ngIf="this.form.get('accountType').invalid && formIsInvalid"
                                        class="text-danger">
                                        Este campo es requerido
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Entidad Bancaria <span class="text-danger">*</span></label>
                                        <ngx-select-dropdown class=" flex-1" formControlName='bankEntity'
                                            [options]='bankingEntity'
                                            [config]='getDropdownConfig("stringData")'>
                                        </ngx-select-dropdown>
                                        <span *ngIf="this.form.get('bankEntity').invalid && formIsInvalid"
                                            class="text-danger">{{ showBankEntityErrors() }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Fecha de ingreso</label>
                                        <input readonly formControlName="admissionDate" type="date" name="dateStart"
                                            class="form-control form-control-solid form-control-lg" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="nremision">Número de Remisión</label>
                                        <input readonly formControlName="referralNumber" type="number"
                                            class="form-control form-control-solid form-control-lg" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Inicio de Nombramiento</label>
                                        <div>
                                            <input readonly formControlName="contractStart" type="date" name="startDate"
                                                class="form-control form-control-solid form-control-lg" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4"
                                    *ngIf="postulationSelected?.bondingType != 2 && postulationSelected?.bondingType != 1">
                                    <div class="form-group">
                                        <label>Expiración del Nombramiento</label>
                                        <div>
                                            <input formControlName="contractExpiration" type="date"
                                                name="contractExpirationDate"
                                                class="form-control form-control-solid form-control-lg" />
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!data.inDetails" class="form-group col-lg-4">
                                    <label>Beneficios del Servidor Público</label>
                                    <!-- <ngx-select-dropdown class=" flex-1" formControlName='employeeBenefits'
                                        [options]='benefits' [config]='benefitSelectConfig'
                                        (change)='fillBenefits($event.value)' [multiple]='true'></ngx-select-dropdown> -->

                                        <ng-multiselect-dropdown
                                        [placeholder]="'Seleccionar Estatus'"
                                        [settings]="getDropdownSettings('ocode', 'stringData')"
                                        [data]="benefits"
                                        formControlName='employeeBenefits'
                                        (onDeSelect)="onDeSelect($event)"
                                        (onSelect)="onSelect($event)"
                                        (onDeSelectAll)="onDeSelectAll($event)"
                                        (onSelectAll)="onSelectAll($event)"
                                    ></ng-multiselect-dropdown>
                                </div>

                                <div class="form-group col-lg-4">
                                    <label>Área Presupuestaria <span class="text-danger">*</span></label>
                                    <ngx-select-dropdown class=" flex-1" formControlName='budgetAreaO'
                                        [options]='budgetAreas' [config]='budgetAreaSelectConfig'
                                        (change)='fillBudgetArea($event.value)' [multiple]='false'>
                                    </ngx-select-dropdown>
                                    <span *ngIf="this.form.get('budgetAreaId').invalid && formIsInvalid"
                                        class="text-danger">{{ showBudgetAreaErrors() }}</span>
                                </div>
                                <div class="form-group col-lg-4">
                                    <label>Categoría <span class="text-danger">*</span></label>
                                    <ngx-select-dropdown class=" flex-1" formControlName='category'
                                        [options]='employeeCategories' [config]='employeeCategoriesConfig'
                                        [multiple]='false'>
                                    </ngx-select-dropdown>
                                    <span *ngIf="form.get('category')?.errors?.required && formIsInvalid" class="text-danger">Este campo es requerido</span>
                                </div>
                                <div class="form-group col-lg-4">
                                    <label class="d-block">Supervisor <span class="text-danger text-monospace legend">*</span></label>
                                    <div class="input-group">
                                        <input type="text" class="form-control form-control-solid form-control-lg"
                                            formControlName='employeeSupervisor'
                                            readonly>
                                        <a *ngIf="!data?.inDetails" data-toggle="tooltip" data-original-title="Seleccionar Supervisor"
                                         (click)="openModalSearchEmployee()" class="input-group-text bg-primary btn-select-Supervisor">
                                            <i class="flaticon2-search-1 icon-2x text-white"></i>
                                    </a>
                                    </div>
                                    <span *ngIf="formIsInvalid && form?.get('employeeSupervisor')?.hasError('required') === true"
                                    class="text-danger">Este campo es requerido</span>
                                </div>
                                <!-- <div class="form-group col-lg-4">
                                    <label class="d-block">Supervisor de Departamento <span class="text-danger text-monospace legend">*</span></label>
                                    <div class="input-group">
                                        <input type="text" class="form-control form-control-solid form-control-lg"
                                            formControlName='departmentSupervisor'
                                            readonly></div>
                                </div> -->
                                <div class="form-group col-lg-4 " *ngIf="data?.inDetails">
                                    <label>Beneficios del Servidor Público</label>
                                    <div class="card card-custom  border border-primary">
                                        <div class="card-body" style="padding: 0;">
                                            <div class="table-responsive">
                                                <table class="table table-hover table-vertical-center">
                                                    <thead>
                                                        <tr class="bg-primary">
                                                            <th class="text-left" style="width: 100px;">Beneficio</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr data-row="0" class="datatable-row"
                                                            *ngFor="let item of getEmployeeBenefits">
                                                            <td class="text-left">
                                                                {{item?.stringData}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- <div class="d-flex align-items-center flex-grow-1 mb-3">
                        <div class="flex-shrink-0 mr-2">
                            <span class="switch switch-sm switch-primary">
                                <h4 class="font-size-h5 font-weight-bolder text-primary flex-shrink-0 m-0 mx-4">
                                    ¿Será supervisor?<span class="text-danger">*</span>
                                </h4>
                                <label>
                                    <input class="cursor-pointer abc-checkbox-primary" formControlName="isSupervisor" type="checkbox" />
                                    <span></span>
                                </label>
                            </span>
                        </div>
                        </div> -->
                        </div>
                        <div class="tab-pane fade" id="nav-e-entry-list" role="tabpanel"
                            aria-labelledby="nav-e-entry-list-tab">
                            <ng-template [ngIf]="form && form.get('employeeEntryList')">
                                <div class="row mb-3 border-bottom"
                                    *ngFor="let benefit of form.get('employeeEntryList')['controls']; let i=index">
                                    <div class="col-lg-2">
                                        <h6>{{entryList[i]?.description}}</h6>
                                    </div>
                                    <div class="col-lg-10" formArrayName="employeeEntryList">
                                        <div class="d-flex align-items-center flex-grow-1 mb-3 row" [formGroupName]="i">
                                            <div class="col-md-3">
                                                <div class="d-flex align-items-center flex-grow-1 mb-3">
                                                    <strong
                                                        class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                                                    <div class="flex-shrink-0 mx-2">
                                                        <span class="switch switch-sm switch-primary">
                                                            <label><input class="cursor-pointer abc-checkbox-primary"
                                                                    formControlName="selected"
                                                                    type="checkbox" /><span></span></label>
                                                        </span>
                                                    </div>
                                                    <strong
                                                        class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group d-flex flex-column">
                                                    <label>Evidencia</label>
                                                    <input class="form-control" type="file" id="formFile"
                                                    [disabled]="data?.inDetails"
                                                        (change)="manageFiles($event.target.files[0], i)">
                                                </div>

                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Observaciones</label>
                                                    <input class="form-control form-control-lg"
                                                    [readonly]="data?.inDetails"
                                                        formControlName="observations">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Asignado por</label>
                                                    <input class="form-control form-control-lg" readonly
                                                        [value]="authInfo?.getUserFullName()">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>


                        </div>

                    </div>
                </form>

            </div>
        </div>
    </div>
    <div class="mat-dialog-actions" align="end">
        <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2"
            type="button" (click)="close()">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
        <ng-container *ngIf="!data.inDetails">
            <button [disabled]="saving && form.invalid"
                class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
                (click)="submit()">
                <i class="flaticon-disco-flexible"></i>
                <span>Guardar</span>
            </button>
        </ng-container>
    </div>
</ng-container>
