<div *ngIf="loading" class="d-flex justify-content-center flex-column align-items-center spinner-container">
  <el-spinner animation="spinner-logo"></el-spinner>
  <span class="d-block font-size-h5 font-weight-bold">{{message}}</span>
</div>
<div class="card card-custom" data-card="true" id="kt_card_3">

  <div class="card-header mt-3">
    <div class="card-title">
      <div class="ttl d-flex align-items-center">
        <i class="flaticon-archivo icon-3x text-danger mr-2"></i>
        <h1 class="text-primary">Archivos de Nómina</h1>
      </div>
    </div>
  </div>
  <div class="card-header mt-3 row justify-content-between">
    <div class="col-sm-12 col-md-7 c-left">
      <div class="card-toolbar d-flex justify-content-start">
        <div class="col-md-3 px-0">
          <div *ngIf="privilege.Search.value" class="form-group mx-2 institution">
            <label class="text-primary">
              Período
            </label>
            <ngx-select-dropdown [options]='periods' [config]="configPeriod" [(ngModel)]="periodSelected"
              (change)="filterByPeriod()">
            </ngx-select-dropdown>
          </div>
        </div>

        <div class="col-md-3 px-0 pt-1">
          <div *ngIf="privilege.Search.value" class="form-group mx-2 institution">
            <label class="text-primary">
              Estatus
            </label>
            <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
              [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
              (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col-md-3 px-0">
          <div *ngIf="privilege.Search.value" class="form-group mx-2 institution">
            <label class="text-primary">
              Tipo de Nómina
            </label>
            <ngx-select-dropdown [options]='payrollTypes' [config]="configPayrollType" [(ngModel)]="payrollTypeSelected"
              (change)="filterByPeriod()">
            </ngx-select-dropdown>
          </div>
        </div>
        <div class="col-md-3 px-0">
          <div *ngIf="privilege.Search.value" class="form-group mx-2 institution">
            <label class="text-primary">
              Área Presupuestaria
            </label>
            <ngx-select-dropdown [options]='budgets' [config]="configBudget" [(ngModel)]="budgetSelected"
              (change)="filterByPeriod()">
            </ngx-select-dropdown>
          </div>
        </div>
      </div>
    </div>



    <div class="col-sm-12 col-md-5 c-right mr-0">
      <div class="card-toolbar d-flex justify-content-end">
        <div class="dropdown col-md-auto col-sm-12">
          <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
            *ngIf="privilege.export.value" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip" title="Exportar"
              data-original-title="Opciones de exportar"></i> Exportar
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

            <a class="dropdown-item align-items-center" (click)="exportToPdf()">
              <i class="fa fa-print icon-2x text-primary mr-3"></i>
              Visualizar Reporte
            </a>
            <!-- <a class="dropdown-item disabled" href="#">
                <i class="flaticon-word icon-2x text-primary mr-3"></i>
                Word
              </a>
              <a class="dropdown-item disabled" href="#">
                  <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                  CSV
              </a>
              <a class="dropdown-item" [routerLink]="['/summary']">
                  <i class="flaticon-pdf-file icon-2x text-primary mr-3"></i>
                  Plantilla
              </a> -->
          </div>
        </div>


        <div class="col text-nowrap">
          <el-search-bar *ngIf="this.privilege.Search.value" #searchBar [records]="records"
            (onKeyChange)="onKeyChange($event)" [keyValue]="filterText" [itemForSearching]="searchBarDisplayNames"
            (onChange)="getFilteredList($event)"></el-search-bar>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body" kt-hidden-height="282">
    <div class="table-responsive table-responsive-sigei">
      <table class="table table-vertical-center">
        <thead>
          <tr class="bg-primary">
            <th class="text-left">Secuencia</th>
            <th class="text-left">Periodo</th>
            <th class="text-left">Periodo Anterior</th>
            <th class="text-left">Tipo de Nómina</th>
            <th class="text-left">Área Presupuestaria</th>
            <th class="text-center">Estatus</th>
            <th class="actionsBtn text-center" style="width: 100px;">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of paginatedRecords">
            <td class="text-left">{{item.headerId}}</td>
            <td class="text-left">{{item.period}}</td>
            <td class="text-left">{{item.periodRelated || 'N/A'}}</td>
            <td class="text-left">{{item.payrollType}}</td>
            <td class="text-left">{{item.budgetArea}}</td>
            <td class="text-center">{{item.headerCondition}}</td>
            <td class="datatable-cell-left datatable-cell">
              <div class="d-flex justify-content-center  px-5  " style="width: max-content">
                <div class="justify-content-start  px-5">
                  
                  <a *ngIf="this.privilege.ViewStatus.value" class="btn btn-sm btn-light-skype btn-icon mr-2"
                    data-toggle="tooltip" title="Estatus SIGEI" (click)="openStatus(item)">
                    <i class="flaticon-ver icon-2x"></i>
                  </a>
                  <a *ngIf="this.privilege.ExpenseDetail.value" class="btn btn-sm btn-light-success btn-icon mr-2"
                    data-toggle="tooltip" title="Detalle del Gasto" (click)="openAddDetail(item, 'save')">
                    <i class="flaticon-agregar-archivo-1 icon-2x "></i>
                  </a>
                  <a *ngIf="this.privilege.Send.value" class="btn btn-light-warning btn-sm btn-icon mr-2"
                    data-toggle="tooltip" title="Enviar" (click)="openAddDetail(item, 'send')">
                    <i class="flaticon-enviar icon-2x "></i>
                  </a>
                  <a *ngIf="item.showConcludeBtn && this.privilege.Conclude.value"
                    class="btn btn-sm btn-light-success btn-icon mr-2" data-toggle="tooltip" title="Concluir nómina"
                    (click)="conclude(item)">
                    <i class="flaticon-etiqueta icon-2x "></i>
                  </a>
                </div>

              </div>

            </td>

          </tr>
        </tbody>
      </table>
      <div>

        <el-caption #paginator [originalRecordsLength]="records.length" [listedRecordsLength]="paginatedRecords.length"
          [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :records.length"
          [records]="filteredList.enable ? filteredList.records : records" (onChage)="getPaginatedRecords($event)">
        </el-caption>

      </div>
    </div>
  </div>
</div>

<export-data-to-pdf [title]="'Archivos de Nómina'" id="dataToPrint">
  <ng-container thead>
    <th class="text-left">Periodo</th>
    <th class="text-left">Tipo de Nómina</th>
    <th class="text-left">Área Presupuestaria</th>
    <th class="text-left">Estatus</th>
  </ng-container>
  <ng-container tbody>
    <tr *ngFor="let item of paginatedRecords">
      <td class="text-left">{{item.period}}</td>
      <td class="text-left">{{item.payrollType}}</td>
      <td class="text-left">{{item.budgetArea}}</td>
      <td class="text-left">{{item.headerCondition}}</td>
    </tr>
  </ng-container>
</export-data-to-pdf>