<!-- <div *ngIf="isLoading" class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
    <el-spinner animation="spinner-logo"></el-spinner>
    <h3 class="text-primary">Cargando Requisición...</h3>
</div> -->
<div class="container-fluid py-3">
    <button routerLink="/personal-requisition"
        class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
        <i class="flaticon2-left-arrow-1"></i>
        <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
    </button>
</div>
<div class="container-fluid" >
    <div id="pdfExp" class="card">
        <div class="card-header px-22">
            <div class="align-items-center d-flex justify-content-lg-between flex-md-row justify-content-sm-center"
                id="header">
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="card-body">
                            <div >
                                <div class="symbol symbol-150 d250 my-4 max-w-250px">
                                    <img alt="Pic" src={{file.logo}} alt="logo..."/>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="col-md-6">
                    <div class="title p-2 d-flex justify-content-center align-items-center flex-column">
                        <h1 class="ttlPDF text-primary text-center"> {{company?.socialReason}}</h1>
                        <h2 class="ttlPDF text-center"> Unidad Organizativa de Recursos Humanos </h2>
                        <h3 class="text-danger text-center">Formulario de Requisición de Personal</h3>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="version p-4 d-flex justify-content-between align-items-center flex-column">
                        <h4 class="text-right ">FO-AC-40</h4>
                        <h4 class="text-right ">Versión 0</h4>
                    </div>
                </div>


            </div>
        </div>
        <div class="card-body pdf-break-text">
            <div id="pdf">
                <div class="pdf-page pb-4">
                  <div class="container-fluid row mt-3">
            <div class="row gutter-t mx-2">
                <h3 class="col-md-12 p-2 bg-primary text-white text-center gutter-b">DATOS GENERALES</h3>
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 pdf-list">

                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Código Documental:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-5">{{requisition?.personalRequisitionId}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Puesto Solicitado:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-5">{{requisition?.positionName}}</span>
                        </div>
                    </div>

                        <div class="row">
                            <div class="col-12 col-sm-5 label-inline">
                                <label>Unidad Organizativa:</label>
                            </div>
                            <div class="col-12 col-sm-7 label-inline">
                                <span class="text-wrap ms-5">{{requisition?.department}}</span>
                            </div>
                        </div>

                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Fecha de Solicitud:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-5">{{requisition?.requestedDate | date: 'dd/MM/yyyy'}}</span>
                        </div>
                    </div>

                        <div class="row">
                            <div class="col-12 col-sm-5 label-inline">
                                <label>Fecha de Ingreso:</label>
                            </div>
                            <div class="col-12 col-sm-7 label-inline">
                                <span class="text-wrap ms-5">{{requisition?.applicationDate | date: 'dd/MM/yyyy'}}</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-sm-5  label-inline">
                                <label>Estatus:</label>
                            </div>
                            <div class="col-12 col-sm-7  label-inline">
                                <span class="text-wrap ms-5">{{requisition?.status ? "Activa" : "Inactiva"}}</span>
                            </div>
                        </div>
                    </div>
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 pdf-list">
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Cantidad de Plaza:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-5">{{requisition?.numberPlaces}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Causa de la Vacante:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-5">{{requisition?.vacancyCause}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label class="text-wrap" style="width: 140px;">Cantidad de Horas a Trabajar:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-5">{{requisition?.workingHours}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Tipo de Requisición:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-5">{{requisition?.requisitionType}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Método de Reclutamiento:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-5">{{requisition?.requisitionMethod}}</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="requisition?.condition == true">
                        <div class="col-12 col-sm-5 label-inline" >
                            <label>Fecha de creación:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-5"> {{!requisition?.requestedDate? 'Desconocida':
                                requisition?.requestedDate | date:'dd/MM/yyyy'}}</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="requisition?.condition == true">
                        <div class="col-12 col-sm-5 label-inline" >
                            <label>Fecha de aprobación:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-5"> {{!requisition?.approvationDate? 'Desconocida':
                                requisition?.approvationDate | date:'dd/MM/yyyy'}}</span>
                        </div>
                    </div>
                    <div class="row"  *ngIf="requisition?.status == false">
                        <div class="col-12 col-sm-5 label-inline">
                            <label>Razon de Inactivación:</label>
                        </div>
                        <div class="col-12 col-sm-7 label-inline">
                            <span class="text-wrap ms-5 " style="width: 100px;"> {{!requisition?.reasonToBeRemoved? 'Desconocida':
                                requisition?.reasonToBeRemoved}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 gutter-t gutter-b" *ngIf="requisition?.condition == true">
                    <h5 class="text-danger text-center">
                        Esta solicitud tendrá una vigencia de 3 meses, si pasado este tiempo no se ha nombrado ninguna
                        persona, se tendrá que hacer la solicitud nuevamente.
                    </h5>
                    <h5 class="text-center text-danger">
                        <strong>
                            Fecha de caducidad:
                            <span> {{!expirationDate? 'Desconocida':
                                expirationDate | date:'dd/MM/yyyy'}}</span>
                        </strong>

                    </h5>
                </div>
                <div class="col-md-6">
                    <div class="card card-dt">
                        <div class="card-header p-2">
                            <h3 class="text-uppercase m-0 text-primary font-weight-bolder text-center">En Sustitución de
                                {{replacements.length == 0 ? "(N/A)": ""}}
                            </h3>
                        </div>
                        <div class="card-body" *ngFor="let replacement of replacements">
                            <p class="fs-5">
                                {{replacement?.name}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card card-dt">
                        <div class="card-header p-2">
                            <h3 class="text-uppercase m-0 text-primary font-weight-bolder text-center">Si es creación,
                                debido a</h3>
                        </div>
                        <div class="card-body">
                            <p class="fs-5">
                                {{requisition?.creationCause}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gutter-t mx-2">
                <h3 class="col-md-12 p-2 bg-secondary text-uppercase text-white text-center d-block">
                    Observaciones
                </h3>
                <div class="min-h-200px fs-5 mt-3">
                    {{requisition?.observations}}
                </div>
            </div>
            <div class="row gutter-t mx-2">
                <h3 class="col-md-12 p-2 bg-primary text-uppercase text-white text-center text-center">
                    Requisitos Deseables
                </h3>
                <div class="col-md-6 pdf-list gutter-t">
                    <div class="label-inline">
                        <label>Sexo:</label>
                        <span>{{requisition?.sex}}</span>
                    </div>
                </div>
                <div class="col-md-6 pdf-list gutter-t">
                    <div class="label-inline">
                        <label>Rango de edad:</label>
                        <span>{{requisition?.minimumAge}}-{{requisition?.maximumAge}}</span>
                    </div>
                </div>

            </div>
            <div class="row gutter-t mx-2">
            </div>
            <div class="row gutter-t mx-2">
                <h3 class="col-md-12 p-2 bg-primary text-uppercase text-white text-center text-center">
                    <strong>Descripción General de las Funciones</strong> (Consultar con manual de puestos, según
                    aplique)
                </h3>
                <div class="min-h-200px fs-5 mt-3">
                    {{requisition?.generalDescription}}
                </div>
            </div>
            <div class="row gutter-t mx-2">
                <h3 class="col-md-12 p-2 bg-secondary text-uppercase text-white text-center text-center">
                    Solicitado por
                </h3>
                <div class="col-md-12 pdf-list gutter-b gutter-t">
                    <div class="label-inline">
                        <label>Servidor Público:</label>
                        <span> {{requisition?.supervisor}}</span>
                    </div>
                    <div class="label-inline">
                        <label>Cargo:</label>
                        <span> {{requisition?.supervisorPosition}}</span>
                    </div>
                    <div class="label-inline">
                        <label>Fecha:</label>
                        <span>{{requisition?.requestedDate | date: 'dd/MM/yyyy'}}</span>
                    </div>
                </div>
                <div class="col-md-12 gutter-b gutter-t">
                    <div class="firma text-center">
                        <span>_________________________________________</span>
                        <p class="font-weight-bold text-dark">
                            Firma
                        </p>
                    </div>
                </div>
            </div>
            <div class="row gutter-t mx-2">
                <h3 class="col-md-12 p-2 bg-primary text-uppercase text-white text-center text-center">
                    Para el uso de la Unidad Organizativa de RRHH
                </h3>
                <div class="col-md-12 pdf-list gutter-b gutter-t">
                    <div class="label-inline">
                        <label>Encargado:</label>
                        <span>{{rrhhDepartmentInfo?.supervisor}}</span>
                    </div>
                    <div class="label-inline">
                        <label>Observaciones:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Fecha de Recibo:</label>
                        <span>
                            <h5 class="text-primary">__-__-____</h5>
                        </span>
                    </div>
                </div>
                <div class="col-md-12 gutter-b gutter-t">
                    <div class="firma text-center">
                        <span>_________________________________________</span>
                        <p class="font-weight-bold text-dark">
                            Firma
                        </p>
                    </div>
                </div>
            </div>
            <div class="row gutter-t mx-2">
                <h3 class="col-md-12 p-2 bg-secondary text-uppercase text-white text-center text-center">
                    Máxima Autoridad Institucional
                </h3>
                <div class="col-md-12 pdf-list gutter-b gutter-t">
                    <div class="label-inline">
                        <label>Servidor Público: </label>
                        <span>{{departmentInfo?.supervisor}}</span>
                    </div>
                    <div class="label-inline">
                        <label>Cargo: </label>
                        <span>{{departmentInfo?.position}}</span>
                    </div>
                    <div class="label-inline">
                        <label>Fecha:</label>
                        <span>
                            <h5 class="text-primary">__-__-____</h5>
                        </span>
                    </div>


                </div>
                <div class="col-md-12 gutter-b gutter-t">
                    <div class="firma text-center">
                        <span>_________________________________________</span>
                        <p class="font-weight-bold text-dark">
                            Firma
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card card-dt">
                        <div class="card-header p-2">
                            <h3 class="text-uppercase m-0 text-primary font-weight-bolder text-center">Puesto ocupado
                                por</h3>
                        </div>
                        <div class="card-body"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card card-dt">
                        <div class="card-header p-2">
                            <h3 class="text-uppercase m-0 text-primary font-weight-bolder text-center">Fecha de Ingreso
                            </h3>
                        </div>
                        <div class="card-body"></div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            </div>
        </div>
        <div class="card-footer gutter-t">
            <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
                <img [src]="file.image1? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-80px">
                <img [src]="file.image2? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid py-3">
    <button routerLink="/personal-requisition"
        class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
        <i class="flaticon2-left-arrow-1"></i>
        <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
    </button>
</div>
<button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
    <i class="flaticon2-printer font-size-h1"></i>
</button>
<script>
    let print = () => {
        let pdf = document.getElementById("pdfExp");
        pdf.contentWindow.focus();
        pdf.contentWindow.print();
    };
</script>
