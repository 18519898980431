import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Period } from '@payroll/schedule/models/period.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PayrollReportService } from 'src/app/services/payroll-report.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-keys-configuration',
  templateUrl: './keys-configuration.component.html',
  styleUrls: ['./keys-configuration.component.css']
})
export class KeysConfigurationComponent implements OnInit {

  reportForm: FormGroup;
  yearsList: any[] = []
  filteredPeriods: Period[] = [];
  periods: Period[] = [];
  budgetAreaList: any[] = [];
  payrollVersions: any[] = [];
  reportUrl = environment.reportUrl
  areaSelected: any;
  yearSelected:number
  

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PAYROLL-REPORT',
    exportAction: 'EXPGEN',
    searchAction: 'SEARGEN'
  }


  constructor(
    private location: Location,
    private payrollService: PayrollService,
    private _toastService: ToastService,
    public authInfo: AuthInfoService,
    private payrollReportService: PayrollReportService,
    private fb: FormBuilder,
  ) {
    this.buildForm();
   }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.getPeriods();
      }
    });
  }

  goBack() {
    this.location.back();
  }

  buildForm() {
    this.reportForm = this.fb.group({
      area: [null, [Validators.required]],
    })
  }

  getPeriods() {
    this.payrollService.getBudgetAreas().subscribe((res: any) => {
      this.budgetAreaList = res.dataList.filter(ba => ba.status == true)

    })

    this.payrollReportService.getPeriods().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error("Ha ocurrido un error al obtener los períodos");
        return;
      }
      this.periods = res.dataList.filter(e => e.status);
      this.filteredPeriods = this.periods
      let periodYears: any[] = []
      let years = this.periods.map((period: any) => {
        let periodYear = new Date(`${period.startDate}`).getFullYear()
        if (!periodYears.includes(periodYear)) {
          periodYears.push(periodYear)
        }
        return (periodYears)
      })

      this.yearsList = years[0].reverse()
    }, err => this._toastService.error(err))
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  changeYear(event) {
    this.yearSelected = event.value
  }
  



  openReport() {

    if (this.reportForm.invalid) {
      this._toastService.warning('Por favor completar todos los campos requeridos', 'Campos incompletos')
      return
    }

    this.areaSelected = { ...this.reportForm.get('area')?.value };


    let period: number = this.yearSelected
    let areaId: number = this.areaSelected?.budgetAreaId;

    const reportUrl = `${this.reportUrl}/?ReportCode=rpt_BugetAreaKeyConfig&CompanyId=${this.authInfo.getCompanyId()}&PeriodId=${period}&BudgetAreaId=${areaId}&UserId=${this.authInfo.getUserId()}`
    let parameters = {
      url: reportUrl,
      title: 'Reporte de Nomina de Empleado con Conceptos',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }

}
