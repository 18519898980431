import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ProofDashboardService } from 'src/app/services/proof-dashboard.service';
import { IDashboardOptions } from 'src/app/services/vacancies-list-dashboard.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
import { BarOptions, ChartOptions } from 'src/app/shared/models/apexChartModels';
import { getPercentage } from 'src/app/shared/utils/utility';
import { IProofDashboard, PostulationTestByGenderQuantity, PostulationTestByScoreQuantity, PostulationTestByTypeQuantity } from '../models/DashboardData';
enum stats {
  female=1,
  male=2
}
@Component({
  selector: 'app-proof-dashboard',
  templateUrl: './proof-dashboard.component.html',
  styleUrls: ['./proof-dashboard.component.css']
})
export class ProofDashboardComponent implements OnInit {
  loadingMessage = 'Cargando...'
  noDataMessage = 'No hay datos'
  resultsChart = {
    series:[],
    labels: [],
    message: this.loadingMessage
  }
  typeTestsChart = {
    series:[],
    labels: [],
    message: this.loadingMessage
  }
  gendersChart = {
    series:[],
    labels: [],
    message: this.loadingMessage
  }
  suscriptions: Subscription = new Subscription()
  public chartOptions: Partial<ChartOptions>;
  public barOptions: Partial<BarOptions>;
  public chartOptions2: Partial<ChartOptions>;
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  amountMale: {total: number, percentage: number} = {
    total: 0,
    percentage: 0
  };
  amountFemale: {total: number, percentage: number} = {
    total: 0,
    percentage: 0
  };
  amountTotal : number = 0;
  filtered = false
  filterParams = {
    year: null,
    date2: this.datePipe.transform(new Date(),'yyyy-MM-dd'),
    date1: '0001-01-01',
    companyId: this.authInfo.getCompanyId()
  }

  constructor(
      public dialogRef: MatDialogRef<ProofDashboardComponent>,
      public dialog: MatDialog,
      private toastr: ToastrService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private srvDashboard: ProofDashboardService,
      private datePipe: DatePipe,
      private authInfo: AuthInfoService
    ) {


      
      
      this.loadCharts();

  }

  ngOnInit(): void {
    //this.getChart()
    try{
      this.filterParams.date1 = this.datePipe.transform(`${this.authInfo.getConstitutionDate()}`, 'yyyy-MM-dd')
      this.filterParams.date2 = this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd')
    }catch(e){

    }
    
    this.getDashboardData()
  }
  
  getDashboardData(){
    this.resultsChart.message = this.loadingMessage
    this.typeTestsChart.message = this.loadingMessage
    this.gendersChart.message = this.loadingMessage
    
    this.srvDashboard.getDashboardData( this.filterParams.date1, this.filterParams.date2, this.filterParams.companyId).subscribe((res: IProofDashboard) => {
      if(res.succeded){
        let d = res.singleData
        this.amountTotal = d.totaPostulationTestQuantities[0].value
        this.setScores(d.postulationTestByScoreQuantities)
        this.setTestTypes(d.postulationTestByTypeQuantities)
        this.setGender(d.postulationTestByGenderQuantities)
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error(this.errorMessage)
    })
  }
  setScores(data: PostulationTestByScoreQuantity[]){
    this.resultsChart.series = []
    this.resultsChart.labels = []
    if(data.length == 0){
      this.resultsChart.message = this.noDataMessage
    }
    data.forEach((element) => {
      this.resultsChart.series.push(element.value)
      this.resultsChart.labels.push(element.description)
    })
  }
  setTestTypes(data: PostulationTestByTypeQuantity[]){
    this.typeTestsChart.series = []
    this.typeTestsChart.labels = []
    if(data.length == 0){
      this.typeTestsChart.message = this.noDataMessage
    }
    data.forEach((element) => {
      this.typeTestsChart.labels.push(element.description)
      this.typeTestsChart.series.push(element.value)
    })
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(FilterDashboardComponent, {
      data: {filterParams: this.filterParams},
    });
    let sub = dialogRef.afterClosed().subscribe((result: {year,date1,date2, applyFilter?: boolean, companyId:any}) => {
      if(!result) return
      if(result?.applyFilter == false){
        return;
      }
      this.filtered = result.year != null || result.date1 != null || result.date2 != null;

      this.filterParams.year = result.year
      this.filterParams.date1 = result.date1
      this.filterParams.date2 = result.date2
      this.filterParams.companyId = result.companyId
      this.getDashboardData()
      //console.log(result);
    });
    this.suscriptions.add(sub)
  }
  clear(){
    this.filterParams = {
      date1: this.datePipe.transform(`01-01-${new Date().getFullYear()}`, 'yyyy-MM-dd'),
      date2: this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd'),
      year: null,
      companyId: this.authInfo.getCompanyId()
    }
    this.getDashboardData()
  }

  setGender(data: PostulationTestByGenderQuantity[]){
    this.gendersChart.series = [];
    this.gendersChart.labels = []
    if(data.length == 0){
      this.gendersChart.message = this.noDataMessage
    }
    data = data.filter(x => x.description != null)
    data.forEach((element) => {
      this.gendersChart.labels.push(element.description)
      this.gendersChart.series.push(element.value)
    })
  }

  getPercentage(total: number, count: number){
    return getPercentage(total, count)
  }
  getOptions(): IDashboardOptions{
    return {
      dateFrom: this.filterParams.date1? this.filterParams.date1 : null,
      dateTo: this.filterParams.date2? this.filterParams.date2: null,
      companyId: this.authInfo.getCompanyId()
    }
  }

  loadCharts(){
    this.chartOptions = {
      series: [],
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#ffffff', '#ffffff', '#ffffff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      stroke: {
        width: 0
      },
      title: {
        text: ""
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      labels: [], 
      legend: {
        show: true,
        position: 'bottom',
        },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }
      ]
    };
    this.chartOptions2 = {
      series: [5,45],
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
            fontSize: '14px',
            fontWeight: 'bold',
            colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      stroke: {
        width: 0
      },
      title: {
        text: ""
      },
      labels: ["Femenino", "Masculino"],
      fill: {
        colors: ["#f11565", "#0481af"]
      },
      colors: ["#f11565", "#0481af"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
          }
        }
      ]
    };
    this.barOptions = {
      series: [
        {
          name: "Mujeres",
          data: [44, 55, 41, 37, 22, 43, 21]
        },
        {
          name: "Hombres",
          data: [53, 32, 33, 52, 13, 43, 32],
        },


      ],
      chart: {
        type: "bar",
        width: 400,
        height: 300,
        stacked: true
      },
      colors: ["#fb7866", "#6489fb"],
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      title: {
        text: ""
      },
      xaxis: {
        labels: {
          show: false
        },

      },
      yaxis: {
        title: {
          text: undefined
        },
        labels: {
          style: {
            colors: ['#033877'],
            fontSize: '15px',
            fontWeight: "bolder",
            cssClass: 'apexcharts-yaxis-label',
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "K";
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40
      }
    }
  }

}
