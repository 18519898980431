<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon mr-5">
                <i class="flaticon-contratacion icon-3x text-danger"></i>
            </span>
            <h1 class="d-flex flex-column">
                Prueba de Postulantes
                <small>Resultados de Pruebas</small>
            </h1>
        </div>
        <div class="card-toolbar">
            <button routerLink="/proof-applicants-list" class="btn btn-pill font-weight-bolder btn-shadow btn-warning">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
            </button>
        </div>
    </div>
    <div class="card-body">
        <form [formGroup]="form">
            <div class="row gutter-b">
                <div [ngClass]="{'col-md-12': personSelected?.condition != 0, 'col-md-9': personSelected?.condition == 0}">
                    <div class="bg-light-info rounded d-flex h-100 align-items-center row">
                        <div class="text-center col-12 col-sm-4 left">
                          <div class="symbol symbol-150 d250 my-4 max-w-250px">
                            <img *ngIf="!this.personSelected?.photo" alt="Pic" src="./assets/media/no-user.png"/>
                            <img *ngIf="this.personSelected?.photo" alt="Pic" [src]="this.personSelected?.photoloaded"/>
                          </div>
                        </div>
                        <div class="col-12 col-sm-7 d-flex justify-content-center  flex-column">
                          <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Nombre: <span class="text-dark ml-3">{{personSelected?.personName || ""}}</span></h3>
                          <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Identificación:
                            <span class="text-dark ml-3" *ngIf="personSelected?.typeIdentificationId == 1 || personSelected?.typeIdentificationId == 4">{{personSelected?.personalIdentification | mask:'000-0000000-0' || ""}}</span>
                            <span class="text-dark ml-3" *ngIf="personSelected?.typeIdentificationId != 1 && personSelected?.typeIdentificationId != 4">{{personSelected?.personalIdentification || ""}}</span>
                          </h3>
                          <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Teléfono: <span class="text-dark ml-3">{{personSelected?.phoneNumber | mask:'(000) 000-0000' || ""}}</span></h3>
                          <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary text-wrap">Correo Electrónico: <span class="text-dark ml-3">{{personSelected?.email || ""}}</span></h3>
                          <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Nivel Académico: <span class="text-dark ml-3">{{personSelected?.academicLevel || ""}}</span></h3>
                        </div>
                    </div>
                </div>
                <div class="col-md-3" *ngIf="personSelected?.condition == 0">
                    <div class="form-group m-0">
                        <label class="d-block gutter-t m-3">Evidencia  <span class="text-danger">*</span></label>
                        <uppy-file #uppyFile
                            cardBody="''"
                            [showDocumentoAfterUploaded]="false"
                            previewDefaultImage="assets/images/ver_file.jpg"
                            classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px"
                            [allowedFileTypes]="['application/pdf', 'image/*']">
                        </uppy-file>
                    </div>
                    <!-- <div class="form-group m-0">
                        <label class="d-block gutter-t m-3">Evidencia  <span class="text-danger">*</span></label>
                        <div class="p-1">
                            <div [ngClass]="{'showUpload': file.document=='', 'NotShowUpload' : file.document!=''}">
                                <div class="evidencia"></div>
                            </div>
                            <div *ngIf="file.document !=''">
                                <div class="symbol symbol-150 d250 my-4 max-w-250px">
                                    <img alt="Pic" src="assets/images/ver_file.jpg" />
                                </div>
                                <div class="btn-group center" role="group">
                                    <button type="button" class="btn btn-primary btn-icon" data-toggle="modal"
                                        data-target="#document">
                                        <i class="flaticon-ver"></i>
                                    </button>
                                    <button type="button" class="btn btn-danger btn-icon" (click)="deleteGuid()">
                                        <i class="flaticon-delete-1"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <span
                        *ngIf="!uppyFileComponent?.existFile && invalidForm"
                        class="text-danger">Debe subir una evidencia</span>
                </div>
            </div>
            <div *ngIf="personSelected?.condition == 0" class="row">
                <div class="form-group col-lg-4">
                    <label>Tipo de Prueba Aplicada <span class="text-danger">*</span></label>
                    <ngx-select-dropdown class=" flex-1" formControlName='typeTest' [options]='testTypes' [config]='getDropdownConfig("stringData","Seleccionar")'></ngx-select-dropdown>
                    <!-- <select formControlName="typeTest" class="form-control form-control-solid form-control-lg">
                        <option value="">Seleccionar</option>
                        <option *ngFor="let type of testTypes" value="{{type.ocode}}">{{
                            type.stringData }}</option>
                    </select> -->
                    <span style="position: absolute;"
                        *ngIf="this.form.get('typeTest').invalid && invalidForm"
                        class="text-danger">{{ showTypeTestErrors() }}</span>
                </div>
                <div class="form-group col-lg-4">
                    <label>Fecha de Aplicación <span class="text-danger">*</span></label>
                    <input formControlName="cdate" type="date" name=""
                        class="form-control form-control-solid form-control-lg">
                    <span style="position: absolute;"
                        *ngIf="this.form.get('cdate').invalid && invalidForm"
                        class="text-danger">{{ showCdateErrors() }}</span>
                </div>
                <div class="form-group col-lg-4">
                    <label>Puntuación / Resultados <span class="text-danger">*</span></label>
                    <ngx-select-dropdown class=" flex-1" formControlName='score' [options]='testResults' [config]='getDropdownConfig("stringData","Seleccionar")'></ngx-select-dropdown>
                    <span style="position: absolute;" *ngIf="this.form.get('score').invalid && invalidForm"
                        class="text-danger">{{ showScoreErrors() }}</span>
                </div>
            </div>
            <div *ngIf="personSelected?.condition == 0" class="row">
                <div class="form-group col-lg-12 d-flex flex-column">
                    <label>Comentario del Evaluador <span class="text-danger">*</span></label>
                    <textarea formControlName="evaluatorComment"
                        class="form-control form-control-solid form-control-lg flex-1"></textarea>
                    <span
                        *ngIf="this.form.get('evaluatorComment').invalid && invalidForm"
                        class="text-danger">{{ showEvaluatorCommentErrors() }}</span>
                </div>
            </div>
            <div *ngIf="personSelected?.condition == 0" class="d-flex justify-content-end">
                <button (click)="resetForm()" [disabled]="!this.personSelected"
                    class="btn btn-pill font-weight-bolder btn-shadow btn-light-dark mr-3">
                    <i class="fa fa-eraser"></i>Limpiar
                </button>
                <button [disabled]="personSelected?.ppvid < 1" (click)="savePostulation()"
                    class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
                    <i class="flaticon-disco-flexible"></i> Guardar
                </button>
            </div>
        </form>
        <div class="separator separator-solid separator-primary gutter-b gutter-t"></div>
        <div class="row gutter-t gutter-b">
            <div class="col-md-12">
                <div class="card card-custom border-primary">
                    <div class="card-header mt-3">
                        <div class="card-title align-items-center">
                            <i class="flaticon2-time icon-2x text-primary mr-3"></i>
                            <h2 class="text-primary m-0">Historial de Pruebas Aplicadas

                            </h2>
                        </div>
                        <div class="card-toolbar ml-auto"></div>
                    </div>
                    <div class="card-body p-0">
                        <div class="table-responsive">
                            <table class="table mt-2 table-vertical-center text-center">
                                <thead>
                                    <tr class="bg-primary">
                                        <th class="max-w-50px">Secuencia</th>
                                        <th>Prueba Aplicada</th>
                                        <th class="max-w-200px">Fecha Aplicación</th>
                                        <th>Puntuación / Resultado</th>
                                        <th>Evidencia</th>
                                        <th>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of records; let index = index">
                                        <td>{{data.postulationTestId}}</td>
                                        <td>{{data.typeTest}}</td>
                                        <td>{{data.postulationDate | date:'dd/MM/yyyy' }}</td>
                                        <td>{{data.score}}</td>
                                        <td>
                                            <a *ngIf="data.documentsId" (click)='openGuID(data.documentsId)' data-toggle="tooltip" title="Evidencia"
                                                class="btn btn-light-youtube font-weight-bold mb-3">
                                                <i class="flaticon-pdf-file icon-2x"></i>
                                            </a>
                                            <p *ngIf="!data.documentsId">N/A</p>
                                        </td>
                                        <td>

                                            <a (click)="openTestResultsReport(data)" class="btn btn-sm btn-light-skype btn-icon mr-2">
                                              <i class="flaticon-ver icon-2x"></i>
                                            </a>

                                            <button tooltip="Eliminar" placement="top" *ngIf="personSelected?.condition == 0"
                                                class="btn btn-sm btn-light-danger btn-icon" (click)="deleteTest(data)">
                                                <i class="flaticon-delete"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="ps-3" >
                                <el-caption #paginator [originalRecordsLength]="recordsOriginal.length" [listedRecordsLength]="records.length" [registerQuantity]="testHistoryData.length" [records]="testHistoryData"
                                (onChage)="getPaginatedRecords($event)"></el-caption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>