import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, AfterViewChecked, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateRegService } from 'src/app/services/http-client/candidateRegService.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { FileResponse, parameterControlModel } from 'src/app/shared/models/candidate-registration.model';
import { country } from 'src/app/shared/models/country.model';
import { territory } from 'src/app/shared/models/territory.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { environment } from 'src/environments/environment';
import { parameterDoc } from 'src/app/shared/utils/parameterDoc';
import { docService } from 'src/app/services/doc.service';
import { JobOfferService } from 'src/app/services/job-offer.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import Swal from 'sweetalert2';
import { VacanciesService } from 'src/app/services/vacancies.service';
import { PositionService } from 'src/app/services/position.service';
import { Account, AccountCertificationEnum, BankAccountCertification, RequestInfoBankAccountCertification } from 'src/app/shared/models/bankAccountCertification.model';
import { MatDialog } from '@angular/material/dialog';
import { WizardBenefitsModalComponent } from './wizard-benefits-modal/wizard-benefits-modal.component';
import { SearchEmployeeComponent } from '@payroll/action-edit/search-employee/search-employee.component';
import { IEmployee } from 'src/app/pages/job-offer/models/Employee';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { IEmployeeHasMoreThanOnePosition } from 'src/app/models/Employee.model';
import { openReport } from 'src/app/shared/utils/utility';
import { InstitutionRelativesComponent } from './institution-relatives/institution-relatives/institution-relatives.component';
import { RecomendationsComponent } from './recomendations/recomendations/recomendations.component';
import * as moment from 'moment';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

declare const KTWizard1: any;
type GUID = string & { isGuid: true };

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalInformationComponent implements OnInit, AfterViewInit, AfterViewChecked {
  moreThanOnePosition = { yes: true, no: false };
  relindex = -1;
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'REGPER',
    changeCodeAction: 'EditEmployeeNumber'
  }
  @Output() changeButton: EventEmitter<boolean> = new EventEmitter();
  @Output() changeLoadData: EventEmitter<any> = new EventEmitter();

  employeeBankAccountHistory: any;
  employeeNumberCopy;
  bankAccountCopy: string;
  entityBankIdCopy: number;
  accountTypeIdCopy: number;
  reportUrl = environment.reportUrl

  // @ViewChild(CandidateRegistrationComponent) candidateReg;
  public SystemLoad: number = 1;
  public ModuleLoad: number = 1;
  branch
  public routeFile: string = "";
  public parameterDoc = parameterDoc.wizard;
  @Input() type: number;
  public file = { guid: "", formData: null }
  public loadingPic = true;
  fieldsErrors = {
    idType: { message: '', dirty: false },
    id: { message: '', dirty: false },
    birth: { message: '', dirty: false },
    age: { message: '', dirty: false },
    nationality: { message: '', dirty: false },
    marital: { message: '', dirty: false },
    country: { message: '', dirty: false }
  }
  selectedSupervisor: IEmployee = null;
  supervisorName: string = '';
  departmentSupervisor: string = '';
  employeeSupervisor: string;
  employeeSupervisorId: number;
  public employee = {
    employeeSupervisor: '',
    employeeSupervisorId: 0,
    supervisorId: 0,
    employeeId: 0,
    employNumber: '',
    name: '',
    positionId: 0,
    departmentId: 0,
    typeEmployeeId: 0,
    employeeType: '',
    branchId: 1,
    entityBankId: 0,
    accountTypeId: 0,
    bankingEntity: '',
    bankAccount: '',
    personId: 0,
    isSupervisor: false,
    departmentId1: 0,
    departmentName: '',
    positionName: '',
    afpid: 0,
    afp: '',
    arsid: 0,
    ars: '',
    supervisor: '',
    salary: '',
    typeIdentificationId: 0,
    typeIdentification: '',
    phoneNumber: '',
    institionalEmail: '',
    admissionDate: null,
    budgetAreaId: 0,
    statusBankAccountCertification: null,
    bankAccountCertId: null,
    moreThanOnePosition: false,
    hiredDate: null
  };

  public birthMunicipalities: Array<any> = [];
  public provinces: Array<any> = [];
  public municipalities: Array<any> = [];
  public birthCities: Array<any> = [];
  public countries: Array<any> = [];
  public sectors: Array<any> = [];
  public budgetAreas: any[] = [];
  public budgetArea: any[] = [];
  public identification = 0;
  public idPerson = 0;
  public id = -1
  public personPicture = ""

  showBirthdayError = false;
  public uploadedProfilePhoto: any;
  public typeIdList = []
  public arsList = []
  public afpList = []
  public bankList = []
  public accountTypes = []
  public maritalList = []
  public disabilityList = []
  public bloodTypeList = []
  public recomend = false;
  public relOption = 0;
  public familyId = 0;
  idRequiredLength = 11
  public relName = '';
  public relationshipsList = []
  public relationshipsAdded = []
  public relationshipAdding = true
  public recomendations = []
  public recomendationsData = { id: 0, name: '', tel: '', institutions: '' }
  public mask = "000-0000000-0"
  public maskText = "Ej: 001-000000-2"
  public candidateData = [];
  public sexList = [];
  branches = []
  public afp;
  public ars;
  public bank;
  public accountType;
  deletebtn = false;
  personalPhoto = false;
  oldNameRelationShipAdded: any = "";
  age: any; // Campo de edad
  disablePersonalIdentification = true; // Agregado para habilitar o deshabilitar el campo de numero de identificación
  inputLastNameError = null;
  inputFirstNameError = null;
  inputStreetError = null;
  inputTypeIdentifactionError = null;
  public personalInfo = {
    personId: 0,
    firstName: "",
    lastName: "",
    academicLevelId: 0,
    birthday: null,
    personalIdentification: "",
    sexId: 1,
    typeIdentification: {} as parameterControlModel,
    typeIdentificationId: 0,
    nationality: {} as country,
    country: {} as country,
    province: {} as territory,
    municipality: {} as territory,
    sector: {} as territory,
    nationalityId: 0,
    countryId: 0,
    provinceId: 0,
    municipalityId: 0,
    sectorId: 0,
    maritalStatusId: 0,
    disabilityTypeId: 0,
    bloodTypeId: 0,
    spouseTypeIdentificationId: "",
    maritalStatus: {} as parameterControlModel,
    disability: {} as parameterControlModel,
    bloodType: {} as parameterControlModel,
    arsId: 0,
    ars: '',
    street: null,
    numberhouse: 0,
    buildingName: "",
    apartmentNumber: "",
    personalReview: "",
    achievements: "",
    strengths: "",
    pointsImprove: "",
    recommendation: false,
    namePersonRecommended: "",
    phonePersonRecommended: "",
    institutionPersonRecommended: "",
    spouseName: "",
    spouseIdentification: "",
    spousePhone: "",
    spousePhoto: 0,
    userName: "",
    pasword: "",
    companyId: this.authInfo.getCompanyId(),
    status: true,
    afpId: 0,
    afp: '',
    familyPersonInstitutionEntities: [],
    personRecomendation: [],
    profileImage: '',
    moreThanOnePosition: null
  }

  isBankAccountCertificate = { yes: 1, no: 2 }

  statusBankAccountCertification;

  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionality defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Click para desplegar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  public config = {
    displayKey: 'nationality', // if objects array passed which key to be displayed defaults to description
    search: true,
    placeholder: 'Seleccionar',
    searchPlaceholder: "Buscar",
    noResultsFound: 'No hay data para mostrar'
  };

  public configCountry = {
    displayKey: 'names', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };

  public configDDL = {
    displayKey: 'stringData', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };

  public configMarital = {
    displayKey: 'stringData', // if objects array passed which key to be displayed defaults to description
    search: false,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };
  public configDisability = {
    displayKey: 'stringData', // if objects array passed which key to be displayed defaults to description
    search: false,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };
  public configBloodType = {
    displayKey: 'stringData', // if objects array passed which key to be displayed defaults to description
    search: false,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };

  public configAfpArs = {
    displayKey: 'stringData', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  }
  public companyDropdownConfig = {
    displayKey: 'companyName', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar',
    searchOnKey: 'companyName'
  }

  public configBudgetAreas = {
    displayKey: 'description', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };



  public sweetAlert = new SweetAlert();

  benefits: any[] = [];
  benefitsOriginalList: any[] = [];

  employeeBenefit: any[] = [];
  benefitsSeleted: any[] = [];

  public employeeId = 0;
  minimunAgeRequired = 18
  @ViewChild('btnCloseBenefit') btnCloseBenefit: ElementRef;

  @ViewChild('uppyFileFotoPerfil')
  uppyFile: UppyFileComponent | null = null;
  constructor(private parameterService: ParameterControlService, private srvCandidateService: CandidateRegService, private dialog: MatDialog,
    private toast: ToastService, private vacanciesService: VacanciesService,
    private srvPerson: PersonService, private route: ActivatedRoute,
    private srvDoc: docService, private jobOfferService: JobOfferService, private cdr: ChangeDetectorRef,
    private authInfo: AuthInfoService,
    private srvPosition: PositionService,) {
    this.idPerson = Number(this.route.snapshot.params["id"] || 0)
    this.employeeId = Number(this.route.snapshot.params["employeeId"] || 0)
  }

  ngAfterViewChecked(): void {
    //this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.getEmployeeBankAccountHistory()
    this.personalInfo.country = null;
    this.personalInfo.typeIdentificationId = null;
    this.personalInfo.typeIdentification = null;
    this.personalInfo.province = null;
    this.personalInfo.municipality = null;
    this.personalInfo.sector = null;
    this.personalInfo.maritalStatus = null
    this.personalInfo.disability = null
    this.personalInfo.bloodType = null
    this.personalInfo.nationality = null
    this.provinces = []
    this.municipalities = [];
    this.sectors = []
    this.loadData();
    this.getVacantBenefits();
    this.getMinimumAge()
  }

  getMinimumAge() {
    this.parameterService.getParameters(keyWord.MinimunAgeRequired).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) { this.toast.error(res.errors[0]); return }
        if (res.warnings.length > 0) { this.toast.warning(res.warnings[0]); return }
        if (res.dataList?.length) {
          const { numericData } = res.dataList[0]
          if (numericData) {
            this.minimunAgeRequired = numericData
          }
        }

      }, error: (error: any) => {

      }
    })
  }
  getEmployeeBenefit() {
    this.srvPerson.getEmployeeBenefit(this.employee?.employeeId).subscribe((res: any) => {
      if (res?.errors?.length > 0) {
        this.toast.error(res?.errors[0]);
        return;
      }
      this.employeeBenefit = res.dataList;
      this.changeDetector();
    }, error => {
      this.toast.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  ChangeBudgetArea(value) {
    this.employee.budgetAreaId = value?.budgetAreaId;
    this.ValitingForm()
  }

  changeDetector() {
    this.cdr.detectChanges();
  }

  get builkBankAccountCertification(): BankAccountCertification {
    const account: Account = {
      tipoDocumento: this.personalInfo?.typeIdentification?.ccode.toString(),
      documento: this.personalInfo?.personalIdentification,
      banco: this.bank?.ccode,
      tipoCuenta: this.accountType?.ccode,
      cuenta: this.employee?.bankAccount,
      nombreBeneficiario: this.employee?.name,
      moneda: AccountCertificationEnum?.Currency
    }
    const requestInfo: RequestInfoBankAccountCertification = {
      origen: AccountCertificationEnum?.Source,
      cuentas: [account]
    }
    const bankAccountCertification: BankAccountCertification = {
      requestInfo: requestInfo,
      employeeId: this.employee?.employeeId,
      companyId: this.authInfo?.getCompanyId(),
      userId: this.authInfo?.getUserId(),
    }
    return bankAccountCertification;
  }

  bankAccountCertificationStatus() {
    this.jobOfferService.bankAccountCertificationStatus({
      employeeId: this.employee?.employeeId,
      companyId: this.authInfo?.getCompanyId(),
      userId: this.authInfo?.getUserId(),
    }).subscribe({
      next: res => {
        if (res?.errors?.length > 0) {
          this.toast.error(res?.errors[0], '');
          return;
        }
        if (res?.warnings?.length > 0) {
          Swal.fire(
            res?.warnings[0],
            '',
            'warning'
          );
          return;
        }
        if (res?.singleData?.resultado?.includes('R00')) {
          Swal.fire(
            'La cuenta ha sido certificada',
            '',
            'success'
          );
        } else {
          Swal.fire(
            'La cuenta aun no ha sido certificada',
            '',
            'warning'
          );
        }
        this.getEmployeeData(this.employeeId);
      }, error: res => {
        this.toast.error('Ocurrió un error tratando de obtener el estatus de la cuenta, Consulte al soporte técnico del sistema', '');
      }
    })
  }

  getEmployeeBankAccountHistory() {
    this.srvPerson.getEmployeeBankAccountHistory(this.employeeId).subscribe({
      next: (resp: any) => {
        this.employeeBankAccountHistory = resp.dataList
      }
    })
  }

  getEmployeeData(employeeId) {
    this.srvPerson.getEmployeeDataByEmployeeId(employeeId).subscribe((res) => {

      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toast.error(err);
        })

        res.warnings.forEach(warn => {
          this.toast.warning(warn)
        });
        return;
      }


      this.employee = res.dataList[0] ? res.dataList[0] : this.employee;
      this.employee.moreThanOnePosition = this.employee.moreThanOnePosition == null ? false : this.employee.moreThanOnePosition;
      this.employee.hiredDate = this.employee.hiredDate ? moment(this.employee.hiredDate).format('yyyy-MM-DD') : null
      this.getEmployeeBenefit();
      this.getBudgetAreas();
      this.employeeNumberCopy = this.employee?.employNumber;
      this.bankAccountCopy = this.employee?.bankAccount;
      this.entityBankIdCopy = this.employee?.entityBankId;
      this.accountTypeIdCopy = this.employee?.accountTypeId;
      this.supervisorName = this.employee.employeeSupervisor
      this.departmentSupervisor = this.employee.supervisor
      this.ValitingForm()
      this.statusBankAccountCertification = this.employee?.statusBankAccountCertification ? this.isBankAccountCertificate.yes
        : this.isBankAccountCertificate.no;
      if (this.employee?.afpid == 0) {
        this.afp = null;
      } else {
        this.getAfp(this.employee?.afpid);
      }
      this.getBranches()

      if (this.employee?.arsid == 0) {
        this.ars = null;
      } else {
        this.getArs(this.employee?.arsid);
      }
      this.getAccountTypes();
      if (this.employee?.admissionDate) {
        var adate = this.employee.admissionDate

        let anio = {
          y: adate.split('-')[0],
          m: adate.split('-')[1],
          d: adate.split('-')[2].substring(0, 2),
        }

        this.employee.admissionDate = anio.y + '-' + anio.m + '-' + anio.d

        if (this.bankList.length == 0) {
          this.srvCandidateService.getParameters(keyWord.BankingEntity).subscribe(e => {
            if (e.errors.length > 0) {
              this.toast.error(e.errors[0])
              return
            }
            this.bankList = e.dataList;
            this.bank = this.bankList?.find(b => b.ocode === this.employee?.entityBankId);

            this.changeDetector();
          })
        } else {
          this.bank = this.bankList?.find(b => b.ocode === this.employee?.entityBankId);
          this.changeDetector();
        }
      }

    }, error => {
      this.toast.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  getAfp(afpid) {
    if (this.afpList.length == 0) {
      this.srvCandidateService.getParameters(keyWord.AFP).subscribe(e => {
        if (e.errors.length > 0) {
          this.toast.error(e.errors[0])
          return
        }

        this.afpList = e.dataList;
        this.afp = this.afpList?.filter(f => f?.ocode == afpid);
        this.personalInfo.afpId = afpid;
      })
    } else {
      this.afp = this.afpList?.filter(f => f?.ocode == afpid);
      this.personalInfo.afpId = afpid;
    }
  }


  getArs(arsid) {
    if (this.arsList.length == 0) {
      this.srvCandidateService.getParameters(keyWord.ARS).subscribe(e => {
        if (e.errors.length > 0) {
          this.toast.error(e.errors[0])
          return
        }

        this.arsList = e.dataList;
        this.ars = {
          ocode: arsid,
          stringData: this.arsList.filter(f => f.ocode == arsid)[0]?.stringData
        }
        if (this.employee?.arsid) {
          this.employee.arsid = arsid
        }
        this.personalInfo.arsId = arsid;
      })
    } else {
      this.ars = {
        ocode: arsid,
        stringData: this.arsList.filter(f => f.ocode == arsid)[0]?.stringData
      }
      if (this.employee?.arsid) {
        this.employee.arsid = arsid
      }
      this.personalInfo.arsId = arsid;
    }
  }


  loadData() {
    if (this.idPerson > 0) {
      this.srvPerson.getPersonWizard(this.idPerson).subscribe(res => {
        if (res.errors.length > 0) {
          this.toast.error(res.errors[0])
          return
        }

        try {
          if (res.succeded == true && this.idPerson > 0) {
            var data = res.singleResultData;
            this.personalInfo = data.person;
            if (this.personalInfo.nationalityId == 0) {
              this.personalInfo.nationality = null
            }

            this.getEmployeeData(this.employeeId);
            if (this.personalInfo.typeIdentificationId == 1) {
              this.mask = "000-0000000-0"
              this.maskText = "Ej: 001-000000-2"
              this.idRequiredLength = 11
            } else {
              this.idRequiredLength = 60
              this.mask = ""
              this.maskText = ""
            }

            if (this.personalInfo.birthday) {
              let birthday = this.personalInfo.birthday
              let anio = {
                y: birthday.split('-')[0],
                m: birthday.split('-')[1],
                d: birthday.split('-')[2].substring(0, 2),
              }
              this.personalInfo.birthday = anio.y + '-' + anio.m + '-' + anio.d
              this.age = this.calculateAge();
            }
            this.identification = this.personalInfo.typeIdentificationId;

            this.personalInfo.familyPersonInstitutionEntities = []
            this.personalInfo.familyPersonInstitutionEntities = data.familyPersonInInstitutions

            if (data.personRecomendation) {
              for (let i = 0; i < data.personRecomendation.length; i++) {
                const item = data.personRecomendation[i];
                let m = {
                  id: item.personRecomendationId,
                  name: item.personName,
                  tel: item.phone,
                  institutions: item.institution
                }
                this.recomendations.push(m)
              }
            }

            this.loadDDL();
            //this.C+andidateReg.loadData(data)
            this.changeLoadData.emit(data);

            this.ValitingForm()

            if (this.personalInfo.profileImage != null && this.personalInfo.profileImage != '') {
              this.file.guid = this.personalInfo.profileImage;
            }

          }
          this.changeDetector();
        } catch (error) {
          this.toast.error(error)
        }
      }, error => {
        this.toast.error(error)
      })
    } else {
      this.loadDDL();
    }
  }

  getBudgetAreas() {
    this.jobOfferService.getBudgetAreas().subscribe((data: any) => {
      if (data.errors.length > 0) {
        this.toast.error(data.errors[0])
        return
      }
      this.budgetAreas = data.dataList.filter(d => d.status);
      this.budgetArea = { ...this.budgetAreas?.find(b => b?.budgetAreaId === this.employee?.budgetAreaId) };
      this.changeDetector();
    },
      (error) => {
      }
    );
  }

  ngAfterViewInit(): void {

  }

  isDominican(nationality): boolean {

    const dominicanDictionary = [
      "Dominicano",
      "Dominicana",
      "Republica Dominicana",
      "República Dominicana",
    ]
    return dominicanDictionary.find(x => nationality?.toLowerCase().includes(x.toLowerCase()))
      ? true : false;
  }

  nationalityChange(event) {
    if (!this.personalInfo.nationality.names) {
      this.fieldsErrors.nationality.message = 'Este campo es requerido'
    } else {
      this.fieldsErrors.nationality.message = ''
    }
    if (event.value == null || event.value.length == 0) {
      this.ValitingForm();
      return;
    }
    this.personalInfo.nationality = event.value
    this.personalInfo.nationalityId = event.value.idCountry
    this.ValitingForm()
  }

  maritalChange($event) {
    if (!this.personalInfo.maritalStatus.stringData) {
      this.fieldsErrors.marital.message = 'Este campo es requerido'
    } else {
      this.fieldsErrors.marital.message = ''
    }
    this.personalInfo.maritalStatusId = $event.value.ocode
    this.ValitingForm()
  }
  disabilityChange($event) {
    this.personalInfo.disabilityTypeId = $event.value.ocode
  }
  bloodTypeChange($event) {
    this.personalInfo.bloodTypeId = $event.value.ocode
  }

  countryChange($event) {
    if (!this.personalInfo.country.names) {
      this.fieldsErrors.country.message = 'Este campo es requerido'
    } else {
      this.fieldsErrors.country.message = ''
    }
    try {
      this.provinces = []
      this.municipalities = []
      this.sectors = []

      this.personalInfo.country = null
      this.personalInfo.province = null
      this.personalInfo.municipality = null
      this.personalInfo.sector = null

      this.personalInfo.provinceId = 0;
      this.personalInfo.municipalityId = 0;
      this.personalInfo.sectorId = 0;

      this.personalInfo.street = null;
      this.personalInfo.numberhouse = null;

      if ($event.value == null || $event.value <= 0) {
        this.ValitingForm()
        return;
      }

      this.personalInfo.country = $event.value
      this.ValitingForm()

      this.loadProvinces(this.personalInfo.country.idCountry);
    } catch (error) {
      this.toast.error(error)
    }
  }

  provinceChange($event) {
    try {
      this.municipalities = []
      this.personalInfo.municipality = null
      this.personalInfo.sector = null

      this.personalInfo.municipalityId = 0;
      this.personalInfo.sectorId = 0;

      if ($event.value == null || $event.value <= 0) {
        this.ValitingForm()
        return;
      }

      this.personalInfo.province = $event.value
      this.ValitingForm()
      this.loadMunicipality($event.value.stateId)
    } catch (error) {
      this.toast.error(error)
    }
  }

  loadProvinces(idCountry: number = 214) {
    //cargamos las provincias
    this.srvCandidateService.getCity(idCountry).subscribe(e => {
      if (e.errors.length > 0) {
        this.toast.error(e.errors[0])
        return
      }
      this.provinces = e.dataList.filter(x => x.status = true)
      this.changeDetector();
      //seteamos la provincia
      if (this.personalInfo.provinceId > 0) {
        let m = this.personalInfo;

        for (let i = 0; i < this.provinces.length; i++) {
          const item = this.provinces[i];

          if (item.stateId == m.provinceId) {
            this.personalInfo.province = item
            this.loadMunicipality(m.province.stateId);
            return
          }
        }
      }

    }, error => {
    })
  }

  municipalityChange($event) {
    try {
      this.sectors = []
      this.personalInfo.municipality = null
      this.personalInfo.sector = null
      this.personalInfo.sectorId = 0;

      if ($event.value == null || $event.value <= 0) {
        this.ValitingForm()
        return;
      }

      this.personalInfo.municipality = $event.value
      this.ValitingForm()
      this.loadSector($event.value.cityId)
    } catch (error) {
      this.toast.error(error)
    }
  }

  loadMunicipality(idProvince: number) {
    //load municipios
    this.srvCandidateService.getDistrit(idProvince).subscribe(e => {
      if (e.errors.length > 0) {
        this.toast.error(e.errors[0])
        return
      }
      this.municipalities = e.dataList.filter(x => x.status = true)
      this.changeDetector();
      //seteamos los municipios
      if (this.personalInfo.municipalityId > 0) {
        let m = this.personalInfo;

        for (let i = 0; i < this.municipalities.length; i++) {
          const item = this.municipalities[i];

          if (item.cityId == m.municipalityId) {
            this.personalInfo.municipality = item
            this.loadSector(m.municipalityId);
            return
          }
        }
      }
    }, error => {
    })
  }

  loadSector(city: number) {
    //load sectors
    this.srvCandidateService.getSectors(city).subscribe(e => {
      if (e.errors.length > 0) {
        this.toast.error(e.errors[0])
        return
      }

      this.sectors = e.dataList.filter(x => x.status = true)
      this.changeDetector();
      //seteamos los municipios
      if (this.personalInfo.sectorId > 0) {
        let m = this.personalInfo;

        for (let i = 0; i < this.sectors.length; i++) {
          const item = this.sectors[i];

          if (item.sectorId == m.sectorId) {
            this.personalInfo.sector = item
            this.ValitingForm()
            return
          }
        }
      }
    }, error => {
    })
  }

  sectorChange($event) {
    try {
      if ($event.value == null || $event.value <= 0) {
        this.personalInfo.sector = null
        this.ValitingForm()
        return;
      }

      var e = $event.value

      e.idSector = e.territoryId

      this.personalInfo.sector = e
      this.ValitingForm()
    } catch (error) {
      this.toast.error(error)
    }
  }

  typeIdChange(e) {
    const idType = this.personalInfo.typeIdentification
    if (!idType?.stringData) {
      this.fieldsErrors.idType.message = 'Este campo es requerido'
    } else {
      this.fieldsErrors.idType.message = ''
    }
    if (e.value.ocode == 1 || e.value.ocode == 4) {
      this.idRequiredLength = 11
      this.mask = "000-0000000-0"
      this.maskText = 'Ej: 001-000000-2'
    } else {
      this.idRequiredLength = 60
      this.mask = ''
      this.maskText = ''
    }
    this.personalInfo.personalIdentification = '';
    this.identificationIsInvalid()
    this.ValitingForm();
  }

  arsAfpChange(data, type) {
    if (type == 1) {
      this.personalInfo.afpId = data.value.ocode
    } else {
      this.personalInfo.arsId = data.value.ocode
    }
  }
  changeCompany() {
    const companyId = this.branch?.companyId
    if (!companyId) {
      this.employee.branchId = null
      return
    }
    this.employee.branchId = this.branch.companyId

  }

  bankChange(data) {
    this.employee.entityBankId = data.value.ocode
  }

  accountTypeChange(data) {
    this.employee.accountTypeId = data?.value?.ocode
  }

  getBranches() {
    this.srvPosition.getCompanies().subscribe({
      next: (res: any) => {
        this.branches = res.dataList
        this.branch = this.branches.find(x => x.companyId == this.employee.branchId)

      },
      error: err => this.toast.error(err)
    })
  }

  getAccountTypes() {
    this.srvCandidateService.getParameters(keyWord.AccountType).subscribe(e => {
      if (e.errors.length > 0) {
        this.toast.error(e.errors[0])
        return
      }
      this.accountTypes = e?.dataList;
      this.accountType = this.accountTypes?.find(a => a?.ocode === this.employee?.accountTypeId);
      this.changeDetector();
    });
  }

  loadDDL() {
    //cargamos las ars y las afp
    if (this.afpList.length == 0) {
      this.srvCandidateService.getParameters(keyWord.AFP).subscribe(e => {
        if (e.errors.length > 0) {
          this.toast.error(e.errors[0])
          return
        }

        this.afpList = e.dataList;
      })
    }

    if (this.arsList.length == 0) {
      this.srvCandidateService.getParameters(keyWord.ARS).subscribe(e => {
        if (e.errors.length > 0) {
          this.toast.error(e.errors[0])
          return
        }

        this.arsList = e.dataList;
      })
    }

    //cargamos los banco
    if (this.bankList.length == 0) {
      this.srvCandidateService.getParameters(keyWord.BankingEntity).subscribe(e => {
        if (e.errors.length > 0) {
          this.toast.error(e.errors[0])
          return
        }

        this.bankList = e.dataList;
      })
    }

    //carga los tipos de identificacion
    this.srvCandidateService.getParameters(keyWord.IDType).subscribe(e => {
      if (e.errors.length > 0) {
        this.toast.error(e.errors[0])
        return
      }

      this.typeIdList = e.dataList
      this.changeDetector();
      if (this.identification >= 0) {
        for (let i = 0; i < this.typeIdList.length; i++) {
          const item = this.typeIdList[i];

          if (item.ocode == this.identification) {
            this.personalInfo.typeIdentification = item
            return
          }
        }
      }
    }, error => {
    })

    //cargamos el estado civil
    this.srvCandidateService.getParameters(keyWord.MaritalStatus).subscribe(e => {
      if (e.errors.length > 0) {
        this.toast.error(e.errors[0])
        return
      }
      this.maritalList = e.dataList.filter(x => x.status = true)
      this.changeDetector();
      if (this.personalInfo.maritalStatusId >= 0) {
        let m = this.personalInfo;

        for (let i = 0; i < this.maritalList.length; i++) {
          const item = this.maritalList[i];

          if (item.ocode == m.maritalStatusId) {
            this.personalInfo.maritalStatus = item;
            return
          }
        }
      }
    }, error => {
    })


    //cargamos los tipos de discapacidades
    this.srvCandidateService.getParameters(keyWord.DisabilityType).subscribe(e => {
      if (e.errors.length > 0) {
        this.toast.error(e.errors[0])
        return
      }
      this.disabilityList = e.dataList.filter(x => x.status = true)
      this.changeDetector();
      if (this.personalInfo.disabilityTypeId >= 0) {
        let m = this.personalInfo;

        for (let i = 0; i < this.disabilityList.length; i++) {
          const item = this.disabilityList[i];

          if (item.ocode == m.disabilityTypeId) {
            this.personalInfo.disability = item;
            return
          }
        }
      }
    }, error => {
    })

    //cargamos los tipos de sangre
    this.srvCandidateService.getParameters(keyWord.BloodType).subscribe(e => {
      if (e.errors.length > 0) {
        this.toast.error(e.errors[0])
        return
      }
      this.bloodTypeList = e.dataList.filter(x => x.status = true)
      this.changeDetector();
      if (this.personalInfo.bloodTypeId >= 0) {
        let m = this.personalInfo;

        for (let i = 0; i < this.bloodTypeList.length; i++) {
          const item = this.bloodTypeList[i];

          if (item.ocode == m.bloodTypeId) {
            this.personalInfo.bloodType = item;
            return
          }
        }
      }
    }, error => {
    })

    //load contries
    this.srvCandidateService.getCountry().subscribe(e => {
      if (e.errors.length > 0) {
        this.toast.error(e.errors[0])
        return
      }
      this.countries = e.dataList.filter(x => x.status == true)
      this.changeDetector();
      //seteamos la nacionalidad
      if (this.personalInfo.nationalityId > 0) {
        let m = this.personalInfo;

        for (let i = 0; i < this.countries.length; i++) {
          const item = this.countries[i];

          if (item.idCountry == m.nationalityId) {
            this.personalInfo.nationality = item;
            break
          }
        }
      }

      //seteamos el pais
      if (this.personalInfo.countryId > 0) {
        let m = this.personalInfo;

        for (let i = 0; i < this.countries.length; i++) {
          const item = this.countries[i];

          if (item.idCountry == m.countryId) {
            this.personalInfo.country = item;
            this.loadProvinces(m.countryId);
            return
          }
        }
      }
    }, error => {
    })

    //load cities
    this.srvCandidateService.getParameters(keyWord.RelationShip).subscribe(e => {
      if (e.errors.length > 0) {
        this.toast.error(e.errors[0])
        return
      }
      for (let i = 0; i < e.dataList.length; i++) {
        const item = e.dataList[i];
        this.relationshipsList.push({ id: item.ocode, description: item.stringData })
      }

      if (this.personalInfo.familyPersonInstitutionEntities.length > 0) {
        let personInst = this.personalInfo.familyPersonInstitutionEntities;

        for (let i = 0; i < personInst.length; i++) {
          const item = personInst[i];
          const add = {
            id: item.fpiiid,
            fpiiid: item.fpiiid,
            personName: item.personName,
            relationshipId: item.relationshipId,
          }
          this.relationshipsAdded.push(add)
        }
      }
      this.changeDetector();
    }, error => {
    })


    //cargar los generos
    this.srvCandidateService.getParameters(keyWord.Gender).subscribe(e => {
      if (e.errors.length > 0) {
        this.toast.error(e.errors[0])
        return
      }
      for (let i = 0; i < e.dataList.length; i++) {
        const item = e.dataList[i];
        if (item.ocode == 1 || item.ocode == 2) {/* Se agregó esta condición para que solo se muestren los dos sexos especificos */
          this.sexList.push(
            {
              stringData: item.stringData,
              ocode: item.ocode,
              ccode: item.ccode
            })
        }
      }
      this.changeDetector();

    })
  }

  addRelationship() {
    if (this.relName != '' && !this.checkPattern(this.relName)) {
      this.toast.warning("No se permiten caracteres especiales", "Aviso")
      return;
    }
    if (this.relationshipAdding == true) {
      if (this.relOption == 1) {
        if (this.fatherExists()) {
          this.toast.warning('Ya agregó este parentesco')
          return
        }
      }
      if (this.relOption == 2) {
        if (this.motherExists()) {
          this.toast.warning('Ya agregó este parentesco')
          return
        }
      }
      this.relationshipsAdded.push({ fpiiid: 0, relationshipId: this.relOption, personName: this.relName })
      document.getElementById('modelClose').click();
      this.relindex = -1
    } else {
      this.updateRow()
    }
  }

  updateRow() {
    let relationId = this.relOption
    let name = this.relName
    let rowIndex = this.relindex

    if (relationId == 2 && this.relationshipsAdded[rowIndex].relationshipId != 2) {
      if (this.motherExists()) {
        this.toast.warning('Ya agregó este parentesco')
        return
      }
    }
    if (relationId == 1 && this.relationshipsAdded[rowIndex].relationshipId != 1) {
      if (this.fatherExists()) {
        this.toast.warning('Ya agregó este parentesco')
        return
      }
    }

    if (rowIndex > -1) {
      this.relationshipsAdded[rowIndex].relationshipId = relationId
      this.relationshipsAdded[rowIndex].personName = name
      document.getElementById('modelClose').click();
      this.relindex = -1
    }
  }

  motherExists() {
    const exists = this.relationshipsAdded.find(x => x.relationshipId == 2)
    if (exists) {
      return true
    }
    return false
  }
  fatherExists() {
    const exists = this.relationshipsAdded.find(x => x.relationshipId == 1)
    if (exists) {
      return true
    }
    return false
  }


  addRecomendations(recomendationData) {

    if (this.id == -1) {
      this.recomendations.push(recomendationData)
      recomendationData = { id: 0, name: '', tel: '', institutions: '' }
    } else {
      this.recomendations[this.id] = recomendationData
      recomendationData = { id: 0, name: '', tel: '', institutions: '' }
    }

    this.id = -1
    document.getElementById('btnCloseRecomendations').click()
  }

  editRecomendations(i, recomendationInfo) {
    this.id = i
    const { name, tel, institutions } = recomendationInfo
    this.recomendations[i].name = name
    this.recomendations[i].tel = tel
    this.recomendations[i].institutions = institutions
  }

  removeRecomendations(i) {
    let id = this.recomendations[i].id;

    if (id <= 0 || id == undefined) {
      this.recomendations.splice(i, 1)
      return
    }

    this.swalQuestion(() => {
      this.srvPerson.deleteRecomendations(id).subscribe(e => {
        this.recomendations.splice(i, 1)
        this.toast.success('Registro borrado')
        this.changeDetector();
      }, error => {
        this.toast.error(error)
      })
    })

  }

  swalQuestion(fn: () => void) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      title: "¿Está seguro que desea eliminar este registro?",
      icon: "question"
    }).then(res => {
      if (res.isConfirmed) {
        fn();
      }
    });
  }

  getParentDescription(id) {
    return this.relationshipsList.filter(e => e.id == id)[0]?.description
  }

  relationShipRemove(relationshipId, i) {
    let model = {
      personId: relationshipId,
      userId: this.authInfo.getUserId(),
      operation: 2
    }

    const data = this.relationshipsAdded[i];

    if (data?.fpiiid === 0) {
      this.relationshipsAdded.splice(i, 1)
      return
    }

    this.swalQuestion(() => {
      this.srvPerson.deleteReletionship(model).subscribe(e => {
        if (!e.succeded) {
          this.toast.error(e.errors[0]);
          return;
        }
        this.relationshipsAdded.splice(i, 1)
        this.changeDetector();
        this.toast.success('Registro borrado')
      }, error => {
        this.toast.error(error)
      })
    })
  }

  calculateAge() {
    let dateSelected = new Date(this.personalInfo.birthday + 'T00:00:00')
    const year = dateSelected.getFullYear()
    const month = dateSelected.getMonth() + 1
    const day = dateSelected.getDate()
    const date2 = new Date();
    let age = date2.getFullYear() - year
    let currentMonth = date2.getMonth() + 1
    if (currentMonth < month) {
      age -= 1;
      return age
    }
    if (currentMonth != month) {
      return age
    }
    if (date2.getDate() < day) {
      age -= 1
      return age
    }
    return age
  }

  validateBirthdayInput() {
    if (!this.personalInfo.birthday) {
      this.fieldsErrors.birth.message = 'Este campo es requerido'
    } else {
      this.fieldsErrors.birth.message = ''
    }
    this.showBirthdayError = (this.calculateAge() < this.minimunAgeRequired) ? true : false;

    let time1 = new Date(this.personalInfo.birthday);
    let time2 = new Date();
    if (time1.getTime() > time2.getTime()) {
      this.showBirthdayError = true;
    }


    const ages = this.calculateAge();
    if (isNaN(ages)) {
      this.age = ''
      this.fieldsErrors.age.message = 'Este campo es requerido'
    } else {
      this.age = ages
      this.fieldsErrors.age.message = ''
    }

  }

  getMaxBirthdayDate() {
    let d = new Date();
    return d.toLocaleDateString('fr-CA');
  }

  validatingLastNameInput() {
    if (this.personalInfo.lastName.length == 0) {
      this.inputLastNameError = "Este campo es requerido"
    } else if (this.personalInfo.lastName.length > 35) {
      this.inputLastNameError = "No se permite más de 35 carácteres"
    } else if (!this.checkPattern(this.personalInfo.lastName)) {
      this.inputLastNameError = "Carácteres inválidos"
    } else {
      this.inputLastNameError = null;
    }
  }
  validatingStreetInput() {
    if (this.personalInfo.street.length == 0) {
      this.inputStreetError = "Este campo es requerido"
    } else {
      this.inputStreetError = null;
    }
  }

  validatingTypeIdentificationInput() {
    if (!this.personalInfo.typeIdentification) {
      this.inputTypeIdentifactionError = "Este campo es requerido"
    } else {
      this.inputTypeIdentifactionError = null;
    }
  }

  validatingFirstNameInput() {

    if (this.personalInfo.firstName.length == 0) {
      this.inputFirstNameError = "Este campo es requerido"
    } else if (this.personalInfo.firstName.length > 35) {
      this.inputFirstNameError = "No se permite más de 35 carácteres"
    } else if (!this.checkPattern(this.personalInfo.firstName)) {
      this.inputFirstNameError = "Carácteres inválidos"
    } else {
      this.inputFirstNameError = null;
    }
  }
  formIsInvalid() {
    if (this.inputLastNameError || this.inputFirstNameError) {
      return true;
    } else {
      return false;
    }
  }
  setSex(m) {
    this.sexList[0].ccode = 'F'
  }
  openBenefits() {
    this.dialog.open(WizardBenefitsModalComponent, {
      data: {
        benefits: this.benefitsOriginalList,
        benefitsSelected: this.employeeBenefit,
        employee: this.employee
      }
    }).afterClosed().subscribe({
      next: (result: any) => {

        if (!result) return
        this.getEmployeeBenefit()
      }
    })
  }

  ValidateID() {
    this.fieldsErrors.id.dirty = true
    if (!this.personalInfo.personalIdentification) {
      this.fieldsErrors.id.message = 'Este campo es requerido'
    } else {
      this.fieldsErrors.id.message = ''
    }
  }

  employeeDataIsInvalid(): boolean {
    if (this.type == 1 || this.type == 2) {
    }
    return false
  }

  inputId() {
    this.identificationIsInvalid()
  }

  identificationIsInvalid() {

    const personalIdentification = this.personalInfo.personalIdentification
    const typeIdentification = this.personalInfo.typeIdentification?.ocode
    if (typeIdentification == undefined) {
      this.changeButton.emit(false)
      return true
    }
    else {
      if (typeIdentification == 1 || typeIdentification == 4) {
        if (personalIdentification.length !== 11) {
          this.changeButton.emit(false)
          return true
        } else if (personalIdentification.length === 11) {
          this.changeButton.emit(true)
          return false
        }
      } else {
        if (personalIdentification.length > 60) {
          this.changeButton.emit(false)
          return true
        }
      }
    }

    return false
  }
  ValitingForm() {
    let m = this.personalInfo;

    if (this.type == 1) {
      if (!this.employee.employeeSupervisorId) {
        this.changeButton.emit(false)
        return
      }
    }
    if (this.identificationIsInvalid()) {
      this.changeButton.emit(false)
      return
    }

    if (this.employeeDataIsInvalid()) {
      this.changeButton.emit(false);
      return
    }
    if (m.typeIdentificationId < 0) {
      this.changeButton.emit(false);
      return;
    }

    if (Array.isArray(m.nationality)) {
      if (m.nationality.length == 0) {
        this.changeButton.emit(false);
        return;
      }
    }

    if (!m.maritalStatusId) {
      this.changeButton.emit(false);
      return;
    }

    if (this.formIsInvalid()) {
      this.changeButton.emit(false);
      return;
    }

    if (m.typeIdentificationId < 0) {
      this.changeButton.emit(false);
      return;
    }

    if (this.calculateAge() < 16 || this.calculateAge() < 0) {
      this.changeButton.emit(false);
      return;
    }

    this.showBirthdayError = false;
    if (m.birthday == '' || m.birthday == null) {
      this.changeButton.emit(false);
      return;
    }

    if (m.personalIdentification == '' || m.firstName?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') == '' || m.lastName?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') == '' || m.sexId <= 0) {
      this.changeButton.emit(false);
      return;
    }

    if (this.idPerson == 0) {
      if (m.nationality == undefined || m.nationality.idCountry <= 0) {
        this.changeButton.emit(false);
        return;
      }

      if (m.country?.idCountry === undefined) {

        this.changeButton.emit(false);
        return;
      } else if (m.country.idCountry == 214) {
        if ((m.province && m.province.stateId <= 0) || !m.province) {
          this.changeButton.emit(false);
          return;
        }

        if ((m.municipality && !m.municipality?.cityId) || !m.municipality?.cityId) {
          this.changeButton.emit(false);
          return;
        }

        if (!m.sector || m.sector == null || !m.sector?.sectorId) {
          this.changeButton.emit(false);
          return;
        }

        if (!m.sector.sectorId || m.sector.sectorId <= 0) {
          this.changeButton.emit(false);
          return;
        }

        if (!m.street?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')) {
          this.changeButton.emit(false);
          return;
        }
        let street = m.street.toString()
        if (street.length < 1 || street.length == '') {
          this.changeButton.emit(false);
          return;
        }

      }
      else {
        this.changeButton.emit(true)
        return
      }
    } else {


      if (m.nationalityId <= 0 || !m.country?.idCountry) {
        this.changeButton.emit(false);
        return;
      }


      if (m.countryId == 214 && m.country?.idCountry === 214) {
        if (m.province === null) {
          this.changeButton.emit(false);
          return;
        }
        if (!m.municipality?.cityId) {
          this.changeButton.emit(false);
          return;
        }
        if (!m.sector?.sectorId) {
          this.changeButton.emit(false);
          return;
        }
        if (!m.street?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')) {
          this.changeButton.emit(false);
          return;
        }
      }
      else {
        this.changeButton.emit(true)
        return
      }
    }

    if (this.employee?.employeeId > 0) {
      if (this.employee?.bankAccount?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')?.length === 0) {
        this.changeButton.emit(false);
        return;
      }
      if (!this.employee?.budgetAreaId) {
        this.changeButton.emit(false);
        return;
      }
    }
    this.changeButton.emit(true);
  }

  clearRecomendation(e) {
    if (e == false) {
      this.personalInfo.namePersonRecommended = '';
      this.personalInfo.phonePersonRecommended = '';
      this.personalInfo.institutionPersonRecommended = ''
      this.personalInfo.recommendation = false
    } else {
      this.personalInfo.recommendation = true
    }
  }

  ddlGetModelsIdentify(item) {
    return {
      booleanData: null,
      ccode: item.ccode,
      companyCode: 1,
      dateData: null,
      description: null,
      doubleData: null,
      keyWord: "IDType",
      numericData: null,
      ocode: item.ocode,
      stringData: item.stringData
    }
  }

  ddlGetModels(item) {
    return {
      alfanumeric: item.alfanumeric,
      alfanumeric3: item.alfanumeric3,
      idCountry: item.idCountry,
      printId: item.idPrint,
      names: item.names,
      nationality: item.nationality,
    }
  }

  checkPattern(data) {

    // Allow A-Z, a-z, 0-9 and underscore. Min 1 char.
    var re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

    return re.test(data);
  }

  deletePhoto() {
    if (this.personalInfo.profileImage != null && this.personalInfo.profileImage != '')
      this.srvDoc.deleteFile(this.personalInfo.profileImage).subscribe(e => {
        this.personalInfo.profileImage = ''
        this.file.guid = ''
        this.loadingPic = true;
        this.deletebtn = false;
        if (this.personalInfo.profileImage == '') {
          this.personalInfo.profileImage = null
          this.cdr.detectChanges();
        }
        this.srvPerson.putPerson(this.personalInfo).subscribe(e => {
          this.toast.success('Foto borrada exitosamente.')
          this.cdr.detectChanges();
        }, error => {
        })
      }, error => {
        this.toast.error(error);
      })
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  validateEmail() {
    var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    if (this.employee.employeeId > 0) {
      if (!this.employee?.institionalEmail?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')) {
        this.changeButton.emit(true);
        this.ValitingForm();
        return ""
      };

      if (!this.employee?.institionalEmail?.match(validRegex)) {
        this.changeButton.emit(false);
        return "El correo no es válido";
      }

      this.changeButton.emit(true);
      this.ValitingForm();
      return "";
    } else {
      this.changeButton.emit(true);
      return "";
    }

  }

  benefitSelectConfig = {
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar',
    moreText: 'más'
  }

  addBenefits() {
    if (this.benefitsSeleted.length === 0) return;
    this.benefitsSeleted?.forEach(benefit => {
      if (this.employeeBenefit.find(eb => eb.benefitId == benefit.ocode)) {
        return
      }

      this.employeeBenefit.push({
        ecbid: 0,
        employeeId: this.employee.employeeId,
        benefitId: benefit.ocode,
        companyId: this.authInfo.getCompanyId(),
        benefit: benefit.stringData,
        status: true,
      });
    });
    this.benefitsSeleted = [];
    this.btnCloseBenefit.nativeElement.click();
    this.changeDetector();
  }
  openModalSearchEmployee() {
    this.dialog.open(SearchEmployeeComponent, {
      data: {
        hideInputTeacher: true
      },
      width: '80%'
    })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return
        }

        if (result?.data) {
          this.selectedSupervisor = result?.data
          this.supervisorName = this.selectedSupervisor.name
          this.employee.employeeSupervisorId = result?.data?.employeeId
          this.changeDetector()
          /* const employee = { ...result.data };

          this.form.patchValue({
            employeeSupervidor: employee?.name,
            supervisorId: employee?.employeeId
          }); */
        }
      });
  }

  openReport() {
    const reportUrl = `${this.reportUrl}/?ReportCode=rrhh_positions&CompanyId=${this.authInfo.getCompanyId()}&PositionId=${this.employee?.positionId}`
    let parameters = {
      url: reportUrl,
      title: 'Reporte Descripción de Cargo',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }
  deleteBenefit(ecbid: number, i: number) {
    if (ecbid <= 0 || ecbid == undefined) {
      this.employeeBenefit.splice(i, 1);
      this.changeDetector();
      return;
    }

    this.swalQuestion(() => {
      this.srvPerson.deleteEmployeeBenefit(ecbid).subscribe((res: any) => {
        if (res?.errors?.length > 0) {
          this.toast.error(res?.errors[0]);
          return;
        }
        this.toast.success('Registro borrado');
        this.getEmployeeBenefit();
      }, error => {
        this.toast.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
      })
    })

  }

  getVacantBenefits() {
    this.vacanciesService.getVacantsTypeBenefit().subscribe(res => {
      if (res.succeded) {
        this.benefits = res.dataList;
        this.benefitsOriginalList = res.dataList;
      } else {
        this.toast.error(res.errors[0]);
      }
    }, error => {
      this.toast.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.');
    })
  }

  get getBenefitsDropdown() {
    let benefits = [...this.benefitsOriginalList.filter(b => {
      if (this.employeeBenefit.some(e => e.benefitId === b.ocode)) {
        return;
      }
      return b;
    })];
    return benefits;
  }

  validMrelationship() {
    return this.relName?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') == '' || this.relOption <= 0;
  }

  validRecomendations() {
    return this.recomendationsData?.name?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') == '' || this.recomendationsData?.institutions?.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') == '';
  }

  changeStatusBankAccountCert(isBankAccountCertificate: number) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      cancelButtonText: 'No',
      confirmButtonText: 'Sí',
      title: "¿Está seguro que desea cambiar el estatus de la cuenta bancaria?",
      icon: "question"
    }).then(res => {
      if (res.isConfirmed) {
        this.jobOfferService.changeStatusBankAccountCertification({
          employeeId: this.employee?.employeeId,
          statusBankAccountCertification: this.isBankAccountCertificate.yes === isBankAccountCertificate,
          companyId: this.authInfo.getCompanyId(),
          userId: this.authInfo.getUserId()
        }).subscribe({
          next: (res: ResponseModel<null>) => {
            if (res?.errors?.length > 0) {
              this.toast.error(res?.errors[0]);
              return;
            }
            this.toast.success('El estatus de la cuenta bancaria se cambió correctamente');
            this.getEmployeeData(this.employee?.employeeId);
          }, error: res => {
            this.toast.error('Ha ocurrido un error tratando de cambiar el estatus de la cuenta bancaria del servidor público');
          }
        });
      } else {
        this.statusBankAccountCertification = this.isBankAccountCertificate.yes === isBankAccountCertificate
          ? this.isBankAccountCertificate.no : this.isBankAccountCertificate.yes;
      }
    });

  }

  changePositionQuantityCondition(morethanOnePosition: boolean) {

    const model: IEmployeeHasMoreThanOnePosition = {
      employeeId: this.employee?.employeeId,
      moreThanOnePosition: morethanOnePosition,
      userId: this.authInfo.getUserId(),
    }

    this.jobOfferService.changePositionsQuantityCondition(model).subscribe({
      next: (res) => {

        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toast.error(err);
          })

          res.warnings.forEach(warn => {
            this.toast.warning(warn)
          });
          return;
        }

        this.toast.success('La cantidad de cargos que puede poseer el servidor publico fue actualizada correctamente');
      }, error: res => {
        this.toast.error('Ha ocurrido un error tratando de actulizar la cantidad de cargos que pueder poseer el servidor publico');
      }
    })

  }

  openRelativesModal(isEditing: boolean = false, relativeInfo: any = null, index?) {
    this.relationshipAdding = !isEditing

    if (isEditing) {
      this.relindex = index
    }

    this.dialog.open(InstitutionRelativesComponent, {
      data: {
        relationShips: this.relationshipsList,
        relativeInfo,
        isEditing
      },
      width:'30%'
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          this.relName = res.relName
          this.relOption = res.relationship?.id
          
          this.addRelationship()
        }
      },
      error: (err: any) => {

      }
    })
  }

  openRecomendationsModal(isEditing: boolean = false, recomendationInfo: any = null, index?: number) {
    this.recomendationsData = { id: 0, name: '', tel: '', institutions: '' }
    this.dialog.open(RecomendationsComponent, {
      data: {
        recomendationInfo,
        isEditing
      },
      width: '30%'
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          const { name, phoneNumber, institution } = res
          let recomendationData = { name, tel: phoneNumber, institutions: institution }
          if (!isEditing) {
            this.addRecomendations(recomendationData)
            return
          }
          this.editRecomendations(index, recomendationData)
          this.changeDetector();
        }
      },
      error: (err: any) => {

      }
    })
  }

  uploadFile(formData: FormData) {
    let data = {
      SystemLoad: this.uppyFile.SystemLoad,
      ModuleLoad: this.uppyFile.ModuleLoad,
      personalFile: this.uppyFile.typePersonalFile,
      routeFile: this.uppyFile.routeFile
    }
    return this.srvDoc.createFile(data, formData).toPromise();
  }

  existFileToDelete(){
    return this.uppyFile.fileToDelete?.length > 0;
  }

  deleteFile(){
    return this.srvDoc.deleteFile(this.uppyFile.fileToDelete).toPromise();
  }

  setImage(event){
    this.file.formData = event;
  }

}
