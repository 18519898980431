<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="fs-3 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px "></span> {{titleModal}}
    </h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <div class="row">
            <div>
                <div class="text-center mb-3" *ngIf="loanding">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Validando firma....
                </div>
                <ng-container *ngIf="geMessageStatus && !loanding">
                    <div class="alert alert-success" role="alert" *ngIf="statusRequest === 'COMPLETED'">
                        <p class="fs-5">{{geMessageStatus}}</p>
                    </div>
                    <div class="alert info-custom" role="alert" *ngIf="statusRequest !== 'COMPLETED'">
                        <p class="fs-5">{{geMessageStatus}}</p>
                    </div>
                </ng-container>
                <div id="pdfContent" style="display: none;">
                    <!--div class="bg-primary">
                        <h3 class="col-md-12 p-2 bg-primary text-white text-center mt-3 d-block">
                            Evidencia de Firma
                        </h3>
                    </div-->
                    <div class="row" id="pdfSHow">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button *ngIf="(this.publicAccessId && statusRequest !== 'COMPLETED' && statusRequest !== 'REJECTED') && !loanding"
    [disabled]="loanding"
        class="btn btn-pill font-weight-bolder btn-shadow btn-warning btn-warning mr-2" type="button"
        (click)="ValidateSign()">
        <i class="flaticon-checked"></i>
        <span>Validar Firma</span>
    </button>
    <button *ngIf="(statusRequest === 'COMPLETED' || statusRequest === 'REJECTED') && !loanding" (click)="save()"
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>