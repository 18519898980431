import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { personalRequiredFilesService } from 'src/app/services/personalRequiredFiles.service';
import { personsAppliedStudies } from 'src/app/shared/models/candidate-registration.model';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { environment } from 'src/environments/environment';
import { ObjetionMap } from '../../map/models/objetionMap-model';
import { ValidationMapModel } from '../../map/models/validation-map-model';
import { NoObjectionComponent } from '../../map/no-objection/no-objection.component';
import { Reject } from '../models/send-map-model';
import { getDOCurrencyFormat } from 'src/app/shared/utils/utility';
//import { ValidatonMapService } from '../../../services/validaton-map.service'

@Component({
  selector: 'app-validate-profile',
  templateUrl: './validate-profile.component.html',
  styleUrls: ['./validate-profile.component.css']
})
export class ValidateProfileComponent implements OnInit, OnDestroy {
  public path: string = "";
  public loadingPic = true;

  jobDataTypes = []
  mask = "000-0000000-0";
  identification: string = '';

  mapValidation =  new FormGroup({
    name: new FormControl(''),
    id: new FormControl('')
  });
  useMask = false
  mapPrincipalDataItem: any;
  subscriptions: Subscription = new Subscription();
  rejectReasonsList: any[] = [];
  mapValidationPositionRows: { id: number, fieldName: string, text: string | number, value: number, pdf: boolean, urlPdf?: string, rejectReasonId: number }[] = []
  objectionProfile
  listReject: any[] = [];
  list = []
  constructor(
    private _builder: FormBuilder,
    private _toastService: ToastrService,
    public dialogRef: MatDialogRef<NoObjectionComponent>,
    private srvDoc: docService,
    private srvPerson: PersonService,
    private srvPersonalReqFiles: personalRequiredFilesService, private toastr: ToastrService,
    private _parameterControlService: ParameterControlService, private authInfo: AuthInfoService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.getJobDataTypes()
  }

  getObjectionProfileReject(){
    this.srvPerson.getObjectionProfileReject(this.data.validationProfile.ppvid, this.data.validationProfile.companyId)
    .subscribe(res => {
      this.listReject = res.dataList;
      this.jobDataTypes.forEach(element => {
        const ocode = element.ocode
        const item = this.listReject.find(x => x.jobDataType == element.ocode)
        element.reasonReject = ''
        const pdfOcodes = [9, 10, 11, 12]
        element.isPdf = pdfOcodes.includes(ocode)
        if(item){
          element.reasonReject = item.reasonRejectionText
        }
      })
      this.buildPositionData()
    })
  }
  buildPositionData(){
    const item = this.objectionProfile
    const {companyName, departmentName, positionName, occupationalGroup, requisitionType, proposedSalary, nationality, academicLevel } = item || {}
    const jobDataTypes = this.jobDataTypes
    jobDataTypes.forEach(element => {
      const ocode = element.ocode
      const pdfOcodes = [9, 10, 11, 12]
      const pdf = pdfOcodes.includes(ocode)
      let value: any = ''
      switch (ocode) {
        case 1:
          value = companyName
          break;
        case 2:
          value = departmentName
          break;
        case 3:
          value = positionName
          break;
        case 4:  
          value = occupationalGroup
          break;
        case 5:
          value = requisitionType
          break;
        case 6:
          value = proposedSalary ? getDOCurrencyFormat(proposedSalary) : 0
          break;
        case 7:
          value = nationality
          break;
        case 8:
          value = academicLevel
          break;
        case 9:
          value = 'certificación-no-delincuencia.pdf'
          break;
        case 10:
          value = 'certificación-medica.pdf'
          break;
        default:
          value = (pdf) ? (element.stringData + '.pdf') : ''
          break;
      }
      element.value = value
    })
  }

  getJobDataTypes(){
    this._parameterControlService.getParameters(keyWord.JobDataType).subscribe({
      next: (res:any) => {
        if(res.errors?.length > 0){
          this.toastr.error(res.errors[0])
          return
        }
        this.jobDataTypes = res.dataList
        this.jobDataTypes.sort((a,b) => a.ocode - b.ocode)
        this.GetObjectionProfile()


      }, error: (error: any) => {
        this.toastr.error("Ha ocurrido un error inesperado")
      }
    })
  }

  GetObjectionProfile() {
    let personProfile = this.data.validationProfile; 
    let ppvid = this.data.validationProfile.ppvid;
    let companyId = this.data.validationProfile.companyId;

    this.srvPerson.getObjetionProfile(ppvid, companyId).subscribe(
      res => {
        if(!res.succeded){
          this._toastService.error(res.errors[0]);
          return;
        }
        if(this.data?.validationProfile?.condition === 5){
          
        }
        if (res.dataList) {   
          if (res.dataList.length > 0) {
            var item = res.dataList[0]
            let i = 1;   
            this.objectionProfile = item
            this.getPersonDocuments()
            this.setReason()
          }
          this.getObjectionProfileReject();

        }
      },
      error=> this._toastService.error(error)
    )
  }

  setReason(){
    let dataTypes = this.jobDataTypes
    let rejectReasons = this.rejectReasonsList

    dataTypes.forEach(element => {
      const reason = rejectReasons.find(x => element.reasonId == x.reasonId)
      if(reason){
        element.reasonText = reason.reasonRejectionText
      }
    })
    this.list = dataTypes
  }

  getPersonDocuments() {
    this.path = ''
    this.srvPerson.getPersonById(this.objectionProfile.personId).subscribe(resPerson => {
      if (resPerson.dataList.length > 0) {
        var data = resPerson.dataList[0];
        this.loadingPic = true;
        this.mapValidation.get('id').patchValue(data.personalIdentification)
        this.mapValidation.get('name').patchValue(data.fullName)
        this.useMask = (data.typeIdentificationId == 1)

        //buscamos la foto de perfil 
        if (data.profileImage != null && data.profileImage != '') {
          this.srvDoc.getDocument(data.profileImage).subscribe(doc => {
            this.loadingPic = false;
            this.path = doc.data;
          }, error => {
            this.loadingPic = false;
            this._toastService.error(error)
          })
        }

        this.srvPersonalReqFiles.get(data.personId).subscribe(res => {
          if (res.dataList.length > 0) {
            var fileReq = res.dataList[0]

            forkJoin({
              criminalRecord: this.srvDoc.getDocument(fileReq.criminalRecord),
              medicalCertificate: this.srvDoc.getDocument(fileReq.medicalCertificate),
              academicDocument: this.srvDoc.getDocument(fileReq.academicDocument),
              identificationImage: this.srvDoc.getDocument(fileReq.identificationImage)
            })
              .subscribe(({ criminalRecord, medicalCertificate, academicDocument, identificationImage}) => {
                const criminal = this.jobDataTypes.find(e => e.ocode === 9)
                const medical = this.jobDataTypes.find(e => e.ocode === 10)
                const academic = this.jobDataTypes.find(e => e.ocode === 11)
                const documentId = this.jobDataTypes.find(e => e.ocode === 12)
                if(criminal){
                  criminal.urlPdf = criminalRecord.data
                }
                if(medical){
                  medical.urlPdf = medicalCertificate.data
                }
                if(academic){
                  academic.urlPdf = academicDocument.data
                }
                if(documentId){
                  documentId.urlPdf = identificationImage.data
                }

              }, error => {
                this._toastService.error(error)
              });
          }

        }, error => {
          this._toastService.error(error)
        })
      }
    }, error => {
      this._toastService.error(error)
    })
  }

  close() {
    const data = { accepted: false, item: null };
    this.dialogRef.close(data);
  }
  
  openPdf(urlPdf) {
    if (urlPdf) {
      window.open(urlPdf, "_blank");
    } else {
      this.toastr.warning(`Esta evidencia no fue cargada al sistema`);
    }
  }
}
