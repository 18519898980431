<h2 class="mat-dialog-title">
    <b>{{departmentName}}</b>
    <button type="button" (click)="closeModal()" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</h2>
<div class="mat-dialog-content-custom">
    <div class="table-responsive">
        <table class="table table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th class="min-w-150px">Servidor Público</th>
                    <th class="min-w-140px text-center">Sexo</th>
                    <th class="min-w-110px ">Cargo</th>
                    <th class="min-w-110px ">Tipo</th>
                    <th class="min-w-110px ">Ingreso Bruto</th>
                    <th class="min-w-110px ">Otros Ingresos</th>
                    <th class="min-w-110px ">Ingreso Total</th>
                    <th class="min-w-110px ">AFP</th>
                    <th class="min-w-110px ">SFS</th>
                    <th class="min-w-110px ">ISR</th>

                    <th class="min-w-110px ">Otro Desc.</th>
                    <th class="min-w-110px ">Total Desc.</th>
                    <th class="min-w-110px ">Neto</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dataListPaginated">
                    <td class="text-left">
                        <span
                            class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{{item?.firstName}}
                            {{item?.lastName}}</span>
                    </td>
                    <td class="text-center">
                        <span class="text-muted font-weight-500"><strong>{{item?.sex}}</strong></span>
                    </td>
                    <td class="text-left">
                        {{item?.positionName}}
                    </td>
                    <td class="text-left">
                        {{item?.employeeType}}
                    </td>
                    <td class="text-left">
                        {{item?.grossSalary | currency:"RD$"}}
                    </td>
                    <td class="text-left">
                        {{item?.otherCredits | currency:"RD$"}}
                    </td>
                    <td class="text-left">{{item?.totalSalary | currency:"RD$"}}</td>
                    <td class="text-left">
                        {{item?.afp | currency:"RD$"}}
                    </td>
                    <td class="text-left">
                        {{item?.sfs | currency:"RD$"}}
                    </td>
                    <td class="text-left">
                        {{item?.isr | currency:"RD$"}}
                    </td>

                    <td class="text-left">
                        {{item?.otherDiscounts | currency:"RD$"}}
                    </td>
                    <td class="text-left">
                        {{item?.totalDiscount | currency:"RD$"}}
                    </td>
                    <td class="text-left">
                        {{item?.netIncome | currency:"RD$"}}
                    </td>
                </tr>
            </tbody>
        </table>
        <div>

            <el-caption #paginator [originalRecordsLength]="dataList.length"
                [listedRecordsLength]="dataListPaginated.length" [registerQuantity]="dataList.length"
                [records]="dataList" (onChage)="getPaginatedRecords($event)"></el-caption>
        </div>
    </div>
</div>