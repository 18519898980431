export class BudgetArea {
    budgetAreaId?: number;
    description: string;
    code: string;
    status: boolean;
    companyId: number;
    createDate: string;
    createUserId: number;
    modifyUserId: number;
    executingUnit: string;
    modifyDate: any;
    executingUnitDescription: string;
    constructor() {
        this.executingUnitDescription = ''
        this.modifyUserId = 0;
        this.modifyDate = null;
        this.executingUnit = '';
        this.description = ""
        this.code = ""
        this.status = false
        this.companyId = 0
        this.createDate = ""
    }
}
