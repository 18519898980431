import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "src/environments/environment"
import { AuthInfoService } from "./auth-info.service"
import { ResponseModel } from "../shared/models/strongly-typed-response.model"
import { PostRoyaltyDetailBulkLoad, ProcessAllData, RoyaltyDetailModel } from "@payroll/open-payroll/payroll-royalty-summary/models/royaltyDetail.model";

@Injectable({
    providedIn: 'root'
  })
  export class RoyaltyDetailService {

    api = environment.api_url
    constructor(private http: HttpClient, private authInfo: AuthInfoService) { }
  
    getRoyaltySummary(HeaderId: number){
        return this.http.get<ResponseModel<any>>(`${this.api}/RoyaltyDetails/GetRoyaltySummary?HeaderId=${HeaderId}`)
    }
    getRoyaltyDetail(HeaderId: number){
        return this.http.get<ResponseModel<any>>(`${this.api}/RoyaltyDetails/GetRoyaltyDetail?HeaderId=${HeaderId}`)
    }
    getDataValidationRoyaltyDetail(conditionId: number){
        return this.http.get<ResponseModel<any>>(`${this.api}/RoyaltyDetails/GetDataValidationRoyaltyDetail?ConditionId=${conditionId}`)
    }
    getDataValidationRoyaltyDetailSummary(){
        return this.http.get<ResponseModel<any>>(`${this.api}/RoyaltyDetails/GetDataValidationRoyaltyDetailSummary`)
    }

    processAllDataRoyaltyDetail(processAllData: ProcessAllData) {
        return this.http.post(this.api + '/RoyaltyDetails/ProcessAllDataRoyaltyDetail', processAllData)
    }
    
    postRoyaltyDetailsBulkLoad(royaltyDetailBulkLoad: PostRoyaltyDetailBulkLoad[]) {
        return this.http.post(this.api + `/royaltyDetails/PostRoyaltyDetailsBulkLoad?UserId=${this.authInfo.getUserId()}&CompanyId=${this.authInfo.getCompanyId()}`, royaltyDetailBulkLoad)
    }

    createRoyaltyDetail( royaltyDetail: RoyaltyDetailModel){
        return this.http.post(this.api + '/RoyaltyDetails', royaltyDetail)
    }
    
    
}