
<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button (click)="this.dialogRef.close();" type="button" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="fs-3 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px "></span> Vacante aplicada
    </h2>
</div>

<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <div>
            <form action="" [formGroup]="modalForm">
                <div class="row d-flex justify-content-between">
                    <div class="form-group col-lg-6">
                        <h3 class="text-danger">Aplicando a la Vacante:</h3>
                        <input type="text" formControlName="positionName" class="form-control form-control-lg">
                    </div>
                    <div class="form-group col-lg-3">
                        <h3 class="text-danger">Tipo de Vacante:</h3>
                        <input type="text" formControlName="requisitionType" class="form-control form-control-lg">
                    </div>
                    <div class="form-group col-lg-3">
                        <h3 class="text-danger">Fecha Aplicación:</h3>
                        <input formControlName="dateApplication" class="form-control form-control-lg"
                            placeholder="Fecha de solicitud" type="date" />
                    </div>
                </div>
                <div class="my-7 separator separator-solid"></div>
                <div class="row">
                    <div class="form-group col-md-6 col-xl-4">
                        <label class="text-primary">Salario deseado</label>
                        <input placeholder="0.00" type="text" currencyMask [options]="{precision:2}" name="salary"
                            formControlName="desiredSalary" class="form-control form-control-solid form-control-lg">
                    </div>
                    <div class="form-group col-md-6 col-xl-4">
                        <label class="text-primary">Fecha Disponible</label>
                        <input type="date" formControlName="startDate"
                            class="form-control form-control-solid form-control-lg">
                    </div>
                    <div class="form-group col-md-6 col-xl-4">
                        <label class="text-primary">Horario Disponible</label>
                        <input type="text" formControlName="hoursAvailableName"
                            class="form-control form-control-solid form-control-lg">
                    </div>
                </div>
                <div class="row">
                    <div class="align-items-center col-md-6 d-flex flex-wrap form-group justify-content-between">
                        <label class="d-block text-primary mb-0">¿Está laborando actualmente?</label>
                        <div class="yn">
                            <div class="form-check form-check-inline ml-5" *ngFor="let item of YesOrNot">
                                <label class="form-check-label mt-2 check-dtl">
                                    <input class="form-check-input" formControlName="employee" required=""
                                        name="employee" type="radio" [value]="item.value">
                                    {{item.text}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="align-items-center col-md-6 d-flex flex-wrap form-group justify-content-between">
                        <label class="d-block text-primary mb-0">¿Disponibilidad para traslado?</label>
                        <div class="yn">
                            <div class="form-check form-check-inline ml-5" *ngFor="let item of YesOrNot">
                                <label class="form-check-label mt-2 check-dtl">
                                    <input formControlName="transfer" class="form-check-input" required=""
                                        name="transfer" type="radio" [value]="item.value">
                                    {{item.text}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="align-items-center col-md-6 d-flex flex-wrap form-group justify-content-between">
                        <label class="d-block text-primary mb-0">¿Tiene disponibilidad para viajar?</label>
                        <div class="yn">
                            <div class="form-check form-check-inline ml-5" *ngFor="let item of YesOrNot">
                                <label class="form-check-label mt-2 check-dtl">
                                    <input formControlName="toTravel" class="form-check-input" required=""
                                        name="toTravel" type="radio" id="rdYes" [value]="item.value">
                                    {{item.text}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="align-items-center col-md-6 d-flex flex-wrap form-group justify-content-between">
                        <label class="d-block text-primary mb-0">¿Autorizo el uso público de mis datos?</label>
                        <div class="yn">
                            <div class="form-check form-check-inline ml-5" *ngFor="let item of YesOrNot">
                                <label class="form-check-label mt-2 check-dtl">
                                    <input formControlName="authorizedData" class="form-check-input" required=""
                                        name="authorizedData" type="radio" id="rdYes" [value]="item.value">
                                    {{item.text}}
                                </label>
                            </div>
                        </div>
                        <!-- <a target="_blank" href="assets/docs/prueba/Diplomas_Estudios_de_Grado_1994_y_Posgrado_2003_UNAPEC.pdf"
                          class="btn btn-light-danger font-weight-bold mb-3 w-100 mt-3">
                          <i class="flaticon-pdf-file icon-2x"></i>Ver Política </a> -->
                    </div>
                </div>
                <div class="row">
                    <div class="d-flex flex-column form-group">
                        <label>Otras condiciones particulares de empleabilidad</label>
                        <textarea formControlName="comment" placeholder="Describa todas las que considere necesarias"
                            class="form-control form-control-lg"></textarea>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2"
    (click)="this.dialogRef.close();" type="button">
    <i class="flaticon2-cross"></i>
    <span>Cerrar</span>
</button>
</div>