import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ToastrService } from 'ngx-toastr';
import { ServiceCommissionService } from 'src/app/services/service-commission.service';
import { IEmployeePermissions } from './../models/EmployeePermissions';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { environment } from 'src/environments/environment';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import { IEmployee } from 'src/app/models/Employee.model';
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { DomSanitizer } from '@angular/platform-browser';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };
@Component({
  selector: 'app-service-commission',
  templateUrl: './service-commission.component.html',
  styleUrls: ['./service-commission.component.css']
})
export class ServiceCommissionComponent implements OnInit, OnChanges {
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'ACTION-CHANGE-EMPLOYEE',
    serviceCommission: { key: 'Service-Commission-Recurring-Actions', value: false },
    add: { key: 'Add-Service-Commission-Recurring-Actions', value: false },
    view: { key: 'View-Pdf-Service-Commission-Recurring-Actions', value: false },
    edit: { key: 'Edit-Service-Commission-Recurring-Actions', value: false },
    finalize: { key: 'Finalize-Service-Commission-Recurring-Actions', value: false },
    approve: { key: 'Approve-Service-Commission-Recurring-Actions', value: false },
    reject: { key: 'Reject-Service-Commission-Recurring-Actions', value: false }
  }
  minEndDate = null
  motiveDropdownConfig;
  institutionDropdownConfig;
  motives = []
  formIsInvalid = false
  currentDate = new Date().toDateString()
  readonly urlDoc = environment.api_doc + '/Document';
  personalFile = personalFile;
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  sweetAlrt = new SweetAlert()
  institutions = []
  file = { document: '' }
  form: FormGroup
  @Input() employee: IEmployee
  @Input() commissions: any;
  @Input() isSeviceCommission: boolean = false;
  @Input() isSuspended: boolean = false;
  @Input() vacationList: any[] = [];
  isEditingServiceCommision: boolean = false;
  currentConditionOnEditting!: number;
  currentEdittingIsWithoutPayroll: boolean = false;

  reasonsConfig = this.getDropdownConfig('stringData');

  modificationReasons: ParameterControl[] = [];
  
  endDateInvalid = false;

  @ViewChild('saveButtonCs') saveButtonRef!: ElementRef;

  @ViewChild('uppyFileServiceCommission')
  uppyFileServiceCommission: UppyFileComponent | null = null;
  constructor(
    private formbuilder: FormBuilder,
    private parameter: ParameterControlService,
    private toastr: ToastrService,
    private srvService: ServiceCommissionService,
    public authInfo: AuthInfoService,
    private sanitizer: DomSanitizer,
    private docService: docService,
  ) { 
    this.form = this.formbuilder.group({
      permissionsId: [0],
      requestDate: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      institution: [null, [Validators.required]],
      motive: [null, [Validators.required]],
      comment: ['', [Validators.required]],
      conditionId: [0],
      evidence: ['', [Validators.required]],
      newModificationComment: [null],
      newModificationReason: [null],
      newModificationReasonId: [null],
      oldModificationComment: [null],
      oldModificationReason: [null]
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSeviceCommission']?.currentValue || changes['isSuspended']?.currentValue) {
      this.disableButtonsAndInputs();
    }
  }

  ngOnInit(): void {
    this.dropdownConfig()
    this.setPrivileges()
    this.getMotives()
    this.getInstitutions()
  }

  disableButtonsAndInputs() {
    if(this.form){
      this.form.disable();
    }
    var buttons: any = document.getElementsByClassName('btn');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = true;
    }
  }

  getDropdownConfig(displayKey: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  finalizePermission(idPermission) {
    this.sweetAlrt.AlertConfirm("Finalizar", "¿Está seguro de que deseas finalizar esta Comisión de Servicio?", "question").then(res => {
      if (res) {
        this.srvService.finalizePermission(idPermission).subscribe({
          next: res => this.toastr.success('Comisión finalizada con exito'),
          error: err => this.toastr.error(err)
        })
      } else {

      }
    })

  }
  setPrivileges() {
    const privileges = this.privilege
    const nameKey = privileges.nameKeyOption
    this.privilege.add.value = this.authInfo.canDoAction(nameKey, privileges.add.key)
    this.privilege.edit.value = this.authInfo.canDoAction(nameKey, privileges.edit.key)
    this.privilege.approve.value = this.authInfo.canDoAction(nameKey, privileges.approve.key)
    this.privilege.view.value = this.authInfo.canDoAction(nameKey, privileges.view.key)
    this.privilege.finalize.value = this.authInfo.canDoAction(nameKey, privileges.finalize.key)
    this.privilege.reject.value = this.authInfo.canDoAction(nameKey, privileges.reject.key)
    this.privilege.serviceCommission.value = this.authInfo.canDoAction(nameKey, privileges.serviceCommission.key)
  }

  changeStartDate() {
    this.minEndDate = this.form.get('startDate').value
  }
  openGuID(guid) {
    try {
      this.docService.getDocument(guid).subscribe(e => {
        window.open(e.data, '_blank');
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  getMotives() {
    this.parameter.getParameters(keyWord.ServiceCommissionMotives).subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          this.toastr.error(res.errors[0])
          return
        }
        this.motives = res.dataList
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })

    this.parameter.getParameters(keyWord.ReasonsToModifyPersonalAction).subscribe({
      next:(res)=>{
        if(!res.succeded){
          res.errors.forEach(err=>{
            this.toastr.error(err);
          })

          res.warnings.forEach(warn=>{
            this.toastr.warning(warn);
          })

          return;
        }

        this.modificationReasons = res.dataList;
      },
      error: (err)=>{
        this.toastr.error('Ha ocurrido un error inesperado consultado la lista de razones de modificacion')
      }
    })
  }

  getInstitutions() {
    this.srvService.getInstitutions().subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          this.toastr.error(res.errors[0])
          return
        }
        this.institutions = res.dataList

        if (this.employee?.status == false || this.isSeviceCommission) {
          this.form.disable()
        }
      }, error: (error: any) => {

      }
    })
  }

  resetForm() {
    this.form.patchValue({
      requestDate: null,
      startDate: null,
      endDate: null,
      institution: null,
      motive: null,
      comment: '',
      evidence: '',
      permissionsId: 0,
      conditionId: 0,
      createUserId: 0,
      newModificationComment: null,
      newModificationReason: null,
      newModificationReasonId: null,
      oldModificationComment: null,
      oldModificationReason: null
    })
  }

  save() {

    const startDate = moment(this.form.get('startDate').value)
    const endDate = moment(this.form.get('endDate').value)
    this.endDateInvalid = startDate > endDate
    if (this.endDateInvalid) return
    this.formIsInvalid = this.form.invalid
    if (this.formIsInvalid) {
      this.toastr.warning("Debe llenar todos los campos", "Aviso");
      return
    }
    const val = this.form.value
    let motiveId = 0
    if (val.motive) {
      motiveId = val.motive.ocode
    }

    this.uppyFileServiceCommission.handleFile((guid) => {
      let commission: IEmployeePermissions = {
        typePersonalActionId: 12,
        applicationDate: val.requestDate,
        comment: val.comment,
        CommisionServiceReasonId: motiveId,
        companyId: this.authInfo.getCompanyId(),
        dateEnd: val.endDate,
        dateStart: val.startDate,
        destinationInstitution: val?.institution?.institutionId,
        employeeId: this.employee.employeeId,
        evidence: guid,
        permissionsId: val?.permissionsId > 0 ? val?.permissionsId : 0,
        status: true,
        conditionId: val?.permissionsId > 0 ? val?.conditionId : 2,
        createUserId: this.isEditingServiceCommision ? val.value?.createUserId : this.authInfo.getUserId(),
        modifyUserId: this.isEditingServiceCommision ? this.authInfo.getUserId() : 0,
        modificationComment: val?.newModificationComment,
        modificationReasonId: val?.newModificationReasonId,
      }
      if (this.employeeIsInVacations(val.startDate, val.endDate).isInVacation) {
        let sweetAlert = new SweetAlert()
        sweetAlert.Default("Aviso", "Existen vacaciones activas de este Servidor Público dentro de las fechas seleccionadas para la Comisión de Servicio, Para realizar esta acción deberá pausar o eliminar esas vacaciones", "warning");
        return;
      } else {
  
        if (this.isEditingServiceCommision) {
          this.updatePermission(commission);
        } else {
          this.postPermission(commission);
        }
      }
    });
  }

  private postPermission(serviceCommision) {
    this.srvService.postPermission(serviceCommision).subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          if (res.errors.length > 0) {
            this.toastr.error(res.errors[0])
          }
          else if (res.warnings.length > 0) {
            this.toastr.warning(res.warnings[0])
          }
          return
        }
        this.toastr.success("Datos guardados");
        this.clearFields(true);
      }, error: (error: any) => {
        this.toastr.error("Ha ocurrido un error al modificar el registro");
      }
    })
  }

  private updatePermission(serviceCommision) {
    this.srvService.updatePermission(serviceCommision).subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          if (res.succeded == false) {
            this.toastr.error(res.errors[0])
          }
          else if (res.warnings.length > 0) {
            this.toastr.warning(res.warnings[0])
          }
          return
        }
        this.toastr.success("Registro modificado exitosamente", "Registro Modificado");
        this.clearFields(true);
      }, error: (error: any) => {
        this.toastr.error("Ha ocurrido un error al modificar el registro");
      }
    })
  }


  clearFields(reloadList: boolean = false){
    this.resetForm()
    this.isEditingServiceCommision = false;

    this.uppyFileServiceCommission.cleanUppy();
    this.file.document = '';

    if(reloadList){
      this.getCommissions()

      if (this.isSeviceCommission) {
        this.form.disable();
        this.saveButtonRef.nativeElement.disabled = true;
      }
    }

    this.form.get('newModificationReason').clearValidators();
    this.form.get('newModificationReason').updateValueAndValidity();
  }

  getCommissions() {
    this.srvService.getEmployeePermissionsByIdEmployee(this.employee.employeeId).subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          this.toastr.error(res.errors[0])
          return
        }
        this.commissions = res.dataList.filter(x => x.typePersonalActionId == 12);
      }, error: (error: any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  dropdownConfig() {
    this.motiveDropdownConfig = {
      displayKey: 'stringData',
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: 'stringData'
    }
    this.institutionDropdownConfig = {
      displayKey: 'socialReason',
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: 'socialReason'
    }
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  getGuID(guid) {
    try {
      this.docService.getDocument(guid).subscribe(e => {
        this.file.document = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  showError(name: string) {
    const field = this.form.get(name)
    if (field.hasError('required')) {
      return 'Este campo es requerido'
    }
    return ''
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  setEvidence(event){
    this.form.get('evidence').setValue(event);
  }

  editServiceCommision(serviceCommision) {

    this.clearFields(false);


    this.isEditingServiceCommision = true;

    this.currentConditionOnEditting = serviceCommision?.conditionId;
    this.currentEdittingIsWithoutPayroll = serviceCommision?.withoutPayroll;

    const modificationReason = this.modificationReasons?.find(m => m.ocode == serviceCommision?.modificationReasonId);

    this.form.enable();
    this.form.patchValue({
      ...serviceCommision,
      requestDate: formatDate(serviceCommision?.applicationDate, "yyyy-MM-dd", "en"),
      startDate: formatDate(serviceCommision?.dateStart, "yyyy-MM-dd", "en"),
      endDate: formatDate(serviceCommision?.dateEnd, "yyyy-MM-dd", "en"),
      createUserId: serviceCommision.createUserId,
      oldModificationComment: serviceCommision?.modificationComment,
      oldModificationReason: modificationReason
    });
    const institution = [...this.institutions].filter(i => i?.institutionId === serviceCommision?.destinationInstitution)
    const motive = [...this.motives].filter(m => m.ocode === serviceCommision.commisionServiceReasonId);
    this.form.get('institution').setValue(institution)
    this.form.get('motive').setValue(motive)
    if (serviceCommision?.evidence) {
      this.file.document = serviceCommision?.evidence;
    } else {
      this.file.document = '';
    }

    this.saveButtonRef.nativeElement.disabled = false;

    this.form.get('newModificationReason').setValidators(Validators.required);
    this.form.get('newModificationReason').updateValueAndValidity();
  }

  changeModificationReason(value: ParameterControl){
    this.form.get('newModificationReasonId').setValue(value?.ocode);
  }

  exportToPdf(employeeId: number, permissionsId: number) {
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.ServiceComission;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&EmployeeId=${employeeId}&CompanyId=${companyId}&PermissionsId=${permissionsId}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Unidades Organizativas',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  employeeIsInVacations(licenseStartDate: any, licenseEndDate: any) {
    
    let hasVacation = this.vacationList
      .filter((vacantion: any) => vacantion.employeeId == this?.employee.employeeId)
      .find(vacantion => (this.convertData(vacantion.dateStart) >= licenseStartDate && this.convertData(vacantion.dateEnd) <= licenseEndDate)
        || licenseStartDate == this.convertData(vacantion.dateEnd) || licenseEndDate == this.convertData(vacantion.dateEnd)
        || licenseStartDate == this.convertData(vacantion.dateStart) || licenseEndDate == this.convertData(vacantion.dateStart)
        || (licenseStartDate >= this.convertData(vacantion.dateStart) && licenseStartDate <= this.convertData(vacantion.dateEnd))
        || (licenseEndDate >= this.convertData(vacantion.dateStart) && licenseEndDate <= this.convertData(vacantion.dateEnd)));
    return {
      isInVacation: hasVacation?.vacancyId ? true : false,
      vacantionId: hasVacation?.vacancyId
    }
  }

  convertData(vacantion) {
    return formatDate(vacantion, 'yyyy-MM-dd', 'en_US')
  }

}