
export class MasiveFiredItem {
    id?: string;
    name?: string;
    category?:string;
    effectiveDate?: string;
    firedType?: any;
    firedReason?: any;
    isActive?:boolean=true;
    startDateText?: string;
    startDate?:  Date;
    endDateText?: string;
    endDate?: Date;
}


export enum FiredType{
  Normal=0,
  Incumplimiento=1,
  Cancelacion=2,
  Otra=3
}
