<div *ngIf="isTableLoading"
  class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
  <el-spinner animation="spinner-logo"></el-spinner>
  <h3 class="text-primary">Cargando PDF...</h3>
</div>
<div class="container-fluid py-3">
  <button  (click)="goBack()"  class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
      <i class="flaticon2-left-arrow-1"></i>
      <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
  </button>
</div>
<div class="container-fluid" *ngIf="!isTableLoading">
  <div id="pdfExp" class="card">
    <div class="card-header px-22">
      <div class="align-items-center d-flex flex-wrap justify-content-lg-between justify-content-md-center" id="header">
        <div class="logo px-2" >
          <img [src]="file.logo"  alt="" class="max-w-200px" />
        </div>

        <div *ngIf="fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">
            {{fileModalConfig.formTitle}}
          </h1>
          <h2 class="text-danger">{{fileModalConfig.formSubTitle}}</h2>
        </div>
        <div *ngIf="!fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">
            Recursos Humanos
          </h1>
          <h2 class="text-danger">Solicitud de Empleo</h2>
        </div>
        <div class="version p-4 d-flex justify-content-between align-items-center flex-column">
          <h4 class="text-right ">FO-RH-14</h4>
          <h4 class="text-right ">Versión 0</h4>
        </div>
       <!-- <div class="date p-4 d-flex flex-wrap justify-content-between align-items-center">
           <h4 class="text-right mr-3">Fecha de solicitud</h4>
          <ng-template [ngIf]="personalRequestView?.person">
            <h5 class="text-danger text-right">{{ personalRequestView?.person.dateRequestFormatted || '__-__-____' }}</h5>
          </ng-template>
          &nbsp;
        </div>-->
      </div>
    </div>
    <div class="card-body pdf-break-text">
      <div id="pdf">
        <div class="pdf-page pb-4">
          <div class="container-fluid row mt-3" id="personal-data">
            <h3 class="col-md-12 p-2 bg-primary text-white mt-3 text-center">DATOS PERSONALES</h3>
            <div class="col-md-7 row">
              <div class="photo col-md-4">
                <span *ngIf="loadingPic" class="d-flex flex-column align-items-center">
                  <img src="assets/media/logos/xmera-logo-3.png" alt="loading" width="70px" />
                  <strong class="text-danger font-size-h3">Cargando...</strong>
                </span>

                <ng-container *ngIf="!loadingPic">
                  <div class="symbol symbol-lg-200" *ngIf="!photoUrl; else second">
                    <i class="symbol-badge bg-success"></i>
                    <img src="assets/media/no-user.png" alt="image">
                  </div>
                  <ng-template #second>
                    <div class="symbol symbol-lg-200">
                      <i class="symbol-badge bg-success"></i>
                      <img [src]="photoUrl | safe" alt="image" (click)="showModal('photo')">
                    </div>
                  </ng-template>
                </ng-container>
              </div>
              <div class="pdf-list col-md-8">
                <div class="row">
                    <div class="col-12 col-sm-5 label-inline">
                        <label>Nombre(s):</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap ms-4">{{ personalRequestView?.person.firstName }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-5 label-inline">
                        <label>Apellido(s):</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap ms-4">{{ personalRequestView?.person.lastName }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-5 label-inline">
                        <label>Calle:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap ms-4">{{ personalRequestView?.person.street || 'N/A' }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-5 label-inline">
                        <label>No. de casa:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap ms-4">{{ (personalRequestView?.person.numberhouse != 0 ? personalRequestView?.person.numberhouse :
                            'N/A') }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-5 label-inline">
                        <label>Apto.:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap ms-4">{{ personalRequestView?.person.apartmentNumber || "N/A" }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-5 label-inline">
                        <label>Edif.:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap ms-4">{{ personalRequestView?.person.buildingName || "N/A" }}</span>
                    </div>
                </div>
                <!-- <div class="pdf-items">
                  <h4>Ciudad:</h4>
                  <span>{{ personalRequestView?.person.city || "N/A" }}</span>
                </div> -->
                <div class="row">
                    <div class="col-12 col-sm-5 label-inline">
                        <label>Ciudad:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap ms-4">{{ personalRequestView?.person.municipality || "N/A" }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-5 label-inline">
                        <label>Sector:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap ms-4">{{ personalRequestView?.person.sector || "N/A" }}</span>
                    </div>
                </div>
                <div class="row" *ngIf="isDominican()">
                    <div class="col-12 col-sm-5 label-inline">
                        <label>Provincia:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap ms-4">{{ personalRequestView?.person.province }}</span>
                    </div>
                </div>
                <!-- <div class="row" *ngIf="!isDominican()">
                    <div class="col-12 col-sm-5 label-inline">
                        <label>País, Ciudad:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap ms-4">{{ personalRequestView?.person.countyName }},
                            {{ personalRequestView?.person.birthCity }}</span>
                    </div>
                </div> -->
                <div class="row" >
                  <div class="col-12 col-sm-5 label-inline">
                      <label>{{personalRequestView?.person.typeIdentification}}:</label>
                  </div>
                  <div class="col-12 col-sm-7 label-inline">
                      <span class="text-wrap ms-4">
                        <ng-container *ngIf="personalRequestView?.person.typeIdentificationId === 1; else noCedula">
                          {{personalRequestView?.person.personalIdentification | mask: "000-0000000-0"}}
                        </ng-container>
                        <ng-template #noCedula>
                          {{personalRequestView?.person.personalIdentification}}
                        </ng-template>
                      </span>
                  </div>
              </div>
                <!--div class="pdf-items" *ngIf="isDominican()">
                  <h4>Provincia:</h4>
                  <span>{{ personalRequestView?.person.province }}</span>
                </div-->
                <div class="row" >
                  <div class="col-12 col-sm-5 label-inline">
                      <label>País :</label>
                  </div>
                  <div class="col-12 col-sm-7 label-inline">
                      <span class="text-wrap ms-4">{{ getCountyName(personalRequestView?.person.countryId) }}</span>
                  </div>
              </div>
              </div>
            </div>
            <div class="pdf-list col-md-5">
                <div class="row" >
                    <div class="col-12 col-sm-5 label-inline">
                        <label>Fecha de nacimiento:</label>
                    </div>
                    <div class="col-12 col-sm-7 label-inline">
                        <span class="text-wrap ms-4">{{ personalRequestView?.person.birthday |date:"dd/MM/yyyy" || "N/A" }}</span>
                    </div>
                </div>
              <!-- <div class="pdf-items">
                <h4>Tipo de sangre:</h4>
                <span>{{ personalRequestView?.person.bloodType }}</span>
              </div> -->
              <div class="row" >
                <div class="col-12 col-sm-5 label-inline">
                    <label>Discapacidad:</label>
                </div>
                <div class="col-12 col-sm-7 label-inline">
                    <span class="text-wrap ms-4">{{ personalRequestView?.person.disabilityType || "N/A" }}</span>
                </div>
            </div>
            <div class="row" >
                <div class="col-12 col-sm-5 label-inline">
                    <label>Sexo:</label>
                </div>
                <div class="col-12 col-sm-7 label-inline">
                    <span class="text-wrap ms-4">{{ personalRequestView?.person.sex | lowercase }}</span>
                </div>
            </div>
            <div class="row" >
                <div class="col-12 col-sm-5 label-inline">
                    <label>Teléfono:</label>
                </div>
                <div class="col-12 col-sm-7 label-inline">
                    <span class="text-wrap ms-4">{{ primaryPhone ? (primaryPhone | mask: "(000)-000-0000") : 'N/A' }}</span>
                </div>
            </div>
            <div class="row" >
                <div class="col-12 col-sm-5 label-inline">
                    <label>Correo:</label>
                </div>
                <div class="col-12 col-sm-7 label-inline">
                    <span class="text-wrap ms-4">{{ primaryEmail || 'N/A' }}</span>
                </div>
            </div>
            <div class="row" >
                <div class="col-12 col-sm-5 label-inline">
                    <label>Nacionalidad:</label>
                </div>
                <div class="col-12 col-sm-7 label-inline">
                    <span class="text-wrap ms-4">{{personalRequestView?.person.nationality |lowercase}}</span>
                </div>
            </div>
            <div class="row" >
                <div class="col-12 col-sm-5 label-inline">
                    <label>Estado civil:</label>
                </div>
                <div class="col-12 col-sm-7 label-inline">
                    <span class="text-wrap ms-4">{{personalRequestView?.person.maritalStatus}}</span>
                </div>
            </div>
            <div class="row" >
                <div class="col-12 col-sm-5 label-inline">
                    <label>Cónyuge:</label>
                </div>
                <div class="col-12 col-sm-7 label-inline">
                    <span class="text-wrap ms-4">{{personalRequestView?.person.spouseName || "N/A"}}</span>
                </div>
            </div>
            <div class="row" >
              <div class="col-12 col-sm-5 label-inline">
                  <label>Cantidad de hijos:</label>
              </div>
              <div class="col-12 col-sm-7 label-inline">
                  <span class="text-wrap ms-4">{{personalRequestView?.person?.numberOfChildren}}</span>
              </div>
          </div>
          <div class="row" *ngIf="personalRequestView?.personRecomendation; else noRecommended">
            <h3 class="text-danger font-weight-bold m-0">Recomendado por:</h3>

            <ng-template ngFor let-item [ngForOf]="personalRequestView?.personRecomendation">
              <div class="col-12 col-sm-5 label-inline">
                <label>Nombre:</label>
              </div>
              <div class="col-12 col-sm-7 label-inline">
                <span class="text-wrap ms-4">{{item.personName}}</span>
              </div>

            </ng-template>
            <ng-template ngFor let-item [ngForOf]="personalRequestView?.personRecomendation">
              <div class="col-12 col-sm-5 label-inline">
                <label>Teléfono:</label>
              </div>
              <div class="col-12 col-sm-7 label-inline">
                <span class="text-wrap ms-4">{{item.phone | mask : '(000) 000-0000'}}</span>
              </div>

            </ng-template>

            <ng-template ngFor let-item [ngForOf]="personalRequestView?.personRecomendation">
              <div class="col-12 col-sm-5 label-inline">
                <label>Institución:</label>
              </div>
              <div class="col-12 col-sm-7 label-inline">
                <span class="text-wrap ms-4">{{item.institution}}</span>
              </div>
            </ng-template>


          </div>
          <ng-template class="row" #noRecommended>
            <h3 class="text-danger font-weight-bold m-0">Recomendado por:</h3>
            <div class="col-12 col-sm-5 label-inline">
              <span>Nadie</span>
            </div>
          </ng-template>
              <!-- <div class="row">
                <h3 class="text-danger font-weight-bold m-0">Recomendado por:</h3>
              </div>
              <div class="pdf-items col-md-12" *ngIf="personalRequestView?.personRecomendation
                ; else noRecommended">
                <ng-template ngFor let-item [ngForOf]="personalRequestView?.personRecomendation">
                  <h4>Nombre: </h4>
                  {{item.personName}}
                  <div class="pdf-items col-md-12" *ngIf="personalRequestView?.personRecomendation
                ; else noRecommended">
                    <h4>Institución:</h4>
                    {{item.institution}}
                  </div>
                </ng-template>
              </div>
              <ng-template #noRecommended>
                <div class="pdf-items col-md-12">
                  <h4>Nadie</h4>
                </div>
              </ng-template>
            </div> -->
            <!-- <div class="col-md-3">



            </div> -->
          </div>
          <div class="container-fluid row mt-3" id="contact-media">
            <h3 class="col-md-12 p-2 bg-secondary text-white text-center mt-3 d-block"
              style="background: #e3292e !important;">
              MEDIOS DE COMUNICACIÓN Y CONTACTO
            </h3>
            <div class="col-md-12 row m-0 p-0 overflow-auto">
              <div class="col-md-6">
                <div class="card card-stretch gutter-b">
                  <div class="card-header p-0">
                    <h3 class="mb-0 p-3 text-primary text-center bg-light-light">Teléfonos</h3>
                  </div>
                  <div class=" card-body p-2 table-responsive">
                    <table class="table p-0">
                      <thead>
                        <tr class="">
                          <th scope="col" class="text-white">No.</th>
                          <th scope="col" class="text-white">Tipo de teléfono</th>
                          <th scope="col" class="text-white">Número</th>
                          <th scope="col" class="text-white">Contacto Primario</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="" *ngFor="let phone of personalRequestView?.personPhone;let i = index">
                          <td class="">{{ i + 1 }}</td>
                          <td class="">{{ phone.phoneContactType }}</td>
                          <td class="">{{ phone.phoneNumber | mask: "(000)-000-0000" }}</td>
                          <td class="">
                            <widget-dynamic-format [format]="formatYesOrNot" [value]="phone.primaryContact">
                            </widget-dynamic-format>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card card-stretch gutter-b">
                  <div class="card-header p-0">
                    <h3 class="mb-0 p-3 text-primary text-center bg-light-light">Correos Electrónicos</h3>
                  </div>
                  <div class=" card-body p-2 table-responsive">
                    <table class="table ">
                      <thead>
                        <tr class="">
                          <th scope="col" class="text-dark">No.</th>
                          <th scope="col" class="text-dark">Tipo de correo</th>
                          <th scope="col" class="text-dark">Correo</th>
                          <th scope="col" class="text-dark">Correo Primario</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="p-0" *ngFor="let email of personalRequestView?.personsEmails ;let i = index">
                          <td class="">{{ i + 1 }}</td>
                          <td class="">{{ email.mailContactType }}</td>
                          <td class="">{{ email.email }}</td>
                          <td class="">
                            <widget-dynamic-format [format]="formatYesOrNot" [value]="email.primaryContact">
                            </widget-dynamic-format>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid row mt-3" id="family-info">
            <h3 class="col-md-12 mt-3 p-2 bg-primary text-white text-center text-center">
              DATOS FAMILIARES
            </h3>
            <div class="col-md-12 row">
              <table class="table ">
                <thead>
                  <tr class="">
                    <th scope="col" class="text-dark">No.</th>
                    <th scope="col" class="text-dark">Nombre del Familiar</th>
                    <th scope="col" class="text-dark">Parentesco</th>
                    <th scope="col" class="text-dark">Estado Civíl</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="p-0" *ngFor="let item of personalRequestView?.personsDependents ;let i = index">
                    <td class="">{{ i + 1 }}</td>
                    <td class="">{{ item.fullName }}</td>
                    <td class="">{{ item.relationship }}</td>
                    <td class="">{{ item.maritalStatus }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="container-fluid row mt-3 " id="family-working-at-here">
            <h3 class="col-md-12 mt-3 p-2 bg-secondary text-white text-center">FAMILIARES TRABAJANDO EN LA INSTITUCIÓN
            </h3>
            <div class="col-md-12 m-0 p-0 overflow-auto">
              <table class="table p-0">
                <thead>
                  <tr class="p-0">
                    <th scope="col" class="text-dark">No.</th>
                    <th scope="col" class="text-dark">Nombre Completo</th>
                    <th scope="col" class="text-dark">Parentesco</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="p-0" *ngFor="
                      let family of personalRequestView?.familyPersonInInstitutions;
                      let i = index
                    ">
                    <td class="">{{ i + 1 }}</td>
                    <td class="">{{ family.personName || " - " }}</td>
                    <td class="">{{ family.relationship || " - " }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="container-fluid row mt-3 " id="family-working-at-here">
            <h3 class="col-md-12 mt-3 p-2 bg-primary text-white text-center">DEPENDIENTES</h3>
            <div class="col-md-12 m-0 p-0 overflow-auto">
              <table class="table p-0">
                <thead>
                  <tr class="p-0">
                    <th scope="col" class="text-dark">No.</th>
                    <th scope="col" class="text-dark">Nombre del familiar</th>
                    <th scope="col" class="text-dark">Parentesco</th>
                    <th scope="col" class="text-dark">Estado civil</th>
                    <th scope="col" class="text-dark">Fecha de nacimiento</th>
                    <th scope="col" class="text-dark">¿Trabaja?</th>
                    <th scope="col" class="text-dark">Lugar de Trabajo</th>
                    <th scope="col" class="text-dark">¿Estudia?</th>
                    <th scope="col" class="text-dark">Lugar de Estudio</th>
                    <!-- <th scope="col" class="text-dark">
                      Institución(es) donde Trabaja / Estudia
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr class="p-0" *ngFor="
                      let family of personalRequestView?.personsDependents;
                      let i = index
                    ">
                    <td class="">{{ i + 1 }}</td>
                    <td class="">{{ family.fullName || " - " }}</td>
                    <td class="">{{ family.relationship || " - " }}</td>
                    <td class="">{{ family.maritalStatus || " - " }}</td>
                    <td class="">{{ family.birthday |date: "dd/MM/yyyy" || " - " }}</td>
                    <td class="">
                      <span>{{family.job? 'Si' : 'No'}}</span>
                    </td>
                    <td>{{family.placeJob || 'N/A'}}</td>
                    <td class="">
                      <span>{{family.study? 'Si' : 'No'}}</span>
                    </td>
                    <td>{{family.placeStudy || 'N/A'}}</td>
                    <!-- <td class="">
                      <div class="pdf-items">
                        <h4 class="text-danger">Trabaja en:</h4>
                        {{ family.workPlace || " - "  }}
                      </div>
                      <div class="pdf-items">
                        <h4 class="text-danger">Estudia en:</h4>
                        {{ family.studyPlace || " - "  }}
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="container-fluid row mt-3" id="more-info">
            <h3 class="col-md-12 p-2 bg-secondary text-white text-center mt-3">
              INFORMACIÓN ADICIONAL
            </h3>
            <div class="col-md-6">

              <ng-container *ngFor="let item of additionalInfoLists[0]; let i = index">
                <div *ngIf="i == 0">
                  <div class="pdf-items">
                    <h4>¿Posee usted otro trabajo aparte del que solicita? </h4>
                    <ng-container
                      *ngIf="personalRequestView?.personAdditionalInformation[0].anotherJob; else noJob;">
                      <span>
                        Si, {{ personalRequestView?.personAdditionalInformation[0].descriptionOtherJob }}
                        <br>
                        {{ personalRequestView?.personAdditionalInformation[0].jobEntryHour }} - {{ personalRequestView?.personAdditionalInformation[0].jobExitHour }}
                      </span>
                    </ng-container>
                    <ng-template #noJob>
                      <span>
                        No
                      </span>
                    </ng-template>
                  </div>
                </div>
                <div>

                  <div class="pdf-items">
                    <h4>{{item.question}}</h4>
                    <span>{{item.answer? 'Si' :'No'}} {{ item.comment ? ', ' + item.comment : '' }}</span>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="col-md-6">

              <ng-container *ngFor="let item of additionalInfoLists[1]; let i = index">

                <div>

                  <div class="pdf-items">
                    <h4>{{item.question}}</h4>
                    <span>{{item.answer? 'Si' :'No'}} {{ item.comment ? ', ' + item.comment : '' }}</span>
                  </div>
                </div>
              </ng-container>
            </div>











           <!--  <div class="d-flex flex-wrap flex-fill">

              <ng-container *ngFor="let item of recruitmentQuestions; let i = index">
                <div *ngIf="i == 0" class="w-50">
                  <div class="pdf-items">
                    <h4>¿Posee usted otro trabajo aparte del que solicita? </h4>
                    <ng-container
                      *ngIf="personalRequestView?.personAdditionalInformation[0].anotherJob; else noJob;">
                      <span>
                        Si, {{ personalRequestView?.personAdditionalInformation[0].descriptionOtherJob }}
                        <br>
                        {{ personalRequestView?.personAdditionalInformation[0].jobEntryHour }} - {{ personalRequestView?.personAdditionalInformation[0].jobExitHour }}
                      </span>
                    </ng-container>
                    <ng-template #noJob>
                      <span>
                        No
                      </span>
                    </ng-template>
                  </div>
                </div>
                <div class="w-50">
                  <div class="pdf-items">
                    <h4>{{item.question}}</h4>
                    <span>{{item.answer? 'Si' :'No'}} {{ item.comment ? ', ' + item.comment : '' }}</span>
                  </div>
                </div>
              </ng-container>
            </div> -->

              <!-- <div class="pdf-items">
                <h4>¿Utilizará servicio de transporte de la institución? </h4>
                <span>{{personalRequestView?.personAdditionalInformation[0].institutionTransportation? 'Si' :
                  'No'}}</span>
              </div>
              <div class="pdf-items">
                <h4>¿Pertenece usted a alguna organización social, fraternal, cultural o religiosa? </h4>
                <ng-container
                  *ngIf="personalRequestView?.personAdditionalInformation[0].socialOrganization; else noOrganization;">
                  <span>
                    Si, {{ personalRequestView?.personAdditionalInformation[0].descriptionSocialOrganization }}
                  </span>
                </ng-container>
                <ng-template #noOrganization>
                  <span>
                    No
                  </span>
                </ng-template>
              </div>
            </div>
            <div class="col-md-6">
              <div class="pdf-items">
                <h4>¿Ha tenido usted alguna enfermedad y/o accidente importante en los últimos cinco años? </h4>
                <ng-container *ngIf="personalRequestView?.personAdditionalInformation[0].disease
                  ; else noAccidentOrIlliness;">
                  <span>
                    Si, {{ personalRequestView?.personAdditionalInformation[0].descriptionDisease }}
                  </span>
                </ng-container>
                <ng-template #noAccidentOrIlliness>
                  <span>
                    No
                  </span>
                </ng-template>
              </div>
              <div class="pdf-items">
                <h4>¿Ha tenido usted alguna implicación o proceso legal en los últimos cinco años? </h4>
                <ng-container *ngIf="personalRequestView?.personAdditionalInformation[0].legalProcess
                    ; else noLegalProcess;">
                  <span>
                    Si, {{ personalRequestView?.personAdditionalInformation[0].descriptionLegalProcess }}
                  </span>
                </ng-container>
                <ng-template #noLegalProcess>
                  <span>
                    No
                  </span>
                </ng-template>
              </div> -->

            </div>

          </div>
          <div class="container-fluid row mt-3" id="academic-formation">
            <h3 class="col-md-12 p-2 bg-secondary text-white text-center mt-3">
              FORMACIÓN ACADÉMICA
            </h3>
            <div class="col-md-12 m-0 p-0 overflow-auto" id="family-working-at-here">
              <table class="table p-0">
                <thead>
                  <tr class="p-0">
                    <th scope="col" class="text-dark">No.</th>
                    <th scope="col" class="text-dark">
                      Carrera
                    </th>
                    <th scope="col" class="text-dark">
                      Casa de estudio
                    </th>
                  <!--  <th scope="col" class="text-dark">
                      Índice académico
                    </th> -->
                    <th scope="col" class="text-dark">Año de término</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="" *ngFor="
                      let item of personalRequestView?.personsAppliedStudies; let i = index
                    ">
                    <td class="p-3 m-0">{{i+1}}</td>
                    <td class="p-3 m-0">{{ item.career || " - " }}</td>
                    <td class="p-3 m-0">{{ item.studyHouse || " - " }}</td>
                 <!--   <td class="p-3 m-0">
                      {{ item.academicIndex || " - " }}
                    </td> -->
                    <td class="p-3 m-0">
                      {{ item.endYear || " - " }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="container-fluid row mt-3" id="language-skills">
            <h3 class="col-md-12 bg-secondary mt-3 text-white p-2 text-center">
              HABILIDADES
            </h3>
            <div class="col-md-12 m-0 p-0 overflow-auto">
              <table class="table p-0">
                <thead>
                  <tr class="p-0">
                    <th scope="col" class="text-dark">No.</th>
                    <th scope="col" class="text-dark">Tipo de Habilidad</th>
                    <th scope="col" class="text-dark">Habilidad</th>
                    <th scope="col" class="text-dark">Tipo de Nivel</th>
                    <th scope="col" class="text-dark">Nivel</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="" *ngFor="let item of personalRequestView?.personsSkills;let i = index;">
                    <td class="p-3 m-0">{{i+1}}</td>
                    <td class="p-3 m-0">{{ item.typeSkills || ''}}</td>
                    <td class="p-3 m-0">{{ item.skills || '' }}</td>
                    <td class="p-3 m-0">{{ item.typeLevelSkill || '' }}</td>
                    <td class="p-3 m-0">{{ item.levelSkillsDescription || '' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="container-fluid row mt-3" id="references">
            <h3 class="col-md-12 p-2 bg-primary text-white mt-3 text-center">
              HISTORIAL Y REFERENCIAS
            </h3>
            <h3 class="bg-white border-bottom col-md-12 p-3 text-center text-primary bg-light-light ">
              Historial Laboral
            </h3>
            <div class="col-md-12 m-0 p-0 overflow-auto" id="laboral-history">
              <table class="table p-0">
                <thead>
                  <tr class="p-0">
                    <th scope="col" class="text-dark">
                      <div class="primary">
                        No.
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Empresa o Institución
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Función o Puesto Desempeñado
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Período (Desde - Hasta)
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Sueldos (&Uacute;ltimo Sueldo)
                      </div>

                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Motivo de Salida
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="" *ngFor="let item of personalRequestView?.personsWorkExperiences;let i = index;">
                    <td class="p-3 m-0">{{i+1}}</td>
                    <td class="p-3 m-0">{{ item.company }}</td>
                    <td class="p-3 m-0">{{ item.jobTitle }}</td>
                    <td class="p-3 m-0">
                      {{ item.startDate |date: "dd/MM/yyyy"}} -
                      <span *ngIf="item.reasonOfExitId > 0">{{ item.departureDate |date: "dd/MM/yyyy"}}</span>
                      <span *ngIf="item.reasonOfExitId == 0">Actual</span>
                    </td>
                    <td class="p-3 m-0">
                      {{ item.lastSalary | currency }}
                    </td>
                    <td class="p-3 m-0">
                      <span *ngIf="item.reasonOfExitId > 0">{{ item.personName }}</span>
                      <span *ngIf="item.reasonOfExitId == 0">N/A</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3 class="bg-white border-bottom col-md-12 p-3 text-center text-primary border-top mt-5 bg-light-light">
              Referencias Laborales
            </h3>
            <div class="col-md-12 m-0 p-0 overflow-auto">
              <table class="table p-0">
                <thead>
                  <tr class="p-0">
                    <th scope="col" class="text-dark">
                      <div class="primary">
                        No.
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Nombre
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Función o Puesto
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Empresa o Institución
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Contacto Telefónico
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">

                      </div>
                    </th>
                    <!-- <th scope="col" class="text-dark">Segundo Contacto</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr class="" *ngFor="
                      let item of personalRequestView?.personsJobReferences;let i = index;">
                    <td class="p-3 m-0">{{i+1}}</td>
                    <td class="p-3 m-0">{{ item.fullName }}</td>
                    <td class="p-3 m-0">{{ item.position }}</td>
                    <td class="p-3 m-0">{{ item.company }}</td>
                    <td class="p-3 m-0">{{ item.phoneNumber | mask: "(000)-000-0000" }}</td>
                    <td class="p-3 m-0"></td>
                    <!-- <td class="p-3 m-0">{{ item.phone2 | mask: "(000)-000-0000" }}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <h3 class="bg-white border-bottom col-md-12 p-3 text-center text-primary border-top mt-5 bg-light-light">
              Referencias Personales
            </h3>
            <div class="col-md-12 m-0 p-0 overflow-auto">
              <table class="table p-0">
                <thead>
                  <tr class="p-0">
                    <th scope="col" class="text-dark">
                      <div class="primary">
                        No.
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Nombre
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Función o Puesto
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Empresa o Institución
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Contacto Telefónico
                      </div>
                    </th>
                    <th scope="col" class="text-dark">
                      <div class="secondary">
                        Segundo Contacto
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="" *ngFor="let item of personalRequestView?.personalReferences;let i = index;">
                    <td class="p-3 m-0">{{i+1}}</td>
                    <td class="p-3 m-0">{{ item.fullName }}</td>
                    <td class="p-3 m-0">{{ item.position }}</td>
                    <td class="p-3 m-0">{{ item.company }}</td>
                    <td class="p-3 m-0">{{ item.phoneNumber1 | mask: "(000)-000-0000" }}</td>
                    <td class="p-3 m-0">{{ item.phoneNumber2 | mask: "(000)-000-0000" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer mt-4">
      <div class="row justify-content-center ">
        <div class="col-md-10 ">
          <div class="final-info-box ">
            <div class="final-info">
              <p>1.-) Esta solicitud será valida solamente por un (1) año. Nos reservamos el derecho de descartar esta solicitud si se confirma falsedad en la información suministrada.</p>
              <p>2.-) Al firmar este documento, el(la) candidato(a) concede al ITLA el permiso de verificar la validez de las informaciones anteriormente expuestas.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
        <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-80px">
        <div class="firma text-center">
          <span>_________________________________________</span>
          <p class="font-weight-bold text-dark text-center">
            Firma del(de la) interesado(a)
          </p>
        </div>
        <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
      </div>
    </div>
  </div>
</div>
<button (click)="exportToPdf()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
  <i class="flaticon2-printer font-size-h1"></i>
</button>
<script>
  let print = () => {
    let pdf = document.getElementById("pdfExp");
    pdf.contentWindow.focus();
    pdf.contentWindow.print();
  };
</script>
<el-modal target="logoModal" size="md" [title]="'Vista de foto'">
  <el-embed *ngIf="fileModalConfig.src" [src]="fileModalConfig.src" [type]="fileModalConfig.type"
    [filename]="fileModalConfig.filename" [width]="fileModalConfig.width" [height]="fileModalConfig.height">
  </el-embed>
</el-modal>
<el-modal target="photo" size="md" [title]="'Vista de foto'">
  <div class="container justify-content-center" style="align-content: center;">
    <ng-template [ngIf]="photoUrl">
      <img [src]="photoUrl | safe" alt="image" style="max-width: 350px;">
    </ng-template>
  </div>
</el-modal>
