import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-candidate-search-result',
  templateUrl: './candidate-search-result.component.html',
  styleUrls: ['./candidate-search-result.component.css']
})
export class CandidateSearchResultComponent implements OnInit {
  
  constructor(public dialogRef: MatDialogRef<CandidateSearchResultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.dialogRef.disableClose = true;
    }

  ngOnInit(): void {
  }

  Accept(item): void {
    const row = { Applied: true, item: item }
    this.dialogRef.close(row);
  }

  Cancel(): void {
    const row = { Applied: false, item: null }
    this.dialogRef.close(row);
  }
}
