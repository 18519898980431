import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Inject, NgZone, PLATFORM_ID } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { any } from '@amcharts/amcharts5/.internal/core/util/Array';
import { IChartData } from '../models/chartModels';
import { isPlatformBrowser } from '@angular/common';
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import ResponsiveTheme from '@amcharts/amcharts5/themes/Responsive';
import AnimatedTheme from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-chart-dnut',
  templateUrl: './chart-dnut.component.html',
  styleUrls: ['./chart-dnut.component.css']
})
export class ChartDnutComponent implements OnInit, OnChanges,OnDestroy {
  @Input() id: string = "charDonut"
  @Input() valueField:string = 'value'
  @Input() categoryField:string = 'description'
  root: any;
  @Input() data: IChartData[] = []

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) { }
  
  
  ngOnDestroy(): void {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
        this.root = undefined
        document.getElementById(this.id).remove()
        
      }
    });
  }
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.data){
      if(this.root){
        this.root.dispose()
        this.root = am5.Root.new(this.id)
        this.buildChart()
      }
    }
  }
  
  
  ngAfterViewInit() {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    this.browserOnly(() => {
      this.root = am5.Root.new(this.id);
      this.buildChart()
      
    })
    
  }
  
  buildChart(){
    if(!this.root) return
    let root = this.root
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
//     root.setThemes([
//       am5themes_Animated.new(root)
//     ]);

//     root.setThemes([
//   am5themes_Responsive.newEmpty(root)
  
// ]);

//     root.setThemes([
//       am5themes_Responsive.new(root)
//     ]);

    
    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(am5percent.PieChart.new(root, {
      
      innerRadius: am5.percent(60),
      layout: root.verticalLayout,
    }));
    
    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series

    /////////////////////////////////// Styles
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: this.valueField,
      categoryField: this.categoryField,
      alignLabels: false
      
    }));

   /*  series.ticks.template.setAll({
      location: 0.5
    }); */

    //change FontSize Chart
    series.labels.template.setAll({
      fontSize: 13,
      fill: am5.color(0x000000),
      text: "{category}",
      maxWidth:95,
      oversizedBehavior: "wrap"
    });

  


    const responsive = ResponsiveTheme.new(root);
    

    responsive.addRule({
      name: "AxisRendererY",
      
      relevant: function(width, height) {
        return width < 480;
      }
     
    });
    
    root.setThemes([
      AnimatedTheme.new(root),
      responsive
    ]); 

    /////////////////////////////////////////
    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(this.data);
    
    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 100);
    
    // Add label
    let label = root.tooltipContainer.children.push(am5.Label.new(root, {
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
      fill: am5.color(0x000000),
      fontSize: 20
      
    }));
  }

  


  ngOnInit(): void {
  }
}
