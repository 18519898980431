import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApexNonAxisChartSeries, ApexChart, ApexResponsive, ApexFill, ApexStroke, ApexStates, ApexLegend, ApexTitleSubtitle, ApexTheme, ApexPlotOptions, ApexDataLabels, ApexAxisChartSeries, ApexXAxis, ApexYAxis, ApexTooltip } from 'ng-apexcharts';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CandidateDashboardService } from 'src/app/services/candidate-dashboard.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
import { getPercentage } from 'src/app/shared/utils/utility';
import { AgeQuantity, CanReDashboardModel } from './models';

type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  fill: ApexFill;
  stroke: ApexStroke;
  states: ApexStates;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  theme: ApexTheme;
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  colors: string[]
};
enum stats{
  published = 1,
  notpublished = 0,
  female = 1,
  male = 2,
  undefined = 3,
  ITLA = 1,
  ITLASD = 2,
  ITLAST = 3
}
type BarOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
  colors: string[]
};


@Component({
  selector: 'app-candidate-registration-dashboard',
  templateUrl: './candidate-registration-dashboard.component.html',
  styleUrls: ['./candidate-registration-dashboard.component.css'],
})
export class CandidateRegistrationDashboardComponent implements OnInit, OnDestroy {
  ageRanges: {text:string, values: number[]}[] = [
    
    { text: '17- años', values: [0, 17] },
    { text: '18 a 25 años', values: [18, 25] },
    { text: '26 a 35 años', values: [26, 35] },
    { text: '36 a 45 años', values: [36, 45] },
    { text: '46 a 55 años', values: [46, 55] },
    { text: '56+ años', values: [56, Number.MAX_VALUE] },
    
  ];
  loadingMessage = 'Cargando...'
  noDataMessage = 'No hay datos'
  genderChart = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }
  nationalityChart = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }
  academicChart = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }
  ageChart = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }
  public chartOptions: Partial<ChartOptions>;
  public barOptions: Partial<BarOptions>;
  public chartOptions2: Partial<ChartOptions>;
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  amountMale: {total: number, percentage: number} = {
    total: 0,
    percentage: 0
  };
  amountFemale: {total: number, percentage: number} = {
    total: 0,
    percentage: 0
  };
  amountTotal : number = 0;
  filtered = false
  filterParams = {
    year: null,
    dateTo: this.datePipe.transform(new Date(),'yyyy-MM-dd'),
    dateFrom: '2022-01-01',
    companyId: this.authInfo.getCompanyId()
  }

  suscriptions = new Subscription()

  public dashboardData: CanReDashboardModel;

  constructor(
      private srvDashboard: CandidateDashboardService,
      public dialogRef: MatDialogRef<CandidateRegistrationDashboardComponent>,
      public dialog: MatDialog,
      private toastr: ToastrService,
      private datePipe: DatePipe,
      private authInfo: AuthInfoService
    ) {
    
      
  }
  ngOnDestroy(): void {
    this.suscriptions.unsubscribe()
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  ngOnInit(): void {
    try{
      this.filterParams.dateFrom = this.datePipe.transform(`${this.authInfo.getConstitutionDate()}`, 'yyyy-MM-dd')
      this.filterParams.dateTo = this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd')
    }catch(e){

    }
    
    this.loadCharts();
    this.getStats()
    // this.getStatsCountry()
    // this.getGender()
    // this.getAgeStats()
    // this.getStatsAcademic()
  }

  filterByDate(): void {
    const dialogRef = this.dialog.open(FilterDashboardComponent, {

      data: {filterParams: {
        date1: this.filterParams.dateFrom,
        date2: this.filterParams.dateTo,
        year: this.filterParams.year,
        companyId: this.filterParams.companyId
      }},
    });
    dialogRef.afterClosed().subscribe((result: {year:any,date1:any,date2:any, applyFilter?:boolean, companyId: any}) => {
      if(!result) return
      this.filtered = result.year != null || result.date1 != null || result.date2 != null;

      if(result?.applyFilter == false){
        return;
      }
      
      this.filterParams.year = result.year
      if(result.date1){
        this.filterParams.dateFrom = result.date1
      }

      if(result.date2){
        this.filterParams.dateTo = result.date2; 
      }
      this.filterParams.companyId = result.companyId

      this.getStats()
      
     
    });
  }

  close(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

  clearFilter(): void {

    if (!this.filtered) {
      return
    }

    this.filterParams = {
      dateFrom: this.datePipe.transform(`01-01-${new Date().getFullYear()}`, 'yyyy-MM-dd'),
      dateTo: this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd'),
      year: null,
      companyId: this.authInfo.getCompanyId()
    }

    this.getStats()
    this.filtered = false;
  }

  getStats(){
    let sub = this.srvDashboard.getTotal(this.filterParams.dateTo,this.filterParams.dateFrom, this.filterParams.companyId).subscribe((res:any) => {
      if(res.succeded){
        
        this.dashboardData = res.singleResultData;

        this.amountTotal = 0;

        this.dashboardData.genderQuantities.forEach((element)=>{
          this.amountTotal = element.value + this.amountTotal;
        });

        this.getGender();
        this.getStatsCountry();
        this.getStatsAcademic();
        this.getAgeStats();

      }else{
        this.toastr.error(res.errors[0])
      }
    }, error => {
      this.toastr.error(this.errorMessage)
    })
    
    this.suscriptions.add(sub)
  }

  getGender(){


    this.genderChart.labels = []
    this.genderChart.series = []
    
    let gendersData = this.dashboardData.genderQuantities;

    if(gendersData.length < 1){
      this.genderChart.status = this.noDataMessage;
      return;
    }

    

    gendersData.forEach((element)=>{
      this.genderChart.labels.push(element.description);
      this.genderChart.series.push(element.value)
    });

  }
  getStatsCountry(){
    this.nationalityChart.labels = []
    this.nationalityChart.series = []

    let countriesData = this.dashboardData.countryQuantities;
    if (countriesData.length < 1) {
      this.nationalityChart.status = this.noDataMessage;
      return;
    }
    
    countriesData.forEach((element) => {
      this.nationalityChart.labels.push(element.description);
      this.nationalityChart.series.push(element.value)
    })


  }
  getStatsAcademic(){
    this.academicChart.labels = []
    this.academicChart.series = []

    let academicData = this.dashboardData.academicLevelQuantities;

    if(academicData.length < 1){
      this.academicChart.status = this.noDataMessage;
      return;
    }
    
    academicData.forEach((element) => {
      this.academicChart.labels.push(element.description);
      this.academicChart.series.push(element.value)
    })

  }
  
  getAgeStats(){

    this.ageChart.labels = []
    this.ageChart.series = []

    let ageData = this.dashboardData.ageQuantities;
    if (ageData.length < 1) { 
      this.ageChart.status = this.noDataMessage; 
      return; 
    }

    

    this.ageRanges.forEach(element => {
      this.ageChart.labels.push(element.text);
      this.ageChart.series.push(this.getStatsByAgeRange(element.values,ageData));
    });

  }
  getStatsByAgeRange(range, arr:AgeQuantity[]):number{
    let value = 0;
    let a = arr.filter(x => parseInt(x.description) >= range[0] && parseInt(x.description) <= range[1]);
    a.forEach((element) => {
      value += element.value;
    })
    return value;
  }

  getPercentage(total: number, count: number){
    return getPercentage(total, count)
  }

  loadCharts(){
    this.chartOptions = {
      series: [],
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#ffffff', '#ffffff', '#ffffff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      stroke: {
        width: 0
      },
      title: {
        text: ""
      },
      labels: [],
      legend: {
        show: true,
        position: 'bottom',
        },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }
      ]
    };
    this.chartOptions2 = {
      series: [5,45],
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
            fontSize: '14px',
            fontWeight: 'bold',
            colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      stroke: {
        width: 0
      },
      title: {
        text: ""
      },
      labels: ["Femenino", "Masculino"],
      fill: {
        colors: ["#f11565", "#0481af"]
      },
      colors: ["#f11565", "#0481af"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
          }
        }
      ]
    };


     this.barOptions = {
      series: [
        {
          name: "Mujeres",
          data: [44, 55, 41, 37, 22, 43, 21]
        },
        {
          name: "Hombres",
          data: [53, 32, 33, 52, 13, 43, 32],
        },


      ],
      chart: {
        type: "bar",
        width: 400,
        height: 300,
        stacked: true
      },
      colors: ["#fb7866", "#6489fb"],
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      title: {
        text: ""
      },
      xaxis: {
        labels: {
          show: false
        },

      },
      yaxis: {
        title: {
          text: undefined
        },
        labels: {
          style: {
            colors: ['#033877'],
            fontSize: '15px',
            fontWeight: "bolder",
            cssClass: 'apexcharts-yaxis-label',
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "K";
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40
      }
    }
  }
}
