import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {

  opened = false
  account = this.accountService.accountValue;
  constructor(private ds: DataService,
              private accountService: AccountService) { }

  ngOnInit(): void {
    this.SubscribeEvents();
  }

  SubscribeEvents(): void {
    this.ds.account$.subscribe(user => {
      this.account = user;
    })
  }
}
