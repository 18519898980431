import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProofApplicantsService } from 'src/app/services/proof-applicants.service';
import { IPostulationTest, PTest } from './models/PostulationTest';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { Subscription } from 'rxjs';
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import Dashboard from '@uppy/dashboard'
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { parameterDoc } from 'src/app/shared/utils/parameterDoc';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { DomSanitizer } from '@angular/platform-browser';
import { docService } from 'src/app/services/doc.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { IResultTests } from './models/ResultTests';
import { IPersonVacant } from './models/PersonVacant';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { openReport } from 'src/app/shared/utils/utility';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-proof-applicants',
  templateUrl: './proof-applicants.component.html',
  styleUrls: ['./proof-applicants.component.css'],
})
export class ProofApplicantsComponent implements OnInit, OnDestroy, AfterViewChecked {
  private readonly urlDoc = environment.api_doc + '/Document';
  public SystemLoad: number = 1;
  public ModuleLoad: number = 2;
  public routeFile: string = "";
  invalidForm: boolean = false;
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  subscriptions: Subscription = new Subscription();
  testHistoryData = [];
  testTypes: any[] = [];
  testResults: IResultTests[] = [];
  form: FormGroup;
  personSelected: IPersonVacant;
  records: PTest[] = [];
  recordsOriginal: PTest[] = [];
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  
  @ViewChild('uppyFile')
  uppyFileComponent: UppyFileComponent | null = null;

  constructor(
    private proofApplicantsService: ProofApplicantsService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private _http: HttpClient,
    private srvParam: ParameterControlService,
    private _route: ActivatedRoute,
    private srvParameter: ParameterControlService,
    private sanitizer: DomSanitizer,
    private srvDoc: docService,
    private _cdRef: ChangeDetectorRef,
    private authInfo: AuthInfoService
  ) { }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  ngOnInit(): void {
    let id = this._route.snapshot.params.id
    this.getPerson(id);

    this.getTypeTests();
    this.getTestScores();

    this.form = this.formBuilder.group({
      typeTest: ['', Validators.required],
      score: ['', Validators.required],
      evaluatorComment: ['', Validators.required],
      cdate: [null, Validators.required],
    });
  }

  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Click para desplegar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  resetForm() {
    this.uppyFileComponent.cleanUppy();
    this.form.get('evaluatorComment').setValue('')
    this.form.get('score').setValue('')
    this.form.get('typeTest').setValue('')
    this.form.get('cdate').setValue('')
  }

  getTypeTests() {
    this.srvParameter.getParameters(keyWord.TypeTestPostulation).subscribe((e: ResponseModel<any>) => {
      if (e.succeded) {
        this.testTypes = e.dataList;
      } else {
        this.toastr.error(e.errors[0])
      }
    })
  }

  deleteTest(test) {
    let data = {
      postulationTestId: test.postulationTestId,
      userId: this.authInfo.getUserId()
    }
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      title: "¿Está seguro que desea eliminar este registro?",
      icon: "question"
    }).then(result => {
      if (result.isConfirmed) {
        if (!this.personSelected) {
          this.toastr.error("Debe seleccionar un postulante", "Postulante no seleccionado")
          return;
        }
        let deleteTestSub = this.proofApplicantsService.deleteTest(data).subscribe((res: any) => {
          if (res.succeded) {
            this.toastr.success("Se ha eliminado la prueba", "Eliminado exitosamente")
            this.getTestRecords()
          } else {
            this.toastr.error(res.errors[0])
          }
        }, (error) => {
          this.toastr.error(this.errorMessage)
        })
        this.subscriptions.add(deleteTestSub)
      }
    })

  }
  getPerson(ppvid) {
    let sub = this.proofApplicantsService.getPersonVacantByPPVID(ppvid, this.authInfo.getCompanyId()).subscribe((data: any) => {
      if (data.succeded) {
        this.personSelected = data.dataList[0];
        if (this.personSelected) {
          this.getTestRecords()
          if (this.personSelected.photo) {
            this.getPersonPhoto(this.personSelected.photo)
          }
        } else {
          this.toastr.warning('No se han encontrado los datos de la persona seleccionada')
        }
      } else {
        this.toastr.error(data.errors[0]);
      }
    }, error => {
      this.toastr.error(this.errorMessage);
    });
    this.subscriptions.add(sub)
  }

  getTestRecords() {
    if (this.personSelected) {
      let sub = this.proofApplicantsService.getPostulationsTestsByPPVID(this.personSelected.ppvid, this.authInfo.getCompanyId()).subscribe((res: ResponseModel<PTest>) => {
        if (res.succeded) {
          this.testHistoryData = res.dataList;
          this.recordsOriginal = res.dataList;
        } else {
          this.toastr.error(res.errors[0])
        }
      }, err => {
        this.toastr.error(this.errorMessage)
      });
      this.subscriptions.add(sub)
    }
  }
  getTestScores() {
    let sub = this.srvParam.getParameters(keyWord.ResultTest).subscribe((res: ResponseModel<IResultTests>) => {
      if (res.succeded) {
        this.testResults = res.dataList;
      } else {
        this.toastr.error(res.errors[0])
      }
    }, error => {
      this.toastr.error(this.errorMessage)
    });
    this.subscriptions.add(sub)
  }

  openGuID(guid) {
    try {
      this.srvDoc.getDocument(guid).subscribe(e => {
        window.open(e.data, '_blank');
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  savePostulation() {
    this.invalidForm = this.form.invalid
    if (this.invalidForm) return;
    if (!this.uppyFileComponent?.existFile) {
      this.toastr.warning("Debe subir una evidencia");
      return;
    }
    this.uppyFileComponent.handleFile((guid: GUID) => {
        let postulationTest: IPostulationTest = {
          postulationTestId: 0,
          postulationId: Number(this.personSelected.ppvid),
          typeTestId: this.form.get('typeTest').value.ocode,
          scoreId: this.form.get('score').value.ocode,
          documentsId: guid,
          condition: 0,
          companyId: this.authInfo.getCompanyId(),
          status: true,
          comment: this.form.get('evaluatorComment').value,
          cdate: this.form.get('cdate').value
        }
        let sub = this.proofApplicantsService.savePostulation(postulationTest).subscribe((res) => {
          if (res.succeded) {
            this.toastr.success('Los datos se han guardado correctamente.');
            this.resetForm();
            this.getTestRecords();
          } else {
            this.toastr.error(res.errors[0])
          }
        }, error => {
          this.toastr.error(this.errorMessage);
        })
        this.subscriptions.add(sub)
    })  ;
  }

  // Mensajes de validaciones
  showTypeTestErrors() {
    let field = this.form.get('typeTest');
    if (field.hasError('required')) {
      return 'Debe seleccionar una opción';
    }
    return '';
  }
  showScoreErrors() {
    let field = this.form.get('score');
    if (field.hasError('required')) {
      return 'Debe seleccionar una opción';
    }
    return '';
  }
  showCdateErrors() {
    let field = this.form.get('cdate');
    if (field.hasError('required')) {
      return 'Debe seleccionar una fecha';
    }
    return '';
  }
  showEvaluatorCommentErrors() {
    let field = this.form.get('evaluatorComment');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }
  getPaginatedRecords(event) {
    this.records = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  getPersonPhoto(photo) {
    this.srvDoc.getDocument(photo).subscribe(e => {
      if (e.succeded) {
        this.personSelected.photoloaded = e.data
      } else {
        this.toastr.error(e.errors[0])
      }
    }, error => {
      this.toastr.error(this.errorMessage)
    })
  }

  openTestResultsReport(testResult) {

    const { postulationId, postulationTestId, companyId } = testResult
    const reportCode = ReportCode.ApplicantsTestResult


    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&PPVId=${postulationId}&PostulationTestId=${postulationTestId}&CompanyId=${companyId}`;

    let parameters = {
      url: reportUrl,
      title: '',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }
}
