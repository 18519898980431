import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderDigitalSignature } from '@payroll/audit-approval/models/headerDigitalSignature.model';
import { IPostHeaderApprove, IPostPayrollReject } from '@payroll/payroll-models';
import { environment } from 'src/environments/environment';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class AuditApprovalService {
  private readonly api = environment.api_url;
  private readonly api_lobby = environment.api_lobby;
  private readonly api_core = environment.api_core_url;

  constructor(
    private http: HttpClient,
    private authInfo: AuthInfoService
  ) { }
  
  
  getHeaderByPeriodTypeArea(periodId: number,typeId: number, areaId: number){
    return this.http.get(this.api + `/Header?HeaderId=0&PayrollPeriodId=${periodId}&PayrollTypeId=${typeId}&BudgetAreaId=${areaId}&type=3&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getHeadersByPeriod(periodId){
    return this.http.get(this.api + `/Header?HeaderId=0&PayrollPeriodId=${periodId}&PayrollTypeId=0&type=1&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getPeriods(type:number = 10){
    return this.http.get(this.api + `/Period?PayrollPeriodId=0&Type=${type}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }
  getPayrollDetails(headerId:number){
    return this.http.get(this.api +  `/Detail/GetOverallSummary?HeaderId=${headerId}&Type=2&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getPayrollDetailResume(headerId: number){
    return this.http.get(this.api +  `/Detail/GetDetailResume?HeaderId=${headerId}&Type=1&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getPayrollDetailByDepartments(headerId: number){
    return this.http.get(this.api +  `/Detail/GetDetailByDeparments?HeaderId=${headerId}&Type=0&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  getHeaderAndSettingsByPeriod(periodId){
    return this.http.get(`${this.api}/Header/GetHeader?HeaderId=0&PayrollPeriodId=${periodId}&PayrollTypeId=0&BudgetAreaId=0&type=5&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  postHeaderApprove(approve: IPostHeaderApprove){
    return this.http.post(`${this.api}/PayrollApprove`, approve)
  }
  postHeaderReject(reject: IPostPayrollReject[]){
    return this.http.post(`${this.api}/PayrollReject`, reject)
  }

  payrollApprove(model){
    return this.http.put(`${this.api}/Header/Approve`, model);
  }

  getDetailsHeaderByDepartment(headerId: number, departmentId: number, typeEmployeeId: number){
    return this.http.get(`${this.api}/Detail/GetOverallSummary?HeaderId=${headerId}&BudgetAreaId=${departmentId}&employeeId=${typeEmployeeId}&Type=8&CompanyId=${this.authInfo.getCompanyId()}&Status=true`)
  }
  
  getLastPayroll(companyId: number){
    return this.http.get(`${this.api}/HomeDashboard/Dashboard?Status=true&CompanyId=${companyId}`);
  }

  getPeriodByPayrollTypeId(PayrollTypeId: number, CompanyId: number, Type = 6){
    return this.http.get(`${this.api}/Period?PayrollPeriodId=0&PayrollTypeId=${PayrollTypeId}&Type=${Type}&CompanyId=${CompanyId}&Status=true`);
  }

  GetDetailPayrollAudit(PayrollPeriodId: string, HeaderId: number, PayrollTypeId: number, CompanyId: number){
    return this.http.get(`${this.api}/Detail/GetDetailPayrollAudit?HeaderId=${HeaderId}&PayrollPeriodId=${PayrollPeriodId}&PayrollTypeId=${PayrollTypeId}&CompanyId=${CompanyId}&Status=true`);
  }

  getTypeEmployeee(HeaderId){
    return this.http.get(`${this.api}/EmployeeType?HeaderId=${HeaderId}&Type=2&CompanyId=${this.authInfo.getCompanyId()}`);
  }

  getPayrollType(){
    return this.http.get(`${this.api_core}/ParameterControl/GetKeyWord?Parameter=PayrollType&CompanyCode=${this.authInfo.getCompanyId()}&Status=true`);
  }

  getCompanyByID(companyCode: number){
    return this.http.get(`${this.api_lobby}/Companies?companyCode=${companyCode}&type=3`);
  }

  getPdfPayrollReport(getPdfPayrollReportModel: any){
    return this.http.post(`${this.api}/Header/GetPdfPayrollReport`, getPdfPayrollReportModel);
  }

  postHeaderDigitalSignature(headerDigitalSignature: HeaderDigitalSignature){
    return this.http.post<ResponseModel<any>>(`${this.api}/HeaderDigitalSignature/`, headerDigitalSignature);
  }

  getHeaderDigitalSignature(HeaderId: number, ProcessId: number, CompanyId: number){
    return this.http.get<ResponseModel<HeaderDigitalSignature>>(`${this.api}/HeaderDigitalSignature/?HeaderDigitalSignatureId=0&HeaderId=${HeaderId}&ProcessId=${ProcessId}&Type=2&CompanyId=${CompanyId}&Status=true`);
  }

  putHeaderDigitalSignature(headerDigitalSignature: HeaderDigitalSignature){
    return this.http.put<ResponseModel<any>>(`${this.api}/HeaderDigitalSignature`, headerDigitalSignature);
  }

  deleteHeaderDigitalSignature(HeaderDigitalSignatureId: number){
    return this.http.delete<ResponseModel<null>>(`${this.api}/HeaderDigitalSignature/?HeaderDigitalSignatureId=${HeaderDigitalSignatureId}`);
  }

}