import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { docService } from 'src/app/services/doc.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { IEmployeePermissions } from '../models/EmployeePermissions';
import { IEmployeeVacancyHistory } from '../models/EmployeeVacancyHistory';
import { IInstitutions } from '../models/Institutions';
import { IAvailableYearsView, IVacancy } from '../models/Vacancy';
import { vacancyCalculations, VacationDaysAllowed } from '../models/vacation-days-allowed.enum';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PermissionType } from 'src/app/shared/models/permissionType.model';
import { IPersonsWorkExperiencesGET } from 'src/app/shared/models/personsWorkExperiences';
import { ShowDocumentModalComponent } from 'src/app/shared/show-document-modal/show-document-modal.component';
import { RejectPermissionModalComponent } from './reject-permission-modal/reject-permission-modal.component';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { openReport } from 'src/app/shared/utils/utility';
import { PauseVacationModalComponent } from './pause-vacation-modal/pause-vacation-modal.component';
import { ModalViewsEnum } from 'src/app/models/modal-views';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
export enum personnelActionTypes {
  license = 1,
  change = 2,
  healthEvent = 3,
  permission = 4,
  banns = 5,
  recognition = 6,
  normalPermission = 7,
  specialPermission = 8,
  externalService = 9,
  commission = 12,
  admissionAppointment = 11,
  dismissal = 13,
  disciplinaryMeasures = 14
}

enum Sex {
  Femenino = 1,
  Masculino = 2
}

export enum DayYear {
  days = 365
}

type GUID = string & { isGuid: true };
@Component({
  selector: 'app-personnel-actions-tap',
  templateUrl: './personnel-actions-tap.component.html',
  styleUrls: ['./personnel-actions-tap.component.css']
})
export class PersonnelActionsTapComponent implements OnInit, OnDestroy, OnChanges {
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'ACTION-CHANGE-EMPLOYEE',
    vacations: { key: 'Vacation-Recurring-Actions', value: false },
    addVacation: { key: 'Add-Vacation-Recurring-Actions', value: false },
    viewPdfVacation: { key: 'View-Pdf-Vacation-Recurring-Actions', value: false },
    editVacation: { key: 'Edit-Vacation-Recurring-Actions', value: false },
    deleteVacation: { key: 'Delete-Vacation-Recurring-Actions', value: false },
    permission: { key: 'Permission-Recurring-Actions', value: false },
    addPermission: { key: 'Add-Permission-Recurring-Actions', value: false },
    editPermission: { key: 'Edit-Permission-Recurring-Actions', value: false },
    viewPdfPermission: { key: 'View-Pdf-Permission-Recurring-Actions', value: false },
    approvePermission: { key: 'Approve-Permission-Recurring-Actions', value: false },
    rejectPermission: { key: 'Reject-Permission-Recurring-Actions', value: false },
    licenses: { key: 'Licenses-Recurring-Actions', value: false },
    externalService: { key: 'External-Service-Recurring-Actions', value: false },
    serviceCommission: { key: 'Service-Commission-Recurring-Actions', value: false },
    bans: { key: 'Ban-Recurring-Actions', value: false },
    disciplinaryActions: { key: 'Disciplinary-Recurring-Actions', value: false },
  }
  permissionDateInvalid = false
  cumulativeYears = 0
  correspondingDays = 0
  dateSelectedError = ''
  exceededDaysError = ''
  selectedVacationForEditing: any;
  permissionHasHours: boolean = false
  permissionToRejectId = 0
  reasonToReject = ''
  commissions = []
  minDateAllow = ''
  banns = []
  showRejectReasonError = false;
  private readonly urlDoc = environment.api_doc + '/Document';
  experienceYears: number = 0
  servicesList = []
  guiID = '';
  licenseGuiID = '';
  file = { document: '', documentLicense: '' }
  personalFile = personalFile;
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  licenses = []

  permissionDaysInvalid = false
  hoursIsRequired = false;
  maxDaysAvailable = 0

  vacancyId: number = 0;
  personSelected: any
  vacancySelected: any;
  isEditingVacancy: boolean = false;
  isInDetailsVacancy: boolean = false;
  subscriptions: Subscription = new Subscription();
  vacancy: IVacancy;
  employeePermissions: IEmployeePermissions;
  vacationForm: FormGroup;
  permissionForm: FormGroup;
  localStorageTab = 'personnel-actions-tab-selected'
  externalServiceForm: FormGroup;
  employeeDataForm: FormGroup;
  vacationFormInvalid: boolean = false;
  employeeId: number = 0;
  @Input() employeeNumber: number = 0;
  FilterOptions: any[] = [
    { ocode: -1, stringData: "Seleccionar" }
  ];

  totals = []
  vacationYears: IAvailableYearsView[] = [];
  currentVacationCounter: any;
  employeeVacationHistory: {
    vacationId: number, year: number, daysUsed: number, totalDaysAllowed: number,
    employeeVacationHistoryDates: [{ startDate: Date, endDate: Date }?]
  }[] = [];

  permissionFormInvalid: boolean = false;
  historyWork: IPersonsWorkExperiencesGET[] = [];
  historyWorkRecords: IEmployeeVacancyHistory[] = []
  vacationData: IVacancy[] = [];
  licenseData: any[] = [];
  licenseFormInvalid: boolean = false;
  historyForm: FormGroup;
  institutions: IInstitutions[] = [];

  listPermisos: IEmployeePermissions[] = [];

  licensesConfig: any = {
    displayKey: 'name',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  }

  licensesOptions: any[] = [];

  noWorkingDays: { date: string, celebration: string; country: string, countryShortName: string; }[] = [];
  noWorkingDayMatchWithDates: { date: string, celebration: string; country: string, countryShortName: string; }[] = [];

  dropdownConfigPermision = {
    displayKey: 'name',
    search: true,
    height: 'auto',
    placeholder: 'Seleccionar',
    moreText: '...',
    noResultsFound: 'No se han encontrado registros',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'name'
  }

  reasonsConfig = {
    displayKey: 'stringData',
    search: true,
    height: 'auto',
    placeholder: 'Seleccionar',
    moreText: '...',
    noResultsFound: 'No se han encontrado registros',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'stringData'
  }

  daysAvailables = 0
  checkboxRecurrent: boolean = false;

  typePermisions: PermissionType[] = [];
  modificationReasons: ParameterControl[] = [];

  checkPermissionRecurrent: boolean = false;


  dateStartIsGreaterThanEndDate = ''
  isAbleForVacationError = ''

  /* daysAllowedIsInvalid = false */
  @Input() isSeviceCommission: boolean = false;
  @Input() isSuspended: boolean = false;
  isEditingPermission: boolean = false;
  currentPermissionConditionOnEditting!: number;
  currentPermissionEdittingIsWithoutPayroll: boolean = false;
  yearDropdownConfig;

  viewTypes = ModalViewsEnum;

  @ViewChild('uppyFilePermission')
  uppyFilePermission: UppyFileComponent | null = null;
  constructor(
    private fBuilder: FormBuilder,
    private toastr: ToastrService,
    private parameterControlService: ParameterControlService,
    private _http: HttpClient,
    private dialog: MatDialog,
    private personnelActionsService: PersonnelActionsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private docService: docService,
    public authInfo: AuthInfoService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSeviceCommission']?.currentValue == true || changes['isSuspended']?.currentValue == true) {
      this.disableButtonsAndInputs();
    }
  }

  disableButtonsAndInputs() {
    this.employeeDataForm.disable();
    this.permissionForm.disable();
    this.vacationForm.disable();
    this.historyForm.disable();
    var buttons: any = document.getElementsByClassName('btn');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.dropdownConfig()
    this.setPrivileges()
    this.builEmployeeDataForm();
    this.buildVacationForm();
    this.buildPermissionForm();
    this.activatedRoute.params.subscribe((params: any) => {
      this.employeeId = params.employeeId;
      this.getEmployeeByEmployeeId(this.employeeId)

    })
    //this.getInstitutions()
    this.getParameterControl();
    this.getNoWorkingDays();
    this.getTypePermision();
    // this.activatedRoute.params.subscribe((params:any) => {
    //   if(isNaN(params.id)){
    //     //this.router.navigate(['/personnel-actions-employee-list'])
    //     return;
    //   }
    //   this.employeeNumber = params.id;

    //})

    this.disablePermissionDays(false)

    this.historyForm = this.fBuilder.group({
      institution: [null, Validators.required],
      years: [0, Validators.pattern(/^(0|[1-9][0-9]*)$/)],
      days: [{ value: 0, disabled: true }]
    })
  }

  setPrivileges() {
    this.privilege = this.authInfo.setPrivileges(this.privilege);
  }

  changeDay() {
    this.permissionDaysInvalid = this.isDaysOfWeekInvalid()
  }
  getTypePermision() {
    this.personnelActionsService.getTypePermision().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastr.error(res.errors[0]);
        return;
      }
      this.typePermisions = res?.dataList?.filter((p: { status: boolean; }) => p?.status);
    }, error => {
      this.toastr.error('Ocurrió un error trantando de obtener los tipos de permisos, por favor intente más tarde o comuníquese con HelpDesk.');
    })
  }

  get getPermisionType() {
    return this.permissionForm?.get('idTypePermissions')?.value as PermissionType;
  }

  get getDateStart() {
    return this.permissionForm?.get('dateStart')?.value as Date;;
  }

  handleDateStartChange() {
    const value = this.permissionForm.get("dateStart").value
    if (value) {
      this.permissionForm.controls["dateEnd"].setValue(value)
    }
  }

  getMinDate(date) {
    let d = new Date(date);
    d.setDate(d.getDate() + 1);
    this.minDateAllow = d.toLocaleDateString('fr-CA')
    return d.toLocaleDateString('fr-CA');
  }

  get getMaxDate() {
    const typePermision = this.getPermisionType;

    if (typePermision?.id === 0 || !typePermision?.id) return "";

    let d = new Date(this.getDateStart);
    d.setDate(d.getDate() + typePermision.daysAllowed);
    return d.toLocaleDateString('fr-CA');

  }

  getNoWorkingDays() {
    let sub = this.personnelActionsService.getHolidays().subscribe((response) => {
      if (response.succeded) {
        this.noWorkingDays = response.dataList.filter(x => x.status)
          .sort((a, b) => {
            return a.month - b.month
          }).map((noWorkingDay) => {
            return {
              date: `${noWorkingDay.year}-${noWorkingDay.month < 10 ? '0'.concat(noWorkingDay.month.toString()) : noWorkingDay.month}-${noWorkingDay.day}`,
              celebration: noWorkingDay.celebration,
              country: noWorkingDay.names,
              countryShortName: noWorkingDay.alfanumeric3
            };
          });

      }
    });

    this.subscriptions.add(sub);
  }

  getParameterControl() {
    this.parameterControlService.getParameters(keyWord.ReasonsToModifyPersonalAction).subscribe({
      next:(res)=>{
        if(!res.succeded){
          res.errors.forEach(err=>{
            this.toastr.error(err);
          })

          res.warnings.forEach(warn=>{
            this.toastr.warning(warn);
          })

          return;
        }

        this.modificationReasons = res.dataList;
      },
      error: (err)=>{
        this.toastr.error('Ha ocurrido un error inesperado consultado la lista de razones de modificacion')
      }
    })
  }
 
  setEvidencePermission(event){
    this.permissionForm.get('evidence').setValue(event);
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  setGuID(guid) {
    try {
      this.docService.getDocument(guid).subscribe(e => {
        this.file.document = e.data
        this.guiID = guid;
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  openGuID(guid) {
    try {
      this.docService.getDocument(guid).subscribe(e => {
        window.open(e.data, '_blank');
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  dropdownConfig() {
    this.yearDropdownConfig = {
      displayKey: 'periodDescription',
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: 'periodDescription'
    }
  }
  builEmployeeDataForm() {
    this.employeeDataForm = this.fBuilder.group({
      employeeName: '',
      employeeNumber: '',
      employeeType: '',
      department: '',
      position: ''
    })
  }

  getEmployeeByEmployeeId(employeeId) {
    this.personnelActionsService.getEmployeeByEmployeeId(employeeId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastr.error("Ha ocurrido un error al obtener los datos del Servidor Público")
        return;
      }
      this.personSelected = res.dataList[0];
      if (this.personSelected.status == false || (this.isSeviceCommission == true || this.isSuspended == true)) {
        this.disableButtonsAndInputs();
      }

      this.employeeId = this.personSelected.employeeId;
      this.employeeDataForm.get('employeeName').setValue(this.personSelected.name)
      this.employeeDataForm.get('employeeNumber').setValue(this.personSelected.employNumber)
      this.employeeDataForm.get('employeeType').setValue(this.personSelected.employeeType)
      this.employeeDataForm.get('department').setValue(this.personSelected.departmentName)
      this.employeeDataForm.get('position').setValue(this.personSelected.positionName)
      if (this.personSelected?.status) {
        this.vacationForm.get('applicationDate').patchValue(new Date().toISOString().substring(0, 10))
        this.permissionForm.get('applicationDate').patchValue(new Date().toISOString().substring(0, 10))
      }

      this.getHistoryWork();
      this.getPemisos();




    }, error => {
      this.toastr.error("Error al buscar el Servidor Público")
      this.router.navigate(['/personnel-actions-employee-list'])
      return;
    })
  }

  getVacationAvailableYears() {

    const employeeId = this.employeeId

    this.personnelActionsService.getEmployeeAvailableVacationYears(employeeId, this.personSelected?.companyId, 0).subscribe({
      next: (res) => {

        if (!res.succeded) {

          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(warn => {
            this.toastr.error(warn);
          })

          return;
        }

        this.vacationYears = res.dataList.map(availableYear => {
          if (availableYear?.employeeId == employeeId) {
            return {
              periodDescription: `${availableYear.year}-${availableYear.yearTo}`,
              ...availableYear
            };
          }
        })

        this.getCumulativeAndDaysCorresponding()

        if (this.isEditingVacancy) {
          this.changeYear();

          this.vacationYears.forEach(v => {
            if (v.year == this.selectedVacationForEditing?.year) {
              v.availableDays = v.availableDays + (this.selectedVacationForEditing?.days ?? 0);
              v.daysTaken = v.daysTaken - (this.selectedVacationForEditing?.days ?? 0);
            }
          })
        }



      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error tratando de consultar la lista de años disponibles para solicitar vacaciones');
      }
    })
  }

  refreshLicenses(doIt: boolean){

    if(doIt){
      this.getPemisos();
    }

  }

  setTabSelected(tab: string) {
    localStorage.setItem(this.localStorageTab, tab)
  }
  getPemisos() {
    this.personnelActionsService.getEmployeePermissionsByIdEmployee(this.employeeId).subscribe((res) => {

      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        });

        res.warnings.forEach(err => {
          this.toastr.warning(err);
        })

        return;
      }

      this.listPermisos = res.dataList.filter((e) => {

        e.hourStartText1 = e.hourStart ? this.convert(e.hourStart) : 'N/A'
        e.hourEndText1 = e.hourEnd ? this.convert(e.hourEnd) : 'N/A'

        return e.typePersonalActionId === 4 || e.typePersonalActionId === 7
          || e.typePersonalActionId === 8
      });

      this.servicesList = res.dataList.filter((e) => e.typePersonalActionId == personnelActionTypes.externalService);

      this.licenses = res.dataList.filter(x => x.typePersonalActionId == personnelActionTypes.license)

      const today = moment().startOf('date')

      for (let i = 0; i < this.licenses.length; i++) {
        const element = this.licenses[i];
        const dateStart = moment(element.dateStart).startOf('date')
        element.ableEdit = dateStart > today

      }
      this.commissions = res.dataList.filter(x => x.typePersonalActionId == personnelActionTypes.commission)
      this.banns = res.dataList.filter(x => x.typePersonalActionId == personnelActionTypes.banns)



    })
  }
  showDocument(document: string, title: string) {
    this.dialog.open(ShowDocumentModalComponent, {
      width: '50%',
      data: {
        src: document,
        title
      }
    })
  }

  changeTypePermison(type: PermissionType, isEdit) {
    const requiredPermision = document.getElementById('requiredPermision');
    const form = this.permissionForm

    if(!isEdit){
      form.get('dateStart').patchValue(null)
      form.get('dateEnd').patchValue(null)
    }else{
      const maxDate = this.getMaxDate;
      const currentEndDate = form.get('dateEnd')?.value;

      if(!currentEndDate || (moment(currentEndDate).startOf('date') > moment(maxDate).startOf('date'))){
        form.get('dateEnd').patchValue(maxDate);
      }

    }

    if (!type?.id) {
      this.permissionForm.get('idTypePermissions').setValue(null);
    } else {
      if (type?.requiresEvidence) {
        this.permissionForm.get('evidence').setValidators(Validators.required);
        this.permissionForm.get('evidence').updateValueAndValidity();
      } else {
        this.permissionForm.get('evidence').setValidators(null);
        this.permissionForm.get('evidence').updateValueAndValidity();
      }
      if (requiredPermision) {
        requiredPermision.innerHTML = type?.requiresEvidence ? '*' : '';
      }

      if (type?.hoursAllowed > 0) {
        this.permissionHasHours = true
        this.permissionForm.controls["hourStart"].enable()
        this.permissionForm.controls["hourEnd"].enable()
        this.permissionForm.controls["hourStart"].setValidators([Validators.required])
        this.permissionForm.controls["hourEnd"].setValidators([Validators.required])
        this.permissionForm.get('hourStart').updateValueAndValidity();
        this.permissionForm.get('hourEnd').updateValueAndValidity();
        this.hoursIsRequired = true
      } else {
        this.permissionHasHours = false
        this.hoursIsRequired = false
        this.permissionForm.controls["hourEnd"].disable()
        this.permissionForm.controls["hourStart"].disable()
        this.permissionForm.controls["hourEnd"].setValue("")
        this.permissionForm.controls["hourStart"].setValue("")
        this.permissionForm.controls["hourStart"].setValidators(null)
        this.permissionForm.controls["hourEnd"].setValidators(null)
        this.permissionForm.get('hourStart').updateValueAndValidity();
        this.permissionForm.get('hourEnd').updateValueAndValidity();
      }

      if (!type?.requiredMotive) {
        this.permissionForm.get('reason').setValue('');
        this.permissionForm.get('reason').setValidators(null);
        this.permissionForm.get('reason').updateValueAndValidity();
      } else {
        this.permissionForm.get('reason').setValidators(Validators.required);
        this.permissionForm.get('reason').updateValueAndValidity();
      }

    }
  }

  changeModificationReason(value: ParameterControl){
    this.permissionForm.get('newModificationReasonId').setValue(value?.ocode);
  }


  changeCheckboxPermision(value) {
    this.checkPermissionRecurrent = value.target.checked;
    this.disablePermissionDays(value.target.checked);
    this.changeDay()
  }

  disablePermissionDays(value) {
    /* Esta función se utiliza para deshabilitar los campos de días de la semana en permisos */
    if (!value) {
      this.permissionForm.get('monday').disable();
      this.permissionForm.get('tuesday').disable();
      this.permissionForm.get('wednesday').disable();
      this.permissionForm.get('thursday').disable();
      this.permissionForm.get('friday').disable();
      this.permissionForm.get('saturday').disable();
      this.permissionForm.get('sunday').disable();
    } else {
      this.permissionForm.get('monday').enable();
      this.permissionForm.get('tuesday').enable();
      this.permissionForm.get('wednesday').enable();
      this.permissionForm.get('thursday').enable();
      this.permissionForm.get('friday').enable();
      this.permissionForm.get('saturday').enable();
      this.permissionForm.get('sunday').enable();
    }
    this.permissionForm.get('monday').setValue(false);
    this.permissionForm.get('tuesday').setValue(false);
    this.permissionForm.get('wednesday').setValue(false);
    this.permissionForm.get('thursday').setValue(false);
    this.permissionForm.get('friday').setValue(false);
    this.permissionForm.get('saturday').setValue(false);
    this.permissionForm.get('sunday').setValue(false);
  }

  convert(hora) {
    return (new Date("1955-11-05T" + hora + "Z")).toLocaleTimeString("bestfit", {
      timeZone: "UTC",
      hour12: !0,
      hour: "numeric",
      minute: "numeric"
    });
  };


  // Inicio de pantalla de permisos
  employeeIsInVacations(): boolean {
    if (this.vacationData.length < 1) {
      return false
    }
    const vacations = this.vacationData[0]
    const endDate = new Date(vacations.dateEnd)
    const dateStart = new Date(vacations.dateStart)
    const today = new Date()
    if (today <= endDate) {
      return true
    }
    if (today >= dateStart) {
      return true
    }
    return false
  }

  dateIsTaken(date1, date2) {
    const permissionsId = this.permissionForm?.get('permissionsId')?.value;
    let dateStart = new Date(date1 + 'T00:00:00')
    let dateEnd = new Date(date2 + 'T00:00:00')
    const permissions = [...this.listPermisos].filter(s => s.permissionsId !== permissionsId)
    permissions.forEach(v => {

      let dStart = new Date(v.dateStart)
      let dEnd = new Date(v.dateEnd)

      //rango de fecha interior
      if (dateStart >= dStart && dateStart <= dEnd) {
        return true
      }
      if (dateEnd >= dStart && dateEnd <= dEnd) {
        return true
      }
      //rango de fecha exterior
      if (dateStart <= dStart && dateEnd >= dEnd) {
        return true
      }

    })
    return false;
  }

  isDaysOfWeekInvalid() {
    if (!this.checkPermissionRecurrent) {
      return false;
    }
    const { monday, tuesday, wednesday, thursday, friday, saturday } = this.permissionForm.value;
    return !([monday, tuesday, wednesday, thursday, friday, saturday].some(value => value));
  }


  savePermission() {
    const dateStart1 = moment(this.permissionForm.get('dateStart').value).startOf('date')
    const dateEnd1 = moment(this.permissionForm.get('dateEnd').value).startOf('date')
    this.permissionDateInvalid = dateStart1 > dateEnd1
    
    if (this.permissionDateInvalid) return

    if (this.permissionForm?.invalid) {
      this.permissionFormInvalid = true;
      this.toastr.warning('Por favor llene los campos obligatorios')
      return;
    }
    this.permissionFormInvalid = false;
    const { dateStart, dateEnd } = this.permissionForm.value
    if (this.dateIsTaken(dateStart, dateEnd)) {
      this.toastr.warning('Ya tiene un permiso registrado en la fecha seleccionada')
      return
    }

    if (this.employeeId == 0) {
      this.toastr.warning("Debe seleccionar un Servidor Público", "Aviso")
      return;
    }


    if (this.getPermisionType?.appliesWomen && this.personSelected?.sexId === Sex.Masculino) {
      this.toastr.warning('Este tipo de permiso solo aplica para personal femenino');
      return;
    }

    if (!this.validateDate(this.permissionForm)) {
      return;
    }

    this.permissionDaysInvalid = this.isDaysOfWeekInvalid()
    if (this.permissionDaysInvalid) {
      this.toastr.warning("Debe seleccionar almenos un dia de la semana")
      return
    }

    if (this.permissionForm.invalid && this.permissionForm.get("idTypePermissions").value?.requiredMotive == true) {
      this.permissionFormInvalid = true;
      this.toastr.warning("Debe llenar todos los campos", "Aviso")
      return;
    }


    this.permissionFormInvalid = false;

    if (this.canHavePermision()) {
      return
    }

    this.uppyFilePermission.handleFile(guid => {
      this.employeePermissions = {
        ...this.permissionForm.value,
        employeeId: this.employeeId,
        companyId: this.authInfo.getCompanyId(),
        status: true,
        permissionsId: this.permissionForm?.value?.permissionsId ?? 0,
        evidence: guid,
        typePermissionsId: this.getPermisionType?.id,
        conditionId: this.isEditingPermission ? this.permissionForm?.value?.conditionId : this.getPermisionType?.requiresApproval === true ? 6 : 2,
        typePersonalActionId: this.checkPermissionRecurrent ? 8 : 4,
        createUserId: this.isEditingPermission ? this.permissionForm.value?.createUserId : this.authInfo.getUserId(),
        modifyUserId: this.authInfo.getUserId(),
        modificationComment: this.permissionForm?.value?.newModificationComment,
        modificationReasonId: this.permissionForm?.value?.newModificationReasonId,
      }
      if (this.isEditingPermission) {
        this.updateEmployeePermission();
      } else {
        this.saveEmployeePermission();
      }
    });
  }

  private saveEmployeePermission() {
    let saveEmployeePermissionSub = this.personnelActionsService.saveEmployeePermission(this.employeePermissions).subscribe({
      next: (res: ResponseModel<any>) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          });

          res.warnings.forEach(err => {
            this.toastr.warning(err);
          })

          return;
        }
        this.toastr.success("Se ha registrado el permiso correctamente", "Registro guardado")
        this.clearPermission(true)
      }, error: res => {
        this.toastr.error("Error al guardar el registro", "Error inesperado");
      }
    });
    this.subscriptions.add(saveEmployeePermissionSub)
  }

  private updateEmployeePermission() {
    let saveEmployeePermissionSub = this.personnelActionsService.updateEmployeePermission(this.employeePermissions).subscribe({
      next: (res: ResponseModel<any>) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          });

          res.warnings.forEach(err => {
            this.toastr.warning(err);
          })

          return;
        }
        this.toastr.success("Registro modificado exitosamente", "Registro Modificado");
        this.clearPermission(true)
      }, error: res => {
        this.toastr.error("Error al modificar el registro", "Error inesperado")
      }
    });
    this.subscriptions.add(saveEmployeePermissionSub)
  }

  clearPermission(reloadList: boolean = false) {
    this.permissionForm.reset();
    this.disablePermissionDays(false);
    this.checkPermissionRecurrent = false;
    this.uppyFilePermission.cleanUppy();
    this.file.document = '';
    this.guiID = '';

    if(reloadList){
      this.getPemisos();
    }

    this.isEditingPermission = false;
    this.permissionForm.get('newModificationReason').clearValidators();
    this.permissionForm.get('newModificationReason').updateValueAndValidity();
  }

  checkDaysAllowedDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateEndFormated = this.setDate(control.value);
      const getMaxDate = this.setDate(this.getMaxDate);
      if (dateEndFormated > getMaxDate) {
        return { daysAllowed: control.value }
      } else {
        return null;
      }
    };
  }
  /* validateDaysAllowed(){
    const dateStart = this.permissionForm.value.dateStart
    const dateEnd = this.permissionForm.value.dateEnd
    const daysAllowed = this.permissionForm.value.idTypePermissions.daysAllowed
    if(!dateEnd){
      console.log('dateEnd invalido')
      return
    }
    if(!dateStart){
      console.log('dateStart invalido')
      return
    }
    const daysDiff = moment(dateEnd).diff(moment(dateStart))
    if(daysDiff <= daysAllowed){
      this.daysAllowedIsInvalid = false
    }else{
      this.daysAllowedIsInvalid = true
    }
  } */

  private setDate(date1) {
    if (!date1) return;
    return formatDate(date1, 'yyyy-MM-dd', 'en_US')
  }

  private validateFormatDate(date: any) {
    let date2 = new Date(date);
    date2.setDate(date2.getDate() + 1);
    return date2.getFullYear() > 9999 || date2.getDay() > 31 || date2.getMonth() > 12;
  }

  validateDate(form: FormGroup) {
    const dateStart = this.setDate(form.get('dateStart').value);
    const dateEnd = this.setDate(form.get('dateEnd').value);
    const applicationDate = this.setDate(form.get('applicationDate').value);

    if (this.validateFormatDate(applicationDate)) {
      this.toastr.warning('La Fecha de Solicitud es Invalida');
      return false;
    }

    if (this.validateFormatDate(dateStart)) {
      this.toastr.warning('La Fecha de Inicio es Invalida');
      return false;
    }

    if (this.validateFormatDate(dateEnd)) {
      this.toastr.warning('La Fecha de Término es Invalida');
      return false;
    }

    return true;
  }

  canHavePermision() {
    const dateStart = this.setDate(this.permissionForm.get('dateStart').value);
    const dateEnd = this.setDate(this.permissionForm.get('dateEnd').value);

    let hasVacation = this.vacationData
      .filter((vacantion: any) => vacantion.employeeId == this.employeeId)
      .some(vacantion => (this.setDate(vacantion.dateStart) >= dateStart && this.setDate(vacantion.dateEnd) <= dateEnd)
        || dateStart == this.setDate(vacantion.dateEnd) || dateEnd == this.setDate(vacantion.dateEnd)
        || dateStart == this.setDate(vacantion.dateStart) || dateEnd == this.setDate(vacantion.dateStart)
        || (dateStart >= this.setDate(vacantion.dateStart) && dateStart <= this.setDate(vacantion.dateEnd))
        || (dateEnd >= this.setDate(vacantion.dateStart) && dateEnd <= this.setDate(vacantion.dateEnd)));

    let hasLicense = this.licenses
      .filter((license: any) => license.employeeId == this.employeeId)
      .some(license => (this.setDate(license.dateStart) >= dateStart && this.setDate(license.dateEnd) <= dateEnd)
        || dateStart == this.setDate(license.dateEnd) || dateEnd == this.setDate(license.dateEnd)
        || dateStart == this.setDate(license.dateStart) || dateEnd == this.setDate(license.dateStart)
        || (dateStart >= this.setDate(license.dateStart) && dateStart <= this.setDate(license.dateEnd))
        || (dateEnd >= this.setDate(license.dateStart) && dateEnd <= this.setDate(license.dateEnd)));

    if (hasVacation || hasLicense) {
      if (hasVacation) {
        this.toastr.warning('Este Servidor Público tiene un periodo de vacación activa, no se le puede aplicar un permiso')
      }

      if (hasLicense) {
        this.toastr.warning('Este Servidor Público tiene una licencia activa, no se le puede aplicar un permiso')
      }
      return true
    }

    return false

  }

  rejectPermission() {
    if (!this.reasonToReject) {
      this.showRejectReasonError = true
      return
    } else {
      this.showRejectReasonError = false
    }
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      title: "¿Está seguro que desea rechazar este permiso?",
      icon: "question",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: '#f64e60'
    }).then(res => {
      if (res.isConfirmed) {
        const permissionReject = {
          permissionsId: this.permissionToRejectId,
          reasonReject: this.reasonToReject
        }
        this.personnelActionsService.rejectPermission(permissionReject).subscribe((res: any) => {
          if (res.succeded) {
            this.toastr.success('Permiso rechazado')
            this.getPemisos()
            document.getElementById('btnClose').click()
          } else {
            this.toastr.error(res.errors[0])
          }
        }, err => {
          this.toastr.error('Ha ocurrido un error inesperado')
        })
      }
    })
  }
  toReject(item) {
    const { permissionsId, conditionId } = item
    this.permissionToRejectId = permissionsId
    this.reasonToReject = ''
  }

  rejectPermissionModal(item) {
    this.dialog.open(RejectPermissionModalComponent, {
      data: { item },
      width: '30%'
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (!res) return
        if (res?.success) {
          this.getPemisos()
        }
      }
    })
  }

  approvePermission(item) {
    const { permissionsId, conditionId } = item
    //if(conditionId != 6) return
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      title: "¿Está seguro que desea aprobar este permiso?",
      icon: "question",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: '#15c229'
    }).then(res => {
      if (res.isConfirmed) {
        this.personnelActionsService.approvePermission(permissionsId).subscribe((res: any) => {
          if (res.succeded) {
            this.getPemisos()
            this.toastr.success("El permiso ha sido aprobado")
          } else {
            this.toastr.error(res.errors[0])
          }
        }, err => {
          this.toastr.error("Ha ocurrido un error inesperado")
        })
      }
    })

  }

  buildPermissionForm() {
    this.permissionForm = this.fBuilder.group({
      permissionsId: [0],
      reason: [''],
      comment: [''],
      applicationDate: [null, Validators.required],
      dateStart: [null, Validators.required],
      dateEnd: [null, [Validators.required, this.checkDaysAllowedDate()]],
      hourStart: [null, Validators.required],
      hourEnd: [null, Validators.required],
      idTypePermissions: [null, Validators.required],
      conditionId: [0],
      monday: [false],
      tuesday: [false],
      wednesday: [false],
      thursday: [false],
      friday: [false],
      saturday: [false],
      sunday: [false],
      evidence: [''],
      createUserId: 0,
      newModificationComment: [null],
      newModificationReason: [null],
      newModificationReasonId: [null],
      oldModificationComment: [null],
      oldModificationReason: [null]
    })

    this.permissionForm.controls["hourEnd"].disable()
    this.permissionForm.controls["hourStart"].disable()

  }


  buildVacationForm() {
    this.vacationForm = this.fBuilder.group({
      vacancyId: [0],
      applicationDate: [null, [Validators.required]],
      dateStart: ['', [Validators.required]],
      dateEnd: ['', [Validators.required]],
      period: ['', [Validators.required]],
      days: [0, [Validators.min(1)]],
      bond: ["false"],
      comment: [''],
      newModificationComment: [null],
      newModificationReason: [null],
      newModificationReasonId: [null],
      oldModificationComment: [null],
      oldModificationReason: [null]
    },);
    this.vacationForm.get('days').disable();
  }
  resetVacationForm() {
    this.vacationForm.enable()
    this.vacationForm.patchValue({
      vacancyId: 0,
      applicationDate: '',
      dateStart: '',
      dateEnd: '',
      period: null,
      days: '',
      comment: '',
      newModificationComment: null,
      newModificationReason: null,
      newModificationReasonId: null,
      oldModificationComment: null,
      oldModificationReason: null
    });
    this.noWorkingDayMatchWithDates = [];
    this.vacationForm.get('days').disable();
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  // llena las dos tablas que estan en vacaciones
  getVacationTablesData() {

    let employeeVacanciesSub = this.personnelActionsService.getEmployeeVacancies(this.personSelected?.employeeId).subscribe((res: any) => {

      if (!res.succeded) {

        res.errors.forEach(err => {
          this.toastr.error(err);
        })

        res.warnings.forEach(warn => {
          this.toastr.error(warn);
        })

        return;
      }

      this.vacationData = (res.dataList != null && res.dataList.length > 0) ? res.dataList.filter(x => x.status) : [];
      this.employeeVacationHistory = [];
      //console.log("Vacaciones tomadas", this.vacationData)
      let arrWithoutDuplicates = this.removeDuplicates(this.vacationData, 'year');
      //console.log(arrWithoutDuplicates)
      arrWithoutDuplicates.filter(x => x.status).forEach(vacation => {
        this.employeeVacationHistory.push({
          vacationId: vacation.vacancyId,
          year: vacation.year,
          daysUsed: this.vacationData.filter(x => x.year == vacation.year && x.status).reduce((a, b) => a + b.days, 0) ?? 0,
          totalDaysAllowed: VacationDaysAllowed.Year,
          employeeVacationHistoryDates: []
        });

        this.vacationData.filter(x => x.year == vacation.year && x.status).forEach(vacation => {
          let { dateStart, dateEnd } = vacation;
          this.employeeVacationHistory.find(x => x.year == vacation.year).employeeVacationHistoryDates.push({
            startDate: dateStart,
            endDate: dateEnd
          })
        });
      });

      this.currentVacationCounter = this.employeeVacationHistory[0];

      this.getVacationAvailableYears();

    }, (error) => {
      this.toastr.error("Error al listar las vacaciones", "Error inesperado");
    })
    this.subscriptions.add(employeeVacanciesSub)

  }
  clearVacancyForm(reloadVacationYears: boolean = true) {
    this.changeDisableVacancyForm(false)
    this.resetVacationForm();
    this.isInDetailsVacancy = false;
    this.selectedVacationForEditing = null;
    this.vacancySelected = null;
    this.dateSelectedError = '';
    this.exceededDaysError = '';
    this.isEditingVacancy = false;

    if(reloadVacationYears){
      this.getVacationAvailableYears()
    }

    this.vacationForm.get('newModificationReason').clearValidators();
    this.vacationForm.get('newModificationReason').updateValueAndValidity();
    
  }
  getVacancy(item) {
    this.personnelActionsService.getVancacyById(item.vacancyId).subscribe((res: any) => {
      //console.log(res);
      if (!res.succeded) {

        res.errors.forEach(err => {
          this.toastr.error(err);
        })

        res.warnings.forEach(warn => {
          this.toastr.error(warn);
        })

        return;
      }

      const vacancySelected = res.dataList[0];
      this.vacancySelected = vacancySelected;

      const modificationReason = this.modificationReasons?.find(m => m.ocode == vacancySelected?.modificationReasonId);
      
      this.vacationForm.patchValue({
        vacancyId: item?.vacancyId,
        applicationDate: formatDate(vacancySelected?.applicationDate, "yyyy-MM-dd", "en"),
        dateStart: formatDate(vacancySelected?.dateStart, "yyyy-MM-dd", "en"),
        dateEnd: formatDate(vacancySelected?.dateEnd, "yyyy-MM-dd", "en"),
        days: vacancySelected?.days,
        comment: vacancySelected?.comment,
        period: {
          periodDescription: `${vacancySelected?.year}-${vacancySelected?.yearTo}`,
          year: vacancySelected?.year,
          yearTo: vacancySelected?.yearTo,
          employeeId: vacancySelected?.employeeId
        },
        oldModificationComment: vacancySelected?.modificationComment,
        oldModificationReason: modificationReason
      });

      this.daysRequested();

      if (this.isEditingVacancy) {
        this.getVacationAvailableYears();
      }

    }, error => {
      this.toastr.error("Error al obtener los detalles de la vacaciones")
    })
  }
  showVacancy(item) {
    this.isEditingVacancy = false;
    this.isInDetailsVacancy = true;
    this.getVacancy(item)
    this.changeDisableVacancyForm();
  }
  changeDisableVacancyForm(disable: boolean = true) {
    if (disable) {
      this.vacationForm.get('dateStart').disable()
      this.vacationForm.get('dateEnd').disable()
      this.vacationForm.get('period').disable()
      this.vacationForm.get('days').disable()
      this.vacationForm.get('applicationDate').disable()
    } else {
      this.vacationForm.get('dateStart').enable()
      this.vacationForm.get('dateEnd').enable()
      this.vacationForm.get('period').enable()
      this.vacationForm.get('days').enable()
      this.vacationForm.get('applicationDate').enable()
    }
  }

  editVacancy(item) {

    this.clearVacancyForm(false);

    this.isEditingVacancy = true;
    this.isInDetailsVacancy = false;
    this.selectedVacationForEditing = item;

    this.getVacancy(item);

    this.vacationForm.get('newModificationReason').setValidators(Validators.required);
    this.vacationForm.get('newModificationReason').updateValueAndValidity();
  }



  

  monthDiff(d1, d2) {
    let months = 0;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };

  isAbleForVacations() {
    const fromDate = moment().startOf('date') /* this.vacationForm.get('dateStart')?.value */
    const vactionStart = moment(fromDate).startOf('date') //.format('yyyy-MM-D')
    const admission = moment(this.personSelected.admissionDate).startOf('date')
    const year = vactionStart.diff(admission, 'year')
    const valid = year >= 1
    return valid
  }

  getMonthDiff() {
    const dateStartSelected = this.vacationForm.get('dateStart')?.value
    const vactionStart = moment(dateStartSelected).hour(0).minute(0).second(0).millisecond(0) //.format('yyyy-MM-D')
    const admission = moment(this.personSelected.admissionDate).hour(0).minute(0).second(0).millisecond(0)
    const diff = vactionStart.diff(admission, 'months')
    return diff
  }


  changeYear() {
    const year = this.vacationForm.get('period')?.value?.year
    if (Array.isArray(year)) {
      if (year.length == 0) {
        return
      }
    }
    if (!year) return
    
    const daysAvailable = this.getDaysAvailableInSpecificYear(year)
    this.maxDaysAvailable = daysAvailable
  }

  changeVacationModificationReason(value: ParameterControl){
    this.vacationForm.get('newModificationReasonId').setValue(value?.ocode);
  }

  validateDates() {
    const dateStart = this.vacationForm.get('dateStart')?.value
    const dateEnd = this.vacationForm.get('dateEnd')?.value
    if (dateStart && dateEnd) {
      if (this.isDateEndInvalid()) {
        //this.toastr.error('La fecha de término debe ser mayor a la fecha de inicio')
        this.dateStartIsGreaterThanEndDate = 'La fecha de término debe ser superior a la fecha de inicio'
      } else {
        this.dateStartIsGreaterThanEndDate = ''
      }
    }
  }

  isDateEndInvalid() {
    const startDate = moment(this.vacationForm.get('dateStart')?.value).hour(0).minute(0).second(0).millisecond(0)
    const endDate = moment(this.vacationForm.get('dateEnd')?.value).hour(0).minute(0).second(0).millisecond(0)
    const isStartDateGreater = startDate > endDate
    return isStartDateGreater
  }

  vacancyFormHasError() {
    this.vacationFormInvalid = this.vacationForm.invalid;
    if (this.vacationForm.invalid) {
      return true
    }
    //console.log(11)
    if (this.validateDateTaken()) {
      //console.log(3123123)
      return true
    }
    if (this.isDateEndInvalid()) {
      return true
    }
    //effectiveDate
    //this.vacationForm.get('applicationDate').value

    if (!this.isAbleForVacations()) {
      this.toastr.error('Debe esperar mínimo 6 meses para solicitar vacaciones')
      return true
    } else {
      this.isAbleForVacationError = ''
    }
    if (this.exceededVacationDays()) {
      this.exceededDaysError = 'Excedió o ya agotó la cantidad de días disponibles para vacaciones.'
      return true
    } else {
      this.exceededDaysError = ''
    }
    if (this.selectVacationDayTwiceError()) {
      this.dateSelectedError = 'Tu rango de fechas colisiona con una/s de las fechas solicitadas anteriormente.'
      return true
    } else {
      this.dateSelectedError = ''
    }

    this.vacationFormInvalid = this.vacationForm.invalid;
    if (this.vacationFormInvalid) {
      return true;
    }
    return false;
  }

  workingDaysCount(firstDate: any, secondDate: any): number {

    const firstWeekEnd = firstDate.clone().endOf('week');
    const lastWeekStart = secondDate.clone().startOf('week');
    const daysBetween = lastWeekStart.diff(firstWeekEnd, 'days') * 5 / 7; // this will always multiply of 7
    let firstWeekDays = firstWeekEnd.day() - firstDate.day(); // check first week
    if (firstDate.day() === 0) { // -1 if start with sunday
        firstWeekDays--;
    }
    let lastWeekDays = secondDate.day() - lastWeekStart.day(); // check last week
    if (secondDate.day() === 6) { // -1 if end with saturday
        lastWeekDays--;
    }

    let noWorkingDayCount = 0;
    this.noWorkingDayMatchWithDates = [];
    this.noWorkingDays.forEach((element) => {
        if (new Date(element.date) >= new Date(firstDate._i) && new Date(element.date) <= new Date(secondDate._i)) {
            const date = moment(element?.date);
            this.noWorkingDayMatchWithDates.push(element);
            if (date?.isoWeekday() === 6 || date?.isoWeekday() === 7) {
                return;
            }
            noWorkingDayCount++;
        }
    });

    return firstWeekDays + Math.floor(daysBetween) + lastWeekDays - noWorkingDayCount;

  }

  daysRequested() {
    if (this.vacationForm.get('dateStart').value && this.vacationForm.get('dateEnd').value) {
      const startDate = new Date(this.vacationForm.get('dateStart').value);
      const endDate = new Date(this.vacationForm.get('dateEnd').value);

      const difference_In_Time = endDate.getTime() - startDate.getTime();

      this.vacationForm.get('days').patchValue(
        this.workingDaysCount(
          moment(this.vacationForm.get('dateStart').value),
          moment(this.vacationForm.get('dateEnd').value))
      );
      
    }


    
  }



  exceededVacationDays(): boolean {
    const daysSelected = this.vacationForm.get('days').value
    return daysSelected > this.maxDaysAvailable
  }


  private getTotalYearsWorking(year: number): number {
    const experienceYears = this.vacationYears.find(x => x.year == year)?.experienceYearsToDate;
    return experienceYears > 0 ? experienceYears : 0
  }

  private getCumulativeAndDaysCorresponding() {
    const today = moment().startOf('date').year();
    const availableDays = this.vacationYears.length > 0 ?  this.vacationYears[this.vacationYears.length - 1] : null;

    this.cumulativeYears = availableDays?.experienceYearsToDate ?? 0;
    this.correspondingDays = availableDays?.allowedDays ?? 0;
  }

  private getDaysAvailableInSpecificYear(year: number): number {
    const totalOnYear = this.vacationYears.find(x => x.year == year);
    const days = totalOnYear?.availableDays ?? 0;

    const daysOfSelectedForEditing = (this.selectedVacationForEditing?.year == year ? this.selectedVacationForEditing?.days : 0) ?? 0;

    const availableDays = this.isEditingVacancy ? (days + daysOfSelectedForEditing) : (days)

    return availableDays
  }


  selectVacationDayTwiceError() {
    if (this.vacationData.some(x => x.vacancyId == this.vacationForm.get('vacancyId').value)) {
      return false;
    }
    let daysTaken = this.employeeVacationHistory ? this.employeeVacationHistory.find(x => x.year == this.vacationForm.get('period')?.value?.year)?.employeeVacationHistoryDates : null;
    let takenOrOverlap: { startDate: Date, endDate: Date, status: boolean }[] = [];
    let startDateRequested = new Date(this.vacationForm.get('dateStart').value);
    let endDateRequested = new Date(this.vacationForm.get('dateEnd').value);
    if (daysTaken?.length > 0) {
      daysTaken.forEach(dayRange => {
        if ((startDateRequested >= new Date(dayRange.startDate) && startDateRequested <= new Date(dayRange.endDate)) ||
          (new Date(dayRange.startDate) >= startDateRequested && new Date(dayRange.startDate) <= endDateRequested)) {
          takenOrOverlap.push({
            startDate: dayRange.startDate,
            endDate: dayRange.endDate,
            status: true
          });
        }
      })
      return takenOrOverlap.filter(x => x.status).length > 0;
    }
  }

  validateDateTaken() {
    let dateStart = moment(this.vacationForm.get('dateStart').value).hour(0).minute(0).second(0).millisecond(0)
    let dateEnd = moment(this.vacationForm.get('dateEnd').value).hour(0).minute(0).second(0).millisecond(0)
    const vacantions = [...this.vacationData].filter(s => s.vacancyId !== this.vacationForm?.value?.vacancyId)
    let hasError: Boolean = false
    vacantions.forEach(v => {

      let dStart = moment(v.dateStart).hour(0).minute(0).second(0).millisecond(0)
      let dEnd = moment(v.dateEnd).hour(0).minute(0).second(0).millisecond(0)

      //rango de fecha interior
      if (dateStart >= dStart && dateStart <= dEnd) {
        hasError = true
      } else {
        hasError = false
      }
      if (dateEnd >= dStart && dateEnd <= dEnd) {
        hasError = true
      } else {
        hasError = false
      }
      //rango de fecha exterior
      if (dateStart <= dStart && dateEnd >= dEnd) {
        hasError = true
      } else {
        hasError = false
      }
    })
    if (hasError == true) {
      this.dateSelectedError = 'Tu rango de fechas colisiona con una/s de las fechas solicitadas anteriormente.'
      return true
    } else {
      this.dateSelectedError = ''
      return false
    }
  }

  submitVacationForm() {


    if (this.vacancyFormHasError()) {
      return;
    }


    if (this.isEditingVacancy) {
      this.updateVacancy();
    } else {
      this.saveVacancy();
    }
  }

  private saveVacancy() {
    if (this.vacancyFormHasError()) {
      return;
    }
    if (!this.validateDate(this.vacationForm)) {
      return;
    }
    let vacancy: IVacancy = {
      employeeId: this.employeeId,
      companyId: this.authInfo.getCompanyId(),
      status: true,
      ...this.vacationForm.value,
      year: this.vacationForm?.value?.period?.year,
      yearTo: this.vacationForm?.value?.period?.yearTo,
      vacancyId: this.vacancyId,
      days: this.vacationForm.get('days').value,
      comment: this.vacationForm.get('comment').value,
      vacationTypeId: 1,
      createUserId: this.authInfo.getUserId()
    }
    let saveVacancySub = this.personnelActionsService.saveVacancy(vacancy).subscribe({
      next: (res: ResponseModel<any>) => {

        if (!res.succeded) {

          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(warn => {
            this.toastr.error(warn);
          })

          return;
        }
        this.toastr.success("Registro guardado con exito", "Registro guardado");
        this.clearVacancyForm();
        this.getHistoryWork();
        this.vacancyId = 0;
      }, error: res => {
        this.toastr.error("Error al guardar las vacaciones", "Error inesperado");
      }
    });
    this.subscriptions.add(saveVacancySub)
  }

  private updateVacancy() {
    if (this.vacancyFormHasError()) {
      return;
    }
    if (!this.validateDate(this.vacationForm)) {
      return;
    }
    let vacancy: IVacancy = {
      employeeId: this.employeeId,
      companyId: this.authInfo.getCompanyId(),
      status: true,
      ...this.vacationForm.value,
      year: this.vacationForm?.value?.period?.year,
      yearTo: this.vacationForm?.value?.period?.yearTo,
      vacancyId: this.vacationForm.get('vacancyId').value,
      days: this.vacationForm.get('days').value,
      comment: this.vacationForm.get('comment').value,
      vacationTypeId: this.vacancySelected.vacationTypeId,
      createUserId: this.vacancySelected.createUserId,
      modifyUserId: this.authInfo.getUserId(),
      modificationComment: this.vacationForm?.value?.newModificationComment,
      modificationReasonId: this.vacationForm?.value?.newModificationReasonId
    }
    let saveVacancySub = this.personnelActionsService.updateVacancy(vacancy).subscribe({
      next: (res: ResponseModel<any>) => {
        if (!res.succeded) {

          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(warn => {
            this.toastr.error(warn);
          })

          return;
        }

        this.toastr.success("Registro modificado exitosamente");
        this.clearVacancyForm();
        this.getHistoryWork();
        this.vacancyId = 0;
      }, error: res => {
        this.toastr.error("Error al modificar las vacaciones", "Error inesperado");
      }
    });
    this.subscriptions.add(saveVacancySub)
  }

  removeVacation(item) {

    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      title: "¿Está seguro que desea eliminar este registro?",
      icon: "question",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar"
    }).then(result => {
      if (result.isConfirmed) {
        let deleteVacancySub = this.personnelActionsService.deleteVacancy(item.vacancyId).subscribe((res: any) => {

          if (!res.succeded) {
            res.errors.forEach(err => {
              this.toastr.error(err);
            });

            res.warnings.forEach(err => {
              this.toastr.warning(err);
            })

            return;
          }

          this.toastr.success("Se ha eliminado el registro")

          if(this.selectedVacationForEditing?.vacancyId == item?.vacancyId){
            this.clearVacancyForm();
          }

          this.getVacationTablesData()
        }, (error) => {
          this.toastr.error("Error al eliminar el registro", "Error inesperado")
        });
        this.subscriptions.add(deleteVacancySub)
      }
    })
  }

  openPauseVacationModal(item: IVacancy, viewType: ModalViewsEnum){
    this.dialog.open(PauseVacationModalComponent,{
      data: {vacationData: item, viewType},
      width: '60%'
    }).afterClosed().subscribe({
      next:(res)=>{
        if(res == true){
          this.getVacationTablesData();
        }
      }
    })
  }

  getHistoryWork() {
    this.personnelActionsService.getHistoryWork(this.personSelected.personId).subscribe((res: any) => {

      if (!res.succeded) {
        res.errors.forEach(err => {
          this.toastr.error(err);
        });

        res.warnings.forEach(err => {
          this.toastr.warning(err);
        })

        return;
      }

      const data: IPersonsWorkExperiencesGET[] = res.dataList
      this.historyWork = data;


      this.getVacationTablesData();



    }, error => {
      this.toastr.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }
  getPaginatedRecordsHistory(event) {
    this.historyWorkRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  getInstitutions() {
    this.personnelActionsService.getInstitutions().subscribe((res: any) => {
      if (res.succeded) {
        this.institutions = res.dataList;
      } else {
        this.toastr.error(res.errors[0])
      }
    }, error => {
      this.toastr.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  // showDaysHistoryErrors(){
  //   let field = this.historyForm.get('days');
  //   if(field.hasError('pattern')){
  //     return 'Este campo solo permite números'
  //   }
  //   return ''
  // }
  showYearsHistoryErrors() {
    let field = this.historyForm.get('years');
    if (field.hasError('pattern')) {
      return 'Este campo solo permite números'
    }
    return ''
  }




  //#region
  showDateStartErrors() {
    let field = this.vacationForm.get('dateStart');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    if (field.hasError('unableForVacationsWithinSelectedDates')) {
      return 'Las vacaciones deben entrar en vigencia tras haber completado un año en la institución.'
    }
    return '';
  }
  showDaysErrors() {
    let field = this.vacationForm.get('days');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }

    return '';
  }
  showDateEndErrors() {
    let field = this.vacationForm.get('dateEnd');

    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    if (field.hasError('higherDate')) {
      return 'La fecha de término debe ser superior a la fecha de inicio';
    }
    if (field.hasError('exceededVacationDays')) {
      return 'Excedió o ya agotó la cantidad de días disponibles para vacaciones.'
    }

    return '';
  }


  showYearErrors() {
    let field = this.vacationForm.get('period');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  editPermision(permission:IEmployeePermissions) {

    this.clearPermission(false);

    this.isEditingPermission = true;
    this.currentPermissionConditionOnEditting = permission?.conditionId;
    this.currentPermissionEdittingIsWithoutPayroll = permission?.withoutPayroll;
    this.setPermission(permission)

    this.permissionForm.get('newModificationReason').setValidators(Validators.required);
    this.permissionForm.get('newModificationReason').updateValueAndValidity();
  }

  setPermission(permission: IEmployeePermissions) {
    const isCheckPermissionRecurrent = this.isCheckPermissionRecurrent(permission);
    this.checkPermissionRecurrent = isCheckPermissionRecurrent;
    if (isCheckPermissionRecurrent) {
      this.enableRecurrentDay();
    } else {
      this.disableRecurrentDay();
    }

    const modificationReason = this.modificationReasons?.find(m => m.ocode == permission?.modificationReasonId);

    this.permissionForm.patchValue({
      ...permission,
      applicationDate: formatDate(permission?.applicationDate, "yyyy-MM-dd", "en"),
      dateStart: moment(permission?.dateStart).format('yyyy-MM-DD'),
      dateEnd: formatDate(permission?.dateEnd, "yyyy-MM-dd", "en"),
      createUserId: permission.createUserId,
      oldModificationComment: permission?.modificationComment,
      oldModificationReason: modificationReason
    });

    const typePermision = this.typePermisions?.find(t => t?.id === permission?.typePermissionsId);
    this.permissionForm.get('idTypePermissions').setValue(typePermision)

    this.changeTypePermison(typePermision, true);

    if (permission?.evidence) {
      this.guiID = permission?.evidence;
      this.file.document = permission?.evidence;
    } else {
      this.guiID = '';
      this.file.document = '';
    }
  }

  enableRecurrentDay() {
    this.permissionForm.get('monday').enable();
    this.permissionForm.get('tuesday').enable();
    this.permissionForm.get('wednesday').enable();
    this.permissionForm.get('thursday').enable();
    this.permissionForm.get('friday').enable();
    this.permissionForm.get('saturday').enable();
    this.permissionForm.get('sunday').enable();
  }

  disableRecurrentDay() {
    this.permissionForm.get('monday').disable();
    this.permissionForm.get('tuesday').disable();
    this.permissionForm.get('wednesday').disable();
    this.permissionForm.get('thursday').disable();
    this.permissionForm.get('friday').disable();
    this.permissionForm.get('saturday').disable();
    this.permissionForm.get('sunday').disable();
  }

  isCheckPermissionRecurrent(permission) {
    return permission?.monday || permission?.tuesday || permission?.wednesday || permission?.thursday || permission?.friday
      || permission?.saturday || permission?.sunday
  }

  getEvidence(guid) {
    this.docService.getDocument(guid).subscribe(res => {
      this.file.document = res.data ? res.data : ''
    })
  }
  showReasonErrors() {
    let field = this.permissionForm.get('reason');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showCommentPermissionErrors() {
    let field = this.permissionForm.get('comment');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showCommentPermissionEvidence() {
    let field = this.permissionForm.get('evidence');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

  showHourEndErrors() {
    let field = this.permissionForm.get('hourEnd');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showidTypePermissionsErrors() {
    let field = this.permissionForm.get('idTypePermissions');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    if (field.hasError('min')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showCommentErrors() {
    let field = this.permissionForm.get('comment');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showHourStartErrors() {
    let field = this.permissionForm.get('hourStart');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showApplicationDateErrors() {
    let field = this.permissionForm.get('applicationDate');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showDateStartErrorsPermission() {
    let field = this.permissionForm.get('dateStart');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }
  showDateEndErrorsPermission() {
    let field = this.permissionForm.get('dateEnd');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    if (field.hasError('higherDate')) {
      return 'La fecha de término no puede ser menor a la fecha de inicio';
    }
    if (field.hasError('daysAllowed')) {
      return 'No puede superar el limite de días permitidos';
    }
    return '';
  }

  openReportPDF(item: IVacancy) {
    const { vacancyId, employeeId, companyId } = item
    const reportUrl = `${environment.reportUrl}/?ReportCode=rpt_Vacancy&CompanyId=${companyId}&EmployeeId=${employeeId}&VacancyId=${vacancyId}`

    if (reportUrl.length === 0) {
      return
    }

    let parameters = {
      url: reportUrl,
      title: 'Vacaciones',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }

  exportPermission(permission){
    const {employeeId, permissionsId} = permission
    const companyId = this.authInfo?.getCompanyId();
    const reportCode: ReportCode = ReportCode.Permission;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&EmployeeId=${employeeId}&PermissionsId=${permissionsId}`;
    let parameters = {
      url: reportUrl,
      title: 'Cargos y Plazas de la Planificación',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

}