import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Mexico from '@uppy/locales/lib/es_MX'
import XHRUpload from '@uppy/xhr-upload';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ContentManagementService } from 'src/app/services/content-management.service';
import { DepartmentService } from 'src/app/services/department.service';
import { docService } from 'src/app/services/doc.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { SocialMediaService } from 'src/app/services/social-media.service';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { environment } from 'src/environments/environment';
import { AddContentModalComponent } from '../../content-management/content-management-list/add-content-modal/add-content-modal.component';
import { SocialNetworks } from '../social-media-models';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';
type GUID = string & { isGuid: true };

@Component({
  selector: 'app-social-media-modal',
  templateUrl: './social-media-modal.component.html',
  styleUrls: ['./social-media-modal.component.css']
})
export class SocialMediaModalComponent implements OnInit {
  file = {
    file: '',
    guid: ''
  }
  title = ''
  showIcon = true
  form = new FormGroup({
    name: new FormControl('', Validators.required),
    link: new FormControl('', Validators.required),
    linkType: new FormControl('', Validators.required),
  })
  linkTypes = []
  personalFile = personalFile;
  SystemLoad: number = 1;
  mode: 'add' | 'details' | 'edit'
  ModuleLoad: number = 1;

  multimediaFile = new Uppy({
    restrictions: {
      maxFileSize: 1048576000000000,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['image/*']
    },
    locale: {
      strings: {
        youCanOnlyUploadFileTypes: 'Solo puedes subir archivos de tipo: %{types}',
        Mexico
      }
    }
  });

  
  routeFile: string = "";
  readonly urlDoc = environment.api_doc + '/Document';

  @ViewChild('uppyFile')
  uppyFile: UppyFileComponent | null = null;
  constructor(
    public dialogRef: MatDialogRef<AddContentModalComponent>,
    private _contenManagementService: ContentManagementService,
    private _toastService: ToastService,
    private _authInfo: AuthInfoService,
    private parameter: ParameterControlService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private srvDoc: docService,
    private _departmentService: DepartmentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private social: SocialMediaService,
  ) { }

  ngOnInit(): void {
    this.mode = this.data.mode
    this.getLinkTypes()
    if(this.mode == 'details'){
      this.form.disable()
      this.fillData()
    }
    if(this.mode == 'edit'){
      this.fillData()
    }
  }


  fillData(){
    this.form.patchValue({
      link: this.data.item.link,
      name: this.data.item.name
    })
    if(this.data.item.icon){
      this.file.guid = this.data.item.icon
    }
  }


  changeType(){
    const type = this.form.value.linkType
    if(!type.ocode){
      this.showIcon = false
      return
    }
    if(type.ocode == 1){
      this.showIcon = true
    }
    if(type.ocode != 1){
      this.showIcon = false
    }
    
  }

  save(){
    const model = this.getModel()

    if(this.form.value.linkType?.ocode == 1){
      this.uppyFile.handleFile(guid => {
        model.icon = guid;
        this.social.postSocialNetworks(model).subscribe({
          next: (res:any) => {
            if(res.errors.length >0) {this.toastr.error(res.errors[0]); return}
            if(res.warnings.length >0) {this.toastr.warning(res.warnings[0]); return}
            this.toastr.success('Datos guardados')
            this.close(true)
          }, error: (error: any) => {this.toastr.error('Ha ocurrido un error inesperado')}
        })
      });
    }else{
      this.social.postSocialNetworks(model).subscribe({
        next: (res:any) => {
          if(res.errors.length >0) {this.toastr.error(res.errors[0]); return}
          if(res.warnings.length >0) {this.toastr.warning(res.warnings[0]); return}
          this.toastr.success('Datos guardados')
          this.close(true)
        }, error: (error: any) => {this.toastr.error('Ha ocurrido un error inesperado')}
      })
    }
 
  }

  getModel(){
    const model: SocialNetworks = {
      companyId: this._authInfo.getCompanyId(),
      createUserId: this.mode == 'add' ? this._authInfo.getUserId() : this.data.item.socialNetworkId,
      icon: this.form.value.linkType?.ocode == 1 ? this.file.guid : null,
      link: this.form.value.link,
      modifyUserId: this._authInfo.getUserId(),
      name: this.form.value.name,
      socialNetworkId: this.mode == 'add' ? 0 : this.data.item.socialNetworkId,
      status: true,
      typeId: this.form.value.linkType.ocode
    }
    return model
  }

  submit(){
    if(this.mode == 'add'){
      this.save()
    }
    if(this.mode == 'edit'){
      this.update()
    }
  }

  getLinkTypes(){
    this.parameter.getParameters(keyWord.LinkType).subscribe({
      next: (res:any) => {
        if(res.errors.length> 0){this.toastr.error(res.errors[0]); return}
        this.linkTypes = res.dataList
        if(this.mode == 'edit' || this.mode == 'details'){
          this.form.get('linkType').patchValue(this.linkTypes.find(x => x.ocode == this.data.item.typeId))
        }
      }, error: (e :any) => {this.toastr.error('Ha ocurrido un error inesperado')}
    })
  }
  update(){
    const model = this.getModel()
    this.social.putSocialNetworks(model).subscribe({
      next: (res:any) => {
        if(res.errors.length >0) {this.toastr.error(res.errors[0]); return}
        if(res.warnings.length >0) {this.toastr.warning(res.warnings[0]); return}
        this.toastr.success('Datos guardados')
        this.close(true)
      }, error: (error: any) => {this.toastr.error('Ha ocurrido un error inesperado')}
    })
  }
  
  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Click para desplegar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  close(success = false){
    this.dialogRef.close({success})
  }
  changeDetector() {
    this.cdr.detectChanges();
  }
  getGuidName(guid: string): GUID {
    return guid as GUID;
  }

    
  setEvidence(event: string){
    this.file.file = event;
  }

}