import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import { AccountService } from 'src/app/services/account.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CollectiveVacationsService } from 'src/app/services/collective-vacations.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { FilterEvent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';
import { VinculationsDetailBulkLoadComponent } from '../vinculations-detail-bulk-load/vinculations-detail-bulk-load.component';
declare function applySort(): any;


@Component({
  selector: 'vinculations',
  templateUrl: './vinculations.component.html',
  styleUrls: ['./vinculations.component.css']
})
export class VinculationsComponent implements OnInit {

  locations: [] = [ ];

  options = [
    "Institución A",
    "Institución B",
    "Institución C",
    "Institución D"

  ];
  departament = [
    "Departamento A",
    "Departamento B",
    "Departamento C",
    "Departamento D"

  ];
  ocupacionalGroup = [
    "Grupo ocupacional A",
    "Grupo ocupacional B",
    "Grupo ocupacional C",
    "Grupo ocupacional D"

  ];
  contractType = [
    "Tipo Contrato A",
    "Tipo Contrato B",
    "Tipo Contrato C",
    "Tipo Contrato D"

  ];
  employeType = [
    "Tipo empleado A",
    "Tipo empleado B",
    "Tipo empleado C",
    "Tipo empleado D"

  ];
  status = [
    "Activo",
    "Inactivo",

  ];
  documentType = [
    "Cédula",
    "Pasaporte",
    "Número seguridad social",
    "Licencia",
    "RNC",
    "Otros"
  ];

  gender = [
    "Hombre",
    "Mujer",
    "Indistintos"

  ];

  civilStatus = [
    "Soltero",
    "Casado",
    "Viudo",
    "Divorciado",
    "Unión libre"

  ];
  
  bank = [
    "Banco de Reservas",
    "Banco Popular",
    "BHD Leon",
    "Promerica",
    "Santa Cruz"

  ];
  viculationsForm = new FormGroup({
    positionName: new FormControl(''),
    position: new FormControl({ value: '', disabled: true }, [Validators.required]),
    vacancyCause: new FormControl('', [Validators.required]),
    vacancyType: new FormControl('', [Validators.required]),
    enclousure: new FormControl('', [Validators.required]),
    plaza: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    department: new FormControl('', [Validators.required]),
    admissionDate: new FormControl('', [Validators.required]),
    startDate: new FormControl(''),
    requirements: new FormControl(''),
    experience: new FormControl(''),
    jobFunctions: new FormControl(''),
    maximumAge: new FormControl('', [Validators.required]),
    minimumAge: new FormControl('', [Validators.required]),
    sex: new FormControl('', [Validators.required]),
    hoursWorked: new FormControl('', [Validators.required]),
    yearsExperience: new FormControl('' ),
    academicLevel: new FormControl('', [Validators.required]),
    requisitionType: new FormControl('', [Validators.required]),
    documentId: new FormControl(''),
    observations: new FormControl(''),
    desirableRequirements: new FormControl(''),
    contractAdmissionDate: new FormControl({ value: null, disabled: true }),
    contractEndingDate: new FormControl({ value: null, disabled: true })
  })

  enclousureConfig = {
    displayKey: 'socialReason',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  };

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'COLLECTIVE-VACATIONS',
    view: { key: 'View', value: false },
    add: { key: 'Add', value: false },
    edit: { key: 'Edit', value: false },
    manage: { key: 'Manage', value: false },
    apply: { key: 'Apply', value: false },
    export: { key: 'Export', value: false },
    search: { key: 'SEARCH', value: false}
  }
  localStorageSearch:string = 'collective-vacations';
  searchParameter: FilterEvent;
  useCollectiveVacations: boolean = false;
  separationsPagedResut = new PagedResult<any>();
  reportUrl = environment.reportUrl;
  showProcess: string = 'CargaManual';

  separationsList = []

  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Secuencia" },
      { propkeyId: 2, displayName: "Cantidad de Colaboradores" },
      { propkeyId: 3, displayName: "Año al que pertenece" },
      { propkeyId: 4, displayName: "Total de Días" },
    ];

  constructor(
    private _exlService: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    public authInfo: AuthInfoService,
    private _collectiveVacationsService: CollectiveVacationsService,
    private _toastService: ToastService,
    private accountService: AccountService,
    private _companiesService: CompaniesService,
  ) {
  }
  
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };

  
  // status: boolean = true
  config = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  };

  VacancyTypesconfig = {
    //displayKey default 'description'
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  };
  positionsConfig = {
    displayKey: 'positionName',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  };

  //#region Uploading photos
  public file = {
    url: null,
    guid: ''
  }

  uppy = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['image/*', 'application/pdf']
    },
    locale:Mexico
  });
  //#endregion

  inView = false

  ngOnInit(): void {
  }
  
  isPDF(){
    return this.file.url.substring(this.file.url.lastIndexOf(".") + 1).slice(0,3).toLowerCase() == "pdf";
  }

  filterSearch(event: FilterEvent) {
    this.separationsPagedResut.page = 1
    this.searchParameter = event
    // this.getVacationsList()
    applySort();
  }

  getPaginatedRecords(event: PaginationEvent) {
  }

  getRouterToBack() {
    return '/collective-actions'

  }

  getVacationsList() {
    if (!this.useCollectiveVacations) {
      return
    }
    const page = this.separationsPagedResut.page
    const pageSize = this.separationsPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    this._collectiveVacationsService.getPaginatedCollectiveVacations(page, pageSize, propKeyId, text).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al obtener la lista de vacaciones colectivas"); return; }
        
        this.separationsList = res.singleData.items
        this.separationsPagedResut = res.singleData
      },
      error: (err: any) => {
        console.log(err);
        this._toastService.error('Ha ocurrido un error al obtener la lista de vacaciones colectivas')
      }
    })
  }

  exportToExcel() {
    let data = this.separationsList
    data.forEach(element => {
      element.startDateText = formatDate(element.startDate, 'yyyy-MM-dd', 'en')
      element.endDateText = formatDate(element.endDate, 'yyyy-MM-dd', 'en')
    });
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Cantidad de Colaboradores",
      "Fecha Inicio",
      "Fecha Término",
      "Años al que pertenece",
      "Total Días",
      "Comentario",
      "Condición"
    ],
    [
      "collectiveVacationsHeaderId",
      "quantityEmployees",
      "startDateText",
      "endDateText",
      "year",
      "totalDays",
      "comment",
      "condition"
    ]]
    this._exlService.exportToExcelSpecificColumns(this.filteredList.enable ? this.filteredList.records : this.separationsList, excelHeaders, 'Lista de Vacaciones Colectivas', true);
  }

  openPdf() {

    let url = ''
    const propKeyId = this.searchParameter?.propkeyId ?? 0
    const companyId = this.authInfo.getCompanyId()
    const searchParameter = this.searchParameter?.value
    const { page, pageSize } = this.separationsPagedResut
    const reportCode: ReportCode = ReportCode.CollectiveVacations;

    url = `${this.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&PropKeyId=${propKeyId}&SearchParameter=${searchParameter}&status=1&page=${page}&pageSize=${pageSize}`

    let parameters = {
      url: url,
      title: '',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }

  viewProcessBulkLoad(): void
  {
    this.dialog.open(VinculationsDetailBulkLoadComponent, {
      width: '90%'
    });
  }
}
