import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
enum types {
  allvacants = 0,
  published = 1,
  gender = 2,
  enclosure = 3,
  departments = 4,
  notPublished = 0,
  academicLevel = 6
}
export interface IDashboardOptions {
  dateFrom: Date | string , dateTo: Date | string, companyId: number
}
@Injectable({
  providedIn: 'root'
})
export class VacanciesListDashboardService {

  private readonly api = environment.api_url
  constructor(
    private http : HttpClient, private authInfo: AuthInfoService
  ) { }
  
  getDashboardData(date1, date2, companyId = this.authInfo.getCompanyId()){
    if(date1 == null){
      date1 = `2000-01-01`
    }
    if(date2 == null){
      date2 = `${new Date().getFullYear()}-12-31`
      
    }
    return this.http.get(`${this.api}/Vacants/Dashboard?Status=true&CompanyId=${companyId}&DateFrom=${date1}&DateTo=${date2}`)
  }
  
  getEnclosures(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    let dateFrom:any = options.dateFrom
    let dateTo = ''
    if(options.dateTo == null){
      dateTo = '3000-12-31'
    }else{
      dateTo = formatDate(options.dateTo, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom != null){
      dateFrom = formatDate(options.dateFrom, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom == null){
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&Type=${types.enclosure}&Condition=1&Status=true&CompanyId=${options.companyId}`)
    }else{
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&DateFrom=${dateFrom}&Type=${types.enclosure}&Condition=1&Status=true&CompanyId=${options.companyId}`)
    }
  }
  getGenders(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    let dateFrom:any = options.dateFrom
    let dateTo = ''
    if(options.dateTo == null){
      dateTo = '3000-12-31'
    }else{
      dateTo = formatDate(options.dateTo, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom != null){
      dateFrom = formatDate(options.dateFrom, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom == null){
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&Type=${types.gender}&Condition=1&Status=true&CompanyId=${options.companyId}`)
    }else{
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&DateFrom=${dateFrom}&Type=${types.gender}&Condition=1&Status=true&CompanyId=${options.companyId}`)
    }
  }
  getDepartments(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    let dateFrom:any = options.dateFrom
    let dateTo = ''
    if(options.dateTo == null){
      dateTo = '3000-12-31'
    }else{
      dateTo = formatDate(options.dateTo, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom != null){
      dateFrom = formatDate(options.dateFrom, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom == null){
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&Type=${types.departments}&Condition=1&Status=true&CompanyId=${options.companyId}`)
    }else{
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&DateFrom=${dateFrom}&Type=${types.departments}&Condition=1&Status=true&CompanyId=${options.companyId}`)
    }
  }
  getPublished(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    /* the condition changes = 0 in endpoint */
    let dateFrom:any = options.dateFrom
    let dateTo = ''
    if(options.dateTo == null){
      dateTo = '3000-12-31'
    }else{
      dateTo = formatDate(options.dateTo, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom != null){
      dateFrom = formatDate(options.dateFrom, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom == null){
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&Type=1&Condition=${types.published}&Status=true&CompanyId=${options.companyId}`)
    }else{
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&DateFrom=${dateFrom}&Type=1&Condition=${types.published}&Status=true&CompanyId=${options.companyId}`)
    }
  }
  getNotPublished(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    /* the condition changes = 0 in endpoint */
    let dateFrom:any = options.dateFrom
    let dateTo = ''
    if(options.dateTo == null){
      dateTo = '3000-12-31'
    }else{
      dateTo = formatDate(options.dateTo, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom != null){
      dateFrom = formatDate(options.dateFrom, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom == null){
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&Type=1&Condition=${types.notPublished}&Status=true&CompanyId=${options.companyId}`)
    }else{
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&DateFrom=${dateFrom}&Type=1&Condition=${types.notPublished}&Status=true&CompanyId=${options.companyId}`)
    }
  }
  getAll(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    let dateFrom:any = options.dateFrom
    let dateTo = ''
    if(options.dateTo == null){
      dateTo = '3000-12-31'
    }else{
      dateTo = formatDate(options.dateTo, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom != null){
      dateFrom = formatDate(options.dateFrom, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom == null){
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&Type=${types.allvacants}&Condition=1&Status=true&CompanyId=${options.companyId}`)
    }else{
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&DateFrom=${dateFrom}&Type=${types.allvacants}&Condition=1&Status=true&CompanyId=${options.companyId}`)
    }
  }
  getAcademicLevel(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    let dateFrom:any = options.dateFrom
    let dateTo = ''
    if(options.dateTo == null){
      dateTo = '3000-12-31'
    }else{
      dateTo = formatDate(options.dateTo, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom != null){
      dateFrom = formatDate(options.dateFrom, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom == null){
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&Type=${types.academicLevel}&Condition=1&Status=true&CompanyId=${options.companyId}`)
    }else{
      return this.http.get(`${this.api}/Vacants/Dashboard?DateTo=${dateTo}&DateFrom=${dateFrom}&Type=${types.academicLevel}&Condition=1&Status=true&CompanyId=${options.companyId}`)
    }
  }
  get3yearsStats(){
    return this.http.get(`${this.api}/Vacants/Dashboard?Type=5&Status=true&CompanyId=${this.authInfo.getCompanyId()}`)
  }
}
