import { AfterViewInit, ChangeDetectorRef, Component, Directive, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ModalViewsEnum } from 'src/app/models/modal-views';
import { ITeacherReviewEvidencePost, ITeacherReviewEvidenceView, ITeacherReviewPost, ITeacherReviewView } from 'src/app/models/teacher.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { ShowFileModalComponent } from 'src/app/pages/performance-evaluation/list-performance-evaluation/detail-performance-evaluation/performance-agreement/qualify-performance-agreement/show-file-modal/show-file-modal.component';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-teacher-reviews-modal',
  templateUrl: './teacher-reviews-modal.component.html',
  styleUrls: ['./teacher-reviews-modal.component.css']
})
export class TeacherReviewsModalComponent implements AfterViewInit {

  title: string = (this.data.viewType == ModalViewsEnum.Create) ? 'Agregar' : (this.data.viewType == ModalViewsEnum.Edit) ? 'Editar' : '';

  viewTypes = ModalViewsEnum;

  form!: FormGroup;

  evidencesType: ParameterControl[] = [];
  evidenceTypeDropdown!: any;

  @ViewChildren(UppyFileComponent) uppyFileComponents: QueryList<UppyFileComponent>;

  constructor(
    public dialogRef: MatDialogRef<TeacherReviewsModalComponent>,
    private toastService: ToastrService,
    private authInfo: AuthInfoService,
    @Inject(MAT_DIALOG_DATA) public data: {viewType: number, model: ITeacherReviewView, employeeId: number},
    private employeeService: EmployeesService,
    private paramService: ParameterControlService,
    private cdRef: ChangeDetectorRef, private fb: FormBuilder,
    private datePipe: DatePipe,
  private dialog: MatDialog) { 

      this.evidenceTypeDropdown = this.dropdownConfig('stringData');

      this.buildForm();

    }

  ngAfterViewInit(): void {
    this.getParameters();
  }

  buildForm() : void{
    this.form = this.fb.group({
      reviewId: [0],
      employeeId: [null, Validators.required],
      comment: ['', Validators.required],
      reviewDate: [null, Validators.required],
      userId: [null],
      modificationReasonId: [null],
      modificationComment: [''],
      evidences: this.fb.array([])
    });

    this.form.get('employeeId')?.setValue(this.data?.employeeId);

    if(this.data.viewType == ModalViewsEnum.View || this.data.viewType == ModalViewsEnum.Edit){
      this.form.patchValue(this.data.model);
      this.form.get('reviewDate')?.setValue(this.datePipe.transform(this.data?.model?.reviewDate,'yyyy-MM-dd'))

      if(this.data.viewType == ModalViewsEnum.View){
        this.form.disable();
      }
    }

    this.form.get('userId')?.setValue(this.authInfo.getUserId());
  }

  getEvidences(): FormArray {
    return this.form.get('evidences') as FormArray;
  }

  get countEvidencesAvailable(): number{
    return this.getEvidences().getRawValue()?.filter(x=>x?.status == true).length;
  }

  addEvidence(model: ITeacherReviewEvidenceView | null) {
    const evidenceGroup = this.fb.group({
      evidenceId: [0],
      reviewId: [null],
      evidenceTypeId: [null, Validators.required],
      evidenceType: [null],
      evidence: [null],
      userId: [null],
      status: [true],
      createDate: [null],
      modificationReasonId: [null],
      modificationComment: [''],

      evidenceTypeObject: [null],
      upplyFileId: ['uppyFile0']
    });

    if(model){
      evidenceGroup.patchValue(model);
      evidenceGroup.get('createDate')?.setValue(this.datePipe.transform(model?.createDate,'yyyy-MM-dd'))
    }

    evidenceGroup.get('reviewId')?.setValue(this.data?.model?.reviewId);
    evidenceGroup.get('userId')?.setValue(this.authInfo.getUserId());

    const count: number = this.getEvidences().length;

    evidenceGroup.get('upplyFileId')?.setValue(`uppyFile${count}`);

    const type = this.evidencesType.find(x=>x.ocode == evidenceGroup.get('evidenceTypeId')?.value)
    evidenceGroup.get('evidenceTypeObject')?.setValue(type);

    if(this.data.viewType == ModalViewsEnum.View){
      evidenceGroup.disable();
    }

    this.getEvidences().push(evidenceGroup);
    this.cdRef.detectChanges();
  }

  changeEvidenceType(index: number){
    const type: ParameterControl =  this.getEvidences().controls[index]?.get('evidenceTypeObject')?.value;
    this.getEvidences().controls[index]?.get('evidenceTypeId')?.setValue(type?.ocode)
  }

  getParameters(){
    const services: { service: Observable<any>, canExecuteService: boolean, unexpectedErrorMessage?: string, successMessage?: string, methodToExecuteAfterSuccess?: (data:any) => void }[] = [
      {
        service: this.paramService.getParameters(keyWord.TeacherReviewEvidenceType),
        canExecuteService: true,
        unexpectedErrorMessage: 'Ha ocurrido un error tratando de consultar el listado de tipos de evidencias',
        successMessage: '',
        methodToExecuteAfterSuccess: (data:ResponseModel<any>)=>{ 
          this.evidencesType = data?.dataList;
          this.cdRef.detectChanges();
        }
      },
      {
        service: this.employeeService.getTeacherReviewEvidences(0,0,this.data?.model?.reviewId),
        canExecuteService: !this.data?.model ? false : (this.data?.model?.reviewId > 0) ? true : false,
        unexpectedErrorMessage: 'Ha ocurrido un error tratando de consultar el listado de evidencias',
        successMessage: '',
        methodToExecuteAfterSuccess: (data:ResponseModel<ITeacherReviewEvidenceView>)=>{ 
          
          const evidences = data?.dataList;

          evidences.forEach(evidence=>{
            this.addEvidence(evidence);
          })

          this.cdRef.detectChanges();
        }
      }
    ]
    
    const $requests = services.map((model) => {
      if (model.canExecuteService) {
        return model.service
          .pipe(map(value => ({ type: model, value: value })))
          .pipe(catchError(e => of({ succeded: false, errors: [model.unexpectedErrorMessage] } as ResponseModel<any>)));
      }
    }).filter(Boolean);

    forkJoin($requests).subscribe((responses: { type: any, value: ResponseModel<any> }[]) => {

      responses.forEach((response) => {
        if (!response?.value?.succeded) {
          response?.value?.errors.forEach(err => {
            this.toastService.error(err);
          })

          response?.value?.warnings.forEach(warn => {
            this.toastService.warning(warn);
          })
        } else {
          response?.type?.methodToExecuteAfterSuccess(response?.value);
        }
      })

    })

    
  }
  

  saveChanges() {


    if (this.form.invalid) {
      this.toastService.warning('Debe completar los campos obligatorios')
      return;
    }

    const evidencesArray = this.getEvidences().controls;
    const uppyComponentsArray = this.uppyFileComponents.toArray();

    let isValid: boolean = true;

    evidencesArray.forEach(evidenceControl => {
      const fileId = evidenceControl.get('upplyFileId')?.value;
      const status = evidenceControl.get('status')?.value;

      if(status == true){
        const invalidUppy = uppyComponentsArray.find(x=> x.customId == fileId  && x.fileSelected == null && x.existFile == false);
        if(invalidUppy){
          isValid = false;
          return;
        }
      }
    })

    if(!isValid){
      this.toastService.warning('Debe llenar las evidencias que se encuentran incompletas')
      return;
    }

    const uploadPromises = evidencesArray.map((evidenceControl, index) => {
      const fileId = evidenceControl.get('upplyFileId')?.value;
      const status = evidenceControl.get('status')?.value;

      return new Promise((resolve, reject) => {
        

        if (status == true) {
          
          const uppyFileComponent = uppyComponentsArray.find(x=> x.customId == fileId);

          if (uppyFileComponent) {

            try{
              uppyFileComponent.handleFile((guid: GUID) => {

                evidenceControl.get('evidence')?.setValidators([Validators.required]);
                evidenceControl.get('evidence')?.updateValueAndValidity();
                evidenceControl.get('evidence')?.setValue(guid);
                
                if (this.form.invalid) {
                  this.toastService.warning('Debe completar los campos obligatorios')
                  evidenceControl.get('evidence')?.setValidators(null);
                  evidenceControl.get('evidence')?.updateValueAndValidity();
                  reject(null)
                }

                evidenceControl.get('evidence')?.setValidators(null);
                evidenceControl.get('evidence')?.updateValueAndValidity();

                resolve(guid);
              });
            }catch(e){
              this.toastService.warning('Ha ocurrido un error inesperado tratando de subir las evidencias')
              reject(e)
            }
          } else {
            resolve(null);
          }
        }else{
          resolve(null);
        }

      });
    });

    // Esperar a que todos los archivos hayan sido subidos y sus GUIDs obtenidos
    Promise.all(uploadPromises).then(() => {
      const values: ITeacherReviewPost = this.form.getRawValue();

      if (this.data.viewType === ModalViewsEnum.Create) {
        this.doInsert(values);
      } else if (this.data.viewType === ModalViewsEnum.Edit) {
        this.doUpdate(values);
      }
    }).catch(error => {
      
    });

    this.cdRef.detectChanges();
  }

  private doInsert(model: ITeacherReviewPost){

    this.employeeService.insertTeacherReview(model).subscribe({
      next: (res)=>{
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          });

          res.warnings.forEach(warn=>{
            this.toastService.warning(warn);
          })
          return;
        }

        this.toastService.success('Revisión registrada satisfactoriamente');
        this.closeModal(true);
      },
      error: (err)=>{
        this.toastService.error('Ha ocurrido un error inesperado intentando registrar la revisión del docente');
      }
    })

  }

  private doUpdate(model: ITeacherReviewPost){

    this.employeeService.updateTeacherReview(model).subscribe({
      next: (res)=>{
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          });

          res.warnings.forEach(warn=>{
            this.toastService.warning(warn);
          })
          return;
        }

        this.toastService.success('Revisión actualizada satisfactoriamente');
        this.closeModal(true);
      },
      error: (err)=>{
        this.toastService.error('Ha ocurrido un error inesperado intentando actualizar la revisión del docente');
      }
    })

  }


  removeEvidence(index: number) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#e63946',
      cancelButtonText: 'Cancelar',
      title: '¿Seguro que desea remover esta evidencia del listado?',

    }).then((result) => {
      if (result.isConfirmed) {
        this.getEvidences().controls[index]?.get('status')?.setValue(false);

        let evidence = this.getEvidences().controls[index];

        let id: number = evidence.get('evidenceId')?.value;

        if (id <= 0) {
          this.getEvidences().removeAt(index);
        } 

      } else {
        return;
      }
    })
  }


  dropdownConfig(displayKey: string = "stringData") {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccione una opción', // text to be displayed when no item is selected defaults to Select,
      //customComparator: (a, b)=> a.id - b.id, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  closeModal(succeded: boolean = false) {
    this.dialogRef.close(succeded);
  }

}
