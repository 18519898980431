<h2 class="mat-dialog-title"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Carga Masiva
    <button type="button" (click)="close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</h2>

<div class="mat-dialog-content">
    <div class="card-body">
        <div class="row">


            <div class="form-group col-md-3" [formGroup]="form">
                <label class="d-block">Cargar Documento<span class="text-danger text-monospace legend">*</span></label>
                <div class="dropzone dropzone-default dz-clickable" id="kt_dropzone_1">
                    <div class="dropzone-msg dz-message needsclick">
                        <input formControlName="file" (click)="onClick()" (change)="fileUpload($event)"
                            class="form-control form-control-sm" [ngClass]="{'form-control-file-custom': false}"
                            type="file" id="file-upload" name="fileUpload" accept=".xls,.xlsx">
                    </div>
                </div>
            </div>

            <div class="form-group col-md-3" *ngIf="this.dataUploadedListOriginal?.length > 0">
                <label class="text-primary">
                    Estatus
                </label>
                <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                    [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                    (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                    (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th>Código</th>
                                <th>Concepto</th>
                                <th>Tipo de concepto</th>
                                <th>Orden de ejecución</th>
                                <th>Tipo Identificación</th>
                                <th>Identificación Beneficiario</th>
                                <th>Nombre Beneficiario</th>
                                <th>Estatus</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of paginatedDataUploaded; let i = index">
                                <td class="text-left">{{item?.code}}</td>
                                <td class="text-left">{{item?.concept}}</td>
                                <td class="text-left">{{item?.conceptType}}</td>
                                <td class="text-left">{{item?.executionOrder}}</td>
                                <td class="text-left">{{item?.beneficiaryIdentificationType}}</td>
                                <td class="text-left">{{item?.beneficiaryIdentificationNumber}}</td>
                                <td class="text-left">{{item?.beneficiaryName}}</td>
                                <td class="text-left">
                                    <ng-container *ngIf="item.details; else noDetail">
                                        <span class="font-weight-bold text-danger">
                                            {{item.details}}
                                        </span>
                                    </ng-container>
                                    <ng-template #noDetail>                          
                                        <span class="font-weight-bold text-success">
                                            Listo para procesar
                                        </span>
                                    </ng-template>
                                </td>
                                
                                <td class="actionsBtn">
                                    <button data-toggle="tooltip" *ngIf="item?.details" (click)="remove(i)"
                                        class="btn btn-sm btn-light-danger btn-icon  m-1" data-original-title="Remover">
                                        <i class="flaticon-delete"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <el-caption #paginator [originalRecordsLength]="dataUploadedListOriginal?.length"
                            [listedRecordsLength]="paginatedDataUploaded?.length"
                            [registerQuantity]="filteredList?.enable && filteredList.records?.length>0 ? filteredList.records?.length :dataUploaded?.length"
                            [records]="filteredList?.enable ? filteredList?.records : dataUploaded"
                            (onChage)="getPaginatedRecords($event)"></el-caption>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" 
    type="button"
    (click)="close()">
        <i class="flaticon2-cross"></i>
        Cancelar
    </button>
    <button [disabled]="dataUploadedListOriginal.length === 0 || !isValidData" (click)="Accept()"
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Procesar</span>
    </button>
</div>