import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';

@Component({
  selector: 'app-wizard-benefits-modal',
  templateUrl: './wizard-benefits-modal.component.html',
  styleUrls: ['./wizard-benefits-modal.component.css']
})
export class WizardBenefitsModalComponent implements OnInit {
  benefitsSelected;
  benefitSelectConfig = {
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar',
    moreText: 'más'
  }
  benefits = []
  form = new FormGroup({
    benefitsSelected: new FormControl('')
  })
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<WizardBenefitsModalComponent>,
    private srvPerson: PersonService,
    private toastr: ToastrService,
    private authInfo: AuthInfoService
  ) { }

  ngOnInit(): void {
    this.benefits = this.data.benefits
    let employeeBenefits = []
    for (let i = 0; i < this.data.benefitsSelected.length; i++) {
      const element = this.data.benefitsSelected[i];
      const benefit = this.benefits.find(x => x.ocode === element.benefitId)
      if (benefit) {
        employeeBenefits = [...employeeBenefits, benefit]
      }
    }
    this.form.get('benefitsSelected').patchValue(employeeBenefits)
  }

  submit() {
    this.save()
  }


  save() {
    const form = this.form

    const benefits = this.form.value.benefitsSelected
    let benefitsToSave = benefits?.map(element => {
      const benefitExist = this.data.benefitsSelected.find(x => x.benefitId === element.ocode)
      return {
        ecbid: benefitExist ? benefitExist.ecbid : 0,
        employeeId: this.data.employee.employeeId,
        benefitId: element.ocode,
        companyId: this.authInfo.getCompanyId(),
        benefit: element.stringData,
        status: true,
      }
    }) as  any[];
    if(benefitsToSave?.length > 0){
      this.srvPerson.postEmployeeBenefit(benefitsToSave).subscribe({
        next: (res: any) => {
          if(res.errors.length > 0){this.toastr.error(res.errors[0]); return}
          if(res.warnings.length > 0){this.toastr.warning(res.errors[0]); return}
          this.toastr.success("Beneficios guardados")
          this.dialogRef.close({ benefits: form.value.benefitsSelected })
        }, error: (error: any) => {
          this.toastr.error('Ha ocurrido un error inesperado')
        }
      });
    }else{
      this.deleteAll(this.data?.employee?.employeeId);
    }
   
  }

  private deleteAll(employeeId: number){
    this.srvPerson.deleteAllEmployeeBenefit(employeeId).subscribe({
      next: (res: ResponseModel<any>) => {
        if(res?.errors?.length > 0){
          this.toastr.error(res?.errors[0]);
          return;
        }
        this.toastr.success("Beneficios guardados")
        this.dialogRef.close({ benefits: this.form.value.benefitsSelected })
      }, error: res => {
        this.toastr.error('Ha ocurrido un error al guardar los beneficios del servidor público');
      }
    });
  }

  close(success = false) {
    this.dialogRef.close()
  }
}
