<div class="card card-custom" data-card="true" id="kt_card_3">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-agregar-archivo-1 icon-3x text-danger mr-2"></i>
                <h1 class="text-primary">Nómina Adicional</h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Dashboard">
            <button data-toggle="modal" *ngIf="privilege?.dashboardAction.value"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto"
                (click)="openDashBoard()">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </button>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
            <div class="card-toolbar d-flex justify-content-start" [formGroup]="form">

                <div class="col-md-3 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Período Anterior
                        </label>
                        <ngx-select-dropdown [options]='periodBeforeList' [config]='periodConfig' formControlName="additionalPeriod"
                            (change)="changeAdditionalPeriod()">
                        </ngx-select-dropdown>
                    </div>
                </div>
                <div class="col-md-3 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Período
                        </label>
                        <ngx-select-dropdown [options]='periodList' [config]='periodConfig' formControlName="period"
                            (change)="changePeriod()">
                        </ngx-select-dropdown>
                    </div>
                </div>

                <div class="col-md-3 mr-0 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Área Presupuestaria
                        </label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown formControlName="area" [options]="budgetAreaList"
                                [config]="budgetConfig" class="w-100"></ngx-select-dropdown>
                        </div>
                    </div>
                </div>

                <!--      <div class="col-md-3 mr-0 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Tipo de Nómina
                        </label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown formControlName="payrollType" [options]="budgetTypeList"
                                [config]="getDropdownConfig('stringData')" class="w-100"></ngx-select-dropdown>
                        </div>
                    </div>
                </div> -->
                <button class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto"
                    *ngIf="privilege?.openAction.value"
                    [disabled]="form.invalid" (click)="openPayroll()" data-toggle="tooltip"
                    data-original-title="Aperturar" type="button" id="dropdownMenuButton">
                    <i class="flaticon-folder icon-2x"></i>
                    Aperturar
                </button>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div data-toggle="tooltip" data-original-title="Opciones de exportar" class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="privilege?.exportAction.value"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                            <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                            Visualizar Reporte
                        </a>
                        <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a> -->
                    </div>
                </div>

                <div class="col text-nowrap">
                    <el-search-bar #searchBar [records]="payrollListOriginal" (onKeyChange)="onKeyChange($event)"
                        [keyValue]="filterText" (onChange)="getFilteredList($event)"
                        [itemForSearching]="searchBarDisplayNames"></el-search-bar>
                </div>
            </div>
        </div>
    </div>


    <div class="card-body" kt-hidden-height="282">
        <div class="table-responsive">
            <table class="table table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-left">Secuencia</th>
                        <th class="text-left">Tipo de Nómina</th>
                        <th class="text-left">Área Presupuestaria</th>
                        <th class="text-left">Estatus</th>
                        <th class="text-left">Fecha Creación</th>
                        <th class="text-left">Usuario Creación</th>

                        <th class="text-center" style="width: 100px;">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let openedPayroll of paginatedPayrollList">
                        <td class="text-left">{{openedPayroll.headerId}}</td>
                        <td class="text-left">{{openedPayroll.payrollType}}</td>
                        <td class="text-left">{{openedPayroll.budgetArea}}</td>
                        <td class="text-left font-weight-bolder">{{openedPayroll.headerCondition}}</td>
                        <td class="text-left">{{openedPayroll.createDate | date:'dd/MM/yyyy HH:mm'  }}</td>
                        <td class="text-left">{{openedPayroll.name}}</td>
                        <td class=" px-5">
                            <div class="d-flex justify-content-center px-5" style="width: max-content">
                                <div class="justify-content-start  px-5">
                                    <button class="btn btn-icon btn-light-warning btn-sm m-1"
                                        *ngIf="privilege?.viewAction.value"
                                        [routerLink]="['/payroll/additional-payroll', openedPayroll.headerId]">
                                        <i class="flaticon-calendario icon-2x" data-toggle="tooltip" title="Administrar"></i>
                                    </button>
                                    <!-- <button class="btn btn-icon btn-light-info btn-sm m-1"
                                        *ngIf="privilege?.viewAction.value"
                                        [routerLink]="['/payroll/payroll-detail', openedPayroll.headerId]">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Detalles"></i>
                                    </button> -->
                                    <button
                                        *ngIf="(openedPayroll.conditionId != 6 && openedPayroll.conditionId != 5) && privilege?.rejectAction.value"
                                        data-placement="top" title="Rechazar" (click)="openRejectModal(openedPayroll)"
                                        data-toggle="tooltip" class="btn btn-icon btn-light-danger"><i
                                            class="flaticon-mala-critica icon-2x"></i></button>
                                    <button
                                        *ngIf="openedPayroll.conditionId === 6 && privilege?.viewAction.value"
                                        class="btn btn-icon btn-light-danger btn-sm m-1"
                                        (click)="openViewDetailRejectPayrollComponent(openedPayroll)">
                                        <i class="flaticon-nomina-de-sueldos-2 icon-2x" data-toggle="tooltip"
                                            title="Detalles del Rechazo"></i>
                                    </button>

                                    <!-- <button class="btn btn-icon btn-light-warning btn-sm m-1" data-target="#addDep" data-toggle="modal">
                                        <i class="flaticon-colaboracion icon-2x" data-toggle="tooltip"
                                            title="Acciones de Personal"></i>
                                    </button> -->
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>

                <el-caption [originalRecordsLength]="openedPayrollList.length"
                    [listedRecordsLength]="paginatedPayrollList.length" #paginator
                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :openedPayrollList.length"
                    [records]="filteredList.enable ? filteredList.records : openedPayrollList"
                    (onChage)="getPaginatedRecords($event)"></el-caption>

            </div>
        </div>
    </div>

    <el-modal target="addDep" title="Acciones de Personal" size="xxl" id="addDepModal">

        <div class="card card-custom" data-card="true" id="kt_card_3">
            <div class="card-header mt-3">
                <div class="card-title">
                    <div class="ttl d-flex align-items-center">
                        <i class="flaticon-colaboracion icon-3x text-danger mr-2"></i>
                        <h1 class="text-primary">Acción de Personal de HHRR</h1>
                    </div>
                </div>
            </div>

            <div class="card-header mt-3 row justify-content-between">
                <div class="col-sm-12 col-md-4 c-left">

                </div>

                <div class="col-sm-12 col-md-8 c-right mr-0">
                    <div class="card-toolbar d-flex justify-content-end">
                        <div data-toggle="tooltip" data-original-title="Opciones de exportar" class="dropdown col-md-auto col-sm-12">
                            <button title="" class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                                *ngIf="privilege?.exportAction.value"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="flaticon-descarga-arrow icon-2x text-white"></i> Exportar
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item align-items-center" (click)="exportToExcel()">
                                    <i class="flaticon-excel icon-2x text-success mr-3"></i>
                                    Excel
                                </a>
                                <!-- <a class="dropdown-item disabled" href="#">
                                    <i class="flaticon-word icon-2x text-primary mr-3"></i>
                                    Word
                                </a>
                                <a class="dropdown-item disabled" href="#">
                                    <i class="flaticon-pdf-file icon-2x text-primary mr-3"></i>
                                    PDF
                                </a>
                                <a class="dropdown-item disabled" href="#">
                                    <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                                    CSV
                                </a> -->
                            </div>
                        </div>

                        <div class="col text-nowrap">
                            <el-search-bar #searchBar [records]="payrollListOriginal" (onKeyChange)="onKeyChange($event)"
                                (onChange)="getFilteredList($event)"
                                [itemForSearching]="searchBarDisplayNames"></el-search-bar>
                        </div>
                    </div>
                </div>
            </div>


            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left">Secuencia</th>
                                <th class="text-left">Nombre</th>
                                <th class="text-left">Cargo</th>
                                <th class="text-left">Unidad Organizativa</th>
                                <th class="text-left">Cédula</th>
                                <th class="text-left">Ingreso Marzo</th>
                                <th class="text-left">Ingreso Abril</th>
                                <th class="text-left">Nuevo Ingreso</th>
                                <th class="text-left">Aumento de Sueldo</th>
                                <th class="text-left">Validación</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-left">-</td>
                                <td class="text-left">-</td>
                                <td class="text-left">-</td>
                                <td class="text-left">-</td>
                                <td class="text-left">-</td>
                                <td class="text-left">-</td>
                                <td class="text-left">-</td>
                                <td class="text-left">-</td>
                                <td class="text-left">-</td>
                                <td class="text-left">-</td>
                            </tr>
                        </tbody>
                    </table>
                    <div>

                        <el-caption [originalRecordsLength]="openedPayrollList.length"
                            [listedRecordsLength]="paginatedPayrollList.length" #paginator
                            [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :openedPayrollList.length"
                            [records]="filteredList.enable ? filteredList.records : openedPayrollList"
                            (onChage)="getPaginatedRecords($event)"></el-caption>

                    </div>
                </div>
            </div>
        </div>
    </el-modal>

    <export-data-to-pdf [title]="'Nómina Normal'" id="dataToPrint">
        <ng-container thead>
            <th class="text-left">Secuencia</th>
            <th class="text-left">Tipo de Nómina</th>
            <th class="text-left">Área Presupuestaria</th>
            <th class="text-left">Estatus</th>
        </ng-container>
        <ng-container tbody>
            <tr *ngFor="let openedPayroll of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.openedPayrollList" data-row="0" class="datatable-row" style="left: 0px;">
                <td class="text-left">{{openedPayroll.headerId}}</td>
                <td class="text-left">{{openedPayroll.payrollType}}</td>
                <td class="text-left">{{openedPayroll.budgetArea}}</td>
                <td class="text-left font-weight-bolder">{{openedPayroll.headerCondition}}</td>
            </tr>
        </ng-container>
    </export-data-to-pdf>
