import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'el-switch',
  template: `
    <div class="switch" [class]="class">
      <div class="onoffswitch">
          <input type="checkbox" [checked]="val" [name]="name" class="onoffswitch-checkbox" [id]="switchId" (change)="onChange($event.target.checked)">
          <label class="onoffswitch-label rounded-pill" [for]="switchId">
              <span class="onoffswitch-inner rounded-pill"></span>
              <span class="onoffswitch-switch rounded-circle"></span>
          </label>
      </div>
    </div>
  `
})
export class SwitchComponent implements OnInit {
  @Input() switchId: string ;
  @Input() val: boolean;
  @Input() name: string ;
  @Input('customClass') class = '';
  @Output() checkValue = new EventEmitter<any>();

  value: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  onChange(value: boolean): void {
    this.value = value;
    this.checkValue.emit(this.value);
  }
}
