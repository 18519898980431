<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="fs-3 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px "></span> Agregar Teléfonos
    </h2>
</div>

<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <form [formGroup]="phoneForm" class="row">
            <div class="form-group col-md-6">
                <label class="">Tipo de Teléfono <span class="text-danger">*</span></label>
                <ngx-select-dropdown [options]="phoneTypeList" formControlName="phoneType"
                    [config]='dropdownConfig("stringData")'></ngx-select-dropdown>

                <errors-component [controlName]="phoneForm.controls['phoneType']"></errors-component>
            </div>

            <div class="form-group col-md-6">
                <label for="number">Número de teléfono <span class="text-danger">*</span></label>
                <input formControlName="phoneNumber" class="form-control form-control-solid form-control-lg" id="tel"
                    mask="(000)-000-0000" type="mask" placeholder="Ej: 809-000-0000" label="Teléfono">

                <errors-component [controlName]="phoneForm.controls['phoneNumber']"></errors-component>
            </div>

            <div class="form-group col-md-6">
                <label for="" class="d-block">¿Contacto Primario? <span class="text-danger">*</span></label>
                <div class="form-check form-check-inline">
                    <label class="form-check-label mt-2 check-dtl">
                        <input [value]="principal.yes" formControlName="isPrimary" class="form-check-input"
                            type="radio">
                        Sí
                    </label>
                </div>
                <div class=" form-check form-check-inline">
                    <label class="form-check-label mt-2 check-dtl">
                        <input [value]="principal.no" formControlName="isPrimary" class="form-check-input" type="radio">
                        No
                    </label>
                </div>
            </div>

            <div class="form-group col-md-6" *ngIf="data.personType == 1 || data.personType == 2">
                <label for="number">Extensión </label>
                <input formControlName="extension" appNumbersOnly [allowDecimals]="false" [allowSign]="false"
                    class="form-control form-control-solid form-control-lg" type="text" autocomplete="0">

                <errors-component [controlName]="phoneForm.controls['extension']"></errors-component>

            </div>
        </form>
    </div>
</div>

<div class=" mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cancelar
    </button>
    <button [disabled]="phoneForm.invalid" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success"
        type="button" (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Aceptar</span>
    </button>
</div>