import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NewsConcept, NewsType } from '../models/PayrollNews.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-advanced-filter-by-payroll-news-list',
  templateUrl: './advanced-filter-by-payroll-news-list.component.html',
  styles: [
  ]
})
export class AdvancedFilterByPayrollNewsListComponent implements OnInit {

  advanceFilterForm: FormGroup = new FormGroup({
    sequence: new FormControl(null),
    personalIdentification: new FormControl(null),
    employee: new FormControl(null),
    newsType: new FormControl(null),
    newsConcept: new FormControl(null),
    registerBy: new FormControl(null),
    amountFrom: new FormControl(null),
    amountTo: new FormControl(null),
    newsCondition: new FormControl(null),
    manualValueFrom: new FormControl(null),
    manualValueTo: new FormControl(null),
    dateFrom: new FormControl(null),
    dateTo: new FormControl(null),
  })

  newtyTypes: NewsType[] = [];
  concepts: NewsConcept[] = [];
  conceptsOriginalList: NewsConcept[] = [];
  newsTypesSelected: any[] = []

  newtyTypesSelected: NewsType[] = [];
  conceptsSelected: NewsConcept[] = [];
  newsConditionsSelected: any[] = [];

  newsConditions: { item_id: number, item_text: string }[] =
    [
      { item_id: 1, item_text: 'Aprobada' },
      { item_id: 2, item_text: 'No Aprobada' },
    ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: true,
    limitSelection: -1
  };

  dropdownConfig(idField, displayKey) {
    return {
      singleSelection: false,
      idField: idField,
      textField: displayKey,
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      enableCheckAll: true,
      itemsShowLimit: 2,
      allowSearchFilter: true,
      searchPlaceholderText: 'Buscar',
      limitSelection: -1
    }
  }

  constructor(
    public dialogRef: MatDialogRef<AdvancedFilterByPayrollNewsListComponent>,
    private _toastService: ToastService,
    private payrollNewService: PayrollNewService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.getNewsType()
    this.getConcepts()
  }

  getCurrentSearch() {
    this.advanceFilterForm.setValue({
      ...this.data.activedfilters,
    })
    const newsTypeIdList =  this.advanceFilterForm.get('newsType').value.map((newsType:NewsType)=> newsType.newsTypeId)    
    this.getConceptsByNewsType(newsTypeIdList)
  }

  getNewsType() {
    this.payrollNewService.getNewsType().subscribe((res: any) => {
      if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al obtener los tipos de novedades"); return; }
      this.newtyTypes = res.dataList.filter(e => e.status);
    }, err => this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk."))
  }

  getConcepts() {
    this.payrollNewService.getNewsConcept().subscribe((res: any) => {
      if (res.errors.length > 0) { this._toastService.error("Ha ocurrido un error al obtener los conceptos"); return; }
      this.conceptsOriginalList = res.dataList.filter(e => e.status);
      this.concepts = this.conceptsOriginalList
      if (this.data.activedfilters) {
        this.getCurrentSearch()
      }

    }, err => this._toastService.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk."))
  }

  onNewsTypeSelect(newsType: NewsConcept) {
    this.newsTypesSelected.push(newsType.newsTypeId)
    this.getConceptsByNewsType(this.newsTypesSelected)
  }

  onNewsTypeDeselect(newsType: NewsConcept) {
    this.advanceFilterForm.get('newsConcept').setValue(null)
    if (this.advanceFilterForm.get('newsType').value.length === 0) {
      this.concepts = this.conceptsOriginalList;
      this.newsTypesSelected = []
      return
    }
    this.concepts = this.concepts.filter((concept: NewsConcept) => concept.newsTypeId !== newsType.newsTypeId)
  }
  onNewsTypeSelectAll() {
    this.advanceFilterForm.get('newsConcept').setValue(null)
    this.concepts = this.conceptsOriginalList;
    this.newsTypesSelected = []
  }
  onNewsTypeDeselectAll() {
    this.advanceFilterForm.get('newsConcept').setValue(null)
    this.concepts = this.conceptsOriginalList;
    this.newsTypesSelected = []
  }

  getConceptsByNewsType(newsTypeIdList){
    const newsTypeId = newsTypeIdList.join("-")
    this.payrollNewService.getNewsConceptsByFilter(newsTypeId).subscribe({
      next:(res:any)=>{
        this.concepts = res.dataList.filter((concept:any)=> concept.status === true)
      },
      error:(err:any)=>{
        this._toastService.error(err)
      }
    })
  }

  Cancel(): void {
    this.dialogRef.close();
  }

  Accept() {
    this.dialogRef.close(this.advanceFilterForm.value)
  }
}
