

export enum keyWord {
    TypeTestPostulation = "TypeTestPostulation",
    PayrollReports = "PayrollReports",
    AccountType = "AccountType",
    AcademicLevel = "AcademicLevel",
    BankingEntity = "BankingEntity",
    CauseRequisition = "CauseRequisition",
    EmployeeType = "EmployeeType",
    Gender = "Gender",
    IDType = "IDType",
    KnowledgeTechnology = "KnowledgeTechnology",
    Language = "Language",
    Locations = "Locations",
    MaritalStatus = "MaritalStatus",
    Months = "Months",
    RequisitionType = "RequisitionType",
    SkillLevel = "SkillLevel",
    WeekDays = "WeekDays",
    ContactType = "ContactType",
    EmailContactType = "EmailContactType",
    PersonalFile = "PersonalFile",
    BloodType = "BloodType",
    PaymentForm = "PaymentForm",
    OccupationalGroup = "OccupationalGroup",
    PersonalActionsType = "PersonalActionsType",
    TypeTest = "TypeTest",
    ActionChange = "ActionChange",
    EvaluationFactors = "EvaluationFactors",
    ValuationScheme = "ValuationScheme",
    FactorLevelEvaluation = "FactorLevelEvaluation",
    CandidateFilter = "CandidateFilter",
    RelationShip = "RelationShip",
    HoursAvailable = "HoursAvailable",
    TypeContractApproved = "TypeContractApproved",
    EmployeeStatusNow = "EmployeeStatusNow",
    InterviewResult = "InterviewResult",
    ResultTest = "ResultTest",
    workinghours = "workinghours",
    validationMap = "ValidationMAP",
    reasonRejection = "ReasonRejection",
    IncidentGrade = "IncidentGrade",
    EventGrade = 'eventGrade',
    EventType = "eventType",
    IncidentType = 'IncidentType',
    PersonalAction= 'PersonalAction',
    AFP= 'AFP',
    ARS= 'ARS',
    StatusGlobalValidation = 'StatusGlobalValidation',
    TypeExit = 'TypeExit',
    ReasonExit = 'ReasonExit',
    DisabilityType = 'DisabilityType',
    EntryList= 'EntryList',
    ExitList= 'ExitList',
    Payroll='PayrollType',
    HeaderConditions='HeaderCondition',
    PayrollType = 'PayrollType',
    ReasonRejectPayroll = 'ReasonRejectPayroll',
    LicenseType = 'LicenseType',
    typeBenefits = 'typeBenefits',
    OperativeStatus = 'OperativeStatus',
    HealthArea = 'HealthArea',
    MedicalSpecialties = 'MedicalSpecialties',
    MedicalVisitMotive = 'MedicalVisitMotive',
    RequisitionMethod = 'RequisitionMethod',
    ConceptType = 'ConceptType',
    ConceptProperties = 'ConceptProperties',
    ServiceCommissionMotives = 'ServiceCommissionMotives',
    CompetencyType = 'CompetencyType',
    CapabilityGrade = 'CapabilityGrade',
    GoalType = 'GoalType',
    GoalIndicatorType = 'GoalIndicatorType',
    ConceptValueType = 'ConceptValueType',
    PositionChangeType = 'PositionChangeType',
    PerformanceEvaluationCondition = "PerformanceEvaluationCondition",
    VacancyType = "VacancyType",
    PublicationClassification = "PublicationClassification",
    PostType = "PostType",
    OriginNews = "OriginNews",
    NewConditionStatus = "NewConditionStatus",
    Directed = "Directed",
    LinkType = "LinkType",
    MonitoringStatus = "MonitoringStatus",
    AdviceType = "AdviceType",
    PlanWorkingCondition = "ImprovementPlanWorkingCondition",
    Experience = "Experience",
    RelevantBehaviourResultType = "RelevantBehaviourResultType",
    RelevantBehaviourType = "RelevantBehaviourType",
    ReasonRejectionPositionPlacesRequest = "ReasonRejectionPositionPlacesRequest",
    PlanningRequestType = "PlanningRequestType",
    ReasonRejectionPlanning = "ReasonRejectionPlanning",
    ExcludedElementType= "ExcludedElementType",
    EvaluationReportType = "EvaluationReportType",
    UnitMeasurement = "UnitMeasurement",
    MedicationPortion = "MedicationPortion",
    PlanningAccount = "PlanningAccount",
    SourceTrainingEfficiency = "SourceTrainingEfficiency",
    MinimunAgeRequired = "MinimunAgeRequired",
    SocialSecurityBeneficiary = "SocialSecurityBeneficiary",
    CompanyBankAccount = "CompanyBankAccount",
    CompanySubBankAccount = "CompanySubBankAccount",
    NatureMedicalVisit = "NatureMedicalVisit",
    CompanyStructuresChangeType = "CompanyStructuresChangeType",
    JobDataType = "JobDataType",
    EmployeeCategory = "EmployeeCategory",
    ReasonsToCancelPersonalAction = "ReasonsToCancelPersonalAction",
    ReasonsToModifyPersonalAction = "ReasonsToModifyPersonalAction",
    PersonalActionsClassificationsOnUse = "PersonalActionsClassificationsOnUse",
    PersonalActionsClassificationTypesOnUse = "PersonalActionsClassificationTypesOnUse",
    TeacherReviewEvidenceType = "TeacherReviewEvidenceType",
}
