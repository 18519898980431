<div class="card card-custom" data-card="true" id="kt_card_3">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-agregar-archivo-1 icon-3x text-danger mr-2"></i>
                <h1 class="text-primary">Resumen por mes</h1>
            </div>
        </div>   
        <div class="card-toolbar">

            <button (click)="getRouterToBack()" class="btn btn-pill font-weight-bolder btn-shadow btn-warning">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
            </button>

        </div>     
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
         <button *ngIf="enableAdd" data-toggle="modal" 
            class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto" (click)="openModalAddNew()">
            <i class="flaticon-mas icon-2x" data-toggle="tooltip" data-original-title="Agregar nuevo detalle de regalia"></i>
            Agregar
          </button>
          <button *ngIf="enableAdd" data-toggle="modal" (click)="openModalBulkloadNews()" 
            class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto">
            <i class="flaticon-recursos-humanos-6 icon-2x"></i> Carga Masiva
          </button>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div data-toggle="tooltip" data-original-title="Opciones de exportar" class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                       
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white"></i> Exportar
                    </button>    
                    
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                        <a class="dropdown-item align-items-center"
                          href="assets/docs/plantilla-carga-masiva/Carga-Masiva-Regalia.xlsx"
                          download="Plantilla Carga Masiva.xlsx">
                          <i class="flaticon-excel icon-2x text-success mr-3"></i>
                          Plantilla Carga Masiva
                        </a>                        
                      </div>
                </div>

                <div class="col text-nowrap">
                    <el-search-bar #searchBar [records]="royaltySummaryListOriginal" (onKeyChange)="onKeyChange($event)"
                        [keyValue]="filterText" (onChange)="getFilteredList($event)"
                        (onKeyParameterChange)="onKeyParameterChange($event)"
                        [itemForSearching]="searchBarDisplayNames"></el-search-bar>
                </div>
            </div>
        </div>
    </div>


    <div class="card-body" kt-hidden-height="282">
        <div class="table-responsive">
            <table class="table table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-left">Documento de identidad</th>
                        <th class="text-left">Nombre</th>
                        <th class="text-left">Cargo</th>
                        <th class="text-left">Unidad organizativa</th>
                        <th class="text-left">Usuario</th>
                        <th class="text-left">Fecha de modificacion</th>
                        <th class="text-right">Total a pagar</th>
                        <th class="text-right">Enero {{headerYear}}</th>
                        <th class="text-right">Febrero {{headerYear}}</th>
                        <th class="text-right">Marzo {{headerYear}}</th>
                        <th class="text-right">Abril {{headerYear}}</th>
                        <th class="text-right">Mayo {{headerYear}}</th>
                        <th class="text-right">Junio {{headerYear}}</th>
                        <th class="text-right">Julio {{headerYear}}</th>
                        <th class="text-right">Agosto {{headerYear}}</th>
                        <th class="text-right">Septiembre {{headerYear}}</th>
                        <th class="text-right">Octubre {{headerYear}}</th>
                        <th class="text-right">Noviembre {{headerYear}}</th>
                        <th class="text-right">Diciembre {{headerYear}}</th>                        
                        <th class="text-center" style="width: 100px;">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let royaltySummary of paginatedRecords">
                        <td class="text-left">{{royaltySummary.personalIdentification}}</td>
                        <td class="text-left">{{royaltySummary.name}}</td>
                        <td class="text-left">{{royaltySummary.positionName}}</td>
                        <td class="text-left">{{royaltySummary.departmentName}}</td>
                        <td class="text-left">{{royaltySummary.createUser}}</td>
                        <td class="text-left">{{royaltySummary.modifyDate | date: 'dd/MM/yyyy HH:mm'}}</td>
                        <td class="text-right">{{royaltySummary.totalPayable | currency: '$'}}</td>
                        <td class="text-right">{{royaltySummary.jan | currency: '$'}}</td>
                        <td class="text-right">{{royaltySummary.feb | currency: '$'}}</td>
                        <td class="text-right">{{royaltySummary.mar | currency: '$'}}</td>
                        <td class="text-right">{{royaltySummary.apr | currency: '$'}}</td>
                        <td class="text-right">{{royaltySummary.may | currency: '$'}}</td>
                        <td class="text-right">{{royaltySummary.jun | currency: '$'}}</td>
                        <td class="text-right">{{royaltySummary.jul | currency: '$'}}</td>
                        <td class="text-right">{{royaltySummary.ago | currency: '$'}}</td>
                        <td class="text-right">{{royaltySummary.sep | currency: '$'}}</td>
                        <td class="text-right">{{royaltySummary.oct | currency: '$'}}</td>
                        <td class="text-right">{{royaltySummary.nov | currency: '$'}}</td>
                        <td class="text-right">{{royaltySummary.dec | currency: '$'}}</td>
                        

                        <td class=" px-5">
                            <div class="d-flex justify-content-center px-5" style="width: max-content">
                                <div class="justify-content-start  px-5">
                                    <button (click)="showDetail(royaltySummary)" class="btn btn-icon btn-light-info btn-sm m-1"
                                        *ngIf="privilege?.viewAction.value">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Detalles"></i>
                                    </button>                                    
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>

                <el-caption #paginator [originalRecordsLength]="royaltySummaryListOriginal.length" [listedRecordsLength]="paginatedRecords.length"
                    [registerQuantity]="royaltySummaryList.length" 
                    [records]="royaltySummaryList" (onChage)="getPaginatedRecords($event)"></el-caption>

            </div>
        </div>
    </div>

    <export-data-to-pdf [title]="'Nómina regalia'" id="dataToPrint">
        <ng-container thead>
            <th class="text-left">Documento de identidad</th>
            <th class="text-left">Nombre</th>
            <th class="text-left">Cargo</th>
            <th class="text-left">Unidad organizativa</th>
            <th class="text-left">Usuario</th>
            <th class="text-left">Fecha de modificacion</th>
            <th class="text-left">Total a pagar</th>
            <th class="text-left">Enero {{headerYear}}</th>
            <th class="text-left">Febrero {{headerYear}}</th>
            <th class="text-left">Marzo {{headerYear}}</th>
            <th class="text-left">Abril {{headerYear}}</th>
            <th class="text-left">Mayo {{headerYear}}</th>
            <th class="text-left">Junio {{headerYear}}</th>
            <th class="text-left">Julio {{headerYear}}</th>
            <th class="text-left">Agosto {{headerYear}}</th>
            <th class="text-left">Septiembre {{headerYear}}</th>
            <th class="text-left">Octubre {{headerYear}}</th>
            <th class="text-left">Noviembre {{headerYear}}</th>
            <th class="text-left">Diciembre {{headerYear}}</th>   
        </ng-container>
        <ng-container tbody>
            <tr *ngFor="let royaltySummary of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.royaltySummaryList" data-row="0" class="datatable-row" style="left: 0px;">
                <td class="text-left">{{royaltySummary.personalIdentification}}</td>
                <td class="text-left">{{royaltySummary.name}}</td>
                <td class="text-left">{{royaltySummary.positionName}}</td>
                <td class="text-left">{{royaltySummary.departmentName}}</td>
                <td class="text-left">{{royaltySummary.createUser}}</td>
                <td class="text-left">{{royaltySummary.modifyDate | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td class="text-left">{{royaltySummary.totalPayable | currency: 'RD$'}}</td>
                <td class="text-left">{{royaltySummary.jan | currency: 'RD$'}}</td>
                <td class="text-left">{{royaltySummary.feb | currency: 'RD$'}}</td>
                <td class="text-left">{{royaltySummary.mar | currency: 'RD$'}}</td>
                <td class="text-left">{{royaltySummary.apr | currency: 'RD$'}}</td>
                <td class="text-left">{{royaltySummary.may | currency: 'RD$'}}</td>
                <td class="text-left">{{royaltySummary.jun | currency: 'RD$'}}</td>
                <td class="text-left">{{royaltySummary.jul | currency: 'RD$'}}</td>
                <td class="text-left">{{royaltySummary.ago | currency: 'RD$'}}</td>
                <td class="text-left">{{royaltySummary.oct | currency: 'RD$'}}</td>
                <td class="text-left">{{royaltySummary.sep | currency: 'RD$'}}</td>
                <td class="text-left">{{royaltySummary.nov | currency: 'RD$'}}</td>
                <td class="text-left">{{royaltySummary.dec | currency: 'RD$'}}</td>
            </tr>
        </ng-container>
    </export-data-to-pdf>
