import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CandidateRegService } from 'src/app/services/http-client/candidateRegService.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { skillsServices } from 'src/app/services/http-client/skillsServices.service';
import { levelSkills, personsSkills, skills, typeLevelSkills, typeSkills } from 'src/app/shared/models/candidate-registration.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import Swal from 'sweetalert2';
import { CandidateRegistrationComponent } from '../../candidate-registration.component';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkillsComponent implements OnInit {
  public data: personsSkills[] = [];
  public dataGv = []
  public typeSkillsList: typeSkills[] = [];
  public skillsList: skills[] = [];
  public typeLevelSkillsList: typeLevelSkills[] = [];
  public levelSkillsList: levelSkills[] = []

  public typeSkillsData: typeSkills
  public SkillsData: skills
  public typeLevelSkillsData: typeLevelSkills
  public levelSkillsData: levelSkills
  public idPerson = 0;

  public typeLevelSkill = ''
  public typeSkill = ''
  public companyId = this.authInfo.getCompanyId();

  constructor(
    private srvCandidateService: CandidateRegService,
    private srvPersonService: PersonService,
    private srvSkill: skillsServices,
    private toast: ToastService,
    private route: ActivatedRoute, private cdr: ChangeDetectorRef, private authInfo: AuthInfoService) {
    this.idPerson = Number(this.route.snapshot.params["id"] || 0)
  }

  public config = {
    displayKey: 'description', // if objects array passed which key to be displayed defaults to description
    search: true,
    placeholder: 'Seleccionar',
    searchPlaceholder: 'Buscar',
    noResultsFound: 'No hay data para mostrar'
  };


  ngOnInit(): void {
    this.loadDDL()
  }

  changeDetector() {
    this.cdr.detectChanges();
  }

  loadData() {
    if (this.idPerson > 0) {
      this.srvPersonService.getPersonSkills(this.idPerson).subscribe(res => {
        if (res.succeded == false) {
          this.toast.error(res.errors[0]);
        }

        this.data = res.dataList;

        for (let i = 0; i < this.data.length; i++) {
          const item = this.data[i];
          this.data[i].levelSkills = this.data[i].levelSkillsId;

          let d = {
            idPersonSkills: 0,
            idPerson: 0,
            idSkills: item.skillsId,
            idTypeLivelSkills: item.typeLivelSkillsId,
            levelSkills: item.levelSkillsId,
            idCompany: 1,
            status: true,
            TypeSkillsDesc: '',
            SkillsDesc: '',
            TypeLevelSkillsDesc: '',
            LevelSkillsDesc: ''
          }
          this.dataGv.push(d)
        }

        forkJoin({
          TypeLevelSkills: this.srvSkill.getTypeLevelSkills(0, this.companyId),
          skill: this.srvSkill.getSkills(0, this.companyId),
          LevelSkills: this.srvSkill.getLevelSkills(0, this.companyId),
          TypeSkills: this.srvSkill.getTypeSkills(0, this.companyId)
        }).subscribe(data => {
          let TypeLevelSkills = data.TypeLevelSkills.dataList
          let LevelSkills = data.LevelSkills.dataList
          let dataPersonSkill = res.dataList;
          let TypeSkills = data.TypeSkills.dataList

          for (let n = 0; n < dataPersonSkill.length; n++) {
            let Skill1 = this.getSkill1(dataPersonSkill[n], TypeSkills)
            this.dataGv[n].TypeSkillsDesc = Skill1
            this.dataGv[n].SkillsDesc = dataPersonSkill[n].skills

            let levelSkill = {
              typeLeveSkillId: 0,
              description: ''
            }

            levelSkill = this.getSkill3(LevelSkills, dataPersonSkill[n].levelSkillsId)
            this.dataGv[n].LevelSkillsDesc = levelSkill.description

            let skill3 = this.getSkill2(TypeLevelSkills, levelSkill.typeLeveSkillId)
            this.dataGv[n].TypeLevelSkillsDesc = skill3
          }
        }, error => {
          this.toast.error(error)
        });
      })
    }
  }

  getSkill1(dataPersonSkill, TypeSkills) {
    for (let s = 0; s < TypeSkills.length; s++) {
      if (TypeSkills[s].typeSkillsId == dataPersonSkill.typeSkillsId) {
        return TypeSkills[s].description
      }
    }
  }

  getSkill2(TypeLevelSkill, typeLevelSkillId) {
    for (let i = 0; i < TypeLevelSkill.length; i++) {
      const item = TypeLevelSkill[i];
      if (item.typeLevelSkillsId == typeLevelSkillId) {
        return TypeLevelSkill[i].description
      }
    }
  }

  getSkill3(LevelSkills, TypeLevelSkills) {
    for (let i = 0; i < LevelSkills.length; i++) {
      const item = LevelSkills[i];
      if (LevelSkills[i].levelSkillsId == TypeLevelSkills) {
        return {
          typeLeveSkillId: LevelSkills[i].typeLevelSkillsId, description: item.description
        }
      }
    }
  }

  loadDDL() {
    this.srvSkill.getTypeSkills(0, this.companyId).subscribe(e => {
      if (e.succeded) {
        this.typeSkillsList = e.dataList
        this.typeSkillsData = null
        this.loadData()
      } else {
        this.toast.error(e.errors[0]);
      }
    }, error => {
      this.toast.error(error)
    })
  }

  loadSkills(idSkills: number) {
    this.srvSkill.getSkills(idSkills, this.companyId).subscribe(e => {
      if (e.succeded) {
        this.skillsList = e.dataList
        this.SkillsData = null
        this.levelSkillsData = null
        this.typeLevelSkillsData = null
        this.typeLevelSkillsList = []
        this.levelSkillsList = []
      } else {
        this.toast.error(e.errors[0]);
      }
    }, error => {
      this.toast.error(error)
    })
  }

  loadTypeLevelSkill(idTypeLevelSkills: number) {
    this.srvSkill.getTypeLevelSkills(idTypeLevelSkills, this.companyId).subscribe(e => {
      if (e.succeded) {
        this.typeLevelSkillsList = e.dataList
        this.typeLevelSkillsData = null
        this.levelSkillsData = null
        this.levelSkillsList = []
      } else {
        this.toast.error(e.errors[0]);
      }
    }, error => {
      this.toast.error(error)
    })
  }

  loadLevelSkills(typeLevelSkillsId) {
    this.srvSkill.getLevelSkills(typeLevelSkillsId, this.companyId).subscribe(e => {
      if (e.succeded) {
        this.typeLevelSkillsList = e.dataList
        this.typeLevelSkillsData = null
      } else {
        this.toast.error(e.errors[0]);
      }
    }, error => {
      this.toast.error(error)
    })
  }

  TypeLevelSkill(idtypeLevelSkill) {
    this.srvSkill.getTypeLevelSkill(idtypeLevelSkill, this.companyId).subscribe(e => {
      if (e.succeded) {
        this.levelSkillsList = e.dataList
        this.levelSkillsData = null
      } else {
        this.toast.error(e.errors[0]);
      }
    }, error => {
      this.toast.error(error)
    })
  }

  changeTypeSkills(e) {
    if (e.value.typeSkillsId)
      this.loadSkills(e.value.typeSkillsId)
    else
      this.SkillsData = null
    this.levelSkillsData = null
    this.typeLevelSkillsData = null
    this.typeLevelSkillsList = []
    this.levelSkillsList = []
  }

  changeSkills(e) {
    if (e.value.skillsId)
      this.loadTypeLevelSkill(e.value.skillsId)
    else
      this.typeLevelSkillsData = null
    this.levelSkillsData = null
    this.levelSkillsList = []

  }

  changeTypeLevelSkill(e) {
    if (e.value.typeLevelSkillsId)
      this.TypeLevelSkill(e.value.typeLevelSkillsId)
    else
      this.levelSkillsList = null
    this.levelSkillsData = null
  }

  changeLevelSkills(e) {

  }

  add() {
    try {
      let m = {
        personSkillsId: 0,
        personId: 0,
        skillsId: this.SkillsData.skillsId,
        typeLivelSkillsId: this.typeLevelSkillsData.typeLevelSkillsId,
        levelSkills: this.levelSkillsData.levelSkillsId,
        companyId: this.authInfo.getCompanyId(),
        status: true
      } as personsSkills

      let d = {
        idPersonSkills: 0,
        idPerson: 0,
        idSkills: this.SkillsData.skillsId,
        idTypeLivelSkills: this.typeSkillsData.typeSkillsId,
        levelSkills: this.levelSkillsData.levelSkillsId,
        idCompany: 1,
        status: true,
        TypeSkillsDesc: this.typeSkillsData.description,
        SkillsDesc: this.SkillsData.description,
        TypeLevelSkillsDesc: this.typeLevelSkillsData.description,
        LevelSkillsDesc: this.levelSkillsData.description
      }

      this.dataGv.push(d)
      this.data.push(m);

      this.typeSkillsData = null
      this.SkillsData = null
      this.typeLevelSkillsData = null
      this.levelSkillsData = null
      this.changeDetector();
      document.getElementById('btnCloseSkill').click()
    } catch (error) {
      this.toast.error(error)
    }
  }

  remove(i) {
    let id = this.data[i].personSkillsId;
    let idPersonSkills = this.data[i].personSkillsId;

    if (id == 0) {
      this.data.splice(i, 1);
      this.dataGv.splice(i, 1);
    } else {
      this.swalQuestion(() => {
        this.srvSkill.delete(id).subscribe(e => {
          this.toast.success('Registro borrado')
          this.data.splice(i, 1);
          this.dataGv.splice(i, 1);
          this.changeDetector();
        }, error => {
          this.toast.error(error)
        });
      });
    }
  }

  isValid() {
    try {
      if (this.typeSkillsData.description == undefined || this.SkillsData.description == undefined || this.typeLevelSkillsData.description == undefined || this.levelSkillsData.description == undefined) {
        return true
      } else {
        return false
      }
    } catch (error) {
      // this.toast.error(error)
      return true
    }
  }

  swalQuestion(fn: () => void) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      title: "¿Está seguro que desea eliminar este registro?",
      icon: "question"
    }).then(res => {
      if (res.isConfirmed) {
        fn();
      }
    });
  }

}
