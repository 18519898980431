<div class="card card-custom ">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon">
                <i class="flaticon-retocar icon-3x text-danger"></i>
            </span>
            <h1>
                Publicar Vacantes
                <!-- <small>sub title</small> -->
            </h1>
        </div>
        <div class="card-toolbar"></div>
    </div>
    <div class="card-body dtl_font">
        <form >
            <div class="row gutter-b">
                <div class="form-group col-xl-8 col-lg-12">
                    <label>Vacante</label>
                    <ngx-select-dropdown readonly class=" flex-1" ></ngx-select-dropdown>
                </div>
                <div class="form-group col-xl-4 col-lg-6">
                    <label>Plaza</label>
                    <input type="text" readonly class="form-control" >
                </div>
            </div>
            <div class="row gutter-b">
                <div class="col-md-9">
                    <div class="border-primary rounded-lg p-5">
                        <div class="row">
                            <div class="form-group col-xl-4 col-lg-6 danger">
                                <label class="">Recinto</label>
                                <ngx-select-dropdown  class="flex-1" ></ngx-select-dropdown >
                            </div>
                            <div class="form-group col-xl-4 col-lg-6">
                                <label>Unidad Organizativa</label>
                                <input type="text" readonly class="form-control form-control-lg">
                            </div>
                            <div class="form-group col-xl-2 col-lg-6">
                                <label>Edad Minima</label>
                                <input type="number" class="form-control form-control-solid form-control-lg" >
                            </div>
                            <div class="form-group col-xl-2 col-lg-6">
                                <label>Edad Máxima</label>
                                <input type="number" class="form-control form-control-solid form-control-lg">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-xl-8 col-lg-12">
                                <label class="">Causa de la Plaza Vacante</label>
                                <ngx-select-dropdown class=" flex-1"  ></ngx-select-dropdown>
                            </div>
                            <div class="form-group col-xl-2 col-lg-6">
                                <label>Años Experiencia</label>
                                <input type="number" class="form-control form-control-solid form-control-lg">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-xl-4 col-lg-6">
                                <label class="">Tipo de requisición</label>
                                <ngx-select-dropdown class=" flex-1" ></ngx-select-dropdown>
                            </div>
                            <div class="form-group col-xl-4 col-lg-6">
                                <label class="">Fecha de Ingreso</label>
                                <input type="date" class="form-control form-control-solid form-control-lg">
                            </div>
                            <div class="form-group col-xl-4 col-lg-6">
                                <label class="d-block">Sexo<span class="text-danger text-monospace legend"> *</span>
                                </label>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label mt-2 check-dtl plz">
                                        <input class="form-check-input" required="" name="sex" type="radio" >
                                        Genero
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-xl-4 col-lg-6">
                                <label class="">Nivel Académico</label>
                                <ngx-select-dropdown class=" flex-1" ></ngx-select-dropdown>
                            </div>
                            <div class="form-group col-xl-4 col-lg-6">
                                <label>Horas Trabajadas</label>
                                <ngx-select-dropdown class=" flex-1"  ></ngx-select-dropdown>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label>Otros Requísitos Deseables</label>
                                <textarea class="form-control form-control-solid form-control-lg"
                                ></textarea>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Descripción General de las Funciones</label>
                                <textarea class="form-control form-control-solid form-control-lg"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group card">
                        <div class="card-body">
                            <div class="PDF"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-12">
                    <div class="card card-custom border-primary gutter-b">
                        <div class="card-header mt-3">
                            <div class="card-title align-items-center">
                                <i class="flaticon-mortarboard icon-3x text-primary mr-3"></i>
                                <h3 class="text-primary m-0">Carreras Preferibles</h3>
                            </div>
                            <div class="card-toolbar ml-auto">
                                <a class="btn btn-pill font-weight-bolder btn-shadow btn-success">
                                    <i class="flaticon2-plus"></i> Agregar
                                </a>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table mt-2 table-vertical-center text-center" >
                                    <thead>
                                        <tr class="bg-primary">
                                            <th>Areas de estudio</th>
                                            <th>Nivel</th>
                                            <th class="text-center">Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td>career</td>
                                            <td></td>
                                            <td class="text-center">
                                                <a class="btn btn-sm btn-light-danger btn-icon mr-2" data-toggle="tooltip" data-original-title="Remover">
                                                    <i class="flaticon-delete icon-2x"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="d-flex justify-content-end align-items-center flex-wrap">
                                    <div class="d-flex align-items-center py-3 mr-2"><select
                                            class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light ng-untouched ng-pristine ng-valid"
                                            style="width: 75px;" ng-reflect-model="10">
                                            <option value="1" ng-reflect-value="1">1</option>
                                            <option value="5" ng-reflect-value="5">5</option>
                                            <option value="10" ng-reflect-value="10">10</option>
                                            <option value="15" ng-reflect-value="15">15</option>
                                            <option value="20" ng-reflect-value="20">20</option>
                                        </select><span class="text-muted">Mostrando página 1 de 1 </span></div>
                                    <div class="d-flex flex-wrap py-2 mr-3">
                                        <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                                            <i class="ki ki-bold-arrow-back icon-xs"></i></button>
                                        <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                                            <i class="ki ki-bold-arrow-next icon-xs"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12">
                    <div class="card card-custom border-primary gutter-b">
                        <div class="card-header mt-3">
                            <div class="card-title align-items-center">
                                <i class="flaticon-gestion-de-proyectos icon-3x text-primary mr-3"></i>
                                <h3 class="text-primary m-0">Habilidades Preferibles</h3>
                            </div>
                            <div class="card-toolbar ml-auto">
                                <a  class="btn btn-pill font-weight-bolder btn-shadow btn-success">
                                    <i class="flaticon2-plus"></i> Agregar
                                </a>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table mt-2 table-vertical-center text-center" >
                                    <thead>
                                        <tr class="bg-primary">
                                            <th>Competencias</th>
                                            <th>Nivel</th>
                                            <th class="text-center">Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>skill.description</td>
                                            <td>skillLevel.description</td>
                                            <td class="text-center">
                                                <a class="btn btn-sm btn-light-danger btn-icon mr-2" data-toggle="tooltip" data-original-title="Remover">
                                                    <i class="flaticon-delete icon-2x"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="d-flex justify-content-end align-items-center flex-wrap">
                                    <div class="d-flex align-items-center py-3 mr-2"><select
                                            class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light ng-untouched ng-pristine ng-valid"
                                            style="width: 75px;" ng-reflect-model="10">
                                            <option value="1" ng-reflect-value="1">1</option>
                                            <option value="5" ng-reflect-value="5">5</option>
                                            <option value="10" ng-reflect-value="10">10</option>
                                            <option value="15" ng-reflect-value="15">15</option>
                                            <option value="20" ng-reflect-value="20">20</option>
                                        </select><span class="text-muted">Mostrando página 1 de 1 </span></div>
                                    <div class="d-flex flex-wrap py-2 mr-3">
                                        <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                                            <i class="ki ki-bold-arrow-back icon-xs"></i></button>
                                        <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                                            <i class="ki ki-bold-arrow-next icon-xs"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12">
                    <div class="card card-custom border-primary gutter-b">
                        <div class="card-header mt-3">
                            <div class="card-title align-items-center">
                                <i class="flaticon-riesgo icon-3x text-primary mr-3"></i>
                                <h3 class="text-primary m-0">Beneficios de la Plaza</h3>
                            </div>
                            <div class="card-toolbar ml-auto">
                                <a  class="btn btn-pill font-weight-bolder btn-shadow btn-success">
                                    <i class="flaticon2-plus"></i> Agregar
                                </a>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table mt-2 table-vertical-center text-center" >
                                    <thead>
                                        <tr class="bg-primary">
                                            <th>Beneficios</th>
                                            <th>Porc%</th>
                                            <th class="text-center">Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td>benefit.description</td>
                                            <td></td>
                                            <td class="text-center">
                                                <a class="btn btn-sm btn-light-danger btn-icon mr-2" data-toggle="tooltip" data-original-title="Remover">
                                                    <i class="flaticon-delete icon-2x"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="d-flex justify-content-end align-items-center flex-wrap">
                                    <div class="d-flex align-items-center py-3 mr-2"><select
                                            class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light ng-untouched ng-pristine ng-valid"
                                            style="width: 75px;" ng-reflect-model="10">
                                            <option value="1" ng-reflect-value="1">1</option>
                                            <option value="5" ng-reflect-value="5">5</option>
                                            <option value="10" ng-reflect-value="10">10</option>
                                            <option value="15" ng-reflect-value="15">15</option>
                                            <option value="20" ng-reflect-value="20">20</option>
                                        </select><span class="text-muted">Mostrando página 1 de 1 </span></div>
                                    <div class="d-flex flex-wrap py-2 mr-3">
                                        <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                                            <i class="ki ki-bold-arrow-back icon-xs"></i></button>
                                        <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                                            <i class="ki ki-bold-arrow-next icon-xs"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer d-flex justify-content-between">
                <a [routerLink]="['map/vacancies']"  class="btn btn-light-primary font-weight-bold">Ir a Lista</a>
                <button type="submit" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success">
                    <i class="flaticon-disco-flexible"></i> Publicar
                </button>
            </div>
        </form>
    </div>
</div>
<script>
    var uppy = new Uppy.Core()
      .use(Uppy.Dashboard, {
        inline: true,
        target: '#drag-drop-area'
      })
      .use(Uppy.Tus, {endpoint: 'https://tusd.tusdemo.net/files/'})

    uppy.on('complete', (result) => {
    })
  </script>
