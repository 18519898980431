<div class="card card-custom mantEmpleado">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon">
                  <i class="flaticon-nomina-de-sueldos icon-3x text-danger"></i>
            </span>
            <h2 class="d-flex flex-column text-primary">
                Aprobación Planificación RRHH (DIGEPRES)
            </h2>
        </div>

        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard">
            <button class="btn btn-success btn-pill font-weight-bolder mt-2 mr-4 mr-0-mobile col-md-auto mb-2-mobile">
                <i class="flaticon-check icon-2x"></i> Aprobar
            </button>
            
            <button data-toggle="modal" class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto" data-toggle="modal"
            data-target="#dashboard">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </button>
        </div>
    </div>

    <div class="card-body">                          
        <div class="row">
            <div class="table-responsive">
                <table class="table table-hover table-vertical-center">
                    <thead class="bg-primary">
                        <tr>
                            <th class="text-center">
                                <label class="checkbox text-center checkbox-lg checkbox-success checkbox-inline m-0">
                                    <input type="checkbox">
                                    <span></span>
                                </label>
                            </th>
                            <th class="text-center">Área</th>
                            <th class="text-center">Cargo</th>
                            <th class="text-center">Grupo Ocupacional</th>
                            <th class="text-center">Creación</th>
                            <th class="text-center">Número Plazas</th>
                            <th class="text-center">Forma Cobertura</th>
                            <th class="text-center">Fecha Estimada Ingreso</th>
                            <th class="text-center">Salario Estimado</th>
                            <th class="text-center">Total Estimado Anual</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="center">
                                <label class="checkbox text-center checkbox-lg checkbox-light-warning checkbox-inline flex-shrink-0 m-0">
                                    <input type="checkbox" value="1">
                                    <span></span>
                                </label>
                            </td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                        </tr>
                        <tr>
                            <td class="center">
                                <label class="checkbox text-center checkbox-lg checkbox-light-warning checkbox-inline flex-shrink-0 m-0">
                                    <input type="checkbox" value="1">
                                    <span></span>
                                </label>
                            </td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                        </tr>
                        <tr>
                            <td class="center">
                                <label class="checkbox text-center checkbox-lg checkbox-light-warning checkbox-inline flex-shrink-0 m-0">
                                    <input type="checkbox" value="1">
                                    <span></span>
                                </label>
                            </td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                            <td class="text-center">Tests</td>
                        </tr>
                          
                                                                
                    </tbody>
                </table>
            </div>
        </div>      
    </div>
</div>