<div class="row">
    <el-search-bar placeholder="Buscar registro" (onFilter)="search($event)" class="ml-3 gutter-b">
    </el-search-bar>
</div>
<table class="table table-hover table-vertical-center">
    <thead>
        <tr class="bg-primary">
            <th>Unidad Organizativa</th>
            <th>ID Servidor Público</th>
            <th>Servidor Público</th>
            <th>Cédula</th>
            <th>Acciones</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Solutions</td>
            <td>2312</td>
            <td>Omar Mendez</td>
            <td>001-0005322-1</td>
            <td>
                <a data-toggle="tooltip" data-placement="top" title="Editar Servidor Público" class="btn btn-light-success btn-icon btn-sm"><i data-target="#openLoad" data-toggle="modal" class="flaticon-edit"></i></a>
                <a data-toggle="tooltip" data-placement="top" title="Eliminar Servidor Público" class="btn btn-link-danger btn-icon btn-sm" (click)="deleteAlert()"><i class="flaticon-delete"></i></a>
            </td>
        </tr>
    </tbody>
</table>
<div class="d-flex justify-content-end align-items-center flex-wrap">
    <div class="d-flex align-items-center py-3 mr-2"><select class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light ng-untouched ng-pristine ng-valid" style="width: 75px;" ng-reflect-model="10">
            <option value="1" ng-reflect-value="1">1</option>
            <option value="5" ng-reflect-value="5">5</option>
            <option value="10" ng-reflect-value="10">10</option>
            <option value="15" ng-reflect-value="15">15</option>
            <option value="20" ng-reflect-value="20">20</option>
        </select><span class="text-muted">Mostrando página 1 de 1 </span></div>
    <div class="d-flex flex-wrap py-2 mr-3"><button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled=""><i
                class="ki ki-bold-arrow-back icon-xs"></i></button><button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled=""><i
                class="ki ki-bold-arrow-next icon-xs"></i></button></div>
</div>

<el-modal target="openLoad" size="xl" style="z-index: 9999;" title="Servidor Público Omar Mendez">
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h3>Conceptos de Pago</h3>
                </div>
                <div class="card-body p-2">
                    <table class="table table-bordered table-vertical-center">
                        <thead class="bg-primary">
                            <th><span>Concepto</span></th>
                            <th><span>Cantidad</span></th>
                            <th><span>Monto</span></th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Sueldo Básico</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Horas Normales</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Horas Extras</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Días Feriados</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Vacaciones </strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Licencias</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Bono Transporte</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Bono Desmp. Individual </strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Bono Desmp. Colectivo</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Bono Nacim. Hijo</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Bono Escolar Dependiente</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Bono Capacitación</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Otros Ingresos</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h3>Conceptos de Descuento</h3>
                </div>
                <div class="card-body p-2">
                    <table class="table table-bordered">
                        <thead class="bg-primary">
                            <th><span>Concepto</span></th>
                            <th><span>Cantidad</span></th>
                            <th><span>Monto</span></th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">ARS</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">ARL</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">SFS</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">ISR</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Cooperativa</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Cafetería</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Transporte</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Gimnasio</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Consumo Tiendas</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Seg. Complementario </strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Inavi </strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Servidor Público feliz </strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="switch switch-sm switch-outline switch-icon switch-success">
                                        <label class="d-flex align-items-center">
                                            <input type="checkbox" name="">
                                            <span></span>
                                    <strong class="text-primary ml-3">Seguro de vida</strong>
                                    </label>
                                    </span>
                                </td>
                                <td><input type="text" class="form-control" placeholder=""></td>
                                <td><input type="text" class="form-control" placeholder="$"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-end mt-3">
        <button class="btn btn-pill font-weight-bolder btn-shadow btn-warning d-flex mr-3" data-dismiss="modal"><i class="flaticon2-left-arrow-1"></i><span> Volver </span></button>
        <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary d-flex mr-3" data-dismiss="modal"><i class="flaticon-calculadora-1"></i><span> Calcular </span></button>
        <button class="btn btn-pill font-weight-bolder btn-shadow btn-success d-flex mr-3" data-dismiss="modal"><i class="flaticon-disco-flexible"></i><span> Guardar </span></button>
    </div>
</el-modal>