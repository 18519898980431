<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>Detalles
    </h2>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">
    <div class="row">
        <div class="table-responsive px-0-mobile">
            <table class="table table-hover table-vertical-center">
                <thead class="bg-primary">
                    <tr>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-left">Cargo</th>
                        <th class="text-left">Grupo Ocupacional</th>
                        <th class="text-center">Salario</th>
                    </tr>
                </thead>
                <tbody *ngIf="planningDetailsRecords.length>0">
                    <tr *ngFor="let detail of planningDetailsRecords">
                        <td class="text-left">{{detail.departmentName || 'N/A'}}</td>
                        <td class="text-left">{{detail.positionName || 'N/A'}}</td>
                        <td class="text-left">{{detail.occupationalGroup || 'N/A'}}</td>
                        <td class="text-center">{{detail.salary | currency: "RD$"}}
                        </td>
                    </tr>

                </tbody>
            </table>
            <div>
                <el-caption *ngIf="planningDetails"
                    [originalRecordsLength]="planningDetails.length"
                    [listedRecordsLength]="planningDetails.length"
                    [registerQuantity]="planningDetails.length"
                    [records]="planningDetails"
                    (onChage)="getPaginatedRecords($event)">
                </el-caption>
            </div>
        </div>
    </div>
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="closeModal()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>