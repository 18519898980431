<div id="reclutamiento_seleccion" class="offcanvas rh offcanvas-left pt-5 pb-10">
  <!--begin::Header-->
  <div class=" align-items-center border-bottom d-flex justify-content-between mb-3 pb-2 ">
    <h2 class="text-danger text-center pl-5">Reclutamiento y Selección</h2>
    <div class="offcanvas-close mt-n1 pr-5 d-flex">
      <!-- <a href="javascript:void(0);" class="btn btn-icon btn-outline-dark mr-4" id="configuracion_toggle">
        <i class="ki ki-gear icon-2x" toggle="tooltip" title="Configuración"></i>
      </a> -->
      <a href="javascript:void(0);" class="btn btn-icon btn-light-danger btn-hover-primary "
        id="reclutamiento_seleccion_close">
        <i class="flaticon2-back"></i>
      </a>
    </div>
  </div>
  <div class="offcanvas-content px-5 scrollPanel">
    <div class="tab-content">
      <div class=" tab-pane fade pt-3 pr-5 mr-n5 show active" id="" role="tabpanel">
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/personal-requisition" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideMenu()">
              <i class="flaticon-busqueda-de-trabajo icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Requisición de Personal</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/vacancies" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideMenu()">
              <i class="flaticon-retocar icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Habilitar Vacantes</span>
              <!-- <span class="btn btn-sm btn-light font-weight-bolder my-lg-0 my-2 py-1 text-danger">+1000</span> -->
            </a>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/candidate-registration-list" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideMenu()">
              <i class="flaticon-usuario icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Registro de Personas</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/apply-vacancies-list" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideMenu()">
              <i class="flaticon-lista-de-verificacion-3 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Aplicar a Vacantes</span>
            </a>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/interview-list" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideMenu()">
              <i class="flaticon-contratacion icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Entrevista y Evaluación</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/proof-applicants-list" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideMenu()">
              <i class="flaticon-aprobado icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Prueba de Postulantes</span>
            </a>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/request-no-objection-map" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideMenu()">
              <i class="flaticon-contract icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Solicitar No Objeción</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/job-offer" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideMenu()">
              <i class="flaticon-contract-1 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Vinculación</span>
            </a>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/user-guide/Reclutamiento_Seleccion" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideMenu()">
              <i class="flaticon-reading icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Guía de Usuario</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/frequent-questions" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideMenu()">
              <i class="flaticon-lista-de-verificacion-2 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Preguntas frecuentes</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Content-->
</div>

<div id="registro_control" class="offcanvas offcanvas-left sg pt-5 pb-10">
  <div class=" align-items-center border-bottom d-flex justify-content-between mb-0 pb-2">
    <h2 class="text-danger text-center pl-5">Registro y Control</h2>
    <div class="offcanvas-close mt-n1 pr-5 d-flex">
      <!-- <a href="javascript:void(0);" class="btn btn-icon btn-outline-dark mr-4" id="configuracion_toggle"
        data-toggle="tooltip" title="" data-original-title="Mantenimiento">
        <i class="ki ki-gear icon-2x"></i>
      </a> -->
      <a href="javascript:void(0);" class="btn btn-icon btn-light-danger btn-hover-primary" id="registro_control_close">
        <i class="flaticon2-back"></i>
      </a>
    </div>
  </div>
  <div class=" offcanvas-header d-flex align-items-center justify-content-between pb-1"></div>

  <div class="offcanvas-content px-5 scrollPanel">
    <div class="row gutter-b">
      <div class="col-6">
        <a routerLink="/employees" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuEmployees()">
          <i class=" flaticon-recursos-humanos-4  icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2"> Servidores Públicos</span>
        </a>
      </div>

      <div class="col-6">
        <a routerLink="/employee-personnel-actions-list" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuEmployees()">
          <i class="flaticon-colaboracion icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Consulta Acción de Personal</span>
          <!-- <span class="btn btn-sm btn-light font-weight-bolder my-lg-0 my-2 py-1 text-danger">+1000</span> -->
        </a>
      </div>

    </div>
    <div class="row gutter-b">
      <div class="col-6">
        <a routerLink="/hourly-teachers" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuEmployees()">
          <i class="flaticon-recursos-humanos-4 icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Docente por Asignatura</span>
        </a>
      </div>
      <div class="col-6">
        <a routerLink="overtime-and-travel-expenses" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuEmployees()">
          <i class="flaticon-reloj icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Horas extras y Viáticos</span>
        </a>
      </div>
    </div>

    <div class="row gutter-b">

      <!--      <div class="col-6">
        <a routerLink="/employee-exit-verification" routerLinkActive="active-btn" class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5">
          <i class="flaticon-candidatos icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Desvincular</span>
        </a>
      </div>
    </div> -->
      <div class="col-6">
        <a routerLink="/employee-service-request" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuEmployees()">
          <i class="flaticon-importacion icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Solicitudes de Servicios</span>
        </a>
      </div>
      <div class="col-6">
        <a routerLink="/collective-actions" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuEmployees()">
          <i class="flaticon-importacion icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Acciones Masivas</span>
        </a>
      </div>
    </div>

    <div class="row gutter-b">
      <!-- <div class="col-6" *ngIf="!enviroment.production">
        <a routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuEmployees()">
          <i class="flaticon-lista-de-verificacion-3 icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Control de Asistencia</span>
        </a>
      </div> -->
      <div class="col-6" *ngIf="useCollectiveVacations">
        <a routerLink="/consult-collective-vacations" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuEmployees()">
          <i class="flaticon-recursos-humanos-1 icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Consulta Vacaciones Colectivas</span>
        </a>
      </div>
      <div class="col-6">
        <a routerLink="/reports" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuEmployees()">
          <i class="flaticon-proceso-2 icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Reportes</span>
        </a>
      </div>
    </div>

    <div class="row gutter-b">
      <div class="col-6">
        <a routerLink="/birthday" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuEmployees()">
          <img src="assets/images/pastel-de-cumpleanos.png" class="svg-icon svg-icon-4x mr-3  filter-primary">
          <span class="d-block font-weight-bold mt-2">Cumpleaños</span>
        </a>
      </div>

      <div class="col-6">
        <a routerLink="/user-guide/Registro_Control" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuEmployees()">
          <i class="flaticon-reading icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Guía de Usuario</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div id="evaluacion_desempeno" class="offcanvas offcanvas-left xd pt-5 pb-10">
  <div class=" align-items-center border-bottom d-flex justify-content-between mb-3 pb-2">
    <h2 class="text-danger text-center pl-5">Evaluación del Desempeño Laboral</h2>
    <div class="offcanvas-close mt-n1 pr-5 d-flex">
      <!-- <a href="javascript:void(0);" class="btn btn-icon btn-outline-dark mr-4" id="" data-toggle="tooltip" title=""
        data-original-title="Mantenimiento">
        <i class="ki ki-gear icon-2x"></i>
      </a> -->
      <a href="javascript:void(0);" class="btn btn-icon btn-light-danger btn-hover-primary"
        id="evaluacion_desempeno_close">
        <i class="flaticon2-back"></i>
      </a>
    </div>
  </div>
  <!--
  <div class=" offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5 "
    kt-hidden-height="70">
    <ul class=" nav nav-pills nav-primary nav-bold nav-tabs nav-tabs-primary border-0 flex-grow-1 px-5 " role="tablist">
      <li class="nav-item tab-1">
        <a class="nav-link active" data-toggle="tab" href="#aevaluacion"><i class="flaticon-modulos mr-3 icon-2x"></i>
          Todos</a>
      </li>
      <li class="nav-item tab-2">
        <a class="nav-link" data-toggle="tab" href="#evaluacion360"><i
            class="flaticon-recursos-humanos-5 mr-3 icon-2x"></i> Evaluación de cumplimiento (360)</a>
      </li>
    </ul>
  </div>
  -->
  <div class="offcanvas-content px-5 scrollPanel border-top">
    <div class="tab-content">
      <div class=" tab-pane fade pt-3 pr-5 mr-n5 active show" id="aevaluacion" role="tabpanel">
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/performance-evaluation/evaluations" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5 "
              (click)="hideEvDes()">
              <i class="flaticon-dashboard-2 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Evaluación del Desempeño Laboral</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/performance-evaluation/organizational-unit-monitoring" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideEvDes()">
              <i class="flaticon-lista-de-verificacion-3 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Monitoreo de los Acuerdos del Desempeño</span>
            </a>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/performance-evaluation/organizational-unit-plan-improvement" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideEvDes()">
              <i class="flaticon-lista-de-verificacion-2 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Plan de Mejora Unidad Organizativa</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/performance-evaluation/competency-dictionary" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideEvDes()">
              <i class="flaticon-lista-de-verificacion-1 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Diccionario de Competencias</span>
            </a>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/performance-evaluation/goal-management" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideEvDes()">
              <i class="flaticon-cinta icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Gestión de Metas</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/performance-evaluation/generate-report" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideEvDes()">
              <i class="flaticon-contratacion icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Reporte de Indicadores</span>
            </a>
          </div>
        </div>
        <!--
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/incidents-list" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideEvDes()">
              <i class="flaticon-riesgo-1 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Incidentes</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/annual-operative-plan-list" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideEvDes()">
              <i class="flaticon-lista-de-verificacion-2 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Plan operativo anual</span>
            </a>
          </div>
        </div>
        -->
      </div>
      <div class=" tab-pane fade pt-3 pr-5 mr-n5" id="evaluacion360" role="tabpanel">
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/ae" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5 "
              (click)="hideMenu()">
              <i class="flaticon-candidato icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Auto Evaluación</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/cev" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideMenu()">
              <i class="flaticon-recursos-humanos-1 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Co-evaluación</span>
            </a>
          </div>
        </div>
        <div class="row gutter-b">
          <!--begin::Item-->
          <div class="col-6">
            <a routerLink="/evsu" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5 "
              (click)="hideMenu()">
              <i class="flaticon-candidato-2 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Evaluación Supervisor</span>
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div (click)="hideEvDes()" id="divOverlay" [ngClass]="{'offcanvas-overlay-custom': true}"></div>
</div>


<!--Historial de Salud-->
<div id="historial_salud" class="offcanvas offcanvas-left xd pt-5 pb-10">
  <div class=" align-items-center border-bottom d-flex justify-content-between mb-3 pb-2">
    <h2 class="text-danger text-center pl-5">Historial de Salud</h2>
    <div class="offcanvas-close mt-n1 pr-5 d-flex">
      <!-- <a href="javascript:void(0);" class="btn btn-icon btn-outline-dark mr-4" id="" data-toggle="tooltip" title=""
        data-original-title="Mantenimiento">
        <i class="ki ki-gear icon-2x"></i>
      </a> -->
      <a href="javascript:void(0);" class="btn btn-icon btn-light-danger btn-hover-primary" id="historial_salud_close">
        <i class="flaticon2-back"></i>
      </a>
    </div>
  </div>

  <div class="offcanvas-content px-5 scrollPanel border-top">
    <div class="tab-content">
      <div class=" tab-pane fade pt-3 pr-5 mr-n5 active show" id="aevaluacion" role="tabpanel">
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/medical-history/employee-health" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideHistorialSalud()">
              <!--               <i class="las la-notes-medical icon-4x text-primary"></i> -->
              <img src="assets/svg/medical/medical-charts.svg" class="svg-icon svg-icon-4x mr-3  filter-primary">
              <span class="d-block font-weight-bold mt-2">Historial de Salud del Servidor Público</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/medical-history/medical-consultation" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideHistorialSalud()">
              <img src="assets/svg/medical/medical-stethoscope.svg" class="svg-icon svg-icon-4x mr-3  filter-primary">
              <span class="d-block font-weight-bold mt-2">Consultas Médicas</span>
            </a>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/medical-history/medical-journey" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideHistorialSalud()">
              <img src="assets/svg/medical/medical-calendar.svg"
                class="svg-icon svg-icon-4x wizard-icon  filter-primary">
              <span class="d-block font-weight-bold mt-2">Jornadas Médicas</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/medical-history/requisitions" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideHistorialSalud()">
              <img src="assets/svg/medical/medical-case-svgrepo-com.svg"
                class="svg-icon svg-icon-4x wizard-icon  filter-primary">
              <span class="d-block font-weight-bold mt-2">Requisición de Medicamentos y Suministros</span>
            </a>
          </div>

        </div>

        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/medical-history/reception-medicines-supplies" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideHistorialSalud()">
              <img src="assets/svg/medical/medical-case-svgrepo-com.svg"
                class="svg-icon svg-icon-4x wizard-icon  filter-primary">
              <span class="d-block font-weight-bold mt-2">Recepción de Medicamentos y Suministros</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/medical-history/inventory-movements" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideHistorialSalud()">
              <i class="flaticon-correccion icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Movimientos de Inventario</span>
            </a>
          </div>

        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/medical-history/existence" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideHistorialSalud()">
              <i class="flaticon-proceso-3 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Existencia</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/medical-history/physicalinventory" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideHistorialSalud()">
              <i class="flaticon-lista-de-verificacion-3 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Inventario Físico</span>
            </a>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/user-guide/Historial_Salud" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideHistorialSalud()">
              <i class="flaticon-reading icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Guía de Usuario</span>
            </a>
          </div>
        </div>
      </div>
      <!--    <div class=" tab-pane fade pt-3 pr-5 mr-n5" id="evaluacion360" role="tabpanel">
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="/ae" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5 "
              (click)="hideMenu()">
              <i class="flaticon-candidato icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Auto Evaluación</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="/cev" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)="hideMenu()">
              <i class="flaticon-recursos-humanos-1 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Co-evaluación</span>
            </a>
          </div>
        </div>
        <div class="row gutter-b">
          begin::Item
          <div class="col-6">
            <a routerLink="/evsu" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5 "
              (click)="hideMenu()">
              <i class="flaticon-candidato-2 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Evaluación Supervisor</span>
            </a>
          </div>

        </div>
      </div> -->
    </div>
  </div>
  <div (click)="hideHistorialSalud()" id="divOverlay" [ngClass]="{'offcanvas-overlay-custom': true}"></div>
</div>
<!---end-->

<!--capacitacion_desarrollo-->
<div id="capacitacion_desarrollo" class="offcanvas offcanvas-left xd pt-5 pb-10">
  <div class=" align-items-center border-bottom d-flex justify-content-between mb-3 pb-2">
    <h2 class="text-danger text-center pl-5">Capacitación y Desarrollo</h2>
    <div class="offcanvas-close mt-n1 pr-5 d-flex">
      <!-- <a href="javascript:void(0);" class="btn btn-icon btn-outline-dark mr-4" id="" data-toggle="tooltip" title=""
        data-original-title="Mantenimiento">
        <i class="ki ki-gear icon-2x"></i>
      </a> -->
      <a href="javascript:void(0);" class="btn btn-icon btn-light-danger btn-hover-primary"
        id="capacitacion_desarrollo_close">
        <i class="flaticon2-back"></i>
      </a>
    </div>
  </div>
  <div class="offcanvas-content px-5 scrollPanel border-top">
    <div class="tab-content">
      <div class=" tab-pane fade pt-3 pr-5 mr-n5 active show" id="aevaluacion" role="tabpanel">
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="training-development/training-dashboard" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)=" hideFormationDevelopment()">
              <i class="flaticon-dashboard icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Dashboard</span>
            </a>
          </div>
          <div class="col-6">
            <button [disabled]="enviroment.production" routerLink="training-development/internal-course-catalogs"
              routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)=" hideFormationDevelopment()">
              <i class="las la-book-open icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Catálogo de Cursos Internos</span>
            </button>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <button routerLink="training-development/external-course-catalogs" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)=" hideFormationDevelopment()">
              <i class="las la-school icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Catálogo de Cursos Externos</span>
            </button>
          </div>
          <div class="col-6">
            <button routerLink="training-development/curricula" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)=" hideFormationDevelopment()">
              <i class="flaticon-calendario icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Plan de Estudio</span>
            </button>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="training-development/training-group" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)=" hideFormationDevelopment()">
              <i class="flaticon-equipo icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Grupos de Capacitación</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="training-development/training-schedule" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)=" hideFormationDevelopment()">
              <i class="flaticon-calendario-1 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Calendario de Capacitaciones</span>
            </a>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="training-development/training-request" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)=" hideFormationDevelopment()">
              <i class="flaticon-recursos-humanos-6 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Solicitud de Capacitaciones</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="training-development/training-development-plan" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)=" hideFormationDevelopment()">
              <i class="flaticon-proceso-2 icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Plan de Capacitación y Desarrollo Institucional</span>
            </a>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="training-development/training-certificates" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)=" hideFormationDevelopment()">
              <i class="flaticon-lista-de-verificacion icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Certificados</span>
            </a>
          </div>
          <div class="col-6">
            <button [disabled]="enviroment.production" routerLink="training-development/training-reports"
              routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)=" hideFormationDevelopment()">
              <i class="flaticon-archivo icon-4x text-primary"></i>
              <span class="d-block font-weight-bold mt-2">Reportes</span>
            </button>
          </div>
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <a routerLink="training-development/provider-catalogs" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)=" hideFormationDevelopment()">
              <img _ngcontent-jcb-c83="" src="assets/svg/medical/medical-calendar.svg"
                class="svg-icon svg-icon-4x wizard-icon filter-primary">
              <span _ngcontent-jcb-c83="" class="d-block font-weight-bold mt-2">Proveedores</span>
            </a>
          </div>
          <div class="col-6">
            <a routerLink="training-development/course-suggestions" routerLinkActive="active-btn"
              class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
              (click)=" hideFormationDevelopment()">
              <i class="flaticon-recursos-humanos-6 icon-4x text-primary"></i>
              <span _ngcontent-jcb-c83="" class="d-block font-weight-bold mt-2">Sugerencias de Cursos</span>
            </a>
          </div>
          <!-- <div class="col-6">
              <a routerLink="training-development/create-test" routerLinkActive="active-btn"
                class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
                (click)=" hideFormationDevelopment()">
                <i class="flaticon-lista-de-verificacion-2 icon-4x text-primary"></i>
                <span class="d-block font-weight-bold mt-2">Prácticas</span>
              </a>
            </div> -->
        </div>
      </div>
    </div>
    <div (click)="hideFormationDevelopment()" id="divOverlay" [ngClass]="{'offcanvas-overlay-custom': true}"></div>
  </div>
  <!--end-->


  <!-- <div id="nomina" class="offcanvas offcanvas-left ad pt-5 pb-10">
  <div class=" align-items-center border-bottom d-flex justify-content-between mb-3 pb-2">
    <h2 class="text-danger text-center pl-5">Nómina</h2>
    <div class="offcanvas-close mt-n1 pr-5 d-flex">
      <a href="javascript:void(0);" class="btn btn-icon btn-outline-dark mr-4" id="" data-toggle="tooltip" title=""
        data-original-title="Mantenimiento">
        <i class="ki ki-gear icon-2x"></i>
      </a>
      <a href="javascript:void(0);" class="btn btn-icon btn-light-danger btn-hover-primary" id="nomina_close">
        <i class="flaticon2-back"></i>
      </a>
    </div>
  </div>
  <div class=" offcanvas-header d-flex align-items-center justify-content-between pb-10"></div>
  <div class="offcanvas-content px-5 scrollPanel">
    <div class="row">
      <div class="col-6 gutter-b">
        <a class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5" routerLink="/payroll/open-payroll" routerLinkActive="active-btn">
          <i class="icon-3x flaticon-agregar-archivo-1 icon-2x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Preparar Nómina</span>
        </a>
      </div>
      <div class="col-6 gutter-b">
        <a class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5" routerLink="/payroll/audit-approval" routerLinkActive="active-btn">
          <i class="icon-3x flaticon-proceso-3 icon-2x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Auditar y Aprobar</span>
        </a>
      </div>
      <div class="col-6 gutter-b">
        <a class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          routerLink="/payroll/process-payroll" routerLinkActive="active-btn">
          <i class="icon-3x flaticon-archivo icon-2x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Procesar Nómina</span>
        </a>
      </div>
      <div class="col-6 gutter-b">
        <a class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5" routerLink="/payroll/payroll-report" routerLinkActive="active-btn">
          <i class="icon-3x flaticon-factura icon-2x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Generar Reportes</span>
        </a>
      </div>
      <div class="col-6 gutter-b">
        <a class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5" routerLink="/payroll/payment-flyer" routerLinkActive="active-btn">
          <i class="icon-3x flaticon-factura icon-2x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Volante de Pago</span>
        </a>
      </div>
    </div>
  </div>
</div> -->
  <div id="planificacion" class="offcanvas offcanvas-left sg pt-5 pb-10">
    <div class=" align-items-center border-bottom d-flex justify-content-between mb-0 pb-2">
      <h2 class="text-danger text-center pl-5">Planificación</h2>
      <div class="offcanvas-close mt-n1 pr-5 d-flex">
        <!-- <a href="javascript:void(0);" class="btn btn-icon btn-outline-dark mr-4" id="planificacion_toggle"
        data-toggle="tooltip" title="" data-original-title="Mantenimiento">
        <i class="ki ki-gear icon-2x"></i>
      </a> -->
        <a href="javascript:void(0);" class="btn btn-icon btn-light-danger btn-hover-primary" id="planificacion_close">
          <i class="flaticon2-back"></i>
        </a>
      </div>
    </div>
    <div class=" offcanvas-header d-flex align-items-center justify-content-between pb-1"></div>

    <div class="offcanvas-content px-5 scrollPanel">
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/planning/planning-opening" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPlanning()">
            <i class="flaticon-agregar-archivo-1 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Apertura de Planificación</span>
          </a>
        </div>
        <div class="col-6">
          <a routerLink="/planning/request-positions-vacancies" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPlanning()">
            <i class="flaticon-reclutamiento icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">
              Solicitar Cargos y Plazas</span>
          </a>
        </div>
      </div>
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/planning/application-for-positions-and-vacancies-rrhh" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPlanning()">
            <i class="flaticon-calendario icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Aprobar Cargos y Plazas</span>
          </a>
        </div>
        <div class="col-6">
          <a routerLink="/planning/generate-planning" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPlanning()">
            <i class="flaticon-proceso-2 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Generar Planificación</span>
          </a>
        </div>
      </div>
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/planning/authorize-planning" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPlanning()">
            <i class="flaticon-proceso-3 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Autorizar Planificación</span>
          </a>
        </div>
        <div class="col-6" *ngIf="!enviroment.production">
          <a routerLink="/planning/list-vacation-scheduling"  routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPlanning()">
            <i class="flaticon-departamento icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Programar Vacaciones</span>
          </a>
        </div>
      </div>
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/planning/reports" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPlanning()">
            <i class="flaticon-proceso-2 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Informes</span>
          </a>
        </div>
        <div class="col-6">

        </div>
      </div>
      <!--
    <div class="row gutter-b">
      <div class="col-6">
        <a routerLink="/planning-rrhh" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuPlanning()">
          <i class="flaticon-datos icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Planificación RRHH</span>
        </a>
      </div>
      <div class="col-6">
        <a routerLink="/mapplanning-approval" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuPlanning()">
          <i class="flaticon-cheque-1 icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Aprobación Planificación (MAP)</span>
        </a>
        </div>
    </div>
    -->
      <!--<div class="row gutter-b">
      <div class="col-6">
        <a routerLink="/finance-planning-approval" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuPlanning()">
          <i class="flaticon-cheque icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Aprobación Planificación (DIGEPRES)</span>
        </a>
      </div>
    </div>-->
    </div>
    <div (click)="hideMenuPlanning()" id="divOverlay" [ngClass]="{'offcanvas-overlay-custom': true}"></div>
  </div>
  <div id="estructura_organica" class="offcanvas offcanvas-left sg pt-5 pb-10">
    <div class=" align-items-center border-bottom d-flex justify-content-between mb-0 pb-2">
      <h2 class="text-danger text-center pl-5">Estructura Orgánica</h2>
      <div class="offcanvas-close mt-n1 pr-5 d-flex">
        <!-- <a href="javascript:void(0);" class="btn btn-icon btn-outline-dark mr-4" id="configuracion_toggle"
        data-toggle="tooltip" title="" data-original-title="Mantenimiento">
        <i class="ki ki-gear icon-2x"></i>
      </a> -->
        <a href="javascript:void(0);" class="btn btn-icon btn-light-danger btn-hover-primary"
          id="estructura_organica_close">
          <i class="flaticon2-back"></i>
        </a>
      </div>
    </div>
    <div class=" offcanvas-header d-flex align-items-center justify-content-between pb-1"></div>

    <div class="offcanvas-content px-5 scrollPanel">
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/view-estructure" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuOrganicStructure()">
            <i class="flaticon-recursos-humanos-2 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Ver Estructura</span>
          </a>
        </div>
        <div class="col-6">
          <a routerLink="/departament-list" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuOrganicStructure()">
            <i class="flaticon-departamento icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Unidades Organizativas</span>
          </a>
        </div>

      </div>
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/positions-list" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuOrganicStructure()">
            <i class="flaticon-lista-de-verificacion-2 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Cargos</span>
          </a>
        </div>

        <div class="col-6">
          <a routerLink="/positionsList-description" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuOrganicStructure()">
            <i class="flaticon-contratacion icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Descripción de Cargos</span>
          </a>
        </div>
      </div>
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/user-guide/Estructura_Organica" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuOrganicStructure()">
            <i class="flaticon-reading icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Guía de Usuario</span>
          </a>
        </div>
      </div>
      <div class="row gutter-b">
        <!-- <div class="col-6">
        <a routerLink="/telephone-directory" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuOrganicStructure()">
          <i class="flaticon-editar icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Directorio Telefónico</span>
        </a>
      </div> -->

        <div class="col-6">
        </div>
      </div>
    </div>
    <div (click)="hideMenuOrganicStructure()" id="divOverlay" [ngClass]="{'offcanvas-overlay-custom': true}"></div>
  </div>
  <div id="map" class="offcanvas offcanvas-left sg pt-5 pb-10">
    <div class=" align-items-center border-bottom d-flex justify-content-between mb-0 pb-2">
      <h2 class="text-danger text-center pl-5">MAP</h2>
      <div class="offcanvas-close mt-n1 pr-5 d-flex">
        <!-- <a href="javascript:void(0);" class="btn btn-icon btn-outline-dark mr-4" id="configuracion_toggle"
        data-toggle="tooltip" title="" data-original-title="Mantenimiento">
        <i class="ki ki-gear icon-2x"></i>
      </a> -->
        <a href="javascript:void(0);" class="btn btn-icon btn-light-danger btn-hover-primary" id="map_close">
          <i class="flaticon2-back"></i>
        </a>
      </div>
    </div>
    <div class=" offcanvas-header d-flex align-items-center justify-content-between pb-1"></div>

    <div class="offcanvas-content px-5 scrollPanel">
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/map/no-objection" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5" (click)="hideMenu()">
            <i class=" flaticon-candidato-1  icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">No Objeción </span>
          </a>
        </div>
        <div class="col-6">
          <a routerLink="" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5" (click)="hideMenu()">
            <i class="flaticon-escoger icon-4x text-primary"></i>
            <!-- <span class="btn btn-sm btn-light font-weight-bolder my-lg-0 my-2 py-1 text-danger">+1000</span> -->
            <span class="d-block font-weight-bold mt-2">Validación de Salida</span>
          </a>
        </div>

      </div>
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5" (click)="hideMenu()">
            <i class="flaticon-lista-de-verificacion-1 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Evaluaciones del Desempeño</span>
          </a>
        </div>
        <div class="col-6">
          <a routerLink="" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5" (click)="hideMenu()">
            <i class="flaticon-area-con-alfileres icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Clima Laboral</span>
          </a>
        </div>
      </div>
    </div>
    <div (click)="hideMenu()" id="divOverlay" [ngClass]="{'offcanvas-overlay-custom': true}"></div>
  </div>
  <div id="configuracion" class="offcanvas offcanvas-left ad pt-5 pb-10">
    <div class=" align-items-center border-bottom d-flex justify-content-between mb-0 pb-2">
      <h2 class="text-danger text-center pl-5">Configuración</h2>
      <div class="offcanvas-close mt-n1 pr-5 d-flex">
        <a href="javascript:void(0);" class="btn btn-icon btn-light-danger btn-hover-primary" id="configuracion_close">
          <i class="flaticon2-back"></i>
        </a>
      </div>
    </div>
    <div class=" offcanvas-header d-flex align-items-center justify-content-between pb-1 px-5 ">
      <input type="text" class="form-control form-control-solid" placeholder="Buscar configuración" />
    </div>
    <div class="offcanvas-content px-5 scrollPanel">
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5 " (click)="hideMenu()">
            <i class="flaticon-squares-3 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Tipo de Unidad Organizativa</span>
          </a>
        </div>
        <div class="col-6">
          <a routerLink="" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5 " (click)="hideMenu()">
            <i class="flaticon-app icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Unidad Organizativa</span>
          </a>
        </div>
      </div>
    </div>
    <div (click)="hideMenu()" id="divOverlay" [ngClass]="{'offcanvas-overlay-custom': true}"></div>
  </div>

  <div id="nomina" class="offcanvas offcanvas-left sg pt-5 pb-10">
    <div class=" align-items-center border-bottom d-flex justify-content-between mb-0 pb-2">
      <h2 class="text-danger text-center pl-5">Nómina</h2>
      <div class="offcanvas-close mt-n1 pr-5 d-flex">
        <!-- <a href="javascript:void(0);" class="btn btn-icon btn-outline-dark mr-4" id="nomina_toggle" data-toggle="tooltip"
        title="" data-original-title="Mantenimiento">
        <i class="ki ki-gear icon-2x"></i>
      </a> -->
        <a href="javascript:void(0);" class="btn btn-icon btn-light-danger btn-hover-primary close-w" id="nomina_close">
          <i class="flaticon2-back"></i>
        </a>
      </div>
    </div>
    <div class=" offcanvas-header d-flex align-items-center justify-content-between pb-1"></div>

    <div class="offcanvas-content px-5 scrollPanel">
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/payroll/payroll-home" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-agregar-archivo-1 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Apertura de Nómina</span>
          </a>
        </div>
        <div class="col-6">
          <a routerLink="/payroll/news-list" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-calendario icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Registro de Novedades</span>
          </a>
        </div>
      </div>
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/payroll/manage-payroll" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-agregar-archivo-1 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Generar Nómina</span>
          </a>
        </div>
        <div class="col-6">
          <a routerLink="/payroll/audit-approval" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-proceso-3 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Auditar y Aprobar</span>
          </a>
        </div>
      </div>
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/payroll/process-payroll" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-archivo icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Archivos de Nómina</span>
          </a>
        </div>
        <div class="col-6">
          <a routerLink="/payroll/payroll-report" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-proceso-2 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Generar Reportes</span>
          </a>
        </div>
      </div>
      <!--  <div class="row gutter-b">
     <div class="col-6">
        <a routerLink="/payroll/final-payroll" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuPayroll()">
          <i class="flaticon-lista-de-verificacion icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Nómina de Prestaciones Laborales</span>
        </a>
      </div> -->

      <!--   <div class="col-6">
        <a routerLink="/payroll/incentive-payroll" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuPayroll()">
          <i class="flaticon-salario icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Nómina de Incentivos</span>
        </a>
      </div>
    </div> -->

      <div class="row gutter-b">
        <!--      <div class="col-6">
        <a routerLink="/payroll/additional-payroll" routerLinkActive="active-btn"
          class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
          (click)="hideMenuPayroll()">
          <i class="flaticon-notas icon-4x text-primary"></i>
          <span class="d-block font-weight-bold mt-2">Nómina Adicional</span>
        </a>
      </div> -->

        <div class="col-6">
          <a routerLink="/payroll/payment-flyer" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-factura icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Comprobantes de Pago</span>
          </a>
        </div>

        <div class="col-6">
          <a routerLink="/schedule" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-calendario-1 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Período</span>
          </a>
        </div>
      </div>


      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/payroll/budget-areas" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-datos icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Áreas presupuestales</span>
          </a>
        </div>
        <div class="col-6">
          <a routerLink="/payroll/payroll-concept" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-presupuesto icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Conceptos de Nómina</span>
          </a>
        </div>
      </div>

      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/payroll/beneficiaries" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-presupuesto icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Beneficiarios de Conceptos de Nómina</span>
          </a>
        </div>
        <div class="col-6">
          <a routerLink="/payroll/statistics" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-presupuesto icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Estadísticas de Nóminas</span>
          </a>
        </div>
      </div>

      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/employee-response-request" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-importacion icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Certificaciones de Retención de Impuestos y TSS</span>
          </a>
        </div>

        <div class="col-6">
          <a routerLink="/payroll/certification-codes" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-contrasena icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Códigos Certificación</span>
          </a>
        </div>
      </div>
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/user-guide/nomina" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideMenuPayroll()">
            <i class="flaticon-reading icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Guía de Usuario</span>
          </a>
        </div>
      </div>

    </div>
    <div (click)="hideMenuPayroll()" id="divOverlay" [ngClass]="{'offcanvas-overlay-custom': true}"></div>
  </div>



  <div id="gestion_contenido" class="offcanvas offcanvas-left sg pt-5 pb-10">
    <div class=" align-items-center border-bottom d-flex justify-content-between mb-0 pb-2">
      <h2 class="text-danger text-center pl-5">Gestión de Contenido</h2>
      <div class="offcanvas-close mt-n1 pr-5 d-flex">
        <!-- <a href="javascript:void(0);" class="btn btn-icon btn-outline-dark mr-4" id="nomina_toggle" data-toggle="tooltip"
          title="" data-original-title="Mantenimiento">
          <i class="ki ki-gear icon-2x"></i>
        </a> -->
        <a href="javascript:void(0);" class="btn btn-icon btn-light-danger btn-hover-primary close-w"
          id="gestion_contenido_close">
          <i class="flaticon2-back"></i>
        </a>
      </div>
    </div>
    <div class=" offcanvas-header d-flex align-items-center justify-content-between pb-1"></div>

    <div class="offcanvas-content px-5 scrollPanel">
      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/content-management" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideContentManagement()">
            <i class="flaticon-datos icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Gestión de Contenido</span>
          </a>
        </div>
        <div class="col-6">
          <a routerLink="/links" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideContentManagement()">
            <i class="flaticon-calendario-1 icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Enlaces</span>
          </a>
        </div>
      </div>

      <div class="row gutter-b">
        <div class="col-6">
          <a routerLink="/user-guide/Gestion_Contenido" routerLinkActive="active-btn"
            class=" btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-5 px-5"
            (click)="hideContentManagement()">
            <i class="flaticon-reading icon-4x text-primary"></i>
            <span class="d-block font-weight-bold mt-2">Guía de Usuario</span>
          </a>
        </div>
      </div>

    </div>
    <div (click)="hideMenuPayroll()" id="divOverlay" [ngClass]="{'offcanvas-overlay-custom': true}"></div>
  </div>
