import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApplyVacancyModel } from '../../personal-requisition/models/apply-vacancy-model';
import { PersonModel } from '../../personal-requisition/models/person.model';
import { DatePipe } from '@angular/common';
import { VacanciesService } from 'src/app/services/vacancies.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { SalaryRangeComponent } from './salary-range/salary-range.component';

@Component({
  selector: 'app-apply-vacancy-modal',
  templateUrl: './apply-vacancy-modal.component.html',
  styleUrls: ['./apply-vacancy-modal.component.css']
})
export class ApplyVacancyModalComponent implements OnInit {
  IsviewMode = false;
  VacancyModel = new ApplyVacancyModel();
  datePie = new DatePipe('en-US')
  Salary: any;
  YesOrNot = [{ value: true, text: 'SI' }, { value: false, text: 'NO' }];
  config = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  };
  hoursAvailable: any[] = []
  hourSelected: any
  appliedVacancy: boolean = false
  isSalaryInvalid: boolean = false
  showDateApplicationError: boolean = false;
  showstartDateError: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ApplyVacancyModalComponent>,
    private toastr: ToastrService,
    private srvVacancy: VacanciesService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { vacancyModel: ApplyVacancyModel,
      isviewMode: boolean}
  ) {
    this.dialogRef.disableClose = true;
    this.VacancyModel = this.data?.vacancyModel;
    this.IsviewMode = this.data?.isviewMode;
   }


  ngOnInit(): void {
    this.getHours();
  }

  close() {
    this.dialogRef.close()
  }

  Cancel(): void {
    this.close()
  }

  getHours() {
    this.srvVacancy.getHoursAvailable().subscribe(data => {
      if (data.succeded) {
        this.hoursAvailable = data?.dataList;
        if(this.IsviewMode){
          this.hourSelected = this.hoursAvailable?.find((ha: any) => ha?.ocode == this.VacancyModel?.hoursAvailable) ?? null;
          this.VacancyModel.hoursAvailable = this.hoursAvailable?.find((ha: any) => ha?.ocode == this.VacancyModel?.hoursAvailable) ?? 0;
        }
      } else {
        this.toastr.error(data.errors[0]);
      }
    }, error => {
      this.toastr.error('Ha ocurrido un error buscando los filtros de candidatos. Consulte con el administrador del sistema');
    });
  }


  onSalaryError() {
    if (this.VacancyModel.desiredSalary > this.VacancyModel.maxSalary || this.VacancyModel.desiredSalary < this.VacancyModel.minSalary) {
      this.isSalaryInvalid = true
    }
    else {
      this.isSalaryInvalid = false
    }
  }

  selecHours(hour) {
    this.VacancyModel.hoursAvailable = hour?.value?.ocode
  }

  openSalaryRange() {
    this.dialog.open(SalaryRangeComponent, {
      data: {
        vacancyModel: this.VacancyModel,
        IsviewMode: this.IsviewMode
      },

    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.Applied) {
          this.selectSalaryRange(result?.salary);
        }
      });
  }

  ApplyVacancy() {

    var endDate = new Date(9999, 12, 31)
    if(new Date(this.VacancyModel.startDate) >= endDate){
      this.toastr.warning('Favor de colocar una fecha valida');
      return
    }

    if (this.VacancyModel.personId == null || this.VacancyModel.personId == undefined ||
      this.VacancyModel.personId == 0) {
      this.toastr.warning('Debe seleccionar la persona que está aplicando a la vacante', 'Validación');
      return;
    }

    if (this.VacancyModel.vacantId == null || this.VacancyModel.vacantId == undefined ||
      this.VacancyModel.vacantId == 0) {
      this.toastr.warning('El sistema no seleccionó correctamente la vacante. Consulte al administrador del sistema', 'Validación');
      return;
    }

    if (this.VacancyModel.desiredSalary == null || this.VacancyModel.desiredSalary == undefined ||
      this.VacancyModel.desiredSalary == 0) {
      this.toastr.warning('Debe indicar el salario deseado', 'Validación');
      return;
    }

    if (this.VacancyModel.employee == null || this.VacancyModel.employee == undefined) {
      this.toastr.warning('Debe indicar si está laborando actualmente', 'Validación');
      return;
    }

    if (this.VacancyModel.startDate == null || this.VacancyModel.startDate == undefined) {
      this.toastr.warning('Debe indicar la fecha en que está disponible', 'Validación');
      return;
    }

    if (this.VacancyModel.dateApplication == null || this.VacancyModel.dateApplication == undefined) {
      this.toastr.warning('Debe indicar la fecha en la que está aplicando', 'Validación');
      return;
    }

    if (this.VacancyModel.toTravel == null || this.VacancyModel.toTravel == undefined) {
      this.toastr.warning('Debe indicar si tiene disponibilidad para viajar', 'Validación');
      return;
    }

    if (this.VacancyModel.transfer == null || this.VacancyModel.transfer == undefined) {
      this.toastr.warning('Debe indicar si tiene disponibilidad para ser transferido', 'Validación');
      return;
    }
    if (this.VacancyModel.hoursAvailable == null || this.VacancyModel.hoursAvailable == undefined ||
      this.VacancyModel.hoursAvailable == 0) {
      this.toastr.warning('Debe indicar el horario disponible', 'Validación');
      return;
    }

    if (this.VacancyModel.authorizedData == null || this.VacancyModel.authorizedData == undefined) {
      this.toastr.warning('Debe indicar si autoriza o no al uso público de sus datos', 'Validación');
      return;
    }
    if (this.VacancyModel.maxSalary && this.VacancyModel.maxSalary > 0) {
      if (this.VacancyModel.desiredSalary > this.VacancyModel.maxSalary || this.VacancyModel.desiredSalary < this.VacancyModel.minSalary) {
        this.toastr.warning('El salario deseado debe estar dentro del rango salarial establecido', 'Validación');
        return;
      }
    }

    let vacancyData = new ApplyVacancyModel
    vacancyData = {
      idPpv: 0,
      personId: this.VacancyModel.personId,
      vacantId: this.VacancyModel.vacantId,
      condition: 0,
      desiredSalary: this.VacancyModel.desiredSalary,
      startDate: this.VacancyModel.startDate,
      toTravel: this.VacancyModel.toTravel,
      transfer: this.VacancyModel.transfer,
      companyId: this.VacancyModel.companyId,
      dateApplication: this.VacancyModel.dateApplication,
      status: this.VacancyModel.status,
      hoursAvailable: this.VacancyModel.hoursAvailable,
      authorizedData: this.VacancyModel.authorizedData,
      employee: this.VacancyModel.employee,
      comment: this.VacancyModel.comment
    }
    this.srvVacancy.ApplyToVacancy(vacancyData).subscribe(data => {
      if(data?.warnings?.length > 0 ){
        this.toastr.warning(data.warnings[0]);
        return
      }

      if(data?.errors?.length > 0 ){
        this.toastr.error(data.errors[0]);
        return
      }
      this.toastr.success('La aplicación de la vacante ha sido registrada exitosamente', 'Información');
      this.dialogRef.close({success: true});
    }, error => {
      this.toastr.error('Ha ocurrido un error al aplicar la vacante');
    })

  }

  selectSalaryRange(salary: number) {
    if(!this.IsviewMode){
      this.VacancyModel.desiredSalary = salary
      this.isSalaryInvalid = false
    }
  }

}
