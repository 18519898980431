<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" class="close" aria-label="Close" (click)="closeDialog(false)">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        {{ data?.isEdit ? 'Editar Cargo' : data?.isView ? 'Ver Cargo' : 'Crear Cargo'}}
    </h2>
</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom mt-3 pt-0">
        <form [formGroup]="positionForm">
            <div class="row gutter-b">
                <div class="form-group col-xl-4 col-lg-6">
                    <label class="">Institución <span class="text-danger">*</span></label>
                    <!-- <ngx-select-dropdown class="flex-1" [options]="companies"
                        [config]="getDropdownConfig('companyName', 'Seleccionar')"
                        (change)="this.companyId = ($event.value?.companyId)" formControlName="company">
                    </ngx-select-dropdown> -->
                    <input class="form-control form-control-lg" type="text"   formControlName="company" readonly>
                </div>
                <div class="form-group col-xl-4 col-lg-6">
                    <label class="">Nombre del Cargo <span class="text-danger">*</span></label>
                    <input class="form-control form-control-lg" type="text" formControlName="positionName">
                </div>

            </div>
            <div class="row gutter-b">
                <div class="form-group col-xl-4 col-lg-6">
                    <label class="">Posiciones Solicitadas <span class="text-danger">*</span></label>
                    <input oninput="this.value=(parseInt(this.value)||0)" min="0" class="form-control form-control-lg"
                        type="number" formControlName="requestedPositions">
                </div>
                <div class="form-group col-xl-4 col-lg-6">
                    <label class="">Posiciones Aprobadas <span class="text-danger">*</span></label>
                    <input class="form-control form-control-lg" type="text" formControlName="approvalPositions" readonly>
                </div>
                <div class="form-group col-xl-4 col-lg-6">
                    <label>Grupo Ocupacional <span class="text-danger">*</span></label>
                    <ngx-select-dropdown class="flex-1" [options]="groups" (change)="onOcupationalGroupChanged()"
                        [config]="getDropdownConfig('stringData', 'Seleccionar')" formControlName="occupationalGroup">
                    </ngx-select-dropdown>
                </div>

            </div>

            <div class="row gutter-b">

                <div class="form-group col-xl-4 col-lg-6">
                    <label>Salario Máximo <span class="text-danger">*</span></label>
                    <input class="form-control form-control-lg" placeholder="RD$ 0" type="text" currencyMask
                        [options]="{precision:2}" formControlName='maxSalary' (keyup)="calculateHalfSalary()">
                    <span class="text-danger">{{this.salariesErros.max}}</span>
                </div>
                <div class="form-group col-xl-4 col-lg-6">
                    <label>Salario Medio <span class="text-danger">*</span></label>
                    <input class="form-control form-control-lg" placeholder="RD$ 0" type="text" currencyMask
                        [options]="{precision:2}" formControlName='halfSalary'>
                    <span class="text-danger">{{this.salariesErros.avg}}</span>
                </div>
                <div class="form-group col-xl-4 col-lg-6">
                    <label>Salario Mínimo <span class="text-danger">*</span></label>
                    <input class="form-control form-control-lg" placeholder="RD$ 0" type="text" currencyMask
                        [options]="{precision:2}" formControlName='minSalary' (keyup)="calculateHalfSalary()">
                    <span class="text-danger">{{this.salariesErros.min}}</span>
                </div>

            </div>
            <div class="row gutter-b">
                <div class="form-group col-xl-4 col-lg-6">
                    <label class="">Cantidad de Entrevistas Requeridas <span class="text-danger">*</span></label>
                    <input oninput="this.value=(parseInt(this.value)||0)" min="0" class="form-control form-control-lg"
                        type="number" formControlName="countInterviews">
                </div>
                <div class="form-group col-xl-4 col-lg-6">
                    <label class="">Cantidad de Pruebas Requeridas <span class="text-danger">*</span></label>
                    <input oninput="this.value=(parseInt(this.value)||0)" min="0" class="form-control form-control-lg"
                        type="number" formControlName="countTest">
                </div>

                <div class="form-group col-xl-4 col-lg-6">
                    <label class="">¿Es Docente por Asignatura? </label>
                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                        <strong
                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                        <div class="flex-shrink-0 mx-2">
                            <span class="switch switch-sm switch-primary">
                                <label><input class="cursor-pointer abc-checkbox-primary" (change)="changeTeacherPerHour($event?.target?.checked)" formControlName="teacherPerHour"
                                        type="checkbox" /><span></span></label>
                            </span>
                        </div>
                        <strong
                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
                    </div>
                </div>

                <div class="form-group col-xl-4 col-lg-6">
                    <label class="">Código Cargo <span class="text-danger">*</span></label>
                    <input class="form-control form-control-lg" formControlName="positionCode" type="text"
                        oninput="this.value = this.value.toUpperCase()">
                </div>
                <!-- <div class="form-group col-xl-4 col-lg-6">
                    <label class="">Documento Aprobado</label>
                    <input class="form-control form-control-lg" type="text" readonly formControlName="approvalDoc">
                </div> -->
                <div class="form-group col-xl-4 col-lg-6">
                    <label class="">Homólogo en Concursa </label>
                    <ngx-select-dropdown class="flex-1" [options]="concursaPositions"
                        [config]="getDropdownConfig('nombre', 'Seleccionar')" formControlName="concursaId">
                    </ngx-select-dropdown>
                </div>
                <div class="form-group col-xl-4 col-lg-6">
                    <label class="">¿Es Supervisor?</label>
                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                        <strong
                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                        <div class="flex-shrink-0 mx-2">
                            <span class="switch switch-sm switch-primary">
                                <label><input class="cursor-pointer abc-checkbox-primary" formControlName="isSupervisor"
                                        type="checkbox" /><span></span></label>
                            </span>
                        </div>
                        <strong
                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
                    </div>
                </div>
                <div class="form-group col-xl-4 col-lg-6" *ngIf="data?.position?.conditionId === 3">
                    <label class="">Razón de Rechazo</label>
                    <button (click)="openReasonReject()"  class="btn font-weight-bolder w-100 mt-2 mr-4 mr-0-mobile mb-2-mobile btn-primary " type="button">
                        <span>Ver Razón de Rechazo</span>
                    </button>
                </div>
            </div>
        </form>
    </div>

</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="closeDialog(false)"
        class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button [disabled]="positionForm?.invalid" *ngIf="!data.isView" (click)="onSubmitPosition()"
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>

</div>
