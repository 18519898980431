<div class="container-fluid py-3">
  <button  (click)="goBack()"  class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
      <i class="flaticon2-left-arrow-1"></i>
      <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
  </button>
</div>
<div class="container-fluid">
  <div id="pdfExp" class="card">
    <div class="card-header px-22">
      <div class="align-items-center d-flex flex-wrap justify-content-lg-between justify-content-md-center" id="header">
        <div class="logo px-2" >
          <img [src]="file.logo" alt="" class="max-w-200px" />
        </div>

        <div *ngIf="fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">
            {{fileModalConfig.formTitle}}
          </h1>
          <h2 class="text-danger">{{fileModalConfig.formSubTitle}}</h2>
        </div>
        <div *ngIf="!fileModalConfig.formSubTitle"
          class="title p-2 d-flex justify-content-center align-items-center flex-column">
          <h1 class="ttlPDF">

            Instituto Tecnológico de Las Américas
          </h1>
          <h2 class="text-danger">Acción de Cambio de Servidor Público</h2>
        </div>
        <div class="date mt-md-5 p-4 d-flex flex-wrap justify-content-between align-items-center">
            <h4 class="text-right mr-3">Fecha de solicitud</h4>
            <h5 class="text-danger text-right">{{this.changeHeader?.date|| "" |date:'dd MMMM yyyy':'UTC':'es-US'}}</h5>
            <!-- &nbsp; -->
          </div>
      </div>
    </div>
    <div class="card-body">
      <div id="pdf">
        <div class="pdf-page pb-4">
          <div class="container-fluid row mt-3" id="personal-data">
            <h3 class="col-md-12 p-2 bg-primary text-white mt-3 text-center">DATOS PERSONALES</h3>
            <div class="col-md-6 row">
              <div class="photo col-md-4">

                <ng-container>
                  <div class="symbol symbol-lg-200" *ngIf="!profileImage; else second">
                    <i class="symbol-badge bg-success"></i>
                    <img src="../../../../assets/media/no-user.png" alt="image">
                  </div>
                  <ng-template #second>
                    <div class="symbol symbol-lg-200">
                      <i class="symbol-badge bg-success"></i>
                      <img [src]="profileImage| safe" alt="image" (click)="showModal('photo')">
                    </div>
                  </ng-template>
                </ng-container>
              </div>
              <div class="pdf-list col-md-8">
                <div class="pdf-items">
                  <h4>Código del Servidor Público: </h4>
                  <span>{{employee?.employNumber || ""}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Servidor Público:</h4>
                  <span>{{employee?.name || ""}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Identificación:</h4>
                  <span *ngIf="employee?.typeIdentificationId == 1">{{employee?.personalIdentification || "" | mask: '000-0000000-0'}}</span>
                  <span *ngIf="employee?.typeIdentificationId != 1">{{employee?.personalIdentification || ""}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Teléfono:</h4>
                  <span>{{employee?.phoneNumber || "" | mask : '(000) 000-0000'}}</span>
                </div>
                <div class="pdf-items">
                  <h4>Unidad Organizativa:</h4>
                  <span>{{employee?.departmentName || ""}}</span>
                </div>
              </div>
            </div>
            <div class="pdf-list col-md-6">
              <div class="pdf-items">
                <h4>Puesto:</h4>
                <span>{{employee?.positionName || ""}}</span>
              </div>
              <div class="pdf-items">
                <h4>Salario:</h4>
                <span>{{employee?.salary || "" | currency}}</span>
              </div>
              <div class="pdf-items">
                <h4>Tipo de Nombramiento:</h4>
                <span>{{employee?.employeeType || ""}}</span>
              </div>
              <div class="pdf-items">
                <h4>Fecha Efectiva:</h4>
                <span>{{employee?.effectiveDate || "" |date:'dd MMMM yyyy':'UTC':'es-US'}}</span>
              </div>
              <div class="pdf-items">
                <h4>Fecha de Ingreso:</h4>
                <span>{{employee?.admissionDate || "" |date:'dd MMMM yyyy':'UTC':'es-US'}}</span>
              </div>

            </div>
          </div>

          <div class="container-fluid row mt-3 " id="family-working-at-here">
            <h3 class="col-md-12 mt-3 p-2 bg-secondary text-white text-center">
              CAMBIO A Servidor Público
            </h3>
            <div class="col-md-12 m-0 p-0 overflow-auto">
              <table class="table p-0">
                <thead>
                  <tr class="p-0">
                    <th scope="col" class="text-dark">Secuencia</th>
                    <th scope="col" class="text-dark">Parámetro</th>
                    <th scope="col" class="text-dark">Datos Actuales</th>
                    <th scope="col" class="text-dark">Datos Nuevos</th>
                    <th scope="col" class="text-dark">Fecha Aprobada</th>
                    <th scope="col" class="text-dark">Motivos</th>
                    <th scope="col" class="text-dark">Comentario</th>
                    <th scope="col" class="text-dark evidenceO">Evidencia</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="p-0" *ngFor="let item of changeDetails">
                    <td class="">{{item?.employeeChangeDetailsId || 0}}</td>
                    <td class="">{{item?.parameterName || "N/A"}}</td>
                    <td class="p-3 m-0" *ngIf="!item?.valueAfterNumeric && !isDate(item.valueBefore)">{{item?.valueBefore || "N/A"}}</td>
                    <td class="p-3 m-0" *ngIf="!item?.valueAfterNumeric && isDate(item.valueBefore)">{{item?.valueBefore || "N/A" |  date:'longDate':'+0400':'es'}}</td>
                    <td class="p-3 m-0" *ngIf="item?.valueAfterNumeric">{{item?.valueBefore || "N/A" | currency:'RD$'}}</td>
                    <td class="p-3 m-0" *ngIf="!item?.valueAfterNumeric && !isDate(item.valueAfter)">{{item?.valueAfter || "N/A"}}</td>
                    <td class="p-3 m-0" *ngIf="!item?.valueAfterNumeric && isDate(item.valueAfter)">{{item?.valueAfter || "N/A" |  date:'longDate':'+0400':'es'}}</td>
                    <td class="p-3 m-0" *ngIf="item?.valueAfterNumeric">{{item?.valueAfter || "N/A" | currency:'RD$'}}</td>
                    <td class="">{{item?.dateApproved  |date:'dd MMMM yyyy':'UTC':'es-US'}}</td>
                    <td class="">{{item?.reason || "N/A" }}</td>
                    <td>{{item?.comment}}</td>
                    <td class="evidenceO">
                      <a *ngIf="item?.evidence" (click)='openGuID(item?.evidence)' data-toggle="tooltip" title="Evidencia"
                          class="btn btn-light-youtube font-weight-bold mb-3">
                          <i class="flaticon-pdf-file icon-2x"></i>
                      </a>
                      <p *ngIf="!item?.evidence">N/A</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="container-fluid row mt-3" id="family-info">
            <h3 class="col-md-12 mt-3 p-2 bg-primary text-white text-center text-center">
              HISTORIAL DE CAMBIOS
            </h3>
            <div class="col-md-12 row">
              <table class="table ">
                <thead>
                  <tr class="">
                    <th scope="col" class="text-dark">Secuencia</th>
                    <th scope="col" class="text-dark">Fecha</th>
                    <th>Aprobación</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="p-0">
                    <td class="">{{this.changeHeader?.employeeChangeHeaderId|| "" }}</td>
                    <td class="">{{this.changeHeader?.date|| "" |date:'dd MMMM yyyy':'UTC':'es-US'}}</td>
                    <td>Julisy Amador</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer mt-4">
      <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
        <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-80px">
        <div class="d-flex flex-wrap justify-content-around flex-fill">
          <div *ngIf="supervisorNames.employeeSupervisor" class="mr-3">
            <span>__________________________</span>
            <p class="font-weight-bold text-dark text-center">
              {{supervisorNames.employeeSupervisor}}
            </p>
          </div>
          <div *ngIf="supervisorNames.hhrr" class="mr-3">
            <span>__________________________</span>
            <p class="font-weight-bold text-dark text-center">
              {{supervisorNames.hhrr}}
            </p>
          </div>
          <div *ngIf="supervisorNames.rectory" class="mr-3">
            <span>__________________________</span>
            <p class="font-weight-bold text-dark text-center">
              {{supervisorNames.rectory}}
            </p>
          </div>
        </div>
        <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" alt="" class="max-w-175px">
      </div>
    </div>
  </div>
</div>
<button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
  <i class="flaticon2-printer font-size-h1"></i>
</button>
<script>
  let print = () => {
    let pdf = document.getElementById("pdfExp");
    pdf.contentWindow.focus();
    pdf.contentWindow.print();
  };
</script>
<el-modal target="logoModal" size="md" [title]="'Vista de foto'">
  <el-embed *ngIf="fileModalConfig.src" [src]="fileModalConfig.src" [type]="fileModalConfig.type"
    [filename]="fileModalConfig.filename" [width]="fileModalConfig.width" [height]="fileModalConfig.height">
  </el-embed>
</el-modal>
<el-modal target="photo" size="md" [title]="'Vista de foto'">
  <div class="container justify-content-center" style="align-content: center;">
    <ng-template [ngIf]="photoUrl">
      <img [src]="photoUrl | safe" alt="image" style="max-width: 350px;">
    </ng-template>
  </div>
</el-modal>
