<app-modal-layout (onClose)="dialogRef.close()">

    <ng-container title>
        {{data?.modalTitle}}
    </ng-container>

    <ng-container body>
        <div class="row">
            <div class="form-group col-12">
                <label>{{data?.inputLabel}}</label>
                <textarea [value]="data?.text" class="form-control form-control-solid form-control-lg" 
                rows="{{data?.rows}}"
                disabled></textarea>
            </div>
        </div>
    </ng-container>

    <ng-container buttons>
        <button (click)="dialogRef.close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
    </ng-container>
</app-modal-layout>
