<div class="card card-custom">
  <div class="card-header mt-3">
    <div class="card-title">
      <div class="ttl d-flex align-items-center">
        <i class="flaticon-recursos-humanos-1 icon-3x text-danger mr-2"></i>
        <h1 class="text-primary">
          Desvinculaciones Masivas
          <span *ngIf="showProcess === 'ValidationResults'">- Resultados de validacion</span>
        </h1>
      </div>
    </div>

    <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard" data-toggle="tooltip"
      data-original-title="volver atrás">
      <a [routerLink]="getRouterToBack()" class="btn btn-pill font-weight-bolder btn-shadow btn-warning">
        <i class="flaticon2-left-arrow-1"></i>
        <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
      </a>
    </div>
  </div>
  <div *ngIf="canShowProcesSelector()" class="card-header mt-3 row justify-content-between">
    <div class="col-sm-12 col-md-7 c-left">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="form-group mx-2 institution">
            <label class="text-primary"> Tipo de procesamiento </label>
            <select class="form-select w-100 form-control-solid form-control-lg" [(ngModel)]="showProcess">
              <option>Elige una opción</option>
              <option [value]="'CargaManual'">Carga manual</option>
              <option [value]="'CargaArchivo'">Carga mediante archivo</option>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-md-8">
          <div *ngIf="showProcess == 'CargaArchivo'">
            <div class="mt-button">
              <button (click)="fileUpload()" class="btn btn-primary btn-pill" type="button">
                <i class="flaticon-subir-1 icon-2x text-white"></i>
                Cargar archivo
              </button>
              <div class="form-group hddn">
                <label for="file">Choose File</label>
                <input type="file" #file accept=".xls,.xlsx" (change)="handleFileInput($event.target.files)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-5 c-right mr-0">
      <div class="card-toolbar d-flex justify-content-end">
        <div class="dropdown col-md-auto col-sm-12" *ngIf="privilege.export.value">
          <button class="btn btn-primary btn-pill dropdown-toggle"  type="button" id="dropdownMenuButton"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
              data-original-title="Opciones de exportar"></i>
            Exportar
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item align-items-center"
              href="assets/docs/plantilla-carga-masiva/Carga-Masiva-Desvinculaciones-v1.xlsx"
              download="Plantilla Desvinculacion Masiva.xlsx">
              <i class="flaticon-excel icon-2x text-success mr-3"></i>
              Descargar Plantilla
            </a>
          </div>
        </div>

        <div class="col text-nowrap" *ngIf="privilege.search.value">
          <app-search-bar-pagination (onFilter)="filterSearch($event)" [parameters]="searchBarDisplayNames"
            [namePageLocalStorage]="localStorageSearch"></app-search-bar-pagination>
        </div>
      </div>
    </div>
  </div>

  <div class="mat-dialog-content-custom" *ngIf="showProcess == 'ValidationResults'">
    <div class="card-body"></div>
    <div class="row">
      <div class="col">
        <div class="table-responsive">
          <table class="table table-vertical-center">
            <thead>
              <tr class="bg-primary">
                <th class="text-left">Descripción</th>
                <th class="text-left">Comentario</th>
                <th class="text-left">Cantidad de Errores</th>
                <th class="text-left">¿Es requerido?</th>
                <th class="actionsBtn">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of errorList?.dataList">
                <td class="text-left">{{ item?.description }}</td>
                <td class="text-left">{{ item?.comment }}</td>
                <td class="text-left">{{ item?.quantity }}</td>
                <td class="text-left" *ngIf="item?.isRequired">Si</td>
                <td class="text-left" *ngIf="!item?.isRequired">No</td>
                <td class="actionsBtn">
                  <button class="btn btn-icon btn-light-info btn-sm m-1" (click)="openDetail(item)">
                    <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="dataTables_info">
            Mostrando
            <span class="font-weight-bolder text-primary ml-1">{{
              errorList?.dataList.length
              }}</span>
            Errores<span *ngIf="errorList?.dataList.length > 1">s</span>.
          </div>
          <!-- <div class="card-footer d-flex justify-content-end">
                    <button *ngIf="errorList?.dataList.length>0" (click)="goBack()" type="button"
                      class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-primary">
                      <i class="flaticon2-back"></i>
                      Regresar a la carga
                    </button>
                  </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="showProcess == 'CargaManual'">
      <div class="table-responsive table-responsive-sigei">
        <form>
          <table class="table table-vertical-center caption-top">
            <thead>
              <tr class="bg-primary">
                <th class="text-center">Documento de Identidad</th>
                <th class="text-left">Categoria</th>
                <th class="text-left">Fecha Efectiva</th>
                <th class="text-left">Tipo de Salida</th>
                <th class="text-left">Motivo de Salidad</th>
                <th class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of separationsList; let i = index">
                <td class="text-center">
                  <input (input)="splitDataElements(i, $event, 'id')" value="" type="text"
                    placeholder="Documento de identidad" class="form-control" />
                </td>
                <td class="text-left">
                  <ngx-select-dropdown [options]="categoryTypes" [config]="config" class="flex-1"
                    (change)="splitDataElements(i, $event, 'category')">
                  </ngx-select-dropdown>
                </td>
                <td class="text-left">
                  <input (focusout)="splitDataElements(i, $event, 'effectiveDate')" value="" type="date"
                    class="form-control" />
                </td>
                <td class="text-left">
                  <ngx-select-dropdown [options]="exitTypes" [config]="config" class="flex-1"
                    (change)="splitDataElements(i, $event, 'exitType')">
                  </ngx-select-dropdown>
                </td>
                <td class="text-left">
                  <ngx-select-dropdown [options]="exitReason" [config]="config" class="flex-1"
                    (change)="splitDataElements(i, $event, 'exitReason')">
                  </ngx-select-dropdown>
                </td>
                <td class="actionsBtn">
                  <button title="" (click)="addAtIndex(i)" *ngIf="privilege.add.value" data-toggle="tooltip" data-original-title="Agregar"
                    class="btn btn-sm btn-light-info btn-icon m-1">
                    <i class="flaticon-mas"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="dataTables_info">
        Mostrando
        <span class="font-weight-bolder text-primary ml-1">{{
          separationsList?.length
          }}</span>
        Registros.
      </div>
      <div class="card-footer d-flex justify-content-end">
        <button (click)="submitData()" type="button" *ngIf="privilege.process.value"
          class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success">
          <i class="flaticon-etiqueta"></i>
          Procesar
        </button>
      </div>
    </div>

    <div *ngIf="showProcess == 'Preview'">
      <div class="table-responsive table-responsive-sigei">
        <form>
          <table class="table table-vertical-center caption-top">
            <thead>
              <tr class="bg-primary">
                <th class="text-center">Documento de Identidad</th>
                <th class="text-center">Nombre del Empleado</th>
                <th class="text-left">Categoria</th>
                <th class="text-left">Fecha Efectiva</th>
                <th class="text-left">Tipo de Salida</th>
                <th class="text-left">Motivo de Salidad</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
                  let row of peoplePreviewConfirmation.dataList;
                  let i = index
                ">
                <td class="text-center">
                  <h5>{{ row?.personalIdentification }}</h5>
                </td>
                <td class="text-left">
                  <h5>{{ row?.name }}</h5>
                </td>
                <td class="text-left">
                  <h5>{{ row?.category }}</h5>
                </td>
                <td class="text-left">
                  <h5>{{ formatDatePreview(row?.effectiveDate) }}</h5>
                </td>
                <td class="text-left">
                  <h5>{{ row?.exitType }}</h5>
                </td>
                <td class="text-left">
                  <h5>{{ row?.exitReason }}</h5>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div>
        <app-paginator [listedRecordsLength]="peoplePreviewConfirmation?.dataList?.length"
          [registerQuantity]="peoplePreviewConfirmationOriginal.dataList.length"
          [registerPerPage]="peoplePreviewConfirmationPagedResut.pageSize"
          [totalPages]="peoplePreviewConfirmationPagedResut.totalPages"
          [selectedPage]="peoplePreviewConfirmationPagedResut.page"
          (onChange)="getPreviewConfirmationPaginatedRecords($event)">
        </app-paginator>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <button (click)="requestFinalSeparation()" type="button" *ngIf="privilege.process.value"
          class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success">
          <i class="flaticon-etiqueta"></i>
          Procesar
        </button>
      </div>
    </div>

    <div *ngIf="showProcess == 'CargaArchivo'">
      <div class="text-center clickable" (click)="fileUpload()" *ngIf="fileLoadingStatus == 'waiting'">
        <p><i class="flaticon2-file-2 icon-8x text-primary mr-2"></i></p>
        Carga una plantilla para continuar
      </div>
      <div class="text-center" *ngIf="fileLoadingStatus == 'reading'">
        <p><i class="flaticon2-search icon-8x text-primary mr-2"></i></p>
        Analizando Plantilla ...
      </div>

      <div class="table-responsive table-responsive-sigei" *ngIf="fileLoadingStatus == 'complete'">
        <table class="table table-hover table-vertical-center" style="table-layout: fixed">
          <thead>
            <tr class="bg-primary">
              <th class="text-left" style="width: 220px">
                Documento de identidad
              </th>
              <th class="text-left" style="width: 250px">Categoria</th>
              <th class="text-left" style="width: 120px">Fecha efectiva</th>
              <th class="text-left" style="width: 220px">Tipo Salida</th>
              <th class="text-left" style="width: 220px">Motivo de Salida</th>
              <th class="text-center" style="width: 90px"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of FileUploadedpeoplePreview; let i = index">
              <td class="text-left">
                {{ item?.personalIdentification }}
              </td>
              <td class="text-left">
                {{ item?.category }}
              </td>
              <td class="text-left">
                {{ item?.effectiveDate }}
              </td>
              <td class="text-left">
                {{ item?.exitType }}
              </td>
              <td class="text-left">
                {{ item?.exitReason }}
              </td>
              <td class="text-center">
                <a placement="top" (click)="removeRow(i)" class="btn btn-sm btn-light-danger btn-icon mr-2"
                  data-toggle="tooltip" title="Eliminar">
                  <i class="flaticon-delete icon-2x"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="dataTables_info" *ngIf="fileLoadingStatus == 'complete'">
        <div>
          <app-paginator [listedRecordsLength]="FileUploadedpeoplePreview?.length"
            [registerQuantity]="FileUploadedpeoplePreviewOriginal.length"
            [registerPerPage]="FileUploadedpeoplePreviewPagedResut.pageSize"
            [totalPages]="FileUploadedpeoplePreviewPagedResut.totalPages"
            [selectedPage]="FileUploadedpeoplePreviewPagedResut.page" (onChange)="getPaginatedRecords($event)">
          </app-paginator>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-end" *ngIf="fileLoadingStatus == 'complete'">
        <button type="button" (click)="submitDataFromImportedFile()" *ngIf="privilege.process.value"
          class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success">
          <i class="flaticon-etiqueta"></i>
          Procesar
        </button>
      </div>
    </div>
  </div>
</div>
