import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastService } from '../../shared/toast/toast.service';
import { keyWord } from '../../shared/utils/parameterControl';
import { VacanciesService } from '../../services/vacancies.service';
import { MatDialog } from '@angular/material/dialog';
import { CandidateSearchResultComponent } from '../candidate-search-result/candidate-search-result.component';
import { PersonalRequsitionService } from '../../services/personal-requsition.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApplyVacancyModel } from '../personal-requisition/models/apply-vacancy-model';
import { PersonModel } from '../personal-requisition/models/person.model';
import { ParameterControlService } from '../../services/parameter-control.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { docService } from 'src/app/services/doc.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { IEmployee } from 'src/app/models/Employee.model';
import { PagedResult } from 'src/app/shared/models/page-result';
import { FilterEvent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { SearchBarDisplayNames } from 'src/app/shared/element-ui/search-bar-pagination/models/SearchBarModels';
import { ApplyVacancyModalComponent } from './apply-vacancy-modal/apply-vacancy-modal.component';
import { bounce } from '@amcharts/amcharts5/.internal/core/util/Ease';

@Component({
  selector: 'app-apply-vacancies',
  templateUrl: './apply-vacancies.component.html',
  styleUrls: ['./apply-vacancies.component.css']
})
export class ApplyVacanciesComponent implements OnInit, AfterViewChecked {
  SearchFilters = { TypeSearch: "", Filter: "" }
  FilterOptions: any[] = [];
  Vacancies: any[] = [];
  employees: IEmployee[] = []
  vacantadmissionDate: string = ""
  PersonSelected = new PersonModel();
  searchParameter: FilterEvent
  datePie = new DatePipe('en-US')
  employeesPagedResult = new PagedResult<any>();
  IsviewMode = false;
  VacanciesRecords: any[] = []
  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Secuencia" },
      { propkeyId: 4, displayName: "Puesto Vacante" },
      { propkeyId: 5, displayName: "Tipo de Vacante" },
      { propkeyId: 3, displayName: "Unidad Organizativa" },
      { propkeyId: 2, displayName: "Sucursal" },
      { propkeyId: 6, displayName: "Sexo" }
    ];
  personId: number = 0;
  hoursAvailable: any[] = []
  hourSelected: any
  appliedVacancy: boolean = false
  isSalaryInvalid: boolean = false
  @ViewChild("paginator") paginator: CaptionComponent;

  filterText = '';

  constructor(private _toastService: ToastService,
    private srvParam: ParameterControlService,
    private srvVacancy: VacanciesService,
    private srvPerson: PersonalRequsitionService,
    private _http: HttpClient,
    private dialog: MatDialog,
    private cp: CurrencyPipe,
    private activatedRoute: ActivatedRoute,
    private srvDoc: docService,
    private _cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private authInfo: AuthInfoService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      this.getPersonByIdentification(params.id);
    })
  }

  getActiveEmployeeInstitution(personId: number) {
    this.srvVacancy.activeEmployeeInstitution([personId]).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error("Error al obtener la lista de Servidores Públicos")
        return;
      }
      this.employees = res?.dataList;
    }, error => {
      this._toastService.error("Error al obtener la lista de Servidores Públicos")
    })
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  getPersonByIdentification(identification) {
    this.srvVacancy.getPersonByIdentification(identification).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error("Error al obtener la personada seleccionada")
        return;
      }
      this.PersonSelected = res.dataList[0];
      this.getActiveEmployeeInstitution(this.PersonSelected?.personId);
      this.getPersonPhotoloaded(res.dataList[0].profileImage);
      this.GetVacancies()
    }, error => {
      this._toastService.error("Error al obtener la personada seleccionada")
    })
  }

  getPersonPhotoloaded(guid: any) {
    this.srvDoc.getDocument(guid).subscribe(
      (e: any) => {
        this.PersonSelected.photoloaded = e.data
      }
    )
  }

  vacancyToggle() {
    this.VacanciesRecords = [];
    this.GetVacancies()
  }

  getPaginatedRecords(event: PaginationEvent) {
    const { selectedPage, registersPerPage } = event;
    const { employeesPagedResult } = this;
    if (employeesPagedResult.page === selectedPage && employeesPagedResult.pageSize === registersPerPage) {
      return;
    }
    this.employeesPagedResult.page = selectedPage;
    this.employeesPagedResult.pageSize = registersPerPage;
    this.GetVacancies()
  }

  filterSearch(event: FilterEvent){
    this.employeesPagedResult.page = 1
    this.searchParameter = event
    this.GetVacancies()
  }

  GetVacancies() {
    this.Vacancies = [];
    const { page, pageSize } = this.employeesPagedResult
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.propkeyId ?? 0;


    const params = {
      propKeyId,
      status: 1,
      page,
      pageSize,
      type: !this.appliedVacancy ? 2 : 3,
      searchParameter: text,
      personId: this.PersonSelected.personId
    };
    this.srvVacancy.getVacantsAllPaginated(params).subscribe(data => {
      if (data.succeded) {
        this.Vacancies = data.singleData.items.filter((vacancy:any) => vacancy.sex === this.PersonSelected.sexId || vacancy.sex === 3);
        this.employeesPagedResult = data.singleData
        this._cdRef.detectChanges();
      } else {
        this._toastService.error(data.errors[0]);
      }
    }, error => {
      this._toastService.error('Ha ocurrido un error buscando las vacantes. Consulte con el administrador del sistema');
    });
  }


  VacancyDetail({...vacancyModel}: ApplyVacancyModel, IsView: boolean) {
    this.IsviewMode = IsView;
    if (!IsView) {
      vacancyModel.employee = true
      vacancyModel.transfer = true
      vacancyModel.toTravel = true
      vacancyModel.authorizedData = true
      if (this.PersonSelected.personId)
        vacancyModel.personId = this.PersonSelected.personId;
        vacancyModel.dateApplication = new Date().toLocaleDateString('fr-CA');
        this.openModal(vacancyModel, this.IsviewMode);
    }
    else {
      this.srvVacancy.getPostulation(this.PersonSelected.personId, vacancyModel?.vacantId).subscribe((res) => {
        if (res.succeded) {
          vacancyModel = res.dataList[0];
          vacancyModel.dateApplication = this.datePie.transform(res.dataList[0].dateApplication, 'yyyy-MM-dd')
          vacancyModel.startDate = this.datePie.transform(res.dataList[0].startDate, 'yyyy-MM-dd')
          this.openModal(vacancyModel, this.IsviewMode);
        }
        else {
          this._toastService.error('Ha ocurrido un error con su solicitud por favor comunicarse con su administrador')
        }
      })
    }
  }

  openModal(vacancyModel, isviewMode: boolean){
    this.dialog.open(ApplyVacancyModalComponent, {
      data: {
        vacancyModel: vacancyModel,
        isviewMode: isviewMode
      },
      width: '70%'
    })
    .afterClosed()
    .subscribe({
      next: (res: {success: boolean}) => {
        if(res?.success){
          this.GetVacancies();
        }
      }
    });
  }

  onKeyChange(text) {
    this.filterText = text;
  }

}
