export class EmployeeChange {
    header: changeHeader
    details: changeDetails[] = []
}

export interface changeHeader {
    employeeChangeHeaderId?: number;
    employeeId: number;
    date: string;
    requestTypes: number;
    requestMapid: number;
    createUserId: number;
    modifyUserId: number;
    modifyDate: string;
    createDate: string;
    status: boolean;
    companyId: number;
    userName?: string;
}

export interface changeDetails {
    parameterName: string;
    reason: string;
    publicAccessId?: string;
    employeeChangeDetailsId?: number;
    employeeChangeHeaderId: number;
    parameterChangeId: number;
    valueBeforeId: number;
    valueBefore: string;
    valueAfterId: number;
    valueAfterNumeric: number;
    additionalValueName?: string;
    additionalValue: number;
    additionalValueBefore?: number;
    additionalValueBeforeName?: string;
    employeeChangeDetailsHrmid?: number;
    valueAfter: string;
    reasonId: number;
    condition: number;
    comment: string;
    evidence: string;
    usrApproved: number;
    dateApproved: string;
    effectiveDate: Date;
    companyId: number;
    status: boolean;
    isHistory?: boolean;
    createUserId: number;
    modifyUserId: number;
    userName?: string
}


export interface IEmployeeChangeGetBase64 {
    employeeChangeDetailsId: number;
    companyName: string;
    employeeFirstName: string;
    employeeLastName: string;
    personalIdentification: string;
    positionName: string;
    salary: number;
    supervisor: string;
    admissionDate: string;
    departmentName: string;
    parameterName: string;
    employeeStatus: boolean;
    employeeBudgetArea: string;
    employeeType: string;
    changeType: number;
    valueBefore: string;
    valueAfter: string;
    valueAfterNumeric: number;
}

export interface IAdditionalValue{
    additionalValue: number;
    additionalValueName: string;
}