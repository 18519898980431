import { Component, OnInit } from '@angular/core';
import { AuthInfoService } from 'src/app/services/auth-info.service';

@Component({
  selector: 'app-telephone-directory',
  templateUrl: './telephone-directory.component.html',
  styleUrls: ['./telephone-directory.component.css']
})
export class TelephoneDirectoryComponent implements OnInit {
  selectedItems
  tabSelected = 'tab1';

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'TELEPHONE-DIRECTORY',
    editAction: 'EDIT',
    exportAction: 'EXPORT',
    searchAction: 'Search'
  }

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  
  constructor(public authInfo: AuthInfoService) { }

  ngOnInit(): void {
   
  }

  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Click para desplegar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

}
