<h2 class="mat-dialog-title"> <span class="h-20px label label-danger label-dot mr-3 w-20px"></span> Dashboard de
    Incidentes
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</h2>
<div class="mat-dialog-content">
    <div class="card-body">
        <div class="d-flex justify-content-between align-items-end mb-3">
            <div class="text-left">
                <h3>Filtrado</h3>
                <div class="text-left d-flex">
                    <h4 class="d-flex font-weight-bolder text-primary">Desde: <span
                            class="text-dark font-weight-lighter ml-3">{{filterParams.date1 |date:'dd/MM/yyyy' ||'
                            '}}</span></h4>
                    <h4 class="d-flex font-weight-bolder mx-5 text-primary">Hasta: <span
                            class="text-dark font-weight-lighter ml-3">{{filterParams.date2|date:'dd/MM/yyyy'||'
                            '}}</span></h4>
                </div>
            </div>
            <div>
                <button class="btn btn-light-primary mx-2" (click)="filterByDate()">
                    <i class="fas fa-filter" data-toggle="tooltip" data-title="Filtrar"></i>
                </button>
                <button class="btn btn-light-warning" [disabled]="!filtered" (click)="clearFilter()">
                    <i class="fas fa-redo" data-toggle="tooltip" data-title="Reiniciar filtros"></i>
                </button>
            </div>
        </div>
        <div class="card bg-white p-5 gutter-b">
            <div class="row gutter-b">
                <div class="col-lg-12">
                    <div class="d-flex align-items-center bg-light-warning rounded p-5 mb-5">
                        <span class="svg-icon svg-icon-warning svg-icon-4x mr-5">
                            <span class="svg-icon svg-icon-primary svg-icon-2x">
                                <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Text/Bullet-list.svg--><svg
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path
                                            d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z"
                                            fill="#000000" />
                                        <path
                                            d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z"
                                            fill="#000000" opacity="0.3" />
                                    </g>
                                </svg>
                                <!--end::Svg Icon-->
                            </span>
                        </span>
                        <div class="d-flex flex-column flex-grow-1 mr-2">
                            <h3 class="font-weight-bold text-dark-75">Total</h3>
                        </div>
                        <span class="font-weight-bolder text-warning py-1 font-size-h2">
                            {{totalIncidents}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">


            <div class="col-lg-6">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Por Sexo</h4>
                    </div>
                    <div class="card-body p-3">
                        <apx-chart [series]="chartOptions?.series" [chart]="chartOptions?.chart"
                            [title]="chartOptions?.title" [labels]="chartOptions?.labels"
                            [dataLabels]="chartOptions.dataLabels"
                            [plotOptions]="chartOptions?.plotOptions" [stroke]="chartOptions?.stroke"
                            [fill]='chartOptions?.fill' [colors]='chartOptions?.colors'>
                        </apx-chart>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Por Estado De Nombramiento</h4>
                    </div>
                    <div class="card-body p-3">
                        <apx-chart [series]="chartOptions2?.series" [chart]="chartOptions2?.chart"
                            [title]="chartOptions2?.title" [labels]="chartOptions2?.labels"
                            [dataLabels]="chartOptions2.dataLabels"
                            [plotOptions]="chartOptions2?.plotOptions" [stroke]="chartOptions2?.stroke"
                            [fill]='chartOptions2?.fill' [colors]='chartOptions2?.colors'>
                        </apx-chart>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card card-custom bg-white">
                    <div class="card-header p-2 mt-2 d-flex justify-content-between">
                        <h3 class="ms-3 font-weight-bold text-dark-75 py-3">Por Unidad organizativa</h3>
                        <ngx-select-dropdown 
                        [multiple]='true' 
                        (change)="changeDepartment()" 
                        [options]="departments" 
                        [(ngModel)]="department" 
                        [config]="dropdownConfig('description')"
                         class="col-12 col-xxl-4"></ngx-select-dropdown> 
                    </div>
                    <div class="card-body p-3">
                        <apx-chart [series]="barOptions2?.series" [chart]="barOptions2?.chart"
                            [dataLabels]="barOptions2?.dataLabels" [plotOptions]="barOptions2?.plotOptions"
                            [yaxis]="barOptions2?.yaxis" [legend]="barOptions2?.legend" [fill]="barOptions2?.fill"
                            [stroke]="barOptions2?.stroke" [tooltip]="barOptions2?.tooltip"
                            [xaxis]="barOptions2?.xaxis">
                        </apx-chart>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Por Tipo de Incidente</h4>
                    </div>
                    <div class="card-body p-3">
                        <apx-chart [series]="barOptions?.series" [chart]="barOptions?.chart"
                            [dataLabels]="barOptions?.dataLabels" [plotOptions]="barOptions?.plotOptions"
                            [yaxis]="barOptions?.yaxis" [legend]="barOptions?.legend" [fill]="barOptions?.fill"
                            [stroke]="barOptions?.stroke" [tooltip]="barOptions?.tooltip" [xaxis]="barOptions?.xaxis">
                        </apx-chart>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-12">
                <div class="card card-custom bg-white">
                    <div class="card-header p-3">
                        <h4 class="card-title">Por Grado del Incidente</h4>
                    </div>
                    <div class="card-body p-3">
                        <apx-chart [series]="barOptions3?.series" [chart]="barOptions3?.chart"
                            [dataLabels]="barOptions3?.dataLabels" [plotOptions]="barOptions3?.plotOptions"
                            [yaxis]="barOptions3?.yaxis" [legend]="barOptions3?.legend" [fill]="barOptions3?.fill"
                            [stroke]="barOptions3?.stroke" [tooltip]="barOptions3?.tooltip" [xaxis]="barOptions3?.xaxis">
                        </apx-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- <div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" (click)="close()" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div> -->