import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApexNonAxisChartSeries, ApexChart, ApexResponsive, ApexFill, ApexStroke, ApexStates, ApexLegend, ApexTitleSubtitle, ApexTheme, ApexPlotOptions, ApexDataLabels, ApexAxisChartSeries, ApexXAxis, ApexYAxis, ApexTooltip, ChartComponent } from 'ng-apexcharts';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { IDashboardOptions, VacanciesListDashboardService } from 'src/app/services/vacancies-list-dashboard.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
import { BarOptions, ChartOptions } from 'src/app/shared/models/apexChartModels';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { getPercentage } from 'src/app/shared/utils/utility';
import { IDepartmentStats } from './models/Department';
import { IEnclosureStats } from './models/EnclosureStats';
import { IGenderStats } from './models/Gender';
import { IPostVacantDashboard, VacantsByAcademicLevelQuantity, VacantsByDepartmentsQuantity, VacantsByEnclosureQuantity, VacantsByGenderQuantity } from './models/VacantDashboard.models';

enum stats {
  published = 1,
  notpublished = 0,
  female = 1,
  male = 2,
  undefined = 3,
  ITLA = 1,
  ITLASD = 2,
  ITLAST = 3
}
@Component({
  selector: 'app-vacancies-dashboard',
  templateUrl: './vacancies-dashboard.component.html',
  styleUrls: ['./vacancies-dashboard.component.css']
})
export class VacanciesDashboardComponent implements OnInit, OnDestroy {
  suscriptions: Subscription = new Subscription();
  loadingMessage = 'Cargando...'
  noDataMessage = 'No hay datos'
  public chartOptions: Partial<ChartOptions>;
  public barOptions: Partial<BarOptions>;
  public bar3years: Partial<BarOptions>;
  public chartOptions2: Partial<ChartOptions>;
  private errorMessage = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  filtered = false
  filterParams = {
    year: null,
    date2: null,
    date1: null,
    companyId: this.authInfo.getCompanyId()
  }
  dataGender = {
    series: [],
    labels: [],
    message: this.loadingMessage
  }
  dataEnclousures = {
    series: [],
    labels: [],
    message: this.loadingMessage
  }
  academicLevelChart = {
    series: [],
    labels: [],
    message: this.loadingMessage
  }
  amountPublished: { total: number, percentage: number } = {
    total: 0,
    percentage: 0
  };
  amountNotPublished: { total: number, percentage: number } = {
    total: 0,
    percentage: 0
  };
  amountVacants: number = 0;

  public dataDepartmentMessage = this.loadingMessage;

  departments: any[]= [];
  department: any[] = [];
  
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<VacanciesDashboardComponent>,
    private srvDashboard: VacanciesListDashboardService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private authInfo: AuthInfoService) {

  }

  ngOnDestroy(): void {
    this.suscriptions.unsubscribe()
  }

  ngOnInit(): void {
    this.filterParams.date1 = this.datePipe.transform(`${this.authInfo.getConstitutionDate()}`, 'yyyy-MM-dd')
    this.filterParams.date2 = this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd')
    this.loadChart();
    this.getDashboardData()

    //this.getChart()
  }

  changeDepartment(){
    this.setDepartments([]);

    if (this.department.length < 1) {
      return
    }
    let data: any[] = []
    this.department.forEach(e => {
      let chartData: any = this.departments.find(x => x.description2 == e.description2)
      if (!chartData) {
        chartData = this.departments.find(x => x.description2 == e.description2) ?? { rowText: e.description2, value1: 0, value2: 0 }
      }
      let cData = Object.assign({}, chartData)
      data.push(cData)
    });
    this.setDepartments([...data]);
  }

  dropdownConfig(displayKey, placeholder?) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(FilterDashboardComponent, {
      data: { filterParams: this.filterParams },
    });

    let sub = dialogRef.afterClosed().subscribe((result) => {
      if (!result) return
      this.filtered = result.year != null || result.date1 != null || result.date2 != null;

      let year = new Date().getFullYear()

      if (result?.applyFilter == false) {
        return
      }
      if (result.year) {
        this.filterParams = {
          year: result.year,
          date1: `${result.year}-01-01`,
          date2: `${result.year}-12-31`,
          companyId: result.companyId
        }
      } else {
        if (result.date1 && !result.date2) {
          this.filterParams = {
            year: null,
            date1: result.date1,
            date2: null,
            companyId: result.companyId
          }
        }
        if (result.date2 && !result.date1) {
          this.filterParams = {
            year: null,
            date1: null,
            date2: result.date2,
            companyId: result.companyId
          }
        }
        if (result.date1 && result.date2) {
          this.filterParams = {
            year: null,
            date1: result.date1,
            date2: result.date2,
            companyId: result.companyId
          }
        }
      }
      this.getDashboardData()
      //console.log(result);
    });
    this.suscriptions.add(sub)
  }

  getChart() {
    //this.setEnclosures()
    //this.setGenders()
    //this.setDepartments()
    this.setAll()
    this.setPublished()
    this.setNotPublished()
    //this.setAcademicLevel()
  }

  getDashboardData() {
    this.academicLevelChart.message = this.loadingMessage
    this.dataGender.message = this.loadingMessage
    this.dataEnclousures.message = this.loadingMessage
    this.dataDepartmentMessage = this.loadingMessage
    this.srvDashboard.getDashboardData(this.filterParams.date1, this.filterParams.date2, this.filterParams.companyId).subscribe((res: IPostVacantDashboard) => {
      if (res.succeded) {
        let d = res.singleData

        this.setGenders(d.vacantsByGenderQuantities)
        this.setAcademicLevel(d.vacantsByAcademicLevelQuantities);
        this.departments = d.vacantsByDepartmentsQuantities;
        this.department = [...this.departments.slice(0,10)];
        this.setDepartments([...this.department]);
        let totalv = d.vacantsTotalQuantities
        this.amountVacants = totalv.length > 0 ? d.vacantsTotalQuantities[0].value : 0
        let published = d.vacantsTotalPublishedQuantities
        this.amountPublished.total = published.length > 0 ? published[0].value : 0
        let notp = d.vacantsTotalNoPublishedQuantities
        this.amountNotPublished.total = notp.length > 0 ? notp[0].value : 0
        let total = this.amountNotPublished.total + this.amountPublished.total
        this.amountNotPublished.percentage = this.getPercentage(total, this.amountNotPublished.total)
        this.amountPublished.percentage = this.getPercentage(total, this.amountPublished.total)
        //this.set3yearChart(d.vacantsTotalBy3LastYearsQuantities)
      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error(this.errorMessage)
    })
  }

  setEnclosures(data: VacantsByEnclosureQuantity[]) {
    this.dataEnclousures.series = [];
    this.dataEnclousures.labels = []
    if (data.length == 0) {
      this.dataEnclousures.message = this.noDataMessage
    }
    data.forEach(element => {
      this.dataEnclousures.series.push(element.value);
      this.dataEnclousures.labels.push(element.description);
    });
  }
  clear() {
    this.filterParams = {
      date1: null,
      date2: null,
      year: null,
      companyId: this.authInfo.getCompanyId()
    }
    this.getDashboardData()
  }
  setGenders(data: VacantsByGenderQuantity[]) {
    this.dataGender.series = [];
    this.dataGender.labels = []

    if (data.length == 0) {
      this.dataGender.message = this.noDataMessage
    }
    data = data.filter(x => x.description != null)
    data.forEach((element) => {
      this.dataGender.series.push(element.value)
      this.dataGender.labels.push(element.description)
    })

  }
  setAcademicLevel(data: VacantsByAcademicLevelQuantity[]) {
    this.academicLevelChart.series = [];
    this.academicLevelChart.labels = []
    let options = this.getOptions()

    if (data.length == 0) {
      this.academicLevelChart.message = this.noDataMessage
    }
    data = data.filter(x => x.description != null)
    data.forEach((element) => {
      this.academicLevelChart.series.push(element.value)
      this.academicLevelChart.labels.push(element.description)
    })

  }
  setAll() {
    let options = this.getOptions()
    let sub = this.srvDashboard.getAll(options).subscribe((res: any) => {
      if (res.succeded) {
        this.amountVacants = res.singleData
      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error(this.errorMessage)
    })
    this.suscriptions.add(sub)
  }
  setPublished() {
    let options = this.getOptions()
    let sub = this.srvDashboard.getPublished(options).subscribe((res: any) => {
      if (res.succeded) {
        this.amountPublished.total = res.singleData
      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error(this.errorMessage)
    })
    this.suscriptions.add(sub)
  }
  setNotPublished() {
    let options = this.getOptions()
    let sub = this.srvDashboard.getNotPublished(options).subscribe((res: any) => {
      if (res.succeded) {
        this.amountNotPublished.total = res.singleData
      } else {
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error(this.errorMessage)
    })
    this.suscriptions.add(sub)
  }
  getOptions(): IDashboardOptions {
    return {
      dateFrom: this.filterParams.date1 ? new Date(this.filterParams.date1) : null,
      dateTo: this.filterParams.date2 ? new Date(this.filterParams.date2) : null,
      companyId: this.authInfo.getCompanyId()
    }
  }
  setDepartments(data: VacantsByDepartmentsQuantity[]) {
    this.barOptions.series = [];
    this.barOptions.plotOptions = {
      bar: {
        dataLabels: {
          position: "top" // top, center, bottom
        }
      }
    };
    this.barOptions.dataLabels = {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"]
      }
    };

    if (data.length == 0) {
      this.dataDepartmentMessage = this.noDataMessage
    }
    data = data.filter(x => x.description != null)
    let data2 = []
    data.forEach((element) => {
      data2.push(
        {
          x: element.description2,
          y: element.value,
          z: element.description,
        }
      )
    })
    this.barOptions.series = [{
      data: data2
    }]
  }
  set3yearChart(last3Data) {
    let data = []
    if (last3Data[0].description == "2021") {
      data.push({
        x: 2020,
        y: 0
      })
    }
    last3Data.forEach(e => {
      data.push({
        x: e.description,
        y: e.value
      })
    })
    this.bar3years.series = []
    this.bar3years.series = [{
      data: data
    }]
  }
  getPercentage(total: number, count: number) {
    return getPercentage(total, count)
  }

  loadChart() {
    this.chartOptions = {
      series: [45, 30, 25],
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: true,
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4
        },
        dropShadow: {
          enabled: false
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      legend: {
        show: true,
        position: 'bottom'
      },

      responsive: [

        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
              position: 'bottom',
            }
          }
        }
      ]
    };

    this.bar3years = {
      series: [
        {
          name: "Cantidad de requisiciones",
          data: [60, 55, 57]
        }
      ],
      chart: {
        zoom: {
          enabled: false
        },
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "data 1",
          "data 2",
          "data 3"
        ]
      },
      yaxis: {
        title: {
          text: ""
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        }
      }
    }


    this.barOptions = {
      series: [
        {
          name: "Cantidad de vacantes",
          data: [0, 0, 0],
        }
      ],
      chart: {
        type: "bar",
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "30%",
          distributed: true
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
        ],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: ""
        },
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        },
        z: {
          formatter: undefined,
          title: ''
        }
      }
    };
  }
}
