import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Inject, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { BarOptions, ChartOptions } from 'src/app/shared/models/apexChartModels';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { Enclosures, Genders } from '../../models/dashboard.enum';
import { PersonalRequisitionsModel } from '../../models/personal-requisitions';
import { DateFilterComponent } from '../dateFilter/date-filter/date-filter.component';
import * as moment from 'moment';

declare const $: any;


@Component({
  selector: 'app-requisition-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewChecked {
  public chartOptions: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions>;
  public barOptions: Partial<BarOptions>;
  public barOptions2: Partial<BarOptions>;
  companyId: any
  datePipe = new DatePipe('en-Us')

  requisitionDashboardData: any

  totalRequisitions:number = 0
  requisitionsByGender: any[] = []
  requisitionsByprecinct: any[] = []
  requisitionsByDepartment: any[] = []
  requisitionsByYear: any[] = []
  approvedRequisitions: number;
  deniedRequisitions: number;
  dateFrom: string = ''
  dateTo: string = new Date().toLocaleDateString('fr-CA')
  refresIsDisabled: boolean = true

  filterParams: any;

  departments: any[]= [];
  department: any[] = [];

  constructor(
    private _toastService: ToastService,
    private prService: PersonalRequsitionService, private dialog: MatDialog,
    public dialogRef: MatDialogRef<DashboardComponent>,
    private _changeDet: ChangeDetectorRef,
    private authInfo: AuthInfoService  
  ) {
    this.companyId = this.authInfo.getCompanyId()
    this.dialogRef.disableClose = true;
    this.buildCharts()
    
  }


  changeDepartment(){
    this.requisitionsByDepartment = [];
    this.setDataDepartment(this.requisitionsByDepartment);

    if (this.department.length < 1) {
      return
    }
    let data: any[] = []
    this.department.forEach(e => {
      let chartData: any = this.departments.find(x => x.description2 == e.description2)
      if (!chartData) {
        chartData = this.departments.find(x => x.description2 == e.description2) ?? { rowText: e.description2, value1: 0, value2: 0 }
      }
      let cData = Object.assign({}, chartData)
      data.push(cData)
    });
    this.requisitionsByDepartment = [...data];
    this.setDataDepartment([...data]);
  }


  dropdownConfig(displayKey, placeholder?) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  buildCharts() {
    this.chartOptions = {
      series: [0, 0, 0],
      chart: {
        // width: 425,
        type: "donut",
      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      title: {
        text: ""
      },
      labels: [],
      fill: {
        colors: ["#9bb5fb", "#2b82ed", "#edb2c8", "#eb0a5c"],
      },
      colors: ["#9bb5fb", "#2b82ed", "#edb2c8", "#eb0a5c"],
      legend: {
        show: true,
        position: 'bottom'
        },

      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
            show: false,
            position: 'bottom',
            }
          }
        }
      ]
    };

    this.chartOptions2 = {
      series: [0, 0, 0],
      chart: {
        type: "donut",
      },
      stroke: {
        width: 0
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      title: {
        text: ""
      },
      labels: [],
      fill: {
        colors: ["#edb2c8", "#9bb5fb", "#2b82ed"]
      },
      colors: ["#edb2c8", "#9bb5fb", "#2b82ed"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            show: false,
            position: 'bottom',
          }
        }
      ]
    };

    this.barOptions = {
      series: [
        {
          name: "Cantidad de requisiciones",
          data: [0, 0, 0]
        }
      ],
      chart: {
        type: "bar",
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: true,
          zoomedArea: {
            stroke: {
              width: 800
            }
          }

        }

      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },

      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
        ],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: ""
        }
      },
      legend:{
        show: false
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        }
      }
    };

    this.barOptions2 = {
      series: [
        {
          name: "Cantidad de requisiciones",
          data: [0, 0, 0],
        }
      ],
      chart: {
        type: "bar",
        height: 450,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
        ],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: ""
        },
      },
      legend:{
        show: false
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        },
        z: {
          formatter: undefined,
          title: ''
        }
      }
    };
  }

  ngOnInit(): void {
    console.log(this.authInfo.getConstitutionDate());
    this.dateFrom = this.datePipe.transform(`${this.authInfo.getConstitutionDate()}`, 'MM-dd-yyyy');
    this.buildCharts()
    this.getDashboardData()
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  filterByDate(): void {
    this.dialog.open(DateFilterComponent, {
      data: {dateFrom: this.dateFrom, dateTo: this.dateTo, companyId: this.companyId}
    })
      .afterClosed()
      .subscribe((result) => {
        this.refresIsDisabled = !result.Applied
        if (!result.Applied) {
          return
        }

        else if (result.dateTo == '' || result.dateFrom == '') {
          return
        }
        this.dateTo = this.datePipe.transform(result.dateTo, 'MM-dd-yyyy')
        this.dateFrom = result.dateFrom
        this.companyId = result.companyId
        this.getDashboardData()
      });
  }

  clearFilter(): void {

    if (this.refresIsDisabled) {
      return
    }

    this.dateFrom = this.datePipe.transform(`${this.authInfo.getConstitutionDate}`, 'MM-dd-yyyy');
    this.dateTo = this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'MM-dd-yyyy');
    this.getDashboardData()
    this.refresIsDisabled = true
  }

  getDashboardData() {
    this.prService.getStatistics(this.companyId, this.dateFrom, this.dateTo).subscribe(data => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return;
      }
      if (data.succeded) {
        this.requisitionDashboardData = data.singleData
        this.setDashboardData()
      }
    }, error => {
    });

  }

  setDashboardData() {
    this.setRequisitionsByCondition()
    this.setRequisitionsByGender()
    this.setRequisitionsByDepartment()
    this.setRequisitionsByYear()
    this.setTotalRequisitions()
  }

  setTotalRequisitions() {
    this.totalRequisitions =  this.requisitionDashboardData?.requisitionsTotalQuantities[0]?.value
  }
  setRequisitionsByCondition() {
    this.approvedRequisitions = this.requisitionDashboardData?.requisitionConditionQuantities.find((rdd: any) => rdd.description == true)?.value ?? 0
    this.deniedRequisitions = this.requisitionDashboardData?.requisitionConditionQuantities.find((rdd: any) => rdd.description == false)?.value ?? 0
  }

  setRequisitionsByEnclosure() {
    this.requisitionsByprecinct = this.requisitionDashboardData?.requisitionByEnclosureQuantities
    this.chartOptions.series = []
    this.chartOptions.labels = []
    this.requisitionsByprecinct.forEach(en => {
      this.chartOptions.series.push(en.value)
      this.chartOptions.labels.push(en.description)
    })
  }

  setRequisitionsByGender() {
    this.requisitionsByGender = this.requisitionDashboardData?.requisitionByGenderQuantities
    this.chartOptions2.series = []
    this.chartOptions2.labels = []
    this.requisitionsByGender.forEach(g => {
      this.chartOptions2.labels.push(g.description)
      this.chartOptions2.series.push(g.value)
    })
  }

  setRequisitionsByDepartment() {
    this.departments = [...this.requisitionDashboardData?.requisitionByDepartmentQuantities];
    this.department = [...this.departments.slice(0,10)];
    this.requisitionsByDepartment = [...this.department];
    this.setDataDepartment([...this.requisitionsByDepartment]);
  }

  setDataDepartment(deparments){
    let data = []
    deparments.forEach(dp => {
      data.push({
        x: dp.description2,
        y: dp.value,
        z: dp.description
      })
    })
    this.barOptions2.series = [{
      data: data
    }]
  }

  setRequisitionsByYear() {
    this.requisitionsByYear = this.requisitionDashboardData?.requisition3LastYearsQuantities
    let data = []
    this.requisitionsByYear.forEach(ry => {
      data.push({
        x: ry.description,
        y: ry.value
      })
    })
    this.barOptions.series = [{
      data: data
    }]
  }



  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

}
