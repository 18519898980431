<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="fs-3 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px "></span> Agregar Correos
    </h2>
</div>

<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <form [formGroup]="emailForm" class="row">
            <div class="form-group col-md-6">
                <label class="">Tipo de Correo <span class="text-danger">*</span></label>
                <ngx-select-dropdown [options]="emailTypeList" formControlName="emailType"
                    [config]='dropdownConfig("stringData")'></ngx-select-dropdown>
                    <errors-component [controlName]="emailForm.controls['emailType']"></errors-component>
            </div>

            <div class="form-group col-md-6">
                <label for="">Correo electrónico <span class="text-danger">*</span></label>
                <input formControlName="email" (change)="isEmailValid()" class="form-control form-control-solid form-control-lg" id="tel"
                    type="email" placeholder="Ej: example@email.com" autocomplete="false">

                <errors-component [controlName]="emailForm.controls['email']"></errors-component>
                <small class="text-danger" *ngIf="showEmailError">Debe ingresar un correo válido</small>
            </div>

            <div class="form-group col-md-6">
                <label for="" class="d-block">¿Correo Primario? <span class="text-danger">*</span></label>
                <div class="form-check form-check-inline">
                    <label class="form-check-label mt-2 check-dtl">
                        <input [value]="principal.yes" formControlName="isPrimary" class="form-check-input" type="radio">
                        Sí
                    </label>
                </div>
                <div class=" form-check form-check-inline">
                    <label class="form-check-label mt-2 check-dtl">
                        <input [value]="principal.no" formControlName="isPrimary" class="form-check-input"  type="radio"> No
                    </label>
                </div>
            </div>
        </form>
    </div>
</div>

<div class=" mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cancelar
    </button>
    <button [disabled]="emailForm.invalid" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success"
        type="button" (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Aceptar</span>
    </button>
</div>