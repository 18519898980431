import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IHttpClient } from './Ihttp-client';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService implements IHttpClient {
  private readonly url = environment.api_url;
  private readonly url_core = environment.api_core_url;
  private readonly fileUrl = environment.api_url;
  constructor(private _http: HttpClient) {}

  get<T>(route: string, options?: {}): Observable<T> {
    return this._http.get<T>(`${this.url}/${route}`, options);
  }

  getById<T>(route: string, id: number, options?: {}): Observable<T> {
     return this._http.get<T>(`${this.url}/${route}/${id}`, options);
  }

  post<T>(obj: object, route: string): Observable<T> {
        return this._http.post<T>(`${this.url}/${route}`, obj);
  }

  postFileManager<T>(obj: object, route: string): Observable<T> {
    return this._http.post<T>(`${this.fileUrl}/${route}`, obj);
  }

  update<T>(obj: object, route: string, id?: number): Observable<T> {
    if(id)
      return this._http.put<T>(`${this.url}/${route}/${id}`, obj);
    else  
      return this._http.put<T>(`${this.url}/${route}`, obj);
  }

  patch<T>(obj: any, route: string): Observable<any> {
    return this._http.patch<T>(`${this.url}/${route}`, obj);
  }

  delete<T>(id: number, route: string): Observable<any> {
    return this._http.delete<T>(`${this.url}/${route}/${id}`);
  }

  customDelete<T>(endpoint: string, keyProp: string, id: number, keyProp2?:string, id2?:number) {
    return this._http.delete(`${this.url_core}/${endpoint}?${keyProp}=${id}&${keyProp2}=${id2}`);
  }
}
