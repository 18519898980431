import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilterDashboardService {
  private api_lobby = environment.api_core_sigei;

  constructor(
    private http: HttpClient
  ) { }

  getCompaniesById(id){
    return this.http.get(`${this.api_lobby}/Companies?CompanyCode=${id}&Status=1&Type=6`)
  }
}
