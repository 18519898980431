<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>
        <ng-content select="[title]"></ng-content>
    </h2>
    <button type="button" (click)="close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom" [style]="styles">
        <ng-content select="[body]"></ng-content>
      
    </div>
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <ng-content select="[buttons]"></ng-content>
</div>