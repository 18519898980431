import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import { IDashboardOptions } from './vacancies-list-dashboard.service';
enum types{
  department=1,
  gender = 2,
  total=3,
  academicLevel=13
}
@Injectable({
  providedIn: 'root'
})
export class InterviewDashboardService {

  private readonly api = environment.api_url
  constructor(
    private http: HttpClient,
    private authInfo: AuthInfoService
  ) { }
  
  getInterviewDashboard(dateFrom1 = null, dateTo1 = null, companyId = this.authInfo.getCompanyId()){
    let year = new Date().getFullYear()
    let dateFrom = dateFrom1 ?? `2000-01-01`
    let dateTo = dateTo1 ?? `${year}-12-31`
    return this.http.get(`${this.api}/PostulationsInterviews/Dashboard?dateFrom=${dateFrom}&dateTo=${dateTo}&Status=true&CompanyId=${companyId}`)
  }
  getTotal(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    return this.http.get(this.getEndpoint(options, types.total))
  }
  getTotalByDepartment(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    return this.http.get(this.getEndpoint(options, types.department))
  }
  getTotalByGender(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    return this.http.get(this.getEndpoint(options, types.gender))
  }
  getTotalByAcademicLevel(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }){
    return this.http.get(this.getEndpoint(options, types.academicLevel))
  }

  private getEndpoint(options: IDashboardOptions = {dateFrom: null, dateTo: null, companyId: this.authInfo.getCompanyId() }, type):string{
    let dateFrom:any = options.dateFrom
    let dateTo = ''
    if(options.dateTo == null){
      dateTo = '3000-12-31'
    }else{
      dateTo = formatDate(options.dateTo, 'yyyy-MM-dd', 'en')
    }
    if(options.dateFrom != null){
      dateFrom = formatDate(dateFrom, 'yyyy-MM-dd', 'en')
      return `${this.api}/PersonsPostulationVacants/Dashboard?Type=${type}&DateTo=${dateTo}&DateFrom=${dateFrom}&CompanyId=${options.companyId}&Status=true`
    }else{
      return `${this.api}/PersonsPostulationVacants/Dashboard?Type=${type}&DateTo=${dateTo}&CompanyId=${options.companyId}&Status=true`
    }
  }
}
