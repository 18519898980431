<ng-container [ngSwitch]="format">
    <span *ngSwitchCase="formatTypes.Currency">
    RD
    <el-ellipsis
      [text]="value | currency"
      [charLength]="charLength"
    ></el-ellipsis>
  </span>

    <span *ngSwitchCase="formatTypes.YesOrNo">
    {{ value ? "Si" : "No" }}
  </span>

    <span *ngSwitchCase="formatTypes.Telephone">
    {{ value | mask: "(000)-000-0000" }}
  </span>

    <span *ngSwitchCase="formatTypes.Date">
    {{ value | date }}
  </span>

  <span *ngSwitchCase="formatTypes.IdCard">
    {{ value | mask: "000-0000000-0" }}
  </span>
    <span *ngSwitchCase="formatTypes.Number">
    <el-ellipsis
      [text]="value | number"
      [charLength]="charLength"
    ></el-ellipsis>
  </span>

    <span *ngSwitchDefault>
    <el-ellipsis
      [text]="valueOf(value)"
      [charLength]="charLength"
    ></el-ellipsis>
  </span>
</ng-container>