<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon mr-5">
                <i class="flaticon-contratacion icon-3x text-danger"></i>
            </span>
            <h1 class="d-flex flex-column">
                Reporte de Entrevista
                <small>Datos Generales</small>
            </h1>
        </div>
        <div class="card-toolbar">
            <button routerLink="/interview-list" class="btn btn-pill font-weight-bolder btn-shadow btn-warning">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
            </button>
        </div>
    </div>
    <form [formGroup]="form">
        <div class="card-body">
            <div class="">
                <div class="col-md-12">
                    <div *ngIf="personSelected" class="bg-light-info rounded row h-100">
                        <div class="text-center col-md-3 center">
                            <div class="symbol symbol-150 d250 rd20 mr-3 my-5 center">
                                <img *ngIf="!this.personSelected.photo" alt="Pic" src="./assets/media/no-user.png" />
                                <img *ngIf="this.personSelected.photo" alt="Pic"
                                    [src]="this.personSelected.photoloaded" />
                            </div>
                        </div>
                        <div class="col-md-9 d-flex justify-content-center flex-column">
                            <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Nombre: <span
                                    class="text-dark ml-3">{{personSelected.personName}}</span></h3>
                            <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Identificación:
                                <span class="text-dark ml-3"
                                    *ngIf="personSelected.typeIdentificationId == 1 || personSelected.typeIdentificationId == 4">{{personSelected?.personalIdentification
                                    | mask:'000-0000000-0' || ""}}</span>
                                <span class="text-dark ml-3"
                                    *ngIf="personSelected.typeIdentificationId != 1 && personSelected.typeIdentificationId != 4">{{personSelected?.personalIdentification
                                    || ""}}</span>
                            </h3>
                            <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Teléfono: <span
                                    class="text-dark ml-3">{{personSelected.phoneNumber | mask:'(000) 000-0000'}}</span>
                            </h3>
                            <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Correo Electrónico: <span
                                    class="text-dark ml-3">{{personSelected.email}}</span></h3>
                            <!-- <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Nivel Academico: <span class="text-dark ml-3">{{personSelected.academicLevel}}</span></h3> -->
                            <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Cargo Solicitado: <span
                                    class="text-dark ml-3">{{personSelected.positionName}}</span></h3>
                            <h3 class="d-flex font-weight-bolder my-2 p-2 text-primary">Nivel Académico: <span
                                    class="text-dark ml-3">{{personSelected.academicLevel}}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gutter-t gutter-b">
                <div class="col-md-12">
                    <div class="card card-custom border-primary">
                        <div class="card-header mt-3">
                            <div class="card-title align-items-center">
                                <i class="flaticon2-time icon-2x text-primary mr-3"></i>
                                <h2 class="text-primary m-0">Historial de Postulaciones</h2>
                            </div>
                            <div class="card-toolbar ml-auto"></div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table mt-2 table-vertical-center">
                                    <thead>
                                        <tr class="bg-primary">
                                            <th class="max-w-50px">Secuencia</th>
                                            <th class="max-w-200px">Fecha</th>
                                            <th>Vacante</th>
                                            <th>Resultado del Proceso</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of testHistoryData; let index = index">
                                            <td>{{index + 1}}</td>
                                            <td>{{item.startDate | date:'dd MMMM yyyy':'UTC':'es-US'}}</td>
                                            <td>{{item.positionName}}</td>
                                            <td>
                                                <span *ngIf="item.condition != 0">
                                                    <span *ngIf="item.condition == 1"
                                                        class="font-weight-bold text-warning"><span
                                                            class="label label-warning label-dot mr-2"></span>Enviada al
                                                        MAP</span>
                                                    <span *ngIf="item.condition == 2"
                                                        class="font-weight-bold text-success"><span
                                                            class="label label-success label-dot mr-2"></span>Aprobada
                                                        por el MAP</span>
                                                    <span *ngIf="item.condition == 3"
                                                        class="font-weight-bold text-success"><span
                                                            class="label label-success label-dot mr-2"></span>Nombrado</span>
                                                    <span *ngIf="item.condition == 4"
                                                        class="font-weight-bold text-warning"><span
                                                            class="label label-warning label-dot mr-2"></span>Reservado
                                                        para Nombramiento</span>
                                                    <span *ngIf="item.condition == 5"
                                                        class="font-weight-bold text-danger"><span
                                                            class="label label-danger label-dot mr-2"></span>Rechazada
                                                        por el MAP</span>
                                                    <span *ngIf="item.condition == 6"
                                                        class="font-weight-bold text-warning"><span
                                                            class="label label-warning label-dot mr-2"></span>Devuelta
                                                        para Revisión</span>
                                                </span>
                                                <span *ngIf="item.condition == 0">
                                                    <span class="label label-info label-dot mr-2"></span>
                                                    <span class="font-weight-bold text-info">En Proceso</span>
                                                </span>
                                            </td>
                                            <td>
                                                <button (click)="openAppliedVacancyModal()" tooltip="Ver"
                                                    placement="top" class="btn btn-sm btn-light-skype btn-icon mr-2">
                                                    <i class="flaticon-ver icon-2x"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="d-flex justify-content-end align-items-center flex-wrap">
                                    <div class="d-flex align-items-center py-3 mr-2">
                                        <select
                                            class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light ng-untouched ng-pristine ng-valid"
                                            ng-reflect-model="10" style="width: 75px;">
                                            <option ng-reflect-value="1" value="1">1</option>
                                            <option ng-reflect-value="5" value="5">5</option>
                                            <option ng-reflect-value="10" value="10">10</option>
                                            <option ng-reflect-value="15" value="15">15</option>
                                            <option ng-reflect-value="20" value="20">20</option>
                                        </select>
                                        <span class="text-muted">Mostrando página 1 de 1</span>
                                    </div>
                                    <div class="d-flex flex-wrap py-2 mr-3">
                                        <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1"
                                            disabled="disabled">
                                            <i class="ki ki-bold-arrow-back icon-xs"></i>
                                        </button>
                                        <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1"
                                            disabled="disabled">
                                            <i class="ki ki-bold-arrow-next icon-xs"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gutter-t gutter-b">
                <div class="col-md-12">
                    <div class="card card-custom border-primary">
                        <div class="card-header mt-3">
                            <div class="card-title align-items-center">
                                <i class="flaticon2-time icon-2x text-primary mr-3"></i>
                                <h2 class="text-primary m-0">Historial de Entrevista</h2>
                            </div>
                            <div class="card-toolbar ml-auto"></div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table mt-2 table-vertical-center">
                                    <thead>
                                        <tr class="bg-primary">
                                            <th class="max-w-50px">Secuencia</th>
                                            <th class="max-w-200px">Fecha</th>
                                            <th>Vacante</th>
                                            <th>Resultado del Proceso</th>
                                            <th>Entrevistador</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of interviewHistory; let index = index">
                                            <td>{{index + 1}}</td>
                                            <td>{{item.dateInterview | date: "dd MMMM yyyy":'UTC':'es-US'}}</td>
                                            <td>{{item.position}}</td>
                                            <td>{{item.result}}</td>
                                            <td>{{item.employeeFullName || 'N/A'}}</td>
                                            <td>
                                                <button (click)="printReport(item)"
                                                    class="btn btn-sm btn-light-skype mr-3 btn-icon">
                                                    <i class="flaticon-ver icon-2x" data-toggle="tooltip"
                                                        title="Reporte de Entrevista"></i>
                                                </button>
                                                <button tooltip="Eliminar" placement="top"
                                                    *ngIf="personSelected.condition == 0"
                                                    class="btn btn-sm btn-light-danger btn-icon"
                                                    (click)="deleteInterview(item)">
                                                    <i class="flaticon-delete"></i>
                                                </button>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gutter-t" *ngIf="personSelected.condition == 0">
                <div class="col-xl-6 col-lg-12 gutter-b">
                    <div class="card card-custom border-primary border-2 p-0 h-100 gutter-b">
                        <h3 class="text-white m-0 bg-primary p-3 d-block">
                            Habilidades Identificadas
                        </h3>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="vocationalTraining" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Aspecto
                                            Profesional
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="learning" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Auto
                                            Aprendizaje
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="analysisCapacity" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Capacidad
                                            Analítica
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="languageAbility" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Capacidad
                                            Idiomática
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="eyeContact" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Contacto
                                            Visual
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="creativityAndInnovation" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Creatividad
                                            e Innovación
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="communication" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Comunicación
                                            No Verbal
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="laborStability" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Estabilidad
                                            Laboral
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="supervisionExperience" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Experiencia
                                            de Supervisión
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="verbalFluency" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Fluidez
                                            Verbal
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="professionalDevelopment" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Formación
                                            Profesional
                                        </strong>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="initiative" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Iniciativa
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="leadership" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Liderazgo
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="multitask" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Multitarea
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="achievementOrientation" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Orientado
                                            a Logros
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="criticalThinking" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Pensamiento
                                            Crítico
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="structuredThinking" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Pensamiento
                                            Estructurado
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="systematicThinking" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Pensamiento
                                            Sistemático
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="puntuality" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Puntualidad
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="resilience" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Resiliencia
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="selfConfidence" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Seguridad
                                            en Sí Mismo
                                        </strong>
                                    </div>
                                    <div class="d-flex align-items-center flex-grow-1 mb-3">
                                        <div class="flex-shrink-0 mr-2">
                                            <span class="switch switch-sm switch-primary">
                                                <label>
                                                    <input class="cursor-pointer abc-checkbox-primary"
                                                        formControlName="sociability" type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                        <strong
                                            class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                            Sociabilidad
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-12 gutter-b">
                    <div class="card card-custom border-primary border-2 p-0 h-100">
                        <h3 class="text-white m-0 bg-primary p-3 d-block">
                            Observaciones Generales
                        </h3>
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Fecha de Entrevista <span class="text-danger">*</span>
                                    </label>
                                    <input formControlName="dateInterview" type="date" name=""
                                        class="form-control form-control-solid form-control-lg">
                                    <span style="position: absolute;"
                                        *ngIf="this.form.get('dateInterview').invalid && invalidForm"
                                        class="text-danger">{{ showdateInterviewErrors() }}</span>

                                </div>
                                <div class="form-group col-md-6">
                                    <label for="for">Razones del cambio <span class="text-danger">*</span></label>
                                    <textarea class="form-control form-control-solid form-control-lg flex-1"
                                      (input)="removeWhitespaces($event, departmentForm.get('reasonsChange'))"
                                        formControlName="reasonsChange"></textarea>
                                    <span style="position: absolute;"
                                        *ngIf="this.form.get('reasonsChange').invalid && invalidForm"
                                        class="text-danger">{{ showReasonChangeErrors() }}</span>
                                </div>

                                <div class="form-group col-lg-6">
                                    <label class="class">
                                        Cargo Propuesto
                                        <span class="text-danger">*</span>
                                    </label>
                                    <select class="form-select form-control-solid form-control-lg"
                                        formControlName="proposedPosition">

                                        <option [value]="item.positionId" *ngFor="let item of employeePositions">
                                            {{item.positionName}}
                                        </option>
                                    </select>
                                    <span style="position: absolute;"
                                        *ngIf="this.form.get('proposedPosition').invalid && invalidForm"
                                        class="text-danger">{{ showProposedPositionErrors() }}</span>
                                </div>
                                <div class="form-group col-lg-6">
                                    <label class="class">
                                        Salario Deseado
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input readonly currencyMask formControlName="desiredPayment"
                                        [options]="{precision:2}" class="form-control" type="text" />
                                    <span style="position: absolute;"></span>
                                </div>

                            </div>
                            <div class="row">
                                <div class="form-group col-lg-6">
                                    <label class="class">
                                        Horario Propuesto
                                        <span class="text-danger">*</span>
                                    </label>
                                    <select class="form-select form-control-solid form-control-lg"
                                        formControlName="proposedSchedule">

                                        <option [value]="item.ocode" *ngFor="let item of hoursAvailable">
                                            {{item.stringData}}
                                        </option>
                                    </select>
                                    <span style="position: absolute;"
                                        *ngIf="this.form.get('proposedSchedule').invalid && invalidForm"
                                        class="text-danger">{{ showProposedScheduleErrors() }}</span>
                                </div>
                                <div class="form-group col-lg-6">
                                    <label class="class">
                                        Salario Propuesto
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="input-group">
                                        <input currencyMask (blur)="onSalaryError()" [options]="{precision:2}"
                                            class="form-control" placeholder="0.00" formControlName="proposedSalary"
                                            type="text" />
                                        <button id="searchBarBtn" class="input-group-text bg-primary"
                                            (click)="openSalaryRange()">
                                            <i class="flaticon-ver icon text-white" data-toggle="tooltip"
                                                title="Sugerencia salarial"></i>
                                        </button>
                                    </div>

                                    <span *ngIf="this.form.get('proposedSalary').invalid && invalidForm"
                                        class="text-danger">{{ showProposedSalaryErrors() }}</span>
                                    <span *ngIf="isSalaryInvalid" class="font-weight-bold text-danger ml-3">El salario
                                        deseado debe estar dentro del rango salarial establecido</span>
                                </div>
                                <div class="form-group col-lg-6">
                                    <label class="class">
                                        Fecha Disponibilidad
                                        <span *ngIf="!this.form.get('dateAvailability').disabled"
                                            class="text-danger">*</span>
                                    </label>
                                    <input class="form-control form-control-solid form-control-lg"
                                        formControlName="dateAvailability" type="date" />
                                    <span style="position: absolute;"
                                        *ngIf="this.form.get('dateAvailability').invalid && invalidForm"
                                        class="text-danger">{{ showDateAvailabilityErrors() }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="class">
                                        Resultado del Proceso de Entrevista
                                        <span class="text-danger">*</span>
                                    </label>
                                    <select (change)="onInterviewResultsChange($event.target.value)"
                                        class="form-select form-control-solid form-control-lg"
                                        formControlName="idResults">
                                        <option value="" hidden selected>Seleccionar</option>
                                        <option *ngFor="let item of interviewResults" [value]="item.ocode">
                                            {{item.stringData}}
                                        </option>
                                    </select>
                                    <span style="position: absolute;"
                                        *ngIf="this.form.get('idResults').invalid && invalidForm" class="text-danger">{{
                                        showInterviewResultErrors() }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="for">Entrevistado(a) Por <span class="text-danger">*</span></label>
                                    <ngx-select-dropdown [config]="interviewerConfig" [options]="interviewersList"
                                        (change)="handleInterviewerChange($event.value)" [multiple]="false"
                                        formControlName="interviewerSelected">
                                    </ngx-select-dropdown>
                                    <span style="position: absolute;"
                                        *ngIf="this.form.get('interviewerEmployeeId').invalid && invalidForm"
                                        class="text-danger">{{ showInterviewerErrors() }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="for">Observaciones</label>
                                    <textarea class="form-control form-control-solid form-control-lg flex-1"
                                        formControlName="generalRemarks"></textarea>
                                    <span style="position: absolute;"
                                        *ngIf="this.form.get('generalRemarks').invalid && invalidForm"
                                        class="text-danger">{{ showGeneralRemarksErrors() }}</span>
                                </div>

                            </div>

                            <div class="col-xl-2 col-lg-6 mt-5">
                                <div class="d-flex align-items-center flex-grow-1 mb-3">
                                    <div class="flex-shrink-0 mr-2">
                                        <span class="switch switch-sm switch-primary">
                                            <label>
                                                <input class="cursor-pointer abc-checkbox-primary"
                                                    formControlName="employee" type="checkbox" />
                                                <span></span>
                                            </label>
                                        </span>
                                    </div>
                                    <strong
                                        class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">
                                        Con empleo
                                    </strong>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-end" *ngIf="personSelected.condition == 0">
            <button (click)="saveInterview()" [disabled]="inDetailsMode"
                class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
                <i class="flaticon-disco-flexible"></i>
                Guardar
            </button>
            <!-- <button (click)="clearAll()" class="btn btn-pill font-weight-bolder btn-shadow btn-info mr-3">
                Nuevo
            </button> -->
        </div>
    </form>
</div>

<!-- <el-modal title="Rango salarial" size="sm" target="salaryRange">

    <div>
        <h3 data-dismiss="modal" (click)="selectSalaryRange(personSelected.minSalary)"> <span role="button"
                class="badge  bg-light text-dark">Salario mínimo: {{personSelected.minSalary |currency }}</span></h3>
        <h3 data-dismiss="modal" (click)="selectSalaryRange(personSelected.halfSalary)"> <span role="button"
                class="badge  bg-light text-dark">Salario medio: {{personSelected.halfSalary |currency }}</span></h3>
        <h3 data-dismiss="modal" (click)="selectSalaryRange(personSelected.maxSalary)"> <span role="button"
                class="badge  bg-light text-dark">Salario máximo: {{personSelected.maxSalary |currency }}</span></h3>
    </div>

    <div class="my-7 separator separator-solid"></div>
    <div class="row">
        <div class="d-flex justify-content-end">
            <button id="btnClose" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2"
                data-dismiss="modal" type="button">
                <i class="flaticon2-cross"></i>
                <span>Cerrar</span>
            </button>
        </div>
    </div>
</el-modal> -->
