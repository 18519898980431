<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon">
        <i class="flaticon-lista-de-verificacion icon-3x text-danger"></i>
            </span>
            <h2 class="text-primary">Acciones de Personal</h2>
            <h6 class="center font-size-h6 font-weight-bold label label-inline label-light-danger ml-5 px-5">En Desarrollo</h6>
        </div>
        <div class="card-toolbar">
            <button routerLink="/employees" class="btn btn-pill font-weight-bolder btn-shadow btn-warning">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
            </button>
        </div>
    </div>
    <div class="card-body">
        <form [formGroup]="employeeDataForm">
            <div class="row">
                <div class="col-lg-3">
                    <div class="form-group">
                        <label>Código <span class="text-danger">*</span></label>
                        <input formControlName="employeeNumber" readonly type="text" class="form-control form-control-solid form-control-lg"/>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label>Servidor Público <span class="text-danger">*</span></label>
                        <input formControlName="employeeName" readonly type="text" class="form-control form-control-solid form-control-lg"/>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-group">
                        <label>Tipo de Servidor Público <span class="text-danger">*</span></label>
                        <input formControlName="employeeType" readonly type="text" class="form-control form-control-solid form-control-lg"/>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="form-group">
                        <label>Unidad Organizativa <span class="text-danger">*</span></label>
                        <input formControlName="department" type="text" class="form-control form-control-solid form-control-lg" readonly />
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="form-group">
                        <label>Posición <span class="text-danger">*</span></label>
                        <input formControlName="position" type="text" class="form-control form-control-solid form-control-lg" readonly />
                    </div>
                </div>
            </div>
        </form>
        <ul class="nav nav-tabs border-tabs-primary" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="vacaciones-tab" data-toggle="tab" href="#vacaciones" >
                    <span class="nav-icon mr-5">
                        <i class="flaticon-despachador-de-vuelo icon-3x"></i>
                    </span>
                    <span class="nav-text">
                        <h3>Vacaciones</h3>
                    </span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="permisos-tab" data-toggle="tab" href="#permisos" aria-controls="permisos" >
                    <span class="nav-icon mr-5">
                        <i class="flaticon-lista-de-verificacion icon-3x"></i>
                    </span>
                    <span class="nav-text">
                        <h3>Permisos (Normales, Especiales, etc.)</h3>
                    </span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="licencias-tab" data-toggle="tab" href="#licencias" aria-controls="licencias">
                    <span class="nav-icon mr-5">
                        <i class="flaticon-proceso-3 icon-3x"></i>
                    </span>
                    <span class="nav-text">
                        <h3>Licencias</h3>
                    </span>
                </a>
            </li>
        </ul>
        <div class="tab-content mt-5">
            <div class="tab-pane fade active show" id="vacaciones" role="tabpanel" aria-labelledby="vacaciones-tab">
                <form [formGroup]="vacationForm">
                    <div class="row gutter-t">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Fecha de Solicitud <span class="text-danger">*</span></label>
                                <input formControlName="applicationDate" type="date" class="form-control form-control-solid form-control-lg" />
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="startDate">
                                Fecha de inicio <span class="text-danger">*</span>
                            </label>
                            <input formControlName="dateStart" type="date" class="form-control form-control-solid form-control-lg" id="startDate"  />
                            <span style="position: absolute;" *ngIf="this.vacationForm.get('dateStart').invalid && vacationFormInvalid" class="text-danger">{{ showDateStartErrors() }}</span>
                        </div>
                        <div class="form-group col-md-4">
                            <div>
                                <label for="endDate">
                                    Fecha de término <span class="text-danger">*</span>
                                </label>
                            </div>
                            <input formControlName="dateEnd" type="date" class="form-control form-control-solid form-control-lg" id="endDate" />
                            <span *ngIf="this.vacationForm.get('dateEnd').invalid && vacationFormInvalid" class="text-danger">{{ showDateEndErrors() }}</span>
                        </div>
                        <div class="form-group col-md-4">
                            <label class="d-block w-100">
                                Año al que pertenecen <span class="text-danger">*</span>
                            </label>
                            <input class="form-control form-control-solid form-control-lg" type="number" formControlName="year" name="" id="" />
                            <span style="position: absolute;" *ngIf="this.vacationForm.get('year').invalid && vacationFormInvalid" class="text-danger">{{ showYearErrors() }}</span>
                        </div>
                        <div class="form-group col-md-4">
                            <div>
                                <label for="totalDays"> Días </label>
                            </div>
                            <input formControlName="days" class="form-control form-control-solid form-control-lg" id="totalDays" placeholder="" type="number" />
                            <span style="position: absolute;" *ngIf="this.vacationForm.get('days').invalid && vacationFormInvalid" class="text-danger">{{ showDaysErrors() }}</span>
                        </div>
                        <div class="form-group col-md-4">
                            <div>
                                <label for="" class="">
                                    ¿Desea pago de bono vacacional?
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label mt-2 check-dtl">
                                    <input formControlName="bond" name="bond" value="true" type="radio" class="form-check-input mt-2" id="payDays"/>
                                        Sí
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label mt-2 check-dtl">
                                    <input formControlName="bond" name="bond" value="false" type="radio" class="form-check-input mt-2" id="payDays"/>
                                        No
                                </label>
                            </div>
                            <span for="" class="d-block text-muted" >Si es afirmativo, Debe tomar al menos 5 días laborables.
                                Art.177
                            </span>
                        </div>
                    </div>
                    <div class="text-right">
                        <button *ngIf="!isInDetailsVacancy" (click)="submitVacationForm();" class="mr-2 btn btn-success btn-pill font-weight-bolder mb-3">
                            <i class="flaticon2-plus"></i> Agregar
                        </button>
                        <button (click)="clearVacancyForm();" class="btn btn-success btn-pill font-weight-bolder mb-3">
                             Limpiar campos
                        </button>
                    </div>
                </form>
                <div class="row gutter-b">
                    <h2 class="text-primary gutter-b">Historial Laboral</h2>
                    <div class="table-responsive">
                        <table class="table atbl table-vertical-center">
                            <thead>
                                <tr class="bg-primary">
                                    <th class="text-left">Institución Gubernamental</th>
                                    <th class="text-center">Cantidad de años</th>
                                    <th class="text-center min-w-175px">Cantidad de Días</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of workHistoryData">
                                <td class="text-left">{{ item.company }}</td>
                                <td class="text-center">{{ item.yearsAmounth }}</td>
                                <td class="text-center">{{ item.daysAmounth }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row gutter-b gutter-t">
                    <h2 class="text-primary gutter-b d-flex justify-content-between flex-wrap">
                        Fecha de Vacaciones
                    </h2>
                    <div class="table-responsive">
                        <table class="table atbl table-vertical-center">
                            <thead>
                                <tr class="bg-primary">
                                    <th class="text-center">Fecha Inicio</th>
                                    <th class="text-center">Fecha Término</th>
                                    <th class="text-center">Año al que Pertenecen</th>
                                    <th class="text-center">Total de Días</th>
                                    <th class="actionsBtn">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of vacationData">
                                    <td class="text-left">
                                        {{ item.dateStart | date: "dd MMMM yyyy" }}
                                    </td>
                                    <td class="text-center">
                                        {{ item.dateEnd | date: "dd MMMM yyyy" }}
                                    </td>
                                    <td class="text-center">{{ item.year }}</td>
                                    <td class="text-center">{{ item.days }}</td>
                                    <td class="actionsBtn">
                                        <!-- <a (click)="showVacancy(item)" data-toggle="modal" data-target="#fVacations" class="btn btn-icon btn-light-skype btn-sm m-1">
                                            <i class="flaticon-ver" data-toggle="tooltip" title="Ver" ></i>
                                        </a> -->
                                        <a class="btn btn-icon btn-light-info btn-sm m-1" routerLink="/pdf-action-change-employee-vacaciones/:id">
                                            <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                                        </a>

                                        <a data-toggle="modal" data-target="#fVacations" (click)="editVacancy(item)" class="btn btn-icon btn-light-success btn-sm m-1">
                                            <i class="flaticon-edit" data-toggle="tooltip" title="Editar" ></i>
                                        </a>
                                        <a (click)="removeVacation(item)" class="btn btn-icon btn-light-danger btn-sm m-1">
                                            <i class="flaticon-delete" data-toggle="tooltip" title="Remover" ></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="gutter-b d-flex bg-dark-o-50 justify-content-center">
                    <span class="font-size-h4-md font-size-h6-sm">
                        <b>Para un total de:</b> [ nn ]
                    </span>
                    <span class="font-size-h4-md font-size-h6-sm ml-2">
                        <b>Restando un total de:</b> [ nn ]
                    </span>
                    <span class="font-size-h4-md font-size-h6-sm ml-2">
                        <b>Correspondientes al año:</b> [ nnnn ]
                    </span>
                </div>
            </div>
            <div class="tab-pane fade" id="permisos" role="tabpanel" aria-labelledby="permisos-tab" >
                <form [formGroup]="permissionForm">
                    <div class="row gutter-t">
                        <div class="col-md-2">
                            <div class="form-group">
                                <label class="d-block">
                                    Tipo de Permiso<span class="text-danger"> *</span>
                                </label>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label mt-2 check-dtl plz">
                                        <input formControlName="idTypePermissions" value="1" class="form-check-input" required="" type="radio" />
                                        Normales
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label mt-2 check-dtl plz">
                                        <input formControlName="idTypePermissions" value="2" class="form-check-input" required="" type="radio" />
                                        Especiales
                                    </label>
                                </div>
                            </div>
                            <span *ngIf="this.permissionForm.get('idTypePermissions').invalid && permissionFormInvalid" class="text-danger">{{ showidTypePermissionsErrors() }}</span>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Fecha de Solicitud <span class="text-danger">*</span></label>
                                <input formControlName="applicationDate" type="date" class="form-control form-control-lg" />
                                <span style="position: absolute;" *ngIf="this.permissionForm.get('applicationDate').invalid && permissionFormInvalid" class="text-danger">{{ showApplicationDateErrors() }}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Fecha de Inicio <span class="text-danger">*</span></label>
                                <input formControlName="dateStart" type="date" class="form-control form-control-lg" />
                                <span style="position: absolute;" *ngIf="this.permissionForm.get('dateStart').invalid && permissionFormInvalid" class="text-danger">{{ showDateStartErrorsPermission() }}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Fecha de Término <span class="text-danger">*</span></label>
                                <input formControlName="dateEnd" type="date" class="form-control form-control-lg" />
                                <span style="position: absolute;" *ngIf="this.permissionForm.get('dateEnd').invalid && permissionFormInvalid" class="text-danger">{{ showDateEndErrorsPermission() }}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Hora de Inicio <span class="text-danger">*</span></label>
                                    <input formControlName="hourStart" type="time" class="form-control form-control-lg"/>
                                    <span style="position: absolute;" *ngIf="this.permissionForm.get('hourStart').invalid && permissionFormInvalid" class="text-danger">{{ showHourStartErrors() }}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Hora de Fin <span class="text-danger">*</span></label>
                                <input formControlName="hourEnd" type="time" class="form-control form-control-lg" />
                                <span style="position: absolute;" *ngIf="this.permissionForm.get('hourEnd').invalid && permissionFormInvalid" class="text-danger">{{ showHourEndErrors() }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row gutter-t flex-wrap justify-content-between">
                        <div class="col">
                            <div class="form-group">
                                <label>Motivo del Permiso <span class="text-danger">*</span></label>
                                <textarea
                                formControlName="comment"
                                class="form-control"
                                ></textarea>
                                <span style="position: absolute;" *ngIf="this.permissionForm.get('reason').invalid && permissionFormInvalid" class="text-danger">{{ showReasonErrors() }}</span>
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>Comentario <span class="text-danger">*</span></label>
                                <textarea
                                formControlName="comment"
                                class="form-control"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row gutter-t">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label class="d-block">Recurrencia (Solo Permisos Especiales)
                                    <span class="text-danger"> *</span>
                                </label>
                                <div class="form-group">
                                    <div>
                                        <label for="" class="d-block">
                                            Recurrencia
                                            <span class="font-italic"></span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="checkbox" formControlName="monday" class="form-check-input dynamicFormControl mr-2 ng-valid ng-dirty ng-touched" value="1"/>
                                        <label class="form-check-label"> Lunes </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="checkbox" formControlName="tuesday" class=" form-check-input dynamicFormControl mr-2 ng-valid ng-dirty ng-touched " value="2" />
                                        <label class="form-check-label"> Martes </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                        type="checkbox"
                                        formControlName="wednesday"
                                        class="
                                            form-check-input
                                            dynamicFormControl
                                            mr-2
                                            ng-valid ng-dirty ng-touched
                                        "
                                        value="3"
                                        />
                                        <label class="form-check-label"> Miércoles </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                        type="checkbox"
                                        formControlName="thursday"
                                        class="
                                            form-check-input
                                            dynamicFormControl
                                            mr-2
                                            ng-valid ng-dirty ng-touched
                                        "
                                        value="4"
                                        />
                                        <label class="form-check-label"> Jueves </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                        type="checkbox"
                                        formControlName="friday"
                                        class="
                                            form-check-input
                                            dynamicFormControl
                                            mr-2
                                            ng-valid ng-dirty ng-touched
                                        "
                                        value="5"
                                        />
                                        <label class="form-check-label"> Viernes </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                        type="checkbox"
                                        formControlName="saturday"
                                        class="
                                            form-check-input
                                            dynamicFormControl
                                            mr-2
                                            ng-valid ng-dirty ng-touched
                                        "
                                        value="6"
                                        />
                                        <label class="form-check-label"> sábado </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="form-group d-flex justify-content-end align-items-center">
                        <button
                        (click)="savePermission()"
                        class="
                            btn btn-pill
                            font-weight-bolder
                            btn-shadow btn-success
                            mr-3
                        "
                        >
                            <i class="flaticon-disco-flexible"></i>
                            <span>Guardar</span>
                        </button>


                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="licencias" role="tabpanel" aria-labelledby="licencias-tab">
                <form [formGroup]="licenseForm">
                    <div class="row gutter-t flex-wrap justify-content-between">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Fecha de Solicitud <span class="text-danger">*</span>
                                </label>
                                <input
                                formControlName="applicationDate"
                                type="date"
                                class="form-control form-control-lg"
                                />
                                <span style="position: absolute;" *ngIf="this.licenseForm.get('applicationDate').invalid && licenseFormInvalid"
                                class="text-danger">{{ showApplicationDateLicenseErrors() }}</span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label
                                >Fecha de Inicio <span class="text-danger">*</span>
                                </label>
                                <input
                                formControlName="dateStart"
                                type="date"
                                class="form-control form-control-lg"
                                />
                                <span style="position: absolute;" *ngIf="this.licenseForm.get('dateStart').invalid && licenseFormInvalid"
                                class="text-danger">{{ showDateStartLicenseErrors() }}</span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label
                                >Fecha de Término <span class="text-danger">*</span>
                                </label>
                                <input
                                formControlName="dateEnd"
                                type="date"
                                class="form-control form-control-lg"
                                />
                                <span style="position: absolute;" *ngIf="this.licenseForm.get('dateEnd').invalid && licenseFormInvalid"
                                class="text-danger">{{ showDateEndLicenseErrors() }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row gutter-t">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label
                                >Motivo de Licencia <span class="text-danger">*</span>
                                </label>
                                <textarea
                                formControlName="reason"
                                class="form-control"
                                ></textarea>
                                <span style="position: absolute;" *ngIf="this.licenseForm.get('reason').invalid && licenseFormInvalid"
                                class="text-danger">{{ showReasonLicenseErrors() }}</span>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Comentario <span class="text-danger">*</span> </label>
                                <textarea
                                formControlName="comment"
                                class="form-control"
                                ></textarea>
                                <span style="position: absolute;" *ngIf="this.licenseForm.get('comment').invalid && licenseFormInvalid"
                                class="text-danger">{{ showCommentLicenseErrors() }}</span>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="form-group d-flex justify-content-end align-items-center">
                        <button
                        (click)="saveEmployeeLicense()"
                        class="
                            btn btn-pill
                            font-weight-bolder
                            btn-shadow btn-success
                            mr-3
                        "
                        >
                            <i class="flaticon-disco-flexible"></i> <span> Guardar</span>
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
