import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RejectPayrollModalComponent } from '@payroll/audit-approval/reject-payroll-modal/reject-payroll-modal.component';
import { BulkLoadFinalNomComponent } from '@payroll/final-payroll/bulk-load-final-nom/bulk-load-final-nom.component';
import { FinalPayrollAddnewsModalComponent } from '@payroll/final-payroll/final-payroll-addnews-modal/final-payroll-addnews-modal.component';
import { FinalPayrollSearchModalComponent } from '@payroll/final-payroll/final-payroll-search-modal/final-payroll-search-modal.component';
import { IEmployeeGET } from '@payroll/final-payroll/models/models';
import { NewsDetailsComponent } from '@payroll/final-payroll/news-details/news-details.component';
import { NewsToApproveModalComponent, payrollNewsTypesToModal } from '@payroll/final-payroll/news-to-approve-modal/news-to-approve-modal.component';
import { headerCondition } from '@payroll/payroll-models';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { FinalPayrollService } from 'src/app/services/final-payroll.service';
import { IncentivePayrollService } from 'src/app/services/incentive-payroll.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { ExportToExcelService } from 'src/app/shared/export-to-excel/export-to-excel.service';

import Swal from 'sweetalert2';
import { ActionEditComponent} from '..';
import { ParameterControlService } from '../../../services/parameter-control.service';
import { ToastService } from '../../../shared/toast/toast.service';
import { IEmployee } from '../../job-offer/models/Employee';
import { AddModalIncentivePayrollComponent } from './add-modal-incentive-payroll/add-modal-incentive-payroll.component';
import { IncentiveAddNewsComponent } from './incentive-add-news/incentive-add-news.component';
import { IncentivePayrollBulkLoadComponent } from './incentive-payroll-bulk-load/incentive-payroll-bulk-load.component';
import { ExcludeEmployeePayrollModalComponent } from '@payroll/modals/exclude-employee-payroll-modal/exclude-employee-payroll-modal.component';
import { PayrollConditions } from 'src/app/shared/enums/PayrollConditions';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';


@Component({
  selector: 'app-incentive-payroll',
  templateUrl: './incentive-payroll.component.html',
  styleUrls: ['./incentive-payroll.component.css']
})


export class IncentivePayrollComponent implements OnInit, AfterViewChecked {

  headerCondition = ''
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'INCENTIVE-PAYROLL',
    addAction: {key: 'ADDREQUISITION', value: false },
    exportAction: {key: 'EXPORT', value: false },
    approveAction: {key: 'APPROVAL', value: false },
    massiveLoadAction: {key: 'MASIVE-LOAD', value: false },
    newsAction: {key: 'NEWS', value: false },
    viewAction: {key: 'VIEW', value: false },
    Exclude: {key: 'Exclude', value: false },
  }

  filterText: string = '';
  periodDropdownIsDisable = false;
  areaDropdownIsDisable = true
  disableDropdowns = false;
  budgetAreaId = 0;
  payrollPeriodId = 0;
  selectedItems: any;
  employeeList: any[] = []
  periods: any[] = []
  budgetAreas: any[] = []
  dropdownPeriod: any;
  dropdownBudgetAreas: any;
  paginatedRecords: any[] = []
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string, propKeyId?: any }[] = [
    { propkey: "employeeId", displayName: "Secuencia", propKeyId: 1 },
    { propkey: "fullName", displayName: "Servidor Público", propKeyId: 2 },
    { propkey: "departmentName", displayName: "Unidad organizativa", propKeyId: 3 },
    { propkey: "sex", displayName: "Sexo", propKeyId: 4},
  ];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' },
    { item_id: 3, item_text: 'Aprobadas' },
    { item_id: 4, item_text: 'No Aprobadas' }
  ];

  propKeyId: any = 0
  text: any = ''
  page: any = 1
  pageSize: any = 10
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  showExcludeBtn = false
  @ViewChild('paginator') paginator: CaptionComponent;
  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  payrollHeader: { headerId: number, conditionId: number } = {
    headerId: 0,
    conditionId: 0,
  };

  constructor(private employeeService: EmployeesService,
    private _toastService: ToastService,
    private _exlService: ExportToExcelService,
    private _changeDet: ChangeDetectorRef,
    private _parameterService: ParameterControlService,
    private _fb: FormBuilder,
    private toastr: ToastrService, private srvDoc: docService,
    private dialog: MatDialog,
    private payrollNewService: PayrollNewService,
    private srvFinal: FinalPayrollService,
    private srvIncentive: IncentivePayrollService,
    public authInfo: AuthInfoService
  ) {
  }

  ngOnInit(): void {
    

    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result=>{
      if(result == true){
        this.getPeriods()
        this.getBudgetAreas()
      }
    })
    this.privilege = this.authInfo.setPrivileges(this.privilege)

  }

  get isOpenToNews(){
    return this.payrollHeader?.conditionId ===  headerCondition?.openToNews;
  }

  get isContitionalRejectPayroll(){
    return (this.payrollHeader?.conditionId === headerCondition?.openToNews
      || this.payrollHeader?.conditionId === headerCondition?.audited 
      || this.payrollHeader?.conditionId === headerCondition?.simulated 
      || this.payrollHeader?.conditionId === headerCondition?.approved);
  }

  openRejectModal() {
    this.dialog.open(RejectPayrollModalComponent, {
      width: '50%',
      data: { headerId: this.payrollHeader?.headerId}
    }).afterClosed().subscribe((result: any) => {
      if (result?.saved) {
        this._toastService.success("Nómina Rechazada");
        this.clear();
      }
    })
  }
  
  openModalBulkloadIncentiveNom() {
    if(!this.periodAndBudgetAreasHasValue()){
      this._toastService.warning("Debe llenar seleccionar un período y el área");
      return;
    }
    this.dialog.open(IncentivePayrollBulkLoadComponent, {
      data: {
        budgetArea: this.dropdownBudgetAreas, 
        payrollPeriod: this.dropdownPeriod
      },
      width: '70%'
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.Applied) {
          this.getOverallSummary();
        }
      });
  }
  
  getPeriods(){
    this.srvIncentive.getPeriods().subscribe((res:any) => {
      if(res.succeded){
        this.periods = res.dataList.filter(x => x.conditionId == 1 && x.status == true)
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }
  
  getBudgetAreas(){
    this.srvIncentive.getBudgetAreas().subscribe((res:any) => {
      if(res.succeded){
        this.budgetAreas = res.dataList.filter(d => d.status);
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }
  changeDropdownPeriod(){
    this.areaDropdownIsDisable = this.dropdownPeriod?.payrollPeriodId < 1
    
    
    
  }

  removeEmployee(item){
    this.dialog.open(ExcludeEmployeePayrollModalComponent, {
      data: {item},
      width: 'auto'
    }).afterClosed().subscribe({
      next: (res:any) => {
        if(res.success){
          this.getOverallSummary()
        }
      }

    })
  }
  areaPeriodValidate(){
    if(!this.dropdownPeriod?.payrollPeriodId && !this.dropdownBudgetAreas?.budgetAreaId){
      this.toastr.warning("Debe seleccionar un período y el área presupuestaria")
      return false
    }
    if(!this.dropdownPeriod?.payrollPeriodId){
      this.toastr.warning("Debe seleccionar un período")
      return false
    }
    if(!this.dropdownBudgetAreas?.budgetAreaId){
      this.toastr.warning("Debe seleccionar el área presupuestaria")
      return false
    }
    return true
  }
  exportToPdf() {
    const companyId: number = this.authInfo.getCompanyId();
    const period = this.dropdownPeriod?.payrollPeriodId ? this.dropdownPeriod?.payrollPeriodId : '0'
    const budgetAreaId = this.dropdownBudgetAreas?.budgetAreaId ? this.dropdownBudgetAreas?.budgetAreaId : '0'
    if(this.areaPeriodValidate() == false){
      return
    }
    //const reportCode: ReportCode = ReportCode.NormalPayroll;
    //const periodId =  this.payrollForm.get('period').value?.payrollPeriodId ?? 0
    //const budgetAreaId = this.payrollForm.get('area').value?.budgetAreaId ?? 0
    const reportUrl = environment.reportUrl + `?Reportcode=ListPayrollInctiveWithout&headerId=${this.payrollHeader?.headerId}&BudgetAreaId=${budgetAreaId}&PeriodId=${period}&CompanyId=${companyId}&PropKeyId=${this.propKeyId}&SearchParameter=${this.text}&Page=${this.page}&PageSize=${this.pageSize}`
    let parameters = {
      url: reportUrl,
      title: 'Reporte Unidades Organizativas',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }
  clear(){
    this.employeeList = []
    this.dropdownPeriod = null
    this.dropdownBudgetAreas = null
    this.areaDropdownIsDisable = true
    this.periodDropdownIsDisable = false
    this.payrollHeader = {
      headerId: 0,
      conditionId: 0,
    };
  }
  
  newsToApprove(employeeId){
    const periodId = this.dropdownPeriod.payrollPeriodId
    const payrollNewsTypes: payrollNewsTypesToModal = {
      incentive: true
    }
    this.dialog.open(NewsToApproveModalComponent, {
      height: '800px',
      data: {
        employeeId,
        periodId,
        payrollNewsTypes,
        budgetArea: this.dropdownBudgetAreas,
        payrollType: 2,
        type: 11
      }
    })
  }
  changeDropdownArea(){
    this.getOverallSummary()
  }
  getOverallSummary(){
    this.headerCondition = ''
    if(this.dropdownPeriod?.payrollPeriodId > 0 && this.dropdownBudgetAreas.budgetAreaId > 0){
      this.srvIncentive.getOverallSumary(this.dropdownPeriod.payrollPeriodId, this.dropdownBudgetAreas.budgetAreaId).subscribe((res:any) => {
        if(res.succeded){
          if(res.dataList.length > 0){
            const headerId = res.dataList[0].headerId
            this.getHeader(headerId)
          }
          this.areaDropdownIsDisable = true
          this.periodDropdownIsDisable = true
          this.employeeList = res?.dataList?.map(d => {
            return {
              ...d,
              fullName: `${d.firstName} ${d.lastName}`
            }
          });
          if(res?.dataList?.length > 0){
            this.payrollHeader = {
              headerId: res?.dataList[0]?.headerId,
              conditionId: res?.dataList[0]?.conditionId
            }
          }
        }else{
          this._toastService.error(res.errors[0])
        }
      }, err => {
        this._toastService.error('Ha ocurrido un error inesperado')
      })
    }
  }
  
  periodAndBudgetAreasHasValue(): boolean{
    if(this.dropdownBudgetAreas.length == 0) return false
    if(this.dropdownPeriod.length == 0) return false
    return true
  }
  
  openSearchEmployee() {
    if(!this.periodAndBudgetAreasHasValue()){
      this._toastService.warning("Debe llenar seleccionar un período y el área")
      return
    }
    this.dialog.open(AddModalIncentivePayrollComponent, {
      data: {
        budgetAreaId: this.dropdownBudgetAreas.budgetAreaId,
        payrollPeriodId: this.dropdownPeriod.payrollPeriodId,
        endpoint: `/Employees?BudgetAreaId=${this.dropdownBudgetAreas.budgetAreaId}&Type=8&Status=true`
      },
      width: '80%'
    })
    .afterClosed()
    .subscribe((result) => {
      if (result?.success == false)  return
      const employee: IEmployeeGET = result.employee
      this.budgetAreaId = result.budgetAreaId
      this.payrollPeriodId = result.payrollPeriodId
      this.getOverallSummary()
      
    });
  }
  
  openAddNew(item: IEmployee, modalTitle, mode: 'show' | 'add'){
    const startDate = this.dropdownPeriod.startDate
    const endDate = this.dropdownPeriod.endDate
    const employeeId = item.employeeId
    this.dialog.open(IncentiveAddNewsComponent,{
      data: {
        startDate,
        endDate,
        modalTitle,
        mode,
        employeeId
      }
    })
  }
  
  getHeader(headerID){
    this.srvIncentive.getHeader(headerID).subscribe({
      next: (res:any) => {
        if(res.errors.length > 0) { this._toastService.error(res.errors[0]); return}
        if(res.dataList.length == 0){ return }
        let header = res.dataList[0].header
        this.headerCondition = header.headerCondition
        let excludeBtnCondition = [PayrollConditions.rejected, PayrollConditions.definitive]
        if(!excludeBtnCondition.some(x => x == header.conditionId)){
          this.showExcludeBtn = true
        }
      }, error: (error: any) => {
        this._toastService.error('Ha ocurrido un error inesperado')
      }
    })
  }
  viewNewsDetails(item){
    const periodId = item.payrollPeriodId
    this.dialog.open(NewsDetailsComponent, {
      data: {
        type: 8,
        item,
        periodId
      }
    })
  }
  sendData(){
    /* const finalPayroll: IFinalPayrollPOST = {
      budgetAreaId: this.budgetAreaId,
      companyId: 1,
      headerId: 0,
      employeeToPayroll: this.employeeList,
      status: true,
      createDate: new Date(),
      createUserId: 1,
      modifyDate: new Date(),
      modifyUserId: 1,
      payrollPeriodId: this.payrollPeriodId,
      payrollTypeId: 1
    }
    this.srvFinal.postFinalPayroll(finalPayroll).subscribe((res:any) => {
      if(res.succeded){
        this._toastService.success("Datos guardados")
      }else{
        this._toastService.error(res.errors[0])
      }
    }, err => {
      this._toastService.error("Ha ocurido un error inesperado")
    }) */
  }
  
  
  
  getFilteredList(event) {
    
    this.text = event?.text ? event?.text : ''
    this.propKeyId = event?.propkey?.propKeyId ? event?.propkey?.propKeyId : '0' 
    this.filteredList = event;
    this.paginator.selectedPage = 1;
  }
 
  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  getPaginatedRecords(event) {
    this.pageSize = event.registersPerPage
    this.page = event.selectedPage
    this.paginatedRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }
  exportToExcel() {
    this._exlService.exportToExcel([], 'Lista');
  }
  onKeyChange(text) {
    this.filterText = text;
  }

  onBtnPrintClick(){
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }
}

