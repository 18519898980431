import { DatePipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';

import { JobOfferService } from 'src/app/services/job-offer.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
import { BarOptions, ChartOptions } from 'src/app/shared/models/apexChartModels';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { DateFilterComponent } from './date-filter/date-filter.component';

declare const $: any;


@Component({
  selector: 'app-job-offer-dashboard',
  templateUrl: './job-offer-dashboard.component.html',
  styleUrls: ['./job-offer-dashboard.component.css']
})
export class JobOfferDashboardComponent implements OnInit, AfterViewInit {

  public chartOptions: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions>;
  public barOptions2: Partial<BarOptions>;
  datePipe = new DatePipe('en-Us')

  totalPostulations: [] = []
  dateFrom: string = '01-01-0001'
  dateTo: string = new Date().toLocaleDateString('fr-CA')
  refresIsDisabled: boolean = true

  filtered = false;
  filterParams = {
    year: null,
    date2: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    date1: '0001-01-01',
    companyId: this.authInfo.getCompanyId()
  }

  departments: any[]= [];
  department: any[] = [];
  constructor(
    private _toastService: ToastService,
    private jobOfferService: JobOfferService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<JobOfferDashboardComponent>,
    private _changeDet: ChangeDetectorRef,
    private authInfo: AuthInfoService) {
    this.dialogRef.disableClose = true;
    this.buildCharts();
  }

  ngAfterViewInit(): void {
    try{
      this.filterParams.date1 = this.datePipe.transform(`${this.authInfo.getConstitutionDate()}`, 'yyyy-MM-dd')
      this.filterParams.date2 = this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd')
    }catch(e){

    }
    this.getJobOfferDashboard();
  }

  buildCharts() {
    this.chartOptions = {
      series: [0, 0, 0],
      chart: {
        type: "donut",
        animations: {
          enabled: false // Disable animations
        }
      },
      
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#ffffff', '#ffffff', '#ffffff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      title: {
        text: ""
      },
      labels: ["", "", ""],
      fill: {
        colors: ["#f11565", "#0481af", "#023878"],
      },
      colors: ["#f11565", "#0481af", "#023878"],
      legend: {
        show: true,
        position: 'bottom',
        },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }
      ]
    };

    this.chartOptions2 = {
      series: [0, 0, 0],
      chart: {
        type: "donut",
        animations: {
          enabled: false // Disable animations
        }
      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      title: {
        text: ""
      },
      labels: ["", "", ""],
      fill: {
        colors: ["#f11565", "#0481af", "#023878"],
      },
      colors: ["#f11565", "#0481af", "#023878"],


      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
          }
        }
      ]
    };


    this.barOptions2 = {
      series: [
        {
          name: "Cantidad de vinculaciones",
          data: [0, 0, 0],
        }
      ],
      chart: {
        type: "bar",
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
        },
        animations: {
          enabled: false // Disable animations
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
        ],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: ""
        },
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        },
        z: {
          formatter: undefined,
          title: ''
        }
      }
    };
  }


  ngOnInit(): void {
   
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  filterByDate(): void {
    this.dialog.open(FilterDashboardComponent, {
      data: { filterParams: this.filterParams }
    })
      .afterClosed()
      .subscribe((result) => {
        if(!result) return
        this.filtered = result.year != null || result.date1 != null || result.date2 != null;

        if (!this.filtered) {
          return;
        }

        this.filterParams.year = result.year
        if (result.date1) {
          this.filterParams.date1 = result.date1
        }

        if (result.date2) {
          this.filterParams.date2 = result.date2
        }

        this.filterParams.companyId = result.companyId

        this.getJobOfferDashboard();
      });
  }

  clearFilter(): void {
    this.filterParams = {
      date1: this.datePipe.transform(`01-01-${new Date().getFullYear()}`, 'yyyy-MM-dd'),
      date2: this.datePipe.transform(`12-31-${new Date().getFullYear()}`, 'yyyy-MM-dd'),
      year: null,
      companyId: this.authInfo.getCompanyId()
    }

    this.getJobOfferDashboard()
  }

  getJobOfferDashboard() {
    this.jobOfferService.getJobOfferDashboard(this.filterParams.date1, this.filterParams.date2, this.filterParams.companyId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      //TOTAL EMPLOYEES
      const totalEmployee = res.singleData.totalEmployeeHiringQuantities;
      this.setTotal(totalEmployee);

      //TOTAL POSTULATIONS BY DEPARTMENT
      const totaDepartment = res.singleData.totaHiringDepartmentQuantities;
      this.departments = [...totaDepartment];
      this.department = [...this.departments.slice(0,10)];
      this.setTotaDepartment(this.department);

      //TOTAL POSTULATIONS BY SEX
      const totagGender = res.singleData.totaHiringGenderQuantities;
      this.setTotagGender(totagGender);
 
      //TOTAL POSTULATIONS BY CONDITION
      const totaStateQuantities = res.singleData.totaHiringStateQuantities;
      this.setTotaStateQuantities(totaStateQuantities)

    }, error => {
      this._toastService.error("Ocurrió un error al buscar los datos");
    })
  }

  setTotal(totalEmployee: any){
    this.totalPostulations = totalEmployee[0].value;
  }

  setTotaDepartment(totaDepartment: any[]){
    this.barOptions2.xaxis.categories = []
    let data2 = []
    totaDepartment.forEach(ed => {
      data2.push({
        x: ed.description2,
        y: ed.value,
        z: ed.description
      })
    })
    this.barOptions2.series = [{
      data: data2
    }]
  }
  
  setTotagGender(totagGender: any[]){
    this.chartOptions.series = []
    this.chartOptions.labels = []
    totagGender.forEach(ps => {
      this.chartOptions.labels.push(ps.description)
      this.chartOptions.series.push(ps.value)
    })
  }

  setTotaStateQuantities(totaStateQuantities: any[]){
    this.chartOptions2.series = []
    this.chartOptions2.labels = []
    totaStateQuantities.forEach(t => {
      switch (t.description) {
        case 'Aprobado':
          this.chartOptions2.labels.push('No Nombrado')
          break;
        case 'Contratado':
          this.chartOptions2.labels.push('Nombrado')
          break;
        case 'Reservado':
          this.chartOptions2.labels.push('Reservado para Nombramiento')
          break;
        default:
          this.chartOptions2.labels.push(t.description)
          break;
      }
      this.chartOptions2.series.push(t.value);
    });
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  changeDepartment(){
    this.setTotaDepartment([]);

    if (this.department.length < 1) {
      return
    }
    let data: any[] = []
    this.department.forEach(e => {
      let chartData: any = this.departments.find(x => x.description2 == e.description2)
      if (!chartData) {
        chartData = this.departments.find(x => x.description2 == e.description2) ?? { rowText: e.description2, value1: 0, value2: 0 }
      }
      let cData = Object.assign({}, chartData)
      data.push(cData)
    });
    this.setTotaDepartment([...data]);
  }

  dropdownConfig(displayKey, placeholder?) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }



}
