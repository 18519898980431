import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INewsPOST } from '@payroll/additional-payroll/models/models';
import { PeriodParams } from '@payroll/schedule/models/period-get-params.model';
import { Period } from '@payroll/schedule/models/period.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Incidents } from '../pages/EvaluationPerformance/models/Incidents';
import { RequestMetaData, ResponseModel } from '../shared/models/strongly-typed-response.model';
import { keyWord } from '../shared/utils/parameterControl';
import { AuthInfoService } from './auth-info.service';
import { ParameterControlService } from './parameter-control.service';

@Injectable({
  providedIn: 'root'
})
export class PayrollPeriodService {
  apiCore = environment.api_core_url;// 'http://172.16.11.173/core_back/api'
  api = environment.api_url; // 'http://172.16.11.173/xhrm_back/api'

  constructor(private http: HttpClient, private authInfo: AuthInfoService) { }

  getAll(params:PeriodParams): Observable<ResponseModel<Period>>{
    params = {
      value: params.value??0,
      type: params.type??0,
      companyId: params.companyId?? this.authInfo.getCompanyId(),
      status: params.status??true,
    }
    return this.http.get<ResponseModel<Period>>(
        `${this.api}/period?PayrollPeriodId=${params.value}&Type=${params.type}&CompanyId=${params.companyId}&Status=${params.status}`);
  }

  post(model: Period): Observable<RequestMetaData>{
    return this.http.post<RequestMetaData>(`${this.api}/Period`, model);
  }

  put(model: Period): Observable<RequestMetaData>{
    return this.http.put<RequestMetaData>(`${this.api}/Period`, model);
  }

  delete(model){
    return this.http.put<RequestMetaData>(`${this.api}/Period/inactive`,model);      
  }

  openPeriod(model){
    return  this.http.put<ResponseModel<Period>>(`${this.api}/Period/Open`, model) 
  }
  closePeriod(model){
    return  this.http.put<ResponseModel<Period>>(`${this.api}/Period/Close`, model) 
  }

  getBeforePeriod(): Observable<ResponseModel<Period>>{
    return this.http.get<ResponseModel<Period>>(
      `${this.api}/period?Type=8&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }


}