<div class="card-title">
  <div class="ttl d-flex align-items-center">
    <i class="flaticon-agregar-archivo-1 icon-3x text-danger mr-2"></i>
    <h1 class="text-primary">Acciones Masivas</h1>
  </div>
</div>

<div class="card-boddy mt-20 mb-20" *ngIf="useCollectiveVacations">
  <div class="text-title">
    <p style="padding-left: 80px" class="h2 font-weight-bold text-primary">
      Haga click en la operación que desea realizar.
    </p>
  </div>

  <div class="d-flex p-5 mt-15">
    <div class="mb-5 card-payroll card-1">
      <a *ngIf="privilege?.collectivevacations?.value" routerLink="/collective-vacations"
        class="btn text-center py-5 px-5">
        <i class="flaticon-importacion icon-5x text-white mt-13"></i>
        <span class="d-block font-weight-bold mt-2 h5 text-white mt-5">Vacaciones Colectivas</span>
      </a>
    </div>
    <div class="mb-5 card-payroll card-2 ml-10">
      <a *ngIf="privilege?.salaryincrease?.value" type="button" (click)="displayMessage()"
        class="btn text-center py-5 px-5">
        <i class="flaticon-notas icon-5x text-white mt-13"></i>
        <span class="d-block font-weight-bold mt-2 h5 text-white mt-5">Aumento Salarial</span>
      </a>
    </div>
    <div class="mb-5 card-payroll card-1 ml-10" *ngIf="privilege?.separations?.value">
      <a routerLink="/separations"
        class="btn text-center py-5 px-5">
        <i class="flaticon-recursos-humanos-1 icon-5x text-white mt-13"></i>
        <span class="d-block font-weight-bold mt-2 h5 text-white mt-5">Desvinculaciones</span>
      </a>
    </div>
    <div class="mb-5 card-payroll card-1 ml-10">
      <a routerLink="/vinculations"
        class="btn text-center py-5 px-5">
        <i class="flaticon-recursos-humanos-4 icon-5x text-white mt-13"></i>
        <span class="d-block font-weight-bold mt-2 h5 text-white mt-5">Vinculaciones</span>
      </a>
    </div>
    <div class="mb-5 card-payroll card-1 ml-10"  *ngIf="privilege?.changeSupervisor?.value">
      <a routerLink="/change-supervisor"
        class="btn text-center py-5 px-5">
        <i class="flaticon-usuario icon-5x text-white mt-13"></i>
        <span class="d-block font-weight-bold mt-2 h5 text-white mt-5">Cambiar Supervisor</span>
      </a>
    </div>
  </div>
</div>
