import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Period } from '@payroll/schedule/models/period.model';
import { docService } from 'src/app/services/doc.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollReportService } from 'src/app/services/payroll-report.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { OverallSummary } from '../models/overallSummary.model';
import * as XLSX from 'xlsx';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { Location } from '@angular/common';
import { PayrollService } from 'src/app/services/payroll.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';

declare const $: any;

@Component({
  selector: 'app-flyer',
  templateUrl: './flyer.component.html',
  styleUrls: ['./flyer.component.css']
})
export class FlyerComponent implements OnInit {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PAYROLL-REPORT',
    exportAction: 'EXPGEN',
    searchAction: 'SEARGEN'
  }


  dropdownSettings = {
    singleSelection: false,
    idField: 'employeeTypeId',
    textField: 'description',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  periods: Period[] = [];
  filteredPeriods: Period[] = [];
  periodSelected: Period;
  yearsList: any[] = []

  payrollTypes: any[] = [];
  payrollTypesSelected: any;

  employeeType: any[] = [];
  employeeTypeSelected: any[] = [];

  overallSummary: any[] = [];
  overallSummaryOriginalList: OverallSummary[] = [];

  form: FormGroup;

  companies: any[] = [];

  photoUrl: string;
  loadingPic: boolean = true;

  subTotalGrossSalary = 0;
  subTotalTotalSalary = 0;
  subTotalSfs = 0;
  subTotalAfp = 0;
  subTotalIsr = 0;
  subTotalOtherDiscounts = 0;
  subTotalTotalDiscount = 0;
  subTotalNetIncome = 0;
  subTotalOtherCredits = 0;

  totalGrossSalary = 0;
  totalOtherCredits = 0;
  totalTotalSalary = 0;
  totalSfs = 0;
  totalAfp = 0;
  totalIsr = 0;
  totalOtherDiscounts = 0;
  totalTotalDiscount = 0;
  totalNetIncome = 0;


  public company: any;
  file = { logo: '', image1: '', image2: '' }

  budgetAreaList: any[] = [];
  areaSelected: any;
  versionSelected: any;

  payrollVersions: any[] = [];
  selectedPayrollType: any;
  periodRelatedSelected: any;
  relatedPeriods = [

  ];
  constructor(
    private parameterServices: ParameterControlService,
    private payrollReportService: PayrollReportService,
    private _exlService: ExportToExcelService,
    private _toastService: ToastService,
    private fb: FormBuilder,
    private srvDoc: docService,
    public authInfo: AuthInfoService,
    private _companiesService: CompaniesService,
    private location: Location,
    private payrollService: PayrollService,
  ) {

    this.buildForm();
  }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.getCompanies();
        this.getPayrollTypes();
        this.getPeriods();
        this.companyById(this.authInfo.getCompanyId())
      }
    });
  }

  changePayrollType(payrollType: ParameterControl) {
    if (payrollType?.ocode === 4) {
      this.addValidation('relatedPeriod', [Validators.required]);
      this.getBeforePeriod();
      return;
    }
    this.relatedPeriods = [];
    this.addValidation('relatedPeriod', null);
    this.getPayrollVersions();
  }

  private addValidation(controlName: string, validators) {
    this.form.get(controlName).setValidators(validators);
    this.form.get(controlName).updateValueAndValidity();
  }

  getBeforePeriod() {
    this.payrollService.getBeforePeriod().subscribe(res => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.relatedPeriods = res.dataList;
      this.getPayrollVersions();
    });
  }

  goBack() {
    this.location.back();
  }

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  buildForm() {
    this.form = this.fb.group({
      payrollType: [null, [Validators.required]],
      employeeType: [null, [Validators.required]],
      period: [null, [Validators.required]],
      relatedPeriod: [null],
      area: [null, [Validators.required]],
      version: [null, [Validators.required]]
    })
  }

  get getPayrollTypeSeleted() {
    return this.form.get('payrollType')?.value;
  }

  get isPeriodAndAreaSeleted() {
    return this.form.get('period')?.valid && this.form.get('area')?.valid;
  }

  getProfileImageCompany(profileImage) {
    this.srvDoc.getDocument(profileImage).subscribe(e => {
      this.photoUrl = e.data;
      this.loadingPic = false
    }, error => {
      this.loadingPic = false
    })
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  getCompanies(): any {
    this.payrollReportService.getCompanies().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.companies = res.dataList;
    }, error => {
      this._toastService.error('Ocurrió un error al cargar las compañías, por favor intente más tarde o comuníquese con HelpDesk.', '');
    })
  }



  getPayrollTypes() {
    this.parameterServices.getParameters(keyWord.PayrollType).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.payrollTypes = res.dataList;
    }, error => {
      this._toastService.error('Ocurrió un error inesperado', '');
    });
  }

  getPayrollVersions() {
    const formValue = this.form?.value;
    const payrollPeriodId = formValue?.period?.payrollPeriodId || 0;
    const payrollTypeId = formValue?.payrollType?.ocode;
    const budgetAreaId = formValue?.area?.budgetAreaId || 0;
    this.payrollVersions = [];
    this.form.get('version').setValue(null);
    if (payrollPeriodId === 0 || budgetAreaId === 0 || (payrollTypeId === null || payrollTypeId === undefined)) {
      return;
    }
    this.payrollService.getPayrollVersions(payrollPeriodId, payrollTypeId, budgetAreaId).subscribe((res: any) => {
      if (res?.errors?.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.payrollVersions = res?.dataList?.map(v => {
        return {
          ...v,
          versionDescription: `${v?.headerId} - ${v?.versionId} - ${v?.headerCondition}`
        }
      });
    }, error => {

    })
  }
  onPayrollVersionChange(version: any) {
    this.getEmployeeType(version?.headerId)
  }

  getPeriods() {
    this.payrollService.getBudgetAreas().subscribe((res: any) => {
      this.budgetAreaList = res.dataList.filter(ba => ba.status == true)

    })

    this.payrollReportService.getPeriods().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error("Ha ocurrido un error al obtener los períodos");
        return;
      }
      this.periods = res.dataList.filter(e => e.status);;
      this.filteredPeriods = this.periods
      let periodYears: any[] = []
      let years = this.periods.map((period: any) => {
        let periodYear = moment(`${period.startDate}`).startOf('date').year().toString()
        if (!periodYears.includes(periodYear)) {
          periodYears.push(periodYear)
        }
        return (periodYears)
      })

      this.yearsList = years[0].reverse()
    }, err => this._toastService.error(err))
  }

  changeYear(event) {
    this.filteredPeriods = this.periods.filter((period: any) => period.description.includes(`${event.value}`))
  }

  getEmployeeType(headerId:number) {
    this.payrollService.getReportsEmployeeTypes(headerId).subscribe((res: any) => {
      if (!res.succeded) {
        return
      }
      this.employeeType = res.dataList?.filter(d => d?.status);
    });
  }

  getCompanyById(id) {
    return this.companies.find(c => c.companyId === id);
  }

  resetTotals() {
    this.totalGrossSalary = 0
    this.totalOtherCredits = 0;
    this.totalTotalSalary = 0
    this.totalSfs = 0
    this.totalAfp = 0
    this.totalIsr = 0
    this.totalOtherDiscounts = 0
    this.totalTotalDiscount = 0
    this.totalNetIncome = 0
  }

  getOverallSummary() {
    //return
    if (this.form?.invalid) {
      this._toastService.warning('Debe llenar todos los campos previamente');
      return;
    }
    this.resetTotals();
    this.overallSummary = [];
    this.payrollTypesSelected = { ... this.form.get('payrollType')?.value };
    this.employeeTypeSelected = [...this.form.get('employeeType')?.value];
    this.periodSelected = { ...this.form.get('period')?.value };
    this.areaSelected = { ...this.form.get('area')?.value };
    this.versionSelected = { ...this.form.get('version')?.value };
    this.periodRelatedSelected = { ...this.form.get('relatedPeriod')?.value };

    let payrollPeriodId: number = this.periodSelected.payrollPeriodId;
    let payrollTypeId: number = this.payrollTypesSelected?.ocode;
    let areaId: number = this.areaSelected?.budgetAreaId;
    let versionId: number = this.versionSelected?.versionId
    let periodRelated: number = this.periodRelatedSelected?.payrollPeriodId || 0;

    this.payrollReportService.getOverallSummaryWithoutEmployeeType(payrollPeriodId, payrollTypeId, areaId, versionId, periodRelated).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0], '');
        return;
      }
      this.overallSummaryOriginalList = res.dataList.filter(x => {
        if (this.employeeTypeSelected.find(y => y.employeeTypeId == x.employeeTypeId)) {
          return true;
        } else {
          return false;
        }
      });

      if (this.overallSummaryOriginalList.length === 0) {
        this._toastService.warning('No se encontraron registros');
        return;
      }

      this.getProfileImageCompany(this.getCompanyById(res.dataList[0]?.companyId)?.logo);

      let departments = this.groupBy(this.overallSummaryOriginalList, overallSummary => overallSummary.departmentId);
      departments.forEach(department => {
        department.forEach(summary => {
          //Totales
          this.totalGrossSalary += summary.grossSalary
          this.totalOtherCredits += summary.otherCredits
          this.totalTotalSalary += summary.totalSalary
          this.totalSfs += summary.sfs
          this.totalAfp += summary.afp
          this.totalIsr += summary.isr
          this.totalOtherDiscounts += summary.otherDiscounts
          this.totalTotalDiscount += summary.totalDiscount
          this.totalNetIncome += summary.netIncome
          //Subtotales
          this.subTotalGrossSalary += summary.grossSalary
          this.subTotalOtherCredits += summary.otherCredits
          this.subTotalTotalSalary += summary.totalSalary
          this.subTotalSfs += summary.sfs
          this.subTotalAfp += summary.afp
          this.subTotalIsr += summary.isr
          this.subTotalOtherDiscounts += summary.otherDiscounts
          this.subTotalTotalDiscount += summary.totalDiscount
          this.subTotalNetIncome += summary.netIncome
        });
        this.overallSummary.push(
          {
            department: this.getValues(department)?.sort((departmentA: any, departmentB: any) => {
              return departmentB?.grossSalary - departmentA?.grossSalary
            }),
            subTotalGrossSalary: this.subTotalGrossSalary?.toFixed(2),
            subTotalOtherCredits: this.subTotalOtherCredits?.toFixed(2),
            subTotalTotalSalary: this.subTotalTotalSalary?.toFixed(2),
            subTotalSfs: this.subTotalSfs?.toFixed(2),
            subTotalAfp: this.subTotalAfp?.toFixed(2),
            subTotalIsr: this.subTotalIsr?.toFixed(2),
            subTotalOtherDiscounts: this.subTotalOtherDiscounts?.toFixed(2),
            subTotalTotalDiscount: this.subTotalTotalDiscount?.toFixed(2),
            subTotalNetIncome: this.subTotalNetIncome?.toFixed(2),

          });
        this.subTotalGrossSalary = 0;
        this.subTotalOtherCredits = 0;
        this.subTotalTotalSalary = 0;
        this.subTotalSfs = 0;
        this.subTotalAfp = 0;
        this.subTotalIsr = 0;
        this.subTotalOtherDiscounts = 0;
        this.subTotalTotalDiscount = 0;
        this.subTotalNetIncome = 0;
      });
      this.overallSummary = this.overallSummary?.sort((departmentGroupA: any, departmentGroupB: any) => {
        return departmentGroupA?.department[0]?.order - departmentGroupB?.department[0]?.order
      })
    }, error => {
      this._toastService.error('Ocurrió un error inesperado al obtener el volante de nómina', '');
    })
  }

  get getEmployeeTypeDescription() {
    const employeeDescriptions = this.employeeTypeSelected?.map(e => e?.description);
    if (employeeDescriptions?.length > 1) {
      const lastEmployeeType = employeeDescriptions[employeeDescriptions?.length - 1];
      employeeDescriptions?.splice(employeeDescriptions?.length - 1, 1);
      return `${employeeDescriptions?.join(', ')} y ${lastEmployeeType}`;
    }
    return employeeDescriptions?.join(', ');
  }

  private getValues(item) {
    return Object.values(item);
  }

  private groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }


  generateTemplate() {

    const formValue = this.form?.value;
    const companyId = this.authInfo.getCompanyId();
    const payrollPeriodId = formValue?.period?.payrollPeriodId || 0;
    const payrollTypeId = formValue?.payrollType?.ocode || 0;
    const budgetAreaId = formValue?.area?.budgetAreaId || 0;
    const employeTpe = formValue?.employeeType?.employeeTypeId || 0;
    const version = formValue?.version?.versionId || 0;
    const periodRelated: number = this.form?.get('relatedPeriod')?.value?.payrollPeriodId || 0;
    console.log(formValue)
    console.log(formValue?.period?.payrollPeriodId )
    console.log(formValue?.employeeType[0]?.employeeTypeId )


    const url = `${environment.reportUrl}?ReportCode=payroll_tranparency&HeaderId=0&PayrollTypeId=${payrollTypeId}&BudgetAreaId=${budgetAreaId}&CompanyId=${companyId}&PayrollPeriodRelated=${periodRelated}&PeriodId=${payrollPeriodId}&status=true&VersionId=${version}&EmployeeTypeId=${formValue?.employeeType[0]?.employeeTypeId}-${formValue?.employeeType[1]?.employeeTypeId}-${formValue?.employeeType[2]?.employeeTypeId}`;
    openReport({
      url: url,
      title: 'Reporte de Nómina General',
      width: '1024',
      height: '724'
    })
  }

  exportToExcel() {
    let excelHeaders: string[][] = [[
      "Servidor Público",
      "Género",
      "Estatus",
      "Total Ingreso",
      "SFS",
      "AFP",
      "ISR",
      "Otros Desc.",
      "Total Desc.",
      "Neto",
    ]];

    const fileName = `Volante de Nómina`;
    const EXCEL_EXT = '.xlsx';

    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${fileName}_export${new Date().getTime()}${EXCEL_EXT}`);

    //this._exlService.exportToExcelSpecificColumns(this.buildObject, excelHeaders, 'Volante de Nómina');
  }

  get buildObject() {
    return this.overallSummaryOriginalList.map((o: OverallSummary) => {
      const over = {
        name: `${o.firstName} ${o.lastName}`,
        sex: o.sex,
        employeeType: o.employeeType,
        grossSalary: o.grossSalary,
        sfs: o.sfs,
        afp: o.afp,
        isr: o.isr,
        otherDiscounts: o.otherDiscounts,
        totalDiscount: o.totalDiscount,
        netIncome: o.netIncome
      }
      return { ...over };
    });
  }


}
