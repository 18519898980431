<div class="modal-header border-0 dv-table-header-1 bg-primary d-flex"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Filtros avanzados
    </h2>
    <button type="button"  class="close" aria-label="Close">
        <i aria-hidden="true" (click)="this.dialogRef.close()" class="ki ki-close text-white font-size-h5"></i>
    </button>
</div>
<div class="mat-dialog-content-custom" [formGroup]="form">
    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="form-group">
              <label>Tipo de Nómina</label>
              <div>
                  <ng-multiselect-dropdown
                      formControlName="payrollTypes"
                      [placeholder]="'Seleccione'"
                      [settings]="dropdownSettings"
                      [data]="payrollTypes">
                  </ng-multiselect-dropdown>
              </div>

          </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="form-group">
            <label>Tipos de Servidores Públicos</label>
            <div>
                <ng-multiselect-dropdown
                    formControlName="employeeType"
                    [placeholder]="'Seleccione'"
                    [settings]="dropdownSettings"
                    [data]="employeeType">
                </ng-multiselect-dropdown>
            </div>
          </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="form-group">
              <label>Servidor Publico</label>
              <div>
                  <input type="text" formControlName="employeeName" class="form-control form-control-solid form-control-lg" />
              </div>
          </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="form-group">
          <label>Unidad Organizativa</label>
          <div>
              <ng-multiselect-dropdown
                  formControlName="deparment"
                  [placeholder]="'Seleccione'"
                  [settings]="dropdownSettings"
                  [data]="deparment">
              </ng-multiselect-dropdown>
          </div>
      </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="form-group">
            <label>Cargo</label>
            <div>
                <ng-multiselect-dropdown
                    formControlName="position"
                    [placeholder]="'Seleccione'"
                    [settings]="dropdownSettings"
                    [data]="position">
                </ng-multiselect-dropdown>
            </div>
          </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="form-group">
            <label>Estatus</label>
            <div>
                <ng-multiselect-dropdown
                    formControlName="status"
                    [placeholder]="'Seleccione'"
                    [settings]="dropdownSettings"
                    [data]="statusOptions">
                </ng-multiselect-dropdown>
            </div>
          </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="form-group">
            <label>Coletilla</label>
            <div>
                <ng-multiselect-dropdown
                    formControlName="coletilla"
                    [placeholder]="'Seleccione'"
                    [settings]="dropdownSettings"
                    [data]="coletilla">
                </ng-multiselect-dropdown>
            </div>
          </div>
      </div>
    </div>
</div>
<div class="mat-dialog-actions"[align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary" type="button"
        (click)="Accept()">
        <i class="flaticon-buscar"></i>
        <span>Buscar</span>
    </button>
</div>
