<div class="modal-header border-0 dv-table-header-1 bg-primary d-flex" mat-dialog-title cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Filtros avanzados
    </h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h5"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">
    <div class="row" [formGroup]="advanceFilterForm">
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Secuencia</label>
                <div>
                    <input type="text" formControlName="sequence"
                        class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Documento de identidad</label>
                <div>
                    <input type="text" formControlName="personalIdentification"
                        class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Servidor público</label>
                <div>
                    <input type="text" formControlName="employee"
                        class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Tipo de Novedad</label>
                <div>
                    <ng-multiselect-dropdown [placeholder]="'Seleccionar Novedad'"
                        [settings]="dropdownConfig('newsTypeId', 'description')" [data]="newtyTypes"
                        formControlName="newsType" (onDeSelect)="onNewsTypeDeselect($event)"
                        (onSelect)="onNewsTypeSelect($event)" (onSelectAll)="onNewsTypeSelectAll()"
                        (onDeSelectAll)="onNewsTypeDeselectAll()"></ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Concepto</label>
                <div>
                    <ng-multiselect-dropdown [placeholder]="'Seleccione'"
                        [settings]="dropdownConfig('newsConceptId', 'description')" [data]="concepts"
                        formControlName="newsConcept">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Registrado por</label>
                <div>
                    <input type="text" formControlName="registerBy"
                        class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Monto</label>
                <div class="input-group">
                    <span class="input-group-text">Desde</span>
                    <input type="number" formControlName="amountFrom" step="0.01" min="0"
                        class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Monto</label>
                <div class="input-group">
                    <span class="input-group-text">Hasta</span>
                    <input type="number" formControlName="amountTo" step="0.01" min="0"
                        class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Condición de la novedad</label>
                <div>
                    <ng-multiselect-dropdown [placeholder]="'Seleccione'" [settings]="dropdownSettings"
                        [data]="newsConditions" formControlName="newsCondition">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Valor Manual</label>
                <div class="input-group">
                    <span class="input-group-text">Desde</span>
                    <input type="number" formControlName="manualValueFrom" step="0.01" min="0"
                        class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Valor Manual</label>
                <div class="input-group">
                    <span class="input-group-text">Hasta</span>
                    <input type="number" formControlName="manualValueTo" step="0.01" min="0"
                        class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Rango de fecha</label>
                <div class="input-group">
                    <span class="input-group-text">Desde</span>
                    <input type="date" formControlName="dateFrom"
                        class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="form-group">
                <label>Rango de fecha</label>
                <div class="input-group">
                    <span class="input-group-text">Hasta</span>
                    <input type="date" formControlName="dateTo"
                        class="form-control form-control-solid form-control-lg" />
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary" type="button" (click)="Accept()">
        <i class="flaticon-buscar"></i>
        <span>Buscar</span>
    </button>
</div>