import { DatePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { PositionService } from 'src/app/services/position.service';
import { FilterDashboardComponent } from 'src/app/shared/filter-dashboard/filter-dashboard.component';
import { BarOptions, ChartOptions } from 'src/app/shared/models/apexChartModels';
import { ToastService } from 'src/app/shared/toast/toast.service';

declare const $: any;

interface AgeStats {
  age: number;
  count: number;
}

@Component({
  selector: 'app-employee-dashboard',
  templateUrl: './employee-dashboard.component.html',
  styleUrls: ['./employee-dashboard.component.css']
})
export class EmployeeDashboardComponent implements OnInit, AfterViewChecked, OnDestroy {
  employeeByOrganizative = []
  public barOptions: Partial<BarOptions>;
  public barOptions2: Partial<BarOptions>;
  public enclosureBarOptions2: Partial<BarOptions>;
  public chartOptions: Partial<ChartOptions>;
  public nacionalityChartOptions: Partial<ChartOptions>;
  public academicChartOptions: Partial<ChartOptions>;
  public genderCharttOptions: Partial<ChartOptions>;
  public transportUsageChartOptions: Partial<ChartOptions>;
  public ageCharttOptions: Partial<ChartOptions>;
  datePipe = new DatePipe('en-Us')
  employeeByDepartments = []
  byEnclousures = []
  positionDropdownSettings
  departmentDropdownSettings
  employeeDashboardData: any
  totalEmployees: [] = []
  employeesByContract: any[] = []
  employeesByDepartment: any[] = []
  employeesBy3Years: any[] = []
  employeesByEnclosure: any[] = []
  inactiveEmployees: number;
  refresIsDisabled: boolean = true
  filtered = false;
  totalEmployeeByAcademicLevelOriginalist: any[] = [];
  employeeByEnclosure = []
  filterParams = {
    year: null,
    date2: this.datePipe.transform(new Date(), 'MM-dd-yyyy'),
    date1: '01-01-0001',
    companyId: this.authInfo.getCompanyId()
  }

  ageRanges: { text: string, values: number[] }[] = [
    { text: '17- años', values: [0, 17] },
    { text: '18 a 25 años', values: [18, 25] },
    { text: '26 a 35 años', values: [26, 35] },
    { text: '36 a 45 años', values: [36, 45] },
    { text: '46 a 55 años', values: [46, 55] },
    { text: '56+ años', values: [56, Number.MAX_VALUE] }
  ];
  loadingMessage = 'Cargando...'
  noDataMessage = 'No hay datos'
  nationalityChart = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }
  academicChart = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }

  academicChart2 = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }

  ageChart = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }
  contratcTypeChart = {
    labels: [],
    series: [],
    status: this.loadingMessage
  }



  selectedItems: any[] = [];

  positions: any[] = [];

  dropdownSettings = {
    singleSelection: false,
    idField: 'positionId',
    textField: 'positionName',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    noDataAvailablePlaceholderText: 'No se han encontrado registros',
    enableCheckAll: true,
    searchPlaceholderText: 'Buscar',
    allowSearchFilter: true,
    itemsShowLimit: 2,
    limitSelection: -1
  };
  organizationalDropdownSetting
  totalEmployeeByAcademicLevelOriginalistDepart: any[] = [];

  selectedItemsDeparments: any[] = [];
  deparments: any[] = [];

  departments: any[] = [];
  department: any[] = [];

  constructor(
    private _toastService: ToastService,
    private employeeService: EmployeesService,
    private positionService: PositionService,
    private dialog: MatDialog, private authService: AuthInfoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmployeeDashboardComponent>,
    private _changeDet: ChangeDetectorRef,
    private authInfo: AuthInfoService
  ) {
    this.dialogRef.disableClose = true;
    this.departmentDropdownSettings = this.getDropdownSettings('departmentId','departmentName')
    this.positionDropdownSettings = this.getDropdownSettings('positionId','positionName')
    this.organizationalDropdownSetting = this.dropdownConfig('description')
    this.buildCharts()

  }

  changeDepartment() {
    this.employeesByDepartment = [];
    this.setDataDepartment([]);

    if (this.department.length < 1) {
      return
    }
    let data: any[] = []
    this.department.forEach(e => {
      let chartData: any = this.departments.find(x => x.description2 === e.description2);
      if (!chartData) {
        chartData = this.departments.find(x => x.description2 === e.description2) ?? { rowText: e.description2, value: 0, value2: 0 }
      }
      //console.log(chartData)
      let cData = Object.assign({}, chartData)
      data.push(cData)
    });
    this.employeesByDepartment = [...data];
  }

  setDataDepartment(deparments) {
    let data = [];
    /* deparments.forEach(ed => {
      data.push({
        x: ed.description2,
        y: ed.value,
        z: ed.description
      })
    }) */
    /* this.barOptions2.series = [{
      data: data
    }] */

  }

  dropdownConfig(displayKey, placeholder?) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccione una opción',
      moreText: 'más',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  getDropdownSettings(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      noDataAvailablePlaceholderText: 'No se han encontrado registros',
      enableCheckAll: true,
      searchPlaceholderText: 'Buscar',
      allowSearchFilter: true,
      itemsShowLimit: 2,
      limitSelection: -1
    };
  }

  getPositions() {
    this.positionService.getPositions(this.authService.getCompanyId(), -1, true).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.positions = res.dataList.filter(d => d.status);
      this.selectedItems = [...this.positions];
      this.changeEmployee_AcademicLevel();
    })
  }

  getDeparments() {
    this.employeeService.getDeparments().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.deparments = res.dataList.filter(d => d.status);
      this.selectedItemsDeparments = [...this.deparments];
      this.chageEmployee_AcademicLevelByDeparment();
    });

  }


  buildCharts() {


    this.barOptions = {
      series: [
        {
          name: "Cantidad de Servidores Públicos",
          data: [0, 0, 0]
        }
      ],
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
        ],
      },
      yaxis: {
        title: {
          text: ""
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        }
      }
    };

    this.barOptions2 = {
      series: [
        {
          name: "Cantidad de Servidores Públicos",
          data: [0, 0, 0],
        }
      ],
      chart: {
        type: "bar",
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
        ],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: ""
        },
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        },
        z: {
          formatter: undefined,
          title: ''
        }
      }
    };

    this.enclosureBarOptions2 = {
      series: [
        {
          name: "Cantidad de Servidores Públicos",
          data: [0, 0, 0],
        }
      ],
      chart: {
        type: "bar",
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
        ],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: ""
        },
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        }
      }
    };

    this.chartOptions = {
      series: [],
      chart: {
        type: "donut",

      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      title: {
        text: ""
      },
      labels: [],

      legend: {
        show: true,
        position: 'bottom',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }
      ]
    };

    this.nacionalityChartOptions = {
      series: [],
      chart: {
        type: "donut",
      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      title: {
        text: ""
      },
      labels: [],
      legend: {
        show: true,
        position: 'bottom',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }
      ]
    };

    this.academicChartOptions = {
      series: [],
      chart: {
        type: "donut",
      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      title: {
        text: ""
      },
      labels: [],
      legend: {
        show: true,
        position: 'bottom',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }
      ]
    };

    this.ageCharttOptions = {
      series: [],
      chart: {
        type: "donut",
      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      title: {
        text: ""
      },
      labels: [],
      legend: {
        show: true,
        position: 'bottom',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }
      ]
    };

    this.genderCharttOptions = {
      series: [],
      chart: {
        type: "donut",
      },
      stroke: {
        width: 0
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      title: {
        text: ""
      },
      labels: [],
      legend: {
        show: true,
        position: 'bottom',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }
      ]
    };

    this.transportUsageChartOptions = {
      series: [],
      chart: {
        type: "donut",
      },
      stroke: {
        width: 0
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff', '#fff', '#fff']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      title: {
        text: ""
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }
      ]
    };

  }


  ngOnInit(): void {
    try {
      this.filterParams.date1 = this.datePipe.transform(`${this.authInfo.getConstitutionDate()}`, 'yyyy-MM-dd')
      this.filterParams.date2 = this.datePipe.transform(`${moment()}`, 'yyyy-MM-dd')
    } catch (e) {

    }

    this.getPositions();
    this.getDeparments();
    this.buildCharts();
    this.getDashboardData();
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  filterByDate(): void {
    this.dialog.open(FilterDashboardComponent, {
      data: { filterParams: this.filterParams }
    })
      .afterClosed()
      .subscribe((result) => {
        this.filtered = result.year != null || result.date1 != null || result.date2 != null;

        if (!this.filtered) {
          return;
        }

        this.filterParams.year = result.year
        if (result.date1) {
          this.filterParams.date1 = result.date1
        }

        if (result.date2) {
          this.filterParams.date2 = result.date2
        }
        this.filterParams.companyId = result.companyId
        this.getDashboardData();
        this.changeEmployee_AcademicLevel();
        this.chageEmployee_AcademicLevelByDeparment()
      });
  }

  clearFilter(): void {


    this.filterParams = {
      date1: `${this.datePipe.transform(`${this.data.companyConstitutionDate}`, 'yyyy-MM-dd')}`,
      date2: `${moment()}`,
      year: null,
      companyId: this.authInfo.getCompanyId()
    }

    this.getDashboardData()
    this.refresIsDisabled = true
  }

  getDashboardData() {
    this.employeeService.getStatistics(this.filterParams.companyId, this.filterParams.date1, this.filterParams.date2).subscribe(data => {
      if (data.errors.length > 0) {
        this._toastService.error(data.errors[0])
        return;
      }
      if (data.succeded) {
        this.employeeDashboardData = data.singleData;
        this.setDashboardData();
      }
    }, error => {
    });

  }

  setDashboardData() {
    this.setTotalEmployee()
    this.setEmployeeByDepartments()
    this.setEmployeeByYears()
    this.setEmployeeByContractType()
    this.setEmployeeByNacionality()
    this.setEmployeeByNacionality()
    this.setEmployeeByAcadmicLevel()
    this.setEmployeeByAge()
    this.setEmployeeByGender()
    this.setEmployeeByEnclosure()
    this.setEmployeeTransportUsage()
  }

  setTotalEmployee() {
    this.totalEmployees = this.employeeDashboardData.totalEmployeeQuantities[0]?.value
    this.inactiveEmployees = this.employeeDashboardData.totalEmployeeInnactiveQuantities[0]?.value
  }

  setEmployeeByDepartments() {
    this.departments = [...this.employeeDashboardData.totalEmployeeByDepartmentsQuantities];
    this.department = [...this.departments.slice(0, 10)];

    this.employeesByDepartment = [...this.department];
    this.setDataDepartment(this.employeesByDepartment);
  }

  setEmployeeByYears() {
    this.employeesBy3Years = this.employeeDashboardData.totalEmployeeBy3LastYearsQuantities
    let data = []
    this.employeesBy3Years.forEach(ey => {
      data.push({
        x: ey.description,
        y: ey.value
      })
    })
    this.barOptions.series = [{
      data: data
    }]
  }

  setEmployeeByContractType() {
    this.employeesByContract = this.employeeDashboardData.totalEmployeeByContractTypeQuantities
    this.contratcTypeChart.series = []
    this.contratcTypeChart.labels = []
    if (this.employeesByContract.length < 1) {
      this.contratcTypeChart.status = this.noDataMessage;
      return;
    }
    this.employeesByContract.forEach(ec => {
      this.contratcTypeChart.labels.push(ec.description ? ec.description : 'No definido')
      this.contratcTypeChart.series.push(ec.value)
    })
    //this.employeeByDepartments = this.employeesByContract
    console.log(this.employeesByContract)
  }

  setEmployeeByNacionality() {
    let dataList = this.employeeDashboardData.totalEmployeeByNationalityQuantities
    this.nationalityChart.labels = []
    this.nationalityChart.series = []
    if (dataList.length < 1) {
      this.nationalityChart.status = this.noDataMessage;
      return;
    }
    dataList.forEach((data) => {
      this.nationalityChart.labels.push(data.description);
      this.nationalityChart.series.push(data.value)
    })
  }

  setEmployeeByAcadmicLevel() {
    let dataList = [...this.totalEmployeeByAcademicLevelOriginalist];
    this.academicChart.labels = []
    this.academicChart.series = []
    if (dataList.length < 1) {
      this.academicChart.status = this.noDataMessage;
      return;
    }
    dataList.forEach((al) => {
      this.academicChart.labels.push(al.description ? al.description : 'No definido');
      this.academicChart.series.push(al.value)
    })
  }

  setEmployeeByAcadmicLevelDepart() {
    let dataList = [...this.totalEmployeeByAcademicLevelOriginalistDepart];
    this.academicChart2.labels = []
    this.academicChart2.series = []
    if (dataList.length < 1) {
      this.academicChart2.status = this.noDataMessage;
      return;
    }
    dataList.forEach((al) => {
      this.academicChart2.labels.push(al.description ? al.description : 'No definido');
      this.academicChart2.series.push(al.value)
    })
  }

  setEmployeeByAge() {
    this.ageChart.status = this.noDataMessage;
    let dataList: AgeStats[] = this.employeeDashboardData.totalEmployeeByAgeQuantities
    this.ageChart.labels = []
    this.ageChart.series = []
    if (dataList.length < 1) { this.ageChart.status = this.noDataMessage; return; }
    this.ageRanges.forEach(element => {
      this.ageChart.labels.push(element.text);
      this.ageChart.series.push(this.getStatsByAgeRange(element.values, dataList))
    });
  }

  getStatsByAgeRange(range, arr): number {
    let value = 0;
    let a = arr.filter(x => x.description >= range[0] && x.description <= range[1]);
    a.forEach((element) => {
      value += element.value;
    })
    return value;
  }

  setEmployeeByGender() {
    let dataList = this.employeeDashboardData.totalEmployeeByGenderQuantities
    this.genderCharttOptions.labels = []
    this.genderCharttOptions.series = []
    dataList.forEach(eg => {
      this.genderCharttOptions.labels.push(eg.description);
      this.genderCharttOptions.series.push(eg.value)
    });
  }

  setEmployeeTransportUsage() {
    let dataList = this.employeeDashboardData.employeeByTransport;

    this.transportUsageChartOptions.labels = []
    this.transportUsageChartOptions.series = []
    dataList.forEach(eg => {
      this.transportUsageChartOptions.labels.push(eg.description);
      this.transportUsageChartOptions.series.push(eg.value)
    });
  }


  setEmployeeByEnclosure() {
    this.employeesByEnclosure = this.employeeDashboardData.totalEmployeeByPrecintQuantities
    let data = []
    this.employeesByEnclosure.forEach(ee => {
      data.push({
        x: ee.description,
        y: ee.value
      })
    })
    this.enclosureBarOptions2.series = [{
      data: data
    }]
    this.employeeByEnclosure = this.employeeByEnclosure
    console.log(this.enclosureBarOptions2.series, this.employeesByEnclosure)
  }

  changeEmployee_AcademicLevel() {
    let academicLevelByPosition: any = {
      select: [...this.selectedItems.map(s => {
        return s.positionId;
      })],
      status: true,
      companyId: this.filterParams.companyId,
      dateFrom: this.filterParams.date1,
      dateTo: this.filterParams.date2,
      type: 0
    };

    this.employeeService.getDashboardAcademicLevelFilter(academicLevelByPosition).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.totalEmployeeByAcademicLevelOriginalist = res.singleData.totalEmployeeByAcademicLevelQQuantities;
      this.setEmployeeByAcadmicLevel();
    })
  }

  chageEmployee_AcademicLevelByDeparment() {
    let academicLevelByDeparmnet: any = {
      select: [...this.selectedItemsDeparments.map(s => {
        return s.departmentId;
      })],
      status: true,
      companyId: this.filterParams.companyId,
      dateFrom: this.filterParams.date1,
      dateTo: this.filterParams.date2,
      type: 1
    };

    this.employeeService.getDashboardAcademicLevelFilter(academicLevelByDeparmnet).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.totalEmployeeByAcademicLevelOriginalistDepart = res.singleData.totalEmployeeByAcademicLevelDepartments;
      this.setEmployeeByAcadmicLevelDepart();
    })
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.changeEmployee_AcademicLevel();
  }

  onItemDeSelect(item: any) {
    this.changeEmployee_AcademicLevel();
  }

  onItemSelect(item: any) {
    this.changeEmployee_AcademicLevel();
  }

  onSelectAll(item: any) {
    this.selectedItems = item;
    this.changeEmployee_AcademicLevel()
  }

  onDeSelectAllDepart() {
    this.selectedItemsDeparments = [];
    this.chageEmployee_AcademicLevelByDeparment();
  }

  onItemDeSelectDepart(item: any) {
    this.chageEmployee_AcademicLevelByDeparment();
  }

  onItemSelectDepart(item: any) {
    this.chageEmployee_AcademicLevelByDeparment();
  }

  onSelectAllDepart(item: any) {
    this.selectedItemsDeparments = item;
    this.chageEmployee_AcademicLevelByDeparment()
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

}
