import { BadgeComponent } from './element-ui/badge/badge.component';
import { DropdownSideMenuComponent } from './element-ui/dropdown-side-menu/dropdown-side-menu.component';
import { ModalComponent } from './element-ui/modal/modal.component';
import { ProgresBarComponent } from './element-ui/progres-bar/progres-bar.component';
import { SpinnerComponent } from './element-ui/spinner/spinner.component';
import { CaptionComponent } from './element-ui/table/caption/caption.component';
import { DropdownComponent } from './element-ui/dropdown/dropdown.component';
import { SwitchComponent } from './element-ui/switch/switch.component';
import { MultiSelectComponent } from './element-ui/multi-select/multi-select.component';
import { SearchBarComponent } from './element-ui/search-bar/search-bar.component';
import { DynamicSelectComponent } from './element-ui/dynamicselect/dynamic-select.component';
import { TableComponent } from './element-ui/table/table.component';
import { InputComponent } from './element-ui/input/input.component';
import { DynamicFormatComponent } from './dynamic-format/dynamic-format.component';
import { ErrorsComponent } from './element-ui/errors/errors.component';
import { DropdownMultiSelectComponent } from './element-ui/dropdown-multi-select/dropdown-multi-select.component'
import { ChartComponent as NgChartComponent } from 'ng-apexcharts';

import { BarChartRaceComponent } from './bar-chart-race/bar-chart-race.component';
import { ChartDnutComponent } from './chart-dnut/chart-dnut.component';
import { ChartSortedBarComponent } from './chart-sorted-bar/chart-sorted-bar.component';
import { ChartsourceComponent } from './chartsource/chartsource.component';
import { ChrtAreaRadarComponent } from './chrt-area-radar/chrt-area-radar.component';
import { FilterDashboardComponent } from './filter-dashboard/filter-dashboard.component';
import { SemiCircleComponent } from './semi-circle/semi-circle.component';
import { StackedChartComponent } from './stacked-chart/stacked-chart.component';
import { ChartComponent } from './chart/chart.component';
import { ChartClusteredBarComponent } from './chart-clustered-bar/chart-clustered-bar.component';
// import { DonutChartComponent } from './element-ui/donut-chart/donut-chart.component';
import { ChartPieComponent } from './chart-pie/chart-pie.component';
import { ControlDocumentComponent } from './control-document/control-document.component';
import { ModalLayoutComponent } from './modal-layout/modal-layout.component';
import { UppyFileManagerComponent } from './uppy-file-manager/uppy-file-manager.component';


export const Component = [
  ModalComponent,
  BadgeComponent,
  ProgresBarComponent,
  SpinnerComponent,
  DropdownSideMenuComponent,
  CaptionComponent,
  DropdownComponent,
  SwitchComponent,
  MultiSelectComponent,
  SearchBarComponent,
  DynamicSelectComponent,
  InputComponent,  
  TableComponent,
  DynamicFormatComponent,
  ErrorsComponent,
  DropdownMultiSelectComponent,
  // DonutChartComponent
  
  DynamicFormatComponent,
  FilterDashboardComponent,
  ChartSortedBarComponent,
  ChartDnutComponent,
  ChrtAreaRadarComponent,
  ChartsourceComponent,
  StackedChartComponent,
  SemiCircleComponent,
  BarChartRaceComponent,
  ChartComponent,
  DropdownMultiSelectComponent,
  ChartClusteredBarComponent,
  ChartPieComponent,
  ControlDocumentComponent,
  ModalLayoutComponent,
  UppyFileManagerComponent
  // NgChartComponent

];
