export enum TypeVerificationAccess {
    ANONYMOUS = "ANONYMOUS",
    USERPASSWORD = "USERPASSWORD"
}

export class SignRequest {
    sender: Sender;
    addresseeLines: Addresseeline[];
    internalNotification: string[];
    subject: string;
    message: string;
    reference: string;
    initDate: string;
    rejectInfo: RejectInfo;
    expirationDate: string;
    verificationAccess: Verificationaccess;
    senderNotificationLevel: string;
    signatureLevel: string;
    notificationUrl: string;
    useDefaultStamp: boolean;
    metadatas: Metadata[];
    documentsToSign: Documentstosign[];
    documentsAnnexes: Documentsannex[];
    disableInboxEmailNotifications: boolean;
    status?: string;
    publicAccessId?: string;
    constructor(){
        this.reference= "FIRMGOB-SIGEI";
        this.verificationAccess = new Verificationaccess();
        this.senderNotificationLevel= "ALL";
        this.signatureLevel= "CERTIFICATE_ONLY";
        this.notificationUrl= "";
        this.useDefaultStamp= true;
        this.metadatas = []
        this.addresseeLines = [];
        this.documentsAnnexes = [];
        this.documentsToSign = [];
        this.disableInboxEmailNotifications = false;
    }
    
}

export class RejectInfo {
    rejectUser: string;
    rejectType: string;
    rejectReason: string;
    rejectDate: number;
}


export class Sender {
    userCode: string;
    entityCode: string;
    constructor(){
      
    }
}

export class Verificationaccess {
    type: string;
    username?: string;
    password?: string;
}

export class Addresseeline {
    addresseeGroups: Addresseegroup[];
}

export class Addresseegroup {
    isOrGroup: boolean;
    userEntities: Userentity[];
    constructor(){
        this.userEntities = [];
        this.isOrGroup = false;
    }
}

export class Userentity {
    userCode: string;
    entityCode: string;
    action: string;
}

export class Metadata {
    key: string;
    value: string;
}

export class DocumentRequest{
    base64: string;
    filename: string;
}

export class Documentstosign {
    publicAccessId?: string;
    template?: Template;
    filename: string;
    url: string;
}

export class Template {
    code: string;
    vars: Var[];
}

export class Var {
    key: string;
    value: string;
}

export class Documentsannex {
    template: Template1;
    filename: string;
    url: string;
}

export class Template1 {
    code: string;
    vars: Var[];
}