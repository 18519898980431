<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-recursos-humanos-1 icon-3x text-danger mr-2"></i>
                <h1 class="text-primary"> Consulta Vacaciones Colectivas </h1>
            </div>
        </div>
    </div>
    <div class="card-header mt-3 row justify-content-between align-items-center">
        <div class="col-sm-12 col-xxl-7">
            <div *ngIf="privilege?.filter?.value && useCollectiveVacations" [formGroup]="filterForm"
                class="row card-toolbar">
                <div class="col-md-4 px-0 mb-3">
                    <div class="form-group mx-2 institution">
                        <div>
                            <label for="">
                                Fecha Inicio<span class="text-danger text-monospace legend">* </span>
                            </label>
                        </div>
                        <input formControlName="startDate" (change)="onStartDateChange()" placeholder="Fecha de ingreso"
                            type="date"
                            class="form-control form-control-lg dynamicFormControl ng-pristine ng-valid ng-touched">
                    </div>
                </div>
                <div class="col-md-4 px-0 mb-3">
                    <div class="form-group mx-2 institution">
                        <div>
                            <label for="">
                                Fecha Fin<span class="text-danger text-monospace legend">* </span>
                            </label>
                        </div>
                        <input formControlName="endDate" placeholder="Fecha de ingreso" type="date"
                            class="form-control form-control-lg dynamicFormControl ng-pristine ng-valid ng-touched"
                            [min]="this.filterForm.get('startDate').value">
                    </div>
                </div>

                <div class="col d-flex align-items-center px-0 mb-3">
                    <button (click)="getVacationsList()" [disabled]="filterForm.invalid"
                        class="btn btn-success btn-pill font-weight-bolder col-md-auto">
                        <i class="flaticon2-search-1 icon-2x" data-toggle="tooltip" data-original-title="Filtrar"></i>
                        Buscar
                    </button>
                    <button [disabled]="filterForm.invalid" (click)="resetFilter()"
                        class="btn btn-light-warning btn-pill font-weight-bolder mx-2">
                        <i class="fas fa-redo icon-2x" data-toggle="tooltip" data-original-title="Reiniciar filtro"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12"
                    *ngIf="privilege?.exportAction?.value  && useCollectiveVacations">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white"></i> Exportar
                    </button>
                    <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">

                        <a class="dropdown-item align-items-center" (click)="openReport()">
                            <i class="fa fa-print icon-2x text-primary mr-3"></i>
                            Visualizar Reporte
                        </a>
                    </div>
                </div>

                <div class="col text-nowrap" *ngIf="privilege?.search?.value && useCollectiveVacations">
                    <app-search-bar-pagination (onFilter)="filterSearch($event)" [parameters]="searchBarDisplayNames"
                        [namePageLocalStorage]="localStorageSearch"></app-search-bar-pagination>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-vertical-center caption-top">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th class="text-center">Cantidad de Servidores</th>
                        <th class="text-left">Fecha Inicio</th>
                        <th class="text-left">Fecha Término</th>
                        <th class="text-center">Años al que Pertenece</th>
                        <th class="text-center">Total Días</th>
                        <th class="text-left">Comentario</th>
                        <th class="text-left">Condición</th>
                        <th class="text-center">Acciones</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let vacation of vacationsList">
                        <td class="text-center">{{vacation?.collectiveVacationsHeaderId}}</td>
                        <td class="text-center">{{vacation.quantityEmployees}}</td>
                        <td class="text-left">{{vacation?.startDate |date: 'dd/MM/yyyy' || 'N/A'}}</td>
                        <td class="text-left">{{vacation?.endDate |date: 'dd/MM/yyyy' || 'N/A'}}</td>
                        <td class="text-center">{{vacation?.year || 'N/A'}}</td>
                        <td class="text-center">{{vacation?.totalDays || 'N/A'}}</td>
                        <td class="text-left">{{vacation?.comment || 'N/A'}}</td>
                        <td class="text-left">
                            <span
                                [ngClass]="vacation.conditionId == 2 ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{vacation.condition}}</span>
                        </td>
                        <td class="actionsBtn">
                            <button data-toggle="tooltip" *ngIf="privilege?.view?.value"
                                (click)="openModalPSCV(vacation)" title="" class="btn btn-sm btn-light-info btn-icon">
                                <i class="flaticon-ver" data-toggle="tooltip" data-original-title="Ver Detalles"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <app-paginator [listedRecordsLength]="vacationsList?.length"
                    [registerQuantity]="vacationsPagedResut.totalItems" [registerPerPage]="vacationsPagedResut.pageSize"
                    [totalPages]="vacationsPagedResut.totalPages" [selectedPage]="vacationsPagedResut.page"
                    (onChange)="getPaginatedRecords($event)">
                </app-paginator>
            </div>
        </div>
    </div>

    <export-data-to-pdf [title]="'Consulta Vacaciones Colectivas'" id="dataToPrint">
        <ng-container thead>
            <th class="text-center">Secuencia</th>
            <th class="text-center">Cantidad de Servidores</th>
            <th class="text-left">Fecha Inicio</th>
            <th class="text-left">Fecha Término</th>
            <th class="text-center">Años al que pertenece</th>
            <th class="text-center">Total Días</th>
            <th class="text-left">Comentario</th>
            <th class="text-left">Condición</th>
        </ng-container>
        <ng-container tbody>
            <tr *ngFor="let item of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.vacationsList"
                data-row="0" class="datatable-row" style="left: 0px;">
                <td class="text-center">{{item?.collectiveVacationsHeaderId}}</td>
                <td class="text-center">{{item.quantityEmployees}}</td>
                <td class="text-left">{{item?.startDate |date: 'dd/MM/yyyy' || 'N/A'}}</td>
                <td class="text-left">{{item?.endDate |date: 'dd/MM/yyyy' || 'N/A'}}</td>
                <td class="text-center">{{item?.year || 'N/A'}}</td>
                <td class="text-center">{{item?.totalDays || 'N/A'}}</td>
                <td class="text-left">{{item?.comment || 'N/A'}}</td>
                <td class="text-left">
                    <span
                        [ngClass]="item.conditionId == 2 ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">
                        {{item.condition}}
                    </span>
                </td>
            </tr>
        </ng-container>
    </export-data-to-pdf>
