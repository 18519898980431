import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { userGuideNames } from 'src/app/models/userGuideEnum';
import { UserGuideService } from 'src/app/services/user-guide.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.css']
})
export class UserGuideComponent implements OnInit {

  userGuideName: string = ''
  private api_lobby = environment.api_core_sigei;
  userGuideUrl: SafeResourceUrl = null;


  constructor(
    private sanitizer: DomSanitizer,
    private _toastService: ToastService,
    private route: ActivatedRoute,
  ) {
    this.userGuideUrl = null
    route.params.subscribe({
      next: (res:any) => {
        this.userGuideName = null
        this.userGuideName = res.userGuideName

        if (this.userGuideName.length <= 0) {
          this._toastService.warning('No se encontró un archivo para mostrar por favor comunicarse con Help Desk', 'No se encontró el manual')
          return
        }
        if(this.routeExists(this.userGuideName)){
          this.userGuideUrl = this.transform(`${this.api_lobby}/UserGuide?UserGuideName=${this.userGuideName}`);
        }
      }
    })
    
  }


  ngOnInit(): void {
    
  }

  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  routeExists(route){
    const guidesNames = userGuideNames
    if(guidesNames.find(x => x.name == route)){
      return true
    }else{
      return false
    }
  }
}
