import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { docService } from 'src/app/services/doc.service';

@Component({
  selector: 'app-requsition-summary',
  templateUrl: './requsition-summary.component.html',
  styleUrls: ['./requsition-summary.component.css']
})
export class RequsitionSummaryComponent implements OnInit {

  id: number;
  photoUrl: string;
  isLoading: boolean;
  fileModalConfig = {
    src: '',
    type: '',
    filename: '',
    width: 225,
    height: 225,
    formTitle: '',
    formSubTitle: '',
  };

  public company: any;
  file = { logo: '', image1: '', image2:'' }

  constructor(public loadingService: LoadingService, private location: Location, private toastr: ToastrService,
    private srvDoc: docService,
    private _companiesService: CompaniesService,
    private authInfo: AuthInfoService) { }

  ngOnInit(): void {
    
    this.companyById(this.authInfo.getCompanyId())
  }
  

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this.toastr.error(error)
      })
    } catch (error) {
      this.toastr.error(error)
    }
  }

  goBack(){
    this.location.back();
  }
}
