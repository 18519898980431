import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ReportService } from 'src/app/services/report.service';
import { Location } from '@angular/common';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';

@Component({
  selector: 'app-age-children-employees-report',
  templateUrl: './age-children-employees-report.component.html',
  styleUrls: ['./age-children-employees-report.component.css']
})
export class AgeChildrenEmployeesReportComponent implements OnInit {


  agechildren:any

  form = this.fb.group({
    option: ['', Validators.required]
  })

  constructor(
                private services : ReportService,
                private location: Location,
                private _authInfo: AuthInfoService,
                private fb: FormBuilder,
                private _toastService: ToastService,
              ) { }

  ngOnInit(): void {
    this.getAgeChildren();
   }


  getDropdownConfig(displayKey: string){
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  getAgeChildren(){
    this.services.getReportRegistationControl().subscribe({
      next:(resp:any)=>{
        if (!resp.succeded) {
          this._toastService.error(resp.errors[0])
          return;
        }
        this.agechildren = resp.dataList
      }, error: (err) => {
        this._toastService.error('Ha ocurrido un error inesperado')
      }
    })
  }

  generate(){
    let ageChildren = this.form.value.option
    if(this.form.invalid){
      this._toastService.warning('Favor de seleccionar una opción.')
      return

    }
    let url = environment.reportUrl + `?ReportCode=rrhh_children_ages&Type=${ageChildren.type}`
    openReport({url, title: 'Title', width: '900', height: '700'})
  }

  goBack() {
    this.location.back();
  }

}
