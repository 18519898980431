export class PersonModel {
    employeeSupervisor?: string;
    personId: number;
    name?: string;
    positionName?: string;
    departmentName?: string;
    supervisor?: string;
    admissionDate?: string;
    salary?: number;
    fullName: string;
    firstName: string;
    lastName: string;
    birthday: Date;
    sexId: number;
    nationalityId: number;
    typeIdentificationId: number;
    personalIdentification: string;
    countryId: number;
    provinceId: number;
    municipalityId: number;
    sectorId: number;
    street: string;
    numberhouse?: number;
    buildingName: string;
    apartmentNumber: string;
    personalReview: string;
    achievements: string;
    strengths: string;
    pointsImprove: string;
    phoneNumber: string;
    email: string;
    academicLevel: string;
    recommendation: boolean;
    namePersonRecommended: string;
    phonePersonRecommended: string;
    pnstitutionPersonRecommended: string;

    maritalStatusId: number;
    spouseName: string;
    spouseTypeIdentificationId: string;
    spouseIdentification: string;
    spousePhone: string;

    spousePhoto: number;
    userName: string;
    pasword: string;
    arsid: number;
    companyId: number;
    status: boolean;
    academicLevelId: number;
    afpid: number;

    photoloaded: string
    profileImage?: string;
    institionalEmail?: string;

    typeEmployeeId?: number;
}