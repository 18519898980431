import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { CertificationCodeComponent } from './certification-code/certification-code.component';
import { CertificationCodeService } from 'src/app/services/certification-code.service';
import { CatalogResponseCertificationAccount } from './models/certification-codes.model';
import { ToastrService } from 'ngx-toastr';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import Swal from 'sweetalert2';
import { DeleteCertificationCodeComponent } from './delete-certification-code/delete-certification-code.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
declare function applySort(): any;

@Component({
  selector: 'app-certification-codes',
  templateUrl: './certification-codes.component.html',
  styleUrls: ['./certification-codes.component.css']
})

export class CertificationCodesComponent implements OnInit, AfterViewChecked {
  filterText: string = '';
  selectedItems: any[] = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 0, item_text: 'Inactivos' },
  ];

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'CERTIFICATION-CODES',
    exportAction: { key: 'Export', value: false },
    addAction: { key: 'Add', value: false },
    deleteAction: { key: 'Delete', value: false },
    editAction: { key: 'Edit', value: false },
    viewAction: { key: 'View', value: false },
    searchAction: { key: 'Search', value: false }
  }

  @ViewChild('paginator') paginator: CaptionComponent;

  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propKeyId: number, propkey?: string, displayName: string }[] =
    [
      { propKeyId: 1, propkey: "catalogResponseCertificationAccountId", displayName: "Secuencia" },
      { propKeyId: 2, propkey: "errorCode", displayName: "Código" },
      { propKeyId: 3, propkey: "shortDescription", displayName: "Descripción Corta" },
      { propKeyId: 4, propkey: "technicalDescription", displayName: "Descripción Técnica" },
    ];

  certificationCodes: CatalogResponseCertificationAccount[] = [];
  certificationCodesOriginalList: CatalogResponseCertificationAccount[] = [];
  certificationCodesRecords: CatalogResponseCertificationAccount[] = [];

  selectedPage: any
  searchPropKeySelected!: { propKeyId?: number, propkey?: string, displayName: string };

  constructor(private dialog: MatDialog, private _changeDet: ChangeDetectorRef,
    private _toastService: ToastrService, private _exlService: ExportToExcelService,
    public authInfo: AuthInfoService,
    private certificationCodeService: CertificationCodeService) { }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.getCertificationCodes();
      }
    });
    applySort();
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  getCertificationCodes() {
    this.certificationCodeService.getCertificationCodes().subscribe({
      next: res => {
        if (res?.errors?.length > 0) {
          this._toastService.error(res.errors[0]);
          return;
        }
        this.certificationCodes = this.filterTable(res?.dataList);
        this.certificationCodesOriginalList = res?.dataList;
      }, error: res => {
        this._toastService.error('Ha ocurrido un error tratando de consultar los Códigos de Certificación');
      }
    });
  }


  onKeyParameterChange(value: { propKeyId?: number, propkey: string, displayName: string } | null) {
    this.searchPropKeySelected = value ?? null;
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
    this.filteredList.records = this.filterTable(event.records)
  }

  onItemSelect(item: any) {
    this.filter();
  }

  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  onItemDeSelect(item: any) {
    this.filter();
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }

  filter() {
    document.getElementById('searchBarBtn').click();
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.certificationCodes = this.filterTable(this.certificationCodesOriginalList)
    }
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl.find(x => x.item_id == 1) && !sl.find(x => x.item_id == 0)) {
      list = list.filter(x => x.status == true);
    }
    if (sl.find(x => x.item_id == 0) && !sl.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    return list;
  }

  onKeyChange(text) {
    this.filterText = text;
  }




  // exportToExcel(){
  //   let data = []
  //   if (this.filteredList.enable) {
  //     data = this.filteredList.records
  //   } else {
  //     data = this.certificationCodes
  //   }
  //   data.forEach(element => {
  //     element.statusText = element.status ? 'Activo' : 'Inactivo'
  //   });
  //   let excelHeaders: string[][] = [[
  //     "Secuencia",
  //     "Código",
  //     "Descripción Corta",
  //     "Descripción Técnica",
  //     "Estatus",
  //   ], [
  //     "catalogResponseCertificationAccountId",
  //     "errorCode",
  //     "shortDescription",
  //     "technicalDescription",
  //     "statusText"
  //   ]]
  //   this._exlService.exportToExcelSpecificColumns(data, excelHeaders, 'Lista de Códigos Certificación', true);
  // }


  getPaginatedRecords(event) {
    this.certificationCodesRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.selectedPage = event.selectedPage
  }

  openModal(item: CatalogResponseCertificationAccount = null, action: number = 1) {
    this.dialog.open(CertificationCodeComponent, {
      data: {
        catalogRespons: item,
        action: action
      },
      width: '40%'
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (res?.created) {
          this.getCertificationCodes();
        }
      }
    });
  }

  deleteCode(code: CatalogResponseCertificationAccount) {
    this.dialog.open(DeleteCertificationCodeComponent, {
      data: {
        catalogRespons: code
      },
      width: '30%'
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (res?.created) {
          this.getCertificationCodes();
        }
      }
    });
  }

  exportToPdf() {
    const reportCode: ReportCode = ReportCode.ReportAccountCertification;
    const companyId: number = this.authInfo.getCompanyId();
    const status: any = this.selectedItems?.length === 2 || this.selectedItems?.length == 0 ? '0-1' : (this.selectedItems[0]?.item_id === 1 ? 1 : 0);
    const pageSize = this.certificationCodesRecords.length
    const propKeyId = this.searchPropKeySelected?.propKeyId ?? 0
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&Status=${status}&PropkeyId=${propKeyId}&SearchParameter=${this.filterText ?? ''}&Page=${this.selectedPage}&PageSize=${pageSize}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Códigos Certificación',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

}
