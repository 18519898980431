<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Historial de modificaciones
    </h2>
    <button type="button" (click)="close()" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>    
</div>

<div class="mat-dialog-content-custom">
    <div class="card card-custom">
        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-12 col-xl-12 col-xxl-6 c-left">
                <div class="card-toolbar d-flex justify-content-start">
                    <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4 mr-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <h2 class=" font-size-h5 text-primary">
                                Datos actuales
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-xl-12 col-xxl-6 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a (click)="exportReport()" class="dropdown-item align-items-center">
                              <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                              Visualizar Reporte
                          </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th>Código</th>
                        <th>Concepto</th>
                        <th>Nombre Corto</th>
                        <th class="text-center">Orden de Ejecución</th>
                        <th>Tipo de Concepto</th>
                        <th>Tipo de Novedad</th>
                        <th>Beneficiario</th>
                        <th>CCP</th>
                        <th class="text-center">¿Aplica para TSS? </th>
                        <th class="text-center">¿Aplica para ISR? </th>
                        <th class="text-center">¿Funciona como Salario? </th>
                        <th class="text-center">¿Es un Débito?</th>
                        <th class="text-center">¿Puede ser Mayor al Salario? </th>
                        <th>Creado Por</th>
                        <th>Fecha Creación</th>
                        <th>Modificado por</th>
                        <th>Fecha Modificacion</th>
                        <th>Estatus</th>
                    </tr>
                </thead>
                <tbody>
                    <tr data-row="0" class="datatable-row" style="left: 0px;">
                    <td class="text-center">{{data.item.newsConceptId}}</td>
                    <td>{{data.item.code}}</td>
                    <td>{{data.item.concept}}</td>
                    <td>{{data.item.shortName || 'N/A'}}</td>
                    <td class="text-center">{{data.item.executionOrder}}</td>
                    <td>{{data.item.conceptType}}</td>
                    <td>{{data.item.newsType}}</td>
                    <td>{{data.item.conceptBeneficiaryDesc}}</td>
                    <td>{{data.item.ccp}}</td>
                    <td class="text-center">
                      <span>{{data.item.isTss ? "Si" : "No"}}</span>
                    </td>
                    <td class="text-center">
                      <span>{{data.item.isIsr ? "Si" : "No"}}</span>
                    </td>
                    <td class="text-center">
                      <span>{{ data.item.isSalary ? "Si" : "No"}}</span>
                    </td>
                    <td class="text-center" >
                      <span>{{data.item.isDebit ? "Si" : "No"}}</span>
                    </td>
                    <td class="text-center" >
                      <span>{{data.item.higherThanSalary ? "Si" : "No"}}</span>
                    </td>
                    <td class="text-center">
                        <span>{{ data.item.createUsername }}</span>
                    </td>
                    <td class="text-center">
                        <span>{{data.item.createDate | date:'short' }}</span>
                    </td>
                    <td class="text-center">
                        <span>{{ data.item.modifyUsername }}</span>
                    </td>
                    <td class="text-center">
                        <span>{{data.item.modifyDate | date:'short' }}</span>
                    </td>
                    <td>
                        <span [ngClass]="data.item.status ? 'text-success': 'text-danger'" class="text-success">{{ data.item.status ? "Activo" : "Inactivo"}}</span>
                    </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="card card-custom">
        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-12 col-xl-12 col-xxl-6 c-left">
                <!-- <div class="card-toolbar d-flex justify-content-start"></div> -->
                <h2 class=" font-size-h5 text-primary">
                    Historico de cambios
                </h2>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th>Código</th>
                        <th>Concepto</th>
                        <th>Nombre Corto</th>
                        <th class="text-center">Orden de Ejecución</th>
                        <th>Tipo de Concepto</th>
                        <th>Tipo de Novedad</th>
                        <th>Beneficiario</th>
                        <th>CCP</th>
                        <th class="text-center">¿Aplica para TSS? </th>
                        <th class="text-center">¿Aplica para ISR? </th>
                        <th class="text-center">¿Funciona como Salario? </th>
                        <th class="text-center">¿Es un Débito?</th>
                        <th class="text-center">¿Puede ser Mayor al Salario? </th>
                        <th>Creado Por</th>
                        <th>Fecha Creación</th>
                        <th>Modificado por</th>
                        <th>Fecha Modificacion</th>
                        <th>Estatus</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let historyConcept of concepts" data-row="0" class="datatable-row" style="left: 0px;">
                    <td>{{ historyConcept.code }}</td>
                    <td>{{ historyConcept.concept }}</td>
                    <td>{{ historyConcept.shortName || 'N/A' }}</td>
                    <td class="text-center">
                        {{ historyConcept.executionOrder }}
                    </td>
                    <td>{{ historyConcept.conceptType }}</td>
                    <td>{{ historyConcept.newsType }}</td>
                    <td>{{ historyConcept.conceptBeneficiaryDesc }}</td>
                    <td>{{ historyConcept.ccp }}</td>
                    <td class="text-center">
                      <span>{{ historyConcept.isTss ? "Si" : "No" }}</span>
                    </td>
                    <td class="text-center">
                      <span>{{ historyConcept.isIsr ? "Si" : "No" }}</span>
                    </td>
                    <td class="text-center">
                      <span>{{ historyConcept.isSalary ? "Si" : "No" }}</span>
                    </td>
                    <td class="text-center" >
                      <span>{{ historyConcept.isDebit ? "Si" : "No" }}</span>
                    </td>
                    <td class="text-center" >
                      <span>{{ historyConcept.higherThanSalary ? "Si" : "No" }}</span>
                    </td>
                    <td class="text-center">
                        <span>{{ historyConcept.createUsername }}</span>
                    </td>
                    <td class="text-center">
                        <span>{{ historyConcept.createDate | date:'short' }}</span>
                    </td>
                    <td class="text-center">
                        <span>{{ historyConcept.modifyUsername }}</span>
                    </td>
                    <td class="text-center">
                        <span>{{ historyConcept.modifyDate | date:'short' }}</span>
                    </td>
                    <td>
                        <span [ngClass]="historyConcept.status ? 'text-success': 'text-danger'" class="text-success">{{ historyConcept.status ? "Activo" : "Inactivo"}}</span>
                    </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <app-paginator [listedRecordsLength]="concepts?.length"
                    [registerQuantity]="payrollConceptPagedResut.totalItems"
                    [registerPerPage]="payrollConceptPagedResut.pageSize" [totalPages]="payrollConceptPagedResut.totalPages"
                    [selectedPage]="payrollConceptPagedResut.page" (onChange)="getPaginatedRecords($event)">
                </app-paginator>
            </div>
        </div>
    </div>
</div>