<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="fs-3 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px "></span> Dependientes
    </h2>
</div>

<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <form [formGroup]="dependentsForm" class="row">
            <div class="form-group col-md-6">
                <label class="">Nombre del familiar <span class="text-danger">*</span></label>
                <input formControlName="depedentName"  (input)="removeWhitespaces($event, dependentsForm.get('depedentName'))"  class="form-control form-control-solid form-control-lg" id="tel"
                    type="text" label="Teléfono">

                <errors-component [controlName]="dependentsForm.controls['depedentName']"></errors-component>
            </div>

            <div class="form-group col-md-6">
                <label for="number">Parentesco <span class="text-danger">*</span></label>
                <ngx-select-dropdown formControlName="relationShip" [options]="relationshipsList"
                    [config]='dropdownConfig("description")'></ngx-select-dropdown>

                <errors-component [controlName]="dependentsForm.controls['relationSip']"></errors-component>
            </div>

            <div class="form-group col-md-6">
                <label for="number">Estado Civil <span class="text-danger">*</span></label>
                <ngx-select-dropdown formControlName="maritalStatus" [options]="maritalStatusList"
                    [config]='dropdownConfig("stringData")'></ngx-select-dropdown>

                <errors-component [controlName]="dependentsForm.controls['maritalStatus']"></errors-component>
            </div>

            <div class="form-group col-md-3">
                <label class="">Fecha de Nacimiento <span class="text-danger">*</span></label>
                <input formControlName="birthDay" (change)="onBirthdayChange($event.target.value)" class="form-control form-control-solid form-control-lg" type="date">

                <errors-component [controlName]="dependentsForm.controls['birthDay']"></errors-component>
            </div>

            <div class="form-group col-md-3">
                <label class="">Edad <span class="text-danger">*</span></label>
                <input readonly formControlName="age" class="form-control form-control-solid form-control-lg">

            </div>

            <div class="form-group col-md-4">
                <label for="" class="d-block">¿Trabaja? <span class="text-danger">*</span></label>
                <div class="form-check form-check-inline">
                    <label class="form-check-label mt-2 check-dtl">
                        <input [value]="true" formControlName="isWorking" class="form-check-input" type="radio">
                        Sí
                    </label>
                </div>
                <div class=" form-check form-check-inline">
                    <label class="form-check-label mt-2 check-dtl">
                        <input [value]="false" formControlName="isWorking" class="form-check-input" type="radio"> No
                    </label>
                </div>
            </div>
            <div class="form-group col-md-4">
                <label for="" class="d-block">¿Estudia? <span class="text-danger">*</span></label>
                <div class="form-check form-check-inline">
                    <label class="form-check-label mt-2 check-dtl">
                        <input [value]="true" formControlName="isStudying" class="form-check-input" type="radio">
                        Sí
                    </label>
                </div>
                <div class=" form-check form-check-inline">
                    <label class="form-check-label mt-2 check-dtl">
                        <input [value]="false" formControlName="isStudying" class="form-check-input" type="radio"> No
                    </label>
                </div>
            </div>
            <div class="form-group col-md-4">
                <label for="" class="d-block">¿Es Dependiente? <span class="text-danger">*</span></label>
                <div class="form-check form-check-inline">
                    <label class="form-check-label mt-2 check-dtl">
                        <input [value]="true" formControlName="isDependent" class="form-check-input" type="radio">
                        Sí
                    </label>
                </div>
                <div class=" form-check form-check-inline">
                    <label class="form-check-label mt-2 check-dtl">
                        <input [value]="false" formControlName="isDependent" class="form-check-input" type="radio"> No
                    </label>
                </div>
            </div>

            <div class="form-group col-md-6">
                <label for="number">Lugar de trabajo </label>
                <input formControlName="workPlace" class="form-control form-control-solid form-control-lg" type="text"
                    autocomplete="0">

                <errors-component [controlName]="dependentsForm.controls['workPlace']"></errors-component>

            </div>
            <div class="form-group col-md-6">
                <label for="number">Lugar de estudio </label>
                <input formControlName="studyingPlace" class="form-control form-control-solid form-control-lg"
                    type="text" autocomplete="0">

                <errors-component [controlName]="dependentsForm.controls['studyingPlace']"></errors-component>

            </div>
        </form>
    </div>
</div>

<div class=" mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cancelar
    </button>
    <button [disabled]="dependentsForm.invalid"
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button" (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Aceptar</span>
    </button>
</div>
