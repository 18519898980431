import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styles: [
  ]
})
export class FrequentQuestionsComponent implements OnInit {
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' },
  ];
  searchList: any[] = []; searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "descriptionQuestion", displayName: "Descripción de la pregunta" },
      { propkey: "nameProcess", displayName: "Proceso" },
    ];

  questions: any[] = [
    {
      id: 1,
      question: '¿Como realizar un tiket?',
      tags: [
        'HelpDesk',
        'Tickets',
        'Ayuda',
        'Soporte'
      ]
    },
    {
      id: 2,
      question: '¿Como se gestionan los tikects?',
      tags: [
        'HelpDesk',
        'Tickets',
        'Ayuda',
        'Soporte'
      ]
    },
    {
      id: 3,
      question: '¿Cuales son los servicios que puedo solicitar en los tickets?',
      tags: [
        'HelpDesk',
        'Tickets',
        'Ayuda',
        'Soporte'
      ]
    },
    {
      id: 4,
      question: '¿Que significa SLA y como se maneja?',
      tags: [
        'HelpDesk',
        'Tickets',
        'Ayuda',
        'Soporte'
      ]
    },
    {
      id: 5,
      question: '¿Como confirmar mi cuenta institucional?',
      tags: [
        'Mi Cuenta',
        'Perfil',
      ]
    },
    {
      id: 6,
      question: '¿Como cambio mi contraseña y datos de mi perfil?',
      tags: [
        'Mi Cuenta',
        'Perfil',
      ]
    },
    {
      id: 7,
      question: '¿Cual es la organización de la institución donde trabajo?',
      tags: [
        'Recursos humanos',
        'Cargo asignado',
      ]
    },
    {
      id: 8,
      question: '¿Que significa SLA y como se maneja?',
      tags: [
        'HelpDesk',
        'Tickets',
        'Ayuda',
        'Soporte'
      ]
    },
    {
      id: 9,
      question: '¿Como confirmar mi cuenta institucional?',
      tags: [
        'Mi Cuenta',
        'Perfil',
      ]
    },
    {
      id: 10,
      question: '¿Como cambio mi contraseña y datos de mi perfil?',
      tags: [
        'Mi Cuenta',
        'Perfil',
      ]
    },
  ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  constructor() { }

  ngOnInit(): void {
  }

}
