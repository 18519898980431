import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { AccountService } from 'src/app/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  public redirectUrl: string;

  constructor(private jwtHelper: JwtHelperService, 
    private router: Router, 
    private authService: AuthInfoService, 
    private accountService: AccountService,
    private toastService: ToastrService,
    private dataService: DataService) { }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return await this.canActivate(childRoute,state);
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)  {
    
    const languageParam = state.root.queryParams["language"];

    const tokenParam = (languageParam != 'null' && languageParam != 'undefined') ? languageParam : null;

    const globalNameKey = route.data['nameKey'];

    await this.authService.checkAndRefreshAuthentication();

    const isAuthenticated: boolean = this.authService.isAuthenticated();

    var showView: boolean = true;

    if(!tokenParam && !isAuthenticated){
      this.toastService.warning('Estimado usuario, para acceder a este sistema debe estar autenticado', 'Advertencia');
      this.authService.redirectUrl = state.url;
      this.accountService.logoutWithAuth();
      return false;
    }

    const currentToken: string | null = this.authService.getToken();

    if(tokenParam && tokenParam != currentToken){

      localStorage.setItem('token', tokenParam);

      await this.accountService.validateToken(tokenParam) .toPromise().then(async res => {

        if (!res.succeded) {
          res.warnings.forEach(warn=>{
            this.toastService.warning(warn);
          })
          showView = false;
          this.accountService.logoutWithAuth();
          return;
        }

        //lleno el local storage si el token esta correct
        let dataAccount = res?.singleResultData;

        dataAccount['jwtToken'] = tokenParam;

        localStorage.setItem('Usr-Account', JSON.stringify(dataAccount));
        const accountData = this.dataService.GetUserData();
        this.dataService.account$.emit(accountData);

      }).catch(error => {
        this.toastService.error("Ha ocurrido un error tratando de validar la sesión del usuario");
        showView = false;
      });

    }

    //if token parameter is in the route, is going to be removed, navigate to it, then AuthGuard will be executed again but without this code block, so the user permissions could be checked
    if(tokenParam){

      const url: string = state.url?.split('?')[0] ?? state.url;

      this.router.navigate([url], {
        queryParams: {
          ...state.root.queryParams,
          language: null
        },
        queryParamsHandling: 'merge'
      });
      
      return;
    }

    await this.authService.canUseModule(globalNameKey).then(async result=>{
      if (!result) {

        showView = false;

        let timerInterval: any;

        Swal.fire({
          title: 'Usted no tiene acceso a este modulo',
          html: 'Sera redireccionado en <b></b> segundos.',
          timer: 5000,
          timerProgressBar: true,
          allowOutsideClick: false,
          showCloseButton: false,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0)
            }, 100)
          },
          willClose: () => {
            this.accountService.logoutWithAuth();
            clearInterval(timerInterval)
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            
          }
        })
        
      }

    }).catch(error => {
      this.toastService.error("Ha ocurrido un error tratando de obtener los permisos del usuario");
      showView = false;
    });


    

    return showView;

  }


}
