import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

interface City {
    item_id: number,
    item_text:string
}

@Component({
  selector: 'app-dropdown-multi-select',
  templateUrl: './dropdown-multi-select.component.html',
  styleUrls: ['./dropdown-multi-select.component.css']
})
export class DropdownMultiSelectComponent implements OnInit {

  myForm:FormGroup
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cities: City[] = [];
  selectedItems: City[] = [];
  dropdownSettings: IDropdownSettings = {};
  constructor(private fb: FormBuilder) {
}

ngOnInit() {

    this.cities = [
        { item_id: 1, item_text: 'Activos' },
        { item_id: 2, item_text: 'Inactivos' },
        { item_id: 3, item_text: 'Aprobados' },
        { item_id: 4, item_text: 'Declinados' },
        { item_id: 5, item_text: 'Anulados' }
    ];
    //this.selectedItems = [{ item_id: 4, item_text: 'Pune' }, { item_id: 6, item_text: 'Navsari' }];
    this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Seleccionar todo',
        unSelectAllText: 'Deseleccionar todo',
        enableCheckAll: true,
        itemsShowLimit: 2,
        allowSearchFilter: false,
        limitSelection: -1
    };
    this.myForm = this.fb.group({
        city: [this.selectedItems]
    });
  }


  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  toogleShowFilter() {
      this.ShowFilter = !this.ShowFilter;
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
      if (this.limitSelection) {
          this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
      } else {
          this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
      }
  }
}





