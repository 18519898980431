import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import { IDashboardOptions } from './vacancies-list-dashboard.service';
enum chartTypes{
  gender=5,
  total=4,
  score=8,
  typeTest=9
}
@Injectable({
  providedIn: 'root'
})
export class ProofDashboardService {

  private readonly api = environment.api_url
  constructor(
    private http: HttpClient,
    private authInfo: AuthInfoService
  ) { }
  
  getDashboardData(dateFrom, dateTo, companyId = this.authInfo.getCompanyId()){
    let year = new Date().getFullYear()
    dateFrom = dateFrom ? dateFrom : `${year}-01-01`;
    dateTo = dateTo ? dateTo : `${year}-12-31`; 
    return this.http.get(`${this.api}/PersonsPostulationVacants/DashboardTest?Status=true&CompanyId=${companyId}&DateFrom=${dateFrom}&DateTo=${dateTo}`)
  }

}
