import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { AdditionalSettingsComponent } from '../taps-concept/additional-settings/additional-settings.component';
import { GeneralComponent } from '../taps-concept/general/general.component';
import { PayrollConceptService } from 'src/app/services/payroll-concept.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ToastrService } from 'ngx-toastr';
import { ConceptSetting } from '@payroll/news-list/models/PayrollNews.model';
import { DefaultValueSettingsComponent } from '../taps-concept/default-value-settings/default-value-settings.component';
import { ConceptValues } from 'src/app/shared/models/conceptValues.model';

@Component({
  selector: 'add-concept-modal',
  templateUrl: './add-concept-modal.component.html',
  styleUrls: ['./add-concept-modal.component.css'],
})
export class AddConceptModalComponent implements OnInit, AfterViewChecked {
  form: FormGroup;
  isNewsTypeAdditionalSelected: boolean = false;
  @ViewChild('generalComponent') generalComponent: GeneralComponent;
  @ViewChild('additionalSettingsComponent') additionalSettingsComponent: AdditionalSettingsComponent;
  @ViewChild('defaultValueSettingsComponent') defaultValueSettingsComponent: DefaultValueSettingsComponent;
  newsConceptsSetting: ConceptSetting;
  requiredValue: boolean = false;
  conceptValues: ConceptValues[] = [];
  constructor(
    public dialogRef :MatDialogRef<AddConceptModalComponent>,
    private srvConcept: PayrollConceptService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private srvAuth: AuthInfoService,
    private toastr: ToastrService,private _changeDet: ChangeDetectorRef,
  ) { this.dialogRef.disableClose = true }
  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  ngOnInit(): void {
    this.isNewsTypeAdditionalSelected = this.data?.item?.newsTypeId === 2;
    this.changeNewsConceptsSetting(this.isNewsTypeAdditionalSelected);
  }


  closeModal(event){
    if(event){
      this.dialogRef.close({success: true})
    }
  }

  close(){
    this.dialogRef.close({success: false});
  }

  private get isValidAdditionalForm(){
    if(this.additionalSettingsComponent?.form?.invalid){
      this.additionalSettingsComponent.formIsInvalid = true;
      return false;
    }
    this.additionalSettingsComponent.formIsInvalid = false;
    return true;
  }


  submit(){
    if(this.generalComponent.isFormInvalid()){
      this.generalComponent.formIsInvalid = true;
      if(this.isNewsTypeAdditionalSelected){
        if(!this.isValidAdditionalForm){
          return;
        }
      }
      return;
    }
    this.generalComponent.formIsInvalid = false;
    if(this.isNewsTypeAdditionalSelected){
      if(!this.isValidAdditionalForm){
        return;
      }
    }
    const model = this.generalComponent.getValueForm();
    const conceptSetting: ConceptSetting = this.additionalSettingsComponent?.getValueNewsConceptSetting();
    if(conceptSetting?.requiredValue){
      model.conceptValuesEntities = this.defaultValueSettingsComponent?.conceptValues;
    }
    if(this.data.inEdit){
      this.updateConcept(model);
    }else{
      this.postConcept(model);
    }

  }

  private updateConcept(model){
    this.srvConcept.updateConcept(model).subscribe({
      next: (res:any) => {
        if (res.succeded == false){
          this.toastr.error(res.errors[0])
          return
        }
        if(this.isNewsTypeAdditionalSelected){
          const conceptSetting: ConceptSetting = this.additionalSettingsComponent?.getValueNewsConceptSetting();
          conceptSetting.newsConceptId = model?.newsConceptId;
          if(conceptSetting?.newsConceptSettingId > 0){
            conceptSetting.modifyDate = new Date();
            conceptSetting.modifyUserId = this.srvAuth.getUserId();
            this.updateNewsConceptSetting(conceptSetting);
          }else{
            this.postNewsConceptSetting(conceptSetting);
          }
        }else{
          this.toastr.success('Datos guardados')
          this.closeModal(true);
        }
      }, error: (error:any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  private postConcept(model){
    this.srvConcept.postConcept(model).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0])
          return
        }
        if(this.isNewsTypeAdditionalSelected){
          const conceptSetting: ConceptSetting = this.additionalSettingsComponent?.getValueNewsConceptSetting();
          conceptSetting.newsConceptId = res?.identity;
          this.postNewsConceptSetting(conceptSetting);
        }else{
          this.toastr.success('Datos guardados')
          this.closeModal(true);
        }
      }, error: (error:any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  private postNewsConceptSetting(newsConceptSetting: ConceptSetting){
    this.srvConcept.postNewsConceptSetting(newsConceptSetting).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0])
          return
        }
        this.toastr.success('Datos guardados')
        this.closeModal(true);
      }, error: (error:any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  private updateNewsConceptSetting(newsConceptSetting: ConceptSetting){
    this.srvConcept.updateNewsConceptSetting(newsConceptSetting).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0])
          return
        }
        this.toastr.success('Datos guardados')
        this.closeModal(true);
      }, error: (error:any) => {
        this.toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  changeNewsConceptsSetting(isNewsTypeAdditionalSelected: boolean){
    this.isNewsTypeAdditionalSelected = isNewsTypeAdditionalSelected;
    if(isNewsTypeAdditionalSelected && this.data?.item?.newsConceptId){
      this.srvConcept.getNewsConceptsSetting(this.data?.item?.newsConceptId).subscribe((res: any) => {
        if(res.errors.length > 0){
          return;
        }
        this.newsConceptsSetting = res?.dataList[0];
      }, error => {
        this.toastr.error("Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.")
      });
    }
  }

}
