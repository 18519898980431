import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalViewsEnum } from 'src/app/models/modal-views';
import { ApplyVacancyModel } from 'src/app/pages/personal-requisition/models/apply-vacancy-model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PlanningRrhhService } from 'src/app/services/planning-rrhh.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { PlanningPlace, PlanningPlaces } from '../../../models/planning';

@Component({
  selector: 'app-vacancy-modal',
  templateUrl: './vacancy-modal.component.html',
  styleUrls: ['./vacancy-modal.component.css']
})
export class VacancyModalComponent implements OnInit {

  isSalaryInvalid: boolean = false
  gridElement: any
  placesDTO = new PlanningPlaces();
  placeDTO: PlanningPlace = {
    planningHeaderId: 0,
    departmentId: 0,
    positionId: 0,
    salary: 0,
    startDate: undefined,
    placeQuantity: 0,
    userId: 0
  };
  placesList: any[] = []
  placesListCopy: any[] = []
  sweetAlrt = new SweetAlert()
  datePipe = new DatePipe('en-Us')
  created: boolean = false
  isEditing: boolean = false

  vacancyForm: FormGroup = new FormGroup({
    department: new FormControl('', Validators.required),
    position: new FormControl('', Validators.required),
    placesQuantity: new FormControl('', Validators.required),
    salary: new FormControl('', Validators.required),
    date: new FormControl('', Validators.required),
  })

  viewsEnum = ModalViewsEnum;
  title: string

  constructor(
    public dialogRef: MatDialogRef<VacancyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      viewMode: number,
      position: any
      headerId: number
    },
    private planningRrhhService: PlanningRrhhService,
    private userInfo: AuthInfoService,
    private _toastService: ToastService
  ) {
    dialogRef.disableClose = true
    data.viewMode == this.viewsEnum.View ? this.title = 'Ver Plaza' : this.title = 'Solicitar Plaza'
  }

  ngOnInit(): void {
    this.getData()
  }
  getData() {
    this.vacancyForm.get('department').setValue(this.data.position.departmentName)
    this.vacancyForm.get('position').setValue(this.data.position.positionName)

    this.planningRrhhService.getPlaces(this.data.headerId, this.data.position.positionId).subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          this._toastService.error(res.errors[0]);
          return
        }
        this.placesList = res.dataList
      },
      error: (err: any) => {
        this._toastService.error('Ocurrio un error tratando de obtener la lista de plazas')
      }
    })

  }


  selectSalaryRange(salary: number) {
    this.vacancyForm.get('salary').setValue(salary)
    this.isSalaryInvalid = false
  }

  onSalaryError() {
    if (this.vacancyForm.get('salary').value > this.data.position.maxSalary || this.vacancyForm.get('salary').value < this.data.position.minSalary) {
      this.isSalaryInvalid = true
    }
    else {
      this.isSalaryInvalid = false
    }
  }

  closeModal() {
    this.dialogRef.close(this.created)
  }

  addPLace() {
    if (this.vacancyForm.invalid) {
      this._toastService.warning('Debe llenar los campos requeridos')
      return
    }
    this.gridElement = {
      departmentId: this.data.position.departmentId,
      placeQuantity: this.vacancyForm.get('placesQuantity').value,
      salary: this.vacancyForm.get('salary').value,
      positionId: this.data.position.positionId,
      startDate: this.vacancyForm.get('date').value,
      userId: this.userInfo.getUserId(),
      planningHeaderId: this.data.headerId,
      departmentName: this.data.position.departmentName,
      positionName: this.data.position.positionName
    }

    if (this.isEditing) {
      if (this.placesList[this.placeDTO.planningPlaceRequestId]) {
        this.placesList[this.placeDTO.planningPlaceRequestId] = {
          departmentId: this.data.position.departmentId,
          placeQuantity: this.vacancyForm.get('placesQuantity').value,
          salary: this.vacancyForm.get('salary').value,
          positionId: this.data.position.positionId,
          startDate: this.vacancyForm.get('date').value,
          userId: this.userInfo.getUserId(),
          planningHeaderId: this.data.headerId,
          departmentName: this.data.position.departmentName,
          positionName: this.data.position.positionName
        }
        this.placesListCopy = this.placesList
      }
      else {
        let existed = this.placesList.find((place) => place.planningPlaceRequestId == this.placeDTO.planningPlaceRequestId)
        this.placesList[this.placesList.indexOf(existed)] = {
          departmentId: this.data.position.departmentId,
          placeQuantity: this.vacancyForm.get('placesQuantity').value,
          salary: this.vacancyForm.get('salary').value,
          positionId: this.data.position.positionId,
          startDate: this.vacancyForm.get('date').value,
          userId: this.userInfo.getUserId(),
          planningHeaderId: this.data.headerId,
          departmentName: this.data.position.departmentName,
          positionName: this.data.position.positionName
        }

        existed = {
          ...existed,
          departmentId: this.data.position.departmentId,
          placeQuantity: this.vacancyForm.get('placesQuantity').value,
          salary: this.vacancyForm.get('salary').value,
          positionId: this.data.position.positionId,
          startDate: this.vacancyForm.get('date').value,
          userId: this.userInfo.getUserId(),
          planningHeaderId: this.data.headerId,
          departmentName: this.data.position.departmentName,
          positionName: this.data.position.positionName
        }
        this.placesListCopy = this.placesList
      }

      return
    }

    this.placesList.splice(0, 0, { ...this.gridElement, status: true })
    this.placesListCopy.splice(0, 0, { ...this.gridElement, status: true })
    this.vacancyForm.reset({
      department: this.data.position.departmentName,
      position: this.data.position.positionName
    })


  }

  savePlace() {
    if (this.placesListCopy.length === 0) {
      return
    }
    if (this.isEditing) {
      this.placeDTO = {
        ...this.placeDTO,
        departmentId: this.data.position.departmentId,
        placeQuantity: this.vacancyForm.get('placesQuantity').value,
        salary: this.vacancyForm.get('salary').value,
        positionId: this.data.position.positionId,
        startDate: this.vacancyForm.get('date').value,
        userId: this.userInfo.getUserId(),
        planningHeaderId: this.data.headerId,
      }
      this.placesDTO.requests.push(this.placeDTO)
    }
    else {

      this.placesListCopy.forEach((place) => {

        this.placeDTO = {
          departmentId: place.departmentId,
          placeQuantity: place.placeQuantity,
          salary: place.salary,
          positionId: place.positionId,
          startDate: place.startDate,
          userId: place.userId,
          planningHeaderId: place.planningHeaderId,
        }

        this.placesDTO.requests.push(this.placeDTO)
      })
    }
    this.planningRrhhService.savePlaces(this.placesDTO).subscribe({
      next: (res: any) => {
        if (res.succeded == false) {
          this._toastService.error(res.errors[0]);
          return
        }

        this.getData()
        this.created = true
        this.resetValues()
      },
      error: (err: any) => {
        this._toastService.error('Ocurrio un error tratando de crear esta plaza')
      }
    })
  }
  resetValues() {
    this.placesListCopy = []
    this.placesDTO = new PlanningPlaces();
    this.vacancyForm.reset({
      department: this.data.position.departmentName,
      position: this.data.position.positionName
    })
  }

  deletePlace(place: PlanningPlace, placesListIndex: number) {
    this.sweetAlrt.AlertConfirm("Eliminar registro", "¿Está seguro de eliminar este registro?", "question").then(res => {
      if (res) {

        if (!place.planningPlaceRequestId && placesListIndex) {
          this.placesList.splice(placesListIndex, 1)
          this._toastService.success('Registro eliminado satisfactoriamente')
          this.getData
          return
        }

        this.planningRrhhService.deletePlace(place.planningPlaceRequestId).subscribe({
          next: (res: any) => {
            this._toastService.success('Registro eliminado satisfactoriamente')
            this.getData()
            this.created = true
          },
          error: (err: any) => {

          }
        })
      } else {

      }
    })
  }

  editPlace(place: any, placesListIndex: number) {

    this.isEditing = true

    this.vacancyForm.setValue({
      department: place.departmentName,
      position: place.positionName,
      placesQuantity: place.placeQuantity,
      salary: place.salary,
      date: this.datePipe.transform(place.startDate, 'yyyy-MM-dd'),
    })
    this.placeDTO.planningPlaceRequestId = place.planningPlaceRequestId ? place.planningPlaceRequestId : placesListIndex
  }

}
