<div class="form-group">
  <!-- select2 -->
  <select
    class="form-control w-100 form-control-solid form-control-lg"
    name="selectFilter"
    [ngClass]="class"
    (change)="onChange.emit($event.target.value)"
  >
    <option [value]="undefined">Elige una opción</option>
    <option
      *ngFor="let option of options"
      [value]="option.value"
      [selected]="option.selected"
    >
      {{ option.text }}
    </option>
  </select>
</div>
