<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-datos icon-3x text-danger mr-2"></i>
                <h1 class="text-primary"> Gestión de Contenido </h1>
            </div>
        </div>
    </div>
    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
            <div class="card-toolbar d-flex justify-content-start">

                <div class="col-md-4 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Estatus
                        </label>
                        <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="dropdownSettings"
                            [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                            (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                    </div>
                </div>
                <button *ngIf="privilege.addAction.value"
                    class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto"
                    (click)="openModalAddContent(null)">
                    <i class="flaticon-mas icon-2x" data-toggle="tooltip" data-original-title="Agregar Contenido"></i>
                    Agregar
                </button>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div *ngIf="privilege.exportAction.value" class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                            <i class="fa fa-print icon-2x text-primary mr-3"></i>
                            Visualizar Reporte
                        </a>
                    </div>
                </div>

                <div *ngIf="privilege.searchAction.value" class="col text-nowrap mt-2">
                    <el-search-bar #searchBar [records]="originalContentList" (onKeyChange)="onKeyChange($event)"
                        (onKeyParameterChange)="onKeyParameterChange($event)" [keyValue]="filterText"
                        [itemForSearching]="searchBarDisplayNames" (onChange)="getFilteredList($event)"></el-search-bar>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-vertical-center caption-top">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-left">Secuencia</th>
                        <th class="text-left">Título</th>
                        <th class="text-left">Fecha Entrada</th>
                        <th class="text-left">Fecha Publicación</th>
                        <th class="text-left">Tipo de Publicación</th>
                        <th class="text-left">Clasificación</th>
                        <th class="text-left">Dirigido a</th>
                        <th class="text-left">Condición</th>
                        <th class="text-center">¿Permite Comentar?</th>
                        <th class="text-center">¿Es una Activdad?</th>
                        <th class="text-left">Estatus</th>
                        <th class="text-center">Acciones</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let content of paginatedContentList">
                        <td class="text-left">{{content.newsId || "N/A"}}</td>
                        <td class="text-left">{{content.postTitle || "N/A"}}</td>
                        <td class="text-left">{{(content.createDate | date:'dd/MM/yyyy') || "N/A"}}</td>
                        <td class="text-left">{{(content.date | date:'dd/MM/yyyy') || "N/A"}}</td>
                        <td class="text-left">{{content.postTypeDescription || "N/A"}}</td>
                        <td class="text-left">{{content.publicationClassification || "N/A"}}</td>
                        <td class="text-left">{{content.directed || "N/A"}}</td>
                        <td class="text-left font-weight-bold">{{content.condition || "N/A"}}</td>
                        <td class="text-center">{{content.allowComment ? "Si" : "No"}}</td>
                        <!-- <td class="text-center">{{content.allowShared ? "Si" : "No"}}</td> -->
                        <td class="text-center">{{content.isActivity ? "Si" : "No"}}</td>
                        <td class="text-left">
                            <span
                                [ngClass]="content.status ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{content.status?
                                "Activa":
                                "Inactiva"}}</span>
                        </td>
                        <td class="actionsBtn">
                            <button *ngIf="content.conditionId == 2 && content.status && privilege.addAction.value"
                                class="btn btn-icon btn-light-warning btn-sm mr-1" data-toggle="tooltip"
                                (click)="openModalAddContent(content, false, true)" title="Editar">
                                <i class="flaticon-edit"></i>
                            </button>

                            <button *ngIf="privilege.seeAction.value" data-toggle="tooltip"
                                (click)="openModalAddContent(content, true, false)" title=""
                                class="btn btn-sm btn-light-info btn-icon mr-1">
                                <i class="flaticon-ver" data-toggle="tooltip" data-original-title="Ver"></i>
                            </button>

                            <button (click)="publishContent(content.newsId)"
                                *ngIf="content.status && content.conditionId != 2 && privilege.postAction.value"
                                data-toggle="tooltip" title="" class="btn btn-sm btn-light-success btn-icon mr-1">
                                <i class="flaticon-check" data-toggle="tooltip" data-original-title="Publicar"></i>
                            </button>

                            <button *ngIf="content.status && privilege.deleteAction.value" data-toggle="tooltip"
                                (click)="deleteContent(content.newsId)" title=""
                                class="btn btn-sm btn-light-danger btn-icon mr-1">
                                <i class="flaticon-delete" data-toggle="tooltip" data-original-title="Inactvar"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>

                <el-caption #paginator [originalRecordsLength]="contentList?.length"
                    [listedRecordsLength]="paginatedContentList?.length"
                    [registerQuantity]="filteredList.enable && filteredList.records?.length>0 ? filteredList.records?.length :contentList?.length"
                    [records]="filteredList.enable ? filteredList.records : contentList"
                    (onChage)="getPaginatedRecords($event)"></el-caption>

            </div>
        </div>
    </div>
</div>


<export-data-to-pdf [title]="'Gestión de Contenido'" id="dataToPrint">
    <ng-container thead>
        <th class="text-left">Título</th>
        <th class="text-left">Fecha Entrada</th>
        <th class="text-left">Fecha Publicación</th>
        <th class="text-left">Tipo de Publicación</th>
        <th class="text-left">Clasificación</th>
        <th class="text-left">Dirigido a</th>
        <th class="text-left">Condición</th>
        <th class="text-center">¿Permite Comentar?</th>
        <th class="text-center">¿Es una Activdad?</th>
        <th class="text-left">Estatus</th>>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let content of paginatedContentList">
            <td class="text-left">{{content.postTitle || "N/A"}}</td>
            <td class="text-left">{{(content.createDate | date:'dd/MM/yyyy') || "N/A"}}</td>
            <td class="text-left">{{(content.date | date:'dd/MM/yyyy') || "N/A"}}</td>
            <td class="text-left">{{content.postTypeDescription || "N/A"}}</td>
            <td class="text-left">{{content.publicationClassification || "N/A"}}</td>
            <td class="text-left">{{content.directed || "N/A"}}</td>
            <td class="text-left font-weight-bold">{{content.condition || "N/A"}}</td>
            <td class="text-center">{{content.allowComment ? "Si" : "No"}}</td>
            <!-- <td class="text-center">{{content.allowShared ? "Si" : "No"}}</td> -->
            <td class="text-center">{{content.isActivity ? "Si" : "No"}}</td>
            <td class="text-left">
                <span
                    [ngClass]="content.status ? 'font-weight-bold text-success' : 'font-weight-bold text-danger'">{{content.status?
                    "Activa":
                    "Inactiva"}}</span>
            </td>
        </tr>
    </ng-container>
</export-data-to-pdf>