import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { InterviewEvaluationService } from 'src/app/services/interview-evaluation.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SearchModalComponent } from '../proof-applicants/search-modal/search-modal.component';
import { IPostulationInterview } from './models/PostulationInterview';
import { openReport, removeWhitespace } from 'src/app/shared/utils/utility';
import { SalaryRangeComponent } from 'src/app/shared/salary-range/salary-range.component';
import { AppliedVacancyModalComponent } from './appliedVacancy-modal/applied-vacancy-modal/applied-vacancy-modal.component';

@Component({
  selector: 'app-interview-evaluation',
  templateUrl: './interview-evaluation.component.html',
  styleUrls: ['./interview-evaluation.component.css']
})
export class InterviewEvaluationComponent implements OnInit, OnDestroy {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'EVALENTREVISTA',
    interviewAction: 'INTERV',
  }

  subscription: Subscription = new Subscription();
  invalidForm = false;
  hoursAvailable = [];
  testHistoryData = [];
  SearchFilters = { TypeSearch: "", Filter: "" }
  FilterOptions: any[] = [];
  interviewResults;
  form: FormGroup;
  YesOrNot = [{ value: true, text: 'SI' }, { value: false, text: 'NO' }];
  personSelected: any = {
    condition: 0
  };
  employeeStatusList = [];
  employeePositions = [];
  interviewHistory = [];
  datePipe = new DatePipe('en-Us');
  todayDate = new Date().toLocaleDateString('fr-CA')
  inDetailsMode: boolean = false;
  postulationInterviewI: IPostulationInterview;
  @Input() dataEntry: any;
  interview: any[] = [];
  postulationSelected: any;
  ppvidSelected: number = 0;
  isSalaryInvalid: boolean;
  selectedInterview: any
  params: any

  interviewerConfig = {
    placeholder: 'Seleccione uno',
    displayKey: 'customName',
    search: true,
  };

  interviewersList: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private interviewEvaluationService: InterviewEvaluationService,
    private toastr: ToastService,
    private dialog: MatDialog,
    private getParameterService: ParameterControlService,
    private srvDoc: docService,
    private activatedRoute: ActivatedRoute,
    private authInfo: AuthInfoService
  ) {
    this.datePipe.transform(this.todayDate, 'MM-dd-yyyy')
    this.activatedRoute.params.subscribe((params: any) => {
      this.params = params
    })
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      this.getPostulationById(params.id, this.authInfo.getCompanyId());
    })
    this.getEmployeesPositions();
    this.getHoursAvailable();
    this.GetFilterOptions();
    this.getInterviewResults();
    this.getEmployeeStatusNow();
    this.getInterviewersList();
    this.buildForm();
    
  }

  buildForm() {
    this.form = this.formBuilder.group({
      personName: ['', [Validators.required]],
      position: [''],
      dateInterview: [new Date().toLocaleDateString('fr-CA'), [Validators.required]],
      professionalDevelopment: false,
      learning: false,
      analysisCapacity: false,
      languageAbility: false,
      eyeContact: false,
      creativityAndInnovation: false,
      communication: false,
      laborStability: false,
      supervisionExperience: false,
      verbalFluency: false,
      vocationalTraining: false,
      initiative: false,
      leadership: false,
      multitask: false,
      achievementOrientation: false,
      criticalThinking: false,
      structuredThinking: false,
      systematicThinking: false,
      puntuality: false,
      resilience: false,
      selfConfidence: false,
      sociability: false,
      generalRemarks: ['', [Validators.maxLength(250)]],
      proposedPosition: ['', [Validators.required]],
      idResults: ['', [Validators.required]],
      employee: [false],
      dateAvailability: ['', [Validators.required]],
      proposedSchedule: ['', [Validators.required]],
      proposedSalary: [0, [Validators.required]],
      desiredPayment: [null, [Validators.required]],
      reasonsChange: ['', [Validators.required]],
      checkIn: [''],
      physicalAppearance: [false],
      workExperience: [false],
      companyId: [this.authInfo.getCompanyId()],
      status: [true],
      vacant: '',
      interviewerEmployeeId: [null, [Validators.required]],
      interviewerSelected: [null, [Validators.required]]
    })

  }

  removeWhitespaces(event, control){
    removeWhitespace(event, control)
  }


  handleInterviewerChange(value) {
    if (value?.employeeId) {
      this.form.get('interviewerEmployeeId').setValue(value.employeeId);
    } else {
      this.form.get('interviewerEmployeeId').setValue(null);
    }

  }

  getInterviewersList() {
    const companyId = this.authInfo.getCompanyId();
    this.authInfo.getAllowedUsersToAction(companyId, this.privilege.interviewAction, this.privilege.nameKeyOption).subscribe({
      next: (res) => {
        if (!res.succeded) {

          res.errors.forEach(err => {
            this.toastr.error(err);
          });

          res.warnings.forEach(wa => {
            this.toastr.warning(wa);
          });

          return;
        }

        this.interviewersList = res.dataList.map(user=>{
          return{
            ...user,
            customName: user.firstName + ' ' + user.lastName
          }
        });

      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error inesperado tratando de consultar el listado de entrevistadores');
      }

    });
  }


  getPostulationById(id, companyId: number) {
    this.interviewEvaluationService.getPostulationById(id, companyId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastr.error("Error al buscar la postulación")
        return;
      }
        this.personSelected = res.dataList[0];
        this.getPersonPhotoloaded(res.dataList[0].photo)
        this.setFormValues()
        this.getHistoryPostulations()
        this.getHistoryInterviews()
    }, error => {
      this.toastr.error("Error al buscar la postulación")
    })
  }
  openSalaryRange() {
    this.dialog.open(SalaryRangeComponent, {
      data: {
        postulationSelected: this.personSelected,
        // IsviewMode: this.IsviewMode
      },

    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.Applied) {
          this.selectSalaryRange(result?.salary);
        }
      });
  }

  openAppliedVacancyModal() {
    this.dialog.open(AppliedVacancyModalComponent, {
      data: {
        interviewId: this.params.id,
        companyId: this.authInfo.getCompanyId()
      }
    })
  }

  selectSalaryRange(salary: number) {
    this.form.get('proposedSalary').setValue(salary)
    this.isSalaryInvalid = false
  }

  onSalaryError() {

    if (this.form.get('proposedSalary').invalid) {
      return
    }

    if (this.form.get('proposedSalary').value > this.personSelected.maxSalary || this.form.get('proposedSalary').value < this.personSelected.minSalary) {
      this.isSalaryInvalid = true
    }
    else {
      this.isSalaryInvalid = false
    }
  }

  saveInterview() {
    if (this.form.invalid || this.isSalaryInvalid == true) {
      this.invalidForm = true;
      return;
    }
    this.invalidForm = false;
    this.postulationInterviewI = {
      ...this.form.value,
      unemployed: this.form.get('employee').value ? false : true,
      postulationInterviewId: 0,
      resultsId: Number(this.form.get('idResults').value),
      postulationId: this.personSelected.ppvid,
      proposedPosition: Number(this.form.get('proposedPosition').value),
      proposedSchedule: Number(this.form.get('proposedSchedule').value),
      proposedSalary: Number(this.form.get('proposedSalary').value),
      companyId: this.authInfo.getCompanyId(),
      checkIn: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
      status: true
    }

    let saveInterviewSub = this.interviewEvaluationService.savePositionInterview(this.postulationInterviewI).subscribe((res: any) => {
      this.toastr.success("Se ha guardado los datos de la entrevista.", "Los datos se han guardado.");
      this.resetForm()
      this.getHistoryInterviews();
    }, error => {
      this.toastr.error("Comunicarse con el departamento de TI", "Ha ocurrido un error al guardar los datos.");
    })
    this.subscription.add(saveInterviewSub)
  }

  getPersonPhotoloaded(guid: any) {
    this.srvDoc.getDocument(guid).subscribe(
      (e: any) => {
        this.personSelected.photoloaded = e.data
      }
    )
  }

  onInterviewResultsChange(option: any) {
    if (option == 1) {
      this.form.get('dateAvailability').clearValidators()
      this.form.get('dateAvailability').updateValueAndValidity()
      this.form.get('dateAvailability').disable()
    }
    else {
      this.form.get('dateAvailability').setValidators(Validators.required)
      this.form.get('dateAvailability').updateValueAndValidity()
      this.form.get('dateAvailability').enable()
    }
  }


  resetForm() {
    this.form.patchValue({
      dateInterview: '',
      professionalDevelopment: false,
      learning: false,
      analysisCapacity: false,
      languageAbility: false,
      eyeContact: false,
      creativityAndInnovation: false,
      communication: false,
      laborStability: false,
      supervisionExperience: false,
      verbalFluency: false,
      vocationalTraining: false,
      initiative: false,
      leadership: false,
      multitask: false,
      achievementOrientation: false,
      criticalThinking: false,
      structuredThinking: false,
      systematicThinking: false,
      puntuality: false,
      resilience: false,
      selfConfidence: false,
      sociability: false,
      physicalAppearance: false,
      workExperience: false,
      generalRemarks: '',
      idResults: '',
      reasonsChange: '',
      dateAvailability: '',
      interviewerEmployeeId: null,
      interviewerSelected: null
    })
  }


  getEmployeesPositions() {
    let employeeSub = this.interviewEvaluationService.getEmployeesPositions().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this.toastr.error(res.errors[0])
        return
      }
      this.employeePositions = res.dataList;
    }, error => {
    })
    this.subscription.add(employeeSub)
  }

  GetFilterOptions() {
    let parameterSub = this.getParameterService.getParameters(keyWord.CandidateFilter).subscribe(
      (data) => {
        if (data.succeded) {
          this.FilterOptions = data.dataList;
        } else {
          this.toastr.error(data.errors[0]);
        }
      },
      (error) => {
        this.toastr.error(
          'Ha ocurrido un error buscando los filtros de candidatos. Consulte con el administrador del sistema'
        );
      }
    );
    this.subscription.add(parameterSub)
  }


  deleteInterview(interview) {
    let data = {
      postulationInterviewId: interview.postulationInterviewId,
      userId: this.authInfo.getUserId()
    }
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      title: "¿Está seguro que desea eliminar este registro?",
      icon: "question"
    }).then(res => {
      if (res.isConfirmed) {
        let deleteInterviewSub = this.interviewEvaluationService.deleteInterview(data).subscribe((res: any) => {
          if (res.errors.length > 0) {
            this.toastr.error("Error al eliminar la entrevista")
            return;
          }
        }, (error) => {
          this.toastr.error("Error al eliminar la entrevista", "Error inesperado")
        }, () => {
          this.getHistoryInterviews();
          Swal.fire("Registro eliminado", '', 'success')
        })
        this.subscription.add(deleteInterviewSub)
      }
    })
  }


  setFormValues() {
    this.form.get('personName').setValue(this.personSelected.personName);
    this.form.get('vacant').setValue(this.personSelected.positionName);
    this.form.get('employee').setValue(this.personSelected.employee);
    this.form.get('desiredPayment').setValue(this.personSelected.desiredSalary);
    this.form.get('proposedSalary').setValue(this.personSelected.desiredSalary);
    this.form.get('proposedPosition').setValue((this.personSelected.positionId) ? this.personSelected.positionId : 2);
    this.form.get('proposedSchedule').setValue(this.personSelected.hoursAvailable);
    this.form.get('checkIn').setValue(this.personSelected.checkIn);
    this.form.get('position').setValue(this.personSelected.positionId);
    this.onSalaryError();
  }





  getHistoryPostulations() {
    let getPostulationsIdentSub = this.interviewEvaluationService.getAllPostulationsByDocumentID(this.personSelected.personalIdentification).subscribe((res) => {
      if (res.errors.length > 0) { this.toastr.error("Error al obtener el historial de postulaciones"); return; }
      this.testHistoryData = res.dataList;
    }, error => {
    });
    this.subscription.add(getPostulationsIdentSub)
  }
  getHistoryInterviews() {
    let interviewHistorySub = this.interviewEvaluationService.getInterviewHistory(this.personSelected.ppvid).subscribe((res: any) => {
      if (res.errors.length > 0) { this.toastr.error("Error al obtener el historial de entrevistas"); return; }
      this.interviewHistory = res.dataList;
    }, error => {
    })
    this.subscription.add(interviewHistorySub)
  }

  printReport(item) {
    const id = item.postulationInterviewId
    const reportUrl = environment.reportUrl
    const companyId = this.authInfo.getCompanyId()
    const url = `${reportUrl}?Reportcode=HistoryInterview_Form&Type=1&CompanyId=${companyId}&Postulationsinterviews=${id}&Status=1`
    let parameters = {
      url: url,
      title: 'Estrevista',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }



  interviewDetails(item) {
    item.dateInterview = this.datePipe.transform(item.dateInterview, 'yyyy-MM-dd');
    item.checkIn = this.datePipe.transform(item.checkIn, 'yyyy-MM-dd');
    item.dateAvailability = this.datePipe.transform(item.dateAvailability, 'yyyy-MM-dd');
    item.idResults = item.resultsId;
    this.form.patchValue(item)
    this.form.disable();
    this.inDetailsMode = true;
  }

  clearAll() {
    this.form.enable()
    this.resetForm()
    this.form.patchValue({
      vacant: this.personSelected.vacant,
      personName: this.personSelected.personName,
      proposedPosition: this.personSelected.positionId,
      proposedSchedule: this.personSelected.hoursAvailable,
      proposedSalary: this.form.get('proposedSalary').value,
      unemployed: this.personSelected.employee ? false : true
    })
    this.inDetailsMode = false;
  }


  getEmployeeStatusNow() {
    let employeeStatusSub = this.getParameterService.getParameters(keyWord.EmployeeStatusNow).subscribe((res: any) => {
      this.employeeStatusList = res.dataList;
    }, error => {

    });
    this.subscription.add(employeeStatusSub)
  }
  getInterviewResults() {
    let interviewResultsSub = this.getParameterService.getParameters(keyWord.InterviewResult).subscribe((res: any) => {
      this.interviewResults = res.dataList;
    }, error => {

    });
    this.subscription.add(interviewResultsSub)
  }
  getHoursAvailable() {
    let hoursAvailabelSub = this.getParameterService.getParameters(keyWord.HoursAvailable).subscribe((res: any) => {
      this.hoursAvailable = res.dataList;
    }, error => {
    });
    this.subscription.add(hoursAvailabelSub)
  }

  showdateInterviewErrors() {
    let field = this.form.get('dateInterview');
    if (field.hasError('required')) {
      return 'Debe seleccionar una fecha';
    }
    return '';
  }

  showProposedSalaryErrors() {
    let field = this.form.get('proposedSalary');
    if (field.hasError('required')) {
      this.isSalaryInvalid = false
      return 'Debe ingresar un salario';
    }
    return '';
  }

  showDateAvailabilityErrors() {
    let field = this.form.get('dateAvailability');
    if (field.hasError('required')) {
      return 'Debe seleccionar una fecha';
    }
    return '';
  }

  showInterviewResultErrors() {
    let field = this.form.get('idResults');
    if (field.hasError('required')) {
      return 'Debe seleccionar un resultado';
    }
    return '';
  }

  showInterviewerErrors() {
    let field = this.form.get('interviewerEmployeeId');
    if (field.hasError('required')) {
      return 'Debe seleccionar un entrevistador';
    }
    return '';
  }

  showProposedScheduleErrors() {
    let field = this.form.get('proposedSchedule');
    if (field.hasError('required')) {
      return 'Debe seleccionar un horario';
    }
    return '';
  }

  showProposedPositionErrors() {
    let field = this.form.get('proposedPosition');
    if (field.hasError('required')) {
      return 'Debe seleccionar una posicion';
    }
    return '';
  }
  showApplicantNameErrors() {
    let field = this.form.get('personName');
    if (field.hasError('required')) {
      return 'Debe seleccionar un postulante';
    }
    return '';
  }

  showVacantErrors() {
    let field = this.form.get('vacant');
    if (field.hasError('required')) {
      return 'Debe seleccionar un postulante';
    }
    return '';
  }
  showGeneralRemarksErrors() {
    let field = this.form.get('generalRemarks');
    if (field.hasError('maxlength')) {
      return `Este campo sólo admite un máximo de ${field?.errors['maxlength']?.requiredLength} caracteres`;
    }
    return '';
  }
  showReasonChangeErrors() {
    let field = this.form.get('reasonsChange');
    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }
    return '';
  }

}
