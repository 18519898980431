<div *ngIf="isLoading" class="w-100 d-flex justify-content-center align-items-center spinner-container flex-column">
    <el-spinner animation="spinner-logo"></el-spinner>
    <h3 class="text-primary">Cargando Requisición...</h3>
</div>
<div class="container-fluid py-3">
    <button routerLink="/personal-requisition"  class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
        <i class="flaticon2-left-arrow-1"></i>
        <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
    </button>
    
</div>

<div class="container-fluid" *ngIf="!isLoading">
    <div id="pdfExp" class="card">
        <div class="card-header px-22">
            <div class="align-items-center d-flex justify-content-lg-between flex-md-row justify-content-sm-center"
                id="header">
                <div class="logo px-2"> <img [src]="file.logo"  alt="" class="max-w-200px" />
                </div>
                <div class="title p-2 d-flex justify-content-center align-items-center flex-column">
                    <h1 class="ttlPDF text-primary text-center"> Instituto Tecnológico de las Américas </h1>
                    <h2 class="ttlPDF text-center"> Unidad Organizativa de Recursos Humanos </h2>
                    <h3 class="text-danger text-center">Formulario de Requisición de Personal</h3>
                </div>
                <div class="date mt-md-5 p-4 d-flex flex-wrap justify-content-between align-items-center">
                    <h4 class="text-right mr-3">Fecha de solicitud</h4>
                    <h5 class="text-primary text-left">__/__/____</h5>
                    <!-- &nbsp; -->
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row gutter-t">
                <h3 class="col-md-12 p-2 bg-primary text-white text-center gutter-b">DATOS GENERALES</h3>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pdf-list">
                    <div class="label-inline">
                        <label>Código Documental:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Puesto Solicitado:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Unidad Organizativa:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Fecha de Solicitud:</label>
                        <span class="text-primary text-left">__/__/____</span>
                    </div>
                    <div class="label-inline">
                        <label>Fecha de Ingreso:</label>
                        <span class="text-primary text-left">__/__/____</span>
                    </div>
                </div>
                <div class="pdf-list col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="label-inline">
                        <label>Cantidad de Plaza:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Causa de la Vacante:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Cantidad de Horas a Trabajar:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Tipo de Requisición:</label>
                        <span></span>
                    </div>
                </div>
                <div class="col-md-12 gutter-t gutter-b">
                    <h5 class="text-danger text-center">
                        Esta solicitud tendrá una vigencia de 3 meses, si pasado este tiempo no se ha nombrado ninguna
                        persona, se tendrá que hacer la solicitud nuevamente.
                    </h5>
                </div>
                <div class="col-md-6">
                    <div class="card card-dt">
                        <div class="card-header p-2">
                            <h3 class="text-uppercase m-0 text-primary font-weight-bolder text-center">En Sustitución de
                            </h3>
                        </div>
                        <div class="card-body"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card card-dt">
                        <div class="card-header p-2">
                            <h3 class="text-uppercase m-0 text-primary font-weight-bolder text-center">Si es creación,
                                debido a</h3>
                        </div>
                        <div class="card-body"></div>
                    </div>
                </div>
            </div>
            <div class="row gutter-t">
                <h3 class="col-md-12 p-2 bg-secondary text-uppercase text-white text-center d-block">
                    Observaciones
                </h3>
                <div class="col-md-12 gutter-t">
                    <div class="card card-dt">
                        <div class="card-header p-2">
                            <h3 class="text-uppercase m-0 text-primary font-weight-bolder text-center">En caso de ser
                                temporal, especifique motivos y tiempo requerido</h3>
                        </div>
                        <div class="card-body"></div>
                    </div>
                </div>
            </div>
            <div class="row gutter-t">
                <h3 class="col-md-12 p-2 bg-primary text-uppercase text-white text-center text-center">
                    Requisitos Deseables
                </h3>
                <div class="col-md-6 pdf-list gutter-t">
                    <div class="label-inline">
                        <label>Sexo:</label>
                        <span></span>
                    </div>
                </div>
                <div class="col-md-6 pdf-list gutter-t">
                    <div class="label-inline">
                        <label>Rango de edad:</label>
                        <span></span>
                    </div>
                </div>

            </div>
            <div class="row gutter-t">
                <h3 class="col-md-12 p-2 bg-secondary text-uppercase text-white text-center text-center">
                    <strong>Competencias Técnicas</strong> (Consultar con manual de puesto administrativo o docentes,
                    según aplique)
                </h3>
                <div class="col-md-12 pdf-list gutter-t">
                    <div class="label-inline">
                        <label>Nivel Educativo (Especifique Carrera):</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Conocimiento, Habilidades y Destreza Requerida:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Otros Requisitos Deseables:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Años de Experiencia:</label>
                        <span></span>
                    </div>
                </div>
            </div>
            <div class="row gutter-t">
                <h3 class="col-md-12 p-2 bg-primary text-uppercase text-white text-center text-center">
                    <strong>Descripción General de las Funciones</strong> (Consultar con manual de puestos, según
                    aplique)
                </h3>
                <div class="min-h-200px">
                </div>
            </div>
            <div class="row gutter-t">
                <h3 class="col-md-12 p-2 bg-secondary text-uppercase text-white text-center text-center">
                    Solicitado por
                </h3>
                <div class="col-md-12 pdf-list gutter-b gutter-t">
                    <div class="label-inline">
                        <label>Servidor Público:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Cargo:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Fecha:</label>
                        <span></span>
                    </div>
                </div>
                <div class="col-md-12 gutter-b gutter-t">
                    <div class="firma text-center">
                        <span>_________________________________________</span>
                        <p class="font-weight-bold text-dark">
                            Firma
                        </p>
                    </div>
                </div>
            </div>
            <div class="row gutter-t">
                <h3 class="col-md-12 p-2 bg-primary text-uppercase text-white text-center text-center">
                    Para el uso de la Unidad Organizativa de RRHH
                </h3>
                <div class="col-md-12 pdf-list gutter-b gutter-t">
                    <div class="label-inline">
                        <label>Encargado:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Observaciones:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Fecha de Recibo:</label>
                        <span></span>
                    </div>
                </div>
                <div class="col-md-12 gutter-b gutter-t">
                    <div class="firma text-center">
                        <span>_________________________________________</span>
                        <p class="font-weight-bold text-dark">
                            Firma
                        </p>
                    </div>
                </div>
            </div>
            <div class="row gutter-t">
                <h3 class="col-md-12 p-2 bg-secondary text-uppercase text-white text-center text-center">
                    Máxima Autoridad Institucional
                </h3>
                <div class="col-md-12 pdf-list gutter-b gutter-t">
                    <div class="label-inline">
                        <label>Servidor Público:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Fecha:</label>
                        <span></span>
                    </div>
                    <div class="label-inline">
                        <label>Condición de Requisición:</label>
                        <span></span>
                    </div>
                </div>
                <div class="col-md-12 gutter-b gutter-t">
                    <div class="firma text-center">
                        <span>_________________________________________</span>
                        <p class="font-weight-bold text-dark">
                            Firma
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card card-dt">
                        <div class="card-header p-2">
                            <h3 class="text-uppercase m-0 text-primary font-weight-bolder text-center">Puesto ocupado
                                por</h3>
                        </div>
                        <div class="card-body"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card card-dt">
                        <div class="card-header p-2">
                            <h3 class="text-uppercase m-0 text-primary font-weight-bolder text-center">Fecha de Ingreso
                            </h3>
                        </div>
                        <div class="card-body"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer gutter-t">
            <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
                <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-80px">
              <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt="" class="max-w-175px">
            </div>
        </div>
    </div>
</div>
<button onclick="print()"  class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
    <i class="flaticon2-printer font-size-h1"></i>
</button>
<script>
    let print = () => {
        let pdf = document.getElementById("pdfExp");
        pdf.contentWindow.focus();
        pdf.contentWindow.print();
    };
</script>