import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { DepartmentService } from 'src/app/services/department.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.css']
})
export class BankAccountsComponent implements OnInit {
  reportForm: FormGroup;
  budgetAreaList: any[] = [];
  areaSelected: any;
  identifications: any;
  identificationSelected: any;
  reportUrl = environment.reportUrl
  employeeType: any[] = [];
  employeeTypeSelected: any;
  departments: any[] = [];
  departmentSelected: any;
  public mask = ""
  

  constructor(
    private location: Location,
    public _toastService: ToastService,
    public authInfo: AuthInfoService,
    private fb: FormBuilder,
    private employeeService: EmployeesService,
    private departmentsService: DepartmentService,
    private payrollService: PayrollService,
    private _ParamService: ParameterControlService,
    ) { 
      this.buildForm();
    }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.getEmployeeTypeList()
    this.getDepartments()
    this.getBudgetAreaList()
    this.getIdentificationType()
  }

  getIdentificationType() {
     this._ParamService.getParameters(keyWord.IDType).subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          this._toastService.error(res.errors[0])
          return;
        }
        this.identifications = res.dataList
      },
      error: (err) => {
        this._toastService.error('Error cargando la lista de las tipos de identificación')
        console.log(err);
      }
    });
  }
  getDepartments() {
    this.departmentsService.getAll().subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          this._toastService.error(res.errors[0])
          return;
        }
        this.departments = res.dataList
      },
      error: (err) => {
        this._toastService.error('Error cargando la lista de las unidades organizativas')
        console.log(err);
      }
    });
  }

  getEmployeeTypeList() {
    this.employeeService.getEmployeeTypes().subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          this._toastService.error(res.errors[0])
          return;
        }
        this.employeeType = res.dataList
      },
      error: (err) => {
        this._toastService.error('Error cargando la lista de tipos de servidores públicos')
        console.log(err);
      }
    });
  }

  getBudgetAreaList() {
    this.payrollService.getBudgetAreas().subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          this._toastService.error(res.errors[0])
          return;
        }
        this.budgetAreaList = res.dataList
      },
      error: (err) => {
        this._toastService.error('Error cargando la lista de areas presupuestarias')
        console.log(err);
      }
    });
  }

  goBack() {
    this.location.back();
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  buildForm() {
    this.reportForm = this.fb.group({
      area: [null, [Validators.required]],
      department: [null, [Validators.required]],
      employeeType: [null, [Validators.required]],
      identification: null,
    })
  }

  onIdentificationTypeChange(identificationType){
    this.reportForm.get('identification').reset()
    if (identificationType.ocode === 1 || identificationType.ocode === 4) {
      this.mask = "000-0000000-0"
    }
    else {
      this.mask=''
    }


  }


  openReport() {
    if (this.reportForm.invalid) {
      this._toastService.warning('Por favor completar todos los campos requeridos', 'Campos incompletos')
      return
    }

    this.areaSelected = { ...this.reportForm.get('area')?.value };
    this.departmentSelected = { ...this.reportForm.get('department')?.value } ?? 0
    this.employeeTypeSelected = { ...this.reportForm.get('employeeType')?.value }??0
    this.identificationSelected = this.reportForm.get('identification')?.value ?? ''

    let areaId: number = this.areaSelected?.budgetAreaId;
    let departmentId: number = this.departmentSelected?.departmentId;
    let employeeTypeId: number = this.employeeTypeSelected?.employeeTypeId;
    const reportCode: ReportCode = ReportCode.BankAccounts;
    const reportUrl = `${this.reportUrl}/?ReportCode=${reportCode}&CompanyId=${this.authInfo.getCompanyId()}&DepartmentId=${departmentId}&License=${this.identificationSelected}&EmployeeTypeId=${employeeTypeId}&BugetAreaId=${areaId}`
  
    let parameters = {
      url: reportUrl,
      title: 'Reporte de Nomina de Empleado con Conceptos',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }

}
