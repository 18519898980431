import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AdditionalPayrollService } from 'src/app/services/additional-payroll.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PagedResult } from 'src/app/shared/models/page-result';
import { IAdditionalPayrollHeaderPOST, IEmployeeGET, IEmployeeToPayroll } from '../models/models';
//import { EmployeeToPayroll, IEmployeeGET, IFinalPayrollPOST } from '../models/models';

@Component({
  selector: 'app-additional-payroll-search-modal',
  templateUrl: './additional-payroll-search-modal.component.html',
  styleUrls: ['./additional-payroll-search-modal.component.css']
})
export class AdditionalPayrollSearchModalComponent implements OnInit, AfterViewChecked {

  selectedEmployee: IEmployeeGET
  employeeList: any[] = []
  paginatedRecords: any[] = []
  budgetAreaId:number = 0
  periodId :number = 0
  payrollPeriodIdRelated: number;
  headerId: number = 0
  employeesPagedResut = new PagedResult<any>();
  isSingleClick: Boolean = true;
  searchParameter;
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkeyId: number, propkey?: string, displayName: string }[] = 
    [
      { propkeyId: 2,propkey: "employNumber", displayName: "Código de Servidor Público" },
      { propkeyId: 3, propkey: "name", displayName: "Servidor Público" },
      { propkeyId: 4, propkey: "personalIdentification", displayName: "Documento de Identidad" },
  ];
    
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {headerId:number, budgetAreaId:number, payrollPeriodId: number, payrollPeriodIdRelated: number},
    private dialogRef: MatDialogRef<AdditionalPayrollSearchModalComponent>,
    private additionalService: AdditionalPayrollService,
    private _changeDet: ChangeDetectorRef,
    private toastr: ToastrService,
    private authInfo: AuthInfoService
  ) { }
  
  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  ngOnInit(): void {
    this.budgetAreaId = this.data.budgetAreaId
    this.periodId = this.data.payrollPeriodId
    this.payrollPeriodIdRelated = this.data.payrollPeriodIdRelated;
    this.headerId = this.data.headerId
    this.getEmployees()
  }
  
  selectEmployee(employee) {
    
    setTimeout(()=>{
      if(this.isSingleClick){
        if (this.selectedEmployee && this.selectedEmployee.employeeId === employee.employeeId) {
          this.selectedEmployee = null;
          return;
        }
        this.selectedEmployee = { ...employee };
        this.isSingleClick = true;
                  
      }
    },250)
  
  }

  doubleClick(employee){
    
    this.selectedEmployee = { ...employee };
    this.isSingleClick = true;
    this.save()
  }
  
  getEmployees(){
    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = !this.searchParameter?.value ? 0 : this.searchParameter?.propkeyId
    this.additionalService.getEmployeesPaginated(page, pageSize, propKeyId, text, this.payrollPeriodIdRelated,this.budgetAreaId).subscribe((res:any) => {
      if(res.succeded){
        if(res.errors.length > 0){this.toastr.error(res.errors[0]); return}
        if(res.warnings.length > 0){this.toastr.warning(res.errors[0]); return}
        this.employeeList = res.singleData.items
        this.employeesPagedResut = res.singleData
      }else{
        
      }
    })
  }
  isEmployeeSelected(employee) {
    if (!employee) return false;
    if (!this.selectedEmployee) return false;
    return this.selectedEmployee.employeeId === employee.employeeId;
  }
  
  close(){
    this.dialogRef.close({success: false})
  }
  save(){
    const payroll = this.getFinalPayrollValues()

    if(!payroll?.employeeId){
      this.toastr.warning('Debe seleccionar al menos un Servidor Público.')
      return;
    }

    this.additionalService.OpenAdditionalPayroll(payroll).subscribe((res:any) => {
      if(res.succeded){
        this.toastr.success('Se han guardado los datos')
        this.dialogRef.close({success: true})
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }
  filterSearch(event){
    this.employeesPagedResut.page = 1
    this.searchParameter = event
    this.getEmployees()
  }
  
  
  getFinalPayrollValues(){
    const values = {
      budgetAreaId: this.budgetAreaId,
      companyId: this.authInfo.getCompanyId(),
      payrollPeriodIdRelated: this.payrollPeriodIdRelated,
      headerId: this.headerId,
      employeeId: [this.selectedEmployee.employeeId],
      createUserId: this.authInfo.getUserId()
    }
    return values
  }
  
  

  getPaginatedRecords(event) {
    
    if (this.employeesPagedResut.page == event.selectedPage && this.employeesPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.employeesPagedResut.page = event.selectedPage;
    this.employeesPagedResut.pageSize = event.registersPerPage;
    this.getEmployees()
  }
  getFilteredList(event) {
    this.filteredList = event;
  }

}
