<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Buscar Servidor Público</h2>
    <button type="button" (click)="close()" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">
    <div class="card card-custom">
        <div class="card-header mt-5 row justify-content-end">
            <div class="col-sm-6 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
        
                    <app-search-bar-pagination (onFilter)="filterSearch($event)" [parameters]="searchBarDisplayNames"></app-search-bar-pagination>
                   
                </div>
            </div>
        </div>
    
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-left">Secuencia</th>
                            <th class="text-left">Código del Servidor Público</th>
                            <th class="text-left">Servidor Público</th>
                            <th class="text-left">Documento de Identidad</th>
                            <th class="text-left">Unidad Organizativa</th>
                            <th class="text-left">Posición</th>             
                            <th class="text-center">Estatus</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let employee of employeeList">
                            <tr class="select-employee" (click)="selectEmployee(employee)" (dblclick)="doubleClick(employee)"
                                [ngClass]="{'selected-employee bg-primary': isEmployeeSelected(employee)}">
                                <td class="text-left">
                                    {{employee.personId}}
                                </td>
                                <td class="text-left">{{employee.employNumber}}</td>
                                <td class="text-left">{{employee.name}}</td>
                                <td *ngIf="employee.typeIdentificationId == 1" class="text-left">{{employee.personalIdentification | mask: '000-0000000-0'}}</td>
                                <td *ngIf="employee.typeIdentificationId != 1" class="text-left">{{employee.personalIdentification}}</td>
                                <td class="text-left">{{employee.departmentName}}</td>
                                <td class="text-left">{{employee.positionName}}</td>                 
                                <td class="text-center">
                                    <span *ngIf="employee.status" class="font-weight-bold text-success"
                                        [ngClass]="{'estatus': isEmployeeSelected(employee)}">Activa</span>
                                    <span *ngIf="!employee.status" class="font-weight-bold text-danger"
                                        [ngClass]="{'estatus': isEmployeeSelected(employee)}">Inactiva</span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div>
                    <app-paginator
                    [listedRecordsLength]="employeeList?.length"
                    [registerQuantity]="employeesPagedResut.totalItems"
                    [registerPerPage]="employeesPagedResut.pageSize"
                    [totalPages]="employeesPagedResut.totalPages"
                    [selectedPage]="employeesPagedResut.page"
                    (onChange)="getPaginatedRecords($event)">
                </app-paginator>
                </div>
            </div>
    
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button (click)="save()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Selecionar</span>
    </button>
</div>