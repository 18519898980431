import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription, forkJoin } from 'rxjs';
import { IPositionToMap, IPositionView } from 'src/app/models/positions.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { HttpClientService } from 'src/app/services/http-client/http-client.service';
import { PositionService } from 'src/app/services/position.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import Swal from 'sweetalert2';
import { CreatePositionModalComponent } from './create-position-modal/create-position-modal.component';
import { ViewReasonRejectComponent } from './view-reason-reject/view-reason-reject.component';
import { FilterDashboardService } from 'src/app/services/filter-dashboard.service';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'app-positions-list',
  templateUrl: './position-list.component.html',
  styleUrls: ['./position-list.component.css']
})

export class PositionsListComponent implements OnInit, OnDestroy, AfterViewChecked {
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'POSITIONS',
    addAction: { key: 'ADD-P', value: false },
    activeAction: { key: 'ACTIVE-P', value: false },
    viewAction: { key: 'VIEW-P', value: false },
    exportAction: { key: 'EXPORT-P', value: false },
    editAction: { key: 'EDITPU', value: false },
    deleteAction: { key: 'DELTPU', value: false }
  }
  localStorageSearch: string = 'app-positions-list';
  subscriptions: Subscription = new Subscription();
  dataList: any[] = [];
  records: any[] = [];
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  filtervalues:any;
  showInactives = false;
  companies: any[] = []
  companyId: number
  currentCompany:any
  registersPerPage:any
  selectedPage:any
  options: any[] = [];
  @ViewChild('paginator') paginator: CaptionComponent;
  status: { name: string, value: boolean }[] = [
    { name: 'Activo', value: true },
    { name: 'Inactivo', value: false },
  ];
  modal: { title: string, action: string; } = { title: 'Agregar', action: 'add' };
  company;
  searchBarDisplayNames: {propKeyId: number, propkey?: string, displayName: string }[] =
    [
      {propKeyId: 1, propkey: "positionId", displayName: "Secuencia" },
      {propKeyId: 4, propkey: "shortName", displayName: "Nombre Corto" },
      {propKeyId: 2, propkey: "positionName", displayName: "Cargo" },
      {propKeyId: 3, propkey: "positionCode", displayName: "Código Cargo" },
      {propKeyId: 6, propkey: "occupationalGroup", displayName: "Grupo Ocupacional" },
      {propKeyId: 5, propkey: "condition", displayName: "Condición" },
      {propKeyId: 8, propkey: "requestedPositions", displayName: "Posiciones Solicitadas" },
      {propKeyId: 7, propkey: "approvalPositions", displayName: "Posiciones Aprobadas" },
    ];

  filterText: string = '';
  selectedItems: any[] = [];
  dataOriginalList: any[] = [];
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' },
    { item_id: 3, item_text: 'No Enviado' },
    { item_id: 4, item_text: 'Solicitado' },
    { item_id: 5, item_text: 'Aprobado' },
    { item_id: 6, item_text: 'Rechazado' },
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  searchParameter: any;
  sweetAlrt = new SweetAlert()

  concursaIdRequired: boolean = false

  constructor(
    private _http: HttpClientService,
    private _cdRef: ChangeDetectorRef,
    private _builder: FormBuilder,
    private _toastr: ToastrService,
    private srvExportToExcel: ExportToExcelService,
    private positionService: PositionService,
    private _filterService: FilterDashboardService,
    public authInfo: AuthInfoService,
    private dialog: MatDialog,
  ) { }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setPrivileges() {
    this.privilege = this.authInfo.setPrivileges(this.privilege);
  }

  ngOnInit(): void {
    //this.setFilterStorage()
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.getData();
        this.setPrivileges();
        // this.getPositionList();
      }
    });
    applySort();
    this.getCompanies();
  }


  getData() {
    let companniesSub = this._filterService.getCompaniesById(this.authInfo.getCompanyId()).subscribe({
      next: (res: any) => {
        this.companies = res.dataList
        this.company = this.companies.find(x => x.companyId == this.authInfo.getCompanyId())
        if (this.company) {
          this.getPositionList()
        }
      },
      error: err => this._toastr.error(err)
    })
    this.subscriptions.add(companniesSub);
  }

  getCompanies() {
    this.positionService.getCompanies().subscribe({
      next: (res: any) => {
        this.currentCompany = res.dataList?.filter(d => d?.status);
        this.company = this.currentCompany.find(x => x.companyId == this.authInfo.getCompanyId())
      },
      error: err => this._toastr.error('Ocurrió un error al obtener el listado de instituciones')
    })
  }



  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Click para desplegar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }


  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    //document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.dataList = this.filterTable(this.dataOriginalList)
    }
    this.setFilterToLocalStorage();
  }
  setFilterToLocalStorage() {
    localStorage.setItem('postions-list_filter', JSON.stringify(this.selectedItems));
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl.find(x => x.item_id == 1) && !sl.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl.find(x => x.item_id == 2) && !sl.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    const conditions = [...this.getConditionsSeleted];
    if (conditions?.length > 0) {
      return list?.filter(l => conditions?.some(condition => condition === l?.conditionId));
    }
    return list;
  }

  private get getConditionsSeleted() {
    const sl: number[] = this.selectedItems?.map(s => s?.item_id);
    const conditions: number[] = [];
    if (sl?.find(x => x === 3)) conditions.push(4);
    if (sl?.find(x => x === 4)) conditions.push(1);
    if (sl?.find(x => x === 5)) conditions.push(2);
    if (sl?.find(x => x === 6)) conditions.push(3);
    return conditions;
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = event.records
    if (!this.paginator) return
    this.paginator.selectedPage = 1;
  }

  // setFilterStorage(){
  //   const localStorageSea = JSON.parse(localStorage.getItem(this.localStorageSearch));
  //   this.searchParameter = localStorageSea;
  // }

  getPositionList( ) {
    this.companyId = this.authInfo.getCompanyId();
    if (!this.companyId) {
      this.dataList = [];
      this.dataOriginalList = [];
      return;
    }
    this.positionService.getPositionsByCompany(this.companyId).subscribe((data: any) => {
      if (data.succeded) {
        this.dataList = this.filterTable(data.dataList);
        this.dataOriginalList = data.dataList;
      } else {
        this._toastr.error(data.errors[0]);
      }
    }, error => {
      this._toastr.error('Ocurrió un error al cargar la lista de posiciones, por favor intente más tarde o comuníquese con HelpDesk.');
    });
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  sendPositionToMap(position: IPositionView) {
    let model: IPositionToMap = {
      positionId: position.positionId,
      conditionId: 1,
      userId: this.authInfo.getUserId()
    }
    this.swalQuestionSend('¿Está seguro que desea enviar este cargo al MAP?', '#28a745', 'Enviar', () => {
      this.positionService.sendPositionToMap(model).subscribe({
        next: (res) => {
          if (!res.succeded) {
            res.errors.forEach(err => {
              this._toastr.error(err);
            })
            return;
          }
          this.getPositionList()
        },
        error: (err) => {
          this._toastr.error("Ha ocurrido un error tratando de enviar el cargo al MAP");
        }
      })
    });


  }

  getPaginatedRecords(event) {
    this.records = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];

    this.registersPerPage = event?.registersPerPage;
    this.selectedPage = event?.selectedPage;

  }

  openCreatePositionModal(isView?: boolean, isEdit?: boolean, position?: any) {
    this.dialog.open(CreatePositionModalComponent, {
      width: '100%',
      data: {
        isView,
        isEdit,
        position
      }
    }).afterClosed().subscribe({
      next: (createdOrEdited: any) => {
        if (createdOrEdited && this.companyId) {
          this.getPositionList()
        }
      },
      error: err => this._toastr.error(err)
    })
  }

  exportToExcell() {
    let excelHeaders: string[][] = [[
      "Secuencia del Cargo",
      "Nombre Corto",
      "Cargo",
      "Código Cargo",
      "Grupo Ocupacional",
      "Posiciones Ocupada",
      "Posiciones disponibles",
      "Condición",
      "Estatus",
    ]]
    let positionList: any[] = [];
    let array = this.dataList
    if (this.filteredList.enable) {
      array = this.filteredList?.records
    }
    array.forEach((e) => {
      positionList.push({
        positionId: e.positionId,
        shortName: e.shortName,
        positionName: e.positionName,
        positionCode: e.positionCode,
        occupationalGroup: e.occupationalGroup,
        occupiedPositions: e.occupiedPositions,
        approvalPositions: e.approvalPositions,
        condition: e.condition,
        status: e.status ? "Activo" : "Inactivo",
      })
    })
    this.srvExportToExcel.exportToExcelSpecificColumns(positionList, excelHeaders, 'Lista de Cargos');
  }

  positionListDelete(position) {
    let data = {
      positionId: position,
      userId: this.authInfo.getUserId()
    }
    this.swalQuestion(() => {
      this.positionService.deletePosition(data).subscribe(res => {
        if (res?.errors?.length > 0) {
          this._toastr.error(res?.errors[0]);
          return;
        }
        this._toastr.success('Registro eliminado');
        this.getPositionList()
      }, error => {
        this._toastr.error('Ha ocurrido un error al eliminar el registro, por favor intente más tarde o comuníquese con HelpDesk.');
      });
    })
  }

  private swalQuestion(fn: () => void) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#eb220c',
      title: "¿Está seguro que desea eliminar este registro?",
      icon: "question"
    }).then(res => {
      if (res?.isConfirmed) {
        fn();
      }
    });
  }

  private swalQuestionSend(title: string, confirmButtonColor: string, confirm: string, fn: () => void) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: confirmButtonColor,
      title: title,
      icon: "question",
      confirmButtonText: confirm,
      cancelButtonText: 'Cancelar',
    }).then(res => {
      if (res?.isConfirmed) {
        fn();
      }
    });
  }

  openReasonReject(reasonReject: string) {
    this.dialog.open(ViewReasonRejectComponent, {
      width: '40%',
      data: { reasonReject: reasonReject }
    })
  }

  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

  onKeyParameterChange(event){
      this.filtervalues = event;
  }

  openPositionReport() {
    const conditions = [...this.getConditionsSeleted];
    let condition:any = '';
    conditions.forEach((resp, index )=> {
      condition += resp
      let resta = conditions.length - index;
      if(resta !== 1){
        condition += '-';
      }
    })

    let statusArray:any[] = [];
    this.selectedItems.forEach(resp => {
        if(resp.item_id == 1 || resp.item_id ==2 ){
           statusArray.push(resp)
        }
    })
     const companyId: number = this.authInfo.getCompanyId();
    const status: any = statusArray[0]?.item_id == 1 && statusArray?.length <= 1 ? 1 : statusArray[0]?.item_id == 2 && statusArray?.length <= 1  ? 0 : statusArray[0]?.item_id == 1 && statusArray[1]?.item_id == 1 ? -1 : -1;
    const reportCode: ReportCode = ReportCode.Positions;
    const PropkeyId: any = this.filtervalues?.propKeyId ?? 0
    const SearchParameter: any = this.filterText ?? ''
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&ConditionId=${condition}&PropkeyId=${PropkeyId}&SearchParameter=${SearchParameter}&Status=${status}&Page=${this.selectedPage}&PageSize=${this.registersPerPage}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Cargos ',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

}
