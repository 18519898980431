import { SweetAlert } from '../../../shared/utils/Sweet-Alert';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { approvedPayroll, Payroll } from '../open-payroll/models/mock-data';
import Swal from 'sweetalert2';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { INgxMultiselectDropdown } from 'src/app/shared/models/NgxMultiselectDropdownSettings';
import { ProcessPayrollService } from 'src/app/services/process-payroll.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { headerCondition, IEmployeeType, IHeader, IPeriod, PeriodConditions } from '@payroll/payroll-models';
import { ToastrService } from 'ngx-toastr';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ExpenseDetailModalComponent } from './expense-detail-modal/expense-detail-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ExpenseItemsComponent } from './expense-items/expense-items.component';
import { StateSigeiComponent } from './state-sigei/state-sigei.component';
import { AddExpenseDetailModalComponent } from './add-expense-detail-modal/add-expense-detail-modal.component';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { PayrollConditions } from 'src/app/shared/enums/PayrollConditions';
import { BudgetAreaService } from 'src/app/services/budget-area.service';
import { RejectFilesHaciendaComponent } from './reject-files-hacienda/reject-files-hacienda.component';
declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'process-payroll',
  templateUrl: './process-payroll.component.html',
  styleUrls: ['./process-payroll.component.scss']
})
export class ProcessPayrollComponent implements OnInit {

  statusOptions = [
    { item_id: 3, item_text: 'Aprobada' },
    { item_id: 5, item_text: 'Definitiva' },
  ];
  periods = []
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'RECORDSNEWS',
    export: { key: 'EXPORT', value: false },
    ViewStatus: { key: 'ViewStatus', value: false },
    Send: { key: 'Send', value: false },
    Conclude: { key: 'Conclude', value: false },
    Search: { key: 'Search', value: false },
    ExpenseDetail: { key: 'ExpenseDetail', value: false },
  }

  message;
  configPayrollType = {
    displayKey: 'stringData',
    search: true,
    height: 'auto',
    placeholder: 'Seleccione una opción',
    moreText: '...',
    noResultsFound: 'No se han encontrado registros',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'stringData'
  };
  configPeriod = {
    displayKey: 'description',
    search: true,
    height: 'auto',
    placeholder: 'Seleccione una opción',
    moreText: '...',
    noResultsFound: 'No se han encontrado registros',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'description'
  };

  configBudget = {
    displayKey: 'description',
    search: true,
    height: 'auto',
    placeholder: 'Seleccione una opción',
    moreText: '...',
    noResultsFound: 'No se han encontrado registros',
    searchPlaceholder: 'Buscar',
    searchOnKey: 'description'
  };
  payrollTypes = []
  budgets = []

  payrollTypeSelected;
  budgetSelected;

  loading = false
  @ViewChild('paginator') paginator: CaptionComponent;
  sweetAlert = new SweetAlert()
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  periodSelected: IPeriod;
  paginatedRecords: any[] = [];
  selectedItems: any[] = [];
  records: IHeader[] = [];
  recordsOriginal: IHeader[] = [];
  filterText: string = '';
  selectedPage: any
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  filteredListO: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "headerId", displayName: "Secuencia" },
      { propkey: "periodRelated", displayName: "Período anterior" },
    ];

  dropdownSettings: INgxMultiselectDropdown = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: false,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };



  reasons: string[] = ['Razón 1', 'Razón 2', 'Razón 3', 'Razón 4', 'Razón 5'];


  constructor(
    private _exlService: ExportToExcelService,
    private srvProcess: ProcessPayrollService,
    private _toastr: ToastrService,
    private dialog: MatDialog,
    private srvParameter: ParameterControlService,
    private _cdRef: ChangeDetectorRef,
    public authInfo: AuthInfoService,
    private srvBudget: BudgetAreaService,
  ) {
  }

  ngOnInit(): void {
    this.getPeriods()
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        // this.getPayrollTypes()
        // this.loadPeriods()
        // this.loadEmployeeTypes()
        this.getHeaders()
        this.getBudgetArea()
        this.getPayrollTypes()
      }
    })
    this.privilege = this.authInfo.setPrivileges(this.privilege);
    applySort();
  }

  getBudgetArea() {
    this.srvBudget.getBudgetAreas().subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) { this._toastr.error(res.errors[0]); return; }
        this.budgets = res.dataList.filter(x => x.status == true)
      }, error: (err: any) => {
        this._toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }
  getPayrollTypes() {
    this.srvParameter.getParameters(keyWord.PayrollType).subscribe({
      next: (res: any) => {
        if (res.errors.length > 0) { this._toastr.error(res.errors[0]); return; }
        this.payrollTypes = res.dataList
      }, error: (err: any) => {
        this._toastr.error('Ha ocurrido un error inesperado')
      }
    })
  }

  filterRecords() {
    const periodId = this.periodSelected.payrollPeriodId
    const typeId = this.payrollTypeSelected.ocode
    const budgetId = this.budgetSelected.budgetAreaId
    this.records = [...this.recordsOriginal]
    if (periodId != void(0)) {
      this.records = this.records.filter(x => x.payrollPeriodId == periodId)
    }
    if  (typeId != void(0)) {
      this.records = this.records.filter(x => x.payrollTypeId == typeId)
    }
    if (budgetId != void(0)) {
      this.records = this.records.filter(x => x.budgetAreaId == budgetId)
    }
  }
  filterFilteredRecords() {
    this.filteredList.records = [...this.filteredListO.records]
    const periodId = this.periodSelected.payrollPeriodId
    const typeId = this.payrollTypeSelected.ocode
    const budgetId = this.budgetSelected.budgetAreaId
    let list = [...this.filteredListO.records]
    if (periodId) {
      this.filteredList.records = list.filter(x => x.payrollPeriodId == periodId)
    }
    if (typeId) {
      this.filteredList.records = list.filter(x => x.payrollTypeId == typeId)
    }
    if (budgetId) {
      this.filteredList.records = list.filter(x => x.budgetAreaId == budgetId)
    }
  }

  filterByPeriod() {
    const filterIsNotApplied = this.filteredList.enable == false
    if (filterIsNotApplied) {
      this.filterRecords()
      return this.records
    } else {
      this.filterFilteredRecords()
      return this.filteredList.records
    }
  }

  filterFilteredList() {

  }


  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  getHeadersByPeriod() {
    this.srvProcess.getHeadersByPeriod(this.periodSelected.payrollPeriodId).subscribe((res) => {
      if (res.succeded) {
        this.records = res.dataList.filter(x => x.conditionId == headerCondition.approved)
      } else {
        this._toastr.error(res.errors[0])
      }
    }, e => {
      this._toastr.error(this.errorMessage)
    })
  }


  getPeriods() {
    this.srvProcess.getPeriodsWithPayrollDefinitiveAndApprove(this.authInfo.getCompanyId()).subscribe({
      next: (res: any) => {
        if (res.errors.legnth > 0) {
          this._toastr.error(res.errors[0])
          return
        }
        this.periods = res.dataList
      }
    })
  }

  filterByEstatus() {

    let array = []
    const periodId = this.periodSelected.payrollPeriodId
    const filteredEnable = this.filteredList.enable
    const items = this.selectedItems
    let result = []

    if (filteredEnable) {
      array = [...this.filteredListO.records]
      result = [...this.filteredListO.records]
    } else {
      array = [...this.recordsOriginal]
      result = [...this.recordsOriginal]
    }

    if (periodId) {
      result = result.filter(x => x.payrollPeriodId == periodId)
    }

    if (items?.length > 0) {
      const approved = items.find(x => x.item_id == 3)
      const definitive = items.find(x => x.item_id == 5)
      if (approved && !definitive) {
        result = result.filter(x => x.conditionId == 3)
      }
      if (definitive && !approved) {
        result = result.filter(x => x.conditionId == 5)
      }
    }

    if (filteredEnable) {
      this.filteredList.records = result
    } else {
      this.records = result
    }
  }

  getReasons(event) {
  }

  showModal(modalName: string) {
    $(`#${modalName}`).modal('show');
  }




  setFilterToLocalStorage() {
    localStorage.setItem('process-payroll_filter', JSON.stringify(this.selectedItems));
  }


  exportToExcel() {
    this._exlService.exportToExcel(this.records, 'Lista de Archivos de nómina');
  }

  
  getFilteredList(event) {
    this.filteredList = event;
    this.filteredListO.enable = event.enable
    this.filteredListO.records = [...event.records]
    this.paginator.selectedPage = 1;
    this.filterByEstatus()
  }

  getPaginatedRecords(event) {
    this.paginatedRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this._cdRef.detectChanges();
    this.selectedPage = event.selectedPage
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  conclude(item) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'No',
      confirmButtonText: 'Sí',
      title: "¿Está seguro que desea cerrar esta nómina?",
      icon: "question"
    }).then(res => {
      if (res.isConfirmed) {
        const model = {
          userId: this.authInfo.getUserId(),
          headerId: item.headerId
        }
        this.srvProcess.concludePayroll(model).subscribe({
          next: (res: any) => {
            if (res.errors.length > 0) {
              this._toastr.error(res.errors[0])
              return
            }
            this._toastr.success('Ha cerrado la nómina')
            this.getHeaders()


          }, error: (error: any) => {

          }
        })
      }
    });
  }

  openAddDetail(item: IHeader, mode: 'send' | 'save'): void {
    const { headerId } = item
    this.dialog.open(AddExpenseDetailModalComponent, {
      width: '100%',
      data: {
        item,
        headerId,
        mode
      },
      disableClose: true
    }).afterClosed().subscribe((result) => {

    });
  }

  openStatus(header): void {
    const { headerId } = header
    this.dialog.open(StateSigeiComponent, {
      width: '100%',
      data: {
        headerId,
        payrollTypeId: header?.payrollTypeId
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.getHeaders()
      }
    });

  }

  getHeaders() {
    this.srvProcess.getHeadersApproved().subscribe((res: any) => {
      if (!res.succeded) {
        this._toastr.error(res.errors[0])
        return
      }
      res.dataList.forEach(e => {
        e.showConcludeBtn = false;
        if (e.canBeDefinitive == 1) {
          if (e.conditionId === PayrollConditions.approved) {
            e.showConcludeBtn = true
          }
        }
      });
      this.records = res.dataList
      this.recordsOriginal = res.dataList

      this.filterByEstatus()
    }, err => {
      this._toastr.error('Ha ocurrido un error inesperado')
    })
  }
  changePeriod() {

  }
  onDeSelectAll() {
  }
  onItemDeSelect(item: any) {
    this.filterByEstatus()
  }
  onItemSelect(item: any) {
    this.filterByEstatus()
  }
  onSelectAll(item: any) {
  }

  openModalReabrirNomina(index) {
    const element = document.querySelectorAll('.reabrirNomina')[index];
    element.setAttribute('data-toggle', 'modal');
    element.setAttribute('data-target', '#reabrir');
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }


  exportToPdf() {
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.PayrollFile;
    const periodId = this.periodSelected.payrollPeriodId
    const payrollTypeId = this.payrollTypeSelected.ocode
    const budgetAreaId = this.budgetSelected.budgetAreaId
    const conditions: number | any = this.selectedItems?.length === 2 || this.selectedItems?.length === 0 ? '3-5' : this.selectedItems[0]?.item_id;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&PayrollPeriodId=${periodId || 0}&PayrollTypeId=${payrollTypeId || ''}&BudgetAreaId=${budgetAreaId || 0}&PayrollPeriodIdRelated=0&Conditions=${conditions}&PropkeyId=0&SearchParameter=&Page=${this.selectedPage}&PageSize=${this.paginatedRecords.length}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Unidades Organizativas',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }
}
