import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.css']
})
export class SearchModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SearchModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.dialogRef.disableClose = true;
    }

  ngOnInit(): void {
  }

  Accept(item): void {
    const row = { Applied: true, item: item }
    this.dialogRef.close(row);
  }

  Cancel(): void {
    const row = { Applied: false, item: null }
    this.dialogRef.close(row);
  }

}
