<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Partidas del Gasto</h2>
    <button type="button" (click)="close()" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <form [formGroup]="form">
            <div class="row">
                <div class="form-group  col-lg-6">
                    <label>Cuenta Presupuestaria<span class="text-danger"> *</span></label>
                    <input formControlName="budgetAccount" 
                    type="text" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('budgetAccount')}}</span>
                </div>
                <div class="form-group  col-lg-6">
                    <label>Total de Ingreso<span class="text-danger"> *</span></label>
                    <input formControlName="totalIncome" placeholder="RD$00.00" type="text" currencyMask
                    [options]="{precision:2}" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('totalIncome')}}</span>
                </div>
                <div class="form-group col-lg-6">
                    <label>Total de Descuento<span class="text-danger"> *</span></label>
                    <input formControlName="fullDiscount" placeholder="RD$00.00" type="text" currencyMask
                    [options]="{precision:2}" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('fullDiscount')}}</span>
                </div>
                <div class="form-group  col-lg-6">
                    <label>Neto Primera Quincena<span class="text-danger"> *</span></label>
                    <input formControlName="netFirstFortnight" placeholder="RD$00.00" type="text" currencyMask
                    [options]="{precision:2}" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('netFirstFortnight')}}</span>
                </div>
                <div class="form-group  col-lg-6">
                    <label>Neto Segunda Quincena<span class="text-danger"> *</span></label>
                    <input formControlName="netSecondFortnight" placeholder="RD$00.00" type="text" currencyMask
                    [options]="{precision:2}" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('netSecondFortnight')}}</span>
                </div>
                <div class="form-group col-lg-6">
                    <label>Patrón<span class="text-danger"> *</span></label>
                    <input formControlName="pattern" placeholder="RD$00.00" type="text" currencyMask
                    [options]="{precision:2}" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('pattern')}}</span>
                </div>
                <div class="form-group  col-lg-6">
                    <label>Riesgo<span class="text-danger"> *</span></label>
                    <input formControlName="risk" placeholder="RD$00.00" type="text" currencyMask
                    [options]="{precision:2}" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('risk')}}</span>
                </div>
                <div class="form-group  col-lg-6">
                    <label>SFS<span class="text-danger"> *</span></label>
                    <input formControlName="sfs" placeholder="RD$00.00" type="text" currencyMask
                    [options]="{precision:2}" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('sfs')}}</span>
                </div>
                <div class="form-group col-lg-6">
                    <label>Retiro Complementario<span class="text-danger"> *</span></label>
                    <input formControlName="complementaryRetreat" placeholder="RD$00.00" type="text" currencyMask
                    [options]="{precision:2}" class="form-control form-control-solid form-control-lg">
                    <span class="text-danger">{{getError('complementaryRetreat')}}</span>
                </div>
            </div>
        </form>
    </div>
<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button (click)="save()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>