import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-payment-details',
  templateUrl: './employee-payment-details.component.html',
  styleUrls: ['./employee-payment-details.component.css']
})
export class EmployeePaymentDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
