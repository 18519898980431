import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalViewsEnum } from 'src/app/models/modal-views';
import { ITeacherReviewView } from 'src/app/models/teacher.model';
import { TeacherReviewsModalComponent } from '../teacher-reviews-modal/teacher-reviews-modal.component';
import { EmployeesService } from 'src/app/services/employees.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import Swal from 'sweetalert2';
import { AuthInfoService } from 'src/app/services/auth-info.service';

@Component({
  selector: 'app-teacher-reviews-list',
  templateUrl: './teacher-reviews-list.component.html',
  styleUrls: ['./teacher-reviews-list.component.css']
})
export class TeacherReviewsListComponent implements OnInit, OnChanges {

  @Input() employeeId: number | null = null;

  searchList: ITeacherReviewView[] = []; searchBarDisplayNames: { propKeyId?: number, propkey?: string, displayName: string }[] =
    [
      { propKeyId: 1, propkey: "reviewId", displayName: "Secuencia" },
      { propKeyId: 2, propkey: "comment", displayName: "Comentario" },
      { propKeyId: 3, propkey: "createdBy", displayName: "Registrado por" }
    ];

  filterText = '';
  searchKeySelected = '';

  searchPropKeySelected!: { propKeyId?: number, propkey?: string, displayName: string };

  registersPerPage: number;
  selectedPage: number;

  selectedItems: any[] = [];
  viewTypes = ModalViewsEnum;

  filteredList: { records: ITeacherReviewView[], enable: boolean } = { records: [], enable: false };

  reviews: ITeacherReviewView[] = [];
  reviewsFiltered: ITeacherReviewView[] = [];
  reviewsPaginated: ITeacherReviewView[] = [];

  constructor(private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private employeeService: EmployeesService,
    private toastService: ToastService,
    private authInfo: AuthInfoService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['employeeId'].currentValue) {
      this.loadList()
    }
  }

  loadList() {

    if (!this.employeeId) {
      return;
    }

    this.employeeService.getTeacherReviews(0,0, this.employeeId).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          });

          res.warnings.forEach(warn=>{
            this.toastService.warning(warn);
          })

          return;
        }

        this.reviews = res.dataList;
        this.filter();

      },
      error: (err) => {
        this.toastService.error('Ha ocurrido un error tratando de consultar el listado de revisiones.');
      }
    });
  }

  OpenCreateModal(viewType: number, model: ITeacherReviewView): void {
    const employeeId = +this.employeeId;

    this.dialog.open(TeacherReviewsModalComponent, {
      data: { viewType, model, employeeId },
      width: '70%',
      disableClose: true
    })
      .afterClosed()
      .subscribe((result) => {
        if (result == true) {
          this.loadList()
        }
      });
  }

  deleteReview(item: ITeacherReviewView) {
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Inactivar',
      confirmButtonColor: '#e63946',
      cancelButtonText: 'Cancelar',
      title: '¿Seguro que desea inactivar esta revisión?',

    }).then((result) => {
      if (result.isConfirmed) {
        this.employeeService.deleteTeacherReviews(item.reviewId, this.authInfo.getUserId()).subscribe({
          next: (res) => {
            if (!res.succeded) {
              res.errors.forEach(err => {
                this.toastService.error(err);
              });

              res.warnings.forEach(warn=>{
                this.toastService.warning(warn);
              })
              return;
            }

            this.loadList();
            this.toastService.success('Revisión inactivada satisfactoriamente');

          },
          error: (err) => {
            this.toastService.error('Ha ocurrido un error tratando de inactivar la revisión del docente.')
          }
        });

      } else {
        return;
      }
    })
  }

  onKeyParameterChange(value: { propKeyId?: number, propkey: string, displayName: string } | null) {
    this.searchPropKeySelected = value ?? null;
  }

  onKeyChange(text: string) {
    this.filterText = text ?? '';
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.reviewsFiltered = this.filterTable(this.reviews)
    }
  }

  filterTable(list: ITeacherReviewView[]) {
    let sl = this.selectedItems;

    if (this.selectedItems.length === 0) {
      return list;
    }



    return list;
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.filteredList.records = this.filterTable(event.records)
    this.cdRef.detectChanges()
  }

  getPaginatedRecords(event) {
    this.reviewsPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    this.registersPerPage = event?.registersPerPage;
    this.selectedPage = event?.selectedPage;
    this.cdRef.detectChanges()
  }

}
