import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { CompaniesService } from 'src/app/services/companies.service';
import { docService } from 'src/app/services/doc.service';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { personalFile } from 'src/app/shared/utils/parameterDoc';
import { environment } from 'src/environments/environment';
//import { PersonalRequisitionsModel } from '../models/personal-requisitions';
import { Location } from '@angular/common';
type GUID = string & { isGuid: true };

@Component({
  selector: 'app-administrative-and-teaching-employees',
  templateUrl: './administrative-and-teaching-employees.component.html',
  styleUrls: ['./administrative-and-teaching-employees.component.css']
})
export class AdministrativeAndTeachingEmployeesComponent implements OnInit {


  constructor(private _route: ActivatedRoute, private _requisitionSrv: PersonalRequsitionService, private _toastService: ToastService,
    private _companiesService: CompaniesService, private srvDoc: docService, private sanitizer: DomSanitizer, private location: Location
  ) { }

  ngOnInit(): void {
   
  }

  goBack(){
    this.location.back();
  }

}
