import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { HttpClient } from '@angular/common/http';
import { CatalogResponseCertificationAccount, PostCatalogResponseCertificationAccount } from '@payroll/certification-codes/models/certification-codes.model';

@Injectable({
  providedIn: 'root'
})
export class CertificationCodeService {
  private readonly API_URL = environment.api_url;

  constructor(private http:HttpClient, private auth: AuthInfoService) { }

  getCertificationCodes(){
    return this.http.get<ResponseModel<CatalogResponseCertificationAccount>>(`${this.API_URL}/CatalogResponseCertificationAccounts?CompanyId=${this.auth?.getCompanyId()}&Type=1`);
  }

  postCertificationCodes(catalogResponseCertificationAccount: PostCatalogResponseCertificationAccount){
    return this.http.post<ResponseModel<null>>(`${this.API_URL}/CatalogResponseCertificationAccounts`, catalogResponseCertificationAccount);
  }

  putCertificationCodes(catalogResponseCertificationAccount: PostCatalogResponseCertificationAccount){
    return this.http.put<ResponseModel<null>>(`${this.API_URL}/CatalogResponseCertificationAccounts`, catalogResponseCertificationAccount);
  }

  deleteCertificationCodes(model){
    return this.http.put<ResponseModel<null>>(`${this.API_URL}/CatalogResponseCertificationAccounts/inactive`, model);
  }

}