<div class="row">
    <div class="form-group col-lg-4">
        <label>Reconocimiento </label>
        <input type="text" class="form-control form-control-lg" />
    </div>
    <div class="form-group col-lg-4">
        <label>No. Servidor Público </label>
        <input type="text" class="form-control form-control-lg" />
    </div>
    <div class="form-group col-lg-4">
        <label>Nombre Completo <span class="text-danger">*</span></label>
        <input type="text" class="form-control form-control-lg" />
    </div>
</div>
<div class="row">
    <div class="form-group col-lg-3">
        <label>Fecha Inicio <span class="text-danger">*</span></label>
        <input type="date" class="form-control form-control-lg" />
    </div>
    <div class="form-group col-lg-3">
        <label>Fecha Fin </label>
        <input type="date" class="form-control form-control-lg" />
    </div>
    
    <div class="form-group col-lg-6">
        <label>&nbsp;</label>
        <div class="d-flex">
            <div class="card flex-row align-items-center justify-content-center flex-grow-1 mr-3 mb-3 p-2">
                <strong class="mx-4">Aplica Pensión<span class="text-danger">*</span></strong>
                <span class="switch switch-sm switch-primary mx-2">
                    <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
                </span>
            </div>
            <div class="card flex-row align-items-center justify-content-center flex-grow-1 mr-3 mb-3 p-2">
                <strong class="mx-4">Aplica Vacaciones<span class="text-danger">*</span></strong>
                <span class="switch switch-sm switch-primary mx-2">
                    <label><input class="cursor-pointer abc-checkbox-primary" type="checkbox"/><span></span></label>
                </span>
            </div>
        </div>
    </div>
</div>                    
<div class="row">
    <div class="table-responsive border-u bg-white p-0">
        <table class="table table-vertical-center">
            <thead class="bg-primary">
                <tr>
                    <th class="text-center">No.</th>
                    <th class="text-center">Institución</th>
                    <th class="text-center">Cargo</th>
                    <th class="text-center">Desde</th>
                    <th class="text-center">Hasta</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="col-md-12 gutter-t">
    <div class=" px-5">
        <div class="form-group d-flex justify-content-between align-items-center p-3">
            <button [routerLink]="['/employees']"  class="btn btn-pill font-weight-bolder btn-shadow btn-warning mr-3 ">
                <i class="flaticon2-left-arrow-1"></i>
                <span>Volver</span>
            </button> 
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-success ">
                <i class="flaticon-disco-flexible"></i>
                <span>Guardar</span>
            </button>                   
        </div>
    </div>
</div>