import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { FinalPayrollService } from 'src/app/services/final-payroll.service';
import { PagedResult } from 'src/app/shared/models/page-result';
import { EmployeeToPayroll, IEmployeeGET, IFinalPayrollPOST } from '../models/models';

@Component({
  selector: 'app-final-payroll-search-modal',
  templateUrl: './final-payroll-search-modal.component.html',
  styleUrls: ['./final-payroll-search-modal.component.css']
})
export class FinalPayrollSearchModalComponent implements OnInit, AfterViewChecked {

  selectedEmployee: IEmployeeGET
  employeeList: any[] = []
  employeesPagedResut = new PagedResult<any>();
  paginatedRecords: any[] = []
  budgetAreaId:number = 0
  periodId :number = 0
  searchParameter;
  
  isSingleClick: Boolean = true;
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkeyId:number, propkey?: string, displayName: string }[] = [
    { propkeyId: 2,propkey: "employNumber", displayName: "Código de Servidor Público" },
    { propkeyId: 3, propkey: "name", displayName: "Servidor Público" },
    { propkeyId: 4, propkey: "personalIdentification", displayName: "Documento de Identidad" },
  ];
    
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {budgetAreaId:number, payrollPeriodId: number},
    private dialogRef: MatDialogRef<FinalPayrollSearchModalComponent>,
    private srvFinal: FinalPayrollService,
    private _changeDet: ChangeDetectorRef,
    private toastr: ToastrService,
    private authInfo: AuthInfoService
  ) { }
  
  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  ngOnInit(): void {
    this.budgetAreaId = this.data.budgetAreaId
    this.periodId = this.data.payrollPeriodId
    this.getEmployees()
  }
  
  selectEmployee(employee) {
    
    setTimeout(()=>{
      if(this.isSingleClick){
        if (this.selectedEmployee && this.selectedEmployee.employeeId === employee.employeeId) {
          this.selectedEmployee = null;
          return;
        }
        this.selectedEmployee = { ...employee };
        this.isSingleClick = true;
      }
    },250)
  }

  
  filterSearch(event){
    this.employeesPagedResut.page = 1
    this.searchParameter = event
    this.getEmployees()
  }
  getEmployees(){
    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value == '' ? 0 : this.searchParameter?.propkeyId
    this.srvFinal.getEmployeeInactivePaginated(this.budgetAreaId, page, pageSize, propKeyId, text).subscribe({
      next: (res:any) => {
        if(res.errors.length > 0){this.toastr.error(res.errors[0]); return}
        if(res.warnings.length > 0){this.toastr.warning(res.errors[0]); return}
        if(res.succeded){
          this.employeeList = res.singleData.items
          this.employeesPagedResut = res.singleData
        }
      }, error: (error:any) => {

      }
    })
  }


  
  doubleClick(employee){
    
    this.selectedEmployee = { ...employee };
    this.isSingleClick = true;
    this.save()
  }
  isEmployeeSelected(employee) {
    if (!employee) return false;
    if (!this.selectedEmployee) return false;
    return this.selectedEmployee.employeeId === employee.employeeId;
  }
  
  close(){
    this.dialogRef.close({success: false})
  }
  save(){
    const payroll = this.getFinalPayrollValues()
    this.srvFinal.postFinalPayroll(payroll).subscribe((res:any) => {
      if(res.succeded){
        this.toastr.success('Se han guardado los datos')
        this.dialogRef.close({success: true})
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }
  getEmployeeToPayrollValues(){
    const employee = this.selectedEmployee
    const employeeToPayroll: EmployeeToPayroll = {
      admissionDate: employee.admissionDate,
      contractExpiration: employee.contractExpiration,
      contractType: employee.contractType,
      departmentId: employee.departmentId,
      departmentName: employee.departmentName,
      employeeId: employee.employeeId,
      employeeType: employee.employeeType,
      employNumber: employee.employNumber,
      personalIdentification: employee.personalIdentification,
      positionId: employee.positionId,
      positionName: employee.positionName,
      salary: employee.salary,
      typeContractId: employee.typeContractId,
      typeEmployeeId: employee.typeEmployeeId,
      typeIdentification: employee.typeIdentification,
      typeIdentificationId: employee.typeIdentificationId,
    }
    return employeeToPayroll
  }
  getFinalPayrollValues(){
    const values: IFinalPayrollPOST = {
      budgetAreaId: this.budgetAreaId,
      companyId: this.authInfo.getCompanyId(),
      createDate: new Date(),
      createUserId: this.authInfo.getUserId(),
      employeeToPayroll: [this.getEmployeeToPayrollValues()],
      headerId: 0,
      payrollPeriodId: this.periodId,
      payrollTypeId: 1,
      status: true,
    }
    return values
  }
  
  

  getPaginatedRecords(event) {
    if (this.employeesPagedResut.page == event.selectedPage && this.employeesPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.employeesPagedResut.page = event.selectedPage;
    this.employeesPagedResut.pageSize = event.registersPerPage;
    this.getEmployees()
  }
  getFilteredList(event) {
    this.filteredList = event;
  }
}
