import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollReportService } from 'src/app/services/payroll-report.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { docService } from 'src/app/services/doc.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { OverallSummary } from '../models/overallSummary.model';
import * as XLSX from 'xlsx';
import { Period } from '@payroll/schedule/models/period.model';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { openReport } from 'src/app/shared/utils/utility';
import { url } from 'inspector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payroll-parcial',
  templateUrl: './payroll-parcial.component.html',
  styleUrls: ['./payroll-parcial.component.css']
})
export class PayrollParcialComponent implements OnInit {


  dropdownSettings = {
    singleSelection: true,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: false,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };


  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 0, item_text: 'Inactivos' }
  ];

  periods: Period[] = [];
  filteredPeriods: Period[] = [];
  periodSelected: Period;
  yearsList: any[] = []
  payrollTypes: any[] = [];
  payrollTypesSelected: any;
  employeeType: any[] = [];
  employeeTypeSelected: any[] = [];
  form: FormGroup;
  companies: any[] = [];
  public company: any;
  budgetAreaList: any[] = [];
  areaSelected: any;
  versionSelected: any;
  payrollVersions: any[] = [];
  selectedPayrollType: any;
  periodRelatedSelected: any;
  relatedPeriods = [

  ];

  coletilla: any = '';
  isThereColetilla: any = false;
  constructor(
    private parameterServices: ParameterControlService,
    private payrollReportService: PayrollReportService,
    private _toastService: ToastService,
    private fb: FormBuilder,
    private srvDoc: docService,
    public authInfo: AuthInfoService,
    private _companiesService: CompaniesService,
    private location: Location,
    private payrollService: PayrollService,
  ) {

    this.buildForm();
  }

  ngOnInit(): void {
    this.getPayrollTypes();
    this.getPeriods();
    this.companyById(this.authInfo.getCompanyId())
  }

  getOverallSummary() {
    const formValue = this.form?.value;
    const companyId = this.authInfo.getCompanyId();
    const payrollPeriodId = formValue?.period?.payrollPeriodId || 0;
    const payrollTypeId = formValue?.payrollType?.ocode || 0;
    const budgetAreaId = formValue?.area?.budgetAreaId || 0;
    const employeTpe = formValue?.employeeType?.employeeTypeId || 0;
    const version = formValue?.version?.versionId || 0;
    const status = formValue?.status.length == 0 ? '' : formValue?.status[0]?.item_id
    const periodRelated: number = this.form?.get('relatedPeriod')?.value?.payrollPeriodId || 0;

    const url = `${environment.reportUrl}?ReportCode=partial_payroll&CompanyId=${companyId}&PeriodId=${payrollPeriodId}&PayrollTypeId=${payrollTypeId}&BudgetAreaId=${budgetAreaId}&VersionId=${version}&EmployType=${employeTpe}&PayrollPeriodIdRelated=${periodRelated}&status=${status}`;
    openReport({
      url: url,
      title: 'Reporte de Nómina Parcial',
      width: '1024',
      height: '724'
    })
  }

  buildForm() {
    this.form = this.fb.group({
      payrollType: [null, [Validators.required]],
      employeeType: [null, [Validators.required]],
      period: [null, [Validators.required]],
      relatedPeriod: [null],
      area: [null, [Validators.required]],
      version: [null, [Validators.required]],
      status: [[]],
    })
  }

  changePayrollType(payrollType: ParameterControl) {
    if (payrollType?.ocode === 4) {
      this.addValidation('relatedPeriod', [Validators.required]);
      this.getBeforePeriod();
      return;
    }
    this.relatedPeriods = [];
    this.addValidation('relatedPeriod', null);
    this.getPayrollVersions();
  }

  private addValidation(controlName: string, validators) {
    this.form.get(controlName).setValidators(validators);
    this.form.get(controlName).updateValueAndValidity();
  }

  getBeforePeriod() {
    this.payrollService.getBeforePeriod().subscribe(res => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.relatedPeriods = res.dataList;
      this.getPayrollVersions();
    });
  }

  goBack() {
    this.location.back();
  }

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
    })
  }

  get getPayrollTypeSeleted() {
    return this.form.get('payrollType')?.value;
  }

  get isPeriodAndAreaSeleted() {
    return this.form.get('period')?.valid && this.form.get('area')?.valid;
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  getPayrollTypes() {
    this.parameterServices.getParameters(keyWord.PayrollType).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.payrollTypes = res.dataList;
    }, error => {
      this._toastService.error('Ocurrió un error inesperado', '');
    });
  }

  getPayrollVersions() {
    const formValue = this.form?.value;
    const payrollPeriodId = formValue?.period?.payrollPeriodId || 0;
    const payrollTypeId = formValue?.payrollType?.ocode;
    const budgetAreaId = formValue?.area?.budgetAreaId || 0;
    this.payrollVersions = [];
    this.form.get('version').setValue(null);
    this.form.get('employeeType').setValue(null);
    this.employeeType = []
    this.isThereColetilla = false
    if (payrollPeriodId === 0 || budgetAreaId === 0 || (payrollTypeId === null || payrollTypeId === undefined)) {
      return;
    }
    this.payrollService.getPayrollVersions(payrollPeriodId, payrollTypeId, budgetAreaId).subscribe((res: any) => {
      if (res?.errors?.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.payrollVersions = res?.dataList?.map(v => {
        return {
          ...v,
          versionDescription: `${v?.headerId} - ${v?.versionId} - ${v?.headerCondition}`
        }
      });
    }, error => {

    })
  }

  onPayrollVersionChange(version: any) {
    this.getEmployeeType(version?.headerId)
  }

  getPeriods() {
    this.payrollService.getBudgetAreas().subscribe((res: any) => {
      this.budgetAreaList = res.dataList.filter(ba => ba.status == true)

    })

    this.payrollReportService.getPeriods().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error("Ha ocurrido un error al obtener los períodos");
        return;
      }
      this.periods = res.dataList.filter(e => e.status);
      this.filteredPeriods = this.periods
      let periodYears: any[] = []
      let years = this.periods.map((period: any) => {
        let periodYear = new Date(`${period.startDate}`).getFullYear()
        if (!periodYears.includes(periodYear)) {
          periodYears.push(periodYear)
        }
        return (periodYears)
      })

      this.yearsList = years[0].reverse()
    }, err => this._toastService.error(err))
  }

  changeYear(event) {
    this.filteredPeriods = this.periods.filter((period: any) => period.description.includes(`${event.value}`))
  }

  getEmployeeType(headerId: number) {
    if (!headerId) {
      this.form.get('employeeType').setValue(null);
      this.employeeType = []
      this.isThereColetilla = false
      return
    }
    this.payrollService.getReportsEmployeeTypes(headerId).subscribe((res: any) => {
      if (!res.succeded) {
        return
      }
      this.employeeType = res.dataList?.filter(d => d?.status);
    });
  }


  getColetilla() {
    const formValue = this.form?.value;
    const { version, employeeType, payrollType } = formValue
    if (!version?.headerId || !employeeType?.employeeTypeId) {
      this.isThereColetilla = false
      this.coletilla = ''
      return
    }
    this.isThereColetilla = true
    this.payrollService.getPayrollColetilla(version?.headerId, employeeType?.employeeTypeId, payrollType?.ocode === 4 ? 4 : null).subscribe(
      {
        next: (resp) => {
          if (resp.dataList.length == 0) {
            this.coletilla = ''
            return
          }
          if (resp.dataList[0].coletilla == null) {
            this.coletilla = ''
            return
          }
          this.coletilla = resp.dataList[0].coletilla

        }
      }
    )
  }

}
