<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" class="close" aria-label="Close" (click)="this.dialogRef.close(false)">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Historial de Contratos
    </h2>
</div>
<div class="mat-dialog-content-custom">
    <div class="card card-custom">
        <div class="card-header mt-3 row justify-content-between">
            <div class="col-sm-12 col-md-6 c-left">
                <div class="card-toolbar d-flex justify-content-start">

                    <div class="col-md-4 mr-2 px-0 pt-1">
                        <div class="form-group mx-2 institution">
                            <label class="text-primary">
                                Estatus
                            </label>
                            <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                                [data]="status" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                                (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 c-right mr-0">
                <div class="card-toolbar d-flex justify-content-end">
                    <div class="dropdown col-md-auto col-sm-12">
                        <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                                data-original-title="Opciones de exportar"></i> Exportar
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item align-items-center" (click)="exportToExcel()">
                                <i class="flaticon-excel icon-2x text-success mr-3"></i>
                                Excel
                            </a>
                            <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                                <i class="fa fa-print icon-2x text-primary mr-3"></i>
                                Visualizar Reporte
                            </a>
                            <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-pdf-file icon-2x text-primary mr-3"></i>
                            PDF
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a> -->
                        </div>
                    </div>

                    <div class="col text-nowrap mt-2">
                        <el-search-bar #searchBar  (onKeyChange)="onKeyChange($event)" [keyValue]="filterText"
                            [records]="contracts" [itemForSearching]="searchBarDisplayNames"
                            (onChange)="getFilteredList($event);"></el-search-bar>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-vertical-center caption-top">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-center">Secuencia</th>
                            <th class="text-left">Fecha Registro</th>
                            <th class="text-left">Inicio Contrato</th>
                            <th class="text-left">Fin Contrato</th>
                            <th class="text-right">Precio por Hora</th>
                            <th class="text-left">Motivo</th>
                            <th class="text-left">Estatus</th>
                            <th class="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let contract of contractsPaginated; let i = index">
                            <td class="text-center">{{contract?.teacherContractId}}</td>
                            <td class="text-left">{{contract?.createDate | date: 'dd/MM/yyyy'}}</td>
                            <td class="text-left">{{contract?.startDate | date: 'dd/MM/yyyy'}}</td>
                            <td class="text-left">{{contract?.endDate | date: 'dd/MM/yyyy'}}</td>
                            <td class="text-right">{{contract?.pricePerHour | currency:'RD$'}}</td>
                            <td class="text-left">{{contract?.annulmentComment}}</td>
                            <td class="text-left">
                                <span class="font-weight-bold"
                                    [ngClass]="{'text-success': contract.statusDesc === 'Activo', 'text-danger':contract.statusDesc === 'Vencido'}">{{contract.statusDesc}}</span>
                            </td>
                            <td class="actionsBtn">
                                <a class="btn btn-icon btn-light-info btn-sm m-1" data-toggle="tooltip"
                                    (click)="OpenAddContract(contract,viewTypes.View)" title="Ver">
                                    <i class="flaticon-ver"></i>
                                </a>
                                <a *ngIf="contract.status && this.data.ableToEditContract" class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip"
                                    (click)="OpenAddContract(contract,viewTypes.Edit)" title="Editar">
                                    <i class="flaticon-edit"></i>
                                </a>
                                <a *ngIf="contract.statusDesc === 'Activo' && this.data.ableToEndContract" class="btn btn-icon btn-light-danger btn-sm m-1" data-toggle="tooltip"
                                    (click)="endContract(contract)" title="Finalizar Contrato">
                                    <i class="flaticon-usuarios icon-2x"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>

                    <el-caption [originalRecordsLength]="contractsFiltered.length"
                        [listedRecordsLength]="contractsPaginated.length" #paginator
                        [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :contractsFiltered.length"
                        [records]="filteredList.enable ? filteredList.records : contractsFiltered"
                        (onChage)="getPaginatedRecords($event)"></el-caption>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="this.dialogRef.close(false)" class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3"
        type="button">
        <i class="flaticon2-cross"></i>
        <span>Cerrar</span>
    </button>
</div>

<export-data-to-pdf [title]="'Historial de Contratos'" id="dataToPrint2">
    <ng-container thead>
        <th class="text-center">Fecha Registro</th>
        <th class="text-center">Inicio Contrato</th>
        <th class="text-center">Fin Contrato</th>
        <th class="text-right">Precio por Hora</th>
        <th class="text-center">Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let contract of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.contractsFiltered"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td class="text-center">{{contract?.createDate | date: 'dd/MM/yyyy'}}</td>
            <td class="text-center">{{contract?.startDate | date: 'dd/MM/yyyy'}}</td>
            <td class="text-center">{{contract?.endDate | date: 'dd/MM/yyyy'}}</td>
            <td class="text-right">{{contract?.pricePerHour | currency:'RD$'}}</td>
            <td class="text-center">
                <span *ngIf="contract.status" class="font-weight-bold text-success">Activo</span>
                <span *ngIf="!contract.status" class="font-weight-bold text-danger">Inactivo</span>
            </td>

        </tr>
    </ng-container>
</export-data-to-pdf>
