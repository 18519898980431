<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>
        Novedades
    </h2>
        <button (click)="close()" type="button" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    </div>
<div class="container">
    <div class="fs-5 bg-primary text-white p-3 mb-3 rounded d-flex justify-content-around align-items-center">
        <span><b>Servidor Público: </b>{{employee.firstName}}  {{employee.lastName}}</span><span><b>Posición: </b>{{employee.positionName}}</span>
    </div>
<div class="table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-left">Tipo</th>
                            <th class="text-left">Concepto</th>
                            <th class="text-left">Monto</th>
                            <th class="text-left">Fecha Inicio</th>
                            <th class="text-left">Fecha Final</th>
                            <th class="text-left">Condición de la Novedad</th>
                            <th class="text-center">Estatus</th>
                            <th class="actionsBtn">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let payrollNew of paginatedRecords">
                            
                            <td class="text-left">{{payrollNew.newsType}}</td>
                            <td class="text-left">{{payrollNew.newsConcept}}</td>
                            <td class="text-left">{{payrollNew.amount | currency: 'RD$'}}</td>
                            <td class="text-left">{{payrollNew.startDate| date:"dd/MM/yyyy"}}</td>
                            <td class="text-left">{{payrollNew.endDate | date:"dd/MM/yyyy"}}</td>
                            <td class="text-left">
                                <span *ngIf="payrollNew.condition === 1" class="font-weight-bold text-success">Aprobada</span>
                                <span *ngIf="payrollNew.condition === 0" class="font-weight-bold text-danger">No Aprobada</span>
                            </td>
                            <td>
                                <span *ngIf="payrollNew.status" class="font-weight-bold text-success">Activa</span>
                                <span *ngIf="!payrollNew.status" class="font-weight-bold text-danger">Inactiva</span>
                            </td>
                            <td class="actionsBtn">
                                <!-- <button class="btn btn-icon btn-light-info btn-sm m-1"
                                    (click)="openModalAddNew(payrollNew, true, false)">
                                    <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver"></i>
                                </button> -->
                                <button 
                                *ngIf="payrollNew.status && payrollNew.condition === 0"
                                (click)="approve(payrollNew)"
                                    data-toggle="tooltip" title="" class="btn btn-sm btn-light-success btn-icon m-1"
                                    data-original-title="Aprobar">
                                    <i class="flaticon-etiqueta icon-2x"></i>
                                </button>
                                <button  *ngIf="payrollNew.status && payrollNew.condition === 0"
                                data-toggle="tooltip" 
                                data-original-title="Editar"
                                 (click)="openEditNew(payrollNew)"
                                    class="btn btn-icon btn-light-warning btn-sm m-1">
                                    <i class="flaticon-edit"></i>
                                </button>
                                <button  *ngIf="payrollNew.status && payrollNew.condition === 0"
                                (click)="openModalDeleteNew(payrollNew)"
                                    data-toggle="tooltip" title="" class="btn btn-sm btn-light-danger btn-icon"
                                    data-original-title="Remover">
                                    <i class="flaticon-delete"></i>
                                </button>
                                <button
                                *ngIf="payrollNew.status && payrollNew.condition === 1"
                                (click)="openModalDisapproveNew(payrollNew)" data-toggle="tooltip" title=""
                                class="btn btn-sm btn-light-danger btn-icon"
                                data-original-title="Desaprobar">
                                <i class="flaticon-mala-critica icon-2x"></i>
                            </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <el-caption #paginator [originalRecordsLength]="records.length"
                        [listedRecordsLength]="paginatedRecords?.length"
                        [registerQuantity]="filteredList?.enable && filteredList.records?.length>0 ? filteredList.records?.length :records?.length"
                        [records]="filteredList?.enable ? filteredList?.records : records"
                        (onChage)="getPaginatedRecords($event)"></el-caption>
                </div>
            </div>
</div>
            