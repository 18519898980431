<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="card card-custom border-primary p-0">
                <div class="card-header mt-3">
                    <div class="card-title align-items-center">
                        <h3 class="text-primary m-0">Familiares y relacionados</h3>
                    </div>
                    <div class="card-toolbar gutter-b">
                        <button class="btn btn-pill btn-shadow btn-success max-w-200px me-3" type="button"
                            (click)="openAddDependentsModal()">
                            <i class="flaticon2-plus"></i>
                            <span> Agregar Dependientes</span>
                        </button>
                    </div>
                </div>
                <div class="card-body p-0 min-h-100px">
                    <div class="table-responsive">
                        <table class="table mt-2 table-vertical-center">
                            <thead>
                                <tr class="bg-primary">
                                    <th>Nombre del Familiar</th>
                                    <th>Parentesco</th>
                                    <th>Estado Civil</th>
                                    <th>Edad</th>
                                    <th>¿Es Dependiente?</th>
                                    <th>Trabaja</th>
                                    <th>Lugar de Trabajo</th>
                                    <th>Estudia</th>
                                    <th>Lugar de Estudio</th>
                                    <th class="actionsBtn">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dependent of familyData; let i = index">
                                    <td>{{dependent.fullName}}</td>
                                    <td>{{getRelationshipDescription(dependent.relationshipId)}} </td>
                                    <td>{{getMarital(dependent.maritalStatusId)}} </td>
                                    <td>{{getAge(dependent)}}</td>
                                    <td>{{(dependent.dependent == true ? 'Si' : 'No')}} </td>
                                    <td>{{(dependent.job == true ? 'Si' : 'No')}} </td>
                                    <td>{{dependent.placeJob || 'N/A'}}</td>
                                    <td>{{(dependent.study == true ? 'Si' : 'No')}} </td>
                                    <td>{{dependent.placeStudy || 'N/A'}}</td>
                                    <td class="actionsBtn">
                                        <button type="button" class="btn btn-sm btn-light-warning btn-icon mr-2"
                                            data-title="Editar"
                                            (click)="openAddDependentsModal(true, dependent, i)">
                                            <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-light-danger btn-icon mr-2"
                                            data-title="Remover" (click)="remove(i)">
                                            <i class="flaticon-delete" data-toggle="tooltip" data-title="Eliminar"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<el-modal target="addDep" title="Dependientes" size="xl" id="addDepModal" (onClose)="resetShowBirthdayError()">
    <div class="row">
        <div class="form-group col-md-6">
            <label>Nombre del familiar <span class="text-danger">*</span></label>
            <input class="form-control form-control-solid form-control-lg " id="number" [(ngModel)]="data.name"
                placeholder="" type="text" required="" autocomplete="0"
                onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32 || event.charCode == 209 || event.charCode == 241 )">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-6">
            <label class="d-block w-100">Parentesco <span class="text-danger">*</span></label>
            <div class="dropdown-alt input-group">
                <select class="form-control  form-control-solid form-control-lg form-select"
                    (change)="relationsShipChange($event)" [(ngModel)]="data.relationsShip">
                    <option *ngFor="let m of relationshipsList" [ngValue]="m.id">{{m.description}}</option>
                </select>
            </div>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-6">
            <label class="d-block w-100">Estado civil <span class="text-danger">*</span></label>
            <div class="dropdown-alt input-group">
                <select class="form-control  form-control-solid form-control-lg form-select"
                    [(ngModel)]="data.maritaly">
                    <option *ngFor="let m of maritalList" [ngValue]="m.ocode">{{m.stringData}}</option>
                </select>
            </div>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-3">
            <label>Fecha de nacimiento <span class="text-danger">*</span></label>
            <input max="9999-12-31" (change)="validateBirthdayInput()" [max]="getCurrentDate"
                class="form-control form-control-solid form-control-lg" type="date" (input)="updateAge()"
                [(ngModel)]="data.birthday">
            <span class="form-text text-danger d-none">Este campo es requerido</span>
            <span class="form-text text-danger" *ngIf="showBirthdayError">Fecha de nacimiento inválida</span>
        </div>
        <div class="form-group col-md-3">
            <label>Edad</label>
            <input class="form-control form-control-solid form-control-lg" [(ngModel)]="age" placeholder=""
                type="number" readonly autocomplete="0">
        </div>

    </div>
    <div class="row">
        <div class="form-group col-md-4">
            <label for="" class="d-block">¿Trabaja? <span class="text-danger">*</span></label>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input type="radio" class="form-check-input" name="works" type="radio" [(ngModel)]="data.works"
                        [value]="work.yes"> Sí
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input type="radio" class="form-check-input" name="works" type="radio" [(ngModel)]="data.works"
                        (change)="data.placeJob = null" [value]="work.no"> No
                </label>
            </div>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-4">
            <label for="" class="d-block">¿Estudia? <span class="text-danger">*</span></label>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input type="radio" class="form-check-input" name="study" type="radio" [(ngModel)]="data.study"
                        [value]="study.yes"> Sí
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input type="radio" class="form-check-input" name="study" type="radio"
                        (change)="data.placeStudy = null" [(ngModel)]="data.study" [value]="study.no"> No
                </label>
            </div>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
        <div class="form-group col-md-4">
            <label for="" class="d-block">¿Es Dependiente?<span class="text-danger">*</span></label>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input type="radio" class="form-check-input" name="dependent" type="radio"
                        [(ngModel)]="data.dependent" [value]="dependent.yes"> Sí
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label mt-2 check-dtl">
                    <input type="radio" class="form-check-input" name="dependent" type="radio"
                        [(ngModel)]="data.dependent" [value]="dependent.no"> No
                </label>
            </div>
            <span class="form-text text-danger d-none">Este campo es requerido</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group ">
                <label>Lugar de trabajo <span class="text-danger" *ngIf="data.works == 1">*</span></label>
                <input [disabled]="data.works == 2" class="form-control form-control-solid form-control-lg" type="text"
                    [(ngModel)]="data.placeJob">
                <span class="form-text text-danger d-none">Este campo es requerido</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group ">
                <label>Lugar de estudio <span class="text-danger" *ngIf="data.study == 1">*</span></label>
                <input [disabled]="data.study == 2" class="form-control form-control-solid form-control-lg" type="text"
                    [(ngModel)]="data.placeStudy">
                <span class="form-text text-danger d-none">Este campo es requerido</span>
            </div>
        </div>
    </div>
    <!--   <div class="row">
        <div class="col-md-6">
            <div class="form-group card">
                <h3 class="d-block gutter-t ml-3">Foto del dependiente</h3>
                <div class="card-body min-h-100px">
                    <div class="image-input image-input-outline" id="kt_image_4"
                        style="background-image: url(assets/images/no-user.png)">
                        <div class="image-input-wrapper" style="background-image: url('')"></div>

                        <label class="btn  btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            data-action="change" data-toggle="tooltip" title="" data-original-title="Subir">
                            <i class="flaticon2-pen  text-muted"></i>
                            <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" />
                            <input type="hidden" name="profile_avatar_remove" />
                        </label>
                        <span class="btn  btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
                            <i class="ki ki-bold-close  text-muted"></i>
                        </span>
                        <span class="btn  btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            data-action="remove" data-toggle="tooltip" title="Remover">
                            <i class="ki ki-bold-close  text-muted"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="row gutter-t">
        <div class="form-group col-md-12 d-flex justify-content-end border-top pt-3 m-0">
            <button (click)="resetShowBirthdayError()" id="btnCerrar"
                class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2" data-dismiss="modal"
                type="button">
                <i class="flaticon2-cross"></i>
                <span>Cerrar</span>
            </button>
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
                <i class="flaticon-disco-flexible"></i>
                <span> Guardar </span>
            </button>
        </div>
    </div>
</el-modal>