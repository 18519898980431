import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IEmployee } from 'src/app/models/Employee.model';
import { IChangePasswordPOST } from 'src/app/models/change_password';
import { IResponseUser, IUserGET } from 'src/app/models/user';
import { AccountService } from 'src/app/services/account.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { Account } from 'src/app/shared/models/account';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.css']
})
export class ResetPasswordModalComponent implements OnInit {

  user!: Account;
  employeeData!: IEmployee;
  
  formIsInvalid: boolean = false;
  passwordForm: FormGroup = new FormGroup({
    currentPassword: new FormControl('', [Validators.required, Validators.min(8)]),
    newPassword: new FormControl('', [Validators.required, Validators.min(8)]),
    confirmPassword: new FormControl('', Validators.required),
    validationCode: new FormControl('', Validators.required),
  })

  userImg!: string;
  errorMessage = 'Ha ocurrido un error inesperado'

  constructor(
    private srvAccount: AccountService,
    private authInfoService: AuthInfoService,
    private dialogRef: MatDialogRef<ResetPasswordModalComponent>,
    private _toasService: ToastrService,
    private srvDoc: docService,
    private employeeService: EmployeesService,
    private cdf: ChangeDetectorRef
  ) {
    dialogRef.disableClose = true
  }

  ngOnInit(): void {

    const userData = this.authInfoService.getUser();
    this.user = userData;
    this.cdf.detectChanges();

    if (this.user.UserImage) {
      this.getUserIMG(this.user.UserImage)
    }
    const employeeId: number = +userData?.employeeId;

    if(employeeId > 0){
      this.getEmployeeData(employeeId)
    }

  }

  getEmployeeData(employeeId: number) {

    this.employeeService.getEmployeeByEmployeeId(employeeId).subscribe({
      next: (res)=>{
        if(!res.succeded){

          res.errors.forEach(err=>{
            this._toasService.error(err);
          });

          res.warnings.forEach(warn=>{
            this._toasService.warning(warn);
          });

          return;
        }

        this.employeeData = res?.dataList[0];
        this.cdf.detectChanges();

      },
      error:(err)=>{
        this._toasService.error('Ha ocurrido un error tratando de consultar la información del Servidor Público asociado con la cuenta de usuario.')
      }
    })
    
  }

  getUserIMG(guid: string) {
    this.srvDoc.getDocument(guid).subscribe((res) => {

      if(!res.succeded){

        res.errors.forEach(err=>{
          this._toasService.error(err);
        });

        return;
      }

      this.userImg = res?.data;

    }, err => {
      this._toasService.error(this.errorMessage)
    })
  }

  changePassword() {

    this.formIsInvalid = this.passwordForm.invalid
    if (this.formIsInvalid) {
      return
    }
    if (!this.passwordMatch()) {
      return
    }
    const form = this.passwordForm.value
    const password: IChangePasswordPOST = {
      userId: +this.user.userId,
      currentPassword: form.currentPassword,
      newPassword: form.newPassword,
      passwordVerficationCode: form.validationCode,
      companyId: this.user.companyId
    }
    this.srvAccount.updatePassword(password).subscribe((res: any) => {

      if(!res.succeded){

        res.errors.forEach(err=>{
          this._toasService.error(err);
        });

        res.warnings.forEach(warn=>{
          this._toasService.warning(warn);
        });

        return;
      }

      this._toasService.success("Se ha cambiado la contraseña")
      this.dialogRef.close({ succeded: true })

      
    }, err => {
      this._toasService.error(this.errorMessage)
    })
  }

  closeModal() {
    this.dialogRef.close()
  }

  passwordMatch() {
    const f = this.passwordForm.value
    return f.newPassword === f.confirmPassword
  }

}
