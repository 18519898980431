<div class="row">
    <div class="d-flex justify-content-end gutter-b ">
        <button (click)="addTransaction()" data-toggle="modal" data-target="#ADTrans" class="btn btn-pill font-weight-bolder btn-success center">
            <i class="flaticon-user-add icon-2x"></i>
            <span>Transacciones Servidor Público</span>
        </button>
    </div>
</div>
<div class="table-responsive border-u">
    <table class="table table-vertical-center">
        <thead>
            <tr class="bg-primary">
                <th class="text-center">ID Transacciones</th>
                <th class="text-center">Descipción</th>
                <th class="text-center">Efecto</th>
                <th class="text-center">Fecha</th>
                <th class="text-center">Valor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
            </tr>
            <tr>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
            </tr>
            <tr>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
            </tr>
            <tr>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
                <td class="text-center">ITLA-02394</td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-end">
        <div class="d-flex justify-content-end align-items-center flex-wrap">
            <div class="d-flex align-items-center py-3 mr-2">
                <select
                    class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-light"
                    style="width: 75px;">
                    <option value="1">1</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                </select>
                <span class="text-muted">Mostrando página 1 de 1 </span>
            </div>
            <div class="d-flex flex-wrap py-2 mr-3">
                <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                </button>
                <button class="btn btn-icon btn-sm btn-circle btn-primary mr-2 my-1" disabled="">
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="col-md-12 gutter-t">
    <div class=" px-5">
        <div class="form-group d-flex justify-content-between align-items-center p-3">
            <button [routerLink]="['/employees']"  class="btn btn-pill font-weight-bolder btn-shadow btn-warning mr-3 ">
                <i class="flaticon2-left-arrow-1"></i>
                <span>Volver</span>
            </button> 
            <button class="btn btn-pill font-weight-bolder btn-shadow btn-success ">
                <i class="flaticon-disco-flexible"></i>
                <span>Guardar</span>
            </button>                   
        </div>
    </div>
</div>