import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';
enum types {
  total = 0,
  department = 1,
  contractType = 2,
  last3Year = 3,
  gender = 4
}
@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private api = environment.api_url;
  private core = environment.api_core_url;
  constructor(
    private http: HttpClient,
    private authInfo: AuthInfoService
  ) { }

  getHomeCharts(dateFrom, dateTo, years: string, companyId = this.authInfo.getCompanyId()) {
    if(years){
      return this.http.get(`${this.api}/HomeDashboard/Dashboard?Status=true&CompanyId=${companyId}&DateFrom=${dateFrom}&DateTo=${dateTo}&years=${years}`)
    }
    return this.http.get(`${this.api}/HomeDashboard/Dashboard?Status=true&CompanyId=${companyId}&DateFrom=${dateFrom}&DateTo=${dateTo}`)
  }
  getHomeChartsYear(year) {
    let date = this.getFilterDate(year)
    return this.http.get(`${this.api}/HomeDashboard/Dashboard?type=1&Status=true&CompanyId=${this.authInfo.getCompanyId()}&DateFrom=01-01-2000&DateTo=12-31-${year}`)
  }

  private getFilterDate(year) {
    return {
      dateFrom: `01-01-${year}`,
      dateTo: `12-31-${year}`
    }
  }

  getPeriods(type: number = 0, companyId = this.authInfo.getCompanyId()) {
    return this.http.get(`${this.api}/Period?PayrollPeriodId=0&Type=${type}&CompanyId=${companyId}&Status=true`)
  }
  getDepartments() {
    return this.http.get(`${this.core}/CompanyStructure?CompanyId=${this.authInfo.getCompanyId()}&IdDepartment=0`)
  }



  getHeadersByPeriod(periodId: number, companyId:number = this.authInfo.getCompanyId()) {
    return this.http.get(this.api + `/HomeDashboard/GetDashboardSelect?Status=true&CompanyId=${companyId}&Type=0&Select=${periodId}`)
  }

}
