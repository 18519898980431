<!-- Modal -->
<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
    <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
  </button>
  <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Dashboard de
    Vacantes</h2>
</div>
<div class="mat-dialog-content-custom">
  <div class="card-body-custom">
    <div class="d-flex justify-content-between align-items-end mb-3 row">
      <div class="text-left col-md-8 col-lg-9">
        <h3>Filtrado</h3>
        <!-- <h4 class="d-flex font-weight-bolder  text-primary">Año: <span class="text-dark font-weight-lighter ml-3">{{ filterParams.year|| '   ' }}</span></h4> -->
        <div class=" text-left d-flex flex-wrap">
          <h4 class="d-flex font-weight-bolder text-primary me-4">Desde: <span
              class="text-dark font-weight-lighter ml-3">{{filterParams.date1| date: 'dd/MM/yyyy' ||' '}}</span></h4>
          <h4 class="d-flex font-weight-bolder  text-primary">Hasta: <span
              class="text-dark font-weight-lighter ml-3">{{filterParams.date2| date: 'dd/MM/yyyy'||' '}}</span></h4>
        </div>
      </div>
      <div class="d-flex flex-nowrap justify-content-between col-12 col-md-3 col-lg-3">
        <button class="btn btn-primary me-2 w-50" (click)="openDialog()">
          <i class="fas fa-filter" data-toggle="tooltip" data-title="Filtrar"></i>
        </button>
        <button class="btn btn-light-warning ms-2 w-50" [disabled]="!filtered" (click)="clear()">
          <i class="fas fa-redo" data-toggle="tooltip" data-title="Reiniciar filtros"></i>
        </button>
      </div>
    </div>

    <div class="card bg-white p-5 gutter-b">
      <div class="row ">
        <div class="col-12 col-xxl-4">
          <div class="d-flex flex-wrap align-items-center bg-light-warning rounded p-5 mb-5 mb-xxl-0 ">
            <span class="svg-icon svg-icon-warning svg-icon-4x mr-5">
              <span class="svg-icon-r svg-icon-primary svg-icon-2x">
                <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Text/Bullet-list.svg-->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z"
                      fill="#000000" />
                    <path
                      d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z"
                      fill="#000000" opacity="0.3" />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </span>
            <div class="d-flex  flex-wrap flex-column flex-grow-1 mr-2">
              <h3 class="font-weight-bold text-dark-75 font-size-r">Total</h3>
            </div>
            <span class="font-weight-bolder text-warning py-1 font-size-r me-1">
              {{amountVacants}}
            </span>
          </div>
        </div>



        <div class="col-12 col-xxl-4">
          <div class="d-flex  flex-wrap align-items-center bg-light-info rounded p-5 mb-5 mb-xxl-0">
            <span class="svg-icon svg-icon-info svg-icon-4x mr-5">
              <span class="svg-icon-r svg-icon-primary svg-icon-2x">
                <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Navigation/Double-check.svg-->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                      fill="#000000" fill-rule="nonzero" opacity="0.3"
                      transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) " />
                    <path
                      d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
                      fill="#000000" fill-rule="nonzero"
                      transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) " />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </span>
            <!--begin::Title-->
            <div class="d-flex flex-wrap flex-wrap flex-column flex-grow-1 mr-2">
              <h3 class="font-weight-bold text-dark-75 font-size-r">Publicadas</h3>
            </div>
            <span class="font-weight-bolder text-info py-1 me-1 text-wrap font-size-r">
              <span>{{amountPublished.total}}</span><span>({{amountPublished.percentage}}%)</span>
            </span>
          </div>
        </div>
        <div class="col-12 col-xxl-4">
          <div class="d-flex flex-wrap align-items-center bg-light-danger rounded p-5">
            <span class="svg-icon svg-icon-danger svg-icon-4x mr-5">
              <span class="svg-icon-r svg-icon-primary svg-icon-2x">
                <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Code/Error-circle.svg-->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                    <path
                      d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"
                      fill="#000000" />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </span>
            <!--begin::Title-->
            <div class="d-flex flex-wrap flex-column flex-grow-1 mr-2">
              <h3 class="font-weight-bold text-dark-75 font-size-r">No Publicadas</h3>
            </div>
            <span class="font-weight-bolder text-danger py-1 font-size-r me-1">
              {{amountNotPublished.total}} ({{amountNotPublished.percentage}}%)
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 gutter-b">
        <div class="card card-custom bg-white">
          <div class="card-header p-3">
            <h4 class="card-title">Por Sexo</h4>
          </div>
          <div class="card-body p-3">
            <ng-container *ngIf="dataGender?.series.length">
              <apx-chart [series]="dataGender.series" [plotOptions]="chartOptions?.plotOptions"
                [labels]="dataGender.labels" [dataLabels]="chartOptions.dataLabels" [chart]="chartOptions.chart"
                [responsive]="chartOptions.responsive" [legend]="chartOptions.legend"></apx-chart>
            </ng-container>
            <ng-container *ngIf="dataGender?.series.length == 0">
              {{dataGender.message}}
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-md-6 gutter-b">
        <div class="card card-custom bg-white">

          <div class="card-header p-3">
            <h4 class="card-title">Por Nivel Académico</h4>
          </div>
          <div class="card-body p-3 w-100">
            <ng-container *ngIf="academicLevelChart?.series.length">
              <apx-chart [series]="academicLevelChart.series" [labels]="academicLevelChart.labels"
                [plotOptions]="chartOptions?.plotOptions" [chart]="chartOptions.chart"
                [dataLabels]="chartOptions.dataLabels" [responsive]="chartOptions.responsive"
                [legend]="chartOptions.legend"></apx-chart>
            </ng-container>
            <ng-container *ngIf="academicLevelChart?.series.length == 0">
              {{academicLevelChart.message}}
            </ng-container>
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-custom bg-white">
          <div class="card-header p-2 mt-2 d-flex justify-content-between">
            <h3 class="ms-3 font-weight-bold text-dark-75 py-3">Por Unidad organizativa</h3>
            <ngx-select-dropdown [multiple]='true' (change)="changeDepartment()" [options]="departments"
              [(ngModel)]="department" [config]="dropdownConfig('description')" class="col-12 col-xxl-4">
            </ngx-select-dropdown>
          </div>
          <div class="card-body p-3">
            <ng-container *ngIf="barOptions.series[0]?.data.length">
              <apx-chart [series]="barOptions.series" [xaxis]="barOptions.xaxis" [chart]="barOptions.chart"
                [plotOptions]="barOptions.plotOptions" [dataLabels]="barOptions.dataLabels" [stroke]="barOptions.stroke"
                [yaxis]="barOptions.yaxis" [fill]="barOptions.fill" [legend]="barOptions.legend"
                [tooltip]="barOptions.tooltip"></apx-chart>
            </ng-container>
            <ng-container *ngIf="barOptions.series[0]?.data.length == 0">
              {{dataDepartmentMessage}}
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!--div class="row">
  <div class="col-lg-12">
    <div class="card card-custom bg-white">
      <div class="card-header p-3">
        <h4 class="card-title">Cantidad de Vacantes</h4>
      </div>
      <div class="card-body p-3">
        <apx-chart
          [chart]="bar3years.chart"
          [plotOptions]="bar3years.plotOptions"
          [dataLabels]="bar3years.dataLabels"
          [series]="bar3years.series"
          [stroke]="bar3years.stroke"
          [xaxis]="bar3years.xaxis"
          [yaxis]="bar3years.yaxis"
          [fill]="bar3years.fill"
          [tooltip]="bar3years.tooltip"
        ></apx-chart>
      </div>
    </div>
  </div>

</div-->

  </div>

  <div *ngIf="false" class="modal-footer">
    <button type="button" class="btn btn-danger" data-dismiss="modal">
      <i class="flaticon-cancel"></i>
      Close
    </button>
    <button type="button" class="btn btn-success"> <i class="flaticon-upload"></i> Guardar</button>
  </div>
</div>
