import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { parameterDoc, personalFile } from '../utils/parameterDoc';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { environment } from 'src/environments/environment';
import { FileResponse } from '../models/uppy-file-manager.model';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ShowDocumentModalComponent } from '../show-document-modal/show-document-modal.component';
import { docService } from 'src/app/services/doc.service';
import { NumbersService } from 'src/app/services/numbers.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { Observable, Subscription } from 'rxjs';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-uppy-file-manager',
  templateUrl: './uppy-file-manager.component.html',
  styleUrls: ['./uppy-file-manager.component.css']
})
export class UppyFileManagerComponent implements OnChanges, AfterViewInit {


  @Input() isDisabled: boolean = false;
  @Input() targetClass: string = 'targetUppyFileM'
  @Input() typePersonalFile: personalFile = personalFile.others;
  @Input() allowedFileTypes: string[] = [
    'application/pdf'
  ];
  @Input() showFileModalTitle: string = 'Evidencia';
  @Input() uploadFile: boolean = false;
  @Input() height: number = 295;
  @Input() width: number = 320;
  @Input() thumbnailWidth: number = 320;

  @Input() cleanAll!: Observable<boolean>;
  @Input() fileForEditting: string;

  @Output() guidEmitter = new EventEmitter<string>();

  uppyComponent = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: this.allowedFileTypes
    },
    locale: Mexico
  });

  fileGUID!: GUID;

  private readonly DOCUMENT_API_BASE_URL = environment.api_doc + '/Document';

  fileData: { filePath: string } = { filePath: '' };

  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";

  constructor(private dialog: MatDialog,
    private toastService: ToastrService,
    private docService: docService,
    private numbersService: NumbersService,
    private parameterService: ParameterControlService) {

    const number = this.numbersService.getRandomInt(1, 1000);
    this.targetClass = `${this.targetClass}-${number}`;

    
  }

  ngOnChanges(changes: SimpleChanges): void {

    const fileForEdittingChange: SimpleChange = changes['fileForEditting'];

    if (fileForEdittingChange && fileForEdittingChange?.previousValue != fileForEdittingChange?.currentValue) {
      this.cleanUppy();
      if(fileForEdittingChange.currentValue){
        this.getFileforEditting(fileForEdittingChange.currentValue);
      }
    }

  }


  ngAfterViewInit(): void {
    this.cleanAll.subscribe(value => {
      if(value){
        this.cleanUppy();
      }
    });

    this.getPath();
  }

  getPath() {
    this.parameterService.getParameters(parameterDoc.PathTest).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastService.error(err);
          })

          res.warnings.forEach(warn => {
            this.toastService.warning(warn);
          })

          return;
        }

        this.routeFile = res.dataList[0].stringData.toString();
        this.loadUppy()
      },
      error: (err) => {
        this.toastService.error('Ha ocurrido un error inesperado consultado la lista de parametros para la evidencia')
      }
    })

  }

  loadUppy() {
    this.uppyComponent.use(Dashboard, {
      trigger: '.UppyModalOpenerBtn',
      inline: true,
      target: `.${this.targetClass}`,
      disabled: this.isDisabled,
      showProgressDetails: true,
      note: `1 archivo, con un maximo de ${(environment.maxFileSize / 0.000001)} MB`,
      width: this.width,
      height: this.height,
      locale: {
        strings: {
          dropPasteFiles: 'Arrastra archivos aquí o %{browse}'
        }
      },
      thumbnailWidth: this.thumbnailWidth,
      metaFields: [
        { id: 'name', name: 'Nombre', placeholder: 'Nombre del Archivo' },
        { id: 'caption', name: 'Caption', placeholder: 'Descripción del archivo' }
      ],
      browserBackButtonClose: false,
      showLinkToFileUploadResult: true,
    },
    ).use(XHRUpload, { endpoint: `${this.DOCUMENT_API_BASE_URL}?SystemLoad=${this.SystemLoad}&ModuleLoad=${this.ModuleLoad}&personalFile=${this.typePersonalFile}&parameterDoc=${this.routeFile}` })
      .on('complete', (result) => {
        if (result.successful) {

          let resData = result.successful[0].response.body.data as FileResponse;

          this.fileGUID = (resData.fileUnit.guidname) as GUID
          this.fileData.filePath = resData.path;

          this.guidEmitter.emit(resData.fileUnit.guidname);

        }
      });
  }


  cleanUppy(){
    this.uppyComponent.cancelAll();
    this.fileData.filePath = '';
  }

  getFileforEditting(guid) {
    this.docService.getDocument(guid).subscribe(res => {
      this.fileData.filePath = res.data ? res.data : ''
    })
  }

  showFile(filePath: string, title: string) {
    this.dialog.open(ShowDocumentModalComponent, {
      width: '50%',
      data: {
        src: filePath,
        title
      }
    })
  }

  removeFile() {
    try {
      this.docService.deleteFile(this.fileGUID).subscribe(e => {
        this.cleanUppy();
        this.guidEmitter.emit(null);
      }, error => {
        this.toastService.error('Ha ocurrido un error inesperado tratando de remover el archivo seleccionado');
      })

    } catch (error) {
      this.toastService.error('Ha ocurrido un error inesperado tratando de remover el archivo seleccionado');
    }
  }

}
