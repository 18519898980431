<ng-container *ngIf="privilege.add.value">
  
        <div class="row mt-10"
        *ngIf="isEditingServiceCommision && (currentConditionOnEditting == 2) && currentEdittingIsWithoutPayroll == true">
        <div class="alert alert-warning d-flex align-items-center">
            <i class="fas fa-exclamation-triangle mr-2 fa-2x blink-for-warning"></i>
            <div>
                Esta acción de personal ya fue <strong>aprobada</strong>, modificarla puede causar alteraciones en
                la <strong>nómina</strong> y otros módulos del <strong>SIGEI</strong>. En caso de que la acción de
                personal haya sido aplicada en una
                nómina u otro proceso dependiente de la misma, su modificación <strong>NO</strong> será
                <strong>permitida</strong>.
            </div>
        </div>

    </div>
        <form class="row mt-10" [formGroup]="form">

            <div class="col form-group">
                <label for="">Fecha de Solicitud <span class="text-danger">*</span></label>
                <input formControlName="requestDate" type="date" class="form-control">
                <span style="position: absolute;"
                    *ngIf="formIsInvalid && form.controls['requestDate']?.errors?.required" class="text-danger">Este
                    campo es requerido</span>
            </div>
            <div class="col form-group">
                <label for="">Fecha de Inicio <span class="text-danger">*</span></label>
                <input (change)="changeStartDate()" formControlName="startDate" type="date" class="form-control">
                <span style="position: absolute;" *ngIf="formIsInvalid && form.controls['startDate']?.errors?.required"
                    class="text-danger">Este campo es requerido</span>
            </div>
            <div class="col form-group">
                <label for="">Fecha de Término <span class="text-danger">*</span></label>
                <input [min]="minEndDate" formControlName="endDate" type="date" class="form-control">
                <span style="position: absolute;" *ngIf="formIsInvalid && form.controls['endDate']?.errors?.required"
                    class="text-danger">Este campo es requerido</span>
                <span *ngIf="endDateInvalid" class="text-danger">La fecha de término debe ser superior a la fecha de
                    inicio
                </span>
            </div>
            <div class="col form-group">
                <label for="">Institución Destino <span class="text-danger">*</span></label>
                <ngx-select-dropdown formControlName="institution"
                    [disabled]="!this.employee?.status || isSeviceCommission" [config]="institutionDropdownConfig"
                    [options]="institutions"></ngx-select-dropdown>
                <span style="position: absolute;"
                    *ngIf="formIsInvalid && form.controls['institution']?.errors?.required" class="text-danger">Este
                    campo es requerido</span>
            </div>
            <div class="col form-group">
                <label for="">Motivo <span class="text-danger">*</span></label>
                <ngx-select-dropdown formControlName="motive" [disabled]="!this.employee?.status || isSeviceCommission"
                    [config]="motiveDropdownConfig" [options]="motives"></ngx-select-dropdown>
                <span style="position: absolute;" *ngIf="formIsInvalid && form.controls['motive']?.errors?.required"
                    class="text-danger">Este campo es requerido</span>
            </div>

            <div class="row">

                <div class="col form-group">
                    <label for="">Comentario <span class="text-danger">*</span></label>
                    <textarea formControlName="comment" class="form-control"></textarea>
                    <span style="position: absolute;"
                        *ngIf="formIsInvalid && form.controls['comment']?.errors?.required" class="text-danger">Este
                        campo es requerido</span>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Evidencia <span class="text-danger">*</span></label>
                        <div class="card align-items-center">
                            <uppy-file 
                                #uppyFileServiceCommission
                                previewDefaultImage="assets/images/cv.jpg"
                                classPreviewDefaultImage="symbol symbol-150 d250 my-4 max-w-250px"
                                targetClass="serviceCommissionEvidence"
                                [width]="320"
                                [height]="295"
                                [isDisabled]="!this.employee?.status || this.isSuspended == true"
                                [showDocumentoAfterUploaded]="false"
                                [fileForEditting]="file?.document"
                                [allowedFileTypes]="['application/pdf']"
                                (fileEmitter)="setEvidence($event)">
                            </uppy-file>
                            <!-- <div class="card-body">
                                <div [ngClass]="{'d-block': file.document=='', 'd-none' : file.document!=''}">
                                    <div class="evidence22"></div>
                                </div>
                                <div *ngIf="file.document !=''">
                                    <div class="symbol symbol-150 d250 my-4 max-w-250px">
                                        <img alt="Pic" src="assets/images/cv.jpg" />
                                    </div>
                                    <div class="btn-group center" role="group">
                                        <button type="button" class="btn btn-primary btn-icon" data-toggle="modal"
                                            data-target="#document">
                                            <i class="flaticon-ver"></i>
                                        </button>
                                        <button type="button" class="btn btn-danger btn-icon" (click)="deleteGuid()" >
                                            <i class="flaticon-delete-1"></i>
                                        </button>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <span style="position: absolute;"
                            *ngIf="formIsInvalid && form.controls['evidence']?.errors?.required"
                            class="text-danger">Este campo es requerido</span>
                    </div>
                </div>
            </div>

            <div class="row mt-2" *ngIf="isEditingServiceCommision">
                <div class="text-primary pt-4">
                    <h4>Información de la Modificación</h4>
                    <hr>
                </div>
                <div class="row mb-2">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Motivo de la Modificación Anterior </label>
                            <input type="text" class="form-control form-control-lg" [value]="form.get('oldModificationReason')?.value?.stringData" readonly/>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="form-group">
                            <label>Comentario de la Modificación Anterior</label>
                            <textarea [value]="form.get('oldModificationComment')?.value" class="form-control"
                                rows="6" readonly></textarea>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Motivo de la Modificación Actual <span class="text-danger">*</span></label>
                            <ngx-select-dropdown formControlName='newModificationReason' [options]='modificationReasons'
                                [config]='reasonsConfig' class="flex-1" (change)="changeModificationReason($event?.value)">
                            </ngx-select-dropdown>
                            <span class="text-danger"
                                *ngIf=" form.controls['newModificationReason']?.errors?.required">Este
                                campo es requerido</span>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="form-group">
                            <label>Comentario de la Modificación Actual</label>
                            <textarea formControlName="newModificationComment" class="form-control"
                                rows="6"></textarea>
                        </div>
                    </div>
                </div>
                
               
            </div>
        </form>

 
    <div class="row">
       <div class="form-group d-flex justify-content-end align-items-center">
        <button (click)="save()" [disabled]="form.invalid" *ngIf="privilege.add.value" #saveButtonCs
        class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
        <i class="flaticon-disco-flexible"></i>
        <span>{{isEditingServiceCommision ? 'Guardar Modificación' : 'Guardar'}}</span>
    </button>

    <button (click)="clearFields(isEditingServiceCommision ? true  : false);"
        class="btn btn-danger btn-pill font-weight-bolder">
        {{isEditingServiceCommision ? 'Cancelar Modificación' : 'Limpiar campos'}}
    </button>
       </div>
    </div>      
</ng-container>
<div class="row gutter-b">
    <h2 class="text-primary gutter-b mb-2 d-flex justify-content-between flex-wrap" >
        Lista Comisión de Servicio
        
      
        
    </h2>
    <div class="table-responsive">
        <table class="table atbl table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th class="text-left">Secuencia</th>
                    <th class="text-left">Fecha de Solicitud</th>
                    <th class="text-left">Fecha de Inicio</th>
                    <th class="text-left">Fecha de Término</th>
                    <th class="text-left">Institución Destino</th>
                    <th class="text-left">Motivo</th>
                    <th class="text-left">Evidencia</th>
                    <th class="text-left">Estatus</th>
                    <th class="text-center">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of commissions">
                    <td class="text-left">{{item.permissionsId}}</td>
                    <td class="text-left">{{item.applicationDate |date: 'dd/MM/yyy'}}</td>
                    <td class="text-left">{{item.dateStart |date: 'dd/MM/yyy'}}</td>
                    <td class="text-left">{{item.dateEnd |date: 'dd/MM/yyy'}}</td>
                    <td class="text-left">{{item?.destinationInstitutionName || ""}}</td>
                    <td class="text-left">
                        <div class="reasonPermiso">
                            {{item.commissionServiceReason || "N/A"}}
                        </div>
                    </td>
                    <td>
                        <a *ngIf="item.evidence" (click)='openGuID(item.evidence)' data-toggle="tooltip"
                            title="Evidencia" class="btn btn-light-youtube font-weight-bold mb-3">
                            <i class="flaticon-pdf-file icon-2x"></i>
                        </a>
                        <p *ngIf="!item.evidence">N/A</p>
                    </td>
                    <td>
                        <ng-container *ngIf="!item?.status; else conditions">
                            <span class="font-weight-bold text-danger">{{item?.conditionId === 10 ? 'Anulado' : 'Inactivo'}}</span>
                        </ng-container>
                        <ng-template #conditions>
                            <ng-container *ngIf="item?.conditionId === 6">
                                <span class="font-weight-bold text-warning">En Espera de Aprobación</span>
                            </ng-container>
                            <ng-container *ngIf="item?.conditionId === 2">
                                <span class="font-weight-bold text-success">Aprobado</span>
                            </ng-container>
                            <ng-container *ngIf="item?.conditionId === 3">
                                <span class="font-weight-bold text-danger">Rechazado</span>
                            </ng-container>
                            <ng-container *ngIf="item?.conditionId === 10">
                                <span class="font-weight-bold text-danger">Anulado</span>
                            </ng-container>
                        </ng-template>
                    </td>

                    <td class="actionsBtn">
                        <button *ngIf="privilege.view.value"
                            (click)="exportToPdf(employee.employeeId,item.permissionsId)"
                            class="btn btn-sm btn-light-info btn-icon mr-2">
                            <i class="flaticon-ver" data-toggle="tooltip" title="Ver"></i>
                        </button>
                        <button *ngIf="  privilege.edit.value && item?.conditionId != 10 && item?.status == true" (click)="editServiceCommision(item)"
                            class="btn btn-icon btn-light-warning btn-sm m-1">
                            <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                        </button>
                        <!-- <a *ngIf=" (item.dateEnd |date: 'MM/dd/yyyy')  <= (currentDate |date: 'MM/dd/yyyy')
                        &&privilege.finalize.value" (click)="finalizePermission(item.permissionsId)"
                            class="btn btn-sm btn-light-danger btn-icon mr-2">
                            <i class="ki ki-close" data-toggle="tooltip" title="Finalizar"></i>
                        </a>
                        <button *ngIf="item.conditionId == 6 &&privilege.approve.value"
                            (click)="approvePermission(item)" type="button"
                            class="btn btn-sm btn-light-success btn-icon mr-2">
                            <i class="flaticon-checked" data-title="Aprobar" data-toggle="tooltip"></i>
                        </button>
                        <button *ngIf="item.conditionId == 6 && privilege.reject.value" data-toggle="modal"
                            data-target="#rejectReason" (click)="toReject(item)" type="button"
                            class="btn btn-sm btn-light-danger btn-icon mr-2">
                            <i class="flaticon2-cross" data-title="Rechazar" data-toggle="tooltip"></i>
                        </button> -->
                    </td>


                </tr>
            </tbody>
        </table>
    </div>
</div>