<div class="modal-header border-0 dv-table-header-1 bg-primary">
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        Ajuste De Entrada De Inventario
    </h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content" [formGroup]="form">
    <div class="container-fluid">
        <h4 class="mt-0 mb-3 text-primary">
            Datos Del Inventario
        </h4>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                    <label>Fecha Del Inventario</label>
                    <div>
                        <input type="date" class="form-control form-control-lg" [value]="cutOffDate" readonly="readonly" />
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                    <label>Realizado Por</label>
                    <div>
                        <input type="text" class="form-control form-control-lg" [value]="inventoryadjustment?.header?.name" readonly="readonly" />
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="form-group">
                    <label>Producto</label>
                    <div>
                        <input type="text" class="form-control form-control-lg" readonly="readonly" [value]="inventoryadjustment.detail.shortDescription" />
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                    <label>Existencia Según El Sistema</label>
                    <div>
                        <input type="text" class="form-control form-control-lg" readonly="readonly" [value]="inventoryadjustment.detail.stock" />
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                    <label>Conteo Físico</label>
                    <div>
                        <input type="text" class="form-control form-control-lg" readonly="readonly" [value]="inventoryadjustment.detail.physicalCount" />
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                    <label>Diferencia</label>
                    <div>
                        <input type="text" class="form-control form-control-lg" readonly="readonly" [value]="inventoryadjustment.detail.different" />
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                    <label>Concepto</label>
                    <div *ngIf="inventoryadjustment.detail.physicalCount < inventoryadjustment.detail.stock">
                        <input type="text" class="form-control form-control-lg" readonly="readonly" value="Faltante" />
                    </div>
                    <div *ngIf="inventoryadjustment.detail.physicalCount > inventoryadjustment.detail.stock">
                      <input type="text" class="form-control form-control-lg" readonly="readonly" value="Sobrante" />
                  </div>
                </div>
            </div>
        </div>
        <h4 class="mt-0 mb-3 text-primary">
            Datos Del Ajuste
        </h4>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                    <label>Cantidad a Ajustar</label>
                    <div>
                        <input type="number" formControlName="amountChanges" class="form-control form-control-lg" />
                        <ng-container *ngIf="formInvalid && form?.get('amountChanges')?.invalid">
                          <span class="text-danger" *ngIf="form.controls['amountChanges'].errors?.required">Este campo es requerido</span>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group">
                    <label>Ajuste Realizado Por</label>
                    <div>
                        <input type="text" class="form-control form-control-lg" [value]="madeby" readonly="readonly" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2 "
    (click)="close()" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button (click)="Acept()" class="btn btn-pill font-weight-bolder btn-shadow btn-success d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Guardar
    </button>
</div>
