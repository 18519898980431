import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IHttpClient } from './Ihttp-client';
import { personalInformation } from 'src/app/shared/models/candidate-registration.model';
import { AuthInfoService } from '../auth-info.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';

@Injectable({
  providedIn: 'root',
})
export class CandidateRegService {
  private readonly url = environment.api_core_url
  constructor(private _http: HttpClient, private authInfo: AuthInfoService) { }
  route = '/ParameterControl/GetKeyWord';

  getParameters(keyWord: string): Observable<any> {
    return this._http.get<ResponseModel<ParameterControl>>(`${this.url}/ParameterControl/GetKeyWord?parameter=${keyWord}&CompanyCode=${this.authInfo.getCompanyId()}&Status=true`);
  }

  getCountry(CountryId: number = 0): Observable<any> {
    return this._http.get<any>(`${this.url}/Country?countryId=${CountryId}`);
  }

  getCity(CountryId: number = 0): Observable<any> {
    return this._http.get<any>(`${this.url}/State?CountryId=${CountryId}&Type=2&CompanyId=${this.authInfo.getCompanyId()}`);
  }
  
  getDistrit(StateId: number): Observable<any> {
    return this._http.get<any>(`${this.url}/City?StateId=${StateId}&Type=2&CompanyId=${this.authInfo.getCompanyId()}`);
  }

  getSectors(cityId: number): Observable<any> {
    return this._http.get<any>(`${this.url}/Sector/GetSector?CityId=${cityId}&Type=2&CompanyId=${this.authInfo.getCompanyId()}`);
  }

  postFile(name, type) {
    var options = { headers: new HttpHeaders().set('Content-Type', 'application/image') };
    var body = JSON.stringify({
      filename: name,
      contentType: type,
    })
    let url = 'https://isf-dev.ewr1.vultrobjects.com/Capture.JPG?AWSAccessKeyId=BBDFRVU2G7CIWHT3I8Q1&Expires=1634677478&Signature=kmgGYTUJhqhExnF1yYpw2eSPBH4%3D'

    return this._http.post<any>(url, body);

  }

}
