
import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaskService } from 'ngx-mask';
import { DropdownModel } from '../../interface/dropdown-model';

@Component({
  selector: 'el-input',
  templateUrl: './input.component.html',

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    },
    MaskService,
  ]
})
export class InputComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() placeholder?: string = '';
  @Input() required?: boolean = false;
  @Input() name?: string = '';
  @Input() type: string = "text";
  @Input() id?: string = '';
  @Input() value?: string = '';
  @Input() label?: string = '';
  @Input() rows?: number = 3;
  @Input() data: string = '';
  @Input() mask: string = '';
  @Input() min: string = null
  @Input() prefix?: string = '';
  @Input() customClass: string = '';
  @Input() options: DropdownModel[];
  @Output() getValue?= new EventEmitter<string>();
  @Input() disabled?: boolean = false;
  @Input() class?: string;
  @Input() inlineAlign: boolean = false;
  @Output() dataChange = new EventEmitter();
  dataCopy;
  @Input() checked = [];
  @Output() getChecked = new EventEmitter();
  isDisabled: boolean;
  onChangeFn = (_: any) => { }
  onTouch = () => { }
  emailPattern = '^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$'

  constructor(private maskService: MaskService) { }

  onBlur() {
    this.onTouch();
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value || '';
    } else {
      this.value = '';
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit(): void {
  }


  ngOnChanges(change: SimpleChanges): void {
    this.dataCopy = this.data;
  }

  onValueChange(): void {
    this.getValue.emit(this.value);
  }

  onChecked(): void {
    this.getChecked.emit(this.checked);
  }

  change(Value): void {
    this.value = Value;
    this.onTouch();
    this.onChangeFn(this.value);
    this.onValueChange();
    this.data = this.dataCopy;
    this.onDataChange();
  }

  onDataChange(): void {
    this.dataChange.emit(this.data);
  }

  onChange(): void {
    this.data = this.dataCopy;
    this.onDataChange();
  }

  toggleMask(Value: string): void {
    Value = Value.replace(this.prefix,'');
    let formatValue = this.maskService.removeMask(Value);
    this.change(formatValue);
  }

  getCheckedValues(opt, value): void {
    return value.find(x => x === opt);
  }

  changeCheckedValue(event): void {
    const found = this.checked.findIndex(x => x === +event);
    if (found != -1) {
      this.checked.splice(found, 1);
    } else {
      this.checked.push(+event);
    }
    this.onChecked();
  }
}
