import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { MatDialog } from '@angular/material/dialog';
import { IndicatorsModalComponent } from './indicators-modal/indicators-modal.component';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { IPeriod, PeriodConditions } from './Audit-Approval-models';
import { AuditApprovalService } from 'src/app/services/audit-approval.service';
import { RejectPayrollModalComponent } from './reject-payroll-modal/reject-payroll-modal.component';
import { headerCondition, IHeader, IHeaderWithSettings, IPayrollApproveSetting, IPostHeaderApprove } from '@payroll/payroll-models';
import { ToastrService } from 'ngx-toastr';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { DashboardAuditApprovalComponent } from '@payroll/dashboard-audit-approval/dashboard-audit-approval.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { DigitalSignatureApproval } from 'src/app/models/digital-signature-approval.model';
import { DigitalSignService } from 'src/app/services/digital-sign.service';
import { PdfPayrollReport } from './models/pdf-payroll-report.model';
import { DocumentRequest, Metadata } from 'src/app/models/sign-request.model';
import { parameterDoc } from 'src/app/shared/utils/parameterDoc';
import { docService } from 'src/app/services/doc.service';
import { CreateSignRequestService } from 'src/app/services/create-sign-request.service';
import { StatusDocuments, UserEntitiesAction } from 'src/app/models/enums-sign.model';
import { HeaderDigitalSignature } from './models/headerDigitalSignature.model';
import { SignApprovalProcessComponent } from '../../sign-approval-process/sign-approval-process.component';
import { formatDate } from '@angular/common';
import { ReplaySubject } from 'rxjs';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';
declare function applySort(): any;

@Component({
  selector: 'audit-approval',
  templateUrl: './audit-approval.component.html',
  styleUrls: ['./audit-approval.component.scss']
})
export class AuditApprovalComponent implements OnInit {

  //privilegio incompleto
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'AUDIT-NOM',
    auditAction: {key: 'AUDITAP', value: false},
    indicatorAction: {key: 'INDICATORSAP', value: false},
    rejectAction: {key: 'REJECTAP', value: false},
    dashboardAction: {key: 'AUDAP', value: false},
    exportAction: {key: 'EXPORTAP', value: false},
  }

  currentPeriod: IPeriod
  period: IPeriod;
  approveButtonDisable = false;
  auditButtonDisable = false;
  paginatedHeaders: any[] = [];
  selectedStatus: any[] = [];
  selectedPeriod: any[] = [];
  headers = [];
  @ViewChild('paginator') paginator: CaptionComponent;
  headersListOriginal = [];
  employeeTypes: any[] = [];
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';

  employeesCounted: number = 0;

  filterText: string = '';
  selectedHeader: any;
  selectedEmployeeType: any;
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  departmentsFilteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
    [
      { propkey: "payrollType", displayName: "Tipo de Nomina" },
      { propkey: "budgetArea", displayName: "Area Presupuestaria" }
    ];

  periodOptions = [

  ];

  periodConfig = {
    displayKey: 'description', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };

  public configDeclinePayroll = {
    displayKey: 'stringData', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };

  approveButtons = []
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  loading = false;
  private usersApprovals: any = [];
  companySign: any;
  file = { logo: '', image1: '', image2: '' }
  constructor(private _exlService: ExportToExcelService,
    private srvAudit: AuditApprovalService,
    private _toastr: ToastrService,
    private _ParamService: ParameterControlService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private docService: docService,
    private srvCreateSign: CreateSignRequestService,
    private _parameterControlService: ParameterControlService,
    private digitalSignService: DigitalSignService,
    public authInfo: AuthInfoService) {
  }

  ngOnInit(): void {
    this.privilege = this.authInfo.setPrivileges(this.privilege);
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.loadPeriods();
        this.getCompanyByID(this.authInfo?.getCompanyId());
      }
    });
    applySort();
  }
  openIndicators(item) {
    this.dialog.open(IndicatorsModalComponent, {
      width: '100%',
      height: '90%',
      data: { item }
    })
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  getParameterRoutes() {
    this._parameterControlService.getParameters(parameterDoc.PathTest).subscribe(e => {
      if (e.dataList.length > 0) {
        this.routeFile = e.dataList[0].stringData.toString();
      }
    });
  }

  setGuids(company) {
    this.docService.getDocument(company?.logo).subscribe(e => {
      this.file.logo = e?.data;
    });
  }


  loadPeriods() {
    this.srvAudit.getPeriods(10).subscribe((res: ResponseModel<IPeriod>) => {
      if (res.succeded) {
        let periods: IPeriod[] = res.dataList;
        this.periodOptions = periods.filter(x => x.conditionId == PeriodConditions.open && x.status == true)
        this.period = this.periodOptions[0]
        this.changePeriod();
      } else {
        this._toastr.error(res.errors[0])
      }
    }, err => {
      this._toastr.error('', 'ERROR INESPERADO')
    })
  }

  changePeriod() {
    this.headersListOriginal = [];
    this.headers = [];
    if (this.period?.payrollPeriodId) {
      this.loadHeaders()
    }
  }

  openRejectModal(header: any, rejectReason: string = '') {
    this.dialog.open(RejectPayrollModalComponent, {
      width: '50%',
      data: { headerId: header?.headerId, rejectReason: rejectReason }
    }).afterClosed().subscribe((result: any) => {
      if (result?.saved) {
        this._toastr.success("Datos guardados")
        this.loadHeaders()
      }
    })
  }

  loadHeaders() {
    this.srvAudit.getHeaderAndSettingsByPeriod(this.period.payrollPeriodId).subscribe((res: ResponseModel<any>) => {
      if (!res.succeded) {

        res.errors.forEach(err => {
          this._toastr.error(err)
        });

        res.warnings.forEach(warn => {
          this._toastr.warning(warn)
        });

        return;
      }


      const headers = res.dataList.map(x => {
        const header = { 
          ...x.header
        };
        header['payrollApprove'] = x.payrollApprove;
        header['payrollApproveSetting'] = x.payrollApproveSetting.reverse();
        header['disableAuditButton'] = header.conditionId == headerCondition.audited;
        
        if (header.payrollApprove.length > 0) {
          header.payrollApproveSetting.forEach(setting => {
            setting['disableButton'] = header.payrollApprove.some(approve => approve.approvedLevel == setting.approvedLevel);
          });
      
          const availableButtons = header.payrollApproveSetting.filter(setting => !setting['disableButton']);
      
          if (availableButtons.length > 1) {
            availableButtons.sort((a, b) => a.approvedLevel - b.approvedLevel);

            header.payrollApproveSetting.forEach(setting => {
              setting['disableButton'] = setting.approvedLevel != availableButtons[0].approvedLevel;
            });
          }
        } else if (header.conditionId !== headerCondition.audited) {
          header.payrollApproveSetting.forEach(e => {
            e['disableButton'] = true;
          });
        }
      
        return header;
      });
      
      this.headersListOriginal = headers;
      this.headers = [...headers];


    }, err => {
      this._toastr.error(this.errorMessage)
    }, () => {

    });
  }
  onBtnPrintClick(){
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }
  getCompanyByID(CompanyId) {
    this.srvAudit.getCompanyByID(CompanyId).subscribe((res: any) => {
      if (res.errors.length > 0) {
        return;
      }
      this.companySign = res.dataList[0];
      this.setGuids(this.companySign);
      this.getParameterRoutes();
    }, error => {
    });
  }

  digitalSignature(header: IHeader){
    if(this.companySign?.useDigitalSignature && !this.companySign?.entityCode){
      this._toastr.warning('La institución no tiene una entidad asignada para la firma digital');
      return;
    }
    if (header?.conditionId == headerCondition?.audited) {
      return;
    }
    const approve = {
      headerId: header?.headerId,
      generatedToken: "",
      confirmationToken: "",
      companyId: this.authInfo.getCompanyId(),
      status: true,
      approvedLevel: 0,
      createUserId: this.authInfo.getUserId(),
      modifyUserId: 0,
      approvedDate: new Date(),
      createDate: new Date(),
      employeeAprovedId: 0,
      modifyDate: new Date(),
      payrollApproveId: 0,
    }
    const pdfPayrollReport: PdfPayrollReport = {
      headerId: header?.headerId,
      companyId: header?.companyId,
      type: 2,
      institutionImage: this.file?.logo ?? ''
    }
    this.srvAudit.getHeaderDigitalSignature(header.headerId, DigitalSignatureApproval.ApproveSignPayroll, header.companyId).subscribe(res => {
      if (res.errors.length > 0) {
        this._toastr.error(res.errors[0]);
        return;
      }
      const headerDigitalSignatures = res.dataList?.filter(d => d?.status)[0];
      if (headerDigitalSignatures?.publicAccessId) {
        this.openSignApprovalProcessComponent(headerDigitalSignatures, header, () => {
          this.payrollApproveDigitalSignature(approve?.headerId);
        });
      }else {
        this.confirmSignPayroll(pdfPayrollReport, DigitalSignatureApproval.ApproveSignPayroll);
        return;
      }
    });
  }

  async fun1(header: IHeader) {
    if (header.conditionId == headerCondition.audited) {
      return
    }
    let randomNumber = Math.floor((Math.random() * (99 - 10 + 1)) + 10);
    let approve: IPostHeaderApprove;
    Swal.fire({
      title: 'Auditar Nómina',
      html: `Por favor introduzca el código <b class="text-danger">${randomNumber}</b>`,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Cancelar',
      preConfirm: (data) => {
        if (data == '' || data == undefined) {
          Swal.showValidationMessage(
            'Debe digitar el código requerido'
          );
          return;
        }
        if(data != randomNumber){
          Swal.showValidationMessage(
            'El código digitado es incorrecto'
          );
          return;
        }
        else {
          approve = {
            headerId: header.headerId,
            generatedToken: randomNumber.toString(),
            confirmationToken: data,
            companyId: this.authInfo.getCompanyId(),
            status: true,
            approvedLevel: 0,
            createUserId: this.authInfo?.getUserId(),
            modifyUserId: 0,
            approvedDate: new Date(),
            createDate: new Date(),
            employeeAprovedId: 150,
            modifyDate: new Date(),
            payrollApproveId: 0,
          }
          return;
        }
      },
    }).then((result) => {
      if (!result.dismiss) {
        if (result.isConfirmed) {
          this.payrollApprove(approve);
        }
      }
    })
  }

  payrollApproveDigitalSignature(headerId: number){
    const model = {
      userId: this.authInfo.getUserId(),
      headerId: headerId
    }
    
    this.srvAudit.payrollApprove(model).subscribe((res: ResponseModel<any>) => {
      if (res.succeded) {
        this._toastr.success("Nómina Firmada");
        this.loadHeaders();
      } else {
        this._toastr.error(res.errors[0]);
      }
    }, e => {
      this._toastr.error(this.errorMessage);
    });
  }

  payrollApprove(approve){
    this.srvAudit.postHeaderApprove(approve).subscribe((res: ResponseModel<any>) => {
      if (res.succeded) {
        this._toastr.success("Nómina Auditada");
        this.loadHeaders();
      } else {
        this._toastr.error(res.errors[0])
      }
    }, e => {
      this._toastr.error(this.errorMessage)
    });
  }

  confirmSignPayroll(pdfPayrollReport, ApprovePayrollAudit){
    Swal.fire({
      title: 'Firmar Nómina',
      html: '¿Está seguro de que desea enviar a firma esta nómina?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (!result.dismiss) {
        if (result.isConfirmed) {
          this.checkConfiguration(pdfPayrollReport, ApprovePayrollAudit, null);
        }
      }
    })
  }

  openSignApprovalProcessComponent(headerDigitalSignature: HeaderDigitalSignature, header: any, approvePayroll: () => void) {
    this.dialog
      .open(SignApprovalProcessComponent, {
        data: {
          publicAccessId: headerDigitalSignature?.publicAccessId,
          title: 'Validar Firma',
        },
        width: '100%',
        minWidth: '50%',
        minHeight: '70%'
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.Applied) {
          if (result?.status === StatusDocuments.COMPLETED) {
            approvePayroll();
          }
          if (result?.status === StatusDocuments.REJECTED) {
            this.openRejectModal(header, result?.rejectInfo?.rejectReason || result?.rejectInfo?.rejectType);
          }
          if (result?.status === StatusDocuments.EXPIRED) {

          }
        }
      });
  }

  checkConfiguration(pdfPayrollReport: PdfPayrollReport, processId: number, documentRequest: DocumentRequest) {
    this.digitalSignService.getUserApprovals(this.authInfo.getCompanyId(), processId).subscribe((res: any) => {
      if (!res.succeded) {
        this._toastr.error(res.errors[0]);
        return;
      }
      if (res?.singleResultData.approvalsIdentification?.length === 0
        && res?.singleResultData.signatureIdentification?.length === 0) {
        this._toastr.warning('No se encontró ninguna configuración de usuarios para la firma digital');
        return;
      }
      this.usersApprovals = res?.singleResultData;
      Swal.fire('Enviando petición de firma', '', 'info')
      Swal.showLoading();
      this.getPdfPayrollReport(pdfPayrollReport, processId);
    }, error => {
      Swal.close();
      this._toastr.error('Ocurrió un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.');
    });
  }

  getPdfPayrollReport(pdfPayrollReport: PdfPayrollReport, processId: number) {
    this.srvAudit.getPdfPayrollReport({
      headerId: pdfPayrollReport.headerId,
      companyId: pdfPayrollReport.companyId,
      type: pdfPayrollReport.type,
      institutionImage: pdfPayrollReport.institutionImage
    }).subscribe((resFile: ResponseModel<any>) => {
      if (resFile?.errors?.length > 0) {
        Swal.close();
        this._toastr.error(resFile?.errors[0])
        return;
      }
      let data = {
        SystemLoad: this.SystemLoad,
        ModuleLoad: this.ModuleLoad,
        personalFile: 1,
        routeFile: this.routeFile
      }
      const { initDate, expirationDate } = resFile?.singleData;
      let formData = new FormData();
      fetch(`data:application/pdf;base64,${resFile?.singleData?.base64}`)
        .then(res => res.blob())
        .then(blob => {
          const titleSign = 'Firmar Nómina';
          const fileName = 'Firmar Nómina.pdf';
          const keyName = 'Firmar-Nómina';
          const file = new File([blob], fileName);
          formData.append(titleSign, file);
          const metadatas = [{ key: keyName, value: titleSign } as Metadata]
          this.docService.createFile(data, formData).subscribe((result: any) => {
            if (result?.data?.path) {
              const documents = [
                {
                  filename: fileName,
                  url: result?.data?.path
                }];
              const headerDigitalSignature: HeaderDigitalSignature = {
                headerDigitalSignatureId: 0,
                headerId: pdfPayrollReport?.headerId,
                processId: processId,
                publicAccessId: null,
                companyId: pdfPayrollReport?.companyId,
                status: true,
                createDate: new Date(),
                createUserId: this.authInfo.getUserId(),
                modifyDate: null,
                modifyUserId: 0
              }
              this.CreateSignRequest(metadatas, documents, titleSign, initDate, expirationDate, headerDigitalSignature);
            }

          }, err => {
            Swal.close();
            this._toastr.error('Ha ocurrido un error al obtener el documento a firmar, por favor intente más tarde o comuníquese con HelpDesk.');
          }
          )
        })
    }, error => {
      Swal.close();
    });
  }

  CreateSignRequest(metadatas, documents, subject, initDate, expirationDate, _headerDigitalSignature: HeaderDigitalSignature) {
    this.srvCreateSign.CreateSignRequest(
      metadatas,
      subject,
      subject,
      documents,
      [],
      this.getAddresseeGroups,
      initDate,
      expirationDate,
      this.companySign?.entityCode
    ).subscribe((data: any) => {
      if (data?.errorCode) {
        Swal.close();
        this._toastr.error('Ha ocurrido un error creando la petición de la firma');
        return;
      }
      if (data?.publicAccessId) {
        const headerDigitalSignature: HeaderDigitalSignature = {
          ..._headerDigitalSignature,
          publicAccessId: data?.publicAccessId,
        }
        this.postHeaderDigitalSignature(headerDigitalSignature);
      }
 
    }, error => {
      Swal.close()
      this._toastr.error(error?.error?.errorMessage ?? '', 'Ha ocurrido un error creando la petición de la firma');
    });
  }

  postHeaderDigitalSignature(headerDigitalSignature: HeaderDigitalSignature) {
    this.srvAudit.postHeaderDigitalSignature(headerDigitalSignature).subscribe(res => {
      if (res.errors.length > 0) {
        Swal.close();
        this._toastr.error(res.errors[0]);
        return;
      }
      Swal.fire({
        title: "Petición de firma enviada correctamente",
        icon: "success"
      });
    }, error => {
      this._toastr.error('Ha ocurrido un error enviando la petición de la firma');
      Swal.close();
    });
  }

  async fun2() {
    Swal.fire({
      title: 'Autorizar Nómina (Director General)',
      html: 'Por favor introduzca el número <b class="text-danger">45</b> de su tarjeta de códigos.',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Cancelar',
      preConfirm: (data) => {
        if (data == '' || data == undefined) {
          Swal.showValidationMessage(
            'Debe digitar el código requerido'
          )
        }
        else {
          return;
        }

      },
    }).then((res) => {
      if (!res.dismiss) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 2000)
      }
    })
  }

  async fun3(header: IHeader, setting: IPayrollApproveSetting) {
    if (setting.disableButton) {
      return
    }
    let randomNumber = Math.floor((Math.random() * (99 - 10 + 1)) + 10);
    let approve: IPostHeaderApprove;
    let text = (setting.approvedLevel == 2) ? '(Director General)' : '(GTE. Financiero)';
    Swal.fire({
      title: `Aprobar Nómina ${text}`,
      html: `Por favor introduzca el código <b class="text-danger">${randomNumber}</b>`,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Cancelar',
      preConfirm: (data) => {
        if (data == '' || data == undefined) {
          Swal.showValidationMessage(
            'Debe digitar el código requerido'
          );
          return;
        } 
        if(data != randomNumber){
          Swal.showValidationMessage(
            'El código digitado es incorrecto'
          );
          return;
        }
        else {
          approve = {
            headerId: header.headerId,
            generatedToken: randomNumber.toString(),
            confirmationToken: data,
            companyId: this.authInfo.getCompanyId(),
            status: true,
            approvedLevel: setting.approvedLevel,
            createUserId: this.authInfo?.getUserId(),
            modifyUserId: 0,
            approvedDate: new Date(),
            createDate: new Date(),
            employeeAprovedId: 0,
            modifyDate: new Date(),
            payrollApproveId: 0,

          }
          return;
        }
      },
    }).then((result) => {
      if (!result.dismiss) {
        this.loading = true
        if (result.isConfirmed) {
          this.approvalPayroll(approve);
        }
      }
    })
  }

  confirmApprovalPayrollLevels(approve, setting){
    let text = (setting?.approvedLevel == 2) ? '(Director General)' : '(GTE. Financiero)';
    Swal.fire({
      title: `Aprobar Nómina ${text}`,
      html: '¿Está seguro de que desea aprobar esta nómina?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (!result.dismiss){
        this.approvalPayroll(approve);
      }
    });
  }

  approvalPayroll(approve){
    this.srvAudit.postHeaderApprove(approve).subscribe((res: ResponseModel<any>) => {
      if (res.succeded) {
        this._toastr.success(`Nómina Aprobada N-${approve?.approvedLevel}`);
        this.loadHeaders()
      } else {
        this._toastr.error(res.errors[0])
      }
    }, e => {
      this._toastr.error(this.errorMessage)
    }, () => {

    })
  }

  async fun4() {
    Swal.fire({
      title: 'Rechazar Nómina',
      html: 'Por favor introduzca el número <b class="text-danger">36</b> de su tarjeta de códigos.',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Cancelar',
      preConfirm: (data) => {
        if (data == '' || data == undefined) {
          Swal.showValidationMessage(
            'Debe digitar el código requerido'
          )
        }
        else {
          return;
        }

      },
    }).then((res) => {
      if (!res.dismiss) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 2000)
      }
    })
  }
  exportToExcel() {
    let records = this.headers.map((header:any) => {
      return {
        headerId: header?.headerId,
        payrollPeriodId: header?.payrollPeriodId,
        payrollTypeId: header?.payrollTypeId,
        payrollType: header?.payrollType,
        conditionId: header?.conditionId,
        headerCondition: header?.headerCondition,
        companyId: header?.companyId,
        status: header?.status,
        createDate: header?.createDate,
        createUserId: header?.createUserId,
        modifyDate: header?.modifyDate,
        modifyUserId: header?.modifyUserId,
        versionId: header?.versionId,
        period: header?.period,
        budgetAreaId: header?.budgetAreaId,
        budgetArea: header?.budgetArea,
        sendLimitDate: header?.sendLimitDate,
      }
    })

    let excelHeaders: string[][] = [[
      "Secuencia",
      "Tipo de Nomina",
      "Área Presupuestaria",
      "Estatus"
    ],
    [
      "headerId",
      "payrollType",
      "budgetArea",
      "headerCondition",
    ]]
    this._exlService.exportToExcelSpecificColumns(records, excelHeaders, 'Lista de auditoría nóminas', true);
  }

  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
  }

  getPaginatedRecords(event) {
    this.paginatedHeaders = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }


  onKeyChange(text) {
    this.filterText = text;
  }

  openDashBoard(): void {
    this.dialog.open(DashboardAuditApprovalComponent, {
      width: '100%'
    })
      .afterClosed()
      .subscribe((result) => {
      });
  }

  get getAddresseeGroups() {
    const userEntitiesAPPROVAL = {
      isOrGroup: false,
      userEntities: this.usersApprovals?.approvalsIdentification.map(identification => {
        return {
          userCode: identification,
          entityCode: this.companySign?.entityCode,
          action: UserEntitiesAction.APPROVAL
        }
      })
    };
    const userEntitiesSIGN = {
      isOrGroup: false,
      userEntities: this.usersApprovals?.signatureIdentification.map(identification => {
        return {
          userCode: identification,
          entityCode: this.companySign?.entityCode,
          action: UserEntitiesAction.SIGN
        }
      })
    };
    let addresseeGroups = [
      {
        addresseeGroups: []
      }
    ];
    const isSignature = userEntitiesSIGN.userEntities.length > 0;
    const isApproval = userEntitiesAPPROVAL.userEntities.length > 0;
    if (isApproval) {
      addresseeGroups[0].addresseeGroups.push(userEntitiesAPPROVAL);
    }
    if (isSignature) {
      addresseeGroups[0].addresseeGroups.push(userEntitiesSIGN);
    }
    return addresseeGroups;
  }

  exportToPdf() {
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.AuditApprovalPayroll;
    const periodId =  this.period.payrollPeriodId
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&PeriodId=${periodId}&PayrollTypeId=0&BudgetAreaId=0&CompanyId=${companyId}&Status=true`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Unidades Organizativas',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }


}
