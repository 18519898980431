<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        {{title}} Período
    </h2>
        <button type="button" (click)="this.dialogRef.close({created:false});" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    
    </div>

<div class="mat-dialog-content">
    <div class="card-body py-0">
        <form [formGroup]="periodForm" style="max-width: 400px;">
            <div class="row">
                <div class="form-group col-md-12">
                    <div>
                        <label for="startDate">
                            Fecha de Inicio<span class="text-danger text-monospace legend">*</span>
                        </label>
                    </div>
                    <input formControlName="startDate"
                        (change)="startOrEndRestrictions = calendarFixedDateScope('endDate')"
                        class="form-control form-control-lg dynamicFormControl" id="startDate"
                        placeholder="Fecha de ingreso" type="date" />
                    <errors-component [controlName]="periodForm.controls['startDate']"></errors-component>

                    <!-- <span class="form-text text-danger" *ngIf="formIsInvalid">{{showApplicationDateErrors()}}</span> -->
                </div>

                <div class="form-group col-md-12">
                    <div>
                        <label for="endDate">
                            Fecha Fin<span class="text-danger text-monospace legend">*</span>
                        </label>
                    </div>
                    <input formControlName="endDate"
                        (change)="openingOrClosingRestrictions = calendarFixedDateScope('closingDate')"
                        min="{{periodForm.get('startDate').value | date:'yyyy-MM-dd'}}"
                        [max]="startOrEndRestrictions.endRestriction"
                        class="form-control form-control-lg dynamicFormControl" id="endDate"
                        placeholder="Fecha de ingreso" type="date" />
                    <errors-component [controlName]="periodForm.controls['endDate']"></errors-component>

                    <!-- <span class="form-text text-danger" *ngIf="formIsInvalid">{{showApplicationDateErrors()}}</span> -->
                </div>

                <fieldset class="" [disabled]="!periodForm.get('startDate').value && !periodForm.get('endDate').value ">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <div>
                                <label for="openingDate">
                                    Fecha de Apertura<span class="text-danger text-monospace legend">*</span>
                                </label>
                            </div>
                            <input formControlName="openingDate" (change)="setCloseDate()"
                                min="{{periodForm.get('startDate').value | date:'yyyy-MM-dd'}}"
                                max="{{periodForm.get('endDate').value | date:'yyyy-MM-dd'}}"
                                class="form-control form-control-lg dynamicFormControl" id="openingDate"
                                placeholder="Fecha de ingreso" type="date" />
                            <errors-component [controlName]="periodForm.controls['openningDate']"></errors-component>

                            <!-- <span class="form-text text-danger" *ngIf="formIsInvalid">{{showApplicationDateErrors()}}</span> -->
                        </div>

                        <div class="form-group col-md-12">
                            <div>
                                <label for="sendLimitDate">
                                    Fecha de Cierre<span class="text-danger text-monospace legend">*</span>
                                </label>
                            </div>
                            <input formControlName="sendLimitDate"
                                min="{{(periodForm.get('openingDate').value ? periodForm.get('openingDate').value : periodForm.get('startDate').value)  | date:'yyyy-MM-dd'}}"
                                max="{{periodForm.get('endDate').value | date:'yyyy-MM-dd'}}"
                                class="form-control form-control-lg dynamicFormControl" id="sendLimitDate"
                                type="date" />
                            <errors-component [controlName]="periodForm.controls['sendLimitDate']"></errors-component>
                        </div>
                    </div>
                </fieldset>

                <div class="form-group col-md-8" *ngIf="periodForm.get('action').value=='update'">
                    <div>
                        <label for="description">
                            Descripción<span class="text-danger text-monospace legend">*</span>
                        </label>
                    </div>
                    <input formControlName="description" type="text"
                        class="form-control form-control-lg dynamicFormControl" id="description"
                        placeholder="Descripcións" required="" />
                    <errors-component [controlName]="periodForm.controls['description']"></errors-component>
                    <!-- <span class="form-text text-danger" *ngIf="formIsInvalid">{{showNumberPlacesErrors()}}</span> -->
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer">
    <button type="button" (click)="cancel()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex mr-2">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button data-dismiss="modal" type="button" (click)="saveForm()" [disabled]="periodForm.invalid"
        class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success">
        <i class="flaticon2-plus"></i>
        Guardar
    </button>
</div>