import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-disapprove-payroll-news',
  templateUrl: './disapprove-payroll-news.component.html',
  styleUrls: ['./disapprove-payroll-news.component.css']
})
export class DisapprovePayrollNewsComponent implements OnInit, AfterViewChecked {

  disapproveNewForm = new FormGroup({
    reason: new FormControl('', Validators.required)
  })
  formIsInvalid = false

  constructor(
    private _toastService: ToastService,
    public dialogRef: MatDialogRef<DisapprovePayrollNewsComponent>,
    private _changeDet: ChangeDetectorRef, 
    private payrollNewService: PayrollNewService,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public authInfo: AuthInfoService) { }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  Accept() {
    if (this.disapproveNewForm.invalid) {
      this.formIsInvalid = true;
      return;
    }

    let data = {
      payrollNewsId: this.data.payrollNew.payrollNewsId,
      reason: this.disapproveNewForm.get('reason').value,
      //status: true,
      //companyId: this.authInfo.getCompanyId(),
      userId: this.authInfo.getUserId(),
    }

    this.payrollNewService.disapprovePayrollNews(data).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error("Error al desaprobar la novedad")
        return;
      }
      this._toastService.success('Novedad desaprobada satisfactoriamente')
      const row = { disapproved: true }
      this.dialogRef.close(row);
    })
  }

  Cancel(): void {
    const row = { disapproved: false }
    this.dialogRef.close(row);
  }

  get showErrors() {
    return {
      errorReason: this.disapproveNewForm.get('reason').invalid,
    }
  }

}
