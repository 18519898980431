import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import { utils, WorkBook, WorkSheet, write} from "xlsx"

@Injectable({
    providedIn: 'root',
})

export class ExportToExcelService {
    readonly EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
    readonly EXCEL_EXT = '.xlsx';

    exportToExcel(json: any[], filename: string) {
        const worksheet: WorkSheet = utils.json_to_sheet(json);
        const workbook: WorkBook = {
            Sheets: {'data': worksheet},
            SheetNames: ['data'],
        }
        
        const excelBuffer: any = write(workbook, {bookType: 'xlsx', type: 'array'});
        this.SaveExcel(excelBuffer, filename);
    }

    private SaveExcel(buffer: any, fileName: string) {
        const data: Blob = new Blob([buffer], {type: this.EXCEL_TYPE});
        FileSaver.saveAs(data, `${fileName}_export${new Date().getTime()}${this.EXCEL_EXT}`);
    }
}