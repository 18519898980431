<div class="card card-custom" style="justify-content: flex-start !important">
    <div class="card-header mt-3 " style="justify-content: flex-start !important">
        <div class="card-title flex-column align-items-start" style="justify-content: flex-start !important">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-recursos-humanos-3 icon-3x text-danger mr-3"></i>
                <h2 class="text-primary"> Estructura Orgánica </h2>
            </div>
        </div>
    </div>

    <!-- <div class="card-header mt-3">
        <div class="card-title d-flex justify-content-end">
            <div class="d-flex justify-content-around mr-2">
                <div class="form-group mx-2">
                    <label class="text-primary">
                        Institución
                        <span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                        <ngx-select-dropdown class=" flex-1" [options]="companies" [config]="configDDLCompany"
                            [(ngModel)]="company">
                        </ngx-select-dropdown>

                        <button data-toggle="tooltip" title="" class="input-group-text bg-primary border-primary"
                            data-original-title="Buscar" (click)="search()">
                            <i class="flaticon2-search-1 icon-2x text-white"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="card-body min-h-500px">
        <div class="row gutter-b">
            <div id="svgChartx" #showStructure ></div>
        </div>
    </div>
</div>