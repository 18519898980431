<div class="container-fluid py-3">
    <button (click)="goBack()"  class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning ">
        <i class="flaticon2-left-arrow-1"></i>
        <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
    </button>
</div>

<div class="container-fluid ">
    <div class="card card-custom">
        <div class="card-header mt-3 ">
            <div class="card-title">
                <div class="ttl d-flex align-items-center ">
                    <i class="flaticon-recursos-humanos-4 icon-4x text-danger mr-2 forward-btn"></i>
                    <h1 class="text-primary forward-btn">Reporte de Servidores Públicos {{showEstatus}}</h1>
                </div>
            </div>
        </div>
        <!--div class="card-header row d-flex justify-content-end p-2 mx-0 ">
            <div class="col-sm-12 col-md-4 c-right mr-0 forward-btn ">
                <div class="card-toolbar ">
                    <button  onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow
                     mt-9 mx-2 col-md-auto">
                        <i class="flaticon2-printer font-size-h1"></i>
                    </button>
                </div>
            </div>
        </div-->
        <div class=" px-0">
            <div class="container-fluid">
                <div id="pdfExp" class="card">
                    <div class="card-header px-22">
                        <div class="row" id="header">
                            <div class="col-md-3">
                                <div class="form-group mb-0">
                                    <div class="card-body p-0">
                                        <div>
                                            <div class="evidence"></div>
                                        </div>
                                        <div>
                                            <span *ngIf="loadingPic" class="d-flex flex-column align-items-center">
                                                <img src="assets/logos/logo-sigei-100-02.png" alt="loading"
                                                    width="70px" />
                                                <strong class="text-danger font-size-h3">Cargando...</strong>
                                            </span>

                                            <ng-container *ngIf="!loadingPic">
                                                <div class="logo px-2" *ngIf="photoUrl">
                                                    <img [src]="file.logo" alt="" class="max-w-200px" />
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="title p-2 d-flex justify-content-center align-items-center flex-column col-md-auto w">
                                <h1 class="ttlPDF text-primary text-center">
                                    Servidores Públicos {{showEstatus}}
                                    </h1>
                                <!--h3 class=" text-center">---

                                </h3-->
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div id="pdf">
                            <div class="pdf-page pb-4">
                                <div class="card">
                                    <div class="card-body m-0 p-0 table-responsive-md ">
                                        <table class="table mt-2  text-center">
                                            <thead>
                                                <tr class="bg-primary">
                                                    <th class="text-left">Servidor Público</th>
                                                    <th class="text-left">Género</th>
                                                    <th class="text-left">Cargo</th>
                                                    <th class="text-left">Estatus</th>
                                                    <th class="text-left">Salario Bruto</th>
                                                    <th class="text-left">Fecha Nombramiento</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let item of employeeByDepartment">
                                                    <tr>
                                                         <th class="text-left bg-light-secondary" colspan="12" >
                                                            <span class="text-dark text-uppercase ">
                                                              {{item.department}}
                                                            </span>
                                                        </th>
                                                    </tr>
                                                    <tr *ngFor="let employee of item.employees">
                                                        <td class="text-left">
                                                            {{employee.name}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{employee.sex}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{employee.positionName}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{(employee.status ? 'Activo' : 'Desvinculado')}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{employee.salary | currency: 'RD$'}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{employee.admissionDate |date: 'dd/MM/yyy'}}
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="card-footer gutter-t">
                        <div class="d-flex flex-wrap justify-content-between align-items-end" id="footer">
                            <img [src]="file.image1 ? file.image1 : 'assets/media/logos/xmera-logo-3.png'" alt=""
                                class="max-w-80px">
                            <img [src]="file.image2 ? file.image2 : 'assets/media/logos/xmera-logo-3.png'" alt=""
                                class="max-w-175px">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<button onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
    <i class="flaticon2-printer font-size-h1"></i>
  </button>

<script>
    let print = () => {
        let pdf = document.getElementById("pdfExp");
        pdf.contentWindow.focus();
        pdf.contentWindow.print();
    };
</script>