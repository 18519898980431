import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PayrollConceptService } from 'src/app/services/payroll-concept.service';
import { AddConceptModalComponent } from './add-concept-modal/add-concept-modal.component';
import { FormulaModalComponent } from './formula-modal/formula-modal.component';
import { ToastrService } from 'ngx-toastr';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { BulkLoadPayrollConceptComponent } from './bulk-load-payroll-concept/bulk-load-payroll-concept.component';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import Swal from 'sweetalert2';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { map } from 'rxjs/operators';
import { AdvancedFilterByPayrollConceptComponent } from './advanced-filter-by-payroll-concept/advanced-filter-by-payroll-concept.component';
import { FilterEvent } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { AuditChangesComponent } from './audit-changes/audit-changes.component';
declare function applySort(): any;

@Component({
  selector: 'payroll-concept',
  templateUrl: './payroll-concept.component.html',
  styleUrls: ['./payroll-concept.component.css']
})
export class PayrollConceptComponent implements OnInit, AfterViewChecked {

  companies = []

  searchParameter: FilterEvent;
  employeesPagedResut = new PagedResult<any>();
  company;
  //incompleto
  /* public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PAYROLL-CONCEPT',
    addAction: 'ADDCONCEPT',
    editAction: 'EDITCONCEPT',
    viewAction: 'VIEWCONCEPT',
    exportAction: 'EXPORTCONCEPT',
    searchAction: 'SEARCHCONCEPT',
    buldLoadAction: 'MASIVE-LOAD-CONCEPT'
  } */

  beneficiaryList:any [] = [];
  beneficiary: any [] = []
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PAYROLL-CONCEPT',
    addAction: {key: 'ADDCONCEPT', value: false},
    editAction:  {key: 'EDITCONCEPT', value: false},
    seeAction: {key: 'VIEWCONCEPT', value: false},
    deleteAction: {key: 'Delete', value: false},
    exportAction:  {key: 'EXPORTCONCEPT', value: false},
    searchAction:  {key: 'SEARCHCONCEPT', value: false},
    buldLoadAction: {key: 'MASIVE-LOAD-CONCEPT', value: false},
    auditChanges:{ key:'AUDIT-CHANGES',value: false },
    activate:{ key:'ACTIVATECONCEPT',value: false }
  }
  propKeyId = 0
  concepts = []
  conceptsOriginal = []
  record:[];
  filterText: string = '';
  paginatedList = []
  @ViewChild('paginator') paginator: CaptionComponent;
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  selectedItems: any = [{ item_id: 1, item_text: 'Activos' }];
  searchList: any[] = []; searchBarDisplayNames: { propkeyId?: number, displayName: string }[] =
  [
    { propkeyId: 1, displayName: "Secuencia" },
    { propkeyId: 3, displayName: "Código" },
    { propkeyId: 4, displayName: "Concepto" },
    { propkeyId: 5, displayName: "Orden de Ejecución" },
    { propkeyId: 6, displayName: "Tipo de Concepto" },
    { propkeyId: 7, displayName: "Tipo de Novedad" },
    { propkeyId: 2, displayName: "Beneficiario" },
    { propkeyId: 100, displayName: "Búsqueda Avanzada" },
    { propkeyId: 14, displayName: "Nombre Corto" },
  ];

  tempAdvanceSearch: any
  advancedFilter:any

  constructor(
    public dialog: MatDialog,
    private srvConcept: PayrollConceptService,
    private toastr: ToastrService,
    public authInfo: AuthInfoService,
    private _exlService: ExportToExcelService,
    private _changeDet: ChangeDetectorRef,
    private srvCompanies: CompaniesService,
  ) { }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  ngOnInit(): void {
    this.setPrivileges()
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result=>{
      if(result == true){
        this.getCompanies()
      }
    });
    applySort();
  }

  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' }
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

   openModalAdd(item = null, inDetails = false, inEdit = false){
    this.dialog.open(AddConceptModalComponent, {
      data: {
        item,
        inDetails,
        inEdit
      },
      width: '100%',
    }).afterClosed().subscribe({
      next: (res) => {
        if(res?.success){
          this.getAllConcept()
        }
      }
    })
  }

  openModalFormula(){
    this.dialog.open(FormulaModalComponent, {
      width: '100%',
    })
  }

  openModalAuditChanges(item){
    this.dialog.open(AuditChangesComponent,{
      data:{
        item
      },
      width:'100%'
    });
  }

  getCompanies(){
    const companyId = this.authInfo?.getCompanyId()
    this.srvCompanies.getCompaniesWithChildren().subscribe({
      next: (res:any) => {
        if(res.succeded == false) {
          this.toastr.error(res.errors[0]);
          return
        }
        this.companies = res.dataList
        const company = this.companies.find(x => x.companyId == companyId && x?.status)
        this.company = company
        if(this.company){
          this.getAllConcept()
        }
      }, error: (error:any) => {

      }
    })
  }


  getAllConcept(){
    const company = this.company
    if(!company?.companyId){
      this.concepts = [];
      this.conceptsOriginal = [];
      return;
    }

    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value ?? ''
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const status: number = this.selectedItems?.length === 2 || this.selectedItems?.length == 0 ? -1 : (this.selectedItems[0]?.item_id === 1 ? 1 : 0);
    const branchId: number = this.company?.companyId ?? 0
    const companyId = company?.companyId;
    const advancedsearch = this.advancedFilter ?? ''
    this.srvConcept.getAllNewConceptPaginated(branchId, page, pageSize, propKeyId, text, status, advancedsearch).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0]);
          return;
        }
        this.concepts = res.singleData.items;
        this.employeesPagedResut = res.singleData
        this.conceptsOriginal = res.dataList;
        /*this.srvConcept.getBeneficiaries().subscribe({
          next: (res:any) => {
            if(res.succeded == false){ this.toastr.error(res.errors[0])
              return
            }
            this.beneficiaryList = res.dataList
            this.concepts.forEach(m => {
              const result = this.beneficiaryList.find((x) => x.conceptBeneficiaryId  == m.conceptBeneficiaryId)
              this.beneficiary.push(result)

            })
            this.beneficiary

            for(let i=0; i < this.concepts.length; i++){
              const beneficiaryDescription = this.beneficiary[i];
              this.concepts[i].beneficiaryDescription = beneficiaryDescription?.description;
            }

          }, error: (error:any) => {
            this.toastr.error('Ha ocurrido un error inesperado')
          }
        })*/


        //this.filter();
      }, error: (error:any) => {

      }
    })
  }
  setPrivileges(){
    const privileges = this.privilege
    const nameKey = privileges.nameKeyOption

    this.privilege.addAction.value = this.authInfo.canDoAction(nameKey, privileges.addAction.key)
    this.privilege.deleteAction.value = this.authInfo.canDoAction(nameKey, privileges.deleteAction.key)
    this.privilege.editAction.value = this.authInfo.canDoAction(nameKey, privileges.editAction.key)
    this.privilege.seeAction.value = this.authInfo.canDoAction(nameKey, privileges.seeAction.key)
    this.privilege.exportAction.value = this.authInfo.canDoAction(nameKey, privileges.exportAction.key)
    this.privilege.searchAction.value = this.authInfo.canDoAction(nameKey, privileges.searchAction.key)
    this.privilege.buldLoadAction.value = this.authInfo.canDoAction(nameKey, privileges.buldLoadAction.key)
    this.privilege.auditChanges.value = this.authInfo.canDoAction(nameKey,privileges.auditChanges.key);
    this.privilege.activate.value = this.authInfo.canDoAction(nameKey,privileges.activate.key);
  }

  getPaginatedRecords(event: PaginationEvent) {
    if (this.employeesPagedResut.page == event.selectedPage && this.employeesPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.employeesPagedResut.page = event.selectedPage;
    this.employeesPagedResut.pageSize = event.registersPerPage;
    this.getAllConcept()
  }
  changeCompany(){
    this.getAllConcept()

  }
  getDropdownConfig(displayKey: string, placeholder?: string) {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: placeholder ? placeholder : 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }
  deleteConcept(item){
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#ff0000',
      title: '¿Seguro que desea inactivar este registro?',
    }).then(res => {
      if(res.isConfirmed){
        const model = {
          newsConceptId: item.newsConceptId,
          modifyUserId: this.authInfo.getUserId()
        }
        this.srvConcept.deleteConcept(model).subscribe({
          next: (res:any) => {
            if(res.errors.length > 0){this.toastr.error(res.errors[0]); return}
            if(res.warnings.length > 0){this.toastr.warning(res.errors[0]); return}
            this.toastr.success('Registro inactivado.')
            this.getAllConcept()
          }, error: (error: any) => {
            this.toastr.error('Ha ocurrido un error inesperado')
          }
        })
      }
    })
  }
  activateConcept(item){
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      title: '¿Seguro que desea activar este registro?',
    }).then(res => {
      if(res.isConfirmed){
        const model = {
          newsConceptId: item.newsConceptId,
          modifyUserId: this.authInfo.getUserId()
        }
        this.srvConcept.activateConcept(model).subscribe({
          next: (res:any) => {
            if(res.errors.length > 0){this.toastr.error(res.errors[0]); return}
            if(res.warnings.length > 0){this.toastr.warning(res.errors[0]); return}
            this.toastr.success('Registro activado.')
            this.getAllConcept()
          }, error: (error: any) => {
            this.toastr.error('Ha ocurrido un error inesperado')
          }
        })
      }
    })
  }


  filterSearch(event: FilterEvent) {
    this.employeesPagedResut.page = 1
    this.searchParameter = event
    if (event.propkeyId != 100) {
      this.getAllConcept();
    } else {
      this.OpenAdvancedFilters();
    }
  }
  exportToPdf() {
    const reportCode: ReportCode = ReportCode.NewsConceptId;
    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value ?? ''
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const status: number = this.selectedItems?.length === 2 || this.selectedItems?.length == 0 ? -1 : (this.selectedItems[0]?.item_id === 1 ? 1 : 0);
    const branchId: number = this.company?.companyId ?? 0
    const advancedFilter = this.advancedFilter ?? ''
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${branchId}&Status=${status}&PropkeyId=${propKeyId}&SearchParameter=${text}&Page=${page}&PageSize=${pageSize}&AdvancedFilter=${advancedFilter}`

    let parameters = {
      url: reportUrl,
      title: 'Lista de Conceptos de Nóminas',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }


  exportToExcel() {
    let data = []
    if(this.filteredList.enable){
      data = this.filteredList.records
    }else{
      data = this.concepts
    }
    data.forEach(element => {
      element.statusText = element.status ? 'Activo' : 'Inactivo'
    });
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Código",
      "Concepto",
      "Orden de ejecución",
      "Tipo de Concepto",
      "Tipo de Novedad",
      "Estatus",
    ], [
      "newsConceptId",
      "code",
      "concept",
      "executionOrder",
      "conceptType",
      "newsType",
      "statusText",
    ]]
    this._exlService.exportToExcelSpecificColumns(data, excelHeaders, 'Lista de Conceptos de Nómina', true);
  }
  getFilteredList(event) {
    this.filteredList = event;
    this.paginator.selectedPage = 1;
    if (event.propkey == "advancedFilter") {
      this.filteredList.records = this.filterTable(event.records)
    }else {
      this.OpenAdvancedFilters();
    }
  }

  filter() {
    document.getElementById('searchBarBtn').click()
    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.concepts = this.filterTable(this.conceptsOriginal)
    }
  }

  filterTable(list: any[]) {
    let sl = this.selectedItems;
    if (sl?.find(x => x.item_id == 1) && !sl?.find(x => x.item_id == 2)) {
      list = list.filter(x => x.status == true);
    }
    if (sl?.find(x => x.item_id == 2) && !sl?.find(x => x.item_id == 1)) {
      list = list.filter(x => x.status == false);
    }
    return list;
  }

  openBulkModal() {
    this.dialog.open(BulkLoadPayrollConceptComponent, {
      width: '70%'
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.Applied) {
          this.getAllConcept();
        }
      });
  }
  onBtnPrintClick(){
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }
  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  OpenAdvancedFilters(){
    this.dialog.open(AdvancedFilterByPayrollConceptComponent,{
      width:'55%',
      data: {
        activedfilters: this.tempAdvanceSearch
      }
    }).afterClosed().subscribe(resp=>{
      if (!resp) return

      this.tempAdvanceSearch = resp?.tempForm
      this.advancedFilter = resp?.AdvancedFilter
      this.getAllConcept();



    })
  }
}
