import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { ICompany } from 'src/app/shared/models/company';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-collective-actions',
  templateUrl: './collective-actions.component.html',
  styleUrls: ['./collective-actions.component.css']
})
export class CollectiveActionsComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  useCollectiveVacations: boolean = false;

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'COLLECTIVE-ACTIONS',
    collectivevacations : { key: 'collective-vacations', value: false },
    salaryincrease: { key: 'salary-increase', value: false },
    changeSupervisor: { key: 'change-supervisor', value: false },
    separations: { key: 'Separations', value: false }
  }

  constructor(private authInfo: AuthInfoService,
    private accountService: AccountService,
    private _companiesService: CompaniesService) { }


  ngOnInit(): void {

    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.privilege = this.authInfo.setPrivileges(this.privilege)

        this.subscription = this.accountService.getAccountSubject().subscribe({
          next: res => {
            this.useCollectiveVacations = res?.useCollectiveVacations;
          }
        });
      }
    });

  }

  displayMessage(){
    Swal.fire({
      text: "Estimado usuario, esta opción aún no está disponible.",
      icon: 'warning'
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
