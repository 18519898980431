<div class="card audit-approval border-0 mt-5">
    <div class="card-body p-0">
        <div class="row justify-content-between">
            <div class="table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-left" *ngFor="let item of headers">{{item}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td class="text-left" *ngFor="let item of body">
                              {{item}}
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div>
                    <el-caption #paginator [originalRecordsLength]="[].length"
                        [listedRecordsLength]="[].length"
                        [registerQuantity]="[].length" [records]="[]"
                        (onChage)="getPaginatedRecords($event)"></el-caption>
                </div>
            </div>
        </div>
    </div>
</div>