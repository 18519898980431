import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { VacanciesService } from 'src/app/services/vacancies.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.css']
})
export class BenefitsComponent implements OnInit {

  
  dropdownSettings = {
    singleSelection: false,
    idField: 'ocode',
    textField: 'stringData',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  benefits: any[] = []
  benefitsForm = new FormGroup({
    benefit: new FormControl('', Validators.required),
  })

  config = {
    displayKey: 'description',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder:'Buscar'
  };


  constructor(private pService: PersonalRequsitionService, private _toastService: ToastService, @Inject(MAT_DIALOG_DATA) public data: any,
    private vacanciesService: VacanciesService, public dialogRef: MatDialogRef<BenefitsComponent>) {
      this.dialogRef.disableClose = true;
     }

  ngOnInit(): void {
    this.getBenefits();
  }
  getBenefits() {
    this.vacanciesService.getVacantsTypeBenefit().subscribe(res=>{
      this.benefits = res.dataList
    })
  }

  Create() {
    const row = { Applied: false, benefits:this.benefitsForm.value.benefit }
    this.dialogRef.close(row);
  }

  Cancel(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

}
