export class Incidents {
    criticalIncidentId?: number;
    employeeId: number;
    date: string;
    typeIncidentId: number;
    degreeIncidentId: number;
    evidence: string;
    description: string;
    employeeConduct: string;
    recommendedAction: string;
    AnotherEmployee : string;
    companyId: number;
    status: boolean

    constructor() {
        this.date = ''
        this.description = ''
        this.employeeConduct = ''
        this.recommendedAction = ''
        this.AnotherEmployee  = ''
        this.companyId = 1
        this.status = true
    }
}