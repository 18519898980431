

<ng-template [ngIf]="selectedEmployee && personInfo">
    <fieldset [disabled]="true">
        <form [formGroup]="form">
            <div class="row">
                <div class="form-group col-lg-4">
                    <label>No. Servidores Públicos</label>
                    <input formControlName="employNumber" type="text" class="form-control form-control-lg" />
                </div>
                <div class="form-group col-lg-4">
                    <label>Cédula</label>
                    <input 
                        mask="000-0000000-0"
                        formControlName="personalIdentification" 
                        type="text" 
                        class="form-control form-control-lg" />
                </div>
                <div class="form-group col-lg-4">
                    <label>No. NSS</label>
                    <input formControlName="nss" type="text" class="form-control form-control-lg" />
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-4">
                    <label>Nombres <span class="text-danger">*</span></label>
                    <input formControlName="firstName" type="text" class="form-control form-control-lg" />
                </div>
                <div class="form-group col-lg-4">
                    <label>Apellidos <span class="text-danger">*</span></label>
                    <input formControlName="lastName" type="text" class="form-control form-control-lg" />
                </div>
                <div class="form-group col-lg-4">
                    <label>Fecha Nacimiento <span class="text-danger">*</span></label>
                    <input formControlName="birthday" type="date" class="form-control form-control-lg" />
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-4">
                    <label>Género <span class="text-danger">*</span></label>
                    <input formControlName="gender" type="text" class="form-control form-control-lg" />
                    <!-- <ngx-select-dropdown class=" flex-1"></ngx-select-dropdown> -->
                </div>
                <div class="form-group col-lg-4">
                    <label>Teléfono <span class="text-danger">*</span></label>
                    <input formControlName="phone" type="text" class="form-control form-control-lg" />
                </div>
                <div class="form-group col-lg-4">
                    <label>Correo Electrónico </label>
                    <input formControlName="email" type="text" class="form-control form-control-lg" />
                </div>
            </div>
            <!-- <div class="row">
                <div class="form-group col-lg-8">
                    <label>Dirección <span class="text-danger">*</span></label>
                    <input formControlName="address" type="text" class="form-control form-control-lg" />
                </div>
                <div class="form-group col-lg-4">
                    <label>Estatus <span class="text-danger">*</span></label>
                    <input 
                     
                        [value]="form.get('status').value? 'Activo': 'Inactivo'" 
                        type="text" 
                        class="form-control form-control-lg" />
            
                    </div>
                </div> -->
                <!-- <ngx-select-dropdown class=" flex-1"></ngx-select-dropdown> -->
        </form>
    </fieldset>
    <div class="col-md-12 gutter-t">
        <div class=" px-5">
            <div class="form-group d-flex justify-content-between align-items-center p-3">
                <button [routerLink]="['/employees']"  class="btn btn-pill font-weight-bolder btn-shadow btn-warning mr-3 ">
                    <i class="flaticon2-left-arrow-1"></i>
                    <span>Volver</span>
                </button> 
                <!-- <button (click)="submitForm()" class="btn btn-pill font-weight-bolder btn-shadow btn-success ">
                    <i class="flaticon-disco-flexible"></i>
                    <span>Guardar</span>
                </button>                    -->
            </div>
        </div>
    </div>
</ng-template>