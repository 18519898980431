<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
    <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
  </button>
  <h2 class="font-size-h2 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Dashboard de Acciones de Personal</h2>
</div>
<div class="modal-body" style="max-height: 80vh; overflow-y: auto;">

  <div class="d-flex justify-content-between align-items-end mb-3">
    <div class="text-left col-md-8 col-lg-9">
      <h3>Filtrado</h3>
      <div class="text-left d-flex flex-wrap">
        <h4 class="d-flex font-weight-bolder text-primary  me-4">Desde: <span
            class="text-dark font-weight-lighter ml-3">{{filterParams.date1 |date:'dd/MM/yyyy' ||''}}</span></h4>
        <h4 class="d-flex font-weight-bolder text-primary">Hasta: <span
            class="text-dark font-weight-lighter ml-3">{{filterParams.date2|date:'dd/MM/yyyy'||''}}</span></h4>
      </div>
    </div>
    <div class="d-flex flex-nowrap justify-content-between col-12 col-md-3 col-lg-3">
      <button class="btn btn-primary me-2 w-50" (click)="filterByDate()">
        <i class="fas fa-filter" data-toggle="tooltip" data-title="Filtrar"></i>
      </button>
      <button class="btn btn-light-warning me-2 w-50" [disabled]="!filtered" (click)="clearFilter()">
        <i class="fas fa-redo" data-toggle="tooltip" data-title="Reiniciar filtros"></i>
      </button>
    </div>
  </div>

  <div class="card bg-white p-5 gutter-b">
    <div class="row">
      <div class="col-lg-12">
        <div class="d-flex align-items-center bg-light-warning rounded p-5">
          <span class="svg-icon svg-icon-warning svg-icon-4x mr-5">
            <span class="svg-icon svg-icon-primary svg-icon-2x">
              <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Text/Bullet-list.svg--><svg
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z"
                    fill="#000000" />
                  <path
                    d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z"
                    fill="#000000" opacity="0.3" />
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
          </span>
          <div class="d-flex flex-column flex-grow-1 mr-2">
            <h3 class="font-weight-bold text-dark-75">Total</h3>
          </div>
          <span class="font-weight-bolder text-warning py-1 font-size-h2">
            {{this.total}}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">

    <div class="col-md-6">
      <div class="card card-custom bg-white">
        <div class="card-header p-3">
          <h4 class="card-title">Vacaciones por Mes</h4>
        </div>
        <div class="card-body p-3">
          <app-bar-chart-race [year]="''"  [valueField]="'quantity'" [data]="vacanciesByMonths" [id]="'chartVacacionesMes'"></app-bar-chart-race>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card card-custom bg-white">
        <div class="card-header p-3">
          <h4 class="card-title">Licencias por Mes</h4>
        </div>
        <div class="card-body p-3">
          <app-bar-chart-race [year]="''" [valueField]="'quantity'" [data]="licencesByMonths" [id]="'chartLicenciasMes'"></app-bar-chart-race>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="card card-custom bg-white">
        <div class="card-header p-3">
          <h4 class="card-title">Permisos por Mes</h4>
        </div>
        <div class="card-body p-3">
          <app-bar-chart-race [year]="''" [valueField]="'quantity'" [data]="permissionsByMonths" [id]="'chartPermisosMes'"></app-bar-chart-race>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card card-custom bg-white">
        <div class="card-header p-3">
          <h4 class="card-title">Cambios por Tipo</h4>
        </div>
        <div class="card-body p-3">
          <app-bar-chart-race [valueField]="'quantity'" [data]="changesByType" [id]="'chartCambiosTipo'">
          </app-bar-chart-race>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    
  <div class="col-lg-6 gutter-b">
    <div class="card card-custom bg-white">
        <div class="card-header p-2 mt-2 d-flex justify-content-between">
            <h3 class="ms-3 font-weight-bold text-dark-75 py-3">Acciones por Unidad Organizativa</h3>
            <ngx-select-dropdown [multiple]='true' (change)="changeEmployee_Department()" [options]="departments" [(ngModel)]="dropdownEmployeeDepartment" [config]="dropdownConfig('departmentName')"
             class="col-12 col-xxl-4"></ngx-select-dropdown> 
        </div>
        <div class="card-body p-3">
            <app-chart-dnut [id]="'chart345'" [data]="employeeByDepartments"></app-chart-dnut>
        </div>
    </div>
  </div>
  
  <div class="col-lg-6 gutter-b">
    <div class="card card-custom bg-white">
      <div class="card-header p-2 mt-2  ms-3">
          <h3 class="font-weight-bold text-dark-75 py-3">Acciones por Tipo</h3>
      </div>
      <div class="card-body p-3">
          <app-bar-chart-race [year]="''" [id]="'chart-bar1asdasd'" [data]="personalActionByType1"></app-bar-chart-race>
      </div>
    </div>
  </div>
  
</div>
</div>


<div *ngIf="false" class="modal-footer">
  <button type="button" class="btn btn-danger" data-dismiss="modal">
    <i class="flaticon-cancel"></i>
    Close
  </button>
  <button type="button" class="btn btn-success"> <i class="flaticon-upload"></i> Guardar</button>
</div>