
import { AfterViewInit, Component, OnInit } from '@angular/core';
declare var $: any; // ADD THIS

@Component({
  selector: 'view-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [` 
  @media print{
    .forward-btn{
        display: none;
    }
  }
  `]
})
export class DashboardComponent implements OnInit, AfterViewInit {
  // user: User;
  menu: object[];
  constructor() { }

  ngAfterViewInit(): void { }
  ngOnInit(): void { }

}
