<div class="card card-custom dtl_font dtl_font_primary">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon">
                <i class="flaticon-riesgo-1 icon-3x text-danger"></i>
            </span>
            <h1 class="d-flex flex-column">Incidente</h1>
        </div>
        <div class="card-toolbar"></div>
    </div>
    <div class="card-body">
        <form [formGroup]='incidentsForm'>
            <div *ngIf="!this.incidentDTO.criticalIncidentId" class="row">
                <div class="col-lg-3">
                    <div class="form-group mr-3">
                        <label>Tipo de búsqueda<span class="text-danger">*</span></label>
                        <select class="form-control form-control-solid form-control-lg" formControlName="typeSearch">
                            <option value="">Seleccionar</option>
                            <option *ngFor="let item of FilterOptions" [value]="item.ocode">{{item.stringData}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-group">
                        <label>Criterio de búsqueda<span class="text-danger">*</span></label>
                        <div class="input-group">
                            <input type="text" class="form-control form-control-solid form-control-lg"
                                formControlName="filter" placeholder="Escriba aquí su búsqueda">
                            <button (click)="ApplySearch()" class="input-group-text bg-primary" data-toggle="tooltip"
                                title="Buscar">
                                <i class="flaticon2-search-1 icon-2x text-white"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Nombre Servidor Público</label>
                        <div class="input-icon">
                            <input class="form-control form-control-solid form-control-lg" type="text" readonly
                                formControlName='employeeName' />

                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="form-group">
                        <label>Tipo de Servidor Público</label>
                        <input type="text" class="form-control form-control-solid form-control-lg" readonly
                            formControlName='employeeType'>
                    </div>
                </div>

            </div>
            <div *ngIf="!this.incidentDTO.criticalIncidentId" class="row gutter-b">
                <div class="col-lg-3">
                    <div class="form-group">
                        <label>Fecha de Ingreso</label>
                        <input type="text" class="form-control form-control-solid form-control-lg" readonly
                            formControlName='admissionDate'>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-group">
                        <label>Unidad Organizativa</label>
                        <input type="text" class="form-control form-control-solid form-control-lg" readonly
                            formControlName='department'>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-group">
                        <label>Posición</label>
                        <input type="text" class="form-control form-control-solid form-control-lg" readonly
                            formControlName='position'>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-group">
                        <label>Supervisor Inmediato</label>
                        <input type="text" class="form-control form-control-solid form-control-lg" readonly>
                    </div>
                </div>
            </div>
            <div class="row justify-content-start d-flex">
                <h3 class="text-white p-3 bg-primary m-0 max-w-300px text-center">Detalles de la Incistencia</h3>
            </div>
            <div class="row">
                <div class="card border-primary rounded-0">
                    <div class="card-body">
                        <div class="row gutter-b">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label>Fecha del Incidente <span class="text-danger">*</span></label>
                                    <input type="date" class="form-control form-control-solid form-control-lg"
                                        formControlName='incidentDate'>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label>Tipo de Incidente</label>
                                    <ngx-select-dropdown [options]='incidentTypes' [config]='config' class=" flex-1"
                                        formControlName='inicidentType'>
                                    </ngx-select-dropdown>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label>Grado del Incidente</label>
                                    <ngx-select-dropdown [options]='incidentGrades' [config]='config'
                                        formControlName='inicidentDegree' class=" flex-1">
                                    </ngx-select-dropdown>
                                </div>
                            </div>

                        </div>
                        <div class="row gutter-b">
                            <div class="col-lg-4">
                                <div class="form-group gutter-b">
                                    <label>Descripción del Incidente <span class="text-danger">*</span></label>
                                    <textarea name="" formControlName='inicidentDescription'
                                        class="form-control min-h-150px"></textarea>
                                </div>
                                <div class="form-group gutter-b">
                                    <label>Otros Servidores Públicos Involucrados</label>
                                    <textarea name="" class="form-control min-h-150px"
                                        formControlName='anotherEmployee'></textarea>
                                </div>

                            </div>
                            <div class="col-lg-5">
                                <div class="form-group gutter-b">
                                    <label>Descripción de la Conducta del Servidor Público <span
                                            class="text-danger">*</span></label>
                                    <textarea name="" formControlName='employeeConduct'
                                        class="form-control min-h-150px"></textarea>
                                </div>
                                <div class="form-group gutter-b">
                                    <label>Acción Recomendada a la Comisión de RR.HH. y/o Comisión de Ética</label>
                                    <textarea name="" formControlName='recommendedAction'
                                        class="form-control min-h-150px"></textarea>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Evidencia</label>
                                    <div class="card align-items-center">
                                        <div class="card-body">
                                            <div
                                                [ngClass]="{'showUpload': file.document=='', 'NotShowUpload' : file.document!=''}">
                                                <div class="evidence"></div>
                                            </div>
                                            <div *ngIf="file.document !=''">
                                                <div class="symbol symbol-150 d250 my-4 max-w-250px">
                                                    <img alt="Pic" src={{file.document}} />
                                                </div>
                                                <div class="btn-group center" role="group">
                                                    <button type="button" class="btn btn-primary btn-icon"
                                                        data-toggle="modal" data-target="#document">
                                                        <i class="flaticon-ver"></i>
                                                    </button>
                                                    <button [disabled]='incidentDTO.criticalIncidentId' type="button" class="btn btn-danger btn-icon"
                                                        (click)="deleteGuid()">
                                                        <i class="flaticon-delete-1"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group d-flex justify-content-end align-items-center ">
                                <button routerLink="/incidents-list"
                                    class="btn btn-pill font-weight-bolder btn-shadow btn-warning mr-3">
                                    <i class="flaticon2-left-arrow-1"></i>
                                    <span> Volver </span>
                                </button>
                                <button [disabled]='incidentsForm.invalid || incidentDTO.criticalIncidentId '
                                    (click)='cleanForm()'
                                    class="btn btn-pill font-weight-bolder btn-shadow btn-secondary mr-3">
                                    <i class="flaticon2-refresh-button"></i>
                                    <span> Limpiar </span>
                                </button>
                                <button [disabled]='incidentsForm.invalid || isView == true' (click)='create()'
                                    class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
                                    <i class="flaticon-disco-flexible"></i>
                                    <span> Guardar </span>
                                </button>
                                <!-- <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary">
                                    <i class="flaticon2-plus"></i>
                                    <span> Agregar </span>
                                </button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<el-modal target="document" title="">
    <iframe [src]='transform(file.document)' width="100%" height="600px"></iframe>
</el-modal>