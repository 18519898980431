<div class="card card-custom" data-card="true" id="kt_card_3">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-agregar-archivo-1 icon-3x text-danger mr-2"></i>
                <h1 class="text-primary">Generar Nómina</h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Dashboard">
            <!--button data-toggle="modal"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto" (click)="openDashBoard()">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </button-->
        </div>
    </div>
    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-3 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Período <span class="text-danger text-monospace legend">*</span>
                        </label>
                        <ngx-select-dropdown [disabled]="disableFilters" (change)="onPeriodChange()"
                            [options]='periodOptions' [config]='config2' [(ngModel)]="selectedPeriod">
                        </ngx-select-dropdown>
                    </div>
                </div>
                <div class="col-md-3 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Área Presupuestaria <span class="text-danger text-monospace legend">*</span>
                        </label>
                        <ngx-select-dropdown [disabled]="disableFilters" [options]='budgetAreaOptions'
                            (change)="changeBudgetArea()" [config]='config2' [(ngModel)]="selectedBudgetArea">
                        </ngx-select-dropdown>
                    </div>
                </div>

                <div class="col-md-3 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Tipo de Nómina <span class="text-danger text-monospace legend">*</span>
                        </label>
                        <ngx-select-dropdown [disabled]="disableFilters" [options]='payrollTypeOptions'
                            [config]='config' (change)="getConcepts()" [(ngModel)]="selectedPayrollType">
                        </ngx-select-dropdown>
                    </div>
                </div>

                <div class="col-md-3 mr-2 px-0 pt-1" *ngIf="selectedPayrollType?.ocode === 4">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Período Anterior
                        </label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [disabled]="disableFilters" [options]="relatedPeriods"
                                (change)="changePayrollPeriodRelated()" [(ngModel)]="selectedPayrollPeriodRelated"
                                [config]="dropdownConfig('description')" class="w-100"></ngx-select-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mr-2 px-0 pt-1" *ngIf="selectedPayrollType?.ocode === 4">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Version
                        </label>
                        <div class="dropdown-alt input-group">
                            <ngx-select-dropdown [disabled]="disableFilters" [options]="versions"
                                 [(ngModel)]="selectedVersion"
                                [config]="dropdownConfig('versionText')" (change)="changeVersion()" class="w-100"></ngx-select-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mr-2 px-0 pt-1" *ngIf="selectedPayrollType?.ocode === 4">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Concepto
                        </label>
                        <div class="dropdown-alt input-group">
                            <ng-multiselect-dropdown [disabled]="disableFilters" [placeholder]="'Seleccionar'"
                                [(ngModel)]="conceptsSelected" [settings]="dropdownSettings"
                                [data]="concepts"></ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>

                <button (click)="loadDetails()"
                    *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.searchAction)"
                    class="btn btn-primary btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                    <i class="flaticon2-search-1 icon-2x"></i> Buscar
                </button>
                <button data-toggle="modal" class="btn btn-primary btn-pill font-weight-bolder mt-2 mx-2 col-md-auto"
                    (click)="clear()">
                    <i class="flaticon-delete-1 icon-2x text-white"></i>
                    Limpiar
                </button>

                <button *ngIf="enableBtn && authInfo.canDoAction(privilege.nameKeyOption, privilege.applyAction)"
                    (click)="setNews()" class="btn btn-success btn-pill font-weight-bolder mt-2 mx-2 col-md-auto">
                    <i class="flaticon-etiqueta icon-2x"></i> Generar Nómina
                </button>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.exportAction)"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                            <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                            Visualizar Reporte
                        </a>
                    </div>
                </div>

                <div class="col text-nowrap">
                    <el-search-bar #searchBar [records]="records" (onKeyChange)="onKeyChange($event)"
                        [keyValue]="filterText" [itemForSearching]="searchBarDisplayNames"
                        (onChange)="getFilteredList($event)" [itemForSearching]="searchBarDisplayNames"></el-search-bar>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body" kt-hidden-height="282">
        <div class="table-responsive table-responsive-sigei">
            <table class="table table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-left">

                        </th>
                        <th class="text-center">Secuencia</th>
                        <th class="text-left">Servidor Público</th>
                        <th class="text-left">Documento de Identidad</th>
                        <th class="text-left">Categoría del Servidor Público</th>
                        <th class="text-left">Cargo</th>
                        <th class="text-left">Tipo</th>
                        <th class="text-left">Concepto</th>
                        <th class="text-right">Monto</th>
                        <th class="text-left">Fecha Inicio</th>
                        <th class="text-left">Fecha Final</th>
                        <th class="text-left">Condición de la Novedad</th>
                        <th class="text-left">Estatus</th>
                        <th class="text-left" style="width: 100px;">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let payrollNew of recordsPaginated">
                        <td class="text-center"><input *ngIf="payrollNew.condition === 0"
                                (change)="selectItem(payrollNew)" [(ngModel)]="payrollNew.isSelected" type="checkbox"
                                name="flyerItem" id="" class="check-size"></td>
                        <td class="text-center">{{payrollNew.payrollNewsId}}</td>
                        <td class="text-left">
                            {{payrollNew.name}}
                        </td>
                        <td class="text-left">{{payrollNew.personalIdentification | mask:'000-0000000-0'}}</td>
                        <td class="text-left">{{payrollNew.category}}</td>
                        <td class="text-left">{{payrollNew.positionName}}</td>
                        <td class="text-left">{{payrollNew.newsType}}</td>
                        <td class="text-left">{{payrollNew.newsConcept}}</td>
                        <td class="text-right">{{payrollNew.amount | currency}}</td>
                        <td class="text-left">{{payrollNew.startDate| date:"dd/MM/yyyy"}}</td>
                        <td class="text-left">{{payrollNew.endDate | date:"dd/MM/yyyy"}}</td>
                        <td class="text-left">
                            <span *ngIf="payrollNew.condition === 1"
                                class="font-weight-bold text-success">Aprobada</span>
                            <span *ngIf="payrollNew.condition === 0" class="font-weight-bold text-danger">No
                                Aprobada</span>
                        </td>
                        <td>
                            <span *ngIf="payrollNew.status" class="font-weight-bold text-success">Activa</span>
                            <span *ngIf="!payrollNew.status" class="font-weight-bold text-danger">Inactiva</span>
                        </td>
                        <td class=" px-5">
                            <div class="d-flex justify-content-center px-5" style="width: max-content">
                                <div class="justify-content-start  px-5">
                                    <button class="btn btn-icon btn-light-info btn-sm m-1"
                                        *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.viewAction)"
                                        (click)="openModalAddNew(payrollNew, true, false)">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="">
                <el-caption #paginator [originalRecordsLength]="records.length"
                    [listedRecordsLength]="recordsPaginated.length"
                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :records.length"
                    [records]="filteredList.enable ? filteredList.records : records"
                    (onChage)="getPaginatedRecords($event)"></el-caption>

            </div>
        </div>
    </div>
</div>

<el-modal size="xl" title="Detalles de las Novedades" target="view">
    <div class="row">
        <div class="col-12">
            <!-- <h3>nombre del Servidor Público / posicion del departamento</h3> -->
            <!-- poner la tarjeta gris que tiene los datos personales de la persona  -->
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th class="text-left">Tipo</th>
                    <th class="text-left">Concepto</th>
                    <th class="text-left">Monto</th>
                    <th class="text-left">Fecha Inicio</th>
                    <th class="text-left">Fecha Final</th>
                    <th class="text-left">Monto Total</th>
                    <th class="text-left">Saldo</th>
                    <th class="text-left">Valor Predeterminado</th>
                    <th class="text-left">Valor Manual</th>
                    <th class="text-left">Estatus</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-left">
                        -
                    </td>
                    <td class="text-left">-</td>
                    <td class="text-left">-</td>
                    <td class="text-left">-</td>
                    <td class="text-left">-</td>
                    <td class="text-left">-</td>
                    <td class="text-left">-</td>
                    <td class="text-left">-</td>
                    <td class="text-left">-</td>
                    <td>
                        <span class="font-weight-bold text-success">Activa</span>
                    </td>
                </tr>

                <tr>
                    <td class="text-left">
                        -
                    </td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left">-</td>
                    <td class="text-left">-</td>
                    <td class="text-left"></td>
                    <td class="text-left">-</td>
                    <td class="text-left">-</td>
                    <td class="text-left">
                        <span class="font-weight-bold text-danger">Inactiva</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button"
            class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2"
            data-dismiss="modal">
            <i class="flaticon2-cross"></i>
            Cerrar
        </button>
        <button data-dismiss="modal" type="button"
            class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success">
            <i class="flaticon2-plus"></i>
            Guardar
        </button>
    </div>
</el-modal>

<export-data-to-pdf [title]="'Generar Nómina'" id="dataToPrint">
    <ng-container thead>
        <th class="text-left">Servidor Público</th>
        <th class="text-left">Posición</th>
        <th class="text-left">Tipo</th>
        <th class="text-left">Concepto</th>
        <th class="text-left">Monto</th>
        <th class="text-left">Fecha Inicio</th>
        <th class="text-left">Fecha Final</th>
        <th class="text-left">Condición de la Novedad</th>
        <th class="text-center">Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let payrollNew of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.records"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td class="text-left">
                {{payrollNew.name}}
            </td>
            <td class="text-left">{{payrollNew.positionName}}</td>
            <td class="text-left">{{payrollNew.newsType}}</td>
            <td class="text-left">{{payrollNew.newsConcept}}</td>
            <td class="text-left">{{payrollNew.amount | currency: 'RD$'}}</td>
            <td class="text-left">{{payrollNew.startDate| date:"dd/MM/yyyy"}}</td>
            <td class="text-left">{{payrollNew.endDate | date:"dd/MM/yyyy"}}</td>
            <td class="text-left">
                <span *ngIf="payrollNew.condition === 1" class="font-weight-bold text-success">Aprobada</span>
                <span *ngIf="payrollNew.condition === 0" class="font-weight-bold text-danger">No
                    Aprobada</span>
            </td>
            <td>
                <span *ngIf="payrollNew.status" class="font-weight-bold text-success">Activa</span>
                <span *ngIf="!payrollNew.status" class="font-weight-bold text-danger">Inactiva</span>
            </td>
        </tr>
    </ng-container>
</export-data-to-pdf>
