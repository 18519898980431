import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IReturnForRevision } from '../models/map';
import { SendMapHeader } from '../pages/request-no-objection-map/models/send-map-header';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { AuthInfoService } from './auth-info.service';
import { ISendMapResponse } from '../pages/request-no-objection-map/models/send-map-model';
@Injectable({
  providedIn: 'root'
})
export class RequestNoObjectionService {

  readonly apiCore = environment.api_core_url;
  readonly api = environment.api_url;
  readonly apiMap = environment.api_map;

  private readonly api_lobby = environment.api_core_sigei;

  constructor(
    private http: HttpClient,
    private authInfo: AuthInfoService
  ) { }


  getDepartments(){
    return this.http.get(this.apiCore + `/CompanyStructure?CompanyId=${this.authInfo.getCompanyId()}&IdDepartment=0`);
  }

  getPostulations(type:number ,departmentName?: string){
    return this.http.get(`${this.api}/PersonsPostulationVacants/GetPostulation?Type=${type}&Person=${departmentName}`)
  }

  getNoObjectionMap(idSendMap: number, companyId: number){
    return this.http.get(`${this.api}/sendmap?type=5&idSendMap=${idSendMap}&CompanyId=${companyId}`);
  }

  returnRevisionToMap(model: IReturnForRevision){
    return this.http.post<ResponseModel<null>>(`${this.api}/SendMap/SendReturnedForEdited`, model);
  }

  saveSendMapHeader(sendMapHeader: SendMapHeader){
    return this.http.post<ResponseModel<any>>(`${this.api}/SendMapHeader`, sendMapHeader);
  }

  saveSendMap(sendMap){
    return this.http.post<ResponseModel<ISendMapResponse>>(`${this.api}/SendMap`, sendMap);
  }

  UpdatePublicAccessId(IdPpv: number, PublicAccessId: string){
    return this.http.delete<ResponseModel<any>>(`${this.api}/PersonsPostulationVacants/UpdatePublicAccessId?IdPpv=${IdPpv}&PublicAccessId=${PublicAccessId}`);
  }
 
  validateMapHeader(number){
    /* sirve para validar si este numero ya existe */
    return this.http.get<ResponseModel<any>>(this.api + `/SendMapHeader?type=2&IdSedMapHeader=${number}&CompanyId=${this.authInfo.getCompanyId()}&Status=true`);
  }


  getPostulationsWithoutDepartment(type){
    return this.http.get<ResponseModel<any>>(`${this.api}/PersonsPostulationVacants/GetPostulation?Type=${type}&IdCompany=${this.authInfo.getCompanyId()}&Status=true`)
  }


  getPostulationsNoObjectionPaginated(options) {
    let params = new HttpParams();
    params = params.append('CompanyId', this.authInfo.getCompanyId().toString())
    params = params.append('PropkeyId', options.propKeyId.toString())
    params = params.append('SearchParameter', options.searchParameter)
    params = params.append('Status', options.status)
    params = params.append('Conditions', options.conditions)
    params = params.append('Page', options.page)
    params = params.append('PageSize', options.pageSize)
    params = params.append('Type', options.type.toString())
    return this.http.get<any>(`${this.api}/PersonsPostulationVacants/GetPaginated`, {params})
  }

  getStatistics(
    type:number,
    dateTo: string,
    dateFrom: string,
    status: boolean = true
  ){

    let today = new Date();
    return this.http.get(`${this.api}/PersonsPostulationVacants/Dashboard?DateTo=${dateTo}&DateFrom=${dateFrom}&Type=${type}&CompanyId=${this.authInfo.getCompanyId()}&Status=${status}`)
  }

  getDashboardNoObjection(
    dateFrom: string,
    dateTo: string,
    CompanyId: number = this.authInfo.getCompanyId(),
    status: boolean = true,
    ){
    return this.http.get<any>(`${this.api}/PersonsPostulationVacants/DashboardNoObjection?Status=${status}&CompanyId=${CompanyId}&DateFrom=${dateFrom}&DateTo=${dateTo}`)
  }

  getMAPCompanyDATAByRNC(IdentificationNumber: string){
    return this.http.get(`${this.api_lobby}/Companies?Status=1&Type=4&IdentificationNumber=${IdentificationNumber}`)
  }

  getUsersApprovals(companyId, processId){
    return this.http.get(`${this.api_lobby}/Companies/approvals?CompanyId=${companyId}&ProcessId=${processId}`)
  }

  getCompanyByID(companyCode: number){
    return this.http.get(`${this.api_lobby}/Companies?companyCode=${companyCode}&type=3`);
  }

}