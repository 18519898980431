import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { HttpClientService } from 'src/app/services/http-client/http-client.service';
import { ProofApplicantsService } from 'src/app/services/proof-applicants.service';
import { FilterEvent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { MockupPostulationModel, PostulationsTestsEntities } from '../models/PostulationTest';
import { ProofDashboardComponent } from '../proof-dashboard/proof-dashboard.component';
import { DatePipe } from '@angular/common';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';

declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'app-proof-applicants-list',
  templateUrl: './proof-applicants-list.component.html',
  styleUrls: ['./proof-applicants-list.component.css']
})
export class ProofApplicantsListComponent implements OnInit, AfterViewChecked {

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PROOFVACANT',
    testAction: 'TEST',
    dashboardAction: 'DASHBPrueba',
    viewAction: 'VIEW',
    exportAction: 'EXPORT',
  }

  searchparameterlocalstorage: string = 'tests-list_filter_search';
  dataList: MockupPostulationModel[] = [];
  testList: MockupPostulationModel[] = [];
  testOriginalList: MockupPostulationModel[] = [];
  testsListPagedResut = new PagedResult<any>();
  records: any[] = [];
  datePipe = new DatePipe('en-Us')
  searchParameter: FilterEvent;
  @ViewChild('paginator') paginator: CaptionComponent;
  errorMessage: string = 'Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.';
  recordsOriginal: any[] = [];
  keyword: string;
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  searchList: any[] = [];
  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Secuencia" },
      { propkeyId: 2, displayName: "Cargo" },
      { propkeyId: 3, displayName: "Candidato" },
      { propkeyId: 4, displayName: "Documento de Identidad", default: true },
      { propkeyId: 5, displayName: "Unidad Organizativa" },
      { propkeyId: 8, displayName: "Pruebas Realizadas" },
    ];

  filterText = '';

  selectedItems = [];

  statusOptions = [
    { item_id: 0, item_text: 'En Proceso', isCondition: true, condition: 0, status: true },
    { item_id: 1, item_text: 'Enviadas al MAP', isCondition: true, condition: 1, status: true },
    { item_id: 3, item_text: 'Nombrados', isCondition: true, condition: 3, status: true },
    { item_id: 4, item_text: 'Reservadas para Nombramiento', isCondition: true, condition: 4, status: true },
    { item_id: 2, item_text: 'Aprobadas por el MAP', isCondition: true, condition: 2, status: true },
    { item_id: 5, item_text: 'Rechazadas por el MAP', isCondition: true, condition: 5, status: true },
    { item_id: 10, item_text: 'Nombramiento Anulado', isCondition: true, condition: 10, status: true }
  ];
  canUseOption = false
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };


  constructor(
    private _cdRef: ChangeDetectorRef,
    private _http: HttpClientService,
    private _toastr: ToastrService,
    private _xlsxService: ExportToExcelService,
    private dialog: MatDialog,
    private _proofApplicantsService: ProofApplicantsService,
    public authInfo: AuthInfoService
  ) {

  }

  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem("tests-list_filter"))?.length > 0) {
      this.selectedItems = JSON.parse(localStorage.getItem("tests-list_filter"));
    }
    this.setFilterStorage();

    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.canUseOption = true
        this.getTestList();
      }
    });

    applySort();
  }

  filterSearch(event: FilterEvent) {
    this.testsListPagedResut.page = 1
    this.searchParameter = event
    this.getTestList()
    this.setFilterToLocalStorageSearch(event);
  }

  private setFilterStorage() {
    const localStorageSearch: FilterEvent = JSON.parse(localStorage.getItem(this.searchparameterlocalstorage));
    this.searchParameter = localStorageSearch;
  }

  setFilterToLocalStorageSearch(event: FilterEvent) {
    localStorage.setItem(this.searchparameterlocalstorage, JSON.stringify(event));
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  getTestList() {
    if (!this.canUseOption) {
      return
    }
    const page = this.testsListPagedResut.page
    const pageSize = this.testsListPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const condition = this.selectedItems?.map((condition: any) => condition.item_id)?.join('-')
    this._proofApplicantsService.getPaginatedPostulations(page, pageSize, propKeyId, text, 1, condition).subscribe((data: any) => {
      if (data.succeded) {
        data.singleData.items.forEach(element => {
          switch (element.condition) {
            case 1:
              element.conditionDescription = 'Enviada al MAP'
              break;
            case 2:
              if(element.requisitionTypeId === 5){
                element.conditionDescription = 'Aprobada'
              }else{
                element.conditionDescription = 'Aprobada por el MAP'
              }
              break;
            case 3:
              element.conditionDescription = 'Nombrado'
              break;
            case 4:
              element.conditionDescription = 'Reservado para Nombramiento'
              break;
            case 5:
              element.conditionDescription = 'Rechazado por el MAP'
              break;
            case 6:
              element.conditionDescription = 'Devuelta para Revisión'
              break;
            default:
              break;
          }
        });
        this.testList = data.singleData.items;
        this.testOriginalList = [...data.singleData.items];
        this.testsListPagedResut = data.singleData
      } else {
        this._toastr.error(data.errors[0])
      }
    }, error => {
      this._toastr.error(this.errorMessage)
    })
  }

  search() {
    this.searchList = this.dataList.filter(x => x.personName.toLocaleLowerCase().includes(this.keyword.toLocaleLowerCase()));
    if (this.searchList.length == 0) {
      this._toastr.warning('No hay coincidencias', 'Registro no encontrado');
    }
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.getTestList();
    this.setFilterToLocalStorage();
  }
  onItemDeSelect(item: any) {
    this.getTestList();
    this.setFilterToLocalStorage();
  }
  onItemSelect(item: any) {
    this.getTestList();
    this.setFilterToLocalStorage();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.getTestList();
    this.setFilterToLocalStorage();
  }

  setFilterToLocalStorage() {
    localStorage.setItem('tests-list_filter', JSON.stringify(this.selectedItems));
  }

  filter() {

    if (this.filteredList.records.length > 0 && this.filterText) {
      this.filteredList.records = this.filterTable(this.filteredList.records)
    } else {
      this.dataList = this.filterTable(this.recordsOriginal)
    }
  }

  filterTable(list: any[]) {

    let sl = this.selectedItems;

    if (sl.length > 0) {
      list = list.filter(x => {
        return sl.find(z => z.item_id == x.condition)
      });
    }


    return list;
  }


  exportToExcel() {
    let data = this.testList
    data.forEach((element: any) => {
      element.conditionText = this.getConditionDescription(element?.condition)
      element.dateText = this.datePipe.transform(element.dateApplication, 'dd/MM/yyyy')
    });
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Posición",
      "Candidato",
      "Documento de Identidad",
      "Unidad Organizativa",
      "Fecha de postulación",
      "Pruebas Realizadas",
      "Condición de Candidato",
    ],
    [
      'ppvid',
      'positionName',
      'personName',
      'personalIdentification',
      'departmentName',
      'dateText',
      'test',
      'conditionText'
    ]];

    if (this.testList.length > 0) {
      this._xlsxService.exportToExcelSpecificColumns(data, excelHeaders, 'Lista de Candidatos para Pruebas', true)
    } else {
      this._toastr.error(`No puede exportar debido a que no hay registros.`, `Error al exportar`);
    }
  }

  getConditionDescription(conditionId) {
    let description = 'En Proceso'

    switch (conditionId) {
      case 1:
        description = 'Enviada al MAP'
        break;
      case 2:
        description = 'Aprobada por el MAP'
        break;
      case 3:
        description = 'Nombrado'
        break;
      case 4:
        description = 'Reservado para Nombramiento'
        break;
      case 5:
        description = 'Rechazada por el MAP'
        break;
      case 6:
        description = 'Devuelta para Revisión'
        break;

      default:
        break;
    }

    return description
  }
  getPaginatedRecords(event: PaginationEvent) {
    if (this.testsListPagedResut.page == event.selectedPage && this.testsListPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.testsListPagedResut.page = event.selectedPage;
    this.testsListPagedResut.pageSize = event.registersPerPage;
    this.getTestList()
  }

  openDialog() {
    const dialogRef = this.dialog.open(ProofDashboardComponent, {
      width: '100%'
    });
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  onBtnPrintClick() {
    let printData = document.getElementById('dataToPrint').cloneNode(true);
    document.body.classList.add('mode-print');
    document.body.appendChild(printData);
    window.print();
    document.body.classList.remove('mode-print');
    document.body.removeChild(printData);
  }

  printReport(){
    const reportUrl = environment.reportUrl
    const companyId = this.authInfo.getCompanyId()
    const userId = this.authInfo.getUserId()
    const page = this.testsListPagedResut.page
    const pageSize = this.testsListPagedResut.pageSize
    const text = this.searchParameter?.value
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const condition = this.selectedItems?.map((condition: any) => condition.item_id)?.join('-')
    const url = `${reportUrl}?Reportcode=Exp_ListTest&CompanyId=${companyId}&PropKeyId=${propKeyId}&SearchParameter=${text}&Status=1&Conditions=${condition}&Page=${page}&PageSize=${pageSize}&Type=1`
    let parameters = {
      url: url,
      title: 'Listado de pruebas',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }

}
