<div class="row">
    <div class="col-6">
        <p class="label-item mb-2">Tipo de Licencia: {{license?.typePermissions}} </p>

        <p class="label-item mb-2">Fecha de Solicitud: {{license?.applicationDate|date: "dd/MM/yyyy"}}: </p>
        <p class="label-item mb-2">Fecha de Inicio: {{license?.dateStart|date: "dd/MM/yyyy"}} </p>
        <p class="label-item mb-2">Fecha de Termino: {{license?.dateEnd|date: "dd/MM/yyyy"}} </p>
    </div>
    <div class="col-6 ">
        <p class="label-item mb-2">Motivo de la Licencia: {{license?.reason || "N/A"}}</p>
        <p class="label-item mb-2">Comentarios: {{license?.comment || "N/A"}}</p>
       
    </div>
  
</div>