import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IPositionView, Positions } from 'src/app/models/positions.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PositionService } from 'src/app/services/position.service';
import { parameterControlModel } from 'src/app/shared/models/candidate-registration.model';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import Swal from 'sweetalert2';
import { runInThisContext } from 'vm';
import { Career, CareerDTO } from '../../post-vacancies/models/careers.model';
import { ModalCompetencyDictionarySearchComponent } from '../modal-competency-dictionary-search/modal-competency-dictionary-search.component';
import { PositionCompetency, PositionsDescription } from '../models/positions-description.model';
import { ViewBehaviorsComponent } from './view-behaviors/view-behaviors.component';
import * as moment from 'moment';

export interface ProbationaryPeriod {
  month: number,
  text: string
}


@Component({
  selector: 'app-position-list-description',
  templateUrl: './position-list-description.component.html',
  styleUrls: ['./position-list-description.component.css']
})
export class PositionListDescriptionComponent implements OnInit, AfterViewChecked {
  form: FormGroup;
  formIsInvalid = false
  requisitionsType: ParameterControl[] = [];
  academicLevelList:  ParameterControl[] = [];
  careers: any[] = [];
  probationaryPeriodsOption: ProbationaryPeriod[] = [];

  dictionary: PositionCompetency[] = [];
  dictionaryRecord: PositionCompetency[] = [];
  dictionaryOriginalList: PositionCompetency[] = [];
  experiences: ParameterControl[] = [];

  constructor(
    public dialogRef: MatDialogRef<PositionListDescriptionComponent>,
    private dialog: MatDialog, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: {
      position: IPositionView,
      edit: boolean,
      view: boolean
    },
    private _changeDet: ChangeDetectorRef,
    private _ParamService: ParameterControlService,
    private _toastr: ToastrService,
    private positionService: PositionService,
    private auth: AuthInfoService
  ) {
    this.dialogRef.disableClose = true;
    this.buildForm();
    this.form.patchValue({
      ...this.data?.position
    });
    if(this.data.view){
      this.form.disable();
    }
    
   }

  private buildForm(){
    this.form = this.fb.group({
      positionName: [{value: '', disabled: true}],
      occupationalGroupDescription: [{value: '', disabled: true}],
      probatoryPeriod: [null],
      career: [null],
      academicLevel: [null],
      experience: [null],
      purpose: [''],
      activity: [''],
      otherKnowledge: [''],
      competency: [[]]
    });
  }

  ngOnInit(): void {
    //this.getCareers();
    this.getAcademicLevel();
    this.getPositionCompetency();
    this.getExperiences();
    this.probationaryPeriodsOption = this.probationaryPeriods;
    this.form.get('probatoryPeriod')
    .setValue(this.probationaryPeriodsOption
      ?.find(p => p?.month === this.data?.position?.probatoryPeriod) ?? null);
  }

  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  getExperiences(){
    this._ParamService.getParameters(keyWord.Experience).subscribe(res => {
      if (res?.errors?.length > 0) {
        this._toastr.error(res?.errors[0])
        return;
      }
      this.experiences = res?.dataList?.filter(d => d?.status);
      this.form.get('experience')
      .setValue(this.experiences
        ?.find(a => a?.ocode === this.data?.position?.experienceId) ?? null);
    });
  }

  getAcademicLevel(){
    this._ParamService.getParameters(keyWord.AcademicLevel).subscribe(res => {
      if (res?.errors?.length > 0) {
        this._toastr.error(res?.errors[0])
        return;
      }
      this.academicLevelList = res?.dataList?.filter(d => d.status);
      this.form.get('academicLevel')
      .setValue(this.data?.position?.academicLevel);
    });
  }

  getCareers(){
    this.positionService.getCareers().subscribe({
      next: res => {
        if(res?.errors?.length > 0){
          this._toastr.error(res?.errors[0])
          return;
        }
        this.careers = res?.dataList?.filter(c => c?.status);
        this.form.get('career')
        .setValue(this.careers
          ?.find(a => a?.careerId === this.data?.position?.careerId) ?? null);
      }
    })
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  Cancel(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

  Accept() {
    this.formIsInvalid = this.form?.invalid;
    if(this.formIsInvalid){
      return;
    };
    const positionsDescription: PositionsDescription = {
      positionId: this.data.position?.positionId,
      probatoryPeriod: this.form?.value?.probatoryPeriod?.month,
      careerId: this.form?.value?.career?.careerId,
      academicLevel: this.form?.value?.academicLevel,
      experienceId: this.form?.value?.experience?.ocode,
      purpose: this.form?.value?.purpose,
      activity: this.form?.value?.activity,
      otherKnowledge: this.form?.value?.otherKnowledge,
      userId: this.auth?.getUserId(),
      modifyDate: this.data.position?.positionId ? moment().format() : null,
      competencies: this.getCompetencyPosition?.map(c => {
        return {
          positionCompetencyId: c?.positionCompetencyId ?? 0,
          positionId: this.data?.position?.positionId,
          capabilityId: c.capabilityId,
          companyId: this.data?.position?.companyId
        }
      })
    };
    this.positionService.updatePositionsDescription(positionsDescription).subscribe({
      next: res => {
        if(res?.errors?.length > 0){
          this._toastr.error(res?.errors[0]);
          return;
        }
        this._toastr.success('La descripción del cargo se actualizó correctamente', '');
        this.dialogRef.close({Applied: true});
      }, error: error => {
        this._toastr.error('Ocurrió un error al actulizar la descripción del cargo');
      }
    });
  }

  getPositionCompetency(){
    this.positionService.getPositionCompetency(this.data?.position?.positionId, this.data?.position?.companyId).subscribe({
      next: res => {
        if(res?.errors?.length > 0){
          this._toastr.error(res?.errors[0]);
          return;
        }
        this.form.get('competency').setValue(res?.dataList?.filter(d => d?.status) ?? []);
        this.setDictionaryList();
      }
    })
  }

  get getCompetencyPosition(): PositionCompetency[] {
    return this.form?.value?.competency ?? [];
  }

  openModalAdd() {
    this.dialog.open(ModalCompetencyDictionarySearchComponent, {
      width: '75%',
      data: {
        competency: this.getCompetencyPosition
      },
    }).afterClosed().subscribe({
      next: (result: { selected: boolean, competency: PositionCompetency[]}) => {
        if(!result?.selected) {
          return;
        }
        let competency: PositionCompetency[] = this.form?.value?.competency ?? [];
        this.form.get('competency').setValue([...competency, ...result.competency]);
        this.setDictionaryList();
      }
    });
      
  }

  getPaginatedRecords(event) {
    this.dictionaryRecord = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }


  getError(name: string) {
    if (!this.formIsInvalid) {
      return '';
    }
    const field = this.form.get(name)
    if (field.hasError('required')) {
      return 'Este campo es obligatorio';
    }
    if (field.hasError('min')) {
      return `Este campo sólo admite un minimo de ${field?.errors['min']?.min}`;
    }
    if (field.hasError('maxlength')) {
      return `Este campo sólo admite un máximo de ${field?.errors['maxlength']?.requiredLength} caracteres`;
    }
    return '';
  }

  private get probationaryPeriods(): ProbationaryPeriod[] {
    return this.getArrayRange(13).filter(month => month > 0).map(month => {
      return {
        month: month,
        text: month === 1 ? `${month} Mes` : `${month} Meses`
      }
    }); 
  }

  private getArrayRange(length: number): number[] {
    return  Array.apply(null, Array(length)).map(function (_, i) {return i;});
  }

  private setDictionaryList(){
    let competency: PositionCompetency[] = this.form?.value?.competency ?? [];
    this.dictionary = [...competency];
    this.dictionary = [...competency];
  }

  deleteDictionary(dictionary: PositionCompetency){
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#ff0000',
      title: '¿Seguro que desea eliminar este registro?',
      
    }).then((result) => {
      if(result.isConfirmed){
        if(dictionary?.positionCompetencyId > 0){
          this.deletePositionCompetency(dictionary);
          return;
        }
        const competency = this.getCompetencyPosition?.filter(d => d?.dictionarySequence !== dictionary?.dictionarySequence);
        this.form.get('competency').setValue(competency);
        this.setDictionaryList();
      }
    });
   
  }

  private deletePositionCompetency(dictionary: PositionCompetency){
    this.positionService.deletePositionCompetency(dictionary?.positionCompetencyId, this.auth?.getUserId()).subscribe({
      next: res => {
        if(res?.errors?.length > 0){
          this._toastr.error(res?.errors[0]);
          return;
        }
        this._toastr.success('La competencia se eliminó correctamente', '');
        const competency = this.getCompetencyPosition?.filter(d => d?.dictionarySequence !== dictionary?.dictionarySequence);
        this.form.get('competency').setValue(competency);
        this.setDictionaryList();
      }, error: error => {
        this._toastr.error('Ocurrió un error al eliminar la competencia');
      }
    });
  }

  openViewBehaviorsComponent(dictionary: PositionCompetency){
    this.dialog.open(ViewBehaviorsComponent, {
      data:  {
        dictionary: dictionary
      }
    });
  }

}