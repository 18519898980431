import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-show-subject-modal',
  templateUrl: './show-subject-modal.component.html',
  styleUrls: ['./show-subject-modal.component.css']
})
export class ShowSubjectModalComponent implements OnInit {
  emailForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ShowSubjectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { src: string, title: string },
    private fb: FormBuilder
  ) {
    this.emailForm = this.fb.group({
      title: ['Hoy estamos de fiesta', [Validators.required]],
      body: ['¡En este día de tu cumpleaños, recibe todas las alegrías del Universo!', [Validators.required]]
    });
  }

  ngOnInit(): void {

  }

  get title() {
    return this.emailForm.get('title');
  }

  get body() {
    return this.emailForm.get('body');
  }

  onSubmit() {
    if (!this.emailForm.valid) {
      return
    }

    let data = {
      title: this.emailForm.get('title').value,
      body: this.emailForm.get('body').value
    }

    this.dialogRef.close(data)
  }

  close() {
    this.dialogRef.close(false)
  }

}
