<!-- <div class="modal-header border-0 dv-table-header-1">
    <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
        <div class="card-body">
            <div class="row">
                <div class="form-group col-lg-12 font-weight-bold">
                    <h1 class="text-primary">Información del correo electrónico </h1>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-12">
                    <label for="firstName">Título <span class="text-danger" *ngIf="title.value == ''"> *</span></label>
                    <input id="lastName" type="text" class="form-control form-control-solid form-control-lg"
                        formControlName="title">
                    <div *ngIf="title.invalid && (title.dirty || title.touched)" style="color: red;">
                        <div *ngIf="title.errors.required">El Título es obligatorio.</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-12">
                    <label for="lastName">Cuerpo <span class="text-danger" *ngIf="body.value == ''"> *</span></label>
                    <textarea class="form-control form-control-solid form-control-lg flex-1" style="height: 150px;"
                        formControlName="body"></textarea>

                    <div *ngIf="body.invalid && (body.dirty || body.touched)" style="color: red;">
                        <div *ngIf="body.errors.required">El Cuerpo es obligatorio.</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class=" d-flex justify-content-end">
                    <button type="submit" class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3"
                        id="btnClose" [disabled]="emailForm.invalid">
                        <i class="flaticon-disco-flexible"></i>
                        <span>Enviar</span>
                    </button>
                    <button type="button" class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3"
                        id="btnClose" (click)="close()">
                        <i class="flaticon2-cross"></i>
                        <span>Cerrar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div> -->
<form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
    <div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
        <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>
            Información del correo electrónico</h2>
        <button type="button" (click)="close();" class="close" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
        </button>
    </div>

    <div class="mat-dialog-content-custom">
        <div class="card-body">
            <div class="row">
                <div class="form-group col-lg-12">
                    <label for="firstName">Título <span class="text-danger" *ngIf="title.value == ''"> *</span></label>
                    <input id="lastName" type="text" class="form-control form-control-solid form-control-lg"
                        formControlName="title">
                    <div *ngIf="title.invalid && (title.dirty || title.touched)" style="color: red;">
                        <div *ngIf="title.errors.required">El Título es obligatorio.</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-12">
                    <label for="lastName">Cuerpo <span class="text-danger" *ngIf="body.value == ''"> *</span></label>
                    <textarea class="form-control form-control-solid form-control-lg flex-1" style="height: 150px;"
                        formControlName="body"></textarea>

                    <div *ngIf="body.invalid && (body.dirty || body.touched)" style="color: red;">
                        <div *ngIf="body.errors.required">El Cuerpo es obligatorio.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mat-dialog-actions" [align]="'end'">
        <button type="button" class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3" id="btnCloseU"
            (click)="close()">
            <i class="flaticon2-cross"></i>
            <span>Cerrar</span>
        </button>

        <button type="submit" class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3" id="btnClose"
            [disabled]="emailForm.invalid">
            <i class="flaticon-disco-flexible"></i>
            <span>Enviar</span>
        </button>
    </div>
</form>