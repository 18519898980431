import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { AuthInfoService } from './auth-info.service';
import { PayrollConceptsBulk } from './../pages/payroll-new/payroll-concept/bulk-load-payroll-concept/models';
import { ConceptSetting } from '@payroll/news-list/models/PayrollNews.model';
import { ConceptValues } from '../shared/models/conceptValues.model';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';

@Injectable({
  providedIn: 'root'
})
export class PayrollConceptService {

  private readonly hrm = environment.api_url
  constructor(
    private http: HttpClient,
    private srvAuth: AuthInfoService
  ) { }


  postBulk(bulk: any){
    return this.http.post(`${this.hrm}/BulkLoad/PostBulkLoadConcept`, bulk);
  }
  getBulk(bulk: PayrollConceptsBulk[]){
    return this.http.post(`${this.hrm}/BulkLoad/GetBulkLoadConcept`, bulk);
  }

  getAllNewConceptPaginated(companyId, page, pageSize, propKeyId, text, status, advancedFilter?){
    return this.http.get(this.hrm + `/NewsConcept/Paginated?NewsConceptId=0&searchParameter=${text}&propKeyId=${propKeyId}&page=${page}&pageSize=${pageSize}&CompanyId=${companyId}&Status=${status}&AdvancedFilter=${advancedFilter}`)
  }
  getAllNewConcept(companyId, type = 0){
    return this.http.get(this.hrm + `/NewsConcept?NewsConceptId=0&Type=${type}&CompanyId=${companyId}&Status=true`)
  }

  getAllDiscountNewConcept(companyId){
    return this.http.get<ResponseModel<any>>(this.hrm + `/NewsConcept?Type=3&CompanyId=${companyId}&Status=true`)
  }

  getAllDiscountNewConceptRelated(conceptOriginId: number, companyId: number){
    return this.http.get<ResponseModel<any>>(this.hrm + `/NewsConcept?NewsConceptId=${conceptOriginId}&Type=5&CompanyId=${companyId}&Status=true`)
  }

  getBeneficiaries(){
    const companyId = this.srvAuth.getCompanyId()
    return this.http.get(this.hrm + `/ConceptBeneficiary?ConceptBeneficiaryId=0&Type=0&CompanyId=${companyId}&Status=true`)
  }

  postConcept(model){
    return this.http.post(this.hrm + '/NewsConcept', model)
  }
  updateConcept(model){
    return this.http.put(this.hrm + '/NewsConcept', model)
  }

  postNewsConceptSetting(newsConceptSetting: ConceptSetting){
    return this.http.post(`${this.hrm}/NewsConceptSetting`, newsConceptSetting);
  }

  updateNewsConceptSetting(newsConceptSetting: ConceptSetting){
    return this.http.put(`${this.hrm}/NewsConceptSetting`, newsConceptSetting);
  }

  getNewsConceptsSetting(NewsConceptSettingId: number, CompanyId: number = this.srvAuth.getCompanyId(), Status: boolean =  true){
    return this.http.get(`${this.hrm}/NewsConceptSetting?NewsConceptSettingId=${NewsConceptSettingId}&Type=1&CompanyId=${CompanyId}&Status=${Status}`);
  }

  getConceptValues(ConceptValuesId: number, CompanyId: number = this.srvAuth.getCompanyId()){
    return this.http.get(`${this.hrm}/ConceptValues?ConceptValuesId=${ConceptValuesId}&Type=2&CompanyId=${CompanyId}&Status=true`);
  }

  postConceptValues(conceptValues: ConceptValues[]){
    return this.http.post(`${this.hrm}/ConceptValues`, conceptValues);
  }

  putConceptValues(conceptValues: ConceptValues[]){
    return this.http.put(`${this.hrm}/ConceptValues`, conceptValues);
  }

  deleteConceptValues(ConceptValuesId: number){
    return this.http.delete(`${this.hrm}/ConceptValues?ConceptValuesId=${ConceptValuesId}`);
  }
  getPayrollTypesByConceptId(conceptId: number, CompanyId: number = this.srvAuth.getCompanyId()){
    return this.http.get(`${this.hrm}/NewsConceptPayollType?NewsConceptPayollTypeId=${conceptId}&type=2&CompanyId=${CompanyId}&Status=true`);
  }

  deleteConcept(model){
    return this.http.put(`${this.hrm}/NewsConcept/Inactivate`, model)
  }
  activateConcept(model){
    return this.http.post(`${this.hrm}/NewsConcept/Activate`, model)
  }

  public getNewsConceptByHeader(parameters: {NewsConceptId, PayrollTypeId, Version, PayrollPeriodId, budgetAreaId, PayrollPeriodIdRelated, Type, CompanyId, status}){
    let params = new HttpParams()
    params = params.append('NewsConceptId', parameters.NewsConceptId)
    params = params.append('PayrollTypeId', parameters.PayrollTypeId)
    params = params.append('Version', parameters.Version)
    params = params.append('PayrollPeriodId',parameters.PayrollPeriodId)
    params = params.append('budgetAreaId',parameters.budgetAreaId)
    params = params.append('PayrollPeriodIdRelated',parameters.PayrollPeriodIdRelated)
    params = params.append('Type',parameters.Type)
    params = params.append('CompanyId',parameters.CompanyId)
    params = params.append('Status', parameters.status)
    return this.http.get(`${this.hrm}/NewsConcept/GetNewsConceptByHeader`, {params})
  }

  getConceptsBasedNewsAvailable(PayrollTypeId: number, PayrollPeriodRelatedId: number, budgetAreaId: number, payrollPeriodId: number, versionId?: number){
    return this.http.get<any>(`${this.hrm}/NewsConcept/ConceptsBasedNewsAvailable?`,{
      params: <any>{
        PayrollTypeId,
        PayrollPeriodRelatedId,
        BudgetAreaId: budgetAreaId,
        PayrollPeriodId: payrollPeriodId,
        CompanyId:this.srvAuth.getCompanyId(),
        Type: 1,
        VersionId: versionId ?? 0
      }
    });
  }
  
  getAllNewConceptPaginatedHistory(companyId,newConceptId,page, pageSize, propKeyId, text, status, advancedFilter?){
    return this.http.get( `${this.hrm}/NewsConcept/PaginatedHistory?NewConceptId=${newConceptId}&searchParameter=${text}&propKeyId=${propKeyId}&page=${page}&pageSize=${pageSize}&CompanyId=${companyId}&Status=${status}&AdvancedFilter=${advancedFilter}`);
  }
}
