import { Component, Input, OnInit } from '@angular/core';
import { FormatTypes } from './enums/format-types';

@Component({
  selector: 'widget-dynamic-format',
  templateUrl: './dynamic-format.component.html',
  styleUrls: ['./dynamic-format.component.scss'],
})
export class DynamicFormatComponent {
  @Input() format: string;
  @Input() value: any;
  @Input() isAling = false;
  formatTypes = FormatTypes;
  charLength = 40;

  valueOf(value: any): any {
    if (value && typeof value == 'object' && value.element) {
      let objectProperty = '';
      
      value.forEach((element) => {
        objectProperty += ` ${element.itemName}:${element.childrenIds[0]}`;
      });
      return objectProperty;
    } else {
      return value;
    }
  }
}
