<angular2-multiselect
  [data]="dropDownItems"
  [(ngModel)]="selectedItems"
  [settings]="dropdownSettings"
  (onSelect)="onItemSelect()"
  (onDeSelect)="OnItemDeSelect()"
  (onSelectAll)="onSelectAll()"
  (onDeSelectAll)="onDeSelectAll()"
>
</angular2-multiselect>
<label *ngIf="isBeenUse()" class="form-text text-danger"
  >debes escoger al menos una</label
>
<div class="mt-3 p-2 size" [class]="selectedItems?.length ? 'scrollable' : ''">
  <h3 class="text-center" *ngIf="!selectedItems?.length"></h3>

  <div *ngIf="selectedItems?.length" id="accordion">
    <ng-container [ngSwitch]="inputType">
      <ng-container *ngSwitchCase="'textbox'">
        <div *ngFor="let item of selectedItems">
          <div id="header">
            <h5 class="mb-0">
              <button
                class="btn btn-primary btn-block text-uppercase notification py-2 rounded-pill"
                data-toggle="collapse"
                [attr.data-target]="'#p' + item.id.toString()"
                aria-expanded="true"
                [attr.aria-controls]="'p' + item.id.toString()"
              >
                {{ item.itemName }}
                <span class="badge badge-light">{{
                  getOptionsSelectedTotalHours(item.id)
                }}</span>
              </button>
            </h5>
          </div>

          <div
            [id]="'p' + item.id.toString()"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <ul class="list-group list-group-flush">
              <li class="list-group-item cursor-pointer">
                <span class="ma-auto">
                  <label>Cantidad de créditos</label>
                  <input
                    *ngIf="Dynamicselect"
                    [(ngModel)]="item.childrenIds[0]"
                    type="number"
                    pattern="/^-?\d+\.?\d*$/"
                    onKeyPress="if(this.value.length==2) return false;"
                    value=""
                    class="form-control"
                    [name]="item.id"
                  />
                </span>
              </li>
            </ul>
          </div>
          <label *ngIf="isinputEmpty(item.id)" class="form-text text-danger"
            >debes tener al menos un credito</label
          >
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
