import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { PayrollNewsDelete } from '../models/PayrollNews.model';

@Component({
  selector: 'app-delete-payroll-new',
  templateUrl: './delete-payroll-new.component.html',
  styleUrls: ['./delete-payroll-new.component.css']
})
export class DeletePayrollNewComponent implements OnInit, AfterViewChecked {
  form: FormGroup;
  formIsInvalid = false

  constructor(private _toastService: ToastService,
    public dialogRef: MatDialogRef<DeletePayrollNewComponent>,
    private _changeDet: ChangeDetectorRef, private dialog: MatDialog,
    private parameterControlService: ParameterControlService,
    private fb: FormBuilder,
    private payrollNewService: PayrollNewService,
    @Inject(MAT_DIALOG_DATA) public data: any,) { 
      this.dialogRef.disableClose = true;
      this.buildForm();
    }

  ngOnInit(): void {
  }
  
  ngAfterViewChecked(): void {
    this._changeDet.detectChanges();
  }

  buildForm(){
    this.form = this.fb.group({
      reason: ['', [Validators.required]]
    });
  }

  Cancel(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }

  Accept(){
    if(this.form.invalid){
      this.formIsInvalid = true;
      return;
    }
    this.deletePayrollNew();
  }

  deletePayrollNew(){
    const payrollNewsDelete: PayrollNewsDelete = {
      payrollNewsId: this.data?.item,
      reason: this.form?.get('reason')?.value
    }
    this.payrollNewService.removeAll(payrollNewsDelete).subscribe(newsResponse => {
      if (newsResponse?.errors?.length > 0) {
        this._toastService.error(newsResponse?.errors[0]);
        return;
      }
      const row = { Applied: true }
      this.dialogRef.close(row);
    }, error => {
      this._toastService.error(`Error al eliminar la novedades`, "Error inesperado");
    });
  }

  get showErrors(){
    return {
      erorReason: this.form.get('reason').invalid,
    }
  }

}
