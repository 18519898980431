<div class="card card-custom" data-card="true" id="kt_card_3">
    <div class="card-header mt-3">
      <div class="card-title">
        <div class="ttl d-flex align-items-center">
          <i class="flaticon-proceso-2 icon-3x text-danger mr-2"></i>
          <h1 class="text-primary">Reportes</h1>
        </div>
      </div>
    </div>
    <div class="card-header mt-3 row justify-content-between">
      <div class="col-sm-12 col-md-7 c-left">
        <div class="card-toolbar d-flex justify-content-start">

          <div class="col-md-4 mr-2 px-0 pt-1">
            <div class="form-group mx-2 institution">
              <label class="text-primary">
                Estatus
              </label>
              <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-5 c-right mr-0">
        <div class="card-toolbar d-flex justify-content-end">
          <div *ngIf="privilege.export.value" class="dropdown col-md-auto col-sm-12">
              <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
               data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip" title="Exportar"
                      data-original-title="Opciones de exportar"></i> Exportar
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                  <a class="dropdown-item align-items-center" (click)="onBtnPrintClick()">
                    <i class="flaticon-pdf-file text-danger icon-2x text-primary mr-3 "></i>
                    PDF
                </a>
              </div>
          </div>
          <div class="col text-nowrap pt-1">
            <el-search-bar #searchBar [records]="reportsOriginalList" (onKeyChange)="onKeyChange($event)"
              [keyValue]="filterText" [itemForSearching]="searchBarDisplayNames" (onChange)="getFilteredList($event)"
              [namePageLocalStorage]="localStorageSearch"
               [itemForSearching]="searchBarDisplayNames">
            </el-search-bar>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body" kt-hidden-height="282">
      <div class="table-responsive table-responsive-sigei">
        <table class="table table-vertical-center">
          <thead>
            <tr class="bg-primary">
              <th class="text-center">Secuencia</th>
              <th class="text-left">Nombre de Reporte</th>
              <th class="text-center">Estatus</th>
              <th class="actionsBtn">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of paginatedReports">
              <td class="text-center">
                {{item.reportId}}
              </td>
              <td class="text-left">{{item.reportName}}</td>
              <td class="text-center">
                <span *ngIf="item.status" class="font-weight-bold text-success">Activa</span>
                <span *ngIf="!item.status" class="font-weight-bold text-danger">Inactiva</span>
              </td>
              <td class="actionsBtn">
                <a [routerLink]="[item.reportUrl]" *ngIf="item.reportSourceId == 1 && privilege.export.value"
                  class="btn btn-sm btn-light-skype btn-icon mr-2" id="viewButton">
                  <i data-toggle="tooltip" data-title="Abrir Reporte" class="flaticon-descarga-arrow icon-2x"></i>
                </a>
                <button (click)="viewReport(item)" *ngIf="item.reportSourceId != 1 && privilege.export.value"
                  class="btn btn-sm btn-light-skype btn-icon mr-2">
                  <i data-toggle="tooltip" data-title="Abrir Reporte" class="flaticon-descarga-arrow icon-2x"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <el-caption #paginator [originalRecordsLength]="reports.length"
            [listedRecordsLength]="paginatedReports?.length"
            [registerQuantity]="filteredList?.enable && filteredList.records?.length>0 ? filteredList.records?.length :reports?.length"
            [records]="filteredList?.enable ? filteredList?.records : reports"
            (onChage)="getPaginatedRecords($event)"></el-caption>
        </div>
      </div>
    </div>
  </div>


  <export-data-to-pdf [title]="'Generar Reportes'" id="dataToPrint">
    <ng-container thead>
      <th class="text-left">Nombre de Reporte</th>
      <th class="text-center">Estatus</th>
    </ng-container>
    <ng-container tbody>
      <tr *ngFor="let item of paginatedReports" >
        <td class="text-left">{{item.reportName}}</td>
        <td class="text-center">
          <span *ngIf="item.status" class="font-weight-bold text-success">Activa</span>
          <span *ngIf="!item.status" class="font-weight-bold text-danger">Inactiva</span>
        </td>
      </tr>
    </ng-container>
  </export-data-to-pdf>
