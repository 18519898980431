<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-recursos-humanos-4  icon-3x text-danger mr-2"></i>
                <h1 class="text-primary">Servidores Públicos</h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Dashboard">
            <button (click)="openDashBoard()" *ngIf="privilege.dashboardAction.value"
                class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </button>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-7 c-left">
            <div class="card-toolbar d-flex justify-content-start">

                <div class="col-md-3 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Estatus
                        </label>
                        <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                            [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                            (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                    </div>
                </div>

                <div class="col-md-3 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Sucursal
                        </label>
                        <ngx-select-dropdown (change)="onBranchChange()" [options]="branches"
                            [config]="dropdownConfig('companyName')" [(ngModel)]="branch"
                            class="w-100"></ngx-select-dropdown>
                    </div>
                </div>

                <button class="btn btn-light-warning btn-pill font-weight-bolder mt-2 mx-0 col-md-auto"
                    (click)="resetFilters()">
                    <i class="fas fa-redo  icon-2x" data-toggle="tooltip" data-title="Reiniciar filtro"></i>
                </button>
                <button *ngIf="incompleteEmployees?.quantity > 0" (click)="openModalIncompleteEmployees()" d
                    class="btn btn-danger btn-pill mt-2 ml-3 mx-0 col-md-auto">
                    <i class="flaticon-recursos-humanos-6 icon-2x"></i> Servidores Públicos Incompletos:
                    {{incompleteEmployees?.quantity}}
                </button>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="privilege.exportAction.value" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="exportToPdf()">
                            <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                            Reporte Detallado
                        </a>
                        <a class="dropdown-item align-items-center" (click)="exportEmployeeByDepartments()">
                            <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                            Servidores Públicos por Unidad Organizativa
                        </a>

                    </div>
                </div>

                <div class="col text-nowrap">
                    <app-search-bar-pagination [namePageLocalStorage]="localStorageSearch"
                        (onFilter)="filterSearch($event)" (onChangeDropDown)="filterSearch($event)"
                        [parameters]="searchBarDisplayNames"></app-search-bar-pagination>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">

        <div class="table-responsive table-responsive-sigei">
            <table class="table table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th class="text-left min-w-150px">Código de Servidor Público</th>
                        <th class="text-left">Servidor Público</th>
                        <th class="text-left">Tipo de Documento</th>
                        <th class="text-left min-w-160px">Documento de Identidad</th>
                        <th class="text-left min-w-160px">Sexo</th>
                        <th class="text-left min-w-160px">Fecha de Ingreso</th>
                        <th class="text-left">Sucursal</th>
                        <th class="text-left">Unidad Organizativa</th>
                        <th class="text-left">Cargo</th>
                        <th class="text-left min-w-150px">Teléfono Primario</th>
                        <th class="text-left">Tipo de Servidor Público</th>
                        <th class="text-left">Categoría de Servidores Públicos</th>
                        <th class="text-left">Tanda</th>
                        <th class="text-left">Condición Actual</th>
                        <th class="text-left">Estatus</th>
                        <th class="actionsBtn">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let employee of employees">   
                        <td class="text-center">
                            {{employee.employeeId}}
                        </td> 
                        <td class="text-left">{{employee.employNumber}}</td>
                        <td class="text-left">{{employee.name}}</td>
                        <td class="text-left">{{employee.typeIdentification}}</td>
                        <td class="text-left" *ngIf="employee.typeIdentificationId == 1">
                            {{employee.personalIdentification | mask: '000-0000000-0'}}</td>
                        <td class="text-left" *ngIf="employee.typeIdentificationId != 1">
                            {{employee.personalIdentification}}</td>
                        <td class="text-left">{{employee.sex}}</td>
                        <td class="text-left">{{employee.admissionDate |date:'dd/MM/yyyy'}}</td>
                        <td class="text-left">{{employee.branch}}</td>
                        <td class="text-left">{{employee.departmentName}}</td>
                        <td class="text-left">{{employee.positionName}}</td>
                        <td class="text-left">{{employee.phoneNumber | mask: "(000) 000-0000"}}</td>
                        <td class="text-left">{{employee.employeeType}}</td>
                        <td class="text-left">{{employee.contractType}}</td>
                        <td class="text-left">{{employee.proposedSchedule}}</td>
                        <td class="text-left">
                            <ng-container *ngIf="employee?.employeeAction">
                                {{employee?.employeeAction}}
                            </ng-container>
                            <ng-container *ngIf="employee?.employeeAction === undefined">
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                <span class="visually-hidden">Loading...</span>
                            </ng-container>
                        </td>
                        <td class="text-center">
                            <span *ngIf="employee.status" class="font-weight-bold text-success">Activo</span>
                            <span *ngIf="!employee.status && !employee.wasHiringCanceled" class="font-weight-bold text-danger">Desvinculado</span>
                            <span *ngIf="!employee.status && employee.wasHiringCanceled" class="font-weight-bold text-danger">Nombramiento Anulado</span>
                        </td>
                        <td class="text-left">
                            <div class="d-flex">
                                <div class="col px-0 min-w-36">
                                    <a *ngIf="employee.status && privilege.editAction.value"
                                        [routerLink]="['/candidate-registration/',employee.personId, 1,employee.employeeId]"
                                        class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip"
                                        title="Editar">
                                        <i class="flaticon-edit"></i>
                                    </a>
                                </div>
                                <div class="col px-0 min-w-36">
                                    <a *ngIf=" privilege.viewAction.value" (click)="exportEmployeeData(employee)"
                                        class="btn btn-icon btn-light-info btn-sm m-1" data-toggle="tooltip"
                                        title="Ver Servidor Público">
                                        <i class="flaticon-ver"></i>
                                    </a>
                                    <!-- 
                                        <a *ngIf="employee.unemployment && privilege.viewAction.value"
                                            routerLink="/pdf-control-document/2/0/{{employee.employeeId}}"
                                            class="btn btn-icon btn-light-info btn-sm m-1" data-toggle="tooltip"
                                            title="Ver Servidor Público">
                                            <i class="flaticon-ver"></i>
                                        </a> -->
                                </div>
                                <div class="col px-0 min-w-36" *ngIf="privilege.historyPersonalActions.value">
                                    <a (click)="openModalHistoryPersonalActionsComponent(employee)"
                                        class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip"
                                        title="HIstorial de Cambios">
                                        <i class="flaticon-contract-3 icon-2x"></i>
                                    </a>
                                </div>
                                <div class="col px-0 min-w-36">
                                    <a (click)="clearStorage()" *ngIf="privilege.personnelAction.value"
                                        [routerLink]="['/action-change-employee',employee.employeeId, 0]"
                                        class="btn btn-icon btn-light-success btn-sm m-1" data-toggle="tooltip"
                                        title="Acciones del Personal">
                                        <i class="flaticon-colaboracion icon-2x"></i>
                                    </a>
                                </div>

                                <div class="col px-0 min-w-36" *ngIf="!employee.status">
                                    <button title="add" class="btn btn-icon btn-light-success btn-sm m-1"
                                        (click)="showModalCertification(employee)">
                                        <i class="flaticon-etiqueta icon-2x" data-toggle="tooltip"
                                            data-title="Certificación Laboral" title="Certificación Laboral"></i>
                                    </button>
                                </div>

                                <!-- started working with the modal -->

                                <div class="col px-0 min-w-36" *ngIf="privilege.auditChanges.value">
                                    <button type="button" class="btn btn-sm btn-light-dark btn-icon mr-2"
                                            data-title="Auditar Cambios" 
                                            (click)="openModalAuditChanges(employee)">
                                              <i class="flaticon-lista-de-verificacion-2" data-toggle="tooltip"
                                                data-title="Auditar Cambios"></i>
                                      </button> 
                                </div>
                                
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <div>
                    <app-paginator [listedRecordsLength]="employees?.length"
                        [registerQuantity]="employeesPagedResut.totalItems"
                        [registerPerPage]="employeesPagedResut.pageSize" [totalPages]="employeesPagedResut.totalPages"
                        [selectedPage]="employeesPagedResut.page" (onChange)="getPaginatedRecords($event)">
                    </app-paginator>
                </div>
            </div>
        </div>

    </div>

</div>