import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DepartmentService } from 'src/app/services/department.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { PayrollFlyerService } from 'src/app/services/payroll-flyer.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { PositionService } from 'src/app/services/position.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';


@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.css']
})
export class AdvancedSearchComponent implements OnInit {

   public newsTypes:any [] = [];
   public beneficiaryList:any [] = [];
   public payrollTypes:any [] = [];
   public deparment:any [] =[];
   public position:any [] =[];
   public employeeType:any [] = [];
   public coletilla:any [] = [];

   public statusOptions = [];

   public form = this._fb.group({
    payrollTypes:[''],
    employeeType:[''],
    employeeName: [''],
    deparment:[''],
    position:[''],
    status:[''],
    coletilla:['']
   })

    dropdownSettings = {
      singleSelection: false,
      idField: 'ocode',
      textField: 'stringData',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      enableCheckAll: true,
      itemsShowLimit: 2,
      allowSearchFilter: true,
      limitSelection: -1
    };

    constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<AdvancedSearchComponent>,
      public _fb : FormBuilder,  private _ParamService: ParameterControlService,
      private _toastService: ToastService, private _departmentService: DepartmentService,
      private _positionService: PositionService, private _employeeType: EmployeesService,
      private _payrollFlyerSvc: PayrollFlyerService, @Inject(MAT_DIALOG_DATA) public data:any,
      ) {
        dialogRef.disableClose = true
        if(this.data.advanceFilter != undefined){
          this.form.setValue({...this.data.advanceFilter})
        }



      }
  ngOnInit(): void {
    this.loadPayrollTypes();
    this.getDeparment();
    this.getPosition();
    this.getEmployeeType();
    this.getColetilla();
    this.getStatus()
  }

  loadPayrollTypes() {
    this._ParamService.getParameters(keyWord.PayrollType).subscribe((res: any) => {
      if (res.succeded) {
        this.payrollTypes = res.dataList;
      } else {
        this._toastService.error(res.error[0])
      }
    }, err => {
      this._toastService.error('', 'ERROR INESPERADO')
    })
  }

  getStatus() {
    this._ParamService.getParameters(keyWord.HeaderConditions).subscribe((res: any) => {
      if (res.succeded) {
        this.statusOptions = res.dataList;
      } else {
        this._toastService.error(res.error[0])
      }
    }, err => {
      this._toastService.error('', 'ERROR INESPERADO')
    })
  }

  getDeparment(){
    this._departmentService.getAll().subscribe({
      next:(resp:any) => {
        var deparment : any [] = resp.dataList
        deparment.forEach(resp => {
          resp.stringData = resp.departmentName;
          resp.ocode = resp.departmentId;
        })
        this.deparment = deparment;
      }
    })
  }

  getPosition(){
    this._positionService.getApprovedPositionsByCompany().subscribe({
      next:(resp:any) => {
        var position : any [] = resp.dataList
        position.forEach(resp => {
          resp.stringData = resp.positionName;
          resp.ocode = resp.positionId;
        })
        this.position = position;
      }
    })
  }

  getEmployeeType() {
    this._employeeType.getEmployeeTypes().subscribe((res: any) => {
      if (!res.succeded) {
        return
      }
      var employeeType: any[] = res.dataList
      employeeType.forEach(resp => {
        resp.stringData = resp.description;
        resp.ocode = resp.employeeTypeId;
      })
      this.employeeType = employeeType.filter(d => d?.status == true && d.description != " ");
    });
  }

  getColetilla(){
    this._payrollFlyerSvc.getPaymentFlyerColetilla(this.data.periodId).subscribe({
      next:(resp) => {
        var coletilla : any [] = resp.dataList
        coletilla.forEach(resp => {
          resp.stringData = resp.coletilla;
          resp.ocode = 1;
        })
        this.coletilla = coletilla
      }
    })
  }

  Cancel(){
    this.dialogRef.close()
  }

  Accept(){
    var data:any = this.form.value;
    const advanceFilterOptions  = {
      ...data,
      payrollTypes: Array.isArray(data?.payrollTypes) ? data.payrollTypes.map(x => x.ocode).join("|") : '',
      employeeType: Array.isArray(data?.employeeType) ? data.employeeType.map(x => x.ocode).join("|") : '',
      deparment: Array.isArray(data?.deparment) ? data.deparment.map(x => x.ocode).join("|") : '',
      position: Array.isArray(data?.position) ? data.position.map(x => x.ocode).join("|") : '',
      status: Array.isArray(data?.status) ? data.status.map(x => x.ocode).join("|") : '',
      coletilla: Array.isArray(data?.coletilla) ? data.coletilla.map(x => x.stringData).join("|") : '',

    }

    this.dialogRef.close(data= {advanceFilter: advanceFilterOptions, formBd: data })
  }

}

