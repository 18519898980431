<div data-card="true" class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-nomina-de-sueldos icon-3x text-danger"></i>
                <h1 class="text-primary"> Planificación RRHH</h1>
            </div>
        </div>
        <div class="card-toolbar d-flex justify-content-start btn-view-dashboard btn-view-dashboard"
            data-toggle="tooltip" data-original-title="Dashboard">
            <!--        <button data-toggle="modal" class="btn btn-outline-primary btn-pill font-weight-bolder mt-2 mx-0 col-md-auto">
                <i class="flaticon-dashboard-1 icon-2x"></i> Ver Dashboard
            </button> -->
        </div>
    </div>
    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-6 c-left">
            <div class="card-toolbar d-flex justify-content-start">

                <div class="col-md-4 mr-2 px-0 pt-1">
                    <div class="form-group mx-2 institution">
                        <label class="text-primary">
                            Estatus
                        </label>
                        <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                            [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                            (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                    </div>
                </div>
                <button *ngIf="this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.addpost)"
                    data-toggle="modal" id="btnAdd"
                    class="btn btn-success font-weight-bolder  btn-pill  mt-2 mx-0 col-md-auto"
                    (click)="openPlanifficationModal(viewsEnum.Create,null)">
                    <i class="flaticon-mas icon-2x" data-toggle="tooltip"
                        data-original-title="Agregar Planificación RRHH"></i>
                    Agregar
                </button>
            </div>
        </div>
        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="exportToExcel()">
                            <i class="flaticon-excel icon-2x text-success mr-3"></i>
                            Excel
                        </a>
                        <a class="dropdown-item align-items-center" (click)="onBtnPrintClick()">
                            <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                            PDF
                        </a>
                        <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a> -->
                    </div>
                </div>

                <div class="col text-nowrap">
                    <el-search-bar #searchBar (onKeyChange)="onKeyChange($event)" [keyValue]="filterText"
                        [records]="planningRrhhOriginalList" [itemForSearching]="searchBarDisplayNames"
                        (onChange)="getFilteredList($event);"></el-search-bar>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-hover table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th class="text-center">Secuencia</th>
                        <th class="text-left">Año</th>
                        <!-- <th class="text-left">Presupuesto Solicitado</th> -->
                        <th class="text-left">Presupuesto Aprobado</th>
                        <th class="text-left">Presupuesto Ejecutado</th>
                        <th class="text-left">Presupuesto Disponible</th>
                        <th class="text-left">Condición</th>
                        <th class="text-left">Estatus</th>
                        <th class="text-left" style="width: 100px;">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let planning of planningRrhhRecords" class="datatable-row" style="left: 0px;">
                        <td class="text-center">{{planning.planningHeaderId}}</td>
                        <td class="text-left">{{planning.yearExecution}}</td>
                        <!-- <td class="text-left">{{planning.requestedBudget | currency}}</td> -->
                        <td class="text-left">{{planning.approvedBudget || 0 | currency}}</td>
                        <td class="text-left">{{planning.executedBudget || 0 | currency}}</td>
                        <td class="text-left">{{planning.availableBudget || 0 | currency}}</td>
                        <td class="text-left">{{planning.condition}}</td>
                        <td class="text-left">
                            <span *ngIf="planning.status" class="font-weight-bold text-success">Activo</span>
                            <span *ngIf="!planning.status" class="font-weight-bold text-danger">Inactivo</span>
                        </td>
                        <td data-field="Actions" data-autohide-disabled="false" aria-label="null" class="text-left">
                            <div class="d-flex justify-content-center  px-5  " style="width: max-content">
                                <div class="justify-content-start  px-5">
                                    <a *ngIf="this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.viewmodal)"
                                        (click)="openPlanifficationModal(viewsEnum.View, planning)" tooltip="Ver"
                                        placement="top" data-toggle="modal"
                                        class="btn btn-sm btn-light-skype btn-icon mr-2">
                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" data-title="Ver"></i>
                                    </a>
                                    <a *ngIf="this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.edit)"
                                        (click)="openPlanifficationModal(viewsEnum.Edit, planning)" tooltip="Editar"
                                        placement="top" class="btn btn-sm btn-light-warning btn-icon mr-2">
                                        <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                                    </a>
                                    <a *ngIf="planning?.conditionId === 6 && this.authInfo.canDoAction(this.privilege.nameKeyOption, this.privilege.approve)"
                                        (click)="approvePlanning(planning)" tooltip="Editar" placement="top"
                                        class="btn btn-sm btn-light-success btn-icon mr-2">
                                        <i class="flaticon-etiqueta icon-2x" data-toggle="tooltip"
                                            data-title="Aprobar"></i>
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <el-caption [originalRecordsLength]="planningRrhh.length"
                    [listedRecordsLength]="planningRrhhRecords.length" #paginator
                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :planningRrhh.length"
                    [records]="filteredList.enable ? filteredList.records : planningRrhh"
                    (onChage)="getPaginatedRecords($event)"></el-caption>
            </div>
        </div>
    </div>
</div>

<export-data-to-pdf [title]="'Vinculación'" id="dataToPrint">
    <ng-container thead>
        <th class="text-left">Año</th>
        <th class="text-left">Presupuesto Aprobado</th>
        <th class="text-left">Presupuesto Ejecutado</th>
        <th class="text-left">Presupuesto Disponible</th>
        <th class="text-left">Condición</th>
        <th class="text-left">Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let planning of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.planningRrhh"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td class="text-left">{{planning.yearExecution}}</td>
            <td class="text-left">{{planning.approvedBudget || 0 | currency}}</td>
            <td class="text-left">{{planning.executedBudget || 0 | currency}}</td>
            <td class="text-left">{{planning.availableBudget || 0 | currency}}</td>
            <td class="text-left">{{planning.condition}}</td>
            <td class="text-left">
                <span *ngIf="planning.status" class="font-weight-bold text-success">Activo</span>
                <span *ngIf="!planning.status" class="font-weight-bold text-danger">Inactivo</span>
            </td>
        </tr>
    </ng-container>
</export-data-to-pdf>