export class ApplyVacancyModel{
       
        idPpv: number;
        personId:number;
        vacantId:number;
        comment : string;
        desiredSalary: number;
        startDate?: string | Date;
        dateApplication: string | Date;
        toTravel: boolean;
        transfer: boolean;
        companyId: number;
        status: boolean;
        hoursAvailable: number;
        condition: number;
        authorizedData: boolean;
        employee: boolean;
        minSalary?:number;
        maxSalary?:number;
        halfSalary?:number;
        positionName?: string;
        requisitionType?: string;
        constructor(){
            this.idPpv = 0;
            this.personId = 0;
            this.vacantId = 0;
            this.comment = '';
            this.condition = 0;
            this.desiredSalary = 0;
            this.startDate = null;
            this.dateApplication = null
            this.toTravel = null;
            this.transfer = null;
            this.companyId = 0;
            this.status = null;
            this.hoursAvailable = 0;
            this.authorizedData = null;
            this.employee= null;
        }
}
