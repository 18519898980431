import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class PayrollReportService {
  
  apiCore = environment.api_core_url;
  private api_lobby = environment.api_core_sigei;
  api = environment.api_url; 

  constructor(private http: HttpClient, private authInfo: AuthInfoService) { }


  getPeriods(companyId: number = this.authInfo.getCompanyId(), status: boolean = true) {
    return this.http.get(`${this.api}/Period?CompanyId=${companyId}&Status=${status}`);
  }

  getOverallSummary(payrollPeriodId: number, payrollTypeId: number, employeeId: number, companyId: number = this.authInfo.getCompanyId()){
    return this.http.get(`${this.api}/Detail/GetOverallSummary?PayrollPeriodId=${payrollPeriodId}&PayrollTypeId=${payrollTypeId}&EmployeeId=${employeeId}&Type=6&CompanyId=${companyId}&Status=true`);
  }

  getOverallSummaryWithoutEmployeeType(payrollPeriodId: number, payrollTypeId: number, budgetAreaId: number, VersionId: number, periodIdRelated: number, companyId: number = this.authInfo.getCompanyId()){
    return this.http.get(`${this.api}/Detail/GetOverallSummary?PayrollPeriodId=${payrollPeriodId}&PayrollTypeId=${payrollTypeId}&BudgetAreaId=${budgetAreaId}&Type=10&CompanyId=${companyId}&Status=true&VersionId=${VersionId}&PayrollPeriodIdRelated=${periodIdRelated}`);
  }

  getCompanies(){
    return this.http.get(`${this.api_lobby}/Companies?companyCode=0&level=2`);
  }

  getReports(companyId = this.authInfo.getCompanyId()){
    return this.http.get(`${this.api_lobby}/Report?ReportId=1&companyId=${companyId}&type=2`)
  }
  getReportById(id, companyId = this.authInfo.getCompanyId()){
    return this.http.get(`${this.api_lobby}/Report?ReportId=${id}&companyId=${companyId}&type=1`)
  }
}
