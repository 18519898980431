import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AdditionalPayrollService } from 'src/app/services/additional-payroll.service';
import { docService } from 'src/app/services/doc.service';
import { FinalPayrollService } from 'src/app/services/final-payroll.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { parameterDoc } from 'src/app/shared/utils/parameterDoc';
import { environment } from 'src/environments/environment';
import { IEmployeeGET } from '../models/models';

@Component({
  selector: 'app-additional-payroll-news-detail-modal',
  templateUrl: './additional-payroll-news-detail-modal.component.html',
  styleUrls: ['./additional-payroll-news-detail-modal.component.css']
})
export class AdditionalPayrollNewsDetailModalComponent implements OnInit {

  records: any[] = []
  paginatedRecords: any[] = []
  employee: any;
  employeeImg = ''
  
  employeeSelected: IEmployeeGET = {
    email: '',
    name: '',
    phoneNumber: '',
    academicLevel: '',
    personalIdentification: '',
    typeIdentificationId: 0,
    employeeSupervisor: ''
  }
  constructor(
    private dialogRef: MatDialogRef<AdditionalPayrollNewsDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {item: IEmployeeGET, periodId: number, budgetArea: any},
    private additionalService: AdditionalPayrollService,
    private parameterControlService: ParameterControlService,
    private srvDoc: docService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    const employeeId = this.data.item.employeeId
    const periodId = this.data.periodId
    const budgetAreaId = this.data?.budgetArea?.budgetAreaId
    this.getEmployeeById(employeeId)
    this.getNewsDetails(periodId, employeeId, budgetAreaId)
  }
  
  
  close(){
    this.dialogRef.close()
  }
  
  getEmployeeById(employeeId){
    this.additionalService.getEmployeeById(employeeId).subscribe((res:any) => {
      if(res.succeded){
        this.employeeSelected = res.dataList[0]
        if(this.employeeSelected.profileImage){
          this.getPersonPhoto(this.employeeSelected.profileImage)
        }
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }
  getPersonPhoto(guid: string){
    this.srvDoc.getDocument(guid).subscribe((res:any) => {
      if(res.succeded){
        this.employeeImg = res.data
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }
  
  getNewsDetails(periodId, employeeId, budgetAreaId: number){
    this.additionalService.getPayrollNewDetail(periodId, employeeId, true, budgetAreaId).subscribe((res:any) => {
      if(res.succeded){
        this.records = res.dataList
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error('Ha ocurrido un error inesperado')
    })
  }
  
  
  getPaginatedRecords(event) {
    this.paginatedRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

}
