<div id="" class="card card-custom">
    <div class="card-header mt-3 " style="justify-content: flex-start !important">
        <div class="card-title flex-column align-items-start" style="justify-content: flex-start !important">
            <div class="ttl d-flex align-items-center">
                <i class="flaticon-departamento icon-3x text-danger mr-3"></i>
                <h2 class="text-primary"> Unidades Organizativas </h2>
            </div>
        </div>
    </div>

    <div class="card-header mt-3 row justify-content-between">
        <div class="col-sm-12 col-md-6 c-left">
            <div class="card-toolbar d-flex justify-content-start">
                <div class="col-md-4 mr-2 px-0 pt-1">
                    <div class="form-group institution">
<!-- 
                        <label class="text-primary">
                            Estatus
                        </label>

                        <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                            [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelect)="onItemDeSelect($event)"
                            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                        </ng-multiselect-dropdown> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-5 c-right mr-0">
            <div class="card-toolbar d-flex justify-content-end">
                <div class="dropdown col-md-auto col-sm-12">
                    <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.exportAction)"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                            data-original-title="Opciones de exportar"></i> Exportar
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item align-items-center" (click)="openDepartmentReport()">
                            <i class="fa fa-print icon-2x text-primary mr-3 "></i>
                            Visualizar Reporte
                        </a>
                        <!-- <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-word icon-2x text-primary mr-3"></i>
                            Word
                        </a>
                        <a class="dropdown-item disabled" href="#">
                            <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                            CSV
                        </a> -->
                    </div>
                </div>

                <div class="col text-nowrap">
                    <el-search-bar (onChange)="getFilteredList($event)" #searchBar [keyValue]="filterText" 
                    (onKeyChange)="onKeyChange($event)" (onKeyParameterChange)="onKeyParameterChange($event)"
                        [itemForSearching]="searchBarDisplayNames" [records]="departmentsListOriginal"
                        [namePageLocalStorage]="localStorageSearch"></el-search-bar>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive table-responsive-sigei">
            <table class="table mt-2 table-vertical-center">
                <thead>
                    <tr class="bg-primary">
                        <th>Secuencia</th>
                        <th>Unidad Organizativa</th>
                        <th>Unidad Organizativa Supervisora</th>
                        <th>Estatus</th>
                        <th>Acciones</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of recordsPaginated">
                        <td>{{item.departmentId}}</td>
                        <td>{{item.departmentName}}</td>
                        <td>{{item?.parentSectionName? item.parentSectionName: "Ninguno"}}</td>
                        <td [ngClass]="item.status?'text-success':'text-danger'">{{item.status? 'Activo': 'Inactivo'}}
                        </td>
                        <td>
                            <a *ngIf="(item.conditionId != 1 && item.conditionId != 3 && item.conditionId != 4)"
                                    [routerLink]="['/department-changes',item.departmentId]"
                                    class="btn btn-icon btn-light-success btn-sm m-1" data-toggle="tooltip"
                                    title="Acciones de cambio">
                                    <i class="flaticon-colaboracion icon-2x"></i>
                                </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="">
                <el-caption #paginator [originalRecordsLength]="dataList.length"
                    [listedRecordsLength]="recordsPaginated.length"
                    [registerQuantity]="filteredList.enable && filteredList.records.length>0 ? filteredList.records.length :dataList.length"
                    [records]="filteredList.enable ? filteredList.records : dataList"
                    (onChage)="getPaginatedRecords($event)"></el-caption>
            </div>
        </div>
    </div>
</div>

<export-data-to-pdf [title]="'Unidades Organizativas'" id="dataToPrint">
    <ng-container thead>
        <th>Unidad Organizativa</th>
        <th>Unidad Organizativa supervisor</th>
        <th>Estatus</th>
    </ng-container>
    <ng-container tbody>
        <tr *ngFor="let item of this.filteredList.enable && this.filteredList.records?.length>0 ? this.filteredList.records : this.dataList"
            data-row="0" class="datatable-row" style="left: 0px;">
            <td>{{item.departmentName}}</td>
            <td>{{item?.parentSectionName? item.parentSectionName: "Ninguno"}}</td>
            <td [ngClass]="item.status?'text-success':'text-danger'">{{item.status? 'Activo': 'Inactivo'}}

        </tr>
    </ng-container>
</export-data-to-pdf>
