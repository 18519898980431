import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IncentiveAddNewsComponent } from '@payroll/incentive-payroll/incentive-add-news/incentive-add-news.component';
import { DisapprovePayrollNewsComponent } from '@payroll/news-list/disapprove-payroll-news/disapprove-payroll-news.component';
import { ToastrService } from 'ngx-toastr';
import { FinalPayrollService } from 'src/app/services/final-payroll.service';
import Swal from 'sweetalert2';
import { FinalPayrollAddnewsModalComponent } from '../final-payroll-addnews-modal/final-payroll-addnews-modal.component';
import { FinalPayrollDeleteNewsComponent } from '../final-payroll-delete-news/final-payroll-delete-news.component';
import { PayrollNewService } from 'src/app/services/payroll-new.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
export interface payrollNewsTypesToModal {
  incentive?: boolean;
  final?: boolean;
}
@Component({
  selector: 'app-news-to-approve-modal',
  templateUrl: './news-to-approve-modal.component.html',
  styleUrls: ['./news-to-approve-modal.component.css']
})
export class NewsToApproveModalComponent implements OnInit {
  records: any[] = []
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  paginatedRecords = []
  employee: any = {
    firstName: '',
    lastName: ''
  };
  budgetAreaId: number;
  payrollType: number;
  constructor(
    private srvFinal: FinalPayrollService,
    @Inject(MAT_DIALOG_DATA) public data: {employeeId:number, periodId: number, payrollNewsTypes: payrollNewsTypesToModal, budgetArea: any, payrollType: number, type: number},
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<NewsToApproveModalComponent>,
    private dialog: MatDialog,
    private payrollNew: PayrollNewService,
    private auth: AuthInfoService,
  ) { this.budgetAreaId = this.data?.budgetArea?.budgetAreaId; this.payrollType = this.data?.payrollType}

  ngOnInit(): void {
    this.getNews()
    this.getEmployeeById(this.data.employeeId)
  }
  close(){
    this.dialogRef.close({success:false})
  }
  
  openEditNew(item){
    const {payrollNewsId, endDate, startDate} = item
    if(this.data.payrollNewsTypes.incentive){
      this.dialog.open(IncentiveAddNewsComponent, {
        data: {
          newId: payrollNewsId,
          endDate,
          startDate,
          mode: 'edit',
          modalTitle: 'Modificar',
          employeeId: this.data.employeeId,
          item: item
        }
      }).afterClosed().subscribe((result:any) => {
        if(result.success){
          this.getNews()
        }
      })
    }
    if(this.data.payrollNewsTypes.final){
      this.dialog.open(FinalPayrollAddnewsModalComponent, {
        data: {
          newId: payrollNewsId,
          endDate,
          startDate,
          mode: 'edit',
          modalTitle: 'Modificar',
          employeeId: this.data.employeeId
        }
      }).afterClosed().subscribe((result:any) => {
        if(result.success){
          this.getNews()
        }
      })
    }
    
  }
  
  getEmployeeById(id){
    this.srvFinal.getEmployeeById(id).subscribe((res:any) => {
      if(res.succeded){
        this.employee = res.dataList[0]
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error("Ha ocurrido un error inesperado")
    })
  }
  
  openModalDeleteNew(item: any) {
    const periodId = this.data.periodId
    this.dialog.open(FinalPayrollDeleteNewsComponent, {
      data: {
        item
      },
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.success) {
          this.getNews()
        }
      });
  }
  approve(payroll){
    Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      title: '¿Seguro que desea aprobar esta novedad?',
      
    }).then((result) => {
      if(result.isConfirmed){
        const model = {
          payrollNewsId: payroll.payrollNewsId,
           userId: this.auth.getUserId()
        }
        this.payrollNew.approvePayrollNew(model).subscribe((res:any) => {
          if(res.succeded){
            this.toastr.success("Se ha aprobado la novedad")
            this.getNews()
          }else{
            this.toastr.error(res.errors[0])
          }
        }, err => {
          this.toastr.error("Ha ocurrido un error inesperado")
        })
      }
    })
    
  }
  getNews(){
    this.records = []
    this.srvFinal.getNewsByEmployeeId(this.data.employeeId, this.data.periodId, this.budgetAreaId, this.payrollType, this.data?.type).subscribe((res:any) => {
      if(res.succeded){
        this.records = res.dataList
      }else{
        this.toastr.error(res.errors[0])
      }
    }, err => {
      this.toastr.error("Ha ocurrido un error inesperado")
    })
  }
  
  getPaginatedRecords(event) {
    this.paginatedRecords = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  openModalDisapproveNew(payrollNew: any) {
    this.dialog.open(DisapprovePayrollNewsComponent, {
      data: {
        payrollNew: payrollNew,
      },
    })
      .afterClosed()
      .subscribe((result) => {
        if (result?.disapproved) {
          this.getNews();
        }
      });
  }

}
