<div class="container-fluid">
    <button class="forward-btn btn btn-pill font-weight-bolder btn-shadow btn-warning mb-3 "  (click)="goBack()">
        <i class="flaticon2-left-arrow-1"></i>
        <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
    </button>
    <div class="container-fluid-custom bg-white rounded">
        <div class="ml-5"  id="pdfExp">
            <div class="row">
                <div class="col-12 col-sm-8 border-start">
                    <div class="d-flex flex-wrap px-16 mb-0 mb-sm-10">
                        <div class="logo my-10" >
                            <img src="../../../../assets/media/logos/logo-Escudo-Dominicano.png"  alt="" class="max-w-100px" />
                            <span class="ttlPDF h1 px-2">
                                PRESIDENCIA DE LA REPÚBLICA
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4 border-end" style="position: relative;">
                    <div class="version p-4 d-flex justify-content-between align-items-center flex-column">
                        <!-- <h4 class="text-right ">FO-RH-14</h4>
                        <h4 class="text-right ">Versión 0</h4> -->
                        <div class="col-6 col-sm-6 text-end align-self-end " style="bottom: 0; position: absolute;">
                          <ng-container *ngIf="id">
                              <span class="d-block mt-5 label-item text-nowrap">Nro. Registro:   {{id}}</span>
                          </ng-container>
                      </div>
                    </div>
                </div>
            </div>

            <div class="row border-top">
                <div class="col-12 col-sm-8 border-end border-start">
                    <div class="mt-2 mb-2">
                        <label class="w-100 text-nowrap h6">Institución:</label>
                        <div>
                            <span class="text-wrap h4 titlePdf"> {{employee?.companyName || "N/A" | uppercase}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4 border-end">
                    <div class="mt-2 mb-2">
                        <label class="w-100 text-nowrap h6">Instrumento Legal:</label>
                        <div>
                            <span *ngIf="type == 1" class="text-wrap label-item mr-3">ACCIÓN DE PERSONAL</span>
                            <span *ngIf="type == 1" class="text-wrap label-item">Nro.: S/D</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row border-top bg-pdf">
                <div class="col-12 col-sm-12 text-center pt-1">
                </div>
                <div class="col-12 col-sm-8 text-center py-2">
                    <label class="item-label text-nowrap text-center">Datos Generales</label>
                </div>
                <div class=" col-12 col-sm-4 py-2 mt-1">
                    <span class="text-wrap h6">Fecha:</span>
                    <span *ngIf="type == 1 || type == 4" class="text-wrap ms-75px ms-2 label-item">{{changeDate  || '' |date:'dd MMMM yyyy':'UTC':'es-US'}}</span>
                    <span *ngIf="type == 2 || type == 3" class="text-wrap ms-75px ms-2 label-item">{{employee?.admissionDate || '' |date:'dd MMMM yyyy':'UTC':'es-US'}}</span>
                    <span *ngIf="type == 10" class="text-wrap ms-75px ms-2 label-item">{{vacationsData?.dateStart || '' |date:'dd MMMM yyyy':'UTC':'es-US'}}</span>
                </div>
            </div>
            <div class="row border-top">
                <div class="col-12 col-sm-4 py-1">
                    <label class="item-label">Nombre:</label>
                    <span class="text-wrap  ms-2 label-item label-item"> {{employee?.firstName || ""}}</span>
                </div>
                <div class="col-12 col-sm-4 py-1">
                    <label class="item-label">Apellido:</label>
                    <span class="text-wrap label-item"> {{employee?.lastName || ""}}</span>
                </div>
                <div class="col-12 col-sm-4 py-1 ">
                    <label class="item-label">Cédula:</label>
                    <span class="text-wrap label-item"> {{employee?.personalIdentification || "" | mask: "000-0000000-00" }}</span>
                </div>
            </div>
            <div class="row border-top">
                <div class="col-sm-8 col-12 py-1">
                    <label class="item-label">Cargo que Desempeña:</label>
                    <span class="text-wrap label-item"> {{employee?.positionName || ""}}</span>
                </div>
                <div class="col-sm-4 col-12 py-1">
                    <span class="item-label">Sueldo Base:</span>
                    <span class="text-wrap label-item"> {{employee?.salary || "" | currency}}</span>
                </div>
            </div>
            <div class="row border-top">
                <div class="col-sm-8 col-12 py-1">
                <label class="item-label">Superior Inmediato:</label>
                <span class="text-wrap label-item"> {{employee?.employeeSupervisor || "N/A"}}</span>
                </div>
                <div class="col-sm-4 col-12 py-1">
                    <span class="item-label text-nowrap">Fecha de Ingreso:</span>
                    <span class="text-wrap label-item"> {{employee?.admissionDate || "" |date:'dd MMMM yyyy':'UTC':'es-US'}}</span>
                </div>
            </div>
            <div class="row border-top">
                <div class="col-sm-8 col-12 py-1">
                    <label class="item-label">Organismo Designado:</label>
                    <span class="text-wrap label-item"> {{employee?.departmentName || ""}}</span>
                </div>
                <div class="col-sm-4 col-12 py-1">
                    <span class="item-label text-nowrap">Cuenta Bancaria:</span>
                    <span class="text-wrap label-item"> {{employee?.bankAccount || "N/A"}}</span>
                </div>
            </div>
            <div class="row border-top">
                <div class="col-sm-8 col-12 py-1">
                    <label class="item-label">Desempeña Funciones en:</label>
                    <span class="text-wrap label-item"> {{employee?.departmentName || ""}}</span>
                </div>
                <div *ngIf="employee?.unemployment || !employee?.status" class="col-sm-4 col-12 py-1">
                    <span class="item-label text-nowrap">Fecha desvinculación:</span>
                    <span class="text-wrap label-item"> {{employee?.exitDate || "" |date:'dd MMMM yyyy':'UTC':'es-US'}}</span>
                </div>
            </div>
            <div class="row border-top bg-pdf">
                <div class="col-12 text-center py-1">
                <label class="item-label text-nowrap text-center">Naturaleza de Acción</label>
                </div>
            </div>
            <div class="row border-top nat-action">
                <div class="col-12 label-item-na">
                    <!--Contenido Dinamico-->
                    <app-cambio-sueldo *ngIf="type == 1" [changeDetailId]="id" (sendChangeDate)="getChangeDate($event)" (sendComment)="comment = $event" [employee]="employee"></app-cambio-sueldo>
                    <app-desvinculacion *ngIf="type == 2" [employee]="employee" ></app-desvinculacion>
                    <app-designacion *ngIf="type == 3" [employee]="employee" ></app-designacion>
                    <app-permissions *ngIf="type == 4" [permissionId]="id" (sendChangeDate)="getChangeDate($event)"  [employee]="employee"></app-permissions>
                    <app-vacations  (vacationsData)="vacationsData = $event" *ngIf="type == 10" [vacationId]="id"  [employee]="employee"></app-vacations>
                    <app-license *ngIf="type == 6" [licenseId]="id" ></app-license>
                </div>
            </div>
            <ng-container *ngIf="type === 1 || type === 2 || type === 3 || type === 10; else showborder">
                <div class="row border-top bg-pdf">
                    <div class="col-12 text-center py-2">
                    <label class="item-label text-nowrap text-center">Motivación de Acción</label>
                    </div>
                </div>
                <div class="row border-top mot-action">
                    <!-- Vacaciones -->
                    <div *ngIf="type == 10" class="col-6 col-sm-6">
                        <p class="label-item">ADMVAC - ADM-Vacaciones - {{vacationsData?.dateStart| date:"dd/MM/yyyy"}} - {{vacationsData?.dateEnd| date:"dd/MM/yyyy"}}</p>
                        <p class="label-item">Comentario: {{vacationsData?.comment}}</p>
                        <p class="label-item">{{vacationsData?.vacationInfo}}</p>
                    </div>
                    <!-- Cambios aServidor Público / Employee changes -->
                    <div *ngIf="type == 1" class="col-6 col-sm-6">
                        <label class=" item-label">{{employee?.positionName || ""}}, desde el {{ changeDate || "" |date:'dd MMMM yyyy':'UTC':'es-US'}} </label>
                        <span class="d-block mt-5 item-label">Observaciones: {{comment}}</span>
                    </div>

                    <!-- Desvinculación /  Fire employee-->
                    <div *ngIf="type == 2" class="col-6 col-sm-6">
                        <label class=" item-label">{{employee?.positionName || ""}}, desde el {{employee?.admissionDate || "" |date:'dd MMMM yyyy':'UTC':'es-US'}}, hasta {{employee?.exitDate || "" |date:'dd MMMM yyyy':'UTC':'es-US'}} </label>
                        <p class="d-block mt-5 item-label">Motivo: {{employee?.exitReasonDescription}}</p>
                        <span class="d-block mt-5 item-label">Observaciones:  {{comment}}</span>
                    </div>

                    <!-- Designación / Hire employee -->
                    <div *ngIf="type == 3" class="col-6 col-sm-6">
                        <label class=" item-label">{{employee?.positionName || ""}}, a partir {{employee?.admissionDate || "" |date:'dd MMMM yyyy':'UTC':'es-US'}} </label>
                        <span class="d-block mt-5 item-label">Observaciones: {{comment}}</span>
                    </div>
                </div>
            </ng-container>
            <ng-template #showborder>
                <div class="row border-top bg-pdf p-2 mb-5">
                    <div class="col-12 text-center py-2">
                    <label class="item-label text-nowrap text-center"></label>
                    </div>
                </div>
            </ng-template>

            <div class="row border-top">
                <div class="col-4 col-sm-4 border-start border-end">
                    <span class=" p-2 ms-5 d-block item-label firmas">
                        Elaborado por:
                    </span>
                    <div class="firma text-center mt-25"></div>
                </div>
                <div class="col-4 col-sm-4 border-end">
                    <span class=" p-2  ms-5 d-block item-label firmas">
                        Firma Superior Inmediato:
                    </span>
                    <div class="firma text-center mt-25"></div>

                </div>
                <div class="col-4 col-sm-4 border-end">
                    <span class=" p-2 ms-5 d-block item-label firmas">
                        Firma Responsable de RH:
                    </span>
                    <div class="firma text-center mt-25"></div>

                </div>
                <div class="col-4 col-sm-8 border-top border-end border-start">
                    <span class=" p-2 ms-5 d-block item-label firmas">
                        Firma Responsable Institución:
                    </span>
                    <div class="firma text-center mt-25"></div>

                </div>
                <div class="col-4 col-sm-4 border-top border-end">
                    <span class=" p-2 ms-5 d-block item-label firmas">
                        Firma Responsable del Área:
                    </span>
                    <div class="firma text-center mt-25"></div>

                </div>
            </div>
        </div>
    </div>
</div>
<button title="Imprimir" onclick="print()" class="btn btn-icon btn-dark btn-circle font-size-h1 btn-shadow float-btn">
    <i class="flaticon2-printer font-size-h1"></i>
  </button>
  <script>
    let print = () => {
      let pdf = document.getElementById("pdfExp");
      pdf.contentWindow.focus();
      pdf.contentWindow.print();
    };
  </script>
