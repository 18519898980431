<div class="modal-header border-0 dv-table-header-1 bg-primary"  mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span> Registrar
        Historial de Cambios</h2>

</div>
<div class="mat-dialog-content-custom">
    <div class="alert alert-danger" role="alert">
        Estimado usuario, le recordamos que los cambios realizados en esta opción no ejecutan ninguna modificación a la
        situación actual del Servidor Público. Estos cambios son realizados exclusivamente para que
        queden registrados en el historial de cambios del Servidor Público en cada fecha de efectividad.
    </div>
    <div class="card-body-custom mt-0 pt-0">
        <div class="card-header mt-3">
            <form [formGroup]="actionChangeForm" class="w-100">
                <div class="row gutter-b align-items-center">
                    <div class="col-lg-2 d-flex align-items-center justify-content-center">
                        <span *ngIf="loadingPic" class="d-flex flex-column align-items-center">
                            <img src="assets/logos/logo-sigei-100-02.png" alt="loading" width="70px" />
                            <strong class="text-danger font-size-h3">Cargando...</strong>
                        </span>
                        <div *ngIf="!loadingPic">
                            <div *ngIf="photoUrl == ''">
                                <div class="symbol symbol-150 d250 my-4 max-w-250px">
                                    <img alt="Pic" src="assets/images/no-user.png" />
                                </div>
                            </div>
                            <div *ngIf="photoUrl != ''">
                                <div class="symbol symbol-150 d250 my-4 max-w-250px">
                                    <img alt="Pic" [src]="photoUrl"
                                        onerror="this.error=null; this.src='assets/images/no-user.png'" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Código del Servidor Público</label>
                                <h3>
                                    {{personSelected?.employNumber}}
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Servidor Público</label>
                                <h3>
                                    {{personSelected?.name}}
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Identificación</label>
                                <h3 *ngIf="personSelected?.typeIdentificationId == 1">
                                    {{personSelected?.personalIdentification
                                    | mask: '000-0000000-0'}}</h3>
                                <h3 *ngIf="personSelected?.typeIdentificationId != 1">
                                    {{personSelected?.personalIdentification}}</h3>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Teléfono</label>
                                <h3>
                                    {{(personSelected?.phoneNumber ? personSelected?.phoneNumber : "N/A") |
                                    mask:personSelected?.phoneNumber ?"(000)-000-0000":"N/A"}}
                                </h3>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Unidad Organizativa</label>
                                <h3>
                                    {{personSelected?.departmentName}}
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-3">

                            <div class="form-group">
                                <label class="mb-0">Cargo</label>
                                <h3>
                                    {{personSelected?.positionName}}
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Salario</label>
                                <h3>
                                    {{personSelected?.salary | currency:"RD$"}}
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Tipo de Servidor Público</label>
                                <h3>
                                    {{personSelected?.employeeType}}
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Fecha Efectiva</label>
                                <h3>
                                    {{personSelected?.effectiveDate | date:"dd/MM/yyyy"}}
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Fecha Ingreso</label>
                                <h3>
                                    {{personSelected?.admissionDate | date:"dd/MM/yyyy"}}
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Correo Institucional</label>
                                <h3>
                                    {{personSelected?.institionalEmail}}
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Estatus</label>
                                <h3 *ngIf="personSelected?.status" class="text-success">
                                    Activo
                                </h3>
                                <h3 *ngIf="personSelected?.status == false" class="text-danger">
                                    Desvinculado
                                </h3>
                                <h3 *ngIf="personSelected?.status == undefined" class="text-danger">

                                </h3>
                            </div>
                        </div>

                        <div class="col-md-3" *ngIf="currentEmployeeCondition?.employeeId">
                            <div class="form-group">
                                <label class="mb-0">Condición Actual</label>
                                <h3 lass="text-success">
                                    {{currentEmployeeCondition?.personalActionsType}}
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Nivel Académico</label>
                                <h3 lass="text-success">
                                    {{personSelected?.academicLevel === undefined ? '' :
                                    (personSelected?.academicLevel == null ? 'N/A' : personSelected?.academicLevel) }}
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="mb-0">Grupo Ocupacional</label>
                                <h3 lass="text-success">
                                    {{personSelected?.occupationalGroup === undefined ? '' :
                                    (personSelected?.occupationalGroup == null ? 'N/A' :
                                    personSelected?.occupationalGroup)
                                    }}
                                </h3>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>

        <div class="card-body">

            <ul class="nav nav-tabs border-tabs-primary" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="cambio-empleado-tab" data-toggle="tab" href="#cambio-empleado">
                        <span class="nav-icon mr-5">
                            <i class="flaticon-proceso icon-3x"></i>
                        </span>
                        <span class="nav-text">
                            <h3>Acciones Permanentes</h3>
                        </span>
                    </a>
                </li>
            </ul>
            <div class="tab-content mt-5">
                <div class="tab-pane fade active show py-4" id="cambio-empleado" role="tabpanel"
                    aria-labelledby="cambio-empleado-tab">
                    <div class="card-body">
                        <form [formGroup]="actionChangeForm" class="w-100">
                            <div class="row gutter-b">
                                <div class="col">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-3 form-group">
                                                    <label>Cambios <span class="text-danger">*</span></label>
                                                    <ngx-select-dropdown formControlName='parameter'
                                                        (change)='fillCurrentData($event.value.ocode)'
                                                        [options]='fields' [config]='fieldsConfig' class="flex-1">
                                                    </ngx-select-dropdown>
                                                    <span class="text-danger"
                                                        *ngIf="formIsInvalid && actionChangeForm.controls['parameter']?.errors?.required">Este
                                                        campo es requerido</span>
                                                </div>
                                                <div class="col-md-3 form-group">
                                                    <label>Datos Actuales <span class="text-danger">*</span></label>
                                                    <input formControlName='currentValue'
                                                        (change)='changeCurrentData($event?.target?.value)'
                                                        class="form-control form-control-lg"
                                                        *ngIf="fieldType == 'text'">

                                                    <input currencyMask [options]="{precision:2}" placeholder="$0.00"
                                                        (keyup)="changeCurrentData($event?.target?.value)"
                                                        *ngIf="fieldType == 'number'" formControlName='currentValue'
                                                        class="form-control form-control-lg">

                                                    <ngx-select-dropdown formControlName='currentValue'
                                                        [ngClass]="{'d-none': (fieldType!=='drop' || fieldType !== 'drop-additional'), 'd-inline': (fieldType=='drop' || fieldType == 'drop-additional')}"
                                                        class="flex-1" [options]='options'
                                                        (change)='changeCurrentData($event.value)' [config]='config'>
                                                    </ngx-select-dropdown>

                                                    <input type="date"
                                                        (change)='changeCurrentData($event?.target?.value)'
                                                        *ngIf="fieldType == 'date'" formControlName='currentValue'
                                                        class="form-control form-control-lg">

                                                    <span class="text-danger"
                                                        *ngIf="formIsInvalid && actionChangeForm.controls['currentValue']?.errors?.required">Este
                                                        campo es requerido</span>
                                                </div>
                                                <div class="col-md-3 form-group">
                                                    <label>Datos Nuevos <span class="text-danger">*</span></label>
                                                    <ngx-select-dropdown formControlName='newData'
                                                        [ngClass]="{'d-none': (fieldType!=='drop' || fieldType !== 'drop-additional'), 'd-inline': (fieldType=='drop' || fieldType == 'drop-additional')}"
                                                        class="flex-1" [options]='options'
                                                        (change)='fillNewData($event.value)' [config]='config'>
                                                    </ngx-select-dropdown>

                                                    <input currencyMask [options]="{precision:2}" placeholder="$0.00"
                                                        *ngIf="fieldType == 'number'" formControlName='newData'
                                                        class="form-control form-control-lg">

                                                    <input type="date" (change)='fillNewData($event.value)'
                                                        *ngIf="fieldType == 'date'" formControlName='newData'
                                                        class="form-control form-control-lg">
                                                    <span class="text-danger"
                                                        *ngIf="formIsInvalid && actionChangeForm.controls['newData']?.errors?.required">Este
                                                        campo es requerido</span>
                                                    <span class="text-danger"
                                                        *ngIf="actionChangeForm.errors?.invalidValue">Los valores no
                                                        pueden
                                                        ser los mismos</span>
                                                    <br>
                                                </div>
                                                <div class="col-md-3 form-group" *ngIf=" fieldType=='drop-additional'">
                                                    <label>{{additionalValueFieldName}} <span
                                                            class="text-danger">*</span></label>
                                                    <ngx-select-dropdown formControlName='additionalValueObject'
                                                        class="flex-1" [options]='additionalValueList'
                                                        (change)='changeAdditionalValue($event.value)'
                                                        [config]='additionalValueConfig'>
                                                    </ngx-select-dropdown>
                                                </div>
                                                <div class="col-md-3 form-group">
                                                    <label>Motivo <span class="text-danger">*</span></label>
                                                    <ngx-select-dropdown formControlName='motive' [options]='motives'
                                                        [config]='motivesConfig' class="flex-1">
                                                    </ngx-select-dropdown>
                                                    <span class="text-danger"
                                                        *ngIf="formIsInvalid && actionChangeForm.controls['motive']?.errors?.required">Este
                                                        campo es requerido</span>

                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>Fecha Efectividad <span
                                                                class="text-danger">*</span></label>
                                                        <input type="date" formControlName="effectiveDate"
                                                            class="form-control">
                                                    </div>
                                                    <span class="text-danger"
                                                        *ngIf="formIsInvalid && actionChangeForm.controls['effectiveDate']?.errors?.required">Este
                                                        campo es requerido</span>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Comentario</label>
                                                        <textarea formControlName="comment" class="form-control"
                                                            rows="6"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>Evidencia <span class="text-danger">*</span></label>
                                                        <div>
                                                            <uppy-file 
                                                                #uppyFile
                                                                cardBody="''"
                                                                previewDefaultImage="assets/images/cv.jpg"
                                                                classPreviewDefaultImage="symbol"
                                                                targetClass="evidenceObId"
                                                                showFileModalTitle="Evidencia"
                                                                [isDisabled]="!this.personSelected?.status"
                                                                [width]="350"
                                                                [height]="138"
                                                                [showDocumentoAfterUploaded]="false"
                                                                [fileForEditting]="guiID"
                                                                [allowedFileTypes]="['application/pdf']"
                                                                (fileEmitter)="setEvidence($event)">
                                                            </uppy-file>
                                                        </div>

                                                        <span style="position: absolute;"
                                                            *ngIf="this.actionChangeForm.controls['evidence']?.errors?.required && formIsInvalid"
                                                            class="text-danger">Este campo es requerido</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div class="col-md-auto d-flex justify-content-center align-items-center">
                                    <button (click)="addChangeDetails()"
                                        [disabled]="!isSalaryCorrect || actionChangeForm.invalid || actionChangeForm.disabled"
                                        class="btn btn-pill font-weight-bolder btn-shadow btn-success">
                                        <i class="flaticon-disco-flexible"></i>
                                        <span> Aplicar cambio </span>
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div class="row gutter-b">
                            <div class="table-responsive">
                                <table class="table atbl table-vertical-center">
                                    <thead>
                                        <tr class="bg-primary">
                                            <!-- <th class="text-center"></th> -->
                                            <th class="text-left">Tipo de Cambio</th>
                                            <th class="text-left">Datos Antiguos</th>
                                            <th class="text-left">Datos Nuevos</th>
                                            <th class="text-left">Fecha Efectividad</th>
                                            <th class="text-left">Evidencia</th>
                                            <th class="text-left">Motivo</th>
                                            <th class="text-center">Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let detail of newChangesRecords; let i =index">
                                            <!-- <td class="text-center"><input type="checkbox" name="" id=""></td> -->
                                            <td class="text-left">{{detail.parameterName}}</td>
                                            <td class="text-left" *ngIf="detail?.valueAfterNumeric">
                                                {{detail.valueBefore
                                                | currency}}</td>
                                            <td class="text-left" *ngIf="!detail?.valueAfterNumeric && !isDate(detail?.valueAfter)">
                                                {{detail.valueBefore}}
                                            </td>
                                            <td class="text-left" *ngIf="isDate(detail?.valueAfter)">
                                                {{detail.valueBefore | date:'dd/MM/yyyy'}}
                                            </td>
                                            <td class="text-left" *ngIf="detail.valueAfterNumeric">{{detail.valueAfter |
                                                currency}}</td>
                                            <td class="text-left"
                                                *ngIf="!detail?.valueAfterNumeric && !isDate(detail?.valueAfter)">
                                                <ng-container
                                                    *ngIf="detail?.additionalValueName else noAdditionalValueName">
                                                    {{detail.valueAfter}} | {{detail.additionalValueName}}
                                                </ng-container>
                                                <ng-template #noAdditionalValueName>
                                                    {{detail.valueAfter}}
                                                </ng-template>
                                            </td>
                                            <td class="" *ngIf="isDate(detail?.valueAfter)">
                                                {{detail?.valueAfter
                                                | date:'dd/MM/yyyy'}}</td>

                                            <td class="text-left">
                                                {{detail.effectiveDate |date:'dd MMMM yyyy':'UTC':'es-US'}}
                                            </td>
                                            <td>
                                                <a *ngIf="detail.evidence"
                                                    (click)="viewPdf(detail.evidence)" 
                                                    data-toggle="tooltip" title="Evidencia"
                                                    class="btn btn-light-youtube font-weight-bold mb-3">
                                                    <i class="flaticon-pdf-file icon-2x"></i>
                                                </a>
                                                <p *ngIf="!detail.evidence">N/A</p>
                                            </td>
                                            <td class="text-left">{{detail.reason}}</td>
                                            <td class="text-center w-100px">
                                                <button *ngIf="!inViewDetails"
                                                    class="btn btn-sm btn-light-danger btn-icon"
                                                    (click)="remove(detail, i)">
                                                    <i class="flaticon-delete"></i>
                                                </button>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <div>
                                    <el-caption #paginator [originalRecordsLength]="newChangesRecordsOriginal.length"
                                        [listedRecordsLength]="newChangesRecords.length"
                                        [registerQuantity]="newChanges.length" [records]="newChanges"
                                        (onChage)="getPaginatedRecordsDetails($event)"></el-caption>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!isEdittingEmployeeChange">
                            <div class="form-group d-flex justify-content-end align-items-center ">
                                <button [disabled]="newChanges.length < 1 || inViewDetails" (click)="setChangeHeader()"
                                    class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
                                    <i class="flaticon-disco-flexible"></i>
                                    <span> Guardar </span>
                                </button>
                            </div>
                        </div>

                        <div class="row gutter-b">
                            <h3 class="text-primary">Historial de Cambios Guardados</h3>
                            <div class="table-responsive">
                                <table class="table atbl table-vertical-center">
                                    <thead>
                                        <tr class="bg-primary">
                                            <!-- <th class="text-center"></th> -->
                                            <th class="text-left">Secuencia</th>
                                            <th class="text-left">Tipo de Cambio</th>
                                            <th class="text-left">Dato Anterior</th>
                                            <th class="text-left">Dato Propuesto</th>
                                            <th class="text-left">Motivo</th>
                                            <th class="text-left">Cambio Adicional</th>
                                            <th class="text-left">Estatus</th>
                                            <th class="text-left">Aprobado Por</th>
                                            <th class="text-left">Fecha Efectividad</th>
                                            <th class="text-left">Fecha Aprobación</th>
                                            <th class="text-center">Evidencia</th>
                                            <th class="text-center">Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let changeDetail of changeDeyailsListRecords; let i = index">
                                            <td class="">{{changeDetail?.employeeChangeDetailsId || 0}}</td>
                                            <td class="">{{changeDetail?.parameterName || "N/A"}}</td>

                                            <td class="" *ngIf="!changeDetail?.valueAfterNumeric && !isDate(changeDetail?.valueAfter)">
                                                {{changeDetail?.valueBefore || "N/A"}}</td>
                                            <td class="" *ngIf="changeDetail?.valueAfterNumeric">{{changeDetail?.valueBefore
                                                || "N/A" | currency}}</td>
                                            <td class="" *ngIf="isDate(changeDetail?.valueAfter)">{{changeDetail?.valueBefore
                                                || "N/A" | date:'dd/MM/yyyy'}}</td>
                                            <td class=""
                                                *ngIf="!changeDetail?.valueAfterNumeric && !isDate(changeDetail?.valueAfter)">
                                                {{changeDetail?.valueAfter
                                                || "N/A"}}</td>
                                            <td class="" *ngIf="changeDetail?.valueAfterNumeric">{{changeDetail?.valueAfter
                                                || "N/A" | currency}}</td>
    
                                            <td class="" *ngIf="isDate(changeDetail?.valueAfter)">{{changeDetail?.valueAfter
                                                || "N/A" | date:'dd/MM/yyyy'}}</td>

                                            <td class="">{{changeDetail?.reason || "N/A" }}</td>
                                            <td class="">{{changeDetail?.additionalValueName || "N/A" }}</td>
                                            <td class="w-100px text-center">
                                                <span style="width: 137px;">
                                                    <span *ngIf="changeDetail.condition == 1"
                                                        class="font-weight-bold text-info"><span
                                                            class="label label-info label-dot mr-2"></span>{{changeDetail.conditionName
                                                        }}</span>
                                                    <span *ngIf="changeDetail.condition == 2"
                                                        class="font-weight-bold text-success"><span
                                                            class="label label-success label-dot mr-2"></span>{{changeDetail.conditionName
                                                        }}</span>
                                                    <span *ngIf="changeDetail.condition == 3"
                                                        class="font-weight-bold text-danger"><span
                                                            class="label label-danger label-dot mr-2"></span>{{changeDetail.conditionName
                                                        }}</span>
                                                    <span *ngIf="changeDetail.condition == 5"
                                                        class="font-weight-bold text-success"><span
                                                            class="label label-success label-dot mr-2"></span>{{changeDetail.conditionName
                                                        }}</span>
                                                    <span *ngIf="changeDetail.condition == 7"
                                                    class="font-weight-bold text-success"><span
                                                        class="label label-success label-dot mr-2"></span>{{changeDetail.conditionName
                                                    }}</span>
                                                    <span *ngIf="changeDetail.condition == 9"
                                                    class="font-weight-bold text-info"><span
                                                        class="label label-info label-dot mr-2"></span>{{changeDetail.conditionName
                                                    }}</span>
                                                    <span *ngIf="changeDetail.condition == 10"
                                                    class="font-weight-bold text-danger"><span
                                                        class="label label-danger label-dot mr-2"></span>{{changeDetail.conditionName
                                                    }}</span>
                                                </span>
                                            </td>
                                            <td class="">{{changeDetail?.employeeApproved || "N/A"}}</td>
                                            <td class="">{{changeDetail?.effectiveDate |date:'dd/MM/yyyy':'UTC':'es-US'}}
                                            </td>
                                            <td class="">{{changeDetail?.dateApproved |date:'dd/MM/yyyy':'UTC':'es-US'}}
                                            </td>
                                            <td class="text-center">
                                                <a *ngIf="changeDetail?.evidence" (click)='openGuID(changeDetail?.evidence)' data-toggle="tooltip"
                                                    title="Evidencia"
                                                    class="btn btn-icon  btn-light-youtube btn-sm m-1">
                                                    <i class="flaticon-pdf-file icon-2x"></i>
                                                </a>
                                                <p *ngIf="!changeDetail?.evidence">N/A</p>
                                            </td>
                                            <td class="actionsBtn">
                                               
                                                    <button *ngIf="changeDetail.parameterChangeId === 2"
                                                        class="btn btn-icon btn-light-info btn-sm m-1" (click)="openReport(changeDetail)">
                                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                                                    </button>
                                               
                                               
                                                    <a *ngIf="changeDetail.parameterChangeId === 10"
                                                        class="btn btn-icon btn-light-info btn-sm m-1" (click)="openReport(changeDetail)">
                                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                                                    </a>
                                               
                                               
                                                    <a *ngIf="(changeDetail.parameterChangeId === 4 ||
                                                        changeDetail.parameterChangeId === 7 || changeDetail.parameterChangeId === 8
                                                        || changeDetail.parameterChangeId === 12)"
                                                        (click)="openReport(changeDetail)" class="btn btn-sm btn-light-info btn-icon m-1">
                                                        <i class="flaticon-ver" data-toggle="tooltip" title="Ver"></i>
                                                    </a>
                                               
                                               
                
                                                    <a *ngIf="changeDetail.parameterChangeId === 9"
                                                        [routerLink]="['/pdf-action-change-changeDetail-exservices', changeDetail.personalActionId, changeDetail.changeDetailId ]"
                                                        class="btn btn-sm btn-light-info btn-icon m-1">
                                                        <i class="flaticon-ver" data-toggle="tooltip" title="Ver"></i>
                                                    </a>
                
                                               
                                               
                
                                                    <a *ngIf="changeDetail.parameterChangeId === 1"
                                                        class="btn btn-icon btn-light-info btn-sm m-1">
                                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"
                                                            (click)="openReport(changeDetail)"></i>
                                                    </a>
                
                                               
                                               
                
                
                                                    <!-- <a *ngIf="getPersonnelActionForPDF(changeDetail.parameterChangeId,'/pdf-preview-action-change')"
                                                        class="btn btn-icon btn-light-info btn-sm m-1"
                                                        [routerLink]="['/pdf-preview-action-change',changeDetail.personalActionId]">
                                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                                                        </a> -->
                
                
                                               
                                               
                
                                                    <a *ngIf="changeDetail.parameterChangeId === 11"
                                                        class="btn btn-icon btn-light-info btn-sm m-1" (click)="openReport(changeDetail)">
                                                        <i class="flaticon-ver icon-2x" data-toggle="tooltip" title="Ver PDF"></i>
                                                    </a>
                
                
                                               
                
                
                
                
                                                <!-- <a *ngIf="privilege?.personnelAction?.value"
                                                    [routerLink]="['/action-change-employee',employee.employeeId, 1]"
                                                    class="btn btn-icon btn-light-success btn-sm m-1" data-toggle="tooltip"
                                                    title="Acciones del Personal">
                                                    <i class="flaticon-colaboracion icon-2x"></i>
                                                </a> -->
                
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <div>
                                    <el-caption #paginator
                                        [originalRecordsLength]="changeDeyailsListRecordsOriginal?.length"
                                        [listedRecordsLength]="changeDeyailsListRecords?.length"
                                        [registerQuantity]="changeDetailsList?.length" [records]="changeDetailsList"
                                        (onChage)="getPaginatedRecordsHeader($event)"></el-caption>
                                </div>
                            </div>
                        </div>
                        <div class="row gutter-b">
                            <h3 class="text-primary">Designaciones</h3>
                            <div class="table-responsive">
                                <table class="table atbl table-vertical-center">
                                    <thead>
                                        <tr class="bg-primary">
                                            <!-- <th class="text-center"></th> -->
                                            <th class="text-left">Secuencia</th>
                                            <th class="text-left">Clasificación</th>
                                            <th class="text-left">Fecha inicio</th>
                                            <th class="text-left">Fecha Efectiva</th>
                                            <th class="text-left">Género</th>
                                            <th class="text-left">Unidad Organizativa</th>
                                            <th class="text-left">Comentario</th>
                                            <th class="text-left">Estatus</th>
                                            <th class="text-center">Acción</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of designationsPaginated; let i = index">
                                            <td class="">{{item?.actionsEmployeeId || 0}}</td>
                                            <td class="">{{item?.typeActions}}</td>
                                            <td class="">{{item?.startDate |date:'dd MMMM yyyy':'UTC':'es-US'}}</td>
                                            <td class="">{{item?.efectiveDay |date:'dd MMMM yyyy':'UTC':'es-US'}}</td>
                                            <td class="">{{item?.sex}}</td>
                                            <td class="">{{item?.departmentName}}</td>
                                            <td class="">{{item?.commentary}}</td>
                                            <td class="text-left">
                                                <ng-container *ngIf="!item?.status; else conditions">
                                                    <span class="font-weight-bold text-danger"> {{item?.conditionId === 10 ? 'Anulado' : 'Inactivo'}} </span>
                                                </ng-container>
                                                <ng-template [ngSwitch]="item?.conditionId" #conditions>
                                                    <span *ngSwitchCase="2" class="font-weight-bold text-success">Aprobado</span>
                                                    <span *ngSwitchCase="3" class="font-weight-bold text-danger">Rechazado</span>
                                                    <span *ngSwitchCase="6" class="font-weight-bold text-info">En Espera de Aprobación</span>
                                                    <span *ngSwitchCase="10" class="font-weight-bold text-danger">Anulado</span>
                                                    <span *ngSwitchDefault class="font-weight-bold ">
                                                            <span class="mr-2">N/A</span>
                                                    </span>
                                                </ng-template>
                                            </td>
                                            <td class="actionsBtn">
                                                <a class="btn btn-icon btn-light-info btn-sm m-1"
                                                (click)="exportDesignation(item)">
                                                <i class="flaticon-ver icon-2x" data-toggle="tooltip"
                                                    title="Ver PDF"></i>
                                            </a>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <div>
                                    <el-caption #paginator [originalRecordsLength]="designations?.length"
                                        [listedRecordsLength]="designationsPaginated?.length"
                                        [registerQuantity]="designations?.length" [records]="designations"
                                        (onChage)="getPaginatedDesignations($event)"></el-caption>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group d-flex justify-content-end align-items-center ">
                                <!-- <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary d-flex mr-2">
                                    <i class="flaticon-checked"></i>
                                    <span>Aprobar</span>
                                </button> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <el-modal target="documentxEvidence" title="Evidencia">
            <iframe [src]='transform(file.document)' width="100%" height="600px"></iframe>
        </el-modal>
    </div>
</div>


<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="this.dialogRef.close()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>