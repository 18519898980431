<div class="modal-header border-0 dv-table-header-1 bg-primary">
<h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Carga Masiva</h2>
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>

</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <div class="row">
            <div class="form-group col-md-4" [formGroup]="form">
                <label class="d-block">Cargar Documento<span class="text-danger text-monospace legend"></span></label>
                <div class="dropzone dropzone-default dz-clickable" id="kt_dropzone_1">
                    <div class="dropzone-msg dz-message needsclick">
                        <input (click)="onClick()" formControlName="file" (change)="fileUpload($event)" 
                        class="form-control form-control-sm" 
                        type="file" id="file-upload" name="fileUpload" accept=".xls,.xlsx">
                    </div>
                </div>
            </div>
            <div class="form-group col-md-3" *ngIf="this.dataUploadedListOriginal?.length > 0">
                <label class="text-primary">
                    Estatus
                </label>
                <ng-multiselect-dropdown [placeholder]="'Seleccionar Estatus'" [settings]="dropdownSettings"
                    [data]="statusOptions" [(ngModel)]="selectedItems" (onDeSelectAll)="onDeSelectAll()"
                    (onDeSelect)="onItemDeSelect($event)" (onSelect)="onItemSelect($event)"
                    (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-left">Documento de Identidad</th>
                                <th class="text-left">Servidor Público</th>
                                <th class="text-left">Detalle</th>
                                <th class="actionsBtn">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of paginatedDataUploaded">
                                <td class="text-left">
                                    <ng-container *ngIf="item.typeIdentificationId == 1; else noCedula">
                                        {{item?.personalIdentification | mask: '000-0000000-0'}}
                                    </ng-container>
                                    <ng-template #noCedula>                          
                                        {{item.personalIdentification}}
                                    </ng-template>
                                </td>
                                <td class="text-left">{{item?.firstName}} {{item?.lastName}}</td>
                                <td class="text-left">
                                    <ng-container *ngIf="item.details; else noDetail">
                                        <span class="font-weight-bold text-danger">
                                            {{item.details}}
                                        </span>
                                    </ng-container>
                                    <ng-template #noDetail>                          
                                        <span class="font-weight-bold text-success">
                                            Listo para procesar
                                        </span>
                                    </ng-template>
                                </td>
                                <td class="actionsBtn">
                                    <button data-toggle="tooltip" *ngIf="item?.details" (click)="remove(item)"
                                        class="btn btn-sm btn-light-danger btn-icon  m-1" data-original-title="Remover">
                                        <i class="flaticon-delete"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <el-caption #paginator [originalRecordsLength]="dataUploaded?.length" [listedRecordsLength]="paginatedDataUploaded?.length"
                        [registerQuantity]="filteredList?.enable && filteredList.records?.length>0 ? filteredList.records?.length :dataUploaded?.length"
                        [records]="filteredList?.enable ? filteredList?.records : dataUploaded"
                        (onChage)="getPaginatedRecords($event)"></el-caption>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cancelar
    </button>
    <button [disabled]="dataUploadedListOriginal.length === 0 || !isValidData" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
        (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Procesar</span>
    </button>
</div>
