import { Injectable } from '@angular/core';
import { AuthInfoService } from './auth-info.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  readonly api_url = environment.api_url
  readonly api_core_sigei = environment.api_core_sigei


  constructor(
    private auth: AuthInfoService,
    private http: HttpClient,
  ) { }

  getReports(companyId = this.auth.getCompanyId()){
    return this.http.get(`${this.api_core_sigei}/Report?ReportId=2&companyId=${companyId}&type=2`)
  }

  getReportRegistationControl(){
    return this.http.get(`${this.api_url}/RegistrationControlReports`)
  }

}
