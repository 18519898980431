import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PlanningRrhhService } from 'src/app/services/planning-rrhh.service';

@Component({
  selector: 'app-return-motives-modal',
  templateUrl: './return-motives-modal.component.html',
  styleUrls: ['./return-motives-modal.component.css']
})
export class ReturnMotivesModalComponent implements OnInit {

  motiveList = []
  motiveListPaginated = []
  constructor(
    public dialogRef: MatDialogRef<ReturnMotivesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authInfo:  AuthInfoService,
    private _planningService: PlanningRrhhService,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this._planningService.getPlanningRejectionReason(this.data.item.planningHeaderId, this.data.item.companyId).subscribe({
      next: (res:any) => {
        if(res.succeded == false){
          this.toastr.error(res.errors[0])
          return
        }
        this.motiveList = res.dataList
      }, error: (error: any) => {

      }
    })
  }

  close(success = false){
    this.dialogRef.close({success})
  }
  
  getPaginatedRecords(event) {
    this.motiveListPaginated = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

}
