import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ParameterControl } from '../shared/models/parameter-control.model';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';
import { AuthInfoService } from './auth-info.service';

@Injectable({
  providedIn: 'root',
})
export class ParameterControlService {
  private readonly url = environment.api_core_url + '/ParameterControl';
  constructor(private _http: HttpClient, private authInfo: AuthInfoService) {}

  getParameters(KeyWord: string) {
      return this._http.get<ResponseModel<ParameterControl>>(`${this.url}/GetKeyWord?Parameter=${KeyWord}&CompanyCode=${this.authInfo.getCompanyId()}&Status=true`);
  }
}
