import { Component, Input } from '@angular/core';

@Component({
  selector: 'el-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() private number: number;
  @Input() color = 'primary';
  @Input() customClass: string;
  constructor() {}

  get addColor(): string {
    return `label-${this.color}`;
  }

  get formatNotificationCounter(): string | number {
    return this.number >= 99 ? '+99' : this.number;
  }
}
