// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  logo_long_name: 'ITLA',
  logo_short_name: 'ITLA',
  copy_right: `© ITLA`,
  copyright_footer: `Copyright © Instituto Tecnológico de las Américas 2022`,
  toastTimeOut: 10000 /*Diez segundos 0:10*/,
  years: `2020 - ${new Date().getFullYear()}`,
  LobbyUrl: 'https://polite-mushroom-0058a6a0f.1.azurestaticapps.net/',
  //api_url: 'https://localhost:5001/api',
  api_url: 'https://xhrm-back.azurewebsites.net/api',
  api_map: 'https://xmap-back.azurewebsites.net/api',
  api_core_url: 'https://xcore-back.azurewebsites.net/api',
  //api_core_url: 'http://localhost:8045/api',
  api_core_sigei: 'https://xcore-sigei-back.azurewebsites.net/api',
  api_doc: 'https://xdms-back.azurewebsites.net/api',
  //Api
  register_per_page: 10,
  maxFileSize: 10485760,//10mb
  url: 'https://polite-mushroom-0058a6a0f.1.azurestaticapps.net',
  configUrl: 'https://happy-coast-0eb40e40f.1.azurestaticapps.net',
  api_lobby: 'https://xcore-sigei-back.azurewebsites.net/api',
  UsernameFirmaGob: "sigei",
  PasswordFirmaGob: "VEXRVEMUTDEMJA1G1I08DN4M7IQ6ALEQPGXZIQID",
  reportUrl: 'https://reportes-sigei-dev.azurewebsites.net/RPVreportPage.aspx',
  application_version: '1.0.0.0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
