<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon">
                <i class="flaticon-colaboracion icon-3x text-danger"></i>
            </span>
            <h2 class="d-flex flex-column text-primary">
                Acciones del Personal
            </h2>
            <!-- <h6 class="center font-size-h6 font-weight-bold label label-inline label-light-danger ml-5 px-5">En Desarrollo</h6> -->
        </div>
        <div class="card-toolbar">
            <a  (click)="goBack()" class="btn btn-pill font-weight-bolder btn-shadow btn-warning goBackButton">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
            </a>
        </div>
    </div>

    <div class="card-header mt-3">
        <form [formGroup]="actionChangeForm" class="w-100">
            <div class="row gutter-b align-items-center">
                <div class="col-lg-2 d-flex align-items-center justify-content-center">
                    <span *ngIf="loadingPic" class="d-flex flex-column align-items-center">
                        <img src="assets/logos/logo-sigei-100-02.png" alt="loading" width="70px" />
                        <strong class="text-danger font-size-h3">Cargando...</strong>
                    </span>
                    <div *ngIf="!loadingPic">
                        <div *ngIf="photoUrl == ''">
                            <div class="symbol symbol-150 d250 my-4 max-w-250px">
                                <img alt="Pic" src="assets/images/no-user.png" />
                            </div>
                        </div>
                        <div *ngIf="photoUrl != ''">
                            <div class="symbol symbol-150 d250 my-4 max-w-250px">
                                <img alt="Pic" [src]="photoUrl"
                                    onerror="this.error=null; this.src='assets/images/no-user.png'" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-10 row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Código del Servidor Público</label>
                            <h3>
                                {{personSelected?.employNumber}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Servidor Público</label>
                            <h3>
                                {{personSelected?.name}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Identificación</label>
                            <h3 *ngIf="personSelected?.typeIdentificationId == 1">
                                {{personSelected?.personalIdentification
                                | mask: '000-0000000-0'}}</h3>
                            <h3 *ngIf="personSelected?.typeIdentificationId != 1">
                                {{personSelected?.personalIdentification}}</h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Teléfono</label>
                            <h3>
                                {{(personSelected?.phoneNumber ? personSelected?.phoneNumber : "N/A") |
                                mask:personSelected?.phoneNumber ?"(000)-000-0000":"N/A"}}
                            </h3>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Unidad Organizativa</label>
                            <h3>
                                {{personSelected?.departmentName}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">

                        <div class="form-group">
                            <label class="mb-0">Cargo</label>
                            <h3>
                                {{personSelected?.positionName}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Sueldo</label>
                            <h3>
                                {{personSelected?.salary | currency:"RD$"}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Tipo de Servidor Público</label>
                            <h3>
                                {{personSelected?.employeeType}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Fecha del Registro</label>
                            <h3>
                                {{personSelected?.effectiveDate | date:"dd/MM/yyyy"}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Fecha Ingreso</label>
                            <h3>
                                {{personSelected?.admissionDate | date:"dd/MM/yyyy"}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Correo Institucional</label>
                            <h3>
                                {{personSelected?.institionalEmail}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Estatus</label>
                            <h3 *ngIf="personSelected?.status" class="text-success">
                                Activo
                            </h3>
                            <h3 *ngIf="personSelected?.status == false && personSelected?.employeeCondition !== 2 && !personSelected?.wasHiringCanceled"
                                class="text-danger">
                                Desvinculado
                            </h3>
                            <h3 *ngIf="personSelected?.status == false && personSelected?.employeeCondition === 2 && !personSelected?.wasHiringCanceled"
                                class="text-danger">
                                Inactivo
                            </h3>
                            <h3 *ngIf="personSelected?.status == false && personSelected?.wasHiringCanceled"
                                class="text-danger">
                                Nombramiento Anulado
                            </h3>
                            <h3 *ngIf="personSelected?.status == undefined" class="text-warning">
                                N/A
                            </h3>
                        </div>
                    </div>

                    <div class="col-md-3" *ngIf="currentEmployeeCondition?.employeeId">
                        <div class="form-group">
                            <label class="mb-0">Condición Actual</label>
                            <h3 lass="text-success">
                                {{currentEmployeeCondition?.personalActionsType}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Nivel Académico</label>
                            <h3 lass="text-success">
                                {{personSelected?.academicLevel === undefined ? '' :
                                (personSelected?.academicLevel == null ? 'N/A' : personSelected?.academicLevel) }}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Grupo Ocupacional</label>
                            <h3 lass="text-success">
                                {{personSelected?.occupationalGroup === undefined ? '' :
                                (personSelected?.occupationalGroup == null ? 'N/A' : personSelected?.occupationalGroup)
                                }}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Sucursal</label>
                            <h3 lass="text-success">
                                {{personSelected?.branch || 'N/A'}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Categoría</label>
                            <h3 lass="text-success">
                                {{personSelected?.employeeCategory || 'N/A'}}
                            </h3>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>

    <div class="card-body">

        <ul class="nav nav-tabs border-tabs-primary" role="tablist">
            <li class="nav-item" *ngIf="privilege.changeActionsPermanent.value">
                <a class="nav-link active" id="cambio-empleado-tab" (click)="setTabSelected('cambio-empleado-tab')"
                    data-toggle="tab" href="#cambio-empleado">
                    <span class="nav-icon mr-5">
                        <i class="flaticon-proceso icon-3x"></i>
                    </span>
                    <span class="nav-text">
                        <h3>Acciones Permanentes</h3>
                    </span>
                </a>
            </li>
            <li class="nav-item" *ngIf="privilege.recurringActions.value">
                <a class="nav-link" id="acciones-personal-tab" (click)="setTabSelected('acciones-personal-tab')"
                    data-toggle="tab" href="#acciones-personal" aria-controls="acciones-personal">
                    <span class="nav-icon mr-5">
                        <i class="flaticon-lista-de-verificacion icon-3x"></i>
                    </span>
                    <span class="nav-text">
                        <h3>Acciones Recurrentes</h3>
                    </span>
                </a>
            </li>
            <li class="nav-item" *ngIf="privilege.addUnlinkEmployees.value">
                <a class="nav-link" id="desvincular-tab" (click)="setTabSelected('desvincular-tab')" data-toggle="tab"
                    href="#desvincular" aria-controls="desvincular">
                    <span class="nav-icon mr-5">
                        <i class="flaticon-mala-critica icon-3x"></i>
                    </span>
                    <span class="nav-text">
                        <h3>Salidas</h3>
                    </span>
                </a>
            </li>
            <li class="nav-item" *ngIf="privilege.entryList.value">
                <a class="nav-link" id="employee-entry-tab" (click)="setTabSelected('employee-entry-tab')"
                    data-toggle="tab" href="#employee-entry" aria-controls="desvincular">
                    <span class="nav-icon mr-5">
                        <i class="flaticon-lista-de-verificacion icon-3x"></i>
                    </span>
                    <span class="nav-text">
                        <h3>Lista de Entrada</h3>
                    </span>
                </a>
            </li>
        </ul>
        <div class="tab-content mt-5">
            <div class="tab-pane fade active show py-4" id="cambio-empleado" role="tabpanel"
                *ngIf="privilege.changeActionsPermanent.value" aria-labelledby="cambio-empleado-tab">
                <div class="card-body">
                    <ng-container *ngIf="privilege.apply.value">
                        <form [formGroup]="actionChangeForm" class="w-100">
                            <div class="row gutter-b mt-0">
                                <div class="row mb-4 mt-0" *ngIf="isEdittingEmployeeChange && (currentChangeConditionOnEditting == 2 || currentChangeConditionOnEditting == 5 || currentChangeConditionOnEditting == 7)">
                                    <div class="alert alert-warning d-flex align-items-center">
                                        <i class="fas fa-exclamation-triangle mr-2 fa-2x blink-for-warning"></i>
                                        <div  >
                                            Esta acción de personal ya fue <strong>aprobada</strong>, modificarla puede causar alteraciones en la <strong>nómina</strong> y otros módulos del <strong>SIGEI</strong>. En caso de que la acción de personal haya sido aplicada en una
                                            nómina u otro proceso dependiente de la misma, su modificación <strong>NO</strong> será <strong>permitida</strong>.
                                        </div>
                                    </div>

                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col-md-12">

                                            <div class="row">
                                                <div class="col-md-3 form-group">
                                                    <label>Cambios <span class="text-danger">*</span></label>
                                                    <ngx-select-dropdown formControlName='parameter'
                                                        (change)='fillCurrentData($event.value.ocode)'
                                                        [options]='fields' [config]='fieldsConfig' class="flex-1">
                                                    </ngx-select-dropdown>
                                                    <span class="text-danger"
                                                        *ngIf="formIsInvalid && actionChangeForm.controls['parameter']?.errors?.required">Este
                                                        campo es requerido</span>
                                                </div>
                                                <div class="col-md-3 form-group">
                                                    <label>Datos Actuales <span class="text-danger">*</span></label>
                                                    <input  [value]="actionChangeForm.get('currentData')?.value"
                                                        class="form-control form-control-lg"
                                                        *ngIf="fieldType !== 'text' && fieldType !== 'date'" readonly>

                                                    <input  [value]="actionChangeForm.get('currentData')?.value | date:'dd/MM/yyyy'"
                                                        class="form-control form-control-lg"
                                                        *ngIf="fieldType == 'date'" readonly>

                                                    <input currencyMask [options]="{precision:2}" placeholder="$0.00"
                                                        *ngIf="fieldType == 'text'" [value]="actionChangeForm.get('currentData')?.value"
                                                        class="form-control form-control-lg" readonly>
                                                </div>
                                                <div class="col-md-3 form-group">
                                                    <label>Datos Nuevos <span class="text-danger">*</span></label>
                                                    <ngx-select-dropdown formControlName='newData'
                                                        [ngClass]="{'d-none': (fieldType!=='drop' || fieldType !== 'drop-additional'), 'd-inline': (fieldType=='drop' || fieldType == 'drop-additional')}"
                                                        class="flex-1" [options]='options'
                                                        (change)='fillNewData($event.value)' [config]='config'>
                                                    </ngx-select-dropdown>

                                                    <input currencyMask [options]="{precision:2}" placeholder="$0.00"
                                                        *ngIf="fieldType == 'text'" formControlName='newData'
                                                        class="form-control form-control-lg" (keyup)="verifySalary()">

                                                    <input type="date" (change)='fillNewData($event.value)'
                                                        *ngIf="fieldType == 'date'" formControlName='newData'
                                                        class="form-control form-control-lg">
                                                    <span class="text-danger"
                                                        *ngIf="formIsInvalid && actionChangeForm.controls['newData']?.errors?.required">Este
                                                        campo es requerido</span>
                                                    <span class="text-danger"
                                                        *ngIf="actionChangeForm.errors?.invalidValue">Los valores no
                                                        pueden
                                                        ser los mismos</span>
                                                    <br>
                                                    <span *ngIf="!isSalaryCorrect" class="text-danger">El Sueldo
                                                        aprobado
                                                        debe ser mayor al que tiene el Servidor Público
                                                        actualmente.</span>
                                                </div>
                                                <div class="col-md-3 form-group" *ngIf=" fieldType=='drop-additional'">
                                                    <label>{{additionalValueFieldName}} <span
                                                            class="text-danger">*</span></label>
                                                    <ngx-select-dropdown formControlName='additionalValueObject'
                                                        class="flex-1" [options]='additionalValueList'
                                                        (change)='changeAdditionalValue($event.value)'
                                                        [config]='additionalValueConfig'>
                                                    </ngx-select-dropdown>
                                                </div>
                                                <div class="col-md-3 form-group">
                                                    <label>Motivo <span class="text-danger">*</span></label>
                                                    <ngx-select-dropdown formControlName='motive' [options]='motives'
                                                        [config]='motivesConfig' class="flex-1">
                                                    </ngx-select-dropdown>
                                                    <span class="text-danger"
                                                        *ngIf="formIsInvalid && actionChangeForm.controls['motive']?.errors?.required">Este
                                                        campo es requerido</span>

                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>Fecha Efectividad <span
                                                                class="text-danger">*</span></label>
                                                        <input (change)="validateEffectiveDate()" type="date"
                                                            formControlName="effectiveDate" class="form-control"
                                                            [min]="this.actionChangeForm?.get('parameter')?.value?.ocode == 11 ? null : admissionDate">
                                                        <span class="text-danger"
                                                            *ngIf="effectiveDateIsInvalid">{{effectiveDateError}}</span>
                                                        <span class="text-danger"
                                                            *ngIf="formIsInvalid && actionChangeForm.controls['effectiveDate']?.errors?.required">Este
                                                            campo es requerido</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Comentario</label>
                                                        <textarea formControlName="comment" class="form-control"
                                                            rows="6"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>Evidencia <span class="text-danger">*</span></label>
                                                        <uppy-file #uppyFile
                                                            cardBody="''"
                                                            previewDefaultImage="assets/images/ver_file.jpg"
                                                            classPreviewDefaultImage="symbol"
                                                            targetClass="evidenceOb"
                                                            [width]="350"
                                                            [height]="138"
                                                            [isDisabled]="!this.personSelected?.status"
                                                            [showDocumentoAfterUploaded]="false"
                                                            [fileForEditting]="guiID"
                                                            [allowedFileTypes]="['application/pdf']"
                                                            (fileEmitter)="setEvidence($event)">
                                                        </uppy-file>
                                                        <span style="position: absolute;"
                                                            *ngIf="this.actionChangeForm.controls['evidence']?.errors?.required && formIsInvalid"
                                                            class="text-danger">Este campo es requerido</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-2" *ngIf="isEdittingEmployeeChange">
                                                <div class="text-primary pt-4">
                                                    <h4>Información de la Modificación</h4>
                                                    <hr>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label>Motivo de la Modificación Anterior </label>
                                                            <input type="text" class="form-control form-control-lg" [value]="actionChangeForm?.get('oldModificationReason')?.value?.stringData" readonly/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <div class="form-group">
                                                            <label>Comentario de la Modificación Anterior</label>
                                                            <textarea [value]="actionChangeForm?.get('oldModificationComment')?.value" class="form-control"
                                                                rows="6" disabled></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label>Motivo de la Modificación Actual <span class="text-danger">*</span></label>
                                                            <ngx-select-dropdown formControlName='newModificationReason' [options]='modificationReasons'
                                                                [config]='motivesConfig' class="flex-1">
                                                            </ngx-select-dropdown>
                                                            <span class="text-danger"
                                                                *ngIf="formIsInvalid && actionChangeForm.controls['newModificationReason']?.errors?.required">Este
                                                                campo es requerido</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <div class="form-group">
                                                            <label>Comentario de la Modificación Actual</label>
                                                            <textarea formControlName="newModificationComment" class="form-control"
                                                                rows="6"></textarea>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div class="col-md-auto text-center d-flex justify-content-end align-items-end">
                                    <div class="row">
                                        <div class="col-12">
                                            <button (click)="addChangeDetails()"
                                            [disabled]="!isSalaryCorrect || actionChangeForm.invalid"
                                            class="btn btn-pill font-weight-bolder btn-shadow btn-success mb-2">
                                            <i class="flaticon-disco-flexible"></i>
                                            <span> {{isEdittingEmployeeChange ? 'Guardar Modificación' : 'Agregar cambio'}} </span>
                                            </button>
                                        </div>
                                       <div class="col-12">
                                        <button (click)="clearEditting(true);" class="btn btn-danger btn-pill font-weight-bolder">
                                            {{isEdittingEmployeeChange ? 'Cancelar Modificación' : 'Limpiar campos'}}
                                        </button>
                                       </div>
                                    </div>


                                </div>
                            </div>
                        </form>

                        <div class="row gutter-b">
                            <div class="table-responsive">
                                <table class="table atbl table-vertical-center">
                                    <thead>
                                        <tr class="bg-primary">
                                            <!-- <th class="text-center"></th> -->
                                            <th class="text-left">Tipo de Cambio</th>
                                            <th class="text-left">Datos Antiguos</th>
                                            <th class="text-left">Datos Nuevos</th>
                                            <th class="text-left">Fecha Efectividad</th>
                                            <th class="text-left">Evidencia</th>
                                            <th class="text-left">Motivo</th>
                                            <th class="text-center">Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let detail of newChangesRecords; let i =index">
                                            <!-- <td class="text-center"><input type="checkbox" name="" id=""></td> -->
                                            <td class="text-left">{{detail.parameterName}}</td>
                                            <td class="text-left" *ngIf="detail?.valueAfterNumeric">
                                                {{detail.valueBefore
                                                | currency}}</td>
                                            <td class="text-left" *ngIf="!detail?.valueAfterNumeric && !isDate(detail?.valueAfter)">
                                                {{detail.valueBefore}}
                                            </td>
                                            <td class="text-left" *ngIf="isDate(detail?.valueAfter)">
                                                {{detail.valueBefore | date:'dd/MM/yyyy'}}
                                            </td>
                                            <td class="text-left" *ngIf="detail.valueAfterNumeric">{{detail.valueAfter |
                                                currency}}</td>
                                            <td class="text-left"
                                                *ngIf="!detail?.valueAfterNumeric && !isDate(detail?.valueAfter)">
                                                <ng-container
                                                    *ngIf="detail?.additionalValueName else noAdditionalValueName">
                                                    {{detail.valueAfter}} | {{detail.additionalValueName}}
                                                </ng-container>
                                                <ng-template #noAdditionalValueName>
                                                    {{detail.valueAfter}}
                                                </ng-template>
                                            </td>
                                            <td class="" *ngIf="isDate(detail?.valueAfter)">
                                                {{detail?.valueAfter
                                                | date:'dd/MM/yyyy'}}</td>

                                            <td class="text-left">
                                                {{detail.effectiveDate |date:'dd MMMM yyyy':'UTC':'es-US'}}
                                            </td>
                                            <td>
                                                <a *ngIf="detail.evidence && isEdittingEmployeeChange" (click)='openGuID(detail.evidence)'
                                                    data-toggle="tooltip" title="Evidencia"
                                                    class="btn btn-light-youtube font-weight-bold mb-3">
                                                    <i class="flaticon-pdf-file icon-2x"></i>
                                                </a>
                                                <a *ngIf="detail.evidence && !isEdittingEmployeeChange"
                                                    (click)="viewPdf(detail.evidence)" 
                                                    data-toggle="tooltip" title="Evidencia"
                                                    class="btn btn-light-youtube font-weight-bold mb-3">
                                                    <i class="flaticon-pdf-file icon-2x"></i>
                                                </a>
                                                <p *ngIf="!detail.evidence">N/A</p>
                                            </td>
                                            <td class="text-left">{{detail.reason}}</td>
                                            <td class="text-center w-100px">
                                                <button *ngIf="!inViewDetails && !isEdittingEmployeeChange"
                                                    class="btn btn-sm btn-light-danger btn-icon"
                                                    (click)="remove(detail, i)">
                                                    <i class="flaticon-delete"></i>
                                                </button>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <div>
                                    <el-caption #paginator [originalRecordsLength]="newChangesRecordsOriginal.length"
                                        [listedRecordsLength]="newChangesRecords.length"
                                        [registerQuantity]="newChanges.length" [records]="newChanges"
                                        (onChage)="getPaginatedRecordsDetails($event)"></el-caption>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!isEdittingEmployeeChange">
                            <div class="form-group d-flex justify-content-end align-items-center ">
                                <button [disabled]="newChanges.length < 1 || inViewDetails" (click)="setChangeHeader()"
                                    class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
                                    <i class="flaticon-disco-flexible"></i>
                                    <span> Guardar </span>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row gutter-b">
                        <h3 class="text-primary">Historial de cambios guardados</h3>
                        <div class="table-responsive">
                            <table class="table atbl table-vertical-center">
                                <thead>
                                    <tr class="bg-primary">
                                        <!-- <th class="text-center"></th> -->
                                        <th class="text-left">Secuencia</th>
                                        <th class="text-left">Tipo de Cambio</th>
                                        <th class="text-left">Dato Anterior</th>
                                        <th class="text-left">Dato Propuesto</th>
                                        <th class="text-left">Motivo</th>
                                        <th class="text-left">Cambio Adicional</th>
                                        <th class="text-left">¿Es Histórica?</th>
                                        <th class="text-left">Estatus</th>
                                        <th class="text-left">Aprobado Por</th>
                                        <th class="text-left">Fecha Efectiva</th>
                                        <th class="text-left">Fecha Aprobación</th>
                                        <th class="text-center">Evidencia</th>
                                        <th class="text-center">Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let changeDetail of changeDeyailsListRecords; let i = index">
                                        <td class="">{{changeDetail?.employeeChangeDetailsId || 0}}</td>
                                        <td class="">{{changeDetail?.parameterName || "N/A"}}</td>

                                        <td class="" *ngIf="!changeDetail?.valueAfterNumeric && !isDate(changeDetail?.valueAfter)">
                                            {{changeDetail?.valueBefore || "N/A"}}</td>
                                        <td class="" *ngIf="changeDetail?.valueAfterNumeric">{{changeDetail?.valueBefore
                                            || "N/A" | currency}}</td>
                                        <td class="" *ngIf="isDate(changeDetail?.valueAfter)">{{changeDetail?.valueBefore
                                            || "N/A" | date:'dd/MM/yyyy'}}</td>
                                        <td class=""
                                            *ngIf="!changeDetail?.valueAfterNumeric && !isDate(changeDetail?.valueAfter)">
                                            {{changeDetail?.valueAfter
                                            || "N/A"}}</td>
                                        <td class="" *ngIf="changeDetail?.valueAfterNumeric">{{changeDetail?.valueAfter
                                            || "N/A" | currency}}</td>

                                        <td class="" *ngIf="isDate(changeDetail?.valueAfter)">{{changeDetail?.valueAfter
                                            || "N/A" | date:'dd/MM/yyyy'}}</td>

                                        <td class="">{{changeDetail?.reason || "N/A" }}</td>
                                        <td class="">{{changeDetail?.additionalValueName || "N/A" }}</td>
                                        <td class="">{{changeDetail?.isHistory ? 'Sí' : 'No' || "N/A" }}</td>
                                        <td class="w-100px text-center">
                                            <span style="width: 137px;">
                                                <span *ngIf="changeDetail.condition == 1"
                                                    class="font-weight-bold text-info"><span
                                                        class="label label-info label-dot mr-2"></span>{{changeDetail.conditionName
                                                    }}</span>
                                                <span *ngIf="changeDetail.condition == 2"
                                                    class="font-weight-bold text-success"><span
                                                        class="label label-success label-dot mr-2"></span>{{changeDetail.conditionName
                                                    }}</span>
                                                <span *ngIf="changeDetail.condition == 3"
                                                    class="font-weight-bold text-danger"><span
                                                        class="label label-danger label-dot mr-2"></span>{{changeDetail.conditionName
                                                    }}</span>
                                                <span *ngIf="changeDetail.condition == 5"
                                                    class="font-weight-bold text-success"><span
                                                        class="label label-success label-dot mr-2"></span>{{changeDetail.conditionName
                                                    }}</span>
                                                <span *ngIf="changeDetail.condition == 7"
                                                class="font-weight-bold text-success"><span
                                                    class="label label-success label-dot mr-2"></span>{{changeDetail.conditionName
                                                }}</span>
                                                <span *ngIf="changeDetail.condition == 9"
                                                class="font-weight-bold text-info"><span
                                                    class="label label-info label-dot mr-2"></span>{{changeDetail.conditionName
                                                }}</span>
                                                <span *ngIf="changeDetail.condition == 10"
                                                class="font-weight-bold text-danger"><span
                                                    class="label label-danger label-dot mr-2"></span>{{changeDetail.conditionName
                                                }}</span>
                                            </span>
                                        </td>
                                        <td class="">{{changeDetail?.employeeApproved || "N/A"}}</td>
                                        <td class="">{{changeDetail?.effectiveDate |date:'dd/MM/yyyy':'UTC':'es-US'}}
                                        </td>
                                        <td class="">{{changeDetail?.dateApproved |date:'dd/MM/yyyy':'UTC':'es-US'}}
                                        </td>
                                        <td class="text-center">
                                            <a *ngIf="changeDetail?.evidence && privilege.viewEvidence.value"
                                                (click)='openGuID(changeDetail?.evidence)' data-toggle="tooltip"
                                                title="Evidencia" class="btn btn-icon  btn-light-youtube btn-sm m-1">
                                                <i class="flaticon-pdf-file icon-2x"></i>
                                            </a>
                                            <p *ngIf="!changeDetail?.evidence">N/A</p>
                                        </td>
                                        <td class="text-center noPrint">
                                            <div class="text-center d-flex justify-content-center">

                                                <a *ngIf="changeDetail.condition == 3 && privilege.viewRejectionReason.value"
                                                    (click)="showRejectionReason(changeDetail?.reasonReject)"
                                                    class="btn btn-icon btn-light-warning btn-sm m-1">
                                                    <i class="flaticon-quitar-usuario-2 icon-2x" data-toggle="tooltip"
                                                        title="Ver razón rechazo"></i>
                                                </a>

                                                <a *ngIf="(changeDetail.condition == 2 || changeDetail.condition == 5 || changeDetail.condition == 7)  && privilege.viewPdfChange.value"
                                                    class="btn btn-icon btn-light-info btn-sm m-1"
                                                    (click)=" exportToPdf(changeDetail)">
                                                    <i class="flaticon-ver icon-2x" data-toggle="tooltip"
                                                        title="Ver PDF"></i>
                                                </a>
                                                <a *ngIf=" privilege.edit.value && changeDetail.condition != 10 && changeDetail.status " (click)="editEmployeeChange(changeDetail)"
                                                    class="btn btn-icon btn-light-warning btn-sm m-1">
                                                    <i class="flaticon-edit" data-toggle="tooltip" title="Editar"></i>
                                                </a>
                                                <a *ngIf="(changeDetail.showResendBtn && companySign?.useDigitalSignature && personSelected?.status) && privilege.resendSignatureRequest.value && !isEdittingEmployeeChange"
                                                    class="btn btn-sm btn-warning btn-icon mr-2" data-toggle="tooltip"
                                                    (click)="resendRequestSign(changeDetail)"
                                                    title="Reenviar Solicitud de firma">
                                                    <img src="/assets/images/refresh.svg"
                                                        class="flaticon-ver icon-custom">
                                                </a>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <div>
                                <el-caption #paginator
                                    [originalRecordsLength]="changeDeyailsListRecordsOriginal?.length"
                                    [listedRecordsLength]="changeDeyailsListRecords?.length"
                                    [registerQuantity]="changeDetailsList?.length" [records]="changeDetailsList"
                                    (onChage)="getPaginatedRecordsHeader($event)"></el-caption>
                            </div>
                        </div>
                    </div>
                    <div class="row gutter-b">
                        <h3 class="text-primary">Designaciones</h3>
                        <div class="table-responsive">
                            <table class="table atbl table-vertical-center">
                                <thead>
                                    <tr class="bg-primary">
                                        <!-- <th class="text-center"></th> -->
                                        <th class="text-left">Secuencia</th>
                                        <th class="text-left">Clasificación</th>
                                        <th class="text-left">Fecha inicio</th>
                                        <th class="text-left">Fecha Efectiva</th>
                                        <th class="text-left">Sexo</th>
                                        <th class="text-left">Unidad Organizativa</th>
                                        <th class="text-left">Comentario</th>
                                        <th class="text-left">Estatus</th>
                                        <th class="text-center">Acción</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let designation of designationsPaginated; let i = index">
                                        <td class="">{{designation?.actionsEmployeeId || 0}}</td>
                                        <td class="">{{designation?.typeActions}}</td>
                                        <td class="">{{designation?.startDate |date:'dd/MM/yy'}}</td>
                                        <td class="">{{designation?.efectiveDay |date:'dd/MM/yyyy'}}
                                        </td>
                                        <td class="">{{designation?.sex}}</td>
                                        <td class="">{{designation?.departmentName}}</td>
                                        <td class="">{{designation?.commentary}}</td>
                                        <td class="text-left">
                                            <ng-container *ngIf="!designation?.status; else conditions">
                                                <span class="font-weight-bold text-danger"> {{designation?.conditionId === 10 ? 'Anulado' : 'Inactivo'}} </span>
                                            </ng-container>
                                            <ng-template [ngSwitch]="designation?.conditionId" #conditions>
                                                <span *ngSwitchCase="2" class="font-weight-bold text-success">Aprobado</span>
                                                <span *ngSwitchCase="3" class="font-weight-bold text-danger">Rechazado</span>
                                                <span *ngSwitchCase="6" class="font-weight-bold text-info">En Espera de Aprobación</span>
                                                <span *ngSwitchCase="10" class="font-weight-bold text-danger">Anulado</span>
                                                <span *ngSwitchDefault class="font-weight-bold ">
                                                        <span class="mr-2">N/A</span>
                                                </span>
                                            </ng-template>
                                        </td>
                                        <td class="actionsBtn">
                                            <a class="btn btn-icon btn-light-info btn-sm m-1"
                                                (click)="exportDesignation(designation)">
                                                <i class="flaticon-ver icon-2x" data-toggle="tooltip"
                                                    title="Ver PDF"></i>
                                            </a>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                            <div>
                                <el-caption #paginator [originalRecordsLength]="designations?.length"
                                    [listedRecordsLength]="designationsPaginated?.length"
                                    [registerQuantity]="designations?.length" [records]="designations"
                                    (onChage)="getPaginatedDesignations($event)"></el-caption>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group d-flex justify-content-end align-items-center ">
                            <!-- <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary d-flex mr-2">
                                <i class="flaticon-checked"></i>
                                <span>Aprobar</span>
                            </button> -->

                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="acciones-personal" role="tabpanel" aria-labelledby="acciones-personal-tab"
                *ngIf="privilege.recurringActions.value">

                <app-personnel-actions-tap [isSeviceCommission]="isSeviceCommission"
                    [isSuspended]="isSuspended"
                    [employeeNumber]="personSelected?.employNumber"></app-personnel-actions-tap>
            </div>
            <div class="tab-pane fade" id="desvincular" role="tabpanel" aria-labelledby="desvincular-tab"
                *ngIf="privilege.addUnlinkEmployees.value">
                <app-unlink-employees-tab [isSeviceCommission]="isSeviceCommission" [personSelected]="personSelected"
                    [isSuspended]="isSuspended"
                    (unlinkEmitter)="getUnlinkEmitter($event)">
                </app-unlink-employees-tab>
            </div>
            <div class="tab-pane fade" id="employee-entry" role="tabpanel" aria-labelledby="employee-benefits-tab"
                *ngIf="privilege.entryList.value">
                <div class="card-body p-2">

                    <form [formGroup]="employeeEntryForm">
                        <ng-template [ngIf]="employeeEntryForm && employeeEntryForm.get('employeeEntryList')">
                            <ng-container *ngIf="privilege.AddEntryList.value">
                                <div class="row mb-3 border-bottom"
                                    *ngFor="let benefit of employeeEntryForm.get('employeeEntryList')['controls']; let i=index">
                                    <div class="col-xl-2 mt-sm-0 mt-xl-12">
                                        <h6 class="text-middle">{{jobOfferBenefitsToggle[i]?.description}}</h6>
                                    </div>
                                    <div class="col-xl-10 " formArrayName="employeeEntryList">
                                        <div class="row" [formGroupName]="i">
                                            <div class="col mt-sm-0 mt-xl-10">
                                                <div class="d-flex align-items-center flex-grow-1 mb-3">
                                                    <strong
                                                        class="checkbox checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                                                    <div class="flex-shrink-0 mx-2">
                                                        <span class="switch switch-sm switch-primary">
                                                            <label><input class="cursor-pointer abc-checkbox-primary"
                                                                    formControlName="selected"
                                                                    type="checkbox" /><span></span></label>
                                                        </span>
                                                    </div>
                                                    <strong
                                                        class="checkbox checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
                                                </div>
                                            </div>

                                            <div class=" col-xl-6 col-xxl-3">
                                                <div class="form-group">
                                                    <label>Evidencia</label>
                                                    <input
                                                        [disabled]="!personSelected?.status || personSelected?.employeeCondition == 1
                                                        || isSuspended == true"
                                                        class="form-control" type="file" id="formFile"
                                                        (change)="manageFiles($event.target.files[0], i)">
                                                </div>

                                            </div>

                                            <div class=" col-xl-6 col-xxl-3">
                                                <div class="form-group">
                                                    <label>Observaciones </label>
                                                    <input class="form-control form-control-lg"
                                                        formControlName="observations">
                                                </div>
                                            </div>
                                            <!-- <div class=" col-xl-6 col-xxl-3">
                                                <div class="form-group">
                                                    <label>Asignado por</label>
                                                    <input class="form-control form-control-lg" readonly
                                                        [value]="userFullName">
                                                </div>
                                            </div> -->
                                            <div class=" col-xl-6 col-xxl-1 mt-xl-0 mt-xxl-9">
                                                <div class="d-flex mb-5 " *ngIf="jobOfferBenefitsToggle[i]?.requireDeclaration">
                                                    <button *ngIf="!isSuspended"
                                                        class="btn btn-icon btn-light-success btn-sm m-1 col"
                                                        data-toggle="tooltip" title="Agregar declaración"
                                                        (click)="openDeclarationModal(benefit, false)">
                                                        <i class="flaticon-mas"></i>
                                                    </button>
                                                    <button *ngIf="!isSuspended"
                                                        class="btn btn-icon btn-light-warning btn-sm m-1 col"
                                                        data-toggle="tooltip" title="Editar declaración"
                                                        (click)="openDeclarationModal(benefit, true)"
                                                        [disabled]="!benefit.get('deviceAsset').value">
                                                        <i class="flaticon-edit"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="actions d-flex justify-content-end">
                                        <!-- <button id="btnCloseModal" class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3" data-dismiss="modal">
                                    <i class="flaticon2-cross"></i> Cerrar
                                </button> -->
                                        <button class="btn btn-pill font-weight-bolder btn-shadow btn-success"
                                            (click)="updateEntryList()">
                                            <i class="flaticon-disco-flexible"></i> Guardar
                                        </button>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="separator separator-solid my-7"></div>

                            <div class="row gutter-b py-3" *ngIf="privilege.ViewEntryList.value">
                                <h3 class="text-primary">Historial de cambios realizados</h3>
                                <div class="table-responsive">
                                    <table class="table atbl table-vertical-center">
                                        <thead>
                                            <tr class="bg-primary">
                                                <!-- <th class="text-center"></th> -->
                                                <th class="text-left">Secuencia</th>
                                                <th class="text-left">Cambio</th>
                                                <th class="text-center">Valor</th>
                                                <th class="text-left">Usuario</th>
                                                <th class="text-left">Observaciones</th>
                                                <th class="text-left">Marca</th>
                                                <th class="text-left">Modelo </th>
                                                <th class="text-left">Activo</th>
                                                <th class="text-left">Número de serie</th>
                                                <th class="text-left">Fecha de entrega dispositivo</th>
                                                <th class="text-left">Evidencia</th>
                                                <th class="text-left">Fecha</th>
                                                <th class="text-left">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let detail of entryListChangesPaginated; let i = index">
                                                <!-- <td class="text-center"><input type="checkbox" name="" id=""></td> -->
                                                <td class="text-left">{{detail.employeeEntryListHistoryId}}</td>
                                                <td class="text-left">{{detail.entryListType}}</td>
                                                <td class="text-center">
                                                    <div *ngIf="!detail.selected">
                                                        NO
                                                    </div>
                                                    <div *ngIf="detail.selected">
                                                        SI
                                                    </div>
                                                </td>
                                                <td class="text-left">{{detail?.firstName}} {{detail?.lastName}}</td>
                                                <td class="text-left">{{detail?.observations}}</td>
                                                <td class="text-left">{{detail?.deviceBrand || 'N/A'}}</td>
                                                <td class="text-left">{{detail?.deviceModel || 'N/A'}}</td>
                                                <td class="text-left">{{detail?.deviceAsset || 'N/A'}}</td>
                                                <td class="text-left">{{detail?.deviceSerial || 'N/A'}}</td>
                                                <td class="text-left">{{(detail?.deviceDeliveredDate | date:'dd/MM/yyyy':'UTC':'es-US') || 'N/A'}}</td>
                                                <td class="text-left">
                                                    <a *ngIf="detail?.evidence" (click)='openGuID(detail?.evidence)'
                                                        data-toggle="tooltip" title="Evidencia"
                                                        class="btn btn-icon  btn-light-youtube btn-sm m-1">
                                                        <i class="flaticon-pdf-file icon-2x"></i>
                                                    </a>
                                                    <p *ngIf="!detail?.evidence">N/A</p>
                                                </td>
                                                <td class="text-left">{{(detail.createDate | date:'dd/MM/yyyy':'UTC':'es-US')|| 'N/A'}}</td>
                                                <td class="actionsBtn text-left">
                                                    <a *ngIf="detail.requireDeclaration &&
                                                    privilege.ViewSwornDeclarationEntryList.value"
                                                        class="btn btn-icon btn-light-info btn-sm m-1"
                                                        data-toggle="modal" data-target="#SwornDeclaration"
                                                        (click)="openSwornDeclarationModal(detail?.employeeEntryListHistoryId)"><i
                                                            class="flaticon-ver" data-toggle="tooltip"
                                                            data-original-title="Ver declaración jurada"></i></a>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <div>
                                        <el-caption #paginator [originalRecordsLength]="entryListChanges.length"
                                            [listedRecordsLength]="entryListChangesPaginated.length"
                                            [registerQuantity]="entryListChanges.length" [records]="entryListChanges"
                                            (onChage)="getPaginatedRecordsEntryList($event)"></el-caption>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </form>
                </div>
            </div>
        </div>


    </div>

    <el-modal target="documentxEvidence" title="Evidencia">
        <iframe [src]='transform(file.document)' width="100%" height="600px"></iframe>
    </el-modal>
</div>
