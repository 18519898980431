import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { docService } from 'src/app/services/doc.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { Subscription } from 'rxjs';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { MatDialog } from '@angular/material/dialog';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { EmployeesIncompleteComponent } from '../employees-incomplete/employees-incomplete.component';
import { DatePipe } from '@angular/common';
import { IEmployee } from 'src/app/models/Employee.model';
import { PagedResult } from 'src/app/shared/models/page-result';
import { EmployeeServiceRequestModalComponent } from '../employee-service/employee-service-request-detail/employee-service-request-modal.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { FilterEvent, SearchBarPaginationComponent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { CompaniesService } from 'src/app/services/companies.service';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
import { employeeServiceRequestDetailModalFormComponent } from '../employee-service/employee-service-request-detail-modal-form/employee-service-request-detail-modal-form.component';
import { HttpClient } from '@angular/common/http';
import { ShowDocumentModalComponent } from 'src/app/shared/show-document-modal/show-document-modal.component';
import { ShowSubjectModalComponent } from 'src/app/shared/show-subject-modal/show-subject-modal.component';

declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'app-birthday',
  templateUrl: './birthday.component.html',
  styleUrls: ['./birthday.component.css']
})
export class BirthdayComponent implements OnInit, AfterViewChecked, OnDestroy {
  today = new Date(Date.now());
  advancedFilter = null
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'EMPLOYEES',
    dashboardAction: { key: 'DASHB-E', value: false },
    exportAction: { key: 'EXPORT-E', value: false },
    editAction: { key: 'EDIT-E', value: false },
    viewAction: { key: 'VIEW-E', value: false },
    personnelAction: { key: 'ACTIONS-E', value: false },
    historyPersonalActions: { key: 'HISTORYPERSONALACTIONS', value: false }
  }

  public api_doc = environment.api_doc
  public photoUrl: string = ''
  public loadingPic: boolean = true
  localStorageSearch = 'employees-birthday-list_filter_search'
  @ViewChild('searchBar') searchBar: SearchBarPaginationComponent
  datePipe = new DatePipe('en-Us')
  isLoading: boolean = true;
  subscription: Subscription = new Subscription();
  employees: any[] = [];
  pageQuantity = 0
  employeesListOriginal: any[] = [];
  paginatedEmployees: any[] = [];
  employeesAI: boolean = false;
  tableFilter = '';
  employeeFilter: any = '';
  typeExitList: any[] = [];
  branches: any[] = []
  branch: any
  reasonExitList: any[] = [];
  @ViewChild('paginator') paginator: CaptionComponent;
  form: FormGroup;
  employeeId: number;
  filterText: string = '';
  postulationId: number;
  exitReasonDescription;
  vacantId: number;
  selectedItems: any[] = [{ item_id: 0, item_text: '' }];
  selectedItemsConditionPhoto: any[] = [{ item_id: 0, item_text: 'Todos' }];
  personId: number;
  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };

  incompleteEmployees: { quantity: number } = {
    quantity: 0
  }
  canUseOption = false;
  searchParameter!: FilterEvent;
  resultData = []
  sendItems = []
  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Secuencia" },
      { propkeyId: 2, displayName: "Código de Servidor Público" },
      { propkeyId: 3, displayName: "Servidor Público" },
      { propkeyId: 4, displayName: "Documento de Identidad", default: true },
      { propkeyId: 5, displayName: "Unidad Organizativa" },
      { propkeyId: 19, displayName: "Fecha Cumpleaños", type: 'number', placeHolder: 'Escriba el dia de cumpleaños' },
      { propkeyId: 6, displayName: "Cargo" },
    ];

  statusOptions = [
    { item_id: 1, item_text: 'Enero' },
    { item_id: 2, item_text: 'Febrero' },
    { item_id: 3, item_text: 'Marzo' },
    { item_id: 4, item_text: 'Abril' },
    { item_id: 5, item_text: 'Mayo' },
    { item_id: 6, item_text: 'Junio' },
    { item_id: 7, item_text: 'Julio' },
    { item_id: 8, item_text: 'Agosto' },
    { item_id: 9, item_text: 'Septiembre' },
    { item_id: 10, item_text: 'Octubre' },
    { item_id: 11, item_text: 'Noviembre' },
    { item_id: 12, item_text: 'Diciembre' }
  ];

  photoConditionList =
    [
      { item_id: 0, item_text: "Todos" },
      { item_id: 1, item_text: "Con foto" },
      { item_id: 2, item_text: "Sin Foto" },
    ];

  employeesPagedResut = new PagedResult<any>();
  dropdownSettings = {
    singleSelection: true,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: false,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  logo = ""
  logoUrl = ""

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Todo',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }
  constructor(private employeeService: EmployeesService,
    private _cdRef: ChangeDetectorRef,
    private _companiesService: CompaniesService,
    private _toastr: ToastrService, private srvDoc: docService,
    private dialog: MatDialog,
    public authInfo: AuthInfoService,
    private _http: HttpClient,
  ) {
  }

  ngOnInit(): void {
    this.selectedItems = this.statusOptions.filter(e => e.item_id == this.today.getMonth() + 1);
    this.employeesPagedResut.pageSize = 1000
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.canUseOption = true
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.getData()
      }
    });
    applySort();
  }

  setCompanyToLocalStorage() {
    const company = this.branch?.companyId;
    localStorage.setItem('employees_companySelected', company ? JSON.stringify(this.branch) : undefined);
  }

  getData() {
    this._companiesService.getCompanies().subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this._toastr.error(err);
          })

          res.warnings.forEach(err => {
            this._toastr.warning(err);
          })

          return;
        }

        this.branches = res.dataList;
        res.dataList.filter(e => e.companyId == this.authInfo.getCompanyId()).forEach(e => {
          this.logo = e.logo
        })
        this.getEmployeeList();
      },
      error: (err) => {

      }
    });
  }

  async getLogo() {
    if (this.logo != "" && this.logoUrl == "")
      await this._http.get<any>(`${this.api_doc}/Document?guid=${this.logo}`)
        .toPromise()
        .then(res => {
          this.logoUrl = res.data;
        }, err => {
          console.log(err);
        })
  }

  onBranchChange() {
    this.getEmployeeList()
    this.setCompanyToLocalStorage()
  }

  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }

  filterSearch(event: FilterEvent) {

    this.employeesPagedResut.page = 1
    this.searchParameter = event
    if (event.propkeyId != 100) {
      this.advancedFilter = ','.repeat(9);
      this.getEmployeeList();
    } else {

    }
  }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  changeStatus() {
    this.getEmployeeList();
  }

  clearStorage() {
    localStorage.removeItem('action-change-tab-selected')
    localStorage.removeItem('personnel-actions-tab-selected')
  }

  getEmployeeList() {
    if (!this.canUseOption) {
      return
    }
    const advanced = this.advancedFilter ?? ','.repeat(9);
    const page = this.employeesPagedResut.page
    const pageSize = this.employeesPagedResut.pageSize
    const text = this.searchParameter?.value ?? ''
    const propKeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const status: number = this.selectedItems?.length === 2 || this.selectedItems?.length == 0 ? -1 : (this.selectedItems[0]?.item_id === 1 ? 1 : 0);

    if (this.selectedItems.length == 0) {
      this.selectedItems = this.statusOptions.filter(e => e.item_id == this.today.getMonth() + 1);
    }

    this.employeeService.getEmployeesPaginatedAdvancedBirthday(page, pageSize, propKeyId, text, status, 1, null, '', this.selectedItems[0].item_id, this.selectedItemsConditionPhoto[0].item_id).subscribe((res: any) => {
      if (!res.succeded) {

        res.errors.forEach(err => {
          this._toastr.error(err);
        })

        res.warnings.forEach(err => {
          this._toastr.warning(err);
        })

        return;
      }

      this.resultData = res.singleData.items;

      if (this.selectedItems.length > 0 && this.selectedItems[0].item_id > 0) {
        this.resultData = this.resultData.filter(e => new Date(e.birthday).getMonth() + 1 == this.selectedItems[0].item_id)
      }

      for (let i = 0; i < this.resultData.length; i++) {
        const item = this.resultData[i];
        this.resultData[i]["url"] = null

        this.getImageBirthday(i, item.profileImage).then(async (e: any) => {

        })
      }

      this.employeesListOriginal = this.resultData
      this.employees = this.resultData;
      this.employeesPagedResut = res.singleData
      this.getEmployeeActions();
      this.getLogo()
    }, err => this._toastr.error('Ha ocurrido un error inesperado al obtener la lista de Servidores Públicos'))

  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.getEmployeeList();
  }

  onItemDeSelect(item: any) {
    this.getEmployeeList();
  }

  onItemSelect(item: any) {
    this.getEmployeeList();
  }

  onSelectAll(item: any) {
    this.selectedItems = item;
    this.getEmployeeList()
  }


  getPaginatedRecords(event: PaginationEvent) {
    if (this.employeesPagedResut.page == event.selectedPage && this.employeesPagedResut.pageSize == event.registersPerPage) {
      return;
    }
    this.employeesPagedResut.page = event.selectedPage;
    this.employeesPagedResut.pageSize = event.registersPerPage;
    this.getEmployeeList()
  }

  config = {
    displayKey: "stringData",
    search: true,
    height: 'auto', //height of the liilts to auto. With auto height scroll will never appear
    placeholder: 'Elige una opción', //i    customComparator: ()=>{}, // a cus ndefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits e limited)
    moreText: 'more', // text to be di
    noResultsFound: 'Sin resultados!',
    searchPlaceholder: 'Buscar', // lab
    searchOnKey: 'stringData', // key on whiwill be selective search. if undefined this will be extensive search on all keys
  }

  getEmployeeActions() {
    this.employeeService.getEmployeeActions().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastr.error(res.errors[0]);
        return;
      }
      const employeeActions = res.dataList as any[];
      const employees = this.employeesListOriginal.map((em: any) => {
        let employee = { ...em };
        const employeeAction = employeeActions.find((e: any) => e.employeeId === em.employeeId);
        employee.employeeAction = employeeAction?.personalActionsType || "N/A";
        return employee;
      });
      this.employees = [...employees];
      this.employeesListOriginal = [...employees];
    });
  }

  exportToPdf() {
    if (this.selectedItems.length == 0) {
      this.selectedItems = this.statusOptions.filter(e => e.item_id == this.today.getMonth() + 1);
    }
    const propKeyId: number = this.searchParameter?.propkeyId ?? 0;
    const searchParameter: string = this.searchParameter?.value ?? '';
    const month: any = this.selectedItems[0].item_id
    const page: number = this.employeesPagedResut?.page;
    const pageSize: number = this.employeesPagedResut?.pageSize;
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.Emplpoyee_Birthday;
    const reportUrl = `${environment.reportUrl}/?Reportcode=${reportCode}&CompanyId=${companyId}&PropkeyId=${propKeyId}&SearchParameter=${searchParameter}&BudgetAreaId=${month}&Status=1&Page=${page}&PageSize=${pageSize}&AdvancedFilter=&HasImage=0`
    let parameters = {
      url: reportUrl,
      title: 'Cumpleaños',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

  async getImageBirthday(row, guid) {
    await this._http.get<any>(`${this.api_doc}/Document?guid=${guid}`)
      .toPromise()
      .then(res => {
        this.employeesListOriginal[row].url = res.data
        this.employees[row].url = res.data;
        this.employees[row].enclosureName = res.data;
        this.employees[row].isSelected = false
      }, err => {
        console.log(err);
      })
  }

  showImagen(data) {
    this.dialog.open(ShowDocumentModalComponent,
      {
        data: {
          src: data.url,
          title: "Foto"
        },
        width: '50%',
      }).afterClosed().subscribe(req => {

      })
  }

  selectItem(item) {
    this.sendItems = this.employees.filter(e => e.isSelected == true);
    console.log(this.sendItems);
  }

  sendMail() {
    if (this.sendItems.length == 0) {
      this._toastr.warning('Debes hacer una seleccion de las personas que estan de cumpleaños')
      return
    }

    this.dialog.open(ShowSubjectModalComponent,
      {
        data: {},
        width: '35%',
      }).afterClosed().subscribe(req => {
        console.log(req);
        if (req) {
          let data = {
            title: req.title,
            body: req.body,
            employees: this.sendItems,
            userId: this.authInfo.getUserId(),
            logo: this.logoUrl
          }

          this.employeeService.postEmployeesBirthday(data).subscribe(e => {
            console.log(e);
            if (e.warnings.length > 0) {
              this._toastr.warning(e.warnings[0])
            }

            if (e.dataList.length > 0) {
              this._toastr.success("Total enviado: " + e.dataList.length, "Proceso terminado")
            }
          })
        }

      })
  }
}
