<ng-container  *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.add)">
    <div class="card-body">
        <form class="row" [formGroup]="form">
            <div class="col form-group">
                <label for="">Fecha de Solicitud <span class="text-danger">*</span></label>
                <input formControlName="requestDate" type="date" class="form-control">
                <span style="position: absolute;" *ngIf="formIsInvalid"
                    class="text-danger">{{showError('requestDate')}}</span>
            </div>
            <div class="col form-group">
                <label for="">Fecha de Inicio <span class="text-danger">*</span></label>
                <input formControlName="startDate" type="date" class="form-control">
                <span style="position: absolute;" *ngIf="formIsInvalid"
                    class="text-danger">{{showError('startDate')}}</span>
            </div>
            <div class="col form-group">
                <label for="">Fecha de Término <span class="text-danger">*</span></label>
                <input formControlName="endDate" type="date" class="form-control">
                <span style="position: absolute;" *ngIf="formIsInvalid" class="text-danger">{{showError('endDate')}}</span>
            </div>
    
            <div class="row">
    
                <div class="col form-group">
                    <label for="">Comentario <span class="text-danger">*</span></label>
                    <textarea formControlName="comment" class="form-control"></textarea>
                    <span style="position: absolute;" *ngIf="formIsInvalid"
                        class="text-danger">{{showError('comment')}}</span>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Evidencia <span class="text-danger">*</span></label>
                        <div class="card align-items-center">
                            <div class="card-body">
                                <div [ngClass]="{'d-block': file.document=='', 'd-none' : file.document!=''}">
                                    <div class="evidenceBanns"></div>
                                </div>
                                <div *ngIf="file.document !=''">
                                    <div class="symbol symbol-150 d250 my-4 max-w-250px">
                                        <img alt="Pic" src="assets/images/cv.jpg" />
                                    </div>
                                    <div class="btn-group center" role="group">
                                        <button type="button" class="btn btn-primary btn-icon" data-toggle="modal"
                                            data-target="#document-bann">
                                            <i class="flaticon-ver"></i>
                                        </button>
                                        <button type="button" class="btn btn-danger btn-icon" (click)="deleteGuid()">
                                            <i class="flaticon-delete-1"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span style="position: absolute;" *ngIf="formIsInvalid"
                            class="text-danger">{{showError('evidence')}}</span>
                    </div>
                </div>
            </div>
        </form>
    
    </div>
</ng-container>
<div class="row gutter-b">
    <h2 class="text-primary gutter-b mb-2 d-flex justify-content-between flex-wrap"
   >
        Lista Amonestaciones
        <button (click)="save()"  
        *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.add)"
        class="btn btn-pill font-weight-bolder btn-shadow btn-success mr-3">
            <i class="flaticon-disco-flexible"></i>
            <span>Guardar</span>
        </button>
    </h2>
    <div class="table-responsive">
        <table class="table atbl table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <th class="text-left">Secuencia</th>
                    <th class="text-left">Fecha de Solicitud</th>
                    <th class="text-left">Fecha de Inicio</th>
                    <th class="text-left">Fecha de Término</th>
                    <th class="text-left">Evidencia</th>
                    <th class="text-left">Estatus</th>
                    <th class="text-center">Acción</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of banns">
                    <td class="text-left">{{item.permissionsId}}</td>
                    <td class="text-left">{{item.applicationDate |date: 'dd/MM/yyy'}}</td>
                    <td class="text-left">{{item.dateStart |date: 'dd/MM/yyy'}}</td>
                    <td class="text-left">{{item.dateEnd |date: 'dd/MM/yyy'}}</td>
                    <td>
                        <a *ngIf="item.evidence" (click)='openGuID(item.evidence)' data-toggle="tooltip"
                            title="Evidencia" class="btn btn-light-youtube font-weight-bold mb-3">
                            <i class="flaticon-pdf-file icon-2x"></i>
                        </a>
                        <p *ngIf="!item.evidence">N/A</p>
                    </td>
                    <td>
                        <ng-container *ngIf="item?.conditionId === 6">
                            <span class="font-weight-bold text-warning">En Espera de Aprobación</span>
                        </ng-container>
                        <ng-container *ngIf="item?.conditionId === 2">
                            <span class="font-weight-bold text-success">Aprobado</span>
                        </ng-container>
                        <ng-container *ngIf="item?.conditionId === 3">
                            <span class="font-weight-bold text-danger">Rechazado</span>
                        </ng-container>
                    </td>
                    <td class="actionsBtn">
                        <a *ngIf="authInfo.canDoAction(privilege.nameKeyOption, privilege.view)"
                            [routerLink]="['/pdf-control-document', 5,item.permissionsId, employee.employeeId]"
                            class="btn btn-sm btn-light-info btn-icon mr-2">
                            <i class="flaticon-ver" data-toggle="tooltip" title="Ver"></i>
                        </a>
                        <a *ngIf="(item.dateEnd |date: 'dd/MM/yyy')  > (currentDate |date: 'dd/MM/yyy')
                            && authInfo.canDoAction(privilege.nameKeyOption, privilege.edit)" 
                            (click)="editBann(item)" class="btn btn-icon btn-light-warning btn-sm m-1">
                            <i class="flaticon-edit" data-toggle="tooltip" title="Editar" ></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<el-modal target="document-bann" title="Evidencia">
    <iframe [src]='transform(file?.document)' width="100%" height="600px"></iframe>
</el-modal>