import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { EmployeeMaintenanceService } from 'src/app/services/employee-maintenance.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import Swal from 'sweetalert2';
import { Person } from '../pdf-preview/models/pdf-personal-record.model';
import { EmployeeInfo } from './models/EmployeeMaintenance';

declare const $: any;
@Component({
  selector: 'app-employee-maintenance',
  templateUrl: './employee-maintenance.component.html',
  styleUrls: ['./employee-maintenance.component.css']
})
export class EmployeeMaintenanceComponent implements OnInit {

  selectedEmployee: any;
  personInfo: Person;
  genders:ParameterControl[];
  subscriptions:Subscription = new Subscription();
  constructor(
    private employeeMaintenanceService: EmployeeMaintenanceService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private _parameterControlService: ParameterControlService,
    private _personService:PersonService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params:any) => {
      this.getPersonInfo(params.id)
    })
  }


  // getEmployee(employeeNumber){
    
  //   Swal.fire("Cargando información delServidor Público",'','info');
  //   Swal.showLoading()
  //   this.employeeMaintenanceService.getEmployeeByEmployeeNumber(employeeNumber).subscribe((res:any) => {
  //     if(res.errors.length > 0){ this.toastr.error("Ha ocurrido un error al obtener los datos delServidor Público"); return;}
  //     this.selectedEmployee = res.dataList[0];
  //     Swal.close()
  //     console.log(res);
  //   }, (error) => {
  //     console.log(error);
  //     this.toastr.error("Ha ocurrido un error al obtener los datos delServidor Público")
  //   })
  // }

  getPersonInfo(employeeNumber:number){

    Swal.fire("Cargando información del Servidor Público",'','info');
    Swal.showLoading()
    let sub = forkJoin([
      this.employeeMaintenanceService.getEmployeeByEmployeeNumber(employeeNumber),
      this._parameterControlService.getParameters(keyWord.Gender)
    ]).subscribe(responses=>{

        if(responses[0].errors.length > 0){ this.toastr.error("Ha ocurrido un error al obtener los datos del Servidor Público"); return;}
        this.selectedEmployee = responses[0].dataList[0];
    
                
        if(responses[1].errors.length> 2) { this.toastr.error("Error al obtener ls géneros"); return;}
        this.genders = responses[1].dataList;   
        Swal.close()
      }, error => {
        this.toastr.error("Error al obtener los géneros");
        }, ()=>{
          this._personService.getPersonById(this.selectedEmployee.personId).subscribe((response:ResponseModel<Person>)=>{
            this.personInfo = response.succeded? response.dataList[0] : null;  
        
          })
  
        })
    this.subscriptions.add(sub);
  }
  updateEmployee(data){
    
  }
  ngOnDestroy(): void {
    $('.tooltip.show').removeClass( "show" );
  }
 
}
