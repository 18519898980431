<div class="row">
    <div class="col-12 card card-custom" data-card="true">
        <div class="card-header">
            <div class="card-title flex-column align-items-start">
                <h3>{{titlePage}}</h3>
                <div class="card-subtitle mt-2 ">
                    <h6 class="subtitle">
                        {{subTitlePage}}
                    </h6>
                </div>
                <!-- <el-search-bar placeholder="Buscar"></el-search-bar> -->
            </div>
            <div class="card-toolbar ml-auto">
                <button class="btn btn-success btn-pill right-0 font-weight-bolder h-40px" data-toggle="modal">
                    <i class="flaticon2-plus"></i>
                    Agregar
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-responsive">
                <table class="footable table mt-2 table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th data-field="Cedula" class="datatable-cell datatable-cell-sort">
                                header
                            </th>
                            <th data-field="Actions" data-autohide-disabled="false"
                                class="datatable-cell-left datatable-cell datatable-cell-sort actionsBtn">
                                Acción
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr data-row="0" class="datatable-row" style="left: 0px">
                            <td class="datatable-cell">
                                Resultados
                            </td>
                            <td class="datatable-cell-left datatable-cell actionsBtn" data-field="Actions"
                                data-autohide-disabled="false" aria-label="null">
                                <button type="button" class="btn btn-sm btn-light-skype btn-icon mr-2" data-title="Ver"
                                    data-toggle="modal">
                                    <i class="flaticon-ver icon-2x"></i>
                                </button>
                                <button type="button" class="btn btn-sm btn-light-success btn-icon mr-2"
                                    data-title="Editar" data-toggle="modal">
                                    <i class="flaticon-edit "></i>
                                </button>
                                <button type="button" class="btn btn-sm btn-light-danger btn-icon m-1">
                                    <i class="flaticon-delete"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>