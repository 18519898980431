import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { VacanciesService } from 'src/app/services/vacancies.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {

  skillsType: any[] = []
  skills: any[] = []
  levelsType: any[] = []
  levels: any[] = []
  skillsAdded: any[] = [];

  skillsForm = new FormGroup({
    typeSkill: new FormControl('', Validators.required),
    skill: new FormControl('', Validators.required),
    typelevel: new FormControl('', Validators.required),
    level: new FormControl('', Validators.required),
  })

  config = {
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  };



  constructor(private pService: PersonalRequsitionService, private _toastService: ToastService, @Inject(MAT_DIALOG_DATA) public data: any,
    private vacanciesService: VacanciesService, public dialogRef: MatDialogRef<SkillsComponent>) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getData();
  }
  getData() {
    this.vacanciesService.getTypeSkill().subscribe(res => {
      this.skillsType = res.dataList
    })
  }

  skillsChange(selectName: string) {

    switch (selectName) {
      case "typeSkill":
        if (this.skillsForm.value.typeSkill.typeSkillsId) {
          this.vacanciesService.getSkills(this.skillsForm.value.typeSkill.typeSkillsId).subscribe(res => {
            this.skills = []
            this.skillsForm.get('skill').reset()
            this.skills = res.dataList
          })
        }
        break;
      case "skill":
        if (this.skillsForm.value.skill.skillsId) {
          this.vacanciesService.getTypeSkillsLevel(this.skillsForm.value.skill.skillsId).subscribe(res => {
            this.levelsType = []
            this.skillsForm.get('typelevel').reset()
            this.levelsType = res.dataList
          })
        }
        break;
      case "typelevel":
        if (this.skillsForm.value.typelevel.typeLevelSkillsId) {
          this.vacanciesService.getSkillsLevel(this.skillsForm.value.typelevel.typeLevelSkillsId).subscribe(res => {
            this.levels = []
            this.skillsForm.get('level').reset()
            this.levels = res.dataList
          })
        }
        break;

      default:
        break;
    }

  }

  Create() {
    let newSkill = {
      skill: this.skillsForm.value.skill,
      skillLevel: this.skillsForm.value.level,
      typelevel: this.skillsForm.value.typelevel
    }
    this.skillsAdded.push(newSkill)
    const row = {
      Applied: false, data: newSkill
    }
    this.dialogRef.close(row,);
  }

  Cancel(): void {
    const row = { Applied: false }
    this.dialogRef.close(row);
  }
}
