<h2 class="mat-dialog-title"> <span class="h-20px label label-danger label-dot mr-3 w-20px"></span> Resultado de Envíos
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
</h2>

<div class="mat-dialog-content">
    <div class="card card-custom">
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                        <tr class="bg-primary">
                            <th class="text-left">Servidor Público</th>
                            <th class="text-left">Correo</th>
                            <th class="text-center">Estatus</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of paginatedResultPayrollVoucherMail">
                            <td class="text-left">{{item.name}}</td>
                            <td class="text-left">{{item.mail || 'N/A'}}</td>
                            <td class="text-center">
                                <i *ngIf="!item.error" class="flaticon-checked icon-2x text-success"></i>
                                <i *ngIf="item.error" class="flaticon2-cross icon-2x text-danger" data-toggle="tooltip"
                                    [attr.data-original-title]="item.error"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                   <el-caption #paginator [originalRecordsLength]="resultPayrollVoucherMailOriginalList.length"
                        [listedRecordsLength]="paginatedResultPayrollVoucherMail.length"
                        [registerQuantity]="resultPayrollVoucherMail.length"
                        [records]="resultPayrollVoucherMail" (onChage)="getPaginatedRecords($event)">
                    </el-caption>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2 "
        (click)="close()" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
</div>