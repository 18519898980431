
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { DataService } from 'src/app/services/data.service';
import { Account } from '../../../shared/models/account';
import { MatDialog } from '@angular/material/dialog';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  opened = false
  account = this.accountService.accountValue;
  constructor(private ds: DataService,
              private accountService: AccountService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.SubscribeEvents();
  }

  SubscribeEvents(): void {
    this.ds.account$.subscribe(user => {
      this.account = user;
    })
  }

  openNotification(): void {
    this.dialog.open(NotificationComponent, {
      width: '65%',
    });
  }

}
