<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-3 w-20px"></span> 
        {{data?.mode === 1 ? 'Agregar': data?.mode === 2 ? 'Detalle' : 'Editar' }} Beneficiario</h2>
    <button type="button" class="close" aria-label="Close">
        <i aria-hidden="true" (click)="close()" class="ki ki-close text-white font-size-h3"></i>
    </button>
</div>
<div class="mat-dialog-content">
    <div class="card-body">
        <div class="row" [formGroup]="beneficiaryForm">
            <div class="form-group col-md-4">
                <label class="">Tipo de identificación <span class="text-danger">*</span></label>
                <ngx-select-dropdown formControlName="identificationType" (change)="typeIdChange($event.value?.ocode)"
                    [options]="typeIdList" [config]="dropdownConfig('stringData')" class=" flex-1">
                </ngx-select-dropdown>
            </div>
            <div class="form-group col-md-4">
                <label class="d-block">Documento de Identidad<span
                        class="text-danger text-monospace legend">*</span></label>
                <div class="input-group">
                    <input *ngIf="beneficiaryForm.get('identificationType').value?.ocode == 1" type="text" class="form-control form-control-solid form-control-lg"
                        placeholder="Escriba aquí su búsqueda" [mask]="mask" formControlName="identification">
                    <input *ngIf="beneficiaryForm.get('identificationType').value?.ocode != 1" type="text" class="form-control form-control-solid form-control-lg"
                        placeholder="Escriba aquí su búsqueda" formControlName="identification">
                </div>
            </div>

            <div class="form-group col-md-4">
                <label class="d-block">Nombre del Beneficiario<span
                        class="text-danger text-monospace legend">*</span></label>
                <div class="input-group">
                    <input type="text" class="form-control form-control-solid form-control-lg"
                        placeholder="Escriba aquí su búsqueda" formControlName="beneficiaryName">
                </div>
            </div>
        </div>
    </div>

</div>

<div class="mat-dialog-actions" [align]="'end'">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="  close()">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button (click)="submitBeneficiary()" *ngIf="data?.mode !== 2" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success"
        type="button" [disabled]="beneficiaryForm.invalid">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>