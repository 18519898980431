<div class="row">
    <div class="col-6">
        <p *ngIf="!permission?.commisionServiceReasonId" class="label-item mb-2">Tipo de Permiso: {{permission?.typePermissions}} </p>

        <p class="label-item mb-2">Fecha de Solicitud: {{permission?.applicationDate | date: 'dd/MM/yyyy'}}: </p>
        <p class="label-item mb-2">Fecha de Inicio: {{permission?.dateStart | date: 'dd/MM/yyyy'}} </p>
        <p class="label-item mb-2">Fecha de Termino: {{permission?.dateEnd | date: 'dd/MM/yyyy'}} </p>
        <p class="label-item mb-2">Hora de Inicio: {{convert(permission?.hourStart)}}  </p>
        <p class="label-item mb-2">Hora de Termino: {{convert(permission?.hourEnd)}}</p>
    </div>
    <div class="col-6 ">
        <p *ngIf="!permission?.commisionServiceReasonId" class="label-item mb-2">Motivo del permiso: {{permission?.reason}}</p>
        <p *ngIf="permission?.commisionServiceReasonId" class="label-item mb-2">Motivo de Comisión Servicio: {{permission?.commissionServiceReason}}</p>
        <p class="label-item mb-2">Comentarios: {{permission?.comment || "N/A"}}</p>
       
    </div>
  
</div>