
export interface IPeriod {
    payrollPeriodId: number;
    periodId: number;
    description: string;
    sendLimitDate: Date;
    conditionId: number;
    status: boolean;
    companyId: number;
    createDate: Date;
    createUserId: number;
    modifyDate: Date;
    modifyUserId: number;
}
export interface IHeader {
    headerId: number;
    payrollPeriodId: number;
    payrollTypeId: number;
    payrollType: string;
    conditionId: number;
    headerCondition: string;
    companyId: number;
    status: boolean;
    createDate: Date;
    createUserId: number;
    modifyDate?: any;
    modifyUserId?: any;
    versionId: number;
    period: string;
    budgetAreaId: number;
    budgetArea: string;
    sendLimitDate: Date;
}

export interface IPeriodDetailsResume {
    headerId: number;
    totalGrossSalary: number;
    totalTss: number;
    totalIsr: number;
    totalDiscount: number;
    totalNetIncome: number;
    totalEmployee: number;
    companyId: number;
}
export interface IGetOverallSummary {
    headerId: number;
    payrollPeriodId: number;
    budgetAreaId: number;
    payrollTypeId: number;
    employeeId: number;
    firstName: string;
    lastName: string;
    sexId: number;
    sex: string;
    positionId: number;
    positionName: string;
    departmentId: number;
    departmentName: string;
    employeeTypeId: number;
    employeeType: string;
    admissionDate: Date;
    contractExpiration: Date;
    grossSalary: number;
    totalSalary: number;
    afp: number;
    isr: number;
    sfs: number;
    otherDiscounts: number;
    totalDiscount: number;
    netIncome: number;
    companyId: number;
    socialReason: string;
    status: boolean;
}
export interface IGetDetailByDeparments {
    headerId: number;
    departmentId: number;
    departmentName: string;
    totalNetIncome: number;
    employeeCount: number;
    companyId: number;
    typeEmployeeId: number;
}

export enum PeriodConditions{
    open = 1,
    closed = 0
}


export interface IHeaderDepartmentEmployeeGET {
    headerId: number;
    payrollPeriodId: number;
    budgetAreaId: number;
    payrollTypeId: number;
    employeeId: number;
    firstName: string;
    lastName: string;
    sexId: number;
    sex: string;
    positionId: number;
    positionName: string;
    departmentId: number;
    departmentName: string;
    employeeTypeId: number;
    employeeType: string;
    admissionDate: Date;
    contractExpiration?: Date;
    grossSalary: number;
    totalSalary: number;
    afp: number;
    isr: number;
    sfs: number;
    otherCredits: number;
    otherDiscounts: number;
    totalDiscount: number;
    totalCredits: number;
    netIncome: number;
    companyId: number;
    socialReason: string;
    status: boolean;
    conditionId: number;
    budgetArea: string;
}
