<div class="card card-custom">
    <div class="card-header mt-3">
        <div class="card-title">
            <span class="card-icon">
                <i class="flaticon-lista-de-verificacion-2 icon-3x text-danger"></i>
            </span>
            <h2 class="d-flex flex-column text-primary">
                Historial de Revisiones
            </h2>
        </div>
        <div class="card-toolbar">
            <a (click)="goBack()" class="btn btn-pill font-weight-bolder btn-shadow btn-warning goBackButton">
                <i class="flaticon2-left-arrow-1"></i>
                <strong class="font-size-h6">Volver a la Pantalla Anterior </strong>
            </a>
        </div>
    </div>

    <div class="card-header mt-3">
        <div class="w-100">
            <div class="row gutter-b align-items-center">
                <div class="col-lg-2 d-flex align-items-center justify-content-center">
                    <div class="symbol symbol-150 d250 my-4 max-w-250px">
                        <img alt="Pic" [src]="photoUrl ? photoUrl: 'assets/images/no-user.png'"
                            onerror="this.error=null; this.src='assets/images/no-user.png'" />
                    </div>
                </div>
                <div class="col-lg-10 row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Código del Docente</label>
                            <h3>
                                {{employeeInfo?.employNumber}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Docente</label>
                            <h3>
                                {{employeeInfo?.name}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Identificación</label>
                            <h3 *ngIf="employeeInfo?.typeIdentificationId == 1">
                                {{employeeInfo?.personalIdentification
                                | mask: '000-0000000-0'}}</h3>
                            <h3 *ngIf="employeeInfo?.typeIdentificationId != 1">
                                {{employeeInfo?.personalIdentification}}</h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Teléfono</label>
                            <h3>
                                {{(employeeInfo?.phoneNumber ? employeeInfo?.phoneNumber : "N/A") |
                                mask:employeeInfo?.phoneNumber ?"(000)-000-0000":"N/A"}}
                            </h3>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Unidad Organizativa</label>
                            <h3>
                                {{employeeInfo?.departmentName}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">

                        <div class="form-group">
                            <label class="mb-0">Cargo</label>
                            <h3>
                                {{employeeInfo?.positionName}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Precio por Hora</label>
                            <h3>
                                {{employeeInfo?.salary | currency:"RD$"}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Tipo de Servidor Público</label>
                            <h3>
                                {{employeeInfo?.employeeType}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Fecha Ingreso</label>
                            <h3>
                                {{employeeInfo?.admissionDate | date:"dd/MM/yyyy"}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Correo Institucional</label>
                            <h3>
                                {{employeeInfo?.institionalEmail}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Estatus</label>
                            <h3 *ngIf="employeeInfo?.status" class="text-success">
                                Activo
                            </h3>
                            <h3 *ngIf="employeeInfo?.status == false && employeeInfo?.employeeCondition !== 2 && !employeeInfo?.wasHiringCanceled"
                                class="text-danger">
                                Desvinculado
                            </h3>
                            <h3 *ngIf="employeeInfo?.status == false && employeeInfo?.employeeCondition === 2 && !employeeInfo?.wasHiringCanceled"
                                class="text-danger">
                                Inactivo
                            </h3>
                            <h3 *ngIf="employeeInfo?.status == false && employeeInfo?.wasHiringCanceled"
                                class="text-danger">
                                Nombramiento Anulado
                            </h3>
                            <h3 *ngIf="employeeInfo?.status == undefined" class="text-warning">
                                N/A
                            </h3>
                        </div>
                    </div>

                    <div class="col-md-3" *ngIf="currentEmployeeCondition?.employeeId">
                        <div class="form-group">
                            <label class="mb-0">Condición Actual</label>
                            <h3 lass="text-success">
                                {{currentEmployeeCondition?.personalActionsType}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Nivel Académico</label>
                            <h3 lass="text-success">
                                {{employeeInfo?.academicLevel === undefined ? '' :
                                (employeeInfo?.academicLevel == null ? 'N/A' : employeeInfo?.academicLevel) }}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Grupo Ocupacional</label>
                            <h3 lass="text-success">
                                {{employeeInfo?.occupationalGroup === undefined ? '' :
                                (employeeInfo?.occupationalGroup == null ? 'N/A' : employeeInfo?.occupationalGroup)
                                }}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Sucursal</label>
                            <h3 lass="text-success">
                                {{employeeInfo?.branch || 'N/A'}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="mb-0">Categoria</label>
                            <h3 lass="text-success">
                                {{employeeInfo?.employeeCategory || 'N/A'}}
                            </h3>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="p-5">
        <app-teacher-reviews-list [employeeId]="employeeId"></app-teacher-reviews-list>
    </div>
</div>