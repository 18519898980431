import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import { ModalViewsEnum } from 'src/app/models/modal-views';
import { ICancelPersonalActionRequestPost, ICancelPersonalActionRequestView, IPersonalActionView } from 'src/app/shared/models/employee-condition.model';
import { environment } from 'src/environments/environment';
import { ShowFileVacationModalComponent } from '../../personnel-actions/personnel-actions-tap/show-file-vacation-modal/show-file-vacation-modal.component';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import XHRUpload from '@uppy/xhr-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { docService } from 'src/app/services/doc.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CancelPersonalActionService } from 'src/app/services/cancel-personal-action.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { changeDetails } from '../../approval-employee-change-actions/models/employee-change';
import { ModalTextAreaComponent } from 'src/app/shared/modal-text-area/modal-text-area.component';
import { UppyFileComponent } from 'src/app/shared/uppy-file/uppy-file.component';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-cancel-personal-action-modal',
  templateUrl: './cancel-personal-action-modal.component.html',
  styleUrls: ['./cancel-personal-action-modal.component.css']
})
export class CancelPersonalActionModalComponent implements OnInit {
  title: string = (this.data.viewType == ModalViewsEnum.Create) ? 'Agregar' : (this.data.viewType == ModalViewsEnum.Edit) ? 'Editar' : '';
  
  reasonList: ParameterControl[] = [];
  
  evidenceGUID = '';
  
  viewTypes = ModalViewsEnum;

  form: FormGroup = new FormGroup({
    actionCanceledId: new FormControl(0, Validators.required),
    personalActionId: new FormControl(null, Validators.required),
    typePersonalActionId: new FormControl(null),
    typePermissionId: new FormControl(null),
    employeeId: new FormControl(null),
    evidence: new FormControl(null),
    comment: new FormControl(null, [Validators.maxLength(500)]),
    reasonId: new FormControl(null, Validators.required),
    userId: new FormControl(null),

    reasonObject: new FormControl(null),
  });

  dropdownReasons = this.dropdownConfig('stringData');

  isPermanentChange: boolean = false;
  permanentChangeInfo!: changeDetails;
  requestInfo!: ICancelPersonalActionRequestView;

  @ViewChild('uppyFile')
  uppyFile: UppyFileComponent | null = null;
  constructor(public dialogRef: MatDialogRef<CancelPersonalActionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {cancelRequestData: ICancelPersonalActionRequestView, actionData: IPersonalActionView, viewType: ModalViewsEnum},
    private dialog: MatDialog,
    private toastr: ToastrService,
    private authInfo: AuthInfoService,
    private personnelActionsService: PersonnelActionsService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private docService: docService,
    private paramService: ParameterControlService,
    private cancelActionService: CancelPersonalActionService) {  
      
      this.patchData(this.data?.cancelRequestData);
  }

  ngOnInit(): void {
  }

  dropdownConfig(displayKey: string = "label"){
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search:true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder:'Seleccione una opción', // text to be displayed when no item is selected defaults to Select,
      //customComparator: (a, b)=> a.id - b.id, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder:'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  patchData(actionData: ICancelPersonalActionRequestView) {
    this.form.patchValue({
      ...actionData
    });

    if(actionData?.typePersonalActionId == 2){
      this.isPermanentChange = true;
      this.getPermanentChangeDetails(actionData.personalActionId);
    }else{
      this.isPermanentChange = false;
    }

    if(this.data.viewType == ModalViewsEnum.View ){
      this.form.disable();
      this.getRequestInfo(actionData);
    }else{
      this.getReasonParameters();
    }
    
  }

  getRequestInfo(actionData: ICancelPersonalActionRequestView){
    const params = {
      ActionCanceledId: actionData.actionCanceledId,
      PersonalActionId: actionData.personalActionId,
      TypePersonalActionId: actionData.personalActionId,
      TypePermissionId: actionData.personalActionId,
      RequestByUserId: null,
      ApprovedByUserId: null,
      AppliedByUserId: null,
      RejectedByUserId: null,
      EmployeeId: null,
      Type: 1
    }

    this.cancelActionService.getCancelRequests(params).subscribe({
      next:(res)=>{

        if(!res.succeded){
          res.errors.forEach(err=>{
            this.toastr.error(err);
          })

          res.warnings.forEach(warn=>{
            this.toastr.warning(warn)
          });
          return;
        }

        const requestInfo = res.dataList[0];
        this.requestInfo = requestInfo;

        this.form.patchValue({
          ...requestInfo
        });

        if (requestInfo?.evidence) {
          this.evidenceGUID = this.getGuid(requestInfo?.evidence);
        }

        this.getReasonParameters(requestInfo?.reasonId);

      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error tratando de consultar la información de la solictud de información.');
      }
    })
  }
 
  getPermanentChangeDetails(id) {
    this.personnelActionsService.getChangeDetails(id, 1).subscribe({
      next:(res)=>{
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(err => {
            this.toastr.warning(err);
          })
          return;
        }

        this.permanentChangeInfo = res.dataList[0];

      },
      error: (err)=>{
        this.toastr.error('Ha ocurrido un error tratando de consultar la información de la acción de personal');
      }
    })

  }

  getReasonParameters(reasonId: number = null){
    this.paramService.getParameters(keyWord.ReasonsToCancelPersonalAction).subscribe({
      next: (res)=>{
        if(!res.succeded){
          res.errors.forEach(err => {
            this.toastr.error(err);
          });

          return;
        }

        this.reasonList = res.dataList;

        if(this.data.viewType != ModalViewsEnum.Create && reasonId > 0){
          let temp = this.reasonList.find(x=>x.ocode == reasonId);
          this.form.get('reasonObject').setValue(temp);
          if(temp){
            this.changeReason(temp)
          }
        }

      },
      error: (err)=> {
          this.toastr.error('Ha ocurrido un error tratando de consultar la lista de razones');
      },
    });
  }

  changeReason(value: ParameterControl){
    this.form.get('reasonId').setValue(value?.ocode);
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  sendRequest(){
    if(this.form.invalid){
      this.toastr.warning('Debe completar los campos obligatorios');
      return;
    }

    this.uppyFile.handleFile(guid => {
      let model: ICancelPersonalActionRequestPost = this.form.getRawValue();
      model.evidence = guid;
      model.userId = this.authInfo.getUserId();
  
      this.cancelActionService.insertCancelRequest(model).subscribe({
        next:(res)=>{
          if (!res.succeded) {
            res.errors.forEach(err => {
              this.toastr.error(err);
            })
  
            res.warnings.forEach(err => {
              this.toastr.warning(err);
            })
            return;
          }
  
          this.toastr.success('Solicitud de anulación registrada satisfactoriamente')
          this.dialogRef.close(true);
        },
        error:(err)=>{
          this.toastr.error('Ha ocurrido un error tratando de consultar la información de la acción de personal');
        }
      });
    });
  }

 
  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  openEvidence(guid, isBase64: boolean = false, base64: string = null) {
    this.dialog.open(ShowFileVacationModalComponent, {
      data: { guid: guid, title: 'Evidencia', isBase64, base64 },
      width: '50%',
    });
  }

  openCommentModal(text: string){
    this.dialog.open(ModalTextAreaComponent, {
      data: { text, inputLabel: 'Razón de rechazo',modalTitle: 'Rechazo de Solicitud de Anulación', rows: 9 },
      width: '50%',
    });
  }

  isDate(date): boolean {
    if (this.isNumeric(date)) {
      return false;
    }
    return (new Date(date).toString() !== "Invalid Date") && !isNaN(Number(new Date(date)));
  }

  isNumeric(value): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    return !isNaN(Number(value));
  }

  setEvidence(guid: GUID){
    this.form.get('evidence').setValue(guid);
  }

}