import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICompany } from 'src/app/shared/models/company';
import { RequestMetaData } from 'src/app/shared/models/strongly-typed-response.model';
import { environment } from '../../environments/environment';
import { AuthInfoService } from './auth-info.service';
import { ResponseModel } from '../shared/models/strongly-typed-response.model';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private readonly api = environment.api_core_url;
  private readonly api_lobby = environment.api_core_sigei;

  constructor(private http: HttpClient, 
    private authInfo: AuthInfoService) { }

  getCompanies() {
    return this.http.get<ResponseModel<ICompany>>(`${this.api_lobby}/Companies?CompanyCode=${this.authInfo.getCompanyId()}&Status=1&Type=1`)
  }
  getAllCompanies() {
    return this.http.get(`${this.api_lobby}/Companies?companyCode=0&level=2`)
  }
  getInactivesCompanies(){
    return this.http.get(`${this.api_lobby}/Companies?Level=2&Status=2`)
  }

  getCompanyById(companyId: number, level: number = 2, status:number = 1) {
    return this.http.get<ResponseModel<ICompany>>(`${this.api_lobby}/companies?companyCode=${companyId}&level=${level}&status=${status}`)
  }

  getCompanyByUserAndId() {
    return this.http.get<ResponseModel<any>>(`${this.api_lobby}/companies?companyCode=${this.authInfo.getCompanyId()}&level=2&status=1`)
  }

  putCompany(company: ICompany) {
    return this.http.put(`${this.api_lobby}/companies`, company);
  }
  saveCompany(company) {
    return this.http.post(`${this.api_lobby}/companies`, company)
  }
  getDepartments(companyId: number = this.authInfo.getCompanyId(), IdDepartment: number = 0) {
    return this.http.get(`${this.api}/CompanyStructure?companyId=${companyId}&IdDepartment=${IdDepartment}`)
  }

  getDepartmentsChildren(companyId: number , IdDepartment: number, type:number = 1, status:boolean = true) {
    return this.http.get(`${this.api}/CompanyStructure/GetCompanyStructure?CompanyId=${companyId}&IdDepartment=${IdDepartment}&Type=${type}&Status=${status}`).toPromise()
  }

  deleteCompany(companyId: number) {
    return this.http.delete(`${this.api_lobby}/companies?companyCode=${companyId}`)
  }
  getLocations() {
    return this.http.get<any>(`${this.api}/Locations`);
  }


  postStructure(data: ICompany) {
    return this.http.post<any>(`${this.api}/CompanyStructure`, data)
  }

  updateStructure(data: ICompany):Observable<RequestMetaData> {
    return this.http.put<RequestMetaData>(`${this.api}/CompanyStructure`, data)
  }
  getStructure(company) {
    return this.http.get<any>(`${this.api}/Company/Structure3?CompanyId=${company}`)
  }

  getCompaniesWithChildren(){
    const companyId = this.authInfo.getCompanyId()
    return this.http.get(`${this.api_lobby}/Companies?CompanyCode=${companyId}&Status=1&Type=2`)
  }
  getExecutionUnits(chapter, subchapter){
    let params = new HttpParams()
    params = params.append('ChapterCode', chapter)
    params = params.append('SubChapterCode', subchapter)
    params = params.append('CompanyId', this.authInfo.getCompanyId().toString())
    params = params.append('UserId', this.authInfo.getUserId().toString())
    params = params.append('Type', '0')
    return this.http.get(`${this.api_lobby}/companies/execution-units`, {params})
  }

}