<div class="form-group ml-2 estatusDropdownd">
    <label class="text-primary">
        Estatus
        <span class="text-danger">*</span>
    </label>
    <div class="input-group">
        <form [formGroup]="myForm" class="w-100">
            <ng-multiselect-dropdown
                [placeholder]="'Seleccionar'"
                [settings]="dropdownSettings"
                formControlName="city"
                [disabled]="disabled"
                [data]="cities"
                [(ngModel)]="selectedItems"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                >
            </ng-multiselect-dropdown>
        </form>
    </div>
</div>
