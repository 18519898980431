<div class="modal-mobile" [ngStyle]="{'width': data.width}">
    <div class="modal-dialog modal-notify md-toast-success mt-0 mb-0" role="document" style="width: 100% !important; max-width: 100% !important;">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead text-white">{{data.titulo}}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Cancel()">
            <span aria-hidden="true" class="white-text">&times;</span>
          </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="text-center">
                    <i class="far fa-question-circle fa-4x mb-3 animated rotateIn confirmacion-color"></i>
                    <p>{{data.message}}</p>
                </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" class="btn btn-outline-red btn-lg waves-effect bg-white" (click)="Accept()">{{btnAceptar}}</a>
                <a type="button" class="btn btn-outline-red btn-lg waves-effect bg-white" (click)="Cancel()">{{btnCancelar}}</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>