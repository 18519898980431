import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OvertimeActivityService {

  api = environment.api_url

  constructor(
    private http: HttpClient,
  ) { }


  getOvertimeActivity(companyId){
    return this.http.get(`${this.api}/OvertimeActivity?type=6&companyId=${companyId}`)
  }
  getOvertimeActivityWithSettings(companyId){
    return this.http.get(`${this.api}/OvertimeActivity/GetOvertimeActivityWithSettings?type=6&companyId=${companyId}`)
  }
  getOvertimeActivityById(companyId, overtimeActiviyId){
    return this.http.get(`${this.api}/OvertimeActivity?type=3&id=${overtimeActiviyId}&companyId=${companyId}`)
  }
  acceptRRHH(model){
    return this.http.post(`${this.api}/OvertimeActivity/acceptRRHH`, model)
  }
  rejectRRHH(model){
    return this.http.post(`${this.api}/OvertimeActivity/rejectRRHH`, model)
  }

  getOvertimeActivityPeriods(companyId){
    return this.http.get(`${this.api}/OvertimeActivity/GetOvertimeActivityPeriods?type=1&companyId=${companyId}`)
  }
  rejectFinance(model){
    return this.http.post(`${this.api}/OvertimeActivity/rejectFinance`, model)
  }
  approvelLevel(model){
    return this.http.post(`${this.api}/OvertimeActivity/approveLevel`, model)
  }

  process(model){
    return this.http.post(`${this.api}/OvertimeActivity/process`, model)
  }
}
