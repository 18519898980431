<div class="table-responsive">
    <table class="table table-hover table-vertical-center">
        <thead>
            <tr class="bg-primary">
                <th class="text-center">Unidades Funcionales</th>
                <th>Escalafones</th>
                <th>Agrupamientos</th>
                <th>Tipos de Servidores Públicos</th>
                <th class=" text-center">Área</th>
             <!--    <th class="actionsBtn" style="width: 100px;">Acciones</th> -->
            </tr>
        </thead>
        <tbody>
            <tr data-row="0" class="datatable-row"
                style="left: 0px;">
                <td class="text-center"></td>
                <td></td>
                <td></td>
                <td></td>
                <td class=" text-center"></td>
        <!--         <td data-field="Actions" data-autohide-disabled="false" aria-label="null"
                    class="datatable-cell-left datatable-cell">
                    <div class="d-flex justify-content-center  px-5  " style="width: max-content">
                        <div class="justify-content-start  px-5">
                        <a tooltip="Ver" placement="top" data-toggle="modal"
                            class="btn btn-sm btn-light-skype btn-icon mr-2">
                            <i class="flaticon-ver icon-2x" data-toggle="tooltip" data-title="Ver"></i>
                        </a>
                        <a  tooltip="Editar" placement="top"
                            class="btn btn-sm btn-light-warning btn-icon mr-2">
                            <i class="flaticon-edit" data-toggle="tooltip" data-title="Editar"></i>
                        </a>
                         <a  tooltip="Inactivar"
                            placement="top" class="btn btn-sm btn-light-success btn-icon mr-2">
                            <i class="flaticon-etiqueta icon-2x " data-toggle="tooltip"
                                data-title="Aprobar"></i>
                        </a> 
                        <a class="btn btn-icon btn-light-danger btn-sm m-1">
                            <i class="las la-file-invoice-dollar  icon-2x" data-toggle="tooltip" title="Fórmulas"></i>
                        </a>
                   </div>
                   </div>
                </td> -->
            </tr>
        </tbody>
    </table>
    <div>
        <el-caption #paginator></el-caption>
    </div>
</div>

<!--div class="mat-dialog-actions mt-2 p-0 mb-0 text-right" [align]="'end'">
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div-->