import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { FilesUnit, PersonalGuidEntity, personRequiredFiles } from 'src/app/shared/models/candidate-registration.model';
import { AuthInfoService } from './auth-info.service';

@Injectable({
    providedIn: 'root',
})

export class personalRequiredFilesService {
    private readonly url_core = environment.api_core_url
    private readonly url_api_doc = environment.api_doc
    constructor(private _http: HttpClient, private authInfo: AuthInfoService) { }

    get(PersonId: number): Observable<any> {
        let companyId = this.authInfo.getCompanyId()
        return this._http.get<any>(`${this.url_core}/PersonalRequiredFiles?PersonId=${PersonId}&companyId=${companyId}`);
    }

    save(PersonalRequiredFilesEntities: personRequiredFiles): Observable<any> {
        return this._http.post<any>(`${this.url_core}/PersonalRequiredFiles`, PersonalRequiredFilesEntities);
    }

    update(PersonalRequiredFilesEntities: personRequiredFiles): Observable<any> {
        return this._http.put<any>(`${this.url_core}/PersonalRequiredFiles`, PersonalRequiredFilesEntities);
    }

    delete(Erfid: number): Observable<any> {
        return this._http.delete<any>(`${this.url_core}/PersonalRequiredFiles?Erfid=${Erfid}`);
    }
}
