<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="fs-3 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px "></span> Familiares en la
        Institución
    </h2>
</div>

<div class="mat-dialog-content-custom">
    <div class="card-body-custom">
        <form [formGroup]="relativesForm">
            <div class="form-group">
                <label class="">Parentesco <span class="text-danger">*</span></label>
                <ngx-select-dropdown [options]="relationShipsList" formControlName="relationship"[config]='dropdownConfig("description")'
                    ></ngx-select-dropdown>
                    <errors-component [controlName]="relativesForm.controls['relationship']"></errors-component>
            </div>
    
            <div class="form-group">
                <label class="">Nombre Completo <span class="text-danger">*</span></label>
                <input (input)="removeWhitespaces($event, relativesForm.get('relativeName'))" formControlName="relativeName" class="form-control form-control-lg" type="text" >
                <!-- <el-input id="relationShipName" formControlName="relativeName" placeholder="" type="text"
                    onkeypress="removeWhitespaces($event);return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32 || event.charCode == 209 || event.charCode == 241 )"
                    autocomplete="false"></el-input> -->
                    <span class="text-danger" *ngIf="nameError.length> 0">{{nameError}}</span>
            </div>
        </form>
    </div>
</div>

<div class="mat-dialog-actions" align="end">
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button"
        (click)="Cancel()">
        <i class="flaticon2-cross"></i>
        Cancelar
    </button>
    <button [disabled]="relativesForm.invalid" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button"
        (click)="Accept()">
        <i class="flaticon-disco-flexible"></i>
        <span>Aceptar</span>
    </button>
</div>