import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { Subscription } from 'rxjs';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { docService } from 'src/app/services/doc.service';
import { InicidentsService } from 'src/app/services/inicidents.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { environment } from 'src/environments/environment';
import { Incidents } from '../models/Incidents';
import { IncidentsModalSearchComponent } from './incidentsModal/incidents-modal-search/incidents-modal-search.component';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./critical-incident.component.css']
})
export class IncidentsComponent implements OnInit {
  incidentDTO = new Incidents()
  incidents: [] = []
  SearchFilters = { TypeSearch: null, Filter: "" }
  FilterOptions: any[] = [];
  personSelected: any;
  personIsSelected: boolean = false;
  subscriptions: Subscription = new Subscription();
  employees: [] = []
  sweetAlrt = new SweetAlert
  incidentTypes: [] = []
  incidentGrades: [] = []
  datePipe = new DatePipe('en-Us')
  isView: boolean = false

  readonly urlDoc = environment.api_doc + '/Document';
  guiID = '';
  personalFile = personalFile;
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";
  file = { document: '' }
  config = {
    displayKey: 'stringData',
    placeholder: 'Seleccione una opción',
    search: true,
    searchPlaceholder: 'Buscar'
  };

  incidentsForm = new FormGroup({
    employeeCode: new FormControl(this.incidentDTO.employeeId),
    incidentDate: new FormControl(this.incidentDTO.date, Validators.required),
    inicidentType: new FormControl(this.incidentDTO.typeIncidentId, Validators.required),
    inicidentDegree: new FormControl(this.incidentDTO.degreeIncidentId, Validators.required),
    inicidentDescription: new FormControl(this.incidentDTO.description, Validators.required),
    employeeConduct: new FormControl(this.incidentDTO.employeeConduct, Validators.required),
    anotherEmployee: new FormControl(this.incidentDTO.AnotherEmployee, Validators.required),
    recommendedAction: new FormControl(this.incidentDTO.recommendedAction, Validators.required),
    evidence: new FormControl(this.incidentDTO.evidence, Validators.required),
    employeeName: new FormControl('', Validators.required),
    employeeType: new FormControl('', Validators.required),
    admissionDate: new FormControl('', Validators.required),
    department: new FormControl('', Validators.required),
    position: new FormControl('', Validators.required),
    typeSearch: new FormControl(this.SearchFilters.TypeSearch, Validators.required),
    filter: new FormControl(this.SearchFilters.Filter, Validators.required),

  })


  evidenceUppy = new Uppy({
    restrictions: {
      maxFileSize: 1000000,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['image/*', 'video/*']
    },
    autoProceed: true,
    locale: Mexico
  });


  constructor(private incidentService: InicidentsService, private srvParam: ParameterControlService,
    private _toastService: ToastService, private dialog: MatDialog, private _route: ActivatedRoute, private srvDoc: docService,
    private sanitizer: DomSanitizer, private authInfo: AuthInfoService) {
    this.incidentDTO.criticalIncidentId = _route.snapshot.params.id
  }

  ngOnInit(): void {
    if (this.incidentDTO.criticalIncidentId) {
      this.getData()
      this.getIncidentById(this.incidentDTO.criticalIncidentId)
      this.incidentsForm.disable()
      return
    }
    this.getData()

  }
  getData() {
    this.GetFilterOptions();

    this.incidentService.getEventType().subscribe((res: any) => {
      if (res.succeded) {
        this.incidentTypes = res.dataList
      } else {
        this._toastService.error(res.errors[0]);
      }
    })

    this.incidentService.getEventGrade().subscribe((res: any) => {
      if (res.succeded) {
        this.incidentGrades = res.dataList
      } else {
        this._toastService.error(res.errors[0]);
      }
    })

    this.getParameterControl()
  }

  GetFilterOptions() {
    let candidateFilterSub = this.srvParam.getParameters(keyWord.CandidateFilter).subscribe(data => {
      if (data.succeded) {
        this.FilterOptions = data.dataList;
      } else {
        this._toastService.error(data.errors[0]);
      }
    }, error => {
      this._toastService.error(
        'Ha ocurrido un error buscando los filtros de candidatos. Consulte con el administrador del sistema'
      );
    });
    this.subscriptions.add(candidateFilterSub)
  }

  ApplySearch() {
    if (this.incidentsForm.value.typeSearch == '') {
      this._toastService.warning('Debe indicar el tipo de búsqueda', 'Advertencia');
      return;
    }

    if (this.incidentsForm.value.filter.replace(' ', '') == '') {
      this._toastService.warning('Debe indicar el criterio de búsqueda', 'Advertencia');
      return;
    }

    let getEmployee = this.incidentService.getEmployeeByParemeter(this.incidentsForm.value.typeSearch, this.incidentsForm.value.filter).subscribe((res: any) => {
      if (res.succeded) {
        if (res.dataList.length > 0) {
          // // Open the candidate search result. It let to the user choose a record from the list
          this.dialog.open(IncidentsModalSearchComponent, {
            data: {
              items: res.dataList
            }
          })
            .afterClosed()
            .subscribe((result) => {
              this.personSelected = result.item;
              this.incidentsForm.get('employeeName').setValue(`${this.personSelected.name}`);
              this.incidentsForm.get('employeeType').setValue(this.personSelected.employeeType);
              this.incidentsForm.get('admissionDate').setValue(this.personSelected.admissionDate);
              this.incidentsForm.get('department').setValue(this.personSelected.departmentName);
              this.incidentsForm.get('position').setValue(this.personSelected.positionName);
              this.incidentDTO.employeeId = this.personSelected.employeeId
              this.SearchFilters.Filter = this.personSelected.personName;
            });

        } else {
          this._toastService.error("No se han encontrado postulantes con estos parámetros.")
        }


      }
    })
    this.subscriptions.add(getEmployee)
  }


  getIncidentById(id: number) {
    this.incidentService.getIncidentsById(id).subscribe((res: any) => {
      if (res.succeded == false) {
        this._toastService.error(res.errors[0]);
        return
      }

      this.incidentsForm.get('employeeCode').setValue(res.dataList[0].idEmployee)
      this.incidentsForm.get('incidentDate').setValue(this.datePipe.transform(res.dataList[0].date, 'yyyy-MM-dd'))
      this.incidentsForm.get('inicidentType').setValue(this.incidentTypes.find((et: any) => et.ocode == res.dataList[0].typeIncidentId))
      this.incidentsForm.get('inicidentDegree').setValue(this.incidentGrades.find((eg: any) => eg.ocode == res.dataList[0].degreeIncidentId))
      this.incidentsForm.get('inicidentDescription').setValue(res.dataList[0].description)
      this.incidentsForm.get('employeeConduct').setValue(res.dataList[0].employeeConduct)
      this.incidentsForm.get('anotherEmployee').setValue(res.dataList[0].anotherEmployee)
      this.incidentsForm.get('recommendedAction').setValue(res.dataList[0].recommendedAction)
      this.incidentsForm.get('evidence').setValue(res.dataList[0].evidence)
      this.getGuID(res.dataList[0].evidence)
      this.isView = true
    },
      err => this._toastService.error(`Ocurrio un error cargando este registro: ${err}`))
  }

  getParameterControl() {
    this.srvParam.getParameters(parameterDoc.PathTest).subscribe(e => {
      if (e.dataList.length > 0) {
        this.routeFile = e.dataList[0].stringData.toString();
      }
      this.loadUppy()
    })
  }

  loadUppy() {
    this.evidenceUppy.use(Dashboard, {
      trigger: '.UppyModalOpenerBtn',
      inline: true,
      target: '.evidence',
      showProgressDetails: true,
      note: 'Solo imagenes, 1 archivo, con un maximo de 1 MB',
      width: 320,
      height: 295,
      locale: {
        strings: {
          dropPasteFiles: 'Arrastra archivos aquí o %{browse}'
        }
      },
      thumbnailWidth: 320,
      metaFields: [
        { id: 'name', name: 'Nombre', placeholder: 'Nombre del Archivo' },
        { id: 'caption', name: 'Caption', placeholder: 'Descripción de la imagen' }
      ],
      browserBackButtonClose: false,
      showLinkToFileUploadResult: true,
    },
    )
      .use(XHRUpload, { endpoint: this.urlDoc + `?SystemLoad=${this.SystemLoad}&ModuleLoad=${this.ModuleLoad}&personalFile=${this.personalFile.photo2x2}&parameterDoc=${this.routeFile}` })
      .on('complete', (result) => {
        if (result.successful) {
          let resData = result.successful[0].response.body.data as FileResponse
          this.guiID = this.getGuid(resData.fileUnit.guidname)
          this.file.document = resData.path;
          this.incidentsForm.get('evidence').setValue(this.guiID ? this.guiID : null)
        }
      });
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  getGuID(guid) {
    try {
      this.srvDoc.getDocument(guid).subscribe(e => {
        this.file.document = e.data
        this.guiID = guid;
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  deleteGuid() {
    try {
      if (this.guiID) {
        this.srvDoc.deleteFile(this.guiID).subscribe(e => {
          this.evidenceUppy.cancelAll()
          this.file.document = '';
          this.guiID = null
        }, error => {
          this._toastService.error(error)
        })
      }
    } catch (error) {
      this._toastService.error(error)
    }
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  create() {
    this.incidentDTO = {
      ...this.incidentDTO,
      date: this.incidentsForm.value.incidentDate,
      typeIncidentId: this.incidentsForm.value.inicidentType?.ocode,
      degreeIncidentId: this.incidentsForm.value.inicidentDegree?.ocode,
      description: this.incidentsForm.value.inicidentDescription,
      evidence: this.incidentsForm.value.evidence,
      employeeConduct: this.incidentsForm.value.employeeConduct,
      AnotherEmployee: this.incidentsForm.value.anotherEmployee,
      recommendedAction: this.incidentsForm.value.recommendedAction,
      companyId: this.authInfo.getCompanyId(),
      status: true

    }
    this.cleanForm()
    this.incidentService.createIncident(this.incidentDTO).subscribe((res: any) => {
      if (res.succeded) {
        this._toastService.success('Incidente reportado')
        this.cleanForm()
        this.incidents = []
      }
    },
      err => this._toastService.error(err))
  }

  cleanForm() {
    this.incidentsForm.reset()
    this.incidents = []
    this.evidenceUppy.cancelAll()
    this.isView = false
  }
}
