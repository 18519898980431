import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { FilterEvent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { SearchBarComponent } from 'src/app/shared/element-ui/search-bar/search-bar.component';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { PaginationEvent } from 'src/app/shared/element-ui/table/paginator/paginator.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { CandidateRegistrationDashboardComponent } from '../candidate-registration-dashboard/candidate-registration-dashboard.component';
import { DatePipe } from '@angular/common';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';
import { ReportCode } from 'src/app/shared/utils/report-code.model';
declare function applySort(): any;

declare const $: any;
@Component({
  selector: 'app-candidate-registration-list',
  templateUrl: './candidate-registration-list.component.html',
  styleUrls: ['./candidate-registration-list.component.css'],
})
export class CandidateRegistrationListComponent implements OnInit, AfterViewChecked {

  employeesPagedResult = new PagedResult<any>();
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'REGPER',
    addAction: { key: 'ADD', value: false },
    editAction: { key: 'EDIT', value: false },
    approveAction: { key: 'APPROVAL', value: false },
    viewAction: { key: 'VIEW', value: false },
    exportAction: { key: 'EXPORT', value: false },
    deleteAction: { key: 'DELETE', value: false },
    dashboardAction: { key: 'DASH', value: false },
    activeAction: { key: 'ACTPER', value: false }
  }
  canUseOption = false
  searchParameter: FilterEvent
  public personList = [];
  public personListRecords = [];
  localStorageSearch = 'candidate-list_filter_search'
  @ViewChild('paginator') paginator: CaptionComponent;
  public personListOriginal = [];
  dataList: any[] = [];
  selectedItems: any;
  filterText: string = '';
  statusOptions = [
    { item_id: 1, item_text: 'Activos' },
    { item_id: 2, item_text: 'Inactivos' },
  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };
  companies: [] = [];
  public searchTextFilter;
  reportUrl = environment.reportUrl
  public sweetAlert = new SweetAlert()

  datePipe = new DatePipe('en-Us');

  constructor(
    private toast: ToastService,
    private srvPerson: PersonService,
    private srvExportToExcel: ExportToExcelService,
    private router: Router,
    private _cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    public authInfo: AuthInfoService) {

  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }
  searchBarDisplayNames: SearchBarPaginationParameter[] =
    [
      { propkeyId: 1, displayName: "Número de Secuencia" },
      { propkeyId: 2, displayName: "Nombre" },
      { propkeyId: 5, displayName: "Tipo de Documento" },
      { propkeyId: 3, displayName: "Documento de Identidad", default: true },
      { propkeyId: 4, displayName: "Teléfono Primario" },
    ];


  ngOnInit(): void {
    if (localStorage.getItem("person-list_filter")) {
      this.selectedItems = JSON.parse(localStorage.getItem("person-list_filter"));
    } else {
      this.selectedItems = [{ item_id: 1, item_text: 'Activos' }];
    }
    this.setFilterStorage()
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result) {
        this.privilege = this.authInfo.setPrivileges(this.privilege)
        this.canUseOption = true
        this.loadData();
      }
    })
    applySort();
  }
  setFilterStorage() {
    const localStorageSearch: FilterEvent = JSON.parse(localStorage.getItem(this.localStorageSearch));
    this.searchParameter = localStorageSearch;
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.setFilterToLocalStorage();
    this.loadData();
  }
  onItemDeSelect(item: any) {
    this.setFilterToLocalStorage();
    this.loadData();
  }
  onItemSelect(item: any) {
    this.setFilterToLocalStorage();
    this.loadData();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.setFilterToLocalStorage();
    this.loadData()
  }
  ngOnDestroy(): void {
    $('.tooltip.show').removeClass("show");
  }
  changeStatus() {
    this.loadData()
    this.setFilterToLocalStorage();
  }
  setFilterToLocalStorage() {
    localStorage.setItem('person-list_filter', JSON.stringify(this.selectedItems));
  }

  loadData() {
    if (!this.canUseOption) {
      return
    }
    const page = this.employeesPagedResult.page
    const pageSize = this.employeesPagedResult.pageSize
    const text = this.searchParameter?.value
    const propKeyId = !this.searchParameter?.value ? 0 : this.searchParameter?.propkeyId
    const status: number = this.selectedItems?.length === 2 || this.selectedItems?.length == 0 ? -1 : (this.selectedItems[0]?.item_id === 1 ? 1 : 0);


    this.srvPerson.getPersonAllPaginated(page, pageSize, propKeyId, text, status).subscribe(res => {

      if (res.errors.length > 0) {
        this.toast.error(res.errors[0])
        return;
      }
      if (res.succeded == true) {
        this.personList = res.singleResultData.items;
        this.personListOriginal = res.singleResultData.items
        this.employeesPagedResult = res.singleResultData
      }
    }, error => {
      this.toast.error(error)
    })
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(CandidateRegistrationDashboardComponent, {
      width: '100%',

      data: { persons: this.personListOriginal },
    });

  }



  filterSearch(event: FilterEvent) {
    this.employeesPagedResult.page = 1
    this.searchParameter = event
    this.loadData()
  }

  getPaginatedRecords(event: PaginationEvent) {
    if (this.employeesPagedResult.page == event.selectedPage && this.employeesPagedResult.pageSize == event.registersPerPage) {
      return;
    }
    this.employeesPagedResult.page = event.selectedPage;
    this.employeesPagedResult.pageSize = event.registersPerPage;
    this.loadData()
  }

  exportToExcell() {
    let data = this.personListOriginal;
    data?.forEach(element => {
      element.status = element.status ? 'Activa' : 'Inactiva';
      element.birthday = this.datePipe.transform(element.birthday, 'dd/MM/yyyy');
      element.createDate = this.datePipe.transform(element.createDate, 'dd/MM/yyyy');
    });
    let excelHeaders: string[][] = [[
      "Secuencia",
      "Nombre",
      "Documento de Identidad",
      "Teléfono Primario",
      "Fecha de Nacimiento",
      "Fecha de Creación",
      "Estatus",
    ], [
      "personId",
      "fullName",
      "personalIdentification",
      "phoneNumber",
      "birthday",
      "createDate",
      "status",
    ]]
    this.srvExportToExcel.exportToExcelSpecificColumns(data, excelHeaders, 'Lista de Personas', true);
  }


  exportToPlantilla() {
    this.router.navigate(['pdf-preview'])
  }

  async deletePerson(id) {
    const success = await this.sweetAlert.AlertConfirm(
      'Inactivar',
      `¿Desea inactivar esta persona?`,
      'error'
    );

    let data = {
      personId: id,
      userId: this.authInfo.getUserId(),
      operation: 1
    }

    if (success) {
      this.srvPerson.deletePerson(data).subscribe(e => {
        if (e.succeded == true) {
          this.loadData();
          this.toast.success("Registro Inactivo")
        }
      }, error => {
        this.toast.error(error)
      })
    }
  }

  async activatePerson(id: number) {
    const success = await this.sweetAlert.AlertConfirm(
      'Activar',
      `¿Desea activar esta persona?`,
      'success'
    );

    if (success) {
      this.srvPerson.activatePerson(id).subscribe(e => {
        if (e.succeded == true) {
          this.loadData();
          this.toast.success("Registro Activo")
        }
      }, error => {
        this.toast.error(error)
      })
    }
  }

  openReport() {

    let url = ''
    const propKeyId = this.searchParameter?.propkeyId ?? 0
    const searchParameter = this.searchParameter?.value
    const actives = this.selectedItems.find((x) => x.item_id === 1);
    const inactives = this.selectedItems.find((x) => x.item_id === 2);
    let status = 0;
    if ((!actives && !inactives) || (actives && inactives)) {
      status = -1;
    } else if (actives && !inactives) {
      status = 1;
    }
    const ableConditions = this.selectedItems
      ?.filter((x) => x.item_id !== 1 && x.item_id !== 2)
      ?.map((e) => e.item_id)
      ?.join("-");
    const { page, pageSize } = this.employeesPagedResult

    url = `${this.reportUrl}/?ReportCode=Exp_PersonVacanciesEnable&CompanyId=${this.authInfo.getCompanyId()}&PropKeyId=${propKeyId}&SearchParameter=${searchParameter}&status=${status}&conditions=${ableConditions}&page=${page}&pageSize=${pageSize}`

    let parameters = {
      url: url,
      title: 'Requisición / Habilitar Vacantes',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }


  exportToPdf(person) {
    const { companyId, personId, status } = person
    const reportCode: ReportCode = ReportCode.JobReport;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&PersonId=${personId}&Person=&Status=${status ? 1 : 2}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Unidades Organizativas',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }
}

