import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { PayrollFlyerService } from 'src/app/services/payroll-flyer.service';
import { RequestMetaData, ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';
import { PayrollFlyerGetTypes } from '../models/payroll-flyer-get-type.enum';
import { PayrollFlyer } from '../models/payroll-flyer.model';
import { PaymentFlyerComponent } from '../payment-flyer.component';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import { environment } from 'src/environments/environment';
import Dashboard from '@uppy/dashboard';
import XHRUpload from '@uppy/xhr-upload';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import { docService } from 'src/app/services/doc.service';
import { EmployeeDebitCredit, NewsApplied, PayrollVoucher } from '../models/payroll-voucher.model';
import { NewsAppliedType } from '../models/news-applied-type.enum';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
type GUID = string & { isGuid: true };


@Component({
  selector: 'app-pdf-payment-voucher',
  templateUrl: './pdf-payment-voucher.component.html',
  styleUrls: ['./pdf-payment-voucher.component.css']
})
export class PdfPaymentVoucherComponent implements OnInit {

  //Other debits //Other credits
  //debitCreditId = 4 debitCreditId = 5
  selectedFlyer: any;

  payrollVoucherData: PayrollVoucher;
  isTableLoading: boolean;
  subscriptions: Subscription = new Subscription();
  bugetArea: string = "";
  contractType: string = "";
  selectedPeriod: string = "";
  //#region Variables related to load picture
  profilePhoto = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['image/*']
    },
    locale:Mexico
  });
  profilePhotoUrl: string = "";
  
  employeeDebitCredit: any[] = [];
  newsApplied: any[]= [];

  public loadingPic = true;
  //#endregion

  public company: any;
  file = { logo: '', image1: '', image2:'' }

  periodId: number;
  employeeId: number;
  headerId: number;

  constructor(
    private _payrollFlyerService: PayrollFlyerService,
    private srvDoc: docService,
    private _companiesService: CompaniesService,
    private authInfo: AuthInfoService,
    private _route: ActivatedRoute,
    private _toastService: ToastrService,)
    {
      
    }

  ngOnInit(): void {
    this.headerId = this._route.snapshot.params["headerId"];
    this.employeeId = this._route.snapshot.params["employeeId"];
    this.periodId = this._route.snapshot.params["periodId"];
    
    if(this.headerId && this.employeeId && this.periodId){
      this.loadData()
    }

    
  }
  
  getVoucherData(): Observable<ResponseModel<PayrollVoucher>> {
    return this._payrollFlyerService.getEmployeePayrollVoucher({
      headerId: this.headerId,
      value: this.employeeId,
      type: PayrollFlyerGetTypes.ByHeaderAndEmployeeId
    });
  }

  loadData(): void {
    

    let sub = this.getVoucherData().subscribe((response: ResponseModel<PayrollVoucher>) => {
      if (response.succeded) {
        if (response.dataList.length > 0) {
          this.payrollVoucherData = response.dataList[0];

          let paymentFlyerImpresionMetadata = {
            paymentFlyerPrintId: 0,
            employeeId: response.dataList[0].employee.employeeId,
            headerId: this.headerId,
            createDate: new Date(),
            createUser: this.authInfo.getUserId()
          }
          this._payrollFlyerService.registeringPaymentFlyerPrinted(paymentFlyerImpresionMetadata)
            .subscribe((response: RequestMetaData) => {
              if (response.succeded) {
                // this._toastr.success(
                //   `Última impresión: ${formatDate(new Date(paymentFlyerImpresionMetadata.createDate), 'dd/MM/yyyy HH:mm:ss', 'en-US')}`)

              }
            });
        
            this.employeeDebitCredit = this.payrollVoucherData.employeeDebitCredit.
            filter((e: EmployeeDebitCredit) => e.debitCreditId !== 4 && e.debitCreditId !== 5);
        
            this.newsApplied =  this.payrollVoucherData.newsApplied;
         
            this.contractType = (this.payrollVoucherData.employee.contractType || '');
           
        
            
            this.getProfilePhoto();
            this.companyById(this.authInfo.getCompanyId())

          return response.dataList[0];


        } else {
          this._toastService.error("No se encontraron datos para imprimir el volante", "Error inesperado");
        }
      }
    });

    this.subscriptions.add(sub);
  }

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      this.company = data.dataList[0]
      this.setGuids(this.company)
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }

  getProfilePhoto(){
    this.srvDoc.getDocument(this.payrollVoucherData.employee.profileImage).subscribe(e => {
      this.profilePhotoUrl = e.data;
      this.loadingPic = false
    }, error => {
      this.loadingPic = false
    })
  }

  returnRelatedAmount(newApplied:NewsApplied): number{
    switch(newApplied.newsTypeId){
      case NewsAppliedType.Extern:
        return newApplied.amount;
      case NewsAppliedType.Additional:
        return (newApplied.defaultValue > 0) ? newApplied.defaultValue : newApplied.manualValue;
      case NewsAppliedType.Litis:
        return newApplied.amount;
    }
  }
  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }



}
