import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Period } from '@payroll/schedule/models/period.model';
import { PayrollService } from 'src/app/services/payroll.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ParameterControl } from 'src/app/shared/models/parameter-control.model';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PayrollReportService } from 'src/app/services/payroll-report.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { keyWord } from 'src/app/shared/utils/parameterControl';
import { environment } from 'src/environments/environment';
import { openReport } from 'src/app/shared/utils/utility';
import { PayrollConceptService } from 'src/app/services/payroll-concept.service';
import { ResponseModel } from 'src/app/shared/models/strongly-typed-response.model';

@Component({
  selector: 'app-employee-concepts',
  templateUrl: './employee-concepts.component.html',
  styleUrls: ['./employee-concepts.component.css']
})
export class EmployeeConceptsComponent implements OnInit {

  reportForm: FormGroup;

  yearsList: any[] = []
  periods: Period[] = [];
  filteredPeriods: Period[] = [];
  periodSelected: Period;
  payrollVersions: any[] = [];
  budgetAreaList: any[] = [];
  relatedPeriods: any[] = [];
  payrollTypes: any[] = [];
  payrollTypesSelected: any;

  areaSelected: any;
  versionSelected: any;
  periodRelatedSelected: any;
  conceptSelected: any;
  reportUrl = environment.reportUrl

  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'PAYROLL-REPORT',
    exportAction: 'EXPGEN',
    searchAction: 'SEARGEN'
  }

  payrollConceptsConfig = {
    displayKey: 'description', //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto',
    placeholder: 'Seleccionar', // text to be displayed when no item is selected defaults to Select,
    limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Buscar', // label thats displayed in search input,
    searchOnKey: 'description' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }

  payrollConcepts = [];

  coletilla: any = '';
  isThereColetilla: any = false;

  constructor(
    private location: Location,
    private payrollService: PayrollService,
    private _toastService: ToastService,
    public authInfo: AuthInfoService,
    private fb: FormBuilder,
    private payrollReportService: PayrollReportService,
    private srvConcept: PayrollConceptService,
    private parameterServices: ParameterControlService,
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.getPayrollTypes();
        this.getPeriods();
      }
    });
  }

  goBack() {
    this.location.back();
  }

  buildForm() {
    this.reportForm = this.fb.group({
      payrollType: [null, [Validators.required]],
      period: [null, [Validators.required]],
      relatedPeriod: [null],
      payrollConcept: [null],
      area: [null, [Validators.required]],
      version: [null, [Validators.required]]
    })
  }

  dropdownConfig(displayKey) {
    return {
      displayKey: displayKey,
      search: true,
      height: 'auto',
      placeholder: 'Seleccione una opción',
      moreText: '...',
      noResultsFound: 'No se han encontrado registros',
      searchPlaceholder: 'Buscar',
      searchOnKey: displayKey
    }
  }

  dropdownSettings = {
    singleSelection: false,
    idField: 'employeeTypeId',
    textField: 'description',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  getPeriods() {
    this.payrollService.getBudgetAreas().subscribe((res: any) => {
      this.budgetAreaList = res.dataList.filter(ba => ba.status == true)

    })

    this.payrollReportService.getPeriods().subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error("Ha ocurrido un error al obtener los períodos");
        return;
      }
      this.periods = res.dataList.filter(e => e.status);
      this.filteredPeriods = this.periods
      let periodYears: any[] = []
      let years = this.periods.map((period: any) => {
        let periodYear = new Date(`${period.startDate}`).getFullYear()
        if (!periodYears.includes(periodYear)) {
          periodYears.push(periodYear)
        }
        return (periodYears)
      })

      this.yearsList = years[0].reverse()
    }, err => this._toastService.error(err))
  }

  getConcepts() {
    const { institution, area, payrollType, period, version, employeeType, concept, relatedPeriod } = this.reportForm.value
    this.reportForm.get('payrollConcept').setValue(null);
    this.payrollConcepts = []
    this.isThereColetilla = false
    if (this.reportForm.invalid) {
      return
    }
    let opt = {
      PayrollTypeId: payrollType.ocode,
      Version: version.versionId,
      PayrollPeriodId: period.payrollPeriodId,
      budgetAreaId: area.budgetAreaId,
      CompanyId: this.authInfo.getCompanyId(),
      PayrollPeriodIdRelated: relatedPeriod?.payrollPeriodId ? relatedPeriod.payrollPeriodId : '0',
      NewsConceptId: '0',
      Type: '0',
      status: 'true'
    }
    this.srvConcept.getNewsConceptByHeader(opt).subscribe({
      next: (res: any) => {
        if (res?.errors?.length > 0) { this._toastService.error(res.errors[0]) }
        if (res?.warnings?.length > 0) { this._toastService.error(res.errors[0]) }
        this.payrollConcepts = res.dataList
        this.reportForm.get('payrollConcept').enable()
      }, error: (error: any) => {
        this._toastService.error('Ha ocurrido un error inesperado')
      }
    })
  }

  changeRelatedPeriod() {
    this.reportForm.get('version').patchValue(null)
    this.reportForm.get('version').enable()
    this.reportForm.get('payrollConcept').patchValue(null)
    this.reportForm.get('payrollConcept').disable()
    if (!this.reportForm.get('relatedPeriod').value?.payrollPeriodId) {
      this.reportForm.get('version').disable()
      this.payrollVersions = []
    } else {
      this.getPayrollVersions()
    }
  }



  changeYear(event) {
    this.filteredPeriods = this.periods.filter((period: any) => period.description.includes(`${event.value}`))
  }


  getPayrollTypes() {
    this.parameterServices.getParameters(keyWord.PayrollType).subscribe((res: any) => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.payrollTypes = res.dataList;
      this.reportForm.get('payrollType').setValue(this.payrollTypes.find((payrollType: any) => payrollType.ocode === 4))
      this.changePayrollType()
    }, error => {
      this._toastService.error('Ocurrió un error inesperado', '');
    });
  }

  changeDropdown() {
    const isAdditional = this.reportForm.value.payrollType?.ocode === 4
    const payrollType = { ... this.reportForm.get('payrollType')?.value };
    const periodRelatedSelected = { ...this.reportForm.get('relatedPeriod')?.value };
    if (payrollType?.ocode == 4 && periodRelatedSelected?.payrollPeriodId > 0) {
    } else {
      this.getPayrollVersions();
    }
  }

  changeVersion() {
    let version = this.reportForm.get('version').value?.headerId
    if (!version) {
      this.reportForm.get('payrollConcept').disable()
      this.reportForm.get('payrollConcept').patchValue(null)
      this.payrollConcepts = []
    } else {
      this.getConcepts()
      this.reportForm.get('payrollConcept').setValidators([Validators.required])
    }
  }


  getVersions(additional, payrollPeriodId, payrollTypeId, budgetAreaId, companyId, relatedPeriod) {
    if (additional) {
      this.payrollService.getPayrollVersionsAdditional(payrollPeriodId, payrollTypeId, budgetAreaId, relatedPeriod, 3, companyId).subscribe((res: any) => {
        if (res?.errors?.length > 0) {
          this._toastService.error(res.errors[0]);
          return;
        }
        this.payrollVersions = res?.dataList?.map(v => {
          return {
            ...v,
            versionDescription: `${v?.headerId} - ${v?.versionId} - ${v?.headerCondition}`
          }
        });
        this.reportForm.get('version').enable()
      }, error => {

      })
    } else {
      this.payrollService.getPayrollVersions(payrollPeriodId, payrollTypeId, budgetAreaId, companyId).subscribe((res: any) => {
        if (res?.errors?.length > 0) {
          this._toastService.error(res.errors[0]);
          return;
        }
        this.payrollVersions = res?.dataList?.map(v => {
          return {
            ...v,
            versionDescription: `${v?.headerId} - ${v?.versionId} - ${v?.headerCondition}`
          }
        });
        this.reportForm.get('version').enable()
      }, error => {

      })
    }
  }

  changePayrollType() {
    const payrollType = this.reportForm.get('payrollType')?.value
    this.reportForm.get('version').enable()

    if (payrollType?.ocode === 4) {
      this.reportForm.get('version').disable()
      this.reportForm.get('payrollConcept').disable();
      this.reportForm.get('relatedPeriod').setValue(null);
      this.reportForm.get('payrollConcept').setValue(null);
      this.addValidation('relatedPeriod', [Validators.required]);
      this.getBeforePeriod();
      return;
    }
    this.relatedPeriods = [];
    this.payrollConcepts = [];
    this.addValidation('relatedPeriod', null);
    this.getPayrollVersions();
  }
  getPayrollVersions() {
    const form = this.reportForm
    form.get('version').patchValue(null)
    form.get('payrollConcept').patchValue(null)
    form.get('payrollConcept').disable()
    this.reportForm.get('payrollConcept').setValue(null);
    this.payrollConcepts = []
    this.isThereColetilla = false
    const payrollPeriodId = form.value.period?.payrollPeriodId;
    const payrollTypeId = form.value.payrollType?.ocode;
    const budgetAreaId = form.value.area?.budgetAreaId;
    const companyId = this.authInfo.getCompanyId()/* form.value.institution?.companyId; */
    this.payrollVersions = [];
    if (payrollPeriodId === undefined || budgetAreaId === undefined || payrollTypeId === undefined || companyId === undefined) {
      return;
    }
    const isAdditional = payrollTypeId === 4

    let relatedPeriod = '0'
    if (isAdditional) {
      if (form.value.relatedPeriod?.payrollPeriodId) {
        relatedPeriod = form.value.relatedPeriod?.payrollPeriodId
      } else {
        return
      }
    }
    this.getVersions(isAdditional, payrollPeriodId, payrollTypeId, budgetAreaId, companyId, relatedPeriod)
  }
  private addValidation(controlName: string, validators) {
    this.reportForm.get(controlName).setValidators(validators);
    this.reportForm.get(controlName).updateValueAndValidity();
  }

  getBeforePeriod() {
    this.payrollService.getBeforePeriod().subscribe(res => {
      if (res.errors.length > 0) {
        this._toastService.error(res.errors[0]);
        return;
      }
      this.relatedPeriods = res.dataList;
      this.getPayrollVersions();
    });
  }

  get getPayrollTypeSeleted() {
    return this.reportForm.get('payrollType')?.value;
  }

  getColetilla() {
    const formValue = this.reportForm?.value;
    const { version, payrollConcept, payrollType } = formValue

    if (!version?.headerId || !payrollConcept?.conceptTypeId) {
      this.isThereColetilla = false
      this.coletilla = ''
      return
    }
    this.isThereColetilla = true
    this.payrollService.getPayrollColetilla(version?.headerId, payrollConcept?.newsConceptId, payrollType?.ocode === 4 ? 4 : null).subscribe(
      {
        next: (resp) => {
          if (resp.dataList.length == 0) {
            this.coletilla = ''
            return
          }
          if (resp.dataList[0].coletilla == null) {
            this.coletilla = ''
            return
          }
          this.coletilla = resp.dataList[0].coletilla

        }
      }
    )
  }

  openReport() {

    if (this.reportForm.invalid) {
      this._toastService.warning('Por favor completar todos los campos requeridos', 'Campos incompletos')
      return
    }

    this.payrollTypesSelected = { ... this.reportForm.get('payrollType')?.value };
    this.periodSelected = { ...this.reportForm.get('period')?.value };
    this.areaSelected = { ...this.reportForm.get('area')?.value };
    this.versionSelected = { ...this.reportForm.get('version')?.value };
    this.periodRelatedSelected = { ...this.reportForm.get('relatedPeriod')?.value };
    this.conceptSelected = { ...this.reportForm.get('payrollConcept')?.value };

    let payrollPeriodId: number = this.periodSelected.payrollPeriodId;
    let payrollTypeId: number = this.payrollTypesSelected?.ocode;
    let areaId: number = this.areaSelected?.budgetAreaId;
    let versionId: number = this.versionSelected?.versionId
    let periodRelated: number = this.periodRelatedSelected?.payrollPeriodId || 0;
    let newsConceptId: number = this.conceptSelected?.newsConceptId || 0;

    const reportUrl = `${this.reportUrl}/?ReportCode=individual_concept_new&CompanyId=${this.authInfo.getCompanyId()}&PeriodId=${payrollPeriodId}&PayrollTypeId=${payrollTypeId}&BudgetAreaId=${areaId}&VersionId=${versionId}&PayrollPeriodIdRelated=${periodRelated}&NewConceptId=${newsConceptId}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte de Nomina de Empleado con Conceptos',
      width: 1024,
      height: 768
    }
    openReport(parameters)
  }


}
