import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { environment } from 'src/environments/environment';

declare const $: any;
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  enviroment = environment;
  account = this.accountService.accountValue;
  lobbyUrl = environment.LobbyUrl;

  appVersion: string = environment.application_version;

  constructor(private accountService: AccountService,
    private authIfo: AuthInfoService) { }

  ngOnInit(): void {
    this.logoTooltip();
  }

 logoTooltip(){

  let logo = document.getElementById('logo')
  
  $(logo).ready(function(){
    $('[data-toggle="tooltip"]').click(function () {
       $('[data-toggle="tooltip"]').tooltip("hide");

      });
  });
}

  goConfig(){
    window.open(environment.configUrl + '?language=' + this.authIfo?.getToken());
  }

}

