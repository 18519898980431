import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PersonalRequsitionService } from 'src/app/services/personal-requsition.service';
import { IConcursaRequisitonView } from '../models/concursa-requisition';
import { IRequisitionView } from '../models/personal-requisitions';

@Component({
  selector: 'app-concursa-status-modal',
  templateUrl: './concursa-status-modal.component.html',
  styleUrls: ['./concursa-status-modal.component.css']
})
export class ConcursaStatusModalComponent implements OnInit {

  requisition: IConcursaRequisitonView = {
    id: 0,
    cargoId: '',
    cargo: '',
    grupoOcupacionalId: '',
    grupoOcupacional: '',
    numeroPlazas: 0,
    institucionId: 0,
    institucion: '',
    provinciaId: 0,
    provincia: '',
    municipioId: 0,
    municipio: '',
    unidadAdministrativa: '',
    salario: 0,
    fechaRequerida: undefined,
    mesesPrueba: 0,
    lugarFunciones: '',
    observaciones: '',
    estadoId: 0,
    estado: '',
    causaVacanteId: 0,
    requisitos: [],
    titulaciones: [],
    remuneraciones: [],
    experiencias: [],
    conocimientos: []
  };

  requiredDate: string = null;
  salary: string = null;

  constructor(public dialogRef: MatDialogRef<ConcursaStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IRequisitionView,
    public authInfo: AuthInfoService,
    private requiService: PersonalRequsitionService, 
    private toastService: ToastrService,
    private datePipe: DatePipe) { 

      if(this.data){
        this.loadRequisition();
      }
    }

  ngOnInit(): void {
  }

  private loadRequisition(){
    this.requiService.getRequisitionFromConcursa(this.data.personalRequisitionId,this.data.companyId).subscribe({
      next: (res)=>{
        if(!res.succeded){

          res.errors.forEach(err=>{
            this.toastService.error(err);
          })

          return;
        }

        this.requisition = res.dataList[0];
        this.requiredDate = this.datePipe.transform(res.dataList[0]?.fechaRequerida,'yyyy-MM-dd');
        this.salary = res.dataList[0]?.salario?.toString();

      },
      error: (err)=>{
        this.toastService.error("Ha ocurrido un error tratando de consultar el estatus de la requisición.")
      }
    })
  }

}
