import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthInfoService } from 'src/app/services/auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceCommissionService {

  private readonly core = environment.api_core_url
  private readonly hrm = environment.api_url

  constructor(
    private http: HttpClient,
    private authInfoServ: AuthInfoService,
  ) { }

  getInstitutions(){
    return this.http.get(`${this.core}/institution`)
  }
  
  postPermission(model){
    return this.http.post(`${this.hrm}/EmployeePermissions`, model)
  }

  updatePermission(model){
    return this.http.put(this.hrm + '/EmployeePermissions', model)
  }

  finalizePermission(idPermissions:number,){
    return this.http.delete(`${this.hrm}/EmployeePermissions/Finalize?IdPermissions=${idPermissions}&UserId=${this.authInfoServ.getUserId()}`)
  }
  
  getEmployeePermissionsByIdEmployee(id){
    return this.http.get(`${this.hrm}/EmployeePermissions?IdPermissions=${id}&Type=1&CompanyId=${this.authInfoServ.getCompanyId()}&Status=true`)
  }
}
