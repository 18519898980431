<form [formGroup]="form">
    <div class="row pt-5 gutter-b">
        <div class="col-sm-12 col-lg-3">
            <div class="form-group">
                <label>Código del Concepto<span class="text-danger">*</span></label>
                <input (input)="removeWhitespaces($event, form.get('code'))" formControlName="code" type="text" class="form-control form-control-solid form-control-lg">
                <span class="text-danger" *ngIf="codeError.length > 0">{{codeError}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group">
                <label>Descripción<span class="text-danger">*</span></label>
                <input (input)="removeWhitespaces($event, form.get('description'))" formControlName="description" type="text" class="form-control form-control-solid form-control-lg">
                <span class="text-danger" *ngIf="descriptionError.length > 0">{{descriptionError}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
          <div class="form-group">
              <label>Nombre Corto<span class="text-danger">*</span></label>
              <input (input)="removeWhitespaces($event, form.get('shortName'))" formControlName="shortName" type="text" class="form-control form-control-solid form-control-lg">
              <span class="text-danger" *ngIf="shortNameError.length > 0">{{shortNameError}}</span>
          </div>
      </div>
        <!-- <div class="col-sm-12 col-lg-3">
            <div class="form-group">
                <label>Descripción Abreviada</label>
                <input formControlName="descriptionAbbr" type="text" class="form-control form-control-solid form-control-lg">
            </div>
        </div> -->
        <div class="col-sm-12 col-lg-3">
            <div class="form-group">
                <label>Orden de Ejecución</label>
                <input formControlName="order" type="text" class="form-control form-control-solid form-control-lg">
                <span class="text-danger" *ngIf="orderError.length > 0">{{orderError}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <!-- <div class="form-group">
                <label>Moneda</label>
                <input type="text" class="form-control form-control-solid form-control-lg">
            </div> -->
            <div class="form-group">
                <label>Beneficiario</label>
                <ngx-select-dropdown formControlName="beneficiary" class=" flex-1 "
                    [config]="getDropdownConfig('description')"
                    [options]="beneficiaryList"></ngx-select-dropdown>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group">
                <label>Tipo de Concepto<span class="text-danger">*</span></label>
                <ngx-select-dropdown formControlName="conceptType" class=" flex-1 "
                    [config]="getDropdownConfig('stringData')"
                    [options]="conceptTypes"></ngx-select-dropdown>
                    <span class="text-danger" *ngIf="conceptTypeError.length > 0">{{conceptTypeError}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group">
                <label>Tipo de Novedad<span class="text-danger">*</span></label>
                <ngx-select-dropdown formControlName="newsType" class=" fslex-1 "
                (change)="changeNewsType()"
                    [config]="getDropdownConfig('description')"
                    [options]="newsTypes"></ngx-select-dropdown>
                    <span class="text-danger" *ngIf="newsTypeError.length > 0">{{newsTypeError}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group">
                <label>Código en el SIGEF</label> 
                <input (input)="removeWhitespaces($event, form.get('codeSigef'))" formControlName="codeSigef" type="text" class="form-control form-control-solid form-control-lg">
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
          <div class="form-group">
              <label>CCP</label>
              <input (input)="removeWhitespaces($event, form.get('ccp'))" formControlName="ccp" type="text" class="form-control form-control-solid form-control-lg">
              <span class="text-danger" *ngIf="ccpError.length > 0">{{ccpError}}</span>


          </div>
         </div>
        <div class="col-sm-12 col-lg-3">
          <div class="form-group " >
              <div>
                  <label for="" class="d-block">¿Funciona como salario? <span class="font-italic"></span><span
                          class="text-danger text-monospace legend">*</span></label>
              </div>
              <div class="d-flex gender flex-wrap">
                  <div class="form-check form-check-inline" >
                      <label class="form-check-label mt-2 check-dtl me-3">
                          <input value="true" formControlName="isSalary" type="radio" class="form-check-input" />
                          Sí
                      </label>

                      <label class="form-check-label mt-2 check-dtl">
                          <input value="false" formControlName="isSalary"  type="radio" class="form-check-input" />
                          No
                      </label>
                  </div>
              </div>
              <span class="text-danger" *ngIf="salaryError.length > 0">{{salaryError}}</span>
          </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group " >
                <div>
                    <label for="" class="d-block">¿Aplica para ISR? <span class="font-italic"></span><span
                            class="text-danger text-monospace legend">*</span></label>
                </div>
                <div class="d-flex gender flex-wrap">
                    <div class="form-check form-check-inline" >
                        <label class="form-check-label mt-2 check-dtl me-3">
                            <input value="true" formControlName="isIsr" type="radio" class="form-check-input" />
                            Sí
                        </label>

                        <label class="form-check-label mt-2 check-dtl">
                            <input value="false" formControlName="isIsr"  type="radio" class="form-check-input" />
                            No
                        </label>
                    </div>
                </div>
                <span class="text-danger" *ngIf="isrError.length > 0">{{isrError}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group " >
                <div>
                    <label for="" class="d-block">¿Aplica para TSS? <span class="font-italic"></span><span
                            class="text-danger text-monospace legend">*</span></label>
                </div>
                <div class="d-flex gender flex-wrap">
                    <div class="form-check form-check-inline" >
                        <label class="form-check-label mt-2 check-dtl me-3">
                            <input value="true" formControlName="isTss" type="radio" class="form-check-input" />
                            Sí
                        </label>

                        <label class="form-check-label mt-2 check-dtl">
                            <input value="false" formControlName="isTss"  type="radio" class="form-check-input" />
                            No
                        </label>
                    </div>
                </div>
                <span class="text-danger" *ngIf="tssError.length > 0">{{tssError}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group " >
                <div>
                    <label for="" class="d-block">¿Es un débito? <span class="font-italic"></span><span
                            class="text-danger text-monospace legend">*</span></label>
                </div>
                <div class="d-flex gender flex-wrap">
                    <div class="form-check form-check-inline" >
                        <label class="form-check-label mt-2 check-dtl me-3">
                            <input value="true" formControlName="isDebit" type="radio" class="form-check-input" />
                            Sí
                        </label>

                        <label class="form-check-label mt-2 check-dtl">
                            <input value="false" formControlName="isDebit"  type="radio" class="form-check-input" />
                            No
                        </label>
                    </div>
                </div>
                <span class="text-danger" *ngIf="debitError.length > 0">{{debitError}}</span>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
          <div class="form-group " >
              <div>
                  <label for="" class="d-block">¿Puede ser mayor al salario? <span class="font-italic"></span><span
                          class="text-danger text-monospace legend">*</span></label>
              </div>
              <div class="d-flex gender flex-wrap">
                  <div class="form-check form-check-inline" >
                      <label class="form-check-label mt-2 check-dtl me-3">
                          <input value="true" formControlName="higherThanSalary" type="radio" class="form-check-input" />
                          Sí
                      </label>

                      <label class="form-check-label mt-2 check-dtl">
                          <input value="false" formControlName="higherThanSalary"  type="radio" class="form-check-input" />
                          No
                      </label>
                  </div>
              </div>
              <span class="text-danger" *ngIf="higherThanSalaryError.length > 0">{{higherThanSalaryError}}</span>
          </div>
      </div>
      <div class="col-sm-12 col-lg-3">
        <div class="form-group " >
            <div>
                <label for="" class="d-block">¿Aplica para regalía? <span class="font-italic"></span><span
                        class="text-danger text-monospace legend">*</span></label>
            </div>
            <div class="d-flex gender flex-wrap">
                <div class="form-check form-check-inline" >
                    <label class="form-check-label mt-2 check-dtl me-3">
                        <input value="true" formControlName="toRoyalty" type="radio" class="form-check-input" />
                        Sí
                    </label>

                    <label class="form-check-label mt-2 check-dtl">
                        <input value="false" formControlName="toRoyalty"  type="radio" class="form-check-input" />
                        No
                    </label>
                </div>
            </div>
            <span class="text-danger" *ngIf="toRoyaltyError.length > 0">{{toRoyaltyError}}</span>
        </div>
    </div>
        <div *ngIf="!this.data.inDetails" class="col-sm-12 col-lg-3">
            <div [ngClass]="{'d-none': this.data.inDetails}" class="form-group">
                <label>Tipo de Nómina<span class="text-danger">*</span></label>
                <ngx-select-dropdown formControlName="payrollTypes" class=" fslex-1 " [config]="getDropdownConfig('stringData')"
                    [multiple]="true" [options]="payrollTypes"></ngx-select-dropdown>
                <span class="text-danger" *ngIf="payrollTypesError.length > 0">{{payrollTypesError}}</span>
            </div>
        </div>
        <div *ngIf="this.data.inDetails" class="col-md-3">
            <div class="card-body" style="padding: 0;">
                <div class="table-responsive">
                    <table class="table table-hover table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th>Tipos de Nómina</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of payrollTypesTable" data-row="0" class="datatable-row" style="left: 0px;">
                                <td>{{item.stringData}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="form-group " >
                <div>
                    <label for="" class="d-block">¿Aplica para descuento? <span class="font-italic"></span><span
                            class="text-danger text-monospace legend">*</span></label>
                </div>
                <div class="d-flex gender flex-wrap">
                    <div class="form-check form-check-inline" >
                        <label class="form-check-label mt-2 check-dtl me-3">
                            <input value="true" formControlName="hasDiscountConcepts" type="radio" class="form-check-input" (change)="changeHasDiscountConcepts($event.target.value)" />
                            Sí
                        </label>
    
                        <label class="form-check-label mt-2 check-dtl">
                            <input value="false" formControlName="hasDiscountConcepts"  type="radio" class="form-check-input" (change)="changeHasDiscountConcepts($event.target.value)" />
                            No
                        </label>
                    </div>
                </div>
                <span class="text-danger" *ngIf="hasDiscountConceptsError.length > 0">{{hasDiscountConceptsError}}</span>
            </div>
        </div>
        <div *ngIf="!this.data.inDetails && this.form?.get('hasDiscountConcepts')?.value == 'true' " class="col-sm-12 col-lg-3">
            <div [ngClass]="{'d-none': this.data.inDetails}" class="form-group">
                <label>Concepto <span class="text-danger">*</span></label>
                <ngx-select-dropdown formControlName="discountConceptsRelated" class=" fslex-1 " [config]="getDropdownConfig('description')"
                    [multiple]="true" [options]="discountConceptsList"></ngx-select-dropdown>
                    <span class="text-danger" *ngIf="hasDiscountConceptsListError.length > 0">{{hasDiscountConceptsListError}}</span>
            </div>
        </div>
        <div *ngIf="this.data.inDetails && this.form?.get('hasDiscountConcepts')?.value == 'true' " class="col-md-3">
            <div class="card-body" style="padding: 0;">
                <div class="table-responsive">
                    <table class="table table-hover table-vertical-center">
                        <thead>
                            <tr class="bg-primary">
                                <th>Concepto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of this.form?.get('discountConceptsRelated')?.value" data-row="0" class="datatable-row" style="left: 0px;">
                                <td>{{item.description}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
    <!-- <div class="row gutter-b">
        <div class="col-12 mb-5">
            <div class="p-5 bg-primary">
                <h2 class="text-white">Propiedades</h2>
            </div>
        </div>
        <div formArrayName="properties">
            <ng-container *ngFor="let item of properties.controls; let i = index">
                {{i + " " + split}}
                <div class="col-sm-12 col-lg-6">
                    <ul class="list-group list-group-flush">
                <ng-container *ngIf="i < split">


                        <li class="list-group-item">
                            <div class="d-flex text-primary justify-content-between">
                                <label class="h6 mt-1">{{item.value.stringData}}</label>
                                <div class="d-flex">
                                    <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                                    <div class="flex-shrink-0 mx-2">
                                        <span class="switch switch-sm switch-primary">
                                            <label>
                                                <input class="cursor-pointer abc-checkbox-primary" type="checkbox" />
                                                <span></span>
                                            </label>
                                        </span>
                                    </div>
                                    <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>

            <ng-container *ngIf="i >= split">
            <div class="col-sm-12 col-lg-6">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <div class="d-flex text-primary justify-content-between">
                            <label class="h6 mt-1">{{item.value.stringData}}</label>
                            <div class="d-flex">
                                <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                                <div class="flex-shrink-0 mx-2">
                                    <span class="switch switch-sm switch-primary">
                                        <label>
                                            <input class="cursor-pointer abc-checkbox-primary" type="checkbox" />
                                            <span></span>
                                        </label>
                                    </span>
                                </div>
                                <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </ng-container>
            </ng-container>

        </div>

    </div> -->
    <!-- <div class="row pt-5">
        <div class="col-sm-12 col-lg-6">
            <div class="card card-custom border border-primary">
                <div class="bg-primary">
                    <div class=" clard-title align-items-center ">
                        <h4 class="p-3 py-5 text-white m-0">Vigencia</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row gutter-b">
                        <div class="col-5">
                            <div class="d-flex text-primary border border-primary rounded p-3">
                                <label class="h6 mt-1">¿Habilitado?</label>
                                <div class="d-flex">
                                    <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">No</strong>
                                    <div class="flex-shrink-0 mx-2">
                                        <span class="switch switch-sm switch-primary">
                                            <label>
                                                <input class="cursor-pointer abc-checkbox-primary" type="checkbox" />
                                                <span></span>
                                            </label>
                                        </span>
                                    </div>
                                    <strong class="checkbox checkbox-lg checkbox-light-danger checkbox-inline flex-shrink-0 m-0 mx-4">Sí</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Fecha Inicio</label>
                                <input type="date" class="form-control form-control-solid form-control-lg">
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Fecha Fin</label>
                                <input type="date" class="form-control form-control-solid form-control-lg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</form>
<!--div class="mat-dialog-actions mt-2 p-0 mb-0 text-right" [align]="'end'">
    <button (click)="close()" class="btn btn-pill font-weight-bolder btn-shadow btn-danger d-flex dynamicBtnCloseModal mr-2" type="button">
        <i class="flaticon2-cross"></i>
        Cerrar
    </button>
    <button *ngIf="data.inDetails == false" (click)="submit()" class="btn btn-pill font-weight-bolder btn-shadow btn-primary btn-success" type="button">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div-->
