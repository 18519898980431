<div class="card card-custom mantEmpleado">
    <div class="card-header mt-3 py-3">
        <div class="card-title">
            <span class="card-icon">
                <i class="ki ki-gear icon-2x text-danger"></i>
            </span>
            <h2 class="d-flex flex-column text-primary">Mantenimiento Servidores Públicos</h2>
            <h6 class="center font-size-h6 font-weight-bold label label-inline label-light-danger ml-5 px-5">En Desarrollo</h6>
        </div>
        <div class="card-toolbar"></div>
    </div>
    <div class="card-body px-2">
        <div class="row">
            <div class="col-md-12">
                <ul class="nav nav-tabs border-tabs-primary" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="personales-tab" data-toggle="tab" href="#personales">
                            <span class="nav-icon mr-5">
                                <i class="flaticon-recursos-humanos icon-2x"></i>
                            </span>
                            <span class="nav-text">
                                <h4>Servidor Público</h4>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="departamento-tab" data-toggle="tab" href="#departamento" aria-controls="departamento">
                            <span class="nav-icon mr-5">
                                <i class="flaticon-departamento icon-2x"></i>
                            </span>
                            <span class="nav-text">
                                <h4>Unidad Organizativa</h4>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="dpago-tab" data-toggle="tab" href="#dpago" aria-controls="dpago">
                            <span class="nav-icon mr-5">
                                <i class="flaticon-departamento icon-2x"></i>
                            </span>
                            <span class="nav-text">
                                <h4>Datos Pago</h4>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="dependientes-tab" data-toggle="tab" href="#dependientes" aria-controls="dependientes">
                            <span class="nav-icon mr-5">
                                <i class="flaticon-departamento icon-2x"></i>
                            </span>
                            <span class="nav-text">
                                <h4>Dependientes</h4>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="reconocimiento-tab" data-toggle="tab" href="#reconocimiento" aria-controls="reconocimiento">
                            <span class="nav-icon mr-5">
                                <i class="flaticon-reloj icon-2x"></i>
                            </span>
                            <span class="nav-text">
                                <h4>Reconocimiento Tiempo</h4>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="transacciones-tab" data-toggle="tab" href="#transacciones">
                            <span class="nav-icon mr-5">
                                <i class="flaticon-recursos-humanos icon-2x"></i>
                            </span>
                            <span class="nav-text">
                                <h4>Transacciones</h4>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pagos-tab" data-toggle="tab" href="#pagos" aria-controls="pagos">
                            <span class="nav-icon mr-5">
                                <i class="flaticon-departamento icon-2x"></i>
                            </span>
                            <span class="nav-text">
                                <h4>Pagos</h4>
                            </span>
                        </a>
                    </li>
                </ul>
                <div class="tab-content p-3 pt-5">
                    <div class="tab-pane p-3 fade active show" id="personales" role="tabpanel" aria-labelledby="personales-tab"> 
                        <app-employee-info (onSubmit)="updateEmployee($event)" *ngIf="selectedEmployee && personInfo" [selectedEmployee]="selectedEmployee" [personInfo]="personInfo"></app-employee-info>                  
                    </div>                    
                    <div class="tab-pane p-3 fade" id="departamento" role="tabpanel" aria-labelledby="departamento-tab">
                        <app-employee-department  (onSubmit)="updateEmployee($event)" *ngIf="selectedEmployee" [selectedEmployee]="selectedEmployee"[selectedPerson]=""  ></app-employee-department>
                    </div>
                    <div class="tab-pane p-3 fade" id="dpago" role="tabpanel" aria-labelledby="dpago-tab">
                        <app-employee-payment-details></app-employee-payment-details>
                    </div>
                    <div class="tab-pane p-3 fade" id="dependientes" role="tabpanel" aria-labelledby="dependientes-tab">
                        <app-employee-dependents  (onSubmit)="updateEmployee($event)" *ngIf="selectedEmployee" [selectedEmployee]="selectedEmployee"></app-employee-dependents>
                    </div>
                    <div class="tab-pane p-3 fade" id="reconocimiento" role="tabpanel" aria-labelledby="reconocimiento-tab">
                        <app-employee-time-recognition></app-employee-time-recognition>
                    </div>
                    <div class="tab-pane p-3 fade" id="transacciones" role="tabpanel" aria-labelledby="transacciones-tab">
                        <app-employee-transactions></app-employee-transactions>
                    </div>
                    <div class="tab-pane p-3 fade" id="pagos" role="tabpanel" aria-labelledby="pagos-tab">
                        <app-employee-payments></app-employee-payments>
                    </div>
                </div>
            </div>            
            
        </div>        
    </div>
</div>

<!-- <el-modal target="ADTrans" title="Activar/Desactivar Transacciones" size="xl">
    <div class="row">
        <div class="form-group col-lg-5">
            <label>TipoServidor Público<span class="text-danger">*</span> </label>
            <input type="text" class="form-control form-control-lg" />
        </div> 
    </div>
    <div class="row">
        <div class="col-lg-5 border-u p-0">
            <h3 class="p-3 d-flex bg-primary text-white">Transacciones</h3>
            <ul class="no-style">
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 1</li>
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 2</li>
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 3</li>
            </ul>            
        </div>
        <div class="col-lg-2 center flex-column">
            <button class="btn btn-light-primary btn-icon gutter-b">
                <i class="flaticon2-fast-back"></i>
            </button>
            <button class="btn btn-light-primary btn-icon">
                <i class="flaticon2-fast-next"></i>
            </button>            
        </div>
        <div class="col-lg-5 border-u p-0">
            <h3 class="p-3 d-flex bg-primary text-white">Transacciones por TipoServidor Público</h3>
            <ul class="no-style">
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 1</li>
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 2</li>
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 3</li>
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 4</li>
                <li class="bg-gray-200 bg-hover-primary text-hover-light mb-3 py-2 px-4">Linea 5</li>
            </ul>            
        </div>
        
    </div>
    <div class="row gutter-t">
        <div class="d-flex justify-content-end">
          <button class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3" data-dismiss="modal">
            <i class="flaticon2-cross"></i>   
            Cerrar
          </button>       
          <button class="btn btn-pill font-weight-bolder btn-shadow btn-success">
              <i class="flaticon-disco-flexible"></i>
              Guardar
          </button>          
        </div>
      </div>
</el-modal> -->