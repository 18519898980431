export enum DashboardPayrollPeriodTypes{
    NormalWithDefinitiveStatus = 4,
    allPeriodClosePayrollDefinitiveOrderDesc = 5
}
export interface ChartGender {
    genderId: number;
    gender: string;
    countSex: number;
}
export interface IDashboardResponse<T> {
    dataList: T[];
    singleData?: any;
    succeded: boolean;
    errorQuery: number;
    identity: number;
    errors: any[];
}

export enum Genders{
    male = "Masculino",
    female = "Femenino",
    maleId = 2,
    femaleId = 1
}

export interface ChartDepartments {
    departmentId: number;
    department: string;
    countDepartment: number;
}

export interface IDonutChartData{
    sector: string; 
    size: number;
}

export interface IRquisitionByMonth {
    requestedDate: number;
    countMonth: number;
}
export interface IEmployeeByCompany {
    company: string;
    countEmployee: number;
}
export interface IEmployeeByContract {
    contractTypeId: number;
    contractType: string;
    countContractType: number;
}
export interface IHiringsPerYear{
    year: 2022,
    count: number
}
export interface IInterviewByDepartment {
    department: string;
    interviewCount: number;
}










export interface VacantsByYearQuantity {
    description: string;
    value: number;
}

export interface EmployeeByCompaniesQuantity {
    description: string;
    value: number;
}

export interface RequisitionMonthQuantity {
    description: string;
    value: number;
}

export interface InterviewDepartmentsQuantity {
    description: string;
    description2?: any;
    value: number;
    value2: number;
}

export interface HiringAgeQuantity {
    description: string;
    value: number;
}
export interface HiringMonthQuantities {
    description: string;
    value: number;
}

export interface EmployeeDepartmentQuantity {
    description: string;
    value: number;
}

export interface EmployeeTypeContractQuantity {
    description: string;
    value: number;
}

export interface EmployeeGenderQuantity {
    description: string;
    value: number;
}

export interface TotalVacantsQuantity {
    description: string;
    value: number;
}

export interface TotalUserQuantity {
    description: string;
    value: number;
}

export interface TotalDepartmentsQuantity {
    description: string;
    value: number;
}

export interface TotalEmployeesQuantity {
    description: string;
    value: number;
}

export interface TotalGenderByDeparmentQuantity {
    description: string;
    description2: string;
    value: number;
    value2: number;
}

export interface SingleData {
    vacantsByYearQuantities: VacantsByYearQuantity[];
    employeeByCompaniesQuantities: EmployeeByCompaniesQuantity[];
    requisitionMonthQuantities: RequisitionMonthQuantity[];
    interviewDepartmentsQuantities: InterviewDepartmentsQuantity[];
    hiringAgeQuantities: HiringAgeQuantity[];
    hiringMonthQuantities: HiringMonthQuantities[];
    hiringUnlinkQuantities: any[];
    employeeDepartmentQuantities: EmployeeDepartmentQuantity[];
    employeeTypeContractQuantities: EmployeeTypeContractQuantity[];
    employeeGenderQuantities: EmployeeGenderQuantity[];
    totalVacantsQuantities: TotalVacantsQuantity[];
    totalUserQuantities: TotalUserQuantity[];
    totalDepartmentsQuantities: TotalDepartmentsQuantity[];
    totalEmployeesQuantities: TotalEmployeesQuantity[];
    totalGenderByDeparmentQuantities: TotalGenderByDeparmentQuantity[];
    vacantsByGenderQuantities?: any;
    payrollBylast3month: payrollBylast3month[];
    vacantsByEnclosureQuantities: any[];
    vacantsByDepartmentsQuantities: any[];
    vacantsByCreatedDateQuantities: any[];
    vacantsByAcademicLevelQuantities: any[];
    personsVacantsByGenderQuantities: any[];
    personsVacantsByNationalityQuantities: any[];
    personsVacantsByAgeQuantities: any[];
    personsVacantsByAcademiclevelQuantities: any[];
    requisitionByGenderQuantities: any[];
    requisitionByEnclosureQuantities: any[];
    requisitionByDepartmentQuantities: any[];
    requisition3LastYearsQuantities: any[];
    requisitionConditionQuantities: any[];
    requisitionsMonthQuantities: any[];
    interviewsByDepartmentsQuantities: any[];
    interviewsByGenderQuantities: any[];
    interviewsTotalQuantities: any[];
    interviewsByAcademicLevelQuantities: any[];
    totalEmployeeIncompleteInformation: any[];
}
export interface employeeSexDepartment{
    rowText: string;
    value1: number;
    value2: number;
}
export interface IResponseHome {
    dataList?: any;
    singleData: SingleData;
    succeded: boolean;
    errorQuery: number;
    identity: number;
    errors: any[];
}
export interface payrollBylast3month {
    description: string;
    description2?: any;
    value: number;
    value2: number;
    value3: number;
    value4: number;
}


export interface IGetDepartments {
    companyId: number;
    locationsId: number;
    departmentId: number;
    isHhrr?: any;
    departmentName: string;
    shortName: string;
    sectionId: number;
    levelSection: number;
    parentSection: number;
    parentSectionName?: any;
    head?: any;
    afterId?: any;
    beforeId?: any;
    depthLevel?: any;
    parentSection2?: any;
    hasChildren?: any;
    disableDrag?: any;
    disableDragDest?: any;
    supervisor: string;
    position: string;
    status: boolean;
    socialReason: string;
    shortNameCompany: string;
    imageProfile: string;
    code?: any;
}
export interface IPeriodsGET {
    payrollPeriodId: number;
    periodId: number;
    description: string;
    sendLimitDate: Date;
    conditionId: number;
    status: boolean;
    companyId: number;
    createDate: Date;
    createUserId: number;
    modifyDate?: any;
    modifyUserId?: any;
    startDate: Date;
    endDate: Date;
    openingDate: Date;
  }
  
  
  
  
  export interface PayrollByDeparment {
    description: string;
    description2?: any;
    value?: number;
    value2?: number;
    value3?: number;
    value4?: number;
}

export interface IDashboardSelect {
    vacantsByYearQuantities: any[];
    employeeByCompaniesQuantities: any[];
    requisitionMonthQuantities: any[];
    interviewDepartmentsQuantities: any[];
    hiringAgeQuantities: any[];
    employeeDepartmentQuantities: any[];
    employeeTypeContractQuantities: any[];
    employeeGenderQuantities: any[];
    totalVacantsQuantities: any[];
    totalUserQuantities: any[];
    totalDepartmentsQuantities: any[];
    totalEmployeesQuantities: any[];
    totalGenderByDeparmentQuantities: any[];
    vacantsByGenderQuantities?: any;
    vacantsByEnclosureQuantities: any[];
    vacantsByDepartmentsQuantities: any[];
    vacantsByCreatedDateQuantities: any[];
    vacantsByAcademicLevelQuantities: any[];
    vacantsTotalQuantities: any[];
    vacantsTotalPublishedQuantities: any[];
    vacantsTotalNoPublishedQuantities: any[];
    vacantsTotalByYearsQuantities: any[];
    vacantsTotalBy3LastYearsQuantities: any[];
    personsVacantsByGenderQuantities: any[];
    personsVacantsByNationalityQuantities: any[];
    personsVacantsByAgeQuantities: any[];
    personsVacantsByAcademiclevelQuantities: any[];
    personsVacantsTotalQuantities: any[];
    requisitionByGenderQuantities: any[];
    requisitionByEnclosureQuantities: any[];
    requisitionByDepartmentQuantities: any[];
    requisition3LastYearsQuantities: any[];
    requisitionConditionQuantities: any[];
    requisitionsMonthQuantities: any[];
    requisitionsTotalQuantities: any[];
    interviewsByDepartmentsQuantities: any[];
    interviewsByGenderQuantities: any[];
    interviewsTotalQuantities: any[];
    interviewsByAcademicLevelQuantities: any[];
    totalEmployeeQuantities: any[];
    totalEmployeeInnactiveQuantities: any[];
    totalEmployeeByDepartmentsQuantities: any[];
    totalEmployeeBy3LastYearsQuantities: any[];
    totalEmployeeByContractTypeQuantities: any[];
    totalEmployeeByNationalityQuantities: any[];
    totalEmployeeByAcademicLevelQQuantities: any[];
    totalEmployeeByAgeQuantities: any[];
    totalEmployeeByGenderQuantities: any[];
    totalEmployeeByPrecintQuantities: any[];
    totalEmployeeHiringQuantities: any[];
    totaHiringDepartmentQuantities: any[];
    totaHiringGenderQuantities: any[];
    totaHiringStateQuantities: any[];
    totaPostulationTestQuantities: any[];
    postulationTestByTypeQuantities: any[];
    postulationTestByScoreQuantities: any[];
    postulationTestByGenderQuantities: any[];
    incidentsTotalQuantities: any[];
    incidentsByGenderQuantities: any[];
    incidentsByTypeQuantities: any[];
    incidentsByDepartmentQuantities: any[];
    incidentsByTypeEmployeeQuantities: any[];
    incidentsByDegreeQuantities: any[];
    actionEmployeeTotalQuantities: any[];
    actionEmployeeContractTypeQuantities: any[];
    actionEmployeeByGenderQuantities: any[];
    actionEmployeeByDepartmentQuantities: any[];
    actionEmployeeB3LastYearsQuantities: any[];
    noObjectionTotalQuantities: any[];
    noObjectionByStatusQuantities: any[];
    payrollByDeparment: PayrollByDeparment[];
    payrollBylast3month: any[];
    employeeByTransport: any[];
}

