<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" class="close" aria-label="Close" (click)="this.dialogRef.close(false)">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white">
        <span class="h-20px label label-danger label-dot mr-3 w-20px"></span>
        {{title}} Contrato
    </h2>
</div>
<div class="mat-dialog-content-custom">
    <div class="card-body-custom" [formGroup]="contractForm">

        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="form-group">
                    <label>Inicio de Contrato <span class="text-danger">*</span></label>
                    <div>
                        <input type="date" class="form-control" formControlName="startDate" [min]="admissionDate" [max]="contractForm.get('endDate').value" />
                        <span class="text-danger" *ngIf="startDateInvalid">La fecha seleccionada debe ser superior a la fecha de registro del servidor público</span>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="form-group">
                    <label>Fin de Contrato <span class="text-danger">*</span></label>
                    <div>
                        <input type="date"  class="form-control" formControlName="endDate" 
                        [min]="contractForm.get('startDate').value" />
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="form-group">
                    <label>Precio por Hora <span class="text-danger">*</span></label>
                    <div>
                        <input type="text" currencyMask [options]="{precision:2}" placeholder="$0.00" class="form-control"  formControlName="pricePerHour"  />
                    </div>
                </div>
            </div>
        </div>
        <h5 class="mt-0 mb-2">
            Evidencias
        </h5>
        <div class="table-responsive" formArrayName="teacherDocuments">
            <table class="table table-vertical-center ">
                <thead>
                    <tr class="bg-primary">
                        <th *ngIf="data.viewType != viewTypes.Create" class="text-center">Secuencia</th>
                        <th class="text-left">
                            Documento
                        </th>
                        <th class="text-center">¿Opcional?</th>
                        <th class="text-center">Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let documentForm of getDocumentsForm().controls; let i = index" [formGroupName]="i">
                        <td *ngIf="data.viewType != viewTypes.Create" class="text-center">{{documentForm.get('teacherDocumentId').value}}</td>
                        <td class="text-left">{{documentForm.get('description').value}}</td>
                        <td class="text-center">
                            <span *ngIf="documentForm.get('isRequired').value; else noRequired"><strong>NO</strong></span>
                            <ng-template #noRequired><strong>SI</strong></ng-template>
                        </td>
                        <td class="text-center">
                            <ng-container *ngIf="documentForm.get('document')?.value else noEvidence">
                                <div class="d-flex justify-content-center">
                                    <button type="button" class="btn btn-light-info btn-icon mr-2" ata-title="Ver"
                                    data-toggle="tooltip"
                                        (click)="openEvidence(documentForm?.get('document')?.value)">
                                        <i class="flaticon-ver"></i>
                                    </button>
                                    <button type="button" class="btn btn-danger btn-icon" (click)="deleteFile(i)" ata-title="Eliminar"
                                    data-toggle="tooltip"
                                        *ngIf="documentForm.get('document')?.value && data.viewType != viewTypes.View">
                                        <i class="flaticon-delete-1"></i>
                                    </button>
                                </div>
                            </ng-container>
                            <ng-template #noEvidence>
                                <label *ngIf="data.viewType != viewTypes.View || editMode" for="file-upload{{i}}"
                                    class="btn btn-light-info btn-sm btn-xmera-grid" data-title="Subir"
                                    data-toggle="tooltip">
                                    <i class="flaticon-subir-3"></i>
                                </label>
                                <input *ngIf="data.viewType != viewTypes.View" id="file-upload{{i}}"
                                    class="btn btn-light-info btn-sm btn-xmera-grid" type="file"
                                    accept="application/pdf" (change)="manageFiles($event, i)" />
                                <div *ngIf="documentForm.get('document').invalid && formSubmitted" >
                                    <br>
                                    <small class="text-danger">Esta evidencia es obligatoria</small>
                                </div>

                            </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="mat-dialog-actions" [align]="'end'">
    <button (click)="this.dialogRef.close(false)" class="btn btn-pill font-weight-bolder btn-shadow btn-danger mr-3"
        type="button">
        <i class="flaticon2-cross"></i>
        <span>Cerrar</span>
    </button>
    <button class="btn btn-pill font-weight-bolder btn-shadow btn-success" type="button" (click)="submit()" 
    *ngIf="data.viewType == viewTypes.Create || editMode">
        <i class="flaticon-disco-flexible"></i>
        <span>Guardar</span>
    </button>
</div>
