import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AccountService } from 'src/app/services/account.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { docService } from 'src/app/services/doc.service';
import { PersonService } from 'src/app/services/http-client/personsService.service';
import { serviceRequestService } from 'src/app/services/serviceRequest.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-employee-service-request-modal',
  templateUrl: './employee-service-request-modal.component.html',
  styleUrls: ['./employee-service-request-modal.component.css']
})
export class EmployeeServiceRequestModalComponent implements OnInit {
  @ViewChild("userHtml", { static: false }) userHtml;
  public body
  public request;
  public loading = false;
  public title = "Solicitud de Servicio"
  public account;
  public aproveed = 0;
  public buttonText = "Procesar";

  pdfUrl: SafeResourceUrl | null = null;

  constructor(private srvPerson: PersonService,
    private _toastService: ToastService,
    private dialogRef: MatDialogRef<EmployeeServiceRequestModalComponent>,
    private srvRequestService: serviceRequestService,
    private srvCompany: CompaniesService,
    private sanitizer: DomSanitizer,
    private srvDoc: docService,
    private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public params,) { }

  ngOnInit(): void {
    this.account = this.accountService.accountValue;
    this.getEmployeeData();
  }

  getEmployeeData() {
    this.loading = true
    this.aproveed = this.params.aproveed;

    if (this.params.letterType == 2) {
      this.title = "Certificación Laboral"
    };

    if (this.params.entregar == true) {
      this.buttonText = "Entregar"
    } else {
      this.buttonText = "Procesar"
    };

    this.srvPerson.getEmployeeDataByEmployeeId(this.params.employeeId).subscribe((res: any) => {
      if (res.succeded && res.dataList.length > 0) {
        var data = res.dataList[0]

        this.srvCompany.getCompanyById(data.companyId).subscribe(comp => {
          var dataComp = comp.dataList[0]
          this.request = {
            name: data.name,
            identification: data.personalIdentification,
            admissionDate: data.admissionDate,
            positionName: data.positionName,
            departmentName: data.departmentName,
            companyName: data.companyName,
            address: dataComp.address,
            companyId: data.companyId,
            salary: data.salary,
            tel: dataComp.companyPhone,
            web: dataComp.url,
            email: dataComp.companyEmail,
            companyImage: '',
            reason: this.params.reason,
            letterType: this.params.letterType,
            exitDate: data.exitDate,
            employeeId: Number(this.params.employeeId),
            sexId: data.sexId,
            requestId: this.params.requestId,
            rnc: dataComp.rnc,
            nameTo: this.params.letterBody?.nameTo,
            positionTo: this.params.letterBody?.positionTo,
            institutionTo: this.params.letterBody?.institutionTo,
            addressTo: this.params.letterBody?.addressTo,
            tittleTo: this.params.letterBody?.tittleTo,
            approvedUserId: Number(this.account.employeeId),
            status: (this.params.entregar == true ? 4 : 0),
            year: this.params.year
          }

          this.srvDoc.getDocument(dataComp.image1).subscribe(e => {
            this.request.companyImage = e.data;

            this.srvRequestService.postRequestServices(this.request).subscribe(response => {
              this.loading = false
              try {
                const file = response.singleData;

                this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                  `${environment.api_url}/EmployeePermissions/files/${file.fileName}?fileId=${file.fileId}`
                );
                
              } catch (error) {
              }
            }, err => {
              this.loading = false
            })
          }, er => {
            this.loading = false
          })
        }, error => {
          this.loading = false
        })

      } else {
        this._toastService.error(res.errors[0])
      }
    }, error => {
      this._toastService.error('Ha ocurrido un error inesperado, por favor intente más tarde o comuníquese con HelpDesk.')
    })
  }

  close() {
    this.dialogRef.close(null);
  }

  aprovee() {
    this.dialogRef.close(this.request);
  }

  saveAddress() {

  }

}
