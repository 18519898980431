

<div class="card-body">
    <div class="d-flex justify-content-between pr-5">
        <div></div>
        <div class="d-flex align-items-center">
            <div class="dropdown col-md-auto col-sm-12">
                <button class="btn btn-primary btn-pill dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="flaticon-descarga-arrow icon-2x text-white" data-toggle="tooltip"
                        data-original-title="Opciones de exportar"></i> Exportar
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <button class="dropdown-item d-flex align-items-center" href="#">
                        <i class="flaticon-excel icon-2x text-success mr-3"></i>
                        <span>Excel</span>
                    </button>
                    <!-- <a class="dropdown-item disabled" href="#">
                        <i class="flaticon-word icon-2x text-primary mr-3"></i>
                        Word
                    </a>
                    <a class="dropdown-item disabled" href="#">
                        <i class="flaticon-pdf-file icon-2x text-danger mr-3"></i>
                        PDF
                    </a>
                    <a class="dropdown-item disabled" href="#">
                        <i class="flaticon-excel-1 icon-2x text-primary mr-3"></i>
                        CSV
                    </a> -->
                </div>
            </div>
            <el-search-bar #searchBar [records]="dataListOriginal" [itemForSearching]="searchBarDisplayNames"
            (onChange)="getFilteredList($event);"></el-search-bar>
        </div>
    </div>
    <div class="table-responsive table-responsive-sigei">
        <table class="table mt-2 table-vertical-center">
            <thead>
                <tr class="bg-primary">
                    <!-- <th>Código Compañía</th> -->
                    <th>Nombre</th>
                    <th>Cargo</th>
                    <th>Unidad Organizativa</th>
                    <th>Correo</th>
                    <th>Teléfono</th>
                    <th>Extensión</th>
                    <th class="actionsBtn">Acciones</th>
                </tr>
            </thead>

            <tbody>
                
                    <tr *ngFor="let item of dataPaginated">
                        <td>{{item.name}}</td>
                        <td>{{item.positionName}}</td>
                        <td>{{item.departmentName}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.phoneNumber ? (item.phoneNumber | mask: '(000) 000 0000') : ''}}</td>
                        <td></td>
                        <td class="actionsBtn">
                            <button (click)="openDirectoryModal()" class="btn btn-icon btn-light-warning btn-sm m-1" data-toggle="tooltip" title="Editar">
                                <i class="flaticon-edit"></i>
                            </button>
                                
                        </td>
                    </tr>
            </tbody>
        </table>
        <div>

            <el-caption #paginator [originalRecordsLength]="filteredList?.enable ? filteredList.records?.length :dataList?.length"
                [listedRecordsLength]="dataPaginated?.length"
                [registerQuantity]="filteredList?.enable && filteredList.records?.length>0 ? filteredList.records?.length :dataList?.length"
                [records]="filteredList?.enable ? filteredList?.records : dataList"
                (onChage)="getPaginatedRecords($event)"></el-caption>
        </div>
    </div>
</div>