<div class="modal-header border-0 dv-table-header-1 bg-primary" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <button type="button" (click)="this.dialogRef.close();" class="close" aria-label="Close">
        <i aria-hidden="true" class="ki ki-close text-white font-size-h3"></i>
    </button>
    <h2 class="font-size-h5 text-white"><span class="h-20px label label-danger label-dot mr-4 w-20px"></span>Dashboard Aplicar a Vacante</h2>
</div>
<div class="mat-dialog-content-custom">
<div class="card-body-custom">
    <div class="card bg-white p-5 gutter-b">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap align-items-center bg-light-warning rounded p-5">
                    <span class="svg-icon svg-icon-warning svg-icon-4x mr-5">
                        <span class="svg-icon-r svg-icon-primary svg-icon-2x">
                            <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Text/Bullet-list.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z" fill="#000000" />
                                    <path d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                            <!--end::Svg Icon-->
                         </span>
                    </span>
                    <div class="d-flex flex-wrap flex-column flex-grow-1 mr-2">
                        <h3 class="font-weight-bold text-dark-75 font-size-r">Total</h3>
                    </div>
                    <span class="font-weight-bolder text-warning py-1 font-size-r">
                        {{amountTotal}}
                    </span>
                </div>
            </div>
            <!-- <div class="col-lg-4">
                <div class="d-flex align-items-center bg-light-info rounded p-5">
                    <span class="svg-icon svg-icon-info svg-icon-4x mr-5">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path d="M6.182345,4.09500888 C6.73256296,3.42637697 7.56648864,3 8.5,3 L15.5,3 C16.4330994,3 17.266701,3.42600075 17.8169264,4.09412386 C17.8385143,4.10460774 17.8598828,4.11593789 17.8809917,4.1281251 L22.5900048,6.8468751 C23.0682974,7.12301748 23.2321726,7.73460788 22.9560302,8.21290051 L21.2997802,11.0816097 C21.0236378,11.5599023 20.4120474,11.7237774 19.9337548,11.4476351 L18.5,10.6198563 L18.5,19 C18.5,19.5522847 18.0522847,20 17.5,20 L6.5,20 C5.94771525,20 5.5,19.5522847 5.5,19 L5.5,10.6204852 L4.0673344,11.4476351 C3.58904177,11.7237774 2.97745137,11.5599023 2.70130899,11.0816097 L1.04505899,8.21290051 C0.768916618,7.73460788 0.932791773,7.12301748 1.4110844,6.8468751 L6.12009753,4.1281251 C6.14061376,4.11628005 6.16137525,4.10524462 6.182345,4.09500888 Z" fill="#000000" opacity="0.3" />
                                <path d="M9.85156673,3.2226499 L9.26236944,4.10644584 C9.11517039,4.32724441 9.1661011,4.62457583 9.37839459,4.78379594 L11,6 L10.0353553,12.7525126 C10.0130986,12.9083095 10.0654932,13.0654932 10.1767767,13.1767767 L11.6464466,14.6464466 C11.8417088,14.8417088 12.1582912,14.8417088 12.3535534,14.6464466 L13.8232233,13.1767767 C13.9345068,13.0654932 13.9869014,12.9083095 13.9646447,12.7525126 L13,6 L14.6216054,4.78379594 C14.8338989,4.62457583 14.8848296,4.32724441 14.7376306,4.10644584 L14.1484333,3.2226499 C14.0557004,3.08355057 13.8995847,3 13.7324081,3 L10.2675919,3 C10.1004153,3 9.94429962,3.08355057 9.85156673,3.2226499 Z" fill="#000000" />
                            </g>
                        </svg>
                    </span>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                        <h3 class="font-weight-bold text-dark-75">Masculino</h3>
                    </div>
                    <span class="font-weight-bolder text-info py-1 font-size-h2">
                        {{amountMale.total|| 0}} ({{amountMale.percentage || 0}}%)
                    </span>
                </div>
            </div> -->
            <!-- <div class="col-lg-4">
                <div class="d-flex align-items-center bg-light-danger rounded p-5">
                    <span class="svg-icon svg-icon-danger svg-icon-4x mr-5">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path d="M8.04050792,3 C8.31778201,4.50768629 9.98487319,5.66666667 12,5.66666667 C14.0151268,5.66666667 15.682218,4.50768629 15.9594921,3 L18.381966,3 C18.9342508,3 19.381966,3.44771525 19.381966,4 C19.381966,4.1552451 19.3458209,4.30835816 19.2763932,4.4472136 L16.2763932,10.4472136 C16.1070012,10.7859976 15.7607381,11 15.381966,11 L8.61803399,11 C8.23926193,11 7.89299881,10.7859976 7.7236068,10.4472136 L4.7236068,4.4472136 C4.47661755,3.9532351 4.6768419,3.35256206 5.17082039,3.10557281 C5.30967583,3.03614509 5.46278889,3 5.61803399,3 L8.04050792,3 Z" fill="#000000" opacity="0.3" />
                                <path d="M9.35406592,12 L14.6459341,12 C15.4637433,12 16.1991608,12.4979019 16.5028875,13.2572186 L19.4514437,20.6286093 C19.6565571,21.1413928 19.4071412,21.7233633 18.8943577,21.9284767 C18.7762374,21.9757248 18.6501865,22 18.522967,22 L5.47703296,22 C4.92474821,22 4.47703296,21.5522847 4.47703296,21 C4.47703296,20.8727806 4.50130816,20.7467296 4.54855627,20.6286093 L7.49711254,13.2572186 C7.80083924,12.4979019 8.53625675,12 9.35406592,12 Z" fill="#000000" />
                            </g>
                        </svg>
                    </span>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                        <h3 class="font-weight-bold text-dark-75">Femenino</h3>
                    </div>
                    <span class="font-weight-bolder text-danger py-1 font-size-h2">
                        {{amountFemale.total || 0}} ({{amountFemale.percentage || 0}}%)
                    </span>
                </div>
            </div> -->
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-3">
                    <h4 class="card-title">Por Nacionalidad</h4>
                </div>
                <div class="card-body p-3">
                    <ng-container *ngIf="countryChart?.series.length > 0">
                        <apx-chart
                            [series]="countryChart.series"
                            [labels]="countryChart.labels"
                            [dataLabels]="chartOptions.dataLabels"
                            [chart]="chartOptions.chart"
                            [title]="chartOptions.title"
                            [plotOptions]="chartOptions.plotOptions"
                            [stroke]="chartOptions.stroke"
                            [fill]='chartOptions.fill'
                            [colors]='chartOptions.colors'
                            [responsive]="chartOptions.responsive"
                            [legend]="chartOptions.legend"
                        ></apx-chart>
                    </ng-container>
                    <ng-container *ngIf="countryChart.series.length < 1">
                        {{countryChart.status}}
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col-md-6 gutter-b">
            <div class="card card-custom bg-white">
                <div class="card-header p-3">
                    <h4 class="card-title">Por Sexo</h4>
                </div>
                <div class="card-body p-3">
                    <ng-container *ngIf="genderChart?.series.length">
                        <apx-chart
                            [series]="genderChart.series"
                            [labels]="genderChart.labels"
                            [dataLabels]="chartOptions.dataLabels"
                            [chart]="chartOptions.chart"
                            [title]="chartOptions.title"
                            [plotOptions]="chartOptions.plotOptions"
                            [stroke]="chartOptions.stroke"
                            [fill]='chartOptions.fill'
                            [colors]='chartOptions.colors'
                            [responsive]="chartOptions.responsive"
                            [legend]="chartOptions.legend"
                        ></apx-chart>
                    </ng-container>
                    <ng-container *ngIf="!genderChart?.series.length">
                        {{genderChart.status}}
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 gutter-b">
            <div class="card card-custom bg-white">
              <div class="card-header p-3">
                  <h4 class="card-title">Por Edad</h4>
              </div>
              <div class="card-body p-3">
                  <ng-container *ngIf="ageChart.series.length">
                <apx-chart
                [series]="ageChart.series"
                [labels]="ageChart.labels"
                [dataLabels]="chartOptions.dataLabels"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [plotOptions]="chartOptions.plotOptions"
                [stroke]="chartOptions.stroke"
                [fill]='chartOptions.fill'
                [colors]='chartOptions.colors'
                [responsive]="chartOptions.responsive"
                [legend]="chartOptions.legend"
                ></apx-chart>
              </ng-container>
              <ng-container  *ngIf="ageChart.series.length == 0">
                {{ageChart.status}}
              </ng-container>
              </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card card-custom bg-white">
              <div class="card-header p-3">
                  <h4 class="card-title">Por Nivel Académico</h4>
              </div>
              <div class="card-body p-3">
                  <ng-container *ngIf="academicLevelChart.series.length">
                <apx-chart
                [series]="academicLevelChart.series"
                [labels]="academicLevelChart.labels"
                [dataLabels]="chartOptions.dataLabels"
                [chart]="chartOptions.chart"
                [title]="chartOptions.title"
                [plotOptions]="chartOptions.plotOptions"
                [stroke]="chartOptions.stroke"
                [fill]='chartOptions.fill'
                [colors]='chartOptions.colors'
                [responsive]="chartOptions.responsive"
                [legend]="chartOptions.legend"
                ></apx-chart>
              </ng-container>
              <ng-container  *ngIf="academicLevelChart.series.length == 0">
                {{academicLevelChart.status}}
              </ng-container>
              </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="false" class="modal-footer">
  <button type="button" class="btn btn-danger" data-dismiss="modal">
    <i class="flaticon-cancel"></i>
      Close
  </button>
  <button type="button" class="btn btn-success"> <i class="flaticon-upload"></i> Guardar</button>
</div>
</div>
