import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IPauseVacationPost, IVacancy } from '../../models/Vacancy';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { PersonnelActionsService } from 'src/app/services/personnel-actions.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { parameterDoc, personalFile } from 'src/app/shared/utils/parameterDoc';
import Uppy from '@uppy/core';
import Mexico from '@uppy/locales/lib/es_MX'
import Dashboard from '@uppy/dashboard';
import { ModalViewsEnum } from 'src/app/models/modal-views';
import { FileResponse } from 'src/app/shared/models/candidate-registration.model';
import XHRUpload from '@uppy/xhr-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { docService } from 'src/app/services/doc.service';
import { ParameterControlService } from 'src/app/services/parameter-control.service';
import { ShowFileVacationModalComponent } from '../show-file-vacation-modal/show-file-vacation-modal.component';

type GUID = string & { isGuid: true };

@Component({
  selector: 'app-pause-vacation-modal',
  templateUrl: './pause-vacation-modal.component.html',
  styleUrls: ['./pause-vacation-modal.component.css']
})
export class PauseVacationModalComponent implements OnInit {

  evidenceGUID = '';
  readonly urlDoc = environment.api_doc + '/Document';
  personalFile = personalFile;
  SystemLoad: number = 1;
  ModuleLoad: number = 2;
  routeFile: string = "";

  evidenceUppy = new Uppy({
    restrictions: {
      maxFileSize: environment.maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['application/pdf']
    },
    locale:Mexico
  });

  evidence = { document: '', documentLicense: '' }

  vacationForm: FormGroup = new FormGroup({
    vacancyId: new FormControl(null, Validators.required),
    employeeId: new FormControl(null),
    newDateEnd: new FormControl(null, Validators.required),
    days: new FormControl(null, Validators.required),
    evidence: new FormControl(null, Validators.required),
    comment: new FormControl(null, [Validators.maxLength(500)]),
    userId: new FormControl(null)
  });

  noWorkingDays: { date: string, celebration: string; country: string, countryShortName: string; }[] = [];
  noWorkingDayMatchWithDates: { date: string, celebration: string; country: string, countryShortName: string; }[] = [];

  maxDateEnd!: string;
  minDateEnd!: string;

  viewTypes = ModalViewsEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { vacationData: IVacancy, viewType: ModalViewsEnum },
    public dialogRef: MatDialogRef<PauseVacationModalComponent>,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private authInfo: AuthInfoService,
    private personnelActionsService: PersonnelActionsService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private docService: docService,
    private paramService: ParameterControlService) {

    if(this.data.vacationData.vacancyId > 0){
      this.patchData(this.data?.vacationData);
      this.getNoWorkingDays();
    }

  }

  ngOnInit(): void {
    this.getPathTestParameters();
  }

  patchData(vacationData: IVacancy) {
    this.vacationForm.patchValue({
      ...vacationData,
      newDateEnd: this.datePipe.transform(vacationData?.dateEnd,'yyyy-MM-dd'),
      comment: vacationData?.commentForPause,
      evidence: vacationData?.evidenceForPause
    });


    if(vacationData.dateEnd){
      this.maxDateEnd = this.datePipe.transform(vacationData?.dateEnd,'yyyy-MM-dd')
    }

    if(vacationData.dateStart){
      this.minDateEnd = this.datePipe.transform(vacationData?.dateStart,'yyyy-MM-dd')
    }

    if(this.data.viewType == ModalViewsEnum.View ){
      this.vacationForm.disable();

      if (vacationData?.evidenceForPause) {
        this.evidenceGUID = this.getGuid(vacationData?.evidenceForPause);
        this.docService.getDocument(this.evidenceGUID).subscribe(e => {
          this.evidence.document = e.data
        }, error => {
          this.toastr.error(error)
        })
      }
    }

    
  }

  pauseVacation() {

    if(this.data.viewType == ModalViewsEnum.View){
      this.toastr.warning('La ventana se encuentra en modo vista, no es posible realizar esa acción');
      return;
    }

    if (this.vacationForm.invalid) {
      this.toastr.warning('Favor de llenar los campos obligatorios');
      return;
    }

    let model: IPauseVacationPost = this.vacationForm.getRawValue();
    model.userId = this.authInfo.getUserId();

    const newEndDate = moment(model?.newDateEnd).hour(0).minute(0).second(0).millisecond(0);
    const startDate =  moment(this.data.vacationData?.dateStart).hour(0).minute(0).second(0).millisecond(0);

    if(this.data.vacationData?.dateEnd){

      const oldEndDate = moment(this.data.vacationData?.dateEnd).hour(0).minute(0).second(0).millisecond(0);

      if (newEndDate >=  oldEndDate) {
        this.toastr.warning('La nueva fecha fin debe ser menor a la anterior');
        return;
      }
    }

    if (newEndDate < startDate) {
      this.toastr.warning('La nueva fecha fin debe ser mayor o igual a la fecha de inicio');
      return;
    }


    this.personnelActionsService.sendPauseVacationRequest(model).subscribe({
      next: (res) => {

        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          });

          res.warnings.forEach(warn => {
            this.toastr.warning(warn);
          });

          return;
        }

        this.toastr.success('Vacaciones pausadas satisfactoriamente');

        this.dialogRef.close(true);

      },
      error: (err) => {
        this.toastr.error('Ha ocurrido un error tratando de pausar las vacaciones seleccionadas');
      }
    })

  }


  getNoWorkingDays() {
    this.personnelActionsService.getHolidays().subscribe((response) => {
      if (response.succeded) {
        this.noWorkingDays = response.dataList.filter(x => x.status)
          .sort((a, b) => {
            return a.month - b.month
          }).map((noWorkingDay) => {
            return {
              date: `${noWorkingDay.year}-${noWorkingDay.month < 10 ? '0'.concat(noWorkingDay.month.toString()) : noWorkingDay.month}-${noWorkingDay.day}`,
              celebration: noWorkingDay.celebration,
              country: noWorkingDay.names,
              countryShortName: noWorkingDay.alfanumeric3
            };
          });

      }
    });
  }

  daysRequested() {
    if (this.data.vacationData.dateStart && this.vacationForm.get('newDateEnd').value) {

      this.vacationForm.get('days').patchValue(
        this.workingDaysCount(
          moment(this.data.vacationData.dateStart),
          moment(this.vacationForm.get('newDateEnd').value))
      );
      
    }
  }

  workingDaysCount(firstDate: any, secondDate: any): number {

    const firstWeekEnd = firstDate.clone().endOf('week');
    const lastWeekStart = secondDate.clone().startOf('week');
    const daysBetween = lastWeekStart.diff(firstWeekEnd, 'days') * 5 / 7; // this will always multiply of 7

    let firstWeekDays = firstWeekEnd.day() - firstDate.day(); // check first week
    if (firstDate.day() === 0) { // -1 if start with sunday
        firstWeekDays--;
    }

    let lastWeekDays = secondDate.day() - lastWeekStart.day(); // check last week
    if (secondDate.day() === 6) { // -1 if end with saturday
        lastWeekDays--;
    }

    let noWorkingDayCount = 0;
    this.noWorkingDayMatchWithDates = [];
    this.noWorkingDays.forEach((element) => {
        if (new Date(element.date) >= new Date(firstDate._i) && new Date(element.date) <= new Date(secondDate._i)) {
            const date = moment(element?.date);
            this.noWorkingDayMatchWithDates.push(element);
            if (date?.isoWeekday() === 6 || date?.isoWeekday() === 7) {
                return;
            }
            noWorkingDayCount++;
        }
    });

    return firstWeekDays + Math.floor(daysBetween) + lastWeekDays - noWorkingDayCount;

  }

  getPathTestParameters() {
    this.paramService.getParameters(parameterDoc.PathTest).subscribe({
      next: (res) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this.toastr.error(err);
          })

          res.warnings.forEach(err => {
            this.toastr.warning(err);
          })
          return;
        }
        this.routeFile = res.dataList[0].stringData.toString()
        this.loadUppy()
      },
      error: (err) => {

      }
    })
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  loadUppy() {

    this.evidenceUppy.use(Dashboard, {
      trigger: '.UppyModalOpenerBtn',
      inline: true,
      target: '.vacationEvidence',
      disabled: this.data.viewType == ModalViewsEnum.View ? true : false,
      showProgressDetails: true,
      note: 'Solo imagenes, 1 archivo, con un maximo de 10 MB',
      width: 320,
      height: 295,
      locale: {
        strings: {
          dropPasteFiles: 'Arrastra archivos aquí o %{browse}'
        }
      },
      thumbnailWidth: 320,
      metaFields: [
        { id: 'name', name: 'Nombre', placeholder: 'Nombre del Archivo' },
        { id: 'caption', name: 'Caption', placeholder: 'Descripción de la imagen' }
      ],
      browserBackButtonClose: false,
      showLinkToFileUploadResult: true,
    })
      .use(XHRUpload, { endpoint: this.urlDoc + `?SystemLoad=${this.SystemLoad}&ModuleLoad=${this.ModuleLoad}&personalFile=${this.personalFile.photo2x2}&parameterDoc=${this.routeFile}` })
      .on('complete', (result) => {
        if (result.successful) {
          let resData = result.successful[0].response.body.data as FileResponse

          this.evidenceGUID = this.getGuid(resData.fileUnit.guidname)
          this.evidence.document = resData.path;
          this.vacationForm.get('evidence').setValue(this.evidenceGUID);

          this.docService.getDocument(this.evidenceGUID).subscribe(e => {
            this.evidence.document = e.data
          }, error => {
            this.toastr.error(error)
          })

        }
      });
  }

  getGuid(guid: string): GUID {
    return guid as GUID; // maybe add validation that the parameter is an actual guid ?
  }

  deleteEvidence() {
    if (!this.evidenceGUID) {
      return;
    }


    this.docService.deleteFile(this.evidenceGUID).subscribe(e => {
      this.evidenceUppy.cancelAll()
      this.evidence.document = '';
      this.evidenceGUID = ''
      this.vacationForm.get('evidence').setValue(null);

    }, error => {
      this.toastr.error(error)
    })

  }

  openEvidence(guid, isBase64: boolean = false, base64: string = null) {
    this.dialog.open(ShowFileVacationModalComponent, {
      data: { guid: guid, title: 'Evidencia', isBase64, base64 },
      width: '70%',
    });
  }

}
