import { Component, OnInit } from '@angular/core';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { docService } from 'src/app/services/doc.service';
import { PositionService } from 'src/app/services/position.service';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PositionCompetency } from '../models/positions-description.model';
import { ProbationaryPeriod } from '../position-list-description/position-list-description.component';
@Component({
  selector: 'pdf-positions-description',
  templateUrl: './pdf-positions-description.component.html',
  styleUrls: ['./pdf-positions-description.component.css']
})
export class PdfPositionsDescriptionComponent implements OnInit {

  isTableLoading: boolean;
  file = { logo: '', image1: '', image2:'' }

  fileModalConfig = {
    src: '',
    type: '',
    filename: '',
    width: 225,
    height: 225,
    formTitle: '',
    formSubTitle: '',
  };
  company: any;
  position: any;
  positionId: number = 0;
  dictionary: PositionCompetency[] = []
  constructor(
    private srvDoc: docService,
    private _toastService: ToastService,
    private positionService: PositionService,
    private _companiesService: CompaniesService,
    private auth: AuthInfoService,
    private location: Location,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.companyById(this.auth?.getCompanyId());
    this.positionId = Number(this._route.snapshot.params["positionId"] || 0);
    this.getPositionsById();
    this.getPositionCompetency();
  }

  goBack(){
    this.location.back();
  }

  getPositionsById(){
    this.positionService.getPositionsById(this.auth?.getCompanyId(), this.positionId, true).subscribe({
      next: res => {
        if(res.errors.length > 0){
          this._toastService.error(res.errors[0]);
          return;
        }
        this.position = res?.dataList[0];
      },
      error: error => {
        this._toastService.error('Ocurrió un error al obtener el cargo');
      }
    })
  }

  getPositionCompetency(){
    this.positionService.getPositionCompetency(this.positionId, this.auth?.getCompanyId()).subscribe({
      next: res => {
        if(res?.errors?.length > 0){
          this._toastService.error(res?.errors[0]);
          return;
        }
        this.dictionary = res?.dataList;
      }
    })
  }

  get getCurrentDate(){
    return new Date();
  }

  get getProbationaryPeriodName(){
    return this.probationaryPeriods?.find(p => p?.month === this.position?.probatoryPeriod)?.text ?? '';
  }

  private get probationaryPeriods(): ProbationaryPeriod[] {
    return this.getArrayRange(13).filter(month => month > 0).map(month => {
      return {
        month: month,
        text: month === 1 ? `${month} Mes` : `${month} Meses`
      }
    }); 
  }

  private getArrayRange(length: number): number[] {
    return  Array.apply(null, Array(length)).map(function (_, i) {return i;});
  }

  companyById(companyId: number) {
    this._companiesService.getCompanyById(companyId).subscribe((data: any) => {
      if(data?.errors?.length > 0){
        return;
      }
      this.company = data?.dataList[0];
      this.setGuids(this.company);
    })
  }

  setGuids(company) {
    try {
      this.srvDoc.getDocument(company.logo).subscribe(e => {
        this.file.logo = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image1).subscribe(e => {
        this.file.image1 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }

    try {
      this.srvDoc.getDocument(company.image2).subscribe(e => {
        this.file.image2 = e.data
      }, error => {
        this._toastService.error(error)
      })
    } catch (error) {
      this._toastService.error(error)
    }
  }


}
