import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IHeader } from '@payroll/audit-approval/Audit-Approval-models';
import { ToastrService } from 'ngx-toastr';
import { IEmployeeTypeView } from 'src/app/models/Employee.model';
import { AuditApprovalService } from 'src/app/services/audit-approval.service';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { PDepartmentDetailsModalComponent } from '../p-department-details-modal/p-department-details-modal.component';

@Component({
  selector: 'app-current-payroll-tab',
  templateUrl: './current-payroll-tab.component.html',
  styleUrls: ['./current-payroll-tab.component.css']
})
export class CurrentPayrollTabComponent implements OnInit, OnChanges {

  payrollAllDetails: any[] = [];
  payrollAllDetailsOriginalList: any[] = [];
  payrollAllDetailsFiltered: any[] = [];
  payrollAllDetailsFilteredCopy: any[] = [];
  @Input() employeesCounted = 0
  @Input() item: any;
  public configType = {
    displayKey: 'description', // if objects array passed which key to be displayed defaults to description
    search: true,
    searchPlaceholder: "Buscar",
    placeholder: 'Seleccionar',
    noResultsFound: 'No hay data para mostrar'
  };
  selectedEmployeeType: any;
  payrollDetailResume = {
    companyId: 0,
    headerId: 0,
    totalDiscount: 0,
    totalEmployee: 0,
    totalSfs: 0,
    totalAfp: 0,
    totalSfse: 0,
    totalOtherCredits: 0,
    totalGrossSalary: 0,
    totalIsr: 0,
    totalAfpe: 0,
    totalSrl: 0,
    totalOtherDebits: 0,
    totalNetIncome: 0,
    totalTss: 0,
    totalInavi: 0
  }
  @Input() employeeTypes: IEmployeeTypeView[] = [];
  payrollDetailDepartments: any[] = [];
  payrollDetailDepartmentsFiltered: any[] = [];
  payrollDetailDepartmentsFilteredCopy: any[] = [];

  filteredList: { records: any[], enable: boolean } = { records: [], enable: false };
  filterText: string = '';
  searchBarDisplayNames: { propkey?: string, displayName: string }[] =
  [
    { propkey: "fullName", displayName: "Servidor Público" },
    { propkey: "sex", displayName: "Sexo" },
    { propkey: "positionName", displayName: "Cargo" },
    { propkey: "employeeType", displayName: "Tipo" },
  ];

  @ViewChild('paginator') paginator: CaptionComponent;

  constructor(
    private srvAudit: AuditApprovalService,
    private _toastr: ToastrService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.item?.currentValue?.headerId) {
      let item: IHeader = changes.item.currentValue
      this.loadDepartments(item.headerId);
      this.loadResume(item.headerId);
      this.loadDetails(item.headerId);
    }

  }

  ngOnInit(): void {
  }
  viewDetails(item){
    const {headerId, departmentId, typeEmployeeId, departmentName} = item
    this.dialog.open(PDepartmentDetailsModalComponent,{
      width: '100%',
      data: {
        headerId,
        departmentId,
        typeEmployeeId,
        departmentName
      }
    })    
  }


  loadDetails(id) {
    this.srvAudit.getPayrollDetails(id).subscribe((res: any) => {
      if (res.succeded) {
        const payrolls =  (res.dataList as any[]).map((e: any) => {
          let employee = {
            ...e,
            fullName: `${e?.firstName} ${e?.lastName}`
          }
          return employee;
        })
        this.payrollAllDetails = [...this.getGroupOverallSummaries(payrolls)]
        this.payrollAllDetailsOriginalList =  [...payrolls];
      } else {
        this._toastr.error(res.error[0]);
      }
    }, err => {
      this._toastr.error('', 'ERROR INESPERADO')
    });
  }

  loadResume(id) {
    this.srvAudit.getPayrollDetailResume(id).subscribe((res: any) => {
      if (res.succeded) {
        this.payrollDetailResume = res.dataList[0];
      } else {
        this._toastr.error(res.errors[0]);
      }
    }, err => {
      this._toastr.error('', 'ERROR INESPERADO')
    });
  }
  loadDepartments(id) {
    this.srvAudit.getPayrollDetailByDepartments(id).subscribe((res: any) => {
      if (res.succeded) {
        this.employeesCounted = 0;
        this.payrollDetailDepartments = res.dataList;
        res.dataList.forEach(element => {
          this.employeesCounted = element.employeeCount + this.employeesCounted;
        });
      } else {
        this._toastr.error(res.error[0]);
      }
    }, err => {
      this._toastr.error('', 'ERROR INESPERADO')
    });
  }

  filterDepartments(item) {
    this.payrollDetailDepartmentsFiltered = [];
    let d = this.payrollDetailDepartments;

    if (d.length <= 0) {
      return;
    }

    this.payrollDetailDepartmentsFiltered = d.filter(x => {
      if (item.employeeTypeId == x.typeEmployeeId) {
        return true;
      }
    });
  }
  employeeTypeChange($event) {
    this.filterDepartments($event.value);
  }

  getPaginatedRecordsForDepartments(event) {
    this.payrollDetailDepartmentsFilteredCopy = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  getPaginatedRecordsForDetails(event) {
    this.payrollAllDetailsFiltered = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  getFilteredList(event) {
    event.records = [...this.getGroupOverallSummaries(event.records)];
    this.filteredList = event;
    this.paginator.selectedPage = 1;
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  private getGroupOverallSummaries(viewGetOverallSummaries: any[]){
    let overallSummaries: any[] = [];
    let departmentsSummaries = this.groupBy(viewGetOverallSummaries, (overall: { departmentId: number; }) =>  overall?.departmentId);
    departmentsSummaries.forEach(department => {
       overallSummaries.push({
        departmentName: this.getValues(department)[0]?.departmentName,
        departments: this.getValues(department)?.sort((departmentA: any, departmentB: any) => {
          return  departmentB?.grossSalary  - departmentA?.grossSalary  
        })
      });
    })
    overallSummaries = overallSummaries?.sort((departmentGroupA: any, departmentGroupB: any) => {
      return departmentGroupA?.departments[0]?.order - departmentGroupB?.departments[0]?.order
    })
    overallSummaries.forEach(d => {
      d.grossSalary = this.getSubTotalOverallSummaries(d?.departments, 'grossSalary');
      d.totalSalary = this.getSubTotalOverallSummaries(d?.departments, 'totalSalary');
      d.otherCredits = this.getSubTotalOverallSummaries(d?.departments, 'otherCredits');
      d.afp = this.getSubTotalOverallSummaries(d?.departments, 'afp');
      d.isr = this.getSubTotalOverallSummaries(d?.departments, 'isr');
      d.sfs = this.getSubTotalOverallSummaries(d?.departments, 'sfs');
      d.otherDiscounts = this.getSubTotalOverallSummaries(d?.departments, 'otherDiscounts');
      d.totalDiscount = this.getSubTotalOverallSummaries(d?.departments, 'totalDiscount');
      d.netIncome = this.getSubTotalOverallSummaries(d?.departments, 'netIncome');
    })
    return overallSummaries;
  }

  private getSubTotalOverallSummaries(list: any[], typeTotal: string){
    return list?.reduce((previous, current) => previous + current[typeTotal], 0)
  }

  private getValues(item) {
    return Object.values(item) as any[];
  }

  private groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  getTotalOverallSummarie(total) {
    const viewGetOverallSummaries = this.filteredList?.enable ? this.filteredList.records :
      this.payrollAllDetails;
    const totalOverallSummaries = viewGetOverallSummaries?.reduce((previous, current) => previous + current[total], 0);
    return totalOverallSummaries;
  }


}