import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, AfterViewInit, ElementRef } from '@angular/core';
import { Inject, NgZone, PLATFORM_ID } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";

import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { any } from '@amcharts/amcharts5/.internal/core/util/Array';
import { isPlatformBrowser } from '@angular/common';
import { IChartData } from '../models/chartModels';
import ResponsiveTheme from '@amcharts/amcharts5/themes/Responsive';
import AnimatedTheme from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-chart-pie',
  templateUrl: './chart-pie.component.html',
  styleUrls: ['./chart-pie.component.css']
})
export class ChartPieComponent implements AfterViewInit, OnChanges, OnDestroy {

  private root: am5.Root;
  @Input() valueField = 'value'
  @Input() categoryField = 'description'
  @Input() data = []
  @Input() id: string = "chartComponet"

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone, private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.root && changes['data'].currentValue) {
      this.root.dispose();
      this.root = am5.Root.new(this.id)
      this.buildChart()
    }
  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f()
      })
    }
  }

  ngAfterViewInit() {
    this.root = am5.Root.new(this.id)
    this.buildChart()
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose()
        this.elementRef.nativeElement.remove();
      }
    })
  }

  buildChart() {
    if (!this.root) {
      return
    }

    if(this.data.length == 0){
      return;
    }

    

    this.browserOnly(() => {

      const root = this.root;
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      // root.setThemes([
      //   am5themes_Animated.new(root)
      // ]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
      let chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          endAngle: 270
        })
      );

      const responsive = ResponsiveTheme.new(root);


      responsive.addRule({
        name: "AxisRendererY",

        relevant: function (width, height) {
          return width < 480;
        }

      });

      root.setThemes([
        responsive
      ]);
      // Create series
      // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
      let series = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: this.valueField,
          categoryField: this.categoryField,
          endAngle: 270,
          alignLabels: false
        })
      );

      series.states.create("hidden", {
        endAngle: -90
      });

      // Set data
      // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
      series.data.setAll(this.data);

      series.appear(1000, 100);

      this.root = root;
    })
  }






}
