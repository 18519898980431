import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IEmployee } from 'src/app/models/Employee.model';
import { ModalViewsEnum } from 'src/app/models/modal-views';
import { AuthInfoService } from 'src/app/services/auth-info.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel/export-to-excel.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { FilterEvent, SearchBarPaginationParameter } from 'src/app/shared/element-ui/search-bar-pagination/search-bar-pagination.component';
import { CaptionComponent } from 'src/app/shared/element-ui/table/caption/caption.component';
import { PagedResult } from 'src/app/shared/models/page-result';
import { SweetAlert } from 'src/app/shared/utils/Sweet-Alert';
import { EmployeesIncompleteComponent } from '../employees-incomplete/employees-incomplete.component';
import { AddTeacherContractComponent } from './add-teacher-contract/add-teacher-contract.component';
import { DashboardHourlyTeachersComponent } from './dashboard-hourly-teachers/dashboard-hourly-teachers.component';
import { ViewContractsComponent } from './view-contracts/view-contracts.component';
import { CompaniesService } from 'src/app/services/companies.service';
import { Company } from 'src/app/models/company';
import { openReport } from 'src/app/shared/utils/utility';
import { environment } from 'src/environments/environment';
import { ReportCode } from 'src/app/shared/utils/report-code.model';

declare const $: any;
declare function applySort(): any;

@Component({
  selector: 'hourly-teachers',
  templateUrl: './hourly-teachers.component.html',
  styleUrls: ['./hourly-teachers.component.css']
})

export class HourlyTeachersComponent implements OnInit {
  searchParameter: FilterEvent
  employeesPagedResult = new PagedResult<any>();
  public privilege = {
    nameKeyModule: 'HRM',
    nameKeyOption: 'HOURLY-TEACHERS',
    export: { key: 'Export', value: false },
    add: { key: 'Add-Contract', value: false },
    ViewContractHistory: { key: 'View-Contract-History', value: false },
    search: { key: 'SEARCH', value: false },
    edit: { key: 'Edit', value: false },
    IncompleteTeachers: { key: 'Incomplete-Teachers', value: false },
    view: { key: 'View', value: false },
    editContract: { key: 'EditContract', value: false },
    endContract: { key: 'EndContract', value: false },
  }
  canUseOption = false
  @Input() defaultParameterValue: { propkey: string, displayName: string }
  subscription: Subscription = new Subscription();
  sweetAlrt = new SweetAlert

  statusOptions: { name: string, code: number }[] = [
    { name: 'Activos', code: 1 },
    { name: 'Inactivos', code: 0 }
  ];
  localStorageSearch = 'app-hourly-teachers'
  searchList: IEmployee[] = [];
  searchBarDisplayNames: SearchBarPaginationParameter[] =

    [
      { propkeyId: 1, displayName: "Secuencia" },
      { propkeyId: 3, displayName: "Docente" },
      { propkeyId: 16, displayName: "Tipo de Documento" },
      { propkeyId: 4, displayName: "Documento de Identidad", default: true },
      { propkeyId: 15, displayName: "Sexo" },
      { propkeyId: 5, displayName: "Unidad organizativa" },
      { propkeyId: 6, displayName: "Cargo" },
      { propkeyId: 17, displayName: "Precio por Hora" },
      { propkeyId: 18, displayName: "Fin Contrato", type: 'date' },
    ];

  filterText = '';
  selectedItems: any[] = [];
  viewTypes = ModalViewsEnum;

  teachers: IEmployee[] = [];
  teachersFiltered: IEmployee[] = [];
  teachersPaginated: IEmployee[] = [];
  companys: any[] = [];
  company: any;

  dropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: false,
    limitSelection: -1
  };

  incompleteEmployees: { quantity: number } = {
    quantity: 0
  }

  constructor(
    private _exlService: ExportToExcelService,
    private _cdRef: ChangeDetectorRef,
    private _payrollService: PayrollService,
    private _toastService: ToastrService,
    private dialog: MatDialog,
    public authInfo: AuthInfoService,
    private employeeService: EmployeesService,
    private datePipe: DatePipe,
    private conpaniesService: CompaniesService,
  ) {
  }

  ngOnInit(): void {
    this.getCompany();
    if (JSON.parse(localStorage.getItem("hourly-teachers-status"))?.length > 0) {
      this.selectedItems = JSON.parse(localStorage.getItem("hourly-teachers-status"));
    } else {
      const valuepr = [this.statusOptions[0]];
      localStorage.setItem('hourly-teachers-status', JSON.stringify(valuepr));
      this.selectedItems = JSON.parse(localStorage.getItem("hourly-teachers-status"))
    }
    this.setStatusToLocalStorage()
    this.authInfo.canUseOption(this.privilege.nameKeyModule, this.privilege.nameKeyOption).then(result => {
      if (result == true) {
        this.privilege = this.authInfo.setPrivileges(this.privilege);
        this.canUseOption = true;
        this.loadTeachers();
        this.getTeacherIncompleteCount();
      }
    });
    applySort();
  }

  getCompany() {
    this.conpaniesService.getCompanies().subscribe({
      next: (resp: any) => {
        this.companys = resp.dataList
        this.company = this.companys.find((company: any) => company.companyId === this.authInfo.getCompanyId());
        this.loadTeachers();
      }
    })
  }

  empleyeeebycompany() {
    this.loadTeachers();
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  loadTeachers() {
    if (!this.canUseOption) {
      return
    }
    const { page, pageSize } = this.employeesPagedResult
    const text = this.searchParameter?.value
    const propkeyId = this.searchParameter?.value ? this.searchParameter?.propkeyId : 0;
    const activeId = 1
    const inactiveId = 0
    const branchId: number = this.company?.companyId ?? 0
    const actives = this.selectedItems.find((x) => x.code === activeId);
    const inactives = this.selectedItems.find((x) => x.code === inactiveId);
    let status = 0;
    if ((!actives && !inactives) || (actives && inactives)) {
      status = -1;
    } else if (actives && !inactives) {
      status = 1;
    }
    const params = {
      propkeyId,
      status,
      page,
      pageSize,
      type: 8,
      branchId,
      searchParameter: text,
    };
    this.employeeService.getTeachersPaginated(params).subscribe({
      next: (res: any) => {
        if (!res.succeded) {
          res.errors.forEach(err => {
            this._toastService.error(err);
          });

          res.warnings.forEach(err => {
            this._toastService.warning(err);
          });

          return;
        }
        this.teachers = res.singleData.items;
        this.employeesPagedResult = res.singleData

      },
      error: (err) => {
        this._toastService.error('Ha ocurrido un error tratando de consultar la lista de docentes.');
      }
    });
  }

  getTeacherIncompleteCount() {
    this.employeeService.getTeacherIncompleteCount().subscribe({
      next: res => {
        if (!res.succeded) {
          res.errors.forEach(element => {
            this._toastService.error(element);
          });
          return;
        }
        this.incompleteEmployees = res?.singleData;
      },
      error: (err) => {
        this._toastService.error('Ha ocurrido un error inesperado al obtener el total de docentes incompletos');
      }
    })
  }

  openDashboard() {
    this.dialog.open(DashboardHourlyTeachersComponent, {
      width: '80%'
    })
  }

  OpenAddContract(model, viewType: number): void {
    this.dialog.open(AddTeacherContractComponent, {
      width: '50%',
      data: { viewType, model }
    })
      .afterClosed()
      .subscribe((result) => {

        if (result == true) {
          this.loadTeachers();
        }

      });
  }

  OpenViewContract(model: IEmployee): void {
    this.dialog.open(ViewContractsComponent, {
      width: '70%',
      data: { model, ableToEditContract: this.privilege.editContract.value, 
        ableToEndContract: this.privilege.endContract.value }
    })
      .afterClosed()
      .subscribe((result) => {

      });
  }
  filterSearch(event: FilterEvent) {
    this.employeesPagedResult.page = 1
    this.searchParameter = event
    this.loadTeachers()
  }

  openModalIncompleteEmployees() {
    this.dialog.open(EmployeesIncompleteComponent, {
      data: { incompleteEmployees: this.incompleteEmployees, title: 'Lista de Docentes con Datos Incompletos', type: 2 },
      width: '100%'
    }).afterClosed().subscribe((res: { changeInfo: boolean }) => {
      if (res?.changeInfo) {
        this.getTeacherIncompleteCount();
      }
    })
  }

  dropdownConfig(displayKey: string = "label") {
    return {
      displayKey: displayKey, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto',
      placeholder: 'Seleccione una opción', // text to be displayed when no item is selected defaults to Select,
      //customComparator: (a, b)=> a.id - b.id, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: '...', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No se han encontrado registros', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Buscar', // label thats displayed in search input,
      searchOnKey: displayKey // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }
  }

  onKeyChange(text) {
    this.filterText = text;
  }

  exportToExcel() {
    let data: IEmployee[] = this.teachers

    const excelHeaders: string[][] = [[
      "Secuencia",
      "Docente",
      "Documento Identidad",
      "Sexo",
      "Unidad Organizativa",
      "Cargo",
      "Fecha Nacimiento",
      "Fecha Registro",
      "Inicio de Contrato",
      "Fin de Contrato",
      "Precio por Hora",
      "Estatus"
    ]];

    const records = data.map(d => {
      return {
        employeeId: d.employeeId,
        name: d.name,
        personalIdentification: d.personalIdentification,
        sex: d.sex,
        departmentName: d.departmentName,
        positionName: d.positionName,
        birthday: this.datePipe.transform(d.birthday, 'dd/MM/yyyy'),
        admissionDate: this.datePipe.transform(d.admissionDate, 'dd/MM/yyyy'),
        contractEndDate: this.datePipe.transform(d.contractExpiration, 'dd/MM/yyyy'),
        contractStartDate: this.datePipe.transform(d.hiredDate, 'dd/MM/yyyy'),
        salary: d.salary,
        status: d.status ? 'Activo' : 'Inactivo'
      }
    });

    this._exlService.exportToExcelSpecificColumns(records, excelHeaders, 'Lista de Docentes');
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.filter();
  }
  onItemDeSelect(item: any) {
    this.filter();
  }
  onItemSelect(item: any) {
    this.filter();
  }
  onSelectAll(item: any) {
    this.selectedItems = item;
    this.filter()
  }

  filter() {
    this.loadTeachers()
    this.setStatusToLocalStorage()
  }

  setStatusToLocalStorage() {
    localStorage.setItem('hourly-teachers-status', JSON.stringify(this.selectedItems));
  }

  getPaginatedRecords(event) {
    const { selectedPage, registersPerPage } = event;
    const { employeesPagedResult } = this;
    if (employeesPagedResult.page === selectedPage && employeesPagedResult.pageSize === registersPerPage) {
      return;
    }
    this.employeesPagedResult.page = selectedPage;
    this.employeesPagedResult.pageSize = registersPerPage;
    this.loadTeachers()
  }

  exportToPdf() {
    const propKeyId: number = this.searchParameter?.propkeyId ?? 0;
    const searchParameter: string = this.searchParameter?.value ?? '';
    const actives = this.selectedItems?.find((x) => x.code === 1);
    const inactives = this.selectedItems?.find((x) => x.code === 0);
    let status = 0;
    if ((!actives && !inactives) || (actives && inactives)) {
      status = -1;
    } else if (actives && !inactives) {
      status = 1;
    }
    const page: number = this.employeesPagedResult?.page;
    const pageSize: number = this.employeesPagedResult?.pageSize;
    const branchId: number = this.company?.companyId ?? 0
    const companyId: number = this.authInfo.getCompanyId();
    const reportCode: ReportCode = ReportCode.TeacherPerHoursList;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&BranchId=${branchId}&PropKeyId=${propKeyId}&SearchParameter=${searchParameter}&Status=${status}&Page=${page}&PageSize=${pageSize}`
    let parameters = {
      url: reportUrl,
      title: 'Lista de Docentes por Asignatura',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }


  exportTeacherData(teacher: { companyId: any; employeeId: any; status: any; }) {
    const { companyId, employeeId, status } = teacher
    const reportCode: ReportCode = ReportCode.EmployeeData;
    const reportUrl = `${environment.reportUrl}/?ReportCode=${reportCode}&CompanyId=${companyId}&EmployeeId=${employeeId}&Status=${status ? 1 : 0}`;
    let parameters = {
      url: reportUrl,
      title: 'Reporte Servidores Públicos',
      width: 1024,
      height: 768
    }
    openReport(parameters);
  }

}
