import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { candidate, personalInformation, personeEmail, personPhone } from 'src/app/shared/models/candidate-registration.model';
import { AuthInfoService } from './auth-info.service';
import { response } from '../models/response.model';
import { EmployeePermissionsEntities } from '../models/service-request.model';
import { EmployeeServiceRequestEntities } from '../models/EmployeeServiceRequestEntity.model';


@Injectable({
  providedIn: 'root',
})

export class serviceRequestService {
  private readonly urlApi = environment.api_url
  constructor(private _http: HttpClient, private authInfo: AuthInfoService) { }

  getRequestServices(employeeId: number, companyId: number, departmentId: number, impuesto: boolean, dateFrom?: Date, dateTo?: Date): Observable<response> {
    let paramsDate = ""

    if (dateFrom && dateTo) {
      paramsDate = `&dateFrom=${new Date(dateFrom).toISOString().substring(0, 10)}&dateTo=${new Date(dateTo).toISOString().substring(0, 10)}`
    } else if (dateFrom) {
      paramsDate = `&dateFrom=${new Date(dateFrom).toISOString().substring(0, 10)}`
    } else if (dateTo) {
      paramsDate = `&dateTo=${new Date(dateTo).toISOString().substring(0, 10)}`
    }

    return this._http.get<response>(`${this.urlApi}/EmployeePermissions/getRequestServices?employeeId=${employeeId}&companyId=${companyId}&departmentId=${departmentId}&impuesto=${impuesto}&status=true` + paramsDate);
  }

  postRequestServices(data) {
    return this._http.post<response>(`${this.urlApi}/EmployeePermissions/postRequestServices`, data);
  }

  approveRequestServices(data) {
    return this._http.post<response>(`${this.urlApi}/EmployeePermissions/ApproveRequestServices`, data);
  }

  getAddressJobLetter(data) {
    return this._http.get<response>(`${this.urlApi}/EmployeePermissions/getAddressJobLetter/${data}`);
  }

  postAddressJobLetter(data) {
    return this._http.post<response>(`${this.urlApi}/EmployeePermissions/postAddressJobLetter`, data);
  }

  getInformationToJobLetter() {
    return this._http.get<response>(`${this.urlApi}/EmployeePermissions/getInformationToJobLetter`);
  }
}