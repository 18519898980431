import { AfterViewChecked, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthInfoService } from "src/app/services/auth-info.service";
import { ToastService } from "src/app/shared/toast/toast.service";

@Component({
    selector: 'app-bulk-Load-Final-Erros-Table',
    templateUrl: './bulkLoadFinalErrosTable.component.html',
    styleUrls: ['./bulkLoadFinalErrosTable.component.css']
})
export class bulkLoadFinalErrosTableComponent implements AfterViewChecked {
    errorList: string[] = []
    errorListOriginalList: string[] = [];
    errorListPaginate: string[] = [];

    constructor(private _toastService: ToastService,
        public dialogRef: MatDialogRef<bulkLoadFinalErrosTableComponent>,
        private _changeDet: ChangeDetectorRef, private dialog: MatDialog,
        private authInfo: AuthInfoService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.errorList = this.data.errors;
        this.errorListOriginalList = this.data.errors;
        this.dialogRef.disableClose = true;
    }

    ngAfterViewChecked(): void {
        this._changeDet.detectChanges();
    }

    getPaginatedRecords(event) {
        this.errorListPaginate = event.formattedRecords[event.selectedPage - 1] ? event.formattedRecords[event.selectedPage - 1].records : [];
    }

}